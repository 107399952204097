import { ViewTypes } from '@markinson/mk.mpv4.schedulercomponent';

export const initalSchedulerData = {
    headerEnabled: false,
    eventItemPopoverEnabled: true,
    schedulerWidth: '67.6%',
    schedulerMaxHeight: '100%',
    eventItemHeight: 60,
    eventItemLineHeight: 65,
    tableHeaderHeight: 50,
    nonWorkingTimeHeadColor: '#000000',
    nonWorkingTimeHeadBgColor: '#ffffff',
    nonWorkingTimeBodyBgColor: '#ffffff',
    frozenAreaColor: '#d3d3d385',
    groupOnlySlotColor: '#dddddd',
    resourceName: 'Technician',
    nonAgendaOtherCellHeaderFormat: 'dddd DD/MM/YYYY',
    views: [
        { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false }
    ]
};
