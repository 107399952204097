import { createStyles } from '@material-ui/core/styles';
export const inlineStyles = createStyles({
    mainPanel: {
        width: '17vw',
        minWidth: '300px',
        backgroundColor: '#666666',
        float: 'right',
        height: 'calc(100vh - 172px)',
    },
    subPanel: {
        margin: '10px',
        padding: '10px'
    },
    subPanelLastChild: {
        margin: '10px',
        padding: '10px'
    },
    subPanelTitle: {
        color: '#000000',
        fontSize: '15px',
        marginBottom: '10px'
    },
    summaryTable: {
        width: '100%',
        margin: 'auto'
    },
    summaryRow: {
        height: 'inherit',
    },
    headingCell: {
        backgroundColor: '#eeeeee',
        border: '1px solid #fff',
        color: '#000000',
        fontSize: '10px',
        fontWeight: 'bold',
        width: '75px',
        padding: '10px !important',
    },
    dataCell: {
        fontSize: '15px',
        padding: '10px !important',
        backgroundColor: '#dddddd',
        color: '#000000',
        border: '1px solid #fff !important'
    },
    numericCell: {
        backgroundColor: '#dddddd',
        color: '#000000',
        fontSize: '15px',
        border: '1px solid #fff',
        textAlign: 'right',
        padding: '10px !important'
    },
    totalCell: {
        backgroundColor: '#cccccc',
        fontSize: '16px',
        color: '#000000',
        textAlign: 'right',
        padding: '10px !important'
    },
    icon: {
        'height': '18px',
        'width': '18px',
        'verticalAlign': 'middle',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    preLoaderWrapperCustomer: {
        height: '96px'
    },
    preLoaderWrapperTotal: {
        height: '106px'
    },
    preLoader: {
        position: 'relative',
        left: 'calc(9.5vw - 50px)',
    },
    splitCellContainer: {
        display: 'flex'
    },
    splitCellContent: {
        flex: '5',
    },
    splitCellAction: {
        flex: '1',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginRight: '-5px'
    },
    iconWarning: {
        'height': '24px',
        'width': '24px',
        'verticalAlign': 'middle',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    media: {
        height: '180px',
        backgroundSize: 'contain'
    },
    switchBase: {
        '&$checked': {
            '& + $bar': {
                backgroundColor: '#6DD900',
                opacity: 1
            },
        },
    },
    checked: {
        '& + $bar': {
            backgroundColor: '#6DD900',
            opacity: 1
        },
    },
    bar: {
        backgroundColor: '#6DD900',
        opacity: 1
    }
});

export const styles = createStyles({
    alertMessage: {
        color: 'red',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '25px',
    },
    dialogStyles: {
        minWidth: '300px',
        overflow: 'auto'
    },
    button: {
        'backgroundColor': '#43A047',
        'color': '#FFFFFF',
        'marginRight': '20px',
        'marginBottom': '16px',
        '&:hover': {
            backgroundColor: '#66BB6A',
        }
    }
});
