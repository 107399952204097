import React, { useState } from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import HelpIcon from '@markinson/uicomponents-v2/SvgIcons/HelpOutline';
import SearchIcon from '@markinson/uicomponents-v2/SvgIcons/Search';
import FavoritesIcon from '@markinson/uicomponents-v2/SvgIcons/Star';
import SettingsIcon from '@markinson/uicomponents-v2/SvgIcons/Settings';
import NotificationsIcon from '@markinson/uicomponents-v2/SvgIcons/Notifications';
import AccountCircle from '@markinson/uicomponents-v2/SvgIcons/AccountCircle';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { AppBar as NavBar, createStyles, StyledComponentProps, IconButton } from '@material-ui/core';
// import IconButton from '@markinson/ui-components/material/IconButton';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@markinson/uicomponents-v2/SvgIcons/Menu';
import CloseIcon from '@markinson/uicomponents-v2/SvgIcons/Close';
import styles from './AppBar.scss';
import AppBarMenu from './ApplicationBarMenu';
import MainMenu from '../../MainMenu';
import Tooltip from '@material-ui/core/Tooltip';
import { Entry } from '../../../config';
import logoImageAsset from 'assets/logo.png';
import { IStaff } from 'api/login';

const inlineStyles = {
  smallIcon: {
    width: 28,
    height: 28,
    color: '#FFFFFF'
  },
  accountCircleText: {
    color: 'white',
    height: 64,
  }
};

const classStyles = createStyles({
  root: {
    flexGrow: 1,
  },
  small: {
    'width': 52,
    'height': 64,
    'padding-left': 12,
    'padding-right': 12,
    'padding-top': 16,
    'padding-bottom': 16,
    'background-color': 'transparent',
    'border-radius': 0,
    '&:hover': {
      'background-color': '#333333'
    },
  },
  smallActiveMenu: {
    'background-color': '#444444',
    '&:hover': {
      'background-color': '#444444'
    },
  },
  smallActive: {
    'background-color': '#FE6F01',
    '&:hover': {
      'background-color': '#FE6F01'
    },
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: 'inherit',
  },
  menuButtonActive: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: '#333333',
  },
  badge: {
    top: 15,
    right: 10,
    color: '#FFFFFF',
    width: 20,
    height: 20,
  },
  badgeUrgent: {
    backgroundColor: '#FF0000'
  },
  badgeAction: {
    backgroundColor: '#25B7D3'
  },
  badgeGeneral: {
    backgroundColor: '#CCCCCC'
  },
  accountCircle: {
    'display': 'inline-block',
    'vertical-align': 'top',
    '&:hover': {
      'background-color': '#333333'
    },
  },
  accountCircleActive: {
    'background-color': '#FE6F01',
    '&:hover': {
      'background-color': '#FE6F01'
    },
  },
  accountCircleText: {
    'transition': 'none',
    'color': 'white',
    'height': 64,
    'padding': '8px 16px',
    '& span span': {
      'margin-right': 5
    },
  },
  disabledButton: {
    opacity: '0.4',
  },
  testInstanceNavBar: {
    backgroundRepeat: 'repeat',
    backgroundColor: '#7F0000 !important'
  }
});

export interface IPanel {
  title: string;
  type: React.ReactNode;
  disabled: boolean;
}
const RightSideIcons: IPanel[] = [
  { title: 'Search', type: SearchIcon, disabled: false },
  { title: 'Help', type: HelpIcon, disabled: false },
  { title: 'Favorites', type: FavoritesIcon, disabled: true, },
  { title: 'Notifications', type: NotificationsIcon, disabled: true, },
  { title: 'User Account', type: AccountCircle, disabled: false },
  { title: 'Settings', type: SettingsIcon, disabled: true }
];

const RightSideIconsSecurityManagement: IPanel[] = [
  { title: 'User Account', type: AccountCircle, disabled: false }
];

interface IAppBarProps extends StyledComponentProps, RouteComponentProps {
  testInstanceName: string;
  staff?: IStaff;
  isMainMenuOpen?: boolean;
  showIcons?: boolean;
  toggleMainMenu?(open: boolean): void;
}

function getTestInstanceBackgroundStyle(TestInstanceName: string): React.CSSProperties {

  return {
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='40px' width='80px'><text x='40' y='20' fill='black' transform='rotate(-30 40 20)' text-anchor='middle' opacity='0.6' font-family='sans-serif'>${TestInstanceName}</text></svg>")`
  };
}

function AppBar(props: IAppBarProps): JSX.Element {

  const { classes, isMainMenuOpen, toggleMainMenu, staff, history, showIcons, testInstanceName } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [rightIconActiveKey, setRightIconActiveKey] = useState<number>(-1);

  const toggleMainMenuOpen = () => {
    toggleMainMenu(isMainMenuOpen);
  };

  const handleToggle = () => {
    setOpen((preOpen) => !preOpen);
    setRightIconActiveKey(-1);
  };

  const handleButtonState = (buttonKey, disabledState) => {
    if (disabledState) {
      return;
    }

    if (buttonKey === rightIconActiveKey) {
      setRightIconActiveKey(-1);
      setOpen((preOpen) => !preOpen);
    } else {
      setRightIconActiveKey(buttonKey);
      setOpen(true);
    }
  };

  const renderIcon = (IconType, iconKey, iconTitle, disabledState) => {
    const btnClass = classNames(styles.navigationButton, rightIconActiveKey === iconKey ? classes.smallActive : false, classes.small, disabledState ? classes.disabledButton : false);
    if (iconTitle === 'Notifications') {
      return (
        <Tooltip title='Notifications' key={iconKey} disableFocusListener={disabledState} disableHoverListener={disabledState} disableTouchListener={disabledState}>
          <Badge
            onClick={() => handleButtonState(iconKey, disabledState)}
            badgeContent={0}
            style={{ padding: 0 }}
            classes={{ badge: classNames(classes.badge, classes.badgeGeneral, disabledState ? classes.disabledButton : false) }}
          >
            <IconButton
              onClick={() => handleButtonState(iconKey, disabledState)}
              className={btnClass}
              disabled={disabledState}>
              <NotificationsIcon style={inlineStyles.smallIcon} />
            </IconButton>
          </Badge>
        </Tooltip>
      );
    }
    if (iconTitle === 'User Account') {
      const _staff = staff || { name: 'Demo.User' };

      return (
        <Tooltip title='User Account' key={iconKey} disableFocusListener={disabledState} disableHoverListener={disabledState} disableTouchListener={disabledState}>
          <div
            className={classNames(classes.accountCircle, rightIconActiveKey === iconKey ? classes.accountCircleActive : false)}
          >
            <Button
              onClick={() => handleButtonState(iconKey, disabledState)}
              disableRipple={true}
              disabled={disabledState}
              className={classNames(styles.navigationButton, classes.accountCircleText)}
            >
              <span className={styles.userAccount}>{_staff.name}</span>
              <AccountCircle style={inlineStyles.smallIcon} />
            </Button>
          </div>
        </Tooltip>
      );
    }
    if (iconTitle === 'Help') {
      return (
        <Tooltip title={iconTitle} key={iconKey} disableFocusListener={disabledState} disableHoverListener={disabledState} disableTouchListener={disabledState}>
          <span>
            <IconButton
              onClick={() => { window.open('/FrontOfficeHelp.pdf'); }}
              className={btnClass}
              disabled={disabledState}
            >
              <IconType style={inlineStyles.smallIcon} />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={iconTitle} key={iconKey} disableFocusListener={disabledState} disableHoverListener={disabledState} disableTouchListener={disabledState}>
        <span>
          <IconButton
            onClick={() => handleButtonState(iconKey, disabledState)}
            className={btnClass}
            disabled={disabledState}
          >
            <IconType style={inlineStyles.smallIcon} />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const handleOnLogoClick = () => {
    history.push('/');
  };

  const renderAppBar = () => {

    const TitleLogo = () => (
      <div
        className={classNames(showIcons ? styles.mainNavigationLogo : styles.mainNavigationFullLogo, styles.iconsOnRightContainer)}>
        <Tooltip title='Go to Dashboard' disableHoverListener={showIcons ? false : true} >
          <img
            className={styles.logoImage}
            src={logoImageAsset}
            onClick={handleOnLogoClick}
          />
        </Tooltip>
      </div>
    );

    return (
      <NavBar
        id='MainNavigation'
        position='static'
        className={classNames(styles.appBar, testInstanceName && classes.testInstanceNavBar)}
        style={testInstanceName ? getTestInstanceBackgroundStyle(testInstanceName) : undefined}
      >
        <Toolbar>
          {((!Entry || Entry !== 'security-management') && showIcons) ?
            <IconButton
              className={classNames(classes.small, isMainMenuOpen ? classes.smallActiveMenu : false, isMainMenuOpen ? classes.menuButtonActive : classes.menuButton)}
              onClick={toggleMainMenuOpen}>
              {isMainMenuOpen ?
                <CloseIcon style={inlineStyles.smallIcon} /> :
                <MenuIcon style={inlineStyles.smallIcon} />}
            </IconButton> : null
          }
          <TitleLogo />
          {(Entry && Entry === 'security-management' && showIcons) ?
            <div className='styles.ApplicationBarActionIcons'>
              {
                RightSideIconsSecurityManagement.map((item, indx) => (
                  renderIcon(item.type, indx, item.title, item.disabled)
                ))
              }
            </div> : ''}
          {((!Entry || Entry !== 'security-management') && showIcons) ?
            <div className='styles.ApplicationBarActionIcons'>
              {
                RightSideIcons.map((item, indx) => (
                  renderIcon(item.type, indx, item.title, item.disabled)
                ))
              }
            </div> : ''}
        </Toolbar>
      </NavBar>
    );
  };

  return (
    <div>
      {renderAppBar()}
      <AppBarMenu open={open && showIcons} panel={(Entry === 'security-management' && RightSideIconsSecurityManagement[rightIconActiveKey]) || RightSideIcons[rightIconActiveKey]} handleToggle={handleToggle} />
      {(!Entry || Entry !== 'security-management') && <MainMenu open={isMainMenuOpen} />}
    </div>
  );
}

export default withRouter(withStyles(classStyles, { index: 1 })(AppBar));
