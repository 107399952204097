
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import {
    selectors as activitySchedulerSelectors
} from 'ducks/serviceActivityScheduling/serviceActivities';
import SummaryPanel from './SummaryPanel';
import { ISummaryPanelProps } from 'components/ServiceActivityScheduling/interfaces';

type IStateProps = Pick<ISummaryPanelProps,
    'dataGridData' | 'loading'>;
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    dataGridData: activitySchedulerSelectors.dataGridData(state),
    loading: activitySchedulerSelectors.jobSummaryLoading(state),
});

export default connect(mapStateToProps, {})(SummaryPanel);
