import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as eventsSelectors, actions as eventsActions } from 'ducks/common/events'
import { search } from 'api/common/events';

export const mapStateToProps = state => ({
  gridName: 'Events',
  columnDefs: eventsSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: eventsSelectors.gridOptions(state),
  isLoading: eventsSelectors.isLoading(state),
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: eventsActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode
}
