import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as salesOrderTypes, actions as salesOrderActions } from 'ducks/salesOrderEnquiry/deliveryLine';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/salesOrderDeliveryLines';

function* getDetails(action) {
  const { success, failure } = salesOrderActions.saga.getDetails;
  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  )
}

export default function* rootSalesOrderSaga() {
  yield takeLatest(salesOrderTypes.getDetails, getDetails)
}