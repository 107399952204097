import BreaksTable from './BreaksTable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as lookupActions, selectors as lookupSelectors } from 'ducks/lookup';
import { IApplicationState } from 'ducks/reducers';
import { IBreaksTableProps } from './interfaces';
import schema from './BreaksTableSchema';

const getSchema = (variant) => {
  switch (variant) {
    case 'ProductPriceScheduleDetails': return schema.ProductPriceScheduleDetails;
    case 'ProductPriceScheduleSellPrices': return schema.ProductPriceScheduleSellPrices;
    default: return null;
  }
};

const mapStateToProps = (state: IApplicationState, props: IBreaksTableProps) => ({
  lookUpOptions: lookupSelectors.options(state) || [],
  schema: getSchema(props.variant)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchLookupOptions: lookupActions.fetchData,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreaksTable);
