import { ISummaryTableDataNode } from 'components/common/SummaryTable/SummaryTable.properties';

const schema: ISummaryTableDataNode[] = [
    {
        objectName: 'PurchaseOrderSummary',
        isLoading: 'purchaseOrdersLoading',
        title: 'Purchase order details',
        children: [
            {
                label: 'Purchase Order',
                fieldNames: ['PurchaseOrder'],
            },
            {
                label: 'Supplier',
                fieldNames: [
                    'SupplierDisplay',
                    'SupplierDisplayLabel',
                    'SupplierCityState'
                ],
                valueRenderer: {
                    api: {
                        api: '/supplierEnquiry/lookup/action',
                        name: 'Supplier',
                        params: {
                            queryFields: {
                                Supplier: 'Supplier'
                            }
                        }
                    }
                }
            },
            {
                label: 'Warehouse',
                fieldNames: [
                    'Warehouse',
                    'WarehouseLabel'
                ],
            },
            {
                label: 'Order Category',
                fieldNames: [
                    'PurchaseOrderCategoryDescription'
                ],
            },
            {
                label: 'Order Type',
                fieldNames: [
                    'OrderType',
                ],
            },
            {
                label: 'Status',
                fieldNames: [
                    'Status',
                ],
                dialogBox: {
                    tableCell: 'value',
                    fieldName: 'AlertMessage',
                    title: 'Alert',
                    color: 'Amber',
                    icon: 'Warning',
                },
            },

        ]
    },
    {
        objectName: 'PurchaseOrderSummary',
        isLoading: 'purchaseOrdersLoading',
        children: [
            {
                label: 'Created',
                fieldNames: [
                    'CreatedDate',
                ],
            },
            {
                label: 'Ship Date',
                fieldNames: [
                    'ShipDate',
                ],
            },
            {
                label: 'Earliest Due',
                fieldNames: [
                    'DueDate',
                ],
            },
            {
                label: 'Last Transmit',
                fieldNames: [
                    'TransmitDate',
                ],
            },
        ]
    },
    {
        objectName: 'PurchaseOrderSummary',
        isLoading: 'purchaseOrdersLoading',
        title: 'Totals',
        children: [
            {
                label: 'Currency',
                fieldNames: [
                    'Currency',
                ],
            },
            {
                label: 'Outstanding',
                fieldNames: [
                    'OutstandingDisplay',
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Order Total',
                fieldNames: [
                    'OrderTotalDisplay',
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Tax Included Prices',
                fieldNames: [
                    'TaxIncludedDisplay',
                ],
            },
        ]
    },
];

export default schema;
