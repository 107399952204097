import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import * as paymentApi from 'api/Worksale/payment';
import PaymentEntryWrapper from './PaymentEntryWrapper';
import { IPaymentEntryWrapperProps } from './PaymentEntryWrapper.properties';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

export default connect(null, mapDispatchToProps)((props: Readonly<Omit<
    IPaymentEntryWrapperProps, 'onProcessPayment' | 'onInitializePayment' | 'onAddNewPaymentLine' | 'onCalculatePaymentLineDetails' | 'onUpdatePaymentLineDetails' |
    'onDeletePaymentLine' | 'onCalculatePaymentAmount'>>) => {
    return (
        <PaymentEntryWrapper
            onInitializePayment={paymentApi.initializePayment}
            onProcessPayment={paymentApi.processPayment}
            onAddNewPaymentLine={paymentApi.addNewPaymentLine}
            onCalculatePaymentLineDetails={paymentApi.calculatePaymentLineDetails}
            onUpdatePaymentLineDetails={paymentApi.updatePaymentLineDetails}
            onDeletePaymentLine={paymentApi.deletePaymentLine}
            onCalculatePaymentAmount={paymentApi.calculatePaymentAmount}
            {...props}
        />
    );
});
