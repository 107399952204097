import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../DynamicExtractModal.styles';
import { IWelcomePanelProps } from '../DynamicExtractModal.properties';
import { Content, Heading } from '../CommonComponents';

const WelcomePanel = (props: IWelcomePanelProps) => {
    const {
        classes,
        details
    } = props;

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(WelcomePanel);
