import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as supplierTypes, actions as supplierActions } from 'ducks/pickSlipEnquiry/slipLines';

import { callApi } from '../utils';
import * as api from 'api/pickSlipEnquiry/slipLines';

function* fetchSlipLineDetails(action) {
  const { success, failure } = supplierActions.saga.fetchSlipLineDetails;

  yield callApi(
    call(api.fetchSlipLineDetails, action.data),
    success,
    failure
  )
}

function* fetchSlipLineDetailsSummary(action) {
  const { success, failure } = supplierActions.saga.fetchSlipLineDetailsSummary;

  yield callApi(
    call(api.fetchSlipLineDetailsSummary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(supplierTypes.fetchSlipLineDetails, fetchSlipLineDetails)
  yield takeLatest(supplierTypes.fetchSlipLineDetailsSummary, fetchSlipLineDetailsSummary)
}