import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { touch, reset } from 'redux-form';
import DashboardMaintenance from './DashboardMaintenance';
import DashboardMaintenanceProps from './DashboardMaintenance.properties';
// import { actions as formActions } from 'ducks/form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as checklistSelectors } from 'ducks/checklistMaintenance/checklist';
import * as api from 'api/dashboardMaintenance/dashboardMaintenance';
import { actions as uiActions } from 'ducks/ui';

const ADD_WIDGET_FORM_NAME = 'AddWidgetForm';
const mapStateToProps = (state: IApplicationState) => ({
    // open: true,
    selectedChecklist: checklistSelectors.selected(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        touchFormFields: (...args: any) => touch(ADD_WIDGET_FORM_NAME, ...args),
        resetAddWidgetForm: () => reset(ADD_WIDGET_FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
    DashboardMaintenanceProps, 'getUserDashboards' | 'saveDashboards'>>) => {
    return (<DashboardMaintenance
        getUserDashboards={api.getUserDashboards}
        {...props} />);
});
