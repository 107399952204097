import { ICatalogueProductFacade } from 'api/swaggerTypes';
import { createReducer } from 'utils/helpers';
import { IActions, ProductCatalogueActionTypes } from './ProductCatalogue.actions';
import { EntryListStorageKey } from './constants';

const getStoredCatalogueId = () => {
  const storedSelectedEntryString = sessionStorage.getItem(EntryListStorageKey);
  const storedSelectedEntry = storedSelectedEntryString ? JSON.parse(storedSelectedEntryString) : null;
  const selectedCatalogueId = Number(storedSelectedEntry?.selectedRowKeys?.[0] ?? 0);

  return selectedCatalogueId;
};

export interface IState {
  breadCrumbData: any[];
  addToSaleOpen: boolean;
  selectedCatalogueId: number;
  selectedProduct: ICatalogueProductFacade;
  isSearchOpen: boolean;
  addToSaleLineClicked: boolean;
}

export const initialState: IState = {
  breadCrumbData: [],
  addToSaleOpen: false,
  selectedProduct: null,
  isSearchOpen: false,
  addToSaleLineClicked: false,
  selectedCatalogueId: getStoredCatalogueId(),
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<ProductCatalogueActionTypes, IAction>;

const actionMap: IActionMap = {
  [ProductCatalogueActionTypes.setBreadCrumbData]: (state: IState, payload: any): IState => ({ ...state, breadCrumbData: payload }),
  [ProductCatalogueActionTypes.setAddToSaleOpen]: (state: IState, payload: any): IState => ({ ...state, addToSaleOpen: payload }),
  [ProductCatalogueActionTypes.setSelectedProduct]: (state: IState, payload: any): IState => ({ ...state, selectedProduct: payload }),
  [ProductCatalogueActionTypes.setIsSearchOpen]: (state: IState, payload: any): IState => ({ ...state, isSearchOpen: payload }),
  [ProductCatalogueActionTypes.setSelectedCatalogueId]: (state: IState, payload: any): IState => ({ ...state, selectedCatalogueId: payload }),
  [ProductCatalogueActionTypes.setAddToSaleLineClicked]: (state: IState, payload: any): IState => ({ ...state, addToSaleLineClicked: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
