import PolicyDetails from 'utils/data/forms/security_management/security_policies/PolicyDetails';
import PolicyRules from 'utils/data/forms/security_management/security_policies/PolicyRules';
import PolicyUserGroups from 'utils/data/forms/security_management/security_policies/PolicyUserGroups';

const getFormSchema = (formId: string) => {
  switch (formId) {
    case 'PolicyDetails':
      return PolicyDetails;
    case 'PolicyRules':
      return PolicyRules;
    case 'PolicyUserGroups':
      return PolicyUserGroups;
    default:
      return PolicyDetails;
  }
};

export default getFormSchema;
