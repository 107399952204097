export default [
  {
    id: '1',
    label: 'Document',
    // 'action': (params) => { window.location.pathname = '/inventory-enquiry' + (params ? '/' + params : '') }
  },
  {
    id: '2',
    label: 'New Document',
    // 'action': (params) => { window.location.pathname = '/product-maintenance' + (params ? '/' + params : '') }
  }
];
