import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  setSalesEntity: (defaultEntity) => ({ defaultEntity }),
  reset: () => null,
  asyncs: {
  }
}, 'lotInPutAway');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Reference', field: 'Reference', minWidth: 200, suppressSorting: true, },
    { headerName: 'Line', field: 'Line', minWidth: 100, suppressSorting: true, },
    { headerName: 'Created', field: 'Created', minWidth: 120, suppressSorting: true, type: 'numericColumn', },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    {
      headerName: 'Account', field: 'CustomerAccountDisplay', minWidth: 100, suppressSorting: true,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: (params) => (params.data && params.data.IsCustomer) ? '/customer-enquiry/customer-details' :
          '/supplier-enquiry/supplier-details',
      },
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.IsCustomer && row.data.CustomerAccount) {
          return {
            CustomerId: row.data.CustomerAccount,
            CustomerAccountNumber: row.data.CustomerAccountDisplay,
            FieldValue: row.data.CustomerAccountDisplay
          };
        }
        if (row.data && row.data.SupplierAccount) {
          return {
            Supplier: row.data.SupplierAccount,
            FieldValue: row.data.SupplierAccountDisplay
          };
        }
        return null;
      }
    },
    { headerName: 'Name', field: 'Name', minWidth: 250, suppressSorting: true, }
  ],
  Data: [],
  selectedFilters: {
    DocumentType: 'Al'
  },
  filterRow: {
    formName: 'IEStockingLotInPutAwayFilters',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Document type',
          name: 'DocumentType',
          required: true,
          options: [
            {
              value: 'Al',
              label: 'All'
            },
            {
              value: 'r',
              label: 'Stock Receipt'
            },
            {
              value: 'c',
              label: 'Credit Note'
            },
            {
              value: 'f',
              label: 'RFC'
            },
            {
              value: 'l',
              label: 'Local Shipment'
            },
            {
              value: 'i',
              label: 'Import Shipment'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
 
    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.lotInPutAway,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.lotInPutAway.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.lotInPutAway.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.lotInPutAway.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.lotInPutAway.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.lotInPutAway.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)