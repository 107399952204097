import { fetchPost, fetchGet, objectify } from '../utils'

export const getSelectedDetails = (supplierId) => {
  return fetchGet(`SupplierEnquiry/Supplier/${supplierId}`)
    .then((result) => result && result.Supplier)
}

export const search = (query) => {
  const { SearchText, Sort, BatchPage } = query
  return fetchPost('PickSlipEnquiry/SearchPickSlips', { SearchText, Sort, BatchPage, BatchSize: 10 })
    .then((something) => ({
      records: something.PickSlips.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }))
}

export const searchById = (DespatchId) => {
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Details`, {}, 'Inline')
    .then((something) => ({
      records: [{ inlineObject: objectify(something.PickSlipDetails), schema: something.PickSlipDetails }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }))

  return apiCall;
}

export const fetchSlipDetails = (DespatchId) => {
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Details`, {}, 'Inline')
    .then((result) => ({
      inlineObject: objectify(result.PickSlipDetails), schema: result.PickSlipDetails
    }));

  return apiCall;
}

export const fetchSlipDetailsSummary = (DespatchId) => {
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Summary`, {}, 'Inline')
    .then((result) => result ? result.PickSlipSummary : null);
  return apiCall;
}
