import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as invoiceTypes, actions as invoiceActions } from 'ducks/salesInvoiceEnquiry/Invoice';

import { callApi } from '../utils';
import * as api from 'api/salesInvoiceEnquiry/invoice';


function* search(action){
  const {success, failure} = invoiceActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = invoiceActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = invoiceActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = invoiceActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* getSummary(action){
  const {success, failure} = invoiceActions.saga.getSummary;

  yield callApi(
    call(api.getSummary, action.data),
    success,
    failure
  )
}

function* getDetails(action){
  const {success, failure} = invoiceActions.saga.getDetails;

  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(invoiceTypes.search, search)
  yield takeLatest(invoiceTypes.searchById, searchById)
  yield takeLatest(invoiceTypes.getSummary, getSummary)
  yield takeLatest(invoiceTypes.getDetails, getDetails)
  yield takeLatest(invoiceTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(invoiceTypes.fetchNextPage, fetchNext)
}