import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../utils'
import moment from 'moment';

export const search = (query) => {
  const { PurchaseOrder, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/ProductReceipts`,
    filters
    && {
      ...filters,
      FromDate: From,
      ToDate: To
    },
    { BatchSize, BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '' }, 'Inline')
    .then((result) => {
      if (result) {
        if (result.ProductReceipts) {
          return {
            records: result.ProductReceipts.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}