import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isDirty, reset, change, touch, getFormSyncErrors } from 'redux-form';
import ServicePatternDetailForm from './ServicePatternDetail';
import { IApplicationState } from 'ducks/reducers';
import IServicePatternDetailProperties, { IActionBarHandlers } from './ServicePatternDetail.properties';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as servicePatternActions } from 'ducks/servicePatternMaintenance/servicePattern';
import * as servicePatternApi from 'api/servicePatternMaintenance/servicePattern';
import { fetchJobTemplateOptions } from 'api/jobTemplateMaintenance/jobTemplate';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';

const FORM_NAME = 'ServicePatternDetail';
const mapStateToProps = (state: IApplicationState) => ({
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  operationMode: operationSelectors.operationMode(state),
  dirty: isDirty(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    touchFormFields: (...args: any) => touch(FORM_NAME, ...args),
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
    changeOperationMode: operationActions.changeOperationMode,
    performSearch: servicePatternActions.search,
    performSearchById: servicePatternActions.searchById,
  },
  dispatch);

type IServicePatternDetail = Readonly<Omit<
  IServicePatternDetailProperties,
  'classes' | 'createServicePattern' | 'deleteServicePattern' | 'updatedServicePattern'>>;

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: IServicePatternDetail) => {
  return (<ServicePatternDetailForm
    createServicePattern={servicePatternApi.createServicePattern}
    deleteServicePattern={servicePatternApi.deleteServicePattern}
    updatedServicePattern={servicePatternApi.updatedServicePattern}
    fetchJobTemplateOptions={fetchJobTemplateOptions}
    {...props} />);
});

export default React.forwardRef(
  (props: Omit<IServicePatternDetail, 'operationMode' | 'changeOperationMode'>, ref: React.Ref<IActionBarHandlers>) => {

    return <ConnectedComponent {...props
    } forwardedRef={ref} />;
  });
