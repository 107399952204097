import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { IInvoiceLineMovementsSearchResponse } from 'api/supplierInvoiceEnquiry/interfaces';
import { ISupplierInvoiceEnquiryLineMovementFacade } from 'api/swaggerTypes';

export interface IInvoiceLinesMovementsData {
  selected: ISupplierInvoiceEnquiryLineMovementFacade;
  gridOptions: IDataGridOptions;
  Actions: any;
  lineColumns: IColDef[];
  lines: ISupplierInvoiceEnquiryLineMovementFacade[];
}

export interface IInvoiceLinesMovementsState extends IExtendedState<IInvoiceLinesMovementsData> {
}

const initialData: IInvoiceLinesMovementsData = {
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true
  },
  Actions: {
  },
  lineColumns: [
    { headerName: 'Date', field: 'Date', suppressSorting: true, minWidth: 150 },
    { headerName: 'Movement', field: 'MovementType', suppressSorting: true },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Balance', field: 'BalanceDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Reference', field: 'Reference', suppressSorting: true },
    { headerName: 'Financial period', field: 'FinancialPeriod', suppressSorting: true },
    { headerName: 'Transaction', field: 'TransactionNumber', suppressSorting: true },
    { headerName: 'User ID', field: 'UserId', suppressSorting: true },
    { headerName: 'User Name', field: 'Username', suppressSorting: true },
    { headerName: 'Cost each', field: 'CostEachDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Total cost', field: 'TotalCostDisplay', type: 'numericColumn', suppressSorting: true }
  ],
  lines: []
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'supplierInvoiceLinesMovements',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (row: ISupplierInvoiceEnquiryLineMovementFacade) => (row),
      callback: (data: IInvoiceLinesMovementsData, row: ISupplierInvoiceEnquiryLineMovementFacade) => ({ ...data, selected: row })
    },
    clearLines: {
      action: () => null,
    }
  },
  asyncActions: {
    fetch: {
      action: (query: { DocumentNumber: number; LineNumber: number; Page?: number; Sort?: string; SortDirection?: string }) => query,
      successCallback: (data: IInvoiceLinesMovementsData, payload: IInvoiceLineMovementsSearchResponse) => ({ ...data, lines: payload && payload.records })
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesMovements: IInvoiceLinesMovementsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesMovements,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
  }
);

const syncSelector = {
  isLoading: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesMovements: IInvoiceLinesMovementsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesMovements.loading,
  gridOptions: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesMovements: IInvoiceLinesMovementsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesMovements.data.gridOptions,
  lineColumns: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesMovements: IInvoiceLinesMovementsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesMovements.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
