import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CashDetailsForm.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import ICashDetailsFormProps from './CashDetailsForm.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import TextField from '@markinson/uicomponents-v2/TextField';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';

const DEFAULT_RENDER_DELAY = 10;

const CashDetailsForm = (props: ICashDetailsFormProps): JSX.Element => {
  const { classes, open, initialValues, schema, onApply, onCancel } = props;

  const [amount, setAmount] = React.useState<number>(0);
  const [tenderedAmount, setTenderedAmount] = React.useState<number>(0);
  const [reference, setReference] = React.useState<string>('');

  const tenderedFieldRef = React.useRef<ITextFieldHandle>();

  React.useEffect(
    () => {
      setTimeout(
        () => {
          if (open && tenderedFieldRef.current) {
            tenderedFieldRef.current.focus();
          }
        },
        DEFAULT_RENDER_DELAY
      );
    },
    [open]
  );

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>, values: any): void {

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      event.currentTarget.blur();
      if (validation() && onApply) {
        onApply({
          ...initialValues,
          ...values
        });
      }
    }
  }

  function validation(): boolean {
    return !((schema.Amount.Required && amount === undefined) || (amount !== undefined && amount <= 0) ||
      (schema.TenderedAmount.Required && !tenderedAmount) || (tenderedAmount !== undefined && tenderedAmount <= 0) ||
      (schema.Reference.Required && !reference));
  }

  React.useEffect(
    () => {
      if (initialValues) {
        setAmount(initialValues.Amount ? initialValues.Amount : 0);
        setTenderedAmount(initialValues.TenderedAmount ? initialValues.TenderedAmount : 0);
        setReference(initialValues.Reference);
      } else {
        setAmount(0);
        setTenderedAmount(0);
        setReference('');
      }
    },
    [initialValues]);

  const getModalContent = () => {
    return <div className={classes.mainDiv}>
      <NumericField
        value={tenderedAmount}
        label={'Tendered'}
        minValueExclusive={0}
        onBlur={(value) => {
          setTenderedAmount(value);
          if (value < amount) {
            setAmount(value);
          }
        }}
        onKeyDown={(e: any) => {
          handleKeyDown(e, {
            Amount: e.currentTarget.value < amount ? e.currentTarget.value : amount,
            TenderedAmount: e.currentTarget.value,
            Reference: reference
          });
        }}
        required={schema.TenderedAmount.Required}
        ref={tenderedFieldRef} />
      <NumericField
        value={amount}
        label={'Payment Amount'}
        decimalPlaces={schema.Amount.MinimumFractionDigits}
        minValueExclusive={0}
        onBlur={(value) => {
          setAmount(value);
          if (value > tenderedAmount) {
            setTenderedAmount(value);
          }
        }}
        onKeyDown={(e: any) => {
          handleKeyDown(e, {
            Amount: e.currentTarget.value,
            TenderedAmount: e.currentTarget.value > tenderedAmount ? e.currentTarget.value : tenderedAmount,
            Reference: reference
          });
        }}
        required={schema.Amount.Required} />
      <TextField
        value={reference}
        label={'Reference'}
        onBlur={(value) => {
          if (value !== reference) {
            setReference(value);
          }
        }}
        onKeyDown={(e: any) => {
          handleKeyDown(e, {
            Amount: amount,
            TenderedAmount: tenderedAmount,
            Reference: e.currentTarget.value
          });
        }}
        required={schema.Reference.Required} />
    </div >;
  };

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        disabled: !validation(),
        listener: async () => {
          if (onApply) {
            onApply({
              ...initialValues,
              Amount: amount,
              TenderedAmount: tenderedAmount,
              Reference: reference
            });
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }];
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Cash Details'}
    onKeyDown={(e) => {
      handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, {
        Amount: amount,
        TenderedAmount: tenderedAmount,
        Reference: reference
      });
    }}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(CashDetailsForm);
