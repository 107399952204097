export const DATAGRIDCONSTANTS = {
    header: {
        Document: 130,
        SalesEntity: 50,
        Entered: 87,
        UserId: 65,
        Expiry: 87,
        Description: 291,
        DeliveryName: 291,
    }
};
