
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as balancesSelectors } from 'ducks/customerEnquiry/balances';
import { selectors as transactionSelectors } from 'ducks/customerEnquiry/transactions';
import { selectors as diarySelectors } from 'ducks/common/diary';

import CustomerFinancial from './Financial';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedCustomer: customerSelectors.selected(state),
  balanceDetails: balancesSelectors.balanceDetails(state),
  transactionDetails: transactionSelectors.details(state),
  transactionSummary: transactionSelectors.summary(state),
  loadingTransactionSummary: transactionSelectors.loadingSummary(state),
  selectedDiary: diarySelectors.selected(state),
});

export default connect(mapStateToProps, {})(CustomerFinancial);
