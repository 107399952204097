import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../utils';

import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IChildAccountsData {
  selected: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface IChildAccountsState extends IExtendedState<IChildAccountsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {

    }
  },
  'customerChildAccounts');

const initialState = asyncInitialState<IChildAccountsData>({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Columns: [
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer && row.data.CustomerDisplay) {
          return {
            CustomerId: row.data.Customer,
            CustomerAccountNumber: row.data.CustomerDisplay,
            FieldValue: row.data.CustomerDisplay
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry/customer-details'
      }
    },
    { headerName: 'Name', field: 'Name', minWidth: 180 },
    { headerName: 'City', field: 'City', minWidth: 180 },
    { headerName: 'State', field: 'State', minWidth: 180 },
  ]
});

export default (state: IChildAccountsState = initialState, action: IDataAction): IChildAccountsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { customerEnquiry: { childAccounts: IChildAccountsState } }) => state.customerEnquiry.childAccounts.data.selected,
  gridOptions: (state: { customerEnquiry: { childAccounts: IChildAccountsState } }) => state.customerEnquiry.childAccounts.data.gridOptions,
  columns: (state: { customerEnquiry: { childAccounts: IChildAccountsState } }) => state.customerEnquiry.childAccounts.data.Columns,
});
