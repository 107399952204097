import React from 'react';
import { usePSContextSelector, usePSDispatch } from './PickSlips.context';
import { LostSaleDialogState, LostSalesType } from 'components/common/LostSalesDialog/LostSalesDialog.properties';
import { PickSlipsActionTypes } from './PickSlips.actions';
import { IState, initialState } from './PickSlips.reducer';
import { useGetUpdateGridLineCachedData, useGetUpdateLineCachedData, useInitializeLineLostSale } from 'api/pickSlips/slipLinesUpdated';
import { ILineLostSaleExtended } from './PickSlips.properties';
import { PriceOverrideScreenType } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { ILinesHandle } from './Lines/Lines.properties';
import { isScreenRequest, isSuccess } from 'api/utils';
import { ILineDetailsHandle } from './LineDetails/LineDetails.properties';
import { useRemovePickSlip } from 'api/pickSlips/pickslip';
import { ISummaryHandle } from './SummaryPanel/SummaryPanel.properties';
import { RouteComponentProps } from 'react-router';
import { IBOMDialogStete } from 'components/common/BOMDialog/BOMDialog.properties';

export enum PickSlipAPIType {
    deleteGridLine = 'deleteGridLine',
    updateGridLine = 'updateGridLine',
    updateLine = 'updateLine',
    removePickSlip = 'removePickSlip'
}
export const useLostSale = () => {
    const contextDispatch = usePSDispatch();
    const dialogStates = usePSContextSelector<'lostSaleDialog'>((state) => state.lostSaleDialog);

    const getUpdateGridLineCachedData = useGetUpdateGridLineCachedData();
    const getUpdateLineCachedData = useGetUpdateLineCachedData();
    const initializeLineLostSaleMutation = useInitializeLineLostSale();
    const removePickSlipMutation = useRemovePickSlip();

    const setDialogState = React.useCallback(
        (dialoge: LostSaleDialogState<ILineLostSaleExtended, PickSlipAPIType>) => contextDispatch({
            [PickSlipsActionTypes.setLostSaleDialog]: dialoge
        }),
        [contextDispatch]
    );

    const initiateLostSale = React.useCallback(
        async (apiType: PickSlipAPIType, DespatchId: number, LineNumber?: number, DespatchLineId?: number, Data?: ILineLostSaleExtended) => {
            switch (apiType) {
                case PickSlipAPIType.updateLine:
                case PickSlipAPIType.updateGridLine:
                case PickSlipAPIType.deleteGridLine: {
                    const response = await initializeLineLostSaleMutation.mutateAsync({
                        DespatchId,
                        LineNumber
                    });

                    setDialogState({
                        open: true,
                        data: { ...response?.PickSliplineLostsale?.inlineObject, DespatchId, LineNumber, DespatchLineId },
                        type: LostSalesType.DeleteSaleLine,
                        apiType: apiType
                    });
                    break;
                }
                case PickSlipAPIType.removePickSlip: {

                    setDialogState({
                        open: true,
                        data: { ...Data, DespatchId: DespatchId, LineNumber: LineNumber, DespatchLineId: DespatchLineId },
                        type: LostSalesType.CancelSale,
                        apiType: apiType
                    });
                    break;
                }
                default:
            }
        },
        [setDialogState]
    );

    const closeModal = React.useCallback(
        () => {
            setDialogState(initialState.lostSaleDialog);
        },
        []
    );

    const onSubmit = React.useCallback(
        async (
            apiType: PickSlipAPIType,
            data: ILineLostSaleExtended,
            lineRef: React.MutableRefObject<ILinesHandle>,
            lineDetailsPageRef: React.MutableRefObject<ILineDetailsHandle>,
            summaryRef: React.MutableRefObject<ISummaryHandle>
        ) => {
            switch (apiType) {
                case PickSlipAPIType.deleteGridLine: {
                    const response = await lineRef.current?.deleteLine({
                        DespatchId: data.DespatchId,
                        LineNumber: data.LineNumber,
                        DespatchLineLostSale: data,
                        RecordLostSale: true,
                        LeaveProductOnOrder: false
                    });
                    if (response?.Status === true) {
                        closeModal();
                        lineRef.current?.refreshDataGrid();
                    }

                    break;
                }
                case PickSlipAPIType.updateGridLine: {

                    const updateResponse = getUpdateGridLineCachedData(data?.DespatchId, data?.LineNumber);
                    const response = await lineRef.current?.updateLine({
                        ...updateResponse?.DespatchGridLineCriteria?.inlineObject,
                        DespatchId: data.DespatchId,
                        LineNumber: data.LineNumber,
                        LostSale: data,
                    });
                    if (response?.Status === true) {
                        closeModal();
                        lineRef.current?.refreshDataGrid();
                    }

                    break;
                }
                case PickSlipAPIType.updateLine: {

                    const updateResponse = getUpdateLineCachedData(data?.DespatchId, data?.DespatchLineId);
                    const response = await lineDetailsPageRef.current?.ok(
                        {
                            ...updateResponse?.PickSlipLineDetails?.inlineObject,
                            LostSale: data,
                        }
                    );

                    if (response?.Status === true) {
                        closeModal();
                    }

                    break;
                }
                case PickSlipAPIType.removePickSlip: {

                    const removeResponse = await removePickSlipMutation.mutateAsync
                        ({
                            RecordLostSale: true,
                            LostSaleDetails: data,
                            PlaceOnOrder: false,
                            CancelKit: false,
                            DespatchId: data?.DespatchId
                        });

                    if (removeResponse?.Status === true) {
                        closeModal();
                        lineRef.current?.refreshDataGrid();
                        summaryRef.current.reloadSummary();
                    }

                    break;
                }
                default:
            }
        },
        [closeModal]
    );

    const isLostSale = React.useCallback(
        (forms) => isScreenRequest(forms, 'WorksalelineLostsaleScreen') || isScreenRequest(forms, 'LostSale'),
        []
    );

    return {
        dialogStates,
        onSubmit,
        initiateLostSale,
        closeModal,
        isLostSale
    };
};

export const usePriceOverride = () => {

    const dialogStates = usePSContextSelector<'PriceOverrideDialog'>((state) => state.PriceOverrideDialog);
    const contextDispatch = usePSDispatch();
    const getUpdateGridLineCachedData = useGetUpdateGridLineCachedData();
    const getUpdateLineCachedData = useGetUpdateLineCachedData();

    const closeDialog = React.useCallback(
        () => {
            contextDispatch({
                setPriceOverrideDialog: initialState.PriceOverrideDialog
            });
        },
        [contextDispatch]
    );

    const isPriceOverrideScreenRequest = (form: any) => form?.FormId === PriceOverrideScreenType.UserAuthAndReasonScreen.toString() ||
        form?.FormId === PriceOverrideScreenType.ReasonScreen.toString() ||
        form?.FormId === PriceOverrideScreenType.UserAuthScreen.toString();

    const setPriceOverrideState = React.useCallback(
        (data: IState['PriceOverrideDialog']) => contextDispatch({
            setPriceOverrideDialog: data
        })
        ,
        [contextDispatch]
    );

    const onSubmit = React.useCallback(
        async (
            apiType: PickSlipAPIType,
            data: ILineLostSaleExtended,
            lineRef: React.MutableRefObject<ILinesHandle>,
            lineDetailsPageRef: React.MutableRefObject<ILineDetailsHandle>
        ) => {
            switch (apiType) {
                case PickSlipAPIType.updateGridLine: {
                    const updateResponse = getUpdateGridLineCachedData(data?.DespatchId, data?.LineNumber);
                    const response = await lineRef.current?.updateLine({
                        ...updateResponse?.DespatchGridLineCriteria?.inlineObject,
                        PriceOverride: data,
                        OverridePrice: true,
                        DespatchId: data.DespatchId,
                        LineNumber: data.LineNumber,
                    });

                    if (response?.Status === true) {
                        closeDialog();
                        lineRef.current?.refreshDataGrid();
                    }
                    break;
                }
                case PickSlipAPIType.updateLine: {
                    const updateResponse = getUpdateLineCachedData(data?.DespatchId, data?.DespatchLineId);
                    const response = await lineDetailsPageRef.current?.ok(
                        {
                            ...updateResponse?.PickSlipLineDetails?.inlineObject,
                            OverridePrice: true,
                            PriceOverride: data,
                        }
                    );

                    if (response?.Status === true) {
                        closeDialog();
                    }
                    break;
                }
                default:
            }
        },
        [closeDialog]
    );

    return {
        dialogStates,
        closeDialog,
        isPriceOverrideScreenRequest,
        setPriceOverrideState,
        onSubmit
    };
};

export const useTabLocations = (url: string, location: RouteComponentProps['location']) => {

    const contextDispatch = usePSDispatch();
    const DespatchId = usePSContextSelector<'DespatchId'>((state) => state.DespatchId);
    const PSLineNumber = usePSContextSelector<'LineNumber'>((state) => state.LineNumber);
    const DespatchLineId = usePSContextSelector<'DespatchLineId'>((state) => state.DespatchLineId);
    const ComponentLineNumber = usePSContextSelector<'ComponentLineNumber'>((state) => state.ComponentLineNumber);
    const ComponentId = usePSContextSelector<'ComponentId'>((state) => state.ComponentId);

    const tabLocations = React.useMemo(
        () => {

            return ({
                Lines: {
                    pathname: url,
                    search: `?DespatchId=${DespatchId}`
                },
                SalesDiary: `${url.endsWith('/') ? url : `${url}/`}sales-diary`,
                SalesDiaryDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}sales-diary-details`,
                    search: `?DespatchId=${DespatchId}`
                },
                SalesNotepad: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}notepad`,
                    search: `?DespatchId=${DespatchId}`
                },
                Details: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}details`,
                    search: `?DespatchId=${DespatchId}`
                },
                Contact: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}contact`,
                    search: `?DespatchId=${DespatchId}`
                },
                LineDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}line-details`,
                    search: `?DespatchId=${DespatchId}&LineNumber=${PSLineNumber}`
                },
                KitComponents: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}line-details/kit-components`,
                    search: `?DespatchId=${DespatchId}&LineNumber=${PSLineNumber}&DespatchLineId=${DespatchLineId}`
                },
                ComponentDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}line-details/kit-components/component-details`,
                    search: `?DespatchId=${DespatchId}&LineNumber=${PSLineNumber}&DespatchLineId=${DespatchLineId}&ComponentLineNumber=${ComponentLineNumber}&ComponentId=${ComponentId}`
                },
            });
        },
        [url, DespatchId, PSLineNumber, DespatchLineId, ComponentLineNumber, ComponentId]
    );

    React.useEffect(
        () => {
            const params = (new URLSearchParams(location.search));
            const DespatchIdParam = params.get('DespatchId') || '';
            const LineNumberParam = params.get('LineNumber') || '';
            const DespatchLineIdParam = params.get('DespatchLineId') || '';
            const ComponentLineNumberParam = params.get('ComponentLineNumber') || '';
            const ComponentIdParam = params.get('ComponentId') || '';

            contextDispatch({
                setDespatchId: Number(DespatchIdParam),
                setLineNumber: Number(LineNumberParam),
                setDespatchLineId: Number(DespatchLineIdParam),
                setComponentLineNumber: Number(ComponentLineNumberParam),
                setComponentId: Number(ComponentIdParam),
            });
        },
        [contextDispatch]
    );

    return {
        tabLocations
    };
};

export const useMultipleBom = () => {
    const contextDispatch = usePSDispatch();
    const multipleBomDialog = usePSContextSelector<'multipleBomDialog'>((state) => state.multipleBomDialog);
    const selectedDespatchId = usePSContextSelector<'DespatchId'>((state) => state.DespatchId);
    const getUpdateGridLineCachedData = useGetUpdateGridLineCachedData();

    const setMultipleBomState = React.useCallback(
        (state: IBOMDialogStete) => {
            contextDispatch({ setMultipleBomState: { ...multipleBomDialog, ...state } });
        },
        [multipleBomDialog]
    );

    const closeDialog = React.useCallback(
        () => {
            contextDispatch({ setMultipleBomState: { open: false } });
        },
        []
    );

    const onBomSelectedRowChanged = React.useCallback(
        (bomCode: string) => {
            contextDispatch({ setMultipleBomState: { ...multipleBomDialog, bomCode } });
        },
        [multipleBomDialog]
    );

    const OnActionClick = React.useCallback(
        async (status, lineDetailRef: React.MutableRefObject<ILineDetailsHandle>, linesRef: React.MutableRefObject<ILinesHandle>) => {

            if (status) {
                if (multipleBomDialog?.screen === 'LineDetails' && multipleBomDialog?.action === 'Components') {
                    lineDetailRef.current?.components({ KitStatus: status, BomCode: multipleBomDialog?.bomCode });
                } else if (multipleBomDialog?.screen === 'LineDetails') {
                    lineDetailRef.current?.ok({ KitStatus: status, BomCode: multipleBomDialog?.bomCode });
                } else {
                    const updateGridLineResponse = getUpdateGridLineCachedData(selectedDespatchId, multipleBomDialog?.LineNumber);
                    const despatchGridLineCriteriaInline = updateGridLineResponse?.DespatchGridLineCriteria?.inlineObject;

                    const response = await linesRef.current?.updateLine({
                        LineNumber: multipleBomDialog?.LineNumber,
                        DespatchId: selectedDespatchId,
                        ...despatchGridLineCriteriaInline,
                        OpenInvoiceLine: {
                            ...despatchGridLineCriteriaInline?.OpenInvoiceLine,
                            KitStatus: status,
                            BomCode: multipleBomDialog?.bomCode
                        }
                    });

                    if (isSuccess(response)) {
                        closeDialog();
                        linesRef.current?.refreshDataGrid();
                    }
                }
            } else {
                closeDialog();
            }
        },
        [multipleBomDialog, selectedDespatchId, closeDialog]
    );

    return {
        multipleBomDialog,
        setMultipleBomState,
        closeDialog,
        onBomSelectedRowChanged,
        OnActionClick
    };
};
