import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    serviceAgreementItemContainer: {
        maxWidth: 700,
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        padding: 25,
        gridRowGap: 5
    },
    fullWidth: {
        gridColumn: '1 / span 2'
    },
    lookupField: {
        paddingBottom: 0,
    },
    heading: {
        fontSize: 'larger',
        display: 'flex',
        flexDirection: 'column',
        gap: '11px',
        color: '#777777',
        margin: '20px 0px'
    }
});
