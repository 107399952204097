import {
  createActions, asyncInitialState, asyncSelectors,
  IDataAction, IExtendedState
} from '../utils';

import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface ISupplierDetailsTransactionsData {
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  columns: IColDef[];
  Data: any[];
}

export interface ISupplierDetailsTransactionsState extends IExtendedState<ISupplierDetailsTransactionsData> {
}

export const { types, actions } = createActions(
  {
    clearLines: () => null,
    asyncs: {
      search: (Data) => (Data),
    }
  },
  'supplierDetailsTransactions');

const initialState = asyncInitialState<ISupplierDetailsTransactionsData>({
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  columns: [
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 120, },
    { headerName: 'Entity', field: 'Entity', minWidth: 120, suppressSorting: true },
    {
      headerName: 'Invoice', field: 'Invoice', type: 'numericColumn', minWidth: 120
    },
    { headerName: 'Transaction type', field: 'TransactionType', minWidth: 150, suppressSorting: true },
    {
      headerName: 'Transaction', type: 'numericColumn',
      field: 'TransactionNumber',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice) {
          return {
            TransactionNumber: row.data.TransactionNumber,
            Supplier: row.data.Supplier,
            FieldValue: row.data.TransactionNumber
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-enquiry/financial/transactions/transaction-details'
      }
    },
    { headerName: 'Original total', field: 'OriginalTotalDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true },
    { headerName: 'Financial period', field: 'Period', minWidth: 250, suppressSorting: true },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true },
    { headerName: 'Local original', field: 'LocalOriginalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Local amount', field: 'LocalAmountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true }
  ],
  Data: []
});

export default (state: ISupplierDetailsTransactionsState = initialState, action: IDataAction): ISupplierDetailsTransactionsState => {
  switch (action.type) {
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { supplierEnquiry: { supplierDetailsTransactions: ISupplierDetailsTransactionsState } }) => state.supplierEnquiry.supplierDetailsTransactions,
  {
    Actions: (data) => data.Actions,
    Data: (data) => data.Data,
  }
);

const syncSelector = {
  isLoading: (state: { supplierEnquiry: { supplierDetailsTransactions: ISupplierDetailsTransactionsState } }) => state.supplierEnquiry.supplierDetailsTransactions.loading,
  gridOptions: (state: { supplierEnquiry: { supplierDetailsTransactions: ISupplierDetailsTransactionsState } }) => state.supplierEnquiry.supplierDetailsTransactions.data.gridOptions,
  columns: (state: { supplierEnquiry: { supplierDetailsTransactions: ISupplierDetailsTransactionsState } }) => state.supplierEnquiry.supplierDetailsTransactions.data.columns,
  Actions: (state: { supplierEnquiry: { supplierDetailsTransactions: ISupplierDetailsTransactionsState } }) => state.supplierEnquiry.supplierDetailsTransactions.data.Actions,
};

export const selectors = { ...asyncSelector, ...syncSelector };
