import * as React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';

const toggleStyles = createStyles({
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
});

export interface IToggleViewProperties extends StyledComponentProps {
  type: string;
  value?: boolean;
}

class ToggleView extends React.PureComponent<IToggleViewProperties> {
  render(): React.ReactNode {
    const { classes, value } = this.props;

    return (
      <FormControlLabel
        label={''}
        control={
          <Switch
            color={'default'}
            checked={!!value}
            disabled={true}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              bar: classes.bar,
            }}
          />
        }
      />
    );
  }
}

const Toggle = withStyles(toggleStyles, {index: 1})(ToggleView);

export default (props: IToggleViewProperties) => {
  const { type } = props;
  switch (type) {
    case 'Toggle':
      return <Toggle {...props} />;
    default:
      return null;
  }
};
