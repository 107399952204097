import { createReducer } from 'utils/helpers';
import { DynamicExtractActionTypes, IActions } from './RunDynamicExtract.actions';
import { IWizardFormDataFacade } from 'api/swaggerTypes';

export interface IState {
    WizardDetails: IWizardFormDataFacade;
    ErrorState: { Panel: string; InError: boolean };
    CurrentPanel: string;
}

export const initialState: IState = {
    WizardDetails: {
        BatchPeriodHandling: 'C',
        BatchDateHandling: 'C'
    },
    ErrorState: { Panel: null, InError: false },
    CurrentPanel: null
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<DynamicExtractActionTypes, IAction>;

const actionMap: IActionMap = {
    [DynamicExtractActionTypes.setWizardDetails]: (state: IState, payload: IState['WizardDetails']): IState => ({ ...state, WizardDetails: payload }),
    [DynamicExtractActionTypes.setErrorState]: (state: IState, payload: IState['ErrorState']): IState => ({ ...state, ErrorState: payload }),
    [DynamicExtractActionTypes.setCurrentPanel]: (state: IState, payload: IState['CurrentPanel']): IState => ({ ...state, CurrentPanel: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
