import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as extractMaintenanceActions, selectors as extractMaintenanceSelectors } from 'ducks/extractMaintenance/extractMaintenanceTemplates';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

interface IPSEItem {
  TemplateName: string;
  Description: string;
  TemplateId: number;
  Name: string;
  '': string;
}

const EMSearchLookUpDrawer = buildSearchLookUpDrawer<IPSEItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IPSEItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IPSEItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps =>  (
  {
    variant: 'ExtractMaintenance',
    searchPlaceholder: 'Search for template...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'Name',
        label: 'Template name'
      },
      {
        filter: 'Description',
        label: 'Description'
      },
    ],
    records: extractMaintenanceSelectors.all(state) || [],
    selectedRecord: extractMaintenanceSelectors.selected(state),
    selectedSortFilter: 'Name',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isExtractMaintenanceLookUpOpen(state),
    isLoading: extractMaintenanceSelectors.isLoading(state),
    nextPage: extractMaintenanceSelectors.nextPage(state),
    prevPage: extractMaintenanceSelectors.prevPage(state),
    pageSize: extractMaintenanceSelectors.pageSize(state),
    totalPages: extractMaintenanceSelectors.totalPages(state),
    loadingPrevPage: extractMaintenanceSelectors.loadingPrevPage(state),
    loadingNextPage: extractMaintenanceSelectors.loadingNextPage(state),
    keyField: 'TemplateId',
    fieldsToDisplay: ['Name', '', '', 'Description'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('TemplateId', selectedRecord.TemplateId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const TemplateId = parseInt(matchParams.get('TemplateId'));
      if (TemplateId) {
        return {
          id: TemplateId,
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: extractMaintenanceActions.search,
  searchById: extractMaintenanceActions.searchById,
  changeSelectedRecord: extractMaintenanceActions.setSelected,
  onToggle: uiActions.toggleExtractMaintenanceLookUp,
  fetchNextPage: extractMaintenanceActions.fetchNextPage,
  fetchPrevPage: extractMaintenanceActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EMSearchLookUpDrawer);
