import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './Delivery.styles';
import FormView from 'components/FormView';
import DeliveryDetailsForm from './Delivery.form';
import IDeliveryDetailProperties, { IDeliveryDetailsHandle } from './Delivery.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const Delivery = (props: IDeliveryDetailProperties, ref: React.Ref<IDeliveryDetailsHandle>) => {
    const { classes, formValues, dirty, changeConfirmationDialog, resetForm } = props;

    const { setActionBar } = React.useContext(ActionBarContext);

    const { showSnackBar } = useSnackBar();
    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const isDirty = internalDirty || dirty;

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        []
    );

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (isDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !isDirty;
            },
            async ok(navigateBack: () => void): Promise<void> {
                showSnackBar({ variant: 'warning', message: 'Changes Saved' });
                setInternalDirty(false);
                navigateBack();

            },
            onCancel(navigateBack: () => void): void {
                if (isDirty) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: () => {
                            setInternalDirty(false);
                            resetForm();
                            navigateBack();
                        }
                    });
                } else {
                    resetForm();
                    navigateBack();
                }
            }
        }),
        [formValues, isDirty]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                    action: 'DeliveryDetailsCancel',
                },
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    action: 'DeliveryDetailsOk',
                },
            ]
        });
    };

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={false}
                formName={'DeliveryDetailsForm'}
                schema={DeliveryDetailsForm}
                includeTabsHeight={false}
                initialValues={{}}
                valuesSchema={{}}
                operationMode={'EDIT'}
                fieldExtensions={{
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Delivery)));
