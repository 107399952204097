import { connect } from 'react-redux';
import ProcessPickSlipDialogs from './ProcessPickSlipDialogs';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import React from 'react';
import { IProcessPickSlipDialogsHandle, IProcessPickSlipDialogsProperties } from './ProcessPickSlipDialogs.properties';

const params = new URLSearchParams(location.search);

const mapStateToProps = (state) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
});

const ConnectedComponent = connect(mapStateToProps, null)(ProcessPickSlipDialogs);

export default React.forwardRef((props: Omit<IProcessPickSlipDialogsProperties, 'forwardedRef'>, ref: React.Ref<IProcessPickSlipDialogsHandle>) => (
    <ConnectedComponent {...props} forwardedRef={ref} />
));
