import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as ServiceActivitySchedulingActions, selectors as serviceActivitySchedulingSelectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as staffSelectors } from 'ducks/staff';

import ServiceActivityScheduling from './ServiceActivityScheduling';
import { IApplicationState } from 'ducks/reducers';
import { IServiceActivitySchedulingProperties } from './interfaces';

type IStateProps = Pick<IServiceActivitySchedulingProperties,
  'isMenuOptionOpen' | 'selectedTab' | 'selectedForm' | 'operationMode' | 'staff' | 'schedulerData' | 'salesEntity'
>;
type IDispatchProps = Pick<IServiceActivitySchedulingProperties,
  'toggleMenuOption' | 'getFormSchema' | 'changeSelectedForm' | 'changeOperationMode'
  | 'toggleBrowseLookUp' | 'changeSelectedTab' | 'getSchedulerResources'
  | 'setDefaultSalesEntity' | 'getSelectedJobDetails' | 'getSchedulerActivities' | 'resetSearchLookupDrawer'
>;

const mapStateToProps = (state: IApplicationState): IStateProps => ({
  operationMode: operationSelectors.operationMode(state),
  selectedForm: formSelectors.selected(state),
  selectedTab: uiSelectors.selectedTab(state),
  staff: staffSelectors.staff(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  salesEntity: serviceActivitySchedulingSelectors.salesEntity(state),
  schedulerData: serviceActivitySchedulingSelectors.schedulerData(state)
});

const actionCreators: IDispatchProps = {
  toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
  changeOperationMode: operationActions.changeOperationMode,
  changeSelectedTab: uiActions.changeSelectedTab,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  toggleMenuOption: uiActions.toggleMenuOption,
  getSchedulerResources: ServiceActivitySchedulingActions.getResources,
  getSchedulerActivities: ServiceActivitySchedulingActions.getScheduledActivities,
  setDefaultSalesEntity: ServiceActivitySchedulingActions.setDefaultSalesEntity,
  getSelectedJobDetails: ServiceActivitySchedulingActions.getSelectedJobDetails,
  resetSearchLookupDrawer: ServiceActivitySchedulingActions.reset,
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceActivityScheduling);
