import { IFormViewForm } from 'components/FormView';

const LostSales: { [x: string]: IFormViewForm } = {
  LostSale: {
    id: 'LostSale',
    layout: {
      rows: 1,
      columns: 2,
    },
    fields: [
      {
        id: '1',
        type: 'PAPER_CONTAINER',
        visible: true,
        props: {
          name: 'activity',
          fullWidth: true,
          elevation: 0
        },
        position: {
          row: 1,
          col: 1
        },
        children: [
          {
            id: 1,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'Lost sales type',
              name: 'LostType',
              lookupName: 'Losttype',
              size: 'small',
              required: true
            }
          },
          {
            id: 2,
            type: 'TEXT_FIELD',
            props: {
              label: 'Reference',
              name: 'Reference',
              size: 'medium'
            },
          },
          {
            id: 3,
            type: 'TEXT_AREA',
            props: {
              label: '',
              name: 'Comment',
              fullWidth: true,
              style: {
                height: 150
              }
            },
          },
          {
            id: 4,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'User ID',
              name: 'LostsaleUserId',
              lookupName: 'Personnel',
              size: 'small',
              required: true
            }
          },
          {
            id: 5,
            type: 'TEXT_FIELD',
            props: {
              label: 'PIN',
              name: 'Pin',
              placeholder: '****',
              size: 'small',
              type: 'password',
              inputProps: {
                maxLength: 4
              },
              required: false
            }
          },
        ]
      }
    ]
  }
};

export default LostSales;
