import { COLUMN_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export const getColumns = (headerFiltersDataSource, classes) => [
    {
        dataField: 'ProductCode',
        caption: 'Product',
    },
    {
        dataField: 'OrderedQuantity',
        caption: 'Ordered',
        width: 105,
        format: '#0.00',
        isEditable: true,
        allowSorting: false,
        allowHeaderFiltering: false,
        shouldFixToDecimalPlaces: true,
    },
    {
        dataField: 'SuppliedQuantity',
        caption: 'Supplied',
        width: 105,
        format: '#0.00',
        isEditable: true,
        allowSorting: false,
        allowHeaderFiltering: false,
        shouldFixToDecimalPlaces: true,
    },
    {
        dataField: 'KittingQuantity',
        caption: 'Kitting',
        width: 105,
        format: '#0.00',
        editorOptions: {
            disabled: true,
        },
        allowHeaderFiltering: false,
        shouldFixToDecimalPlaces: true,
    },
    {
        dataField: 'Price',
        caption: 'Price',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        isEditable: true,
        allowSorting: false,
        allowHeaderFiltering: false,
        shouldFixToDecimalPlaces: true,
    },
    {
        dataField: 'Discount',
        caption: 'Discount %',
        width: COLUMN_SIZE.md0,
        format: "#0.00 '%'",
        isEditable: true,
        allowSorting: false,
        allowHeaderFiltering: false,
    },
    {
        dataField: 'ExtendedPrice',
        caption: 'Extended',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        editorOptions: {
            disabled: true,
        },
        allowHeaderFiltering: false,
        shouldFixToDecimalPlaces: true,
    },
    {
        dataField: 'SiteCode',
        caption: 'Site',
        width: COLUMN_SIZE.md0,
        cssClass: classes?.worksaleLineHeaderFilter,
        format: '#0.00',
        editorOptions: {
            disabled: true,
        },
        headerFiltersDataSource: headerFiltersDataSource?.SiteCode || []
    },
    {
        dataField: 'Description',
        caption: 'Description',
        width: COLUMN_SIZE.xl0,
        editorOptions: {
            disabled: true,
        },
        allowHeaderFiltering: false,
    },
    {
        dataField: 'IsEnableDescription',
        visible: false,
    },
    {
        dataField: 'IsOversellAllowed',
        visible: false,
    },
    {
        dataField: 'LineNumber',
        visible: false,
    },
    {
        dataField: 'LotSerialTrack',
        visible: false,
    },
    {
        dataField: 'OriginalDiscount',
        visible: false,
    },
    {
        dataField: 'OriginalPrice',
        visible: false,
    },
    {
        dataField: 'PriceOverride',
        visible: false,
    },
    {
        dataField: 'ProductId',
        visible: false,
    },
    {
        dataField: 'ProductType',
        visible: false,
    },
    {
        dataField: 'TaxStatus',
        visible: false,
    },
    {
        dataField: 'WorksalelineId',
        visible: false,
    },
];
