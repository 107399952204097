import { connect } from 'react-redux';
import Diary from './Diary';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';
import { selectors as diarySelectors } from 'ducks/common/diary';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedRFC: rfcSelectors.selected(state),
  selectedDiary: diarySelectors.selected(state),
});
export default connect(mapStateToProps)(Diary);
