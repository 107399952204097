import { IState } from './ProductCatalogue.reducer';

export interface IActions {
  [ProductCatalogueActionTypes.setBreadCrumbData]?: IState['breadCrumbData'];
  [ProductCatalogueActionTypes.setAddToSaleOpen]?: IState['addToSaleOpen'];
  [ProductCatalogueActionTypes.setSelectedProduct]?: IState['selectedProduct'];
  [ProductCatalogueActionTypes.setIsSearchOpen]?: IState['isSearchOpen'];
  [ProductCatalogueActionTypes.setSelectedCatalogueId]?: IState['selectedCatalogueId'];
  [ProductCatalogueActionTypes.setAddToSaleLineClicked]?: IState['addToSaleLineClicked'];
}

export enum ProductCatalogueActionTypes {
  setBreadCrumbData = 'setBreadCrumbData',
  setAddToSaleOpen = 'setAddToSaleOpen',
  setSelectedProduct = 'setSelectedProduct',
  setIsSearchOpen = 'setIsSearchOpen',
  setSelectedCatalogueId = 'setSelectedCatalogueId',
  setAddToSaleLineClicked = 'setAddToSaleLineClicked',
}
