import React from 'react';
import DataGrid, { Column, Editing, Scrolling, RowDragging, GroupPanel, RequiredRule, SearchPanel, LoadPanel, Button } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IChecklistsGridProps } from './ChecklistsGrid.properties';
import styles from './ChecklistGrid.styles';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';

const INDEX_COLUMN_WIDTH = 50;
const COMMAND_COLUMN_WIDTH = 75;
const LARGE_COLUMN_MIN_WIDTH = 200;

const ChecklistsGrid = (props: IChecklistsGridProps) => {
    const {
        classes,
        disabled = false,
        checklistsData,
        dataGridRef,
        onUpdate,
        onDelete,
        onReorder,
        onInsert
    } = props;
    const { preventDataGridDefaults } = useGridKeyboardNavigation();

    return (<Paper className={classes.parentContainer} >
        <DataGrid
            disabled={disabled}
            keyExpr={'Sequence'}
            ref={dataGridRef}
            className={classes.checklistQuestionDataGrid}
            onKeyDown={preventDataGridDefaults}
            dataSource={checklistsData}
            allowColumnReordering={true}
            repaintChangesOnly={true}
            remoteOperations={true}
            columnResizingMode={'nextColumn'}
            allowColumnResizing={true}
            onRowInserted={() => { if (onInsert) onInsert(checklistsData); }}
            onRowUpdated={() => { if (onUpdate) onUpdate(checklistsData); }}
            onRowRemoved={() => { if (onDelete) onDelete(checklistsData); }}
            showBorders={false}
            noDataText=''
            sorting={{
                mode: 'none'
            }}
            hoverStateEnabled={true}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={false}
                confirmDelete={false}
            />
            <RowDragging
                allowReordering={true}
                onReorder={(e) => {
                    const visibleRows = e.component.getVisibleRows();
                    const orderedChecklists = [...checklistsData];
                    const toIndex = orderedChecklists.indexOf(visibleRows[e.toIndex].data);
                    const fromIndex = orderedChecklists.indexOf(e.itemData);

                    orderedChecklists.splice(fromIndex, 1);
                    orderedChecklists.splice(toIndex, 0, e.itemData);

                    if (onReorder) {
                        onReorder(orderedChecklists);
                    }
                }}
                showDragIcons={true}
            />
            <Scrolling mode='virtual' />
            <Column
                caption={'#'}
                dataField={'Sequence'}
                allowEditing={false}
                minWidth={INDEX_COLUMN_WIDTH}
                fixed={true}
            />
            <Column
                caption={'Checklist'}
                editorOptions={{
                    maxLength: 100,
                }}
                minWidth={LARGE_COLUMN_MIN_WIDTH}
                calculateCellValue={(data: any) => {
                    return `${data.ChecklistCode} - ${data.ChecklistCodeLabel}`;
                }}
            >
                <RequiredRule />
            </Column>
            <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
                <Button name={'delete'} icon={'trash'} />
            </Column>
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'virtual'} />
            <LoadPanel enabled={false} />
        </DataGrid>
    </Paper>
    );
};

export default withStyles(styles, { index: 1 })(ChecklistsGrid);
