import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as childAccountSelector, actions as childAccountActions } from 'ducks/supplierEnquiry/financial/childAccounts';
import { fetch } from 'api/supplierEnquiry/financial/childAccounts';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SupplierFinancialChildAccounts',
  columnDefs: childAccountSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: childAccountSelector.gridOptions(state),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
    &&
    {
      SupplierId: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: childAccountActions.setSelected
};
