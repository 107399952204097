import { IFormViewForm } from 'components/FormView';

const SlipDetails: IFormViewForm = {
  id: 'SlipDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Delivery type',
            name: 'DeliveryTypeLabel',
            size: 'small',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Site',
            name: 'Site',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            label: '',
            name: 'address',
            type: 'extended'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Freight code',
            name: 'FreightCode',

            size: 'small',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Send via',
            name: 'SendVia',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Carrier',
            name: 'Carrier',

            size: 'large',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Freight account',
            name: 'FreightAccount',
            size: 'medium',
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Shipping',
            name: 'Shipping',
            size: 'large',
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Consignment note',
            name: 'ConsignmentNote',

            size: 'large',
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Cartons',
            name: 'Cartons',
            size: 'small',
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Weight',
            name: 'WeightDisplay',
            size: 'medium',
          }
        },
        {
          id: 8,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Cubic',
            name: 'Cubic',

            size: 'medium',
          }
        },
        {
          id: 9,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Pallets',
            name: 'Pallets',
            size: 'small',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Contact',
            name: 'ContactName',
            size: 'large',
          }
        },
        {
          id: 1,
          type: 'ACTION_FIELD',
          visible: true,
          props: {
            label: 'Phone',
            name: 'Phone',

            action: {
              iconName: 'Phone'
            },
          }
        },
        {
          id: 2,
          type: 'ACTION_FIELD',
          visible: true,
          props: {
            label: 'Fax',
            name: 'Fax',

            action: {
              iconName: 'Phone'
            },
          }
        },
        {
          id: 3,
          type: 'ACTION_FIELD',
          visible: true,
          props: {
            label: 'Mobile',
            name: 'Mobile',
            action: {
              iconName: 'Phone'
            },
          }
        },
        {
          id: 4,
          type: 'ACTION_FIELD',
          visible: true,
          props: {
            label: 'Email',
            name: 'Email',
            size: 'large',

            action: {
              iconName: 'Mail'
            },
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1,
        colspan: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Despatch comments',
            name: 'Comment',
            size: 'large',
            style: {
              height: '250px'
            }
          }
        }
      ]
    },
  ]
};
export default SlipDetails;
