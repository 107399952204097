import { takeLatest, call, Effect, put } from 'redux-saga/effects';

import { types as allocateTypes, actions as allocateActions } from 'ducks/cashReceipting/allocate';
import { actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { actions as uiActions } from 'ducks/ui';
import { callApi } from '../utils';
import * as api from 'api/cashReceipting/cashReceipt';
import { IDataAction } from 'ducks/utils';

function* allocateCashReceipt(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = allocateActions.saga.allocateCashReceipt;

    function* postApiSuccessCallEffect(): IterableIterator<Effect> {
        yield put(uiActions.changeSelectedTab('Allocate'));
    }
    yield callApi(
        call(api.allocateCashReceipt, action.data),
        success,
        failure,
        { postApiSuccessCallEffect, dialogActionCallEffect: allocateCashReceipt }
    );
}

function* getAllocatedCashReceipt(): IterableIterator<Effect> {
    const { success, failure } = allocateActions.saga.getAllocatedCashReceipt;

    yield callApi(
        call(api.getAllocatedCashReceipt),
        success,
        failure
    );
}
function* updateAllocatedCashReceipt(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = allocateActions.saga.updateAllocatedCashReceipt;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        const { success: cashReceiptSuccess } = cashReceiptActions.saga.updateAllocatedCashReceipt;
        yield put(cashReceiptSuccess(response));
    }
    yield callApi(
        call(api.updateAllocatedCashReceipt, action.data),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

export default function* rootSupplierInvoiceSaga(): IterableIterator<Effect> {
    yield takeLatest(allocateTypes.allocateCashReceipt, allocateCashReceipt);
    yield takeLatest(allocateTypes.getAllocatedCashReceipt, getAllocatedCashReceipt);
    yield takeLatest(allocateTypes.updateAllocatedCashReceipt, updateAllocatedCashReceipt);
}
