import React from 'react';
import FormView from 'components/FormView';
import ServicePatternDetailForm from './ServicePatternDetail.form';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import Cancel from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import Edit from '@markinson/uicomponents-v2/SvgIcons/Edit';
import RemoveCircle from '@markinson/uicomponents-v2/SvgIcons/RemoveCircle';
import AddCircle from '@markinson/uicomponents-v2/SvgIcons/AddCircle';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import IServicePatternDetailProperties from './ServicePatternDetail.properties';
import { IJobtemplateSearchFacade, IServiceItemTypeFacade, IServicePatternDetailsFacade, IServicePatternItemDetailsFacade } from 'api/swaggerTypes';
import { Operation } from 'utils/operations';
import { isNull, pathOr } from 'utils/utils';
import DataGrid, { Column, Editing, Scrolling, GroupPanel, SearchPanel, Button, LoadPanel, RowDragging, RequiredRule } from 'devextreme-react/data-grid';
import styles from './ServicePatternDetail.styles';
import SearchScreen from '@markinson/uicomponents-v2/SearchScreen';
import { cloneDeep } from 'lodash';
import { Paper, withStyles } from '@material-ui/core';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const INDEX_COLUMN_WIDTH = 50;
const COMMAND_COLUMN_WIDTH = 75;
const LARGE_COLUMN_MIN_WIDTH = 200;
const LOADING_INDICATOR_HEIGHT_WIDTH = 50;

const ServicePatternDetail = (props: IServicePatternDetailProperties): JSX.Element => {
  const {
    isLoading = false, operationMode, selectedServPattern, forwardedRef, formValues, gridData, dirty, classes, formSyncErrors, changeFormFieldValue, resetForm,
    deleteServicePattern, createServicePattern, performSearch, fetchJobTemplateOptions, performSearchById,
    updatedServicePattern, changeConfirmationDialog, touchFormFields, changeOperationMode
  } = props;

  const [initialValues, setInitialValues] = React.useState<IServiceItemTypeFacade>();
  const [openSearchScreen, setOpenSearchScreen] = React.useState<boolean>(false);
  const [addJobTemplateLoading, setAddJobTemplateLoading] = React.useState<boolean>(false);
  const [jobTemplateData, setJobTemplateData] = React.useState<IJobtemplateSearchFacade[]>([]);
  const { setActionBar } = React.useContext(ActionBarContext);
  const processValidationForms = React.useContext(ProcessValidationFormsContext);
  const [servicePatternItemsModified, setServicePatternItemsModified] = React.useState<boolean>(false);
  const [servicePatternItemsData, setServicePatternItemsData] = React.useState<IServicePatternItemDetailsFacade[]>([]);
  const inEditNew = operationMode === Operation.NEW || operationMode === Operation.EDIT;
  const isItemSelected = !isNull(selectedServPattern);
  const leftIcons = [
    {
      label: 'Back',
      Icon: ArrowBack,
      action: 'Back',
    }
  ];
  const centerIcons = [
    {
      label: 'Add Job Template',
      Icon: AddCircle,
      action: 'AddJob',
      disabled: !inEditNew
    }];
  const rightIcons = [
    {
      label: 'New',
      Icon: AddCircle,
      action: 'New',
      disabled: inEditNew
    },
    {
      label: 'Edit',
      Icon: Edit,
      action: 'Edit',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Delete',
      Icon: RemoveCircle,
      action: 'Delete',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Cancel',
      Icon: Cancel,
      action: 'Cancel',
      disabled: !inEditNew
    },
    {
      label: 'OK',
      Icon: CheckCircle,
      action: 'Save',
      disabled: !inEditNew
    },
  ];

  const dataGridRef = React.useRef<DataGrid>();

  React.useEffect(
    () => {
      changeOperationMode(Operation.BROWSE);
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    []
  );

  React.useEffect(
    () => {
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    [operationMode, selectedServPattern]
  );

  React.useEffect(
    () => {
      setInitialValues(selectedServPattern);
      setServicePatternItemsData(selectedServPattern ? cloneDeep(selectedServPattern.Items) : []);
    },
    [selectedServPattern]
  );

  React.useEffect(
    () => {
      if (dataGridRef.current) {
        if (isLoading) {
          dataGridRef.current.instance.beginCustomLoading('');
        } else {
          dataGridRef.current.instance.endCustomLoading();
        }
      }
    },
    [isLoading]
  );

  React.useEffect(
    () => {
      setServicePatternItemsData(cloneDeep(gridData));
    },
    [gridData]
  );

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      onEdit(): void {
        changeOperationMode(Operation.EDIT);
      },
      onCancel(): void {
        if (dirty || servicePatternItemsModified) {
          changeConfirmationDialog({
            open: true,
            title: 'Discard changes',
            message: 'Are you sure you want to continue?',
            okLabel: 'Discard',
            onCancel: () => null,
            onOk: () => {
              resetForm();
              changeOperationMode(Operation.BROWSE);
              setServicePatternItemsData(selectedServPattern ? cloneDeep(selectedServPattern.Items) : []);
              setServicePatternItemsModified(false);
            }
          });
        } else {
          resetForm();
          changeOperationMode(Operation.BROWSE);
        }

        if (dataGridRef.current) {
          dataGridRef.current.instance.cancelEditData();
        }
      },
      onNew(): void {
        initServicePatternDetail();
        changeOperationMode(Operation.NEW);
      },
      onOk(): void {
        setServicePatternItemsModified(false);

        if (!servicePatternFormValidated()) return;

        if (dataGridRef.current) {
          dataGridRef.current.instance.cancelEditData();
        }

        const updatedValues = {
          ...formValues,
          FrequencyValue: Number(formValues.FrequencyValue),
          ServicePatternId: Number(formValues.ServicePatternId),
          Items: servicePatternItemsData.map((p, idx) => ({ ...p, SequenceNumber: idx + 1 }))
        };

        if (operationMode === Operation.NEW) {
          create(updatedValues)
            .then((response) => {
              if (response.Status && !response.Forms) {
                showSnackBar({ variant: 'success', message: 'Service Pattern created successfully.' });
                const servicePatternId = pathOr(null, ['ServicePatternDetails', 'inlineObject', 'ServicePatternId'], response);
                performSearchById(servicePatternId);
                setServicePatternItemsData(response?.ServicePatternDetails?.inlineObject?.Items);
                changeOperationMode(Operation.BROWSE);
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to create Service Pattern.' });
              }
            })
            .catch((err) => {
              showSnackBar({ variant: 'error', message: 'Failed to create Service Pattern.' });
              console.warn(err);
            });
        } else {
          update(updatedValues)
            .then((response) => {
              if (response.Status && !response.Forms) {
                showSnackBar({ variant: 'success', message: 'Service Pattern updated successfully.' });
                const servicePatternId = pathOr(null, ['ServicePatternDetails', 'inlineObject', 'ServicePatternId'], response);
                performSearchById(servicePatternId);
                setServicePatternItemsData(response?.ServicePatternDetails?.inlineObject?.Items);
                changeOperationMode(Operation.BROWSE);
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to update Service Pattern.' });
              }

            })
            .catch((err) => {
              showSnackBar({ variant: 'error', message: 'Failed to update Service Pattern.' });
              console.warn(err);
            });
        }
      },
      onDelete(): void {
        changeConfirmationDialog({
          open: true,
          title: 'Delete Service Pattern',
          message: 'Are you sure you want to continue?',
          okLabel: 'Delete',
          onCancel: () => null,
          onOk: () => {
            if (deleteServicePattern) {
              deletePattern(selectedServPattern.ServicePatternId)
                .then((response) => {
                  if (response.Status && !response.Forms) {
                    showSnackBar({ variant: 'success', message: 'Service Pattern deleted successfully.' });
                    performSearch({ SearchText: '' });
                  }
                  if (!response.Status && !response.Forms) {
                    showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete Service Pattern.' });
                  }
                })
                .catch((err) => {
                  showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete Service Pattern.' });
                  console.warn(err);
                });
            }
          }
        });
      },
      onAdd(): void {
        setOpenSearchScreen(true);
      }
    })
  );

  function initServicePatternDetail(pattern: IServicePatternDetailsFacade = null): void {
    changeFormFieldValue('ServicePatternId', pattern ? pattern.ServicePatternId : null);
    changeFormFieldValue('Description', pattern ? pattern.Description : '');
    changeFormFieldValue('Name', pattern ? pattern.Name : '');
    changeFormFieldValue('ServiceItemType', pattern ? pattern.ServiceItemType : '');
    changeFormFieldValue('FrequencyValue', pattern ? pattern.FrequencyValue : null);
    changeFormFieldValue('FrequencyType', pattern ? pattern.FrequencyValue : 'D');
    setServicePatternItemsData(pattern ? pattern.Items : []);
  }

  function servicePatternFormValidated(): boolean {
    if (!formValues || !formValues.Name || !formValues.Description || !formValues.FrequencyValue || !formValues.FrequencyType ||
      !isNull(formSyncErrors)) {
      touchFormFields('Name', 'Description', 'FrequencyValue', 'FrequencyType');
      showSnackBar({ variant: 'error', message: 'Please resolve form errors.' });

      return false;
    }

    return true;
  }
  async function create(query: any): Promise<any> {
    const response = await createServicePattern(query);

    return processValidationForms(response, query, async (validatedResponse) => create(validatedResponse));
  }

  async function update(query: IServicePatternDetailsFacade): Promise<any> {
    const response = await updatedServicePattern(query.ServicePatternId, query);

    return processValidationForms(response, query, async (validatedResponse) => update(validatedResponse));
  }

  async function deletePattern(servicePatternId: number): Promise<any> {
    const response = await deleteServicePattern(servicePatternId);

    return processValidationForms(response, servicePatternId, async (validatedResponse) => deletePattern(validatedResponse));
  }

  function getJobTemplatesToAdd(SearchText: string): void {
    setAddJobTemplateLoading(true);
    fetchJobTemplateOptions({ SearchText })
      .then((response) => {
        setAddJobTemplateLoading(false);

        if (!isNull(response.Data)) {
          const templates = response.Data.map((option) => ({
            TemplateCode: option.Code,
            Description: option.Label
          }));
          setJobTemplateData(templates);
        }

        setAddJobTemplateLoading(false);

      }).catch((err) => {
        setAddJobTemplateLoading(false);
        console.warn(err);
      });
  }

  function handleClose(rowData: IJobtemplateSearchFacade): void {
    if (!isNull(rowData)) {
      const Items = dataGridRef?.current?.instance?.getDataSource()?.items();
      const newSequenceNumber = isNull(Items) ? 0 : Math.max(...Items.map((item) => item.SequenceNumber));
      const newRow = {
        TemplateCode: rowData.TemplateCode,
        SequenceNumber: newSequenceNumber + 1,
      };
      setServicePatternItemsData([...Items, newRow]);
    }
    setOpenSearchScreen(false);
  }

  const deleteHandler = (event) => {
    changeConfirmationDialog({
      open: true,
      title: 'Delete selected record',
      message: 'Are you sure you want to delete the selected record?',
      okLabel: 'Delete',
      onCancel: () => undefined,
      onOk: () => {
        const GridItems = dataGridRef?.current?.instance?.getDataSource()?.items();
        const Items = GridItems.filter((servicePatternItem) => (servicePatternItem.SequenceNumber !== event.row.key)).map((Item, idx) => ({ ...Item, SequenceNumber: idx + 1 }));
        setServicePatternItemsData(Items ? cloneDeep(Items) : []);
      }
    });
  };

  return (
    <React.Fragment>
      <FormView
        isLoading={isLoading}
        formName={'ServicePatternDetail'}
        style={{
          height: 410
        }}
        schema={ServicePatternDetailForm}
        initialValues={initialValues}
        operationMode={operationMode}
      />
      <Paper className={classes.templatesGridContainer}>
        <DataGrid
          disabled={operationMode === Operation.BROWSE}
          keyExpr={'SequenceNumber'}
          ref={dataGridRef}
          className={classes.servicePatternTemplateGrid}
          allowColumnReordering={true}
          noDataText=''
          dataSource={servicePatternItemsData}
          repaintChangesOnly={true}
          remoteOperations={true}
          columnResizingMode={'nextColumn'}
          allowColumnResizing={true}
          onRowUpdated={() => {
            setServicePatternItemsModified(true);
          }
          }
          onRowInserted={() => {
            setServicePatternItemsModified(true);
          }}
          onRowRemoved={() => {
            setServicePatternItemsModified(true);
          }}
          showBorders={false}
          sorting={{
            mode: 'none'
          }}
          hoverStateEnabled={true}
        >
          <RowDragging
            allowReordering={true}
            onReorder={(e) => {
              const { fromIndex, toIndex, itemData } = e;
              const newPatterns = dataGridRef?.current?.instance?.getDataSource()?.items();
              newPatterns.splice(fromIndex, 1);
              newPatterns.splice(toIndex, 0, itemData);

              setServicePatternItemsData(newPatterns);
              setServicePatternItemsModified(true);
            }
            }
            dropFeedbackMode='push'
            showDragIcons={true}
          />
          <Editing
            refreshMode={'reshape'}
            mode='row'
            allowAdding={false}
            allowDeleting={true}
            allowUpdating={false}
            confirmDelete={true}
          />
          <Scrolling mode='virtual' />
          <Column
            caption={'#'}
            dataField={'SequenceNumber'}
            allowEditing={false}
            minWidth={INDEX_COLUMN_WIDTH}
            fixed={true}
          />
          <Column
            dataField={'TemplateCode'}
            caption={'Job Template'}
            editorOptions={{
              maxLength: 100,
            }}
            minWidth={LARGE_COLUMN_MIN_WIDTH}
          >
            <RequiredRule />
          </Column>
          <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
            <Button name={'delete'} icon={'trash'} onClick={deleteHandler} />
          </Column>
          <GroupPanel visible={false} />
          <LoadPanel enabled={false} />
          <SearchPanel visible={false} />
          <LoadPanel shading={false} height={LOADING_INDICATOR_HEIGHT_WIDTH} width={LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        </DataGrid>
      </Paper>

      <SearchScreen
        open={openSearchScreen}
        data={jobTemplateData as any}
        dataGridKey={'TemplateCode'}
        title={'Add Job Template'}
        loading={addJobTemplateLoading}
        hideLookupSearchOption={true}
        callSearchLookupOnOpen={true}
        defaultSearchText={''}
        onClose={handleClose}
        onLookup={getJobTemplatesToAdd}
      >
        <Column caption='Code' dataField='TemplateCode' />
        <Column caption='Description' dataField='Description' />
      </SearchScreen>
    </React.Fragment>
  );
};

export default withStyles(styles, { index: 1 })(ServicePatternDetail);
