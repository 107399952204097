import React from 'react';
import NewDocumentOutputDialog from 'components/NewDocumentOutputDialog';
import { useRetrievePickSlipSummary, useRetrievePrintData } from 'api/pickSlips/slipLinesUpdated';
import IPrintDialogProperties from './PrintDialog.properties';

const PrintDialog  = (props: IPrintDialogProperties) => {
    const { customerId, despatchId, openDocumentOutputDialog, setPickSlipDocumentOutputOptions, setOpenDocumentOutputDialog } = props;
    const {data: printData} = useRetrievePrintData(despatchId, true);
    const {data, isFetching} = useRetrievePickSlipSummary(despatchId);

    React.useEffect(
        () => {
            setPickSlipDocumentOutputOptions(printData?.PickSlip?.inlineObject ?? undefined);
        },
        [printData]
    );

    return (
        <>
            <NewDocumentOutputDialog
                open={openDocumentOutputDialog}
                comment={''}
                isFetching={isFetching}
                documentType={'SO'}
                fieldId={'PickSlipPrintData'}
                customerId={customerId}
                supplierId={0}
                DespatchId={despatchId}
                Invoice={data?.PickSlipSummary?.inlineObject?.Invoice}
                SalesEntity={data?.PickSlipSummary?.inlineObject?.SalesEntity}
                applyDocumentOutputOptions={true}
                isNonConfirmationScreen={true}
                onApply={() => setOpenDocumentOutputDialog(false)}
                onCancel={() => setOpenDocumentOutputDialog(false)}
            />
        </>
    );
};

export default PrintDialog;
