import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import DateTextField from 'components/FormView/Fields/DateField';
import SelectContainer from 'components/SelectContainer';
import Button from '@material-ui/core/Button';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@markinson/uicomponents-v2/SvgIcons';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import IWorksaleSearchProps, { IWorksaleCriteriaFacadeExtended, IWorksaleSearchHandle } from './WorksaleSearch.properties';
import inlineStyles from './WorksaleSearch.styles';
import { isNull, isShallowEqual } from 'utils/utils';
import { ENTER_KEY } from 'utils/constants';
import moment from 'moment';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';
import LoadingButton from 'components/common/LoadingButton';
import { useCalculateWorksaleContext, useRetrieveWorksalePeriod } from 'api/Worksale/worksaleLatest';
import { useAsyncClickHandler } from 'utils/hooks';
import { SalesProcessingActionsTypes } from 'components/Worksale/Worksale.actions';
import { useSPDispatch } from 'components/Worksale/Worksale.context';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { ISelectFieldHandle } from '@markinson/uicomponents-v2/SelectField/SelectField.properties';
import { ICalculateWorksaleContextResponse } from 'api/swaggerTypes';

const LOOKUP_FIELD_FOCUS_DELAY_TIME = 500;
const NON_LOOKUP_FIELD_FOCUS_DELAY_TIME = 200;
const INTERNAL_CUSTOMERS_NOT_SUPPORTED_ERROR = 'Internal customers not supported.';

const WorksaleSearch = (props: IWorksaleSearchProps, ref: React.Ref<IWorksaleSearchHandle>) => {
    const { classes, loading: loadingProp, formData, disabled, onApply } = props;

    const processValidationForms = React.useContext(ProcessValidationFormsContext);
    const contextDispatch = useSPDispatch();
    const calculateWorksaleContext = useCalculateWorksaleContext();
    const customerLookupRef = React.useRef<IBasicLookupFieldHandle>(null);
    const entityLookupRef = React.useRef<IBasicLookupFieldHandle>(null);
    const customerPORef = React.useRef(null);
    const dateLookupRef = React.useRef(null);
    const periodFieldRef = React.useRef<ISelectFieldHandle>(null);
    const useRetrieveWorksalePeriodMutation = useRetrieveWorksalePeriod();

    const formInitialState = {
        CustomerId: null,
        Customer: null,
        Date: '',
        Period: null,
        CustomerPurchaseOrder: '',
        SalesEntity: '',
    };

    const [form, setFormValues] = React.useState<IWorksaleCriteriaFacadeExtended>(formInitialState);
    const [lookupCalculateRequested, setLookupCalculateRequested] = React.useState<boolean>(false);
    const [periodFocus, setperiodFocus] = React.useState<boolean>(false);

    const loading = React.useMemo(() => loadingProp || calculateWorksaleContext.isLoading, [loadingProp, calculateWorksaleContext.isLoading]);

    React.useEffect(
        () => {

            if (!loadingProp) {
                focusOnFieldBasedOnChangedField('CustomerId');
            }
        },
        [loadingProp]
    );
    React.useEffect(
        () => {

            if (periodFieldRef?.current && periodFocus) {
                setTimeout(
                    () => {
                        periodFieldRef?.current?.focus();
                        setperiodFocus((prev) => !prev);
                    },
                    NON_LOOKUP_FIELD_FOCUS_DELAY_TIME
                );
            }
        },
        [periodFieldRef?.current, periodFocus]
    );

    React.useEffect(
        () => {

            if (!isNull(formData)) {
                getDefaultDate(Number(formData.Period), formData?.Date)
                    .then((date) => setFormValues({ ...formData, Date: date }));
            }
        },
        [formData]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            isDirty(): boolean {
                return !isShallowEqual(formData, form);
            },
            focusOnCustomerField(): void {
                focusOnFieldBasedOnChangedField('CustomerId');
            }
        })
    );

    const applyCallback = () => {
        if (validationPassed() && onApply) {
            onApply({ ...form });
        }
    };

    const { onClick: onApplyClick } = useAsyncClickHandler(applyCallback, !loading && !lookupCalculateRequested);

    //returns default date based on selected period range
    const getDefaultDate = async (selectedPeriod: number, incommingDate: string): Promise<string> => {
        if (selectedPeriod) {
            const response = await useRetrieveWorksalePeriodMutation.mutateAsync({
                SearchText: '',
                Module: 'iv'
            });
            const selectedOption = response?.Data ? response?.Data?.find((opt) => Number(opt.Code) === selectedPeriod) : null;
            const dateFormat = 'DD/MM/YYYY';
            const selectedStartDateMoment = selectedOption ? moment(selectedOption.Details.StartDate, dateFormat) : '';
            const selectedEndDateMoment = selectedOption ? moment(selectedOption.Details.EndDate, dateFormat) : '';
            const inCommingDateMoment = incommingDate ? moment(incommingDate, dateFormat) : '';

            if (inCommingDateMoment && inCommingDateMoment !== '') {
                return inCommingDateMoment.isBefore(selectedStartDateMoment, 'day') ?
                    selectedStartDateMoment.format(dateFormat) : inCommingDateMoment.isAfter(selectedEndDateMoment, 'day') ?
                        selectedEndDateMoment.format(dateFormat) : inCommingDateMoment.format(dateFormat);
            }

            return moment().isBefore(selectedStartDateMoment, 'day') ?
                selectedStartDateMoment.format(dateFormat) : moment().isAfter(selectedEndDateMoment, 'day') ?
                    selectedEndDateMoment.format(dateFormat) : moment().format(dateFormat);
        }

        return moment().format('DD/MM/YYYY');
    };

    const updateFormField = (name, value) => {
        setFormValues((prevForm) => ({
            ...prevForm,
            [name]: value
        }));

        if (name !== 'CustomerPurchaseOrder') {
            calculateWorksale(name, value);
        }
    };

    const validationPassed = () => {
        const { Date = '', SalesEntity = {} }: any = form;

        return !isNull(Date) &&
            !isNull(SalesEntity);
    };

    const handleUndoButtonClick = () => {
        if (isNull(formData)) {
            setFormValues(formInitialState);
            contextDispatch({
                [SalesProcessingActionsTypes.setUnAppliedCustomerId]: null,
            });
        } else {
            setFormValues(formData);
            contextDispatch({
                [SalesProcessingActionsTypes.setUnAppliedCustomerId]: formData.CustomerId,
            });
        }
    };

    const focusOnFieldWithDelay = (fieldRef, FOCUS_DELAY_TIME: number) => {
        setTimeout(
            () => {
                fieldRef?.current?.focus();
            },
            FOCUS_DELAY_TIME
        );
    };

    const focusOnFieldBasedOnChangedField = (ChangedField: string) => {
        switch (ChangedField) {
            case 'CustomerId':
                focusOnFieldWithDelay(customerLookupRef, LOOKUP_FIELD_FOCUS_DELAY_TIME);
                break;
            case 'CustomerPurchaseOrder':
                focusOnFieldWithDelay(customerPORef, LOOKUP_FIELD_FOCUS_DELAY_TIME);
                break;
            case 'SalesEntity':
                focusOnFieldWithDelay(entityLookupRef, LOOKUP_FIELD_FOCUS_DELAY_TIME);
                break;
            case 'Period':
                setperiodFocus((prev) => !prev);
                break;
            case 'Date':
                focusOnFieldWithDelay(dateLookupRef, NON_LOOKUP_FIELD_FOCUS_DELAY_TIME);
                break;
            default:
        }
    };

    const handleFormValidationQuestion = async (validatedResponse) => {
        const { CustomerId, Date, Period, CustomerPurchaseOrder, SalesEntity } = validatedResponse?.WorksaleCriteria;
        const response = await calculateWorksaleContext.mutateAsync({
            CustomerId,
            Date,
            Period,
            CustomerPurchaseOrder,
            SalesEntity,
            UpdateSaleEntity: true,
            urlQueryParams: {
                ChangedField: 'CustomerId',
            }
        });

        if (response && response.Status) {
            setFormValues({
                ...response?.WorksaleCriteria,
                Customer: (response?.WorksaleCriteria as any)?.CustomerIdDisplay || response?.WorksaleCriteria?.CustomerId?.toString()
            });
        }
    };

    const resetCustomerId = () => {
        setFormValues({
            ...form,
            CustomerId: null
        });
    };

    const handleFormValidation = async (response: ICalculateWorksaleContextResponse, isCustomerIdChanged: boolean, ChangedField: string) => {

        const isInternalCustomersNotSupportedError = response.Forms[0].MessageType === 'Error' && response.Forms[0].Message === INTERNAL_CUSTOMERS_NOT_SUPPORTED_ERROR;
        if (isInternalCustomersNotSupportedError) resetCustomerId();

        await processValidationForms(response, response, isCustomerIdChanged ? handleFormValidationQuestion : null);
        focusOnFieldBasedOnChangedField(ChangedField);
    };

    const calculateWorksale = async (ChangedField: string, value) => {
        setLookupCalculateRequested(false);

        if (isNull(value) || ChangedField === 'SalesEntity' || formData?.[ChangedField] === value) {
            return;
        }

        const response = await calculateWorksaleContext.mutateAsync({
            ...form,
            UpdateSaleEntity: null,
            [ChangedField]: value
            ,
            urlQueryParams: {
                ChangedField
            }
        });

        if (response && response?.Status) {
            setFormValues({
                ...response?.WorksaleCriteria,
                Customer: (response?.WorksaleCriteria as any)?.CustomerIdDisplay || response?.WorksaleCriteria?.CustomerId?.toString()
            });

            const hasForms = response && response.hasOwnProperty('Forms');
            const statusNotTrue = response?.Status !== true;
            const isCustomerIdChanged = ChangedField === 'CustomerId';
            const isPeriodChanged = ChangedField === 'Period';

            if (!hasForms) {
                focusOnFieldBasedOnChangedField(statusNotTrue || isPeriodChanged ? ChangedField : 'CustomerPurchaseOrder');
            } else if (hasForms) {
                handleFormValidation(response, isCustomerIdChanged, ChangedField);
            }
        }

    };

    const lookupOnBlur = (name: string, value: string) => {
        if (value && value !== form?.[name]) {
            setLookupCalculateRequested(true);
        }
    };

    const customerOnSelectedItemChange = (v, inputText) => {
        setFormValues((prev) => ({ ...prev, Customer: v?.Display }));
        updateFormField('CustomerId', v ? v?.Code : inputText);
        contextDispatch({
            [SalesProcessingActionsTypes.setUnAppliedCustomerId]: v ? v?.Code : inputText,
        });
    };

    return (
        <React.Fragment>
            <Paper style={inlineStyles.lookupFieldsPaperContainer} square={true}>
                <BasicLookupActionField
                    className={classes.customerLookupField}
                    placeholder={'Customer'}
                    label={''}
                    size={'large'}
                    searchScreenTitle={'Customer Lookup'}
                    lookupName={'Customer'}
                    value={form?.CustomerId?.toString() ?? ''}
                    onSelectedItemChange={customerOnSelectedItemChange}
                    suppressDescription={false}
                    disabled={loading || formData?.Recalled}
                    ref={customerLookupRef}
                    descriptionField={'Label'}
                    onBlur={(v) => lookupOnBlur('Customer', v)}
                    onEnterKeyDown={onApplyClick}
                />
                <TextField
                    fieldRef={(reference) => { customerPORef.current = reference; }}
                    label={'Customer P/O'}
                    name={'Period'}
                    style={inlineStyles.POfield}
                    value={form.CustomerPurchaseOrder || ''}
                    inputProps={{ maxLength: 15 }}
                    disabled={loading}
                    onChange={(t) => { updateFormField('CustomerPurchaseOrder', t.target.value); }}
                    onKeyDown={async ({ keyCode, target }) => {
                        if (keyCode === ENTER_KEY) {
                            await calculateWorksale('CustomerPurchaseOrder', target?.value);
                            onApplyClick();
                        }
                    }}
                />
            </Paper>
            <Paper style={inlineStyles.lookupFieldsPaperContainer} square={true}>
                <BasicLookupActionField
                    className={classes.basicLookupField}
                    placeholder={'Entity'}
                    label={'Entity'}
                    size={'large'}
                    lookupName={'SalesEntity'}
                    value={form.SalesEntity}
                    onSelectedItemChange={(v, inputText) => { updateFormField('SalesEntity', v ? v.Code : inputText); }}
                    suppressDescription={true}
                    required={true}
                    disabled={loading || disabled}
                    ref={entityLookupRef}
                    onBlur={(v) => lookupOnBlur('SalesEntity', v)}
                    onEnterKeyDown={onApplyClick}
                />
                <div className={classes.selectContainer}>
                    <SelectContainer
                        label={'Period'}
                        innerRef={periodFieldRef}
                        className={classes.field}
                        disabled={loading}
                        value={(form.Period || '').toString()}
                        apiPrefs={({
                            LookupType: 'Dynamic',
                            path: '/CustomTypes/Lookup/ActiveModulePeriod/Search',
                            method: 'POST'
                        })}
                        params={{
                            SearchText: '',
                            BatchPage: 1,
                            BatchSize: 10,
                            Module: 'iv'
                        }}
                        onChange={(v) => {
                            updateFormField('Period', v);
                        }}
                    />
                </div>
                <DateTextField
                    label={'Date'}
                    value={form.Date}
                    style={inlineStyles.field}
                    disabled={loading}
                    defaultValue={false}
                    onChange={(v) => { updateFormField('Date', v); }}
                    required={true}
                    fieldRef={dateLookupRef}
                    onKeyDown={({ keyCode }) => {
                        if (keyCode === ENTER_KEY) {
                            if (dateLookupRef.current) {
                                dateLookupRef.current.blur();
                            }
                            onApplyClick();
                        }
                    }}
                />
            </Paper>
            <Paper style={inlineStyles.commitActionPaperContainer} square={true}>
                <Button
                    onClick={handleUndoButtonClick}
                    variant={'contained'}
                    disabled={loading}
                    className={classes.commitActionButton}>
                    <CancelIcon style={{ color: 'red' }} /> UNDO
                </Button>
                <LoadingButton
                    onMouseDown={() => {
                        setTimeout(
                            () => {
                                onApplyClick();
                            },
                            0
                        );
                    }}
                    variant={'contained'}
                    disabled={loading || !validationPassed()}
                    className={classes.commitActionButton}
                    loading={loading}
                    startIcon={<CheckCircleIcon style={{ color: 'green' }} />}>
                    APPLY
                </LoadingButton>
            </Paper>
        </React.Fragment>
    );
};

export default withStyles(inlineStyles, { index: 1 })(React.memo(React.forwardRef(WorksaleSearch)));
