// tslint:disable: cyclomatic-complexity
import React from 'react';
import * as styles from './Worksale.scss';
import WorksaleLookupPanel from './WorksaleLookupPanel';
import BreadcrumbBar from '@markinson/uicomponents-v2/BreadcrumbBar';
import ActionBar from '@markinson/uicomponents-v2/ActionBar';
import IWorksaleProperties, { ILostSalesData } from './Worksale.properties';
import WorksaleDetails from './WorksaleDetails';
import { withRouter, Route } from 'react-router';
import SummaryPanel from './SummaryPanel';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import {
    IWorksaleCriteriaFacade, IRecallQuoteFacade, ISaleConfirmationFacade,
    ISalesOrderConfirmationFacade, IProcessSalesInvoiceDetailsResponse, IProcessSalesOrderDetailsResponse, IProcessPaymentDetailsResponse, IInquiryConfirmationFacade, IWorksaleGridLineDetailFacade,
    IWorksaleDeliveryDataFacade, IUpdateWorksalelineDetailFacade,
    IInternalTransferOrderFacade,
} from 'api/swaggerTypes';
import { IObjectified, isScreenRequest } from 'api/utils';
import modules from './Worksale.modules';
import { COMMIT_BACKORDER_POPUP_MESSAGE, getTabs } from './constants';
import QuoteConfirmation from './QuoteConfirmation';
import ParksaleConfirmation from './ParksaleConfirmation';
import DeliveryDetails from './DeliveryDetails';
import Deliveries from './Deliveries';
import ProductCatalogue from './ProductCatalogue';
import { IProductCatalogueHandle } from './ProductCatalogue/ProductCatalogue.properties';
import OrderDetails from './OrderDetails';
import { isNull } from 'utils/utils';
import RecallQuoteDialog from './RecallQuoteDialog';
import { IWorksaleDetailsHandle } from './WorksaleDetails/WorksaleDetails.properties';
import OrderConfirmation from './OrderConfirmationDialog';
import SaleConfirmation from './SaleConfirmationDialog';
import LineDetails from './LineDetails';
import {
    CorrectedQuoteConfirmationFacade, ObjectifiedWorkSaleSummaryDetailsResponse, useFetchCurrentWorksale, useFetchCurrentWorksaleSummary, useApplyWorksaleCriteria, useDeleteCurrentWorksale, useRemoveLine, useRecallQuote, useFetchQuoteConfirmation, useFetchParksaleConfirmation, useSaveAsQuote, useSaveAsSalesEnquiry, useFetchWorksaleConfirmation, useFetchSalesOrderConfirmation, useFetchSaleConfirmation, useFetchPaymentInformation, useInitializeWorksaleLostSale, useInitializeWorksaleLineLostSale, useProcessSaleConfirmation, useProcessSalesOrderConfirmation, useSetSummaryQueryData, useSetCurrentWorksaleQueryData
} from 'api/Worksale/worksale';
import WorksaleMessages from './WorksaleMessages';
import LineDetailsSummaryTableSchema from './LineDetails/LineDetails.summaryTable.schema';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { IDeliveryDetailsHandle } from './DeliveryDetails/DeliveryDetails.properties';
import { IOrderDetailsHandle } from './OrderDetails/OrderDetails.properties';
import { ILineDetailsHandle } from './LineDetails/LineDetails.properties';
import RecallParkedSaleDialog from './RecallParkedSaleDialog';
import classNames from 'classnames';
import { Operation } from 'utils/operations';
import LostSalesDialog from '../common/LostSalesDialog';
import { useSPContextSelector, useSPDispatch } from './Worksale.context';
import { SalesProcessingActionsTypes } from './Worksale.actions';
import OrderRecallDialog from './OrderRecallDialog';
import { useLostSale, useTabLocations } from './Worksale.hooks';
import { useRecallParkedSale, useRetriveParkedSale } from 'api/Worksale/worksaleLatest';
import { ILocationState, useModule } from 'utils/hooks';
import { LostSalesType } from 'components/common/LostSalesDialog/LostSalesDialog.properties';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { IFulfillmentHandle } from './Fulfillment/Fulfillment.properties';
import PaymentEntryWrapper from 'components/common/PaymentEntryWrapper';
import NavigationTabs from '@markinson/uicomponents-v2/NavigationTabs';
import { IWorksaleLookupPanelHandle } from './WorksaleLookupPanel/WorksaleLookupPanel.properties';
import Selectors from './Worksale.selectors';
import Fulfillment from './Fulfillment/Fulfillment';
import TransmitInternalPODialog from './TransmitInternalPODialog';

function Worksale(props: IWorksaleProperties): JSX.Element {

    const {
        history,
        location,
        match,
        changeOperationMode,
        changeConfirmationDialog,
        changeInformationDialog,
    } = props;

    const worksaleId = useSPContextSelector(Selectors.WorksaleId) || undefined;
    const customerId = useSPContextSelector(Selectors.CustomerId);

    const { refetch: fetchCurrentWorksale, data: currentWorksaleData } = useFetchCurrentWorksale();
    const { refetch: fetchCurrentWorksaleSummary } = useFetchCurrentWorksaleSummary(worksaleId);
    const { mutateAsync: applyWorksaleCriteriaMutateAsync } = useApplyWorksaleCriteria();
    const { mutateAsync: deleteCurrentWorksaleMutateAsync } = useDeleteCurrentWorksale();
    const { mutateAsync: processSaleConfirmationMutateAsync } = useProcessSaleConfirmation();
    const { mutateAsync: processSalesOrderConfirmationMutateAsync } = useProcessSalesOrderConfirmation();
    const { mutateAsync: removeLineMutateAsync } = useRemoveLine();
    const { mutateAsync: recallQuoteMutateAsync } = useRecallQuote();
    const { mutateAsync: saveAsQuoteMutateAsync } = useSaveAsQuote();
    const { mutateAsync: saveAsSalesEnquiryMutateAsync } = useSaveAsSalesEnquiry();
    const { mutateAsync: initializeWorksaleLostSaleMutateAsync } = useInitializeWorksaleLostSale();
    const { mutateAsync: initializeWorksaleLineLostSaleMutateAsync } = useInitializeWorksaleLineLostSale();
    const processValidationForms = React.useContext(ProcessValidationFormsContext);
    const { actionBarProps } = React.useContext(ActionBarContext);
    const contextDispatch = useSPDispatch();
    const warningSnackBar = () => {
        showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
    };
    const url = match ? match.url : '';
    const path = match ? match.path : '';

    const { tabLocations } = useTabLocations(url, location);

    const { selectedTab, handleModuleChange } = useModule(
        history,
        location,
        'Lines',
        tabLocations,
        modules
    );

    const homeScreen = () => { history.push(tabLocations.Lines); };
    const deliveryDetailsCancel = () => { history.push(tabLocations.Deliveries); };

    const worksaleDetailsRef = React.useRef<IWorksaleDetailsHandle>();
    const worksaleDeliveryDetailsRef = React.useRef<IDeliveryDetailsHandle>();
    const worksaleOrderDetailsRef = React.useRef<IOrderDetailsHandle>();
    const worksaleLineDetailsRef = React.useRef<ILineDetailsHandle>();
    const worksaleCatalogueRef = React.useRef<IProductCatalogueHandle>();
    const worksaleLookupPanelRef = React.useRef<IWorksaleLookupPanelHandle>();
    const workslaeContainerRed = React.useRef();
    const fulfillmentRef = React.useRef<IFulfillmentHandle>();

    const salesEntity = useSPContextSelector(Selectors.salesEntity);
    const setSummaryQueryData = useSetSummaryQueryData();
    const setCurrentWorksaleQueryData = useSetCurrentWorksaleQueryData();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [recallOrNewOrder, setRecallOrNewOrder] = React.useState(false);
    const [worksaleLookupPanelDisabled, setWorksaleLookupPanelDisabled] = React.useState<boolean>(false);
    const [shortSuppliedLostSaleData, setShortSuppliedLostSaleData] = React.useState<IUpdateWorksalelineDetailFacade>(null);
    const [reserveSuppliedQuantity, setReserveSuppliedQuantity] = React.useState(false);
    const [operationInProgress, setOperationInProgress] = React.useState<'None' | 'ProcessSale' | 'ProcessSalesOrder'>('None');
    const [processSaleQuery, setProcessSaleQuery] = React.useState<ISaleConfirmationFacade>();
    const [processSalesOrderQuery, setProcessSalesOrderQuery] = React.useState<ISalesOrderConfirmationFacade>();
    const [selectedDeliverSite, setSelectedDeliverSite] = React.useState<IObjectified<IWorksaleDeliveryDataFacade>>(null);

    const [quoteConfirmationOpen, setQuoteConfirmationOpen] = React.useState(false);
    const [parksaleConfirmationOpen, setParksaleConfirmationOpen] = React.useState(false);
    const [salesOrderConfirmationOpen, setSalesOrderConfirmationOpen] = React.useState(false);
    const [saleConfirmationOpen, setSaleConfirmationOpen] = React.useState(false);
    const [recallParkedSaleOpen, setRecallParkedSaleOpen] = React.useState(false);
    const [paymentEntryOpen, setPaymentEntryOpen] = React.useState(false);
    const [worksaleLookupPanelOpen, setWorksaleLookupPanelOpen] = React.useState(true);
    const [recallQuoteOpen, setRecallQuoteOpen] = React.useState(false);
    const [orderRecallOpen, setOrderRecallOpen] = React.useState(false);
    const [changeDeliveryDialogOpen, setChangeDeliveryDialogOpen] = React.useState(false);
    const [transmitInternalPODialogOpen, setTransmitInternalPODialogOpen] = React.useState(false);
    const [showCommitBackordersAfterInternal, setShowCommitBackordersAfterInternal] = React.useState(false);
    const [salesOrder, setSalesOrder] = React.useState<string>();
    const [purchaseOrders, setPurchaseOrders] = React.useState<IInternalTransferOrderFacade[]>();

    const { setDialogState: setLostSaleDialogStates, dialogStates, closeModal: closeLotSaleDialog } = useLostSale();
    const isOrderRecalled = useSPContextSelector<'orderRecalled'>((state) => state.orderRecalled);
    const fetchRecallParkedSalesMutation = useRetriveParkedSale();
    const recallParkedSaleMutation = useRecallParkedSale();
    const { refetch: fetchQuoteConfirmation } = useFetchQuoteConfirmation(worksaleId);
    const { refetch: fetchParksaleConfirmation } = useFetchParksaleConfirmation(worksaleId);
    const { refetch: fetchWorksaleConfirmation } = useFetchWorksaleConfirmation(worksaleId);
    const { refetch: fetchSalesOrderConfirmation } = useFetchSalesOrderConfirmation(worksaleId);
    const { refetch: fetchSaleConfirmation } = useFetchSaleConfirmation(worksaleId);
    const { refetch: fetchPaymentInformation } = useFetchPaymentInformation(worksaleId);
    const tabsData = React.useMemo(() => getTabs({ customerId, worksaleId }), [customerId, worksaleId]);
    const worksaleMessagesOpen = useSPContextSelector(Selectors.WorksaleMessagesOpen);
    const worksaleMessages = useSPContextSelector(Selectors.WorksaleMessages);

    const _handleModuleChange = React.useCallback(
        (newSelectedTab: string, locationObj?: ILocationState) => {
            if (selectedTab === 'OrderDetails' && worksaleOrderDetailsRef.current?.isDirty()) {
                warningSnackBar();

                return;
            }

            handleModuleChange(newSelectedTab, locationObj);
        },
        [selectedTab, worksaleOrderDetailsRef, handleModuleChange]
    );

    async function loadWorksale(): Promise<void> {
        try {
            setLoading(true);
            const { data } = await fetchCurrentWorksale();
            contextDispatch({
                [SalesProcessingActionsTypes.setWorksaleId]: data.WorkSale.Summary.WorksaleId,
                [SalesProcessingActionsTypes.setCustomerId]: data.WorkSale.Summary.SaleHeader.inlineObject.CustomerId,
                [SalesProcessingActionsTypes.setSalesEntity]: data.WorkSale.Summary.SaleHeader.inlineObject.SalesEntity,
                [SalesProcessingActionsTypes.setOrderRecalled]: data.WorkSale.Criteria.inlineObject.Recalled,
                [SalesProcessingActionsTypes.setAutoSupplyQuantity]: (data.WorkSale?.Summary?.AutoSupplyRecallOrder as any).Value,
                [SalesProcessingActionsTypes.setDisableAutoSupplyQuantity]: (data.WorkSale?.Summary?.AutoSupplyRecallOrder as any).ReadOnly
            });
            const search = new URLSearchParams();
            search.append('WorksaleId', data.WorkSale.Summary.WorksaleId.toString());
            setSummaryQueryData({
                Status: true,
                WorksaleSummary: data.WorkSale.Summary
            });
            await fetchRecallParkedSalesMutation.mutateAsync({ SalesEntity: '', urlQueryParams: { ParkedSaleOnly: true } });
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(
        () => {
            setWorksaleLookupPanelOpen(worksaleId === 0 || worksaleId === undefined);
            setWorksaleLookupPanelDisabled(worksaleId !== 0 && worksaleId !== undefined);
        },
        [worksaleId]);

    React.useEffect(
        () => {
            loadWorksale().catch((err) => { console.log(err); });
        },
        []
    );

    async function onShortSuppliedLostsaleRequest(lineData: IUpdateWorksalelineDetailFacade): Promise<void> {
        if (lineData.LineLostsale) {
            setShortSuppliedLostSaleData(lineData);
            initiateLostSale(LostSalesType.ShortSuppliedSaleLine, undefined, lineData);
        }
    }

    async function initiateLostSale(type: LostSalesType, id?: number, lineData?: IUpdateWorksalelineDetailFacade): Promise<void> {
        switch (type) {
            case LostSalesType.CancelSale: {
                const response = await initializeWorksaleLostSaleMutateAsync({
                    WorksaleId: worksaleId
                });
                if (response.Status) {
                    setLostSaleDialogStates({ open: true, data: { ...response.Lostsale.inlineObject, WorksaleLineId: id }, onOk: submitLostSale, type: LostSalesType.CancelSale });

                } else {
                    showSnackBar({ variant: 'error', message: 'Unable to initialize LostSale' });
                }
                break;
            }
            case LostSalesType.DeleteSaleLine: {
                const response = await initializeWorksaleLineLostSaleMutateAsync({
                    WorksaleId: worksaleId,
                    WorksaleLineId: id
                });
                if (response.Status) {
                    setLostSaleDialogStates({ open: true, data: { ...response.LineLostsale.inlineObject, WorksaleLineId: id }, onOk: submitLostSale, type: LostSalesType.DeleteSaleLine });

                } else {
                    showSnackBar({ variant: 'error', message: 'Unable to initialize LostSale' });
                }
                break;
            }
            case LostSalesType.ShortSuppliedSaleLine: {
                if (lineData) {
                    setLostSaleDialogStates({ open: true, data: { ...lineData.LineLostsale, WorksaleLineId: id }, onOk: (_data, _type) => submitLostSale(_data, _type, lineData), type: LostSalesType.ShortSuppliedSaleLine });
                } else {
                    showSnackBar({ variant: 'error', message: 'Unable to initialize LostSale' });
                }
                break;
            }
            default:
                showSnackBar({ variant: 'error', message: 'Unable to initialize LostSale' });
        }
    }

    async function submitLostSale(data: ILostSalesData, type: LostSalesType, lineData?: IUpdateWorksalelineDetailFacade): Promise<void> {
        switch (type) {
            case LostSalesType.CancelSale:
                await cancelWorksale(data);
                break;
            case LostSalesType.DeleteSaleLine:
                await deleteWorksaleLine(data);
                break;
            case LostSalesType.ShortSuppliedSaleLine:
                worksaleLineDetailsRef.current.updateWorksaleLineDetail(
                    worksaleId,
                    {
                        ...shortSuppliedLostSaleData,
                        ...lineData,
                        LineLostsale: data
                    }
                );
                break;
            default:
                showSnackBar({ variant: 'error', message: 'Unable to initialize LostSale' });
        }
    }

    async function refuseAddingLostSale(lostType: LostSalesType, lineId?: number): Promise<void> {
        if (lostType === LostSalesType.CancelSale) {
            await cancelWorksale();
        } else if (lostType === LostSalesType.DeleteSaleLine) {
            await deleteWorksaleLine(null, lineId);
        }
    }

    async function onInitiateDeleteSaleLine(id: number): Promise<void> {
        setLostSaleDialogStates({ type: LostSalesType.DeleteSaleLine });

        handleLostsaleConfirmation('Delete Sale Line', 'The selected sale line will be deleted.\n Do you wish to record a lost sale ?', LostSalesType.DeleteSaleLine, id);
    }

    async function deleteWorksaleLine(data?: ILostSalesData, lineId?: number): Promise<void> {
        const id = lineId ? lineId : data.WorksaleLineId;
        const response = data ? await removeLineMutateAsync({
            WorkSaleLineId: id,
            ...data,
            urlQueryParams: {
                createLostsale: true
            }
        }) : await removeLineMutateAsync({
            WorkSaleLineId: id,
            urlQueryParams: {
                createLostsale: false
            }
        });

        if (response?.Status) {
            worksaleDetailsRef.current.reloadLines();
            closeLotSaleDialog();

            await refetchSummary();

        } else {
            showSnackBar({ variant: 'error', message: data ? 'Unable to add Lost sale.' : 'Unable to delete sale line' });
        }
    }

    async function cancelWorksale(data?: ILostSalesData): Promise<void> {

        const response = data ? await deleteCurrentWorksaleMutateAsync({
            ...data,
            urlQueryParams: {
                createLostsale: true
            }
        }) : await deleteCurrentWorksaleMutateAsync({
            urlQueryParams: {
                createLostsale: false
            }
        });

        if (response.Status) {
            showSnackBar({ variant: 'success', message: 'Sale has been discarded successfully.' });

            setCurrentWorksaleQueryData({
                ...currentWorksaleData,
                WorkSale: {
                    ...currentWorksaleData.WorkSale,
                    Criteria: { inlineObject: {}, schema: {} }
                }
            });
            resetWorkSale();
            closeLotSaleDialog();

            if (worksaleDetailsRef.current) {
                worksaleDetailsRef.current.resetFastLineEntry();
            }
            await loadWorksale();

        } else {
            showSnackBar({ variant: 'error', message: data ? 'Unable to add Lost sale.' : 'Sale failed to be discarded.' });
        }
    }

    async function quoteWorksale(): Promise<void> {
        setOperationInProgress('None');
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false,
            [SalesProcessingActionsTypes.setWorksaleMessages]: undefined
        });
        const { data: response } = await fetchQuoteConfirmation();

        if (response.Status && response.QuoteConfirmation) {
            setQuoteConfirmationOpen(true);

        } else {
            showSnackBar({ variant: 'error', message: 'Failed to retrieve quote information.' });
        }
    }

    async function parkWorksale(): Promise<void> {
        setOperationInProgress('None');
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false,
            [SalesProcessingActionsTypes.setWorksaleMessages]: undefined
        });
        const { data: response } = await fetchParksaleConfirmation();

        if (response.Status && response.InquiryConfirmation) {
            setParksaleConfirmationOpen(true);
        } else {
            showSnackBar({ variant: 'error', message: 'Failed to park the sale.' });
        }
    }

    async function processWorksaleAsSale(): Promise<void> {
        if (operationInProgress === 'ProcessSale') {
            setSaleConfirmationOpen(true);

            return;
        }

        setOperationInProgress('None');
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false,
            [SalesProcessingActionsTypes.setWorksaleMessages]: undefined,
        });

        const { data: response } = await fetchSaleConfirmation(); // how to test??

        if (response.Status && response.SaleConfirmation) {
            setSaleConfirmationOpen(true);

            return;
        }

        if (!response?.Status && response?.Forms?.[0]?.Message?.includes('customer account')) {
            focusCustomer();

            return;
        }

        showSnackBar({ variant: 'error', message: 'Failed to retrieve sale confirmation.' });
    }

    async function onProcessSaleConfirmationAndValidate(query: ISaleConfirmationFacade, ignoreWarnings: boolean): Promise<IProcessSalesInvoiceDetailsResponse> {

        const response = await processSaleConfirmationMutateAsync({
            ...query,
            PaymentDetail: query.PaymentDetail || {},
            urlQueryParams: {
                ignoreWarning: ignoreWarnings ? 'True' : 'False'
            }
        });

        return processValidationForms(response, query, async (newQuery) => {
            const updatedQuery = { ...newQuery, SalesOrder: response.SalesOrder };
            setProcessSaleQuery(updatedQuery);

            return onProcessSaleConfirmationAndValidate(updatedQuery, ignoreWarnings);
        });
    }

    function closeAllDialogs(): void {
        setQuoteConfirmationOpen(false);
        setParksaleConfirmationOpen(false);
        setSalesOrderConfirmationOpen(false);
        setSaleConfirmationOpen(false);
        setPaymentEntryOpen(false);
        setWorksaleLookupPanelOpen(false);
        setRecallQuoteOpen(false);
        setOrderRecallOpen(false);
        setTransmitInternalPODialogOpen(false);
    }

    const resetWorkSale = () => {
        closeAllDialogs();
        setSummaryQueryData(undefined);
        setCurrentWorksaleQueryData({
            Status: true,
            WorkSale: undefined
        });
        setOperationInProgress('None');
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleId]: undefined,
            [SalesProcessingActionsTypes.setCustomerId]: undefined,
            [SalesProcessingActionsTypes.setSalesEntity]: undefined,
            [SalesProcessingActionsTypes.setOrderRecalled]: false,
            [SalesProcessingActionsTypes.setAutoSupplyQuantity]: false,
            [SalesProcessingActionsTypes.setDisableAutoSupplyQuantity]: false,
            [SalesProcessingActionsTypes.setWorksaleDeliveryId]: undefined,
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false,
            [SalesProcessingActionsTypes.setWorksaleMessages]: undefined
        });
    };

    async function handleOkSaleConfirmation(query: ISaleConfirmationFacade, ignoreWarnings?: boolean): Promise<void> {
        try {
            setProcessSaleQuery(query);
            contextDispatch({
                [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false
            });
            const response = await onProcessSaleConfirmationAndValidate(query, ignoreWarnings);
            setSalesOrder(response?.SalesOrder);
            setPurchaseOrders(response?.PurchaseOrders);
            const screenRequest = isScreenRequest(response?.Forms);
            if (!screenRequest && response.Status && response.SalesOrder) {
                if (response.Invoice) {
                    showSnackBar({ variant: 'success', message: `Sale has been allocated invoice number  ${response.Invoice}.`, time: -1 });
                } else {
                    showSnackBar({ variant: 'success', message: `Sale has been processed as sale ${response.SalesOrder}.`, time: -1 });
                }
                resetWorkSale();
                await loadWorksale();

                return;
            }
            if (screenRequest) {
                const hasTransmitInternalPurchaseOrder = response?.Forms?.some((form) => form?.FormId === 'TransmitInternalPurchaseOrder');
                const hasCommitBackorders = response?.Forms?.some((form) => form?.FormId === 'CommitBackorders');
                if (hasTransmitInternalPurchaseOrder) {
                    setSalesOrderConfirmationOpen(false);
                    setTransmitInternalPODialogOpen(true);
                    setShowCommitBackordersAfterInternal(hasCommitBackorders);

                    return;
                } else if (hasCommitBackorders) {
                    setSalesOrderConfirmationOpen(false);
                    changeConfirmationDialog({
                        open: true,
                        title: 'Commit Backordered Sale Lines',
                        message: COMMIT_BACKORDER_POPUP_MESSAGE,
                        okLabel: 'commit backorders',
                        cancelLabel: 'continue',
                        onCancel: () => {
                            resetWorkSale();
                            showSnackBar({ variant: 'success', message: `Sale has been processed as sale ${response.SalesOrder}.`, time: -1 });
                            loadWorksale();
                        },
                        onOk: async () => {
                            handleBackorderLines(response.SalesOrder);
                        }
                    });

                    return;
                }

                return;
            }
            if (response.Messages && response.Messages.length > 0) {
                setSaleConfirmationOpen(false);
                setOperationInProgress('ProcessSale');

                contextDispatch({
                    [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: true,
                    [SalesProcessingActionsTypes.setWorksaleMessages]: response.Messages
                });

                return;
            }
            if (response.PaymentRequired) {
                const { data: paymentInformation } = await fetchPaymentInformation();
                if (!paymentInformation.Status || !paymentInformation.PaymentInformationDetail) {
                    showSnackBar({ variant: 'error', message: 'Failed to fetch payment information.' });
                }
                setProcessSaleQuery(query);
                setPaymentEntryOpen(true);
                setSaleConfirmationOpen(false);

                return;
            }

            showSnackBar({ variant: 'error', message: 'Failed to process as sale.' });

        } catch (err) {
            showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
        }
    }

    async function handleApplyPaymentEntry(paymentResponse: IProcessPaymentDetailsResponse): Promise<void> {
        setPaymentEntryOpen(false);
        const newQuery = { ...processSaleQuery, PaymentDetail: paymentResponse.ProcessPaymentDetail };
        setProcessSaleQuery(newQuery);

        await handleOkSaleConfirmation(newQuery, true);
    }

    async function processWorksaleAsSalesOrder(): Promise<void> {
        if (operationInProgress === 'ProcessSalesOrder') {
            setSalesOrderConfirmationOpen(true);

            return;
        }

        setOperationInProgress('None');
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false,
            [SalesProcessingActionsTypes.setWorksaleMessages]: undefined,
        });
        const { data: response } = await fetchSalesOrderConfirmation();

        if (response.Status && response.SalesOrderConfirmation) {
            setSalesOrderConfirmationOpen(true);
        } else {
            showSnackBar({ variant: 'error', message: 'Failed to retrieve sales order confirmation.' });
        }
    }

    async function onProcessSalesOrderConfirmationAndValidate(query: ISalesOrderConfirmationFacade, ignoreWarnings: boolean): Promise<IProcessSalesOrderDetailsResponse> {
        const response = await processSalesOrderConfirmationMutateAsync({
            ...query,
            urlQueryParams: {
                ReserveSuppliedQuantity: reserveSuppliedQuantity ? 'True' : 'False',
                ignoreWarning: ignoreWarnings ? 'True' : 'False'
            }
        });

        return processValidationForms(response, query, async (newQuery) => {
            const updatedQuery = { ...newQuery, SalesOrder: response.SalesOrder };
            setProcessSalesOrderQuery(updatedQuery);

            return onProcessSalesOrderConfirmationAndValidate(updatedQuery, ignoreWarnings);
        });
    }

    function handleBackorderLines(saleOrder: string): void {
        resetWorkSale();
        setWorksaleLookupPanelOpen(false); //temporary solution till Order Commitments module is moved out of Sales processing.
        _handleModuleChange('BackorderLines', {
            search: { SalesOrderId: saleOrder },
            state: { disableCommit: (currentWorksaleData?.WorkSale.Summary.BlockPurchaseOrderCommitAfterSale as any).Value || false, disablePurchase: (currentWorksaleData?.WorkSale.Summary.BlockPurchaseOrderCreateAfterSale as any).Value || false }
        });
    }

    async function handleOkSalesOrderConfirmation(query: ISalesOrderConfirmationFacade, ignoreWarnings: boolean): Promise<void> {
        try {
            setProcessSalesOrderQuery(query);
            const response = await onProcessSalesOrderConfirmationAndValidate(query, ignoreWarnings);
            setSalesOrder(response?.SalesOrder);
            setPurchaseOrders(response?.PurchaseOrders);

            const screenRequest = response.Forms ? response.Forms.find((form) => form.FormType === 'ScreenRequest') : undefined;
            if (!screenRequest && response.Status && response.SalesOrder) {
                showSnackBar({ variant: 'success', message: `Sale has been processed as sales order ${response.SalesOrder}.`, time: -1 });
                resetWorkSale();
                await loadWorksale();

                return;
            }
            if (screenRequest) {
                const hasTransmitInternalPurchaseOrder = response?.Forms?.find((form) => form?.FormId === 'TransmitInternalPurchaseOrder');
                const hasCommitBackorders = response?.Forms?.some((form) => form?.FormId === 'CommitBackorders');
                if (hasTransmitInternalPurchaseOrder) {
                    setSalesOrderConfirmationOpen(false);
                    setTransmitInternalPODialogOpen(true);
                    setShowCommitBackordersAfterInternal(hasCommitBackorders);

                    return;
                } else if (hasCommitBackorders) {
                    setSalesOrderConfirmationOpen(false);
                    changeConfirmationDialog({
                        open: true,
                        title: 'Commit Backordered Sale Lines',
                        message: COMMIT_BACKORDER_POPUP_MESSAGE,
                        okLabel: 'commit backorders',
                        cancelLabel: 'continue',
                        onCancel: () => {
                            resetWorkSale();
                            showSnackBar({ variant: 'success', message: `Sale has been processed as sale ${response.SalesOrder}.`, time: -1 });
                            loadWorksale();
                        },
                        onOk: async () => {
                            handleBackorderLines(response.SalesOrder);
                        }
                    });

                    return;
                }

                return;
            }
            if (response?.Messages?.length > 0) {
                setSalesOrderConfirmationOpen(false);
                setOperationInProgress('ProcessSalesOrder');
                contextDispatch({
                    [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: true,
                    [SalesProcessingActionsTypes.setWorksaleMessages]: response.Messages
                });

                return;
            }

            showSnackBar({ variant: 'error', message: 'Failed to process as sale.' });
        } catch (err) {
            showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
        }
    }

    async function onWorksaleMessagesBack(): Promise<void> {
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false
        });
    }

    async function onWorksaleMessagesContinue(): Promise<void> {
        contextDispatch({
            [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: false
        });
        switch (operationInProgress) {
            case 'ProcessSale':
                await handleOkSaleConfirmation(processSaleQuery, true);
                break;
            case 'ProcessSalesOrder':
                await handleOkSalesOrderConfirmation(processSalesOrderQuery, true);
                break; default:
        }
    }
    async function processWorksale(): Promise<void> {
        if (operationInProgress === 'ProcessSale') {
            await processWorksaleAsSale();

            return;
        }

        if (operationInProgress === 'ProcessSalesOrder') {
            await processWorksaleAsSalesOrder();

            return;
        }
        const { data: response } = await fetchWorksaleConfirmation();

        if (response.Status && response.WorksaleConfirmation) {
            if (response.WorksaleConfirmation.CheckReservationRequired) {
                let preformData: { ReserveSuppliedQuantity: boolean } = { ReserveSuppliedQuantity: false };
                preformData = await processValidationForms(
                    {
                        ReserveSuppliedQuantity: false,
                        Forms: [{
                            Data: 'ReserveSuppliedQuantity',
                            Message: response.WorksaleConfirmation.ReservationMessage,
                            FormType: 'TrueFalseParameterRequest',
                            Sequence: 1
                        }]
                    },
                    preformData,
                    (newQuery) => (newQuery));

                setReserveSuppliedQuantity(preformData.ReserveSuppliedQuantity);
            }
            if (response.WorksaleConfirmation.CanInvoice) {
                await processWorksaleAsSale();
            } else {
                await processWorksaleAsSalesOrder();
            }

            return;
        }
        showSnackBar({ variant: 'error', message: 'Failed to retrieve worksale confirmation.' });
    }

    async function handleOkQuoteConfirmation(query: CorrectedQuoteConfirmationFacade): Promise<void> {
        try {
            const response = await saveAsQuoteMutateAsync(query);

            if (response.Status) {
                showSnackBar({ variant: 'success', message: `Sale has been saved as quote ${response.QuoteId}.`, time: -1 });

                resetWorkSale();
                await loadWorksale();
            } else {
                showSnackBar({ variant: 'error', message: 'Failed to save worksale as quote' });
            }
        } catch (err) {
            showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
        }
    }

    async function handleOkParksaleConfirmation(query: IInquiryConfirmationFacade): Promise<void> {
        try {
            const response = await saveAsSalesEnquiryMutateAsync(query);

            if (response.Status === true) {
                showSnackBar({ variant: 'success', message: `Sale has been parked as a Sales Enquiry. ${response.QuoteId}.`, time: -1 });

                resetWorkSale();
                await loadWorksale();

            } else {
                showSnackBar({ variant: 'error', message: 'Failed to park the sale.' });
            }
        } catch (err) {
            showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
        }
    }

    function navigateBack(): void {
        if (!selectedTab) {
            history.push('/dashboard');
        }
        if (selectedTab) {
            const targetModule = modules.find((item) => item.id === selectedTab);
            if (selectedTab === 'Deliveries' || selectedTab === 'ProductCatalogue') {
                _handleModuleChange('Lines');
                contextDispatch({
                    [SalesProcessingActionsTypes.setWorksaleDeliveryId]: undefined
                });
            } else if (selectedTab === 'OrderDetails') {
                _handleModuleChange('Lines');
            } else if (selectedTab === 'ComponentDetails') {
                _handleModuleChange('KitComponents');
            } else if (selectedTab === 'CatalogueProductDetails') {
                _handleModuleChange('ProductCatalogue');
            } else if (selectedTab === 'CatalogueEntryDetails') {
                _handleModuleChange('ProductCatalogue');
            } else if (selectedTab === 'KitComponents') {
                _handleModuleChange('LineDetails');
            } else if (selectedTab === 'Fulfillment') {
                _handleModuleChange('Deliveries');
            } else if (!targetModule || !targetModule.parent || targetModule.parent === '') {
                history.push('/dashboard');
            } else {
                _handleModuleChange(targetModule.parent);
            }
        }
    }

    async function handleApplyWorksaleCriteria(criteria: IWorksaleCriteriaFacade): Promise<ObjectifiedWorkSaleSummaryDetailsResponse> {
        try {
            setLoading(true);
            const response = await applyWorksaleCriteriaMutateAsync(criteria);

            if (isScreenRequest(response?.Forms, 'OustandingCustomerOrders')) {
                setOrderRecallOpen(true);
                setRecallOrNewOrder(true);

                setCurrentWorksaleQueryData({
                    ...currentWorksaleData,
                    WorkSale: {
                        ...currentWorksaleData.WorkSale,
                        Summary: response.WorksaleSummary,
                        Criteria: {
                            inlineObject: criteria,
                            schema: {}
                        }
                    }
                });

                return response;
            }

            if (response?.Status && response?.WorksaleSummary?.WorksaleId) {
                showSnackBar({ variant: 'success', message: 'Sale changes applied successfully.' });
                setSummaryQueryData({
                    Status: true,
                    WorksaleSummary: response.WorksaleSummary
                });
                setCurrentWorksaleQueryData({
                    ...currentWorksaleData,
                    WorkSale: {
                        ...currentWorksaleData.WorkSale,
                        Summary: response.WorksaleSummary,
                        Criteria: {
                            inlineObject: criteria,
                            schema: {}
                        }
                    }
                });
                contextDispatch({
                    [SalesProcessingActionsTypes.setWorksaleId]: response.WorksaleSummary.WorksaleId,
                    [SalesProcessingActionsTypes.setCustomerId]: response.WorksaleSummary?.SaleHeader?.inlineObject?.CustomerId,
                    [SalesProcessingActionsTypes.setSalesEntity]: response.WorksaleSummary.SaleHeader.inlineObject.SalesEntity,
                    [SalesProcessingActionsTypes.setUnAppliedCustomerId]: 0,
                    [SalesProcessingActionsTypes.setAutoSupplyQuantity]: (response.WorksaleSummary?.AutoSupplyRecallOrder as any).Value,
                    [SalesProcessingActionsTypes.setDisableAutoSupplyQuantity]: (response.WorksaleSummary?.AutoSupplyRecallOrder as any).ReadOnly
                });
                setRecallOrNewOrder(false);
            }

            return response;
        } finally {
            setLoading(false);
        }
    }

    function recallParkedSale(): void {
        setRecallParkedSaleOpen(true);
    }

    function handleNewSiteOnClick(): void {
        changeOperationMode(Operation.NEW);
        setSelectedDeliverSite(null);
        _handleModuleChange('DeliveryDetails');
    }

    function recallOrder(): void {
        if (isOrderRecalled) {
            if (changeInformationDialog) {
                changeInformationDialog({
                    open: true,
                    title: 'Information',
                    message: 'A customer order has already been recalled for this sale!',
                    okLabel: 'OK'
                });
            }

            return;
        }
        setOrderRecallOpen(true);
    }

    function handleActionClick(action: string): void {
        switch (action) {
            case 'Back':
                switch (selectedTab) {
                    case 'DeliveryDetails':
                        worksaleDeliveryDetailsRef.current.onCancel(navigateBack);
                        break;
                    case 'OrderDetails':
                        worksaleOrderDetailsRef.current.onCancel(navigateBack);
                        break;
                    case 'LineDetails':
                        worksaleLineDetailsRef.current.onCancel(navigateBack);
                        break;
                    case 'ComponentDetails':
                        worksaleLineDetailsRef.current.componentDetailOnCancel(navigateBack);
                        break;
                    case 'ProductCatalogue':
                    case 'CatalogueProductDetails':
                    case 'CatalogueEntryDetails':
                        worksaleCatalogueRef.current.onCancel(selectedTab);
                        break;
                    default:
                        navigateBack();
                }
                break;
            case 'Cancel':
                setLostSaleDialogStates({ type: LostSalesType.CancelSale });
                handleLostsaleConfirmation('Cancel Sale', 'The current sale will be cancelled and deleted.\n Do you wish to record a lost sale ?', LostSalesType.CancelSale);
                break;
            case 'RecallQuote':
                setRecallQuoteOpen(true);
                break;
            case 'Process':
                processWorksale().catch((err) => {
                    showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
                });
                break;
            case 'Quote':
                quoteWorksale().catch((err) => {
                    showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
                });
                break;
            case 'Recall':
                recallParkedSale();
                break;
            case 'Park':
                parkWorksale().catch((err) => {
                    showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
                });
                break;
            case 'WarehouseSelection':
                worksaleLineDetailsRef.current.warehouse();
                break;
            case 'ComponentWarehouseSelection':
                worksaleLineDetailsRef.current.componentDetailsWarehouse();
                break;
            case 'LineDetailsCancel':
                worksaleLineDetailsRef.current.onCancel(homeScreen);
                break;
            case 'LineDetailsLotSerial':
                worksaleLineDetailsRef.current.lotSerial();
                break;
            case 'ComponentDetailsLotSerial':
                worksaleLineDetailsRef.current.componentDetailsLotSerial();
                break;
            case 'ComponentDetailsOk':
                worksaleLineDetailsRef.current.componentDetailOnOk();
                break;
            case 'ComponentDetailsCancel':
                worksaleLineDetailsRef.current.componentDetailOnCancel(navigateBack);
                break;
            case 'DeliveryDetailsOk':
                worksaleDeliveryDetailsRef.current.onOk();
                break;
            case 'DeliveryDetailsCancel':
                worksaleDeliveryDetailsRef.current.onCancel(deliveryDetailsCancel);
                break;
            case 'OrderDetailsOk':
                worksaleOrderDetailsRef.current.onOk();
                break;
            case 'OrderDetailsCancel':
                worksaleOrderDetailsRef.current.onCancel(homeScreen);
                break;
            case 'New_Site':
                handleNewSiteOnClick();
                break;
            case 'KitComponents':
                worksaleLineDetailsRef.current.components();
                break;
            case 'AddToSalesLines':
                worksaleCatalogueRef.current.add();
                break;
            case 'Entry_Detail':
                worksaleCatalogueRef.current.entryDetail();
                break;
            case 'Search':
                worksaleCatalogueRef.current.search();
                break;
            case 'OrderRecall':
                recallOrder();
                break;
            case 'ChangeDelivery':
                setChangeDeliveryDialogOpen(true);
                break;
            case 'Fulfillment':
                history.push(tabLocations.Fulfillment);
                break;
            case 'SupplyFull':
            case 'InternalTransfer':
            case 'SupplyBackorder':
                fulfillmentRef?.current?.[action]();
                break;
            default:
        }
    }

    const recallQuoteNotification = (response) => {
        if (response?.Messages[0]?.MessageDetail?.Value) {
            showSnackBar({ variant: 'warning', message: response?.Messages[0]?.MessageDetail?.Value });
        } else {
            showSnackBar({ variant: 'success', message: 'Quote recalled successfully.' });
        }
    };

    const refetchSummary = async () => {
        if (worksaleId > 0) {
            await fetchCurrentWorksaleSummary();
        }
    };

    async function handleRecallQuote(query: IRecallQuoteFacade): Promise<void> {
        try {
            const response = await recallQuoteMutateAsync({
                ...query,
                WorksaleId: worksaleId
            });

            if (response?.Status) {
                await refetchSummary();
                worksaleDetailsRef.current.reloadLines();
                recallQuoteNotification(response);
            } else {
                const message = response?.Messages?.[0]?.MessageDetail as unknown as { Value: string; Type: string };
                showSnackBar({ variant: 'error', message: message.Value ?? 'Failed to recall quote' });
            }
        } finally {
            setRecallQuoteOpen(false);
        }
    }

    async function onUpdateOrderDetailsSuccessfully(): Promise<void> {
        loadWorksale().catch((err) => { console.log(err); });
        await refetchSummary();
        navigateBack();
    }

    function recallParkedSaleOnCancel(): void {
        setRecallParkedSaleOpen(false);
    }

    async function onRecallSuccessfully(): Promise<void> {
        loadWorksale().catch((err) => { console.log(err); });
        await refetchSummary();
        worksaleDetailsRef.current.reloadLines();
    }

    function handleShowLineDetailOnClick(data: IWorksaleGridLineDetailFacade): void {
        contextDispatch({ setWorksaleLineId: data?.WorksalelineId });
        _handleModuleChange('LineDetails', {
            search: {
                WorksaleLineId: data?.WorksalelineId
            }
        });
    }

    function handleBreadcrumbClick(moduleId: string): void {
        switch (selectedTab) {
            case 'LineDetails':
                if (moduleId !== 'LineDetails') {
                    if (!worksaleLineDetailsRef.current) {
                        return;
                    }
                    if (worksaleLineDetailsRef.current.isDirty()) {
                        warningSnackBar();

                        return;
                    }
                    worksaleLineDetailsRef.current.onCancel(navigateBack);
                }
                break;
            case 'ComponentDetails':
                if (moduleId !== 'ComponentDetails') {
                    if (!worksaleLineDetailsRef.current) {
                        return;
                    }
                    worksaleLineDetailsRef.current.handleBreadCrumb();
                }
                break;
            case 'ProductCatalogue':
                if (moduleId !== 'ProductCatalogue') {
                    if (!worksaleCatalogueRef.current) {
                        return;
                    }
                    worksaleCatalogueRef.current.onCancel(selectedTab);
                }
                break;
            case 'CatalogueProductDetails':
                if (moduleId !== 'CatalogueProductDetails') {
                    if (!worksaleCatalogueRef.current) {
                        return;
                    }
                    worksaleCatalogueRef.current.onCancel(selectedTab);
                }
                break;
            case 'CatalogueEntryDetails':
                if (moduleId !== 'CatalogueEntryDetails') {
                    if (!worksaleCatalogueRef.current) {
                        return;
                    }
                    worksaleCatalogueRef.current.onCancel(selectedTab);
                }
                break;
            case 'DeliveryDetails':
                if (moduleId !== 'DeliveryDetails') {
                    if (worksaleDeliveryDetailsRef?.current?.isDirty()) {
                        warningSnackBar();

                        return;
                    }
                    worksaleDeliveryDetailsRef.current.onCancel(navigateBack);
                }
                break;
            default:
                _handleModuleChange(moduleId);

        }
    }

    function handleDeleteSaleline(id: number, line: IWorksaleGridLineDetailFacade): void {
        if (line.LineType === 'r') {
            if (changeConfirmationDialog) {
                changeConfirmationDialog({
                    open: true,
                    title: 'Delete Sale Line',
                    message: 'Are you sure you want to delete?',
                    okLabel: 'Delete',
                    onCancel: () => undefined,
                    onOk: async () => {
                        await deleteWorksaleLine(null, id);
                    }
                });
            }
        } else {
            onInitiateDeleteSaleLine(id);
        }
    }

    function handleLostsaleConfirmation(title: string, message: string, lostType: LostSalesType, lineId?: number): void {
        if (changeConfirmationDialog) {
            changeConfirmationDialog({
                open: true,
                title: title,
                message: message,
                okLabel: 'Yes',
                onCancel: () => undefined,
                onOk: () => initiateLostSale(lostType, lineId),
                onRefuse: () => refuseAddingLostSale(lostType, lineId),
                showRefuse: true
            });
        }
    }
    async function onRecordClick(recordId: any): Promise<void> {
        setWorksaleLookupPanelDisabled(true);
        const response = await recallParkedSaleMutation.mutateAsync({
            ParkedSaleId: recordId,
            WorkSaleId: worksaleId
        });
        if (response.Status && isNull(response.Messages)) {
            showSnackBar({ variant: 'success', message: 'Parked Sale recalled successfully.' });
            await onRecallSuccessfully();
        } else {
            showSnackBar({ variant: 'error', message: 'Failed to recall parked sale.' });
        }
    }

    function focusCustomer(): void {
        setWorksaleLookupPanelOpen(true);
        worksaleLookupPanelRef.current?.focusCustomer();
    }

    function transMitInternalonCancel(): void {
        setTransmitInternalPODialogOpen(false);
        if (showCommitBackordersAfterInternal) {
            setShowCommitBackordersAfterInternal(false);
            changeConfirmationDialog({
                open: true,
                title: 'Commit Backordered Sale Lines',
                message: COMMIT_BACKORDER_POPUP_MESSAGE,
                okLabel: 'commit backorders',
                cancelLabel: 'continue',
                onCancel: () => {
                    resetWorkSale();
                    showSnackBar({ variant: 'success', message: `Sale has been processed as sale ${salesOrder}.`, time: -1 });
                    loadWorksale();
                },
                onOk: async () => {
                    handleBackorderLines(salesOrder);
                }
            });
        } else {
            resetWorkSale();
            showSnackBar({ variant: 'success', message: `Sale has been processed as sale ${salesOrder}.`, time: -1 });
            loadWorksale();
        }

        setSalesOrder(null);
        setPurchaseOrders(null);
    }

    return (
        <div className={styles.worksaleOuter} ref={workslaeContainerRed}>
            <div className={styles.worksaleContainer}>
                <WorksaleLookupPanel
                    ref={worksaleLookupPanelRef}
                    open={worksaleLookupPanelOpen}
                    loading={loading}
                    disabled={worksaleLookupPanelDisabled}
                    parkedSalesListItems={fetchRecallParkedSalesMutation.data?.ParkedSales}
                    parkedSalesListItemsLoading={fetchRecallParkedSalesMutation.isLoading}
                    handleRecordChange={onRecordClick}
                    onOpenChanged={(open) => { setWorksaleLookupPanelOpen(open); }}
                    onApplyWorksaleCriteria={handleApplyWorksaleCriteria} />
                <div className={classNames(styles.worksaleSection, worksaleLookupPanelOpen && styles.opened, !worksaleLookupPanelOpen && styles.closed)} >
                    <BreadcrumbBar
                        mainModule={'Sales Processing'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={handleBreadcrumbClick}
                        onMainModuleClick={() => handleBreadcrumbClick('Lines')}
                        hideOptionsMenu={true}
                    />
                    <SummaryPanel
                        selectedTab={selectedTab}
                        cusReqWarningOnClick={focusCustomer}
                    />
                    {tabsData.some(({ id }) => id === selectedTab) && <NavigationTabs
                        tabsData={tabsData}
                        activeTabId={selectedTab}
                        lookupOpen={worksaleLookupPanelOpen}
                        onTabChange={_handleModuleChange}
                    />}
                    <Route
                        key={'Lines'}
                        path={path}
                        exact
                        render={() => {
                            return <WorksaleDetails
                                innerRef={worksaleDetailsRef}
                                salesEntity={salesEntity}
                                messages={worksaleMessages}
                                showDetailOnClick={handleShowLineDetailOnClick}
                                closeLostsaleModal={() => {
                                    closeLotSaleDialog();
                                }}
                                onSaleLinesChanged={refetchSummary}
                                onProceedLostSaleLine={initiateLostSale}
                                onDeleteSaleline={handleDeleteSaleline}
                            />;
                        }} />
                    <Route
                        key={'OrderDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}order-details`}
                        exact
                        render={() => (
                            <OrderDetails
                                ref={worksaleOrderDetailsRef}
                                changeSelectedTab={_handleModuleChange}
                                onSuccess={onUpdateOrderDetailsSuccessfully}
                                onWarehouseChanged={refetchSummary}
                            />
                        )} />
                    <Route
                        key={'DeliveryDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}delivery-details`}
                        exact
                        render={() => (
                            <DeliveryDetails
                                ref={worksaleDeliveryDetailsRef}
                                deliveryDetail={selectedDeliverSite}
                                changeSelectedTab={_handleModuleChange}
                                onDefaultDeliveryChange={refetchSummary}
                            />
                        )} />
                    <Route
                        key={'Deliveries'}
                        path={`${path.endsWith('/') ? path : `${path}/`}deliveries`}
                        exact
                        render={() => (
                            <Deliveries
                                open={changeDeliveryDialogOpen}
                                onClose={() => { setChangeDeliveryDialogOpen(false); }}
                                changeSelectedTab={_handleModuleChange}
                                showDetailOnClick={(rowData) => {
                                    changeOperationMode(Operation.EDIT);
                                    setSelectedDeliverSite(rowData);
                                    _handleModuleChange('DeliveryDetails');
                                }}
                                onDefaultWorkSaleDeliveryUpdate={(resp) => {
                                    setSummaryQueryData({
                                        Status: true,
                                        WorksaleSummary: resp?.WorksaleSummary
                                    });
                                }} />
                        )} />
                    <Route
                        key={'Fulfillment'}
                        path={`${path.endsWith('/') ? path : `${path}/`}fulfillment`}
                        exact
                        render={() => (
                            <Fulfillment
                                changeSelectedTab={_handleModuleChange}
                                innerRef={fulfillmentRef}
                                showDetailOnClick={() => {
                                    changeOperationMode(Operation.EDIT);
                                    _handleModuleChange('Deliveries');
                                }}
                            />
                        )} />
                    <Route
                        key={'ProductCatalogue'}
                        path={`${path.endsWith('/') ? path : `${path}/`}product-catalogue*`}
                        exact
                        render={(routeProps) => (
                            <ProductCatalogue
                                match={{
                                    url: routeProps?.match?.url,
                                    path: routeProps?.match?.path,
                                }}
                                ref={worksaleCatalogueRef}
                                changeSelectedTab={_handleModuleChange}
                                onSaleLinesAdded={refetchSummary}
                            />
                        )} />
                    <Route
                        key={'LineDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}line-details*`}
                        exact
                        render={(routeProps) => (
                            <LineDetails
                                {...routeProps}
                                forwardedRef={worksaleLineDetailsRef}
                                changeSelectedTab={_handleModuleChange}
                                summaryTableProps={{
                                    isLoading: false,
                                    summaryTableSchema: LineDetailsSummaryTableSchema
                                }}
                                onSaleLineChanged={refetchSummary}
                                closeLostsaleModal={() => {
                                    closeLotSaleDialog();
                                }}
                                onShortSuppliedLostsaleRequest={onShortSuppliedLostsaleRequest}
                            />
                        )} />
                </div>
            </div>
            <ActionBar {...actionBarProps} onActionClick={handleActionClick} />
            {recallQuoteOpen ?
                <RecallQuoteDialog
                    open={recallQuoteOpen}
                    salesEntity={salesEntity}
                    onClose={() => { setRecallQuoteOpen(false); }}
                    onOk={handleRecallQuote}
                /> : undefined}
            {orderRecallOpen ?
                <OrderRecallDialog
                    open={orderRecallOpen}
                    recallOrNewOrder={recallOrNewOrder}
                    customerId={currentWorksaleData?.WorkSale?.Criteria?.inlineObject.CustomerId}
                    salesEntity={currentWorksaleData?.WorkSale?.Criteria?.inlineObject.SalesEntity}
                    date={currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.Date}
                    period={currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.Period}
                    CustomerPurchaseOrder={currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.CustomerPurchaseOrder ?? ''}
                    onClose={() => { setOrderRecallOpen(false); setRecallOrNewOrder(false); }}
                    onOk={() => { setOrderRecallOpen(false); }}
                    onRecallSuccess={onRecallSuccessfully}
                    createWorkSale={handleApplyWorksaleCriteria}
                /> : undefined}
            {quoteConfirmationOpen ?
                <QuoteConfirmation
                    open={quoteConfirmationOpen}
                    onCancel={() => { setQuoteConfirmationOpen(false); }}
                    onOk={handleOkQuoteConfirmation}
                /> : undefined}
            <ParksaleConfirmation
                open={parksaleConfirmationOpen}
                onCancel={() => { setParksaleConfirmationOpen(false); }}
                onOk={handleOkParksaleConfirmation}
            />
            <OrderConfirmation
                open={salesOrderConfirmationOpen}
                operationInProgress={operationInProgress}
                processSalesOrderQuery={processSalesOrderQuery}
                onCancel={() => { setSalesOrderConfirmationOpen(false); }}
                onOk={(query) => { handleOkSalesOrderConfirmation(query, false).catch((err) => { console.warn(err); }); }}
            />
            <SaleConfirmation
                open={saleConfirmationOpen}
                operationInProgress={operationInProgress}
                processSaleQuery={processSaleQuery}
                onCancel={() => { setSaleConfirmationOpen(false); }}
                onOk={(query) => { handleOkSaleConfirmation(query, false).catch((err) => { console.warn(err); }); }}
            />
            <WorksaleMessages
                open={worksaleMessagesOpen}
                messages={worksaleMessages}
                onBack={onWorksaleMessagesBack}
                onContinue={onWorksaleMessagesContinue}
            />
            <PaymentEntryWrapper
                open={paymentEntryOpen}
                onCancel={async () => { setPaymentEntryOpen(false); }}
                onApply={handleApplyPaymentEntry}
            />
            <RecallParkedSaleDialog
                worksaleId={worksaleId}
                open={recallParkedSaleOpen}
                onRecall={onRecallSuccessfully}
                onCancel={recallParkedSaleOnCancel} />

            <LostSalesDialog
                open={dialogStates.open}
                loading={false}
                data={dialogStates.data}
                type={dialogStates.type}
                onOk={dialogStates.onOk}
                onCancel={() => {
                    closeLotSaleDialog();
                }}
            />
            <TransmitInternalPODialog
                open={transmitInternalPODialogOpen}
                salesOrder={salesOrder}
                purchaseOrders={purchaseOrders}
                onCancel={transMitInternalonCancel} />
        </div >
    );
}
export default withRouter(Worksale);
