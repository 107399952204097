import { IState } from './BackorderCommitment.reducer';

export interface IActions {
    [BackorderCommitmentActionTypes.setSalesOrderId]?: IState['SalesOrderId'];
    [BackorderCommitmentActionTypes.setPurchaseOrderId]?: IState['PurchaseOrderId'];
    [BackorderCommitmentActionTypes.setPurchaseOrderLineId]?: IState['PurchaseOrderLineId'];
    [BackorderCommitmentActionTypes.setCommitmentLineNumber]?: IState['CommitmentLineNumber'];
    [BackorderCommitmentActionTypes.setOpenInternalOrderDialog]?: IState['OpenInternalOrderDialog'];
    [BackorderCommitmentActionTypes.setInternalOrderDialogData]?: IState['InternalOrderDialogData'];
}

export enum BackorderCommitmentActionTypes {
    setSalesOrderId = 'setSalesOrderId',
    setPurchaseOrderId = 'setPurchaseOrderId',
    setPurchaseOrderLineId = 'setPurchaseOrderLineId',
    setCommitmentLineNumber = 'setCommitmentLineNumber',
    setOpenInternalOrderDialog = 'setOpenInternalOrderDialog',
    setInternalOrderDialogData = 'setInternalOrderDialogData',
}
