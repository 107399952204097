import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import PriceCheck from './PriceCheck';
import { actions as detailActions, selectors as detailSelectors } from 'ducks/priceCheck/details';
import { actions as whyActions, selectors as whySelectors } from 'ducks/priceCheck/why';
import { actions as trueCostActions } from 'ducks/priceCheck/trueCost';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { actions as priceCalculatorActions } from 'ducks/common/priceCalculator';
import { selectors as configSelectors } from 'ducks/appConfig';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  details: detailSelectors.details(state),
  customerPriceCode: detailSelectors.customerPriceCode(state),
  loadingDetails: detailSelectors.isLoading,
  priceCalculationFactors: whySelectors.why(state),
  filters: filterSelectors.getFilters(state, 'PriceCheckFilters'),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state),
  isV2: configSelectors.isV2(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getDetails: detailActions.fetch,
    getCustomerPriceSummary: detailActions.fetchCustomerSummary,
    getPriceCalculationFactors: whyActions.fetch,
    closeMenu: uiActions.closeMainMenu,
    fetchDefaultTrueCostValues: trueCostActions.fetchDefaultTrueCostValues,
    calculatePrice: priceCalculatorActions.fetchPriceDetails,
    clearDetails: detailActions.clearDetails
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceCheck);
