import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState, asyncOnRequest, asyncOnSuccess, asyncOnError,
  IDataAction, IExtendedState, SuccessAction, asyncSelectors
} from '../utils';
import { FormViewField } from 'components/FormView';

export interface IProductPriceScheduleData {
  selectedFilters: any;
  selected: any;
  details?: any;
  settings?: any;
  sellPrices?: any;
  comments?: any;
  gridOptions: IDataGridOptions;
  Actions: any;
  lineColumns: IColDef[];
  lines: any[];
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
}

export interface IProductPriceScheduleState extends IExtendedState<IProductPriceScheduleData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => (row),
    asyncs: {
      fetchDetails: (query) => (query),
      fetchSettings: (query) => (query),
      fetchSellPrices: (query) => (query),
      fetchComments: (query) => (query),
    }
  },
  'productPriceSchedule');

const initialState = asyncInitialState<IProductPriceScheduleData>({
  selectedFilters: {
    TransactionOption: 'Unfinalised',
    FromDate: '',
    ToDate: '',
  },
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'ProductPriceScheduleDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  filterRow: {
    formName: 'SupplierDocumentsFilters',
    parameters: [
      {
        id: 0,
        type: 'SELECT_FIELD',
        props: {
          label: 'Show',
          name: 'TransactionOption',
          defaultValue: 'Unfinalised',
          required: false,
          size: 'medium',
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            },
          ]
        }
      },
      {
        id: 1,
        type: 'DATE_FIELD',
        props: {
          label: 'Start range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px'
          }
        }
      },
    ] as FormViewField[]
  },
  lineColumns: [
    { headerName: 'Start', field: 'Start', minWidth: 150 },
    { headerName: 'End', field: 'End', suppressSorting: true, minWidth: 150 },
    { headerName: 'Buy price', field: 'BuyPriceDisplay', minWidth: 150, type: 'numericColumn', suppressSorting: true },
    { headerName: 'Unit', field: 'Unit', suppressSorting: true, minWidth: 150 },
    { headerName: 'Pricing type', field: 'PricingType', minWidth: 150, suppressSorting: true },
    { headerName: 'Description', field: 'Description', minWidth: 150 },
  ],
  lines: [],
  details: null,
  settings: null,
  sellPrices: null,
  comments: null,
});

export default (state: IProductPriceScheduleState = initialState, action: IDataAction): IProductPriceScheduleState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.fetchDetails:
      return asyncOnRequest(state, action);
    case types.saga.fetchDetails.success:
      return asyncOnSuccess(state, action, (data: IProductPriceScheduleData, successAction: SuccessAction) => {
        return {
          ...data,
          details: successAction.payload
        };
      });
    case types.saga.fetchDetails.failure:
      return asyncOnError(state, action);
    case types.fetchSettings:
      return asyncOnRequest(state, action);
    case types.saga.fetchSettings.success:
      return asyncOnSuccess(state, action, (data: IProductPriceScheduleData, successAction: SuccessAction) => {
        return {
          ...data,
          settings: successAction.payload
        };
      });
    case types.saga.fetchSettings.failure:
      return asyncOnError(state, action);
    case types.fetchSellPrices:
      return asyncOnRequest(state, action);
    case types.saga.fetchSellPrices.success:
      return asyncOnSuccess(state, action, (data: IProductPriceScheduleData, successAction: SuccessAction) => {
        return {
          ...data,
          sellPrices: successAction.payload
        };
      });
    case types.saga.fetchSellPrices.failure:
      return asyncOnError(state, action);
    case types.fetchComments:
      return asyncOnRequest(state, action);
    case types.saga.fetchComments.success:
      return asyncOnSuccess(state, action, (data: IProductPriceScheduleData, successAction: SuccessAction) => {
        return {
          ...data,
          comments: successAction.payload
        };
      });
    case types.saga.fetchComments.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule,
    {
      details: (data: IProductPriceScheduleData) => data.details,
      settings: (data: IProductPriceScheduleData) => data.settings,
      sellPrices: (data: IProductPriceScheduleData) => data.sellPrices,
      comments: (data: IProductPriceScheduleData) => data.comments,
    }),
  isLoading: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.loading,
  gridOptions: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.data.gridOptions,
  lineColumns: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.data.lineColumns,
  filterRow: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.data.filterRow,
  selectedFilters: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.data.selectedFilters,
  selected: (state: { supplierProductEnquiry: { productPriceSchedule: IProductPriceScheduleState } }) => state.supplierProductEnquiry.productPriceSchedule.data.selected
};
