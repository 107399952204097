import { IFormViewForm } from 'components/FormView';

const DeliveryDetails: IFormViewForm = {
  id: 'DeliveryDetails',
  layout: {
    rows: 3,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_FIELD',
          props: {
            label: 'Site',
            name: 'SiteCode',
            placeholder: '',
            size: 'small',
            required: true
          }
        },
        {
          id: '1',
          type: 'SELECT_FIELD',
          visible: true,
          props: {
            label: 'Delivery type',
            name: 'DeliveryType',
            placeholder: '',
            size: 'small',
            apiPrefs: {
              path: '/CustomTypes/Lookup/DeliveryType',
              method: 'GET',
              params: null
            },
          }
        },
        {
          id: '2',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Default delivery',
            name: 'Primary'
          }
        },
        {
          id: '3',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Save site to customer account',
            name: 'SaveDelivery'
          }
        }
      ]
    },
    {
      id: '2',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Site address',
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: '',
            name: 'Name',
            placeholder: 'Name of addressee',
            size: 'large',
            required: true
          }
        },
        {
          id: '2',
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            label: 'Delivery address',
            name: 'address'
          }
        }
      ]
    },
    {
      id: '3',
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Site contact details',
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Contact name',
            name: 'Contact',
            placeholder: '',
            size: 'large',
            required: false,
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Contact phone',
            name: 'Phone',
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Contact fax',
            name: 'Fax',
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Contact mobile',
            name: 'Mobile',
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Contact email',
            type: 'email',
            name: 'Email',
            size: 'large',
            action: {
              iconName: 'Mail',
            }
          }
        }
      ]
    },
    {
      id: '4',
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Shipping',
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            lookupName: 'FreightCode',
            placeholder: '',
            size: 'small',
            required: false
          }
        },
        {
          id: '2',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Freight type',
            name: 'FreightType',
            lookupName: 'FreightType',
            suppressValidation: true,
            suppressDescription: true,
            size: 'small'
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Carrier',
            name: 'Carrier',
            suppressValidation: true,
            suppressDescription: true,
            lookupName: 'CarrierCode',
            size: 'large',
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Freight account',
            name: 'FreightAccount',
            placeholder: '',
            size: 'large',
          }
        },
        {
          id: '5',
          type: 'TEXT_FIELD',
          props: {
            label: 'Instructions',
            name: 'Shipping',
            placeholder: '',
            size: 'large',
          }
        }
      ]
    },
    {
      id: '5',
      position: {
        row: 2,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Site Settings',
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Territory',
            name: 'Territory',
            lookupName: 'SalesTerritory',
            placeholder: '',
            size: 'small',
            required: true
          }
        },
        {
          id: '2',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Sales rep',
            name: 'SalesRepresentative',
            lookupName: 'SalesRep',
            placeholder: '',
            size: 'small',
            required: false
          }
        }
      ]
    }
  ]
};
export default DeliveryDetails;
