import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import { ISummaryPanelProps, ISummaryPanelState } from '../../../interfaces';
import MaterialLabourDataGrid from './MaterialLabourDataGrid';

const inlineStyles = createStyles({
    summaryGrid: {
        width: 680,
        height: 600
    }
});

class SummaryPanel extends Component<ISummaryPanelProps, ISummaryPanelState> {
    getDetailTemplate = () => {
        return (<div>Master detail cell rendrer</div>);
    }

    render(): React.ReactNode {
        const { classes, dataGridData } = this.props;

        return (
            <div className={`review-job-summary-panel ${classes.summaryGrid}`}>
                <MaterialLabourDataGrid data={dataGridData} />
            </div>
        );
    }
}

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
