import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
  }
}, 'BOM');


const initialState = asyncInitialState({
  gridOptions: {
    doubleClickActionTab: 'ComponentDetails',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  BOMLineColumns: [
    { headerName: 'Line', field: 'Line', type: 'numericColumn', suppressSorting: true, minWidth: 100 },
    {
      headerName: 'Product',
      field: 'ProductDisplay',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'On backorder', field: 'BackorderQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Description', field: 'Description', suppressSorting: true },
    { headerName: 'Comment', field: 'Comment', suppressSorting: true },
  ],
  BOMLinesData: [
  ],
  BOMLineActions: [
  ]

});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
   
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.BOM,
  {
    selected: data => data.selected,
    selectedBOMLine: data => data.selected,
    BOMLinesData: data => data.BOMLinesData,
    BOMLineActions: data => data.BOMLineActions,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.BOM.loading,
  gridOptions: state => state.salesOrderEnquiry.BOM.data.gridOptions,
  BOMLineColumns: state => state.salesOrderEnquiry.BOM.data.BOMLineColumns,
  BOMLineActions: state => state.salesOrderEnquiry.BOM.data.BOMLineActions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)