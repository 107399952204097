import { combineReducers } from 'redux';
import prices from './prices'
import priceMatrix from './priceMatrix'
import schedule from './schedule'
import futureBreaks from './futureBreaks'
import priceBreaks from './priceBreaks'
import foreignPrices from './foreignPrices'

const associationsReducer = combineReducers({
  prices,
  priceMatrix,
  schedule,
  futureBreaks,
  priceBreaks,
  foreignPrices
});

export default associationsReducer;
