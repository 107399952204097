import { BaseApi } from './baseApi';

export enum LookupType {
  Fixed = 'Fixed',
  Dynamic = 'Dynamic'
}

export enum ValueType {
  Int32 = 'Int32',
  String = 'String'
}

export interface ILookupOption {
  Code: string;
  Label?: string;
  Value?: string;
  Display?: string;
  Description?: string;
  Details?: any;
}

export interface ILookupSearch {
  Path: string;
  Label: string;
  Method: string;
  Template: any;
}

export interface ILookupRequest {
  SearchText: string;
  BatchPage?: number;
}

export interface ILookupResponse {
  LookupType: LookupType;
  ValueType: ValueType;
  Data: ILookupOption[];
}

export class CustomLookupType extends BaseApi {
  LookupName: string;
  LookupType: LookupType;
  ValueType: ValueType;
  Data?: ILookupOption[];
  Search?: ILookupSearch;
  Scope?: string;

  constructor(lookupName: string, lookupType: LookupType, valueType: ValueType, data?: ILookupOption[], search?: ILookupSearch, scope?: string) {
    super();
    this.LookupName = lookupName;
    this.LookupType = lookupType;
    this.ValueType = valueType;
    this.Data = data;
    this.Search = search;
    this.Scope = scope;
  }

  async getOption(value: string, params?: { ProductId?: number; CustomerId?: number; WarehouseEntity?: string; Entity?: string; WorksaleID?: number }): Promise<ILookupOption> {
    if (this.Data) {
      return Promise.resolve(this.Data[value]);
    }

    if (this.Scope === 'Product' && params.ProductId) {
      return this.get<ILookupOption>(`CustomTypes/Product/${params.ProductId}/Lookup/${this.LookupName}/Option/${value}`);
    } else if (this.Scope === 'Customer' && params.CustomerId) {
      return this.get<ILookupOption>(`CustomTypes/Customer/${params.CustomerId}/Lookup/${this.LookupName}/Option/${value}`);
    } else if (this.Scope === 'WarehouseProduct' && params.ProductId && params.WarehouseEntity) {
      return this.get<ILookupOption>(`CustomTypes/Warehouse/${params.WarehouseEntity}/Product/${params.ProductId}/Lookup/${this.LookupName}/Option/${value}`);
    } else if (this.Scope === 'Entity' && params.Entity) {
      return this.get<ILookupOption>(`CustomTypes/SalesEntity/${params.Entity}/Lookup/${this.LookupName}/Option/${value}`);
    } else if (this.Scope === 'WorksaleDelivery' && params.WorksaleID) {
      return this.get<ILookupOption>(`CustomTypes/Worksale/${params.WorksaleID}/Lookup/${this.LookupName}/Option/${value}`);
    } else {
      return this.get<ILookupOption>(`CustomTypes/Lookup/${this.LookupName}/Option/${value}`);
    }
  }

  testExactOption(response: ILookupResponse, key: string, option: ILookupOption, hasSearch: boolean, searchText?: string): boolean {
    if (!hasSearch) {
      return false;
    }

    if (searchText.toLocaleUpperCase() !== key.toLocaleUpperCase()) {
      return false;
    }

    response.Data[key] = { ...option };

    return true;
  }

  testOption(response: ILookupResponse, key: string, option: ILookupOption, hasSearch: boolean, searchText?: string): void {
    if (!hasSearch) {
      response.Data[key] = { ...option };

      return;
    }

    if (searchText.toLocaleUpperCase() === key.toLocaleUpperCase()) {
      response.Data[key] = { ...option };

      return;
    }

    if (option && option.Value && option.Value.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0) {
      response.Data[key] = { ...option };

      return;
    }

    if (option && option.Display && option.Display.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0) {
      response.Data[key] = { ...option };

      return;
    }

    if (option && option.Label && option.Label.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0) {
      response.Data[key] = { ...option };

      return;
    }

    if (option && option.Description && option.Description.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0) {
      response.Data[key] = { ...option };

      return;
    }
  }

  async searchOptions(search: ILookupRequest): Promise<ILookupResponse> {
    if (this.Search) {
      return this.post<ILookupRequest, ILookupResponse>(this.Search.Path, search);
    }

    const response: ILookupResponse = {
      LookupType: this.LookupType,
      ValueType: this.ValueType,
      Data: []
    };

    if (!this.Data) {
      return Promise.resolve(response);
    }

    const hasSearch: boolean = search.SearchText && search.SearchText.length > 0;

    // Return exact match if found.
    for (const key in this.Data) {
      if (this.Data.hasOwnProperty(key)) {
        const option: ILookupOption = this.Data[key];
        if (this.testExactOption(response, key, option, hasSearch, search.SearchText)) {
          return Promise.resolve(response);
        }
      }
    }

    // Perform case insensitive search.
    for (const key in this.Data) {
      if (this.Data.hasOwnProperty(key)) {
        const option: ILookupOption = this.Data[key];
        this.testOption(response, key, option, hasSearch, search.SearchText);
      }
    }

    return Promise.resolve(response);
  }
}

class CustomTypes extends BaseApi {
  static LookupsLoaded: boolean = false;
  static LookupsLoadPromise: Promise<any> = null;
  static Lookups: { [index: string]: CustomLookupType } = {
    Mock: new CustomLookupType('Mock', LookupType.Fixed, ValueType.Int32, [
      // tslint:disable:object-literal-key-quotes
      { Code: '1', Display: '00001', Label: 'Anne', Description: 'First' },
      { Code: '2', Display: '00002', Label: 'Bob', Description: 'Second' },
      { Code: '3', Display: '00003', Label: 'Charles', Description: 'Third' }
      // tslint:enable:object-literal-key-quotes
    ])
  };

  static async lookupFromLoaded(href: string): Promise<CustomLookupType> {
    if (CustomTypes.Lookups[href]) {
      return Promise.resolve(CustomTypes.Lookups[href]);
    } else {
      return Promise.reject('Lookup not defined');
    }
  }

  async lookup(lookupName: string): Promise<CustomLookupType> {
    if (CustomTypes.LookupsLoaded || lookupName === 'Mock') {
      return CustomTypes.lookupFromLoaded(lookupName);
    }

    if (CustomTypes.LookupsLoadPromise) {
      return CustomTypes.LookupsLoadPromise.then(async () => {
        return CustomTypes.lookupFromLoaded(lookupName);
      });
    }

    CustomTypes.LookupsLoadPromise = this.get<{ [index: string]: ILookupResponse }>('CustomTypes/Lookup').then(async (response) => {
      CustomTypes.Lookups = {};
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          const lookupValue: any = response[key];
          CustomTypes.Lookups[key] = new CustomLookupType(
            key,
            lookupValue.LookupType,
            lookupValue.ValueType,
            lookupValue.Data,
            lookupValue.Search,
            lookupValue.Scope);
        }
      }
      CustomTypes.LookupsLoaded = true;
    });

    return CustomTypes.LookupsLoadPromise.then(async () => {
      return CustomTypes.lookupFromLoaded(lookupName);
    });
  }

  lookupGet(lookupName: string, params?: any): { (value: string): Promise<ILookupOption> } {

    return async (value) => {
      return this.lookup(lookupName).then(async (lookupValue) => lookupValue.getOption(value, params));
    };
  }

  lookupSearch(lookupName: string): { (search: ILookupRequest): Promise<ILookupResponse> } {
    return async (search) => {
      return this.lookup(lookupName).then(async (lookupValue) => lookupValue.searchOptions(search));
    };
  }
}

const CustomTypesApi: CustomTypes = new CustomTypes();

export { CustomTypesApi };
