import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from './../utils'

export const searchLocalShipments = (query) => {
  const { PurchaseOrder, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(
    `/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/IntransitLocalShipments`,
    { BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '', BatchSize },
    'Inline'
  )
    .then((result) => {
      if (result) {
        if (result.LocalShipments) {
          return {
            records: result.LocalShipments.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}

export const searchImportShipments = (query) => {
  const { PurchaseOrder, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/IntransitImportShipments`,
    { BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '', BatchSize }, 'Inline')
    .then((result) => {
      if (result) {
        if (result.ImportShipments) {
          return {
            records: result.ImportShipments.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}
