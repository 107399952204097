const SummaryTableSchema = [
  {
    objectName: 'ComponentSummary',
    isLoading: 'loadingComponentSummary',
    label: 'Component details',
    children: [
      {
        label: 'Product',
        fieldNames: [
          'ProductIdDisplay',
          'ProductIdLabel'
        ],
        valueRenderer: {
          api: {
            api: '/inventoryEnquiry/lookup/action',
            name: 'enquiry',
            params: {
              queryFields: {
                ProductId: 'ProductId',
                SearchText: 'ProductId'
              }
            }
          }
        }
      },
      {
        label: 'Product Group',
        fieldNames: [
          'ProductGroup',
          'ProductGroupLabel'
        ]
      },
      {
        label: 'Product Type',
        fieldNames: [
          'ProductTypeLabel'
        ]
      },
    ]
  },
  {
    objectName: 'ComponentSummary',
    isLoading: 'loadingComponentSummary',
    children: [
      {
        label: 'Warehouse',
        fieldNames: [
          'WarehouseEntity',
          'WarehouseEntityDescription'
        ],
      },
      {
        label: 'Available',
        fieldNames: [
          'AvailableInWarehouseDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'Elsewhere',
        fieldNames: [
          'AvailableElsewhereDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
    ]
  },
  {
    objectName: 'KitSummary',
    isLoading: 'loadingKitSummary',
    label: 'Kit product details',
    children: [
      {
        label: 'Product',
        fieldNames: [
          'ProductIdDisplay',
          'ProductIdLabel'
        ],
        valueRenderer: {
          api: {
            api: '/inventoryEnquiry/lookup/action',
            name: 'enquiry',
            params: {
              queryFields: {
                ProductId: 'ProductId',
                SearchText: 'ProductId'
              }
            }
          }
        }
      },
      {
        label: 'Product Group',
        fieldNames: [
          'ProductGroup',
          'ProductGroupLabel'
        ]
      },
      {
        label: 'Kitting Quantity',
        fieldNames: [
          'KittingQuantityDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true,
        }
      },
    ]
  }
];

export default SummaryTableSchema;
