import * as React from 'react';
import { isNull } from 'utils/utils';

import { IConfigurationProperties } from './MTDSaleByRep.properties';
import configurationSchema from './MTDSaleByRep.form.json';
import ChartContainer from 'components/common/Charts/Charts.container';
import BarChart from 'components/common/Charts/BarChart';
import { IDashboardWidgetMTDSalesFacade } from 'api/swaggerTypes';
const BAR_PADDING = 0.5;

const MTDSaleByRep = (props: IConfigurationProperties): JSX.Element => {
    const { getSaleHistory, touchFormFields, widgetInfo, modalFormValues = {}, updateFormField } = props;
    const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
    const [isConfigurationSet, setIsConfigurationSet] = React.useState<boolean>(false);
    const [salesHistoryData, setSalesHistoryData] = React.useState<IDashboardWidgetMTDSalesFacade[]>([]);
    const [subtitle, setSubtitle] = React.useState<string>('');
    const [initValues, setInitValues] = React.useState<any>({});

    React.useEffect(
        () => {
            if (widgetInfo && widgetInfo.Configuration) {
                const configuration = JSON.parse(widgetInfo.Configuration);
                setIsConfigurationSet(true);
                fetchSalesHistory(configuration.Entity, configuration.EntityView);
            }
        },
        [widgetInfo]
    );

    const fetchSalesHistory = (wh, entView) => {
        setInitValues({
            Entity: wh,
            EntityView: entView,
        });
        getSaleHistory({ Warehouse: wh, EntView: entView })
            .then((res) => {
                const { MTDSales } = res;
                const data = MTDSales && MTDSales.inlineObject;
                const history = data && data.SalesHistory;
                setSubtitle(data && data.CurrentPeriod || '');
                setSalesHistoryData(history);
            }).catch((err) => {
                console.warn(err);
            });

    };

    const validateConfiguration = () => (!isNull(modalFormValues.EntityView) && !isNull(modalFormValues.Entity));

    const handleOkClick = () => {
        if (validateConfiguration()) {
            fetchSalesHistory(modalFormValues.Entity, modalFormValues.EntityView);
            setIsConfigurationSet(true);
            setFilterOpen(false);
        } else {
            touchFormFields('Entity');
            touchFormFields('EntityView');
        }
    };
    const handleCancelClick = () => {
        setFilterOpen(false);
    };
    const onEntityViewChange = () => {
        updateFormField('Entity', '');
    };

    return (<div >
        <ChartContainer
            isConfigurationSet={isConfigurationSet}
            filterOpen={filterOpen}
            title='MTD Sales by Rep'
            widgetInfo={widgetInfo}
            modalFormProps={{
                formSchema: configurationSchema,
                initialValues: initValues,
                formValues: modalFormValues,
                fieldExtensions: {
                    EntityView: {
                        onSelectedItemChange: onEntityViewChange,
                        onSelectedValueChange: onEntityViewChange
                    },
                    Entity: {
                        params: { ParentView: modalFormValues.EntityView }
                    },
                }
            }}
            setFilterOpen={setFilterOpen}
            modalOkClick={handleOkClick}
            modalCancelClick={handleCancelClick}
            validateConfiguration={validateConfiguration}
        >
            <BarChart
                chartData={salesHistoryData}
                config={{
                    id: 'MTDSales',
                    title: 'MTD Sales by Rep',
                    subtitle,
                    argumentField: 'SalesRep',
                    valueAxisTitle: 'Value $',
                    series: [
                        { valueField: 'MTDGrossSales', name: 'Gross Sale (MTD)' },
                        { valueField: 'MTDCostSales', name: 'Cost of Sale (MTD)', barPadding: BAR_PADDING },
                        { valueField: 'PreviousPeriodGrossSales', name: 'Gross Sale (Prev Mth)' },
                        { valueField: 'PreviousPeriodCostSales', name: 'Cost of Sale (Prev Mth)', barPadding: BAR_PADDING },
                    ]
                }}
            />
        </ChartContainer>

    </div >);
};

export default MTDSaleByRep;
