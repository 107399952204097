import { createStyles } from '@material-ui/core/styles';

export const OrderRecallStyles = createStyles({
    OrderRecallContainer: {
        'maxWidth': 1000,
        'height': 542,
        'display': 'flex',
        'flexDirection': 'column',
        '@media screen and (max-width: 770px)': {
            height: 582,
        },
        '@media screen and (max-width: 428px)': {
            height: 624,
        }
    },
    OrderRecallToogleContaner: {
        'display': 'flex',
        'flexDirection': 'column',
        'backgroundColor': '#dddddd',
        'bottom': 0,
        'position': 'absolute',
        'width': '100%',
        'height': '38px',
        '& label': {
            marginLeft: '16px',
        }
    }
});

export const toggleStyles = createStyles({
    switchBase: {
        '&$checked': {
            '& + $bar': {
                backgroundColor: '#6DD900',
                opacity: 1
            },
        },
    },
    bar: {},
    checked: {},
    formControlLabel: {
        '&$disabled': {
            color: '#555555',
        },
    },
    disabled: {

    },
});
