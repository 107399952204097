import * as React from 'react';
import ExtendedTextField from '@markinson/uicomponents-v2/ExtendedTextField';
import IExtendedTextFieldProps from '@markinson/uicomponents-v2/ExtendedTextField/ExtendedTextField.properties';
import RestockingCalculatorModal from './RestockingCalculatorModal';
interface IRestockingCalculatorFieldState {
    browseOpen: boolean;
}

export interface IRestockingCalculatorProps extends IExtendedTextFieldProps {
    params: any;
    calculator: any;
}

class RestockingCalculatorField extends React.PureComponent<IRestockingCalculatorProps, IRestockingCalculatorFieldState> {

    constructor(props: Readonly<IRestockingCalculatorProps>) {
        super(props);
        this.state = {
            browseOpen: false
        };
    }

    openBrowseModal = (): void => {
        this.setState({ browseOpen: true });
    }

    closeBrowseModal = (): void => {
        this.setState({ browseOpen: false });
    }

    handleOk = (value: any): void => {
        this.props.onChange(value);
        this.setState({ browseOpen: false });
    }

    render(): React.ReactNode {
        const { name, value, calculator, helpText, ...rest } = this.props;
        const { browseOpen } = this.state;

        return (
            <>
                <ExtendedTextField
                    value={value}
                    helpText={helpText}
                    action={{ iconName: 'Calculator', controller: this.openBrowseModal }}
                    {...rest}
                />
                <RestockingCalculatorModal open={browseOpen} onClose={this.closeBrowseModal} onOk={this.handleOk} Calculator={calculator || {}} />

            </>
        );
    }
}

export default RestockingCalculatorField;
