import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as movementSelectors, actions as movementActions } from 'ducks/inventoryEnquiry/stocking/movements';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/stocking/movements';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingMovements',
  columnDefs: movementSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: movementSelectors.gridOptions(state),
  filterRow: movementSelectors.filterRow(state),
  selectedFilters: movementSelectors.selectedFilters(state),
  appliedFilters: movementSelectors.filterRow(state) && filterSelectors.getFilters(state, movementSelectors.filterRow(state).formName),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
    {
      ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
    },

  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: movementActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
