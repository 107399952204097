export default [
  {
    id: '1',
    name: 'Trade',
    abbreviation: 'TRD',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Exchange',
    abbreviation: 'EXC',
    fieldList1: 'field'
  },
];
