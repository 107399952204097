import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    mainPanel: {
        width: '17vw',
        minWidth: '320px',
        maxWidth: '320px',
        backgroundColor: '#666666',
        float: 'right',
        paddingBottom: 10,
        height: 'calc(100vh - 182px)',
    },
});
