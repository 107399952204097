import { IFormViewForm } from 'components/FormView';

const CustomerForm: IFormViewForm = {
  id: 'PolicyDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Policy Details',
        name: 'policy-details',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Policy',
            name: 'Policy',
            size: 'large',
            required: true
          }
        },
        {
            id: 1,
            type: 'TEXT_FIELD',
            visible: true,
            props: {
              label: 'Comment',
              name: 'Comment',
              size: 'large',
              required: true
            }
          }
      ]
    }
  ]
};
export default CustomerForm;
