
export const MODULE_TREE = [
    { id: 'InvoiceDetails', label: 'Invoice Details', parent: '' },
    { id: 'Comments', label: 'Comments', parent: '' },
    { id: 'InvoiceLines', label: 'Invoice Lines', parent: '' },
    { id: 'LineDetails', label: 'Line Details', parent: 'InvoiceLines' },
    { id: 'LotDetails', label: 'Lot Details', parent: 'InvoiceLines' },
    { id: 'SerialNumbers', label: 'Serial Numbers', parent: 'InvoiceLines' },
    { id: 'Movements', label: 'Movements', parent: 'InvoiceLines' },
    { id: 'Events', label: 'Events', parent: '' },
    { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' }
];
