import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
  }
}, 'pricesByWH');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Available', field: 'AvailableQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Sell price (ex tax)', field: 'SellPriceExclTaxDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Sell price (inc tax)', field: 'SellPriceInclTaxDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'GP', field: 'GrossProfitPercentDisplay', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Base price', field: 'BasePriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Retail price', field: 'RetailPriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Average cost', field: 'AverageCostDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Last cost', field: 'LastCostDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Allocated quantity', field: 'AllocatedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Last price change', field: 'LastPriceChangeDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Previous base', field: 'PreviousBasePriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Previous retail', field: 'PreviousRetailPriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Last receipt', field: 'LastReceiptDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.priceCheck.pricesByWH,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.priceCheck.pricesByWH.loading,
  gridOptions: state => state.priceCheck.pricesByWH.data.gridOptions,
  columns: state => state.priceCheck.pricesByWH.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)