const OrderDetails = {
  'id': 'OrderDetails',
  'layout': {
    'rows': 3,
    'columns': 3
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Postal address',
      },
      'children': [
        {
          'id': 0,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'label': 'Postal address',
            'name': 'PostalAddress',
            'type': 'extended',
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Delivery address'
      },
      'children': [
        {
          'id': 0,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'name': 'DeliveryAddress',
            'type': 'extended',
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Delivery contact details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Delivery contact',
            'name': 'DeliveryContact',
            'placeholder': '',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Phone',
            'name': 'Phone',
            'placeholder': '',
            'required': false,
            'size': 'medium',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 2,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Mobile',
            'name': 'Mobile',
            'placeholder': '',
            'required': false,
            'size': 'medium',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 3,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Email',
            'name': 'Email',
            'placeholder': '',
            'required': false,
            'size': 'large',
            'action': {
              'iconName': 'Mail',
            }
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sales order type',
            'name': 'SalesOrderType',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Priority',
            'name': 'PriorityLabel',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true,
            'hideInV2': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Terms (days)',
            'name': 'Terms',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'One invoice per despatch',
            'name': 'OneInvoicePerDespatch',
            'required': false,
            'disabled': true,
            'defaultValue': false
          }
        },
        {
          'id': 4,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Allow partial delivery',
            'name': 'AllowPartialDelivery',
            'required': false,
            'disabled': true,
            'defaultValue': false
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sales rep',
            'name': 'SalesRepresentative',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Entered by',
            'name': 'EnteredBy',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Order category',
            'name': 'OrderCategory',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Territory',
            'name': 'Territory',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax class',
            'name': 'TaxClass',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax exempt reference',
            'name': 'TaxExemptReference',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Currency',
            'name': 'Currency',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Exchange rate',
            'name': 'ExchangeRateDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'disabled': true,
            'alignment': 'right',
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 3,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Invoice instructions',
            'name': 'InvoiceInstructions1',
            'placeholder': '',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': '',
            'name': 'InvoiceInstructions2',
            'placeholder': '',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Return authority',
            'name': 'ReturnAuthority',
            'placeholder': '',
            'helpText': '',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price category',
            'name': 'PriceCategory',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 3,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 1',
            'name': 'Category1',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 2',
            'name': 'Category2',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 3',
            'name': 'Category3',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 4',
            'name': 'Category4',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 5',
            'name': 'Category5',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 6',
            'name': 'Category6',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        }
      ]
    }
  ]
}
export default OrderDetails;