import * as React from 'react';
import FormView from '../../FormView/index';
import { withRouter } from 'react-router';
import { Operation } from 'utils/operations';
import UserNotifications from '../../common/UserNotifications/index';
import { IDeliveryContactProps } from '../interfaces';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class Contacts extends React.PureComponent<IDeliveryContactProps> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      onInitialLoad('DeliveryDetails');
    }
  }

  componentDidUpdate(prevProps: Readonly<IDeliveryContactProps>): void {
    const { operationMode, selectedAddress } = this.props;
    if ((prevProps.operationMode === Operation.EDIT || prevProps.operationMode === Operation.NEW)
      && operationMode === Operation.SAVE
    ) {
      this.submitFormValues(prevProps.operationMode === Operation.NEW);
    }
    if (operationMode === Operation.DELETE && selectedAddress) {
      this.deleteAction();
    }
    if (operationMode === Operation.CANCEL || operationMode === Operation.BACK) {
      this.cancelFormSubmission(prevProps);
    }
  }

  cancelFormSubmission = (prevProps: Readonly<IDeliveryContactProps>) => {
    const {
      dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad, initializeForm, selectedAddress, operationMode
    } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          changeOperationMode(Operation.BROWSE);
          initializeForm(selectedAddress);
        }
      });
    } else {
      if (operationMode === Operation.CANCEL) {
        changeOperationMode(Operation.BROWSE);
        initializeForm(selectedAddress);
      } else {
        if (prevProps.operationMode === Operation.EDIT) {
          changeOperationMode(Operation.BROWSE);
        } else {
          onInitialLoad('CustomerDetails');
          changeOperationMode(Operation.BROWSE);
        }
      }
    }
  }

  deleteAction = () => {
    const { changeConfirmationDialog, changeOperationMode } = this.props;
    changeConfirmationDialog({
      open: true,
      title: 'Delete selected record',
      message: 'Are you sure you want to delete the selected record?',
      okLabel: 'Delete',
      onCancel: () => {
        changeOperationMode(Operation.BROWSE);
      },
      onOk: () => {
        this.removeRecord();
      }
    });
  }

  removeRecord = () => {
    const { changeOperationMode, location, resetForm, removeAddress, selectedAddress, selectedCustomer } = this.props;
    const params = new URLSearchParams(location.search);
    const CustomerId = (selectedCustomer && selectedCustomer.CustomerId) || parseInt(params.get('CustomerId'));
    if (selectedAddress) removeAddress(CustomerId, selectedAddress.SiteCode);
    resetForm();
    changeOperationMode(Operation.BROWSE);
  }

  submitFormValues = (create?: boolean): void => {
    const { selectedAddress, values, updateAddress, isValid, formSyncErrors, changeOperationMode, createAddress, selectedCustomer } = this.props;
    if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0)) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }
      if (create) {
        changeOperationMode(Operation.EDIT);

        return;
      }
      changeOperationMode(Operation.NEW);

      return;
    }
    const params = new URLSearchParams(location.search);
    const CustomerId = (selectedCustomer && selectedCustomer.CustomerId) || parseInt(params.get('CustomerId'));

    if (create) {
      createAddress(CustomerId, { ...values, ProceedWithUpdatePrimaryDelivery: null });
    } else {
      if (selectedAddress && CustomerId) updateAddress(CustomerId, selectedAddress.SiteCode, values);
    }

    changeOperationMode(Operation.BROWSE);
  }

  getInitialValues = (): any => {
    const { selectedAddress, selectedAddressSchema, operationMode } = this.props;
    if (operationMode === Operation.NEW) {
      return {
        initialValues:
          { Name: this.props.selectedCustomer?.Name },
        valuesSchema:
          {}
      };
    }

    return { initialValues: selectedAddress, valuesSchema: selectedAddressSchema };
  }

  render(): React.ReactNode {
    const { location, selectedForm, operationMode, dirty, isValid, formSyncErrors, addressNotifications, addressError, selectedCustomer, isV2 } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };
    const params = new URLSearchParams(location.search);
    const CustomerId = selectedCustomer ? selectedCustomer.CustomerId : parseInt(params.get('CustomerId'));

    return (selectedForm &&
      <React.Fragment>
        {((dirty
          && operationMode === Operation.SAVE) || (operationMode === Operation.BROWSE))
          && <UserNotifications
            notifications={addressNotifications}
            errors={addressError}
            formSyncErrors={!isValid && formSyncErrors}
            entity={'Address'}
            isValid={isValid}
          />}

        {(selectedForm.id === 'DeliveryDetails') &&
          (<FormView
            formName={'CustomerAddresses'}
            includeTabsHeight={true}
            schema={selectedForm}
            browseLookUpOpen={true}
            browseLookUpComponent={CustomerId && {
              variant: 'DeliveryDetails',
              searchParams: {
                CustomerId: CustomerId
              },
              searchPlaceholder: 'Search for addresses...',
              isBrowseLookUp: true
            }}
            initialValues={initialValues}
            operationMode={operationMode}
            valuesSchema={valuesSchema}
            fieldExtensions={{
              PhoneMobile: {
                visible: !isV2
              }
            }}
          />)}
      </React.Fragment>
    );
  }
}

export default withRouter(Contacts);
