import * as React from 'react';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import TechnicianSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Technician';

import BreadcrumbBar from '../common/BreadcrumbBar';
import * as styles from './RosterMaintenance.scss';
import options from './OptionItems.json';
import { Operation } from '../../utils/operations';
import { Route } from 'react-router';

import { IRosterMaintenanceViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

import RosterLookup from './RosterLookup';

const inlineStyles = {
  rosterMaintenanceSection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class RosterMaintenanceView extends React.PureComponent<IRosterMaintenanceViewProperties> {

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  componentDidUpdate(prevProps: Readonly<IRosterMaintenanceViewProperties>): void {
    const { operationMode, selectedTab, changeSelectedForm, selectedForm } = this.props;
    if (prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (!selectedForm) {
      changeSelectedForm('roster-maintenance', selectedTab);
    }
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'roster-maintenance';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    if (!!selectedTab) {
      this.props.changeSelectedForm(context, selectedTab);
    }
    this.props.history.push(this.getRoute()[selectedTab || null]);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'roster-maintenance';
    const TechnicianId = params.get('TechnicianId') || '';
    const RosterTemplateId = params.get('RosterTemplateId') || '';

    const routes = ({
      RosterMaintenance: { pathname: `/${context}`, search: `?TechnicianId=${TechnicianId}`, component: RosterLookup },
      RosterLookup: { pathname: `/${context}/rosterlookup`, search: `?TechnicianId=${TechnicianId}&RosterTemplateId=${RosterTemplateId}`, component: RosterLookup },
    });

    return routes;
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption, isMenuOptionOpen } = this.props;
    toggleMenuOption(!isMenuOptionOpen);
  }

  backAction = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.rosterMaintenanceOuter}>
        <div className={styles.rosterMaintenanceContainer}>
          <TechnicianSearchLookUpDrawer
            enableToggle={true}
          />
          <div
            style={inlineStyles.rosterMaintenanceSection}
            className={styles.rosterMaintenanceSection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Roster Maintenance'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                  formName={`${selectedTab}`}
                />}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={options}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'roster-maintenance'} />
      </div>
    );
  }
}

export default RosterMaintenanceView;
