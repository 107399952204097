const SummaryTableSchema = [
    {
        objectName: 'lineCommitmentSummary',
        isLoading: 'loadingLineCommitmentSummary',
        children: [
            {
                label: 'Product',
                fieldNames: [
                    'ProductIdDisplay',
                    'ProductIdDisplayLabel'
                ],
                valueRenderer: {
                    api: {
                        api: '/inventoryEnquiry/lookup/action',
                        name: 'enquiry',
                        params: {
                            queryFields: {
                                ProductId: 'ProductId',
                            }
                        }
                    }
                }
            },
            {
                label: 'Product Group',
                fieldNames: [
                    'ProductGroup',
                    'ProductGroupLabel'
                ]
            }
        ]
    },
    {
        objectName: 'lineCommitmentSummary',
        isLoading: 'loadingLineCommitmentSummary',
        children: [
            {
                label: 'Ordered',
                fieldNames: [
                    'OrderedQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Supplied',
                fieldNames: [
                    'SuppliedQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Kitting',
                fieldNames: [
                    'KittingQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'In Picking',
                fieldNames: [
                    'InPickingQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Backordered',
                fieldNames: [
                    'BackorderedQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            }
        ]
    },
    {
        objectName: 'lineCommitmentSummary',
        isLoading: 'loadingLineCommitmentSummary',
        children: [
            {
                label: 'Committed',
                fieldNames: [
                    'CommittedQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Reserved',
                fieldNames: [
                    'ReservedQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Backorder Outstanding',
                fieldNames: [
                    'OustandingBackorderQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            }
        ]
    },
    {
        objectName: 'lineCommitmentSummary',
        isLoading: 'loadingLineCommitmentSummary',
        children: [
            {
                label: 'Price/Unit',
                fieldNames: [
                    'PriceDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Discount',
                fieldNames: [
                    'DiscountDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit',
                fieldNames: [
                    'SellUnitDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                },
                extendedText: {
                    displayText: true,
                    apiFlagName: 'TaxIncluded',
                    texts: [
                        '(In. Tax)',
                        '(Ex. Tax)',
                    ]
                  }
            },
        ]
    }
];

export default SummaryTableSchema;
