import * as moment from 'moment';
import { objectify } from '../utils';
import { BaseApi } from '../baseApi';

export interface IGetPriceDetailsRequest {
  ProductId: number;
  CustomerId: number;
  SaleQuantity: number;
  SalesEntity: string;
  Warehouse: string;
  CustomerPriceCode: string;
  PriceCategory: string;
  EffectiveDate: string;
}

class PriceCheckDetailsApi extends BaseApi {
  constructor() {
    super();
  }

  async search(query: IGetPriceDetailsRequest): Promise<any> {
    const request = {
      ...query,
      CustomerId: query && query.CustomerId ? query.CustomerId : 0,
      EffectiveDate: query && query.EffectiveDate && moment(query.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    };

    return this
      .post<IGetPriceDetailsRequest, any>('PriceCheck/GetPriceDetails?responseMetadataFormat=Inline', request)
      .then((result) => result && ({ schema: result.PriceCheckDetails, value: { ...objectify(result.PriceCheckDetails), Customer: result.PriceCheckDetails.CustomerId.Display } }));
  }
}

const priceCheckDetailsApi = new PriceCheckDetailsApi();

export async function search(query: IGetPriceDetailsRequest): Promise<any> {
  return priceCheckDetailsApi.search(query);
}
