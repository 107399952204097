import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, fetchPost, objectify } from '../utils';

export const search = async (query: { PolicyId: number; Sort: string; Page: number; SortDirection: string; BatchSize?: number }): Promise<{ records: any; nextPage: boolean; currPage: number }> => {
    const { PolicyId, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return fetchGet(`/Security/Policy/${PolicyId}/UserGroups`, { BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}` }, 'Inline')
        .then((result) => (result && result.UserGroups &&
        {
            records: result.UserGroups.map((val) => (objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        })
            ||
        {
            records: [],
            nextPage: false,
            currPage: 1,
        });
};

export const addGroup = async (query: { UserGroup: string; PolicyId: number }) => {

    const { UserGroup, PolicyId } = query;

    return fetchPost(`/Security/Policy/${PolicyId}/AddUserGroups`, [UserGroup], {}, 'Inline')
        .then(() => ({ Message: 'Group Added Successfully.' })).catch((error) => { throw error; });
};

export const removeGroups = async (query: { UserGroups: string[]; PolicyId: number }) => {

    const { UserGroups, PolicyId } = query;

    return fetchPost(`/Security/Policy/${PolicyId}/RemoveUserGroups`, [...UserGroups], {}, 'Inline')
        .then(() => ({ Message: 'Group(s) removed Successfully.' })).catch((error) => { throw error; });
};
