import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from './../utils'

export const searchServiceOrders = (query) => {
  const { PurchaseOrder, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(
    `/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/CommittedServiceOrders`,
    { BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '', BatchSize },
    'Inline'
  )
    .then((result) => {
      if (result) {
        if (result.CommittedServiceOrders) {
          return {
            records: result.CommittedServiceOrders.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}

export const searchSalesOrders = (query) => {
  const { PurchaseOrder, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(
    `/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/CommittedSalesOrders`,
    { BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '', BatchSize },
    'Inline'
  )
    .then((result) => {
      if (result) {
        if (result.CommittedSalesOrders) {
          return {
            records: result.CommittedSalesOrders.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}

export const searchProductionOrders = (query) => {
  const { PurchaseOrder, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(
    `/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/CommittedProductionOrders`,
    { BatchSize, BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '' },
    'Inline'
  )
    .then((result) => {
      if (result) {
        if (result.CommittedProductionOrders) {
          return {
            records: result.CommittedProductionOrders.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}