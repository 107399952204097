import * as React from 'react';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import SummaryPanel from './SummaryPanel';
import { withRouter, Route } from 'react-router-dom';
import BreadcrumbBar from './../common/BreadcrumbBar';
import * as styles from './CustomerQuoteEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from 'utils/operations';
import QuoteSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Quote';
import QuoteDetails from './QuoteDetails';
import { shallowCompare } from '../../utils/utils';
import QuoteLines from './QuoteLines';
import Notepad from './NotePad';
import Diary from './Diary';
import { ICustomerQuoteEnquiryViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  customerEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class CustomerQuoteEnquiryView extends React.Component<ICustomerQuoteEnquiryViewProperties> {

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'customer-quote-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    const notCustomer = selectedTab === 'S/Order' || selectedTab === 'Diary';
    this.props.toggleBrowseLookUp(notCustomer);
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    const { pathname, search } = this.getRoute()[selectedTab || 'QuoteDetails'];
    this.props.history.push({ pathname, search });
    this.fetchChildRecords(selectedTab);
  }
  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'customer-quote-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const Quote = params.get('Quote') || '';
    const LineNumber = params.get('LineNumber');
    const DiaryId = params.get('DiaryId');
    const BomLineNumber = params.get('BomLineNumber');
    const routes = {
      QuoteDetails: { pathname: `/${context}/quote-details`, search: `?Quote=${Quote}`, component: QuoteDetails },
      QuoteSummary: { pathname: `/${context}/quote-details-summary`, search: `?Quote=${Quote}`, component: QuoteDetails },
      Comments: { pathname: `/${context}/quote-details-comments`, search: `?Quote=${Quote}`, component: QuoteDetails },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?Quote=${Quote}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?Quote=${Quote}&DiaryId=${DiaryId}`, component: Diary },
      Notepad: { pathname: `/${context}/notepad`, search: `?Quote=${Quote}`, component: Notepad },
      QuoteLines: { pathname: `/${context}/quote-lines`, search: `?Quote=${Quote}`, component: QuoteLines },
      LineDetails: { pathname: `/${context}/quote-lines/details`, search: `?Quote=${Quote}&LineNumber=${LineNumber}`, component: QuoteLines },
      BillOfMaterials: { pathname: `/${context}/quote-lines/bom`, search: `?Quote=${Quote}&LineNumber=${LineNumber}`, component: QuoteLines },
      ComponentDetails: { pathname: `/${context}/quote-lines/bom/component-details`, search: `?Quote=${Quote}&LineNumber=${LineNumber}&BomLineNumber=${BomLineNumber}`, component: QuoteLines },
      BOMReservationDetails: { pathname: `/${context}/quote-lines/bom/reservation-details`, search: `?Quote=${Quote}&LineNumber=${LineNumber}&BomLineNumber=${BomLineNumber}`, component: QuoteLines },
      BOMReservationLots: { pathname: `/${context}/quote-lines/bom/reservation-lots`, search: `?Quote=${Quote}&LineNumber=${LineNumber}&BomLineNumber=${BomLineNumber}`, component: QuoteLines },
      BOMReservationSerials: { pathname: `/${context}/quote-lines/bom/reservation-serials`, search: `?Quote=${Quote}&LineNumber=${LineNumber}&BomLineNumber=${BomLineNumber}`, component: QuoteLines },
      ReservationDetails: { pathname: `/${context}/quote-lines/reservation-details`, search: `?Quote=${Quote}&LineNumber=${LineNumber}`, component: QuoteLines },
      ReservationLots: { pathname: `/${context}/quote-lines/lot-details`, search: `?Quote=${Quote}&LineNumber=${LineNumber}`, component: QuoteLines },
      ReservationSerials: { pathname: `/${context}/quote-lines/serial-numbers`, search: `?Quote=${Quote}&LineNumber=${LineNumber}`, component: QuoteLines },
      null: { pathname: `/${context}/`, search: null, component: QuoteDetails, },
    };

    return routes;
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<ICustomerQuoteEnquiryViewProperties>): boolean {
    return shallowCompare(this, nextProps);
  }

  fetchChildRecords = (selectedTab: string) => {
    switch (selectedTab) {
      case 'QuoteDetails':
      case 'Comments':
        this.getQuoteDetails();
        break;
      case 'QuoteLines':
        this.getQuoteLines();
        break;
      case 'QuoteSummary':
        this.getQuoteSummary();
        break;
      case 'BillOfMaterials':
        this.getBillOfMaterials();
        break;
      case 'ReservationDetails':
        this.getReservationDetails();
        break;
      case 'BOMReservationDetails':
        this.getBOMReservationDetails();
        break;
      case 'ReservationSerials':
        this.getReservationSerials();
        break;
      case 'BOMReservationSerials':
        this.getBOMReservationSerials();
        break;
      case 'ReservationLots':
        this.getReservationLots();
        break;
      case 'BOMReservationLots':
        this.getBOMReservationLots();
        break;
      case 'ComponentDetails':
        this.getComponentDetails();
        break;
      case 'LineDetails':
        this.getLineDetails();
        break;
      default:
    }
  }

  getQuoteDetails = () => {
    const { selectedQuote } = this.props;
    if (selectedQuote) {
      this.props.fetchQuoteDetails({ QuoteId: selectedQuote.Quote || '' });
      this.props.fetchQuoteSummaryPanel({ QuoteId: selectedQuote.Quote || '' });
    }
  }

  getQuoteLines = () => {
    const { selectedQuote } = this.props;
    if (selectedQuote) {
      this.props.fetchQuoteLines({ QuoteId: selectedQuote.Quote });
    }
  }

  getQuoteSummary = () => {
    const { selectedQuote } = this.props;
    if (selectedQuote) {
      this.props.fetchQuoteSummary({ QuoteId: selectedQuote.Quote });
      this.props.fetchQuoteSummaryPanel({ QuoteId: selectedQuote.Quote });
    }
  }

  getBillOfMaterials = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    if (QuoteID && lineNumber) {
      this.props.fetchQuoteLineSummary({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getReservationDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    if (QuoteID && lineNumber) {
      this.props.fetchQuoteLineSummary({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchReservationDetails({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getBOMReservationDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine, selectedBOMLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    const BomLineNumber = selectedBOMLine ? selectedBOMLine.LineNumber : params.get('BomLineNumber');
    if (BomLineNumber && lineNumber && QuoteID) {
      this.props.fetchBOMReservationDetails({ QuoteId: QuoteID, LineNumber: BomLineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getReservationSerials = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    if (QuoteID && lineNumber) {
      this.props.fetchQuoteLineSummary({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getBOMReservationSerials = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine, selectedBOMLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    const BomLineNumber = selectedBOMLine ? selectedBOMLine.LineNumber : params.get('BomLineNumber');
    if (BomLineNumber && lineNumber && QuoteID) {
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getReservationLots = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    if (QuoteID && lineNumber) {
      this.props.fetchQuoteLineSummary({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getBOMReservationLots = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine, selectedBOMLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    const BomLineNumber = selectedBOMLine ? selectedBOMLine.LineNumber : params.get('BomLineNumber');
    if (BomLineNumber && lineNumber && QuoteID) {
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getComponentDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine, selectedBOMLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    const BomLineNumber = selectedBOMLine ? selectedBOMLine.LineNumber : params.get('BomLineNumber');
    if (BomLineNumber && lineNumber && QuoteID) {
      this.props.fetchBOMLineDetailSummary({ QuoteId: QuoteID, LineNumber: BomLineNumber });
      this.props.fetchBOMLineDetails({ QuoteId: QuoteID, LineNumber: BomLineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  getLineDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { selectedQuote, selectedQuoteLine } = this.props;
    const QuoteID = selectedQuote ? selectedQuote.Quote : params.get('Quote');
    const lineNumber = selectedQuoteLine ? selectedQuoteLine.LineNumber : params.get('LineNumber');
    if (QuoteID && lineNumber) {
      this.props.fetchQuoteLineSummary({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteLineDetail({ QuoteId: QuoteID, LineNumber: lineNumber });
      this.props.fetchQuoteSummaryPanel({ QuoteId: QuoteID });
    }
  }

  componentDidUpdate(prevProps: Readonly<ICustomerQuoteEnquiryViewProperties>): void {
    const { operationMode, selectedTab } = this.props;

    if (selectedTab !== prevProps.selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('customer-quote-enquiry', this.props.selectedTab);
    }
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'QuoteDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('QuoteDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      case 'LineDetails':
      case 'BillOfMaterials':
      case 'ReservationLots':
      case 'ReservationSerials':
      case 'ReservationDetails':
        return options.QuoteLineDetails;
      case 'ComponentDetails':
      case 'BOMReservationDetails':
      case 'BOMReservationLots':
      case 'BOMReservationSerials':
        return options.BOMLineDetails;
      default: return options.CustomerQuoteEnquiry;
    }
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <QuoteSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[() => { this.fetchChildRecords(selectedTab); }]}
          />
          <div
            style={inlineStyles.customerEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Customer Quote Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                />}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'customer-quote-enquiry'} />
      </div>
    );
  }
}

export default withRouter(CustomerQuoteEnquiryView);
