import * as React from 'react';
import InlineActionButton from '@markinson/uicomponents-v2/InlineActionButton';
import InputLabel from '@material-ui/core/InputLabel';
import { StyledComponentProps, withStyles, createStyles } from '@material-ui/core';

const classStyles = createStyles({
  labelText: {
    fontFamily: 'Roboto, sans-serif',
    color: '#777777',
    display: 'block',
    fontSize: 15,
    fontWeight: 700,
    padding: '0 8px'
  },
  containerPadding: {
    padding: '0 0 16px 0',
    display: 'flex',
    alignItems: 'center',
  }
});

export interface IActionButtonProps extends StyledComponentProps {
  label?: string;
  readOnly: boolean;
  disabled: boolean;
}

class ActionButton extends React.Component<IActionButtonProps> {

  render(): React.ReactNode {
    const { label, classes, readOnly, disabled, ...remaining } = this.props;

    return (
      <div
        className={classes.containerPadding}>

        <InlineActionButton
          disabled={readOnly || disabled}
          {...remaining}
        />
        <InputLabel
          classes={{
            root: classes.labelText
          }}
        >{label}</InputLabel>
      </div>
    );
  }
}

export default withStyles(classStyles, {index: 1})(ActionButton);
