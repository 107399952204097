import * as React from 'react';
import { IPaymentEntryWrapperProps } from './PaymentEntryWrapper.properties';
import PaymentEntryDialog from '../PaymentEntryDialog';
import { ObjectifiedPaymentDetail, ObjectifiedPaymentDetailsResponse } from 'api/Worksale/payment';
import { processValidationForms } from 'utils/processValidationForms';
import { IPaymentDetailFacade, IProcessPaymentDetailsResponse } from 'api/swaggerTypes';
import { showSnackBar } from '../SnackBars/SnackBar.hooks';
import { useFetchPaymentInformation } from 'api/Worksale/worksale';
import { useSPContextSelector } from 'components/Worksale/Worksale.context';
import Selectors from 'components/Worksale/Worksale.selectors';

function PaymentEntry(props: IPaymentEntryWrapperProps): JSX.Element {
  const { open, onApply, onCancel, onAddNewPaymentLine, onCalculatePaymentLineDetails, onUpdatePaymentLineDetails, onDeletePaymentLine, changeConfirmationDialog, onCalculatePaymentAmount, onInitializePayment, onProcessPayment } = props;
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const { data: paymentInformation } = useFetchPaymentInformation(worksaleId);
  const [initialPaymentEntryValues, setInitialPaymentEntryValues] = React.useState<ObjectifiedPaymentDetail>();

  const initialValues = React.useMemo(() => (paymentInformation?.PaymentInformationDetail), [paymentInformation]);
  React.useEffect(
    () => {
      if (initialValues) {
        onInitializePaymentAndValidate(initialValues).then((response) => {
          if (response.Status && response?.PaymentDetail) {
            setInitialPaymentEntryValues(response?.PaymentDetail);
          }
        });
      }
    },
    [initialValues]
  );

  async function onProcessPaymentAndValidate(query: IPaymentDetailFacade): Promise<IProcessPaymentDetailsResponse> {
    return processValidationForms(await onProcessPayment(query), query, onProcessPaymentAndValidate);
  }

  async function onInitializePaymentAndValidate(query: any): Promise<ObjectifiedPaymentDetailsResponse> {
    return processValidationForms(await onInitializePayment(query), query, onInitializePaymentAndValidate);
  }

  async function handleApplyPaymentEntry(query: IPaymentDetailFacade): Promise<void> {
    const response = await onProcessPaymentAndValidate(query);
    if (!response.Status || !response.ProcessPaymentDetail) {
      showSnackBar({ variant: 'error', message: 'Failed to process payment.' });

      return;
    }

    onApply(response);
  }

  return (
    <React.Fragment>
      <PaymentEntryDialog
        open={open}
        initialValues={initialPaymentEntryValues}
        onCancel={async () => { onCancel(); }}
        onAddNewPaymentLine={onAddNewPaymentLine}
        onCalculatePaymentLineDetails={onCalculatePaymentLineDetails}
        onUpdatePaymentLineDetails={onUpdatePaymentLineDetails}
        onDeletePaymentLine={onDeletePaymentLine}
        onCalculatePaymentAmount={onCalculatePaymentAmount}
        onApply={handleApplyPaymentEntry}
        changeConfirmationDialog={changeConfirmationDialog}
      />
    </React.Fragment>);
}

export default PaymentEntry;
