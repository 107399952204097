import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import renderField from '../DataGridDevEx/CustomRenderers/RenderField';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, isDirty, reset, isValid } from 'redux-form';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as lookupSelectors, actions as lookupActions } from 'ducks/lookup';

const styles = createStyles({
  table: {
    marginBottom: '15px'
  },
  filterCell: {
    width: '170px',
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    padding: '10px',

  },
  filterLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '34px'
  },
  valueCell: {
    backgroundColor: '#dddddd',
    border: '1px solid #fff',
    padding: '10px',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  filterMenuPaper: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '400px',
    top: '80px',
    height: 'auto',
    zIndex: 100,
  },
  footer: {
    backgroundColor: '#e0e0e0',
    height: '50px'
  },
  applyButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    float: 'right',
    lineHeight: '34px'
  },
  fieldsContainer: {
    padding: '15px'
  }
});

interface IFilterRowProperties extends StyledComponentProps {
  formName: string;
  form: string;
  isValid: boolean;
  values: any;
  hidePreFilters: boolean;
  initialValues: any;
  parameters: any;
  reqParams: any;
  valuesSchema: any;
  lookupList: any;
  operationMode: string;
  rowModelType?: string;
  onBlur(event: any): boolean;
  reset(formName: string): void;
  fetchLookups(something: any): any;
  onApplyFilters?(filters: any): void;
}

interface IFilterRowState {
  apply: boolean;
}

class FilterRow extends React.Component<IFilterRowProperties, IFilterRowState> {

  constructor(props: Readonly<IFilterRowProperties>) {
    super(props);

    this.state = {
      apply: false
    };
  }

  applyFilter = () => {
    this.setState({ apply: true });
  }

  componentDidUpdate(prevProps: Readonly<IFilterRowProperties>): void {
    const { values, reqParams, isValid: valid, onApplyFilters, reset: resetForm, formName } = this.props;
    if (this.state.apply) {
      if (valid) {
        if (onApplyFilters) {
          onApplyFilters(values);
        }
      }
      this.setState({ apply: false });
    }
    //This is the case where the data in LookupDrawer changes and FilterRow needs update.
    if (JSON.stringify(reqParams) !== JSON.stringify(prevProps.reqParams)) {
      if (resetForm) {
        reset(formName);
      }
    }
  }

  render(): React.ReactNode {
    const { classes, parameters } = this.props;

    return (
      <Paper className={classes.filterMenuPaper}>
        <div className={classes.fieldsContainer}>
          {parameters.map((childFieldSchema, childRowId) => {
            const { valuesSchema, operationMode, onBlur, values } = this.props;
            const stateAndProps = { valuesSchema, operationMode, onBlur, values };
            const fieldData = { childFieldSchema, childRowId };

            return renderField(fieldData, stateAndProps);
          })}
        </div>
        <div className={classes.footer}>
          <Button
            variant={'contained'}
            className={classes.applyButton}
            onClick={this.applyFilter}>
            <DoneAllIcon style={{ color: 'green' }} /> APPLY
                      </Button>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => (
  {
    operationMode: operationSelectors.operationMode(state),
    validate: ownProps.validate,
    form: ownProps.formName,
    key: ownProps.formName,
    values: getFormValues(ownProps.formName)(state),
    dirty: isDirty(ownProps.formName)(state),
    isValid: isValid(ownProps.formName)(state),
    lookupList: lookupSelectors.all(state)
  });

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    reset: reset,
    fetchLookups: lookupActions.fetch,
  },
  dispatch);

export default compose<any, any, any>(
  connect(mapStateToProps, {}),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: true
  })
)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {index: 1})(FilterRow)));
