import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as purchaseOrderTypes, actions as purchaseOrderActions } from 'ducks/purchaseOrderEnquiry/branchOrders';

import { callApi } from 'sagas/utils';
import * as api from 'api/purchaseOrderEnquiry/purchaseOrderBranchOrders';

function* fetchSummary(action) {
  const { success, failure } = purchaseOrderActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

export default function* rootSalesOrderSaga() {
  yield takeLatest(purchaseOrderTypes.fetchSummary, fetchSummary)
}