import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as productTypes, actions as productActions } from 'ducks/product';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/product';


function* search(action) {
  const { success, failure } = productActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchDetail(action){
  const { success, failure } = productActions.saga.fetchDetail;

  yield callApi(
    call(api.fetchDetail, action.data),
    success,
    failure
  )
}
export default function* rootProductSaga() {
  yield takeLatest(productTypes.search, search)
  yield takeLatest(productTypes.fetchDetail, fetchDetail)
}