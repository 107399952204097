import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as serviceAgreementActions, selectors as serviceAgreementSelectors } from 'ducks/serviceAgreementMaintenance/serviceAgreement';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';
import { IServiceAgreementFacade } from 'api/swaggerTypes';

interface IServiceAgreementDetailFacade extends IServiceAgreementFacade {
    CustomerLabel?: string;
}
const ServicePattern = buildSearchLookUpDrawer<IServiceAgreementDetailFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IServiceAgreementDetailFacade>,
    'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IServiceAgreementDetailFacade>,
    'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'ServiceAgreements',
        searchPlaceholder: 'Search for Service Agreements...',
        operationMode: operationSelectors.operationMode(state),
        sortingFilters: [
            {
                filter: 'Description',
                label: 'Description'
            },
            {
                filter: 'Customer',
                label: 'Customer'
            },
        ],
        records: serviceAgreementSelectors.all(state) || [],
        selectedRecord: serviceAgreementSelectors.selected(state),
        selectedSortFilter: 'Description',
        selectedSortDirection: 'Ascending',
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: serviceAgreementSelectors.isLoading(state),
        nextPage: serviceAgreementSelectors.nextPage(state),
        prevPage: serviceAgreementSelectors.prevPage(state),
        pageSize: serviceAgreementSelectors.pageSize(state),
        totalPages: serviceAgreementSelectors.totalPages(state),
        loadingPrevPage: serviceAgreementSelectors.loadingPrevPage(state),
        loadingNextPage: serviceAgreementSelectors.loadingNextPage(state),
        searchParams: {},
        keyField: 'ServiceAgreementId',
        fieldsToDisplay: ['Description', null, 'CustomerLabel', null],
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
            if (selectedRecord) {
                matchParams.set('ServiceAgreementId', selectedRecord.ServiceAgreementId);
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const serviceAgreementId = matchParams.get('ServiceAgreementId');
            if (serviceAgreementId) {
                return {
                    id: serviceAgreementId,
                };
            }

            return null;
        },
    });

const actionCreators: DispatchProps = {
    search: serviceAgreementActions.search,
    searchById: serviceAgreementActions.searchById,
    changeSelectedRecord: serviceAgreementActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    fetchNextPage: serviceAgreementActions.fetchNextPage,
    fetchPrevPage: serviceAgreementActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServicePattern);
