import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import LoginForm from '../LoginForm';
import { Entry } from '../../config';

export interface ILoginFields {
  staffUserName: string;
  staffPassword: string;
}

interface ILoginProperties extends RouteComponentProps {
  loggedIn: boolean;
  isAdmin?: boolean;
  isLoading: boolean;
  error: string;
  login(fields: ILoginFields): void;
}

class Login extends React.Component<ILoginProperties> {

  componentDidMount(): void {
    document.title = 'MomentumPro';
  }

  handleSubmit = (fields: ILoginFields) => {
    this.props.login(fields);
  }

  getRedirectRoute = (): { pathname: string; search?: string } => {
    const { history } = this.props;
    if (history.location.state && (history.location.state as any).from) {
      const { pathname, search } = (history.location.state as any).from;

      return { pathname, search };
    }

    if (Entry && Entry === 'security-management') {
      return { pathname: '/security-policies' };
    }

    return { pathname: '/dashboard' };
  }

  render(): React.ReactNode {
    const { loggedIn, isAdmin } = this.props;
    const route = this.getRedirectRoute();
    if ((Entry && Entry === 'security-management' && loggedIn && isAdmin) || ((!Entry || Entry !== 'security-management') && loggedIn)) {
      return (
        <Redirect
          to={{
            ...route,
            state: { from: this.props.location }
          }} />
      );
    }

    return (
      <LoginForm isLoading={this.props.isLoading} onSubmit={this.handleSubmit} error={
        this.props.error || (Entry && Entry === 'security-management'
          && loggedIn && !isAdmin) && 'Please sign in as an administrator.'
      } />
    );
  }
}

export default withRouter(Login);
