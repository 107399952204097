import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Paper, CircularProgress } from '@material-ui/core';
import BillToIdActionList from 'utils/data/db/actions/BillToIdActionList';
import SalesOrderActionList from 'utils/data/db/actions/SOrderActions';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps } from '../interfaces';
import { pathOr } from 'utils/utils';
import WarningIconDialog from 'components/common/WarningIconDialog';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important',
    whiteSpace: 'pre-line'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  iconWarning: {
    'height': '24px',
    'width': '24px',
    'verticalAlign': 'middle',
    'color': 'red',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  statusContainer: {
    display: 'flex'
  },
});
const pSize = CircularProgressSize;
const paperElevation = PaperElevation;

class SummaryPanel extends React.PureComponent<ISummaryPanelProps> {

  componentDidUpdate(prevProps: any): void {
    const { search, saleTotal, filters, getSaleDetails } = this.props;

    if (filters && (!prevProps || !prevProps.filters || prevProps.filters !== filters)) {
      const { customer, order } = filters;

      if (customer && customer.CustomerAccountNumber) {
        search(customer.CustomerAccountNumber);
      }
      if (order && order.SalesOrder) {
        saleTotal(order.SalesOrder);
        getSaleDetails(order.SalesOrder);
      }
    }
  }

  appendParam = (actionList: any, invoiceSummary: any) => {
    if (invoiceSummary && invoiceSummary.CustomerId && invoiceSummary.CustomerId.Value) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: invoiceSummary.CustomerId.Value, CustomerAccountNumber: invoiceSummary.CustomerId.Display };

        return item;
      });
    }

    return actionList;
  }

  appendParamSO = (actionList1: any, invoiceSummary: any) => {
    if (invoiceSummary && invoiceSummary.SalesOrder && invoiceSummary.SalesOrder.Value) {
      return actionList1.map((item) => {
        item.actionParam = { SalesOrder: invoiceSummary.SalesOrder.Value };

        return item;
      });
    }

    return actionList1;
  }

  getInvoiceDetails = (): { [key: string]: any } => {
    const actionList = BillToIdActionList;
    const actionList1 = SalesOrderActionList;
    const { invoiceSummary } = this.props;
    const showAlert = pathOr(false, ['Alert', 'Value'], invoiceSummary);
    const alertMessage = pathOr(false, ['AlertMessage', 'Value'], invoiceSummary);

    return ({
      'DOCUMENT': <div>
        <span >{invoiceSummary && invoiceSummary.DocumentType && invoiceSummary.DocumentType.Value}</span>
        <br />
        <span >{invoiceSummary && invoiceSummary.InvoiceNumber && invoiceSummary.InvoiceNumber.Value}</span>
      </div>,
      'SALES ENTITY': <div>
        <span >{invoiceSummary && invoiceSummary.SalesEntity && invoiceSummary.SalesEntity.Value}</span>
        <br />
        <span >{invoiceSummary && invoiceSummary.SalesEntity && invoiceSummary.SalesEntity.Label}</span>
      </div>
      ,
      'CUSTOMER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{invoiceSummary && invoiceSummary.CustomerId && invoiceSummary.CustomerId.Display}</span>
          <br />
          <span >{invoiceSummary && invoiceSummary.CustomerId && invoiceSummary.CustomerId.Label}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParam(actionList, invoiceSummary)}
              disabled={!(invoiceSummary && invoiceSummary.CustomerId)}
            />
          }
        </div>
      </div>,
      'CUSTOMER P/O': invoiceSummary && invoiceSummary.CustomerPurchaseOrder.Value,
      'S/ORDER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{invoiceSummary && invoiceSummary.SalesOrder.Value}</span>
          <br />
          <span >{invoiceSummary && invoiceSummary.SalesOrderDescription.Value}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParamSO(actionList1, invoiceSummary)}
              disabled={!(invoiceSummary && invoiceSummary.SalesOrder.Value)}

            />
          }
        </div>
      </div>,
      'INVOICE STATUS': <div style={inlineStyles.statusContainer}>
        <div>{pathOr('', ['InvoiceStatus', 'Value'], invoiceSummary)}</div>
        {showAlert &&
          <WarningIconDialog title='Alert' message={alertMessage} />}
      </div>,
    });
  }

  getDates = (): { [key: string]: any } => {
    const { invoiceSummary } = this.props;

    return ({
      'ENTERED': invoiceSummary && invoiceSummary.EnteredDateTime.Value,
      'INVOICED': invoiceSummary && invoiceSummary.InvoicedDateTime.Value,
      'FINANCIAL PERIOD': invoiceSummary && invoiceSummary.FinancialPeriod.Value,
    });
  }

  getTotals = (): { [key: string]: any } => {
    const { invoiceSummary } = this.props;

    return ({
      'CURRENCY': invoiceSummary && invoiceSummary.CurrencyCode.Value,
      'CHARGES': invoiceSummary && invoiceSummary.Charges && invoiceSummary.Charges.Display,
      'TAX TOTAL': invoiceSummary && invoiceSummary.TaxTotal && invoiceSummary.TaxTotal.Display,
      'INVOICE TOTAL': invoiceSummary && invoiceSummary.InvoiceTotal && invoiceSummary.InvoiceTotal.Display,
    });
  }

  render(): React.ReactNode {
    const { classes, isLoading } = this.props;
    const invoiceDetails = this.getInvoiceDetails();
    const dates = this.getDates();
    const totals = this.getTotals();
    const numeric = (property) => ['CHARGES', 'TAX TOTAL', 'INVOICE TOTAL'].includes(property);

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Document details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(invoiceDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {invoiceDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(dates).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {dates[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>Totals</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                < Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(totals).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            numeric={numeric(property)}
                            className={property === 'INVOICE TOTAL'
                              ? classes.totalCell :
                              numeric(property) ?
                                classes.numericCell
                                : classes.dataCell}>
                            {totals[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
