import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IPeriodAdjustmentProps } from '../DynamicExtractModal.properties';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';
import SelectContainer from 'components/SelectContainer';
import { PROGRAM_ID } from '../DynamicExtractModal.constants';

const PeriodAdjustment = ({ classes, details }: IPeriodAdjustmentProps) => {

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <SelectContainer
                label={'Period handling'}
                value={WizardDetails?.BatchPeriodHandling}
                onChange={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, BatchPeriodHandling: v }
                    });
                }}
                apiPrefs={({
                    LookupType: 'Dynamic',
                    path: '/CustomTypes/Lookup/WizardBSuitePeriodHandling/Search',
                    method: 'POST'
                })}
                params={{
                    SearchText: '',
                    ProgramId: PROGRAM_ID,
                    BatchPage: 1,
                    BatchSize: 100
                }}
                required={false}
                defaultFirstValue={WizardDetails?.BatchPeriodHandling === null}
            />
            <SelectContainer
                label={'Date handling'}
                value={WizardDetails?.BatchDateHandling}
                onChange={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, BatchDateHandling: v }
                    });
                }}
                apiPrefs={({
                    LookupType: 'Dynamic',
                    path: '/CustomTypes/Lookup/WizardBSuiteDateHandling/Search',
                    method: 'POST'
                })}
                params={{
                    SearchText: '',
                    ProgramId: PROGRAM_ID,
                    BatchPage: 1,
                    BatchSize: 100
                }}
                required={false}
                defaultFirstValue={WizardDetails?.BatchDateHandling === null}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(PeriodAdjustment);
