import FormViewModal from 'components/common/Modals/FormViewModal';
import React from 'react';
import { IFreightChargesDialogProperties } from './FreightChargesDialog.properties';
import FreightChargesSchema from './FreightChargesDialog.form';
import { useCalculateFreight } from 'api/pickSlips/pickslip';
import { IFreightChargeFacade } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';

const FreightChargesDialog = (props: IFreightChargesDialogProperties) => {
    const { open, loading, values, data, selectedDespatchId, onCancel, onOk } = props;

    const calculateFreightMutation = useCalculateFreight();
    const [internalData, setInternalData] = React.useState<IObjectified<IFreightChargeFacade>>(data);

    React.useEffect(
        () => {
            setInternalData(data);
        },
        [data]
    );

    const calculateFreight = React.useCallback(
        async (_data) => {
            const response = await calculateFreightMutation.mutateAsync({
                DespatchId: selectedDespatchId,
                ..._data
            });
            if (response?.Status === true) {
                setInternalData(response?.FreightCharge);
            }
        },
        [selectedDespatchId]
    );

    const formFieldOnBlur = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const targetValue = e?.target?.value;
            const fieldName = e?.target?.name;

            if (fieldName) {
                const isValueChanged = Number(internalData?.inlineObject?.[fieldName]) !== Number(targetValue);

                if (isValueChanged) {
                    calculateFreight({
                        ...values,
                        [fieldName]: targetValue
                    });
                }
            }
        },
        [data, values, internalData?.inlineObject, calculateFreight]
    );

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Freight Charges'
                dialogActionsButtons={true}
                dialogActionsShadow={false}
                formProps={{
                    style: { height: '310px', minWidth: '283px' },
                    formSchema: FreightChargesSchema,
                    initialValues: internalData?.inlineObject,
                    valuesSchema: internalData?.schema,
                    onBlur: formFieldOnBlur,
                }}
                actions={[
                    {
                        title: 'Ok',
                        isDefault: true,
                        listener: () => {
                            if (onOk) {
                                onOk(values);
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default FreightChargesDialog;
