import { COLUMN_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export const columns = [
    {
        dataField: 'ProductIdDisplay',
        caption: 'Product',
    },
    {
        dataField: 'OrderedQuantityDisplay',
        caption: 'Ordered',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        format: '#0.00',
        allowSorting: false,
        isEditable: true,
        alignment: 'right'
    },
    {
        dataField: 'BackorderedQuantityDisplay',
        caption: 'On Order',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        format: '#0.00',
        allowSorting: false,
        isEditable: true,
        alignment: 'right'
    },
    {
        dataField: 'SuppliedQuantityDisplay',
        caption: 'Supplied',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        format: '#0.00',
        allowSorting: false,
        allowEditing: false,
        alignment: 'right'
    },
    {
        dataField: 'PriceDisplay',
        caption: 'Price',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        format: '#0.00',
        allowSorting: false,
        isEditable: true,
        alignment: 'right'
    },
    {
        dataField: 'BuyingUnit',
        caption: 'Unit',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        allowSorting: false,
        allowEditing: false
    },
    {
        dataField: 'SupplierProduct',
        caption: 'Supplier Product',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        allowSorting: false,
        isEditable: true,
    },
    {
        dataField: 'DueDate',
        caption: 'Due',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.md0,
        allowSorting: false,
        isEditable: true,
    },
    {
        dataField: 'Description',
        caption: 'Description',
        minWidth: COLUMN_SIZE.md1,
        width: COLUMN_SIZE.lg0,
        allowSorting: false,
        allowEditing: false,
    }
];
