import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import ChecklistsGrid from './ChecklistsGrid';
import { IChecklistSummaryProps } from '../../../interfaces';
import { IJobActivityChecklistsDetailFacade } from 'api/swaggerTypes';
import { isNull } from 'utils/utils';
import lodash from 'lodash';
import { IChecklistDataGridData } from './ChecklistsGrid.properties';

const inlineStyles = createStyles({
    summaryGrid: {
        width: 680,
        height: 600
    }
});

const SummaryPanel = (props: IChecklistSummaryProps): JSX.Element => {

    const {
        classes,
        serviceJobId,
        getChecklists,
        onChecklistSave,
    } = props;
    const [checklists, setChecklists] = React.useState<IJobActivityChecklistsDetailFacade[]>([]);
    const [gridData, setGridData] = React.useState<IChecklistDataGridData[]>([]);
    React.useEffect(
        () => {
            generateGridData();
        },
        [checklists]
    );
    React.useEffect(
        () => {
            getChecklistsData();
        },
        []);

    const getChecklistsData = () => {
        getChecklists({ ServiceJobId: serviceJobId }).then(
            (response) => {
                if (isNull(response.Forms)) {
                    const cls = response.Checklists.map((cl) => cl.inlineObject);
                    setChecklists(cls);
                }
            }
        ).catch((e) => { console.warn(e.message); });
    };

    const generateGridData = () => {

        const uniqueServiceItems = lodash.uniq(checklists.map((cl) => cl.ServiceItem));
        const gData = uniqueServiceItems.map((serviceItem) => {
            const itemChecklists = checklists.filter((question) => question.ServiceItem === serviceItem);
            const firstActivity = lodash.first(itemChecklists) as any;
            const serviceItemText = !isNull(firstActivity.ServiceItem) ? `${firstActivity.ServiceItemDisplay} - ${firstActivity.ServiceItemLabel}` : 'No Service Item';

            return {
                key: firstActivity.ServiceItem,
                ServiceItem: serviceItemText,
                ItemType: 'None',
                checklists: itemChecklists
            };

        });
        setGridData(gData);
    };

    const onSave = (updatedComment, checklistItem) => {
        const checklist = lodash.cloneDeep(checklists.find((cl) => cl.ChecklistID === checklistItem.ChecklistID));
        checklist.Comment = updatedComment;
        onChecklistSave({ ServiceJobId: serviceJobId, ChecklistId: checklist.ChecklistID, Checklist: checklist })
            .then((response) => {
                if (isNull(response.Forms)) {
                    getChecklistsData();
                }
            }
            ).catch((e) => { console.warn(e.message); });
    };

    const onQuestionSave = (checklistItem) => {
        onChecklistSave({ ServiceJobId: serviceJobId, ChecklistId: checklistItem.ChecklistID, Checklist: checklistItem })
            .then((response) => {
                if (isNull(response.Forms)) {
                    getChecklistsData();
                }
            }
            ).catch((e) => { console.warn(e.message); });
    };

    return (
        <div className={`review-job-summary-panel ${classes.summaryGrid}`}>

            <ChecklistsGrid
                onChecklistSave={onSave}
                onChecklistQuestionSave={onQuestionSave}
                gridData={gridData}
            />
        </div>
    );
};

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
