import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as supplierTypes, actions as pickSlipActions } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';
import { types as slipDetailTypes, actions as slipDetailsActions } from 'ducks/pickSlipEnquiry/slipDetails';
import { callApi } from '../utils';
import * as api from 'api/pickSlipEnquiry/pickSlipEnquiries';


function* search(action) {
  const { success, failure } = pickSlipActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action) {
  const { success, failure } = pickSlipActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchPickSlipDetails(action) {
  const { success, failure } = slipDetailsActions.saga.fetch;

  yield callApi(
    call(api.fetchSlipDetails, action.data),
    success,
    failure
  )
}

function* fetchPickSlipDetailsSummary(action) {
  const { success, failure } = slipDetailsActions.saga.fetchSummary;

  yield callApi(
    call(api.fetchSlipDetailsSummary, action.data),
    success,
    failure
  )
}

function* fetchNext(action) {
  const { success, failure } = pickSlipActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action) {
  const { success, failure } = pickSlipActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(supplierTypes.search, search)
  yield takeLatest(supplierTypes.searchById, searchById)
  yield takeLatest(slipDetailTypes.fetch, fetchPickSlipDetails)
  yield takeLatest(slipDetailTypes.fetchSummary, fetchPickSlipDetailsSummary)
  yield takeLatest(supplierTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(supplierTypes.fetchNextPage, fetchNext)
}