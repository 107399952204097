import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { ICommitmentsProperties } from '../interfaces';

class Commitments extends React.PureComponent<ICommitmentsProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/commitments/committed-purchase-order':
          onInitialLoad('CommittedPurchaseOrder');
          break;
        case '/sales-order-enquiry/commitments/committed-production-order':
          onInitialLoad('CommittedProductionOrder');
          break;
        case '/sales-order-enquiry/commitments/committed-in-put-away':
          onInitialLoad('CommittedInPutAway');
          break;
        default:
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, initialValues, operationMode, isLoading } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={this.props.notifications}
          entity='Commitments'
        />
        <FormView
          isLoading={isLoading}
          formName='Commitments'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default Commitments;
