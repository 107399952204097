import { IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

import { pathOr } from 'utils/utils';

export interface ILotsData {
  selected: any;
  deleteObj: any;
  Actions: any;
  lineColumns: IColDef[];
  lots: any[];
  newLineRenderer: string;
  WarehouseProduct: string;
  openLotDetailModal: boolean;
  pickSLipLot: any;
}

export interface ILotsState extends IExtendedState<ILotsData> {
  fetchPickSlipLot_loading?: boolean;
  updatePickSlipLot_loading?: boolean;
  fetchLots_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    changeModalVisibility: (data) => (data),
    clearLines: () => null,
    asyncs: {
      fetchPickSlipLot: (Data) => (Data),
      fetchLots: (Data) => (Data),
      updatePickSlipLot: (Data) => (Data),
      saveNewPickSlipLot: (Data) => (Data),
    }
  },
  'PSlots');

const initialState = asyncInitialState<ILotsData>({
  selected: null,
  deleteObj: null,
  Actions: {
  },
  lineColumns: [
    { headerName: 'Lot number', field: 'LotNumber', minWidth: 150, suppressSorting: true, },
    { headerName: 'Expiry', field: 'Expiry', minWidth: 150, suppressSorting: true },
    { headerName: 'Available', field: 'AvailableQuantity', minWidth: 150, suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Quantity', field: 'LotQuantity', minWidth: 150, suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Description', field: 'Description', minWidth: 150, suppressSorting: true, },
    { headerName: 'Price', field: 'Price', minWidth: 150, suppressSorting: true, type: 'numericColumn' },
  ],
  lots: [],
  newLineRenderer: 'PSLots',
  WarehouseProduct: null,
  openLotDetailModal: false,
  pickSLipLot: {}
});

export default (state: ILotsState = initialState, action: IDataAction): ILotsState => {
  switch (action.type) {
    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };

    case types.fetchPickSlipLot:
    case types.updatePickSlipLot:
    case types.saveNewPickSlipLot:
    case types.fetchLots:
      return asyncOnRequest(state, action);

    case types.saga.fetchPickSlipLot.success:
    case types.saga.updatePickSlipLot.success:
    case types.saga.saveNewPickSlipLot.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          pickSLipLot: successAction.payload
        };
      });

    case types.saga.fetchLots.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          lots: successAction.payload
        };
      });

    case types.saga.fetchPickSlipLot.failure:
    case types.saga.updatePickSlipLot.failure:
    case types.saga.saveNewPickSlipLot.failure:
    case types.saga.fetchLots.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots,
  {
    lots: (data) => pathOr([], ['lots', 'records'], data),
    pickSLipLot: (data) => data.pickSLipLot,
    selected: (data) => data.selected,
  }
);

const syncSelector = {
  isLoading: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.fetchLots_loading,
  psLotLoading: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.fetchPickSlipLot_loading || state.pickSlips.lots.updatePickSlipLot_loading,
  openLotDetailModal: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.data.openLotDetailModal,
  lineColumns: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.data.lineColumns,
  newLineRenderer: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.data.newLineRenderer,
  WarehouseProduct: (state: { pickSlips: { lots: ILotsState } }) => state.pickSlips.lots.data.WarehouseProduct,
};

export const selectors = { ...asyncSelector, ...syncSelector };
