import { connect } from 'react-redux';
import ServiceAgreementItemModal from './ServiceAgreementItemModal';
import { IApplicationState } from 'ducks/reducers';
import { getFormValues } from 'redux-form';

const mapStateToProps = (state: IApplicationState) => ({
    agreementFormValues: getFormValues('ServiceAgreementDetail')(state),
});

export default connect(mapStateToProps, null)(ServiceAgreementItemModal);
