import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors,
  reduceById, concatObj
} from './utils'

export const { types, actions } = createActions({
  setSelected: (contextId, formId) => ({ contextId, formId }),
  setFieldAsyncError: (formName, fieldName, error) => ({ formName, fieldName, error }),
  updateValue: (value) => value,
  resetFormAsyncErrors: (formName) => (formName),
  asyncs: {
    search: (query) => ({ query }),
  }
}, 'formSchema')

const NOT_SELECTED = -1

let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  formsAsyncErrors: {}
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.search:
      return asyncOnRequest(state, action)

    case types.resetFormAsyncErrors:
      return {
        ...state,
        data: {
          ...state.data,
          formsAsyncErrors: {
            [action.data]: {}
          }
        }
      }
    case types.setFieldAsyncError: {
      const { formName, fieldName, error } = action.data;
      let formsAsyncErrors = Object.assign({}, state.data.formsAsyncErrors);

      if (!formsAsyncErrors || !formsAsyncErrors.hasOwnProperty(formName)) {
        formsAsyncErrors[formName] = {};
      }

      if (error) {
        formsAsyncErrors[formName][fieldName] = error;
      } else {
        delete formsAsyncErrors[formName][fieldName];
      }

      return {
        ...state,
        data: {
          ...state.data,
          formsAsyncErrors: formsAsyncErrors
        }
      }
    }
    case types.setSelected:
      const selected = action.data.formId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.updateValue:
      const { value, formName, fieldName } = action.data;
      if (state[formName] && state[formName].values && state[formName].values[fieldName]) {
        state[formName].values[fieldName] = value;
      }
      return {
        ...state,
      }
    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {
        if (action.payload) {
          const schema = [action.payload]
          const prevList = data !== null ? data.list : {}

          return {
            ...data,
            list: concatObj(prevList, reduceById(schema))
          }
        } else {
          return { ...data };
        }
      })

    default:
      return state
  }
}

export const selectors = {
  getFormAsyncErrors: (formName) => (state) => (state.formSchema.data.formsAsyncErrors && state.formSchema.data.formsAsyncErrors.hasOwnProperty(formName)) ? state.formSchema.data.formsAsyncErrors[formName] : null,
  all: (state) => state.formSchema.data.list !== undefined && state.formSchema.data.list != null && Object.values(state.formSchema.data.list),
  selected: (state) => (state.formSchema.data.list !== undefined && state.formSchema.data.list != null && state.formSchema.data.list[state.formSchema.data.selected]) || null,
}
