import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { withRouter } from 'react-router-dom';
import SummaryTable from '../../common/SummaryTable';
import SummaryTableSchema from './../SummaryTableSchema.json';
import { IQuoteLinesProperties } from '../interfaces';

class QuoteLines extends React.Component<IQuoteLinesProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-quote-enquiry/quote-lines':
          onInitialLoad('QuoteLines');
          break;
        case '/customer-quote-enquiry/quote-lines/details':
          onInitialLoad('LineDetails');
          break;
        case '/customer-quote-enquiry/quote-lines/bom':
          onInitialLoad('BillOfMaterials');
          break;
        case '/customer-quote-enquiry/quote-lines/bom/component-details':
          onInitialLoad('ComponentDetails');
          break;
        case '/customer-quote-enquiry/quote-lines/bom/reservation-details':
          onInitialLoad('BOMReservationDetails');
          break;
        case '/customer-quote-enquiry/quote-lines/bom/reservation-lots':
          onInitialLoad('BOMReservationLots');
          break;
        case '/customer-quote-enquiry/quote-lines/bom/reservation-serials':
          onInitialLoad('BOMReservationSerials');
          break;
        case '/customer-quote-enquiry/quote-lines/reservation-details':
          onInitialLoad('ReservationDetails');
          break;
        case '/customer-quote-enquiry/quote-lines/lot-details':
          onInitialLoad('ReservationLots');
          break;
        case '/customer-quote-enquiry/quote-lines/serial-numbers':
          onInitialLoad('ReservationSerials');
          break;
        default:
          onInitialLoad('QuoteLines');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, reservationDetailsSchema, quoteLineDetailSchema, reservationDetails,
      bomReservationLotLines, bomReservationDetails, quoteLineDetail, reservationSerialLines,
      bomLineDetails, reservationLotLines } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
        return { initialValues: quoteLineDetail, valuesSchema: quoteLineDetailSchema };
      case 'ComponentDetails':
        return { initialValues: bomLineDetails };
      case 'ReservationLots':
        return { initialValues: reservationLotLines };
      case 'BOMReservationLots':
        return { initialValues: bomReservationLotLines };
      case 'ReservationDetails':
        return { initialValues: reservationDetails, valuesSchema: reservationDetailsSchema };
      case 'BOMReservationDetails':
        return { initialValues: bomReservationDetails };
      case 'ReservationSerials':
        return { initialValues: reservationSerialLines };
      default: return null;
    }
  }

  lineSummary = () => {

    return <SummaryTable
      LoadingQuoteLineSummary={this.props.LoadingQuoteLineSummary}
      selectedLine={this.props.quoteLineSummary}
      data={
        SummaryTableSchema.LineDetails
      } />;
  }

  componentDetailsSummary = () => {
    return <SummaryTable selectedComponent={this.props.bomLineDetailSummary} data={
      SummaryTableSchema.ComponentDetails
    } />;
  }

  getSummaryTableRenderer = (selectedTab: string) => {
    switch (selectedTab) {
      case 'LineDetails':
      case 'ReservationDetails':
      case 'ReservationLots':
      case 'ReservationSerials':
        return this.lineSummary;
      case 'ComponentDetails':
      case 'BOMReservationDetails':
      case 'BOMReservationLots':
      case 'BOMReservationSerials':
        return this.componentDetailsSummary;
      default: return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, notifications } = this.props;
    const initials = this.getInitialValues();
    const initialValues = initials ? initials.initialValues : null;
    const valuesSchema = initials ? initials.valuesSchema : null;

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={notifications}
          entity='CustomerQuoteEnquiry'
        />
        <FormView
          isLoading={isLoading}
          formName='CustomerQuoteEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          valuesSchema={valuesSchema}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(QuoteLines);
