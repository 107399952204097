import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  setEntityView: (defaultView, defaultWarehouse) => ({ defaultView, defaultWarehouse }),
  asyncs: {
  }
}, 'ServiceOrders');

const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    EntityView: 'MPRO',
    Warehouse: '',
    CustomerId: ''
  },
  filterRow: {
    formName: 'ServiceOrdersFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'small',
          required: false
        }
      }
    ]
  },
  gridOptions: {
    toolPanel: false,
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    {
      headerName: 'Service order', field: 'ServiceOrder', minWidth: 150
    },
    { headerName: 'Line', field: 'Line', type: 'numericColumn', minWidth: 100, suppressSorting: true },
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            CustomerId: row.data.Customer,
            CustomerAccountNumber: row.data.CustomerDisplay,
            FieldValue: row.data.CustomerDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 180, suppressSorting: true },
    { headerName: 'Required Quantity', field: 'RequiredQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Required', field: 'Required', minWidth: 120, suppressSorting: true },
    { headerName: 'Started', field: 'Started', minWidth: 120, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true },
    { headerName: 'Customer name', field: 'CustomerLabel', minWidth: 250, suppressSorting: true },
    { headerName: 'Service order description', field: 'Description', minWidth: 250, suppressSorting: true },
    { headerName: 'Sales rep', field: 'SalesRep', minWidth: 100, suppressSorting: true },
    { headerName: 'Sales rep name', field: 'SalesRepLabel', minWidth: 250, suppressSorting: true },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return initialState;
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setEntityView:
      const entityView = action.data.defaultView;
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView,
            Warehouse: warehouse
          }
        }
      };

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.sales.serviceOrders,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.sales.serviceOrders.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.sales.serviceOrders.data.gridOptions,
  columns: state => state.inventoryEnquiry.sales.serviceOrders.data.Columns,
  filterRow: state => state.inventoryEnquiry.sales.serviceOrders.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.sales.serviceOrders.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)