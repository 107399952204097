import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { IColDef } from '../DataGrid.properties';

const styles = createStyles({
  link: {
    color: '#009DDC',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

export interface IHyperLinkRendererProperties extends StyledComponentProps {
  link: string;
  value: string | { FieldValue: string } | any;
  colDef: IColDef;
}

function appendParams(path: string, params: object): string {
  const search = new URLSearchParams();
  for (const paramProp in params) {
    if (params.hasOwnProperty(paramProp)) {
      search.append(paramProp === 'FieldValue' ? 'SearchText' : paramProp, params[paramProp]);
    }
  }
  path += `?${search.toString()}`;

  return path;
}

const HyperLinkRenderer = (props: Readonly<IHyperLinkRendererProperties>) => {

  const { colDef, classes } = props;
  let { link, value } = props;

  if (!value) {
    return null;
  }

  if (typeof (value) === 'object') {
    link = appendParams(link, value);
    value = value.FieldValue || '...';
  } else if (typeof ('value') === 'string') {
    link = `${link}/${value}`;
  }

  return (
    <a
      onClick={() => window.open(link)}
      className={classes.link}
      target={'_blank'}
      title={`Click to open ${colDef.headerName}`}>
      {value}
    </a >);
};

export default withStyles(styles, { index: 1 })(HyperLinkRenderer);
