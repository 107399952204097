import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { IActivityDetailsProps, IActivityDetailsState } from '../interfaces';
import { Operation } from 'utils/operations';
import { isNull } from 'utils/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class ActivityDetail extends React.PureComponent<IActivityDetailsProps, IActivityDetailsState> {

    operationMap: Map<Operation, (prevProps?: Readonly<IActivityDetailsProps>) => void>;

    constructor(props: Readonly<IActivityDetailsProps>) {
        super(props);
        this.state = {
            getLocalValue: false,
            useCurrentValue: false,
            values: {
                ActivityCode: '',
                Description: '',
                LabourProduct: '',
                BomCode: '',
                Estimate: '',
                Comment: '',
                Active: true,
            },
            fieldErrors: {},
        };
        this.operationMap = new Map<Operation, () => void>();
        this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
        this.operationMap[Operation.DELETE] = this.deleteActivity;
        this.operationMap[Operation.BACK] = this.cancelFormSubmission;
        this.operationMap[Operation.NEW] = this.populateNewForm;
        this.operationMap[Operation.SAVE] = this.handleSave;
    }

    handleSave = (prevProps: Readonly<IActivityDetailsProps>): void => {
        const { formSyncErrors, changeOperationMode } = this.props;
        const { fieldErrors } = this.state;
        const formHasError = Object.values(fieldErrors).includes(true);
        if (formHasError || !isNull(formSyncErrors)) {
            showSnackBar({ variant: 'error', message: 'Please resolve form errors.' });
            changeOperationMode(prevProps.operationMode);
            this.setState({ getLocalValue: true });

            return;
        }
        if (prevProps.operationMode === Operation.NEW) {
            this.setState({ getLocalValue: true });
            this.createActivity();
        } else {
            this.updateActivity();
        }
    }

    createActivity = (): void => {
        const { createActivity, values } = this.props;
        createActivity(values);
    }

    updateActivity = (): void => {
        const { updateActivity, values } = this.props;
        updateActivity(values);
    }

    deleteActivity = (): void => {
        const { deleteActivity, values = {}, changeOperationMode, changeConfirmationDialog } = this.props;

        if (values.ActivityCode) {
            changeConfirmationDialog({
                open: true,
                title: 'Delete Activity',
                message: 'Are you sure you want to delete?',
                okLabel: 'Delete',
                onCancel: () => {
                    changeOperationMode(Operation.BROWSE);
                },
                onOk: () => {
                    deleteActivity(values.ActivityCode);
                }
            });
        }
        changeOperationMode(Operation.BROWSE);
    }

    populateNewForm = (prevProps: Readonly<IActivityDetailsProps>): void => {
        const { values } = this.props;
        this.setState({ getLocalValue: true });
        if (prevProps.operationMode === Operation.CANCEL) {
            this.setState({
                values: values
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IActivityDetailsProps>): void {
        const { operationMode } = this.props;

        if (operationMode !== prevProps.operationMode) {
            if (prevProps.operationMode !== Operation.CANCEL) {
                this.setState({ useCurrentValue: false });
            }
            this.setState({ getLocalValue: false });
            if (this.operationMap[operationMode]) { this.operationMap[operationMode](prevProps); }
        }
    }

    componentDidMount(): void {
        const { path, onInitialLoad } = this.props;

        if (path) {
            switch (path) {
                case '/activity-maintenance/activity-details':
                    onInitialLoad('ActivityDetails');
                    break;
                default:
                    onInitialLoad('ActivityDetails');
            }
        }
    }

    cancelFormSubmission = (prevProps: Readonly<IActivityDetailsProps>) => {
        const {
            dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad,
            selectedTab, resetForm, operationMode, history
        } = this.props;
        if (dirty) {
            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    this.setState({ useCurrentValue: true });
                    changeOperationMode(prevProps.operationMode);
                },
                onOk: () => {
                    changeOperationMode(Operation.BROWSE);
                    resetForm();
                }
            });
        } else {
            if (operationMode === Operation.CANCEL) {
                changeOperationMode(Operation.BROWSE);
            } else {
                if (prevProps.operationMode === Operation.NEW) {
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab !== 'ActivityDetails') {
                    onInitialLoad('ActivityDetails');
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab === 'ActivityDetails') {
                    history.push('/dashboard');
                }
            }
        }
    }

    getInitialValues = (): any => {
        const { activityData, selectedTab, operationMode, values, selectedActivity, selectedActivitySchema } = this.props;
        const { getLocalValue, useCurrentValue } = this.state;
        if (operationMode === Operation.CANCEL || useCurrentValue) { return { initialValues: values }; }
        if (getLocalValue) {

            return { initialValues: this.state.values };
        }

        switch (selectedTab) {
            case 'ActivityDetails':
                return {
                    initialValues: !isNull(activityData?.inlineObject) ? activityData?.inlineObject : selectedActivity,
                    valuesSchema: !isNull(activityData?.schema) ? activityData?.schema : selectedActivitySchema
                };
            default:
                return null;
        }
    }

    handleFieldError = (field: string, hasError: boolean): void => {
        this.setState({
            fieldErrors: {
                ...this.state.fieldErrors,
                [field]: hasError
            }
        });
    }

    checkHours = (value: string) => {
        if (value && value[0] === '2') {
            return /[0-3]/;
        } else {
            return /[0-9]/;
        }
    };
    render(): React.ReactNode {
        const { isBrowseLookUpOpen, selectedForm, operationMode, formName, isFormLoading, values = {}, isV2 } = this.props;
        const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };
        const Estimate = values && values.Estimate || null;

        return (selectedForm &&
            <React.Fragment>
                <FormView
                    formName={formName}
                    browseLookUpOpen={isBrowseLookUpOpen}
                    includeTabsHeight={true}
                    schema={selectedForm}
                    initialValues={initialValues}
                    operationMode={operationMode}
                    valuesSchema={valuesSchema}
                    isLoading={isFormLoading}
                    fieldExtensions={
                        {
                            ActivityCode: {
                                readOnly: operationMode === Operation.EDIT,
                                validation: {
                                    controller: (value) => {
                                        if (value.includes('/') || value.includes('\\') || value.includes('+') || value.includes('%') || value.includes('?')) {
                                            return false;
                                        }

                                        return true;
                                    },
                                    errorMessage: '"\\", "/", "+", "%", "?" characters are not allowed in Activity Code'
                                },
                                hasError: (hasError) => { this.handleFieldError('ActivityCode', hasError); }
                            },
                            Estimate: {
                                hasError: (hasError) => { this.handleFieldError('Estimate', hasError); },
                                mask: [/[0-2]/, this.checkHours(Estimate), ':', /[0-5]/, /\d/]
                            },
                            BomCode: {
                                hasError: (hasError) => { this.handleFieldError('BomCode', hasError); }
                            },
                            LabourProductId: {
                                hasError: (hasError) => { this.handleFieldError('LabourProductId', hasError); }
                            },
                            Active: {
                                disabled: operationMode === Operation.BROWSE || isV2,
                            }
                        }
                    }
                />
            </React.Fragment>
        );
    }
}

export default withRouter(ActivityDetail);
