import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (quoteId) => ({ quoteId }),
  setSelectedQuoteLine: (quoteId) => ({ quoteId }),
  changeSortFilter: (selectedSortFilter) => selectedSortFilter,
  changeSortDirection: (selectedSortDirection) => selectedSortDirection,
  reset: () => null,
  asyncs: {
    searchQuotes: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
    searchById: ({ Quote }) => ({ Quote }),
    fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
    fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
    fetchCustomerQuoteDetails: (quoteDetails) => quoteDetails,
    fetchQuoteSummary: (quoteSummary) => quoteSummary,
    fetchQuoteSummaryPanel: (quoteSummaryPanel) => quoteSummaryPanel,
    fetchQuoteLines: (quoteLines) => quoteLines,
    fetchQuoteLineDetail: (quoteLine) => quoteLine,
    fetchQuoteLineSummary: (quoteLine) => quoteLine,
    fetchReservationDetails: (reservation) => reservation,
  }
}, 'quote')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  selectedQuoteLine: null,
  list: null,
  schemas: null,
  quoteDetails: null,
  quoteDetailsSchema: null,
  quoteSummary: null,
  quoteSummarySchema: null,
  selectedSortFilter: 'Name',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  quoteLineActions: {},
  quoteLineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100, },
    {
      headerName: 'Product',
      field: 'ProductCode',
      minWidth: 200,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductCode) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductCode
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Extended', field: 'ExtendedDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 100, },
    { headerName: 'Description', field: 'Description', suppressSorting: true, minWidth: 300, },
    { headerName: 'Warehouse name', field: 'WarehouseLabel', minWidth: 250, suppressSorting: true, }
  ],
  quoteLinesData: [],
  quoteLinesSchema: [],
  quoteLineDetail: null,
  quoteLineSummary: null,
  reservationDetails: null,
  quoteLineDetailSchema: null,
  reservationDetailsSchema: null,
  quoteSummaryPanel: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: '',
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return initialState
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };
    case types.searchQuotes:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);

    case types.setSelected:
      const selected = action.data.quoteId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.setSelectedQuoteLine:
      const selectedQuoteLine = action.data.quoteId;
      return {
        ...state,
        data: { ...state.data, selectedQuoteLine }
      }
    case types.saga.searchById.success:
    case types.saga.searchQuotes.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const quotes = action.payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = action.payload.records.map((combinedObject) => combinedObject.schema);
        return {
          ...data,
          list: quotes,
          schemas: schemas,
          nextPage: action.payload.nextPage && action.payload.currPage + 1,
          prevPage: action.payload.prevPage && action.payload.currPage - 1,
          currPage: action.payload.currPage
        };
      }, { fetch: true })
    case types.saga.searchQuotes.failure:
    case types.saga.searchById.failure:
      return asyncOnError(state, action)

    case types.fetchCustomerQuoteDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchCustomerQuoteDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          quoteDetails: action.payload.inlineObject,
          quoteDetailsSchema: action.payload.schema
        }
      })
    case types.saga.fetchCustomerQuoteDetails.failure:
      const newState = asyncOnError(state, action);
      return {
        ...newState,
        data: {
          ...newState.data,
          quoteDetails: {}
        }
      }

    case types.fetchQuoteSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchQuoteSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          quoteSummary: action.payload.inlineObject,
          quoteSummarySchema: action.payload.schema
        }
      })
    case types.saga.fetchQuoteSummary.failure:
      return asyncOnError(state, action)

    case types.fetchQuoteLineDetail:
      return asyncOnRequest(state, action)
    case types.saga.fetchQuoteLineDetail.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          quoteLineDetail: action.payload.inlineObject,
          quoteLineDetailSchema: action.payload.schema
        }
      })
    case types.saga.fetchQuoteLineDetail.failure:
      return asyncOnError(state, action)

    case types.fetchQuoteLineSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchQuoteLineSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          quoteLineSummary: action.payload
        }
      })
    case types.saga.fetchQuoteLineSummary.failure:
      return asyncOnError(state, action)
    case types.fetchReservationDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchReservationDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          reservationDetails: action.payload.inlineObject,
          reservationDetailsSchema: action.payload.schema
        }
      })
    case types.saga.fetchReservationDetails.failure:
      return asyncOnError(state, action)
    case types.fetchQuoteSummaryPanel:
      return asyncOnRequest(state, action)
    case types.saga.fetchQuoteSummaryPanel.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          quoteSummaryPanel: action.payload
        }
      })
    case types.saga.fetchQuoteSummaryPanel.failure:
      return asyncOnError(state, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action)
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const quotes = result.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = result.records.map((combinedObject) => combinedObject.schema);
        const newRemoval = result.records.length;
        const newList = data.list.concat(quotes);
        const newSchemas = data.schemas.concat(schemas);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
          nextPage: result.nextPage && result.currPage + 1,
          prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
        };
      }, { fetch: true })

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const quotes = result.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = result.records.map((combinedObject) => combinedObject.schema);
        const newList = quotes.slice(0, data.removeWhenPrev).concat(data.list);
        const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
          prevPage: result.prevPage && result.currPage - 1,
          nextPage: (newList.length > data.pageSize * data.totalPages) && (result.currPage + data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
        };
      }, { fetch: true })
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerQuoteEnquiry.quote,
  {
    list: (data) => data.list,
    selected: (data) => (data.selected && data.list !== undefined && data.list != null && data.list.find(x => x.Quote === data.selected)) || null,
    quoteLineActions: data => data.quoteLineActions,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
    quoteDetails: (data) => data.quoteDetails,
    quoteDetailsSchema: (data) => data.quoteDetailsSchema,
    quoteSummary: (data) => data.quoteSummary,
    quoteSummarySchema: (data) => data.quoteSummarySchema,
    quoteLineDetail: (data) => data.quoteLineDetail,
    quoteLineDetailSchema: (data) => data.quoteLineDetailSchema,
    quoteLineSummary: (data) => data.quoteLineSummary,

  }
)

const syncSelector = {
  selectedSortFilter: state => state.customerQuoteEnquiry.quote.data.selectedSortFilter,
  selectedSortDirection: state => state.customerQuoteEnquiry.quote.data.selectedSortDirection,
  isLoading: state => state.customerQuoteEnquiry.quote.searchQuotes_loading || state.customerQuoteEnquiry.quote.searchById_loading,
  loadingQuoteLines: state => state.customerQuoteEnquiry.quote.fetchQuoteLines_loading,
  gridOptions: state => state.customerQuoteEnquiry.quote.data.gridOptions,
  quoteLineColumns: state => state.customerQuoteEnquiry.quote.data.quoteLineColumns,
  quoteLineActions: state => state.customerQuoteEnquiry.quote.data.quoteLineActions,
  quoteLinesData: state => state.customerQuoteEnquiry.quote.data.quoteLinesData,
  selectedQuoteLine: (state) => state.customerQuoteEnquiry.quote.data.selectedQuoteLine,
  reservationDetails: (state) => state.customerQuoteEnquiry.quote.data.reservationDetails,
  reservationDetailsSchema: (state) => state.customerQuoteEnquiry.quote.data.reservationDetailsSchema,
  quoteSummaryPanel: (state) => state.customerQuoteEnquiry.quote.data.quoteSummaryPanel,
  loadingNextPage: state => state.customerQuoteEnquiry.quote.fetchNextPage_loading,
  loadingPrevPage: state => state.customerQuoteEnquiry.quote.fetchPrevPage_loading,
  pageSize: (state) => state.customerQuoteEnquiry.quote.data.pageSize,
  totalPages: (state) => state.customerQuoteEnquiry.quote.data.totalPages,
  selectedQuoteSchema: (state) => (state.customerQuoteEnquiry.quote.data.quoteLinesSchema && state.customerQuoteEnquiry.quote.data.selectedQuoteLine) ? state.customerQuoteEnquiry.quote.data.quoteLinesSchema.filter(x => x.Line === state.customerQuoteEnquiry.quote.data.selectedQuoteLine.Line)[0] : null,
  LoadingQuoteLineSummary: state => state.customerQuoteEnquiry.quote.fetchQuoteLineSummary_loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

