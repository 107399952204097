import { combineReducers } from 'redux';
import productDetails from './productDetails';
import associations from './associations';
import whereUsed from './whereUsed';
import historicalDates from './historicalDates';
import stocking from './stocking';
import sales, { IInventorySalesState } from './sales';
import pricing from './pricing';
import attachments, { IInventoryEnquiryAttachmentsState } from './attachments';
import inventoryProduct, { IInventoryProductState } from './inventoryProduct';
import userFields, { IUserFieldsState } from './userFields';

export interface IInventoryState {
  inventoryProduct: IInventoryProductState;
  sales: IInventorySalesState;
  [name: string]: any;
  attachments: IInventoryEnquiryAttachmentsState;
  userFields: IUserFieldsState;
}

const inventoryEnquiryReducer = combineReducers<IInventoryState>({
  inventoryProduct,
  productDetails,
  associations,
  historicalDates,
  whereUsed,
  stocking,
  sales,
  pricing,
  attachments,
  userFields
});

export default inventoryEnquiryReducer;
