import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    lookupFieldsPaperContainer: {
        width: 'calc(100% - 20px)',
        padding: '10px 10px 0',
        boxSizing: 'border-box',
        margin: '0px 10px 10px 10px',
    },
    field: {
        width: '100%',
        minWidth: '200px',
        maxWidth: 'unset',
    },
    basicLookupField: {
        '& > div': {
            width: '100%',
            maxWidth: 'unset'
        }
    },
    customerLookupField: {
        '& > div': {
            width: '100%',
            maxWidth: 'unset',
            padding: 0
        },
        '& > div > div': {
            'margin-top': '8px',
            'margin-left': 'auto'
        },
        'flex-wrap': 'wrap'
    },
    POfield: {
        'width': '100%',
        'minWidth': '200px',
        'maxWidth': 'unset',
        'margin-top': '8px'
    },
    selectContainer: {
        '& .MuiInputBase-input': {
            width: 'auto'
        }
    },
    commitActionPaperContainer: {
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: '60px',
        backgroundColor: '#ebebeb',
        margin: '0px 10px'
    },
    commitActionButton: {
        flexBasis: '50%',
        boxShadow: 'none',
        borderRadius: 0
    },
    circularLoading: {
        color: '#006633',
        margin: '0px 5px'
    }
});
