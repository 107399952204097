import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router-dom';
import { ISlipLineDetailsProps } from '../interfaces';
import * as SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from 'components/common/SummaryTable';

class SlipLines extends React.PureComponent<ISlipLineDetailsProps> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/pick-slip-enquiry/slip-lines':
          onInitialLoad('SlipLines');
          break;
        case '/pick-slip-enquiry/slip-lines/details':
          onInitialLoad('SlipLineDetails');
          break;
        case '/pick-slip-enquiry/slip-lines/lots':
          onInitialLoad('Lots');
          break;
        case '/pick-slip-enquiry/slip-lines/serials':
          onInitialLoad('Serials');
          break;
        case '/pick-slip-enquiry/slip-lines/details/bom':
          onInitialLoad('SlipLineBOM');
          break;
        case '/pick-slip-enquiry/slip-lines/details/bom/component-details':
          onInitialLoad('SlipLineBOMDetails');
          break;
        case '/pick-slip-enquiry/slip-lines/details/bom/lots':
          onInitialLoad('SlipLineBOMLots');
          break;
        case '/pick-slip-enquiry/slip-lines/details/bom/serials':
          onInitialLoad('SlipLineBOMSerials');
          break;
        default:
          onInitialLoad('SlipLines');
      }
    }
  }

  getInitialValues = (): any => {
    const {
      selectedTab, slipLineDetails, slipLineBOMDetails
    } = this.props;
    switch (selectedTab) {
      case 'SlipLineDetails':
        return { initialValues: (slipLineDetails && slipLineDetails.values), valuesSchema: slipLineDetails && slipLineDetails.schema };
      case 'SlipLineBOMDetails':
        return { initialValues: (slipLineBOMDetails && slipLineBOMDetails.values), valuesSchema: slipLineBOMDetails && slipLineBOMDetails.schema };
      default:
        return null;
    }
  }

  getSlipLineDetailsSummary = () => {
    const { slipLineDetailsSummary, loadingSlipLineDetailsSummary } = this.props;

    return <SummaryTable loadingSlipLineDetailsSummary={loadingSlipLineDetailsSummary}
      selectedSlipLine={slipLineDetailsSummary && slipLineDetailsSummary.values}
      data={
        SummaryTableSchema.SlipLineDetails
      }
    />;
  }

  getSlipLineBOMDetailsSummary = () => {
    const { slipLineBOMDetailsSummary, loadingSlipLineBOMDetailsSummary } = this.props;

    return <SummaryTable loadingSlipLineBOMDetailsSummary={loadingSlipLineBOMDetailsSummary}
      selectedSlipLineBOM={slipLineBOMDetailsSummary && slipLineBOMDetailsSummary.values}
      data={
        SummaryTableSchema.SlipLineBOMDetails
      }
    />;
  }

  getSummaryTableRenderer = (selectedTab: any) => {
    switch (selectedTab) {
      case 'SlipLineDetails':
      case 'Lots':
      case 'Serials':
        return this.getSlipLineDetailsSummary;
      case 'SlipLineBOMDetails':
      case 'SlipLineBOMLots':
      case 'SlipLineBOMSerials':
        return this.getSlipLineBOMDetailsSummary;
      default: return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName, selectedTab } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SlipLines);
