import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';

const mapStateToProps = (state) => ({
  summaryData: supplierProductSelectors.summary(state),
  isLoading: supplierProductSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
