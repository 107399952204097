import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './CreditDetailsForm.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import ICreditDetailsFormProps from './CreditDetailsForm.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import TextField from '@markinson/uicomponents-v2/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { IUpdatePaymentLineDetailFacade } from 'api/swaggerTypes';
import { ObjectifiedPaymentLineDetailsResponse } from 'api/Worksale/payment';

const CreditDetailsForm = (props: ICreditDetailsFormProps): JSX.Element => {
  const { classes, open, initialValues, schema, paymentDetail, onCalculatePaymentLineDetails, onApply, onCancel } = props;

  const processValidationForms = React.useContext(ProcessValidationFormsContext);

  const [values, setValues] = React.useState(initialValues);
  const [amount, setAmount] = React.useState<number>(0);
  const [accountName, setAccountName] = React.useState<string>('');
  const [cardNumber, setCardNumber] = React.useState<string>('');
  const [cardExpiry, setCardExpiry] = React.useState<string>('');
  const [waiveMerchantFeeSurcharge, setWaiveMerchantFeeSurcharge] = React.useState<boolean>(true);
  const [merchantFeeRecovery, setMerchantFeeRecovery] = React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [reference, setReference] = React.useState<string>('');
  const [calculateInProgress, setCalculateInProgress] = React.useState<boolean>(false);

  function formatExpiry(value: number): string {

    const MonthsPlaces = 100;
    const years = value % MonthsPlaces;
    const months = Math.floor(value / MonthsPlaces);
    const partLength = 2;

    return `${months.toString().padStart(partLength, '0')}/${years.toString().padStart(partLength, '0')}`;
  }

  function parseExpiry(value: string): number {
    const maxExpiry = 9999;
    let expiryAsNumber = parseInt(value.replace(/\D/g, ''));
    if (isNaN(expiryAsNumber)) {
      expiryAsNumber = 0;
    }

    return Math.max(0, Math.min(expiryAsNumber, maxExpiry));
  }

  React.useEffect(
    () => {
      setValues(initialValues);
      if (initialValues) {
        setAmount(values.Amount ? values.Amount : 0);
        setAccountName(values.AccountName);
        setCardNumber(values.CardNumber);
        setCardExpiry(values.CardExpiry ? formatExpiry(values.CardExpiry) : ''); // TODO - what format is this.
        setWaiveMerchantFeeSurcharge(values.WaiveMerchantFeeSurcharge || false);
        setReference(values.Reference);
      } else {
        setAmount(0);
        setAccountName('');
        setCardNumber('');
        setCardExpiry('');
        setWaiveMerchantFeeSurcharge(false);
        setReference('');
      }
    },
    [initialValues]);

  React.useEffect(
    () => {
      if (values) {
        setMerchantFeeRecovery(values.MerchantFeeRecovery ? values.MerchantFeeRecovery : 0);
        setTotalAmount(values.TotalAmount ? values.TotalAmount : 0);
      } else {
        setMerchantFeeRecovery(0);
        setTotalAmount(0);
      }
    },
    [values]);

  async function onCalculatePaymentLineDetailsAndValidate(query: IUpdatePaymentLineDetailFacade, ChangedField: string): Promise<ObjectifiedPaymentLineDetailsResponse> {
    return processValidationForms(await onCalculatePaymentLineDetails(query, ChangedField), query, async (newQuery) => onCalculatePaymentLineDetailsAndValidate(newQuery, ChangedField));
  }

  async function handleValuesChanged(newValues: IUpdatePaymentLineDetailFacade['UpdateLine'], ChangedField: string): Promise<void> {
    if (onCalculatePaymentLineDetails) {
      try {
        setCalculateInProgress(true);
        const response = await onCalculatePaymentLineDetailsAndValidate(
          {
            PaymentDetail: paymentDetail,
            UpdateLine: newValues
          },
          ChangedField);

        if (response.Status && response.PaymentLineDetail) {
          setValues(response.PaymentLineDetail.inlineObject);
        }
      } finally {
        setCalculateInProgress(false);
      }
    }
  }

  const maxCardNumberLength = 20;

  const getModalContent = () => {
    return <div className={classes.mainDiv}>
      <div className={classes.leftColumnDiv}>
        <NumericField
          value={amount}
          label={'Payment Amount'}
          minValueExclusive={0}
          onBlur={(value) => {
            setAmount(value);
            handleValuesChanged(
              {
                ...values,
                Amount: value,
                AccountName: accountName,
                CardNumber: cardNumber,
                CardExpiry: parseExpiry(cardExpiry),
                WaiveMerchantFeeSurcharge: waiveMerchantFeeSurcharge,
                MerchantFeeRecovery: merchantFeeRecovery,
                TotalAmount: totalAmount,
                Reference: reference
              },
              'Amount').catch((err) => { console.warn(err); });
          }}
          required={schema.Amount.Required} />
        <TextField
          value={accountName}
          label={'Cardholder'}
          onChange={(value) => { setAccountName(value); }}
          required={schema.AccountName.Required} />
        <TextField
          value={cardNumber}
          label={'Card number'}
          maxLength={maxCardNumberLength}
          onChange={(value) => { setCardNumber(value); }}
          required={schema.CardNumber.Required}
        />
        <TextField
          value={cardExpiry}
          label={'Card expires'}
          onChange={(value) => { setCardExpiry(value); }}
          onBlur={(value) => {
            setCardExpiry(formatExpiry(parseExpiry(value)));
          }}
          required={schema.CardExpiry.Required}
        />
      </div>
      <div className={classes.rightColumnDiv}>
        <TextField
          value={reference}
          label={'Reference'}
          onChange={(value) => { setReference(value); }}
          required={schema.Reference.Required} />
        <FormControlLabel
          label={'Waive surcharge'}
          control={<Switch
            color={'default'}
            checked={waiveMerchantFeeSurcharge}
            onChange={(_, value) => {
              setWaiveMerchantFeeSurcharge(value);
              handleValuesChanged(
                {
                  ...values,
                  Amount: amount,
                  AccountName: accountName,
                  CardNumber: cardNumber,
                  CardExpiry: parseExpiry(cardExpiry),
                  WaiveMerchantFeeSurcharge: value,
                  MerchantFeeRecovery: merchantFeeRecovery,
                  TotalAmount: totalAmount,
                  Reference: reference
                },
                'WaiveMerchantFeeSurcharge').catch((err) => { console.warn(err); });
            }}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              bar: classes.bar,
            }}
          />}
          style={{ width: '100%' }}
          classes={{
            label: classes.formControlLabel
          }} />
        <NumericField
          value={merchantFeeRecovery}
          label={'Merchant fee recovery'}
          onChange={(value) => { setMerchantFeeRecovery(value); }}
          readonly />
        <NumericField
          value={totalAmount}
          label={'Total Payment'}
          onChange={(value) => { setTotalAmount(value); }}
          readonly />
      </div>
    </div >;
  };

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        disabled: calculateInProgress ||
          (schema.Amount.Required && amount === undefined) || (amount !== undefined && amount <= 0) ||
          (schema.AccountName.Required && !accountName) ||
          (schema.CardNumber.Required && !cardNumber) || (cardNumber && cardNumber.length > maxCardNumberLength) ||
          (schema.CardExpiry.Required && !cardExpiry) ||
          (schema.Reference.Required && !reference),
        listener: async () => {
          if (onApply) {
            onApply({
              ...values,
              Amount: amount,
              AccountName: accountName,
              CardNumber: cardNumber,
              CardExpiry: parseExpiry(cardExpiry),
              WaiveMerchantFeeSurcharge: waiveMerchantFeeSurcharge,
              MerchantFeeRecovery: merchantFeeRecovery,
              TotalAmount: totalAmount,
              Reference: reference
            });
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }
    ];
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Credit Card Details'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(CreditDetailsForm);
