import { fetchGet, fetchPost, objectify } from '../utils';

export const fetchKitComponents = (query) => {
    const { DespatchId, LineNumber, Kitting, BackOrdered } = query;

    return fetchGet(
        `/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/GetPickSlipKitComponenets`, { Kitting, BackOrdered })
        .then((result) => {
            if (result) {
                if (result.PickSlipKitComponenets) {
                    return {
                        ...result,
                        records: result.PickSlipKitComponenets.map((val) =>
                            ({ ...objectify(val) })),
                        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
                        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
                    };
                } else {
                    return result;
                }
            }
        });
};

export const fetchKitComponentSummary = (query) => {
    const { DespatchId, LineNumber } = query;
    const apiCall = fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/GetPickSlipKitComponenetSummary`, {}, 'Inline')
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetSummary),
            schema: result.PickSlipKitComponenetSummary
        }));

    return apiCall;
};

export const fetchKitComponentDetails = (query) => {
    const { DespatchId, LineNumber, KitComponent } = query;
    const apiCall = fetchGet(`PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/KitComponent/${KitComponent}/GetPickSlipKitComponenetDetails`, {}, 'Inline')
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};

export const updateKitComponentDetails = (query) => {
    const { KitComponent, data } = query;
    const apiCall = fetchPost(`/PickSlipTransaction/KitComponent/${KitComponent}/UpdatePickSlipKitComponenetDetails`, data, null)
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};

export const createKitComponentDetails = (query) => {
    const { KitComponent, DespatchId, LineNumber, data } = query;
    const apiCall = fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/KitComponenet/${KitComponent || 0}/CreatePickSlipKitComponenetDetails`, data)
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};

export const saveKitComponentDetails = (query) => {
    const { KitComponent, DespatchId, LineNumber, data } = query;
    const apiCall = fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/KitComponenet/${KitComponent}/SavePickSlipKitComponenetDetails`, data)
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};

export const cancelKitComponentDetails = (query) => {
    const { KitComponent } = query;
    const apiCall = fetchPost(`/PickSlipTransaction/KitComponent/${KitComponent}/CancelPickSlipKitComponenetDetails`, null)
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};

export const removeKitComponentDetails = (query) => {
    const { KitComponent } = query;
    const apiCall = fetchPost(`/PickSlipTransaction/KitComponent/${KitComponent}/RemovePickSlipKitComponenetDetails`, null)
        .then((result) => ({
            values: objectify(result.PickSlipKitComponenetDetails),
            schema: result.PickSlipKitComponenetDetails
        }));

    return apiCall;
};
