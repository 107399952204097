export default [
  {
    id: '1',
    label: 'Customer Transaction Enquiry in new tab',
    'action': (params) => {window.open('/customer-transaction-enquiry' + (params ? '/' + params : ''))}
  },
  {
    id: '2',
    label: 'Customer Transaction Enquiry',
    'action': (params) => {window.location.pathname = '/customer-transaction-enquiry' + (params ? '/' + params : '')}

  }
]
