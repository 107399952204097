import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    templatesGridContainer: {
        margin: '0px 16px',
        height: 'calc(100% - 530px)'
    },
    servicePatternTemplateGrid: {
        height: '100%'
    }
});
