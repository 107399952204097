import { isNull } from 'utils/utils';

export const mapType = (type, name) => ({ type, name });
export const getTypes = (typeObjects) => Object.keys(typeObjects).reduce((acc, key) => ({ ...acc, [key]: typeObjects[key].type }), {});

export const lookup = (data, key, initialState = { loading: false, fetched: false }) => {
  if (key in data) {
    return data[key];
  }

  return initialState;
};

// action creator helper
const constructType = (namespace, key, lifecycle = 'none') => {
  if (lifecycle === 'none') {
    return `${namespace}_${key}`;
  }

  return `${namespace}_${key}.${lifecycle}`;
};

export const dateComparator = (date1, date2) => {
  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }
  const date1Formatting = date1.split('/');
  const date2Formatting = date2.split('/');
  const a1 = date1Formatting[0];
  const a2 = date2Formatting[0];
  const b1 = date1Formatting[1];
  const b2 = date2Formatting[1];
  date1Formatting[0] = b1;
  date2Formatting[0] = b2;
  date1Formatting[1] = a1;
  date2Formatting[1] = a2;
  const x = new Date(date1Formatting.join('/'));
  const y = new Date(date2Formatting.join('/'));
  if (x < y) {
    return 1;
  }
  if (x > y) {
    return -1;
  }
  if (+x === +y) {
    return 0;
  }
};

export const createActions = (actionCreators, namespace) => {
  let { asyncs, ...syncs } = actionCreators;
  if (!asyncs) {
    asyncs = {};
  }

  if (!Object.entries) {
    Object.entries = function (obj) {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = new Array(i); // preallocate the Array
      while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
      }

      return resArray;
    };
  }

  const sagas = Object.entries(asyncs).reduce(
    (acc, [key, entry]) => {
      return {
        ...acc,
        [key]: {
          success: (payload) => ({
            type: constructType(namespace, key, 'success'),
            name: key,
            lifecycle: 'success',
            callback: entry.successCallback,
            payload
          }),
          failure: (error) => ({
            type: constructType(namespace, key, 'failure'),
            name: key,
            lifecycle: 'failure',
            callback: entry.failureCallback,
            error
          })
        }
      };
    },
    {});

  asyncs = Object.entries(asyncs).reduce(
    (acc, [key, entry]) => {
      return {
        ...acc,
        [key]: (...args) => ({
          type: constructType(namespace, key),
          name: key,
          lifecycle: 'request',
          callback: entry.callback,
          data: typeof (entry) === 'function' ? entry(...args) : typeof (entry) === 'object' ? entry.action(...args) : undefined
        })
      };
    },
    {});

  syncs = Object.entries(syncs).reduce(
    (acc, [key, entry]) => {
      return {
        ...acc,
        [key]: (...args) => ({
          type: constructType(namespace, key),
          name: key,
          lifecycle: 'none',
          callback: entry.callback,
          data: typeof (entry) === 'function' ? entry(...args) : typeof (entry) === 'object' ? entry.action(...args) : undefined
        })
      };
    },
    {});

  const sagaTypes = Object.entries(sagas).reduce(
    (acc, [key, sagaActions]) => ({
      ...acc,
      [key]: Object.entries(sagaActions).reduce(
        (sagaActionAcc, [lifecycle, func]) => ({
          ...sagaActionAcc,
          [lifecycle]: constructType(namespace, key, lifecycle),
        }),
        {}
      )
    }),
    {}
  );

  const types = Object.entries({ ...syncs, ...asyncs }).reduce(
    (acc, [key, func]) => ({
      ...acc,
      [key]: constructType(namespace, key),
    }),
    { saga: sagaTypes }
  );

  const actions = { ...syncs, ...asyncs, saga: sagas };

  return { types, actions };
};

// help with constructing async stores
export const asyncInitialState = (initialData = null) => {
  return {
    fetched: false,
    loading: false,
    detailLoading: false,
    error: null,
    data: initialData,
    notifications: {}
  };
};

export const asyncOnRequest = (state, action) => ({
  ...state,
  error: null,
  notifications: {},
  loading: true,
  [`${action.name}_loading`]: true,
});

export const asyncOnSuccess = (state, action, map, notification) => {
  const notifications = { [action.name]: true, ...(notification || {}) };

  notifications.clear = () => {
    notifications[action.name] = false;
  };

  return {
    ...state,
    fetched: true,
    loading: false,
    [`${action.name}_loading`]: false,
    notifications: notifications,
    data: map(state.data, action)
  };
};

export const asyncOnError = (state, action) => ({
  ...state,
  loading: false,
  [`${action.name}_loading`]: false,
  error: action.error
});

// help creating async selectors
export const asyncSelectors = (getState, dataSelectors) => {
  const selectors = Object.entries(dataSelectors).reduce(
    (acc, [key, func]) => ({
      ...acc,
      [key]: (state, ...args) => {
        if (isNull(getState(state))) {
          return;
        }
        const { fetched, data } = getState(state);

        if (fetched === false) {
          return null;
        }

        return func(data, ...args);
      }
    }),
    {}
  );

  return {
    ...selectors,
    isFetched: (state) => getState(state).fetched,
    isLoading: (state) => getState(state).loading,
    error: (state) => getState(state).error,
  };
};

// help creating sync selectors

export const syncSelectors = (getState, dataSelectors) => {
  const selectors = Object.entries(dataSelectors).reduce(
    (acc, [key, func]) => ({
      ...acc,
      [key]: (state, ...args) => {
        return func(getState(state, ...args));
      }
    }),
    {}
  );

  return selectors;
};

// utils for data in reducers
export const reduceById = (list) => list.reduce(
  (acc, item) => ({ ...acc, [item.id]: item }),
  {}
);

// utils for data in reducers
export const reduceByPrimary = (list, keyField) => list.reduce(
  (acc, item) => ({ ...acc, [item[keyField]]: item }),
  {}
);

export const reduceByPrimaryValue = (list, keyField) => list.reduce(
  (acc, item) => ({ ...acc, [item[keyField].Value]: item }),
  {}
);

export const formatFloatingNumber = (value, minimumFractionDigits = 2) => {
  const parsedValue = parseFloat(value.value);
  if (!(parsedValue && !isNaN(parsedValue))) {
    return (0).toLocaleString(
      undefined, {
        minimumFractionDigits: value &&
          value.colDef &&
          minimumFractionDigits
      });
  }
  const formattedValue = parsedValue.toLocaleString(
    undefined, {
      minimumFractionDigits: value &&
        value.colDef &&
        minimumFractionDigits
    });

  return formattedValue;
};

const DEFAULT_FRACTION_DIGITS = 2;

export const formatPercentageNumber = (value) => {
  const parsedValue = parseFloat(value.value);

  if (!(value && !isNaN(parsedValue))) {
    return '';
  }

  const formattedValue = parsedValue.toLocaleString(
    undefined, {
      minimumFractionDigits: value &&
        value.colDef &&
        value.colDef.minimumFractionDigits ? value.colDef.minimumFractionDigits : DEFAULT_FRACTION_DIGITS
    });

  return `${formattedValue} %`;
};

export const booleanFormatter = (field) => {
  if (field.value) {
    return 'Yes';
  } else {
    return 'No';
  }
};

export const concatObj = (prevObj, newObj) => ({ ...prevObj, ...newObj });

export const deleteKey = (list, key, keyField, replaceWith) => {
  if (replaceWith) {
    list.splice(list.findIndex((element) => element[keyField] === key || (element[keyField].Value && element[keyField].Value === key)), 1, replaceWith);
  } else {
    list.splice(list.findIndex((element) => element[keyField] === key || (element[keyField].Value && element[keyField].Value === key)), 1);
  }

  return list;
};

const DEFAULT_CHARACTER_LIMIT = 100;

export const limitCharacters = (text) => {
  return text && text.value && text.value.substring(0, text.colDef && text.colDef.takeCharacters ? text.colDef.takeCharacters : DEFAULT_CHARACTER_LIMIT);
};

export function initializeReducer(options) {
  const { namespace, initialData, syncActions, asyncActions } = options;
  const initialState = asyncInitialState(initialData);
  const { actions, types } = createActions({ asyncs: asyncActions, ...syncActions }, namespace);

  const reducer = function (state = initialState, action) {
    if (types[action.name] && types[action.name] === action.type) {
      let data = action.data;
      if (action.callback) {
        data = action.callback(state.data, action.data);
      }
      if (action.lifecycle === 'none') {
        return { ...state, data: { ...state.data, ...data } };
      } else if (action.lifecycle === 'request') {
        return asyncOnRequest({ ...state, data: { ...state.data, ...data } }, { ...action, data: data });
      }
    } else if (action.lifecycle === 'success' && types.saga[action.name] && types.saga[action.name].success === action.type) {
      if (action.callback) {
        const data = action.callback(state.data, action.payload);
        return asyncOnSuccess(state, action, () => data);
      }
    } else if (action.lifecycle === 'failure' && types.saga[action.name] && types.saga[action.name].failure === action.type) {
      if (action.callback) {
        const data = action.callback(state.data, action.payload);
        return asyncOnError({ ...state, data: { ...state.data, ...data } });
      } else {
        return asyncOnError(state, action);
      }
    }

    return state;
  }

  return { initialState, actions, types, reducer };
}
