//libs
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ViewHeadline from '@markinson/uicomponents-v2/SvgIcons/ViewHeadline';
import { withStyles, StyledComponentProps } from '@material-ui/core/styles';
import NewDocumentOutputDialog from 'components/NewDocumentOutputDialog';
import { Operation } from 'utils/operations';
import classNames from 'classnames';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { isNull } from 'utils/utils';

//src
interface IDocumentOutputFieldProps extends StyledComponentProps {
    name?: string;
    operationMode?: Operation;
    sort?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    documentType: string;
    fieldId: string;
    comment?: string;
    params?: any;
    input?: any;
    style?: any;
    size?: string;
    fieldValue?: string;
    value?: string;
    label?: string;
    placeholder?: string;
    onChange(data: any): void;
    onOpen?(): void;
}

interface IDocumentOutputFieldState {
    openDialog: boolean;
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '16px',
        flex: 1
    },
    button: {
        borderRadius: '5px',
        border: '2px solid gray',
        padding: '3px',
        margin: '0px 5px'
    },
    disabled: {
        color: '#555555',
        backgroundColor: '#EEEEEE',
        cursor: 'default',
    },
    dialog: {
        maxWidth: 'unset',
        maxHeight: 'unset'
    },
    dialogContent: {
        'padding': 'unset',
        '&:first-child': {
            paddingTop: 'unset'
        }
    },
    readOnly: {
        'pointer-events': 'none',
        'color': '#555555',
        'backgroundColor': '#EEEEEE',
    }
};

class DocOutputButton extends React.Component<IDocumentOutputFieldProps, IDocumentOutputFieldState> {

    constructor(props: IDocumentOutputFieldProps) {
        super(props);
        this.state = { openDialog: false };
    }

    componentDidUpdate(prevProps: IDocumentOutputFieldProps): void {
        const { onChange, fieldValue } = this.props;

        if (prevProps.fieldValue !== fieldValue) {
            if (onChange) onChange(fieldValue);
        }
    }

    handleModalVisibility = (open: boolean) => {
        const { onOpen } = this.props;

        if (open) {
            if (onOpen) {
                onOpen();
            }
        }
        this.setState({ openDialog: open });
    }

    onCancel = () => {
        this.handleModalVisibility(false);
    }

    render(): React.ReactNode {

        const {
            classes, readOnly, name, size, documentType, fieldId,
            disabled, params = {}, comment = '', style, fieldValue = '', value
        } = this.props;
        const { openDialog } = this.state;
        const textFieldValue = !isNull(fieldValue) ? fieldValue : value;

        return (
            <div key={name} className={classes.container}>

                <NewDocumentOutputDialog
                    open={openDialog}
                    comment={comment}
                    documentType={documentType}
                    fieldId={fieldId}
                    customerId={params.CustomerId || null}
                    supplierId={params.Supplier || null}
                    onApply={() => { this.handleModalVisibility(false); }}
                    onCancel={this.onCancel}
                />
                <TextField
                    name={name}
                    disabled={true}
                    title={textFieldValue}
                    value={textFieldValue}
                    size={size}
                    style={{
                        display: 'inline-block',
                        padding: 'unset',
                        ...style
                    }}
                />
                <IconButton
                    disableRipple
                    disabled={disabled || readOnly}
                    className={classNames(classes.button, { [classes.readOnly]: readOnly || disabled })}
                    onClick={() => { this.handleModalVisibility(true); }}>
                    <ViewHeadline />
                </IconButton>
            </div>
        );
    }
}

export default withStyles(styles, { index: 1 })(DocOutputButton);
