import { IFormViewForm } from 'components/FormView';

const Charges: IFormViewForm = {
    id: 'InternalOrderDialogForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'From',
                        name: 'Sender',
                        size: 'large',
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Subject',
                        name: 'Subject',
                        size: 'large',
                        style: {
                            padding: 0
                        }
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_AREA',
                    props: {
                        name: 'Message',
                        style: {
                            height: '250px',
                        },
                    }
                },
                {
                    id: 4,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Email',
                        name: 'Email',
                        size: 'large',
                        lookupName: 'SystemUserEmail',
                        suppressValidation: true,
                        suppressDescription: true,
                        style: {
                            marginTop: '16px'
                        },
                    }
                },
            ]
        }
    ]
};

export default Charges;
