import SummaryTable from './SummaryTable';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as lookupActions, selectors as lookupSelectors } from 'ducks/lookup';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  lookUpOptions: lookupSelectors.options(state) || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    fetchLookupOptions: lookupActions.fetchData,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTable);
