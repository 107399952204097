import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';

export interface IPickSlipDetailsData {
  slipDetails: any;
  slipDetailsSummary: any;
}

export interface ISupplierDetailsState extends IExtendedState<IPickSlipDetailsData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (query) => (query),
      fetchSummary: (query) => (query),
    }
  },
  'slipDetails');

const initialState = asyncInitialState<IPickSlipDetailsData>({
  slipDetails: null,
  slipDetailsSummary: null

});

export default (state: ISupplierDetailsState = initialState, action: DataAction): ISupplierDetailsState => {
  switch (action.type) {
    case types.fetch:
    case types.fetchSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: IPickSlipDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          slipDetails: successAction.payload
        };
      });
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data: IPickSlipDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          slipDetailsSummary: successAction.payload
        };
      });
    case types.saga.fetch.failure:
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { pickSlipEnquiry: { slipDetails: ISupplierDetailsState } }) => state.pickSlipEnquiry.slipDetails,
    {
      slipDetails: (data: IPickSlipDetailsData) => data.slipDetails,
      slipDetailsSummary: (data: IPickSlipDetailsData) => data.slipDetailsSummary,
    }),
  loadingSummary: (state: any) => state.pickSlipEnquiry.slipDetails.fetchSummary_loading,
  isLoading: (state: { pickSlipEnquiry: { slipDetails: ISupplierDetailsState } }) => state.pickSlipEnquiry.slipDetails.loading,
};
