const InvoiceLineBOMComponent = {
  'id': 'InvoiceLineBOMComponent',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Product description',
            'name': 'ProductDescription',
            'placeholder': '',
            'size': 'large',
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Required',
            'name': 'RequiredQuantityDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'props': {
            'label': 'Supplied',
            'name': 'SuppliedQuantityDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_AREA',
          'props': {
            'name': 'Comment',
            'label': 'Component line comment',
            'fullWidth': true,
            'disabled': true,
            'style': {
              'height': '200px'
            }
          }
        }
      ]
    }
  ]
}
export default InvoiceLineBOMComponent
