import { connect } from 'react-redux';

import { selectors as mainMenuSelectors } from 'ducks/mainMenu';
import MainMenu from './MainMenu';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => (
  {
    mainMenuData: mainMenuSelectors.menuFolders(state),
    isLoading: mainMenuSelectors.isLoading(state),
  });

export default connect(mapStateToProps)(MainMenu);
