import React from 'react';
import DataGrid, { Column, Editing, Scrolling, MasterDetail } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ChecklistDetailView from './ChecklistDetailView';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IChecklistsGridProps } from './ChecklistsGrid.properties';
import styles from './ChecklistGrid.styles';

const INDEX_COLUMN_WIDTH = '50%';
const LARGE_COLUMN_MIN_WIDTH = 75;

const ChecklistsGrid = (props: IChecklistsGridProps) => {
    const {
        classes,
        disabled = false,
        dataGridRef,
        onChecklistSave,
        onChecklistQuestionSave,
        gridData,
    } = props;

    return (<Paper className={classes.parentContainer} >
        <DataGrid
            dataSource={gridData}
            disabled={disabled}
            keyExpr={'ServiceItem'}
            ref={dataGridRef}
            className={classes.checklistQuestionDataGrid}
            noDataText=''
            repaintChangesOnly={true}
            remoteOperations={true}
            allowColumnReordering={true}
            columnResizingMode={'nextColumn'}
            allowColumnResizing={true}
            showBorders={false}
            sorting={{
                mode: 'none'
            }}
            hoverStateEnabled={true}
        >
            <MasterDetail
                enabled={true}
                component={
                    (childProps) => <ChecklistDetailView
                        onChecklistSave={onChecklistSave}
                        onChecklistQuestionSave={onChecklistQuestionSave}
                        {...childProps}
                    />}
            />
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}
                confirmDelete={false}
            />
            <Scrolling mode='virtual' />
            <Column
                caption={'Service Item'}
                dataField={'ServiceItem'}
                allowEditing={false}
                minWidth={INDEX_COLUMN_WIDTH}
                fixed={true}
            />
            <Column
                caption={'Item Type'}
                dataField={'ItemType'}
                minWidth={LARGE_COLUMN_MIN_WIDTH}
            />
        </DataGrid>
    </Paper>
    );
};

export default withStyles(styles, { index: 1 })(ChecklistsGrid);
