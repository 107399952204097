import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as inTransitLocalSelectors, actions as inTransitLocalActions } from 'ducks/inventoryEnquiry/stocking/inTransitLocal';
import { search } from 'api/inventoryEnquiry/stocking/inTransitLocal';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesInTransitLocal',
  columnDefs: inTransitLocalSelectors.columns(state),
  rowData: inTransitLocalSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: inTransitLocalSelectors.gridOptions(state),
  isLoading: inTransitLocalSelectors.isLoading(state),
  reqParams: (inTransitLocalSelectors.selected(state) || params().get('ProductId') || params().get('Warehouse'))
    &&
  {
    ProductId: (inTransitLocalSelectors.selected(state) && inTransitLocalSelectors.selected(state).ProductId) || params().get('ProductId'),
    Warehouse: (inTransitLocalSelectors.selected(state) && inTransitLocalSelectors.selected(state).Warehouse) || params().get('Warehouse')
  },
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: inTransitLocalActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
