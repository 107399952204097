import ApiService from 'utils/ApiService';
import { IObjectified } from '../utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { Api, asObjectifiedArray } from '../baseApi';
import {
  ITechnicianSearchResponse,
  ITechnicianSearchFacade,
  ISortOrder,
  ISearchRequest
} from '../swaggerTypes';

export const getRosterTechniciansData = () => {
  return new ApiService().getRosterTechniciansData();
};
export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
  records: IObjectified<ITechnicianSearchFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return Api.postInlineCollectionResponse<ISearchRequest, ITechnicianSearchResponse, 'Technicians'>(
    'RostertemplateMaintenance/SearchTechnicians', { SearchText, Sort, BatchPage, BatchSize })
    .then((response) => {
      const records = asObjectifiedArray(response, 'Technicians');
      const batchInformation = (response as ITechnicianSearchResponse).BatchInformation;

      return {
        records,
        currPage: batchInformation ? batchInformation.BatchPage : 1,
        nextPage: batchInformation && !batchInformation.EndOfData,
        prevPage: batchInformation && batchInformation.BatchPage > 1
      };
    });
}
