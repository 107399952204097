import { fetchPost, objectify, objectifyAddress, fetchGet } from '../utils'

export const search = (query) => {
  const { SearchText, Sort, BatchPage } = query
  const respType = 'Inline'
  return fetchPost('/PurchaseOrderEnquiry/Search', { SearchText, Sort, BatchPage}, null, respType)
    .then((result) => (result && result.PurchaseOrders &&
      {
        PurchaseOrders: result.PurchaseOrders.map((val) => objectify(val)),
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
      }
    ) || {PurchaseOrders: []})
}

export const searchById = (PurchaseOrder) => {
  return fetchGet(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/Details`, null, 'Inline')
    .then((result) => ({
      PurchaseOrders: (result
        && result.PurchaseOrderDetails
        && [ objectify(result.PurchaseOrderDetails) ])
        || [],
      currPage: 1,
      nextPage: false,
      prevPage: false}))
}

export const fetchSummary = (query) => {
  const { PurchaseOrder } = query
  return fetchGet(`PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/Summary`)
    .then((result) => result ? objectify(result.PurchaseOrderSummary) : null)
}

export const getDetails = (query) => {
  const { PurchaseOrder } = query
  return fetchGet(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/Details`, null, 'Inline')
    .then((result) => {
      if (result) {
        const purchaseOrderDetails = objectify(result.PurchaseOrderDetails)
        return {
          values: Object.assign(purchaseOrderDetails, {
            SupplierAddress: objectifyAddress(purchaseOrderDetails, 'Supplier'),
            DeliveryAddress: objectifyAddress(purchaseOrderDetails, 'Delivery')
          }), schema: result.PurchaseOrderDetails
        }
      } else {
        return null
      }
    })
}
