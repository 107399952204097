import React from 'react';
import classNames from 'classnames';
import { ILocationState, useModule } from 'utils/hooks';
import EMSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/ExtractMaintenance';
import { ActionBar, BreadcrumbBar } from '@markinson/uicomponents-v2';
import { Route, withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import styles from './ExtractMaintenance.styles';
import { IExtractMaintenanceProps } from './ExtractMaintenance.properties';
import TemplateDetails from './TemplateDetails';
import modules from './ExtractMaintenance.modules';
import { useTabLocations } from './ExtractMaintenance.hooks';
import options from './ExtractMaintenance.options';
import OptionsMenu from 'components/common/OptionsMenu';
import Fields from './Fields';
import { useEMContextSelector, useEMDispatch } from './ExtractMaintenance.context';

const ExtractMaintenance = (props: IExtractMaintenanceProps) => {
    const { selectedTemplateId, classes, history, location, match, isExtractMaintenanceLookUpOpen, isMenuOptionOpen, searchById, toggleMenuOption } = props;
    const url = match ? match.url : '';
    const path = match ? match.path : '';
    const { actionBarProps } = React.useContext(ActionBarContext);
    const { tabLocations } = useTabLocations(url, location);
    const params = new URLSearchParams(location.search);
    const templateId = useEMContextSelector<'TemplateId'>((state) => state.TemplateId) || Number(params.get('TemplateId'));
    const contextDispatch = useEMDispatch();

    const { selectedTab, handleModuleChange } = useModule(
        history,
        location,
        'ExtractMaintenance',
        tabLocations,
        modules,
    );

    React.useEffect(
        () => {
            contextDispatch({ setIsFieldsInEdit: false });
        },
        [templateId]
    );

    React.useEffect(
        () => {
            const urlParams = new URLSearchParams(location.search);
            if (templateId && Number(templateId) > 1) {
                searchById(urlParams.get('TemplateId'));
            }
        },
        []
    );

    const handleToggleMenuOptionOpen = React.useCallback(
        () => {
            toggleMenuOption(!isMenuOptionOpen);
        },
        [isMenuOptionOpen, toggleMenuOption]
    );
    const templateDetailsPageRef = React.useRef<any>();

    const handleActionBarButtonClick = React.useCallback(
        (action: string) => {
            switch (action) {
                case 'TemplateDetailsOnAdd':
                case 'TemplateDetailsOnEdit':
                case 'TemplateDetailsOnDelete':
                case 'TemplateDetailsCancel':
                case 'TemplateDetailsOk':
                    templateDetailsPageRef.current?.[action]();
                    break;
                case 'FieldsBackButtonClick':
                    _handleModuleChange('TemplateDetails');
                    break;
                default:
            }
        },
        [selectedTab, templateDetailsPageRef]
    );
    React.useEffect(
        () => {
            if (history.location.pathname === '/extract-maintenance') {
                const urlParams = new URLSearchParams(location.search);
                const newPath = templateId
                    ? `/extract-maintenance/template-details?TemplateId=${urlParams.get('TemplateId')}`
                    : '/extract-maintenance/template-details';
                history.push(newPath);
            }
        },
        [history]
    );

    const _handleModuleChange = React.useCallback(
        (tabToNavigate, inlineObject?: ILocationState) => {
            if (!selectedTemplateId) return;

            if (selectedTab === 'TemplateDetails' && !templateDetailsPageRef.current?.isChangesSavedOrDiscarded()) {
                return;
            }

            if (selectedTab === 'TemplateDetails' && tabToNavigate === 'TemplateDetails') {
                return;
            }

            handleModuleChange(tabToNavigate, inlineObject ?? { search: (selectedTab === 'TemplateDetails') ? { TemplateId: selectedTemplateId } : null });
        },
        [selectedTab, templateDetailsPageRef, selectedTemplateId, handleModuleChange]
    );

    return (
        <div className={classes.mainDiv}>
            <div className={classes.innerContainer}>
                <EMSearchLookUpDrawer enableToggle disabled={false} />
                <div
                    className={classNames(classes.EMSection, {
                        [classes.EMSectionMarginOpen]: isExtractMaintenanceLookUpOpen,
                        [classes.EMSectionMarginClose]: !isExtractMaintenanceLookUpOpen
                    })}
                >
                    <BreadcrumbBar
                        mainModule={'Extract Maintenance'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={_handleModuleChange}
                        onMainModuleClick={() => _handleModuleChange('TemplateDetails')}
                        onOptionsMenuClick={handleToggleMenuOptionOpen}
                    />
                    <Route
                        key={'TemplateDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}template-details`}
                        exact
                        render={() => <TemplateDetails innerRef={templateDetailsPageRef} />}
                    />
                    <Route
                        key={'Fields'}
                        path={`${path.endsWith('/') ? path : `${path}/`}fields`}
                        exact
                        render={() => <Fields />}
                    />
                </div>
            </div>
            <OptionsMenu
                open={isMenuOptionOpen}
                options={options.EM}
                onOptionClick={_handleModuleChange}
                defaultValue={selectedTab}
            />
            <ActionBar {...actionBarProps} onActionClick={handleActionBarButtonClick} />
        </div >
    );
};

export default withRouter(withStyles(styles, { index: 1 })(ExtractMaintenance));
