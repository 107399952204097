import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { INotepadFields } from '../interfaces';

class NotePad extends React.PureComponent<INotepadFields> {

  componentDidMount(): void {
    const { onInitialLoad } = this.props;
    onInitialLoad('Notepad');
  }
  getInitialValues = () => {
    const {
      selectedTab, selectedDespatchId, location
    } = this.props;
    const params = (new URLSearchParams(location.search));
    const DespatchId = parseInt(params.get('DespatchId'));

    switch (selectedTab) {
      case 'Notepad':
        return {
          params: {
            DespatchId: selectedDespatchId || DespatchId,
          }
        };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(NotePad);
