import { combineReducers } from 'redux';

import balances from './balances';
import pricing from './pricing';
import marketing from './marketing';
import detailsTransactions from './detailsTransactions';
import transactions from './transactions';
import backorders from './backorders';
import quotes from './quotes';
import salesOrders from './salesOrders';
import invoices, { IInvoicesState } from './invoices';
import childAccounts, { IChildAccountsState } from './childAccounts';
import movements, { IMovementsState } from './movements';
import serviceOrders, { IServiceOrdersState } from './serviceOrders';
import enquiries, { IEnquiriesState } from './enquiries';
import userFields, { IUserFieldsState } from './userFields';

export interface ICustomerEnquiryState {
  invoices: IInvoicesState;
  childAccounts: IChildAccountsState;
  movements: IMovementsState;
  serviceOrders: IServiceOrdersState;
  enquiries: IEnquiriesState;
  userFields: IUserFieldsState;
  [name: string]: any;
}

const customerEnquiryReducer = combineReducers<ICustomerEnquiryState>({
  pricing,
  marketing,
  balances,
  transactions,
  detailsTransactions,
  backorders,
  quotes,
  salesOrders,
  invoices,
  childAccounts,
  movements,
  serviceOrders,
  enquiries,
  userFields
});

export default customerEnquiryReducer;
