import { call, takeLatest, takeEvery, Effect } from 'redux-saga/effects';
import { login, changePasswordAPI, logout } from 'api/login';
import { callApi } from './utils';

import { types as staffTypes, actions as staffActions, ILoginFields } from 'ducks/staff';
import { DataAction } from 'ducks/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* staffLogin(action: DataAction<ILoginFields>): IterableIterator<Effect> {
  const { success, failure } = staffActions.saga.login;

  yield callApi(
    call(login, action.data),
    success,
    failure
  );
}

function showSnackBarOnPostFetch(successMessage: string, failureMessage: string): (response: { success: boolean }) => IterableIterator<Effect> {
  return function* postApiSuccessCallEffect(response: { success: boolean }): IterableIterator<Effect> {
    if (response && response.success) {
      showSnackBar({ variant: 'success', message: successMessage, time: 6000 });
    } else {
      showSnackBar({ variant: 'error', message: failureMessage, time: 6000 });
    }
  };
}

function* changePassword(action: DataAction<{ staffUserName: string; staffPassword: string; newPassword: string }>): IterableIterator<Effect> {
  const { success, failure } = staffActions.saga.changePassword;

  yield callApi(
    call(changePasswordAPI, action.data),
    success,
    failure,
    { postApiSuccessCallEffect: showSnackBarOnPostFetch('Password updated successfully', 'Invalid password') }
  );
}

function* logoutStaff(): IterableIterator<Effect> {
  const { success, failure } = staffActions.saga.logout;

  yield callApi(
    call(logout),
    success,
    failure,
    { postApiSuccessCallEffect: showSnackBarOnPostFetch('logged out successfully', 'Failed to proceed the request') }
  );
}

export default function* rootStaffSaga(): IterableIterator<Effect> {
  yield takeLatest(staffTypes.login, staffLogin);
  yield takeEvery(staffTypes.changePassword, changePassword);
  yield takeLatest(staffTypes.logout, logoutStaff);
}
