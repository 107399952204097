import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';
import InfoIcon from '@markinson/uicomponents-v2/SvgIcons/Info';
import HelpIcon from '@markinson/uicomponents-v2/SvgIcons/Help';
import DoneIcon from '@markinson/uicomponents-v2/SvgIcons/Done';

import styles from './NotificationItem.scss';

class NotificationItem extends Component {
  render() {

    const { type, message, timeStamp } = this.props;

    return (
      <div >
        <div className={styles.rootDiv} >
          <div className={styles.iconDiv} >
            {
              type === 'warning' ?
                <WarningIcon style={{ height: '100%', width: '100%', color: 'red' }}
                /> :
                type === 'info' ?
                  <InfoIcon style={{ height: '100%', width: '100%', color: '#ff6923' }}
                  /> :
                  type === 'action' ?
                    <HelpIcon
                      style={{ height: '100%', width: '100%', color: '#26a4ff' }}
                    /> : {}
            }
          </div>
          <div className={styles.contentSection}>
            <div className={styles.notificationConent}>
              {message}
            </div>
            <div className={styles.footerSection} >
              <div className={styles.metaContainer}>
                {timeStamp}
              </div>
              <div className={styles.actionContainer}>
                {type !== 'action' ?
                  <Button
                    style={{
                      lineHeight: '10px',
                      fontSize: '10px',
                      padding: '5px',
                      '& button': {
                        height: '20px'
                      },
                    }}
                    fullWidth={true}
                  >
                    CLEAR
                  </Button> :
                  <DoneIcon style={{ color: 'green' }} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.separator} >

        </div>
      </div>
    )
  }
}

export default NotificationItem;
