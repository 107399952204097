import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as saleOrderSelectors, actions as saleOrderActions } from 'ducks/purchaseOrderEnquiry/committedSaleOrder';
import { search } from 'api/purchaseOrderEnquiry/committedSaleOrder';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { actions as orderLineActions } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'CommittedSaleOrder',
  columnDefs: saleOrderSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: saleOrderSelectors.Actions(state),
  gridOptions: saleOrderSelectors.gridOptions(state),
  isLoading: saleOrderSelectors.isLoading(state),
  apiMethod: search,
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) &&
    {
      PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
    },
  keyFields: [{ valueField: 'PurchaseOrderLineNumber', routeField: 'LineNumber' }],
});

export const actions = {
  deleteSelectedRecords: saleOrderActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: orderLineActions.setSelected
}
