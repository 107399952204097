import React from 'react';
import { ILookupPanelProperties } from './LookupPanel.properties';
import inlineStyles from './LookupPanel.styles';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import ChevronLeftIcon from '@markinson/uicomponents-v2/SvgIcons/ChevronLeft';
import ChevronRightIcon from '@markinson/uicomponents-v2/SvgIcons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';

const LookupPanel = (props: ILookupPanelProperties) => {
    const { classes, open, setOpen, children } = props;

    const handleKeyPress = (event) => {
        switch (event?.key) {
            case 'Home':
                event.preventDefault();
                setOpen(true);
                break;
            case 'Escape':
                event.preventDefault();
                setOpen(false);
                break;
            default:
        }
    };

    React.useEffect(
        () => {

            document.addEventListener('keydown', handleKeyPress);

            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        },
        [handleKeyPress]
    );

    return open ? (
        <div className={classes.lookupInner}>
            <Paper style={inlineStyles.lookupContainerStyle} square={true}>
                {children}
            </Paper>

            <Paper
                className={classes.lookupDragController}
                classes={{
                    root: classNames({ [classes.openedDragController]: open })
                }}
                square={true}
                onClick={() => { setOpen(!open); }}
            >
                <ChevronLeftIcon style={inlineStyles.dragIcon} />
            </Paper>
        </div>
    ) : (
        <Paper
            className={classes.lookupDragController}
            classes={{
                root: classNames({ [classes.openedDragController]: open })
            }}
            square={true}
            onClick={() => { setOpen(!open); }}
        >
            <ChevronRightIcon style={inlineStyles.closedDragIcon} />
        </Paper>
    );
};

export default withStyles(inlineStyles, { index: 1 })(LookupPanel);
