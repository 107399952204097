import * as React from 'react';
import FormView from 'components/FormView';
import { IDairyFields } from '../interfaces';

class Diary extends React.PureComponent<IDairyFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = '/supplier-product-enquiry';
    if (path) {
      switch (path) {
        case `${context}/diary`:
          onInitialLoad('SalesDiary');
          break;
        case `${context}/diary/diary-details`:
          onInitialLoad('DiaryDetails');
          break;
        default:
          onInitialLoad('SalesDiary');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, selectedProduct
    } = this.props;
    const params = new URLSearchParams(location.search);
    const SupplierProductId = (selectedProduct && selectedProduct.SupplierProductId) || params.get('SupplierProductId');
    switch (selectedTab) {
      case 'DiaryDetails':
      case 'SalesDiary':
        return {
          params: SupplierProductId && {
            SupplierProductId: SupplierProductId
          }
        };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={(selectedTab === 'SalesDiary' && 'Diary') || selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default Diary;
