import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as searchActions, selectors as searchSelectors } from 'ducks/searchPanel';
import { selectors as programAreaSelectors } from 'ducks/programArea';
import SearchPanel from './SearchPanel';

const mapStateToProps = state => ({
  searchResults: searchSelectors.all(state),
  savedResults: searchSelectors.saved(state),
  programAreas: programAreaSelectors.programAreas(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  search: searchActions.search,
  saveSearch: searchActions.saveSearch,
  deleteSearch: searchActions.deleteSearch
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel);