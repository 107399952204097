import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Paper, CircularProgress } from '@material-ui/core';
import BillToIdActionList from 'utils/data/db/actions/supplierActionList';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps, ISummaryPanelState } from '../interfaces';
import { isNull } from 'utils/utils';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
});
const pSize = CircularProgressSize;
const paperElevation = PaperElevation;

class SummaryPanel extends React.PureComponent<ISummaryPanelProps, ISummaryPanelState> {

  constructor(props: ISummaryPanelProps) {
    super(props);
    const state: ISummaryPanelState = {
      open: false,
      dialogOpen: false,
    };
    this.state = state;
  }

  appendParam = (actionList: any, summaryData: any) => {
    if (summaryData && summaryData.Supplier) {
      return actionList.map((item) => {
        item.actionParam = { Supplier: Number(summaryData.Supplier) };

        return item;
      });
    }

    return actionList;
  }

  render(): React.ReactNode {
    const { classes, summaryData, isLoading } = this.props;
    const actionList = BillToIdActionList;
    const productDetails = {
      'SUPPLIER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{summaryData && summaryData.SupplierDisplay}</span>
          <br />
          <span >{summaryData && summaryData.SupplierLabel}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParam(actionList, summaryData)}
              disabled={isNull(summaryData && summaryData.Supplier)}
            />
          }
        </div>
      </div>,
      'SUPPLIER PRODUCT': <div>
        <span >{summaryData && summaryData.SupplierDisplay}</span>
      </div>
      ,
      'DESCRIPTION': <div>
        <span >{summaryData && summaryData.Description}</span>
      </div>,
      'BARCODE': <div>
        <span >{summaryData && summaryData.Barcode}</span>
      </div>
    };

    const userDefined = {
      'USER DEFINED 1': summaryData && summaryData.UserDefined1,
      'USER DEFINED 2': summaryData && summaryData.UserDefined2,
      'USER DEFINED 3': summaryData && summaryData.UserDefined3,
      'USER DEFINED 4': summaryData && summaryData.UserDefined4,
      'USER DEFINED 5': summaryData && summaryData.UserDefined5,
      'USER DEFINED 6': summaryData && summaryData.UserDefined6,
    };
    const numeric = (property) => ['CHARGES', 'TAX TOTAL', 'INVOICE TOTAL', 'OVERCHARGE', 'VARIANCE'].includes(property);

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Document details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(productDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {productDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>User Defined</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                < Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(userDefined).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            numeric={numeric(property)}
                            className={property === 'INVOICE TOTAL'
                              ? classes.totalCell :
                              numeric(property) ?
                                classes.numericCell
                                : classes.dataCell}>
                            {userDefined[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, {index: 1})(SummaryPanel);
