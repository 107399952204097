import { createStyles } from '@material-ui/core/styles';

const noBorder = {
  borderTop: 'none',
  borderBottom: 'none',
};

const focused = {
  backgroundColor: '#cccccc',
  color: 'inherit'
};

export default createStyles({
  FulfillmentDataGrid: {
    'width': '100%',
    'height': '100%',
    'maxWidth': '100% !important',
    '& .dx-datagrid-rowsview .dx-row,': {
      ...noBorder,
    },
    '& .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td': {
      ...noBorder,
    },
    '& .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td': {
      ...noBorder,
    },
    '& .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td': {
      ...noBorder,
      ...focused
    },
    '& .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td': {
      ...noBorder,
    },
    '& .dx-datagrid-headers .dx-row > td ': {
      outline: 'unset !important',
      alignContent: 'end'
    },
    '& .dx-datagrid-headers table.dx-datagrid-table ': {
      height: '100%',
    },
    '& .dx-checkbox-container .dx-checkbox-icon ': {
      width: '20px',
      height: '18px',
      border: '2px solid #333333',
      marginBottom: '3px',
    },
    '& .dx-checkbox-checked .dx-checkbox-icon': {
      color: '#333333',
    },
    '& .dx-checkbox-indeterminate .dx-checkbox-icon::before': {
      backgroundColor: '#333333',
    },
    '& td[role="columnheader"] > .dx-datagrid-text-content': {
      width: '100%'
    },
    '& .dx-datagrid-content .dx-datagrid-table .dx-row > td': {
      verticalAlign: 'bottom'
    },
  },
  radio: {
    '& .MuiSvgIcon-root': {
      height: 16,
      width: 16,
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
    'margin': 0,
    '& .MuiRadio-checked': {
      color: '#000'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  capabilityPercent: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000'
  },
  capabilityLabel: {
    fontSize: '14px',
    textTransform: 'uppercase'
  },
  warehouseQuantity: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500
  },
  red: {
    color: '#FF0000'
  },
  green: {
    color: '#43A047'
  },
  amber: {
    color: '#FFA000'
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 'calc(100% - 110px)'
  },
  grid: {
    height: 'calc(100% - 30px)',
    margin: 15,
    marginRight: 0
  },
  Icon: {
    cursor: 'pointer',
  },
  actionButtonIcon: {
    cursor: 'pointer',
    fontSize: '18px',
    margin: '0 3px',
    color: '#337ab7'
  },
  anchor: {
    display: 'inline-flex'
  },
  disabledIcon: {
    color: 'gray',
    pointerEvents: 'none'
  },
  highlight: {
    background: '#e6e6e6 !important'
  },
});
