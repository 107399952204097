import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/priceCheck/why';

import { callApi } from '../utils';
import * as api from 'api/priceCheck/why';


function* search(action){
  const {success, failure} = actions.saga.fetch;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootSaga(){
  yield takeLatest(types.fetch, search)
}