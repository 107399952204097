import { combineReducers } from 'redux';
import whereUsedBOM from './BOM';
import whereUsedCatalogue from './Catalogue';

const whereUsedReducer = combineReducers({
  whereUsedBOM,
  whereUsedCatalogue
});

export default whereUsedReducer;
