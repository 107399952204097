import { IFormViewForm } from 'components/FormView';

const SlipLineBOM: IFormViewForm = {
  id: 'SlipLineBOM',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [{
        type: 'DATA_GRID_DEV_EX',
        props: {
          name: 'lines',
          module: 'PSEBOM'
        }
      }]
    }
  ]
};
export default SlipLineBOM;
