import optionsData from 'components/MainMenu/ModuleData.json'
class Search
{
  static saved = ['Sales', 'Purchases', 'Reports']
 getOptions = () => {
   var arr1 = []
   optionsData.map(level1 => {
     //  arr1.push(level1.label)
     if (level1.subMenu) {
       level1.subMenu.map(level2 => {
         //  arr1.push(level2.label)
         if (level2.children) {
           level2.children.map(level3 => {
             arr1.push(level3);
             return level3;
           })
         }
         return level2;
       })
     }
     return level1;
   });
   return arr1;
 }
}
export default Search
