import React from 'react';
import CheckCircleOutline from '@markinson/uicomponents-v2/SvgIcons/CheckCircleOutline';
import { createStyles } from '@material-ui/core/styles';

const inlineStyles = createStyles({
    serviceActivitySectionContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        cursor: 'unset'
    },
    customSectionIcon: {
        color: '#009E11',
        fontSize: '18px',
        marginRight: '2px'
    }
});

const ServiceActivityCustomItemSection = (item: any): React.ReactNode => {

    return (item && item.ActivityStatus === 'C') ? (
        <div style={inlineStyles.serviceActivitySectionContainer} title="This activity can't be dragged.">
            <CheckCircleOutline style={inlineStyles.customSectionIcon} /> Completed
        </div>
    ) : null;
};

export default ServiceActivityCustomItemSection;
