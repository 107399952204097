import { IFormViewForm } from 'components/FormView';

const ServiceAgreementDetail: IFormViewForm = {
  id: 'ServiceAgreementDetail',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            required: true,
            size: 'large',
            inputProps: {
              maxLength: 40
            }
          }
        },
        {
          id: '2',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Customer',
            name: 'Customer',
            lookupName: 'Customer',
            placeholder: '',
            required: true,
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'DATE_FIELD',
          props: {
            label: 'Start',
            name: 'StartDate',
            required: true,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            defaultValue: '',
            nullable: true
          }
        },
        {
          id: 4,
          type: 'DATE_FIELD',
          props: {
            label: 'Finish',
            name: 'ExpiryDate',
            required: true,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            defaultValue: '',
            nullable: true
          }
        }
      ]
    },
    {
      id: '2',
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            placeholder: '',
            style: {
              height: 250
            }
          }
        },
      ]
    }
  ]
};
export default ServiceAgreementDetail;
