import { combineReducers } from 'redux';
import slipDetails from './slipDetails';
import pickSlipEnquiries, { IPSEState } from './pickSlipEnquiries';
import slipLines, { ISlipLinesState } from './slipLines';
import lots, { ILotsState } from './lots';
import serials, { ISerialsState } from './serials';
import bom, { IBOMState } from './bom';
import bomLots, { IBOMLotsState } from './bomLots';
import bomSerials, { IBOMSerialsState } from './bomSerials';

export interface IPickSlipEnquiryState {
  pickSlipEnquiries: IPSEState;
  slipLines: ISlipLinesState;
  lots: ILotsState;
  serials: ISerialsState;
  bom: IBOMState;
  bomLots: IBOMLotsState;
  bomSerials: IBOMSerialsState;
  [name: string]: any;
}
const combined = combineReducers<IPickSlipEnquiryState>({
  slipDetails,
  pickSlipEnquiries,
  slipLines,
  serials,
  lots,
  bom,
  bomLots,
  bomSerials,
});

export default combined;
