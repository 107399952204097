import * as React from 'react';
import FormView from '../../FormView';
import * as SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from '../../common/SummaryTable';
import { withRouter } from 'react-router-dom';
import { IPurchasingProps } from '../interfaces';

class Purchasing extends React.PureComponent<IPurchasingProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-enquiry/purchasing/local-shipments':
          onInitialLoad('LocalShipments');
          break;
        case '/supplier-enquiry/purchasing/purchase-orders':
          onInitialLoad('SupplierPurchaseOrder');
          break;
        case '/supplier-enquiry/purchasing/products':
          onInitialLoad('SupplierPurchasingProducts');
          break;
        case '/supplier-enquiry/purchasing/purchase-diary':
          onInitialLoad('PurchaseDiary');
          break;
        case '/supplier-enquiry/purchasing/purchase-diary/diary-details':
          onInitialLoad('PurchaseDiaryDetails');
          break;
        default:
          onInitialLoad('SupplierPurchaseOrder');
      }
    }
  }

  getSummaryTableRenderer = (selectedTab: string) => {
    switch (selectedTab) {
      case 'SupplierPurchaseOrder':
        return this.getPurchaseOrdersSummary;
      default: return null;
    }
  }

  getPurchaseOrdersSummary = () => {
    const { purchaseOrdersSummary, loadingPurchaseOrdersSummary } = this.props;

    return <SummaryTable
      loadingPurchaseOrdersSummary={loadingPurchaseOrdersSummary}
      purchaseOrdersSummary={purchaseOrdersSummary}
      data={
        SummaryTableSchema.SupplierPurchaseOrder
      } />;
  }

  getInitialValues = (): any => {
    const {
      selectedTab, purchaseOrdersSummary, loadingPurchaseOrdersSummary, selectedSupplier
    } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const supplier = (selectedSupplier && selectedSupplier.Supplier) || params.get('Supplier');
    switch (selectedTab) {
      case 'SupplierPurchaseOrder':
        return !loadingPurchaseOrdersSummary && purchaseOrdersSummary ? { initialValues: purchaseOrdersSummary, valuesSchema: null } : null;
      case 'PurchaseDiary':
      case 'PurchaseDiaryDetails':
        return { initialValues: { params: supplier && { Supplier: parseInt(supplier) }, doubleClickActionTab: 'PurchaseDiaryDetails' }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={(selectedTab === 'PurchaseDiary' && 'Diary')
            || (selectedTab === 'PurchaseDiaryDetails' && 'DiaryDetails')
            || formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Purchasing);
