const options = {
    EM: [
        {
            label: 'Run Dynamic Extract',
            value: 'RunDynamicExtract',
            SelectionDisabled: true,
            children: [
                {
                    label: 'Template Details',
                    value: 'TemplateDetails'
                },
                {
                    label: 'Fields',
                    value: 'Fields'
                },
            ]
        }
    ],
};

export default options;
