import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import SupplierProductEnquiry from './SupplierProductEnquiry';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { actions as productPriceScheduleActions, selectors as productPriceScheduleSelectors } from 'ducks/supplierProductEnquiry/productPriceSchedule';
import { selectors as supplierProductSelectors, actions as SupplierProductActions } from 'ducks/supplierProductEnquiry/supplierProduct';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state),
  selectedForm: formSelectors.selected(state),
  selectedDiary: diarySelectors.selected(state),
  selectedPriceSchedule: productPriceScheduleSelectors.selected(state),
  summary: supplierProductSelectors.summary(state),
  selectedProduct: supplierProductSelectors.selected(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSummary: SupplierProductActions.getSummary,
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeSelectedTab: uiActions.changeSelectedTab,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    lockDrawer: uiActions.lockDrawer,
    unlockDrawer: uiActions.unlockDrawer,
    closeMenu: uiActions.closeMainMenu,
    fetchProductPriceScheduleDetails: productPriceScheduleActions.fetchDetails,
    fetchProductPriceScheduleSettings: productPriceScheduleActions.fetchSettings,
    fetchProductPriceScheduleSellPrices: productPriceScheduleActions.fetchSellPrices,
    fetchProductPriceScheduleComments: productPriceScheduleActions.fetchComments,
    changeOperationMode: operationActions.changeOperationMode,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductEnquiry);
