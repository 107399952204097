import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IFormViewForm, FormViewField } from '../../FormView/index';
import { Operation } from '../../../utils/operations';
import DynamicFormView from '../../FormView/DynamicFormView/index';
import { isNull } from 'utils/utils';
import { IValidationDialogProperties } from './ValidationDialog.properties';
import FocusLock from 'react-focus-lock';
import { capitalize } from 'lodash';

function getLayoutSchema(formSchema: IFormViewForm): FormViewField[][] {
  const { rows, columns } = formSchema.layout;
  const { fields } = formSchema;
  const layout = [];

  for (let i = 1; i <= rows; i++) {
    const rowItems = [];

    for (let j = 1; j <= columns; j++) {
      const colItems = [fields.filter((field) => {
        return field.position.row === i && field.position.col === j;
      })];

      rowItems.push(colItems);
    }

    layout.push(rowItems);
  }

  return layout;
}

class ValidationDialogView extends React.Component<IValidationDialogProperties, any> {

  FormView: any;
  timer: NodeJS.Timeout;

  componentWillUnmount(): void {
    clearInterval(this.timer);
  }
  handleAction = (action: { isDefault: boolean; label: string; callback(formValues: any): void }): boolean => {
    const { onClose, initialValues, formValues, pollingTimeoutMilliseconds } = this.props;

    clearInterval(this.timer);

    if (onClose) {
      onClose();
    }

    if (!isNull(pollingTimeoutMilliseconds) && pollingTimeoutMilliseconds === 0) {
      return true;
    }

    action.callback({ ...initialValues, ...formValues });

    return true;
  }

  handleEntered = () => {
    const { pollingTimeoutMilliseconds } = this.props;
    if (!isNull(pollingTimeoutMilliseconds) && pollingTimeoutMilliseconds !== 0) {

      this.timer = setInterval(this.handleRefresh, pollingTimeoutMilliseconds);
    }
  }

  handleRefresh = () => {
    const { refreshAction } = this.props;
    if (refreshAction) {
      refreshAction.callback({ action: 'RefreshStatus' });
    }
  }

  render(): React.ReactNode {
    const { open, message, title, actions, form, initialValues, valuesSchema, modalContent } = this.props;

    return (<Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={'sm'}
      style={{ whiteSpace: 'pre-line', zIndex: 9999 }}
      aria-labelledby={'confirmation-dialog-title'}
      open={open}
      onEntered={this.handleEntered}
    >
      <DialogTitle id={'confirmation-dialog-title'}>{title}</DialogTitle>
      <FocusLock autoFocus={false}>
        <DialogContent>
          {message && message.split('\\n').map((splitMessage, key) => (<p key={key}> {splitMessage}</p>))}
          {modalContent && modalContent}
          {form && <DynamicFormView
            formName={'ValidationDialog'}
            ref={(el) => this.FormView = el}
            operationMode={Operation.EDIT}
            layout={getLayoutSchema(form)}
            initialValues={initialValues}
            valuesSchema={valuesSchema}
            enableReinitialize={true}
          />}
        </DialogContent>
        <DialogActions>
          {actions.map((action) =>
            <Button
              key={action.name}
              onClick={() => this.handleAction(action)}
              color={action.isDefault ? 'default' : 'secondary'}>
              {capitalize(action.label)}
            </Button>
          )}
        </DialogActions>
      </FocusLock>
    </Dialog>);
  }
}

export default ValidationDialogView;
