import { useQuery, UseQueryOptions, QueryKey, useQueryClient, useMutation } from '@tanstack/react-query';
import { fetchFile, injectParamValuesUrl } from 'api/utils';
import { clone, isArray } from 'lodash';
import { useProcessValidationForms } from 'utils/processValidationForms';
import { isNull } from 'utils/utils';
import { Api } from '../baseApi';
import { IDeleteRecordResponse } from '../swaggerTypes';

export enum ResponseType {
  Json = 'Json',
  file = 'file'
}
export interface IOptions {
  disableDefaultValidation?: boolean;
  inline?: boolean;
  responseType?: ResponseType;
  responseParser?(response: any): any;
}
const DefaultOptions = {
  inline: false,
  responseType: ResponseType.Json,
  disableDefaultValidation: false,
  responseParser: null
};

interface IUrlParams<U = object> {
  urlQueryParams?: U;
}

export enum APIMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type Payload<P = any, U = object> = IUrlParams<U> & P;

const appendUrlSearchParams = (url: string, params: object): string => {
  if (isNull(url) || isNull(params)) {
    return url;
  }

  let updatedUrl = clone(url);
  const urlQueryParams = new URLSearchParams('');
  Object.keys(params).forEach((key) => {
    urlQueryParams.set(key, params[key]);
  });
  updatedUrl = `${updatedUrl}?${urlQueryParams.toString()}`;

  return updatedUrl;
};

export const prepareUrl = (url: string, data: any, params: object, inline: boolean = false): string =>
  appendUrlSearchParams(injectParamValuesUrl(url, data), { ...params, ...data?.urlQueryParams, responseMetadataFormat: inline ? 'Inline' : 'None' });

const processApiWithValidations = (processValidationForms) => <R, P = null>(method: APIMethod, url: string, data: P & {arrayTypePayload?: []} = null): Promise<R> => {

  const _processApiWithValidations = processApiWithValidations(processValidationForms);

  const payload = isArray(data?.arrayTypePayload) ? data?.arrayTypePayload : { ...data, urlQueryParams: undefined } as any;

  switch (method) {
    case APIMethod.GET: {
      return Api.get<R>(url)
        .then((response) => processValidationForms(
          response,
          null,
          () => _processApiWithValidations<R>(APIMethod.GET, url)
        ));
    }
    case APIMethod.POST: {
      return Api.post<P, R>(url, payload)
        .then((response) => processValidationForms(
          response,
          payload,
          (validationData) => _processApiWithValidations<R>(APIMethod.POST, url, validationData)
        ));
    }
    case APIMethod.PUT: {
      return Api.put<P, R>(url, payload)
        .then((response) => processValidationForms(
          response,
          payload,
          (validationData) => _processApiWithValidations<R>(APIMethod.PUT, url, validationData)
        ));
    }
    case APIMethod.DELETE: {
      return Api.delete<R>(url)
        .then((response) => processValidationForms(
          response,
          null,
          () => _processApiWithValidations<R>(APIMethod.DELETE, url)
        ));
    }
    default:
      return null;
  }

};

export const fetcher = <R>(
  {
    url,
    urlQueryParams,
    options = {},
    urlParams
  }: { url: string; urlQueryParams: object; options?: IOptions; urlParams: object },
  processValidationForms,
): Promise<R> => {
  const isResponseTypeFile = options?.responseType === ResponseType.file;

  let fetchPromise = null;
  if (isResponseTypeFile) {
    fetchPromise = fetchFile(url as string, urlQueryParams);
  } else {
    const finalizedURL = prepareUrl(url as string, urlParams, urlQueryParams as object, options?.inline);

    const _processApiWithValidations = processApiWithValidations(processValidationForms);

    fetchPromise = options?.disableDefaultValidation ? Api
      .get<R>(finalizedURL)
      : _processApiWithValidations<R>(APIMethod.GET, finalizedURL);
  }

  return fetchPromise
    .then((response) => options?.responseParser ? options.responseParser(response) : options)
    .catch((err) => console.warn(err));
};

interface IUseFetchParams<R> {
  url: string;
  urlParams?: object;
  urlQueryParams?: object;
  queryConfig?: UseQueryOptions<R, Error, R, QueryKey>;
  options?: IOptions;
}
/**
*  To fetch results using useQuery and get API call method
* @param {Object} query useFetch Params.
* @param {Object} query.url api endpoint.
* @param {Object} [query.urlQueryParams] url search params with question mark ?.
* @param {Object} [query.urlParams] url query values that will be injected in url query variables.
* @param {Object} [query.config] useQuery options.
* @param {Object} [query.options] custom options for api.
* @template R Response type.
* @returns useQuery result
*/
export const useFetch = <R>({
  url,
  urlQueryParams,
  queryConfig,
  options,
  urlParams
}: IUseFetchParams<R>) => {
  const processValidationForms = useProcessValidationForms();

  return useQuery({
    queryKey: [APIMethod.GET, prepareUrl(url, urlParams, urlQueryParams, options?.inline)],
    queryFn: () => fetcher<R>({ url, urlParams, urlQueryParams, options }, processValidationForms),
    enabled: !!url && queryConfig?.enabled,
    ...queryConfig
  });
};

const useGenericMutation = <P extends IUrlParams, R>(
  func: (data: P) => Promise<R>,
  url: string,
  method: APIMethod,
  urlQueryParams?: object,
  inline = false
) => {
  const queryClient = useQueryClient();

  return useMutation(func, {
    onSuccess: (data, payload) => {
      const { urlQueryParams: dataUrlQueryParams } = payload;
      queryClient.setQueryData([method, prepareUrl(url!, payload, { ...urlQueryParams, ...dataUrlQueryParams }, inline)], data);
    },
    onError: (err) => {
      console.error(err);
    },
  });
};

/**
* To fetch result using useMutation and Get API call method.
* It is normally used when you want to call fetch on button click and want pass some url search params with question mark ?
* @param url api endpoint.
* @param urlQueryParams url search params with question mark ?.
* @param options custom options for api.
* @template R Response type.
* @template P Response payload.
* @returns useMutation result
*/
export const useFetchMutation = <P extends IUrlParams, R>(
  url: string,
  urlQueryParams?: object,
  options: IOptions = DefaultOptions
) => {
  const processValidationForms = useProcessValidationForms();

  return useGenericMutation<P, R>(
    (data) => {
      const { urlQueryParams: dataUrlQueryParams } = data;
      const isResponseTypeFile = options?.responseType === ResponseType.file;

      const finalizedUrl = isResponseTypeFile ? injectParamValuesUrl(url, data)
        : prepareUrl(url, data, { ...urlQueryParams, ...dataUrlQueryParams, }, options?.inline);

      let fetchMutationPromise = null;
      if (isResponseTypeFile) {
        fetchMutationPromise = fetchFile(finalizedUrl, { ...urlQueryParams, ...dataUrlQueryParams });
      } else {
        const _processApiWithValidations = processApiWithValidations(processValidationForms);

        fetchMutationPromise = options?.disableDefaultValidation ?
          Api.get<R>(finalizedUrl)
          : _processApiWithValidations<R>(APIMethod.GET, finalizedUrl);
      }

      return fetchMutationPromise
        .then((response) => options?.responseParser ? options.responseParser(response) : response)
        .catch((err) => console.warn(err));
    },
    url,
    APIMethod.GET,
    urlQueryParams,
    options?.inline
  );
};

/**
*  To delete result using useMutation and delete API call method
* @param url api endpoint.
* @param urlQueryParams url search params with question mark ?.
* @param options custom options for api.
* @template R Response type.
* @returns useMutation result
*/
export const useDelete = <P extends IUrlParams, R = IDeleteRecordResponse>(
  url: string,
  urlQueryParams?: object,
  options: IOptions = {}
) => {
  const processValidationForms = useProcessValidationForms();

  return useGenericMutation<P, R>(
    (data) => {
      const { urlQueryParams: dataUrlQueryParams } = data;
      const finalizedUrl = prepareUrl(url, data, { ...urlQueryParams, ...dataUrlQueryParams }, options?.inline);

      const _processApiWithValidations = processApiWithValidations(processValidationForms);

      const deletePromise = options?.disableDefaultValidation ?
        Api.delete<R>(finalizedUrl)
        : _processApiWithValidations<R>(APIMethod.DELETE, finalizedUrl);

      return deletePromise
        .then((response) => options?.responseParser ? options.responseParser(response) : response)
        .catch((err) => console.warn(err));
    },
    url,
    APIMethod.DELETE,
    urlQueryParams,
    options?.inline
  );
};

/**
* To post result using useMutation and post API call method
* @param url api endpoint.
* @param urlQueryParams url search params with question mark ?.
* @param options custom options for api.
* @template R Response type.
* @template P Payload type.
* @returns useMutation result
*/
export const usePost = <P extends IUrlParams, R>(
  url: string,
  urlQueryParams?: object,
  options: IOptions = {}
) => {
  const processValidationForms = useProcessValidationForms();

  return useGenericMutation<P, R>(
    (data) => {
      const { urlQueryParams: dataUrlQueryParams } = data;
      const finalizedUrl = prepareUrl(url, { ...data, urlQueryParams: undefined }, { ...urlQueryParams, ...dataUrlQueryParams }, options?.inline);

      const _processApiWithValidations = processApiWithValidations(processValidationForms);
      const payload = { ...data, urlQueryParams: undefined };

      const postPromise = options?.disableDefaultValidation ?
        Api.post<P, R>(finalizedUrl, payload)
        : _processApiWithValidations(APIMethod.POST, finalizedUrl, data);

      return postPromise
        .then((response) => options?.responseParser ? options.responseParser(response as R) : response)
        .catch((err) => console.warn(err));
    },
    url,
    APIMethod.POST,
    urlQueryParams,
    options?.inline
  );
};

/**
* To update result using useMutation and put API call method
* @param url api endpoint.
* @param urlQueryParams url search params with question mark ?.
* @param options custom options for api.
* @template R Response type.
* @template P Payload type.
* @returns useMutation result
*/
export const useUpdate = <P extends IUrlParams, R>(
  url: string,
  urlQueryParams?: object,
  options: IOptions = {}
) => {
  const processValidationForms = useProcessValidationForms();

  return useGenericMutation<P, R>(
    (data) => {
      const { urlQueryParams: dataUrlQueryParams } = data;
      const finalizedUrl = prepareUrl(url, data, { ...urlQueryParams, ...dataUrlQueryParams }, options?.inline);

      const _processApiWithValidations = processApiWithValidations(processValidationForms);
      const payload = { ...data, urlQueryParams: undefined };

      const updatePromise = options?.disableDefaultValidation ?
        Api.put<P, R>(finalizedUrl, payload)
        : _processApiWithValidations(APIMethod.PUT, finalizedUrl, data);

      return updatePromise
        .then((response) => options?.responseParser ? options.responseParser(response as R) : response)
        .catch((err) => console.warn(err));
    },
    url,
    APIMethod.PUT,
    urlQueryParams,
    options?.inline
  );
};
