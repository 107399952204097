import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';

export const fetchBOM = (query) => {
  const { DespatchId, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(`/PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}/BillOfMaterials`, {}, {
    BatchPage: Page,
    Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
    BatchSize
  })
    .then((result) => {
      if (result) {
        if (result.PickSlipBOMLines) {
          return {
            records: result.PickSlipBOMLines.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};
export const fetchLots = (query) => {
  const { DespatchId, BOMLineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(`/PickSlipEnquiry/Despatch/${DespatchId}/Line/${BOMLineNumber}/BillOfMaterial/Lots`, {}, {
    BatchPage: Page,
    Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
    BatchSize
  })
    .then((result) => {
      if (result) {
        if (result.PickSlipcomponentLots) {
          return {
            records: result.PickSlipcomponentLots.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};
export const fetchSerials = (query) => {
  const { DespatchId, BOMLineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(`/PickSlipEnquiry/Despatch/${DespatchId}/Line/${BOMLineNumber}/BillOfMaterial/Serials`, {}, {
    BatchPage: Page,
    Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
    BatchSize
  })
    .then((result) => {
      if (result) {
        if (result.PickSlipcomponentLots) {
          return {
            records: result.PickSlipcomponentLots.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchDetails = (query) => {
  const { DespatchId, BOMLineNumber } = query;
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Line/${BOMLineNumber}/BillOfMaterial/Details`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipComponentDetail),
      schema: result.PickSlipComponentDetail
    }));

  return apiCall;
};

export const fetchSummary = (query) => {
  const { DespatchId, BOMLineNumber } = query;
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Line/${BOMLineNumber}/BillOfMaterial/Summary`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipComponentSummary),
      schema: result.PickSlipComponentSummary
    }));

  return apiCall;
};
