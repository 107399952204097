import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors,
    IExtendedState, IDataAction, SuccessAction
} from '../utils';

export interface IPOData {
    selected: number;
    list: any[];
    schemas: any;
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    removeWhenPrev: number;
    currSearchText: string;
}

export interface IPOState extends IExtendedState<IPOData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
    {
        reset: () => null,
        setSelected: (PurchaseOrder) => ({ PurchaseOrder }),
        asyncs: {
            search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
            searchById: (id: number) => id,
            fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
            fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
        }
    },
    'purchaseOrders');

const NOT_SELECTED = -1;
const initialState = asyncInitialState<IPOData>({
    selected: NOT_SELECTED,
    list: null,
    schemas: null,
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 100,
    totalPages: 5,
    removeWhenPrev: 0,
    currSearchText: ''
});

export default (state: IPOState = initialState, action: IDataAction): IPOState => {
    switch (action.type) {
        case types.setSelected:
            const selected = action.data.PurchaseOrder;

            return {
                ...state,
                data: { ...state.data, selected }
            };
        case types.search:
            return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
        case types.searchById:
            return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
        case types.fetchNextPage:
        case types.fetchPrevPage:
            action.data.SearchText = state.data.currSearchText;

            return asyncOnRequest(state, action);
        case types.saga.search.success:
        case types.saga.searchById.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPOData, successAction: SuccessAction) => {

                    const purchaseOrders = successAction.payload.records.map((combinedObject) => combinedObject.inlineObject);
                    const schemas = successAction.payload.records.map((combinedObject) => combinedObject.schema);

                    return {
                        ...data,
                        list: purchaseOrders,
                        schemas: schemas,
                        nextPage: successAction.payload.nextPage && parseInt(successAction.payload.currPage) + 1,
                        prevPage: successAction.payload.prevPage && parseInt(successAction.payload.currPage) - 1,
                        currPage: successAction.payload.currPage
                    };
                },
                { fetch: true });
        case types.saga.fetchNextPage.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPOData, successAction: SuccessAction) => {
                    const result = successAction.payload;
                    const purchaseOrders = result.records.map((combinedObject) => combinedObject.inlineObject);
                    const schemas = result.records.map((combinedObject) => combinedObject.schema);
                    const newRemoval = result.records.length;
                    const newList = data.list.concat(purchaseOrders);
                    const newSchemas = data.schemas.concat(schemas);

                    return {
                        ...data,
                        list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                        schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                        nextPage: result.nextPage && parseInt(result.currPage) + 1,
                        prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
                        currPage: result.currPage,
                        removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                    };
                },
                { fetch: true });

        case types.saga.fetchPrevPage.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPOData, successAction: SuccessAction) => {
                    const result = successAction.payload;
                    const purchaseOrders = result.records.map((combinedObject) => combinedObject.inlineObject);
                    const schemas = result.records.map((combinedObject) => combinedObject.schema);
                    const newList = purchaseOrders.slice(0, data.removeWhenPrev).concat(data.list);
                    const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                    return {
                        ...data,
                        list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                        schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                        prevPage: result.prevPage && result.currPage - 1,
                        nextPage: (newList.length > data.pageSize * data.totalPages) && (parseInt(result.currPage) + data.totalPages),
                        currPage: result.currPage,
                        removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                    };
                },
                { fetch: true });

        case types.saga.search.failure:
        case types.saga.searchById.failure:
        case types.saga.fetchNextPage.failure:
        case types.saga.fetchPrevPage.failure:

            return asyncOnError(state, action);
        case types.reset:
            return initialState;
        default:
            return state;
    }
};

const asyncSelector = asyncSelectors(
    (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.PurchaseOrder === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.PurchaseOrder.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
        selectedPurchaseOrder: (data) => data.selected
    }
);

const syncSelector = {
    isLoading: (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders.search_loading || state.purchaseOrders.orders.searchById_loading,
    loadingNextPage: (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders.fetchNextPage_loading,
    loadingPrevPage: (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders.fetchPrevPage_loading,
    pageSize: (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders.data.pageSize,
    totalPages: (state: { purchaseOrders: { orders: IPOState } }) => state.purchaseOrders.orders.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
