import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    recallParkedSaleContainer: {
        maxWidth: 1520,
    },
    popup: {
        width: 400,
        height: 150,
        overflow: 'auto'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabled: {
        color: '#AAAAAA !important',
        cursor: 'default !important'
    },
    infoButton: {
        color: '#337ab7',
        width: 18,
        height: 17,
        cursor: 'pointer'
    }
});
