import React from 'react';
import ReactDOM from 'react-dom';
import { default as App } from './App';
import './index.scss';
import { QueryClientProvider } from '@tanstack/react-query';
import QueryClient from './queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SnackBarProvider from 'components/common/SnackBars/SnackBarProvider';

ReactDOM.render(
  <QueryClientProvider client={QueryClient}>
    <SnackBarProvider>
      <App />
    </SnackBarProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById('root')
)
