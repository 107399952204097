import { call, put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { actions as operationActions } from 'ducks/uiOperations';
import { initialize } from 'redux-form';
import { Operation } from 'utils/operations';
import { types as addressTypes, actions as addressActions } from 'ducks/address';

import { callApi } from './utils';
import * as api from 'api/address';


function* search(action) {
  const { success, failure } = addressActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action) {
  const { success, failure } = addressActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* create(action) {
  const { success, failure } = addressActions.saga.create;

  function* postApiFailureCallEffect() {
    yield put(operationActions.changeOperationMode(Operation.NEW));
    yield put(initialize('CustomerAddresses', action.data.values));
  }
  function* postApiSuccessCallEffect() {
    yield put(operationActions.changeOperationMode(Operation.BROWSE));
  }

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: create, postApiFailureCallEffect, postApiSuccessCallEffect }
  )
}

function* update(action) {
  const { success, failure } = addressActions.saga.update;

  function* postApiFailureCallEffect() {
    yield put(operationActions.changeOperationMode(Operation.EDIT));
    yield put(initialize('CustomerAddresses', action.data.values));
  }

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: update, postApiFailureCallEffect }
  )
}

function* remove(action) {
  const { success, failure } = addressActions.saga.remove;
  yield callApi(
    call(api.remove, action.data),
    success,
    failure,
    { dialogActionCallEffect: remove }
  )
}

function* fetchPrimary(action) {
  const { success, failure } = addressActions.saga.fetchPrimary;
  yield callApi(
    call(api.fetchPrimary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(addressTypes.search, search)
  yield takeLatest(addressTypes.searchById, searchById)
  yield takeLatest(addressTypes.create, create)
  yield takeLatest(addressTypes.update, update)
  yield takeLatest(addressTypes.remove, remove)
  yield takeLatest(addressTypes.fetchPrimary, fetchPrimary)
}