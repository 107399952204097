import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'inPutAwayWIP');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Reference', field: 'Reference', suppressSorting: true, minWidth: 250, },
    { headerName: 'Line', field: 'Line', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Entered', field: 'Entered', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Status', field: 'Status', suppressSorting: true, minWidth: 120, },
    { headerName: 'Account', field: 'AccountNumber', suppressSorting: true, minWidht: 120, },
    { headerName: 'Name', field: 'Name', suppressSorting: true, minWidth: 250, }
  ],
  Data: [],
  selectedFilters: {
    DocumentType: 'All'
  },
  filterRow: {
    formName: 'IEStockingQuantitiesInPutAwayWIPFilters',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Document type',
          name: 'DocumentType',
          required: true,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'StockReceipt',
              label: 'Stock Receipt'
            },
            {
              value: 'CreditNote',
              label: 'Credit Note'
            },
            {
              value: 'RFC',
              label: 'RFC'
            },
            {
              value: 'LocalShipment',
              label: 'Local Shipment'
            },
            {
              value: 'ImportShipment',
              label: 'Import Shipment'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.inPutAwayWIP,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.inPutAwayWIP.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.inPutAwayWIP.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.inPutAwayWIP.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.inPutAwayWIP.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.inPutAwayWIP.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)