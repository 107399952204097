import { combineReducers } from 'redux';
import slipDetails, { ISlipDetailsState } from './slipDetails';
import pickSlipTransaction, { IPSState } from './pickSlipTransaction';
import slipLines, { ISlipLinesState } from './slipLines';
import lots, { ILotsState } from './lots';
import serials, { ISerialsState } from './serials';
import bom, { IBOMState } from './bom';
import bomLots, { IBOMLotsState } from './bomLots';
import bomSerials, { IBOMSerialsState } from './bomSerials';
import binLocations, { IBinLocationsState } from './binLocations';
import kitComponents, { IKitComponentsState } from './kitComponents';

export interface IPickSlipsState {
  pickSlipTransaction: IPSState;
  slipLines: ISlipLinesState;
  slipDetails: ISlipDetailsState;
  lots: ILotsState;
  serials: ISerialsState;
  bom: IBOMState;
  bomLots: IBOMLotsState;
  bomSerials: IBOMSerialsState;
  binLocations: IBinLocationsState;
  kitComponents: IKitComponentsState;
  [name: string]: any;
}
const combined = combineReducers<IPickSlipsState>({
  slipDetails,
  pickSlipTransaction,
  slipLines,
  serials,
  lots,
  bom,
  bomLots,
  bomSerials,
  binLocations,
  kitComponents
});

export default combined;
