import { IFormViewForm } from 'components/FormView';

const ProductPriceScheduleDetails: IFormViewForm = {
  id: 'ProductPriceScheduleDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Price schedule details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Description',
            name: 'Description',
            size: 'large'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Start',
            name: 'Start',
            size: 'small'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'End',
            name: 'End',
            size: 'small'
          }
        },
        {
          id: 3,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Standard pricing',
            name: 'StandardPricing'
          }
        },
        {
          id: 4,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Consolidated pricing',
            name: 'ConsolidatedPricing'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Buy prices',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supplier base',
            alignment: 'right',
            name: 'SupplierBaseDisplay',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Supplier discount',
            alignment: 'right',
            name: 'SupplierDiscountDisplay',
            adornment: '%',
            size: 'small'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Buy price',
            alignment: 'right',
            name: 'BuyPriceDisplay',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Tax included buy price',
            name: 'TaxIncludedBuyPrice'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Exchange rate',
            name: 'ExhangeRateDisplay',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Estimated landed',
            name: 'EstimatedLandedDisplay',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1,
        colspan: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Buy price break details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'BREAKS_TABLE',
          visible: true,
          props: {
            variant: 'ProductPriceScheduleDetails',
            name: 'Breaks'
          }
        }
      ]
    }
  ]
};
export default ProductPriceScheduleDetails;
