import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';

export interface IKittingData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    hidePreFilters?: boolean;
    formName: string;
    parameters: FormViewField[];
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IKittingState extends IExtendedState<IKittingData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {

    }
  },
  'stockingQuantitiesKitting');

const initialState = asyncInitialState<IKittingData>({
  selected: null,
  selectedFilters: {
    SalesEntity: '',
    CustomerId: 0
  },
  filterRow: {
    formName: 'IEStockingQuantitiesKittingFilters',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Sales entity',
          name: 'SalesEntity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'medium',
          required: false
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 150,
      suppressSorting: true,
      type: 'numericColumn',
      sort: 'asc',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.InvoiceNumber && row.data.SalesEntity) {
          return { SalesEntity: row.data.SalesEntity, InvoiceNumber: row.data.InvoiceNumber, FieldValue: row.data.InvoiceNumber };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Line', field: 'LineNo', type: 'numericColumn', suppressSorting: true, minWidth: 100, },
    { headerName: 'Sales entity', field: 'SalesEntity', minWidth: 150, },
    { headerName: 'Entered', field: 'EnteredDate', type: 'numericColumn', suppressSorting: true, minWidth: 120, },
    { headerName: 'Kitting', field: 'KittingQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    {
      headerName: 'Customer',
      field: 'CustomerIdDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.CustomerId && row.data.CustomerIdDisplay) {
          return {
            CustomerId: row.data.CustomerId,
            CustomerAccountNumber: row.data.CustomerIdDisplay,
            FieldValue: row.data.CustomerIdDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', suppressSorting: true, minWidth: 150, },
    { headerName: 'Customer name', field: 'CustomerIdLabel', suppressSorting: true, minWidth: 200, },
    { headerName: 'Warehouse', field: 'Warehouse', suppressSorting: true, minWidth: 120, },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', suppressSorting: true, minWidth: 200, }
  ]
});

export default (state: IKittingState = initialState, action: IDataAction): IKittingState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.selected,
  gridOptions: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.gridOptions,
  columns: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.Columns,
  Actions: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.Actions,
  filterRow: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.filterRow,
  selectedFilters: (state: { inventoryEnquiry: { stocking: { kitting: IKittingState } } }) => state.inventoryEnquiry.stocking.kitting.data.selectedFilters
});
