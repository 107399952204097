/* APIRoutes
* Use create/retrieve/update/delete keywords for api prop name and follow the module screen hierarchy.
* if same api is used for multiple operations, define it once with the prop name in short form
*  (e.g cu, cd,cud, crud)
*/

export const APIRoutes = {
    pickSlips: {
        process: 'PickSlipTransaction/Despatch/:DespatchId/Process',
        calculateSurcharge: 'PickSlipTransaction/Despatch/:DespatchId/CalculateSurcharge',
        calculateFreight: 'PickSlipTransaction/Despatch/:DespatchId/CalculateFreightCharge',
        confirmation: 'PickSlipTransaction/Despatch/:DespatchId/ConfirmationDetails',
        removeChargeLines: 'OpenInvoice/SalesInvoice/:InvoiceNumber/Entity/:SalesEntity/RemoveInvoiceChargeLines',
        lines: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/Lines',
            calculate: 'PickSlipTransaction/Despatch/:DespatchId/Line/:LineNumber/CalculateGridLineDetails',
            update: 'PickSlipTransaction/Despatch/:DespatchId/Line/:LineNumber/UpdateGridLineDetails',
            delete: 'PickSlipTransaction/Despatch/:DespatchId/Line/:LineNumber/Delete',
            initializeLostSale: 'PickSlipTransaction/Despatch/:DespatchId/Line/:LineNumber/InitialiseDespatchLineLostsale',
            detail: {
                retrieve: 'PickSlipTransaction/Despatch/:DespatchId/Line/:LineNumber',
                calculate: 'PickSlipTransaction/Despatch/:DespatchId/Line/:DespatchLineId/CalculateLineDetails',
                update: 'PickSlipTransaction/Despatch/:DespatchId/Line/:DespatchLineId/UpdateLineDetails',
                cancel: 'PickSlipTransaction/Despatch/Line/:DespatchLineId/ClearWIPDespatchLine'
            },
        },
        detail: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/Details',
            calculate: 'PickSlipTransaction/Despatch/:DespatchId/CalculateDetails',
            update: 'PickSlipTransaction/Despatch/:DespatchId/UpdateDetails'
        },
        remove: 'PickSlipTransaction/Despatch/:DespatchId/RemoveDespatch',
        initializeLostSale: 'PickSlipTransaction/Despatch/:DespatchId/InitialiseDespatchLostsale',
        shipping: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/ShippingDetails',
            calculate: 'PickSlipTransaction/Despatch/:DespatchId/CalculateShippingDetails',
            update: 'PickSlipTransaction/Despatch/:DespatchId/UpdateShippingDetails'
        },
        contact: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/ContactDetails'
        },
        summary: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/Summary'

        },
        print: {
            retrieve: 'PickSlipTransaction/Despatch/:DespatchId/GetDocumentOutputDefaults'
        },
        charges: {
            calculate: 'PickSlipTransaction/Despatch/:DespatchId/CalculateChargeLine',
            update: 'PickSlipTransaction/Despatch/:DespatchId/CreateChargeLine',
        },
        components: {
            load: 'WIPKitComponents/Document/:DocumentId/Despatch/LoadWIPComponents',
            retrieve: 'WIPKitComponents/Document/:DocumentId/Components',
            addComponent: 'WIPKitComponents/Document/:DocumentId/AddComponent',
            calculateComponent: 'WIPKitComponents/Document/:DocumentId/CalculateComponent',
            deleteLine: 'WIPKitComponents/Component/:ComponentId/Delete',
            summary: 'WIPKitComponents/Document/:DocumentId/KitSummary',
            Line: {
                retrieve: 'WIPKitComponents/Component/:ComponentId/GetComponentLineDetails',
                calculateDetail: 'WIPKitComponents/Component/:ComponentId/CalculateComponentGridLineDetail',
                updateDetail: 'WIPKitComponents/Component/:ComponentId/UpdateComponentGridLineDetail',
                calculate: 'WIPKitComponents/Component/:ComponentId/CalculateComponentLineDetail',
                update: 'WIPKitComponents/Component/:ComponentId/UpdateComponentLineDetail'
            }
        },
        diary: {
            lines: {
                retrieve: 'Diary/GetDiaries/debinvdesp/Key/:DespatchId',
                update: 'Diary/:DiaryId',
                delete: 'Diary/:DiaryId',
            },
            detail: {
                retrieve: 'Diary/:DiaryId',
                create: 'Diary/debinvdesp/Key/:DespatchId'
            }
        },
        notepad: {
            retrieve: 'Notepad/Table/debinvdesp/Key/:DespatchId',
            update: 'Notepad/Table/debinvdesp/Key/:DespatchId',
        }
    }
};
