import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../../utils';

export const fetch = async (query: { ProductId: number; Page: number; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { ProductId, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchGet(
    `/InventoryEnquiry/Product/${ProductId}/Association/BillOfMaterials`,
    {
      BatchPage: Page,
      BatchSize
    },
    respType)
    .then(
      (result: {
        AssociationsBOMs: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.AssociationsBOMs) {

            return {
              records: result.AssociationsBOMs.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
