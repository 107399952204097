import React from 'react';
import { useBOCContextSelector, useBOCDispatch } from './BackorderCommitment.context';
import { RouteComponentProps } from 'react-router';

export const useTabLocations = (url: string, location: RouteComponentProps['location']) => {

    const contextDispatch = useBOCDispatch();
    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);
    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);
    const PurchaseOrderLineId = useBOCContextSelector<'PurchaseOrderLineId'>((state) => state.PurchaseOrderLineId);
    const CommitmentLineNumber = useBOCContextSelector<'CommitmentLineNumber'>((state) => state.CommitmentLineNumber);

    const tabLocations = React.useMemo(
        () => {
            const backOrderCommitment = { pathname: url, search: `?SalesOrderId=${SalesOrderId}` };
            const purchaseOrder = {
                pathname: `${url.endsWith('/') ? url : `${url}/`}purchase-order`,
                search: `?SalesOrderId=${SalesOrderId}&PurchaseOrderId=${PurchaseOrderId}`
            };

            return ({
                BackorderLines: backOrderCommitment,
                BackorderCommitment: backOrderCommitment,
                SalesProcessing: '/sales-processing',
                LineCommitment: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}line-commitment`,
                    search: `?SalesOrderId=${SalesOrderId}&LineNumber=${CommitmentLineNumber}`
                },
                PurchaseOrderLines: purchaseOrder,
                PurchaseOrder: purchaseOrder,
                OrderDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}purchase-order/order-details`,
                    search: `?SalesOrderId=${SalesOrderId}&PurchaseOrderId=${PurchaseOrderId}`
                },
                Deliveries: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}purchase-order/delivery`,
                    search: `?SalesOrderId=${SalesOrderId}&PurchaseOrderId=${PurchaseOrderId}`
                },
                PurchaseOrderLineDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}purchase-order/line-details`,
                    search: `?SalesOrderId=${SalesOrderId}&PurchaseOrderId=${PurchaseOrderId}&PurchaseOrderLineId=${PurchaseOrderLineId}`
                },
            });
        },
        [url, SalesOrderId, PurchaseOrderId, PurchaseOrderLineId, CommitmentLineNumber]
    );

    React.useEffect(
        () => {
            const params = (new URLSearchParams(location.search));
            const salesOrderId = params.get('SalesOrderId') || '';
            const purchaseOrderId = params.get('PurchaseOrderId') || '';
            const purchaseOrderLineId = params.get('PurchaseOrderLineId') || '';
            const commitmentLineNumber = params.get('LineNumber') || '';

            contextDispatch({
                setSalesOrderId: salesOrderId,
                setPurchaseOrderId: Number(purchaseOrderId),
                setPurchaseOrderLineId: Number(purchaseOrderLineId),
                setCommitmentLineNumber: Number(commitmentLineNumber)
            });
        },
        [contextDispatch]
    );

    return {
        tabLocations
    };
};
