import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as quantitiesSelectors, actions as quantitiesActions } from 'ducks/inventoryEnquiry/stocking/quantities';
import { fetch } from 'api/inventoryEnquiry/stocking/quantities';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup'

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantities',
  columnDefs: quantitiesSelectors.columns(state),
  rowData: quantitiesSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: quantitiesSelectors.gridOptions(state),
  isLoading: quantitiesSelectors.isLoading(state),
  detailCellRenderer: quantitiesSelectors.detailCellRenderer(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) && {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  keyFields: [{ valueField: 'Warehouse', routeField: 'Warehouse' }],
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: quantitiesActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
