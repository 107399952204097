import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  ReportingAreaGrid: {
    'height': '100%',
    '& .dx-treelist-rowsview .dx-treelist-empty-space': {
      marginRight: '10px'
    },
    '& .dx-checkbox-container .dx-checkbox-icon ': {
      width: '20px',
      height: '18px',
      border: '2px solid #333333',
      marginBottom: '1px',
    },
    '& .dx-checkbox-checked .dx-checkbox-icon': {
      color: '#333333',
      border: '2px solid #333333'
    },
    '& .dx-checkbox-indeterminate .dx-checkbox-icon::before': {
      backgroundColor: '#333333',
    },
    '& .dx-treelist-rowsview .dx-line': {
      width: '14px',
      height: '14px'
    },
    '& .dx-treelist-rowsview .dx-line span': {
      fontSize: '14px',
      lineHeight: '14px'
    },
    '& .dx-treelist-rowsview .dx-treelist-collapsed span::before': {
      width: '14px',
      marginTop: -'4px',
      marginLeft: -'6px',
      fontSize: '20px',
      top: '80%',
      left: '-1px'
    },
    '& .dx-treelist-rowsview .dx-treelist-expanded span::before': {
      width: '14px',
      marginTop: -'4px',
      marginLeft: -'6px',
      fontSize: '20px',
      top: '92%',
      left: '-4px'
    },
    '& .dx-treelist-rowsview .dx-line:before': {
      visibility: 'visible',
      content: "'' !important",
      position: 'absolute',
      left: '0px',
      right: '-4px',
      top: '-7px',
      bottom: '-11px',
      backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAABmCAYAAAAgVArEAAAAgElEQVRoQ+3aMQrAIAxA0Sgey+PnXulU6PBpoW22/yaF8AUnB0dVRZcZEZGZLSfMp4EvjCPjyDgyjowj48g4ao2vc/H322XvPdZ1czf8Ruu1GEfGkXFkHBlHxpFxZBwZR8aRcWQcGUfGkXFkHBlHxpFxZBwZR8aRcWQcjfY/RV0OBM0OyQHa1DgAAAAASUVORK5CYII=')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0px -30px'
    },
    '& .dx-treelist-rowsview .dx-line-middle:before': {
      backgroundPositionY: '-9px',
    },
    '& .dx-treelist-rowsview .dx-line-last:before': {
      bottom: '4px',
      top: '-7px'
    },
    '& .dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space': {
      color: 'black',
    },
    '& .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td': {
      backgroundColor: '#FFF !important',
    },
    '& .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td, .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td': {
      borderBottom: 'unset !important',
      borderTop: 'unset !important'
    },
    '& .dx-treelist-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-treelist-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-treelist-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused)': {
      backgroundColor: '#e6e6e6 !important',
      color: '#333'
    },
    '& .dx-treelist-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td, .dx-treelist-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td': {
      borderTop: '1px solid #e6e6e6',
      borderBottom: '1px solid #e6e6e6'
    },
    '& .dx-treelist-rowsview.dx-treelist-nowrap .dx-treelist-table .dx-treelist-cell-expandable .dx-treelist-text-content': {
      paddingLeft: 5
    },
    '@media screen and (max-width: 1100px)': {
      minHeight: 120,
    }
  }
});
