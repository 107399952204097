import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as programAreaActions, selectors as programAreaSelectors } from 'ducks/programArea';
import { actions as formActions } from 'ducks/form';

import DashboardView from './DashboardView'

const mapStateToProps = state => ({
  programAreas: programAreaSelectors.programAreas(state),
  dashboardWidgets: programAreaSelectors.dashboardWidgets(state),
  programAreasLoaded: programAreaSelectors.isFetched(state),
  isFetchWidgetsLoading: programAreaSelectors.isFetchWidgetsLoading(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchDashboardWidgets: programAreaActions.fetchDashboardWidgets,
  changeOperationMode: operationActions.changeOperationMode,
  changeOperationMode: operationActions.changeOperationMode,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);