import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as productMovementActions, selectors as productMovementSelectors } from 'ducks/pme/Movements';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

interface ICustomerItem {
  'ProductIdDisplay': string;
  'ProductGroup': string;
  'ProductIdLabel': string;
  'ProductId': number;
  [name: string]: any;
}

const CustomerSearchLookUpDrawer = buildSearchLookUpDrawer<ICustomerItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'searchPlaceholder' | 'operationMode' | 'records' | 'selectedRecord' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'
  | 'searchTextProp' | 'customFilterRow' | 'appliedFilters'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'ProductMovements',
    searchPlaceholder: 'Search for Movements...',
    operationMode: operationSelectors.operationMode(state),
    records: productMovementSelectors.all(state) || [],
    selectedRecord: productMovementSelectors.selected(state),
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: productMovementSelectors.isLoading(state),
    nextPage: productMovementSelectors.nextPage(state),
    prevPage: productMovementSelectors.prevPage(state),
    pageSize: productMovementSelectors.pageSize(state),
    totalPages: productMovementSelectors.totalPages(state),
    loadingPrevPage: productMovementSelectors.loadingPrevPage(state),
    loadingNextPage: productMovementSelectors.loadingNextPage(state),
    searchParams: {},
    customFilterRow: productMovementSelectors.filterRow(state),
    appliedFilters: productMovementSelectors.selectedFilters(state),
    keyField: 'ProductMovementId',
    fieldsToDisplay: ['Date', 'MovementType', 'MovementNumber', 'Quantity'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('ProductMovementId', selectedRecord.ProductMovementId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const ProductMovementId = matchParams.get('ProductMovementId');
      if (ProductMovementId) {
        return {
          id: { ProductMovementId },
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: productMovementActions.search,
  searchById: productMovementActions.searchById,
  changeSelectedRecord: productMovementActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: productMovementActions.fetchNextPage,
  fetchPrevPage: productMovementActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchLookUpDrawer);
