import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryPanel from '../SummaryPanel/index';
import { IAssociationsProps } from '../interfaces';
import { MasterDetailCommentAreaRenderer } from 'components/common/DataGridDevEx/CustomRenderers/CommentAreaRenderer';

class Associations extends React.PureComponent<IAssociationsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = 'inventory-enquiry';
    if (path) {
      switch (path) {
        case `/${context}/associations/alternates`:
          onInitialLoad('AssociationsAlternates');
          break;
        case `/${context}/associations/suppressions`:
          onInitialLoad('AssociationsSupersessions');
          break;
        case `/${context}/associations/bill-of-material`:
          onInitialLoad('AssociationsBillOfMaterials');
          break;
        default:
          onInitialLoad('AssociationsAlternates');
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, selectedTab } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <SummaryPanel
          variant={'ProductDetails'}
        />
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={null}
          fieldExtensions={{
            IEAssociationsAlternates: {
              masterDetailRenderer: MasterDetailCommentAreaRenderer
            },
            IEAssociationsBOM: {
              masterDetailRenderer: MasterDetailCommentAreaRenderer
            }
          }}
        />
      </React.Fragment>
    );
  }
}

export default Associations;
