import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import actionsList from 'utils/data/db/actions/EnquiryActionList';
import { PaperElevation, CircularProgressSize, SummaryBackPaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps, ISummaryPanelState } from '../interfaces';

const style = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 172px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '10px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '10px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '10px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '10px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '-5px'
  },
  iconWarning: {
    'height': '24px',
    'width': '24px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  media: {
    height: '130px',

  }
});

class SummaryPanel extends React.PureComponent<ISummaryPanelProps, ISummaryPanelState> {
  constructor(props: ISummaryPanelProps) {
    super(props);
    this.state = {
      open: false,
      dialogOpen: false
    };
  }

  toggleMenu = (): void => {
    event.stopPropagation();
    this.setState(() => ({
      open: !this.state.open
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  handleDialogOpen = (): void => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleDialogClose = (): void => {
    this.setState({ dialogOpen: false });
  };

  appendParam = (actionList: any, movementSummary: any): any => {
    if (movementSummary && movementSummary.ProductId) {
      return actionList.map((item) => {
        item.actionParam = { ProductId: movementSummary.ProductId, SearchText: movementSummary.ProductIdDisplay };

        return item;
      });
    }

    return actionList;
  }

  renderPreLoader = (): React.ReactNode => {
    const { classes, } = this.props;

    return (<Paper
      elevation={PaperElevation}
      className={classes.subPanel}
      square={true}>
      <p className={classes.subPanelTitle}>Product details</p>
      <CircularProgress
        size={CircularProgressSize}
        className={classes.preLoader}
        color={'secondary'}
        variant={'indeterminate'} />
    </Paper>);
  }

  renderProduct = (): React.ReactNode => {
    const { classes, movementSummary } = this.props;

    return (
      <Paper elevation={PaperElevation} square={true} className={classes.subPanel}>
        <p className={classes.subPanelTitle}>Product details</p>
        <Table className={classes.summaryTable}>
          <TableBody>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    <span>{movementSummary && movementSummary.ProductIdDisplay}</span>
                    <br />
                    <span>{movementSummary && movementSummary.ProductIdLabel}</span>
                  </div>
                  <div className={classes.splitCellAction} >
                    {
                      <InfoButton actionList={this.appendParam(actionsList, movementSummary)}
                        disabled={!(movementSummary && movementSummary.ProductIdLabel)}
                      />
                    }
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT GROUP</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    <span >{movementSummary && movementSummary.ProductGroup}</span>
                    <br />
                    <span >{movementSummary && movementSummary.ProductGroupDescription}</span>
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT GROUP</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    {movementSummary && movementSummary.ProductGroup}
                  </div>
                  <div className={classes.splitCellAction} >
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderMovement = (): React.ReactNode => {
    const { classes, movementSummary } = this.props;

    return (
      <Paper elevation={PaperElevation} square={true} className={classes.subPanel}>
        <p className={classes.subPanelTitle}>Movement details</p>
        <Table className={classes.summaryTable}>
          <TableBody>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>MOVEMENT TYPE</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    {movementSummary && movementSummary.MovementType}
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>TRANSACTION</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    {movementSummary && movementSummary.TransactionId}
                  </div>
                  <div className={classes.splitCellAction} >
                    {
                      <InfoButton actionList={this.appendParam(actionsList, movementSummary)}
                        disabled={!(movementSummary && movementSummary.TransactionId)}
                      />
                    }
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>DATE</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    {movementSummary && movementSummary.Date}
                  </div>
                  <div className={classes.splitCellAction} >
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>FINANCIAL PERIOD</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    {movementSummary && movementSummary.FinancialPeriod}
                  </div>
                  <div className={classes.splitCellAction} >
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper >
    );
  }

  renderCosting = (): React.ReactNode => {
    const { classes, movementSummary } = this.props;

    return (
      <Paper elevation={PaperElevation} square={true} className={classes.subPanel}>
        <Table className={classes.summaryTable}>
          <TableBody>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>COST EACH</TableCell>
              <TableCell
                numeric={true}
                className={classes.numericCell}>
                {movementSummary && movementSummary.CostEach}
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>SELL PRICE EACH EACH</TableCell>
              <TableCell
                numeric={true}
                className={classes.numericCell}>
                {movementSummary && movementSummary.SellPriceEach}
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>TAX EACH</TableCell>
              <TableCell
                numeric={true}
                className={classes.numericCell}>
                {movementSummary && movementSummary.TAXEach}
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>QUANTITY</TableCell>
              <TableCell
                numeric={true}
                className={classes.numericCell}>
                {movementSummary && movementSummary.QUANTITY}
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>QUANTITY BALANCE</TableCell>
              <TableCell
                numeric={true}
                className={classes.totalCell}>
                {movementSummary && movementSummary.QuantityBalance}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper >
    );
  }

  render(): React.ReactNode {
    const { classes, loadingSummary } = this.props;

    return (
      <div>
        <Paper elevation={SummaryBackPaperElevation} className={classes.mainPanel} square={true} >
          <Scrollbars>
            {(loadingSummary
              && this.renderPreLoader())
              ||
              (
                this.renderProduct()
              )
            }
            {(loadingSummary
              && this.renderPreLoader())
              ||
              (
                this.renderMovement()
              )
            }
            {(loadingSummary
              && this.renderPreLoader())
              ||
              (
                this.renderCosting()
              )
            }
          </Scrollbars>
        </Paper>
      </div >
    );
  }
}

export default withStyles(style, {index: 1})(SummaryPanel);
