import { connect } from 'react-redux';
import InTransit from './InTransit';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state)
});

export default connect(mapStateToProps, {})(InTransit);
