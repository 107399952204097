export enum Operation {
  EDIT = 'EDIT',
  BROWSE = 'BROWSE',
  NEW = 'NEW',
  DELETE = 'DELETE',
  SAVE = 'SAVE',
  CANCEL = 'CANCEL',
  BACK = 'BACK',
  WAREHOUSE = 'WAREHOUSE',
  LOT = 'LOT',
  BOM = 'BOM',
  SERIAL = 'SERIAL',
  DELIVERY = 'DELIVERY',
  RESERVE = 'RESERVE',
  PARK = 'PARK',
  PRINT = 'PRINT',
  QUOTE = 'QUOTE',
  PROCESS = 'PROCESS',
  CALCULATOR = 'CALCULATOR',
  ALLOCATE = 'ALLOCATE',
  INVOICES = 'INVOICES',
  ORDERS = 'ORDERS',
  CRITERIA = 'CRITERIA',
  ADD_CHARGE = 'ADD_CHARGE',
  PICKING = 'PICKING',
  CURRENT_PERIOD = 'CURRENT_PERIOD',
  PREVIOUS_PERIOD = 'PREVIOUS_PERIOD',
  NEXT_PERIOD = 'NEXT_PERIOD',
  REVIEW = 'REVIEW'
}

export const OPERATIONS = Object.freeze({
  EDIT: 'EDIT',
  BROWSE: 'BROWSE',
  NEW: 'NEW',
  DELETE: 'DELETE',
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  BACK: 'BACK',
  WAREHOUSE: 'WAREHOUSE',
  LOT: 'LOT',
  BOM: 'BOM',
  SERIAL: 'SERIAL',
  DELIVERY: 'DELIVERY',
  RESERVE: 'RESERVE',
  PARK: 'PARK',
  PRINT: 'PRINT',
  QUOTE: 'QUOTE',
  PROCESS: 'PROCESS',
  CALCULATOR: 'CALCULATOR',
  ALLOCATE: 'ALLOCATE',
  INVOICES: 'INVOICES',
  ORDERS: 'ORDERS',
  CRITERIA: 'CRITERIA',
  ADD_CHARGE: 'ADD_CHARGE',
  PICKING: 'PICKING',
  CURRENT_PERIOD: 'CURRENT_PERIOD',
  PREVIOUS_PERIOD: 'PREVIOUS_PERIOD',
  NEXT_PERIOD: 'NEXT_PERIOD',
  REVIEW: 'REVIEW'
});
