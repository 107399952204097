import * as React from 'react';
import { Paper, CircularProgress, Button } from '@material-ui/core';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { ILoginFields } from '../Login/Login';
import * as styles from './LoginForm.scss';
const loginBannerAsset  = require('../../assets/branding-login.png');

interface ILoginFormFields {
  isLoading: boolean;
  error: any;
  onSubmit(fields: ILoginFields): void;
}
interface ILoginState {
  fields: ILoginFields;
}
const CircularProgressSize = 20;
class LoginForm extends React.Component<ILoginFormFields, ILoginState> {

  setUserRef: (element: any) => void;
  setPassRef: (element: any) => void;
  private userField: HTMLInputElement;
  private passField: HTMLInputElement;

  constructor(props: Readonly<ILoginFormFields>) {
    super(props);
    this.state = {
      fields: {
        staffUserName: '',
        staffPassword: ''
      }
    };
    this.userField = null;
    this.setUserRef = (element) => {
      this.userField = element;
    };

    this.passField = null;
    this.setPassRef = (element) => {
      this.passField = element;
    };
  }

  componentDidMount(): void {
    this.usernameFocus();
  }

  handleUserNameChange = (event: any) => {
    this.setState({
      fields: { ...this.state.fields, staffUserName: event.target.value }
    });
  }

  handlePasswordChange = (event: any) => {
    this.setState({
      fields: { ...this.state.fields, staffPassword: event.target.value }
    });
  }

  handleLoginSubmit = (event: any) => {
    event.preventDefault();
    const { staffUserName, staffPassword } = this.state.fields;
    let invalid = false;
    // First we will check both the fields and make them focused
    if (staffUserName === '' && this.usernameFocus()) {
      invalid = true;
    }
    if (staffPassword === '' && this.passwordFocus()) {
      // Calling username focus again as that should be a first focus
      if (staffUserName === '') this.usernameFocus();
      invalid = true;
    }

    if (!invalid) this.callOnSubmit();
  }

  usernameFocus = () => {
    this.userField.focus();

    return true;
  }

  passwordFocus = () => {
    this.passField.focus();

    return true;
  }

  callOnSubmit = () => {
    if (this.props.onSubmit) this.props.onSubmit(this.state.fields);
  }

  render(): React.ReactNode {
    const { isLoading, error } = this.props;
    const buttonLabel = (
      <span className={styles.buttonLabel}>
        Login
        {isLoading && (
          <CircularProgress size={CircularProgressSize} className={styles.buttonLabelProgress} />
        )}
      </span>
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.outer}>
          <Paper className={styles.inner}>
            {
               <img className={styles.loginImage} src={loginBannerAsset} />}
            <div className={styles.formDiv}>
              <form onSubmit={this.handleLoginSubmit}>
                <h3 className={styles.loginHeading}>Login</h3>
                <TextField
                  style={{ width: 240 }}
                  name='staffUserName'
                  label='Username'
                  value={this.state.fields.staffUserName}
                  required={true}
                  onChange={this.handleUserNameChange}
                  placeholder={'Username'}
                  fieldRef={this.setUserRef}
                />
                <TextField
                  style={{ width: 240 }}
                  name='staffPassword'
                  label='Password'
                  type='password'
                  value={this.state.fields.staffPassword}
                  required={true}
                  onChange={this.handlePasswordChange}
                  placeholder={'Password'}
                  fieldRef={this.setPassRef}

                />
                {
                  error && <div className={styles.loginError}>
                    {error}
                  </div>
                }
                <div className={styles.actionBanner}>
                  <Button
                    variant={'raised'}
                    className={styles.loginButton}
                    type={'submit'}
                  >
                    {buttonLabel}
                  </Button>
                </div>
              </form>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default LoginForm;
