const ReservationDetails = {
  'id': 'ReservationDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product description',
            'name': 'ProductDescription',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reserved quantity',
            'name': 'ReservedQuantity',
            'placeholder': '',
            'helpText': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Entered',
            'name': 'EnteredDate',
            'size': 'small'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Expiry',
            'name': 'ExpiryDate',
            'size': 'small'
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'User ID',
            'name': 'UserId',
            'helpText': '',
            'apiURL': '',
            'size': 'small'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reference',
            'name': 'Reference',
            'size': 'medium'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_AREA',
          'visible': true,
          'props': {
            'label': 'Reservation comment',
            'name': 'Comment',
            'fullWidth': true,
            'size': 'small',
            'style': {
              'height': '350px'
            }
          }
        }
      ]
    }
  ]
}
export default ReservationDetails;