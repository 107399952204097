import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';

import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import JobTemplateSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/JobTemplates';
import JobTemplateDetails from './JobTemplateDetails';
import Activities from './Activities';

import { isNull } from '../../utils/utils';

import BreadcrumbBar from './../common/BreadcrumbBar';
import * as styles from './JobTemplateMaintenance.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';

import { IJobTemplateMaintenanceViewProperties } from './interfaces';
import { MODULE_TREE, TABS_DATA } from './constants';
import NavigationTabs from 'components/JobTemplateMaintenance/NavigationTabs';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const inlineStyles = createStyles({
    JobTemplateMaintenanceSection: {
        width: 'calc(100% - 20px)',
        marginLeft: '10px',
    }
});

class JobTemplateMaintenanceView extends React.Component<IJobTemplateMaintenanceViewProperties> {

    componentDidMount(): void {
        this.props.changeOperationMode(Operation.BROWSE);
    }

    componentDidUpdate(prevProps: Readonly<IJobTemplateMaintenanceViewProperties>): void {
        const { operationMode, selectedTab, changeSelectedForm, selectedForm, selectedJobTemplate } = this.props;
        if (prevProps.selectedTab !== selectedTab || (!selectedJobTemplate && !this.props.searchingForActivities && this.props.searchingForActivities !== prevProps.searchingForActivities)) {
            this.handleModuleChange(selectedTab);
        }
        if (operationMode === Operation.BACK) {
            this.backAction(prevProps);
        }
        if (!selectedForm) {
            changeSelectedForm('job-template-maintenance', selectedTab);
        }

    }

    componentWillUnmount(): void {
        this.props.resetSearchLookupDrawer();
        this.props.destoryForm();
    }

    handleModuleChange = (selectedTab: string) => {
        const { operationMode, TemplateCode } = this.props;
        if ((selectedTab !== 'JobTemplateDetails') && (isNull(TemplateCode))) {
            showSnackBar({ variant: 'error', message: 'No activity selected.' });
            this.props.changeSelectedTab('JobTemplateDetails');

            return;
        }
        if (operationMode === Operation.NEW || operationMode === Operation.EDIT) {
            showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

            return;
        }
        const targetTab = selectedTab;
        this.props.changeSelectedTab(targetTab);
        const context = 'job-template-maintenance';
        this.props.getFormSchema({
            context,
            formId: targetTab
        });
        const notJobTemplate = targetTab === 'Activities';
        this.props.toggleBrowseLookUp(notJobTemplate);

        this.props.changeSelectedForm(context, targetTab);
        this.props.changeOperationMode(Operation.BROWSE);
        const { pathname, search } = this.getRoute()[targetTab || 'JobTemplateDetails'];
        this.props.history.push({ pathname, search });

        if (this.props.changeScope) {
            this.props.changeScope('job-template-maintenance');
        }
    }

    getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
        const params = (new URLSearchParams(this.props.location.search));
        const context = 'job-template-maintenance';
        const TemplateCode = params.get('TemplateCode') || '';

        const routes = ({
            JobTemplateMaintenance: { pathname: `/${context}`, search: `?TemplateCode=${TemplateCode}`, component: JobTemplateDetails },
            JobTemplateDetails: { pathname: `/${context}/job-template-details`, search: `?TemplateCode=${TemplateCode}`, component: JobTemplateDetails },
            Activities: { pathname: `/${context}/job-template-details/activities`, search: `?TemplateCode=${TemplateCode}&ActivityCode=${params.get('ActivityCode') || ''}`, component: Activities },
        });

        return routes;
    }

    handleToggleMenuOptionOpen = () => {
        const { toggleMenuOption, isMenuOptionOpen } = this.props;
        toggleMenuOption(!isMenuOptionOpen);
    }

    backAction = (prevProps: Readonly<IJobTemplateMaintenanceViewProperties>) => {
        const { dirty, selectedTab, changeConfirmationDialog, changeOperationMode, history, resetForm } = this.props;
        if (dirty) {
            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    changeOperationMode(prevProps.operationMode);
                },
                onOk: () => {
                    if (selectedTab === 'JobTemplateDetails') {
                        history.push('/dashboard');
                    }
                    this.handleModuleChange('JobTemplateDetails');
                    changeOperationMode(Operation.BROWSE);
                    resetForm();
                }
            });
        } else {
            if (selectedTab) {
                const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
                if (!targetModule || !targetModule.parent || targetModule.parent === '') {
                    if (selectedTab === 'JobTemplateDetails') {
                        history.push('/dashboard');
                    } else {
                        this.handleModuleChange('JobTemplateDetails');
                    }
                } else {
                    this.handleModuleChange(targetModule.parent);
                }
            }
            changeOperationMode(Operation.BROWSE);
        }
    }

    fetchJobTemplateDetailsRecord = () => {
        const { TemplateCode } = this.props;
        if (TemplateCode) {
            this.props.fetchJobTemplateData(TemplateCode);
        }
    }

    getRecordChangeCallBacks = () => {
        const { selectedTab } = this.props;
        const callBacks = [];
        switch (selectedTab) {
            case 'Activities':
            case 'JobTemplateDetails':
                callBacks.push(this.fetchJobTemplateDetailsRecord);
                break;
            default:
        }

        return callBacks;
    }
    changeScope = () => {
        const { changeScope, selectedTab } = this.props;
        changeScope(selectedTab);
    }

    getOptionMenuData = (selectedTab: string) => {
        switch (selectedTab) {
            default:
                return options.JobTemplateMaintenance;
        }
    }

    handleOptionClick = (selectedTab: string): void => {
        const { changeSelectedTab, operationMode } = this.props;
        if (operationMode === Operation.NEW || operationMode === Operation.EDIT) {
            showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

            return;
        }
        changeSelectedTab(selectedTab);
    }

    render(): React.ReactNode {
        const { selectedTab, isMenuOptionOpen, operationMode, isCustomerLookUpOpen, scope } = this.props;
        const routes = Object.values(this.getRoute());

        return (
            <div className={styles.JobTemplateMaintenanceOuter}>
                <div className={styles.JobTemplateMaintenanceContainer}>

                    <JobTemplateSearchLookUpDrawer
                        enableToggle={true}
                        hideFilterButton={true}
                        recordChangeCallBacks={this.getRecordChangeCallBacks()}
                    />
                    <div
                        style={inlineStyles.JobTemplateMaintenanceSection}
                        className={styles.JobTemplateMaintenanceSection}
                    >
                        <BreadcrumbBar
                            onClick={this.handleToggleMenuOptionOpen}
                            mainModule={'Job Template Maintenance'}
                            activeModule={selectedTab}
                            moduleTree={MODULE_TREE}
                        />
                        <NavigationTabs
                            selectedTab={selectedTab}
                            onTabChange={this.handleModuleChange}
                            operationMode={operationMode}
                            tabsData={TABS_DATA}
                            isCustomerLookUpOpen={isCustomerLookUpOpen}
                        />

                        {routes.map((route) => (
                            <Route
                                exact
                                key={route.pathname}
                                path={route.pathname}
                                render={() => <route.component
                                    onInitialLoad={this.handleModuleChange}
                                    path={route.pathname}
                                    formName={selectedTab}
                                />}
                            />
                        ))}

                    </div>
                </div>
                <OptionsMenu
                    open={isMenuOptionOpen}
                    options={this.getOptionMenuData(selectedTab)}
                    onOptionClick={this.handleOptionClick}
                    defaultValue={selectedTab}
                />
                <ActionBar scope={scope} />
            </div>
        );
    }
}

export default withRouter(JobTemplateMaintenanceView);
