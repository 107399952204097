import FormView from 'components/FormView';
import React from 'react';
import LineDetailsForm from './LineDetails.form';
import ILineDetailsProperties from './LineDetails.properties';
import {
    IBinLotFacade,
    ICalculateWorksalelineDetailFacade, ILoadComponentsFacade, IUpdateWorksalelineDetailFacade,
    IWIPComponentFacade,
    IWorksalelineDetailFacade
} from 'api/swaggerTypes';
import SummaryTable from 'components/common/SummaryTable';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import CancelIcon from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import { CheckCircle, ViewList as ComponentsIcon, WarehouseBlack } from '@markinson/uicomponents-v2/SvgIcons';
import PartialSerialDialog from 'components/common/PartialSerialDialog';
import WarehouseSelectionDialog from '../../common/WarehouseSelectionDialog';
import inlineStyles from './LineDetails.styles';
import { IObjectifiedCalculateWorksaleLineResponse, IObjectifiedIUpdateWorksalelineDetailResponse, IObjectifiedLoadedComponentDetailResponse } from 'api/Worksale/worksale';
import { Inline, isScreenRequest } from 'api/utils';
import BOMDialog from '../../common/BOMDialog';
import LibraryBooksIcon from '@markinson/uicomponents-v2/SvgIcons/LibraryBooks';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { Route } from 'react-router';
import KitComponents from '../KitComponents';
import KitComponentDetails from '../KitComponents/KitComponentDetails';
import { IKitComponentDetailsHandle } from '../../common/KitComponents/KitComponentDetails/KitComponentDetails.properties';
import { isNull } from 'utils/utils';
import { useCalculateWorkSaleLineDetails, useUpdateWorkSaleLineDetails, useLoadComponents, useCancelLineDetails, useFetchWorksaleLine } from 'api/Worksale/lineDetails';
import { PriceOverrideAuthRequestedScreen } from '../Worksale.properties';
import { usePriceOverrideAndKitValidations } from '../Worksale.hooks';
import PriceOverrideAuthorityDialog from '../../common/PriceOverrideAuthorityDialog';
import { IPriceOverrideData } from '../../common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { fetchProductSerials } from 'api/product/productSearch';
import SerialDialog from 'components/common/SerialDialog';
import LotDialog from 'components/common/LotDialog';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useSPContextSelector, useSPDispatch } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useAsyncClickHandler } from 'utils/hooks';

const WarehouseIcon = ({ className }) => <WarehouseBlack style={inlineStyles.warehouseIcon} className={className} />;

const LineDetails = (props: ILineDetailsProperties): JSX.Element => {

    const {
        forwardedRef,
        values,
        summaryTableProps,
        formSyncErrors,
        isV2,
        match,
        dirty,
        resetForm,
        changeSelectedTab,
        onSaleLineChanged,
        closeLostsaleModal,
        onShortSuppliedLostsaleRequest,
    } = props;

    const { isLoading: summaryTableIsLoading = false, summaryTableSchema } = summaryTableProps || {};

    const customerId = useSPContextSelector(Selectors.CustomerId);
    const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;

    const contextDispatch = useSPDispatch();
    const params = new URLSearchParams(location.search);
    const worksaleLineId = Number(params.get('WorksaleLineId') ?? 0);

    const updateLineDetailsMutation = useUpdateWorkSaleLineDetails();
    const calculateLineDetailsMutation = useCalculateWorkSaleLineDetails();
    const loadComponentsMutation = useLoadComponents();
    const cancelLineDetailsMutation = useCancelLineDetails();
    const fetchLineDetailsMutation = useFetchWorksaleLine();

    const {
        multipleBomOpen,
        bomList,
        kitStatus,
        selectedBomCode,
        priceOverrideDialogLoading,
        priceOverrideDialogOpen,
        priceOverrideScreenType,
        priceOverrideDetailSummary,
        multipleBomDialogApi,
        setMultipleBomDialogApi,
        validate,
        setKitStatus,
        setBomList,
        setSelectedBomCode,
        setMultipleBomState,
        setPriceOverrideDialogLoading,
        setPriceOverrideDialogOpen
    } = usePriceOverrideAndKitValidations();

    const [isLoading, setIsLoading] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState<IWorksalelineDetailFacade>({});
    const [worksaleLineDetailsSchema, setworksaleLineDetailsSchema] = React.useState<Inline<IWorksalelineDetailFacade>>();
    const [lotDialogOpen, setLotDialogOpen] = React.useState<boolean>(false);
    const [partialSerialDialogOpen, setPartialSerialDialogOpen] = React.useState<boolean>(false);
    const [fullSerialDialogOpen, setFullSerialDialogOpen] = React.useState<boolean>(false);
    const [warehouseSelectionOpen, setWarehouseSelectionOpen] = React.useState(false);
    const [selectedComponentLine, setSelectedComponentLine] = React.useState<IWIPComponentFacade>();
    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const [notifyUnsavedChanges, setNotifyUnsavedChanges] = React.useState<boolean>(true);

    const isLineDirty = React.useMemo(() => dirty || internalDirty, [dirty, internalDirty]);
    const isPathLineDetails = React.useMemo(() => match && match.url && match.url === '/sales-processing/line-details', [match]);

    const isComponentsEnabled = React.useMemo
        (
            () =>
                initialValues.LineType === 'k' && (initialValues.KittingQuantity > 0 || initialValues.BackorderedQuantity > 0)
            ,
            [initialValues]
        );

    const lotSerialTracked = React.useMemo
        (
            () =>
                initialValues.LineSummary && initialValues.LineSummary?.LotSerialTracked && initialValues.LineSummary?.LotSerialTracked?.toLowerCase() !== 'n' ? initialValues.LineSummary?.LotSerialTracked : ''
            ,
            [initialValues]
        );

    const { setActionBar } = React.useContext(ActionBarContext);
    const componentDetailRef = React.useRef<IKitComponentDetailsHandle>();

    const RequiredShipDate = values && values.RequiredShipDate ? values.RequiredShipDate : null;
    const ExpectedShipDate = values && values.ExpectedShipDate ? values.ExpectedShipDate : null;
    const OrderExpiryDate = values && values.OrderExpiryDate ? values.OrderExpiryDate : null;
    const KittingQuantity = values ? values.KittingQuantity : 0;
    const BackorderedQuantity = values ? values.BackorderedQuantity : 0;

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            isDirty(): boolean {
                return isLineDirty;
            },
            async updateWorksaleLineDetail(id: number, line: IUpdateWorksalelineDetailFacade): Promise<void> {
                const response = await updateLineDetailsAndValidate(id, { ...line, KitStatus: kitStatus, BomCode: selectedBomCode });
                if (!response.Forms && response.Status) {
                    handleSaleLineChanged();
                    navigateBack();
                    contextDispatch({
                        setWorksaleLineId: null
                    });
                }
            },
            onCancel(callback?: () => void): void {
                cancelWorksaleDetail(callback);
                contextDispatch({
                    setWorksaleLineId: null
                });
            },
            lotSerial(): void {
                switch (lotSerialTracked) {
                    case 'lf':
                        setLotDialogOpen(true);
                        break;
                    case 'sp':
                        setPartialSerialDialogOpen(true);
                        break;
                    case 'sf':
                        setFullSerialDialogOpen(true);
                        break;
                    default:
                }
            },
            warehouse(): void {
                selectWarehouse();
            },
            componentDetailsLotSerial(): void {
                if (componentDetailRef.current) {
                    componentDetailRef.current.lotSerial();
                }
            },
            componentDetailsWarehouse(): void {
                if (componentDetailRef.current) {
                    componentDetailRef.current.warehouse();
                }
            },
            components(): void {
                handleLoadComponents();
            },
            componentDetailOnOk(): void {
                if (componentDetailRef.current) {
                    componentDetailRef.current.onOk();
                }
            },
            componentDetailOnCancel(callBack?: () => void): void {
                if (componentDetailRef.current) {
                    componentDetailRef.current.onCancel(callBack);
                }
            },
            handleBreadCrumb(): void {
                if (componentDetailRef.current) {
                    componentDetailRef.current.handleBreadCrumb();
                }
            }
        }),
        [isLineDirty, values]
    );

    React.useEffect(
        () => {
            if (worksaleLineId < 0) {
                navigateBack();

                return;
            }

            if (worksaleLineId > 0) {
                fetchWorksaleLineDetails({ id: worksaleLineId }).then((r) => {
                    setNotifyUnsavedChanges(false);

                    return r;
                });
            }
        },
        [worksaleLineId, customerId]
    );

    const handleOnOk = React.useCallback(
        async () => {
            if (validData()) {
                try {
                    const response = await updateLineDetailsAndValidate(worksaleId, { ...values, KitStatus: kitStatus, BomCode: selectedBomCode });
                    if (!response.Forms && response.Status) {
                        handleSaleLineChanged();
                        showSnackBar({ variant: 'success', message: 'Line Details successfully updated.' });
                        navigateBack();
                        setInternalDirty(false);
                    } else if (response.Forms && response.Status) {
                        if (response.Forms.find((form) => form.MessageType === 'Information')) {
                            handleSaleLineChanged();
                            navigateBack();
                        }
                    }
                } catch (err) {
                    console.warn(err);
                }
            }
        },
        [worksaleId, values, kitStatus, selectedBomCode]
    );

    const { onClick: onOk } = useAsyncClickHandler(handleOnOk, !calculateLineDetailsMutation.isLoading);

    React.useEffect(
        () => {
            if (isPathLineDetails) {
                setActionBar({
                    leftIcons: [],
                    centerIcons: [
                        {
                            label: 'Lot/Serial',
                            Icon: LibraryBooksIcon,
                            action: 'LineDetailsLotSerial',
                            disabled: !initialValues.EnableLotSerial

                        },
                        {
                            label: 'Warehouse',
                            Icon: WarehouseIcon,
                            action: 'WarehouseSelection',
                        },
                        {
                            label: 'Components',
                            Icon: ComponentsIcon,
                            action: 'KitComponents',
                            disabled: !isComponentsEnabled || !worksaleLineId
                        }
                    ],
                    rightIcons: [
                        {
                            label: 'Cancel',
                            Icon: CancelIcon,
                            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                            action: 'LineDetailsCancel',
                        },
                        {
                            label: 'Ok',
                            Icon: CheckCircle,
                            action: 'LineDetailsOk',
                            callBack: onOk
                        }
                    ]
                });
            }
        },
        [isComponentsEnabled, worksaleLineId, initialValues, isPathLineDetails, onOk]
    );

    React.useEffect(
        () => {
            if (RequiredShipDate !== null) {
                onDateChange('RequiredShipDate', RequiredShipDate);

                return;
            }
        },
        [RequiredShipDate]
    );

    React.useEffect(
        () => {
            if (ExpectedShipDate !== null) {
                onDateChange('ExpectedShipDate', ExpectedShipDate);

                return;
            }
        },
        [ExpectedShipDate]
    );

    React.useEffect(
        () => {
            if (OrderExpiryDate !== null) {
                onDateChange('OrderExpiryDate', OrderExpiryDate);

                return;
            }
        },
        [OrderExpiryDate]
    );

    async function cancelWorksaleDetail(callback?: () => void): Promise<void> {
        const response = await cancelLineDetailsMutation.mutateAsync({ WorksalelineId: worksaleLineId });
        if (response.Status) {
            resetForm();
            if (callback) {
                callback();
            }
            setInternalDirty(false);
        }
    }

    const handleLoadComponents = React.useCallback(
        () => {
            loadComponents({
                BomCode: selectedBomCode,
                KittingQuantity: KittingQuantity,
                OnOrderQuantity: BackorderedQuantity
            }).then((response) => {

                if (response.Status && !isScreenRequest(response.Forms) && changeSelectedTab) {
                    changeSelectedTab('KitComponents');
                }
            });
        },
        [selectedBomCode, KittingQuantity, BackorderedQuantity]
    );

    async function loadComponents(calculateComponentsPayload: ILoadComponentsFacade): Promise<IObjectifiedLoadedComponentDetailResponse> {

        const response = await loadComponentsMutation.mutateAsync({
            ...calculateComponentsPayload,
            WorksalelineId: worksaleLineId,
            urlQueryParams: {
                BatchPage: 1,
                BatchSize: DEFAULT_PAGE_SIZE,
                Sort: ''
            }
        });

        if (response.Forms) {
            validate(PriceOverrideAuthRequestedScreen.WorksaleLineDetails, {
                Forms: response.Forms,
                BomCode: response.Components?.inlineObject?.BomCode,
                KitDetail: response.Components?.inlineObject?.KitDetail,
                KitStatus: response.Components?.inlineObject?.KitStatus,
                multipleBomDialogApi: 'load'
            });
        }

        return response;
    }

    function handleSaleLineChanged(): void {
        if (onSaleLineChanged) {
            onSaleLineChanged();
        }
    }

    async function fetchWorksaleLineDetails(criteria: { id: number; KeepUnsavedChanges?: any }): Promise<void> {
        try {
            const response = await fetchLineDetailsMutation.mutateAsync({
                WorksalelineId: worksaleLineId,
                urlQueryParams: {
                    NotifyUnsavedChanges: notifyUnsavedChanges,
                    KeepUnsavedChanges: criteria.KeepUnsavedChanges
                }
            });

            const WorksalelineDetailInline = response?.WorksalelineDetail && response?.WorksalelineDetail?.inlineObject;
            const schema = response?.WorksalelineDetail && response?.WorksalelineDetail?.schema;
            if (WorksalelineDetailInline) {
                resetForm();
                setInitialValues(WorksalelineDetailInline);
                setworksaleLineDetailsSchema(schema);
                setKitStatus(WorksalelineDetailInline.KitStatus);
            }

        } catch (err) {
            console.warn(err);
        }
    }

    async function updateLineDetailsAndValidate(WorksaleId: number, line: IUpdateWorksalelineDetailFacade): Promise<IObjectifiedIUpdateWorksalelineDetailResponse> {
        setIsLoading(true);
        try {
            const response = await updateLineDetailsMutation.mutateAsync({ ...line as any, WorksaleId });

            if (response.Status && !response.Forms) {
                setPriceOverrideDialogOpen(false);
                closeLostsaleModal();
            }

            if (isScreenRequest(response.Forms)) {
                validate(PriceOverrideAuthRequestedScreen.WorksaleLineDetails, {
                    Forms: response.Forms,
                    BomCode: response.OverrideDetails?.inlineObject?.BomCode,
                    KitDetail: response.OverrideDetails?.inlineObject?.KitDetail,
                    KitStatus: response.OverrideDetails?.inlineObject?.KitStatus,
                    OverrideDetails: response.OverrideDetails?.inlineObject?.OverrideDetails,
                    multipleBomDialogApi: 'update'
                });
                const lostSaleRequest = response.Forms[0].FormId === 'WorksalelineLostsaleScreen';
                setInitialValues(response.OverrideDetails.inlineObject);
                if (lostSaleRequest) {
                    onShortSuppliedLostsaleRequest(response.OverrideDetails.inlineObject);
                }
            }

            return response;

        } finally {
            setIsLoading(false);
        }
    }

    function validData(): boolean {
        if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
            const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
            if (requiredError) {
                showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
            } else {
                const firstError = Object.keys(formSyncErrors)[0];
                showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
            }

            return false;
        }

        return true;
    }

    function navigateBack(): void {
        if (changeSelectedTab) {
            changeSelectedTab('Lines');
        }
    }

    async function formFieldOnBlur(e: React.FocusEvent<HTMLInputElement>): Promise<void> {
        const targetValue = e?.target?.value;
        const fieldName = e?.target?.name;

        if (fieldName) {
            const isQuantityField = fieldName.includes('Quantity') || fieldName.includes('Discount') || fieldName.includes('Price');
            const isValueChanged = isQuantityField ? (parseFloat(targetValue) ? (parseFloat(targetValue) !== parseFloat(initialValues[fieldName])) : parseFloat(initialValues[fieldName]) !== 0) : (targetValue !== initialValues[fieldName]);

            const isInvalidQuantity = isQuantityField && (targetValue === null || targetValue === '');
            if (isQuantityField && isInvalidQuantity && isValueChanged) {
                setInitialValues({ ...initialValues, [fieldName]: 0 });
            }

            if (isValueChanged) {
                setInternalDirty(true);
                if (isInvalidQuantity) {
                    await calculateLineDetailsAndValidate(worksaleId, { ...initialValues, ...values, [fieldName]: 0 }, fieldName as keyof ICalculateWorksalelineDetailFacade);
                } else {
                    await calculateLineDetailsAndValidate(worksaleId, { ...initialValues, ...values }, fieldName as keyof ICalculateWorksalelineDetailFacade);
                }
            }
        }
    }

    async function onSiteCodeChanged(siteCode: any): Promise<void> {
        if (siteCode && siteCode.Code) {
            if (siteCode.Code.toUpperCase() !== initialValues.SiteCode.toUpperCase()) {
                setInternalDirty(true);
                await calculateLineDetailsAndValidate(worksaleId, { ...initialValues, ...values, SiteCode: siteCode.Code }, 'SiteCode' as keyof ICalculateWorksalelineDetailFacade);
            }
        }
    }

    async function onSiteCodeError(hasError: boolean): Promise<void> {
        if (hasError) {
            setInitialValues({ ...initialValues, SiteCode: '' });
        }
    }

    async function onDateChange(key: string, value: any): Promise<void> {
        if (!isNull(initialValues) && value && (initialValues[key] !== value)) {
            setInternalDirty(true);
            await calculateLineDetailsAndValidate(worksaleId, { ...initialValues, ...values }, key as keyof ICalculateWorksalelineDetailFacade);
        }
    }

    async function calculateLineDetailsAndValidate(WorksaleId: number, line: ICalculateWorksalelineDetailFacade, changedField: keyof ICalculateWorksalelineDetailFacade): Promise<IObjectifiedCalculateWorksaleLineResponse> {
        const response = await calculateLineDetailsMutation.mutateAsync({
            ...line,
            WorksaleId,
            urlQueryParams: {
                ChangedField: changedField
            }
        });

        const CalculateWorksalelineDetailInline = response?.CalculateWorksalelineDetail && response?.CalculateWorksalelineDetail?.inlineObject;
        const schema = response?.CalculateWorksalelineDetail && response?.CalculateWorksalelineDetail.schema;

        if (response?.Status) {
            if (CalculateWorksalelineDetailInline) {
                resetForm();
                setInitialValues(CalculateWorksalelineDetailInline);
                setworksaleLineDetailsSchema(schema);
            }
            setInternalDirty(true);
        }

        return response;
    }

    function closeLotSerialDialogs(): void {
        setLotDialogOpen(false);
        setPartialSerialDialogOpen(false);
        setFullSerialDialogOpen(false);
    }

    async function applyDialog(binLots: IBinLotFacade[]): Promise<void> {
        calculateLineDetailsAndValidate(worksaleId, { ...initialValues, ...values, BinLot: binLots, KitStatus: kitStatus, BomCode: selectedBomCode }, 'BinLot')
            .then((response) => {
                if (response.Status && !response.Forms) {
                    showSnackBar({ variant: 'success', message: 'Details successfully updated.' });
                    closeLotSerialDialogs();
                    fetchWorksaleLineDetails({ id: worksaleLineId });
                }
            })
            .catch((err) => { console.warn(err); });
    }

    function selectWarehouse(): void {
        setWarehouseSelectionOpen(true);
    }

    async function handleWarehouseSelectionOk(selectedWarehouse: string): Promise<void> {
        if (selectedWarehouse) {
            const request = { ...initialValues, ...values, WarehouseEntity: selectedWarehouse };
            await calculateLineDetailsAndValidate(worksaleId, request, 'Warehouse' as keyof ICalculateWorksalelineDetailFacade).then((response) => {
                if (response.Status && !response.Forms) {
                    setWarehouseSelectionOpen(false);
                }
            }).catch((err) => { console.warn(err); });
        }
    }

    const handleActionClick = (status: 'c' | 'k') => {
        setMultipleBomState(false);
        setMultipleBomDialogApi(null);
        setKitStatus(status);
        clearKittingDetail();
        if (status) {
            if (multipleBomDialogApi === 'update') {
                updateLineDetailsAndValidate(worksaleId, { ...values, KitStatus: kitStatus, BomCode: selectedBomCode })
                    .then((response) => {
                        if (response.Status && !response.Forms) {
                            showSnackBar({ variant: 'success', message: 'Details successfully updated.' });
                            navigateBack();
                        }
                        if (!response.Status) {
                            showSnackBar({ variant: 'error', message: 'Failed to updated' });
                            fetchWorksaleLineDetails({ id: worksaleLineId });
                        }
                    })
                    .catch((err) => { console.warn(err); });
            } else if (multipleBomDialogApi === 'load') {
                handleLoadComponents();
            }
        }
    };

    const clearKittingDetail = () => {
        setKitStatus('');
        setBomList([]);
        setSelectedBomCode('');
    };

    const HandleBomSelectedRowChanged = (bomCode: string) => {
        setSelectedBomCode(bomCode);
    };

    const handlePriceOverrideOk = async (priceOverrideData: IPriceOverrideData) => {
        if (validData()) {
            setPriceOverrideDialogLoading(true);
            try {
                const response = await updateLineDetailsAndValidate(worksaleId, { ...values, OverrideDetails: { ...priceOverrideDetailSummary, ...priceOverrideData }, KitStatus: kitStatus, BomCode: selectedBomCode });
                if (!response.Forms && response.Status) {
                    handleSaleLineChanged();
                    navigateBack();
                    setInternalDirty(false);
                } else if (response.Forms && response.Status) {
                    if (response.Forms.find((form) => form.MessageType === 'Information')) {
                        handleSaleLineChanged();
                        navigateBack();
                    }
                }
            } catch (err) {
                console.warn(err);
            } finally {
                setPriceOverrideDialogLoading(false);
            }
        }
    };

    const fetchProductSerialsCallback = React.useCallback(
        () => {
            return fetchProductSerials('Lookup', {
                SearchText: '',
                WorksaleId: worksaleId,
                LineNumber: initialValues.LineNumber,
                WarehouseEntity: initialValues.WarehouseEntity,
                ProductId: initialValues.ProductId
            });

        },
        [initialValues, worksaleId]
    );

    return (
        <React.Fragment>
            <LotDialog
                open={lotDialogOpen}
                WorksaleId={worksaleId}
                LineNumber={initialValues.LineNumber}
                WarehouseEntity={initialValues.WarehouseEntity}
                ProductId={initialValues.ProductId}
                RequiredQuantity={initialValues.SuppliedQuantity}
                BinLots={initialValues.BinLot}
                onApply={async (binLots) => { applyDialog(binLots).catch((err) => { console.warn(err); }); }}
                onCancel={async () => { setLotDialogOpen(false); }}
            />
            <PartialSerialDialog
                open={partialSerialDialogOpen}
                RequiredQuantity={initialValues.SuppliedQuantity}
                BinLots={initialValues.BinLot}
                onApply={async (binLots) => { applyDialog(binLots).catch((err) => { console.warn(err); }); }}
                onCancel={async () => { setPartialSerialDialogOpen(false); }}
            />
            <SerialDialog
                open={fullSerialDialogOpen}
                RequiredQuantity={initialValues.SuppliedQuantity}
                BinLots={initialValues.BinLot}
                onApply={async (binLots) => { applyDialog(binLots).catch((err) => { console.warn(err); }); }}
                onCancel={async () => { setFullSerialDialogOpen(false); }}
                fetchProductSerials={fetchProductSerialsCallback}
            />
            <WarehouseSelectionDialog
                open={warehouseSelectionOpen}
                ProductId={initialValues.ProductId}
                onOk={handleWarehouseSelectionOk}
                onCancel={() => setWarehouseSelectionOpen(false)} />
            <FormView
                hidden={!isPathLineDetails}
                isLoading={isLoading}
                formName={'LineDetailsForm'}
                schema={LineDetailsForm}
                includeTabsHeight={false}
                worksaleId={worksaleId}
                initialValues={{
                    ...initialValues,
                    ProductCode: initialValues?.LineSummary?.ProductCode || '',
                    worksaleId: worksaleId
                }}
                valuesSchema={worksaleLineDetailsSchema}
                operationMode={'EDIT'}
                onBlur={formFieldOnBlur}
                summaryTableRenderer={() => summaryTableSchema ?
                    (<SummaryTable loadingWorksaleLineDetailsSummary={isLoading || summaryTableIsLoading}
                        selectedWorksaleLine={{ ...initialValues?.LineSummary, ProductId: initialValues?.ProductId }}
                        data={
                            summaryTableSchema
                        }
                    />) : null}
                fieldExtensions={
                    {
                        Description: {
                            disabled: !initialValues?.IsEnableDescription
                        },
                        RequiredShipDate: {
                            defaultValue: false,
                            disabled: !initialValues.IsEnableShip,
                        },
                        ExpectedShipDate: {
                            defaultValue: false,
                            disabled: isV2 || !initialValues.IsEnableShip
                        },
                        OrderExpiryDate: {
                            defaultValue: false,
                            disabled: !initialValues.IsEnableShip
                        },
                        SiteCode: {
                            onSelectedItemChange: (value: any) => (onSiteCodeChanged(value)),
                            hasError: (hasError: boolean) => (onSiteCodeError(hasError)),
                            disabled: !Boolean(customerId),
                            required: Boolean(customerId)
                        }
                    }
                }
            />
            <PriceOverrideAuthorityDialog
                open={priceOverrideDialogOpen}
                loading={priceOverrideDialogLoading}
                type={priceOverrideScreenType}
                data={priceOverrideDetailSummary}
                onOk={handlePriceOverrideOk}
                onCancel={() => setPriceOverrideDialogOpen(false)}
            />
            {multipleBomOpen && <BOMDialog
                open={multipleBomOpen}
                onClose={() => setMultipleBomState(false)}
                data={bomList}
                kitStatus={kitStatus ? kitStatus.toLowerCase() : ''}
                onActionClick={handleActionClick}
                onSelectedRowChanged={HandleBomSelectedRowChanged}
            />}
            <Route
                key={'KitComponents'}
                path={`${match?.path ?? ''}/kit-components`}
                exact
                render={() => (
                    <KitComponents
                        worksaleLineId={initialValues && initialValues.WorksalelineId}
                        navigateBack={() => changeSelectedTab('Lines')}
                        showDetailOnClick={(data) => {
                            setSelectedComponentLine(data);
                            changeSelectedTab('ComponentDetails');
                        }}
                        confirmOnDelete={true}
                    />
                )} />
            <Route
                key={'ComponentDetails'}
                path={`${match?.path ?? ''}/component-details`}
                exact
                render={() => (
                    <KitComponentDetails
                        ref={componentDetailRef}
                        worksaleId={worksaleId}
                        componentId={selectedComponentLine && selectedComponentLine.ComponentId}
                        LineNumber={selectedComponentLine && selectedComponentLine.LineNumber}
                        worksaleLineId={initialValues && initialValues.WorksalelineId}
                        disableSupplied={initialValues.KittingQuantity === 0}
                        navigateBack={() => changeSelectedTab('KitComponents')} />
                )} />
        </React.Fragment>
    );
};

export default React.forwardRef(LineDetails);
