import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as ordersSelector, actions as ordersActions } from 'ducks/cashReceipting/orders';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as cashReceiptingSelectors } from 'ducks/cashReceipting/cashReceipt';

export const mapStateToProps = (state) => ({
  gridName: 'CashReceiptingOrders',
  columnDefs: ordersSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: ordersSelector.gridOptions(state),
  filterRow: ordersSelector.filterRow(state),
  selectedFilters: ordersSelector.selectedFilters(state),
  appliedFilters: ordersSelector.filterRow(state) && filterSelectors.getFilters(state, ordersSelector.filterRow(state).formName),
  reqParams: {
    entity: cashReceiptingSelectors.entity(state),
    customerId: cashReceiptingSelectors.CustomerId(state)
  },
  rowData: ordersSelector.orders(state)
});

export const actions = {
  detailViewAction: ordersActions.getSpecificOrderAllocation,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: ordersActions.setSelected,
  applyFilters: ordersActions.fetchAllocatedOrders
};
