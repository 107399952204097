export default [
    { id: 'BackorderCommitment', label: 'Backorder Commitment', parent: '' },
    { id: 'BackorderLines', label: 'Lines', parent: 'BackorderCommitment', onBack: 'SalesProcessing' },
    { id: 'LineCommitment', label: 'Line Commitment', parent: 'BackorderLines', onBack: 'BackorderLines' },
    { id: 'PurchaseOrder', label: 'Purchase Order', parent: 'BackorderCommitment' },
    { id: 'PurchaseOrderLines', label: 'Lines', parent: 'PurchaseOrder', onBack: 'BackorderLines' },
    { id: 'PurchaseOrderDeliveries', label: 'Deliveries', parent: 'PurchaseOrder', onBack: 'BackorderLines' },
    { id: 'PurchaseOrderLineDetails', label: 'Line Details', parent: 'PurchaseOrderLines', onBack: 'PurchaseOrderLines' },
    { id: 'OrderDetails', label: 'Order Details', parent: 'PurchaseOrder', onBack: 'PurchaseOrderLines' },
    { id: 'Deliveries', label: 'Delivery', parent: 'PurchaseOrder', onBack: 'PurchaseOrderLines' },
];
