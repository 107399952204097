const Diary = {
  'id': 'SalesDiary',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [{
        'type': 'DIARY',
        'props': {
          'name': 'CustomerQuoteDiary',
          'fetchApi': '/CustomerQuoteEnquiry/Quote/<DocumentNumber>/RetrieveDiaries'
        }
      }]
    }
  ]
}
export default Diary
