import {
  createActions, asyncInitialState, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null
}, 'inTransitImportShipments');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'P/O Line', field: 'PurchaseOrderLineNumber', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    {
      headerName: 'Product', field: 'ProductIdDisplay',
      suppressSorting: true,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      },
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
    },
    { headerName: 'Shipment', field: 'Shipment', minWidth: 150 },
    { headerName: 'ETA', field: 'ETA', minWidth: 150, suppressSorting: true },
    { headerName: 'In transit', field: 'QtyIntransitDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150 },
    { headerName: 'Freight type', field: 'FreightType', suppressSorting: true, minWidth: 150 },
    { headerName: 'Carrier', field: 'Carrier', suppressSorting: true, minWidth: 150 },
    { headerName: 'Description', field: 'Description', minWidth: 150 },
    { headerName: 'Product description', field: 'ProductIdLabel', suppressSorting: true, minWidth: 150 },
    { headerName: 'Product group', field: 'ProductGroup', suppressSorting: true, minWidth: 150 },
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.inTransitImportShipments,
  {
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.inTransitImportShipments.loading,
  gridOptions: state => state.purchaseOrderEnquiry.inTransitImportShipments.data.gridOptions,
  Columns: state => state.purchaseOrderEnquiry.inTransitImportShipments.data.Columns,
  Actions: state => state.purchaseOrderEnquiry.inTransitImportShipments.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)