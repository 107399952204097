import * as React from 'react';
import { connect } from 'react-redux';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import Icon from '@markinson/uicomponents-v2/Icon';
import { Grid as GridLayout, Button, Paper, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@material-ui/core';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import ExtendedDisplayField from '../../FormView/Fields/ExtendedDisplayField';
import { selectors as priceCalculatorSelectors, actions as priceCalculatorActions } from 'ducks/common/priceCalculator';
import SummaryTable from '../SummaryTable';
import SummaryTableSchema from './SummaryTableSchema.json';
import { bindActionCreators } from 'redux';

const style = createStyles({
  header: {
    backgroundColor: '#E5E5E5'
  },
  dialogBody: {
    padding: '2px',
    width: '816px',
    backgroundColor: '#E5E5E5'
  },
  mainBody: {

  },
  footer: {
    backgroundColor: '#E5E5E5',
    margin: '0px',
    padding: '8px 4px'
  },
  buttonClose: {
    color: '#000000de'
  },
  iconClose: {
    color: '#b20000'
  },
  summaryPanel: {
    width: '340px',
    backgroundColor: '#666666',
    height: '100%'
  },
  calcDiv: {
    backgroundColor: '#FFFFFF'
  },
  calculatorFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '60px',
    width: '100%',
    backgroundColor: '#ebebeb'
  },
  calculateButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: '#ebebeb'
  },
  formDiv: {
    padding: '15px'
  },
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {

  }

});

export interface IPriceCalculatorProperties extends StyledComponentProps {
  open: boolean;
  filters?: any;
  isLoading?: boolean;
  data?: any;
  initialData: any;
  onApply(filters: any): void;
  onFetchDefaultPriceDetails(filters: any): void;
  onClose(): void;
}

interface IPriceCalculatorState {
  cost: number;
  includeCost: boolean;
  price: number;
  includePrice: boolean;
  gp: number;
  taxRate: number;
}

class PriceCalculator extends React.Component<IPriceCalculatorProperties, IPriceCalculatorState> {

  constructor(props: IPriceCalculatorProperties) {
    super(props);
    this.state = { cost: 0, includeCost: false, price: 0, includePrice: false, gp: 0, taxRate: 0 };
  }

  componentDidUpdate(prevProps: Readonly<IPriceCalculatorProperties>): void {
    if (this.props.filters && prevProps.filters !== this.props.filters) {
      this.props.onFetchDefaultPriceDetails(this.props.filters);
    }
    if (prevProps.initialData !== this.props.initialData && this.props.initialData) {
      this.setState({ cost: this.props.initialData.Cost, includeCost: this.props.initialData.IncludeCost, price: this.props.initialData.Price, includePrice: this.props.initialData.IncludePrice, gp: this.props.initialData.GP, taxRate: this.props.initialData.TaxRate });
    }
  }

  handleCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cost: (event && event.target && parseFloat(event.target.value)) || 0 });
  }

  handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ price: (event && event.target && parseFloat(event.target.value)) || 0 });
  }

  handleGpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ gp: (event && event.target && parseFloat(event.target.value)) || 0 });
  }

  handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ taxRate: (event && event.target && parseFloat(event.target.value)) || 0 });
  }

  handlePriceTaxToggle = () => {
    this.setState({ includePrice: !this.state.includePrice });
  }

  handleCostTaxToggle = () => {
    this.setState({ includeCost: !this.state.includeCost });
  }

  handleApplyFilters = () => {
    const { cost, includeCost, price, includePrice, gp, taxRate } = this.state;
    if (this.props.filters && this.props.onApply) {
      this.props.onApply({ ...this.props.filters, cost, includeCost, price, includePrice, gp, taxRate });
    }
  }

  render(): JSX.Element {
    const { classes } = this.props;
    const defaultDecimalPlaces = 4;

    return (<Dialog
      open={this.props.open}
      aria-labelledby='form-dialog-title'
      maxWidth={false}
    >
      <DialogTitle className={classes.header}>Price Calculator</DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <GridLayout container spacing={0} direction={'row'}>
          <GridLayout {...{ item: true, xs: 7, className: classes.calcDiv }}>
            <div className={classes.formDiv}>
              <div>
                <TextField
                  size={'medium'}
                  label={'Cost'}
                  type={'number'}
                  decimalSeparator
                  decimalPlaces={defaultDecimalPlaces}
                  value={this.state.cost}
                  style={{ float: 'left' }}
                  onChange={this.handleCostChange}
                />
                <FormControlLabel
                  control={<Switch
                    color={'default'}
                    checked={this.state.includeCost}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      bar: classes.bar,
                    }}
                  />}
                  style={{ width: '40%', float: 'right', height: '70px' }}
                  classes={{
                    label: classes.formControlLabel,
                    disabled: classes.disabled
                  }}
                  label='Tax included'
                  onChange={this.handleCostTaxToggle}
                />
              </div>
              <div>
                <ExtendedDisplayField
                  {
                  ...{
                    label: 'GP',
                    type: 'number',
                    decimalSeparator: true,
                    adornment: '%',
                    size: 'small',
                    value: this.state.gp,
                    onChange: this.handleGpChange
                  }
                  }

                />
              </div>
              <div>
                <TextField
                  size={'medium'}
                  label={'Price'}
                  type={'number'}
                  decimalSeparator
                  decimalPlaces={defaultDecimalPlaces}
                  style={{ float: 'left' }}
                  value={this.state.price}
                  onChange={this.handlePriceChange} />
                <FormControlLabel
                  control={<Switch
                    color={'default'}
                    checked={this.state.includePrice}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      bar: classes.bar,
                    }}
                  />}
                  style={{ width: '40%', float: 'right', height: '70px' }}
                  classes={{
                    label: classes.formControlLabel,
                    disabled: classes.disabled
                  }}
                  label='Tax included'
                  onChange={this.handlePriceTaxToggle}
                />
              </div>
              <div>
                <div>
                  <ExtendedDisplayField
                    {
                    ...{
                      label: 'Tax rate',
                      type: 'number',
                      decimalSeparator: true,
                      adornment: '%',
                      size: 'small',
                      value: this.state.taxRate,
                      onChange: this.handleTaxRateChange
                    }
                    }
                  />
                </div>
              </div>
            </div>
            <div className={classes.calculatorFooter}>
              <div className={classes.calculateButton}>
              </div>
              <Button
                variant={'contained'}
                className={classes.calculateButton}
                onClick={this.handleApplyFilters}>
                <DoneAllIcon style={{ color: '#006633' }} /> CALCULATE
              </Button>
            </div>
          </GridLayout>
          <GridLayout  {...{ item: true, xs: 5 }}>
            <Paper elevation={0} className={classes.summaryPanel} square={true}>
              <SummaryTable data={SummaryTableSchema} priceSummaryLoading={this.props.isLoading} priceSummary={this.props.data} />
            </Paper>
          </GridLayout>
        </GridLayout>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button color='primary' onClick={this.props.onClose} className={classes.buttonClose}>
          <Icon iconName={'Cancel'} className={classes.iconClose} />Close
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = (state) => ({
  isLoading: priceCalculatorSelectors.isLoading(state),
  data: priceCalculatorSelectors.data(state),
  initialData: priceCalculatorSelectors.defaultPriceDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onFetchDefaultPriceDetails: priceCalculatorActions.fetchDefaultPriceDetails
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style, { index: 1 })(PriceCalculator));
