import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as policyRulesSelectors, actions as policyRulesActions } from 'ducks/securityManagement/policyRules';
import { selectors as policySelectors } from 'ducks/securityManagement/securityPolicies';
import { IApplicationState } from 'ducks/reducers';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { search } from 'api/SecurityManagement/policyRules';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = (state: IApplicationState) => ({
    gridName: 'SecurityPolicyRules',
    isLoading: policyRulesSelectors.isLoading(state),
    appliedFilters: policyRulesSelectors.filterRow(state) && filterSelectors.getFilters(state, policyRulesSelectors.filterRow(state).formName),
    selectedFilters: policyRulesSelectors.selectedFilters(state),
    columnDefs: policyRulesSelectors.columns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
    gridOptions: policyRulesSelectors.gridOptions(state),
    filterRow: policyRulesSelectors.filterRow(state),
    contextMenu: policyRulesSelectors.contextMenu(state),
    reqParams: (policySelectors.selected(state) || params().get('PolicyId'))
        &&
    {
        PolicyId: (policySelectors.selected(state) && policySelectors.selected(state).PolicyId) || params().get('PolicyId')
    },
    apiMethod: search
});

export const actions = {
    changeSelectedTab: uiActions.changeSelectedTab,
    changeOperationMode: operationActions.changeOperationMode,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    setSelectedOrderLine: policyRulesActions.setSelected,
    applyFilters: filterActions.applyFilters
};
