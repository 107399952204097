import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
  }
}, 'IEASCBOM');

const initialState = asyncInitialState({
  selected: null,
  Columns: [
    { headerName: 'BOM code', field: 'BOMCode', cellRenderer: 'agGroupCellRenderer', minWidth: 150 },
    { headerName: 'Primary BOM', field: 'PrimaryBOMDisplay' },
    { headerName: 'BOM description', field: 'Description' },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.associations.BOM,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.associations.BOM.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.associations.BOM.data.gridOptions,
  columns: state => state.inventoryEnquiry.associations.BOM.data.Columns,
  detailCellRenderer: state => state.inventoryEnquiry.associations.BOM.data.detailCellRenderer,
  detailColumns: state => state.inventoryEnquiry.associations.BOM.data.detailCellRendererParams,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)