import React, { Component } from 'react';
import NotificationItem from './NotificationItem';

import Button from '@material-ui/core/Button';

import styles from './NotificationPanel.scss';

class NotificationPanel extends Component{
  render(){

    return (
      <div className={styles.rootDiv}>
        <div className={styles.notificationList}>
          <NotificationItem
            type={'warning'}
            message={'Service order xxx01 ended in error. Please review for the further information.'}
            timeStamp={'08/02/18 10:51:00 AM'}
          />
          <NotificationItem 
            type={'info'}
            message={'Service order xxx01 ended in error. Please review for the further information.'}
            timeStamp={'08/02/18 10:51:00 AM'}
          />
          <NotificationItem 
            type={'action'}
            message={'Service order xxx01 ended in error. Please review for the further information.'}
            timeStamp={'08/02/18 10:51:00 AM'}
          />
        </div>
        <div className={styles.panelFooter}>
          <Button
            variant={'raised'}
            style={{backgroundColor: '#FE6F01', color: '#FFFFFF'}}
            className={styles.clearAllButton}
          >
            CLEAR ALL
          </Button>
        </div>
      </div>
    )
  }
}

export default NotificationPanel;