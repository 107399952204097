import {
    ISortOrder, ISearchRequest,
    IServicePatternDetailsFacade, IServicePatternSearchResponse, IServicePatternDetailsResponse,
    IServicePatternSearchFacade,
    IFormInfo,
    IDeleteRecordResponse,
} from './../swaggerTypes';
import { AsInline, IObjectified } from '../utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';
import { ILookupResponse } from 'api/customType';

const sortServicePatternItems = (response: AsInline<IServicePatternDetailsResponse, 'ServicePatternDetails'>) => {
    if (response.ServicePatternDetails) {
        (response.ServicePatternDetails.Items as any).sort((a, b) => a.SequenceNumber.Value - b.SequenceNumber.Value);
    }
};

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
    records: IObjectified<IServicePatternSearchFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return Api.postInlineCollectionResponse<ISearchRequest, IServicePatternSearchResponse, 'ServicePatterns'>(
        'ServicePatternMaintenance/SearchServicePatterns', { SearchText, Sort, BatchPage, BatchSize })
        .then((response) => {
            const records = asObjectifiedArray(response, 'ServicePatterns');
            const batchInformation = (response as unknown as IServicePatternSearchResponse).BatchInformation;

            return {
                records,
                currPage: batchInformation ? batchInformation.BatchPage : 1,
                nextPage: batchInformation && !batchInformation.EndOfData,
                prevPage: batchInformation && batchInformation.BatchPage > 1
            };
        });
}

export async function searchById(query: { ServicePatternId: number }): Promise<{
    records: IObjectified<IServicePatternDetailsFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { ServicePatternId } = query;

    return Api.getInlineResponse<IServicePatternDetailsResponse, 'ServicePatternDetails'>(
        `ServicePatternMaintenance/ServicePattern/${ServicePatternId}`)
        .then((response) => {
            sortServicePatternItems(response);
            const records = isNull(response.Forms) ? [asObjectified(response, 'ServicePatternDetails')] : [];

            return {
                Forms: response.Forms,
                records: records,
                currPage: 1,
                nextPage: false,
                prevPage: false
            };
        });
}
export type IObjectifiedServicePatternResponse = Omit<IServicePatternDetailsResponse, 'ServiceItemType'> & {
    ServicePatternDetails: IObjectified<IServicePatternDetailsFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
};
export async function fetchServicePattern(ServicePatternId: number): Promise<IObjectifiedServicePatternResponse> {

    return Api.getInlineResponse<IServicePatternDetailsResponse, 'ServicePatternDetails'>(
        `ServicePatternMaintenance/ServicePattern/${ServicePatternId}`
    )
        .then((response) => {
            sortServicePatternItems(response);
            const details: any = asObjectified(response, 'ServicePatternDetails');
            if (details.inlineObject) details.inlineObject.Items.sort((a, b) => a.SequenceNumber - b.SequenceNumber);

            return {
                ...response,
                ServicePatternDetails: details
            };
        });
}

export async function createServicePattern(query: IServicePatternDetailsFacade):
    Promise<IObjectifiedServicePatternResponse> {

    return Api.postInlineResponse<IServicePatternDetailsFacade, IServicePatternDetailsResponse, 'ServicePatternDetails'>(
        'ServicePatternMaintenance/CreateServicePattern', query)
        .then((response) => {

            return {
                ...response,
                ServicePatternDetails: asObjectified(response, 'ServicePatternDetails')
            };
        });
}

export async function deleteServicePattern(ServicePatternId: number): Promise<IDeleteRecordResponse> {
    return Api.delete<IDeleteRecordResponse>(`ServicePatternMaintenance/ServicePattern/${ServicePatternId}`);
}

export async function updatedServicePattern(ServicePatternId: number, query: IServicePatternDetailsFacade): Promise<IObjectifiedServicePatternResponse> {
    return Api.putInlineResponse<IServicePatternDetailsFacade, IServicePatternDetailsResponse, 'ServicePatternDetails'>(
        `ServicePatternMaintenance/ServicePattern/${ServicePatternId}`, query)
        .then((response) => {
            sortServicePatternItems(response);

            return {
                ...response,
                ServicePatternDetails: asObjectified(response, 'ServicePatternDetails')
            };
        });
}

export const fetchServicePatternOptions = async (query: { SearchText: string }): Promise<ILookupResponse> => {
    return Api.post<{ SearchText: string }, ILookupResponse>('CustomTypes/Lookup/ServicePattern/Search', query);
};
