const DiaryDetails = {
  'id': 'DiaryDetails',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'props': {
        'name': 'DiaryDetails',
        'fullWidth': true
      },
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 2
      },
      'children': [
        {
          id: 1,
          type: 'DIARY_DETAILS',
          props: {
            label: 'DiaryDetails',
            name: 'DiaryDetails',
            createApi: '/PurchaseOrderEnquiry/PurchaseOrder/<PurchaseOrder>/CreateDiary',
            updateApi: '/PurchaseOrderEnquiry/PurchaseOrder/<PurchaseOrder>/Diary/<DiaryId>',
            searchByIdApi: '/PurchaseOrderEnquiry/PurchaseOrder/<PurchaseOrder>/Diary/<DiaryId>',
            deleteApi: '/PurchaseOrderEnquiry/PurchaseOrder/<PurchaseOrder>/Diary/<DiaryId>'
          }
        }
      ]
    }
  ]
}
export default DiaryDetails
