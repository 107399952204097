import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: 'calc(100% - 354px)',
        float: 'left',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        margin: 15
    },
    grid: {
        height: 'calc(100vh - 250px)',
        width: '100%'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
});
