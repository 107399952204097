import React from 'react';
import TimeField from '@markinson/uicomponents-v2/TimeField';

class TimeTextField extends React.Component {

  componentDidUpdate(prevProps) {
    const { value, operationMode } = this.props;
    if (!value && !prevProps.value && operationMode === 'NEW' && !this.props.meta.active && !this.props.meta.visited && this.props.defaultValue !== false)
      this.props.onChange('')
  }
  componentDidMount() {
    const { value } = this.props;
    if (!value && this.props.defaultValue !== false && this.props.onChange)
      this.props.onChange('')
  }

  render() {
    const { operationMode, onChange, ...rest } = this.props;

    return (
      <TimeField
        onChange={onChange}
        {...rest}
      />
    )
  }
}

export default TimeTextField;
