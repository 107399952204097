import { IColDef, IDataGridOptions } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';

export interface ISlipLinesData {
  selected: any;
  selectedFilters: any;
  slipLineDetails: any;
  slipLineDetailsSummary: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  Columns: IColDef[];
  Data: any[];
}

export interface ISlipLinesState extends IExtendedState<ISlipLinesData> {
  fetchSlipLineDetailsSummary_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clearLines: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      fetch: (Data) => (Data),
      fetchSlipLineDetails: (Data) => (Data),
      fetchSlipLineDetailsSummary: (Data) => (Data),
    }
  },
  'slipLines');

const initialState = asyncInitialState<ISlipLinesData>({
  selected: null,
  selectedFilters: {
    Line: '',
    Product: ''
  },
  filterRow: {
    formName: 'SupplierInvoiceFilters',
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'Line',
          name: 'Line',
          size: 'small',
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Product',
          name: 'Product',
          lookupName: 'Product',
          size: 'small',
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: 'SlipLineDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Line', field: 'LineNumber', minWidth: 100, suppressSorting: true, },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      },
      minWidth: 120, suppressSorting: true,
    },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'To pick', field: 'ToPickQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 150, suppressSorting: true, },
    { headerName: 'Product group', field: 'ProductGroup', minWidth: 120, suppressSorting: true, },
    { headerName: 'Product description', field: 'ProductDescription', minWidth: 120, suppressSorting: true, },
    { headerName: 'Customer product', field: 'CustomerProduct', minWidth: 150, suppressSorting: true, },
    { headerName: 'Comment', field: 'Comment', minWidth: 150, suppressSorting: true, },
  ],
  Data: [],
  slipLineDetails: null,
  slipLineDetailsSummary: null
});

export default (state: ISlipLinesState = initialState, action: IDataAction): ISlipLinesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetch:
    case types.fetchSlipLineDetails:
    case types.fetchSlipLineDetailsSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          Data: linesData
        };
      });
    case types.saga.fetchSlipLineDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const lineDetails = successAction.payload;

        return {
          ...data,
          slipLineDetails: lineDetails
        };
      });
    case types.saga.fetchSlipLineDetailsSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const lineDetailsSummary = successAction.payload;

        return {
          ...data,
          slipLineDetailsSummary: lineDetailsSummary
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines,
  {
    selected: (data) => data.selected,
    Actions: (data) => data.Actions,
  }
);

const syncSelector = {
  Data: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.Data,
  slipLineDetails: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.slipLineDetails,
  slipLineDetailsSummary: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.slipLineDetailsSummary,
  isLoading: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.loading,
  gridOptions: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.gridOptions,
  columns: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.Columns,
  Actions: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.Actions,
  filterRow: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.filterRow,
  selectedFilters: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.data.selectedFilters,
  loadingSlipLineDetailsSummary: (state: { pickSlipEnquiry: { slipLines: ISlipLinesState } }) => state.pickSlipEnquiry.slipLines.fetchSlipLineDetailsSummary_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
