import { IDashboardWidgetOutstandingCustomerQuoteResponse } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';
import { Api, asObjectified } from '../../baseApi';

export interface IObjectifiedOutstandingCustomerQuotesResponse {
    OutstandingCustomerQuotes: IObjectified<IDashboardWidgetOutstandingCustomerQuoteResponse>;
    Status?: boolean;
}

export const getOutstandingCustomerQuotes = async ({ EntView, SalesEntity }): Promise<IObjectifiedOutstandingCustomerQuotesResponse> => {
    return Api.getInlineResponse<IDashboardWidgetOutstandingCustomerQuoteResponse, 'OutstandingCustomerQuotes'>(
        `DashboardMaintenance/DashboardWidget/OutstandingCustomerQuotes/EntView/${EntView}/Entity/${SalesEntity}`)
        .then((response) => {
            return {
                ...response,
                OutstandingCustomerQuotes: response.OutstandingCustomerQuotes ? asObjectified(response, 'OutstandingCustomerQuotes') : null
            };
        });
};
