import React from 'react';
import { useActionBarHotKeys } from 'utils/hooks';
import { GlobalHotKeys } from 'react-hotkeys';
import { KEY_MAP } from 'utils/constants';
import { ActionBarContext } from 'utils/ActionBarContextProvider';

interface IActionBarHotKeysWrapper {
    children: React.ReactNode | JSX.Element;
}

const ActionBarHotKeysWrapper = ({ children }: IActionBarHotKeysWrapper) => {
    const { actionBarProps: { actionBar } } = React.useContext(ActionBarContext);
    const { handlers } = useActionBarHotKeys(actionBar);

    return (
        <GlobalHotKeys keyMap={KEY_MAP} handlers={handlers} allowChanges>
            {children}
        </GlobalHotKeys>
    );
};

export default React.memo(ActionBarHotKeysWrapper);
