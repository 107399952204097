import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as branchOrdersSelectors, actions as branchOrdersActions } from 'ducks/purchaseOrderEnquiry/branchOrders';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { search } from 'api/purchaseOrderEnquiry/purchaseOrderBranchOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'
import { actions as orderLineActions } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'BranchOrders',
  columnDefs: branchOrdersSelectors.branchOrdersColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: branchOrdersSelectors.branchOrdersActions(state),
  gridOptions: branchOrdersSelectors.gridOptions(state),
  isLoading: branchOrdersSelectors.isLoading(state),
  filterRow: branchOrdersSelectors.filterRow(state),
  selectedFilters: branchOrdersSelectors.selectedFilters(state),
  appliedFilters: branchOrdersSelectors.filterRow(state) && filterSelectors.getFilters(state, branchOrdersSelectors.filterRow(state).formName),
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) &&
  {
    PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
  },
  keyFields: [{ valueField: 'PurchaseOrder', routeField: 'PurchaseOrder' }],
  apiMethod: search
});

export const actions = {
  deleteSelectedRecords: branchOrdersActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: orderLineActions.setSelected,
  applyFilters: filterActions.applyFilters
}
