import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IFinalConfirmationDialogProperties } from './FinalConfirmationDialog.properties';
import FinalConfirmationSchema from './FinalConfirmationDialog.form';

const FinalConfirmationDialog = (props: IFinalConfirmationDialogProperties) => {
    const { open, loading, data, onCancel, onOk, supplierId, values, purchaseOrderOuputOptions, changeFormFieldValue } = props;

    const isEGatewaySupplier = data?.PurchaseOrderConfirmation?.inlineObject?.TransmitVia?.includes('E-Gateway');
    const EnableSetStatusToTransmit = data?.PurchaseOrderConfirmation?.inlineObject?.EnableSetStatusToTransmit;

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Final Confirmation'
                formProps={{
                    formSchema: FinalConfirmationSchema,
                    initialValues: data?.PurchaseOrderConfirmation?.inlineObject,
                    valuesSchema: data?.PurchaseOrderConfirmation?.schema,
                    style: { height: '380px', minWidth: '600px' },
                    supplierId: supplierId,
                    fieldExtensions: {
                        Transmit: {
                            onChange: (e) => {
                                if (data?.PurchaseOrderConfirmation?.schema.SetStatusToTransmit.ReadOnly) {
                                    changeFormFieldValue('SetStatusToTransmit', e.target.checked);
                                }
                            }
                        },
                        SetStatusToTransmit: {
                            disabled: (!isEGatewaySupplier && !EnableSetStatusToTransmit) || Boolean(values?.Transmit)
                        }
                    },
                }}
                actions={[
                    {
                        title: 'Ok',
                        listener: () => {
                            if (onOk) {
                                onOk({
                                    ...values,
                                    PurchaseOrderOutput: purchaseOrderOuputOptions
                                });
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </div>
    );
};

export default FinalConfirmationDialog;
