import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as reservationSerialsSelectors, actions as reservationSerialsActions } from 'ducks/salesOrderEnquiry/reservationSerials';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { fetch } from 'api/salesOrderEnquiry/reservationSerials';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  columnDefs: reservationSerialsSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: reservationSerialsSelectors.Actions(state),
  gridOptions: reservationSerialsSelectors.gridOptions(state),
  isLoading: reservationSerialsSelectors.isLoading(state),
  reqParams: (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber')) &&
    (orderSelectors.selected(state) || params.get('SalesOrder')) &&
  {
    SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
    LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
  },
  keyFields: [{ valueField: 'LineNumber', routeField: 'LineNumber' }],
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: reservationSerialsActions.setSelected
}
