import * as React from 'react';
import { IOrderDetailsProps } from './OrderDetails.properties';
import FormView from 'components/FormView';
import OrderDetailFrom from './OrderDetails.form';
import CancelIcon from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useFetchWorksaleOrderDetails, useUpdateWorksaleOrderDetails } from 'api/Worksale/orderDetails';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';

const OrderDetails = (props: IOrderDetailsProps): JSX.Element => {
  const { formValues, forwardedRef, dirty, resetForm, changeSelectedTab, onWarehouseChanged, onSuccess, formSyncErrors, formAsyncErrors, changeConfirmationDialog } = props;
  const { refetch: fetchWorksaleOrderDetails, data: worksaleOrderDetailsData } = useFetchWorksaleOrderDetails();
  const { mutateAsync: updateWorksaleOrderDetailsMutateAsync } = useUpdateWorksaleOrderDetails();
  const { setActionBar } = React.useContext(ActionBarContext);
  const warehouseFieldRef = React.useRef<IBasicLookupFieldHandle>();

  const customerId = useSPContextSelector(Selectors.CustomerId);
  const worksaleId = useSPContextSelector(Selectors.WorksaleId);

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      isDirty(): boolean {
        return dirty;
      },
      onOk(): void {
        if (isValidData()) {
          if (worksaleOrderDetailsData?.WorksaleDetails?.inlineObject?.HasWorksalelines && worksaleOrderDetailsData?.WorksaleDetails?.inlineObject?.WarehouseEntity !== formValues.WarehouseEntity) {
            changeConfirmationDialog({
              open: true,
              title: 'Warehouse Update Confirmation',
              message: 'The default warehouse has been altered for the order.\n\nOrder lines assigned to the original warehouse may be updated to new warehouse.\nAny stock reservations or commitments will be removed for the updated lines.\n\nApply warehouse update to the order lines?',
              okLabel: 'Yes',
              onOk: () => {
                handleWarehouseSelectionOk();
                updateOrderDetails(true).catch((err) => { console.warn(err); });
              },
              onRefuse: () => () => {
                updateOrderDetails(false).catch((err) => { console.warn(err); });
              },
              showRefuse: true,
              showCancel: false,
            });

          } else {
            updateOrderDetails(false).catch((err) => { console.warn(err); });
          }
        }
      },
      onCancel(callback?: () => void): void {
        resetForm();
        if (callback) {
          callback();
        }
      }
    })
  );

  React.useEffect(
    () => {

      if (worksaleId < 0 || !customerId) {
        changeSelectedTab('Lines');

        return;
      }

      setTimeout(
        () => {
          warehouseFieldRef.current?.focus();
        },
        0
      );

      setActionBarButtons();
      fetchWorksaleOrderDetails();
    },
    [worksaleId, customerId, warehouseFieldRef]
  );

  function handleWarehouseSelectionOk(): void {
    if (onWarehouseChanged) {
      onWarehouseChanged();
    }
  }

  function OrderDetailsData(): object {

    return {
      ...formValues,
      CustomerPurchaseOrder: (worksaleOrderDetailsData?.WorksaleDetails?.inlineObject?.CustomerPurchaseOrder !== formValues.CustomerPurchaseOrder) ? formValues.CustomerPurchaseOrder : undefined,
    };
  }

  async function updateOrderDetails(ChangeLines: boolean): Promise<void> {

    const response = await updateWorksaleOrderDetailsMutateAsync({
      WorksaleId: worksaleId,
      ...OrderDetailsData(),
      urlQueryParams: {
        changeLines: ChangeLines ? 'True' : 'False',
      }
    });

    if (response.Status) {
      showSnackBar({ variant: 'success', message: 'Order Details successfully updated.' });
      fetchWorksaleOrderDetails();
      onSuccess();
    } else {
      response.Messages && response.Messages.length > 0 ? response.Messages.map((message) => (
        showSnackBar({ variant: 'error', message: message.MessageDetail })
      )) : showSnackBar({ variant: 'error', message: 'Failed to update the order details.' });
    }
  }

  function isValidData(): boolean {
    if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      return false;
    } else if (formAsyncErrors && Object.keys(formAsyncErrors).length > 0) {
      const firstError = Object.keys(formAsyncErrors)[0];
      showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });

      return false;
    }

    return true;
  }

  function setActionBarButtons(): void {
    setActionBar({
      leftIcons: [],
      centerIcons: [],
      rightIcons: [
        {
          label: 'Cancel',
          Icon: CancelIcon,
          iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
          action: 'OrderDetailsCancel',
        },
        {
          label: 'Ok',
          Icon: CheckCircle,
          action: 'OrderDetailsOk',
        }
      ]
    });
  }

  return (
    <React.Fragment>
      <FormView
        customerId={Number(customerId)}
        formName={'OrderDetails'}
        schema={OrderDetailFrom}
        valuesSchema={worksaleOrderDetailsData?.WorksaleDetails?.schema}
        includeTabsHeight={true}
        initialValues={worksaleOrderDetailsData?.WorksaleDetails?.inlineObject}
        operationMode={'EDIT'}
        fieldExtensions={{
          WarehouseEntity: {
            fieldRef: warehouseFieldRef
          }
        }}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(OrderDetails);
