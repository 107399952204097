export default [
  {
    id: '1',
    label: 'Local Shipment Enquiry in new tab',
    'action': (params) => {window.open('/local-shipment-enquiry' + (params ? '/' + params : ''))}
  },
  {
    id: '2',
    label: 'Local Shipment Enquiry',
    'action': (params) => {window.location.pathname = '/local-shipment-enquiry' + (params ? '/' + params : '')}
  }
]
