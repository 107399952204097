import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as invoiceActions, selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface IInvoiceItem {
  key: string;
  SalesEntity: string;
  Invoice: number;
  CustomerDescription: string;
  CustomerLabel: string;
}

const InvoiceSearchLookUpDrawer = buildSearchLookUpDrawer<IInvoiceItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IInvoiceItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IInvoiceItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Invoice',
    searchPlaceholder: 'Search for invoices...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'Invoice',
        label: 'Invoice'
      }
    ],
    records: invoiceSelectors.all(state) || [],
    selectedRecord: invoiceSelectors.selected(state),
    selectedSortFilter: 'Invoice',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isCustomerLookUpOpen(state),
    nextPage: invoiceSelectors.nextPage(state),
    prevPage: invoiceSelectors.prevPage(state),
    pageSize: invoiceSelectors.pageSize(state),
    totalPages: invoiceSelectors.totalPages(state),
    isLoading: invoiceSelectors.isLoading(state),
    loadingPrevPage: invoiceSelectors.loadingPrevPage(state),
    loadingNextPage: invoiceSelectors.loadingNextPage(state),
    keyField: 'key',
    fieldsToDisplay: ['SalesEntity', 'Invoice', 'CustomerDescription', 'CustomerLabel'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('SalesEntity', selectedRecord.SalesEntity);
        matchParams.set('InvoiceNumber', selectedRecord.Invoice);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const SalesEntity = matchParams.get('SalesEntity');
      const InvoiceNumber = matchParams.get('InvoiceNumber');
      if (SalesEntity && InvoiceNumber) {
        return {
          id: { SalesEntity, InvoiceNumber },
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: invoiceActions.search,
  searchById: invoiceActions.searchById,
  onToggle: uiActions.toggleCustomerLookUp,
  changeSelectedRecord: invoiceActions.setSelected,
  fetchNextPage: invoiceActions.fetchNextPage,
  fetchPrevPage: invoiceActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSearchLookUpDrawer);
