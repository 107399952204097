import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as inPutAwaySelectors, actions as inPutAwayActions } from 'ducks/salesOrderEnquiry/cdCommittedInPutAway';
import { fetch } from 'api/salesOrderEnquiry/cdCommittedInPutAway';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';

export const mapStateToProps = state => {
  const params = new URLSearchParams(location.search);

  return ({
    gridName: 'CommittedInPutAway',
    columnDefs: inPutAwaySelectors.Columns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
    actions: inPutAwaySelectors.Actions(state),
    gridOptions: inPutAwaySelectors.gridOptions(state),
    isLoading: inPutAwaySelectors.isLoading(state),
    reqParams: (orderLineSelectors.selectedOrderLine(state) || params.get('BOMLineNumber')) &&
      (orderSelectors.selected(state) || params.get('SalesOrder')) &&
    {
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('BOMLineNumber'),
      SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
    },
    apiMethod: fetch
  });
}

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: inPutAwayActions.setSelected
}
