import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as bomLotsSelectors, actions as bomLotsActions } from 'ducks/pickSlipEnquiry/bomLots';
import { fetchLots } from 'api/pickSlipEnquiry/bom';
import { actions as filterActions } from 'ducks/common/filters';
import { selectors as pickSlipSelectors } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';
import { IApplicationState } from 'ducks/reducers';
import { selectors as bomSelectors } from 'ducks/pickSlipEnquiry/bom';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'PSEBOMLots',
  columnDefs: bomLotsSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: bomLotsSelectors.gridOptions(state),
  reqParams: ((pickSlipSelectors.selected(state) || params.get('DespatchId')) && (bomSelectors.selected(state) || params.get('BOMLineNumber')))
    &&
    {
      DespatchId: (pickSlipSelectors.selected(state) && pickSlipSelectors.selected(state).DespatchId) || params.get('DespatchId'),
      BOMLineNumber: (bomSelectors.selected(state) && bomSelectors.selected(state).LineNumber) || params.get('BOMLineNumber')
    },
  apiMethod: fetchLots
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: bomLotsActions.setSelected,
  applyFilters: filterActions.applyFilters
};
