import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as supplierProductActions, selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';

interface ISupplierProductItem {
  Supplier: number;
  SupplierProductId: number;
  SupplierProductIdDisplay: string;
  SupplierProductIdLabel: string;
  SupplierDisplay: string;
  SupplierLabel: string;
  [name: string]: any;
}

const SupplierSearchLookUpDrawer = buildSearchLookUpDrawer<ISupplierProductItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ISupplierProductItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ISupplierProductItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'SupplierProduct',
    searchPlaceholder: 'Search for products...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'Supplier',
        label: 'Supplier'
      },
      {
        filter: 'SupplierProductId',
        label: 'Supplier Product'
      },
    ],
    records: supplierProductSelectors.all(state) || [],
    selectedRecord: supplierProductSelectors.selected(state),
    selectedSortFilter: 'Supplier',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isSupplierLookUpOpen(state),
    isLoading: supplierProductSelectors.isLoading(state),
    nextPage: supplierProductSelectors.nextPage(state),
    prevPage: supplierProductSelectors.prevPage(state),
    pageSize: supplierProductSelectors.pageSize(state),
    totalPages: supplierProductSelectors.totalPages(state),
    loadingPrevPage: supplierProductSelectors.loadingPrevPage(state),
    loadingNextPage: supplierProductSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'SupplierProductId',
    fieldsToDisplay: ['SupplierProductIdDisplay', '', 'SupplierProductIdLabel', '', 'SupplierDisplay', 'SupplierLabel'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('SupplierProductId', selectedRecord.SupplierProductId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const SupplierProductId = Number(matchParams.get('SupplierProductId'));
      if (SupplierProductId) {
        return {
          id: SupplierProductId
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: supplierProductActions.search,
  searchById: supplierProductActions.searchById,
  changeSelectedRecord: supplierProductActions.setSelected,
  onToggle: uiActions.toggleSupplierLookUp,
  fetchNextPage: supplierProductActions.fetchNextPage,
  fetchPrevPage: supplierProductActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearchLookUpDrawer);
