import { IDocumentOutputOptionsFacade } from 'api/swaggerTypes';
import { IExtendedState, asyncSelectors, initializeReducer } from './utils';
import { IObjectified } from 'api/utils';
import { isNull } from 'utils/utils';

interface IDocumentOutputData {
  initialOptions: {
    [documentType: string]: {
      [fieldId: string]: IObjectified<IDocumentOutputOptionsFacade>;
    };
  };
  options: {
    [documentType: string]: {
      [fieldId: string]: IObjectified<IDocumentOutputOptionsFacade>;
    };
  };
}

export interface IDocumentOutputState extends IExtendedState<IDocumentOutputData> { }

const initialData: IDocumentOutputData = {
  initialOptions: {},
  options: {}
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'documentOutput',
  initialData: initialData,
  syncActions: {
    updateInitialOptions: {
      action: (documentType: string, fieldId: string, options: IDocumentOutputOptionsFacade) => ({ documentType, fieldId, options }),
      callback: (data: IDocumentOutputData, payload: { documentType: string; fieldId: string; options: IDocumentOutputOptionsFacade }): IDocumentOutputData => {
        const { documentType, fieldId, options: payloadOptions } = payload;

        const initialOptions = { ...data.initialOptions };
        initialOptions[documentType] = initialOptions[documentType] || {};
        initialOptions[documentType][fieldId] = initialOptions[documentType][fieldId] || { inlineObject: {}, schema: {} };
        initialOptions[documentType][fieldId].inlineObject = { ...initialOptions[documentType][fieldId].inlineObject, ...payloadOptions };

        const options = { ...data.options };
        options[documentType] = options[documentType] || {};
        options[documentType][fieldId] = options[documentType][fieldId] || { inlineObject: {}, schema: {} };
        options[documentType][fieldId].inlineObject = { ...options[documentType][fieldId].inlineObject, ...payloadOptions };

        return { ...data, initialOptions, options };
      }
    },
    updateOptions: {
      action: (documentType: string, fieldId: string, options: IDocumentOutputOptionsFacade) => ({ documentType, fieldId, options }),
      callback: (data: IDocumentOutputData, payload: { documentType: string; fieldId: string; options: IDocumentOutputOptionsFacade }): IDocumentOutputData => {
        const { documentType, fieldId, options: payloadOptions } = payload;
        const options = { ...data.options };
        options[documentType] = options[documentType] || {};
        options[documentType][fieldId] = options[documentType][fieldId] || { inlineObject: {}, schema: {} };
        options[documentType][fieldId].inlineObject = { ...options[documentType][fieldId].inlineObject, ...payloadOptions };

        return { ...data, options };
      }
    },
    cancelOptions: {
      action: (documentType: string, fieldId: string) => ({ documentType, fieldId }),
      callback: (data: IDocumentOutputData, payload: { documentType: string; fieldId: string }) => {
        const { documentType, fieldId } = payload;
        const initialOptions = { ...data.initialOptions };
        initialOptions[documentType] = initialOptions[documentType] || {};
        initialOptions[documentType][fieldId] = initialOptions[documentType][fieldId] || { inlineObject: {}, schema: {} };
        const options = { ...data.options };
        options[documentType] = options[documentType] || {};
        options[documentType][fieldId] = { ...initialOptions[documentType][fieldId] };

        return { ...data, initialOptions, options };
      }
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { documentOutput: IDocumentOutputState }) => state.documentOutput,
  {
  }
);

const syncSelector = {
  options: (documentType: string, fieldId: string) => (state: { documentOutput: IDocumentOutputState }) => {
    if (isNull(documentType) || isNull(fieldId)) {
      return undefined;
    }

    const options = state.documentOutput.data.options;

    return options[documentType] && options[documentType][fieldId]
      && options[documentType][fieldId].inlineObject;
  },
  fieldValue: (documentType: string, fieldId: string) => (state: { documentOutput: IDocumentOutputState }) => {
    if (isNull(documentType) || isNull(fieldId)) {
      return undefined;
    }
    const options = state.documentOutput.data.options;

    return options[documentType] && options[documentType][fieldId]
      && options[documentType][fieldId].inlineObject && options[documentType][fieldId].inlineObject.Summary;
  },
  notifications: (state: { documentOutput: IDocumentOutputState }) => state.documentOutput.notifications,
  error: (state: { documentOutput: IDocumentOutputState }) => state.documentOutput.error,
};

export const selectors = { ...asyncSelector, ...syncSelector };
