import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Paper, Table,  TableBody, TableRow, TableCell } from '@material-ui/core';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import BillToIdActionList from '../../utils/data/db/actions/BillToIdActionList';
import { selectors as creditNoteSelectors } from 'ducks/creditNotes/creditNote';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '320px',
    maxWidth: '320px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '10px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '10px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '10px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '10px !important'
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '-5px'
  },
  centerPanel: {
    margin: '10px',
    padding: '10px'
  },
  headers: {
    fontSize: '10px',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    fontSize: '10px',
    color: '#000000',
  },
  table2DataCell: {
    fontSize: '12px',
    paddingLeft: '10px'
  },
  numericDataCell: {
    paddingRight: '10px'
  },
  table1DataRow: {
    height: '25px',
  },
  table1HeaderRow: {
    height: '15px',
  },
  table1TD: {
    '& td': {
      width: '60px',
      padding: '3px ',
      textAlign: 'center'
    }
  },
  creditStatusCell: {
    color: 'RED',
    padding: '5px 10px',
    height: '30px',
    boxSizing: 'border-box',
  },
  creditStatusCellHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconWarning: {
    'height': '24px',
    'width': '24px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  }
});

interface ICreditNoteHeader {
  CustomerId: { Value: number; Label: string; Display?: string; Description?: string };
  Warehouse: { Value: number; Label: string; Display?: string; Description?: string };
  Invoice: { Value: number; Label: string; Display?: string; Description?: string };
  EnterDate: { Value: string };
  CustomerCreditReason: { Label: string };
  Period: { Value: string; Label: string };
  CustomerPurchaseOrder: { Value: string; Label: string; Display?: string; Description?: string };
  Quote: { Value: string; Label: string };
  SalesEnquiry: { Value: string; Label: string; Display?: string; Description?: string };
  SalesEntity: { Value: string; Label: string; Display?: string; Description?: string };
  InvoiceTotal: { Value: number };
  RestockingCharge: { Value: number };
  Tax: { Value: number };
}

interface ISummaryPanelProperties extends StyledComponentProps {
  creditNoteHeader: ICreditNoteHeader;
}

interface ISummaryPanelState {
  open: boolean;
}

function customer(creditNoteHeader: ICreditNoteHeader, actionList: IActionListItem[], classes: any): React.ReactNode {
  return (
    <div className={classes.splitCellContainer} >
      <div className={classes.splitCellContent} >
        <span >{creditNoteHeader && creditNoteHeader.CustomerId && creditNoteHeader.CustomerId.Display}</span>
        <br />
        <span >{creditNoteHeader && creditNoteHeader.CustomerId && creditNoteHeader.CustomerId.Label}</span>
      </div>
      <div className={classes.splitCellAction} >
        {actionList && <InfoButton actionList={actionList}
          disabled={!(creditNoteHeader && creditNoteHeader.CustomerId)}
        />}
      </div>
    </div>);
}

function purchaseOrder(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && creditNoteHeader.CustomerPurchaseOrder ? creditNoteHeader.CustomerPurchaseOrder.Value : '';
}

function saleEntity(creditNoteHeader: ICreditNoteHeader): React.ReactNode {
  return (
    <span>
      <span>{creditNoteHeader && creditNoteHeader.SalesEntity ? creditNoteHeader.SalesEntity.Value : ''}</span>
      <br />
      <span>{creditNoteHeader && creditNoteHeader.SalesEntity ? creditNoteHeader.SalesEntity.Description : ''}</span>
    </span>
  );
}

function warehouse(creditNoteHeader: ICreditNoteHeader): React.ReactNode {
  return (
    <span>
      <span>{creditNoteHeader && creditNoteHeader.Warehouse ? creditNoteHeader.Warehouse.Value : ''}</span>
      <br />
      <span>{creditNoteHeader && creditNoteHeader.Warehouse ? creditNoteHeader.Warehouse.Description : ''}</span>
    </span>
  );
}

function reason(creditNoteHeader: ICreditNoteHeader): React.ReactNode {
  return creditNoteHeader && creditNoteHeader.CustomerCreditReason ? creditNoteHeader.CustomerCreditReason.Label : '';
}

function period(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && creditNoteHeader.Period ? creditNoteHeader.Period.Label : '';
}

function invoice(creditNoteHeader: ICreditNoteHeader): number {
  return creditNoteHeader && creditNoteHeader.Invoice ? creditNoteHeader.Invoice.Value : 0;
}

function enterDate(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && creditNoteHeader.EnterDate ? creditNoteHeader.EnterDate.Value : '';
}

function isNull(value?: any): boolean {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

function restockingChargeTotal(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && !isNull(creditNoteHeader.RestockingCharge) ? Number(creditNoteHeader.RestockingCharge.Value).toFixed(NumberOfDecimalPlaces) : '0.00';
}
function taxAmount(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && !isNull(creditNoteHeader.Tax) ? Number(creditNoteHeader.Tax.Value).toFixed(NumberOfDecimalPlaces) : '0.00';
}

function invoiceTotal(creditNoteHeader: ICreditNoteHeader): string {
  return creditNoteHeader && !isNull(creditNoteHeader.InvoiceTotal) ? creditNoteHeader.InvoiceTotal.Value.toFixed(NumberOfDecimalPlaces) : '0.00';
}

interface IActionListItem {
  id: string;
  label: string;
  actionParam?: any;
  action(...params: any[]): void;
}

const SubPanelElevation = 2;
const NumberOfDecimalPlaces = 2;

class SummaryPanelComponent extends React.PureComponent<ISummaryPanelProperties, ISummaryPanelState> {
  state: ISummaryPanelState = {
    open: false
  };

  toggleMenu = (event: Event) => {
    event.stopPropagation();
    this.setState(() => ({
      open: !this.state.open
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  appendParam = (actionList: IActionListItem[]): IActionListItem[] => {
    const { creditNoteHeader } = this.props;
    if (creditNoteHeader && creditNoteHeader.CustomerId && creditNoteHeader.CustomerId.Value) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: creditNoteHeader.CustomerId.Value, CustomerAccountNumber: creditNoteHeader.CustomerId.Display };

        return item;
      });
    }

    return actionList;
  }

  render(): React.ReactNode {
    const { classes, creditNoteHeader } = this.props;
    const actionList = BillToIdActionList;

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={SubPanelElevation} className={classes.centerPanel} square={true}>
              <p className={classes.subPanelTitle}>Credit note details</p>
              <Table className={classes.summaryTable}>
                <TableBody>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>CUSTOMER</TableCell>
                    <TableCell className={classes.dataCell}>{customer(creditNoteHeader, actionList ? this.appendParam(actionList) : null, classes)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>CUSTOMER P/ORDER</TableCell>
                    <TableCell className={classes.dataCell}>{purchaseOrder(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>REASON</TableCell>
                    <TableCell className={classes.dataCell}>{reason(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>ENTITY</TableCell>
                    <TableCell className={classes.dataCell}>{saleEntity(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>WAREHOUSE</TableCell>
                    <TableCell className={classes.dataCell}>{warehouse(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>PERIOD</TableCell>
                    <TableCell className={classes.dataCell}>{period(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>INVOICE</TableCell>
                    <TableCell className={classes.dataCell}>{invoice(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>DATE</TableCell>
                    <TableCell className={classes.dataCell}>{enterDate(creditNoteHeader)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <Paper elevation={SubPanelElevation} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>Totals</p>
              <Table className={classes.summaryTable}>
                <TableBody>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>RESTOCKING CHARGE</TableCell>
                    <TableCell numeric padding='none' className={classes.numericCell}>{restockingChargeTotal(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>TAX</TableCell>
                    <TableCell numeric padding='none' className={classes.numericCell}>{taxAmount(creditNoteHeader)}</TableCell>
                  </TableRow>
                  <TableRow className={classes.summaryRow}>
                    <TableCell className={classes.headingCell}>INVOICE TOTAL</TableCell>
                    <TableCell numeric padding='none' className={classes.totalCell}>{invoiceTotal(creditNoteHeader)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  creditNoteHeader: (creditNoteSelectors as any).creditNoteHeader(state)
});

const SummaryPanel = connect(mapStateToProps, {})(withStyles(inlineStyles, {index: 1})(SummaryPanelComponent));

export default SummaryPanel;
