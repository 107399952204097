import { connect } from 'react-redux';
import ProductPriceSchedule from './ProductPriceSchedule';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
// import { actions as invoiceLineActions, selectors as invoiceLineSelectors } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLines';
import { selectors as productPriceScheduleSelectors } from 'ducks/supplierProductEnquiry/productPriceSchedule';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  details: productPriceScheduleSelectors.details(state),
  settings: productPriceScheduleSelectors.settings(state),
  sellPrices: productPriceScheduleSelectors.sellPrices(state),
  comments: productPriceScheduleSelectors.comments(state),
  isLoading: productPriceScheduleSelectors.isLoading(state),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceSchedule);
