import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import InvoiceComponent from './InvoiceComponent';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as invoiceSelectors, actions as invoiceActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';
import { selectors as invoiceDetailsSelectors } from 'ducks/supplierInvoiceEnquiry/invoiceDetails';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedInvoice: invoiceSelectors.selected(state),
  selectedForm: formSelectors.selected(state),
  invoiceDetails: invoiceDetailsSelectors.invoiceDetails(state),
  invoiceComment: invoiceDetailsSelectors.comment(state),
  isLoading: invoiceDetailsSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getInvoiceSummary: invoiceActions.getSummary
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceComponent);
