import { IFormViewForm } from 'components/FormView';

const TransactionDetails: IFormViewForm = {
  id: 'TransactionDetails',
  layout: {
    rows: 2,
    columns: 2
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Allocated invoice',
            name: 'AllocatedInvoice',
            size: 'medium',
            api: '/salesOrderEnquiry/lookup/action',
            params: {
              queryFields: {
                SalesEntity: 'SalesEntity',
                InvoiceNumber: 'AllocatedInvoice'
              }
            }
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'G/L batch',
            name: 'GLBatch',
            size: 'small',
            api: '/salesOrderEnquiry/lookup/action'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference',
            name: 'Reference',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Due',
            name: 'DueDate',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Age',
            name: 'Age',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Exchange rate',
            name: 'ExhangeRateDisplay',
            size: 'medium',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Local original',
            name: 'LocalOriginalDisplay',
            size: 'medium',
            alignment: 'right',
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Local amount',
            name: 'LocalAmountDisplay',
            alignment: 'right',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Comments',
            name: 'Comments',
            fullWidth: true,
            style: {
              height: '360px'
            }
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Internal notes',
            name: 'InternalNotes',
            fullWidth: true,
            style: {
              height: '360px'
            }
          }
        }
      ]
    }
  ]
};
export default TransactionDetails;
