import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../utils';
export const fetch = async (query: { ProductMovementId: number; Page: number; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { ProductMovementId, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchGet(`/ProductMovementEnquiry/Stocking/LotDetails/BinLocations/Product/${ProductMovementId}`, { BatchPage: Page, BatchSize }, respType)
    .then(
      (result: {
        PMEBinLocations: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.PMEBinLocations) {

            return {
              records: result.PMEBinLocations.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
