import { combineReducers } from 'redux';
import alternates from './alternates';
import suppressions from './suppressions';
import BOM from './BOM';

const associationsReducer = combineReducers({
  alternates,
  suppressions,
  BOM
});

export default associationsReducer;
