import { IFormViewForm } from 'components/FormView';

const DeliveryDetailsForm: IFormViewForm = {
  id: 'DeliveryDetailsForm',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            size: 'small',
            lookupName: 'FreightCode',
            suppressDescription: true,
            hideInV2: true,
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Freight type',
            name: 'SendVia',
            size: 'medium',
            lookupName: 'FreightType',
            suppressValidation: true,
            suppressDescription: true,
          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Carrier',
            name: 'Carrier',
            suppressValidation: true,
            suppressDescription: true,
            size: 'medium',
            lookupName: 'CarrierCode',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Shipping instructions',
            name: 'Shipping',
            size: 'large',
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Picking',
            name: 'Picking',
            size: 'large',
          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        label: 'Deliver to'
      },
      children: [
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Warehouse',
            name: 'Warehouse',
            placeholder: '',
            size: 'small',
            required: false,
            lookupName: 'WarehouseEntity',
            isEntityScoped: true,
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Site',
            name: 'SiteCode',
            placeholder: '',
            size: 'small',
            required: false,
            lookupName: 'EntityDeliverySite',
            isEntityScoped: true,
          }
        },
        {
          id: '2',
          type: 'ADDRESS_BLOCK_EX',
          props: {
            label: '',
            name: 'DeliveryAddress',
            required: false,
            type: 'extended',
            hideCountry: true,
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Contact',
            name: 'DeliveryContact',
            placeholder: '',
            size: 'large',
            required: false,
          }
        },
        {
          id: 4,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Save delivery address',
            name: 'SaveDelivery',
          }
        },
      ]
    },
  ]
};

export default DeliveryDetailsForm;
