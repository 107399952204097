import { takeLatest, Effect, call, select, put } from 'redux-saga/effects';
import { types as serialNumberTypes, actions as serialNumberActions, selectors as serialNumberSelectors } from 'ducks/common/serialNumber';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/common/serialNumber';
import { isNull } from 'utils/utils';

function* fetchAvailableLines(action: any): IterableIterator<Effect> {
  const { success, failure } = serialNumberActions.saga.fetchAvailableLines;
  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    const selectedLines: any[] = yield select(serialNumberSelectors.getSelectedLines);
    const updated = response.map((serialNumber) => {

      const foundInSelected = selectedLines.find(({ SerialNumber }) => SerialNumber === serialNumber.SerialNumber);

      return { ...serialNumber, hide: !isNull(foundInSelected) };
    });
    yield put(success(updated));
  }

  yield callApi(
    call(api.fetchAvailableSerials, action.data),
    success,
    failure, { postApiSuccessCallEffect }
  );
}

function* saveSerialNumbers(action: any): IterableIterator<Effect> {
  const { success, failure } = serialNumberActions.saga.saveSerials;

  const selectedLines: any[] = yield select(serialNumberSelectors.getSelectedLines);

  const serials = [];
  selectedLines.forEach((line) => {
    serials.push(line.SerialNumber);
  });

  yield callApi(
    call(api.saveSerials, { ...action.data, serials }),
    success,
    failure
  );
}

export default function* rootSerialNumberSaga(): IterableIterator<Effect> {
  yield takeLatest(serialNumberTypes.fetchAvailableLines, fetchAvailableLines);
  yield takeLatest(serialNumberTypes.saveSerials, saveSerialNumbers);
  yield takeLatest(serialNumberTypes.fetchSelectedSerials, connectSagaToApi(serialNumberActions, 'fetchSelectedSerials', api.fetchSelectedSerial));
  yield takeLatest(serialNumberTypes.fetchSerialsSummary, connectSagaToApi(serialNumberActions, 'fetchSerialsSummary', api.fetchSerialSummary));
}
