interface IParamsAsObject {
  Supplier?: string;
}

function appendParams(path: string, params: (IParamsAsObject | string | number)): string {
  if (typeof (params) === 'string') {
    if (params) {
      const search = new URLSearchParams();
      search.append('SearchText', params);
      path += `?${search.toString()}`;
    }
  } else if (typeof(params) === 'number') {
    if (params) {
      const search = new URLSearchParams();
      search.append('SearchText', params.toString());
      path += `?${search.toString()}`;
    }
  } else if (typeof (params) === 'object') {
    if (params && (params.Supplier)) {
      const search = new URLSearchParams();
      search.append('Supplier', params.Supplier);
      path += `?${search.toString()}`;
    }
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Supplier Enquiry in new tab',
    action: (params: (IParamsAsObject | string | number)) => {window.open(appendParams('/supplier-enquiry/supplier-details', params)); }
  },
  {
    id: '2',
    label: 'Supplier Enquiry',
    action: (params: (IParamsAsObject | string | number)) => {window.location.assign(appendParams('/supplier-enquiry/supplier-details', params)); }
  }
];
