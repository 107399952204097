import * as React from 'react';
import ChartContainer from './ChartContainer';
import { IChartContainerProperties } from './Charts.properties';
import * as dashboardAPI from 'api/dashboardMaintenance/dashboardMaintenance';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IChartContainerProperties) => bindActionCreators(
    {
        resetConfigurationForm: () => reset(ownProps.modalFormProps.formSchema.id),
    },
    dispatch);

export default connect(null, mapDispatchToProps)((props: Readonly<Omit<
    IChartContainerProperties, 'setWidgetConfiguration'>>) => {
    return (
        <ChartContainer
            setWidgetConfiguration={dashboardAPI.setWidgetConfiguration}
            {...props} />
    );
});
