import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify, fetchGet, Inline } from '../utils';
import { APIMethod, Payload, prepareUrl, useDelete, useFetch, usePost, useUpdate, useFetchMutation } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { useQueryClient } from '@tanstack/react-query';
import {
  IDeleteRecordResponse, IExtractOutputFieldFacade, IExtractOutputFieldResponse, IExtractTemplateDetailFacade,
  IExtractTemplateDetailsResponse, IExtractSortFieldFacade, IExtractSortFieldsResponse, IExtractReportingAreasResponse, IReportingAreaFacade, IExtractAreaFieldsResponse, IValidatedResponse, IExtractFilterFacade, IExtractGetFilterResponse,
  IExtractFiltersResponse,
  IDDEGetExtractFilterCriteria
} from 'api/swaggerTypes';

export const search = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost('ExtractMaintenance/SearchTemplates', { SearchText, Sort, BatchPage, BatchSize })
    .then((response) => ({
      records: response.Templates.map((val) => ({ inlineObject: objectify(val), schema: val })),
      currPage: response.BatchInformation && response.BatchInformation.BatchPage,
      nextPage: response.BatchInformation && !response.BatchInformation.EndOfData,
      prevPage: response.BatchInformation && response.BatchInformation.BatchPage > 1
    }));
};

export const searchById = (TemplateId) => {
  if (TemplateId > 1) {
    const apiCall = fetchGet(`ExtractMaintenance/Template/${TemplateId}`, {}, 'Inline')
      .then((response) => ({
        records: [{ inlineObject: objectify(response.TemplateDetails), schema: response.TemplateDetails }],
        currPage: 1,
        nextPage: false,
        prevPage: false
      }));

    return apiCall;
  }

  return [];
};

const fetchTemplateResponseParser = (response): Promise<any> => {
  return {
    ...response,
    TemplateDetails: { inlineObject: objectify(response.TemplateDetails), schema: response.TemplateDetails },

  };
};

export const useFetchEMTemplate = (TemplateId: number) => {
  return useFetch<any>(
    {
      url: APIRoutes.extractMaintenance.template.retrieve,
      urlParams: { TemplateId },
      queryConfig: { enabled: !!TemplateId },
      options: { inline: true, responseParser: fetchTemplateResponseParser }
    }
  );
};

export const useSetEMTemplateQueryData = () => {
  const queryClient = useQueryClient();

  return (TemplateId: number, dataToUpdate: any) => {
    const finalUrl = prepareUrl(APIRoutes.extractMaintenance.template.retrieve, { TemplateId }, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

export const useFetchEMNewTemplate = () => {
  return useFetch<any>(
    {
      url: APIRoutes.extractMaintenance.template.new,
      options: { inline: true, responseParser: fetchTemplateResponseParser }
    }
  );
};

const saveTemplateResponseParser = (response): Promise<IExtractTemplateDetailsResponse> => {
  return {
    ...response,
    TemplateDetails: { inlineObject: objectify(response.TemplateDetails), schema: response.TemplateDetails },

  };
};

export const useSaveEMTemplate = () => {

  return usePost<Payload<{ TemplateId: number; query: IExtractTemplateDetailFacade }>, IExtractTemplateDetailsResponse>
    (APIRoutes.extractMaintenance.template.save, null, { inline: true, responseParser: saveTemplateResponseParser });
};

export const useDeleteEMTemplate = () => {

  return usePost<Payload<{ TemplateId: number; DeleteTemplate?: boolean }>, IDeleteRecordResponse>
    (APIRoutes.extractMaintenance.template.delete, null, { inline: false });
};

const templateOutputFieldsResponseParser = (response): Promise<IExtractOutputFieldResponse> => {
  const { OutputFields } = response;

  return {
    ...response,
    OutputFields: OutputFields.map((o) => objectify(o as Inline<IExtractOutputFieldFacade>))
  };
};

export const useFetchEMOutputFields = (TemplateId: number) => {
  return useFetch<IExtractOutputFieldResponse>(
    {
      url: APIRoutes.extractMaintenance.fields.retrieve,
      urlParams: { TemplateId },
      queryConfig: { enabled: !!TemplateId },
      options: { inline: true, responseParser: templateOutputFieldsResponseParser }
    }
  );
};

export const useUpdateEMOutputField = () => {

  return useUpdate<Payload<{ TemplateId: number; ExtractId: number; urlQueryParams: { FieldName: string; Label: string } }>, IExtractOutputFieldResponse>
    (APIRoutes.extractMaintenance.fields.update, null, { inline: true, responseParser: templateOutputFieldsResponseParser });
};

export const useReorderEMOutputFields = () => {

  return usePost<Payload<{ TemplateId: number; ExtractId: number; OutputFields: any }>, IExtractOutputFieldResponse>
    (APIRoutes.extractMaintenance.fields.reorder, null, { inline: true, responseParser: templateOutputFieldsResponseParser });
};

export const useSetEMOutputFieldsQueryData = () => {
  const queryClient = useQueryClient();

  return (TemplateId: number, dataToUpdate: any) => {
    const finalUrl = prepareUrl(APIRoutes.extractMaintenance.fields.retrieve, { TemplateId }, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

export const useDeleteEMOutputField = () => {

  return useDelete<Payload<{ TemplateId: number; ExtractId: number }>, IExtractOutputFieldResponse>(APIRoutes.extractMaintenance.fields.delete, null, { inline: true, responseParser: templateOutputFieldsResponseParser });
};
const ExtractSortFieldsResponseParser = (response) => {
  if (response) {
    return {
      ...response,
      SortFields: response?.SortFields?.map((o) => objectify(o as Inline<IExtractSortFieldFacade>))
    };
  }
};

export const useRetrieveEMTemplateSortFields = (TemplateId: number) =>
  useFetch<IExtractSortFieldsResponse>(
    {
      url: APIRoutes.extractMaintenance.template.extract.sortFields.retrieve,
      urlParams: { TemplateId },
      queryConfig: { enabled: !!TemplateId },
      options: { inline: true, responseParser: ExtractSortFieldsResponseParser }
    }
  );

export const useUpdateEMTemplateSortField = () => {

  return useUpdate<Payload<{ TemplateId: number; ExtractId: number; urlQueryParams: { SortDirection: string; SortProperty: string } }>,
    IExtractSortFieldsResponse>
    (
      APIRoutes.extractMaintenance.template.extract.sortFields.update,
      null,
      {
        inline: true,
        responseParser: ExtractSortFieldsResponseParser
      }
    );
};

export const useDeleteEMTemplateSortField = () => {

  return useDelete<Payload<{ TemplateId: number; ExtractId: number }>, IExtractSortFieldsResponse>
    (
      APIRoutes.extractMaintenance.template.extract.sortFields.delete,
      null,
      {
        inline: true,
        responseParser: ExtractSortFieldsResponseParser
      }
    );
};

export const useReorderEMTemplateSortFields = () => {

  return usePost<Payload<{ TemplateId: number; ExtractId: number; SortFields: any }>, IExtractSortFieldsResponse>
    (
      APIRoutes.extractMaintenance.template.extract.sortFields.reOrder,
      null,
      { inline: true, responseParser: ExtractSortFieldsResponseParser }
    );
};

export const useSetEMTemplateSortFieldsData = () => {
  const queryClient = useQueryClient();

  return (TemplateId: number, dataToUpdate: any) => {
    const finalUrl = prepareUrl(APIRoutes.extractMaintenance.template.extract.sortFields.retrieve, { TemplateId }, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

export const useFetchRAFields = () => useFetchMutation<Payload<{ TemplateId: number; urlQueryParams: { AreaName: string } }>, IExtractAreaFieldsResponse>(
  APIRoutes.extractMaintenance.reportingArea.fields,
  null,
  {
    inline: false
  }
);

const ExtractReportingAreasResponseParser = (response) => {
  if (response) {
    return {
      ...response,
      ReportingAreas: response?.ReportingAreas?.map((o) => objectify(o as Inline<IReportingAreaFacade>))
    };
  }
};

export const useRetrieveEMReportingAreas = (TemplateId: number) =>
  useFetch<IExtractReportingAreasResponse>(
    {
      url: APIRoutes.extractMaintenance.reportingArea.retrieveReportingAreas,
      urlParams: { TemplateId },
      queryConfig: { enabled: !!TemplateId },
      options: { inline: false, responseParser: ExtractReportingAreasResponseParser }
    }
  );

export const useSetReportingAreasData = () => {
  const queryClient = useQueryClient();

  return (TemplateId: number, dataToUpdate: any) => {
    const finalUrl = prepareUrl(APIRoutes.extractMaintenance.reportingArea.retrieveReportingAreas, { TemplateId }, null, false);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

export const useUpdateReportingAreas = () => {
  return usePost<Payload<{ TemplateId: number } & IReportingAreaFacade>, IExtractReportingAreasResponse>
    (
      APIRoutes.extractMaintenance.reportingArea.retrieveReportingAreas,
      null,
      { inline: false, responseParser: ExtractReportingAreasResponseParser }
    );
};

export const useEMTemplateStartEdit = () => {

  return usePost<Payload<{ TemplateId: number }>, IValidatedResponse>
    (APIRoutes.extractMaintenance.template.startEdit, null, { inline: true });
};

export const useAddExtractOutputFields = () => {

  return usePost<Payload<{ TemplateId: number; OutputFields: any; urlQueryParams: { AreaName: string } }>, IExtractOutputFieldResponse>
    (APIRoutes.extractMaintenance.fields.add, null, { inline: true, responseParser: templateOutputFieldsResponseParser });
};

export const useAddSort = () => {

  return usePost<Payload<{ TemplateId: number; query: IExtractSortFieldFacade; urlQueryParams: { AreaName: string } }>, IExtractTemplateDetailsResponse>
    (APIRoutes.extractMaintenance.addSort.save, null, { inline: true });
};

const fetchFilterFieldResponseParser = (response): Promise<any> => {
  return {
    ...response,
    ExtractFilter: { inlineObject: objectify(response.ExtractFilter), schema: response.ExtractFilter },

  };
};
export const useRetrieveFilterField = () => usePost<Payload<{ TemplateId: number; FilterId?: string } & IDDEGetExtractFilterCriteria>, any>(
  APIRoutes.extractMaintenance.addFilter.retrieve,
  null,
  {
    inline: true,
    responseParser: fetchFilterFieldResponseParser
  }
);

export const useAddFilter = () => {

  return usePost<Payload<{ TemplateId: number; query: IExtractFilterFacade; urlQueryParams: { AreaName: string } }>, IExtractGetFilterResponse>
    (APIRoutes.extractMaintenance.addFilter.update, null, { inline: true });
};

export const useEMDiscardTemplate = () => {

  return usePost<Payload<{ TemplateId: number }>, IValidatedResponse>
    (APIRoutes.extractMaintenance.template.discard, null, { inline: true });
};

export const useEMCommitTemplate = () => {

  return usePost<Payload<{ TemplateId: number }>, IValidatedResponse>
    (APIRoutes.extractMaintenance.template.commit, null, { inline: true });
};

const ExtractFiltersResponseParser = (response) => {
  if (response) {
    return {
      ...response,
      ExtractFilters: response?.ExtractFilters?.map((o) => objectify(o as Inline<IExtractFilterFacade>))
    };
  }
};

export const useRetrieveEMFilters = (TemplateId: number) =>
  useFetch<IExtractFiltersResponse>(
    {
      url: APIRoutes.extractMaintenance.template.extract.filters.retrieve,
      urlParams: { TemplateId },
      queryConfig: { enabled: !!TemplateId },
      options: { inline: true, responseParser: ExtractFiltersResponseParser }
    }
  );

export const useDeleteEMFilter = () => {

  return useDelete<Payload<{ TemplateId: number; ExtractId: number; FilterId: string }>, IExtractFiltersResponse>
    (
      APIRoutes.extractMaintenance.template.extract.filters.delete,
      null,
      {
        inline: true,
        responseParser: ExtractFiltersResponseParser
      }
    );
};

export const useSetEMFiltersData = () => {
  const queryClient = useQueryClient();

  return (TemplateId: number, dataToUpdate: any) => {
    const finalUrl = prepareUrl(APIRoutes.extractMaintenance.template.extract.filters.retrieve, { TemplateId }, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};
