import { createActions, asyncInitialState, asyncOnRequest, asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, SuccessAction, DataAction } from '../utils';
import { pathOr } from 'utils/utils';

export interface ICreditNoteContextData {
  creditNoteContext: any;
}

export interface ICreditNoteContextState extends IExtendedState<ICreditNoteContextData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      creditNoteContextLoading: () => null,
    },
    getCreditNoteContext: () => null,
    updateCreditNoteContext: (data) => (data),
    clearCreditNoteContext: (data) => data,
    parkCreditNote: () => null
  },
  'creditNoteContext');

const initialState = asyncInitialState<ICreditNoteContextData>({
  creditNoteContext: {
  },
});

export default (state: ICreditNoteContextState = initialState, action: DataAction): ICreditNoteContextState => {
  switch (action.type) {
    case types.creditNoteContextLoading:
      return asyncOnRequest(state, action);
    case types.saga.creditNoteContextLoading.success:
      return asyncOnSuccess(state, action, (data: ICreditNoteContextData, successAction: SuccessAction) => {
        return {
          ...data,
          creditNoteContext: successAction.payload.CreditNoteContext,
        };
      });
    case types.saga.creditNoteContextLoading.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors((state: { creditNotes: { creditNoteContext: ICreditNoteContextState } }) => state.creditNotes.creditNoteContext, {
    creditNoteContext: (data: ICreditNoteContextData) => data.creditNoteContext,
    customerId: (data: ICreditNoteContextData) => pathOr('', ['creditNoteContext', 'Customer', 'Value'], data)
  }),

  creditNoteContextLoading: (state: { creditNotes: { creditNoteContext: ICreditNoteContextState } }) => state.creditNotes.creditNoteContext.loading,
  creditNoteContextFetched: (state: { creditNotes: { creditNoteContext: ICreditNoteContextState } }) => state.creditNotes.creditNoteContext.fetched,
  creditNoteContextError: (state: { creditNotes: { creditNoteContext: ICreditNoteContextState } }) => state.creditNotes.creditNoteContext.error,
  creditNoteContextNotifications: (state: { creditNotes: { creditNoteContext: ICreditNoteContextState } }) => state.creditNotes.creditNoteContext.notifications
};
