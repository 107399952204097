import { Api } from '../baseApi';

interface ILookupResponse {
  'LookupType'?: 'Fixed' | 'Dynamic';
  'ValueType'?: 'Int32' | 'String' | 'Decimal';
  'Data'?: ILookupOption[];
  'Scope'?: string;
}

export interface ILookupOption {
  'Code'?: string;
  'Display'?: string;
  'Label'?: string;
  'Description'?: string;
  'Details'?: {
    [key: string]: any;
  };
}

interface IQuery {
  SearchText: string;
  lookupName: string;
  BatchPage: number;
  BatchSize: number;
}

export const fetchLookupData = (query: IQuery) => {
  const { lookupName, ...restQuery } = query;

  return Api.post<{ SearchText: string; BatchPage: number; BatchSize: number }, ILookupResponse>(`CustomTypes/Lookup/${lookupName}/Search`, restQuery)
    .then((response) => {
      return response.Data;
    });
};
export const fetchLookupOption = (query: { lookupName?: string; value: string }) => {
  const { lookupName, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/Lookup/${lookupName}/Option/${value}`);
};

export const fetchCustomerLookupOption = (query: { lookupName?: string; value: unknown; CustomerId: number }) => {
  const { lookupName, CustomerId, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/Customer/${CustomerId}/Lookup/${lookupName}/Option/${value}`);
};
export const fetchWorkSaleLookupOption = (query: { lookupName?: string; value: unknown; WorksaleId: string }) => {
  const { lookupName, WorksaleId, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/Worksale/${WorksaleId}/Lookup/${lookupName}/Option/${value}`);
};
export const fetchProductLookupOption = (query: { lookupName?: string; value: unknown; ProductId: string }) => {
  const { lookupName, ProductId, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/Product/${ProductId}/Lookup/${lookupName}/Option/${value}`);
};
export const fetchEntityLookupOption = (query: { lookupName?: string; value: unknown; SalesEntity: string }) => {
  const { lookupName, SalesEntity, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/SalesEntity/${SalesEntity}/Lookup/${lookupName}/Option/${value}`);
};
export const fetchPurchaseOrderLookupOption = (query: { lookupName?: string; value: unknown; Supplier: string }) => {
  const { lookupName, Supplier, value } = query;

  return Api.get<ILookupOption>(`CustomTypes/Supplier/${Supplier}/Lookup/${lookupName}/Option/${value}`);
};
