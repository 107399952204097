import React from 'react';
import SelectContainer from 'components/SelectContainer';
import { Paper } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
const inlineStyles = createStyles({
    container: {
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px 20px 20px',
        width: 400
    }
});

interface IPostRosterProps {
    entity: string;
    rosterPeriod: string;
    period: number;
    rosterPeriodEnd: string;
    onRosterDateChange(value: string): void;
    onPeriodChange(value: number): void;
    onOptionsUpdate(): void;
}

const PostRosterModal = ({ entity, rosterPeriod, period, rosterPeriodEnd, onRosterDateChange, onPeriodChange, onOptionsUpdate }: IPostRosterProps) => {
    const dayStrEnd = 3;

    return (
        <div>
            <Paper style={inlineStyles.container} square={true}>
                <div style={{ width: '100%', height: '75px', minHeight: '75px', marginBottom: '5px', backgroundColor: '#E8E8E8' }}>
                    <p style={{ padding: '3% 5% 5px 5%', fontSize: '12px' }}>
                        Instructions
                    </p>
                    <p style={{ padding: '0% 5% 3% 5%', fontSize: '12px' }}>
                        Please select the date you want to start posting a roster from and how long it should last.
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ paddingRight: '20%' }}> <SelectContainer
                        label={'Starting'}
                        size={'small'}

                        apiPrefs={({
                            LookupType: 'Dynamic',
                            path: '/CustomTypes/Lookup/RosterPeriod/Search',
                            method: 'POST',
                        })}
                        value={rosterPeriod}
                        params={{ Entity: entity }}
                        onChange={onRosterDateChange}
                        onOptionsUpdate={onOptionsUpdate}
                    /> </div>
                    <SelectContainer
                        label={'Duration'}
                        size={'small'}
                        options={
                            [
                                { value: 1, label: '1 Week' },
                                { value: 2, label: '2 Weeks' },
                                { value: 3, label: '3 Weeks' },
                                { value: 4, label: '4 Weeks' },
                                { value: 5, label: '5 Weeks' },
                                { value: 6, label: '6 Weeks' },
                                { value: 7, label: '7 Weeks' },
                                { value: 8, label: '8 Weeks' },
                                { value: 9, label: '9 Weeks' },
                                { value: 10, label: '10 Weeks' },
                                { value: 11, label: '11 Weeks' },
                                { value: 12, label: '12 Weeks' },
                            ]
                        }
                        value={period}
                        onChange={onPeriodChange}
                        onOptionsUpdate={onOptionsUpdate}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <SelectContainer
                        label={'Finishing'}
                        size={'small'}
                        disabled
                        options={[{ value: 1, label: `${moment(rosterPeriodEnd, 'DD/MM/YYYY').format('dddd').substring(0, dayStrEnd)} ${rosterPeriodEnd}` }]}
                        value={1}
                        params={{ Entity: entity }}
                        onOptionsUpdate={onOptionsUpdate}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default withStyles(inlineStyles, {index: 1})(PostRosterModal);
