import { ISummaryTableDataNode } from 'components/common/SummaryTable/SummaryTable.properties';

const schema: ISummaryTableDataNode[] = [
    {
        objectName: 'documentDetails',
        isLoading: 'documentDetailsLoading',
        title: 'Document details',
        children: [
            {
                label: 'Customer',
                fieldNames: [
                    'CustomerIdDisplay',
                    'CustomerIdDisplayLabel'
                ],
                valueRenderer: {
                    api: {
                        api: '/customermaintenance/lookup/action',
                        name: 'CustomerId',
                        params: {
                            queryFields: {
                                CustomerId: 'CustomerId',
                                SearchText: ''
                            }
                        }
                    }
                }
            },
            {
                label: 'Customer P/Order',
                fieldNames: [
                    'CustomerPurchaseOrder'
                ],
            },
            {
                label: 'Entity',
                fieldNames: [
                    'SalesEntity',
                ],
            },
            {
                label: 'S/Order',
                fieldNames: [
                    'SalesOrder'
                ],
            }

        ]
    },
];

export default schema;
