import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
    fetch: ({ ProductId }) => ({ ProductId })
  }
}, 'quantities');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'StockingQuantitiesPurchaseOrder',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
    enableMasterDetailModel: true,
    masterDetailsForm: 'QuantitiesForm',
    disableExcelExport: false
  },
  Columns: [
    { headerName: 'Warehouse', field: 'WarehouseLabel', minWidth: 150 },
    { headerName: 'On hand', field: 'OnHandQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Allocated', field: 'AllocatedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Available', field: 'AvailableQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', suppressSorting: true },
  ],
  Data: [],
  detailCellRenderer: 'QuantitiesForm',
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          Data: linesData,
          selected: null
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.quantities,
  {
    data: data => data.Data,

  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.quantities.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.quantities.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.quantities.data.Columns,
  detailCellRenderer: state => state.inventoryEnquiry.stocking.quantities.data.detailCellRenderer,
  selected: state => state.inventoryEnquiry.stocking.quantities.data.selected
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)