import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './BackorderCommitment.actions';
import reducer, { initialState, IState } from './BackorderCommitment.reducer';

export const BackorderCommitmentStateContext = createContext<IState>(initialState);
export const WorksaleDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const useBOCDispatch = () => useContext(WorksaleDispatchContext);
export const useBOCState = () => useContext(BackorderCommitmentStateContext);
export const useBOCContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(BackorderCommitmentStateContext, selector);

interface IBackorderCommitmentStateProvider {
  children: React.ReactNode;
}

const BackorderCommitmentStateProvider = ({ children }: IBackorderCommitmentStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <BackorderCommitmentStateContext.Provider value={state}>
      <WorksaleDispatchContext.Provider value={dispatch}>
        {children}
      </WorksaleDispatchContext.Provider>
    </BackorderCommitmentStateContext.Provider>
  );
};

export default BackorderCommitmentStateProvider;
