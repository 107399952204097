import * as React from 'react';
import { getFormValues, isValid } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Paper, Button } from '@material-ui/core';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import DynamicFormView from '../FormView/DynamicFormView';
import { dragExpandIcon, customerMaintenanceDrawerBackground } from '../../themes/colors';
import Scrollbars from 'react-custom-scrollbars';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { selectors as detailSelectors } from 'ducks/priceCheck/details';
import { selectors as staffSelectors } from 'ducks/staff';
import { actions as filterActions } from 'ducks/common/filters';
import { IStaff } from 'api/login';
import { IGetPriceDetailsRequest } from 'api/priceCheck/details';
import './LookUpPannel.css';
import { CheckCircle as CheckCircleIcon } from '@markinson/uicomponents-v2/SvgIcons';

const inlineStyles = createStyles({
  openedDragController: {
    left: '300px',
    width: '10px',
    backgroundColor: '#F47920',
    cursor: 'pointer'
  },
  dragIcon: {
    color: dragExpandIcon,
    width: '20px',
    height: '20px',
    marginLeft: '-5px',
    cursor: 'pointer'
  },
  closedDragIcon: {
    color: dragExpandIcon,
    width: '30px',
    height: '30px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
  lookupContainerStyle: {
    top: '0',
    backgroundColor: customerMaintenanceDrawerBackground,
    height: 'calc(100% - 67px)',
    boxShadow: 'none',
    width: 300,
    position: 'relative',
    borderRight: '2px solid #999999'
  },
  recallLookupContainerStyle: {
    width: 'calc(100% - 20px)',
    padding: '10px',
    paddingTop: '0px',
    boxSizing: 'border-box',
    margin: '10px',
  },
  lookupFieldsPaperContainer: {
    margin: 0,
    marginBottom: '16px'
  },
  topLookupFields: {
    width: '100%',
    minWidth: '200px'
  },
  expansionListContainer: {
    marginBottom: '10px',
    height: 'calc(100% - 185px)'
  },
  commitActionPaperContainer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '60px',
    width: '100%',
    backgroundColor: '#ebebeb'
  },
  commitActionButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    top: '90px',
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  panelHeading: {
    color: '#000000',
    fontSize: '15px',
    paddingTop: '10px'
  },
  formWrapper: {
    'padding': 0,
    '& > div': {
      padding: '10px',
    },
    '& > div > form > div > div': {
      maxWidth: '100%',
      minWidth: '280px',
      flexBasis: '100%',
      marginBottom: '40px',
    }
  }
});

const formLayout = {
  id: 'FiltersDrawer',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 0.75
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        square: true,
        style: inlineStyles.lookupFieldsPaperContainer
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Product',
            name: 'ProductId',
            lookupName: 'Product',
            size: 'medium',
            required: true,
            disabled: false,
            suppressDescription: true,
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Customer',
            name: 'CustomerId',
            lookupName: 'Customer',
            size: 'medium',
            required: false,
            disabled: false,
            suppressDescription: true,
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Sale quantity',
            name: 'SaleQuantity',
            size: 'medium',
            disabled: false,
            decimalSeparator: true,
            type: 'number',
            required: true,
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Sales entity',
            name: 'SalesEntity',
            lookupName: 'SalesEntity',
            required: true,
            size: 'small',
            suppressDescription: true,
          }
        },
        {
          id: '4',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Warehouse',
            name: 'Warehouse',
            lookupName: 'WarehouseEntity',
            size: 'small',
            suppressDescription: true,
            required: true,
          }
        },
        {
          id: '5',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Customer price code',
            name: 'CustomerPriceCode',
            lookupName: 'CustomerPriceCode',
            required: false,
            size: 'small',
            suppressDescription: true,
          }
        },
        {
          id: 6,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Price category',
            name: 'PriceCategory',
            isLookupTypeFixed: true,
            lookupName: 'PriceCategory',
            placeholder: '',
            size: 'small',
            required: false,
            suppressDescription: true,
          }
        },
        {
          id: 7,
          type: 'DATE_FIELD',
          props: {
            label: 'Effective',
            name: 'EffectiveDate',
            required: true,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            nullable: false,
            size: 'small',
            style: {
              display: 'inline-block'
            }
          }
        }
      ]
    }
  ]
};

interface ISetFiltersParams {
  formName: string;
  filters: any;
}

interface IPriceCheckLookupPanelProperties extends StyledComponentProps {
  staff: IStaff;
  filters: any;
  isValid: boolean;
  customerPriceCode: any;
  onApply(filters: any): void;
  setFilters(params: ISetFiltersParams): void;
  getCustomerPriceSummary(args: any): void;
}

interface IPriceCheckLookupPanelState {
  filters: IGetPriceDetailsRequest;
}

function getLayoutSchema(formSchema: any): any[] {
  const { rows, columns } = formSchema.layout;
  const { fields } = formSchema;
  const layout = [];
  for (let i = 1; i <= rows; i++) {
    const rowItems = [];
    for (let j = 1; j <= columns; j++) {
      const colItems = [fields.filter((field) => {
        return field.position.row === i && field.position.col === j;
      })];
      rowItems.push(colItems);
    }
    layout.push(rowItems);
  }

  return layout;
}

class LookupPanel extends React.PureComponent<IPriceCheckLookupPanelProperties, IPriceCheckLookupPanelState> {

  constructor(props: Readonly<IPriceCheckLookupPanelProperties>) {
    super(props);
    this.state = {
      filters: {
        CustomerId: null,
        CustomerPriceCode: null,
        EffectiveDate: moment().format('DD/MM/YYYY'),
        PriceCategory: null,
        ProductId: null,
        SaleQuantity: 1,
        SalesEntity: props.staff.defaultSalesEntity,
        Warehouse: props.staff.defaultWarehouseEntity
      }
    };
  }

  componentDidMount(): void {
    const { setFilters, onApply } = this.props;
    const params = new URLSearchParams(location.search);
    const WarehouseEntity = params.get('WarehouseEntity');
    const SalesEntity = params.get('SalesEntity');
    const CustomerId = Number(params.get('CustomerId'));
    const ProductId = Number(params.get('ProductId'));
    const SaleQuantity = Number(params.get('SalesQuantity'));
    const filters: IGetPriceDetailsRequest = {
      Warehouse: WarehouseEntity,
      SalesEntity,
      CustomerId,
      ProductId,
      SaleQuantity,
      EffectiveDate: moment().format('DD/MM/YYYY'),
      PriceCategory: null,
      CustomerPriceCode: null,
    };
    if (WarehouseEntity && SalesEntity && ProductId && SaleQuantity) {
      setFilters({ formName: 'PriceCheckFilters', filters: filters });
      this.setState({ filters }, () => { onApply(filters); }
      );
    }
  }

  componentDidUpdate(prevProps: any): void {
    const { filters, customerPriceCode } = this.props;
    if ((prevProps.customerPriceCode || customerPriceCode) && customerPriceCode && (!prevProps.customerPriceCode || customerPriceCode !== prevProps.customerPriceCode)) {
      const updatedFilters: IGetPriceDetailsRequest = {
        ...filters,
        CustomerPriceCode: customerPriceCode && customerPriceCode.Value,
      };
      this.setState({ filters: updatedFilters });
    }
  }

  onLookupApply = () => {
    if (this.props.isValid) {
      this.props.onApply(this.props.filters);
      this.props.setFilters({ formName: 'PriceCheckFilters', filters: this.props.filters });
    }
  }

  render(): React.ReactNode {
    const { classes } = this.props;
    const { filters } = this.state;
    const layout = getLayoutSchema(formLayout);

    return (
      <div>
        <Paper style={inlineStyles.lookupContainerStyle} square={true}>
          <Scrollbars>
            <div className={classes.formWrapper}>
              <DynamicFormView
                formName={'PriceCheckFilters'}
                layout={layout}
                initialValues={filters}
                enableReinitialize={true}
                operationMode={'new'}
                style={{ padding: 0 }}
              />
            </div>
          </Scrollbars>
          <Paper style={inlineStyles.commitActionPaperContainer} square={true}>
            <div className={classes.commitActionButton}>

            </div>
            <Button
              onClick={this.onLookupApply}
              variant={'contained'}
              className={classes.commitActionButton}>
              <CheckCircleIcon style={{ color: 'green' }} /> APPLY
            </Button>
          </Paper>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: uiSelectors.isSalesProcessingLookupPanelOpen(state),
  filters: getFormValues('PriceCheckFilters')(state),
  isLoading: detailSelectors.isLoading(state),
  staff: staffSelectors.staff(state),
  isValid: isValid('PriceCheckFilters')(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onToggle: uiActions.toggleSalesProcessingLookupPanel,
    setFilters: filterActions.applyFilters,
  },
  dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(inlineStyles, { index: 1 })(LookupPanel)));
