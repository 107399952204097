export default [
  {
    id: '1',
    label: 'G/L Batch Enquiry in new tab',
    'action': (params) => {window.open('/gl-batch-enquiry' + (params ? '/' + params : '' ))}
  },
  {
    id: '2',
    label: 'G/L Batch Enquiry',
    'action': (params) => {window.location.pathname = '/gl-batch-enquiry' + (params ? '/' + params : '' )}
  }
]
