// tslint:disable: cyclomatic-complexity
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import { Phone, Mail } from '@markinson/uicomponents-v2/SvgIcons';
import FrameWorkRenderer from './FrameWorkRenderers';
import { CircularProgressSize } from '../../../utils/constants';
import { isNull } from 'utils/utils';
import classNames from 'classnames';
import { ISummaryTableProperties } from './SummaryTable.properties';
import { styles } from './SummaryTable.styles';
import { renderPopup } from './PopUp/PopUp';

const SUMMARY_TABLE_PAPER_ELEVATION = 2;
const DEFAULT_DECIMAL_PLACES = 2;
const ACTION_ICONS = {
  Phone: Phone,
  Mail: Mail
};

// The use of '& any' is because of the dynamic lookup of properties from the JSON data.
class SummaryTable extends React.PureComponent<ISummaryTableProperties & any> {

  componentDidMount(): void {
    const { data } = this.props;
    if (data) {
      data.forEach((section) => {
        if (section.hasOwnProperty('children')) {
          section.children?.forEach((property) => property.valueRenderer
            && property.valueRenderer.api
            && property.valueRenderer.api.api
            && property.valueRenderer.api.name
            && this.props.fetchLookupOptions({ api: property.valueRenderer.api.api, name: property.valueRenderer.api.name }));
        }
      });
    }
  }

  numberWithCommas = (num: number) => {
    return !isNaN(num) &&
      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  handleFloats = (num: any, property: any) => {
    return !isNaN(num) &&
      parseFloat(num)
        .toFixed(
          (property.valueRenderer && !isNaN(property.valueRenderer.floatingPoints) && property.valueRenderer.floatingPoints) ||
          DEFAULT_DECIMAL_PLACES);
  }
  render(): React.ReactNode {
    const { classes, data, lookUpOptions, className = '', inSummaryPanel, customChildren } = this.props;

    const Container = ({ isPaper, children, ...rest }) => isPaper ? <Paper elevation={SUMMARY_TABLE_PAPER_ELEVATION} className={classNames(`${classes.paper} ${className}`, {
      [classes.isInSummaryPanel]: inSummaryPanel
    })} {...rest}>{children}</Paper> : <div {...rest}>{children}</div>;

    return (
      <Container isPaper={!inSummaryPanel}>
        {data && data.map((item, index) => {
          const itemData = this.props[item.objectName] || {};
          const CustomChildrenComponent = customChildren?.[item.componentName];

          return (
            <Container key={`subPanelWrapper${index} `} isPaper={inSummaryPanel}>
              {item.title && <p key={`subPanelTitle${index} `} className={classes.subPanelTitle}>{item.title}</p>}
              {this.props[item.isLoading]
                ? (<div key={`preLoader${index} `} className={data.indexOf(item) === data.length - 1
                  ? classes.lastPreLoaderParent
                  : classes.preLoaderParent}>
                  <CircularProgress
                    size={CircularProgressSize}
                    color={'secondary'}
                    variant={'indeterminate'} />
                </div>)
                : (<div key={`tableWrapper${index} `}>
                  {item.label && <p className={classes.subPanelTitle}>{item.label}</p>}
                  < Table key={`table${index} `} className={
                    classNames(item.className, {
                      [classes.lastTable]: data.indexOf(item) === data.length - 1,
                      [classes.table]: data.indexOf(item) !== data.length - 1,
                    })} >
                    <TableBody>
                      {item.componentName ? <CustomChildrenComponent {...itemData} />
                        : item.children.map((property, i) => {
                          const actionList: any = this.getActionList(property, lookUpOptions, item);
                          const valueRenderer = property.valueRenderer || {};
                          const ActionIconComponent = valueRenderer.action
                            && valueRenderer.action.iconName && ACTION_ICONS[valueRenderer.action.iconName];

                          return (
                            <TableRow key={`property${index}${i}`} className={`${classes.tableRow} summary-table-row`}>
                              <TableCell key={`label${index}${i}`} className={`${valueRenderer.matchLabelCase ? classes.propertyCellMatchCase : classes.propertyCell} summary-table-label-cell`}>
                                {property.label}
                                {
                                  property?.extendedText?.displayText &&
                                  <p>
                                    {itemData[property?.extendedText?.apiFlagName] ? property?.extendedText?.texts[0] : property?.extendedText?.texts[1]}
                                  </p>
                                }
                                {
                                  renderPopup(property, itemData, 'label')
                                }
                              </TableCell>
                              <TableCell
                                key={`valueCell${index}${i} `}
                                numeric={(valueRenderer.isNumeric || valueRenderer.isNumericDisplay)}
                                className={`${valueRenderer.emphasize ? classes.lastValueCell : classes.valueCell} summary - table - value - cell`}
                                style={{ padding: '10px', }}>
                                <div className={(valueRenderer.isNumeric || valueRenderer.isNumericDisplay) ? classes.numericOuter : classes.outer}>
                                  {(valueRenderer.isNumeric && valueRenderer.api) ?
                                    <div key={`numericInfo${index}${i} `} className={classes.numericSplitCellAction} >
                                      <InfoButton actionList={(actionList && actionList.Options) || []} />
                                    </div>
                                    : null
                                  }
                                  <div key={`wrapper${index}${i} `} className={classes.splitCellContent}>
                                    {property.fieldNames.map((field, j) =>
                                      <div key={`fieldWrapper${index}${i}${j} `}>
                                        {valueRenderer.preSymbol || ''}
                                        {(property.frameWorkRenderers
                                          && property.frameWorkRenderers[field]
                                          && <FrameWorkRenderer key={`frameworkComponent${index}${i}${j} `} type={property.frameWorkRenderers[field]} value={this.props[item.objectName][field]} />)
                                          || <span key={`field${index}${i}${j} `} className={classes.value}>
                                            {itemData
                                              && (
                                                (
                                                  valueRenderer.floatingNumber
                                                  && this.handleFloats(itemData[field], property)
                                                  ||
                                                  valueRenderer.commaSeparated
                                                  && this.numberWithCommas(itemData[field])
                                                )
                                                || itemData[field]
                                              )}
                                          </span>
                                        }
                                        {valueRenderer.postSymbol || ''}
                                      </div>
                                    )}
                                  </div>
                                  {(!valueRenderer.isNumeric && valueRenderer.api) ?
                                    <div key={`info${index}${i} `} className={classes.splitCellAction} >
                                      <InfoButton actionList={(actionList && actionList.Options) || []} />
                                    </div>
                                    : null
                                  }
                                  {(ActionIconComponent && itemData[property.fieldNames[0]]) ?
                                    <div key={`action${index}${i} `} className={classes.splitCellAction} >
                                      <a href={`${valueRenderer.action.iconName === 'Phone' ? 'tel' : 'mailto'}:${itemData[property.fieldNames[0]]} `}><ActionIconComponent className={classes.actionIcon} /></a>
                                    </div>
                                    : null
                                  }
                                  {
                                    renderPopup(property, itemData, 'value')
                                  }
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }
                    </TableBody>
                  </Table>
                </div>)

              }

            </Container>
          );
        })
        }
      </Container>
    );
  }

  private getActionList(property: any, lookUpOptions: any, item: any): any {
    if (!property.valueRenderer || !property.valueRenderer.api || !lookUpOptions) {
      return null;
    }

    const actionList = lookUpOptions[`${property.valueRenderer.api.api}/${property.valueRenderer.api.name}`];
    if (!actionList) {
      return actionList;
    }

    if (!property.valueRenderer.api.params || !property.valueRenderer.api.params.queryFields) {
      return actionList;
    }

    const actionListParams = {};
    const queryFields = property.valueRenderer.api.params.queryFields;
    const fieldData = this.props[item.objectName];
    for (const field in queryFields) {
      if (queryFields.hasOwnProperty(field) && fieldData && fieldData.hasOwnProperty(queryFields[field])) {
        if (queryFields[field] && fieldData[queryFields[field]]) {
          actionListParams[field] = fieldData[queryFields[field]];
        }
      }
    }

    if (isNull(actionListParams)) return null;

    actionList.Options = actionList.Options.map((actionListItem: any) => ({ ...actionListItem, actionParam: actionListParams }));

    return actionList;
  }
}

export default withStyles(styles, { index: 1 })(SummaryTable);
