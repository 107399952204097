import { IFormViewForm } from 'components/FormView';

const AddFilterDialogForm: IFormViewForm = {
  id: 'AddFilterDialogForm',
  layout: {
    rows: 1,
    columns: 1,
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        elevation: 0,
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Filter by',
            name: 'FilterField',
            size: 'medium',
            disabled: true,
          }
        },
        {
            group: {
                style: {
                  flexWrap: 'wrap',
                },
                childern: [
                    {
                        id: 1,
                        type: 'SELECT_FIELD',
                        visible: true,
                        props: {
                        label: 'Condition',
                        name: 'Operator',
                        placeholder: '',
                        required: true,
                        size: 'medium',
                        apiPrefs: {
                            LookupType: 'Fixed',
                            path: '/CustomTypes/Lookup/ExtractFilterOperator/Search',
                            method: 'POST'
                          },
                        }
                    },
                    {
                        id: 1,
                        type: 'TEXT_FIELD',
                        visible: true,
                        props: {
                            label: 'Value 1',
                            name: 'Value1',
                            placeholder: '',
                            size: 'medium',
                            required: true,
                        }
                    },
                    {
                        id: 1,
                        type: 'TEXT_FIELD',
                        visible: true,
                        props: {
                            label: 'Value 2',
                            name: 'Value2',
                            placeholder: '',
                            size: 'medium',
                            required: true,
                            disabled: true,
                        }
                    },
                ]
            }
        },
      ]
    },
  ]
};

export default AddFilterDialogForm;
