import {
    asyncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {

    ISortOrder,
    IJobtemplateDetailFacade
} from 'api/swaggerTypes';
import {
    IObjectified,
    Inline
} from 'api/utils';
import { isNull } from 'utils/utils';

export interface IJobTemplateData {
    jobTemplateData: { inlineObject: IJobtemplateDetailFacade; schema: Inline<IJobtemplateDetailFacade> };
    selected: string;
    list: IJobtemplateDetailFacade[];
    schemas: Inline<IJobtemplateDetailFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IJobTemplateState extends IExtendedState<IJobTemplateData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
    fetchJobTemplateData_loading?: boolean;
    create_loading?: boolean;
    update_loading?: boolean;
    delete_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: IJobTemplateData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    jobTemplateData: { inlineObject: {}, schema: {} },
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'jobTemplate',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (templateCode: string) => templateCode,
            callback: (data: IJobTemplateData, templateCode: string): IJobTemplateData => ({ ...data, selected: templateCode })
        },
        reset: {
          action: () => null,
          callback: (data: IJobTemplateData): IJobTemplateData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
            callback: (data: IJobTemplateData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IJobTemplateData, payload: { records: IObjectified<IJobtemplateDetailFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IJobTemplateData => {
                const jobTemplates = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: jobTemplates,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },

        searchById: {
            action: (templateCode: string) => ({ TemplateCode: templateCode }),
            successCallback: (data: IJobTemplateData, payload: { records: IObjectified<IJobtemplateDetailFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IJobTemplateData => {
                const jobTemplates = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: jobTemplates,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },

        fetchJobTemplateData: {
            action: (templateCode: string) => ({ TemplateCode: templateCode }),
            successCallback: (data: IJobTemplateData, payload: { records: IObjectified<IJobtemplateDetailFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IJobTemplateData => {
                const jobTemplates = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    jobTemplateData: { inlineObject: jobTemplates[0], schema: schemas[0] },
                };
            }
        },

        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IJobTemplateData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },

            successCallback: (data: IJobTemplateData, payload: { records: IObjectified<IJobtemplateDetailFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IJobTemplateData => {
                const jobTemplates = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);
                const newRemoval = payload.records.length;
                const newList = data.list.concat(jobTemplates);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },

        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IJobTemplateData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },

            successCallback: (data: IJobTemplateData, payload: { records: IObjectified<IJobtemplateDetailFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IJobTemplateData => {
                const jobTemplates = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);
                const newList = jobTemplates.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },
        create: {
            action: (values: IJobtemplateDetailFacade) => (values),
            successCallback: (data: IJobTemplateData, payload: { JobTemplateDetails: IObjectified<IJobtemplateDetailFacade> }): IJobTemplateData => {
                return { ...data, jobTemplateData: payload.JobTemplateDetails };
            }
        },
        update: {
            action: (values: IJobtemplateDetailFacade) => (values),
            successCallback: (data: IJobTemplateData, payload: { JobTemplateDetails: IObjectified<IJobtemplateDetailFacade> }): IJobTemplateData => {

                return { ...data, jobTemplateData: payload.JobTemplateDetails };
            }
        },
        delete: {
            action: (TemplateCode: string) => (TemplateCode),
            successCallback: (data: IJobTemplateData): IJobTemplateData => {

                const list = [...data.list];
                const schemas = [...data.schemas];
                if (!isNull(list)) {
                    const selected = data.list.find((element) => element.TemplateCode === data.selected);
                    list.splice(list.indexOf(selected), 1);
                }
                if (!isNull(schemas)) {

                    const selectedSchema = data.schemas.find((element) => element.TemplateCode.Value === data.selected);
                    schemas.splice(schemas.indexOf(selectedSchema), 1);
                }

                return {
                    ...data, list, schemas,
                    selected: NOT_SELECTED,
                    jobTemplateData: { inlineObject: {}, schema: {} }
                };
            }
        },
    }

});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.TemplateCode === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.TemplateCode.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
        jobTemplateData: (data) => data.jobTemplateData,
        ServiceItemTypeId: (data) => data.jobTemplateData.inlineObject.ServiceItemTypeId,
    }
);

const syncSelector = {
    TemplateCode: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.data.selected,
    isLoading: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.search_loading || state.jobTemplateMaintenance.jobTemplate.searchById_loading,
    loadingNextPage: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.fetchNextPage_loading,
    loadingPrevPage: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.fetchPrevPage_loading,
    pageSize: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.data.pageSize,
    totalPages: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.data.totalPages,
    notifications: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.notifications,
    error: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.error,
    isFormLoading: (state: { jobTemplateMaintenance: { jobTemplate: IJobTemplateState } }) => state.jobTemplateMaintenance.jobTemplate.fetchJobTemplateData_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
