import React from 'react';
import FormView from 'components/FormView/FormView';
import OrderDetailForm from './OrderDetails.form';
import IOrderDetailsProps, { IOrderDetailsHandle } from './OrderDetails.properties';
import { withStyles } from '@material-ui/core/styles';
import styles from './OrderDetails.styles';
import { useCalculateOrderDetails, useRetrievePurchaseOrderDetails, useSetOrderDetailsQueryData, useUpdateOrderDetails } from 'api/Worksale/backorderCommitment';
import { useBOCContextSelector } from '../../BackorderCommitment.context';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import { objectifyAddressForPost } from 'api/utils';
import { OrderDetailsCalculationFields } from '../../constants';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const OrderDetails = (props: IOrderDetailsProps, ref: React.Ref<IOrderDetailsHandle>) => {
    const { classes, dirty, formValues, resetForm, changeConfirmationDialog } = props;

    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);

    const retrievePurchaseOrderDetails = useRetrievePurchaseOrderDetails(PurchaseOrderId);
    const updatePurchaseOrderDetailsMutation = useUpdateOrderDetails();
    const calculatePurchaseOrderDetailsMutation = useCalculateOrderDetails();
    const setOrderDetailsQueryData = useSetOrderDetailsQueryData();
    const purchaseOrderDetails = retrievePurchaseOrderDetails.data?.PurchaseOrderDetail;
    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const isDirty = internalDirty || dirty;

    const { setActionBar } = React.useContext(ActionBarContext);

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        []
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                    action: 'PurchaseOrderDetailsCancel',
                },
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    action: 'PurchaseOrderDetailsOk',
                },
            ]
        });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (isDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !isDirty;
            },
            async onOk(navigateBack: () => void): Promise<void> {
                const response = await updatePurchaseOrderDetailsMutation.mutateAsync({
                    ...formValues,
                    ...objectifyAddressForPost(formValues?.address, '', ['', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
                    address: undefined,
                    PurchaseOrderId
                });
                if (response?.Status === true) {
                    showSnackBar({ variant: 'success', message: 'Details updated successfully.' });
                    setOrderDetailsQueryData(PurchaseOrderId, response);
                    setInternalDirty(false);
                    navigateBack();
                }
            },
            onCancel(navigateBack: () => void): void {
                if (isDirty) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: () => {
                            resetForm();
                            navigateBack();
                            setInternalDirty(false);
                        }
                    });
                } else {
                    resetForm();
                    navigateBack();
                }
            }
        }),
        [isDirty, PurchaseOrderId, formValues, resetForm]
    );

    const calculateOrderDetails = React.useCallback(
        async (fieldName, value) => {
            if (value && OrderDetailsCalculationFields.includes(fieldName) &&
                purchaseOrderDetails?.inlineObject[fieldName] !== value) {

                const response = await calculatePurchaseOrderDetailsMutation.mutateAsync({
                    ...formValues,
                    ...objectifyAddressForPost(formValues?.address, '', ['', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
                    address: undefined,
                    [fieldName]: value,
                    AllowFutureDate: false,
                    AllowPassedDate: false,
                    PurchaseOrderId,
                    urlQueryParams: {
                        ChangedFieldId: fieldName,
                    }
                });
                if (response?.Status === true && response?.PurchaseOrderDetail) {
                    setOrderDetailsQueryData(PurchaseOrderId, response);
                    setInternalDirty(true);
                }
            }
        },
        [PurchaseOrderId, purchaseOrderDetails, formValues]
    );

    const formViewOnBlur = React.useCallback(
        async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
            const fieldName = e?.target?.name;
            const targetValue = e?.target?.value;

            if (fieldName) {
                calculateOrderDetails(fieldName, targetValue);
            }
        },
        [calculateOrderDetails]
    );

    const onLookupSelectedItemChange = React.useCallback(
        (name, item) => {
            calculateOrderDetails(name, item?.Code);
        },
        [calculateOrderDetails]
    );

    const handleDateOnChange = React.useCallback(
        (name, value) => {
            if (!formValues) {  // it means the form is not loaded yet
                return;
            }

            let date = '';
            Object.keys(value).forEach((k: string) => {
                if (/^[0-9]*$/.test(k)) {
                    date += value[k];
                }
            });
            calculateOrderDetails(name, date);
        },
        [formValues, calculateOrderDetails]
    );

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={false}
                onBlur={formViewOnBlur}
                formName={'PurchaseOrderDetailsForm'}
                schema={OrderDetailForm}
                includeTabsHeight={false}
                initialValues={purchaseOrderDetails?.inlineObject}
                valuesSchema={purchaseOrderDetails?.schema}
                operationMode={'EDIT'}
                fieldExtensions={{
                    PurchaseOrderTranType: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('PurchaseOrderTranType', item)
                    },
                    PurchaseOrderCategory: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('PurchaseOrderCategory', item)
                    },
                    TaxClass: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('TaxClass', item)
                    },
                    FreightCode: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('FreightCode', item)
                    },
                    FreightType: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('FreightType', item)
                    },
                    Carrier: {
                        onSelectedItemChange: (item) => onLookupSelectedItemChange('Carrier', item)
                    },
                    DueDate: {
                        onChange: (v) => handleDateOnChange('DueDate', v)
                    },
                    ShipDate: {
                        onChange: (v) => handleDateOnChange('ShipDate', v)
                    }
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(OrderDetails)));
