import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: 'auto',
        padding: '2px 15px 2px 2px',
        display: 'flex',
        flexDirection: 'column',
        margin: '15px 0 15px 15px',
    },
    fastEntryContainer: {
        marginBottom: 10,
        backgroundColor: '#ddd'
    },
    grid: {
        height: 'calc(100vh - 370px)',
        width: '100%'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledIcon: {
        color: '#AAA !important',
    }
});
