import * as React from 'react';
import { Tooltip, Paper, Radio, FormLabel, FormControl, Button, RadioGroup as MaterialRadioGroup, FormControlLabel as MaterialFormControlLabel } from '@material-ui/core';
// import { RadioGroup, FormControlLabel } from '@markinson/ui-components/material';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import ArrowDropUpIcon from '@markinson/uicomponents-v2/SvgIcons/ArrowDropUp';
import ArrowDropDownIcon from '@markinson/uicomponents-v2/SvgIcons/ArrowDropDown';
import InlineActionButton from '@markinson/uicomponents-v2/InlineActionButton';
import { createStyles, StyledComponentProps } from '@material-ui/core/styles';

import * as styles from './LookUpList.scss';

const FormControlLabel = (props) => <MaterialFormControlLabel {...props} />;
const RadioGroup = (props) => <MaterialRadioGroup {...props} />;

const inlineStyles = createStyles({
  searchTextField: {
    marginTop: '14px',
    width: '240px',
    minWidth: '240px'
  },
  filterRadioButton: {
    marginLeft: '0px',
    color: '#444444'
  },
  sortingApplyButton: {
    marginTop: '10px',
    width: '250px',
    backgroundColor: '#F47920',
    color: '#FFFFFF'
  },
  inlineFilterButton: {
    marginTop: '14px',
    backgroundColor: '#CCCCCC'
  },
  radioFormLabel: {
    color: '#777777'
  },
  sortingIconAscending: {
    position: 'absolute',
    top: -15,
    left: 0,
    fontSize: 45,
    color: '#777777'
  },
  sortingIconDescending: {
    position: 'absolute',
    left: 30,
    color: '#777777',
    fontSize: 45,
    top: -16,
  },
  iconColorOrange: {
    color: '#F47920'
  },
});

export interface ILookUpListProperties<T extends object> extends StyledComponentProps {
  searchPlaceholder: string;
  hideFilterButton?: boolean;
  searchText: string;
  sortingFilters: { filter: keyof T; label: string }[];
  selectedSortFilter: string;
  sortDirection: string;
  filterDropDownOpen: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  callSearchAction(): void;
  handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void;
  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void;
  handleClose(): void;
  handleSortDirectionToggle(): void;
  handleChangeSortingFilter(event: Event, value: any): void;
  handleFilterClick(): void;
}

class LookUpList<T extends object> extends React.Component<ILookUpListProperties<T>> {
  searchField: any;

  constructor(props: Readonly<ILookUpListProperties<T>>) {
    super(props);
    this.searchField = React.createRef<typeof TextField>();
  }

  componentDidMount(): void {
    if (this.searchField) {
      this.searchField.focus();
    }
  }

  render(): React.ReactNode {

    const {
      searchPlaceholder, sortingFilters, style, disabled, handleChangeSearchText, handleKeyDown, selectedSortFilter, sortDirection,
      callSearchAction, searchText, handleClose, handleSortDirectionToggle, handleChangeSortingFilter, handleFilterClick, filterDropDownOpen,
      hideFilterButton } = this.props;

    return (
      <div style={style} className={styles.lookupSearchSection}>
        <TextField
          fieldRef={(ref) => { this.searchField = ref; }}
          placeholder={searchPlaceholder}
          value={searchText}
          action={{
            iconName: 'Search',
            controller: callSearchAction
          }}
          iconStyles={{ width: 23, height: 23 }}
          style={inlineStyles.searchTextField}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          onChange={handleChangeSearchText}
        />
        {!hideFilterButton && <InlineActionButton
          disabled={disabled}
          iconName={'Filter'}
          onClick={handleFilterClick}
          style={inlineStyles.inlineFilterButton}
        />}
        {filterDropDownOpen ?
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={styles.filterMenuPaper}>
              <FormControl>
                <div className={styles.sortingPanelHeader}>
                  <FormLabel style={inlineStyles.radioFormLabel}>Sort</FormLabel>
                  <div className={styles.sortingHandlerContainer} onClick={handleSortDirectionToggle}>
                    <Tooltip id='tooltip-ascending' title='Sort ascending' placement='right'>
                      <ArrowDropUpIcon style={{ ...inlineStyles.sortingIconAscending, ...(sortDirection === 'Ascending' && inlineStyles.iconColorOrange) }} />
                    </Tooltip>
                    <Tooltip id='tooltip-descending' title='Sort descending' placement='right'>
                      <ArrowDropDownIcon style={{ ...inlineStyles.sortingIconDescending, ...(sortDirection === 'Descending' && inlineStyles.iconColorOrange) }} />
                    </Tooltip>
                  </div>
                </div>
                <RadioGroup name={'sortFilter'} value={selectedSortFilter} onChange={handleChangeSortingFilter}>
                  {sortingFilters && sortingFilters.map((filter, index) => (
                    <FormControlLabel
                      key={index}
                      value={filter.filter}
                      style={inlineStyles.filterRadioButton}
                      control={
                        <Radio
                          color='secondary'
                        />
                      }
                      label={filter.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                variant={'raised'}
                style={inlineStyles.sortingApplyButton}
                onClick={callSearchAction}> APPLY </Button>
            </Paper>
          </ClickAwayListener>
          : null}
      </div>
    );
  }
}

export default LookUpList;
