import React, { forwardRef } from 'react';
import { TreeList, Column, Selection, LoadPanel } from 'devextreme-react/tree-list';
import { withStyles } from '@material-ui/core';
import styles from './ReportingAreaGrid.styles';
import { useEMContextSelector, useEMDispatch } from 'components/ExtractMaintenance/ExtractMaintenance.context';
import { useRetrieveEMReportingAreas, useUpdateReportingAreas, useSetReportingAreasData } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import CheckBox from 'devextreme/ui/check_box';
import { isSuccess } from 'api/utils';
import { IReportingAreaGridHandle, IReportingAreaGridProps, IReportingFacadeExtended } from './ReportingAreaGrid.properties';

const getNodeByLevel = (node, level) => {
  if (!node.parent) {
    return;
  }

  if (node.parent.level === level || node.parent.level === undefined) {
    // Remove the '|| node.parent.level === undefined' part after release
    return node.parent;
  } else {
    return getNodeByLevel(node.parent, level);
  }
};

const modifyEmptySpaceStyles = (e) => {
  const currentNode = e.row.node;
  const $emptySpaceElements = e.cellElement.querySelectorAll(
    '.dx-treelist-empty-space'
  );
  const children = currentNode.parent.children;
  const isLasChildren = children[children.length - 1].key === currentNode.key;

  for (let i = 0; i < $emptySpaceElements.length; i++) {
    const node = getNodeByLevel(currentNode, i - 1);

    if (
      node &&
      node.children.length > 1 &&
      currentNode.parent.key !== node.children[node.children.length - 1].key
    ) {
      $emptySpaceElements[i].classList.add('dx-line');
    }

    if (i === $emptySpaceElements.length - 1) {
      $emptySpaceElements[i].classList.add('dx-line');
      $emptySpaceElements[i].classList.add('dx-line-middle');
      if (isLasChildren) {
        $emptySpaceElements[i].classList.toggle('dx-line-last');
      }
    }
  }
};

const ReportingAreaGrid = (props: IReportingAreaGridProps, ref: React.Ref<IReportingAreaGridHandle>) => {
  const { classes } = props;
  const params = new URLSearchParams(location.search);
  const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);
  const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);

  const contextDispatch = useEMDispatch();
  const treeListRef = React.useRef<TreeList>();
  const [focusedRowKey, setFocusedRowKey] = React.useState<number>(0);
  const isGridPreparing = React.useRef<boolean>(false);

  const retreiveReportingAreas = useRetrieveEMReportingAreas(templateId);
  const updateReportingAreasMutation = useUpdateReportingAreas();
  const setReportingAreasData = useSetReportingAreasData();

  const reportingAreaData = React.useMemo(() => retreiveReportingAreas?.data, [retreiveReportingAreas?.data]);

  const defaultSelectedRowKeys = React.useMemo(
    () => reportingAreaData?.ReportingAreas?.filter((r) => r.IsSelected && r.IsEnabled).map((area) => area?.AreaId),
    [reportingAreaData]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      OnCollapseAll: () => {
        const parent = reportingAreaData?.ReportingAreas?.find((r) => r.IsPrimary);
        treeListRef.current?.instance.collapseRow(parent?.AreaId);
      }
    }),
    [reportingAreaData]
  );

  const selectDefaultRows = React.useCallback(
    async () => {
      isGridPreparing.current = true;
      treeListRef.current.instance.clearSelection();
      await treeListRef.current?.instance?.selectRows(defaultSelectedRowKeys, false);
      isGridPreparing.current = false;
    },
    [defaultSelectedRowKeys]
  );

  React.useEffect(
    () => {
      if (!retreiveReportingAreas?.isFetching && reportingAreaData?.ReportingAreas) {
        selectDefaultRows();
      }
    },
    [retreiveReportingAreas.isFetching, selectDefaultRows]
  );

  const filterAreaItems = React.useCallback(
    (reportingAreas: any, parentId: number): IReportingFacadeExtended[] => {
      return reportingAreas
        ?.filter((r) => r.ParentId === parentId)
        ?.map((area) => {
          area.items = filterAreaItems(reportingAreas, area?.AreaId);

          return area;
        }) || [];
    },
    []
  );

  const dataSource = React.useMemo(
    () => ({
      store: filterAreaItems(reportingAreaData?.ReportingAreas, 0),
    }),
    [reportingAreaData, filterAreaItems]);

  const onCellPrepared = React.useCallback(
    (e) => {
      if (e.rowType === 'data' && e.columnIndex === 0) {
        modifyEmptySpaceStyles(e);

        //disable checkboxes that are not allowed to edit
        const element = e.cellElement.querySelector('.dx-select-checkbox');
        const checkBox = CheckBox.getInstance(element);
        checkBox?.option('disabled', inEditMode ? !e?.data?.IsEnabled : true);
      }

    },
    [inEditMode]
  );

  const handleFocusedRowChanged = React.useCallback(
    (e) => {
      const focusedKey = e.component.option('focusedRowKey');
      setFocusedRowKey(focusedKey);
      const focusedRowData = reportingAreaData?.ReportingAreas?.find((a) => a?.AreaId === focusedKey);

      contextDispatch({
        setFocusedArea: e?.row?.data,
        setIsFocusedAreaNameSelected: focusedRowData?.IsSelected
      });
    },
    [reportingAreaData]
  );

  const collapseSiblings = React.useCallback(
    (node, siblings) => {
      siblings?.forEach((sibling) => {
        if (sibling.key !== node.key && treeListRef.current?.instance.isRowExpanded(sibling.key)) {
          collapseSiblings(sibling, sibling?.children);
          treeListRef.current?.instance.collapseRow(sibling.key);
        }
      });
    },
    []
  );

  const updateReportingAreas = React.useCallback(
    async (rowData) => {
      const response = await updateReportingAreasMutation.mutateAsync({ TemplateId: templateId, ...rowData });
      if (isSuccess(response) && response?.ReportingAreas) {
        setReportingAreasData(templateId, response);
        contextDispatch({
          setIsFocusedAreaNameSelected: response?.ReportingAreas?.find((b) => b?.AreaId === focusedRowKey)?.IsSelected
        });
      }
    },
    [templateId, focusedRowKey]
  );
  const onSelectionChanged = React.useCallback(
    (e) => {
      if (isGridPreparing.current || !reportingAreaData?.ReportingAreas) {
        return;
      }

      const IsSelected = Boolean(e.currentSelectedRowKeys?.[0]);
      const currentRowKey = e.currentSelectedRowKeys?.[0] || e.currentDeselectedRowKeys?.[0];
      const focusedRowData = reportingAreaData?.ReportingAreas?.find((a) => a?.AreaId === currentRowKey);

      if (IsSelected) {
        const node = treeListRef.current?.instance.getNodeByKey(currentRowKey);
        collapseSiblings(node, node?.parent?.children);
      }

      if (reportingAreaData?.ReportingAreas.length && focusedRowData && inEditMode) updateReportingAreas({ ...focusedRowData, IsSelected, items: undefined });
    }
    ,
    [reportingAreaData, inEditMode, collapseSiblings, updateReportingAreas]
  );

  const onRowClick = React.useCallback(
    (row) => {
      if (!(row.event.target.parentElement.classList.contains('dx-treelist-expanded') ||
        row.event.target.parentElement.classList.contains('dx-treelist-collapsed'))) { // check if click is on row or not, to ignore arrow key default click
        if (row.isExpanded) {
          row?.component?.collapseRow(row?.key);
        } else {
          row?.component?.expandRow(row?.key);
        }
      }
    },
    []
  );

  return (
    <TreeList
      ref={treeListRef}
      className={classes.ReportingAreaGrid}
      dataSource={dataSource || []}
      columnAutoWidth={true}
      itemsExpr='items'
      keyExpr='AreaId'
      parentIdExpr='ParentId'
      dataStructure='tree'
      showColumnHeaders={false}
      showRowLines={false}
      showBorders={true}
      focusedRowEnabled={true}
      focusedRowKey={focusedRowKey}
      onRowClick={onRowClick}
      onCellPrepared={onCellPrepared}
      onSelectionChanged={onSelectionChanged}
      onFocusedRowChanged={handleFocusedRowChanged}
      noDataText=''
    >
      <Selection mode='multiple' />
      <Column dataField='AreaName' />
      <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
    </TreeList>
  );
};

export default withStyles(styles, { index: 1 })(forwardRef(ReportingAreaGrid));
