import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils';

export const fetch = async (query: { ProductId: number; Warehouse: string; Page: number; BatchSize?: number; filters: any; Sort: string; SortDirection: string }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { ProductId, Warehouse, Page, filters, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';
  const filterApplication = { ...filters };
  if (Sort) {
    filterApplication.Sort = Sort;
    filterApplication.SortDirection = SortDirection === 'desc' ? 'Descending' : 'Ascending';
  }

  return fetchPost(
    `/InventoryEnquiry/Product/${ProductId}/Warehouse/${Warehouse}/Quantity/Kitting`, {
    ...filterApplication,
  },
    {
      BatchPage: Page, BatchSize
    },
    respType)
    .then(
      (result: {
        StockingQuantityKittings: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.StockingQuantityKittings) {

            return {
              records: result.StockingQuantityKittings.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
