import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  dateComparator,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null,
  asyncs: {
    fetch: Data => Data,
  }
}, 'ldProductionOrder');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Priority', field: 'Priority', type: 'numericColumn', suppressSorting: true, minWidth: 150 },
    { headerName: 'Production order', field: 'ProductionOrder' },
    { headerName: 'Committed', field: 'CommittedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'User ID', field: 'UserId', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Due', field: 'DueDate', type: 'numericColumn', comparator: dateComparator, suppressSorting: true },
    { headerName: 'Order status', field: 'OrderStatus', suppressSorting: true },
    { headerName: 'Description', field: 'Description', suppressSorting: true }
  ],
  Data: [
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clears:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.ldProductionOrder,
  {
    Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.ldProductionOrder.loading,
  gridOptions: state => state.salesOrderEnquiry.ldProductionOrder.data.gridOptions,
  Columns: state => state.salesOrderEnquiry.ldProductionOrder.data.Columns,
  Actions: state => state.salesOrderEnquiry.ldProductionOrder.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)