const LineDetails = {
  'id': 'LineDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product description',
            'name': 'ProductDescription',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Customer product',
            'name': 'CustProduct',
            'size': 'medium'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quantity',
            'name': 'QuantityDisplay',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reserved quantity',
            'name': 'ReservedQuantity',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price',
            'name': 'PriceDisplay',
            'size': 'medium',
            'alignment': 'right',
            'type': 'string',
          }
        },
        {
          'id': 5,
          'type': 'EXTENDED_DISPLAY_FIELD',
          'visible': true,
          'props': {
            'label': 'Discount',
            'name': 'Discount',
            'size': 'small',
            'type': 'number',
            'decimalSeparator': true,
            'adornment': '%',
          }
        },
        {
          'id': 6,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax include price',
            'name': 'TaxIncluded',
            'defaultValue': false
          }
        },
        {
          'id': 7,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Special price',
            'name': 'SpecialPrice',
            'defaultValue': false
          }
        },
        {
          'id': 8,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Fixed price',
            'name': 'FixedPrice',
            'defaultValue': false
          }
        },
        {
          'id': 9,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Supplier quoted cost',
            'name': 'SupplierQuotedCost',
            'defaultValue': false
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted currency',
            'name': 'QuotedCurrency',
            'size': 'small'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted exchange rate',
            'name': 'QuotedExchangeRate',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_AREA',
          'visible': true,
          'props': {
            'label': 'Quote line comment',
            'name': 'Comment',
            'style': {
              'height': '220px'
            }
          }
        }
      ]
    }
  ]
};
export default LineDetails;