import { combineReducers } from 'redux';
import checklist, { IChecklistState } from './checklist';

export interface IChecklistMaintenanceState {
    checklist: IChecklistState;
}
const combined = combineReducers({
    checklist
});

export default combined;
