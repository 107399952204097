import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { IInvoiceLinesFields } from '../interfaces';

class InvoiceLines extends React.PureComponent<IInvoiceLinesFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-invoice-enquiry/invoice-lines':
          onInitialLoad('InvoiceLines');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details':
          onInitialLoad('InvoiceLineDetails');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details/serials':
          onInitialLoad('InvoiceLineSerials');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details/lots':
          onInitialLoad('InvoiceLineLots');
          break;
        default:
          onInitialLoad('InvoiceLines');
      }
    }
  }
  getInitialValues = () => {
    const {
      selectedTab, selectedInvoiceLine, selectedInvoice,
      invoiceLineDetails, loadingInvoiceLineSummary
    } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const lineNumber = params.get('LineNumber');
    switch (selectedTab) {
      case 'InvoiceLineDetails':
      case 'InvoiceLineLots':
      case 'InvoiceLineSerials':
        return (selectedInvoiceLine || lineNumber) && invoiceLineDetails && !loadingInvoiceLineSummary && { values: invoiceLineDetails.values, schema: invoiceLineDetails.schema };
      default:
        return { values: selectedInvoice, schema: null };
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const { values = {}, schema = {} } = this.getInitialValues() || {};

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          valuesSchema={schema}
          initialValues={values}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(InvoiceLines);
