import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as activityActions, selectors as activitySelectors } from 'ducks/activityMaintenance/activity';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';
import { IActivitySearchFacade, IActivityDetailFacade } from 'api/swaggerTypes';

const ActivitySearchLookUpDrawer = buildSearchLookUpDrawer<IActivitySearchFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IActivityDetailFacade>,
    'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IActivityDetailFacade>,
    'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'Activities',
        searchPlaceholder: 'Search for Activities...',
        operationMode: operationSelectors.operationMode(state),
        sortingFilters: [
            {
                filter: 'ActivityCode',
                label: 'ActivityCode'
            },
            {
                filter: 'Description',
                label: 'Activity'
            }
        ],
        records: activitySelectors.all(state) || [],
        selectedRecord: activitySelectors.selected(state),
        selectedSortFilter: 'ActivityCode',
        selectedSortDirection: 'Ascending',
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: activitySelectors.isLoading(state),
        nextPage: activitySelectors.nextPage(state),
        prevPage: activitySelectors.prevPage(state),
        pageSize: activitySelectors.pageSize(state),
        totalPages: activitySelectors.totalPages(state),
        loadingPrevPage: activitySelectors.loadingPrevPage(state),
        loadingNextPage: activitySelectors.loadingNextPage(state),
        searchParams: {},
        keyField: 'ActivityCode',
        fieldsToDisplay: ['ActivityCode', 'Description', null, null],
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
            if (selectedRecord) {
                matchParams.set('ActivityCode', selectedRecord.ActivityCode);
                matchParams.set('CapabilityCode', '');
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const activityCode = matchParams.get('ActivityCode');
            if (activityCode) {
                return {
                    id: activityCode,
                };
            }

            return null;
        },
    });

const actionCreators: DispatchProps = {
    search: activityActions.search,
    searchById: activityActions.searchById,
    changeSelectedRecord: activityActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    fetchNextPage: activityActions.fetchNextPage,
    fetchPrevPage: activityActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySearchLookUpDrawer);
