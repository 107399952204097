import React from 'react';
import INewJobServiceAgreementModalProps, { IFileSelectionHandle } from './DynamicExtractModal.properties';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { PANEL_NAME, PROGRAM_ID } from './DynamicExtractModal.constants';
import WelcomePanel from './Panels/WelcomePanel';
import ExtractReference from './Panels/ExtractReference';
import ViewSelection from './Panels/ViewSelection';
import TemplateSection from './Panels/TemplateSection';
import PeriodAdjustment from './Panels/PeriodAdjustment';
import CompleteExtractProgram from './Panels/CompleteExtractProgram';
import ExtractProcessingCriteria from './Panels/ExtractProcessingCriteria';
import FileSelection from './Panels/FileSelection';
import RunTimeSelection from './Panels/RunTimeSelection';
import BatchSuiteSelection from './Panels/BatchSuiteSelection';
import { parsePages, useFetchWizardConfiguration, useFileSelectionValidate, useSubmitDynamicExtractWizard } from 'api/dynamicDataExtract/dynamicDataExtract';
import { isEmpty } from 'lodash';
import { useDEContextSelector, useDEDispatch } from '../RunDynamicExtract.context';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { IValidationDialogAction } from 'components/common/ValidationDialog/ValidationDialog.properties';

const DynamicExtractModal = (props: INewJobServiceAgreementModalProps) => {
    const {
        open,
        handleClose,
    } = props;

    const processValidationForms = React.useContext(ProcessValidationFormsContext);
    const { mutateAsync: fileSelectionValidateMutateAsync } = useFileSelectionValidate();
    const fileSelectionRef = React.useRef<IFileSelectionHandle>(null);

    const [panelStack, setPanelStack] = React.useState<string[]>([]);
    const contextDispatch = useDEDispatch();

    const { data: wizard, isLoading } = useFetchWizardConfiguration(PROGRAM_ID);
    const { mutateAsync: submitDynamicExtractWizardMutateAsync } = useSubmitDynamicExtractWizard();

    const panels = React.useMemo(() => (parsePages(wizard) || {}), [wizard]);

    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const ErrorState = useDEContextSelector<'ErrorState'>((state) => state.ErrorState);
    const Panel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);

    React.useEffect(
        () => {
            if (!isEmpty(panels)) {
                contextDispatch({ setCurrentPanel: (Object.keys(panels).find((key) => panels[key].PageNumber === 1)) });
            }
        },
        [panels]
    );

    const handleNextPanel = () => {
        const nextPanel = panels[Panel]?.NextPageDetail(WizardDetails);
        if (nextPanel) {
            setPanelStack([...panelStack, Panel]);
            contextDispatch({ setCurrentPanel: nextPanel });
        } else {
            history.back();
        }
    };

    const handleNextButton = async () => {
        if (Panel === 'WizFileSelection') {
            proceedFromFileSelectionPanel();
        } else if (Panel === 'WizEnd') {
            const response = await submitDynamicExtractWizardMutateAsync({
                ...WizardDetails,
                ProgramId: PROGRAM_ID
            });
            if (response?.Status) {
                handleNextPanel();
            }
        } else {
            handleNextPanel();
        }
    };

    const handleBackButton = () => {
        if (panelStack.length > 0) {
            const newPanelStack = [...panelStack];
            const prevPanel = newPanelStack.pop();
            setPanelStack(newPanelStack);
            contextDispatch({ setCurrentPanel: prevPanel });
        } else {
            contextDispatch({ setCurrentPanel: '' });
        }
    };

    const handleFileSelectionValidateCallback = (action: IValidationDialogAction) => {

        switch (action.name) {
            case 'yes':
                handleNextPanel();
                break;
            case 'no':
            default:

        }

    };

    const proceedFromFileSelectionPanel = async () => {
        if (!WizardDetails?.FileLocation) {
            fileSelectionRef.current.focusFileLocationField();

            return;
        }

        if (!WizardDetails?.FileName) {
            fileSelectionRef.current.focusFileNameField();

            return;
        }

        if (!WizardDetails?.FileType) {
            fileSelectionRef.current.focusFileTypeField();

            return;
        }

        const response = await fileSelectionValidateMutateAsync({ FileName: WizardDetails?.FileName, FileOffset: '', FileLocation: WizardDetails?.FileLocation, FileType: WizardDetails?.FileType });
        if (response?.Forms.length) {

            return processValidationForms(response, response?.Forms, null, handleFileSelectionValidateCallback);
        }
        handleNextPanel();
    };

    function getModalContent(): JSX.Element {
        const components: { [key: string]: JSX.Element } = {
            [PANEL_NAME.WIZ_START]: <WelcomePanel details={panels[PANEL_NAME.WIZ_START]} />,
            [PANEL_NAME.WIZ_TEMPLATE_SELECTION]: <TemplateSection details={panels[PANEL_NAME.WIZ_TEMPLATE_SELECTION]} />,
            [PANEL_NAME.WIZ_VIEW]: <ViewSelection details={panels[PANEL_NAME.WIZ_VIEW]} staff={props.staff} />,
            [PANEL_NAME.WIZ_REFERENCE]: <ExtractReference details={panels[PANEL_NAME.WIZ_REFERENCE]} />,
            [PANEL_NAME.WIZ_BATCH_SUITE_DATE_HANDLING]: <PeriodAdjustment details={panels[PANEL_NAME.WIZ_BATCH_SUITE_DATE_HANDLING]} />,
            [PANEL_NAME.WIZ_END]: <CompleteExtractProgram details={panels[PANEL_NAME.WIZ_END]} />,
            [PANEL_NAME.WIZ_ACTION]: <ExtractProcessingCriteria details={panels[PANEL_NAME.WIZ_ACTION]} />,
            [PANEL_NAME.WIZ_FILE_SELECTION]: <FileSelection details={panels[PANEL_NAME.WIZ_FILE_SELECTION]} innerRef={fileSelectionRef} />,
            [PANEL_NAME.WIZ_RUN_TIME_SELECTION]: <RunTimeSelection details={panels[PANEL_NAME.WIZ_RUN_TIME_SELECTION]} />,
            [PANEL_NAME.WIZ_BATCH_SUITE]: <BatchSuiteSelection details={panels[PANEL_NAME.WIZ_BATCH_SUITE]} />,
        };

        return components[Panel] || <></>;
    }

    if (isLoading) {
        return null;
    }

    return (
        <FormViewModal
            open={open}
            loading={isLoading}
            title={wizard?.Configuration?.inlineObject?.Title}
            dialogActionsButtons={true}
            dialogActionsShadow={false}
            modalContent={getModalContent()}
            actions={[
                {
                    title: Panel !== 'WizEnd' ? 'Next' : 'Finish',
                    isDefault: !(ErrorState?.InError && ErrorState?.Panel === Panel),
                    listener: handleNextButton,
                    disabled: ErrorState?.InError && ErrorState?.Panel === Panel
                },
                {
                    title: 'Back',
                    disabled: Panel === 'WizStart',
                    listener: handleBackButton
                },
                {
                    title: 'Cancel',
                    listener: () => {
                        contextDispatch({ setCurrentPanel: '' });
                        if (handleClose) {
                            handleClose();
                        }
                    },
                },
            ]}
        />
    );
};

export default DynamicExtractModal;
