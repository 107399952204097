import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as depositDetailsTypes, actions as depositDetailsActions } from 'ducks/salesOrderEnquiry/depositDetails';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/depositDetails';

function* fetchSummary(action) {
  const { success, failure } = depositDetailsActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

export default function* rootDepositDetailsSaga() {
  yield takeLatest(depositDetailsTypes.fetchSummary, fetchSummary)
}