import ProductDetails from './../data/forms/inventory_enquiry/ProductDetails'
import AssociationsAlternates from 'utils/data/forms/inventory_enquiry/associations/alternates'
import AssociationsSuppressions from 'utils/data/forms/inventory_enquiry/associations/suppressions'
import AssociationsBOM from 'utils/data/forms/inventory_enquiry/associations/bom'
import WhereUsedBOM from 'utils/data/forms/inventory_enquiry/whereUsed/bom'
import WhereUsedCatalogue from 'utils/data/forms/inventory_enquiry/whereUsed/catalogue'
import HistoricalDates from './../data/forms/inventory_enquiry/HistoricalDates'
import NotepadForm from './../data/forms/inventory_enquiry/Notepad'
import StockingSerials from 'utils/data/forms/inventory_enquiry/stocking/serials'
import StockingMovements from 'utils/data/forms/inventory_enquiry/stocking/movements'
import StockingQuantities from 'utils/data/forms/inventory_enquiry/stocking/quantities'
import StockingQuantitiesPurchaseOrder from 'utils/data/forms/inventory_enquiry/stocking/quantitiesPurchaseOrder'
import StockingQuantitiesServiceWIP from 'utils/data/forms/inventory_enquiry/stocking/quantitiesServiceWIP'
import StockingQuantitiesProductionWIP from 'utils/data/forms/inventory_enquiry/stocking/quantitiesProductionWIP'
import StockingQuantitiesInPutAwayWIP from 'utils/data/forms/inventory_enquiry/stocking/quantitiesInPutAwayWIP'
import StockingQuantitiesProductionOrder from 'utils/data/forms/inventory_enquiry/stocking/quantitiesProductionOrder'
import StockingQuantitiesInTransitImport from 'utils/data/forms/inventory_enquiry/stocking/inTransitImport'
import StockingQuantitiesInTransitLocal from 'utils/data/forms/inventory_enquiry/stocking/inTransitLocal'
import StockingQuantitiesPicking from 'utils/data/forms/inventory_enquiry/stocking/picking'
import StockingQuantitiesReserved from 'utils/data/forms/inventory_enquiry/stocking/reserved'
import StockingQuantitiesKitting from 'utils/data/forms/inventory_enquiry/stocking/kitting'
import StockingLotDetails from 'utils/data/forms/inventory_enquiry/stocking/lotDetails'
import StockingBinLocations from 'utils/data/forms/inventory_enquiry/stocking/binLocations'
import StockingReserveHistory from 'utils/data/forms/inventory_enquiry/stocking/reserveHistory'
import StockingLotPicking from 'utils/data/forms/inventory_enquiry/stocking/lotPicking'
import StockingLotInPutAway from 'utils/data/forms/inventory_enquiry/stocking/lotInPutAway'
import StockingLotReserved from 'utils/data/forms/inventory_enquiry/stocking/lotReserved'
import IESSalesOrders from 'utils/data/forms/inventory_enquiry/sales/salesOrders'
import IESServiceOrders from 'utils/data/forms/inventory_enquiry/sales/serviceOrders'
import IESLostSales from 'utils/data/forms/inventory_enquiry/sales/lostSales'
import IESReturnAuthorities from 'utils/data/forms/inventory_enquiry/sales/returnAuthorities'
import IESQuotes from 'utils/data/forms/inventory_enquiry/sales/quotes'
import Prices from 'utils/data/forms/inventory_enquiry/pricing/Prices'
import ForeignPrices from 'utils/data/forms/inventory_enquiry/pricing/ForeignPrices'
import PriceMatrix from 'utils/data/forms/inventory_enquiry/pricing/PriceMatrix'
import Schedule from 'utils/data/forms/inventory_enquiry/pricing/Schedule'
import Documents from 'utils/data/forms/inventory_enquiry/attachments/Documents';
import Links from 'utils/data/forms/inventory_enquiry/attachments/Links';
import Images from 'utils/data/forms/inventory_enquiry/attachments/Images';
import UserFields from 'utils/data/forms/inventory_enquiry/UserFields';

const getFormSchema = (formId) => {
  switch (formId) {
    case 'ProductDetails':
      return ProductDetails
    case 'AssociationsAlternates':
      return AssociationsAlternates
    case 'AssociationsSupersessions':
      return AssociationsSuppressions
    case 'AssociationsBillOfMaterials':
      return AssociationsBOM
    case 'HistoricalDates':
      return HistoricalDates
    case 'Notepad':
      return NotepadForm
    case 'UserFields':
      return UserFields
    case 'WhereUsedBOM':
      return WhereUsedBOM
    case 'WhereUsedCatalogue':
      return WhereUsedCatalogue
    case 'StockingSerials':
      return StockingSerials
    case 'StockingMovements':
      return StockingMovements
    case 'StockingQuantities':
      return StockingQuantities
    case 'StockingQuantitiesPurchaseOrder':
      return StockingQuantitiesPurchaseOrder
    case 'StockingQuantitiesServiceWIP':
      return StockingQuantitiesServiceWIP
    case 'StockingQuantitiesProductionWIP':
      return StockingQuantitiesProductionWIP
    case 'StockingQuantitiesInPutAwayWIP':
      return StockingQuantitiesInPutAwayWIP
    case 'StockingQuantitiesProductionOrder':
      return StockingQuantitiesProductionOrder
    case 'StockingQuantitiesInTransitImport':
      return StockingQuantitiesInTransitImport
    case 'StockingQuantitiesInTransitLocal':
      return StockingQuantitiesInTransitLocal
    case 'StockingQuantitiesPicking':
      return StockingQuantitiesPicking
    case 'StockingQuantitiesReserved':
      return StockingQuantitiesReserved
    case 'StockingQuantitiesKitting':
      return StockingQuantitiesKitting
    case 'StockingLotDetails':
      return StockingLotDetails
    case 'StockingBinLocations':
      return StockingBinLocations
    case 'StockingReserveHistory':
      return StockingReserveHistory
    case 'StockingLotPicking':
      return StockingLotPicking
    case 'StockingLotInPutAway':
      return StockingLotInPutAway
    case 'StockingLotReserved':
      return StockingLotReserved
    case 'IESSalesOrders':
      return IESSalesOrders
    case 'ServiceOrders':
      return IESServiceOrders
    case 'IESLostSales':
      return IESLostSales
    case 'IESReturnAuthorities':
      return IESReturnAuthorities
    case 'IESQuotes':
      return IESQuotes;
    case 'Prices':
      return Prices;
    case 'ForeignPrices':
      return ForeignPrices;
    case 'PriceMatrix':
      return PriceMatrix;
    case 'Schedule':
      return Schedule;
    case 'Documents':
      return Documents;
    case 'Links':
      return Links;
    case 'Images':
      return Images;
    default:
      return ProductDetails
  }
}

export default getFormSchema
