import React, { Component } from 'react';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ChangePassword from 'components/ChangePasswordForm';
import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';
import { isNull } from 'utils/utils';

const styles = theme => ({
  listItem: {
    paddingLeft: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  menuLevel1Text: theme.menuLevel1Text,
})

const AccountSettings = (props) => {

  const { classes, logout, handleToggle, toggleDashboardOpen, setIsDashboardDefault, IsAdministrator } = props;
  const [openPasswordChange, setOpenPasswordChange] = React.useState(false);


  const handleLogOut = () => {
    logout();
    handleToggle();
  }

  const openDashboardModal = (isDefault) => {
    toggleDashboardOpen(true);
    setIsDashboardDefault(isDefault);
  }

  const Settings = React.useMemo(
    () => ([
      { label: 'Change Password', action: () => setOpenPasswordChange(true) },
      { label: 'Dashboard Maintenance', action: () => openDashboardModal(false) },
      ...IsAdministrator ? [{ label: 'Default Dashboard Maintenance', action: () => openDashboardModal(true) }] : [],
    ]),
    [IsAdministrator]
  );

  const Favourites = [
    //'Organise Favourites'
  ];

  return (
    <div>
      <List component="nav">
        <ListItem button className={classes.listItem} onClick={handleLogOut}>
          <ListItemText primary={'Log out'} className={classes.menuLevel1Text} />
        </ListItem>
      </List>
      <Subheader>Account Settings</Subheader>
      <List component="nav">
        {Settings.map((item, idx) => (
          <ListItem button key={idx} className={classes.listItem} onClick={item.action}>
            <ListItemText primary={item.label} className={classes.menuLevel1Text} />
          </ListItem>
        ))}
      </List>
      {!isNull(Favourites) && (
        <>
          <Subheader>Favorites</Subheader>
          <List component="nav">
            {Favourites.map((item, idx) => (
              <ListItem button key={idx} className={classes.listItem}>
                <ListItemText primary={item} className={classes.menuLevel1Text} />
              </ListItem>
            ))}
          </List>
        </>
      )}
      <ChangePassword open={openPasswordChange} closeForm={() => setOpenPasswordChange(false)} />
    </div>
  )
}

export default withStyles(styles, { index: 1 })(AccountSettings);
