import { takeLatest, Effect } from 'redux-saga/effects';
import { types as programAreaTypes, actions as programAreaActions } from 'ducks/programArea';
import { connectSagaToApi } from './utils';
import * as api from 'api/programArea';
import * as dashboardApi from 'api/dashboardMaintenance/dashboardMaintenance';

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(programAreaTypes.fetchProgramAreas, connectSagaToApi(programAreaActions, 'fetchProgramAreas', api.getProgramAreas));
  yield takeLatest(programAreaTypes.fetchDashboardWidgets, connectSagaToApi(programAreaActions, 'fetchDashboardWidgets', dashboardApi.getUserDashboards));
}
