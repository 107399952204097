
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import {
    actions as activitySchedulerActions,
    selectors as activitySchedulerSelectors
} from 'ducks/serviceActivityScheduling/serviceActivities';
import AssignmentsPanel from './AssignmentsPanel';
import { bindActionCreators, Dispatch } from 'redux';
import { IAssignmentsPanelProps } from 'components/ServiceActivityScheduling/interfaces';

type IStateProps = Pick<IAssignmentsPanelProps,
    'loading' | 'data' | 'adjustmentProps'>;
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    adjustmentProps: activitySchedulerSelectors.adjustmentProps(state),
    data: activitySchedulerSelectors.reviewJobSummary(state),
    loading: activitySchedulerSelectors.adjustmentPanelLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        onAdjust: activitySchedulerActions.applyReviewJobAdjustments,
        updateAdjustmentProps: activitySchedulerActions.updateAdjustmentProps,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsPanel);
