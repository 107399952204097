
import { connect } from 'react-redux';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as slipLineSelectors } from 'ducks/pickSlipEnquiry/slipLines';
import { selectors as bomSelectors } from 'ducks/pickSlipEnquiry/bom';

import SlipLines from './SlipLines';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  slipLineDetails: slipLineSelectors.slipLineDetails(state),
  slipLineDetailsSummary: slipLineSelectors.slipLineDetailsSummary(state),
  loadingSlipLineDetailsSummary: slipLineSelectors.loadingSlipLineDetailsSummary(state),
  slipLineBOMDetails: bomSelectors.bomDetails(state),
  slipLineBOMDetailsSummary: bomSelectors.bomSummary(state),
  loadingSlipLineBOMDetailsSummary: bomSelectors.loadingBOMSummary(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
});

export default connect(mapStateToProps, null)(SlipLines);
