import { IFormViewForm } from 'components/FormView';

const LineDetails: IFormViewForm = {
    id: 'LineDetailsForm',
    layout: {
        rows: 3,
        columns: 3
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Product',
                        name: 'ProductCode',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        name: 'Description',
                        placeholder: '',
                        size: 'large',
                        required: false,
                        inputProps: {
                            maxLength: 40
                        }
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Ordered',
                        name: 'OrderedQuantity',
                        placeholder: '',
                        size: 'small',
                        alignment: 'right',
                        required: false,
                        decimalSeparator: true,
                        decimalPlaces: 2,
                        preventNegativeValue: true
                    }
                },
                {
                    id: '3',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Supplied',
                        name: 'SuppliedQuantity',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2,
                        preventNegativeValue: true
                    }
                },
                {
                    id: '3',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Kitting',
                        name: 'KittingQuantity',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2,
                        preventNegativeValue: true
                    }
                },
                {
                    id: '4',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Backorder',
                        name: 'BackorderedQuantity',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2,
                        preventNegativeValue: true
                    }
                },
                {
                    id: '5',
                    type: 'EXTENDED_TEXT_FIELD',
                    props: {
                        label: 'Price',
                        name: 'Price',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        preventNegativeValue: true
                    }
                },
                {
                    id: '6',
                    type: 'EXTENDED_DISPLAY_FIELD',
                    visible: true,
                    props: {
                        label: 'Discount',
                        name: 'Discount',
                        size: 'small',
                        type: 'number',
                        decimalSeparator: true,
                        adornment: '%',
                    }
                },
            ]
        },
        {
            id: '2',
            position: {
                row: 1,
                col: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Required ship',
                        name: 'RequiredShipDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'medium',
                        required: true,
                    }
                },
                {
                    id: '1',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Expected ship',
                        name: 'ExpectedShipDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'medium',
                        required: true,
                    }
                },
                {
                    id: '2',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Order expiry',
                        name: 'OrderExpiryDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'medium'
                    }
                },
                {
                    id: '3',
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        label: 'Site',
                        name: 'SiteCode',
                        lookupName: 'WorksaleDelivery',
                        placeholder: '',
                        size: 'small',
                        required: true,
                        isWorksaleScoped: true
                    }
                }
            ]
        },
        {
            id: '3',
            position: {
                row: 2,
                col: 1,
                colspan: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Comment',
                        name: 'Comment',
                        size: 'large',
                        style: {
                            height: '400px'
                        }
                    }
                }
            ]
        },
    ]
};
export default LineDetails;
