import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IOrderConfirmationDialogProperties } from './OrderConfirmationDialog.properties';
import OrderConfirmationSchema from './OrderConfirmationDialog.form';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useFetchSalesOrderConfirmation } from 'api/Worksale/worksale';

const OrderConfirmationDialog = (props: IOrderConfirmationDialogProperties) => {
    const { open, loading, values, orderDocumentOutputOptions, setOrderDocumentOutputOptions, onCancel, onOk, operationInProgress, processSalesOrderQuery } = props;
    const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
    const customerId = useSPContextSelector(Selectors.CustomerId);
    const { data: response } = useFetchSalesOrderConfirmation(worksaleId);

    const initialValues = React.useMemo(
        () => {
            if (operationInProgress === 'ProcessSale') {
                return processSalesOrderQuery;
            } else {
                return {
                    ...response?.SalesOrderConfirmation.inlineObject,
                    OrderAcknowledgementOutput: response?.SalesOrderConfirmation.OrderAcknowledgementOutput.inlineObject
                };
            }
        },
        [operationInProgress, processSalesOrderQuery, response]
    );

    const valuesSchema = React.useMemo(() => (response?.SalesOrderConfirmation.schema), [response]);

    React.useEffect(
        () => {
            if (initialValues) {
                setOrderDocumentOutputOptions(initialValues?.OrderAcknowledgementOutput);
            } else {
                setOrderDocumentOutputOptions(undefined);
            }
        },
        [initialValues]);

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Order Confirmation'
                formProps={{
                    formSchema: OrderConfirmationSchema,
                    initialValues: {
                        ...initialValues,
                        OrderAcknowledgementOutput: initialValues && initialValues.OrderAcknowledgementOutput ? initialValues.OrderAcknowledgementOutput.Summary : ''
                    },
                    valuesSchema: valuesSchema ? valuesSchema : {},
                    style: { height: '380px', minWidth: '400px' },
                    customerId: customerId
                }}
                actions={[
                    {
                        title: 'Ok',
                        listener: () => {
                            if (onOk) {
                                onOk({ ...values, OrderAcknowledgementOutput: orderDocumentOutputOptions });
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </div>
    );
};

export default OrderConfirmationDialog;
