export const PANEL_NAME = {
    WIZ_START: 'WizStart',
    WIZ_TEMPLATE_SELECTION: 'WizDDETemplateSelection',
    WIZ_VIEW: 'WizView',
    WIZ_REFERENCE: 'WizReference',
    WIZ_ACTION: 'WizAction',
    WIZ_FILE_SELECTION: 'WizFileSelection',
    WIZ_RUN_TIME_SELECTION: 'WizRunTimeSelection',
    WIZ_BATCH_SUITE: 'WizBatchSuite',
    WIZ_BATCH_SUITE_DATE_HANDLING: 'WizBSuiteDateHandling',
    WIZ_END: 'WizEnd',
};

export const PROGRAM_ID = 'DynamicDataExtract';
