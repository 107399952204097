import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, fetchPut, objectify, createRequestObject } from '../utils';

export const search = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost('PickSlipEnquiry/SearchPickSlips', { SearchText, Sort, BatchPage, BatchSize })
    .then((something) => ({
      records: something.PickSlips.map((val) => ({ inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }));
};

export const searchById = (DespatchId) => {
  const apiCall = fetchGet(`PickSlipTransaction/Despatch/${DespatchId}/Details`, {}, 'Inline')
    .then((something) => ({
      records: [{ inlineObject: objectify(something.PickSlip), schema: something.PickSlip }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }));

  return apiCall;
};

export const fetchSlipDetails = (DespatchId) => {
  const apiCall = fetchGet(`PickSlipTransaction/Despatch/${DespatchId}`, {}, 'Inline')
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};

export const cancelPickSlipDetails = (DespatchId) => {
  const apiCall = fetchPost(`PickSlipTransaction/Despatch/${DespatchId}/CancelDetails`)
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};

export const saveSlipDetails = (payload) => {
  const apiCall = fetchPost('PickSlipTransaction/SaveDespatchDetails', createRequestObject(payload), null, 'Inline')
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};
export const finalizeSlipDetails = (payload) => {
  const apiCall = fetchPost('PickSlipTransaction/Finalise', createRequestObject(payload), null, 'Inline')
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};

export const updateSlipDetails = (payload) => {
  const apiCall = fetchPut('PickSlipTransaction/Despatch', createRequestObject(payload), 'Inline')
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};

export const removeSlipDetails = (payload) => {
  const apiCall = fetchPost('PickSlipTransaction/RemoveDespatch', createRequestObject(payload), null, 'Inline')
    .then((result) => ({
      Forms: result.Forms,
      inlineObject: objectify(result.PickSlip), schema: result.PickSlip
    }));

  return apiCall;
};

export const recordLostSale = ({ DespatchId, payload }) => {
  const apiCall = fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Lostsale`, payload, null, 'Inline')
    .then((result) => ({
      inlineObject: objectify(result.LostSaleRecord), schema: result.LostSaleRecord
    }));

  return apiCall;
};

export const fetchRecordLostSale = (DespatchId) => {
  const apiCall = fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Lostsale`, {}, 'Inline')
    .then((result) => ({
      inlineObject: objectify(result.LostSaleRecord), schema: result.LostSaleRecord
    }));

  return apiCall;
};
