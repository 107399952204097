import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as quoteLineSelectors, actions as quoteLineActions } from 'ducks/customerQuoteEnquiry/quote';
import { fetchQuoteLines } from 'api/customerQuoteEnquiry/quote';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'QuoteLines',
  columnDefs: quoteLineSelectors.quoteLineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  actions: quoteLineSelectors.quoteLineActions(state),
  gridOptions: quoteLineSelectors.gridOptions(state),
  isLoading: quoteLineSelectors.loadingQuoteLines(state),
  keyFields: [{valueField: 'LineNumber', routeField: 'LineNumber'}],
  reqParams: (quoteLineSelectors.selected(state) || params.get('Quote'))
    &&
    {
      QuoteId: (quoteLineSelectors.selected(state) && quoteLineSelectors.selected(state).Quote) || params.get('Quote')
    },
  apiMethod: fetchQuoteLines
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: quoteLineActions.setSelectedQuoteLine,
  changeOperationMode: operationActions.changeOperationMode,
}
