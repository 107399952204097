import { IFormViewForm } from 'components/FormView';

const ParksaleConfirmationForm: IFormViewForm = {
    id: 'ParksaleConfirmationForm',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1,
                colspan: 3
            },
            type: 'PAPER_CONTAINER',
            props: {
                elevation: 0
            },
            children: [
                {
                    id: 1,
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Notes',
                        name: 'Note',
                        fullWidth: true,
                        style: {
                            height: '400px'
                        }
                    }
                },
            ]
        }
    ]
};
export default ParksaleConfirmationForm;
