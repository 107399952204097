import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryTable from 'components/common/SummaryTable';

import * as SummaryTableSchema from '../SummaryTableSchema.json';
import { ISupplierDetailsProps } from '../interfaces';

class Activity extends React.PureComponent<ISupplierDetailsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      const context = '/supplier-enquiry/activity';
      switch (path) {
        case `${context}/invoices`:
          onInitialLoad('SupplierActivityInvoices');
          break;
        case `${context}/movements`:
          onInitialLoad('SupplierActivityMovements');
          break;
        case `${context}/grns`:
          onInitialLoad('SupplierActivityGRNs');
          break;
        case `${context}/freight`:
          onInitialLoad('SupplierActivityFreight');
          break;
        case `${context}/rfcs`:
          onInitialLoad('SupplierActivityRFCs');
          break;
        default:
          onInitialLoad('SupplierActivityInvoices');
      }
    }
  }

  getSummaryTableRenderer = (selectedTab: string): React.ReactNode => {
    switch (selectedTab) {
      case 'SupplierActivityRFCs':
        return this.RFCSummary;
      default: return null;
    }

  }

  RFCSummary = (): React.ReactNode => {
    const { rfcSummary, loadingRFCSummary } = this.props;

    return (<SummaryTable
      rfcSummary={rfcSummary}
      loadingRFCSummary={loadingRFCSummary}
      data={
        SummaryTableSchema.SupplierActivityRFCs
      }
    />);
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName, selectedTab, rfcSummary } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={rfcSummary}
          operationMode={operationMode}
          valuesSchema={null}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default Activity;
