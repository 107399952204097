import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { Selectors, Actions } from './bindings';

import SearchLookUpDrawer, { ISearchLookUpDrawerProperties } from './SearchLookUpDrawer';
import SortingFilters from './SortingFilters.json';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState, props: Readonly<ISearchLookUpDrawerProperties>) => (
  {
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: SortingFilters[props.variant],
    ...(Selectors)(props.variant, state)
  });

const mapDispatchToProps = (dispatch: Dispatch, props: Readonly<ISearchLookUpDrawerProperties>) => bindActionCreators(
  {
    ...Actions(props.variant)
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchLookUpDrawer);
