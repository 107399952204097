import React, { useEffect, useState } from 'react';
import DataGrid from 'devextreme-react/data-grid';
import StarIcon from '@markinson/uicomponents-v2/SvgIcons/Star';
import { withStyles } from '@material-ui/core/styles';
import styles from './Deliveries.styles';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from './SummaryTableSchema.json';
import Paper from '@material-ui/core/Paper';
import { IDeliveriesProps } from './Deliveries.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import AddCircle from '@markinson/uicomponents-v2/SvgIcons/AddCircle';
import { IRetrieveWorksaleDeliveriesPayload, useDeleteWorksaleDelivery, useRetrieveWorkSaleDeliveries, useUpdateDefaultWorksaleDelivery } from 'api/Worksale/worksale';
import DataSource from 'devextreme/data/data_source';
import { createWorksaleDeliveriesDataSource } from './createDeliveriesDataSource';
import { useSPContextSelector, useSPDispatch } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { ChangeCircle, PlaylistAddCircle } from '@markinson/uicomponents-v2/SvgIcons';
import ChangeDeliveryDialog from '../ChangeDeliveryDialog';
import DynamicDataGrid from 'components/common/DataGridDevEx/DynamicDataGrid';
import { SUMMARY_PANEL_WIDTH, columns } from './constants';
import { SalesProcessingActionsTypes } from '../Worksale.actions';
import { useElementSize } from 'utils/hooks';

const Deliveries = (props: IDeliveriesProps) => {
  const { classes, changeSelectedTab, changeConfirmationDialog, showDetailOnClick, onDefaultWorkSaleDeliveryUpdate, open, onClose } = props;
  const { onKeyDown, onFocusedCellChanging } = useGridKeyboardNavigation();
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const customerId = useSPContextSelector(Selectors.CustomerId);
  const { mutateAsync: entriesFetchMutateAsync } = useRetrieveWorkSaleDeliveries();
  const { mutateAsync: updateDefaultWSDeliveryMutateAsync } = useUpdateDefaultWorksaleDelivery();
  const { mutateAsync: deleteWorksaleDeliveryMutateAsync } = useDeleteWorksaleDelivery();
  const dataGridRef = React.useRef<DataGrid>();
  const { setActionBar } = React.useContext(ActionBarContext);

  const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true);

  const [selectedRow, setSelectedRow] = useState(null);
  const [dataSource, setDataSource] = useState<DataSource>((new DataSource([])));
  const contextDispatch = useSPDispatch();
  const worksaleDeliveryId = useSPContextSelector(Selectors.WorksaleDeliveryId);
  React.useEffect(
    () => {
      contextDispatch({
        [SalesProcessingActionsTypes.setWorksaleDeliveryId]: undefined
      });
    },
    []
  );
  const deliveriesContainerRef = React.useRef<HTMLDivElement>(null);
  const { width: deliveriesContainerWidth } = useElementSize(deliveriesContainerRef);

  React.useEffect(
    () => {
      if (!customerId) {
        changeSelectedTab('Lines');

        return;
      }
      setActionBarButtons();
    },
    [changeSelectedTab, customerId, worksaleDeliveryId]
  );

  useEffect(() => setIsFirstTime(true), []);

  const setActionBarButtons = (): void => {
    setActionBar({
      leftIcons: [],
      centerIcons: [
        {
          label: 'Change Delivery',
          Icon: ChangeCircle,
          action: 'ChangeDelivery',
          disabled: !selectedRow?.inlineObject?.LineCount
        },
        {
          label: 'Fulfillment',
          Icon: PlaylistAddCircle,
          action: 'Fulfillment',
          disabled: !worksaleDeliveryId
        }
      ],
      rightIcons: [
        {
          label: 'New Site',
          Icon: AddCircle,
          action: 'New_Site',
        }
      ]
    });
  };

  const fetchWorkSaleDeliveries = async (query) => {
    const data = {
      ...query,
      urlQueryParams: {
        BatchPage: query.BatchPage,
        BatchSize: query.BatchSize,
        Sort: '',
        DebId: customerId
      }
    };

    return entriesFetchMutateAsync(data);
  };

  const updateDefaultDelivery = async (query) => {
    const data = {
      ...query,
      WorkSaleId: query.WorksaleId,
      urlQueryParams: {
        SiteCode: query.SiteCode,
      }
    };

    return updateDefaultWSDeliveryMutateAsync(data);
  };
  const deleteWorksaleDeliverySite = async (query) => {
    const data = {
      WorksaleDeliveryId: query
    };

    return deleteWorksaleDeliveryMutateAsync(data);
  };

  useEffect(
    () => {
      const customDataSource = createWorksaleDeliveriesDataSource(
        {
          fetch: async (pageDetail: Omit<IRetrieveWorksaleDeliveriesPayload, 'WorkSaleId' | 'CustomerId'>) => {
            if (worksaleId > 0 && customerId) {
              const res = await fetchWorkSaleDeliveries({ WorkSaleId: worksaleId, CustomerId: customerId, ...pageDetail });
              if (!res.Status) {
                setDataSource(new DataSource([]));
              }

              res.Deliveries = res?.Deliveries?.map((delivery) => ({
                ...delivery,
                DisableDeleteAction: !Number(delivery.inlineObject.WorkSaleDeliveryId)
              }));

              return res;
            } else {
              setDataSource(new DataSource([]));
            }
          },
        });

      setDataSource(customDataSource);
    },
    [worksaleId, customerId]
  );

  const onSelectedRowChanged = ({ selectedRowsData }) => {
    const selectedRowData = (selectedRowsData && selectedRowsData[0]) ? selectedRowsData[0] : {};
    setSelectedRow(selectedRowData);
    contextDispatch({
      setWorksaleDeliveryId: Number(selectedRowData?.inlineObject?.WorkSaleDeliveryId),
      setWorksaleDeliverySummary: selectedRowData?.inlineObject,
    });
  };

  const handleFavorite = async ({ row }) => {
    const response = await updateDefaultDelivery({ SiteCode: (row.data && row.data.inlineObject) ? row.data.inlineObject.SiteCode : '', WorksaleId: worksaleId });
    onDefaultWorkSaleDeliveryUpdate(response);

    if (dataGridRef.current) {
      await dataGridRef.current.instance.refresh();
      const visibleRows = dataGridRef.current.instance.getVisibleRows();
      const defaultRowData = visibleRows.find((r) => r.data.inlineObject.Primary === true);
      const defaultRowIndex = defaultRowData?.rowIndex;
      if (defaultRowIndex) {
        dataGridRef.current.instance.selectRowsByIndexes([defaultRowIndex]);
      }
    }
  };

  const removeRow = (rowData) => {
    changeConfirmationDialog({
      open: true,
      title: 'Delete Site',
      message: 'Are you sure you want to continue?',
      okLabel: 'Delete',
      onCancel: () => null,
      onOk: async () => {
        deleteWorksaleDeliverySite(rowData.inlineObject.WorkSaleDeliveryId)
          .finally(() => {
            dataGridRef.current.instance.refresh();
          });
      }
    });
  };

  const SelectAndFocusFirstRow = () => {
    setTimeout(
      () => {
        dataGridRef.current?.instance?.focus();
        dataGridRef?.current.instance.selectRowsByIndexes([0]);
        const data = dataGridRef?.current.instance.getSelectedRowsData();
        contextDispatch({
          setWorksaleDeliveryId: Number(data[0]?.inlineObject?.WorkSaleDeliveryId),
          setWorksaleDeliverySummary: data[0]?.inlineObject,
        });
      },
      0
    );
  };

  const onContentReady = React.useCallback(
    (e) => {
      if (isFirstTime && e.component.getDataSource()?.items().length) {
        SelectAndFocusFirstRow();
        setIsFirstTime(false);
      }
    },
    [isFirstTime]
  );

  const detailOnClick = (allProps) => {
    if (showDetailOnClick) {
      showDetailOnClick(allProps.data ? allProps.data : null);
    }
  };

  const actionsAtStart = (rowData) => (
    <a className={classes.anchor}>
      <StarIcon className={classes.actionButtonIcon} onClick={() => handleFavorite(rowData)} />
    </a>
  );

  return (
    <div className={classes.container} ref={deliveriesContainerRef}>
      <Paper className={classes.grid} style={{width: `${deliveriesContainerWidth - SUMMARY_PANEL_WIDTH}px`}}>
        <DynamicDataGrid
          dataGridClassName={classes.dataGrid}
          onDelete={removeRow}
          columns={columns}
          allowHeaderFilting={false}
          allowUpdating={false}
          gridProps={{
            id: 'DeliveriesGrid',
            dataSource: dataSource,
            dataGridRef: dataGridRef,
            onContentReady: onContentReady,
            onKeyDown: onKeyDown,
            onSelectionChanged: onSelectedRowChanged,
            onFocusedCellChanging: onFocusedCellChanging,
            selection: { mode: 'single' },
            sorting: { mode: 'single' }
          }}
          detailOnClick={detailOnClick}
          actionsAtStart={actionsAtStart}
        />
      </Paper>
      <SummaryTable
        loadingDeliverySummary={false}
        deliverySummary={selectedRow?.inlineObject}
        data={
          SummaryTableSchema.Deliveries
        }
      />
      {open ?
        <ChangeDeliveryDialog
          open={open}
          initialValues={selectedRow?.inlineObject || {}}
          valuesSchema={selectedRow?.schema || {}}
          dataSource={dataSource}
          SelectAndFocusFirstRow={SelectAndFocusFirstRow}
          onClose={onClose}
        /> : undefined}
    </div >
  );
};

export default withStyles(styles, { index: 1 })(Deliveries);
