import { fetchGet, objectify, fetchPost, fetchDelete } from '../utils';

export const fetStatus = () => {

    return fetchGet('/Payment/IntegratedEftpos/Status')
        .then(objectify);
};

export const sendOk = () => {

    return fetchPost('/Payment/IntegratedEftpos/SendOk')
        .then(objectify);
};

export const sendClose = () => {

    return fetchPost('/Payment/IntegratedEftpos/SendCancel')
        .then(objectify);
};
export const purchase = (data) => {

    return fetchPost('/Payment/IntegratedEftpos/Purchase', data)
        .then(objectify);
};

export const fetchPaymentDetails = () => {

    return fetchGet('/Payment/PaymentDetails')
        .then(parsePaymentResponse);
};

export const applyPayment = (data) => {

    return fetchPost('/Payment/ApplyPayment', data, null)
        .then(parsePaymentResponse);

};

export const updatePaymentContext = (data) => {

    return fetchPost('/Payment/PaymentContext', data, null)
        .then(parsePaymentResponse);

};
export const processPayment = (data) => {

    return fetchPost('/Payment/ProcessPayment', data, null);
};

export const deletePayment = (query) => {
    const { PositionNumber } = query;

    return fetchDelete(`/Payment/PaymentLine/${PositionNumber}`)
        .then(parsePaymentResponse);

};
export const cancelPayment = (data) => {

    return fetchPost('/Payment/CancelPayment', data, null);
};

const parsePaymentResponse = (response) => {
    const { PaymentDetail } = response;

    return PaymentDetail ? {
        ...response,
        ...PaymentDetail,
        PaymentDetails: { inlineObject: objectify(PaymentDetail), schema: PaymentDetail },
        DefaultLine: { inlineObject: objectify(PaymentDetail.DefaultLine || {}), schema: PaymentDetail.DefaultLine || {} },
        PaymentLines: PaymentDetail.PaymentLines.map((values) => objectify(values, null)),
        PaymentContext: { inlineObject: objectify(PaymentDetail.PaymentContext) || {}, schema: PaymentDetail.PaymentContext || {} },
    } : { ...response };
};
