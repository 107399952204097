import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  SortGrid: {
    'height': 'calc(100% - 35px)',
    'width': '100%',
    '& .dx-datagrid-rowsview .dx-row > td': {
      textOverflow: 'unset'
    },
  }
});
