// tslint:disable: cyclomatic-complexity
import * as  React from 'react';
import moment from 'moment';
import { FormControlLabel, Switch, Typography, Button, CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CancelIcon from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import ExpandMoreIcon from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import DateTextField from '@markinson/uicomponents-v2/DateTextField';
import TimeField from '@markinson/uicomponents-v2/TimeField';
import AutoCompleteField from 'components/AutoCompleteContainer';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Scrollbars from 'react-custom-scrollbars';
import { updateOptions, ObjectifiedDocumentOutputOptionsDetailsResponse } from 'api/documentOutput';

import * as styles from './DocumentOutputDialog.scss';

import { CircularProgressSize } from 'utils/constants';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { IDocumentOutputDialogProperties, IDocumentOutputDialogState, IDocumentOutputFormProperties, IDocumentOutputOptionsFacadeExtended, IExpansionProperties, IFaxFormProperties, IPrintFormProperties, IToggleButtonProperties } from './DocumentOutputDialog.properties';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const classesStyles = createStyles({
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {

  },
  rootExpansionSummaryContainer: {
    backgroundColor: '#D1D1D1',
    boxShadow: 'rgb(170, 170, 170) 0px 0px 0px 1px inset',
    height: '48px'
  },
  expandedSummaryContainer: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
  },
  scrollBarStyle: {
    padding: '10px'
  },
  rootExpansionPanelContainer: {
    width: '680px'
  },
  footerActionButton: {
    height: '52px',
    boxShadow: 'none',
    borderRadius: 0
  },
  cancelButtonIcon: {
    height: '26px',
    width: '26px',
    color: '#990000',
    marginRight: '5px'
  },
  applyButtonIcon: {
    height: '26px',
    width: '26px',
    color: '#6dd900',
    marginRight: '5px'
  },
  formFieldWidth: {
    width: '100%'
  },
  basicFormFieldWidth: {
    '& > div': {
      width: '100%'
    }
  },
  mailTextArea: {
    height: '210px'
  },
  faxTextArea: {
    height: '305px'
  },
  circularProgressBar: {
    color: '#F47920'
  },
  dialog: {
    maxWidth: 'unset',
    maxHeight: 'unset'
  },
  dialogContent: {
    'padding': 'unset',
    '&:first-child': {
      paddingTop: 'unset'
    }
  }
});

function ToggleButton(props: Readonly<IToggleButtonProperties>): JSX.Element {
  return (
    <FormControlLabel
      control={<Switch
        color={'default'}
        checked={props.checked || false}
        onChange={props.onChange}
        disabled={props.isToogleDisabled}
        classes={{
          switchBase: props.classes.switchBase,
          checked: props.classes.checked,
          bar: props.classes.bar,
        }}
        value={props.value}
      />}
      classes={{
        label: props.classes.formControlLabel,
        disabled: props.classes.disabled
      }}
      label={props.label}
      style={{
        width: '320px'
      }}
    />
  );
}

function Expansion(props: Readonly<IExpansionProperties>): JSX.Element {
  return (
    <ExpansionPanel
      classes={{
        root: props.classes.rootExpansionPanelContainer,
      }}
      defaultExpanded={props?.defaultExpended ?? false}
      expanded={props.isExpanded || false}
      onChange={props.onChange}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: props.classes.rootExpansionSummaryContainer,
          expanded: props.classes.expandedSummaryContainer
        }}
      >
        <Typography className={!props.isExpanded ? styles.expansionSummaryTitle : ''}>{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {props.detailComponent}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

function PrintForm(props: Readonly<IPrintFormProperties>): JSX.Element {
  const { values, onFieldChange, onPrinterChange } = props;

  return (<div className={styles.formFieldRow}>
    <div className={styles.singleColumnForm}>
      <BasicLookupActionField
        style={classesStyles.basicFormFieldWidth}
        placeholder={'Printer'}
        column1Field={'Display'}
        column2Field={'Label'}
        label={'Printer'}
        size={'large'}
        lookupName={'Printer'}
        value={(values && values.PrinterName) || ''}
        display={values && values.PrinterNameDisplay}
        onSelectedItemChange={(item, inputText) => onPrinterChange(item ?? inputText as any)}
        suppressDescription={true}
      />
    </div>
    <div className={styles.singleColumnForm}>
      <TextField
        label={'Copies'}
        size={'small'}
        value={values && values.PrinterNumCopies}
        onChange={onFieldChange('PrinterNumCopies')}
      />
    </div>
  </div>
  );
}

function EmailForm(props: Readonly<IDocumentOutputFormProperties>): JSX.Element {
  const { customerId, supplierId, values, onFieldChange, onLookupChange } = props;
  const contactLookupName = customerId ? 'CustomerContactEmail' : 'SupplierContactEmail';
  const contactLookupParams = customerId ? { CustomerId: customerId } : { Supplier: supplierId };

  return (
    <div className={styles.formFieldRow}>
      <div className={styles.singleColumnForm}>
        <BasicLookupActionField
          suppressValidation
          suppressDescription
          isLookupTypeFixed
          style={classesStyles.formFieldWidth}
          placeholder={''}
          label={'To'}
          size={'large'}
          lookupName={contactLookupName}
          value={values && values.EmailTo}
          params={contactLookupParams}
          onSelectedItemChange={(v, inputText) => { onLookupChange('EmailTo')(v ? v.Display : inputText); }}
          readonly={false}
        />
        <BasicLookupActionField
          suppressValidation
          suppressDescription
          isLookupTypeFixed
          style={classesStyles.formFieldWidth}
          placeholder={''}
          label={'CC'}
          size={'large'}
          lookupName={contactLookupName}
          value={values && values.EmailCC}
          params={contactLookupParams}
          onSelectedItemChange={(v, inputText) => { onLookupChange('EmailCC')(v ? v.Display : inputText); }}
          readonly={false}
        />
        <BasicLookupActionField
          suppressValidation
          suppressDescription
          isLookupTypeFixed
          style={classesStyles.formFieldWidth}
          placeholder={''}
          label={'BCC'}
          size={'large'}
          lookupName={contactLookupName}
          value={values && values.EmailBCC}
          params={contactLookupParams}
          onSelectedItemChange={(v, inputText) => { onLookupChange('EmailBCC')(v ? v.Display : inputText); }}
          readonly={false}
        />
      </div>
      <div className={styles.singleColumnForm}>
        <TextField
          label={'Subject'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.EmailSubject}
          onChange={onFieldChange('EmailSubject')}
        />
        <TextArea
          label={'Message'}
          style={classesStyles.mailTextArea}
          value={values && values.EmailBody}
          onChange={onFieldChange('EmailBody')}
        />
      </div>
    </div>
  );
}

function FaxForm(props: Readonly<IFaxFormProperties>): JSX.Element {
  const { values, onFieldChange, onDateTimeChange, toggleOnChange, isV2 } = props;
  const timeOnChange = (time: any) => { onDateTimeChange('FaxSendTime')(moment(time, 'hh:mm A').format('HH:mm:ss')); };

  return (
    <div className={styles.formFieldRow}>
      <div className={styles.singleColumnForm}>
        <TextField
          label={'Attention'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxAttention}
          onChange={onFieldChange('FaxAttention')}
        />
        <TextField
          label={'Subject'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxSubject}
          onChange={onFieldChange('FaxSubject')}
        />
        <TextField
          label={'Fax number'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxNumber}
          onChange={onFieldChange('FaxNumber')}
        />
        <TextArea
          label={'Note'}
          style={classesStyles.faxTextArea}
          value={values && values.FaxNote}
          onChange={onFieldChange('FaxNote')}
        />
      </div>
      <div className={styles.singleColumnForm}>
        <TextField
          label={'To'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxTo}
          onChange={onFieldChange('FaxTo')}
        />
        <TextField
          label={'From'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxFrom}
          onChange={onFieldChange('FaxFrom')}
        />
        <TextField
          label={'Reference'}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxReference}
          onChange={onFieldChange('FaxReference')}
        />
        <div className={styles.formFieldRow}>
          <div className={styles.singleColumnForm}>
            <DateTextField
              label={'Date'}
              size={'small'}
              style={classesStyles.formFieldWidth}
              value={values && values.FaxSendDate}
              onChange={onDateTimeChange('FaxSendDate')}
            />
          </div>
          <div className={styles.singleColumnForm}>
            <TimeField
              label={'Time'}
              size={'small'}
              format={'hh:mm a'}
              style={classesStyles.formFieldWidth}
              value={values && values.FaxSendTime}
              onChange={timeOnChange}
            />
          </div>
        </div>
        <ToggleButton
          checked={values && values.FaxUseCoverSheet}
          onChange={toggleOnChange('FaxUseCoverSheet')}
          label={'Use cover sheet'}
          value={'FaxUseCoverSheet'}
          classes={props.classes}
        />
        {
          !isV2 && <AutoCompleteField
            label={'Priority'}
            style={classesStyles.formFieldWidth}
            value={values && values.FaxPriority ? values.FaxPriority.toString() : undefined}
            onChange={onFieldChange('FaxPriority')}
            apiPrefs={{
              path: '/CustomTypes/Lookup/Priority/Search',
              method: 'POST',
              params: null
            }}
          />
        }
        <ToggleButton
          checked={values && values.FaxNotifyByEmail}
          onChange={toggleOnChange('FaxNotifyByEmail')}
          label={'Notify fax completion by Email'}
          value={'FaxNotifyByEmail'}
          classes={props.classes}
        />
        <TextField
          label={'Mail'}
          action={{
            iconName: 'Mail',
          }}
          size={'large'}
          style={classesStyles.formFieldWidth}
          value={values && values.FaxNotifyEmailAddress}
          onChange={onFieldChange('FaxNotifyEmailAddress')}
        />
      </div>
    </div>
  );
}
class DocumentOutputDialog extends React.Component<IDocumentOutputDialogProperties, IDocumentOutputDialogState> {

  static contextType: typeof ProcessValidationFormsContext = ProcessValidationFormsContext;

  context!: React.ContextType<typeof ProcessValidationFormsContext>;

  scrollBars: Scrollbars;

  constructor(props: Readonly<IDocumentOutputDialogProperties>) {
    super(props);
    const { values } = props;
    this.state = {
      printOpen: values?.PrintEnabled,
      emailOpen: values?.EmailEnabled,
      faxOpen: values?.EmailEnabled,
      isEmailDisabled: values?.EmailEnabled,
      isFaxDisabled: values?.EmailEnabled,
    };
  }

  componentDidMount(): void {
    const { values } = this.props;
    this.setState({
      printOpen: values?.PrintEnabled,
      emailOpen: values?.EmailEnabled,
      faxOpen: values?.FaxEnabled,
      isEmailDisabled: values?.EmailAvailable,
      isFaxDisabled: values?.FaxAvailable,
    });
  }

  componentDidUpdate(prevProps: Readonly<IDocumentOutputDialogProperties>): void {
    const { values } = this.props;
    if (!values) {
      return;
    }
    const { PrintEnabled, EmailEnabled, FaxEnabled } = values;

    const { values: prevValues } = prevProps || {};

    const { PrintEnabled: PrevPrintEnabled, EmailEnabled: PrevEmailEnabled, FaxEnabled: PrevFaxEnabled } = prevValues || {};

    if (PrevPrintEnabled !== PrintEnabled) {
      this.setState({ printOpen: PrintEnabled });
    }

    if (PrevEmailEnabled !== EmailEnabled) {
      this.setState({ emailOpen: EmailEnabled });
    }

    if (PrevFaxEnabled !== FaxEnabled) {
      this.setState({ faxOpen: FaxEnabled });
    }
  }

  handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { values, documentType, fieldId, onOptionsChanged } = this.props;

    onOptionsChanged(documentType, fieldId, {
      ...values, [name]: event.target.checked
    });
  }

  handleLookupValueChange = (name: keyof IDocumentOutputOptionsFacadeExtended) => (value: any) => {
    const { values, documentType, fieldId, onOptionsChanged } = this.props;

    onOptionsChanged(documentType, fieldId, {
      ...values, [name]: (value && value.Display) ? value.Display : value
    });
  }

  handleInputChange = (name: keyof IDocumentOutputOptionsFacadeExtended) => (value: React.ChangeEvent<HTMLInputElement>) => {
    const { values, documentType, fieldId, onOptionsChanged } = this.props;

    onOptionsChanged(documentType, fieldId, {
      ...values, [name]: (value && value.target) ? value.target.value : value
    });
  }

  updateOptionsAndValidate = async (values: IDocumentOutputOptionsFacadeExtended): Promise<ObjectifiedDocumentOutputOptionsDetailsResponse> => {
    const {applyDocumentOutputOptions = false} = this.props;
    const options = {
      Invoice: this.props.Invoice,
      SalesEntity: this.props.SalesEntity,
      DespatchId: this.props.DespatchId,
      TTDocOut: {
        ...values
      }
    };
    const response = await updateOptions(options, applyDocumentOutputOptions);

    return this.context(response, values, async (newValues) => this.updateOptionsAndValidate(newValues));
  }

  onApplyClick = () => {
    const { values, onApply, applyOptions, documentType, fieldId } = this.props;

    this.updateOptionsAndValidate(values).then((response) => {
      if (response.Status && response.DocumentOutputOptions) {
        if (onApply) {
          onApply(documentType, fieldId, response.DocumentOutputOptions.inlineObject);
        }

        applyOptions(documentType, fieldId, response.DocumentOutputOptions.inlineObject);
      }
    }).catch((err) => {
      showSnackBar({ variant: 'error', message: `Unexpected Error ${err.message}.` });
    });
  }

  onCancelClick = () => {
    const { onCancel, cancelOptions, documentType, fieldId } = this.props;
    if (onCancel) {
      onCancel(documentType, fieldId);
    }
    cancelOptions(documentType, fieldId);
  }

  render(): React.ReactNode {
    const { classes, loading, open, customerId, supplierId, values, comment, isV2, isFetching } = this.props;
    const { emailOpen, faxOpen, printOpen } = this.state;
    const isNonConfirmationScreenInV2 = (isV2 && !this.props?.isNonConfirmationScreen);

    return (
      <Dialog
        disableEnforceFocus
        disableBackdropClick
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby={'confirmation-dialog-title'}
        open={open}
        onClose={this.onCancelClick}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div className={styles.wrapper}>
            {(loading || isFetching) && <div className={styles.loadingOverlay}> <CircularProgress size={CircularProgressSize} className={classes.circularProgressBar} /></div>}
            <div className={styles.outer}>
              <div className={`${styles.inner} ${isNonConfirmationScreenInV2 ? styles.innerV2 : ''}`}>
                <div className={`${styles.controlHeaderBlock} ${isNonConfirmationScreenInV2 ? styles.controlHeaderBlockV2 : ''}`}>
                  <h3 className={styles.titleHeading}>Document Output Options</h3>
                  <div className={styles.documentSelectorBlock}>
                    {(!isV2 || this.props?.isNonConfirmationScreen) && <BasicLookupActionField
                      label={'Document'}
                      lookupName={'OutputDocument'}
                      column1Field={'Display'}
                      column2Field={'Label'}
                      value={(values && values.DocumentId) ? values.DocumentId.toString() : ''}
                      display={(values && values.DocumentIdDisplay) || ''}
                      params={{ DocumentType: values && values.DocumentType }}
                      onSelectedItemChange={(value, inputText) => {
                        this.handleLookupValueChange('DocumentId')(value ? value.Code : inputText);
                        this.handleLookupValueChange('DocumentIdDisplay')(value ? value.Display : inputText);
                      }}
                      size={'large'}
                      style={{
                        width: '320px'
                      }}
                      suppressDescription={true}
                    />}
                    {!isV2 && <BasicLookupActionField
                      label={'Template'}
                      lookupName={'OutputDocumentTemplate'}
                      column1Field={'Display'}
                      column2Field={'Label'}
                      value={(values && values.DocumentTemplateId) ? values.DocumentTemplateId.toString() : ''}
                      display={(values && values.DocumentTemplateIdDisplay) || ''}
                      params={{ DocumentId: values && values.DocumentId }}
                      onSelectedItemChange={(value, inputText) => {
                        this.handleLookupValueChange('DocumentTemplateId')(value ? value.Code : inputText);
                        this.handleLookupValueChange('DocumentTemplateIdDisplay')(value ? value.Display : inputText);
                      }}
                      size={'large'}
                      style={{
                        width: '320px'
                      }}
                      suppressDescription={true}
                    />
                    }
                  </div>
                  <div className={`${styles.additionalSelectorsBlock} ${isNonConfirmationScreenInV2 ? styles.additionalSelectorsBlockV2 : ''}`}>
                    <ToggleButton
                      checked={values && values.DocumentReprint}
                      onChange={
                        (e) => { this.handleCheckboxChange('DocumentReprint')(e); }
                      }
                      label={'Document reprint'}
                      classes={classes}
                    />
                    <h3 className={styles.commentsLabel}>Comments: {comment}</h3>
                  </div>
                  <hr className={styles.separator} />
                  <div className={styles.toggleSelectorsBlock}>
                    <h3 className={styles.outputSelectionLabel}>Output selection:</h3>
                    <div className={styles.toggleButtonContainer}>
                      <ToggleButton
                        checked={values && values.PrintEnabled}
                        onChange={this.handleCheckboxChange('PrintEnabled')}
                        label={'Print'}
                        classes={classes}
                        value={'PrintEnabled'}
                      />
                      <ToggleButton
                        checked={values && values.EmailEnabled}
                        onChange={this.handleCheckboxChange('EmailEnabled')}
                        label={'Email'}
                        classes={classes}
                        value={'EmailEnabled'}
                        isToogleDisabled={!this.state.isEmailDisabled}
                      />
                      <ToggleButton
                        checked={values && values.FaxEnabled}
                        onChange={this.handleCheckboxChange('FaxEnabled')}
                        label={'Fax'}
                        classes={classes}
                        value={'FaxEnabled'}
                        isToogleDisabled={!this.state.isFaxDisabled}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.expansionContainerBlock}>
                  <Scrollbars
                    className={styles.scrollBarStyle}
                    ref={(el) => this.scrollBars = el}
                  >
                    <Expansion
                      classes={classes}
                      defaultExpended={values?.PrintEnabled}
                      isExpanded={printOpen}
                      onChange={() => { this.setState({ printOpen: !printOpen && values && values.PrintEnabled }); }}
                      title={'Print'}
                      detailComponent={
                        <PrintForm
                          customerId={Number(customerId)}
                          values={values}
                          onLookupChange={this.handleLookupValueChange}
                          onFieldChange={this.handleInputChange}
                          onPrinterChange={(item) => {
                            this.handleLookupValueChange('PrinterName')(item.Code);
                            this.handleLookupValueChange('PrinterNameDisplay')(item.Display);
                          }}
                        />}
                    />
                    <Expansion
                      classes={classes}
                      defaultExpended={values?.EmailEnabled}
                      isExpanded={emailOpen}
                      onChange={() => { this.setState({ emailOpen: !emailOpen && values && values.EmailEnabled }); }}
                      title={'E-mail'}
                      detailComponent={
                        <EmailForm
                          customerId={Number(customerId)}
                          supplierId={Number(supplierId)}
                          values={values}
                          classes={classes}
                          onLookupChange={this.handleLookupValueChange}
                          onFieldChange={this.handleInputChange}
                        />}
                    />
                    <Expansion
                      classes={classes}
                      defaultExpended={values?.FaxEnabled}
                      isExpanded={faxOpen}
                      onChange={() => { this.setState({ faxOpen: !faxOpen && values && values.FaxEnabled }); }}
                      title={'Fax'}
                      detailComponent={
                        <FaxForm
                          customerId={Number(customerId)}
                          values={values}
                          classes={classes}
                          onLookupChange={this.handleLookupValueChange}
                          onFieldChange={this.handleInputChange}
                          onDateTimeChange={this.handleInputChange}
                          toggleOnChange={this.handleCheckboxChange}
                          isV2={isV2}
                        />}
                    />
                  </Scrollbars>
                </div>
                <div className={styles.actionFooterBlock}>
                  <Button onClick={this.onCancelClick}
                    variant={'contained'}
                    className={classes.footerActionButton}>
                    <CancelIcon style={classesStyles.cancelButtonIcon} /> CANCEL
                  </Button>
                  <Button onClick={this.onApplyClick}
                    variant={'contained'}
                    classes={{
                      contained: classes.footerActionButton
                    }}
                    className={classes.footerActionButton}>
                    <CheckCircleIcon style={classesStyles.applyButtonIcon} /> APPLY
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(classesStyles, { index: 1 })(DocumentOutputDialog);
