import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { IColDef, IDataGridOptions } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IInvoicesData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IInvoicesState extends IExtendedState<IInvoicesData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'invoices');

const RequiredFields = [
  'EntityView', 'TransactionOption'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IInvoicesData>({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Warehouse: '',
    TransactionOption: 'Unfinalised',
    FromDate: '',
    ToDate: '',
    Invoice: ''
  },
  filterRow: {
    formName: 'SupplierInvoiceFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'TransactionOption',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            }
          ]
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Invoice',
          name: 'Invoice',
          size: 'medium',
          required: false,
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Invoice',
      field: 'Invoice',
      type: 'numericColumn',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice && row.data.InvoiceDocumentNumber) {
          return {
            DocumentNumber: row.data.InvoiceDocumentNumber,
            FieldValue: row.data.Invoice
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry/invoice-details'
      },
      minWidth: 120,
      sort: 'asc'
    },
    { headerName: 'W/H', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Invoice date', field: 'InvoiceDate', type: 'numericColumn', minWidth: 180 },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true, },
    { headerName: 'Invoice total', field: 'InvoiceTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true, },
    { headerName: 'Local total', field: 'LocalTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Local Currency', field: 'LocalCurrency', minWidth: 120, suppressSorting: true, },
    { headerName: 'Charges', field: 'ChargesDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Shipment', field: 'Shipment', minWidth: 120, suppressSorting: true, },
    { headerName: 'Shipment name', field: 'ShipmentName', minWidth: 150, suppressSorting: true, },
  ]
});

export default (state: IInvoicesState = initialState, action: IDataAction): IInvoicesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.selected,
  gridOptions: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.gridOptions,
  columns: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.Columns,
  Actions: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.Actions,
  filterRow: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.filterRow,
  selectedFilters: (state: { supplierEnquiry: { activity: { invoices: IInvoicesState } } }) => state.supplierEnquiry.activity.invoices.data.selectedFilters
});
