import { IFormViewForm } from 'components/FormView';

const CapabilitiesForm: IFormViewForm = {
    id: 'Capabilities',
    layout: {
        rows: 1,
        columns: 2
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'Capabilities',
                fullWidth: true
            },
            position: {
                row: 1,
                col: 1
            },
            children: [
                {
                    id: '1',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Capability',
                        name: 'CapabilityCode',
                        lookupName: 'CapabilityCode',
                        required: true,
                        size: 'medium',
                    }
                }
            ]
        },
    ]
};

export default CapabilitiesForm;
