import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as serviceActivityActions, selectors as serviceActivitySelectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import { dragAndDropActivity } from '../../LookUpList/DragAndDropSources';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';
import { ISchedulerJobSearchFacade, ISchedulerActivitySearchFacade } from 'api/swaggerTypes';
import ServiceActivityCustomItemSection from '../CustomListItemSections/ServiceActivityCustomItemSection';

interface ISchedulerJobSearchFacadeExtended extends ISchedulerJobSearchFacade {
    CustomerIdLabel?: string;
}
const ServiceActivityLookupDrawer = buildSearchLookUpDrawer<ISchedulerJobSearchFacadeExtended, ISchedulerActivitySearchFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ISchedulerJobSearchFacadeExtended, ISchedulerActivitySearchFacade>,
    'searchPlaceholder' | 'operationMode' | 'records' | 'selectedRecord' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'
    | 'searchTextProp' | 'filterHeader' | 'cascadingListKey' | 'cascadingListKeyField' | 'cascadingListFields' | 'suppressSubHeader' | 'appliedFilters' | 'schedulerData' | 'dragAndDropSource' | 'renderCustomItemSection' | 'isItemDraggable'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ISchedulerJobSearchFacadeExtended, ISchedulerActivitySearchFacade>,
    'search' | 'fetchPrevPage' | 'fetchNextPage' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'newSchedulerEvent'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'ProductMovements',
        searchPlaceholder: 'Search for Activities...',
        operationMode: operationSelectors.operationMode(state),
        records: serviceActivitySelectors.all(state) || [],
        selectedRecord: serviceActivitySelectors.selected(state),
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: serviceActivitySelectors.isLoading(state),
        nextPage: serviceActivitySelectors.nextPage(state),
        prevPage: serviceActivitySelectors.prevPage(state),
        pageSize: serviceActivitySelectors.pageSize(state),
        totalPages: serviceActivitySelectors.totalPages(state),
        loadingPrevPage: serviceActivitySelectors.loadingPrevPage(state),
        loadingNextPage: serviceActivitySelectors.loadingNextPage(state),
        searchParams: {},
        suppressSubHeader: true,
        filterHeader: serviceActivitySelectors.filterHeader(state),
        appliedFilters: serviceActivitySelectors.selectedFilters(state),
        keyField: 'ServiceJobId',
        fieldsToDisplay: ['CustomerId', 'CustomerIdLabel', 'Description', null, 'ServiceJobId', 'ScheduleStatus'],
        cascadingListKey: 'Activities',
        cascadingListKeyField: 'ActivityId',
        cascadingListFields: ['ActivityCode', 'ScheduleStatus', 'Description', null],
        schedulerData: serviceActivitySelectors.schedulerData(state),
        dragAndDropSource: dragAndDropActivity,
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: ISchedulerJobSearchFacade & ISchedulerActivitySearchFacade) => {
            if (selectedRecord) {
                matchParams.set('ActivityId', selectedRecord.ActivityId ? selectedRecord.ActivityId.toString() : '');
                matchParams.set('ServiceJobId', selectedRecord.ServiceJobId);
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const activityId = matchParams.get('ActivityId');
            const serviceJobId = matchParams.get('ServiceJobId');
            if (activityId && serviceJobId) {
                return {
                    id: { ActivityId: parseFloat(activityId) || 0, ServiceJobId: serviceJobId },
                };
            }

            return null;
        },
        renderCustomItemSection: (item: any) => {
            return ServiceActivityCustomItemSection(item);
        },

        isItemDraggable: (item: any) => {
            if (item && item.ActivityStatus === 'C') {
                return false;
            }

            return true;
        }
    });

const actionCreators: DispatchProps = {
    search: serviceActivityActions.search,
    searchById: serviceActivityActions.searchById,
    changeSelectedRecord: serviceActivityActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    fetchPrevPage: serviceActivityActions.fetchPrevPage,
    fetchNextPage: serviceActivityActions.fetchNextPage,
    newSchedulerEvent: serviceActivityActions.newSchedulerEvent
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceActivityLookupDrawer);
