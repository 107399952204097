import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { Operation } from 'utils/operations';
import UserNotifications from '../../common/UserNotifications';
import { ICustomerDetailsProps } from '../interfaces';
import { ICustomerDetails, INewCustomerDetails } from 'api/swaggerTypes';
import { Inline, IObjectified } from 'api/utils';
import * as api from 'api/customer';
import { isNull } from 'utils/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class CustomerDetail extends React.Component<ICustomerDetailsProps, { initialValues: ICustomerDetails; valuesSchema: Inline<ICustomerDetails> }> {

  constructor(props: Readonly<ICustomerDetailsProps>) {
    super(props);

    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };
    this.state = {
      initialValues,
      valuesSchema
    };
  }

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      onInitialLoad('CustomerDetails');
    }

    api.getNewCustomerTemplate().then(this.updateInitialValues).catch((err) => { console.warn(err); });
  }

  componentDidUpdate(prevProps: Readonly<ICustomerDetailsProps>): void {
    const { operationMode, selectedCustomer, selectedCustomerSchema, values, isLoadingCreateCustomer, customerError, changeOperationMode } = this.props;
    if ((prevProps.operationMode === Operation.EDIT || prevProps.operationMode === Operation.NEW)
      && operationMode === Operation.SAVE
    ) {
      this.submitFormValues(prevProps.operationMode === Operation.NEW);
    }
    if (operationMode === Operation.DELETE && selectedCustomer) {
      this.deleteAction();
    }
    if (operationMode === Operation.CANCEL || operationMode === Operation.BACK) {
      this.cancelFormSubmission(prevProps);
    }
    if (values && prevProps.values && (values.CustomerType !== prevProps.values.CustomerType)) {
      this.handleCustomerTypeChange();
    }
    if (prevProps.operationMode === Operation.BROWSE && operationMode === Operation.NEW) {
      api.getNewCustomerTemplate().then(this.updateInitialValues).catch((err) => { console.warn(err); });
    }
    if (prevProps.operationMode === Operation.BROWSE && operationMode === Operation.EDIT) {
      this.setState({
        initialValues: selectedCustomer,
        valuesSchema: selectedCustomerSchema
      });
    }
    if (!isLoadingCreateCustomer && prevProps.isLoadingCreateCustomer !== isLoadingCreateCustomer && !customerError) {
      changeOperationMode(Operation.BROWSE);
    }
  }

  initializeForms = () => {
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };
    this.setState({
      initialValues,
      valuesSchema
    });
  }

  handleCustomerTypeChange = () => {
    const { operationMode, selectedCustomer, values, changeFormFieldValue } = this.props;
    let { initialValues } = this.state;

    const isInternalCustomer = values && values.CustomerType === 'I';
    if (operationMode !== Operation.NEW) {
      initialValues = selectedCustomer;
    }

    if (values) {
      if (isInternalCustomer) {
        changeFormFieldValue('SalesEntity', initialValues.SalesEntity);
        changeFormFieldValue('InternalWarehouse', initialValues.InternalWarehouse);
      } else {
        changeFormFieldValue('SalesEntity', '');
        changeFormFieldValue('InternalWarehouse', '');
      }
    }
  }

  updateInitialValues = (customer: IObjectified<INewCustomerDetails>): void => {
    const { inlineObject: initialValues, schema: valuesSchema } = customer;
    this.setState({ initialValues, valuesSchema });
  }

  cancelFormSubmission = (prevProps: Readonly<ICustomerDetailsProps>) => {
    const {
      dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad,
      selectedTab, operationMode, history, selectedCustomer, initializeForm
    } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          changeOperationMode(Operation.BROWSE);
          if (selectedCustomer) {
            initializeForm(selectedCustomer);
          } else {
            onInitialLoad('CustomerDetails');
            changeOperationMode(Operation.BROWSE);
          }
        }
      });
    } else {
      if (operationMode === Operation.CANCEL) {
        changeOperationMode(Operation.BROWSE);
        if (selectedCustomer) {
          initializeForm(selectedCustomer);
        } else {
          onInitialLoad('CustomerDetails');
          changeOperationMode(Operation.BROWSE);
        }
      } else {
        if (prevProps.operationMode === Operation.EDIT) {
          changeOperationMode(Operation.BROWSE);
        } else if (selectedTab !== 'CustomerDetails') {
          onInitialLoad('CustomerDetails');
          changeOperationMode(Operation.BROWSE);
        } else if (selectedTab === 'CustomerDetails') {
          history.push('/dashboard');
        }
      }
    }
  }

  deleteAction = () => {
    const { changeConfirmationDialog, changeOperationMode, resetForm, removeCustomer, selectedCustomer } = this.props;
    changeConfirmationDialog({
      open: true,
      title: 'Delete selected record',
      message: 'Are you sure you want to delete the selected record?',
      okLabel: 'Delete',
      onCancel: () => {
        changeOperationMode(Operation.BROWSE);
      },
      onOk: () => {
        removeCustomer(selectedCustomer.CustomerId);
        resetForm();
        changeOperationMode(Operation.BROWSE);
      }
    });
  }

  showWarningSnackBar = () => {
    showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
  }

  submitFormValues = (create?: boolean): void => {
    const { values, selectedFormAsyncErrors, isValid, formSyncErrors, dirty, updateCustomer, changeOperationMode, createCustomer } = this.props;
    if (!dirty) {
      changeOperationMode(Operation.BROWSE);

      return;
    }

    if (values.AccountingMethod === '0') {
      this.showWarningSnackBar();
      changeOperationMode(Operation.EDIT);

      return;
    }

    if (formSyncErrors) {
      delete formSyncErrors.Abbreviation;
      delete formSyncErrors.Owner;
    }
    if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0) || (selectedFormAsyncErrors && Object.keys(selectedFormAsyncErrors).length > 0)) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        this.showWarningSnackBar();
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError || 'data'}` });
      }
      if (create) {
        changeOperationMode(Operation.NEW);
      } else {
        changeOperationMode(Operation.EDIT);
      }

      return;
    }
    const Values = { ...values, InternalWarehouse: isNull(values.InternalWarehouse) ? '' : values.InternalWarehouse };
    if (create) {
      createCustomer(Values);
    } else {
      updateCustomer({ newValues: Values, customer: values });
      this.setState({ initialValues: values });
      changeOperationMode(Operation.BROWSE);
    }
  }

  getInitialValues = (): { initialValues: ICustomerDetails; valuesSchema: Inline<ICustomerDetails> } => {
    return {
      initialValues: {
        Active: true,
        BreakLevel: '1',
        AdviseMethod: 'N',
        SalesTerritory: 'DEF',
        SalesCategory: 'DEF',
        OrderCategory: 'DEF',
        CreditCode: 'N',
        CurrencyCode: 'AUD',
        TaxClass: 'EXPORT',
        CustomerPriceCode: 'DEF',
        ParetoClass: 'A',
        DeliveryType: '2',
        CustomerType: 'A',
        AccountingMethod: 'B'
      },
      valuesSchema: {
        SalesCategory: { Label: 'Default', Type: 'String', Value: 'DEF' },
        SalesTerritory: { Value: 'DEF', Label: 'Default', Type: 'String' },
        CreditCode: { Value: 'N', Label: 'Normal Account Terms', Type: 'String', ReadOnly: true },
        CurrencyCode: { Value: 'AUD', Label: 'Aust Dollar', Type: 'String' },
        TaxClass: { Value: 'EXPORT', Label: 'Export Customers', Type: 'String' },
        CustomerPriceCode: { Value: 'DEF', Label: 'Standard', Type: 'String' }
      }
    };
  }

  isOwnForm = (): boolean => {
    const allowed = ['CustomerDetails', 'CustomerSetting', 'CustomerFinancial', 'CustomerPricing'];
    const { selectedForm } = this.props;

    return selectedForm && allowed.includes(selectedForm.id);
  }

  getRequiredValueSchema = (valuesSchema: Inline<ICustomerDetails>) => {
    const { values } = this.props;

    if (valuesSchema && values) {
      return {
        ...valuesSchema,
        InternalWarehouse: { ...valuesSchema.InternalWarehouse, ReadOnly: values.CustomerType !== 'I' },
        SalesEntity: { ...valuesSchema.SalesEntity, ReadOnly: values.CustomerType !== 'I' },
      };
    } else {
      return null;
    }
  }

  render(): React.ReactNode {
    const {
      selectedCustomer, selectedCustomerSchema, selectedForm, operationMode, dirty, isValid, formSyncErrors,
      customerNotifications, customerError, isLoadingUpdateCustomer
    } = this.props;

    let { initialValues, valuesSchema } = this.state;
    if (operationMode === Operation.BROWSE && !isLoadingUpdateCustomer) {
      initialValues = selectedCustomer;
      valuesSchema = selectedCustomerSchema;
    }

    const { Backorders } = this.props.values || false;

    return (selectedForm &&
      <React.Fragment>
        {((dirty
          && operationMode === Operation.SAVE) || (operationMode === Operation.BROWSE))
          && <UserNotifications
            notifications={customerNotifications}
            errors={customerError}
            formSyncErrors={!isValid && formSyncErrors}
            entity={'Customer'}
            isValid={isValid}
          />}
        {this.isOwnForm() && <FormView
          formName={'CustomerForm'}
          includeTabsHeight={true}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={this.getRequiredValueSchema(valuesSchema)}
          fieldExtensions={{
            Backorders: {
              onChange: (e) => {
                this.props.changeFormFieldValue('IncludeInBackOrderProcessing', e.target.checked);
              }
            },
            IncludeInBackOrderProcessing: {
              disabled: operationMode === Operation.BROWSE || (operationMode === Operation.EDIT || operationMode === Operation.NEW) && !Backorders,
            }
          }}
        />}
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerDetail);
