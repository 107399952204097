import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import BillToIdActionList from 'utils/data/db/actions/BillToIdActionList';
import SalesOrderActionList from 'utils/data/db/actions/SOrderActions';
import InvoiceActionList from 'utils/data/db/actions/Invoice';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps } from '../interfaces';
import { pathOr } from 'utils/utils';
import './SummaryPanel.scss';
import WarningIconDialog from 'components/common/WarningIconDialog';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  iconWarning: {
    'height': '24px',
    'width': '24px',
    'verticalAlign': 'middle',
    'color': 'red',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  statusContainer: {
    display: 'flex'
  },
});
const pSize = CircularProgressSize;
const paperElevation = PaperElevation;

class SummaryPanel extends React.PureComponent<ISummaryPanelProps> {

  appendParamCE = (actionList: any, pickSlipSummary: any) => {
    if (pickSlipSummary && pickSlipSummary.CustomerId && pickSlipSummary.CustomerId.Value) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: pickSlipSummary.CustomerId.Value, CustomerAccountNumber: pickSlipSummary.CustomerId.Display };

        return item;
      });
    }

    return actionList;
  }

  appendParamSO = (actionList: any, pickSlipSummary: any) => {
    if (pickSlipSummary && pickSlipSummary.SalesOrder && pickSlipSummary.SalesOrder.Value) {
      return actionList.map((item) => {
        item.actionParam = { SalesOrder: pickSlipSummary.SalesOrder.Value };

        return item;
      });
    }

    return actionList;
  }
  appendParamInvoice = (actionList: any, pickSlipSummary: any) => {
    if (pickSlipSummary && pickSlipSummary.Invoice && pickSlipSummary.SalesEntity) {
      return actionList.map((item) => {
        item.actionParam = { InvoiceNumber: pickSlipSummary.Invoice.Value, SalesEntity: pickSlipSummary.SalesEntity.Value };

        return item;
      });
    }

    return actionList;
  }

  getPickSlipDetails = (): { [key: string]: any } => {
    const customerEnqActions = BillToIdActionList;
    const salesOrderActions = SalesOrderActionList;
    const invoiceActionList = InvoiceActionList;
    const { slipDetailsSummary, classes } = this.props;
    const showAlert = pathOr(false, ['Alert', 'Value'], slipDetailsSummary);
    const alertMessage = pathOr(false, ['AlertMessage', 'Value'], slipDetailsSummary);

    return ({
      'INVOICE': <div className={classes.splitCellContainer}>
        <div className={classes.splitCellContent} >
          <span >{pathOr('', ['Invoice', 'Value'], slipDetailsSummary)}</span>
        </div>
        <div className={classes.splitCellAction} >
          {
            <InfoButton actionList={this.appendParamInvoice(invoiceActionList, slipDetailsSummary)}
              disabled={pathOr('', ['Invoice', 'Value'], slipDetailsSummary) === ''}
            />
          }
        </div>
      </div>,
      'DESPATCH': <div>
        <span >{pathOr('', ['DespatchNumber', 'Value'], slipDetailsSummary)}</span>
      </div>
      ,
      'PICK NO': <div>
        <span >{pathOr('', ['PickNumber', 'Value'], slipDetailsSummary)}</span>
      </div>,
      'CUSTOMER': <div className={classes.splitCellContainer}>
        <div className={classes.splitCellContent} >
          <span >{pathOr('', ['CustomerId', 'Display'], slipDetailsSummary)}</span>
          <br />
          <span >{pathOr('', ['CustomerId', 'Label'], slipDetailsSummary)}</span>
        </div>
        <div className={classes.splitCellAction} >
          {
            <InfoButton actionList={this.appendParamCE(customerEnqActions, slipDetailsSummary)}
              disabled={pathOr('', ['CustomerId', 'Value'], slipDetailsSummary) === ''}

            />
          }
        </div>
      </div>,
      'CUSTOMER P/O ': pathOr('', ['CustomerPurchaseOrder', 'Value'], slipDetailsSummary),
      'SALES ORDER': <div className={classes.splitCellContainer}>
        <div className={classes.splitCellContent} >
          <span >{pathOr('', ['SalesOrder', 'Value'], slipDetailsSummary)}</span>
        </div>
        <div className={classes.splitCellAction} >
          {
            <InfoButton actionList={this.appendParamSO(salesOrderActions, slipDetailsSummary)}
              disabled={pathOr('', ['SalesOrder', 'Value'], slipDetailsSummary) === ''}
            />
          }
        </div>
      </div>,
      'STATUS': <div className={classes.statusContainer}>
        <div>{pathOr('', ['Status', 'Value'], slipDetailsSummary)}</div>
        {showAlert &&
          <WarningIconDialog title='Alert' message={alertMessage} />}
      </div>,

    });
  }

  getPlaceDetails = (): { [key: string]: any } => {
    const { slipDetailsSummary, classes } = this.props;

    return ({
      'WAREHOUSE': <div className={classes.splitCellContent} >
        <span >{pathOr('', ['Warehouse', 'Value'], slipDetailsSummary)}</span>
        <br />
        <span >{pathOr('', ['Warehouse', 'Label'], slipDetailsSummary)}</span>
      </div>,
      'TERRITORY': <div className={classes.splitCellContent} >
        <span >{pathOr('', ['Territory', 'Value'], slipDetailsSummary)}</span>
        <br />
        <span >{pathOr('', ['Territory', 'Label'], slipDetailsSummary)}</span>
      </div>,
      'SALES REP': <div className={classes.splitCellContent} >
        <span >{pathOr('', ['SalesRepresentative', 'Value'], slipDetailsSummary)}</span>
        <br />
        <span >{pathOr('', ['SalesRepresentative', 'Label'], slipDetailsSummary)}</span>
      </div>,
      'PICKER': <div className={classes.splitCellContent} >
        <span >{pathOr('', ['PickerId', 'Value'], slipDetailsSummary)}</span>
        <br />
        <span >{pathOr('', ['PickerName', 'Value'], slipDetailsSummary)}</span>
      </div>,
    });
  }

  getSlipMeta = (): { [key: string]: any } => {
    const { slipDetailsSummary } = this.props;

    return ({
      ENTERED: pathOr('', ['EnteredDate', 'Value'], slipDetailsSummary),
      DESPATCHED: pathOr('', ['DespatchedDate', 'Value'], slipDetailsSummary),
      PRIORITY: pathOr('', ['Priority', 'Label'], slipDetailsSummary),
    });
  }

  render(): React.ReactNode {
    const { classes, isLoading } = this.props;
    const invoiceDetails = this.getPickSlipDetails();
    const placeDetails = this.getPlaceDetails();
    const slipMeta = this.getSlipMeta();

    return (
      <div className='summary-table-container'>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Pick Slip details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                className={classes.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(invoiceDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {invoiceDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow className={classes.summaryRow}>
                      <TableCell>
                        <br />
                      </TableCell>
                    </TableRow>
                    {Object.keys(placeDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {placeDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow className={classes.summaryRow}>
                      <TableCell>
                        <br />
                      </TableCell>
                    </TableRow>
                    {Object.keys(slipMeta).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {slipMeta[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, {index: 1})(SummaryPanel);
