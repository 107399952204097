import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/customerEnquiry/detailsTransactions';
import { fetch } from 'api/customerEnquiry/detailsTransactions'
import { selectors as transactionSelectors } from 'ducks/customerEnquiry/transactions';

export const mapStateToProps = state => ({
  gridName: 'CETransactions',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (transactionSelectors.selected(state) || params.get('TransactionId'))
    &&
  {
    TransactionId: (transactionSelectors.selected(state) && transactionSelectors.selected(state).TransactionId) || params.get('TransactionId')
  },
  apiMethod: fetch,
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
