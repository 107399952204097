import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from 'ducks/utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchPrices: (query) => (query),
  }
}, 'foreignPrices')

let initialState = asyncInitialState({
  gridOptions: {
    toolPanel: false,
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Currency', field: 'CurrencyCode', minWidth: 100, },
    { headerName: 'Currency description', field: 'Description', minWidth: 250,  },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Future price', field: 'FuturePriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Future date', field: 'FutureDate', minWidth: 120, }
  ],
  detailRowHeight: 540,
  linesData: [],
  linesSchema: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.fetchPrices:
      return asyncOnRequest(state, action)
    case types.saga.fetchPrices.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          linesData: action.payload
        }
      })
    case types.saga.fetchPrices.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.pricing.foreignPrices,
  {
    linesData: data => data.linesData,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.pricing.foreignPrices.loading,
  gridOptions: state => state.inventoryEnquiry.pricing.foreignPrices.data.gridOptions,
  lineColumns: state => state.inventoryEnquiry.pricing.foreignPrices.data.lineColumns
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

