import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as servicePatternActions, selectors as servicePatternSelectors } from 'ducks/servicePatternMaintenance/servicePattern';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';
import { IServicePatternDetailsFacade } from 'api/swaggerTypes';

const ServicePattern = buildSearchLookUpDrawer<IServicePatternDetailsFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IServicePatternDetailsFacade>,
    'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IServicePatternDetailsFacade>,
    'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'ServicePattern',
        searchPlaceholder: 'Search for Service Patterns...',
        operationMode: operationSelectors.operationMode(state),
        sortingFilters: [
            {
                filter: 'Name',
                label: 'Name'
            },
            {
                filter: 'Description',
                label: 'Description'
            }
        ],
        records: servicePatternSelectors.all(state) || [],
        selectedRecord: servicePatternSelectors.selected(state),
        selectedSortFilter: 'Name',
        selectedSortDirection: 'Ascending',
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: servicePatternSelectors.isLoading(state),
        nextPage: servicePatternSelectors.nextPage(state),
        prevPage: servicePatternSelectors.prevPage(state),
        pageSize: servicePatternSelectors.pageSize(state),
        totalPages: servicePatternSelectors.totalPages(state),
        loadingPrevPage: servicePatternSelectors.loadingPrevPage(state),
        loadingNextPage: servicePatternSelectors.loadingNextPage(state),
        searchParams: {},
        keyField: 'ServicePatternId',
        fieldsToDisplay: ['Name', null, 'Description', null],
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
            if (selectedRecord) {
                matchParams.set('ServicePatternId', selectedRecord.ServicePatternId);
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const serviceItemTypeId = matchParams.get('ServicePatternId');
            if (serviceItemTypeId) {
                return {
                    id: serviceItemTypeId,
                };
            }

            return null;
        },
    });

const actionCreators: DispatchProps = {
    search: servicePatternActions.search,
    searchById: servicePatternActions.searchById,
    changeSelectedRecord: servicePatternActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    fetchNextPage: servicePatternActions.fetchNextPage,
    fetchPrevPage: servicePatternActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServicePattern);
