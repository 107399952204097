import {
    asyncSelectors, IExtendedState, initializeReducer,
    deleteKey
} from 'ducks/utils';
import { Inline, IObjectified } from 'api/utils';
import {
    ISortOrder,
    IActivitySearchFacade, IActivityDetailFacade,
} from 'api/swaggerTypes';

export interface IActivityData {
    selected: string;
    list: IActivityDetailFacade[];
    schemas: Inline<IActivitySearchFacade>[];
    activityData: IObjectified<IActivityDetailFacade>;
    formData: IObjectified<IActivityDetailFacade>;
}

export interface IActivityState extends IExtendedState<IActivityData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchActivityData_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: IActivityData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    activityData: {
        inlineObject: {},
        schema: {}
    },
    formData: {
        inlineObject: {},
        schema: {}
    },
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'jobActivity',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (activityCode: string) => activityCode,
            callback: (data: IActivityData, activityCode: string) => ({ ...data, selected: activityCode })
        },
        resetFormData: {
            action: () => ({}),
            callback: (data: IActivityData) => ({ ...data, formData: { inlineObject: { ActivityCode: null, BomCode: null, LabourProductId: null }, schema: {} }, })
        },
        resetActivityData: {
            action: () => ({}),
            callback: (data: IActivityData) => ({ ...data, activityData: { inlineObject: { ActivityCode: null, BomCode: null, LabourProductId: null }, schema: {} }, selected: NOT_SELECTED })
        },
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; TemplateCode: string; Sort?: ISortOrder }) => (search),
            callback: (data: IActivityData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[] }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return { ...data, list: activities, schemas: schemas, };
            }
        },
        searchById: {
            action: (search: { TemplateCode: string; ActivityCode: string }) => (search),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return { ...data, list: activities, schemas: schemas, activityData: { inlineObject: null, schema: null } };
            }
        },
        fetchActivityData: {
            action: (activityCode: string) => ({ ActivityCode: activityCode }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivityDetailFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    activityData: { inlineObject: activities[0], schema: schemas[0] },
                };
            }
        },
        updateActivity: {
            action: (activityCode: string) => ({ ActivityCode: activityCode }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivityDetailFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    formData: { inlineObject: activities[0], schema: schemas[0] },
                };
            }
        },
        create: {
            action: (TemplateCode: string, ActivityCode: string) => ({ TemplateCode, ActivityCode }),
            successCallback: (data: IActivityData, payload: { ActivityDetails: IObjectified<IActivityDetailFacade> }): IActivityData => {
                const inline = payload.ActivityDetails && payload.ActivityDetails.inlineObject || {};
                const schema = payload.ActivityDetails && payload.ActivityDetails.schema || {};

                return { ...data, activityData: { inlineObject: inline, schema: schema } };
            }
        },
        delete: {
            action: (TemplateCode: string, ActivityCode: string) => ({ TemplateCode, ActivityCode }),
            successCallback: (data: IActivityData, payload: IObjectified<IActivitySearchFacade>): IActivityData => {
                const prevActivities = deleteKey(data.list, payload, 'ActivityCode');
                const prevSchemas = deleteKey(data.schemas, payload, 'ActivityCode');

                return { ...data, list: prevActivities, schemas: prevSchemas };
            }
        }
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ActivityCode === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ActivityCode.Value === data.selected)) || null,
        activityData: (data) => (data.activityData),
        formData: (data) => (data.formData),
    }
);

const syncSelector = {
    activityCode: (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity.data.selected,
    notifications: (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity.notifications,
    error: (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity.error,
    isFormLoading: (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity.fetchActivityData_loading,
    isLookupLoading: (state: { jobTemplateMaintenance: { jobActivity: IActivityState } }) => state.jobTemplateMaintenance.jobActivity.searchById_loading || state.jobTemplateMaintenance.jobActivity.search_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
