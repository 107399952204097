import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as invoiceTypes, actions as invoiceActions } from 'ducks/customerEnquiry/invoices';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/invoices';


function* fetch(action) {
  const { success, failure } = invoiceActions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure,
    { dialogActionCallEffect: fetch }
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(invoiceTypes.fetch, fetch)
}