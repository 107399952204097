import { IState } from './Worksale.reducer';

const WorksaleId = (s: IState) => s.WorksaleId;
const CustomerId = (s: IState) => s.CustomerId;
const salesEntity = (s: IState) => s.salesEntity;
const WorksaleLineId = (s: IState) => s.WorksaleLineId;
const WorksaleDeliveryId = (s: IState) => s.WorksaleDeliveryId;
const WorksaleDeliverySummary = (s: IState) => s.worksaleDeliverySummary;
const WorksaleMessagesOpen = (s: IState) => s.worksaleMessagesOpen;
const WorksaleMessages = (s: IState) => s.worksaleMessages;

const Selectors = {
  WorksaleId,
  CustomerId,
  salesEntity,
  WorksaleLineId,
  WorksaleDeliveryId,
  WorksaleMessagesOpen,
  WorksaleMessages,
  WorksaleDeliverySummary
};

export default Selectors;
