const Why = {
  'id': 'Why',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'props': {
        'label': 'Factors used in price calculation'
      },
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 3
      },
      'children': [
        {
          'id': '1',
          'type': 'TEXT_AREA',
          'props': {
            'label': '',
            'name': 'PriceCalculationFactors',
            'fullWidth': true,
            'style': {
              'height': '655px'
            }
          },
        },
      ]
    },
  ]
};
export default Why;