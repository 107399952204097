import { fetchPost, fetchPut, fetchDelete, objectify, swapObjectValues } from '../utils';
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
const respType = 'None';

export const search = async (data) => {
  const { fetchApi, filters, Page, Sort, SortDirection, diaryType, BatchSize = DEFAULT_PAGE_SIZE } = data;
  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  const params = {
    SortField: Sort,
    Direction: SortDirection === 'desc' ? 'Descending' : 'Ascending'
  };
  return fetchPost(
    fetchApi,
    (filters && {
      ...params,
      FromDate: From,
      ToDate: To,
      System: filters.SystemGenerated,
      UserId: filters.UserId,
      Subject: filters.Subject,
      DefaultSearch: filters.DefaultSearch
    }) || params,
    { BatchPage: Page, DiaryType: diaryType, BatchSize },
    'Inline')
    .then((result) => {
      if (result)
        if (result.DiaryEntry)
          return {
            records: result.DiaryEntry.map(item => objectify(swapObjectValues(item), null, 'Inline')),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        else
          return result;
    })
};

export const create = async (data) => {
  const { createApi, diaryEntry, diaryType } = data;
  if (diaryEntry.DiaryDetails) {
    delete diaryEntry.DiaryDetails;
  }
  if (diaryEntry.params) {
    delete diaryEntry.params;
  }
  const requestData = { ...diaryEntry, Date: moment(diaryEntry.Date, 'DD/MM/YYYY').format('YYYY-MM-DD'), Time: moment(diaryEntry.Time, 'hh:mm A').format('HH:mm:ss') };
  delete requestData.DiaryId
  requestData.SystemGenerated = false;
  return fetchPost(createApi, requestData, { DiaryType: diaryType }, respType)
    .then((result) => result && result.DiaryEntry);
};

export const update = async (data) => {
  const { updateApi, diaryEntry, diaryType } = data;
  if (diaryEntry.DiaryDetails) {
    delete diaryEntry.DiaryDetails;
  }
  if (diaryEntry.params) {
    delete diaryEntry.params;
  }
  const requestData = { ...diaryEntry, Date: moment(diaryEntry.Date, 'DD/MM/YYYY').format('YYYY-MM-DD'), Time: moment(diaryEntry.Time, 'hh:mm A').format('HH:mm:ss') };

  return fetchPut(updateApi, requestData, respType, { DiaryType: diaryType })
    .then((result) => result && result.DiaryEntry);
};

export const deleteEntry = async (data) => {
  const { deleteApi } = data;

  return fetchDelete(deleteApi)
    .then((result) => null);
};

export const searchById = (data) => {
  const { searchByIdApi } = data;
  return fetchPost(searchByIdApi, null, null, 'None').then((result) => result.DiaryEntry);
}
