import { createStyles } from '@material-ui/core';

export const styles = createStyles({
    paper: {
      width: '280px',
      float: 'right',
      padding: '15px',
      margin: '15px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px'
    },
    isInSummaryPanel: {
      padding: 10,
      margin: 10,
      borderRadius: 'unset',
      marginBottom: 0
    },
    table: {
      width: '100%',
      border: '1px white',
    },
    lastTable: {
      width: '100%',
      border: '1px white',
    },
    tableRow: {
      height: 'inherit',
    },
    propertyCell: {
      width: '75px',
      backgroundColor: '#eeeeee',
      border: '1px solid #fff',
      padding: '10px',
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    propertyCellMatchCase: {
      width: '75px',
      backgroundColor: '#eeeeee',
      border: '1px solid #fff',
      padding: '10px',
      fontSize: '10px',
      fontWeight: 'bold',
    },
    valueCell: {
      width: 'calc(100% - 75px)',
      backgroundColor: '#dddddd',
      border: '1px solid #fff',
      padding: '10px',
      fontSize: '15px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    lastValueCell: {
      width: 'calc(100% - 75px)',
      backgroundColor: '#bbbbbb',
      border: '1px solid #fff',
      padding: '10px',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 'bold',
    },
    preLoaderParent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '15px',
    },
    lastPreLoaderParent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subPanelTitle: {
      color: '#000000',
      fontSize: '15px',
      marginBottom: '10px',
    },
    outer: {
      alignItems: 'center',
      display: 'flex'
    },
    numericOuter: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    numericSplitCellAction: {
      flex: '1',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      right: '10px'
    },
    splitCellAction: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
    },
    splitCellContent: {
      flex: '5',
      overflowWrap: 'anywhere'
    },
    value: {
      whiteSpace: 'pre-wrap',
      maxWidth: '10rem',
      display: 'inline-block',
    },
    actionIcon: {
      color: 'rgb(3, 1, 4)'
    }
  });
