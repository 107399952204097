import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DiaryDetails from './DiaryDetails';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as diarySelectors, actions as diaryActions } from 'ducks/common/diary';
import { IApplicationState } from 'ducks/reducers';
import { selectors as staffSelectors } from 'ducks/staff';
import { getFormValues } from 'redux-form';

const mapStateToProps = (state: IApplicationState) => ({
  diary: diarySelectors.selected(state),
  operationMode: operationSelectors.operationMode(state),
  isLoading: diarySelectors.savingData(state),
  notifications: diarySelectors.notifications(state),
  errors: diarySelectors.error(state),
  formValues: getFormValues('DiaryDetails')(state),
  parentTab: uiSelectors.selectedTab(state) === 'CreditDiaryDetails' ? 'CreditDiary' : uiSelectors.selectedTab(state) === 'PurchaseDiaryDetails' ? 'PurchaseDiary' : 'SalesDiary',
  currentUserId: staffSelectors.id(state) ? staffSelectors.id(state) : ''
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    createEntry: diaryActions.create,
    updateEntry: diaryActions.update,
    deleteEntry: diaryActions.remove,
    searchById: diaryActions.searchById
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiaryDetails);
