import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isDirty, reset, getFormValues } from 'redux-form';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as creditNoteSelectors, actions as creditNoteActions } from 'ducks/creditNotes/creditNote';
import { selectors as creditNoteContextSelectors, actions as creditNoteContextActions } from 'ducks/creditNotes/creditNoteContext';
import { selectors as creditNoteLineSelector } from 'ducks/creditNotes/creditNoteLine';
import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as paymentDetailsSelectors } from 'ducks/common/paymentDetails';
import CreditNotes from './CreditNotes';

const FORM_NAME = 'CreditNotes';

const mapStateToProps = (state) => ({
  creditNoteContext: creditNoteContextSelectors.creditNoteContext(state),
  creditNoteContextLoading: creditNoteContextSelectors.creditNoteContextLoading(state),
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedCustomer: customerSelectors.selected(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  context: creditNoteContextSelectors.creditNoteContextError(state),
  creditNoteFetched: creditNoteSelectors.creditNoteFetched(state),
  scope: uiSelectors.scope(state),
  creditNoteForms: creditNoteSelectors.creditNoteForms(state),
  creditNoteLoading: creditNoteSelectors.creditNoteLoading(state),
  isLoading: creditNoteSelectors.creditNoteLoading(state),
  applyResponse: creditNoteSelectors.applyResponse(state),
  selectedWarehouse: uiSelectors.selectedWarehouse(state),
  dirty: isDirty(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isCreditNotesLookupPanelOpen: uiSelectors.isCreditNotesLookupPanelOpen(state),
  creditNoteLineLoading: creditNoteLineSelector.creditNoteLineLoading(state),
  creditNoteLineDetails: creditNoteLineSelector.lineDetails(state),
  creditNoteLineDetailsLoading: creditNoteLineSelector.isLoading(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  paymentDetails: paymentDetailsSelectors.paymentDetails(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCreditNoteContext: creditNoteContextActions.getCreditNoteContext,
    updateCreditNoteContext: creditNoteContextActions.updateCreditNoteContext,
    clearCreditNoteContext: creditNoteContextActions.clearCreditNoteContext,
    parkCreditNote: creditNoteContextActions.parkCreditNote,
    toggleCreditNotesLookupPanel: uiActions.toggleCreditNotesLookupPanel,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeScope: uiActions.changeScope,
    changeSelectedWarehouse: uiActions.changeSelectedWarehouse,
    changeWarehouseLookupOpen: uiActions.changeWarehouseLookupOpen,
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    cancelCreditNote: creditNoteActions.cancelCreditNote,
    updateCreditNoteNote: creditNoteActions.updateCreditNoteNote,
    applyCreditNoteContext: creditNoteActions.applyCreditNoteContext,
    closeMenu: uiActions.closeMainMenu,
    changeValidationDialog: uiActions.changeValidationDialog,
    completeProcessCreditNote: creditNoteActions.completeProcessCreditNote,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes);
