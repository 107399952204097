import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { IDiaryProperties } from '../interfaces';

class Diary extends React.PureComponent<IDiaryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-quote-enquiry/sales-diary':
          onInitialLoad('SalesDiary');
          break;
        case '/customer-quote-enquiry/sales-diary/diary-details':
          onInitialLoad('DiaryDetails');
          break;
        default:
          onInitialLoad('SalesDiary');
      }
    }
  }
  getInitialValues = () => {
    const { selectedTab, selectedQuote } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const Quote = (selectedQuote && selectedQuote.Quote) || params.get('Quote');
    switch (selectedTab) {
      case 'SalesDiary':
      case 'DiaryDetails':
        return { initialValues: { params: Quote && { DocumentNumber: Quote } }, valuesSchema: {} };
      default: return null;
    }
  }
  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initials = this.getInitialValues();
    const initialValues = initials ? initials.initialValues : null;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab === 'SalesDiary' ? 'Diary' : selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Diary);
