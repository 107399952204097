import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { INotePadProperties } from '../interfaces';

class NotePad extends React.PureComponent<INotePadProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/notepad':
          onInitialLoad('Notepad');
          break;
        default:
          onInitialLoad('OrderDetails');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedOrder
    } = this.props;
    const params = (new URLSearchParams(location.search));
    const salesOrder = params.get('SalesOrder') || '';

    return {
      params: {
        SalesOrder: (selectedOrder && selectedOrder.SalesOrder) || salesOrder,
      }
    };

  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={this.getInitialValues()}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(NotePad);
