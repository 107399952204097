
export const summaryTableData = [
  {
    objectName: 'Header',
    isLoading: 'invoiceAllocationLoading',
    children: [
      {
        label: 'Transaction Total',
        fieldNames: [
          'TransactionTotal'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      },
      {
        label: 'Merchant Fee Recovery',
        fieldNames: [
          'MerchantFeeRecovery'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      },
      {
        label: 'Cash Rounding',
        fieldNames: [
          'CashRounding'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      }
    ]
  },
  {
    objectName: 'Header',
    isLoading: 'invoiceAllocationLoading',
    children: [
      {
        label: 'Remaining On Account',
        fieldNames: [
          'OnAccount'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      },
      {
        label: 'Payment Total',
        fieldNames: [
          'PaymentTotal'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      }
    ]
  },
  {
    objectName: 'Header',
    isLoading: 'invoiceAllocationLoading',
    label: 'Cash Payment',
    children: [
      {
        label: 'Tendered',
        fieldNames: [
          'TenderedAmount'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      },
      {
        label: 'Change Due',
        fieldNames: [
          'ChangeDueAmount'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true,
          emphasize: true
        }
      }
    ]
  }
];
