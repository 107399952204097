import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as salesOrderSelectors, actions as salesOrderActions } from 'ducks/inventoryEnquiry/sales/salesOrders';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { search } from 'api/inventoryEnquiry/sales/salesOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { actions as formActions } from 'ducks/form';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IESSalesOrders',
  columnDefs: salesOrderSelectors.columns(state),
  rowData: salesOrderSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: salesOrderSelectors.gridOptions(state),
  isLoading: salesOrderSelectors.isLoading(state),
  filterRow: salesOrderSelectors.filterRow(state),
  selectedFilters: salesOrderSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) && {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  appliedFilters: salesOrderSelectors.filterRow(state) && filterSelectors.getFilters(state, salesOrderSelectors.filterRow(state).formName),
  apiMethod: search
})

export const actions = {
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: salesOrderActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
