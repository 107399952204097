export const REVIEW_JOB_MODAL_TITLES = {
    1: 'Job Selection',
    2: 'Material & Labour Issued',
    3: 'Checklist Status',
    4: 'Summary & Adjustment',
};

export const SELECT_JOB_TABLE_COLUMN_DEFS = [
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Job ID',
        field: 'ServiceJobId',
        minWidth: 130
    },
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Job Name',
        field: 'Description',
        minWidth: 200
    },
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Customer',
        tooltipField: 'CustomerIdLabel',
        valueGetter: function (params: any): string {
            return `${params.data.CustomerIdDisplay}-${params.data.CustomerIdLabel}`;
        },
        minWidth: 210
    },
    {
        headerName: 'End Date',
        field: 'SchedulerEndDate',
        minWidth: 100
    },
];

export const SCHEDULE_ACTIVITY_TABLE_COLUMN_DEFS = [
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Start Time',
        field: 'StartTimeDisplay',
        minWidth: 200
    },
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'End Time',
        field: 'EndTimeDisplay',
        minWidth: 200
    },
];
