import React from 'react';
import DataGrid, { Column, Editing, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button } from 'devextreme-react/data-grid';
import { IServiceAgreementScheduleGridProps } from './ServiceAgreementScheduleGrid.properties';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import styles from './ServiceAgreementScheduleGrid.styles';
import { FREQUENCY_TYPES } from '../../ServiceAgreementMaintenance.constants';

const COMMAND_COLUMN_WIDTH = 75;
const LARGE_COLUMN_MIN_WIDTH = 200;
const LOADING_INDICATOR_HEIGHT_WIDTH = 50;

const ServiceAgreementScheduleGrid = (props: Readonly<IServiceAgreementScheduleGridProps>) => {
    const {
        classes,
        loading = false,
        disabled = false,
        itemsData,
        dataGridRef,
        onEditClicked,
        onUpdate,
        onDelete,
        onInsert,
        changeConfirmationDialog,
        onRowRecordDelete,
    } = props;

    React.useEffect(
        () => {
            const gridRef = dataGridRef as any;
            if (gridRef.current) {
                if (loading) {
                    gridRef.current.instance.beginCustomLoading('');
                } else {
                    gridRef.current.instance.endCustomLoading();
                }
            }
        },
        [loading]
    );

    const deleteClick = ({row}) => {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the selected record?',
            okLabel: 'Delete',
            onCancel: () => null,
            onOk: () => {
                onRowRecordDelete(row.key);
            }
        });
    };

    return (
        <Paper className={classes.parentContainer} >
            <DataGrid
                disabled={disabled}
                keyExpr={'ServiceAgreementScheduleId'}
                ref={dataGridRef}
                dataSource={itemsData}
                className={classes.scheduleItemsDataGrid}
                repaintChangesOnly={false}
                remoteOperations={true}
                columnResizingMode={'nextColumn'}
                noDataText=''
                allowColumnReordering={true}
                allowColumnResizing={true}
                onRowUpdated={() => {
                    if (onUpdate) {
                        onUpdate(itemsData);
                    }
                }
                }
                onRowInserted={() => {
                    if (onInsert) {
                        onInsert(itemsData);
                    }
                }}
                onRowRemoved={() => {
                    if (onDelete) {
                        onDelete(itemsData);
                    }
                }}
                showBorders={false}
                sorting={{
                    mode: 'none'
                }}
                hoverStateEnabled={true}
            >
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    confirmDelete={true}
                    allowUpdating={false}
                />
                <Scrolling mode={'virtual'} />
                <Column
                    caption={'Service Item'}
                    editorOptions={{
                        maxLength: 100,
                    }}
                    cellRender={(params) => {
                        return `${params.data.ServiceItemDisplay || ''} - ${params.data.ServiceItemLabel}`;
                    }}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column
                    dataField={'FrequencyValue'}
                    caption={'Frequency'}
                    editorOptions={{
                        maxLength: 100,
                    }}
                    alignment={'left'}
                    cellRender={(params) => {
                        return `${params.data.FrequencyValue || ''} - ${FREQUENCY_TYPES[params.data.FrequencyType] || ''}`;
                    }}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column
                    caption={'Next Job'}
                    editorOptions={{
                        maxLength: 100,
                    }}
                    cellRender={(params) => {
                        return `${params.data.NextSequenceNumber || ''} - ${params.data.TemplateCodeLabel}`;
                    }}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column
                    dataField={'NextServiceDue'}
                    caption={'Next Service'}
                    editorOptions={{
                        maxLength: 100,
                    }}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column
                    dataField={'Comment'}
                    caption={'Comment'}
                    editorOptions={{
                        maxLength: 100,
                    }}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
                    <Button icon={'edit'} onClick={onEditClicked} />
                    <Button name={'delete'} icon={'trash'} onClick={deleteClick} />
                    <Button name={'undelete'} icon={'undo'} />
                </Column>
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <LoadPanel shading={false} height={LOADING_INDICATOR_HEIGHT_WIDTH} width={LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
        </Paper>
    );
};

export default withStyles(styles, { index: 1 })(ServiceAgreementScheduleGrid);
