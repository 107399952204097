import { IFormViewForm } from 'components/FormView';

const PriceOverride: { [x: string]: IFormViewForm } = {
  UserAuthAndReasonScreen: {
    id: 'PriceOverrideDialogForm',
    layout: {
      rows: 1,
      columns: 1,
    },
    fields: [
      {
        id: '1',
        type: 'PAPER_CONTAINER',
        visible: true,
        props: {
          name: 'activity',
          fullWidth: true,
          elevation: 0
        },
        position: {
          row: 1,
          col: 1,
          colspan: 3

        },
        children: [
          {
            id: 1,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'Price override reason',
              name: 'OverrideReason',
              lookupName: 'Poreason',
              size: 'medium',
              required: true
            }
          },
          {
            id: 2,
            type: 'TEXT_AREA',
            props: {
              label: '',
              name: 'ReasonComment',
              fullWidth: true,
              style: {
                height: 148,
                marginBottom: 20
              }
            },
          },
          {
            id: 3,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'User ID',
              name: 'PriceOverrideUserId',
              lookupName: 'Personnel',
              size: 'small',
              required: true,
            }
          },
          {
            id: 13,
            type: 'TEXT_FIELD',
            props: {
              label: 'PIN',
              name: 'Pin',
              placeholder: '****',
              size: 'small',
              type: 'password',
              inputProps: {
                maxLength: 4
              },
              required: false
            }
          },
        ]
      }
    ]
  },
  UserAuthScreen: {
    id: 'PriceOverrideDialogForm',
    layout: {
      rows: 1,
      columns: 1,
    },
    fields: [
      {
        id: '1',
        type: 'PAPER_CONTAINER',
        visible: true,
        props: {
          name: 'activity',
          fullWidth: true,
          elevation: 0
        },
        position: {
          row: 1,
          col: 1,
          colspan: 3

        },
        children: [
          {
            id: 4,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'User ID',
              name: 'PriceOverrideUserId',
              lookupName: 'Personnel',
              size: 'small',
              required: true,
            }
          },
          {
            id: 13,
            type: 'TEXT_FIELD',
            props: {
              label: 'PIN',
              name: 'Pin',
              placeholder: '****',
              size: 'small',
              type: 'password',
              required: false,
              inputProps: {
                maxLength: 4
              }
            }
          },
        ],
      }
    ]
  },
  ReasonScreen: {
    id: 'PriceOverrideDialogForm',
    layout: {
      rows: 1,
      columns: 1,
    },
    fields: [
      {
        id: '1',
        type: 'PAPER_CONTAINER',
        visible: true,
        props: {
          name: 'activity',
          fullWidth: true,
          elevation: 0
        },
        position: {
          row: 1,
          col: 1,
          colspan: 3

        },
        children: [
          {
            id: 1,
            type: 'EX_LOOKUP_FIELD',
            props: {
              label: 'Price override reason',
              name: 'OverrideReason',
              lookupName: 'Poreason',
              size: 'medium',
              required: true
            }
          },
          {
            id: 2,
            type: 'TEXT_AREA',
            props: {
              label: '',
              name: 'ReasonComment',
              fullWidth: true,
              style: {
                height: 148
              }
            },
          }]
      },
    ]
  }

};

export default PriceOverride;
