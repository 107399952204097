import React from 'react';
import { withStyles } from '@material-ui/core';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { TemplateSectionProps } from '../DynamicExtractModal.properties';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';

const TemplateSection = (props: TemplateSectionProps) => {
    const {
        classes,
        details
    } = props;

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const CurrentPanel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);

    const [isLookupInError, setIsLookupInError] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            if (!isLookupInError && WizardDetails?.Details?.TemplateId && WizardDetails?.Details?.TemplateId !== '') {
                contextDispatch({
                    setErrorState: { InError: false, Panel: '' }
                });
            } else {
                contextDispatch({
                    setErrorState: { InError: true, Panel: CurrentPanel }
                });
            }
        },
        [isLookupInError, WizardDetails?.Details?.TemplateId]
    );

    const OnSelectionChanged = (value: any) => {
        if (value && value.Code !== '') {
            contextDispatch({
                setWizardDetails: { ...WizardDetails, Details: { ...WizardDetails?.Details, TemplateId: value.Code } }
            });
        }
    };

    const handleLookupError = React.useCallback(
        (error: boolean) => {
            setIsLookupInError(error);
        },
        [setIsLookupInError]
    );

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <BasicLookupActionField
                // className={classes.customerLookupField}
                placeholder={'Template'}
                label={'Template'}
                size={'large'}
                searchScreenTitle={'Template Lookup'}
                lookupName={'ActiveExtractTemplate'}
                value={WizardDetails?.Details?.TemplateId}
                required
                onSelectedItemChange={(item) => OnSelectionChanged(item as any)}
                suppressDescription={false}
                descriptionField={'Label'}
                hasError={(value) => { handleLookupError(value); }}
                column1Field='Display'
                column2Field='Description'
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(TemplateSection);
