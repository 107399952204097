import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchPut, fetchDelete, fetchGet, createRequestObject, objectify } from '../utils'

export const getSelectedDetails = (supplierId) => {
  return fetchGet(`SupplierEnquiry/Supplier/${supplierId}`)
    .then((result) => result && result.Supplier)
}

export const search = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchPost('SupplierEnquiry/SearchSuppliers', { SearchText, Sort, BatchPage, BatchSize })
    .then((something) => ({
      records: something.SearchSuppliers.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }))
}

export const searchById = (supplierId) => {
  const apiCall = fetchGet(`SupplierEnquiry/SupplierDetails/Supplier/${supplierId}`, {}, 'Inline')
    .then((result) => ({
      records: [{ inlineObject: objectify(result.SupplierDetail), schema: result.SupplierDetail }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }));

  return apiCall;
}

export const update = (data) => {
  const respType = 'Inline'
  const { supplier, newValues } = data
  const updatedSupplier = Object.assign(supplier, createRequestObject(newValues))

  return fetchPut(`SupplierEnquiry/Supplier/${supplier.SupplierId}`, updatedSupplier, respType)
    .then((result) => result.Supplier ? Object.assign({}, { inlineObject: objectify(result.Supplier, null, respType), schema: result.Supplier }) : result)
}

export const create = (data) => {
  const respType = 'Inline'
  return fetchPost('SupplierEnquiry/CreateSupplier', createRequestObject(data), null, respType)
    .then((result) => result.Supplier ? Object.assign({}, { inlineObject: objectify(result.Supplier, null, respType), schema: result.Supplier }) : result)
}

export const remove = (supplierId) => {
  return fetchDelete(`SupplierEnquiry/Supplier/${supplierId}`)
    .then((result) => supplierId)
}

export const getSupplierDetails = (Supplier) => {
  const respType = 'Inline'
  const { supplierId } = Supplier;
  return fetchGet(`SupplierEnquiry/SupplierDetails/Supplier/${supplierId.Supplier}`, null, respType).then((result) => {
    if (result && result.SupplierDetail) {

      return ({
        schema: result.SupplierDetail,
        values: objectify(result.SupplierDetail)
      });
    }
  });
}

export const getPrimaryContacts = (Supplier) => {
  const respType = 'None'
  const { supplierId } = Supplier;
  return fetchGet(`SupplierEnquiry/PrimaryContacts/Supplier/${supplierId.Supplier}`, null, respType).then((result) => {
    if (result && result.SupplierPrimaryContacts) {

      return ({
        values: result.SupplierPrimaryContacts.map((val) => {
          return Object.assign({}, {
            ContactNumber: val.ContactNumber,
            Primary: val.Primary,
            type: val.Abbreviation,
            title: val.ContactName,
            phone: val.Phone,
            email: val.Email
          })
        })
      });
    }
  });
}

export const getContactDetails = ({ Supplier, ContactNumber }) => {
  const respType = 'Inline'
  return fetchGet(`/SupplierEnquiry/ContactDetail/Supplier/${Supplier}/Contact/${ContactNumber}`, null, respType)
    .then((result) => {
      return {
        inlineObject: objectify(result.SupplierContactDetail),
        schema: result.SupplierContactDetail
      }
    })
}
