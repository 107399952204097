import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
  }
}, 'salesAudit');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'SalesAuditDetails',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Sales Audit', field: 'AuditCode', minWidth: 150, suppressSorting: true, },
    { headerName: 'BOM Component', field: 'BOMComponentDisplay', minWidth: 100, suppressSorting: true, },
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Product', field: 'ProductCode',  minWidth: 150, suppressSorting: true, },
    { headerName: 'Product Group', field: 'ProductGroup', minWidth: 250, suppressSorting: true, },
    { headerName: 'Product Description', field: 'ProductDescription', minWidth: 250, suppressSorting: true, },
    { headerName: 'Audit Description', field: 'AuditDescription', minWidth: 250, suppressSorting: true, }
  ],
  lines: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
  
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.salesAudit,
  {
    lines: data => data.lines,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.salesAudit.loading,
  gridOptions: state => state.salesInvoiceEnquiry.salesAudit.data.gridOptions,
  lineColumns: state => state.salesInvoiceEnquiry.salesAudit.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)