import React from 'react';
import DataGrid, { Column, Editing, Scrolling, RowDragging, Button } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IDashboardMaintenanceGridProps } from './DashboardMaintenanceGrid.properties';
import styles from './DashboardMaintenanceGrid.styles';

const LARGE_COLUMN_MIN_WIDTH = 75;
const COMMAND_COLUMN_WIDTH = 50;

const ChecklistsGrid = (props: IDashboardMaintenanceGridProps) => {
    const {
        classes,
        disabled = false,
        dataGridRef,
        data,
        onDelete
    } = props;

    return (<Paper className={classes.parentContainer} >
        <DataGrid
            dataSource={data}
            disabled={disabled}
            keyExpr={'Id'}
            ref={dataGridRef}
            className={classes.widgetDataGrid}
            repaintChangesOnly={true}
            remoteOperations={true}
            columnResizingMode={'nextColumn'}
            allowColumnReordering={true}
            allowColumnResizing={true}
            noDataText=''
            showBorders={false}
            sorting={{
                mode: 'none'
            }}
            hoverStateEnabled={true}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={false}
                confirmDelete={true}
            />
            <RowDragging
                allowReordering={true}
                showDragIcons={true}
                onReorder={(e) => {
                    const visibleRows = e.component.getVisibleRows();
                    const toIndex = data.indexOf(visibleRows[e.toIndex].data);
                    const fromIndex = data.indexOf(e.itemData);

                    data.splice(fromIndex, 1);
                    data.splice(toIndex, 0, e.itemData);

                    e.component.refresh();
                }}
            />
            <Scrolling mode='virtual' />
            <Column
                caption={'Widget Name'}
                dataField={'WidgetIdLabel'}
                minWidth={LARGE_COLUMN_MIN_WIDTH}
            />
            <Column
                caption={'Widget Type'}
                dataField={'WidgetTypeIdLabel'}
                minWidth={LARGE_COLUMN_MIN_WIDTH}
            />
            <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
                <Button name={'delete'} icon={'trash'} onClick={onDelete} />
            </Column>
        </DataGrid>
    </Paper>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(ChecklistsGrid));
