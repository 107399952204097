import { call, put, CallEffect, Effect, select, take } from 'redux-saga/effects';
import { actions as staffActions, selectors as staffSelectors } from 'ducks/staff';
import { ApiError } from 'api/baseApi';
import { ExtendedActions, CombinedActions, SagaActions } from 'ducks/utils';
import { actions as uiActions } from 'ducks/ui';
import { channel } from 'redux-saga';
import { IValidationDialogOptions } from 'components/common/ValidationDialog/ValidationDialog.properties';
import { FormsValidator } from 'components/common/ValidationForms/validation';
import { isNull, pathOr } from 'utils/utils';
import { IFormInfo } from 'api/swaggerTypes';
import { showSnackBar, VARIANT } from 'components/common/SnackBars/SnackBar.hooks';

const HTTP_NOT_AUTHORIZED = 401;
const HTTP_BAD_REQUEST = 400;

function* showErrorSnackBar(data: any): IterableIterator<Effect> {
  showSnackBar({ variant: 'error', message: data.errorMsg, detail: data.detail });
}

/**
 * If the error is flagged as showUser, returns the error message, otherwise shows a generic error.
 * @param error The error object returned from the API
 * @returns error.message or a generic error as appropriate.
 */
export const getErrorMessage = (error: ApiError): string => {
  if (error.showUser) {
    return error.message;
  }

  return 'Server is down/restarting, Please contact System Admin for support.';
};

interface IOptions {
  postApiSuccessCallEffect?(...args: any[]): any;
  postApiFailureCallEffect?(...args: any[]): any;
  dialogActionCallEffect?(...args: any[]): any;
}

function* handleApiCall<T = any>(apiCallEffect: CallEffect, successAction: (data: T) => any, failureAction: (data: string) => any, options?: IOptions): IterableIterator<Effect> {
  const loggedIn = yield select(staffSelectors.loggedIn);
  const { postApiSuccessCallEffect = null, postApiFailureCallEffect = null, dialogActionCallEffect = null } = options || {};
  try {
    let response: any;
    response = yield apiCallEffect;

    const statusUndefinedOrTrue = response.Status === undefined || response.Status;

    if (statusUndefinedOrTrue && response.Forms === undefined) {
      yield put(successAction(response));
    }

    if (postApiSuccessCallEffect && statusUndefinedOrTrue) {
      yield call(postApiSuccessCallEffect, response);
    }

    if (response.Status === false) {
      if (postApiFailureCallEffect) {
        yield call(postApiFailureCallEffect);
      }
      yield put(failureAction(''));
    }

    const payload = pathOr({}, ['payload', 'args', 0], apiCallEffect);
    if (dialogActionCallEffect) {
      yield call(processValidationForms, response, payload, dialogActionCallEffect);
    }

    yield call(checkAndShowSnackbar, response);

  } catch (error) {
    if (error.res && error.res.status === HTTP_NOT_AUTHORIZED && loggedIn) {
      try {
        yield put(staffActions.saga.logout.success(null));
      } catch (error) {
        yield put(staffActions.saga.logout.success(null));
      }
    }
    yield call(checkAndShowError, error);
    if (postApiFailureCallEffect) {
      yield call(postApiFailureCallEffect, error);
    }
    yield put(failureAction(getErrorMessage(error)));
  }
}

/**
 * Creates a call effect to handle the API call, and then call either the success action or failure action as appropriate.
 * @template The expected API response type.
 * @param apiCallEffect API call effect
 * @param successAction Success Redux action
 * @param failureAction Failure Redux action
 * @param options Contains FormValidation and Continuation Call Effects
 * @returns A Redux call effect
 */
export function callApi<T = any>(apiCallEffect: CallEffect, successAction: (data: T) => any, failureAction: (data: string) => any, options?: IOptions): CallEffect {
  return call(handleApiCall, apiCallEffect, successAction, failureAction, options);
}

type ActionType<A extends object, S extends object, K extends keyof CombinedActions<A, S>> = ReturnType<CombinedActions<A, S>[K]>;
type ActionData<A extends object, S extends object, K extends keyof CombinedActions<A, S>> = ReturnType<CombinedActions<A, S>[K]>['data'];
type SuccessData<D extends object, A extends object, K extends keyof SagaActions<D, A>> = Parameters<SagaActions<D, A>[K]['successCallback']>[1];

/**
 * Connects the async action and it's success/failure actions (saga) to an API function.
 * @template D The reducers data type (e.g. IContactData)
 * @template A The async actions
 * @template S The sync actions
 * @template K The async action to attach.
 * @param extendedAction The actions object to attach to the API
 * @param actionName The action name to attach to the API (an Async Action)
 * @param api The API function to call when the action is invoked.
 * @yields A sequence of Redux call effects.
 */
export function connectSagaToApi<D extends object, A extends object, S extends object, K extends keyof A>(
  extendedAction: ExtendedActions<D, A, S>,
  actionName: K,
  api: (data: ActionData<A, S, K>) => Promise<SuccessData<D, A, K>>)
  : (action: ActionType<A, S, K>) => IterableIterator<Effect> {
  return function* (payload: ActionType<A, S, K>): IterableIterator<Effect> {
    const { success, failure } = extendedAction.saga[actionName];
    yield call(handleApiCall, call(api, payload.data), success, failure);
  };
}

function* checkAndShowError(error: any): IterableIterator<Effect> {
  const errorInfo: { errorMsg: string; detail: Response & { ExceptionDetails?: string } } = yield call(captureErrorInfo, error);
  if (errorInfo.errorMsg) {
    yield call(showErrorSnackBar, errorInfo);
  }
}

function displaySnackBar(MessageType: string, message: string): void {
    const variant = VARIANT[(MessageType as 'Success' | 'Information' | 'Alert' | 'Error')];
    showSnackBar({variant:  variant as 'error' | 'success' | 'warning' | 'info', message});
}
function* checkAndShowSnackbar(response: any): IterableIterator<Effect> {
  const forms = response?.Forms;

  const checkAndShowSnackBar = (form: IFormInfo) => { displaySnackBar(form.MessageType, form?.Message); };

  if (forms?.[0].DisplayType === 'StatusMessage') {
    forms.forEach(checkAndShowSnackBar);
  }
}

export async function captureErrorInfo(error: { res: Response; message: string }): Promise<{ errorMsg: string; detail?: Response & { ExceptionDetails?: string } }> {
  let errorMsg: string;
  let parsedResp: any;

  if (error && error.res) {
    if (error.res.status === HTTP_BAD_REQUEST) {
      try {
        parsedResp = await error.res.json();
        const { ValidationErrors, Message } = parsedResp;
        errorMsg = (ValidationErrors && ValidationErrors[0] && ValidationErrors[0].Message)
          || Message || error.message;

      } catch (err) {
        errorMsg = error.message;
      }
    } else {
      errorMsg = error.message;
    }
    if (isNull(parsedResp)) {
      try {
        parsedResp = await error.res.json();
      } catch (err) {
        parsedResp = {};
      }
    }
    const { ExceptionDetails = null } = parsedResp;

    if (typeof ExceptionDetails === 'string') {
      return { errorMsg, detail: { ...error.res, ExceptionDetails } };
    } else if (Array.isArray(ExceptionDetails) && !isNull(ExceptionDetails)) {
      return { errorMsg, detail: { ...error.res, ExceptionDetails: pathOr('', [0, 'Message'], ExceptionDetails) } };
    } else {
      return { errorMsg, detail: error.res };
    }
  }

  return { errorMsg, detail: undefined };
}

const changeValidationDialogChannel = channel();
export const changeValidationDialogCallback = (data: IValidationDialogOptions) => { changeValidationDialogChannel.put(uiActions.changeValidationDialog(data)); };

export function* processValidationForms(response: any, formValues: any, postFormsProcessing: any): IterableIterator<Effect> {

  const forms: IFormInfo[] = pathOr([], ['Forms'], response);

  if (!isNull(forms)) {
    const validator = new FormsValidator(changeValidationDialogCallback);
    const sortedForms = forms.sort((lhs, rhs) => lhs.Sequence - rhs.Sequence);

    try {
      const validationResponse: { submitFormValues: boolean; formValues: any } = yield call(validator.processValidationForms, sortedForms, { formValues, response });
      if (postFormsProcessing && validationResponse.submitFormValues) {
        yield call(postFormsProcessing, { data: validationResponse.formValues });
      }
    } catch (err) { return; }

  }
}

export function* watchValidationDialogChannel(): IterableIterator<Effect> {
  while (true) {
    const action = yield take(changeValidationDialogChannel);
    yield put(action);
  }
}

const snackBarChannel = channel();
export const snackBarCallback = (response: IFormInfo) => { displaySnackBar(response.MessageType, response.Message); };

export function* watchSnackBarChannel(): IterableIterator<Effect> {
  while (true) {
    const action = yield take(snackBarChannel);
    yield put(action);
  }
}
