import React from 'react';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IExtractReferenceProps } from '../DynamicExtractModal.properties';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';

const ExtractReference = (props: IExtractReferenceProps) => {
    const {
        classes,
        details
    } = props;

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <TextField
                label={'Reference'}
                name={'Reference'}
                size='large'
                value={WizardDetails?.Reference}
                onBlur={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, Reference: v?.target?.value }
                    });
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(ExtractReference);
