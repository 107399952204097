import { combineReducers } from 'redux';
import serviceItemType, { IServiceItemTypeState } from './serviceItemType';

export interface IServiceItemTypeMaintenanceState {
  serviceItemType: IServiceItemTypeState;
}
const combined = combineReducers<IServiceItemTypeMaintenanceState>({
  serviceItemType
});

export default combined;
