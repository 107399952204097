import { isDirty, reset, getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as balancesActions, selectors as balancesSelectors } from 'ducks/supplierEnquiry/balances';
import { selectors as supplierSelectors, actions as supplierActions } from 'ducks/supplierEnquiry/supplier';
import { actions as transactionActions, selectors as transactionSelectors } from 'ducks/supplierEnquiry/supplierTransactions';
import { actions as detailTransactionActions } from 'ducks/supplierEnquiry/supplierDetailsTransactions';
import { actions as shipmentActions, selectors as shipmentSelectors } from 'ducks/supplierEnquiry/purchasing/localShipments';
import { actions as quotesActions, selectors as quotesSelectors } from 'ducks/supplierEnquiry/quotes';
import { actions as salesOrdersActions, selectors as salesOrdersSelectors } from 'ducks/supplierEnquiry/salesOrders';
import { actions as backordersActions, selectors as backordersSelectors } from 'ducks/supplierEnquiry/backorders';
import { selectors as contactSelectors } from 'ducks/contact';
import { selectors as addressSelectors } from 'ducks/address';
import { selectors as supplierProductSelectors, actions as supplierProductActions } from 'ducks/customerProduct';
import { actions as supplierPricingActions, selectors as supplierPricingSelectors } from 'ducks/supplierEnquiry/pricing';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { selectors as marketingSelectors } from 'ducks/supplierEnquiry/marketing';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { actions as invoiceActions } from 'ducks/supplierEnquiry/activity/invoices';
import { actions as grnActions } from 'ducks/supplierEnquiry/activity/grns';
import { actions as movementsActions } from 'ducks/supplierEnquiry/activity/movements';
import { actions as freightActions } from 'ducks/supplierEnquiry/activity/freight';
import { actions as rfcActions } from 'ducks/supplierEnquiry/activity/rfc';
import { selectors as staffSelectors } from 'ducks/staff';
import { selectors as deliveryDetailsSelectors, actions as deliveryDetailsActions } from 'ducks/supplierEnquiry/deliveryDetails';
import { actions as supplierBalanceActions } from 'ducks/supplierEnquiry/financial/balances';
import { actions as supplierPurchaseOrdersActions, selectors as supplierPurchaseOrderSelectors } from 'ducks/supplierEnquiry/purchasing/purchaseOrders';
import { actions as financialSettingsActions } from 'ducks/supplierEnquiry/settings/financial';
import { actions as generalSettingsActions } from 'ducks/supplierEnquiry/settings/general';
import { actions as attachmentsImagesActions } from 'ducks/supplierEnquiry/attachments/images';

import SupplierEnquiry from './SupplierEnquiry';

const FORM_NAME = 'SupplierEnquiry';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedAddress: addressSelectors.selected(state),
  selectedAddressSchema: addressSelectors.selectedSchema(state),
  contacts: supplierSelectors.contacts(state),
  selectedContact: contactSelectors.selected(state),
  selectedContactSchema: contactSelectors.selectedSchema(state),
  selectedSupplier: supplierSelectors.selected(state),
  supplierDetails: supplierSelectors.supplierDetails(state),
  supplierDetails_isLoading: supplierSelectors.isLoading(state),
  selectedSupplierSchema: supplierSelectors.selectedSchema(state),
  selectedDiary: diarySelectors.selected(state),
  selectedForm: formSelectors.selected(state),
  selectedMarketing: marketingSelectors.selected(state),
  selectedMarketingSchema: marketingSelectors.selectedSchema(state),
  selectedProduct: supplierProductSelectors.selected(state),
  selectedProductSchema: supplierProductSelectors.selectedSchema(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedTransaction: transactionSelectors.selected(state),
  selectedSiteCode: deliveryDetailsSelectors.selected(state),
  selectedSiteCodeSchema: deliveryDetailsSelectors.selectedSchema(state),
  selectedShipments: shipmentSelectors.selected(state),

  selectedBackordersFilters: backordersSelectors.selectedFilters(state),
  selectedQuotesFilters: quotesSelectors.selectedFilters(state),
  selectedSalesOrdersFilters: salesOrdersSelectors.selectedFilters(state),
  selectedTransactionsFilters: transactionSelectors.selectedFilters(state),
  selectedPurchaseOrderFilters: supplierPurchaseOrderSelectors.selectedFilters(state),

  transactionDetails: transactionSelectors.details(state),
  transactionSummary: transactionSelectors.summary(state),
  loadingTransactionSummary: transactionSelectors.loadingSummary(state),
  selectedShipmentssFilters: shipmentSelectors.selectedFilters(state),

  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),

  balanceDetails: balancesSelectors.balanceDetails(state),
  pricingSummary: supplierPricingSelectors.summary(state),
  loadingPricingSummary: supplierPricingSelectors.loadingSupplierPricingSummary(state),
  dirty: isDirty(state.ui.selectedTab === 'Notepad' ? 'SupplierNotes' : FORM_NAME)(state),
  formValues: getFormValues(state.ui.selectedTab === 'Notepad' ? 'SupplierNotes' : FORM_NAME)(state),
  staff: staffSelectors.staff(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleSupplierLookUp: uiActions.toggleSupplierLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    fetchBalanceDetails: balancesActions.fetch,
    getContactDetails: supplierActions.getContactDetails,
    search: supplierActions.search,
    searchById: supplierActions.searchById,
    getSupplierDetails: supplierActions.getSupplierDetails,
    getPrimaryContacts: supplierActions.getPrimaryContacts,
    fetchProductDetail: supplierProductActions.fetchDetail,
    fetchTransactions: transactionActions.search,
    fetchTransactionDetails: transactionActions.fetchTransactionDetails,
    fetchTransactionSummary: transactionActions.fetchTransactionSummary,
    fetchShipmentss: shipmentActions.search,
    fetchQuotes: quotesActions.fetch,
    getAttachmentsImages: attachmentsImagesActions.fetch,
    fetchDeliveryDetails: deliveryDetailsActions.searchDeliveryDetail,
    searchDeliveryDetailById: deliveryDetailsActions.searchDeliveryDetailById,
    setQuotesEntityView: quotesActions.setEntityView,
    setInvoicesEntityView: invoiceActions.setEntityView,
    setGRNEntityView: grnActions.setEntityView,
    setFreightEntityView: freightActions.setEntityView,
    setMovementsEntityVew: movementsActions.setEntityView,
    setRFCEntityView: rfcActions.setEntityView,
    setSalesOrdersEntityView: salesOrdersActions.setEntityView,
    setBackordersEntityView: backordersActions.setEntityView,
    setTransactionsEntityView: transactionActions.setEntityView,
    setShipmentssEntityView: shipmentActions.setEntityView,
    setPurchaseOrdersEntityView: supplierPurchaseOrdersActions.setEntityView,

    getRFCSummary: rfcActions.getRFCSummary,

    fetchSalesOrders: salesOrdersActions.fetch,
    fetchBackorders: backordersActions.fetch,
    getDetailsTransactions: detailTransactionActions.search,
    getSupplierPricingDetails: supplierPricingActions.fetchSupplierPricingDetails,
    getSupplierPricingSummary: supplierPricingActions.fetchSupplierPricingSummary,

    getSupplierBalanceDetails: supplierBalanceActions.getBalancesDetails,
    getSupplierPurchaseOrdersSummary: supplierPurchaseOrdersActions.getPurchaseOrdersSummary,
    getSupplierFinancialSettings: financialSettingsActions.getSupplierFinancialSettings,
    getSupplierGeneralSettings: generalSettingsActions.getSupplierGeneralSettings,
    changeSelectedSupplier: supplierActions.setSelected,
    closeMenu: uiActions.closeMainMenu
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierEnquiry);
