import { combineReducers } from 'redux';

import invoices, { IInvoicesState } from './invoices';
import movements, { IMovementsState } from './movements';
import grn, { IGRNState } from './grns';
import freight, { IFreightState } from './freight';
import rfc, { IRFCState } from './rfc';

export interface ISupplierActivityState {
  invoices: IInvoicesState;
  movements: IMovementsState;
  grn: IGRNState;
  freight: IFreightState;
  rfc: IRFCState;
}

const customerEnquiryReducer = combineReducers<ISupplierActivityState>({
  invoices,
  grn,
  movements,
  freight,
  rfc
});

export default customerEnquiryReducer;
