import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/index';
import RFCSearchLookUpDrawer from 'components/common/SearchLookUpDrawer/Containers/RFC';
import SummaryPanel from './SummaryPanel';
import { shallowCompare } from 'utils/utils';
import { withRouter } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import styles from './RFCEnquiry.scss';
import options from './OptionItems.json';
import { OPERATIONS } from 'utils/operations';
import { Route } from 'react-router';
import RFCDetails from './RFCDetails/index';
import Notepad from './NotePad/index';
import Diary from './Diary/index';
import RFCLines from './RFCLines/index';
import { IRFCEnquiryProps } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  salesInvoiceEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class RFCEnquiryView extends React.Component<IRFCEnquiryProps> {

  handleModuleChange = (selectedTab: any) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'rfc-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'rfc-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const RFCNumber = params.get('RFCNumber');
    const DiaryId = params.get('DiaryId');

    return ({
      RFCDetails: { pathname: `/${context}/details`, search: `?RFCNumber=${RFCNumber}`, component: RFCDetails },
      Notepad: { pathname: `/${context}/notepad`, search: `?RFCNumber=${RFCNumber}`, component: Notepad },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?RFCNumber=${RFCNumber}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?RFCNumber=${RFCNumber}&DiaryId=${DiaryId}`, component: Diary },
      Comments: { pathname: `/${context}/comments`, search: `?RFCNumber=${RFCNumber}`, component: RFCDetails },
      Summary: { pathname: `/${context}/summary`, search: `?RFCNumber=${RFCNumber}`, component: RFCDetails },
      RFCLines: { pathname: `/${context}/rfc-lines`, search: `?RFCNumber=${RFCNumber}`, component: RFCLines },
      null: { pathname: `/${context}/`, search: null, component: RFCDetails, },
    });
  }

  handleInvoiceChange = () => {
    const { selectedTab } = this.props;
    this.fetchChildRecords(selectedTab);
    this.getRFCSummary();
  }

  fetchChildRecords = (selectedTab: any) => {
    switch (selectedTab) {
      case 'RFCDetails':
        this.getRFCDetails();
        break;
      case 'Comments':
        this.getRFCComment();
        break;
      case 'Summary':
        this.getRFCDetailsSummary();
        break;

      default:
    }
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(OPERATIONS.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<IRFCEnquiryProps>): any {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: Readonly<IRFCEnquiryProps>): void {
    const { operationMode, selectedTab, selectedRFC, rfcSummary } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('rfc-enquiry', selectedTab);
    }
    if (selectedRFC && !rfcSummary) {
      this.getRFCSummary();
    }
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'RFCDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('RFCDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getRFCDetails = () => {
    if (this.props.selectedRFC) {
      const { RFCNumber } = this.props.selectedRFC;
      this.props.getRFCDetails({ RFCNumber });
      this.props.getRFCSummary({ RFCNumber });
    }
  }

  getRFCSummary = () => {
    if (this.props.selectedRFC) {
      const { RFCNumber } = this.props.selectedRFC;
      this.props.getRFCSummary({ RFCNumber });
    }
  }

  getRFCComment = () => {
    if (this.props.selectedRFC) {
      const { RFCNumber } = this.props.selectedRFC;
      this.props.getRFCComment({ RFCNumber });
    }
  }

  getRFCDetailsSummary = () => {
    if (this.props.selectedRFC) {
      const { RFCNumber } = this.props.selectedRFC;
      this.props.getRFCDetailsSummary({ RFCNumber });
    }
  }

  getValuesSchema = () => {
    const { selectedTab, rfcDetails } = this.props;
    switch (selectedTab) {
      case 'RFCDetails':
        return rfcDetails && rfcDetails.schema;
      default:
        return null;
    }
  }

  getOptionMenuItems = () => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
      default: return options.RFCDetails;
    }
  }
  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const schema = this.getValuesSchema();
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <RFCSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.handleInvoiceChange
            ]}
          />
          <div
            style={inlineStyles.salesInvoiceEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Return for Credit Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} valuesSchema={schema} />;
                }}
              />
            ))}

          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuItems()}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'rfc-enquiry'} />
      </div>
    );
  }
}

export default withRouter(RFCEnquiryView);
