import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  deleteKey, IExtendedState, IDataAction, SuccessAction
} from './utils';

export interface ICustomerData {
  selected: number;
  list: any[];
  schemas: any;
  contacts: any[];
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
}

export interface ICustomerState extends IExtendedState<ICustomerData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  subOp_getContacts_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
  create_loading?: boolean;
  update_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (customerId) => ({ customerId }),
    reset: () => null,
    asyncs: {
      search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
      searchById: (id: number) => id,
      fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      update: (data) => (data),
      create: (data) => (data),
      remove: (customerId) => customerId,
      subOp_getContacts: ({ CustomerId }) => CustomerId,
    }
  },
  'customer');

const NOT_SELECTED = -1;
const initialState = asyncInitialState<ICustomerData>({
  selected: NOT_SELECTED,
  list: [],
  schemas: [],
  contacts: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: '',
});

export default (state: ICustomerState = initialState, action: IDataAction): ICustomerState => {
  switch (action.type) {
    case types.reset:
      return initialState;
    case types.setSelected:
      const selected = action.data.customerId;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchById:
    case types.update:
    case types.create:
    case types.remove:
    case types.subOp_getContacts:
      return asyncOnRequest(state, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action);

    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const customers = successAction.payload.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = successAction.payload.records.map((combinedObject) => combinedObject.schema);

          return {
            ...data,
            list: customers,
            schemas: schemas,
            nextPage: successAction.payload.nextPage ? Number(successAction.payload.currPage) + 1 : null,
            prevPage: successAction.payload.prevPage ? Number(successAction.payload.currPage) - 1 : null,
            currPage: successAction.payload.currPage
          };
        },
        { fetch: true });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const customers = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newRemoval = result.records.length;
          const newList = data.list.concat(customers);
          const newSchemas = data.schemas.concat(schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
            nextPage: result.nextPage ? Number(result.currPage) + 1 : null,
            prevPage: result.prevPage && result.currPage > data.totalPages ? Number(result.currPage) - data.totalPages : null,
            currPage: result.currPage,
            removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
          };
        },
        { fetch: true });

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const customers = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newList = customers.slice(0, data.removeWhenPrev).concat(data.list);
          const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
            prevPage: result.prevPage ? Number(result.currPage) - 1 : null,
            nextPage: (newList.length > data.pageSize * data.totalPages) ? (Number(result.currPage) + data.totalPages) : null,
            currPage: result.currPage,
            removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
          };
        },
        { fetch: true });

    case types.saga.update.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const newCustomer = successAction.payload.inlineObject || successAction.payload;
          const newSchema = successAction.payload.schema;
          const prevCustomers = deleteKey(data.list, newCustomer.CustomerId, 'CustomerId', newCustomer);
          const prevSchemas = deleteKey(data.schemas, newSchema.CustomerId.Value, 'CustomerId', newSchema);

          return {
            ...data,
            list: prevCustomers,
            schemas: prevSchemas,
          };
        },
        { update: true });
    case types.saga.create.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const newCustomer = successAction.payload.inlineObject || successAction.payload;
          const newSchema = successAction.payload.schema;
          const prevCustomers = data.list || [];
          const prevSchemas = data.schemas || [];
          prevSchemas.push(newSchema);
          prevCustomers.push(newCustomer);

          return {
            ...data,
            list: prevCustomers,
            schemas: prevSchemas,
            selected: newCustomer.CustomerId
          };
        },
        { create: true });
    case types.saga.remove.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICustomerData, successAction: SuccessAction) => {
          const prevCustomers = deleteKey(data.list, successAction.payload, 'CustomerId');
          const prevSchemas = deleteKey(data.schemas, successAction.payload, 'CustomerId');

          return {
            ...data,
            list: prevCustomers,
            schemas: prevSchemas
          };
        },
        { delete: true });
    case types.saga.subOp_getContacts.success:
      return asyncOnSuccess(state, action, (data: ICustomerData, successAction: SuccessAction) => {
        const contacts = successAction.payload;

        return {
          ...data,
          contacts: contacts
        };
      });
    case types.saga.update.failure:
    case types.saga.create.failure:
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.remove.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
    case types.saga.subOp_getContacts.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { customer: ICustomerState }) => state.customer,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.CustomerId === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.CustomerId.Value === data.selected)) || null,
    primaryContacts: (data) => (data.contacts) !== undefined && data.contacts !== null && data.contacts.filter((contact) => contact.Primary),
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
);

const syncSelector = {
  isLoading: (state: { customer: ICustomerState }) => state.customer.search_loading || state.customer.searchById_loading,
  isLoadingCreateCustomer: (state: { customer: ICustomerState }) => state.customer.create_loading,
  isLoadingUpdateCustomer: (state: { customer: ICustomerState }) => state.customer.update_loading,
  detailLoading: (state: { customer: ICustomerState }) => state.customer.subOp_getContacts_loading,
  loadingNextPage: (state: { customer: ICustomerState }) => state.customer.fetchNextPage_loading,
  loadingPrevPage: (state: { customer: ICustomerState }) => state.customer.fetchPrevPage_loading,
  pageSize: (state: { customer: ICustomerState }) => state.customer.data.pageSize,
  totalPages: (state: { customer: ICustomerState }) => state.customer.data.totalPages,
  notifications: (state: { customer: ICustomerState }) => state.customer.notifications,
  error: (state: { customer: ICustomerState }) => state.customer.error
};

export const selectors = { ...asyncSelector, ...syncSelector };
