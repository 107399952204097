import React from 'react';
import styles from '../NewJob.styles';
import { IConfirmationPanel } from '../NewJobModal.properties';
import { withStyles } from '@material-ui/core';
import DataGrid, { Column, Selection, Summary, GroupItem, SortByGroupSummaryInfo, LoadPanel } from 'devextreme-react/data-grid';
import { NEW_JOB_DATA_GRID_HEIGHT, NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH } from '../NewJobModal.constants';

// tslint:disable: no-magic-numbers
function ConfirmationPanel(props: IConfirmationPanel): JSX.Element {
    const {
        classes,
        data,
    } = props;

    const dataGridRef = React.useRef<DataGrid>();
    function decimalHoursToHoursMinutes(num: number): string {
        const hours = `${Math.floor(num) % 24}`.length <= 1 ? `0${Math.floor(num) % 24}` : `${Math.floor(num) % 24}`;

        let min = (num % 1) * 60; //get minutes
        const sec = (min % 1) * 60; //get seconds

        min = sec > 40 ? Math.trunc(min) + 1 : Math.trunc(min); //if seconds are greator than 40, make it a minute

        const minutes = `${min}`.length <= 1 ? `0${min}` : `${min}`; //convert to string

        return `${hours.slice(0, 2)}:${minutes.slice(0, 2)}`;
    }

    return (
        <div className={classes.serviceAgreementItemContainer}>
            <div className={`${classes.fullWidth} ${classes.heading}`}>Review the summary of a job and confirm everything is correct.</div>
            <DataGrid
                style={styles.fullWidth}
                ref={dataGridRef}
                dataSource={data}
                keyExpr='Id'
                repaintChangesOnly={true}
                height={NEW_JOB_DATA_GRID_HEIGHT}
                columnResizingMode={'nextColumn'}
                allowColumnReordering={true}
                noDataText=''
                allowColumnResizing={true}
                showBorders={false}
                sorting={{
                    mode: 'none'
                }}
                hoverStateEnabled={true}>
                <Selection mode='single' />
                <Column dataField={'ServiceItemDisplay'} groupCellRender={(d) => d.value} groupIndex={0} calculateGroupValue={(d) => `${d.ServiceNoDisplay} - ${d.ServiceNoDisplayLabel}`} />
                <Column dataField='ActivityCode' minWidth={'40%'} caption='Activity' cellRender={(d) => `${d.data.ActivityCode} - ${d.data.Description}`} />
                <Column dataField='EstimatedHours' minWidth={'30%'} caption='Estimated Labour (Hours)' customizeText={(d) => decimalHoursToHoursMinutes(d.value)} />

                <Summary>
                    <GroupItem
                        column='EstimatedHours'
                        summaryType='sum'
                        showInGroupFooter={false}
                        alignByColumn={true}
                        customizeText={(d) => decimalHoursToHoursMinutes(d.value)} />
                </Summary>
                <SortByGroupSummaryInfo summaryItem='count' />
                <LoadPanel shading={false} height={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} width={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
        </div>
    );
}

export default withStyles(styles, { index: 1 })(ConfirmationPanel);
// tslint:enable: no-magic-numbers
