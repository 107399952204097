import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import ServiceItemTypeMaintenance from './ServiceItemTypeMaintenance';
import ServiceItemTypeMaintenanceProps from './ServiceItemTypeMaintenance.properties';
import { actions as formActions } from 'ducks/form';
import { selectors as uiSelectors } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';
import { actions as serviceItemTypeActions } from 'ducks/serviceItemTypeMaintenance/serviceItemType';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeSelectedForm: formActions.setSelected,
    getFormSchema: formActions.search,
    resetSearchLookupDrawer: serviceItemTypeActions.reset,
    destoryForm: () => destroy('ServiceItemTypeDetail'),
  },
  dispatch);

const mapStateToProps = (state: IApplicationState) => ({
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state)
});

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
  ServiceItemTypeMaintenanceProps>) => {
  return (<ServiceItemTypeMaintenance
    {...props} />);
});
