import * as React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import ChangePassword from './ChangePassword.form';
import { IChangePasswordFormProps } from './ChangePassword.properties';

const ChangePasswordForm  =  (props: IChangePasswordFormProps) => {

  const setPassRef = React.useRef<HTMLInputElement>();
  const setNewPassRef = React.useRef<HTMLInputElement>();
  const setConfirmPassRef = React.useRef<HTMLInputElement>();

  const handleInputChange = (event) => {
    props.changeFieldValue(event.target.name, event.target.value);
  };

  const handleFocus = (ref: React.MutableRefObject<HTMLInputElement>) => {
    setTimeout(
      () => {ref?.current?.focus(); },
      0
    );
  };

  const handleChangeSubmit = () => {
    const { newPassword, confirmPassword, staffPassword } = props.formValues;
    let invalid = false;
    // First we will check both the fields and make them focused
    if ((!confirmPassword || confirmPassword !== newPassword)) {
      handleFocus(setConfirmPassRef);
      invalid = true;
    }
    if (!newPassword) {
      handleFocus(setNewPassRef);
      invalid = true;
    }
    if (!staffPassword) {
      handleFocus(setPassRef);
      invalid = true;
    }

    if (!invalid) callOnSubmit();
  };

  const callOnSubmit = () => {
    if (props.onSubmit) {
      const { newPassword, confirmPassword, staffPassword } = props.formValues;
      props.onSubmit({
        confirmPassword,
        newPassword,
        staffPassword,
      });
      if (props.closeForm) {
        props.closeForm();
      }
    }
  };

  return (
    <FormViewModal
      open={props.open}
      loading={false}
      title='Change Password'
      formProps={{
        formSchema: ChangePassword,
        initialValues: {},
        style: {  minWidth: '282px', height: '256px' },
        fieldExtensions: {
          staffPassword: {
            fieldRef: (Ref) => setPassRef.current = Ref,
            onChange: handleInputChange
          },
          newPassword: {
            fieldRef: (Ref) => setNewPassRef.current = Ref,
            onChange: handleInputChange
          },
          confirmPassword: {
            fieldRef: (Ref) => setConfirmPassRef.current = Ref,
            onChange: handleInputChange
          },
        }
    }}

      actions={[
          {
              title: 'Change',
              listener: handleChangeSubmit
          },
          {
              title: 'Cancel',
              isDefault: true,
              listener: () => {
                props.closeForm();
              },
          },
      ]}
      dialogActionsButtons={true}
      dialogActionsShadow={false}
    />
  );
};

export default ChangePasswordForm;
