import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as transactionSelectors } from 'ducks/customerEnquiry/transactions';
import { selectors as productDetailsSelectors } from 'ducks/inventoryEnquiry/productDetails';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  selectedTransaction: transactionSelectors.selected(state),
  transactionDetails: transactionSelectors.transactionDetails(state),
  selectedTransactionLoading: transactionSelectors.isLoading(state),
  productDetailsSummary: productDetailsSelectors.productDetailsSummary(state),
  selectedProduct: productDetailsSelectors.productDetails(state),
  selectedProductLoading: productDetailsSelectors.isLoading(state),
});

export default connect(mapStateToProps, null)(SummaryPanel);
