import { fetchPost, objectify, fetchGet } from '../utils'

export const search = (query) => {
  const { SearchText, CustomerId, Sort } = query
  return fetchPost(`/CustomerEnquiry/Customer/${CustomerId}/SearchMarketingAttributes`, { SearchText, Sort }, null, 'Inline')
    .then((something) => (something && something.CustomerMarketingAttributes && something.CustomerMarketingAttributes.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val }))) || [])
}

export const searchById = ({ CustomerId, AttributeSet, AttributeCode }) => {
  return fetchGet(`/CustomerEnquiry/Customer/${CustomerId}/MarketingAttribute/${AttributeSet}/${AttributeCode}`, null, 'Inline')
    .then((result) => (result && result.CustomerDeliveryDetails && [{
      inlineObject: objectify(result.CustomerDeliveryDetails),
      schema: result.CustomerDeliveryDetails,
    }]) || [] )
}