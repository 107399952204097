import React from 'react';
import DateTextField from '@markinson/uicomponents-v2/DateTextField';

class DateField extends React.Component {
  componentDidUpdate(prevProps) {
    const { value, operationMode, nullable } = this.props;
    if (!nullable && !value && !prevProps.value && operationMode === 'NEW' && !this.props.meta.active && !this.props.meta.visited && this.props.defaultValue !== false)
      this.props.onChange(new Date().toLocaleDateString('en-GB'))
  }
  componentDidMount() {
    const { value, nullable } = this.props;
    if (!nullable && (!value || value === '') && this.props.defaultValue !== false && this.props.onChange)
      this.props.onChange(new Date().toLocaleDateString('en-GB'))
  }

  render() {
    const { name, onBlur, ...rest } = this.props;

    return (
      <DateTextField
        onBlur={(e) => {
          if (e.target) {
            e.target.name = name;
          }
          if (onBlur) {
            onBlur(e);
          }
        }}
        {...rest}
      />
    )
  }
}

export default DateField;
