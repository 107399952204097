import { takeLatest, call, Effect, put } from 'redux-saga/effects';

import { reset } from 'redux-form';
import { actions as uiActions } from 'ducks/ui';
import { types as cashReceiptTypes, actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { actions as allocateActions } from 'ducks/cashReceipting/allocate';
import { actions as paymentActions } from 'ducks/common/paymentDetails';
import { actions as uiOperationsActions } from 'ducks/uiOperations';

import { callApi } from '../utils';
import * as api from 'api/cashReceipting/cashReceipt';
import { IDataAction } from 'ducks/utils';
import { Operation } from 'utils/operations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* processCashReceipt(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.processCashReceipt;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(cashReceiptActions.changeModalVisibility({ openConfirmReceipt: true }));
  }
  yield callApi(
    call(api.processCashReceipt, action.data),
    success,
    failure,
    { dialogActionCallEffect: processCashReceipt, postApiSuccessCallEffect }
  );
}

function* getCashReceipt(): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.getCashReceipt;

  yield callApi(
    call(api.getCashReceipt),
    success,
    failure
  );
}

function* updateCashReceipt(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.updateCashReceipt;

  yield callApi(
    call(api.updateCashReceipt, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateCashReceipt }
  );
}

function* deleteCashReceipt(): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.deleteCashReceipt;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(cashReceiptActions.toggleCashReceiptingLookupPanel(true));
    yield put(uiOperationsActions.changeOperationMode(Operation.BROWSE));
  }

  yield callApi(
    call(api.deleteCashReceipt),
    success,
    failure,
    { dialogActionCallEffect: deleteCashReceipt, postApiSuccessCallEffect }
  );
}

function* applyCashReceiptContext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.applyCashReceiptContext;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(cashReceiptActions.toggleCashReceiptingLookupPanel(false));
    yield put(uiOperationsActions.changeOperationMode(Operation.EDIT));
  }

  yield callApi(
    call(api.applyCashReceiptContext, action.data),
    success,
    failure,
    { dialogActionCallEffect: applyCashReceiptContext, postApiSuccessCallEffect }
  );
}

function* fetchCashReceiptContext(): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.fetchCashReceiptContext;

  yield callApi(
    call(api.fetchCashReceiptContext),
    success,
    failure,
  );
}

function* updateCashReceiptContext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.updateCashReceiptContext;

  yield callApi(
    call(api.updateCashReceiptContext, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateCashReceiptContext }
  );
}

function* cashReceiptProcessComplete(): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.cashReceiptProcessComplete;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(reset('CashReceipt'));
    yield put(uiActions.changeSelectedTab('CashReceipt'));
    yield put(uiOperationsActions.changeOperationMode(Operation.BROWSE));
    showSnackBar({ variant: 'success', message: 'Payment process completed.' });
    yield call(getCashReceipt);
    yield call(fetchCashReceiptContext);
    yield put(cashReceiptActions.toggleCashReceiptingLookupPanel(true));
  }

  yield callApi(
    call(api.cashReceiptProcessComplete),
    success,
    failure,
    { postApiSuccessCallEffect, dialogActionCallEffect: cashReceiptProcessComplete }
  );
}

function* confirmCashReceiptProcess(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = cashReceiptActions.saga.confirmCashReceiptProcess;
  const { success: allocateSuccess } = allocateActions.saga.confirmCashReceiptProcess;
  const { success: paymentSuccess } = paymentActions.saga.confirmCashReceiptProcess;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    yield put(allocateSuccess(response));
    yield put(paymentSuccess(response));
    yield put(cashReceiptActions.changeModalVisibility({ openConfirmReceipt: false }));
    if (response.PaymentRequired) {
      yield put(uiActions.changeSelectedTab('PaymentDetails'));
    }
  }

  yield callApi(
    call(api.confirmCashReceiptProcess, action.data),
    success,
    failure,
    { dialogActionCallEffect: confirmCashReceiptProcess, postApiSuccessCallEffect }
  );
}

export default function* rootSupplierInvoiceSaga(): IterableIterator<Effect> {
  yield takeLatest(cashReceiptTypes.getCashReceipt, getCashReceipt);
  yield takeLatest(cashReceiptTypes.updateCashReceipt, updateCashReceipt);
  yield takeLatest(cashReceiptTypes.deleteCashReceipt, deleteCashReceipt);
  yield takeLatest(cashReceiptTypes.applyCashReceiptContext, applyCashReceiptContext);
  yield takeLatest(cashReceiptTypes.fetchCashReceiptContext, fetchCashReceiptContext);
  yield takeLatest(cashReceiptTypes.updateCashReceiptContext, updateCashReceiptContext);
  yield takeLatest(cashReceiptTypes.processCashReceipt, processCashReceipt);
  yield takeLatest(cashReceiptTypes.confirmCashReceiptProcess, confirmCashReceiptProcess);
  yield takeLatest(cashReceiptTypes.cashReceiptProcessComplete, cashReceiptProcessComplete);
}
