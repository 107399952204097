import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';
import { pathOr } from 'utils/utils';

export interface IPickSlipDetailsData {
  slipDetails: any;
  slipDetailsSummary: any;
  lostSaleForm: any;
  lostSaleModalOpen: boolean;
}

export interface ISlipDetailsState extends IExtendedState<IPickSlipDetailsData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (query) => (query),
      save: (data) => (data),
      update: (data) => (data),
      remove: (data) => (data),
      finalize: (data) => (data),
      cancelPickSlipDetails: (data) => (data),
      recordLostSale: (query) => (query),
      fetchRecordLostSale: (query) => (query)
    },
    openLostSaleModal: (data) => data
  },
  'pickSlipDetails');

const initialState = asyncInitialState<IPickSlipDetailsData>({
  slipDetails: {},
  slipDetailsSummary: {},
  lostSaleForm: {},
  lostSaleModalOpen: false
});

export default (state: ISlipDetailsState = initialState, action: DataAction): ISlipDetailsState => {
  switch (action.type) {
    case types.fetch:
    case types.save:
    case types.update:
    case types.remove:
    case types.cancelPickSlipDetails:
    case types.recordLostSale:
    case types.fetchRecordLostSale:
    case types.finalize:
      return asyncOnRequest(state, action);

    case types.openLostSaleModal:
      return {
        ...state,
        data: {
          ...state.data,
          lostSaleModalOpen: action.data
        }
      };

    case types.saga.fetch.success:
    case types.saga.save.success:
    case types.saga.update.success:
    case types.saga.remove.success:
      case types.saga.finalize.success:
    case types.saga.cancelPickSlipDetails.success:
      return asyncOnSuccess(state, action, (data: IPickSlipDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          slipDetails: successAction.payload
        };
      });
    case types.saga.recordLostSale.success:
    case types.saga.fetchRecordLostSale.success:
      return asyncOnSuccess(state, action, (data: IPickSlipDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          lostSaleForm: successAction.payload
        };
      });

    case types.saga.fetch.failure:
    case types.saga.save.failure:
    case types.saga.update.failure:
    case types.saga.remove.failure:
    case types.saga.finalize.failure:
    case types.saga.cancelPickSlipDetails.failure:
    case types.saga.recordLostSale.failure:
    case types.saga.fetchRecordLostSale.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { pickSlips: { slipDetails: ISlipDetailsState } }) => state.pickSlips.slipDetails,
    {
      slipDetails: (data: IPickSlipDetailsData) => data.slipDetails,
      lostSale: (data: IPickSlipDetailsData) => data.lostSaleForm,
      entity: (data: IPickSlipDetailsData) => pathOr('', ['slipDetails', 'inlineObject', 'SalesEntity'], data),
    }),
  isLoading: (state: { pickSlips: { slipDetails: ISlipDetailsState } }) => state.pickSlips.slipDetails.loading,
  lostSaleModalOpen: (state: { pickSlips: { slipDetails: ISlipDetailsState } }) => state.pickSlips.slipDetails.data.lostSaleModalOpen,
  ValidationForms: (state: { pickSlips: { slipDetails: ISlipDetailsState } }) => pathOr([], ['pickSlips', 'slipDetails', 'data', 'slipDetails', 'Forms'], state),
};
