import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import ChargesSummaryTableSchema from './ChargesDialog.summaryTable.schema';
import IChargesDialogProperties from './ChargesDialog.properties';
import SummaryTable from 'components/common/SummaryTable';
import ChargesForm from './ChargesDialog.form';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';
import { useCalculateCharges, useUpdateCharges } from 'api/pickSlips/slipLinesUpdated';
import { ChargesDialogCalculationFields, ChargesDialogNonCalculationFields } from '../PickSlips.constants';
import { IChargeLineDetailFacade, IChargeLineSummaryFacade } from 'api/swaggerTypes';
import {  IObjectified } from 'api/utils';
import { formValueChangedByUser, isNull } from 'utils/utils';

const initialValues = {
        Comment: '',
        Price: 0,
        Cost: 0,
        DefaultDiscount: 0,
        SuppliedQuantity: 0,
        Description: '',
    };
const initialValuesSchema = {
    inlineObject: {},
    schema: {
        Comment: {ReadOnly: true},
        Price: {ReadOnly: true},
        Cost: {ReadOnly: true},
        DefaultDiscount: {ReadOnly: true},
        SuppliedQuantity: {ReadOnly: true},
        Description: {ReadOnly: true},
        TaxIncluded: {Value : false, ReadOnly: true},
    }
    };

const ChargesDialog = (props: IChargesDialogProperties) => {
    const { open, despatchId, formValues, resetForm, changeFieldValue, onOk, onCancel } = props;
    const calculateChargesMutation = useCalculateCharges();
    const updateChargesMutation = useUpdateCharges();
    const [summaryData, setSummaryData] = React.useState<IChargeLineSummaryFacade>();
    const [chargeLineDetails, setChargeLineDetails] = React.useState<IObjectified<IChargeLineDetailFacade>>(initialValuesSchema);

    const productFieldRef = React.useRef<IBasicLookupFieldHandle>();
    const descriptionRef = React.useRef<HTMLInputElement>();

    React.useEffect(
    () => {
        if (open) {
            setTimeout(
                () => {productFieldRef.current.focus(); },
                0
            );
        }
    },
    [open]
    );

    const hasValueUpdate = (fieldName: string, value?: any) => {
        if (!isNull(chargeLineDetails.inlineObject)) {
            const newValue = (fieldName === 'Discount' && chargeLineDetails.schema.DefaultDiscount.Type === 'Percentage') ? `${value} %` : value;

            return formValueChangedByUser(fieldName, newValue, chargeLineDetails);
        }

        return true;
    };

    const calculateCharges = React.useCallback(
        async (fieldName, value) => {
            if (hasValueUpdate(fieldName, value)) {

                const ChargesFormValues = (fieldName !== 'Discount') ? {
                    DespatchId: despatchId,
                    ...formValues,
                    Discount: formValues.DefaultDiscount,
                    [fieldName]: value,
                } : {
                    DespatchId: despatchId,
                    ...formValues,
                    [fieldName]: value,
                };
                const response = await calculateChargesMutation.mutateAsync({
                    ...ChargesFormValues,
                    TaxIncluded: chargeLineDetails.schema.TaxIncluded.Value,

                    urlQueryParams: {
                        ChangedField: fieldName,
                    }
                });

                if (response?.Status === true && response?.ChargeLineDetail && response?.ChargeLineSummary) {

                    if (formValues) {
                        Object.keys(formValues).forEach((key) => {
                        changeFieldValue(key, response?.ChargeLineDetail.inlineObject[key]);
                        });
                    }
                    setSummaryData(response?.ChargeLineSummary.inlineObject);
                    setChargeLineDetails(response.ChargeLineDetail);
                }
        }
        },
        [formValues]
    );

    const onLookupSelectedItemChange = React.useCallback(
        (name, item) => {
            calculateCharges(name, item).then(() => {
                if (descriptionRef?.current) {
                    descriptionRef?.current?.focus();
                }
            });

        },
        [formValues, descriptionRef.current]
        );

    const formViewOnBlur = React.useCallback(
        async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
            const fieldName = e?.target?.name;
            const fieldValue = e?.target?.value;
            if (ChargesDialogNonCalculationFields.includes(fieldName)) {
                return;
            }

            if (fieldName) {
                calculateCharges(ChargesDialogCalculationFields[fieldName] ?? fieldName, fieldValue);
            }
        },
        [calculateCharges]
    );

    const resetCharges = () => {
        resetForm();
        setSummaryData(undefined);
        setChargeLineDetails(initialValuesSchema);
    };

    return (
        <div>
            <FormViewModal
                open={open}
                loading={false}
                title='Charge Details'
                formProps={{
                    formSchema: ChargesForm,
                    initialValues: initialValues,
                    onBlur: formViewOnBlur,
                    valuesSchema: chargeLineDetails.schema,
                    style: { height: '574px' },
                    fieldExtensions: {
                        ProductId: {
                            fieldRef: productFieldRef,
                            onSelectedItemChange: (item, _searchText) => onLookupSelectedItemChange('ProductId', item?.Code)
                        },
                        Description: {
                            fieldRef: (Ref) => descriptionRef.current = Ref
                        },
                    },
                    summaryTableRenderer: () =>
                    (<SummaryTable
                        chargesSummary={summaryData}
                        loadingChargesSummary={false}
                        data={ChargesSummaryTableSchema}
                    />),
                }}
                actions={[
                    {
                        title: 'ok',
                        disabled: !summaryData,
                        listener: async () => {
                            const response = await updateChargesMutation.mutateAsync({
                                ...formValues,
                                Discount: formValues.DefaultDiscount,
                                DespatchId: despatchId,
                            });
                            if (response.Status) {
                                resetCharges();
                                onOk();
                            }
                        },
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            resetCharges();
                            onCancel(false);
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </div>
    );
};

export default ChargesDialog;
