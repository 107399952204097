import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import BillToIdActionList from 'utils/data/db/actions/BillToIdActionList';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps } from '../interfaces';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  textRightAlign: {
    textAlign: 'right'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
});
const pSize = CircularProgressSize;
const paperElevation = PaperElevation;

interface ISummaryPanelState {
  open: boolean;
}
class SummaryPanel extends React.PureComponent<ISummaryPanelProps, ISummaryPanelState> {

  constructor(props: ISummaryPanelProps) {
    super(props);
    const state: ISummaryPanelState = {
      open: false
    };
    this.state = state;
  }

  appendParam = (actionList: any, CashReceiptSummary: any) => {
    if (CashReceiptSummary && CashReceiptSummary.CustomerId) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: CashReceiptSummary.CustomerId };

        return item;
      });
    }

    return actionList;
  }

  render(): React.ReactNode {
    const { classes, CashReceiptingSummary: summary = {}, isLoading } = this.props;
    const actionList = BillToIdActionList;
    const saleOrderDetails = {
      'CUSTOMER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{summary && summary.CustomerIdDisplay}</span>
          <br />
          <span>{summary && summary.CustomerIdLabel}</span>
        </div>  <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParam(actionList, summary)}
              disabled={!(summary && summary.CustomerId)}
            />
          }
        </div></div>,
      'ACCOUNTING METHOD': <div >
        <span >{summary && summary.AccountingMethodLabel}</span>
      </div>,
      'ENTITY': <div>
        <span >{summary && summary.SalesEntity}</span>
        <br />
        <span>{summary && summary.SalesEntityDescription}</span>
      </div >,
      'PERIOD': <div>
        <div style={inlineStyles.splitCellContent} >
          <span >{summary && summary.Period}</span>
          <br />
          <span >{summary && summary.WarehouseDescription}</span>
        </div>
      </div>,
      'DATE': <div>
        <span >{summary && summary.Date}</span>
      </div>,
      'STATUS': <div>
        <span >{summary && summary.CreditStatus}</span>
      </div>
    };

    const totals = {
      'BALANCE': summary && summary.BalanceDisplay,
      'ALLOCATED': summary && summary.AllocatedDisplay,
      'UNALLOCATED': summary && summary.UnallocatedDisplay,
      'TOTAL RECEIPT': summary && summary.TotalReceiptDisplay,
    };

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>P.O.S. Details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(saleOrderDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {saleOrderDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanelLastChild} square={true}>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                < Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(totals).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={`${classes.dataCell} ${classes.textRightAlign}`}>
                            {totals[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div >
    );
  }
}

export default withStyles(inlineStyles, {index: 1})(SummaryPanel);
