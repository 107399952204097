import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as uiSelectors } from '../../../ducks/ui';
import { Selectors, Actions } from '../DataGridDevEx/bindings';

import DataGridDevEx from './DataGrid';
import { IDataGridProps } from './DataGrid.properties';
import { IApplicationState } from '../../../ducks/reducers';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as configSelectors } from 'ducks/appConfig';

const mapStateToProps = (state: IApplicationState, props: Readonly<IDataGridProps>) => (
  {
    isV2: configSelectors.isV2(state),
    isV3: configSelectors.isV3(state),
    selectedTab: uiSelectors.selectedTab(state),
    ...(Selectors)(props.module, state)
  });

const mapDispatchToProps = (dispatch: Dispatch, props: Readonly<IDataGridProps>) => bindActionCreators(
  {
    changeOperationMode: operationActions.changeOperationMode,
    ...Actions(props.module)
  },
  dispatch);

//connect<MapStateToProps, MapDispatchToProps,OwnProps>
export default connect<{ [x: string]: any }, { [x: string]: any }, { [x: string]: any }>(mapStateToProps, mapDispatchToProps)(DataGridDevEx);
