import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: '98%',
        height: '60%',
        padding: 0,
        flexGrow: 1,
        boxSizing: 'border-box',
        margin: '15px'
    },
    checklistQuestionDataGrid: {
        height: '100%'
    },
    moveIcon: {
        cursor: 'pointer'
    }
});
