import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify, swapObjectValues } from '../utils'
import moment from 'moment';
export const fetch = (query) => {
  const { CustomerId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return fetchPost(
    `/CustomerEnquiry/Customer/${CustomerId}/SalesOrders`,
    filters && { ...filters, FromDate: From, ToDate: To, },
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    'Inline'
  )
    .then((result) => {
      if (result)
        if (result.SalesOrders)
          return {
            records: result.SalesOrders.map(item => objectify(swapObjectValues(item), null, 'Inline')),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        else return result;
    })
}