import React from 'react';
import { connect } from 'react-redux';
import { IPickSlipsProps } from './PickSlips.properties';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import PickSlips from './PickSlips';
import PickSlipsStateProvider from './PickSlips.context';
import { actions as pickSlipActions, selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';
import { actions as operationActions } from 'ducks/uiOperations';

const params = new URLSearchParams(location.search);

const mapStateToProps = (state) => ({
    isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
    selectedPickSlip: pickSlipSelectors.selected(state),
    isSupplierLookUpOpen: uiSelectors.isSupplierLookUpOpen(state),
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    pickSlipDocumentOutputOptions: documentOutputSelectors.options('SO', 'PickSlipPrintData')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeValidationDialog: uiActions.changeValidationDialog,
        toggleMenuOption: uiActions.toggleMenuOption,
        changeOperationMode: operationActions.changeOperationMode,
        setPickSlipDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'PickSlipPrintData', options),
        resetSearchLookupDrawer: pickSlipActions.reset
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
    IPickSlipsProps>) => {
    return (
        <PickSlipsStateProvider>
            <PickSlips {...props} />
        </PickSlipsStateProvider>
    );
});
