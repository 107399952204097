import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors, isDirty, reset, change, touch } from 'redux-form';
import ServiceAgreementDetailForm from './ServiceAgreementDetail';
import { IApplicationState } from 'ducks/reducers';
import IServiceAgreementDetailProperties, { IActionBarHandlers } from './ServiceAgreementDetail.properties';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as serviceAgreementActions } from 'ducks/serviceAgreementMaintenance/serviceAgreement';
import * as serviceAgreementApi from 'api/serviceAgreementMaintenance/serviceAgreement';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';

const FORM_NAME = 'ServiceAgreementDetail';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  operationMode: operationSelectors.operationMode(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
    performSearch: serviceAgreementActions.search,
    changeValidationDialog: uiActions.changeValidationDialog,
    changeOperationMode: operationActions.changeOperationMode,
    touchFormFields: (...args: any) => touch(FORM_NAME, ...args),
  },
  dispatch);

type IServiceAgreementDetail = Readonly<Omit<
  IServiceAgreementDetailProperties,
  'createServiceAgreement' | 'deleteServiceAgreement' | 'updateServiceAgreement' | 'fetchScheduleDetail' |
  'createSchedule' | 'updateSchedule' | 'deleteSchedule' | 'touchFormFields'>>;

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: IServiceAgreementDetail) => {
  return (<ServiceAgreementDetailForm
    createServiceAgreement={serviceAgreementApi.createServiceAgreement}
    deleteServiceAgreement={serviceAgreementApi.deleteServiceAgreement}
    updateServiceAgreement={serviceAgreementApi.updatedServiceAgreement}
    fetchScheduleDetail={serviceAgreementApi.fetchServiceAgreementSchedule}
    createSchedule={serviceAgreementApi.createServiceAgreementSchedule}
    updateSchedule={serviceAgreementApi.updatedServiceAgreementSchedule}
    deleteSchedule={serviceAgreementApi.deleteServiceAgreementSchedule}
    {...props} />);
});

export default React.forwardRef(
  (props: Omit<IServiceAgreementDetail, 'operationMode' | 'changeOperationMode'>, ref: React.Ref<IActionBarHandlers>) => {

    return <ConnectedComponent {...props} forwardedRef={ref} />;
  });
