import * as moment from 'moment';
//import { objectify } from '../utils';
import { BaseApi } from '../baseApi';

export interface IGetPriceCalculationFactorsRequest {
  ProductId: number;
  CustomerId: number;
  SaleQuantity: number;
  SalesEntity: string;
  Warehouse: string;
  CustomerPriceCode: string;
  PriceCategory: string;
  EffectiveDate: string;
}

class PriceCheckCalculationFactorsApi extends BaseApi {
  constructor() {
    super();
  }

  async search(query: IGetPriceCalculationFactorsRequest): Promise<any> {
    const request  = {
      ...query,
      CustomerId: query && query.CustomerId ? query.CustomerId : 0,
      EffectiveDate: query && query.EffectiveDate && moment(query.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    };

    return this
      .post<IGetPriceCalculationFactorsRequest, any>('PriceCheck/GetPriceCalculationFactors?responseMetadataFormat=Inline', request)
      .then((result) => result);
  }
}

const priceCheckCalculationFactorsApi = new PriceCheckCalculationFactorsApi();

export async function search(query: IGetPriceCalculationFactorsRequest): Promise<any> {
  return priceCheckCalculationFactorsApi.search(query);
}
