import React from 'react';
import { BreadcrumbBar } from '@markinson/uicomponents-v2';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import styles from './RunDynamicExtract.styles';
import { IExtractMaintenanceProps } from './RunDynamicExtract.properties';
import modules from './RunDynamicExtract.modules';
import options from './RunDynamicExtract.options';
import OptionsMenu from 'components/common/OptionsMenu';
import DynamicExtractModal from './DynamicExtractModal';

const RunDynamicExtract = (props: IExtractMaintenanceProps) => {
    const { classes, history, isMenuOptionOpen, toggleMenuOption } = props;
    const handleToggleMenuOptionOpen = React.useCallback(
        () => {
            toggleMenuOption(!isMenuOptionOpen);
        },
        [isMenuOptionOpen, toggleMenuOption]
    );

    return (
        <div className={classes.mainDiv}>
            <div className={classes.innerContainer}>
                <BreadcrumbBar
                    mainModule={'Run Dynamic Extract'}
                    activeModuleId={''}
                    moduleTree={modules}
                    onBreadcrumbClick={null}
                    onMainModuleClick={null}
                    onOptionsMenuClick={handleToggleMenuOptionOpen}
                />
            </div>
            <OptionsMenu
                open={isMenuOptionOpen}
                options={options.EM}
                onOptionClick={null}
                defaultValue={''}
            />
            <DynamicExtractModal
                open={true}
                handleClose={() => history.push('/dashboard')}
            />
        </div >
    );
};

export default withRouter(withStyles(styles, { index: 1 })(RunDynamicExtract));
