import { IFormViewForm } from 'components/FormView';

const Financial: IFormViewForm = {
  id: 'PMEMovementDetails',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Movement',
            name: 'MovementNumber',
            size: 'small',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Date',
            name: 'Date',
            size: 'large',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference',
            name: 'Reference',
            size: 'large',
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'User ID',
            name: 'UserId',
            size: 'small',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Document',
            name: 'DocumentNumber',
            size: 'large',
            api: '/priceCheckToInv/lookup/action'
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Document Reference',
            name: 'DocumentReference',
            size: 'large',
            api: '/priceCheckToInv/lookup/action'
          }
        },
        {
          id: 6,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Document account',
            name: 'DocumentAccount',
            size: 'small',
            api: '/priceCheckToInv/lookup/action'
          }
        },
        {
          id: 7,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Sales Audit',
            name: 'SalesAudit',
            size: 'small',
            api: '/priceCheckToInv/lookup/action'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Comments',
            name: 'Comments',
            fullWidth: true,
            style: {
              height: '100%'
            }
          }
        }
      ]
    }
  ]
};

export default Financial;
