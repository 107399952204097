import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  mainDiv: {
    top: '0',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    height: 'calc(100% - 182px)'
  },
  paperContainer: {
    padding: '5px',
    width: 'fit-content',
  },
  collapseableBreadCrumb: {
    paddingBottom: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
  },
  grid: {
    width: 'calc(100% - 300px)',
    margin: 15,
    marginRight: 0
  },
  Icon: {
    cursor: 'pointer',
  },
  actionButtonIcon: {
    cursor: 'pointer',
    fontSize: '18px',
    margin: '0 3px',
    color: '#337ab7'
  },
  anchor: {
    display: 'inline-flex'
  },
  disabledIcon: {
    color: 'gray',
    pointerEvents: 'none'
  },
  rowsContainer: {
    height: 'calc(100% - 45px)'
  }
});
