import { takeLatest, Effect } from 'redux-saga/effects';

import { types as InvoiceDetailTypes, actions as InvoiceDetailActions } from 'ducks/supplierInvoiceEnquiry/invoiceDetails';

import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierInvoiceEnquiry/invoiceDetails';

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(InvoiceDetailTypes.fetch, connectSagaToApi(InvoiceDetailActions, 'fetch', api.fetch));
  yield takeLatest(InvoiceDetailTypes.fetchSummary, connectSagaToApi(InvoiceDetailActions, 'fetchSummary', api.fetchSummary));
  yield takeLatest(InvoiceDetailTypes.fetchComment, connectSagaToApi(InvoiceDetailActions, 'fetchComment', api.fetchComment));
}
