import { IFormViewForm } from 'components/FormView';

const CustomerDetails: IFormViewForm = {
  id: 'CustomerDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Customer',
        name: 'customer',
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Customer',
            name: 'CustomerAccountNumber',
            type: 'string',
            required: true,
            size: 'small',
            hideInV2: true,
            inputProps: {
              maxLength: 10
            }
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Customer',
            name: 'CustomerAccountNumber',
            type: 'number',
            required: false,
            size: 'small',
            hideInV3: true,
            inputProps: {
              maxLength: 6
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Abbreviation',
            required: false,
            name: 'Abbreviation',
            size: 'small'
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Name',
            required: true,
            name: 'Name',
            size: 'large',
            inputProps: {
              maxLength: 35
            }
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Owner',
            name: 'Owner',
            required: false,
            size: 'large',
            inputProps: {
              maxLength: 30
            }
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer active',
            name: 'Active',
            required: false,
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'General Contact details',
        name: 'contactDetails',
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Phone',
            name: 'PhoneMain',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            },
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Fax',
            name: 'FaxMain',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            },
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Mobile',
            name: 'PhoneMobile',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            },
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Email',
            name: 'EmailMain',
            required: false,
            size: 'large',
            action: {
              iconName: 'Mail',
            },
            inputProps: {
              maxLength: 100
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Web',
            name: 'URL',
            required: false,
            size: 'large',
            action: {
              iconName: 'Language',
            },
            inputProps: {
              maxLength: 100
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Address',
        name: 'address',
        required: true,
      },
      children: [
        {
          type: 'ADDRESS_BLOCK_EX',
          props: {
            name: 'address'
          }
        }
      ],
      position: {
        row: 1,
        col: 2
      },
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Alert / Information',
        name: 'alertInfo',
      },
      position: {
        row: 2,
        col: 2
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Message line 1',
            name: 'Message1',
            required: false,
            size: 'large',
            inputProps: {
              maxLength: 30
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Message line 2',
            name: 'Message2',
            required: false,
            size: 'large',
            inputProps: {
              maxLength: 30
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Account Information',
        name: 'accountInfo',
      },
      position: {
        row: 2,
        col: 1
      },
      children: [
        {
          type: 'DATE_FIELD',
          props: {
            label: 'Date opened',
            name: 'OpenDate',
            required: true,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            size: 'small'
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Business number',
            name: 'BusinessNumber',
            required: false,
            size: 'medium',
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Company number',
            name: 'CompanyNo',
            required: false,
            size: 'medium',
            inputProps: {
              maxLength: 20
            }
          }
        }
      ]
    }
  ]
};
export default CustomerDetails;
