import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils'

export const search = (query) => {
  const { PurchaseOrder, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchPost(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/BranchOrders`, filters,
    { BatchSize, BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '' }, 'Inline')
    .then((result) => {
      if (result) {
        if (result.BranchOrders) {
          return {
            records: result.BranchOrders.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}

export const fetchSummary = (query) => {
  const { PurchaseOrder } = query
  return fetchGet(`PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/ConsolidateTotals`)
    .then((result) => result ? objectify(result.ConsolidateTotals) : null)
}