import * as React from 'react';
import { BrowseLookUpModal, ISelectedLookupOption } from './BrowseLookUpModal';
import ExtendedTextField from '@markinson/uicomponents-v2/ExtendedTextField';
import { CustomTypesApi, ILookupRequest, ILookupResponse, ILookupOption } from 'api/customType';
import { ApiError } from 'api/baseApi';
import { isNull } from 'utils/utils';
import { ENTER_KEY } from 'utils/constants';

export interface ILookUpActionFieldProperties {
  label: string;
  placeholder?: string;
  size?: any;
  lookupName: string;
  style?: any;
  value?: (ISelectedLookupOption | string);
  searchText?: string;
  params?: any;
  fieldRef?: React.Ref<any> | React.RefObject<any>;
  current?: React.Ref<any> | React.RefObject<any>;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  active?: boolean;
  touched?: boolean;
  suppressDescription?: boolean;
  suppressErrorMessage?: boolean;
  onSelectedItemChange?(selectedItem?: ISelectedLookupOption): void;
  onSelectedValueChange?(selectedValue?: string): void;
  onError?(error: ApiError): void;
  onFocus?(event: any): boolean;
  onBlur?(event: any): boolean;
  onChange?(value: any): void;
  isLoading?(loading: boolean): void;
  onModalClose?(selectedItem?: ISelectedLookupOption): void;
  onModalVisibilityChange?(browseOpen?: boolean): void;
  hasError?(hasError: boolean): void;
}

export interface ILookUpActionFieldState {
  mode: 'item' | 'value';
  searchPending: boolean;
  searchTouched: boolean;
  loading: boolean;
  browseOpen: boolean;
  selectedValue?: string;
  selectedItem?: ISelectedLookupOption;
  searchText: string;
  getApi(value: string): Promise<ILookupOption>;
  searchApi(search: ILookupRequest): Promise<ILookupResponse>;
}

class LookUpActionField extends React.Component<ILookUpActionFieldProperties, ILookUpActionFieldState>  {

  inputField: typeof ExtendedTextField;

  constructor(props: ILookUpActionFieldProperties) {
    super(props);
    this.state = {
      mode: 'value',
      searchPending: false,
      searchTouched: false,
      loading: false,
      browseOpen: false,
      getApi: CustomTypesApi.lookupGet(this.props.lookupName, this.props.params),
      searchApi: CustomTypesApi.lookupSearch(this.props.lookupName),
      selectedValue: undefined,
      selectedItem: undefined,
      searchText: ''
    };
  }

  componentDidMount(): void {
    this.handleValueChanged();
  }

  componentDidUpdate(prevProps: Readonly<ILookUpActionFieldProperties>, prevState: Readonly<ILookUpActionFieldState>): void {
    if (this.props.lookupName !== prevProps.lookupName) {
      this.setState(
        {
          getApi: CustomTypesApi.lookupGet(this.props.lookupName, this.props.params),
          searchApi: CustomTypesApi.lookupSearch(this.props.lookupName)
        });
    }

    if (this.props.value !== prevProps.value) {
      this.handleValueChanged();
    }

    if (this.state.selectedValue !== prevState.selectedValue) {
      this.handleSelectedValueChanged();
    } else if (this.state.selectedItem !== prevState.selectedItem) {
      this.handleSelectedItemChanged();
    }

    if (this.state.searchText !== prevState.searchText) {
      this.setState({ searchPending: true });
    }

    if (!this.state.loading && prevState.loading) {
      this.setState({ searchTouched: true });
    }
    if (this.state.loading !== prevState.loading) {
      if (this.props.isLoading) this.props.isLoading(this.state.loading);
    }
    if (this.state.browseOpen !== prevState.browseOpen) {
      if (this.props.onModalVisibilityChange) this.props.onModalVisibilityChange(this.state.browseOpen);
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value });
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER_KEY) {
      this.handleSearch().catch(this.handleError);
    }
  }

  handleBlur = () => {
    this.handleSearch()
      .then(() => { this.callOnBlur(); })
      .catch(this.handleError);
  }

  handleLookupModalClose = (selectedItem: ISelectedLookupOption) => {
    const { onModalClose } = this.props;
    if (selectedItem) {
      this.setState(() => ({
        browseOpen: false,
        selectedValue: selectedItem ? selectedItem.Value : null,
        selectedItem: selectedItem,
        searchText: selectedItem.Display || selectedItem.Value || this.props.searchText
      }));
    } else {
      this.setState(() => ({
        browseOpen: false
      }));
    }

    if (onModalClose) onModalClose(selectedItem);
  }

  handleSearch = async (): Promise<void> => {
    if (this.state.searchText === '') {
      this.setState({ selectedItem: null, searchPending: false });

      return Promise.resolve();
    }

    if (this.state.searchText === this.props.searchText) {
      this.setState({ searchPending: false });

      return Promise.resolve();
    }

    if (this.state.selectedItem && (this.state.selectedItem.Display || this.state.selectedItem.Value) === this.state.searchText) {
      this.setState({ searchPending: false });

      return Promise.resolve();
    }

    return this.getItemFromSearchText(this.state.searchText);
  }

  getItemFromValue = (value: string) => {
    this.setState({ loading: true });

    this.state.getApi(value).then(
      (result) => {
        this.setState((prevState, prevProps) => {
          if (prevProps.value && prevProps.value.toString() !== value) {
            // Stale retrieval
            return {
              ...prevState,
              loading: false,
              searchPending: false,
            };
          }

          const selectedItem = (result && this.props.value) ? { ...result, Value: value } : null;

          return {
            ...prevState,
            loading: false,
            searchPending: false,
            selectedValue: selectedItem ? selectedItem.Value : undefined,
            selectedItem: selectedItem,
            searchText: selectedItem ? selectedItem.Display || selectedItem.Value : prevState.searchText
          };
        });

      },
      this.handleError
    );

  }

  getItemFromSearchText = async (searchText: string): Promise<void> => {
    this.setState({ loading: true });

    return this.state.searchApi({ ...this.props.params || {}, SearchText: searchText }).then(
      (result) => {
        this.setState((prevState) => {

          const selectedKey = (result.Data.length === 1) ?
            result.Data[0].Code :
            prevState.selectedItem ? prevState.selectedItem.Value : null;

          const newSelectedItem = result.Data.find((d) => d.Code === selectedKey);
          const selectedItem = selectedKey && newSelectedItem ?
            { ...newSelectedItem, Value: selectedKey } :
            { ...prevState.selectedItem, Label: '' };

          if (result.Data.length === 0) {
            return {
              loading: false,
              searchPending: false,
              selectedValue: selectedItem ? selectedItem.Value : undefined,
              selectedItem: selectedItem,
              searchText: searchText,
              browseOpen: false
            };
          } else if (result.Data.length === 1) {
            return {
              loading: false,
              searchPending: false,
              selectedValue: selectedItem ? selectedItem.Value : undefined,
              selectedItem: selectedItem,
              searchText: selectedItem ? selectedItem.Display || selectedItem.Value : prevState.searchText,
              browseOpen: false
            };
          } else {
            return {
              loading: false,
              searchPending: false,
              selectedValue: selectedItem ? selectedItem.Value : undefined,
              selectedItem: selectedItem,
              searchText: searchText,
              browseOpen: true
            };
          }
        });
      });
  }

  validate = () => {
    if (this.state.searchPending) {
      return true;
    }

    if (this.state.loading) {
      return true;
    }

    if (this.state.selectedItem) {
      if ((this.state.selectedItem.Display || this.state.selectedItem.Value) === this.state.searchText) {
        return true;
      }
    } else {
      if (this.state.searchText === '') {
        return true;
      }
    }

    return false;
  }

  handleValueChanged = (): void => {
    const { value } = this.props;
    let selectedValue: string;
    let selectedItem: ISelectedLookupOption;
    let searchText;
    let mode: ('item' | 'value');

    if (!isNull(value) && typeof (value) === 'object') {
      mode = 'item';
      selectedItem = value || { Display: '', Value: undefined };
      selectedValue = selectedItem.Value;
      searchText = selectedItem.Display || selectedItem.Value || '';
    } else if (!isNull(value)) {
      mode = 'value';
      selectedValue = value.toString();
      selectedItem = this.state.selectedItem && this.state.selectedItem.Value === selectedValue ? this.state.selectedItem : undefined;
      searchText = selectedItem ? selectedItem.Display || selectedItem.Value || '' : '';
    } else if (this.props.searchText) {
      selectedValue = undefined;
      selectedItem = undefined;
      searchText = this.props.searchText;
    } else {
      selectedValue = undefined;
      selectedItem = undefined;
      searchText = '';
    }

    this.setState({
      mode: mode,
      loading: (selectedValue && !selectedItem),
      selectedValue: selectedValue,
      selectedItem: selectedItem,
      searchText: searchText
    });
  }

  handleSelectedValueChanged = (): void => {
    if (this.state.selectedItem && this.state.selectedItem.Value === this.state.selectedValue) {
      this.handleSelectedItemChanged();
    } else if (this.state.selectedValue) {
      // Value selected, not reflected by item.
      this.getItemFromValue(this.state.selectedValue);
    } else if (this.state.selectedItem) {
      // no value but selected item exists.
      this.setState({
        selectedItem: null,
        searchText: ''
      });
    }
  }

  handleSelectedItemChanged = (): void => {
    if (this.state.selectedItem && this.state.selectedItem.Value !== this.state.selectedValue) {
      // Out of date - value moved on.
      return;
    }
    this.callOnChange();
  }

  callOnChange = (): void => {
    const oldValue = this.props.value;
    const newValue = this.state.mode === 'item' ? this.state.selectedItem : (this.state.selectedItem ? this.state.selectedItem.Value : null);
    if (oldValue !== newValue) {
      if (this.props.onSelectedItemChange) {
        this.props.onSelectedItemChange(this.state.selectedItem);
      }
      if (this.props.onSelectedValueChange) {
        this.props.onSelectedValueChange(this.state.selectedItem ? this.state.selectedItem.Value : null);
      }
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }
  }

  callOnBlur = (): void => {
    const newValue = this.state.selectedItem ? this.state.selectedItem.Value : null;

    if (this.props.onBlur) {
      this.props.onBlur(newValue);
    }
  }

  handleError = (error: any): void => {
    this.setState({ loading: false });
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  openBrowseModal = (): void => {
    this.setState({ browseOpen: true });
  }

  render(): React.ReactNode {
    const { label, required, readOnly, onFocus, active, size, placeholder, style, suppressDescription, suppressErrorMessage, hasError, params, disabled, touched } = this.props;
    const { browseOpen, selectedItem, searchApi, searchText, searchTouched } = this.state;

    return (
      <React.Fragment>
        <ExtendedTextField
          placeholder={placeholder}
          label={label}
          value={searchText || ''}
          onChange={this.handleChange}
          fieldRef={(ref) => { this.inputField = ref; }}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          action={{ iconName: 'Search', controller: this.openBrowseModal }}
          onFocus={onFocus}
          onBlur={this.handleBlur}
          touched={searchTouched || touched}
          size={size}
          autoFocus={active}
          style={style}
          onKeyDown={this.handleKeyDown}
          validation={{
            controller: this.validate,
            errorMessage: !suppressErrorMessage ? `ERROR: Invalid ${(label !== undefined ? label : placeholder)}` : ''
          }}
          autoComplete={'off'}
          helpText={(!suppressDescription ? (selectedItem ? selectedItem.Label : '') : null)}
          hasError={hasError}
        />
        {browseOpen &&
          <BrowseLookUpModal
            open={browseOpen}
            title={`${(label ? label : placeholder)} lookup`}
            searchApi={searchApi}
            onClose={this.handleLookupModalClose}
            searchText={searchText}
            selectedItem={selectedItem}
            params={params}
            onError={this.props.onError}
          />}
      </React.Fragment>
    );
  }
}

export { LookUpActionField };
