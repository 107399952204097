import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    contentContainer: {
        width: 460,
        maxWidth: 460,
        height: 430,
        maxHeight: 430,
        padding: 25,
    },
    fullWidth: {
        gridColumn: '1 / span 2'
    },
    serviceDueContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    heading: {
        fontSize: 'larger',
        color: '#777777',
        margin: '20px 0px'
    },
    UpcomingGroupCellComponentContainer: {
        display: 'flex',
        gap: '20px',
    },
    dialogTitleHeading: {
        fontSize: 22,
        fontWeight: 'bold',
        paddingBottom: 8
    },
    dialogTitle: {
        padding: '20px 22px 10px 22px',
        backgroundColor: '#dddddd'
    },
    dialogActionBar: {
        backgroundColor: '#dddddd',
        borderRadius: 0.7,
        padding: '10px 22px 10px 22px',
        margin: 0,
        height: 52
    },
    dialogContent: {
        height: '80%',
        overflowY: 'hidden',
        padding: '0 1rem 0 1rem'
    },
    sectionHeading: {
        fontSize: 18,
        fontWeight: 'normal',
        color: '#777',
        marginBottom: 20
    },
    sectionContent: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#777',
        marginBottom: 10
    },
    dialogContainer: {
        padding: '1rem 0 1rem 0'
    },
    searchField: {
        display: 'inline-block',
        flexGrow: 1,
        marginRight: 7,
        fontWeight: 400,
        alignSelf: 'center'
    },
    searchButton: {
        minWidth: '100px',
        padding: '8px !important',
        justifyContent: 'space-around'
    },
    formControlRoot: {
        height: 74
    },
    nextBtn: {
        'background': '#F47920',
        'color': '#fff',
        'borderColor': '#ddd',
        'borderRadius': 4,
        'borderWidth': 1,
        'borderStyle': 'solid',
        'fontWeight': 400,
        'fontSize': 14,
        'lineHeight': 1.35715,
        'padding': '7px 18px 8px',
        'textTransform': 'none',
        'border': '1px solid #F47920',

        '&:hover': {
            background: '#F47920'
        },

        '&:disabled': {
            background: '#F47920',
            opacity: '50%',
            color: '#fff'
        }
    },
    cancelBtn: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    dateTimeRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'nowrap',
        justifyContent: 'flex-start'
    },
    dateTimeColumn: {
        paddingRight: '16px'
    },
    dataGrid: {
        minHeight: '150px'
    }
});
