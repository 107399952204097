import React from 'react';
import { withStyles } from '@material-ui/core';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IViewSelectionProps } from '../DynamicExtractModal.properties';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';

const ViewSelection = (props: IViewSelectionProps) => {
    const {
        classes,
        details,
        staff
    } = props;

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const CurrentPanel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);

    const [isLookupInError, setIsLookupInError] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            if (!WizardDetails?.Details?.EntityView) {

                contextDispatch({

                    setWizardDetails: { ...WizardDetails, Details: { ...WizardDetails?.Details, EntityView: staff.defaultView } }

                });

            }
        },
        []
    );

    React.useEffect(
        () => {
            if (!isLookupInError && WizardDetails?.Details?.EntityView && WizardDetails?.Details?.EntityView !== '') {
                contextDispatch({
                    setErrorState: { InError: false, Panel: '' }
                });
            } else {
                contextDispatch({
                    setErrorState: { InError: true, Panel: CurrentPanel }
                });
            }
        },
        [isLookupInError, WizardDetails?.Details?.EntityView]
    );

    const OnSelectionChanged = (value: any) => {
        if (value && value?.Code) {
            contextDispatch({
                setWizardDetails: { ...WizardDetails, Details: { ...WizardDetails?.Details, EntityView: value?.Code } }
            });
        }
    };

    const handleLookupError = React.useCallback(
        (error: boolean) => {
            setIsLookupInError(error);
        },
        [setIsLookupInError]
    );

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <BasicLookupActionField
                placeholder={'View'}
                label={'View'}
                size={'small'}
                searchScreenTitle={'View Lookup'}
                lookupName={'EntityView'}
                value={WizardDetails?.Details?.EntityView}
                required
                suppressDescription={false}
                descriptionField={'Label'}
                hasError={(value) => { handleLookupError(value); }}
                onSelectedItemChange={(item) => OnSelectionChanged(item as any)}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(ViewSelection);
