import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from '../SummaryTableSchema.json';
import { IPurchaseOrderLinesEnquiryProperties } from '../interfaces';

class PurchaseOrdersLinesEnquiry extends React.Component<IPurchaseOrderLinesEnquiryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/purchase-order-enquiry/order-lines':
          onInitialLoad('OrderLines');
          break;
        case '/purchase-order-enquiry/order-lines/line-details':
          onInitialLoad('LineDetails');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/committed-sales-orders':
          onInitialLoad('LDCommittedSalesOrder');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/committed-production-orders':
          onInitialLoad('LDCommittedProductionOrder');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/committed-service-orders':
          onInitialLoad('LDCommittedServiceOrder');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/in-transit/local-shipments':
          onInitialLoad('LDInTransitLocalShipments');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/in-transit/import-shipments':
          onInitialLoad('LDInTransitImportShipments');
          break;
        case '/purchase-order-enquiry/order-lines/line-details/branch-orders':
          onInitialLoad('LDBranchOrders');
          break;
        default:
          onInitialLoad('OrderLines');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedTab, orderLineDetails, orderLineSummary } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
        return ({ ...orderLineDetails, ...orderLineSummary });
      default:
        return {};
    }
  }

  getValueSchema = (): any => {
    const { selectedTab, orderLineDetailsSchema } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
        return orderLineDetailsSchema;
      default:
        return null;
    }
  }

  getSummaryTableRenderer = (): React.ReactNode => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
      case 'LDCommittedSalesOrder':
      case 'LDCommittedProductionOrder':
      case 'LDCommittedServiceOrder':
      case 'LDBranchOrders':
      case 'LDInTransitLocalShipments':
      case 'LDInTransitImportShipments':
        return this.lineSummary;
      default: return null;
    }
  };

  lineSummary = (): React.ReactNode => {
    const { orderLineSummary, loadingOrderLineSummary } = this.props;

    return <SummaryTable
      selectedLine={orderLineSummary}
      loadingOrderLineSummary={loadingOrderLineSummary}
      data={
        SummaryTableSchema.LineDetails
      } />;
  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;
    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValueSchema();

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={this.props.notifications}
          entity='Order Line'
        />
        <FormView
          isLoading={isLoading}
          formName='PurchaseOrdersLinesEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          valuesSchema={valuesSchema}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default PurchaseOrdersLinesEnquiry;
