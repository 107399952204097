import { combineReducers } from 'redux';
import purchaseOrders, { IPOState } from './purchaseOrders';

export interface IPurchaseOrdersState {
  orders: IPOState;
  [name: string]: any;
}
const combined = combineReducers<IPurchaseOrdersState>({ orders: purchaseOrders });

export default combined;
