import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';
import { pathOr } from 'utils/utils';

export interface ISlipLinesData {
  selected: any;
  selectedFilters: any;
  slipLineDetails: any;
  slipLineDetailsSummary: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  Columns: IColDef[];
  lines: any[];
  openChargeDetailModal: boolean;
  chargeDetails: any;
}

export interface ISlipLinesState extends IExtendedState<ISlipLinesData> {
  fetchSlipLineDetailsSummary_loading?: boolean;
  updateProductCharge_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    changeModalVisibility: (open) => (open),
    clearLines: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      fetchSlipLines: (lines) => (lines),
      deleteSlipLine: (lines) => (lines),
      fetchSlipLineDetails: (lines) => (lines),
      cancelSlipLineDetails: (lines) => (lines),
      updateSlipLineDetails: (lines) => (lines),
      fetchSlipLineDetailsSummary: (lines) => (lines),
      createProductCharge: () => null,
      updateProductCharge: (data) => (data),
    }
  },
  'PSslipLines');

const initialState = asyncInitialState<ISlipLinesData>({
  selected: null,
  selectedFilters: {
    Line: '',
    Product: ''
  },
  filterRow: {
    formName: 'PickSlipLinesFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Line',
          name: 'SalesInvoice',
          lookupName: 'SalesInvoice',
          isEntityScoped: true,
          size: 'small',
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Product',
          name: 'Product',
          lookupName: 'Product',
          size: 'medium',
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: 'SlipLineDetails',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Line', field: 'LineNumber', minWidth: 100, suppressSorting: true, type: 'numericColumn' },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      }
    },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Original currency', field: 'OriginalCurrencyDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Discount%', field: 'DiscountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 150, suppressSorting: true, },
    { headerName: 'Comment', field: 'Comment', minWidth: 150, suppressSorting: true, },
  ],
  lines: [],
  slipLineDetails: {},
  slipLineDetailsSummary: {},
  openChargeDetailModal: false,
  chargeDetails: {}
});

export default (state: ISlipLinesState = initialState, action: IDataAction): ISlipLinesState => {
  switch (action.type) {
    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetchSlipLines:
    case types.deleteSlipLine:
    case types.fetchSlipLineDetails:
    case types.cancelSlipLineDetails:
    case types.createProductCharge:
    case types.updateProductCharge:
    case types.updateSlipLineDetails:
    case types.fetchSlipLineDetailsSummary:
      return asyncOnRequest(state, action);

    case types.saga.fetchSlipLines.success:
    case types.saga.deleteSlipLine.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          lines: linesData.records
        };
      });

    case types.saga.fetchSlipLineDetails.success:
    case types.saga.cancelSlipLineDetails.success:
    case types.saga.updateSlipLineDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const lineDetails = successAction.payload;

        return {
          ...data,
          slipLineDetails: lineDetails
        };
      });

    case types.saga.createProductCharge.success:
    case types.saga.updateProductCharge.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        return {
          ...data,
          chargeDetails: successAction.payload
        };
      });

    case types.saga.fetchSlipLineDetailsSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const lineDetailsSummary = successAction.payload;

        return {
          ...data,
          slipLineDetailsSummary: lineDetailsSummary
        };
      });

    case types.saga.fetchSlipLines.failure:
    case types.saga.deleteSlipLine.failure:
    case types.saga.fetchSlipLineDetails.failure:
    case types.saga.cancelSlipLineDetails.failure:
    case types.saga.createProductCharge.failure:
    case types.saga.fetchSlipLineDetailsSummary.failure:
    case types.saga.updateProductCharge.failure:
    case types.saga.updateSlipLineDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { slipLines: ISlipLinesState } }) => state.pickSlips.slipLines,
  {
    selected: (data) => data.selected,
    Actions: (data) => data.Actions,
  }
);

const syncSelector = {
  openChargeDetailModal: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'openChargeDetailModal'], state),
  chargeDetails: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'chargeDetails'], state),
  lines: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'lines'], state),
  slipLineDetails: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'slipLineDetails'], state),
  slipLineDetailsSummary: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'slipLineDetailsSummary'], state),
  ProductId: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'slipLineDetailsSummary', 'values', 'ProductId'], state),
  WarehouseEntity: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'slipLineDetailsSummary', 'values', 'Warehouse'], state),
  LineNumber: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'selected', 'LineNumber'], state),
  isLoading: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr(false, ['pickSlips', 'slipLines', 'loading'], state),
  gridOptions: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'gridOptions'], state),
  columns: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'Columns'], state),
  Actions: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'Actions'], state),
  filterRow: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'filterRow'], state),
  selectedFilters: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'data', 'selectedFilters'], state),
  loadingSlipLineDetailsSummary: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'fetchSlipLineDetailsSummary_loading'], state),
  loadingChargeDetails: (state: { pickSlips: { slipLines: ISlipLinesState } }) => pathOr({}, ['pickSlips', 'slipLines', 'createProductCharge_loading'], state)
};

export const selectors = { ...asyncSelector, ...syncSelector };
