
import * as React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { IProgramArea } from 'api/swaggerTypes';

import Dashboard from '../DashboardView';
import { NotFound } from '../common/Errors';
import ProductMovementEnquiry from '../ProductMovementEnquiry';
import ComponentsMeta from '../Components';
import { ConnectSource } from '../../config';
import { isNull } from 'utils/utils';
import { MoProV2, MoProV3, PROGRAM_NAME_INDEX } from 'utils/constants';
import { IDashboardWidget } from 'components/DashboardMaintenance/DashboardMaintenance.properties';
import Worksale from 'components/Worksale';
import Paths from '../Paths.json';
import BackorderCommitment from 'components/Worksale/BackorderCommitment';
import CreditNotes from 'components/CreditNotes';
import PurchaseOrders from 'components/PurchaseOrders';

interface IAppContentProperties extends RouteComponentProps {
  programAreas: IProgramArea[];
  dashboardWidgets: IDashboardWidget[];
  programAreasLoaded: boolean;
  programAreasLoading: boolean;
  loggedIn: boolean;
  fetchMainMenu(): void;
  fetchProgramAreas(): void;
  fetchDashboardWidgets(): void;
  setAppVersion(version: string): void;
}

class AppContent extends React.Component<IAppContentProperties> {

  componentDidMount(): void {
    const { loggedIn, fetchMainMenu, fetchProgramAreas, setAppVersion, fetchDashboardWidgets } = this.props;
    if (loggedIn) {
      fetchMainMenu();
      fetchProgramAreas();
      fetchDashboardWidgets();
    }

    if (isNull(ConnectSource)) {
      setAppVersion(MoProV3);
    } else {
      setAppVersion(MoProV2);
    }
  }

  componentDidUpdate(prevProps: any): void {
    document.title = 'MomentumPro';
    //page url changes
    if (this.props.location.pathname !== prevProps.location.pathname ||
      //For refresh
      (this.props.location.pathname !== '/dashboard' && document.title === 'MomentumPro')) {

      document.title = this.programName();
    }
  }

  programName(): string {
    const { programAreas } = this.props;
    const rawProgramName = window.location.href.split('/')[PROGRAM_NAME_INDEX];
    const programId = Object.keys(Paths).find((key) => Paths[key] === `/${rawProgramName}`);
    const program = programAreas && programAreas.filter((programArea) => programArea.ProgramAreaId === Number(programId));

    return program && program[0] ? program[0].Description : 'MomentumPro';
  }

  render(): React.ReactNode {
    const { loggedIn, programAreas, programAreasLoaded, programAreasLoading } = this.props;

    if (!loggedIn) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: this.props.location ? { ...this.props.location, component: undefined } : { pathname: '/dashboard' } }
          }
          } />
      );
    }

    return (
      <Switch>
        <Route path={'/dashboard'} component={Dashboard} />
        {programAreasLoaded && programAreas && programAreas.map((a) => {
          const program = ComponentsMeta[a.ProgramAreaId];

          return program && <Route key={program.path} path={program.path} component={program.component} />;
        })}
        <Route path={'/product-movement-enquiry'} component={ProductMovementEnquiry} />
        <Route path={'/sales-processing'} component={Worksale} />
        <Route path={'/backorder-commitment'} component={BackorderCommitment} />
        <Route path={'/credit-notes'} component={CreditNotes} />
        <Route path={'/purchase-orders'} component={PurchaseOrders} />
        {!programAreasLoading && <Route path={'*'} component={NotFound} />}
      </Switch>
    );
  }
}

export default AppContent;
