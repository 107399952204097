import React from 'react';
import { withRouter } from 'react-router';
import { Paper, withStyles, Button } from '@material-ui/core';
import { IFieldsProps } from './Fields.properties';
import styles from './Fields.styles';
import { RemoveCircle, Edit, ArrowBack } from '@markinson/uicomponents-v2/SvgIcons/';
import CommitIcon from 'assets/Commit';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import SortGrid from './SortGrid';
import OutputFieldsGrid from './OutputFieldsGrid';
import FilterGrid from './FilterGrid';
import FieldsGrid from './FieldsGrid';
import ReportingAreaGrid from './ReportingAreaGrid';
import { useEMContextSelector, useEMDispatch } from '../ExtractMaintenance.context';
import { useAddExtractOutputFields, useAddSort, useEMCommitTemplate, useEMDiscardTemplate, useEMTemplateStartEdit, useFetchEMOutputFields, useRetrieveEMFilters, useRetrieveEMReportingAreas, useRetrieveEMTemplateSortFields, useRetrieveFilterField, useSetEMOutputFieldsQueryData } from 'api/extractMaintenance/extractMaintenanceTemplates';
import AddFilterDialog from './AddFilterDialog';
import { IExtractFilterFacade } from 'api/swaggerTypes';

const Fields = (props: IFieldsProps) => {
    const { classes } = props;

    const { setActionBar } = React.useContext(ActionBarContext);
    const firstButtonRef = React.useRef<HTMLButtonElement>(null);
    const lastButtonRef = React.useRef<HTMLButtonElement>(null);
    const fieldsGridInnerRef = React.useRef(null);
    const reportingAreaGridInnerRef = React.useRef(null);
    const params = React.useMemo(() => (new URLSearchParams(location.search)), [location.search]);
    const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);

    const { mutateAsync: saveAddSortMutateAsync } = useAddSort();
    const selectedFieldNames = useEMContextSelector<'focusedFieldNames'>((state) => state.focusedFieldNames);
    const contextDispatch = useEMDispatch();
    const [isFilterDialogOpen, toogleFilterDialog] = React.useState(false);
    const [filtersFormData, setFiltersFormData] = React.useState({});
    const [isInUpdateFilter, setIsInUpdateFilter] = React.useState(false);
    const { mutateAsync: filterMutateAsync } = useRetrieveFilterField();
    const AreaName = useEMContextSelector<'focusedArea'>((state) => state.focusedArea)?.AreaName;

    const { mutateAsync: addExtractOutputFieldsdMutateAsync } = useAddExtractOutputFields();
    const setOutputFieldsData = useSetEMOutputFieldsQueryData();

    const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);
    const EMTemplateStartEditMutation = useEMTemplateStartEdit();
    const EMDiscardTemplateMutation = useEMDiscardTemplate();
    const EMCommitdTemplateMutation = useEMCommitTemplate();
    const sortGridData = useRetrieveEMTemplateSortFields(templateId);
    const filterGridData = useRetrieveEMFilters(templateId);
    const outputFieldsGridData = useFetchEMOutputFields(templateId);
    const reportingAreaGridData = useRetrieveEMReportingAreas(templateId);

    const OnEditFields = React.useCallback(
        async () => {
            const response = await EMTemplateStartEditMutation.mutateAsync({ TemplateId: templateId });
            if (response.Status) {
                contextDispatch({
                    setIsFieldsInEdit: true,
                });
            }
        },
        [templateId]
    );

    const OnDiscardTemplate = async () => {
        const response = await EMDiscardTemplateMutation.mutateAsync({ TemplateId: templateId });
        if (response.Status) {
            sortGridData.refetch();
            filterGridData.refetch();
            outputFieldsGridData.refetch();
            reportingAreaGridData.refetch();
            contextDispatch({
                setFocusedFieldNamesempty: true,
                setIsFieldsInEdit: false,
            });
            reportingAreaGridInnerRef?.current?.OnCollapseAll();
        }
    };

    const OnCommitTemplate = async () => {
        const response = await EMCommitdTemplateMutation.mutateAsync({ TemplateId: templateId });
        if (response.Status) {
            sortGridData.refetch();
            filterGridData.refetch();
            outputFieldsGridData.refetch();
            reportingAreaGridData.refetch();
            contextDispatch({
                setFocusedFieldNamesempty: true,
                setIsFieldsInEdit: false,
            });
            reportingAreaGridInnerRef?.current?.OnCollapseAll();
        }
    };

    const setActionBarButtons = React.useCallback(
        async () => {
            setActionBar({
                leftIcons: [{
                    label: 'Back',
                    Icon: ArrowBack,
                    action: 'FieldsBackButtonClick'
                }],
                centerIcons: [],
                rightIcons: [
                    {
                        label: 'Edit',
                        Icon: Edit,
                        disabled: inEditMode,
                        action: 'FieldsOnEdit',
                        callBack: OnEditFields
                    },
                    {
                        label: 'Discard',
                        Icon: RemoveCircle,
                        disabled: !inEditMode,
                        action: 'FieldsOnDiscard',
                        iconStyle: { fill: inEditMode ? 'rgba(178, 0, 0, 1)' : '' },
                        callBack: OnDiscardTemplate
                    },
                    {
                        label: 'Commit',
                        Icon: CommitIcon,
                        disabled: !inEditMode,
                        iconStyle: { transform: 'rotate(180deg)', fill: inEditMode ? 'rgba(109, 217, 0, 1)' : '' },
                        action: 'FieldsOnCommit',
                        callBack: OnCommitTemplate
                    },
                ]
            });
        },
        [templateId, inEditMode, OnEditFields]
    );

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        [setActionBarButtons]
    );

    const ELEMENT2 = 2;

    const handleAddSort = async () => {
        const query: any = {
            SortProperty: selectedFieldNames[0].FieldName,
            Source: selectedFieldNames[0].Source,
            SortDirection: 'ASCE',
            FieldType: selectedFieldNames[0].FieldType
        };
        const response = await saveAddSortMutateAsync({
            TemplateId: templateId,
            ...query,
            urlQueryParams: {
                AreaName
            }
        });
        if (response.Status) {
            sortGridData.refetch();
            contextDispatch({ setFocusedFieldNamesempty: true });
        }
    };

    const handleAddFilter = async (data?: IExtractFilterFacade) => {
        toogleFilterDialog(true);
        const filterFormData = await filterMutateAsync(
            {
                FilterField: data?.FilterField || selectedFieldNames[0]?.FieldName,
                AreaName: data?.Source || AreaName,
                FieldDataType: data?.FieldDataType || selectedFieldNames[0]?.FieldDataType,
                TemplateId: templateId,
                FilterId: data?.FilterId || '%20',
            });

        setFiltersFormData({
            ...filterFormData,
            ExtractFilter: {
                ...filterFormData.ExtractFilter,
                inlineObject: {
                    ...filterFormData.ExtractFilter.inlineObject,
                    FieldDataType: filterFormData?.ExtractFilter?.inlineObject?.FieldDataType || selectedFieldNames[0]?.FieldDataType
                }
            }
        });
        setIsInUpdateFilter(Boolean(data));
    };

    return (<>
        <div className={classes.fieldsContainer}>
            <div className={classes.leftContainer}>
                <Paper className={classes.paperLeftContainer}>
                    <Paper className={classes.paperLeft} elevation={0}>
                        <Paper className={classes.containerLabel} elevation={0}>Reporting area</Paper>
                        <ReportingAreaGrid innerRef={reportingAreaGridInnerRef} />
                    </Paper>
                    <Paper className={classes.fieldsLeftContainer} elevation={0}>
                        <Paper className={classes.containerLabel} elevation={0}>Fields</Paper>
                        <FieldsGrid innerRef={fieldsGridInnerRef} />
                    </Paper>
                </Paper>
                <div className={classes.buttonsContainer}>
                    {
                        [
                            {
                                id: 0,
                                label: 'Add sort',
                                isDefault: false,
                                listener: handleAddSort,
                                disabled: selectedFieldNames.length === 0 || selectedFieldNames.length > 1
                            },
                            {
                                id: 1,
                                label: 'Add filter',
                                isDefault: false,
                                listener: handleAddFilter,
                                disabled: selectedFieldNames.length === 0 || selectedFieldNames.length > 1
                            },
                            {
                                id: 2,
                                label: 'Add field',
                                isDefault: Boolean(selectedFieldNames.length),
                                listener: async () => {
                                    const response = await addExtractOutputFieldsdMutateAsync({
                                        TemplateId: templateId,
                                        OutputFields: fieldsGridInnerRef.current?.getSelectedRowsData(),
                                        urlQueryParams: {
                                            AreaName
                                        }
                                    });

                                    if (response.Status) {
                                        fieldsGridInnerRef.current?.deselectAll();
                                        setOutputFieldsData(Number(templateId), response);
                                    }
                                },
                                disabled: selectedFieldNames.length === 0
                            },
                        ].map((button) => {
                            return (
                                <Button
                                    key={button.id}
                                    color='primary'
                                    onClick={() => { button.listener(); }}
                                    disabled={button.disabled}
                                    autoFocus={button.isDefault}
                                    className={(button.isDefault) ? classes.active : classes.action}
                                    buttonRef={(Ref) => {
                                        if (button.id === 0) {
                                            firstButtonRef.current = Ref;
                                        }
                                        if (button.id === ELEMENT2) {
                                            lastButtonRef.current = Ref;
                                        }
                                    }}
                                >
                                    {button.label}
                                </Button>
                            );
                        })
                    }
                </div>
            </div>
            <div className={classes.rightContainer}>
                <Paper className={classes.paper}>
                    <Paper className={classes.containerLabel} elevation={0}>Sort</Paper>
                    <SortGrid />
                </Paper>
                <Paper className={classes.paper}>
                    <Paper className={classes.containerLabel} elevation={0}>Filter</Paper>
                    <FilterGrid handleUpdateFilterDetails={handleAddFilter} />
                </Paper>
                <Paper className={classes.outputFieldsContainer}>
                    <Paper className={classes.containerLabel} elevation={0}>Output fields</Paper>
                    <OutputFieldsGrid />
                </Paper>
            </div>
        </div>
        <AddFilterDialog isFilterDialogOpen={isFilterDialogOpen} toogleFilterDialog={toogleFilterDialog} filtersFormData={filtersFormData} isInUpdateFilter={isInUpdateFilter} />
    </>);
};

export default withRouter(withStyles(styles, { index: 1 })(React.memo(Fields)));
