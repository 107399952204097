import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { ISupplierInvoiceEnquiryLineFacade } from 'api/swaggerTypes';
import { IInvoiceLinesSummaryResponse, IInvoiceLinesDetailsResponse } from 'api/supplierInvoiceEnquiry/interfaces';

export interface IInvoiceLinesData {
  selected: any;
  gridOptions: IDataGridOptions;
  Actions: any;
  invoiceLineSummary: IInvoiceLinesSummaryResponse;
  lineDetails: IInvoiceLinesDetailsResponse;
  lineColumns: IColDef[];
  lines: ISupplierInvoiceEnquiryLineFacade[];
}

export interface IInvoiceLinesState extends IExtendedState<IInvoiceLinesData> {
  getInvoiceLineSummary_loading?: boolean;
}

const initialData: IInvoiceLinesData = {
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true
  },
  Actions: {
  },
  lineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100 },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      minWidth: 100,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Buy quantity', field: 'BuyQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Unit', field: 'Unit', suppressSorting: true },
    { headerName: 'Tax Total', field: 'TaxTotalDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Extended', field: 'ExtendedDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Description', field: 'Description', suppressSorting: true },
    { headerName: 'Group', field: 'Group', suppressSorting: true }
  ],
  lines: [],
  invoiceLineSummary: null,
  lineDetails: null
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'supplierInvoiceLines',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (row: any) => (row),
      callback: (data: IInvoiceLinesData, row: any) => ({ ...data, selected: row })
    },
    clearLines: {
      action: () => null,
    }
  },
  asyncActions: {
    getInvoiceLineSummary: {
      action: (query: { DocumentNumber: number; LineNumber: number }) => (query),
      successCallback: (data: IInvoiceLinesData, payload: IInvoiceLinesSummaryResponse) => ({ ...data, invoiceLineSummary: payload })
    },
    getInvoiceLineDetails: {
      action: (query: { DocumentNumber: number; LineNumber: number }) => (query),
      successCallback: (data: IInvoiceLinesData, payload: IInvoiceLinesDetailsResponse) => ({ ...data, lineDetails: payload })
    },
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { supplierInvoiceEnquiry: { supplierInvoiceLines: IInvoiceLinesState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLines,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
    invoiceLineSummary: (data) => data.invoiceLineSummary,
    details: (data) => data.lineDetails,
  }
);

const syncSelector = {
  isLoading: (state: { supplierInvoiceEnquiry: { supplierInvoiceLines: IInvoiceLinesState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLines.loading,
  loadingSummary: (state: { supplierInvoiceEnquiry: { supplierInvoiceLines: IInvoiceLinesState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLines.getInvoiceLineSummary_loading,
  gridOptions: (state: { supplierInvoiceEnquiry: { supplierInvoiceLines: IInvoiceLinesState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLines.data.gridOptions,
  lineColumns: (state: { supplierInvoiceEnquiry: { supplierInvoiceLines: IInvoiceLinesState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLines.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
