interface IParamsAsObject {
  SalesEntity?: string;
  InvoiceNumber?: string;
}

function appendParams(path: string, params: (IParamsAsObject | string | number)): string {
  if (typeof (params) === 'string') {
    if (params) {
      const search = new URLSearchParams();
      search.append('SearchText', params);
      path += `?${search.toString()}`;
    }
  } else if (typeof(params) === 'number') {
    if (params) {
      const search = new URLSearchParams();
      search.append('SearchText', params.toString());
      path += `?${search.toString()}`;
    }
  } else if (typeof (params) === 'object') {
    if (params && (params.SalesEntity && params.InvoiceNumber)) {
      const search = new URLSearchParams();
      search.append('SalesEntity', params.SalesEntity);
      search.append('InvoiceNumber', params.InvoiceNumber);
      path += `?${search.toString()}`;
    }
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Sales Invoice Enquiry in new tab',
    action: (params: (IParamsAsObject | string | number)) => {window.open(appendParams('/sales-invoice-enquiry/invoice-details', params)); }
  },
  {
    id: '2',
    label: 'Sales Invoice Enquiry',
    action: (params: (IParamsAsObject | string | number)) => {window.location.assign(appendParams('/sales-invoice-enquiry/invoice-details', params)); }
  }
];
