import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as lostSalesSelectors, actions as lostSalesActions } from 'ducks/inventoryEnquiry/sales/lostSales';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { search } from 'api/inventoryEnquiry/sales/lostSales';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { actions as formActions } from 'ducks/form';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IESLostSales',
  columnDefs: lostSalesSelectors.columns(state),
  rowData: lostSalesSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lostSalesSelectors.gridOptions(state),
  isLoading: lostSalesSelectors.isLoading(state),
  filterRow: lostSalesSelectors.filterRow(state),
  selectedFilters: lostSalesSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) && {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  appliedFilters: lostSalesSelectors.filterRow(state) && filterSelectors.getFilters(state, lostSalesSelectors.filterRow(state).formName),
  apiMethod: search
})

export const actions = {
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lostSalesActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
