import { ISnackBarState } from './SnackBarProvider';
import { IErrorValidationDetails, IFormInfo } from 'api/swaggerTypes';
import { isNull } from 'utils/utils';
import { autoHideDuration } from 'utils/constants';
import { OptionsWithExtraProps, SnackbarMessage, VariantType, enqueueSnackbar as enqueueSnackbarImported, useSnackbar as useNoStackSnackbar } from 'notistack';
import React from 'react';

interface IResponseExtension {
    Forms: IFormInfo[];
    Status: boolean | string;
    ValidationErrors: IErrorValidationDetails[];
}

export const VARIANT = {
    Success: 'success',
    Information: 'info',
    Alert: 'warning',
    Error: 'error',
};
const parseSnackBarData = (data: ISnackBarState) => ({
    ...data,
    autoHideDuration: data?.time > 0 ? data?.time : autoHideDuration,
    preventDuplicate: true,
} as OptionsWithExtraProps<VariantType> & { message?: SnackbarMessage });

const showSnackBarImported = (data: ISnackBarState) => enqueueSnackbarImported(parseSnackBarData(data));

export const useSnackBar = () => {
    const { enqueueSnackbar, closeSnackbar } = useNoStackSnackbar();

    const showSnackBar = React.useCallback(
        (data: ISnackBarState): void => {
            const showSnackBarInner = () => enqueueSnackbar(parseSnackBarData(data));

            if (data?.delay > 0) {
                setTimeout(showSnackBarInner, data.delay);
            } else {
                showSnackBarInner();
            }
        },
        [enqueueSnackbar]
    );

    const hideSnackBar = React.useCallback(
        (): void => {
            closeSnackbar();
        },
        [closeSnackbar]
    );

    const validateAndShowSnackBar = React.useCallback(
        <R extends IResponseExtension>(response: R) => {
            if (isNull(response?.Forms)) {
                return response;
            }

            const forms = response?.Forms;

            const checkAndShowSnackBar = (form: IFormInfo) => {
                showSnackBar({ variant: VARIANT[form.MessageType], message: form?.Message, time: autoHideDuration });
            };

            forms.forEach((form) => {
                if (form?.DisplayType === 'StatusMessage') {
                    checkAndShowSnackBar(form);
                }
            });

            if (response?.Status === 'ValidationError') {
                response?.ValidationErrors.forEach((error) => {
                    showSnackBar({ variant: 'error', message: error?.Message });
                });
            }

            return response;
        },
        [showSnackBar]
    );

    return {
        validateAndShowSnackBar,
        showSnackBar,
        hideSnackBar
    };
};

export { showSnackBarImported as showSnackBar };
