import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IInvoicesData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  Columns: IColDef[];
  Data: any[];
}

export interface IInvoicesState extends IExtendedState<IInvoicesData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clearLines: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      fetch: (Data) => (Data),
    }
  },
  'invoices');

const RequiredFields = [
  'EntityView', 'TransactionType', 'TransactionOption'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IInvoicesData>({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Entity: '',
    TransactionType: 'All',
    TransactionOption: 'All',
    FromDate: '',
    ToDate: '',
    CustomerPO: ''
  },
  filterRow: {
    formName: 'CustomerInvoiceFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Invoice type',
          name: 'TransactionType',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Invoice',
              label: 'Invoice'
            },
            {
              value: 'Credit note',
              label: 'Credit note'
            }
          ]
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'TransactionOption',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Yes',
              label: 'Updated'
            },
            {
              value: 'No',
              label: 'Not Updated'
            }
          ]
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Customer P/O',
          name: 'CustomerPO',
          size: 'medium',
          required: false,
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Entity', field: 'SalesEntity', minWidth: 100, suppressSorting: true, },
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.InvoiceNumber) {
          return {
            SalesEntity: row.data.SalesEntity,
            InvoiceNumber: row.data.InvoiceNumber,
            FieldValue: row.data.InvoiceNumber
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Date', field: 'TransactionDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Type', field: 'InvoiceType', minWidth: 150, suppressSorting: true, },
    { headerName: 'Invoice total', field: 'InvoiceTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true, },
    {
      headerName: 'Sales order',
      field: 'SalesOrder',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.SalesOrder) {
          return {
            SalesOrder: row.data.SalesOrder,
            FieldValue: row.data.SalesOrder
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-order-enquiry/order-details'
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 150, suppressSorting: true, },
    { headerName: 'Local total', field: 'LocalTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Updated', field: 'UpdatedDisplay', minWidth: 120, suppressSorting: true, },
  ],
  Data: []
});

export default (state: IInvoicesState = initialState, action: IDataAction): IInvoicesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          Data: linesData
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices,
  {
    //data: (data) => data.Data,
    selected: (data) => data.selected,
    Actions: (data) => data.Actions,
  }
);

const syncSelector = {
  Data: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.Data,
  isLoading: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.loading,
  gridOptions: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.gridOptions,
  columns: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.Columns,
  Actions: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.Actions,
  filterRow: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.filterRow,
  selectedFilters: (state: { customerEnquiry: { invoices: IInvoicesState } }) => state.customerEnquiry.invoices.data.selectedFilters
};

export const selectors = { ...asyncSelector, ...syncSelector };
