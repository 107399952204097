import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as enquiriesSelectors, actions as enquiriesActions } from 'ducks/customerEnquiry/enquiries';
import { fetch } from 'api/customerEnquiry/enquiries';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as customerSelector } from 'ducks/customer';


const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'CEEnquiries',
  columnDefs: enquiriesSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: enquiriesSelectors.gridOptions(state),
  filterRow: enquiriesSelectors.filterRow(state),
  selectedFilters: enquiriesSelectors.selectedFilters(state),
  appliedFilters: enquiriesSelectors.filterRow(state) && filterSelectors.getFilters(state, enquiriesSelectors.filterRow(state).formName),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: enquiriesActions.setSelected,
  applyFilters: filterActions.applyFilters
}
