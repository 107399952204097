import { takeLatest, Effect } from 'redux-saga/effects';
import { types as supplierProductTypes, actions as supplierProductActions } from 'ducks/supplierProductEnquiry/supplierProduct';
import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierProductEnquiry/supplierProduct';

export default function* rootDocumentOutputOptionsSaga(): IterableIterator<Effect> {
  yield takeLatest(supplierProductTypes.search, connectSagaToApi(supplierProductActions, 'search', api.search));
  yield takeLatest(supplierProductTypes.searchById, connectSagaToApi(supplierProductActions, 'searchById', api.searchById));
  yield takeLatest(supplierProductTypes.fetchNextPage, connectSagaToApi(supplierProductActions, 'fetchNextPage', api.search));
  yield takeLatest(supplierProductTypes.fetchPrevPage, connectSagaToApi(supplierProductActions, 'fetchPrevPage', api.search));
  yield takeLatest(supplierProductTypes.getSummary, connectSagaToApi(supplierProductActions, 'getSummary', api.fetchSummary));
}
