import { CSSProperties } from '../../../node_modules/@material-ui/core/styles/withStyles';

interface IBaseElement {
  id?: string | number;
  visible?: boolean;
  style?: CSSProperties;
  hideInV2?: boolean;
  hideInV3?: boolean;
  position?: {
    row: number;
    col: number;
    colspan?: number;
  };
  layout?: {
    row?: number;
    xs?: {
      col: number;
    };
    sm?: {
      col: number;
    };
    md?: {
      col: number;
    };
    lg?: {
      col: number;
    };
  };
}

export interface IPaperContainer extends IBaseElement {
  type: 'PAPER_CONTAINER';
  children?: FormViewField[];
  props: {
    name?: string;
    label?: string;
    style?: CSSProperties;
    fullWidth?: boolean;
    required?: boolean;
    elevation?: number;
    minColumnWidth?: number;
  };
}

export interface ISubheader extends IBaseElement {
  type: 'SUBHEADER';
  props: {
    value?: string;
    name?: string;
  };
}

export interface ITypography extends IBaseElement {
  type: 'TYPOGRAPHY';
  props: {
    text?: string;
    customStyles?: CSSProperties;
    type?: 'default' | 'heading1' | 'heading2' | 'heading3' | 'heading' | 'fieldLabel' | 'actionButtonText' | 'custom';
  };
}

export interface IApiPrefs {
  path: string;
  method: string;
  params?: any;
  LookupType?: string;
}

export interface IInputProps {
  maxLength?: number;
  minLength?: number;
}

export interface IConstraints extends IInputProps {
  maxValue?: number;
  minValue?: number;
}

export interface ICommonFieldProperties {
  name?: string;
  style?: CSSProperties;
  label?: string;
  alignment?: string;
  required?: boolean;
  disabled?: boolean;
  emphasized?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  hideInV2?: boolean;
  hideInV3?: boolean;
  type?: string;
  size?: string;
  hasDynamicLabel?: boolean;
  defaultValue?: any;
  placeholder?: string;
  sort?: 'asc' | 'desc';
  constraints?: IConstraints;
  inputProps?: IInputProps;
  precedentFields?: string[];
}

export interface IAddressBlock extends IBaseElement {
  type: 'ADDRESS_BLOCK';
  props: (ICommonFieldProperties | {
  });
}
export interface IGoogleAddressBlock extends IBaseElement {
  type: 'ADDRESS_BLOCK_EX';
  props: (ICommonFieldProperties | {
  });
}

export interface IContactBlock extends IBaseElement {
  type: 'CONTACT_BLOCK';
  props: (ICommonFieldProperties | {
    variant?: string;
  });
}

export interface IToggleField extends IBaseElement {
  type: 'TOGGLE_FIELD';
  props: (ICommonFieldProperties | {
    defaultValue?: boolean;
  });
}
export interface IDataGridDevEx extends IBaseElement {
  type: 'DATA_GRID_DEV_EX';
  props: (ICommonFieldProperties | {
    module?: string;
    styles?: CSSProperties;
    columnDefs?: any;
  });
}

export interface IImageGrid extends IBaseElement {
  type: 'IMAGE_GRID';
  name?: string;
  props: (ICommonFieldProperties | {
    variant?: string;
  });
}

export interface IBreaksTable extends IBaseElement {
  type: 'BREAKS_TABLE';
  props: (ICommonFieldProperties | {
    variant: string;
  });
}

export interface INotepad extends IBaseElement {
  type: 'NOTEPAD';
  props: (ICommonFieldProperties | {
    formName?: string;
    fetchApi?: string;
    createApi?: string;
    updateApi?: string;
    deleteApi?: string;
  });
}

export interface IEvents extends IBaseElement {
  type: 'EVENTS';
  props: (ICommonFieldProperties | {
    fetchApi?: string;
  });
}

export interface IDiary extends IBaseElement {
  type: 'DIARY';
  props: (ICommonFieldProperties | {
    fetchApi?: string;
    createApi?: string;
    updateApi?: string;
    deleteApi?: string;
    diaryType?: string;
  });
}

export interface IDiaryDetails extends IBaseElement {
  type: 'DIARY_DETAILS';
  props: (ICommonFieldProperties | {
    diaryType?: string;
    createApi?: string;
    updateApi?: string;
    deleteApi?: string;
    searchByIdApi?: string;
  });
}

export interface ITextAreaField extends IBaseElement {
  type: 'TEXT_AREA';
  props: (ICommonFieldProperties | {
  });
}
export interface IImageViewField extends IBaseElement {
  type: 'IMAGE_VIEW';
  props: (ICommonFieldProperties | {
    imageCount: any;
    getMainImage: any;
    mainImageObject: any;
    getListOfImages: any;
  });
}

export interface ITextField extends IBaseElement {
  type: 'TEXT_FIELD';
  props: (ICommonFieldProperties | {
    placeholder?: string;
    decimalSeparator?: boolean;
    decimalPlaces?: number;
    preventNegativeValue?: boolean;
  });
}

export interface IExtendedTextField extends IBaseElement {
  type: 'EXTENDED_TEXT_FIELD';
  props: (ICommonFieldProperties | {
    api?: string;
    action?: { iconName: string };
    helpText?: string;
    placeholder?: string;
    decimalSeparator?: boolean;
    suppressHelpText?: boolean;
    preventNegativeValue?: boolean;
    params?: { queryFields: { [key: string]: (string | { Field: string; Property: ('Display' | 'Value') }) } };
  });
}

export interface IRestockingCalculatorField extends IBaseElement {
  type: 'RESTOCKING_CALCULATOR_FIELD';
  props: (ICommonFieldProperties) | {
    decimalSeparator?: boolean;
  };
}

export interface ICustomerLookupField extends IBaseElement {
  type: 'CUSTOMER_LOOKUP_FIELD';
  props: (ICommonFieldProperties | {
    lookupName?: string;
    placeholder?: string;
    params?: any;
    valueField?: string;
    isCustomerScoped?: boolean;
    isProductScoped?: boolean;
  });
}

export interface IExtendedDisplayField extends IBaseElement {
  type: 'EXTENDED_DISPLAY_FIELD';
  props: (ICommonFieldProperties | {
    api?: string;
    action?: { iconName: string };
    helpText?: string;
    placeholder?: string;
    decimalSeparator?: boolean;
    params?: { queryFields: { [key: string]: (string | { Field: string; Property: ('Display' | 'Value') }) } };
    adornment: any;
  });
}

export interface ILookupField extends IBaseElement {
  type: 'EX_LOOKUP_FIELD';
  props: (ICommonFieldProperties | {
    lookupName?: string;
    placeholder?: string;
    params?: any;
    apiPrefs?: IApiPrefs;
    isCustomerScoped?: boolean;
    isWorksaleScoped?: boolean;
    isProductScoped?: boolean;
    isEntityScoped?: boolean;
    isSupplierScoped?: boolean;
    advancedApi?: string;
    fieldList?: string;
    fieldKey?: string;
    valueField?: string;
    displayField?: string;
    descriptionField?: string;
    suppressDescription?: boolean;
    suppressValidation?: boolean;
    isLookupTypeFixed?: boolean;
  });
}

export interface ISelectField extends IBaseElement {
  type: 'SELECT_FIELD';
  props: (ICommonFieldProperties | {
    placeholder?: string;
    apiPrefs?: IApiPrefs;
    value?: string;
    isEntityScoped?: boolean;
    isCustomerScoped?: boolean;
    isProductScoped?: boolean;
    options?: {
      value: string;
      label: any;
    }[];
  });
}

export interface IAutoCompleteField extends IBaseElement {
  type: 'AUTOCOMPLETE_FIELD';
  props: (ICommonFieldProperties | {
    apiPrefs?: IApiPrefs;
    isClearable?: boolean;
    options: {
      value: any;
      label: string;
    }[];
  });
}

export interface IActionField extends IBaseElement {
  type: 'ACTION_FIELD';
  props: (ICommonFieldProperties | {
  });
}

export interface IDateField extends IBaseElement {
  type: 'DATE_FIELD';
  props: (ICommonFieldProperties | {
  });
}

export interface ITimeField extends IBaseElement {
  type: 'TIME_FIELD';
  props: (ICommonFieldProperties | {
  });
}

export interface IDocumentOutputButton extends IBaseElement {
  type: 'DOCUMENT_OUTPUT_FIELD';
  props: (ICommonFieldProperties | {
  });
}

export interface ISerialNumberProperties extends IBaseElement {
  type: 'SERIAL_NUMBER_GRID';
  props: (ICommonFieldProperties | {
    getSummaryAPI: string;
    getSelectedAPI: string;
  });
}
export interface IMaskedInput extends IBaseElement {
  type: 'MASKED_INPUT';
  props: (ICommonFieldProperties | {
    mask: (string | RegExp)[];
  });
}
export interface IDivider extends IBaseElement {
  type: 'DIVIDER';
  props: (ICommonFieldProperties);
}

export interface IDivider extends IBaseElement {
  type: 'DIVIDER';
  props: (ICommonFieldProperties);
}

export interface IGroup extends IBaseElement {
  group: {
    style?: CSSProperties;
    childern: FormViewField[];
  };
}

export type FormViewField = (
  IPaperContainer |
  ISubheader |
  IAddressBlock |
  IGoogleAddressBlock |
  IContactBlock |
  IToggleField |
  IDataGridDevEx |
  IImageGrid |
  IDiary |
  IDiaryDetails |
  INotepad |
  IEvents |
  ITextAreaField |
  IImageViewField |
  ITextField |
  ILookupField |
  ICustomerLookupField |
  ISelectField |
  IAutoCompleteField |
  IActionField |
  IDateField |
  ITimeField |
  IExtendedTextField |
  IRestockingCalculatorField |
  IExtendedDisplayField |
  IBreaksTable |
  IDocumentOutputButton |
  ISerialNumberProperties |
  IMaskedInput |
  IDivider |
  ITypography |
  IGroup);

export interface IFormViewForm {
  id: string;
  layout: {
    rows: number;
    columns: number;
  };
  fields: FormViewField[];
}

export { default } from './FormView';
