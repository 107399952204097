import { createReducer } from 'utils/helpers';
import { PurchaseOrdersActionTypes, IActions } from './PurchaseOrders.actions';
import { IPriceOverrideAuthorityDialogProperties } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';

export type IPriceOverrideDialogState<IApiType = any> = Omit<IPriceOverrideAuthorityDialogProperties<IApiType>, 'values' | 'resetForm' | 'onOk' | 'onCancel'>;
export interface IState {
    PurchaseOrder?: number;
    LineNumber?: number;
}

export const initialState: IState = {
    PurchaseOrder: 0,
    LineNumber: 0,
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<PurchaseOrdersActionTypes, IAction>;

const actionMap: IActionMap = {
    [PurchaseOrdersActionTypes.setPurchaseOrder]: (state: IState, payload: IState['PurchaseOrder']): IState => ({ ...state, PurchaseOrder: payload }),
    [PurchaseOrdersActionTypes.setLineNumber]: (state: IState, payload: IState['LineNumber']): IState => ({ ...state, LineNumber: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
