import * as React from 'react';
import DataGrid,
{
  Column, Paging, Selection, MasterDetail,
  Scrolling, GroupPanel, SearchPanel, LoadPanel
} from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core';
import styles from './DataGrid.styles';
import { IColDef, IDataGridProps } from './DataGrid.properties';
import FilterRow from './FilterRow';
import { Popup } from 'devextreme-react/popup';
import { isNull } from 'utils/utils';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import InfoIcon from '@markinson/uicomponents-v2/SvgIcons/Info';
import { withRouter } from 'react-router-dom';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import HyperLinkRenderer from './CustomRenderers/HyperLinkRenderer';
import MasterDetailRowRenderer from './CustomRenderers/MasterDetailRowRenderer';
import { COLUMN_SIZE, DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from './DataGrid.constants';
import CreateStore from './DataGrid.createStore';
import FileDownloadCellRenderer from './CustomRenderers/FileDownloadCellRenderer';
import ExportSpreadSheetModal, { useExcelExport } from './ExportSpreadSheetModal';

const HyperlinkCell = (row: any, col: IColDef) => {
  const params = col.hyperlinkParamGetter ? col.hyperlinkParamGetter(row) : {};
  const link = (typeof col.cellRendererParams.link === 'function') ? col.cellRendererParams.link(row) : col.cellRendererParams.link;

  return <HyperLinkRenderer
    colDef={col}
    value={params}
    link={link}
  />;
};

const addMenuItems = (contextMenu, contextMenuOnItemClick, onExporting) => (e: any): void => {
  if (e.target === 'content') {
    // e.items can be undefined
    if (!e.items) e.items = [];

    if (!isNull(e.row)) {
      if (contextMenu) {
        contextMenu.forEach((item: { name: any }) => {
          if (typeof item === 'object') {
            e.items.push(
              {
                ...item,
                text: item.name,
                onItemClick: (itemEvent: { itemData: any }) => contextMenuOnItemClick(itemEvent.itemData, e)
              }
            );
          }
        });
      }
    }

    if (onExporting) {
      e.items.push(
        {
          text: 'Export to Spreadsheet',
          onItemClick: () => onExporting(e)
        }
      );
    }
    // Add a custom menu item
  }
};

const DataGridDevEx = (props: Readonly<IDataGridProps>) => {
  const {
    classes, style = {}, useRowData = false, dataGridRef, columnDefs, dataGridProps = {}, reqParams = {}, keyFields = [], apiMethod, isLoading,
    filterRow, gridOptions = {}, applyFilters, selectedFilters, appliedFilters = [], rowData, masterDetailRenderer, doubleClickActionTab: formDoubleClickActionTab,
    changeSelectedTab, contextMenu, contextMenuOnItemClick, history, detailColumns, setSelectedOrderLine: setSelectedLine, isV2, isV3, selectedTab,
    customFilterRowRenderer, customPopupContentRenderer
  } = props;

  const { repaintChangesOnly = true, remoteOperations = true, allowColumnResizing = true, showBorders = false, hoverStateEnabled = true, allowColumnReordering = true, ...restDataGridProps } = dataGridProps;

  const {
    detailCellRenderer, doubleClickActionTab, detailCellRendererParams = {}, disableExcelExport = false,
  } = gridOptions;

  const internalRef = dataGridRef ? dataGridRef : React.useRef<DataGrid>();
  const { openExportModal, fileMeta, fileMetaRef, setFileMeta, onExporting, exportToSpreadsheet, handleExportCancel } = useExcelExport(selectedTab);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupText, setPopupText] = React.useState('');
  const [popupLabel, setPopupLabel] = React.useState('');

  const [customPopupSelectedData, setCustomPopupSelectedData] = React.useState({});
  const hasRowData = useRowData || (props.hasOwnProperty('rowData') && isNull(apiMethod));
  const isCellRendererDisabled = ({ hideCellRendererInV2, hideCellRendererInV3 }: IColDef) => (isV2 && hideCellRendererInV2) || (isV3 && hideCellRendererInV3);
  const addMenuItemsCallBack = addMenuItems(contextMenu, contextMenuOnItemClick, !disableExcelExport && onExporting);

  const gridData = React.useMemo(
    () => hasRowData ? rowData : CreateStore(apiMethod, appliedFilters, keyFields, reqParams, fileMetaRef),
    [hasRowData, rowData, apiMethod, JSON.stringify(appliedFilters), JSON.stringify(keyFields), JSON.stringify(reqParams)]
  );

  React.useEffect(
    () => {
      if (internalRef && internalRef.current) {
        if (isLoading) {
          internalRef.current.instance.beginCustomLoading('');
        } else {
          internalRef.current.instance.endCustomLoading();
        }
      }
    },
    [isLoading]
  );

  const CustomCelRenderers = {
    hyperLinkRenderer: HyperlinkCell,
    FileDownload: FileDownloadCellRenderer
  };

  const hasMasterDetail = masterDetailRenderer || (detailColumns && detailColumns.detailGridOptions && !isNull(detailColumns.detailGridOptions.columnDefs));
  const hasDrillDown = !isNull(doubleClickActionTab);
  const showInfoIcon = (!isNull(detailCellRenderer) && detailCellRenderer === 'CommentAreaRenderer') || customPopupContentRenderer;
  const isRightAligned = (col: IColDef) => String(col.type).toUpperCase() === 'NUMERICCOLUMN';

  function renderFilterRow(): React.ReactNode {
    return filterRow && (<div>
      <FilterRow
        parameters={filterRow.parameters}
        formName={filterRow.formName}
        onApplyFilters={applyFilters}
        initialValues={selectedFilters}
        validate={filterRow.validate}
        reqParams={reqParams}
        hidePreFilters={filterRow.hidePreFilters}
        rowModelType={gridOptions.rowModelType} />
    </div>);
  }

  function renderPopup(): React.ReactChild {
    const hidePopup = () => {
      setPopupOpen(false);
      setCustomPopupSelectedData({});
      setPopupText('');
      setPopupLabel('');
    };

    return popupOpen && <Popup
      visible={popupOpen}
      onHiding={hidePopup}
      hideOnOutsideClick={true}
      height={'auto'}
      width={'auto'}
      title={customPopupContentRenderer ? undefined : !isNull(popupLabel) ? popupLabel : 'Comment'}
      contentRender={() =>
        customPopupContentRenderer ? customPopupContentRenderer(customPopupSelectedData) : <div className={classes.commentStyles}>
          {popupText}
        </div>
      } />;
  }

  const handleItemDetailClick = (cellProps: { data: { data: any } }) => {
    history.push({ search: getRouteValues(cellProps.data.data) });
    if (setSelectedLine) {
      setSelectedLine(cellProps.data.data);
    }
    changeSelectedTab(!isNull(formDoubleClickActionTab) ? formDoubleClickActionTab : gridOptions.doubleClickActionTab);
  };

  const getRouteValues = (cellData: any): string => {
    const params = new URLSearchParams(location.search);
    if (reqParams) {
      Object.keys(reqParams).map((key) => { if (key !== 'fetchApi') params.set(key, reqParams[key]); });
    }
    if (keyFields) {
      keyFields.map((key: { routeField: string; valueField: string | number }) => { params.set(key.routeField, cellData[key.valueField]); });
    }

    return params.toString();
  };

  return (<div className={classes.parentContainer} >

    {renderFilterRow()}
    {renderPopup()}
    {customFilterRowRenderer && customFilterRowRenderer(props)}

    <DataGrid
      {...restDataGridProps}
      ref={internalRef}
      dataSource={gridData as any}
      repaintChangesOnly={repaintChangesOnly}
      allowColumnReordering={allowColumnReordering}
      remoteOperations={remoteOperations}
      columnResizingMode={'widget'}
      allowColumnResizing={allowColumnResizing}
      showBorders={showBorders}
      style={{ ...style, height: style.height || '625px', width: style.width || '100%' }}
      hoverStateEnabled={hoverStateEnabled}
      onContextMenuPreparing={addMenuItemsCallBack}
      noDataText=''
    >
      <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
      {hasDrillDown &&
        <Column
          allowEditing={false}
          width={COLUMN_SIZE.sm0}
          fixed={true}
          cellComponent={(allProps) => {
            return <MoreIcon
              className={classes.iconStyle}
              onClick={() => { handleItemDetailClick(allProps); }}
            />;
          }}
        />
      }

      {showInfoIcon &&
        <Column
          allowEditing={false}
          width={COLUMN_SIZE.sm0}
          fixed={true}
          cellComponent={(allProps) => {
            return <InfoIcon
              className={classes.iconStyle}
              onClick={() => {
                if (customPopupContentRenderer) {
                  setCustomPopupSelectedData(allProps.data);
                } else {
                  const colData = !isNull(detailCellRendererParams) && detailCellRendererParams.getDetailRowData(allProps.data);
                  setPopupText(colData.commentData || '');
                  setPopupLabel(colData.label || '');
                }
                setPopupOpen(true);
              }} />;
          }}
        />}

      {hasMasterDetail && <MasterDetail
        enabled={true}
        component={masterDetailRenderer ? masterDetailRenderer : (detailProps) => <MasterDetailRowRenderer {...detailProps} detailColumnsParams={detailColumns} />}
      />}

      {!isNull(columnDefs) && columnDefs.map((col, i) => {

        return <Column
          key={`col${i}`}
          caption={col.headerName}
          dataField={col.field}
          allowEditing={false}
          minWidth={col.minWidth || col.width}
          type={col.type}
          fixed={false}
          alignment={isRightAligned(col) ? 'right' : 'left'}
          cellRender={col.valueGetter ? (cellData) => <div dangerouslySetInnerHTML={{ __html: col.valueGetter(cellData) }} /> : undefined}
          allowSorting={!col.suppressSorting}
          {...(CustomCelRenderers[col.cellRenderer] && !isCellRendererDisabled(col)) ? { cellRender: (data) => CustomCelRenderers[col.cellRenderer](data, col) } : {}}
        />;
      })}
      {gridOptions.enableMultiSelection && <Selection mode='multiple' allowSelectAll={gridOptions.allowSelectAll} />}
      <GroupPanel visible={false} />
      <SearchPanel visible={false} />
      <Scrolling mode={'infinite'} />
      <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
    </DataGrid>
    <ExportSpreadSheetModal open={openExportModal} fileMeta={fileMeta} setFileMeta={setFileMeta} onOk={exportToSpreadsheet} onCancel={handleExportCancel} />
  </div >);
};

export default withStyles(styles, { index: 1 })(withRouter(React.memo(DataGridDevEx)));
