import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';

export interface IDocumentsData {
  selectedFilters: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
}

export interface IDocumentsState extends IExtendedState<IDocumentsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
    }
  },
  'documents');

const initialState = asyncInitialState<IDocumentsData>({
  selectedFilters: {
    Keywords: '',
    Description: ''
  },
  filterRow: {
    formName: 'SupplierDocumentsFilters',
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'Keywords',
          name: 'Keywords',
          size: 'medium',
          required: false,
        }
      },
      {
        id: 1,
        type: 'TEXT_FIELD',
        props: {
          label: 'Description',
          name: 'Description',
          size: 'medium',
          required: false,
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Filename',
      // field: 'FileName',
      minWidth: 180,
      cellRenderer: 'FileDownload'
    },
    {
      headerName: 'Description',
      field: 'Description',
      minWidth: 180
    },
    {
      headerName: 'Keywords',
      field: 'Keywords',
      minWidth: 180
    }
  ]
});

export default (state: IDocumentsState = initialState, action: IDataAction): IDocumentsState => {
  switch (action.type) {
    default:
      return state;
  }
};

export const selectors = ({
  gridOptions: (state: { supplierProductEnquiry: { attachments: { documents: IDocumentsState } } }) => state.supplierProductEnquiry.attachments.documents.data.gridOptions,
  columns: (state: { supplierProductEnquiry: { attachments: { documents: IDocumentsState } } }) => state.supplierProductEnquiry.attachments.documents.data.Columns,
  Actions: (state: { supplierProductEnquiry: { attachments: { documents: IDocumentsState } } }) => state.supplierProductEnquiry.attachments.documents.data.Actions,
  filterRow: (state: { supplierProductEnquiry: { attachments: { documents: IDocumentsState } } }) => state.supplierProductEnquiry.attachments.documents.data.filterRow,
  selectedFilters: (state: { supplierProductEnquiry: { attachments: { documents: IDocumentsState } } }) => state.supplierProductEnquiry.attachments.documents.data.selectedFilters
});
