import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  SuccessAction,
  asyncOnError,
} from '../utils';

import { FormViewField } from 'components/FormView';
import { pathOr } from 'utils/utils';

export interface IInvoicesData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
  openBulkModal: boolean;
  openInvoiceAllocationModal: boolean;
  bulkInvoicesSelections: any;
  invoiceAllocation: any;
  invoices: any;
  SalesEntity: string;
}

export interface IInvoicesState extends IExtendedState<IInvoicesData> {
  applyBulkSelectionCriteria_loading?: boolean;
  getBulkSelectionCriteria_loading?: boolean;
  getSpecificInvoiceAllocation_loading?: boolean;
  applySpecificInvoiceAllocation_loading?: boolean;
  fetchAllocatedInvoices_loading?: boolean;
  createInvoiceAllocation_loading?: boolean;
  updateInvoiceAllocation_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    handleOnNewInvoice: (defaultView) => defaultView,
    changeModalVisibility: (data) => (data),
    asyncs: {
      getBulkSelectionCriteria: () => null,
      applyBulkSelectionCriteria: (data) => (data),
      getSpecificInvoiceAllocation: (data) => (data),
      applySpecificInvoiceAllocation: (data) => (data),
      fetchAllocatedInvoices: (data) => (data),
      deleteSpecificInvoiceAllocation: (data) => (data),
      createInvoiceAllocation: () => null,
      updateInvoiceAllocation: (data: any) => (data),
    }
  },
  'invoices');

const initialState = asyncInitialState<IInvoicesData>({
  selected: null,
  selectedFilters: {
    InvoiceNumber: '',
    SalesEntity: '',
    ShowAllOutstandingInvoices: false
  },
  filterRow: {
    formName: 'CashReceiptingInvoiceFilters',
    validate: null,
    parameters: [
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        visible: true,
        props: {
          label: 'Entity',
          size: 'small',
          name: 'SalesEntity',
          lookupName: 'SalesEntity',
          disabled: false,
          isEntityScoped: true,
          suppressDescription: true
        }
      },
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        visible: true,
        props: {
          label: 'Invoice',
          placeholder: '0',
          size: 'small',
          name: 'InvoiceNumber',
          lookupName: 'CashReceiptInvoice',
          disabled: false,
          isEntityScoped: true,
          suppressDescription: true,
          precedentFields: ['SalesEntity']
        }
      },
      {
        id: 2,
        type: 'TOGGLE_FIELD',
        props: {
          label: 'All outstanding invoices',
          name: 'ShowAllOutstandingInvoices',
          required: false,
          disabled: false,
          defaultValue: false
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    suppressEditDeleteInContextMenu: false,
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 180 },
    { headerName: 'Entity', field: 'SalesEntity', suppressSorting: true, type: 'numericColumn', minWidth: 180 },
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 120,
      type: 'numericColumn',
      sort: 'asc',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.InvoiceNumber) {
          return {
            DocumentNumber: row.data.InvoiceNumber,
            FieldValue: row.data.InvoiceNumber
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Orig total', field: 'OriginalTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Outstanding', field: 'OutstandingAmountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Receipted', field: 'AllocatedReceiptedAmountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Age', field: 'Age', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true, },
    { headerName: 'Local Orig', field: 'LocalCurrencyOriginalTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Local Amt', field: 'LocalCurrencyOutstandingAmountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true, },
  ],
  openBulkModal: false,
  openInvoiceAllocationModal: false,
  bulkInvoicesSelections: null,
  invoiceAllocation: null,
  invoices: null,
  SalesEntity: ''
});

export default (state: IInvoicesState = initialState, action: IDataAction): IInvoicesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };

    case types.handleOnNewInvoice:

      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.getBulkSelectionCriteria:
    case types.applyBulkSelectionCriteria:
    case types.getSpecificInvoiceAllocation:
    case types.applySpecificInvoiceAllocation:
    case types.fetchAllocatedInvoices:
    case types.deleteSpecificInvoiceAllocation:
    case types.createInvoiceAllocation:
    case types.updateInvoiceAllocation:
      return asyncOnRequest(state, action);

    case types.saga.getBulkSelectionCriteria.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInvoicesData, successAction: SuccessAction) => {
          return {
            ...data,
            bulkInvoicesSelections: pathOr({}, ['payload'], successAction),
          };
        });

    case types.saga.fetchAllocatedInvoices.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInvoicesData, successAction: SuccessAction) => {
          return {
            ...data,
            invoices: pathOr({}, ['payload', 'records'], successAction),
          };
        });

    case types.saga.getSpecificInvoiceAllocation.success:
    case types.saga.updateInvoiceAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInvoicesData, successAction: SuccessAction) => {
          return {
            ...data,
            invoiceAllocation: pathOr({}, ['payload'], successAction),
            SalesEntity: pathOr({}, ['payload', 'inlineObject', 'SalesEntity'], successAction),
          };
        });
    case types.saga.createInvoiceAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInvoicesData, successAction: SuccessAction) => {
          return {
            ...data,
            invoiceAllocation: pathOr({}, ['payload'], successAction),
          };
        });

    case types.saga.applyBulkSelectionCriteria.success:
    case types.saga.applySpecificInvoiceAllocation.success:
    case types.saga.deleteSpecificInvoiceAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInvoicesData) => {
          return data;
        });
    case types.saga.updateInvoiceAllocation.failure:
    case types.saga.createInvoiceAllocation.failure:
    case types.saga.getBulkSelectionCriteria.failure:
    case types.saga.applyBulkSelectionCriteria.failure:
    case types.saga.getSpecificInvoiceAllocation.failure:
    case types.saga.applySpecificInvoiceAllocation.failure:
    case types.saga.fetchAllocatedInvoices.failure:
    case types.saga.deleteSpecificInvoiceAllocation.failure:
      return asyncOnError(state, action);

    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.selected,
  invoices: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.invoices,
  invoicesLoading: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.fetchAllocatedInvoices_loading,
  SalesEntity: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.SalesEntity,
  bulkInvoicesSelections: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.bulkInvoicesSelections,
  openBulkModal: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.openBulkModal,
  invoiceAllocation: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.invoiceAllocation,
  invoiceAllocationLoading: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.getSpecificInvoiceAllocation_loading ||
    state.cashReceipting.invoices.applySpecificInvoiceAllocation_loading || state.cashReceipting.invoices.createInvoiceAllocation_loading || state.cashReceipting.invoices.updateInvoiceAllocation_loading,
  bulkModalLoading: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.getBulkSelectionCriteria_loading ||
    state.cashReceipting.invoices.applyBulkSelectionCriteria_loading,
  openInvoiceAllocationModal: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.openInvoiceAllocationModal,
  gridOptions: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.gridOptions,
  columns: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.Columns,
  Actions: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.Actions,
  filterRow: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.filterRow,
  selectedFilters: (state: { cashReceipting: { invoices: IInvoicesState } }) => state.cashReceipting.invoices.data.selectedFilters
});
