import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IGRNData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IGRNState extends IExtendedState<IGRNData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'grn');

const RequiredFields = [
  'EntityView', 'TransactionOption'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IGRNData>({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Warehouse: '',
    TransactionOption: 'Unfinalised',
    FromDate: '',
    ToDate: '',
    MatchOptions: 1
  },
  filterRow: {
    formName: 'SupplierGRNFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'TransactionOption',
          defaultValue: 'Unfinalised',
          required: false,
          options: [
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            }
          ]
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Match status',
          name: 'MatchOptions',
          defaultValue: 1,
          required: false,
          options: [
            {
              value: 1,
              label: 'Matched'
            },
            {
              value: 2,
              label: 'Unmatched'
            }
          ]
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'GRN',
      field: 'GRN',
      type: 'numericColumn',
      valueGetter: (row) => {
        if (row.data) {
          return row.data.GRNCode;
        }
      },
      minWidth: 120
    },
    { headerName: 'Invoice date', field: 'InvoiceDate', type: 'numericColumn', minWidth: 150, sort: 'desc' },
    { headerName: 'W/H', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Receipted', field: 'ReceiptedDisplay', minWidth: 150, suppressSorting: true, },
    {
      headerName: 'Invoice',
      field: 'Invoice',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice) {
          return {
            DocumentNumber: row.data.DocumentNumber,
            FieldValue: row.data.Invoice
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Invoice total', field: 'InvoiceTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true, },
  ]
});

export default (state: IGRNState = initialState, action: IDataAction): IGRNState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.selected,
  gridOptions: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.gridOptions,
  columns: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.Columns,
  Actions: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.Actions,
  filterRow: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.filterRow,
  selectedFilters: (state: { supplierEnquiry: { activity: { grn: IGRNState } } }) => state.supplierEnquiry.activity.grn.data.selectedFilters
});
