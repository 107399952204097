import { connect } from 'react-redux';
import DiaryDetails from './DiaryDetails';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { getFormValues, reset, isDirty, isValid, getFormSyncErrors, change } from 'redux-form';
import { Dispatch, bindActionCreators } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as staffSelectors } from 'ducks/staff';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';

const params = new URLSearchParams(location.search);
export const FORM_NAME = 'DiaryDetails';

const mapStateToProps = (state: IApplicationState) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    dirty: isDirty(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
    selectedForm: formSelectors.selected(state),
    operationMode: operationSelectors.operationMode(state),
    currentUserId: staffSelectors.id(state) ? staffSelectors.id(state) : '',
    isValid: isValid(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeOperationMode: operationActions.changeOperationMode,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeFieldValue: (field, value) => change(FORM_NAME, field, value),
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiaryDetails);
