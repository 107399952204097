import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearPaymentLines: () => null,
  asyncs: {
    remove: deleteObj => deleteObj,
    getPaymentSummary: something => something,
    getPaymentDetails: something => something,
    update: row => row,
    create: paymentLine => paymentLine
  }
}, 'payment');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'PaymentDetails',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  paymentLinesActions: {
    // topRowAction: {
    //   type: 'newButton',
    //   label: 'New payment line'
    // }
  },
  paymentLinesColumns: [
    { headerName: 'Payment', field: 'PaymentCode', suppressSorting: true, minWidth: 150 },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true, },
    { headerName: 'Banking entity', field: 'BankEntityCode', suppressSorting: true, },
    { headerName: 'Payment reference', field: 'PaymentReference', suppressSorting: true, },
    { headerName: 'Bank transaction', field: 'BankTransactionNumber', suppressSorting: true, }
  ],
  paymentLinesData: [],
  paymentSummary: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearPaymentLines:
      return {
        ...state,
        data: {
          ...state.data,
          paymentLinesData: []
        }
      }
    case types.remove:
      return asyncOnRequest(state, action)

    case types.saga.remove.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const row = action.payload;
        let lines = state.data.paymentLinesData;
        lines = lines.filter((line, ind) => {
          return !(row.Payment === line.Payment && row.LineNumber === line.LineNumber);
        });
        return {
          ...data,
          paymentLinesData: lines
        }
      })

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    case types.getPaymentDetails:
      return asyncOnRequest(state, action)

    case types.saga.getPaymentDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const paymentDetails = action.payload;
        return {
          ...data,
          paymentDetails: paymentDetails
        }
      })

    case types.getPaymentSummary:
      return asyncOnRequest(state, action)
    case types.saga.getPaymentSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const paymentSummary = action.payload;
        return {
          ...data,
          paymentSummary: paymentSummary
        }
      })
   
    case types.update:
      return asyncOnRequest(state, action)
    case types.saga.update.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const newLines = data.paymentLinesData.map((item, index) => {
          if (item.Payment === action.payload.Payment && item.LineNumber === action.payload.LineNumber) {
            return action.payload;
          }
          return item;
        })
        return {
          ...data,
          paymentLinesData: newLines
        }
      })
    case types.create:
      return asyncOnRequest(state, action)
    case types.saga.create.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          paymentLinesData: [...data.paymentLinesData, action.payload]
        }
      })
    case types.saga.remove.failure:
    case types.saga.update.failure:
    case types.saga.create.failure:
    case types.saga.getPaymentSummary.failure:
      return asyncOnError(state, action)

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.payment,
  {
    paymentActions: data => data.paymentLinesActions,
    paymentLinesData: data => data.paymentLinesData,
    paymentSummary: data => data.paymentSummary,
    paymentDetails: data => data.paymentDetails,
  }
)

const syncSelector = {
  selected: state => state.salesInvoiceEnquiry.payment.data.selected,
  isLoading: state => state.salesInvoiceEnquiry.payment.loading,
  loadingSummary: state => state.salesInvoiceEnquiry.payment.getPaymentSummary_loading,
  gridOptions: state => state.salesInvoiceEnquiry.payment.data.gridOptions,
  paymentLinesColumns: state => state.salesInvoiceEnquiry.payment.data.paymentLinesColumns,
  paymentLinesActions: state => state.salesInvoiceEnquiry.payment.data.paymentLinesActions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)