import React from 'react';

import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as configSelectors } from 'ducks/appConfig';
import BasicLookupActionField, { IBasicLookupActionFieldProps } from './BasicLookupActionField';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';

const mapStateToProps = (state: IApplicationState) => ({
    isV2: configSelectors.isV2(state),
    isV3: configSelectors.isV3(state),
});

const ConnectedComponent = connect(mapStateToProps, null)((props: Readonly<Omit<
    IBasicLookupActionFieldProps,
    'classes' | 'onSearch' | 'onFindOption'>>) => {
    return (<BasicLookupActionField {...props} />);
});

export default React.forwardRef(
    (props: Readonly<Omit<IBasicLookupActionFieldProps, 'isV2' | 'isV3' | 'classes' | 'onSearch' | 'onFindOption'>>, ref: React.Ref<IBasicLookupFieldHandle>) => {
        return <ConnectedComponent {...props} fieldRef={ref} />;
    });
