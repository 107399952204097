
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IChildAccountsData {
  selected: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface IChildAccountsState extends IExtendedState<IChildAccountsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'childAccounts');

const initialState = asyncInitialState<IChildAccountsData>({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Columns: [
    {
      headerName: 'Supplier',
      field: 'SupplierAccount',
      minWidth: 150,
      suppressSorting: true,
    },
    { headerName: 'Name', field: 'Name', minWidth: 180, suppressSorting: true, },
    { headerName: 'City', field: 'City', minWidth: 180, suppressSorting: true, },
    { headerName: 'State', field: 'State', minWidth: 180, suppressSorting: true, },
  ]
});

export default (state: IChildAccountsState = initialState, action: IDataAction): IChildAccountsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { financial: { childAccounts: IChildAccountsState } } }) => state.supplierEnquiry.financial.childAccounts.data.selected,
  gridOptions: (state: { supplierEnquiry: { financial: { childAccounts: IChildAccountsState } } }) => state.supplierEnquiry.financial.childAccounts.data.gridOptions,
  columns: (state: { supplierEnquiry: { financial: { childAccounts: IChildAccountsState } } }) => state.supplierEnquiry.financial.childAccounts.data.Columns,
});
