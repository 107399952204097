import React from 'react';
import DataGrid, { Column, Editing, Scrolling, RowDragging, GroupPanel, RequiredRule, SearchPanel, LoadPanel, Button, Lookup } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IChecklistQuestionsGridProps } from './ChecklistQuestionsGrid.properties';
import { IChecklistLineDetailFacade } from 'api/swaggerTypes';
import styles from './ChecklistQuestionsGrid.styles';
import { fetchChecklistLookupOptions } from 'api/checklistMaintenance/checklist';
import { ILookupOption } from 'api/customType';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';

const INDEX_COLUMN_WIDTH = 50;
const COMMAND_COLUMN_WIDTH = 75;
const NORMAL_COLUMN_WIDTH = 150;
const LARGE_COLUMN_MIN_WIDTH = 200;

const ChecklistQuestionsGrid = (props: Readonly<IChecklistQuestionsGridProps>) => {
    const {
        classes,
        disabled = false,
        checklistQuestionsData,
        dataGridRef,
        onUpdate,
        onDelete,
        onReorder,
        onInsert,
        changeConfirmationDialog,
        onDeleteRow,
    } = props;

    const { preventDataGridDefaults } = useGridKeyboardNavigation();
    const [dataTypesOptions, setDataTypesOptions] = React.useState<ILookupOption[]>([]);

    React.useEffect(
        () => {
            fetchChecklistLookupOptions()
                .then((response) => {
                    if (response && response.Data) {
                        const options = response.Data.map((option) => ({ ...option, Value: option.Code }));
                        setDataTypesOptions(options);
                    }
                });
        },
        []
    );

    function calculateSequenceValue(sequence: number): number {
        return sequence ?
            sequence : (checklistQuestionsData.length > 0) ?
                (Math.max(...checklistQuestionsData.map((q) => q.Sequence)) + 1) : 1;
    }

    const deleteClick = ({row}) => {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the selected record?',
            okLabel: 'Delete',
            onCancel: () => null,
            onOk: () => {
                onDeleteRow(row.data.Sequence);
            }
        });
    };

    return (<Paper className={classes.parentContainer} >
        <DataGrid
            disabled={disabled}
            keyExpr={'Sequence'}
            ref={dataGridRef}
            className={classes.checklistQuestionDataGrid}
            onKeyDown={preventDataGridDefaults}
            dataSource={checklistQuestionsData}
            repaintChangesOnly={true}
            remoteOperations={true}
            allowColumnReordering={true}
            columnResizingMode={'nextColumn'}
            noDataText=''
            allowColumnResizing={true}
            onRowUpdated={() => {
                if (onUpdate) {
                    onUpdate(checklistQuestionsData);
                }
            }
            }
            onRowInserted={() => {
                if (onInsert) {
                    onInsert(checklistQuestionsData);
                }
            }}
            onRowRemoved={() => {
                if (onDelete) {
                    onDelete(checklistQuestionsData);
                }
            }}
            showBorders={false}
            sorting={{
                mode: 'none'
            }}
            hoverStateEnabled={true}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={true}
                confirmDelete={true}
            />
            <RowDragging
                allowReordering={true}
                onReorder={(e) => {
                    const visibleRows = e.component.getVisibleRows();
                    const newQuestion = [...checklistQuestionsData];
                    const toIndex = newQuestion.indexOf(visibleRows[e.toIndex].data);
                    const fromIndex = newQuestion.indexOf(e.itemData);

                    newQuestion.splice(fromIndex, 1);
                    newQuestion.splice(toIndex, 0, e.itemData);

                    if (onReorder) {
                        onReorder(newQuestion);
                    }
                }}
                showDragIcons={true}
            />
            <Scrolling mode='virtual' />
            <Column
                caption={'#'}
                dataField={'Sequence'}
                allowEditing={false}
                minWidth={INDEX_COLUMN_WIDTH}
                fixed={true}
            />
            <Column
                dataField={'Question'}
                caption={'Question'}
                editorOptions={{
                    maxLength: 100,
                }}
                minWidth={LARGE_COLUMN_MIN_WIDTH}
                setCellValue={async (newData: IChecklistLineDetailFacade, value: string, prevData: IChecklistLineDetailFacade): Promise<void> => {
                    newData.Question = value;
                    newData.DataType = prevData.DataType;
                    newData.HyperlinkReference = prevData.HyperlinkReference;
                    newData.Validation = prevData.Validation;
                    newData.Sequence = calculateSequenceValue(prevData.Sequence);
                }}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField='DataType'
                caption='Type'
                minWidth={NORMAL_COLUMN_WIDTH}
            >
                <RequiredRule />
                <Lookup dataSource={dataTypesOptions} displayExpr='Display' valueExpr='Value' />
            </Column>
            <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
                <Button name={'save'} icon={'save'} />
                <Button name={'edit'} icon={'edit'} />
                <Button name={'delete'} icon={'trash'} onClick={deleteClick} />
                <Button name={'cancel'} icon={'undo'} />
                <Button name={'undelete'} icon={'undo'} />
            </Column>
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <LoadPanel enabled={false} />
        </DataGrid>
    </Paper>
    );
};

export default withStyles(styles, { index: 1 })(ChecklistQuestionsGrid);
