import { combineReducers } from 'redux';
import settingsByWarehouse, { ISettingsByWarehouseState } from './byWareHouse';
import financial, { IFinancialSettingsState } from './financial';
import general, { IGeneralSettingsState } from './general';
import attributes, { ISettingsAttributesState } from './attributes';

export interface ISupplierSettingsState {
  settingsByWarehouse: ISettingsByWarehouseState;
  financial: IFinancialSettingsState;
  general: IGeneralSettingsState;
  attributes: ISettingsAttributesState;
}

const supplierSettingsReducer = combineReducers<ISupplierSettingsState>({
  settingsByWarehouse,
  financial,
  general,
  attributes
});

export default supplierSettingsReducer;
