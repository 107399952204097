import ActivityForm from '../data/forms/activity_maintenance/ActivityForm';
import CapabilitiesForm from '../data/forms/activity_maintenance/CapabilitiesForm';

const getFormSchema = (formId) => {
    switch (formId) {
        case 'ActivityDetails':
            return ActivityForm;
        case 'Capabilities':
            return CapabilitiesForm;
        default:
            return ActivityForm;
    }
};

export default getFormSchema;
