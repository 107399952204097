import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from './../utils';
import { IInvoiceLinesSummaryResponse, IInvoiceLinesDetailsResponse, IInvoiceLinesSearchResponse } from './interfaces';

export async function search(query: { DocumentNumber: number; Page?: number; Sort?: string; SortDirection?: string; BatchSize?: number }): Promise<IInvoiceLinesSearchResponse> {
  const { DocumentNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/Lines/Invoices/${DocumentNumber}`, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize })
    .then((result) => result && result.SupplierInvoiceEnquiryLines &&
      ({
        records: result.SupplierInvoiceEnquiryLines.map((item) => objectify(item, null, 'Inline')),
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      })
    );
}
export async function getInvoiceLineSummary(query: { DocumentNumber: number; LineNumber: number }): Promise<IInvoiceLinesSummaryResponse> {
  const { DocumentNumber, LineNumber } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/LineSummary/Invoices/${DocumentNumber}/Line/${LineNumber}`)
    .then((result) => (result && {
      schema: result.SupplierInvoiceEnquiryLineSummary, values: objectify(result.SupplierInvoiceEnquiryLineSummary)
    }));
}

export async function getInvoiceLineDetails(query: { DocumentNumber: number; LineNumber: number }): Promise<IInvoiceLinesDetailsResponse> {
  const { DocumentNumber, LineNumber } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/LineDetails/Invoices/${DocumentNumber}/Line/${LineNumber}`, null, 'Inline')
    .then((result) => (result && {
      schema: result.SupplierInvoiceEnquiryLineDetail, values: objectify(result.SupplierInvoiceEnquiryLineDetail)
    }));
}
