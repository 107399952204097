import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as bomTypes, actions as bomActions } from 'ducks/pickSlipEnquiry/bom';

import { callApi } from '../utils';
import * as api from 'api/pickSlipEnquiry/bom';

function* fetchDetails(action) {
  const { success, failure } = bomActions.saga.fetchDetails;

  yield callApi(
    call(api.fetchDetails, action.data),
    success,
    failure
  )
}

function* fetchSummary(action) {
  const { success, failure } = bomActions.saga.fetchSummary;

  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(bomTypes.fetchDetails, fetchDetails)
  yield takeLatest(bomTypes.fetchSummary, fetchSummary)
}