import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/index';
import InvoiceSearchLookUpDrawer from 'components/common/SearchLookUpDrawer/Containers/Invoice';
import SummaryPanel from './SummaryPanel';
import { shallowCompare, findParentTab, isTabChild } from 'utils/utils';
import { withRouter } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import styles from './SalesInvoiceEnquiry.scss';
import options from './OptionItems.json';
import { OPERATIONS } from 'utils/operations';
import SummaryTable from 'components/common/SummaryTable';
import * as SummaryTableSchema from './SummaryTableSchema.json';
import { Route } from 'react-router';
import InvoiceComponent from './InvoiceComponent/index';
import InvoiceLines from './InvoiceLines/index';
import InvoiceLineBOM from './InvoiceLineBOM/index';
import InvoicePayments from './InvoicePayments/index';
import InvoiceSlips from './InvoiceSlips/index';
import InvoiceSalesAudit from './InvoiceSalesAudit/index';
import Events from './Events/index';
import Diary from './Diary/index';
import NotePad from './NotePad/index';
import { ISalesInvoiceEnquiryProps } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  salesInvoiceEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class SalesInvoiceEnquiryView extends React.Component<ISalesInvoiceEnquiryProps> {

  handleModuleChange = (selectedTab: any) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'sales-invoice-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'sales-invoice-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = params.get('SalesEntity') || '';
    const lineNumber = params.get('LineNumber') || '';
    const invoiceNumber = params.get('InvoiceNumber') || '';
    const AuditRegId = params.get('AuditRegId') || '';
    const AuditDataId = params.get('AuditDataId') || '';
    const DiaryId = params.get('DiaryId') || '';
    const BomLineNumber = params.get('BomLineNumber') || '';
    const PaymentCode = params.get('PaymentCode') || '';

    return ({
      InvoiceDetails: { pathname: `/${context}/invoice-details`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceComponent },
      Comments: { pathname: `/${context}/comments`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceComponent },
      InternalComments: { pathname: `/${context}/internal-comments`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceComponent },
      InvoiceLines: { pathname: `/${context}/invoice-lines`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceLines },
      InvoiceLineDetails: { pathname: `/${context}/invoice-lines/line-details`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      InvoiceLineSerials: { pathname: `/${context}/invoice-lines/line-details/serials`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      InvoiceLineLots: { pathname: `/${context}/invoice-lines/line-details/lots`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      InvoiceLineBOM: { pathname: `/${context}/invoice-lines/line-details/bill-of-material`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}`, component: InvoiceLineBOM },
      InvoiceLineBOMComponent: { pathname: `/${context}/invoice-lines/line-details/bill-of-material/component-details`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}&BomLineNumber=${BomLineNumber}`, component: InvoiceLineBOM },
      InvoiceLineBOMComponentSerials: { pathname: `/${context}/invoice-lines/line-details/bill-of-material/component-details/serials`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}&BomLineNumber=${BomLineNumber}`, component: InvoiceLineBOM },
      InvoiceLineBOMComponentLots: { pathname: `/${context}/invoice-lines/line-details/bill-of-material/component-details/lots`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&LineNumber=${lineNumber}&BomLineNumber=${BomLineNumber}`, component: InvoiceLineBOM },
      Payment: { pathname: `/${context}/payments`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoicePayments },
      PaymentDetails: { pathname: `/${context}/payments/payment-details`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&PaymentCode=${PaymentCode}`, component: InvoicePayments },
      PickSlipsByDespatch: { pathname: `/${context}/pick-slips-by-despatch`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceSlips },
      PickSlipsByProduct: { pathname: `/${context}/pick-slips-by-product`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceSlips },
      SalesAudit: { pathname: `/${context}/sales-audit`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: InvoiceSalesAudit },
      SalesAuditDetails: { pathname: `/${context}/sales-audit/audit-details`, search: `?SalesEntity=${salesEntity}&AuditRegId=${AuditRegId}&AuditDataId=${AuditDataId}&InvoiceNumber=${invoiceNumber}`, component: InvoiceSalesAudit },
      Events: { pathname: `/${context}/events`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: Events },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}&DiaryId=${DiaryId}`, component: Diary },
      Notepad: { pathname: `/${context}/notepad`, search: `?SalesEntity=${salesEntity}&InvoiceNumber=${invoiceNumber}`, component: NotePad },
      null: { pathname: `/${context}/`, search: null, component: InvoiceComponent, },
    });
  }

  handleInvoiceChange = () => {
    const { selectedTab } = this.props;
    this.getInvoiceSummary();
    this.fetchChildRecords(selectedTab, isTabChild(MODULE_TREE, selectedTab));
  }

  fetchChildRecords = (selectedTab: any, _movedToParent: boolean = false) => {
    if (_movedToParent) return;

    switch (selectedTab) {
      case 'InvoiceDetails':
        this.getInvoiceDetails();
        break;
      case 'Comments':
        this.getInvoiceDetails();
        break;
      case 'InternalComments':
        this.getInvoiceDetails();
        break;

      case 'InvoiceLineDetails':
        this.getInvoiceLineSummary();
        break;
      case 'InvoiceLineBOM':
        this.getInvoiceLineBOM();
        break;
      case 'InvoiceLineBOMComponent':
        this.getInvoiceLineBOMDetails();
        break;
      case 'InvoiceLineSerials':
      case 'InvoiceLineBOMComponentSerials':
        this.getInvoiceLineSerials(selectedTab);
        break;
      case 'InvoiceLineLots':
      case 'InvoiceLineBOMComponentLots':
        this.getInvoiceLineLots(selectedTab);
        break;
      case 'SalesAuditDetails':
        this.getAuditDetailsSummary();
        break;
      case 'Payment':
        this.getPaymentSummary();
        break;
      case 'PaymentDetails':
        this.getPaymentDetails();
        break;
      default:
    }
  }

  moveBackToParentTab = () => {
    const { selectedTab }: any = this.props;

    if (isTabChild(MODULE_TREE, selectedTab)) {
      const parentTab = findParentTab(MODULE_TREE, selectedTab);
      this.handleModuleChange(parentTab);
    }

  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(OPERATIONS.BROWSE);
  }

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: any): void {
    const { operationMode, invoiceSummary, selectedInvoice, selectedTab } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('sales-invoice-enquiry', selectedTab);
    }
    if (selectedInvoice && !invoiceSummary) {
      this.getInvoiceSummary();
    }
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'InvoiceDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('InvoiceDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getDiary = () => {
    this.props.getDiary();
  }

  getInvoiceLineBOMDetails(): void {
    const { selectedInvoice } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    const bomLineNumber = this.props.selectedBOM ? this.props.selectedBOM.LineNumber : params.get('BomLineNumber');
    const lineNumber = this.props.selectedBOM ? this.props.selectedBOM.LineNumber : params.get('LineNumber');
    if (lineNumber && bomLineNumber && invoiceNumber && salesEntity) {
      this.props.getInvoiceLineBOMDetails({ Invoice: invoiceNumber, SalesEntity: salesEntity, LineNumber: bomLineNumber });
    }
  }

  getInvoiceSummary = () => {
    const { selectedInvoice } = this.props;
    if (selectedInvoice) {
      const { Invoice, SalesEntity } = selectedInvoice;
      this.props.getInvoiceSummary(Invoice, SalesEntity);
    }
  }

  getInvoiceDetails = () => {
    const { selectedInvoice } = this.props;
    if (selectedInvoice) {
      const { Invoice, SalesEntity } = selectedInvoice;
      this.props.getInvoiceDetails(Invoice, SalesEntity);
    }
  }

  getInvoiceLineSummary = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const lineNumber = selectedInvoiceLine ? selectedInvoiceLine.LineNumber : params.get('LineNumber');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    if (lineNumber && invoiceNumber && salesEntity) {
      this.props.getInvoiceLineSummary(invoiceNumber, salesEntity, lineNumber);
      this.props.getInvoiceLineDetails(invoiceNumber, salesEntity, lineNumber);
    }
  }

  getInvoiceLineBOM = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const lineNumber = selectedInvoiceLine ? selectedInvoiceLine.LineNumber : params.get('LineNumber');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    if (invoiceNumber && salesEntity && lineNumber) {
      this.props.getInvoiceLineSummary(invoiceNumber, salesEntity, lineNumber);
    }
  }

  getInvoiceLineSerials = (selectedTab?: string) => {
    const { selectedInvoice, selectedInvoiceLine, selectedBOM } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const lineNumber = selectedInvoiceLine ? selectedInvoiceLine.LineNumber : params.get('LineNumber');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    const bomLineNumber = (selectedBOM && selectedBOM.LineNumber) || params.get('BomLineNumber');
    if (salesEntity && lineNumber && invoiceNumber) {
      if (selectedTab === 'InvoiceLineSerials') {
        this.props.getInvoiceLineSummary(invoiceNumber, salesEntity, lineNumber);
      } else if (selectedTab === 'InvoiceLineBOMComponentSerials' && bomLineNumber) {
        this.getInvoiceLineBOMDetails();
        this.props.getInvoiceLineSerialsByBom({ Invoice: invoiceNumber, SalesEntity: salesEntity, LineNumber: bomLineNumber });
      }
    }
  }

  getInvoiceLineLots = (selectedTab?: string) => {
    const { selectedInvoice, selectedInvoiceLine, selectedBOM } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const lineNumber = selectedInvoiceLine ? selectedInvoiceLine.LineNumber : params.get('LineNumber');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    const bomLineNumber = (selectedBOM && selectedBOM.LineNumber) || params.get('BomLineNumber');
    if (salesEntity && lineNumber && invoiceNumber) {
      if (selectedTab === 'InvoiceLineLots') {
        this.props.getInvoiceLineSummary(invoiceNumber, salesEntity, lineNumber);
      } else if (selectedTab === 'InvoiceLineBOMComponentLots' && bomLineNumber) {
        this.getInvoiceLineBOMDetails();
        this.props.getInvoiceLineLotsByBom({ Invoice: invoiceNumber, SalesEntity: salesEntity, LineNumber: bomLineNumber });
      }
    }
  }

  getAuditDetailsSummary = () => {
    const { selectedInvoice, selectedAudit } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    const AuditRegId = selectedAudit ? (selectedAudit.SalesauditregId) : params.get('AuditRegId');
    if (salesEntity && invoiceNumber && AuditRegId) {
      this.props.getAuditDetailsSummary({ AuditRegId: AuditRegId });
    }
  }

  getPaymentSummary = () => {
    const { selectedInvoice } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    if (invoiceNumber && salesEntity) {
      this.props.getPaymentSummary({ Invoice: invoiceNumber, SalesEntity: salesEntity });
    }
  }

  getPaymentDetails = () => {
    this.getPaymentSummary();
    const { selectedInvoice, selectedPaymentLine } = this.props;
    const { Invoice, SalesEntity } = selectedInvoice || { Invoice: null, SalesEntity: null };
    const params = (new URLSearchParams(this.props.location.search));
    const salesEntity = SalesEntity || params.get('SalesEntity');
    const invoiceNumber = Invoice || params.get('InvoiceNumber');
    const PaymentCode = (selectedPaymentLine && selectedPaymentLine.PaymentCode) || params.get('PaymentCode');
    if (invoiceNumber && salesEntity && PaymentCode) {
      this.props.getPaymentDetails({ Invoice: invoiceNumber, SalesEntity: salesEntity, PaymentCode: PaymentCode });
    }
  }

  invoiceLineSummary = () => {
    const { invoiceLineSummary, loadingInvoiceLineSummary } = this.props;

    return <SummaryTable loadingInvoiceLineSummary={loadingInvoiceLineSummary}
      selectedInvoiceLine={invoiceLineSummary && invoiceLineSummary.values}
      data={
        SummaryTableSchema.InvoiceLineDetails
      }
    />;
  }

  salesAuditDetailsSummary = () => {
    const { auditDetailsSummary, loadingAuditSummary } = this.props;

    return <SummaryTable
      selectedAudit={auditDetailsSummary}
      loadingAuditSummary={loadingAuditSummary}
      data={
        SummaryTableSchema.SalesAuditDetails
      }
    />;
  }

  componentDetailsSummary = () => {
    const { bomDetails, loadingBomComponentDetails } = this.props;

    return <SummaryTable
      selectedComponent={bomDetails}
      loadingBomComponentDetails={loadingBomComponentDetails}
      data={
        SummaryTableSchema.InvoiceLineBOMComponent
      }
    />;
  }

  paymentSummary = () => {
    const loading = this.props.loadingPaymentSummary;

    return <SummaryTable
      selectedPayment={this.props.paymentSummary}
      data={SummaryTableSchema.Payment}
      loadingSummary={loading}
    />;
  }

  getSummaryTableRenderer = (selectedTab: any) => {
    switch (selectedTab) {
      case 'InvoiceLineDetails':
      case 'InvoiceLineSerials':
      case 'InvoiceLineLots':
        return this.invoiceLineSummary;
      case 'InvoiceLineBOMComponent':
      case 'InvoiceLineBOMComponentSerials':
      case 'InvoiceLineBOMComponentLots':
        return this.componentDetailsSummary;
      case 'SalesAuditDetails':
        return this.salesAuditDetailsSummary;
      case 'Payment':
      case 'PaymentDetails':
        return this.paymentSummary;
      default: return null;
    }
  }

  getValuesSchema = () => {
    const { selectedTab, invoiceDetails } = this.props;
    switch (selectedTab) {
      case 'InvoiceDetails':
        return invoiceDetails && invoiceDetails.schema;
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const schema = this.getValuesSchema();
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <InvoiceSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.moveBackToParentTab,
              this.handleInvoiceChange
            ]}
          />
          <div
            style={inlineStyles.salesInvoiceEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Sales Invoice Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} valuesSchema={schema} summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)} />;
                }}
              />
            ))}

          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={(selectedTab === 'InvoiceLineDetails'
            || selectedTab === 'InvoiceLineSerials'
            || selectedTab === 'InvoiceLineLots'
            || selectedTab === 'InvoiceLineBOM')
            ?
            options.InvoiceLineDetails
            :
            (selectedTab === 'InvoiceLineBOMComponent'
              || selectedTab === 'InvoiceLineBOMComponentSerials'
              || selectedTab === 'InvoiceLineBOMComponentLots'
            )
              ?
              options.InvoiceLineBOMComponent
              :
              options.InvoiceDetails}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'sales-invoice-enquiry'} />
      </div>
    );
  }
}

export default withRouter(SalesInvoiceEnquiryView);
