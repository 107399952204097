import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { PaperElevation } from 'utils/constants';
import { IBreaksTableProps } from './interfaces';

const styles = createStyles({
  table: {
    width: '100%',
    border: '1px white',
    marginBottom: '15px'
  },
  lastTable: {
    width: '100%',
    border: '1px white',
  },
  tableRow: {
    height: 'inherit',
  },
  propertyCell: {
    width: '175px',
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    padding: '10px',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  valueCell: {
    width: 'calc(100% - 75px)',
    backgroundColor: '#dddddd',
    border: '1px solid #fff',
    padding: '10px',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  lastValueCell: {
    width: 'calc(100% - 75px)',
    backgroundColor: '#bbbbbb',
    border: '1px solid #fff',
    padding: '10px',
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'bold',
  },
  numericOuter: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

class BreaksTable extends React.PureComponent<IBreaksTableProps> {

  render(): React.ReactNode {
    const { classes, value, schema } = this.props;

    return (
      <Paper elevation={PaperElevation}>
        {
          schema && schema.map((item, index) => {
            return (
              <div key={`subPanelWrapper${index}`}>
                {

                  <div key={`tableWrapper${index}`}>
                    <Table
                      key={`table${index}`} className={schema.indexOf(item) === schema.length - 1
                        ? classes.lastTable
                        : classes.table}
                    >
                      <TableBody>
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.lastValueCell} >{item[0].name}</TableCell>
                          {
                            item[0].columns ?
                              item[0].columns.map((col: string) => {
                                return <TableCell className={classes.propertyCell} numeric={true}>{col}</TableCell>;
                              })
                              :
                              (<>
                                <TableCell className={classes.propertyCell} numeric={true}>BREAK QUANTITY</TableCell>
                                <TableCell className={classes.propertyCell} numeric={true}>PRICE</TableCell>
                                <TableCell className={classes.propertyCell} numeric={true}>DISCOUNT</TableCell>
                                <TableCell className={classes.propertyCell} numeric={true}>RESULT</TableCell>
                                <TableCell className={classes.propertyCell} numeric={true}>GP</TableCell>
                              </>)

                          }
                        </TableRow>

                        {item[0].data.map((property, i: number) => (
                          <TableRow key={`property${index}${i}`} className={classes.tableRow}>
                            <TableCell key={`label${index}${i}`} className={classes.propertyCell}>Level {i + 1}</TableCell>
                            {
                              property.children.map((levelRow, j) => (
                                <TableCell
                                  key={`valueCell${index}${j}`}
                                  numeric={true}
                                  className={classes.valueCell}
                                  style={{ padding: '10px' }}
                                >
                                  <div className={classes.numericOuter}>
                                    <span key={`field${index}$${j}`}>
                                      {
                                        value
                                        && levelRow.fieldNames.map((key) => value[key])
                                      }
                                    </span>
                                  </div>
                                </TableCell>
                              ))
                            }
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                }
              </div>
            );
          })
        }
      </Paper>
    );
  }
}

export default withStyles(styles, {index: 1})(BreaksTable);
