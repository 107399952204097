import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null,
  setEntityView: (defaultView) => defaultView,
  asyncs: {
    fetch: (Data) => Data,
  }
}, 'salesOrders');


const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Entity: '',
    FinalizationOption: 'Unfinalised',
    FromDate: '',
    ToDate: '',
    CustomerPO: ''
  },
  filterRow: {
    formName: 'CustomerSalesOrderFilters',
    validate: (values) => {
      let errors = {};
      RequiredFields.forEach((item, index) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      })
      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false,
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'FinalizationOption',
          required: false,
          options: [
            {
              label: 'Unfinalised only ',
              value: 'Unfinalised'
            },
            {
              label: 'Finalised only',
              value: 'Finalised'
            }
          ]
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Order placed from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          size: 'small',
          nullable: 'true',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Customer P/O',
          name: 'CustomerPO',
          size: 'medium',
          required: false,
        }
      },
    ]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Entity', field: 'Entity', minWidth: 100, suppressSorting: true },
    {
      headerName: 'Sales order',
      field: 'SalesOrderNumber',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.SalesOrderNumber) {
          return {
            SalesOrder: row.data.SalesOrderNumber,
            FieldValue: row.data.SalesOrderNumber
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-order-enquiry/order-details'
      }
    },
    {
      headerName: 'Placed', field: 'PlacedDate', type: 'numericColumn', minWidth: 120,
      valueGetter: (row) => {
        if (row.data && row.data.PlacedDate) {
          return row.data.PlacedDate;
        } return null;
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 150, suppressSorting: true },
    { headerName: 'Order total', field: 'OrderTotalDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true },
    { headerName: 'Outstanding total', field: 'OutstandingTotalDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 120, suppressSorting: true },
    { headerName: 'Deposit received', field: 'DepositReceivedDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true },
    { headerName: 'Earliest ship', field: 'EarliestShipDate', minWidth: 150, type: 'numericColumn', suppressSorting: true },
    { headerName: 'Earliest expiry', field: 'EarliestExpiryDate', minWidth: 150, type: 'numericColumn', suppressSorting: true },
    { headerName: 'Local total', field: 'LocalTotalDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setEntityView:
      const entityView = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          Data: linesData
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action)

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.salesOrders,
  {
    // Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  Data: state => state.customerEnquiry.salesOrders.data.Data,
  isLoading: state => state.customerEnquiry.salesOrders.loading,
  gridOptions: state => state.customerEnquiry.salesOrders.data.gridOptions,
  Columns: state => state.customerEnquiry.salesOrders.data.Columns,
  Actions: state => state.customerEnquiry.salesOrders.data.Actions,
  filterRow: state => state.customerEnquiry.salesOrders.data.filterRow,
  selectedFilters: state => state.customerEnquiry.salesOrders.data.selectedFilters
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)