import * as React from 'react';
import { CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import { Operation } from 'utils/operations';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { LookUpActionField as ExtendedLookUpActionField } from 'components/common/ExtendedLookUpActionField/LookUpActionField';
import DateTextField from '@markinson/uicomponents-v2/DateTextField';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import { CircularProgressSize, SnackBarDisplayTimeMilliseconds } from '../../../utils/constants';
import { withRouter, RouteComponentProps } from 'react-router';
import { IConfirmationDialogProperties } from '../ConfirmationDialog/ConfirmationDialog';
import TimeTextField from '@markinson/uicomponents-v2/TimeField';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import moment from 'moment';
import { showSnackBar } from '../SnackBars/SnackBar.hooks';

const required = (value) => {
  return value || value !== '' ? undefined : 'Required';
};

const inlineStyles = {
  preLoader: {
    margin: '320px calc(50% - 16px)'
  }
};

const Styles = createStyles({
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {

  },
  rowWrapper: {
    width: '100%',
    height: '90px',
  },
  commentRowWrapper: {
    width: '100%',
    height: '470px',
  },
  left: {
    marginRight: '15px',
    float: 'left'
  },
  right: {
    width: 'auto',
    float: 'right',
    paddingTop: '28px'
  }
});

interface IDiaryEntry {
  UserId?: string;
  Subject?: string;
  Date?: string;
  Time?: string;
  CommentFull?: string;
  DiaryId?: number;
  SystemGenerated?: boolean;
}

interface IDiaryDetailsState {
  values: IDiaryEntry;
  dirty?: boolean;
}

interface IDiaryDetailsProps extends StyledComponentProps, RouteComponentProps {
  deleteApi: string;
  createApi: string;
  updateApi: string;
  searchByIdApi: string;
  isLoading?: boolean;
  operationMode: Operation;
  notifications: any;
  parentTab: string;
  errors: any;
  diary?: IDiaryEntry;
  formValues: any;
  currentUserId: string;
  changeConfirmationDialog(options: IConfirmationDialogProperties): void;
  changeOperationMode(operationMode: Operation): void;
  changeSelectedTab(selectedTab: string): void;
  createEntry(params: { createApi: string; diaryEntry: IDiaryEntry }): void;
  updateEntry(params: { updateApi: string; diaryEntry: IDiaryEntry }): void;
  deleteEntry(params: { deleteApi: string }): void;
  searchById(params: { searchByIdApi: string }): void;
}

class DiaryDetails extends React.Component<IDiaryDetailsProps, IDiaryDetailsState> {
  constructor(props: IDiaryDetailsProps) {
    super(props);
    this.state = {
      values: {
        UserId: '',
        Subject: '',
        Date: moment().format('DD/MM/YYYY'),
        Time: moment().format('hh:mm a'),
        CommentFull: '',
        DiaryId: null,
        SystemGenerated: false
      },
      dirty: false
    };
  }
  componentDidMount(): void {
    const { operationMode, searchByIdApi, formValues, currentUserId, changeOperationMode } = this.props;
    const params = new URLSearchParams(this.props.location.search);
    const diaryId = params.get('DiaryId');

    if (operationMode === Operation.NEW) {
      this.setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          UserId: currentUserId
        }
      }));
    }

    if (operationMode !== Operation.NEW && operationMode !== Operation.DELETE) {
      if (diaryId && diaryId !== 'null') {
        this.props.searchById({ searchByIdApi: this.getApi(searchByIdApi, { ...formValues ? formValues.params : {}, DiaryId: diaryId }) });
        changeOperationMode(Operation.BROWSE);
      } else {
        changeOperationMode(Operation.NEW);
      }
    }
  }

  componentDidUpdate(prevProps: IDiaryDetailsProps): void {
    const { operationMode, changeSelectedTab, notifications, errors, diary, isLoading, formValues = {}, parentTab, searchByIdApi, currentUserId, searchById } = this.props;

    if (JSON.stringify(formValues.params) !== JSON.stringify(prevProps.formValues.params)) {
      changeSelectedTab(parentTab);
    }
    const params = new URLSearchParams(this.props.location.search);
    const prevParams = new URLSearchParams(prevProps.location.search);
    const diaryId = params.get('DiaryId');
    const prevDiaryId = prevParams.get('DiaryId');
    if (diaryId !== prevDiaryId && operationMode !== Operation.NEW) {
      searchById({ searchByIdApi: this.getApi(searchByIdApi, { ...formValues.params, DiaryId: diary.DiaryId }) });
    }
    if (prevProps.operationMode === Operation.BROWSE && operationMode === Operation.NEW) {

      this.setState({
        values: {
          UserId: currentUserId,
          Subject: '',
          Date: moment().format('DD/MM/YYYY'),
          Time: moment().format('hh:mm A'),
          CommentFull: '',
          DiaryId: null,
          SystemGenerated: false
        },
        dirty: false
      });
      params.set('DiaryId', '');
      this.props.history.push({ search: params.toString() });
    }

    if (prevProps.operationMode === Operation.NEW && operationMode === Operation.SAVE) {
      this.createNewRecord();
    }
    if (prevProps.operationMode === Operation.EDIT && operationMode === Operation.SAVE) {
      this.updateNewRecord();
    }
    if (prevProps.operationMode === Operation.BROWSE && operationMode === Operation.DELETE) {
      this.deleteAction();
    }

    if (operationMode === Operation.CANCEL) {
      this.cancelFormSubmission(prevProps.operationMode);
    }
    if (notifications !== prevProps.notifications) {
      this.checkNotifications(notifications);
    }
    if (errors && errors.Status === 'ValidationError' && prevProps.isLoading && !isLoading) {
      this.checkValidationErrors(errors);
    }
    if (diary !== prevProps.diary && operationMode !== Operation.EDIT) {
      this.setState({
        values: {
          UserId: diary.UserId,
          Subject: diary.Subject,
          Date: diary.Date,
          Time: diary.Time,
          CommentFull: diary.CommentFull,
          DiaryId: diary.DiaryId,
          SystemGenerated: diary.SystemGenerated
        },
        dirty: false
      });
      params.set('DiaryId', diary.DiaryId.toString());
      this.props.history.push({ search: params.toString() });
    }
  }

  checkValidationErrors = (error: any): void => {
    const { ValidationErrors } = error;
    ValidationErrors.forEach((item) => {
      showSnackBar({ variant: 'warning', message: `Validation Error = ${item.Property} : ${item.Message}`, time: SnackBarDisplayTimeMilliseconds });
    });
  }

  checkNotifications = (notifications: any): void => {
    if (notifications.update) {
      showSnackBar({ variant: 'success', message: 'Diary Successfully Updated', time: SnackBarDisplayTimeMilliseconds });
    }
    if (notifications.create) {
      showSnackBar({ variant: 'success', message: 'Diary Successfully Added', time: SnackBarDisplayTimeMilliseconds });
    }
  }

  deleteAction = (): void => {
    this.props.changeConfirmationDialog({
      open: true,
      title: 'Delete selected record',
      message: 'Are you sure you want to delete the selected record?',
      okLabel: 'Delete',
      onCancel: () => {
        this.props.changeOperationMode(Operation.BROWSE);
      },
      onOk: () => {
        this.removeSelectedRecord();
      }
    });
  }

  removeSelectedRecord = (): void => {
    const { deleteApi, formValues, parentTab } = this.props;
    const { values } = this.state;
    this.props.deleteEntry({ deleteApi: this.getApi(deleteApi, { ...formValues ? formValues.params : {}, DiaryId: values.DiaryId }) });
    this.props.changeSelectedTab(parentTab);
  }

  validate = (): boolean => {
    const { values: { Subject, Date, Time, UserId } } = this.state;

    return (Subject && Date && Time && UserId) ? true : false;
  }

  createNewRecord = (): void => {
    const { formValues, createApi } = this.props;
    const { dirty, values } = this.state;
    if (dirty) {
      if (this.validate()) {
        this.props.createEntry({ createApi: this.getApi(createApi, formValues ? formValues.params : {}), diaryEntry: values });
        this.props.changeOperationMode(Operation.BROWSE);
      } else {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.', time: SnackBarDisplayTimeMilliseconds });
        this.props.changeOperationMode(Operation.NEW);
      }
    } else {
      this.props.changeSelectedTab(this.props.parentTab);
    }
  }

  updateNewRecord = (): void => {
    const { updateApi, formValues } = this.props;
    const { dirty, values } = this.state;

    if (dirty) {
      if (this.validate()) {
        this.props.updateEntry({ updateApi: this.getApi(updateApi, { ...formValues ? formValues.params : {}, DiaryId: values.DiaryId }), diaryEntry: values });
        this.props.changeOperationMode(Operation.BROWSE);
      } else {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.', time: SnackBarDisplayTimeMilliseconds });
        this.props.changeOperationMode(Operation.EDIT);
      }
    } else {
      this.props.changeOperationMode(Operation.BROWSE);
    }
  }

  getApi = (api: string, params: any): string => {
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, params[curr]);
    };
    if (params) Object.keys(params).reduce(reducer, api);

    return (params && Object.keys(params).reduce(reducer, api)) || api;
  }

  cancelFormSubmission = (preOperationMode: Operation): void => {
    const { parentTab, diary } = this.props;
    if (this.state.dirty) {
      this.setState({ dirty: false });
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(preOperationMode);
        },
        onOk: () => {
          this.props.changeOperationMode(Operation.BROWSE);
          if (preOperationMode === Operation.NEW) {
            this.props.changeSelectedTab(parentTab);
          } else if (preOperationMode === Operation.EDIT && diary) {
            this.setState({
              values: {
                UserId: diary.UserId,
                Subject: diary.Subject,
                Date: diary.Date,
                Time: diary.Time,
                CommentFull: diary.CommentFull,
                DiaryId: diary.DiaryId,
                SystemGenerated: diary.SystemGenerated
              },
              dirty: false
            });
          }
        }
      });
    } else {
      this.props.changeOperationMode(Operation.BROWSE);
      if (preOperationMode === Operation.NEW) {
        this.props.changeSelectedTab(parentTab);
      }
    }
  }

  changeUser = (event: any): boolean => {
    this.setState({ dirty: true, values: { ...this.state.values, UserId: (event) || '' } });

    return true;
  }

  changeDate = (event: any): void => {
    this.setState({ dirty: true, values: { ...this.state.values, Date: (event) || '' } });
  }

  changeTime = (event: any): void => {
    this.setState({ dirty: true, values: { ...this.state.values, Time: (event) || '' } });
  }

  changeSubject = (event: any): void => {
    this.setState({ dirty: true, values: { ...this.state.values, Subject: (event.target.value) || '' } });
  }

  changeComment = (event: any): void => {
    this.setState({ dirty: true, values: { ...this.state.values, CommentFull: (event.target.value) || '' } });
  }

  unEditableFields = (): boolean => {
    const { operationMode } = this.props;

    return operationMode !== Operation.NEW;
  }

  readOnly = (): boolean => {
    const { operationMode } = this.props;

    return operationMode !== Operation.NEW && operationMode !== Operation.EDIT;
  }

  render(): React.ReactNode {
    const { isLoading, classes } = this.props;

    return (
      (
        isLoading &&
        <CircularProgress size={CircularProgressSize} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
      )
      || <div>
        <div className={classes.rowWrapper}>
          <div className={classes.left}>
            <ExtendedLookUpActionField
              label={'User Id'}
              value={this.state.values.UserId}
              lookupName={'Personnel'}
              onChange={this.changeUser}
              size='small'
              readOnly={this.readOnly() || this.unEditableFields() || this.state.values.SystemGenerated}
              required
            />
          </div>
          <div className={classes.left}>
            <DateTextField
              label={'Date'}
              value={this.state.values.Date}
              required={required}
              format={'DD/MM/YYYY'}
              onChange={this.changeDate}
              disabled={this.readOnly() || this.unEditableFields() || this.state.values.SystemGenerated}
              size='small'
            />
          </div>
          <div className={classes.left}>
            <TimeTextField
              label={'Time'}
              value={this.state.values.Time}
              required={required}
              format={'hh:mm a'}
              onChange={this.changeTime}
              disabled={this.readOnly() || this.unEditableFields() || this.state.values.SystemGenerated}
              size='small'
            />
          </div>
          <div className={classes.right}>
            <FormControlLabel
              control={<Switch
                disabled={true}
                checked={this.state.values.SystemGenerated}
                color={'default'}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  bar: classes.bar,
                }}
              />}
              classes={{
                label: classes.formControlLabel,
                disabled: classes.disabled
              }}
              label={'System Generated'}
              {...{ name: 'SystemGenerated' }}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <TextField
            label={'Subject'}
            size={'large'}
            required
            value={this.state.values.Subject}
            onChange={this.changeSubject}
            readOnly={this.readOnly() || this.state.values.SystemGenerated}
            autoComplete={'off'}
          />
        </div>
        <div className={classes.commentRowWrapper}>
          <TextArea
            size={'large'}
            style={{ height: '470px' }}
            value={this.state.values.CommentFull}
            readOnly={this.readOnly() || this.state.values.SystemGenerated}
            onChange={this.changeComment}
          />
        </div>
      </div>);
  }
}

export default withRouter(withStyles(Styles, { index: 1 })(DiaryDetails));
