import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as settingsAttributesSelector, actions as settingsAttributesActions } from 'ducks/supplierEnquiry/settings/attributes';
import { fetch } from 'api/supplierEnquiry/settings/attributes';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SupplierSettingsAttributes',
  columnDefs: settingsAttributesSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: settingsAttributesSelector.gridOptions(state),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
    &&
    {
      Supplier: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: settingsAttributesActions.setSelected
};
