import JobTemplateForm from '../data/forms/job_template_maintenance/JobTemplateForm';
import ActivitiesForm from '../data/forms/job_template_maintenance/ActivityForm';

const getFormSchema = (formId) => {
    switch (formId) {
        case 'JobTemplateDetails':
            return JobTemplateForm;
        case 'Activities':
            return ActivitiesForm;
        default:
            return JobTemplateForm;
    }
};

export default getFormSchema;
