/* APIRoutes
* Use create/retrieve/update/delete keywords for api prop name and follow the module screen hierarchy.
* if same api is used for multiple operations, define it once with the prop name in short form
*  (e.g cu, cd,cud, crud)
*/

export const APIRoutes = {
    extractMaintenance: {
        reportingArea: {
            fields: 'ExtractMaintenance/Template/:TemplateId/ReportingArea/Fields',
            retrieveReportingAreas: 'ExtractMaintenance/Template/:TemplateId/ReportingAreas'
        },
        template: {
            new: 'ExtractMaintenance/NewTemplate',
            retrieve: 'ExtractMaintenance/Template/:TemplateId',
            save: 'ExtractMaintenance/Template/:TemplateId',
            delete: 'ExtractMaintenance/Template/:TemplateId/Delete',
            startEdit: 'ExtractMaintenance/Template/:TemplateId/Extract/StartEdit',
            discard: 'ExtractMaintenance/Template/:TemplateId/Discard',
            commit: 'ExtractMaintenance/Template/:TemplateId/Commit',
            extract: {
                sortFields: {
                    retrieve: 'ExtractMaintenance/Template/:TemplateId/Extract/SortFields',
                    update: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/SortField',
                    delete: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/SortField',
                    reOrder: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/SortFields/Reorder',
                },
                filters: {
                    retrieve: 'ExtractMaintenance/Template/:TemplateId/Extract/FilterFields',
                    delete: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/Filter/:FilterId/FilterField',
                }
            },
        },
        fields: {
            retrieve: 'ExtractMaintenance/Template/:TemplateId/Extract/OutputFields',
            update: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/OutputField',
            reorder: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/OutputFields/Reorder',
            add: 'ExtractMaintenance/Template/:TemplateId/Extract/OutputFields',
            delete: 'ExtractMaintenance/Template/:TemplateId/Extract/:ExtractId/OutputField',
        },
        addSort: {
            save: 'ExtractMaintenance/Template/:TemplateId/Extract/SortField'
        },
        addFilter: {
            retrieve: 'ExtractMaintenance/Template/:TemplateId/Extract/Filter/:FilterId/FilterField',
            update: 'ExtractMaintenance/Template/:TemplateId/Extract/FilterField'
        }
    }
};
