import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../utils'

export const search = (query) => {
  const { PurchaseOrder, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchGet(`/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/IntransitLocalShipments`,
    { BatchSize, BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '' }, 'Inline')
    .then((result) => {
      if (result) {
        if (result.LocalShipments) {
          return {
            records: result.LocalShipments.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}