import * as React from 'react';
import { connect } from 'react-redux';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, Button, Grid as GridLayout } from '@material-ui/core';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import ExtendedDisplayField from '../../FormView/Fields/ExtendedDisplayField/index';
import { selectors as priceCalculatorSelectors } from 'ducks/common/priceCalculator';
import SummaryTable from '../SummaryTable/index';
import SummaryTableSchema from './RestockingCalculatorModalSummaryTableSchema.json';

import { calculateRestockingCharge } from 'api/creditNotes';

const style = createStyles({
  header: {
    backgroundColor: '#E5E5E5'
  },
  dialogBody: {
    padding: '2px',
    width: '816px',
    backgroundColor: '#E5E5E5'
  },
  mainBody: {

  },
  footer: {
    backgroundColor: '#E5E5E5',
    margin: '0px',
    padding: '8px 4px'
  },
  buttonClose: {
    color: '#000000de'
  },
  iconClose: {
    color: '#b20000'
  },
  summaryPanel: {
    width: '340px',
    backgroundColor: '#666666',
    height: '100%'
  },
  calcDiv: {
    backgroundColor: '#FFFFFF'
  },
  calculatorFooter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '60px',
    width: '100%',
    backgroundColor: '#ebebeb'
  },
  calculateButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: '#ebebeb'
  },
  formDiv: {
    padding: '15px'
  },
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {

  }

});

export interface IRestockingCalculatorProperties extends StyledComponentProps {
  open: boolean;
  Calculator: any;
  onApply?(filters: any): void;
  onOk(value: any): void;
  onClose(): void;
}

interface IRestockingCalculatorState {
  UpdatedCalculator: any;
  isLoading: boolean;
}

class PriceCalculator extends React.Component<IRestockingCalculatorProperties, IRestockingCalculatorState> {

  constructor(props: Readonly<IRestockingCalculatorProperties>) {
    super(props);
    this.state = {
      UpdatedCalculator: props.Calculator,
      isLoading: false
    };
  }

  componentDidUpdate(prevProps: IRestockingCalculatorProperties): void {
    const { Calculator } = this.props;
    if (prevProps.Calculator !== Calculator) {
      this.setState({ UpdatedCalculator: Calculator });
    }
  }

  handleRestockingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.UpdatedCalculator) {
      this.setState({ UpdatedCalculator: { ...this.state.UpdatedCalculator, RestockingPercent: (event && event.target && parseFloat(event.target.value)) || 0 } });
    }
  }

  handleApplyFilters = () => {
    //applyfilters
    this.setState({ isLoading: true });
    calculateRestockingCharge(this.state.UpdatedCalculator).then((result) => { this.setState({ UpdatedCalculator: result, isLoading: false }); }).catch();
  }

  handleOk = () => {
    this.setState({ UpdatedCalculator: { ...this.props.Calculator } });
    this.props.onOk(this.state.UpdatedCalculator && this.state.UpdatedCalculator.TotalStockingCharge);
  }

  handleClose = () => {
    this.setState({ UpdatedCalculator: { ...this.props.Calculator } });
    this.props.onClose();
  }

  render(): JSX.Element {
    const { classes } = this.props;
    const defaultDecimalPlaces = 2;

    return (<Dialog
      open={this.props.open}
      aria-labelledby='form-dialog-title'
      maxWidth={false}
    >
      <DialogTitle className={classes.header}>Restocking Charge Calculator</DialogTitle>
      <DialogContent className={classes.dialogBody}>
        <GridLayout container spacing={0} direction={'row'}>
          <GridLayout {...{ item: true, xs: 7, className: classes.calcDiv }}>
            <div className={classes.formDiv}>
              <div>
                <TextField
                  size={'medium'}
                  label={'Credit amount'}
                  type={'number'}
                  decimalSeparator
                  decimalPlaces={defaultDecimalPlaces}
                  defaultValue={this.state.UpdatedCalculator && this.state.UpdatedCalculator.CreditAmount}
                  readOnly
                />
              </div>
              <div>
                <ExtendedDisplayField
                  {
                  ...{
                    label: 'Restocking %',
                    type: 'number',
                    decimalSeparator: true,
                    adornment: '%',
                    size: 'small',
                    defaultValue: this.state.UpdatedCalculator && this.state.UpdatedCalculator.RestockingPercent,
                    onChange: this.handleRestockingChange
                  }
                  }

                />
              </div>
            </div>
            <div className={classes.calculatorFooter}>
              <div className={classes.calculateButton}>
              </div>
              <Button
                variant={'contained'}
                className={classes.calculateButton}
                onClick={this.handleApplyFilters}>
                <DoneAllIcon style={{ color: '#006633' }} /> CALCULATE
            </Button>
            </div>
          </GridLayout>
          <GridLayout  {...{ item: true, xs: 5 }}>
            <Paper elevation={0} className={classes.summaryPanel} square={true}>
              <SummaryTable data={this.state.UpdatedCalculator.TaxIncluded ? SummaryTableSchema.inclusiveTax : SummaryTableSchema.exclusiveTax} summary={this.state.UpdatedCalculator} isLoading={this.state.isLoading} />
            </Paper>
          </GridLayout>
        </GridLayout>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button color='primary' onClick={this.handleClose} className={classes.buttonClose}>
          Cancel
        </Button>
        <Button color='primary' onClick={this.handleOk} className={classes.buttonClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

const mapStateToProps = (state) => ({
  isLoading: priceCalculatorSelectors.isLoading(state)
});

export default connect(mapStateToProps, {})(withStyles(style, {index: 1})(PriceCalculator));
