import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
  reset: () => null,
  asyncs: {
  }
}, 'reserveHistory');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Reserve type', field: 'ReserveType', minWidth: 150, suppressSorting: true, },
    { headerName: 'Document', field: 'Document', minWidth: 150, suppressSorting: true, },
    { headerName: 'Date', field: 'Date', minWidth: 120, type: 'numericColumn', },
    { headerName: 'Action', field: 'Action', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reserved quantity', field: 'ReservedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'User', field: 'Username', suppressSorting: true, minWidth: 100, },
    { headerName: 'Name', field: 'ResponsiblePersonLabel', suppressSorting: true, minWidth: 200, },
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            CustomerId: row.data.Customer,
            CustomerAccountNumber: row.data.CustomerDisplay,
            FieldValue: row.data.CustomerDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer name', field: 'CustomerLabel', minWidth: 200, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 250, suppressSorting: true, },
  ],
  detailCellRenderer: 'CommentAreaRenderer',
  detailCellRendererParams: {
    getDetailRowData: function (params) {
      return { commentData: params.data.Commment }
    }
  },
  Data: [],
  selectedFilters: {
    ReserveTypeOption: 'All',
    Warehouse: 'QW',
    Customer: '',
    Document: '',
  },
  filterRow: {
    formName: 'IEReserveHistoryFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Reserve type',
          name: 'ReserveTypeOption',
          required: true,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'General',
              label: 'General'
            },
            {
              value: '1',
              label: 'Quotes'
            },
            {
              value: '2',
              label: 'Enquiry'
            },
            {
              value: '3',
              label: 'Sales order'
            },
            {
              value: '4',
              label: 'Service order'
            }
          ]
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'Customer',
          lookupName: 'Customer',
          size: 'medium',
          required: false
        }
      },
      {
        id: 3,
        type: 'TEXT_FIELD',
        props: {
          label: 'Document',
          name: 'Document',
          size: 'medium',
          required: false,
        }
      },
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setWarehouse:
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: warehouse
          }
        }
      };
   
    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.reserveHistory,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.reserveHistory.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.reserveHistory.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.reserveHistory.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.reserveHistory.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.reserveHistory.data.selectedFilters,
  detailCellRenderer: state => state.inventoryEnquiry.stocking.reserveHistory.data.detailCellRenderer,
  detailColumns: state => state.inventoryEnquiry.stocking.reserveHistory.data.detailCellRendererParams,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)