import React from 'react';
import LayoutFrame from 'components/LayoutFrame';
import { withRouter } from 'react-router-dom';
const CUSTOMER_MAINTENANCE = 'customer-maintenance';
const CUSTOMER_ENQUIRY = 'customer-enquiry';

class Layout extends React.Component {

  componentDidMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    const locationPath = location.pathname.split('/')[1];
    this.props.changeScope(locationPath);

    if (locationPath === CUSTOMER_MAINTENANCE) {

      if (this.props.selectedTab) {
        this.props.changeSelectedForm(CUSTOMER_MAINTENANCE, this.props.selectedTab);
        this.props.getFormSchema({
          context: CUSTOMER_MAINTENANCE,
          formId: this.props.selectedTab
        });
      }
    }

    if (locationPath === CUSTOMER_ENQUIRY) {
      if (this.props.selectedTab) {
        this.props.changeSelectedForm(CUSTOMER_ENQUIRY, this.props.selectedTab);
        this.props.getFormSchema({
          context: CUSTOMER_ENQUIRY,
          formId: this.props.selectedTab
        });
      }
    }
  }



  toggleMainMenu = (value) => {
    if (value) {
      this.props.closeMainMenu();
    }
    else {
      this.props.openMainMenu();
    }
  }

  render() {
    const { loggedIn, isMainMenuOpen } = this.props;
    return (
      <LayoutFrame showIcons={loggedIn} toggleMainMenu={this.toggleMainMenu} isMainMenuOpen={isMainMenuOpen && loggedIn} />
    )
  }
}

export default withRouter(Layout);