import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as transactionSelectors } from 'ducks/customerEnquiry/transactions';
import { selectors as movementSelectors } from 'ducks/pme/Movements';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  selectedTransaction: transactionSelectors.selected(state),
  transactionDetails: transactionSelectors.transactionDetails(state),
  selectedTransactionLoading: transactionSelectors.isLoading(state),
  movementSummary: movementSelectors.summary(state),
  loadingSummary: movementSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
