import {
    asyncSelectors, IExtendedState, initializeReducer, syncSelectors,
} from 'ducks/utils';
import { InlineValue } from 'api/utils';
import { IActivityChecklistFacade, } from 'api/swaggerTypes';
import { IObjectifiedActivityChecklistsResponse } from 'api/activityMaintenance/checklists';
import { isNull } from 'utils/utils';

export interface IChecklistData {
    list: IActivityChecklistFacade[];
    schemas: InlineValue<IActivityChecklistFacade>[];
    isDirty: boolean;
}

export interface IChecklistState extends IExtendedState<IChecklistData> {
    getChecklists_loading?: boolean;
    updateChecklists_loading?: boolean;
}

const initialData: IChecklistData = {
    list: [],
    schemas: [],
    isDirty: false
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'checklists',
    initialData: initialData,
    syncActions: {
        setIsDirty: {
            action: (isDirty: boolean) => isDirty,
            callback: (data: IChecklistData, isDirty: boolean): IChecklistData => ({ ...data, isDirty: isDirty })
        }
    },
    asyncActions: {
        getChecklists: {
            action: (ActivityCode: string) => (ActivityCode),
            successCallback: (data: IChecklistData, payload: IObjectifiedActivityChecklistsResponse): IChecklistData => {
                const checklists = payload.ActivityChecklists && payload.ActivityChecklists.inlineObject;
                const schemas = payload.ActivityChecklists && payload.ActivityChecklists.schema;
                const checklistsArray = Object.values(checklists).filter((cl) => !isNull(cl));

                return { ...data, list: checklistsArray, schemas: schemas, isDirty: false };
            }
        },
        updateChecklists: {
            action: (query: { ActivityCode: string; checklists: IActivityChecklistFacade[] }) => (query),
            successCallback: (data: IChecklistData, payload: IObjectifiedActivityChecklistsResponse): IChecklistData => {
                const checklists = payload.ActivityChecklists && payload.ActivityChecklists.inlineObject;
                const schemas = payload.ActivityChecklists && payload.ActivityChecklists.schema;
                const checklistsArray = Object.values(checklists).filter((cl) => !isNull(cl));

                return { ...data, list: checklistsArray, schemas: schemas, isDirty: false };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { activityMaintenance: { checklists: IChecklistState } }) => state.activityMaintenance.checklists,
    {
        checklists: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    }
);

const syncSelector = syncSelectors(
    (state: { activityMaintenance: { checklists: IChecklistState } }) => state.activityMaintenance.checklists,
    {
        isLoading: (checklists) => checklists.getChecklists_loading || checklists.updateChecklists_loading,
        notifications: (checklists) => checklists.notifications,
        error: (checklists) => checklists.error,
        isDirty: (checklists) => checklists.data.isDirty,
    });

export const selectors = { ...asyncSelector, ...syncSelector };
