import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuoteLines from './QuoteLines';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as quoteSelectors, actions as quoteActions } from 'ducks/customerQuoteEnquiry/quote';
import { selectors as bomReservationSerialsSelectors } from 'ducks/customerQuoteEnquiry/bomReservationSerials';
import { selectors as bomReservationLotSelectors } from 'ducks/customerQuoteEnquiry/bomReservationLots';
import { actions as BOMActions, selectors as BOMSelectors } from 'ducks/customerQuoteEnquiry/BOM';
import { selectors as reservationSerialsSelectors } from 'ducks/customerQuoteEnquiry/reservationSerials';
import { selectors as reservationLotSelectors } from 'ducks/customerQuoteEnquiry/reservationLots';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  reservationDetails: quoteSelectors.reservationDetails(state),
  quoteDetails: quoteSelectors.quoteDetails(state),
  quoteDetailsSchema: quoteSelectors.quoteDetailsSchema(state),
  reservationDetailsSchema: quoteSelectors.reservationDetailsSchema(state),
  quoteLineDetailSchema: quoteSelectors.quoteLineDetailSchema(state),
  bomReservationSerialLines: bomReservationSerialsSelectors.lines(state),
  bomReservationLotLines: bomReservationLotSelectors.lines(state),
  quoteLineSummary: quoteSelectors.quoteLineSummary(state),
  LoadingQuoteLineSummary: quoteSelectors.LoadingQuoteLineSummary(state),
  bomReservationDetails: BOMSelectors.reservationDetails(state),
  reservationSerialLines: reservationSerialsSelectors.lines(state),
  selectedQuote: quoteSelectors.selected(state),
  quoteLineDetail: quoteSelectors.quoteLineDetail(state),
  bomLines: BOMSelectors.lines(state),
  bomLineDetails: BOMSelectors.lineDetails(state),
  reservationLotLines: reservationLotSelectors.lines(state),
  bomLineDetailSummary: BOMSelectors.lineDetailSummary(state),
  selectedBOMLine: BOMSelectors.selected(state),
  selectedQuoteLine: quoteSelectors.selectedQuoteLine(state),
  selectedQuoteSchema: quoteSelectors.selectedQuoteSchema(state)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchQuoteLines: quoteActions.fetchQuoteLines,
    fetchQuoteLineDetail: quoteActions.fetchQuoteLineDetail,
    fetchQuoteLineSummary: quoteActions.fetchQuoteLineSummary,
    fetchReservationDetails: quoteActions.fetchReservationDetails,
    fetchQuoteSummaryPanel: quoteActions.fetchQuoteSummaryPanel,
    fetchBOMLineDetails: BOMActions.fetchLineDetails,
    fetchBOMLineDetailSummary: BOMActions.fetchLineDetailSummary,
    fetchBOMReservationDetails: BOMActions.fetchReservationDetails,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(QuoteLines);
