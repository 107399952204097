import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    DialogTitleStyles: {
        'backgroundColor': '#dddddd',
        'padding': '15px 0 15px 10px',
        '& h2': {
            fontWeight: 600,
            fontSize: '22px',
        }
    },
    ContainerStyles: {
        'display': 'flex',
        'justifyContent': 'flex-start',
        'gap': '46px',
        'marginTop': '20px',
        '@media screen and (max-width: 1176px)': {
            flexDirection: 'column',
            gap: '15px',
        }
    },
    FormContainerStyles: {
        'display': 'flex',
        '& > div': {
            display: 'inline-flex',
            flexDirection: 'column',
            marginRight: '5px'
        },
        '@media screen and (max-width: 860px)': {
            flexDirection: 'column',
        }
    },
    ButtonContainerStyles: {
        'display': 'flex',
        'marginBottom': '20px',
        'align-items': 'center',
        '@media screen and (max-width: 1176px)': {
            justifyContent: 'end',
            marginBottom: '0px',
        }
    },
    ButtonLabelStyle: {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 500,
        alignItems: 'flex-start',
        lineHeight: '26.5px',
        pointerEvents: 'none'
    },
    CheckCircleIconStyle: {
        color: 'green',
    },
    CancelIconStyle: {
        color: 'red',
    },
    button: {
        backgroundColor: 'transparent',
        height: '36px',
        boxShadow: 'none',
    },
    labels: {
        '& div label': {
            paddingBottom: '8px',
        },
        '& div div': {
            display: 'block !important',
        },
        '& div div div div': {
            'paddingBottom': '1px !important',
            '& p': {
                'padding-left': '8px !important',
            },
        },
    },
    Price: {
        'marginLeft': '8px',
        '@media screen and (max-width: 860px)': {
            marginLeft: '0px !important',
        }
    },
    DialogContent: {
        padding: '4px 0 10px 10px',
    },
});

export const DialogStyles = {
    maxWidth: '1116px',
    borderRadius: 0,
};
