import React from 'react';
import { usePOContextSelector, usePODispatch } from './PurchaseOrders.context';
import { RouteComponentProps } from 'react-router';

export enum PickSlipAPIType {
    deleteGridLine = 'deleteGridLine',
    updateGridLine = 'updateGridLine',
    updateLine = 'updateLine',
}

export const useTabLocations = (url: string, location: RouteComponentProps['location']) => {

    const contextDispatch = usePODispatch();
    // Dummy States
    const PurchaseOrder = usePOContextSelector<'PurchaseOrder'>((state) => state.PurchaseOrder);
    const POLineNumber = usePOContextSelector<'LineNumber'>((state) => state.LineNumber);

    const tabLocations = React.useMemo(
        () => {

            return ({
                Lines: {
                    pathname: url,
                    search: `?PurchaseOrder=${PurchaseOrder}`
                },
                Delivery: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}delivery`,
                    search: `?PurchaseOrder=${PurchaseOrder}`
                },
                PurchaseOrderDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}order-details`,
                    search: `?PurchaseOrder=${PurchaseOrder}`
                },
            });
        },
        [url, PurchaseOrder, POLineNumber]
    );

    React.useEffect(
        () => {
            const params = (new URLSearchParams(location.search));
            const PurchaseOrderParam = params.get('PurchaseOrder') || '';
            const LineNumberParam = params.get('LineNumber') || '';

            contextDispatch({
                setPurchaseOrder: Number(PurchaseOrderParam),
                setLineNumber: Number(LineNumberParam),
            });
        },
        [contextDispatch]
    );

    return {
        tabLocations
    };
};
