import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction, IExtendedState
} from '../utils';

export interface IProductLinksData {
  selected: any;
  gridOptions: IDataGridOptions;
  Actions: any;
  lineColumns: IColDef[];
  lines: any[];
}

export interface IProductLinksState extends IExtendedState<IProductLinksData> {
}

export const { types, actions } = createActions(
  {
  },
  'productLinks');

const initialState = asyncInitialState<IProductLinksData>({
  selected: null,
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  lineColumns: [
    { headerName: 'Product', field: 'ProductDisplay', minWidth: 150 },
    { headerName: 'Group', field: 'Group', minWidth: 150 },
    { headerName: 'Main supplier', field: 'MainSupplierDisplay', minWidth: 150, suppressSorting: true },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 150 },
    { headerName: 'Description', field: 'Description', minWidth: 150 },
  ],
  lines: []
});

export default (state: IProductLinksState = initialState, action: IDataAction): IProductLinksState => {
  switch (action.type) {
    default:
      return state;
  }
};

const syncSelector = {
  isLoading: (state: { supplierProductEnquiry: { productLinks: IProductLinksState } }) => state.supplierProductEnquiry.productLinks.loading,
  gridOptions: (state: { supplierProductEnquiry: { productLinks: IProductLinksState } }) => state.supplierProductEnquiry.productLinks.data.gridOptions,
  lineColumns: (state: { supplierProductEnquiry: { productLinks: IProductLinksState } }) => state.supplierProductEnquiry.productLinks.data.lineColumns,
};

export const selectors = syncSelector;
