
export const MODULE_TREE = [
  { id: 'QuoteDetails', label: 'Quote Details', parent: '' },
  { id: 'QuoteSummary', label: 'Quote Summary', parent: '' },
  { id: 'Comments', label: 'Comments', parent: '' },
  { id: 'QuoteLines', label: 'Quote Lines', parent: '' },
  { id: 'LineDetails', label: 'Line Details', parent: 'QuoteLines' },
  { id: 'S/Order', label: 'S/Order', parent: '' },
  { id: 'Notepad', label: 'Notepad', parent: '' },
  { id: 'SalesDiary', label: 'Diary', parent: '' },
  { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' },
  { id: 'BillOfMaterials', label: 'Bill of Materials', parent: 'LineDetails' },
  { id: 'ComponentDetails', label: 'Component Details', parent: 'BillOfMaterials' },
  { id: 'ReservationDetails', label: 'Reservation Details', parent: 'LineDetails' },
  { id: 'ReservationLots', label: 'Reservation Lots', parent: 'LineDetails' },
  { id: 'ReservationSerials', label: 'Reservation Serials', parent: 'LineDetails' },
  { id: 'BOMReservationDetails', label: 'Reservation Details', parent: 'ComponentDetails' },
  { id: 'BOMReservationLots', label: 'Reservation Lots', parent: 'ComponentDetails' },
  { id: 'BOMReservationSerials', label: 'Reservation Serials', parent: 'ComponentDetails' },
];
