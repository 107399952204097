import { Payload, usePost } from 'api/reactQuery/queryService';
import {
    IBinLotsFacade, IValidatedResponse
} from 'api/swaggerTypes';
import { APIRoutes } from './constants';

export type IBinLotsFacadeExtended = { DocumentId?: number; RequiredQuantity?: number; ProductId?: number } & IBinLotsFacade;
interface IUpdateProductWIPLotSerialPayload {
    DocumentId?: number;
    RequiredQuantity?: number;
    ProductId?: number;
    SuppliedQuantity?: number;
    KittingQuantity?: number;
    WarehouseEntity?: string;
}

export const useUpdateProductWIPLotSerial = () =>
    usePost<Payload<{ BinLot: IBinLotsFacade }, IUpdateProductWIPLotSerialPayload>, IValidatedResponse>(
        APIRoutes.product.lotSerial.update,
        null,
        { inline: true }
    );
