export default [
  {
    id: '1',
    name: 'Australian Capital Territory',
    abbreviation: 'ACT',
    city: 'Smith'
  },
  {
    id: '2',
    name: 'New South Wales',
    abbreviation: 'NSW',
    city: 'Fitzgerald'
  },
  {
    id: '3',
    name: 'Queensland',
    abbreviation: 'QLD',
    city: 'Harper'
  },
  {
    id: '4',
    name: 'South Australia',
    abbreviation: 'SA',
    city: 'Jones'
  },
  {
    id: '5',
    name: 'North Australia',
    abbreviation: 'NA',
    city: 'Biden'
  },
  {
    id: '6',
    name: 'Western Australia',
    abbreviation: 'WA',
    city: 'Perth'
  },
  {
    id: '7',
    name: 'Victoria',
    abbreviation: 'VIC',
    city: 'Sydney'
  },
  {
    id: '8',
    name: 'Tasmania',
    abbreviation: 'TAS',
    city: 'Perth'
  }
];
