import * as React from 'react';
import DashboardMaintenanceProps, { IDashboardWidget } from './DashboardMaintenance.properties';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import AddWidgetSchema from './AddWidget.form.json';
import DashboardGrid from './DashboardMaintenanceGrid';
import { isNull } from 'utils/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useSaveDashboards } from 'api/dashboardMaintenance/dashboardMaintenance';

const DashboardMaintenance = (props: DashboardMaintenanceProps): JSX.Element => {
    const { open, isDefaultDashboard, onClose, onCloseSuccess, getUserDashboards, touchFormFields, resetAddWidgetForm, changeConfirmationDialog } = props;

    const [addWidgetOpen, setAddWidgetOpen] = React.useState<boolean>(false);
    const [selectedWidgetTypeId, setSelectedWidgetTypeId] = React.useState<number>();
    const [selectedWidgetType, setSelectedWidgetType] = React.useState<string>();
    const [selectedWidget, setSelectedWidget] = React.useState<string>();
    const [selectedWidgetId, setSelectedWidgetId] = React.useState<number>();
    const [dashboards, setDashboards] = React.useState([]);

    const { mutateAsync: saveDashboardsMutateAsync } = useSaveDashboards();

    const toggleAddWidgetModal = (openValue: boolean) => {
        setAddWidgetOpen(openValue);
    };

    React.useEffect(
        () => {
            if (open) {
                try {
                    setDashboards([]);
                    getDashboards();
                } catch (err) {
                    console.warn(err);
                }
            }
        },
        [open]);

    const confirmationActions = (): IActionItem[] => {
        return [
            {
                iconName: 'Cancel',
                title: 'Cancel',
                listener: async () => {
                    if (onClose) {
                        onClose();
                    }
                }
            },
            {
                title: 'Ok',
                iconName: 'CheckCircle',
                listener: async () => {
                    if (onClose) {
                        onClose();
                    }
                    saveWidget(dashboards)
                        .then((response) => {
                            if (response.Status && !response.Forms) {
                                if (onCloseSuccess) onCloseSuccess();
                                showSnackBar({ variant: 'success', message: 'Widget saved successfully' });
                            }
                        })
                        .catch((err) => {
                            showSnackBar({ variant: 'error', message: 'Failed to save widgets.' });
                            console.warn(err);
                        });
                }
            },
            {
                title: 'Add Widget',
                iconName: 'AddCircle',
                alignment: 'left',
                listener: async () => {
                    toggleAddWidgetModal(true);
                    clearLastSelected();
                }
            }];
    };

    const addWidgetActions = (): IActionItem[] => {
        return [
            {
                iconName: 'Cancel',
                title: 'Cancel',
                listener: async () => {
                    toggleAddWidgetModal(false);
                }
            },
            {
                title: 'Ok',
                iconName: 'CheckCircle',
                listener: async () => {
                    if (isNull(selectedWidgetId) || isNull(selectedWidgetTypeId)) {
                        touchFormFields('Widget');
                        touchFormFields('WidgetType');
                    } else {
                        addWidget();
                        toggleAddWidgetModal(false);
                    }
                }
            },
        ];
    };

    const clearLastSelected = () => {
        resetAddWidgetForm();
        setSelectedWidget(null);
        setSelectedWidgetId(null);
        setSelectedWidgetTypeId(null);
        setSelectedWidgetType(null);
    };

    const addWidget = () => {
        const dashboard = {
            Id: selectedWidgetId,
            WidgetId: selectedWidgetId,
            WidgetIdLabel: selectedWidget,
            WidgetTypeId: selectedWidgetTypeId,
            WidgetTypeIdLabel: selectedWidgetType,

        };

        if (!isNull(dashboard.WidgetId) && !isNull(dashboard.WidgetTypeId) && dashboards.indexOf((d) => d.WidgetId === dashboard.Id) < 0) {
            setDashboards([...dashboards, dashboard]);
        }
    };

    const saveWidget = async (widgets: IDashboardWidget[]) => {
        const response = await saveDashboardsMutateAsync({
            arrayTypePayload: widgets,
            urlQueryParams: {
                defaultDashboard: isDefaultDashboard ? 'True' : 'False'
            },
        });

        return response;
    };

    const getDashboards = () => {
        getUserDashboards(isDefaultDashboard)
            .then((response) => {
                setDashboards(response.DashboardWidgets);
            }).catch((err) => {
                console.warn(err);
            });
    };

    const onWidgetTypeChange = (inv?: { Code?: number; Label?: string }) => {
        console.log('inv', inv);
        setSelectedWidgetTypeId(inv && inv.Code || null);
        setSelectedWidgetType(inv && inv.Label || null);
    };
    const onWidgetChange = (inv?: { Code?: number; Label?: string }) => {
        setSelectedWidgetId(inv && Number(inv.Code) || null);
        setSelectedWidget(inv && inv.Label || null);
    };

    const onDelete = React.useCallback(
        ({ row }) => {
            changeConfirmationDialog({
                open: true,
                title: 'Delete selected record',
                message: 'Are you sure you want to delete this record?',
                okLabel: 'Delete',
                onCancel: () => null,
                onOk: () => {
                    setDashboards((preDashboards) => preDashboards.filter((widget) => widget.DashboardWidgetId
                        !== row.data.DashboardWidgetId));

                }
            });
        },
        [dashboards]
    );

    return (
        <div>
            <FormViewModal
                open={open}
                loading={false}
                title={`${isDefaultDashboard ? 'Default ' : ''}Dashboard Maintenance`}
                modalContent={<DashboardGrid data={dashboards} onDelete={onDelete} />}
                actions={confirmationActions()}
            />
            {addWidgetOpen && <FormViewModal
                open={addWidgetOpen}
                loading={false}
                title={'Add Widget'}
                actions={addWidgetActions()}
                formProps={{
                    widgetTypeId: Number(selectedWidgetTypeId),
                    style: {
                        height: '175px',
                        minWidth: '390px'
                    },
                    formSchema: AddWidgetSchema,
                    valuesSchema: {},
                    fieldExtensions: ({
                        WidgetType: {
                            onSelectedItemChange: onWidgetTypeChange
                        },
                        Widget: {
                            onSelectedItemChange: onWidgetChange
                        },
                    })
                }}
            />}
        </div>
    );
};

export default DashboardMaintenance;
