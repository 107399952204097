import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { ISupplierInvoiceEnquiryLineSerialFacade } from 'api/swaggerTypes';
import { IInvoiceLinesSerialNumbersSearchResponse } from 'api/supplierInvoiceEnquiry/interfaces';

export interface IInvoiceLinesSerialNumbersData {
  selected: ISupplierInvoiceEnquiryLineSerialFacade;
  gridOptions: IDataGridOptions;
  Actions: any;
  lineColumns: IColDef[];
  lines: ISupplierInvoiceEnquiryLineSerialFacade[];
}

export interface IInvoiceLinesSerialNumbersState extends IExtendedState<IInvoiceLinesSerialNumbersData> {
}

const initialData: IInvoiceLinesSerialNumbersData = {
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true
  },
  Actions: {
  },
  lineColumns: [
    { headerName: 'Serial number', field: 'SerialNumber', minWidth: 150 }
  ],
  lines: []
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'supplierInvoiceLinesSerialNumbers',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (row: ISupplierInvoiceEnquiryLineSerialFacade) => (row),
      callback: (data: IInvoiceLinesSerialNumbersData, row: ISupplierInvoiceEnquiryLineSerialFacade) => ({ ...data, selected: row })
    },
    clearLines: {
      action: () => null,
    }
  },
  asyncActions: {
    fetch: {
      action: (query: { DocumentNumber: number; LineNumber: number; Page?: number; Sort?: string; SortDirection?: string }) => query,
      successCallback: (data: IInvoiceLinesSerialNumbersData, payload: IInvoiceLinesSerialNumbersSearchResponse) => ({ ...data, lines: payload && payload.records })
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesSerialNumbers: IInvoiceLinesSerialNumbersState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesSerialNumbers,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
  }
);

const syncSelector = {
  isLoading: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesSerialNumbers: IInvoiceLinesSerialNumbersState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesSerialNumbers.loading,
  gridOptions: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesSerialNumbers: IInvoiceLinesSerialNumbersState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesSerialNumbers.data.gridOptions,
  lineColumns: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesSerialNumbers: IInvoiceLinesSerialNumbersState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesSerialNumbers.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
