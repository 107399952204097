import { connect } from 'react-redux';
import { SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import moment from 'moment';
import * as React from 'react';
import ErrorIcon from '@markinson/uicomponents-v2/SvgIcons/Error';
import { isNull } from 'utils/utils';
import { IApplicationState } from 'ducks/reducers';
import { selectors as activitySchedulerSelectors, actions as activitySchedulerActions, IEventItem } from 'ducks/serviceActivityScheduling/serviceActivities';
import { bindActionCreators, Dispatch } from 'redux';

const BACKGROUND_COLORS = {
  ServiceJob: '#76C043',
  ServiceActivity: '#47c3d2',
  General: '#FFBF32'
};
export interface ISchedulerEventProps {
  schedulerData: SchedulerData;
  event: any;
  bgColor: any;
  isStart: boolean;
  isEnd: boolean;
  mustAddCssClass: any;
  mustBeHeight: any;
  agendaMaxEventWidth: any;
  selectedId: number;
  selectedActivityScheduleId: number;
  hoverSelectedActivityOptionsId: number;
  hoverSelectedActivityScheduleId: number;
  hoverSelectedOptions?: number;
  openScheduleActivityOptions: number;
  isHover: boolean;
  activityType?: 'ServiceActivity' | 'ServiceJob' | 'General';
  setHoverSelectedOptions(activityScheduleId: any): void;
  setHoverSelectedActivitySchedule(activityScheduleId: number): void;
  setHoverSelected(activityScheduleId: any): void;
  adjustActivitySchedule(event: IEventItem): void;
  deleteActivitySchedule(event: IEventItem): void;
  scheduleActivityOptionsModalToggle(selectedActivityScheduleId: number): void;
  hoverToggle(isOpen: boolean): void;
  eventItemMouseDown?(event: IEventItem): void;
  eventItemMouseUp?(event: IEventItem): void;
}

function SchedulerEvent(props: Readonly<ISchedulerEventProps>): JSX.Element {
  const {
    event, schedulerData, selectedId, selectedActivityScheduleId, hoverSelectedActivityScheduleId,
    bgColor, setHoverSelectedOptions, hoverSelectedActivityOptionsId, mustBeHeight, agendaMaxEventWidth, isStart, isEnd, mustAddCssClass,
    deleteActivitySchedule, scheduleActivityOptionsModalToggle, setHoverSelected, setHoverSelectedActivitySchedule, openScheduleActivityOptions,
    adjustActivitySchedule, hoverToggle, eventItemMouseDown, eventItemMouseUp } = props;
  const titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
  const isActivitySelected = event.activityId && selectedId && selectedId.toString() === event.activityId;
  const isEventSelected = selectedActivityScheduleId === event.id;
  const isHoverEventSelected = hoverSelectedActivityScheduleId === event.id;
  const isOptionsModalToggle = openScheduleActivityOptions === event.id;
  const isGeneral = event.activityType === 'General';

  const backgroundColor = BACKGROUND_COLORS[event.activityType] || bgColor;

  const hasMessage = !isNull(event.messageType);
  const activityText = event.description;
  const divStyle: React.CSSProperties = {
    border: isEventSelected ? '2px solid rgb(63,63,63)' :
      isHoverEventSelected ? '2px dotted rgb(63,63,63)' :
        isActivitySelected ? '2px solid rgb(127,127,127)' :
          '2px solid transparent',
    borderRadius: '4px',
    backgroundColor: backgroundColor,
    height: `calc(${mustBeHeight} - 2px)`,
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginTop: '6px',
    marginRight: '5px',
    marginLeft: '5px',
    alignItems: 'flex-start',
    fontSize: '12px',
    position: 'relative',
    cursor: 'move'
  };

  if (!!agendaMaxEventWidth) {
    (divStyle as any).maxWidth = agendaMaxEventWidth;
  }
  const labelStyle = { color: '#000000' };
  if (isStart) {
    (divStyle as any).paddingLeft = '4px';
  }
  if (isEnd) {
    (divStyle as any).paddingRight = '4px';
  }

  function getDifference(): string {
    const start = moment(event.start).valueOf();
    const end = moment(event.end).valueOf();
    const diff = end - start;
    const hours = moment.utc(diff).format('H');
    const mins = moment.utc(diff).format('m');
    let result = '';
    if (hours > 0) {
      result += `${hours}h `;
    }

    if (mins > 0) {
      result += `${mins}m`;
    }

    return result;
  }

  function getHoverStatus(): void {
    hoverToggle(true);

    if (setHoverSelected && event) {
      setHoverSelected(event.activityId);
    }
    if (setHoverSelectedActivitySchedule && event) {
      setHoverSelectedActivitySchedule(event.id);
    }
  }

  const optionOuterContainerRef = React.useRef(null);

  const onEventItemMouseDown = (e) => {
    if (eventItemMouseDown && optionOuterContainerRef && !optionOuterContainerRef.current.contains(e.target)) {
      eventItemMouseDown(event);
    }
  };

  const onEventItemMouseUp = (e) => {
    if (eventItemMouseUp && optionOuterContainerRef && !optionOuterContainerRef.current.contains(e.target)) {
      eventItemMouseUp(event);
    }
  };

  return <div
    onMouseDown={onEventItemMouseDown}
    onMouseUp={onEventItemMouseUp}
    style={{ paddingLeft: '0px', paddingRight: '7px', pointerEvents: 'auto' }}
    onMouseEnter={() => { getHoverStatus(); }}
    onMouseLeave={() => { setHoverSelectedActivitySchedule(1); setHoverSelected(1); hoverToggle(false); }}
  >
    <div ref={optionOuterContainerRef} >
      <div
        onClick={() => { scheduleActivityOptionsModalToggle(event.id); }}
        onMouseEnter={() => { setHoverSelectedOptions(!hoverSelectedActivityOptionsId); }}
        onMouseLeave={() => { setHoverSelectedOptions(!hoverSelectedActivityOptionsId); }}
        style={{
          padding: '0px',
          margin: '0px',
          justifyContent: 'center',
          position: 'absolute',
          transform: 'translate(6px, -9px)',
          width: '20px',
          height: '20px',
          right: '3px',
          zIndex: 3
        }}
      >
        {(isEventSelected || isHoverEventSelected) &&

          <div>
            <span style={{ color: 'grey', position: 'absolute' }}>
              <p style={{ fontSize: '15px', color: 'grey' }}>&#9899;</p>
            </span>

            <span style={{ color: 'black', position: 'absolute', zIndex: 4, transform: 'translate(0px, -8px)', right: '3px' }}>
              <p style={{ fontSize: '20px', color: 'white' }}>&#8230;</p>
            </span>
          </div>
        }
      </div>
      {
        ((isOptionsModalToggle && isEventSelected) || ((!isEventSelected && isOptionsModalToggle && isHoverEventSelected))) &&
        <div style={{ padding: '3px', backgroundColor: 'white', paddingLeft: '8px', margin: '0px', border: '1px solid black', borderRadius: '4px', justifyContent: 'center', position: 'absolute', transform: 'translate(-2px, 5px)', right: '6px', zIndex: 2 }}>
          <span style={{ color: 'black' }}>
            <div onClick={() => { adjustActivitySchedule(event); }}><p style={{ color: 'black', fontSize: '12px', marginBottom: '0.5em' }}>Adjust Schedule</p></div>
            <div onClick={() => { deleteActivitySchedule(event); }}><p style={{ color: 'red', fontSize: '12px', marginBottom: '0' }}>Delete</p></div>
          </span>
        </div>
      }
    </div>
    <div key={event.id} className={mustAddCssClass} style={divStyle} >

      <div style={{
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
        margin: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        maxWidth: '80%',
      }}>
        {!isGeneral && <span style={{ ...labelStyle, color: 'white', width: '140%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={event.customerName}>
          {event.customerName}
        </span>}
        <span style={{ ...labelStyle, fontWeight: 'bold' }}>
          {titleText}
        </span>
        <span style={{ ...labelStyle, width: '140%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {activityText}
        </span>
        <span style={{ color: 'white' }}>
          {hasMessage && <span style={{ color: 'orange', padding: '0px', margin: '0px', width: '15px' }}>
            <ErrorIcon style={{ height: '12px', paddingRight: '2px', margin: '0px', transform: 'translate(0, 2px)', width: '15px' }} />
          </span>}

          {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
          <span style={{ fontWeight: 'bold', color: 'black', position: 'absolute', right: '2px' }}>
            {getDifference()}
          </span>
        </span>
      </div >
    </div></div >;
}

const mapStateToProps = (state: IApplicationState) => ({
  selectedId: activitySchedulerSelectors.selectedId(state),
  selectedActivityScheduleId: activitySchedulerSelectors.selectedActivityScheduleId(state),
  hoverSelectedActivityScheduleId: activitySchedulerSelectors.hoverSelectedActivityScheduleId(state),
  openScheduleActivityOptions: activitySchedulerSelectors.openScheduleActivityOptions(state),
  isHover: activitySchedulerSelectors.isHover(state),
  hoverSelectedActivityOptionsId: activitySchedulerSelectors.hoverSelectedActivityOptionsId(state)

});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    adjustActivitySchedule: activitySchedulerActions.adjustActivitySchedule,
    deleteActivitySchedule: activitySchedulerActions.deleteActivitySchedule,
    scheduleActivityOptionsModalToggle: activitySchedulerActions.scheduleActivityOptionsModalToggle,
    hoverToggle: activitySchedulerActions.hoverToggle,
    setHoverSelectedActivitySchedule: activitySchedulerActions.setHoverSelectedActivitySchedule,
    setHoverSelected: activitySchedulerActions.setHoverSelected,
    setHoverSelectedOptions: activitySchedulerActions.setHoverSelectedOptions
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SchedulerEvent));
