import * as React from 'react';
import FormView from '../../FormView';
import * as SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from '../../common/SummaryTable';
import { withRouter } from 'react-router-dom';
import { ISupplierEnquiryFinancialProps } from '../interfaces';

class Financial extends React.PureComponent<ISupplierEnquiryFinancialProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-enquiry/financial/balances':
          onInitialLoad('SupplierFinancialBalances');
          break;
        case '/supplier-enquiry/financial/transactions':
          onInitialLoad('Transactions');
          break;
        case '/supplier-enquiry/financial/transactions/transaction-details':
          onInitialLoad('TransactionDetails');
          break;
        case '/supplier-enquiry/financial/transactions/transaction-details/transactions':
          onInitialLoad('DetailsTransactions');
          break;
        case '/supplier-enquiry/financial/credit-diary':
          onInitialLoad('CreditDiary');
          break;
        case '/supplier-enquiry/financial/credit-diary/diary-details':
          onInitialLoad('CreditDiaryDetails');
          break;
        case '/supplier-enquiry/financial/child-accounts':
          onInitialLoad('SupplierFinancialChildAccounts');
          break;
        default:
          onInitialLoad('SupplierFinancialBalances');
      }
    }
  }

  getSummaryTableRenderer = (selectedTab: string, loadingTransactionSummary: boolean) => {
    switch (selectedTab) {
      case 'TransactionDetails':
      case 'DetailsTransactions':
        return () => this.getTransactionSummary(loadingTransactionSummary);
      default: return null;
    }
  }

  getTransactionSummary = (loadingTransactionSummary: boolean) => {
    const { transactionSummary } = this.props;

    return <SummaryTable
      loadingTransactionSummary={loadingTransactionSummary}
      selectedTransaction={transactionSummary ? {
        TransactionNumber: transactionSummary.TransactionNumber && transactionSummary.TransactionNumber.Value,
        TransactionDate: transactionSummary.TransactionDate && transactionSummary.TransactionDate.Value,
        FinancialPeriod: transactionSummary.Period && transactionSummary.Period.Value,
        Age: transactionSummary.Age && transactionSummary.Age.Value,
        Due: transactionSummary.Due && transactionSummary.Due.Value,
        TransactionStatus: transactionSummary.TransactionStatus && transactionSummary.TransactionStatus.Value,
        TransactionType: transactionSummary.TransactionType && transactionSummary.TransactionType.Value,
        SourceDocument: transactionSummary.SourceDocument && transactionSummary.SourceDocument.Value,
        SourceDocumentNumber: transactionSummary.SourceDocumentNumber && transactionSummary.SourceDocumentNumber.Value,
        Currency: transactionSummary.Currency && transactionSummary.Currency.Value,
        OriginalTotal: transactionSummary.OriginalTotal && transactionSummary.OriginalTotal.Display,
        OriginalAmount: transactionSummary.Amount && transactionSummary.Amount.Display,
        DiscountAmount: transactionSummary.DiscountAmount && transactionSummary.DiscountAmount.Display,
        ClaimDiscount: transactionSummary.ClaimDiscount && transactionSummary.ClaimDiscount.Display,
        Released: transactionSummary.Released && transactionSummary.Released.Display
      }
        :
        {}
      }
      data={
        this.getTransactionSummaryModifiedSchema(transactionSummary)
      } />;
  }

  getTransactionSummaryModifiedSchema = (transactionSummary: any): any => {
    const schema: any = SummaryTableSchema.Transactions;
    if (transactionSummary) {
      const { TransactionType } = transactionSummary;
      if (TransactionType && TransactionType.Value) {
        let api = '';
        let paramName = '';
        switch (TransactionType.Value) {
          case 'Cheque Payment': api = ''; break;
          case 'Invoice': api = '/supplierInvoiceEnquiry/lookup/action'; paramName = 'DocumentNumber'; break;
          case 'Credit Request': api = '/rfcEnquiry/lookup/action'; paramName = 'RFCNumber'; break;
          case 'Debit Journal': api = '/rfcEnquiry/lookup/action'; paramName = 'RFCNumber'; break;
          case 'Credit Note': api = '/supplierInvoiceEnquiry/lookup/action'; paramName = 'DocumentNumber'; break;
          case 'Invoice Sundry': api = '/supplierInvoiceEnquiry/lookup/action'; paramName = 'DocumentNumber'; break;
          case 'Cheq Paymnt CR': api = ''; break;
          default: api = '';
        }
        schema[1].children[1].valueRenderer = {
          api: {
            api,
            name: 'AllocatedInvoice',
            params: {
              queryFields: {
                [paramName]: 'SourceDocumentNumber'
              }
            }
          }
        };
      }
    }

    return schema;
  }

  getInitialValues = (): any => {
    const {
      selectedTab, balanceDetails, balanceDetailsSchema, transactionDetails, loadingTransactionSummary, selectedSupplier
    } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const supplier = (selectedSupplier && selectedSupplier.Supplier) || params.get('Supplier');
    switch (selectedTab) {
      case 'SupplierFinancialBalances':
        return balanceDetails ?
          {
            initialValues: balanceDetails,
            valuesSchema: {
              ...balanceDetailsSchema,
              LastPurchase: { ...balanceDetailsSchema.LastPurchase, Label: balanceDetailsSchema.LastPurchaseDate.Value },
              LastPayment: { ...balanceDetailsSchema.LastPayment, Label: balanceDetailsSchema.LastPaymentDate.Value }
            }
          }
          : null;
      case 'TransactionDetails':
        return transactionDetails && !loadingTransactionSummary && { initialValues: transactionDetails, valuesSchema: transactionDetails.schema };
      case 'CreditDiary':
      case 'CreditDiaryDetails':
        return { initialValues: { params: supplier && { Supplier: parseInt(supplier) }, doubleClickActionTab: 'CreditDiaryDetails' }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, formName, loadingTransactionSummary } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={(selectedTab === 'CreditDiary' && 'Diary')
            || (selectedTab === 'CreditDiaryDetails' && 'DiaryDetails')
            || formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab, loadingTransactionSummary)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Financial);
