import { createReducer } from 'utils/helpers';
import { ExtractMaintenanceActionTypes, IActions } from './ExtractMaintenance.actions';
import { IReportingAreaFacade } from 'api/swaggerTypes';

export interface IState {
    TemplateId: number;
    focusedArea: IReportingAreaFacade;
    isFocusedAreaNameSelected: boolean;
    isFieldsInEdit: boolean;
    focusedFieldNames: {
        FieldDataType: string;
        FieldLabel: string;
        FieldName: string;
        FieldType: string;
        Source: string;
    }[];
    resetfocusedFieldNames: boolean;
}

export const initialState: IState = {
    TemplateId: 0,
    focusedArea: null,
    isFocusedAreaNameSelected: false,
    isFieldsInEdit: false,
    focusedFieldNames: [],
    resetfocusedFieldNames: false,
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<ExtractMaintenanceActionTypes, IAction>;

const actionMap: IActionMap = {
    [ExtractMaintenanceActionTypes.setTemplateId]: (state: IState, payload: IState['TemplateId']): IState => ({ ...state, TemplateId: payload }),
    [ExtractMaintenanceActionTypes.setFocusedArea]: (state: IState, payload: IState['focusedArea']): IState => ({ ...state, focusedArea: payload }),
    [ExtractMaintenanceActionTypes.setIsFocusedAreaNameSelected]: (state: IState, payload: IState['isFocusedAreaNameSelected']): IState => ({ ...state, isFocusedAreaNameSelected: payload }),
    [ExtractMaintenanceActionTypes.setIsFieldsInEdit]: (state: IState, payload: IState['isFieldsInEdit']): IState => ({ ...state, isFieldsInEdit: payload }),
    [ExtractMaintenanceActionTypes.setFocusedFieldNames]: (state: IState, payload: IState['focusedFieldNames']): IState => ({ ...state, focusedFieldNames: payload }),
    [ExtractMaintenanceActionTypes.setFocusedFieldNamesempty]: (state: IState, payload: IState['resetfocusedFieldNames']): IState => ({ ...state, resetfocusedFieldNames: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
