import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Icon from '@markinson/uicomponents-v2/Icon';
import { IAddIconRendererProperties } from '../interfaces';

const spacingMultiplier = 2;

const styles = (theme: Theme) => createStyles({
  paper: {
    marginRight: theme.spacing.unit * spacingMultiplier
  },
  popperClose: {
    pointerEvents: 'none',
  },
  iconStyle: {
    cursor: 'pointer',
    marginLeft: '-5px',
    marginTop: '12px'
  }
});

class AddIconRenderer extends React.Component<IAddIconRendererProperties> {
  constructor(props: any) {
    super(props);
    props.reactContainer.style.display = 'inline-flex';
    props.reactContainer.style.alignItems = 'center';
    props.reactContainer.style.height = '80%';
    props.reactContainer.style.width = '80%';
  }
  render(): React.ReactNode {
    const { classes } = this.props;

    return (
      <Icon iconName='Add' className={classes.iconStyle} />
    );
  }
}

export default withStyles(styles, {index: 1})(AddIconRenderer);
