import Details from 'utils/data/forms/price_check/details'
import Why from 'utils/data/forms/price_check/why'
import Prices from 'utils/data/forms/price_check/prices'
import PCPricesByWH from 'utils/data/forms/price_check/pricesByWH'
import SalesHistory from 'utils/data/forms/price_check/salesHistory'
import Matrix from 'utils/data/forms/price_check/matrix'
import Categories from 'utils/data/forms/price_check/categories'
import trueCost from 'utils/data/forms/price_check/trueCost'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'Details':
      return Details
    case 'Why':
      return Why
    case 'Prices':
      return Prices
    case 'PricesByWarehouse':
      return PCPricesByWH
    case 'SalesHistory':
      return SalesHistory
    case 'Matrix':
      return Matrix
    case 'Categories':
      return Categories
    case 'TrueCost':
      return trueCost
    default:
      return Details
  }
}

export default getFormSchema
