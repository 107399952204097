import { fetchPost, fetchGet, objectify } from '../utils'

export const search = (query) => {
  const { SearchText, CustomerId, Sort } = query
  return fetchPost(`/CustomerEnquiry/Customer/${CustomerId}/SearchProducts`, { SearchText, Sort }, null, 'Inline')
    .then((something) => {
      if (something && something.CustomerProducts) {
        return something.CustomerProducts.map((val) => {
          const dataObject = val;
          const labeledDataObject = {
            ...dataObject,
            CustomerProduct: {
              ...dataObject.CustomerProduct,
              Label: dataObject.CustomerProductDescription.Value,
            }
          };
          return Object.assign(
            {}, { inlineObject: objectify(labeledDataObject), schema: labeledDataObject });
        })
      }
    })
}

export const searchById = ({ CustomerId, CustomerProduct }) => {
  return fetchGet(`/CustomerEnquiry/Customer/${CustomerId}/Product/${CustomerProduct}`, null, 'Inline')
    .then((result) => (result && result.Product && [{
      inlineObject: objectify({
        ...result.Product,
        CustomerProduct: {
          ...result.Product.CustomerProduct,
          Label: result.Product.CustomerDescription.Value,
        },
      }),
      schema: {
        ...result.Product,
        CustomerProduct: {
          ...result.Product.CustomerProduct,
          Label: result.Product.CustomerDescription.Value,
        }
      },
    }]) || [] )
}


export const fetchDetail = (query) => {
  const { prodCode } = query
  const { CustomerId } = query
  return fetchGet(`/CustomerEnquiry/Customer/${CustomerId}/Product/${prodCode}`, null, 'Inline')
    .then((something) => something.Product.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })))
}