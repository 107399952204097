import {
  createActions, asyncInitialState,
  asyncSelectors, dateComparator
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'picking');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 150,
      suppressSorting: true,
      type: 'numericColumn',
      hyperlinkParamGetter: (row) => {
        if (row.data.InvoiceNumber && row.data.SalesEntity) {
          return { SalesEntity: row.data.SalesEntity, InvoiceNumber: row.data.InvoiceNumber, FieldValue: row.data.InvoiceNumber };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Line', field: 'LineNo', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Sales entity', field: 'SalesEntity', minWidth: 100, suppressSorting: true, },
    { headerName: 'Entered', field: 'EnteredDate', comparator: dateComparator, type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Backordered', field: 'BackorderQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    {
      headerName: 'Customer',
      field: 'CustomerIdDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data.CustomerId && row.data.CustomerIdDisplay) {
          return {
            CustomerId: row.data.CustomerId,
            CustomerAccountNumber: row.data.CustomerIdDisplay,
            FieldValue: row.data.CustomerIdDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 150, suppressSorting: true, },
    { headerName: 'Customer name', field: 'CustomerIdLabel', minWidth: 250, suppressSorting: true, },
    { headerName: 'Sales entity name', field: 'SalesEntityName', minWidth: 250, suppressSorting: true, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 100, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', minWidth: 250, suppressSorting: true, }
  ],
  Data: [],
  selectedFilters: {
    SalesEntity: '',
    CustomerId: 0,
  },
  filterRow: {
    formName: 'IEStockingQuantitiesPickingFilters',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Sales entity',
          name: 'SalesEntity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'medium',
          required: false
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.picking,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.picking.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.picking.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.picking.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.picking.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.picking.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)