import { IObjectified, Inline } from './utils';
import { IDocumentOutputOptionsFacade, IDocumentOutputOptionsDetailsResponse, INotificationFacade } from './swaggerTypes';
import { Api, toObjectified } from './baseApi';
import { Payload, usePost } from './reactQuery/queryService';
import { APIRoutes } from './constants';

export type ObjectifiedDocumentOutputOptionsDetailsResponse = Omit<IDocumentOutputOptionsDetailsResponse, 'DocumentOutputOptions'> & {
  DocumentOutputOptions: IObjectified<IDocumentOutputOptionsFacade>;
};

export interface IDocumentOutputOptions {
  Invoice?: number;
  SalesEntity?: string;
  DespatchId?: number;
  TTDocOut?: IDocumentOutputOptionsFacade;
}

export async function updateOptions(options: IDocumentOutputOptions, SendDocument: boolean): Promise<ObjectifiedDocumentOutputOptionsDetailsResponse> {
  const params = new URLSearchParams('');
  params.set('SendDocument', SendDocument ? 'True' : 'False');

  return Api.postInlineCollectionResponse<IDocumentOutputOptions, IDocumentOutputOptionsDetailsResponse, 'DocumentOutputOptions'>(
    `DocumentOutput/ApplyDocumentOutputOptions?${params.toString()}`, options)
    .then((response) => ({
      ...response,
      DocumentOutputOptions: response.DocumentOutputOptions ? toObjectified(response.DocumentOutputOptions) : undefined
    }));
}

const DocumentOutputOptionsDetailsResponseParser = (response) => {
  const { DocumentOutputOptions } = response;

  return {
    ...response,
    DocumentOutputOptions: DocumentOutputOptions ? toObjectified(DocumentOutputOptions as Inline<IDocumentOutputOptionsFacade>) : null
  };
};

export const useSendNotification = () =>
  usePost<Payload<INotificationFacade>, ObjectifiedDocumentOutputOptionsDetailsResponse>(
    APIRoutes.DocumentOutput.Notification.Send,
    null,
    { inline: true, responseParser: DocumentOutputOptionsDetailsResponseParser }
  );
