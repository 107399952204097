import * as React from 'react';
import DataGrid, { Column, Editing, Scrolling, GroupPanel, Sorting, SearchPanel, LoadPanel, Button, HeaderFilter, Paging } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import classNames from 'classnames';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IKitComponentsLinesProps } from './KitComponentsLines.properties';
import styles from './KitComponentsLines.styles';
import { COLUMN_SIZE, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import ProductCell from 'components/common/ProductCell';
import { ICalculateComponentDetailsFacade, IWIPComponentFacade } from 'api/swaggerTypes';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';

const NumberOfDecimalPlaces = 2;

function KitComponentsLines(props: Readonly<IKitComponentsLinesProps>): JSX.Element {
    const {
        componentLinesDataSource, classes, className, style, worksaleLineId, disableSuppliedQuantityEditing,
        showDetailOnClick, onDelete, onComponentLineFieldChanged, onEditChange
    } = props;
    const { preventDataGridDefaults } = useGridKeyboardNavigation();
    const dataGridRef = React.useRef<DataGrid>();

    async function handleSetCellValue<K extends keyof IWIPComponentFacade>(prevData: IWIPComponentFacade, newData: IWIPComponentFacade, field: K, value: any): Promise<void> {
        if (onComponentLineFieldChanged) {
            const mergedData = { ...prevData, ...newData };
            mergedData[field] = value;
            const response = await onComponentLineFieldChanged(worksaleLineId, mergedData.ComponentId, mergedData, field as keyof ICalculateComponentDetailsFacade);
            const lineData = response ? response : mergedData;
            newData.RequiredQuantity = lineData?.RequiredQuantity;
            newData.SuppliedQuantity = lineData?.SuppliedQuantity;
            newData.Description = lineData?.Description;
            newData.ProductId = lineData?.ProductId;
            newData.OnOrderQuantity = lineData?.OnOrderQuantity;
        }
    }

    async function deleteClick(e: any): Promise<void> {
        if (onDelete) {
            onDelete(Number(e.row.key));
        }
    }

    const onEditorPreparing = React.useCallback(
        (e) => {
            e.editorOptions.onFocusIn = (args) => {
                const input = args.element.querySelector("input[type='text']");
                if (input) {
                    input.select();
                }
            };
            if (e.dataField === 'SuppliedQuantity') {
                e.editorOptions.disabled = disableSuppliedQuantityEditing || e.row?.data?.SuppliedQuantityReadOnly;
            }
        },
        [disableSuppliedQuantityEditing]
    );

    const onContentReady = React.useCallback(
        (e) => {
            const isEditing = e.element.querySelector("input[type='text']") != null;
            onEditChange(isEditing);
        },
        [onEditChange]
    );

    const detailCellComponent = React.useCallback(
        (allProps) => {
            return <MoreIcon className={classes.lineDetailIcon} onClick={() => {
                if (showDetailOnClick) {
                    showDetailOnClick(allProps.data ? allProps.data.data : null);
                }
            }} />;
        },
        [classes, showDetailOnClick]
    );

    return (
        <Paper className={classNames(classes.parentContainer, className)} style={style}>
            <DataGrid
                className={classes.worksaleLineDataGrid}
                onKeyDown={preventDataGridDefaults}
                dataSource={componentLinesDataSource}
                showBorders={false}
                renderAsync={true}
                repaintChangesOnly={true}
                allowColumnReordering={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                noDataText=''
                ref={dataGridRef}
                onEditorPreparing={onEditorPreparing}
                onContentReady={onContentReady}
                onEditCanceled={() => { onEditChange(false); }}
            >
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={true}
                    confirmDelete={false}
                />
                <HeaderFilter
                    visible={true}
                />
                <Column
                    allowEditing={false}
                    minWidth={COLUMN_SIZE.sm0}
                    width={COLUMN_SIZE.sm1}
                    allowResizing={false}
                    fixed={true}
                    alignment='center'
                    allowHeaderFiltering={false}
                    cellComponent={detailCellComponent}
                />
                <Column
                    dataField={'ProductIdDisplay'}
                    caption={'Product'}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    fixed={true}
                    cellComponent={(allProps) => (
                        <ProductCell
                            urlParamKeys={['ProductId']}
                            toLink={'/inventory-enquiry/product-details'}
                            {...allProps} />
                    )}
                />
                <Column
                    dataField={'ProductGroup'}
                    caption={'Group'}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }} />
                <Column
                    dataField={'RequiredQuantity'}
                    caption={'Required'}
                    allowHeaderFiltering={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }}
                    setCellValue={async (newData: ICalculateComponentDetailsFacade, value: number, prevData: ICalculateComponentDetailsFacade): Promise<void> => {
                        await handleSetCellValue(prevData, newData, 'RequiredQuantity', value);
                    }}
                    calculateDisplayValue={(data: Record<string, unknown>) => (data?.RequiredQuantity as number)?.toFixed(NumberOfDecimalPlaces)} />
                <Column
                    dataField={'SuppliedQuantity'}
                    caption={'Supplied'}
                    allowHeaderFiltering={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }}
                    setCellValue={async (newData: ICalculateComponentDetailsFacade, value: number, prevData: ICalculateComponentDetailsFacade): Promise<void> => {
                        await handleSetCellValue(prevData, newData, 'SuppliedQuantity', value);
                    }}
                    calculateDisplayValue={(data: Record<string, unknown>) => (data?.SuppliedQuantity as number)?.toFixed(NumberOfDecimalPlaces)} />
                <Column
                    dataField={'Description'}
                    caption={'Description'}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    editorOptions={{
                        disabled: true,
                        maxLength: 40
                    }}
                    minWidth={COLUMN_SIZE.lg1} />
                <Column dataField={'OnOrderQuantity'} visible={false} />
                <Column type={'buttons'} width={COLUMN_SIZE.sm1} allowResizing={false} fixed={true}>
                    <Button name={'save'} icon={'save'} />
                    <Button name={'edit'} icon={'edit'} />
                    <Button name={'delete'} icon={'trash'} onClick={deleteClick} />
                    <Button name={'cancel'} icon={'undo'} />
                    <Button name={'undelete'} icon={'undo'} />
                </Column>
                <Sorting mode='single' />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel enabled={false} />
            </DataGrid>
        </Paper >
    );
}

export default withStyles(styles, { index: 1 })(React.memo(KitComponentsLines));
