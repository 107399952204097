import { withStyles } from '@material-ui/core/styles';
import styles from './ProductCatalogue.styles';
import { IProductCatalogueProps } from './ProductCatalogue.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import React, { useCallback } from 'react';
import { AddCircle as AddSalesLineIcon, SearchCircle, CircleFormatListBulleted } from '@markinson/uicomponents-v2/SvgIcons/';
import EntriesView from './EntriesView';
import {
    useRetrieveEntries,
    useRetrieveEntryImages,
    useRetrieveImage,
    useRetrieveCatalogueProducts,
    useRetrieveCatalogueProductImages,
    useSearchCatalogueEntries,
    useFetchInitialCatalogueConfig,
    useCalculateFastLineDetail,
    useCreateFastLine,
    useCurrentWorksale
} from 'api/Worksale/catalogue';
import {
    ICalculateFastLineCriteriaFacade,
    ICatalogueEntryFacade,
    ICatalogueSearchEntryFacade,
    ICreateFastLineFacade,
    IImageDetailFacade
} from 'api/swaggerTypes';
import { CollapsibleBreadcrumbBar } from '@markinson/uicomponents-v2';
import reducer, { initialState } from './ProductCatalogue.reducer';
import { moduleTree } from '@markinson/uicomponents-v2/CollapsibleBreadcrumbBar/CollapsibleBreadcrumbBar.properties';
import { DefaultBreadCrumbItem } from './constants';
import AddToSale from './AddToSale';
import ProductGrid, { IProductGridHandle } from './ProductGrid';
import SearchEntryModal from './SearchForEntryModal';
import PriceOverrideAuthorityDialog from '../../common/PriceOverrideAuthorityDialog';
import BOMDialog from '../../common/BOMDialog';
import { usePriceOverrideAndKitValidations } from '../Worksale.hooks';
import { IPriceOverrideData } from '../../common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { IAddToSaleHandle } from './AddToSale/AddToSale.properties';
import { Route } from 'react-router';
import CatalogueProductDetails from './CatalogueProductDetails/index';
import { ICatalogueProductDetailsHandle } from './CatalogueProductDetails/CatalogueProductDetails.properties';
import EntryDetails from './EntryDetails';
import { IEntryDetailsHandle } from './EntryDetails/EntryDetails.properties';
import { ITreeListHandle } from 'components/common/TreeList/TreeList.properties';
import { DEFAULT_BREAD_CRUMBS } from '../constants';
import { isNull } from 'utils/utils';
import { useValidateProduct } from 'api/Worksale/worksaleLatest';
import { isScreenRequest } from 'api/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { TAB } from 'utils/constants';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';

const ProductCatalogue = (props: IProductCatalogueProps) => {
    const { classes, forwardedRef, match, changeSelectedTab, onSaleLinesAdded } = props;

    const worksaleId = useSPContextSelector(Selectors.WorksaleId);

    const productCatalogueConfig = useFetchInitialCatalogueConfig(worksaleId);
    const DefaultBreadCrumbData = { id: '0', label: productCatalogueConfig?.data?.CatalogueDescription || DefaultBreadCrumbItem.label };
    const CatalogueInitialState = { ...initialState, breadCrumbData: [DefaultBreadCrumbData] };

    const [productCatalogueState, productCatalogueDispatch] = React.useReducer(reducer, CatalogueInitialState);
    const productGridRef = React.useRef<IProductGridHandle>(null);
    const addSaleRef = React.useRef<IAddToSaleHandle>(null);
    // Please add the disabled conditions for the bottom navigation. It can be used for the keyboard navigation as well
    const SearchDisabled = false;
    const EntryDetailDisabled = false;
    const { setActionBar } = React.useContext(ActionBarContext);

    const {
        multipleBomOpen,
        bomList,
        kitStatus,
        priceOverrideDialogOpen,
        priceOverrideScreenType,
        priceOverrideDetailSummary,
        selectedBomCode,
        validate,
        resetStates: resetPriceOverrideBomStates,
        isPriceOverrideScreenRequest,
        setKitStatus,
        setSelectedBomCode,
        setMultipleBomState,
        setPriceOverrideDialogOpen,
        setPriceOverrideDetailSummary
    } = usePriceOverrideAndKitValidations();
    const entriesFetchMutation = useRetrieveEntries();
    const entriesSearchFetchMutation = useSearchCatalogueEntries();
    const entryImagesFetchMutation = useRetrieveEntryImages();
    const productImagesFetchMutation = useRetrieveCatalogueProductImages();
    const entryProductsFetchMutation = useRetrieveCatalogueProducts();
    const imageFetchMutation = useRetrieveImage();
    const calculateFastLineDetailMutation = useCalculateFastLineDetail();
    const createFastLineMutation = useCreateFastLine();
    const { isFetched: isWorksaleDataFetched, data: worksaleData } = useCurrentWorksale();
    const validateProductMutation = useValidateProduct();

    const selectedProduct = productCatalogueState?.selectedProduct;
    const selectedCatalogueId = productCatalogueState?.selectedCatalogueId;

    const fastLineInlineData = calculateFastLineDetailMutation.data?.FastLineDetails;
    const catalogueProductDetailsRef = React.useRef<ICatalogueProductDetailsHandle>(null);
    const catalogueEntryDetailsRef = React.useRef<IEntryDetailsHandle>(null);
    const treeListRef = React.useRef<ITreeListHandle>(null);

    const isPathProductCatalogue = React.useMemo(() => match?.url === '/sales-processing/product-catalogue', [match?.url]);
    const isPathProductCatalogueProductDetails = React.useMemo(() => match?.url === '/sales-processing/product-catalogue/catalogue-product-details', [match?.url]);

    function handleSaleLinesChanged(): void {
        if (onSaleLinesAdded) {
            onSaleLinesAdded();
        }
    }

    React.useEffect(
        () => {
            if (productCatalogueState?.addToSaleLineClicked) {
                handleAddToLineOpen();
                productCatalogueDispatch({ setAddToSaleLineClicked: false });
            }
        },
        [productCatalogueState?.addToSaleLineClicked]
    );

    React.useEffect(
        () => {
            if (isWorksaleDataFetched) {
                if (worksaleData?.WorksaleId) {
                    productGridRef.current?.reload();
                } else {
                    navigateBack();
                }
            }
        },
        [isWorksaleDataFetched]
    );

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            search(): void {
                if (!SearchDisabled) {
                    productCatalogueDispatch({ setIsSearchOpen: true });
                }
            },
            entryDetail(): void {
                if (!EntryDetailDisabled) {
                    changeSelectedTab('CatalogueEntryDetails');
                }
            },
            add(): void {
                resetPriceOverrideBomStates();
                //setting clicked flag as rest don't reset states immediately. May need to rethink and refactor it in future.
                productCatalogueDispatch({ setAddToSaleLineClicked: true });
            },
            onCancel(selectedTab: string): void {
                switch (selectedTab) {
                    case 'ProductCatalogue':
                        navigateBack();
                        break;
                    case 'CatalogueProductDetails':
                        catalogueProductDetailsRef.current.onCancel();
                        break;
                    case 'CatalogueEntryDetails':
                        catalogueEntryDetailsRef.current.onCancel();
                        break;
                    default:
                        navigateBack();
                }
            }
        })
    );

    const leftIcons = [BACK_BUTTON];

    const centerIcons = [
        {
            label: 'Search',
            Icon: SearchCircle,
            action: 'Search',
            disabled: SearchDisabled
        },
        {
            label: 'Entry Detail',
            Icon: CircleFormatListBulleted,
            action: 'Entry_Detail',
            disabled: EntryDetailDisabled
        }
    ];

    const rightIcons = [
        {
            label: 'Add Line',
            Icon: AddSalesLineIcon,
            action: 'AddToSalesLines',
            disabled: !Boolean(selectedProduct)
        }
    ];

    const handleKeyPress = (event) => {
        if (isPathProductCatalogue || isPathProductCatalogueProductDetails) {
            if (event.key === 'Insert') {
                event.preventDefault();
                handleAddToLineOpen();
            }
        }
        if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            if (!EntryDetailDisabled) {
                changeSelectedTab('CatalogueEntryDetails');
            }
        }
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            if (!SearchDisabled) {
                productCatalogueDispatch({ setIsSearchOpen: true });
            }
        }

        if (event.ctrlKey && event.key === 'Home') {
            event.preventDefault();
            if (window.location.pathname.split('/').pop() === 'product-catalogue') {
                treeListRef.current.selectDefault();
            }
        }

    };

    React.useEffect(
        () => {
            // attach the event listener
            document.addEventListener('keydown', handleKeyPress);

            // remove the event listener
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        },
        [handleKeyPress]
    );

    React.useEffect(
        () => {
            if (isPathProductCatalogue) {
                setActionBarButtons();
            }
        },
        [selectedProduct, isPathProductCatalogue]
    );

    const handleAddToLineOpen = async () => {
        if (!Boolean(selectedProduct)) {
            return;
        }

        const validationResponse = await validateProductMutation.mutateAsync({
            CustomerId: worksaleData?.Summary?.SaleHeader?.CustomerId,
            ProductId: selectedProduct.ProductId,
            SalesEntity: worksaleData?.Summary?.SaleHeader?.SalesEntity,
            WarehouseEntity: worksaleData?.Summary?.SaleHeader?.WarehouseEntity
        });

        if (!validationResponse?.Status) {
            return;
        }

        if (!Boolean(selectedProduct?.ProductId)) {
            notifyProductFailedToAdd();

            return;
        }

        if (productCatalogueConfig.data?.AllowCataloguePriceQuantityAdjustment) {
            productCatalogueDispatch({ setAddToSaleOpen: true });
        } else {
            await addLineWOPriceQuantityAdjustment();
        }
    };

    const addLineWOPriceQuantityAdjustment = async (status?: string) => {
        const response = await calculateAndValidateFastLine({
            ProductId: selectedProduct?.ProductId,
            OrderedQuantity: selectedProduct?.Quantity,
            KitStatus: status
        });

        if ((response?.FastLineDetails?.BomCode &&
            isNull(response?.FastLineDetails?.KitStatus)) || isScreenRequest(response.Forms)) {
            return;
        }

        createAndValidateFastLine({
            ...response?.FastLineDetails,
            OrderedQuantity: response?.FastLineDetails?.OrderedQuantity || 1,
            DiscountedPrice: selectedProduct?.Price,
            BomCode: selectedBomCode,
            KitStatus: status
        });
    };

    const handleAddToLineClose = () => {
        productCatalogueDispatch({ setAddToSaleOpen: false });
    };

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons,
            centerIcons,
            rightIcons
        });
    };

    const loadChildren = useCallback(
        (parentId: any): Promise<any> =>
            entriesFetchMutation.mutateAsync({ CatalogueId: parentId || 0 })
                .then((response) => response?.catalogueEntries ?? [])
                .catch((err) => console.log(err)),
        []
    );

    const makeBreadCrumbData = (selectedItem: any, treeModules: moduleTree[] = []): moduleTree[] => {
        if (!selectedItem.key) {
            treeModules.push(productCatalogueState.breadCrumbData[0]);

            return treeModules;
        }

        makeBreadCrumbData(selectedItem.parent, treeModules);
        treeModules.push({ id: selectedItem?.key, label: selectedItem?.data?.Description });

        return treeModules;

    };

    const getListOfImages = useCallback(
        (catalogueId: number) =>
            entryImagesFetchMutation.mutateAsync({ CatalogueId: catalogueId })
                .then((response) => response.images)
                .catch((err) => console.log(err)),
        []
    );

    const getCatalogueProductImages = useCallback(
        (catalogueProductId: number) =>
            productImagesFetchMutation.mutateAsync({ CatalogueProductId: catalogueProductId })
                .then((response) => response.images)
                .catch((err) => console.log(err)),
        []
    );

    const handleLoadImage = useCallback(
        (d: IImageDetailFacade) =>
            imageFetchMutation.mutateAsync({
                ImageId: d?.ImageObj ?? 0,
                urlQueryParams: { Filename: d?.Filename ?? '' }
            }),
        []
    );

    const loadProducts = useCallback(
        (WorksaleId: number, catalogueId: number, BatchPage?: number, BatchSize?: number) =>
            (Boolean(worksaleId) && Boolean(catalogueId)) ? entryProductsFetchMutation.mutateAsync({
                WorksaleId,
                CatalogueId: catalogueId,
                urlQueryParams: {
                    BatchPage,
                    BatchSize
                }
            }) : Promise.resolve(null),
        []
    );

    const handleSearchEntries = useCallback(
        (search: any, catalogueID: number) =>
            entriesSearchFetchMutation.mutateAsync({
                CatalogueId: catalogueID ?? 0,
                SearchAll: search.searchAll,
                SearchText: search.searchText ?? '',
                BatchPage: search.BatchPage ?? null,
                BatchSize: search.BatchSize ?? null
            }),
        []
    );

    const handleOnEntrySelectionChanged = useCallback(
        (selectedItem) => {
            productCatalogueDispatch({ setSelectedProduct: null, setSelectedCatalogueId: selectedItem?.key });

            const data = makeBreadCrumbData(selectedItem);

            productCatalogueDispatch({ setBreadCrumbData: data });
        },
        [selectedProduct]
    );

    const handleOnBreadcrumbClick = useCallback(
        (key) => {
            if (treeListRef.current) {
                if (Number(key) === 0) {
                    treeListRef.current.unselectAll();
                    productCatalogueDispatch({
                        setBreadCrumbData: [DefaultBreadCrumbData],
                        setSelectedProduct: null,
                        setSelectedCatalogueId: null
                    });
                    treeListRef.current.collapseAll();
                } else {
                    treeListRef.current.selectItem(key);
                    treeListRef.current.collapseSelectedNodeChildren(key);
                    treeListRef.current.scrollToItem(key);
                }
            }
        },
        []
    );

    const handleOnSearchEntries = async (item: ICatalogueSearchEntryFacade) => {
        if (item && item.Parents) {
            productCatalogueDispatch({ setIsSearchOpen: false });
            treeListRef.current.collapseAll();
            if (item.Parents.length > 0) {
                treeListRef.current.searchItem(item.Parents, item.CatalogueId);
            } else {
                treeListRef.current.selectItem(item.CatalogueId);
            }
        }
    };

    const handleProductSelectionChanged = useCallback(
        (product) => {
            productCatalogueDispatch({ setSelectedProduct: product });
        },
        []
    );

    const calculateAndValidateFastLine = useCallback(
        (query: ICalculateFastLineCriteriaFacade) => calculateFastLineDetailMutation.mutateAsync({
            WorksaleId: worksaleData?.WorksaleId,
            BomCode: selectedBomCode,
            KitStatus: kitStatus,
            ...query,
            CatalogueProduct: true,
        })
            .then((response) => {
                validate(null, {
                    Forms: response.Forms,
                    BomCode: selectedBomCode,
                    KitDetail: response.FastLineDetails?.KitDetail,
                    KitStatus: response.FastLineDetails?.KitStatus,
                    OverrideDetails: response.FastLineDetails,
                });
                if (response?.FastLineDetails?.BomCode) {
                    setSelectedBomCode(response?.FastLineDetails?.BomCode);
                }
                if (response?.FastLineDetails?.KitStatus) {
                    setKitStatus(response?.FastLineDetails?.KitStatus);
                }

                return response;
            }),
        [selectedProduct, worksaleData?.WorksaleId, selectedBomCode, kitStatus]
    );

    const createAndValidateFastLine = useCallback(
        (query: ICreateFastLineFacade) => createFastLineMutation.mutateAsync({
            ...fastLineInlineData,
            ...query,
            WorksaleId: worksaleData?.WorksaleId,
            ProductId: selectedProduct?.ProductId,
            CatalogueProduct: true
        }).then((response) => {
            validate(null, {
                Forms: response.Forms,
                BomCode: selectedBomCode,
                KitDetail: response.GridLineDetails?.inlineObject?.KitDetail,
                KitStatus: response.GridLineDetails?.inlineObject?.KitStatus,
                OverrideDetails: response.GridLineDetails?.inlineObject?.OverrideDetails,
            });

            if (response.Status && !response.Forms) {
                setPriceOverrideDialogOpen(false);
                notifyProductAdded();
            }

            if (!response.Status) {
                notifyProductFailedToAdd();
            }

            if (!isPriceOverrideScreenRequest(response.Forms?.[0])) {
                productGridRef.current?.reloadLines();
            }

            handleSaleLinesChanged();

            return response;
        }),
        [selectedProduct, worksaleData?.WorksaleId, selectedBomCode, productGridRef.current, kitStatus, calculateFastLineDetailMutation.data]
    );

    const notifyProductAdded = () => {
        showSnackBar({
            variant: 'success',
            message: 'Product added successfully'
        });
    };

    const notifyProductFailedToAdd = () => {
        showSnackBar({
            variant: 'error',
            message: 'Failed to add product'
        });
    };

    const handlePriceOverrideOk = useCallback(
        async (priceOverrideData: IPriceOverrideData) => {
            try {
                const response = await createAndValidateFastLine({
                    ...createFastLineMutation.data?.GridLineDetails.inlineObject,
                    BomCode: selectedBomCode,
                    KitStatus: kitStatus,
                    DiscountedPrice: createFastLineMutation.data?.GridLineDetails.inlineObject?.Price,
                    OverrideDetails: { ...priceOverrideDetailSummary, ...priceOverrideData },
                });
                if (response.Status && !response.Forms) {
                    productCatalogueDispatch({ setAddToSaleOpen: false });
                    setPriceOverrideDetailSummary(null);
                    handleSaleLinesChanged();

                    await productGridRef.current?.reloadLines();
                }
            } catch (err) {
                console.warn(err);
            }
        },
        [createFastLineMutation.data, priceOverrideDetailSummary, productGridRef.current, selectedBomCode, kitStatus, createAndValidateFastLine]
    );

    const handlePriceOverrideCancel = useCallback(
        () => {
            setPriceOverrideDialogOpen(false);
        },
        []
    );

    const handleActionClick = useCallback(
        async (status: 'c' | 'k') => {
            setMultipleBomState(false);
            setKitStatus(status);

            if (!productCatalogueConfig.data?.AllowCataloguePriceQuantityAdjustment && status) {

                return addLineWOPriceQuantityAdjustment(status);
            }

            if (status) {
                if (status === 'c') {
                    addSaleRef.current?.disablePriceField();
                }

                calculateAndValidateFastLine({
                    ProductId: selectedProduct?.ProductId,
                    OrderedQuantity: fastLineInlineData?.OrderedQuantity,
                    KitStatus: status
                });
            } else {
                addSaleRef.current?.resetToDefault();
            }

        },
        [calculateFastLineDetailMutation.data, productCatalogueConfig.data, addSaleRef.current, selectedBomCode, createAndValidateFastLine, calculateAndValidateFastLine]
    );

    function navigateBack(): void {
        if (changeSelectedTab) {
            changeSelectedTab('Lines');
        }
    }

    const handleEntryViewTabOut = () => {
        productGridRef.current?.selectDefaultRow();
    };

    const handleProductGridKeyDown = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.shiftKey && e.keyCode === TAB) {
                treeListRef.current?.selectItem(selectedCatalogueId);

                return;
            }
        },
        [treeListRef.current, selectedCatalogueId]);

    const handleAddToSaleOnApply = React.useCallback(
        (query) => {
            return createAndValidateFastLine({
                ...query,
                BomCode: selectedBomCode,
                KitStatus: kitStatus,
            });
        },
        [selectedBomCode, kitStatus, createAndValidateFastLine]
    );

    React.useEffect(
        () => {
            if (!multipleBomOpen && kitStatus === null) {
                handleAddToLineClose();
            }
        },
        [multipleBomOpen, kitStatus]
    );

    return (
        <div className={classes.mainDiv}>
            {isPathProductCatalogue && (<div className={classes.container}>

                <span className={classes.collapseableBreadCrumb}>
                    <CollapsibleBreadcrumbBar
                        moduleTree={productCatalogueState?.breadCrumbData ?? []}
                        onBreadcrumbClick={handleOnBreadcrumbClick}
                        maxItems={DEFAULT_BREAD_CRUMBS}
                    />
                </span>
                <SearchEntryModal
                    open={productCatalogueState?.isSearchOpen}
                    loading={false}
                    title={'Search For Entry'}
                    onClose={() => { productCatalogueDispatch({ setIsSearchOpen: false }); }}
                    onSearch={(s) => handleSearchEntries(s, selectedCatalogueId)}
                    onOk={handleOnSearchEntries}
                >
                </SearchEntryModal>
                <div className={classes.rowsContainer}>
                    <EntriesView
                        treeListRef={treeListRef}
                        loadChildren={loadChildren}
                        getListOfImages={(item: ICatalogueEntryFacade) => getListOfImages(item?.CatalogueId)}
                        getMainImage={handleLoadImage}
                        loadImage={handleLoadImage}
                        onSelectionChanged={handleOnEntrySelectionChanged}
                        onTabOut={handleEntryViewTabOut}
                        defaultFirstRowSelection={true}
                        hideImage={!productCatalogueConfig.data?.DisplayCatalogueEntryImages}
                    />
                    <ProductGrid
                        innerRef={productGridRef}
                        defaultSelectedId={selectedProduct?.CatalogueProductId}
                        catalogueId={selectedCatalogueId}
                        worksaleId={worksaleData?.WorksaleId}
                        handleLoadProducts={loadProducts}
                        getMainImage={handleLoadImage}
                        getListOfImages={getCatalogueProductImages}
                        loadImage={handleLoadImage}
                        onProductSelectionChanged={handleProductSelectionChanged}
                        hideImage={!productCatalogueConfig.data?.DisplayCatalogueProductImages}
                        showDetailOnClick={(data) => {
                            productCatalogueDispatch({ setSelectedProduct: data });
                            changeSelectedTab('CatalogueProductDetails');
                        }}
                        onKeyDown={handleProductGridKeyDown}
                    />
                </div>
                <PriceOverrideAuthorityDialog
                    open={priceOverrideDialogOpen}
                    loading={createFastLineMutation.isLoading}
                    type={priceOverrideScreenType}
                    data={priceOverrideDetailSummary}
                    onOk={handlePriceOverrideOk}
                    onCancel={handlePriceOverrideCancel}
                />
                <BOMDialog
                    open={multipleBomOpen}
                    onClose={() => setMultipleBomState(false)}
                    data={bomList}
                    kitStatus={kitStatus ? kitStatus.toLowerCase() : ''}
                    onActionClick={handleActionClick}
                    onSelectedRowChanged={setSelectedBomCode}
                />
            </div >)}
            {
                productCatalogueState.addToSaleOpen && <AddToSale
                    innerRef={addSaleRef}
                    isAddToSaleOpen={productCatalogueState.addToSaleOpen}
                    productId={selectedProduct?.ProductId}
                    productCode={selectedProduct?.ProductCode}
                    productName={selectedProduct?.Description}
                    productPrice={fastLineInlineData?.DiscountedPrice}
                    priceDescription={fastLineInlineData?.PriceDescription}
                    productQuantity={selectedProduct?.Quantity}
                    calculateLoading={calculateFastLineDetailMutation.isLoading}
                    handleClose={handleAddToLineClose}
                    calculateFastLine={calculateAndValidateFastLine}
                    onApply={handleAddToSaleOnApply}
                />
            }
            <Route
                key={'CatalogueProductDetails'}
                path={`${match?.path || ''}/catalogue-product-details`}
                exact
                render={() => (
                    <CatalogueProductDetails
                        worksaleId={worksaleData?.WorksaleId}
                        catalogueProductId={selectedProduct?.CatalogueProductId}
                        getMainImage={handleLoadImage}
                        getListOfImages={getCatalogueProductImages}
                        loadImage={handleLoadImage}
                        ref={catalogueProductDetailsRef}
                        changeSelectedTab={changeSelectedTab}
                    />
                )} />
            <Route
                key={'CatalogueEntryDetails'}
                path={`${match?.path || ''}/catalogue-entry-details`}
                exact
                render={() => (
                    <EntryDetails
                        catalogueId={selectedCatalogueId}
                        getMainImage={handleLoadImage}
                        getListOfImages={getListOfImages}
                        loadImage={handleLoadImage}
                        ref={catalogueEntryDetailsRef}
                        changeSelectedTab={changeSelectedTab}
                    />
                )} />
        </div >
    );
};

export default withStyles(styles, { index: 1 })(ProductCatalogue);
