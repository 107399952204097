import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset, change } from 'redux-form';
import { selectors as documentOutputSelectors } from 'ducks/documentOutput';
import FinalConfirmationDialog from './FinalConfirmationDialog';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'FinalConfirmationForm';

const mapStateToProps = (state: IApplicationState) => ({
  purchaseOrderOuputOptions: documentOutputSelectors.options('SO', 'PurchaseOrderOutput')(state),
  dirty: isDirty(FORM_NAME)(state),
  values: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinalConfirmationDialog);
