import { fetchPost, fetchGet, objectify } from './../utils'
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import moment from 'moment';

export const search = (query) => {
  const { Supplier, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(
    `/SupplierEnquiry/Transactions/Supplier/${Supplier}/Retrieve`,
    filters && { ...filters, FromDate: From, ToDate: To, },
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize }
  )
    .then((result) => {
      if (result) {
        if (result.SupplierTransactions) {
          return {
            records: result.SupplierTransactions.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}


export const getDetails = (query) => {
  const { TransactionNumber } = query;
  const respType = 'Inline';
  return fetchGet(`/SupplierEnquiry/Transactions/Transaction/${TransactionNumber}`, null, respType)
    .then((result) => result ? { inlineObject: objectify(result.SupplierTransactionDetail, null, 'Inline'), schema: result.SupplierTransactionDetail } : null)
}

export const getSummary = (query) => {
  const { TransactionNumber } = query;
  const respType = 'Inline';
  return fetchGet(`/SupplierEnquiry/Transactions/Summary/Transaction/${TransactionNumber}`, null, respType)
    .then((result) => result && result.TransactionSummary);
}
