import * as React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { isNull, deepCopyFunction } from 'utils/utils';
import moment from 'moment';
import { IResource, IEventData } from 'ducks/serviceActivityScheduling/serviceActivities';
import { Operation } from 'utils/operations';
import { IShift } from 'api/swaggerTypes';
import { IScheduleActivityModalProps, IScheduleActivityModalState } from '../../interfaces';
import { SCHEDULE_ACTIVITY_TABLE_COLUMN_DEFS } from '../constants';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import TimeField from '@markinson/uicomponents-v2/TimeField';
import DataGridDevEx from 'components/common/DataGridDevEx';
import DataGrid from 'devextreme-react/data-grid';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const inlineStyles = {
  mainDiv: {
    width: 400,
    height: 300,
    padding: '16px'
  },
  gridDiv: {
    width: '100%',
    height: '60%',
  },
  timeDiv: {
    display: 'flex',
    padding: '16px',
  },
  endTimeDiv: {
    paddingLeft: '16px',
  }

};

const parseTime = (time) => {
  return moment(time, 'hh:mm a');
};

const ScheduleActivityForm = (
  props: {
    eventData: IEventData;
    timeValues: { StartTime: string; EndTime: string };
    getResource(resourceId: string): IResource;
    setTime(name: ('StartTime' | 'EndTime'), value: string): void;
  }) => {
  const { timeValues, eventData, getResource, setTime } = props;
  const { item } = eventData;
  const dataGridRef = React.useRef<DataGrid>();

  const rowSelect = (event: any) => {
    const { StartTime } = event.selectedRowsData && event.selectedRowsData[0];
    setTime('StartTime', parseTime(StartTime));
    calculateAndSetEndTime(StartTime);
  };

  const calculateAndSetEndTime = (StartTime) => {
    const { estimate } = item;

    const duration = moment.duration({
      h: moment(estimate, 'hhm').hours(),
      m: moment(estimate, 'hhm').minute()
    });

    const EndTime = parseTime(StartTime).add(duration);
    setTime('EndTime', parseTime(EndTime));
  };

  const sortShifts = (shiftsArr: IShift[]) => {
    return shiftsArr.sort((a, b) =>
      parseTime(a.StartTime).isAfter(parseTime(b.StartTime))
        ? 1
        : parseTime(a.StartTime).isSame(parseTime(b.StartTime))
          ? parseTime(a.EndTime).isAfter(parseTime(b.EndTime))
            ? 1
            : -1
          : -1
    );
  };

  const getShifts = (): IShift[] => {
    const { slotId, date } = eventData;
    const resource = getResource(slotId);
    const shifts = resource.shifts;
    const dayShifts = shifts.filter((shift: IShift) => date.isSame(moment(shift.Date, 'DD/MM/YYYY'), 'day'));
    const deepCopyShifts = deepCopyFunction(dayShifts);
    const consolidatedShifts = deepCopyShifts.length > 1 ? consolidateTimes(deepCopyShifts) : deepCopyShifts;
    const events = slotEvents();
    const eventTimes = [];
    events.forEach((element) => {
      const eventStart = element.start.split('T');
      const eventEnd = element.end.split('T');
      const event = {
        StartTime: parseTime(eventStart[1]).format('hh:mm a'),
        EndTime: parseTime(eventEnd[1]).format('hh:mm a')
      };
      eventTimes.push(event);
    });

    const consolidatedEvents = eventTimes.length > 1 ? consolidateTimes(eventTimes) : eventTimes;
    const brokenShifts = (consolidatedShifts.length > 0 && consolidatedEvents.length > 0) ?
      breakShifts(consolidatedShifts, consolidatedEvents) : consolidatedShifts;
    const formattedTimes = formatTimes(brokenShifts);

    return formattedTimes;
  };

  const formatTimes = (timeArr): IShift[] => {
    for (const element of timeArr) {
      element.StartTimeDisplay = parseTime(element.StartTime).format('hh:mm A');
      element.EndTimeDisplay = parseTime(element.EndTime).format('hh:mm A');
    }

    return timeArr;
  };

  const breakShifts = (shifts: IShift[], events: IShift[]): IShift[] => {

    const isEventInShift = (event: IShift, shift: IShift) => {
      return (
        parseTime(event.StartTime).isSameOrAfter(parseTime(shift.StartTime)) &&
        parseTime(event.EndTime).isSameOrBefore(parseTime(shift.EndTime))
      );
    };
    const isEventAtStart = (event: IShift, shift: IShift) => {
      return parseTime(event.StartTime).isSame(parseTime(shift.StartTime));
    };
    const isEventAtEnd = (event: IShift, shift: IShift) => {
      return parseTime(event.EndTime).isSame(parseTime(shift.EndTime));
    };
    const startTimeOverlapping = (event: IShift, shift: IShift) =>
      parseTime(event.StartTime).isBetween(
        parseTime(shift.StartTime),
        parseTime(shift.EndTime)
      );
    const endTimeOverlapping = (event: IShift, shift: IShift) =>
      parseTime(event.EndTime).isBetween(
        parseTime(shift.StartTime),
        parseTime(shift.EndTime)
      );
    const completeOverlapping = (event: IShift, shift: IShift) => isEventInShift(shift, event);

    const removeShift = (shiftsArr: IShift[], shift) => {
      const index = shiftsArr.indexOf(shift);
      if (index > -1) {
        shiftsArr.splice(index, 1);
      }

      return shiftsArr;
    };

    events.forEach((event) => {
      for (const shift of shifts) {
        const match = isEventInShift(event, shift);
        if (match) {
          if (isEventAtStart(event, shift)) {
            shift.StartTime = event.EndTime;
          } else if (isEventAtEnd(event, shift)) {
            shift.EndTime = event.StartTime;
          } else {
            const endTime = shift.EndTime;
            shift.EndTime = event.StartTime;
            const newShift = {
              ...shift,
              StartTime: event.EndTime,
              EndTime: endTime
            };
            shifts.push(newShift);

            shifts = sortShifts(shifts);
          }
          break;
        }

        if (startTimeOverlapping(event, shift)) {
          shift.EndTime = event.StartTime;
          break;
        }
        if (endTimeOverlapping(event, shift)) {
          shift.StartTime = event.EndTime;
          break;
        }
        if (completeOverlapping(event, shift)) {
          shifts = removeShift(shifts, shift);
          break;
        }
      }
    });

    return shifts;
  };

  const slotEvents = () => {
    const { schedulerData, date, slotId } = eventData;
    const { events } = schedulerData;

    return events.filter((event) => (
      date.isSame(moment(event.start), 'day') && event.resourceId === slotId) &&
      event.id !== item.id);
  };

  const consolidateTimes = (shiftsArr: IShift[]): IShift[] => {
    const shifts = sortShifts(shiftsArr);

    const conShifts = [{ ...shifts[0] }];
    let shift = conShifts[0];

    for (let i = 1; i < shifts.length; i++) {
      const check = shifts[i];
      if (parseTime(check.StartTime).isSameOrBefore(parseTime(shift.EndTime))) {
        if (parseTime(check.EndTime).isAfter(parseTime(shift.EndTime))) {
          shift.EndTime = check.EndTime;
        }
      } else {
        conShifts.push({ ...check });
        shift = conShifts[conShifts.length - 1];
      }
    }

    return conShifts;
  };

  const onTimeChange = (name: ('StartTime' | 'EndTime'), time: any) => {
    setTime(name, time);
  };

  const rowData = getShifts();

  const onContentReady = async () => {
    if (dataGridRef.current) {
      const selectedRowsKeys = await dataGridRef.current.instance.getSelectedRowKeys();
      if (!selectedRowsKeys.length) {
        dataGridRef.current.instance.selectRowsByIndexes([0]);
      }
    }
  };

  const handleStartTimeChange = (time) => {
    onTimeChange('StartTime', time);
    calculateAndSetEndTime(time);
  };

  return <div style={inlineStyles.mainDiv}>

    <Subheader>Availability</Subheader>
    <div style={inlineStyles.gridDiv}>

      <DataGridDevEx
        loading={false}
        columnDefs={SCHEDULE_ACTIVITY_TABLE_COLUMN_DEFS}
        rowData={rowData}
        dataGridRef={dataGridRef}
        dataGridProps={{
          selection: true,
          onContentReady,
          onSelectionChanged: rowSelect
        }}
        style={{ height: 'inherit' }}
      />
    </div>
    <div style={inlineStyles.timeDiv}>
      <TimeField
        label={'Start Time'}
        size={'small'}
        format={'hh:mm a'}
        required={true}
        value={timeValues && timeValues.StartTime}
        onChange={handleStartTimeChange}
      />
      <div style={inlineStyles.endTimeDiv}>
        <TimeField
          label={'End Time'}
          size={'small'}
          format={'hh:mm a'}
          required={true}
          value={timeValues && timeValues.EndTime}
          onChange={(time) => { onTimeChange('EndTime', time); }}
        />
      </div>
    </div>
  </div>;
};

class ScheduleActivityModal extends React.Component<IScheduleActivityModalProps, IScheduleActivityModalState> {
  constructor(props: IScheduleActivityModalProps) {
    super(props);
    this.state = {
      StartTime: '',
      EndTime: ''
    };
  }

  componentDidMount = (): void => {
    const { eventData } = this.props;
    const timeValues = {
      StartTime: eventData && eventData.start ? moment(eventData.start) : this.state.StartTime,
      EndTime: eventData && eventData.end ? moment(eventData.end) : this.state.EndTime
    };
    this.setState(timeValues);
  }

  componentDidUpdate = (prevProps: Readonly<IScheduleActivityModalProps>): void => {
    const { eventData: prevEventData } = prevProps;
    const { eventData } = this.props;
    if (prevEventData !== eventData) {
      const timeValues = {
        StartTime: eventData && eventData.start ? moment(eventData.start) : this.state.StartTime,
        EndTime: eventData && eventData.end ? moment(eventData.end) : this.state.EndTime
      };
      this.setState(timeValues);
    }
  }

  getResource = (resourceId: string): IResource => {
    const { resourcesList } = this.props;
    if (resourcesList && resourcesList.length > 0) {
      return resourcesList.find((Resource: IResource) => Resource.id === resourceId);
    }

    return null;
  }

  scheduleActivityActions = () => {
    const { scheduleActivityModalToggle, changeOperationMode } = this.props;
    const setState = this.setState.bind(this);

    return [
      {
        iconName: 'Cancel',
        title: 'Cancel',
        listener: () => {
          scheduleActivityModalToggle(false);
          changeOperationMode(Operation.BROWSE);
          setState({
            StartTime: '',
            EndTime: ''
          });
        }
      },
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: this.onScheduleOk
      }];
  }

  onScheduleOk = () => {
    const ActivityStartTime = this.state.StartTime;
    const ActivityEndTime = this.state.EndTime;

    if (isNull(ActivityStartTime) || isNull(ActivityEndTime)) {
      showSnackBar({ variant: 'error', message: 'Start Time and End Time are required.' });

      return;
    } else if (parseTime(ActivityStartTime) >= parseTime(ActivityEndTime)) {
      showSnackBar({ variant: 'error', message: 'End Time must be later than Start Time.' });

      return;
    }

    this.props.scheduleActivityModalToggle(false);
    this.props.schedulerEventConfirm(ActivityStartTime, ActivityEndTime);
    this.props.changeOperationMode(Operation.BROWSE);
    this.setState({
      StartTime: '',
      EndTime: ''
    });
  }

  setTime = (name: 'StartTime' | 'EndTime', value: string) => {
    this.setState({
      [name]: value
    });
  }

  getModalContent = () => {
    const { eventData } = this.props;
    const timeValues = {
      StartTime: this.state.StartTime,
      EndTime: this.state.EndTime
    };

    return <ScheduleActivityForm
      timeValues={timeValues}
      eventData={eventData}
      getResource={this.getResource}
      setTime={this.setTime} />;
  }

  render(): React.ReactNode {
    const { open } = this.props;

    return (
      <FormViewModal
        open={open}
        loading={false}
        title={'Schedule Activity'}
        modalContent={this.getModalContent()}
        actions={this.scheduleActivityActions()}
      />
    );
  }
}

export default ScheduleActivityModal;
