import React from 'react';
import { connect } from 'react-redux';
import CommonKitComponents from './KitComponents';
import { actions as uiActions } from 'ducks/ui';
import { Dispatch, bindActionCreators } from 'redux';
import IKitComponentsProperties, { OmittedApiTypes } from './KitComponents.properties';
import { ICalculateComponentDetailsFacade, IWIPComponentFacade } from 'api/swaggerTypes';
import {
  useAddComponent, useCalculateComponentFastline, useCalculateComponentGridLineDetails,
  useDeleteComponentLine, useFetchComponentLines, useUpdateComponentGridLineDetails, useFetchKitSummary
} from 'api/pickSlips/components';

const KitComponents = (props: Readonly<Omit<
  IKitComponentsProperties,
  'classes' | OmittedApiTypes>>) => {

  const params = new URLSearchParams(location.search);
  const DespatchLineIdUrlParam = Number(params.get('DespatchLineId') ?? '');

  const { DocumentId: DocumentIdProp } = props;

  const DocumentId = DocumentIdProp ?? DespatchLineIdUrlParam;
  const fetchComponentsMutation = useFetchComponentLines();
  const fetchKitSummary = useFetchKitSummary();
  const deleteLineMutation = useDeleteComponentLine();
  const calculateComponentFastlineMutation = useCalculateComponentFastline();
  const addComponentMutation = useAddComponent();
  const calculateComponentGridLineDetailsMutation = useCalculateComponentGridLineDetails();
  const updateComponentGridLineDetailsMutation = useUpdateComponentGridLineDetails();

  const fetchComponentLines = React.useCallback(
    (_w, BatchPage, BatchSize) => {
      if (!DocumentId) {
        return null;
      }

      return fetchComponentsMutation.mutateAsync({
        DocumentId,
        urlQueryParams: {
          BatchPage,
          BatchSize
        }
      });
    },
    [DocumentId]
  );

  const calculateFastline = React.useCallback(
    (_w, ProductId, Quantity) => {
      if (!DocumentId) {
        return null;
      }

      return calculateComponentFastlineMutation.mutateAsync({
        DocumentId,
        urlQueryParams: {
          ProductId,
          Quantity
        }
      });
    },
    [DocumentId]
  );
  const kitSummary = React.useCallback(
    async (_w: number) => {
      if (!DocumentId) {
        return null;
      }

      return fetchKitSummary.mutateAsync({
        DocumentId,
      });
    },
    [DocumentId]
  );

  const addComponent = React.useCallback(
    (_w, ProductId: number, RequiredQuantity: number) => {
      if (!DocumentId) {
        return null;
      }

      return addComponentMutation.mutateAsync({
        DocumentId,
        urlQueryParams: {
          ProductId,
          RequiredQuantity
        }
      });
    },
    [DocumentId]
  );

  const calculateComponentGridLineDetails = React.useCallback(
    (_w: number, ComponentId: number, line: ICalculateComponentDetailsFacade, changedField: keyof (ICalculateComponentDetailsFacade)) => {
      if (!ComponentId) {
        return null;
      }

      return calculateComponentGridLineDetailsMutation.mutateAsync({
        ...line,
        ComponentId,
        urlQueryParams: {
          ChangedField: changedField
        }
      });
    },
    []
  );

  const updateComponentGridLineDetails = React.useCallback(
    (_w: number, ComponentId: number, line: IWIPComponentFacade) => {
      if (!ComponentId) {
        return null;
      }

      return updateComponentGridLineDetailsMutation.mutateAsync({
        ...line,
        ComponentId
      });
    },
    []
  );

  const deleteLine = React.useCallback(
    (componentId: number) => {
      if (!componentId) {
        return null;
      }

      return deleteLineMutation.mutateAsync({
        ComponentId: componentId
      });
    },
    []
  );

  return (<CommonKitComponents
    onFetchComponentLines={fetchComponentLines}
    onCreateComponentLine={addComponent}
    onCalculateComponentFastLineDetails={calculateFastline}
    onCalculateComponentGridLineDetails={calculateComponentGridLineDetails}
    onUpdateComponentGridLine={updateComponentGridLineDetails}
    onDeleteComponent={deleteLine}
    onFetchTotalComponentLinesCount={async () => undefined}
    getLineKitSummary={kitSummary}
    {...props} />);
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeValidationDialog: uiActions.changeValidationDialog,
  },
  dispatch);

export default connect(null, mapDispatchToProps)(KitComponents);
