import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as customerPricingSelectors, actions as customerPricingActions } from 'ducks/customerEnquiry/pricing';
import { fetch } from 'api/customerEnquiry/pricing';
import { selectors as customerSelector } from 'ducks/customer';

export const mapStateToProps = state => ({
  gridName: 'CustomerPricing',
  columnDefs: customerPricingSelectors.columns(state),
  detailColumns: customerPricingSelectors.detailColumns(state),
  rowData: customerPricingSelectors.list(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: customerPricingSelectors.gridOptions(state),
  isLoading: customerPricingSelectors.loadingCustomerPricingDetails(state),
  detailRowHeight: customerPricingSelectors.detailRowHeight(state),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
  {
    CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: customerPricingActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
