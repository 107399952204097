import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  mainDiv: {
    display: 'flex',
  },
  formDiv: {
    margin: '15px'

  },
  tableDiv: {

  },
  paymentLineDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#DDDDDD',
    padding: '10px',
    width: '500px',
  },
  paymentLookupField: {
    padding: 0,
    height: '70px',
  },
  button: {
    boxShadow: 'none',
    padding: '10px',
  },
  paymentLineDataGrid: {
    width: '520px',
    height: '175px'
  },
  disabledBtn: {
    backgroundColor: '#DDDDDD !important',
  },
  actionButtonIcon: {
    cursor: 'pointer',
    fontSize: '18px',
    margin: '0 3px',
    color: '#337ab7'
  },
  anchor: {
    display: 'inline-flex'
  },
  disabledIcon: {
    color: 'gray',
    pointerEvents: 'none'
  }
});
