import { apiEndpoint } from '../config';
import { objectify, AsInline, AsInlineArray, IObjectified, Inline } from './utils';

const HTTP_OK = 200;
const HTTP_NO_CONTENT = 204;
const HTTP_NOT_AUTHENTICATED = 401;
const HTTP_SYSTEM_ERROR = 500;
const HTTP_SERVICE_UNAVAILABLE = 503;

export class ApiError extends Error {
  res: Response;
  showUser: boolean;
}

/**
 * Parses the API response status and throws an error if the response status is not 200 (OK) or 201 (No Content).
 * @param response An API response
 * @returns The API response
 */
export function checkResponseStatus(response: Response): Response {
  switch (response.status) {
    case HTTP_NOT_AUTHENTICATED:
      const authenticationError = new ApiError('Login Failed: Username/password is incorrect.');
      authenticationError.res = response;
      authenticationError.showUser = true;
      throw authenticationError;
    case HTTP_SYSTEM_ERROR:
      const systemError = new ApiError('An unexpected error has occurred. Please contact the system administrator for support.');
      systemError.res = response;
      systemError.showUser = true;
      throw systemError;
    case HTTP_SERVICE_UNAVAILABLE:
      const serviceError = new ApiError('Server is temporarily unavailable. Please contact the system administrator for support.');
      serviceError.res = response;
      serviceError.showUser = true;
      throw serviceError;
    case HTTP_OK:
    case HTTP_NO_CONTENT:
    default:
      return response;
  }
}

/**
 * Parses the API response content type and throws an error if the content type is not 'application/json' or 'text/plain'
 * @param response An API response
 * @returns Either the API JSON object (if content-type is 'application/json') or the API text (if content type is 'text/plain')/
 */
export async function checkResponseContentType(response: Response): Promise<any> {
  if (response && response.headers.get('content-type') && response.headers.get('content-type').indexOf('application/json') !== -1) {
    return response.json();
  } else if (response && response.headers.get('content-type') && response.headers.get('content-type').indexOf('text/plain') !== -1) {
    return response.text();
  }
  const error = new ApiError('Unexpected Response Content.');
  error.res = response;
  error.showUser = true;
  throw error;
}

/**
 * Parses the API response content type
 * @param response An API response
 * @returns Either the API JSON object (if content-type is 'application/json'), the API text (if content type is 'text/plain') or otherwise 'Success'
 */
export async function parseResponseContentType(response: Response): Promise<any> {
  if (response && response.headers.get('content-type') && response.headers.get('content-type').indexOf('application/json') !== -1) {
    return response.json();
  } else if (response && response.headers.get('content-type') && response.headers.get('content-type').indexOf('text/plain') !== -1) {
    return response.text();
  }

  return 'Success';
}

/**
 * Transforms an inline object to objectified format.
 * @param inlineObject An inline object (API response object where properties are objects with metdata inline)
 * @returns An objectified object, with inlineObject property set with the values of the item and schema with the full inline object.
 */
export function toObjectified<T extends object>(inlineObject: Inline<T>): IObjectified<T> {
  return { inlineObject: objectify(inlineObject), schema: inlineObject };
}

/**
 * Transforms an object containing an inline object to one where the inline object is transformed to objectified format.
 * @param containerObject On object with a property contianing an inline object (API response object where properties are objects with metdata inline)
 * @param propertyName Property on the containerObject containing the inline object.
 * @returns The containerObject where containerObject.propertyName is transformed into objectified format.
 */
export function asObjectified<T extends object, K extends keyof T, I extends object>(containerObject: AsInline<T, K>, propertyName: K): IObjectified<I> {
  if (!containerObject) {
    return null;
  }

  const item = containerObject[propertyName];
  if (!item || !(item instanceof Object)) {
    return null;
  }

  return toObjectified(item as T[K] & Inline<I>);
}

/**
 * Transforms an inline collection to objectified format.
 * @param inlineCollection An inline collection (API response collection where properties are objects with metdata inline)
 * @returns An objectified collection, with inlineCollection property set with the values of the item and schema with the full inline object.
 */
export function toObjectifiedArray<T extends object>(inlineCollection: Inline<T>[]): IObjectified<T>[] {
  if (inlineCollection) {
    return inlineCollection.map(toObjectified);
  }

  return null;
}

/**
 * Transforms an object containing an inline collection to one where the inline collection is transformed to objectified format.
 * @param containerObject On object with a property contianing an inline collection (API response object where properties are objects with metdata inline)
 * @param propertyName Property on the containerObject containing the inline collection.
 * @returns The containerObject where containerObject.propertyName is transformed into objectified format.
 */
export function asObjectifiedArray<T extends object, K extends keyof T, I extends object>(
  containerObject: AsInlineArray<T, K>, propertyName: K): IObjectified<I>[] {
  if (!containerObject) {
    return null;
  }

  const collection = containerObject[propertyName];
  if (!collection) {
    return null;
  }

  return toObjectifiedArray(collection as T[K] & Inline<I>[]);
}

/**
 * Transforms an object containing an inline collection to one where the inline collection is transformed to inline array format.
 * @param containerObject On object with a property contianing an inline collection (API response object where properties are objects with metdata inline)
 * @param propertyName Property on the containerObject containing the inline collection.
 * @returns The containerObject where containerObject.propertyName is transformed into objectified format.
 */
export function asObjectifiedInlineArray<T extends object, K extends keyof T, I extends object>(
  containerObject: AsInlineArray<T, K>, propertyName: K): AsInlineArray<T, K> {
  if (!containerObject) {
    return null;
  }

  const collection = containerObject[propertyName];
  if (!collection) {
    return null;
  }

  return {
    ...containerObject,
    [propertyName]: (collection as T[K] & Inline<I>[]).map((o) => objectify(o as Inline<I>))
  };
}

/**
 * Base API class with methods for accessing the API using appropriate authentication and common headers.
 */
export class BaseApi {

  baseApiEndpoint: string;

  constructor() {
    this.baseApiEndpoint = apiEndpoint;
  }

  /**
   * Gets data from the API
   * @template O Expected request response object type
   * @param relativePath relative path to retrieve in the API
   * @returns The API response object as JSON/TEXT depending on content-type.
   */
  async get<O>(relativePath: string): Promise<O> {
    return this
      .send('GET', new URL(relativePath, this.baseApiEndpoint))
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Gets data from the API with the responseMetadataFormat query parameter set to Inline
   *
   * The response object will be returned as an Inline variation on O.
   *
   * @template O Expected request response object type
   * @param relativePath relative path to retrieve in the API
   * @returns The API response object as AsInline<O,K>
   */
  async getInline<O>(relativePath: string): Promise<O> {
    return this
      .send('GET', this.formatInline(new URL(relativePath, this.baseApiEndpoint)))
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Gets data from the API with the responseMetadataFormat query parameter set to Inline
   *
   * The response object will be returned as an Inline variation on O.
   *
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to retrieve in the API
   * @returns The API response object as AsInline<O,K>
   */
  async getInlineResponse<O, K extends keyof O>(relativePath: string): Promise<AsInline<O, K>> {
    return this.getInline<AsInline<O, K>>(relativePath);
  }

  /**
   * Gets data from the API with the responseMetadataFormat query parameter set to Inline
   *
   * The response object will be returned as an Inline variation on O.
   *
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to retrieve in the API
   * @returns The API response object as AsInlineArray<O,K>
   */
  async getInlineCollectionResponse<O, K extends keyof O>(relativePath: string): Promise<AsInlineArray<O, K>> {
    return this
      .send('GET', this.formatInline(new URL(relativePath, this.baseApiEndpoint)))
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as AsInlineArray<O, K>);
  }

  /**
   * Posts data to the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @param relativePath relative path to post in the API
   * @param requestBody request content to post to the API.
   * @returns The API response object as JSON/TEXT depending on content-type.
   */
  async post<I, O>(relativePath: string, requestBody?: I): Promise<O> {
    return this
      .sendData('POST', new URL(relativePath, this.baseApiEndpoint), requestBody)
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Posts data to the API with the responseMetadataFormat query parameter set to Inline
   * @param relativePath relative path to post in the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @param requestBody request content to post to the API.
   */
  async postInline<I, O>(relativePath: string, requestBody?: I): Promise<O> {
    return this
      .sendData('POST', this.formatInline(new URL(relativePath, this.baseApiEndpoint)), requestBody)
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Posts data to the API with the responseMetadataFormat query parameter set to Inline
   *
   * The response object will be returned as an Inline variation on O.
   * @param relativePath relative path to post in the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param requestBody request content to post to the API.
   * @returns The API response object as AsInline<O, K>
   */
  async postInlineResponse<I, O, K extends keyof O>(relativePath: string, requestBody?: I): Promise<AsInline<O, K>> {
    return this.postInline<I, AsInline<O, K>>(relativePath, requestBody);
  }

  /**
   * Posts data to the API with the responseMetadataFormat query parameter set to Inline
   *
   * The response object will be returned as an Inline variation on O.
   * @param relativePath relative path to post in the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param requestBody request content to post to the API.
   * @returns The API response object as AsInlineArray<O, K>
   */
  async postInlineCollectionResponse<I, O, K extends keyof O>(relativePath: string, requestBody?: I): Promise<AsInlineArray<O, K>> {
    return this.postInline<I, AsInlineArray<O, K>>(relativePath, requestBody);
  }

  /**
   * Updates data in the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as JSON/TEXT depending on content-type.
   */
  async put<I, O>(relativePath: string, requestBody?: I): Promise<O> {
    return this
      .sendData('PUT', new URL(relativePath, this.baseApiEndpoint), requestBody)
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Updates data in the API
   * @template I Expected request object type
   * @template O Expected request response object type
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as JSON/TEXT depending on content-type.
   */

  async putInline<I, O>(relativePath: string, requestBody?: I): Promise<O> {
    return this
      .sendData('PUT', this.formatInline(new URL(relativePath, this.baseApiEndpoint)), requestBody)
      .then(checkResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   * Updates data in the API with the responseMetadataFormat query parameter set to Inline
   * @template I Expected request object type
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as as AsInline<O, K>
   */
  async putInlineResponse<D, R, K extends keyof R>(relativePath: string, requestBody?: D): Promise<AsInline<R, K>> {
    return this.putInline<D, AsInline<R, K>>(relativePath, requestBody);
  }

  /**
   * Updates data in the API with the responseMetadataFormat query parameter set to Inline
   * @template I Expected request object type
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as as AsInlineArray<O, K>
   */
  async putInlineCollectionResponse<D, R, K extends keyof R>(relativePath: string, requestBody?: D): Promise<AsInlineArray<R, K>> {
    return this.putInline<D, AsInlineArray<R, K>>(relativePath, requestBody);
  }

  /**
   * Deletes data from the API
   * @param relativePath relative path to delete in the API
   * @template O Expected request response object type
   * @returns The API response object as JSON/TEXT depending on the content-type.
   */
  async delete<O>(relativePath: string): Promise<O> {
    return this
      .send('DELETE', new URL(relativePath, this.baseApiEndpoint))
      .then(parseResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  async deleteInline<O>(relativePath: string): Promise<O> {
    return this
      .send('DELETE', this.formatInline(new URL(relativePath, this.baseApiEndpoint)))
      .then(parseResponseContentType)
      .then((responseBody) => responseBody as O);
  }

  /**
   *  Deletes data from the API with the responseMetadataFormat query parameter set to Inline
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as as AsInline<O, K>
   */
  async deleteInlineResponse<R, K extends keyof R>(relativePath: string): Promise<AsInline<R, K>> {
    return this.deleteInline<AsInline<R, K>>(relativePath);
  }

  /**
   *  Deletes data from the API with the responseMetadataFormat query parameter set to Inline
   * @template O Expected request response object type
   * @template K Property on O that will be returned with inline metadata.
   * @param relativePath relative path to update in the API
   * @param requestBody request content update in the API.
   * @returns The API response object as as AsInlineArray<O, K>
   */
  async deleteInlineCollectionResponse<R, K extends keyof R>(relativePath: string): Promise<AsInlineArray<R, K>> {
    return this.deleteInline<AsInlineArray<R, K>>(relativePath);
  }

  private async sendData<B>(method: 'POST' | 'PUT', url: URL, body: B): Promise<Response> {
    return fetch(url.href, {
      method: method, headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      },
      body: body ? JSON.stringify(body) : null,
    }).then(checkResponseStatus);
  }

  private async send(method: 'GET' | 'DELETE', url: URL): Promise<Response> {

    return fetch(url.href, {
      method: method, headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    }).then(checkResponseStatus);
  }

  private formatInline(url: URL): URL {
    const params = new URLSearchParams(url.search);
    params.set('responseMetadataFormat', 'Inline');
    url.search = params.toString();

    return url;
  }
}

export const Api = new BaseApi();
