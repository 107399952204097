import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/salesInvoiceEnquiry/InvoiceLineSerials';
import { fetch } from 'api/salesInvoiceEnquiry/invoiceLineSerials';
import { selectors as salesAuditSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceSalesAudit';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';
import { selectors as invoiceLineSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLines';

export const mapStateToProps = state => {
  const params = new URLSearchParams(location.search);

  return ({
    gridName: 'Serials',
    columnDefs: lineSelectors.lineColumns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
    gridOptions: lineSelectors.gridOptions(state),
    isLoading: lineSelectors.isLoading(state),
    reqParams: (invoiceSelectors.selected(state) || params.get('InvoiceNumber') || params.get('SalesEntity')) &&
      (invoiceLineSelectors.selected(state) || params.get('LineNumber'))
      &&
    {
      Invoice: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).Invoice) || params.get('InvoiceNumber'),
      SalesEntity: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).SalesEntity) || params.get('SalesEntity'),
      LineNumber: (salesAuditSelectors.selected(state) && salesAuditSelectors.selected(state).LineNumber) || params.get('LineNumber')
    },
    apiMethod: fetch
  });
}

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
