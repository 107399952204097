import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as serialSelectors, actions as serialActions } from 'ducks/common/serialNumber';
import TrackingGrid from './TrackingGrid';

const mapStateToProps = (state: IApplicationState) => ({
  selectedLines: serialSelectors.getSelectedLines(state),
  availableLines: serialSelectors.getAvailableLines(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    clearSelectedLines: serialActions.clearSelectedLines,
    addSelectedLine: serialActions.addSelectedLine,
    addFromAvailableLines: serialActions.addFromAvailableLines,
    removeSelectedLine: serialActions.removeSelectedLine,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrackingGrid);
