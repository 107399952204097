import { isDirty, reset, destroy } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as balancesActions } from 'ducks/customerEnquiry/balances';
import { selectors as customerSelectors, actions as customerActions } from 'ducks/customer';
import { actions as invoiceActions } from 'ducks/customerEnquiry/invoices';
import { actions as transactionActions, selectors as transactionSelectors } from 'ducks/customerEnquiry/transactions';
import { actions as quotesActions } from 'ducks/customerEnquiry/quotes';
import { actions as salesOrdersActions } from 'ducks/customerEnquiry/salesOrders';
import { actions as backordersActions } from 'ducks/customerEnquiry/backorders';
import { actions as customerProductActions } from 'ducks/customerProduct';
import { actions as customerPricingActions } from 'ducks/customerEnquiry/pricing';

import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as staffSelectors } from 'ducks/staff';
import { actions as filterActions } from 'ducks/common/filters';

import CustomerEnquiry from './CustomerEnquiry';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'CustomerEnquiry';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedCustomer: customerSelectors.selected(state),
  selectedForm: formSelectors.selected(state),
  selectedTab: uiSelectors.selectedTab(state),
  searchingForCustomers: customerSelectors.isFetched(state),
  transactionDetails: transactionSelectors.details(state),
  transactionSummary: transactionSelectors.summary(state),
  loadingTransactionSummary: transactionSelectors.loadingSummary(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedTransaction: transactionSelectors.selected(state),
  dirty: isDirty(state.ui.selectedTab === 'Notepad' ? 'CustomerNotes' : FORM_NAME)(state),
  staff: staffSelectors.staff(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    fetchBalanceDetails: balancesActions.fetch,
    getContacts: customerActions.subOp_getContacts,
    fetchProductDetail: customerProductActions.fetchDetail,
    fetchTransactionDetails: transactionActions.fetchTransactionDetails,
    fetchTransactionSummary: transactionActions.fetchTransactionSummary,

    setQuotesEntityView: quotesActions.setEntityView,
    setInvoicesEntityView: invoiceActions.setEntityView,
    setSalesOrdersEntityView: salesOrdersActions.setEntityView,
    setBackordersEntityView: backordersActions.setEntityView,
    setTransactionsEntityView: transactionActions.setEntityView,

    getCustomerPricingSummary: customerPricingActions.fetchCustomerPricingSummary,
    changeSelectedCustomer: customerActions.setSelected,
    closeMenu: uiActions.closeMainMenu,
    applyFilters: filterActions.applyFilters,
    resetSearchLookupDrawer: customerActions.reset,
    destoryForm: () => destroy('CustomerDetails'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEnquiry);
