import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IBatchSuiteSelectionProps } from '../DynamicExtractModal.properties';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { useDEContextSelector, useDEDispatch } from '../../RunDynamicExtract.context';
import { useRetrieveWizardBSActions } from 'api/dynamicDataExtract/dynamicDataExtract';
import { PROGRAM_ID } from '../DynamicExtractModal.constants';
import { FIELD_COLUMN_WIDTH } from 'components/ExtractMaintenance/Fields/Fields.constants';
import { DataGrid } from 'devextreme-react';
import { Column, GroupPanel, SearchPanel, Scrolling, LoadPanel, Selection, Sorting } from 'devextreme-react/data-grid';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';

const BatchSuiteSelection = (props: IBatchSuiteSelectionProps) => {
    const {
        classes,
        details
    } = props;

    const dataGridInnerRef = React.useRef<DataGrid>();
    const [isFirstTimeLoad, setIsFirstTimeLoad] = React.useState<boolean>(true);

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const CurrentPanel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);

    const useRetrieveWizardBSActionsMutation = useRetrieveWizardBSActions();
    const [wizardActionsData, setWizardActionsData] = React.useState(null);
    const [description, setDescription] = React.useState('');

    const [isLookupInError, setIsLookupInError] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            if (!isLookupInError && WizardDetails?.BatchSuite && WizardDetails?.BatchSuite !== '') {
                contextDispatch({
                    setErrorState: { InError: false, Panel: '' }
                });
            } else {
                contextDispatch({
                    setErrorState: { InError: true, Panel: CurrentPanel }
                });
            }
        },
        [isLookupInError, WizardDetails?.BatchSuite]
    );

    React.useEffect(
        () => {
            useRetrieveWizardBSActionsMutation.mutateAsync({
                SearchText: '',
                ProgramId: PROGRAM_ID
            }).then((response) => {
                setWizardActionsData(response.Data);
            });
        },
        []
    );

    const handleLookupError = React.useCallback(
        (error: boolean) => {
            setIsLookupInError(error);
        },
        [setIsLookupInError]
    );

    const selectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e?.selectedRowsData?.length > 0 ? e.selectedRowsData[0] : undefined;
            if (selectedRowData) {
                contextDispatch({ setWizardDetails: { ...WizardDetails, BatchSuiteAction: selectedRowData?.Code } });
                setDescription(selectedRowData?.Description);
            }
        },
        []);

    const onContentReady = React.useCallback(
        async () => {
            if (dataGridInnerRef.current?.instance?.getDataSource()?.items().length > 0 && isFirstTimeLoad) {

                if (WizardDetails?.BatchSuiteAction) {
                    await dataGridInnerRef.current?.instance.selectRows([WizardDetails?.BatchSuiteAction], false);
                } else {
                    dataGridInnerRef.current.instance.selectRowsByIndexes([0])
                        .catch((err) => console.warn(err));
                    //update the model in first loading
                    const firstRowKey = dataGridInnerRef.current.instance.getKeyByRowIndex(0);
                    contextDispatch({ setWizardDetails: { ...WizardDetails, BatchSuiteAction: firstRowKey } });
                }

                setIsFirstTimeLoad(false);
            }
        },
        [isFirstTimeLoad, WizardDetails, dataGridInnerRef.current]
    );

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <BasicLookupActionField
                placeholder={'Batch suite'}
                label={'Batch suite'}
                size={'medium'}
                searchScreenTitle={'Batch suite'}
                lookupName={'Batchsuite'}
                value={WizardDetails?.BatchSuite}
                required
                suppressDescription={false}
                column1Field={'Display'}
                column2Field={'Label'}
                descriptionField={'Label'}
                onSelectedItemChange={(item) => {
                    if (item) {
                        contextDispatch({
                            setWizardDetails: { ...WizardDetails, BatchSuite: item?.Code }
                        });
                    }
                }}
                hasError={(value) => { handleLookupError(value); }}
            />
            <DataGrid
                ref={dataGridInnerRef}
                dataSource={wizardActionsData}
                className={classes.dataGrid}
                showBorders={false}
                renderAsync={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                onSelectionChanged={selectionChanged}
                noDataText=''
                onContentReady={onContentReady}
                keyExpr={'Code'}
            >
                <Column
                    dataField={'Code'}
                    visible={false}
                />
                <Column
                    dataField={'Display'}
                    caption={'Task processing criteria'}
                    allowHeaderFiltering={false}
                    width={FIELD_COLUMN_WIDTH}
                    allowEditing={false}
                    allowSorting={false}
                />

                <Sorting mode='none' />
                <Selection mode={'single'} />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
            <Content text={description} />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(BatchSuiteSelection);
