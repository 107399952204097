import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from '../../../../ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from '../../../../ducks/ui';
import { actions as quoteActions, selectors as quoteSelectors } from '../../../../ducks/customerQuoteEnquiry/quote';
import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface IQuoteItem {
  Quote: number;
  QuoteLabel: string;
  CustomerIdDisplay: string;
  CustomerIdLabel: string;
}

const QuoteSearchLookUpDrawer = buildSearchLookUpDrawer<IQuoteItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IQuoteItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IQuoteItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Quote',
    searchPlaceholder: 'Search for quote...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'Quote',
        label: 'Quote'
      }
    ],
    records: quoteSelectors.list(state) || [],
    selectedRecord: quoteSelectors.selected(state),
    selectedSortFilter: 'Quote',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: quoteSelectors.isLoading(state),
    nextPage: quoteSelectors.nextPage(state),
    prevPage: quoteSelectors.prevPage(state),
    pageSize: quoteSelectors.pageSize(state),
    totalPages: quoteSelectors.totalPages(state),
    loadingPrevPage: quoteSelectors.loadingPrevPage(state),
    loadingNextPage: quoteSelectors.loadingNextPage(state),
    keyField: 'Quote',
    fieldsToDisplay: ['Quote', 'QuoteLabel', 'CustomerIdDisplay', 'CustomerIdLabel'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) matchParams.set('Quote', selectedRecord.Quote);

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const Quote = matchParams.get('Quote');
      if (Quote) {
        return {
          id: { Quote },
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: quoteActions.searchQuotes,
  searchById: quoteActions.searchById,
  changeSelectedRecord: quoteActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: quoteActions.fetchNextPage,
  fetchPrevPage: quoteActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSearchLookUpDrawer);
