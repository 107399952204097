import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import { OPERATIONS } from '../../../utils/operations';
import { CircularProgressSize } from '../../../utils/constants';

const inlineStyles = {
  preLoader: {
    margin: '320px calc(50% - 16px)'
  }
};

class Notepad extends Component {
  componentDidMount() {
   if (this.doesIdExist()) {
      this.getNotes();
   }
  }

  componentWillUnmount() {
    this.props.destroyForm();
  }

  componentDidUpdate(prevProps, prevState) {
    const { operationMode, dirty, formValues, note } = this.props;
    if (formValues && JSON.stringify(formValues.params) !== JSON.stringify(prevProps.formValues.params) && operationMode === OPERATIONS.BROWSE) {
      this.getNotes();
    }
    if (note !== prevProps.note && operationMode === OPERATIONS.BROWSE) {
      this.props.initialize('Notepad', { ...formValues, noteData: this.props.note ? this.props.note.noteData : '' });
    }
    if (operationMode === OPERATIONS.CANCEL) {
      this.cancelFormSubmission();
    }
    if (operationMode === OPERATIONS.SAVE) {
      if (dirty) {
        if (formValues.noteData) {
          this.setNotes();
        }
      }
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
  }

  cancelFormSubmission = () => {
    const { dirty } = this.props;
    if (dirty) {
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(OPERATIONS.EDIT);
        },
        onOk: () => {
          this.props.resetForm();
          this.props.changeOperationMode(OPERATIONS.BROWSE);
        }
      });
    } else {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
  }

  doesIdExist = () => {
    return this.props.formValues && Object.keys(this.props.formValues.params).reduce((acc, current) => {
      if (this.props.formValues.params[current]) {
        return true;
      }
    }, false);
  }

  getNotes = () => {
    const { fetchApi, formValues } = this.props;
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, formValues.params[curr]);
    };
    if (formValues && formValues.params !== '') {
      this.props.getNote(Object.keys(formValues.params).reduce(reducer, fetchApi));
    }
  }

  setNotes = () => {
    const { updateApi, formValues } = this.props;
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, formValues.params[curr]);
    };
    this.props.setNote((formValues && formValues.params && Object.keys(formValues.params).reduce(reducer, updateApi)) || updateApi, formValues.noteData);
  }

  render() {
    const { isLoading, label, fullWidth, required, operationMode, input, style } = this.props;

    return (
      isLoading &&
      <CircularProgress size={CircularProgressSize} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
    )
      ||
      <TextArea
        label={label}
        style={fullWidth ? { minWidth: 100, maxWidth: '100%', ...style } : { ...style }}
        required={required}
        disabled={operationMode === OPERATIONS.BROWSE}
        {...input}
      />;
  }
}

export default Notepad;
