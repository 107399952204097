import { FormViewField } from 'components/FormView';

export default {
    formName: 'RecallParkedSaleFilterRow',
    validate: null,
    rowModelType: 'serverSide',
    parameters: [
        {
            id: 1,
            type: 'EX_LOOKUP_FIELD',
            visible: true,
            props: {
                label: 'Entity',
                size: 'small',
                name: 'SalesEntity',
                lookupName: 'SalesEntity',
                disabled: false,
                required: true
            }
        },
        {
            id: 2,
            type: 'EX_LOOKUP_FIELD',
            visible: true,
            props: {
                label: 'User ID',
                size: 'small',
                name: 'UserID',
                lookupName: 'Personnel',
                disabled: false
            }
        },
        {
            id: '2',
            type: 'EX_LOOKUP_FIELD',
            props: {
                label: 'Username',
                name: 'Username',
                lookupName: 'Username',
                placeholder: '',
                size: 'medium',
                suppressDescription: true
            }
        },
        {
            id: '2',
            type: 'EX_LOOKUP_FIELD',
            props: {
                label: 'Customer',
                name: 'CustomerId',
                lookupName: 'Customer',
                placeholder: '',
                size: 'medium'
            }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Show parked sales',
            name: 'ShowParkedSales',
            required: false
          }
        },
    ] as FormViewField[]
};
