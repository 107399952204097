import { IFormViewForm } from 'components/FormView';

const ServiceItemTypeDetails: IFormViewForm = {
  id: 'ServiceItemTypeDetail',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1,
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Service Item Type',
            name: 'ServiceItemTypeId',
            placeholder: '',
            size: 'medium',
            inputProps: {
              maxLength: 10
            }
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            size: 'large',
            inputProps: {
              maxLength: 40
            }
          }
        }
      ]
    }
  ]
};
export default ServiceItemTypeDetails;
