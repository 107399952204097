import * as React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { googleApiKey } from './config';
import { MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';

import store from './store';
import Layout from './routes/_Layout';
import AppContent from './components/AppContent';
import Login from './components/Login';
import * as styles from './App.scss';
import theme from './theme';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ProcessValidationFormsContext, processValidationForms } from 'utils/processValidationForms';
import ActionBarContextProvider from 'utils/ActionBarContextProvider';
import { Wrapper } from '@googlemaps/react-wrapper';
import { HotKeys, configure } from 'react-hotkeys';
import { KEY_MAP } from 'utils/constants';
import ActionBarHotKeysWrapper from 'components/common/ActionBarHotKeysWrapper';

configure({
  ignoreEventsCondition: () => false
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
});

class App extends React.Component {
  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <HotKeys keyMap={KEY_MAP}>
          <JssProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <ProcessValidationFormsContext.Provider value={processValidationForms}>
                  <ActionBarContextProvider>
                    <ActionBarHotKeysWrapper>
                      <BrowserRouter>
                        <Wrapper apiKey={googleApiKey} libraries={['places']}>
                          <div className={styles.app} >
                            <Layout />
                            <Switch>
                              <Route exact path={'/'} component={Login} />
                              <Route path={'*'} component={AppContent} />
                            </Switch>
                          </div>
                        </Wrapper>
                      </BrowserRouter>
                    </ActionBarHotKeysWrapper>
                  </ActionBarContextProvider>
                </ProcessValidationFormsContext.Provider>
              </DndProvider>
            </MuiThemeProvider>
          </JssProvider>
        </HotKeys>
      </Provider>
    );
  }
}

export default App;
