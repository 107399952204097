import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RFCDetails from './RFCDetails';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';
import { selectors as rfcDetailsSelectors, actions as rfcDetailsActions } from 'ducks/rfcEnquiry/rfcDetails';
const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedRFC: rfcSelectors.selected(state),
  selectedForm: formSelectors.selected(state),
  rfcDetails: rfcDetailsSelectors.rfcDetails(state),
  rfcComment: rfcDetailsSelectors.comment(state),
  rfcDetailsSummary: rfcDetailsSelectors.rfcSummary(state),
  isLoading: rfcDetailsSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getRFCSummary: rfcDetailsActions.fetchSummary
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RFCDetails);
