import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';

export interface IPriceCalculationFactorsData {
  why: any;
}

export interface IPriceCalculationFactorsState extends IExtendedState<IPriceCalculationFactorsData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (filters) => (filters),
    }
  },
  'why');

const initialState = asyncInitialState<IPriceCalculationFactorsData>({
  why: null
});

export default (state: IPriceCalculationFactorsState = initialState, action: DataAction): IPriceCalculationFactorsState => {
  switch (action.type) {
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: IPriceCalculationFactorsData, successAction: SuccessAction) => {
        return {
          ...data,
          why: successAction.payload
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { priceCheck: { why: IPriceCalculationFactorsState } }) => state.priceCheck.why,
    {
      why: (data: IPriceCalculationFactorsData) => data.why,
    }),
  isLoading: (state: { priceCheck: { why: IPriceCalculationFactorsState } }) => state.priceCheck.why.loading,
};
