import * as React from 'react';

import { FormsValidator } from 'components/common/ValidationForms/validation';
import { isNull } from './utils';
import { changeValidationDialogCallback, snackBarCallback } from 'sagas/utils';
import { IFormInfo, IErrorValidationDetails } from 'api/swaggerTypes';
import { IValidationDialogAction } from 'components/common/ValidationDialog/ValidationDialog.properties';

/**
 * Processes Validation Forms and if necessary re-executes (recursively) Original request
 * @param responseContent API Response
 * @param forms Forms Content of an API Response
 * @param requestContent API Request Content
 * @param postFormsProcessing Action to use for Validated Forms
 * @param actionsCallback A generic single callback for all actions. Added to satisfy calling custom functionality
 */
export async function processValidationForms<T extends { Forms?: IFormInfo[]; ValidationErrors?: IErrorValidationDetails[] }>(responseContent: T, requestContent: any, postFormsProcessing: (formValues: any) => Promise<any>, actionsCallback?: (action: IValidationDialogAction) => void): Promise<T> {
    const { Forms } = responseContent;

    const forms = Forms;

    if (isNull(forms)) {
        return responseContent;
    }

    const validator = new FormsValidator(changeValidationDialogCallback, actionsCallback);
    const sortedForms = forms.sort((lhs, rhs) => lhs.Sequence - rhs.Sequence);
    const filteredForms = sortedForms.filter((form) => form?.DisplayType !== 'StatusMessage');
    const statusMessages = sortedForms.filter((form) => form?.DisplayType === 'StatusMessage');

    if (statusMessages.length) statusMessages.map((statusMessage) => { snackBarCallback(statusMessage); });

    try {
        const validationResponse = await validator.processValidationForms(filteredForms, { formValues: requestContent, response: responseContent });
        if (validationResponse.submitFormValues) {
            return await postFormsProcessing(validationResponse.formValues);
        } else {
            return responseContent;
        }
    } catch (err) {
        return responseContent;
    }
}

export const ProcessValidationFormsContext = React.createContext(processValidationForms);

export const useProcessValidationForms = () => React.useContext(ProcessValidationFormsContext);
