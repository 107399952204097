import { asyncSelectors, IExtendedState, initializeReducer } from './utils';
import { IProgramAreaResponse, IProgramArea } from 'api/swaggerTypes';
import { IDashboardWidget, IDashboardWidgetResponse } from 'components/DashboardMaintenance/DashboardMaintenance.properties';

export interface IProgramAreaData {
  ProgramAreas: IProgramArea[];
  DashboardWidgets: IDashboardWidget[];
}
export interface IProgramAreaState extends IExtendedState<IProgramAreaData> {
  fetchProgramAreas_loading?: boolean;
  fetchDashboardWidgets_loading?: boolean;
}

const initialData: IProgramAreaData = {
  ProgramAreas: [],
  DashboardWidgets: []
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'programArea',
  initialData: initialData,
  asyncActions: {
    fetchProgramAreas: {
      action: () => null,
      successCallback: (data: IProgramAreaData, payload: IProgramAreaResponse): IProgramAreaData => {
        return {
          ...data,
          ProgramAreas: payload ? payload.ProgramAreas : []
        };
      }
    },
    fetchDashboardWidgets: {
      action: () => null,
      successCallback: (data: IProgramAreaData, payload: IDashboardWidgetResponse): IProgramAreaData => {
        return {
          ...data,
          DashboardWidgets: payload ? payload.DashboardWidgets : []
        };
      }
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { programArea: IProgramAreaState }) => state.programArea,
  {
    programAreas: (data: IProgramAreaData) => data.ProgramAreas,
    dashboardWidgets: (data: IProgramAreaData) => data.DashboardWidgets,
  }
);

const syncSelector = {
  isLoading: (state: { programArea: IProgramAreaState }) => state.programArea.fetchProgramAreas_loading,
  isFetchWidgetsLoading: (state: { programArea: IProgramAreaState }) => state.programArea.fetchDashboardWidgets_loading,
};

export const selectors = { ...asyncSelector, ...syncSelector };
