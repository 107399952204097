import { IFormViewForm } from 'components/FormView';

const QuoteConfirmationForm: IFormViewForm = {
    id: 'QuoteConfirmationForm',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1,
                colspan: 3
            },
            type: 'PAPER_CONTAINER',
            props: {
                elevation: 0
            },
            children: [
                {
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Generate Quote',
                        name: 'OutputQuote'
                    }
                },
                {
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    props: {
                        name: 'QuoteOutput',
                        documentType: 'CN',
                        fieldId: 'QuotePrinter',
                        isCustomerScoped: true,
                        size: 'large',
                        precedentFields: ['OutputQuote']
                    }
                },
                {
                    type: 'DIVIDER',
                    props: {
                        name: 'divider',
                    }
                },
                {
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Description',
                        name: 'Description',
                        size: 'large',
                    }
                },
                {
                    type: 'TEXT_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Contact',
                        name: 'Contact',
                        size: 'medium',
                    }
                },
                {
                    type: 'ACTION_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Phone',
                        name: 'Phone',
                        size: 'medium',
                        action: {
                            iconName: 'Phone',
                        }
                    }
                },
                {
                    type: 'ACTION_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Fax',
                        name: 'Fax',
                        size: 'medium',
                        action: {
                            iconName: 'Phone',
                        }
                    }
                },
                {
                    type: 'ACTION_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Mobile',
                        name: 'Mobile',
                        size: 'medium',
                        action: {
                            iconName: 'Phone',
                        }
                    }
                },
                {
                    type: 'ACTION_FIELD',
                    props: {
                        label: 'Email',
                        type: 'email',
                        name: 'Email',
                        size: 'large',
                        action: {
                            iconName: 'Mail',
                        }
                    }
                },
                {
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Quote type',
                        name: 'QuoteTransactionType',
                        size: 'small',
                        lookupName: 'QuoteType',
                        isLookupTypeFixed: true
                    }
                },
                {
                    type: 'DATE_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Expiry date',
                        name: 'ExpiryDate',
                        size: 'small',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                    }
                },
                {
                    type: 'TOGGLE_FIELD',
                    style: {
                        width: '50%',
                        display: 'inline-block'
                    },
                    props: {
                        label: 'Reserve all available stock',
                        name: 'ReserveAllAvailableStock',
                    }
                },
            ]
        }
    ]
};
export default QuoteConfirmationForm;
