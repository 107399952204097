import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

export interface IBOMLotsData {
  selected: any;
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  lineColumns: IColDef[];
  lines: any[];
}

export interface IBOMLotsState extends IExtendedState<IBOMLotsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clearLines: () => null,
    asyncs: {
      fetch: (Data) => (Data),
    }
  },
  'PSbomLots');

const initialState = asyncInitialState<IBOMLotsData>({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  lineColumns: [
    { headerName: 'Lot', field: 'LotNumber', minWidth: 150, suppressSorting: true },
    { headerName: 'Quantity', field: 'LotQuantityDisplay', minWidth: 150, suppressSorting: true },
  ],
  lines: []
});

export default (state: IBOMLotsState = initialState, action: IDataAction): IBOMLotsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload && successAction.payload.records;

        return {
          ...data,
          lines: linesData
        };
      });

    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { bomLots: IBOMLotsState } }) => state.pickSlips.bomLots,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
  }
);

const syncSelector = {
  isLoading: (state: { pickSlips: { bomLots: IBOMLotsState } }) => state.pickSlips.bomLots.loading,
  gridOptions: (state: { pickSlips: { bomLots: IBOMLotsState } }) => state.pickSlips.bomLots.data.gridOptions,
  lineColumns: (state: { pickSlips: { bomLots: IBOMLotsState } }) => state.pickSlips.bomLots.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
