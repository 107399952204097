import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as scheduleSelectors, actions as scheduleActions } from 'ducks/inventoryEnquiry/pricing/schedule';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/pricing/schedule';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'Schedule',
  columnDefs: scheduleSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: scheduleSelectors.gridOptions(state),
  isLoading: scheduleSelectors.isLoading(state),
  filterRow: scheduleSelectors.filterRow(state),
  selectedFilters: scheduleSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) && {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  appliedFilters: scheduleSelectors.filterRow(state) && filterSelectors.getFilters(state, scheduleSelectors.filterRow(state).formName),
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: scheduleActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}