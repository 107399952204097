import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Paging, Sorting, HeaderFilter, Editing, Button } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { ILineCommitmentGridProps } from './LineCommitmentGrid.properties';
import styles from './LineCommitmentGrid.styles';

import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { createDataSource, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { useRetreiveOutstandingDocuments, useUpdateOutstandingDocument } from 'api/Worksale/backorderCommitment';
import { isScreenRequest } from 'api/utils';
import UserIdScreenDialog from 'components/Worksale/UserIdScreenDialog';
import { Paper } from '@material-ui/core';
import { isNull } from 'utils/utils';

const SMALL_COLUMN_MIN_WIDTH = 100;
const SMALL_COLUMN_DEFAULT_WIDTH = 150;
const COMMAND_COLUMN_WIDTH = 75;
const LARGE_COLUMN_MIN_WIDTH = 200;
const NumberOfDecimalPlaces = 2;

function LineCommitmentGrid(props: Readonly<ILineCommitmentGridProps>): JSX.Element {
    const { classes, salesOrder, lineNumber, onLineUpdate, navigateBack
    } = props;
    const dataGridInnerRef = React.useRef<DataGrid>();

    const { onFocusedCellChanging, onKeyDown } = useGridKeyboardNavigation();

    const lineCommitmentLinesFetchMutation = useRetreiveOutstandingDocuments();
    const updateLineCommitmentUpdateMutation = useUpdateOutstandingDocument();

    const [userIdScreenDialogOpen, setUserIdScreenDialogOpen] = React.useState(false);

    const userIdScreenDialogData = updateLineCommitmentUpdateMutation.data?.UpdateCommitment?.inlineObject?.UpdateCommitmentCriteria;
    const dialogTitle = updateLineCommitmentUpdateMutation.data?.Forms?.[0]?.Title;
    const dialogMessage = updateLineCommitmentUpdateMutation.data?.Forms?.[0]?.Message;

    const loadLines = React.useCallback(
        async (BatchPage, BatchSize, Sort, filter) => {
            const response = await lineCommitmentLinesFetchMutation.mutateAsync({
                OrderNumber: salesOrder,
                LineNumber: lineNumber,
                urlQueryParams: {
                    BatchPage,
                    BatchSize,
                    Sort,
                    FilterByUncommitted: filter?.CommittedQuantity
                }
            });

            return response?.OutstandingDocuments ?? [];
        },
        [salesOrder, lineNumber]
    );

    const submitUserIdData = React.useCallback(
        async (data: any) => {

            const response = await updateLineCommitmentUpdateMutation.mutateAsync({
                ...updateLineCommitmentUpdateMutation.data?.UpdateCommitment?.inlineObject.UpdateCommitmentCriteria,
                OrderNumber: updateLineCommitmentUpdateMutation.data?.UpdateCommitment?.inlineObject.SalesOrder,
                LineNumber: updateLineCommitmentUpdateMutation.data?.UpdateCommitment?.inlineObject.LineNumber,
                ...data,
            });

            if (response?.Status && isNull(response?.Forms)) {
                setUserIdScreenDialogOpen(false);
                reloadLines();
                onLineUpdate();
            }

            return response;
        },
        [updateLineCommitmentUpdateMutation.data]
    );

    const updateLine = React.useCallback(
        async (data: any) => {
            const item = dataGridInnerRef.current?.instance?.getDataSource().items().find((f) => f.Id === data.Id);

            const response = await updateLineCommitmentUpdateMutation.mutateAsync({
                ...item,
                ...data,
                OrderNumber: salesOrder,
                LineNumber: lineNumber,
                Sort: ''
            });

            if (response.Status && isScreenRequest(response.Forms) && response.Forms?.[0]?.FormId === 'UserID') {
                setUserIdScreenDialogOpen(true);
            } else if (response.Status && !response.Forms) {
                reloadLines();
                onLineUpdate();
            }

            return response;
        },
        [salesOrder, lineNumber, dataGridInnerRef, userIdScreenDialogOpen]
    );
    const commitedFilters = [
        {
            text: 'Lines With A Committed Quantity',
            value: 'false'
        },
        {
            text: 'Lines Having No Committed Quantity',
            value: 'true'
        }
    ];

    const lineCommitmentDataSource = React.useMemo(
        () => createDataSource(
            'Id',
            { fetch: loadLines, update: updateLine },
            {
                CommittedQuantity: commitedFilters
            }
        ),
        [loadLines]
    );

    const reloadLines = () => {
        return lineCommitmentDataSource.reload()
            .then(() => {
                dataGridInnerRef.current.instance.refresh(true);
            });
    };

    React.useEffect(
        () => {
            if (!lineCommitmentDataSource.isLoading() && lineCommitmentDataSource?.items().length === 0) {
                navigateBack();
            }
        },
        [lineCommitmentDataSource?.items().length, lineCommitmentDataSource.isLoading()]
    );

    return (
        <Paper className={classes.paperContainer}>
            <DataGrid
                ref={dataGridInnerRef}
                className={classes.commitmentDataGrid}
                dataSource={lineCommitmentDataSource}
                showBorders={false}
                renderAsync={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                onKeyDown={onKeyDown}
                onFocusedCellChanging={onFocusedCellChanging}
                noDataText=''
                onEditorPreparing={(e) => {
                    e.editorOptions.onFocusIn = (args) => {
                        const input = args.element.querySelector("input[type='text']");
                        if (input) {
                            input.select();
                        }
                    };
                }}
            >
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}
                />
                <HeaderFilter visible />
                <Column
                    dataField={'DocumentType'}
                    caption={'Type'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                >
                </Column>
                <Column
                    dataField={'DocumentNumber'}
                    caption={'Document'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                >
                </Column>
                <Column
                    dataField={'UncommittedQuantity'}
                    caption={'Uncommitted'}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    editorOptions={{
                        step: 0
                    }}
                    calculateDisplayValue={(data: Record<string, unknown>) => (data.UncommittedQuantity as number).toFixed(NumberOfDecimalPlaces)}

                />
                <Column
                    dataField={'CommittedQuantity'}
                    caption={'Committed'}
                    allowHeaderFiltering={true}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    editorOptions={{
                        step: 0
                    }}
                    calculateDisplayValue={(data: Record<string, unknown>) => (data.CommittedQuantity as number).toFixed(NumberOfDecimalPlaces)}
                >
                    <HeaderFilter dataSource={commitedFilters} />
                </Column>
                <Column
                    dataField={'EarliestDue'}
                    caption={'Earliest due'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                />
                <Column
                    dataField={'Reference'}
                    caption={'Reference'}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    minWidth={LARGE_COLUMN_MIN_WIDTH} />
                <Column
                    dataField={'PurchaseOrderLineNumber'}
                    visible={false} />
                <Column
                    dataField={'PurchaseOrder'}
                    visible={false} />
                <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false} fixed={true}>
                    <Button name={'save'} icon={'save'} />
                    <Button name={'edit'} icon={'edit'} disabled={false} />
                    <Button name={'cancel'} icon={'undo'} />
                </Column>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Sorting mode='none' />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>

            <UserIdScreenDialog
                open={userIdScreenDialogOpen}
                title={dialogTitle ?? 'Commit Confirmation'}
                message={dialogMessage ?? 'Are you sure you want to commit to this document?'}
                loading={false}
                data={{
                    UserId: userIdScreenDialogData?.OverrideUserId,
                    Pin: userIdScreenDialogData?.OverrideUserPIN
                }}
                onOk={(data) => submitUserIdData({ OverrideUserId: data.UserId, OverrideUserPIN: data.Pin })}
                onCancel={() => setUserIdScreenDialogOpen(false)}
            />
        </Paper>

    );
}

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(LineCommitmentGrid)));
