import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as lotInPutAwaySelectors, actions as lotInPutAwayActions } from 'ducks/inventoryEnquiry/stocking/lotInPutAway'
import { selectors as lotSelectors } from 'ducks/inventoryEnquiry/stocking/lotDetails'
import { fetch } from 'api/inventoryEnquiry/stocking/lotInPutAway';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'IEStockingLotInPutAway',
  columnDefs: lotInPutAwaySelectors.columns(state),
  rowData: lotInPutAwaySelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lotInPutAwaySelectors.gridOptions(state),
  isLoading: lotInPutAwaySelectors.isLoading(state),
  filterRow: lotInPutAwaySelectors.filterRow(state),
  appliedFilters: lotInPutAwaySelectors.filterRow(state) && filterSelectors.getFilters(state, lotInPutAwaySelectors.filterRow(state).formName),
  selectedFilters: lotInPutAwaySelectors.selectedFilters(state),
  reqParams:
    (lotSelectors.selected(state) || params.get('ProductId'))
    && (lotSelectors.selected(state) || params.get('Warehouse'))
    && (lotSelectors.selected(state) || params.get('LotSerial'))
    && {
      ProductId: (lotSelectors.selected(state) && lotSelectors.selected(state).ProductId) || params.get('ProductId'),
      Warehouse: (lotSelectors.selected(state) && lotSelectors.selected(state).Warehouse) || params.get('Warehouse'),
      LotSerial: (lotSelectors.selected(state) && lotSelectors.selected(state).LotSerial) || params.get('LotSerial')
    },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lotInPutAwayActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
