export default [
  {
    id: '1',
    name: 'Ipec',
    abbreviation: 'I',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Xyz',
    abbreviation: 'X',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'Abc',
    abbreviation: 'A',
    fieldList1: 'field'
  },
];
