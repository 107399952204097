import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './Details.styles';
import FormView from 'components/FormView';
import PickSlipDetailForm from './Details.form';
import IDetailsProperties, { IDetailsHandle } from './Details.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import { useCalculatePickSlipDetail, useFetchPickSlipDetail, useSetPickSlipDetailsQueryData, useUpdatePickSlipDetail } from 'api/pickSlips/pickslip';
import { objectifyAddressForPost } from 'api/utils';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useRetrievePickSlipSummary } from 'api/pickSlips/slipLinesUpdated';
import { isNull } from 'utils/utils';

const Details = (props: IDetailsProperties, ref: React.Ref<IDetailsHandle>) => {
    const { classes, selectedDespatchId, selectedCustomerId, formValues, dirty, changeConfirmationDialog, resetForm } = props;

    const { setActionBar } = React.useContext(ActionBarContext);

    const { showSnackBar } = useSnackBar();
    const pickSlipDetail = useFetchPickSlipDetail(selectedDespatchId);
    const calculateDetailMutation = useCalculatePickSlipDetail();
    const updateDetailMutation = useUpdatePickSlipDetail();
    const setPickSlipDetailsQueryData = useSetPickSlipDetailsQueryData();
    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const isDirty = internalDirty || dirty;

    const fetchSummary = useRetrievePickSlipSummary(selectedDespatchId);
    const despatchStatus = fetchSummary.data?.PickSlipSummary?.inlineObject?.Status;
    const isDespatchCancelled = fetchSummary.isLoading || despatchStatus?.includes('Cancelled');

    React.useEffect(
        () => {
            setActionBarButtons();
            resetForm();
        },
        [isDespatchCancelled]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (isDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !isDirty;
            },
            async ok(navigateBack: () => void): Promise<void> {
                const response = await updateDetailMutation.mutateAsync({
                    ...formValues,
                    ...objectifyAddressForPost((formValues as any)?.address, '', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
                });
                if (response?.Status === true) {
                    setInternalDirty(false);
                    navigateBack();
                }
            },
            onCancel(navigateBack: () => void): void {
                if (isDirty) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: () => {
                            setInternalDirty(false);
                            resetForm();
                            navigateBack();
                        }
                    });
                } else {
                    resetForm();
                    navigateBack();
                }
            }
        }),
        [formValues, isDirty]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                    action: 'PickSlipDetailsCancel',
                },
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    disabled: isNull(selectedDespatchId) || isDespatchCancelled,
                    action: 'PickSlipDetailsOk',
                },
            ]
        });
    };

    const calculatePickSlipDetailDetails = React.useCallback(
        async (fieldName, value) => {
            if (fieldName && value && pickSlipDetail.data?.PickSlip?.inlineObject[fieldName] !== value) {
                const response = await calculateDetailMutation.mutateAsync({
                    DespatchId: selectedDespatchId,
                    ...formValues,
                    ...objectifyAddressForPost((formValues as any)?.address, '', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
                    address: undefined,
                    [fieldName]: value,
                    urlQueryParams: {
                        ChangedField: fieldName,
                    }
                });

                if (response?.Status === true && response?.PickSlip) {
                    setPickSlipDetailsQueryData(selectedDespatchId, response);
                    setInternalDirty(true);
                }
            }
        },
        [selectedDespatchId, pickSlipDetail, formValues]
    );

    const onLookupSelectedItemChange = React.useCallback(
        (name, item) => {
            calculatePickSlipDetailDetails(name, item);
        },
        [calculatePickSlipDetailDetails]
    );

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={false}
                customerId={Number(selectedCustomerId)}
                formName={'PickSlipDetailsForm'}
                schema={PickSlipDetailForm}
                includeTabsHeight={false}
                initialValues={pickSlipDetail.data?.PickSlip?.inlineObject}
                valuesSchema={pickSlipDetail.data?.PickSlip?.schema}
                operationMode={'EDIT'}
                fieldExtensions={{
                    Sitecode: {
                        onSelectedItemChange: (item, searchText) => onLookupSelectedItemChange('Sitecode', item?.Code ?? searchText)
                    },
                    FreightCode: {
                        onSelectedItemChange: (item, searchText) => onLookupSelectedItemChange('FreightCode', item?.Code ?? searchText)
                    },
                    DeliveryType: {
                        onChange: (value: any) => (onLookupSelectedItemChange('DeliveryType', value[0]))
                    }
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Details)));
