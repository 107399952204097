import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { OPERATIONS } from '../../../utils/operations';
import { ICreditNoteDetailsProperties } from '../interfaces';
import { isShallowEqual, pathOr, isNull } from 'utils/utils';

class CreditNoteDetails extends React.PureComponent<ICreditNoteDetailsProperties> {

  formView: FormView;

  constructor(props: Readonly<ICreditNoteDetailsProperties>) {
    super(props);
  }

  componentDidMount(): void {
    const { selectedTab, creditNoteFetched } = this.props;
    if (selectedTab !== 'CNDetails') {
      this.props.changeSelectedTab('CNDetails');
    }

    if (creditNoteFetched) {
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    this.props.getCreditNote();
    this.props.toggleCreditNotesLookupPanel(false);
  }

  componentWillUnmount(): void {
    this.props.destroyForm();
  }

  anyActive = () => {
    const { meta } = this.props;
    const { SiteCode } = meta;
    let anyActiveField = false;
    anyActiveField = SiteCode && SiteCode.active;

    return anyActiveField;
  }

  isNull = (value: any) => {
    return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
  }

  componentDidUpdate(prevProps: Readonly<ICreditNoteDetailsProperties>): void {
    this.checkOperation(prevProps);
  }

  checkOperation = (prevProps: Readonly<ICreditNoteDetailsProperties>) => {
    const { dirty, values, isValid, operationMode, creditNoteFetched, saveCreditNoteDetails } = this.props;
    if (operationMode === OPERATIONS.SAVE && isValid) {
      saveCreditNoteDetails(values);
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if (operationMode === OPERATIONS.BROWSE && creditNoteFetched) {
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if ((operationMode === OPERATIONS.EDIT || operationMode === OPERATIONS.SAVE) && !creditNoteFetched) {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }

    if (operationMode === OPERATIONS.SAVE && dirty && !isValid && creditNoteFetched) {
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if (operationMode === OPERATIONS.CANCEL && prevProps.operationMode !== OPERATIONS.CANCEL) {
      this.cancelFormSubmission(prevProps);
    }
  }

  cancelFormSubmission = (prevProps: Readonly<ICreditNoteDetailsProperties>) => {
    const { dirty } = this.props;
    if (dirty) {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          this.props.changeOperationMode(OPERATIONS.BROWSE);
          this.props.cancelCreditNoteDetails();
          this.props.destroyForm();
        }
      });
    } else {
      this.props.changeOperationMode(OPERATIONS.EDIT);
      this.props.cancelCreditNoteDetails();
    }
  }

  handleOnBlur = () => {
    this.handleChange();
  }

  handleChange = () => {
    const { values, initialValues } = this.props;
    if (this.compareFormValues(initialValues, values)) {
      const reqFormValues = this.fillEmptyDecimalFields(values);
      this.props.updateDetails(reqFormValues);
    }
  }

  fillEmptyDecimalFields = (formValues: any = {}) => {
    const tempChildren = this.getChildren();

    tempChildren.forEach((child: any) => {
      const { props = {} } = child;
      if (props.type === 'number' && isNull(formValues[props.name])) {
        formValues[props.name] = 0;
      }
    });

    return formValues;
  }

  compareFormValues = (lastUpdatedValues: any, formValues: any = {}) => {
    const tempChildren = this.getChildren();
    let hasChanged = false;

    for (const child of tempChildren) {
      if (hasChanged) break;
      const { props = {} } = child;
      const formValue = formValues[props.name];
      const savedValue = lastUpdatedValues[props.name];
      hasChanged = (props.type === 'number') ?
        Number(formValue) !== Number(savedValue) :
        !isShallowEqual(formValue, savedValue);
    }

    return hasChanged;
  }

  getChildren = () => {
    const { selectedForm } = this.props;
    const fields = pathOr([], ['fields'], selectedForm);
    let tempChildren = [];

    fields.forEach((field: any) => {
      const children = pathOr([], ['children'], field);
      tempChildren = tempChildren.concat(children);
    });

    return tempChildren;
  }

  render(): React.ReactNode {
    const {
      isBrowseLookUpOpen, selectedTab, selectedForm,
      operationMode, details, detailsSchema } = this.props;

    let customerId: number = null;
    if (details && details.CustomerId) {
      customerId = (typeof (details.CustomerId) === 'number') ? details.CustomerId : parseInt(details.CustomerId);
    }

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={{ ...(this.props.creditNoteNotifications || {}), update: this.props.creditNoteNotifications && this.props.creditNoteNotifications.saveDetails }}
          errors={this.props.creditNoteErrors}
          formSyncErrors={this.props.formSyncErrors}
          entity={'Credit Notes'}
          isValid={this.props.isValid}
        />

        <FormView
          formName={'CNOrderDetails'}
          customerId={customerId}
          ref={(el) => this.formView = el}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          valuesSchema={detailsSchema}
          includeTabsHeight={false}
          initialValues={details}
          operationMode={operationMode}
          enableReinitialize={true}
          onBlur={this.handleOnBlur}
        />
      </React.Fragment>);
  }
}

export default CreditNoteDetails;
