import { IFormViewForm } from 'components/FormView';

const Comments: IFormViewForm = {
  id: 'Comments',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'Comments',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            label: 'Supplier invoice comments',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '650px'
            }
          }
        }
      ]
    }
  ]
};
export default Comments;
