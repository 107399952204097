import { SchedulerData, ViewTypes } from '@markinson/mk.mpv4.schedulercomponent';
import moment from 'moment';
import lodash from 'lodash';
import { AsObjectified, AsObjectifiedArray, IObjectified } from 'api/utils.d';
import { FormViewField } from 'components/FormView';
import {
    ISchedulerJobSearchResponse, ISchedulerJobSearchFacade, ISchedulerJobDetailsResponse,
    ISortOrder, ISchedulerJobDetailFacade, IResourcesResponse,
    IActivityScheduleFacade, IShift,
    IRostertemplateDetailsResponse,
    IServiceJobSummaryFacade,
    ISchedulerActivitySearchFacade,
    IActivityScheduleResponse,
    IServiceActivityScheduleFacade,
    IServiceJobScheduleFacade,
    IFormInfo,
    INewGeneralActivityScheduleFacade,
    IGeneralActivityScheduleFacade,
    IServiceJobActivitySummaryFacade,
    IServiceJobCustomerDetailsFacade,
    ISearchServiceJobFacade
} from 'api/swaggerTypes';
import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { IServiceActivitySchedulingState } from './';
import { syncSelectors } from 'ducks/utils';
import { ObjectifiedDailyResourceActivityScheduleResponse, IObjectifiedResourceActivitySchedule, IObjectifiedIUpcomingServiceJobs } from 'api/serviceScheduling/serviceActivities';
import { isNull } from 'utils/utils';
import { initalSchedulerData } from './serviceActivityScheduling.constants';

export interface IServiceJobSummaryResponse {
    'ServiceJob'?: IObjectified<IServiceJobSummaryFacade>;
    'Status'?: boolean;
    'Forms'?: IFormInfo[];
}

export interface IServiceActivityFilters {
    SalesEntity?: string;
    JobStatusFilter?: string;
}

export interface IDataGridData {
    Key: number;
    ServiceItem: number;
    ServiceItemText: string;
    ItemType: string;
    LabourCostTotal: string;
    MaterialCostTotal: string;
    TotalCostTotal: string;
    Activities: IServiceJobActivitySummaryFacade[];
}

export interface IAdjustmentProps {
    AdjustmentAmount?: number;
    AdjustmentDescription?: string;
    AdjustmentProductId?: string;
    ServiceJobId?: string;
}
export interface IResource { id: string; name: string; shifts?: IShift[]; }

export interface IEventItem {
    id: any;
    start: string;
    end: string;
    resourceId: string;
    description?: string;
    title?: string;
    estimate?: string;
    activityId?: number;
    messageType?: string;
    Capabilities?: string[];
    message?: string;
    showPopover?: boolean;
    isActivity?: boolean;
    serviceJobId?: string;
    activityType?: 'ServiceActivity' | 'ServiceJob' | 'General';
}
export interface IEventData {
    schedulerData: SchedulerData;
    slotId: any;
    slotName: string;
    date: any;
    start: string;
    end: string;
    item: IEventItem;
}

export interface ISchedule extends IServiceActivityScheduleFacade, IServiceJobScheduleFacade {
    itemType?: 'ServiceActivity' | 'ServiceJob' | 'General';
}

export interface IServiceActivityData {
    schedulerData: SchedulerData;
    schedulerDataTriggerString: string;
    newEventData?: IEventData;
    moveEventData?: IEventData;
    selectedId: any;
    adjustmentProps: IAdjustmentProps;
    selectedActivityScheduleId: number;
    hoverSelectedActivityScheduleId: number;
    hoverSelectedActivityOptionsId: number;
    hoverSelectedActivityScheduleOptionsId: number;
    openScheduleActivities: boolean;
    openScheduleActivityOptions: number;
    isHover: boolean;
    openMoveActivities: boolean;
    list: ISchedulerJobSearchFacade[];
    reviewJobsData: ISchedulerJobSearchFacade[];
    reviewJobSummary: IObjectified<IServiceJobSummaryFacade>;
    dataGridData: IDataGridData[];
    resourcesList: IResource[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    removeWhenPrev: number;
    selectedFilters: IServiceActivityFilters;
    filterHeader: {
        applyOnStart?: boolean;
        formName: string;
        parameters: FormViewField[];
    };
    salesEntity?: string;
    jobDetails?: ISchedulerJobDetailFacade;
    ServiceJobId?: string;
    activityDetails?: {
        'HyperlinkReference'?: string;
        'ActivityCode'?: string;
        'ActivityId'?: number;
        'CapabilitiesRequired'?: string;
        'Description'?: string;
        'ScheduleEndDate'?: string;
        'Estimate'?: string;
        'ScheduleStatus'?: string;
        'ServiceJobId'?: string;
        'ScheduleStartDate'?: string;
        'Status'?: string;
    };
    selectedCustomerDetails: IServiceJobCustomerDetailsFacade;
    upcomingServiceDate: string;
    upcomingServiceJobs: IObjectifiedIUpcomingServiceJobs;
}

export interface IServiceActivityState extends IExtendedState<IServiceActivityData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
    fetchReviewJobSummary_loading?: boolean;
    applyReviewJobAdjustments_loading?: boolean;
    fetchActivityImages_loading?: boolean;
    getScheduledActivities_loading?: boolean;
    getResources_loading?: boolean;
    fetchJobsToReview_loading?: boolean;
    getServiceAgreementJobs_loading?: boolean;
    deleteActivitySchedule_loading?: boolean;
    reAddDeletedJobToList_loading?: boolean;
}

export interface ISearchQuery { SearchText: string; Sort?: ISortOrder; BatchPage?: number; filters?: IServiceActivityFilters; }

const initSchedulerData = new SchedulerData(moment().format('YYYY-MM-DD'), ViewTypes.Week, false, false, initalSchedulerData);

initSchedulerData.setResources([]);
initSchedulerData.setEvents([]);

const NOT_SELECTED = -1;

const initialData: IServiceActivityData = {
    schedulerDataTriggerString: '',
    schedulerData: initSchedulerData,
    newEventData: null,
    adjustmentProps: {},
    selectedId: NOT_SELECTED,
    selectedActivityScheduleId: NOT_SELECTED,
    hoverSelectedActivityScheduleId: NOT_SELECTED,
    hoverSelectedActivityOptionsId: NOT_SELECTED,
    hoverSelectedActivityScheduleOptionsId: NOT_SELECTED,
    openScheduleActivities: false,
    openScheduleActivityOptions: NOT_SELECTED,
    isHover: false,
    openMoveActivities: false,
    list: [],
    reviewJobsData: [],
    reviewJobSummary: { inlineObject: {}, schema: {} },
    dataGridData: null,
    resourcesList: [],
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    removeWhenPrev: 0,
    ServiceJobId: '',
    selectedFilters: {
        SalesEntity: '',
        JobStatusFilter: 'Unscheduled'
    },
    salesEntity: '',
    filterHeader: {
        applyOnStart: true,
        formName: 'serviceActivityFilters',
        parameters: [
            {
                id: 0,
                type: 'EX_LOOKUP_FIELD',
                props: {
                    label: '',
                    name: 'SalesEntity',
                    required: true,
                    lookupName: 'SalesEntity',
                    placeholder: 'Entity',
                    size: 'large',
                    style:
                    {
                        minWidth: '284px'
                    },
                    suppressDescription: true
                }
            },
            {
                id: 1,
                type: 'SELECT_FIELD',
                props: {
                    label: 'Job Status',
                    name: 'JobStatusFilter',
                    required: false,
                    size: 'large',
                    options: [
                        {
                            value: 'Unscheduled',
                            label: 'Unscheduled'
                        },
                        {
                            value: 'Overdue',
                            label: 'Overdue'
                        },
                        {
                            value: 'PartiallyScheduled',
                            label: 'Partially Scheduled'
                        },
                        {
                            value: 'Scheduled',
                            label: 'Scheduled'
                        },
                        {
                            value: 'All',
                            label: 'All'
                        },
                    ]
                }
            }
        ] as FormViewField[]
    },
    selectedCustomerDetails: {},
    upcomingServiceDate: '',
    upcomingServiceJobs: []
};

function getActivity(data: IServiceActivityData, activityId: number): ISchedulerActivitySearchFacade {
    if (data.list && data.list.length > 0) {
        const matchedServiceJob = data.list.find((serviceJob: ISchedulerJobSearchFacade) => serviceJob.Activities && serviceJob.Activities.length > 0 && serviceJob.Activities.some((child: ISchedulerActivitySearchFacade) => child.ActivityId === activityId));
        if (matchedServiceJob && matchedServiceJob.Activities) {
            return matchedServiceJob.Activities.find((activity: ISchedulerActivitySearchFacade) => activity.ActivityId === activityId);
        }
    }

    return null;
}

function getSelectedActivity(data: IServiceActivityData): ISchedulerActivitySearchFacade {
    if (data.list) {
        return data.list.find((serviceJob: ISchedulerJobSearchFacade) => serviceJob.ServiceJobId === data.selectedId) ||
            data.list.map((serviceJob: ISchedulerJobSearchFacade) => serviceJob.Activities)
                .reduce((allActivities, serviceJobActivities) => [...allActivities, ...serviceJobActivities], [])
                .find((activity: ISchedulerActivitySearchFacade) => activity.ActivityId === data.selectedId);
    }

    return null;
}
export interface IActivityScheduleFacadeDetail extends IActivityScheduleFacade {
    CustomerIdLabel?: string;
}
function getScheduleEvents(activitySchedules: IObjectified<IActivityScheduleFacadeDetail>[]): IEventItem[] {
    return activitySchedules.map((item) => {
        const { inlineObject: event, schema } = item;
        const date = moment(event.WorkDate, schema.WorkDate.Format.toUpperCase());
        const startTime = moment(event.StartTime, schema.StartTime.Format.replace('tt', 'a'));
        const endTime = moment(event.EndTime, schema.EndTime.Format.replace('tt', 'a'));
        const start = moment(date).set('hour', startTime.get('hour')).set('minute', startTime.get('minute'));
        const end = moment(date).set('hour', endTime.get('hour')).set('minute', endTime.get('minute'));

        return {
            id: event.ActivityScheduleId,
            start: start.format('YYYY-MM-DDTHH:mm'),
            end: end.format('YYYY-MM-DDTHH:mm'),
            resourceId: event.ResourceId.toString(),
            title: event.Title,
            description: event.Description,
            activityId: Number.parseFloat(event.ActivityId),
            messageType: event.MessageType,
            message: event.Message,
            showPopover: !isNull(event.MessageType),
            activityType: event.ActivityType,
            Capabilities: event.Capabilities,
            serviceJobId: event.ServiceJobId,
            customerId: event.CustomerId,
            customerName: event.CustomerIdLabel
        };
    });
}

function updateResourceScheduleEvents(existingEvents: IEventItem[], resourceActivitySchedules: IObjectifiedResourceActivitySchedule[]): IEventItem[] {
    return resourceActivitySchedules.reduce(
        (events, scheduleDate) => {
            return updateEvents(events, scheduleDate);
        },
        [...existingEvents]);
}

function updateEvents(existingEvents: IEventItem[], resourceSchedule: IObjectifiedResourceActivitySchedule): IEventItem[] {
    const { ResourceId, WorkDate, ActivitySchedules } = resourceSchedule.inlineObject;
    const workDate = moment(WorkDate, resourceSchedule.schema.WorkDate.Format.toUpperCase());
    const updatedEvents = ActivitySchedules.map((item) => {
        const { inlineObject: event, schema } = item as IObjectified<IActivityScheduleFacadeDetail>;
        const date = moment(event.WorkDate, schema.WorkDate.Format.toUpperCase());
        const startTime = moment(event.StartTime, schema.StartTime.Format.replace('tt', 'a'));
        const endTime = moment(event.EndTime, schema.EndTime.Format.replace('tt', 'a'));
        const start = moment(date).set('hour', startTime.get('hour')).set('minute', startTime.get('minute'));
        const end = moment(date).set('hour', endTime.get('hour')).set('minute', endTime.get('minute'));

        return {
            id: event.ActivityScheduleId,
            start: start.format('YYYY-MM-DDTHH:mm'),
            end: end.format('YYYY-MM-DDTHH:mm'),
            resourceId: event.ResourceId.toString(),
            title: event.Title,
            description: event.Description,
            activityId: Number.parseFloat(event.ActivityId),
            messageType: event.MessageType,
            message: event.Message,
            showPopover: !isNull(event.MessageType),
            activityType: event.ActivityType,
            Capabilities: event.Capabilities,
            serviceJobId: event.ServiceJobId,
            customerId: event.CustomerId,
            customerName: event.CustomerIdLabel
        };
    });

    // Clear anything for the current resource/date
    let events = existingEvents.filter(
        (event) =>
            event.resourceId !== ResourceId ||
            !moment(event.start).startOf('date').isSame(workDate));

    // Add everything in the response.
    events = events.concat(updatedEvents);

    return events;
}

function getEstimate(event: IEventItem): string {
    const duration = moment.duration(moment(event.end).diff(moment(event.start)));
    const minutesLength = 2;

    return `${duration.hours()}:${duration.minutes().toString().padStart(minutesLength, '0')}`;
}

function makeDataGridData(serviceJob: IObjectified<IServiceJobSummaryFacade>): IDataGridData[] {
    const inlineActivities = (serviceJob && serviceJob.inlineObject && serviceJob.inlineObject.Activities) || [];
    const uniqueServiceItems = lodash.uniq(inlineActivities.map((act) => act.ServiceItem));

    return uniqueServiceItems.map((serviceItem, idx) => {
        const itemActivities = inlineActivities.filter((activity) => activity.ServiceItem === serviceItem);
        const firstActivity = lodash.first(itemActivities) as any;

        const labourCostTotal = lodash.sumBy(itemActivities, (a) => a.LabourCost);
        const materialCostTotal = lodash.sumBy(itemActivities, (a) => a.MaterialCost);
        const totalCost = lodash.sumBy(itemActivities, (a) => a.TotalCost);

        const serviceItemText = !isNull(firstActivity.ServiceItem) ? `${firstActivity.ServiceItemDisplay} - ${firstActivity.ServiceItemLabel}` : 'No Service Item';
        const fixedDecimals = 2;

        return {
            Key: idx,
            ServiceItem: serviceItem,
            ServiceItemText: serviceItemText,
            ItemType: 'None',
            LabourCostTotal: `$${labourCostTotal.toFixed(fixedDecimals)}`,
            MaterialCostTotal: `$${materialCostTotal.toFixed(fixedDecimals)}`,
            TotalCostTotal: `$${totalCost.toFixed(fixedDecimals)}`,
            Activities: itemActivities
        };
    });

}
const { types, actions, reducer } = initializeReducer({
    namespace: 'serviceActivity',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (activityId: number) => (activityId),
            callback: (data: IServiceActivityData, activityId: number) => {
                return {
                    ...data,
                    selectedId: activityId,
                    selectedActivityScheduleId: NOT_SELECTED
                };
            }
        },
        setSelectedActivitySchedule: {
            action: (selectedActivityScheduleId: number) => (selectedActivityScheduleId),
            callback: (data: IServiceActivityData, selectedActivityScheduleId: number) => {
                return {
                    ...data,
                    selectedActivityScheduleId: selectedActivityScheduleId
                };
            }
        },
        setHoverSelected: {
            action: (activityId: number) => (activityId),
            callback: (data: IServiceActivityData, _activityId: number) => {
                return {
                    ...data,
                    hoverSelectedActivityScheduleId: NOT_SELECTED
                };
            }
        },
        setHoverSelectedActivitySchedule: {
            action: (selectedActivityScheduleId: number) => (selectedActivityScheduleId),
            callback: (data: IServiceActivityData, hoverSelectedActivityScheduleId: number) => {
                return {
                    ...data,
                    hoverSelectedActivityScheduleId: hoverSelectedActivityScheduleId
                };
            }
        },
        setHoverSelectedOptions: {
            action: (activityId: number) => (activityId),
            callback: (data: IServiceActivityData, activityId: number) => {
                return {
                    ...data,
                    hoverSelectedActivityOptionsId: activityId,
                    hoverSelectedActivityScheduleOptionsId: NOT_SELECTED
                };
            }
        },
        setHoverSelectedActivityScheduleOptions: {
            action: (selectedActivityScheduleId: number) => (selectedActivityScheduleId),
            callback: (data: IServiceActivityData, hoverSelectedActivityScheduleId: number) => {

                return {
                    ...data,
                    hoverSelectedActivityScheduleOptionsId: hoverSelectedActivityScheduleId !== data.hoverSelectedActivityScheduleOptionsId ? hoverSelectedActivityScheduleId : NOT_SELECTED
                };
            }
        },
        setSchedulerData: {
            action: (schedulerData: SchedulerData) => (schedulerData),
            callback: (data: IServiceActivityData, schedulerData: SchedulerData) => ({ ...data, schedulerData: schedulerData })
        },
        scheduleActivityModalToggle: {
            action: (toggle: boolean) => (toggle),
            callback: (data: IServiceActivityData, toggle: boolean) => ({ ...data, openScheduleActivities: toggle })
        },
        hoverToggle: {
            action: (toggle: boolean) => (toggle),
            callback: (data: IServiceActivityData, toggle: boolean) => ({ ...data, isHover: toggle })
        },
        scheduleActivityOptionsModalToggle: {
            action: (selectedActivityScheduleId: number) => (selectedActivityScheduleId),
            callback: (data: IServiceActivityData, selectedActivityScheduleId: number) => ({ ...data, openScheduleActivityOptions: selectedActivityScheduleId !== data.openScheduleActivityOptions ? selectedActivityScheduleId : NOT_SELECTED })
        },
        newSchedulerEvent: {
            action: (schedulerData: SchedulerData, slotId: any, slotName: any, start: any, end: any, type: any, item: IEventItem) => ({ schedulerData, slotId, slotName, start, end, type, item }),
            callback: (data: IServiceActivityData, actionData: { schedulerData: SchedulerData; slotId: any; slotName: any; start: any; end: any; type: any; item: IEventItem }) => {
                const { schedulerData, slotId, slotName, item, start } = actionData;
                const openScheduleActivities = item ? true : false;

                return { ...data, openScheduleActivities, newEventData: { schedulerData, slotId, slotName, date: moment(start), start: '', end: '', item } };
            }
        },
        moveActivityModalToggle: {
            action: (toggle: boolean) => (toggle),
            callback: (data: IServiceActivityData, toggle: boolean) => ({ ...data, openMoveActivities: toggle })
        },
        adjustActivitySchedule: {
            action: (event: IEventItem) => (event),
            callback: (data: IServiceActivityData, event: IEventItem) => {
                const { schedulerData, resourcesList } = data;
                const matchedResource = resourcesList.find((resource) => resource.id === event.resourceId);
                const item = { ...event, estimate: getEstimate(event) };

                const slotId = matchedResource.id;
                const slotName = matchedResource.name;
                const date = moment(event.start);
                const start = event.start;
                const end = event.end;

                return { ...data, openScheduleActivityOptions: NOT_SELECTED, openMoveActivities: true, moveEventData: { schedulerData, slotId, slotName, date, start, end, item } };
            }
        },
        moveSchedulerEvent: {
            action: (schedulerData: SchedulerData, event: IEventItem, slotId: any, slotName: any, start: string, end: string) => ({ schedulerData, event, slotId, slotName, start, end, }),
            callback: (data: IServiceActivityData, actionData: { schedulerData: SchedulerData; event: IEventItem; slotId: any; slotName: any; start: any; end: any }) => {
                const { schedulerData, event, slotId, slotName, start } = actionData;
                const item = { ...event, estimate: getEstimate(event) };
                const date = moment(start);

                if (event.resourceId === slotId && moment(event.start).isSame(moment(start), 'day')) {
                    return { ...data };
                }

                return { ...data, openMoveActivities: true, moveEventData: { schedulerData, slotId, slotName, date, start: '', end: '', item } };
            }
        },
        setDefaultSalesEntity: {
            action: (salesEntity: string) => salesEntity,
            callback: (data: IServiceActivityData, salesEntity: string) => ({ ...data, selectedFilters: { ...data.selectedFilters, SalesEntity: salesEntity ? salesEntity.toUpperCase() : '' } })
        },
        triggerSchedulerDataChange: {
            action: () => undefined,
            callback: (data: IServiceActivityData) => {
                return {
                    ...data,
                    schedulerDataTriggerString: Date.now().toString()
                };
            }
        },
        updateAdjustmentProps: {
            action: (query: IAdjustmentProps): IAdjustmentProps => ({ AdjustmentAmount: query.AdjustmentAmount, AdjustmentDescription: query.AdjustmentDescription, AdjustmentProductId: query.AdjustmentProductId, ServiceJobId: query.ServiceJobId }),
            callback: (data: IServiceActivityData, query: IAdjustmentProps) => {

                return {
                    ...data,
                    adjustmentProps: { ...query },
                };
            },
        },
        clearCustomerDetails: {
            action: (data: any) => (data),
            callback: (data: IServiceActivityData) => {
                return {
                    ...data,
                    selectedCustomerDetails: {}
                };
            }
        },
        reset: {
          action: () => null,
          callback: (data: IServiceActivityData): IServiceActivityData => {

            const resetSchedulerData = new SchedulerData(moment().format('YYYY-MM-DD'), ViewTypes.Week, false, false, initalSchedulerData);

            resetSchedulerData.setResources([]);
            resetSchedulerData.setEvents([]);

            return ({ ...data, ...initialData, schedulerData: resetSchedulerData, jobDetails: {}, activityDetails: {}});
        }
        }
    },
    asyncActions: {
        search: {
            action: (query: ISearchQuery): { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number } => ({ SalesEntity: query.filters.SalesEntity, JobStatusFilter: query.filters.JobStatusFilter, BatchPage: 1 }),
            callback: (data: IServiceActivityData, query: { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number }) => {

                return {
                    ...data,
                    selectedId: NOT_SELECTED,
                    selectedFilters: { SalesEntity: query.SalesEntity, JobStatusFilter: query.JobStatusFilter }
                };
            },
            successCallback: (data: IServiceActivityData, payload: ISchedulerJobSearchResponse & { SalesEntity: string }) => {
                const { ServiceJobs, SalesEntity } = payload;

                return {
                    ...data,
                    salesEntity: SalesEntity,
                    list: ServiceJobs,
                    nextPage: !payload.BatchInformation.EndOfData ? payload.BatchInformation.BatchPage + 1 : null,
                    prevPage: payload.BatchInformation.BatchPage > 1 ? payload.BatchInformation.BatchPage - 1 : null,
                    currPage: payload.BatchInformation.BatchPage
                };
            }
        },
        fetchJobsToReview: {
            action: (query: ISearchQuery): { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number } => ({ SalesEntity: query.filters.SalesEntity, JobStatusFilter: query.filters.JobStatusFilter, BatchPage: 1 }),
            successCallback: (data: IServiceActivityData, payload: any & { SalesEntity: string }) => {
                const { ServiceJobs } = payload;

                return {
                    ...data,
                    reviewJobsData: ServiceJobs.map((job) => job.inlineObject),
                };
            }
        },
        fetchReviewJobSummary: {
            action: (query: { ServiceJobId?: string }): { ServiceJobId: string } => ({ ServiceJobId: query.ServiceJobId }),
            callback: (data: IServiceActivityData, query: { ServiceJobId: string }) => {

                return {
                    ...data,
                    ServiceJobId: query.ServiceJobId,
                };
            },
            successCallback: (data: IServiceActivityData, payload: IServiceJobSummaryResponse) => {
                const { ServiceJob } = payload;

                return {
                    ...data,
                    reviewJobSummary: ServiceJob,
                };
            }
        },
        fetchActivityImages: {
            action: (query: { ServiceJob?: IServiceJobSummaryFacade }): { ServiceJob: IServiceJobSummaryFacade } => ({ ServiceJob: query.ServiceJob }),
            successCallback: (data: IServiceActivityData, payload: IServiceJobSummaryResponse) => {
                const { ServiceJob } = payload;

                return {
                    ...data,
                    reviewJobSummary: ServiceJob,
                    dataGridData: makeDataGridData(ServiceJob)
                };
            }
        },
        applyReviewJobAdjustments: {
            action: (query: IAdjustmentProps): IAdjustmentProps => ({ AdjustmentAmount: query.AdjustmentAmount, AdjustmentDescription: query.AdjustmentDescription, AdjustmentProductId: query.AdjustmentProductId, ServiceJobId: query.ServiceJobId }),
            successCallback: (data: IServiceActivityData, payload: IServiceJobSummaryResponse) => {
                const { ServiceJob } = payload;

                return {
                    ...data,
                    reviewJobSummary: {
                        inlineObject: { ...data.reviewJobSummary && data.reviewJobSummary.inlineObject, ...ServiceJob ? ServiceJob.inlineObject : {} },
                        schema: { ...data.reviewJobSummary && data.reviewJobSummary.schema, ...ServiceJob ? ServiceJob.schema : {} }
                    }
                };
            },
        },
        fetchNextPage: {
            action: (query: { Sort?: ISortOrder; BatchPage?: number }): { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number } => ({ SalesEntity: '', BatchPage: query.BatchPage }),
            callback: (data: IServiceActivityData, query: { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number }) => {
                query.SalesEntity = data.selectedFilters.SalesEntity;
                query.JobStatusFilter = data.selectedFilters.JobStatusFilter;

                return { ...data };
            },
            successCallback: (data: IServiceActivityData, payload: ISchedulerJobSearchResponse) => {
                const { ServiceJobs } = payload;

                return {
                    ...data,
                    list: [...data.list, ...ServiceJobs],
                    nextPage: !payload.BatchInformation.EndOfData ? payload.BatchInformation.BatchPage + 1 : null,
                    prevPage: payload.BatchInformation.BatchPage > 1 ? payload.BatchInformation.BatchPage - 1 : null,
                    currPage: payload.BatchInformation.BatchPage
                };
            }
        },
        fetchPrevPage: {
            action: (query: { Sort?: ISortOrder; BatchPage?: number }): { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number } => ({ SalesEntity: '', BatchPage: query.BatchPage }),
            callback: (data: IServiceActivityData, query: { SalesEntity: string; JobStatusFilter?: string; BatchPage?: number }) => {
                query.SalesEntity = data.selectedFilters.SalesEntity;
                query.JobStatusFilter = data.selectedFilters.JobStatusFilter;

                return { ...data };
            },
            successCallback: (data: IServiceActivityData, payload: ISchedulerJobSearchResponse) => {
                const { ServiceJobs } = payload;

                return {
                    ...data,
                    list: [...data.list, ...ServiceJobs],
                    nextPage: !payload.BatchInformation.EndOfData ? payload.BatchInformation.BatchPage + 1 : null,
                    prevPage: payload.BatchInformation.BatchPage > 1 ? payload.BatchInformation.BatchPage - 1 : null,
                    currPage: payload.BatchInformation.BatchPage
                };
            }
        },
        searchById: {
            action: (query: { ActivityId: number; ServiceJobId: string }) => (query),
            callback: (data: IServiceActivityData, _: { ActivityId: number; ServiceJobId: string }) => {
                return {
                    ...data,
                    selectedId: NOT_SELECTED
                };
            },
            successCallback: (data: IServiceActivityData, payload: ISchedulerJobDetailsResponse) => {
                const { JobDetails } = payload;

                return {
                    ...data,
                    jobDetails: JobDetails,
                    activityDetails: JobDetails.ActivityDetail,
                    salesEntity: JobDetails.SalesEntity,
                    list: [{
                        Description: JobDetails.Description,
                        SalesEntity: JobDetails.SalesEntity,
                        ScheduleStatus: JobDetails.ScheduleStatus,
                        ServiceJobId: JobDetails.ServiceJobId,
                        Activities: [{
                            ActivityCode: JobDetails.ActivityDetail.ActivityCode,
                            ActivityId: JobDetails.ActivityDetail.ActivityId,
                            Description: JobDetails.ActivityDetail.Description,
                            ServiceJobId: JobDetails.ActivityDetail.ServiceJobId,
                            ScheduleStatus: JobDetails.ActivityDetail.ScheduleStatus
                        }]
                    }],
                    nextPage: null,
                    prevPage: null,
                    currPage: 1
                };
            }
        },
        getResources: {
            action: (SalesEntity: string, FromDate: string, ToDate: string): { SalesEntity: string; FromDate: string; ToDate: string } => ({ SalesEntity, FromDate, ToDate }),
            successCallback: (data: IServiceActivityData, payload: AsObjectifiedArray<IResourcesResponse, 'Resources'>) => {
                const { Resources } = payload;
                const resourceList = Resources.map((item) => {
                    const { inlineObject: resource } = item;

                    return { id: resource.ResourceId.toString(), name: resource.ResourceName, shifts: resource.Shifts, Capabilities: resource.Capabilities, ResourceType: resource.ResourceType };
                });
                const { schedulerData } = data;

                schedulerData.setResources(resourceList);

                return {
                    ...data,
                    resourcesList: resourceList,
                    schedulerData: schedulerData
                };
            }
        },
        getScheduledActivities: {
            action: (SalesEntity: string, FromDate: string, ToDate: string): { SalesEntity: string; FromDate: string; ToDate: string } => ({ SalesEntity, FromDate, ToDate }),
            successCallback: (data: IServiceActivityData, payload: AsObjectifiedArray<IActivityScheduleResponse, 'ActivitySchedules'>) => {
                const { ActivitySchedules } = payload;
                const { schedulerData } = data;

                const updatedEvents = getScheduleEvents(ActivitySchedules);
                schedulerData.setEvents(updatedEvents);

                return {
                    ...data,
                    resourcesList: [...data.resourcesList],
                    schedulerData: schedulerData
                };
            }
        },
        getSelectedJobDetails: {
            action: (query: { ServiceJobId?: string; ActivityId?: number }): { ServiceJobId?: string; ActivityId?: number } => (query),
            callback: (data: IServiceActivityData, query: { ServiceJobId?: string; ActivityId?: number }) => {

                if (isNull(query.ServiceJobId)) {

                    const selected = getSelectedActivity(data);
                    query.ActivityId = selected?.ActivityId;
                    query.ServiceJobId = selected?.ServiceJobId;
                }

                return { ...data };
            },
            successCallback: (data: IServiceActivityData, payload: AsObjectified<ISchedulerJobDetailsResponse, 'JobDetails'> & AsObjectified<ISchedulerJobDetailFacade, 'ActivityDetail'>) => {
                const { JobDetails, ActivityDetail } = payload;

                return {
                    ...data,
                    jobDetails: JobDetails.inlineObject,
                    activityDetails: ActivityDetail.inlineObject
                };
            }
        },
        updateRoster: {
            action: (query: { SalesEntity: string; StartDate: string; EndDate: string }) => (query),
            successCallback: (data: IServiceActivityData, payload: AsObjectified<IRostertemplateDetailsResponse, 'RosterTemplateDetails'>) => {
                return { ...data, payload };
            }
        },
        completeJobReview: {
            action: () => undefined,
        },
        getCustomerDetails: {
            action: (CustomerId: number) => (CustomerId),
            successCallback: (data: IServiceActivityData, payload: IObjectified<IServiceJobCustomerDetailsFacade>) => {
                return {
                    ...data,
                    selectedCustomerDetails: payload ? payload.inlineObject : {}
                };
            }
        },
        getDeliveryAddress: {
            action: (CustomerId: number, SiteCode: string) => ({ CustomerId, SiteCode }),
        },
        getJobActivities: {
            action: (TemplateCode: string) => (TemplateCode),
        },
        createNewJob: {
            action: (data: any) => (data)
        },
        newSchedulerEventConfirm: {
            action: (startTime: string, endTime: string): { Schedule: ISchedule } => ({
                Schedule: {
                    StartTime: startTime,
                    EndTime: endTime
                }
            }),
            callback: (data: IServiceActivityData, query: { SalesEntity: string; Schedule: ISchedule }) => {
                const { item, slotId, date } = data.newEventData;

                const startTime = query.Schedule.StartTime ? moment(query.Schedule.StartTime, 'hh:mm a') : null;
                const endTime = query.Schedule.EndTime ? moment(query.Schedule.EndTime, 'hh:mm a') : null;

                query.Schedule = {
                    ResourceType: 'Technician', // For now we only have employees, later we will grab this from the drop target.
                    ResourceId: slotId,
                    SalesEntity: data.salesEntity,
                    WorkDate: date.format('YYYY-MM-DD'),
                    StartTime: startTime ? startTime.format('hh:mm a') : null,
                    EndTime: endTime ? endTime.format('hh:mm a') : null,
                };

                if (item.isActivity) {
                    const activity = getActivity(data, item.id);

                    query.Schedule = {
                        ...query.Schedule,
                        ServiceActivityId: activity.ActivityId,
                        itemType: 'ServiceActivity'
                    };
                } else {
                    query.Schedule = {
                        ...query.Schedule,
                        ServiceJobId: item.serviceJobId,
                        itemType: 'ServiceJob'
                    };
                }

                return data;
            },
            successCallback: (data: IServiceActivityData, payload: ObjectifiedDailyResourceActivityScheduleResponse) => {
                if (payload.Forms) {
                    return data;
                }

                const { ResourceActivitySchedules } = payload;
                const { schedulerData } = data;

                const updatedEvents = updateResourceScheduleEvents(schedulerData.events, ResourceActivitySchedules);
                schedulerData.setEvents(updatedEvents);
                schedulerData.setEvents(updatedEvents);
                const updatedEvent = updatedEvents.length && updatedEvents[updatedEvents.length - 1];

                // Any events that are now scheduled should be marked as such in the activity list
                // Any jobs that are now partially/fully scheduled should be marked as such in the activity list.
                const list = [...data.list];
                let NotScheduledJobs;

                if (updatedEvent && updatedEvent.activityType === 'ServiceJob') {

                    NotScheduledJobs = list.filter((job) => job.ServiceJobId !== updatedEvent.title);
                } else {

                    list.forEach((serviceJob) => {
                        serviceJob.Activities.forEach((activity) => {
                            const matchedEvent = updatedEvents.find((event) => event.activityId === activity.ActivityId);
                            if (!matchedEvent) {
                                return;
                            }
                            activity.ScheduleStatus = 'Scheduled';
                        });
                        const unscheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Unscheduled');
                        const scheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Scheduled');
                        serviceJob.ScheduleStatus = scheduled && !unscheduled ? 'Scheduled' : scheduled && unscheduled ? 'Partial' : 'Unscheduled';
                    });

                    NotScheduledJobs = list.filter((job) => job.ScheduleStatus !== 'Scheduled');
                }

                return {
                    ...data,
                    list: NotScheduledJobs,
                    resourcesList: [...data.resourcesList],
                    SchedulerData: schedulerData
                };
            }
        },
        scheduleGeneralActivity: {
            action: (Schedule: INewGeneralActivityScheduleFacade): { Schedule: INewGeneralActivityScheduleFacade } => ({
                Schedule
            }),
            successCallback: (data: IServiceActivityData, payload: ObjectifiedDailyResourceActivityScheduleResponse) => {
                if (payload.Forms) {
                    return data;
                }

                const { ResourceActivitySchedules } = payload;
                const { schedulerData } = data;

                const updatedEvents = updateResourceScheduleEvents(schedulerData.events, ResourceActivitySchedules);
                schedulerData.setEvents(updatedEvents);

                // Any events that are now scheduled should be marked as such in the activity list
                // Any jobs that are now partially/fully scheduled should be marked as such in the activity list.
                const list = [...data.list];
                list.forEach((serviceJob) => {
                    serviceJob.Activities.forEach((activity) => {
                        const matchedEvent = updatedEvents.find((event) => event.activityId === activity.ActivityId);
                        if (!matchedEvent) {
                            return;
                        }
                        activity.ScheduleStatus = 'Scheduled';
                    });
                    const unscheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Unscheduled');
                    const scheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Scheduled');
                    serviceJob.ScheduleStatus = scheduled && !unscheduled ? 'Scheduled' : scheduled && unscheduled ? 'Partial' : 'Unscheduled';
                });

                return {
                    ...data,
                    list: list,
                    resourcesList: [...data.resourcesList],
                    SchedulerData: schedulerData
                };
            }
        },
        moveSchedulerEventConfirm: {
            action: (startTime: string, endTime: string): { Schedule: ISchedule } => ({
                Schedule: {
                    StartTime: startTime,
                    EndTime: endTime
                }
            }),
            callback: (data: IServiceActivityData, query: { SalesEntity: string; ActivityScheduleId: any; Schedule: ISchedule | IGeneralActivityScheduleFacade }) => {
                const { item, slotId, date } = data.moveEventData;

                const startTime = query.Schedule.StartTime ? moment(query.Schedule.StartTime, 'hh:mm a') : null;
                const endTime = query.Schedule.EndTime ? moment(query.Schedule.EndTime, 'hh:mm a') : null;

                query.ActivityScheduleId = item.id;
                query.Schedule = {
                    ResourceType: 'Technician', // For now we only have employees, later we will grab this from the drop target.
                    ResourceId: slotId,
                    SalesEntity: data.salesEntity,
                    WorkDate: date.format('YYYY-MM-DD'),
                    StartTime: startTime ? startTime.format('hh:mm a') : null,
                    EndTime: endTime ? endTime.format('hh:mm a') : null,
                    itemType: item.activityType
                };
                switch (item.activityType) {
                    case 'ServiceJob':
                        query.Schedule = {
                            ...query.Schedule,
                            ServiceJobId: item.serviceJobId,
                        };
                        break;
                    case 'ServiceActivity':
                        query.Schedule = {
                            ...query.Schedule,
                            ServiceActivityId: item.activityId,
                        };
                        break;
                    case 'General':
                        query.Schedule = {
                            ...query.Schedule,
                            Title: item.title,
                            Description: item.description,
                        };
                        break;
                    default:

                }

                return data;
            },
            successCallback: (data: IServiceActivityData, payload: ObjectifiedDailyResourceActivityScheduleResponse) => {
                if (payload.Forms) {
                    return data;
                }

                const { ResourceActivitySchedules } = payload;
                const { schedulerData } = data;

                const updatedEvents = updateResourceScheduleEvents(schedulerData.events, ResourceActivitySchedules);
                schedulerData.setEvents(updatedEvents);

                // Any events that are now scheduled should be marked as such in the activity list
                // Any jobs that are now partially/fully scheduled should be marked as such in the activity list.
                const list = [...data.list];
                list.forEach((serviceJob) => {
                    serviceJob.Activities.forEach((activity) => {
                        const matchedEvent = updatedEvents.find((event) => event.activityId === activity.ActivityId);
                        if (!matchedEvent) {
                            return;
                        }
                        activity.ScheduleStatus = 'Scheduled';
                    });
                    const unscheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Unscheduled');
                    const scheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Scheduled');
                    serviceJob.ScheduleStatus = scheduled && !unscheduled ? 'Scheduled' : scheduled && unscheduled ? 'Partial' : 'Unscheduled';
                });

                return {
                    ...data,
                    list: list,
                    resourcesList: [...data.resourcesList],
                    schedulerData: schedulerData
                };
            }
        },
        deleteActivitySchedule: {
            action: (event: IEventItem) => ({ ActivityScheduleId: event.id, ActivityType: event.activityType, ServiceJobId: event.serviceJobId }),
            callback: (data: IServiceActivityData, _query: { ActivityScheduleId: any }) => {
                return { ...data, openScheduleActivityOptions: NOT_SELECTED };
            },
            successCallback: (data: IServiceActivityData, payload: ObjectifiedDailyResourceActivityScheduleResponse) => {
                if (payload.Forms) {
                    return data;
                }

                const { ResourceActivitySchedules } = payload;
                const { schedulerData } = data;

                const updatedEvents = updateResourceScheduleEvents(schedulerData.events, ResourceActivitySchedules);
                const deletedEvent = schedulerData.events && schedulerData.events.find((event) => updatedEvents.indexOf(event) < 0); //Event that is missing from updatedEvents list is deletedEvent
                schedulerData.setEvents(updatedEvents);

                const list = [...data.list];

                if (deletedEvent && deletedEvent.activityType === 'ServiceActivity') {
                    list.forEach((serviceJob) => {
                        serviceJob.Activities.forEach((activity) => {
                            if (deletedEvent.activityId === activity.ActivityId) {
                                activity.ScheduleStatus = 'Unscheduled';
                            }
                        });

                        const unscheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Unscheduled');
                        const scheduled = serviceJob.Activities.find((activity) => activity.ScheduleStatus === 'Scheduled');
                        serviceJob.ScheduleStatus = scheduled && !unscheduled ? 'Scheduled' : scheduled && unscheduled ? 'Partial' : 'Unscheduled';
                    });

                }

                return {
                    ...data,
                    list: list,
                    resourcesList: [...data.resourcesList],
                    schedulerData: schedulerData
                };
            }
        },
        getServiceAgreementJobs: {
            action: (query: ISearchServiceJobFacade) => (query),
            callback: (data: IServiceActivityData, query: ISearchServiceJobFacade) => {
                return { ...data, upcomingServiceDate: query.EndDate };
            },
            successCallback: (data: IServiceActivityData, payload: IObjectifiedIUpcomingServiceJobs) => {

                return {
                    ...data,
                    upcomingServiceJobs: payload,
                };
            }
        },
        reAddDeletedJobToList: {
            action: (DeletedServiceJobId: string) => ({ DeletedServiceJobId }),
            successCallback: (data: IServiceActivityData, jobs: ISchedulerJobSearchFacade[]) => {

                return {
                    ...data,
                    list: jobs
                };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { serviceActivityScheduling: IServiceActivitySchedulingState }) => state.serviceActivityScheduling.serviceActivity,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        selected: getSelectedActivity,
        selectedId: (data) => data.selectedId,
        selectedActivityScheduleId: (data) => data.selectedActivityScheduleId,
        hoverSelectedActivityScheduleId: (data) => data.hoverSelectedActivityScheduleId,
        hoverSelectedActivityOptionsId: (data) => data.hoverSelectedActivityOptionsId,
        hoverSelectedActivityScheduleOptionsId: (data) => data.hoverSelectedActivityScheduleOptionsId,
        jobDetails: (data) => data.jobDetails,
        activityDetails: (data) => data.activityDetails,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
        resourcesList: (data) => data.resourcesList,
        salesEntity: (data) => data.salesEntity,
        reviewJobsData: (data) => data.reviewJobsData,
        reviewJobSummary: (data) => data.reviewJobSummary,
        dataGridData: (data) => data.dataGridData,
        newEventData: (data) => data.newEventData,
        moveEventData: (data) => data.moveEventData,
        serviceJobId: (data) => data.ServiceJobId,
        selectedCustomerDetails: (data) => data.selectedCustomerDetails,
        upcomingServiceJobs: (data) => data.upcomingServiceJobs,
        upcomingServiceDate: (data) => data.upcomingServiceDate,
    }
);

const syncSelector = syncSelectors(
    (state: { serviceActivityScheduling: IServiceActivitySchedulingState }) => state.serviceActivityScheduling.serviceActivity,
    {
        schedulerData: (serviceActivity) => serviceActivity.data.schedulerData,
        isLoading: (serviceActivity) => serviceActivity.search_loading || serviceActivity.searchById_loading,
        loadingNextPage: (serviceActivity) => serviceActivity.fetchNextPage_loading,
        loadingPrevPage: (serviceActivity) => serviceActivity.fetchPrevPage_loading,
        jobSummaryLoading: (serviceActivity) => serviceActivity.fetchReviewJobSummary_loading || serviceActivity.fetchActivityImages_loading,
        fetchJobsToReviewLoading: (serviceActivity) => serviceActivity.fetchJobsToReview_loading,
        upcomingServAgreementsLoading: (serviceActivity) => serviceActivity.getServiceAgreementJobs_loading,
        fetchSchedulerActivitiesLoading: (serviceActivity) => serviceActivity.getScheduledActivities_loading,
        fetchResourcesLoading: (serviceActivity) => serviceActivity.getResources_loading,
        adjustmentPanelLoading: (serviceActivity) => serviceActivity.applyReviewJobAdjustments_loading,
        deleteActivityScheduleLoading: (serviceActivity) => serviceActivity.deleteActivitySchedule_loading,
        reAddDeletedJobToListLoading: (serviceActivity) => serviceActivity.reAddDeletedJobToList_loading,
        pageSize: (serviceActivity) => serviceActivity.data.pageSize,
        totalPages: (serviceActivity) => serviceActivity.data.totalPages,
        notifications: (serviceActivity) => serviceActivity.notifications,
        error: (serviceActivity) => serviceActivity.error,
        filterHeader: (serviceActivity) => serviceActivity.data.filterHeader,
        selectedFilters: (serviceActivity) => serviceActivity.data.selectedFilters,
        isHover: (serviceActivity) => serviceActivity.data.isHover,
        hoverToggle: (serviceActivity) => serviceActivity.data.isHover,
        openScheduleActivities: (serviceActivity) => serviceActivity.data.openScheduleActivities,
        scheduleActivityModalToggle: (serviceActivity) => serviceActivity.data.openScheduleActivities,
        openScheduleActivityOptions: (serviceActivity) => serviceActivity.data.openScheduleActivityOptions,
        scheduleActivityOptionsModalToggle: (serviceActivity) => serviceActivity.data.openScheduleActivityOptions,
        schedulerDataTriggerString: (serviceActivity) => serviceActivity.data.schedulerDataTriggerString,
        adjustmentProps: (serviceActivity) => serviceActivity.data.adjustmentProps,
        openMoveActivities: (serviceActivity) => serviceActivity.data.openMoveActivities,
    }
);

export const selectors = { ...asyncSelector, ...syncSelector };
