import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    paperContainer: {
        width: '100%',
        maxWidth: 'calc(100% - 10px)',
        height: '100%',
        flexGrow: 1,
    },
    commitmentImageDiv: {
        'padding': '10px',
        '& div div div': {
            '@media screen and (max-width: 1100px)': {
                width: '250px',
            }
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none'
        }
    },
    commitmentDataGrid: {
        'height': 'calc(100% - 60px)',
        '& .dx-datagrid-headers .dx-row > td ': {
            outline: 'unset !important'
        },
        '& .dx-checkbox-container .dx-checkbox-icon ': {
            width: '20px',
            height: '18px',
            border: '2px solid #333333',
            marginBottom: '3px',
        },
        '& .dx-checkbox-checked .dx-checkbox-icon': {
            color: '#333333',
        },
        '& .dx-checkbox-indeterminate .dx-checkbox-icon::before': {
            backgroundColor: '#333333',
        },

    },
    commitmentHeaderFilter: {
        '& span': {
            color: '#959595 !important',
            cursor: 'pointer',
        }
    },
    grid: {
        marginTop: '10px'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledLineDetailIcon: {
        cursor: 'default',
        fill: 'grey'
    },
});
