import { createStyles } from '@material-ui/core/styles';

export const inlineStyles = createStyles({
    dialog: {
        width: 800,
        height: 550,
        maxWidth: 1500,
        overflow: 'hidden'
    },
    dialogActionBar: {
        backgroundColor: '#dddddd',
        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
        borderRadius: 0.7,
        height: '70px',
        margin: '4px 0 0 0'
    },
    dialogContent: {
        'height': '95%',
        'padding': '10px',
        'position': 'relative',
        '&:first-child': {
            paddingTop: 0
        }
    },
    dialogTitleHeading: {
        fontSize: 22,
        paddingBottom: 8
    },
    dialogTitle: {
        padding: '10px 0px 12px 22px',
        backgroundColor: '#dddddd',
        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
        height: 30,
        zIndex: 1,
        marginBottom: 5
    },
    buttonTitle: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center'
    },
    cancelBtn: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    dialogBtnIcon: {
        width: '35px',
        height: '35px'
    },
    imageCounterText: {
        padding: '10px'
    },
    mainDiv: {
        width: '100%',
        height: '100%',
    },
    galleryDiv: {
        display: 'flex',
        paddingTop: '20px',
        alignItems: 'center',
        justifyContent: 'Center'
    },
    galleryPaper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    galleryImage: {
        width: '350px',
        display: 'flex',
        justifyContent: 'center',
        objectFit: 'cover',
        borderRadius: '5px',
        border: '2px solid #eeeeee',
        margin: '0px 15px 0px 15px'
    },
    galleryIcon: {
        border: '2px solid #eeeeee',
        borderRadius: '5px'
    },
    linearProgress: {
        bottom: '-4px',
        width: '100%'
    },
    media: {
        height: '100%',
        width: '100%',
        minWidth: '210px',
        backgroundSize: 'contain',
        cursor: 'pointer',
        borderRadius: '2px',
        border: '2px solid #eeeeee'
    },
    noImageDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'default'
    }
});
