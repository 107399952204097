import * as React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, StyledComponentProps, createStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';

export interface IEFTPOSModalData {
    description: string;
    status: string;
}
export interface IEFTPOSModalProps extends StyledComponentProps {
    open: boolean;
    loading: boolean;
    data: IEFTPOSModalData;
    disableOk?: boolean;
    disableClose?: boolean;
    onOk(): void;
    onClose(): void;
}
interface IEFTPOSModalState {
    showMore: boolean;
}

const styles = createStyles({
    subTitle: {
        color: 'grey',
        fontSize: '1rem',
        margin: '5px 0px'
    },
    dialogContent: {
        padding: '0 24px'
    },
    labelValueContainer: {
        'display': 'grid',
        'grid-template-columns': '100px 400px',
        'padding': '7px 15px',
        'fontSize': '0.9rem'
    },
    label: {
        textAlign: 'start',
        fontWeight: 'bold'
    },
    value: {
        wordBreak: 'break-all',
        backgroundColor: '#eeee',
        padding: '2px 10px'
    },
    description: {
        wordBreak: 'break-all',
        backgroundColor: '#eeee',
    },
    descriptionInner: {
        padding: '2px 10px'
    },
    button: {
        color: 'yellow'
    },
    progressBar: {
        marginTop: 20
    }
});

class EFTPOSModal extends React.Component<IEFTPOSModalProps, IEFTPOSModalState> {

    render(): React.ReactNode {

        const { classes, open, loading, disableOk = false, disableClose = false, onOk, onClose, data } = this.props;

        return (
            <div >
                <Dialog open={open} aria-labelledby='form-dialog-title' className='error-detail-modal'>
                    <DialogTitle id='form-dialog-title'>
                        Process EFTPOS Transaction
                        <div className={classes.subTitle}>Processing an integrated EFTPOS Transaction using PC-EFTPOS.</div>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>

                        <div className={classes.labelValueContainer}>
                            <div className={classes.label}>Status</div>
                            <div className={classes.value} title={'Processing'}>
                                {data.status}
                            </div>
                        </div>
                        <div className={classes.labelValueContainer}>
                            <div className={classes.label}>Description</div>
                            <div className={classes.description} >
                                <Scrollbars autoHeight
                                    autoHeightMin={'100px'}
                                    autoHeightMax={'250px'}>
                                    <div className={classes.descriptionInner}>{data.description}</div>
                                </Scrollbars>
                            </div>
                        </div>

                    </DialogContent>
                    {loading && <LinearProgress color='secondary' className={classes.progressBar} />}
                    <DialogActions>
                        <Button disabled={disableOk} onClick={onOk} color='primary'>
                            Ok
                     </Button>
                        <Button disabled={disableClose} onClick={onClose} color='primary'>
                            Close
                     </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, {index: 1})(React.memo(EFTPOSModal));
