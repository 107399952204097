import {
  asyncSelectors,
  IExtendedState,
  initializeReducer
} from '../utils';
import {
  ITechnicianSearchFacade,
  ISortOrder,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';

export interface ITechnicianData {
  technicianData: { inlineObject: ITechnicianSearchFacade; schema: Inline<ITechnicianSearchFacade> };
  selected: number;
  list: ITechnicianSearchFacade[];
  schemas: Inline<ITechnicianSearchFacade>[];
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  currSearchText: string;
  removeWhenPrev: number;
}

export interface ITechnicianState extends IExtendedState<ITechnicianData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = -1;

const initialData: ITechnicianData = {
  selected: NOT_SELECTED,
  list: [],
  schemas: [],
  technicianData: { inlineObject: {}, schema: {} },
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  currSearchText: '',
  removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'technician',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (UserId: number) => UserId,
      callback: (data: ITechnicianData, UserId: number): ITechnicianData => ({ ...data, selected: UserId })
    },
    reset: {
      action: () => null,
      callback: (data: ITechnicianData): ITechnicianData => ({ ...data, ...initialData })
    }
  },
  asyncActions: {
    search: {
      action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
      callback: (data: ITechnicianData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
      successCallback: (data: ITechnicianData, payload: { records: IObjectified<ITechnicianSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): ITechnicianData => {
        const technicians = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          list: technicians,
          schemas: schemas,
          nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
          prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
          currPage: payload.currPage
        };
      }
    },
    searchById: {
      action: (userId: string) => ({ UserId: userId }),
      successCallback: (data: ITechnicianData, payload: { records: IObjectified<ITechnicianSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): ITechnicianData => {
        const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          list: activities,
          schemas: schemas,
          nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
          prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
          currPage: payload.currPage
        };
      }
    },
    fetchRosterData: {
      action: (userId: string) => ({ UserId: userId }),
      successCallback: (data: ITechnicianData, payload: { records: IObjectified<ITechnicianSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): ITechnicianData => {
        const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          technicianData: { inlineObject: activities[0], schema: schemas[0] },
        };
      }
    },
    fetchNextPage: {
      action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
      callback: (data: ITechnicianData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
        search.SearchText = data.currSearchText;

        return data;
      },
      successCallback: (data: ITechnicianData, payload: { records: IObjectified<ITechnicianSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): ITechnicianData => {
        const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        const newRemoval = payload.records.length;
        const newList = data.list.concat(activities);
        const newSchemas = data.schemas.concat(schemas);

        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
          nextPage: payload.nextPage && payload.currPage + 1,
          prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
          currPage: payload.currPage,
          removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
        };
      }
    },
    fetchPrevPage: {
      action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
      callback: (data: ITechnicianData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
        search.SearchText = data.currSearchText;

        return data;
      },
      successCallback: (data: ITechnicianData, payload: { records: IObjectified<ITechnicianSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): ITechnicianData => {
        const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        const newList = activities.slice(0, data.removeWhenPrev).concat(data.list);
        const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
          prevPage: payload.prevPage && payload.currPage - 1,
          nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
          currPage: payload.currPage,
          removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
        };
      }
    },
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.UserId === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.UserId.Value === data.selected)) || null,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
    technicianData: (data) => data.technicianData,
  }
);

const syncSelector = {
  userId: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.data.selected,
  isLoading: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.search_loading || state.rosterMaintenance.technician.searchById_loading,
  loadingNextPage: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.fetchNextPage_loading,
  loadingPrevPage: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.fetchPrevPage_loading,
  pageSize: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.data.pageSize,
  totalPages: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.data.totalPages,
  notifications: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.notifications,
  error: (state: { rosterMaintenance: { technician: ITechnicianState } }) => state.rosterMaintenance.technician.error
};

export const selectors = { ...asyncSelector, ...syncSelector };
