import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  dateComparator
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null,
  asyncs: {
  }
}, 'purchaseOrder');

//const actionListAPI = '/salesOrderEnquiry/lookup/action';

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Line', field: 'Line', type: 'numericColumn', minWidth: 150 },
    {
      headerName: 'Product',
      field: 'ProductDisplay',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Product Group', field: 'ProductGroup', suppressSorting: true, minWidth: 120 },
    { headerName: 'Product Description', field: 'ProductDescription', minWidth: 150, suppressSorting: true },
    { headerName: 'Priority', field: 'Priority', suppressSorting: true, type: 'numericColumn', minWidth: 100 },
    { headerName: 'Purchase order', field: 'PurchaseOrder', suppressSorting: true, minWidth: 150 },
    { headerName: 'Committed', field: 'CommittedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 100 },
    { headerName: 'User ID', field: 'UserId', type: 'numericColumn', suppressSorting: true, minWidth: 100 },
    { headerName: 'Due', field: 'DueDate', comparator: dateComparator, type: 'numericColumn', minWidth: 100 },
    { headerName: 'Order status', field: 'OrderStatus', suppressSorting: true, minWidth: 100 },
    { headerName: 'Supplier', field: 'Supplier', suppressSorting: true, minWidth: 100 },
    { headerName: 'Supplier name', field: 'SupplierName', suppressSorting: true, minWidth: 120 },
  ],
  Data: [
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.purchaseOrder,
  {
    Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.purchaseOrder.loading,
  gridOptions: state => state.salesOrderEnquiry.purchaseOrder.data.gridOptions,
  Columns: state => state.salesOrderEnquiry.purchaseOrder.data.Columns,
  Actions: state => state.salesOrderEnquiry.purchaseOrder.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)