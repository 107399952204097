import { fetchPost, objectify, fetchGet, fetchPut } from '../utils';

export const fetchAllocatedInvoices = (filters) => {

  return fetchPost(
    '/PointOfSaleCashReceipting/CashReceipt/SearchInvoiceAllocations',
    filters,
    null,
    'Inline'
  ).then(
    (result) => {
      if (result) {
        if (result.CashReceiptInvoiceAllocations) {
          return {
            records: result.CashReceiptInvoiceAllocations.map((item) => objectify(item, null, 'Inline')),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        }
      }

      return result;
    });
};

export const getBulkSelectionCriteria = () => {
  return fetchGet('/PointOfSaleCashReceipting/CashReceipt/BulkInvoiceSelectionCriteria', {}, 'Inline')
    .then(({ CashReceiptBulkInvoiceSelection = {} }) => ({ inlineObject: objectify(CashReceiptBulkInvoiceSelection), schema: CashReceiptBulkInvoiceSelection }));
};

export const applyBulkSelectionCriteria = (data) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/ApplyBulkInvoiceSelectionCriteria', data, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const getSpecificInvoiceAllocation = (query) => {
  const { SalesEntity, InvoiceNumber } = query;

  return fetchGet(`/PointOfSaleCashReceipting/CashReceipt/SalesEntity/${SalesEntity}/Invoice/${InvoiceNumber}/CurrentAllocation`, {}, 'Inline')
    .then(({ CashReceiptInvoiceAllocation = {} }) => ({ inlineObject: objectify(CashReceiptInvoiceAllocation), schema: CashReceiptInvoiceAllocation }));
};

export const applySpecificInvoiceAllocation = (query) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/ApplyInvoiceAllocation', query, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const deleteSpecificInvoiceAllocation = async (query) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/ApplyInvoiceAllocation', query, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const createInvoiceAllocation = async (): Promise<{ inlineObject: any; schema: any }> => {
  return fetchGet('/PointOfSaleCashReceipting/CashReceipt/CreateNewInvoiceAllocation').then((result: { CashReceiptInvoiceAllocation: any }) => {
    if (result && result.CashReceiptInvoiceAllocation) {
      return {
        inlineObject: objectify(result.CashReceiptInvoiceAllocation), schema: result.CashReceiptInvoiceAllocation
      };
    }

    return { inlineObject: {}, schema: {} };
  });
};

export const updateInvoiceAllocation = async (data: any): Promise<{ inlineObject: any; schema: any }> => {
  return fetchPut('/PointOfSaleCashReceipting/CashReceipt/CreateNewInvoiceAllocation', {...data}, 'Inline').then((result: { CashReceiptInvoiceAllocation: any }) => {
    if (result && result.CashReceiptInvoiceAllocation) {
      return {
        inlineObject: objectify(result.CashReceiptInvoiceAllocation), schema: result.CashReceiptInvoiceAllocation
      };
    }

    return { inlineObject: {}, schema: {} };
  });
};
