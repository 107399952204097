import * as React from 'react';
import ValidationDialogView from '../../ValidationDialog/MessageBoxValidationDialog';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';
import { withStyles } from '@material-ui/core';
import { IPopUpDialogProperties } from './PopUp.properties';
import { popUpDialogStyles } from './PopUp.styles';
import { children } from '../SummaryTable.properties';

const PopUp = ({ classes, ...restProps }: IPopUpDialogProperties) => {
    const [open, setOpen] = React.useState(false);
    const actions = React.useMemo(
        () => (
            [
                {
                    name: 'ok',
                    isDefault: true,
                    label: 'ok',
                    callback: () => { setOpen(false); }
                }
            ]
        ),
        []
    );

    return (
        <React.Fragment>
            <ValidationDialogView open={open} actions={actions} {...restProps} />
            <button
                className={classes.iconWarning}
                onClick={() => { setOpen(true); }}>
                <WarningIcon />
            </button>
        </React.Fragment>
    );
};

const StyledPopUp = withStyles(popUpDialogStyles, { index: 1 })(PopUp);

export const renderPopup = (property: children, itemData: any, renderPosition: string) => {
    if (property.hasOwnProperty('dialogBox') && itemData[property.dialogBox.fieldName] && property.dialogBox.tableCell === renderPosition) {
        return (<StyledPopUp title={property.dialogBox.title} color={property.dialogBox.color} icon={property.dialogBox.icon} message={itemData[property.dialogBox.fieldName]} />);
    }

    return (<React.Fragment></React.Fragment>);
};
