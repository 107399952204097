import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as customerSelectors } from 'ducks/customer';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import RosterMaintenance from './RosterMaintenance';
import { IApplicationState } from 'ducks/reducers';
import { actions as technicianActions } from 'ducks/rosterMaintenance/technician';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedCustomer: customerSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  selectedForm: formSelectors.selected(state),
  customerLoading: customerSelectors.isFetched(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    closeMenu: uiActions.closeMainMenu,
    resetSearchLookupDrawer: technicianActions.reset,
    destoryForm: () => destroy('RosterLookup'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RosterMaintenance);
