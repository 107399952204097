import { takeLatest, call, Effect, put, select } from 'redux-saga/effects';

import { selectors as filterSelectors, actions as filterActions } from 'ducks/common/filters';
import { types as invoicesTypes, actions as invoicesActions } from 'ducks/cashReceipting/invoices';
import { actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { actions as allocateActions } from 'ducks/cashReceipting/allocate';
import { callApi, connectSagaToApi } from '../utils';
import * as api from 'api/cashReceipting/invoices';
import { IDataAction } from 'ducks/utils';

function* getBulkSelectionCriteria(): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.getBulkSelectionCriteria;

    yield callApi(
        call(api.getBulkSelectionCriteria),
        success,
        failure
    );
}

function* deleteSpecificInvoiceAllocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.deleteSpecificInvoiceAllocation;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        const { success: cashReceiptSuccess } = cashReceiptActions.saga.applySpecificInvoiceAllocation;
        const { success: allocateSuccess } = allocateActions.saga.applySpecificInvoiceAllocation;
        yield put(cashReceiptSuccess(response));
        yield put(allocateSuccess(response));

        const filters = yield select(filterSelectors.getFilters, 'CashReceiptingInvoiceFilters');
        yield call(fetchAllocatedInvoices, { data: { filters } });
    }

    yield callApi(
        call(api.deleteSpecificInvoiceAllocation, { ...action.data, AllocatedReceiptedAmount: 0 }),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

function* fetchAllocatedInvoices(action: any): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.fetchAllocatedInvoices;

    const filters = action.data.filters;
    yield put(filterActions.applyFilters({ filters, formName: 'CashReceiptingInvoiceFilters' }));

    yield callApi(
        call(api.fetchAllocatedInvoices, filters),
        success,
        failure
    );
}

function* getSpecificInvoiceAllocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.getSpecificInvoiceAllocation;

    yield put(invoicesActions.changeModalVisibility({ openInvoiceAllocationModal: true }));

    yield callApi(
        call(api.getSpecificInvoiceAllocation, action.data),
        success,
        failure,
    );
}

function* applySpecificInvoiceAllocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.applySpecificInvoiceAllocation;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        const { success: cashReceiptSuccess } = cashReceiptActions.saga.applySpecificInvoiceAllocation;
        const { success: allocateSuccess } = allocateActions.saga.applySpecificInvoiceAllocation;
        yield put(cashReceiptSuccess(response));
        yield put(allocateSuccess(response));

        const filters = yield select(filterSelectors.getFilters, 'CashReceiptingInvoiceFilters');
        yield call(fetchAllocatedInvoices, { data: { filters } });

        yield put(invoicesActions.changeModalVisibility({ openInvoiceAllocationModal: false }));
    }

    yield callApi(
        call(api.applySpecificInvoiceAllocation, action.data),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

function* applyBulkSelectionCriteria(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = invoicesActions.saga.applyBulkSelectionCriteria;
    const { success: cashReceiptSuccess } = cashReceiptActions.saga.applyBulkSelectionCriteria;
    const { success: allocateSuccess } = allocateActions.saga.applyBulkSelectionCriteria;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield put(invoicesActions.changeModalVisibility({ openBulkModal: false }));
        yield put(cashReceiptSuccess(response));
        yield put(allocateSuccess(response));
    }
    yield callApi(
        call(api.applyBulkSelectionCriteria, action.data),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

export default function* rootSupplierInvoiceSaga(): IterableIterator<Effect> {
    yield takeLatest(invoicesTypes.getBulkSelectionCriteria, getBulkSelectionCriteria);
    yield takeLatest(invoicesTypes.applyBulkSelectionCriteria, applyBulkSelectionCriteria);
    yield takeLatest(invoicesTypes.getSpecificInvoiceAllocation, getSpecificInvoiceAllocation);
    yield takeLatest(invoicesTypes.applySpecificInvoiceAllocation, applySpecificInvoiceAllocation);
    yield takeLatest(invoicesTypes.fetchAllocatedInvoices, fetchAllocatedInvoices);
    yield takeLatest(invoicesTypes.deleteSpecificInvoiceAllocation, deleteSpecificInvoiceAllocation);
    yield takeLatest(invoicesTypes.createInvoiceAllocation, connectSagaToApi(invoicesActions, 'createInvoiceAllocation', api.createInvoiceAllocation));
    yield takeLatest(invoicesTypes.updateInvoiceAllocation, connectSagaToApi(invoicesActions, 'updateInvoiceAllocation', api.updateInvoiceAllocation));
}
