import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from './../utils'

export const search = (query) => {
  const { PurchaseOrder, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query

  return fetchPost(
    `/PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines`,
    filters && { ProductId: filters.ProductId || 0, Finalised: filters.Finalised },
    { BatchPage: Page, Sort: (Sort && SortDirection && `${Sort}:${SortDirection}`) || '', BatchSize },
    'Inline'
  )
    .then((result) => {
      if (result) {
        if (result.PurchaseOrdersLines) {
          return {
            records: result.PurchaseOrdersLines.map((val) =>
              Object.assign({}, objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}