export default [
  {
    id: '1',
    label: 'G/L Account Enquiry in new tab',
    'action': (params) => { window.open('/account-enquiry' + ( params ? '/' + params : '' )) }
  },
  {
    id: '2',
    label: 'G/L Account Enquiry',
    'action': (params) => { window.location.pathname = '/account-enquiry' + ( params ? '/' + params : '' ) }
  }
];
