import { IWorksaleDeliveryDataFacade } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';
import { IRetrieveWorksaleDeliveriesPayload, IObjectifiedRetrieveWorksaleDeliveriesResponse } from 'api/Worksale/worksale';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';

/**
 * This creates a generic data source
 * Called by both Mock data Sources and functions that wrap this with calls to integrated APIs.
 * @param api api functions
 * @returns Data Source of Deliveries
 */
export function createWorksaleDeliveriesDataSource(
  api: {
    fetch(query: Omit<IRetrieveWorksaleDeliveriesPayload, 'WorkSaleId' | 'CustomerId'>): Promise<IObjectifiedRetrieveWorksaleDeliveriesResponse>;
  }): DataSource {
  return new DataSource({
    load: async (loadOptions: LoadOptions) => {

      const defaultSkip = 0;
      const defaultTake = 10;
      const { skip = defaultSkip, take = defaultTake } = loadOptions;
      const BatchPage = Math.floor(Number((skip) + defaultTake) / defaultTake);
      const BatchSize = defaultTake;

      let sortField = '';
      let direction = '';

      if (take > defaultTake) {
        return async function (): Promise<any> {
          const APICallsCount = Math.floor((loadOptions.take as number) / defaultTake);
          let allRecords: IObjectified<IWorksaleDeliveryDataFacade>[] = [];

          try {
            for (let i = 0; i < APICallsCount; i++) {
              if (loadOptions.sort) {
                sortField = loadOptions.sort[0].selector;
                direction = loadOptions.sort[0].desc ? 'desc' : 'asc';
              }

              const Data = await api.fetch({ BatchSize, BatchPage: BatchPage + i , Sort: sortField, SortDirection: direction });

              if (Data.Deliveries.length > 0) {
                allRecords = allRecords.concat(Data.Deliveries);
              }

              if (Data.Deliveries.length < defaultTake) {
                  break;
                }
              }

            } catch (err) {
            console.warn(err);
          }

          return allRecords;
        }();
      }
    },
  });
}
