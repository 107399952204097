import * as React from 'react';
import Paper from '@material-ui/core/Paper';

interface IPaperContainerProps {
  className: string;
  name?: string;
  children?: React.ReactNode;
}

class PaperContainer extends React.PureComponent<IPaperContainerProps> {

  render(): React.ReactNode {
    const { ...rest } = this.props;

    return (
      <Paper
        {...rest}
      />
    );
  }
}

export default PaperContainer;
