import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    fastEntryContainer: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'space-between',
        'backgroundColor': '#DDDDDD',
        'padding': '10px',
        '@media screen and (max-width: 720px)': {
            flexDirection: 'column'
        }
    },
    fastEntryFields: {
        'display': 'flex',
        'flexFlow': 'wrap',
        '@media screen and (max-width: 720px)': {
            flexDirection: 'column',
            flexFlow: 'unset',
            width: '100%'
        },
        '& > div': {
            'display': 'inline-flex',
            'flexDirection': 'column',
            'marginRight': '5px',
            '@media screen and (max-width: 720px)': {
                flexDirection: 'unset',
                marginRight: '0px'
            }
        },
    },
    buttonsContainer: {
    '@media screen and (max-width: 720px)': {
        display: 'flex',
        justifyContent: 'end',
        width: '100%'
    }
    },
    button: {
        boxShadow: 'none',
        padding: '10px',
        margin: '0 10px',
    },
    circularLoading: {
        color: '#006633',
        margin: '0px 5px'
    },
    field: {
        display: 'inline-flex',
        flexDirection: 'column',
        marginRight: '5px'
    },
    disabledBtn: {
        backgroundColor: '#DDDDDD !important',
    },
    productLookup: {
        'width': '250px',
        'display': 'inline-flex',
        'minWidth': '250px',
        'maxWidth': '250px',
        'alignItems': 'center',
        '& > div > div': {
            'flexDirection': 'column',
            '& > div:nth-child(1)': {
                paddingBottom: '0px',
            },
            '& > div:nth-child(2)': {
                paddingTop: '2px'
            },
            '& > div:nth-child(2) > div': {
                marginLeft: '8px'
            },
        }
    },
    quantity: {
        '& > div:nth-child(2)': {
            paddingTop: 'unset'
        },
    }
});
