import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/index';
import SummaryPanel from './SummaryPanel/index';
import { shallowCompare } from 'utils/utils';
import { withRouter } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import styles from './CashReceipting.scss';
import options from './OptionItems.json';
import { OPERATIONS, Operation } from 'utils/operations';
import { Route } from 'react-router';
import Allocate from './Allocate/index';
import POSCashReceipt from './POSCashReceipt/index';
import { ICashReceiptingProps, ICashReceiptingState } from './interfaces';
import { MODULE_TREE, ACTION_BAR_SCOPES } from './constants';
import CashReceiptingLookupPanel from './CashReceiptingLookupPanel';
import Invoices from './Invoices';
import Orders from './Orders';
import PaymentDetails from 'components/common/PaymentDetails';

const inlineStyles = {
  salesInvoiceEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class CashReceiptngView extends React.Component<ICashReceiptingProps, ICashReceiptingState> {

  constructor(props: ICashReceiptingProps) {
    super(props);

    this.handleModuleChange = this.handleModuleChange.bind(this);
    this.lookupOnApply = this.lookupOnApply.bind(this);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
    this.props.resetForm('CashReceipt');
  }

  shouldComponentUpdate(nextProps: Readonly<ICashReceiptingProps>): any {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: Readonly<ICashReceiptingProps>): void {
    const { operationMode, selectedTab } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('cash-receipting', selectedTab);
    }
  }

  handleModuleChange = (selectedTab: any) => {
    const { changeSelectedTab, getFormSchema, changeSelectedForm, history, changeScope } = this.props;
    changeSelectedTab(selectedTab);
    const context = 'cash-receipting';
    getFormSchema({
      context,
      formId: selectedTab
    });
    changeSelectedForm(context, selectedTab);
    history.push(this.getRoute()[selectedTab]);

    this.fetchChildRecords(selectedTab);
    if (changeScope) {
      const scope = ACTION_BAR_SCOPES[selectedTab] || context;
      changeScope(scope);
    }
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any; parentTab?: string; fetchChildRecords?(params: any): void; postSuccessAction?(): void } } => {
    const context = '/cash-receipting';
    const { completePaymentProcess } = this.props;

    return ({
      CashReceipt: { pathname: `${context}`, search: null, component: POSCashReceipt },
      Allocate: { pathname: `${context}/allocate`, search: null, component: Allocate },
      CashReceiptingInvoices: { pathname: `${context}/allocate/invoices`, search: null, component: Invoices },
      CashReceiptingOrders: { pathname: `${context}/allocate/orders`, search: null, component: Orders },
      PaymentDetails: { pathname: `${context}/payment-details`, search: null, component: PaymentDetails, postSuccessAction: completePaymentProcess, parentTab: 'CashReceipt' },
    });
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  backAction = () => {
    const { selectedTab, changeOperationMode } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'CashReceipt') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('CashReceipt');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
      changeOperationMode(Operation.EDIT);
    }
  }

  getOptionMenuItems = () => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'CashReceipt':
      default: return options.CashReceipting;
    }
  }

  fetchChildRecords = (selectedTab: string) => {
    const routeData = this.getRoute()[selectedTab];
    if (routeData && routeData.fetchChildRecords) {
      routeData.fetchChildRecords(null);
    }
  }

  lookupOnApply = (data: any) => {
    const { applyCashReceiptContext } = this.props;
    this.handleModuleChange('CashReceipt');
    if (applyCashReceiptContext) applyCashReceiptContext(data);
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen, cashReceiptContext, cashReceiptContextLoading, fetchCashReceiptContext, updateCashReceiptContext, changeConfirmationDialog, scope } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <CashReceiptingLookupPanel
            CashReceiptContext={cashReceiptContext}
            loading={cashReceiptContextLoading}
            fetchCashReceiptContext={fetchCashReceiptContext}
            updateCashReceiptContext={updateCashReceiptContext}
            changeConfirmationDialog={changeConfirmationDialog}
            onApply={this.lookupOnApply}
          />
          <div
            style={inlineStyles.salesInvoiceEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'P.O.S. Cash Receipting'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component
                    onInitialLoad={this.handleModuleChange}
                    path={route.pathname}
                    postSuccessAction={() => { if (route.postSuccessAction) route.postSuccessAction(); }} />;
                }}
              />
            ))}

          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuItems()}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={scope} />
      </div>
    );
  }
}

export default withRouter(CashReceiptngView);
