import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as productReceiptsSelectors, actions as productReceiptsActions } from 'ducks/purchaseOrderEnquiry/productReceipts';
import { search } from 'api/purchaseOrderEnquiry/productReceipts';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'ProductReceipts',
  columnDefs: productReceiptsSelectors.productReceiptsColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: productReceiptsSelectors.productReceiptsActions(state),
  gridOptions: productReceiptsSelectors.gridOptions(state),
  isLoading: productReceiptsSelectors.isLoading(state),
  filterRow: productReceiptsSelectors.filterRow(state),
  selectedFilters: productReceiptsSelectors.selectedFilters(state),
  appliedFilters: productReceiptsSelectors.filterRow(state) && filterSelectors.getFilters(state, productReceiptsSelectors.filterRow(state).formName),
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) &&
  {
    PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
  },
  keyFields: [{ valueField: 'Invoice', routeField: 'Invoice' }],
  apiMethod: search
});

export const actions = {
  deleteSelectedRecords: productReceiptsActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: productReceiptsActions.setSelected,
  applyFilters: filterActions.applyFilters
}