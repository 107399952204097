import DataGrid, { Column, Button, Editing, GroupPanel, HeaderFilter, LoadPanel, Paging, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import React from 'react';
import { IDynamicDataGridProperties } from './DynamicDataGrid.properties';
import { withStyles } from '@material-ui/core';
import styles from './DynamicDataGrid.styles';
import { useGridKeyboardNavigation } from '../DataGrid.hooks';
import { COLUMN_SIZE, DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE, NumberOfDecimalPlaces } from '../DataGrid.constants';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import ProductCell from 'components/common/ProductCell';

const DynamicDataGrid = (props: IDynamicDataGridProperties) => {
    const { classes, gridProps, columns, allowHeaderFilting, detailOnClick, calculateFieldValue, onDelete, actionsAtStart, actionsAtEnd, allowUpdating, dataGridClassName } = props;
    const { dataGridRef, disableDrillDown, onEditorPreparing, onKeyDown, sorting, disableEditing, disableDelete, ...restGridProps } = gridProps;
    const { preventDataGridDefaults } = useGridKeyboardNavigation();

    const handleOnKeyDown = React.useCallback(
        (e) => {
            preventDataGridDefaults(e);

            if (onKeyDown) {
                onKeyDown(e);
            }
        },
        [onKeyDown]
    );

    const detailCellComponent = React.useCallback(
        (allProps) => {
            const disableDetail = (allProps?.data?.data?.DisableLine ?? false) || disableDrillDown;

            return <MoreIcon className={disableDetail ? classes.disabledLineDetailIcon : classes.lineDetailIcon} onClick={() => {
                if (detailOnClick) {
                    detailOnClick(allProps.data ? allProps.data : null);
                }
            }} />;
        },
        [disableDrillDown, detailOnClick]
    );

    async function handleSetCellValue(prevData: any, newData: any, field: string, value: number): Promise<void> {
        if (calculateFieldValue) {
            const mergedData = { ...prevData, ...newData };
            mergedData[field] = value;
            const response = await calculateFieldValue(mergedData, field);
            const lineKey = response && Object.keys(response).find((key) => key.toLowerCase() !== 'status') || undefined;
            const lineData = (response?.Status ?? response?.status) && lineKey ? (response[lineKey]?.inlineObject ?? response[lineKey]) : prevData;

            columns.forEach((column) => {
                const dataField = column?.dataField ?? '';
                newData[dataField] = lineData?.[dataField];
            });
        }
    }

    const validateEditing = (e: any) => e.row.data.DisableLine ?? disableEditing ?? false;

    const validateDelete = (e: any) => e.row.data.DisableDeleteAction ?? disableDelete ?? false;

    async function deleteClick(e: any): Promise<void> {
        if (onDelete) {
            onDelete(e.row.key, e.row.data);
        }
    }

    const renderActionsAtStart = (rowData) => actionsAtStart ? actionsAtStart(rowData) : null;

    const renderActionsAtEnd = (rowData) => actionsAtEnd ? actionsAtEnd(rowData) : null;

    const renderCellComponent = (rowData, col) => {
        if (col?.cellComponent) {
            return col?.cellComponent(rowData);
        }

        return undefined;
    };

    return (
        <DataGrid
            {...restGridProps}
            ref={dataGridRef}
            className={dataGridClassName || classes.dataGrid}
            noDataText=''
            showBorders={false}
            renderAsync={true}
            repaintChangesOnly={true}
            allowColumnReordering={true}
            remoteOperations={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            hoverStateEnabled={true}
            sorting={sorting ?? {
                mode: 'none'
            }}
            onEditorPreparing={(e) => {
                e.editorOptions.onFocusIn = (args) => {
                    const input = args.element.querySelector("input[type='text']");
                    if (input) {
                        input.select();
                    }
                };

                if (onEditorPreparing) {
                    onEditorPreparing(e);
                }
            }}
            onKeyDown={handleOnKeyDown}>
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={allowUpdating}
                confirmDelete={false}
            />
            <HeaderFilter
                visible={allowHeaderFilting}
            />
            {detailOnClick && (<Column
                allowEditing={false}
                width={COLUMN_SIZE.sm0}
                allowResizing={false}
                fixed={true}
                alignment='center'
                allowHeaderFiltering={false}
                cellComponent={detailCellComponent} />)
            }

            {columns?.map((col) => (col?.caption === 'Product') ?
                (
                    <Column
                        {...col}
                        caption={'Product'}
                        allowEditing={false}
                        allowHeaderFiltering={false}
                        width={COLUMN_SIZE.lg0}
                        fixed={true}
                        cellComponent={(allProps) => (
                            <ProductCell
                                urlParamKeys={['ProductId']}
                                toLink={'/inventory-enquiry/product-details'}
                                {...allProps} />
                        )}
                    />
                ) : <Column
                    {...col}
                    editorOptions={{
                        ...col?.isEditable ? { step: 0 } : { disabled: true },
                        ...col?.editorOptions
                    }}
                    setCellValue={col?.isEditable ? async (newData, value: number, prevData): Promise<void> => {
                        await handleSetCellValue(prevData, newData, col?.dataField, value);
                    } : undefined}

                    calculateDisplayValue={
                        col?.shouldFixToDecimalPlaces ? (data: Record<string, unknown>) =>
                            (data?.[col?.dataField] as number)?.toFixed(data?.UnitConversionMaximumFractionDigits as number ?? NumberOfDecimalPlaces) : undefined
                    }
                    headerFilter={col?.headerFiltersDataSource ? { dataSource: col?.headerFiltersDataSource } : null}
                    cellComponent={col?.cellComponent ? (rowdata) => renderCellComponent(rowdata, col) : undefined}
                />
            )}

            <Column type={'buttons'} width={COLUMN_SIZE.sm1} allowResizing={false} fixed={true}>
                <Button render={renderActionsAtStart} />
                <Button name={'save'} icon={'save'} />
                <Button name={'edit'} icon={'edit'} disabled={validateEditing} cssClass={classes.actionIcon} />
                <Button name={'delete'} icon={'trash'} disabled={validateDelete} cssClass={classes.actionIcon} onClick={deleteClick} />
                <Button name={'cancel'} icon={'undo'} />
                <Button name={'undelete'} icon={'undo'} />
                <Button render={renderActionsAtEnd} />
            </Column>

            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel
                shading={false}
                height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH}
                width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH}
                text={''}
                showPane={false} />
        </DataGrid>
    );
};

export default withStyles(styles, { index: 1 })(DynamicDataGrid);
