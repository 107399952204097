import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
    getDetails: (DeliveryId) => ({ DeliveryId })
  }
}, 'deliveryLine');


const initialState = asyncInitialState({
  selected: null,
  details: null,
  gridOptions: {
    doubleClickActionTab: 'DeliveryDetails',
    suppressEditDeleteInContextMenu: true,
  },
  deliveryLinesActions: {
  },
  deliveryLinesColumns: [
    { headerName: 'Site', field: 'Site', type: 'numericColumn', suppressSorting: true, minWidth: 100 },
    { headerName: 'Default', field: 'PrimaryDisplay', suppressSorting: true },
    { headerName: 'Delivery type', field: 'DeliveryType', suppressSorting: true },
    { headerName: 'City', field: 'City', suppressSorting: true },
    { headerName: 'Carrier', field: 'Carrier', suppressSorting: true },
    { headerName: 'Send via', field: 'SendVia', suppressSorting: true },
    { headerName: 'Delivery name', field: 'DeliveryAddressName', suppressSorting: true },
    { headerName: 'Contact', field: 'ContactName', suppressSorting: true },
  ],
  deliveryLinesData: [
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearDeliveryLines:
      return {
        ...state,
        data: {
          ...state.data,
          deliveryLinesData: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.getDetails:
      return asyncOnRequest({
        ...state,
        data: { ...state.data, details: null }
      }, action)

    case types.saga.getDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          details: linesData
        }
      })
    case types.saga.getDetails.failure:
      return asyncOnError(state, action)

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.deliveryLine,
  {
    selectedDeliveryLine: data => data.selected,
    deliveryDeliveryActions: data => data.deliveryLinesActions,
    details: data => data.details,
    selected: data => data.selected,
  }
)

const syncSelector = {
  deliveryLinesData: state => state.salesOrderEnquiry.deliveryLine.data.deliveryLinesData,
  isLoading: state => state.salesOrderEnquiry.deliveryLine.fetch_loading,
  loadingDetails: state => state.salesOrderEnquiry.deliveryLine.getDetails_loading,
  gridOptions: state => state.salesOrderEnquiry.deliveryLine.data.gridOptions,
  deliveryLinesColumns: state => state.salesOrderEnquiry.deliveryLine.data.deliveryLinesColumns,
  deliveryLinesActions: state => state.salesOrderEnquiry.deliveryLine.data.deliveryLinesActions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)