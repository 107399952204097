import { IFormViewForm } from 'components/FormView';

const FinalConfirmation: IFormViewForm = {
    id: 'FinalConfirmationForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                    },
                    props: {
                        name: 'OutputPurchaseOrder',
                        defaultValue: true,
                        label: 'Purchase Order',
                    }
                },
                {
                    id: 2,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'PurchaseOrderOutput',
                        fieldId: 'PurchaseOrderOutput',
                        documentType: 'SO',
                        isSupplierScoped: true,
                        precedentFields: ['OutputPurchaseOrder'],
                    }
                },
                {
                    id: 2,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                    },
                    props: {
                        name: 'Transmit',
                        defaultValue: false,
                        label: 'Transmit',
                        precedentFields: ['EnableTransmit'],
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    style: {
                        display: 'inline-block',
                        width: '63%'
                    },
                    props: {
                        name: 'TransmitVia',
                        placeholder: '',
                        size: 'large',
                        precedentFields: ['TransmitViaReadOnly'],
                    }
                },
                {
                    id: 3,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    props: {
                        name: 'SetStatusToTransmit',
                        defaultValue: true,
                        label: 'Set P/O status to transmitted'
                    }
                },
                {
                    id: 4,
                    type: 'DIVIDER',
                    props: {
                    }
                },
                {
                    id: 5,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'User ID',
                        name: 'PurchaseOrderUserId',
                        lookupName: 'Personnel',
                        isLookupTypeFixed: true,
                        size: 'small',
                    }
                },
                {
                    id: 6,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Pin',
                        name: 'Pin',
                        placeholder: '****',
                        size: 'small',
                        type: 'password',

                    }
                },
            ]
        }
    ]
};

export default FinalConfirmation;
