import React, { forwardRef } from 'react';
import styles from './FieldsGrid.styles';
import { COLUMN_SIZE, DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Selection, Sorting, HeaderFilter } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core';
import { IFieldsGridProps, IFieldsGridsHandle } from './FilterGrid.properties';
import { useFetchRAFields } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { useEMContextSelector, useEMDispatch } from 'components/ExtractMaintenance/ExtractMaintenance.context';
import CheckBox from 'devextreme/ui/check_box';
import IconWithText from './IconWithText';

const FieldsGrid = (props: IFieldsGridProps, ref: React.Ref<IFieldsGridsHandle>) => {
    const { classes } = props;

    const params = new URLSearchParams(location.search);
    const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);
    const contextDispatch = useEMDispatch();
    const fieldsGridRef = React.useRef<DataGrid>();

    const focusedArea = useEMContextSelector<'focusedArea'>((state) => state.focusedArea);
    const isFocusedAreaNameSelected = useEMContextSelector<'isFocusedAreaNameSelected'>((state) => state.isFocusedAreaNameSelected);
    const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);
    const resetfocusedFieldNames = useEMContextSelector<'resetfocusedFieldNames'>((state) => state.resetfocusedFieldNames);

    const fetchRAFieldsMutation = useFetchRAFields();

    const fetchFields = React.useCallback(
        () => {
            if (!templateId || focusedArea?.IsPrimary || !focusedArea?.AreaName) {
                fetchRAFieldsMutation.reset();

                return null;
            }

            return fetchRAFieldsMutation.mutateAsync({
                TemplateId: templateId,
                urlQueryParams: {
                    AreaName: focusedArea?.AreaName
                }
            });

        },
        [templateId, focusedArea]
    );

    React.useEffect(
        () => {
            fetchFields();
        },
        [fetchFields]
    );

    React.useEffect(
        () => {
            if (resetfocusedFieldNames) {
                fieldsGridRef.current.instance.deselectAll();
                contextDispatch({ setFocusedFieldNamesempty: false });
            }
        },
        [resetfocusedFieldNames, fieldsGridRef]
    );

    React.useEffect(
        () => {
            if (fetchRAFieldsMutation.isLoading) {
                fieldsGridRef.current?.instance?.beginCustomLoading('');
            } else {
                fieldsGridRef.current?.instance?.endCustomLoading();
            }
        },
        [fetchRAFieldsMutation.isLoading]
    );

    React.useImperativeHandle(ref, () => ({
        deselectAll: () => {
            fieldsGridRef.current.instance.deselectAll();
        },
        getSelectedRowsData: () => {
            return fieldsGridRef.current.instance.getSelectedRowsData();
        },
    }));

    const handleRowClick = React.useCallback(
        (e) => {
            const keys = e.component.getSelectedRowKeys();
            const index = keys.indexOf(e.key);

            if (index > -1) {
                keys.splice(index, 1);
            } else {
                keys.push(e.key);
            }

            e.component.selectRows(keys, false);
        },
        []
    );

    const onCellPrepared = React.useCallback(
        (e) => {
            if (e.rowType === 'data' && e.columnIndex === 0) {
                //disable checkboxes that are not allowed to edit
                const element = e.cellElement.querySelector('.dx-select-checkbox');
                const checkBox = CheckBox.getInstance(element);
                checkBox?.option('disabled', (!isFocusedAreaNameSelected || !inEditMode));
            }
        },
        [isFocusedAreaNameSelected, inEditMode]
    );

    const onSelectionChanged = React.useCallback(
        (e) => {
            if (!isFocusedAreaNameSelected || !inEditMode) {
                e.component?.deselectRows(e.currentSelectedRowKeys);
            }

            contextDispatch({
                setFocusedFieldNames: e.component.getSelectedRowKeys().map((fieldObject) => fieldObject),
            });
        },
        [isFocusedAreaNameSelected, inEditMode]
    );

    return (
        <DataGrid
            className={classes.FieldsGrid}
            dataSource={fetchRAFieldsMutation.data?.ExtractAreaFields ?? []}
            ref={fieldsGridRef}
            showBorders={true}
            renderAsync={true}
            remoteOperations={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            hoverStateEnabled={true}
            showColumnHeaders={false}
            noDataText=''
            showColumnLines={false}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}
            onRowClick={handleRowClick}
        >
            <Selection mode='multiple' showCheckBoxesMode='always' />
            <Column
                type={'selection'}
                width={COLUMN_SIZE.sm0}
                minWidth={COLUMN_SIZE.sm0}

            />
            <HeaderFilter visible />
            <Column
                dataField={'FieldName'}
                cssClass={(!inEditMode || !isFocusedAreaNameSelected) ? 'field-disabled' : ''}
                allowHeaderFiltering={false}
                allowEditing={false}
                cellRender={({data}) => <IconWithText data={data} />}
            >
            </Column>
            <Sorting mode='none' />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        </DataGrid >
    );
};

export default withStyles(styles, { index: 1 })(forwardRef(FieldsGrid));
