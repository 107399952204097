import { IFormViewForm } from 'components/FormView';

const CNLineDetails: IFormViewForm = {
  id: 'CNLineDetails',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Product',
            name: 'ProductId',
            lookupName: 'Product',
            suppressDescription: true
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          props: {
            label: 'Product group',
            name: 'ProductGroup',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            size: 'large'
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Quantity',
            name: 'Qauntity',
            type: 'number',
            size: 'medium',
            decimalSeparator: true,
          }
        },
        {
          id: 4,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Reason',
            name: 'CustomerCreditReason',
            isLookupTypeFixed: true,
            lookupName: 'CustomerCreditReason',
            size: 'small'
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          props: {
            label: 'Price',
            name: 'Price',
            size: 'medium',
            type: 'number',
            decimalSeparator: true
          }
        },
        {
          id: 6,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Supplier discount',
            alignment: 'right',
            name: 'Discount',
            adornment: '%',
            size: 'small'
          }
        },
        {
          id: 7,
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Tax included price',
            name: 'TaxIncluded',
            defaultValue: false
          }
        },
        {
          id: 8,
          type: 'RESTOCKING_CALCULATOR_FIELD',
          props: {
            label: 'Restocking charge',
            name: 'RestockingCharge',
            required: false,
            size: 'medium',
            type: 'number',
            decimalSeparator: true
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true,
        style: {
          height: '500px'
        }
      },
      children: [
        {
          id: 1,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '500px'
            }
          }
        }
      ]
    }
  ]
};
export default CNLineDetails;
