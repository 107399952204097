import { combineReducers } from 'redux';

import invoice, { IInvoiceState } from './Invoice';
import invoiceLines from './InvoiceLines';
import slips from './slips';
import payment from './payment';
import BOM from './InvoiceLineBOM';
import Serial from './InvoiceLineSerials';
import Lot from './InvoiceLineLots';
import salesAudit from './InvoiceSalesAudit';
import salesAuditDetails from './SalesAuditDetails';

export interface ISalesInvoiceEnquiryState {
  invoice: IInvoiceState;
}

const SalesInvoiceEnquiryReducer = combineReducers({
  invoice,
  invoiceLines,
  slips,
  payment,
  BOM,
  Serial,
  Lot,
  salesAudit,
  salesAuditDetails
});

export default SalesInvoiceEnquiryReducer;
