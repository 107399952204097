import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';

export const search = (query) => {
  const { SearchText, Supplier, Sort, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchPost(`/SupplierEnquiry/Deliveries/Supplier/${Supplier}/Retrieve`, { SearchText, Sort, BatchSize })
    .then((data) => {
      if (data && data.SupplierDeliverys) {
        return {
          records: data.SupplierDeliverys.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
          BatchInformation: data.BatchInformation
        }
      }
    })
}


export const searchById = ({ Supplier, SiteCode }) => {
  return fetchGet(`/SupplierEnquiry/DeliveryDetails/Supplier/${Supplier}/SiteCode/${SiteCode}`, null, 'Inline')
    .then((data) => {
      if (data && data.SupplierDeliveryDetail) {
        return {
          records: [{
            inlineObject: objectify(data.SupplierDeliveryDetail),
            schema: data.SupplierDeliveryDetail
          }]
        }
      }
      else return []
    })
}