import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as transactionTypes, actions as transactionActions } from 'ducks/supplierEnquiry/supplierDetailsTransactions';

import { callApi } from '../utils';
import * as api from 'api/supplierEnquiry/detailsTransactions';


function* search(action){
  const {success, failure} = transactionActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootTransactionSaga(){
  yield takeLatest(transactionTypes.search, search);
}