export default [
  {
    id: '1',
    label: 'Customer Enquiry in new tab',
    'action': (params) => { window.open('/customer-enquiry' + ( params ? '/' + params : '' )) }
  },
  {
    id: '2',
    label: 'Customer Maintenance in new tab',
    'action': (params) => { window.open('/customer-maintenance' + ( params ? '/' + params : '' )) }
  },
  {
    id: '3',
    label: 'Customer Enquiry',
    'action': (params) => { window.location.pathname = '/customer-enquiry' + ( params ? '/' + params : '' ) }
  },
  {
    id: '4',
    label: 'Customer Maintenance',
    'action': (params) => { window.location.pathname = '/customer-maintenance' + ( params ? '/' + params : '' ) }
  }
];
