import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';

export interface ISupplierDetailsData {
  details: any;
}

export interface ISupplierDetailsState extends IExtendedState<ISupplierDetailsData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (filters) => (filters),
    }
  },
  'details');

const initialState = asyncInitialState<ISupplierDetailsData>({
  details: null
});

export default (state: ISupplierDetailsState = initialState, action: DataAction): ISupplierDetailsState => {
  switch (action.type) {
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: ISupplierDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          details: successAction.payload
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { priceCheck: { details: ISupplierDetailsState } }) => state.priceCheck.details,
    {
      details: (data: ISupplierDetailsData) => data.details,
    }),
  isLoading: (state: { priceCheck: { details: ISupplierDetailsState } }) => state.priceCheck.details.loading,
};
