import * as React from 'react';
import FormView from '../../FormView';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from '../SummaryTableSchema.json';
import { IBranchOrdersProperties } from '../interfaces';

class BranchOrders extends React.PureComponent<IBranchOrdersProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/branch-orders':
          onInitialLoad('BranchOrders');
          break;
        default:
          onInitialLoad('BranchOrders');
      }
    }
  }

  branchOrderSummary = (): React.ReactNode => {
    const { branchOrderSummary, branchOrderSummaryLoading } = this.props;

    return <SummaryTable
      branchOrder={branchOrderSummary}
      branchOrderLoading={branchOrderSummaryLoading}
      data={
        SummaryTableSchema.BranchOrders
      } />;
  };

  getSummaryTableRenderer = (): React.ReactNode => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'BranchOrders':
        return this.branchOrderSummary;
      default: return null;
    }
  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName='BranchOrders'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default BranchOrders;
