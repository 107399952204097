export default [
  {
    id: '1',
    name: 'Tom Cruise',
    abbreviation: '1',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Jason Brown',
    abbreviation: '2',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'Tony Stark',
    abbreviation: '3',
    fieldList1: 'field'
  },
];
