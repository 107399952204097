import * as React from 'react';
import LookupField from '@markinson/uicomponents-v2/LookupField';
import { CustomerLookupProps } from './CustomerLookup.properties';
import { Column } from 'devextreme-react/data-grid';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import { lookup, search } from 'api/customer/customerSearch';
const WIDTH_100 = 100;
const WIDTH_200 = 200;

function CustomerLookup(props: Readonly<CustomerLookupProps>, ref: React.Ref<ITextFieldHandle>): JSX.Element {
    const { onChange, onBlur, onSelectedItemChanged, ...restProps } = props;

    const handleOnSelectedItemChanged = (item) => {
        onChange(item ? Number(item.CustomerId) : null);

        if (onSelectedItemChanged) {
            onSelectedItemChanged(item);
        }
    };

    return (
        <LookupField
            innerRef={ref}
            searchScreenDialogStyle={{
                width: 892,
                maxWidth: 892,
                height: 495,
                maxHeight: 495
            }}
            valueField={'CustomerId'}
            displayField={'CustomerAccount'}
            descriptionField={'Name'}
            placeholder={'Customer'}
            onLookup={async (searchText, exactMatch) => lookup(searchText, exactMatch)}
            onSearch={async (searchText) => search(searchText)}
            onChange={(v) => console.log(v)}
            onSelectedItemChanged={handleOnSelectedItemChanged}
            {...restProps}
        >
            <Column dataField='CustomerAccount' caption='Customer' allowResizing={true} minWidth={WIDTH_100} fixed={true} />
            <Column dataField='Abbreviation' caption='Abbrev' allowResizing={true} minWidth={WIDTH_100} />
            <Column dataField='Name' caption='Name' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='City' caption='City' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='State' caption='State' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='BusinessNumber' caption='Business no' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='CompanyNumber' caption='Company no' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='Owner' caption='Owner' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
        </LookupField >);
}

export default React.forwardRef(CustomerLookup);
