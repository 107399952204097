import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as localShipmentsSelectors, actions as localShipmentsActions } from 'ducks/purchaseOrderEnquiry/ldInTransitLocalShipments';
import { searchLocalShipments } from 'api/purchaseOrderEnquiry/ldInTransit';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { selectors as orderLineSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'InTransitLocalShipments',
  columnDefs: localShipmentsSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: localShipmentsSelectors.Actions(state),
  gridOptions: localShipmentsSelectors.gridOptions(state),
  isLoading: localShipmentsSelectors.isLoading(state),
  apiMethod: searchLocalShipments,
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) && (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber'))
    &&
  {
    PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
    LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
  },
  keyFields: [{ valueField: 'PurchaseOrderLineNumber', routeField: 'LineNumber' }],
});

export const actions = {
  deleteSelectedRecords: localShipmentsActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: localShipmentsActions.setSelected
}
