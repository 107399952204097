/* APIRoutes
* Use create/retrieve/update/delete keywords for api prop name and follow the module screen hierarchy.
* if same api is used for multiple operations, define it once with the prop name in short form
*  (e.g cu, cd,cud, crud)
*/

export const APIRoutes = {
    product: {
        lotSerial: {
            update: 'Product/UpdateWIPBinLot'
        }
    }
};
