import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchPriceDetails: (filters, api) => ({ filters, api }),
    fetchDefaultPriceDetails: (filters) => ({ filters }),
  }
}, 'priceCalculator');


const initialState = asyncInitialState({
  Data: null,
  DefaultPriceDetails: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.fetchPriceDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchPriceDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          Data: linesData
        }
      })
    case types.saga.fetchPriceDetails.failure:
      return asyncOnError(state, action);
    case types.fetchDefaultPriceDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchDefaultPriceDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          DefaultPriceDetails: linesData
        }
      })
    case types.saga.fetchDefaultPriceDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.common.priceCalculator,
  {
    data: data => data.Data,
    defaultPriceDetails: data => data.DefaultPriceDetails
  }
)

const syncSelector = {
  isLoading: state => state.common.priceCalculator.fetchPriceDetails_loading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)