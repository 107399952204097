import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../utils';

export interface ITransactionsData {
  selected: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface ITransactionsState extends IExtendedState<ITransactionsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {

    }
  },
  'PMETransactions');

const initialState = asyncInitialState<ITransactionsData>({
  selected: null,
  gridOptions: {
  },
  Columns: [
    { headerName: 'Warehouse', field: 'WareHouse', minWidth: 120, suppressSorting: true, },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      },
      minWidth: 150,
      suppressSorting: true
    },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true, },
    { headerName: 'Balance', field: 'BalanceDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true, },
    { headerName: 'Cost/unit', field: 'CostDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true, },
    { headerName: 'Product Description', field: 'ProductIdLabel', minWidth: 180, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WareHouseName', minWidth: 180, suppressSorting: true, },
  ]
});

export default (state: ITransactionsState = initialState, action: IDataAction): ITransactionsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { pme: { transactions: ITransactionsState } }) => state.pme.transactions.data.selected,
  gridOptions: (state: { pme: { transactions: ITransactionsState } }) => state.pme.transactions.data.gridOptions,
  columns: (state: { pme: { transactions: ITransactionsState } }) => state.pme.transactions.data.Columns
});
