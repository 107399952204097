import * as React from 'react';
import FormView from '../../FormView/index';
import { ICustomerPricingProps } from '../interfaces';

class CustomerPricing extends React.PureComponent<ICustomerPricingProps> {
  componentDidMount(): void {
    const { onInitialLoad } = this.props;
    onInitialLoad('Pricing');
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          valuesSchema={null}
        />
      </React.Fragment>
    );
  }
}

export default CustomerPricing;
