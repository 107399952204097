import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../utils';

export interface IBinLocationsData {
  selected: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface IBinLocationsState extends IExtendedState<IBinLocationsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {

    }
  },
  'PMEBinLocations');

const initialState = asyncInitialState<IBinLocationsData>({
  selected: null,
  gridOptions: {
  },
  Columns: [
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Bin', field: 'Bin', minWidth: 180, suppressSorting: true, },
    { headerName: 'Quantity', field: 'QuantityOnHandDisplay', type: 'numericColumn', minWidth: 180, suppressSorting: true, },
    { headerName: 'Bin Description', field: 'Description', minWidth: 180, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WarehouseDisplay', minWidth: 180, suppressSorting: true, },
  ]
});

export default (state: IBinLocationsState = initialState, action: IDataAction): IBinLocationsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { pme: { binLocations: IBinLocationsState } }) => state.pme.binLocations.data.selected,
  gridOptions: (state: { pme: { binLocations: IBinLocationsState } }) => state.pme.binLocations.data.gridOptions,
  columns: (state: { pme: { binLocations: IBinLocationsState } }) => state.pme.binLocations.data.Columns
});
