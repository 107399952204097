const DeliveryDetails = {
  'id': 'DeliveryDetails',
  'layout': {
    'rows': 2,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Site address',
      },
      'children': [
        {
          'id': 1,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'label': '',
            'name': 'address',
            'type': 'extended',
            'address1': false,
          }
        }
      ]
    },
    {
      'id': 2,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Site contact details',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Contact',
            'name': 'Contact',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Phone',
            'name': 'Phone',
            'action': {
              'iconName': 'Phone'
            },
            'required': false,
            'disabled': false
          }
        },
        {
          'id': 2,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Mobile',
            'name': 'Mobile',
            'action': {
              'iconName': 'Phone'
            },
            'required': false,
            'disabled': false
          }
        },
        {
          'id': 3,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Fax',
            'name': 'Fax',
            'action': {
              'iconName': 'Phone'
            },
            'required': false,
            'disabled': false
          }
        },
        {
          'id': 4,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Email',
            'name': 'Email',
            'size': 'large',
            'action': {
              'iconName': 'Mail'
            },
            'required': false,
            'disabled': false
          }
        }
      ]
    },
    {
      'id': 3,
      'position': {
        'row': 2,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Shipping details',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Freight code',
            'name': 'FreightCode',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Send via',
            'name': 'SendVia',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Carrier',
            'name': 'Carrier',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Freight account',
            'name': 'FreightAccount',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Instruction',
            'name': 'Instruction',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 4,
      'position': {
        'row': 2,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EX_LOOKUP_FIELD',
          'visible': true,
          'props': {
            'label': 'Territory',
            'name': 'Territory',
            'size': 'small',
            lookupName: 'SalesTerritory',
          }
        },
        {
          'id': 1,
          'type': 'EX_LOOKUP_FIELD',
          'visible': true,
          'props': {
            'label': 'Sales rep',
            'name': 'SalesRepresentative',
            'size': 'small',
            lookupName: 'Personnel',
          }
        }
      ]
    }
  ]
}

export default DeliveryDetails;