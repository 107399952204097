import { call, select, put, Effect, takeLatest } from 'redux-saga/effects';

import { actions as uiActions } from 'ducks/ui';
import { IDataAction } from 'ducks/utils';
import { types as slipLineTypes, actions as slipLineActions } from 'ducks/pickSlips/slipLines';
import { selectors as pickLipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { types as lotTypes, actions as lotActions } from 'ducks/pickSlips/lots';
import { actions as filterActions } from 'ducks/common/filters';

import { callApi } from '../utils';
import * as api from 'api/pickSlips/slipLines';

function* fetchSlipLines(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.fetchSlipLines;

  const filters = action.data;
  yield put(filterActions.applyFilters({ formName: 'PickSlipLinesFilters', filters }));

  yield callApi(
    call(api.fetchSlipLines, filters),
    success,
    failure,
    { dialogActionCallEffect: fetchSlipLines }
  );
}

function* createProductCharge(): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.createProductCharge;

  yield callApi(
    call(api.createProductCharge),
    success,
    failure
  );
}

function* updateProductCharge(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.updateProductCharge;

  yield callApi(
    call(api.updateProductCharge, action.data),
    success,
    failure
  );
}

function* deleteSlipLine(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.deleteSlipLine;
  const DespatchId = yield select(pickLipSelectors.selectedDespatchId);

  const payload = { LineNumber: action.data, DespatchId };

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(uiActions.changeSelectedTab('SlipLines'));
  }
  yield callApi(
    call(api.deleteSlipLine, payload),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* fetchSlipLineDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.fetchSlipLineDetails;

  yield callApi(
    call(api.fetchSlipLineDetails, action.data),
    success,
    failure
  );
}

function* cancelSlipLineDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.cancelSlipLineDetails;

  yield callApi(
    call(api.cancelSlipLineDetails, action.data),
    success,
    failure
  );
}

function* updateSlipLineDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.updateSlipLineDetails;

  yield callApi(
    call(api.updateSlipLineDetails, action.data),
    success,
    failure
  );
}

function* fetchSlipLineDetailsSummary(action: any): IterableIterator<Effect> {
  const { success, failure } = slipLineActions.saga.fetchSlipLineDetailsSummary;

  yield callApi(
    call(api.fetchSlipLineDetailsSummary, action.data),
    success,
    failure
  );
}

function* fetchPickSlipLot(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = lotActions.saga.fetchPickSlipLot;
  yield put(lotActions.changeModalVisibility({ openLotDetailModal: true }));

  yield callApi(
    call(api.fetchPickSlipLot, action.data),
    success,
    failure
  );
}

function* updatePickSlipLot(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = lotActions.saga.updatePickSlipLot;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    const { DespatchId, LineNumber } = action.data;
    const data = { DespatchId, LineNumber };

    yield put(lotActions.changeModalVisibility({ openLotDetailModal: false }));
    yield call(fetchLots, { data });
    yield call(fetchSlipLineDetailsSummary, { data });
  }

  yield callApi(
    call(api.updatePickSlipLot, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* saveNewPickSlipLot(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = lotActions.saga.saveNewPickSlipLot;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    const { DespatchId, LineNumber } = action.data;
    const data = { DespatchId, LineNumber };

    yield call(fetchLots, { data });
    yield call(fetchSlipLineDetailsSummary, { data });
  }

  yield callApi(
    call(api.saveNewPickSlipLot, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* fetchLots(action: any): IterableIterator<Effect> {
  const { success, failure } = lotActions.saga.fetchLots;

  yield callApi(
    call(api.fetchLots, action.data),
    success,
    failure
  );
}

function* saveLot(action: any): IterableIterator<Effect> {
  const { success, failure } = lotActions.saga.saveNewPickSlipLot;

  yield callApi(
    call(api.saveLot, action.data),
    success,
    failure
  );
}

export default function* rootSlipLineSaga(): IterableIterator<Effect> {
  yield takeLatest(slipLineTypes.fetchSlipLineDetails, fetchSlipLineDetails);
  yield takeLatest(slipLineTypes.fetchSlipLineDetailsSummary, fetchSlipLineDetailsSummary);
  yield takeLatest(slipLineTypes.fetchSlipLines, fetchSlipLines);
  yield takeLatest(slipLineTypes.deleteSlipLine, deleteSlipLine);
  yield takeLatest(slipLineTypes.cancelSlipLineDetails, cancelSlipLineDetails);
  yield takeLatest(slipLineTypes.createProductCharge, createProductCharge);
  yield takeLatest(slipLineTypes.updateProductCharge, updateProductCharge);
  yield takeLatest(slipLineTypes.updateSlipLineDetails, updateSlipLineDetails);
  yield takeLatest(lotTypes.fetchPickSlipLot, fetchPickSlipLot);
  yield takeLatest(lotTypes.updatePickSlipLot, updatePickSlipLot);
  yield takeLatest(lotTypes.saveNewPickSlipLot, saveNewPickSlipLot);
  yield takeLatest(lotTypes.fetchLots, fetchLots);
  yield takeLatest(lotTypes.saveNewPickSlipLot, saveLot);
}
