import { BaseApi, checkResponseStatus, checkResponseContentType, ApiError } from './baseApi';
import { fetchPost, fetchGet } from './utils';
import { ConnectSource } from '../config';
import { isNull } from 'utils/utils';

const IDENTIFIER_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/userId';
const NAME_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/userName';
const DEFAULT_VIEW_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/defaultview';
const MASTER_VIEW_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/masterview';
const DEFAULT_SALES_ENTITY_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/defaultsalesentity';
const DEFAULT_WAREHOUSE_ENTITY_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/defaultwarehouseentity';
const IS_ADMINISTRATOR_CLAIM = 'http://schemas.markinson.com/moprov4/2018/07/identity/claims/isadministrator';

interface ILoginResponse {
  access_token: string;
  token_type: ('Bearer');
}

export interface IStaff {
  id: string;
  name: string;
  defaultView: string;
  masterView: string;
  IsAdministrator: string;
  defaultSalesEntity: string;
  defaultWarehouseEntity: string;
}

export interface ILoginDetails {
  token: string;
  token_type: ('Bearer');
  staff: IStaff;
}

class LoginApi extends BaseApi {

  loginURI: string = isNull(ConnectSource) ? 'Login' : `LoginExternalUser?ConnectSource=${ConnectSource}`;

  constructor() {
    super();
  }

  parseToken = (accessToken: string): IStaff => {
    const jwtPayload = JSON.parse(atob(accessToken.split('.')[1]));

    return {
      id: jwtPayload[IDENTIFIER_CLAIM],
      name: jwtPayload[NAME_CLAIM],
      defaultView: jwtPayload[DEFAULT_VIEW_CLAIM],
      masterView: jwtPayload[MASTER_VIEW_CLAIM],
      defaultSalesEntity: jwtPayload[DEFAULT_SALES_ENTITY_CLAIM],
      defaultWarehouseEntity: jwtPayload[DEFAULT_WAREHOUSE_ENTITY_CLAIM],
      IsAdministrator: jwtPayload[IS_ADMINISTRATOR_CLAIM]
    };
  }

  async login(username: string, password: string): Promise<ILoginDetails> {
    return fetch(new URL(this.loginURI, this.baseApiEndpoint).href, {
      method: 'POST', headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`
      },
    }).then(checkResponseStatus)
      .then(checkResponseContentType)
      .then((data: any) => {
        if (typeof (data) === 'object') {
          const loginResponse = data as ILoginResponse;

          return {
            token: loginResponse.access_token,
            token_type: loginResponse.token_type,
            staff: this.parseToken(loginResponse.access_token)
          };
        }

        let errorMessage = 'Unexpected Login Response.';
        if (typeof (data) === 'string') {
          errorMessage = data;
        }

        const error = new ApiError(errorMessage);
        error.showUser = true;
        throw error;
      });
  }
}

const loginApi = new LoginApi();

export const login = async ({ staffUserName, staffPassword }) => loginApi.login(staffUserName, staffPassword);
export const parseToken = loginApi.parseToken;

export const changePasswordAPI = async (query: { staffUserName: string; staffPassword: string; newPassword: string }): Promise<{ success: boolean }> => {
  const respType = 'Inline';
  const { staffUserName, staffPassword, newPassword } = query;

  return fetchPost(
    '/SystemUserMaintenance/ChangePassword', {
    username: staffUserName,
    password: staffPassword,
    passwordNew: newPassword
  },
    null,
    respType)
    .then((result: {
      success: boolean;
    }) => {
      if (result) {
        return result;
      }

      return { success: false };
    });
};

export const logout = async (): Promise<{ success: boolean }> => {
  return fetchGet(
    '/Logout', {}, 'None')
    .then(() => {
      return { success: true };
    }).catch(() => {
      return { success: false };
    });
};
