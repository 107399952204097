import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as suppressionsSelectors, actions as suppressionsActions } from 'ducks/inventoryEnquiry/associations/suppressions'
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/associations/suppressions';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'ProductAssociationsSuppressions',
  columnDefs: suppressionsSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: suppressionsSelectors.gridOptions(state),
  isLoading: suppressionsSelectors.isLoading(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch

})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: suppressionsActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode
}
