import * as React from 'react';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import CustomerSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Customer';

import BreadcrumbBar from './../common/BreadcrumbBar';
import NavigationTabs from './NavigationTabs';
import * as styles from './CustomerMaintenance.scss';
import options from './OptionItems.json';
import { Operation } from '../../utils/operations';
import { Route } from 'react-router';

import CustomerDetails from './CustomerDetails';
import Contacts from './Contacts';
import DeliveryDetails from './DeliveryDetails';
import SalesDiary from './SalesDiary';
import { ICustomerMaintenanceViewProperties } from './interfaces';
import { MODULE_TREE, TABS_DATA } from './constants';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const inlineStyles = {
  customerMaintenanceSection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

const isMainCustomerTab = (selectedTab: string) => ['CustomerDetails', 'CustomerSetting', 'CustomerFinancial', 'CustomerPricing'].includes(selectedTab);

class CustomerMaintenanceView extends React.PureComponent<ICustomerMaintenanceViewProperties> {

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'customer-maintenance';
    const CustomerId = params.get('CustomerId') || '';
    const ContactNumber = params.get('ContactNumber') || '';
    const SiteCode = params.get('SiteCode') || '';
    const DiaryId = params.get('DiaryId') || '';

    const routes = ({
      CustomerMaintenance: { pathname: `/${context}`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      CustomerDetails: { pathname: `/${context}/customer-details`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      Contacts: { pathname: `/${context}/contacts`, search: `?CustomerId=${CustomerId}&ContactNumber=${ContactNumber}`, component: Contacts },
      DeliveryDetails: { pathname: `/${context}/delivery-details`, search: `?CustomerId=${CustomerId}&SiteCode=${SiteCode}`, component: DeliveryDetails },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?CustomerId=${CustomerId}`, component: SalesDiary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?CustomerId=${CustomerId}&DiaryId=${DiaryId}`, component: SalesDiary }
    });

    return routes;
  }

  handleModuleChange = (tab: string) => {
    const {
      operationMode, selectedTab, changeSelectedTab, selectedCustomer,
      getFormSchema, changeOperationMode,
      toggleBrowseLookUp, changeSelectedForm } = this.props;
    if (operationMode === Operation.EDIT || operationMode === Operation.NEW) {
      if (!(isMainCustomerTab(selectedTab) && isMainCustomerTab(tab))) {
        if (selectedTab !== tab) {
          showSnackBar({ variant: 'warning', message: 'Please Save or Cancel changes in current record.' });

          return;
        }
      }
    }
    if (!selectedCustomer && (tab === 'DeliveryDetails' || tab === 'Contacts' || tab === 'SalesDiary')) {
      showSnackBar({ variant: 'error', message: 'No customer selected.' });
      this.handleModuleChange('CustomerDetails');

      return;
    }
    if (!(isMainCustomerTab(tab) && operationMode !== Operation.BROWSE)) {
      const { pathname, search } = this.getRoute()[(isMainCustomerTab(tab) && 'CustomerDetails') || tab];
      this.props.history.push({ pathname, search });
    }
    changeSelectedTab(tab);
    const context = 'customer-maintenance';
    getFormSchema({
      context,
      formId: tab
    });
    if (tab !== 'DiaryDetails' && tab !== 'SalesDiary'
      && !isMainCustomerTab(tab)) {
      changeOperationMode(Operation.BROWSE);
    }
    const notCustomer = tab === 'DeliveryDetails' || tab === 'Contacts';
    toggleBrowseLookUp(notCustomer);
    changeSelectedForm('customer-maintenance', tab);
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption } = this.props;
    toggleMenuOption(true);
  }

  isTabIdValid = (tabId: string) => {
    const tabs = TABS_DATA.filter((item) => (item.id === tabId));
    if (tabs.length > 0) {
      return tabId;
    }

    return false;
  }

  isDiaryComponent = (selectedTab: string): boolean => {

    return selectedTab === 'SalesDiary'
      || selectedTab === 'DiaryDetails';
  }

  componentDidUpdate(prevProps: Readonly<ICustomerMaintenanceViewProperties>): void {
    const { selectedForm, selectedTab, operationMode, changeSelectedForm } = this.props;
    if (!selectedForm) {
      changeSelectedForm('customer-maintenance', selectedTab);
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.NEW && isMainCustomerTab(selectedTab)) {
      this.handleModuleChange('CustomerDetails');
    }
  }
  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  getFormName = () => {
    const { selectedTab } = this.props;
    if (selectedTab === 'Contacts') {
      return 'CustomerContacts';
    }
    if (selectedTab === 'DeliveryDetails') {
      return 'CustomerAddresses';
    }
    if (selectedTab === 'SalesDiary') {
      return 'SalesDiary';
    }
    if (selectedTab === 'DiaryDetails') {
      return 'DiaryDetails';
    }

    return 'CustomerMaintenance';
  }

  render(): React.ReactNode {
    const {
      selectedTab, operationMode, isMenuOptionOpen,
      isCustomerLookUpOpen } = this.props;
    const selectedValidTab = this.isTabIdValid(selectedTab);
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerMaintenanceOuter}>
        <div className={styles.customerMaintenanceContainer}>
          <CustomerSearchLookUpDrawer
            enableToggle={true}
          />
          <div
            style={inlineStyles.customerMaintenanceSection}
            className={styles.customerMaintenanceSection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Customer Maintenance'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <NavigationTabs
              selectedTab={selectedValidTab}
              onTabChange={this.handleModuleChange}
              operationMode={operationMode}
              tabsData={TABS_DATA}
              isCustomerLookUpOpen={isCustomerLookUpOpen}
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                />}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={options}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'customer-maintenance'} />
      </div>
    );
  }
}

export default CustomerMaintenanceView;
