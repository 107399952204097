import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, reset } from 'redux-form';
import DespatchDetailsDialog from './DespatchDetailsDialog';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'DespatchDetailsForm';
const mapStateToProps = (state: IApplicationState) => ({
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DespatchDetailsDialog);
