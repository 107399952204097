import * as React from 'react';
import LookupField from '@markinson/uicomponents-v2/LookupField';
import { ProductLookupProps } from './ProductLookup.properties';
import { Column, Paging } from 'devextreme-react/data-grid';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import { lookup, search } from 'api/product/productSearch';
import { DEFAULT_PAGE_SIZE } from '../DataGridDevEx/DataGrid.constants';
const WIDTH_100 = 100;
const WIDTH_200 = 200;

function ProductLookup(props: Readonly<ProductLookupProps>, ref: React.Ref<ITextFieldHandle>): JSX.Element {
    const { WarehouseEntity, CustomerId, ...rest } = props;

    const searchScreenDialogStyle = {
        width: 892,
        maxWidth: 892,
        height: 495,
        maxHeight: 495
    };

    const AvailableQuantityHeaderCell = React.useCallback(
        () => WarehouseEntity ? <span>Available<br />{WarehouseEntity}</span> : <span>Available</span>,
        [WarehouseEntity]
    );

    const AvailableQuantityOtherHeaderCell = React.useCallback(
        () => <span>Available<br />Elsewhere</span>,
        []
    );
    const AllocatedQuantityHeaderCell = React.useCallback(
        () => WarehouseEntity ? <span>Allocated<br />{WarehouseEntity}</span> : <span>Allocated</span>,
        [WarehouseEntity]
    );
    const AllocatedQuantityOtherHeaderCell = React.useCallback(
        () => <span>Allocated<br />Elsewhere</span>,
        []
    );

    const onLookup = React.useCallback(
        (searchText, exactMatch) => lookup(searchText || '', CustomerId, WarehouseEntity, exactMatch),
        [CustomerId, WarehouseEntity]
    );
    const onSearch = React.useCallback(
        (searchText) => search(searchText || '', CustomerId, WarehouseEntity),
        [CustomerId, WarehouseEntity]
    );

    return (
        <LookupField
            innerRef={ref}
            searchScreenDialogStyle={searchScreenDialogStyle}
            valueField={'ProductId'}
            displayField={'ProductCode'}
            label={'Product'}
            descriptionField={'Description'}
            placeholder={'Product'}
            onLookup={onLookup}
            onSearch={onSearch}
            {...rest}
        >
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
            <Column dataField='ProductCode' caption='Product' allowResizing={true} width={WIDTH_100} minWidth={WIDTH_100} />
            <Column dataField='ProductGroup' caption='Group' allowResizing={true} width={WIDTH_100} minWidth={WIDTH_100} />
            <Column dataField='Description' caption='Description' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
            <Column dataField='AvailableQuantity' headerCellRender={AvailableQuantityHeaderCell} minWidth={WIDTH_100} width={WIDTH_100} />
            <Column dataField='AvailableQuantityOther' headerCellRender={AvailableQuantityOtherHeaderCell} allowResizing={true} minWidth={WIDTH_100} width={WIDTH_100} />
            <Column dataField='AllocatedQuantity' headerCellRender={AllocatedQuantityHeaderCell} allowResizing={true} minWidth={WIDTH_100} width={WIDTH_100} />
            <Column dataField='AllocatedQuantityOther' headerCellRender={AllocatedQuantityOtherHeaderCell} allowResizing={true} minWidth={WIDTH_100} width={WIDTH_100} />
            <Column dataField='Reference' caption='Reference' allowResizing={true} width={WIDTH_100} />
        </LookupField >);
}

export default React.forwardRef(ProductLookup);
