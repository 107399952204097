import { takeLatest, call, Effect, put, select } from 'redux-saga/effects';

import { types as ordersTypes, actions as ordersActions } from 'ducks/cashReceipting/orders';
import { actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { actions as allocateActions } from 'ducks/cashReceipting/allocate';
import { callApi } from '../utils';
import * as api from 'api/cashReceipting/orders';
import { IDataAction } from 'ducks/utils';
import { selectors as filterSelectors, actions as filterActions } from 'ducks/common/filters';

function* fetchAllocatedOrders(action: any): IterableIterator<Effect> {
    const { success, failure } = ordersActions.saga.fetchAllocatedOrders;

    const filters = action.data.filters;
    yield put(filterActions.applyFilters({ filters, formName: 'CashReceiptingOrdersFilters' }));

    yield callApi(
        call(api.fetchAllocatedOrders, filters),
        success,
        failure
    );
}

function* getSpecificOrderAllocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = ordersActions.saga.getSpecificOrderAllocation;

    yield put(ordersActions.changeModalVisibility({ openOrderAllocationModal: true }));

    yield callApi(
        call(api.getSpecificOrderAllocation, action.data),
        success,
        failure
    );
}

function* applySpecificOrderAllocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = ordersActions.saga.applySpecificOrderAllocation;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        const { success: cashReceiptSuccess } = cashReceiptActions.saga.applySpecificOrderAllocation;
        const { success: allocateSuccess } = allocateActions.saga.applySpecificOrderAllocation;
        yield put(cashReceiptSuccess(response));
        yield put(allocateSuccess(response));

        const filters = yield select(filterSelectors.getFilters, 'CashReceiptingOrdersFilters');
        yield call(fetchAllocatedOrders, { data: { filters } });

        yield put(ordersActions.changeModalVisibility({ openOrderAllocationModal: false }));
    }
    yield callApi(
        call(api.applySpecificOrderAllocation, action.data),
        success,
        failure,
        { dialogActionCallEffect: applySpecificOrderAllocation, postApiSuccessCallEffect }
    );
}

export default function* rootSupplierInvoiceSaga(): IterableIterator<Effect> {
    yield takeLatest(ordersTypes.fetchAllocatedOrders, fetchAllocatedOrders);
    yield takeLatest(ordersTypes.getSpecificOrderAllocation, getSpecificOrderAllocation);
    yield takeLatest(ordersTypes.applySpecificOrderAllocation, applySpecificOrderAllocation);
    yield takeLatest(ordersTypes.fetchAllocatedOrders, fetchAllocatedOrders);
}
