
import { fetchPost, objectify, swapObjectValues } from '../utils';
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export const search = (query) => {
  const { filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const respType = 'Inline';

  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(
    `/PriceCheck/TrueCost`,
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    },
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    respType
  )
    .then(
      (result) => {
        if (result)
          if (result.CostComponents)
            return {
              records: result.CostComponents.map(item => objectify(swapObjectValues(item), null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            }
          else
            return result

      })
}

export const fetchDefaultTrueCostValues = (query) => {
  const { filters } = query;
  const respType = 'Inline'
  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return fetchPost('/PriceCheck/InitialiseTrueCost',
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    }, {},
    respType)
    .then((result) => {
      if (result) {
        const dataObject = result.InitialiseTrueCost;
        const objectified = objectify(dataObject);

        return objectified;
      }
    })
}