import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as invoiceDetailsSelectors } from 'ducks/supplierInvoiceEnquiry/invoiceDetails';
import { selectors as invoiceSelectors } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';

const mapStateToProps = (state) => ({
  selectedInvoice: invoiceSelectors.selected(state),
  invoiceSummary: invoiceDetailsSelectors.summary(state),
  isLoading: invoiceSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
