import * as React from 'react';
import { IChangeDeliveryDialogProps } from './ChangeDeliveryDialog.properties';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import ChangeDeliveryForm from './ChangeDeliveryDialog.form';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useChangeDelivery, useSetSummaryQueryData } from 'api/Worksale/worksale';

const ChangeDeliveryDialog = (props: IChangeDeliveryDialogProps): JSX.Element => {
  const { open, onClose, values, initialValues, valuesSchema, changeFormFieldValue, dataSource, SelectAndFocusFirstRow } = props;
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;

  const { mutateAsync: updateDefaultWSDeliveryMutateAsync } = useChangeDelivery();
  const setSummaryQueryData = useSetSummaryQueryData();
  const defaultDelivery = React.useMemo(() => (dataSource?.items().find((item) => item.inlineObject.Primary === true)), [dataSource]);

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        listener: async () => {
          const response = await updateDefaultWSDeliveryMutateAsync({
            WorksaleId: worksaleId,
            urlQueryParams: {
              CurrentSiteCode: values.SiteCode,
              NewSiteCode: values.NewSiteCode,
              DefaultDelivery: values.Primary
            }
          });

          if (response?.Status) {
            dataSource.reload().then(() => {
              SelectAndFocusFirstRow();
              setSummaryQueryData({
                Status: true,
                WorksaleSummary: response.WorksaleSummary
              });
            });
            onClose();
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: () => {
          onClose();
        }
      }
    ];
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title='Change Delivery'
    formProps={{
      style: {
        height: '290px',
        minWidth: '450px'
      },
      formSchema: ChangeDeliveryForm,
      initialValues: initialValues,
      valuesSchema: valuesSchema,
      customerId: 1,
      worksaleId: worksaleId,
      fieldExtensions: (
        {
          NewSiteCode: {
            onChange: (_, value) => {
              const siteCode = values?.SiteCode?.toLowerCase();
              const newSiteCode = value?.toLowerCase();
              const defaultSiteCode = defaultDelivery?.inlineObject?.SiteCode?.toLowerCase();

              if (newSiteCode === defaultSiteCode || siteCode === defaultSiteCode) {
                changeFormFieldValue('Primary', true);
              } else {
                changeFormFieldValue('Primary', false);
              }
            }
          },
          Primary: {
            disabled: values?.NewSiteCode?.toLowerCase() === defaultDelivery?.inlineObject?.SiteCode?.toLowerCase()
          }
        }
      )
    }}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}

  />);
};

export default ChangeDeliveryDialog;
