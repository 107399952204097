import { IJobtemplateSearchResponse, IJobtemplateSearchFacade, IJobtemplateDetailFacade } from './../swaggerTypes';
import { IObjectified, } from '../utils';
import { IJobtemplateDetailsResponse, ISortOrder, ISearchRequest } from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';
import { ILookupResponse } from 'api/customType';
export interface IObjectifiedJobTemplateSearchResponse {
    records: IObjectified<IJobtemplateSearchFacade>[];
    currPage: number; nextPage: boolean; prevPage: boolean;
}

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<IObjectifiedJobTemplateSearchResponse> {
    const { SearchText, Sort, BatchPage } = query;

    return Api.postInlineCollectionResponse<ISearchRequest, IJobtemplateSearchResponse, 'Jobtemplates'>(
        'JobTemplateMaintenance/SearchJobTemplates', { SearchText, Sort, BatchPage })
        .then((response) => {
            const records = asObjectifiedArray(response, 'Jobtemplates');
            const batchInformation = (response as unknown as IJobtemplateSearchResponse).BatchInformation;

            return {
                records,
                currPage: batchInformation ? batchInformation.BatchPage : 1,
                nextPage: batchInformation && !batchInformation.EndOfData,
                prevPage: batchInformation && batchInformation.BatchPage > 1
            };
        });
}

export async function searchById(query: { TemplateCode: string }): Promise<IObjectifiedJobTemplateSearchResponse> {

    const { TemplateCode } = query;

    return Api.getInlineResponse<IJobtemplateDetailsResponse, 'JobTemplateDetails'>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}`)
        .then((response) => {
            const records = isNull(response.Forms) ? [asObjectified(response, 'JobTemplateDetails')] : [];

            return {
                Forms: response.Forms,
                records: records,
                currPage: 1,
                nextPage: false,
                prevPage: false
            };
        });
}

export async function create(query: IJobtemplateDetailFacade): Promise<{ JobTemplateDetails: IObjectified<IJobtemplateSearchFacade> }> {

    return Api.postInlineResponse<IJobtemplateDetailFacade, IJobtemplateDetailsResponse, 'JobTemplateDetails'>(
        'JobTemplateMaintenance/JobTemplate/Create', query)
        .then((response) => {

            return {
                ...response,
                JobTemplateDetails: asObjectified(response, 'JobTemplateDetails')
            };
        });
}

export async function update(query: IJobtemplateDetailFacade): Promise<{ JobTemplateDetails: IObjectified<IJobtemplateSearchFacade> }> {
    query.Charge = isNull(query.Charge) ? 0 : query.Charge;
    const { TemplateCode } = query;

    return Api.putInlineResponse<IJobtemplateDetailFacade, IJobtemplateDetailsResponse, 'JobTemplateDetails'>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}`, query)
        .then((response) => {

            return {
                ...response,
                JobTemplateDetails: asObjectified(response, 'JobTemplateDetails')
            };
        });
}

export async function deleteJobTemplate(TemplateCode: string): Promise<IJobtemplateDetailsResponse> {

    return Api.delete<IJobtemplateDetailsResponse>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}`)
        .then((response) => response);
}
export const fetchJobTemplateOptions = async (query: { SearchText: string }): Promise<ILookupResponse> => {
    return Api.post<{ SearchText: string }, ILookupResponse>('CustomTypes/Lookup/TemplateCode/Search', query);
};
