import * as React from 'react';
import FormView from '../../FormView';
import * as SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from '../../common/SummaryTable';
import { ICustomerFinancialProps } from '../interfaces';

class CustomerDetail extends React.PureComponent<ICustomerFinancialProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-enquiry/financial/balances':
          onInitialLoad('Balances');
          break;
        case '/customer-enquiry/financial/child-accounts':
          onInitialLoad('CustomerChildAccounts');
          break;
        case '/customer-enquiry/financial/transactions':
          onInitialLoad('Transactions');
          break;
        case '/customer-enquiry/financial/transactions/transaction-details':
          onInitialLoad('TransactionDetails');
          break;
        case '/customer-enquiry/financial/transactions/transaction-details/transactions':
          onInitialLoad('DetailsTransactions');
          break;
        case '/customer-enquiry/financial/credit-diary':
          onInitialLoad('CreditDiary');
          break;
        case '/customer-enquiry/financial/credit-diary/diary-details':
          onInitialLoad('CreditDiaryDetails');
          break;
        default:
          onInitialLoad('Balances');
      }
    }
  }

  getSummaryTableRenderer = (selectedTab: string) => {
    switch (selectedTab) {
      case 'TransactionDetails':
      case 'DetailsTransactions':
        return this.getTransactionSummary;
      default: return null;
    }
  }

  getTransactionSummary = () => {
    const { transactionSummary, transactionDetails, loadingTransactionSummary } = this.props;

    if (!transactionSummary || !transactionDetails) {
      return <SummaryTable loadingTransactionSummary={loadingTransactionSummary} selectedTransaction={{}} data={SummaryTableSchema.Transactions} />;
    }

    return <SummaryTable
      loadingTransactionSummary={loadingTransactionSummary}
      selectedTransaction={{
        TransactionNumber: transactionSummary.TransactionId && transactionSummary.TransactionId.Display,
        TransactionDate: transactionSummary.TransactionDate && transactionSummary.TransactionDate.Value,
        FinancialPeriod: transactionSummary.FinancialPeriod && transactionSummary.FinancialPeriod.Value,
        TransactionStatus: transactionSummary.TransactionStatus && transactionSummary.TransactionStatus.Value,
        TransactionType: transactionSummary.TransactionType && transactionSummary.TransactionType.Value,
        SourceDocument: transactionSummary.SourceDocument && transactionSummary.SourceDocument.Value,
        Currency: transactionSummary.Currency && transactionSummary.Currency.Value,
        OriginalTotal: transactionSummary.OriginalTotal && transactionSummary.OriginalTotal.Value,
        OriginalAmount: transactionSummary.OriginalAmount && transactionSummary.OriginalAmount.Value,
        SalesEntity: transactionDetails.SalesEntity,
        AllocatedInvoice: transactionDetails.AllocatedInvoice
      }}
      data={
        SummaryTableSchema.Transactions
      } />;
  }

  getInitialValues = (): any => {
    const {
      selectedCustomer, selectedTab, balanceDetails, transactionDetails, loadingTransactionSummary
    } = this.props;
    switch (selectedTab) {
      case 'Balances':
        return balanceDetails ? { initialValues: balanceDetails.inlineObject, valuesSchema: balanceDetails.schema } : null;
      case 'TransactionDetails':
        return transactionDetails && !loadingTransactionSummary && { initialValues: transactionDetails, valuesSchema: transactionDetails.schema };
      case 'CreditDiary':
      case 'CreditDiaryDetails':
        return { initialValues: { params: { CustomerId: selectedCustomer && selectedCustomer.CustomerId }, doubleClickActionTab: 'CreditDiaryDetails' }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={(selectedTab === 'CreditDiary' && 'Diary')
            || (selectedTab === 'CreditDiaryDetails' && 'DiaryDetails')
            || formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default CustomerDetail;
