import { IFormViewForm } from 'components/FormView';

const TemplateDetailsForm: IFormViewForm = {
  id: 'TemplateDetailsForm',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Template name',
            name: 'Name',
            size: 'large',
            required: true,
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            size: 'large',
            required: true,
          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Responsible user',
            size: 'medium',
            name: 'ResponsibleUser',
            required: true,
            lookupName: 'Personnel',
          }
        },
        {
          id: 3,
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Active template',
            size: 'small',
            name: 'Active',
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Comment',
            name: 'Comment',
            placeholder: '',
            size: 'large',
            style: {
              height: '250px'
            }
          }
        }
      ]
    },
  ]
};

export default TemplateDetailsForm;
