import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';

import { createStyles, withStyles } from '@material-ui/core/styles';
import { dragExpandIcon, customerMaintenanceDrawerBackground } from '../../themes/colors';

import * as styles from './CashReceiptingLookupPanel.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { actions as cashReceiptActions, selectors as cashReceiptSelectors } from 'ducks/cashReceipting/cashReceipt';
import { CircularProgressSize } from '../../utils/constants';
import { ICashReceiptingLookupPanelProperties, ICashReceiptingLookupPanelState } from './interfaces';
import { isShallowEqual, pathOr } from 'utils/utils';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import LookupPanel from 'components/common/LookupPanel';

const inlineStyles = createStyles({
  openedDragController: {
    left: '300px',
    width: '10px',
    backgroundColor: '#F47920',
    cursor: 'pointer'
  },
  dragIcon: {
    color: dragExpandIcon,
    width: '20px',
    height: '20px',
    marginLeft: '-5px',
    cursor: 'pointer'
  },
  closedDragIcon: {
    color: dragExpandIcon,
    width: '30px',
    height: '30px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
  lookupContainerStyle: {
    top: '0',
    backgroundColor: customerMaintenanceDrawerBackground,
    height: '100%',
    boxShadow: 'none',
    width: 300,
    position: 'relative',
  },
  recallLookupContainerStyle: {
    width: 'calc(100% - 20px)',
    padding: '10px 10px 0',
    boxSizing: 'border-box',
    margin: '10px',
  },
  lookupFieldsPaperContainer: {
    width: 'calc(100% - 20px)',
    padding: '10px 10px 0',
    boxSizing: 'border-box',
    margin: '10px',
  },
  LookupFields: {
    width: '100%',
    minWidth: '200px',
    maxWidth: '100%'
  },
  BasicLookupFields: {
    '& > div': {
      width: '100%',
      minWidth: '200px',
      maxWidth: '100%'
    }
  },
  expansionListContainer: {
    marginBottom: '10px',
    height: 'calc(100% - 185px)'
  },
  commitActionPaperContainer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '60px',
    width: '100%',
    backgroundColor: '#ebebeb'
  },
  commitActionButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    marginLeft: 'auto'
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  panelHeading: {
    color: '#000000',
    fontSize: '15px',
    paddingBottom: '10px',
  }
});

class CashReceiptingLookupPanelComponent extends React.Component<ICashReceiptingLookupPanelProperties, ICashReceiptingLookupPanelState> {

  constructor(props: Readonly<ICashReceiptingLookupPanelProperties>) {
    super(props);
    this.state = {
      loading: true,
      CashReceiptContext: {
        CustomerId: null,
        Date: null,
        Period: null,
        SalesEntity: null,
      }
    };
  }

  componentDidMount(): void {
    this.props.fetchCashReceiptContext();
  }

  componentWillReceiveProps(nextProps: Readonly<ICashReceiptingLookupPanelProperties>): void {
    if (!isShallowEqual(this.state.CashReceiptContext, nextProps.CashReceiptContext)) {
      const { CashReceiptContext } = nextProps;
      this.setState({ CashReceiptContext: CashReceiptContext });
    }
    if (this.state.loading !== nextProps.loading) {
      const { loading } = nextProps;
      this.setState({ loading: loading });
    }
  }

  onLookupApply = () => {
    const { onApply } = this.props;
    const { CashReceiptContext } = this.state;
    if (onApply) onApply(CashReceiptContext);
  }

  selectCustomer = (customer: any): void => {
    if (this.state.loading) {
      return;
    }
    this.props.updateCashReceiptContext({
      ...this.state.CashReceiptContext,
      CustomerId: customer.Code
    });
  }

  selectSalesEntity = (salesEntity: any): void => {
    if (this.state.loading) {
      return;
    }
    this.props.updateCashReceiptContext({
      ...this.state.CashReceiptContext,
      SalesEntity: salesEntity.Code
    });
  }

  selectPeriod = (period: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCashReceiptContext({
      ...this.state.CashReceiptContext,
      Period: period
    });
  }

  selectDate = (date: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCashReceiptContext({
      ...this.state.CashReceiptContext,
      Date: { ...this.state.CashReceiptContext.Date, Value: date }
    });
  }

  onLookupToggle = () => {
    this.props.onToggle(!this.props.open);
  }

  render(): React.ReactNode {
    const { classes, open, onToggle } = this.props;
    const CustomerId = pathOr('', ['CashReceiptContext', 'CustomerId'], this.state);
    const Date = pathOr('', ['CashReceiptContext', 'Date'], this.state);
    const Period = pathOr('', ['CashReceiptContext', 'Period'], this.state);
    const SalesEntity = pathOr('', ['CashReceiptContext', 'SalesEntity'], this.state);
    const isApplyDisabled = !(CustomerId && SalesEntity);
    const { loading } = this.state;

    return (
      <div>
        <LookupPanel open={open} setOpen={onToggle}>
          <div className={styles.lookupInner}>
            <Scrollbars>
              <Paper style={inlineStyles.lookupFieldsPaperContainer} square={true}>
                <TextField
                  label={'Period'}
                  name={'Period'}
                  value={Period || 1}
                  disabled={true}
                  style={inlineStyles.LookupFields}
                />
                <TextField
                  label={'Date'}
                  name={'Date'}
                  value={Date || ''}
                  disabled={true}
                  style={inlineStyles.LookupFields}
                />
                <BasicLookupActionField
                  className={classes.BasicLookupFields}
                  placeholder={'Entity'}
                  label={'Entity'}
                  size={'large'}
                  lookupName={'SalesEntity'}
                  value={SalesEntity}
                  onSelectedItemChange={this.selectSalesEntity}
                  suppressDescription={true}
                />
                <BasicLookupActionField
                  className={classes.BasicLookupFields}
                  placeholder={'Customer'}
                  label={'Customer'}
                  size={'large'}
                  lookupName={'Customer'}
                  value={CustomerId}
                  onSelectedItemChange={this.selectCustomer}
                  suppressDescription={true}
                />
              </Paper>
            </Scrollbars>
            <Paper style={inlineStyles.commitActionPaperContainer} square={true}>
              <Button onClick={this.onLookupApply}
                disabled={isApplyDisabled}
                variant={'contained'}
                className={classes.commitActionButton}>
                <DoneAllIcon style={{ color: isApplyDisabled ? '' : '#006633' }} /> APPLY
              </Button>
            </Paper>
            {loading ?
              <div style={inlineStyles.preLoaderWrapperCustomer}>
                <CircularProgress
                  size={CircularProgressSize}
                  style={inlineStyles.preLoader}
                  color={'secondary'}
                  variant={'indeterminate'} />
              </div> : ''
            }
          </div>
        </LookupPanel>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  open: cashReceiptSelectors.isCashReceiptingLookupPanelOpen(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onToggle: cashReceiptActions.toggleCashReceiptingLookupPanel,
  },
  dispatch);

const CashReceiptingLookupPanel = withStyles(inlineStyles, { index: 1 })(CashReceiptingLookupPanelComponent);

export default connect(mapStateToProps, mapDispatchToProps)(CashReceiptingLookupPanel);
