import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
  }
}, 'prices');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Percentage', field: 'PercentageDisplay', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Type', field: 'PriceType', minWidth: 120, suppressSorting: true, },
    { headerName: 'Compare price', field: 'ComparePriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Tax altered', field: 'IsTaxAlteredDisplay', minWidth: 100, suppressSorting: true, },
    { headerName: 'Schedule', field: 'PriceSchedule', minWidth: 120, suppressSorting: true, },
    { headerName: 'Override schedule', field: 'IsOverrideScheduleDisplay', minWidth: 120, suppressSorting: true, },
    { headerName: 'Fixed contract', field: 'IsFixedContractDisplay', minWidth: 120, suppressSorting: true, },
    { headerName: 'Schedule description', field: 'PriceScheduleLabel', minWidth: 250, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
  
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.priceCheck.prices,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.priceCheck.prices.loading,
  gridOptions: state => state.priceCheck.prices.data.gridOptions,
  columns: state => state.priceCheck.prices.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)