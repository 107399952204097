import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { actions as formActions } from 'ducks/form'
import { selectors as lineSelectors, actions as lineActions } from 'ducks/customerEnquiry/transactions'
import { selectors as customerSelector } from 'ducks/customer'
import { search } from 'api/customerEnquiry/transactions'
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search)

export const mapStateToProps = (state) => ({
  gridName: 'CETransactions',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), // to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  filterRow: lineSelectors.filterRow(state),
  selectedFilters: lineSelectors.selectedFilters(state),
  appliedFilters: lineSelectors.filterRow(state) && filterSelectors.getFilters(state, lineSelectors.filterRow(state).formName),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  keyFields: [{ valueField: 'TransactionId', routeField: 'TransactionId' }],
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected,
  applyFilters: filterActions.applyFilters
}
