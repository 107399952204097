import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify, fetchGet } from './../utils'

export const search = (query) => {
  const { RFCNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchPost(`ReturnForCreditEnquiry/RFC/${RFCNumber}/Lines/Retrieve`, null, { BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}` })
    .then((result) => result && result.RFCEnquiryLines &&
      ({
        records: result.RFCEnquiryLines.map(item => objectify(item, null, 'Inline')),
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      })
    )
}

export const fetchDetails = (query) => {
  const { RFC, LineNumber } = query
  return fetchGet(`ReturnForCreditEnquiry/RFC/${RFC}/Line/${LineNumber}/ProductDetails`, {}, 'Inline')
    .then((result) => result && objectify(result.RFCEnquiryProductDetail, null, 'Inline'))
}
