import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reset } from 'redux-form';
import PriceOverrideAuthorityDialog from './PriceOverrideAuthorityDialog';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'PriceOverrideDialogForm';

const mapStateToProps = (state: IApplicationState) => ({
  values: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceOverrideAuthorityDialog);
