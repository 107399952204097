import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify, fetchGet } from '../utils';
import moment from 'moment';

export const fetch = async (query: { SupplierProductId: string; filters: any; Page: number; Sort: string; SortDirection: string; BatchSize?: number }) => {
    const { SupplierProductId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
    const From = (filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const respType = 'Inline';

    return fetchPost(
        `/SupplierProductEnquiry/SupplierProduct/${SupplierProductId}/RetrieveProductPriceSchedule`,
        filters
        && {
            ...filters,
            FromDate: From,
            ToDate: To,
        },
        { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize }, respType
    )
        .then(
            (result) => {
                if (result) {
                    if (result.PordProceScheds) {
                        return {
                            records: result.PordProceScheds.map((item) => objectify(item, null, 'Inline')),
                            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
                            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
                        };
                    } else {
                        return result;
                    }
                }

            });
};

export const fetchDetails = (query) => {
    const { Supplier, LoadNumber, SupplierProduct } = query;
    const apiCall = fetchGet(`SupplierProductEnquiry/Supplier/${Supplier}/LoadNumber/${LoadNumber}/SupplierProduct/${SupplierProduct}/Details`, {}, 'Inline')
        .then((result) => ({
            values: objectify(result.PordProceSchedDetails),
            schema: result.PordProceSchedDetails
        }));

    return apiCall;
};

export const fetchSettings = (query) => {
    const { Supplier, LoadNumber, SupplierProduct } = query;
    const apiCall = fetchGet(`SupplierProductEnquiry/Supplier/${Supplier}/LoadNumber/${LoadNumber}/SupplierProduct/${SupplierProduct}/Settings`, {}, 'Inline')
        .then((result) => ({
            values: objectify(result.PPSSetting),
            schema: result.PPSSetting
        }));

    return apiCall;
};

export const fetchComments = (query) => {
    const { Supplier, LoadNumber, SupplierProduct } = query;
    const apiCall = fetchGet(`SupplierProductEnquiry/Supplier/${Supplier}/LoadNumber/${LoadNumber}/SupplierProduct/${SupplierProduct}/Comment`, {}, 'Inline')
        .then((result) => result);

    return apiCall;
};

export const fetchSellPrices = (query) => {
    const { Supplier, LoadNumber, SupplierProduct } = query;
    const apiCall = fetchGet(`SupplierProductEnquiry/Supplier/${Supplier}/LoadNumber/${LoadNumber}/SupplierProduct/${SupplierProduct}/SellPrices`, {}, 'Inline')
        .then((result) => ({
            values: objectify(result.PordProceSchedSellPrice),
            schema: result.PordProceSchedSellPrice
        }));

    return apiCall;
};
