import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as customerActions, selectors as customerSelectors } from 'ducks/customer';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface ICustomerItem {
  CustomerId: number;
  CustomerAccountNumber: string;
  Name: string;
  Abbreviation: string;
  City: string;
}

const CustomerSearchLookUpDrawer = buildSearchLookUpDrawer<ICustomerItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Customers',
    searchPlaceholder: 'Search for customers...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'CustomerAccountNumber',
        label: 'Customer account number'
      },
      {
        filter: 'Name',
        label: 'Customer name'
      },
      {
        filter: 'Abbreviation',
        label: 'Abbreviation'
      },
      {
        filter: 'City',
        label: 'City'
      }
    ],
    records: customerSelectors.all(state) || [],
    selectedRecord: customerSelectors.selected(state),
    selectedSortFilter: 'CustomerAccountNumber',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: customerSelectors.isLoading(state),
    nextPage: customerSelectors.nextPage(state),
    prevPage: customerSelectors.prevPage(state),
    pageSize: customerSelectors.pageSize(state),
    totalPages: customerSelectors.totalPages(state),
    loadingPrevPage: customerSelectors.loadingPrevPage(state),
    loadingNextPage: customerSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'CustomerId',
    fieldsToDisplay: ['CustomerAccountNumber', 'Name', 'Abbreviation', 'City'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('CustomerId', selectedRecord.CustomerId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const customerId = parseInt(matchParams.get('CustomerId'));
      if (customerId) {
        return {
          id: customerId,
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: customerActions.search,
  searchById: customerActions.searchById,
  changeSelectedRecord: customerActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: customerActions.fetchNextPage,
  fetchPrevPage: customerActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchLookUpDrawer);
