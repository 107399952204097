import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import CatalogueProductDetails from './CatalogueProductDetails';
import { IApplicationState } from 'ducks/reducers';
import ICatalogueProductDetailsProperties, { ICatalogueProductDetailsHandle } from './CatalogueProductDetails.properties';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as configSelectors } from 'ducks/appConfig';

const FORM_NAME = 'CatalogueProductDetailsForm';

const mapStateToProps = (state: IApplicationState) => ({
    isV2: configSelectors.isV2(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME)
    },
    dispatch);

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: Readonly<ICatalogueProductDetailsProperties>) => {
    return (<CatalogueProductDetails
        {...props} />);
});

export default React.forwardRef(
    (props: Readonly<ICatalogueProductDetailsProperties>, ref: React.Ref<ICatalogueProductDetailsHandle>) => {

        return <ConnectedComponent {...props} forwardedRef={ref} />;
    });
