import { IFormViewForm } from 'components/FormView';

const DespatchDetailsDialog: IFormViewForm = {
    id: 'DespatchDetailsForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: 1,
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 0,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Freight code',
                        name: 'FreightCode',
                        size: 'small',
                        lookupName: 'FreightCode',
                        hideInV2: true,
                        suppressDescription: true,
                    }
                },
                {
                    id: 1,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Send via',
                        name: 'SendVia',
                        size: 'small',
                        lookupName: 'FreightType',
                        suppressValidation: true,
                        suppressDescription: true,
                    }
                },
                {
                    id: 2,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Carrier',
                        name: 'Carrier',
                        suppressValidation: true,
                        suppressDescription: true,
                        size: 'large',
                        lookupName: 'CarrierCode',
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Freight account',
                        name: 'FreightAccount',
                        size: 'medium',
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Shipping',
                        name: 'Shipping',
                        placeholder: '',
                        size: 'large',
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Consignment note',
                        name: 'ConsignmentNote',
                        placeholder: '',
                        size: 'large',
                    }
                },
                {
                    id: 6,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Cartons',
                        placeholder: '0',
                        alignment: 'left',
                        type: 'number',
                        name: 'Cartons',
                        size: 'small',
                    }
                },
                {
                    id: 7,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Pallets',
                        name: 'Pallets',
                        type: 'number',
                        alignment: 'left',
                        hideInV2: true,
                        placeholder: '0',
                        size: 'small',
                    }
                },
                {
                    id: 7,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Weight',
                        name: 'Weight',
                        placeholder: '0.0000',
                        size: 'medium',
                        decimalSeparator: true,
                        type: 'number',
                        decimalPlaces: 4
                    }
                },
                {
                    id: 8,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Cubic',
                        name: 'Cubic',
                        placeholder: '0.0000',
                        size: 'medium',
                        decimalSeparator: true,
                        type: 'number',
                        hideInV2: true,
                        decimalPlaces: 4
                    }
                },
            ]

        }
    ]
};

export default DespatchDetailsDialog;
