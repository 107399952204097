import { combineReducers } from 'redux';
import salesOrders from './salesOrders';
import serviceOrders from './serviceOrders';
import lostSales from './lostSales';
import returnAuthorities from './returnAuthorities';
import quotes, { IQuotesState } from './quotes';

export interface IInventorySalesState {
  quotes: IQuotesState;
  [name: string]: any;
}

const SalesReducer = combineReducers<IInventorySalesState>({
  salesOrders,
  lostSales,
  returnAuthorities,
  quotes,
  serviceOrders
});

export default SalesReducer;
