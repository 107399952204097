import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'
//import TrueCostCalculator from 'components/PriceCheck/TrueCostCalculator';

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
    fetchDefaultTrueCostValues: (filters) => ({ filters })
  }
}, 'trueCost');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    disableExcelExport: true
  },
  columns: [
    { headerName: 'Costing component', field: 'Description', suppressSorting: true, },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Extended', field: 'ExtendedDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
  ],
  Data: null,
  InitialData: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    case types.fetchDefaultTrueCostValues:
      return asyncOnRequest(state, action)
    case types.saga.fetchDefaultTrueCostValues.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          InitialData: linesData
        }
      })
    case types.saga.fetchDefaultTrueCostValues.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.priceCheck.trueCost,
  {
    data: data => data.Data && data.Data.CostComponents,
    summary: data => data.Data,
    initialData: data => data.InitialData
  }
)

const syncSelector = {
  isLoading: state => state.priceCheck.trueCost.fetchTrueCostDetails_loading,
  gridOptions: state => state.priceCheck.trueCost.data.gridOptions,
  columns: state => state.priceCheck.trueCost.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)