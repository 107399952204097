import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, IDataAction
} from '../utils';
import { pathOr } from 'utils/utils';

export interface IPOSAllocateData {
  allocatedData: any;
  depositCustomer: any;
}

export interface IPOSAllocateState extends IExtendedState<IPOSAllocateData> {
  allocateCashReceipt_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    asyncs: {
      allocateCashReceipt: (data) => (data),
      getAllocatedCashReceipt: () => null,
      updateAllocatedCashReceipt: (data) => (data),
      applyBulkSelectionCriteria: (data) => (data),
      applySpecificInvoiceAllocation: (data) => (data),
      applySpecificOrderAllocation: (data) => (data),
      deleteSpecificInvoiceAllocation: (data) => (data),
      confirmCashReceiptProcess: (data) => (data),
    }
  },
  'allocate');

const initialState = asyncInitialState<IPOSAllocateData>({
  allocatedData: null,
  depositCustomer: null,
});

export default (state: IPOSAllocateState = initialState, action: IDataAction): IPOSAllocateState => {
  switch (action.type) {

    case types.allocateCashReceipt:
    case types.getAllocatedCashReceipt:
    case types.updateAllocatedCashReceipt:
    case types.confirmCashReceiptProcess:
      return asyncOnRequest(state, action);

    case types.saga.allocateCashReceipt.success:
    case types.saga.getAllocatedCashReceipt.success:
    case types.saga.updateAllocatedCashReceipt.success:
    case types.saga.applyBulkSelectionCriteria.success:
    case types.saga.applySpecificInvoiceAllocation.success:
    case types.saga.applySpecificOrderAllocation.success:
    case types.saga.confirmCashReceiptProcess.success:
    case types.saga.deleteSpecificInvoiceAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IPOSAllocateData, successAction: SuccessAction) => {
          return {
            ...data,
            depositCustomer: pathOr({}, ['payload', 'DepositCustomer', 'Value'], successAction),
            allocatedData: pathOr({}, ['payload', 'Allocation'], successAction),
          };
        });
    case types.saga.allocateCashReceipt.failure:
    case types.saga.getAllocatedCashReceipt.failure:
    case types.saga.updateAllocatedCashReceipt.failure:
    case types.saga.confirmCashReceiptProcess.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};
export const selectors = {
  ...asyncSelectors(
    (state: { cashReceipting: { allocate: IPOSAllocateState } }) => state.cashReceipting.allocate,
    {
      allocate: (data: IPOSAllocateData) => data.allocatedData
    }),
  isLoading: (state: { cashReceipting: { allocate: IPOSAllocateState } }) => state.cashReceipting.allocate.allocateCashReceipt_loading,
};
