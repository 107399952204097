import { IFormViewForm } from 'components/FormView';

const MarketingForm: IFormViewForm = {
  id: 'Marketing',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Attribute details',
        name: 'pricing'
      },
      position: {
        row: 1,
        col: 1,
        colspan: 2
      },
      children: [
        {
          id: '1',
          type: 'ACTION_FIELD',
          props: {
            label: 'Attribute set',
            name: 'AttributeSet',
            size: 'medium'
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Attribute code',
            name: 'AttributeCode',
            size: 'medium',
          }
        },
        {
          id: '4',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '200px'
            }
          }
        },
      ]
    },
  ]
};
export default MarketingForm;
