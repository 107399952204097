import * as React from 'react';
import FormView from '../../FormView/index';
import { ISettingsProps } from '../interfaces';

class Settings extends React.PureComponent<ISettingsProps> {
  componentDidMount(): void {
    const { onInitialLoad, selectedTab } = this.props;
    onInitialLoad(selectedTab);
  }

  getInitialValues = (): any => {
    const {
      selectedTab,
      selectedCustomer, selectedCustomerSchema
    } = this.props;
    switch (selectedTab) {
      case 'CustomerSetting':
      case 'SettingsFinancial':
        return { initialValues: selectedCustomer, valuesSchema: selectedCustomerSchema };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default Settings;
