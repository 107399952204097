import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IUserIdScreenDialogProperties } from './UserIdScreenDialog.properties';
import UserIdScreenDialogForm from './UserIdScreenDialog.form';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const UserIdScreenDialog = (props: IUserIdScreenDialogProperties) => {

  const { open, loading = false, data, values, resetForm, onOk, onCancel, formSyncErrors, title, message } = props;

  const getActions = () => {
    return [
      {
        title: 'Ok',
        listener: () => {
          if (onOk && isValidData()) {
            onOk(values);
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: () => {
          resetForm();
          if (onCancel) {
            onCancel();
          }
        },
      },
    ];
  };

  function isValidData(): boolean {
    if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      return false;
    }

    return true;
  }

  return (
    <div>
      <FormViewModal
        open={open}
        loading={loading}
        title={title}
        formProps={{
          formSchema: UserIdScreenDialogForm,
          initialValues: data,
          style: { height: '350px', minWidth: '400px' },
          fieldExtensions: {
            Message: { value: message }
          }
        }
        }
        actions={getActions()}
        dialogActionsButtons={true}
        dialogActionsShadow={false}
      />
    </div>
  );
};

export default UserIdScreenDialog;
