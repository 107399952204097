import SummaryTable from 'components/common/SummaryTable';
import FormView from 'components/FormView';
import React from 'react';
import SummaryTableSchema from './KitComponentDetails.summaryTable.schema';
import ComponentDetailFormSchema from './KitComponentDetails.form';
import IKitComponentDetailsProperties from './KitComponentDetails.properties';
import { IBinLot, IBinLotFacade, IComponentDetailFacade, IComponentLineDetailFacade, IUpdateComponentLineDetailFacade } from 'api/swaggerTypes';
import { Inline, isSuccess } from 'api/utils';
import { IObjectifiedComponentLineDetailResponse } from 'api/Worksale/worksale';
import WarehouseSelectionDialog from 'components/common/WarehouseSelectionDialog';
import { fetchProductSerials } from 'api/product/productSearch';
import SerialDialog from 'components/common/SerialDialog';
import LotDialog from 'components/common/LotDialog';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import PartialSerialDialog from 'components/common/PartialSerialDialog';
import BinLocationsDialog from 'components/common/BinLocationsDialog';

const KitComponentDetails = (props: IKitComponentDetailsProperties) => {

  const {
    values, worksaleId, worksaleLineId, LineNumber, componentId: componentIdProp, forwardedRef,
    formSyncErrors, disableSupplied, fetchComponentDetail, calculateComponentLineDetail, resetForm,
    onUpdateComponentDetail, navigateBack, onUpdateBinLots
  } = props;

  const [componentLineDetailsSchema, setComponentLineDetailsSchema] = React.useState<Inline<IComponentLineDetailFacade>>();
  const [initialValues, setInitialValues] = React.useState<IComponentLineDetailFacade['ComponentDetail']>();
  const [summaryTableData, setSummaryTableData] = React.useState<Omit<IComponentLineDetailFacade, 'ComponentDetail'>>({});

  const [lotDialogOpen, setLotDialogOpen] = React.useState<boolean>(false);
  const [partialSerialDialogOpen, setPartialSerialDialogOpen] = React.useState<boolean>(false);
  const [fullSerialDialogOpen, setFullSerialDialogOpen] = React.useState<boolean>(false);
  const [warehouseSelectionOpen, setWarehouseSelectionOpen] = React.useState(false);
  const [lotSerialTracked, setLotSerialTracked] = React.useState<string>('');
  const [binLots, setBinLots] = React.useState<IBinLot[]>();
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [binLocationsDialogopen, setBinLocationsDialogopen] = React.useState(false);
  const [isBinTracked, setIsBinTracked] = React.useState(false);

  const params = new URLSearchParams(location.search);
  const componentIdParam = Number(params.get('ComponentId') ?? '');
  const componentId = componentIdParam || componentIdProp;

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      onOk: handleOnOk,
      async updateComponentDetail(ComponentId: number, line: IUpdateComponentLineDetailFacade): Promise<void> {
        const response = await onUpdateComponentDetail(ComponentId, line);
        if (isSuccess(response)) {
          _navigateBack();
        } else {
          resetForm();
        }
      },
      onCancel(callback?: () => void): void {
        resetForm();
        _navigateBack();
        if (callback) {
          callback();
        }
      },
      lotSerial(): void {
        switch (lotSerialTracked) {
          case 'lf':
            setLotDialogOpen(true);
            break;
          case 'sp':
            setPartialSerialDialogOpen(true);
            break;
          case 'sf':
            setFullSerialDialogOpen(true);
            break;
          case 'n':
            setBinLocationsDialogopen(true);
            break;
          default:
        }
      },
      warehouse(): void {
        setWarehouseSelectionOpen(true);
      },
      handleBreadCrumb(): void {
        if (dirty) {
          showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

          return;
        }
        _navigateBack();
      },
    }),
    [lotSerialTracked, values, binLots]
  );

  React.useEffect(
    () => {
      if (worksaleId && (worksaleLineId && componentId)) {
        fetchComponentLineDetail();
      } else if (!worksaleId && componentId) {
        fetchComponentLineDetail();
      } else {
        _navigateBack();
      }
    },
    [worksaleId, worksaleLineId, componentId]
  );

  async function handleOnOk(): Promise<void> {
    if (validData()) {
      try {
        const response = await onUpdateComponentDetail(componentId, { BinLot: binLots, ComponentDetail: values });

        if (isSuccess(response)) {
          _navigateBack();
        } else {
          resetForm();
        }
      } catch (err) {
        console.warn(err);
        resetForm();
      }
    }
  }

  function _navigateBack(): void {
    if (navigateBack) {
      navigateBack();
    }
  }

  function validData(): boolean {
    if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      return false;
    }

    return true;
  }

  async function fetchComponentLineDetail(): Promise<void> {
    try {
      const response = await fetchComponentDetail(componentId);

      const { ComponentLineDetail } = response;
      const ComponentLineDetailInline = ComponentLineDetail && ComponentLineDetail.inlineObject;
      const schema = ComponentLineDetail && ComponentLineDetail.schema;
      if (ComponentLineDetailInline) {
        const ComponentSummary = (ComponentLineDetailInline && ComponentLineDetailInline.ComponentSummary) || {};
        setLotSerialTracked(ComponentSummary?.LotSerialTracked ?? '');
        setInitialValues(ComponentLineDetailInline.ComponentDetail);
        setSummaryTableData(ComponentLineDetailInline);
        setBinLots(ComponentLineDetailInline.BinLot);
        setComponentLineDetailsSchema(schema);
        setIsBinTracked(ComponentSummary?.BinTracked === 'f');
      }
    } catch (err) {
      console.warn(err);
    }
  }

  async function formFieldOnBlur(e: React.FocusEvent<HTMLInputElement>): Promise<void> {
    const targetValue = e && e.target && e.target.value;
    const fieldName = e && e.target && e.target.name;

    if (fieldName) {
      const isValueChanged = parseFloat(targetValue) !== parseFloat(initialValues[fieldName] || 0);
      if (isValueChanged) {
        setDirty(true);
        await calculateComponentLineDetailAndValidate(fieldName as keyof IComponentDetailFacade, values);
      }
    }
  }

  async function calculateComponentLineDetailAndValidate(changedField: keyof IComponentDetailFacade, line: IComponentDetailFacade): Promise<IObjectifiedComponentLineDetailResponse> {
    if (calculateComponentLineDetail) {
      const response = await calculateComponentLineDetail(componentId, changedField, line);
      const { ComponentLineDetail } = response || {};
      const ComponentLineDetailInline = ComponentLineDetail && ComponentLineDetail.inlineObject;
      const schema = ComponentLineDetail && ComponentLineDetail.schema;

      if (isSuccess(response)) {
        setInitialValues(ComponentLineDetailInline.ComponentDetail);
        setSummaryTableData({ ...ComponentLineDetailInline, KitSummary: (summaryTableData && summaryTableData.KitSummary) || {} });
        setComponentLineDetailsSchema(schema);
      } else {
        resetForm();
      }

      return response;
    }

    return null;
  }

  function closeLotSerialDialogs(): void {
    setLotDialogOpen(false);
    setPartialSerialDialogOpen(false);
    setFullSerialDialogOpen(false);
  }

  async function applyDialog(_binLots: IBinLotFacade[]): Promise<void> {
    if (validData()) {
      const response = await onUpdateBinLots(componentId, { BinLot: _binLots as IBinLot[], ComponentDetail: values });
      if (isSuccess(response)) {
        closeLotSerialDialogs();
        setBinLocationsDialogopen(false);
        setBinLots(_binLots as IBinLot[]);
      }
    }
  }

  async function handleWarehouseSelectionOk(selectedWarehouse: string): Promise<void> {
    const response = await calculateComponentLineDetailAndValidate('WarehouseEntity' as keyof IComponentDetailFacade, { ...initialValues, ...values, WarehouseEntity: selectedWarehouse });
    if (response.Status && !response.Forms) {
      setWarehouseSelectionOpen(false);
    }
  }

  const fetchProductSerialsCallback = React.useCallback(
    () => {
      return fetchProductSerials('Lookup', {
        SearchText: '',
        WorksaleId: worksaleId,
        LineNumber: LineNumber,
        WarehouseEntity: initialValues.WarehouseEntity,
        ProductId: initialValues.ProductId,
        DocumentType: worksaleId ? 'Worksale' : 'Worktable',
        DocumentId: componentId
      });

    },
    [initialValues, worksaleId, LineNumber]
  );

  return (
    <>
      <BinLocationsDialog
        open={binLocationsDialogopen}
        DocumentType={'Worktable'}
        LineNumber={LineNumber}
        WarehouseEntity={initialValues && initialValues.WarehouseEntity}
        ProductId={initialValues?.ProductId}
        RequiredQuantity={initialValues && initialValues.SuppliedQuantity}
        BinLots={((summaryTableData && summaryTableData.BinLot) || []) as IBinLotFacade[]}
        onApply={async (lots) => { applyDialog(lots).catch((err) => { console.warn(err); }); }}
        onCancel={() => { setBinLocationsDialogopen(false); }}
      />
      <LotDialog
        open={lotDialogOpen}
        isBinTracked={isBinTracked}
        WorksaleId={worksaleId}
        LineNumber={LineNumber}
        WarehouseEntity={initialValues && initialValues.WarehouseEntity}
        ProductId={initialValues && initialValues.ProductId}
        RequiredQuantity={initialValues && initialValues.SuppliedQuantity}
        BinLots={((summaryTableData && summaryTableData.BinLot) || []) as IBinLotFacade[]}
        onApply={async (lots) => { applyDialog(lots).catch((err) => { console.warn(err); }); }}
        onCancel={async () => { setLotDialogOpen(false); }}
      />
      <PartialSerialDialog
        open={partialSerialDialogOpen}
        isBinTracked={isBinTracked}
        RequiredQuantity={initialValues && initialValues.RequiredQuantity}
        BinLots={((summaryTableData && summaryTableData.BinLot) || []) as IBinLotFacade[]}
        onApply={async (lots) => { applyDialog(lots).catch((err) => { console.warn(err); }); }}
        onCancel={async () => { setPartialSerialDialogOpen(false); }}
      />
      <SerialDialog
        open={fullSerialDialogOpen}
        isBinTracked={isBinTracked}
        RequiredQuantity={initialValues && initialValues.SuppliedQuantity}
        BinLots={((summaryTableData && summaryTableData.BinLot) || []) as IBinLotFacade[]}
        onApply={async (lots) => { applyDialog(lots).catch((err) => { console.warn(err); }); }}
        onCancel={async () => { setFullSerialDialogOpen(false); }}
        fetchProductSerials={fetchProductSerialsCallback}
      />
      <WarehouseSelectionDialog
        open={warehouseSelectionOpen}
        ProductId={initialValues && initialValues.ProductId}
        onOk={handleWarehouseSelectionOk}
        onCancel={() => setWarehouseSelectionOpen(false)} />
      <FormView
        isLoading={false}
        formName={'KitComponentDetailsForm'}
        schema={ComponentDetailFormSchema}
        includeTabsHeight={false}
        initialValues={initialValues}
        valuesSchema={componentLineDetailsSchema?.ComponentDetail}
        operationMode={'EDIT'}
        onBlur={formFieldOnBlur}
        summaryTableRenderer={() => <SummaryTable loadingKitSummary={false} loadingComponentSummary={false}
          ComponentSummary={summaryTableData && summaryTableData.ComponentSummary}
          KitSummary={summaryTableData && summaryTableData.KitSummary}
          data={
            SummaryTableSchema
          }
        />}
        fieldExtensions={{
          SuppliedQuantity: {
            disabled: disableSupplied ?? (componentLineDetailsSchema?.ComponentDetail as Inline<IComponentDetailFacade>)?.SuppliedQuantity?.ReadOnly
          },
        }}
      />
    </>
  );
};

export default React.forwardRef(KitComponentDetails);
