import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
    getInvoiceLineSummary: (Invoice, SalesEntity, LineNumber) => ({ Invoice, SalesEntity, LineNumber }),
    getInvoiceLineDetails: (Invoice, SalesEntity, LineNumber) => ({ Invoice, SalesEntity, LineNumber })
  }
}, 'invoiceLines');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'InvoiceLineDetails',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100, },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Product Group', field: 'ProductGroup', minWidth: 150, suppressSorting: true },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Discount %', field: 'DiscountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Tax', field: 'TaxDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Extended', field: 'ExtendedDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Currency', field: 'Currency', minWidth: 100, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 250, suppressSorting: true, }
  ],
  lines: [],
  invoiceLineSummary: null,
  lineDetails: null,

});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
 
    case types.getInvoiceLineSummary:
    case types.getInvoiceLineDetails:
      return asyncOnRequest(state, action)

    case types.saga.getInvoiceLineDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const invoiceLineDetails = action.payload;
        return {
          ...data,
          lineDetails: invoiceLineDetails
        };
      }, { fetch: true })

    case types.saga.getInvoiceLineSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const invoiceLineSummary = action.payload;
        return {
          ...data,
          invoiceLineSummary: invoiceLineSummary
        };
      }, { fetch: true })

    case types.saga.getInvoiceLineSummary.failure:
    case types.saga.getInvoiceLineDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.invoiceLines,
  {
    lines: data => data.lines,
    selected: data => data.selected,
    invoiceLineSummary: (data) => data.invoiceLineSummary,
    details: (data) => data.lineDetails
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.invoiceLines.fetch_loading,
  loadingSummary: state => state.salesInvoiceEnquiry.invoiceLines.getInvoiceLineSummary_loading,
  gridOptions: state => state.salesInvoiceEnquiry.invoiceLines.data.gridOptions,
  lineColumns: state => state.salesInvoiceEnquiry.invoiceLines.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)