import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchLineDetails: (query) => (query),
    fetchLineSummary: (query) => (query)
  }
}, 'purchaseOrderLineDetails')

let initialState = asyncInitialState({
  lineDetails: {},
  lineDetailsSchema: {},
  lineSummary: {},
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.fetchLineDetails:
    case types.fetchLineSummary:
      return asyncOnRequest(state, action)

    case types.saga.fetchLineDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          lineDetails: action.payload.values,
          lineDetailsSchema: action.payload.schema
        }
      })
    case types.saga.fetchLineSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          lineSummary: action.payload
        }
      })
    case types.saga.fetchLineDetails.failure:
    case types.saga.fetchLineSummary.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.purchaseOrderLineDetails,
  {
    lineDetails: data => data.lineDetails,
    lineDetailsSchema: data => data.lineDetailsSchema,
    lineSummary: data => data.lineSummary,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.purchaseOrderLineDetails.loading,
  loadingSummary: state => state.purchaseOrderEnquiry.purchaseOrderLineDetails.fetchLineSummary_loading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)



