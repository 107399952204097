import { takeLatest, Effect, call, put } from 'redux-saga/effects';
import { types as activityTypes, actions as activityActions } from 'ducks/activityMaintenance/activity';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/activityMaintenance/activity';
import { IDataAction } from 'ducks/utils';
import { Operation } from 'utils/operations';
import { actions as operationActions } from 'ducks/uiOperations';
import { initialize, reset } from 'redux-form';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* postSuccessCallback(response: any, message: string): IterableIterator<Effect> {
  if (!response.Forms) {
    const ActivityCode = response.ActivityDetails && response.ActivityDetails.inlineObject.ActivityCode;
    yield put(activityActions.searchById(ActivityCode));
    showSnackBar({ variant: 'success', message });
  }
}

function* createActivity(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = activityActions.saga.create;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    yield put(operationActions.changeOperationMode(Operation.BROWSE));
    yield call(postSuccessCallback, response, 'Activity created successfully.');
  }

  function* postApiFailureCallEffect(): IterableIterator<Effect> {
    yield put(reset('ActivityDetails'));
    yield put(operationActions.changeOperationMode(Operation.NEW));
    yield put(initialize('ActivityDetails', action.data));
  }

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: createActivity, postApiFailureCallEffect, postApiSuccessCallEffect }
  );
}

function* updateActivity(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = activityActions.saga.update;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    yield put(operationActions.changeOperationMode(Operation.BROWSE));
    yield call(postSuccessCallback, response, 'Activity updated successfully.');
  }

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateActivity, postApiSuccessCallEffect }
  );
}

function* deleteActivity(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = activityActions.saga.delete;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    if (!response.Forms) {
      showSnackBar({ variant: 'success', message: 'Activity deleted successfully.' });
    }
  }

  yield callApi(
    call(api.deleteActivity, action.data),
    success,
    failure,
    { dialogActionCallEffect: deleteActivity, postApiSuccessCallEffect }
  );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = activityActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure,
    { dialogActionCallEffect: searchById }
  );
}

export default function* rootActivityMaintenanceSaga(): IterableIterator<Effect> {
  yield takeLatest(activityTypes.create, createActivity);
  yield takeLatest(activityTypes.update, updateActivity);
  yield takeLatest(activityTypes.delete, deleteActivity);
  yield takeLatest(activityTypes.search, connectSagaToApi(activityActions, 'search', api.search));
  yield takeLatest(activityTypes.fetchPrevPage, connectSagaToApi(activityActions, 'fetchPrevPage', api.search));
  yield takeLatest(activityTypes.fetchNextPage, connectSagaToApi(activityActions, 'fetchNextPage', api.search));
  yield takeLatest(activityTypes.searchById, searchById);
  yield takeLatest(activityTypes.fetchActivityData, connectSagaToApi(activityActions, 'fetchActivityData', api.searchById));
}
