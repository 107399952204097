import { IFormViewForm } from 'components/FormView';

const OrderDetails: IFormViewForm = {
  id: 'OrderDetails',
  layout: {
    rows: 3,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Warehouse',
            name: 'WarehouseEntity',
            lookupName: 'WarehouseEntity',
            placeholder: '',
            size: 'small'
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Customer P/O',
            name: 'CustomerPurchaseOrder',
            placeholder: '',
            size: 'medium',
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Ship date',
            name: 'ShipDate',
            placeholder: '',
            size: 'small',
            disabled: true
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Expiry date',
            name: 'ExpiryDate',
            placeholder: '',
            size: 'small',
            disabled: true
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Invoice instructions',
            name: 'InvoiceInstructions1',
            type: 'extended',
            size: 'large',
          }
        },
        {
          id: '5',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            name: 'InvoiceInstructions2',
            type: 'extended',
            size: 'large',
          }
        }
      ]
    },
    {
      id: '2',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sale terms',
            name: 'Terms',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Price category',
            name: 'PriceCategory',
            lookupName: 'PriceCategory',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '2',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Priority',
            name: 'PriorityLabel',
            placeholder: '',
            size: 'medium',
            disabled: true
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            lookupName: 'TaxClass',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Tax exempt reference',
            name: 'TaxExemptReference',
            placeholder: '',
            size: 'medium',
          }
        },
        {
          id: '5',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'One invoice per despatch',
            name: 'InvoiceByDespatch',
          }
        },
        {
          id: '6',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow partial delivery',
            name: 'AllowPartialDelivery',
          }
        },
        {
          id: '7',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow backorders',
            name: 'AllowBackorders',
            disabled: true
          }
        }
      ]
    },
    {
      id: '3',
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            size: 'large',
            style: {
              height: '490px'
            },
          }
        }
      ]
    },
    {
      id: '4',
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 1',
            name: 'Category1',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 2',
            name: 'Category2',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 3',
            name: 'Category3',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 4',
            name: 'Category4',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 5',
            name: 'Category5',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '5',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 6',
            name: 'Category6',
            placeholder: '',
            size: 'small',
          }
        },
      ]
    },

  ]
};
export default OrderDetails;
