import FormView from 'components/FormView';
import React from 'react';
import EntryDetailsSchema from './EntryDetails.form';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Operation } from 'utils/operations';
import { IEntryDetailsHandle, IEntryDetailsProperties } from './EntryDetails.properties';
import { ArrowBack } from '@markinson/uicomponents-v2/SvgIcons/';
import { useRetrieveCatalogueEntryDetails } from 'api/Worksale/catalogue';

const EntryDetails = (props: IEntryDetailsProperties, ref: React.Ref<IEntryDetailsHandle>) => {
  const { setActionBar } = React.useContext(ActionBarContext);

  const { catalogueId, changeSelectedTab, getMainImage, getListOfImages, loadImage } = props;

  const entryDetails = useRetrieveCatalogueEntryDetails(catalogueId);

  const entryDetailInlineObj = entryDetails.data?.CatatlogueEntryDetails?.inlineObject;

  React.useImperativeHandle(
    ref,
    () => ({
      onCancel(): void {
        navigateBack();

        return;
      }
    })
  );

  React.useEffect(
    () => {
      setActionBar({
        leftIcons: [{
          label: 'Back',
          Icon: ArrowBack,
          action: 'Back',
        }],
        centerIcons: [],
        rightIcons: []
      });
    },
    []
  );

  React.useEffect(
    () => {
      if (!catalogueId) {
        navigateBack();
      }
    },
    [catalogueId]
  );

  function navigateBack(): void {
    if (changeSelectedTab) {
      changeSelectedTab('ProductCatalogue');
    }
  }

  return (
    <>
      <FormView
        isLoading={entryDetails.isLoading}
        formName={'EntryDetailsForm'}
        schema={EntryDetailsSchema}
        includeTabsHeight={false}
        initialValues={entryDetailInlineObj}
        valuesSchema={entryDetails.data?.CatatlogueEntryDetails?.schema}
        operationMode={Operation.BROWSE}
        fieldExtensions={{
          Image: {
            dialogTitle: 'Entry Image',
            getMainImage,
            loadImage,
            mainImageObject: entryDetailInlineObj?.MainImage ?? null,
            imageCount: entryDetailInlineObj?.ImageCount ?? 0,
            getListOfImages: () => getListOfImages(catalogueId),
          }
        }}
      />
    </>
  );
};

export default React.forwardRef(EntryDetails);
