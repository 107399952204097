import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Orders from './Orders';
import { getFormValues } from 'redux-form';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as cashReceiptSelectors, actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { selectors as ordersSelectors, actions as ordersActions } from 'ducks/cashReceipting/orders';
import { selectors as allocateSelectors } from 'ducks/cashReceipting/allocate';

const FORM_NAME = 'Orders';
const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  cashReceipt: cashReceiptSelectors.cashReceipt(state),
  allocate: allocateSelectors.allocate(state),
  entity: cashReceiptSelectors.entity(state),
  openOrderAllocationModal: ordersSelectors.openOrderAllocationModal(state),
  orderAllocation: ordersSelectors.orderAllocation(state),
  orderAllocationLoading: ordersSelectors.orderAllocationLoading(state),
  formValues: getFormValues(FORM_NAME)(state),
  orderAllocationFormValues: getFormValues('OrderAllocation')(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCashReceipt: cashReceiptActions.getCashReceipt,
    updateCashReceipt: cashReceiptActions.updateCashReceipt,
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    changeModalVisibility: ordersActions.changeModalVisibility,
    applySpecificOrderAllocation: ordersActions.applySpecificOrderAllocation,
    getSpecificOrderAllocation: ordersActions.getSpecificOrderAllocation,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
