import { combineReducers } from 'redux';
import activity, { IActivityState } from './activity';
import capability, { ICapabilityState } from './capability';
import checklists, { IChecklistState } from './checklists';

export interface IActivityMaintenanceState {
  activity: IActivityState;
  capability: ICapabilityState;
  checklists: IChecklistState;
}
const combined = combineReducers({
  activity, capability, checklists
});

export default combined;
