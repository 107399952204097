import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/rfcEnquiry/RFCLines';
import { selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';
import { search } from 'api/rfc/rfcLines';

const params = new URLSearchParams();

export const mapStateToProps = state => ({
  gridName: 'RFCLines',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (rfcSelectors.selected(state) || params.get('RFCNumber')) && { RFCNumber: rfcSelectors.selected(state).RFCNumber || params.get('RFCNumber') },
  keyFields: [{ valueField: 'RFC', routeField: 'RFCNumber' }],
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
