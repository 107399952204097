import {
  IServiceJobActivityChecklistsResponse, IFormInfo, IJobActivityChecklistsDetailFacade, IServiceJobActivityChecklistResponse
} from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified, } from '../baseApi';
import { IObjectified } from './../utils.d';

export async function fetchJobsChecklists(query: { ServiceJobId?: string }):
  Promise<IObjectifiedServiceJobChecklistsResponse> {

  const { ServiceJobId } = query;

  return Api.getInlineCollectionResponse<IServiceJobActivityChecklistsResponse, 'Checklists'>(`ServiceJobMaintenance/ServiceJob/${ServiceJobId}/Checklists`)
    .then((response) => ({ ...response, Checklists: asObjectifiedArray(response, 'Checklists') }));
}

export async function updateChecklist(query: { ServiceJobId: string; ChecklistId: number; Checklist: IJobActivityChecklistsDetailFacade }):
  Promise<IObjectifiedServiceJobActivityChecklistResponse> {
  const { ServiceJobId, ChecklistId, Checklist } = query;

  return Api.putInlineResponse<IJobActivityChecklistsDetailFacade, IServiceJobActivityChecklistResponse, 'Checklist'>(
    `ServiceJobMaintenance/ServiceJob/${ServiceJobId}/Checklist/${ChecklistId}`,
    Checklist
  ).then((response) => ({ ...response, Checklist: asObjectified(response, 'Checklist') }));
}

export interface IObjectifiedServiceJobActivityChecklistResponse {
  Checklist: IObjectified<IJobActivityChecklistsDetailFacade>;
  Status?: boolean;
  Forms?: IFormInfo[];
}

export interface IObjectifiedServiceJobChecklistsResponse {
  Checklists: IObjectified<IJobActivityChecklistsDetailFacade>[];
  Status?: boolean;
  Forms?: IFormInfo[];
}
