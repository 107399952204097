import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { isNull } from 'utils/utils';
import { IQuickEntryRowProperties, IQuickEntryRowState } from '../interfaces';
import { ILookupOption } from 'api/customType';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';

const KEY_ENTER = 13;
const HTTP_OK = 200;

const styles = (theme) => ({
  lookup: {
    width: '250px',
    align: 'left',
    minWidth: '250px',
    maxWidth: '425px',
  },
  buttonsContainer: {
    minWidth: '210px',
    maxWidth: '210px',
    width: '210px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '80px',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
    }
  },
  table: {
    backgroundColor: '#cccccc',
    marginBottom: '15px',
    padding: '16px 0 0 16px',
  }
});

class QuickEntryRowComponent extends React.Component<IQuickEntryRowProperties, IQuickEntryRowState> {
  serialRef: any;
  quantityRef: any;
  lookupField: any;
  quantityField: any;
  priceField: any;
  setTextFieldRef: (element: HTMLInputElement) => void;

  constructor(props: Readonly<IQuickEntryRowProperties>) {
    super(props);
    this.lookupField = React.createRef();
    this.serialRef = React.createRef();
    this.quantityRef = React.createRef();
    this.state = {
      selectedSerial: { Code: '' }
    };
  }

  componentDidMount(): void {
    if (this.props.isFullyTracked) {
      setTimeout(() => this.lookupField && this.lookupField.inputField && this.lookupField.inputField.select(), HTTP_OK);
    } else this.serialRef.focus();
  }

  handleSave = () => {
    const { handleSave, isFullyTracked } = this.props;
    let serial = '';

    if (isFullyTracked) {
      const { selectedSerial } = this.state;
      serial = selectedSerial && selectedSerial.Code;
      if (isNull(serial)) {
        this.lookupField.inputField.select();

        return;
      }
    } else {
      serial = this.serialRef.value;
      if (isNull(serial)) {
        this.serialRef.focus();

        return;
      }
    }

    const quantity = this.quantityRef.value;
    if (isNull(quantity)) {
      this.quantityRef.focus();

      return;
    }
    if (handleSave) {
      handleSave(serial, quantity);
    }
    this.handleCancel();
  }

  handleCancel = () => {
    const { isFullyTracked } = this.props;
    if (isFullyTracked) {
      this.setState({ selectedSerial: { Code: '' } });
      this.lookupField.inputField.select();
    } else {
      this.serialRef.value = '';
      this.serialRef.focus();
    }
    this.quantityRef.value = 1;
  }

  onKeyPress = (event: any) => {
    if (event.charCode === KEY_ENTER) {
      event.preventDefault();
      this.handleSave();
    }
  }

  handleSelectedSerialChanged = (selectedSerial: ILookupOption) => {
    this.setState({ selectedSerial });
  }

  render(): React.ReactNode {
    const { classes, isFullyTracked, params } = this.props;
    const { selectedSerial } = this.state;
    const buttonEnabled = true;
    const disableLookup = isNull(params.WarehouseEntity) || isNull(params.ProductId) || false;

    return (
      <div>
        <Grid
          container
          className={classes.table}
          alignItems='flex-start'
          direction='row'
        >
          <Grid item xs className={classes.lookup}>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '10px' }}>
                {isFullyTracked ?
                  <BasicLookupActionField
                    label={''}
                    placeholder={'Starting Serial'}
                    size={'medium'}
                    lookupName={'WarehouseProductLot'}
                    value={selectedSerial.Code}
                    params={params}
                    suppressDescription={true}
                    suppressErrorMessage={true}
                    onSelectedItemChange={(item) => this.handleSelectedSerialChanged(item as any)}
                    ref={(lookup) => { this.lookupField = lookup; }}
                    readonly={disableLookup}
                  /> :
                  <TextField
                    disableHelperText
                    label={''}
                    placeholder={'Starting Serial'}
                    size={'medium'}
                    fieldRef={(ref) => { this.serialRef = ref; }}
                    onKeyPress={this.onKeyPress}
                    constraints={{
                      maxValue: 99999999
                    }}
                  />}
              </div>
              <TextField
                label={''}
                required
                disableHelperText
                placeholder={'Quantity'}
                size={'small'}
                defaultValue={1}
                type={'number'}
                fieldRef={(ref) => { this.quantityRef = ref; }}
                onKeyPress={this.onKeyPress}
              />
            </div>
          </Grid>
          <Grid item xs className={classes.buttonsContainer}>
            <Button
              onClick={this.handleSave}
              disabled={!buttonEnabled}
            >
              <CheckCircleIcon style={{ color: buttonEnabled ? 'green' : 'rgb(170,170,170)' }} />Save
            </Button>

            <Button
              onClick={this.handleCancel}
              disabled={!buttonEnabled}
            >
              <CancelIcon style={{ color: buttonEnabled ? 'red' : 'rgb(170,170,170)' }} />Cancel
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
        ></Grid>
      </div >
    );
  }
}

export default withStyles(styles, { index: 1 })(QuickEntryRowComponent);
