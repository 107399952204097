import { isNull } from 'utils/utils'
import { fetchGet, fetchPost, fetchPut, fetchDelete, createRequestObject, objectify } from './utils'

export const search = (query) => {
  const { SearchText, CustomerId, Sort } = query
  return fetchPost(`CustomerMaintenance/Customer/${CustomerId}/SearchDeliveryDetails`, { SearchText, Sort })
    .then((data) => (data && data.CustomerDeliveryDetails && data.CustomerDeliveryDetails.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val }))) || [])
}

export const searchById = ({ CustomerId, SiteCode }) => {
  return fetchGet(`/CustomerEnquiry/Customer/${CustomerId}/DeliveryDetails/${SiteCode}`, null, 'Inline')
    .then((result) => (result && result.CustomerDeliveryDetails && [{
      inlineObject: objectify(result.CustomerDeliveryDetails),
      schema: result.CustomerDeliveryDetails,
    }]) || [])
}

export const create = (data) => {
  const { CustomerId, values } = data
  return fetchPost(`CustomerMaintenance/Customer/${CustomerId}/CreateDeliveryDetails`, createRequestObject(values), { customerId: CustomerId })
    .then((something) => {
      if (isNull(something.Forms)) {
        return { ...something, inlineObject: objectify(something.CustomerDeliveryDetails), schema: something.CustomerDeliveryDetails }
      } else {
        return {
          ...something,
          inlineObject: {},
          schema: {}
        }
      }
    })
}


export const remove = (data) => {
  const { CustomerId, SiteCode } = data
  return fetchDelete(`CustomerMaintenance/Customer/${CustomerId}/DeliveryDetails/${SiteCode}`).then((response) => SiteCode)
}

export const update = (data) => {
  const { CustomerId, SiteCode, values } = data
  return fetchPut(`CustomerMaintenance/Customer/${CustomerId}/DeliveryDetails/${SiteCode}`, createRequestObject(values), 'Inline')
    .then((something) => something && Object.assign({}, { inlineObject: objectify(something.CustomerDeliveryDetails), schema: something.CustomerDeliveryDetails }))
}

export const fetchPrimary = (data) => {
  const { customerId } = data
  return fetchGet(`CustomerMaintenance/Customer/${customerId}/PrimaryDeliveryDetails`, null, 'Inline')
    .then((something) => something && objectify(something.CustomerDeliveryDetails))
}
