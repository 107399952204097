import * as React from 'react';
import { StyledComponentProps, withStyles, createStyles, Button } from '@material-ui/core';

const classStyles = createStyles({
  labelText: {
    fontFamily: 'Roboto, sans-serif',
    color: '#777777',
    display: 'block',
    fontSize: 15,
    fontWeight: 700,
  },
  containerPadding: {
    padding: '0 0 16px 0',
    display: 'flex',
    alignItems: 'center',
  },
  buttonStyles: {
    'textTransform': 'none',
    'color': 'blue',
    'textDecoration': 'underline',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: '#FFFFFF',
    }
  }
});

export interface IHyperLinkButtonProps extends StyledComponentProps {
  label?: string;
}

class HyperLinkButton extends React.Component<IHyperLinkButtonProps> {

  render(): React.ReactNode {
    const { label, classes, ...remaining } = this.props;

    return (
      <div
        className={classes.containerPadding}>
        <Button
          className={classes.buttonStyles}
          disableFocusRipple={true}
          disableRipple={true}
          {...remaining}
        >
          {label}
        </Button>
      </div>
    );
  }
}

export default withStyles(classStyles, {index: 1})(HyperLinkButton);
