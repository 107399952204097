import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as productDetailsSelectors, actions as productDetailsActions } from 'ducks/inventoryEnquiry/productDetails';

export const mapStateToProps = state => ({
  gridName: 'ProductDetails',
  columnDefs: productDetailsSelectors.Columns(state),
  rowData: productDetailsSelectors.Data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: productDetailsSelectors.Actions(state),
  gridOptions: productDetailsSelectors.gridOptions(state),
  isLoading: productDetailsSelectors.isLoading(state)
});

export const actions = {
  deleteSelectedRecords: productDetailsActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: productDetailsActions.setSelected
}
