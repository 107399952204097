import { IDashboardWidgetMTDSalesResponse } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';
import { Api, asObjectified } from '../../baseApi';

export interface IObjectifiedSaleHistoryResponse {
    MTDSales: IObjectified<IDashboardWidgetMTDSalesResponse>;
}

export const getSaleHistory = async ({ EntView, Warehouse }): Promise<IObjectifiedSaleHistoryResponse> => {
    return Api.getInlineResponse<IDashboardWidgetMTDSalesResponse, 'MTDSales'>(
        `DashboardMaintenance/DashboardWidget/MTDSalesbyRep/EntView/${EntView}/Warehouse/${Warehouse}`)
        .then((response) => {
            return {
                ...response,
                MTDSales: response.MTDSales ? asObjectified(response, 'MTDSales') : null
            };
        });
};
