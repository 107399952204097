import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';

const mapStateToProps = (state) => ({
  selectedInvoice: invoiceSelectors.selected(state),
  invoiceSummary: invoiceSelectors.summary(state),
  isLoading: invoiceSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
