import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as serviceWIPSelectors, actions as serviceWIPActions } from 'ducks/inventoryEnquiry/stocking/serviceWIP';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { selectors as quantitySelectors } from 'ducks/inventoryEnquiry/stocking/quantities';
import { search } from 'api/inventoryEnquiry/stocking/serviceWIP';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { actions as formActions } from 'ducks/form';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesServiceWIP',
  columnDefs: serviceWIPSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  appliedFilters: serviceWIPSelectors.filterRow(state) && filterSelectors.getFilters(state, serviceWIPSelectors.filterRow(state).formName),
  gridOptions: serviceWIPSelectors.gridOptions(state),
  isLoading: serviceWIPSelectors.isLoading(state),
  filterRow: serviceWIPSelectors.filterRow(state),
  selectedFilters: serviceWIPSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    (quantitySelectors.selected(state) || params.get('Warehouse')) &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (quantitySelectors.selected(state) && quantitySelectors.selected(state).Warehouse) || params.get('Warehouse')
  },
  apiMethod: search

})

export const actions = {
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: serviceWIPActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
