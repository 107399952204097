import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { ICustomerDetailsProps } from '../interfaces';
class CustomerDetail extends React.PureComponent<ICustomerDetailsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-enquiry/customer-details':
          onInitialLoad('CustomerDetails');
          break;
        case '/customer-enquiry/customer-details/contacts':
          onInitialLoad('Contacts');
          break;
        case '/customer-enquiry/customer-details/delivery-details':
          onInitialLoad('DeliveryDetails');
          break;
        case '/customer-enquiry/customer-details/products':
          onInitialLoad('CustomerProducts');
          break;
        case '/customer-enquiry/customer-details/marketing':
          onInitialLoad('Marketing');
          break;
        case '/customer-enquiry/customer-details/notepad':
          onInitialLoad('Notepad');
          break;
        case '/customer-enquiry/customer-details/sales-diary':
          onInitialLoad('SalesDiary');
          break;
        case '/customer-enquiry/customer-details/sales-diary/diary-details':
          onInitialLoad('DiaryDetails');
          break;
        case '/customer-enquiry/customer-details/user-fields':
          onInitialLoad('UserFields');
          break;
        default:
          onInitialLoad('CustomerDetails');
      }
    }
  }

  getDynamicBrowseLookup = (selectedTab: string) => {
    const { selectedCustomer } = this.props;
    if (selectedCustomer) {
      switch (selectedTab) {
        case 'Contacts':
          return {
            variant: 'Contacts',
            searchParams: {
              CustomerId: selectedCustomer.CustomerId
            },
            searchPlaceholder: 'Search for contacts...',
            isBrowseLookUp: true
          };
        case 'Products':
          return {
            variant: 'Products',
            searchParams: {
              CustomerId: selectedCustomer.CustomerId
            },
            isBrowseLookUp: true,
            searchPlaceholder: 'Search for products...',
          };
        case 'CustomerProducts':
          return {
            variant: 'CustomerProducts',
            searchParams: {
              CustomerId: selectedCustomer.CustomerId
            },
            isBrowseLookUp: true,
            searchPlaceholder: 'Search for products...',
          };
        case 'Marketing':
          return {
            variant: 'Marketing',
            searchParams: {
              CustomerId: selectedCustomer.CustomerId
            },
            isBrowseLookUp: true,
            searchPlaceholder: 'Search for attributes...',
          };
        case 'DeliveryDetails':
          return {
            variant: 'DeliveryDetails',
            searchParams: {
              CustomerId: selectedCustomer.CustomerId
            },
            searchPlaceholder: 'Search for addresses...',
            isBrowseLookUp: true
          };
        default: return null;
      }
    }

    return null;
  }

  getInitialValues = (): any => {
    const params = new URLSearchParams(this.props.location.search);
    const {
      selectedTab,
      selectedCustomer, selectedCustomerSchema,
      selectedContact, selectedContactSchema,
      selectedAddress, selectedAddressSchema,
      selectedProduct, selectedProductSchema,
      selectedMarketing, selectedMarketingSchema,
    } = this.props;
    switch (selectedTab) {
      case 'CustomerDetails':
        return { initialValues: selectedCustomer, valuesSchema: selectedCustomerSchema };
      case 'Contacts':
        return { initialValues: selectedContact, valuesSchema: selectedContactSchema };
      case 'DeliveryDetails':
        return { initialValues: selectedAddress, valuesSchema: selectedAddressSchema };
      case 'CustomerProducts':
        return { initialValues: selectedProduct, valuesSchema: selectedProductSchema };
      case 'Marketing':
        return { initialValues: selectedMarketing, valuesSchema: selectedMarketingSchema };
      case 'Notepad':
      case 'SalesDiary':
      case 'DiaryDetails':
        return { initialValues: { params: { CustomerId: (selectedCustomer && selectedCustomer.CustomerId) || parseInt(params.get('CustomerId')) } }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, formName, isV2 } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={(selectedTab === 'SalesDiary' && 'Diary') || formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={this.getDynamicBrowseLookup(selectedTab)}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          fieldExtensions={{
            PhoneMobile: {
              visible: selectedTab === 'DeliveryDetails' && !isV2
            }
          }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerDetail);
