import { call, takeLatest, Effect, put, takeEvery, select } from 'redux-saga/effects';
import { callApi } from './utils';

import { OPERATIONS } from '../utils/operations';

import { IDataAction } from 'ducks/utils';
import { actions as uiActions } from 'ducks/ui';
import { actions as uiOperationActions } from 'ducks/uiOperations';
import { types as creditNoteContextTypes, actions as creditNoteContextActions, selectors as creditNoteContextSelectors } from 'ducks/creditNotes/creditNoteContext';
import { types as creditNoteTypes, actions as creditNoteActions } from 'ducks/creditNotes/creditNote';
import { types as creditNoteLineTypes, actions as creditNoteLineActions } from 'ducks/creditNotes/creditNoteLine';
import { actions as PaymentActions } from 'ducks/common/paymentDetails';

import * as api from 'api/creditNotes';

function* getCreditNoteContext(): IterableIterator<Effect> {
  const creditNoteContextLoading = creditNoteContextActions.creditNoteContextLoading;
  const { success, failure } = creditNoteContextActions.saga.creditNoteContextLoading;
  // Let React know that the work sale line is updating.
  yield put(creditNoteContextLoading());
  yield callApi(call(api.getCreditNoteContext), success, failure);
}

function* clearCreditNoteContext(action: IDataAction): IterableIterator<Effect> {
  const creditNoteContextLoading = creditNoteContextActions.creditNoteContextLoading;
  const { success, failure } = creditNoteContextActions.saga.creditNoteContextLoading;
  const { success: cancelCreditNoteSuccess } = creditNoteActions.saga.cancelCreditNote;
  const cancelCreditNoteLoading = creditNoteActions.cancelCreditNote;

  // Let React know that the work sale line is updating.
  yield put(creditNoteContextLoading());

  if (action.data.DeleteWork) {
    yield put(cancelCreditNoteLoading());
    yield put(cancelCreditNoteSuccess({}));
  } else if (action.data.DeleteWork !== null) {
    return;
  }
  // Make the asynchronous call.
  yield callApi(
    call(api.clearCreditNoteContext, action.data),
    success,
    failure,
    { dialogActionCallEffect: clearCreditNoteContext });
}

function* updateCreditNoteContext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = creditNoteContextActions.saga.creditNoteContextLoading;
  const creditNoteContextLoading = creditNoteContextActions.creditNoteContextLoading;

  yield put(creditNoteContextLoading());
  yield callApi(
    call(api.updateCreditNoteContext, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateCreditNoteContext });
}

function* applyCreditNoteContext(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLoading = creditNoteActions.creditNoteLoading;
  const { success, failure } = creditNoteActions.saga.creditNoteLoading;

  // Let React know that the work sale line is updating.
  yield put(creditNoteLoading());

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    // Update the UI to be in NEW mode on the Line Details tab.
    yield put(uiActions.changeSelectedTab('CNLines'));
    yield put(uiOperationActions.changeOperationMode(OPERATIONS.BROWSE));
  }

  // Make the asynchronous call.
  yield callApi(
    call(api.applyCreditNoteContext, action.data),
    success,
    failure,
    { postApiSuccessCallEffect, dialogActionCallEffect: applyCreditNoteContext });

}

function* processCreditNote(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = creditNoteActions.saga.processCreditNote;
  const { success: paymentSuccess } = PaymentActions.saga.processCreditNote;

  let payload = { ...action.data };

  function* postApiSuccessCallEffect({ CreditNotes = {}, PaymentDetails = {} }: any): IterableIterator<Effect> {
    const { ConfirmCreditNote, EnterRestockingCharge } = CreditNotes;

    if (CreditNotes.PaymentRequired && CreditNotes.PaymentRequired.Value) {
      yield put(paymentSuccess({ PaymentDetails }));
      yield put(uiActions.changeSelectedTab('PaymentDetails'));

      return;
    }
    if (ConfirmCreditNote && EnterRestockingCharge) {
      if (ConfirmCreditNote.Value && EnterRestockingCharge.Value) {
        yield call(completeProcessCreditNote, { data: CreditNotes });

        return;
      }
    }
  }

  if (action.data) {
    if (action.data.PaymentRequired && action.data.PaymentRequired.Value) {
      yield put(paymentSuccess(action.data));
      yield put(uiActions.changeSelectedTab('PaymentDetails'));

      return;
    }

    if (typeof action.data.ConfirmCreditNote === 'object' && typeof action.data.EnterRestockingCharge === 'boolean') {
      if (action.data.EnterRestockingCharge) {
        payload = { ...action.data, RestockingCharge: action.data.formValues, EnterRestockingCharge: { Value: true, Type: 'boolean' } };
      }
    }

    if (typeof action.data.ConfirmCreditNote === 'boolean' && typeof action.data.EnterRestockingCharge === 'object') {
      if (action.data.ConfirmCreditNote) {
        payload = { ...action.data, CreditNoteConfirmation: action.data.formValues, ConfirmCreditNote: { Value: true, Type: 'boolean' } };
      }
    }

    if (action.data && action.data.formValues && action.data.formValues.UserPIN) {
      payload = {
        ...action.data,
        CreditNoteConfirmation: {
          ...action.data.CreditNoteConfirmation,
          UserPIN: action.data.formValues.UserPIN
        }
      };
    }

    if (action.data && action.data.formValues) {
      payload = { ...payload, formValues: undefined };
    }
  }

  // Make the asynchronous call.
  yield callApi(
    call(api.processCreditNote, payload),
    success,
    failure,
    { postApiSuccessCallEffect, dialogActionCallEffect: processCreditNote });

}

function* completeProcessCreditNote(action: any): IterableIterator<Effect> {
  const creditNoteContextLoading = creditNoteContextActions.creditNoteContextLoading;
  const { success, failure } = creditNoteContextActions.saga.creditNoteContextLoading;
  const { success: cancelCreditNote } = creditNoteActions.saga.cancelCreditNote;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    yield put(cancelCreditNote({}));
    yield put(uiActions.toggleCreditNotesLookupPanel(true));
    yield put(uiActions.changeSelectedTab('CNLines'));
  }

  // Let React know that the work sale line is updating.
  yield put(creditNoteContextLoading());

  // Make the asynchronous call.
  yield callApi(
    call(api.completeProcessCreditNote, action.data),
    success,
    failure,
    { postApiSuccessCallEffect, dialogActionCallEffect: completeProcessCreditNote });
}

function* updateCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLineLoading = creditNoteLineActions.creditNoteLineLoading;
  const { success, failure } = creditNoteLineActions.saga.creditNoteLineLoading;

  // Let React know that the work sale line is updating.
  yield put(creditNoteLineLoading());

  yield callApi(
    call(api.updateCreditNoteLine, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateCreditNoteLine }
  );
}

function* createCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLineLoading = creditNoteLineActions.creditNoteLineLoading;
  const { success, failure } = creditNoteLineActions.saga.creditNoteLineLoading;

  // Let React know that the work sale line is updating.
  yield put(creditNoteLineLoading());

  if (action.data && action.data.LeaveUnallocated === false) {
    yield put(uiActions.changeSelectedTab('CNLines'));
    yield put(uiActions.toggleCreditNotesLookupPanel(true));

    return;
  }

  yield callApi(
    call(api.createCreditNoteLine, action.data),
    success,
    failure,
    { dialogActionCallEffect: createCreditNoteLine }
  );
}

function* saveCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLineLoading = creditNoteLineActions.creditNoteLineLoading;
  const { success: saleLineSuccess, failure: saleLineFailure } = creditNoteLineActions.saga.creditNoteLineLoading;

  // Let React know that the work sale line is loading.
  // yield put(creditNoteLoading());
  yield put(creditNoteLineLoading());

  function* postApiSuccessCallEffect(saveResponse: any): IterableIterator<Effect> {
    if (saveResponse.CreditNoteLine) {

      // Let react know that the work sale line has loaded.
      yield put(saleLineSuccess(saveResponse));

      action.data = yield select(creditNoteContextSelectors.creditNoteContext);
      yield put(uiActions.changeSelectedTab('CNLines'));
      yield call(applyCreditNoteContext, action);
      yield put(uiOperationActions.changeOperationMode(OPERATIONS.BROWSE));
    }
  }
  yield callApi(
    call(api.saveCreditNoteLine, action.data),
    saleLineSuccess,
    saleLineFailure,
    { postApiSuccessCallEffect }
  );
}

function* saveNewCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLoading = creditNoteActions.creditNoteLoading;
  const { success, failure } = creditNoteActions.saga.creditNoteLoading;

  // Let React know that the work sale line is updating.
  yield put(creditNoteLoading());

  yield callApi(
    call(api.saveNewCreditNoteLine, action.data),
    success,
    failure
  );
}

function* deleteCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLoading = creditNoteActions.creditNoteLoading;
  const { success, failure } = creditNoteActions.saga.creditNoteLoading;

  // Let React know that the work sale line is updating.
  yield put(creditNoteLoading());

  yield callApi(
    call(api.deleteCreditNoteLine, action.data),
    success,
    failure
  );
}

function* getCreditNote(): IterableIterator<Effect> {
  const { success, failure } = creditNoteActions.saga.getCreditNote;

  yield callApi(
    call(api.getCreditNote),
    success,
    failure
  );
}

function* cancelCreditNoteDetails(): IterableIterator<Effect> {
  const { success, failure } = creditNoteActions.saga.getCreditNote;

  yield callApi(
    call(api.cancelCreditNoteDetails),
    success,
    failure
  );
}

function* cancelCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLoading = creditNoteActions.creditNoteLoading;
  const { success, failure } = creditNoteActions.saga.creditNoteLoading;

  yield put(creditNoteLoading());

  yield callApi(
    call(api.cancelCreditNoteLine, action.data),
    success,
    failure
  );
}

function* updateDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = creditNoteActions.saga.getCreditNote;

  yield callApi(
    call(api.updateDetails, action.data),
    success,
    failure
  );
}

function* saveCreditNoteDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = creditNoteActions.saga.saveCreditNoteDetails;

  yield callApi(
    call(api.saveCreditNote, action.data),
    success,
    failure
  );
}

function* getCreditNoteLine(action: IDataAction): IterableIterator<Effect> {
  const creditNoteLineLoading = creditNoteLineActions.creditNoteLineLoading;
  const { success, failure } = creditNoteLineActions.saga.creditNoteLineLoading;

  yield put(creditNoteLineLoading());

  yield callApi(
    call(api.getCreditNoteLine, action.data),
    success,
    failure
  );
}

export default function* rootCreditNotesSaga(): IterableIterator<Effect> {
  yield takeLatest(creditNoteContextTypes.getCreditNoteContext, getCreditNoteContext);
  yield takeLatest(creditNoteContextTypes.updateCreditNoteContext, updateCreditNoteContext);
  yield takeLatest(creditNoteContextTypes.clearCreditNoteContext, clearCreditNoteContext);
  yield takeEvery(creditNoteTypes.applyCreditNoteContext, applyCreditNoteContext);
  yield takeEvery(creditNoteLineTypes.updateCreditNoteLine, updateCreditNoteLine);
  yield takeEvery(creditNoteLineTypes.deleteCreditNoteLine, deleteCreditNoteLine);
  yield takeEvery(creditNoteLineTypes.saveCreditNoteLine, saveCreditNoteLine);
  yield takeEvery(creditNoteTypes.saveNewCreditNoteLine, saveNewCreditNoteLine);
  yield takeEvery(creditNoteTypes.getCreditNote, getCreditNote);
  yield takeEvery(creditNoteTypes.saveCreditNoteDetails, saveCreditNoteDetails);
  yield takeEvery(creditNoteTypes.updateDetails, updateDetails);
  yield takeEvery(creditNoteTypes.cancelCreditNoteDetails, cancelCreditNoteDetails);
  yield takeEvery(creditNoteLineTypes.getCreditNoteLine, getCreditNoteLine);
  yield takeEvery(creditNoteLineTypes.createCreditNoteLine, createCreditNoteLine);
  yield takeEvery(creditNoteTypes.processCreditNote, processCreditNote);
  yield takeEvery(creditNoteTypes.completeProcessCreditNote, completeProcessCreditNote);
  yield takeEvery(creditNoteLineTypes.cancelCreditNoteLine, cancelCreditNoteLine);
}
