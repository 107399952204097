import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  mainDiv: {
    padding: '4px 10px'
  },
  lotLineDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#DDDDDD',
    padding: '10px',
    width: '700px',
    gap: '10px'
  },
  button: {
    boxShadow: 'none',
    padding: '10px',
  },
  lotLineDataGrid: {
    width: '720px',
    height: '350px'
  },
  disabledBtn: {
    backgroundColor: '#DDDDDD !important',
  },
  bottomRowDiv: {
  },
  bottomRow: {
    display: 'flex',
    flexFlow: 'row',
    height: '36px',
  },
  headingCell: {
    backgroundColor: '#dddddd',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '75px',
    padding: '10px !important',
  },
  dataCell: {
    fontSize: '12px',
    padding: '10px !important',
    backgroundColor: '#cccccc',
    color: '#000000',
    textAlign: 'right',
    border: '1px solid #fff !important',
    width: '100px',
  },
  emphasizedCell: {
    fontSize: '13px',
    padding: '10px !important',
    backgroundColor: '#999999',
    color: '#000000',
    textAlign: 'right',
    border: '1px solid #fff !important',
    width: '100px',
  },
  cellSpacing: {
    marginRight: '31.5px'
  },
  inlineFields: {
    height: '5rem',
    gap: '14px',
    display: 'flex',
  }
});
