import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import { TransmitInternalPODialogStyles } from './TransmitInternalPODialog.styles';
import { withStyles } from '@material-ui/core';
import TransmitInternalPOGrid, { SubTitle } from './TransmitInternalPOContent';
import { ITransmitInternalPODialogProperties } from './TransmitInternalPODialog.properties';
import { TITLE_CONTENT } from './TransmitInternalPODialog.constants';
import { useTransmitInternalSale } from 'api/Worksale/worksale';

const TransmitInternalPODialog = (props: ITransmitInternalPODialogProperties) => {
    const { open, loading, salesOrder, purchaseOrders, onCancel } = props;

    const transmitInternalSaleMutation = useTransmitInternalSale();
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = React.useState();

    const [data, setData] = React.useState(purchaseOrders);
    const [disabledTransmitButton, setDisabledTrasmitButton] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            setData(purchaseOrders);
        },
        [purchaseOrders]
    );

    React.useEffect(
        () => {
            if (data && selectedPurchaseOrder) {
                const status = data?.find((f) => f.PurchaseOrder === selectedPurchaseOrder)?.Status === 't';
                setDisabledTrasmitButton(status);
            }
        },
        [data, selectedPurchaseOrder]
    );

    const handleTransmit = React.useCallback(
        async () => {
            setDisabledTrasmitButton(true);
            const response = await transmitInternalSaleMutation.mutateAsync({
                PurchaseOrder: selectedPurchaseOrder,
                SalesOrder: salesOrder,
                urlQueryParams: {
                    PurchaseOrder: selectedPurchaseOrder,
                    SalesOrder: salesOrder
                }
            });

            setData((response as any)?.PurchaseOrderTransmitDetail?.InternalTransferOrders);
        },
        [selectedPurchaseOrder, salesOrder]
    );

    const actions: IActionItem[] = React.useMemo(
        () => ([
            {
                title: 'Transmit',
                isDefault: true,
                listener: () => { handleTransmit(); },
                disabled: disabledTransmitButton
            },
            {
                title: 'Cancel',
                listener: () => { onCancel(); },
            },
        ]),
        [handleTransmit, disabledTransmitButton]
    );

    return (
        <FormViewModal
            open={open}
            title={TITLE_CONTENT}
            subTitle={<SubTitle />}
            loading={loading}
            modalContent={<TransmitInternalPOGrid loading={transmitInternalSaleMutation.isLoading} data={data} onSelectedRowChanged={setSelectedPurchaseOrder} />}
            actions={actions}
            dialogActionsButtons={true}
            dialogActionsShadow={false}
        />
    );
};
export default withStyles(TransmitInternalPODialogStyles)(React.memo(TransmitInternalPODialog));
