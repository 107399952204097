import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as productionOrderSelectors, actions as productionOrderActions } from 'ducks/purchaseOrderEnquiry/ldCommittedProductionOrder';
import { searchProductionOrders } from 'api/purchaseOrderEnquiry/ldCommitted';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { selectors as orderLineSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'CommittedProductionOrder',
  columnDefs: productionOrderSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: productionOrderSelectors.Actions(state),
  gridOptions: productionOrderSelectors.gridOptions(state),
  isLoading: productionOrderSelectors.isLoading(state),
  apiMethod: searchProductionOrders,
  reqParams: ((orderSelectors.selected(state) || params.get('PurchaseOrder')) && (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber')))
   &&
    {
      PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
    },
});

export const actions = {
  deleteSelectedRecords: productionOrderActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: productionOrderActions.setSelected
}
