import Details from 'utils/data/forms/pme/movement_details';
import PMETransactions from 'utils/data/forms/pme/transactions';
import PMEBinLocations from 'utils/data/forms/pme/binLocations';
import PMELotDetails from 'utils/data/forms/pme/LotDetails';

const getFormSchema = (formId: string) => {
  switch (formId) {
    case 'PMETransactions':
      return PMETransactions;
    case 'PMEBinLocations':
      return PMEBinLocations;
    case 'PMELotDetails':
      return PMELotDetails;
    case 'PMEMovementDetails':
    default:
      return Details;
  }
};

export default getFormSchema;
