import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearOrderLines: () => null,
  asyncs: {
  }
}, 'lineInvoices');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Entity', field: 'SalesEntity', suppressSorting: true },
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data) {
          return {
            SalesEntity: row.data.SalesEntity,
            InvoiceNumber: row.data.InvoiceNumber,
            FieldValue: row.data.InvoiceNumber
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Entered', field: 'EnteredDate', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Invoice status', field: 'InvoiceStatus', suppressSorting: true },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Warehouse', field: 'Warehouse', suppressSorting: true },
    { headerName: 'Site', field: 'SiteCode', suppressSorting: true },
    { headerName: 'Delivery name', field: 'DeliveryAddressName', suppressSorting: true },
  ],
  Data: [
  ]

});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearOrderLines:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
  
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.lineInvoices,
  {
    selectedInvoice: data => data.selected,
    Data: data => data.Data,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.lineInvoices.loading,
  gridOptions: state => state.salesOrderEnquiry.lineInvoices.data.gridOptions,
  columns: state => state.salesOrderEnquiry.lineInvoices.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)