import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import ExtendedTextField from '@markinson/uicomponents-v2/ExtendedTextField';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@markinson/uicomponents-v2/SvgIcons/';
import IAddToSaleProperties, { IAddToSaleHandle, ILastChangedField } from './AddToSale.properties';
import styles, { DialogStyles } from './AddToSale.styles';
import { isScreenRequest } from 'api/utils';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import LoadingButton from 'components/common/LoadingButton';
import { ENTER_KEY, TAB } from 'utils/constants';
import { IObjectifiedWorksaleGridLineDetailsResponse } from 'api/Worksale/catalogue';

const AddToSale = (props: IAddToSaleProperties, ref: React.Ref<IAddToSaleHandle>) => {
  const {
    classes, isAddToSaleOpen, productId, productCode, productName, productPrice = 0, productQuantity,
    priceDescription, calculateLoading, handleClose, calculateFastLine, onApply
  } = props;

  const modalRef = React.useRef<HTMLDivElement>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [apply, setApply] = React.useState<boolean>(false);
  const [isPriceOverride, setIsPriceOverride] = React.useState<boolean>(false);
  const [price, setPrice] = React.useState<number>(productPrice);
  const [priceHelpText, setPriceHelpText] = React.useState<string>(priceDescription);
  const [quantity, setQuantity] = React.useState<number>(productQuantity);
  const [priceDisabled, setPriceDisabled] = React.useState<boolean>(false);
  const [lastChangedField, setLastChangedField] = React.useState<ILastChangedField>();

  const quantityRef = React.useRef<ITextFieldHandle>();
  let priceRef = React.useRef<HTMLInputElement>();

  React.useImperativeHandle(
    ref,
    () => ({
      disablePriceField(): void {
        setPriceDisabled(true);
      },
      resetToDefault(): void {
        setPrice(productPrice);
        setQuantity(productQuantity);
        setPriceHelpText(priceDescription);
        setPriceDisabled(false);
      },
    })
  );

  React.useEffect(() => setPrice(productPrice), [productPrice]);
  React.useEffect(() => setQuantity(productQuantity), [productQuantity]);
  React.useEffect(() => setPriceHelpText(priceDescription), [priceDescription]);

  React.useEffect(
    () => {
      handleCalculateEntries({
        ProductId: productId,
        OrderedQuantity: productQuantity,
        KitStatus: null
      });
    },
    [isAddToSaleOpen]
  );

  React.useEffect(
    () => {
      if (quantityRef.current) {
        quantityRef.current.focus();
        quantityRef.current.select();
      } else {
        (document?.getElementById('quantityId') as HTMLInputElement)?.select();
      }
    },
    []
  );

  React.useEffect(
    () => {
      if (apply && !loading && !calculateLoading) {
        setApply(false);
        handleOnSubmit();
      }
    },
    [apply, loading, calculateLoading]
  );

  React.useEffect(
    () => {
      if (lastChangedField === 'quantity') {
        focusPriceField();
      }
    },
    [lastChangedField]
  );

  const keyDownHandler = (e) => {
    if (e.keyCode === ENTER_KEY && e.currentTarget.id === 'cancelButtonId') {
      e.preventDefault();
      handleClose();
    }
    if (e.keyCode === ENTER_KEY && e.currentTarget.id !== 'cancelButtonId') {
      e.preventDefault();
      setLoading(true);
      setApply(true);
      if (document.activeElement.id === 'quantityId') {
        (document.activeElement as HTMLElement)?.blur();
      }
    }
    // only execute if tab is pressed
    if (e.keyCode !== TAB) return;

    // here we query all focusable elements, customize as your own need
    const focusableModalElements = modalRef.current.querySelectorAll(
      '#quantityId, #priceId, #applyButtonId, #cancelButtonId'
    );

    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!e.shiftKey && document.activeElement === lastElement) {
      (firstElement as HTMLElement)?.focus();

      return e.preventDefault();
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (e.shiftKey && document.activeElement === firstElement) {
      (lastElement as HTMLElement)?.focus();
      e.preventDefault();
    }
  };

  function focusPriceField(): void {
    if (priceRef.current) {
      priceRef.current.focus();
      priceRef.current.select();
    } else {
      (document?.getElementsByName('FastEntryPrice')[0] as HTMLInputElement)?.select();
    }
  }

  const closeAddToSaleDialog = (response: IObjectifiedWorksaleGridLineDetailsResponse) => {
    if (isScreenRequest(response?.Forms)) return !isScreenRequest(response?.Forms);

    if (response?.Forms) {
      const errorsMessages = response.Forms.filter((form) => form.MessageType === 'Error');

      return !errorsMessages.length;
    }

    return true;
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);
      const response = await onApply({
        Description: productName,
        OrderedQuantity: quantity,
        DiscountedPrice: price,
        PriceOverride: isPriceOverride
      });

      if (response?.Status && closeAddToSaleDialog(response)) {
        handleClose();
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setLoading(false);
    }

  };

  const handleCalculateEntries = async (query: any) => {
    try {
      setLoading(true);
      const response = await calculateFastLine(query);
      setQuantity(response.FastLineDetails?.OrderedQuantity);
      setPrice(response.FastLineDetails?.DiscountedPrice);
      setIsPriceOverride(false);
      setPriceHelpText(response.FastLineDetails?.PriceDescription);
    } catch (err) {
      console.warn(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isAddToSaleOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        disableBackdropClick={true}
        PaperProps={{
          style: DialogStyles,
          tabIndex: 0,
        }}
        tabIndex={0}
      >
        <DialogTitle id='form-dialog-title' className={classes.DialogTitleStyles}>Add Sale Line</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <div className={classes.ContainerStyles} ref={modalRef}>
            <div className={classes.FormContainerStyles}>
              <span className={classes.labels}>
                <ExtendedTextField
                  label='Product'
                  disabled
                  size={'medium'}
                  value={productCode}
                  helpText={productName}
                  style={{ marginRight: '0px' }}
                />
              </span>
              <span>
                <NumericField
                  label='Quantity'
                  value={quantity}
                  onKeyDown={keyDownHandler}
                  name='FastEntryQuantity'
                  size={'small'}
                  autoFocus
                  id='quantityId'
                  onBlur={(value) => {
                    const newQuantity = Number(value);
                    if (newQuantity !== quantity) {
                      setLastChangedField('quantity');
                      handleCalculateEntries({
                        ProductId: productId,
                        OrderedQuantity: newQuantity
                      });
                    } else {
                      setLoading(false);
                    }
                  }}
                  ref={quantityRef}
                />
              </span>
              <span className={classes.Price}>
                <ExtendedTextField
                  label={'Price'}
                  size={'medium'}
                  disabled={priceDisabled}
                  placeholder={'0.00'}
                  decimalSeparator
                  type={'number'}
                  fieldRef={(reference) => priceRef = reference}
                  id='priceId'
                  name='FastEntryPrice'
                  helpText={priceHelpText}
                  onKeyDown={keyDownHandler}
                  value={price}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    setPrice(value);
                    setIsPriceOverride(true);
                    setLastChangedField('price');
                  }}
                />
              </span>
            </div>
            <div className={classes.ButtonContainerStyles}>
              <LoadingButton
                loading={loading}
                onMouseDown={() => {
                  setTimeout(
                    () => {
                      setApply(true);
                    },
                    0);
                }}
                variant={'contained'}
                className={classes.button}
                onKeyDown={keyDownHandler}
                id='applyButtonId'
                startIcon={<CheckCircleIcon className={classes.CheckCircleIconStyle} />}
              >
                APPLY
              </LoadingButton>
              <Button
                onClick={handleClose}
                variant={'contained'}
                id='cancelButtonId'
                className={classes.button}
                onKeyDown={keyDownHandler}
              >
                <span className={classes.ButtonLabelStyle}>
                  <CancelIcon className={classes.CancelIconStyle} />Cancel
                </span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(AddToSale)));
