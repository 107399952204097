import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Column } from 'devextreme-react/tree-list';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IEntriesViewProps } from './EntriesView.properties';
import styles from './EntriesView.styles';
import ImageView from 'components/common/ImageView';
import Paper from '@material-ui/core/Paper';
import { PaperElevation } from 'utils/constants';
import TreeList from 'components/common/TreeList';
import { useElementSize } from 'utils/hooks';
import { EntryListStorageKey } from '../constants';

const DEFAULT_HEIGHT = 400;

function EntriesView(props: Readonly<IEntriesViewProps>): JSX.Element {
    const {
        classes, hideImage = false, defaultFirstRowSelection, treeListRef,
        loadChildren, getMainImage, getListOfImages, loadImage, onTabOut, onSelectionChanged
    } = props;
    const [selectedItem, setSelectedItem] = React.useState<any>(null);

    const treeListContainerRef = React.useRef<HTMLDivElement>(null);
    const size = useElementSize(treeListContainerRef, { height: DEFAULT_HEIGHT });

    const cellTemplate = React.useCallback(
        (_c, data) => { //to keep extra spaces coming from API response.
            const div = document.createElement('div');
            div.innerHTML = data.displayValue.replaceAll(' ', '&nbsp;'); //devextreme accepts only &nbsp for blank spaces.

            return div;
        },
        []
    );

    return (
        <div className={classes.parentContainer} ref={treeListContainerRef}>
            <div className={`${classes.entriesView}`}>
                <Paper className={classes.paperContainer}>
                    <TreeList
                        innerRef={treeListRef}
                        style={{ height: size.height }}
                        itemId={'CatalogueId'}
                        loadData={loadChildren}
                        parentIdExpr={'ParentId'}
                        rootValue=''
                        hasItemsExpr={'hasItems'}
                        onSelectionChanged={(n) => {
                            setSelectedItem(n?.data);
                            onSelectionChanged(n);
                        }}
                        defaultFirstRowSelection={defaultFirstRowSelection}
                        onTabOut={onTabOut}
                        showColumnHeaders={false}
                        storageKey={EntryListStorageKey}
                    >
                        <Column
                            caption={'Entry'}
                            dataField='Description'
                            allowSorting={false}
                            cellTemplate={cellTemplate} />
                    </TreeList>
                </Paper>
            </div>
            {!hideImage &&
                <Paper elevation={PaperElevation} className={classes.productImageDiv}>
                    <ImageView
                        dialogTitle='Entry Image'
                        imageCount={selectedItem?.ImageCount ?? 0}
                        getMainImage={getMainImage}
                        loadImage={loadImage}
                        mainImageObject={selectedItem?.MainImage?.[0] ?? null}
                        getListOfImages={() => getListOfImages(selectedItem)}
                    />
                </Paper>
            }
        </div >
    );
}

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(EntriesView)));
