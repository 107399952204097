import { fetchGet, objectify } from './../utils'

export const getPaymentDetails = (query) => {
  const { DebtorCashObject } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/Transaction/CashObject/${DebtorCashObject}/PaymentEntryDetails`)
    .then((result) => {
      if (result) {
        const objectified = objectify(result.PaymentEntryDetails);
        return { schema: result.PaymentEntryDetails, values: { ...objectified } }
      }
      else
        return null;
    })
}

