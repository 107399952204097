import { connect } from 'react-redux';
import { getFormValues, isDirty, reset, getFormSyncErrors } from 'redux-form';
import LineDetails from './LineDetails';
import { IApplicationState } from 'ducks/reducers';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as configSelectors } from 'ducks/appConfig';
const FORM_NAME = 'LineDetailsForm';
const mapStateToProps = (state: IApplicationState) => ({
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    isV2: configSelectors.isV2(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LineDetails);
