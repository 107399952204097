import { connect } from 'react-redux';

import UserNotifications from './UserNotifications';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
});

export default connect(mapStateToProps, null)(UserNotifications);
