import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as outputActions, selectors as outputSelectors } from 'ducks/documentOutput';
import DocumentOutputDialog from './DocumentOutputDialog';
import { IApplicationState } from 'ducks/reducers';
import { selectors as configSelectors } from 'ducks/appConfig';
import { IDocumentOutputDialogProperties } from './DocumentOutputDialog.properties';

const mapStateToProps = (state: IApplicationState, props: Partial<IDocumentOutputDialogProperties>) =>
({
  values: outputSelectors.options(props.documentType, props.fieldId)(state),
  loading: outputSelectors.isLoading(state),
  isV2: configSelectors.isV2(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    onOptionsChanged: outputActions.updateOptions,
    cancelOptions: outputActions.cancelOptions,
    applyOptions: outputActions.updateInitialOptions,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentOutputDialog);
