import { fetchPost, objectify } from '../../utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import moment from 'moment';

const formatDate = (date) => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

export const fetch = async (query: { Supplier: number; Page: number; filters: any; Sort: string; SortDirection: string; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { Supplier, Page, filters, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';
  const From = filters && filters.FromDate && formatDate(filters.FromDate);
  const To = filters && filters.ToDate && formatDate(filters.ToDate);

  return fetchPost(
    `/SupplierEnquiry/PurchaseOrder/Supplier/${Supplier}/Retrieve`,
    filters
    &&
    {
      ...filters,
      FromDate: From,
      ToDate: To,
    },
    {
      BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`
    },
    respType)
    .then(
      (result: {
        SupplierPurchaseOrders: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.SupplierPurchaseOrders) {

            return {
              records: result.SupplierPurchaseOrders.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};

export const fetchSummary = async (query: { Supplier: number; filters: any }): Promise<{ [name: string]: any }> => {
  const { Supplier, filters } = query;
  const respType = 'Inline';

  const From = filters && filters.FromDate && formatDate(filters.FromDate);
  const To = filters && filters.ToDate && formatDate(filters.ToDate);

  return fetchPost(
    `/SupplierEnquiry/PurchaseOrder/Summary/Supplier/${Supplier}/Retrieve`,
    filters
    &&
    {
      ...filters,
      FromDate: From,
      ToDate: To,
    },
    null,
    respType)
    .then(
      (result: {
        SupplierPurchaseOrderSummary: any;
      }) => {
        if (result) {
          if (result.SupplierPurchaseOrderSummary) {

            return objectify(result.SupplierPurchaseOrderSummary, null, 'Inline');
          }
        }

        return null;
      });
};
