import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  parentContainer: {
    width: '100%',
    height: '100%',
    padding: 0,
    flexGrow: 1,
    boxSizing: 'border-box',
  },
  iconStyle: {
    cursor: 'pointer',
    marginLeft: '5px',
  },
  commentStyles: {
    whiteSpace: 'pre-wrap',
    width: 700,
    height: 260
  }
});
