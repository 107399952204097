import { IFormViewForm } from 'components/FormView';

const ProductPriceScheduleSellPrices: IFormViewForm = {
  id: 'ProductPriceScheduleSellPrices',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Recommended sell prices',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference cost',
            alignment: 'right',
            name: 'ReferenceCostDisplay',
            size: 'medium'
          }
        },
        {
          id: 1,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Tax included buy price',
            name: 'TaxIncludeBuyPrice'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Base price',
            alignment: 'right',
            name: 'BasePriceDisplay',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Tax included base price',
            name: 'TaxIncludedBasePrice'
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Retail price',
            name: 'RetailPriceDisplay',
            size: 'medium',
            alignment: 'right',
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1,
        colspan: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'BREAKS_TABLE',
          visible: true,
          props: {
            variant: 'ProductPriceScheduleSellPrices',
            name: 'Breaks'
          }
        }
      ]
    }
  ]
};
export default ProductPriceScheduleSellPrices;
