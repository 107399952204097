import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as reserveHistorySelectors, actions as reserveHistoryActions } from 'ducks/inventoryEnquiry/stocking/reserveHistory';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/stocking/reserveHistory';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingReserveHistory',
  columnDefs: reserveHistorySelectors.columns(state),
  rowData: reserveHistorySelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: reserveHistorySelectors.gridOptions(state),
  isLoading: reserveHistorySelectors.isLoading(state),
  filterRow: reserveHistorySelectors.filterRow(state),
  appliedFilters: reserveHistorySelectors.filterRow(state) && filterSelectors.getFilters(state, reserveHistorySelectors.filterRow(state).formName),
  selectedFilters: reserveHistorySelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    { ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId') },
  detailColumns: reserveHistorySelectors.detailColumns(state),
  detailCellRenderer: reserveHistorySelectors.detailCellRenderer(state),
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: reserveHistoryActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
