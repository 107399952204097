import { ArrowBack, Cancel } from '@markinson/uicomponents-v2/SvgIcons';
import { HotKeyNames } from 'utils/constants';

export const BACK_BUTTON = {
    label: 'Back',
    Icon: ArrowBack,
    action: 'Back',
    hotkey: HotKeyNames.CtrlB
};

export const CANCEL_BUTTON = {
    label: 'Cancel',
    Icon: Cancel,
    iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
    action: 'Cancel',
};
