import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryPanel from '../SummaryPanel/index';
import { IStockingProps } from '../interfaces';
import { MasterDetailCommentAreaRenderer } from 'components/common/DataGridDevEx/CustomRenderers/CommentAreaRenderer';
import FormRenderer from 'components/common/DataGridDevEx/CustomRenderers/FormRenderer';

class Stocking extends React.PureComponent<IStockingProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const routes = {
      '/inventory-enquiry/stocking/quantities/details': 'StockingQuantitiesPurchaseOrder',
      '/inventory-enquiry/stocking/quantities/details/orders/purchase-orders':
        'StockingQuantitiesPurchaseOrder'
      ,
      '/inventory-enquiry/stocking/quantities/details/orders/production-orders':
        'StockingQuantitiesProductionOrder'
      ,
      '/inventory-enquiry/stocking/quantities/details/orders/in-transit-import':
        'StockingQuantitiesInTransitImport'
      ,
      '/inventory-enquiry/stocking/quantities/details/orders/in-transit-local':
        'StockingQuantitiesInTransitLocal'
      ,
      '/inventory-enquiry/stocking/quantities/details/sales/picking':
        'StockingQuantitiesPicking'
      ,
      '/inventory-enquiry/stocking/quantities/details/sales/kitting':
        'StockingQuantitiesKitting'
      ,
      '/inventory-enquiry/stocking/quantities/details/sales/reserved':
        'StockingQuantitiesReserved'
      ,
      '/inventory-enquiry/stocking/quantities/details/work-in-progress/service':
        'StockingQuantitiesServiceWIP'
      ,
      '/inventory-enquiry/stocking/quantities/details/work-in-progress/production':
        'StockingQuantitiesProductionWIP'
      ,
      '/inventory-enquiry/stocking/quantities/details/work-in-progress/in-put-away':
        'StockingQuantitiesInPutAwayWIP'
      ,
      '/inventory-enquiry/stocking/quantities':
        'StockingQuantities'
      ,
      '/inventory-enquiry/stocking/lot-details':
        'StockingLotDetails'
      ,
      '/inventory-enquiry/stocking/lot-details/allocation-details':
        'StockingLotPicking'
      ,
      '/inventory-enquiry/stocking/lot-details/allocation-details/picking':
        'StockingLotPicking'
      ,
      '/inventory-enquiry/stocking/lot-details/allocation-details/reserved':
        'StockingLotReserved'
      ,
      '/inventory-enquiry/stocking/lot-details/allocation-details/in-put-away':
        'StockingLotInPutAway'
      ,
      '/inventory-enquiry/stocking/serial-numbers':
        'StockingSerials'
      ,
      '/inventory-enquiry/stocking/movements':
        'StockingMovements'
      ,
      '/inventory-enquiry/stocking/bin-locations':
        'StockingBinLocations'
      ,
      '/inventory-enquiry/stocking/reserve-history':
        'StockingReserveHistory'
    };

    onInitialLoad(routes[path] || 'StockingQuantities');
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, selectedTab } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <SummaryPanel
          variant={'ProductDetails'}
        />
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={null}
          fieldExtensions={{
            IEStockingBinLocations: {
              masterDetailRenderer: MasterDetailCommentAreaRenderer
            },
            IEStockingQuantities: {
              customPopupContentRenderer: (data) => <FormRenderer {...data} />
            }
          }}
        />
      </React.Fragment>
    );
  }
}

export default Stocking;
