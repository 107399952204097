
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, getFormSyncErrors, reset, change, initialize } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as customerSelectors, actions as customerActions } from 'ducks/customer';

import CustomerDetail from './CustomerDetails';
const FORM_NAME = 'CustomerForm';

const mapStateToProps = (state: IApplicationState) => ({
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
    isValid: isValid(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    operationMode: operationSelectors.operationMode(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedForm: formSelectors.selected(state),
    selectedCustomer: customerSelectors.selected(state),
    selectedCustomerSchema: customerSelectors.selectedSchema(state),
    customerNotifications: state.customer.notifications,
    customerError: state.customer.error,
    isLoadingCreateCustomer: customerSelectors.isLoadingCreateCustomer(state),
    isLoadingUpdateCustomer: customerSelectors.isLoadingUpdateCustomer(state),
    selectedFormAsyncErrors: formSelectors.getFormAsyncErrors(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        updateCustomer: customerActions.update,
        createCustomer: customerActions.create,
        removeCustomer: customerActions.remove,
        initializeForm: (values) => initialize(FORM_NAME, values),
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeOperationMode: operationActions.changeOperationMode,
        changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
