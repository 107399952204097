const trueCost = {
  'id': 'TrueCost',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'style': {
          'height': '330px'
        }
      },
      'children': [{
        'type': 'DATA_GRID_DEV_EX',
        'props': {
          'name': 'lines',
          'module': 'PCTrueCost',
          'styles': {
            'height': '330px'
          }
        }
      }]
    }
  ]
}
export default trueCost
