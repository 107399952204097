import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';

export const search = (query) => {
  const { SearchText, Supplier, Sort, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchPost(`SupplierEnquiry/SupplierContact/Supplier/${Supplier}/Search`, { SearchText, Sort, BatchSize })
    .then((data) => {
      if (data && data.SupplierContactSearches) {
        return {
          records: data.SupplierContactSearches.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
          BatchInformation: data.BatchInformation
        }
      }
    })
}


export const searchById = ({ Supplier, ContactNumber }) => {
  return fetchGet(`/SupplierEnquiry/ContactDetail/Supplier/${Supplier}/Contact/${ContactNumber}`, null, 'Inline')
    .then((data) => {
      if (data && data.SupplierContactDetail) {
        return {
          records: [{
            inlineObject: objectify(data.SupplierContactDetail),
            schema: data.SupplierContactDetail
          }]
        }
      }
      else return []
    })
}