import {
  createActions, asyncInitialState, asyncSelectors, dateComparator
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  setEntityView: (defaultView, defaultWarehouse) => ({ defaultView, defaultWarehouse }),
  asyncs: {
  }
}, 'IESSalesOrders');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Columns: [
    {
      headerName: 'Sales order',
      field: 'SalesOrder',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            SalesOrder: row.data.SalesOrder,
            FieldValue: row.data.SalesOrder
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-order-enquiry/order-details'
      },
      minWidth: 100
    },
    { headerName: 'Line', field: 'LineNo', type: 'numericColumn', minWidth: 100 },
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            CustomerId: row.data.Customer,
            FieldValue: row.data.CustomerDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 100 },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', minWidth: 100 },
    { headerName: 'On backorder', field: 'BackorderQuantityDisplay', minWidth: 100 },
    { headerName: 'Committed', field: 'CommittedQuantityDisplay', minWidth: 100 },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', minWidth: 100 },
    { headerName: 'Ship', field: 'ShipDate', comparator: dateComparator, minWidth: 100 },
    { headerName: 'Entered', field: 'EnteredDate', comparator: dateComparator, minWidth: 100 },
    { headerName: 'Sales order description', field: 'SalesOrderDescription', minWidth: 190 },
    { headerName: 'Customer name', field: 'CustomerName', minWidth: 190 },
    { headerName: 'Sales rep', field: 'SalesRep', minWidth: 100 },
    { headerName: 'Sales rep name', field: 'SalesRepName', minWidth: 100 },
  ],
  Data: [],
  selectedFilters: {
    EntityView: 'MPRO',
    Warehouse: '',
    CustomerId: 0
  },
  filterRow: {
    formName: 'IESSalesOrdersFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'small',
          required: false
        }
      }
    ]
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return initialState;
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setEntityView:
      const entityView = action.data.defaultView;
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView,
            Warehouse: warehouse
          }
        }
      };

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.sales.salesOrders,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.sales.salesOrders.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.sales.salesOrders.data.gridOptions,
  columns: state => state.inventoryEnquiry.sales.salesOrders.data.Columns,
  filterRow: state => state.inventoryEnquiry.sales.salesOrders.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.sales.salesOrders.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)