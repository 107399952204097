import * as React from 'react';
import ExtendedTextField from '@markinson/uicomponents-v2/ExtendedTextField';
import IExtendedTextFieldProps from '@markinson/uicomponents-v2/ExtendedTextField/ExtendedTextField.properties';

interface IExtendedTextFieldContainerFields extends IExtendedTextFieldProps {
  api?: any;
  fetchLookupOptions?: any;
  schema?: any;
  suppressHelpText?: boolean;
  params?: { queryFields: { [key: string]: string } };
}

class ExtendedTextFieldContainer extends React.PureComponent<IExtendedTextFieldContainerFields> {

  componentDidMount(): void {
    const { api, name } = this.props;
    if (api) this.props.fetchLookupOptions({ api, name });
  }

  appendParam = (params: any) => {
    const { api, results, name } = this.props;
    let actionList = null;

    if (api && results) {
      const apiString = `${api}/${name}`;
      actionList = results[apiString];
      actionList = actionList ? actionList.Options : [];
    }

    if (actionList && params) {
      return actionList.map((item: any) => ({ ...item, actionParam: params }));
    }

    return actionList;
  }

  render(): React.ReactNode {
    const { name, schema, value, helpText, api, fetchLookupOptions, params, readOnly, suppressHelpText, ...rest } = this.props;
    let extendedTextValue = value;
    let extendedTextHelpText = helpText;
    let actionListParams: any = value;
    if (schema && schema[name]) {
      const objVal = schema[name];
      if (readOnly && objVal.Display) {
        extendedTextValue = objVal.Display;
      }
      if (objVal.Label) {
        extendedTextHelpText = objVal.Label;
      }
      if (params && params.queryFields) {
        actionListParams = {};
        for (const field in params.queryFields) {
          if (params.queryFields.hasOwnProperty(field) && params.queryFields[field]) {
            const queryField = params.queryFields[field];
            if (typeof (queryField) === 'string') {
              const queryFieldString = queryField as string;
              if (schema.hasOwnProperty(queryFieldString) && schema[queryFieldString]) {
                actionListParams[field] = schema[queryFieldString].Value;
              }
            } else {
              const queryFieldsValue = queryField as { Field: string; Property: ('Value' | 'Display') };
              if (schema.hasOwnProperty(queryFieldsValue.Field) && schema[queryFieldsValue.Field]) {
                actionListParams[field] = schema[queryFieldsValue.Field][queryFieldsValue.Property || 'Value'];
              }
            }
          }
        }
      }
    }

    return (
      <ExtendedTextField
        actionList={this.appendParam(actionListParams)}
        value={extendedTextValue}
        helpText={suppressHelpText ? '' : extendedTextHelpText}
        readOnly={readOnly}
        name={name}
        {...rest}
      />
    );
  }
}

export default ExtendedTextFieldContainer;
