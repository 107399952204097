import { IFormViewForm } from 'components/FormView';

const JobTemplateForm: IFormViewForm = {
    id: 'JobTemplateDetails',
    layout: {
        rows: 1,
        columns: 2
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'JobTemplates',
                fullWidth: true
            },
            position: {
                row: 1,
                col: 1
            },
            children: [
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Job template',
                        name: 'TemplateCode',
                        required: true,
                        size: 'medium',

                    }

                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Description',
                        required: true,
                        name: 'Description',
                        size: 'large'
                    }
                },
                {
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Item Type',
                        name: 'ServiceItemTypeId',
                        lookupName: 'ServiceItemType',
                        required: false,
                        size: 'medium',

                    }
                },
                {
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Include in newly scheduled jobs',
                        name: 'IsDefault',
                    }
                },
                {
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Has fixed charge',
                        name: 'HasFixedCharge',
                    }
                },
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Charge',
                        name: 'Charge',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        type: 'number',
                        decimalSeparator: true,
                        decimalPlaces: 2,
                    }
                },
            ]
        },
        {
            id: '2',
            type: 'PAPER_CONTAINER',
            props: {
                name: 'Comments',
                fullWidth: true
            },
            position: {
                row: 1,
                col: 2
            },
            children: [
                {
                    id: '1',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Comment',
                        name: 'Comment',
                        fullWidth: true,
                        style: {
                            height: '300px'
                        }
                    }
                }
            ]
        }
    ]
};

export default JobTemplateForm;
