import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import RecallParkedSaleDialogContent from './RecallParkedSaleDialogContent.container';
import { IRecallParkedSaleDialogContentHandle, IRecallParkedSaleDialogProps } from './RecallParkedSaleDialog.properties';
import { IFormViewModelHandle } from 'components/common/Modals/FormViewModal.properties';
import { isNull } from 'utils/utils';

const RecallParkedSaleDialog = (props: IRecallParkedSaleDialogProps) => {
    const { open, loading, worksaleId, onCancel, onRecall } = props;

    const cancelBtnRef = React.useRef<HTMLButtonElement>();
    const contentRef = React.useRef<IRecallParkedSaleDialogContentHandle>();
    const formViewModalRef = React.useRef<IFormViewModelHandle>();
    const recallButtonRef = React.useRef<HTMLButtonElement>();
    const [gridLength, setGridLength] = React.useState(0);
    const [selectedRow, setSelectedRow] = React.useState(null);

    const onGridTabOut = () => {
        cancelBtnRef.current.focus();
    };

    const handleFilterButtonShiftTab = React.useCallback(
        () => {
            formViewModalRef.current?.focusLastButton();
        },
        [formViewModalRef.current]
    );

    return (
        <div>
            <FormViewModal
                open={open}
                title='Recall Sale'
                loading={loading}
                innerRef={formViewModalRef}
                modalContent={
                    <RecallParkedSaleDialogContent
                        innerRef={contentRef}
                        worksaleId={worksaleId}
                        recallButtonRef={recallButtonRef}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        onRecall={onRecall}
                        onCancel={onCancel}
                        onGridTabOut={onGridTabOut}
                        onFilterButtonShiftTab={handleFilterButtonShiftTab}
                        setGridLength={setGridLength}
                    />
                }
                actions={[
                    {
                        title: 'Recall',
                        iconName: 'CheckCircle',
                        isDefault: gridLength > 0,
                        disabled: (gridLength === 0) || isNull(selectedRow),
                        ref: recallButtonRef,
                        listener: () => {
                            if (contentRef.current) {
                                contentRef.current.recall();
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        iconName: 'Cancel',
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                        ref: cancelBtnRef
                    }
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
                onActionsTabOut={() => {
                    contentRef.current.focusFilterButton();
                }}
                onActionShiftTabOut={() => {
                    contentRef.current.focusGrid();
                }}
            />
        </div>
    );
};

export default RecallParkedSaleDialog;
