import * as React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import { IReviewJobWizardProps } from '../../interfaces';
import { Operation } from 'utils/operations';
import AssignmentsPanel from './AssignmentsPanel';
import SelectJobPanel from './SelectJobPanel';
import SummaryPanel from './SummaryPanel';
import ChecklistSummary from './ChecklistSummary';
import { REVIEW_JOB_MODAL_TITLES } from '../constants';
import { isNull } from 'utils/utils';
import './ReviewJobWizard.css';

// tslint:disable: no-magic-numbers
interface IReviewJobWizardState {
    panel: number;
    selectedActivity: any;
    selectedJob: any;
    nextSelected: boolean;
}

class ReviewJobWizard extends React.Component<IReviewJobWizardProps, IReviewJobWizardState> {
    constructor(props: IReviewJobWizardProps) {
        super(props);

        this.state = {
            panel: 1,
            selectedActivity: {},
            selectedJob: {},
            nextSelected: false
        };
    }

    componentDidUpdate(): void {
        const { data, jobSummaryLoading } = this.props;
        const { nextSelected } = this.state;
        if (nextSelected && !jobSummaryLoading) {
            if (!isNull(data)) {
                this.setState((prevState) => ({
                    panel: prevState.panel + 1,
                    selectedJob: {}
                }));
            }
            this.setState({ nextSelected: false });
        }
    }

    getTitle = (): string => {
        const { panel } = this.state;
        const panelNumber = `(${panel} of 4)`;
        const title = `${REVIEW_JOB_MODAL_TITLES[panel] || ''} ${panelNumber}`;

        return title;
    }

    moveToNextPanel = () => {
        this.setState((prevState) => ({ panel: prevState.panel + 1 }));
    }

    selectJobNextListener = () => {
        const { selectedJob = {}, panel } = this.state;

        this.setState({ nextSelected: true });

        if (panel === 1) {
            this.props.fetchReviewJobSummary({ ServiceJobId: selectedJob.ServiceJobId });
        }
    }

    newJobActions = () => {
        const NEXT_BUTTON: IActionItem = {
            iconName: 'Forward',
            title: 'Next',
            listener: () => {
                this.setState((prevState) => ({ panel: prevState.panel + 1 }));
            }
        };

        const PREV_BUTTON: IActionItem = {
            iconName: 'Forward',
            angleOfRotation: 180,
            title: 'Back',
            alignment: 'left',
            listener: () => {
                this.setState((prevState) => ({ panel: prevState.panel - 1 }));
            }
        };

        const CANCEL_BUTTON: IActionItem = {
            iconName: 'Cancel',
            title: 'Cancel',
            alignment: 'left',
            listener: () => {
                this.props.handleClose();
                this.props.changeOperationMode(Operation.BROWSE);
            }
        };

        const DONE_BUTTON: IActionItem = {
            title: 'Submit',
            iconName: 'CheckCircle',
            listener: () => {
                this.props.completeJobReview();
                this.props.handleClose();
                this.props.changeOperationMode(Operation.BROWSE);
            }
        };
        switch (this.state.panel) {
            case 1: return [CANCEL_BUTTON, {
                ...NEXT_BUTTON,
                listener: this.selectJobNextListener,
                disabled: isNull(this.state.selectedJob) || this.props.jobSummaryLoading
            }];
            case 2:
            case 3: return [CANCEL_BUTTON, PREV_BUTTON, NEXT_BUTTON];
            case 4: return [CANCEL_BUTTON, PREV_BUTTON, DONE_BUTTON];
            default: return [];
        }
    };

    onJobSelection = (selection: any) => {
        const data = selection && selection.selectedRowsData && selection.selectedRowsData[0];
        if (data) {
            this.setState({
                selectedJob: data
            });
        }
    }

    getModalContent = () => {
        const { panel } = this.state;
        switch (panel) {
            case 1:
                return <SelectJobPanel onJobSelected={this.onJobSelection} />;
            case 2:
                return <SummaryPanel />;
            case 3:
                return <ChecklistSummary />;
            default:
                return <AssignmentsPanel />;
        }
    }

    render(): React.ReactNode {
        const { open } = this.props;

        return (
            <FormViewModal
                open={open}
                loading={false}
                title={this.getTitle()}
                modalContent={this.getModalContent()}
                actions={this.newJobActions()}
            />
        );
    }
}

export default ReviewJobWizard;
