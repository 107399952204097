import { takeLatest, Effect, call } from 'redux-saga/effects';
import { types as technicianTypes, actions as technicianActions } from 'ducks/rosterMaintenance/technician';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/rosterMaintenance/technician';
import { IDataAction } from 'ducks/utils';

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = technicianActions.saga.searchById;
  const SearchText = action.data.UserId;

  yield callApi(
    call(api.search, { SearchText }),
    success,
    failure,
    { dialogActionCallEffect: searchById }
  );
}

export default function* rootrosterMaintenanceSaga(): IterableIterator<Effect> {
  yield takeLatest(technicianTypes.search, connectSagaToApi(technicianActions, 'search', api.search));
  yield takeLatest(technicianTypes.fetchPrevPage, connectSagaToApi(technicianActions, 'fetchPrevPage', api.search));
  yield takeLatest(technicianTypes.fetchNextPage, connectSagaToApi(technicianActions, 'fetchNextPage', api.search));
  yield takeLatest(technicianTypes.searchById, searchById);
}
