import data from './../data/db.json';
import SalesTerritories from './../data/db/lookups/SalesTerritories';
import SalesCategories from './../data/db/lookups/SalesCategories';
import OrderCategories from './../data/db/lookups/OrderCategories';
import FreightCodes from './../data/db/lookups/FreightCodes';
import SalesReps from './../data/db/lookups/SalesReps';
import InternalWarehouses from './../data/db/lookups/InternalWarehouses';
import SalesEntities from './../data/db/lookups/SalesEntities';
import TaxClasses from './../data/db/lookups/TaxClasses';
import Currencies from './../data/db/lookups/Currencies';
import CreditTypes from './../data/db/lookups/CreditTypes';
import CustomerPriceCodes from './../data/db/lookups/CustomerPriceCodes';
import DefaultWarehouses from './../data/db/lookups/DefaultWarehouses';
import MaintenanceActionList from './../data/db/actions/MaintenanceActionList';
import SchedCodeActionList from './../data/db/actions/SchedCodeActionList';
import BillToIdActionList from './../data/db/actions/BillToIdActionList';
import CommittedProductActionList from './../data/db/actions/CommittedProductActionList';
import CommittedDocumentActionList from './../data/db/actions/CommittedDocumentActionList';
import DefaultChartAccountActionList from './../data/db/actions/DefaultChartAccountActionList';
import SupplierActionList from './../data/db/actions/supplierActionList';
import SupplierInvoiceActionList from './../data/db/actions/supplierInvoiceActionList';
import RFCActionList from './../data/db/actions/RFCActionList';
import SalesProcessingActionList from './../data/db/actions/SalesProcessingActionList';
import SalesProcessingPriceActionList from './../data/db/actions/SalesProcessingPriceActionList';
import EnquiryActionList from './../data/db/actions/EnquiryActionList';
import SalesOrderEnquiryLineActionList from './../data/db/actions/SalesOrderEnquiryLineActionList';
import PriceCheckToInventoryActionList from './../data/db/actions/PriceCheckToInventoryActionList';
import SlipActions from './../data/db/actions/SlipActions';
import SalesOrderActionsList from './../data/db/actions/SalesOrderActionsList';
import QuoteActionList from './../data/db/actions/QuoteActionList';
import InvoiceEnquiry from './../data/db/actions/Invoice';
import SalesInvoiceEnquiry from './../data/db/actions/SalesInvoiceEnquiry';
import CustomerTransactionEnquiry from './../data/db/actions/CustomerTransactionEnquiry';
import GLBatchEnquiry from './../data/db/actions/GLBatchEnquiry';
import LocalShipment from './../data/db/actions/LocalShipments';

class LookupService {

  getSalesTerritories = () => {
    return SalesTerritories;
  }

  getSalesCategories = () => {
    return SalesCategories;
  }

  getOrderCategories = () => {
    return OrderCategories;
  }

  getFreightCodes = () => {
    return FreightCodes;
  }

  getSalesReps = () => {
    return SalesReps;
  }

  getInternalWarehouses = () => {
    return InternalWarehouses;
  }

  getSalesEntities = () => {
    return SalesEntities;
  }

  getTaxClasses = () => {
    return TaxClasses;
  }

  getCurrencies = () => {
    return Currencies;
  }

  getCreditTypes = () => {
    return CreditTypes;
  }

  getCustomerPriceCodes = () => {
    return CustomerPriceCodes;
  }

  getDefaultWarehouses = () => {
    return DefaultWarehouses;
  }

  getActionList = (type) => {
    switch (type) {
      case 'maintenance': return MaintenanceActionList;
      case 'SchedOverride':
      case 'SchedCode': return SchedCodeActionList;
      case 'Customer': return BillToIdActionList;
      case 'CustomerId': return BillToIdActionList;
      case 'BillToId': return BillToIdActionList;
      case 'DefaultChartAccount': return DefaultChartAccountActionList;
      case 'supplier': return SupplierActionList;
      case 'supplierInvoice': return SupplierInvoiceActionList;
      case 'rfc': return RFCActionList;
      case 'enquiry': return EnquiryActionList;
      case 'salesOrderLine': return SalesOrderEnquiryLineActionList;
      case 'priceToInventory': return PriceCheckToInventoryActionList;
      case 'salesprocessing': return SalesProcessingActionList;
      case 'salesprocessingprice': return SalesProcessingPriceActionList;
      case 'committedProduct': return CommittedProductActionList;
      case 'committedDocument': return CommittedDocumentActionList;
      case 'saleOrder': return SalesOrderActionsList;
      case 'quotes': return QuoteActionList;
      case 'AllocatedInvoice':
      case 'salesInvoiceEnquiry': return SalesInvoiceEnquiry;
      case 'Batch':
      case 'glBatchEnquiry': return GLBatchEnquiry;
      case 'customerTransactionEnquiry': return CustomerTransactionEnquiry;
      case 'Invoice': return InvoiceEnquiry;
      case 'ReturnAuthority':
      case 'pickSlipEnquiries': return SlipActions;
      case 'LocalShipment': return LocalShipment;
      default: return null;
    }
  }

  getLookupOptions = (lookupId) => {
    const result = data.lookups.filter((item) => lookupId === item.id)[0];
    return result;
  }
}
export default LookupService;