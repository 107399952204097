import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as productionOrderSelectors, actions as productionOrderActions } from 'ducks/salesOrderEnquiry/ldCommittedProductionOrder';
import { fetch } from 'api/salesOrderEnquiry/ldCommittedProductionOrder';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';

export const mapStateToProps = state => {
  const params = new URLSearchParams(location.search);

  return ({
    gridName: 'LdCommittedProductionOrder',
    columnDefs: productionOrderSelectors.Columns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
    actions: productionOrderSelectors.Actions(state),
    gridOptions: productionOrderSelectors.gridOptions(state),
    isLoading: productionOrderSelectors.isLoading(state),
    reqParams: (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber')) &&
      (orderSelectors.selected(state) || params.get('SalesOrder')) &&
    {
      SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
    },
    apiMethod: fetch
  });
}

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: productionOrderActions.setSelected
}
