import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { ICapabilitiesProps } from '../interfaces';
import { Operation } from 'utils/operations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class Capabilities extends React.PureComponent<ICapabilitiesProps> {

    operationMap: Map<Operation, (prevProps?: Readonly<ICapabilitiesProps>) => void>;

    constructor(props: Readonly<ICapabilitiesProps>) {
        super(props);
        this.operationMap = new Map<Operation, () => void>();
        this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
        this.operationMap[Operation.BACK] = this.cancelFormSubmission;
        this.operationMap[Operation.SAVE] = this.submitFormValues;
        this.operationMap[Operation.DELETE] = this.deleteCapability;
    }

    componentDidMount(): void {
        const { path, onInitialLoad } = this.props;

        if (path) {
            switch (path) {
                case '/activity-maintenance/activity-details/capabilities':
                    onInitialLoad('Capabilities');
                    break;
                default:
                    onInitialLoad('Capabilities');
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<ICapabilitiesProps>): void {
        const { operationMode } = this.props;

        if (operationMode !== prevProps.operationMode) {
            if (this.operationMap[operationMode]) {
                this.operationMap[operationMode](prevProps);
            }
        }
    }

    deleteCapability = (): void => {
        const { deleteCapability, formValues = {}, changeOperationMode, changeConfirmationDialog } = this.props;
        const params = new URLSearchParams(location.search);
        const ActivityCode = params.get('ActivityCode');
        if (formValues.CapabilityCode) {
            changeConfirmationDialog({
                open: true,
                title: 'Delete Capability',
                message: 'Are you sure you want to delete?',
                okLabel: 'Delete',
                onCancel: () => {
                    changeOperationMode(Operation.BROWSE);
                },
                onOk: () => {
                    deleteCapability(ActivityCode, formValues.CapabilityCode);
                }
            });
        }
        changeOperationMode(Operation.BROWSE);
    }

    submitFormValues = (create?: boolean): void => {
        const { isValid, dirty, formSyncErrors, changeOperationMode } = this.props;
        if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0)) {
            const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
            if (requiredError) {
                showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
            } else {
                const firstError = Object.keys(formSyncErrors)[0];
                showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
            }
            if (create) {
                changeOperationMode(Operation.EDIT);

                return;
            }
            changeOperationMode(Operation.NEW);

            return;
        }
        if (dirty) {
            this.addCapability();

            changeOperationMode(Operation.BROWSE);
        }
    }

    addCapability = () => {
        const { addCapability, location, selectedActivity, formValues } = this.props;

        const params = new URLSearchParams(location.search);
        const ActivityCode = selectedActivity ? selectedActivity.ActivityCode : parseInt(params.get('ActivityCode'));
        const CapabilityCode = formValues.CapabilityCode;
        addCapability(ActivityCode, CapabilityCode);
    }

    cancelFormSubmission = (prevProps: Readonly<ICapabilitiesProps>) => {
        const {
            dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad,
            selectedTab, resetForm, operationMode, history
        } = this.props;
        if (dirty) {
            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    // changeOperationMode(Operation.EDIT); //works after second cancel dialog
                    changeOperationMode(prevProps.operationMode);
                },
                onOk: () => {
                    changeOperationMode(Operation.BROWSE);
                    resetForm();
                }
            });
        } else {
            if (operationMode === Operation.CANCEL) {
                changeOperationMode(Operation.BROWSE);
            } else {
                if (prevProps.operationMode === Operation.EDIT) {
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab !== 'Capabilities') {
                    onInitialLoad('Capabilities');
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab === 'Capabilities') {
                    history.push('/dashboard');
                }
            }
        }
    }

    getDynamicBrowseLookup = (selectedTab: string) => {
        const { selectedActivity } = this.props;

        const ActivityCode = selectedActivity ? selectedActivity.ActivityCode : '';

        if (true) {
            switch (selectedTab) {
                case 'Capabilities':
                    return {
                        open: true,
                        variant: 'Capabilities',
                        searchParams: {
                            ActivityCode: ActivityCode
                        },
                        searchPlaceholder: 'Search for capabilities...',
                        isBrowseLookUp: true,
                        hideFilterButton: true
                    };

                default: return null;
            }
        }

        return null;
    }

    getInitialValues = (): any => {
        const { selectedCapability, selectedCapabilitySchema, selectedTab, operationMode } = this.props;
        if (operationMode === Operation.CANCEL) { return; }
        if (operationMode === Operation.NEW) {
            return {
                initialValues: {},
                valuesSchema: {}
            };
        }
        switch (selectedTab) {
            case 'Capabilities':
                return { initialValues: selectedCapability, valuesSchema: selectedCapabilitySchema };
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;
        const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

        return (selectedForm &&
            <React.Fragment>
                <FormView
                    formName={formName}
                    browseLookUpOpen={isBrowseLookUpOpen}
                    browseLookUpComponent={this.getDynamicBrowseLookup(formName)}
                    schema={selectedForm}
                    initialValues={initialValues}
                    operationMode={operationMode}
                    valuesSchema={valuesSchema}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(Capabilities);
