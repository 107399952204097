import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as fromTypes, actions as fromActions } from 'ducks/form';

import { callApi } from './utils';
import * as api from 'api/form';


function* search(action){
  const {success, failure} = fromActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}
export default function* rootCustomerSaga(){
  yield takeLatest(fromTypes.search, search)
}