import * as React from 'react';
import FormView from '../../FormView';
import { INotePadProperties } from '../interfaces';

class NotePad extends React.PureComponent<INotePadProperties> {

  componentDidMount(): void {
    const { onInitialLoad } = this.props;
    onInitialLoad('Notepad');
  }

  getInitialValues = () => {
    const {
      selectedProduct
    } = this.props;

    return {
      params: {
        SupplierProductId: selectedProduct && selectedProduct.SupplierProductId
      }
    };

  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={this.getInitialValues()}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default NotePad;
