import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InvoiceLines from './InvoiceLines';
import { actions as invoiceLineActions, selectors as invoiceLineSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLines';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as invoiceSelectors, actions as invoiceActions } from 'ducks/salesInvoiceEnquiry/Invoice';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  invoiceLineSummary: invoiceLineSelectors.invoiceLineSummary(state),
  invoiceLineDetails: invoiceLineSelectors.details(state),
  loadingInvoiceLineSummary: invoiceLineSelectors.loadingSummary(state),
  selectedInvoiceLine: invoiceLineSelectors.selected(state),
  selectedInvoice: invoiceSelectors.selected(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getInvoiceLineSummary: invoiceLineActions.getInvoiceLineSummary,
    getInvoiceLineDetails: invoiceLineActions.getInvoiceLineDetails,
    getInvoiceSummary: invoiceActions.getSummary
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceLines);
