import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router-dom';
import { ISupplierDetailsProps } from '../interfaces';

class CustomerDetail extends React.PureComponent<ISupplierDetailsProps> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-enquiry/supplier-details':
          onInitialLoad('SupplierDetails');
          break;
        case '/supplier-enquiry/supplier-details/contacts':
          onInitialLoad('Contacts');
          break;
        case '/supplier-enquiry/supplier-details/delivery-details':
          onInitialLoad('DeliveryDetails');
          break;
        case '/supplier-enquiry/supplier-details/user-fields':
          onInitialLoad('UserFields');
          break;
        case '/supplier-enquiry/supplier-details/marketing':
          onInitialLoad('Marketing');
          break;
        case '/supplier-enquiry/supplier-details/notepad':
          onInitialLoad('Notepad');
          break;
        default:
          onInitialLoad('SupplierDetails');
      }
    }
  }

  getContactDetails = () => {
    const { selectedContact } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const Supplier = params.get('Supplier') || '';
    const ContactNumber = (selectedContact && selectedContact.ContactNumber) || params.get('ContactNumber') || '';
    const { getContactDetails } = this.props;
    if (Supplier && ContactNumber) {
      getContactDetails({ Supplier: parseInt(Supplier), ContactNumber: parseInt(ContactNumber) });
    }
  }

  onDeliveryChange = (): void => {
    const { selectedSiteCode, searchDeliveryDetailForId, selectedSupplier } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SiteCode = selectedSiteCode || (params.get('SiteCode') || '');
    const Supplier = (selectedSupplier && selectedSupplier.Supplier) || (params.get('Supplier') || '');
    if (Supplier && SiteCode && searchDeliveryDetailForId) {
      searchDeliveryDetailForId({ Supplier: Supplier.toString(), SiteCode: SiteCode });
    }
  }

  getDynamicBrowseLookup = (selectedTab: string) => {
    const { selectedSupplier, location } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = params.get('Supplier') || '';
    if (selectedSupplier) {
      switch (selectedTab) {
        case 'Contacts':
          return {
            variant: 'SupplierContact',
            searchParams: {
              Supplier: (selectedSupplier && selectedSupplier.Supplier) || Supplier
            },
            searchPlaceholder: 'Search for contacts...',
            isBrowseLookUp: true,
            recordChangeCallBacks: [
              this.getContactDetails
            ]
          };
        case 'DeliveryDetails':
          return {
            variant: 'SupplierDeliveryDetails',
            searchParams: {
              Supplier: (selectedSupplier && selectedSupplier.Supplier) || Supplier
            },
            searchPlaceholder: 'Search for addresses...',
            isBrowseLookUp: true,
            recordChangeCallBacks: [
              this.onDeliveryChange
            ]
          };
        default: return null;
      }
    }

    return null;
  }

  getInitialValues = (): any => {
    const params = (new URLSearchParams(this.props.location.search));
    const Supplier = params.get('Supplier') || '';
    const SiteCode = params.get('SiteCode') || '';
    const {
      selectedTab,
      selectedSupplier, supplierDetails,
      deliveryDetails,
      selectedProduct, selectedProductSchema,
      selectedMarketing, selectedMarketingSchema,
      contactDetails
    } = this.props;
    switch (selectedTab) {
      case 'SupplierDetails':
        return { initialValues: (supplierDetails && supplierDetails.values) || Supplier, valuesSchema: supplierDetails && supplierDetails.schema };
      case 'Contacts':
        return contactDetails && { initialValues: contactDetails.inlineObject, valuesSchema: contactDetails.schema };
      case 'DeliveryDetails':
        return { initialValues: (deliveryDetails && deliveryDetails.values) || SiteCode, valuesSchema: deliveryDetails && deliveryDetails.schema };
      case 'CustomerProducts':
        return { initialValues: selectedProduct, valuesSchema: selectedProductSchema };
      case 'Marketing':
        return { initialValues: selectedMarketing, valuesSchema: selectedMarketingSchema };
      case 'Notepad':
        return { initialValues: { params: { Supplier: selectedSupplier && selectedSupplier.Supplier } }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={this.getDynamicBrowseLookup(selectedTab)}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerDetail);
