import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import SummaryPanel from './SummaryPanel';
import FormView from 'components/FormView';
import NavigationTabs from './NavigationTabs';
import PriceCheckLookupPanel from './PriceCheckLookupPanel';
import PriceCalculator from 'components/common/PriceCalculator';
import { shallowCompare } from 'utils/utils';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BreadcrumbBar from './../common/BreadcrumbBar';
import styles from './PriceCheck.scss';
import options from './OptionItems.json';
import { OPERATIONS } from 'utils/operations';
import TrueCostCalculator from './TrueCostCalculator';
import { Grid } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';

const inlineStyles = {
  salesInvoiceEnquirySection: {
    width: 'calc(100% - 300px)',
    marginLeft: '0px',
  },
  scrollBar: {
    width: 'unset',
  }
};

const moduleTree = [
  { id: 'Details', label: 'Details', parent: '' },
  { id: 'Why', label: 'Why', parent: '' },
  { id: 'Prices', label: 'Prices', parent: '' },
  { id: 'PricesByWarehouse', label: 'Prices by Warehouse', parent: '' },
  { id: 'SalesHistory', label: 'Sales History', parent: '' },
  { id: 'Categories', label: 'Categories', parent: '' },
  { id: 'TrueCost', label: 'True Cost Calculator', parent: '' },
  { id: 'Matrix', label: 'Matrix', parent: '' },
];

const tabsData = [
  {
    label: 'DETAILS',
    id: 'Details'
  },
  {
    label: 'WHY',
    id: 'Why'
  },
  {
    label: 'PRICES',
    id: 'Prices'
  },
  {
    label: 'PRICES BY W/H',
    id: 'PricesByWarehouse'
  },
  {
    label: 'MATRIX',
    id: 'Matrix'
  },
  {
    label: 'CATEGORIES',
    id: 'Categories'
  },
  {
    label: 'TRUE COST',
    id: 'TrueCost'
  },
  {
    label: 'SALES HISTORY',
    id: 'SalesHistory'
  }
];

const v2TabsData = [
  {
    label: 'DETAILS',
    id: 'Details'
  },
  {
    label: 'PRICES',
    id: 'Prices'
  },
  {
    label: 'PRICES BY W/H',
    id: 'PricesByWarehouse'
  },
  {
    label: 'MATRIX',
    id: 'Matrix'
  },
  {
    label: 'TRUE COST',
    id: 'TrueCost'
  }
];

interface IPriceCheckProps extends RouteComponentProps {
  filters: any;
  details: any;
  isMenuOptionOpen: boolean;
  selectedTab: any;
  operationMode: any;
  selectedForm: any;
  priceCalculationFactors: any;
  isBrowseLookUpOpen: boolean;
  customerPriceCode: any;
  isV2?: boolean;
  getDetails(): any;
  getCustomerPriceSummary(args: any): any;
  changeSelectedTab(args: any): void;
  getFormSchema(args: any): void;
  changeSelectedForm(context: any, tab: any): void;
  getPriceCalculationFactors(args: any): void;
  toggleMenuOption(args: any): void;
  changeOperationMode(args: any): void;
  calculatePrice(args: any, path: string): void;
  clearDetails(): void;
}

class PriceCheck extends React.Component<IPriceCheckProps> {

  componentDidMount(): void {
    const context = 'price-check';
    this.props.getFormSchema({
      context,
      formId: this.props.selectedTab
    });
    this.props.changeSelectedForm('price-check', this.props.selectedTab);
    this.props.changeOperationMode(OPERATIONS.BROWSE);
    this.props.clearDetails();
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: any): void {
    const { operationMode } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (JSON.stringify(this.props.filters) !== JSON.stringify(prevProps.filters) && this.props.selectedTab !== 'Details') {
      this.fetchChildRecords(this.props.selectedTab);
    }
    if (this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) {
      this.handleModuleChange(this.props.selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('price-check', this.props.selectedTab);
    }
  }

  handleModuleChange = (selectedTab: any) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'price-check';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    this.fetchChildRecords(selectedTab);

  }

  fetchChildRecords = (selectedTab: any) => {
    if (this.props.filters) {
      if (this.props.details) {
        switch (selectedTab) {
          case 'Why':
            this.props.getPriceCalculationFactors(this.props.filters);
            break;
          default:
        }
      }
    }
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = moduleTree.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'Details') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('Details');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getInitialValues = () => {
    const { selectedTab, details, priceCalculationFactors } = this.props;
    switch (selectedTab) {
      case 'Details':
        return details || {};
      case 'Why':
        return { value: { PriceCalculationFactors: priceCalculationFactors } } || {};
      default:
        return null;
    }
  }

  isTabIdValid = (tabId: any) => {
    const tabs = tabsData.filter((item) => {
      return (item.id === tabId);
    });
    if (tabs.length > 0) {
      return tabId;
    }

    return false;
  }

  calculatePrice = (filters: any) => {
    this.props.calculatePrice(filters, '/PriceCheck/PriceCalculator');
  }

  render(): React.ReactNode {
    const {
      selectedTab, operationMode,
      isMenuOptionOpen, selectedForm
    } = this.props;
    const selectedValidTab = this.isTabIdValid(selectedTab);
    const initialValues = this.getInitialValues();

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <PriceCheckLookupPanel
            onApply={this.props.getDetails}
            getCustomerPriceSummary={this.props.getCustomerPriceSummary}
            customerPriceCode={this.props.customerPriceCode}
          />
          <div
            style={inlineStyles.salesInvoiceEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Price Check'}
              activeModule={selectedTab}
              moduleTree={moduleTree}
            />
            <SummaryPanel />
            <NavigationTabs
              selectedTab={selectedValidTab}
              onTabChange={this.handleModuleChange}
              operationMode={operationMode}
              tabsData={this.props.isV2 ? v2TabsData : tabsData}
            />
            <Grid className={styles.trueCostGridCOntainer}>
              <Scrollbars style={inlineStyles.scrollBar}>
                {selectedTab === 'TrueCost' && <TrueCostCalculator
                />}
                {selectedForm && <FormView
                  formName={'PriceCheck'}
                  browseLookUpOpen={this.props.isBrowseLookUpOpen}
                  browseLookUpComponent={selectedTab}
                  includeTabsHeight={true}
                  schema={selectedForm}
                  initialValues={(initialValues && initialValues.value) || {}}
                  valuesSchema={(initialValues && initialValues.schema) || {}}
                  operationMode={operationMode}
                  enableReinitialize={true}
                />
                }
              </Scrollbars>
            </Grid>
          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={options}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'price-check'} />
        <PriceCalculator
          open={operationMode === OPERATIONS.CALCULATOR}
          onClose={() => { this.props.changeOperationMode(OPERATIONS.BROWSE); }}
          filters={this.props.filters} onApply={this.calculatePrice}
        />
      </div>
    );
  }
}

export default withRouter(PriceCheck);
