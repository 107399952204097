import {
  createActions, asyncInitialState, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row })
}, 'productReceipts');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  productReceiptsActions: {
  },
  productReceiptsColumns: [
    {
      headerName: 'Invoice', field: 'Invoice',

      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry'
      },
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice) {
          return {
            DocumentNumber: row.data.DocumentNumber,
            FieldValue: row.data.Invoice
          };
        } else return null;
      },
    },
    { headerName: 'GRN', field: 'GRN', type: 'numericColumn', minWidth: 150 },
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 150 },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true },
    { headerName: 'Description', field: 'ProductIdLabel', minWidth: 200, suppressSorting: true },
    { headerName: 'Reference', field: 'Reference', minWidth: 200, suppressSorting: true },
    { headerName: 'Invoice total', field: 'InvoiceTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true }
  ],
  selectedFilters: {
    FromDate: '',
    ToDate: '',
    Finalised: 'All'
  },
  filterRow: {
    formName: 'PurchaseOrderFilters',
    parameters: [
      {
        id: 0,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 1,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'Finalised',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.productReceipts,
  {
    selectedOrderLine: data => data.selected,
    productReceiptsActions: data => data.productReceiptsActions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.productReceipts.loading,
  gridOptions: state => state.purchaseOrderEnquiry.productReceipts.data.gridOptions,
  productReceiptsColumns: state => state.purchaseOrderEnquiry.productReceipts.data.productReceiptsColumns,
  productReceiptsActions: state => state.purchaseOrderEnquiry.productReceipts.data.productReceiptsActions,
  filterRow: state => state.purchaseOrderEnquiry.productReceipts.data.filterRow,
  selectedFilters: state => state.purchaseOrderEnquiry.productReceipts.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)