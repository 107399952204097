import {
  ISortOrder, ISearchRequest,
  IServiceItemTypeFacade, IServiceItemTypeSearchResponse, IServiceItemTypeResponse, IErrorResponse
} from './../swaggerTypes';
import { IObjectified, Inline, } from '../utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { Api, asObjectifiedArray, asObjectified, toObjectified } from '../baseApi';
import { isNull } from 'utils/utils';

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
  records: IObjectified<IServiceItemTypeFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return Api.postInlineCollectionResponse<ISearchRequest, IServiceItemTypeSearchResponse, 'ServiceItemTypes'>(
    'ServiceItemTypeMaintenance/SearchServiceItemTypes', { SearchText, Sort, BatchPage, BatchSize })
    .then((response) => {
      const records = asObjectifiedArray(response, 'ServiceItemTypes');
      const batchInformation = (response as IServiceItemTypeSearchResponse).BatchInformation;

      return {
        records,
        currPage: batchInformation ? batchInformation.BatchPage : 1,
        nextPage: batchInformation && !batchInformation.EndOfData,
        prevPage: batchInformation && batchInformation.BatchPage > 1
      };
    });
}

export async function searchById(query: { ServiceItemTypeId: string }): Promise<{
  records: IObjectified<IServiceItemTypeFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { ServiceItemTypeId } = query;

  return Api.getInlineResponse<IServiceItemTypeResponse, 'ServiceItemType'>(
    `ServiceItemTypeMaintenance/ServiceItemType/${ServiceItemTypeId}`)
    .then((response) => {
      const records = isNull(response.Forms) ? [asObjectified(response, 'ServiceItemType')] : [];

      return {
        Forms: response.Forms,
        records: records,
        currPage: 1,
        nextPage: false,
        prevPage: false
      };
    });
}
export type IObjectifiedServiceItemTypeResponse = Omit<IServiceItemTypeResponse, 'ServiceItemType'> & {
  ServiceItemType: IObjectified<IServiceItemTypeFacade>;
};

export type InlineServiceItemTypeResponse = Omit<IServiceItemTypeResponse, 'ServiceItemType'> & {
  ServiceItemType: Inline<IServiceItemTypeFacade>;
};

export async function fetchServiceItemType(ServiceItemTypeId: string): Promise<IObjectifiedServiceItemTypeResponse> {

  return Api.getInline<InlineServiceItemTypeResponse>(
    `ServiceItemTypeMaintenance/ServiceItemType/${ServiceItemTypeId}`
  )
    .then((response) => {

      return {
        ...response,
        ServiceItemType: response.ServiceItemType ? toObjectified(response.ServiceItemType) : null
      };
    });
}

export async function createServiceItemType(query: IServiceItemTypeFacade):
  Promise<IServiceItemTypeResponse> {

  return Api.post<IServiceItemTypeFacade, IServiceItemTypeResponse>(
    'ServiceItemTypeMaintenance/CreateServiceItemType', query);
}

export async function deleteServiceItemType(ServiceItemTypeId: string): Promise<IErrorResponse> {
  return Api.delete<IErrorResponse>(`ServiceItemTypeMaintenance/ServiceItemType/${ServiceItemTypeId}`);
}

export async function updatedServiceItemType(ServiceItemTypeId: string, query: IServiceItemTypeFacade): Promise<IObjectifiedServiceItemTypeResponse> {
  return Api.putInline<IServiceItemTypeFacade, InlineServiceItemTypeResponse>(
    `ServiceItemTypeMaintenance/ServiceItemType/${ServiceItemTypeId}`, query)
    .then((response) => {
      return {
        ...response,
        ServiceItemType: response.ServiceItemType ? toObjectified(response.ServiceItemType) : null
      };
    });
}
