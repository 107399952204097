import { call, takeLatest } from 'redux-saga/effects';

import { types as paymentTypes, actions as paymentActions } from 'ducks/salesOrderEnquiry/paymentEntryDetails';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/paymentEntryDetails';

function* getPaymentDetails(action) {
  const { success, failure } = paymentActions.saga.fetchPaymentEntryDetails;
  yield callApi(
    call(api.getPaymentDetails, action.data),
    success,
    failure
  )
}

export default function* rootLineDetailsSaga() {
  yield takeLatest(paymentTypes.fetchPaymentEntryDetails, getPaymentDetails)
}