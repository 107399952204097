import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLines';
import { selectors as invoiceSelector } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';
import { search } from 'api/supplierInvoiceEnquiry/invoiceLines';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'InvoiceLines',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: ((invoiceSelector.selected(state) && invoiceSelector.selected(state).DocumentNumber) || params.get('DocumentNumber')) &&
  {
    DocumentNumber: (invoiceSelector.selected(state) && invoiceSelector.selected(state).DocumentNumber) || params.get('DocumentNumber')
  },
  keyFields: [{ valueField: 'LineNumber', routeField: 'LineNumber' }],
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lineActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
