import { IFormViewForm } from 'components/FormView';

const ProductPriceScheduleSettings: IFormViewForm = {
  id: 'ProductPriceScheduleSettings',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Pricing details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Product price code',
            name: 'ProductPriceCode',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Price per',
            name: 'PricePerDisplay',
            alignment: 'right',
            size: 'small'
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Duty code',
            name: 'DutyCode',
            size: 'small'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Buying details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Buying unit',
            name: 'BuyingUnit',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Unit conversion factor',
            name: 'UnitConversionFactorDisplay',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Buy multiples of',
            name: 'BuyMultiples',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Minimum order quantity',
            name: 'MinimumOrderQuantityDisplay',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supply lead time',
            name: 'SupplyLeadTime',
            alignment: 'right',
            size: 'small'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Miscellaneous details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'On hand',
            name: 'OnHandDisplay',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Weight',
            name: 'WeightDisplay',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Cubic size',
            name: 'CubicSizeDisplay',
            alignment: 'right',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Volume',
            name: 'VolumeDisplay',
            alignment: 'right',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Price update details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Buy price override',
            name: 'BuyPriceOverrideDisplay',
            alignment: 'right',
            adornment: '%',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Base price override',
            name: 'BasePriceOverrideDisplay',
            alignment: 'right',
            adornment: '%',
            size: 'small'
          }
        },
        {
          id: 0,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Retail price override',
            name: 'RetialPriceOverrideDisplay',
            alignment: 'right',
            adornment: '%',
            size: 'small'
          }
        }
      ]
    }
  ]
};
export default ProductPriceScheduleSettings;
