
export const MODULE_TREE = [
    { id: 'ProductPriceSchedule', label: 'Product Price Schedule', parent: '' },
    { id: 'ProductLinks', label: 'Product Links', parent: '' },
    { id: 'SalesDiary', label: 'Diary', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' },
    { id: 'ProductPriceScheduleDetails', label: 'Details', parent: 'ProductPriceSchedule' },
    { id: 'ProductPriceScheduleSellPrices', label: 'Sell Prices', parent: 'ProductPriceSchedule' },
    { id: 'ProductPriceScheduleSettings', label: 'Settings', parent: 'ProductPriceSchedule' },
    { id: 'ProductPriceScheduleComments', label: 'Comments', parent: 'ProductPriceSchedule' },
    { id: 'Notepad', label: 'Notepad', parent: '' },
    { id: 'Attachments', label: 'Attachments', parent: '' },
    { id: 'Documents', label: 'Documents', parent: 'Attachments' },
    { id: 'Links', label: 'Links', parent: 'Attachments' },
    { id: 'Images', label: 'Images', parent: 'Attachments' },
];
