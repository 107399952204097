import { IFormViewForm } from 'components/FormView';

const CreditDiaryDetails: IFormViewForm = {
  id: 'CreditDiaryDetails',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'diary',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 2
      },
      children: [
        {
          id: 1,
          type: 'DIARY_DETAILS',
          props: {
            label: 'CustomerCreditDiary',
            name: 'CustomerCreditDiary',
            createApi: '/CustomerEnquiry/Customer/<CustomerId>/CreateCreditDiary',
            searchByIdApi: '/CustomerEnquiry/Customer/<CustomerId>/CreditDiary/<DiaryId>',
            updateApi: '/CustomerEnquiry/Customer/<CustomerId>/CreditDiary/<DiaryId>',
            deleteApi: '/CustomerEnquiry/Customer/<CustomerId>/CreditDiary/<DiaryId>',
            diaryType: 'CREDIT'
          }
        }
      ]
    }
  ]
};
export default CreditDiaryDetails;
