import {
  createActions, asyncInitialState,
  asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'productionWIP');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Production order', field: 'ProductionOrder', minWidth: 120, },
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Earliest due', field: 'EarliestDue', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Started', field: 'Started', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Required quantity', field: 'RequiredQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Issued quantity', field: 'IssuedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 350, },
    { headerName: 'Production order item', field: 'ProductionOrderItem', minWidth: 200, suppressSorting: true, },
    { headerName: 'Group', field: 'ProductGroup', minWidth: 150, suppressSorting: true, },
    { headerName: 'Item description', field: 'ItemDescription', minWidth: 250, suppressSorting: true, },
    { headerName: 'Responsible person', field: 'User', minWidth: 200, suppressSorting: true, },
    { headerName: 'Name', field: 'UserLabel', minWidth: 250, suppressSorting: true, },
    { headerName: 'Status', field: 'Status', minWidth: 200, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.productionWIP,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.productionWIP.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.productionWIP.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.productionWIP.data.Columns
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)