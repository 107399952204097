import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as RFCDetailTypes, actions as RFCDetailActions } from 'ducks/rfcEnquiry/rfcDetails';

import { callApi } from '../utils';
import * as api from 'api/rfc/rfcDetails';


function* fetch(action){
  const {success, failure} = RFCDetailActions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}

function* fetchSummary(action) {
  const { success, failure } = RFCDetailActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

function* fetchRFCSummary(action) {
  const { success, failure } = RFCDetailActions.saga.fetchRFCSummary;
  yield callApi(
    call(api.fetchRFCSummary, action.data),
    success,
    failure
  )
}

function* fetchComment(action) {
  const { success, failure } = RFCDetailActions.saga.fetchComment;
  yield callApi(
    call(api.fetchComment, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(RFCDetailTypes.fetch, fetch)
  yield takeLatest(RFCDetailTypes.fetchSummary, fetchSummary)
  yield takeLatest(RFCDetailTypes.fetchRFCSummary, fetchRFCSummary)
  yield takeLatest(RFCDetailTypes.fetchComment, fetchComment)
}