import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'inTransitImport');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Shipment', field: 'Shipment', type: 'numericColumn', minWidth: 200, },
    { headerName: 'Line', field: 'LineNo', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'ETA', field: 'ETA', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Buy quantity', field: 'BUQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Unit', field: 'BuyingUnitDescription', minWidth: 100, suppressSorting: true, },
    { headerName: 'Freight type', field: 'FreightType', minWidth: 200, suppressSorting: true, },
    { headerName: 'Carrier', field: 'Carrier', minWidth: 200, suppressSorting: true, },
    { headerName: 'Shipment description', field: 'ShipmentDescription', minWidth: 250, suppressSorting: true, },
    { headerName: 'Purchase order', field: 'PurchaseOrder', minWidth: 120, suppressSorting: true, },
    { headerName: 'Supplier', field: 'Supplier', minWidth: 120, suppressSorting: true, },
    { headerName: 'Supplier name', field: 'SupplierName', minWidth: 200, suppressSorting: true, },
    { headerName: 'Contact', field: 'Contact', minWidth: 200, suppressSorting: true, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 100, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', minWidth: 250, suppressSorting: true, }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    
    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.inTransitImport,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.inTransitImport.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.inTransitImport.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.inTransitImport.data.Columns
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)