import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'
import { isNull } from 'utils/utils';

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  applyFilters: (data) => (data),
  setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
  asyncs: {
  }
}, 'slips');


const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    Warehouse: '',
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
  },
  byProductColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100, },
    {
      headerName: 'Product',
      field: 'ProductCode',
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductCode) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductCode
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Despatch', field: 'Despatch', minWidth: 100, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Site', field: 'SiteCode', minWidth: 100, suppressSorting: true, },
    { headerName: 'Delivery name', field: 'DeliveryName', minWidth: 250, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 250, suppressSorting: true, },
    { headerName: 'Product group', field: 'ProductGroup', suppressSorting: true, minWidth: 100, },
    {
      headerName: 'Pick slip',
      field: 'PickSlip',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.PickSlip) {
          return {
            FieldValue: row.data.PickSlip
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      hideCellRendererInV2: true,
      cellRendererParams: {
        link: '/pick-slip-enquiry/slip-details'
      }
    },
    { headerName: 'Warehouse name', field: 'WarehouseLabel', minWidth: 150, suppressSorting: true, },
  ],
  byDespatchColumns: [
    { headerName: 'Despatch', field: 'Despatch', minWidth: 100, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Site', field: 'SiteCode', minWidth: 100, suppressSorting: true, },
    { headerName: 'Delivery name', field: 'DeliveryName', minWidth: 250, suppressSorting: true, },
    { headerName: 'City', field: 'City', minWidth: 150, suppressSorting: true, },
    { headerName: 'Status', field: 'Status', minWidth: 120, suppressSorting: true, },
    { headerName: 'Carrier', field: 'Carrier', minWidth: 250, suppressSorting: true, },
    { headerName: 'Ship via', field: 'ShipVia', minWidth: 120, suppressSorting: true, },
    {
      headerName: 'Pick slip',
      field: 'PickSlip',
      minWidth: 120,
      hideCellRendererInV2: true,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.PickSlip) {
          return {
            FieldValue: row.data.PickSlip
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/pick-slip-enquiry/slip-details'
      }
    },
    { headerName: 'Warehouse name', field: 'WarehouseLabel', minWidth: 150, suppressSorting: true, },
  ],
  lines: [],
  fetchedLines: [],
  filterRow: {
    formName: 'PickSlipLinesFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
        }
      },
    ]
  },
});

export default (state = initialState, action) => {
  switch (action.type) {

    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    case types.applyFilters:
      const filters = action.data && action.data.filters;
      const { fetchedLines = [] } = state.data;
      const lines = isNull(filters.Warehouse) ? fetchedLines : fetchedLines.filter(l => l.Warehouse === filters.Warehouse);

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: filters.Warehouse
          },
          lines
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.slips,
  {
    lines: data => data.lines,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.slips.loading,
  gridOptions: state => state.salesInvoiceEnquiry.slips.data.gridOptions,
  byProductColumns: state => state.salesInvoiceEnquiry.slips.data.byProductColumns,
  byDespatchColumns: state => state.salesInvoiceEnquiry.slips.data.byDespatchColumns,
  filterRow: (state) => state.salesInvoiceEnquiry.slips.data.filterRow,
  selectedFilters: (state) => state.salesInvoiceEnquiry.slips.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)