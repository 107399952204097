export default [
  { id: 'Lines', label: 'Lines', parent: '' },
  { id: 'OrderDetails', label: 'Order Details', parent: '' },
  { id: 'DeliveryDetails', label: 'Delivery Details', parent: 'Deliveries' },
  { id: 'Fulfillment', label: 'Fulfillment', parent: 'Deliveries' },
  { id: 'LineDetails', label: 'Line Details', parent: 'Lines' },
  { id: 'Deliveries', label: 'Deliveries', parent: '' },
  { id: 'KitComponents', label: 'Kit Components', parent: 'LineDetails' },
  { id: 'ComponentDetails', label: 'Component Details', parent: 'KitComponents' },
  { id: 'ProductCatalogue', label: 'Catalogue', parent: '' },
  { id: 'CatalogueProductDetails', label: 'Product Details', parent: 'ProductCatalogue' },
  { id: 'CatalogueEntryDetails', label: 'Entry Details', parent: 'ProductCatalogue' },
];
