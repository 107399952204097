import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../utils';

export const getInvoiceLineSummary = (query) => {
  const { Invoice, SalesEntity, LineNumber } = query;

  return fetchGet(`SalesInvoiceEnquiry/Entity/${SalesEntity}/SalesInvoice/${Invoice}/${LineNumber}/InvoiceLineSummary`)
    .then((result) => (result && {
      schema: result.InvoiceLineSummary, values: objectify(result.InvoiceLineSummary)
    }));
};

export const getInvoiceLineDetails = (query) => {
  const { Invoice, SalesEntity, LineNumber } = query;

  return fetchGet(`/SalesInvoiceEnquiry/SalesInvoice/${Invoice}/Entity/${SalesEntity}/Line/${LineNumber}`, null, 'Inline')
    .then((result) => (result && {
      schema: result.SalesInvoiceLineDetails, values: objectify(result.SalesInvoiceLineDetails)
    }));
};

export const fetch = async (query: { SalesEntity: string; Invoice: string; Page: number; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { SalesEntity, Invoice, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchGet(
    `SalesInvoiceEnquiry/SalesInvoice/${Invoice}/Entity/${SalesEntity}/InvoiceLines`,
    {
      BatchPage: Page,
      BatchSize
    },
    respType)
    .then(
      (result: {
        SalesInvoiceLines: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.SalesInvoiceLines) {

            return {
              records: result.SalesInvoiceLines.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
