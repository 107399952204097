import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify, objectifyAddress } from '../utils';

export const getDetails = (query) => {
  const { DeliveryId } = query;

  return fetchGet(`/SalesOrderEnquiry/SalesOrder/Delivery/${DeliveryId}/Details`, null, 'Inline')
    .then((result) => {
      const objectified = result ? objectify(result.SalesOrderDeliveryDetail) : {};

      return { schema: result.SalesOrderDeliveryDetail, values: { ...objectified, address: objectifyAddress(objectified, 'Delivery') } };
    });
};

export const fetch = async (query: { SalesOrder: string; Page: number; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { Page, SalesOrder, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchGet(
    `SalesOrderEnquiry/SalesOrder/${SalesOrder}/Deliveries`,
    {
      BatchPage: Page,
      BatchSize
    },
    respType)
    .then(
      (result: {
        SalesOrderDeliveries: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.SalesOrderDeliveries) {

            return {
              records: result.SalesOrderDeliveries.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
