import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    FieldsGrid: {
        'height': '114%',
        'width': '100%',
        '& .dx-datagrid-focus-overlay': {
            border: 'none'
        },
        '& .dx-checkbox-container .dx-checkbox-icon ': {
            width: '20px',
            height: '18px',
            border: '2px solid #333333',
            marginBottom: '1px',
            marginLeft: '5px',
        },
        '& .dx-checkbox-checked .dx-checkbox-icon': {
            color: '#333333',
            border: '2px solid #333333'
        },
        '& .dx-checkbox-indeterminate .dx-checkbox-icon::before': {
            backgroundColor: '#333333',
        },
        '@media screen and (max-width: 1100px), (max-height: 1000px)': {
            height: 300
        }
    },
    IconWithTextstyles: {
        display: 'flex',
        fontSize: 16,
        columnGap: 5
    }
});
