import React, { useEffect, useRef, useState } from 'react';
import DataGrid, { Column, Scrolling, LoadPanel, Button, Editing, Selection } from 'devextreme-react/data-grid';
import FilterRow from 'components/common/DataGridDevEx/FilterRow';
import filterRow from './RecallParkedSaleDialogContent.filterRow';
import { Popup } from 'devextreme-react/popup';
import styles from './RecallParkedSaleDialogContent.styles';
import { withStyles } from '@material-ui/core';
import { recallParkedSale, deleteParkSale, fetchInitialRetrieveParkedSales } from 'api/Worksale/worksale';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { IFilters, IRecallParkedSaleDialogContentHandle, IRecallParkedSaleDialogContentProps } from './RecallParkedSaleDialog.properties';
import { IMessageModelFacade, IParkedSaleFilter } from 'api/swaggerTypes';
import InfoIcon from '@markinson/uicomponents-v2/SvgIcons/Info';
import { isNull } from 'utils/utils';
import classNames from 'classnames';
import { DATAGRIDCONSTANTS } from 'components/Worksale/RecallParkedSaleDialog/RecallParkedSaleDialog.constants';
import { useRetriveParkedSale } from 'api/Worksale/worksaleLatest';
import { ENTER_KEY, ESCAPE, TAB } from 'utils/constants';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useMemoizedKeyHandlers } from 'utils/hooks';
import { HotKeys } from 'react-hotkeys';

const RecallParkedSaleDialogContent = (props: IRecallParkedSaleDialogContentProps, ref: React.Ref<IRecallParkedSaleDialogContentHandle>) => {
    const { classes, worksaleId, recallButtonRef, selectedRow, setSelectedRow, setGridLength, onCancel, onRecall, onGridTabOut, onFilterButtonShiftTab, changeConfirmationDialog } = props;
    const dataGridRef = useRef<DataGrid>();

    const [loading, setLoading] = useState<boolean>(false);
    const [notesOpen, setNotesOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<IFilters>();
    const [filtersValuesSchema, setFiltersValuesSchema] = React.useState<any>({});
    const [note, setNote] = useState<string>('');

    const processValidationForms = React.useContext(ProcessValidationFormsContext);
    const { isLoading, data, mutateAsync } = useRetriveParkedSale();
    const recallParkedSaleApi = async (query) => processValidationForms(await recallParkedSale(query), query, recallParkedSale);

    const filterButtonRef = React.useRef<HTMLButtonElement>();
    const { onKeyDown: gridOnKeyDown, onFocusedCellChanging } = useGridKeyboardNavigation();
    const [defaultFirstRowSelection, setDefaultFirstRowSelection] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            setGridLength(dataGridRef.current?.instance?.getDataSource()?.items().length);
        },
        [dataGridRef.current?.instance?.getDataSource()?.items().length]);

    const recallParkedSaleInquiry = React.useCallback(
        () => {
            setLoading(true);
            recallParkedSaleApi({
                ParkedSaleId: selectedRow?.ParkedSaleId,
                WorkSaleId: worksaleId
            }).then((response) => {
                if (response.Status && isNull(response.Messages)) {
                    onCancel();
                    showSnackBar({ variant: 'success', message: 'Parked Sale recalled successfully.' });
                    onRecall();
                } else {
                    if (!isNull(response.Messages)) {
                        showMessages(response.Messages);
                    }
                    showSnackBar({ variant: 'error', message: 'Failed to recall parked sale.' });
                }

            }).catch((err) => console.warn(err))
                .finally(() => {
                    setLoading(false);
                });
        },
        [selectedRow, worksaleId]
    );

    function showMessages(messages: IMessageModelFacade[]): void {
        const ExceptionDetails = messages.reduce((p, c) => p ? `${p}\n- ${c.MessageDetail}` : `- ${c.MessageDetail}`, '');
        showSnackBar({ variant: 'error', message: 'error', time: 3000, detail: { ExceptionDetails } });
    }

    React.useImperativeHandle(
        ref,
        () => ({
            focusFilterButton,
            focusGrid(): void {
                focusGrid();
            },
            recall: recallParkedSaleInquiry,
        }),
        [selectedRow, recallParkedSaleInquiry]
    );

    React.useEffect(
        () => {
            setDefaultFirstRowSelection(true);
        },
        []
    );

    useEffect(
        () => {
            setLoading(true);
            fetchInitialRetrieveParkedSales()
                .then((response) => {
                    const { InitialisedParkedSales } = response;
                    if (InitialisedParkedSales) {
                        const _filters = {
                            CustomerId: InitialisedParkedSales.inlineObject.CustomerId,
                            Username: InitialisedParkedSales.inlineObject.Username,
                            UserID: InitialisedParkedSales.inlineObject.UserID,
                            SalesEntity: InitialisedParkedSales.inlineObject.Entity,
                            ShowParkedSales: InitialisedParkedSales.inlineObject.ShowParkedSales,
                        };

                        setFilters(_filters);
                        setFiltersValuesSchema(InitialisedParkedSales?.schema);
                        filterOnApply({ filters: _filters });
                    }
                }).catch((err) => console.warn(err))
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    useEffect(
        () => {
            if (loading || isLoading) {
                if (dataGridRef.current) {
                    dataGridRef.current.instance.beginCustomLoading('');
                }
            } else {
                dataGridRef.current.instance.endCustomLoading();
            }
        },
        [loading, isLoading]
    );

    const onEscape = React.useCallback(
        () => {
            if (onCancel) {
                onCancel();
            }
        },
        [onCancel]
    );

    const onEnter = React.useCallback(
        () => {
            if (recallButtonRef?.current &&
                dataGridRef.current.instance?.$element()?.[0]?.contains(document.activeElement)) {
                recallParkedSaleInquiry();
            }
        },
        [dataGridRef?.current, recallParkedSaleInquiry]
    );

    const handlers = useMemoizedKeyHandlers({
        Escape: onEscape,
        Enter: onEnter
    });

    const focusFilterButton = React.useCallback(
        () => {
            if (filterButtonRef.current) {
                filterButtonRef.current.focus();
            }
        },
        [filterButtonRef.current]
    );

    const handleKeyDown = React.useCallback(
        (e) => {

            const keyboardEvent = e.event as KeyboardEvent;

            if (keyboardEvent.keyCode === TAB && !keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        onGridTabOut();
                    },
                    0
                );

                return;
            }

            if (keyboardEvent.keyCode === TAB && keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        focusFilterButton();
                    },
                    0
                );

                return;
            }

            if (keyboardEvent.keyCode === ESCAPE) {
                onEscape();

                return;
            }

            if (keyboardEvent.keyCode === ENTER_KEY) {
                onEnter();

                return;
            }

            gridOnKeyDown(e);
        },
        [gridOnKeyDown, onEscape, onEnter, focusFilterButton]
    );

    const focusAndSelectFirstRowGrid = React.useCallback(
        () => {
            if (!dataGridRef.current) {
                return;
            }

            dataGridRef.current?.instance?.getScrollable()?.scrollTo(0);
            focusGrid();

            if (dataGridRef.current?.instance?.getDataSource()?.items()?.length > 0) {
                dataGridRef.current?.instance?.selectRowsByIndexes([0]);
            }
            setDefaultFirstRowSelection(false);
        },
        [dataGridRef.current, setDefaultFirstRowSelection]
    );

    const focusGrid = () => {
        setTimeout(
            () => {
                dataGridRef.current?.instance.focus();
            },
            0);
    };

    const onContentReady = React.useCallback(
        () => {
            if (defaultFirstRowSelection) {
                focusAndSelectFirstRowGrid();
            }
        },
        [defaultFirstRowSelection, focusAndSelectFirstRowGrid]
    );

    const filterOnApply = async ({ filters: appliedFilters }: { formName?: string; filters: IFilters }) => {
        const _appliedFilters = { ...appliedFilters, CustomerID: appliedFilters.CustomerId };
        setFilters(appliedFilters);
        if (appliedFilters) {
            await mutateAsync(_appliedFilters as IParkedSaleFilter);
            setDefaultFirstRowSelection(true);
        }
    };

    const infoButtonOnClick = (rowData) => {
        setNote(rowData.data.Note);
        setNotesOpen(true);
    };

    const removeRow = (rowData) => {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the Selected record?',
            okLabel: 'Delete',
            onCancel: () => null,
            onOk: async () => {
                const result = await deleteParkSale(rowData.row.data.ParkedSaleId);
                if (result.Status) {
                    await mutateAsync({ CustomerID: filters.CustomerID, UserID: filters.UserID, Username: filters.Username, SalesEntity: filters.SalesEntity, ShowParkedSales: filters.ShowParkedSales });
                }
            },
        });
    };

    const handleOnSelectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e.selectedRowsData?.[0] ?? {};
            setSelectedRow(selectedRowData);
        },
        [setSelectedRow]
    );

    return (
        <HotKeys handlers={handlers}>
            <div className={classes.recallParkedSaleContainer}>
                <FilterRow
                    filterButtonRef={filterButtonRef}
                    disableInitialApplyCall={true}
                    parameters={filterRow.parameters}
                    formName={filterRow.formName}
                    onApplyFilters={filterOnApply}
                    initialValues={{ ...filters }}
                    valuesSchema={filtersValuesSchema}
                    validate={filterRow.validate}
                    rowModelType={filterRow.rowModelType}
                    onFilterButtonShiftTab={onFilterButtonShiftTab} />
                <DataGrid
                    ref={dataGridRef}
                    keyExpr={'ParkedSaleId'}
                    height={DATAGRIDCONSTANTS.GRID_HEIGHT}
                    allowColumnReordering={true}
                    dataSource={data?.ParkedSales ?? []}
                    repaintChangesOnly={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnResizing={true}
                    noDataText=''
                    showBorders={false}
                    onKeyDown={handleKeyDown}
                    onFocusedCellChanging={onFocusedCellChanging}
                    onContentReady={onContentReady}
                    onSelectionChanged={handleOnSelectionChanged}
                    sorting={{
                        mode: 'none'
                    }}
                    onCellPrepared={(e) => {
                        const UserIDHeader = 2;
                        const EnteredHeader = 4;
                        if (e.rowType === 'header' && e.columnIndex === UserIDHeader) {
                            e.cellElement.style.textAlign = 'center';
                        }

                        if (e.rowType === 'header' && e.columnIndex === EnteredHeader) {
                            e.cellElement.style.textAlign = 'left';
                        }
                    }}
                >
                    <Editing
                        refreshMode={'reshape'}
                        mode='row'
                        allowAdding={false}
                        allowDeleting={true}
                        confirmDelete={true}
                        allowUpdating={false}
                    />
                    <Scrolling mode='virtual' />
                    <Column
                        caption={'Inquiry'}
                        dataField={'ParkedSaleId'}
                        minWidth={'20%'}
                        width={DATAGRIDCONSTANTS.header.InquiryColumn}
                    />
                    <Column
                        caption={'User ID'}
                        dataField={'User'}
                        minWidth={'7%'}
                        width={DATAGRIDCONSTANTS.header.UserColumn}
                    />
                    <Column
                        caption={'User name'}
                        dataField={'Username'}
                        width={DATAGRIDCONSTANTS.header.UsernameColumn}
                    />
                    <Column
                        caption={'Entered'}
                        dataField={'Entered'}
                        minWidth={'15%'}
                        alignment='center'
                        width={DATAGRIDCONSTANTS.header.EnteredColumn}
                    />
                    <Column
                        caption={'Time'}
                        dataField={'TimeDisplay'}
                        alignment='center'
                        minWidth={'10%'}
                        width={DATAGRIDCONSTANTS.header.TimeColumn}
                    />
                    <Column
                        caption={'Delivery name'}
                        dataField={'CustomerName'}
                        width={DATAGRIDCONSTANTS.header.DeliveryColumn}
                        minWidth={'30%'}
                    />
                    <Column
                        caption={'Notes'}
                        dataField={'Note'}
                        minWidth={'30%'}
                        width={DATAGRIDCONSTANTS.header.NotesColumn}
                    />
                    <Column type={'buttons'} width={DATAGRIDCONSTANTS.header.ButtonsColumn} allowResizing={false}>
                        <Button render={
                            (rowData) => <InfoIcon
                                className={classNames(classes.infoButton, !rowData.data.Notes && classes.disabled)}
                                onClick={() => (rowData.data.Notes) ? infoButtonOnClick(rowData) : undefined} />
                        } />
                        <Button name={'delete'} icon={'trash'} onClick={removeRow} />
                    </Column>
                    <LoadPanel
                        shading={false}
                        height={DATAGRIDCONSTANTS.LOADING_PANEL_WIDTH_HEIGHT}
                        width={DATAGRIDCONSTANTS.LOADING_PANEL_WIDTH_HEIGHT}
                        text={''}
                        showPane={false}
                    />
                    <Selection mode={'single'} />
                </DataGrid>
                <Popup
                    visible={notesOpen}
                    title={'Notes'}
                    width={'auto'}
                    height={'auto'}
                    hideOnOutsideClick
                    onVisibleChange={setNotesOpen}
                    contentRender={() => {
                        return (<p className={classes.popup}>
                            {note}
                        </p>);
                    }}
                />
            </div>
        </HotKeys>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(RecallParkedSaleDialogContent)));
