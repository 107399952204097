import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';
import { Table, TableBody, TableRow, TableCell, Dialog, DialogTitle, DialogActions, List, ListItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import BillToIdActionList from '../../../utils/data/db/actions/BillToIdActionList';
import { ISimpleDialogProperties, ISummaryPanelProperties, IActionListItem } from './SummaryPanel.properties';
import {
  styles, inlineStyles
} from './SummaryPanel.styles';
import { isNull } from 'utils/utils';
import * as api from 'api/Worksale/worksale';
import FocusLock from 'react-focus-lock';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';

const SimpleDialog = (props: ISimpleDialogProperties): JSX.Element => {
  const { classes, onClose, message, ...other } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} {...other}
      classes={{
        paper: classes.dialogStyles
      }}
    >
      <DialogTitle>Credit Message</DialogTitle>
      <FocusLock>
        <>
          <List>
            {
              message.split('\n').map((item: string) =>
                <ListItem key={item}>
                  <ListItemText primary={item}
                    classes={{ primary: classes.creditStatus }} />
                </ListItem>
              )
            }
          </List>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' autoFocus
              classes={{ raised: classes.button }}>
              OK
            </Button>
          </DialogActions>
        </>
      </FocusLock>

    </Dialog >
  );
};

const SimpleDialogWrapped = withStyles(styles, { index: 1 })(SimpleDialog);

const numberOfInvoices = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return customerStatus && customerStatus.Invoices && customerStatus.Invoices.Value ? customerStatus.Invoices.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0';
};

const numberOfEnquiries = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return customerStatus && customerStatus.Enquiries && customerStatus.Enquiries.Value ? customerStatus.Enquiries.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0';
};

const numberOfOrders = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return customerStatus && customerStatus.Orders && customerStatus.Orders.Value ? customerStatus.Orders.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0';
};

const numberOfQuotes = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return customerStatus && customerStatus.Quotes && customerStatus.Quotes.Value ? customerStatus.Quotes.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0';
};

const formatAvailableCredit = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return (Number.isFinite(Number(customerStatus.AvailableCredit.Value))) ?
    Number(customerStatus.AvailableCredit.Value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) :
    customerStatus.AvailableCredit.Value;
};

const availableCredit = (customerStatus: api.InlineWorkSaleSummary['CustomerStatus']): string => {
  return customerStatus && !isNull(customerStatus.AvailableCredit) ? formatAvailableCredit(customerStatus) : '';
};

const customer = (
  classes: any,
  saleHeader: api.InlineWorkSaleSummary['SaleHeader'],
  actionList: IActionListItem[],
  isCustomerRequired: boolean,
  cusReqWarningOnClick
): React.ReactNode => {

  return (
    <div className={classes.splitCellContainer} >
      <div className={classes.splitCellContent} >
        {saleHeader?.CustomerId?.Display && (
          <>
            <span >{saleHeader?.CustomerId?.Display}</span>
            <br />
          </>)}
        <span >{saleHeader?.CustomerId?.Label}</span>
      </div>
      <div className={classes.splitCellAction} >
        {(actionList && !isCustomerRequired) && <InfoButton actionList={actionList}
          disabled={!(saleHeader?.CustomerId?.Value)}
        />}
        {isCustomerRequired && <WarningIcon
          className={classes.iconWarning}
          onClick={cusReqWarningOnClick} />
        }
      </div>
    </div>);
};

const purchaseOrder = (saleHeader: api.InlineWorkSaleSummary['SaleHeader']): string => {
  return saleHeader && saleHeader.PurchaseOrder ? saleHeader.PurchaseOrder.Value : '';
};

const siteCode = (saleHeader: api.InlineWorkSaleSummary['SaleHeader']): React.ReactNode => {

  return (
    <span>
      <span>{saleHeader && saleHeader.SiteCode ? saleHeader.SiteCode.Value : ''}</span>
      <br />
      <span>{saleHeader && saleHeader.City ? saleHeader.City.Value : ''}{saleHeader && saleHeader.State && (saleHeader.State.Value ?? '' !== '') ? ', '.concat(saleHeader.State.Value) : ' '}</span>
    </span>
  );
};

const saleEntity = (saleHeader: api.InlineWorkSaleSummary['SaleHeader']): React.ReactNode => {
  return (
    <span>
      <span>{saleHeader && saleHeader.SalesEntity ? saleHeader.SalesEntity.Value : ''}</span>
      <br />
      <span>{saleHeader && saleHeader.SalesEntity ? saleHeader.SalesEntity.Description : ''}</span>
    </span>
  );
};

const period = (saleHeader: api.InlineWorkSaleSummary['SaleHeader']): string => {
  return saleHeader && saleHeader.Period ? saleHeader.Period.Display : '';
};

const enterDate = (saleHeader: api.InlineWorkSaleSummary['SaleHeader']): string => {
  return saleHeader && saleHeader.EnterDate ? saleHeader.EnterDate.Value : '';
};

const grossProfitPercentage = (saleTotals: api.InlineWorkSaleSummary['SaleTotals']): string => {
  return saleTotals && !isNull(saleTotals.GrossProfitPercentage) ? Number(saleTotals.GrossProfitPercentage.Value).toLocaleString(undefined, { minimumFractionDigits: NumberOfDecimalPlaces, maximumFractionDigits: NumberOfDecimalPlaces }) : '0.00';
};

const orderTotal = (saleTotals: api.InlineWorkSaleSummary['SaleTotals']): string => {
  return saleTotals && !isNull(saleTotals.OrderTotal) ? Number(saleTotals.OrderTotal.Value).toLocaleString(undefined, { minimumFractionDigits: NumberOfDecimalPlaces, maximumFractionDigits: NumberOfDecimalPlaces }) : '0.00';
};

const taxAmount = (saleTotals: api.InlineWorkSaleSummary['SaleTotals']): string => {
  return saleTotals && !isNull(saleTotals.TaxAmount) ? Number(saleTotals.TaxAmount.Value).toLocaleString(undefined, { minimumFractionDigits: NumberOfDecimalPlaces, maximumFractionDigits: NumberOfDecimalPlaces }) : '0.00';
};

const invoiceTotal = (saleTotals: api.InlineWorkSaleSummary['SaleTotals']): string => {
  return saleTotals && !isNull(saleTotals.InvoiceTotal) ? saleTotals.InvoiceTotal.Value.toLocaleString(undefined, { minimumFractionDigits: NumberOfDecimalPlaces, maximumFractionDigits: NumberOfDecimalPlaces }) : '0.00';
};

const SubPanelElevation = 2;
const CreditColumnSpan = 2;
const NumberOfDecimalPlaces = 2;

const SummaryPanelComponent = ({ classes, cusReqWarningOnClick }: ISummaryPanelProperties) => {
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const { data: currentWorksaleSummaryData } = api.useFetchCurrentWorksaleSummary(worksaleId);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const customerStatus = React.useMemo(() => (currentWorksaleSummaryData?.WorksaleSummary?.CustomerStatus?.schema || undefined), [currentWorksaleSummaryData]);
  const saleHeader = React.useMemo(() => (currentWorksaleSummaryData?.WorksaleSummary?.SaleHeader?.schema || undefined), [currentWorksaleSummaryData]);
  const saleTotals = React.useMemo(() => (currentWorksaleSummaryData?.WorksaleSummary?.SaleTotals?.schema || undefined), [currentWorksaleSummaryData]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onCreditStatusCellClick = () => {
    handleDialogOpen();
  };

  const appendParam = (data: any, actionListItems: IActionListItem[]): IActionListItem[] => {
    if (data && data?.CustomerId && data.CustomerId.Value) {
      return actionListItems.map((item) => {
        item.actionParam = { CustomerId: data.CustomerId.Value, CustomerAccountNumber: data.CustomerId.Display };

        return item;
      });
    }

    return actionListItems;
  };

  const table1HeaderRow = classNames(
    classes.table1HeaderRow,
    classes.table1TD
  );

  const table1Headers = classNames(
    classes.headers,
    classes.headerCell
  );

  const table1DataRow = classNames(
    classes.table1DataRow,
    classes.table1TD
  );

  const creditStatusCell = customerStatus && customerStatus.CreditStatus && (customerStatus.CreditStatus.Value === 'Alert' || customerStatus.CreditStatus.Value === 'Block') ?
    classNames(classes.dataCell, classes.creditStatusCell, classes.creditStatusCellHover) :
    classes.dataCell;

  const actionList = BillToIdActionList;
  const isCustomerRequired = saleHeader?.CustomerId?.Label?.trim().toLowerCase() === 'customer required';

  return (
    <div>
      <Paper elevation={0} className={classes.mainPanel} square={true}>
        <Scrollbars>
          <Paper elevation={SubPanelElevation} className={classes.subPanel} square={true}>
            <p className={classes.subPanelTitle}>Customer status</p>
            <Table className={classes.summaryTable}>
              <TableBody>
                <TableRow className={table1HeaderRow}>
                  <TableCell className={table1Headers}>QUOTES</TableCell>
                  <TableCell className={table1Headers}>ORDERS</TableCell>
                  <TableCell className={table1Headers}>ENQUIRIES</TableCell>
                  <TableCell className={table1Headers} padding='none'>INVOICES</TableCell>
                </TableRow>
                <TableRow className={table1DataRow}>
                  <TableCell className={classes.dataCell}>{numberOfQuotes(customerStatus)}</TableCell>
                  <TableCell className={classes.dataCell}>{numberOfOrders(customerStatus)}</TableCell>
                  <TableCell className={classes.dataCell}>{numberOfEnquiries(customerStatus)}</TableCell>
                  <TableCell className={classes.dataCell} padding='none'>{numberOfInvoices(customerStatus)}</TableCell>
                </TableRow>
                <TableRow className={table1HeaderRow}>
                  <TableCell colSpan={CreditColumnSpan} className={table1Headers}>CREDIT STATUS</TableCell>
                  <TableCell colSpan={CreditColumnSpan} className={table1Headers} padding='none'>AVAILABLE CREDIT</TableCell>
                </TableRow>
                <TableRow className={classes.table1DataRow}>
                  <TableCell
                    colSpan={CreditColumnSpan}
                    className={creditStatusCell}
                  >
                    <div style={{ position: 'relative', height: '100%', textAlign: 'center' }} >
                      {customerStatus && customerStatus.CreditStatus && (customerStatus.CreditStatus.Value === 'Alert' || customerStatus.CreditStatus.Value === 'Blocked') ?
                        <WarningIcon
                          className={classes.iconWarning}
                          style={{ right: '45', position: 'absolute', top: '-2px' }}
                          onClick={customerStatus && customerStatus.CreditStatus && (customerStatus.CreditStatus.Value === 'Alert' || customerStatus.CreditStatus.Value === 'Blocked')
                            ? onCreditStatusCellClick : null} />
                        : 'OK'}
                    </div>
                  </TableCell>
                  <TableCell numeric colSpan={CreditColumnSpan} className={classes.dataCell} padding='none' style={inlineStyles.numericDataCell}>
                    {availableCredit(customerStatus)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <Paper elevation={SubPanelElevation} className={classes.centerPanel} square={true}>
            <p className={classes.subPanelTitle}>Sale details</p>
            <Table className={classes.summaryTable}>
              <TableBody>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>CUSTOMER</TableCell>
                  <TableCell className={classNames(classes.dataCell, { [classes.emphasizedCell]: isCustomerRequired })}>{customer(
                    classes,
                    saleHeader,
                    actionList ? appendParam(saleHeader, actionList) : null,
                    isCustomerRequired,
                    cusReqWarningOnClick
                  )}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>CUSTOMER P/ORDER</TableCell>
                  <TableCell className={classes.dataCell}>{purchaseOrder(saleHeader)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>ENTITY</TableCell>
                  <TableCell className={classes.dataCell}>{saleEntity(saleHeader)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>PERIOD</TableCell>
                  <TableCell className={classes.dataCell}>{period(saleHeader)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>DATE</TableCell>
                  <TableCell className={classes.dataCell}>{enterDate(saleHeader)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>DEFAULT DELIVERY</TableCell>
                  <TableCell className={classes.dataCell}>{siteCode(saleHeader)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          <Paper elevation={SubPanelElevation} className={classes.subPanelLastChild} square={true}>
            <p className={classes.subPanelTitle}>Sale totals</p>
            <Table className={classes.summaryTable}>
              <TableBody>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>GP</TableCell>
                  <TableCell numeric padding='none' className={classes.numericCell}>{grossProfitPercentage(saleTotals)} %</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>ORDER TOTAL</TableCell>
                  <TableCell numeric padding='none' className={classes.numericCell}>{orderTotal(saleTotals)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>TAX</TableCell>
                  <TableCell numeric padding='none' className={classes.numericCell}>{taxAmount(saleTotals)}</TableCell>
                </TableRow>
                <TableRow className={classes.summaryRow}>
                  <TableCell className={classes.headingCell}>INVOICE TOTAL</TableCell>
                  <TableCell numeric padding='none' className={classNames(classes.totalCell, classes.emphasizedCell)}>{invoiceTotal(saleTotals)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Scrollbars>
      </Paper>
      <SimpleDialogWrapped open={dialogOpen} onClose={handleDialogClose} message={(customerStatus && customerStatus.CreditMessage && customerStatus.CreditMessage.Value) || ''} />
    </div>
  );
};

export default withStyles(inlineStyles, { index: 1 })(SummaryPanelComponent);
