
import * as React from 'react';
import { HotKeyNames } from './constants';
import { getElementByInnerText, getKeyByValue } from './utils';
import { RouteComponentProps } from 'react-router';
import { IActionBarProps } from './ActionBarContextProvider';
import { isAnyDialogOpened } from './helpers';

export const useElementSize = (ref?: React.MutableRefObject<any>, initialSize: { width?: number; height?: number } = { width: 0, height: 0 }) => {

    const [elementSize, setElementSize] = React.useState<{ width?: number; height?: number }>(initialSize);
    React.useEffect(
        () => {
            const handleResize = () => {
                const size = {
                    height: ref?.current?.offsetHeight ?? window.innerHeight,
                    width: ref?.current?.offsetWidth ?? window.innerWidth
                };
                setElementSize(size);
            };
            window.addEventListener('resize', handleResize);
            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        },
        []);

    return elementSize;
};

type KeyHandlersMap = Partial<Record<HotKeyNames, (event: KeyboardEvent) => void>>;

// it returns handlers which you need to pass in HotKeys HOC
export const useMemoizedKeyHandlers = (keyHandles: KeyHandlersMap) => React.useMemo(
    () => (keyHandles),
    [keyHandles]
);

export interface ILocationState {
    search?: any;
    state?: any;
}

export const useModule = (
    history: RouteComponentProps['history'],
    location: RouteComponentProps['location'],
    defaultSelectedTab: string,
    tabLocations: any,
    modules: any[]
) => {
    const [selectedTab, setSelectedTab] = React.useState<string>(defaultSelectedTab);

    const handleModuleChange = React.useCallback(
        (newSelectedTab: string, locationObj?: ILocationState): void => {

            const pathname = (typeof tabLocations[newSelectedTab] === 'string') ? tabLocations[newSelectedTab] : tabLocations[newSelectedTab]?.pathname;
            const search = locationObj?.search ? `?${new URLSearchParams(locationObj?.search)}`
                : tabLocations[newSelectedTab]?.search ? `?${new URLSearchParams(tabLocations[newSelectedTab]?.search)}` : undefined;

            if (pathname) {
                history.push({
                    pathname,
                    search,
                    state: locationObj?.state ?? undefined
                });
            }

        },
        [tabLocations, history]
    );

    React.useEffect(
        () => {
            const pathname = location ? location.pathname : '';
            const tabByLocation = getKeyByValue(tabLocations, pathname);
            setSelectedTab(tabByLocation);
        },
        [location]);

    const navigateBack = React.useCallback(
        () => {
            const currentTabOnBackModule = modules.find((m) => m.id === selectedTab)?.onBack;
            if (currentTabOnBackModule) {
                handleModuleChange(currentTabOnBackModule);
            }
        },
        [modules, selectedTab, handleModuleChange]
    );

    return {
        selectedTab,
        setSelectedTab,
        handleModuleChange,
        navigateBack,
    };
};

/**
 * Returns onClick handler function which will call the passed callback when passed condition is satisfied.
 * Used for asynchronous click operation, where we need to wait for some promise to fullfil before calling click.
 * @param {Function} onClickCallback: onClickCallback callback function which you wanna call when condition met.
 * @param {Boolean} condition: boolean value based upon which onClickCallback will be called.
 * @returns {Function}
 */
export const useAsyncClickHandler = (onClickCallback: () => void, condition: boolean) => {
    const [clicked, setClicked] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            if (condition && clicked && onClickCallback) {
                onClickCallback();
                setClicked(false);
            }
        },
        [condition, clicked, onClickCallback]
    );
    const onClick = React.useCallback(
        () => {
            setClicked(true);
        },
        []
    );

    return { clicked, onClick };
};

export const useActionBarHotKeys = (actionBar: IActionBarProps['actionBar']) => {
    const buttonHotkeysMeta = React.useMemo(() => actionBar?.leftIcons?.concat(actionBar?.centerIcons, actionBar?.rightIcons)?.map((b) => ({ label: b?.label, hotkey: b?.hotkey })), [actionBar]);

    const validateAndCickButton = (innerText: string) => (e) => {
        e?.preventDefault();
        if (isAnyDialogOpened()) {
            return;
        }

        const parkSaleButton = getElementByInnerText('span', innerText);

        const buttonElement = parkSaleButton?.parentElement?.parentElement as HTMLButtonElement;
        if (buttonElement && !buttonElement?.disabled) {
            buttonElement.click();
        }
    };

    const handlers = React.useMemo(
        () => buttonHotkeysMeta.reduce(
            (acc, { label, hotkey }) => {

                if (label && hotkey) {
                    return { ...acc, [hotkey?.toString()]: validateAndCickButton(label) };
                }

                return acc;
            },
            {}
        ),
        [buttonHotkeysMeta]
    );

    return { handlers };

};
