import { connect } from 'react-redux';
import PurchaseOrderLineDetails from './PurchaseOrderLineDetails';
import { getFormValues } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'PurchaseOrderLineDetailsForm';
const mapStateToProps = (state: IApplicationState) => ({
    formValues: getFormValues(FORM_NAME)(state),
});

export default connect(mapStateToProps, null)(PurchaseOrderLineDetails);
