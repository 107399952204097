import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import React from 'react';
import SearchScreen from '@markinson/uicomponents-v2/SearchScreen';
import { IServicePatternSearchFacade } from 'api/swaggerTypes';
import { fetchServicePattern, fetchServicePatternOptions } from 'api/servicePatternMaintenance/servicePattern';
import { isNull } from 'utils/utils';
import IServiceAgreementItemModalProps, { IServiceAgreementItemViewHandle, IServiceAgreementScheduleDetailsFacadeExtension } from './ServiceAgreementItemModal.properties';
import ServiceAgreementItemView from './ServiceAgreementItemView';
import { Column } from 'devextreme-react/data-grid';
import { first } from 'lodash';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const ServiceAgreementItemModal = (props: IServiceAgreementItemModalProps) => {
    const {
        open,
        data,
        loading = false,
        agreementFormValues,
        onSubmit,
        onClose,
    } = props;

    const serviceItemViewRef = React.useRef<IServiceAgreementItemViewHandle>();

    const [openSearchScreen, setOpenSearchScreen] = React.useState<boolean>(false);
    const [servPatternData, setServPatternData] = React.useState<IServicePatternSearchFacade[]>(null);
    const [servAgreementScheduleData, setServAgreementScheduleData] = React.useState<IServiceAgreementScheduleDetailsFacadeExtension>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [fieldsTouched, setFieldsTouched] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            setServAgreementScheduleData(data);
        },
        [data]
    );

    React.useEffect(
        () => {
            setIsLoading(loading);
        },
        [loading]
    );

    function newJobActions(): IActionItem[] {
        return [
            {
                iconName: 'Forward',
                title: 'Load Pattern',
                angleOfRotation: 270,
                alignment: 'left',
                listener: () => {
                    setOpenSearchScreen(true);
                }
            },
            {
                title: 'Cancel',
                iconName: 'Cancel',
                listener: () => {
                    if (onClose) {
                        onClose();
                    }
                }
            },
            {
                title: 'Ok',
                iconName: 'CheckCircle',
                listener: () => {
                    setFieldsTouched(true);
                    if (isNull(servAgreementScheduleData?.Items)) {
                        showSnackBar({ variant: 'error', message: 'Service Pattern Items required.' });

                        return;
                    }

                    setTimeout(
                        () => {
                            const hasError = serviceItemViewRef.current.hasError();
                            if (onSubmit && serviceItemViewRef.current && !hasError) {
                                onSubmit(servAgreementScheduleData);
                            }
                        },
                        0
                    );
                }
            }
        ];
    }

    function getServicePatterns(SearchText: string): void {
        setIsLoading(true);
        fetchServicePatternOptions({ SearchText })
            .then((response) => {
                setIsLoading(false);
                const options = response.Data;
                if (!isNull(options)) {
                    const patterns = options.map((option) => {
                        return {
                            ServicePatternId: Number(option.Code),
                            Name: option.Display,
                            Description: option.Label,
                            FrequencyValue: option.Details && option.Details.FrequencyValue,
                            FrequencyType: option.Details && option.Details.FrequencyType && option.Details.FrequencyType.Display,
                        };
                    });

                    setServPatternData(patterns);
                }

            }).catch((err) => {
                console.warn(err);
            })
            .finally(() => setIsLoading(false));
    }

    function handleClose(rowData: IServicePatternSearchFacade): void {
        if (!isNull(rowData)) {
            setIsLoading(true);
            fetchServicePattern(rowData.ServicePatternId)
                .then((response) => {
                    if (!isNull(response.ServicePatternDetails)) {
                        const { FrequencyValue, FrequencyType, Items, ServicePatternId } = (response.ServicePatternDetails && response.ServicePatternDetails.inlineObject) || {};
                        const firstItem = first(Items) as any;
                        const defaultNextSequenceNumber = firstItem && firstItem.SequenceNumber;
                        const defaultNextJob = firstItem && firstItem.TemplateCode;
                        const defaultNextJobLabel = firstItem && firstItem.TemplateCodeLabel;

                        setServAgreementScheduleData((PrevServAgreementScheduleData) => ({
                            ...PrevServAgreementScheduleData,
                            FrequencyValue,
                            FrequencyType,
                            ServicePatternId: ServicePatternId,
                            NextSequenceNumber: defaultNextSequenceNumber,
                            TemplateCode: defaultNextJob,
                            TemplateCodeLabel: defaultNextJobLabel,
                            Items
                        }));
                    }
                })
                .finally(() => setIsLoading(false));
        }
        setOpenSearchScreen(false);
    }

    return (
        <React.Fragment>
            <FormViewModal
                open={open}
                loading={isLoading}
                title={'Service agreement item'}
                modalContent={
                    <ServiceAgreementItemView
                        CustomerId={agreementFormValues.Customer}
                        forwardedRef={serviceItemViewRef}
                        data={servAgreementScheduleData}
                        setData={setServAgreementScheduleData}
                        loading={isLoading}
                        fieldsTouched={fieldsTouched}
                    />}
                actions={newJobActions()}
            />
            <SearchScreen
                open={openSearchScreen}
                title={'Load Pattern'}
                dataGridKey={'ServicePatternId'}
                loading={false}
                callSearchLookupOnOpen={true}
                onClose={handleClose}
                hideLookupSearchOption={true}
                data={servPatternData as any}
                onLookup={getServicePatterns}
            >
                <Column caption='Pattern Name' dataField='Name' />
                <Column caption='Pattern Description' dataField='Description' />
                <Column caption='Frequency' dataField='FrequencyValue' alignment={'left'} />
                <Column caption='Type' dataField='FrequencyType' />
            </SearchScreen>
        </React.Fragment>
    );
};

export default ServiceAgreementItemModal;
