import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils'

export const search = (query) => {
  const { ProductId, Page, filters, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchPost(`InventoryEnquiry/Product/${ProductId}/SerialDetails`, filters && {
    Warehouse: filters.Warehouse,
  }, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize }, 'Inline')
    .then((result) => (result && result.StockingSerialss &&
    {
      records: result.StockingSerialss.map((val) => (objectify(val))),
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
    })
      ||
    {
      records: [],
      nextPage: false,
      currPage: 1,
    });
}
