import { call, Effect, takeLatest } from 'redux-saga/effects';

import { IDataAction } from 'ducks/utils';
import { types as binTypes, actions as binActions } from 'ducks/pickSlips/binLocations';

import { callApi } from '../utils';
import * as api from 'api/pickSlips/binLocations';

function* fetchBinLocations(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.fetchBinLocations;

    yield callApi(
        call(api.fetchBinLocations, action.data),
        success,
        failure
    );
}

function* fetchBinLocationsSummary(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.fetchBinLocationsSummary;

    yield callApi(
        call(api.fetchBinLocationsSummary, action.data),
        success,
        failure
    );
}

function* saveBinlocation(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.saveBinlocation;

    function* postApiSuccessCallEffect(): IterableIterator<Effect> {
        yield call(fetchBinLocations, action);
    }

    yield callApi(
        call(api.saveBinlocation, action.data),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

export default function* rootBinLocationsSaga(): IterableIterator<Effect> {
    yield takeLatest(binTypes.fetchBinLocations, fetchBinLocations);
    yield takeLatest(binTypes.fetchBinLocationsSummary, fetchBinLocationsSummary);
    yield takeLatest(binTypes.saveBinlocation, saveBinlocation);
}
