import { IObjectified } from '../utils';
import { IRostertemplateSearchResponse, ISortOrder, ISearchRequest, IRostertemplateSearchFacade, IRostertemplateDetailsResponse, IRostertemplateDetailFacade } from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { isNull } from 'utils/utils';

export async function search(request: { SearchText: string; TechnicianId: number; Sort: ISortOrder; BatchSize?: number }): Promise<{
  records: IObjectified<IRostertemplateSearchFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { SearchText, TechnicianId, Sort, BatchSize = DEFAULT_PAGE_SIZE } = request;
  if (isNull(TechnicianId)) {
    return {
      records: [],
      currPage: 1,
      nextPage: false,
      prevPage: false,
    };
  }

  return Api.postInlineCollectionResponse<ISearchRequest, IRostertemplateSearchResponse, 'RosterTemplates'>(
    `RostertemplateMaintenance/Technician/${TechnicianId}/SearchRostertemplates`, { SearchText, Sort, BatchSize }
  ).then((response) => {
    const records = asObjectifiedArray(response, 'RosterTemplates');
    const batchInformation = (response as unknown as IRostertemplateSearchResponse).BatchInformation;

    return {
      records,
      currPage: batchInformation ? batchInformation.BatchPage : 1,
      nextPage: batchInformation && !batchInformation.EndOfData,
      prevPage: batchInformation && batchInformation.BatchPage > 1
    };
  });
}

export async function searchById(query: { RosterTemplateId: number; TechnicianId: number }): Promise<{
  records: IObjectified<IRostertemplateDetailFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { RosterTemplateId, TechnicianId } = query;

  return Api.getInlineResponse<IRostertemplateDetailsResponse, 'RosterTemplateDetails'>(
    `RostertemplateMaintenance/Technician/${TechnicianId}/Rostertemplate/${RosterTemplateId}`)
    .then((response) => {
      const objectifiedResponse = asObjectified(response, 'RosterTemplateDetails');
      const records = isNull(objectifiedResponse) ? [] : [objectifiedResponse];

      return {
        records: records,
        currPage: 1,
        nextPage: false,
        prevPage: false
      };
    });
}

export async function create(query: { UserId: number; values: IRostertemplateDetailFacade }):
  Promise<{ RosterTemplateDetails?: IObjectified<IRostertemplateDetailFacade> }> {
  const { UserId, values } = query;

  return Api.postInlineResponse<IRostertemplateSearchFacade, IRostertemplateDetailsResponse, 'RosterTemplateDetails'>(
    `RosterTemplateMaintenance/Technician/${UserId}/CreateRosterTemplate`, values
  ).then((response) => {

    return {
      ...response,
      RosterTemplateDetails: asObjectified(response, 'RosterTemplateDetails')
    };
  });
}

export async function update(values: any): Promise<{ RosterTemplateDetails: IObjectified<IRostertemplateSearchFacade> }> {

  const { UserId, RosterTemplateId } = values;

  return Api.putInlineResponse<IRostertemplateDetailFacade, IRostertemplateDetailsResponse, 'RosterTemplateDetails'>(
    `RostertemplateMaintenance/Technician/${UserId}/Rostertemplate/${RosterTemplateId}`, values)
    .then((response) => {

      return {
        ...response,
        RosterTemplateDetails: asObjectified(response, 'RosterTemplateDetails')
      };
    });
}

export async function removeRosterEntry(query: { UserId: number; RosterTemplateId: number }): Promise<IRostertemplateDetailsResponse> {
  const { UserId, RosterTemplateId } = query;

  return Api.delete<IRostertemplateDetailsResponse>(
    `RostertemplateMaintenance/Technician/${UserId}/Rostertemplate/${RosterTemplateId}`)
    .then((response) => response);
}
