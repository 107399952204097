
export const DEFAULT_PAGE_SIZE = 50;
export const DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH = 100;
export const MAX_VALUE_INT_DOTNET = 2147483647;
export const DEFAULT_EXPORT_BATCH_SIZE = 500;
export const COLUMN_SIZE = {
    sm0: 50,
    sm1: 75,
    md0: 100,
    md1: 150,
    lg0: 190,
    lg1: 200,
    xl0: 330
};
export const NumberOfDecimalPlaces = 2;

export const Patterns = {
    positiveTwoDecimalValue: {
        type: 'pattern',
        message: 'Enter a positive value.',
        pattern: /^\d*\.?\d+$/,
    }
};
