import React from 'react';
import { withStyles } from '@material-ui/core';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { useRetrievePurchaseOrderDetails } from 'api/purchaseOrders/purchaseOrders';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import FormView from 'components/FormView';
import styles from './OrderDetails.styles';
import OrderDetailFrom from './OrderDetails.form';
import { IOrderDetailsHandle, IOrderDetailsProps, setActionBarFn } from './OrderDetails.properties';
import { ActionBarIcons, changeConfirmationDialogDefaults } from './OrderDetails.constants';

const setActionBarButtons = (setActionBarCallBack: setActionBarFn): void => { setActionBarCallBack(ActionBarIcons); };

const OrderDetails = (props: IOrderDetailsProps, ref: React.Ref<IOrderDetailsHandle>): JSX.Element => {
  const { classes, changeConfirmationDialog, resetForm, dirty, formValues, PurchaseOrder } = props;
  const { setActionBar } = React.useContext(ActionBarContext);
  const fetchSummary = PurchaseOrder ? useRetrievePurchaseOrderDetails(PurchaseOrder) : null;
  const { showSnackBar } = useSnackBar();

  const [internalDirty, _setInternalDirty] = React.useState<boolean>(false);
  const isDirty = internalDirty || dirty;

  const resetAndNavigateBack = (callBack: () => void) => {
    resetForm();
    callBack();
  };

  React.useImperativeHandle(
    ref,
    () => ({
      isChangesSavedOrDiscarded(): boolean {
        if (isDirty) showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

        return !isDirty;
      },
      async OrderDetailsOk(navigateBack: () => void): Promise<void> {
        resetAndNavigateBack(navigateBack);

      },
      OrderDetailsCancel(navigateBack: () => void): void {
        if (!isDirty) return resetAndNavigateBack(navigateBack);

        changeConfirmationDialog({
          ...changeConfirmationDialogDefaults,
          onOk: () => { resetAndNavigateBack(navigateBack); }
        });
      }
    }),
    [formValues, isDirty]
  );

  React.useEffect(
    () => { setActionBarButtons(setActionBar); },
    []
  );

  return (
    <div className={classes.formContainer}>
      <FormView
        formName={'PurchaseOrdersOrderDetails'}
        schema={OrderDetailFrom}
        valuesSchema={fetchSummary?.data?.schema}
        includeTabsHeight={true}
        initialValues={fetchSummary?.data?.values}
        operationMode={'EDIT'}
      />
    </div>
  );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(OrderDetails)));
