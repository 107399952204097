import * as React from 'react';
import { COLUMN_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import StarIcon from '@markinson/uicomponents-v2/SvgIcons/Star';

const DefaultCellComponent = ({ data }) => {
    return data?.value ? <StarIcon /> : null;
};

export const columns = [
    {
        fixed: true,
        alignment: 'center',
        caption: 'Default',
        minWidth: COLUMN_SIZE.sm1,
        width: COLUMN_SIZE.sm1,
        allowSorting: false,
        allowResizing: false,
        cellComponent: DefaultCellComponent,
        calculateCellValue: (d) => d.inlineObject.Primary
    },
    {
        caption: 'Site',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        allowSorting: false,
        calculateCellValue: (d) => d.inlineObject.SiteCode
    },
    {
        caption: 'Type',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        allowSorting: false,
        calculateCellValue: (d) => d.inlineObject.DeliveryTypeLabel
    },
    {
        dataField: 'LineCount',
        caption: 'Lines',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        allowSorting: true,
        calculateCellValue: (d) => d.inlineObject.LineCount
    },
    {
        dataField: 'OnOrderCount',
        caption: 'B/O lines',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        allowSorting: true,
        calculateCellValue: (d) => d.inlineObject.OnOrderCount
    },
    {
        caption: 'Name',
        minWidth: COLUMN_SIZE.lg1,
        allowSorting: false,
        calculateCellValue: (d) => d.inlineObject.Name
    },
    {
        caption: 'City',
        minWidth: COLUMN_SIZE.lg1,
        allowSorting: false,
        calculateCellValue: (d) => d.inlineObject.State ? `${d.inlineObject.City}, ${d.inlineObject.State}` : d.inlineObject.City
    },
];

export const SUMMARY_PANEL_WIDTH = 355;
