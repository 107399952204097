import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, createStyles, Grid, withStyles } from '@material-ui/core';
import Icon from '@markinson/uicomponents-v2/Icon';
import { IFormViewForm, FormViewField } from '../../FormView/index';
import { Operation } from '../../../utils/operations';
import DynamicFormView from '../../FormView/DynamicFormView/index';
import { isNull } from 'utils/utils';
import { IValidationDialogAction, IValidationDialogProperties } from './ValidationDialog.properties';
import FocusLock from 'react-focus-lock';
import { capitalize } from 'lodash';

function getLayoutSchema(formSchema: IFormViewForm): FormViewField[][] {
  const { rows, columns } = formSchema.layout;
  const { fields } = formSchema;
  const layout = [];

  for (let i = 1; i <= rows; i++) {
    const rowItems = [];

    for (let j = 1; j <= columns; j++) {
      const colItems = [fields.filter((field) => {
        return field.position.row === i && field.position.col === j;
      })];

      rowItems.push(colItems);
    }

    layout.push(rowItems);
  }

  return layout;
}

const inlineStyles = createStyles({
  outerGrid: {
    display: 'flex',
    flexFlow: 'row'
  },
  left: {
    background: 'white',
    padding: '24px',
    paddingRight: '0'
  },
  right: {
    flex: 1,
    background: 'white'
  },
  paper: {
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  dialogPaper: {
    borderRadius: '8px',
    background: '#dddddd'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 24px 0px'
  },
  dialogTitle: {
    background: '#dddddd',
    padding: '24px 1rem 0'
  },
  content: {
    'padding': '0 24px 24px 24px',
    '& > p': {
      marginTop: '8px'
    }
  },
  dialogContent: {
    background: '#dddddd',
    padding: '1rem 1rem'
  },
  actions: {
    marginRight: '20px',
    marginLeft: '20px'
  },
  action: {
    border: '1px solid #777777',
    background: '#F5F5F5',
    color: 'black',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '8px 16px'
  },
  actionAmber: {
    '&:focus': {
      background: '#F47920',
      border: '1px solid #F47920',
      color: 'white'
    }
  },
  actionBlue: {
    '&:focus': {
      background: '#0E86D4',
      border: '1px solid #0E86D4',
      color: 'white'
    }
  },
  actionRed: {
    '&:focus': {
      background: '#B20000',
      border: '1px solid #B20000',
      color: 'white'
    }
  },
  actionGreen: {
    '&:focus': {
      background: '#009E0F',
      color: 'white'
    }
  },
  iconContainer: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 48
  },
  iconAmber: {
    color: '#F47920'
  },
  iconBlue: {
    color: '#0E86D4'
  },
  iconRed: {
    color: '#B20000'
  },
  iconGreen: {
    color: '#009E0F'
  }
});

class ValidationDialogView extends React.Component<IValidationDialogProperties, any> {

  firstButtonRef: any;
  modalContentRef: any;
  FormView: any;
  timer: NodeJS.Timeout;

  componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  handleAction = (action: IValidationDialogAction): boolean => {
    const { initialValues, formValues, pollingTimeoutMilliseconds, onClose, actionsCallback } = this.props;

    clearInterval(this.timer);

    if (onClose) {
      onClose();
    }

    if (!isNull(pollingTimeoutMilliseconds) && pollingTimeoutMilliseconds === 0) {
      return true;
    }

    if (actionsCallback) {
      actionsCallback(action);
    }

    action?.callback({ ...initialValues, ...formValues });

    return true;
  }

  handleEntered = () => {
    const { pollingTimeoutMilliseconds } = this.props;
    if (!isNull(pollingTimeoutMilliseconds) && pollingTimeoutMilliseconds !== 0) {

      this.timer = setInterval(this.handleRefresh, pollingTimeoutMilliseconds);
    }
  }

  handleRefresh = () => {
    const { refreshAction } = this.props;
    if (refreshAction) {
      refreshAction.callback({ action: 'RefreshStatus' });
    }
  }

  handleActionKeyDown = (e: any, idx: any) => {
    const { actions, focusModalContent } = this.props;
    if (idx === (actions.length - 1) && e.key === 'Tab') {
      if (focusModalContent) {
        focusModalContent();
      } else {
        this.firstButtonRef.focus();
      }
      e.preventDefault();
    } else if (idx === 0 && e.key === 'Tab' && e.shiftKey) {
      this.firstButtonRef.focus();
      e.preventDefault();
    }
  }

  handleOutsideClick = () => {
    const { actions, title } = this.props;

    const action = title === 'Information' ? actions.find((a) => a.isDefault)
      : actions.find((a) => a.name === 'cancel');

    this.handleAction(action);
  }

  render(): React.ReactNode {
    const { classes, className, open, message, title, actions, form, initialValues, valuesSchema, modalContent, icon, color, enableCloseOnOutsideClick, applyDialogSkin, onKeyDown } = this.props;

    return (<Dialog
      maxWidth={'sm'}
      style={{ whiteSpace: 'pre-line', zIndex: 9999 }}
      aria-labelledby={'confirmation-dialog-title'}
      open={open}
      onKeyDown={onKeyDown}
      onEntered={this.handleEntered}
      className={className}
      classes={{
        paper: applyDialogSkin ? classes.dialogPaper : classes.paper
      }}
      onClose={enableCloseOnOutsideClick ? this.handleOutsideClick : undefined}
    >
      <Grid className={classes.outerGrid}>
        {icon && (<Grid className={classes.left}>
          <div className={classes.iconContainer}>
            <Icon iconName={icon} className={`${classes.icon} ${classes[`icon${color}`]}`} />
          </div>
        </Grid>)}
        <Grid className={classes.right}>
          <DialogTitle
            className={'title'}
            classes={{
              root: applyDialogSkin ? `${classes.title} ${classes.dialogTitle}` : classes.title
            }}
            disableTypography
          >
            {title}
          </DialogTitle>
          <DialogContent
            classes={{
              root: applyDialogSkin ? `${classes.content} ${classes.dialogContent}` : classes.content
            }}
          >
            {message && message.split('\\n').map((splitMessage, key) => (<p key={key}> {splitMessage}</p>))}
            {modalContent && modalContent}
            {form && <DynamicFormView
              formName={'ValidationDialog'}
              ref={(el) => this.FormView = el}
              operationMode={Operation.EDIT}
              layout={getLayoutSchema(form)}
              initialValues={initialValues}
              valuesSchema={valuesSchema}
              enableReinitialize={true}
            />}
          </DialogContent>
        </Grid>
      </Grid>
      <FocusLock autoFocus={false}>
        <DialogActions
          classes={{
            root: classes.actions
          }}
        >
          {actions.map((action, idx) =>
            <Button
              key={action.name}
              onClick={() => this.handleAction(action)}
              className={`${classes.action} ${classes[`action${color}`]}`}
              autoFocus={action.isDefault}
              buttonRef={(ref) => {
                if (idx === 0) {
                  this.firstButtonRef = ref;
                }
              }}
              onKeyDown={(e) => this.handleActionKeyDown(e, idx)}
            >
              {capitalize(action.label)}
            </Button>
          )}
        </DialogActions>
      </FocusLock>
    </Dialog >);
  }
}

export default withStyles(inlineStyles, { index: 1 })(ValidationDialogView);
