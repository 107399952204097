import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryPanel from '../SummaryPanel';
import { IProductDetailsProps } from '../interfaces';

class ProductDetails extends React.PureComponent<IProductDetailsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = 'inventory-enquiry';
    if (path) {
      switch (path) {
        case `/${context}/product-details`:
          onInitialLoad('ProductDetails');
          break;
        case `/${context}/product-details/historical-dates`:
          onInitialLoad('HistoricalDates');
          break;
        case `/${context}/product-details/notepad`:
          onInitialLoad('Notepad');
          break;
        case `/${context}/product-details/user-fields`:
          onInitialLoad('UserFields');
          break;
        default:
          onInitialLoad('ProductDetails');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedTab, selectedProduct, productDetails, productDetailsSchema } = this.props;

    switch (selectedTab) {
      case 'ProductDetails': return { initialValues: productDetails, valuesSchema: productDetailsSchema };
      case 'Lists': return null;
      case 'Notepad':
        return { initialValues: { params: { DocumentNumber: selectedProduct && selectedProduct.ProductId } }, valuesSchema: {} };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, selectedTab } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <SummaryPanel
          variant={'ProductDetails'}
        />
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default ProductDetails;
