import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as policyUserGroupsSelectors, actions as policyUserGroupsActions } from 'ducks/securityManagement/policyUserGroups';
import { selectors as policySelectors } from 'ducks/securityManagement/securityPolicies';
import { IApplicationState } from 'ducks/reducers';
import { search } from 'api/SecurityManagement/policyUserGroups';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = (state: IApplicationState) => ({
    gridName: 'SecurityPolicyUserGroups',
    newLineRenderer: 'UserGroups',
    isLoading: policyUserGroupsSelectors.isLoading(state),
    columnDefs: policyUserGroupsSelectors.columns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
    gridOptions: policyUserGroupsSelectors.gridOptions(state),
    contextMenu: policyUserGroupsSelectors.contextMenu(state),
    reqParams: (policySelectors.selected(state) || params().get('PolicyId'))
        &&
        {
            PolicyId: (policySelectors.selected(state) && policySelectors.selected(state).PolicyId) || params().get('PolicyId')
        },
    apiMethod: search
});

export const actions = {
    changeSelectedTab: uiActions.changeSelectedTab,
    changeOperationMode: operationActions.changeOperationMode,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    setSelectedOrderLine: policyUserGroupsActions.setSelected
};
