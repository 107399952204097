import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectors as staffSelectors, actions as staffActions } from 'ducks/staff';
import { actions as uiActions } from 'ducks/ui';
import AccountSettings from './AccountSettings';

const mapStateToProps = (state) => ({
  isLoggedIn: staffSelectors.loggedIn(state),
  IsAdministrator: staffSelectors.IsAdministrator(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    logout: staffActions.logout,
    toggleDashboardOpen: uiActions.toggleDashboardOpen,
    setIsDashboardDefault: uiActions.setIsDashboardDefault,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
