import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { IObjectified } from 'api/utils';
import { ISupplierInvoiceEnquiryDetailFacade, ISupplierInvoiceEnquiryCommentFacade, ISupplierInvoiceEnquirySummaryFacade } from 'api/swaggerTypes';

export interface ISupplierInvoiceDetailsData {
  invoiceDetails: IObjectified<ISupplierInvoiceEnquiryDetailFacade>;
  Comment: ISupplierInvoiceEnquiryCommentFacade;
  Summary: ISupplierInvoiceEnquirySummaryFacade;
}

export interface ISupplierInvoiceDetailsState extends IExtendedState<ISupplierInvoiceDetailsData> {
}

const initialData: ISupplierInvoiceDetailsData = {
  invoiceDetails: null,
  Comment: {},
  Summary: null
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'invoiceDetails',
  initialData: initialData,
  asyncActions: {
    fetch: {
      action: (query: { DocumentNumber: number }) => (query),
      successCallback: (data: ISupplierInvoiceDetailsData, payload: IObjectified<ISupplierInvoiceEnquiryDetailFacade>) => ({
        ...data,
        invoiceDetails: payload
      })
    },
    fetchComment: {
      action: (query: { DocumentNumber: number }) => (query),
      successCallback: (data: ISupplierInvoiceDetailsData, payload: ISupplierInvoiceEnquiryCommentFacade) => ({
        ...data,
        Comment: payload
      })
    },
    fetchSummary: {
      action: (query: { DocumentNumber: number }) => (query),
      successCallback: (data: ISupplierInvoiceDetailsData, payload: ISupplierInvoiceEnquirySummaryFacade) => ({
        ...data,
        Summary: payload
      })
    },
  }
});

export { types, actions };
export default reducer;

export const selectors = {
  ...asyncSelectors(
    (state: { supplierInvoiceEnquiry: { invoiceDetails: ISupplierInvoiceDetailsState } }) => state.supplierInvoiceEnquiry.invoiceDetails,
    {
      invoiceDetails: (data: ISupplierInvoiceDetailsData) => data.invoiceDetails,
      comment: (data: ISupplierInvoiceDetailsData) => data.Comment,
      summary: (data: ISupplierInvoiceDetailsData) => data.Summary,
    }),
  isLoading: (state: { supplierInvoiceEnquiry: { invoiceDetails: ISupplierInvoiceDetailsState } }) => state.supplierInvoiceEnquiry.invoiceDetails.loading,
};
