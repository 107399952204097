import { createStyles } from '@material-ui/core';

export const popUpDialogStyles = createStyles({
  iconWarning: {
      color: 'red',
      height: '24px',
      width: '24px',
      verticalAlign: 'middle',
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer'
  },
});
