import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions,
  asyncInitialState,
  asyncOnError,
  asyncOnSuccess,
  asyncOnRequest,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';

export interface IMovementsData {
  selected: any;
  selectedFilters: any;
  fetch_loading?: boolean;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface IMovementsState extends IExtendedState<IMovementsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    reset: () => null,
    setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
    asyncs: {
      fetch: ({ ProductId, filters }) => ({ ProductId, filters })
    }
  },
  'movements');

const RequiredFields = [
  'Warehouse',
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IMovementsData>({
  selected: null,
  selectedFilters: {
    Warehouse: '',
    MovementType: 'ALL',
    From: '',
    To: ''
  },
  filterRow: {
    formName: 'SupplierMovementsFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'SELECT_FIELD',
        props: {
          label: 'Movement type',
          name: 'MovementType',
          defaultValue: 'ALL',
          required: false,
          size: 'medium',
          apiPrefs: {
            LookupType: 'Fixed',
            path: '/CustomTypes/Lookup/Movetype',
            method: 'GET',
            params: null
          }
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'From date',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'To date',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  Columns: [
    { headerName: 'Date', field: 'MovementDate', minWidth: 120, },
    { headerName: 'Time', field: 'MovementTime', suppressSorting: true, minWidth: 100, },
    { headerName: 'Movement', field: 'MovementTypeDescription', minWidth: 200, suppressSorting: true, },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Balance', field: 'BalanceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 200, suppressSorting: true, },
    { headerName: 'Financial period', field: 'FinancialPeriod', minWidth: 250, suppressSorting: true, },
    { headerName: 'Document', field: 'Document', minWidth: 150, suppressSorting: true, },
    { headerName: 'Document reference', field: 'DocumentReference', minWidth: 150, suppressSorting: true, },
    { headerName: 'Document account', field: 'DocumentAccount', minWidth: 150, suppressSorting: true, },
    { headerName: 'Cost each', field: 'CostEachDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Total cost', field: 'CostTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Sell price each', field: 'PriceEachDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Total price', field: 'PriceTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
  ]
});

export default (state: IMovementsState = initialState, action: IDataAction): IMovementsState => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setWarehouse:
      const warehouse = action.data.defaultWarehouse;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: warehouse
          }
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          Data: linesData,
          selected: null
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:

      return state;

  }
};

export const selectors = ({
  gridOptions: (state: { inventoryEnquiry: { stocking: { movements: IMovementsState } } }) => state.inventoryEnquiry.stocking.movements.data.gridOptions,
  columns: (state: { inventoryEnquiry: { stocking: { movements: IMovementsState } } }) => state.inventoryEnquiry.stocking.movements.data.Columns,
  filterRow: (state: { inventoryEnquiry: { stocking: { movements: IMovementsState } } }) => state.inventoryEnquiry.stocking.movements.data.filterRow,
  selectedFilters: (state: { inventoryEnquiry: { stocking: { movements: IMovementsState } } }) => state.inventoryEnquiry.stocking.movements.data.selectedFilters
});
