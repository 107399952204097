import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as invoiceSelector, actions as invoiceActions } from 'ducks/cashReceipting/invoices';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as cashReceiptingSelectors } from 'ducks/cashReceipting/cashReceipt';

export const mapStateToProps = (state) => ({
  gridName: 'CashReceiptingInvoices',
  columnDefs: invoiceSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: invoiceSelector.gridOptions(state),
  filterRow: invoiceSelector.filterRow(state),
  selectedFilters: invoiceSelector.selectedFilters(state),
  appliedFilters: invoiceSelector.filterRow(state) && filterSelectors.getFilters(state, invoiceSelector.filterRow(state).formName),
  reqParams: {
    entity: cashReceiptingSelectors.entity(state)
  },
  rowData: invoiceSelector.invoices(state),
});

export const actions = {
  deleteSelectedRecords: invoiceActions.deleteSpecificInvoiceAllocation,
  detailViewAction: invoiceActions.getSpecificInvoiceAllocation,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: invoiceActions.setSelected,
  applyFilters: invoiceActions.fetchAllocatedInvoices
};
