import { call, select, put, Effect, takeLatest } from 'redux-saga/effects';

import { types as pickSlipTypes, selectors as pickSlipSelectors, actions as pickSlipActions } from 'ducks/pickSlips/pickSlipTransaction';
import { types as slipDetailTypes, selectors as slipDetailsSelectors, actions as slipDetailsActions } from 'ducks/pickSlips/slipDetails';
import { callApi } from '../utils';
import { IDataAction } from 'ducks/utils';
import * as api from 'api/pickSlips/pickSlipTransaction';
import { pathOr, isNull } from 'utils/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* search(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = pickSlipActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = pickSlipActions.saga.searchById;
  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  );
}

function* fetchPickSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.fetch;

  yield callApi(
    call(api.fetchSlipDetails, action.data),
    success,
    failure
  );
}

function* cancelPickSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.cancelPickSlipDetails;

  yield callApi(
    call(api.cancelPickSlipDetails, action.data),
    success,
    failure
  );
}

function* savePickSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.save;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    showSnackBar({ variant: 'success', message: 'Details saved successfully.', time: -1 });
  }

  yield callApi(
    call(api.saveSlipDetails, action.data),
    success,
    failure,
    { postApiSuccessCallEffect, dialogActionCallEffect: savePickSlipDetails }
  );
}

function* finalizeSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.finalize;

  yield callApi(
    call(api.finalizeSlipDetails, action.data),
    success,
    failure,
    { dialogActionCallEffect: finalizeSlipDetails }
  );
}

function* updateSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.update;

  yield callApi(
    call(api.updateSlipDetails, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateSlipDetails }
  );
}

function* removePickSlipDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.remove;
  const currentForms: any[] = yield select(slipDetailsSelectors.ValidationForms);
  const currentSlipDetails: any = yield select(slipDetailsSelectors.slipDetails);
  const currentReferenceVars = pathOr([], ['ReferenceVariables'], currentForms && currentForms[0]);

  if (!isNull(currentReferenceVars)) {
    if ((currentReferenceVars[0] === 'RecordLostSale' && action.data.RecordLostSale === null)
      || (currentReferenceVars[0] === 'PlaceOnBackOrder' && action.data.PlaceOnBackOrder === null)
      || (currentReferenceVars[0] === 'DeleteDespatch' && action.data.DeleteDespatch === false)) {
      currentSlipDetails.Forms = [];
      yield put(success(currentSlipDetails));

      return;
    } else if (currentReferenceVars[0] === 'RecordLostSale' && action.data.RecordLostSale === true) {
      const DespatchId = yield select(pickSlipSelectors.selectedDespatchId);
      yield call(fetchRecordLostSale, { data: DespatchId });
      yield put(slipDetailsActions.openLostSaleModal(true));

      return;
    }
  }
  yield callApi(
    call(api.removeSlipDetails, action.data),
    success,
    failure,
    { dialogActionCallEffect: removePickSlipDetails },
  );
}

function* recordLostSale(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.recordLostSale;
  const { success: removeSuccess, failure: removeFailure } = slipDetailsActions.saga.remove;
  const currentSlipDetails: any = yield select(slipDetailsSelectors.slipDetails);
  const DespatchId = yield select(pickSlipSelectors.selectedDespatchId);
  const removePayload = { ...currentSlipDetails.inlineObject, RecordLostSale: true };

  yield callApi(
    call(api.recordLostSale, { DespatchId, payload: action.data }),
    success,
    failure
  );
  yield callApi(
    call(api.removeSlipDetails, removePayload),
    removeSuccess,
    removeFailure,
  );
}

function* fetchRecordLostSale(action: any): IterableIterator<Effect> {
  const { success, failure } = slipDetailsActions.saga.fetchRecordLostSale;

  yield callApi(
    call(api.fetchRecordLostSale, action.data),
    success,
    failure
  );
}

function* fetchNext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = pickSlipActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* fetchPrev(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = pickSlipActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(pickSlipTypes.search, search);
  yield takeLatest(pickSlipTypes.searchById, searchById);
  yield takeLatest(slipDetailTypes.fetch, fetchPickSlipDetails);
  yield takeLatest(slipDetailTypes.cancelPickSlipDetails, cancelPickSlipDetails);
  yield takeLatest(slipDetailTypes.save, savePickSlipDetails);
  yield takeLatest(slipDetailTypes.finalize, finalizeSlipDetails);
  yield takeLatest(slipDetailTypes.update, updateSlipDetails);
  yield takeLatest(slipDetailTypes.remove, removePickSlipDetails);
  yield takeLatest(slipDetailTypes.recordLostSale, recordLostSale);
  yield takeLatest(slipDetailTypes.fetchRecordLostSale, fetchRecordLostSale);
  yield takeLatest(pickSlipTypes.fetchPrevPage, fetchPrev);
  yield takeLatest(pickSlipTypes.fetchNextPage, fetchNext);
}
