import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  asyncSelectors
} from '../utils';

import { FormViewField } from 'components/FormView';

export interface IMovementsData {
  selected: number;
  list: any[];
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
  selectedFilters: any;
  details?: any;
  summary?: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
}

export interface IMovementsState extends IExtendedState<IMovementsData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  getSummary_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
      search: ({ SearchText, filters }) => ({ SearchText, filters, BatchPage: 1 }),
      searchById: ({ ProductMovementId }) => ({ ProductMovementId }),
      fetchNextPage: ({ BatchPage }) => ({ BatchPage }),
      fetchPrevPage: ({ BatchPage }) => ({ BatchPage }),
      getDetails: ({ ProductMovementId }) => ({ ProductMovementId }),
      getSummary: ({ ProductMovementId }) => ({ ProductMovementId }),
    }
  },
  'pmeMovements');

const RequiredFields = [
  'Warehouse', 'MovementType'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IMovementsData>({
  selected: null,
  list: [],
  nextPage: null,
  prevPage: null,
  currPage: null,
  pageSize: null,
  totalPages: null,
  removeWhenPrev: null,
  currSearchText: '',
  details: null,
  summary: null,
  selectedFilters: {
    Warehouse: '',
    MovementType: 'ALL',
    FromDate: '',
    ToDate: ''
  },
  filterRow: {
    formName: 'Test',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 5,
        type: 'SELECT_FIELD',
        props: {
          label: 'Movement type',
          name: 'MovementType',
          defaultValue: 'ALL',
          required: false,
          size: 'medium',
          apiPrefs: {
            path: '/CustomTypes/Lookup/Movetype',
            method: 'GET',
            params: null
          },
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      }] as FormViewField[],
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return Object.keys(errors).length > 0 ? errors : null;
    }

  },

});

export default (state: IMovementsState = initialState, action: IDataAction): IMovementsState => {
  switch (action.type) {
    case types.getSummary:
    case types.getDetails:
      return asyncOnRequest(state, action);
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, list: [], selected: -1 } }, action);
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: -1, list: [], selectedFilters: action.data.filters, currSearchText: action.data.SearchText } }, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.filters = state.data.selectedFilters;
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action);
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const result = successAction.payload;
        const movements = result.records;

        return {
          ...data,
          list: movements,
          nextPage: result.nextPage && parseInt(result.currPage) + 1,
          prevPage: result.prevPage && parseInt(result.currPage) - 1,
          currPage: result.currPage
        };
      });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(
        state, action, (data, successAction) => {
          const result = successAction.payload;
          const movements = result.records;
          const newRemoval = result.records.length;
          const newList = data.list.concat(movements);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
            nextPage: result.nextPage && parseInt(result.currPage) + 1,
            prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
          };
        },
        {
          fetch: true
        }
      );

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(
        state, action, (data, successAction) => {
          const result = successAction.payload;
          const movements = result.records;
          const newList = movements.slice(0, data.removeWhenPrev).concat(data.list);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
            prevPage: result.prevPage && result.currPage - 1,
            nextPage: (newList.length > data.pageSize * data.totalPages) && (parseInt(result.currPage) + data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
          };
        },
        {
          fetch: true
        });
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.saga.getSummary.success:
      return asyncOnSuccess(
        state, action, (data, successAction) => {
          const result = successAction.payload;

          return {
            ...data,
            summary: result && result.summary
          };
        },
        {
          fetch: true
        });
    case types.saga.getDetails.success:
      return asyncOnSuccess(
        state, action, (data, successAction) => {
          const result = successAction.payload;

          return {
            ...data,
            details: result && result.details
          };
        },
        {
          fetch: true
        });
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
    case types.saga.getDetails.failure:
    case types.saga.getSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pme: { movements: IMovementsState } }) => state.pme.movements,
  {
    all: (data: IMovementsData) => data.list !== undefined && data.list != null && Object.values(data.list),
    selected: (data: IMovementsData) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ProductMovementId === data.selected)) || null,
    nextPage: (data: IMovementsData) => data.nextPage,
    prevPage: (data: IMovementsData) => data.prevPage,
    summary: (data: IMovementsData) => data.summary,
    details: (data: IMovementsData) => data.details,
  }
);

const syncSelector = {
  isLoading: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.search_loading || state.pme.movements.searchById_loading,
  loadingSummary: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.search_loading || state.pme.movements.getSummary_loading,
  loadingNextPage: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.fetchNextPage_loading,
  loadingPrevPage: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.fetchPrevPage_loading,
  pageSize: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.data.pageSize,
  totalPages: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.data.totalPages,
  filterRow: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.data.filterRow,
  selectedFilters: (state: { pme: { movements: IMovementsState } }) => state.pme.movements.data.selectedFilters
};

export const selectors = { ...asyncSelector, ...syncSelector };
