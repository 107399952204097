import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (lotId) => ({ lotId }),
  setSelectedLotLine: (lotId) => ({ lotId }),
  changeSortFilter: (selectedSortFilter) => selectedSortFilter,
  changeSortDirection: (selectedSortDirection) => selectedSortDirection,
  asyncs: {
  }
}, 'reservationLots')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  lines: [],
  schemas: null,
  selectedSortFilter: 'Name',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lotLineActions: {},
  lotLineColumns: [
    { headerName: 'Lot', field: 'Lot', type: 'numericColumn', minWidth: 200, },
    { headerName: 'Reserved', field: 'ReservedQuantity', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Description', field: 'LotDescription', suppressSorting: true, minWidth: 300, }
  ],
  lotLinesData: [],
  lotLinesSchema: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return initialState
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.setSelected:
      const selected = action.data.lotId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.setSelectedLotLine:
      const selectedLotLine = action.data.lotId;
      return {
        ...state,
        data: { ...state.data, selectedLotLine }
      }

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerQuoteEnquiry.reservationLots,
  {
    lines: (data) => data.lines !== undefined && data.lines != null && Object.values(data.lines),
  }
)

const syncSelector = {
  selectedSortFilter: state => state.customerQuoteEnquiry.reservationLots.data.selectedSortFilter,
  selectedSortDirection: state => state.customerQuoteEnquiry.reservationLots.data.selectedSortDirection,
  isLoading: state => state.customerQuoteEnquiry.reservationLots.loading,
  gridOptions: state => state.customerQuoteEnquiry.reservationLots.data.gridOptions,
  lineColumns: state => state.customerQuoteEnquiry.reservationLots.data.lotLineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

