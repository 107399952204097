import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../../utils'

export const search = (query) => {
  const { ProductId, Warehouse, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchGet(`/InventoryEnquiry/Stocking/Quantities/QuantitiesDetails/ProductionWIP/Product/${ProductId}/Warehouse/${Warehouse}`, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize }, 'Inline')
    .then((result) => (result && result.ProductionWIPs &&
    {
      records: result.ProductionWIPs.map((val) => (objectify(val))),
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
    })
      ||
    {
      records: [],
      nextPage: false,
      currPage: 1,
    });
}
