import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as trueCostSelectors, actions as trueCostActions } from 'ducks/priceCheck/trueCost';
import { search } from 'api/priceCheck/trueCost';

export const mapStateToProps = state => ({
  gridName: 'PCTrueCost',
  columnDefs: trueCostSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: trueCostSelectors.gridOptions(state),
  isLoading: trueCostSelectors.isLoading(state),
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: trueCostActions.setSelected
}
