import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from './utils'

export const { types, actions } = createActions({
  setSelected: (productId) => ({ productId }),
  asyncs: {
    search: (query) => (query),
    searchById: ({ CustomerId, CustomerProduct }) => ({ CustomerId, CustomerProduct }),
    fetchDetail: (query) => (query)
  }
}, 'customerProduct')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  selectedDetail: null
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.search:
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
      
    case types.fetchDetail:
      return asyncOnRequest(state, action)

    case types.setSelected:
      const selected = action.data.productId;
      return {
        ...state,
        data: { ...state.data, selected }
      }

    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, action) => {

        const products = action.payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = action.payload.map((combinedObject) => combinedObject.schema);
        return {
          ...data,
          list: products,
          schemas: schemas
        };
      })
    case types.saga.fetchDetail.success:
      return asyncOnSuccess(state, action, (data, action) => {

        const productDetail = action.payload;
        const selectedSchema = data.schemas[data.selected];
        const selectedProduct = data.list[data.selected];
        selectedSchema.ProdGroup.Label = productDetail.ProductDescription;
        selectedSchema.CustomerProduct = { Label: productDetail.CustomerDescription };
        selectedProduct.Comment = productDetail.Comment;
        selectedProduct.CustomerProduct = productDetail.CustomerProduct;
        return {
          ...data,
          productDetail: productDetail,
          selectedSchema: selectedSchema,
        };
      })
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.fetchDetail.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerProduct,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.CustomerProduct === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.CustomerProduct.Value === data.selected)) || null,
  }
)

const syncSelector = {
  isLoading: state => state.customerProduct.search_loading || state.customerProduct.searchById_loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

