import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify, fetchDelete, fetchPut } from '../utils';

export const search = async (query): Promise<any> => {
    const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return fetchPost('/Security/SearchPolicies', { SearchText }, { Sort: `${Sort.Property}:${Sort.Direction === 'Descending' ? 'desc' : 'asc'}`, BatchPage, BatchSize }, 'Inline')
        .then((result) => (result && result.policies &&
        {
            records: result.policies.map((val) => (objectify(val))),
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
        })
            ||
            { records: [] });
};

export const searchById = async ({ PolicyId }): Promise<any> => {
    return fetchGet(`/Security/Policy/${PolicyId}`, null, 'Inline')
        .then((result) => (result && result.policy && {
            records: [objectify(result.policy)],
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1,
        }) || { records: [] });
};

export const remove = async (query: { PolicyId: number }): Promise<number> => {
    const { PolicyId } = query;

    return fetchDelete(`/Security/Policy/${PolicyId}`)
        .then(() => PolicyId);
};

export const create = async (data: { Policy: string; Comment: string }): Promise<{ Policy: string; Comment: string; PolicyId: number }> => {

    return fetchPost('/Security/CreatePolicy', data, null, 'Inline')
        .then((result: { policy: any }) => {
            if (result.policy) {
                return objectify(result.policy);
            }

            return result.policy;
        });
};

export const update = async (data: { Policy: string; Comment: string; PolicyId: number }): Promise<{ Policy: string; Comment: string; PolicyId: number }> => {
    const { PolicyId } = data;

    return fetchPut(`Security/Policy/${PolicyId}`, data, 'Inline')
        .then((result: { policy: any }) => {
            if (result.policy) {
                return objectify(result.policy);
            }

            return result.policy;
        });
};
