export const DATAGRIDCONSTANTS = {
    ICON_COLUMN_DEFAULT_WIDTH: 16,
    GRID_HEIGHT: 470,
    LOADING_PANEL_WIDTH_HEIGHT: 20,
    header: {
        IconColumn: 60,
        InquiryColumn: 137,
        UserColumn: 62,
        UsernameColumn: 149,
        EnteredColumn: 88,
        TimeColumn: 49,
        DeliveryColumn: 242,
        NotesColumn: 444,
        ButtonsColumn: 70,
    }
};
