import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as paymentSelectors, actions as paymentAction } from 'ducks/salesInvoiceEnquiry/payment';
import { fetch } from 'api/salesInvoiceEnquiry/payment';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  columnDefs: paymentSelectors.paymentLinesColumns(state),
  isSalesProcessingLookupPanelOpen: uiSelectors.isSalesProcessingLookupPanelOpen(state), //to trigger update size based on drawer state change,
  actions: paymentSelectors.paymentActions(state),
  gridOptions: paymentSelectors.gridOptions(state),
  keyFields: [{ valueField: 'PaymentCode', routeField: 'PaymentCode' }],
  isLoading: paymentSelectors.isLoading(state),
  reqParams: (invoiceSelectors.selected(state) || params.get('InvoiceNumber') || params.get('SalesEntity'))
    &&
  {
    Invoice: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).Invoice) || params.get('InvoiceNumber'),
    SalesEntity: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).SalesEntity) || params.get('SalesEntity'),
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: paymentAction.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
