
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { selectors as pricesSelectors } from 'ducks/inventoryEnquiry/pricing/prices';

import ProductDetails from './Pricing';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedProduct: productSelectors.selected(state),
  pricesSummary: pricesSelectors.pricesSummary(state),
});

export default connect(mapStateToProps, {})(ProductDetails);
