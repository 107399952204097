import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncSelectors,
  IDataAction, asyncOnRequest, asyncOnSuccess, asyncOnError,
  IExtendedState
} from '../utils';

import { pathOr } from 'utils/utils';

export interface IKitComponentsData {
  selected: any;
  deleteObj: any;
  Actions: any;
  summary: any;
  details: any;
  lineColumns: IColDef[];
  kitComponents: any[];
  WarehouseProduct: string;
  openLotDetailModal: boolean;
  gridOptions: IDataGridOptions;
  newLineRenderer: string;
}

export interface IKitComponentsState extends IExtendedState<IKitComponentsData> {
  fetchKitComponentSummary_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    changeModalVisibility: (data) => (data),
    clearLines: () => null,
    asyncs: {
      fetchKitComponents: (data) => (data),
      fetchKitComponentSummary: (data) => (data),
      fetchKitComponentDetails: (data) => (data),
      createKitComponentDetails: (data) => (data),
      updateKitComponentDetails: (data) => (data),
      saveKitComponentDetails: (data) => (data),
      cancelKitComponentDetails: (data) => (data),
      removeKitComponentDetails: (data) => (data),
    }
  },
  'kitComponents');

const initialState = asyncInitialState<IKitComponentsData>({
  selected: null,
  deleteObj: null,
  Actions: {
  },
  gridOptions: {
    doubleClickActionTab: 'KitComponentDetail',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  lineColumns: [
    { headerName: 'Line', field: 'Line', suppressSorting: true, minWidth: 100 },
    { headerName: 'Product', field: 'ProductIdDisplay' },
    { headerName: 'Group', field: 'Group', suppressSorting: true },
    { headerName: 'Required', field: 'Required', suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Supplied', field: 'Supplied', suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Description', field: 'Description', suppressSorting: true },
  ],
  kitComponents: [],
  newLineRenderer: 'KitComponents',
  WarehouseProduct: null,
  openLotDetailModal: false,
  summary: null,
  details: {}
});

export default (state: IKitComponentsState = initialState, action: IDataAction): IKitComponentsState => {
  switch (action.type) {
    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetchKitComponentSummary:
    case types.fetchKitComponentDetails:
    case types.createKitComponentDetails:
    case types.updateKitComponentDetails:
    case types.saveKitComponentDetails:
    case types.cancelKitComponentDetails:
    case types.removeKitComponentDetails:
      return asyncOnRequest(state, action);
    case types.saga.fetchKitComponentSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          summary: successAction.payload
        };
      });

    case types.saga.fetchKitComponentDetails.success:
    case types.saga.createKitComponentDetails.success:
    case types.saga.updateKitComponentDetails.success:
    case types.saga.saveKitComponentDetails.success:
    case types.saga.cancelKitComponentDetails.success:
    case types.saga.removeKitComponentDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          details: successAction.payload
        };
      });

    case types.saga.fetchKitComponentSummary.failure:
    case types.saga.fetchKitComponentDetails.failure:
    case types.saga.createKitComponentDetails.failure:
    case types.saga.updateKitComponentDetails.failure:
    case types.saga.saveKitComponentDetails.failure:
    case types.saga.cancelKitComponentDetails.failure:
    case types.saga.removeKitComponentDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { kitComponents: IKitComponentsState } }) => state.pickSlips.kitComponents,
  {
    kitComponents: (data) => pathOr([], ['kitComponents', 'records'], data),
    selected: (data) => data.selected,
    KitComponent: (data) => data.selected && data.selected.KitComponent,
    summary: (data) => data.summary,
    details: (data) => data.details,
  }
);

const syncSelector = {
  openLotDetailModal: (state: { pickSlips: { kitComponents: IKitComponentsState } }) => state.pickSlips.kitComponents.data.openLotDetailModal,
  lineColumns: (state: { pickSlips: { kitComponents: IKitComponentsState } }) => state.pickSlips.kitComponents.data.lineColumns,
  gridOptions: (state: { pickSlips: { kitComponents: IKitComponentsState } }) => pathOr({}, ['pickSlips', 'kitComponents', 'data', 'gridOptions'], state),
  newLineRenderer: (state: { pickSlips: { kitComponents: IKitComponentsState } }) => state.pickSlips.kitComponents.data.newLineRenderer,
  fetchKitComponentSummary_loading: (state: { pickSlips: { kitComponents: IKitComponentsState } }) => state.pickSlips.kitComponents.fetchKitComponentSummary_loading,
};

export const selectors = { ...asyncSelector, ...syncSelector };
