import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as quoteTypes, actions as quoteActions } from 'ducks/customerQuoteEnquiry/quote';

import { callApi } from '../utils';
import * as api from 'api/customerQuoteEnquiry/quote';


function* searchQuotes(action) {
  const { success, failure } = quoteActions.saga.searchQuotes;

  yield callApi(
    call(api.searchQuotes, action.data),
    success,
    failure
  )
}


function* searchById(action) {
  const { success, failure } = quoteActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchCustomerQuoteDetails(action) {
  const { success, failure } = quoteActions.saga.fetchCustomerQuoteDetails;

  yield callApi(
    call(api.fetchCustomerQuoteDetails, action.data),
    success,
    failure
  )
}
function* fetchQuoteSummary(action) {
  const { success, failure } = quoteActions.saga.fetchQuoteSummary;

  yield callApi(
    call(api.fetchQuoteSummary, action.data),
    success,
    failure
  )
}
function* fetchQuoteSummaryPanel(action) {
  const { success, failure } = quoteActions.saga.fetchQuoteSummaryPanel;

  yield callApi(
    call(api.fetchQuoteSummaryPanel, action.data),
    success,
    failure
  )
}
function* fetchQuoteLines(action) {
  const { success, failure } = quoteActions.saga.fetchQuoteLines;

  yield callApi(
    call(api.fetchQuoteLines, action.data),
    success,
    failure
  )
}
function* fetchQuoteLineDetail(action) {
  const { success, failure } = quoteActions.saga.fetchQuoteLineDetail;

  yield callApi(
    call(api.fetchQuoteLineDetail, action.data),
    success,
    failure
  )
}
function* fetchQuoteLineSummary(action) {
  const { success, failure } = quoteActions.saga.fetchQuoteLineSummary;

  yield callApi(
    call(api.fetchQuoteLineSummary, action.data),
    success,
    failure
  )
}
function* fetchReservationDetails(action) {
  const { success, failure } = quoteActions.saga.fetchReservationDetails;

  yield callApi(
    call(api.fetchReservationDetails, action.data),
    success,
    failure
  )
}
function* fetchNext(action) {
  const { success, failure } = quoteActions.saga.fetchNextPage;

  yield callApi(
    call(api.searchQuotes, action.data),
    success,
    failure
  )
}

function* fetchPrev(action) {
  const { success, failure } = quoteActions.saga.fetchPrevPage;

  yield callApi(
    call(api.searchQuotes, action.data),
    success,
    failure
  )
}
export default function* rootCustomerQuoteEnquirySaga() {
  yield takeLatest(quoteTypes.searchQuotes, searchQuotes)
  yield takeLatest(quoteTypes.searchById, searchById)
  yield takeLatest(quoteTypes.fetchCustomerQuoteDetails, fetchCustomerQuoteDetails)
  yield takeLatest(quoteTypes.fetchQuoteSummary, fetchQuoteSummary)
  yield takeLatest(quoteTypes.fetchQuoteLines, fetchQuoteLines)
  yield takeLatest(quoteTypes.fetchQuoteLineDetail, fetchQuoteLineDetail)
  yield takeLatest(quoteTypes.fetchQuoteLineSummary, fetchQuoteLineSummary)
  yield takeLatest(quoteTypes.fetchReservationDetails, fetchReservationDetails)
  yield takeLatest(quoteTypes.fetchQuoteSummaryPanel, fetchQuoteSummaryPanel)
  yield takeLatest(quoteTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(quoteTypes.fetchNextPage, fetchNext)
}