import { IFormViewForm } from 'components/FormView';

const ActivityForm: IFormViewForm = {
  id: 'Activities',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'activity',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Activity',
            name: 'ActivityCode',
            lookupName: 'ActiveActivityCode',
            suppressDescription: true,
            required: true,
            size: 'medium',
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Description',
            disabled: true,
            required: false,
            name: 'Description',
            fullWidth: true
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Labour product',
            name: 'LabourProductId',
            lookupName: 'LabourProduct',
            disabled: true,
            required: false,
            size: 'medium',

          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Bill of materials',
            name: 'BomCode',
            lookupName: 'BomCode',
            disabled: true,
            required: false,
            size: 'medium',

          }
        },
        {
          type: 'MASKED_INPUT',
          props: {
            label: 'Estimate',
            name: 'Estimate',
            size: 'small',
            placeholder: 'HH:MM',
            required: false,
            mask: [/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/],
            disabled: true
          }
        }
      ]
    },
    {
      id: '2',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'Comments',
        fullWidth: true,
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            disabled: true,
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '300px'
            }
          }
        }
      ]
    }
  ]
};

export default ActivityForm;
