import { fetchGet, objectify } from './../utils'

export const getReservationDetails = (query) => {
  const { SalesOrder, LineNumber } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Line/${LineNumber}/LineReservations/Details`, null, 'Inline')
    .then((result) => {
      if (result) {
        const objectified = objectify(result.SalesOrderReservationDetails);
        return { schema: result.SalesOrderReservationDetails, values: { ...objectified } }
      }
      else
        return null;
    })
}
