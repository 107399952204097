import { Payload, useFetch, usePost } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { objectify } from 'api/utils';
import { ILookupResponse, IValidatedResponse, IWizardFormDataFacade } from 'api/swaggerTypes';

export const parsePages = (wizard) => {

  return wizard?.Configuration?.inlineObject?.Pages?.reduce(
    (acc, page) => {
      acc[page.FormId] = {
        FormId: page.FormId,
        Description: page.Description,
        Header: page.Header,
        NextPageDetail: function (dynamicExtractForm: any): string {
          if (page.NextPageDetail.length > 1) {
            const nextPanelDetails = convertToObject(page.NextPageDetail);
            const key = Object.keys(nextPanelDetails)[0];
            const value = dynamicExtractForm[key];

            return nextPanelDetails[key][value] ?? '';
          }

          return page.NextPageDetail[0].FormId;
        },
        PageNumber: page.PageNumber
      };

      return acc;
    },
    {}
  );
};

export const convertToObject = (arr) => {

  return arr.reduce(
    (acc, curr) => {
      const { ConditionProperty, ConditionValue, FormId } = curr;
      if (!acc[ConditionProperty]) {
        acc[ConditionProperty] = {};
      }
      acc[ConditionProperty][ConditionValue] = FormId;

      return acc;
    },
    {}
  );
};

const fetchWizardConfigurationResponseParser = (response): Promise<any> => {

  return {
    ...response,
    Configuration: { inlineObject: objectify(response.Configuration), schema: response.Configuration },

  };
};

export const useFetchWizardConfiguration = (ProgramId: string) => {
  return useFetch<any>(
    {
      url: APIRoutes.dynamicExtract.wizard.start,
      urlParams: { ProgramId },
      queryConfig: { enabled: !!ProgramId },
      options: { inline: true, responseParser: fetchWizardConfigurationResponseParser }
    }
  );
};

export const useRetrieveWizardActions = () => {

  return usePost<Payload<{ SearchText: string; ProgramId: string }>, ILookupResponse>
    (
      APIRoutes.dynamicExtract.wizardActions.retrieve,
      null,
      { inline: true }
    );
};

export const useRetrieveWizardBSActions = () => {

  return usePost<Payload<{ SearchText: string; ProgramId: string }>, ILookupResponse>
    (
      APIRoutes.dynamicExtract.wizardBSActions.retrieve,
      null,
      { inline: true }
    );
};

export const useFileSelectionValidate = () => {

  return usePost<Payload<{ FileName: string; FileOffset: string; FileLocation: string; FileType: string }>, any>
    (APIRoutes.dynamicExtract.fileSelection.validate, null, { disableDefaultValidation: true });
};

const submitDynamicExtractWizardResponseParser = (response): Promise<any> => {

  return response;
};

export const useSubmitDynamicExtractWizard = () => {

  return usePost<Payload<{ ProgramId: string } & IWizardFormDataFacade>, IValidatedResponse>
    (APIRoutes.dynamicExtract.wizard.submit, null, { inline: true, responseParser: submitDynamicExtractWizardResponseParser });
};
