import * as React from 'react';
import AutoCompleteField from '@markinson/uicomponents-v2/AutoCompleteField';
import { Operation } from 'utils/operations';

export interface IAutoCompleteContainerProps {
  name?: string;
  label?: string;
  style?: React.CSSProperties;
  options?: { value: string; label: string }[];
  results?: { [key: string]: { lookupId: string; Data: { value: string; label: string }[] } };
  apiPrefs?: { path: string; method: ('GET' | 'POST'); params: any };
  value?: string;
  operationMode?: Operation;
  defaultValue?: string;
  sort?: boolean;
  onChange?(value: string): void;
  onBlur?(value: string): void;
  fetchLookupOptions?(apiPrefs: { path: string; method: ('GET' | 'POST'); params: any }): void;
}

class AutoCompleteContainer extends React.PureComponent<IAutoCompleteContainerProps> {

  componentDidMount(): void {
    const { results, apiPrefs } = this.props;
    const result = results && apiPrefs && results[apiPrefs.path];
    if (apiPrefs && !result && apiPrefs.path) {
      this.props.fetchLookupOptions(this.props.apiPrefs);
    }
  }

  handleDefaultValue = () => {
    const { apiPrefs, results, value, operationMode, defaultValue, sort, onChange } = this.props;
    const optionsData = apiPrefs ? results[apiPrefs.path] : null;

    if (optionsData && optionsData.Data) {
      const options = optionsData.Data;
      if (options && sort) {
        options.sort((a, b) => {
          if (a.label === 'None') {
            return -1;
          }

          return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        });
      }
      if (defaultValue === 'None' && !options.find((item) => item.label === 'None')) {
        options.unshift({ value: '0', label: 'None' });
      }
      if (operationMode === 'NEW' && !value) {
        if (onChange) {
          if (options[0].value === '0') {
            onChange(value);
          } else {
            onChange(options[0].value);
          }
        }
      }
    }
  }

  onChange = (event: { value: string; label: string}) => {
    const { onChange } = this.props;
    let { value } = (event || { value: undefined });
    if (value === '0' || !value) {
      value = '0';
    }
    if (onChange) {
      onChange(value);
    }
  }

  onBlur = () => {
    const { onBlur, value } = this.props;
    if (onBlur) {
      onBlur(value);
    }
  }

  render(): React.ReactNode {
    const { apiPrefs, results, value, operationMode, name, defaultValue, onChange, onBlur, options, ...rest } = this.props;
    const optionsData = apiPrefs ? results[apiPrefs.path] : null;
    this.handleDefaultValue();

    const transformedValue = (value === '' || value === null) && defaultValue === 'None' ? '0' : value;

    return (
      <AutoCompleteField
        options={(optionsData && optionsData.Data) || options || []}
        name={name}
        value={transformedValue}
        onChange={this.onChange}
        onBlur={this.onBlur}
        {...rest}
      />
    );
  }
}

export default AutoCompleteContainer;
