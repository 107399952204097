import { IFormViewForm } from 'components/FormView';

const CustomerForm: IFormViewForm = {
  id: 'CustomerDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Customer',
        name: 'customer',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Customer',
            name: 'CustomerAccountNumber',
            size: 'small'
          },
          layout: {
            row: 1,
            xs: {
              col: 12
            },
            sm: {
              col: 12
            },
            md: {
              col: 3
            },
            lg: {
              col: 3
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Abbreviation',
            name: 'Abbreviation',
            size: 'small'
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Name',
            name: 'Name',
            size: 'large'
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Owner',
            name: 'Owner',
            size: 'large',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer active',
            name: 'Active',
            required: false
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Postal address',
        name: 'address',
        required: true,
      },
      children: [
        {
          type: 'ADDRESS_BLOCK_EX',
          props: {
            name: 'address'          }
        }
      ],
      position: {
        row: 2,
        col: 1
      },
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'General contact details',
        name: 'contactDetails',
        required: true,
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Phone',
            name: 'PhoneMain',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Fax',
            name: 'FaxMain',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Mobile',
            name: 'PhoneMobile',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Email',
            type: 'email',
            name: 'EmailMain',
            required: false,
            size: 'large',
            action: {
              iconName: 'Mail',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Web',
            name: 'URL',
            required: false,
            size: 'large',
            action: {
              iconName: 'Language',
            }
          }
        }
      ]
    },
    {
      type: 'CONTACT_BLOCK',
      props: {
        label: 'Primary contacts',
        name: 'primaryContact',
        variant: 'customer'
      },
      position: {
        row: 1,
        col: 3
      },
    }
  ]
};
export default CustomerForm;
