import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
  asyncs: {
  }
}, 'serials');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Serial number', field: 'LotSerial', minWidth: 200, },
    { headerName: 'Entered', field: 'EnteredDate', type: 'numericColumn', suppressSorting: true, minWidth: 120, },
  ],
  Data: [],
  selectedFilters: {
    Warehouse: 'QW'
  },
  filterRow: {
    formName: 'SerialFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      }]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setWarehouse:
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: warehouse
          }
        }
      };
   
    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.serials,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.serials.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.serials.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.serials.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.serials.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.serials.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)