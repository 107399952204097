import {
  createActions, asyncInitialState, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null
}, 'salesHistory');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Type', field: 'Type', minWidth: 120, suppressSorting: true, },
    { headerName: 'Document', field: 'DocumentNumber', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Sell price', field: 'SellPriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Extended', field: 'ExtendedPriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Unit cost', field: 'UnitCostDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'GP', field: 'GrossProfitPercentDisplay', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 250, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 250, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.priceCheck.salesHistory,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.priceCheck.salesHistory.loading,
  gridOptions: state => state.priceCheck.salesHistory.data.gridOptions,
  columns: state => state.priceCheck.salesHistory.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)