import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as documentSelector } from 'ducks/supplierProductEnquiry/attachments/documents';
import { fetch } from 'api/supplierProductEnquiry/attachments/documents';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';
import { IApplicationState } from 'ducks/reducers';

const getParams = () => new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SupplierProductEnquiryDocuments',
  columnDefs: documentSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: documentSelector.gridOptions(state),
  filterRow: documentSelector.filterRow(state),
  selectedFilters: documentSelector.selectedFilters(state),
  appliedFilters: documentSelector.filterRow(state) && filterSelectors.getFilters(state, documentSelector.filterRow(state).formName),
  reqParams: (supplierProductSelectors.selected(state) || getParams().get('SupplierProductId'))
    &&
  {
    SupplierProductId: (supplierProductSelectors.selected(state) && supplierProductSelectors.selected(state).SupplierProductId) || getParams().get('SupplierProductId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  applyFilters: filterActions.applyFilters
};
