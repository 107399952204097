import { fetchGet, objectify } from '../../utils';
export const fetch = async (query: { Supplier: number }): Promise<{ values: any; schema: any }> => {
  const { Supplier } = query;
  const respType = 'Inline';

  return fetchGet(`/SupplierEnquiry/Balances/Supplier/${Supplier}`, null, respType)
    .then(
      (result: {
        SupplierBalances: any;
      }) => {
        if (result) {
          if (result.SupplierBalances) {

            return {
              values: objectify(result.SupplierBalances),
              schema: result.SupplierBalances,
            };
          }
        }

        return {
          values: null,
          schema: null,
        };
      });
};
