import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';

import ContactsBlockContainer from './ContactsBlockContainer';

const mapStateToProps = (state, props) => {
  switch (props.variant) {
    case 'supplier': return {
      loading: supplierSelectors.primaryContactsLoading(state),
      primaryContacts: supplierSelectors.primaryContacts(state),
    }
    case 'customer':
    default: return {
      loading: customerSelectors.detailLoading(state),
      primaryContacts: customerSelectors.primaryContacts(state),
    };
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsBlockContainer);