import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError,
  formatPercentageNumber
} from 'ducks/utils'

export const { types, actions } = createActions({
  setWarehouse: (Warehouse) => Warehouse,
  asyncs: {
  }
}, 'priceMatrix')

let initialState = asyncInitialState({
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  lineColumns: [
    { headerName: 'Customer price code', field: 'CustomerPriceCode', cellRenderer: 'agGroupCellRenderer', minWidth: 100 },
    { headerName: 'Description', field: 'Description' },
    { headerName: 'Type', field: 'Type', suppressSorting: true, },
    { headerName: 'Percent', field: 'Percent', valueGetter: formatPercentageNumber, type: 'numericColumn', suppressSorting: true, }
  ],
  detailCellRendererParams: {
    detailGridOptions: {
      columnDefs: [
        { headerName: 'Break level', field: 'BreakLevel', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true },
        { headerName: 'Quantity', field: 'BreakQuantity', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true },
        { headerName: 'Percent', field: 'BreakPercent', valueGetter: formatPercentageNumber, type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true }
      ],
    },
    getDetailRowData: (row) => row.data.Breaklevels,
  },
  selectedFilters: {
    Warehouse: '',
  },
  filterRow: {
    formName: 'PriceMatrixFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          // suppressDescription: true,
          required: true,
        }
      }
    ]
  },
  linesData: [],
  linesSchema: []
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.setWarehouse:
      const Warehouse = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            Warehouse: Warehouse
          }
        }
      };

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.pricing.priceMatrix,
  {
    linesData: data => data.linesData,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.pricing.priceMatrix.loading,
  gridOptions: state => state.inventoryEnquiry.pricing.priceMatrix.data.gridOptions,
  lineColumns: state => state.inventoryEnquiry.pricing.priceMatrix.data.lineColumns,
  detailColumns: state => state.inventoryEnquiry.pricing.priceMatrix.data.detailCellRendererParams,
  filterRow: state => state.inventoryEnquiry.pricing.priceMatrix.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.pricing.priceMatrix.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

