import { withStyles } from '@material-ui/core/styles';
import styles from './BackorderCommitment.styles';
import { IBackorderCommitmentProps } from './BackorderCommitment.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import React from 'react';
import CommitmentGrid from './CommitmentGrid/CommitmentGrid';
import { ICommitmentGridHandle } from './CommitmentGrid';
import LineCommitment from './LineCommitment/LineCommitment';
import { Route, withRouter } from 'react-router-dom';
import { ActionBar, BreadcrumbBar } from '@markinson/uicomponents-v2';
import { getTabs } from './constants';
import modules from './BackorderCommitments.modules';
import PurchaseOrder from './PurchaseOrder';
import BackorderSummaryPanel from './BackorderSummaryPanel';
import { HotKeys } from 'react-hotkeys';
import { ILocationState, useMemoizedKeyHandlers, useModule } from 'utils/hooks';
import classNames from 'classnames';
import { isNull } from 'utils/utils';
import { MessageBoxColor } from 'components/common/ValidationForms/formDefinations';
import { useTabLocations } from './BackorderCommitment.hooks';
import { useBOCContextSelector, useBOCDispatch } from './BackorderCommitment.context';
import { IOrderDetailsHandle } from './PurchaseOrder/OrderDetails/OrderDetails.properties';
import { IDeliveriesHandle } from './PurchaseOrder/Deliveries/Deliveries.properties';
import { IPurchaseOrderLinesHandle } from './PurchaseOrder/Lines/Lines.properties';
import { IPurchaseOrderLineDetailsHandle } from './PurchaseOrder/PurchaseOrderLineDetails/PurchaseOrderLineDetails.properties';
import { usePurchaseOrderContext } from 'api/Worksale/backorderCommitment';
import NavigationTabs from '@markinson/uicomponents-v2/NavigationTabs';
import InternalOrderDialog from './InternalOrderDialog';
import { useSendNotification } from 'api/documentOutput';
import { INotificationFacade } from 'api/swaggerTypes';

const BackorderCommitment = (props: IBackorderCommitmentProps) => {
    const { classes, history, location, match, changeValidationDialog } = props;

    const { actionBarProps } = React.useContext(ActionBarContext);

    const url = match ? match.url : '';
    const path = match ? match.path : '';

    const commitmentGridRef = React.useRef<ICommitmentGridHandle>(null);
    const purchaseOrderLinesHandleRef = React.useRef<IPurchaseOrderLinesHandle>(null);
    const purchaseOrderLineDetailsHandleRef = React.useRef<IPurchaseOrderLineDetailsHandle>(null);
    const purchaseOrderDetailsHandleRef = React.useRef<IOrderDetailsHandle>(null);
    const purchaseDeliveriesHandleRef = React.useRef<IDeliveriesHandle>(null);

    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);
    const OpenInternalOrderDialog = useBOCContextSelector<'OpenInternalOrderDialog'>((state) => state.OpenInternalOrderDialog);
    const InternalOrderDialogData = useBOCContextSelector<'InternalOrderDialogData'>((state) => state.InternalOrderDialogData);
    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);

    const [disablePurchase, setDisablePurchase] = React.useState<boolean>(false);
    const [disableCommit, setDisableCommit] = React.useState<boolean>(false);
    const [lookupPanelOpen, setLookupPanelOpen] = React.useState<boolean>(false);

    const contextDispatch = useBOCDispatch();

    const { tabLocations } = useTabLocations(url, location);
    const fetchPurchaseOrderContextMutation = usePurchaseOrderContext();
    const sendNotificationMutation = useSendNotification();

    const tabsData = React.useMemo(() => getTabs({ PurchaseOrderId }), [PurchaseOrderId]);

    const { selectedTab, handleModuleChange, navigateBack } = useModule(
        history,
        location,
        'BackorderLines',
        tabLocations,
        modules,
    );

    const backorderOrLineCommitment = React.useMemo(
        () => selectedTab === 'BackorderLines' || selectedTab === 'LineCommitment',
        [selectedTab]
    );

    React.useEffect(
        () => {
            setDisablePurchase(location.state?.disablePurchase);
            setDisableCommit(location.state?.disableCommit);
        },
        [setDisablePurchase, setDisableCommit]
    );

    const showLineNotSelectedWarning = React.useCallback(
        () => {
            changeValidationDialog({
                messageBoxOpen: true,
                icon: 'ReportProblem',
                color: MessageBoxColor.Alert,
                title: 'Alert',
                form: null,
                message: 'One or more lines must be selected to proceed!',
                actions: [
                    {
                        name: 'Ok',
                        label: 'Ok',
                        isDefault: false,
                        callback: () => undefined
                    }
                ]
            });
        },
        []
    );

    const sendNotification = React.useCallback(
        async (data: INotificationFacade) => {
            const response = await sendNotificationMutation.mutateAsync({
                ...data
            });

            if (response?.Status) {
                contextDispatch({
                    setOpenInternalOrderDialog: false
                });
            }
        },
        []
    );

    const navigateToPurchaseLines = React.useCallback(
        async () => {
            const selectedLines = commitmentGridRef.current?.getSelectedRowKeys();

            if (isNull(selectedLines)) {
                showLineNotSelectedWarning();
            } else {
                const contextResponse = await fetchPurchaseOrderContextMutation.mutateAsync({
                    OrderNumber: SalesOrderId,
                    CommitLines: selectedLines?.map((l) => ({
                        lineNumber: l
                    }))
                });

                if (contextResponse?.Status) {
                    handleModuleChange('PurchaseOrderLines', { state: { selectedLines } });
                }
            }
        },
        [commitmentGridRef, SalesOrderId, handleModuleChange, showLineNotSelectedWarning]
    );

    const navigateToCommit = React.useCallback(
        () => {
            const selectedLines = commitmentGridRef.current?.getSelectedRowKeys();
            if (isNull(selectedLines)) {
                showLineNotSelectedWarning();
            } else {
                commitmentGridRef.current?.commitBackOrder();
            }
        },
        [commitmentGridRef, showLineNotSelectedWarning]
    );

    const handleActionBarButtonClick = React.useCallback(
        (action: string) => {
            switch (action) {
                case 'Purchase':
                    navigateToPurchaseLines();
                    break;
                case 'Commit':
                    navigateToCommit();
                    break;
                case 'Back':
                    navigateBack();
                    break;
                case 'Cancel':
                    handleModuleChange('SalesProcessing');
                    break;
                case 'PurchaseOrderLineDetailsOk':
                    purchaseOrderLineDetailsHandleRef.current.onOK(handleModuleChange);
                    break;
                case 'PurchaseOrderLineDetailsCancel':
                    purchaseOrderLineDetailsHandleRef.current.onCancel(handleModuleChange);
                    break;
                case 'PurchaseOrderDetailsOk':
                    purchaseOrderDetailsHandleRef.current.onOk(navigateBack);
                    break;
                case 'PurchaseOrderDetailsCancel':
                    purchaseOrderDetailsHandleRef.current.onCancel(navigateBack);
                    break;
                case 'PurchaseOrderDeliveriesOk':
                    purchaseDeliveriesHandleRef.current.onOK(handleModuleChange);
                    break;
                case 'PurchaseOrderDeliveriesCancel':
                    purchaseDeliveriesHandleRef.current.onCancel(handleModuleChange);
                    break;
                case 'PurchaseOrderLinesProcess':
                    purchaseOrderLinesHandleRef.current.onProcess();
                    break;
                case 'PurchaseOrderLinesCancel':
                    purchaseOrderLinesHandleRef.current.onCancel(handleModuleChange);
                    break;
                default:
            }
        },
        [
            purchaseOrderLineDetailsHandleRef,
            purchaseOrderDetailsHandleRef,
            purchaseDeliveriesHandleRef,
            purchaseOrderLinesHandleRef,
            handleModuleChange,
            navigateBack,
            navigateToPurchaseLines,
            navigateToCommit
        ]
    );

    const onCtrlL = React.useCallback(
        (e) => {
            navigateToPurchaseLines();
            e?.preventDefault();
        },
        [navigateToPurchaseLines]
    );

    const onEscape = React.useCallback(
        (e) => {
            if (selectedTab === 'PurchaseOrderLines') {
                handleActionBarButtonClick('PurchaseOrderLinesCancel');
                e?.preventDefault();
            }
        },
        [selectedTab]
    );

    const CtrlK = React.useCallback(
        (e) => {
            e?.preventDefault();
            navigateToCommit();
        },
        [navigateToCommit]
    );

    const handlers = useMemoizedKeyHandlers({
        Escape: onEscape,
        CtrlL: onCtrlL,
        CtrlK: CtrlK
    });

    const commitmentGridDetailOnClick = React.useCallback(
        (data) => {
            contextDispatch({
                setCommitmentLineNumber: data?.LineNumber
            });
            handleModuleChange('LineCommitment', { search: { SalesOrderId, LineNumber: data?.LineNumber } });
        },
        [SalesOrderId, handleModuleChange, contextDispatch]
    );

    const _handleModuleChange = React.useCallback(
        (tabToNavigate, inlineObject?: ILocationState) => {

            if (selectedTab === 'OrderDetails' && !purchaseOrderDetailsHandleRef.current?.isChangesSavedOrDiscarded()) {
                return;
            }

            if (selectedTab === 'Deliveries' && !purchaseDeliveriesHandleRef.current?.isChangesSavedOrDiscarded()) {
                return;
            }

            handleModuleChange(tabToNavigate, inlineObject);
        },
        [selectedTab, purchaseOrderDetailsHandleRef, handleModuleChange]
    );

    return (
        <HotKeys handlers={handlers}>
            <div className={classes.mainDiv}>
                <div className={classNames(
                    classes.centerContainer,
                    {
                        [classes.onLookupOpened]: lookupPanelOpen,
                        [classes.onLookupClosed]: !lookupPanelOpen,
                        [classes.hideMargin]: backorderOrLineCommitment
                    }
                )}>
                    <BreadcrumbBar
                        mainModule={'Sales Processing'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={_handleModuleChange}
                        onMainModuleClick={() => history.push('/sales-processing')}
                        hideOptionsMenu={true}
                    />
                    <BackorderSummaryPanel selectedTab={selectedTab} />
                    {tabsData.some(({ id }) => id === selectedTab) && (<NavigationTabs
                        tabsData={tabsData}
                        activeTabId={selectedTab}
                        lookupOpen={lookupPanelOpen}
                        onTabChange={_handleModuleChange}
                    />)}
                    <Route
                        key={'BackorderLines'}
                        path={path}
                        exact
                        render={() => <CommitmentGrid
                            innerRef={commitmentGridRef}
                            disableCommit={disableCommit}
                            disablePurchase={disablePurchase}
                            showDetailOnClick={commitmentGridDetailOnClick}
                        />
                        }
                    />
                    <Route
                        key={'LineCommitment'}
                        path={`${path.endsWith('/') ? path : `${path}/`}line-commitment`}
                        exact
                        render={() => (
                            <LineCommitment
                                changeSelectedTab={_handleModuleChange}
                                navigateBack={navigateBack}
                            />
                        )}
                    />
                    <Route
                        key={'PurchaseOrder'}
                        path={`${path.endsWith('/') ? path : `${path}/`}purchase-order`}
                        render={() => (
                            <PurchaseOrder
                                purchaseOrderLinesRef={purchaseOrderLinesHandleRef}
                                purchaseOrderLineDetailsRef={purchaseOrderLineDetailsHandleRef}
                                purchaseOrderDetailsRef={purchaseOrderDetailsHandleRef}
                                purchaseDeliveriesRef={purchaseDeliveriesHandleRef}
                                setLookupPanelOpen={setLookupPanelOpen}
                                changeSelectedTab={_handleModuleChange} />
                        )}
                    />
                    <InternalOrderDialog
                        open={OpenInternalOrderDialog}
                        value={InternalOrderDialogData?.inlineObject}
                        valueSchema={InternalOrderDialogData?.schema}
                        onCancel={() => contextDispatch({
                            setOpenInternalOrderDialog: false
                        })}
                        onOk={(data) => { sendNotification(data); }}
                    />
                </div>
                <ActionBar {...actionBarProps} onActionClick={handleActionBarButtonClick} />
            </div>
        </HotKeys >
    );
};

export default withRouter(withStyles(styles, { index: 1 })(BackorderCommitment));
