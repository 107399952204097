import React from 'react';
import classNames from 'classnames';
import { IPurchaseOrdersProps } from './PurchaseOrders.properties';
import PurchaseOrderSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/PurchaseOrders';
import { ILocationState, useModule } from 'utils/hooks';
import { TABS_DATA } from './PurchaseOrders.constants';
import modules from './PurchaseOrders.modules';
import { ActionBar, BreadcrumbBar, NavigationTabs } from '@markinson/uicomponents-v2';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './PurchaseOrders.options';
import { Route, withRouter } from 'react-router';
import Lines from './Lines';
import { withStyles } from '@material-ui/core';
import styles from './PurchaseOrders.styles';
import { useTabLocations } from './PurchaseOrders.hooks';
import { ILinesHandle } from './Lines/Lines.properties';
import OrderDetails from './OrderDetails';
import Delivery from './Delivery';
import { IDeliveryDetailsHandle } from './Delivery/Delivery.properties';
import { PurchaseOrdersActionTypes } from './PurchaseOrders.actions';
import { usePODispatch } from './PurchaseOrders.context';
import SummaryPanel from './SummaryPanel';
import { IOrderDetailsHandle } from './OrderDetails/OrderDetails.properties';

const PurchaseOrders = (props: IPurchaseOrdersProps) => {
    const { classes, match, history, location, isMenuOptionOpen, isPurchaseOrdersLookUpOpen, selectedPurchaseOrder, toggleMenuOption } = props;
    const contextDispatch = usePODispatch();
    const { actionBarProps } = React.useContext(ActionBarContext);
    const url = match ? match.url : '';
    const path = match ? match.path : '';
    const linesRef = React.useRef<ILinesHandle>();
    const detailsRef = React.useRef<IOrderDetailsHandle>();
    const deliveryRef = React.useRef<IDeliveryDetailsHandle>();
    const { tabLocations } = useTabLocations(url, location);
    const { selectedTab, handleModuleChange } = useModule(
        history,
        location,
        'Lines',
        tabLocations,
        modules,
    );

    React.useEffect(
        () => {
            if (!selectedPurchaseOrder) {
                handleModuleChange('Lines');
            } else {
                contextDispatch({
                    [PurchaseOrdersActionTypes.setPurchaseOrder]: selectedPurchaseOrder,
                });
            }
        },
        [selectedPurchaseOrder]
    );

    React.useEffect(
        () => {
            return () => {
                props.resetSearchLookupDrawer();
            };
        },
        []
    );

    const _handleModuleChange = React.useCallback(
        (tabToNavigate, inlineObject?: ILocationState) => {
            handleModuleChange(tabToNavigate, inlineObject ?? { search: null });
        },
        [selectedTab, selectedPurchaseOrder, handleModuleChange]
    );

    const handleToggleMenuOptionOpen = React.useCallback(
        () => {
            toggleMenuOption(!isMenuOptionOpen);
        },
        [isMenuOptionOpen, toggleMenuOption]
    );

    const handleActionBarButtonClick = React.useCallback(
        (action: string) => {
            switch (action) {
                case 'Back':
                    switch (selectedTab) {
                        case 'Details':
                            _handleModuleChange('Lines');
                            break;
                        default:
                    }
                    break;
                case 'OrderDetailsOk':
                case 'OrderDetailsCancel':
                    detailsRef.current?.[action](() => { _handleModuleChange('Lines'); });
                    break;
                default:
            }
        },
        [selectedTab, selectedPurchaseOrder, detailsRef, _handleModuleChange, handleModuleChange]
    );

    return (
        <div className={classes.mainDiv}>
            <div className={classes.innerContainer}>
                <PurchaseOrderSearchLookUpDrawer enableToggle disabled={false} />
                <div
                    className={classNames(classes.PSSection, {
                        [classes.PSSectionMarginOpen]: isPurchaseOrdersLookUpOpen,
                        [classes.PSSectionMarginClose]: !isPurchaseOrdersLookUpOpen
                    })}
                >
                    <BreadcrumbBar
                        mainModule={'Purchase Orders'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={_handleModuleChange}
                        onMainModuleClick={() => _handleModuleChange('Lines')}
                        onOptionsMenuClick={handleToggleMenuOptionOpen}
                    />
                    <SummaryPanel />
                    {TABS_DATA.some(({ id }) => id === selectedTab) && <NavigationTabs
                        lookupOpen={isPurchaseOrdersLookUpOpen}
                        tabsData={TABS_DATA}
                        activeTabId={selectedTab}
                        onTabChange={_handleModuleChange}
                    />}
                    <Route
                        key={'Lines'}
                        path={path}
                        exact
                        render={() => <Lines forwardRef={linesRef} changeSelectedTab={handleModuleChange} />}
                    />
                    <Route
                        key={'PurchaseOrderDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}order-details`}
                        exact
                        render={() => <OrderDetails  innerRef={detailsRef} PurchaseOrder={selectedPurchaseOrder} />}
                    />
                    <Route
                        key={'Delivery'}
                        path={`${path.endsWith('/') ? path : `${path}/`}delivery`}
                        exact
                        render={() => <Delivery ref={deliveryRef} />}
                    />
                </div>
            </div>
            <OptionsMenu
                open={isMenuOptionOpen}
                options={options.PS}
                onOptionClick={_handleModuleChange}
                defaultValue={selectedTab}
            />
            <ActionBar {...actionBarProps} onActionClick={handleActionBarButtonClick} />

        </div >
    );
};

export default withRouter(withStyles(styles, { index: 1 })(PurchaseOrders));
