import { Payload, useFetchMutation, usePost, useUpdate } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { toObjectified } from 'api/baseApi';
import { IObjectified, Inline, objectify } from 'api/utils';
import { ICalculateComponentDetailsFacade, IComponentDetailFacade, IComponentFastLineDetailsResponse, IComponentLineDetailFacade, IComponentLineDetailResponse, IDeleteRecordResponse, IGetComponentsResponse, ILoadComponentsResponse, IWIPComponentDetailResponse, IWIPComponentFacade, IGetWIPComponentKitHeaderSummaryResponse, IWIPComponentKitHeaderSummaryFacade  } from 'api/swaggerTypes';

const kitComponentLineDetailParser = (response) => {

    if (response) {
        return {
            ...response,
            ComponentLineDetail: response.ComponentLineDetail && toObjectified(response.ComponentLineDetail as Inline<IComponentLineDetailFacade>)
        };
    }
};

export type IObjectifiedComponentLineDetailResponse = Omit<IComponentLineDetailResponse, 'ComponentLineDetail'> & {
    ComponentLineDetail: IObjectified<IComponentLineDetailFacade>;
};

export const useFetchKitComponentLineDetail = () =>
    useFetchMutation<Payload<{ ComponentId: number }>, IObjectifiedComponentLineDetailResponse>(
        APIRoutes.pickSlips.components.Line.retrieve,
        null,
        {
            inline: true,
            responseParser: kitComponentLineDetailParser
        }
    );

export type IObjectifiedKitSummaryResponse = Omit<IGetWIPComponentKitHeaderSummaryResponse, 'WorksalelineKitSummary'> & {
    kitSummary: IObjectified<IWIPComponentKitHeaderSummaryFacade>;
    };

const kitSummaryParser = (response) => {

    if (response) {
        return {
            ...response,
            kitSummary: response.WIPKitHeaderSummary && toObjectified(response.WIPKitHeaderSummary as Inline<IObjectifiedKitSummaryResponse>)
        };
    }
};
export const useFetchKitSummary = () =>
    useFetchMutation<Payload<{ DocumentId: number }>, IObjectifiedKitSummaryResponse>(
        APIRoutes.pickSlips.components.summary,
        null,
        {
            inline: true,
            responseParser: kitSummaryParser
        }
    );

const componentLinesResponseParser = (response) => ({
    ...response,
    Components: (response?.Components) ? response.Components.map((c, _i) => objectify(c)) : []
});
export const useLoadComponents = () =>
    usePost<Payload<{ DocumentId: number; BomCode?: string; UpdateKit?: boolean }>, ILoadComponentsResponse>(
        APIRoutes.pickSlips.components.load,
    );

export const useFetchComponentLines = () =>
    useFetchMutation<Payload<{ DocumentId: number }, { BatchPage?: number; BatchSize?: number }>, IGetComponentsResponse>(
        APIRoutes.pickSlips.components.retrieve,
        null,
        {
            inline: true,
            responseParser: componentLinesResponseParser
        }
    );

export const useDeleteComponentLine = () =>
    usePost<Payload<{ ComponentId: number }>, IDeleteRecordResponse>(
        APIRoutes.pickSlips.components.deleteLine
    );

export type IObjectifiedComponentFastLineDetailsResponse = Omit<IComponentFastLineDetailsResponse, 'ComponentDetails'> & {
    ComponentDetails: IObjectified<ICalculateComponentDetailsFacade>;
};

const calculateComponenetFastlineResponseParser = (response) => ({
    ...response,
    ComponentDetails: response.ComponentDetails && toObjectified(response.ComponentDetails as Inline<ICalculateComponentDetailsFacade>)
});

export const useCalculateComponentFastline = () =>
    useFetchMutation<Payload<{ DocumentId: number }, { ProductId?: number; Quantity?: number }>, IObjectifiedComponentFastLineDetailsResponse>(
        APIRoutes.pickSlips.components.calculateComponent,
        null,
        {
            inline: true,
            responseParser: calculateComponenetFastlineResponseParser
        }
    );

const addComponenetResponseParser = (response) => ({
    ...response,
    ComponentLine: response.ComponentLine && toObjectified(response.ComponentLine as Inline<IWIPComponentFacade>)
});

export const useAddComponent = () =>
    useUpdate<Payload<{ DocumentId: number }, { ProductId?: number; RequiredQuantity?: number; SuppliedQuantity?: number; BackorderedQuantity?: number }>, IWIPComponentDetailResponse>
        (APIRoutes.pickSlips.components.addComponent, null, { inline: true, responseParser: addComponenetResponseParser });

const componentFastlineDetailsResponseParser = (response) => {

    return {
        ...response,
        ComponentDetails: response.ComponentDetails && toObjectified(response.ComponentDetails as Inline<ICalculateComponentDetailsFacade>)
    };

};

export type IObjectifiedWIPComponentDetailResponse = Omit<IWIPComponentDetailResponse, 'ComponentDetails'> & {
    ComponentLine: IObjectified<IWIPComponentFacade>;
};

export const useCalculateComponentGridLineDetails = () =>
    usePost<Payload<ICalculateComponentDetailsFacade & { ComponentId: number }, { ChangedField: string }>, IObjectifiedComponentFastLineDetailsResponse>
        (APIRoutes.pickSlips.components.Line.calculateDetail, null, { inline: true, responseParser: componentFastlineDetailsResponseParser });

export const useUpdateComponentGridLineDetails = () =>
    usePost<Payload<IWIPComponentFacade & { ComponentId: number }>, IObjectifiedWIPComponentDetailResponse>
        (APIRoutes.pickSlips.components.Line.updateDetail, null, { inline: true, responseParser: addComponenetResponseParser });

export const useUpdateComponentLineDetails = () =>
    usePost<Payload<IComponentDetailFacade & { ComponentId: number }>, IObjectifiedComponentLineDetailResponse>
        (APIRoutes.pickSlips.components.Line.update, null, { inline: true, responseParser: kitComponentLineDetailParser });

export const useCalculateComponentLineDetails = () =>
    usePost<Payload<IComponentDetailFacade & { ComponentId: number }, { ChangedField: string }>, IObjectifiedComponentLineDetailResponse>
        (APIRoutes.pickSlips.components.Line.calculate, null, { inline: true, responseParser: kitComponentLineDetailParser });
