import { combineReducers } from 'redux';
import childAccounts, { IChildAccountsState } from './childAccounts';
import balances, { IBalancesState } from './balances';

export interface ISupplierFinancialState {
  childAccounts: IChildAccountsState;
  balances: IBalancesState;
}

const supplierFinancialReducer = combineReducers<ISupplierFinancialState>({
  childAccounts,
  balances
});

export default supplierFinancialReducer;
