const LineDetails = {
  'id': 'LineDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Ordered',
            'size': 'medium',
            'required': false,
            'name': 'QtyOrdered',
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Supplied',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'QtySuppliedDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'On order',
            'size': 'medium',
            'required': false,
            'name': 'QtyOnOrderDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Base cost',
            'size': 'medium',
            'required': false,
            'name': 'BasePriceDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_DISPLAY_FIELD',
          'visible': true,
          'props': {
            'label': 'Discount',
            'placeholder': '',
            'size': 'small',
            'name': 'Discount',
            'type': 'number',
            'decimalSeparator': true,
            'adornment': '%',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'PriceDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Currency',
            'size': 'small',
            'required': false,
            'name': 'Currency',
          }
        },
        {
          'id': 7,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Extended',
            'size': 'medium',
            'required': false,
            'name': 'ExtendedTotalDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 8,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Special price',
            'required': false,
            'name': 'SpecialPrice',
          }
        },
        {
          'id': 9,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Estimated',
            'required': false,
            'name': 'Estimated',
          }
        },
        {
          'id': 10,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax included price',
            'required': false,
            'name': 'TaxIncludedPrice',
          }
        },
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Stocking unit',
            'size': 'small',
            'name': 'UnitCode',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Buy conversion factor',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'BuyConvFactorDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'In transit',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'QtyIntransitDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Committed',
            'size': 'medium',
            'required': false,
            'name': 'QtyCommitted',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_AREA',
          'props': {
            'name': 'Comment',
            'label': 'Comment',
            'fullWidth': true,
            'style': {
              'height': '200px'
            }
          }
        }
      ]
    }
  ]
}
export default LineDetails;