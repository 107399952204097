import { OPERATIONS } from 'utils/operations';
import { pathOr, isNull } from 'utils/utils';


const inlineStyles = {
  greenIconStyle: {
    fill: 'rgba(109, 217, 0, 1)'
  },
  redIconStyle: {
    fill: 'rgba(178, 0, 0, 1)'
  },
  navigationItemStyle: {
    width: '50px',
    boxSizing: 'border-box',
  }
};



const BACK_BUTTON = {
  label: 'Back',
  iconName: 'ArrowBack',
  iconStyle: inlineStyles.iconStyle,
  action: OPERATIONS.BACK,
  disabled: (currentMode) => {
    return false;
  }
};

const SAVE_BUTTON = {
  label: 'OK',
  iconName: 'CheckCircle',
  iconStyle: inlineStyles.greenIconStyle,
  action: OPERATIONS.SAVE,
  disabled: (currentMode) => {
    return currentMode !== OPERATIONS.BROWSE;
  }
};

const CANCEL_BUTTON = {
  label: 'Cancel',
  iconName: 'Cancel',
  iconStyle: inlineStyles.redIconStyle,
  action: OPERATIONS.CANCEL,
  disabled: (currentMode) => {
    return currentMode !== OPERATIONS.EDIT && currentMode !== OPERATIONS.NEW;
  }
};

const EDIT_BUTTON = {
  label: 'Edit',
  iconName: 'Edit',
  action: OPERATIONS.EDIT,
  disabled: (currentMode, disable) => {
    return currentMode !== OPERATIONS.BROWSE || disable();
  }
};

const DELETE_BUTTON = {
  label: 'Delete',
  iconName: 'RemoveCircle',
  action: OPERATIONS.DELETE,
  disabled: (currentMode, disable) => {
    return currentMode !== OPERATIONS.BROWSE || disable();
  }
};

const NEW_BUTTON = {
  label: 'New',
  iconName: 'AddCircle',
  action: OPERATIONS.NEW,
  disabled: (currentMode) => {
    return currentMode !== OPERATIONS.BROWSE;
  }
};

const initialState = {
  'customer-maintenance':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      NEW_BUTTON,
      EDIT_BUTTON,
      DELETE_BUTTON,
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'activity-maintenance':
  {
    'Capabilities': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...NEW_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.NEW;
          }
        },
        DELETE_BUTTON,
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    },
    'Checklists': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [
        {
          label: 'Add Checklist',
          iconName: 'AddCircle',
          action: OPERATIONS.NEW,
          disabled: () => false
        }
      ],
      rightIcons: [
        {
          ...CANCEL_BUTTON,
          disabled: () => false
        },
        {
          ...SAVE_BUTTON,
          disabled: () => false
        }
      ]
    },
    default: {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...NEW_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.NEW;
          }
        },
        EDIT_BUTTON,
        DELETE_BUTTON,
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    }
  },
  'job-template-maintenance':
  {
    'Activities': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...NEW_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.NEW;
          }
        },
        DELETE_BUTTON,
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    },
    default: {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...NEW_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.NEW || currentMode === OPERATIONS.EDIT;
          }
        },
        EDIT_BUTTON,
        DELETE_BUTTON,
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    }
  },
  'customer-enquiry': {
    'CustomerDetails':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: []
    }
  },
  'credit-notes': {
    'CNLines':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [{
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return false;
        }
      },
      {
        label: 'Process',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          return false;
        }
      }]
    },
    'CNDetails':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [{
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return false;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return false;
        }
      }]
    },
    'CNLineDetails':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [{
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return false;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return false;
        }
      }]
    },
    'PaymentDetails':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          label: 'Confirm',
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    }
  },
  'roster-maintenance':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      NEW_BUTTON,
      EDIT_BUTTON,
      DELETE_BUTTON,
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'default':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: []
  },
  'sales-order-enquiry': {
    'default':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: []
    }
  },
  'price-check': {
    'default': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [
        {
          label: 'Calculator',
          iconName: 'Calculator',
          iconStyle: inlineStyles.iconStyle,
          action: OPERATIONS.CALCULATOR,
          disabled: (currentMode) => {
            return false;
          }
        }
      ],
      rightIcons: []
    }
  },

  'security-policies': {
    'PolicyDetails': {
      leftIcons: [],
      centerIcons: [],
      rightIcons: [
        NEW_BUTTON,
        EDIT_BUTTON,
        DELETE_BUTTON,
        CANCEL_BUTTON,
        { ...SAVE_BUTTON, label: 'Save', disabled: (currentMode) => { return currentMode !== OPERATIONS.EDIT && currentMode !== OPERATIONS.NEW } }
      ],
    },
    'default': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [],
    }
  },
  'pick-slips':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'PRINT',
        iconName: 'Assignment',
        action: OPERATIONS.PRINT,
        disabled: () => true
      }],
    rightIcons: [
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          return currentMode !== OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'pick-slips-lines': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      {
        ...EDIT_BUTTON,
        disabled: (currentMode) => {
          return true;
        }
      },
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return true;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return true;
        }
      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
        disabled: (currentMode) => {
          return true;
        }
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          // return currentMode !== OPERATIONS.BROWSE; making this action disable for first release
          return true;
        }
      }
    ]
  },
  'pick-slips-lines-details': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Picking',
        iconName: 'AddCircle',
        action: OPERATIONS.PARK,
        disabled: () => true
      },
      {
        label: 'Bom',
        iconName: 'Assignment',
        action: OPERATIONS.BOM,
        disabled: () => true
      }
    ],
    rightIcons: [
      EDIT_BUTTON,
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          // return currentMode !== OPERATIONS.BROWSE; making this action disable for first release
          return true;
        }
      }
    ]
  },
  'pick-slips-kit-component-detail': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Picking',
        iconName: 'AddCircle',
        action: OPERATIONS.PARK,
        disabled: () => true
      }
    ],
    rightIcons: [
      EDIT_BUTTON,
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          // return currentMode !== OPERATIONS.BROWSE; making this action disable for first release
          return true;
        }
      }
    ]
  },
  'pick-slips-lines-lots': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Picking',
        iconName: 'AddCircle',
        action: OPERATIONS.PARK,
        disabled: () => true
      },
      {
        label: 'Bom',
        iconName: 'Assignment',
        action: OPERATIONS.BOM,
        disabled: () => true
      }
    ],
    rightIcons: [
      {
        ...EDIT_BUTTON,
        disabled: () => true
      },
      {
        ...CANCEL_BUTTON,
        disabled: () => true

      },
      {
        ...SAVE_BUTTON,
        disabled: () => true

      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
        disabled: () => true
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          // return currentMode !== OPERATIONS.BROWSE; making this action disable for first release
          return true;
        }
      }
    ]
  },
  'pick-slips-kit-components': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Add Component',
        iconName: 'AddCircle',
        action: OPERATIONS.BOM,
        disabled: () => true
      }
    ],
    rightIcons: [
      {
        ...EDIT_BUTTON,
        disabled: () => true
      },
      {
        ...CANCEL_BUTTON,
        disabled: () => true

      },
      {
        ...SAVE_BUTTON,
        disabled: () => true

      },
      {
        ...DELETE_BUTTON,
        label: 'Remove',
        disabled: () => true
      },
      {
        label: 'Finalize',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          // return currentMode !== OPERATIONS.BROWSE; making this action disable for first release
          return true;
        }
      }
    ]
  },
  'cash-receipting':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Allocate',
        iconName: 'AddCircle',
        iconStyle: inlineStyles.iconStyle,
        action: OPERATIONS.ALLOCATE,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }],
    rightIcons: [
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        label: 'Process',
        iconName: 'ProcessIcon',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'allocate':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        label: 'Ok',
        iconName: 'CheckCircle',
        iconStyle: inlineStyles.greenIconStyle,
        action: OPERATIONS.SAVE,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'cash-receipt-invoices':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Criteria',
        iconName: 'PlaylistAddCheck',
        iconStyle: inlineStyles.iconStyle,
        action: OPERATIONS.CRITERIA,
        disabled: (currentMode) => {
          return false;
        }
      },
    ],
    rightIcons: [
      {
        ...NEW_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'cash-receipt-orders':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'payment-details':
  {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [
      {
        ...CANCEL_BUTTON,
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      },
      {
        ...SAVE_BUTTON,
        label: 'Confirm',
        disabled: (currentMode) => {
          return currentMode === OPERATIONS.BROWSE;
        }
      }
    ]
  },
  'service-activity-scheduling': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [
      {
        label: 'Previous',
        iconName: 'ChevronLeft',
        action: OPERATIONS.PREVIOUS_PERIOD,
        disabled: (currentMode) => false
      },
      {
        label: 'Week',
        iconName: 'CalendarToday',
        action: OPERATIONS.CURRENT_PERIOD,
        disabled: (currentMode) => false
      },
      {
        label: 'Next',
        iconName: 'ChevronRight',
        action: OPERATIONS.NEXT_PERIOD,
        disabled: (currentMode) => false
      },
    ],
    rightIcons: [
      {
        label: 'Upcoming Job',
        iconName: 'ArrowUpward',
        showBadge: true,
        badgeCount: 0,
        action: OPERATIONS.ALLOCATE,
        disabled: (currentMode) => {
          return currentMode !== OPERATIONS.BROWSE;
        }
      },
      {
        label: 'New Job',
        iconName: 'AddCircle',
        action: OPERATIONS.NEW,
        disabled: (currentMode) => {
          return currentMode !== OPERATIONS.BROWSE;
        }
      },
      {
        label: 'Post Roster',
        iconName: 'Description',
        action: OPERATIONS.PROCESS,
        disabled: (currentMode) => false
      },
      {
        label: 'Review Job',
        iconName: 'CheckCircle',
        showBadge: true,
        badgeCount: 0,
        action: OPERATIONS.REVIEW,
        disabled: (currentMode) => false
      },
    ]
  },
  'worksale': {
    leftIcons: [BACK_BUTTON],
    centerIcons: [],
    rightIcons: [CANCEL_BUTTON]
  },
  'common': {
    'Notepad':
    {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        {
          ...EDIT_BUTTON,
          disabled: (currentMode) => {
            return currentMode !== OPERATIONS.BROWSE;
          }
        },
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    },
    'SalesDiary': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [NEW_BUTTON]
    },
    'DiaryDetails': {
      leftIcons: [BACK_BUTTON],
      centerIcons: [],
      rightIcons: [
        NEW_BUTTON,
        EDIT_BUTTON,
        DELETE_BUTTON,
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    }

  }
};

export default (state = initialState, action) => {

  return state;

};

const actionBarData = (scope, tab, state, diaryOperations) => {
  if (tab === 'SalesDiary' || tab === 'DiaryDetails' || tab === 'CreditDiary' || tab === 'CreditDiaryDetails' || tab === 'PurchaseDiary' || tab === 'PurchaseDiaryDetails') {
    return {
      ...initialState['common'][tab],
      rightIcons: tab === 'SalesDiary' || tab === 'CreditDiary' || tab === 'PurchaseDiary' ? [
        {
          ...NEW_BUTTON,
          disabled: (currentMode) => {
            return currentMode !== OPERATIONS.BROWSE || tab === 'SalesDiary' && isRecordSelected(state, SelectedDiaryID[scope]);
          } 
        }
      ]
        :
        [
          NEW_BUTTON,
          {
            ...EDIT_BUTTON,
            disabled: (currentMode, disable) => {
              return currentMode !== OPERATIONS.BROWSE || diaryOperations || disable();
            }
          },
          {
            ...DELETE_BUTTON,
            disabled: (currentMode, disable) => {
              return currentMode !== OPERATIONS.BROWSE || diaryOperations || disable();
            }
          },
          {
            ...CANCEL_BUTTON,
            disabled: (currentMode) => {
              return currentMode === OPERATIONS.BROWSE;
            }
          },
          {
            ...SAVE_BUTTON,
            disabled: (currentMode) => {
              return currentMode === OPERATIONS.BROWSE;
            }
          }]
    };
  }
  else if (tab === 'Notepad') {

    return {
      ...initialState['common'][tab],
      rightIcons: [
        {
          ...EDIT_BUTTON,
          disabled: (currentMode) => {
            return currentMode !== OPERATIONS.BROWSE || (Object.keys(SelectedNotepadID).includes(scope) && isRecordSelected(state, SelectedNotepadID[scope]));
          }
        },
        {
          ...CANCEL_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        },
        {
          ...SAVE_BUTTON,
          disabled: (currentMode) => {
            return currentMode === OPERATIONS.BROWSE;
          }
        }
      ]
    };
  } else if (tab === 'Allocate') {

    const isDepositCustomer = pathOr(false, ['cashReceipting', 'allocate', 'data', 'depositCustomer'], state);

    return {
      ...initialState[scope],
      centerIcons: [
        {
          label: 'Invoices',
          iconName: 'Description',
          iconStyle: inlineStyles.iconStyle,
          action: OPERATIONS.INVOICES,
          disabled: (currentMode) => {
            return isDepositCustomer;
          }
        },
        {
          label: 'Orders',
          iconName: 'ListAlt',
          iconStyle: inlineStyles.iconStyle,
          action: OPERATIONS.ORDERS,
          disabled: (currentMode) => {
            return !isDepositCustomer;
          }
        }
      ],
    }

  } else if (scope === 'pick-slips-lines') {
    return {
      ...initialState[scope],
      centerIcons: [
        {
          label: 'Add Charge',
          iconName: 'AddCircle',
          action: OPERATIONS.ADD_CHARGE,
          disabled: () => disableAddCharge(state)
        }]
    }
  } else if (scope === 'pick-slips-lines-details') {
    if (tab === 'SerialNumber') {
      return {
        ...initialState[scope],
        rightIcons: [
          ...initialState[scope].rightIcons,
          SAVE_BUTTON,
        ]
      }
    }
    return {
      ...initialState[scope],
      centerIcons: [
        {
          label: 'Picking',
          iconName: 'AddCircle',
          action: OPERATIONS.PICKING,
          disabled: () => disablePickSlipPicking(state)
        },
        {
          label: 'Bom',
          iconName: 'Assignment',
          action: OPERATIONS.BOM,
          disabled: () => disablePickSlipBOM(state)
        }
      ]
    }
  } else if (scope === 'pick-slips') {
    return {
      ...initialState[scope],
      rightIcons: [
        {
          ...EDIT_BUTTON,
          disabled: () => {
            const result = disablePickSlipEdit(state);
            return result;
          }
        },
        ...initialState[scope].rightIcons
      ]
    }
  } else if (scope === 'roster-maintenance') {
    return {
      ...initialState[scope],
      rightIcons: initialState[scope].rightIcons.map(btn => {
        const conditionalDisabledBtns = ['Edit', 'Delete'];
        if (conditionalDisabledBtns.includes(btn.label)) {

          return {
            ...btn,
            disabled: (currentMode) => {
              const result = disableRosterMaintenanceEditDelete(state);

              return result || currentMode === OPERATIONS.EDIT || currentMode === OPERATIONS.NEW;
            }
          }
        }

        return btn;
      })
    }
  }
  else if (scope === 'activity-maintenance') {
    if (tab === 'Capabilities') {
      return {
        ...initialState[scope][tab],
        rightIcons: initialState[scope][tab].rightIcons.map(btn => {
          const conditionalDisabledBtns = ['Delete'];
          if (conditionalDisabledBtns.includes(btn.label)) {
            return {
              ...btn,
              disabled: (currentMode) => {
                const result = disableCapabilitiesDelete(state);
                return result || currentMode === OPERATIONS.EDIT || currentMode === OPERATIONS.NEW;
              }
            }
          }

          return btn;
        })
      }
    }
    else if (tab === 'ActivityDetails') {
      return {
        ...initialState[scope]['default'],
        rightIcons: initialState[scope]['default'].rightIcons.map(btn => {
          const conditionalDisabledBtns = ['Delete', 'Edit'];
          if (conditionalDisabledBtns.includes(btn.label)) {
            return {
              ...btn,
              disabled: (currentMode) => {
                const result = disableActivityDelete(state);
                return result || currentMode === OPERATIONS.EDIT || currentMode === OPERATIONS.NEW;
              }
            }
          }

          return btn;
        })
      }
    }
    else {
      return initialState[scope] && initialState[scope][tab];
    }
  } else if (scope === 'service-activity-scheduling') {
    return {
      ...initialState[scope],
      rightIcons: initialState[scope].rightIcons.map(btn => {
        const conditionalDisabledBtns = ['Upcoming Job', 'New Job', 'Post Roster', 'Review Job'];
        if (conditionalDisabledBtns.includes(btn.label)) {

          const reviewJobsCount = pathOr(0, ['serviceActivityScheduling', 'serviceActivity', 'data', 'reviewJobsData', 'length'], state);
          const upcomingServiceJobsCount = pathOr(0, ['serviceActivityScheduling', 'serviceActivity', 'data', 'upcomingServiceJobs', 'length'], state);

          if (btn.label === 'Review Job') {
            return {
              ...btn,
              badgeCount: reviewJobsCount,
              disabled: () => disableServiceActivityNew(state) || reviewJobsCount === 0
            }
          } else if ('Upcoming Job') {
            return {
              ...btn,
              badgeCount: upcomingServiceJobsCount,
              disabled: () => disableServiceActivityNew(state)
            }
          } else {
            return {
              ...btn,
              disabled: () => disableServiceActivityNew(state)
            }
          }

        }

        return btn;
      })
    }
  } else if (tab === 'JobTemplateDetails') {
    return {
      ...initialState[scope].default,
      rightIcons: initialState[scope].default.rightIcons.map(btn => {
        const conditionalDisabledBtns = ['Delete', 'Edit'];
        if (conditionalDisabledBtns.includes(btn.label)) {
          return {
            ...btn,
            disabled: (currentMode) => {
              const result = isRecordSelected(state, ['jobTemplateMaintenance', 'jobTemplate', 'data', 'selected']);
              return result || currentMode === OPERATIONS.EDIT || currentMode === OPERATIONS.NEW;
            }
          }
        }

        return btn;
      })
    }
  }
  else if (initialState[scope] && initialState[scope][tab]) {
    return initialState[scope] && initialState[scope][tab];
  }
  else if (initialState[scope] && initialState[scope]['default']) {
    return initialState[scope] && initialState[scope]['default']
  }
  else if (initialState[scope]) {
    return initialState[scope];
  } else {
    return initialState['default'];
  }

}

const SelectedDiaryID = {
  'sales-order-enquiry': ['salesOrderEnquiry', 'salesOrder', 'data', 'selected'],
  'sales-invoice-enquiry': ['salesInvoiceEnquiry', 'invoice', 'data', 'selected'],
  'customer-quote-enquiry': ['customerQuoteEnquiry', 'quote', 'data', 'selected'],
  'purchase-order-enquiry': ['purchaseOrderEnquiry', 'purchaseOrder', 'data', 'selected'],
}

const SelectedNotepadID = {
  ...SelectedDiaryID,
  'inventory-enquiry': ['SearchLookUp', 'productLookup', 'data', 'selected'],
}

function getSelectedDiaryOperations(state) {
  return state.common.diary.data.selected && ((state.common.diary.data.selected.SystemGenerated))
}

function disableAddCharge(state) {
  return !pathOr(false, ['pickSlips', 'slipDetails', 'data', 'slipDetails', 'inlineObject', 'EnableChargeButton'], state)
}

function disablePickSlipPicking(state) {
  return !pathOr(false, ['pickSlips', 'slipLines', 'data', 'slipLineDetails', 'values', 'EnablePicking'], state)
}

function disablePickSlipBOM(state) {
  return !pathOr(false, ['pickSlips', 'slipLines', 'data', 'slipLineDetails', 'values', 'EnableBom'], state)
}

function disablePickSlipEdit(state) {
  const pickSlipStatus = pathOr('', ['pickSlips', 'slipDetails', 'data', 'slipDetails', 'inlineObject', 'Status'], state);
  return (pickSlipStatus === 'Invoice cancelled' || pickSlipStatus === 'Cancelled');
}

function disableRosterMaintenanceEditDelete(state) {
  const selectedRosterTemplateId = pathOr('', ['rosterMaintenance', 'rosterEntries', 'data', 'selected'], state);
  return (selectedRosterTemplateId === -1 || isNull(selectedRosterTemplateId));
}

function disableCapabilitiesDelete(state) {
  const capabilityId = pathOr('', ['activityMaintenance', 'capability', 'data', 'selected'], state);
  return (capabilityId === -1 || isNull(capabilityId));
}

function disableActivityDelete(state) {
  const capabilityId = pathOr('', ['activityMaintenance', 'activity', 'data', 'selected'], state)
  return (capabilityId === -1 || isNull(capabilityId));
}

function disableServiceActivityNew(state) {
  const salesEntity = pathOr('', ['serviceActivityScheduling', 'serviceActivity', 'data', 'salesEntity'], state);
  return (salesEntity === -1 || isNull(salesEntity));
}

function isRecordSelected(state, SelectedScope) {
  const selectedId = pathOr('', SelectedScope, state);
  return (selectedId === -1 || isNull(selectedId));
}

export const selectors = {
  actionBar: state => actionBarData(state.ui.scope, state.ui.selectedTab, state, getSelectedDiaryOperations(state))
}
