import { takeLatest, Effect } from 'redux-saga/effects';
import { types as productPriceScheduleTypes, actions as productPriceScheduleActions } from 'ducks/supplierProductEnquiry/productPriceSchedule';
import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierProductEnquiry/productPriceSchedule';

export default function* rootProductPriceScheduleSaga(): IterableIterator<Effect> {
  yield takeLatest(productPriceScheduleTypes.fetchDetails, connectSagaToApi(productPriceScheduleActions, 'fetchDetails', api.fetchDetails));
  yield takeLatest(productPriceScheduleTypes.fetchSettings, connectSagaToApi(productPriceScheduleActions, 'fetchSettings', api.fetchSettings));
  yield takeLatest(productPriceScheduleTypes.fetchSellPrices, connectSagaToApi(productPriceScheduleActions, 'fetchSellPrices', api.fetchSellPrices));
  yield takeLatest(productPriceScheduleTypes.fetchComments, connectSagaToApi(productPriceScheduleActions, 'fetchComments', api.fetchComments));
}
