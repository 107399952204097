import { IFormViewForm } from 'components/FormView';

const UserIdScreenDialogForm: IFormViewForm = {
  id: 'UserIdScreenDialogForm',
  layout: {
    rows: 1,
    columns: 1,
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'UserId',
        fullWidth: true,
        elevation: 0
      },
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      children: [
        {
          id: 0,
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: '',
            name: 'Message'
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'User ID',
            name: 'UserId',
            lookupName: 'Personnel',
            size: 'medium',
            required: true,
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Pin',
            name: 'Pin',
            placeholder: '****',
            size: 'medium',
            type: 'password',
            inputProps: {
              maxLength: 4
            }
          }
        },
      ]
    }
  ]
};

export default UserIdScreenDialogForm;
