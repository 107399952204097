import { IFormViewForm } from 'components/FormView';

const KitComponentDetail: IFormViewForm = {
    id: 'KitComponentDetail',
    layout: {
        rows: 2,
        columns: 3
    },
    fields: [
        {
            id: 1,
            position: {
                row: 1,
                col: 1,
                colspan: 1.25
            },
            type: 'PAPER_CONTAINER',
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: 0,
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        label: 'Product',
                        name: 'ProductId',
                        size: 'medium',
                        lookupName: 'Product',
                        suppressDescription: true
                    }
                },
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Description',
                        name: 'Description',
                        placeholder: '',
                        size: 'large'
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Required',
                        name: 'Required',
                        placeholder: '',
                        size: 'medium',
                        type: 'number'
                    }
                },
                {
                    id: 5,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Supplied',
                        name: 'Supplied',
                        placeholder: '',
                        size: 'medium',
                        type: 'number'
                    }
                },
                {
                    id: 6,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Backorder',
                        name: 'BackOrder',
                        placeholder: '',
                        size: 'medium',
                        type: 'number'
                    }
                }
            ]
        },
        {
            id: 1,
            position: {
                row: 1,
                col: 2,
                colspan: 1.25
            },
            type: 'PAPER_CONTAINER',
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: 0,
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Comment',
                        name: 'Comment',
                        fullWidth: true,
                        style: {
                            height: '300px'
                        }
                    }
                }
            ]
        }
    ]
};
export default KitComponentDetail;
