import React, { Component } from 'react';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandLess from '@markinson/uicomponents-v2/SvgIcons/ExpandLess';
import ExpandMore from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';


const styles = theme => ({
  listItem: {
    paddingLeft: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  menuLevel1Text: theme.menuLevel1Text,
  menuLevel2Text: theme.menuLevel2Text,
})

class Administration extends Component {

  constructor() {
    super();
    this.state = { open: true };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes } = this.props;

    const Admin = [
      {
        label: 'MomentumPro Services'
      },
      {
        label: 'Event Viewer'
      },
      {
        label: 'Users'
      },
      {
        label: 'Security'
      },
      {
        label: 'Maintenance'
      },
      {
        label: 'Operations'
      },
      {
        label: 'Configurations'
      },
      {
        label: 'Diagnostics',
        children: [
          {
            label: 'Inventory Diagnostics'
          },
          {
            label: 'A/R Diagnostics'
          },
          {
            label: 'Purchasing Diagnostics'
          },
          {
            label: 'Service Diagnostics'
          },
          {
            label: 'Production Diagnostics'
          },
          {
            label: 'Financial Integrity Check'
          }
        ]
      }
    ];
    const Information = [
      'MomentumPro Users',
      'MomentumPro Severs'
    ];
    const ServerFiles = [
      'Shared Files'
    ];
    const Tasks = [
      'Run a Batch Suite'
    ];

    const NestedIcon = () => (
      this.state.open ? <ExpandLess /> : <ExpandMore />
    );
    return (
      <div>
        <Subheader>Information</Subheader>
        <List component="nav">
          {Information.map((item, idx) => (
            <ListItem button key={idx} className={classes.listItem}>
              <ListItemText primary={item} className={classes.menuLevel1Text} />
            </ListItem>
          ))}
        </List>
        <Subheader>Server Files</Subheader>
        <List component="nav">
          {ServerFiles.map((item, idx) => (
            <ListItem button key={idx} className={classes.listItem}>
              <ListItemText primary={item} className={classes.menuLevel1Text} />
            </ListItem>
          ))}
        </List>
        <Subheader>Tasks</Subheader>
        <List component="nav">
          {Tasks.map((item, idx) => (
            <ListItem button key={idx} className={classes.listItem}>
              <ListItemText primary={item} className={classes.menuLevel1Text} />
            </ListItem>
          ))}
        </List>
        <Subheader>Administration</Subheader>
        <List component="nav">
          {Admin.map((item, idx) => (
            <ListItem
              button key={idx} className={classes.listItem}
              onClick={this.handleClick}
            >
              <ListItemText primary={item.label} className={classes.menuLevel1Text} />
              {item.children ? <NestedIcon /> : null}
            </ListItem>
          ))}
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button>
                <ListItemText primary="Starred" className={classes.menuLevel2Text} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </div>
    )
  }

}


export default withStyles(styles, {index: 1})(Administration);