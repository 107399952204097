import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
    fetch: ({ Supplier, Page }) => ({ Supplier, Page: 1 }),
  }
}, 'userFields');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
    enableMasterDetailModel: true,
    detailCellRenderer: 'CommentAreaRenderer',
    detailCellRendererParams: {
      getDetailRowData: function (params) {
        return { commentData: params.data.Details, label: 'Details' }
      }
    },
  },
  Columns: [
    { headerName: 'Field Name', field: 'FieldName', suppressSorting: true, minWidth: 180 },
    { headerName: 'Details', field: 'Details', suppressSorting: true, minWidth: 180 },
  ],
  Data: [
    {
      FieldName: 'value',
      Details: 'value',
    }
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload.records;
        return {
          ...data,
          Data: linesData,
          selected: null
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.supplierEnquiry.userFields,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.supplierEnquiry.userFields.fetch_loading,
  gridOptions: state => state.supplierEnquiry.userFields.data.gridOptions,
  columns: state => state.supplierEnquiry.userFields.data.Columns,
  data: state => state.supplierEnquiry.userFields.data.Data,
  detailCellRenderer: state => state.supplierEnquiry.userFields.data.detailCellRenderer,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)