import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';
export const fetch = (query) => {
  const { DespatchId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(
    `/PickSlipEnquiry/Despatch/${DespatchId}/Lines`,
    filters && { ...filters },
    {
      BatchPage: Page,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
      BatchSize
    }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLines) {
          return {
            records: result.PickSlipLines.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchLots = (query) => {
  const { DespatchId, LineNumber, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(
    `/PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}/Lots`,
    filters && { ...filters },
    {
      BatchPage: Page,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
      BatchSize
    }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLineLots) {
          return {
            records: result.PickSlipLineLots.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchSerials = (query) => {
  const { DespatchId, LineNumber, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(
    `/PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}/Serials`,
    filters && { ...filters },
    {
      BatchPage: Page,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
      BatchSize
    }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLineSerials) {
          return {
            records: result.PickSlipLineSerials.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchSlipLineDetails = (query) => {
  const { DespatchId, LineNumber } = query;
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineDetails),
      schema: result.PickSlipLineDetails
    }));

  return apiCall;
};

export const fetchSlipLineDetailsSummary = (query) => {
  const { DespatchId, LineNumber } = query;
  const apiCall = fetchGet(`PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}/Summary`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineSummary),
      schema: result.PickSlipLineSummary
    }));

  return apiCall;
};
