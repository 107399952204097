import { createStyles } from '@material-ui/core/styles';

export const recallQuoteStyles = createStyles({
    recallQuoteContainer: {
        maxWidth: 1000,
        height: 486,
        display: 'flex',
        flexDirection: 'column',
    },
    recallQuoteToogleContaner: {
        'display': 'flex',
        'flexDirection': 'column',
        'backgroundColor': '#dddddd',
        'bottom': 0,
        'position': 'absolute',
        'width': '100%',
        'height': '84px',
        '& label': {
            marginLeft: '16px',
        }
    }
});

export const toggleStyles = createStyles({
    switchBase: {
        '&$checked': {
            '& + $bar': {
                backgroundColor: '#6DD900',
                opacity: 1
            },
        },
    },
    bar: {},
    checked: {},
    formControlLabel: {
        '&$disabled': {
            color: '#555555',
        },
    },
    disabled: {

    },
});
