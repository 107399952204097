import { IFormViewForm } from 'components/FormView';

const ActivityForm: IFormViewForm = {
  id: 'ActivityDetails',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'activity',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Activity',
            required: true,
            name: 'ActivityCode',
            size: 'medium',
            inputProps: {
              maxLength: 10
            }
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Description',
            required: true,
            name: 'Description',
            fullWidth: true
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Item Type',
            name: 'ServiceItemTypeId',
            lookupName: 'ServiceItemType',
            required: false,
            size: 'medium',

          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Sequence Group',
            name: 'SequenceGroup',
            required: true,
            size: 'medium',
            apiPrefs: {
              path: '/CustomTypes/Lookup/SequenceGroup',
              method: 'GET',
              params: null
            },
          }
        },
      ]
    },
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'activity',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Labour product',
            name: 'LabourProductId',
            lookupName: 'LabourProduct',
            required: false,
            size: 'medium',

          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Bill of materials',
            name: 'BomCode',
            lookupName: 'BomCode',
            required: false,
            size: 'medium',

          }
        },
        {
          type: 'MASKED_INPUT',
          props: {
            label: 'Estimate',
            name: 'Estimate',
            size: 'small',
            placeholder: 'HH:MM',
            mask: [],
            required: true
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Active',
            name: 'Active',
            required: false
          }
        },
      ]
    },
    {
      id: '3',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'Comments',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '300px'
            }
          }
        }
      ]
    }
  ]
};

export default ActivityForm;
