export default [
  {
    id: '1',
    label: 'Supplier Invoice Enquiry in new tab',
    action: (params?: { DocumentNumber: number }) => {
      window.open(`/supplier-invoice-enquiry/invoice-details?DocumentNumber=${params && params.DocumentNumber}`);
    }
  },
  {
    id: '2',
    label: 'Supplier Invoice Enquiry',
    action: (params?: { DocumentNumber: number }) => {
      window.location.assign(`/supplier-invoice-enquiry/invoice-details?DocumentNumber=${params && params.DocumentNumber}`);
    }
  }
];
