import { IFormViewForm } from 'components/FormView';

const SettingsForm: IFormViewForm = {
  id: 'CustomerSetting',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'setting',
        required: true,
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Customer type',
            name: 'CustomerType',
            apiPrefs: {
              LookupType: 'Dynamic',
              path: '/CustomTypes/Lookup/CustomerType/Search',
              method: 'POST'
            },
            required: false,
          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Pareto class',
            required: false,
            name: 'ParetoClass',
            apiPrefs: {
              path: '/CustomTypes/Lookup/ParetoClass',
              method: 'GET',
              params: null
            },
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sales territory',
            size: 'small',
            required: true,
            name: 'SalesTerritory',
            lookupName: 'SalesTerritory',
            inputProps: {
              maxLength: 6
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sales category',
            name: 'SalesCategory',
            lookupName: 'SalesCategory',
            isLookupTypeFixed: true,
            size: 'small',
            required: true,
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Order category',
            name: 'OrderCategory',
            lookupName: 'OrderCategory',
            isLookupTypeFixed: true,
            size: 'small',
            required: true,
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            lookupName: 'FreightCode',
            size: 'small',
            required: false,
            inputProps: {
              maxLength: 2
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sales rep',
            name: 'SalesRepresentative',
            lookupName: 'SalesRep',
            size: 'small',
            required: false,
            inputProps: {
              maxLength: 10
            }
          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Advise method',
            required: false,
            name: 'AdviseMethod',
            defaultValue: 'None',
            apiPrefs: {
              path: '/CustomTypes/Lookup/AdviseMethod',
              method: 'GET',
              params: null
            },
          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Delivery type',
            required: false,
            name: 'DeliveryType',
            apiPrefs: {
              path: '/CustomTypes/Lookup/DeliveryType',
              method: 'GET',
              params: null
            },
          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Priority',
            required: false,
            name: 'Priority',
            hideInV2: true,
            apiPrefs: {
              LookupType: 'Dynamic',
              path: '/CustomTypes/Lookup/Priority/Search',
              method: 'POST'
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Account Options',
        name: 'accountOptions',
        required: true,
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in mailouts',
            name: 'IncludeInMailouts',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in statement run',
            name: 'IncludeInStatementRun',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow back orders',
            name: 'Backorders',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in back order processing',
            name: 'IncludeInBackOrderProcessing',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer attracts sales surcharge',
            name: 'SalesSurch',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Keep sales history for each delivery address',
            name: 'SiteSales',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow partial order fulfillments',
            name: 'AllowPartDel',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send an order acknowledgement',
            name: 'SendOrderAcknowledgment',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send order status',
            name: 'OrderStat',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send delivery docket',
            name: 'DelDock',
            required: false,
            hideInV2: true,
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Enforce customer p/o on all orders',
            name: 'EnforceOrder',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'One invoice per despatch',
            name: 'InvByDesp',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer collects reward points',
            name: 'RewardPoints',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Manual order ship dates',
            name: 'ManualShipDate',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Charge merchant fee recovery',
            name: 'RecoverMerchantFee',
            required: false
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Internal distribution details',
        name: 'accountInfo',
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Internal warehouse',
            name: 'InternalWarehouse',
            lookupName: 'WarehouseEntity',
            size: 'small',
            required: false,
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sales entity',
            name: 'SalesEntity',
            lookupName: 'SalesEntity',
            size: 'small',
            required: false,
            inputProps: {
              maxLength: 3
            }
          }
        }
      ]
    }
  ]
};
export default SettingsForm;
