import { Api } from '../baseApi';
import {
    ICustomerSearchFacadeSearchResponse,
    ICustomerSearchRequest,
    ICustomerSearchFacade,
} from 'api/swaggerTypes';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';

export async function fetch(Type: 'Search' | 'Lookup', SearchText: string, BatchPage?: number, BatchSize?: number, exactMatch?: boolean): Promise<ICustomerSearchFacadeSearchResponse> {
    return Api.post<ICustomerSearchRequest, ICustomerSearchFacadeSearchResponse>(
        'Customer/SearchCustomers',
        { SearchText, BatchPage, BatchSize, Type, CheckExactMatch: exactMatch });
}

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 20;

export async function search(SearchText: string): Promise<DataSource> {
    return new DataSource({
        key: 'CustomerId',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<ICustomerSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = (skip / take) + 1;
            const BatchSize = take;

            const data = await fetch('Search', SearchText, BatchPage, BatchSize)
                .then((response) => response.Customers);

            return data;
        },
    });
}

export async function lookup(SearchText: string, exactMatch?: boolean): Promise<DataSource> {
    return new DataSource({
        key: 'CustomerId',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<ICustomerSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = (skip / take) + 1;
            const BatchSize = take;

            const data = await fetch('Lookup', SearchText, BatchPage, BatchSize, exactMatch)
                .then((response) => response.Customers);

            return data;
        },
    });
}
