import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  clearLines: () => null,
  asyncs: {
    fetchSummary: ({ AuditRegId }) => ({ AuditRegId }),
  }
}, 'salesAuditDetails');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Field', field: 'Field', suppressSorting: true, minWidth: 100 },
    { headerName: 'Value', field: 'Value', suppressSorting: true, }
  ],
  lines: [],
  summary: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.fetchSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const summaryData = action.payload;
        return {
          ...data,
          summary: summaryData
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.salesAuditDetails,
  {
    lines: data => data.lines,
    summary: data => data.summary,
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.salesAuditDetails.loading,
  loadingSummary: state => state.salesInvoiceEnquiry.salesAuditDetails.fetchSummary_loading,
  gridOptions: state => state.salesInvoiceEnquiry.salesAuditDetails.data.gridOptions,
  lineColumns: state => state.salesInvoiceEnquiry.salesAuditDetails.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)