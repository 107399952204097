import MTDSalesByRep from './MTDSaleByRep';
import OutstandingCustomerOrders from './OutstandingCustomerOrders';
import OutstandingCustomerQuotes from './OutstandingCustomerQuotes';

export default {
    1001: {
        component: MTDSalesByRep,
    },
    1002: {
        component: OutstandingCustomerOrders,
    },
    1003: {
        component: OutstandingCustomerQuotes,
    },
};
