import React from 'react';
import SelectField from '@markinson/uicomponents-v2/SelectField';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import DataGrid, {
  Column, Editing, Scrolling,
  Popup,
  Form
} from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { IChecklistDetailViewProps, IChecklistsOptions } from './../ChecklistsGrid.properties';
import { IJobActivityChecklistsDetailFacade } from 'api/swaggerTypes';
import ChecklistDetailModal from '../ChecklistDetailModal';
import Icon from '@markinson/uicomponents-v2/Icon';
// import Button from '@markinson/ui-components/material/Button';
import Button from '@material-ui/core/Button';
import EditIcon from '@markinson/uicomponents-v2/SvgIcons/Edit';
import { isNull } from 'utils/utils';
import 'devextreme-react/text-area';
import lodash from 'lodash';

const inlineStyles = {
  outerDiv: {
    display: 'flex',
    paddingBottom: '10px'
  },
  innerTADiv: {
    flex: 'auto',
  },
  innerBDiv: {
    width: '15%',
    marginTop: '36px',
    padding: '10px 15px',
    display: 'flex',
    alignItem: 'center'
  }

};
const notesEditorOptions = { height: 100 };
const COL_SPAN = 2;

const ChecklistDetailView = (props: IChecklistDetailViewProps): JSX.Element => {
  const {
    onChecklistSave, data, onChecklistQuestionSave
  } = props;

  const [checklists, setChecklists] = React.useState<IChecklistsOptions[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<number>(-1);
  const [comment, setComment] = React.useState<string>('');
  const [question, setQuestion] = React.useState<string>('');
  const [checklistQuestions, setChecklistQuestions] = React.useState<any[]>([]);
  const [checklistDetailOpen, setChecklistDetailOpen] = React.useState<boolean>(false);
  const [checklistItem, setChecklistItem] = React.useState<IJobActivityChecklistsDetailFacade>({});
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [status, setStatus] = React.useState<string>('');

  React.useEffect(
    () => {
      const cls = data.data.checklists.map((cl) => ({ ...cl, value: cl.ChecklistID, label: generateLabel(cl) }));
      setChecklists(cls);
    },
    [data]);
  React.useEffect(
    () => {
      if (!isNull(checklists)) {
        onChange({ target: { value: checklists[0].ChecklistID } });
      }
    },
    [checklists]);
  React.useEffect(
    () => {
      setDisabled(isNull(selectedItem) || selectedItem === -1);
    },
    [selectedItem]);

  const createModalData = (clItem) => {
    const { Question } = clItem;
    setQuestion(Question);
    const itemStatus = calculateStatus(clItem);
    setStatus(itemStatus);
  };

  const calculateStatus = (clItem): string => {
    const { DataType, AlphaValue, LogValueDisplay, NumValue } = clItem;
    switch (DataType) {
      case 'L':
      case 'P':
      case 'T':
        return String(AlphaValue);
      case 'Y':
        return String(LogValueDisplay);

      case 'N':
        return String(NumValue);
      default:
        return '';
    }
  };

  const generateLabel = (checklist: IJobActivityChecklistsDetailFacade): string => {
    return `${checklist.ChecklistCode} - ${checklist.Description}`;
  };

  const getSelectedChecklist = (value: number): IChecklistsOptions => {
    return checklists.find((cl) => cl.ChecklistID === value);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setSelectedItem(value);
    const selected = getSelectedChecklist(value);
    setChecklistItem(selected);
    setComment(selected.Comment || '');
    setChecklistQuestions(selected.ChecklistLines);
  };

  const updateChecklistDetailOpen = (open: boolean) => {
    setChecklistDetailOpen(open);
  };
  const onUpdate = (lineDetail) => {
    const selectedChecklist = lodash.cloneDeep(checklistItem);
    const updatedLine = selectedChecklist.ChecklistLines.find((cl) => cl.ChecklistLineID === lineDetail.ChecklistLineID);
    updatedLine.Comment = lineDetail.Comment;
    onChecklistQuestionSave(selectedChecklist);
  };

  return (
    <React.Fragment>
      <SelectField
        label={'Checklist'}
        options={checklists}
        value={selectedItem}
        onChange={onChange}
      />
      <div style={inlineStyles.outerDiv}>
        <div style={inlineStyles.innerTADiv}>
          <TextArea
            label={'Comment'}
            disabled={true}
            value={comment}
          />
        </div>
        <div style={inlineStyles.innerBDiv}>
          <Button
            onClick={() => { updateChecklistDetailOpen(true); }}
            variant={'contained'}
            disabled={disabled}
          >
            <EditIcon />
          </Button>
        </div >
      </div>
      <DataGrid
        keyExpr={'ChecklistLineID'}
        dataSource={checklistQuestions}
        repaintChangesOnly={true}
        noDataText=''
        remoteOperations={true}
        columnResizingMode={'nextColumn'}
        allowColumnReordering={true}
        style={{ maxHeight: 300 }}
        allowColumnResizing={true}
        onEditingStart={(element) => { createModalData(element.data); }}
        showBorders={false}
        onRowUpdated={(row) => {
          onUpdate(row.data);
        }}
        sorting={{
          mode: 'none'
        }}
        hoverStateEnabled={true}
      >
        <Editing
          refreshMode={'reshape'}
          mode='popup'
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={true}
          confirmDelete={false}
        >
          <Popup title='Question Details' showTitle={true} width={'550px'} height={'450px'} />
          <Form>
            <Item itemType='group' colCount={1} colSpan={COL_SPAN}>
              <Item
                dataField='Question'
                disabled
                colSpan={COL_SPAN}
                component={() => {
                  return <TextField
                    value={question}
                    disabled
                    size={'large'}
                  />;
                }}
                label={{ text: 'Question', location: 'top', showColon: false }} />
              <Item
                colSpan={1}
                dataField='Status'
                label={{ text: 'Status', location: 'top', showColon: false }}
                editorType={TextField}
                component={() => {
                  return <TextField
                    disabled
                    value={status}
                    size={'small'}
                  />;
                }}
                text={status}
                disabled />
            </Item>
            <Item
              dataField='Comment'
              editorType='dxTextArea'
              colSpan={COL_SPAN}
              editorOptions={notesEditorOptions}
              label={{ text: 'Comment', location: 'top', showColon: false }} />

          </Form>
        </Editing>
        <Scrolling mode='virtual' />
        <Column
          caption={'Question'}
          dataField={'Question'}
          allowEditing={false}
          minWidth={'50%'}
          fixed={true}
        />
        <Column
          caption={'Status'}
          calculateCellValue={(values) => (calculateStatus(values))}
          allowEditing={false}
          minWidth={'10%'}
          fixed={true}
        />
        <Column
          caption={'Comment'}
          dataField={'Comment'}
          minWidth={'30%'}
        />
        <Column type={'buttons'} width={'10%'} allowResizing={false}>
          <Icon iconName='edit' />
          <Icon iconName='cancel' />
          <Icon iconName='save' />
        </Column>
      </DataGrid>
      <ChecklistDetailModal
        open={checklistDetailOpen}
        checklistItem={checklistItem}
        onChecklistCancel={(open) => { updateChecklistDetailOpen(open); }}
        onChecklistSave={(open, updatedComment, checklist) => {
          updateChecklistDetailOpen(open);
          if (onChecklistSave) {
            onChecklistSave(updatedComment, checklist);
          }
        }}
      />
    </React.Fragment>
  );
};

export default ChecklistDetailView;
