import { ISummaryTableDataNode } from 'components/common/SummaryTable/SummaryTable.properties';

const schema: ISummaryTableDataNode[] = [
    {
        objectName: 'purchaseOrderDetails',
        isLoading: 'purchaseOrderDetailsLoading',
        title: 'Purchase order details',
        children: [
            {
                label: 'Supplier',
                fieldNames: [
                    'SupplierIdDisplay',
                    'SupplierIdLabel',
                ],
            },
            {
                label: 'Purchase Order',
                fieldNames: [
                    'PurchaseOrder'
                ],
            },
            {
                label: 'Deliver To Warehouse',
                fieldNames: [
                    'Warehouse',
                    'WarehouseLabel',
                ],
            },
            {
                label: 'Earliest Due',
                fieldNames: [
                    'DueDate'
                ],
            }

        ]
    },
    {
        objectName: 'purchaseOrderDetails',
        isLoading: 'purchaseOrderDetailsLoading',
        title: 'Purchase order totals',
        children: [
            {
                label: 'Currency',
                fieldNames: [
                    'Currency',
                ],
            },
            {
                label: 'Tax Class',
                fieldNames: [
                    'TaxClass'
                ],
            },
            {
                label: 'Price Include tax',
                fieldNames: [
                    'TaxIncludedDisplay',
                ],
            },
            {
                label: 'Order Total',
                fieldNames: [
                    'OrderTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true,
                    emphasize: true,
                }
            }

        ]
    }
];

export default schema;
