import { IFormViewForm } from 'components/FormView';

const OrderDetail: IFormViewForm = {
    id: 'PurchaseOrderDetailForm',
    layout: {
        rows: 3,
        columns: 3
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '1',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Order type',
                        name: 'PurchaseOrderTranType',
                        lookupName: 'POrderTransactionType',
                        placeholder: '',
                        size: 'small',
                    }
                },
                {
                    id: '2',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Order category',
                        name: 'PurchaseOrderCategory',
                        lookupName: 'POrderCategory',
                        placeholder: '',
                        size: 'small',
                    }
                },
                {
                    id: '3',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Order due',
                        name: 'DueDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'small',
                    }
                },
                {
                    id: '4',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Order ship',
                        name: 'ShipDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'small',
                    }
                },
                {
                    id: '5',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Quote reference',
                        name: 'QuoteReference',
                        placeholder: '',
                        size: 'large',
                    }
                },
                {
                    id: '6',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Tax class',
                        name: 'TaxClass',
                        type: 'string',
                        size: 'small',
                        hideInV3: true,
                    }
                },
                {
                    id: '6',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Tax class',
                        name: 'TaxClass',
                        lookupName: 'TaxClass',
                        placeholder: '',
                        size: 'small',
                        hideInV2: true,
                    }
                },
            ]
        },
        {
            id: '2',
            position: {
                row: 1,
                col: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '1',
                    type: 'ADDRESS_BLOCK_EX',
                    visible: true,
                    props: {
                        label: 'Supplier Address',
                        name: 'address'
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Contact',
                        name: 'Contact',
                        placeholder: '',
                        size: 'large',
                    }
                },
            ]
        },
        {
            id: '3',
            position: {
                row: 1,
                col: 3
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '1',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Freight code',
                        name: 'FreightCode',
                        lookupName: 'FreightCode',
                        placeholder: '',
                        size: 'small',
                        hideInV2: true
                    }
                },
                {
                    id: '2',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Freight type',
                        name: 'FreightType',
                        lookupName: 'FreightType',
                        placeholder: '',
                        size: 'medium',
                        suppressValidation: true,
                        suppressDescription: true,
                    }
                },
                {
                    id: '3',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Carrier',
                        name: 'Carrier',
                        lookupName: 'CarrierCode',
                        placeholder: '',
                        size: 'medium',
                        suppressValidation: true,
                        suppressDescription: true,
                    }
                },
                {
                    id: '4',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Shipping instruction',
                        name: 'Shipping',
                        placeholder: '',
                        size: 'large',
                    }
                },
                {
                    id: '5',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Packing instruction',
                        name: 'Packing',
                        placeholder: '',
                        size: 'large',
                    }
                },
            ]
        },
        {
            id: '3',
            position: {
                row: 2,
                col: 1,
                colspan: 1,
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Order comment',
                        name: 'Comment',
                        size: 'large',
                        style: {
                            height: '170px'
                        }
                    }
                }
            ]
        },
    ]
};
export default OrderDetail;
