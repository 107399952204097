import { combineReducers } from 'redux';
import jobTemplate, { IJobTemplateState } from './jobTemplate';
import jobActivity, { IActivityState } from './activity';

export interface IJobTemplateMaintenanceState {
  jobTemplate: IJobTemplateState;
  jobActivity: IActivityState;

}
const combined = combineReducers({
  jobTemplate, jobActivity
});

export default combined;
