import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as breaksTypes, actions as breaksActions } from 'ducks/inventoryEnquiry/pricing/futureBreaks';

import { callApi } from '../../utils';
import * as api from 'api/inventoryEnquiry/pricing/futureBreaks';


function* getBreaks(action){
  const {success, failure} = breaksActions.saga.getBreaks;

  yield callApi(
    call(api.getBreaks, action.data),
    success,
    failure
  )
}


export default function* rootCustomerSaga(){
  yield takeLatest(breaksTypes.getBreaks, getBreaks)
}