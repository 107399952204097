import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './ChequeDetailsForm.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import IChequeDetailsFormProps from './ChequeDetailsForm.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import TextField from '@markinson/uicomponents-v2/TextField';

const ChequeDetailsForm = (props: IChequeDetailsFormProps): JSX.Element => {
  const { classes, open, initialValues, schema, onApply, onCancel } = props;

  const [amount, setAmount] = React.useState<number>(0);
  const [chequeNumber, setChequeNumber] = React.useState<string>('');
  const [bankCode, setBankCode] = React.useState<string>('');
  const [branch, setBranch] = React.useState<string>('');
  const [accountName, setAccountName] = React.useState<string>('');
  const [bankAccountNumber, setBankAccountNumber] = React.useState<string>('');
  const [reference, setReference] = React.useState<string>('');

  React.useEffect(
    () => {
      if (initialValues) {
        setAmount(initialValues.Amount ? initialValues.Amount : 0);
        setChequeNumber(initialValues.ChequeNumber ? initialValues.ChequeNumber.toString() : '');
        setBankCode(initialValues.BankCode);
        setBranch(initialValues.Branch);
        setAccountName(initialValues.AccountName);
        setBankAccountNumber(initialValues.BankAccountNumber);
        setReference(initialValues.Reference);
      } else {
        setAmount(0);
        setChequeNumber('');
        setBankCode('');
        setBranch('');
        setAccountName('');
        setBankAccountNumber('');
        setReference('');
      }
    },
    [initialValues]);

  const maxChequeNumberLength = 9;
  const maxChequeNumberParseLength = 20;

  const getModalContent = () => {
    return <div className={classes.mainDiv}>
      <div className={classes.leftColumnDiv}>
        <NumericField
          value={amount}
          label={'Payment Amount'}
          minValueExclusive={0}
          onBlur={(value) => { setAmount(value); }}
          required={schema.Amount.Required} />
        <TextField
          value={chequeNumber}
          label={'Cheque'}
          maxLength={maxChequeNumberLength}
          onChange={(value) => { setChequeNumber(value); }}
          onBlur={(value) => {
            const parsedChequeNumber = parseInt(value ? value.substr(0, maxChequeNumberParseLength) : undefined);
            if (!isNaN(parsedChequeNumber)) {
              setChequeNumber(parsedChequeNumber.toString());
            } else {
              setChequeNumber('');
            }
          }}
          required={schema.ChequeNumber.Required} />
        <TextField
          value={bankCode}
          label={'Bank'}
          onChange={(value) => { setBankCode(value); }}
          required={schema.BankCode.Required} />
        <TextField
          value={branch}
          label={'Branch'}
          onChange={(value) => { setBranch(value); }}
          required={schema.Branch.Required} />
      </div>
      <div className={classes.rightColumnDiv}>
        <TextField
          value={accountName}
          label={'Account Name'}
          onChange={(value) => { setAccountName(value); }}
          required={schema.AccountName.Required} />
        <TextField
          value={bankAccountNumber}
          label={'Bank Account Number'}
          onChange={(value) => { setBankAccountNumber(value); }}
          required={schema.BankAccountNumber.Required} />
        <TextField
          value={reference}
          label={'Reference'}
          onChange={(value) => { setReference(value); }}
          required={schema.Reference.Required} />
      </div>
    </div >;
  };

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        disabled:
          (schema.Amount.Required && amount === undefined) || (amount !== undefined && amount <= 0) ||
          (schema.ChequeNumber.Required && !chequeNumber) || (chequeNumber && chequeNumber.length > maxChequeNumberLength) ||
          (schema.BankCode.Required && !bankCode) ||
          (schema.Branch.Required && !branch) ||
          (schema.AccountName.Required && !accountName) ||
          (schema.BankAccountNumber.Required && !bankAccountNumber) ||
          (schema.Reference.Required && !reference),
        listener: async () => {
          if (onApply) {
            onApply({
              ...initialValues,
              Amount: amount,
              ChequeNumber: parseInt(chequeNumber),
              BankCode: bankCode,
              Branch: branch,
              AccountName: accountName,
              BankAccountNumber: bankAccountNumber,
              Reference: reference
            });
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }
    ];
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Cheque Details'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(ChequeDetailsForm);
