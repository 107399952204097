import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Login from './Login';
import { selectors as staffSelectors, actions as staffActions } from 'ducks/staff';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  loggedIn: staffSelectors.loggedIn(state),
  isAdmin: staffSelectors.IsAdministrator(state),
  isLoading: staffSelectors.loading(state),
  error: staffSelectors.error(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    login: staffActions.login,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
