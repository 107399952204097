import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as detailSelectors } from 'ducks/priceCheck/details';

const mapStateToProps = (state) => ({
  details: detailSelectors.details(state),
  isLoading: detailSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPanel);
