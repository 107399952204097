import notepad from './../data/forms/common_components/notepad'
import payment from './../data/forms/common_components/payment-details'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'Notepad':
      return notepad
    case 'PaymentDetails':
      return payment
    default:
      return null
  }
}

export default getFormSchema
