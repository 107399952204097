import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Dialog, CircularProgress, Radio, FormControlLabel, Button, Divider, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import Scrollbars from 'react-custom-scrollbars';
import { ILookupRequest, ILookupResponse } from 'api/customType';
import { ApiError } from 'api/baseApi';
import { CircularProgressSize, ENTER_KEY } from '../../../utils/constants';
import { isNull } from 'utils/utils';

const classStyles = createStyles({
  dialog: {
    width: 446,
    height: 495,
  },
  dialogTitleHeading: {
    fontSize: 22,
    paddingBottom: 8
  },
  dialogTitle: {
    padding: '20px 26px 14px 22px',
    backgroundColor: '#dddddd',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
    height: 74,
    marginBottom: 5
  },
  dialogActionBar: {
    backgroundColor: '#dddddd',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
    borderRadius: 0.7,
    height: 52,
    margin: '4px 0 0 0'
  },
  dialogContent: {
    'height': '100%',
    'overflowY': 'visible',
    '&:first-child': {
      paddingTop: 0
    }
  },
  searchField: {
    width: '80%',
    display: 'inline-block',
    marginRight: 7,
    fontWeight: 400
  },
  formControlRoot: {
    height: 74
  },
  okBtn: {
    color: 'rgba(0, 102, 26, 0.87)'
  },
  cancelBtn: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  primaryLabel: {
    fontSize: 16
  },
  secondaryLabel: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  resultLabel: {
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: 16
  },
  preLoader: {
    margin: '0 auto'
  },
  circularContainer: {
    'display': 'flex',
    'align-items': 'center',
    'text-align': 'center',
    'height': 'calc(100% - 24px)'
  }
});

export interface ISelectedLookupOption {
  Value: string;
  Label?: string;
  Display?: string;
  Description?: string;
  Details?: any;
}

export interface IBrowseLookupModalProperties extends StyledComponentProps {
  title: string;
  open: boolean;
  searchText: string;
  selectedItem?: ISelectedLookupOption;
  params?: any;
  searchApi(search: ILookupRequest): Promise<ILookupResponse>;
  onError?(error: ApiError | TypeError): void;
  onClose?(selectedItem: ISelectedLookupOption): void;
}

export interface IBrowseLookupModalState {
  loading: boolean;
  searchText: string;
  selectedItem?: ISelectedLookupOption;
  options: any;
}

class BrowseLookUpModalComponent extends React.PureComponent<IBrowseLookupModalProperties, IBrowseLookupModalState> {
  mounted: boolean = false;
  searchField: any;
  okButton: any;

  constructor(props: IBrowseLookupModalProperties) {
    super(props);
    const state: IBrowseLookupModalState = {
      loading: true,
      searchText: this.props.searchText,
      selectedItem: this.props.selectedItem,
      options: null
    };
    this.state = state;
  }

  componentDidMount(): void {
    this.mounted = true;
    if (this.props.searchApi) {
      this.handleSearch();
    }
    if (this.searchField) { this.searchField.select(); }
  }

  componentDidUpdate(): void {
    if (this.searchField) { this.searchField.focus(); }
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  handleSearch = () => {
    if (this.props.searchApi) {
      this.props.searchApi({ ...this.props.params || {}, SearchText: this.state.searchText || '' }).then(
        (result) => {
          if (!this.mounted) {
            return;
          }

          this.setState((prevState) => {
            let selectedKey: string = prevState.selectedItem ? prevState.selectedItem.Value : null;
            // tODO Limit by search (GETS) or change to PUTS.
            if (result.Data.length === 1) {
              selectedKey = result.Data[0].Code;
            }
            const newSelectedItem = result.Data.find((d) => d.Code === selectedKey);

            const selectedItem: any = selectedKey && newSelectedItem ?
              { ...newSelectedItem, Value: selectedKey }
              : null;

            return {
              loading: false,
              options: result.Data,
              selectedItem: selectedItem
            };
          });
        },
        (error: ApiError | TypeError) => {
          if (!this.mounted) {
            return;
          }

          this.setState(() => {
            return {
              loading: false,
              options: [],
              selectedItem: null
            };
          });
          if (!isNull(this.props.onError)) {
            this.props.onError(error);
          }
        });
    }
  }

  handleCancel = () => {
    if (!this.state.loading && this.props.onClose) {
      this.props.onClose(null);
    }
  }

  handleOk = () => {
    if (!this.state.loading && this.state.selectedItem && this.props.onClose) {
      this.props.onClose(this.state.selectedItem);
    }
  }

  handleChange = (event: any) => {
    const value: any = event.target.value;
    const selectedItem: ISelectedLookupOption = { ...this.state.options[value], Value: value };
    this.setState((_) => {
      return { selectedItem: selectedItem };
    });
    if (this.okButton) {
      this.okButton.focus();
    }
  }

  handleSearchTextChange = (event: any) => {
    event.stopPropagation();
    this.setState({ searchText: event.target.value });
  }

  handleSearchFieldFocus = () => {
    this.searchField.select();
  }

  handleKeyDown = (event: any) => {
    if (event.keyCode === ENTER_KEY) {
      this.handleSearch();
    }
  }

  render(): React.ReactNode {
    const { classes, title, open } = this.props;
    const { selectedItem, loading } = this.state;
    const options: string[] = loading ? [] : Object.keys(this.state.options);

    return (
      <Dialog
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby='confirmation-dialog-title'
        open={open}
        onClose={this.handleCancel}
      >
        <DialogTitle id='confirmation-dialog-title'
          className={classes.dialogTitle}
        >
          <div className={classes.dialogTitleHeading}>{title}</div>
          <TextField
            disableHelperText={true}
            value={this.state.searchText}
            action={{
              iconName: 'Search',
              controller: this.handleSearch
            }}
            className={classes.searchField}
            iconStyles={{ width: 25, height: 25 }}
            onChange={this.handleSearchTextChange}
            onKeyDown={this.handleKeyDown}
            fieldRef={(ref) => { this.searchField = ref; }}
            onFocus={this.handleSearchFieldFocus}
          />
          {options && <span className={classes.resultLabel}>{options.length} results found</span>}
        </DialogTitle>
        <Scrollbars>
          <DialogContent className={classes.dialogContent}>
            {loading ?
              <div className={classes.circularContainer}>
                <CircularProgress size={CircularProgressSize} className={classes.preLoader} color={'secondary'} variant={'indeterminate'} />
              </div>
              : options.map((key) => {
                const checked: boolean = selectedItem ? (selectedItem.Value === key) : false;

                return <React.Fragment key={key}>
                  <FormControlLabel
                    className={classes.formControlRoot}
                    value={key}
                    onChange={this.handleChange}
                    checked={checked}
                    control={<Radio />}
                    label={
                      <React.Fragment>
                        {<div className={classes.primaryLabel}>
                          {this.state.options[key].Display || key} - {this.state.options[key].Label || ''}
                        </div>}
                        {this.state.options[key].Description &&
                          <div className={classes.secondaryLabel}>{this.state.options[key].Description}</div>}
                      </React.Fragment>
                    } />
                  <Divider />
                </React.Fragment>;
              })}
          </DialogContent>
        </Scrollbars>
        <DialogActions className={classes.dialogActionBar}>
          <Button onClick={this.handleCancel} className={classes.cancelBtn} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.handleOk} className={classes.okBtn} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const BrowseLookUpModal = withStyles(classStyles, { index: 1 })(BrowseLookUpModalComponent);

export { BrowseLookUpModal };
