import { IOutstandingOrdersFilter } from 'api/swaggerTypes';

export const DATAGRIDCONSTANTS = {
    header: {
        SalesOrder: 152,
        Customer: 92,
        CustomerPO: 152,
        Entered: 87,
        Entity: 50,
        WH: 44,
        Status: 92,
        Category: 73,
        EarliestShip: 90,
        Expiry: 87,
        Description: 378,
        CustomerName: 332,
    }
};

export const SUBTITLE = 'You may recall one of the listed sales orders or create a new order for the customer’s P/O';

const ORDER_STATUS = {
    a: 'All',
    1: 'Incomplete',
    2: 'Active',
    3: 'On hold'
};

export const ParseOrderStatus = (status: IOutstandingOrdersFilter['OrderStatus']) => ORDER_STATUS?.[status] ?? '';
