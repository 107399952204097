
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IMovementsData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IMovementsState extends IExtendedState<IMovementsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'movements');

const RequiredFields = [
  'EntityView', 'MovementType'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IMovementsData>({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Warehouse: '',
    Product: 0,
    FromDate: '',
    ToDate: '',
    MovementType: 'ALL'
  },
  filterRow: {
    formName: 'SupplierMovementsFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Product',
          name: 'Product',
          lookupName: 'Product',
          size: 'medium',
          required: false
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'SELECT_FIELD',
        props: {
          label: 'Movement type',
          name: 'MovementType',
          defaultValue: 'ALL',
          required: false,
          size: 'medium',
          apiPrefs: {
            path: '/CustomTypes/Lookup/Movetype',
            method: 'GET',
            params: null
          },
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Product',
      field: 'ProductDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Product) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductDisplay
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      }
    },
    { headerName: 'W/H', field: 'Warehouse', minWidth: 120, suppressSorting: true, },
    { headerName: 'Date', field: 'DateTime', type: 'numericColumn', minWidth: 180, sort: 'desc' },
    { headerName: 'Movement type', field: 'MovementType', minWidth: 180, suppressSorting: true, },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Balance', field: 'BalanceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reference', field: 'Reference', minWidth: 180, suppressSorting: true, },
    { headerName: 'Product Description', field: 'Description', minWidth: 180, suppressSorting: true, },
    { headerName: 'Cost each', field: 'CostEachDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Total cost', field: 'TotalCostDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Transaction', field: 'TransactionNumber', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Financial Period', field: 'Period', minWidth: 180, suppressSorting: true, },
    { headerName: 'User ID', field: 'User', minWidth: 150, type: 'numericColumn', suppressSorting: true, },
    { headerName: 'User name', field: 'UserLabel', minWidth: 180, suppressSorting: true, },
  ]
});

export default (state: IMovementsState = initialState, action: IDataAction): IMovementsState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.selected,
  gridOptions: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.gridOptions,
  columns: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.Columns,
  Actions: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.Actions,
  filterRow: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.filterRow,
  selectedFilters: (state: { supplierEnquiry: { activity: { movements: IMovementsState } } }) => state.supplierEnquiry.activity.movements.data.selectedFilters
});
