import { IFormViewForm } from 'components/FormView';

const SettingsForm: IFormViewForm = {
  id: 'CustomerSetting',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'setting',
        required: false,
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Date opened',
            name: 'OpenDate',
            required: false,
            size: 'small'
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Customer type',
            name: 'CustomerTypeLabel',
            required: false,
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Pareto class',
            required: false,
            name: 'ParetoClass',
            size: 'small',
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales territory',
            size: 'small',
            required: false,
            name: 'SalesTerritory',
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales category',
            name: 'SalesCategory',
            size: 'small',
            required: false
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Order category',
            name: 'OrderCategory',
            size: 'small',
            required: false
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            size: 'small',
            required: false
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales rep',
            name: 'SalesRepresentative',
            size: 'small',
            required: false
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Advise method',
            required: false,
            name: 'AdviseMethodLabel',
            size: 'small',
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Delivery type',
            required: false,
            name: 'DeliveryTypeLabel',
            size: 'small'
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Priority',
            required: false,
            name: 'PriorityLabel',
            size: 'medium'
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Account Options',
        name: 'accountOptions',
        required: true,
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in mailouts',
            name: 'IncludeInMailouts',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in statement run',
            name: 'IncludeInStatementRun',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow back orders',
            name: 'Backorders',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Include in back order processing',
            name: 'IncludeInBackOrderProcessing',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer attracts sales surcharge',
            name: 'SalesSurch',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Keep sales history for each delivery address',
            name: 'SiteSales',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Allow partial order fulfillments',
            name: 'AllowPartDel',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send an order acknowledgement',
            name: 'SendOrderAcknowledgment',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send order status',
            name: 'OrderStat',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Send delivery docket',
            name: 'DelDock',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Enforce customer purchase order on all orders',
            name: 'EnforceOrder',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'One invoice per despatch',
            name: 'InvByDesp',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Customer collects reward points',
            name: 'RewardPoints',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Manual order ship dates',
            name: 'ManualShipDate',
            required: false
          }
        },
        {
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Charge merchant fee recovery',
            name: 'RecoverMerchantFee',
            required: false
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Internal distribution details',
        name: 'accountInfo',
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Warehouse',
            name: 'InternalWarehouse',
            size: 'small',
            required: false

          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales entity',
            name: 'SalesEntity',
            size: 'small',
            required: false
          }
        }
      ]
    }
  ]
};
export default SettingsForm;
