function appendParams(
  path: string,
  params: { ProductId?: number; WarehouseEntity?: string; SalesQuantity?: string; CustomerId?: string; SalesEntity?: string }
): string {
  if (params && (params.ProductId || params.WarehouseEntity)) {
    const search = new URLSearchParams();
    if (params.ProductId) {
      search.append('ProductId', params.ProductId.toString());
    }
    if (params.WarehouseEntity) {
      search.append('WarehouseEntity', params.WarehouseEntity);
    }
    if (params.SalesQuantity) {
      search.append('SalesQuantity', params.SalesQuantity);
    }
    if (params.CustomerId) {
      search.append('CustomerId', params.CustomerId);
    }
    if (params.SalesEntity) {
      search.append('SalesEntity', params.SalesEntity);
    }
    path += `?${search.toString()}`;
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Price Check in new tab',
    action: (params) => { window.open(appendParams('/price-check', params)); }
  },
  {
    id: '2',
    label: 'Price Check',
    action: (params) => { window.location.assign(appendParams('/price-check', params)); }
  },
];
