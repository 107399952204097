import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as lotReservedSelectors, actions as lotReservedActions } from 'ducks/inventoryEnquiry/stocking/lotReserved';
import { selectors as lotSelectors } from 'ducks/inventoryEnquiry/stocking/lotDetails';
import { fetch } from 'api/inventoryEnquiry/stocking/lotReserved';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingLotReserved',
  columnDefs: lotReservedSelectors.columns(state),
  rowData: lotReservedSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lotReservedSelectors.gridOptions(state),
  isLoading: lotReservedSelectors.isLoading(state),
  filterRow: lotReservedSelectors.filterRow(state),
  appliedFilters: lotReservedSelectors.filterRow(state) && filterSelectors.getFilters(state, lotReservedSelectors.filterRow(state).formName),
  selectedFilters: lotReservedSelectors.selectedFilters(state),
  reqParams: (lotSelectors.selected(state) || params.get('ProductId'))
    && (lotSelectors.selected(state) || params.get('Warehouse'))
    && (lotSelectors.selected(state) || params.get('LotSerial'))
    && {
    ProductId: (lotSelectors.selected(state) && lotSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (lotSelectors.selected(state) && lotSelectors.selected(state).Warehouse) || params.get('Warehouse'),
    LotSerial: (lotSelectors.selected(state) && lotSelectors.selected(state).LotSerial) || params.get('LotSerial')
  },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lotReservedActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
