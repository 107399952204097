import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IRunTimeSelectionProps } from '../DynamicExtractModal.properties';
import { DateTextField, TextField } from '@markinson/uicomponents-v2';
import ToggleButton from 'components/common/ToggleButton';
import TimeTextField from 'components/FormView/Fields/TimeField';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';
import moment from 'moment';
import { PROGRAM_ID } from '../DynamicExtractModal.constants';
import SelectContainer from 'components/SelectContainer';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const RunTimeSelection = (props: IRunTimeSelectionProps) => {
    const {
        classes,
        details
    } = props;

    const [disableDateSelection, setDisableDateSelection] = React.useState(false);
    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);

    React.useEffect(
        () => {
            if (WizardDetails?.ExtractRunCriteria === 'FUTURE') {
                setDisableDateSelection(false);
                contextDispatch({
                    setWizardDetails: { ...WizardDetails, RunDate: WizardDetails?.RunDate || moment().format('DD/MM/YYYY'), RunTime: WizardDetails?.RunTime || moment().format('hh:mm a') }
                });
            } else {
                setDisableDateSelection(true);
            }
        },
        [WizardDetails?.ExtractRunCriteria]);

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <SelectContainer
                label={'Extract run time'}
                value={WizardDetails?.ExtractRunCriteria}
                onChange={(e) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, ExtractRunCriteria: e }
                    });
                }}
                apiPrefs={({
                    LookupType: 'Dynamic',
                    path: '/CustomTypes/Lookup/WizardRunTime/Search',
                    method: 'POST'
                })}
                params={{
                    SearchText: '',
                    ProgramId: PROGRAM_ID,
                    BatchPage: 1,
                    BatchSize: 100
                }}
                required={false}
                defaultFirstValue={true}
            />
            <div className={classes.dateTimeRow}>
                <span className={classes.dateTimeColumn}>
                    <DateTextField
                        label={'Date'}
                        value={WizardDetails?.RunDate || moment()}
                        required={true}
                        format={'DD/MM/YYYY'}
                        onChange={(d) => {
                            if (d.toString() === moment().format('DD/MM/YYYY').toString()) {
                                const currTime = moment(moment().format('hh:mm a'), 'h:mma');
                                const selectTime = moment(WizardDetails?.RunTime, 'h:mma');

                                if (!selectTime.isAfter(currTime)) {
                                    contextDispatch({
                                        setWizardDetails: { ...WizardDetails, RunTime: moment().format(moment().format('hh:mm a')) }
                                    });
                                }
                            }
                            contextDispatch({
                                setWizardDetails: { ...WizardDetails, RunDate: d }
                            });
                        }}
                        disabled={disableDateSelection}
                        size='small'
                        minDate={moment()}
                    />
                </span>
                <span className={classes.dateTimeColumn}>
                    <TimeTextField
                        label={'Time'}
                        value={WizardDetails?.RunTime || moment()}
                        required={true}
                        format={'hh:mm a'}
                        onChange={(t) => {
                            if (WizardDetails?.RunDate?.toString() === moment().format('DD/MM/YYYY').toString()) {
                                const currTime = moment(moment().format('hh:mm a'), 'h:mma');
                                const selectTime = moment(t, 'h:mma');

                                if (selectTime.isAfter(currTime)) {
                                    contextDispatch({
                                        setWizardDetails: { ...WizardDetails, RunTime: t }
                                    });
                                } else {
                                    showSnackBar({ variant: 'error', message: 'Invalid time selection, please select later time.' });
                                }
                            } else {
                                contextDispatch({
                                    setWizardDetails: { ...WizardDetails, RunTime: t }
                                });
                            }
                        }}
                        disabled={disableDateSelection}
                        size='small'
                    />
                </span>
            </div>
            <ToggleButton
                checked={WizardDetails?.SendNotificationEmail}
                onChange={(_, checked) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, SendNotificationEmail: checked }
                    });
                }}
                label={'Advice me via email when task has been completed'}
                value={WizardDetails?.SendNotificationEmail}
            />
            <TextField
                label={'Email'}
                name={'Email'}
                size='large'
                value={WizardDetails?.NotificationEmail}
                type='email'
                onBlur={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, NotificationEmail: v }
                    });
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(RunTimeSelection);
