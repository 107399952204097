import RFCDetails from './../data/forms/rfc-enquiry/RFCDetails'
import Comments from './../data/forms/rfc-enquiry/Comments'
import Notepad from './../data/forms/rfc-enquiry/NotepadForm'
import SalesDiary from './../data/forms/rfc-enquiry/SalesDiary'
import DiaryDetails from './../data/forms/rfc-enquiry/DiaryDetails'
import RFCLines from './../data/forms/rfc-enquiry/RFCLines'
import RFCSummary from './../data/forms/rfc-enquiry/RFCSummary'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'RFCDetails':
      return RFCDetails
    case 'Comments':
      return Comments
    case 'Notepad':
      return Notepad
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    case 'RFCLines':
      return RFCLines
    case 'Summary':
      return RFCSummary
    default:
      return RFCDetails;
  }
}

export default getFormSchema;
