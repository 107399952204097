import { fetchGet, objectify } from '../utils'

export const fetch = (query) => {
  const { CustomerId } = query
  return fetchGet(`/CustomerEnquiry/Customer/${CustomerId}/Balances`, null, 'Inline')
    .then((something) => {
      if (something) {
        const dataObject = something.CustomerBalanceDetails;
        return Object.assign({}, { inlineObject: objectify(dataObject), schema: dataObject })
      }
    })

}