import React from 'react';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import IWorksaleDetailsProperties, { IWorksaleDetailsHandle } from './WorksaleDetails.properties';
import WorksaleFastEntry from '../../common/FastEntry';
import { withStyles } from '@material-ui/core/styles';
import inlineStyles from './WorksaleDetails.styles';
import WorksaleLines, { IWorksaleLinesHandle } from './WorksaleLines';
import DataSource from 'devextreme/data/data_source';
import { createWorksaleLinesDataSource } from '../worksaleLinesDataSource';
import { ICalculateFastLineCriteriaFacade, IWorksaleFastLineDetailsResponse, IWorksaleGridLineDetailFacade } from 'api/swaggerTypes';
import { Quote as QuoteIcon, OrderRecall, Cancel as CancelIcon, Process as ProcessIcon, ParkSale, RecallParkSale } from '@markinson/uicomponents-v2/SvgIcons';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { isNull } from 'utils/utils';
import { PriceOverrideAuthRequestedScreen } from '../Worksale.properties';
import BOMDialog from '../../common/BOMDialog';
import { IFastEntryHandle } from '../../common/FastEntry/FastEntry.properties';
import { IValidationDialogAction } from 'components/common/ValidationDialog/ValidationDialog.properties';
import { IPriceOverrideData } from '../../common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { useLostSale, usePriceOverrideAndKitValidations } from '../Worksale.hooks';
import PriceOverrideAuthorityDialog from '../../common/PriceOverrideAuthorityDialog';
import { isScreenRequest } from 'api/utils';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useCalculateFastEntryDetails, useCalculateGridLineDetails, useFetchCurrentWorksale, useFetchLines, useFetchWorksaleSites, useInsertLine, useRemoveLine, useTotalLinesCount, useUpdateLine } from 'api/Worksale/worksale';
import { ACTION_BAR_BTN_LABELS } from '../constants';
import { HotKeyNames } from 'utils/constants';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';

const ParkSaleIcon = (itemProps) => <ParkSale {...itemProps} style={inlineStyles.recallIcon} />;
const OrderRecallIcon = (itemProps) => <OrderRecall {...itemProps} style={inlineStyles.recallIcon} />;
const RecallSaleIcon = (itemProps) => <RecallParkSale {...itemProps} style={inlineStyles.recallIcon} />;

const leftIcons = [BACK_BUTTON];

const centerIcons = [
    {
        label: ACTION_BAR_BTN_LABELS.Lines.rSale,
        Icon: RecallSaleIcon,
        action: 'Recall',
        hotkey: HotKeyNames.CtrlR
    },
    {
        label: ACTION_BAR_BTN_LABELS.Lines.rOrder,
        Icon: OrderRecallIcon,
        action: 'OrderRecall',
        hotkey: HotKeyNames.CtrlO
    },
    {
        label: ACTION_BAR_BTN_LABELS.Lines.rQuote,
        Icon: QuoteIcon,
        action: 'RecallQuote',
        disabled: true,
        hotkey: HotKeyNames.CtrlQ
    }
];

const WorksaleDetail = (props: IWorksaleDetailsProperties, ref: React.Ref<IWorksaleDetailsHandle>) => {

    const processValidationForms = React.useContext(ProcessValidationFormsContext);

    const {
        classes,
        messages,
        salesEntity,
        showDetailOnClick,
        onSaleLinesChanged,
        onSaleLinesTotalCountChanged,
        onDeleteSaleline,
        closeLostsaleModal,
    } = props;
    const isLineEditing = useSPContextSelector<'isLineEdit'>((state) => state.isLineEdit);

    const customerId = useSPContextSelector(Selectors.CustomerId);
    const worksaleId = useSPContextSelector(Selectors.WorksaleId);
    const { data: currentWorksaleData } = useFetchCurrentWorksale();
    const { mutateAsync: fetchLinesMutateAsync } = useFetchLines();
    const { mutateAsync: insertLineMutateAsync } = useInsertLine();
    const { mutateAsync: updateLineMutateAsync } = useUpdateLine();
    const { mutateAsync: removeLineMutateAsync } = useRemoveLine();
    const { mutateAsync: fetchWorksaleSitesMutateAsync, data: worksaleSitesData } = useFetchWorksaleSites();
    const { mutateAsync: calculateGridLineDetailsMutateAsync } = useCalculateGridLineDetails();
    const { mutateAsync: calculateFastEntryDetailsMutateAsync } = useCalculateFastEntryDetails();
    const { mutateAsync: totalLinesCountMutateAsync } = useTotalLinesCount();

    const rightIcons = [
        {
            label: ACTION_BAR_BTN_LABELS.Lines.park,
            Icon: ParkSaleIcon,
            action: 'Park',
            disabled: true,
            hotkey: HotKeyNames.CtrlP
        },
        {
            label: ACTION_BAR_BTN_LABELS.Lines.quote,
            Icon: QuoteIcon,
            action: 'Quote',
            disabled: true,
            hotkey: HotKeyNames.CtrlU
        },
        {
            label: ACTION_BAR_BTN_LABELS.cancel,
            Icon: CancelIcon,
            action: 'Cancel',
            disabled: true,
            hotkey: HotKeyNames.CtrlBackspace
        },
        {
            label: ACTION_BAR_BTN_LABELS.process,
            Icon: ProcessIcon,
            action: 'Process',
            disabled: true,
            hotkey: HotKeyNames.CtrlEnter
        }
    ];

    const { setActionBar } = React.useContext(ActionBarContext);
    const workSaleFastEntryRef = React.useRef<IFastEntryHandle>(null);
    const worksaleLinesRef = React.useRef<IWorksaleLinesHandle>(null);

    const {
        multipleBomOpen,
        bomList,
        kitStatus,
        selectedBomCode,
        priceOverrideDialogLoading,
        priceOverrideDialogOpen,
        priceOverrideScreenType,
        priceOverrideDetailSummary,
        priceOverrideAuthRequestedScreen,
        multipleBomDialogApi,
        validate,
        isPriceOverrideScreenRequest,
        setMultipleBomDialogApi,
        setKitStatus,
        setBomList,
        setSelectedBomCode,
        setMultipleBomState,
        setPriceOverrideDialogLoading,
        setPriceOverrideDialogOpen
    } = usePriceOverrideAndKitValidations();

    const [linesDataSource, setLinesDataSource] = React.useState<DataSource>(new DataSource([]));
    const [worksaleHasLines, setWorksaleHasLines] = React.useState(false);
    const [updatedWorksaleLineSummary, setUpdatedWorksaleLineSummary] = React.useState<IWorksaleGridLineDetailFacade>();

    const { setDialogState: setLostSaleDialogStates, isLostSale } = useLostSale();

    function handleSaleLinesChanged(): void {
        if (onSaleLinesChanged) {
            onSaleLinesChanged();
        }
    }

    function handleSaleLinesTotalCountChanged(totalCount: number): void {
        if (onSaleLinesTotalCountChanged) {
            onSaleLinesTotalCountChanged(totalCount);
        }

        setWorksaleHasLines(totalCount > 0);
    }

    React.useImperativeHandle(
        ref,
        () => ({
            reloadLines(): void {
                reloadLines();
            },
            resetFastLineEntry(): void {
                if (workSaleFastEntryRef.current) {
                    workSaleFastEntryRef.current.resetToDefault();
                }
            },
        })
    );

    React.useEffect(
        () => {
            setActionBar({
                leftIcons,
                centerIcons,
                rightIcons
            }
            );

            return () => {
                setActionBar({
                    leftIcons: [],
                    centerIcons: [],
                    rightIcons: []
                }
                );
            };
        },
        []
    );

    React.useEffect(
        () => {
            const isWorksaleHasLines = async () => {
                if (worksaleId) {
                    const count = await totalLinesCountMutateAsync({
                        WorksaleId: worksaleId,
                        urlQueryParams: {
                            BatchPage: 1,
                            BatchSize: 1
                        }
                    });
                    setWorksaleHasLines(count > 0);
                }
            };
            isWorksaleHasLines();
        },
        [worksaleId]);

    React.useEffect(
        () => {
            setActionBar({
                leftIcons,
                centerIcons: [
                    {
                        label: ACTION_BAR_BTN_LABELS.Lines.rSale,
                        Icon: RecallSaleIcon,
                        action: 'Recall',
                        disabled: isLineEditing,
                        hotkey: HotKeyNames.CtrlR
                    },
                    {
                        label: ACTION_BAR_BTN_LABELS.Lines.rOrder,
                        Icon: OrderRecallIcon,
                        action: 'OrderRecall',
                        disabled: isLineEditing || currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.Recalled,
                        hotkey: HotKeyNames.CtrlO
                    },
                    {
                        label: ACTION_BAR_BTN_LABELS.Lines.rQuote,
                        Icon: QuoteIcon,
                        action: 'RecallQuote',
                        disabled: !worksaleId || isLineEditing,
                        hotkey: HotKeyNames.CtrlQ
                    }
                ],
                rightIcons: [
                    {
                        label: ACTION_BAR_BTN_LABELS.Lines.park,
                        Icon: ParkSaleIcon,
                        action: 'Park',
                        disabled: !worksaleId || !worksaleHasLines || isLineEditing || !Boolean(customerId) || currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.Recalled,
                        hotkey: HotKeyNames.CtrlP
                    },
                    {
                        label: ACTION_BAR_BTN_LABELS.Lines.quote,
                        Icon: QuoteIcon,
                        action: 'Quote',
                        disabled: !worksaleId || !worksaleHasLines || isLineEditing || !Boolean(customerId) || currentWorksaleData?.WorkSale?.Criteria?.inlineObject?.Recalled,
                        hotkey: HotKeyNames.CtrlU
                    },
                    {
                        label: ACTION_BAR_BTN_LABELS.cancel,
                        Icon: CancelIcon,
                        iconStyle: worksaleId && !isLineEditing ? { fill: 'rgba(178, 0, 0, 1)' } : undefined,
                        action: 'Cancel',
                        disabled: !worksaleId || isLineEditing,
                        hotkey: HotKeyNames.CtrlBackspace
                    },
                    {
                        label: ACTION_BAR_BTN_LABELS.process,
                        Icon: ProcessIcon,
                        iconStyle: worksaleId && !isLineEditing ? { fill: 'rgba(109, 217, 0, 1)' } : undefined,
                        action: 'Process',
                        disabled: !worksaleId || isLineEditing || !worksaleHasLines,
                        hotkey: HotKeyNames.CtrlEnter
                    }
                ]
            });
        },
        [worksaleId, worksaleHasLines, isLineEditing, customerId]);

    React.useEffect(
        () => {
            if (worksaleId) {
                const dataSource = createWorksaleLinesDataSource(
                    {
                        fetch: async (batchPage?: number, batchSize?: number, filter?: any) => {
                            const lines = await fetchLinesMutateAsync({
                                WorksaleId: worksaleId,
                                SiteCode: filter && filter.SiteCode,
                                urlQueryParams: {
                                    BatchPage: batchPage.toString(),
                                    BatchSize: batchSize.toString()
                                }
                            });

                            if (!lines.Status) {
                                setLinesDataSource(new DataSource([]));
                            }
                            setWorksaleHasLines(lines?.BatchInformation?.TotalCount ? true : false);

                            if (messages) {
                                messages.forEach((message) => {
                                    if (message.MessageFile === 'OrderLine' && message.MessageKey) {
                                        lines.GridLines
                                            .filter((line) => line.LineNumber === parseInt(message.MessageKey))
                                            .forEach((matchedLine) => { (matchedLine as any).AlertMessage = message.MessageDetail; });
                                    }
                                });
                            }
                            await fetchWorksaleSitesMutateAsync({
                                SearchText: '',
                                WorksaleID: worksaleId
                            });

                            lines.GridLines = lines?.GridLines?.map((line) => ({
                                ...line,
                                DisableLine: line?.LineType === 'r'
                            }));

                            return lines;
                        },
                        totalCount: async () => {
                            const count = await totalLinesCountMutateAsync({
                                WorksaleId: worksaleId,
                                urlQueryParams: {
                                    BatchPage: 1,
                                    BatchSize: 1
                                }
                            });

                            handleSaleLinesTotalCountChanged(count);

                            return count;
                        },
                        insert: async (worksaleLineSummary: IWorksaleGridLineDetailFacade) => {
                            const response = await insertLineMutateAsync({
                                ...worksaleLineSummary,
                                WorksaleId: worksaleId
                            });
                            setUpdatedWorksaleLineSummary(worksaleLineSummary);
                            setMultipleBomDialogApi('addLine');
                            validate(PriceOverrideAuthRequestedScreen.FastLineEntry, {
                                Forms: response.Forms,
                                BomCode: response.GridLineDetails?.inlineObject?.BomCode,
                                KitDetail: response.GridLineDetails?.inlineObject?.KitDetail,
                                KitStatus: response.GridLineDetails?.inlineObject?.KitStatus,
                                OverrideDetails: response.GridLineDetails?.inlineObject?.OverrideDetails,
                            });

                            if (response.Status && !response.Forms) {
                                setPriceOverrideDialogOpen(false);
                                closeLostsaleModal();
                            }

                            handleSaleLinesChanged();

                            return response;
                        },
                        update: async (worksaleLineSummary: IWorksaleGridLineDetailFacade) => {
                            const response = await updateLineMutateAsync({
                                ...worksaleLineSummary,
                                WorksaleId: worksaleId,
                                WorksalelineId: worksaleLineSummary?.WorksalelineId
                            });

                            setUpdatedWorksaleLineSummary(worksaleLineSummary);
                            const screenRequest = response.Forms ? response.Forms.find((form) => form.FormType === 'ScreenRequest') : undefined;
                            if (response.Status && !response.Forms) {
                                setPriceOverrideDialogOpen(false);
                                closeLostsaleModal();
                            }

                            if (screenRequest) {
                                setMultipleBomDialogApi('updateGridLine');
                                validate(PriceOverrideAuthRequestedScreen.WorksaleLineGrid, {
                                    Forms: response.Forms,
                                    BomCode: response.GridLineDetails?.inlineObject?.BomCode,
                                    KitDetail: response.GridLineDetails?.inlineObject?.KitDetail,
                                    KitStatus: response.GridLineDetails?.inlineObject?.KitStatus,
                                    OverrideDetails: response.GridLineDetails?.inlineObject?.OverrideDetails,
                                });

                                return response;
                            }
                            handleSaleLinesChanged();

                            return response;
                        },
                        remove: async (lineNumber: number): Promise<any> => {
                            const response = await removeLineMutateAsync({
                                WorkSaleLineId: lineNumber,
                                urlQueryParams: {
                                    createLostsale: false
                                }
                            });

                            handleSaleLinesChanged();

                            return response;
                        },
                    },
                    processValidationForms);
                setLinesDataSource(dataSource);
            } else {
                setLinesDataSource(new DataSource([]));
                handleSaleLinesTotalCountChanged(0);
                handleSaleLinesChanged();
            }
        },
        [worksaleId, messages, customerId]);

    const reloadLines = () => {
        return linesDataSource.reload()
            .then(() => {
                worksaleLinesRef.current.refreshDataGrid();
            });
    };

    const calculateFastLineMessageBoxActionsCallback = (action: IValidationDialogAction) => {
        switch (action.name) {
            case 'go back':
                onGoBack();
                break;

            case 'sell as components':
                setKitStatus('c');
                if (workSaleFastEntryRef.current) {
                    workSaleFastEntryRef.current.disablePriceField();
                }
                break;

            case 'sell as kit':
                setKitStatus('k');
                break;

            default:

        }
    };

    async function calculateAndValidateFastEntryDetails(line: ICalculateFastLineCriteriaFacade): Promise<IWorksaleFastLineDetailsResponse> {
        const response = await calculateFastEntryDetailsMutateAsync({
            ...line,
            WorksaleId: worksaleId
        });
        validate(PriceOverrideAuthRequestedScreen.FastLineEntry, {
            Forms: response.Forms,
            BomCode: response.FastLineDetails?.BomCode,
            KitDetail: response.FastLineDetails?.KitDetail,
            KitStatus: response.FastLineDetails?.KitStatus,
            OverrideDetails: response.FastLineDetails,
            multipleBomDialogApi: 'calculateFastEntry'
        });

        if (response.FastLineDetails && response.FastLineDetails.BomCode) {
            setSelectedBomCode(response.FastLineDetails.BomCode);
        }

        return processValidationForms(response, line, async (validatedLine) => calculateAndValidateFastEntryDetails(validatedLine), calculateFastLineMessageBoxActionsCallback);
    }

    async function calculateAndValidateGridLineDetails(line: IWorksaleGridLineDetailFacade, field: keyof IWorksaleGridLineDetailFacade): Promise<{ line: IWorksaleGridLineDetailFacade; status: boolean }> {
        const response = await calculateGridLineDetailsMutateAsync({
            ...line,
            WorksaleId: worksaleId,
            WorksalelineId: line?.WorksalelineId,
            urlQueryParams: {
                ChangedField: field
            }
        });

        if (response?.Status) {
            if (!isNull(response?.GridLineDetails) && response?.GridLineDetails?.WorksalelineId !== 0) {
                return { line: response.GridLineDetails, status: response.Status };
            } else {
                return { line, status: response.Status };
            }
        } else {
            return { line: {}, status: false };
        }

    }

    const clearKittingDetail = () => {
        setKitStatus('');
        setBomList([]);
        setSelectedBomCode('');
    };

    const onGoBack = () => {
        if (workSaleFastEntryRef.current) {
            workSaleFastEntryRef.current.resetToDefault();
        }
        clearKittingDetail();
    };

    const handleActionClick = (status: 'c' | 'k') => {
        setMultipleBomState(false);
        setKitStatus(status);

        if (!status) {
            if (multipleBomDialogApi === 'calculateFastEntry') {
                onGoBack();
            }

            return;
        }

        switch (multipleBomDialogApi) {
            case 'calculateFastEntry': {
                if (status === 'c') {
                    if (workSaleFastEntryRef.current) {
                        workSaleFastEntryRef.current.disablePriceField();
                        workSaleFastEntryRef.current.calculateFastLineEntry({ KitStatus: 'c' });
                    }
                } else if (status === 'k') {
                    if (workSaleFastEntryRef.current) {
                        workSaleFastEntryRef.current.calculateFastLineEntry({ KitStatus: 'k' });
                    }
                } else {
                    onGoBack();
                }

                break;
            }
            case 'updateGridLine':
                linesDataSource.store().update(updatedWorksaleLineSummary.WorksalelineId, { ...updatedWorksaleLineSummary, KitStatus: status, BomCode: selectedBomCode });
                break;

            default:
        }
    };

    const handlePriceOverrideOk = (priceOverrideData: IPriceOverrideData) => {
        setPriceOverrideDialogLoading(true);
        switch (priceOverrideAuthRequestedScreen) {
            case PriceOverrideAuthRequestedScreen.FastLineEntry:
                linesDataSource.store().insert({ ...updatedWorksaleLineSummary, OverrideDetails: { ...priceOverrideDetailSummary, ...priceOverrideData } })
                    .then((response) => {
                        setPriceOverrideDialogLoading(false);
                        if (!response.Forms) {
                            workSaleFastEntryRef.current.resetToDefault();
                            reloadLines();
                        }

                        return response;
                    });
                break;
            case PriceOverrideAuthRequestedScreen.WorksaleLineGrid:
                linesDataSource.store().update(updatedWorksaleLineSummary.WorksalelineId, { ...updatedWorksaleLineSummary, OverrideDetails: { ...priceOverrideDetailSummary, ...priceOverrideData } })
                    .then((response) => {
                        if (worksaleLinesRef.current) {
                            worksaleLinesRef.current.cancelRowEditing();
                            reloadLines();
                        }
                        setPriceOverrideDialogLoading(false);

                        return response;
                    });
                break;

            default:
        }
    };

    const handlePriceOverrideCancel = async () => {
        setPriceOverrideDialogOpen(false);
        if (priceOverrideAuthRequestedScreen === PriceOverrideAuthRequestedScreen.FastLineEntry) {
            await workSaleFastEntryRef.current.calculateFastLineEntry();
            workSaleFastEntryRef.current.focusPriceField();
        }
    };

    const refreshWorksaleline = async (response) => {
        if (response?.Status && !isScreenRequest(response?.Forms)) {
            await linesDataSource.reload();
            worksaleLinesRef.current.refreshDataGrid();
            clearKittingDetail();
            workSaleFastEntryRef.current.resetToDefault();
        }
    };

    const onApply = async (query) => {
        try {
            const updatedQuery = { ...query, KitStatus: kitStatus, BomCode: selectedBomCode };
            const response = await linesDataSource.store().insert(updatedQuery);
            if (isLostSale(response.Forms)) {
                setLostSaleDialogStates({
                    open: true,
                    data: {
                        ...response.GridLineDetails?.inlineObject.LineLostsale,
                        WorksaleId: worksaleId
                    },
                    onOk: async (data) => {
                        const lostSaleResponse = await linesDataSource.store().insert({ ...response.GridLineDetails.inlineObject, LineLostsale: data });
                        await refreshWorksaleline(lostSaleResponse);
                    }
                });
            } else {
                await refreshWorksaleline(response);
            }

        } catch (err) {
            throw err;
        }
    };

    return (
        <div className={classes.worksaleDetailsContainer}>
            <WorksaleFastEntry
                innerRef={workSaleFastEntryRef}
                enableProductValidation={true}
                disabled={!Boolean(worksaleId) || isLineEditing}
                SalesEntity={salesEntity}
                CustomerId={customerId}
                KitStatus={kitStatus}
                BomCode={selectedBomCode}
                onProductChanged={clearKittingDetail}
                onFastEntryFieldChanged={calculateAndValidateFastEntryDetails}
                onApply={onApply}
                onUndo={async () => {
                    clearKittingDetail();
                }}
            />
            <WorksaleLines
                className={classes.grid}
                saleLinesDataSource={linesDataSource}
                siteHeaderFilter={worksaleSitesData?.Data?.map((site) => ({ text: site.Code, value: site.Code })) || []}
                innerRef={worksaleLinesRef}
                onSaleLineFieldChanged={calculateAndValidateGridLineDetails}
                showDetailOnClick={(values) => {
                    showDetailOnClick(values);
                }}
                onDelete={onDeleteSaleline}
                onSaving={async (rowData) => {
                    const updateResp = await linesDataSource.store().update(rowData.WorksalelineId, rowData);
                    if (!isPriceOverrideScreenRequest(updateResp.Forms?.[0])) {
                        await linesDataSource.reload();
                        worksaleLinesRef.current.refreshDataGrid();
                        if (worksaleLinesRef.current) {
                            worksaleLinesRef.current.cancelRowEditing();
                        }
                    }
                }}
            />
            <PriceOverrideAuthorityDialog
                open={priceOverrideDialogOpen}
                loading={priceOverrideDialogLoading}
                type={priceOverrideScreenType}
                data={priceOverrideDetailSummary}
                onOk={handlePriceOverrideOk}
                onCancel={handlePriceOverrideCancel}
            />
            <BOMDialog
                open={multipleBomOpen}
                onClose={() => setMultipleBomState(false)}
                data={bomList}
                kitStatus={kitStatus ? kitStatus.toLowerCase() : ''}
                onActionClick={handleActionClick}
                onSelectedRowChanged={setSelectedBomCode}
            />
        </div>
    );
};

export default withStyles(inlineStyles, { index: 1 })(React.forwardRef(WorksaleDetail));
