import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as lotDetailSelectors, actions as lotDetailActions } from 'ducks/inventoryEnquiry/stocking/lotDetails'
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup'
import { fetch } from 'api/inventoryEnquiry/stocking/lotDetails';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'IEStockingLotDetails',
  columnDefs: lotDetailSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lotDetailSelectors.gridOptions(state),
  isLoading: lotDetailSelectors.isLoading(state),
  filterRow: lotDetailSelectors.filterRow(state),
  selectedFilters: lotDetailSelectors.selectedFilters(state),
  appliedFilters: lotDetailSelectors.filterRow(state) && filterSelectors.getFilters(state, lotDetailSelectors.filterRow(state).formName),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    { ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId') },
  keyFields: [{ valueField: 'LotSerial', routeField: 'LotSerial' }, { valueField: 'Warehouse', routeField: 'Warehouse' }],
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lotDetailActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
