import { connect } from 'react-redux';
import Events from './Events';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as pickSlipSelectors } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedDespatchId: pickSlipSelectors.selectedDespatchId(state)
});

export default connect(mapStateToProps, {})(Events);
