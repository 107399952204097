import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, change, getFormSyncErrors, touch, reset } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import {
    actions as operationActions,
} from 'ducks/uiOperations';
import {
    actions as activitySchedulerActions,
    selectors as activitySchedulerSelectors
} from 'ducks/serviceActivityScheduling/serviceActivities';
import INewJobWizardProps from './NewJobModal.properties';
import NewJobWizard from './NewJobModal';
import { bindActionCreators, Dispatch } from 'redux';
import * as serviceActivitiesApi from 'api/serviceScheduling/serviceActivities';
import { actions as uiActions } from 'ducks/ui';

type IStateProps = Pick<INewJobWizardProps,
    'formValues' | 'formSyncErrors' | 'SalesEntity' | 'selectedCustomerDetails' | 'selectedFilters' | 'upcomingServiceJobs'
    | 'upcomingServAgreementsLoading' | 'upcomingServiceDate'
>;
const NEW_JOB_FORM = 'NewJobForm';
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    formValues: getFormValues(NEW_JOB_FORM)(state),
    SalesEntity: activitySchedulerSelectors.salesEntity(state),
    formSyncErrors: getFormSyncErrors(NEW_JOB_FORM)(state),
    selectedCustomerDetails: activitySchedulerSelectors.selectedCustomerDetails(state),
    selectedFilters: activitySchedulerSelectors.selectedFilters(state),
    upcomingServiceJobs: activitySchedulerSelectors.upcomingServiceJobs(state),
    upcomingServAgreementsLoading: activitySchedulerSelectors.upcomingServAgreementsLoading(state),
    upcomingServiceDate: activitySchedulerSelectors.upcomingServiceDate(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeOperationMode: operationActions.changeOperationMode,
        getDeliveryAddress: activitySchedulerActions.getDeliveryAddress,
        getCustomerDetails: activitySchedulerActions.getCustomerDetails,
        clearCustomerDetails: activitySchedulerActions.clearCustomerDetails,
        getServiceAgreementJobs: activitySchedulerActions.getServiceAgreementJobs,
        changeFormFieldValue: (fieldName: string, fieldValue: any) => change(NEW_JOB_FORM, fieldName, fieldValue),
        touchFormFields: (...args: any) => touch(NEW_JOB_FORM, ...args),
        resetForm: () => reset(NEW_JOB_FORM),
        performSearch: activitySchedulerActions.search,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

type INewJobWizardProperties = Readonly<Omit<
    INewJobWizardProps,
    'getJobActivities' | 'getDefaultJob' | 'createNewJob' | 'recordServiceAgreementSchedule' | 'fetchDeliverySiteOptions' | 'getCustomerContacts'>>;

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: INewJobWizardProperties) => {
    return (<NewJobWizard
        getJobActivities={serviceActivitiesApi.getJobActivities}
        getDefaultJob={serviceActivitiesApi.getDefaultJob}
        createNewJob={serviceActivitiesApi.createNewJob}
        fetchDeliverySiteOptions={serviceActivitiesApi.fetchDeliverySiteOptions}
        recordServiceAgreementSchedule={serviceActivitiesApi.recordServiceAgreementSchedule}
        getCustomerContacts={serviceActivitiesApi.getCustomerContacts}
        {...props} />);
});

export default ConnectedComponent;
