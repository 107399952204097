import { connect } from 'react-redux';
import AddFilterDialog from './AddFilterDialog';
import { getFormValues, change, reset } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as extractMaintenanceSelectors, } from 'ducks/extractMaintenance/extractMaintenanceTemplates';
import { bindActionCreators, Dispatch } from 'redux';

const FORM_NAME = 'AddFilterDialogForm';
const params = new URLSearchParams(location.search);
const mapStateToProps = (state: IApplicationState) => ({
    selectedTemplateId: extractMaintenanceSelectors.selected(state)?.TemplateId ?? params.get('TemplateId'),
    formValues: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeFieldValue: (field, value) => change(FORM_NAME, field, value),
        resetForm: () => reset(FORM_NAME)
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddFilterDialog);
