import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isDirty, reset, change } from 'redux-form';
import ServiceItemTypeDetails from './ServiceItemTypeDetails';
import { IApplicationState } from 'ducks/reducers';
import IServiceItemTypeDetailsProperties, { IActionBarHandlers } from './ServiceItemTypeDetails.properties';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as serviceItemTypeSelectors, actions as serviceItemTypeActions } from 'ducks/serviceItemTypeMaintenance/serviceItemType';
import * as serviceItemTypeApi from 'api/serviceItemTypeMaintenance/serviceItemType';

const FORM_NAME = 'ServiceItemTypeDetail';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  values: getFormValues(FORM_NAME)(state),
  selectedServItemType: serviceItemTypeSelectors.selected(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
    performSearch: serviceItemTypeActions.search,
  },
  dispatch);

type IServiceItemTypeDetails = Readonly<Omit<
  IServiceItemTypeDetailsProperties,
  'createServiceItemType' | 'deleteServiceItemType' | 'updateServiceItemType'>>;

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: IServiceItemTypeDetails) => {
  return (<ServiceItemTypeDetails
    createServiceItemType={serviceItemTypeApi.createServiceItemType}
    deleteServiceItemType={serviceItemTypeApi.deleteServiceItemType}
    updateServiceItemType={serviceItemTypeApi.updatedServiceItemType}
    {...props} />);
});

export default React.forwardRef(
  (props: IServiceItemTypeDetails, ref: React.Ref<IActionBarHandlers>) => {

    return <ConnectedComponent {...props} forwardedRef={ref} />;
  });
