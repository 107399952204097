import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils'

export const search = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchPost(
    '/ReturnForCreditEnquiry/SearchRFCs',
    { SearchText, Sort, BatchPage, BatchSize }
  )
    .then((something) => ({
      records: something.RFCEnquirySearchs.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }))
}

export const searchById = (RFC) => {
  return fetchGet(`/ReturnForCreditEnquiry/RFC/${RFC}/Details`, {}, 'Inline')
    .then((something) => ({
      records: [{ inlineObject: objectify(something.RFCEnquiryDetail), schema: something.RFCEnquiryDetail }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }))
}


