import { IFormViewForm } from 'components/FormView';

const EntryDetails: IFormViewForm = {
    id: 'EntryDetailsForm',
    layout: {
        rows: 2,
        columns: 3
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Entry',
                        name: 'EntryNumber',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        name: 'Description',
                        label: 'Description',
                        placeholder: '',
                        size: 'large',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Created',
                        name: 'Created',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '3',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Modified',
                        name: 'Modified',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
            ]
        },
        {
            id: '2',
            position: {
                row: 1,
                col: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'IMAGE_VIEW',
                    props: {
                        name: 'Image'
                    }
                }
            ]
        },
        {
            id: '3',
            position: {
                row: 2,
                col: 1,
                colspan: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Comment',
                        name: 'Comment',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true,
                        style: {
                            height: '200px'
                        }
                    }
                }
            ]
        }

    ]

};

export default EntryDetails;
