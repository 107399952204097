import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils';

export const fetch = async (query:
  {
    ProductId: number; Page: number; filters: any; BatchSize?: number;
  }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { ProductId, filters, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(
    `/InventoryEnquiry/Product/${ProductId}/Quotes`,
    filters
    && {
      ...filters, CustomerId: filters.CustomerId || 0
    },
    {
      BatchPage: Page, BatchSize
    },
    'Inline')
    .then(
      (result: {
        InventoryQuotes: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.InventoryQuotes) {

            return {
              records: result.InventoryQuotes.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
