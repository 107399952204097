import { FormViewField } from 'components/FormView';

export default {
  formName: 'OrderRecallFilterRow',
  validate: null,
  rowModelType: 'serverSide',
  parameters: [
    {
      id: 0,
      type: 'EX_LOOKUP_FIELD',
      props: {
        label: 'Entity',
        name: 'SalesEntity',
        lookupName: 'SalesEntity',
        size: 'small',
      }
    },
    {
      id: 1,
      type: 'SELECT_FIELD',
      props: {
        label: 'Status',
        name: 'OrderStatus',
        size: 'medium',
        required: false,
        options: [
          {
            value: 'a',
            label: 'All'
          },
          {
            value: '1',
            label: 'Incomplete'
          },
          {
            value: '2',
            label: 'Active'
          },
        ],
      }
    },
    {
      id: 2,
      type: 'EX_LOOKUP_FIELD',
      props: {
        label: 'Category',
        name: 'Category',
        lookupName: 'OrderCategory',
        size: 'small',
        required: false
      }
    },
    {
      id: 3,
      type: 'TEXT_FIELD',
      props: {
        label: 'Sales Order',
        name: 'SalesOrder',
        required: false,
        size: 'medium',
      }
    },
    {
      id: 4,
      type: 'EX_LOOKUP_FIELD',
      props: {
        label: 'Customer',
        name: 'CustomerId',
        lookupName: 'Customer',
        required: false,
        size: 'medium',
      }
    },
    {
      id: 5,
      type: 'TEXT_FIELD',
      props: {
        label: 'Customer P/O',
        name: 'CustomerPurchaseOrder',
        required: false,
        size: 'medium'
      }
    },
  ] as FormViewField[]
};
