import React, { Component } from 'react';
import DataGrid from '../DataGridDevEx';
import { OPERATIONS } from '../../../utils/operations';
import { SnackBarDisplayTimeMilliseconds } from 'utils/constants';
import { showSnackBar } from '../SnackBars/SnackBar.hooks';

class Diary extends Component {
  componentDidMount() {
    if (this.props.selectedTab !== 'DiaryDetails' && this.props.selectedTab !== 'CreditDiaryDetails' && this.props.selectedTab !== 'PurchaseDiaryDetails') {
      const { notifications, errors, isLoading } = this.props;
      if (notifications) {
        this.checkNotifications(notifications);
      }
      if (errors && errors.Status === 'ValidationError' && !isLoading) {
        this.checkValidationErrors(errors);
      }
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.operationMode === OPERATIONS.NEW) {
      this.props.changeSelectedTab(this.props.formValues.doubleClickActionTab || 'DiaryDetails');
    }

  }

  componentWillUnmount() {
    this.props.destroyForm();
  }

  checkValidationErrors = (error) => {
    const { ValidationErrors } = error;
    ValidationErrors.forEach((item, ind) => {
      showSnackBar({ variant: 'warning', message: `Validation Error = ${item.Property} : ${item.Message}` }, SnackBarDisplayTimeMilliseconds);
    });
  }

  checkNotifications = (notifications) => {
    if (notifications.delete) {
      showSnackBar({ variant: 'success', message: 'Diary Successfully Deleted' });
    }
  }

  getApi = () => {
    const { fetchApi, formValues } = this.props;
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, formValues.params[curr]);
    };

    return (formValues && formValues.params && Object.keys(formValues.params).reduce(reducer, fetchApi));
  }


  render() {
    const { style, formValues, diaryType } = this.props;
    const api = this.getApi();
    return (
      <DataGrid
        style={style}
        module={'Diary'}
        doubleClickActionTab={formValues && formValues.doubleClickActionTab}
        reqParams={
          api && !api.includes('<') && !api.includes('>') &&
          {
            fetchApi: this.getApi(),
            diaryType: diaryType,
            ...(formValues && formValues.params)
          }
        }
      />
    );
  }
}

export default Diary;
