import { fetchGet, objectify } from '../utils'


export const fetch = (query) => {
  const { ProductId } = query
  return fetchGet(`InventoryEnquiry/Product/${ProductId}/Details`, {}, 'Inline')
    .then((result) => {
      if (result) {
        const obj = objectify(result.ProductDetails, null, 'Inline');
        return { inlineObject: { ...obj, Quantities: result.ProductDetails ? result.ProductDetails.Quantities && result.ProductDetails.Quantities.map(item => objectify(item, null, 'Inline')) : null }, schema: result.ProductDetails }
      }
    }
    )
}

export const fetchSummary = (query) => {
  const { ProductId } = query;
  return fetchGet(`InventoryEnquiry/Product/${ProductId}/Summary`, {}, 'Inline')
    .then((result) => result ? objectify(result.ProductDetailsSummary, null, 'Inline') : null)
}