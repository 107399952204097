import { connect } from 'react-redux';
import Delivery from './Delivery';
import { selectors as purchaseOrdersSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { getFormValues, reset, isDirty } from 'redux-form';
import { Dispatch, bindActionCreators } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'DeliveryDetailsForm';

const mapStateToProps = (state: IApplicationState) => ({
    selectedPurchaseOrder: purchaseOrdersSelectors.selected(state),
    dirty: isDirty(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
