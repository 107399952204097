export const MODULE_TREE = [
  { id: 'ProductDetails', label: 'Product Details', parent: '' },
  { id: 'Associations', label: 'Associations', parent: '' },
  { id: 'UserFields', label: 'User Fields', parent: '' },
  { id: 'AssociationsAlternates', label: 'Alternates', parent: 'Associations' },
  { id: 'AssociationsSupersessions', label: 'Supersessions', parent: 'Associations' },
  { id: 'AssociationsBillOfMaterials', label: 'Bill of Materials', parent: 'Associations' },
  { id: 'HistoricalDates', label: 'Historical Dates', parent: 'ProductDetails' },
  { id: 'Notepad', label: 'Notepad', parent: 'ProductDetails' },
  { id: 'WhereUsed', label: 'Where Used', parent: '' },
  { id: 'WhereUsedBOM', label: 'Bill of Materials', parent: 'WhereUsed' },
  { id: 'WhereUsedCatalogue', label: 'Catalogue', parent: 'WhereUsed' },
  { id: 'Stocking', label: 'Stocking', parent: '' },
  { id: 'StockingSerials', label: 'Serial Numbers', parent: 'Stocking' },
  { id: 'StockingMovements', label: 'Movements', parent: 'Stocking' },
  { id: 'StockingQuantities', label: 'Quantities', parent: 'Stocking' },
  { id: 'StockingLotDetails', label: 'Lot Details', parent: 'Stocking' },
  { id: 'StockingBinLocations', label: 'Bin Locations', parent: 'Stocking' },
  { id: 'StockingReserveHistory', label: 'Reserve History', parent: 'Stocking' },
  { id: 'StockingLotAllocationDetails', label: 'Allocation Details', parent: 'StockingLotDetails' },
  { id: 'StockingLotPicking', label: 'Picking', parent: 'StockingLotAllocationDetails' },
  { id: 'StockingLotReserved', label: 'Reserved', parent: 'StockingLotAllocationDetails' },
  { id: 'StockingLotInPutAway', label: 'In Put Away', parent: 'StockingLotAllocationDetails' },
  { id: 'StockingQuantitiesDetails', label: 'Quantity details', parent: 'StockingQuantities' },
  { id: 'StockingQuantitiesPurchaseOrder', label: 'Purchase Orders', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesProductionOrder', label: 'Production Orders', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesInTransitImport', label: 'In Transit Import', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesInTransitLocal', label: 'In Transit Local', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesPicking', label: 'Picking', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesReserved', label: 'Reserved', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesKitting', label: 'Kitting', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesServiceWIP', label: 'Service WIP', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesProductionWIP', label: 'Production WIP', parent: 'StockingQuantitiesDetails' },
  { id: 'StockingQuantitiesInPutAwayWIP', label: 'In Put Away', parent: 'StockingQuantitiesDetails' },
  { id: 'Sales', label: 'Sales', parent: '' },
  { id: 'ServiceOrders', label: 'Service Orders', parent: 'Sales' },
  { id: 'IESSalesOrders', label: 'Sales Orders', parent: 'Sales' },
  { id: 'IESReturnAuthorities', label: 'Return Authorities', parent: 'Sales' },
  { id: 'IESLostSales', label: 'Lost Sales', parent: 'Sales' },
  { id: 'IESQuotes', label: 'Quotes', parent: 'Sales' },
  { id: 'Pricing', label: 'Pricing', parent: '' },
  { id: 'Prices', label: 'Prices', parent: 'Pricing' },
  { id: 'ForeignPrices', label: 'Foreign Prices', parent: 'Pricing' },
  { id: 'PriceMatrix', label: 'Price Matrix', parent: 'Pricing' },
  { id: 'Schedule', label: 'Schedule', parent: 'Pricing' },
  { id: 'FutureBreaks', label: 'Future Breaks', parent: 'Pricing' },
  { id: 'PriceBreaks', label: 'Price Breaks', parent: 'Pricing' },
  { id: 'Attachments', label: 'Attachments', parent: '' },
  { id: 'Documents', label: 'Documents', parent: 'Attachments' },
  { id: 'Links', label: 'Links', parent: 'Attachments' },
  { id: 'Images', label: 'Images', parent: 'Attachments' },
];
export const NON_EXISTING_MODULES = [
  { id: 'InventoryEnquiry', target: 'ProductDetails' },
  { id: 'Associations', target: 'AssociationsAlternates' },
  { id: 'WhereUsed', target: 'WhereUsedBOM' },
  { id: 'Stocking', target: 'StockingQuantities' },
  { id: 'Pricing', target: 'Prices' },
  { id: 'Sales', target: 'IESSalesOrders' },
  { id: 'StockingQuantitiesDetails', target: 'StockingQuantitiesPurchaseOrder' },
  { id: 'StockingLotAllocationDetails', target: 'StockingLotPicking' },
  { id: 'Attachments', target: 'Images' }
];
