import {
  createActions, asyncInitialState, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row })
}, 'purchaseOrderLine');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  purchaseOrderLineActions: {
  },
  purchaseOrderLineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 150 },
    {
      headerName: 'Product', field: 'ProductIdDisplay',
      minWidth: 150,
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      },
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
    },
    { headerName: 'Ordered', field: 'QtyOrderedDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'On order', field: 'QtyOnOrderDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Supplied', field: 'QtySuppliedDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'In transit', field: 'QtyIntransitDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Currency', field: 'Currency', minWidth: 150, suppressSorting: true },
    { headerName: 'Buying unit', field: 'BuyingUnit', minWidth: 150, suppressSorting: true },
    { headerName: 'Extended', field: 'ExtendedTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Description', field: 'Description', minWidth: 150 },
    { headerName: 'Supplier product', field: 'SupplierProduct', minWidth: 150 },
    { headerName: 'Product group', field: 'ProductGroup', minWidth: 150, suppressSorting: true },
    { headerName: 'Due ito store', field: 'DueDate', minWidth: 150 },
  ],
  selectedFilters: {
    ProductId: 0,
    Finalised: 'All'
  },
  filterRow: {
    formName: 'PurchaseOrderFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Product',
          name: 'ProductId',
          lookupName: 'Product',
          size: 'medium'
        }
      },
      {
        id: 1,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'Finalised',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      let LineNumber;
      if (selected && selected.PurchaseOrderLineNumber) {
        LineNumber = selected.PurchaseOrderLineNumber;
      } else {
        LineNumber = selected && selected.LineNumber;
      }
      return {
        ...state,
        data: {
          ...state.data,
          selected: { ...selected, LineNumber }
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.purchaseOrderLine,
  {
    // selectedOrderLine: data => data.selected,
    purchaseOrderLineActions: data => data.purchaseOrderLineActions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.purchaseOrderLine.loading,
  gridOptions: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.gridOptions,
  selectedOrderLine: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.selected,
  purchaseOrderLineColumns: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.purchaseOrderLineColumns,
  purchaseOrderLineActions: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.purchaseOrderLineActions,
  filterRow: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.filterRow,
  selectedFilters: state => state.purchaseOrderEnquiry.purchaseOrderLine.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)