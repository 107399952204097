import React, { Component } from 'react';
import _ from 'lodash';
export const isPropsSame = (listOfProps, thisProps, nextProps) => {
  const diffList = listOfProps.filter(key => thisProps[key] !== nextProps[key])
  return diffList > 0;
}

export function withPropsChecker(WrappedComponent) {
  return class PropsChecker extends Component {
    componentDidUpdate(prevProps) {
      const nextProps = this.props;
      Object.keys(nextProps)
        .filter(key => {
          return nextProps[key] !== prevProps[key];
        })
        .map(key => {
          console.log(
            'changed property:',
            key,
            'from',
            prevProps[key],
            'to',
            nextProps[key]
          );
          return key
        });
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

// Usage
withPropsChecker('MyComponent')

/*eslint-disable no-self-compare */

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
function is(x, y) {
  // SameValue algorithm
  if (x === y) { // Steps 1-5, 7-10
    // Steps 6.b-6.e: +0 != -0
    // Added the nonzero y check to make Flow happy, but it is redundant
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    // Step 6.a: NaN == NaN
    return x !== x && y !== y;
  }
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export const isShallowEqual = (objA, objB) => {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null ||
    typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (
      !hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

export const shallowCompare = (instance, nextProps, nextState) => {
  return (
    !isShallowEqual(instance.props, nextProps) ||
    !isShallowEqual(instance.state, nextState)
  );
}

export const obj_diff = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

/**
* isNull checks that passed value is empty, undefined or null
* @param {any} value
* @returns {boolean}
*/
export const isNull = (value) => {
  return value === '' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0);
}
/**
 * pathOr is used to safely extract required value from multilevel objects. 
 * @param {any} defaultValue  If drilling breaks at some level it will return default value.
 * @param {Array} path  path to the desired value in an object.
 * @param {object} data  Actual data from which a value should be extracted. 
 * @returns {any}
 */

export const pathOr = (defaultValue, path, data) => {
  if (!isNull(path) && Array.isArray(path) && !isNull(data)) {
    const index = path[0];
    const nextPath = path.slice(1);
    if (
      (Array.isArray(data) && data[index]) ||
      (typeof data === 'object' && data.hasOwnProperty(index))
    ) {
      if (isNull(nextPath)) {
        return data[index];
      } else {
        return pathOr(defaultValue, nextPath, data[index]);
      }
    } else {
      return defaultValue;
    }
  }
};
/**
 * updateSomeProps traverse nested objects and update provided props in provided data . 
 * @param {object} data  Data containing specified props to update.
 * @param {object} propsToUpdate  Contains props and values to be updated in data object. 
 */

export const updateSomeProps = (data, propsToUpdate) => {

  if (!isNull(data) && !isNull(propsToUpdate) && typeof data === 'object' && typeof propsToUpdate === 'object') {
    const firstRefKey = pathOr('', [0], Object.keys(propsToUpdate));

    for (const key in data) {
      if (data.hasOwnProperty(firstRefKey)) {
        Object.keys(propsToUpdate).forEach(refKey => {
          data[refKey] = propsToUpdate[refKey];
        });
        break;
      } else if (data[key] && typeof data[key] === 'object') {
        updateSomeProps(data[key], propsToUpdate);
      }
    }
  }

  return data;
};
/**
 * This functions checks all the required precedent fields are filled in provided values . 
 * @param {Array} precedentFields  required fields.
 * @param {object} values  data containing precedentFields. 
 */

export const isPrecedentFieldsFilled = (precedentFields = [], values = {}) => {
  return precedentFields.every((fieldName = '') => {
    const value = values && values[fieldName]
    return value !== false && !isNull(value)
  })
}

export const deepCopyFunction = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

/**
 * This functions checks if currentTab is child and returns boolean. 
 * @param {Array} MODULE_TREE  Modules Metadata.
 * @param {string} currentTab  current tab. 
 */

export const isTabChild = (MODULE_TREE = [], currentTab = '') => {
  const currentTabNode = MODULE_TREE.find((tab) => tab.id === currentTab);

  return currentTabNode && !isNull(currentTabNode.parent);
}

/**
 * This functions find and returns parent tab. 
 * @param {Array} MODULE_TREE  Modules Metadata.
 * @param {string} currentTab  current tab. 
 */

export const findParentTab = (MODULE_TREE = [], currentTab = '') => {
  const currentTabNode = MODULE_TREE.find((tab) => tab.id === currentTab);
  if (currentTabNode && !isNull(currentTabNode.parent)) {
    return findParentTab(MODULE_TREE, currentTabNode.parent);
  }

  return currentTabNode ? currentTabNode.id : '';
}

/**
 * This functions find and returns fist key for a given value in the given object. 
 * @param {Object} object  Values.
 * @param {any} value  Value to find. 
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => {
    const tabPathname = (typeof object[key] === 'string') ? object[key] : object[key].pathname;

    return tabPathname === value;
  });
}

/**
 * This functions find if passed URL is valid or not
 * @param {string} str  value.
 */

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
    'i');

  return !!pattern.test(str);
};

/**
 * This functions find and returns  a boolean if any value in form has been updated which then calls the calculate API
 * @param {string} fieldName  Values.
 * @param {any} value  updated Value from the form. 
 * @param {any} formData  the whole data in the form. 
 */
export const formValueChangedByUser = (fieldName, value, formData) => (value !== (formData.inlineObject.hasOwnProperty(`${fieldName}Display`) ?
  formData.inlineObject[`${fieldName}Display`] : formData.inlineObject[fieldName]));
/*
* The function returns an element by matching provided innerText
 * @param {string} targetTagName  element.
 * @param {any} targetText  innerText. 
 */

export const getElementByInnerText = (targetTagName, targetText) => {
  const xpath = `//${targetTagName}[text()="${targetText}"]`;

  return document.evaluate(
    xpath,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue;
};
