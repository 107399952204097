import { IFormViewForm } from 'components/FormView';

const PickSlipNotepadForm: IFormViewForm = {
  id: 'PickSlipNotepadForm',
  layout: {
    rows: 1,
    columns: 1,
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Notepad',
            name: 'Note',
            size: 'large',
            style: {
              height: '650px',
            }
          }
        },
      ]
    },
  ]
};

export default PickSlipNotepadForm;
