import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset } from 'redux-form';
import ParksaleConfirmation from './ParksaleConfirmation';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty('ParksaleConfirmationForm')(state),
  values: getFormValues('ParksaleConfirmationForm')(state),
  isValid: isValid('ParksaleConfirmationForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset('ParksaleConfirmationForm')
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParksaleConfirmation);
