import { combineReducers } from 'redux';
import servicePattern, { IServicePatternState } from './servicePattern';

export interface IServicePatternMaintenanceState {
  servicePattern: IServicePatternState;
}
const combined = combineReducers<IServicePatternMaintenanceState>({
  servicePattern
});

export default combined;
