const SummaryTableSchema = {
  CancelSaleSchema: [
    {
      objectName: 'cancelSaleSummary',
      isLoading: 'loadingCancelSaleSummary',
      children: [
        {
          label: 'Customer',
          fieldNames: [
            'CustomerIdDisplay',
            'CustomerIdDisplayLabel'
          ],
        },
        {
          label: 'Customer P/O',
          fieldNames: [
            'CustomerPO',
          ]
        }
      ]
    },
  ],
  DeleteSaleLineSchema: [
    {
      objectName: 'deleteSaleLineSummary',
      isLoading: 'loadingDeleteSaleLineSummary',
      children: [
        {
          label: 'Product',
          fieldNames: [
            'ProductIdDisplay',
            'ProductIdDisplayLabel'
          ]
        },
        {
          label: 'Customer',
          fieldNames: [
            'CustomerIdDisplay',
            'CustomerIdDisplayLabel'
          ]
        },
        {
          label: 'Customer P/O',
          fieldNames: [
            'CustomerPO'
          ],
        },
        {
          label: 'Quantity',
          fieldNames: [
            'QuantityLostDisplay',
          ],
          valueRenderer: {
            isNumeric: true,
            floatingNumber: true
          }
        },
        {
          label: 'Cost Each',
          fieldNames: [
            'CostDisplay'
          ],
          valueRenderer: {
            isNumeric: true,
          }
        },
        {
          label: 'Sell/unit (Ex. Tax)',
          fieldNames: [
            'PriceDisplay'
          ],
          valueRenderer: {
            isNumeric: true,
            floatingNumber: true
          }
        },
      ]
    },
  ],
  ShortSuppliedSaleLineSchema: [
    {
      objectName: 'shortSuppliedSaleLineSummary',
      isLoading: 'loadingShortSuppliedSaleLineSummary',
      children: [
        {
          label: 'Product',
          fieldNames: [
            'ProductIdDisplay',
            'ProductIdDisplayLabel'
          ]
        },
        {
          label: 'Customer',
          fieldNames: [
            'CustomerIdDisplay',
            'CustomerIdDisplayLabel'
          ]
        },
        {
          label: 'Customer P/O',
          fieldNames: [
            'CustomerPO'
          ],
        },
        {
          label: 'Quantity',
          fieldNames: [
            'QuantityLostDisplay',
          ],
          valueRenderer: {
            isNumeric: true,
            floatingNumber: true
          }
        },
        {
          label: 'Cost Each',
          fieldNames: [
            'CostDisplay'
          ],
          valueRenderer: {
            isNumeric: true
          }
        },
        {
          label: 'Sell/unit (Ex. Tax)',
          fieldNames: [
            'PriceDisplay'
          ],
          valueRenderer: {
            isNumeric: true,
            floatingNumber: true
          }
        },
      ]
    },
  ],
};

export default SummaryTableSchema;
