import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './GenericDetailsForm.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import IGenericDetailsFormProps from './GenericDetailsForm.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import TextField from '@markinson/uicomponents-v2/TextField';

const GenericDetailsForm = (props: IGenericDetailsFormProps): JSX.Element => {
  const { classes, open, title, initialValues, schema, onApply, onCancel } = props;

  const [amount, setAmount] = React.useState<number>(0);
  const [reference, setReference] = React.useState<string>('');

  React.useEffect(
    () => {
      if (initialValues) {
        setAmount(initialValues.Amount ? initialValues.Amount : 0);
        setReference(initialValues.Reference);
      } else {
        setAmount(0);
        setReference('');
      }
    },
    [initialValues]);

  const getModalContent = () => {
    return <div className={classes.mainDiv}>
      <NumericField
        value={amount}
        label={'Payment Amount'}
        minValueExclusive={0}
        onBlur={(value) => { setAmount(value); }}
        required={schema.Amount.Required} />
      <TextField
        value={reference}
        label={'Reference'}
        onChange={(value) => { setReference(value); }}
        required={schema.Reference.Required} />
    </div >;
  };

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        disabled:
          (schema.Amount.Required && amount === undefined) || (amount !== undefined && amount <= 0) ||
          (schema.Reference.Required && !reference),
        listener: async () => {
          if (onApply) {
            onApply({
              ...initialValues,
              Amount: amount,
              Reference: reference
            });
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }
    ];
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={title}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(GenericDetailsForm);
