const ComponentDetails = {
  'id': 'ComponentDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product description',
            'name': 'ProductDescription',
            'placeholder': '',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Customer product',
            'name': 'CustomerProduct',
            'placeholder': '',
            'size': 'medium'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Component quantity',
            'name': 'Quantity',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
            'type': 'number',
            'decimalSeparator': true,
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reserved quantity',
            'name': 'ReservedQuantity',
            'size': 'medium',
            'alignment': 'right',
            'type': 'number',
            'decimalSeparator': true,
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_AREA',
          'visible': true,
          'props': {
            'label': 'Component line comment',
            'name': 'ComponentLineComment',
            'fullWidth': true,
            'style': {
              'height': '350px'
            }
          }
        }
      ]
    }
  ]
}
export default ComponentDetails;