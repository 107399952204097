import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import ChargesForm from './InternalOrderDialog.form';
import IInternalOrderProperties from './InternalOrderDialog.properties';

const InternalOrderDialog = (props: IInternalOrderProperties) => {
    const { open, changeConfirmationDialog, onOk, value, valueSchema, onCancel, formValues } = props;

    const fromFieldRef = React.useRef<HTMLInputElement>();

    React.useEffect(
        () => {
            if (open) fromFieldRef?.current?.focus();
        },
        [open]
    );

    return (
        <div>
            <FormViewModal
                open={open}
                loading={false}
                title='Internal Order Notification'
                formProps={{
                    formSchema: ChargesForm,
                    style: { padding: 0 },
                    initialValues: value,
                    valuesSchema: valueSchema,
                    fieldExtensions: {
                        Sender: {
                            fieldRef: (Ref) => fromFieldRef.current = Ref
                        },
                    },
                }}
                actions={[
                    {
                        title: 'ok',
                        listener: () => onOk(formValues),
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            changeConfirmationDialog({
                                open: true,
                                title: 'Cancel Notification',
                                message: 'Are you sure you want to cancel the notification?',
                                okLabel: 'Yes',
                                cancelLabel: 'No',
                                onCancel: () => undefined,
                                onOk: onCancel,
                            });
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </div>
    );
};

export default InternalOrderDialog;
