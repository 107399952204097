import { combineReducers } from 'redux';
import creditNoteContext, { ICreditNoteContextState } from './creditNoteContext';
import creditNote, { ICreditNoteState } from './creditNote';
import creditNoteLine, { ICreditNoteLineState } from './creditNoteLine';

export interface ICreditNotesState {
  creditNoteContext: ICreditNoteContextState;
  creditNote: ICreditNoteState;
  creditNoteLine: ICreditNoteLineState;
}

const creditNotesReducer = combineReducers({
  creditNoteContext,
  creditNote,
  creditNoteLine
});

export default creditNotesReducer;
