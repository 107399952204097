export const MARGIN = 16;
export const PADDING_MULTIPLIER = 2;

export const SelectedGridColumns = [
  { headerName: 'Serial number', field: 'SerialNumber', minWidth: 200, suppressSorting: true, },
];

export const AvailableGridColumns = [
  { headerName: ' ', field: 'add', cellRenderer: 'addIcon', minWidth: 65, maxWidth: 65, suppressSorting: true, },
  { headerName: 'Serial number', field: 'SerialNumber', minWidth: 200, suppressSorting: true, },
  { headerName: 'Entered', field: 'Entered', type: 'numericColumn', minWidth: 200, suppressSorting: true, },
];

export const SummarySchema = [
  {
    objectName: 'serialNumberSummary',
    isLoading: 'serialNumberSummaryLoading',
    children: [
      {
        label: 'Product',
        fieldNames: [
          'ProductDisplay'
        ],
        valueRenderer: {
          api: {
            api: '/salesOrderEnquiry/lookup/action',
            name: 'enquiry',
            params: {
              queryFields: {
                ProductId: 'Product',
                SearchText: 'ProductDisplay'
              }
            }
          }
        }
      },
      {
        label: 'Product Description',
        fieldNames: [
          'ProductLabel'
        ]
      },
      {
        label: 'Product Group',
        fieldNames: [
          'ProductGroup'
        ]
      },
      {
        label: 'Product Type',
        fieldNames: [
          'ProductTypeDisplay'
        ]
      },
      {
        label: 'Customer Product',
        fieldNames: [
          'CustomerProduct'
        ]
      }
    ]
  },
  {
    objectName: 'serialNumberSummary',
    isLoading: 'serialNumberSummaryLoading',
    children: [
      {
        label: 'Status',
        fieldNames: [
          'Status'
        ]
      }
    ]
  },
  {
    objectName: 'serialNumberSummary',
    isLoading: 'serialNumberSummaryLoading',
    children: [
      {
        label: 'Warehouse',
        fieldNames: [
          'WarehouseDescription'
        ]
      },
      {
        label: 'Available',
        fieldNames: [
          'Available'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      }
    ]
  },
  {
    objectName: 'serialNumberSummary',
    isLoading: 'serialNumberSummaryLoading',
    children: [
      {
        label: 'REQUIRED',
        fieldNames: [
          'Required'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      },
      {
        label: 'Entered',
        fieldNames: [
          'EnteredQuantity'
        ],
        valueRenderer: {
          isNumeric: true,
          floatingNumber: true
        }
      }
    ]
  }
];
