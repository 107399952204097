import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Paging, Sorting, HeaderFilter, Editing, Button } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IDiaryGridProps } from './DiaryGrid.properties';
import styles from './DiaryGrid.styles';

import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { createDataSource, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { Paper } from '@material-ui/core';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import FilterRow from 'components/common/DataGridDevEx/FilterRow';
import filterRow from './DiaryGrid.filterRow';
import { useDeleteDiaryLines, useRetrieveDiaryLines, useUpdateDiary } from 'api/pickSlips/diary';

const SMALL_COLUMN_MIN_WIDTH = 100;
const SMALL_COLUMN_DEFAULT_WIDTH = 100;
const COMMAND_COLUMN_WIDTH = 75;
const LARGE_COLUMN_MIN_WIDTH = 200;
const ICON_COLUMN_DEFAULT_WIDTH = 50;

function DiaryGrid(props: Readonly<IDiaryGridProps>): JSX.Element {
    const { classes, selectedDespatchId, showDetailOnClick, changeConfirmationDialog
    } = props;
    const dataGridInnerRef = React.useRef<DataGrid>();
    const filterButtonRef = React.useRef<HTMLButtonElement>();

    const { onFocusedCellChanging, onKeyDown } = useGridKeyboardNavigation();
    const fetchLinesMutation = useRetrieveDiaryLines();
    const updateLinesMutation = useUpdateDiary();
    const deleteLinesMutation = useDeleteDiaryLines(selectedDespatchId);

    const fetchLines = React.useCallback(
        (BatchPage, BatchSize, Sort) => Number(selectedDespatchId) ? fetchLinesMutation.mutateAsync({
            DespatchId: selectedDespatchId,
            urlQueryParams: {
                BatchPage,
                BatchSize,
                Sort,
            }

        }).then((response) => response?.DiaryEntry) : ([] as any)
        ,
        [selectedDespatchId]
    );

    const updateLine = React.useCallback(
        async (data: any) => {
            const item = dataGridInnerRef.current?.instance?.getDataSource().items().find((f) => f.Id === data.Id);
            const result = await updateLinesMutation.mutateAsync({DiaryId: item.DiaryId, Subject: item.Subject, CommentFull: item.CommentFull, ...data});

            if (result.Status && !result.Forms) {
                reloadLines();
            }

            return result;
        },
        []
    );

    const salesDiaryDataSource = React.useMemo(
        () => createDataSource(
            'Id',
            { fetch: fetchLines, update: updateLine }
        ),
        [fetchLines]
    );

    const reloadLines = async () => {
        salesDiaryDataSource.reload();
        await dataGridInnerRef.current.instance.refresh(true);
    };

    async function deleteClick(e: any): Promise<void> {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the selected record?',
            okLabel: 'Delete',
            onCancel: () => undefined,
            onOk: async () => {
                const {Status } = await deleteLinesMutation.mutateAsync({
                    DiaryId: e.row.data.DiaryId
                });
                if (Status) {
                    reloadLines();
                }

                return Status;
            }
        });
    }

    return (
        <Paper className={classes.paperContainer}>
            <FilterRow
                filterButtonRef={filterButtonRef}
                disableInitialApplyCall={true}
                parameters={filterRow.parameters}
                formName={filterRow.formName}
                onApplyFilters={() => { return; }}
                initialValues={{}}
                valuesSchema={{}}
                validate={filterRow.validate}
                rowModelType={filterRow.rowModelType}
                onFilterButtonShiftTab={() => { return; }}
            />
            <DataGrid
                ref={dataGridInnerRef}
                className={classes.commitmentDataGrid}
                dataSource={salesDiaryDataSource}
                showBorders={false}
                renderAsync={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                onKeyDown={onKeyDown}
                onFocusedCellChanging={onFocusedCellChanging}
                noDataText=''
                onEditorPreparing={(e) => {
                    e.editorOptions.onFocusIn = (args) => {
                        const input = args.element.querySelector("input[type='text']");
                        if (input) {
                            input.select();
                        }
                    };
                }}
            >
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={true}
                />
                <HeaderFilter visible />
                <Column
                    allowEditing={false}
                    minWidth={ICON_COLUMN_DEFAULT_WIDTH}
                    width={COMMAND_COLUMN_WIDTH}
                    allowResizing={false}
                    fixed={true}
                    alignment='center'
                    allowHeaderFiltering={false}
                    cellComponent={(allProps) => {
                        const disableLine = allProps.data.data && allProps.data.data.LineType === 'r';

                        return <MoreIcon className={disableLine ? classes.disabledLineDetailIcon : classes.lineDetailIcon} onClick={() => {
                            if (!disableLine && showDetailOnClick) {
                                showDetailOnClick(allProps.data ? allProps.data.data : null);
                            }
                        }} />;
                    }}
                />
                <Column
                    dataField={'Date'}
                    caption={'Date'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                    alignment={'right'}
                    fixed={true}
                >
                </Column>
                <Column
                    dataField={'Time'}
                    caption={'Time'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                    alignment={'right'}
                />
                <Column
                    dataField={'Subject'}
                    caption={'Subject'}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column
                    dataField={'UserIdDescription'}
                    caption={'User Id'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                />
                <Column
                    dataField={'Surname'}
                    caption={'Surname'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    allowEditing={false}
                />
                <Column
                    dataField={'FirstName'}
                    caption={'First name'}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH} />
                <Column
                    dataField={'CommentFull'}
                    caption={'Comment'}
                    allowHeaderFiltering={false}
                    allowEditing={true}
                    minWidth={LARGE_COLUMN_MIN_WIDTH} />
                <Column
                    dataField={'SystemGeneratedDisplay'}
                    caption={'System generated'}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                    minWidth={LARGE_COLUMN_MIN_WIDTH}
                />
                <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false} fixed={true}>
                    <Button name={'save'} icon={'save'} />
                    <Button name={'edit'} icon={'edit'} disabled={(e) => e.row.data.SystemGenerated === true} />
                    <Button name={'delete'} icon={'trash'} onClick={deleteClick} disabled={(e) => e.row.data.SystemGenerated === true} />
                    <Button name={'cancel'} icon={'undo'} />
                </Column>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Sorting mode='none' />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
        </Paper>

    );
}

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(DiaryGrid)));
