import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as paymentDetailsSelector, actions as paymentDetailsActions } from 'ducks/common/paymentDetails';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as cashReceiptingSelectors } from 'ducks/cashReceipting/cashReceipt';

export const mapStateToProps = (state) => ({
  gridName: 'PaymentMethod',
  columnDefs: paymentDetailsSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: paymentDetailsSelector.gridOptions(state),
  filterRow: paymentDetailsSelector.filterRow(state),
  selectedFilters: paymentDetailsSelector.selectedFilters(state),
  appliedFilters: paymentDetailsSelector.filterRow(state) && filterSelectors.getFilters(state, paymentDetailsSelector.filterRow(state).formName),
  reqParams: {
    entity: cashReceiptingSelectors.entity(state),
    customerId: cashReceiptingSelectors.CustomerId(state)
  },
  newLineRenderer: paymentDetailsSelector.newLineRenderer(state),
  rowData: paymentDetailsSelector.paymentLines(state),
  isLoading: paymentDetailsSelector.isLoading(state)
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  deleteSelectedRecords: paymentDetailsActions.deletePayment,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: paymentDetailsActions.setSelected,
  applyFilters: paymentDetailsActions.updatePaymentContext
};
