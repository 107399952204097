export const CUSTOMER_LOOKUP_NAME = 'Customer';
export const CUSTOMER_LOOKUP_COLUMNS = [
    {
        dataField: 'CustomerId',
        caption: 'Customer',
        allowResizing: true,
        minWidth: 100,
        width: 100,
        fixed: true,
        hideInV3: true
    },
    {
        dataField: 'CustomerAccountNumber',
        caption: 'Customer',
        allowResizing: true,
        minWidth: 100,
        width: 100,
        fixed: true,
        hideInV2: true
    },
    {
        dataField: 'Name',
        caption: 'Name',
        allowResizing: true,
        minWidth: 200,
        width: 200,
        fixed: false
    },
    {
        dataField: 'Abbreviation',
        caption: 'Abbreviation',
        allowResizing: true,
        minWidth: 100,
        width: 100,
        fixed: false
    },
    {
        dataField: 'City',
        caption: 'City',
        allowResizing: true,
        minWidth: 200,
        width: 200,
        fixed: false
    },
    {
        dataField: 'State',
        caption: 'State',
        allowResizing: true,
        minWidth: 200,
        width: 200,
        fixed: false
    },
];
export const SEARCH_SCREEN_DIALOG_STYLE = {
    width: 892,
    maxWidth: 892,
    height: 495,
    maxHeight: 495
};
