import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as purchaseOrderSelectors, actions as purchaseOrderActions } from 'ducks/inventoryEnquiry/stocking/purchaseOrders';
import { search } from 'api/inventoryEnquiry/stocking/purchaseOrders';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesPurchaseOrder',
  isLoading: purchaseOrderSelectors.isLoading(state),
  columnDefs: purchaseOrderSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: purchaseOrderSelectors.gridOptions(state),
  reqParams: (purchaseOrderSelectors.selected(state) || params().get('ProductId') || params().get('Warehouse'))
    &&
  {
    ProductId: (purchaseOrderSelectors.selected(state) && purchaseOrderSelectors.selected(state).ProductId) || params().get('ProductId'),
    Warehouse: (purchaseOrderSelectors.selected(state) && purchaseOrderSelectors.selected(state).Warehouse) || params().get('Warehouse')
  },
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: purchaseOrderActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
