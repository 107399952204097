import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MaterialTextField, InputLabel, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const classStyles = {
  labelAbove: {
    fontFamily: 'Roboto, sans-serif',
    color: '#777777',
    display: 'block',
    fontSize: 12,
    fontWeight: 400,
    padding: '0 0 8px 0'
  },
  noLabelProvided: {
    padding: '16px 0 0 0'
  },
  input: {
    boxSizing: 'border-box',
    padding: '8px 0 8px 8px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    color: '#000000',
    height: '34px',
    width: '100%',
    outline: 'none',
    '&:-webkit-autofill': {
      padding: '3px 8px',
      margin: '2px 2px',
      height: 30
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0 8px 0 0'
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0 8px 0 0'
    }
  },
  inputField: {
    boxShadow: 'inset 0px 0px 0px 1px #AAAAAA',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    border: 'none',
    lineHeight: '16px',
    boxSizing: 'border-box',
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    '-webkit-appearance': 'none',
  },
  requiredField: {
    boxShadow: 'inset 0px 0px 0px 1px #AAAAAA, inset 5px 0px #000000',
    paddingLeft: 5,
    '-webkit-appearance': 'none'
  },
  disabledInput: {
    color: '#555555',
    backgroundColor: '#EEEEEE',
    cursor: 'default',
  },
  emphasizedInput: {
    color: '#555555',
    backgroundColor: '#CCCCCC',
    fontWeight: 500
  },
  disabledInputColor: {
    color: '#555555',
  },
  focusedInput: {
    boxShadow: 'inset 0px 0px 0px 2px #347FFE',
    '-webkit-appearance': 'none'
  },
  errorBorder: {
    boxShadow: 'inset 0px 0px 0px 2px #FF0000',
    '-webkit-appearance': 'none'
  },
  requiredErroredField: {
    boxShadow: 'inset 0px 0px 0px 2px #FF0000, inset 5px 0px #FF0000',
    paddingLeft: 5,
    '-webkit-appearance': 'none'
  },
  requiredFocusedInput: {
    boxShadow: 'inset 0px 0px 0px 2px #347FFE, inset 5px 0px #347FFE',
    paddingLeft: 5,
    '-webkit-appearance': 'none'
  },
  focusFontColor: {
    color: '#347FFE'
  },
  errorFontColor: {
    color: '#FF0000'
  },
  errorMessage: {
    whiteSpace: 'noWrap',
  },
  errorLabel: {
    fontFamily: 'Roboto, sans-serif',
    color: '#FF0000',
    display: 'block',
    fontSize: 12,
    padding: '8px 0 8px'
  },
  sizeSmall: {
    minWidth: '155px',
    maxWidth: '155px',
    width: '100%',
  },
  sizeMedium: {
    minWidth: '245px',
    maxWidth: '245px',
    width: '100%',
  },
  sizeLarge: {
    minWidth: '300px',
    maxWidth: '100%',
    width: '100%',
  },
  numberField: {
    textAlign: 'right',
  },
  inputAdornmentRoot: {
    backgroundColor: '#f2f2f2',
    color: '#000000',
    borderLeft: '1px solid #AAAAAA',
    borderRadius: '0 4px 4px 0',
    height: '32px',
    maxHeight: '32px',
    marginRight: '2px',
    marginTop: '0',
    width: '45px',
  },
  inputAdornmentFocused: {
    height: '30px',
    width: '45px',
    marginTop: '2px',
    marginRight: '2px',
    borderRadius: '0 3px 3px 0',

  },
};

const isValueNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

const isValidEmail = (email = '') => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

class ExtendedDisplayField extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    action: PropTypes.object,
    size: PropTypes.string,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    isFocused: PropTypes.bool,
    fieldRef: PropTypes.func,
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    decimalSeparator: PropTypes.bool,
    decimalPlaces: PropTypes.number,
    emphasizedInput: PropTypes.bool
  };

  state = {
    isFocused: false,
    isErrored: false,
    showPassword: false,
    errorMessage: this.props.helperText || ''
  };

  componentDidUpdate(prevProps) {
    const { value, touched } = this.props;

    if (touched && !this.state.isErrored && !this.validate(value)) {
      this.handleValidation(this.props.value);
    }

    if (this.state.isErrored && this.validate(value)) {
      this.handleValidation(value);
    }
    if (this.props.decimalSeparator && !isValueNull(value)) {
      this.field.value = parseFloat(value).toFixed(this.props.decimalPlaces || 2);
    }
  }

  componentDidMount() {
    if (!isValueNull(this.props.value)) {
      this.handleValidation(this.props.value);
    }
    if (this.props.decimalSeparator) {
      this.field.value = parseFloat(this.props.defaultValue || 0).toFixed(this.props.decimalPlaces || 2);
    }
  }

  handleValidation = (value) => {
    if (!this.validate(value)) {
      this.updateErrorMessage(value);
    }
    else {
      this.setState({
        isErrored: false,
        errorMessage: ''
      })
    }
  }

  validate = (value) => {
    const { constraints, validation, required, type } = this.props;
    if (value && constraints && constraints.minLength !== undefined) {
      if (value.length < constraints.minLength) {
        return false;
      }
    }
    if (value && constraints && constraints.maxLength !== undefined) {
      if (value.length > constraints.maxLength) {
        return false;
      }
    }
    if (value && validation && !validation.controller(value)) {
      return false;
    }

    if (value && type === 'email' && !isValidEmail(value)) {
      return false;
    }

    if (required && isValueNull(value)) {
      return false;
    }

    return true;
  }

  updateErrorMessage = (value) => {
    const { constraints, validation, required, label, placeholder, type } = this.props;
    let errorMessage = this.state.errorMessage;

    if (value && constraints) {
      if (constraints.minLength !== undefined && value.length < constraints.minLength) {
        errorMessage = `${label || placeholder} must have at least ${constraints.minLength} characters`;
      }

      if (constraints.maxLength !== undefined && value.length > constraints.maxLength) {
        errorMessage = `${label || placeholder} must not exceed ${constraints.maxLength} characters`;
      }
    }

    if (value && validation && !validation.controller(value)) {
      errorMessage = this.props.validation.errorMessage;
    }

    if (value && type === 'email' && !isValidEmail(value)) {
      errorMessage = 'Entered value is not a valid Email!';
    }

    if (required && isValueNull(value)) {
      errorMessage = `${label || placeholder || 'This field'} is required...`;
    }

    this.props.onError && this.props.onError(errorMessage, this.props.name);
    this.setState({ errorMessage, isErrored: true })
  }

  onFocus = () => {
    this.setState({
      isFocused: true,
    })
    this.props.onFocus && this.props.onFocus();
  }

  onBlur = (event) => {
    if (this.props.decimalSeparator) {
      const { value } = event.target;
      let formatVal = parseFloat(value).toFixed(this.props.decimalPlaces || 2);
      event.target.value = formatVal;
    }
    this.setState({
      isFocused: false,
    })
    this.props.onBlur && this.props.onBlur(event);
    this.handleValidation(event.target.value);
  }

  handleActionClick = () => {
    if (this.props.type === 'password') {
      this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    } else {
      this.props.action.controller && this.props.action.controller(this.props.value);
    }
  };

  render() {
    const {
      classes,
      className,
      disabled,
      readOnly,
      label,
      name,
      type,
      placeholder,
      required,
      size,
      style,
      title,
      fieldRef,
      disableHelperText,
      onFocus,
      onBlur,
      iconStyles,
      touched,
      decimalSeparator,
      emphasized,
      adornment,
      ...rest
    } = this.props;

    const labelClasses = classNames(
      classes.labelAbove,
      this.state.isFocused && classes.focusFontColor,
      this.state.isErrored && classes.errorFontColor,
      (disabled || readOnly) && classes.blockedCursor,
      isValueNull(label) && classes.noLabelProvided,
    )

    const inputRoot = classNames(
      classes.inputField,
      required && classes.requiredField,
      (disabled || readOnly) && classes.disabledInput,
      emphasized && classes.emphasizedInput
    )

    const input = classNames(
      classes.input,
      this.props.type === 'number' && classes.numberField
    )

    const inputError = classNames(
      classes.errorBorder,
      required && classes.requiredErroredField
    )

    const errorMessageClasses = classNames(
      classes.errorMessage,
      classes.errorFontColor
    )

    return (
      <div
        className={
          className
        }
        title={title}
        style={Object.assign(
          { padding: '0px 0px 16px' },
          classStyles.sizeMedium,
          size && size.toUpperCase() === 'SMALL' ? classStyles.sizeSmall :
            size && size.toUpperCase() === 'MEDIUM' ? classStyles.sizeMedium :
              size && size.toUpperCase() === 'LARGE' ? classStyles.sizeLarge : null,
          style
        )}
      >
        <InputLabel
          classes={{
            root: labelClasses
          }}
        >{label}</InputLabel>
        <MaterialTextField
          name={name || 'ExtendedDisplayField'}
          type={(decimalSeparator) ? 'number' : (this.state.showPassword && type === 'password') ? 'text' : type || 'text'}
          inputRef={ref => { this.field = ref; return fieldRef && fieldRef(ref) }}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          placeholder={placeholder}
          style={{ width: '100%' }}
          helperText={!disableHelperText && this.state.isErrored && this.state.errorMessage}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: inputRoot,
              focused: this.state.isErrored ? inputError : required ? classes.requiredFocusedInput : classes.focusedInput,
              error: inputError,
              input: input,
              disabled: classes.disabledInputColor
            },
            endAdornment: adornment && <InputAdornment
              position="end"
              classes={{
                root: classNames({
                  [classes.inputAdornmentRoot]: true,
                  [classes.inputAdornmentFocused]: this.state.isFocused || this.state.isErrored,
                }),
              }}
            >
              <span
                style={{ margin: 'auto' }}
              >
                {adornment}
              </span>
            </InputAdornment>
          }
          }
          FormHelperTextProps={{
            classes: {
              error: errorMessageClasses
            }
          }}
          disabled={(disabled || readOnly)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          error={this.state.isErrored}
          {...rest}
        />
      </div >
    )
  }
}

export default withStyles(classStyles, { index: 1, muiName: 'MkExtendedDisplayField' })(ExtendedDisplayField);