const QuoteDetails = {
  'id': 'QuoteDetails',
  'layout': {
    'rows': 2,
    'columns': 3
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Postal address'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'placeholder': 'Customer Name',
            'name': 'PostalAddressName',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'label': 'Postal Address',
            'name': 'PostalAddress'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Delivery address'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'placeholder': 'Delivery Name',
            'name': 'DeliveryAddressName',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'label': 'Delivery Address',
            'name': 'DeliveryAddress'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Contact details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quote contact',
            'name': 'Contact',
            'size': 'large'
          }
        },
        {
          'id': 1,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Phone',
            'name': 'Phone',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 2,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Mobile',
            'name': 'Mobile',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 3,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Fax',
            'name': 'Fax',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 4,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Email',
            'name': 'Email',
            'size': 'large',
            'action': {
              'iconName': 'Mail',
            }
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quote type',
            'size': 'small',
            'name': 'QuoteType'
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sales rep',
            'name': 'SalesRepresentative',
            'size': 'small',
            'type': 'string',
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Entered by',
            'name': 'EnteredBy',
            'size': 'small'
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Territory',
            'name': 'SalesTerritory',
            'size': 'small'
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price category',
            'name': 'PriceCategory',
            'size': 'small'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sales/Service order',
            'name': 'SOrder',
            'size': 'small',
            'api': '/salesOrderEnquiry/lookup/action'
          }
        },
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Service call',
            'name': 'ServiceCall',
            'size': 'small',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Invoice instructions',
            'name': 'InvoiceInstructions1',
            'size': 'large'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': '',
            'name': 'InvoiceInstructions2',
            'size': 'large'
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax class',
            'name': 'TaxClass',
            'size': 'small'
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax exempt reference',
            'name': 'TaxExemptReference',
            'size': 'medium'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Shipping details'
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Freight code',
            'name': 'FreightCode',
            'placeholder': '',
            'size': 'small'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Send via',
            'name': 'SendVia',
            'size': 'medium'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Carrier',
            'name': 'Carrier',
            'size': 'large'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Freight account',
            'name': 'FreightAccount',
            'size': 'medium'
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Shipping instructions',
            'name': 'ShippingInstructions',
            'size': 'large',
          }
        }
      ]
    }
  ]
};
export default QuoteDetails;