import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from '../../../../ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from '../../../../ducks/ui';
import { actions as productActions, selectors as productSelectors } from '../../../../ducks/SearchLookUp/productLookup';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface ICustomerItem {
  'ProductIdDisplay': string;
  'ProductGroup': string;
  'ProductIdLabel': string;
  'ProductId': number;
  [name: string]: any;
}

const CustomerSearchLookUpDrawer = buildSearchLookUpDrawer<ICustomerItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp' | 'lastLinePosition'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ICustomerItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Products',
    searchPlaceholder: 'Search for products...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'ProductId',
        label: 'Product'
      },
      {
        filter: 'ProductGroup',
        label: 'Product Group'
      }
    ],
    records: productSelectors.all(state) || [],
    selectedRecord: productSelectors.selected(state),
    selectedSortFilter: 'ProductId',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: productSelectors.isLoading(state),
    nextPage: productSelectors.nextPage(state),
    prevPage: productSelectors.prevPage(state),
    pageSize: productSelectors.pageSize(state),
    totalPages: productSelectors.totalPages(state),
    loadingPrevPage: productSelectors.loadingPrevPage(state),
    loadingNextPage: productSelectors.loadingNextPage(state),
    searchParams: { Type: 'Lookup', CheckProductActive: true, CheckSupplierProduct: true, AlternateProductCheck: true },
    keyField: 'ProductId',
    fieldsToDisplay: ['ProductIdDisplay', 'ProductGroup', 'ProductIdLabel', 'MatchType'],
    lastLinePosition: 'right',
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('ProductId', selectedRecord.ProductId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const ProductId = matchParams.get('ProductId');
      if (ProductId) {
        return {
          id: { ProductId },
        };
      }

      return null;
    }
  });

const actionCreators: DispatchProps = {
  search: productActions.search,
  searchById: productActions.searchById,
  changeSelectedRecord: productActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: productActions.fetchNextPage,
  fetchPrevPage: productActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearchLookUpDrawer);
