const NotepadForm = {
  'id': 'Notepad',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'props': {
        'name': 'notepad',
        'fullWidth': true
      },
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 3
      },
      'children': [
        {
          'id': '1',
          'type': 'NOTEPAD',
          'props': {
            'formName': 'Notepad',
            'fetchApi': 'InventoryEnquiry/Product/<DocumentNumber>/Note',
            'updateApi': 'InventoryEnquiry/Product/<DocumentNumber>/Note',
            'label': 'Notepad',
            'name': 'noteData',
            'fullWidth': true,
            'style': {
              'height': '650px'
            }
          }
        }
      ]
    }
  ]
}
export default NotepadForm
