import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Charts.styles';

import PieChart, {
    Legend,
    Series,
    Tooltip,
    Label,
    Connector,
    Export,
} from 'devextreme-react/pie-chart';

import { IDoughnutChartProps } from './Charts.properties';

const DoughnutChart = (props: IDoughnutChartProps) => {
    const { classes, chartData, config } = props;

    return (
        <div className={classes.chartContainer}>
            <PieChart
                id='pie'
                type='doughnut'
                title={config.title}
                palette='Soft Pastel'
                size={{
                    height: 360
                }}
                dataSource={chartData}
                resolveLabelOverlapping='shift'
            >
                <Series argumentField={config.seriesArgumentField} valueField={config.seriesValueField}>
                    <Label visible={Boolean(config.customizeLabelText)} customizeText={config.customizeLabelText}>
                        <Connector visible={true} />
                    </Label>
                </Series>
                <Export enabled={true} />
                <Legend
                    horizontalAlignment='right'
                    verticalAlignment='top'
                />
                <Tooltip enabled={Boolean(config.customizeTooltip)} customizeTooltip={config.customizeTooltip} />
            </PieChart>
        </div>
    );
};

export default withStyles(styles, { index: 1 })(DoughnutChart);
