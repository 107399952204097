import React from 'react';
import {
    AddCircle as AddCircleIcon,
    Process as ProcessIcon,
    QuoteIcon
} from '@markinson/uicomponents-v2/SvgIcons/';
import PrintIcon from 'assets/Print';
import FastEntry from '../../common/FastEntry';
import { DataGrid } from 'devextreme-react';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Paper, withStyles } from '@material-ui/core';
import styles from './Lines.styles';
import { ILinesProperties } from './Lines.properties';
import { createDataSource } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { isNull } from 'utils/utils';
import DynamicDataGrid from 'components/common/DataGridDevEx/DynamicDataGrid';
import { columns } from './constants';
import { IFastEntryHandle } from 'components/common/FastEntry/FastEntry.properties';
import { useRetrievePurchaseOrdersLines } from 'api/purchaseOrders/purchaseOrders';

const PrintIconStyled = (props) => <PrintIcon {...props} style={{ width: 35, height: 35, margin: 3 }} />;

const Lines = (props: ILinesProperties) => {
    const { classes, selectedPurchaseOrder } = props;
    const { setActionBar } = React.useContext(ActionBarContext);
    const fetchLinesMutation = useRetrievePurchaseOrdersLines();

    const dataGridInnerRef = React.useRef<DataGrid>();
    const FastEntryRef = React.useRef<IFastEntryHandle>(null);

    const centerIcons = [
        {
            label: 'Print',
            Icon: PrintIconStyled,
            action: 'Print',
            disabled: false
        },
        {
            label: 'Load',
            Icon: QuoteIcon,
            action: 'Load',
            disabled: false
        }
    ];
    const rightIcons = [
        {
            label: 'New Order',
            Icon: AddCircleIcon,
            action: 'newOrder',
            disabled: isNull(selectedPurchaseOrder),
            iconStyle: !isNull(selectedPurchaseOrder) ? { fill: 'rgba(0, 0, 0, 1)' } : undefined,
        },
        {
            label: 'Process',
            Icon: ProcessIcon,
            action: 'Process',
            disabled: isNull(selectedPurchaseOrder),
            iconStyle: !isNull(selectedPurchaseOrder) ? { fill: 'rgba(109, 217, 0, 1)' } : undefined,
        }
    ];

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons,
                rightIcons
            });
        },
        [selectedPurchaseOrder]
    );

    const fetchLines = React.useCallback(
        async (BatchPage, BatchSize, Sort) => {
            if (selectedPurchaseOrder !== undefined && selectedPurchaseOrder !== null) {
                try {
                    const lines = await fetchLinesMutation.mutateAsync({
                        PurchaseOrder: selectedPurchaseOrder || 0,
                        urlQueryParams: {
                            BatchPage,
                            BatchSize,
                            Sort
                        }
                    });

                    return lines?.PurchaseOrdersLines;
                } catch (error) {
                    console.error('Error fetching lines:', error);

                    return null;
                }
            } else {
                return null;
            }
        },
        [selectedPurchaseOrder]
    );

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons,
                rightIcons
            });
        },
        []
    );

    const linesDataSource = React.useMemo(
        () => createDataSource(
            'LineNumber',
            { fetch: fetchLines },
        ),
        [fetchLines]
    );

    return (
        <div className={classes.parentContainer}>
            <Paper className={classes.fastEntryContainer}>
                <FastEntry
                    innerRef={FastEntryRef}
                    enableProductValidation={true}
                    disabled={true}

                />
            </Paper>
            <Paper>
                <DynamicDataGrid
                    dataGridClassName={classes.grid}
                    columns={columns}
                    allowHeaderFilting={false}
                    allowUpdating={true}
                    detailOnClick={() => {
                        console.log('details clicked');
                    }}
                    gridProps={{
                        dataSource: linesDataSource,
                        dataGridRef: dataGridInnerRef,
                        sorting: { mode: 'single' },
                        disableDrillDown: false,
                        disableEditing: false,
                        disableDelete: false,
                    }}
                />
            </Paper>
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Lines)));
