import { connect } from 'react-redux';
import InvoicePayments from './InvoicePayments';
import { selectors as paymentSelectors } from 'ducks/salesInvoiceEnquiry/payment';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedPaymentLine: paymentSelectors.selected(state),
  paymentSummary: paymentSelectors.paymentSummary(state),
  loadingPaymentSummary: paymentSelectors.loadingSummary(state),
  paymentDetails: paymentSelectors.paymentDetails(state),
});
export default connect(mapStateToProps, {})(InvoicePayments);
