import FormViewModal from 'components/common/Modals/FormViewModal';
import React from 'react';
import { IDespatchDetailsDialogProperties } from './DespatchDetailsDialog.properties';
import DespatchDetailsSchema from './DespatchDetailsDialog.form';

const DespatchDetailsDialog = (props: IDespatchDetailsDialogProperties) => {
    const { open, loading, values, data, onCancel, onOk } = props;

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Despatch Details'
                dialogActionsButtons={true}
                dialogActionsShadow={false}
                formProps={{
                    formSchema: DespatchDetailsSchema,
                    initialValues: data?.inlineObject,
                    valuesSchema: data?.schema,
                    style: { width: '500px' }
                }}
                actions={[
                    {
                        title: 'Ok',
                        listener: () => {
                            if (onOk) {
                                onOk(values);
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default DespatchDetailsDialog;
