import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Grid as GridLayout } from '@material-ui/core';
import UserNotifications from 'components/common/UserNotifications';
import Scrollbars from 'react-custom-scrollbars';
import SearchLookUpDrawer from 'components/common/SearchLookUpDrawer';
import Grid from '@material-ui/core/Grid';

import styles from './FormView.scss';
import DynamicFormView from './DynamicFormView';
import ValidateCustomer from './Validations/ValidateCustomer';
import validateDiary from './Validations/ValidateDiary';
class FormView extends Component {

  renderBrowseLookup = () => {
    const { browseLookUpOpen, browseLookUpComponent } = this.props;
    return ((browseLookUpOpen && browseLookUpComponent) ? <GridLayout item style={{ flex: '0 0 300px' }}>
      <SearchLookUpDrawer {...browseLookUpComponent} />
    </GridLayout> : null)
  }

  getLayoutSchema(formSchema) {
    const { rows, columns } = formSchema.layout;
    const { fields } = formSchema;
    let layout = [];
    for (let i = 1; i <= rows; i++) {
      let rowItems = [];
      for (let j = 1; j <= columns; j++) {
        const colItems = [fields.filter((field) => {
          return field.position.row === i && field.position.col === j;
        })]
        rowItems.push(colItems)
      }
      layout.push(rowItems);
    }
    return layout;
  }

  validate = (values) => {
    const { browseLookUpComponent, validate } = this.props;
    const allowedValidation = ['CustomerDetails', 'CustomerSetting', 'CustomerFinancial', 'CustomerPricing'].includes(browseLookUpComponent);
    let errors = {};

    if (allowedValidation) {
      errors = { ...ValidateCustomer(values) }
    }
    if (browseLookUpComponent === 'DiaryDetails') {
      errors = { ...validateDiary(values) }
    }
    if (validate) {
      errors = { ...validate(values) };
    }

    return errors;
  }

  render() {
    const {
      stopInitialization, initialValues, schema, formName, includeTabsHeight,
      isLoading, operationMode, valuesSchema, customerId, entity, productId, widgetTypeId,
      fieldExtensions, style, summaryTableRenderer, worksaleId, hidden, supplierId
    } = this.props;
    const layout = this.getLayoutSchema(schema);

    return !hidden ? (
      <div className={includeTabsHeight ? styles.wrapper : styles.wrapperNoTabs} style={style}>
        <GridLayout container spacing={0} alignContent="flex-start" className={styles.GridContainer}>
          {this.renderBrowseLookup()}
          <GridLayout container spacing={0} style={{ flexGrow: 1, flexBasis: 0 }} >
            <Scrollbars>
              <Grid container spacing={0} direction="row" style={{ height: '100%' }}>
                <Grid item xs>
                  {isLoading ? <UserNotifications isLoading={isLoading} /> :
                    <DynamicFormView
                      formName={formName}
                      validate={this.validate}
                      customerId={customerId}
                      worksaleId={worksaleId}
                      supplierId={supplierId}
                      productId={productId}
                      entity={entity}
                      widgetTypeId={widgetTypeId}
                      operationMode={operationMode}
                      layout={layout}
                      initialValues={initialValues || null}
                      valuesSchema={valuesSchema}
                      enableReinitialize={!stopInitialization}
                      onBlur={this.props.onBlur}
                      fieldExtensions={fieldExtensions}
                    />}
                </Grid>
                {summaryTableRenderer && <Grid item style={{ width: '330px' }}>
                  {summaryTableRenderer()}
                </Grid>}
              </Grid>
            </Scrollbars>
          </GridLayout>
        </GridLayout>
      </div>
    ) : null;
  }
}

FormView.propTypes = {
  schema: PropTypes.object.isRequired
};


export default FormView;