import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify, fetchPut } from '../utils';

export const fetchSlipLines = async (query) => {
  const { DespatchId, filters } = query;

  return fetchPost(
    `/PickSlipTransaction/Despatch/${DespatchId}/Lines`,
    filters
    && { ...filters }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLines) {
          return {
            records: result.PickSlipLines.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchLots = async (query) => {
  const { DespatchId, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchGet(
    `/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/Lots`,
    {
      BatchPage: Page,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
      BatchSize
    }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLineLots) {
          return {
            records: result.PickSlipLineLots.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const saveNewPickSlipLot = async (query) => {
  const { DespatchId, LineNumber, data } = query;

  return fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/SaveNewLot`, data, null, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineLotDetails),
      schema: result.PickSlipLineLotDetails
    }));
};

export const fetchPickSlipLot = async (query) => {
  const { DespatchId, LineNumber, LotNumber } = query;

  return fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/Lot/${LotNumber}`)
    .then((result) => ({
      values: objectify(result.PickSlipLineLotDetails),
      schema: result.PickSlipLineLotDetails
    }));
};

export const updatePickSlipLot = async (query) => {
  const { DespatchId, LineNumber, LotNumber, data } = query;

  return fetchPut(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/Lot/${LotNumber}`, data, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineLotDetails),
      schema: result.PickSlipLineLotDetails
    }));
};

export const fetchSerials = async (query) => {
  const { DespatchId, LineNumber, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(
    `/PickSlipEnquiry/Despatch/${DespatchId}/Line/${LineNumber}/Serials`,
    filters
    && {
      ...filters
    },
    {
      BatchPage: Page,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`,
      BatchSize
    }
  )
    .then((result) => {
      if (result) {
        if (result.PickSlipLineSerials) {
          return {
            records: result.PickSlipLineSerials.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const deleteSlipLine = async (query) => {
  const { DespatchId, LineNumber } = query;

  return fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/Delete`, {})
    .then((result) => {
      if (result) {
        if (result.PickSlipLines) {
          return {
            records: result.PickSlipLines.map((val) =>
              ({ ...objectify(val) })),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        } else {
          return result;
        }
      }
    });
};

export const fetchSlipLineDetails = async (query) => {
  const { DespatchId, LineNumber } = query;
  const apiCall = fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineDetails),
      schema: result.PickSlipLineDetails
    }));

  return apiCall;
};

export const updateSlipLineDetails = async (query) => {
  const { DespatchId, LineNumber, data } = query;
  const apiCall = fetchPut(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}`, data, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineDetails),
      schema: result.PickSlipLineDetails
    }));

  return apiCall;
};

export const cancelSlipLineDetails = async (query) => {
  const { DespatchId, LineNumber } = query;
  const apiCall = fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/CancelLineDetails`, null, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineDetails),
      schema: result.PickSlipLineDetails
    }));

  return apiCall;
};

export const fetchSlipLineDetailsSummary = async (query) => {
  const { DespatchId, LineNumber } = query;
  const apiCall = fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/Summary`, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.PickSlipLineSummary),
      schema: result.PickSlipLineSummary
    }));

  return apiCall;
};

export const createProductCharge = async () => {
  const apiCall = fetchPost('/PickSlipTransaction/CreateCurrentProductCharge', null, {}, 'Inline')
    .then((result) => ({
      values: objectify(result.ProductCharge),
      schema: result.ProductCharge
    }));

  return apiCall;
};

export const updateProductCharge = async (query) => {
  const { DespatchId } = query;
  const apiCall = fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/UpdateCurrentProductCharge`, query, null, 'Inline')
    .then((result) => ({
      values: objectify(result.ProductCharge),
      schema: result.ProductCharge
    }));

  return apiCall;
};

export const saveLot = async (query) => {
  const { DespatchId, LineNumber, data } = query;

  return fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/SaveNewLot`, data)
    .then((result) => {
      if (result) {
        if (result.PickSlipLineLotDetails) {
          return result.PickSlipLineLotDetails;
        } else {
          return result;
        }
      }
    });
};
