import { IState } from './ExtractMaintenance.reducer';

export interface IActions {
    [ExtractMaintenanceActionTypes.setTemplateId]?: IState['TemplateId'];
    [ExtractMaintenanceActionTypes.setFocusedArea]?: IState['focusedArea'];
    [ExtractMaintenanceActionTypes.setIsFocusedAreaNameSelected]?: IState['isFocusedAreaNameSelected'];
    [ExtractMaintenanceActionTypes.setIsFieldsInEdit]?: IState['isFieldsInEdit'];
    [ExtractMaintenanceActionTypes.setFocusedFieldNames]?: IState['focusedFieldNames'];
    [ExtractMaintenanceActionTypes.setFocusedFieldNamesempty]?: IState['resetfocusedFieldNames'];
}

export enum ExtractMaintenanceActionTypes {
    setTemplateId = 'setTemplateId',
    setFocusedArea = 'setFocusedArea',
    setIsFocusedAreaNameSelected= 'setIsFocusedAreaNameSelected',
    setIsFieldsInEdit= 'setIsFieldsInEdit',
    setFocusedFieldNames= 'setFocusedFieldNames',
    setFocusedFieldNamesempty= 'setFocusedFieldNamesempty',
}
