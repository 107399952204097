import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import PriceOverrideSummaryTableSchema from './PriceOverrideAuthority.summaryTable.schema';
import { IPriceOverrideAuthorityDialogProperties, PriceOverrideScreenType, PriceOverrideScreenTitle } from './PriceOverrideAuthorityDialog.properties';
import SummaryTable from 'components/common/SummaryTable';
import { PriceOverrideFormSchemas } from './PriceOverrideAuthorityDialog.constants';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';

const PriceOverrideAuthorityDialog = (props: IPriceOverrideAuthorityDialogProperties) => {

  const { open, loading = false, type = PriceOverrideScreenType.UserAuthAndReasonScreen, apiType, data, values, resetForm, onOk, onCancel } = props;

  const overrideReasonRef = React.useRef<IBasicLookupFieldHandle>();
  const formSchema = PriceOverrideFormSchemas[type];
  const overrideReasonRefReady = (overrideReasonRef && overrideReasonRef.current) ? true : false;

  React.useEffect(
    () => {
      if (open && overrideReasonRef.current) {
        overrideReasonRef.current.focus();
      }
    },
    [open, overrideReasonRefReady]
  );

  const getActions = () => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: () => {
          if (onOk) {
            onOk(values, apiType);
          }
        }
      },
      {
        title: 'Cancel',
        iconName: 'Cancel',
        isDefault: true,
        listener: () => {
          resetForm();
          if (onCancel) {
            onCancel();
          }
        },
      },
    ];
  };

  return (
    <div>
      {formSchema ? <FormViewModal
        open={open}
        loading={loading}
        title={PriceOverrideScreenTitle[type]}
        formProps={{
          formSchema: formSchema,
          initialValues: data,
          style: { height: '440px' },
          fieldExtensions: {
            OverrideReason: {
              fieldRef: overrideReasonRef
            }
          },
          summaryTableRenderer: () => PriceOverrideSummaryTableSchema ?
            (<SummaryTable loadingPriceOverrideSummary={false}
              priceOverrideSummary={data}
              data={
                PriceOverrideSummaryTableSchema
              }
            />) : null
        }
        }
        actions={getActions()}
        dialogActionsButtons={true}
      /> : null}
    </div>
  );
};

export default PriceOverrideAuthorityDialog;
