const OrderDetails = {
  'id': 'OrderDetails',
  'layout': {
    'rows': 3,
    'columns': 3
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Supplier contact details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Contact',
            'name': 'Contact',
            'placeholder': '',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Phone',
            'name': 'ContactPhone',
            'placeholder': '',
            'required': false,
            'size': 'medium',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 2,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Mobile',
            'name': 'ContactMobile',
            'placeholder': '',
            'required': false,
            'size': 'medium',
            'action': {
              'iconName': 'Phone',
            }
          }
        },
        {
          'id': 3,
          'type': 'ACTION_FIELD',
          'visible': true,
          'props': {
            'label': 'Email',
            'name': 'ContactEmail',
            'placeholder': '',
            'required': false,
            'size': 'large',
            'action': {
              'iconName': 'Mail',
            }
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Supplier address',
        'name': 'SupplierAddress',
      },
      'children': [
        {
          'id': 0,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'label': 'Supplier address',
            'name': 'SupplierAddress',
            'type': 'extended',
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Order totals',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Exchange rate',
            'name': 'ExchangeRateDisplay',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Local Currency',
            'name': 'LocalCurrency',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Local total',
            'name': 'LocalTotalDisplay',
            'size': 'medium',
            'required': false,
            'disabled': true,
            'type': 'number'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Local outstanding',
            'name': 'LocalOutstandingDisplay',
            'size': 'medium',
            'required': false,
            'disabled': true,
            'type': 'number'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Delivery site details'
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Warehouse',
            'name': 'Warehouse',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Site',
            'name': 'SiteCode',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Site contact',
            'name': 'SiteContact',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Delivery address'
      },
      'children': [
        {
          'id': 0,
          'type': 'ADDRESS_BLOCK_EX',
          'visible': true,
          'props': {
            'name': 'DeliveryAddress',
            'type': 'extended',
            'disabled': true,
            'hideCountry': true
          }

        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Shipping details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'freight code',
            'name': 'FreightCode',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Freight type',
            'name': 'FreightType',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Carrier',
            'name': 'Carrier',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Shipping',
            'name': 'Shipping',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Picking',
            'name': 'Packing',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 3,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Responsible person',
            'name': 'ResponsiblePersonLabel',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax class',
            'name': 'TaxClass',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quote reference',
            'name': 'QuoteReference',
            'size': 'medium',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'SUBHEADER',
          'props': {
            'value': 'User defined categories',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 1',
            'name': 'UserDefinedCat1',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 2',
            'name': 'UserDefinedCat2',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 3',
            'name': 'UserDefinedCat3',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 7,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 4',
            'name': 'UserDefinedCat4',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 8,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 5',
            'name': 'UserDefinedCat5',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 9,
          'type': 'TEXT_FIELD',
          'visible': true,
          'style': {
            'width': '50%',
            'display': 'inline-block'
          },
          'props': {
            'label': 'User defined 6',
            'name': 'UserDefinedCat6',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        },
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 3,
        'col': 2,
        'colspan': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_AREA',
          'visible': true,
          'props': {
            'label': 'Comments',
            'name': 'Comments',
            'fullWidth': true,
            'disabled': true,
            'size': 'small',
            'style': {
              'height': '500px'
            }
          }
        }
      ]
    }
  ]
}
export default OrderDetails;