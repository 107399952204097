const LineDetails = {
  'id': 'LineDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product description',
            'placeholder': '',
            'size': 'large',
            'required': false,
            'name': 'ProductDescription'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Customer product',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'CustomerProductCode'
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Ordered',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'OrderedQuantity',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Supplied',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'SuppliedQuantity',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'In picking',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'PickingQuantityDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Kitting',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'KittingQuantityDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Backordered',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'BackorderQuantityDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 7,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'PriceDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 8,
          'type': 'EXTENDED_DISPLAY_FIELD',
          'visible': true,
          'props': {
            'label': 'Discount',
            'placeholder': '',
            'size': 'small',
            'name': 'Discount',
            'type': 'number',
            'decimalSeparator': true,
            'adornment': '%',
          }
        },
        {
          'id': 9,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax included price',
            'required': false,
            'name': 'TaxIncludedPrice',
          }
        },
        {
          'id': 10,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Special price',
            'required': false,
            'name': 'SpecialPrice',
          }
        },
        {
          'id': 11,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Fixed price',
            'required': false,
            'name': 'FixedPrice',
            'defaultValue': false
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reserved',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'ReservedQuantityDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Committed',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'CommittedQuantityDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted currency',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'name': 'QuotedCurrency'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted exchange rate',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'name': 'QuotedExhangeRateDisplay',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_AREA',
          'props': {
            'name': 'OrderLineComment',
            'label': 'Order line comment',
            'fullWidth': true,
            'style': {
              'height': '200px'
            }
          }
        }
      ]
    }
  ]
}
export default LineDetails;