import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, LinearProgress, Button } from '@material-ui/core';
import { pathOr } from 'utils/utils';

const classStyles = createStyles({
  dialog: {
    width: 500,
    height: 550,
    maxWidth: 1500,
    padding: '20px 0 0 0'
  },
  dialogActionBar: {
    backgroundColor: '#dddddd',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
    borderRadius: 0.7,
    height: 52,
    margin: '4px 0 0 0'
  },
  dialogContent: {
    'height': '70%',
    'padding': 0,
    'position': 'relative',
    '&:first-child': {
      paddingTop: 0
    }
  },
  cancelBtn: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  image: {
    objectFit: 'cover',
    maxWidth: '400px',
    minWidth: '200px',
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
    margin: '10px'
  },
  description: {
    margin: '10px',
    wordBreak: 'break-all'
  },
  linearProgress: {
    bottom: '-4px',
    width: '100%'
  }
});

export interface IImageModalProperties extends StyledComponentProps {
  open: boolean;
  loading: boolean;
  image: string;
  data: any;
  onClose?(): void;
}

export interface IImageModalState {
  searchText: string;
}

class ImageModalComponent extends React.PureComponent<IImageModalProperties, IImageModalState> {

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  render(): React.ReactNode {
    const { classes, open, loading, image, data } = this.props;
    const fileName = pathOr(null, ['FileName', 'Value'], data);
    const fileSize = pathOr(null, ['FileSize', 'Value'], data);
    const description = pathOr(null, ['Description', 'Value'], data);

    return (
      <Dialog
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby='confirmation-dialog-title'
        open={open}
        onClose={this.handleClose}
      >

        <DialogContent className={classes.dialogContent}>
          <div className={classes.imageDiv}>
            <img src={image} className={classes.image} />
          </div>
          {(fileName || fileSize) && <div className={classes.fileName}>{`${fileName} (${fileSize})`}</div>}
          {description && <div className={classes.description}>{description}</div>}
        </DialogContent>
        <div>
          {loading && <LinearProgress className={classes.linearProgress} color='secondary' />}
          <DialogActions className={classes.dialogActionBar}>
            <Button onClick={this.handleClose} className={classes.cancelBtn} color='primary'>
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(classStyles, { index: 1 })(ImageModalComponent);
