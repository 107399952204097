import React from 'react';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IChecklistDetailModal } from '../ChecklistsGrid.properties';

const inlineStyles = {
  mainDiv: {
    width: '550px',
    height: '350px',
    padding: '15px'
  },
  textArea: {
    height: '150px',
  },
  outerDiv: {
    display: 'flex',
  },
  innerTADiv: {
    width: '70%',
  },
  innerTFDiv: {
    padding: '16px 10px',
  }
};

const ChecklistDetailView = (props: IChecklistDetailModal): JSX.Element => {

  const { checklistItem, open, onChecklistCancel: onCancel, onChecklistSave: onSave } = props;
  const { Instructions } = checklistItem;

  React.useEffect(
    () => {
      setComment(checklistItem.Comment);
      createStatus();
    },
    [checklistItem]
  );

  const [comment, setComment] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>('');

  const createStatus = () => {
    const { DataType, AlphaValue, LogValueDisplay, NumValue } = checklistItem as any;
    switch (DataType) {
      case 'L':
      case 'P':
      case 'T':
        setStatus(AlphaValue);

        return;
      case 'Y':
        setStatus(LogValueDisplay);

        return;
      case 'N':
        setStatus(NumValue);

        return;
      default:
        setStatus('');
    }
  };

  const getModalContent = () => {

    return <div
      style={inlineStyles.mainDiv}
    >
      <div style={inlineStyles.outerDiv}>
        <div style={inlineStyles.innerTADiv}>
          <TextArea
            label={'Instruction'}
            disabled={true}
            value={Instructions}
            style={inlineStyles.textArea}
          />
        </div>
        <div style={inlineStyles.innerTFDiv}>
          <TextField
            label={'Status'}
            value={status}
            size={'small'}
            disabled={true}
          />
        </div >
      </div>
      <TextArea
        label={'Comment'}
        value={comment}
        style={inlineStyles.textArea}
        onChange={(e) => { setComment(e.target.value); }}
      />

    </div>;
  };

  const getModalActions = () => {

    return [
      {
        iconName: 'Cancel',
        title: 'Cancel',
        listener: () => {
          setComment(checklistItem.Comment);
          onCancel(false);
        }
      },
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: () => {
          onSave(false, comment, checklistItem);
        }
      }];
  };

  return (

    <FormViewModal
      open={open}
      loading={false}
      title={'Checklist Details'}
      modalContent={getModalContent()}
      actions={getModalActions()}
    />
  );
};

export default ChecklistDetailView;
