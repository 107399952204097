import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
    createActions, asyncInitialState,
    IDataAction,
    IExtendedState
} from '../utils';

export interface IUserFieldsData {
    selected: any;
    gridOptions: IDataGridOptions;
    Columns: IColDef[];
}

export interface IUserFieldsState extends IExtendedState<IUserFieldsData> {
}

export const { types, actions } = createActions(
    {
        setSelected: (row) => ({ row }),
        asyncs: {

        }
    },
    'customerUserFields');

const initialState = asyncInitialState<IUserFieldsData>({
    selected: null,
    gridOptions: {
        doubleClickActionTab: '',
        suppressEditDeleteInContextMenu: true,
        rowModelType: 'serverSide',
        cacheBlockSize: 10,
        maxBlocksInCache: 5,
        enableMasterDetailModel: true,
        detailCellRenderer: 'CommentAreaRenderer',
        detailCellRendererParams: {
            getDetailRowData: function (params: { data?: { Details?: string } }): any {
                return { commentData: params.data.Details, Label: 'Value' };
            }
        }
    },
    Columns: [
        { headerName: 'Field name', field: 'FieldName', minWidth: 200, suppressSorting: true },
        {
            headerName: 'Details', field: 'Details',
            valueGetter: (row: { data: { Details: string } }) => {
                if (row.data && row.data.Details) {
                    return row.data.Details.split('.')[0];
                } else return null;
            },
            minWidth: 500, suppressSorting: true
        },
    ]
});

export default (state: IUserFieldsState = initialState, action: IDataAction): IUserFieldsState => {
    switch (action.type) {
        case types.setSelected:
            const selected = action.data.row;

            return {
                ...state,
                data: {
                    ...state.data,
                    selected: selected
                }
            };
        default:
            return state;
    }
};

export const selectors = ({
    selected: (state: { customerEnquiry: { userFields: IUserFieldsState } }) => state.customerEnquiry.userFields.data.selected,
    gridOptions: (state: { customerEnquiry: { userFields: IUserFieldsState } }) => state.customerEnquiry.userFields.data.gridOptions,
    columns: (state: { customerEnquiry: { userFields: IUserFieldsState } }) => state.customerEnquiry.userFields.data.Columns,
});
