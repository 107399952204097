import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotePad from './NotePad';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as quoteSelectors, actions as quoteActions } from 'ducks/customerQuoteEnquiry/quote';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  quoteDetails: quoteSelectors.quoteDetails(state),
  quoteDetailsSchema: quoteSelectors.quoteDetailsSchema(state),
  quoteSummary: quoteSelectors.quoteSummary(state),
  quoteSummarySchema: quoteSelectors.quoteSummarySchema(state),
  selectedQuote: quoteSelectors.selected(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchQuoteLines: quoteActions.fetchQuoteLines,
    fetchQuoteLineDetail: quoteActions.fetchQuoteLineDetail,
    fetchQuoteLineSummary: quoteActions.fetchQuoteLineSummary,
    fetchReservationDetails: quoteActions.fetchReservationDetails,
    fetchQuoteSummaryPanel: quoteActions.fetchQuoteSummaryPanel
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotePad);
