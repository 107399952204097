import React, { Component } from 'react';
import { DATETIME_FORMAT } from '@markinson/mk.mpv4.schedulercomponent';
import GeneralAcivitySchema from 'utils/data/forms/service_activity_scheduler/GeneralActivity';
import FormViewModal from 'components/common/Modals/FormViewModal';
import moment from 'moment';
import { IGeneralActivityProps } from 'components/ServiceActivityScheduling/interfaces';

class GeneralAcivityModal extends Component<IGeneralActivityProps> {

    generalActivityActions = () => {

        return [
            {
                iconName: 'Cancel',
                title: 'Cancel',
                listener: () => {
                    this.props.handleClose();
                }
            }
            ,
            {
                title: 'Ok',
                iconName: 'CheckCircle',
                listener: () => {
                    this.schedule();
                }
            }];
    }

    schedule = () => {
        const { schedule, formValues, SalesEntity, data = {}, handleClose } = this.props;

        if (this.Validated()) {
            schedule({
                ...formValues,
                ResourceId: data.resource && data.resource.id,
                ResourceType: data.resource && data.resource.ResourceType,
                SalesEntity: SalesEntity
            });

            handleClose();
        }
    }
    Validated = () => {
        const { formSyncErrors, touchFormFields } = this.props;
        const ErrorFields = Object.keys(formSyncErrors);
        if (ErrorFields.includes('Title') ||
            ErrorFields.includes('Description') ||
            ErrorFields.includes('StartDate') ||
            ErrorFields.includes('EndDate') ||
            ErrorFields.includes('StartTime') ||
            ErrorFields.includes('EndTime')) {
            touchFormFields('Title', 'Description', 'StartDate', 'EndDate', 'StartTime', 'EndTime');

            return false;
        }

        return true;
    }

    getInitialValues = () => {
        const { data = {} } = this.props;

        return {
            StartTime: moment(data.start, DATETIME_FORMAT).format('hh:mm a'),
            EndTime: moment(data.end, DATETIME_FORMAT).format('hh:mm a'),
            StartDate: moment(data.start, DATETIME_FORMAT).format('DD/MM/YYYY'),
            EndDate: moment(data.end, DATETIME_FORMAT).format('DD/MM/YYYY'),
            AllDayEventToggle: true,
        };
    }

    render(): React.ReactNode {

        const { open, formValues = {} } = this.props;

        return (
            <FormViewModal
                open={open}
                loading={false}
                title={'Schedule General Activity'}
                formProps={{
                    style: {
                        height: '360px',
                        minWidth: '390px'
                    },
                    formSchema: GeneralAcivitySchema,
                    initialValues: this.getInitialValues(),
                    valuesSchema: {},
                    fieldExtensions: ({
                        StartTime: {
                            disabled: formValues.AllDayEventToggle
                        },
                        EndTime: {
                            disabled: formValues.AllDayEventToggle
                        }
                    })
                }}
                actions={this.generalActivityActions()}
            />
        );
    }
}

export default GeneralAcivityModal;
