import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils';

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clear: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      fetch: (Data) => Data,
    }
  },
  'quotes');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    'EntityView': '',
    'Entity': '',
    'FinalizationOption': 'Unfinalised',
    'FromDate': '',
    'ToDate': '',
  },
  filterRow: {
    formName: 'CustomerQuotesFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item, index) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 3,
        type: 'SELECT_FIELD',
        props: {
          label: 'Show',
          name: 'FinalizationOption',
          required: false,
          options: [
            {
              value: 'Unfinalised',
              label: 'Unfinalised only'
            },
            {
              value: 'Finalised',
              label: 'Finalised only'
            },
          ]
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'Entered range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
    ]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Entity', field: 'Entity', minWidth: 100, suppressSorting: true },
    {
      headerName: 'Quote',
      field: 'Quote',
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Quote) {
          return {
            Quote: row.data.Quote,
            FieldValue: row.data.Quote
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-quote-enquiry'
      }
    },
    {
      headerName: 'Entered', field: 'Entered', type: 'numericColumn', minWidth: 120,
      valueGetter: (row) => {
        if (row.data && row.data.EnteredDate) {
          return row.data.EnteredDate;
        } return null;
      }
    },
    {
      headerName: 'Expiry', field: 'ExpiryDate', type: 'numericColumn', minWidth: 120, suppressSorting: true
    },
    {
      headerName: 'Status', field: 'Status', minWidth: 120, suppressSorting: true
    },
    {
      headerName: 'Delivery name', field: 'DeliveryName', minWidth: 250, suppressSorting: true
    },
    {
      headerName: 'Description', field: 'Description', minWidth: 200, suppressSorting: true
    },
    {
      headerName: 'Sales rep', field: 'SalesRepresentative', minWidth: 120, suppressSorting: true
    },
    {
      headerName: 'Quote type', field: 'QuoteType', minWidth: 150, suppressSorting: true
    },
    {
      headerName: 'Quote total', field: 'QuoteTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true
    },
    {
      headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true
    },
    {
      headerName: 'Local total', field: 'LocalTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true
    }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      };

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          Data: linesData
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.quotes,
  {
    // Data: data => data.Data,
    selected: (data) => data.selected,
    Actions: (data) => data.Actions,
  }
);

const syncSelector = {
  Data: (state) => state.customerEnquiry.quotes.data.Data,
  isLoading: (state) => state.customerEnquiry.quotes.loading,
  gridOptions: (state) => state.customerEnquiry.quotes.data.gridOptions,
  Columns: (state) => state.customerEnquiry.quotes.data.Columns,
  Actions: (state) => state.customerEnquiry.quotes.data.Actions,
  filterRow: (state) => state.customerEnquiry.quotes.data.filterRow,
  selectedFilters: (state) => state.customerEnquiry.quotes.data.selectedFilters
};

export const selectors = { ...asyncSelector, ...syncSelector };