import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as purchaseOrderSelectors, actions as purchaseOrderActions } from 'ducks/salesOrderEnquiry/committedPurchaseOrder';
import { fetch } from 'api/salesOrderEnquiry/committedPurchaseOrder';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';

export const mapStateToProps = state => {
  const params = new URLSearchParams(location.search);

  return ({
    gridName: 'CommittedPurchaseOrder',
    columnDefs: purchaseOrderSelectors.Columns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
    actions: purchaseOrderSelectors.Actions(state),
    gridOptions: purchaseOrderSelectors.gridOptions(state),
    isLoading: purchaseOrderSelectors.isLoading(state),
    reqParams: orderSelectors.selected(state) || params.get('SalesOrder') &&
    {
      SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
    },
    apiMethod: fetch
  });
}

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: purchaseOrderActions.setSelected
}
