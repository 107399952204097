import React, { useMemo } from 'react';
import BasicLookupField, { IBasicLookupFieldProps } from '@markinson/uicomponents-v2/BasicLookupField';
import { fetchLookupData, fetchLookupOption, fetchCustomerLookupOption, fetchEntityLookupOption, fetchProductLookupOption, fetchWorkSaleLookupOption, fetchPurchaseOrderLookupOption } from 'api/basicLookupField/basicLookupApi';
import { IBasicLookupFieldHandle, ILookupFieldColumn } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';
import { CUSTOMER_LOOKUP_COLUMNS, CUSTOMER_LOOKUP_NAME, SEARCH_SCREEN_DIALOG_STYLE } from './constants';

const FIXED_DEFAULT_TYPE_BATCH_SIZE = 100;
const DEFAULT_BATCH_SIZE = 10;

const lookupSearchApi = (lookupName: string, params: any) => (searchText: string | undefined, batchSize: number, batchPage: number, exactMatch?: boolean) => {
  return fetchLookupData({ lookupName, SearchText: searchText, BatchSize: batchSize, BatchPage: batchPage, CheckExactMatch: exactMatch, ...params ? params : {} });
};
const findOption = (lookupName: string, scopeOptions: IScopeOptions, params: any) => (value: string | undefined) => {
  if (scopeOptions.isCustomerScoped && params.CustomerId) {
    return fetchCustomerLookupOption({ lookupName, value, CustomerId: params.CustomerId || 0 });
  } else if (scopeOptions.isWorksaleScoped && params.WorksaleID) {
    return fetchWorkSaleLookupOption({ lookupName, value, WorksaleId: params.WorksaleID || '' });
  } else if (scopeOptions.isProductScoped && params.ProductId) {
    return fetchProductLookupOption({ lookupName, value, ProductId: params.ProductId || '' });
  } else if (scopeOptions.isEntityScoped && params.Entity) {
    return fetchEntityLookupOption({ lookupName, value, SalesEntity: params.Entity || '' });
  } else if (scopeOptions.isSupplierScoped && params.Supplier) {
    return fetchPurchaseOrderLookupOption({ lookupName, value, Supplier: params.Supplier || '' });
  } else {
    return fetchLookupOption({ lookupName, value });
  }
};

interface ILookupFieldColumnEx extends ILookupFieldColumn {
  hideInV2?: boolean;
  hideInV3?: boolean;
}
interface IScopeOptions {
  isCustomerScoped?: boolean;
  isWorksaleScoped?: boolean;
  isProductScoped?: boolean;
  isEntityScoped?: boolean;
  isWidgetTypeScoped?: boolean;
  isSupplierScoped?: boolean;
}
export interface IBasicLookupActionFieldProps extends Omit<IBasicLookupFieldProps, 'valueField' | 'displayField' | 'descriptionField' | 'column1Field' | 'column2Field'>, IScopeOptions {
  lookupName: string;
  params?: any;
  isLookupTypeFixed?: boolean;
  valueField?: string;
  displayField?: string;
  descriptionField?: string;
  column1Field?: string;
  column2Field?: string;
  isV2?: boolean;
  isV3?: boolean;
  fieldRef?: React.Ref<IBasicLookupFieldHandle>;
  onValueChange?(value: string): void;
  setFieldAsyncError?(fieldName: string, error: string): void;
}

const BasicLookupActionField = (props: Omit<IBasicLookupActionFieldProps, 'classes' | 'onFindOption' | 'onSearch'>, ref: React.Ref<IBasicLookupFieldHandle>) => {

  const {
    lookupName,
    value,
    params = {},
    isCustomerScoped = false,
    isWorksaleScoped = false,
    isProductScoped = false,
    isEntityScoped = false,
    isSupplierScoped = false,
    isWidgetTypeScoped = false,
    isLookupTypeFixed = false,
    column1Field,
    column2Field,
    valueField,
    displayField,
    descriptionField,
    fieldRef,
    setFieldAsyncError,
    onSelectedItemChange,
    onChange,
    onValueChange,
    onInvalidChange,
    isV2,
    isV3,
    ...restProps
  } = props;

  const SearchApi = useMemo(() => lookupSearchApi(lookupName, params), [lookupName, params]);

  const findOptionApi = useMemo(
    () => {
      return findOption(
        lookupName,
        {
          isCustomerScoped,
          isWorksaleScoped,
          isProductScoped,
          isEntityScoped,
          isWidgetTypeScoped,
          isSupplierScoped,
        },
        params
      );
    },
    [
      lookupName,
      isCustomerScoped,
      isWorksaleScoped,
      isProductScoped,
      isEntityScoped,
      isWidgetTypeScoped,
      isSupplierScoped,
      params
    ]);

  const filterColumnsByVersion = (cols: ILookupFieldColumnEx[]): ILookupFieldColumnEx[]  => {

    return cols.filter(({ hideInV2, hideInV3 }) => {
      return !(isV3 && hideInV3) && !(isV2 && hideInV2);
    });
  };

  const getSettingsByLookupName = () => {
    switch (lookupName) {
      case CUSTOMER_LOOKUP_NAME:
        return {
          columns: filterColumnsByVersion(CUSTOMER_LOOKUP_COLUMNS),
          dialogStyle: SEARCH_SCREEN_DIALOG_STYLE,
          hideColumnHeaders: false,
          disableGridColumnLines: false,
          dataPrefix: 'Details'
        };
      default:
        return null;
    }
  };

  return (
    <BasicLookupField
      {...restProps}
      innerRef={fieldRef ? fieldRef : ref}
      value={value as string}
      onFindOption={findOptionApi}
      batchSize={isLookupTypeFixed ? FIXED_DEFAULT_TYPE_BATCH_SIZE : DEFAULT_BATCH_SIZE}
      valueField={valueField || 'Code'}
      descriptionField={descriptionField || 'Description'}
      displayField={displayField || 'Display'}
      column1Field={column1Field || 'FormattedValue'}
      column2Field={column2Field || 'Description'}
      lookupOverrides={getSettingsByLookupName()}
      onSelectedItemChange={(item, inputText) => {
        if (onChange) {
          onChange(item?.Code ?? inputText);
        }
        if (onSelectedItemChange) {
          onSelectedItemChange(item, inputText);
        }
      }}
      onInvalidChange={(invalid: boolean, invalidValue) => {
        if (onInvalidChange) {
          onInvalidChange(invalid, invalidValue);
        }
        if (setFieldAsyncError) {
          setFieldAsyncError(props.name, invalid ? 'Invalid Value' : null);
        }
      }}
      onChange={onValueChange}
      onSearch={SearchApi}
    />
  );
};

export default React.memo(React.forwardRef(BasicLookupActionField));
