import { IFormViewForm } from 'components/FormView';

const Events: IFormViewForm = {
  id: 'Events',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      visible: true,
      children: [
        {
          id: '1',
          type: 'EVENTS',
          props: {
            name: 'SupplierInvoiceEnquiryEvents',
            label: 'Supplier invoice enquiry events',
            fetchApi: '/SupplierInvoiceEnquiry/SupplierInvoice/Events/Invoices/<DocumentNumber>'
          }
        }
      ]
    }
  ]
};
export default Events;
