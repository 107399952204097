export default [
  {
    id: '1',
    label: 'Pick Slip Enquiry in new tab',
    'action': (params) => {
      window.open('/pick-slip-enquiry' + (params ? '/' + params : ''))}
  },
  {
    id: '2',
    label: 'Pick Slip Enquiry',
    'action': (params) => {
      window.location.pathname = '/pick-slip-enquiry' + (params ? '/' + params : '')}
  }  
]
