import { takeLatest, Effect, put, call, select } from 'redux-saga/effects';
import { types as capabilityTypes, actions as capabilityActions } from 'ducks/activityMaintenance/capability';
import { selectors as activitySelectors } from 'ducks/activityMaintenance/activity';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/activityMaintenance/capability';
import { IDataAction } from 'ducks/utils';
import { IActivitySearchFacade } from 'api/swaggerTypes';
import { OPERATIONS } from 'utils/operations';
import { actions as uiOperationActions } from 'ducks/uiOperations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* postSuccessCallback(response: any, message: string): IterableIterator<Effect> {
    const selectedActivity: IActivitySearchFacade = yield select(activitySelectors.selected);
    const ActivityCode = selectedActivity.ActivityCode;
    if (!response.Forms) {
        yield put(capabilityActions.search({ ActivityCode, SearchText: response.CapabilityDetails?.inlineObject.CapabilityCode }));
        showSnackBar({ variant: 'success', message });
    } else {
        yield put(capabilityActions.search({ ActivityCode, SearchText: '' }));
        yield put(uiOperationActions.changeOperationMode(OPERATIONS.EDIT));
    }
}

function* createCapability(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = capabilityActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield call(postSuccessCallback, response, 'Capability added successfully.');
    }

    yield callApi(
        call(api.create, action.data),
        success,
        failure,
        { dialogActionCallEffect: createCapability, postApiSuccessCallEffect }
    );
}

function* deleteCapability(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = capabilityActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        if (!response.Forms) {
            const selectedActivity: IActivitySearchFacade = yield select(activitySelectors.selected);
            const ActivityCode = selectedActivity.ActivityCode;
            yield put(capabilityActions.search({ ActivityCode }));
            showSnackBar({ variant: 'success', message: 'Capability deleted successfully.' });
        }
    }

    yield callApi(
        call(api.deleteCapability, action.data),
        success,
        failure,
        { dialogActionCallEffect: deleteCapability, postApiSuccessCallEffect }
    );
}

export default function* rootActivityMaintenanceCapabilitySaga(): IterableIterator<Effect> {
    yield takeLatest(capabilityTypes.search, connectSagaToApi(capabilityActions, 'search', api.search));
    yield takeLatest(capabilityTypes.searchById, connectSagaToApi(capabilityActions, 'searchById', api.searchById));
    yield takeLatest(capabilityTypes.create, createCapability);
    yield takeLatest(capabilityTypes.delete, deleteCapability);
}
