
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import { selectors as rfcSelectors } from 'ducks/supplierEnquiry/activity/rfc';

import Activity from './Activity';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedSupplier: supplierSelectors.selected(state),
  rfcSummary: rfcSelectors.summary(state),
  loadingRFCSummary: rfcSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(Activity);
