import { IKitDetail } from 'api/Worksale/worksale';
import { createReducer } from 'utils/helpers';
import { PriceOverrideScreenType } from '../common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { IActions, SalesProcessingActionsTypes } from './Worksale.actions';
import { ILostSalesData, PriceOverrideAuthRequestedScreen } from './Worksale.properties';
import { LostSaleDialogState } from '../common/LostSalesDialog/LostSalesDialog.properties';
import { IMessageModelFacade } from 'api/swaggerTypes';

export interface IState {
  WorksaleId: string | number;
  WorksaleLineId: number;
  WorksaleDeliveryId: number;
  CustomerId: number;
  salesEntity: string;
  UnAppliedCustomerId: number;
  priceOverrideScreenType: PriceOverrideScreenType;
  priceOverrideDialogOpen: boolean;
  multipleBomOpen: boolean;
  priceOverrideAuthRequestedScreen: PriceOverrideAuthRequestedScreen;
  priceOverrideDetailSummary: any;
  bomList: IKitDetail[];
  kitStatus: string;
  selectedBomCode: string;
  orderRecalled: boolean;
  autoSupplyQuantity: boolean;
  disableAutoSupplyQuantity: boolean;
  lostSaleDialog: LostSaleDialogState<ILostSalesData>;
  isLineEdit: boolean;
  worksaleMessagesOpen: boolean;
  worksaleMessages: IMessageModelFacade[];
  worksaleDeliverySummary: any;
}

export const initialState: IState = {
  WorksaleId: '',
  WorksaleLineId: 0,
  WorksaleDeliveryId: 0,
  CustomerId: 0,
  salesEntity: '',
  UnAppliedCustomerId: 0,
  priceOverrideScreenType: PriceOverrideScreenType.UserAuthAndReasonScreen,
  priceOverrideDialogOpen: false,
  multipleBomOpen: false,
  priceOverrideAuthRequestedScreen: PriceOverrideAuthRequestedScreen.FastLineEntry,
  priceOverrideDetailSummary: null,
  bomList: [],
  kitStatus: '',
  selectedBomCode: '',
  orderRecalled: false,
  autoSupplyQuantity: false,
  disableAutoSupplyQuantity: false,
  isLineEdit: false,
  lostSaleDialog: {
    open: false,
    data: null,
    loading: false,
    type: null,
    onOk: () => undefined
  },
  worksaleMessagesOpen: false,
  worksaleMessages: undefined,
  worksaleDeliverySummary: {
  }
};

type IActionMap = Record<SalesProcessingActionsTypes, (state: IState, payload: unknown) => IState>;

const actionMap: IActionMap = {
  [SalesProcessingActionsTypes.setWorksaleId]: (state: IState, payload: IState['WorksaleId']): IState => ({ ...state, WorksaleId: payload }),
  [SalesProcessingActionsTypes.setWorksaleLineId]: (state: IState, payload: IState['WorksaleLineId']): IState => ({ ...state, WorksaleLineId: payload }),
  [SalesProcessingActionsTypes.setCustomerId]: (state: IState, payload: IState['CustomerId']): IState => ({ ...state, CustomerId: payload }),
  [SalesProcessingActionsTypes.setSalesEntity]: (state: IState, payload: IState['salesEntity']): IState => ({ ...state, salesEntity: payload }),
  [SalesProcessingActionsTypes.setUnAppliedCustomerId]: (state: IState, payload: IState['UnAppliedCustomerId']): IState => ({ ...state, UnAppliedCustomerId: payload }),
  [SalesProcessingActionsTypes.setPriceOverrideScreenType]: (state: IState, payload: IState['priceOverrideScreenType']): IState => ({ ...state, priceOverrideScreenType: payload }),
  [SalesProcessingActionsTypes.setPriceOverrideDialogOpen]: (state: IState, payload: IState['priceOverrideDialogOpen']): IState => ({ ...state, priceOverrideDialogOpen: payload }),
  [SalesProcessingActionsTypes.setPriceOverrideAuthRequestedScreen]: (state: IState, payload: IState['priceOverrideAuthRequestedScreen']): IState => ({ ...state, priceOverrideAuthRequestedScreen: payload }),
  [SalesProcessingActionsTypes.setPriceOverrideDetailSummary]: (state: IState, payload: IState['priceOverrideDetailSummary']): IState => ({ ...state, priceOverrideDetailSummary: payload }),
  [SalesProcessingActionsTypes.setKitStatus]: (state: IState, payload: IState['kitStatus']): IState => ({ ...state, kitStatus: payload }),
  [SalesProcessingActionsTypes.setSelectedBomCode]: (state: IState, payload: IState['selectedBomCode']): IState => ({ ...state, selectedBomCode: payload }),
  [SalesProcessingActionsTypes.setBomList]: (state: IState, payload: IState['bomList']): IState => ({ ...state, bomList: payload }),
  [SalesProcessingActionsTypes.setMultipleBomState]: (state: IState, payload: IState['multipleBomOpen']): IState => ({ ...state, multipleBomOpen: payload }),
  [SalesProcessingActionsTypes.setOrderRecalled]: (state: IState, payload: IState['orderRecalled']): IState => ({ ...state, orderRecalled: payload }),
  [SalesProcessingActionsTypes.setAutoSupplyQuantity]: (state: IState, payload: IState['autoSupplyQuantity']): IState => ({ ...state, autoSupplyQuantity: payload }),
  [SalesProcessingActionsTypes.setDisableAutoSupplyQuantity]: (state: IState, payload: IState['disableAutoSupplyQuantity']): IState => ({ ...state, disableAutoSupplyQuantity: payload }),
  [SalesProcessingActionsTypes.setLostSaleDialog]: (state: IState, payload: IState['lostSaleDialog']): IState => ({ ...state, lostSaleDialog: { ...state.lostSaleDialog, ...payload } }),
  [SalesProcessingActionsTypes.setLineEdit]: (state: IState, payload: IState['isLineEdit']): IState => ({ ...state, isLineEdit: payload }),
  [SalesProcessingActionsTypes.setWorksaleMessagesOpen]: (state: IState, payload: IState['worksaleMessagesOpen']): IState => ({ ...state, worksaleMessagesOpen: payload }),
  [SalesProcessingActionsTypes.setWorksaleMessages]: (state: IState, payload: IState['worksaleMessages']): IState => ({ ...state, worksaleMessages: payload }),
  [SalesProcessingActionsTypes.setWorksaleDeliveryId]: (state: IState, payload: IState['WorksaleDeliveryId']): IState => ({ ...state, WorksaleDeliveryId: payload }),
  [SalesProcessingActionsTypes.setWorksaleDeliverySummary]: (state: IState, payload: IState['worksaleDeliverySummary']): IState => ({ ...state, worksaleDeliverySummary: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
