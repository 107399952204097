import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Scrollbars from 'react-custom-scrollbars';
import styles from './DashboardView.scss';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import Icon from '@markinson/uicomponents-v2/Icon';
import ComponentsMeta from '../Components';
import DashboardWidgetsMeta from '../DashboardWidgets/DashboardWidgets';
import classNames from 'classnames';

const Version = process.env.MOPRO_VERSION;

class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({ isFlipped: !this.state.isFlipped });
  }
  componentDidMount() {
    const { changeOperationMode } = this.props;
    if (changeOperationMode) {
      changeOperationMode('BROWSE');
    }
  }
  renderCard(programAreaId, dashboardWidgetId) {
    const program = ComponentsMeta[programAreaId];
    return program ? (
      <Grid item key={dashboardWidgetId}>
        <Card className={styles.quickAccessCard}>
          <CardActionArea className={styles.quickAccessCardArea} to={program.path} component={Link}>
            <div className={styles.quickAccessCardIcon}>{program.icon}</div>
            <Typography className={styles.quickAccessCardText} component="span">{program.description}</Typography>
          </CardActionArea>
        </Card>
      </Grid>) : undefined;
  }
  renderContent() {
    const { dashboardWidgets } = this.props;
    return dashboardWidgets.map(program => this.renderCard(program.WidgetId, program.DashboardWidgetId));
  }

  renderWidgets = () => {
    const { dashboardWidgets } = this.props;
    return dashboardWidgets.map(widget => this.renderWidget(widget));

  }
  renderWidget = (widget) => {
    const dashboardWidget = DashboardWidgetsMeta[widget.WidgetId];
    const Component = dashboardWidget ? dashboardWidget.component : null;
    return Component ? <Grid item key={widget.DashboardWidgetId} className={styles.widgetContainer}>
      <Component widgetInfo={widget} />
    </Grid> : undefined;

  }

  render() {
    const { programAreas, programAreasLoaded, isFetchWidgetsLoading, fetchDashboardWidgets } = this.props;
    return (
      <div className={styles.mainDisplayContainer} >
        <Scrollbars>
          <div className={styles.scrollableContainer}>
            <div className={styles.widgetsContainer}>
              <Grid container item spacing={16} className={styles.quickAccessGridDefault} xs={12} alignItems="flex-start">
                {programAreasLoaded && programAreas && this.renderContent()}
                {/* Sales Module */}
                {this.renderCard(50)}
              </Grid>
              <Grid container item spacing={16} className={styles.quickAccessGridDefault} xs={12} alignItems="flex-start">
                {programAreasLoaded && programAreas && this.renderWidgets()}
              </Grid>
            </div>
            <IconButton className={styles.reloadContainer} onClick={fetchDashboardWidgets}>
              <Icon iconName="Autorenew" className={classNames({ [styles.loading]: isFetchWidgetsLoading })} />
            </IconButton>
          </div>
        </Scrollbars>
        <Paper className={styles.bottomNavigationBar} square={true} elevation={10}>
          <div> <br />MomentumPro{Version ? ` (Release ${Version})` : ''}</div>
        </Paper>
      </div >
    );
  }
}

export default DashboardView;
