import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Charts.styles';
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Label,
    Legend,
    Title,
    ValueAxis,
    Export,
    Tooltip,
    ArgumentAxis
} from 'devextreme-react/chart';
import { IBarChartProperties } from './Charts.properties';

const ROTATION_ANGLE = 90;

const BarChart = (props: IBarChartProperties): JSX.Element => {
    const { classes, chartData, config } = props;
    const onPointClick = (e) => {
        e.target.select();
    };

    const customizeTooltip = (pointInfo) => {
        const isCurrentMonth = !pointInfo.seriesName.includes('Prev');
        const GP =
            pointInfo.point.data ?
                isCurrentMonth ?
                    pointInfo.point.data.MTDGP :
                    pointInfo.point.data.PreviousPeriodGP :
                0;
        const isCost = pointInfo.seriesName.includes('Cost');
        const viewableString = isCost ? 'Cost of Sales: $' : 'Sales Gross: $';

        return {
            text: `
            ${viewableString}${pointInfo.valueText}<br/>
            GP: ${GP}%
            `,
        };
    };

    return (
        <div className={classes.chartContainer}>
            <Chart
                id='chart'
                title={{
                    text: config.title,
                    subtitle: config.subtitle
                }}
                style={{ heigh: '100%', width: '100%' }}
                dataSource={chartData}
                onPointClick={onPointClick}
                palette='Soft Pastel'
            >

                {config.valueAxisTitle &&
                    <ValueAxis>
                        <Title text={config.valueAxisTitle} />
                    </ValueAxis>
                }
                <ArgumentAxis>
                    <Label
                        rotationAngle={ROTATION_ANGLE}
                        overlappingBehavior='rotate'
                    />
                </ArgumentAxis>
                <CommonSeriesSettings
                    argumentField={config.argumentField}
                    type='bar'
                    hoverMode='allArgumentPoints'
                    selectionMode='allArgumentPoints'
                >
                </CommonSeriesSettings>

                <Tooltip
                    enabled={true}
                    location={'edge'}
                    customizeTooltip={customizeTooltip}
                />
                {config.series.map((s) => (
                    <Series {...s} />
                ))}
                <Legend
                    verticalAlignment='bottom'
                    horizontalAlignment='center'>
                </Legend>
                <Export enabled={true} />
            </Chart>
        </div>
    );
};

export default withStyles(styles, { index: 1 })(BarChart);
