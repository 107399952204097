import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, IExtendedState, IDataAction, SuccessAction
} from '../utils';

export interface ISupplierContactData {
  selected: number;
  list: any[];
  schemas: any;
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  selectedContactDetail?: null;
  totalPages: number;
  currSearchText: string;
}

export interface ISupplierContactState extends IExtendedState<ISupplierContactData> {
  searchContact_loading?: boolean;
  searchContactById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (ContactNumber) => ({ ContactNumber }),
    asyncs: {
      searchContact: (query) => (query),
      searchContactById: ({ Supplier, ContactNumber }) => ({ Supplier, ContactNumber })
    }
  },
  'supplierContacts');

const NOT_SELECTED = -1;
const initialState = asyncInitialState<ISupplierContactData>({
  selected: NOT_SELECTED,
  list: null,
  schemas: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 1,
  currSearchText: ''
});

export default (state: ISupplierContactState = initialState, action: IDataAction): ISupplierContactState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.ContactNumber;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.searchContact:
        return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchContactById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.saga.searchContact.success:
    case types.saga.searchContactById.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierContactData, successAction: SuccessAction) => {
          const records = successAction.payload.records;
          const batchInformation = successAction.payload.BatchInformation;
          if (records) {
            const list = records.map((combinedObject) => combinedObject.inlineObject);
            const schemas = records.map((combinedObject) => combinedObject.schema);

            return {
              ...data,
              list: list,
              schemas: schemas,
              nextPage: batchInformation && !batchInformation.EndOfData ? batchInformation.BatchPage + 1 : 0,
              prevPage: batchInformation && batchInformation.EndOfData ? batchInformation.BatchPage - 1 : 0,
              currPage: batchInformation && batchInformation.BatchPage,
              selected: (list && list[0] && list[0].ContactNumber) || -1
            };
          }

          return {
            ...data,
          };
        },
        { fetch: true });
    case types.saga.searchContact.failure:
    case types.saga.searchContactById.failure:
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ContactNumber === data.selected)) || NOT_SELECTED,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ContactNumber === data.selected)) || null,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
);

const syncSelector = {
  isLoading: (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts.searchContact_loading || state.supplierEnquiry.supplierContacts.searchContactById_loading,
  loadingNextPage: (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts.fetchNextPage_loading,
  loadingPrevPage: (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts.fetchPrevPage_loading,
  pageSize: (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts.data.pageSize,
  totalPages: (state: { supplierEnquiry: { supplierContacts: ISupplierContactState } }) => state.supplierEnquiry.supplierContacts.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
