import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as kitSelectors, actions as kitActions } from 'ducks/pickSlips/kitComponents';
import { actions as filterActions } from 'ducks/common/filters';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { IApplicationState } from 'ducks/reducers';
import { selectors as slipLineSelectors } from 'ducks/pickSlips/slipLines';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'PSKitComponents',
  columnDefs: kitSelectors.lineColumns(state),
  gridOptions: kitSelectors.gridOptions(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  reqParams: ((pickSlipSelectors.selected(state) || params.get('DespatchId')) && (slipLineSelectors.selected(state) || params.get('LineNumber')))
    &&
    {
      DespatchId: (pickSlipSelectors.selected(state) && pickSlipSelectors.selected(state).DespatchId) || params.get('DespatchId'),
      LineNumber: (slipLineSelectors.selected(state) && slipLineSelectors.selected(state).LineNumber) || params.get('LineNumber')
    },
  rowData: kitSelectors.kitComponents(state),
  isLoading: kitSelectors.isLoading(state),
  newLineRenderer: kitSelectors.newLineRenderer(state),
  bypassDeleteConfirmation: true
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: kitActions.setSelected,
  applyFilters: filterActions.applyFilters
};
