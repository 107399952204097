import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  dialog: {
    width: 'calc(60vw + 32px)',
    overflow: 'hidden'
  },
  dialogActionBar: {
    backgroundColor: '#dddddd',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
    borderRadius: 0.7,
    minHeight: '52px',
    height: 'auto',
    margin: 'unset',
    display: 'block',
    zIndex: 1
  },
  warningGridContainer: {
    minHeight: '200px',
    height: '100%',
    position: 'relative',
    flexGrow: 1
  },
  warningGrid: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  modalContent: {
    flexDirection: 'column',
    display: 'flex'
  },
  actionBtn: {
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize'
  },
  dialogTitleHeading: {
    fontSize: 22,
    paddingBottom: 8
  },
  dialogTitle: {
    padding: '10px 0px 12px 22px',
    backgroundColor: '#dddddd',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.65)',
    height: 30,
    zIndex: 1,
    marginBottom: 5
  },
  iconStyle: {
    height: '40px',
    width: '40px',
    fill: '#000000'
  },
  disabledIconStyle: {
    height: '40px',
    width: '40px',
    fill: 'rgba(0, 0, 0, 0.54)'
  },
  buttonTitle: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center'
  },
  hide: {
    display: 'none'
  }
});
