import { omit } from 'lodash';

/**
 * create reducer for userReducer hook with object type procedural calls instead of switch cases
 * @param {IState} state state.
 * @param {IAction} actions actions to be called.
 * @param {IActions} actions actions to be called.
 * @param {IActionMap} actions actions to be called.
 */

export const createReducer = <IActionMap, IState, IActions>(actionMap: IActionMap) => (state: IState, actions: IActions): IState => {

  const key = Object.keys(actions)?.[0] ?? null;
  if (!key) {
    return state;
  }

  const payload = actions[key];
  const handler = actionMap[key];
  const updatedState = handler ? handler(state, payload) : state;
  const updatedActions = omit(actions as object, [key]);

  return createReducer(actionMap)(updatedState, updatedActions) as IState;
};

export const isAnyDialogOpened = (): boolean => {
  const modalElement = document.querySelector('.MuiDialog-root');

  return Boolean(modalElement);
};
