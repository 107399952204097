import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as inTransitImportSelectors, actions as inTransitImportActions } from 'ducks/inventoryEnquiry/stocking/inTransitImport';
import { fetch } from 'api/inventoryEnquiry/stocking/inTransitImport';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup'
import { selectors as quantitySelectors } from 'ducks/inventoryEnquiry/stocking/quantities'

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesInTransitImport',
  columnDefs: inTransitImportSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: inTransitImportSelectors.gridOptions(state),
  isLoading: inTransitImportSelectors.isLoading(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    && (quantitySelectors.selected(state) || params.get('Warehouse')) &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (quantitySelectors.selected(state) && quantitySelectors.selected(state).Warehouse) || params.get('Warehouse')
  },
  apiMethod: fetch

})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: inTransitImportActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
