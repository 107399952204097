import { createActions, asyncInitialState, asyncOnRequest, asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, DataAction, SuccessAction } from '../utils';
import { pathOr } from 'utils/utils';

export interface ICreditNoteData {
  active: boolean;
  creditNoteHeader: any;
  Lines: any[];
  creditNoteForms: any;
  creditNoteLineColumns: any;
  creditNoteLineGridOptions: any;
  creditNoteLineActions: any;
  newLineRenderer: string;
  selected: any;
  details: any;
  detailsSchema: any;
  applyResponse: any;
}

export interface ICreditNoteState extends IExtendedState<ICreditNoteData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      creditNoteLoading: () => null,
      cancelCreditNote: () => null,
      cancelCreditNoteDetails: () => null,
      saveDetails: (data) => (data),
      updateDetails: (data) => (data),
      updateCreditNoteNote: (data) => (data),
      getCreditNote: () => null,
      saveCreditNoteDetails: (data) => (data),
      processCreditNote: (data) => (data),
    },
    applyCreditNoteContext: (data) => (data),
    deleteCreditNoteLine: (data) => (data),
    saveCreditNoteLine: (data) => (data),
    saveNewCreditNoteLine: (data) => (data),
    saveCreatedCreditNoteLine: (data) => (data),
    saveUpdatedCreditNoteLine: (data) => (data),
    setSelected: (row) => ({ row }),
    completeProcessCreditNote: (data) => (data),
  },
  'creditNote');

const initialState = asyncInitialState<ICreditNoteData>({
  active: false,
  creditNoteHeader: null,
  Lines: [
  ],
  creditNoteForms: {},
  creditNoteLineColumns: [
    { headerName: 'Line', field: 'LineNumber', width: 350 },
    { headerName: 'Product', field: 'ProductIdDisplay', width: 350 },
    { headerName: 'Quantity', field: 'QauntityDisplay', width: 350 },
    { headerName: 'Price', field: 'PriceDisplay', width: 350 },
    { headerName: 'Discount', field: 'DiscountDisplay', width: 350 },
    { headerName: 'Total', field: 'TotalDisplay', width: 350 },
    { headerName: 'Description', field: 'Description', width: 350 },
    { headerName: 'Comment', field: 'Comment', width: 350 }
  ],
  creditNoteLineGridOptions: {
    doubleClickActionTab: 'CNLineDetails',
  },
  creditNoteLineActions: {},
  newLineRenderer: 'CNLines',
  selected: null,
  details: null,
  detailsSchema: null,
  applyResponse: null
});

export default (state: ICreditNoteState = initialState, action: DataAction): ICreditNoteState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.creditNoteLoading:
    case types.processCreditNote:
    case types.cancelCreditNote:
    case types.cancelCreditNoteDetails:
    case types.updateCreditNoteNote:
    case types.updateDetails:
    case types.saveDetails:
    case types.getCreditNote:
    case types.saveCreditNoteDetails:
      return asyncOnRequest(state, action);

    case types.saga.creditNoteLoading.success:
    case types.saga.processCreditNote.success:
    case types.saga.saveDetails.success:
    case types.saga.updateDetails.success:
      return asyncOnSuccess(state, action, (data: ICreditNoteData, successAction: SuccessAction) => {
        return {
          ...data,
          active: true,
          creditNoteHeader: pathOr(null, ['payload', 'CreditNotes', 'CreditNoteHeader'], successAction),
          Lines: pathOr([], ['payload', 'CreditNotes', 'Lines'], successAction).map((line) => line.inlineObject),
          creditNoteForms: pathOr(null, ['payload', 'CreditNotes', 'CreditNoteForms'], successAction),
          applyResponse: pathOr(null, ['payload', 'Response'], successAction),
        };
      });

    case types.saga.cancelCreditNote.success:
      return asyncOnSuccess(state, action, (data: ICreditNoteData) => {
        return {
          ...data,
          active: false,
          creditNoteHeader: { ...pathOr({}, ['data', 'creditNoteHeader'], initialState) },
          details: { ...pathOr({}, ['data', 'details'], initialState) },
          Lines: [],
          applyResponse: null
        };
      });
    case types.saga.updateCreditNoteNote.success:
      return asyncOnSuccess(state, action, (data: ICreditNoteData, successAction: SuccessAction<string>) => {
        return {
          ...data,
          note: successAction.payload
        };
      });
    case types.saga.getCreditNote.success:
    case types.saga.saveCreditNoteDetails.success:
      return asyncOnSuccess(state, action, (data: ICreditNoteData, successAction: SuccessAction<string>) => {
        return {
          ...data,
          details: { ...pathOr({}, ['payload', 'Details', 'inlineObject'], successAction) },
          detailsSchema: { ...pathOr({}, ['payload', 'Details', 'schema'], successAction) }
        };
      });
    case 'creditNoteContext_clearCreditNoteContext':
      return initialState;
    case types.saga.creditNoteLoading.failure:
    case types.saga.processCreditNote.failure:
    case types.saga.cancelCreditNote.failure:
    case types.saga.updateDetails.failure:
    case types.saga.saveDetails.failure:
    case types.saga.cancelCreditNoteDetails.failure:
    case types.saga.updateCreditNoteNote.failure:
    case types.saga.saveCreditNoteDetails.failure:
    case types.saga.getCreditNote.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors((state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote, {
    creditNoteHeader: (data: ICreditNoteData) => data.creditNoteHeader,
    Lines: (data: ICreditNoteData) => data.Lines,
    creditNoteForms: (data: ICreditNoteData) => data.creditNoteForms,
    details: (data: ICreditNoteData) => data.details,
    detailsSchema: (data: ICreditNoteData) => data.detailsSchema,
    applyResponse: (data: ICreditNoteData) => data.applyResponse,
    customerId: (data: ICreditNoteData) => data.creditNoteHeader && data.creditNoteHeader.CustomerId && data.creditNoteHeader.CustomerId.Value ? parseInt(data.creditNoteHeader.CustomerId.Value) : 0,
  }),
  notifications: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.notifications,
  error: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.error,
  creditNoteLoading: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.loading,
  creditNoteFetched: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.fetched && state.creditNotes.creditNote.data.active,
  creditNoteError: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.error,
  creditNoteNotifications: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.notifications,
  creditNoteLineGridOptions: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.data.creditNoteLineGridOptions,
  creditNoteLineColumns: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.data.creditNoteLineColumns,
  creditNoteLineActions: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.data.creditNoteLineActions,
  newLineRenderer: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.data.newLineRenderer,
  selected: (state: { creditNotes: { creditNote: ICreditNoteState } }) => state.creditNotes.creditNote.data.selected,
};
