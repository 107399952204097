import { takeLatest, Effect, call } from 'redux-saga/effects';
import { types as activityTypes, actions as activityActions } from 'ducks/serviceItemTypeMaintenance/serviceItemType';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/serviceItemTypeMaintenance/serviceItemType';
import { IDataAction } from 'ducks/utils';

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = activityActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure,
    { dialogActionCallEffect: searchById }
  );
}

export default function* rootActivityMaintenanceSaga(): IterableIterator<Effect> {
  yield takeLatest(activityTypes.search, connectSagaToApi(activityActions, 'search', api.search));
  yield takeLatest(activityTypes.fetchPrevPage, connectSagaToApi(activityActions, 'fetchPrevPage', api.search));
  yield takeLatest(activityTypes.fetchNextPage, connectSagaToApi(activityActions, 'fetchNextPage', api.search));
  yield takeLatest(activityTypes.searchById, searchById);
}
