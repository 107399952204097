import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle, CardMedia, LinearProgress, IconButton } from '@material-ui/core';
import Gallery from 'devextreme-react/gallery';
import { IImageViewProperties, NoImage } from './ImageView.properties';
import { inlineStyles } from './ImageView.styles';
import Icon from '@markinson/uicomponents-v2/Icon';
import { ChevronLeft as ArrowBackIcon, ChevronRight as ArrowForwardIcon } from '@markinson/uicomponents-v2/SvgIcons';
import { ARROW_LEFT, ARROW_RIGHT } from 'utils/constants';
const BACK_ICON_HEIGHT = 48;
const GALLERY_IMAGE_HEIGHT = 300;

const ImageViewComponent = (props: IImageViewProperties): JSX.Element => {
  const { classes, mainImageObject, imageCount, getMainImage, getListOfImages, loadImage, dialogTitle } = props;

  const galleryRef = React.useRef(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>();
  const [totalCount, setTotalCount] = React.useState<number>(1);
  const [mainImage, setMainImage] = React.useState<any>();
  const [currentCounter, setCurrentCounter] = React.useState<number>(1);
  const [imageList, setImageList] = React.useState<any[]>();
  const [disablePrevButton, setDisablePrevButton] = React.useState<boolean>(true);
  const [disableNextButton, setDisableNextButton] = React.useState<boolean>(true);

  React.useEffect(
    () => {
      setLoading(true);
      if (Number(imageCount) > 0 && getMainImage && mainImageObject) {
        getMainImage(mainImageObject)
          .then((response) => {
            setMainImage(response);
          });
      } else {
        setMainImage(null);
      }
      setLoading(false);
    },
    [mainImageObject]
  );

  React.useEffect(
    () => {
      setLoading(true);

      if (open && getListOfImages) {

        getListOfImages()
          .then((resp) => {
            if (Number(imageCount) > 0) {
              setTotalCount(imageCount);
              if (imageCount > 1) {
                setCurrentCounter(1);
              }
              setDisableNextButton((imageCount > 1) ? false : true);
            }
            setImageList(resp);
          });
      }
      setLoading(false);
    },
    [open]
  );
  React.useEffect(
    () => {
      setDisablePrevButton(currentCounter === 1);
      setDisableNextButton(currentCounter === imageCount);
    },
    [currentCounter]
  );

  function goToPreviousItem(): void {
    if (currentCounter > 1) {
      setCurrentCounter(currentCounter - 1);
    }
    galleryRef.current.instance.prevItem(true);
  }

  function goToNextItem(): void {
    if (currentCounter < totalCount) {
      setCurrentCounter(currentCounter + 1);
    }
    galleryRef.current.instance.nextItem(true);
  }

  function handleClose(): void {
    setCurrentCounter(1);
    setOpen(false);
  }

  function handleCardClick(): void {
    if (imageCount > 0) {
      setOpen(true);
    }
  }

  const onImageSelectionChanged = (selectedItem) => {

    const selectedImageMeta = selectedItem?.addedItems?.[0];

    if (selectedImageMeta?.hasOwnProperty('Filename')) {
      setLoading(true);
      loadImage(selectedImageMeta)
        .then((resp) => {
          const updatedImages = imageList.map((img) => (img === selectedImageMeta) ? resp === undefined ? NoImage : resp : img);
          setImageList(updatedImages);
        })
        .catch((err) => console.warn(err))
        .finally(() => setLoading(false));
    }
  };
  const handleDialogOnKeyDown = React.useCallback(
    (e) => {
      switch (e.keyCode) {
        case ARROW_LEFT:
          goToPreviousItem();
          break;

        case ARROW_RIGHT:
          goToNextItem();
          break;

        default:
      }
    },
    [imageCount, totalCount, currentCounter]
  );

  return (
    <div className={classes.mainDiv}>
      <div className={classes.galleryPaper}>
        {mainImage ? <CardMedia
          className={classes.media}
          image={mainImage}
          onClick={handleCardClick}
        /> :
          <div
            className={`${classes.media} ${classes.noImageDiv}`}
          >
            No Image
          </div>
        }
      </div>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby='confirmation-dialog-title'
        open={open}
        onKeyDown={handleDialogOnKeyDown}
        onClose={handleClose}
      >
        <DialogTitle id='confirmation-dialog-title'
          className={classes.dialogTitle}
        >
          <div className={classes.dialogTitleHeading}>{dialogTitle}</div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.imageCounterText}>
            Image {currentCounter}/{totalCount}
          </div>
          <div className={classes.galleryDiv}>
            <IconButton
              disabled={disablePrevButton}
              onClick={goToPreviousItem}
              className={classes.galleryIcon}
            >
              <ArrowBackIcon height={BACK_ICON_HEIGHT} />
            </IconButton >
            <div className={classes.galleryImage}>
              <Gallery
                id={'gallery'}
                dataSource={imageList}
                height={GALLERY_IMAGE_HEIGHT}
                showNavButtons={false}
                ref={galleryRef}
                showIndicator={false}
                onSelectionChanged={loadImage ? onImageSelectionChanged : undefined}
                swipeEnabled={false}
              />
            </div>

            <IconButton
              disabled={disableNextButton}
              onClick={goToNextItem}
              className={classes.galleryIcon}
            >
              <ArrowForwardIcon />
            </IconButton>
          </div >
        </DialogContent >
        <div>
          {loading && <LinearProgress className={classes.linearProgress} color='secondary' />}
          <DialogActions className={classes.dialogActionBar}>
            <Button onClick={handleClose}>
              <div className={classes.buttonTitle}>
                <Icon
                  className={classes.dialogBtnIcon}
                  iconName={'Cancel'}
                />
                {'Cancel'}
              </div>
            </Button>

          </DialogActions>
        </div>
      </Dialog >

    </div >
  );

};

export default withStyles(inlineStyles, { index: 1 })(ImageViewComponent);
