import React from 'react';
import FormView from 'components/FormView';
import ChecklistMaintenanceDetailsForm from './ChecklistMaintenanceDetail.form';
import AddCircle from '@markinson/uicomponents-v2/SvgIcons/AddCircle';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import RemoveCircle from '@markinson/uicomponents-v2/SvgIcons/RemoveCircle';
import Edit from '@markinson/uicomponents-v2/SvgIcons/Edit';
import Cancel from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import IChecklistMaintenanceDetailProperties from './ChecklistMaintenanceDetail.properties';
import { Operation } from 'utils/operations';
import { isNull } from 'utils/utils';
import ChecklistQuestionsGrid from './ChecklistQuestionsGrid';
import styles from './ChecklistMaintenanceDetail.styles';
import DataGrid from 'devextreme-react/data-grid';
import { IChecklistDetailsFacade, IChecklistLineDetailFacade } from 'api/swaggerTypes';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { cloneDeep } from 'lodash';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const leftIcons = [
    {
        label: 'Back',
        Icon: ArrowBack,
        action: 'Back',
    }
];

const ChecklistMaintenanceDetail = (props: IChecklistMaintenanceDetailProperties) => {
    const {
        dirty,
        values,
        forwardedRef,
        selectedChecklist,
        operationMode,
        touchFormFields,
        changeFormFieldValue,
        resetForm,
        setOperationMode,
        changeConfirmationDialog,
        createChecklist,
        updateChecklist,
        deleteChecklist,
        search,
        searchById
    } = props;

    const editOrNewOperation = operationMode === Operation.NEW || operationMode === Operation.EDIT;
    const isChecklistSelected = !isNull(selectedChecklist);
    const centerIcons = [
        {
            label: 'Add Question',
            Icon: AddCircle,
            action: 'AddQuestion',
            disabled: !editOrNewOperation
        }
    ];
    const rightIcons = [
        {
            label: 'New',
            Icon: AddCircle,
            action: 'NewChecklist',
            disabled: editOrNewOperation
        },
        {
            label: 'Edit',
            Icon: Edit,
            action: 'EditChecklist',
            disabled: !isChecklistSelected || editOrNewOperation
        },
        {
            label: 'Delete',
            Icon: RemoveCircle,
            action: 'DeleteChecklist',
            disabled: !isChecklistSelected || editOrNewOperation
        },
        {
            label: 'Cancel',
            Icon: Cancel,
            action: 'CancelChecklist',
            disabled: !editOrNewOperation
        },
        {
            label: 'Ok',
            Icon: CheckCircle,
            action: 'OkChecklist',
            disabled: !editOrNewOperation
        },
    ];
    const processValidationForms = React.useContext(ProcessValidationFormsContext);

    const dataGridRef = React.useRef<DataGrid>();
    const { setActionBar } = React.useContext(ActionBarContext);

    const [checklistQuestionsData, setChecklistQuestionsData] = React.useState<IChecklistLineDetailFacade[]>([]);
    const [checklistQuestionsModified, setChecklistQuestionsModified] = React.useState<boolean>(false);

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            onNew(): void {
                initChecklistDetail();
                setOperationMode(Operation.NEW);
            },
            onEdit(): void {
                setOperationMode(Operation.EDIT);
            },
            onDelete(): void {
                changeConfirmationDialog({
                    open: true,
                    title: 'Delete Checklist',
                    message: 'Are you sure you want to continue?',
                    okLabel: 'Delete',
                    onCancel: () => null,
                    onOk: () => {
                        deleteChecklistDetail(selectedChecklist.ChecklistCode)
                            .then((response) => {
                                if (response.Status && !response.Forms) {
                                    showSnackBar({ variant: 'success', message: 'Checklist deleted successfully.' });
                                    search({ SearchText: '' });
                                }
                                if (!response.Status && !response.Forms) {
                                    showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete checklist.' });
                                }
                            })
                            .catch((err) => {
                                showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete checklist.' });
                                console.warn(err);
                            });
                    }
                });
            },
            onCancel(): void {
                if (dirty || checklistQuestionsModified) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => null,
                        onOk: () => {
                            resetForm();
                            setOperationMode(Operation.BROWSE);
                            setChecklistQuestionsData(selectedChecklist ? cloneDeep(selectedChecklist.Questions) : []);
                            setChecklistQuestionsModified(false);

                        }
                    });

                } else {
                    resetForm();
                    setOperationMode(Operation.BROWSE);
                }

                if (dataGridRef.current) {
                    dataGridRef.current.instance.cancelEditData();
                }
            },
            onOk(): void {
                setChecklistQuestionsModified(false);

                if (!checklistFormValidated()) return;

                if (dataGridRef.current) {
                    dataGridRef.current.instance.cancelEditData();
                }

                const checklist = {
                    ...values,
                    Questions: checklistQuestionsData.map((que, idx) => ({ ...que, Sequence: idx + 1 }))
                };

                if (operationMode === Operation.NEW) {
                    create(checklist)
                        .then((response) => {
                            if (response.Status && !response.Forms) {
                                showSnackBar({ variant: 'success', message: 'Checklist created successfully.' });
                                searchById(response?.Checklist?.inlineObject?.ChecklistCode);
                            }
                            if (!response.Status) {
                                showSnackBar({ variant: 'error', message: 'Failed to create checklist.' });
                            }
                            if (response.Forms) {
                                initChecklistDetail(selectedChecklist);
                            }
                        })
                        .catch((err) => {
                            showSnackBar({ variant: 'error', message: 'Failed to create checklist.' });
                            console.warn(err);
                        });
                } else {
                    update(checklist)
                        .then((response) => {
                            if (response.Status && !response.Forms) {
                                showSnackBar({ variant: 'success', message: 'Checklist updated successfully.' });
                                searchById(response?.Checklist?.inlineObject?.ChecklistCode);
                            }
                            if (!response.Status) {
                                showSnackBar({ variant: 'error', message: 'Failed to update Checklist.' });
                            }

                        })
                        .catch((err) => {
                            showSnackBar({ variant: 'error', message: 'Failed to update Checklist.' });
                            console.warn(err);
                        });
                }

                setOperationMode(Operation.BROWSE);
            },
            addQuestion(): void {
                if (dataGridRef.current) {
                    dataGridRef.current.instance.addRow();
                }
            }
        })
    );

    React.useEffect(
        () => {
            setChecklistQuestionsData(selectedChecklist ? cloneDeep(selectedChecklist.Questions) : []);
        },
        [selectedChecklist]
    );

    React.useEffect(
        () => {

            setActionBar({
                leftIcons,
                centerIcons,
                rightIcons
            });
        },
        [operationMode, selectedChecklist]
    );

    function checklistFormValidated(): boolean {
        if (!values || !values.ChecklistCode || !values.Description) {
            touchFormFields('ChecklistCode', 'Description');

            return false;
        }

        return true;
    }

    function initChecklistDetail(checklist: IChecklistDetailsFacade = null): void {
        resetForm();
        changeFormFieldValue('ChecklistCode', checklist ? checklist.ChecklistCode : '');
        changeFormFieldValue('Description', checklist ? checklist.Description : '');
        changeFormFieldValue('Instructions', checklist ? checklist.Instructions : '');
        changeFormFieldValue('DataType', checklist ? checklist.DataType : '');
        setChecklistQuestionsData(checklist ? checklist.Questions : []);
    }

    async function create(query: any): Promise<any> {
        const response = await createChecklist(query);

        return processValidationForms(response, query, async (validatedResponse) => create(validatedResponse));
    }

    async function update(query: any): Promise<any> {
        const response = await updateChecklist(query);

        return processValidationForms(response, query, async (validatedResponse) => update(validatedResponse));
    }

    async function deleteChecklistDetail(checklistCode: any): Promise<any> {
        const response = await deleteChecklist(checklistCode);

        return processValidationForms(response, checklistCode, async (validatedResponse) => deleteChecklist(validatedResponse));
    }

    return (
        <React.Fragment>
            <FormView
                formName={'ChecklistMaintenanceDetail'}
                style={{
                    height: 313
                }}
                schema={ChecklistMaintenanceDetailsForm}
                includeTabsHeight={true}
                initialValues={selectedChecklist}
                operationMode={operationMode}
                fieldExtensions={{
                    ChecklistCode: {
                        disabled: operationMode === Operation.EDIT || operationMode === Operation.BROWSE
                    }
                }}
            />
            <div style={styles.questionsGridContainer}>
                <ChecklistQuestionsGrid
                    disabled={operationMode === Operation.BROWSE}
                    dataGridRef={dataGridRef}
                    changeConfirmationDialog={changeConfirmationDialog}
                    onUpdate={() => setChecklistQuestionsModified(true)}
                    onDelete={() => setChecklistQuestionsModified(true)}
                    onInsert={() => setChecklistQuestionsModified(true)}
                    onReorder={(updatedQuestions) => {
                        setChecklistQuestionsData(updatedQuestions);
                        setChecklistQuestionsModified(true);
                    }}
                    onDeleteRow={(Sequence) => {
                        const updatedChecklistQuestionsData = checklistQuestionsData.filter((question) => question.Sequence !== Sequence);
                        setChecklistQuestionsData(updatedChecklistQuestionsData);
                        setChecklistQuestionsModified(true);
                    }}
                    checklistQuestionsData={checklistQuestionsData}
                />
            </div>
        </React.Fragment>
    );
};

export default ChecklistMaintenanceDetail;
