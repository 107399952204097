import { fork, Effect } from 'redux-saga/effects';

import commonNotepadSaga from './common/notepad';
import diarySaga from './common/diary';
import PriceCalculatorSaga from './common/priceCalculator';
import rootSerialNumberSaga from './common/serialNumber';

import rootCreditNotesSaga from './creditNotes';
import rootStaffSaga from './staff';
import customerSaga from './customer';
import supplierSaga from './supplierEnquiry/supplier';
import supplierContactSaga from './supplierEnquiry/supplierContact';
import supplierDeliveryDetailsSaga from './supplierEnquiry/deliverydetails';
import supplierTransactionsSaga from './supplierEnquiry/supplierTransactions';
import supplierDetailsTransactionsSaga from './supplierEnquiry/supplierDetailsTransactions';
import supplierInvoiceSaga from './supplierInvoiceEnquiry/supplierInvoice';
import supplierUserFieldsSaga from './supplierEnquiry/userFields';
import invoiceDetailsSaga from './supplierInvoiceEnquiry/invoiceDetails';
import invoiceLinesSaga from './supplierInvoiceEnquiry/invoiceLines';
import invoiceLinesLotDetailsSaga from './supplierInvoiceEnquiry/invoiceLinesLotDetails';
import invoiceLinesSerialNumbersSaga from './supplierInvoiceEnquiry/invoiceLinesSerialNumbers';
import invoiceLinesMovementsSaga from './supplierInvoiceEnquiry/invoiceLinesMovements';
import rfcEnquirySaga from './rfc/rfc';
import rfcEnquiryDetailsSaga from './rfc/rfcDetails';
import rfcEnquiryLinesSaga from './rfc/rfcLines';
import contactSaga from './contact';
import addressSaga from './address';
import rootLookupSaga from './lookup';
import rootFormSaga from './form';
import searchSaga from './searchPanel';
import balancesSaga from './customerEnquiry/balances';
import productSaga from './customerEnquiry/product';
import quotesSaga from './customerEnquiry/quotes';
import salesOrdersSaga from './customerEnquiry/salesOrders';
import backordersSaga from './customerEnquiry/backorders';
import customerProductSaga from './customerEnquiry/customerProduct';
import rootMarketingSaga from './customerEnquiry/marketing';
import rootCustomerQuoteEnquirySaga from './customerQuoteEnquiry/quote';
import rootBOMSaga from './customerQuoteEnquiry/BOM';
import invoice from './salesInvoiceEnquiry/invoice';
import invoiceLines from './salesInvoiceEnquiry/invoiceLines';
import CEPricing from './customerEnquiry/pricing';

//sale Order enquiry
import saleOderEnquirySaga from './salesOrderEnquiry/salesOrder';
import saleOrderDeliverySaga from './salesOrderEnquiry/salesOrderDeliveryLines';
import depositDetailsSage from './salesOrderEnquiry/DepositDetails';
import orderLineDetailsSaga from './salesOrderEnquiry/OrderLineDetails';
import rootReservationDetailsSaga from './salesOrderEnquiry/ReservationDetails';
import rootCDReservationDetailsSaga from './salesOrderEnquiry/CdReservationDetails';
import componentDetailsSaga from './salesOrderEnquiry/ComponentDetails';
import paymentEntryDetailsSaga from './salesOrderEnquiry/PaymentEntryDetails';

//purchase Order
import purchaseOderEnquirySaga from './purchaseOrderEnquiry/PurchaseOrder';
import purchaseOrderLineDetailsSaga from './purchaseOrderEnquiry/PurchaseOrderLineDetails';
import purchaseOrderBranchOrdersSaga from './purchaseOrderEnquiry/BranchOrders';
import purchaseOrders from './purchaseOrders/purchaseOrders';

import paymentSaga from './salesInvoiceEnquiry/payment';
import invoiceLineBOMSaga from './salesInvoiceEnquiry/invoiceLineBOM';
import invoiceLineSerialsSaga from './salesInvoiceEnquiry/invoiceLineSerials';
import invoiceLineLotSaga from './salesInvoiceEnquiry/invoiceLineLots';
import salesAuditDetailsSaga from './salesInvoiceEnquiry/salesAuditDetails';
import customerInvoiceSaga from './customerEnquiry/invoices';
import rootTransactionSaga from './customerEnquiry/transactions';
import priceCheckDetailsSaga from './priceCheck/details';
import priceCheckWhySaga from './priceCheck/why';
import PCTrueCost from './priceCheck/trueCost';
import rootProductLookUpSaga from './SearchLookUp/productLookUp';
import IEProductdetails from './inventoryEnquiry/productDetails';
import IEStockingMovements from './inventoryEnquiry/stocking/movements';
import IEStockingQuantities from './inventoryEnquiry/stocking/quantities';
import IEFutureBreaks from './inventoryEnquiry/pricing/futureBreaks';
import IEPriceBreaks from './inventoryEnquiry/pricing/priceBreaks';
import IEPrices from './inventoryEnquiry/pricing/prices';
import InventoryEnquiryImagesSaga from './inventoryEnquiry/attachments/images';

import SupplierEnquiryRFCActivitySaga from './supplierEnquiry/activity/rfc';
import SupplierBalancesSaga from './supplierEnquiry/financial/balances';
import SupplierPurchaseOrdersSaga from './supplierEnquiry/purchasing/purchaseOrders';
import SupplierFinancialSettingsSaga from './supplierEnquiry/settings/financial';
import SupplierGeneralSettingsSaga from './supplierEnquiry/settings/general';
import SupplierAttachmentsImagesSaga from './supplierEnquiry/attachments/images';
import PSEPickSlipsSaga from './pickSlipEnquiry/pickSlipEnquiries';
import PSESlipLinesSaga from './pickSlipEnquiry/slipLines';
import PSEBOMSaga from './pickSlipEnquiry/bom';
import PickSlipsSaga from './pickSlips/pickSlipTransaction';
import ExtractMaintenance from './extractMaintenance/extractMaintenanceTemplates';
import PickSlipLinesSaga from './pickSlips/slipLines';
import PickSlipBOMSaga from './pickSlips/bom';
import PickSlipBinLocations from './pickSlips/binLocations';
import PickSlipKitComponent from './pickSlips/kitComponent';

import SecurityPoliciesSaga from './SecurityManagement/SecurityPolicies';

import productMovementSaga from './pme/movements';

import cashReceiptSaga from './cashReceipting/cashReceipt';
import allocateCashReceiptSaga from './cashReceipting/allocate';
import invoicesCashReceiptSaga from './cashReceipting/invoices';
import ordersCashReceiptSaga from './cashReceipting/orders';

import { watchValidationDialogChannel } from './utils';
import SupplierProductSaga from './supplierProductEnquiry/supplierProduct';
import SupplierProductImagesSaga from './supplierProductEnquiry/attachments/images';
import SupplierProductPriceSchedule from './supplierProductEnquiry/productPriceSchedule';

import MainMenuSaga from './mainMenu';

import ProgramAreaSaga from './programArea';

import paymentsSaga from './common/paymentDetails';

import serviceActivitySaga from './serviceActivityScheduling/serviceActivities';

import rootActivityMaintenanceSaga from './activityMaintenance/activity';
import rootActivityMaintenanceCapabilitySaga from './activityMaintenance/capability';
import rootActivityMaintenanceChecklistsSaga from './activityMaintenance/checklists';
import rootChecklistMaintenanceSaga from './checklistMaintenance/checklist';
import rootJobTemplateMaintenanceSaga from './jobTemplateMaintenance/jobTemplate';
import rootJobTemplateMaintenanceActivitySaga from './jobTemplateMaintenance/activity';

import rootTechnicianSaga from './rosterMaintenance/technician';
import rootRosterEntriesSaga from './rosterMaintenance/rosterEntries';

import serviceItemTypeSaga from './serviceItemTypeMaintenance/serviceItemType';
import servicePatternSaga from './servicePatternMaintenance/servicePattern';
import serviceAgreementSaga from './serviceAgreementMaintenance/serviceAgreement';

export default function* rootSaga(): IterableIterator<Effect> {
  yield fork(watchValidationDialogChannel);
  yield fork(rootSerialNumberSaga);
  yield fork(rootCreditNotesSaga);
  yield fork(rootMarketingSaga);
  yield fork(rootStaffSaga);
  yield fork(customerSaga);
  yield fork(supplierSaga);
  yield fork(supplierContactSaga);
  yield fork(supplierDeliveryDetailsSaga);
  yield fork(supplierTransactionsSaga);
  yield fork(supplierDetailsTransactionsSaga);
  yield fork(supplierInvoiceSaga);
  yield fork(supplierUserFieldsSaga);
  yield fork(rfcEnquirySaga);
  yield fork(rfcEnquiryDetailsSaga);
  yield fork(rfcEnquiryLinesSaga);
  yield fork(invoiceDetailsSaga);
  yield fork(invoiceLinesSaga);
  yield fork(invoiceLinesLotDetailsSaga);
  yield fork(invoiceLinesSerialNumbersSaga);
  yield fork(invoiceLinesMovementsSaga);
  yield fork(contactSaga);
  yield fork(productSaga);
  yield fork(customerProductSaga);
  yield fork(addressSaga);
  yield fork(rootLookupSaga);
  yield fork(rootFormSaga);
  yield fork(rootProductLookUpSaga);
  yield fork(IEProductdetails);
  yield fork(searchSaga);
  yield fork(invoice);
  yield fork(invoiceLines);
  yield fork(saleOderEnquirySaga);
  yield fork(balancesSaga);
  yield fork(saleOrderDeliverySaga);
  yield fork(rootCustomerQuoteEnquirySaga);
  yield fork(invoiceLineBOMSaga);
  yield fork(invoiceLineSerialsSaga);
  yield fork(invoiceLineLotSaga);
  yield fork(rootBOMSaga);
  yield fork(salesAuditDetailsSaga);
  yield fork(customerInvoiceSaga);
  yield fork(commonNotepadSaga);
  yield fork(diarySaga);
  yield fork(rootTransactionSaga);
  yield fork(paymentSaga);
  yield fork(priceCheckDetailsSaga);
  yield fork(priceCheckWhySaga);
  yield fork(quotesSaga);
  yield fork(salesOrdersSaga);
  yield fork(backordersSaga);
  yield fork(depositDetailsSage);
  yield fork(orderLineDetailsSaga);
  yield fork(rootReservationDetailsSaga);
  yield fork(rootCDReservationDetailsSaga);
  yield fork(componentDetailsSaga);
  yield fork(IEStockingMovements);
  yield fork(IEStockingQuantities);
  yield fork(IEFutureBreaks);
  yield fork(IEPriceBreaks);
  yield fork(IEPrices);
  yield fork(InventoryEnquiryImagesSaga);
  yield fork(CEPricing);
  yield fork(paymentEntryDetailsSaga);
  yield fork(PCTrueCost);
  yield fork(PriceCalculatorSaga);
  yield fork(SupplierEnquiryRFCActivitySaga);
  yield fork(SupplierBalancesSaga);
  yield fork(SupplierPurchaseOrdersSaga);
  yield fork(SupplierFinancialSettingsSaga);
  yield fork(SupplierGeneralSettingsSaga);
  yield fork(SupplierAttachmentsImagesSaga);
  yield fork(productMovementSaga);
  yield fork(PSEPickSlipsSaga);
  yield fork(PSESlipLinesSaga);
  yield fork(PSEBOMSaga);
  yield fork(PickSlipsSaga);
  yield fork(ExtractMaintenance);
  yield fork(PickSlipLinesSaga);
  yield fork(SecurityPoliciesSaga);
  yield fork(PickSlipBOMSaga);
  yield fork(PickSlipBinLocations);
  yield fork(PickSlipKitComponent);
  yield fork(SupplierProductSaga);
  yield fork(SupplierProductImagesSaga);
  yield fork(SupplierProductPriceSchedule);
  yield fork(MainMenuSaga);
  yield fork(ProgramAreaSaga);
  yield fork(cashReceiptSaga);
  yield fork(allocateCashReceiptSaga);
  yield fork(purchaseOderEnquirySaga);
  yield fork(purchaseOrderLineDetailsSaga);
  yield fork(purchaseOrderBranchOrdersSaga);
  yield fork(purchaseOrders);
  yield fork(invoicesCashReceiptSaga);
  yield fork(ordersCashReceiptSaga);
  yield fork(paymentsSaga);
  yield fork(serviceActivitySaga);
  yield fork(rootActivityMaintenanceSaga);
  yield fork(rootActivityMaintenanceCapabilitySaga);
  yield fork(rootActivityMaintenanceChecklistsSaga);
  yield fork(rootJobTemplateMaintenanceSaga);
  yield fork(rootJobTemplateMaintenanceActivitySaga);
  yield fork(rootTechnicianSaga);
  yield fork(rootRosterEntriesSaga);
  yield fork(rootChecklistMaintenanceSaga);
  yield fork(serviceItemTypeSaga);
  yield fork(servicePatternSaga);
  yield fork(serviceAgreementSaga);
}
