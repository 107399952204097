import { connect } from 'react-redux';
import PurchaseOrderEnquiry from './PurchaseOrderEnquiry';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as purchaseSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  purchaseOrderDetails: purchaseSelectors.details(state),
  selectedOrder: purchaseSelectors.selected(state),
});

export default connect(mapStateToProps, {})(PurchaseOrderEnquiry);
