import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function Sigma(props: Readonly<SvgIconProps>): JSX.Element {
  return (
    <SvgIcon height='24px' viewBox='0 -960 960 960' width='24px' fill='#000' {...props}>
      <path d='M240-160v-80l260-240-260-240v-80h480v120H431l215 200-215 200h289v120H240Z' />
    </SvgIcon>);
}

export default React.memo(Sigma);
