import { createActions, asyncInitialState, asyncSelectors, asyncOnRequest, asyncOnSuccess, asyncOnError, syncSelectors } from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
    fetchSummary: depositLinesData => depositLinesData,
  }
}, 'depositDetails');


const initialState = asyncInitialState({
  selected: null,
  summary: null,
  gridOptions: {
    doubleClickActionTab: 'PaymentEntryDetails',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Payment', field: 'PaymentCode', suppressSorting: true, minWidth: 150 },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Banking entity', field: 'BankingEntity', suppressSorting: true },
    { headerName: 'Payment reference', field: 'PaymentReference', suppressSorting: true },
    { headerName: 'Bank transaction', field: 'BankTransactionNumber', suppressSorting: true },
  ],
  lines: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchSummary:
      return asyncOnRequest(state, action)

    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const summary = action.payload;
        return {
          ...data,
          summary
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.depositDetails,
  {
    lines: data => data.lines,
    selected: data => data.selected,
    summary: data => data.summary,

  }
)

const syncSelector = syncSelectors(
  (state) => state.salesOrderEnquiry.depositDetails,
  {
    isLoading: depositDetails => depositDetails.loading,
    loadingSummary: depositDetails => depositDetails.fetchSummary_loading,
    gridOptions: depositDetails => depositDetails.data.gridOptions,
    lineColumns: depositDetails => depositDetails.data.lineColumns,
  }
)

export const selectors = Object.assign({}, asyncSelector, syncSelector)