import * as React from 'react';
import Subheader from '@markinson/uicomponents-v2/Subheader';
// import { SimpleDataGrid as DataGrid } from 'components/common/DataGridDevEx/SimpleDataGrid';
import QuickEntryRow from './QuickEntryRow';
// import { SelectedGridColumns, AvailableGridColumns } from '../constants';
import { IDataGridProperties, IDataGridState } from '../interfaces';
import { createStyles, withStyles } from '@material-ui/core';
import AddIconRenderer from './AddIconRenderer';

const serialGridStyles = createStyles({
  fullyTracked: { height: '44%' }, //'calc(44% - 28px)' },
  partiallyTracked: { height: 'calc(100% - 80px)' },
  subheaderAdded: { height: 'calc(100% - 40px)' },
});

class TrackingGrid extends React.Component<IDataGridProperties, IDataGridState> {
  // innerGridRef: React.RefObject<DataGrid>;

  constructor(props: Readonly<IDataGridProperties>) {
    super(props);
    // this.innerGridRef = React.createRef<DataGrid>();
    this.state = {
      startingSerial: '',
      quantity: 0,
      frameworkComponents: { addIcon: AddIconRenderer }
    };
  }

  handleSave = (startingSerial: string, quantity?: number) => {
    this.setState(() => ({ startingSerial, quantity }), this.addToSelectedGrid);
  }

  addToSelectedGrid = () => {
    const { startingSerial, quantity } = this.state;
    const matches = startingSerial.match(/\d+$/);
    if (matches) {
      const numPart = Number(matches[0]);
      const BIGNUMBER = 9999999999;
      if (numPart > BIGNUMBER) {
        this.handleReallyBigNumber();
      } else {
        for (let i = 0; i < quantity; i++) {
          let numberPart = numPart;
          numberPart = Number(numberPart) + i;
          const serial = startingSerial.slice(0, matches.index) + numberPart.toString();
          this.addLine(serial);
        }
      }
    } else {
      for (let i = 0; i < quantity; i++) {
        const serial = i ? startingSerial + i.toString() : startingSerial;
        this.addLine(serial);
      }
    }
  }

  handleReallyBigNumber = () => {
    const { startingSerial, quantity } = this.state;
    const TEN = 10;
    const lastTen = startingSerial.substr(-TEN);
    const startPart = startingSerial.substr(0, startingSerial.length - TEN);
    const matches = lastTen.match(/\d+$/);
    for (let i = 0; i < quantity; i++) {
      let numberPart = Number(matches[0]);
      numberPart = Number(numberPart) + i;
      const numericString = numberPart.toString();
      const matchStartPart = startPart.match(/\d+$/);
      let serial = '';
      if (matchStartPart && numericString.length > TEN) {
        const carry = numericString.substr(0, numericString.length - TEN);
        const remaining = numericString.substr(-TEN);
        let num = Number(matchStartPart[0]);
        num = num + Number(carry);
        serial = startPart.slice(0, matchStartPart.index).toString() + num.toString() + remaining;
        this.addLine(serial);
      } else {
        serial = startPart + numericString;
        this.addLine(serial);
      }
    }
  }

  addLine = (serial: string) => {
    const { addSelectedLine, addFromAvailableLines, isFullyTracked } = this.props;
    if (isFullyTracked) addFromAvailableLines(serial);
    else addSelectedLine(serial);
  }

  renderQuickEntryRow = (): React.ReactNode => {
    const { isFullyTracked, params } = this.props;

    return <div>
      <QuickEntryRow
        isFullyTracked={isFullyTracked}
        handleSave={this.handleSave}
        params={params}
      />
    </div>;
  };

  handleCellClick = (event: any) => {
    if (event.colDef && event.colDef.cellRenderer) {

      switch (event.colDef.cellRenderer) {
        case 'addIcon':
          const { SerialNumber } = event.data;
          this.handleSave(SerialNumber, 1);
          break;
        default:
      }
    }
  }

  handleRemoveClick = (rows: any[]) => {
    const { removeSelectedLine } = this.props;
    if (rows[0]) removeSelectedLine(rows[0].SerialNumber);
  }

  render(): React.ReactNode {
    const { classes, isFullyTracked } = this.props;
    // const { frameworkComponents } = this.state;

    // const getContextMenuItems = (isAvailableGrid?: boolean) => {
    //   const { clearSelectedLines } = this.props;
    //   // const innerGrid = this.innerGridRef.current;
    //   let rows = [];
    //   // if (innerGrid) {
    //   //   rows = innerGrid.getSelectedRows();
    //   // }
    //   const delOption = {
    //     name: 'Delete',
    //     action: () => {

    //       // if (innerGrid) {
    //       //   this.handleRemoveClick(rows);
    //       // }
    //     },
    //     disabled: isNull(rows),
    //     icon: '<i class="material-icons" style="vertical-align:middle;font-size:19px">delete</i>'
    //   };
    //   const clearOption = {
    //     name: 'Clear',
    //     action: () => {
    //       if (clearSelectedLines) clearSelectedLines();
    //     },
    //     icon: '<i class="material-icons" style="vertical-align:middle;font-size:19px">delete</i>'
    //   };

    //   const result: any = [
    //     'copy',
    //     'copyWithHeaders',
    //     'separator',
    //     'export'
    //   ];

    //   if (!isAvailableGrid) {
    //     result.unshift('separator');
    //     result.unshift(delOption);
    //     result.unshift(clearOption);
    //   }

    //   return result;
    // };

    return (
      <div style={{ height: '100%' }}>
        {this.renderQuickEntryRow()}
        {
          isFullyTracked &&
          < div className={classes.fullyTracked}>
            <Subheader>Available serial numbers</Subheader>
            <div className={classes.subheaderAdded}>
              {/* <DataGrid
                ref={this.innerGridRef}
                loading={false}
                columnDefs={AvailableGridColumns}
                rowData={availableLines}
                getContextMenuItems={() => getContextMenuItems(true)}
                frameworkComponents={frameworkComponents}
                onCellClicked={this.handleCellClick}
              /> */}
            </div>
          </div >
        }
        < div className={isFullyTracked ? classes.fullyTracked : classes.partiallyTracked}>
          <Subheader>Selected serial numbers</Subheader>
          <div className={classes.subheaderAdded}>
            {/* <DataGrid
              ref={this.innerGridRef}
              loading={false}
              columnDefs={SelectedGridColumns}
              rowData={selectedLines}
              getContextMenuItems={() => getContextMenuItems(false)}
            /> */}
          </div>
        </div >
      </div >
    );
  }
}

export default (withStyles(serialGridStyles, { index: 1 })(TrackingGrid));
