import { IFormViewForm } from 'components/FormView';

const OrderConfirmation: IFormViewForm = {
    id: 'OrderConfirmationForm',
    layout: {
        rows: 1,
        columns: 2
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1
            },
            children: [
                {
                    id: 1,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    props: {
                        name: 'OutputAcknowledgement',
                        defaultValue: false,
                        label: 'Order acknowledgement',
                    }
                },
                {
                    id: 2,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    props: {
                        label: 'Print to printer',
                        name: 'OrderAcknowledgement',
                        size: 'large',
                        fieldId: 'OrderAcknowledgement',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputAcknowledgement'],
                    }
                },
                {
                    id: 10,
                    type: 'DIVIDER',
                    props: {
                    }
                },
                {
                    id: 3,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Order category',
                        name: 'SalesOrderCategory',
                        lookupName: 'OrderCategory',
                        isLookupTypeFixed: true,
                        size: 'small',
                    }
                },
                {
                    id: 4,
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        label: 'Sales rep',
                        name: 'SalesRepresentative',
                        lookupName: 'SalesRep',
                        placeholder: '',
                        size: 'small',

                    }
                },
                {
                    id: 5,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Customer purchase order',
                        name: 'CustomerPurchaseOrder',
                        placeholder: '',
                        size: 'large',
                        required: false
                    }
                },
            ]
        }
    ]
};

export default OrderConfirmation;
