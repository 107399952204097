import * as React from 'react';
import FormView from 'components/FormView';
import { ICashReceiptInvoicesProps, ICashReceiptInvoicesStates } from '../interfaces';
import { Operation, OPERATIONS } from 'utils/operations';
import FormViewModal from 'components/common/Modals/FormViewModal';
import BulkSelectionModalSchema from 'utils/data/forms/cash-receipting/bulk-selection-criteria.json';
import InvoiceAllocationModalSchema from 'utils/data/forms/cash-receipting/invoice-allocation.json';
import SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from 'components/common/SummaryTable/SummaryTable';
import { isNull, pathOr } from 'utils/utils';

class Invoices extends React.PureComponent<ICashReceiptInvoicesProps, ICashReceiptInvoicesStates> {

  operationMap: Map<Operation, (prevProps?: Readonly<ICashReceiptInvoicesStates>) => void>;

  constructor(props: ICashReceiptInvoicesProps) {
    super(props);
    this.state = {
      SalesEntity: ''
    };
    this.handleEnquiry = this.handleEnquiry.bind(this);
  }

  componentDidMount(): void {
    const { path, onInitialLoad, allocate } = this.props;
    if (isNull(allocate)) {
      onInitialLoad('CashReceipt');
    } else if (path === '/cash-receipting/allocate/invoices') {
      onInitialLoad('CashReceiptingInvoices');
    }
  }

  componentDidUpdate(prevProps: ICashReceiptInvoicesProps): void {
    const { operationMode, changeOperationMode } = this.props;

    if (operationMode !== prevProps.operationMode) {
      switch (operationMode) {
        case OPERATIONS.CRITERIA:
          this.handleCriteria();
          changeOperationMode(Operation.EDIT);
          break;

        case OPERATIONS.NEW:
          this.handleNew();
          changeOperationMode(Operation.EDIT);
          break;

        default:
          changeOperationMode(Operation.EDIT);

      }
    }
  }

  handleCriteria = () => {
    const { getBulkSelectionCriteria } = this.props;

    if (getBulkSelectionCriteria) {
      this.handleModalVisibility('openBulkModal', true);
      getBulkSelectionCriteria();
    }
  }

  handleNew = () => {
    const { handleOnNewInvoice, cashReceiptContext = {}, destroyForm, createInvoiceAllocation } = this.props;
    if (handleOnNewInvoice) handleOnNewInvoice({ SalesEntity: cashReceiptContext.SalesEntity, invoiceAllocation: {} });
    destroyForm('InvoiceAllocation');
    createInvoiceAllocation();
    this.handleModalVisibility('openInvoiceAllocationModal', true);
  }

  handleModalVisibility = (name: string, open: boolean) => {
    const { changeModalVisibility } = this.props;
    changeModalVisibility({ [name]: open });
  }

  getSummaryTableRenderer = () => {
    const { invoiceAllocation, invoiceAllocationLoading } = this.props;

    return () => <SummaryTable
      invoiceAllocationLoading={invoiceAllocationLoading}
      InvoiceAllocation={invoiceAllocation && invoiceAllocation.inlineObject}
      data={
        SummaryTableSchema.InvoiceAllocationSummaryTable
      }
    />;
  }

  handleEnquiry = () => {
    const { InvoiceAlloFormValues, SalesEntity = '', changeConfirmationDialog } = this.props;
    const SalesInvoice = (InvoiceAlloFormValues && InvoiceAlloFormValues.InvoiceNumber) || '';

    if (isNull(SalesInvoice) && changeConfirmationDialog) {
      changeConfirmationDialog({
        open: true,
        title: 'Alert',
        message: 'Invoice not entered.',
        okLabel: 'Ok',
        showCancel: false
      });
    } else {
      const search = new URLSearchParams();
      search.append('SalesEntity', SalesEntity.toString());
      search.append('InvoiceNumber', SalesInvoice.toString());
      window.open(`/sales-invoice-enquiry/invoice-details?${search.toString()}`);
    }
  }

  handleBulkOk = () => {
    const { applyBulkSelectionCriteria, BulkSelectionFormValues } = this.props;
    if (applyBulkSelectionCriteria && BulkSelectionFormValues) {
      applyBulkSelectionCriteria(BulkSelectionFormValues);
    }
  }

  handleInvoiceAllocationSave = () => {
    const { applySpecificInvoiceAllocation, InvoiceAlloFormValues } = this.props;
    if (applySpecificInvoiceAllocation && InvoiceAlloFormValues) {
      applySpecificInvoiceAllocation(InvoiceAlloFormValues);
    }
  }

  onInvoiceChange = (inv?: { Value?: number }) => {
    const { InvoiceAlloFormValues, updateInvoiceAllocation } = this.props;
    if (inv && inv.Value && InvoiceAlloFormValues && InvoiceAlloFormValues.InvoiceNumber !== Number(inv.Value)) {
      updateInvoiceAllocation({
        ...InvoiceAlloFormValues,
        InvoiceNumber: inv.Value,
        AllocatedReceiptedAmount: 0,
        SalesEntity: ''
      });
    }
  }

  handleOnBlur = (e: any) => {
    const { InvoiceAlloFormValues, updateInvoiceAllocation } = this.props;
    if (e.target && e.target.name === 'AllocatedReceiptedAmount') {
      if (InvoiceAlloFormValues && InvoiceAlloFormValues.InvoiceNumber) {
        updateInvoiceAllocation({
          ...InvoiceAlloFormValues,
          AllocatedReceiptedAmount: e.target.value
        });
      }
    }
  }

  render(): React.ReactNode {
    const {
      isBrowseLookUpOpen, selectedTab, cashReceipt, selectedForm, operationMode,
      summaryTableRenderer, openBulkModal, openInvoiceAllocationModal, bulkInvoicesSelections,
      bulkModalLoading = false, invoiceAllocation, invoiceAllocationLoading, SalesEntity
    } = this.props;

    const initialValues = cashReceipt && cashReceipt.inlineObject;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={null}
          summaryTableRenderer={summaryTableRenderer}
        />
        {openBulkModal && <FormViewModal
          open={openBulkModal}
          title='Bulk Selection Criteria'
          loading={bulkModalLoading}
          formProps={{
            formSchema: BulkSelectionModalSchema,
            initialValues: pathOr({}, ['inlineObject'], bulkInvoicesSelections),
            valuesSchema: pathOr({}, ['schema'], bulkInvoicesSelections),
          }}
          onClose={() => this.handleModalVisibility('openBulkModal', false)}
          actions={[
            {
              title: 'CANCEL',
              listener: () => this.handleModalVisibility('openBulkModal', false)
            },
            {
              title: 'OK',
              listener: this.handleBulkOk
            },
          ]}
        />}
        {openInvoiceAllocationModal && <FormViewModal
          open={openInvoiceAllocationModal}
          title='Invoice Allocation'
          loading={invoiceAllocationLoading}
          formProps={{
            formSchema: InvoiceAllocationModalSchema,
            style: { height: '530px' },
            initialValues: pathOr({}, ['inlineObject'], invoiceAllocation),
            valuesSchema: pathOr({}, ['schema'], invoiceAllocation),
            entity: SalesEntity,
            summaryTableRenderer: this.getSummaryTableRenderer(),
            onBlur: this.handleOnBlur,
            enableReinitialize: true,
            fieldExtensions: ({
              InvoiceNumber: {
                onSelectedItemChange: this.onInvoiceChange
              }
            })
          }}
          onClose={() => this.handleModalVisibility('openInvoiceAllocationModal', false)}
          actions={[
            {
              title: 'ENQUIRY',
              listener: this.handleEnquiry
            },
            {
              title: 'CANCEL',
              listener: () => this.handleModalVisibility('openInvoiceAllocationModal', false)
            },
            {
              title: 'SAVE',
              listener: this.handleInvoiceAllocationSave
            },
          ]}
        />}
      </React.Fragment>
    );
  }
}

export default Invoices;
