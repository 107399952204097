import { connect } from 'react-redux';
import InvoiceLineBOM from './InvoiceLineBOM';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as invoiceLineBOMSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLineBOM';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  bomDetails: invoiceLineBOMSelectors.details(state),
});

export default connect(mapStateToProps, {})(InvoiceLineBOM);
