import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import BillToIdActionList from 'utils/data/db/actions/BillToIdActionList';
import InventoryActionsList from 'utils/data/db/actions/EnquiryActionList';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';

const inlineStyles = {
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important',
    '& span.sml': {
      fontSize: '10px',
    }
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important',
    '& span.sml': {
      fontSize: '10px',
    }
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important',
    '& span.sml': {
      fontSize: '10px',
    }
  },
  icon: {
    height: '18px',
    width: '18px',
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
}
class SummaryPanel extends PureComponent {
  state = {
    open: false,
    open2: false,
    dialogOpen: false
  };
  componentDidUpdate(prevProps) {
    const { search, saleTotal, filters, getSaleDetails } = this.props;

    if (filters && (!prevProps || !prevProps.filters || prevProps.filters !== filters)) {
      const { customer, order } = filters;

      if (customer && customer.CustomerAccountNumber)
        search(customer.CustomerAccountNumber);
      if (order && order.SalesOrder) {
        saleTotal(order.SalesOrder);
        getSaleDetails(order.SalesOrder);
      }
    }
  }

  toggleMenu = (event) => {
    event.stopPropagation();
    this.setState(() => ({
      open: !this.state.open
    }));
  };

  toggleMenu2 = (event) => {
    event.stopPropagation();
    this.setState(() => ({
      open2: !this.state.open2
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      open: false,
      open2: false
    }));
  };

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  onSelectChange = (event) => {
    this.props.changePeriod(event.target.value)
  }

  onDateChange = (value) => {
    this.props.changeDate(value)
  }

  onCreditStatusCellClick = () => {
    this.handleDialogOpen();
  }


  appendParam = (actionList, details) => {
    if (details && details.schema && details.schema.CustomerId && details.schema.CustomerId.Value) {
      return actionList.map((item, index) => {
        item.actionParam = { CustomerId: details.schema.CustomerId.Value, CustomerAccountNumber: details.schema.CustomerId.Display };
        return item;
      })
    }
    return actionList;
  }

  appendParam2 = (actionList, details) => {
    if (details && details.schema && details.schema.ProductId && details.schema.ProductId.Value) {
      return actionList.map((item, index) => {
        item.actionParam = details.schema.ProductId.Value;
        return item;
      })
    }
    return actionList;
  }

  render() {
    const { classes, details, isLoading } = this.props;
    const actionList = BillToIdActionList;
    const actionList2 = InventoryActionsList;

    const checkDetails = {
      'CUSTOMER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{details && details.schema && details.schema.CustomerId.Display}</span>
          <br />
          <span >{details && details.schema && details.schema.CustomerId.Label}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {<InfoButton actionList={this.appendParam(actionList, details) || []}
            disabled={!(details && details.schema && details.schema.CustomerId.Display)}
          />
          }
        </div>
      </div>,
      'CUSTOMER PRICE CODE': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{details && details.schema && details.schema.CustomerPriceCode.Value}</span>
          <br />
          <span >{details && details.schema && details.schema.CustomerPriceCode.Label}</span>
        </div>
      </div>,
      'PRODUCT': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{details && details.schema && details.schema.ProductCode.Value}</span>
          <br />
          <span >{details && details.schema && details.schema.ProductDescription.Value}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParam2(actionList2, details) || []}
              disabled={!(details && details.schema && details.schema.ProductId.Value)}
            />
          }
        </div>
      </div>,
      'PRODUCT GROUP': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{details && details.schema && details.schema.ProductGroup.Value}</span>
          <br />
          <span >{details && details.schema && details.schema.ProductGroupDescription.Value}</span>
        </div>
      </div>,
      'STATUS': details && details.schema && details.schema.Status && details.schema.Status.Value,
    }

    const priceDetails = {
      'CURRENCY': details && details.schema && details.schema.CurrencyCode.Value,
      'SELL PRICE/UNIT': details && details.schema && `${details.schema.SellPricePerUnitExclTax.Display} <br /><span class='sml'>(EX. TAX)</span>`,
      'SELL PRICE/UNIT ': details && details.schema && `${details.schema.SellPricePerUnitInclTax.Display} <br /><span class='sml'>(INC. TAX)</span>`,
      'PRICE PER': details && details.schema && details.schema.PricePer.Value,
      'SPECIAL PRICE': details && details.schema && details.schema.IsSpecialPrice.Value ? 'Yes' : 'No',
      'DISCOUNT': details && details.schema && `${details.schema.Discount.Display}`,
      'PRICE SCHEDULE': details && details.schema && details.schema.PriceSchedule.Value,
      'EXTENDED SELL PRICE': details && details.schema && `${details.schema.ExtendedSellPriceExclTax.Display} <br /><span class='sml'>(EX. TAX)</span>`,
      'TAX TOTAL': details && details.schema && details.schema.TaxTotal.Display,
      'EXTENDED SELL PRICE ': details && details.schema && `${details.schema.ExtendedSellPriceInclTax.Display} <br /><span class='sml'>(INC. TAX)</span>`,
    }
    const numeric = (property) => ['SELL PRICE/UNIT', 'SELL PRICE/UNIT ', 'PRICE PER', 'DISCOUNT', 'EXTENDED SELL PRICE', 'TAX TOTAL', 'EXTENDED SELL PRICE '].includes(property);
    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={2} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Price check details</p>
              {(isLoading && <CircularProgress
                size={50}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <div>
                  < Table className={classes.summaryTable} >
                    <TableBody>
                      {Object.keys(checkDetails).map((property, i) => {
                        return (
                          <TableRow key={i} className={classes.summaryRow}>
                            <TableCell className={classes.headingCell}>{property}</TableCell>
                            <TableCell
                              className={classes.dataCell}>
                              {checkDetails[property]}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              }
            </Paper>
            <Paper elevation={2} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>Price details</p>
              {(isLoading && <CircularProgress
                size={50}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <div>
                  < Table className={classes.summaryTable} >
                    <TableBody>
                      {Object.keys(priceDetails).map((property, i) => {
                        return (
                          <TableRow key={i} className={classes.summaryRow}>
                            <TableCell className={classes.headingCell}>{property}</TableCell>
                            <TableCell
                              numeric={numeric(property)}
                              className={property === 'SELL PRICE/UNIT' || property === 'SELL PRICE/UNIT '
                                ? classes.totalCell :
                                numeric(property) ?
                                  classes.numericCell
                                  : classes.dataCell}>
                              <div dangerouslySetInnerHTML={{ __html: priceDetails[property] }} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);