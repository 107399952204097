import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as orderLineSelectors, actions as orderLineActions } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { search } from 'api/purchaseOrderEnquiry/purchaseOrderLines';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'OrderLines',
  columnDefs: orderLineSelectors.purchaseOrderLineColumns(state),
  // rowData: orderLineSelectors.purchaseOrderLinesData(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: orderLineSelectors.purchaseOrderLineActions(state),
  gridOptions: orderLineSelectors.gridOptions(state),
  isLoading: orderLineSelectors.isLoading(state),
  filterRow: orderLineSelectors.filterRow(state),
  selectedFilters: orderLineSelectors.selectedFilters(state),
  appliedFilters: orderLineSelectors.filterRow(state) && filterSelectors.getFilters(state, orderLineSelectors.filterRow(state).formName),
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) &&
    {
      PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
    },
  keyFields: [{ valueField: 'LineNumber', routeField: 'LineNumber' }],
  apiMethod: search
});

export const actions = {
  deleteSelectedRecords: orderLineActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: orderLineActions.setSelected,
  applyFilters: filterActions.applyFilters
}
