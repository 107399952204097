import { isDirty, reset, destroy } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { actions as filterActions } from 'ducks/common/filters';
import {
    selectors as jobTemplateSelectors,
    actions as jobTemplateActions
} from 'ducks/jobTemplateMaintenance/jobTemplate';
import { IApplicationState } from 'ducks/reducers';
import JobTemplateMaintenance from './JobTemplateMaintenance';

const FORM_NAME = 'JobTemplateForm';
const params = new URLSearchParams(location.search);
const mapStateToProps = (state: IApplicationState) => ({
    scope: uiSelectors.scope(state),
    operationMode: operationSelectors.operationMode(state),
    selectedJobTemplate: jobTemplateSelectors.selected(state),
    selectedForm: formSelectors.selected(state),
    selectedTab: uiSelectors.selectedTab(state),
    isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
    isActivityLookUpOpen: uiSelectors.isActivityLookUpOpen(state),
    isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
    TemplateCode: jobTemplateSelectors.TemplateCode(state) || params.get('TemplateCode'),
    dirty: isDirty(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
        toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
        changeOperationMode: operationActions.changeOperationMode,
        changeSelectedTab: uiActions.changeSelectedTab,
        getFormSchema: formActions.search,
        changeSelectedForm: formActions.setSelected,
        toggleMenuOption: uiActions.toggleMenuOption,
        resetForm: () => reset('JobTemplateForm'),
        changeScope: uiActions.changeScope,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        closeMenu: uiActions.closeMainMenu,
        applyFilters: filterActions.applyFilters,
        fetchJobTemplateData: jobTemplateActions.fetchJobTemplateData,
        resetSearchLookupDrawer: jobTemplateActions.reset,
        destoryForm: () => destroy('JobTemplateDetails'),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobTemplateMaintenance);
