import * as React from 'react';
import FormView from '../../FormView/index';
import { withRouter } from 'react-router-dom';
import { IAttachmentsProps } from '../interfaces';

class Attachments extends React.PureComponent<IAttachmentsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/inventory-enquiry/attachments/documents':
          onInitialLoad('Documents');
          break;
        case '/inventory-enquiry/attachments/links':
          onInitialLoad('Links');
          break;
        case '/inventory-enquiry/attachments/images':
          onInitialLoad('Images');
          break;
        default:
          onInitialLoad('Documents');
      }
    }
  }

  getInitialValues = (): any => {
    const {
      selectedTab, images, imagesLoading, schema
    } = this.props;

    switch (selectedTab) {
      case 'Images':
        return images && !imagesLoading && { initialValues: images, valuesSchema: schema };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Attachments);
