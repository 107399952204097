import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './PickSlips.actions';
import reducer, { initialState, IState } from './PickSlips.reducer';

export const PickSlipsStateContext = createContext<IState>(initialState);
export const PickSlipsDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const usePSDispatch = () => useContext(PickSlipsDispatchContext);
export const usePSState = () => useContext(PickSlipsStateContext);
export const usePSContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(PickSlipsStateContext, selector);

interface IPickSlipsStateProvider {
  children: React.ReactNode;
}

const PickSlipsStateProvider = ({ children }: IPickSlipsStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <PickSlipsStateContext.Provider value={state}>
      <PickSlipsDispatchContext.Provider value={dispatch}>
        {children}
      </PickSlipsDispatchContext.Provider>
    </PickSlipsStateContext.Provider>
  );
};

export default PickSlipsStateProvider;
