import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Invoices from './Invoices';
import { getFormValues, destroy } from 'redux-form';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as cashReceiptSelectors, actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { selectors as invoicesSelectors, actions as invoicesActions } from 'ducks/cashReceipting/invoices';
import { selectors as allocateSelectors } from 'ducks/cashReceipting/allocate';

const FORM_NAME = 'Invoices';
const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  cashReceipt: cashReceiptSelectors.cashReceipt(state),
  cashReceiptContext: cashReceiptSelectors.cashReceiptContext(state),
  openBulkModal: invoicesSelectors.openBulkModal(state),
  openInvoiceAllocationModal: invoicesSelectors.openInvoiceAllocationModal(state),
  bulkInvoicesSelections: invoicesSelectors.bulkInvoicesSelections(state),
  bulkModalLoading: invoicesSelectors.bulkModalLoading(state),
  invoiceAllocation: invoicesSelectors.invoiceAllocation(state),
  invoiceAllocationLoading: invoicesSelectors.invoiceAllocationLoading(state),
  SalesEntity: invoicesSelectors.SalesEntity(state),
  invoicesLoading: invoicesSelectors.invoicesLoading(state),
  allocate: allocateSelectors.allocate(state),
  formValues: getFormValues(FORM_NAME)(state),
  InvoiceAlloFormValues: getFormValues('InvoiceAllocation')(state),
  BulkSelectionFormValues: getFormValues('BulkSelectionCriteria')(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCashReceipt: cashReceiptActions.getCashReceipt,
    updateCashReceipt: cashReceiptActions.updateCashReceipt,
    deleteCashReceipt: cashReceiptActions.deleteCashReceipt,
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    changeModalVisibility: invoicesActions.changeModalVisibility,
    getBulkSelectionCriteria: invoicesActions.getBulkSelectionCriteria,
    applyBulkSelectionCriteria: invoicesActions.applyBulkSelectionCriteria,
    applySpecificInvoiceAllocation: invoicesActions.applySpecificInvoiceAllocation,
    handleOnNewInvoice: invoicesActions.handleOnNewInvoice,
    createInvoiceAllocation: invoicesActions.createInvoiceAllocation,
    updateInvoiceAllocation: invoicesActions.updateInvoiceAllocation,
    destroyForm: destroy
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
