import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as slipSelectors, actions as slipActions } from 'ducks/salesInvoiceEnquiry/slips';
import { fetch } from 'api/salesInvoiceEnquiry/pickSlipEnquiries';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'PickSlipsByDespatch',
  columnDefs: slipSelectors.byDespatchColumns(state),
  rowData: slipSelectors.lines(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: slipSelectors.gridOptions(state),
  isLoading: slipSelectors.isLoading(state),
  filterRow: slipSelectors.filterRow(state),
  selectedFilters: slipSelectors.selectedFilters(state),
  appliedFilters: slipSelectors.filterRow(state) && filterSelectors.getFilters(state, slipSelectors.filterRow(state).formName),
  reqParams: (invoiceSelectors.selected(state) || params.get('InvoiceNumber') || params.get('SalesEntity'))
    &&
  {
    Invoice: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).Invoice) || params.get('InvoiceNumber'),
    SalesEntity: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).SalesEntity) || params.get('SalesEntity'),
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: slipActions.setSelected,
  applyFilters: filterActions.applyFilters
}
