import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  mainDiv: {
    padding: '10px',
    width: '440px'
  },
  mainDivBin: {
    padding: '10px',
    width: '640px'
  },
  lotLine: {
    display: 'flex',
    backgroundColor: '#DDDDDD',
    padding: '10px',
    gap: '10px',
  },
  button: {
    boxShadow: 'none',
    padding: '10px',
  },
  lotLineGrid: {
    height: '350px'
  },
  disabledBtn: {
    backgroundColor: '#DDDDDD !important',
  },
  bottomRow: {
    display: 'flex',
    flexFlow: 'row',
    height: '36px',
    justifyContent: 'space-between',
  },
  headingCell: {
    backgroundColor: '#dddddd',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '75px',
    padding: '10px !important',
    flex: 1
  },
  dataCell: {
    fontSize: '12px',
    padding: '10px !important',
    backgroundColor: '#cccccc',
    color: '#000000',
    textAlign: 'right',
    border: '1px solid #fff !important',
    width: '100px',
    flex: 1
  },
  emphasizedCell: {
    fontSize: '13px',
    padding: '10px !important',
    backgroundColor: '#999999',
    color: '#000000',
    textAlign: 'right',
    border: '1px solid #fff !important',
    width: '100px',
    flex: 1
  },
});
