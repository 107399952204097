import React from 'react';
import SummaryPanel from 'components/common/SummaryPanel';
import { IPickSlipsSummaryPanel, ISummaryHandle } from './SummaryPanel.properties';
import SummaryTableSchema from '../SummaryPanel/SummaryTableSchema';
import { useRetrievePurchaseOrdersSummary } from 'api/purchaseOrders/purchaseOrders';
import { usePOContextSelector } from '../PurchaseOrders.context';

const PickSlipsSummaryPanel = (_props: IPickSlipsSummaryPanel, ref: React.Ref<ISummaryHandle>) => {

    const fetchSummary = useRetrievePurchaseOrdersSummary(usePOContextSelector<'PurchaseOrder'>((state) => state.PurchaseOrder));

    React.useImperativeHandle(
        ref,
        () => ({
            reloadSummary(): void {
                fetchSummary.refetch();
            }
        }),
        [fetchSummary]
    );

    return (
        <React.Fragment>
            <SummaryPanel
                loading={fetchSummary.isInitialLoading}
                data={fetchSummary.data?.PurchaseOrderSummary?.inlineObject}
                schema={SummaryTableSchema}
            />
        </React.Fragment>
    );
};

export default React.forwardRef(PickSlipsSummaryPanel);
