import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImageGrid, { IImageGridProperties } from './ImageGrid';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { fetch } from 'api/supplierEnquiry/attachments/images';
import { fetch as fetchImage } from 'api/inventoryEnquiry/attachments/images';
import { IApplicationState } from 'ducks/reducers';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as imageSelectors, actions as imageActions } from 'ducks/supplierEnquiry/attachments/images';
import { selectors as supplierProductImageSelectors, actions as supplierProductImageActions } from 'ducks/supplierProductEnquiry/attachments/images';
import { selectors as inventoryProductImageSelectors, actions as inventoryProductImageActions } from 'ducks/inventoryEnquiry/attachments/images';
import { selectors as supplierSelector } from 'ducks/supplierEnquiry/supplier';
import { selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';
import { selectors as inventoryProductSelectors } from 'ducks/inventoryEnquiry/inventoryProduct';

const getProps = (variant: string, state: any): any => {
  const params = new URLSearchParams(location.search);

  switch (variant) {
    case 'SupplierEnquiryImages': return {
      reqParams: (supplierSelector.selected(state) || params.get('Supplier'))
        &&
      {
        Supplier: (supplierSelector.selected(state) && supplierSelector.selected(state).Supplier) || params.get('Supplier')
      },
      filterRow: imageSelectors.filterRow(state),
      selectedFilters: imageSelectors.selectedFilters(state),
      isLoading: imageSelectors.isLoading(state),
      appliedFilters: imageSelectors.filterRow(state) && filterSelectors.getFilters(state, imageSelectors.filterRow(state).formName),
      apiMethod: fetch,
    };
    case 'SupplierProductEnquiryImages': return {
      reqParams: (supplierProductSelectors.selected(state) || params.get('SupplierProductId'))
        &&
      {
        SupplierProductId: (supplierProductSelectors.selected(state) && supplierProductSelectors.selected(state).SupplierProductId) || params.get('SupplierProductId')
      },
      filterRow: supplierProductImageSelectors.filterRow(state),
      selectedFilters: supplierProductImageSelectors.selectedFilters(state),
      isLoading: supplierProductImageSelectors.isLoading(state),
      appliedFilters: supplierProductImageSelectors.filterRow(state) && filterSelectors.getFilters(state, supplierProductImageSelectors.filterRow(state).formName),
      apiMethod: fetch,
    };
    case 'InventoryEnquiryImages': return {
      reqParams: (inventoryProductSelectors.selected(state) || params.get('ProductId'))
        &&
      {
        ProductId: (inventoryProductSelectors.selected(state) && inventoryProductSelectors.selected(state).ProductId) || params.get('ProductId')
      },
      filterRow: inventoryProductImageSelectors.filterRow(state),
      selectedFilters: inventoryProductImageSelectors.selectedFilters(state),
      isLoading: inventoryProductImageSelectors.isLoading(state),
      appliedFilters: inventoryProductImageSelectors.filterRow(state) && filterSelectors.getFilters(state, inventoryProductImageSelectors.filterRow(state).formName),
      apiMethod: fetchImage,
    };
    default: return {};
  }
};

const getActions = (variant: string): any => {
  switch (variant) {
    case 'SupplierEnquiryImages': return {
      applyFilters: imageActions.fetch
    };
    case 'SupplierProductEnquiryImages': return {
      applyFilters: supplierProductImageActions.fetch
    };
    case 'InventoryEnquiryImages': return {
      applyFilters: inventoryProductImageActions.fetch
    };
    default: return {};
  }
};

const mapStateToProps = (state: IApplicationState, props: IImageGridProperties) => ({
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  ...getProps(props.variant, state)
});

const mapDispatchToProps = (dispatch, props: IImageGridProperties) => bindActionCreators(
  {
    changeSelectedTab: uiActions.changeSelectedTab,
    changeOperationMode: operationActions.changeOperationMode,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    ...getActions(props.variant)
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageGrid);
