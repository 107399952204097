import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import AddFilterDialogForm from './AddFilterDialog.form';
import { useAddFilter, useRetrieveEMFilters } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { useEMContextSelector, useEMDispatch } from 'components/ExtractMaintenance/ExtractMaintenance.context';
import IAddFilterDialogProperties from './AddFilterDialog.properties';
import { useElementSize } from 'utils/hooks';
import { DEFAULTHEIGHT, DEFAULTWIDTH, MINIMUMHEIGHT, MINIMUMWIDTH, SCREENSIZE } from './AddFilterDialog.constants';

const AddFilterDialog = (props: IAddFilterDialogProperties) => {
    const { isFilterDialogOpen, toogleFilterDialog, filtersFormData, formValues, selectedTemplateId, changeFieldValue, isInUpdateFilter, resetForm } = props;
    const { mutateAsync: updateAddFilterMutateAsync } = useAddFilter();
    const AreaName = useEMContextSelector<'focusedArea'>((state) => state.focusedArea)?.AreaName;
    const contextDispatch = useEMDispatch();
    const [isOKDisabled, setIsOkDisabled] = React.useState(true);
    const [isBetweenOperator, setIsBetweenOperator] = React.useState(false);
    const retrieveEMFilters = useRetrieveEMFilters(selectedTemplateId);
    const { width } = useElementSize();

    React.useEffect(
        () => {
            const data = filtersFormData?.ExtractFilter?.inlineObject;
            setIsBetweenOperator(data?.Operator === 'BT');
        },
        [filtersFormData]
    );

    React.useEffect(
        () => {
            const isValidInput = validateInput(formValues?.Operator, formValues?.Value1, formValues?.Value2);
            setIsOkDisabled(!isValidInput);
        },
        [formValues]
    );

    const validateInput = (Operator?: string, value1?: string, value2?: string): boolean => {
        switch (Operator) {
            case 'BT':
                return ((value1 !== null && value1 !== '') && (value2 !== null && value2 !== ''));
            default:
                return (value1 !== null && value1 !== '');
        }
    };

    return (
        <>
            <FormViewModal
                open={isFilterDialogOpen}
                loading={false}
                title={isInUpdateFilter ? 'Update Filter' : 'Add Filter'}
                formProps={{
                    formSchema: AddFilterDialogForm,
                    initialValues: filtersFormData?.ExtractFilter?.inlineObject,
                    valuesSchema: filtersFormData?.ExtractFilter?.schema,
                    style: { height: `${width < SCREENSIZE ? MINIMUMHEIGHT : DEFAULTHEIGHT}px`, width: `${width < SCREENSIZE ? MINIMUMWIDTH : DEFAULTWIDTH}px` },
                    fieldExtensions: {
                        Operator: {
                            onChange: (_item, selectedItem) => {
                                if (selectedItem === 'BT' || selectedItem === null) {
                                    setIsBetweenOperator(true);
                                } else {
                                    setIsBetweenOperator(false);
                                    changeFieldValue('Value2', '');
                                }
                            }
                        },
                        Value2: {
                            required: isBetweenOperator,
                            disabled: !isBetweenOperator,
                            placeholder: isBetweenOperator ? '' : 'N/A'
                        }
                    },
                }}
                actions={[
                    {
                        title: 'ok',
                        disabled: isOKDisabled,
                        listener: async () => {
                            const response = await updateAddFilterMutateAsync({
                                ...formValues,
                                FieldDataType:  filtersFormData?.ExtractFilter?.inlineObject?.FieldDataType,
                                TemplateId: selectedTemplateId,
                                urlQueryParams: {
                                    AreaName: isInUpdateFilter ? formValues.Source : AreaName
                                }
                            });

                            if (response?.Status) {
                                contextDispatch({ setFocusedFieldNamesempty: true });
                                retrieveEMFilters.refetch();
                                resetForm();
                                toogleFilterDialog(false);
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        listener: () => {
                            resetForm();
                            toogleFilterDialog(false);
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </>
    );
};

export default AddFilterDialog;
