import { combineReducers } from 'redux';
import serviceAgreement, { IServiceAgreementState } from './serviceAgreement';

export interface IServiceAgreementMaintenanceState {
    serviceAgreement: IServiceAgreementState;
}
const combined = combineReducers<IServiceAgreementMaintenanceState>({
    serviceAgreement
});

export default combined;
