export const MODULE_TREE = [
  { id: 'CustomerDetails', label: 'Customer Details', parent: '' },
  { id: 'Contacts', label: 'Contacts', parent: '' },
  { id: 'DeliveryDetails', label: 'Delivery Details', parent: '' },
  { id: 'CustomerProducts', label: 'Products', parent: '' },
  { id: 'Marketing', label: 'Marketing', parent: '' },
  { id: 'UserFields', label: 'User Fields', parent: '' },
  { id: 'Lists', label: 'Lists', parent: '' },
  { id: 'Notepad', label: 'Notepad', parent: '' },
  { id: 'Financial', label: 'Financial', parent: '' },
  { id: 'CreditDiary', label: 'Credit Diary', parent: '' },
  { id: 'Sales', label: 'Sales', parent: '' },
  { id: 'SalesAnalysis', label: 'Sales Analysis', parent: '' },
  { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
  { id: 'Pricing', label: 'Pricing Details', parent: '' },
  { id: 'BillingContracts', label: 'Billing Contracts', parent: '' },
  { id: 'Balances', label: 'Balances', parent: '' },
  { id: 'CustomerChildAccounts', label: 'Child Accounts', parent: '' },
  { id: 'Transactions', label: 'Transactions', parent: '' },
  { id: 'TransactionDetails', label: 'Transaction Details', parent: 'Transactions' },
  { id: 'DetailsTransactions', label: 'Transactions', parent: 'TransactionDetails' },
  { id: 'Backorders', label: 'Backorders', parent: '' },
  { id: 'Invoices', label: 'Invoices', parent: '' },
  { id: 'Quotes', label: 'Quotes', parent: '' },
  { id: 'SalesOrders', label: 'Sales Orders', parent: '' },
  { id: 'Settings', label: 'Settings', parent: '' },
  { id: 'ServiceOrders', label: 'Service Orders', parent: '' },
  { id: 'Enquiries', label: 'Enquiries', parent: '' },
  { id: 'SettingsFinancial', label: 'Financial', parent: 'Settings' },
  { id: 'Movements', label: 'Movements', parent: '' },
  { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' },
  { id: 'CreditDiaryDetails', label: 'Diary Details', parent: 'CreditDiary' },
  { id: 'CustomerSetting', label: 'General', parent: 'Settings' }
];
