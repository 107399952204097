import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IRecallQuoteDialogProperties, IFilters } from './RecallQuoteDialog.properties';
import { recallQuoteStyles } from './RecallQuoteDialog.styles';
import { withStyles } from '@material-ui/core/styles';
import { isNull } from 'utils/utils';
import DataGrid, { LoadPanel, Column, Paging, Sorting, Selection, GroupPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import FilterRow from 'components/common/DataGridDevEx/FilterRow';
import filterRow from './RecallQuoteDialogContent.filterRow';
import { DATAGRIDCONSTANTS } from './RecallQuoteDialog.constants';
import { useRetrieveQuotes } from 'api/Worksale/worksaleLatest';
import { ENTER_KEY, ESCAPE, TAB } from 'utils/constants';
import moment from 'moment';
import { createDataSource, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { HotKeys } from 'react-hotkeys';
import { useMemoizedKeyHandlers } from 'utils/hooks';
import ToggleButton from 'components/common/ToggleButton';
import { IFormViewModelHandle } from 'components/common/Modals/FormViewModal.properties';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';

const RecallQuoteDialog = (props: IRecallQuoteDialogProperties) => {
    const { classes, open, loading, salesEntity, staff, onOk, onClose } = props;

    const customerId = useSPContextSelector(Selectors.CustomerId);

    const retrieverQuotes = useRetrieveQuotes();
    const { onKeyDown: gridOnKeyDown, onFocusedCellChanging } = useGridKeyboardNavigation();

    const gridRef = React.useRef<DataGrid>();
    const recallRef = React.useRef<HTMLButtonElement>();
    const filterButtonRef = React.useRef<HTMLButtonElement>();
    const firstToggleButtonRef = React.useRef<HTMLInputElement>();
    const formViewModalRef = React.useRef<IFormViewModelHandle>();
    const lastToggleButtonRef = React.useRef<HTMLInputElement>();

    const [selectedDocumentNumber, setSelectedDocumentNumber] = React.useState<string>('');
    const [recalculatePricingToggle, setRecalculatePricingToggle] = React.useState<boolean>(false);
    const [automaticallyCalculateToggle, setAutomaticallyCalculateToggle] = React.useState<boolean>(false);
    const [selectFirstRow, setSelectFirstRow] = React.useState<boolean>(false);
    const [filters, setFilters] = React.useState<IFilters>({
        EntityView: staff?.defaultView,
        Entity: salesEntity,
        FinalizationOption: 'Unfinalised',
    });

    React.useEffect(
        () => {
            setSelectFirstRow(true);
        },
        []
    );

    const retrieveQuotesApiCallback = React.useCallback(
        async (BatchPage, BatchSize) => {
            const FromDate = filters?.FromDate ? moment(filters?.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined;
            const ToDate = filters?.ToDate ? moment(filters?.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined;

            const formattedFilters = {
                ...filters,
                FromDate,
                ToDate
            };

            const response = await retrieverQuotes.mutateAsync({
                ...formattedFilters,
                CustomerId: customerId,
                urlQueryParams: {
                    BatchPage,
                    BatchSize
                }
            });

            return response.Quote;
        },
        [customerId, filters]
    );

    const dataSource = React.useMemo(
        () => createDataSource(
            'DocumentNumber',

            { fetch: retrieveQuotesApiCallback },
        ),
        [retrieveQuotesApiCallback]
    );

    const filterOnApply = ({ filters: appliedFilters }: { formName?: string; filters: IFilters }) => {
        setFilters(appliedFilters);
        setSelectFirstRow(true);
    };

    const onCellPrepared = React.useCallback(
        (e) => {
            const UserIDHeader = 2;
            const EnteredHeader = 3;
            const ExpiryHeader = 4;
            if (e.rowType === 'header' && (e.columnIndex === UserIDHeader || e.columnIndex === EnteredHeader || e.columnIndex === ExpiryHeader)) {
                e.cellElement.style.textAlign = 'left';
            }
        },
        []
    );

    const handleOnSelectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e?.selectedRowsData?.length > 0 ? e.selectedRowsData[0] : undefined;

            setAutomaticallyCalculateToggle(selectedRowData?.RecalculateSupplied ?? false);
            setRecalculatePricingToggle(selectedRowData?.RecalculatePricing ?? false);
            setSelectedDocumentNumber(selectedRowData?.DocumentNumber);
        },
        []
    );

    const handleOnRowDblClick = React.useCallback(
        (e) => {
            const selectedRowData = e?.data ?? undefined;

            setAutomaticallyCalculateToggle(selectedRowData?.RecalculateSupplied ?? false);
            setRecalculatePricingToggle(selectedRowData?.RecalculatePricing ?? false);
            setSelectedDocumentNumber(selectedRowData?.DocumentNumber);
        },
        []
    );

    const handleRecall = React.useCallback(
        () => {
            if (onOk) {
                onOk({
                    QuoteId: selectedDocumentNumber,
                    RecalculatePrices: recalculatePricingToggle,
                    SupplyQuantities: automaticallyCalculateToggle
                });
            }
        },
        [onOk, selectedDocumentNumber, recalculatePricingToggle, automaticallyCalculateToggle]
    );

    const onEscape = React.useCallback(
        () => {
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    const onEnter = React.useCallback(
        () => {
            if (recallRef.current &&
                gridRef.current.instance?.$element()?.[0]?.contains(document.activeElement)) {
                handleRecall();
            }
        },
        [handleRecall]
    );

    const focusFilterButton = React.useCallback(
        () => {
            if (filterButtonRef.current) {
                filterButtonRef.current.focus();
            }
        },
        [filterButtonRef.current]
    );

    const handleKeyDown = React.useCallback(
        (e) => {

            const keyboardEvent = e.event as KeyboardEvent;
            if (keyboardEvent.keyCode === TAB && !keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        firstToggleButtonRef.current?.focus();
                    },
                    0
                );

                return;
            }
            if (keyboardEvent.keyCode === TAB && keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        focusFilterButton();
                    },
                    0
                );

                return;
            }

            if (keyboardEvent.keyCode === ESCAPE) {
                onEscape();

                return;
            }
            if (keyboardEvent.keyCode === ENTER_KEY) {
                onEnter();

                return;
            }

            gridOnKeyDown(e);
        },
        [gridOnKeyDown, onEscape, focusFilterButton, firstToggleButtonRef.current]
    );

    const focusAndSelectFirstRowGrid = React.useCallback(
        () => {
            if (!gridRef.current) {
                return;
            }

            gridRef.current?.instance?.getScrollable()?.scrollTo(0);
            gridRef.current?.instance.focus();

            if (gridRef.current?.instance?.getDataSource()?.items()?.length > 0) {
                gridRef.current?.instance?.selectRowsByIndexes([0]);
            }
            setSelectFirstRow(false);
        },
        [gridRef.current, setSelectFirstRow]
    );

    const onContentReady = React.useCallback(
        () => {
            if (selectFirstRow) {
                focusAndSelectFirstRowGrid();
            }
        },
        [selectFirstRow, focusAndSelectFirstRowGrid]
    );

    const handlers = useMemoizedKeyHandlers({
        Escape: onEscape,
        Enter: onEnter
    });

    const handleLastToggleKeyDown = React.useCallback(
        (e) => {
            if (e.keyCode === TAB && !e.shiftKey) {
                formViewModalRef.current?.focusFirstButton();
                e?.preventDefault();
            }
        },
        [formViewModalRef.current]
    );

    const handleFilterButtonShiftTab = React.useCallback(
        () => {
            formViewModalRef.current?.focusLastButton();
        },
        [formViewModalRef.current]
    );

    const handleActionShiftTabOut = React.useCallback(
        () => {
            lastToggleButtonRef.current?.focus();
        },
        [lastToggleButtonRef.current]
    );

    return (
        <div>
            <HotKeys handlers={handlers}>
                <FormViewModal
                    open={open}
                    title='Recall Quote'
                    loading={loading}
                    innerRef={formViewModalRef}
                    onActionsTabOut={focusFilterButton}
                    onActionShiftTabOut={handleActionShiftTabOut}
                    modalContent={
                        <div className={classes.recallQuoteContainer}>
                            <FilterRow
                                filterButtonRef={filterButtonRef}
                                disableInitialApplyCall={true}
                                parameters={filterRow.parameters}
                                formName={filterRow.formName}
                                onApplyFilters={filterOnApply}
                                initialValues={{ ...filters }}
                                validate={filterRow.validate}
                                rowModelType={filterRow.rowModelType}
                                onFilterButtonShiftTab={handleFilterButtonShiftTab}
                            />
                            <DataGrid
                                ref={gridRef}
                                dataSource={dataSource}
                                style={{ height: 335 }}
                                allowColumnReordering={true}
                                renderAsync={true}
                                remoteOperations={true}
                                showBorders={false}
                                showColumnLines={true}
                                columnResizingMode={'nextColumn'}
                                hoverStateEnabled={true}
                                noDataText=''
                                onCellPrepared={onCellPrepared}
                                onKeyDown={handleKeyDown}
                                onSelectionChanged={handleOnSelectionChanged}
                                onFocusedCellChanging={onFocusedCellChanging}
                                onContentReady={onContentReady}
                                onRowDblClick={handleOnRowDblClick}
                            >
                                <Column dataField='DocumentNumber' caption='Document no' allowResizing={false} width={DATAGRIDCONSTANTS.header.Document} />
                                <Column dataField='Entity' caption='Entity' allowResizing={false} width={DATAGRIDCONSTANTS.header.SalesEntity} />
                                <Column dataField='UserId' caption='User ID' allowResizing={true} width={DATAGRIDCONSTANTS.header.UserId} />
                                <Column dataField='EnteredDate' caption='Entered' allowResizing={false} width={DATAGRIDCONSTANTS.header.Entered} alignment='center' />
                                <Column dataField='ExpiryDate' caption='Expiry' allowResizing={false} width={DATAGRIDCONSTANTS.header.Expiry} alignment='center' />
                                <Column dataField='Description' caption='Description' allowResizing={true} width={DATAGRIDCONSTANTS.header.Description} />
                                <Column dataField='Name' caption='Name' allowResizing={true} width={DATAGRIDCONSTANTS.header.DeliveryName} />
                                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                                <Sorting mode='none' />
                                <Selection mode={'single'} />
                                <GroupPanel visible={false} />
                                <SearchPanel visible={false} />
                                <Scrolling mode={'infinite'} />
                                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
                            </DataGrid>
                            <div className={classes.recallQuoteToogleContaner}>
                                <ToggleButton
                                    inputRef={firstToggleButtonRef}
                                    checked={recalculatePricingToggle}
                                    onChange={(e) => { setRecalculatePricingToggle(e.target.checked); }}
                                    label={'Recalculate finalised quote prices'}
                                    value={'RecalculatePricing'}
                                />
                                <ToggleButton
                                    checked={automaticallyCalculateToggle}
                                    inputRef={lastToggleButtonRef}
                                    onChange={(e) => { setAutomaticallyCalculateToggle(e.target.checked); }}
                                    label={'Automatically calculate supplied quantities'}
                                    value={'AutomaticallyCalculate'}
                                    onKeyDown={handleLastToggleKeyDown}
                                />
                            </div>
                        </div>
                    }
                    actions={[
                        {
                            title: 'Recall',
                            disabled: isNull(selectedDocumentNumber),
                            iconName: 'CheckCircle',
                            ref: recallRef,
                            isDefault: (gridRef.current?.instance?.getDataSource()?.items().length > 0),
                            listener: handleRecall
                        },
                        {
                            title: 'Cancel',
                            iconName: 'Cancel',
                            listener: () => {
                                if (onClose) {
                                    onClose();
                                }
                            },
                        },
                    ]}
                    dialogActionsButtons={true}
                    dialogActionsShadow={false}
                />
            </HotKeys>
        </div>
    );
};

export default withStyles(recallQuoteStyles)(RecallQuoteDialog);
