import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as deliveryLineSelectors, actions as deliveryLineActions } from 'ducks/salesOrderEnquiry/deliveryLine';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { fetch } from 'api/salesOrderEnquiry/salesOrderDeliveryLines';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'Deliveries',
  columnDefs: deliveryLineSelectors.deliveryLinesColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: deliveryLineSelectors.deliveryLinesActions(state),
  gridOptions: deliveryLineSelectors.gridOptions(state),
  isLoading: deliveryLineSelectors.isLoading(state),
  reqParams: (orderSelectors.selected(state) || params.get('SalesOrder')) &&
  {
    SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
  },
  keyFields: [{ valueField: 'DeliveryId', routeField: 'DeliveryId' }],
  apiMethod: fetch

});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: deliveryLineActions.setSelected
}
