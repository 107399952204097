export const MODULE_TREE = [
  { id: 'CashReceipt', label: '', parent: '' },
  { id: 'PaymentDetails', label: 'Payment Details', parent: 'CashReceipt' },
  { id: 'Allocate', label: 'Allocate', parent: 'CashReceipt' },
  { id: 'CashReceiptingInvoices', label: 'Select By Invoices', parent: 'Allocate' },
  { id: 'CashReceiptingOrders', label: 'Select By Orders', parent: 'Allocate' },
];
export const ACTION_BAR_SCOPES = {
  Allocate: 'allocate',
  CashReceipt: 'cash-receipting',
  CashReceiptingInvoices: 'cash-receipt-invoices',
  CashReceiptingOrders: 'cash-receipt-orders',
  PaymentDetails: 'payment-details'
};
