import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';

export const ActionBarIcons = {
    leftIcons: [],
    centerIcons: [],
    rightIcons: [
        {
            label: 'Cancel',
            Icon: CancelIcon,
            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
            action: 'OrderDetailsCancel',
        },
        {
            label: 'Ok',
            Icon: CheckCircle,
            action: 'OrderDetailsOk',
        }
    ]
};

export const changeConfirmationDialogDefaults = {
    open: true,
    title: 'Discard changes',
    message: 'Are you sure you want to continue?',
    okLabel: 'Discard',
    onCancel: () => undefined,
};
