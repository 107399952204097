import React, { Component } from 'react';
import DataGrid, { Column, LoadPanel } from 'devextreme-react/data-grid';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ISelectJobPanelProps } from 'components/ServiceActivityScheduling/interfaces';

const inlineStyles = createStyles({
    selectJobGrid: {
        width: 680,
        height: 600
    }
});

class SelectJobPanel extends Component<ISelectJobPanelProps> {

    render(): React.ReactNode {

        const { classes, onJobSelected, loading } = this.props;

        return (
            <div className={classes.selectJobGrid}>
                <DataGrid
                    dataSource={this.props.data}
                    noDataText=''
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    onSelectionChanged={onJobSelected}
                    paging={{
                        enabled: true,
                        pageSize: 15
                    }}
                >
                    <Column
                        caption='Job ID'
                        dataField='ServiceJobId'
                        minWidth={'20%'}
                    />
                    <Column
                        caption='Job Name'
                        dataField='Description'
                        minWidth={'30%'}
                    />
                    <Column
                        caption='Customer'
                        dataField='CustomerIdLabel'
                        minWidth={'30%'}
                    />
                    <Column
                        caption='End Date'
                        dataField='SchedulerEndDate'
                        minWidth={'20%'}
                    />
                    <LoadPanel enabled={loading} />
                </DataGrid>
            </div>
        );
    }
}

export default withStyles(inlineStyles, { index: 1 })(SelectJobPanel);
