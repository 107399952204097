import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/salesInvoiceEnquiry/InvoiceLines';

import { callApi } from '../utils';
import * as api from 'api/salesInvoiceEnquiry/invoiceLines';

function* getInvoiceLineSummary(action) {
  const { success, failure } = invoiceLineActions.saga.getInvoiceLineSummary;

  yield callApi(
    call(api.getInvoiceLineSummary, action.data),
    success,
    failure
  )
}

function* getInvoiceLineDetails(action) {
  const { success, failure } = invoiceLineActions.saga.getInvoiceLineDetails;

  yield callApi(
    call(api.getInvoiceLineDetails, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(invoiceLineTypes.getInvoiceLineSummary, getInvoiceLineSummary)
  yield takeLatest(invoiceLineTypes.getInvoiceLineDetails, getInvoiceLineDetails)
}