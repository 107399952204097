import * as React from 'react';
import FormView from 'components/FormView';
import { IRFCFields } from '../interfaces';
class RFCDetails extends React.PureComponent<IRFCFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/rfc-enquiry/rfc-details':
          onInitialLoad('RFCDetails');
          break;
        case '/rfc-enquiry/comments':
          onInitialLoad('Comments');
          break;
        case '/rfc-enquiry/summary':
          onInitialLoad('Summary');
          break;
        default:
          onInitialLoad('RFCDetails');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, selectedRFC, rfcDetails, rfcComment, rfcDetailsSummary
    } = this.props;
    switch (selectedTab) {
      case 'RFCDetails':
        return selectedRFC && rfcDetails && rfcDetails.inlineObject;
      case 'Comments':
        return selectedRFC && rfcComment && rfcComment.Comment;
      case 'Summary':
        return selectedRFC && rfcDetailsSummary;
      default:
      return selectedRFC && rfcDetails && rfcDetails.inlineObject;
    }
  }
  getValuesSchema = () => {
    const { selectedTab, rfcDetails } = this.props;
    switch (selectedTab) {
      case 'RFCDetails':
        return rfcDetails && rfcDetails.schema;
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValuesSchema();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={valuesSchema}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default RFCDetails;
