import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IServiceOrdersData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IServiceOrdersState extends IExtendedState<IServiceOrdersData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'serviceOrders');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IServiceOrdersData>({
  selected: null,
  selectedFilters: {
    EntityView: 'MPRO',
    Entity: '',
    Finalised: 'Unfinalised',
    FromDate: '',
    ToDate: '',
    CustomerPO: ''
  },
  filterRow: {
    formName: 'CEServiceOrdersFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'Finalised',
          required: false,
          options: [
            {
              value: 'Unfinalised',
              label: 'Unfinalised only'
            },
            {
              value: 'Finalised',
              label: 'Finalised only'
            },
          ]
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Customer P/O',
          name: 'CustomerPO',
          size: 'medium',
          required: false,
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    { headerName: 'Entity', field: 'Entity', type: 'numericColumn', minWidth: 100, suppressSorting: true },
    { headerName: 'Service order', field: 'ServiceOrder', minWidth: 100, suppressSorting: true },
    { headerName: 'Started', field: 'Started', minWidth: 180, suppressSorting: true },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 180, suppressSorting: true },
    { headerName: 'Order total', field: 'OrderTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true },
    { headerName: 'Project', field: 'Project', minWidth: 150, suppressSorting: true },
    { headerName: 'Order description', field: 'OrderDescription', minWidth: 250 },
    { headerName: 'Service item', field: 'ServiceItemDisplay', minWidth: 150, suppressSorting: true },
    { headerName: 'Service item name', field: 'ServiceItemName', minWidth: 100, suppressSorting: true }
  ]
});

export default (state: IServiceOrdersState = initialState, action: IDataAction): IServiceOrdersState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.selected,
  gridOptions: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.gridOptions,
  columns: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.Columns,
  Actions: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.Actions,
  filterRow: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.filterRow,
  selectedFilters: (state: { customerEnquiry: { serviceOrders: IServiceOrdersState } }) => state.customerEnquiry.serviceOrders.data.selectedFilters
});
