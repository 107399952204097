import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isDirty, } from 'redux-form';
import { actions as uiActions } from 'ducks/ui';
import Worksale from './Worksale';
import WorksaleProps from './Worksale.properties';
import { actions as formActions } from 'ducks/form';
import { actions as operationActions } from 'ducks/uiOperations';
import WorksaleStateProvider from './Worksale.context';
import { IApplicationState } from 'ducks/reducers';

const DELIVERY_DETAILS_FORM = 'DeliveryDetails';
const mapStateToProps = (state: IApplicationState) => ({
    isDeliveryDetailsDirty: isDirty(DELIVERY_DETAILS_FORM)(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeSelectedForm: formActions.setSelected,
        getFormSchema: formActions.search,
        changeOperationMode: operationActions.changeOperationMode,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeInformationDialog: uiActions.changeInformationDialog,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: WorksaleProps) => {

    return (
        <WorksaleStateProvider>
            <Worksale {...props} />
        </WorksaleStateProvider>);
});
