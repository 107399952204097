import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button, } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import { ISerialDialogProps } from './PartialSerialDialog.properties';
import styles from './PartialSerialDialog.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import TextField from '@markinson/uicomponents-v2/TextField';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { Button as MUIButton, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { IBinLotFacade, IProductLotSearchFacade, } from 'api/swaggerTypes';
import classNames from 'classnames';

import { isNull } from 'utils/utils';
import { COLUMN_SIZE, NumberOfDecimalPlaces } from '../DataGridDevEx/DataGrid.constants';
import ProductBinLookup from '../ProductBinLookup';
import { ENTER_KEY } from 'utils/constants';

const PartialSerialDialog = (props: ISerialDialogProps): JSX.Element => {
  const {
    classes, open, RequiredQuantity, BinLots, isBinTracked, ProductId, WarehouseEntity, DocumentId,
    DocumentType, LineNumber, ReservationNumber, onApply, onCancel
  } = props;
  const [enteredQuantity, setEnteredQuantity] = React.useState(0);
  const [lines, setLines] = React.useState<IBinLotFacade[]>([]);
  const [serialNumber, setSerialNumber] = React.useState<string>('');
  const [productLotValue, setProductLotValue] = React.useState<unknown>();
  const [productLotDisplay, setProductLotDisplay] = React.useState<string>();
  const [productLot, setProductLot] = React.useState<IProductLotSearchFacade>();
  const serialTextField = React.useRef<HTMLTextAreaElement | HTMLInputElement>();

  React.useEffect(
    () => { setLines(BinLots); },
    [BinLots]);

  React.useEffect(
    () => { updateQuantities(); },
    [lines]);

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        disabled: !RequiredQuantity || enteredQuantity !== RequiredQuantity,
        listener: async () => {
          if (onApply) {
            await onApply(lines);
          }
        }
      },
      {
        iconName: 'Cancel',
        isDefault: true,
        title: 'Cancel',
        listener: async () => {
          if (onCancel) {
            await onCancel();
          }
        }
      }];
  };

  const addLine = () => {
    if (isApplyDisabled) {
      return;
    }
    const newLine = {
      LotSerial: serialNumber,
      Quantity: 1,
      ...productLot
    };
    if (isNull(lines)) {
      setLines([newLine]);
    } else {
      const found = lines.find((item) => item.LotSerial.toUpperCase() === serialNumber.toUpperCase());
      if (isNull(found)) {
        setLines([...lines, newLine]);
      }
    }
    clearEntryData();
  };

  const clearEntryData = () => {
    setSerialNumber('');
    setProductLotValue(undefined);
    setProductLotDisplay('');
    setProductLot(undefined);

    if (serialTextField.current) {
      serialTextField.current.focus();
    }
  };

  const updateQuantities = () => {
    setEnteredQuantity(isNull(lines) ? 0 : lines.length);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      addLine();
    }
  };

  const isApplyDisabled = isNull(serialNumber.trim());

  const getModalContent = () => {

    const binLookupOnBlur = React.useCallback(
      (value, display, _description, record) => {
        if (value !== productLotValue) {
          if (record) {
            setProductLotValue(value);
            setProductLotDisplay(display);
            setProductLot(record);
          } else {
            setProductLotValue(undefined);
            setProductLotDisplay('');
            setProductLot(undefined);
          }
        }
      },
      [productLotValue]
    );

    const binLookupOnCloseSearchScreen = React.useCallback(
      (record: IBinLotFacade) => {
        const value = (record && record.Bin) ? record.Bin : null;

        if (value !== productLotValue) {
          if (record) {
            setProductLotValue(record.Bin);
            setProductLotDisplay(record.Bin);
            setProductLot(record);
          } else {
            setProductLotValue(undefined);
            setProductLotDisplay('');
            setProductLot(undefined);
          }
        }
      },
      [productLotValue]
    );

    return <div className={classNames({
      [classes.mainDiv]: !Boolean(isBinTracked),
      [classes.mainDivBin]: Boolean(isBinTracked)
    })}>

      <div className={classes.lotLine}>
        <TextField
          value={serialNumber}
          innerRef={serialTextField}
          label={'Serial'}
          onKeyDown={handleKeyDown}
          onChange={(value) => { setSerialNumber(value); }}
        />
        {Boolean(isBinTracked) && <ProductBinLookup
          WarehouseEntity={WarehouseEntity}
          ProductId={ProductId}
          LineNumber={LineNumber}
          ReservationNumber={ReservationNumber}
          DocumentId={DocumentId}
          DocumentType={DocumentType}
          value={productLotValue}
          display={productLotDisplay}
          PartialSerial={true}
          suppressDescription={true}
          onBlur={binLookupOnBlur}
          onCloseSearchScreen={binLookupOnCloseSearchScreen}
        />}
        <MUIButton
          onClick={addLine}
          variant={'contained'}
          disabled={isApplyDisabled}
          className={classNames(classes.button, isApplyDisabled && classes.disabledBtn)}
        >
          <CheckCircleIcon style={{ color: 'green' }} />
          APPLY
        </MUIButton>
      </div>
      <DataGrid
        keyExpr={'LotSerial'}
        className={classes.lotLineGrid}
        dataSource={lines}
        repaintChangesOnly={true}
        allowColumnReordering={true}
        remoteOperations={true}
        noDataText=''
        columnResizingMode={'nextColumn'}
        allowColumnResizing={true}
        onRowRemoved={updateQuantities}
        editing={{
          allowAdding: false,
          allowDeleting: true,
          allowUpdating: false,
          confirmDelete: false,
        }}
        showBorders={false}
        sorting={{
          mode: 'none'
        }}
        hoverStateEnabled={true}
      >
        <Column
          caption={'Serial'}
          dataField={'LotSerial'}
          fixed={true}
          allowEditing={false}
          minWidth={COLUMN_SIZE.sm1}
          width={COLUMN_SIZE.lg0}
        />
        <Column
          caption={'Bin'}
          dataField={'Bin'}
          fixed={true}
          allowEditing={false}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm1}
          width={COLUMN_SIZE.lg0}
        />
        <Column
          caption={'Bin type'}
          dataField={'BinType'}
          fixed={true}
          allowEditing={false}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm1}
          width={COLUMN_SIZE.lg0}
        />
        <Column
          type={'buttons'}
          allowResizing={false}
          fixed={true}
          minWidth={COLUMN_SIZE.sm0}
        >
          <Button name={'delete'}
            icon={'trash'}
            hint={'Remove Line'} />
        </Column>
        <GroupPanel visible={false} />
        <SearchPanel visible={false} />
        <Scrolling mode={'virtual'} />
        <LoadPanel enabled={false} />
      </DataGrid>
      <Table>
        <TableBody>
          <TableRow className={classes.bottomRow}>
            <TableCell className={classes.headingCell}>REQUIRED</TableCell>
            <TableCell className={classes.emphasizedCell}>{Number(RequiredQuantity || 0).toFixed(NumberOfDecimalPlaces)}</TableCell>
            <TableCell className={classes.headingCell}>ENTERED</TableCell>
            <TableCell className={classes.dataCell}>{Number(enteredQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>;
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Select Serial Numbers (Partial)'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(PartialSerialDialog);
