import {
    takeLatest, Effect,
    put, call, select
} from 'redux-saga/effects';
import { types as activityTypes, actions as activityActions } from 'ducks/jobTemplateMaintenance/activity';
import { selectors as jobTemplateSelectors } from 'ducks/jobTemplateMaintenance/jobTemplate';
import {
    connectSagaToApi,
    callApi
} from '../utils';
import * as api from 'api/jobTemplateMaintenance/activity';
import { IDataAction } from 'ducks/utils';
import { IJobtemplateDetailFacade } from 'api/swaggerTypes';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* postSuccessCallback(response: any, message: string): IterableIterator<Effect> {
    if (!response.Forms) {
        const selectedJobTemplate: IJobtemplateDetailFacade = yield select(jobTemplateSelectors.selected);
        const TemplateCode = selectedJobTemplate.TemplateCode;
        const ActivityCode = response.ActivityDetails && response.ActivityDetails.inlineObject.ActivityCode;
        yield put(activityActions.searchById({ TemplateCode, ActivityCode }));
        showSnackBar({ variant: 'success', message });
    }
}

function* createActivity(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = activityActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield call(postSuccessCallback, response, 'Activity added successfully.');
    }

    yield callApi(
        call(api.create, action.data),
        success,
        failure,
        { dialogActionCallEffect: createActivity, postApiSuccessCallEffect }
    );
}

function* deleteActivity(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = activityActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        if (!response.Forms) {
            const selectedJobTemplate: IJobtemplateDetailFacade = yield select(jobTemplateSelectors.selected);
            const TemplateCode = selectedJobTemplate.TemplateCode;
            yield put(activityActions.search({ TemplateCode }));
            showSnackBar({ variant: 'success', message: 'Activity deleted successfully.' });
        }
    }

    yield callApi(
        call(api.deleteActivity, action.data),
        success,
        failure,
        { dialogActionCallEffect: deleteActivity, postApiSuccessCallEffect }
    );
}

export default function* rootJobTemplateMaintenanceActivitySaga(): IterableIterator<Effect> {
    yield takeLatest(activityTypes.search, connectSagaToApi(activityActions, 'search', api.search));
    yield takeLatest(activityTypes.searchById, connectSagaToApi(activityActions, 'searchById', api.searchById));
    yield takeLatest(activityTypes.fetchActivityData, connectSagaToApi(activityActions, 'fetchActivityData', api.searchById));
    yield takeLatest(activityTypes.updateActivity, connectSagaToApi(activityActions, 'updateActivity', api.searchById));
    yield takeLatest(activityTypes.create, createActivity);
    yield takeLatest(activityTypes.delete, deleteActivity);
}
