import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';
export interface IInvoiceLinesData {
  selected: any;
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  lineColumns: IColDef[];
  lines: any[];
  lineDetails: any;
}

export interface IInvoiceLinesState extends IExtendedState<IInvoiceLinesData> {
  fetchDetails_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clearLines: () => null,
    asyncs: {
      fetchDetails: (Data) => (Data),
    }
  },
  'RFCLines');

const initialState = asyncInitialState<IInvoiceLinesData>({
  selected: {},
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    enableMasterDetailModel: true,
    masterDetailsForm: 'RFCProductDetails',
    masterDetailsValueGetter: (data?: any): any => data
  },
  deleteObj: null,
  Actions: {
  },
  lineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100 },
    {
      headerName: 'Product',
      field: 'ProductDisplay',
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductDisplay) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Return quantity', field: 'ReturnQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Unit', field: 'Unit', suppressSorting: true },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Extended', field: 'ExtendedDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Description', field: 'Description' },
    { headerName: 'Group', field: 'Group', suppressSorting: true },
    { headerName: 'Supplier product', field: 'SupplierProduct', suppressSorting: true }
  ],
  lines: [],
  lineDetails: {}
});

export default (state: IInvoiceLinesState = initialState, action: IDataAction): IInvoiceLinesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetchDetails:
      return asyncOnRequest(state, action);

    case types.saga.fetchDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          lineDetails: successAction.payload
        };
      });
    case types.saga.fetchDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { rfcEnquiry: { RFCLines: IInvoiceLinesState } }) => state.rfcEnquiry.RFCLines,
  {
    lines: (data) => data.lines,
    lineDetails: (data) => data.lineDetails,
    selected: (data) => data.selected
  }
);

const syncSelector = {
  isLoading: (state: { rfcEnquiry: { RFCLines: IInvoiceLinesState } }) => state.rfcEnquiry.RFCLines.loading,
  loadingDetails: (state: { rfcEnquiry: { RFCLines: IInvoiceLinesState } }) => state.rfcEnquiry.RFCLines.fetchDetails_loading,
  gridOptions: (state: { rfcEnquiry: { RFCLines: IInvoiceLinesState } }) => state.rfcEnquiry.RFCLines.data.gridOptions,
  lineColumns: (state: { rfcEnquiry: { RFCLines: IInvoiceLinesState } }) => state.rfcEnquiry.RFCLines.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
