import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { validURL } from 'utils/utils';

export interface ILinksData {
  selectedFilters: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
}

export interface ILinksState extends IExtendedState<ILinksData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
    }
  },
  'inventoryEnquiryLinks');

const initialState = asyncInitialState<ILinksData>({
  selectedFilters: {
    Keywords: '',
    Description: ''
  },
  filterRow: {
    formName: 'InventoryEnquiryLinksFilters',
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'Keywords',
          name: 'Keywords',
          size: 'medium',
          required: false,
        }
      },
      {
        id: 1,
        type: 'TEXT_FIELD',
        props: {
          label: 'Description',
          name: 'Description',
          size: 'medium',
          required: false,
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Link',
      field: 'Web',
      minWidth: 180,
      valueGetter: (params: any) => {
        if (params.data) {
          if (validURL(params.data.Web) && params.data.Web.includes('http')) {
            return `<a href="${params.data.Web}" target="_blank">${params.data.Web}</a>`;
          } else {
            return params.data.Web;
          }
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Description',
      field: 'Description',
      minWidth: 180
    },
    {
      headerName: 'Keywords',
      field: 'KeyWords',
      minWidth: 180
    }
  ]
});

export default (state: ILinksState = initialState, action: IDataAction): ILinksState => {
  switch (action.type) {
    default:
      return state;
  }
};

export const selectors = ({
  gridOptions: (state: { inventoryEnquiry: { attachments: { links: ILinksState } } }) => state.inventoryEnquiry.attachments.links.data.gridOptions,
  columns: (state: { inventoryEnquiry: { attachments: { links: ILinksState } } }) => state.inventoryEnquiry.attachments.links.data.Columns,
  Actions: (state: { inventoryEnquiry: { attachments: { links: ILinksState } } }) => state.inventoryEnquiry.attachments.links.data.Actions,
  filterRow: (state: { inventoryEnquiry: { attachments: { links: ILinksState } } }) => state.inventoryEnquiry.attachments.links.data.filterRow,
  selectedFilters: (state: { inventoryEnquiry: { attachments: { links: ILinksState } } }) => state.inventoryEnquiry.attachments.links.data.selectedFilters
});
