import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, IDataAction
} from './../utils';

interface IInvoiceData {
  selected: any;
  list: any[];
  summary: any;
  details: any;
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
}

export interface IInvoiceState extends IExtendedState<IInvoiceData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
  getSummary_loading?: boolean;
  getDetails_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (invoiceId) => ({ invoiceId }),
    asyncs: {
      search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
      searchById: (id: { SalesEntity: string; InvoiceNumber: number }) => (id),
      fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      getSummary: (InvoiceNumber, SalesEntity) => ({ InvoiceNumber, SalesEntity }),
      getDetails: (InvoiceNumber, SalesEntity) => ({ InvoiceNumber, SalesEntity }),
      reset: () => null
    }
  },
  'invoice');

const NOT_SELECTED = -1;

const initialState = asyncInitialState<IInvoiceData>({
  selected: NOT_SELECTED,
  list: null,
  summary: null,
  details: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: '',
});

export default (state: IInvoiceState = initialState, action: IDataAction): IInvoiceState => {

  switch (action.type) {
    case types.setSelected:
      const selected = action.data.invoiceId;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.getSummary:
    case types.getDetails:
      return asyncOnRequest(state, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action);
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const result = successAction.payload;

          return {
            ...data,
            list: result.invoices,
            nextPage: result.nextPage && result.currPage + 1,
            prevPage: result.prevPage && result.currPage - 1,
            currPage: result.currPage
          };
        },
        { fetch: true });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const result = successAction.payload;
          const newRemoval = result.invoices.length;
          const newList = data.list.concat(result.invoices);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
            nextPage: result.nextPage && result.currPage + 1,
            prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
          };
        },
        { fetch: true });
    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const result = successAction.payload;
          const newList = result.invoices.slice(0, data.removeWhenPrev).concat(data.list);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
            prevPage: result.prevPage && result.currPage - 1,
            nextPage: (newList.length > data.pageSize * data.totalPages) && (result.currPage + data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
          };
        },
        { fetch: true });
    case types.saga.getSummary.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const summary = successAction.payload;

          return {
            ...data,
            summary: summary
          };
        },
        { fetch: true });
    case types.saga.getDetails.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const details = successAction.payload;

          return {
            ...data,
            details: details
          };
        },
        { fetch: true });
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.getSummary.failure:
    case types.saga.getDetails.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
      return asyncOnError(state, action);
    case types.reset:
      return initialState;
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.key === data.selected)) || null,
    summary: (data) => data.list && data.selected && data.summary,
    details: (data) => data.list && data.selected && data.details,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
);

const syncSelector = {
  isLoading: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.search_loading || state.salesInvoiceEnquiry.invoice.searchById_loading,
  loadingNextPage: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.fetchNextPage_loading,
  loadingPrevPage: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.fetchPrevPage_loading,
  loadingSummary: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.getSummary_loading,
  loadingDetails: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.getDetails_loading,
  pageSize: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.data.pageSize,
  totalPages: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.data.totalPages,
  selectedInvoice: (state: { salesInvoiceEnquiry: { invoice: IInvoiceState } }) => state.salesInvoiceEnquiry.invoice.data.selected,
};

export const selectors = { ...asyncSelector, ...syncSelector };
