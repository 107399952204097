import { IFormViewForm } from 'components/FormView';

const DeliveryDetailsForm: IFormViewForm = {
  id: 'DeliveryDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'siteData',
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Site',
            name: 'SiteCode',
            size: 'small'
          },
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Name',
            name: 'Name',
            size: 'large',
          }
        },
        {
          id: '3',
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Default warehouse',
            name: 'DefaultWarehouse',
            size: 'small'
          }
        },
        {
          id: '4',
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Sales entity',
            name: 'SalesEntity',
            required: false,
            size: 'small'
          }
        },
        {
          id: '5',
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Primary address',
            name: 'Primary',
            required: false
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Site address',
        name: 'address',
        required: true,
      },
      children: [
        {
          type: 'ADDRESS_BLOCK_EX',
          props: {
            name: 'address'          }
        }
      ],
      position: {
        row: 1,
        col: 2
      },
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Site contact details',
        name: 'siteContactDetails',
        required: true,
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Site contact',
            name: 'Contact',
            size: 'large',
            required: false,
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Phone',
            name: 'Phone',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Fax',
            name: 'Fax',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Mobile',
            name: 'PhoneMobile',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Email',
            type: 'email',
            name: 'Email',
            required: false,
            size: 'large',
            action: {
              iconName: 'Mail',
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Site settings',
        name: 'alertInfo',
      },
      position: {
        row: 2,
        col: 2
      },
      children: [
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales territory',
            name: 'SalesTerritory',
            required: false,
            size: 'small'
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Sales rep',
            name: 'SalesRepresentative',
            required: false,
            size: 'small'
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Shipping',
        name: 'shipping',
      },
      position: {
        row: 2,
        col: 1
      },
      children: [
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            size: 'small',
            required: false
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Send via',
            name: 'FreightType',
            size: 'small'
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Carrier',
            name: 'Carrier',
            size: 'large',
            required: false,
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Freight account',
            name: 'FreightAcc',
            size: 'large',
            required: false,
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Shipping instructions',
            name: 'Shipping',
            size: 'large',
            required: false,
          }
        }
      ]
    }
  ]
};
export default DeliveryDetailsForm;
