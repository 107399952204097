import React from 'react';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, HeaderFilter, Editing, Button, RowDragging } from 'devextreme-react/data-grid';
import { IOutputFieldsGridProps } from './OutputFieldsGrid.properties';
import { withStyles } from '@material-ui/core';
import styles from './OutputFieldsGrid.styles';
import { useDeleteEMOutputField, useFetchEMOutputFields, useReorderEMOutputFields, useSetEMOutputFieldsQueryData, useUpdateEMOutputField } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { useEMContextSelector } from 'components/ExtractMaintenance/ExtractMaintenance.context';
import { ACTION_COLUMN_WIDTH, DRAG_COLUMN_WIDTH, FIELD_COLUMN_WIDTH } from '../Fields.constants';

const OutputFieldsGrid = (props: IOutputFieldsGridProps) => {
    const { classes } = props;
    const params = new URLSearchParams(location.search);
    const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);
    const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);
    const { data: outputFieldsData } = useFetchEMOutputFields(templateId);
    const { mutateAsync: reorderEMOutputFieldMutateAsync } = useReorderEMOutputFields();
    const { mutateAsync: updateEMOutputField } = useUpdateEMOutputField();
    const { mutateAsync: deleteEMOutputField } = useDeleteEMOutputField();
    const setOutputFieldsData = useSetEMOutputFieldsQueryData();
    const dataGridInnerRef = React.useRef<DataGrid>(null) as React.MutableRefObject<DataGrid | null>;

    const onDelete = React.useCallback(
        async (e: any): Promise<void> => {
            if (e?.row?.key) {
                const response = await deleteEMOutputField({
                    TemplateId: templateId,
                    ExtractId: e.row.data.ExtractId,
                    urlQueryParams: {
                        FieldName: e.row.data.FieldName
                    }
                });

                setOutputFieldsData(templateId, response);
            }
        },
        [templateId]
    );

    const onUpdate = React.useCallback(
        async (e) => {
            if (e?.changes[0]?.key) {
                const Label = e?.changes[0]?.data?.FieldLabel;
                const { FieldName, ExtractId} = e?.changes[0]?.key;
                if (Label !== outputFieldsData?.OutputFields?.find((f) => f.FieldName === FieldName)?.FieldLabel) {
                    const response = await updateEMOutputField({
                        TemplateId: templateId,
                        ExtractId,
                        urlQueryParams: {
                            FieldName,
                            Label,
                        }
                    });
                    setOutputFieldsData(templateId, response);
                } else {
                    await dataGridInnerRef.current.instance.saveEditData();
                }
                e.cancel = true;
            }
        },
        [templateId, outputFieldsData]
    );

    return (
        <DataGrid
            className={classes.OutputFieldsGrid}
            dataSource={outputFieldsData?.OutputFields}
            ref={dataGridInnerRef}
            showBorders={true}
            renderAsync={true}
            remoteOperations={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            hoverStateEnabled={true}
            noDataText=''
            columnAutoWidth={true}
            disabled={!inEditMode}
            onSaving={onUpdate}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={true}
            />
            <Column
                type='drag'
                width={DRAG_COLUMN_WIDTH}
                fixed={true}
            />
            <HeaderFilter visible />
            <Column
                dataField={'FieldNameDisplay'}
                caption={'Fields'}
                allowHeaderFiltering={false}
                width={FIELD_COLUMN_WIDTH}
                allowSorting={false}
                allowEditing={false}
            >
            </Column>
            <Column
                dataField={'FieldLabel'}
                caption={'Labels'}
                allowHeaderFiltering={false}
                minWidth={FIELD_COLUMN_WIDTH}
                allowSorting={false}
            >
            </Column>
            <Column type={'buttons'} width={ACTION_COLUMN_WIDTH} allowResizing={false} fixed={true}>
                <Button name={'save'} icon={'save'} />
                <Button name={'edit'} icon={'edit'} />
                <Button name={'delete'} icon={'trash'} onClick={onDelete} />
                <Button name={'cancel'} icon={'undo'} />
            </Column>
            <RowDragging
                allowReordering={true}
                onReorder={async (e) => {
                    const visibleRows = e.component.getVisibleRows();
                    const orderedOutputFields = [...outputFieldsData?.OutputFields];
                    const toIndex = orderedOutputFields.indexOf(visibleRows[e.toIndex].data);
                    const fromIndex = orderedOutputFields.indexOf(e.itemData);

                    orderedOutputFields.splice(fromIndex, 1);
                    orderedOutputFields.splice(toIndex, 0, e.itemData);

                    const response = await reorderEMOutputFieldMutateAsync({
                        TemplateId: templateId,
                        ExtractId: e.itemData?.ExtractId,
                        OutputFields: orderedOutputFields
                    });

                    setOutputFieldsData(Number(templateId), response);
                }}
                showDragIcons={true}
            />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        </DataGrid>
    );
};

export default withStyles(styles, { index: 1 })(OutputFieldsGrid);
