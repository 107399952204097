import { connect } from 'react-redux';
import NotePad from './NotePad';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedProduct: supplierProductSelectors.selected(state),
});

export default connect(mapStateToProps, {})(NotePad);
