import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as CatalogueSelectors, actions as CatalogueActions } from 'ducks/inventoryEnquiry/whereUsed/Catalogue'
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/whereUsed/catalogue';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'WhereUsedCatalogue',
  columnDefs: CatalogueSelectors.columns(state),
  rowData: CatalogueSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: CatalogueSelectors.gridOptions(state),
  isLoading: CatalogueSelectors.isLoading(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: CatalogueActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
