import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import customer, { ICustomerState } from './customer';
import contact from './contact';
import address from './address';
import product from './product';
import customerProduct from './customerProduct';
import ui, { IUIData } from './ui';
import uiOperations from './uiOperations';
import staff, { IStaffState } from './staff';
import lookup from './lookup';
import formSchema from './form';
import searchPanel from './searchPanel';
import actionBar from './actionBar';
import creditNotes, { ICreditNotesState } from './creditNotes';
import salesInvoiceEnquiry, { ISalesInvoiceEnquiryState } from './salesInvoiceEnquiry';
import salesOrderEnquiry from './salesOrderEnquiry';
import purchaseOrderEnquiry from './purchaseOrderEnquiry';
import purchaseOrders, { IPurchaseOrdersState } from './purchaseOrders';
import customerEnquiry, { ICustomerEnquiryState } from './customerEnquiry';
import supplierEnquiry, { ISupplierEnquiryState } from './supplierEnquiry';
import pickSlipEnquiry, { IPickSlipEnquiryState } from './pickSlipEnquiry';
import pickSlips, { IPickSlipsState } from './pickSlips';
import extractMaintenance, { IExtractMaintenanceState } from './extractMaintenance';
import supplierInvoiceEnquiry, { ISupplierInvoiceEnquiryState } from './supplierInvoiceEnquiry';
import rfcEnquiry, { IRFCEnquiryState } from './rfcEnquiry';
import customerQuoteEnquiry from './customerQuoteEnquiry';
import SearchLookUp from './SearchLookUp';
import common, { ICommonState } from './common';
import inventoryEnquiry, { IInventoryState } from './inventoryEnquiry';
import priceCheck from './priceCheck';
import pme, { IProductMovementsEnquiryState } from './pme';
import securityManagement, { ISecurityManagementState } from './securityManagement';
import supplierProductEnquiry, { ISupplierProductEnquiryState } from './supplierProductEnquiry';
import mainMenu, { IMainMenuState } from './mainMenu';
import programArea, { IProgramAreaState } from './programArea';
import cashReceipting, { ICashReceiptingState } from './cashReceipting';
import documentOutput, { IDocumentOutputState } from './documentOutput';
import paymentDetails, { IPaymentsState } from './common/paymentDetails';
import serviceActivityScheduling, { IServiceActivitySchedulingState } from './serviceActivityScheduling';
import activityMaintenance, { IActivityMaintenanceState } from './activityMaintenance';
import checklistMaintenance, { IChecklistMaintenanceState } from './checklistMaintenance';
import rosterMaintenance, { IRosterMaintenanceState } from './rosterMaintenance';
import jobTemplateMaintenance, { IJobTemplateMaintenanceState } from './jobTemplateMaintenance';
import serviceItemTypeMaintenance, { IServiceItemTypeMaintenanceState } from './serviceItemTypeMaintenance';
import servicePatternMaintenance, { IServicePatternMaintenanceState } from './servicePatternMaintenance';
import serviceAgreementMaintenance, { IServiceAgreementMaintenanceState } from './serviceAgreementMaintenance';
import appConfig, { IAppConfigState } from './appConfig';

export interface IApplicationState {
  appConfig: IAppConfigState;
  staff: IStaffState;
  ui: IUIData;
  uiOperations: any;
  priceCheck: any;
  customer: ICustomerState;
  activityMaintenance: IActivityMaintenanceState;
  checklistMaintenance: IChecklistMaintenanceState;
  rosterMaintenance: IRosterMaintenanceState;
  jobTemplateMaintenance: IJobTemplateMaintenanceState;
  creditNotes: ICreditNotesState;
  cashReceipting: ICashReceiptingState;
  salesInvoiceEnquiry: ISalesInvoiceEnquiryState;
  customerEnquiry: ICustomerEnquiryState;
  customerQuoteEnquiry: any;
  inventoryEnquiry: IInventoryState;
  SearchLookUp: any;
  supplierEnquiry: ISupplierEnquiryState;
  pickSlipEnquiry: IPickSlipEnquiryState;
  pickSlips: IPickSlipsState;
  extractMaintenance: IExtractMaintenanceState;
  purchaseOrders: IPurchaseOrdersState;
  supplierInvoiceEnquiry: ISupplierInvoiceEnquiryState;
  rfcEnquiry: IRFCEnquiryState;
  securityManagement: ISecurityManagementState;
  address: any;
  product: any;
  customerProduct: any;
  lookup: any;
  formSchema: any;
  form: any;
  searchPanel: any;
  actionBar: any;
  contact: any;
  salesOrderEnquiry: any;
  purchaseOrderEnquiry: any;
  pme: IProductMovementsEnquiryState;
  supplierProductEnquiry: ISupplierProductEnquiryState;
  mainMenu: IMainMenuState;
  programArea: IProgramAreaState;
  common: ICommonState;
  documentOutput: IDocumentOutputState;
  paymentDetails: IPaymentsState;
  serviceActivityScheduling: IServiceActivitySchedulingState;
  serviceItemTypeMaintenance: IServiceItemTypeMaintenanceState;
  servicePatternMaintenance: IServicePatternMaintenanceState;
  serviceAgreementMaintenance: IServiceAgreementMaintenanceState;
}

const appReducer = combineReducers<IApplicationState>({
  appConfig,
  ui,
  uiOperations,
  staff,
  contact,
  address,
  product,
  customerProduct,
  activityMaintenance,
  rosterMaintenance,
  jobTemplateMaintenance,
  customer,
  lookup,
  formSchema,
  form: formReducer,
  searchPanel,
  actionBar,
  creditNotes,
  purchaseOrderEnquiry,
  purchaseOrders,
  cashReceipting,
  salesInvoiceEnquiry,
  customerEnquiry,
  customerQuoteEnquiry,
  salesOrderEnquiry,
  common,
  inventoryEnquiry,
  SearchLookUp,
  priceCheck,
  supplierEnquiry,
  pickSlipEnquiry,
  pickSlips,
  extractMaintenance,
  supplierInvoiceEnquiry,
  pme,
  rfcEnquiry,
  securityManagement,
  supplierProductEnquiry,
  mainMenu,
  programArea,
  documentOutput,
  paymentDetails,
  serviceActivityScheduling,
  checklistMaintenance,
  serviceItemTypeMaintenance,
  servicePatternMaintenance,
  serviceAgreementMaintenance
});

const rootReducer = (state, action) => {
  if (action.type === 'staff_logout.success') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
