import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import renderField from '../DataGridDevEx/CustomRenderers/RenderField';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, isDirty, reset, isValid } from 'redux-form';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as lookupSelectors, actions as lookupActions } from 'ducks/lookup';
import FormView from 'components/FormView';

const styles = createStyles({
    table: {
        marginBottom: '15px'
    },
    filterCell: {
        width: '170px',
        backgroundColor: '#eeeeee',
        border: '1px solid #fff',
        padding: '10px',

    },
    filterLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        lineHeight: '34px'
    },
    valueCell: {
        backgroundColor: '#dddddd',
        border: '1px solid #fff',
        padding: '10px',
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    filterWrapper: {
        height: 'auto',
        padding: '8px 8px 16px 8px'
    },
    footer: {
        height: '25px'
    },
    applyButton: {
        flexBasis: '50%',
        boxShadow: 'none',
        borderRadius: 0,
        float: 'right',
        lineHeight: '25px'
    }
});

interface IFilterRowProperties extends StyledComponentProps {
    formName: string;
    form: string;
    isValid: boolean;
    values: any;
    hidePreFilters: boolean;
    initialValues: any;
    parameters: FormView[];
    reqParams: any;
    valuesSchema: any;
    lookupList: any;
    operationMode: string;
    rowModelType?: string;
    applyOnStart?: boolean;
    onBlur(event: any): boolean;
    reset(formName: string): void;
    fetchLookups(something: any): any;
    onApplyFilters?(filters: any): void;
}

interface IFilterRowState {
    apply: boolean;
    fieldLoading: boolean;
}

class FilterRow extends React.Component<IFilterRowProperties, IFilterRowState> {

    constructor(props: Readonly<IFilterRowProperties>) {
        super(props);

        this.state = {
            apply: false,
            fieldLoading: false
        };
    }

    componentDidMount(): void {
        const { applyOnStart = false } = this.props;
        this.setState({
            apply: applyOnStart,
            fieldLoading: applyOnStart
        });
    }

    applyFilter = () => {
        this.setState({ apply: true });
    }

    isFieldLoading = (loading: boolean) => {
        this.setState({ fieldLoading: loading });
    }

    componentDidUpdate(prevProps: Readonly<IFilterRowProperties>): void {
        const { values, reqParams, isValid: valid, onApplyFilters, reset: resetForm, formName } = this.props;
        const { fieldLoading } = this.state;
        if (this.state.apply && !fieldLoading) {
            if (valid) {
                if (onApplyFilters) {
                    onApplyFilters(values);
                }
            }
            this.setState({ apply: false });
        }
        //This is the case where the data in LookupDrawer changes and FilterRow needs update.
        if (JSON.stringify(reqParams) !== JSON.stringify(prevProps.reqParams)) {
            if (resetForm) {
                reset(formName);
            }
        }
    }

    render(): React.ReactNode {
        const { classes, parameters } = this.props;

        return (
            <div className={classes.filterWrapper}>
                <div>
                    {parameters.map((childFieldSchema, childRowId) => {
                        const isLoading = this.isFieldLoading;
                        const { valuesSchema, operationMode, onBlur, values } = this.props;
                        const stateAndProps = { valuesSchema, operationMode, onBlur, values };
                        const fieldData = { childFieldSchema, childRowId };

                        if ((childFieldSchema as any).type === 'EX_LOOKUP_FIELD') {
                            return renderField(fieldData, { ...stateAndProps, isLoading });
                        } else {
                            return renderField(fieldData, stateAndProps);
                        }
                    })}
                </div>
                <div className={classes.footer}>
                    <Button
                        className={classes.applyButton}
                        onClick={this.applyFilter}>
                        <DoneAllIcon style={{ color: 'green' }} /> APPLY
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => (
    {
        operationMode: operationSelectors.operationMode(state),
        validate: ownProps.validate,
        form: ownProps.formName,
        key: ownProps.formName,
        values: getFormValues(ownProps.formName)(state),
        dirty: isDirty(ownProps.formName)(state),
        isValid: isValid(ownProps.formName)(state),
        lookupList: lookupSelectors.all(state)
    });

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        reset: reset,
        fetchLookups: lookupActions.fetch,
    },
    dispatch);

export default compose<any, any, any>(
    connect(mapStateToProps, {}),
    reduxForm({
        enableReinitialize: true,
        destroyOnUnmount: true
    })
)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { index: 1 })(FilterRow)));
