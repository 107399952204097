import { Payload, usePost, useFetch, useFetchMutation, useDelete, APIMethod, prepareUrl } from 'api/reactQuery/queryService';
import {
    ISalesOrderDocumentDetailsResponse, ISalesOrderDocumentDetailFacade, IOutstandingOrderLineDetailSeachResponse,
    IOutstandingOrderLinesFilter, IOutstandingSalesOrderLineFacade, IOustandingDocumentDetailSearchResponse,
    IOutstandingDocumentFacade, IUpdateCommitmentCriteriaFacade, IUpdateCommitmentDetailSearchResponse,
    IUpdateCommitmentFacade, IOrderLineCommitmentSummaryResponse, IOrderLineCommitmentSummaryFacade,
    IPurchaseOrderSummaryDetailResponse, IPurchaseOrderContextLines, IOrderSummaryFacade, IOrderTotalsFacade,
    IPurchaseOrderContextCriteria, IPurchaseOrderContextResponse, IApplyPurchaseOrderContextResponse,
    IGetPurchaseOrderLinesSearchResponse, ICommitPurchaseOrderLineDetailFacade, ICommitPurchaseOrderGridLineDetailFacade,
    IPurchaseOrderGridLineDetailResponse, IUpdatePurchaseOrderLineDetailResponse, IGetPurchaseOrderLineDetailResponse, IDeliveryDetailFacade, IDeliveryDetailResponse,
    IPurchaseOrderDetailsFacade, IPurchaseOrderDetailResponse, ICommitPurchaseOrderConfirmationCriteria, IPurchaseOrderCommitDetailFacade, IPurchaseOrderCommitDetailResponse, ICancelPurchaseOrderContextResponse, ICommitPurchaseOrderConfirmationResponse, IPurchaseOrderCommitCriteriaFacade, IPurchaseOrderConfirmationFacade, ICommitSalesLinesResponse, ICommitSalesLinesCriteriaFacade
} from 'api/swaggerTypes';
import { APIRoutes } from './constants';
import { asObjectifiedInlineArray, toObjectified } from 'api/baseApi';
import { AsInlineArray, AsObjectified, Inline, IObjectified, objectify, objectifyAddress } from 'api/utils';
import { IBatchInfo } from 'components/common/DataGridDevEx/DataGrid.properties';
import { useQueryClient } from '@tanstack/react-query';

export type IObjectifiedDocumentDetailsResponse = Omit<ISalesOrderDocumentDetailsResponse, 'Document'> & {
    Document: IObjectified<ISalesOrderDocumentDetailFacade>;
};
export type IObjectifiedPurchaseOrderSummarySearcResponse = Omit<IPurchaseOrderSummaryDetailResponse, 'PurchaseSummary'> & {
    PurchaseSummary: IOrderSummaryFacade & IOrderTotalsFacade;
};

export type IObjectifiedOrderLineCommitmentSummaryResponse = Omit<IOrderLineCommitmentSummaryResponse, 'Summary'> & {
    Summary: IObjectified<IOrderLineCommitmentSummaryFacade>;
};

export type IObjectifiedPurchaseOrderLineDetailsResponse = Omit<IGetPurchaseOrderLineDetailResponse, 'PurchaseOrderLineDetail' | 'PurchaseOrderLineSummary'> & {
    PurchaseOrderLineDetail: IObjectified<ICommitPurchaseOrderLineDetailFacade>;
    PurchaseOrderLineSummary: IObjectified<ICommitPurchaseOrderLineDetailFacade>;
};

const documentDetailsSummaryResponseParser = (response) => {
    const { DocumentDetail } = response;

    return {
        ...response,
        Document: DocumentDetail ? toObjectified(DocumentDetail as Inline<ISalesOrderDocumentDetailFacade>) : null
    };
};

const orderLineCommitmentSummaryResponseParser = (response) => {
    const { OrderLineCommitmentSummary } = response;

    return {
        ...response,
        Summary: OrderLineCommitmentSummary ? toObjectified(OrderLineCommitmentSummary as Inline<IOrderLineCommitmentSummaryFacade>) : null
    };
};

const objectifiedPurchaseOrderSummary = (OrderSummary: IOrderSummaryFacade, OrderTotals: IOrderTotalsFacade) => {
    const PurchaseSummary = toObjectified({ OrderSummary, OrderTotals } as Inline<IPurchaseOrderSummaryDetailResponse>);

    return { ...PurchaseSummary?.inlineObject?.OrderSummary, ...PurchaseSummary?.inlineObject?.OrderTotals };
};

const purchaseOrderSummaryResponseParser = (response) => {
    const { OrderSummary, OrderTotals, Status } = response;

    return {
        ...response,
        PurchaseSummary: Status ? objectifiedPurchaseOrderSummary(OrderSummary, OrderTotals) : null
    };
};

export const useDocumentDetailsSummary = (SalesOrderId: string) =>
    useFetch<IObjectifiedDocumentDetailsResponse>(
        {
            url: APIRoutes.backorderCommitment.documentDetails.retrieve,
            urlParams: { SalesOrderId },
            queryConfig: { enabled: Boolean(SalesOrderId) },
            options: { inline: true, responseParser: documentDetailsSummaryResponseParser }
        }
    );

const retrieveOrderLineResponseParser = (response: IOutstandingOrderLineDetailSeachResponse): IOutstandingOrderLineDetailSeachResponse => {
    const { OutstandingOrderLines } = response;

    return {
        ...response,
        OutstandingOrderLines: OutstandingOrderLines.map((o) => objectify(o as Inline<IOutstandingSalesOrderLineFacade>))
    };
};

export const useRetrieveBackorderCommitmentLines = () =>
    usePost<Payload<IOutstandingOrderLinesFilter & { OrderNumber: string }, IBatchInfo>, IOutstandingOrderLineDetailSeachResponse>
        (APIRoutes.backorderCommitment.outstandingLines.retrieve, null, { inline: true, responseParser: retrieveOrderLineResponseParser });

const outstandingDocumentsResponseParser = (response) => {
    const { OutstandingDocuments } = response;

    return {
        ...response,
        OutstandingDocuments: OutstandingDocuments.map((o) => objectify(o as Inline<IOutstandingDocumentFacade>))
    };
};

interface IOutStandingDocuemntPayload {
    OrderNumber: string;
    LineNumber: number;
}

export const useRetreiveOutstandingDocuments = () =>
    useFetchMutation<Payload<IOutStandingDocuemntPayload, { FilterByUncommitted: string } & IBatchInfo>, IOustandingDocumentDetailSearchResponse>
        (
            APIRoutes.backorderCommitment.outstandingDocuments.retrieve, null,
            {
                inline: true,
                responseParser: outstandingDocumentsResponseParser
            }
        );

export type IObjectifiedUpdateCommitmentCriteriaFacade = AsObjectified<IUpdateCommitmentDetailSearchResponse, 'UpdateCommitment'>;

const updateOutstandingDocumentResponseParser = (response) => {
    const { UpdateCommitment } = response;

    return {
        ...response,
        UpdateCommitment: UpdateCommitment ? toObjectified(UpdateCommitment as Inline<IUpdateCommitmentFacade>) : null
    };
};

export const useUpdateOutstandingDocument = () =>
    usePost<Payload<IUpdateCommitmentCriteriaFacade & IOutStandingDocuemntPayload>, IObjectifiedUpdateCommitmentCriteriaFacade>
        (APIRoutes.backorderCommitment.outstandingDocuments.update, null, {
            inline: true,
            responseParser: updateOutstandingDocumentResponseParser
        });

export const useRetrieveCommitmentLinesSummary = (OrderNumber: string, LineNumber: number) =>
    useFetch<IObjectifiedOrderLineCommitmentSummaryResponse>(
        {
            url: APIRoutes.backorderCommitment.outstandingDocuments.retrieveSummary,
            urlParams: { OrderNumber, LineNumber },
            queryConfig: { enabled: Boolean(OrderNumber) && Boolean(LineNumber) },
            options: { inline: true, responseParser: orderLineCommitmentSummaryResponseParser }
        }
    );

const purchaseOrderContextParser = (response: IPurchaseOrderContextResponse): IPurchaseOrderContextResponse => {
    const { PurchaseOrderContext } = response;

    return {
        ...response,
        PurchaseOrderContext: PurchaseOrderContext ? toObjectified(PurchaseOrderContext as any)?.inlineObject : null
    };
};

export const usePurchaseOrderContext = () =>
    usePost<Payload<IPurchaseOrderContextLines> & { OrderNumber: string }, IPurchaseOrderContextResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderContext.retrieve, null,
            {
                inline: true,
                responseParser: purchaseOrderContextParser
            }
        );

export const useRetrievePurchaseOrderSummary = (PurchaseOrderId: number) =>
    useFetch<IObjectifiedPurchaseOrderSummarySearcResponse>(
        {
            url: APIRoutes.backorderCommitment.PurchaseOrderSummary.retrieveSummary,
            urlParams: { PurchaseOrderId },
            queryConfig: { enabled: Boolean(PurchaseOrderId) },
            options: { inline: true, responseParser: purchaseOrderSummaryResponseParser }
        }
    );

export const useCalculatePurchaseOrderContext = () =>
    usePost<Payload<IPurchaseOrderContextCriteria, { ChangedFieldId?: string }>, IPurchaseOrderContextResponse>
        (APIRoutes.backorderCommitment.purchaseOrderContext.calculate, null, {
            inline: true,
            responseParser: purchaseOrderContextParser
        }
        );

export const useApplyPurchaseOrderContext = () =>
    usePost<Payload<IPurchaseOrderContextCriteria & { OrderNumber?: string }>, IApplyPurchaseOrderContextResponse>
        (APIRoutes.backorderCommitment.purchaseOrderContext.create, null, null);

export const useRetreivePurchaseOrderLines = () =>
    useFetchMutation<Payload<{ PurchaseOrderId: number }, IBatchInfo>, IGetPurchaseOrderLinesSearchResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderLines.retrieve, null,
            {
                inline: true,
                responseParser: (response: AsInlineArray<IGetPurchaseOrderLinesSearchResponse, 'PurchaseOrderLines'>) =>
                    asObjectifiedInlineArray(response, 'PurchaseOrderLines')
            }
        );

const purchaseOrderLineDetailsResponseParser = (response) => {
    const { PurchaseOrderLineDetail, PurchaseOrderLineSummary, Status } = response;

    return {
        ...response,
        PurchaseOrderLineDetail: Status ? toObjectified(PurchaseOrderLineDetail) : null,
        PurchaseOrderLineSummary: Status ? toObjectified(PurchaseOrderLineSummary) : null
    };
};

export const useRetrievePurchaseOrderLineDetails = () =>
    useFetchMutation<Payload<{ PurchaseOrderLineId?: number }>, IObjectifiedPurchaseOrderLineDetailsResponse>
        (
            APIRoutes.backorderCommitment.PurchaseOrderLineDetails.retrieve, null,
            {
                inline: true,
                responseParser: purchaseOrderLineDetailsResponseParser
            }
        );

const gridLineResponseParser = (response) => ({
    ...response,
    PurchaseOrderGridLineDetail: toObjectified(response?.PurchaseOrderGridLineDetail as Inline<ICommitPurchaseOrderGridLineDetailFacade>)?.inlineObject
});

export const useCalculatePurchaseOrderLine = () =>
    usePost<Payload<ICommitPurchaseOrderGridLineDetailFacade, { ChangedFieldId?: string }>, IPurchaseOrderGridLineDetailResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderGrid.calculate,
            null,
            {
                inline: true,
                responseParser: gridLineResponseParser
            }
        );
export const useUpdatePurchaseOrderLine = () =>
    usePost<Payload<ICommitPurchaseOrderGridLineDetailFacade>, IPurchaseOrderGridLineDetailResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderGrid.update,
            null,
            {
                inline: true,
                responseParser: gridLineResponseParser
            }
        );

export const useDeletePurchaseOrderGridLine = () =>
    useDelete<Payload<{ PurchaseOrderLineId: number }>>(APIRoutes.backorderCommitment.purchaseOrderGrid.delete, { DeleteLine: true }, null);

export const useCalculatePurchaseOrderLineDetail = () =>
    usePost<Payload<ICommitPurchaseOrderLineDetailFacade, { ChangedFieldId?: string }>, IObjectifiedPurchaseOrderLineDetailsResponse>
        (APIRoutes.backorderCommitment.PurchaseOrderLineDetails.calculate, null, { inline: true, responseParser: purchaseOrderLineDetailsResponseParser });

export const useSavePurchaseOrderLineDetails = () =>
    usePost<Payload<ICommitPurchaseOrderLineDetailFacade> & { PurchaseOrderLineId: any }, IUpdatePurchaseOrderLineDetailResponse>
        (
            APIRoutes.backorderCommitment.PurchaseOrderLineDetails.update, null, null
        );

type IObjectifiedPurchaseOrderDetailsResponse = AsObjectified<IPurchaseOrderDetailResponse, 'PurchaseOrderDetail'>;
const purchaseOrderDetailsResponseParser = (response) => ({
    ...response,
    PurchaseOrderDetail: response?.PurchaseOrderDetail ? toObjectified(response?.PurchaseOrderDetail as Inline<IPurchaseOrderDetailsFacade>) : null,
});

export const useRetrievePurchaseOrderDetails = (PurchaseOrderId: number) =>
    useFetch<IObjectifiedPurchaseOrderDetailsResponse>(
        {
            url: APIRoutes.backorderCommitment.purchaseOrderDetail.retrieve,
            urlParams: { PurchaseOrderId },
            queryConfig: { enabled: Boolean(PurchaseOrderId) },
            options: { inline: true, responseParser: purchaseOrderDetailsResponseParser }
        }
    );

export const useUpdateOrderDetails = () =>
    usePost<Payload<IPurchaseOrderDetailsFacade> & { PurchaseOrderId: number }, IObjectifiedPurchaseOrderDetailsResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderDetail.update,
            null,
            {
                inline: true,
                responseParser: purchaseOrderDetailsResponseParser
            }
        );

interface ICalculateOrderUrlSearParams {
    ChangedFieldId: string;
    AllowPassedDate?: boolean;
    AllowFutureDate?: boolean;
}
export const useCalculateOrderDetails = () =>
    usePost<Payload<IPurchaseOrderDetailsFacade, ICalculateOrderUrlSearParams>, IObjectifiedPurchaseOrderDetailsResponse>
        (
            APIRoutes.backorderCommitment.purchaseOrderDetail.calculate,
            null,
            {
                inline: true,
                responseParser: purchaseOrderDetailsResponseParser
            }
        );

export const useSetOrderDetailsQueryData = () => {
    const queryClient = useQueryClient();

    return (PurchaseOrderId: number, dataToUodate: IObjectifiedPurchaseOrderDetailsResponse) => {
        const finalUrl = prepareUrl(APIRoutes.backorderCommitment.purchaseOrderDetail.retrieve, { PurchaseOrderId }, null, true);
        queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUodate);
    };
};

export const useSetDeliveryDetailQueryData = () => {
    const queryClient = useQueryClient();

    return (PurchaseOrderId: number, dataToUodate: IObjectifiedDeliveryDetailResponse) => {
        const finalUrl = prepareUrl(APIRoutes.backorderCommitment.PurchaseOrderDeliveryDetails.retrieveDelivery, { PurchaseOrderId }, null, true);
        queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUodate);
    };
};

export type IObjectifiedDeliveryDetailResponse = Omit<IDeliveryDetailResponse, 'DeliveryDetail'> & {
    DeliveryDetail: IObjectified<IDeliveryDetailFacade>;
};

const purchaseOrderDeliveryDetailResponseParser = (response) => {
    const DeliveryDetail = objectify(response.DeliveryDetail);

    return {
        ...response,
        DeliveryDetail: {
            inlineObject: {
                ...DeliveryDetail,
                DeliveryAddress: objectifyAddress(DeliveryDetail, 'Delivery', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country'])
            },
            schema: response.DeliveryDetail
        }
    };
};

export const useRetrievePurchaseOrderDeliveryDetail = (PurchaseOrderId: number) =>
    useFetch<IObjectifiedDeliveryDetailResponse>(
        {
            url: APIRoutes.backorderCommitment.PurchaseOrderDeliveryDetails.retrieveDelivery,
            urlParams: { PurchaseOrderId },
            queryConfig: { enabled: Boolean(PurchaseOrderId) },
            options: { inline: true, responseParser: purchaseOrderDeliveryDetailResponseParser }
        }
    );

export const useCalculatePurchaseOrderDeliveryDetails = () =>
    usePost<Payload<IDeliveryDetailFacade, { ChangedFieldId?: string }>, IObjectifiedDeliveryDetailResponse>
        (APIRoutes.backorderCommitment.PurchaseOrderDeliveryDetails.calculateDelivery, null, { inline: true, responseParser: purchaseOrderDeliveryDetailResponseParser });

export const useSavePurchaseOrderDeliveryDetails = () =>
    usePost<Payload<IDeliveryDetailFacade>, IObjectifiedDeliveryDetailResponse>
        (APIRoutes.backorderCommitment.PurchaseOrderDeliveryDetails.updateDelivery, null, { inline: true, responseParser: purchaseOrderDeliveryDetailResponseParser });

const processPurchaseOrderResponseParser = (response) => ({
    ...response,
    PurchaseOrderConfirmation: response?.PurchaseOrderConfirmation ? toObjectified(response?.PurchaseOrderConfirmation as Inline<IPurchaseOrderConfirmationFacade>) : null
});

export type IObjectifiedCommitPurchaseOrderConfirmationResponse = AsObjectified<ICommitPurchaseOrderConfirmationResponse, 'PurchaseOrderConfirmation'>;

export const useProcessPurchaseOrder = () =>
    usePost<Payload<ICommitPurchaseOrderConfirmationCriteria & { PurchaseOrderId: number }>, IObjectifiedCommitPurchaseOrderConfirmationResponse>
        (APIRoutes.backorderCommitment.processPurchaseOrder.process, null, { inline: true, responseParser: processPurchaseOrderResponseParser });
export const useCancelPurchaseOrder = () =>
    usePost<Payload<{ PurchaseOrderId: number }>, ICancelPurchaseOrderContextResponse>
        (APIRoutes.backorderCommitment.purchaseOrderContext.cancel, null, null);

export type ObjectifiedPurchaseOrderCommitDetail = Omit<IPurchaseOrderCommitDetailFacade, 'Notification'> & {
    Notification: IObjectified<IPurchaseOrderCommitDetailFacade['Notification']>;
};

export type IObjectifiedPurchaseOrderCommitDetailResponse = Omit<IPurchaseOrderCommitDetailResponse, 'PurchaseOrderCommitDetail'> & {
    PurchaseOrderCommitDetail: IObjectified<ObjectifiedPurchaseOrderCommitDetail>;
};

const purchaseOrderCommitDetailResponseParser = (response): IObjectifiedPurchaseOrderCommitDetailResponse => ({
    ...response,
    PurchaseOrderCommitDetail: {
        inlineObject: {
            ...response?.PurchaseOrderCommitDetail ? objectify(response?.PurchaseOrderCommitDetail) : null,
            Notification: response?.PurchaseOrderCommitDetail?.Notification ? toObjectified(response?.PurchaseOrderCommitDetail?.Notification) : null,
        },
        schema: response?.PurchaseOrderCommitDetail
    },
});

export const useCompletePurchaseOrder = () =>
    usePost<Payload<IPurchaseOrderCommitCriteriaFacade>, IObjectifiedPurchaseOrderCommitDetailResponse>
        (APIRoutes.backorderCommitment.purchaseOrderDetail.process, null, { inline: true, responseParser: purchaseOrderCommitDetailResponseParser });

const backOrderCommitResponseParser = (response) => ({
    ...response,
    CommitSalesLinesCriteria: response?.CommitSalesLinesCriteria ? toObjectified(response?.CommitSalesLinesCriteria as Inline<ICommitSalesLinesCriteriaFacade>)?.inlineObject : null
});

export const useBackOrderCommit = () =>
    usePost<Payload<ICommitSalesLinesCriteriaFacade & { OrderNumber: string }>, ICommitSalesLinesResponse>
        (APIRoutes.backorderCommitment.commit.create, null, { inline: true, responseParser: backOrderCommitResponseParser });
