import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import BillToIdActionList from '../../../utils/data/db/actions/BillToIdActionList';
import Scrollbars from 'react-custom-scrollbars';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import { Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProperties, ISummaryPanelState } from '../interfaces';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '80px',
    padding: '10px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '10px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '10px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '10px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '-5px'
  }
});

class SummaryPanel extends React.PureComponent<ISummaryPanelProperties, ISummaryPanelState> {
  constructor(props: Readonly<ISummaryPanelProperties>) {
    super(props);
    this.state = {
      customerActionOpen: false,
    };
  }

  toggleMenu = (event: any) => {
    event.stopPropagation();
    this.setState(() => ({
      customerActionOpen: !this.state.customerActionOpen
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      customerActionOpen: false
    }));
  };

  appendParam = (actionList: any[], orderSummary: any) => {
    if (orderSummary && orderSummary.CustomerId && orderSummary.CustomerId) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: orderSummary.CustomerId, CustomerAccountNumber: orderSummary.CustomerIdDisplay };

        return item;
      });
    }

    return actionList;
  }

  getSalesOrderDetails = (): { [key: string]: any } => {
    const actionList = BillToIdActionList;
    const { orderSummary } = this.props;

    return ({
      'SALES ORDER': <div>
        <span >{orderSummary && orderSummary.SalesOrder}</span>
        <br />
        <span >{orderSummary && orderSummary.SalesOrderDescription}</span>
      </div>,
      'CUSTOMER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{orderSummary && orderSummary.CustomerId && orderSummary.CustomerIdDisplay}</span>
          <br />
          <span >{orderSummary && orderSummary.CustomerId && orderSummary.CustomerIdLabel}</span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {actionList &&
            <InfoButton actionList={this.appendParam(actionList, orderSummary)}
              disabled={!(orderSummary && orderSummary.CustomerId)}
            />
          }
        </div>
      </div>,
      'CUSTOMER P/O': orderSummary && orderSummary.CustomerPurchaseOrder,
      'SALES ENTITY': <div>
        <span >{orderSummary && orderSummary.SalesEntity}</span>
        <br />
        <span >{orderSummary && orderSummary.SalesEntity && orderSummary.SalesEntityLabel}</span>
      </div>,
      'WAREHOUSE': <div>
        <span >{orderSummary && orderSummary.Warehouse}</span>
        <br />
        <span >{orderSummary && orderSummary.Warehouse && orderSummary.WarehouseLabel}</span>
      </div>,
      'ORDER STATUS': <div>
        <span >{orderSummary && orderSummary.OrderStatusDescription}</span>
      </div>
    });
  }

  getDates = (): { [key: string]: any } => {
    const { orderSummary } = this.props;

    return ({
      'ENTERED': orderSummary && orderSummary.EnteredDate,
      'EARLIEST SHIP': orderSummary && orderSummary.EarliestShipDate,
      'EARLIEST EXPIRY': orderSummary && orderSummary.EarliestExpiryDate,
    });
  }

  getTotals = (): { [key: string]: any } => {
    const { orderSummary } = this.props;

    return ({
      'CURRENCY': orderSummary && orderSummary.Currency,
      'OUTSTANDING': orderSummary && orderSummary.OutstandingDisplay,
      'ORDER TOTAL': orderSummary && orderSummary.OrderTotalDisplay,
    });
  }

  render(): React.ReactNode {
    const { classes, isLoading } = this.props;
    const saleOrderDetails = this.getSalesOrderDetails();

    const dates = this.getDates();

    const totals = this.getTotals();
    const numeric = (property) => ['OUTSTANDING', 'ORDER TOTAL'].includes(property);

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={PaperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Sales order details</p>
              {(isLoading && <CircularProgress
                size={CircularProgressSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(saleOrderDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell className={classes.dataCell}>
                            {saleOrderDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={PaperElevation} className={classes.subPanel} square={true}>
              {(isLoading && <CircularProgress
                size={CircularProgressSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(dates).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell className={classes.dataCell}>
                            {dates[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={PaperElevation} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>Totals</p>
              {(isLoading && <CircularProgress
                size={CircularProgressSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable}>
                  <TableBody>
                    {Object.keys(totals).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell className={property === 'ORDER TOTAL' ? classes.totalCell : numeric(property) ? classes.numericCell : classes.dataCell}>
                            {totals[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, {index: 1})(SummaryPanel);
