import { call, put } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as invoiceTypes, actions as invoiceActions } from 'ducks/common/diary';
import { actions as uiActions } from 'ducks/ui';

import { callApi } from '../utils';
import * as api from 'api/common/diary';

function* search(action){
  const {success, failure} = invoiceActions.saga.fetch;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* create(action){
  const {success, failure} = invoiceActions.saga.create;

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: create }
  )
}

function* update(action){
  const {success, failure} = invoiceActions.saga.update;

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: update }
  )
}

function* remove(action){
  const {success, failure} = invoiceActions.saga.remove;

  yield callApi(
    call(api.deleteEntry, action.data),
    success,
    failure,
    { dialogActionCallEffect: remove }
  )
}

function* searchById(action){
  const {success, failure} = invoiceActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure,
    { dialogActionCallEffect: searchById }
  )
}

function* deleteDiaryNotification(action) {
  showSnackBar({ variant: 'success', message: 'Diary deleted successfully.' });
}

export default function* rootCustomerSaga(){
  yield takeLatest(invoiceTypes.fetch, search)
  yield takeLatest(invoiceTypes.create, create)
  yield takeLatest(invoiceTypes.update, update)
  yield takeLatest(invoiceTypes.remove, remove)
  yield takeLatest(invoiceTypes.searchById, searchById)
  yield takeLatest(invoiceTypes.saga.remove.success, deleteDiaryNotification);
}