import React from 'react';
import CommonKitComponentDetails from '../../../common/KitComponents/KitComponentDetails';
import IKitComponentDetailsProperties, { IKitComponentDetailsHandle, OmittedApiTypes } from '../../../common/KitComponents/KitComponentDetails/KitComponentDetails.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import {
    Cancel as CancelIcon, CheckCircle, LibraryBooks as LibraryBooksIcon
} from '@markinson/uicomponents-v2/SvgIcons/';
import WarehouseSvg from 'assets/warehouse_black.svg';
import { useCalculateComponentLineDetail, useFetchComponentLineDetail, useUpdateComponentDetail } from 'api/Worksale/worksale';

const WarehouseIcon = () => <img style={{ width: 40, height: 40 }} src={WarehouseSvg} alt='warehouse' />;

const KitComponentDetails = (
    props: Readonly<Omit<IKitComponentDetailsProperties, OmittedApiTypes>>,
    ref: React.Ref<IKitComponentDetailsHandle>
) => {
    const { mutateAsync: calculateComponentLineDetailMutateAsync } = useCalculateComponentLineDetail();
    const { mutateAsync: fetchComponentLineDetailMutateAsync } = useFetchComponentLineDetail();
    const { mutateAsync: updateComponentDetailMutateAsync } = useUpdateComponentDetail();

    const { setActionBar } = React.useContext(ActionBarContext);
    const [enableLotSerial, setEnableLotSerial] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons: [
                    {
                        label: 'Lot/Serial',
                        Icon: LibraryBooksIcon,
                        action: 'ComponentDetailsLotSerial',
                        disabled: !enableLotSerial
                    },
                    {
                        label: 'Warehouse',
                        Icon: WarehouseIcon,
                        action: 'ComponentWarehouseSelection',
                    }
                ],
                rightIcons: [
                    {
                        label: 'Cancel',
                        Icon: CancelIcon,
                        iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                        action: 'ComponentDetailsCancel',
                    },
                    {
                        label: 'Ok',
                        Icon: CheckCircle,
                        action: 'ComponentDetailsOk',
                    }
                ]
            });
        },
        [enableLotSerial]
    );

    const fetchComponentLineDetail = React.useCallback(
        async (c) => {
            const response = await fetchComponentLineDetailMutateAsync({ ComponentId: c });
            setEnableLotSerial(response?.ComponentLineDetail?.inlineObject?.EnableLotSerial ?? false);

            return response;
        },
        []
    );

    const calculateComponentLineDetail = React.useCallback(
        async (cId, cf, cd) => {
            const response = await calculateComponentLineDetailMutateAsync({
                ...cd,
                WorksalelineId: props?.worksaleLineId,
                ComponentId: cId,
                urlQueryParams: {
                    ChangedField: cf
                }
            });
            setEnableLotSerial(response?.ComponentLineDetail?.inlineObject?.EnableLotSerial ?? false);

            return response;
        },
        [props?.worksaleLineId]
    );

    const onUpdateComponentDetail = React.useCallback(
        async (cId, cd) => {
            const response = await updateComponentDetailMutateAsync({
                ...cd,
                WorksalelineId: props?.worksaleLineId,
                ComponentId: cId
            });

            return response;
        },
        [props?.worksaleLineId]
    );

    const onUpdateBinLots = React.useCallback(
        async (componentId, componentDetail) => {
            const response = await updateComponentDetailMutateAsync({
                ...componentDetail,
                WorksalelineId: props?.worksaleLineId,
                ComponentId: componentId
            });

            return response;
        },
        [props?.worksaleLineId]
    );

    return (<CommonKitComponentDetails
        forwardedRef={ref}
        enableLotSerial={enableLotSerial}
        fetchComponentDetail={fetchComponentLineDetail}
        calculateComponentLineDetail={calculateComponentLineDetail}
        onUpdateBinLots={onUpdateBinLots}
        onUpdateComponentDetail={onUpdateComponentDetail}
        {...props} />);
};

export default React.forwardRef(KitComponentDetails);
