import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as notepadTypes, actions as notepadActions } from 'ducks/common/notepad';

import { callApi } from '../utils';
import * as api from 'api/common/notepad';


function* getNote(action) {
  const { success, failure } = notepadActions.saga.getNote;

  yield callApi(
    call(api.getNote, action.data),
    success,
    failure,
    { dialogActionCallEffect: getNote }
  )
}
function* setNote(action) {
  const { success, failure } = notepadActions.saga.setNote;

  yield callApi(
    call(api.setNote, action.data),
    success,
    failure,
    { dialogActionCallEffect: setNote }
  )
}
export default function* notepadSaga() {
  yield takeLatest(notepadTypes.getNote, getNote);
  yield takeLatest(notepadTypes.setNote, setNote);
}