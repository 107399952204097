import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@markinson/uicomponents-v2/SvgIcons';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField/BasicLookupActionField';
import { IPurchaseOrderData, IBackorderCommitmentLookupPanelProperties } from './BackorderCommitment.properties';
import inlineStyles from './BackorderCommitment.styles';
import LookupPanel from 'components/common/LookupPanel';
import LoadingButton from 'components/common/LoadingButton';
import { isNull } from 'utils/utils';
import { isSuccess } from 'api/utils';
import { useAsyncClickHandler } from 'utils/hooks';

const defaultFormState = {
    Supplier: null,
    PurchaseOrder: null
};

const TIMEOUT_DELAY = 300;

function BackorderCommitmentLookupPanel(props: IBackorderCommitmentLookupPanelProperties): JSX.Element {
    const { classes, open, formData, loading, disabled, onfieldChange, setOpen, onApply = () => undefined, onUndo = () => undefined, autoGeneratePO } = props;

    const supplierLookupRef = React.useRef<IBasicLookupFieldHandle>(null);
    const poLookupRef = React.useRef<IBasicLookupFieldHandle>(null);

    const [form, setFormValues] = React.useState<IPurchaseOrderData>(formData ?? defaultFormState);
    const [isLookupError, setIsLookupError] = React.useState<{ [x: string]: boolean }>({});
    const [lastCalculateSucceeded, setLastCalculateSucceeded] = React.useState<boolean>(true);
    const calculateRequested = React.useRef(false);

    const isAnyLookupsHasError = React.useCallback(() => Object.values(isLookupError).some((item) => item), [isLookupError]);

    React.useEffect(
        () => {
            if (supplierLookupRef.current) {
                supplierLookupRef.current.focus();
            }
        },
        [loading]
    );

    React.useEffect(
        () => {
            setFormValues(formData);
        },
        [formData]
    );

    const calculateFormField = React.useCallback(
        async (name: string, value) => {
            if (value?.toString() === form?.[name]?.toString()) {
                return;
            }

            const response = await onfieldChange(name, value, form);
            setLastCalculateSucceeded(isSuccess(response) && !response.Forms);

            setFormValues((prevForm) => ({
                ...prevForm,
                [name]: value,
                ...response ? { SupplierDisplay: (response?.PurchaseOrderContext as any)?.SupplierIdDisplay } : {}
            }));

            if (name === 'PurchaseOrder' && response?.Forms) {
                setTimeout(
                    () => {
                        poLookupRef.current?.focus();
                    },
                    TIMEOUT_DELAY
                );
            }
        },
        [form, formData, onfieldChange, setLastCalculateSucceeded]
    );

    const updateFormField = React.useCallback(
        async (name: string, value: any) => {
            calculateRequested.current = false;

            setFormValues((prevForm) => ({
                ...prevForm,
                [name]: value,
            }));

            if (!(name === 'PurchaseOrder' && autoGeneratePO)) {
                await calculateFormField(name, value);
            }

            if (name === 'Supplier' && isNull(value)) {
                updateFormField('PurchaseOrder', null);
            }
        },
        [autoGeneratePO, setFormValues, calculateFormField]
    );

    const validationPassed = () => !isNull(form?.Supplier) && (autoGeneratePO || !isNull(form?.PurchaseOrder));

    const handleOnApply = React.useCallback(
        () => {
            if (onApply && validationPassed() && !isAnyLookupsHasError()) {
                onApply(form);
            }
        },
        [form, onApply, validationPassed, isAnyLookupsHasError]
    );

    const { onClick: onApplyClick } = useAsyncClickHandler(
        handleOnApply,
        !loading && !calculateRequested.current && lastCalculateSucceeded
    );

    const handleUndo = React.useCallback(
        () => {
            setFormValues(defaultFormState);
            setLastCalculateSucceeded(true);
            if (onUndo) {
                onUndo(defaultFormState);
            }
        },
        [onUndo, setFormValues, setLastCalculateSucceeded]
    );

    const handleLookupError = React.useCallback(
        (fieldName: string, error: boolean) => {
            setIsLookupError({ ...isLookupError, [fieldName]: error });
        },
        [setIsLookupError, isLookupError]
    );

    const lookupOnBlur = (name: string, value: string) => {
        if (value && value !== form?.[name]) {
            calculateRequested.current = true;
        }
    };

    return (
        <div className={classes.backorderCommitmentLookupPanel}>
            <LookupPanel open={open} setOpen={setOpen}>
                <Paper style={inlineStyles.lookupFieldsPaperContainer} square={true}>
                    <BasicLookupActionField
                        className={classes.customerLookupField}
                        placeholder={'Supplier'}
                        label={''}
                        size={'large'}
                        searchScreenTitle={'Supplier Lookup'}
                        lookupName={'Supplier'}
                        value={form?.Supplier?.toString()}
                        onSelectedItemChange={(v, inputText) => {
                            setFormValues((pre) => ({ ...pre, SupplierDisplay: v?.Display }));
                            updateFormField('Supplier', v ? v?.Code : inputText);
                        }}
                        suppressDescription={true}
                        disabled={loading || disabled}
                        ref={supplierLookupRef}
                        hasError={(value) => { handleLookupError('SupplierLookup', value); }}
                        onBlur={(v) => lookupOnBlur('SupplierDisplay', v)}
                    />
                    <BasicLookupActionField
                        className={classes.customerLookupField}
                        label={'Purchase Order'}
                        size={'large'}
                        ref={poLookupRef}
                        searchScreenTitle={'Purchase Order Lookup'}
                        lookupName={'SupplierPurchaseOrderInPreparing'}
                        value={form?.PurchaseOrder}
                        onSelectedItemChange={(v, inputText) => { updateFormField('PurchaseOrder', v ? v.Code : inputText); }}
                        suppressDescription={true}
                        isSupplierScoped={true}
                        suppressValidation={!autoGeneratePO}
                        displayField={'Code'}
                        disabled={loading || disabled || !Boolean(form?.Supplier)}
                        hasError={(value) => { handleLookupError('PurchaseOrderLookup', value); }}
                        onBlur={(v) => lookupOnBlur('PurchaseOrder', v)}
                        params={{ Supplier: Number(form?.Supplier) }} />
                </Paper>
                <Paper style={inlineStyles.commitActionPaperContainer} square={true}>
                    <Button
                        onMouseDown={handleUndo}
                        variant={'contained'}
                        disabled={disabled}
                        className={classes.commitActionButton}>
                        <CancelIcon style={{ color: 'red' }} /> UNDO
                    </Button>
                    <LoadingButton
                        onMouseDown={() => {
                            setTimeout(
                                () => {
                                    onApplyClick();
                                }
                            );
                        }}
                        variant={'contained'}
                        disabled={loading || !validationPassed() || disabled}
                        className={classes.commitActionButton}
                        loading={loading}
                        startIcon={<CheckCircleIcon style={{ color: 'green' }} />}>
                        APPLY
                    </LoadingButton>
                </Paper>
            </LookupPanel>
        </div>
    );
}

export default withStyles(inlineStyles, { index: 1 })(React.memo(React.forwardRef(BackorderCommitmentLookupPanel)));
