import React from 'react';
import CommonKitComponentDetails from '../../../common/KitComponents/KitComponentDetails';
import IKitComponentDetailsProperties, { IKitComponentDetailsHandle, OmittedApiTypes } from '../../../common/KitComponents/KitComponentDetails/KitComponentDetails.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import {
    Cancel as CancelIcon, CheckCircle, LibraryBooks
} from '@markinson/uicomponents-v2/SvgIcons/';
import { useCalculateComponentLineDetails, useFetchKitComponentLineDetail, useUpdateComponentLineDetails } from 'api/pickSlips/components';
import { usePSDispatch } from 'components/PickSlips/PickSlips.context';
import { useUpdateProductWIPLotSerial } from 'api/product/productSearchUpdated';

const KitComponentDetails = (
    props: Readonly<Omit<IKitComponentDetailsProperties, OmittedApiTypes>>,
    ref: React.Ref<IKitComponentDetailsHandle>
) => {
    const { onPicking, onOk, onCancel } = props;
    const { setActionBar } = React.useContext(ActionBarContext);
    const [enablePicking, setEnablePicking] = React.useState<boolean>();

    const contextDispatch = usePSDispatch();
    const updateLotSerialMutation = useUpdateProductWIPLotSerial();
    const retrievePickslipComponentLineDetailsMutation = useFetchKitComponentLineDetail();
    const updateComponentLineDetailsMutation = useUpdateComponentLineDetails();
    const calculateComponentLineDetailsMutation = useCalculateComponentLineDetails();
    const retrievePickslipComponentDetailsInline = retrievePickslipComponentLineDetailsMutation.data?.ComponentLineDetail?.inlineObject?.ComponentDetail;

    const centerIcons = [
        {
            label: 'Picking',
            Icon: LibraryBooks,
            action: 'ComponentDetailsPicking',
            iconStyle: { fill: undefined },
            disabled: !enablePicking,
            callBack: onPicking
        }
    ];

    const rightIcons = [
        {
            label: 'Cancel',
            Icon: CancelIcon,
            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
            action: 'KitComponentDetailsCancel',
            callBack: onCancel
        },
        {
            label: 'OK',
            Icon: CheckCircle,
            action: 'KitComponentDetailsOk',
            callBack: onOk
        },
    ];

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons,
                rightIcons
            });
        },
        [enablePicking, onOk, onCancel, onPicking]
    );

    const fetchComponentLineDetail = React.useCallback(
        async (componentId: number) => {
            if (componentId) {
                const response = await retrievePickslipComponentLineDetailsMutation.mutateAsync({
                    ComponentId: componentId
                });

                if (response?.Status === true) {
                    setEnablePicking(response?.ComponentLineDetail?.inlineObject?.EnableLotSerial);

                    return response;
                }
            }

            return null;
        },
        []
    );

    const navigateBack = React.useCallback(
        () => {
            contextDispatch({
                setComponentLineNumber: null,
                setComponentId: null,
            });
            if (props.navigateBack) {
                props.navigateBack();
            }
        },
        [props.navigateBack]
    );

    const calculateComponentLineDetail = React.useCallback(
        async (componentId, changedField, detail) => {

            const response = await calculateComponentLineDetailsMutation.mutateAsync({
                ComponentId: componentId, ...detail,
                urlQueryParams: {
                    ChangedField: changedField
                }
            });

            setEnablePicking(response?.ComponentLineDetail?.inlineObject?.ComponentDetail?.EnableLotSerial ?? false);

            return response;
        },
        []
    );
    const onUpdateComponentDetail = React.useCallback(
        (componentId, detail) => updateComponentLineDetailsMutation.mutateAsync({
            ComponentId: componentId, ...detail?.ComponentDetail,
        }),
        []
    );

    const updateLotSerial = React.useCallback(
        async (componentId, detail) => updateLotSerialMutation.mutateAsync({
            BinLot: detail?.BinLot,
            urlQueryParams: {
                DocumentId: componentId,
                RequiredQuantity: detail?.ComponentDetail?.SuppliedQuantity,
                ProductId: detail?.ComponentDetail?.ProductId,
                SuppliedQuantity: detail?.ComponentDetail?.SuppliedQuantity,
                KittingQuantity: detail?.ComponentDetail?.KittingQuantity ?? 0,
                WarehouseEntity: detail?.ComponentDetail?.WarehouseEntity
            }
        }),
        [retrievePickslipComponentDetailsInline]
    );

    return (<CommonKitComponentDetails
        forwardedRef={ref}
        fetchComponentDetail={fetchComponentLineDetail}
        calculateComponentLineDetail={calculateComponentLineDetail}
        onUpdateComponentDetail={onUpdateComponentDetail}
        navigateBack={navigateBack}
        onUpdateBinLots={updateLotSerial}
        {...props} />);
};

export default React.forwardRef(KitComponentDetails);
