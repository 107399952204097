import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions } from 'ducks/ui';
import { selectors as configSelectors } from 'ducks/appConfig';
import OptionMenu from './OptionsMenu';

const mapStateToProps = (state) => ({
  isV2: configSelectors.isV2(state),
  isV3: configSelectors.isV3(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    toggleMenuOption: uiActions.toggleMenuOption
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OptionMenu);
