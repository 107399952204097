export default [
  {
    id: '1',
    name: 'AU Dollar',
    abbreviation: 'AUD',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'US Dollar',
    abbreviation: 'USD',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'Pakistani Rupee',
    abbreviation: 'PKR',
    fieldList1: 'field'
  },
];
