import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, change, getFormSyncErrors, reset } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import {
    actions as operationActions,
} from 'ducks/uiOperations';
import IDynamicExtractWizardProps from './DynamicExtractModal.properties';
import DynamicExtractWizard from './DynamicExtractModal';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as staffSelectors } from 'ducks/staff';

type IStateProps = Pick<IDynamicExtractWizardProps,
    'formValues' | 'formSyncErrors' | 'staff'
>;
const DYNAMIC_EXTRACT_FORM = 'dynamicExtractForm';
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    formValues: getFormValues(DYNAMIC_EXTRACT_FORM)(state),
    formSyncErrors: getFormSyncErrors(DYNAMIC_EXTRACT_FORM)(state),
    staff: staffSelectors.staff(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeOperationMode: operationActions.changeOperationMode,
        changeFormFieldValue: (fieldName: string, fieldValue: any) => change(DYNAMIC_EXTRACT_FORM, fieldName, fieldValue),
        resetForm: () => reset(DYNAMIC_EXTRACT_FORM),
    },
    dispatch);

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: IDynamicExtractWizardProps) => {

    return (<DynamicExtractWizard {...props} />);
});

export default ConnectedComponent;
