import { call, Effect, put, takeLatest, delay } from 'redux-saga/effects';
import { types as paymentsTypes, actions as paymentsActions } from 'ducks/common/paymentDetails';
import { callApi } from '../utils';
import * as api from 'api/common/paymentDetails';
import { IDataAction } from 'ducks/utils';
import { pathOr, isNull } from 'utils/utils';

function* eftposPostApiCallEffect(response: any): IterableIterator<Effect> {
    yield put(paymentsActions.showEftposDialog({
        open: !response.isCompleted,
        data: {
            description: response.detailedStatus,
            status: response.status
        },
        disableOk: !response.enableOkButton,
        disableClose: !response.isCompleted
    }));
}

function* fetStatus(): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.fetStatus;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {

        yield put(paymentsActions.showEftposDialog({
            open: true,
            data: {
                description: response.detailedStatus,
                status: response.status
            },
            disableOk: !response.enableOkButton,
            disableClose: !response.isCompleted
        }));

        if (!response.isCompleted) {
            const DURATION = 5000;
            yield call(delay, DURATION);
            yield call(fetStatus);
        }
    }
    yield callApi(
        call(api.fetStatus),
        success,
        failure,
        { postApiSuccessCallEffect }
    );
}

function* sendClose(): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.sendClose;

    yield callApi(
        call(api.sendClose),
        success,
        failure,
        { postApiSuccessCallEffect: eftposPostApiCallEffect }
    );
}
function* sendOk(): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.sendOk;

    yield callApi(
        call(api.sendOk),
        success,
        failure,
        { postApiSuccessCallEffect: eftposPostApiCallEffect }
    );
}
function* fetchPaymentDetails(): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.fetchPaymentDetails;

    yield callApi(
        call(api.fetchPaymentDetails),
        success,
        failure,
    );
}
function* applyPayment(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.applyPayment;

    yield callApi(
        call(api.applyPayment, action.data),
        success,
        failure,
        { dialogActionCallEffect: applyPayment }
    );
}

function* processPayment(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.processPayment;
    const isProcessPayment = action.data.ProcessPayment;
    const postSuccessAction = action.data.postSuccessAction;

    if (isProcessPayment === false) {
        return;
    }

    function* postApiSuccessCallEffect(): IterableIterator<Effect> {
        if (isProcessPayment !== null) {
            yield call(postSuccessAction);
        }
    }
    yield callApi(
        call(api.processPayment, action.data),
        success,
        failure,
        { dialogActionCallEffect: processPayment, postApiSuccessCallEffect }
    );
}

function* deletePayment(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.deletePayment;

    yield callApi(
        call(api.deletePayment, action.data),
        success,
        failure,
        { dialogActionCallEffect: deletePayment }
    );
}
function* cancelPayment(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.cancelPayment;

    function* postApiSuccessCallEffect(): IterableIterator<Effect> {
        yield call(fetchPaymentDetails);
    }
    yield callApi(
        call(api.cancelPayment, action.data),
        success,
        failure,
        { dialogActionCallEffect: cancelPayment, postApiSuccessCallEffect }
    );
}

function* updatePaymentContext(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.updatePaymentContext;

    const CashLocation = pathOr('', ['data', 'filters', 'CashLocation'], action);
    const BankingEntity = pathOr('', ['data', 'filters', 'BankingEntity'], action);

    if (!isNull(CashLocation) && !isNull(BankingEntity)) {
        yield callApi(
            call(api.updatePaymentContext, action.data.filters),
            success,
            failure,
            { dialogActionCallEffect: updatePaymentContext }
        );
    }
}

function* purchase(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = paymentsActions.saga.purchase;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield put(paymentsActions.showEftposDialog({
            open: true,
            data: {
                description: response.detailedStatus,
                status: response.status
            },
            disableOk: !response.enableOkButton,
            disableClose: !response.isCompleted
        }));

        yield call(fetStatus);
    }

    yield callApi(
        call(api.purchase, action.data),
        success,
        failure,
        { postApiSuccessCallEffect, dialogActionCallEffect: purchase }
    );
}

export default function* rootPaymentsSaga(): IterableIterator<Effect> {
    yield takeLatest(paymentsTypes.fetStatus, fetStatus);
    yield takeLatest(paymentsTypes.sendClose, sendClose);
    yield takeLatest(paymentsTypes.sendOk, sendOk);
    yield takeLatest(paymentsTypes.fetchPaymentDetails, fetchPaymentDetails);
    yield takeLatest(paymentsTypes.applyPayment, applyPayment);
    yield takeLatest(paymentsTypes.purchase, purchase);
    yield takeLatest(paymentsTypes.updatePaymentContext, updatePaymentContext);
    yield takeLatest(paymentsTypes.processPayment, processPayment);
    yield takeLatest(paymentsTypes.deletePayment, deletePayment);
    yield takeLatest(paymentsTypes.cancelPayment, cancelPayment);
}
