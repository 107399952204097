import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryPanel from '../SummaryPanel/index';
import { ISalesProps } from '../interfaces';

class Sales extends React.PureComponent<ISalesProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = 'inventory-enquiry';
    if (path) {
      switch (path) {
        case `/${context}/sales/sales-orders`:
          onInitialLoad('IESSalesOrders');
          break;
        case `/${context}/sales/service-orders`:
          onInitialLoad('ServiceOrders');
          break;
        case `/${context}/sales/return-authorities`:
          onInitialLoad('IESReturnAuthorities');
          break;
        case `/${context}/sales/lost-sales`:
          onInitialLoad('IESLostSales');
          break;
        case `/${context}/sales/quotes`:
          onInitialLoad('IESQuotes');
          break;
        default:
          onInitialLoad('IESSalesOrders');
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, selectedTab } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <SummaryPanel
          variant={'ProductDetails'}
        />
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={null}
        />
      </React.Fragment>
    );
  }
}

export default Sales;
