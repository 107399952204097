import ApiService from 'utils/ApiService'

export const search = ({query}) => {
  if (query && query.match(/^ *$/) === null) {
    return new ApiService().getSearchOptions().filter(
      val => {
        return val.label.toLowerCase().includes(query.toLowerCase())
      }
    )
  }
  return []
}
