import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as notepadActions, selectors as notepadSelectors } from 'ducks/common/notepad';
import Notepad from './Notepad';
import { isDirty, reset, getFormValues, initialize, destroy } from 'redux-form';

const mapStateToProps = (state, ownProps) => ({
  operationMode: operationSelectors.operationMode(state),
  isLoading: notepadSelectors.isLoading(state),
  dirty: isDirty('Notepad')(state),
  note: notepadSelectors.note(state),
  formValues: getFormValues('Notepad')(state),
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  resetForm: () => reset('Notepad'),
  initialize: initialize,
  changeConfirmationDialog: uiActions.changeConfirmationDialog,
  changeOperationMode: operationActions.changeOperationMode,
  getNote: notepadActions.getNote,
  setNote: notepadActions.setNote,
  destroyForm: () => destroy('Notepad'),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notepad);