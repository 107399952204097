import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as binLocationsSelector, actions as binLocationsActions } from 'ducks/pme/BinLocations';
import { fetch } from 'api/pme/BinLocations';
import { selectors as movementSelectors } from 'ducks/pme/Movements';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'PMEBinLocations',
  columnDefs: binLocationsSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: binLocationsSelector.gridOptions(state),
  reqParams: (movementSelectors.selected(state) || params.get('ProductMovementId'))
    &&
    {
      ProductMovementId: (movementSelectors.selected(state) && movementSelectors.selected(state).ProductMovementId) || params.get('ProductMovementId')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: binLocationsActions.setSelected
};
