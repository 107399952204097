import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchReservationDetails: (query) => (query),
  }
}, 'cdReservationDetails')

let initialState = asyncInitialState({
  cdReservationDetails: '',
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.fetchReservationDetails:
      return asyncOnRequest(state, action)

    case types.saga.fetchReservationDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          cdReservationDetails: action.payload
        }
      })
    case types.saga.fetchReservationDetails.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.cdReservationDetails,
  {
    cdReservationDetails: data => data.cdReservationDetails,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.cdReservationDetails.loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)



