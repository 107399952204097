
import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';

import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import ActivitySearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Activities';
import ActivityDetails from './ActivityDetails';
import Capabilities from './Capabilities';
import Checklists from './Checklists';

import { shallowCompare, isNull } from '../../utils/utils';

import BreadcrumbBar from './../common/BreadcrumbBar';
import * as styles from './ActivityMaintenance.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';

import { IActivityMaintenanceViewProperties } from './interfaces';
import { MODULE_TREE, TABS_DATA } from './constants';
import NavigationTabs from 'components/ActivityMaintenance/NavigationTabs';
import { IActivitySearchFacade } from 'api/swaggerTypes';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const inlineStyles = createStyles({
    ActivityMaintenanceSection: {
        width: 'calc(100% - 20px)',
        marginLeft: '10px',
    }
});

class ActivityMaintenanceView extends React.Component<IActivityMaintenanceViewProperties> {

    componentDidMount(): void {
        this.props.changeOperationMode(Operation.BROWSE);
    }

    shouldComponentUpdate(nextProps: Readonly<IActivityMaintenanceViewProperties>): boolean {
        return shallowCompare(this, nextProps);
    }

    componentDidUpdate(prevProps: Readonly<IActivityMaintenanceViewProperties>): void {
        const { operationMode, selectedTab, changeSelectedForm, selectedForm } = this.props;
        if (prevProps.selectedTab !== selectedTab) {
            this.handleModuleChange(selectedTab);
        }
        if (operationMode === Operation.BACK) {
            this.backAction(prevProps);
        }
        if (!selectedForm) {
            changeSelectedForm('activity-maintenance', selectedTab);
        }

    }

    componentWillUnmount(): void {
       this.props.resetSearchLookupDrawer();
       this.props.destoryForm();
    }

    handleModuleChange = (selectedTab: string) => {
        const { operationMode } = this.props;
        const params = (new URLSearchParams(this.props.location.search));
        const ActivityCode = params.get('ActivityCode') || '';
        if ((selectedTab !== 'ActivityDetails') && (!this.props.selectedActivity && isNull(ActivityCode))) {
            showSnackBar({ variant: 'error', message: 'No activity selected.' });
            this.props.changeSelectedTab('ActivityDetails');

            return;
        }
        if (operationMode === Operation.NEW || operationMode === Operation.EDIT) {
            showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

            return;
        }
        const targetTab = selectedTab;

        this.props.changeSelectedTab(targetTab);
        const context = 'activity-maintenance';
        this.props.getFormSchema({
            context,
            formId: targetTab
        });
        const notActivity = targetTab === 'Capabilities';
        this.props.toggleBrowseLookUp(notActivity);
        this.props.changeSelectedForm(context, targetTab);

        this.props.changeOperationMode(Operation.BROWSE);
        const { pathname, search } = this.getRoute()[targetTab || 'ActivityDetails'];
        this.props.history.push({ pathname, search });

        if (this.props.changeScope) {
            this.props.changeScope(context);
        }
    }

    getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
        const params = (new URLSearchParams(this.props.location.search));
        const ActivityCode = params.get('ActivityCode') || '';
        const context = 'activity-maintenance';

        const routes = ({
            ActivityMaintenance: { pathname: `/${context}`, search: `?ActivityCode=${ActivityCode}`, component: ActivityDetails },
            ActivityDetails: { pathname: `/${context}/activity-details`, search: `?ActivityCode=${ActivityCode}`, component: ActivityDetails },
            Checklists: { pathname: `/${context}/checklist`, search: `?ActivityCode=${ActivityCode}`, component: Checklists },
            Capabilities: { pathname: `/${context}/activity-details/capabilities`, search: `?ActivityCode=${ActivityCode}&CapabilityCode=${params.get('CapabilityCode') || ''}`, component: Capabilities },
        });

        return routes;
    }

    handleToggleMenuOptionOpen = () => {
        const { toggleMenuOption, isMenuOptionOpen } = this.props;
        toggleMenuOption(!isMenuOptionOpen);
    }

    backAction = (prevProps: Readonly<IActivityMaintenanceViewProperties>) => {
        const { dirty, selectedTab, changeConfirmationDialog, changeOperationMode, history, resetForm } = this.props;
        if (dirty) {
            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    changeOperationMode(prevProps.operationMode);
                },
                onOk: () => {
                    if (selectedTab === 'ActivityDetails') {
                        history.push('/dashboard');
                    }
                    this.handleModuleChange('ActivityDetails');
                    changeOperationMode(Operation.BROWSE);
                    resetForm();
                }
            });
        } else {
            if (selectedTab) {
                const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
                if (!targetModule || !targetModule.parent || targetModule.parent === '') {
                    if (selectedTab === 'ActivityDetails') {
                        history.push('/dashboard');
                    } else {
                        this.handleModuleChange('ActivityDetails');
                    }
                } else {
                    this.handleModuleChange(targetModule.parent);
                }
            }
            changeOperationMode(Operation.BROWSE);
        }
    }

    fetchActivityDetailsRecord = () => {
        const { ActivityCode } = this.props;
        if (ActivityCode) {
            this.props.fetchActivityData(ActivityCode);
        }
    }

    getRecordChangeCallBacks = () => {
        const { selectedTab } = this.props;
        const callBacks = [];
        switch (selectedTab) {
            case 'ActivityDetails':
                callBacks.push(this.fetchActivityDetailsRecord);
                break;
            default:
        }

        return callBacks;
    }
    changeScope = () => {
        const { changeScope, selectedTab } = this.props;
        changeScope(selectedTab);
    }

    getOptionMenuData = (selectedTab: string) => {
        switch (selectedTab) {
            default:
                return options.ActivityMaintenance;
        }
    }

    handleOptionClick = (selectedTab: string): void => {
        const { changeSelectedTab, operationMode } = this.props;
        if (operationMode === Operation.NEW || operationMode === Operation.EDIT) {
            showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });

            return;
        }
        changeSelectedTab(selectedTab);
    }

    isNextItemSelectionAllowed = (nextItem: IActivitySearchFacade): boolean => {
        const { isChecklistsDirty, selectedActivity } = this.props;

        if (isChecklistsDirty) {
            if (selectedActivity.ActivityCode !== nextItem.ActivityCode) {
                this.showRecordChangeDiscardWarning(nextItem);
            }

            return false;
        }

        return true;
    }

    showRecordChangeDiscardWarning = (nextItem: IActivitySearchFacade) => {
        const { changeConfirmationDialog, changeOperationMode, setSelectedActivity, setChecklistIsDirty } = this.props;

        changeConfirmationDialog({
            open: true,
            title: 'Discard changes',
            message: 'Are you sure you want to continue?',
            okLabel: 'Discard',
            onCancel: () => {
                changeOperationMode(Operation.BROWSE);
            },
            onOk: () => {
                setSelectedActivity(nextItem.ActivityCode);
                setChecklistIsDirty(false);
                changeOperationMode(Operation.BROWSE);
            }
        });
    }

    render(): React.ReactNode {
        const { selectedTab, isMenuOptionOpen, scope } = this.props;
        const routes = Object.values(this.getRoute());

        return (
            <div className={styles.ActivityMaintenanceOuter}>
                <div className={styles.ActivityMaintenanceContainer}>

                    <ActivitySearchLookUpDrawer
                        enableToggle={true}
                        hideFilterButton={true}
                        recordChangeCallBacks={this.getRecordChangeCallBacks()}
                        isNextItemSelectionAllowed={this.isNextItemSelectionAllowed}
                    />
                    <div
                        style={inlineStyles.ActivityMaintenanceSection}
                        className={styles.ActivityMaintenanceSection}
                    >
                        <BreadcrumbBar
                            onClick={this.handleToggleMenuOptionOpen}
                            mainModule={'Activity Maintenance'}
                            activeModule={selectedTab}
                            moduleTree={MODULE_TREE}
                        />
                        <NavigationTabs
                            selectedTab={selectedTab}
                            onTabChange={this.handleModuleChange}
                            tabsData={TABS_DATA}
                        />

                        {routes.map((route) => (
                            <Route
                                exact
                                key={route.pathname}
                                path={route.pathname}
                                render={() => <route.component
                                    onInitialLoad={this.handleModuleChange}
                                    path={route.pathname}
                                    formName={selectedTab}
                                />}
                            />
                        ))}

                    </div>
                </div>
                <OptionsMenu
                    open={isMenuOptionOpen}
                    options={this.getOptionMenuData(selectedTab)}
                    onOptionClick={this.handleOptionClick}
                    defaultValue={selectedTab}
                />
                <ActionBar scope={scope} />
            </div>
        );
    }
}

export default withRouter(ActivityMaintenanceView);
