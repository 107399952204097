import PickSlipDetails from './../data/forms/pick-slip-enquiry/SlipDetails';
import SlipLines from './../data/forms/pick-slip-enquiry/SlipLines';
import SlipLineDetails from './../data/forms/pick-slip-enquiry/SlipLineDetails';
import Serials from './../data/forms/pick-slip-enquiry/Serials';
import NotePad from './../data/forms/pick-slip-enquiry/NotePad';
import Lots from './../data/forms/pick-slip-enquiry/Lots';
import SlipLineBOM from './../data/forms/pick-slip-enquiry/SlipLineBOM';
import SlipLineBOMDetails from './../data/forms/pick-slip-enquiry/SlipLineBOMDetails';
import BOMLots from './../data/forms/pick-slip-enquiry/BOMLots';
import BOMSerials from './../data/forms/pick-slip-enquiry/BOMSerials';
import Diary from './../data/forms/pick-slip-enquiry/Diary';
import DiaryDetails from './../data/forms/pick-slip-enquiry/DiaryDetails';
import Events from './../data/forms/pick-slip-enquiry/Events';

const getFormSchema = (formId) => {
    switch (formId) {
        case 'SlipDetails':
            return PickSlipDetails;
        case 'SlipLines':
            return SlipLines;
        case 'SlipLineDetails':
            return SlipLineDetails;
        case 'Lots':
            return Lots;
        case 'Serials':
            return Serials;
        case 'SlipLineBOM':
            return SlipLineBOM;
        case 'SlipLineBOMDetails':
            return SlipLineBOMDetails;
        case 'SlipLineBOMLots':
            return BOMLots;
        case 'SlipLineBOMSerials':
            return BOMSerials;
        case 'Notepad':
            return NotePad;
        case 'SalesDiary':
            return Diary;
        case 'DiaryDetails':
            return DiaryDetails;
        case 'Events':
            return Events;
        default:
            return PickSlipDetails;
    }
};

export default getFormSchema;
