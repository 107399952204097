import { IFormViewForm } from 'components/FormView';

const KitComponentDetail: IFormViewForm = {
  id: 'KitComponentDetailForm',
  layout: {
    rows: 3,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_FIELD',
          props: {
            label: 'Product',
            name: 'ProductIdDisplay',
            placeholder: '',
            size: 'medium',
            required: false,
            readOnly: true,
            disabled: true
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            size: 'large',
            disabled: true,
            readOnly: true,
            required: false,
            inputProps: {
              maxLength: 40
            }
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Required',
            name: 'RequiredQuantity',
            type: 'number',
            placeholder: '',
            size: 'small',
            alignment: 'right',
            required: false,
            decimalSeparator: true,
            decimalPlaces: 2
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Supplied',
            name: 'SuppliedQuantity',
            type: 'number',
            placeholder: '',
            size: 'small',
            required: false,
            decimalSeparator: true,
            alignment: 'right',
            decimalPlaces: 2
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Backorder',
            name: 'OnOrderQuantity',
            type: 'number',
            placeholder: '',
            size: 'small',
            required: false,
            decimalSeparator: true,
            alignment: 'right',
            decimalPlaces: 2
          }
        },
      ]
    },
    {
      id: '3',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            size: 'large',
            style: {
              height: '100%'
            }
          }
        }
      ]
    },
  ]
};
export default KitComponentDetail;
