import React from 'react';
import DataSource from 'devextreme/data/data_source';
import { FilterDescriptor, LoadOptions } from 'devextreme/data';
import { ARROW_DOWN, ARROW_UP } from 'utils/constants';
import { isNull } from 'utils/utils';
import { IFilterOptionData, IFilterRequest } from 'api/swaggerTypes';
import { Payload, usePost } from 'api/reactQuery/queryService';

export type GridIFiltersData = Record<any, IFilterOptionData[]>;

export const createDataSource = <DataNode, FetchResponse, InsertResponse, UpdateResponse, RemoveResponse>(
    key: string,
    api: {
        fetch(batchPage?: number, batchSize?: number, sort?: string, filter?: any): Promise<FetchResponse[]>;
        totalCount?(): Promise<number>;
        insert?(data: DataNode): Promise<InsertResponse>;
        update?(data: DataNode): Promise<UpdateResponse>;
        remove?(key: string | number): Promise<RemoveResponse>;
    },
    gridFiltersData?: GridIFiltersData) => new DataSource({
        key: key,
        load: async (loadOptions: LoadOptions): Promise<any[]> => {
            const defaultSkip = 0;
            const defaultTake = 50;
            let sortField = '';
            let direction = '';
            let sort = '';

            const { skip = defaultSkip, take = defaultTake } = loadOptions;

            const BatchPage = Math.floor(Number((skip) + defaultTake) / defaultTake);
            const BatchSize = defaultTake;

            if (loadOptions.sort) {
                sortField = loadOptions.sort[0].selector;
                direction = loadOptions.sort[0].desc ? 'desc' : 'asc';
                sort = (sortField && direction) ? `${sortField}:${direction}` : null;
            }

            const parsedFilter = parseDataGridFilter(loadOptions.filter, gridFiltersData);

            const parseData = (records) =>
                key === 'Id' ? records.map((d, idx) => ({ ...d, Id: `${BatchPage}_${idx}` })) : records;

            if (take > defaultTake) { //if take is greater then default batch page. Do multiple API calls to fetch all data requested through 'take' param
                return async function (): Promise<any> {
                    const APICallsCount = Math.floor(loadOptions.take / defaultTake);
                    let allRecords = [];

                    try {
                        for (let i = 0; i < APICallsCount; i++) {
                            const records = await api.fetch(BatchPage + i, BatchSize, sort, parsedFilter);

                            if (records?.length > 0) {
                                allRecords = allRecords.concat(records);
                            }

                            if (records.length < defaultTake) {
                                break;
                            }
                        }

                    } catch (err) {
                        console.warn(err);
                    }

                    return parseData(allRecords);
                }();
            } else {
                const records = await api.fetch(BatchPage, BatchSize, sort, parsedFilter);

                return parseData(records) ?? [];
            }
        },
        remove: async (removeKey: any): Promise<void> => {
            await api.remove(removeKey);
        },
        insert: api.insert,
        update: async (updateKey: any, item) => {
            const combinedKeyAndItem = { ...item, [key]: updateKey };

            return api.update(combinedKeyAndItem);
        },
        totalCount: async (_loadOptions: LoadOptions): Promise<number> => {
            return api.totalCount();
        }
    });

// handle common keyboard navigations
export const useGridKeyboardNavigation = () => {

    const preventDataGridDefaults = (e) => {
        const keyboardEvent = e.event as KeyboardEvent;

        if ((keyboardEvent.metaKey || keyboardEvent.ctrlKey) && keyboardEvent.key === 'ArrowLeft' ||
            (keyboardEvent.metaKey || keyboardEvent.ctrlKey) && keyboardEvent.key === 'ArrowRight') {
            keyboardEvent.preventDefault();

            return;
        }
    };

    const onKeyDown = async (e) => {
        const keyboardEvent = e.event as KeyboardEvent;
        preventDataGridDefaults(e);

        if (keyboardEvent.keyCode !== ARROW_DOWN && keyboardEvent.keyCode !== ARROW_UP) {
            return;
        }

        const selKey = e.component.getSelectedRowKeys();
        const visibleRowsLength = e.component.getVisibleRows().length;

        if (selKey.length) {
            const currentKey = selKey[0];
            let index = e.component.getRowIndexByKey(currentKey);
            if (keyboardEvent.keyCode === ARROW_UP) {
                index--;
                if (index >= 0) {
                    await e.component.selectRowsByIndexes([index]);
                    keyboardEvent.stopPropagation();
                }
            } else if (keyboardEvent.keyCode === ARROW_DOWN) {
                index++;
                if (index < visibleRowsLength) {
                    await e.component.selectRowsByIndexes([index]);
                    keyboardEvent.stopPropagation();
                }
            }
        }
        e.component.focus();
    };

    // need to attach this function to grid where keyborad navigation is applied
    const onFocusedCellChanging = React.useCallback(
        (e) => {
            e.isHighlighted = false;
        },
        []);

    return { onKeyDown, onFocusedCellChanging, preventDataGridDefaults };
};

export const parseDataGridFilter = (filter: FilterDescriptor | FilterDescriptor[], gridFiltersData?: GridIFiltersData) => {
    const gridFilterValues = {};

    if (gridFiltersData) {
        Object.keys(gridFiltersData).forEach((key) => {
            gridFilterValues[key] = gridFiltersData?.[key]?.map((f) => f.value);
        });
    }

    if (isNull(filter)) {
        return filter;
    }

    const filterMap: any = {};
    const addToMap = (key, value) => {
        if (filterMap.hasOwnProperty(key)) {
            filterMap[key].push(value);
        } else {
            filterMap[key] = [value];
        }
    };

    const addUnExcludedToMap = (key, value) => {
        filterMap[key] = filterMap.hasOwnProperty(key) ?
            filterMap?.[key]?.filter((v) => v !== value) : gridFilterValues?.[key]?.filter((v) => v !== value);
    };

    const extractFilter = (filterData, exclude = false) => {
        if (filterData && filterData.filterValue) {
            if (exclude) {
                addUnExcludedToMap(filterData[0], filterData.filterValue);
            } else {
                addToMap(filterData[0], filterData.filterValue);
            }
        } else if (typeof (filterData) !== 'string' && filterData !== null && Array.isArray(filterData)) {
            filterData.forEach((item) => {
                if (typeof (item) !== 'string') {
                    extractFilter(item, filterData?.[0] === '!' || exclude);
                }
            });
        }
    };

    extractFilter(filter);

    return filterMap;
};

export const useFetchDataGridFilters = <R extends object>() =>
    usePost<Payload<IFilterRequest>, R>('Filters/GetFilterOptionsList', null, null);
