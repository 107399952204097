import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { ICompleteExtractProgramProps } from '../DynamicExtractModal.properties';

const CompleteExtractProgram = (props: ICompleteExtractProgramProps) => {
    const {
        classes,
        details
    } = props;

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(CompleteExtractProgram);
