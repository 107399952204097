import React from 'react';
import Subheader from '@markinson/uicomponents-v2/Subheader';

interface IContentProps {
    text?: string;
}

const SubheaderStyles = {
    fontSize: '18px',
    fontWeight: 'regular',
    padding: 0,
    marginBottom: 20,
    color: '#777'
};

export const Heading = ({ text }) => {
    return (
        <Subheader
            style={SubheaderStyles}>
            {text}
        </Subheader>
    );
};

export const Content: React.FC<IContentProps> = ({ text }) => {
    return (
        <>
            {text.split('\r\n').map((t, index) => (
                <Subheader
                    key={index}
                    style={{ ...SubheaderStyles, fontSize: 14, fontWeight: 'bold', marginBottom: 10, lineHeight: 1.3 }}>
                    {t}
                </Subheader>
            ))}
        </>
    );
};
