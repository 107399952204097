import { IObjectified } from '../utils';
import { IActivitySearchResponse, ISortOrder, ISearchRequest, IActivityDetailFacade, IActivityDetailsResponse } from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { isNull } from 'utils/utils';

export async function search(request: { SearchText: string; TemplateCode: string; Sort: ISortOrder; BatchSize?: number }): Promise<{
    records: IObjectified<IActivityDetailFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { SearchText, TemplateCode, Sort, BatchSize = DEFAULT_PAGE_SIZE } = request;
    if (isNull(TemplateCode)) {
        return {
            records: [],
            currPage: 1,
            nextPage: false,
            prevPage: false
        };
    }

    return Api.postInlineCollectionResponse<ISearchRequest, IActivitySearchResponse, 'Activities'>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}/SearchActivities`, { SearchText, Sort, BatchSize }
    ).then((response) => {
        const records = isNull(response.Forms) ? asObjectifiedArray(response, 'Activities') : [];
        const batchInformation = (response as IActivitySearchResponse).BatchInformation;

        return {
            Forms: response.Forms,
            records,
            currPage: batchInformation ? batchInformation.BatchPage : 1,
            nextPage: batchInformation && !batchInformation.EndOfData,
            prevPage: batchInformation && batchInformation.BatchPage > 1
        };
    });
}

export async function searchById(query: { TemplateCode: string; ActivityCode: string }): Promise<{
    records: IObjectified<IActivityDetailFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { ActivityCode } = query;

    return Api.getInlineResponse<IActivityDetailsResponse, 'ActivityDetails'>(
        `ActivityMaintenance/Activity/${ActivityCode}`)
        .then((response) => {
            const records = isNull(response.Forms) ? [asObjectified(response, 'ActivityDetails')] : [];

            return {
                Forms: response.Forms,
                records: records,
                currPage: 1,
                nextPage: false,
                prevPage: false
            };
        });
}

export async function create(query: { TemplateCode: string; ActivityCode: string }):
    Promise<{ ActivityDetails: IObjectified<IActivityDetailsResponse> }> {
    const { TemplateCode, ActivityCode } = query;

    return Api.postInlineResponse<IActivityDetailFacade, IActivityDetailsResponse, 'ActivityDetails'>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}/Activity/${ActivityCode}`
    ).then((response) => (
        {
            ...response,
            ActivityDetails: asObjectified(response, 'ActivityDetails')
        }
    ));
}

export async function deleteActivity(query: { TemplateCode: string; ActivityCode: string }): Promise<IActivityDetailsResponse> {
    const { TemplateCode, ActivityCode } = query;

    return Api.delete<IActivityDetailsResponse>(

        `JobTemplateMaintenance/JobTemplate/${TemplateCode}/Activity/${ActivityCode}`)
        .then((response) => response);
}
