import * as React from 'react';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu';
import ProductsLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Products';
import { shallowCompare } from '../../utils/utils';

import { withRouter, Route } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar';
import * as styles from './InventoryEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';
import ProductDetails from './ProductDetails';
import Associations from './Associations';
import WhereUsed from './WhereUsed';
import Pricing from './Pricing';
import Sales from './Sales';
import Stocking from './Stocking';
import Attachments from './Attachments';
import { IInventoryEnquiryViewProperties } from './interfaces';
import { MODULE_TREE, NON_EXISTING_MODULES } from './constants';

const inlineStyles = {
  inventoryEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class InventoryEnquiryView extends React.Component<IInventoryEnquiryViewProperties> {

  handleModuleChange = (selectedTab: string): void => {
    if (selectedTab === 'Balances' && !!this.props.selectedCustomer) {
      if (this.props.fetchBalanceDetails) this.props.fetchBalanceDetails({ CustomerId: this.props.selectedCustomer.CustomerId });
    }

    const fromNonExisting = NON_EXISTING_MODULES.find((node) => node.id === selectedTab);
    const targetTab = (fromNonExisting && fromNonExisting.target) || selectedTab;
    this.props.changeSelectedTab(targetTab);

    const context = 'inventory-enquiry';
    this.props.getFormSchema({
      context,
      formId: targetTab
    });
    this.props.changeSelectedForm(context, targetTab);
    this.props.changeOperationMode(Operation.BROWSE);
    this.props.history.push(this.getRoute()[targetTab || 'ProductDetails']);
    this.fetchChildRecords(targetTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any; fetchChildRecords?(params: any): void } } => {
    const context = 'inventory-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const ProductId = params.get('ProductId') || '';
    const LotSerial = params.get('LotSerial') || '';
    const Warehouse = params.get('Warehouse') || '';

    const routes = {
      InventoryEnquiry: { pathname: `/${context}`, search: `?ProductId=${ProductId}`, component: ProductDetails },
      ProductDetails: { pathname: `/${context}/product-details`, search: `?ProductId=${ProductId}`, component: ProductDetails, fetchChildRecords: this.fetchProductDetails },
      HistoricalDates: { pathname: `/${context}/product-details/historical-dates`, search: `?ProductId=${ProductId}`, component: ProductDetails },
      Notepad: { pathname: `/${context}/product-details/notepad`, search: `?ProductId=${ProductId}`, component: ProductDetails },
      UserFields: { pathname: `/${context}/product-details/user-fields`, search: `?ProductId=${ProductId}`, component: ProductDetails },
      AssociationsAlternates: { pathname: `/${context}/associations/alternates`, search: `?ProductId=${ProductId}`, component: Associations },
      AssociationsSupersessions: { pathname: `/${context}/associations/suppressions`, search: `?ProductId=${ProductId}`, component: Associations, },
      AssociationsBillOfMaterials: { pathname: `/${context}/associations/bill-of-material`, search: `?ProductId=${ProductId}`, component: Associations },
      WhereUsedBOM: { pathname: `/${context}/where-used/bill-of-material`, search: `?ProductId=${ProductId}`, component: WhereUsed },
      WhereUsedCatalogue: { pathname: `/${context}/where-used/catalogue`, search: `?ProductId=${ProductId}`, component: WhereUsed, },
      Prices: { pathname: `/${context}/pricing/prices`, search: `?ProductId=${ProductId}`, component: Pricing },
      ForeignPrices: { pathname: `/${context}/pricing/foreign-prices`, search: `?ProductId=${ProductId}`, component: Pricing, },
      PriceMatrix: { pathname: `/${context}/pricing/price-matrix`, search: `?ProductId=${ProductId}`, component: Pricing, },
      Schedule: { pathname: `/${context}/pricing/schedule`, search: `?ProductId=${ProductId}`, component: Pricing, },
      FutureBreaks: { pathname: `/${context}/pricing/future-breaks`, search: `?ProductId=${ProductId}`, component: Pricing },
      PriceBreaks: { pathname: `/${context}/pricing/price-breaks`, search: `?ProductId=${ProductId}`, component: Pricing },
      IESSalesOrders: { pathname: `/${context}/sales/sales-orders`, search: `?ProductId=${ProductId}`, component: Sales },
      ServiceOrders: { pathname: `/${context}/sales/service-orders`, search: `?ProductId=${ProductId}`, component: Sales },
      IESReturnAuthorities: { pathname: `/${context}/sales/return-authorities`, search: `?ProductId=${ProductId}`, component: Sales },
      IESLostSales: { pathname: `/${context}/sales/lost-sales`, search: `?ProductId=${ProductId}`, component: Sales },
      IESQuotes: { pathname: `/${context}/sales/quotes`, search: `?ProductId=${ProductId}`, component: Sales, fetchChildRecords: this.fetchSalesQuotes },
      Stocking: { pathname: `/${context}/stocking`, search: `?ProductId=${ProductId}`, component: Stocking },
      StockingQuantities: { pathname: `/${context}/stocking/quantities`, search: `?ProductId=${ProductId}`, component: Stocking },
      StockingReserveHistory: { pathname: `/${context}/stocking/reserve-history`, search: `?ProductId=${ProductId}`, component: Stocking, },
      StockingBinLocations: { pathname: `/${context}/stocking/bin-locations`, search: `?ProductId=${ProductId}`, component: Stocking },
      StockingMovements: { pathname: `/${context}/stocking/movements`, search: `?ProductId=${ProductId}`, component: Stocking, fetchChildRecords: this.fetchStockingMovements },
      StockingSerials: { pathname: `/${context}/stocking/serial-numbers`, search: `?ProductId=${ProductId}`, component: Stocking },
      StockingLotDetails: { pathname: `/${context}/stocking/lot-details`, search: `?ProductId=${ProductId}`, component: Stocking, },
      StockingLotPicking: { pathname: `/${context}/stocking/lot-details/allocation-details/picking`, search: `?ProductId=${ProductId}&LotSerial=${LotSerial}&Warehouse=${Warehouse}`, component: Stocking },
      StockingLotReserved: { pathname: `/${context}/stocking/lot-details/allocation-details/reserved`, search: `?ProductId=${ProductId}&LotSerial=${LotSerial}&Warehouse=${Warehouse}`, component: Stocking, },
      StockingLotInPutAway: { pathname: `/${context}/stocking/lot-details/allocation-details/in-put-away`, search: `?ProductId=${ProductId}&LotSerial=${LotSerial}&Warehouse=${Warehouse}`, component: Stocking, },
      StockingQuantitiesPurchaseOrder: { pathname: `/${context}/stocking/quantities/details/orders/purchase-orders`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesProductionOrder: { pathname: `/${context}/stocking/quantities/details/orders/production-orders`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesInTransitImport: { pathname: `/${context}/stocking/quantities/details/orders/in-transit-import`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking, },
      StockingQuantitiesInTransitLocal: { pathname: `/${context}/stocking/quantities/details/orders/in-transit-local`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesPicking: { pathname: `/${context}/stocking/quantities/details/sales/picking`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesKitting: { pathname: `/${context}/stocking/quantities/details/sales/kitting`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesReserved: { pathname: `/${context}/stocking/quantities/details/sales/reserved`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking, },
      StockingQuantitiesServiceWIP: { pathname: `/${context}/stocking/quantities/details/work-in-progress/service`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesProductionWIP: { pathname: `/${context}/stocking/quantities/details/work-in-progress/production`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      StockingQuantitiesInPutAwayWIP: { pathname: `/${context}/stocking/quantities/details/work-in-progress/in-put-away`, search: `?ProductId=${ProductId}&Warehouse=${Warehouse}`, component: Stocking },
      Documents: { pathname: `/${context}/attachments/documents`, search: `?ProductId=${ProductId}`, component: Attachments },
      Links: { pathname: `/${context}/attachments/links`, search: `?ProductId=${ProductId}`, component: Attachments },
      Images: { pathname: `/${context}/attachments/images`, search: `?ProductId=${ProductId}`, component: Attachments, fetchChildRecords: this.getAttachmentsImages },
    };

    return routes;
  }

  handleToggleMenuOptionOpen = (): void => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    const { staff, setWarehouse } = this.props;
    if (staff) setWarehouse(staff.defaultWarehouseEntity);
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<IInventoryEnquiryViewProperties>): boolean {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: IInventoryEnquiryViewProperties): void {
    const { operationMode, selectedTab, selectedProduct, productDetailsSummary } = this.props;

    if (operationMode === Operation.BACK) {
      this.backAction(prevProps);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('inventory-enquiry', this.props.selectedTab);
    }
    if (selectedTab !== prevProps.selectedTab) {
      this.handleModuleChange(selectedTab);
    }

    if (selectedProduct && !productDetailsSummary) {
      this.fetchProductSummary();
    }

    if (selectedProduct && productDetailsSummary) {
      if (selectedProduct.ProductId !== productDetailsSummary.ProductId) {
        this.fetchProductSummary();
      }
    }
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.resetproductDetailsModule();
    this.props.destoryForm();
  }

  cancelFormSubmission = (): void => {
    const { dirty } = this.props;
    if (dirty) {
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(Operation.EDIT);
        },
        onOk: () => {
          this.props.resetForm();
          this.props.changeOperationMode(Operation.BROWSE);
        }
      });
    } else {
      this.props.changeOperationMode(Operation.BROWSE);
    }
  }

  backAction = (prevProps: IInventoryEnquiryViewProperties) => {
    const { dirty, selectedTab } = this.props;
    if (dirty) {
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          if (selectedTab === 'ProductDetails') {
            this.props.history.push('/dashboard');
          }
          this.handleModuleChange('ProductDetails');
          this.props.changeOperationMode(Operation.BROWSE);
          this.props.resetForm();
        }
      });
    } else {
      if (selectedTab) {
        const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
        if (!targetModule || !targetModule.parent || targetModule.parent === '') {
          if (selectedTab === 'ProductDetails') {
            this.props.history.push('/dashboard');
          } else {
            this.handleModuleChange('ProductDetails');
          }
        } else {
          this.handleModuleChange(targetModule.parent);
        }
      }
      this.props.changeOperationMode(Operation.BROWSE);
    }
  }

  fetchChildRecords = (selectedTab: string): void => {
    const routeData = this.getRoute()[selectedTab];
    if (routeData && routeData.fetchChildRecords) {
      routeData.fetchChildRecords(null);
    }
  }

  fetchStockingMovements = (): void => {
    const { staff } = this.props;
    if (staff) this.props.setStockingMovementsWarehouse(staff.defaultWarehouseEntity);
  }

  getAttachmentsImages = (): void => {
    const { getAttachmentsImages, selectedProduct, location } = this.props;
    const params = new URLSearchParams(location.search);
    const ProductId = selectedProduct ? selectedProduct.ProductId : params.get('ProductId') ? parseInt(params.get('ProductId')) : '';
    if (ProductId) {
      getAttachmentsImages({ ProductId });
    }
  }

  fetchSalesQuotes = (): void => {
    const { staff } = this.props;
    const { setSalesQuotesEntityView } = this.props;
    if (staff) setSalesQuotesEntityView(staff.defaultView, staff.defaultWarehouseEntity);
  }

  fetchProductDetails = (): void => {
    const { fetchProductDetails, fetchProductSummary, selectedProduct } = this.props;
    if (selectedProduct && selectedProduct.ProductId) {
      fetchProductDetails({
        ProductId: selectedProduct && selectedProduct.ProductId
      });
      fetchProductSummary({
        ProductId: selectedProduct && selectedProduct.ProductId
      });
    }

  }

  fetchProductSummary = () => {
    const { selectedProduct } = this.props;
    if (selectedProduct && selectedProduct.ProductId) {
      this.props.fetchProductSummary({ ProductId: selectedProduct && selectedProduct.ProductId });
    }
  }

  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      case 'StockingQuantitiesPurchaseOrder':
      case 'StockingQuantitiesProductionOrder':
      case 'StockingQuantitiesInTransitImport':
      case 'StockingQuantitiesInTransitLocal':
      case 'StockingQuantitiesPicking':
      case 'StockingQuantitiesReserved':
      case 'StockingQuantitiesKitting':
      case 'StockingQuantitiesServiceWIP':
      case 'StockingQuantitiesProductionWIP':
      case 'StockingQuantitiesInPutAwayWIP':
      case 'StockingQuantitiesDetails': return options.QuantitiesDetails;
      case 'StockingLotInPutAway':
      case 'StockingLotReserved':
      case 'StockingLotPicking': return options.StockingLotDetails;
      default: return options.InventoryEnquiry;
    }
  }

  resetChildGrids = () => {
    const { resetStockingSerials } = this.props;
    resetStockingSerials();
  }

  onProductChange = (): void => {
    const { selectedTab } = this.props;
    this.fetchChildRecords(selectedTab);
  }

  render(): React.ReactNode {
    const {
      selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.inventoryEnquiryOuter}>
        <div className={styles.inventoryEnquiryContainer}>
          <ProductsLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.onProductChange,
              this.resetChildGrids
            ]}
          />
          <div
            style={inlineStyles.inventoryEnquirySection}
            className={styles.inventoryEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Inventory Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                />}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'inventory-enquiry'} />
      </div>
    );
  }
}

export default withRouter(InventoryEnquiryView);
