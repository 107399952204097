import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../../utils'

export const fetch = (query) => {
  const { ProductId, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchGet(`/InventoryEnquiry/Product/${ProductId}/StockingQuantities`, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize })
    .then((result) => result && result.StockingQuantitiess &&
      ({
        records: result.StockingQuantitiess.map(item => objectify(item, null, 'Inline')),
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      }))
}
