import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLinesLotDetails';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'InvoiceLinesLotDetails',
  columnDefs: lineSelectors.lineColumns(state),
  rowData: lineSelectors.lines(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (lineSelectors.selected(state) || params.get('DocumentNumber')) && {
    DocumentNumber: (lineSelectors.selected(state) && lineSelectors.selected(state).DocumentNumber) || params.get('DocumentNumber')
  },
  keyFields: [{ valueField: 'DocumentNumber', routeField: 'DocumentNumber' }, { valueField: 'LineNumber', routeField: 'LineNumber' }],
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
