export const TABS_DATA = [
  { id: 'ServiceItemTypeDetail', label: 'Service Agreement Maintenance' },
];

export const FREQUENCY_TYPES = {
  D: 'Daily',
  W: 'Week',
  F: 'Fortnightly',
  M: 'Monthly',
  Y: 'Yearly'
};
