import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  SuccessAction,
  asyncSelectors
} from '../../utils';

export interface IFinancialSettingsData {
  details?: any;
  schema?: any;
}

export interface IFinancialSettingsState extends IExtendedState<IFinancialSettingsData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      getSupplierFinancialSettings: (params: { Supplier: number }) => (params)
    }
  },
  'supplierFinancialSettings');

const initialState = asyncInitialState<IFinancialSettingsData>({
  details: null,
  schema: null
});

export default (state: IFinancialSettingsState = initialState, action: IDataAction): IFinancialSettingsState => {
  switch (action.type) {
    case types.getSupplierFinancialSettings:
      return asyncOnRequest(state, action);
    case types.saga.getSupplierFinancialSettings.success:
      return asyncOnSuccess(state, action, (data: IFinancialSettingsData, successAction: SuccessAction) => {
        const result = successAction.payload;

        return {
          ...data,
          details: result && result.values,
          schema: result && result.schema,
        };
      });
    case types.saga.getSupplierFinancialSettings.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = ({
  ...asyncSelectors(
    (state: { supplierEnquiry: { settings: { financial: IFinancialSettingsState } } }) => state.supplierEnquiry.settings.financial,
    {
      financialSettingsDetails: (data: IFinancialSettingsData) => data.details,
      financialSettingsSchema: (data: IFinancialSettingsData) => data.schema,
    }),
  loadingDetails: (state: { supplierEnquiry: { settings: { financial: IFinancialSettingsState } } }) => state.supplierEnquiry.settings.financial.loading,
});
