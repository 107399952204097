import * as React from 'react';
import { createStyles, Grid, withStyles, StyledComponentProps, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import Icon from '@markinson/uicomponents-v2/Icon';
import { capitalize } from 'lodash';

export interface IInformationDialogProperties extends StyledComponentProps {
  open: boolean;
  title?: string;
  message?: string;
  okLabel?: string;
  onClose?(): void;
  onOk?(): void;
}

const inlineStyles = createStyles({
  outerGrid: {
    display: 'flex',
    flexFlow: 'row'
  },
  left: {
    background: 'white',
    padding: '24px',
    paddingRight: '0'
  },
  right: {
    background: 'white'
  },
  paper: {
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 24px 0px'
  },
  content: {
    'padding': '0 24px 24px 24px',
    '& > p': {
      marginTop: '8px'
    }
  },
  actions: {
    marginRight: '20px',
    marginLeft: '20px'
  },
  action: {
    'border': '1px solid #777777',
    'background': '#F5F5F5',
    'color': 'black',
    'borderRadius': '4px',
    'textTransform': 'none',
    'padding': '8px 16px',
    '&:focus': {
      background: '#0E86D4',
      border: '1px solid #0E86D4',
      color: 'white'
    }
  },
  iconContainer: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 48,
    color: '#0E86D4'
  },
});

class InformationDialogView extends React.Component<IInformationDialogProperties> {
  firstButtonRef: any;

  handleOkClick = (): void => {
    const { onClose, onOk } = this.props;
    if (onClose) {
      onClose();
    }

    if (onOk) {
      onOk();
    }
  }

  handleActionKeyDown = (e: any, firstButton: boolean, lastButton?: boolean) => {
    if (lastButton && e.key === 'Tab') {
      this.firstButtonRef?.focus();
      e.preventDefault();
    } else if (firstButton && e.key === 'Tab' && e.shiftKey) {
      this.firstButtonRef?.focus();
      e.preventDefault();
    }
  }

  render(): React.ReactNode {
    const { classes, open, message, title, okLabel } = this.props;

    return (<Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth={'sm'}
      style={{ whiteSpace: 'pre-line' }}
      aria-labelledby={'confirmation-dialog-title'}
      open={open}
      classes={{
        paper: classes.paper
      }}
      onEntered={() => { this.firstButtonRef?.focus(); }}
    >
      <Grid className={classes.outerGrid}>
        <Grid className={classes.left}>
          <div className={classes.iconContainer}>
            <Icon iconName={'Info'} className={classes.icon} />
          </div>
        </Grid>
        <Grid className={classes.right}>
          <DialogTitle
            className={'title'}
            classes={{
              root: classes.title,
            }}
            disableTypography
          >
            {title ? title : 'Discard Changes'}
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.content
            }}
          >
            {message && message.split('\\n').map((splitMessage, key) => (<p key={key}> {splitMessage}</p>))}
          </DialogContent>
        </Grid>
      </Grid>
      <DialogActions
        classes={{
          root: classes.actions
        }}
      >
        <Button
          onClick={this.handleOkClick}
          className={classes.action}
          autoFocus={true}
          onKeyDown={(e) => this.handleActionKeyDown(e, true, true)}
        >
          {capitalize(okLabel ? okLabel : 'Ok')}
        </Button>
      </DialogActions>
    </Dialog>);
  }
}

export default withStyles(inlineStyles, { index: 1 })(InformationDialogView);
