import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/inventoryEnquiry/pricing/foreignPrices';
import { fetch } from 'api/inventoryEnquiry/pricing/foreignPrices';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEForeignPrices',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lineActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
