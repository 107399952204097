import * as React from 'react';
import { withRouter } from 'react-router';
import ExpandMoreIcon from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import ExpandLessIcon from '@markinson/uicomponents-v2/SvgIcons/ExpandLess';
import { ISchedulerProps } from '../interfaces';
import './Scheduler.css';
import Scheduler, { SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import '@markinson/mk.mpv4.schedulercomponent/lib/css/style.css';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { Operation } from 'utils/operations';
import NewJobWizard from './NewJobModal';
import FormViewModal from 'components/common/Modals/FormViewModal';
import SchedulerEvent from './SchedulerEvent';
import { isNull } from 'utils/utils';
import PostRosterModal from './PostRosterModal';
import ReviewJobWizard from './ReviewJobWizard';
import ScheduleActivityModal from './ScheduleActivityModal';
import GeneralAcivityModal from './GeneralAcivityModal';
import { IShift } from 'api/swaggerTypes';
import { IEventItem } from 'ducks/serviceActivityScheduling/serviceActivities';
import { LinearProgress } from '@material-ui/core';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const events = [
];

const inlineStyles = {
  schedulerPreLoader: {
    height: '5px',
    width: '100%',
  }
};

export interface IGeneralActivityData {
  start?: any;
  end?: any;
  resource?: any;
}
interface ISchedulerState {
  isNewJobModalOpen: boolean;
  isUpcomingJobModalOpen: boolean;
  isReviewJobModalOpen: boolean;
  isGeneralActivityModalOpen: boolean;
  isPostModalOpen: boolean;
  rosterPeriod: string;
  rosterPeriodEnd: string;
  period: number;
  generalActivityData: IGeneralActivityData | null;
}

class ActivityScheduler extends React.Component<ISchedulerProps, ISchedulerState> {
  operationMap: Map<Operation, (prevProps?: Readonly<ISchedulerProps>) => void>;

  constructor(props: Readonly<ISchedulerProps>) {
    super(props);
    this.operationMap = new Map<Operation, () => void>();
    this.operationMap[Operation.NEW] = this.handleNew;
    this.operationMap[Operation.ALLOCATE] = this.handleUpcomingJobs;
    this.operationMap[Operation.REVIEW] = this.handleReviewJob;
    this.operationMap[Operation.PROCESS] = this.handlePostRoster;
    this.operationMap[Operation.PREVIOUS_PERIOD] = this.prevClick;
    this.operationMap[Operation.CURRENT_PERIOD] = this.todayClick;
    this.operationMap[Operation.NEXT_PERIOD] = this.nextClick;
    this.state = {
      isNewJobModalOpen: false,
      isUpcomingJobModalOpen: false,
      isPostModalOpen: false,
      isReviewJobModalOpen: false,
      isGeneralActivityModalOpen: false,
      rosterPeriod: '',
      rosterPeriodEnd: '',
      period: 1,
      generalActivityData: null
    };
  }

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      onInitialLoad('Scheduler');
    }
  }

  componentDidUpdate(prevProps: Readonly<ISchedulerProps>): void {
    const { operationMode, fetchResourcesLoading } = this.props;

    if (operationMode !== prevProps.operationMode) {
      if (this.operationMap[operationMode]) {
        this.operationMap[operationMode](prevProps);
      }
    }

    if (!fetchResourcesLoading && fetchResourcesLoading !== prevProps.fetchResourcesLoading) {
      this.populateNotRosteredCells();
    }
  }

  populateNotRosteredCells = () => {
    const { schedulerData = {}, triggerSchedulerDataChange } = this.props;

    if (isNull(schedulerData.resources)) return;

    this.unfreezeHeaderItems();
    this.unfreezeResources();
    const frozenCellsData = [];

    schedulerData.renderData.forEach((row) => {
      if (!row.freeze) {
        const resource = schedulerData.resources.find((r) => r.id === row.slotId);
        const shifts = resource && resource.shifts;

        row.headerItems.forEach((col, idx) => {
          const date = moment(col.time, 'YYYY-MM-DD');
          const dayShifts = shifts.filter((shift: IShift) => moment(shift.Date, 'DD/MM/YYYY').isSame(date));
          if (isNull(dayShifts)) {
            frozenCellsData.push({
              itemIndex: idx,
              slotId: row.slotId,
              freeze: true,
              freezeMessage: 'Not Rostered'
            });
          }
        });
      }
    });

    schedulerData.setFrozenCells(frozenCellsData);
    triggerSchedulerDataChange();

  }

  unfreezeHeaderItems = () => {
    const { schedulerData, triggerSchedulerDataChange } = this.props;
    schedulerData.setFrozenCells([]);
    triggerSchedulerDataChange();
  }

  handlePostRoster = () => {
    if (!this.state.isPostModalOpen) {
      this.setState({ isPostModalOpen: true });
    }
  }

  handleNew = () => {
    if (this.state.isNewJobModalOpen) return;
    this.setState(
      { isNewJobModalOpen: true },
      () => { this.props.changeOperationMode(Operation.EDIT); }
    );

  };

  handleUpcomingJobs = () => {
    this.setState(
      { isUpcomingJobModalOpen: true },
      () => { this.props.changeOperationMode(Operation.BROWSE); }
    );

  };
  handleReviewJob = () => {
    if (this.state.isReviewJobModalOpen) return;
    this.setState(
      { isReviewJobModalOpen: true },
      () => { this.props.changeOperationMode(Operation.EDIT); }
    );

  };

  todayClick = () => {
    const { schedulerData, salesEntity, setSchedulerData, getSchedulerResources, getSchedulerActivities } = this.props;

    schedulerData.setDate();

    if (getSchedulerResources && salesEntity) {
      getSchedulerResources(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (getSchedulerActivities && salesEntity) {
      getSchedulerActivities(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (setSchedulerData) {
      setSchedulerData(schedulerData);
    }
    this.setState(() => { this.props.changeOperationMode(Operation.EDIT); });
  }

  prevClick = () => {
    const { schedulerData, salesEntity, setSchedulerData, getSchedulerResources, getSchedulerActivities } = this.props;

    schedulerData.prev();

    if (getSchedulerResources && salesEntity) {
      getSchedulerResources(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (getSchedulerActivities && salesEntity) {
      getSchedulerActivities(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (setSchedulerData) {
      setSchedulerData(schedulerData);
    }
    this.setState(() => { this.props.changeOperationMode(Operation.EDIT); });
  }

  nextClick = () => {
    const { schedulerData, salesEntity, setSchedulerData, getSchedulerResources, getSchedulerActivities } = this.props;

    schedulerData.next();

    if (getSchedulerResources && salesEntity) {
      getSchedulerResources(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (getSchedulerActivities && salesEntity) {
      getSchedulerActivities(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (setSchedulerData) {
      setSchedulerData(schedulerData);
    }
    this.setState(() => { this.props.changeOperationMode(Operation.EDIT); });

  }

  moveEvent = (schedulerData: SchedulerData, event: { id: any }, slotId: any, slotName: any, newStart: any, newEnd: any) => {
    const { moveSchedulerEvent } = this.props;
    const droppedSlot = schedulerData.renderData.find((d) => d.slotId === slotId);
    const START_DATETIME_FORMAT = '"YYYY-MM-DD hh:mm:ss"';
    const firstFrozenHeaderItem = droppedSlot.headerItems.find((col) => {
      const startDate = moment(col.start, START_DATETIME_FORMAT).format('YYYY-MM-DD');
      const newStartDate = moment(newStart, START_DATETIME_FORMAT).format('YYYY-MM-DD');

      return startDate === newStartDate;
    });
    const frozen = droppedSlot.freeze ? true : firstFrozenHeaderItem && firstFrozenHeaderItem.freeze;
    if (!frozen && moveSchedulerEvent) {
      moveSchedulerEvent(schedulerData, event, slotId, slotName, newStart, newEnd);
    }
    this.unfreezeResources();
  }

  onViewChange = (schedulerData: SchedulerData, view: any) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(events);
    this.props.setSchedulerData(schedulerData);
  }

  onSelectDate = (schedulerData: SchedulerData, date: string) => {
    const { salesEntity, setSchedulerData, getSchedulerResources } = this.props;

    schedulerData.setDate(date);
    if (getSchedulerResources && salesEntity) {
      getSchedulerResources(salesEntity, schedulerData.startDate, schedulerData.endDate);
    }
    if (setSchedulerData) {
      setSchedulerData(schedulerData);
    }
  }

  eventClicked = (_schedulerData: SchedulerData, event: any) => {
    const { setSelectedActivitySchedule } = this.props;

    if (setSelectedActivitySchedule && event) {
      setSelectedActivitySchedule(event.id);
    }
  };

  toggleExpandFunc = (schedulerData: SchedulerData, slotId: any) => {
    schedulerData.toggleExpandStatus(slotId);
    this.props.setSchedulerData(schedulerData);
  }

  eventItemTemplateResolver = (schedulerData: SchedulerData, event: any, bgColor: any, isStart: boolean, isEnd: boolean, mustAddCssClass: any, mustBeHeight: any, agendaMaxEventWidth: any) => {
    return <SchedulerEvent
      schedulerData={schedulerData}
      event={event}
      bgColor={bgColor}
      isStart={isStart}
      isEnd={isEnd}
      mustAddCssClass={mustAddCssClass}
      mustBeHeight={mustBeHeight}
      agendaMaxEventWidth={agendaMaxEventWidth}
      eventItemMouseDown={this.eventItemMouseDown}
      eventItemMouseUp={this.unfreezeResources}
    />;
  }

  eventItemPopoverTemplateResolver = (_schedulerData: SchedulerData, event: any) => {
    const { messageType, message } = event;
    const hasMessage = !isNull(messageType);

    return (hasMessage ?
      <React.Fragment>
        <h3>{messageType}: {message}</h3>
      </React.Fragment> : null);
  }

  slotItemTemplateResolver = (schedulerData: SchedulerData, item: any, slotClickedFunc: (schedulerData: SchedulerData, item: any) => void, _width: any, className: string) => {
    const indents = [];
    for (let i = 0; i < item.indent; i++) {
      indents.push(<span key={`es${i}`} className='expander-space'></span>);
    }
    let indent = <span key={`es${item.indent}`} className='expander-space'></span>;
    if (item.hasChildren) {
      indent = item.expanded ? (
        <ExpandLessIcon key={`es${item.indent}`}
          style={{ verticalAlign: 'bottom' }}
          onClick={() => {
            this.toggleExpandFunc(schedulerData, item.slotId);
          }} />
      ) : (
        <ExpandMoreIcon type='plus-square' key={`es${item.indent}`}
          style={{ verticalAlign: 'bottom' }}
          onClick={() => {
            this.toggleExpandFunc(schedulerData, item.slotId);
          }} />
      );
    }
    indents.push(indent);

    return (
      <div title={item.slotName} className={className} style={{ textAlign: 'left' }}>
        {slotClickedFunc != undefined ? <span className='slot-cell'>{indents}<a className='slot-text' onClick={() => {
          slotClickedFunc(schedulerData, item);
        }}>{item.slotName}</a></span>
          : <span className='slot-cell'>{indents}<span className='slot-text'>{item.slotName}</span></span>}
      </div>
    );
  }

  postRoster = () => {
    const {
      updateRoster, changeOperationMode, salesEntity = '',
    } = this.props;
    const { rosterPeriod, period = 1 } = this.state;

    if (isNull(rosterPeriod)) {
      showSnackBar({ variant: 'error', message: 'Please select Roster date range.' });

      return;
    }

    let StartDate = rosterPeriod;
    const Duration = period;

    if (salesEntity && StartDate && Duration) {
      const dateFormat = 'DD/MM/YYYY';

      StartDate = moment(StartDate, dateFormat).format('YYYY-MM-DD');
      updateRoster({ SalesEntity: salesEntity, StartDate, Duration });
    }

    this.setState({ isPostModalOpen: false });
    changeOperationMode(Operation.BROWSE);
  }

  postRosterActions = () => {

    return [
      {
        title: 'Ok',
        listener: this.postRoster
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: () => {
          this.setState({ isPostModalOpen: false });
          this.props.changeOperationMode(Operation.BROWSE);
        }
      }
    ];
  }

  moveActivityActions = () => {
    const { serviceActivityFormValues } = this.props;

    return [
      {
        iconName: 'Cancel',
        title: 'Cancel',
        listener: () => {
          this.props.moveActivityModalToggle(false);
          this.props.changeOperationMode(Operation.BROWSE);
        }
      }
      ,
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: () => {
          const { serviceActivityFormSyncErrors } = this.props;
          if (!isNull(serviceActivityFormSyncErrors)) {
            showSnackBar({ variant: 'error', message: 'Start Time and End Time are required.' });

            return;
          } else if (moment(serviceActivityFormValues.ActivityStartTime, 'hh:mm a') >= moment(serviceActivityFormValues.ActivityEndTime, 'hh:mm a')) {
            showSnackBar({ variant: 'error', message: 'End Time must be later than Start Time.' });

            return;
          }

          this.props.moveActivityModalToggle(false);
          this.props.moveSchedulerEventConfirm(serviceActivityFormValues.ActivityStartTime, serviceActivityFormValues.ActivityEndTime);
          this.props.changeOperationMode(Operation.BROWSE);
        }
      }];
  }

  onPostModalClose = () => {
    this.setState({ isPostModalOpen: false });
  }

  handleNewJobWizardClose = (): void => {
    this.setState({ isNewJobModalOpen: false });
    this.props.changeOperationMode(Operation.BROWSE);
  }
  handleUpcomingJobWizardClose = (): void => {
    this.setState({ isUpcomingJobModalOpen: false });
    this.props.changeOperationMode(Operation.BROWSE);
  }
  handleReviewJobWizardClose = (): void => {
    this.setState({ isReviewJobModalOpen: false });
    this.props.changeOperationMode(Operation.BROWSE);
  }

  onScheduleActivityModalClose = () => {
    this.props.scheduleActivityModalToggle(false);
    this.props.changeOperationMode(Operation.BROWSE);
  }

  onMoveActivityModalClose = () => {
    this.props.moveActivityModalToggle(false);
  }

  onRosterDateChange = (value: string) => {
    const { period } = this.state;
    this.setState({ rosterPeriod: value });
    this.setState({ rosterPeriodEnd: moment(value, 'DD/MM/YYYY').clone().add(period, 'week').subtract(1, 'day').format('DD/MM/YYYY') });

  }

  onPeriodChange = (value: number) => {
    this.setState({ period: value });
    const { rosterPeriod } = this.state;
    this.setState({ rosterPeriodEnd: moment(rosterPeriod, 'DD/MM/YYYY').clone().add(value, 'week').subtract(1, 'day').format('DD/MM/YYYY') });
  }

  onPostRosterEnter = () => {
    const { getLookupData } = this.props;
    if (isNull(getLookupData)) {
      return;
    }
    this.onPostRosterOptionsUpdate();
  }

  onPostRosterOptionsUpdate = () => {
    const { getLookupData } = this.props;
    const { period } = this.state;
    const rosterPeriodLookup = getLookupData('/CustomTypes/Lookup/RosterPeriod/Search');
    const { Options = [] } = rosterPeriodLookup;
    const rosterPeriod = this.getClosestToCurrentDate(Options);
    this.setState({ rosterPeriod: rosterPeriod });
    this.setState({ period: 1 });
    this.setState({ rosterPeriodEnd: moment(rosterPeriod, 'DD/MM/YYYY').clone().add(period, 'week').subtract(1, 'day').format('DD/MM/YYYY') });
  }

  getClosestToCurrentDate = (options: any): string => {
    if (isNull(options)) {
      return '';
    }

    const currentDate = moment(new Date());
    const differences = options.map((option: any) => {
      const optionDate = moment(option.value, 'DD/MM/YYYY');
      const difference = currentDate.diff(optionDate);

      return Math.abs(difference);
    });
    const closestOptionIndex = differences.indexOf(Math.min(...differences));

    return (options[closestOptionIndex] && options[closestOptionIndex].value) || '';
  }

  closeGeneralActivityModal = () => {
    this.setState({ isGeneralActivityModalOpen: false });
  }

  newEvent = (schedulerData: SchedulerData, slotId: any, _1: any, start: any, end: any) => {
    const selectedResource = schedulerData && schedulerData.resources.find((res: any) => res.id === slotId);

    if (!this.state.isGeneralActivityModalOpen) {
      this.setState({ isGeneralActivityModalOpen: true, generalActivityData: { start, end, resource: selectedResource } });
    }
  }

  eventItemMouseDown = (event: IEventItem) => {
    const { schedulerData, triggerSchedulerDataChange, getSelectedJobDetails } = this.props;

    const draggedActivityCapabilities = (event && event.Capabilities) ? event.Capabilities : [];
    const matchedRows = schedulerData.resources.filter((r) => draggedActivityCapabilities.every((c) => r.Capabilities.includes(c)));
    const unmatchedRows = schedulerData.resources.filter((value) => !matchedRows.includes(value));
    const frozenRowsData = [];

    schedulerData.resources.forEach((r) => {
      if (unmatchedRows.some((row) => row.id === r.id)) {
        frozenRowsData.push({
          slotId: r.id,
          freeze: true,
          freezeMessage: 'Capability requirement not met.'
        });
      }
    });

    getSelectedJobDetails({ ServiceJobId: event.serviceJobId, ActivityId: event.activityId });

    schedulerData.setFrozenRows(frozenRowsData);
    triggerSchedulerDataChange();

    this.eventClicked(schedulerData, event);
  }

  endDrag = (_: any, monitor: any) => {
    const { schedulerData } = this.props;

    if (monitor.getDropResult) {
      const dropResult = monitor.getDropResult();
      if (dropResult && schedulerData.resources.some((d) => d.id === dropResult.slotId)) return;
    }
    this.unfreezeResources();
  }

  unfreezeResources = () => {
    const { schedulerData, triggerSchedulerDataChange } = this.props;
    schedulerData.setFrozenRows([]);
    triggerSchedulerDataChange();
  }

  render(): React.ReactNode {
    const {
      salesEntity,
      openScheduleActivities, newEventData, newSchedulerEventConfirm, scheduleActivityModalToggle,
      openMoveActivities, moveEventData, moveSchedulerEventConfirm, moveActivityModalToggle, isSchedulerLoading } = this.props;
    const { isPostModalOpen, rosterPeriod, period, rosterPeriodEnd, isGeneralActivityModalOpen, generalActivityData } = this.state;

    return (
      <div style={
        {
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'flex-start',
          height: 'calc(100% - 40px - 67px)',
        }
      }>
        {isSchedulerLoading && <LinearProgress style={inlineStyles.schedulerPreLoader} color='secondary' />}
        {this.state.isNewJobModalOpen && <NewJobWizard
          open={this.state.isNewJobModalOpen}
          forNewJob={true}
          handleClose={this.handleNewJobWizardClose}
        />}
        {this.state.isUpcomingJobModalOpen && <NewJobWizard
          open={this.state.isUpcomingJobModalOpen}
          forNewJob={false}
          handleClose={this.handleUpcomingJobWizardClose}
        />}
        {this.state.isReviewJobModalOpen && <ReviewJobWizard
          open={this.state.isReviewJobModalOpen}
          handleClose={this.handleReviewJobWizardClose}
        />}
        <ScheduleActivityModal
          open={openScheduleActivities}
          eventData={newEventData}
          schedulerEventConfirm={newSchedulerEventConfirm}
          scheduleActivityModalToggle={scheduleActivityModalToggle}
          handleClose={this.onScheduleActivityModalClose}
        />
        <ScheduleActivityModal
          open={openMoveActivities}
          eventData={moveEventData}
          schedulerEventConfirm={moveSchedulerEventConfirm}
          scheduleActivityModalToggle={moveActivityModalToggle}
          handleClose={this.onMoveActivityModalClose}
        />
        {isPostModalOpen && <FormViewModal
          open={isPostModalOpen}
          loading={false}
          title={'Post Roster'}
          onEnter={this.onPostRosterEnter}
          modalContent={<PostRosterModal
            entity={salesEntity}
            rosterPeriod={rosterPeriod}
            rosterPeriodEnd={rosterPeriodEnd}
            period={period}
            onRosterDateChange={this.onRosterDateChange}
            onOptionsUpdate={this.onPostRosterOptionsUpdate}
            onPeriodChange={this.onPeriodChange}
          />}
          actions={this.postRosterActions()}
          dialogActionsButtons={true}
          dialogActionsShadow={false}
        />}
        {isGeneralActivityModalOpen && <GeneralAcivityModal
          open={isGeneralActivityModalOpen}
          data={generalActivityData}
          handleClose={this.closeGeneralActivityModal} />}
        <Scrollbars>
          <Scheduler
            schedulerData={this.props.schedulerData}
            dndSources={this.props.dndSources}
            endDrag={this.endDrag}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            moveEvent={this.moveEvent}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            endResizable={false}
            newEvent={this.newEvent}
            eventItemTemplateResolver={this.eventItemTemplateResolver}
            slotItemTemplateResolver={this.slotItemTemplateResolver}
            eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
          />
        </Scrollbars>
      </div>);
  }
}

export default withRouter(ActivityScheduler);
