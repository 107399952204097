
import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, List, ListItem, ListItemText, StyledComponentProps, Table, TableBody, TableCell, TableRow, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';
import { creditStatusDialogStyles, customerStatusStyles } from './SummaryPanel.styles';
import FocusLock from 'react-focus-lock';

const CreditColumnSpan = 2;

interface ISimpleDialogProperties extends StyledComponentProps<keyof typeof creditStatusDialogStyles> {
    open: boolean;
    message?: string;
    onClose(): void;
}

const CreditStatusDialog = (props: ISimpleDialogProperties): JSX.Element => {
    const { classes, onClose, message, ...other } = props;

    return (
        <Dialog onClose={onClose} {...other}
            classes={{
                paper: classes.dialogStyles
            }}
        >
            <DialogTitle>Credit Message</DialogTitle>
            <FocusLock>
                <>
                    <List>
                        {
                            message.split('\n').map((item: string) =>
                                <ListItem key={item}>
                                    <ListItemText primary={item}
                                        classes={{ primary: classes.creditStatus }} />
                                </ListItem>
                            )
                        }
                    </List>
                    <DialogActions>
                        <Button onClick={onClose} variant='contained' autoFocus
                            classes={{ raised: classes.button }}>
                            OK
                        </Button>
                    </DialogActions>
                </>
            </FocusLock>
        </Dialog >
    );
};

const CreditStatusDialogStyled = withStyles(creditStatusDialogStyles, { index: 1 })(CreditStatusDialog);

interface ICustomerStatusProperties extends StyledComponentProps<keyof typeof customerStatusStyles> {
    CreditStatus: string;
    AvailableCredit: string;
    CreditMessage: string;
}
interface IFormatAvailableCredit {
    Value: string;
    type: string;
}

const CustomerStatus = (props: ICustomerStatusProperties) => {
    const { classes, CreditStatus, AvailableCredit, CreditMessage } = props;
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const creditStatusCell = React.useMemo(
        () => (CreditStatus === 'Alert' || CreditStatus === 'Block') ?
            classNames(classes.dataCell, classes.creditStatusCell, classes.creditStatusCellHover) :
            classes.dataCell,
        []
    );

    const formatAvailableCredit = React.useMemo(
        (): IFormatAvailableCredit => (Number.isFinite(Number(AvailableCredit))) ?
            { Value: Number(AvailableCredit).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }), type: 'numericCell' } :
            { Value: AvailableCredit, type: 'stringCell' },
        [AvailableCredit]
    );

    return (
        <>
            <Table className={classes.summaryTable}>
                <TableBody>
                    <TableRow className={classNames(
                        classes.table1HeaderRow,
                        classes.table1TD
                    )}>
                        <TableCell colSpan={CreditColumnSpan} className={classNames(
                            classes.headers,
                            classes.headerCell
                        )}>CREDIT STATUS</TableCell>
                        <TableCell colSpan={CreditColumnSpan} className={classNames(
                            classes.headers,
                            classes.headerCell
                        )} padding='none'>AVAILABLE CREDIT</TableCell>
                    </TableRow>
                    <TableRow className={classes.table1DataRow}>
                        <TableCell
                            colSpan={CreditColumnSpan}
                            className={creditStatusCell}
                        >
                            <div style={{ position: 'relative', height: '100%', textAlign: 'center' }} >
                                {(CreditStatus === 'Alert' || CreditStatus === 'Blocked') ?
                                    <WarningIcon
                                        className={classes.iconWarning}
                                        style={{ right: '45', position: 'absolute', top: '-2px' }}
                                        onClick={(CreditStatus === 'Alert' || CreditStatus === 'Blocked')
                                            ? () => setDialogOpen(true) : undefined} />
                                    : 'OK'}
                            </div>
                        </TableCell>
                        <TableCell numeric colSpan={CreditColumnSpan} className={classes.dataCell} padding='none' style={{ ...customerStatusStyles.numericDataCell, ...customerStatusStyles[formatAvailableCredit.type] }}>
                            {formatAvailableCredit.Value}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <CreditStatusDialogStyled open={dialogOpen} onClose={() => setDialogOpen(false)} message={(CreditMessage) || ''} />
        </>
    );
};

export default withStyles(customerStatusStyles, { index: 1 })(CustomerStatus);
