import { createStyles } from '@material-ui/core/styles';

export const classStyles = createStyles({
    dialogIncludedSummaryTable: {
        width: 'calc(800px + 32px)',
        maxWidth: 1500,
        padding: '2px 0 0 0',
        overflow: 'hidden'
    },
    dialog: {
        maxWidth: 'calc(80vw + 32px)',
        overflow: 'hidden'
    },
    dialogActionBar: {
        backgroundColor: '#dddddd',
        borderRadius: 0.7,
        minHeight: '52px',
        height: 'auto',
        margin: 'unset',
        display: 'flex',
        textAlign: 'center',
        zIndex: 1,
    },
    modalContent: {
        'height': 'fit-content',
        'overflowY': 'visible',
        'padding': 0,
        'position': 'relative',
        '&:first-child': {
            paddingTop: 0
        }
    },
    actionBtn: {
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'capitalize'
    },
    dialogTitleHeading: {
        fontSize: 22,
        paddingBottom: 6,
    },
    dialogSubTitleHeading: {
        fontSize: 16,
    },
    dialogTitle: {
        padding: '10px 0px 12px 22px',
        backgroundColor: '#dddddd',
        zIndex: 1,
    },
    iconStyle: {
        height: '40px',
        width: '40px',
        fill: '#000000'
    },
    disabledIconStyle: {
        height: '40px',
        width: '40px',
        fill: 'rgba(0, 0, 0, 0.54)'
    },
    buttonTitle: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center'
    },
    action: {
        'border': '1px solid #777777',
        'background': '#F5F5F5',
        'color': 'black',
        'borderRadius': '4px',
        'textTransform': 'capitalize',
        'padding': '8px 16px',
        'margin-bottom': '14px',
        '&:focus': {
          background: '#F47920',
          border: '1px solid #F47920',
          color: 'white'
        }
      },
    active: {
        'border': '1px solid #F47920',
        'background': '#F47920',
        'color': 'white',
        'borderRadius': '4px',
        'textTransform': 'capitalize',
        'padding': '8px 16px',
        'margin-bottom': '14px',
        '&:hover': {
            background: '#F47920',
            // border: '1px solid #F47920',
            // color: 'white'
          }
      },
});

export const headingStyle = (dialogSubHeading: boolean) => ({ paddingBottom : dialogSubHeading ? '8' : '' });

export const titleStyle = (dialogSubHeading: boolean, dialogActionsShadow: boolean) => {

    return { height : dialogSubHeading ? '44' : '30', boxShadow: (dialogActionsShadow) ? '0 0 7px 0 rgba(0, 0, 0, 0.65)' : '' };
};
