import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as kittingSelectors, actions as kittingActions } from 'ducks/inventoryEnquiry/stocking/kitting';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { selectors as quantitySelectors } from 'ducks/inventoryEnquiry/stocking/quantities';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { fetch } from 'api/inventoryEnquiry/stocking/kitting';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state) => ({
  gridName: 'IEStockingQuantitiesKitting',
  columnDefs: kittingSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: kittingSelectors.gridOptions(state),
  filterRow: kittingSelectors.filterRow(state),
  selectedFilters: kittingSelectors.selectedFilters(state),
  appliedFilters: kittingSelectors.filterRow(state) && filterSelectors.getFilters(state, kittingSelectors.filterRow(state).formName),
  reqParams: (productSelectors.selected(state) || parseInt(params.get('ProductId'))) && (quantitySelectors.selected(state) || params.get('Warehouse')) &&
    {
      ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || parseInt(params.get('ProductId')),
      Warehouse: (quantitySelectors.selected(state) && quantitySelectors.selected(state).Warehouse) || params.get('Warehouse')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: kittingActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
};
