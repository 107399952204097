import { asyncSelectors, deleteKey, IExtendedState, initializeReducer } from './utils';
import { ICustomerContactDetails, ISortOrder } from 'api/swaggerTypes';
import { Inline, IObjectified } from 'api/utils';
import { IObjectifiedCustomerContactDetailsResponse } from 'api/contact';

export interface IContactData {
  selected: number;
  list: ICustomerContactDetails[];
  schemas: Inline<ICustomerContactDetails>[];
  selectedSortFilter?: string;
  selectedSortDirection?: string;
}

export interface IContactState extends IExtendedState<IContactData> { }

const NOT_SELECTED = -1;

const initialData: IContactData = {
  selected: NOT_SELECTED,
  list: [],
  schemas: [],
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'contact',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (contactId: number) => contactId,
      callback: (data: IContactData, contactId: number) => ({ ...data, selected: contactId })
    },
    changeSortFilter: {
      action: (filter: string) => filter,
      callback: (data: IContactData, filter: string) => ({ ...data, selectedSortFilter: filter })
    },
    changeSortDirection: {
      action: (direction: string) => direction,
      callback: (data: IContactData, direction: string) => ({ ...data, selectedSortDirection: direction })
    }
  },
  asyncActions: {
    search: {
      action: (query: { SearchText: string; CustomerId: number; Sort: ISortOrder }) => (query),
      successCallback: (data: IContactData, payload: IObjectified<ICustomerContactDetails>[]): IContactData => {
        const contacts = payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.map((combinedObject) => combinedObject.schema);

        return { ...data, list: contacts, schemas: schemas };
      }
    },
    searchById: {
      action: (query: { CustomerId: number; ContactNumber: number }) => ({ CustomerId: query.CustomerId, ContactNumber: query.ContactNumber }),
      callback: (data: IContactData, _: { CustomerId: number; ContactNumber: number }) => ({ ...data, selected: NOT_SELECTED }),
      successCallback: (data: IContactData, payload: IObjectified<ICustomerContactDetails>[]): IContactData => {
        const contacts = payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.map((combinedObject) => combinedObject.schema);

        return { ...data, list: contacts, schemas: schemas };
      }
    },
    create: {
      action: (CustomerId: number, values: ICustomerContactDetails) => ({ CustomerId, values }),
      successCallback: (data: IContactData, payload: IObjectifiedCustomerContactDetailsResponse): IContactData => {
        const newContact = payload.CustomerContactDetails?.inlineObject;
        const newSchema = payload.CustomerContactDetails?.schema;
        const prevSchemas = data.schemas;
        const prevUpdate = newContact.Primary && (data.list.find((item) => item.ContactType === newContact.ContactType && item.Primary));
        const prevContacts = (prevUpdate && deleteKey(data.list, prevUpdate.ContactNumber, 'ContactNumber', { ...prevUpdate, Primary: false })) || data.list;
        prevSchemas.push(newSchema);
        prevContacts.push(newContact);

        return { ...data, list: prevContacts, schemas: prevSchemas, selected: newContact.ContactNumber };
      }
    },
    update: {
      action: (CustomerId: number, ContactNum: number, values: ICustomerContactDetails) => ({ CustomerId, ContactNum, values }),
      successCallback: (data: IContactData, payload: IObjectifiedCustomerContactDetailsResponse): IContactData => {
        const updatedContact = payload.CustomerContactDetails?.inlineObject;
        const updatedSchema = payload.CustomerContactDetails?.schema;
        const prevUpdate = updatedContact.Primary && (
          data.list.find((item) => item.ContactType === updatedContact.ContactType && item.Primary && item.ContactNumber !== updatedContact.ContactNumber)
        );
        const prevContacts = deleteKey(
          ((prevUpdate && deleteKey(data.list, prevUpdate.ContactNumber, 'ContactNumber', { ...prevUpdate, Primary: false })) || data.list),
          updatedContact.ContactNumber,
          'ContactNumber',
          updatedContact
        );
        const prevSchemas = deleteKey(data.schemas, updatedSchema.ContactNumber.Value, 'ContactNumber', updatedSchema);

        return {
          ...data,
          list: prevContacts,
          schemas: prevSchemas,
        };
      }
    },
    remove: {
      action: (CustomerId: number, ContactNum: number) => ({ CustomerId, ContactNum }),
      successCallback: (data: IContactData, payload: number): IContactData => {
        const prevContacts = deleteKey(data.list, payload, 'ContactNumber');
        const prevSchemas = deleteKey(data.schemas, payload, 'ContactNumber');

        return { ...data, list: prevContacts, schemas: prevSchemas };
      }
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { contact: IContactState }) => state.contact,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ContactNumber === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ContactNumber.Value === data.selected)) || null,
  }
);

const syncSelector = {
  notifications: (state: { contact: IContactState }) => state.contact.notifications,
  error: (state: { contact: IContactState }) => state.contact.error
};

export const selectors = { ...asyncSelector, ...syncSelector };
