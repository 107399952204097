const SummaryTableSchema = [
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Product',
                fieldNames: [
                    'ProductIdDisplay',
                    'ProductIdDisplayLabel'
                ],
                valueRenderer: {
                    api: {
                        api: '/inventoryEnquiry/lookup/action',
                        name: 'enquiry',
                        params: {
                            queryFields: {
                                ProductId: 'ProductId',
                                SearchText: 'ProductCode'
                            }
                        }
                    }
                }
            },
            {
                label: 'Product GROUP',
                fieldNames: [
                    'ProductGroup',
                    'ProductGroupLabel'
                ]
            },
        ]
    },
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'STOCKING UNIT',
                fieldNames: [
                    'StockingUnit'
                ],
            },
            {
                label: 'LAST COST',
                fieldNames: [
                    'LastCostDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'EXTENDED TOTAL',
                fieldNames: [
                    'ExtendedTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true,
                    emphasize: true
                }
            },
        ]
    }
];

export default SummaryTableSchema;
