import { IFormViewForm } from 'components/FormView';

const FinancialForm: IFormViewForm = {
  id: 'CustomerFinancial',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'credit'
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Credit type',
            name: 'CreditCode',
            lookupName: 'CreditCode',
            required: true,
            size: 'small',
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Credit limit',
            name: 'CreditLimit',
            alignment: 'right',
            inputProps: {
              maxLength: 14
            }
          }
        },
        {
          id: '3',
          type: 'AUTOCOMPLETE_FIELD',
          props: {
            label: 'Accounting method',
            required: true,
            name: 'AccountingMethod',
            isClearable: false,
            apiPrefs: {
              path: '/CustomTypes/Lookup/AccountingMethod',
              method: 'GET',
              params: null
            },
          }
        },
        {
          id: '4',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Currency',
            name: 'CurrencyCode',
            lookupName: 'CurrencyCode',
            required: true,
            size: 'small',
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          id: '5',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            isLookupTypeFixed: true,
            lookupName: 'TaxClass',
            required: true,
            size: 'small',
            inputProps: {
              maxLength: 8
            }
          }
        },
        {
          id: '6',
          type: 'TEXT_FIELD',
          props: {
            label: 'Tax exempt reference',
            name: 'TaxExemptRef',
            inputProps: {
              maxLength: 15
            }
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        name: 'bill'
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Bill to account',
            name: 'BillToId',
            required: false,
            size: 'small',
            lookupName: 'Customer',
            inputProps: {
              maxLength: 10
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Default chart account',
            name: 'DefaultChartAccount',
            required: false,
            size: 'small',
            lookupName: 'ChartOfAccount',
            hideInV3: true,
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Default chart account',
            name: 'ChartOfAccount',
            required: false,
            size: 'small',
            lookupName: 'ChartOfAccountNonConsolidated',
            hideInV2: true,
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          id: '6',
          type: 'TEXT_FIELD',
          props: {
            label: 'Sub Account',
            name: 'SubAcc',
            inputProps: {
              maxLength: 15
            },
            hideInV3: true
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Supplier',
            name: 'SupplierId',
            required: false,
            size: 'small',
            lookupName: 'Supplier',
            inputProps: {
              maxLength: 10
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Bank details',
        name: 'bankDetails',
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Bank',
            name: 'BankCode',
            size: 'small',
            required: false,
            inputProps: {
              maxLength: 3
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Branch',
            name: 'Branch',
            required: false,
            inputProps: {
              maxLength: 20
            }
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Payee',
            name: 'Payee',
            size: 'large',
            fullWidth: true,
            required: false,
            inputProps: {
              maxLength: 35
            }
          }
        }
      ]
    },
  ]
};
export default FinancialForm;
