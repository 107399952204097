import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (id) => ({ id }),
  asyncs: {
    fetchSupplierPricingDetails: ({ SupplierId }) => ({ SupplierId }),
    fetchSupplierPricingSummary: ({ SupplierId }) => ({ SupplierId }),
  }
}, 'pricing')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,

  gridOptions: {
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Product price code', field: 'ProductPriceCode', cellRenderer: 'agGroupCellRenderer' },
    { headerName: 'Description', field: 'ProductCodeDescription', suppressSorting: true },
    { headerName: 'Type', field: 'Type', suppressSorting: true },
    { headerName: 'Percent', field: 'PercentDisplay', type: 'numericColumn', suppressSorting: true }
  ],
  detailRowHeight: 380,
  detailCellRendererParams: {
    detailGridOptions: {
      columnDefs: [
        { headerName: 'Break level', field: 'BreakLevel', suppressSorting: true },
        { headerName: 'Quantity', field: 'BreakQuantity', type: 'numericColumn', suppressSorting: true },
        { headerName: 'Percent', field: 'BreakPercentDisplay', type: 'numericColumn', suppressSorting: true }
      ],
      sideBar: false,
      onGridReady: function (params) {
        params.columnApi.autoSizeColumns();
      }
    },
    toolPanelSuppressSideButtons: true,
    getDetailRowData: function (params) {
      params.successCallback(params.data.Breaklevels);
    }
  },
  list: [],
  summary: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchSupplierPricingDetails:
    case types.fetchSupplierPricingSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSupplierPricingDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          list: action.payload
        }
      })
    case types.saga.fetchSupplierPricingSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          summary: action.payload
        }
      })
    case types.saga.fetchSupplierPricingDetails.failure:
    case types.saga.fetchSupplierPricingSummary.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.supplierEnquiry.pricing,
  {
    list: data => data.list || [],
    selected: data => (data.list && data.selected) || null,
    summary: data => data.summary
  }
)

const syncSelector = {
  loadingSupplierPricingDetails: state => state.supplierEnquiry.pricing.fetchSupplierPricingDetails_loading,
  loadingSupplierPricingSummary: state => state.supplierEnquiry.pricing.fetchSupplierPricingSummary_loading,
  gridOptions: state => state.supplierEnquiry.pricing.data.gridOptions,
  columns: state => state.supplierEnquiry.pricing.data.columns,
  detailColumns: state => state.supplierEnquiry.pricing.data.detailCellRendererParams,
  detailRowHeight: (state) => state.supplierEnquiry.pricing.data.detailRowHeight,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

