import * as React from 'react';
import FormView from 'components/FormView';
import { IInvoiceLineBOMFields } from '../interfaces';

class InvoiceLineBOM extends React.PureComponent<IInvoiceLineBOMFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-invoice-enquiry/invoice-lines/line-details/bill-of-material':
          onInitialLoad('InvoiceLineBOM');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details/bill-of-material/component-details':
          onInitialLoad('InvoiceLineBOMComponent');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details/bill-of-material/component-details/serials':
          onInitialLoad('InvoiceLineBOMComponentSerials');
          break;
        case '/sales-invoice-enquiry/invoice-lines/line-details/bill-of-material/component-details/lots':
          onInitialLoad('InvoiceLineBOMComponentLots');
          break;
        default:
          onInitialLoad('InvoiceLineBOM');
      }
    }
  }

  getValuesSchema = () => {
    const { selectedTab, bomDetails, invoiceDetails } = this.props;
    switch (selectedTab) {
      case 'InvoiceDetails':
        return invoiceDetails && invoiceDetails.schema;
      case 'InvoiceLineBOMComponent':
        return { Supplied: { Label: bomDetails && bomDetails.UnitCode } };
      default:
        return null;
    }
  }
  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, bomDetails, summaryTableRenderer } = this.props;
    const valuesSchema = this.getValuesSchema();
    const initialValues = bomDetails;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default InvoiceLineBOM;
