import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as quotesSelector, actions as quotesAction } from 'ducks/customerEnquiry/quotes';
import { selectors as customerSelector } from 'ducks/customer';
import { fetch } from 'api/customerEnquiry/quotes';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'Quotes',
  columnDefs: quotesSelector.Columns(state),
  rowData: quotesSelector.Data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: quotesSelector.gridOptions(state),
  filterRow: quotesSelector.filterRow(state),
  selectedFilters: quotesSelector.selectedFilters(state),
  isLoading: quotesSelector.isLoading(state),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  appliedFilters: quotesSelector.filterRow(state) && filterSelectors.getFilters(state, quotesSelector.filterRow(state).formName),
  apiMethod: fetch
});
export const actions = {
  deleteSelectedRecords: quotesAction.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: quotesAction.setSelected,
  applyFilters: filterActions.applyFilters
}
