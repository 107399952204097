export default [
  {
    id: '1',
    label: 'Return For Credit Enquiry in new tab',
    action: (params?: { RFCNumber: number }) => {
      window.open(`/rfc-enquiry/details?RFCNumber=${params && params.RFCNumber}`);
    }
  },
  {
    id: '2',
    label: 'Return For Credit Enquiry',
    action: (params?: { RFCNumber: number }) => {
      window.location.assign(`/rfc-enquiry/details?RFCNumber=${params && params.RFCNumber}`);
    }
  }
];
