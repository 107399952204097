import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
  reset: () => null,
  asyncs: {
  }
}, 'binLocations');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
    disableExcelExport: false
  },
  Columns: [
    { headerName: 'Bin', field: 'Bin', cellRenderer: 'agGroupCellRenderer', minWidth: 150 },
    { headerName: 'Bin type', field: 'BinType', suppressSorting: true, minWidth: 200, },
    { headerName: 'On hand', field: 'QuantityOnHandDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Capacity', field: 'CapacityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Description', field: 'Description', minWidth: 350, suppressSorting: true, },
  ],
  Data: [],
  selectedFilters: {
    Warehouse: 'QW',
    BinType: 'All'
  },
  filterRow: {
    formName: 'IEBinLocationsFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Bin type',
          name: 'BinType',
          required: true,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Primary',
              label: 'Primary'
            },
            {
              value: 'Secondary',
              label: 'Secondary'
            },
            {
              value: 'Bulk',
              label: 'Bulk'
            }
          ]
        }
      },
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setWarehouse:
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: warehouse
          }
        }
      };

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.binLocations,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.binLocations.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.binLocations.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.binLocations.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.binLocations.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.binLocations.data.selectedFilters,
  detailCellRenderer: state => state.inventoryEnquiry.stocking.binLocations.data.detailCellRenderer,
  detailColumns: state => state.inventoryEnquiry.stocking.binLocations.data.detailCellRendererParams,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)