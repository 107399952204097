import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './RunDynamicExtract.actions';
import reducer, { initialState, IState } from './RunDynamicExtract.reducer';

export const RunDynamicExtract = createContext<IState>(initialState);
export const RunDynamicExtractDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const useDEDispatch = () => useContext(RunDynamicExtractDispatchContext);
export const useDEState = () => useContext(RunDynamicExtract);
export const useDEContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(RunDynamicExtract, selector);

interface IPickSlipsStateProvider {
  children: React.ReactNode;
}

const ExtractMaintenanceProvider = ({ children }: IPickSlipsStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <RunDynamicExtract.Provider value={state}>
      <RunDynamicExtractDispatchContext.Provider value={dispatch}>
        {children}
      </RunDynamicExtractDispatchContext.Provider>
    </RunDynamicExtract.Provider>
  );
};

export default ExtractMaintenanceProvider;
