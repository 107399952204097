import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';

import ActionBar from '../common/ActionBar/index';
import OptionsMenu from '../common/OptionsMenu/index';
import PSESearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/PickSlip';
import SlipDetails from './SlipDetails';
import SlipLines from './SlipLines';

import { shallowCompare } from 'utils/utils';

import BreadcrumbBar from '../common/BreadcrumbBar/index';
import * as styles from './PickSlipEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from 'utils/operations';
import { IPSEViewProperties, IPSEState } from './interfaces';
import { MODULE_TREE } from './constants';
import SummaryPanel from './SummaryPanel';
import NotePad from './NotePad';
import Diary from './Diary';
import Events from './Events';

const inlineStyles = createStyles({
  PSESection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
});

class PickSlipEnquiryView extends React.Component<IPSEViewProperties, IPSEState> {

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'pick-slip-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab === 'SlipLineDetails' || selectedTab === 'SlipLineBOMDetails') {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any; fetchChildRecords?(params: any): void } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'pick-slip-enquiry';
    const DespatchId = params.get('DespatchId') || this.props.selectedDespatchId || '';
    const DiaryId = params.get('DiaryId') || '';

    return ({
      SlipDetails: { pathname: `/${context}/slip-details`, search: `?DespatchId=${DespatchId}`, component: SlipDetails, fetchChildRecords: this.fetchSlipDetail },
      SlipLines: { pathname: `/${context}/slip-lines`, search: `?DespatchId=${DespatchId}`, component: SlipLines },
      SlipLineDetails: { pathname: `/${context}/slip-lines/details`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}`, component: SlipLines, fetchChildRecords: this.fetchSlipLineDetails },
      Lots: { pathname: `/${context}/slip-lines/lots`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}`, component: SlipLines },
      Serials: { pathname: `/${context}/slip-lines/serials`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}`, component: SlipLines },
      SlipLineBOM: { pathname: `/${context}/slip-lines/details/bom`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}`, component: SlipLines },
      SlipLineBOMDetails: { pathname: `/${context}/slip-lines/details/bom/component-details`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}&&BOMLineNumber=${params.get('BOMLineNumber')}`, component: SlipLines, fetchChildRecords: this.fetchSlipLineBOMDetails },
      SlipLineBOMLots: { pathname: `/${context}/slip-lines/details/bom/lots`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}&&BOMLineNumber=${params.get('BOMLineNumber')}`, component: SlipLines },
      SlipLineBOMSerials: { pathname: `/${context}/slip-lines/details/bom/serials`, search: `?DespatchId=${DespatchId}&&LineNumber=${params.get('LineNumber')}&&BOMLineNumber=${params.get('BOMLineNumber')}`, component: SlipLines },
      Notepad: { pathname: `/${context}/notepad`, search: `?DespatchId=${DespatchId}`, component: NotePad },
      SalesDiary: { pathname: `/${context}/diary`, search: `?DespatchId=${DespatchId}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/diary/diary-details`, search: `?DespatchId=${DespatchId}&DiaryId=${DiaryId}`, component: Diary },
      Events: { pathname: `/${context}/events`, search: `?DespatchId=${DespatchId}`, component: Events },
      null: { pathname: `/${context}`, search: `?DespatchId=${DespatchId}`, component: SlipDetails },
    });
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption, isMenuOptionOpen } = this.props;
    toggleMenuOption(!isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
    this.getDetailsSummary();
  }

  shouldComponentUpdate(nextProps: Readonly<IPSEViewProperties>, nextState: Readonly<IPSEState>): boolean {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: Readonly<IPSEViewProperties>): void {
    const { operationMode, selectedTab, changeSelectedForm, selectedForm } = this.props;
    if (prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction(prevProps);
    }
    if (!selectedForm) {
      changeSelectedForm('pick-slip-enquiry', selectedTab);
    }
  }

  backAction = (prevProps: Readonly<IPSEViewProperties>) => {
    const { dirty, selectedTab, changeConfirmationDialog, changeOperationMode, history, resetForm } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          if (selectedTab === 'SlipDetails') {
            history.push('/dashboard');
          }
          this.handleModuleChange('SlipDetails');
          changeOperationMode(Operation.BROWSE);
          resetForm();
        }
      });
    } else {
      if (selectedTab) {
        const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
        if (!targetModule || !targetModule.parent || targetModule.parent === '') {
          if (selectedTab === 'SlipDetails') {
            history.push('/dashboard');
          } else {
            this.handleModuleChange('SlipDetails');
          }
        } else {
          this.handleModuleChange(targetModule.parent);
        }
      }
      changeOperationMode(Operation.BROWSE);
    }
  }

  fetchSlipDetail = () => {
    const { selectedDespatchId, location } = this.props;
    const params = (new URLSearchParams(location.search));
    const despatchId = selectedDespatchId || Number(params.get('DespatchId'));

    if (despatchId > 0) {
      this.props.fetchSlipDetails(despatchId);
    }
  }

  fetchSlipLineDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSlipLineDetails, selectedPickSlip, getSlipLineDetailsSummary } = this.props;
    const DespatchId = (selectedPickSlip && selectedPickSlip.DespatchId.toString()) || params.get('DespatchId') || '';
    const LineNumber = params.get('LineNumber') || '';
    if (DespatchId && LineNumber && getSlipLineDetails && getSlipLineDetailsSummary) {
      getSlipLineDetailsSummary({ DespatchId: parseInt(DespatchId), LineNumber: parseInt(LineNumber) });
      getSlipLineDetails({ DespatchId: parseInt(DespatchId), LineNumber: parseInt(LineNumber) });
    }
  }

  fetchSlipLineBOMDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSlipLineBOMDetails, selectedPickSlip, getSlipLineBOMDetailsSummary } = this.props;
    const DespatchId = (selectedPickSlip && selectedPickSlip.DespatchId.toString()) || params.get('DespatchId') || '';
    const BOMLineNumber = params.get('BOMLineNumber') || '';
    if (DespatchId && BOMLineNumber && getSlipLineBOMDetails && getSlipLineBOMDetailsSummary) {
      getSlipLineBOMDetailsSummary({ DespatchId: parseInt(DespatchId), BOMLineNumber: parseInt(BOMLineNumber) });
      getSlipLineBOMDetails({ DespatchId: parseInt(DespatchId), BOMLineNumber: parseInt(BOMLineNumber) });
    }
  }

  fetchChildRecords = (selectedTab: string) => {
    const routeData = this.getRoute()[selectedTab];
    if (routeData && routeData.fetchChildRecords) {
      routeData.fetchChildRecords(null);
    }
  }

  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      case 'SlipLineDetails':
      case 'SlipLineBOM':
      case 'Lots':
      case 'Serials': return options.SlipLineDetails;
      case 'SlipLineBOMLots':
      case 'SlipLineBOMSerials':
      case 'SlipLineBOMDetails': return options.SlipLineBOM;
      default:
        return options.PSE;
    }
  }

  onPickSlipChange = (): void => {
    const { selectedTab } = this.props;

    this.fetchChildRecords(selectedTab);
    this.getDetailsSummary();
    switch (selectedTab) {
      case 'SlipLineBOMDetails':
      case 'SlipLineBOMSerials':
      case 'SlipLineBOMLots': this.getSlipLineBOMDetailsSummary(); break;
      default: this.getSlipLineDetailsSummary();
    }
  }

  getDetailsSummary = () => {
    const { selectedDespatchId, location } = this.props;
    const params = (new URLSearchParams(location.search));
    const despatchId = selectedDespatchId || Number(params.get('DespatchId'));
    if (despatchId > 0) { this.props.getPickSlipSummary(despatchId); }
  }

  getSlipLineDetailsSummary = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSlipLineDetails, selectedPickSlip, getSlipLineDetailsSummary } = this.props;
    const DespatchId = (selectedPickSlip && selectedPickSlip.DespatchId.toString()) || params.get('DespatchId') || '';
    const LineNumber = params.get('LineNumber') || '';
    if (DespatchId && LineNumber && getSlipLineDetails && getSlipLineDetailsSummary) {
      getSlipLineDetailsSummary({ DespatchId: parseInt(DespatchId), LineNumber: parseInt(LineNumber) });
    }
  }

  getSlipLineBOMDetailsSummary = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSlipLineBOMDetails, selectedPickSlip, getSlipLineBOMDetailsSummary } = this.props;
    const DespatchId = (selectedPickSlip && selectedPickSlip.DespatchId.toString()) || params.get('DespatchId') || '';
    const BOMLineNumber = params.get('BOMLineNumber') || '';
    if (DespatchId && BOMLineNumber && getSlipLineBOMDetails && getSlipLineBOMDetailsSummary) {
      getSlipLineBOMDetailsSummary({ DespatchId: parseInt(DespatchId), BOMLineNumber: parseInt(BOMLineNumber) });
    }
  }

  handleOptionClick = (selectedTab: string): void => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab(selectedTab);
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.PSEOuter}>
        <div className={styles.PSEContainer}>

          <PSESearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.onPickSlipChange
            ]}
          />
          <div
            style={inlineStyles.PSESection}
            className={styles.PSESection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Pick Slip Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                  formName={'PickSlipEnquiry'}
                />}
              />
            ))}

          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleOptionClick}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'pick-slip-enquiry'} />
      </div>
    );
  }
}

export default withRouter(PickSlipEnquiryView);
