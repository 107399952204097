import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './IEftDetailsForm.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import IEftDetailsFormProps from './IEftDetailsForm.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import TextField from '@markinson/uicomponents-v2/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import { IUpdatePaymentLineDetailFacade } from 'api/swaggerTypes';
import { ObjectifiedPaymentLineDetailsResponse } from 'api/Worksale/payment';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { Operation } from 'utils/operations';

const EftDetailsForm = (props: IEftDetailsFormProps): JSX.Element => {
  const { classes, open, initialValues, schema, paymentDetail, onCalculatePaymentLineDetails, onApply, onCancel, operationMode } = props;

  const processValidationForms = React.useContext(ProcessValidationFormsContext);

  const [values, setValues] = React.useState(initialValues);
  const [amount, setAmount] = React.useState<number>(0);
  const [eftDeviceID, setEftDeviceID] = React.useState<number>(0);
  const [eftDeviceIDDisplay, setEftDeviceIDDisplay] = React.useState<string>('');
  const [cashOut, setCashOut] = React.useState<number>(0);
  const [waiveMerchantFeeSurcharge, setWaiveMerchantFeeSurcharge] = React.useState<boolean>(true);
  const [merchantFeeRecovery, setMerchantFeeRecovery] = React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [reference, setReference] = React.useState<string>('');
  const [calculateInProgress, setCalculateInProgress] = React.useState<boolean>(false);

  React.useEffect(
    () => {
      setValues(initialValues);
      if (initialValues) {
        setAmount(values.Amount ? values.Amount : 0);
        setCashOut(values.CashOut ? values.CashOut : 0);
        setWaiveMerchantFeeSurcharge(values.WaiveMerchantFeeSurcharge || false);
        setReference(values.Reference);
      } else {
        setAmount(0);
        setCashOut(0);
        setWaiveMerchantFeeSurcharge(false);
        setReference('');
      }
    },
    [initialValues]);

  React.useEffect(
    () => {
      if (values) {
        setMerchantFeeRecovery(values.MerchantFeeRecovery ? values.MerchantFeeRecovery : 0);
        setTotalAmount(values.TotalAmount ? values.TotalAmount : 0);
      } else {
        setMerchantFeeRecovery(0);
        setTotalAmount(0);
      }
    },
    [values]);

  async function onCalculatePaymentLineDetailsAndValidate(query: IUpdatePaymentLineDetailFacade, ChangedField: string): Promise<ObjectifiedPaymentLineDetailsResponse> {
    return processValidationForms(await onCalculatePaymentLineDetails(query, ChangedField), query, async (newQuery) => onCalculatePaymentLineDetailsAndValidate(newQuery, ChangedField));
  }

  async function handleValuesChanged(newValues: IUpdatePaymentLineDetailFacade['UpdateLine'], ChangedField: string): Promise<void> {
    if (onCalculatePaymentLineDetails) {
      try {
        setCalculateInProgress(true);
        const response = await onCalculatePaymentLineDetailsAndValidate(
          {
            PaymentDetail: paymentDetail,
            UpdateLine: newValues
          },
          ChangedField);

        if (response.Status && response.PaymentLineDetail) {
          setValues(response.PaymentLineDetail.inlineObject);
        }
      } finally {
        setCalculateInProgress(false);
      }
    }
  }

  const getModalContent = () => {
    return <div className={classes.mainDiv}>
      <div className={classes.leftColumnDiv}>
        <NumericField
          value={amount}
          label={'Payment Amount'}
          minValueExclusive={0}
          readonly={operationMode === Operation.BROWSE}
          onBlur={(value) => {
            setAmount(value);
            handleValuesChanged(
              {
                ...values,
                Amount: value,
                CashOut: cashOut,
                WaiveMerchantFeeSurcharge: waiveMerchantFeeSurcharge,
                MerchantFeeRecovery: merchantFeeRecovery,
                TotalAmount: totalAmount,
                Reference: reference
              },
              'Amount').catch((err) => { console.warn(err); });
          }}
          required={schema.Amount.Required} />
        <BasicLookupActionField
          placeholder={'EFT Device'}
          disabled={operationMode === Operation.BROWSE}
          label={''}
          size={'medium'}
          lookupName={'EftDevice'}
          value={eftDeviceID === 0 ? '' : String(eftDeviceID)}
          display={eftDeviceIDDisplay}
          onSelectedItemChange={(v) => {
            setEftDeviceID(Number(v ? v.Code : 0));
            setEftDeviceIDDisplay((v ? v.Display : '') as string);
          }}
          suppressDescription={true}
          required={operationMode !== Operation.BROWSE}
        />
        <NumericField
          value={cashOut}
          readonly={operationMode === Operation.BROWSE}
          label={'Cash Out'}
          minValueInclusive={0}
          onBlur={(value) => {
            setCashOut(value);
            handleValuesChanged(
              {
                ...values,
                Amount: amount,
                CashOut: value,
                WaiveMerchantFeeSurcharge: waiveMerchantFeeSurcharge,
                MerchantFeeRecovery: merchantFeeRecovery,
                TotalAmount: totalAmount,
                Reference: reference
              },
              'CashOut').catch((err) => { console.warn(err); });
          }}
          required={schema.CashOut.Required} />
        <TextField
          value={reference}
          label={'Reference'}
          onChange={(value) => { setReference(value); }}
          disabled={true}
        />
      </div>
      <div className={classes.rightColumnDiv}>
        <FormControlLabel
          label={'Waive surcharge'}
          disabled={operationMode === Operation.BROWSE}
          control={<Switch
            disabled={operationMode === Operation.BROWSE}
            color={'default'}
            checked={waiveMerchantFeeSurcharge}
            onChange={(_, value) => {
              setWaiveMerchantFeeSurcharge(value);
              handleValuesChanged(
                {
                  ...values,
                  Amount: amount,
                  CashOut: cashOut,
                  WaiveMerchantFeeSurcharge: value,
                  MerchantFeeRecovery: merchantFeeRecovery,
                  TotalAmount: totalAmount,
                  Reference: reference
                },
                'WaiveMerchantFeeSurcharge').catch((err) => { console.warn(err); });
            }}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              bar: classes.bar,
            }}
          />}
          style={{ width: '100%' }}
          classes={{
            label: classes.formControlLabel
          }}
        />
        <NumericField
          value={merchantFeeRecovery}
          label={'Merchant fee recovery'}
          onChange={(value) => { setMerchantFeeRecovery(value); }}
          readonly />
        <NumericField
          value={totalAmount}
          label={'Total Payment'}
          onChange={(value) => { setTotalAmount(value); }}
          readonly />
      </div>
    </div >;
  };

  const confirmationActions = (): IActionItem[] => {
    if (operationMode === Operation.BROWSE) {

      return [{
        iconName: 'Cancel',
        title: 'Cancel',
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }];
    } else {

      return [
        {
          title: 'Ok',
          disabled: calculateInProgress ||
            (schema.Amount.Required && amount === undefined) || (amount !== undefined && amount <= 0) ||
            (schema.CashOut.Required && !cashOut) || (cashOut !== undefined && cashOut < 0) ||
            (schema.EFTDeviceId.Required && !eftDeviceID),
          listener: async () => {
            if (onApply) {
              onApply({
                ...values,
                Amount: amount,
                CashOut: cashOut,
                EFTDeviceId: eftDeviceID,
                WaiveMerchantFeeSurcharge: waiveMerchantFeeSurcharge,
                MerchantFeeRecovery: merchantFeeRecovery,
                TotalAmount: totalAmount,
                Reference: reference
              });
            }
          }
        },
        {
          title: 'Cancel',
          isDefault: true,
          listener: async () => {
            if (onCancel) {
              onCancel();
            }
          }
        }
      ];
    }
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Integrated Electronic Payment Details'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(EftDetailsForm);
