const SummaryTableSchema = [
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Product',
                fieldNames: [
                    'ProductCode',
                    'ProductDescription'
                ],
                valueRenderer: {
                    api: {
                        api: '/inventoryEnquiry/lookup/action',
                        name: 'enquiry',
                        params: {
                            queryFields: {
                                ProductId: 'ProductId',
                                SearchText: 'ProductCode'
                            }
                        }
                    }
                }
            },
            {
                label: 'Product Group',
                fieldNames: [
                    'ProductGroup',
                    'ProductGroupLabel'
                ]
            },
            {
                label: 'Product Type',
                fieldNames: [
                    'ProductTypeLabel'
                ]
            },
        ]
    },
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Warehouse',
                fieldNames: [
                    'WarehouseEntity',
                    'WarehouseEntityDescription'
                ],
            },
            {
                label: 'Available',
                fieldNames: [
                    'AvailableInWarehouseDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Elsewhere',
                fieldNames: [
                    'AvailableElsewhereDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Cost',
                fieldNames: [
                    'DisplayCostDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Base',
                fieldNames: [
                    'BasePriceDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'GP',
                fieldNames: [
                    'GrossPercentageDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit',
                fieldNames: [
                    'SellPerUnitIncTaxAmountDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit (EX. TAX)',
                fieldNames: [
                    'SellPerUnitExTaxAmountDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedWorksaleLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Tax Total',
                fieldNames: [
                    'TaxTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Extended Total',
                fieldNames: [
                    'ExtendedTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true,
                    emphasize: true
                }
            },
        ]
    }
];

export default SummaryTableSchema;
