import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import {
    selectors as activitySelectors,
} from 'ducks/activityMaintenance/activity';
import {
    selectors as checklistsSelectors,
    actions as checklistActions,
} from 'ducks/activityMaintenance/checklists';

import Checklists from './Checklists';

const mapStateToProps = (state: IApplicationState) => ({
    operationMode: operationSelectors.operationMode(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedActivity: activitySelectors.selected(state),
    checklists: checklistsSelectors.checklists(state),
    isDataLoading: checklistsSelectors.isLoading(state),
    isDirty: checklistsSelectors.isDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeOperationMode: operationActions.changeOperationMode,
        getChecklists: checklistActions.getChecklists,
        updateChecklists: checklistActions.updateChecklists,
        setIsDirty: checklistActions.setIsDirty,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
