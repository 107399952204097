import React from 'react';

import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import PickSlipContactForm from './Contact.form';
import { withStyles } from '@material-ui/core';
import styles from './Contact.styles';
import FormView from 'components/FormView';
import IContactProperties, { IContactHandle } from './Contact.properties';
import { useRetrievePickSlipContactDetails } from 'api/pickSlips/pickslip';

const Contact = (props: IContactProperties, ref: React.Ref<IContactHandle>) => {
    const { classes, despatchId, formValues } = props;

    const { setActionBar } = React.useContext(ActionBarContext);
    const retrievePickSlipContactDetails = useRetrievePickSlipContactDetails(despatchId);
    const pickSlipContactDetails = retrievePickSlipContactDetails.data?.PickSlipContactDetails;

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        []
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    action: 'PickSlipContactDetailsOk',
                },
            ]
        });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            async onOK(navigateBack: () => void): Promise<void> {
                navigateBack();
            }
        }),
        [despatchId, formValues]
    );

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={false}
                formName={'PickSlipContactForm'}
                schema={PickSlipContactForm}
                includeTabsHeight={false}
                initialValues={pickSlipContactDetails?.inlineObject}
                valuesSchema={pickSlipContactDetails?.schema}
                operationMode={'EDIT'}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Contact)));
