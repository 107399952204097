import * as React from 'react';
import SelectField from '@markinson/uicomponents-v2/SelectField';
import { isShallowEqual } from 'utils/utils';

class SelectContainer extends React.PureComponent<any, any> {
  state: any = {
    Data: this.props.options || []
  };

  componentDidMount(): void {
    const { apiPrefs, params } = this.props;
    if (apiPrefs && apiPrefs.path) {

      const _apiPrefs = { ...apiPrefs, params: { ...apiPrefs.params, ...params } };
      this.props.fetchLookupOptions(_apiPrefs);
    }
  }

  componentDidUpdate(): void {
    const { onOptionsUpdate, apiPrefs, results } = this.props;
    const { Data } = this.state;

    const newOptionsData = apiPrefs ? results[apiPrefs.path] : null;
    if (newOptionsData && !isShallowEqual(newOptionsData.Data, Data)) {
      this.setState(
        { Data: newOptionsData.Data },
        () => {
          if (onOptionsUpdate) {
            onOptionsUpdate();
          }
        }
      );
    }
  }

  handleDefaultValue = () => {
    const { apiPrefs, results, value, operationMode, defaultValue, sort, defaultFirstValue } = this.props;
    const optionsData = apiPrefs ? results[apiPrefs.path] : null;

    if (optionsData && optionsData.Data) {
      const options = optionsData.Data;
      if (options && sort) {
        options.sort((a, b) => {
          if (a.label === 'None') {
            return -1;
          }

          return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        });
      }
      if (defaultValue === 'None' && !options.find((item) => item.label === 'None')) {
        options.unshift({ value: '0', label: 'None' });
      }
      if ((operationMode === 'NEW' || defaultFirstValue) && !value) {
        if (options[0].value === '0') {
          this.props.onChange(value);
        } else {
          this.props.onChange(options[0].value);
        }
      }
    }
  }

  onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let { value } = event.target;
    if (value === '0') {
      value = null;
    }
    this.props.onChange(value);
  }

  render(): React.ReactNode {
    const { apiPrefs, results, value, operationMode, name, defaultValue, onChange, options, ...rest } = this.props;
    const optionsData = apiPrefs ? results[apiPrefs.path] : null;
    this.handleDefaultValue();

    return (
      <SelectField
        options={(optionsData && optionsData.Data) || options || []}
        name={name}
        value={(value === '' || value === null) && defaultValue === 'None' ? '0' : value}
        onChange={this.onChange}
        {...rest}
      />
    );
  }
}

export default SelectContainer;
