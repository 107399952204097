import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, deleteKey
} from './utils'

export const { types, actions } = createActions({
  setSelected: (addressId) => ({ addressId }),
  asyncs: {
    search: (query) => (query),
    searchById: ({ CustomerId, SiteCode }) => ({ CustomerId, SiteCode }),
    create: (CustomerId, values) => ({ CustomerId, values }),
    update: (CustomerId, SiteCode, values) => ({ CustomerId, SiteCode, values }),
    remove: (CustomerId, SiteCode) => ({ CustomerId, SiteCode }),
    fetchPrimary: (customerId) => ({ customerId })
  }
}, 'address')


const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  schemas: null,
  primaryAddress: null
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };
    case types.search:
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.create:
    case types.update:
    case types.remove:
    case types.fetchPrimary:
      return asyncOnRequest(state, action)
    case types.saga.create.failure:
    case types.saga.update.failure:
    case types.saga.remove.failure:
    case types.saga.search.failure:
    case types.saga.searchById.failure:
      return asyncOnError(state, action)
    case types.saga.create.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const newAddress = action.payload.inlineObject;
        const newSchema = action.payload.schema;
        const prevSchemas = data.schemas;
        const prevUpdate = newAddress.Primary && 
        (data.list.find((item) => item.Primary));
        const prevAddresses = (prevUpdate && deleteKey(data.list, prevUpdate.SiteCode, 'SiteCode', {...prevUpdate, Primary: false})) || data.list;
        prevSchemas.push(newSchema);
        prevAddresses.push(newAddress);
        return {
          ...data,
          list: prevAddresses,
          schemas: prevSchemas,
          selected: newAddress.SiteCode
        }
      })
    case types.saga.update.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const updatedAddress = action.payload.inlineObject;
        const updatedSchema = action.payload.schema;
        const prevUpdate = updatedAddress.Primary && 
        (data.list.find((item) => item.ContactType === updatedAddress.ContactType 
        && item.Primary && item.SiteCode !== updatedAddress.SiteCode));
        const prevAddresses = deleteKey(((prevUpdate && deleteKey(data.list, prevUpdate.SiteCode, 'SiteCode', {...prevUpdate, Primary: false}))
        || data.list), updatedAddress.SiteCode, 'SiteCode', updatedAddress);
        const prevSchemas = deleteKey(data.schemas, updatedSchema.SiteCode.Value, 'SiteCode', updatedSchema)
        return {
          ...data,
          list: prevAddresses,
          schema: prevSchemas
        }
      })
    case types.saga.remove.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const prevContacts = deleteKey(data.list, action.payload, 'SiteCode');
        const prevSchemas = deleteKey(data.schemas, action.payload, 'SiteCode')
        return {
          ...data,
          list: prevContacts,
          schemas: prevSchemas
        }
      })
    case types.setSelected:
      const selected = action.data.addressId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.saga.fetchPrimary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const primaryAddress = action.payload;

        return {
          ...data,
          primaryAddress: primaryAddress
        }
      })
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const addresses = action.payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = action.payload.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          list: addresses,
          schemas: schemas
        }
      })
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.address,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas) || [],
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.SiteCode === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.SiteCode.Value === data.selected)) || null,
    selectedPrimary: (data) => data.primaryAddress || null,
  }
)

const syncSelector = {
  isLoading: state => state.address.search_loading || state.address.searchById_loading,
  notifications: state => state.address.notifications,
  error: state => state.address.error
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)
