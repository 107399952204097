import React from 'react';
import { connect } from 'react-redux';
import CommonKitComponents from 'components/common/KitComponents/KitComponents.container';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import IKitComponentsProperties, { OmittedApiTypes } from 'components/common/KitComponents/KitComponents.properties';
import { useCalculateComponentFastLineDetails, useCalculateComponentGridLineDetails, useCreateComponentLine, useDeleteComponent, useFetchComponentLines, useGetLineKitSummary, useTotalComponentLinesCount, useUpdateComponentGridLine } from 'api/Worksale/worksale';
import { ICalculateComponentDetailsFacade, IWIPComponentFacade } from 'api/swaggerTypes';

const KitComponents = (props: Readonly<Omit<
  IKitComponentsProperties,
  'classes' | OmittedApiTypes>>) => {

  const { worksaleLineId } = props;

  const { mutateAsync: createComponentLineMutateAsync } = useCreateComponentLine();
  const { mutateAsync: updateComponentGridLineMutateAsync } = useUpdateComponentGridLine();
  const { mutateAsync: deleteComponentMutateAsync } = useDeleteComponent();
  const { mutateAsync: calculateComponentGridLineDetailsMutateAsync } = useCalculateComponentGridLineDetails();
  const { mutateAsync: calculateComponentFastLineDetailsMutateAsync } = useCalculateComponentFastLineDetails();
  const { mutateAsync: fetchComponentLinesMutateAsync } = useFetchComponentLines();
  const { mutateAsync: totalComponentLinesCountMutateAsync } = useTotalComponentLinesCount();
  const { mutateAsync: fetchLineKitSummaryMutateAsync } = useGetLineKitSummary();

  const fetchComponentLines = React.useCallback(
    (_w, BatchPage, BatchSize) => {
      if (!worksaleLineId) {
        return null;
      }

      return fetchComponentLinesMutateAsync({
        WorksalelineId: worksaleLineId,
        urlQueryParams: {
          BatchPage: BatchPage?.toString(),
          BatchSize: BatchSize?.toString()
        }
      });
    },
    [worksaleLineId]
  );

  const totalComponentLinesCount = React.useCallback(
    (_w: number) => {
      if (!worksaleLineId) {
        return null;
      }

      return totalComponentLinesCountMutateAsync({
        WorksalelineId: worksaleLineId,
        urlQueryParams: {
          BatchPage: 1,
          BatchSize: 1
        }
      });
    },
    [worksaleLineId]
  );

  const calculateFastline = React.useCallback(
    (_w, ProductId, Quantity) => {
      if (!worksaleLineId) {
        return null;
      }

      return calculateComponentFastLineDetailsMutateAsync({
        WorksalelineId: worksaleLineId,
        urlQueryParams: {
          ProductId: ProductId || 0,
          Quantity: Quantity || 0
        }
      });
    },
    [worksaleLineId]
  );

  const kitSummary = React.useCallback(
    async (_w: number) => {
      if (!worksaleLineId) {
        return null;
      }

      return fetchLineKitSummaryMutateAsync({
        WorksalelineId: worksaleLineId,
      });
    },
    [worksaleLineId]
  );

  const addComponent = React.useCallback(
    (_w, ProductId: number, RequiredQuantity: number) => {
      if (!worksaleLineId) {
        return null;
      }

      return createComponentLineMutateAsync({
        WorksalelineId: worksaleLineId,
        urlQueryParams: {
          ProductId,
          RequiredQuantity
        }
      });
    },
    [worksaleLineId]
  );

  const calculateComponentGridLineDetails = React.useCallback(
    (_w: number, ComponentId: number, line: ICalculateComponentDetailsFacade, changedField: keyof (ICalculateComponentDetailsFacade)) => {
      if (!ComponentId) {
        return null;
      }

      return calculateComponentGridLineDetailsMutateAsync({
        ...line,
        WorksalelineId: worksaleLineId,
        ComponentId: ComponentId,
        urlQueryParams: {
          ChangedField: changedField
        }
      });
    },
    []
  );

  const updateComponentGridLineDetails = React.useCallback(
    (_w: number, ComponentId: number, line: IWIPComponentFacade) => {
      if (!ComponentId) {
        return null;
      }

      return updateComponentGridLineMutateAsync({
        ...line,
        WorksalelineId: worksaleLineId,
        ComponentId: ComponentId
      });
    },
    []
  );

  const deleteLine = React.useCallback(
    (componentId: number) => {
      if (!componentId) {
        return null;
      }

      return deleteComponentMutateAsync({
        ComponentId: componentId
      });
    },
    []
  );

  return (<CommonKitComponents
    onFetchComponentLines={fetchComponentLines}
    onCreateComponentLine={addComponent}
    onCalculateComponentFastLineDetails={calculateFastline}
    onCalculateComponentGridLineDetails={calculateComponentGridLineDetails}
    onUpdateComponentGridLine={updateComponentGridLineDetails}
    onDeleteComponent={deleteLine}
    onFetchTotalComponentLinesCount={totalComponentLinesCount}
    getLineKitSummary={kitSummary}
    {...props} />);
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeValidationDialog: uiActions.changeValidationDialog,
  },
  dispatch);

export default connect(null, mapDispatchToProps)(KitComponents);
