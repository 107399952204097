import CustomerDetails from './../data/forms/customer_maintenance/CustomerForm'
import SettingsForm from './../data/forms/customer_maintenance/SettingsForm'
import FinancialForm from './../data/forms/customer_maintenance/FinancialForm'
import PricingForm from './../data/forms/customer_maintenance/PricingForm'
import DeliveryDetailsForm from './../data/forms/customer_maintenance/DeliveryDetailsForm'
import ContactsForm from './../data/forms/customer_maintenance/ContactsForm'
import SalesDiary from './../data/forms/customer_maintenance/SalesDiary'
import DiaryDetails from './../data/forms/customer_maintenance/DiaryDetails'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'CustomerDetails':
      return CustomerDetails
    case 'CustomerSetting':
      return SettingsForm
    case 'CustomerFinancial':
      return FinancialForm
    case 'CustomerPricing':
      return PricingForm
    case 'Contacts':
      return ContactsForm
    case 'DeliveryDetails':
      return DeliveryDetailsForm
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    default:
      return CustomerDetails
  }
}

export default getFormSchema
