import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftColumnDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 15px 15px 15px'
  },
  rightColumnDiv: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 15px 15px 0'
  },
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  }
});
