import InvoiceDetails from './../data/forms/supplier_invoice_enquiry/InvoiceDetails'
import Comments from './../data/forms/supplier_invoice_enquiry/Comments'
import Events from './../data/forms/supplier_invoice_enquiry/Events'
import SalesDiary from './../data/forms/supplier_invoice_enquiry/SalesDiary'
import DiaryDetails from './../data/forms/supplier_invoice_enquiry/DiaryDetails'
import InvoiceLines from './../data/forms/supplier_invoice_enquiry/InvoiceLines'
import InvoiceLinesLotDetails from './../data/forms/supplier_invoice_enquiry/InvoiceLinesLotDetails'
import InvoiceLinesSerialNumbers from './../data/forms/supplier_invoice_enquiry/InvoiceLinesSerialNumbers'
import InvoiceLinesMovements from './../data/forms/supplier_invoice_enquiry/InvoiceLinesMovements'
import InvoiceLineDetails from './../data/forms/supplier_invoice_enquiry/InvoiceLineDetails'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'InvoiceDetails':
      return InvoiceDetails
    case 'Comments':
      return Comments
    case 'Events':
      return Events
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    case 'InvoiceLines':
      return InvoiceLines
    case 'LineDetails':
      return InvoiceLineDetails
    case 'LotDetails':
      return InvoiceLinesLotDetails
    case 'SerialNumbers':
      return InvoiceLinesSerialNumbers
    case 'Movements':
      return InvoiceLinesMovements
    default:
      return InvoiceDetails;
  }
}

export default getFormSchema;
