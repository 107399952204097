import { connect } from 'react-redux';
import { selectors as orderLineDetailsSelectors } from 'ducks/salesOrderEnquiry/orderLineDetails';
import SalesOrderLinesEnquiry from './SalesOrderLinesEnquiry';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as reservationDetailsSelectors } from 'ducks/salesOrderEnquiry/reservationDetails';
import { selectors as cdReservationDetailsSelectors } from 'ducks/salesOrderEnquiry/cdReservationDetails';
import { selectors as componentDetailsSelectors } from 'ducks/salesOrderEnquiry/componentDetails';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  orderLineDetails: orderLineDetailsSelectors.lineDetails(state),
  orderLineSummary: orderLineDetailsSelectors.lineSummary(state),
  loadingOrderLineSummary: orderLineDetailsSelectors.loadingSummary(state),
  reservationDetails: reservationDetailsSelectors.reservationDetails(state),
  cdReservationDetails: cdReservationDetailsSelectors.cdReservationDetails(state),
  componentDetails: componentDetailsSelectors.componentDetails(state),
  componentSummary: componentDetailsSelectors.componentSummary(state),
  loadingComponentDetailsSummary: componentDetailsSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SalesOrderLinesEnquiry);
