const InternalComments = {
  'id': 'InternalComments',
  'layout': {
    'rows': 1,
    'columns': 1
  },
  'fields': [
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'props': {
        'name': 'InternalComments',
        'fullWidth': true
      },
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 3
      },
      'children': [
        {
          'id': '1',
          'type': 'TEXT_AREA',
          'props': {
            'label': 'Internal comments',
            'name': 'InternalComments',
            'fullWidth': true,
            'style': {
              'height': '650px'
            }
          }
        }
      ]
    }
  ]
}
export default InternalComments
