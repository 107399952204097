import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IExtractProcessingCriteriaProps } from '../DynamicExtractModal.properties';
import { useRetrieveWizardActions } from 'api/dynamicDataExtract/dynamicDataExtract';
import { PROGRAM_ID } from '../DynamicExtractModal.constants';
import DataGrid, { Column, GroupPanel, LoadPanel, Scrolling, SearchPanel, Sorting, Selection } from 'devextreme-react/data-grid';
import { FIELD_COLUMN_WIDTH } from 'components/ExtractMaintenance/Fields/Fields.constants';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import { useDEContextSelector, useDEDispatch } from 'components/RunDynamicExtract/RunDynamicExtract.context';

const ExtractProcessingCriteria = (props: IExtractProcessingCriteriaProps) => {
    const {
        classes,
        details
    } = props;

    const dataGridInnerRef = React.useRef<DataGrid>();
    const [isFirstTimeLoad, setIsFirstTimeLoad] = React.useState<boolean>(true);

    const useRetrieveWizardActionsMutation = useRetrieveWizardActions();

    const contextDispatch = useDEDispatch();
    const [wizardActionsData, setWizardActionsData] = React.useState(null);
    const [description, setDescription] = React.useState('');
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const CurrentPanel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);

    React.useEffect(
        () => {
            if (WizardDetails?.Action && WizardDetails?.Action !== '') {
                contextDispatch({
                    setErrorState: { InError: false, Panel: '' }
                });
            } else {
                contextDispatch({
                    setErrorState: { InError: true, Panel: CurrentPanel }
                });
            }
        },
        [WizardDetails?.Action]
    );

    React.useEffect(
        () => {
            useRetrieveWizardActionsMutation.mutateAsync({
                SearchText: '',
                ProgramId: PROGRAM_ID
            }).then((response) => {
                setWizardActionsData(response.Data);
            });
        },
        []
    );

    const selectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e?.selectedRowsData?.length > 0 ? e.selectedRowsData[0] : undefined;
            if (selectedRowData) {
                contextDispatch({ setWizardDetails: { ...WizardDetails, Action: selectedRowData?.Code } });
                setDescription(selectedRowData?.Description);
            }
        },
        []);

    const onContentReady = React.useCallback(
        async () => {
            if (dataGridInnerRef.current?.instance?.getDataSource()?.items().length > 0 && isFirstTimeLoad) {

                if (WizardDetails?.Action) {
                    await dataGridInnerRef.current?.instance.selectRows([WizardDetails?.Action], false);
                } else {
                    dataGridInnerRef.current.instance.selectRowsByIndexes([0])
                        .catch((err) => console.warn(err));
                    //update the model in first loading
                    const firstRowKey = dataGridInnerRef.current.instance.getKeyByRowIndex(0);
                    contextDispatch({ setWizardDetails: { ...WizardDetails, Action: firstRowKey } });
                }

                setIsFirstTimeLoad(false);
            }
        },
        [isFirstTimeLoad, WizardDetails, dataGridInnerRef.current]
    );

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <DataGrid
                ref={dataGridInnerRef}
                className={classes.dataGrid}
                dataSource={wizardActionsData}
                showBorders={false}
                renderAsync={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                onSelectionChanged={selectionChanged}
                noDataText=''
                onContentReady={onContentReady}
                keyExpr={'Code'}
            >
                <Column
                    dataField={'Code'}
                    visible={false}
                />
                <Column
                    dataField={'Display'}
                    caption={'Action'}
                    allowHeaderFiltering={false}
                    width={FIELD_COLUMN_WIDTH}
                    allowEditing={false}
                    allowSorting={false}
                />

                <Sorting mode='none' />
                <Selection mode={'single'} />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
            <Content text={description} />
        </div >
    );
};

export default withStyles(styles, { index: 1 })(ExtractProcessingCriteria);
