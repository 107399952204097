import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    alertIconError: {
        color: 'red',
    },
    alertIconWarning: {
        color: '#f99d1b',
    },
    alertIcon: {
        marginLeft: '5px',
        alignSelf: 'center'
    },
    infoIcon: {
        color: 'black',
        marginLeft: '5px',
        alignSelf: 'center',
        cursor: 'pointer'
    },
    link: {
        textDecoration: 'none'
    },
    alertMessageDiv: {
        display: 'flex',

    },
    mainDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    iconDiv: {
        display: 'flex',

    }
});
