const SummaryTableSchema = [
    {
        objectName: 'selectedKitProduct',
        isLoading: 'kitProductDetailsSummaryLoading',
        children: [
            {
                label: 'Product',
                fieldNames: [
                    'ProductIdDisplay',
                    'ProductIdLabel'
                ],
                valueRenderer: {
                    api: {
                        api: '/inventoryEnquiry/lookup/action',
                        name: 'enquiry',
                        params: {
                            queryFields: {
                                ProductId: 'ProductId',
                                SearchText: 'ProductIdDisplay'
                            }
                        }
                    }
                }
            },
            {
                label: 'Product Group',
                fieldNames: [
                    'ProductGroup'
                ]
            }
        ]
    },
    {
        objectName: 'selectedKitProduct',
        isLoading: 'kitProductDetailsSummaryLoading',
        children: [
            {
                label: 'Kitting Quantity',
                fieldNames: [
                    'KittingQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedKitProduct',
        isLoading: 'kitProductDetailsSummaryLoading',
        children: [
            {
                label: 'Cost',
                fieldNames: [
                    'CostDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Base',
                fieldNames: [
                    'BasePriceDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'GP',
                fieldNames: [
                    'GrossPercentageDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit',
                fieldNames: [
                    'SellPerUnitIncTaxDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit (EX. TAX)',
                fieldNames: [
                    'SellPerUnitExTaxDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedKitProduct',
        isLoading: 'kitProductDetailsSummaryLoading',
        children: [
            {
                label: 'Tax Total',
                fieldNames: [
                    'TaxTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Extended Total',
                fieldNames: [
                    'ExtendedTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true,
                    emphasize: true
                }
            },
        ]
    }
];

export default SummaryTableSchema;
