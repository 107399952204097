import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../utils';

export const search = async (query: { PolicyId: number; filters: any; Sort: string; Page: number; SortDirection: string; BatchSize?: number }): Promise<{ records: any; nextPage: boolean; currPage: number }> => {
    const { PolicyId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return fetchPost(`/Security/Policy/${PolicyId}/SearchRules`, { ...filters }, { BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}` }, 'Inline')
        .then((result) => (result && result.Rules &&
        {
            records: result.Rules.map((val) => (objectify(val))),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        })
            ||
        {
            records: [],
            nextPage: false,
            currPage: 1,
        });
};

export interface IUpdateAccessLevelQuery { MenuEntries: number[]; ProgramAreas: number[]; PolicyId: number; Access: 'Default' | 'Full' | 'No'; }

export const UpdateAccess = async (query: IUpdateAccessLevelQuery) => {

    const { MenuEntries, ProgramAreas, PolicyId, Access } = query;
    const getURL = (AccessLevel: string): string => {
        switch (AccessLevel) {
            case 'Full':
                return `/Security/Policy/${PolicyId}/SetFullAccess`;
            case 'No':
                return `/Security/Policy/${PolicyId}/SetNoAcess`;
            default:
                return `/Security/Policy/${PolicyId}/ResetToDefault`;
        }
    };

    return fetchPost(getURL(Access), { MenuEntries: MenuEntries || [0], ProgramAreas: ProgramAreas || [0] }, {}, 'Inline')
        .then(() => ({ Message: 'Access Level Updated Successfully.' })).catch((error) => { throw error; });
};
