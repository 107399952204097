import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/salesInvoiceEnquiry/InvoiceLineSerials';

import { callApi } from '../utils';
import * as api from 'api/salesInvoiceEnquiry/invoiceLineSerials';

function* searchByBOM(action) {
  const { success, failure } = invoiceLineActions.saga.fetchByBom;

  yield callApi(
    call(api.bomSerials, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(invoiceLineTypes.fetchByBom, searchByBOM)
}