import { takeLatest, Effect, call, put } from 'redux-saga/effects';
import { types as contactTypes, actions as contactActions } from 'ducks/contact';
import { connectSagaToApi, callApi } from './utils';
import * as api from 'api/contact';
import { Operation } from 'utils/operations';
import { initialize } from 'redux-form';
import { actions as operationActions } from 'ducks/uiOperations';
import { IDataAction } from 'ducks/utils';

function* update(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = contactActions.saga.update;

  function* postApiFailureCallEffect(): IterableIterator<Effect> {
    yield put(operationActions.changeOperationMode(Operation.EDIT));
    yield put(initialize('CustomerContacts', action.data.values));
  }

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: update, postApiFailureCallEffect }
  );
}

function* create(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = contactActions.saga.create;

  function* postApiFailureCallEffect(): IterableIterator<Effect> {
    yield put(operationActions.changeOperationMode(Operation.NEW));
    yield put(initialize('CustomerContacts', action.data.values));
  }

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: create, postApiFailureCallEffect }
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(contactTypes.search, connectSagaToApi(contactActions, 'search', api.search));
  yield takeLatest(contactTypes.searchById, connectSagaToApi(contactActions, 'searchById', api.searchById));
  yield takeLatest(contactTypes.create, create);
  yield takeLatest(contactTypes.update, update);
  yield takeLatest(contactTypes.remove, connectSagaToApi(contactActions, 'remove', api.remove));
}
