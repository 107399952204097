import * as React from 'react';
import TrackingGrid from './TrackingGrid';
import Paper from 'components/FormView/Fields/PaperContainer';
import SummaryTable from 'components/common/SummaryTable';
import { withStyles } from '@material-ui/core/styles';
import * as constants from './constants';
import { Grid } from '@material-ui/core';
import { ISerialNumberProps } from './interfaces';
import { isShallowEqual } from 'utils/utils';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing.unit * constants.PADDING_MULTIPLIER,
    color: theme.palette.text.secondary,
    marginBottom: constants.MARGIN,
    marginRight: constants.MARGIN,
    height: '700px',
  },
});

class SerialNumber extends React.Component<ISerialNumberProps> {
  componentDidMount(): void {
    const { serialNumberSummary, isFullyTracked } = this.props;
    this.fetchSummary();
    this.fetchSelected();
    if (isFullyTracked && serialNumberSummary && serialNumberSummary.Warehouse && serialNumberSummary.Product) {
      this.fetchAvailLines();
    }
  }

  componentDidUpdate(prevProps: ISerialNumberProps): void {
    const { serialNumberSummary, isFullyTracked } = this.props;
    if (isFullyTracked && !isShallowEqual(prevProps.serialNumberSummary, serialNumberSummary)) {
      this.fetchAvailLines();
    }
  }

  fetchAvailLines = (): void => {
    const { fetchAvailableLines, serialNumberSummary } = this.props;
    const data = {
      WarehouseEntity: serialNumberSummary && serialNumberSummary.Warehouse || null,
      ProductId: serialNumberSummary && Number(serialNumberSummary.Product) || null,
    };
    fetchAvailableLines(data);
  }

  getApi = (ApiString: string, params: any) => {
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, params[curr]);
    };

    return (params && Object.keys(params).reduce(reducer, ApiString)) || ApiString;
  }

  fetchSummary = () => {
    const { params, getSummaryAPI, fetchSerialsSummary } = this.props;
    const apiString = this.getApi(getSummaryAPI, params);
    fetchSerialsSummary({ apiString });
  }

  fetchSelected = () => {
    const { params, getSelectedAPI, fetchSelectedSerials } = this.props;
    const apiString: string = this.getApi(getSelectedAPI, params);
    fetchSelectedSerials({ apiString });

  }

  render(): React.ReactNode {
    const { classes, serialNumberSummary, isFullyTracked } = this.props;
    const data = {
      WarehouseEntity: serialNumberSummary && serialNumberSummary.Warehouse || null,
      ProductId: serialNumberSummary && Number(serialNumberSummary.Product) || null,
    };

    return (
      <div>
        <Grid container spacing={0} direction='row'>
          <Grid item xs>
            <Paper className={classes.paper}>
              <TrackingGrid
                isFullyTracked={isFullyTracked || false}
                params={data}
              />
            </Paper>
          </Grid>
          <Grid item style={{ width: '330px' }}>
            <SummaryTable
              serialNumberSummary={serialNumberSummary}
              data={
                constants.SummarySchema
              }
            />
          </Grid>
        </Grid>

      </div >
    );
  }
}

export default (withStyles(styles, {index: 1})(SerialNumber));
