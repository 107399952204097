import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, IDataAction, SuccessAction, deleteKey
} from './../utils';

interface ISecurityPolicyData {
    selected: any;
    list: any[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    removeWhenPrev: number;
    currSearchText: string;
}

export interface ISecurityPolicyState extends IExtendedState<ISecurityPolicyData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
    {
        setSelected: (PolicyId) => ({ PolicyId }),
        asyncs: {
            search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
            searchById: (id: { PolicyId: number }) => (id),
            fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
            fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
            update: (data: { PolicyId: number; Policy: string; Comment: string }) => (data),
            create: (data: { Policy: string; Comment: string }) => (data),
            remove: (PolicyId: number) => ({ PolicyId }),
        }
    },
    'securityPolicy');

const NOT_SELECTED = -1;

const initialState = asyncInitialState<ISecurityPolicyData>({
    selected: NOT_SELECTED,
    list: null,
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    removeWhenPrev: 0,
    currSearchText: '',
});

export default (state: ISecurityPolicyState = initialState, action: IDataAction): ISecurityPolicyState => {

    switch (action.type) {
        case types.setSelected:
            const selected = action.data.PolicyId;

            return {
                ...state,
                data: { ...state.data, selected }
            };
        case types.search:
            return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
        case types.searchById:
            return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
        case types.fetchNextPage:
        case types.fetchPrevPage:
            action.data.SearchText = state.data.currSearchText;

            return asyncOnRequest(state, action);
        case types.saga.search.success:
        case types.saga.searchById.success:
            return asyncOnSuccess(
                state,
                action,
                (data, successAction) => {
                    const result = successAction.payload;

                    return {
                        ...data,
                        list: result.records,
                        nextPage: result.nextPage && parseInt(result.currPage) + 1,
                        prevPage: result.prevPage && parseInt(result.currPage) - 1,
                        currPage: parseInt(result.currPage)
                    };
                },
                { fetch: true });
        case types.saga.fetchNextPage.success:
            return asyncOnSuccess(
                state,
                action,
                (data, successAction) => {
                    const result = successAction.payload;
                    const newRemoval = result.records.length;
                    const newList = data.list.concat(result.records);

                    return {
                        ...data,
                        list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                        nextPage: result.nextPage && parseInt(result.currPage) + 1,
                        prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
                        currPage: result.currPage,
                        removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                    };
                },
                { fetch: true });
        case types.saga.fetchPrevPage.success:
            return asyncOnSuccess(
                state,
                action,
                (data, successAction) => {
                    const result = successAction.payload;
                    const newList = result.records.slice(0, data.removeWhenPrev).concat(data.list);

                    return {
                        ...data,
                        list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                        prevPage: result.prevPage && result.currPage - 1,
                        nextPage: (newList.length > data.pageSize * data.totalPages) && (parseInt(result.currPage) + data.totalPages),
                        currPage: result.currPage,
                        removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                    };
                },
                { fetch: true });
        case types.saga.update.success:
            return asyncOnSuccess(
                state,
                action,
                (data: ISecurityPolicyData, successAction: SuccessAction) => {
                    const newPolicy = successAction.payload;
                    const prevPolicies = deleteKey(data.list, newPolicy.PolicyId, 'PolicyId', newPolicy);

                    return {
                        ...data,
                        list: prevPolicies
                    };
                },
                { update: true });
        case types.saga.create.success:
            return asyncOnSuccess(
                state,
                action,
                (data: ISecurityPolicyData, successAction: SuccessAction) => {
                    const newPolicy = successAction.payload;
                    const prevPolicies = data.list || [];
                    prevPolicies.push(newPolicy);

                    return {
                        ...data,
                        list: prevPolicies,
                        selected: newPolicy.PolicyId
                    };
                },
                { create: true });
        case types.saga.remove.success:
            return asyncOnSuccess(
                state,
                action,
                (data: ISecurityPolicyData, successAction: SuccessAction) => {
                    const newList = deleteKey(data.list, successAction.payload, 'PolicyId');

                    return {
                        ...data,
                        list: newList,
                    };
                },
                { delete: true });
        case types.saga.search.failure:
        case types.saga.searchById.failure:
        case types.saga.fetchNextPage.failure:
        case types.saga.fetchPrevPage.failure:
        case types.saga.update.failure:
        case types.saga.create.failure:
        case types.saga.remove.failure:
            return asyncOnError(state, action);
        default:
            return state;
    }
};

const asyncSelector = asyncSelectors(
    (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies,
    {
        all: (data: ISecurityPolicyData) => data.list !== undefined && data.list != null && Object.values(data.list),
        selected: (data: ISecurityPolicyData) => (data.list !== undefined && data.list != null && data.list.find((element) => element.PolicyId === data.selected)) || null,
        nextPage: (data: ISecurityPolicyData) => data.nextPage,
        prevPage: (data: ISecurityPolicyData) => data.prevPage,
    }
);

const syncSelector = {
    isLoading: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.search_loading || state.securityManagement.securityPolicies.searchById_loading,
    loadingNextPage: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.fetchNextPage_loading,
    loadingPrevPage: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.fetchPrevPage_loading,
    pageSize: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.data.pageSize,
    totalPages: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.data.totalPages,
    notifications: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.notifications,
    errors: (state: { securityManagement: { securityPolicies: ISecurityPolicyState } }) => state.securityManagement.securityPolicies.error,
};

export const selectors = { ...asyncSelector, ...syncSelector };
