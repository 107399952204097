import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as purchaseOrderTypes, actions as purchaseOrderActions } from 'ducks/purchaseOrderEnquiry/purchaseOrder';

import { callApi } from 'sagas/utils';
import * as api from 'api/purchaseOrderEnquiry/purchaseOrder';

function* search(action) {
  const { success, failure } = purchaseOrderActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = purchaseOrderActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* getDetails(action) {
  const { success, failure } = purchaseOrderActions.saga.getDetails;
  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  )
}
function* fetchSummary(action) {
  const { success, failure } = purchaseOrderActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = purchaseOrderActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = purchaseOrderActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootSalesOrderSaga() {
  yield takeLatest(purchaseOrderTypes.search, search)
  yield takeLatest(purchaseOrderTypes.searchById, searchById)
  yield takeLatest(purchaseOrderTypes.getDetails, getDetails)
  yield takeLatest(purchaseOrderTypes.fetchSummary, fetchSummary)
  yield takeLatest(purchaseOrderTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(purchaseOrderTypes.fetchNextPage, fetchNext)
}