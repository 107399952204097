import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';

const mapStateToProps = (state) => ({
  orderSummary: orderSelectors.summary(state),
  isLoading: orderSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
