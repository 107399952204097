import { combineReducers } from 'redux';
import supplierProduct, { ISupplierProductState } from './supplierProduct';
import productPriceSchedule, { IProductPriceScheduleState } from './productPriceSchedule';
import productLinks, { IProductLinksState } from './productLinks';
import attachments, { ISupplierProductAttachmentsState } from './attachments';

export interface ISupplierProductEnquiryState {
  supplierProduct: ISupplierProductState;
  productPriceSchedule: IProductPriceScheduleState;
  productLinks: IProductLinksState;
  attachments: ISupplierProductAttachmentsState;
}
const combined = combineReducers({
  supplierProduct,
  productPriceSchedule,
  productLinks,
  attachments,
});

export default combined;
