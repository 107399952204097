import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as contactTypes, actions as contactActions } from 'ducks/supplierEnquiry/deliveryDetails';

import { callApi } from '../utils';
import * as api from 'api/supplierEnquiry/deliverydetails';


function* search(action) {
  const { success, failure } = contactActions.saga.searchDeliveryDetail;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action) {
  const { success, failure } = contactActions.saga.searchDeliveryDetailById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* searchForId(action) {
  const { success, failure } = contactActions.saga.searchDeliveryDetailForId;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(contactTypes.searchDeliveryDetail, search)
  yield takeLatest(contactTypes.searchDeliveryDetailById, searchById)
  yield takeLatest(contactTypes.searchDeliveryDetailForId, searchForId)
}