import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { IEventFields } from '../interfaces';

class Events extends React.PureComponent<IEventFields> {

    componentDidMount(): void {
        const { path, onInitialLoad } = this.props;
        if (path) {
            switch (path) {
                case '/sales-invoice-enquiry/events':
                    onInitialLoad('Events');
                    break;
                default:
                    onInitialLoad('InvoiceDetails');
            }
        }
    }

    getInitialValues = () => {
        const {
            selectedTab, selectedInvoice, location
        } = this.props;
        const params = (new URLSearchParams(location.search));
        const invoiceNumber = params.get('InvoiceNumber');
        const salesEntity = params.get('SalesEntity');
        switch (selectedTab) {
            case 'Events':
                return {
                    params: {
                        InvoiceNumber: (selectedInvoice && selectedInvoice.Invoice) || invoiceNumber,
                        SalesEntity: (selectedInvoice && selectedInvoice.SalesEntity) || salesEntity
                    }
                };
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
        const initialValues = this.getInitialValues();

        return (selectedForm &&
            <React.Fragment>
                <FormView
                    isLoading={isLoading}
                    formName={selectedTab}
                    browseLookUpOpen={isBrowseLookUpOpen}
                    browseLookUpComponent={selectedTab}
                    schema={selectedForm}
                    includeTabsHeight={false}
                    initialValues={initialValues}
                    operationMode={operationMode}
                    enableReinitialize={true}
                    summaryTableRenderer={summaryTableRenderer}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(Events);
