function appendParams(path: string, params: { CustomerId?: number; CustomerAccountNumber?: string}): string {
  if (params && (params.CustomerId || params.CustomerAccountNumber)) {
    const search = new URLSearchParams();
    if (params.CustomerId) {
      search.append('CustomerId', params.CustomerId.toString());
    }
    if (params.CustomerAccountNumber) {
      search.append('CustomerAccountNumber', params.CustomerAccountNumber);
    }
    path += `?${search.toString()}`;
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Customer Enquiry in new tab',
    action: (params: { CustomerId?: number; CustomerAccountNumber?: string}) => { window.open(appendParams('/customer-enquiry', params)); }
  },
  {
    id: '2',
    label: 'Customer Maintenance in new tab',
    action: (params: { CustomerId?: number; CustomerAccountNumber?: string}) => { window.open(appendParams('/customer-maintenance', params)); }
  },
  {
    id: '3',
    label: 'Customer Enquiry',
    action: (params: { CustomerId?: number; CustomerAccountNumber?: string}) => { window.location.assign(appendParams('/customer-enquiry', params)); }
  },
  {
    id: '4',
    label: 'Customer Maintenance',
    action: (params: { CustomerId?: number; CustomerAccountNumber?: string}) => { window.location.assign(appendParams('/customer-maintenance', params)); }
  }
];
