import React from 'react';
import { Drawer, AppBar, Toolbar, Typography, StyledComponentProps, IconButton } from '@material-ui/core';
// import { IconButton } from '@markinson/ui-components/material';
import CloseIcon from '@markinson/uicomponents-v2/SvgIcons/Clear';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Scrollbars from 'react-custom-scrollbars';

import SearchPanel from './SearchPanel';
import AccountSettings from './AccountSettingsPanel';
import Administration from './AdministrationPanel';
import NotificationPanel from './NotificationsPanel';
import HelpPanel from './HelpPanel';
import FavouritesPanel from './FavouritesPanel';
import styles from './AppBarMenu.scss';
import { IPanel } from '../AppBar';

const classStyles = () => createStyles({
  drawer: {
    top: 64,
    right: 0,
    backgroundColor: '#BBBBBB',
    width: 340,
    left: 'initial',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    height: 'calc(100% - 64px)'
  },
  paper: {
    backgroundColor: '#BBBBBB',
    width: 340,
    height: '100%',
    outline: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    flex: 1,
    fontSize: '16px',
    fontWeight: 100,
    color: 'rgba(0,0,0,0.54)',
  }
});

const RightSidePanels = {
  'Search': SearchPanel,
  'Help': HelpPanel,
  'Favorites': FavouritesPanel,
  'Notifications': NotificationPanel,
  'User Account': AccountSettings,
  'Settings': Administration
};

interface IAppBarMenuProps extends StyledComponentProps {
  open?: boolean;
  panel?: IPanel;
  handleToggle?(open?: boolean): void;
}

function AppBarMenu(props: IAppBarMenuProps): JSX.Element {

  const { classes, open, panel, handleToggle } = props;

  const renderPanel = (title) => {
    const SelectedPanel = RightSidePanels[title];

    return (
      <SelectedPanel handleToggle={handleToggle} />
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Drawer
      anchor={'right'}
      open={open}
      className={classes.drawer}
      ModalProps={{
        hideBackdrop: true,
        disableEnforceFocus: true,
        disableRestoreFocus: true,
        disableAutoFocus: true
      }}
      PaperProps={{
        className: classes.paper,
        elevation: 6
      }}
    >
      <AppBar
        className={styles.appBar}
        position='static'
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant='title' className={classes.title}>
            {
              open ? panel.title : ''
            }
          </Typography>
          <IconButton
            onClick={() => { handleToggle(); }}
            title='Close'
            className={styles.iconCloseButton}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Scrollbars style={{ height: 'calc(100% - 52px)' }}>
        <div className={styles.menuContent}>
          {
            renderPanel(open ? panel.title : '')
          }
        </div>
      </Scrollbars>
    </Drawer>
  );
}

export default withStyles(classStyles, { index: 1 })(AppBarMenu);
