import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import SummaryTable from '../../../common/SummaryTable';
import SummaryTableSchema from './SummaryTableSchema.json';

const styles = createStyles({
  mainDiv: {
    display: 'flex'
  },
  summaryTable: {
    flex: 1
  }
});

export interface IFormRendererProps extends StyledComponentProps {
  data?: any;
}

function FormRenderer(props: IFormRendererProps): JSX.Element {
  const { classes, data } = props;

  return (
    <div className={classes.mainDiv}>
      <SummaryTable className={classes.summaryTable} Orders={
        data && {
          Purchase: data.PurchaseOrderQuantityDisplay,
          Production: data.ProductionOrderQuantityDisplay,
          InTransitLocal: data.InTransitLocalQuantityDisplay,
          InTransitImport: data.InTransitImportQuantityDisplay,
          Commited: data.CommittedQuantityDisplay,
        }
      } data={
        SummaryTableSchema.Orders
      } />
      <SummaryTable className={classes.summaryTable} Sales={
        data && {
          Backorders: data.BackordersQuantityDisplay,
          Kitting: data.KittingQuantityDisplay,
          Picking: data.PickingQuantityDisplay,
          Reserved: data.ReservedQuantityDisplay,
          Corrections: data.CorrectionsQuantityDisplay,
        }
      } data={
        SummaryTableSchema.Sales
      } />
      <SummaryTable className={classes.summaryTable} WorkInProgress={
        data && {
          ServiceRequired: data.ServiceOrderRequiredQuantityDisplay,
          ProductionRequired: data.ProductionOrderRequiredQuantityDisplay,
          ServiceIssued: data.ServiceOrderIssuedQuantityDisplay,
          ProductionIssued: data.ProductionOrderIssuedQuantityDisplay,
          InPutAway: data.InPutAwayQuantityDisplay,
        }
      } data={
        SummaryTableSchema.WorkInProgress
      } />
    </div>
  );
}

export default (withStyles(styles, { index: 1 })(FormRenderer));
