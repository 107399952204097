import { connect } from 'react-redux';
import SalesOrderDeliveriesEnquiry from './SalesOrderDeliveriesEnquiry';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as deliveryLineSelectors } from 'ducks/salesOrderEnquiry/deliveryLine.js';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  notifications: state.salesOrderEnquiry.deliveryLine.notifications,
  deliveryDetails: deliveryLineSelectors.details(state),
  loadingDeliverySummary: deliveryLineSelectors.loadingDetails(state),
});

export default connect(mapStateToProps, {})(SalesOrderDeliveriesEnquiry);
