import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, Paper, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps } from '../interfaces';
import { ISchedulerJobDetailFacade } from 'api/swaggerTypes';
const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
});

const pSize = CircularProgressSize;
const paperElevation = PaperElevation;
const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DISPLAY_DATE_FORMAT = 'L';
const DISPLAY_TIME_FORMAT = 'HH:mm';
const NO_ESTIMATE = 'No Estimate';
const NOT_SCHEDULED = 'Not scheduled';

// tslint:disable: no-string-literal
class SummaryPanel extends React.PureComponent<ISummaryPanelProps> {

  appendParam = (
    actionList: { id: string; label: string; action(params: { CustomerId?: number; CustomerAccountNumber?: string }): void }[],
    jobDetails: ISchedulerJobDetailFacade) => {
    if (jobDetails && jobDetails.CustomerId) {
      return actionList.map((item) => {
        return { ...item, actionParam: { CustomerId: jobDetails.CustomerId, CustomerAccountNumber: jobDetails['CustomerIdDisplay'] } };
      });
    }

    return actionList;
  }

  getActivityDetails = (): { [key: string]: any } => {
    const { activityDetails } = this.props;
    const start = activityDetails && activityDetails.ScheduleStartDate ? moment(activityDetails.ScheduleStartDate, API_DATE_FORMAT) : null;
    const end = activityDetails && activityDetails.ScheduleEndDate ? moment(activityDetails.ScheduleEndDate, API_DATE_FORMAT) : null;
    const estimate = activityDetails && activityDetails.Estimate ? ((time) => {
      const [hours, minutes] = time.split(':').map((v, _i, _a) => parseInt(v));

      return `${hours} hours ${minutes} minutes`;
    })(activityDetails.Estimate) : null;

    return ({
      'ACTIVITY': <div>
        <span >{activityDetails && activityDetails.ActivityCode}</span>
        <br />
        <span >{activityDetails && activityDetails.Description}</span>
      </div>,
      'CAPABILITIES REQUIRED': activityDetails && activityDetails.CapabilitiesRequired ? <div>{
        activityDetails.CapabilitiesRequired.split(/(?:\r\n|\r|\n)/).map((line, i) => (<span key={i}>{line}<br /></span>))
      }</div> : '',
      'ESTIMATE': estimate ? estimate : NO_ESTIMATE,
      'ACTIVITY STATUS': activityDetails && activityDetails.ActivityStatusDisplay,
      'ACTIVITY START': start && start.isValid() ? `${start.format(DISPLAY_DATE_FORMAT)} ${start.format(DISPLAY_TIME_FORMAT)}` : NOT_SCHEDULED,
      'ACTIVITY END': end && end.isValid() ? `${end.format(DISPLAY_DATE_FORMAT)} ${end.format(DISPLAY_TIME_FORMAT)}` : NOT_SCHEDULED
    });
  }

  getJobDetails = (): { [key: string]: any } => {
    const { jobDetails } = this.props;
    const start = jobDetails && jobDetails.ScheduleStartDate ? moment(jobDetails.ScheduleStartDate, API_DATE_FORMAT) : null;
    const end = jobDetails && jobDetails.ScheduleEndDate ? moment(jobDetails.ScheduleEndDate, API_DATE_FORMAT) : null;

    return ({
      'JOB': jobDetails && jobDetails.ServiceJobId,
      'CUSTOMER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{jobDetails && jobDetails['CustomerIdDisplay']}</span>
          <br />
          <span >{jobDetails && jobDetails['CustomerIdDisplayLabel']}</span>
        </div>
      </div>,
      'CUSTOMER P/O': jobDetails && jobDetails.CustomerPurchaseOrder,
      'SALES ENTITY': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{jobDetails && jobDetails['SalesEntityLabel']}</span>
          <br />
          <span >{jobDetails && jobDetails['SalesEntityDescription']}</span>
        </div>
      </div>,
      'JOB STATUS': jobDetails && jobDetails.Status,
      'CUSTOMER ADDRESS': jobDetails && jobDetails.CustomerAddress ? <div>{
        jobDetails.CustomerAddress.split(/(?:\r\n|\r|\n)/).map((line, i) => (<span key={i}>{line}<br /></span>))
      }</div> : '',
      'JOB START': start && start.isValid() ? `${start.format(DISPLAY_DATE_FORMAT)} ${start.format(DISPLAY_TIME_FORMAT)}` : NOT_SCHEDULED,
      'JOB END': end && end.isValid() ? `${end.format(DISPLAY_DATE_FORMAT)} ${end.format(DISPLAY_TIME_FORMAT)}` : NOT_SCHEDULED
    });
  }

  render(): React.ReactNode {
    const { classes, isLoading } = this.props;
    const activityDetails = this.getActivityDetails();
    const jobDetails = this.getJobDetails();

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Activity details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(activityDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {activityDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Job details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(jobDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {jobDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}
// tslint:enable: no-string-literal

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
