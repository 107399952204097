import * as React from 'react';
import FormView from 'components/FormView';
import { Operation, OPERATIONS } from 'utils/operations';
import SummaryTableSchema from './SummaryTableSchema.json';
import SummaryTable from 'components/common/SummaryTable/SummaryTable';

export interface IPaymentDatailsProps {
  SalesEntity?: string;
  paymentDetails?: any;
  parentTab?: string;
  isBrowseLookUpOpen?: boolean;
  selectedTab: any;
  selectedForm: any;
  operationMode: any;
  defaultLine?: any;
  paymentContext?: any;
  paymentLines?: any;
  paymentDetailsLoading?: boolean;
  destroyForm(name: string): void;
  onInitialLoad(args: string): any;
  fetchPaymentDetails(): void;
  processPayment(args: any): void;
  cancelPayment(args: any): void;
  changeOperationMode(args: any): void;
  postSuccessAction?(args: string): void;
}

class PaymentDetails extends React.PureComponent<IPaymentDatailsProps> {

  componentDidMount(): void {
    const { onInitialLoad, fetchPaymentDetails, paymentDetails, parentTab } = this.props;
    if (paymentDetails) {
      onInitialLoad('PaymentDetails');
      fetchPaymentDetails();
    } else {
      onInitialLoad(parentTab);
    }

  }

  componentDidUpdate(prevProps: IPaymentDatailsProps): void {
    const { operationMode, changeOperationMode } = this.props;

    if (prevProps.operationMode !== operationMode) {
      switch (operationMode) {
        case OPERATIONS.SAVE:
          this.handleSave();
          changeOperationMode(Operation.EDIT);
          break;
        case OPERATIONS.CANCEL:
          this.handleCancel();
          changeOperationMode(Operation.EDIT);
          break;

        default:
          changeOperationMode(Operation.EDIT);
      }
    }
  }

  handleSave = () => {
    const { paymentDetails = {}, processPayment, defaultLine = {}, paymentContext = {}, paymentLines = [], postSuccessAction } = this.props;

    const payload = {
      ...paymentDetails.inlineObject,
      DefaultLine: defaultLine.inlineObject,
      PaymentContext: paymentContext.inlineObject,
      PaymentLines: paymentLines,
      postSuccessAction: postSuccessAction
    };

    if (processPayment) processPayment(payload);
  }

  handleCancel = () => {
    const { paymentDetails = {}, cancelPayment, defaultLine = {}, paymentContext = {}, paymentLines = [] } = this.props;

    const payload = {
      ...paymentDetails.inlineObject,
      DefaultLine: defaultLine.inlineObject,
      PaymentContext: paymentContext.inlineObject,
      PaymentLines: paymentLines,
    };

    if (cancelPayment) cancelPayment(payload);
  }
  handleConfirm = () => {
    const { processPayment, paymentDetails } = this.props;
    if (paymentDetails && processPayment) processPayment(paymentDetails);
  }

  getSummaryTableRenderer = () => {
    const { paymentDetails, paymentDetailsLoading } = this.props;
    const data = SummaryTableSchema.summary;

    return () => (data ? <SummaryTable
      PaymentSumaryLoading={paymentDetailsLoading}
      PaymentSumary={paymentDetails && paymentDetails.inlineObject}
      data={data}
    /> : null);
  }

  render(): React.ReactNode {
    const {
      isBrowseLookUpOpen, selectedTab, selectedForm, paymentDetails, operationMode
    } = this.props;

    const initialValues = paymentDetails && paymentDetails.inlineObject;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={null}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default PaymentDetails;
