
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as supplierSelectors, actions as supplierActions } from 'ducks/supplierEnquiry/supplier';
import { selectors as contactSelectors } from 'ducks/supplierEnquiry/supplierContacts';
import { selectors as deliveryDetailsSelectors, actions as deliveryDetailsActions } from 'ducks/supplierEnquiry/deliveryDetails';
import { selectors as addressSelectors } from 'ducks/address';
import { selectors as customerProductSelectors } from 'ducks/customerProduct';
import { selectors as marketingSelectors } from 'ducks/customerEnquiry/marketing';
import { selectors as supplieruserFieldsSelectors } from 'ducks/supplierEnquiry/userFields';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { getFormValues } from 'redux-form';

import SupplierDetail from './SupplierDetails';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  supplierDetails: supplierSelectors.supplierDetails(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedSupplier: supplierSelectors.selected(state),
  selectedSupplierSchema: supplierSelectors.selectedSchema(state),
  selectedContact: contactSelectors.selected(state),
  contactDetails: supplierSelectors.contacts(state),
  prmaryContacts: supplierSelectors.primaryContacts(state),
  selectedContactSchema: contactSelectors.selectedSchema(state),
  selectedSiteCode: deliveryDetailsSelectors.selected(state),
  selectedSiteCodeSchema: deliveryDetailsSelectors.selectedSchema(state),
  deliveryDetails: deliveryDetailsSelectors.deliveryDetails(state),
  supplierUserFieldsData: supplieruserFieldsSelectors.data(state),
  selectedAddress: addressSelectors.selected(state),
  selectedAddressSchema: addressSelectors.selectedSchema(state),
  selectedProduct: customerProductSelectors.selected(state),
  selectedProductSchema: customerProductSelectors.selectedSchema(state),
  selectedMarketing: marketingSelectors.selected(state),
  selectedMarketingSchema: marketingSelectors.selectedSchema(state),
  selectedDiary: diarySelectors.selected(state),
  currentDiary: getFormValues('DiaryDetails')(state)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    searchDeliveryDetailForId: deliveryDetailsActions.searchDeliveryDetailForId,
    getContactDetails: supplierActions.getContactDetails,
    getPrimaryContacts: supplierActions.getPrimaryContacts,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDetail);
