import { takeLatest, Effect, call } from 'redux-saga/effects';
import { connectSagaToApi, callApi } from '../utils';
import { types as checklistsTypes, actions as checklistsActions } from 'ducks/activityMaintenance/checklists';
import * as api from 'api/activityMaintenance/checklists';
import { IDataAction } from 'ducks/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* updateChecklists(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = checklistsActions.saga.updateChecklists;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        if (!response.Forms) {
            showSnackBar({ variant: 'success', message: 'Checklists updated successfully.' });
        }
    }

    yield callApi(
        call(api.updateChecklists, action.data),
        success,
        failure,
        { postApiSuccessCallEffect, dialogActionCallEffect: updateChecklists }
    );
}

export default function* rootActivityMaintenanceCapabilitySaga(): IterableIterator<Effect> {
    yield takeLatest(checklistsTypes.getChecklists, connectSagaToApi(checklistsActions, 'getChecklists', api.getChecklists));
    yield takeLatest(checklistsTypes.updateChecklists, updateChecklists);
}
