export default [
  {
    id: '1',
    name: 'Normal Account Terms',
    abbreviation: 'N',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Special Account Terms',
    abbreviation: 'S',
    fieldList1: 'field'
  },
];
