import * as React from 'react';
import FormView from 'components/FormView';
import { IInvoicePaymentsFields } from '../interfaces';

class InvoicePayments extends React.PureComponent<IInvoicePaymentsFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-invoice-enquiry/payments':
          onInitialLoad('Payment');
          break;
        case '/sales-invoice-enquiry/payments/payment-details':
          onInitialLoad('PaymentDetails');
          break;
        default:
          onInitialLoad('Payment');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, paymentSummary, paymentDetails
    } = this.props;
    switch (selectedTab) {
      case 'Payment':
        return paymentSummary;
      case 'PaymentDetails':
        return paymentDetails;
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default InvoicePayments;
