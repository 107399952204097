import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError,
  reduceByPrimary, reduceByPrimaryValue
} from './utils'

export const { types, actions } = createActions({
  setSelected: (productId) => ({ productId }),
  changeSortFilter: (selectedSortFilter) => selectedSortFilter,
  changeSortDirection: (selectedSortDirection) => selectedSortDirection,
  asyncs: {
    search: (query) => (query),
    fetchDetail: (query) => (query)
  }
}, 'product')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  selectedSortFilter: 'ProdCode',
  selectedSortDirection: 'desc',
  selectedDetail: null
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.search:
    case types.fetchDetail:
      return asyncOnRequest(state, action)

    case types.setSelected:
      const selected = action.data.productId;
      return {
        ...state,
        data: { ...state.data, selected }
      }

    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {

        const products = action.payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = action.payload.map((combinedObject) => combinedObject.schema);
        return {
          ...data,
          list: reduceByPrimary(products, 'ProdCode'),
          schemas: reduceByPrimaryValue(schemas, 'ProdCode')
        };
      })
    case types.saga.fetchDetail.success:
      return asyncOnSuccess(state, action, (data, action) => {

        const productDetail = action.payload;
        const selectedSchema = data.schemas[data.selected];
        const selectedProduct = data.list[data.selected];
        selectedSchema.ProdGroup.Label = productDetail.ProductDescription;
        selectedSchema.CustomerProduct = { Label: productDetail.CustomerDescription };
        selectedProduct.Comment = productDetail.Comment;
        selectedProduct.CustomerProduct = productDetail.CustomerProduct;
        return {
          ...data,
          productDetail: productDetail,
          selectedSchema: selectedSchema,
        };
      })
    case types.saga.search.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.product,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    selected: (data) => (data.list !== undefined && data.list != null && data.list[data.selected]) || null,
    selectedSchema: (data) => data.selectedSchema || null,
  }
)

const syncSelector = {
  selectedSortFilter: state => state.product.data.selectedSortFilter,
  selectedSortDirection: state => state.product.data.selectedSortDirection,
  isLoading: state => state.product.search_loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

