import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import CreditNotesLookupPanel from './CreditNotesLookupPanel';
import BreadcrumbBar from './../common/BreadcrumbBar';
import SummaryPanel from './SummaryPanel';
import * as styles from './CreditNotes.scss';
import options from './OptionItems.json';
import { shallowCompare } from '../../utils/utils';
import CreditNoteDetails from './CreditNoteDetails';
import PaymentDetails from 'components/common/PaymentDetails';
import CreditNoteLines from './CreditNoteLines';
import SummaryTableSchema from './SummaryTableSchema.json';
import SummaryTable from '../common/SummaryTable';
import { Operation } from '../../utils/operations';
import { ICreditNotesViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  salesProcessingSection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

const tabsData = [
  { id: 'CNLines', label: 'Lines' },
  { id: 'CNDetails', label: 'Details' },
  { id: 'CNNotes', label: 'Notes' },
];

class CreditNotesView extends React.Component<ICreditNotesViewProperties> {

  operationMap: Map<Operation, (prevProps?: Readonly<ICreditNotesViewProperties>) => void>;

  constructor(props: Readonly<ICreditNotesViewProperties>, context?: any) {
    super(props, context);
    this.operationMap = new Map<Operation, () => void>();
    this.operationMap[Operation.BROWSE] = this.handleBrowse;
    this.operationMap[Operation.BACK] = this.handleBack;
    this.operationMap[Operation.CANCEL] = this.handleCancel;
  }

  handleModuleChange = (selectedTab: string) => {
    if (selectedTab !== 'CNLineDetails' && selectedTab !== 'CNLines' && selectedTab !== 'CNDetails') {
      this.props.history.push('/credit-notes');
    }
    if (selectedTab === 'CNDetails') {
      this.props.changeOperationMode(Operation.EDIT);
      this.props.history.push('/credit-notes/credit-details');
    }
    if (selectedTab === 'CNLines') {
      this.props.history.push('/credit-notes/credit-lines');
      if (this.props.creditNoteFetched) {
        this.props.changeOperationMode(Operation.BROWSE);
      }
    }
    if (selectedTab === 'CNLineDetails') {
      this.props.changeOperationMode(Operation.EDIT);
      this.props.history.push('/credit-notes/credit-lines/details');
    }
    if (selectedTab === 'PaymentDetails') {
      this.props.changeOperationMode(Operation.EDIT);
      this.props.history.push('/credit-notes/payment-details');
    }
    this.props.changeSelectedTab(selectedTab);
    this.changeModule(selectedTab);
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  isTabIdValid = (selectedTab: string) => {
    const tabs = tabsData.filter((item) => (item.id === selectedTab));
    if (tabs.length > 0) {
      return selectedTab;
    }

    return '';
  }

  changeModule = (selectedTab: string) => {
    const tab = selectedTab || this.props.selectedTab;
    const context = 'credit-notes';
    this.props.getFormSchema({
      context,
      formId: tab
    });
    this.props.changeSelectedForm(context, tab);
  }

  componentDidMount(): void {
    this.handleModuleChange('CNLines');
    this.props.changeOperationMode(Operation.BROWSE);
  }

  componentDidUpdate(prevProps: Readonly<ICreditNotesViewProperties>): void {
    const {
      selectedTab, selectedForm, history, match, creditNoteFetched, operationMode
    } = this.props;

    if (creditNoteFetched && !prevProps.creditNoteFetched) {
      this.props.changeOperationMode(Operation.BROWSE);
    }

    if (!selectedForm) {
      this.props.changeSelectedForm('credit-notes', selectedTab);
    }

    if (prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }

    if (operationMode !== prevProps.operationMode) {
      if (this.operationMap[operationMode]) {
        this.operationMap[operationMode](prevProps);
      }
    }

    if (selectedTab === 'CNLines' && prevProps.selectedTab !== 'CNLines' && history.location.pathname === match.url) {
      this.props.history.push('/credit-notes/credit-lines');
    }
  }

  handleBrowse = (): void => {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  cancelFormSubmission = (prevProps: Readonly<ICreditNotesViewProperties>) => {
    if (this.props.creditNoteFetched) {
      this.props.clearCreditNoteContext({ DeleteWork: null });
      this.props.changeOperationMode(prevProps.operationMode);
    }
  };

  handleCancel = (prevProps: Readonly<ICreditNotesViewProperties>): void => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'CNLines':
        this.cancelFormSubmission(prevProps);
        break;
      default:
    }
  }

  handleSave = (): void => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      default:
    }
  }

  handleBack = () => {
    const curModule = MODULE_TREE.filter((x) => x.id === this.props.selectedTab)[0];
    if (this.props.dirty) {
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(Operation.BROWSE);
        },
        onOk: () => {
          if (curModule.parent !== '') {
            this.handleModuleChange(curModule.parent);
          } else {
            this.handleModuleChange('CNLines');
          }
          this.props.changeOperationMode(Operation.BROWSE);
          this.props.resetForm();
        }
      });
    } else if (curModule.parent !== '') {
      this.handleModuleChange(curModule.parent);
      this.props.changeOperationMode(Operation.BROWSE);
      this.props.resetForm();
    } else {
      if (curModule.id === 'CNLines') {
        this.props.history.push('/dashboard');
      } else {
        this.handleModuleChange('CNLines');
      }
      this.props.changeOperationMode(Operation.BROWSE);
    }
  };

  shouldComponentUpdate(nextProps: Readonly<ICreditNotesViewProperties>, nextState: Readonly<{}>): boolean {
    return shallowCompare(this, nextProps, nextState);
  }

  creditNoteLineSummary = (): React.ReactNode => {
    const { creditNoteLineDetails } = this.props;

    return <SummaryTable
      lineDetails={creditNoteLineDetails}
      data={
        SummaryTableSchema.CNLineDetails
      }
    />;
  }

  getSummaryTableRenderer = (selectedTab: string) => {
    switch (selectedTab) {
      case 'CNLineDetails':
        return this.creditNoteLineSummary;
      default: return null;
    }
  }

  render(): React.ReactNode {
    const {
      selectedTab, match,
      isMenuOptionOpen, creditNoteContext, creditNoteContextLoading, changeConfirmationDialog, applyResponse,
      getCreditNoteContext, updateCreditNoteContext, applyCreditNoteContext, clearCreditNoteContext, paymentDetails
    } = this.props;

    return (
      <div className={styles.customerMaintenanceOuter}>
        <div className={styles.customerMaintenanceContainer}>
          <CreditNotesLookupPanel
            creditNoteContext={creditNoteContext}
            loading={creditNoteContextLoading}
            getCreditNoteContext={getCreditNoteContext}
            updateCreditNoteContext={updateCreditNoteContext}
            clearCreditNoteContext={clearCreditNoteContext}
            changeConfirmationDialog={changeConfirmationDialog}
            onApply={applyCreditNoteContext}
          />
          <div
            style={inlineStyles.salesProcessingSection}
            className={styles.salesProcessingSection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Credit Notes'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            <Route exact path={`${match.url}`} render={() => <CreditNoteLines />} />
            <Route exact path={`${match.url}/credit-lines`} render={() => <CreditNoteLines />} />
            <Route exact path={`${match.url}/credit-details`} render={() => <CreditNoteDetails />} />
            <Route exact path={`${match.url}/credit-lines/details`} render={() => <CreditNoteLines summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)} />} />
            <Route exact path={`${match.url}/payment-details`} render={() => <PaymentDetails parentTab='CNLines' onInitialLoad={this.handleModuleChange} postSuccessAction={() => this.props.completeProcessCreditNote({...applyResponse, PaymentStatus: true, PaymentDetails: paymentDetails})} />} />
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={options}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'credit-notes'} />
      </div>
    );
  }
}

export default withRouter(CreditNotesView);
