import { fetchPost, objectify } from '../../utils';

export const fetch = async (query) => {
  const { SupplierProductId, filters } = query;
  const respType = 'Inline';

  return fetchPost(
    `/SupplierProductEnquiry/SupplierProduct/${SupplierProductId}/SearchImages`,
    filters ?
      {
        ...filters
      } : {},
    null,
    respType
  ).then(
    (result) => {
      if (result.Images) {
        return {
          inlineObject: result.Images.map((item) => objectify(item, null, 'Inline')),
          schema: result.Images
        };
      }

      return result;
    });
};
