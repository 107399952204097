import {
  createActions, asyncInitialState, asyncSelectors,
  asyncOnRequest, asyncOnSuccess, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
    fetchSummary: (PurchaseOrder) => ({ PurchaseOrder }),
  }
}, 'branchOrders');

const initialState = asyncInitialState({
  selected: null,
  summary: {},
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  branchOrdersActions: {
  },
  branchOrdersColumns: [
    { headerName: 'Warehouse', field: 'Warehouse', suppressSorting: true, minWidth: 100 },
    {
      headerName: 'Purchase order', field: 'PurchaseOrder',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/purchase-order-enquiry'
      },
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.PurchaseOrder) {
          return {
            PurchaseOrder: row.data.PurchaseOrder,
            FieldValue: row.data.PurchaseOrder
          };
        } else return null;
      },
    },
    { headerName: 'Resp person', field: 'UserId', minWidth: 150, suppressSorting: true },
    { headerName: 'Created', field: 'Created', type: 'numericColumn', minWidth: 150 },
    { headerName: 'Earliest due', field: 'DueDate', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Order total', field: 'OrderTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Outstanding', field: 'OutstandingDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true },
  ],
  selectedFilters: {
    ProductId: 0,
    Finalised: 'All'
  },
  filterRow: {
    formName: 'BranchOrdersFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Product',
          name: 'ProductId',
          lookupName: 'Product',
          size: 'medium'
        }
      },
      {
        id: 1,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'Finalised',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const summary = action.payload;
        return {
          ...data,
          summary: summary
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.branchOrders,
  {
    selectedOrderLine: data => data.selected,
    summary: data => data.summary,
    branchOrdersActions: data => data.branchOrdersActions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.branchOrders.loading,
  gridOptions: state => state.purchaseOrderEnquiry.branchOrders.data.gridOptions,
  branchOrdersColumns: state => state.purchaseOrderEnquiry.branchOrders.data.branchOrdersColumns,
  branchOrdersActions: state => state.purchaseOrderEnquiry.branchOrders.data.branchOrdersActions,
  filterRow: state => state.purchaseOrderEnquiry.branchOrders.data.filterRow,
  selectedFilters: state => state.purchaseOrderEnquiry.branchOrders.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)