import { createActions, IDataAction, DataAction } from '../utils';

export interface IFiltersState {
  appliedFilters: any;
}

export const { types, actions } = createActions(
  {
    applyFilters: (data: { formName: string; filters: any }) => (data),
  },
  'filters');

const initialState = {
  appliedFilters: {}
};

export default (state: IFiltersState = initialState, action: IDataAction): IFiltersState => {
  switch (action.type) {
    case types.applyFilters:
      const applyFiltersAction = action as DataAction<{ formName: string; filters: any }>;
      const newAppliedFilters = state.appliedFilters;
      newAppliedFilters[applyFiltersAction.data.formName] = applyFiltersAction.data.filters;

      return {
        ...state,
        appliedFilters: newAppliedFilters
      };
    default:
      return state;
  }
};

export const selectors = {
  getFilters: (state, formName) => (state.common.filters.appliedFilters && state.common.filters.appliedFilters[formName]),
};
