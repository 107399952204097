import { takeLatest, Effect } from 'redux-saga/effects';
import { types as policyTypes, actions as policyActions } from 'ducks/securityManagement/securityPolicies';
import { connectSagaToApi } from './../utils';
import * as api from 'api/SecurityManagement/SecurityPolicies';

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(policyTypes.search, connectSagaToApi(policyActions, 'search', api.search));
  yield takeLatest(policyTypes.fetchNextPage, connectSagaToApi(policyActions, 'fetchNextPage', api.search));
  yield takeLatest(policyTypes.fetchPrevPage, connectSagaToApi(policyActions, 'fetchPrevPage', api.search));
  yield takeLatest(policyTypes.searchById, connectSagaToApi(policyActions, 'searchById', api.searchById));
  yield takeLatest(policyTypes.update, connectSagaToApi(policyActions, 'update', api.update));
  yield takeLatest(policyTypes.create, connectSagaToApi(policyActions, 'create', api.create));
  yield takeLatest(policyTypes.remove, connectSagaToApi(policyActions, 'remove', api.remove));
}
