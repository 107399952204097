import * as React from 'react';
import ImageCell from './ImageCell';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import FilterRow from 'components/common/DataGridDevEx/FilterRow';
import { CircularProgressSize } from 'utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Scrollbars from 'react-custom-scrollbars';

const styles = createStyles({
  mainDiv: {
    height: 735
  },
  grid: {
    display: 'flex',
    flexFlow: 'wrap'
  },
  preLoader: {
    display: 'flex',
    justifyContent: 'center'
  }
});
export interface IImageGridProperties extends StyledComponentProps {
  schema: any[];
  filterRow?: any;
  selectedFilters?: any;
  applyFilters?: any;
  reqParams?: any;
  isLoading?: boolean;
  variant?: string;
}

class ImageGrid extends React.Component<IImageGridProperties> {

  render(): React.ReactNode {
    const { schema, classes, filterRow, reqParams, applyFilters, selectedFilters, isLoading } = this.props;

    return (
      <div className={classes.mainDiv}>
        {filterRow && <FilterRow
          parameters={filterRow.parameters}
          formName={filterRow.formName}
          onApplyFilters={applyFilters}
          initialValues={selectedFilters}
          validate={filterRow.validate}
          reqParams={reqParams}
          hidePreFilters={filterRow.hidePreFilters}
          />}
        {
          isLoading ?
            <div className={classes.preLoader}>
              <CircularProgress
                size={CircularProgressSize}
                color={'secondary'}
                variant={'indeterminate'} />
            </div>
            :
            <Scrollbars style={{overflowX: 'hidden', height: '90%'}}>
              <div className={classes.grid}>
                {
                  schema && schema.map((item, index) => {
                    return <ImageCell data={item} key={index} />;
                  })
                }
              </div>
            </Scrollbars>
        }

      </div>
    );
  }
}

export default withStyles(styles, {index: 1})(ImageGrid);
