import { combineReducers } from 'redux';
import allocate, { IPOSAllocateState } from './allocate';
import cashReceipt, { ICashReceiptState } from './cashReceipt';
import invoices, { IInvoicesState } from './invoices';
import orders, { IOrdersState } from './orders';

export interface ICashReceiptingState {
  cashReceipt: ICashReceiptState;
  allocate: IPOSAllocateState;
  invoices: IInvoicesState;
  orders: IOrdersState;
}
const combined = combineReducers({
  cashReceipt,
  allocate,
  invoices,
  orders
});

export default combined;
