import { objectify } from 'api/utils';
import { isNull } from 'utils/utils';

export const getMessageBoxFormDefinition = (form: any, { formValues, response }: any) => {
    if (form.FormType !== 'Message') {
        return null;
    }
    const definitionMethod = getDefinitionsMethod[form.MessageType];

    return definitionMethod ? definitionMethod({ form, formValues, response }) : {};
};

export const getValidationFormDefinition = (form: any, { formValues, response }: any) => {
    const definitionMethod = getDefinitionsMethod[form.FormId || form.FormType];

    return definitionMethod ? definitionMethod({ form, formValues, response }) : {};
};

const generateDataFromRefVariables = (form, value: any): object => {
    const data = {};
    if (form.ReferenceVariables) {
        form.ReferenceVariables.forEach((refName: string) =>
            data[refName] = value
        );
    }

    return data;
};

const MaxNoButtonsInMessageBox = 3;

export const MessageBoxColor = {
    Alert: 'Amber',
    Information: 'Blue',
    Question: 'Amber',
    Error: 'Red'
};

const generateDynamicButtons = (form: any): object => {

    return form.Button.sort((a, b) => a.sequence > b.sequence ? 1 : -1).slice(0, MaxNoButtonsInMessageBox).map((b: any) => ({
        name: b.label.toLowerCase(),
        label: b.label,
        isDefault: b.default,
        data: b.value ? generateDataFromRefVariables(form, b.value) : null,
        value: b.value,
        cancelFormReSubmission: b?.cancelCallback ?? false,
        cancelRemainingValidations: b.cancelRemainingValidation || isNull(form?.ReferenceVariables)
    }));
};

const getEFTPOSActions = (form) => {
    const { EnableOkButton, EnableCancelButton, EnableYesButton, EnableAcceptButton, EnableNoButton, EnableDeclineButton } = form;

    const noDeclineAction = {
        name: 'NoDecline',
        label: EnableNoButton ? 'No' : 'Decline',
        isDefault: false,
        data: { action: 'NoDecline' }
    };
    const yesAcceptAction = {
        name: 'YesAccept',
        label: EnableYesButton ? 'Yes' : 'Accept',
        isDefault: false,
        data: { action: 'YesAccept' }
    };
    const okCancelAction = {
        name: 'OkCancel',
        label: EnableOkButton ? 'Ok' : 'Cancel',
        isDefault: false,
        data: { action: 'OkCancel' }
    };
    const actions = [];
    if (EnableNoButton || EnableDeclineButton) {
        actions.push(noDeclineAction);
    }
    if (EnableYesButton || EnableAcceptButton) {
        actions.push(yesAcceptAction);
    }
    if (EnableOkButton || EnableCancelButton) {
        actions.push(okCancelAction);
    }

    return actions;
};

const getDefinitionsMethod = {
    TrueFalseParameterRequest: ({ form }) => ({
        title: 'Warning',
        message: form.Message,
        actions: [
            {
                name: 'yes',
                label: 'Yes',
                isDefault: false,
                data: generateDataFromRefVariables(form, true)
            },
            {
                name: 'no',
                label: 'No',
                isDefault: true,
                data: generateDataFromRefVariables(form, false),
                cancelRemainingValidations: true
            }
        ]
    }),
    TrueFalseCancelParameterRequest: ({ form }) => ({
        title: 'Information',
        message: form.Message,
        actions: [
            {
                name: 'yes',
                label: 'Yes',
                isDefault: false,
                data: generateDataFromRefVariables(form, true)
            },
            {
                name: 'no',
                label: 'No',
                isDefault: false,
                data: generateDataFromRefVariables(form, false),
                cancelRemainingValidations: true
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: generateDataFromRefVariables(form, null),
                cancelRemainingValidations: true
            }
        ]
    }),
    MultipleShipDateForm: ({ form }) => ({
        title: 'Multiple ship dates',
        message: form.Message,
        actions: [
            {
                name: 'yes',
                label: 'Yes',
                isDefault: false,
                data: { MultipleShipDate: true }
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: { MultipleShipDate: false },
                cancelRemainingValidations: true
            }
        ]
    }),
    SPNewLineSelectBomComp: ({ formValues }) => ({
        title: 'Multiple Bill of Materials Found',
        message: '',
        form: {
            id: 'BillOfMaterialsValidationForm',
            layout: { columns: 1, rows: 1 },
            fields: [{
                position: { row: 1, col: 1 },
                id: '100',
                type: 'EX_LOOKUP_FIELD',
                visible: true,
                props: {
                    name: 'ProductBomCode',
                    label: 'Bill of Materials',
                    lookupName: 'BillOfMaterials',
                    params: { ProductId: parseInt(formValues.ProductId) },
                    required: true
                }
            }]
        },
        actions: [
            {
                name: 'ok',
                label: 'Ok',
                isDefault: true,
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: { ProductId: null, SellAs: null, ReturnComponentToStock: null },
                cancelRemainingValidations: true
            }
        ]

    }),
    SPNewLineSellKitAs: () => ({
        title: 'Kitting Item',
        message: 'Select required kitting status?',
        actions: [
            {
                name: 'kit',
                label: 'Sell as Kit',
                isDefault: false,
                data: { SellAs: 'k' }
            },
            {
                name: 'component',
                label: 'Sell as Components',
                isDefault: false,
                data: { SellAs: 'c' }
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: { ProductId: null, SellAs: null, ReturnComponentToStock: null },
                cancelRemainingValidations: true
            }
        ]
    }),
    RemoveOpenPickSlip: () => ({
        title: 'Remove Open Pick Slip',
        message: 'You have selected to remove this open pick slip. You need to specify what to do with the associated lines that are currently in picking.\n\n Select <DELETE> to remove the open pick slip.\n\n Select <ON ORDER> to remove the open pick slip and set the picking quantity to an order.',
        actions: [
            {
                name: 'cancel',
                label: 'CANCEL',
                isDefault: true,
                data: { PlaceOnBackOrder: null },
                cancelRemainingValidations: true
            },
            {
                name: 'delete',
                label: 'DELETE',
                isDefault: false,
                data: { PlaceOnBackOrder: false }
            },
            {
                name: 'onOrder',
                label: 'ON ORDER',
                isDefault: false,
                data: { PlaceOnBackOrder: true }
            },
        ]
    }),
    CNRestockingCharge: ({ response }) => ({
        title: 'Restocking Charge',
        initialValues: response && response.Response && objectify(response.Response.RestockingCharge),
        valuesSchema: {
            ...response && response.Response && response.Response.RestockingCharge,
            RestockingChargeCalculator: response && response.Response && objectify(response.Response.RestockingChargeCalculator)
        },
        form: {
            id: 'CNRestockingCharge',
            layout: { columns: 2, rows: 1 },
            fields: [
                {
                    position: { row: 1, col: 1 },
                    id: '100',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'CreditNoteValueDisplay',
                        label: 'Credit note value',
                        size: 'small',
                        disabled: true
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '101',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'RestockingTax',
                        label: 'Restocking tax',
                        size: 'small',
                        disabled: true
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '102',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'RestockingByLineChargeDisplay',
                        label: 'Restocking by line charge',
                        size: 'small',
                        disabled: true
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '103',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'TotalRestockingChargeDisplay',
                        label: 'Total restocking charge',
                        size: 'small',
                        disabled: true
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '104',
                    type: 'RESTOCKING_CALCULATOR_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'RestockingFee',
                        label: 'Restocking fee',
                        size: 'small',
                        type: 'number',
                        decimalSeparator: true,
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '105',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        width: '50%',
                        float: 'left'
                    },
                    props: {
                        name: 'RefundValueDisplay',
                        label: 'Refund value',
                        size: 'small',
                        disabled: true
                    }
                },
            ]
        },
        actions: [
            {
                name: 'ok',
                label: 'Ok',
                isDefault: true,
                data: {
                    ...response.Response,
                    EnterRestockingCharge: true,
                },
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: {
                    ...response.Response,
                    EnterRestockingCharge: false,
                },
                cancelRemainingValidations: true
            }
        ]
    }),
    CreditNoteConfirmation: ({ formValues, response }) => ({
        title: 'Credit Note Confirmation',
        initialValues: response && response.Response && objectify(response.Response.CreditNoteConfirmation),
        form: {
            id: 'CNRestockingCharge',
            layout: { columns: 1, rows: 1 },
            fields: [
                {
                    position: { row: 1, col: 1 },
                    id: '100',
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Print credit note',
                        name: 'PrintCreditNote',
                        defaultValue: false
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '101',
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    props: {
                        name: 'CreditNotePrinter',
                        documentType: 'CN',
                        precedentFields: ['PrintCreditNote'],
                        fieldId: 'CreditNotePrinter',
                        isCustomerScoped: true,
                        size: 'large'
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '102',
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Transmit',
                        name: 'Transmit',
                        defaultValue: false
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '103',
                    type: 'TEXT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block'
                    },
                    props: {
                        name: 'QuotePrinter',
                        disabled: true,
                        size: 'large'
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '104',
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Print docket',
                        name: 'PrintDocket',
                        defaultValue: false
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '105',
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    props: {
                        name: 'QuotePrinter',
                        documentType: 'CN',
                        fieldId: 'QuotePrinter',
                        precedentFields: ['PrintDocket'],
                        isCustomerScoped: true,
                        size: 'large'
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '106',
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        name: 'SalesOrderCategory',
                        label: 'Order category',
                        lookupName: 'OrderCategory',
                        params: { ProductId: parseInt(formValues.ProductId) },
                        isLookupTypeFixed: true,
                        required: true,
                        readOnly: true,
                        size: 'small'
                    }
                },
                {
                    position: { row: 1, col: 1 },
                    id: '107',
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        name: 'UserId',
                        label: 'User ID',
                        lookupName: 'Personnel',
                        params: { ProductId: parseInt(formValues.ProductId) },
                        size: 'small'
                    }
                },
            ]
        },
        actions: [
            {
                name: 'ok',
                label: 'Ok',
                isDefault: true,
                data: {
                    ...response.Response,
                    ConfirmCreditNote: true,
                },
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                data: {
                    ...response.Response,
                    ConfirmCreditNote: false,
                },
                cancelRemainingValidations: true
            }
        ]
    }),
    UserPIN: () => ({
        title: 'User PIN',
        form: {
            id: 'UserPIN',
            layout: { columns: 1, rows: 1 },
            fields: [{
                position: { row: 1, col: 1 },
                id: '100',
                type: 'TEXT_FIELD',
                props: {
                    name: 'UserPIN',
                    label: 'PIN',
                    type: 'password'
                }
            }]
        },
        actions: [
            {
                name: 'ok',
                label: 'Ok',
                cancelRemainingValidations: true,
                data: {
                    PromptUserPIN: true,
                },
            },
            {
                name: 'cancel',
                label: 'Cancel',
                isDefault: true,
                cancelRemainingValidations: false,
                data: {
                    PromptUserPIN: false,
                },
            }
        ]
    }),
    EFTPOSTask: ({ form }) => ({
        title: form.Status,
        message: form.DetailedStatus,
        pollingTimeoutMilliseconds: form.PollingTimeoutMilliseconds,
        actions: getEFTPOSActions(form)
    }),
    Alert: ({ form }) => ({
        title: form.Title || 'Alert',
        message: form.Message,
        icon: 'ReportProblem',
        color: MessageBoxColor.Alert,
        actions: form.Button && form.Button.length > 0 ? generateDynamicButtons(form)
            : [
                {
                    name: 'ok',
                    label: 'Ok',
                    isDefault: form.Default && ['ok', 'cancel'].includes(form.Default.toLowerCase()),
                    data: generateDataFromRefVariables(form, true),
                    cancelFormReSubmission: isNull(form?.ReferenceVariables)
                }
            ]
    }),
    Information: ({ form }) => ({
        title: form.Title || 'Information',
        message: form.Message,
        icon: 'Info',
        color: MessageBoxColor.Information,
        actions: form.Button && form.Button.length > 0 ? generateDynamicButtons(form)
            : [
                {
                    name: 'ok',
                    label: 'Ok',
                    isDefault: true,
                    data: null
                }
            ]
    }),
    Question: ({ form }) => ({
        title: form.Title || 'Question',
        message: form.Message,
        icon: 'Help',
        color: MessageBoxColor.Question,
        actions: form.Button && form.Button.length > 0 ? generateDynamicButtons(form)
            : [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    isDefault: form.Default ? form.Default.toLowerCase() === 'cancel' : true,
                    data: null,
                    cancelRemainingValidations: true
                },
                {
                    name: 'no',
                    label: 'No',
                    isDefault: form.Default && form.Default.toLowerCase() === 'no',
                    data: generateDataFromRefVariables(form, false),
                    cancelRemainingValidations: true
                },
                {
                    name: 'yes',
                    label: 'Yes',
                    isDefault: form.Default && form.Default.toLowerCase() === 'yes',
                    data: generateDataFromRefVariables(form, true)
                }
            ]
    }),
    Error: ({ form }) => ({
        title: form.Title || 'Error',
        message: form.Message,
        icon: 'Report',
        color: MessageBoxColor.Error,
        actions: form.Button && form.Button.length > 0 ? generateDynamicButtons(form)
            : [
                {
                    name: 'cancel',
                    label: 'Ok',
                    isDefault: true,
                    data: null
                }
            ]
    }),
};
