
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as configSelectors } from 'ducks/appConfig';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import DynamicFormView from './DynamicFormView';

const mapStateToProps = (state, ownProps) => ({
  operationMode: ownProps.operationMode || uiSelectors.operationMode(state),
  isV2: configSelectors.isV2(state),
  isV3: configSelectors.isV3(state),
  validate: ownProps.validate,
  form: ownProps.formName,
  key: ownProps.formName,
  formValues: getFormValues(ownProps.formName)(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetFormAsyncErrors: formActions.resetFormAsyncErrors,
  setFieldAsyncError: formActions.setFieldAsyncError
}, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: false,
    destroyOnUnmount: false
  })
)(DynamicFormView);