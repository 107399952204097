
export const MODULE_TREE = [
    { id: 'SlipDetails', label: 'Pick Slip Details', parent: '' },
    { id: 'SlipLines', label: 'Pick Slip Lines', parent: '' },
    { id: 'SlipLineDetails', label: 'Slip Line Details', parent: 'SlipLines' },
    { id: 'Lots', label: 'Lots', parent: 'SlipLineDetails' },
    { id: 'Serials', label: 'Serials', parent: 'SlipLineDetails' },
    { id: 'SlipLineBOM', label: 'Bill of Materials', parent: 'SlipLineDetails' },
    { id: 'SlipLineBOMDetails', label: 'Component Details', parent: 'SlipLineBOM' },
    { id: 'SlipLineBOMLots', label: 'Lots', parent: 'SlipLineBOMDetails' },
    { id: 'SlipLineBOMSerials', label: 'Serials', parent: 'SlipLineBOMDetails' },
    { id: 'Notepad', label: 'Notepad', parent: '' },
    { id: 'SalesDiary', label: 'Diary', parent: '' },
    { id: 'Events', label: 'Events', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' }
];
