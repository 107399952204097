import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { ISupplierInvoiceEnquirySearchFacade, ISupplierInvoiceEnquirySummaryFacade, ISortOrder } from 'api/swaggerTypes';
import { Inline } from 'api/utils';
import { ISupplierInvoiceSearchResponse, ISupplierInvoiceSearchByIdResponse } from 'api/supplierInvoiceEnquiry/interfaces';

export interface ISupplierInvoiceData {
  selected: any;
  list: ISupplierInvoiceEnquirySearchFacade[];
  schemas: Inline<ISupplierInvoiceEnquirySearchFacade>[];
  summary: ISupplierInvoiceEnquirySummaryFacade;
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
}

export interface ISupplierInvoiceState extends IExtendedState<ISupplierInvoiceData> {
  search_loading?: boolean;
  getSummary_loading?: boolean;
  searchById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = -1;

const initialData: ISupplierInvoiceData = {
  selected: NOT_SELECTED,
  list: null,
  schemas: null,
  summary: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: ''
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'supplierInvoice',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (DocumentNumber: number) => ({ DocumentNumber }),
      callback: (data: ISupplierInvoiceData, actionData: { DocumentNumber: number }) => ({ ...data, selected: actionData.DocumentNumber })
    }
  },
  asyncActions: {
    search: {
      action: (data: { SearchText: string; Sort: ISortOrder }) => ({ SearchText: data.SearchText, Sort: data.Sort, BatchPage: 1 }),
      callback: (data: ISupplierInvoiceData, actionData: { SearchText: string; Sort: ISortOrder; BatchPage: number }) =>
        ({ ...data, selected: NOT_SELECTED, currSearchText: actionData.SearchText }),
      successCallback: (data: ISupplierInvoiceData, payload: ISupplierInvoiceSearchResponse): ISupplierInvoiceData => {
        const suppliers = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          list: suppliers,
          schemas: schemas,
          nextPage: payload.nextPage && payload.currPage + 1,
          prevPage: payload.prevPage && payload.currPage - 1,
          currPage: payload.currPage
        };
      }
    },
    searchById: {
      action: (id: number) => id,
      callback: (data: ISupplierInvoiceData, _id: number) =>
        ({ ...data, selected: NOT_SELECTED }),
      successCallback: (data: ISupplierInvoiceData, payload: ISupplierInvoiceSearchByIdResponse): ISupplierInvoiceData => {
        const suppliers = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          list: suppliers,
          schemas: schemas,
          nextPage: payload.nextPage && payload.currPage + 1,
          prevPage: payload.prevPage && payload.currPage - 1,
          currPage: payload.currPage
        };
      }
    },
    fetchPrevPage: {
      action: (data: { Sort: ISortOrder; BatchPage: number }) => ({ SearchText: undefined, Sort: data.Sort, BatchPage: data.BatchPage }),
      callback: (data: ISupplierInvoiceData, actionData: { SearchText: string; Sort: ISortOrder; BatchPage: number }) => {
        // This only works because this reducer is called before the saga.
        actionData.SearchText = data.currSearchText;

        return data;
      },
      successCallback: (data: ISupplierInvoiceData, payload: ISupplierInvoiceSearchResponse): ISupplierInvoiceData => {
        const suppliers = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);
        const newList = suppliers.slice(0, data.removeWhenPrev).concat(data.list);
        const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
          prevPage: payload.prevPage && payload.currPage - 1,
          nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
          currPage: payload.currPage,
          removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
        };
      }
    },
    fetchNextPage: {
      action: (data: { Sort: ISortOrder; BatchPage: number }) => ({ SearchText: undefined, Sort: data.Sort, BatchPage: data.BatchPage }),
      callback: (data: ISupplierInvoiceData, actionData: { SearchText: string; Sort: ISortOrder; BatchPage: number }) => {
        // This only works because this reducer is called before the saga.
        actionData.SearchText = data.currSearchText;

        return data;
      },
      successCallback: (data: ISupplierInvoiceData, payload: ISupplierInvoiceSearchResponse): ISupplierInvoiceData => {

        const suppliers = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);
        const newRemoval = payload.records.length;
        const newList = data.list.concat(suppliers);
        const newSchemas = data.schemas.concat(schemas);

        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
          nextPage: payload.nextPage && payload.currPage + 1,
          prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
          currPage: payload.currPage,
          removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
        };
      }
    },
    getSummary: {
      action: (DocumentNumber: number, SalesEntity: string) => ({ DocumentNumber, SalesEntity }),
      successCallback: (data: ISupplierInvoiceData, payload: ISupplierInvoiceEnquirySummaryFacade) => ({ ...data, summary: payload })
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.DocumentNumber === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.Supplier.Value === data.selected)) || null,
    summary: (data) => data.list && data.selected && data.summary,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
);

const syncSelector = {
  isLoading: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.search_loading || state.supplierInvoiceEnquiry.supplierInvoice.searchById_loading,
  loadingNextPage: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.fetchNextPage_loading,
  loadingPrevPage: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.fetchPrevPage_loading,
  loadingSummary: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.getSummary_loading,
  pageSize: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.data.pageSize,
  totalPages: (state: { supplierInvoiceEnquiry: { supplierInvoice: ISupplierInvoiceState } }) => state.supplierInvoiceEnquiry.supplierInvoice.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
