import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as rfcActions, selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

interface ISupplierItem {
  RFCNumber: number;
  Entered: string;
  TotalDisplay: string;
  Warehouse: string;
}

const SupplierSearchLookUpDrawer = buildSearchLookUpDrawer<ISupplierItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'RFC',
    searchPlaceholder: 'Search for RFCs...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'RFCNumber',
        label: 'RFC number'
      },
      {
        filter: 'Warehouse',
        label: 'Warehouse'
      },
      {
        filter: 'Entered',
        label: 'Entered'
      },
      {
        filter: 'TotalDisplay',
        label: 'RFC total'
      }
    ],
    records: rfcSelectors.all(state) || [],
    selectedRecord: rfcSelectors.selected(state),
    selectedSortFilter: 'RFCNumber',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isSupplierLookUpOpen(state),
    isLoading: rfcSelectors.isLoading(state),
    nextPage: rfcSelectors.nextPage(state),
    prevPage: rfcSelectors.prevPage(state),
    pageSize: rfcSelectors.pageSize(state),
    totalPages: rfcSelectors.totalPages(state),
    loadingPrevPage: rfcSelectors.loadingPrevPage(state),
    loadingNextPage: rfcSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'RFCNumber',
    fieldsToDisplay: ['RFCNumber', 'Warehouse', 'Entered', 'TotalDisplay'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('RFCNumber', selectedRecord.RFCNumber);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const RFCNumber = parseInt(matchParams.get('RFCNumber'));
      if (RFCNumber) {
        return {
          id: RFCNumber
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: rfcActions.search,
  searchById: rfcActions.searchById,
  changeSelectedRecord: rfcActions.setSelected,
  onToggle: uiActions.toggleSupplierLookUp,
  fetchNextPage: rfcActions.fetchNextPage,
  fetchPrevPage: rfcActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearchLookUpDrawer);
