import React, {Component, Children} from 'react';
import PropTypes from 'prop-types';

export const makeSelectable = (MyComponent) => {
  return class extends Component {
    static propTypes = {
      children: PropTypes.node,
      onChange: PropTypes.func,
      selectedItemStyle: PropTypes.object,
      value: PropTypes.any
    };

    extendChild(child, styles, selectedItemStyle) {
      if (child && child.type && child.type.muiName === 'ListItem') {
        const selected = this.isChildSelected(child, this.props);
        let selectedChildrenStyles;
        if (selected) {
          selectedChildrenStyles = Object.assign({}, styles, selectedItemStyle);
        }

        const mergedChildrenStyles = Object.assign({}, child.props.style, selectedChildrenStyles);

        this.keyIndex += 1;

        return React.cloneElement(child, {
          onClick: (event) => {
            this.handleItemClick(event, child);
            if (child.props.onClick) {
              child.props.onClick(event);
            }
          },
          key: this.keyIndex,
          style: mergedChildrenStyles,
          className: child.props.className
        });
      } else {
        return child;
      }
    }


    isChildSelected(child, props) {
      return props.value === child.props.value;
    }

    handleItemClick = (event, item) => {
      const itemValue = item.props.value;

      if (itemValue !== this.props.value) {
        if (this.props.onChange) {
          this.props.onChange(event, itemValue);
        }
      }
    };

    render() {
      const {
        children,
        selectedItemStyle,
        ...other
      } = this.props;

      this.keyIndex = 0;
      const styles = {};

      return (
        <MyComponent {...other} {...this.state}>
          {Children.map(children, (child) => (
            this.extendChild(child, styles, selectedItemStyle))
          )}
        </MyComponent>
      );
    }
  };
};

export default makeSelectable;