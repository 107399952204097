import React from 'react';
import DataGrid,
{
    Column,
    Scrolling,
    MasterDetail,
    GroupPanel,
    SearchPanel,
    LoadPanel
} from 'devextreme-react/data-grid';
import DetailViewRenderer from '../../SummaryPanelDetailViewRenderer';
import { IMaterialLabourDataGridProps } from 'components/ServiceActivityScheduling/interfaces';
import IconsCellRendrer from 'components/common/DataGridDevEx/CustomRenderers/IconsCellRendrer';
import { isNull } from 'utils/utils';

const IconsCellRender = ({ data = {} }: any) => {
    const activities = data && data?.Activities && data?.Activities;
    const icons = [];
    if (activities.some((activity) => activity?.Images && activity?.Images.length > 0)) {
        icons.push('CameraAlt');
    }
    if (activities.some((activity) => !isNull(activity?.JournalNotes))) {
        icons.push('Chat');
    }

    return (<IconsCellRendrer style={{ padding: 0 }} icons={icons} />);
};
const MaterialLabourDataGrid = ({ data }: IMaterialLabourDataGridProps): JSX.Element => {

    return (
        <DataGrid id='grid-container'
            dataSource={data}
            keyExpr='Key'
            width={'100%'}
            noDataText=''
            height={'100%'}
            allowColumnReordering={true}
            showBorders={true}
        >
            <Column allowEditing={false} dataField={'ServiceItemText'} caption={'Service Item'} />
            <Column allowEditing={false} dataField={'ItemType'} caption={'Item Type'} cellRender={() => 'None'} />
            <Column allowEditing={false} dataField={'LabourCostTotal'} caption={'Labour $'} />
            <Column allowEditing={false} dataField={'MaterialCostTotal'} caption={'Materials $'} />
            <Column allowEditing={false} dataField={'TotalCostTotal'} caption={'Total $'} />
            <Column allowEditing={false} cellRender={IconsCellRender} caption={'Journal'} />
            <MasterDetail
                enabled={true}
                component={DetailViewRenderer}
            />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'virtual'} />
            <LoadPanel enabled={false} />
        </DataGrid>
    );
};

export default MaterialLabourDataGrid;
