import { fetchGet, objectify } from './../utils'

export const getLineDetails = (query) => {
  const { PurchaseOrder, LineNumber } = query;
  return fetchGet(`PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/Detailss`)
    .then((result) => {
      if (result.PurchaseOrderLineDetails) {
        return {
          values: objectify(result.PurchaseOrderLineDetails),
          schema: result.PurchaseOrderLineDetails
        }
      }
      return result;
    })
}

export const getLineSummary = (query) => {
  const { PurchaseOrder, LineNumber } = query;
  return fetchGet(`PurchaseOrderEnquiry/PurchaseOrder/${PurchaseOrder}/OrderLines/${LineNumber}/Summary`)
    .then((result) => (result && objectify(result.PurchaseOrderLineSummary)))
}