import React from 'react';
import DespatchConfirmationSchema from './DespatchConfirmationDialog.form';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IDespatchConfirmationDialogProperties } from './DespatchConfirmationDialog.properties';

const DespatchConfirmationDialog = (props: IDespatchConfirmationDialogProperties) => {
    const {
        open, loading, initialValues, values, valuesSchema,
        invoiceDocumentOutputOptions, setInvoiceDocumentOutputOptions,
        salesDocketDocumentOutputOptions, setSalesDocketDocumentOutputOptions,
        despatchNoteOutputOptions, setDespatchNoteDocumentOutputOptions,
        deliveryDocketOutputOptions, setDeliveryDocketOutputOptions,
        barcodeLabelOutput, setBarcodeLabelOutputOptions,
        onOk, onCancel
    } = props;

    React.useEffect(
        () => {
            if (initialValues) {
                setInvoiceDocumentOutputOptions(initialValues?.InvoiceOutput);
                setSalesDocketDocumentOutputOptions(initialValues?.SalesDocketOutput);
                setDespatchNoteDocumentOutputOptions(initialValues?.DespatchNoteOutput);
                setDeliveryDocketOutputOptions(initialValues?.DeliveryDocketOutput);
                setBarcodeLabelOutputOptions(initialValues?.BarcodeLabelOutput);
            } else {
                setInvoiceDocumentOutputOptions(undefined);
                setSalesDocketDocumentOutputOptions(undefined);
                setDespatchNoteDocumentOutputOptions(undefined);
                setDeliveryDocketOutputOptions(undefined);
                setBarcodeLabelOutputOptions(undefined);
            }
        },
        [initialValues]);

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Despatch Confirmation'
                dialogActionsButtons={true}
                dialogActionsShadow={false}
                formProps={{
                    formSchema: DespatchConfirmationSchema,
                    initialValues: {
                        ...initialValues,
                        InvoiceOutput: initialValues && initialValues.InvoiceOutput ? initialValues.InvoiceOutput.Summary : '',
                        SalesDocketOutput: initialValues && initialValues.SalesDocketOutput ? initialValues.SalesDocketOutput.Summary : '',
                        DespatchNoteOutput: initialValues && initialValues.DespatchNoteOutput ? initialValues.DespatchNoteOutput.Summary : '',
                        DeliveryDocketOutput: initialValues && initialValues.DeliveryDocketOutput ? initialValues.DeliveryDocketOutput.Summary : '',
                        BarcodeLabelOutput: initialValues && initialValues.BarcodeLabelOutput ? initialValues.BarcodeLabelOutput.Summary : ''
                    },
                    fieldExtensions: {
                        InvoiceSale: {
                            onChange: (e) => {
                                props.changeFormFieldValue('OutputInvoice', e.target.checked);
                                if (!valuesSchema.OutputBarcodeLabel.ReadOnly) {
                                    props.changeFormFieldValue('OutputBarcodeLabel', e.target.checked);
                                }
                                if (!valuesSchema.Transmit.ReadOnly) {
                                    props.changeFormFieldValue('Transmit', e.target.checked);
                                }
                            }
                        },
                        OutputBarcodeLabel: {
                            disabled: (valuesSchema?.OutputBarcodeLabel?.ReadOnly || !(values && values.InvoiceSale))
                        }
                    },
                    valuesSchema: valuesSchema ? valuesSchema : {},
                    style: { height: '575px', minWidth: '600px' },
                }}
                actions={[
                    {
                        title: 'Ok',
                        listener: () => {
                            if (onOk) {
                                onOk({
                                    ...values,
                                    InvoiceOutput: invoiceDocumentOutputOptions,
                                    SalesDocketOutput: salesDocketDocumentOutputOptions,
                                    DespatchNoteOutput: despatchNoteOutputOptions,
                                    DeliveryDocketOutput: deliveryDocketOutputOptions,
                                    BarcodeLabelOutput: barcodeLabelOutput,
                                });
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default DespatchConfirmationDialog;
