import {
    ISortOrder,
    ISearchRequest,
    IFormInfo,
    IDeleteRecordResponse,
    IServiceAgreementSearchResponse,
    IServiceAgreementDetailsResponse,
    IServiceAgreementFacade,
    IServiceAgreementScheduleDetailsFacade,
    IServiceAgreementScheduleDetailsResponse,
    IServiceAgreementSchedulesResponse,
} from './../swaggerTypes';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { IObjectified, AsObjectifiedArray } from '../utils';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
    records: IObjectified<IServiceAgreementFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return Api.postInlineCollectionResponse<ISearchRequest, IServiceAgreementSearchResponse, 'ServiceAgreements'>(
        'ServiceAgreementMaintenance/SearchServiceAgreements', { SearchText, Sort, BatchPage, BatchSize })
        .then((response) => {
            const records = asObjectifiedArray(response, 'ServiceAgreements');
            const batchInformation = (response as unknown as IServiceAgreementSearchResponse).BatchInformation;

            return {
                records,
                currPage: batchInformation ? batchInformation.BatchPage : 1,
                nextPage: batchInformation && !batchInformation.EndOfData,
                prevPage: batchInformation && batchInformation.BatchPage > 1
            };
        });
}

export async function searchById(query: { ServiceAgreementId: number }): Promise<{
    records: IObjectified<IServiceAgreementFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { ServiceAgreementId } = query;

    return Api.getInlineResponse<IServiceAgreementDetailsResponse, 'ServiceAgreementDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}`)
        .then((response) => {
            const records = isNull(response.Forms) ? [asObjectified(response, 'ServiceAgreementDetails')] : [];

            return {
                Forms: response.Forms,
                records: records,
                currPage: 1,
                nextPage: false,
                prevPage: false
            };
        });
}
export type IObjectifiedServiceAgreementResponse = Omit<IServiceAgreementDetailsResponse, 'ServiceAgreementDetails'> & {
    ServiceAgreementDetails: IObjectified<IServiceAgreementFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
};
export async function fetchServiceAgreement(ServiceAgreementId: number): Promise<IObjectifiedServiceAgreementResponse> {

    return Api.getInlineResponse<IServiceAgreementDetailsResponse, 'ServiceAgreementDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}`
    )
        .then((response) => {

            return {
                ...response,
                ServiceAgreementDetails: asObjectified(response, 'ServiceAgreementDetails')
            };
        });
}

export async function createServiceAgreement(query: IServiceAgreementFacade):
    Promise<IObjectifiedServiceAgreementResponse> {

    return Api.postInlineResponse<IServiceAgreementFacade, IServiceAgreementDetailsResponse, 'ServiceAgreementDetails'>(
        'ServiceAgreementMaintenance/CreateServiceAgreement', query)
        .then((response) => {

            return {
                ...response,
                ServiceAgreementDetails: asObjectified(response, 'ServiceAgreementDetails')
            };
        });
}

export async function deleteServiceAgreement(ServiceAgreementId: number): Promise<IDeleteRecordResponse> {
    return Api.delete<IDeleteRecordResponse>(`ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}`);
}

export async function updatedServiceAgreement(ServiceAgreementId: number, query: IServiceAgreementFacade): Promise<IObjectifiedServiceAgreementResponse> {
    return Api.putInlineResponse<IServiceAgreementFacade, IServiceAgreementDetailsResponse, 'ServiceAgreementDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}`, query)
        .then((response) => {
            return {
                ...response,
                ServiceAgreementDetails: asObjectified(response, 'ServiceAgreementDetails')
            };
        });
}
export type IObjectifiedServiceAgreementScheduleResponse = Omit<IServiceAgreementScheduleDetailsResponse, 'ServiceAgreementScheduleDetails'> & {
    ServiceAgreementScheduleDetails: IObjectified<IServiceAgreementScheduleDetailsFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
};
export async function createServiceAgreementSchedule(query: IServiceAgreementScheduleDetailsFacade):
    Promise<IObjectifiedServiceAgreementScheduleResponse> {
    const { ServiceAgreementId } = query;

    return Api.postInlineResponse<IServiceAgreementScheduleDetailsFacade, IServiceAgreementScheduleDetailsResponse, 'ServiceAgreementScheduleDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}/CreateSchedule`, query)
        .then((response) => {

            return {
                ...response,
                ServiceAgreementScheduleDetails: asObjectified(response, 'ServiceAgreementScheduleDetails')
            };
        });
}

export async function deleteServiceAgreementSchedule(ServiceAgreementId: number, ScheduleId: number): Promise<IDeleteRecordResponse> {
    return Api.delete<IDeleteRecordResponse>(`ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}/Schedule/${ScheduleId}`);
}

export async function updatedServiceAgreementSchedule(ServiceAgreementId: number, ScheduleId: number, query: IServiceAgreementFacade): Promise<IObjectifiedServiceAgreementScheduleResponse> {
    return Api.putInlineResponse<IServiceAgreementScheduleDetailsFacade, IServiceAgreementScheduleDetailsResponse, 'ServiceAgreementScheduleDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}/Schedule/${ScheduleId}`, query)
        .then((response) => {
            return {
                ...response,
                ServiceAgreementScheduleDetails: asObjectified(response, 'ServiceAgreementScheduleDetails')
            };
        });
}

export async function fetchServiceAgreementSchedule(ServiceAgreementId: number, ScheduleId: number): Promise<IObjectifiedServiceAgreementScheduleResponse> {

    return Api.getInlineResponse<IServiceAgreementScheduleDetailsResponse, 'ServiceAgreementScheduleDetails'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}/Schedule/${ScheduleId}`
    )
        .then((response) => {

            return {
                ...response,
                ServiceAgreementScheduleDetails: asObjectified(response, 'ServiceAgreementScheduleDetails')
            };
        });
}

export async function fetchServiceAgreementSchedules(ServiceAgreementId: number): Promise<AsObjectifiedArray<IServiceAgreementSchedulesResponse, 'ServiceAgreementSchedules'>> {

    return Api.getInlineCollectionResponse<IServiceAgreementSchedulesResponse, 'ServiceAgreementSchedules'>(
        `ServiceAgreementMaintenance/ServiceAgreement/${ServiceAgreementId}/Schedules`
    )
        .then((response) => {

            return {
                ...response,
                ServiceAgreementSchedules: asObjectifiedArray(response, 'ServiceAgreementSchedules')
            };
        });
}
