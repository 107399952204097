import * as React from 'react';
import WorksaleSearch from './WorksaleSearch';
import IWorksaleLookupPanelProps, { IWorksaleLookupPanelHandle } from './WorksaleLookupPanel.properties';
import { IWorksaleSearchHandle } from './WorksaleSearch/WorksaleSearch.properties';
import ParkedSaleList from './ParkedSaleList';
import { ClickAwayListener } from '@material-ui/core';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { IWorksaleCriteriaFacade } from 'api/swaggerTypes';
import LookupPanel from 'components/common/LookupPanel';
import { useFetchCurrentWorksale } from 'api/Worksale/worksale';

function WorksaleLookupPanel(props: IWorksaleLookupPanelProps, ref: React.Ref<IWorksaleLookupPanelHandle>): JSX.Element {

    const {
        open, loading, disabled, parkedSalesListItems, parkedSalesListItemsLoading,
        onOpenChanged: onToggleOpen, onApplyWorksaleCriteria, handleRecordChange
    }: IWorksaleLookupPanelProps = props;

    const { data: currentWorksaleData } = useFetchCurrentWorksale();
    const worksaleSearchRef = React.useRef<IWorksaleSearchHandle>();

    const data = React.useMemo(() => (currentWorksaleData?.WorkSale?.Criteria?.inlineObject), [currentWorksaleData]);

    React.useImperativeHandle(
        ref,
        () => ({
            focusCustomer(): void {
                worksaleSearchRef.current?.focusOnCustomerField();

            },
        })
    );

    async function handleApply(values: IWorksaleCriteriaFacade): Promise<void> {
        const { CustomerId = null, Date = '', CustomerPurchaseOrder = '', Period = '', SalesEntity = '' } = values || {};

        if (onApplyWorksaleCriteria) {
            const response = await onApplyWorksaleCriteria({
                CustomerId,
                CustomerPurchaseOrder: CustomerPurchaseOrder,
                Date: Date,
                Period: Number(Period),
                SalesEntity: SalesEntity,
                CheckCustomerPO: true
            });

            if (response?.Status !== true) {
                worksaleSearchRef.current?.focusOnCustomerField();
            }
        }
    }

    const formData = React.useMemo(
        () => ({
            CustomerId: data?.CustomerId,
            Customer: (data as any)?.CustomerIdDisplay || data?.CustomerId?.toString(),
            CustomerPurchaseOrder: data?.CustomerPurchaseOrder,
            Date: data?.Date,
            Period: Number(data?.Period),
            SalesEntity: data?.SalesEntity,
            Recalled: data?.Recalled
        }),
        [data]
    );

    const warnChanges = () => {
        if (worksaleSearchRef.current?.isDirty()) {
            showSnackBar({ variant: 'warning', message: 'Please apply or undo changes.' });
        }
    };

    const handleOnClickAway = (e) => {
        const dialogElement = document.querySelector('.MuiDialog-container');
        if (open && !dialogElement?.contains(e.target)) { // check if click is not on dialog box otherwise show warning
            warnChanges();
        }
    };

    return (
        <ClickAwayListener onClickAway={handleOnClickAway} >
            <div>
                <LookupPanel open={open} setOpen={onToggleOpen}>
                    <WorksaleSearch
                        innerRef={worksaleSearchRef}
                        loading={loading}
                        formData={formData}
                        disabled={disabled}
                        onApply={handleApply}
                        onUndo={() => undefined}
                    />
                    <ParkedSaleList
                        listItems={parkedSalesListItems}
                        isLoading={loading || parkedSalesListItemsLoading}
                        disabled={disabled}
                        keyField={'ParkedSaleId'}
                        fieldsToDisplay={['ParkedSaleId', 'DateTime', 'CustomerName', null]}
                        handleRecordChange={handleRecordChange}
                    />
                </LookupPanel>
            </div>
        </ClickAwayListener>
    );
}

export default React.memo(React.forwardRef(WorksaleLookupPanel));
