import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetch: (balanceDetails) => balanceDetails,
  }
}, 'balances')

let initialState = asyncInitialState({
  balanceDetails: null
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.fetch:
      return asyncOnRequest(state, action)

    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          balanceDetails: action.payload
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.supplierEnquiry.balances,
  {
    balanceDetails: data => data.balanceDetails,
  }
)

const syncSelector = {
  
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)



