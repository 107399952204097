import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/priceCheck/details';

import { callApi } from '../utils';
import * as api from 'api/priceCheck/details';
import * as pricingApi from 'api/customerEnquiry/pricing';


function* search(action) {
  const { success, failure } = actions.saga.fetch;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* getCustomerPriceSummary(action) {
  const { success, failure } = actions.saga.fetchCustomerSummary;

  yield callApi(
    call(pricingApi.getPricingSummary, action.data),
    success,
    failure
  )
}

export default function* rootSaga() {
  yield takeLatest(types.fetch, search)
  yield takeLatest(types.fetchCustomerSummary, getCustomerPriceSummary)
}