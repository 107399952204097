import { call, takeLatest } from 'redux-saga/effects';

import { types as reservationDetailsType, actions as reservationDetailsActions } from 'ducks/salesOrderEnquiry/reservationDetails';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/reservationDetails';

function* getReservationDetails(action) {
  const { success, failure } = reservationDetailsActions.saga.fetchReservationDetails;
  yield callApi(
    call(api.getReservationDetails, action.data),
    success,
    failure
  )
}

export default function* rootLineDetailsSaga() {
  yield takeLatest(reservationDetailsType.fetchReservationDetails, getReservationDetails)
}