import { combineReducers } from 'redux';

import quote from './quote';
import BOM from './BOM';
import reservationLots from './reservationLots';
import bomReservationLots from './bomReservationLots';
import reservationSerials from './reservationSerials';
import bomReservationSerials from './bomReservationSerials';


const customerQuoteEnquiry = combineReducers({
  quote,
  BOM,
  reservationLots,
  reservationSerials,
  bomReservationLots,
  bomReservationSerials
});

export default customerQuoteEnquiry;
