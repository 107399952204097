import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as salesOrderTypes, actions as salesOrderActions } from 'ducks/salesOrderEnquiry/salesOrder';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/salesOrder';

function* search(action) {
  const { success, failure } = salesOrderActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = salesOrderActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* getDetails(action) {
  const { success, failure } = salesOrderActions.saga.getDetails;
  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  )
}
function* fetchSummary(action) {
  const { success, failure } = salesOrderActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = salesOrderActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = salesOrderActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootSalesOrderSaga() {
  yield takeLatest(salesOrderTypes.search, search)
  yield takeLatest(salesOrderTypes.searchById, searchById)
  yield takeLatest(salesOrderTypes.getDetails, getDetails)
  yield takeLatest(salesOrderTypes.fetchSummary, fetchSummary)
  yield takeLatest(salesOrderTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(salesOrderTypes.fetchNextPage, fetchNext)
}