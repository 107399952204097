export default [
  {
    id: '1',
    name: 'Phone Order',
    abbreviation: 'P',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Normal Order',
    abbreviation: 'N',
    fieldList1: 'field'
  },
];
