const CDReservationDetails = {
  'id': 'CDReservationDetails',
  'layout': {
    'rows': 1,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 1.5,
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product description ',
            'name': 'ProductDescription',
            'placeholder': 'Product description',
            'size': 'large',
            'required': false,
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reserved',
            'name': 'ReservedQuantity',
            'placeholder': 'Reserved',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'name': 'EnteredDate',
            'label': 'Entered date',
            'size': 'small',
            'required': false,
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'name': 'ExpiryDate',
            'label': 'Expiry date',
            'size': 'small',
            'required': false,
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'name': 'UserId',
            'label': 'User ID',
            'placeholder': 'User ID',
            'size': 'small',
            'required': false,
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5,
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reference',
            'name': 'Reference',
            'placeholder': 'Reference',
            'size': 'medium',
            'required': false,
          }
        },
        {
          'id': 1,
          'type': 'TEXT_AREA',
          'props': {
            'name': 'ReservationComment',
            'label': 'Reservation comment',
            'fullWidth': true,
            'style': {
              'height': '200px'
            }
          }
        }
      ]
    }
  ]
}
export default CDReservationDetails;
