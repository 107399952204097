import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';

export interface IQuotesData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IQuotesState extends IExtendedState<IQuotesData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView, defaultWarehouse) => ({ defaultView, defaultWarehouse }),
    asyncs: {

    }
  },
  'invoices');

const RequiredFields = [
  'EntityView', 'Finalised'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IQuotesData>({
  selected: null,
  selectedFilters: {
    EntityView: 'MPRO',
    Warehouse: '',
    CustomerId: '',
    Finalised: 'Unfinalised',
  },
  filterRow: {
    formName: 'IESQuotesFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'small',
          required: false
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Status',
          name: 'Finalised',
          required: false,
          options: [
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            }
          ]
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    { headerName: 'Sales entity', field: 'SalesEntity', minWidth: 120, suppressSorting: true, },
    {
      headerName: 'Quote',
      field: 'QuoteId',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.QuoteId) {
          return {
            Quote: row.data.QuoteId,
            FieldValue: row.data.QuoteId
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-quote-enquiry'
      }
    },

    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 150, suppressSorting: true },
    { headerName: 'Line', field: 'LineNo', minWidth: 100, type: 'numericColumn', suppressSorting: true },
    {
      headerName: 'Customer',
      field: 'CustomerIdDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            CustomerId: row.data.CustomerId,
            FieldValue: row.data.CustomerIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Entered', field: 'EnteredDate', minWidth: 120, suppressSorting: true },
    { headerName: 'Expiry', field: 'ExpiryDate', minWidth: 120, suppressSorting: true },
    { headerName: 'Status', field: 'StatusDescription', suppressSorting: true, minWidth: 150 },
    { headerName: 'Quote type', field: 'QuoteType', suppressSorting: true, minWidth: 150 },
    { headerName: 'Customer name', field: 'CustomerIdLabel', suppressSorting: true, minWidth: 150 },
    { headerName: 'Delivery name', field: 'DeliveryName', suppressSorting: true, minWidth: 150 },
    { headerName: 'Description', field: 'Description', suppressSorting: true, minWidth: 150 },
  ]
});

export default (state: IQuotesState = initialState, action: IDataAction): IQuotesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data.defaultView;
      const warehouse = action.data.defaultWarehouse;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView,
            Warehouse: warehouse
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.selected,
  gridOptions: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.gridOptions,
  columns: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.Columns,
  Actions: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.Actions,
  filterRow: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.filterRow,
  selectedFilters: (state: { inventoryEnquiry: { sales: { quotes: IQuotesState } } }) => state.inventoryEnquiry.sales.quotes.data.selectedFilters
});
