import React from 'react';
import MessageBoxValidationDialog from 'components/common/ValidationDialog/MessageBoxValidationDialog';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { IBOMDialogProperties, IBOMGridHandle, IBOMGridProperties } from './BOMDialog.properties';
import { FOOTER_MESSAGE, GRID_HEIGHT } from './BOMDialog.constants';
import styles from './BOMDialog.styles';
import { withStyles } from '@material-ui/core';
import { ARROW_DOWN, ARROW_UP, ESCAPE, TAB } from 'utils/constants';

const BOMGrid = (props: IBOMGridProperties, ref: React.Ref<IBOMGridHandle>) => {

  const { classes, data, showFooterMessage, onSelectedRowChanged, onEscape, onTabOut } = props;

  const dataGridInnerRef = React.useRef<DataGrid>(null) as React.MutableRefObject<DataGrid | null>;
  const [isContentLoaded, setIsContentLoaded] = React.useState<boolean>(false);

  React.useImperativeHandle(
    ref,
    () => ({
      focusGrid(): void {
        selectFirstRow();
      },
    })
  );

  React.useEffect(
    () => {
      if (onSelectedRowChanged) {
        onSelectedRowChanged((data && data.length) ? data[0].BomCode : null);
      }
    },
    [data]
  );

  const handleOnSelectionChanged = (d) => {
    if (onSelectedRowChanged) {
      onSelectedRowChanged(d.selectedRowKeys ? d.selectedRowKeys[0] : null);
    }
  };

  const selectFirstRow = async () => {
    if (dataGridInnerRef.current) {
      dataGridInnerRef.current.instance.focus();
      await dataGridInnerRef.current.instance.selectRowsByIndexes([0]);
      setIsContentLoaded(false);
    }
  };

  const handleDataGridKeyDown = async (e: any) => {
    const keyboardEvent = e.event as KeyboardEvent;

    const selKey = e.component.getSelectedRowKeys();
    const visibleRowsLength = e.component.getVisibleRows().length;

    if (selKey.length) {
      const currentKey = selKey[0];
      let index = e.component.getRowIndexByKey(currentKey);
      if (keyboardEvent.keyCode === ARROW_UP) {
        index--;
        if (index >= 0) {
          await e.component.selectRowsByIndexes([index]);
          keyboardEvent.stopPropagation();
        }
      } else if (keyboardEvent.keyCode === ARROW_DOWN) {
        index++;
        if (index < visibleRowsLength) {
          await e.component.selectRowsByIndexes([index]);
          keyboardEvent.stopPropagation();
        }
      }
    }
    e.component.focus();

    if (onEscape && keyboardEvent.keyCode === ESCAPE) {
      onEscape();
    }
    if (onTabOut && keyboardEvent.keyCode === TAB) {
      onTabOut();
    }
  };

  React.useEffect(
    () => {
      setIsContentLoaded(true);
    },
    []
  );

  const onContentReady = React.useCallback(
    () => {
        if (isContentLoaded) {
          selectFirstRow();
        }
    },
    [isContentLoaded, selectFirstRow]
  );

  return (
    <div>
      <DataGrid
        keyExpr={'BomCode'}
        ref={dataGridInnerRef}
        className={classes.datagrid}
        allowColumnReordering={true}
        dataSource={data || []}
        repaintChangesOnly={true}
        height={GRID_HEIGHT}
        columnResizingMode={'nextColumn'}
        allowColumnResizing={true}
        noDataText=''
        showBorders={false}
        onKeyDown={handleDataGridKeyDown}
        onFocusedCellChanging={(e) => {
          e.isHighlighted = false;
        }}
        selection={{
          mode: 'single'
        }}
        sorting={{
          mode: 'none'
        }}
        onSelectionChanged={handleOnSelectionChanged}
        paging={{
          enabled: false
        }}
        onContentReady={onContentReady}
      >
        <Column
          caption={'BOM code'}
          dataField={'BomCode'}
          width={'20%'}
        />
        <Column
          caption={'Primary'}
          dataField={'Primary'}
          width={'15%'}
          calculateCellValue={(d) => d.Primary ? 'Yes' : 'No'}
        />
        <Column
          caption={'Description'}
          dataField={'Description'}
          width={'65%'}
        />
      </DataGrid>
      {showFooterMessage && <p>{FOOTER_MESSAGE}</p>}
    </div>
  );
};

const BOMGridStyled = withStyles(styles, { index: 1 })(React.forwardRef(BOMGrid));

const BOMDialog = (props: IBOMDialogProperties) => {
  const { open, data, kitStatus, onSelectedRowChanged, onActionClick, onClose } = props;

  const bomGridRef = React.useRef<IBOMGridHandle>(null);
  const messageBoxRef = React.useRef(null);

  const handleOnActionClick = (status: 'k' | 'c') => {
    if (onActionClick) {
      onActionClick(status);
    }
  };

  const handleFocusModalContent = () => {
    if (bomGridRef.current) {
      bomGridRef.current.focusGrid();
    }
  };

  const handleTabOut = () => {
    if (messageBoxRef.current && messageBoxRef.current.firstButtonRef) {
      setTimeout(
        () => {
          messageBoxRef.current.firstButtonRef.focus();
        },
        0
      );
    }
  };

  const handleMessageDialogKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === ESCAPE && onClose) {
      onClose();
    }
  };

  return (
    <div>
      <MessageBoxValidationDialog
        innerRef={messageBoxRef}
        onKeyDown={handleMessageDialogKeyDown}
        open={open}
        title='Select Bill of Materials'
        color={'Green'}
        applyDialogSkin
        modalContent={
          <BOMGridStyled
            innerRef={bomGridRef}
            data={data}
            showFooterMessage={(kitStatus === 'a' || kitStatus === '')}
            onSelectedRowChanged={onSelectedRowChanged}
            onEscape={() => handleOnActionClick(null)}
            onTabOut={handleTabOut} />
        }
        focusModalContent={handleFocusModalContent}
        actions={kitStatus === 'k' ?
          [
            {
              name: 'go-back',
              label: 'Go back',
              isDefault: false,
              callback: () => handleOnActionClick(null)
            },
            {
              name: 'sell-as-kit',
              label: 'Sell as Kit',
              isDefault: true,
              callback: () => handleOnActionClick('k')
            },
          ] : kitStatus === 'c' ? [
            {
              name: 'go-back',
              label: 'Go back',
              isDefault: false,
              callback: () => handleOnActionClick(null)
            },
            {
              name: 'sell-as-components',
              label: 'Sell as components',
              isDefault: true,
              callback: () => handleOnActionClick('c')
            },
          ] : [
            {
              name: 'go-back',
              label: 'Go back',
              isDefault: false,
              callback: () => handleOnActionClick(null)
            },
            {
              name: 'sell-as-components',
              label: 'Sell as components',
              isDefault: false,
              callback: () => handleOnActionClick('c')
            },
            {
              name: 'sell-as-kit',
              label: 'Sell as Kit',
              isDefault: true,
              callback: () => handleOnActionClick('k')
            },
          ]}
      />
    </div>
  );
};

export default BOMDialog;
