import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    worksaleDetailsContainer: {
        width: 'calc(100% - 350px)',
        height: 'calc(100% - 180px)',
        padding: '15px',
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
    },
    grid: {
        marginTop: '10px'
    },
    recallIcon: {
        width: 35,
        height: 38
    }
});
