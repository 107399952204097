import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../../utils';
import { FormViewField } from 'components/FormView';

export interface IImagesData {
  images: any[];
  schema: any[];
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
  };
}

export interface IImagesState extends IExtendedState<IImagesData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (filters) => (filters),
    }
  },
  'supplierProductImages');

const initialState = asyncInitialState<IImagesData>({
  images: null,
  schema: null,
  selectedFilters: {
    Keywords: '',
    Description: ''
  },
  filterRow: {
    formName: 'ImagesFilters',
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'Keywords',
          name: 'Keywords',
          size: 'medium',
          required: false,
        }
      },
      {
        id: 1,
        type: 'TEXT_FIELD',
        props: {
          label: 'Description',
          name: 'Description',
          size: 'medium',
          required: false,
        }
      }
    ] as FormViewField[]
  },
});

export default (state: IImagesState = initialState, action: DataAction): IImagesState => {
  switch (action.type) {
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: IImagesData, successAction: SuccessAction) => {
        return {
          ...data,
          images: successAction.payload.inlineObject,
          schema: successAction.payload.schema
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { supplierProductEnquiry: { attachments: { images: IImagesState } } }) => state.supplierProductEnquiry.attachments.images,
    {
      images: (data: IImagesData) => data.images,
      schema: (data: IImagesData) => data.schema
    }),
  isLoading: (state: { supplierProductEnquiry: { attachments: { images: IImagesState } } }) => state.supplierProductEnquiry.attachments.images.loading,
  filterRow: (state: { supplierProductEnquiry: { attachments: { images: IImagesState } } }) => state.supplierProductEnquiry.attachments.images.data.filterRow,
  selectedFilters: (state: { supplierProductEnquiry: { attachments: { images: IImagesState } } }) => state.supplierProductEnquiry.attachments.images.data.selectedFilters
};
