import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/salesInvoiceEnquiry/SalesAuditDetails';

import { callApi } from '../utils';
import * as api from 'api/salesInvoiceEnquiry/SalesAuditDetails';

function* fetchSummary(action) {
  const { success, failure } = invoiceLineActions.saga.fetchSummary;

  yield callApi(
    call(api.getSummary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(invoiceLineTypes.fetchSummary, fetchSummary)
}