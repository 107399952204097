import { IFormViewForm } from 'components/FormView';

const GeneralActivity: IFormViewForm = {
    id: 'GeneralActivity',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1,
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: false,
                elevation: 0
            },
            children: [
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Title',
                        name: 'Title',
                        size: 'large',
                        required: true,
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Description',
                        name: 'Description',
                        size: 'large',
                    }
                },
                {
                    id: 3,
                    type: 'DATE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '50%'
                    },
                    props: {
                        label: 'Start Date',
                        name: 'StartDate',
                        size: 'small',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        required: true,
                    }
                },
                {
                    id: 4,
                    type: 'DATE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '50%'
                    },
                    props: {
                        label: 'End Date',
                        name: 'EndDate',
                        size: 'small',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        required: true,
                    }
                },
                {
                    id: '5',
                    type: 'TIME_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '50%'
                    },
                    props: {
                        required: true,
                        label: 'Start Time',
                        name: 'StartTime',
                        size: 'small',
                    }
                },
                {
                    id: '6',
                    type: 'TIME_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '50%'
                    },
                    props: {
                        required: true,
                        label: 'End Time',
                        name: 'EndTime',
                        size: 'small',
                    }
                },
                {
                    id: 7,
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'All day event',
                        name: 'AllDayEventToggle',
                        disabled: false,
                        defaultValue: true,
                    }
                },
            ]
        }
    ]
};
export default GeneralActivity;
