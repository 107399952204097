import * as React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './NavigationTabs.scss';

const classStyles = {
  tabsIndicator: {
    backgroundColor: '#f99d1b',
    height: '4px',
    marginTop: '-4px'

  },
  tabsRoot: {
    minHeight: 40
  },
  tabRoot: {
    maxWidth: 500,
    backgroundColor: '#555555',
    height: 40
  },
  tabLabel: {
    color: 'white'
  }
};
interface INavigatonTabsProps {
  classes: any;
  tabsData: any;
  selectedTab: any;
  operationMode: any;
  onTabChange(args: any): void;
}
class NavigationTabs extends React.PureComponent<INavigatonTabsProps> {

  handleTabChange = (_event: any, val: any) => {
    this.props.onTabChange(val);
  }

  render(): React.ReactNode {
    const { classes, tabsData, selectedTab } = this.props;

    return (
      <div className={styles.tabSection}>
        <Tabs
          onChange={this.handleTabChange}
          value={selectedTab}
          classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          fullWidth
        >
          {tabsData.map((tab) => (
            <Tab
              classes={{ root: classes.tabRoot, label: classes.tabLabel }}
              label={tab.label}
              value={tab.id}
              className={styles.tabItem}
              key={tab.id}>
            </Tab>
          ))}
        </Tabs>
      </div >
    );
  }
}

export default withStyles(classStyles, {index: 1})(NavigationTabs);
