import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as pricesTypes, actions as pricesActions } from 'ducks/inventoryEnquiry/pricing/prices';

import { callApi } from '../../utils';
import * as api from 'api/inventoryEnquiry/pricing/prices';

function* fetchSummary(action) {
  const { success, failure } = pricesActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}


export default function* rootCustomerSaga() {
  yield takeLatest(pricesTypes.fetchSummary, fetchSummary)
}