import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: '500px',
        height: '350px',
        padding: 0,
        flexGrow: 1,
        boxSizing: 'border-box',
        margin: 0
    },
    widgetDataGrid: {
        height: '100%'
    },
});
