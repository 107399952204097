import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { List, ListItem, Drawer, CircularProgress } from '@material-ui/core';
import SubMenu from './SubMenu';
import SalesProcessing from '@markinson/uicomponents-v2/SvgIcons/SalesProcessing';
import AccountsReceivable from '@markinson/uicomponents-v2/SvgIcons/AccountsReceivable';
import Pricing from '@markinson/uicomponents-v2/SvgIcons/Pricing';
import Inventory from '@markinson/uicomponents-v2/SvgIcons/Inventory';
import AccountsPayable from '@markinson/uicomponents-v2/SvgIcons/AccountsPayable';
import Services from '@markinson/uicomponents-v2/SvgIcons/Services';
import Banking from '@markinson/uicomponents-v2/SvgIcons/Banking';
import Warehousing from '@markinson/uicomponents-v2/SvgIcons/Warehousing';
import Purchasing from '@markinson/uicomponents-v2/SvgIcons/Purchasing';
import Scrollbars from 'react-custom-scrollbars';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ITopLevelMenu } from 'api/swaggerTypes';
import DynamicDataExtract from 'assets/DynamicDataExtractIcon';

const CIRCULAR_PROGRESS_SIZE = 20;

const styles = (theme) => ({
  drawer: {
    top: 64,
    width: 210,
    boxShadow: ''
  },
  paperDrawer: theme.mixins.gutters({
    backgroundColor: '#444444',
    width: 210,
    outline: 'none',
    color: '#FFFFFF'
  }),
});

const preLoaderItemStyle = {
  height: 52,
  width: 210,
  padding: 16,
  backgroundColor: 'transparent'
};

interface IMainMenuState {
  selectedIndex: number;
}

interface IMainMenuProperties {
  defaultValue?: any;
  open?: any;
  classes: any;
  isLoading?: boolean;
  mainMenuData: ITopLevelMenu[];
}

class MainMenu extends React.Component<IMainMenuProperties, IMainMenuState> {

  constructor(props: IMainMenuProperties) {
    super(props);
    this.state = {
      selectedIndex: -1
    };
  }

  UNSAFE_componentWillMount(): void {
    this.setState({
      selectedIndex: this.props.defaultValue || -1,
    });
  }

  handleClick = (index: number) => {
    this.setState({
      selectedIndex: this.state.selectedIndex === index ? -1 : index
    });
  }

  renderListItem(moduleItem: any, value: any): any {

    const listStyle = {
      height: 52,
      width: 210,
      padding: 12,
      backgroundColor: this.state.selectedIndex === value ? '#BBBBBB' : 'transparent',
      color: this.state.selectedIndex === value ? '#000000' : '#FFFFFF',
      fontSize: 15,
      fontWeight: 400
    };

    const iconStyle = {
      width: 26,
      height: 26,
      color: this.state.selectedIndex === value ? '#000000' : '#FFFFFF',
      left: 0,
    };

    const IconComponent = {
      SalesProcessing: SalesProcessing,
      Inventory: Inventory,
      AccountsReceivable: AccountsReceivable,
      Pricing: Pricing,
      AccountsPayable: AccountsPayable,
      Services: Services,
      Banking: Banking,
      Warehousing: Warehousing,
      Purchasing: Purchasing,
      DynamicDataExtract,
    };
    const SpecificIcon = IconComponent[moduleItem.iconType];

    return (

      <ListItem
        button
        style={listStyle}
        key={value}
        value={value}
        onClick={() => {
          this.handleClick(value);
        }}
        title={moduleItem.label}
      >
        <ListItemIcon>
          <SpecificIcon
            style={iconStyle}
          />
        </ListItemIcon>
        {moduleItem.label}
      </ListItem>

    );
  }

  render(): React.ReactNode {
    const { open, classes, mainMenuData, isLoading } = this.props;

    return (
      <React.Fragment>
        <Drawer
          anchor={'left'}
          open={open}
          className={classes.drawer}
          ModalProps={{
            hideBackdrop: true,
            disableEnforceFocus: true,
            disableRestoreFocus: true,
            disableAutoFocus: true
          }}
          PaperProps={{
            style: {
              padding: 0,
              height: '100%'
            },
            className: classes.paperDrawer,
            elevation: 0
          }}>
          <Scrollbars>
            <List
              style={{ padding: 0, margin: 0, }}
            >
              {!isLoading ?
                mainMenuData && mainMenuData.map((moduleItem, index) => (this.renderListItem(moduleItem, index++))) :
                <ListItem
                  button
                  style={preLoaderItemStyle}
                >
                  <CircularProgress size={CIRCULAR_PROGRESS_SIZE} color={'secondary'} variant={'indeterminate'} />
                </ListItem>}
            </List>
          </Scrollbars>
        </Drawer>
        {this.state.selectedIndex > -1 && open ?
          <SubMenu
            open={this.state.selectedIndex !== -1}
            moduleItem={mainMenuData[this.state.selectedIndex]}
          >
          </SubMenu> : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { index: 1 })(MainMenu);
