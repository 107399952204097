import SupplierDetails from './../data/forms/supplier_enquiry/SupplierDetails'
import Notepad from './../data/forms/supplier_enquiry/NotepadForm'
import CreditDiary from './../data/forms/supplier_enquiry/CreditDiary'
import PurchaseDiary from './../data/forms/supplier_enquiry/PurchaseDiary'
import PurchaseDiaryDetails from './../data/forms/supplier_enquiry/PurchaseDiaryDetails'
import ContactsForm from './../data/forms/supplier_enquiry/ContactsForm'
import DeliveryDetailsForm from './../data/forms/supplier_enquiry/DeliveryDetailsForm'
import UserFields from './../data/forms/supplier_enquiry/UserFields'
import Transactions from './../data/forms/supplier_enquiry/Transactions'
import TransactionDetails from './../data/forms/supplier_enquiry/TransactionDetails'
import DetailsTransactions from './../data/forms/supplier_enquiry/DetailsTransactions'
import Invoices from './../data/forms/supplier_enquiry/activity/invoices'
import GRN from './../data/forms/supplier_enquiry/activity/grns'
import Movements from './../data/forms/supplier_enquiry/activity/movements'
import Freight from './../data/forms/supplier_enquiry/activity/freight'
import RFC from './../data/forms/supplier_enquiry/activity/rfc'
import ChildAccount from './../data/forms/supplier_enquiry/financial/ChildAccounts'
import Balances from './../data/forms/supplier_enquiry/financial/balances'
import CreditDiaryDetails from './../data/forms/supplier_enquiry/CreditDiaryDetails'
import PurchaseOrder from './../data/forms/supplier_enquiry/purchasing/PurchaseOrders'
import SupplierSettingsByWarehouse from './../data/forms/supplier_enquiry/settings/SupplierSettingsByWarehouse'
import SupplierFinancialSettings from './../data/forms/supplier_enquiry/settings/SupplierFinancialSettings'
import SupplierGeneralSettings from './../data/forms/supplier_enquiry/settings/SupplierGeneralSettings'
import SupplierSettingsAttributes from './../data/forms/supplier_enquiry/settings/SupplierSettingsAttributes'
import LocalShipments from './../data/forms/supplier_enquiry/purchasing/LocalShipments'
import SupplierPurchasingProducts from './../data/forms/supplier_enquiry/purchasing/Products'
import Documents from './../data/forms/supplier_enquiry/attachments/Documents'
import Links from './../data/forms/supplier_enquiry/attachments/Links'
import Images from './../data/forms/supplier_enquiry/attachments/Images'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'SupplierDetails':
      return SupplierDetails
    case 'Notepad':
      return Notepad
    case 'CreditDiary':
      return CreditDiary
    case 'PurchaseDiary':
      return PurchaseDiary
    case 'PurchaseDiaryDetails':
      return PurchaseDiaryDetails
    case 'Contacts':
      return ContactsForm
    case 'DeliveryDetails':
      return DeliveryDetailsForm
    case 'UserFields':
      return UserFields
    case 'Transactions':
      return Transactions
    case 'TransactionDetails':
      return TransactionDetails
    case 'DetailsTransactions':
      return DetailsTransactions
    case 'SupplierActivityInvoices':
      return Invoices
    case 'SupplierActivityGRNs':
      return GRN
    case 'SupplierActivityMovements':
      return Movements
    case 'SupplierActivityFreight':
      return Freight
    case 'SupplierActivityRFCs':
      return RFC
    case 'SupplierFinancialChildAccounts':
      return ChildAccount
    case 'SupplierFinancialBalances':
      return Balances
    case 'CreditDiaryDetails':
      return CreditDiaryDetails
    case 'SupplierPurchaseOrder':
      return PurchaseOrder
    case 'SupplierSettingsByWarehouse':
      return SupplierSettingsByWarehouse
    case 'SupplierFinancialSettings':
      return SupplierFinancialSettings
    case 'SupplierGeneralSettings':
      return SupplierGeneralSettings
    case 'LocalShipments':
      return LocalShipments
    case 'SupplierPurchasingProducts':
      return SupplierPurchasingProducts
    case 'Documents':
      return Documents
    case 'Links':
      return Links
    case 'Images':
      return Images
    case 'SupplierSettingsAttributes':
      return SupplierSettingsAttributes
    default:
      return SupplierDetails
  }
}

export default getFormSchema
