import { isNull } from 'lodash';
/* eslint-disable no-use-before-define */
import { apiEndpoint } from '../config';
import form from 'ducks/form';

const HTTP_OK = 200;
const HTTP_NO_CONTENT = 204;
const HTTP_ERROR_MSGS = {
  400: 'Validation Error',
  401: 'Login Failed: Username/password is incorrect.',
  404: 'Error: Resource not found'
}

export const checkStatus = (response) => {

  if (response.status === HTTP_OK || response.status === HTTP_NO_CONTENT) {
    return response;
  } else {
    const errorMsg = HTTP_ERROR_MSGS[response.status] || response.statusText;
    const error = new Error(errorMsg);
    error.res = response;
    error.showUser = true;
    throw error;
  }
};

export const objectToUrlParam = (data) => Object.keys(data).map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');

export const objectifyAddress = (object, diffAddress, keys = null) => {
  if (!keys) {
    return {
      addressee: object[`${(diffAddress || '')}AddressName`],
      addressLine1: object[`${(diffAddress || '')}AddressLine1`],
      addressLine2: object[`${(diffAddress || '')}AddressLine2`],
      city: object[`${(diffAddress || '')}AddressCity`],
      state: object[`${(diffAddress || '')}AddressState`],
      postalCode: object[`${(diffAddress || '')}AddressPostalCode`],
      country: object[`${(diffAddress || '')}AddressCountry`]
    };
  }
  return {
    addressee: object[(diffAddress || '') + keys[0]],
    addressLine1: object[(diffAddress || '') + keys[1]],
    addressLine2: object[(diffAddress || '') + keys[2]],
    city: object[(diffAddress || '') + keys[3]],
    state: object[(diffAddress || '') + keys[4]],
    postalCode: object[(diffAddress || '') + keys[5]],
    country: object[(diffAddress || '') + keys[6]]
  };
};

export const objectifyAddressForPost = (object, diffAddress, keys = null) => {
  if ((object === undefined || isNull(object)) && keys) {
    return {
      [(diffAddress || '') + keys[0]]: null,
      [(diffAddress || '') + keys[1]]: null,
      [(diffAddress || '') + keys[2]]: null,
      [(diffAddress || '') + keys[3]]: null,
      [(diffAddress || '') + keys[4]]: null,
      [(diffAddress || '') + keys[5]]: null,
      [(diffAddress || '') + keys[6]]: null
    };
  }
  if (!keys) {
    return {
      [`${diffAddress || ''}Addressee`]: object['addressee'],
      [`${diffAddress || ''}AddressLine1`]: object['addressLine1'],
      [`${diffAddress || ''}AddressLine2`]: object['addressLine2'],
      [`${diffAddress || ''}AddressCity`]: object['city'],
      [`${diffAddress || ''}AddressState`]: object['state'],
      [`${diffAddress || ''}AddressPostalCode`]: object['postalCode'],
      [`${diffAddress || ''}AddressCountry`]: object['country']
    };
  }
  return {
    [(diffAddress || '') + keys[0]]: object['addressee'],
    [(diffAddress || '') + keys[1]]: object['addressLine1'],
    [(diffAddress || '') + keys[2]]: object['addressLine2'],
    [(diffAddress || '') + keys[3]]: object['city'],
    [(diffAddress || '') + keys[4]]: object['state'],
    [(diffAddress || '') + keys[5]]: object['postalCode'],
    [(diffAddress || '') + keys[6]]: object['country']
  };
};

const objectifyArray = (array) => {
  const inlineObj = array.map(item => objectify(item));
  return inlineObj;
}

export const objectify = (object, addressValues = null, respType = 'Inline') => {

  const inlineObj = {};

  if (respType === 'Inline') {
    Object.keys(object).map((key, keyIndex) => {

      if (object[key]) {
        if (Array.isArray(object[key])) {
          return inlineObj[key] = objectifyArray(object[key]);
        }
        if (!isNull(object[key]) && typeof object[key] === 'object' && !object[key].hasOwnProperty('Value')) {
          return inlineObj[key] = objectify(object[key], addressValues, respType);
        }
        if (object[key].Description) {
          inlineObj[`${key}Description`] = object[key].Description;
        }
        if (object[key].Label) {
          inlineObj[`${key}Label`] = object[key].Label;
        }
        if (object[key].Display) {
          inlineObj[`${key}Display`] = object[key].Display;
        }
        if (object[key].Display && object[key].Label) {
          inlineObj[`${key}DisplayLabel`] = object[key].Label;
        }
        if (object[key].Value && object[key].Thumbnail) {
          inlineObj[`${key}Thumbnail`] = object[key].Thumbnail;
        }
        if (object[key].Value && object[key].MaximumFractionDigits) {
          inlineObj[`${key}MaximumFractionDigits`] = object[key].MaximumFractionDigits;
        }
        if (object[key].Value && object[key].MinimumFractionDigits) {
          inlineObj[`${key}MinimumFractionDigits`] = object[key].MinimumFractionDigits;
        }
        if (object[key].hasOwnProperty('ReadOnly')) {
          inlineObj[`${key}ReadOnly`] = object[key].ReadOnly;
        }

        return inlineObj[key] = typeof object[key] === 'object' ? object[key].Value : object[key];
      }

      return inlineObj[key] = object[key];
    });
    if (object.Address1) {
      inlineObj.address = {
        addressee: (object.Addressee && object.Addressee.Value) || (object.Name && object.Name.Value),
        addressLine1: object.Address1 && object.Address1.Value,
        addressLine2: object.Address2 && object.Address2.Value,
        city: object.City && object.City.Value,
        state: object.State && object.State.Value,
        postalCode: object.PostCode && object.PostCode.Value,
        country: object.Country && object.Country.Value
      };
    }

    if (addressValues) {
      addressValues.forEach((val) => {
        if (val.name && val.values) {
          inlineObj[val.name] = {
            addressee: addressValues && object[val.values.addressee].Value,
            addressLine1: addressValues && object[val.values.addressLine1].Value,
            addressLine2: addressValues && object[val.values.addressLine2].Value,
            city: addressValues && object[val.values.city].Value,
            state: addressValues && object[val.values.state].Value,
            postalCode: addressValues && object[val.values.postalCode].Value,
            country: addressValues && object[val.values.country].Value
          };
        }
      });
    }

    inlineObj.HyperlinkReference = object.HyperlinkReference;
  } else {
    return objectifyAddress(object);
  }

  return inlineObj;
};

export const objectifyForLabel = (object) => {

  const inlineObj = {};
  Object.keys(object).map((key, keyIndex) => {
    if (object[key]) {
      if (object[key].Label) {
        inlineObj[`${key}Label`] = object[key].Label;
      }
      if (object[key].Description) {
        inlineObj[`${key}Description`] = object[key].Description;
      }
      if (object[key].Display && object[key].Label) {
        inlineObj[`${key}DisplayLabel`] = object[key].Label;
      }

      return inlineObj[key] = object[key].Value;
    }

    return inlineObj[key] = object[key];
  });
  inlineObj.HyperlinkReference = object.HyperlinkReference;

  return inlineObj;
};
export const swapObjectValues = (object) => {

  Object.keys(object).forEach((key) => {
    if (object[key].Display) {
      object[key] = {
        ...object[key],
        Data: object[key].Value,
        Value: object[key].Display
      };
    }
  });

  return object;
};
export const createRequestObject = (newValues) => {
  const values = { ...newValues }; // make clone so that deleting keys doesn't effects already loaded form
  const requestObject = values.address ? {
    ...values,
    Addressee: values.address.addressee ? values.address.addressee : '',
    Address1: values.address.addressLine1 ? values.address.addressLine1 : '',
    Address2: values.address.addressLine2 ? values.address.addressLine2 : '',
    City: values.address.city ? values.address.city : '',
    State: values.address.state ? values.address.state : '',
    PostCode: values.address.postalCode ? values.address.postalCode : '',
    Country: values.address.country ? values.address.country : ''
  }
    : values;
  delete requestObject.address;
  delete requestObject.postalAddress;
  Object.keys(requestObject).map((key, keyIndex) => {
    if (requestObject[`${key}-$${key}-Display`]) {
      delete requestObject[`${key}-$${key}-Display`];
    }
    if (requestObject[`${key}-$${key}-Label`]) {
      return delete requestObject[`${key}-$${key}-Label`];
    }

    return requestObject[`${key}-$${key}-Label`];
  });

  return requestObject;
};

export const fetchGet = async (methodName, urlParam, respType = 'Inline') => {
  const responseType = `responseMetadataFormat=${respType}`;
  const endPoint = urlParam ? `${methodName}?${responseType}&${objectToUrlParam(urlParam)}` : `${methodName}?${responseType}`;
  const url = new URL(endPoint.replace(/^\/+/, ''), apiEndpoint).href;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  }).then(checkStatus)
    .then((res) => {
      if (res && res.headers.get('content-type') && res.headers.get('content-type').indexOf('application/json') !== -1) {
        return res.json();
      }

      return null;
    });
};

export const fetchFile = async (methodName, urlParam) => {
  const endPoint = urlParam ? `${methodName}?&${objectToUrlParam(urlParam)}` : `${methodName}`;
  const url = new URL(endPoint.replace(/^\/+/, ''), apiEndpoint).href;

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    }
  }).then(checkStatus)
    .then((res) => {
      if (res) {
        return res.blob();
      }

      return null;
    });
};

export const fetchPost = async (methodName, bodyObject, urlParam, respType = 'Inline') => {
  const resType = `responseMetadataFormat=${respType}`;
  const endPoint = urlParam ? `${methodName}?${resType}&${objectToUrlParam(urlParam)}` : `${methodName}?${resType}`;
  const url = new URL(endPoint.replace(/^\/+/, ''), apiEndpoint).href;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: JSON.stringify(bodyObject)
  }).then(checkStatus)
    .then((res) => {
      if (res && res.headers.get('content-type') && res.headers.get('content-type').indexOf('application/json') !== -1) {
        return res.json();
      }

      return null;
    });
};

export const fetchPut = async (methodName, bodyObject, respType = 'None', urlParam) => {
  const responseType = `responseMetadataFormat=${respType}`;
  const endPoint = urlParam ? `${methodName}?${responseType}&${objectToUrlParam(urlParam)}` : `${methodName}?${responseType}`;
  const url = new URL(endPoint.replace(/^\/+/, ''), apiEndpoint).href;

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: typeof (bodyObject) === 'string' ? bodyObject : JSON.stringify(bodyObject)
  }).then(checkStatus)
    .then((res) => {
      if (res && res.headers.get('content-type') && res.headers.get('content-type').indexOf('application/json') !== -1) {
        return res.json();
      }

      return null;
    });
};

export const fetchDelete = async (methodName, bodyObject, urlParam, respType = 'None') => {
  const responseType = `responseMetadataFormat=${respType}`;
  const endPoint = urlParam ? `${methodName}?${responseType}&${urlParam}` : `${methodName}?${responseType}`;
  const url = new URL(endPoint.replace(/^\/+/, ''), apiEndpoint).href;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    },
    body: typeof (bodyObject) === 'string' ? bodyObject : JSON.stringify(bodyObject)
  }).then(checkStatus)
    .then((res) => {
      if (res && res.headers.get('content-type') && res.headers.get('content-type').indexOf('application/json') !== -1) {
        return res.json();
      }

      return 'Success';
    });
};

export function injectParamValuesUrl(url, urlParams) {
  if (!Boolean(url) || !Boolean(urlParams)) {
    return url;
  }

  Object.keys(urlParams).forEach((key) => {
    if (url.includes(`:${key}`)) {
      url = url.replace(`:${key}`, urlParams[key])
    }
  })

  return url;
}

export const isScreenRequest = (Forms, type = null) => {
  return Forms?.some(form => form?.FormType === 'ScreenRequest' && (type ? form?.FormId === type : true));
}

export const isSuccess = (response) => (response && response.Status === true) ? (response.Forms && response.Forms && response.Forms[0] && response.Forms[0].DisplayType === 'StatusMessage') ?
  response.Forms[0].MessageType === 'Success'
  : true : false;
