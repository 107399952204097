import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';
import { ISupplierInvoiceSearchResponse, ISupplierInvoiceSearchByIdResponse } from './interfaces';
import { ISortOrder } from 'api/swaggerTypes';

export async function getSelectedDetails(supplierId: number): Promise<any> {
  return fetchGet(`SupplierEnquiry/Supplier/${supplierId}`)
    .then((result) => result && result.Supplier);
}

export async function search(query: { SearchText: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<ISupplierInvoiceSearchResponse> {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost('SupplierInvoiceEnquiry/SearchSupplierInvoices', { SearchText, Sort, BatchPage, BatchSize })
    .then((something) => ({
      records: something.SupplierInvoiceEnquirySearchs.map((val) => ({ inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }));
}

export async function searchById(DocumentNumber: number): Promise<ISupplierInvoiceSearchByIdResponse> {
  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/Details/Invoices/${DocumentNumber}`, {}, 'Inline')
    .then((something) => ({
      records: [{ inlineObject: objectify(something.SupplierInvoiceEnquiryDetail), schema: something.SupplierInvoiceEnquiryDetail }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }));
}
