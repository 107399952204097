export default [
  {
    id: '1',
    name: 'Resident',
    abbreviation: 'Resident',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Non Resident',
    abbreviation: 'Non Resident',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'Self Employed',
    abbreviation: 'Self Employed',
    fieldList1: 'field'
  },
];
