import { IFormViewForm } from 'components/FormView';

const Charges: IFormViewForm = {
    id: 'PickSlipChargesForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Product',
                        name: 'ProductId',
                        lookupName: 'SundryChargeProduct',
                        size: 'medium',
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        name: 'Description',
                        placeholder: '',
                        size: 'large',

                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    style: {
                        width: '50%'
                    },
                    props: {
                        label: 'Supplied',
                        name: 'SuppliedQuantity',
                        placeholder: '',
                        size: 'small',
                        type: 'number',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2
                    }
                },
                {
                    id: 4,
                    type: 'TEXT_FIELD',
                    style: {
                        width: '50%'
                    },
                    props: {
                        label: 'Price',
                        name: 'Price',
                        placeholder: '',
                        size: 'medium',
                        type: 'number',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2
                    }
                },
                {
                    id: 5,
                    type: 'TEXT_FIELD',
                    style: {
                        width: '50%'
                    },
                    props: {
                        label: 'Cost',
                        name: 'Cost',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        type: 'number',
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 4
                    }
                },
                {
                    id: 6,
                    type: 'EXTENDED_DISPLAY_FIELD',
                    visible: true,
                    style: {
                        width: '50%'
                    },
                    props: {
                        label: 'Discount',
                        name: 'DefaultDiscount',
                        size: 'small',
                        type: 'number',
                        decimalSeparator: true,
                        adornment: '%',
                    }
                },
                {
                    id: 7,
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Comment',
                        name: 'Comment',
                        placeholder: '',
                        size: 'large',
                        id: 'test',
                        style: {
                            height: '144px',
                        },
                        required: false
                    }
                }
            ]
        }
    ]
};

export default Charges;
