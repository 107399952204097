import * as React from 'react';
import { createStyles, Grid, withStyles, StyledComponentProps, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import Icon from '@markinson/uicomponents-v2/Icon';
import ReactFocusLock from 'react-focus-lock';
import { isNull } from 'utils/utils';
import { capitalize } from 'lodash';

export interface IConfirmationDialogProperties extends StyledComponentProps {
  open: boolean;
  title?: string;
  message?: string;
  okLabel?: string;
  showCancel?: boolean;
  showRefuse?: boolean;
  cancelLabel?: string;
  refuseLabel?: string;
  defaultBtnNo?: '1' | '2' | '3';
  onClose?(): void;
  onCancel?(): void;
  onRefuse?(): void;
  onOk?(): void;
}

const inlineStyles = createStyles({
  outerGrid: {
    display: 'flex',
    flexFlow: 'row'
  },
  left: {
    background: 'white',
    padding: '24px',
    paddingRight: '0'
  },
  right: {
    background: 'white'
  },
  paper: {
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 24px 0px'
  },
  content: {
    'padding': '0 24px 24px 24px',
    '& > p': {
      marginTop: '8px'
    }
  },
  actions: {
    marginRight: '20px',
    marginLeft: '20px'
  },
  action: {
    'border': '1px solid #777777',
    'background': '#F5F5F5',
    'color': 'black',
    'borderRadius': '4px',
    'textTransform': 'none',
    'padding': '8px 16px',
    '&:focus': {
      background: '#F47920',
      border: '1px solid #F47920',
      color: 'white'
    }
  },
  iconContainer: {
    height: 40,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 48,
    color: '#F47920'
  },
});

class ConfirmationDialogView extends React.Component<IConfirmationDialogProperties> {
  firstButtonRef: any;

  handleCancelClick = (): void => {
    const { onClose, onCancel } = this.props;
    if (onClose) {
      onClose();
    }

    if (onCancel) {
      onCancel();
    }
  }

  handleRefuseClick = (): void => {
    const { onClose, onRefuse } = this.props;
    if (onClose) {
      onClose();
    }

    if (onRefuse) {
      onRefuse();
    }
  }

  handleOkClick = (): void => {
    const { onClose, onOk } = this.props;
    if (onClose) {
      onClose();
    }

    if (onOk) {
      onOk();
    }
  }

  handleActionKeyDown = (e: any, firstButton: boolean, lastButton?: boolean) => {
    if (lastButton && e.key === 'Tab') {
      this.firstButtonRef?.focus();
      e.preventDefault();
    } else if (firstButton && e.key === 'Tab' && e.shiftKey) {
      this.firstButtonRef?.focus();
      e.preventDefault();
    }
  }

  render(): React.ReactNode {
    const { classes, open, message, title, okLabel, cancelLabel, refuseLabel, showCancel = true, showRefuse = false, defaultBtnNo } = this.props;

    if (!message) return <React.Fragment></React.Fragment>;

    return (<Dialog
      disableBackdropClick={!showCancel}
      disableEscapeKeyDown={!showCancel}
      maxWidth={'sm'}
      style={{ whiteSpace: 'pre-line' }}
      aria-labelledby={'confirmation-dialog-title'}
      open={open}
      classes={{
        paper: classes.paper
      }}
    >
      <Grid className={classes.outerGrid}>
        <Grid className={classes.left}>
          <div className={classes.iconContainer}>
            <Icon iconName={'Help'} className={classes.icon} />
          </div>
        </Grid>
        <Grid className={classes.right}>
          <DialogTitle
            className={'title'}
            classes={{
              root: classes.title,
            }}
            disableTypography
          >
            {title ? title : 'Discard Changes'}
          </DialogTitle>
          <DialogContent
            classes={{
              root: classes.content
            }}
          >
            {message && message.split('\\n').map((splitMessage, key) => (<p key={key}> {splitMessage}</p>))}
          </DialogContent>
        </Grid>
      </Grid>
      <ReactFocusLock>
        <DialogActions
          classes={{
            root: classes.actions
          }}
        >
          {showCancel &&
            <Button
              className={classes.action}
              onClick={this.handleCancelClick}
              autoFocus={isNull(defaultBtnNo) ? true : defaultBtnNo === '1'}
              buttonRef={(ref) => {
                this.firstButtonRef = ref;
              }}
              onKeyDown={(e) => {
                this.handleActionKeyDown(e, true);
                if (e.key === 'Enter') {
                  this.handleCancelClick();
                }
              }}
            >
              {capitalize(cancelLabel ? cancelLabel : 'Cancel')}
            </Button>}
          {showRefuse &&
            <Button
              onClick={this.handleRefuseClick}
              className={classes.action}
              autoFocus={isNull(defaultBtnNo) ? !showCancel : defaultBtnNo === '2'}
              buttonRef={(ref) => {
                if (!showCancel) {
                  this.firstButtonRef = ref;
                }
              }}
              onKeyDown={(e) => {
                this.handleActionKeyDown(e, !showCancel);
                if (e.key === 'Enter') {
                  this.handleRefuseClick();
                }
              }}
            >
              {capitalize(refuseLabel ? refuseLabel : 'No')}
            </Button>}
          <Button
            onClick={this.handleOkClick}
            className={classes.action}
            autoFocus={isNull(defaultBtnNo) ? !showCancel && !showRefuse : defaultBtnNo === '3'}
            onKeyDown={(e) => {
              this.handleActionKeyDown(e, !showCancel && !showRefuse, true);
              if (e.key === 'Enter') {
                this.handleOkClick();
              }
            }}
          >
            {capitalize(okLabel ? okLabel : 'Ok')}
          </Button>
        </DialogActions>
      </ReactFocusLock>
    </Dialog >);
  }
}

export default withStyles(inlineStyles, { index: 1 })(ConfirmationDialogView);
