import { IFormViewForm } from 'components/FormView';

const Balances: IFormViewForm = {
  id: 'SupplierFinancialBalances',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Financial period',
            name: 'FinancialPeriod',
            size: 'medium',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Opening balance',
            name: 'OpeningBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Outstanding balance',
            name: 'OutstandingBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        label: 'Aged balances',
      },
      children: [
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Current',
            name: 'Current',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '30 days',
            name: 'Days30',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '60 days',
            name: 'Days60',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '90 days',
            name: 'Days90',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '120 days and over',
            name: 'Days120',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 6,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Unallocated balance',
            name: 'UnlocatedBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 7,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Future balance',
            name: 'FutureBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Trading terms',
            name: 'TradingTerms',
            size: 'small',
            alignment: 'right',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit limit',
            name: 'CreditLimitDisplay',
            size: 'medium',
            alignment: 'right',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Discount terms',
            name: 'DiscountTerms',
            size: 'small',
            type: 'string',
            alignment: 'right',
            helpText: 'days'
          }
        },
        {
          id: 3,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Invoice discount',
            name: 'InvoiceDiscount',
            size: 'small',
            type: 'number',
            decimalSeparator: true,
            adornment: '%',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last purchase',
            name: 'LastPurchase',
            size: 'medium',
            type: 'string',
            alignment: 'right'
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last payment',
            name: 'LastPayment',
            size: 'medium',
            type: 'string',
            alignment: 'right'
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last cheque',
            name: 'LastCheque',
            size: 'small',
            alignment: 'right',
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last EFT number',
            name: 'LastEftNumber',
            size: 'small',
            alignment: 'right',
          }
        }
      ]
    }
  ]
};

export default Balances;
