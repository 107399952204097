import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary1Color: grey[900],
    secondary: {
      light: orange[800],
      main: '#F47920',
      dark: orange[800],
    },
    info: '#0E86D4',
    warning: '#F47920',
  },
  bottomNavigation: {
    selectedFontSize: 14,
    unselectedFontSize: 14
  },
  menuLevel1Text: {
    '& :first-child': {
      fontSize: '15px',
      fontWeight: 'bold'
    }
  },
  menuLevel2Text: {
    '& :first-child': {
      fontSize: '15px',
      fontWeight: 400,
      paddingLeft: '15px',
    }
  },
  menuHeading: {
    fontSize: '16px',
    fontWeight: 100,
    lineHeight: '52px',
    backgroundColor: '#bbbbbb',
  },
  overrides: {
    MuiModal: {
      root: {
        zIndex: 999,
      }
    }
  }
})