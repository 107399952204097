import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { IDairyFields } from '../interfaces';

class Diary extends React.PureComponent<IDairyFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-invoice-enquiry/sales-diary':
          onInitialLoad('SalesDiary');
          break;
        case '/sales-invoice-enquiry/sales-diary/diary-details':
          onInitialLoad('DiaryDetails');
          break;
        default:
          onInitialLoad('InvoiceDetails');
      }
    }
  }
  getDiary = () => {
    this.props.getDiary();
  }

  getInitialValues = () => {
    const {
      selectedTab, selectedInvoice, invoiceDetails, location
    } = this.props;
    const params = (new URLSearchParams(location.search));
    const invoiceNumber = (selectedInvoice && selectedInvoice.Invoice) || params.get('InvoiceNumber');
    const salesEntity = (selectedInvoice && selectedInvoice.SalesEntity) || params.get('SalesEntity');
    switch (selectedTab) {
      case 'DiaryDetails':
      case 'SalesDiary':
        return {
          params: invoiceNumber && salesEntity && {
            InvoiceNumber: invoiceNumber,
            SalesEntity: salesEntity
          }
        };
      case 'InvoiceDetails':
        return selectedInvoice && invoiceDetails && invoiceDetails.values;
      default:
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={(selectedTab === 'SalesDiary' && 'Diary') || selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Diary);
