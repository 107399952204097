import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lotsSelectors, actions as lotsActions } from 'ducks/pickSlipEnquiry/lots';
import { fetchLots } from 'api/pickSlipEnquiry/slipLines';
import { actions as filterActions } from 'ducks/common/filters';
import { selectors as pickSlipSelectors } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';
import { IApplicationState } from 'ducks/reducers';
import { selectors as slipLineSelectors } from 'ducks/pickSlipEnquiry/slipLines';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'PSELots',
  columnDefs: lotsSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lotsSelectors.gridOptions(state),
  reqParams: ((pickSlipSelectors.selected(state) || params.get('DespatchId')) && (slipLineSelectors.selected(state) || params.get('LineNumber')))
    &&
    {
      DespatchId: (pickSlipSelectors.selected(state) && pickSlipSelectors.selected(state).DespatchId) || params.get('DespatchId'),
      LineNumber: (slipLineSelectors.selected(state) && slipLineSelectors.selected(state).LineNumber) || params.get('LineNumber')
    },
  apiMethod: fetchLots
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lotsActions.setSelected,
  applyFilters: filterActions.applyFilters
};
