import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
    fetchByBom: ({ Invoice, SalesEntity, LineNumber }) => ({ Invoice, SalesEntity, LineNumber }),
  }
}, 'Lot');


const lineDetailColumns = [
  { headerName: 'Lot', field: 'LotNumber', minWidth: 150, },
  { headerName: 'Description', field: 'Description', minWidth: 250, },
  { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
  { headerName: 'Cost each', field: 'CostEachDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
  { headerName: 'Expiry', field: 'ExpiryDate', minWidth: 120, suppressSorting: true, },
];

const componentDetailColumns = [
  { headerName: 'Lot', field: 'LotNumber', minWidth: 150, },
  { headerName: 'Description', field: 'Description', minWidth: 250, },
  { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
  { headerName: 'Cost each', field: 'CostEachDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
];

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [],
  lines: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchByBom:
      return asyncOnRequest({ ...state, data: { ...state.data, lineColumns: componentDetailColumns } }, action)
    case types.saga.fetchByBom.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          lines: linesData
        }
      })
    case types.saga.fetchByBom.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.Lot,
  {
    lines: data => data.lines,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.Lot.loading,
  gridOptions: state => state.salesInvoiceEnquiry.Lot.data.gridOptions,
  lineColumns: state => state.salesInvoiceEnquiry.Lot.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)