import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { fetchFile } from 'api/utils';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import ImageModal from './ImageModal';

const styles = createStyles({
  paper: {
    minHeight: '180px',
    maxWidth: '160px',
    margin: '15px',
    cursor: 'pointer'
  },
  image: {
    width: '64px',
    height: '64px',
    margin: '20px 0px 5px 0px',
    objectFit: 'cover'
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
    margin: '10px',
    fontSize: 'small',
    color: 'black'
  },
  description: {
    margin: '10px',
    wordBreak: 'break-all',
    fontSize: 'small',
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }
});

export interface IImageCellProperties extends StyledComponentProps {
  data: any;
}

export interface IImageCellState {
  imageData: string;
  open: boolean;
  loading: boolean;
}

class ImageCell extends React.Component<IImageCellProperties, IImageCellState> {
  constructor(props: IImageCellProperties) {
    super(props);
    this.state = {
      imageData: null,
      open: false,
      loading: false
    };
  }

  handleClick = (): void => {
    const { data } = this.props;
    this.setState({ open: !this.state.open, loading: true });
    fetchFile(`/Image/${data.ImageId.Value}`).then((result) => {
      this.setState({ imageData: window.URL.createObjectURL(result), loading: false });
    });
  }

  handleClose = (): void => {
    this.setState({ open: false });
  }
  render(): React.ReactNode {
    const { classes, data } = this.props;
    const { open, imageData, loading } = this.state;
    const elevation = 2;

    return (
      <>
        <Paper elevation={elevation} className={classes.paper} onClick={this.handleClick}>
          <div className={classes.imageDiv}>
            <img className={classes.image} src={data && data.Image && data.Image.Thumbnail} />
          </div>
          <div className={classes.fileName}>{`${data.FileName.Value} (${data.FileSize.Value})`}</div>
          <div className={classes.description}>{data.Description.Value}</div>
        </Paper>
        <ImageModal
          open={open}
          image={imageData || (data && data.Image && data.Image.Thumbnail)}
          loading={loading}
          onClose={this.handleClose}
          data={data}
        />
      </>
    );
  }
}

export default withStyles(styles, { index: 1 })(ImageCell);
