import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { fetch } from 'api/inventoryEnquiry/whereUsed/bom';
import { selectors as BOMSelectors, actions as BOMActions } from 'ducks/inventoryEnquiry/whereUsed/BOM';
import { IApplicationState } from 'ducks/reducers';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'WhereUsedBOM',
  columnDefs: BOMSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: BOMSelectors.gridOptions(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: BOMActions.setSelected
};
