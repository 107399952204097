import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as userFieldsSelectors, actions as userFieldActions } from 'ducks/supplierEnquiry/userFields';
import {selectors as supplierSelectors} from 'ducks/supplierEnquiry/supplier';
import { getSupplierUserFields } from 'api/supplierEnquiry/userFields';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'SupplierEnquiryUserFields',
  columnDefs: userFieldsSelectors.columns(state),
  rowData: userFieldsSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: userFieldsSelectors.gridOptions(state),
  detailCellRenderer: userFieldsSelectors.detailCellRenderer(state),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
  &&
  {
    Supplier: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
  },
  apiMethod: getSupplierUserFields
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelectedm,
  setSelectedOrderLine: userFieldActions.setSelected
}
