import { createRequestObject, Inline, IObjectified } from './utils';
import { ICustomerContactSearchResponse, ISortOrder, ISearchRequest, ICustomerContactDetails, IContactDetailsResponse } from './swaggerTypes';
import { Api, asObjectifiedArray, asObjectified, toObjectified } from './baseApi';

export type IObjectifiedCustomerContactDetailsResponse = Omit<IContactDetailsResponse, 'CustomerContactDetails'> & {
  CustomerContactDetails: IObjectified<ICustomerContactDetails>;
};

export type InlineContactDetailsResponse = Omit<IContactDetailsResponse, 'CustomerContactDetails'> & {
  CustomerContactDetails: Inline<ICustomerContactDetails>;
};

export async function search(request: { SearchText: string; CustomerId: number; Sort: ISortOrder }): Promise<IObjectified<ICustomerContactDetails>[]> {
  const { SearchText, CustomerId, Sort } = request;

  return Api.postInlineCollectionResponse<ISearchRequest, ICustomerContactSearchResponse, 'CustomerContacts'>(
    `CustomerMaintenance/Customer/${CustomerId}/SearchContacts`, { SearchText, Sort }
  ).then((response) => asObjectifiedArray(response, 'CustomerContacts'));
}

export async function searchById(query: { CustomerId: number; ContactNumber: number }): Promise<IObjectified<ICustomerContactDetails>[]> {
  const { CustomerId, ContactNumber } = query;

  return Api.getInlineResponse<IContactDetailsResponse, 'CustomerContact'>(
    `/CustomerEnquiry/Customer/${CustomerId}/Contact/${ContactNumber}`)
    .then((response) => {
      const customerContact = asObjectified(response, 'CustomerContact');

      return customerContact ? [customerContact] : [];
    });
}

export async function create(request: { CustomerId: number; values: ICustomerContactDetails }): Promise<IObjectifiedCustomerContactDetailsResponse> {
  const { CustomerId, values, ...rest } = request;

  return Api.postInlineResponse<ICustomerContactDetails, InlineContactDetailsResponse, 'CustomerContactDetails'>(
    `CustomerMaintenance/Customer/${CustomerId}/CreateContact`,
    createRequestObject({ ...values, ...rest }))
    .then((response) => {
      const { CustomerContactDetails } = response;

      return {
        ...response,
        CustomerContactDetails: CustomerContactDetails ? toObjectified(CustomerContactDetails as Inline<any>) : null
      };
    });
}

export async function remove(request: { CustomerId: number; ContactNum: number }): Promise<number> {
  const { CustomerId, ContactNum } = request;

  return Api.delete<any>(`CustomerMaintenance/Customer/${CustomerId}/Contact/${ContactNum}`).then(() => ContactNum);
}

export async function update(request: { CustomerId: number; ContactNum: number; values: ICustomerContactDetails }): Promise<IObjectifiedCustomerContactDetailsResponse> {
  const { CustomerId, ContactNum, values, ...rest } = request;

  return Api.putInlineResponse<ICustomerContactDetails, InlineContactDetailsResponse, 'CustomerContactDetails'>(
    `CustomerMaintenance/Customer/${CustomerId}/Contact/${ContactNum}?responseMetadataFormat=Inline`,
    createRequestObject({ ...values, ...rest }))
    .then((response) => {
      const { CustomerContactDetails } = response;

      return {
        ...response,
        CustomerContactDetails: CustomerContactDetails ? toObjectified(CustomerContactDetails as Inline<any>) : null
      };
    });
}
