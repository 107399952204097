import { takeLatest, Effect } from 'redux-saga/effects';
import { types as patternTypes, actions as patternActions } from 'ducks/servicePatternMaintenance/servicePattern';
import { connectSagaToApi } from '../utils';
import * as api from 'api/servicePatternMaintenance/servicePattern';

export default function* rootServicePatternSaga(): IterableIterator<Effect> {
  yield takeLatest(patternTypes.search, connectSagaToApi(patternActions, 'search', api.search));
  yield takeLatest(patternTypes.searchById, connectSagaToApi(patternActions, 'searchById', api.searchById));
  yield takeLatest(patternTypes.fetchPrevPage, connectSagaToApi(patternActions, 'fetchPrevPage', api.search));
  yield takeLatest(patternTypes.fetchNextPage, connectSagaToApi(patternActions, 'fetchNextPage', api.search));
}
