import React from 'react';
import IChecklistMaintenanceProps from './ChecklistMaintenance.properties';
import ChecklistSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/ChecklistMaintenance';
import BreadcrumbBar from '@markinson/uicomponents-v2/BreadcrumbBar';
import * as styles from './ChecklistMaintenance.scss';
import modules from './ChecklistMaintenance.modules';
import ChecklistMaintenanceDetail from './ChecklistMaintenanceDetail';
import { NavigationTabs } from '@markinson/uicomponents-v2';
import { TABS_DATA } from './constants';
import { withRouter, Route } from 'react-router-dom';
import { getKeyByValue } from 'utils/utils';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './ChecklistMaintenance.options';
import ActionBar from '@markinson/uicomponents-v2/ActionBar';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Operation } from 'utils/operations';
import { IChecklistMaintenanceDetailsHandle } from './ChecklistMaintenanceDetail/ChecklistMaintenanceDetail.properties';

const ChecklistMaintenance = (props: IChecklistMaintenanceProps): JSX.Element => {
    const {
        match,
        history,
        isCustomerLookUpOpen
    } = props;

    const { actionBarProps } = React.useContext(ActionBarContext);

    const url = match ? match.url : '';
    const path = match ? match.path : '';

    const tabLocations = {
        ChecklistMaintenance: url,
    };

    const checklistMaintenanceDetailsRef = React.useRef<IChecklistMaintenanceDetailsHandle>();

    const [optionsMenuOpen, setOptionsMenuOpen] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<string>('ChecklistMaintenanceDetail');
    const [operationMode, setOperationMode] = React.useState<Operation>(Operation.BROWSE);
    React.useEffect(
        () => {
            const pathname = location ? location.pathname : '';
            const tabByLocation = getKeyByValue(tabLocations, pathname);
            setSelectedTab(tabByLocation);
        },
        [location]);

    React.useEffect(
        () => {
            return () => {
                props.resetSearchLookupDrawer();
                props.destoryForm();
            };
        },
        []
    );

    function handleModuleChange(newSelectedTab: string): void {
        history.push(tabLocations[newSelectedTab]);
    }

    function handleOptionClick(newSelectedTab: string): void {
        handleModuleChange(newSelectedTab);
    }

    function navigateBack(): void {
        if (!selectedTab) {
            history.push('/dashboard');
        }
        if (selectedTab) {
            const targetModule = modules.find((item) => item.id === selectedTab);
            if (!targetModule || !targetModule.parent || targetModule.parent === '') {
                history.push('/dashboard');
            } else {
                handleModuleChange(targetModule.parent);
            }
        }
    }
    function handleActionClick(action: string): void {
        switch (action) {
            case 'Back':
                navigateBack();
                break;
            case 'AddQuestion':
                checklistMaintenanceDetailsRef.current.addQuestion();
                break;
            case 'EditChecklist':
                checklistMaintenanceDetailsRef.current.onEdit();
                break;
            case 'DeleteChecklist':
                checklistMaintenanceDetailsRef.current.onDelete();
                break;
            case 'NewChecklist':
                checklistMaintenanceDetailsRef.current.onNew();
                break;
            case 'CancelChecklist':
                checklistMaintenanceDetailsRef.current.onCancel();
                break;
            case 'OkChecklist':
                checklistMaintenanceDetailsRef.current.onOk();
                break;

            default:
        }
    }

    return (
        <div className={styles.checklistOuter}>
            <div className={styles.checklistContainer}>
                <ChecklistSearchLookUpDrawer enableToggle={true} />
                <div className={styles.checklistSection} >
                    <BreadcrumbBar
                        onOptionsMenuClick={() => { setOptionsMenuOpen(!optionsMenuOpen); }}
                        mainModule={'Checklist Maintenance'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={(moduleId) => { handleModuleChange(moduleId); }}
                        onMainModuleClick={() => { handleModuleChange('ChecklistMaintenance'); }}
                    />
                    {TABS_DATA.some(({ id }) => id === selectedTab) ? <NavigationTabs
                        lookupOpen={isCustomerLookUpOpen}
                        tabsData={TABS_DATA}
                        activeTabId={selectedTab}
                        onTabChange={(tab) => { handleModuleChange(tab); }}
                    /> : null}
                    <Route
                        key={'Lines'}
                        path={path}
                        exact
                        render={(routeProps) => {
                            return <ChecklistMaintenanceDetail
                                {...routeProps}
                                ref={checklistMaintenanceDetailsRef}
                                operationMode={operationMode}
                                setOperationMode={setOperationMode}
                            />;
                        }} />
                </div>
            </div>
            <OptionsMenu
                open={optionsMenuOpen}
                options={options}
                onOptionClick={handleOptionClick}
                defaultValue={selectedTab}
                onClickAway={() => { setOptionsMenuOpen(false); }}
            />
            <ActionBar {...actionBarProps} onActionClick={handleActionClick} />
        </div>
    );
};

export default withRouter(ChecklistMaintenance);
