import DataSource from 'devextreme/data/data_source';
import { isNull } from 'utils/utils';
import { DEFAULT_EXPORT_BATCH_SIZE, DEFAULT_PAGE_SIZE, MAX_VALUE_INT_DOTNET } from './DataGrid.constants';

export default function CreateStore(apiMethod: (...args: any) => any, appliedFilters: any, keyFields: any, reqParams: any, fileMetaRef: any): any {
    if (isNull(reqParams) && isNull(appliedFilters)) {
        return new DataSource({});
    }

    const getExcelExportBatchSize = () => {
        if (fileMetaRef.current) {
            if (fileMetaRef.current.BatchSize > MAX_VALUE_INT_DOTNET) {
                return MAX_VALUE_INT_DOTNET;
            }

            return fileMetaRef.current.BatchSize;
        }

        return DEFAULT_EXPORT_BATCH_SIZE;
    };

    return new DataSource({
        key: !isNull(keyFields) ? keyFields[0].valueField : 'Id',
        load: function (loadOptions: any): any {

            const pageIndex = (!loadOptions.skip && !loadOptions.take) ? 1 : (Number(loadOptions.skip) + DEFAULT_PAGE_SIZE) / DEFAULT_PAGE_SIZE;
            let sortField = '';
            let direction = '';

            const BatchSize = (!loadOptions.skip && !loadOptions.take) ? getExcelExportBatchSize() : DEFAULT_PAGE_SIZE;

            if (loadOptions.sort) {
                sortField = loadOptions.sort[0].selector;
                direction = loadOptions.sort[0].desc ? 'desc' : 'asc';
            }

            const parseData = (records) => ({
                data: isNull(keyFields) ? records.map((d, idx) => ({ ...d, Id: `${pageIndex}_${idx}` })) : records,
            });

            const CallApiMethod = (page: number) => apiMethod({
                ...reqParams,
                Page: page,
                Sort: sortField, SortDirection: direction,
                filters: appliedFilters,
                BatchSize
            });

            if (loadOptions.take > DEFAULT_PAGE_SIZE) { //if take is greater then default batch page. Do multiple API calls to fetch all data requested through 'take' param

                return async function (): Promise<any> {
                    const APICallsCount = Math.floor(loadOptions.take / DEFAULT_PAGE_SIZE);
                    let allRecords = [];

                    try {
                        for (let i = 0; i < APICallsCount; i++) {
                            const response = await CallApiMethod(pageIndex + i);

                            allRecords = allRecords.concat(response.records);

                            if (response.records.length < DEFAULT_PAGE_SIZE) {
                                break;
                            }
                        }

                    } catch (err) {
                        console.warn(err);

                        return [];
                    }

                    return parseData(allRecords);
                }();
            }

            return CallApiMethod(pageIndex)
                .then((data) => {

                    return parseData(data.records);
                })
                .catch((err) => {
                    console.warn(err);

                    return [];
                });
        }
    });
}
