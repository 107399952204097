
import { fetchPost, objectify } from '../utils';
import moment from 'moment';

export const fetchPriceDetails = (query) => {
  const { filters, api } = query;
  const respType = 'Inline'
  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return fetchPost(api,
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    },
    {

    },
    respType)
    .then((result) => result && result.PriceCalculator && objectify(result.PriceCalculator))
}

export const fetchDefaultPriceDetails = (query) => {
  const { filters } = query;
  const respType = 'Inline'
  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return fetchPost('/PriceCheck/InitialisePriceCalculator',
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    },
    {

    },
    respType)
    .then((result) => result && result.PriceCalculator && objectify(result.PriceCalculator))
}