import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import ServicePatternMaintenance from './ServicePatternMaintenance';
import ServicePatternMaintenanceProps from './ServicePatternMaintenance.properties';
import { actions as formActions } from 'ducks/form';
import { selectors as patternSelectors, actions as servicePatternActions } from 'ducks/servicePatternMaintenance/servicePattern';
import { IApplicationState } from 'ducks/reducers';
import * as servicePatternApi from 'api/servicePatternMaintenance/servicePattern';
import { selectors as uiSelectors } from 'ducks/ui';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeSelectedForm: formActions.setSelected,
    getFormSchema: formActions.search,
    resetSearchLookupDrawer: servicePatternActions.reset,
    destoryForm: () => destroy('ServicePatternDetail'),
  },
  dispatch);

const mapStateToProps = (state: IApplicationState) => ({
  ServicePatternId: patternSelectors.ServicePatternId(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state)
});

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
  ServicePatternMaintenanceProps>) => {
  return (<ServicePatternMaintenance
    fetchServicePattern={servicePatternApi.fetchServicePattern}
    {...props} />);
});
