/* eslint-disable */
import data from './data/db.json';
import CustomerMaintenanceAPI from './api_services/CustomerMaintenanceAPI';
import JobTemplateMaintenanceAPI from './api_services/JobTemplateMaintenanceAPI';
import CustomerEnquiryAPI from './api_services/CustomerEnquiryAPI';
import ActivityMaintenanceAPI from './api_services/ActivityMaintenanceAPI';
import SupplierEnquiryAPI from './api_services/SupplierEnquiryAPI';
import SupplierInvoiceEnquiryAPI from './api_services/SupplierInvoiceEnquiryAPI';
import RFCEnquiryAPI from './api_services/RFCEnquiryAPI';
import CustomerQuoteEnquiryAPI from './api_services/CustomerQuoteEnquiryAPI';
import SalesOrderEnquiryAPI from './api_services/SalesOrderEnquiryAPI';
import PurchaseOrderEnquiryAPI from './api_services/PurchaseOrderEnquiryAPI';
import SalesInvoiceEnquiryAPI from './api_services/SalesInvoiceEnquiryAPI';
import CommonComponentsAPI from './api_services/CommonComponentsAPI';
import Search from './api_services/searchPanelOptions';
import InventoryEnquiryAPI from './api_services/InventoryEnquiryAPI';
import PriceCheckAPI from './api_services/PriceCheckAPI';
import PMEApi from './api_services/PMEApi';
import ServiceOrdersAPI from './api_services/ServiceOrdersAPI';
import PickSlipEnquiryAPI from './api_services/PickSlipEnquiryAPI';
import PickSlipsAPI from './api_services/PickSlipsAPI';
import SupplierProductAPI from './api_services/SupplierProductEnquiryAPI';
import CreditNotesAPI from './api_services/CreditNotesAPI';
import SecurityPolicyAPI from './api_services/SecurityPolicyAPI';
import CashReceipting from './api_services/CashReceipting';
import RosterMaintenanceAPI from './api_services/RosterMaintenanceAPI';

class ApiService {
  getFormSchema({ context, formId }) {
    switch (context) {
      case 'customer-maintenance': {
        return CustomerMaintenanceAPI(formId);
      }
      case 'job-template-maintenance': {
        return JobTemplateMaintenanceAPI(formId);
      }
      case 'sales-order-enquiry': {
        return SalesOrderEnquiryAPI(formId);
      }
      case 'purchase-order-enquiry': {
        return PurchaseOrderEnquiryAPI(formId);
      }
      case 'customer-enquiry': {
        return CustomerEnquiryAPI(formId);
      }
      case 'activity-maintenance': {
        return ActivityMaintenanceAPI(formId);
      }
      case 'customer-quote-enquiry': {
        return CustomerQuoteEnquiryAPI(formId);
      }
      case 'sales-invoice-enquiry':
        return SalesInvoiceEnquiryAPI(formId);
      case 'common':
        return CommonComponentsAPI(formId);
      case 'inventory-enquiry':
        return InventoryEnquiryAPI(formId);
      case 'price-check':
        return PriceCheckAPI(formId);
      case 'supplier-enquiry':
        return SupplierEnquiryAPI(formId);
      case 'supplier-invoice-enquiry':
        return SupplierInvoiceEnquiryAPI(formId);
      case 'product-movement-enquiry':
        return PMEApi(formId);
      case 'rfc-enquiry':
        return RFCEnquiryAPI(formId);
      case 'pick-slip-enquiry':
        return PickSlipEnquiryAPI(formId);
      case 'service-orders':
        return ServiceOrdersAPI(formId);
      case 'pick-slips':
        return PickSlipsAPI(formId);
      case 'supplier-product-enquiry':
        return SupplierProductAPI(formId);
      case 'security-policies':
        return SecurityPolicyAPI(formId)
      case 'credit-notes':
        return CreditNotesAPI(formId)
      case 'security-policies':
        return SecurityPolicyAPI(formId)
      case 'cash-receipting':
        return CashReceipting(formId)
      case 'roster-maintenance':
        return RosterMaintenanceAPI(formId)
      default: return null;
    }
  }

  getFormData(selectedRecordId) {
    return data.customers.find(item => item.id === selectedRecordId);
  }

  getSearchResult(queryString) {
    return data.customers
  }

  getSearchOptions() {
    return new Search().getOptions();
  }

  getCustomers() {
    return data.customers;
  }

  getContacts() {
    return data.contacts;
  }

  getProducts() {
    return data.products;
  }

  getAttributes() {
    return data.attributes;
  }

  getEnquiries() {
    return data.enquiries;
  }

  getOrders() {
    return data.orders;
  }

  getQuotes() {
    return data.quotes;
  }

  getAddresses() {
    return data.addresses;
  }

  getLookupOptions = (lookupId) => {
    const result = data.lookups.filter((item) => lookupId === item.id)[0];
    return result;
  }

  getBoms = () => {
    return data.BOM;
  }

  getInvoices = () => {
    return data.invoices;
  }

  getInvoiceLines = () => {
    return data.InvoiceLines;
  }

  getPaymentLines = () => {
    return data.PaymentLines;
  }
  getsOrder = () => {
    return data.sOrder;
  }

  pickSlipsByProduct = () => {
    return data.PickSlipsByProduct;
  }

  getSalesOrderLines = () => {
    return data.SalesOrderLines;
  }

  getSalesOrderDeliveryLines = () => {
    return data.SalesOrderDeliveryLines;
  }

  getCommittedPutInAway = () => {
    return data.CommittedPutInAway;
  }

  getLdCommittedPutInAway = () => {
    return data.LdCommittedPutInAway;
  }

  getCommittedProductionOrders = () => {
    return data.CommittedProductionOrders;
  }

  getLdCommittedProductionOrders = () => {
    return data.LdCommittedProductionOrders;
  }

  getInvoiceLineBOM = () => {
    return data.InvoiceLineBOM;
  }

  getInvoiceLineSerials = () => {
    return data.InvoiceLineSerials;
  }

  getInvoiceLineLots = () => {
    return data.InvoiceLineLots;
  }
  getCQELotLines = () => {
    return data.CQEReservationLotLines;
  }

  getCQESerialLines = () => {
    return data.CQESerialLines;
  }
  getBOMLines = () => {
    return data.CQEBOMLines;
  }
  getInvoiceSalesAudits = () => {
    return data.InvoiceSalesAudits;
  }

  getSalesAuditDetails = ({ auditId }) => {
    return data.SalesAuditDetails.filter(item => item.AuditId === auditId);
  }

  getSOEReservationLots = () => {
    return data.SOEReservationLots;
  }

  getSOEReservationSerials = () => {
    return data.SOEReservationSerials;
  }

  getOrderLineInvoices = () => {
    return data.OrderLineInvoices;
  }

  getBOMLines = () => {
    return data.SOEBOMLines;
  }

  getCustomerInvoices = () => {
    return data.CustomerInvoices;
  }

  getEvents = (module) => {
    return data[`${module}Events`];
  }

  getCustomerDiary = () => {
    return data.CustomerDiary;
  }

  getTransactions = () => {
    return data.Transactions;
  }

  getProductAssociationAlternates = () => {
    return data.ProductAssociationAlternates;
  }

  getProductAssociationSuppressions = () => {
    return data.ProductAssociationSuppressions;
  }

  getProductAssociationBOM = () => {
    return data.ProductAssociationBOM;
  }

  getServiceJobs = (SalesEntity, ShowUnscheduled) => {
    let serviceJobs = data.sJobs;
    if (SalesEntity) {
      serviceJobs = serviceJobs.filter(job => job.salesEntity && job.salesEntity.Value === SalesEntity)
    }
    if (ShowUnscheduled) {
      serviceJobs = serviceJobs.filter(job => job.status && job.status.Value === 'Not scheduled');
    }
    return { serviceJobs };
  }

  getServiceJobsById = (jobId) => {
    return { serviceJobs: data.sJobs.filter(job => job && job.jobId && job.jobId.Value === jobId) };
  }

  getServiceActivities = (JobId) => {
    return { activities: data.activities.filter(activity => activity.jobId && activity.jobId.Value === JobId) };
  }

  getServiceActivitiesById = (JobId, activityId) => {
    return { activities: data.activities.filter(activity => activity.jobId && activity.jobId.Value === JobId && activity.activityId && activity.activityId.Value === activityId) };
  }

  getServiceActivityTechnicians = () => {
    return { technicians: data.technicians };
  }

  getActivityMaintenanceData = () => {
    return { records: data.activityLookup };
  }

  getActivityMaintenanceCapabilityData = () => {
    return { records: data.capabilityLookup };
  }

  getRosterTechniciansData = () => {
    return { records: data.rosterTechnicians };
  }

  getRosterEntriesData = () => {
    return { records: data.rosterEntries };
  }
}


export default ApiService;