import { IActivitySearchResponse, IActivitySearchFacade, IActivityDetailFacade } from './../swaggerTypes';
import { IObjectified, } from '../utils';
import { IActivityDetailsResponse, ISortOrder, ISearchRequest } from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
  records: IObjectified<IActivitySearchFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return Api.postInlineCollectionResponse<ISearchRequest, IActivitySearchResponse, 'Activities'>(
    'ActivityMaintenance/SearchActivities', { SearchText, Sort, BatchPage, BatchSize })
    .then((response) => {
      const records = asObjectifiedArray(response, 'Activities');
      const batchInformation = (response as IActivitySearchResponse).BatchInformation;

      return {
        records,
        currPage: batchInformation ? batchInformation.BatchPage : 1,
        nextPage: batchInformation && !batchInformation.EndOfData,
        prevPage: batchInformation && batchInformation.BatchPage > 1
      };
    });
}

export async function searchById(query: { ActivityCode: string }): Promise<{
  records: IObjectified<IActivitySearchFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
  const { ActivityCode } = query;

  return Api.getInlineResponse<IActivityDetailsResponse, 'ActivityDetails'>(
    `ActivityMaintenance/Activity/${ActivityCode}`)
    .then((response) => {
      const records = isNull(response.Forms) ? [asObjectified(response, 'ActivityDetails')] : [];

      return {
        Forms: response.Forms,
        records: records,
        currPage: 1,
        nextPage: false,
        prevPage: false
      };
    });
}

export async function create(query: IActivityDetailFacade):
  Promise<{ ActivityDetails: IObjectified<IActivitySearchFacade> }> {

  return Api.postInlineResponse<IActivityDetailFacade, IActivityDetailsResponse, 'ActivityDetails'>(
    'ActivityMaintenance/Activity/Create', query)
    .then((response) => {

      return {
        ...response,
        ActivityDetails: asObjectified(response, 'ActivityDetails')
      };
    });
}

export async function update(query: IActivityDetailFacade): Promise<{ ActivityDetails: IObjectified<IActivitySearchFacade> }> {

  const { ActivityCode } = query;

  return Api.putInlineResponse<IActivityDetailFacade, IActivityDetailsResponse, 'ActivityDetails'>(
    `ActivityMaintenance/Activity/${ActivityCode}`, query)
    .then((response) => {

      return {
        ...response,
        ActivityDetails: asObjectified(response, 'ActivityDetails')
      };
    });
}

export async function deleteActivity(ActivityCode: string): Promise<IActivityDetailsResponse> {

  return Api.delete<IActivityDetailsResponse>(
    `ActivityMaintenance/Activity/${ActivityCode}`)
    .then((response) => response);
}
