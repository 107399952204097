import { IObjectified } from '../utils';
import { IActivityChecklistsResponse, IActivityChecklistFacade, IFormInfo } from '../swaggerTypes';
import { Api, asObjectified } from '../baseApi';
import { ILookupResponse } from 'api/customType';

export interface IObjectifiedActivityChecklistsResponse {
    ActivityChecklists: IObjectified<IActivityChecklistFacade[]>;
    Status?: boolean;
    Forms?: IFormInfo[];
}

export async function getChecklists(ActivityCode: string): Promise<IObjectifiedActivityChecklistsResponse> {

    return Api.getInlineResponse<IActivityChecklistsResponse, 'ActivityChecklists'>(
        `ActivityMaintenance/Activity/${ActivityCode}/Checklists`)
        .then((response) => {

            return {
                ...response,
                ActivityChecklists: asObjectified(response, 'ActivityChecklists')
            };
        });
}

export async function updateChecklists(query: { ActivityCode: string; checklists: IActivityChecklistFacade[] }): Promise<IObjectifiedActivityChecklistsResponse> {

    const { ActivityCode, checklists } = query;

    return Api.putInlineResponse<IActivityChecklistFacade[], IActivityChecklistsResponse, 'ActivityChecklists'>(
        `ActivityMaintenance/Activity/${ActivityCode}/Checklists`, checklists)
        .then((response) => {

            return {
                ...response,
                ActivityChecklists: asObjectified(response, 'ActivityChecklists')
            };
        });
}

export const fetchChecklistsOptions = async (query: { SearchText: string }): Promise<ILookupResponse> => {
    return Api.post<{ SearchText: string }, ILookupResponse>('CustomTypes/Lookup/ServiceChecklist/Search', query);
};
