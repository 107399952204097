import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reset } from 'redux-form';
import LostSalesDialog from './LostSalesDialog';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  values: getFormValues('LostSale')(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset('LostSale'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesDialog);
