const PaymentDetails = {
  'id': 'PaymentDetails',
  'layout': {
    'rows': 2,
    'columns': 2
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1, 
        'colspan': 1.5
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reference',
            'name': 'Reference',
            'size': 'medium'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Bank transaction',
            'name': 'BankTransaction',
            'size': 'small',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Payment amount',
            'name': 'PaymentAmountDisplay',
            'size': 'medium',
            'alignment': 'right'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Cash out',
            'name': 'CashOutDisplay',
            'size': 'medium',
            'alignment': 'right'
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Merchant fee recovery',
            'name': 'MerchanteFeeRecoveryDisplay',
            'size': 'medium',
            'alignment': 'right'
          }
        },
        {
          'id': 5,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Merchant recovery fee waived',
            'name': 'MerchantRecoveryFeeWaived'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2,
        'colspan': 1.5,
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Cheque Payment Details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Cheque number',
            'name': 'ChequeNumberDisplay',
            'size': 'small',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Account name',
            'name': 'AccountName',
            'size': 'large'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Bank code',
            'name': 'BankCode',
            'size': 'small',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Bank branch',
            'name': 'BankBranch',
            'size': 'medium'
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Bank account number',
            'name': 'BankAccountNumber',
            'size': 'large'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 1,
        'colspan': 1.5,
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'EFT Payment Details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Transaction type',
            'name': 'TransactionTypeDescription',
            'placeholder': '',
            'helpText': '',
            'size': 'small'
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'EFTPOS Merchant',
            'name': 'Merchant',
            'size': 'medium'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'EFTPOS Terminal',
            'name': 'Terminal',
            'size': 'medium'
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'EFTPOS transaction date/time',
            'name': 'TransactionsDateTime',
            'size': 'medium'
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'EFTPOS card type',
            'name': 'CardType',
            'size': 'large'
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'EFTPOS Authorisation',
            'name': 'Authorisation',
            'size': 'large'
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 2,
        'colspan': 1.5,
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Credit Card Payment Details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Credit card',
            'name': 'CreditCard',
            'size': 'small',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Card name',
            'name': 'CardName',
            'size': 'large',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Card number',
            'name': 'CardNumber',
            'size': 'medium',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Card expiry',
            'name': 'CardExpiryDisplay',
            'size': 'small',
          }
        }
      ]
    }
  ]
}
export default PaymentDetails;