import FormViewModal from 'components/common/Modals/FormViewModal';
import React from 'react';
import { ISalesSurchargeDialogProperties } from './SalesSurchargeDialog.properties';
import SalesSurchargeSchema from './SalesSurchargeDialog.form';
import { useCalculateSurcharge } from 'api/pickSlips/pickslip';
import { IDespatchSurchargeFacade } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';

const SalesSurchargeDialog = (props: ISalesSurchargeDialogProperties) => {
    const { open, loading, values, data, selectedDespatchId, onCancel, onOk } = props;

    const calculateSurchargeMutation = useCalculateSurcharge();
    const [internalData, setInternalData] = React.useState<IObjectified<IDespatchSurchargeFacade>>(data);

    React.useEffect(
        () => {
            setInternalData(data);
        },
        [data]
    );

    const calculateFreight = React.useCallback(
        async (_data) => {
            const response = await calculateSurchargeMutation.mutateAsync({
                DespatchId: selectedDespatchId,
                ..._data
            });
            if (response?.Status === true) {
                setInternalData(response?.DespatchSurcharge);
            }
        },
        [selectedDespatchId]
    );

    const formFieldOnBlur = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const targetValue = e?.target?.value;
            const fieldName = e?.target?.name;

            if (fieldName) {
                const isValueChanged = Number(internalData?.inlineObject?.[fieldName]) !== Number(targetValue);

                if (isValueChanged) {
                    calculateFreight({
                        ...values,
                        [fieldName]: targetValue
                    });
                }
            }
        },
        [data, values, internalData?.inlineObject, calculateFreight]
    );

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title='Sales Surcharge'
                dialogActionsButtons={true}
                dialogActionsShadow={false}
                formProps={{
                    style: { height: '385px', minWidth: '283px' },
                    formSchema: SalesSurchargeSchema,
                    initialValues: internalData?.inlineObject,
                    valuesSchema: internalData?.schema,
                    onBlur: formFieldOnBlur
                }}
                actions={[
                    {
                        title: 'Ok',
                        isDefault: true,
                        listener: () => {
                            if (onOk) {
                                onOk(values);
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default SalesSurchargeDialog;
