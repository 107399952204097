import * as React from 'react';
import { withRouter } from 'react-router';
import { IChecklistProps, IChecklistsState } from '../interfaces';
import {
    Operation,
} from 'utils/operations';
import ChecklistsGrid from './ChecklistsGrid';
import { IActivityChecklistFacade } from 'api/swaggerTypes';
import { isShallowEqual, isNull } from 'utils/utils';
import SearchScreen from '@markinson/uicomponents-v2/SearchScreen';
import { Column } from 'devextreme-react/data-grid';
import { fetchChecklistsOptions } from 'api/activityMaintenance/checklists';
import { cloneDeep } from 'lodash';

class Checklists extends React.PureComponent<IChecklistProps, IChecklistsState> {
    operationMap: Map<Operation, (prevProps?: Readonly<IChecklistProps>) => void>;

    constructor(props: Readonly<IChecklistProps>) {
        super(props);
        this.state = {
            checklists: [],
            openSearchScree: false,
            addChecklistData: [],
            addChecklistLoading: false,
        };
        this.operationMap = new Map<Operation, () => void>();
        this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
        this.operationMap[Operation.BACK] = this.cancelFormSubmission;
        this.operationMap[Operation.NEW] = this.handleNew;
        this.operationMap[Operation.SAVE] = this.handleSave;
    }

    componentDidMount(): void {
        const { path, onInitialLoad } = this.props;

        if (path) {
            switch (path) {
                case '/activity-maintenance/checklists':
                    onInitialLoad('Checklists');
                    break;
                default:
                    onInitialLoad('Checklists');
            }
        }
        this.getChecklists();
    }

    componentDidUpdate(prevProps: Readonly<IChecklistProps>): void {
        const { checklists, operationMode, selectedActivity } = this.props;
        if (!isShallowEqual(prevProps.checklists, checklists)) {
            const checklistCopy = cloneDeep(checklists);
            this.setState({ checklists: checklistCopy });
        }
        if (operationMode !== prevProps.operationMode) {
            if (this.operationMap[operationMode]) {
                this.operationMap[operationMode](prevProps);
            }
        }

        if (!isShallowEqual(prevProps.selectedActivity, selectedActivity)) {
            this.getChecklists();
        }
    }

    getActivityCode = (): string => {
        const { location } = this.props;
        const params = (new URLSearchParams(location.search));
        const ActivityCode = params.get('ActivityCode') || '';

        return ActivityCode;
    }

    getChecklists = (): void => {
        const { getChecklists, selectedActivity } = this.props;
        const ActivityCode = !isNull(selectedActivity) ? selectedActivity.ActivityCode : this.getActivityCode();
        this.setState({
            checklists: []
        });
        if (getChecklists) {
            getChecklists(ActivityCode);
        }
    }

    handleReorder = (reorderedList: IActivityChecklistFacade[]): void => {
        this.setState(
            { checklists: reorderedList },
            () => { this.props.setIsDirty(true); });
    }

    handleSave = (): void => {
        const { updateChecklists, changeOperationMode } = this.props;
        const { checklists } = this.state;
        const updatedList = checklists.map((cl, i) => ({ ...cl, Sequence: i + 1 }));
        updateChecklists({ ActivityCode: this.getActivityCode(), checklists: updatedList });
        changeOperationMode(Operation.BROWSE);
    }

    handleNew = (): void => {
        this.setState({ openSearchScree: true });
        this.props.changeOperationMode(Operation.BROWSE);
    }

    handleClose = (rowData: any): void => {
        const { checklists } = this.state;
        if (!isNull(rowData)) {
            const existing = checklists.find((cl) => cl.ChecklistCode === rowData.checklistCode);
            if (isNull(existing)) {
                const newRow = {
                    ActivityCode: this.getActivityCode(),
                    ChecklistCode: rowData.checklistCode,
                    Sequence: checklists.length + 1,
                    ChecklistCodeLabel: rowData.checklistLabel,
                };
                this.setState(
                    { checklists: [...checklists, newRow] },
                    () => { this.props.setIsDirty(true); });
            }
        }
        this.setState({ openSearchScree: false });
    }

    cancelFormSubmission = () => {
        const {
            checklists, selectedTab, history, isDirty,
            onInitialLoad, changeConfirmationDialog, changeOperationMode
        } = this.props;

        if (isDirty) {

            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    changeOperationMode(Operation.BROWSE);
                },
                onOk: () => {
                    const checklistCopy = cloneDeep(checklists);
                    this.setState(
                        { checklists: checklistCopy },
                        () => { this.props.setIsDirty(false); });
                    changeOperationMode(Operation.BROWSE);
                }
            });
        } else {
            if (Operation.CANCEL) {
                changeOperationMode(Operation.BROWSE);
            } else {
                if (selectedTab !== 'Checklists') {
                    onInitialLoad('Checklists');
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab === 'Checklists') {
                    history.push('/dashboard');
                }
            }
        }
    }

    getLinesToAdd = (SearchText: string): void => {
        this.setState({ addChecklistLoading: true });
        fetchChecklistsOptions({ SearchText })
            .then((response) => {
                this.setState({ addChecklistLoading: false });

                if (response.Data) {
                    const dataArr = response.Data.map((option) => ({ checklistCode: option.Code, checklistLabel: option.Label }));
                    this.setState({ addChecklistData: dataArr });
                }
            }).catch((err) => {
                this.setState({ addChecklistLoading: false });
                console.warn(err);
            });
    }

    render(): React.ReactNode {
        const { checklists, openSearchScree, addChecklistData, addChecklistLoading } = this.state;
        const { isDataLoading, setIsDirty } = this.props;

        return (
            <React.Fragment>
                <ChecklistsGrid
                    disabled={isDataLoading}
                    onReorder={this.handleReorder}
                    checklistsData={checklists}
                    onDelete={() => { setIsDirty(true); }}
                    onInsert={() => { setIsDirty(true); }}
                />
                <SearchScreen
                    callSearchLookupOnOpen={true}
                    open={openSearchScree}
                    loading={addChecklistLoading}
                    title={'Add Checklist'}
                    onClose={this.handleClose}
                    data={addChecklistData as any}
                    onLookup={this.getLinesToAdd}
                    onSearch={this.getLinesToAdd}
                >
                    <Column caption='Checklist Code' dataField='checklistCode' />
                    <Column caption='Checklist Description' dataField='checklistLabel' />
                </SearchScreen>
            </React.Fragment>
        );
    }
}

export default withRouter(Checklists);
