import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet } from '../utils';
const respType = 'None';

export const search = async (query) => {
  const { fetchApi, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchGet(fetchApi, { BatchPage: Page, BatchSize }, respType).then((result) => {
    if (result)
      if (result.Event)
        return {
          records: result.Event,
          nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
          currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        }
      else
        return result;
  });
};
