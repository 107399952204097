import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as alternatesSelectors, actions as alternatesActions } from 'ducks/inventoryEnquiry/associations/alternates'
import { fetch } from 'api/inventoryEnquiry/associations/alternates';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'ProductAssociationsAlternates',
  columnDefs: alternatesSelectors.columns(state),
  detailColumns: alternatesSelectors.detailColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: alternatesSelectors.gridOptions(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  isLoading: alternatesSelectors.isLoading(state),
  detailCellRenderer: alternatesSelectors.detailCellRenderer(state),
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: alternatesActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
