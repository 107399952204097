import { takeLatest, Effect, takeEvery } from 'redux-saga/effects';

import { types as supplierInvoiceTypes, actions as supplierInvoiceActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';

import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierInvoiceEnquiry/supplierInvoice';

export default function* rootSupplierInvoiceSaga(): IterableIterator<Effect> {
  yield takeLatest(supplierInvoiceTypes.search, connectSagaToApi(supplierInvoiceActions, 'search', api.search));
  yield takeLatest(supplierInvoiceTypes.searchById, connectSagaToApi(supplierInvoiceActions, 'searchById', api.searchById));
  yield takeEvery(supplierInvoiceTypes.fetchPrevPage, connectSagaToApi(supplierInvoiceActions, 'fetchPrevPage', api.search));
  yield takeEvery(supplierInvoiceTypes.fetchNextPage, connectSagaToApi(supplierInvoiceActions, 'fetchNextPage', api.search));
}
