import React from 'react';
import Date from 'assets/Date';
import Sigma from 'assets/Sigma';
import Lookup from 'assets/Lookup';
import { IIconWithTextProps } from './FilterGrid.properties';
import styles from './FieldsGrid.styles';
import { withStyles } from '@material-ui/core';

// Map of FieldType to corresponding icon components
const iconMap = {
    Date: <Date />,
    Calculated: <Sigma />,
    Lookup: <Lookup />,
    // Add more mappings as needed
};

export const IconWithText = ({ data, classes }: IIconWithTextProps) => (<p className={classes.IconWithTextstyles}>{data.FieldDataType.includes('date') ? iconMap.Date : iconMap[data.FieldType] || null}{data.FieldLabel}</p>);

export default withStyles(styles, { index: 1 })(IconWithText);
