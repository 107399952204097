function appendParams(path: string, params: (number | { SalesOrder?: number })): string {
  if (params && typeof (params) === 'string') {
    const search = new URLSearchParams();
    search.append('SalesOrder', params);
    path += `?${search.toString()}`;
  } else if (params && typeof (params) === 'object' && params.SalesOrder) {
    const search = new URLSearchParams();
    if (params.SalesOrder) {
      search.append('SalesOrder', params.SalesOrder.toString());
    }
    path += `?${search.toString()}`;
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Sales Order Enquiry in new tab',
    action: (params?: (number | { SalesOrder?: number; LineNumber?: number})) => { window.open(appendParams('/sales-order-enquiry/order-details', params)); }
  },
  {
    id: '2',
    label: 'Sales Order Enquiry',
    action: (params?: (number | { SalesOrder?: number; LineNumber?: number})) => { window.location.assign(appendParams('/sales-order-enquiry/order-details', params)); }
  }
];
