import {
    IWorksaleDetailsFacade,
    IMessageModelFacade,
    IWorksaleDetailsDetailsResponse,
} from './../swaggerTypes';
import { Inline, IObjectified, objectify } from '../utils';
import { toObjectified } from '../baseApi';
import { APIMethod, Payload, prepareUrl, useFetch, usePost } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { useQueryClient } from '@tanstack/react-query';

export type IObjectifiedWorksaleOrderDetailsResponse = Omit<IWorksaleDetailsDetailsResponse, 'Messages' | 'WorksaleDetails'> & {
    WorksaleDetails: IObjectified<IWorksaleDetailsFacade>;
    'Messages'?: IMessageModelFacade[];
};

const fetchWorksaleOrderDetailsResponseParser = (response): Promise<IObjectifiedWorksaleOrderDetailsResponse> => {
    const { WorksaleDetails = {} } = response;

    return {
        ...response,
        WorksaleDetails: WorksaleDetails ? toObjectified(response.WorksaleDetails as Inline<IWorksaleDetailsFacade>) : null
    };
};

export const useFetchWorksaleOrderDetails = () => {
    return useFetch<IObjectifiedWorksaleOrderDetailsResponse>(
        {
            url: APIRoutes.orderDetails.entries.retrieve,
            queryConfig: { enabled: false },
            options: { inline: true, responseParser: fetchWorksaleOrderDetailsResponseParser }
        }
    );
};

export const useSetWorksaleOrderDetailsQueryData = () => {
    const queryClient = useQueryClient();

    return (dataToUpdate: IObjectifiedWorksaleOrderDetailsResponse) => {
        const finalUrl = prepareUrl(APIRoutes.orderDetails.entries.retrieve, null, null, true);
        queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
    };
};

const updateWorksaleOrderDetailsResponseParser = (response): Promise<IObjectifiedWorksaleOrderDetailsResponse> => {
    return {
        ...response,
        Messages: response.Messages ? response.Messages.map((d) => objectify(d, null, 'Inline')) : []
    };
};

export const useUpdateWorksaleOrderDetails = () => {

    return usePost<Payload<{ WorksaleId: number; urlQueryParams?: any }>, any>
        (APIRoutes.orderDetails.update.worksaleOrderDetails, null, { inline: true, responseParser: updateWorksaleOrderDetailsResponseParser });
};
