import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';
import { selectors as orderSelectors, actions as orderActions } from 'ducks/salesOrderEnquiry/salesOrder';
import { actions as deliveryLineActions, selectors as deliveryLineSelectors } from 'ducks/salesOrderEnquiry/deliveryLine';
import { actions as ldCommittedPrOActions } from 'ducks/salesOrderEnquiry/ldCommittedProductionOrder';
import { selectors as BOMSelectors } from 'ducks/salesOrderEnquiry/BOM';
import { actions as componentDetailsActions, selectors as componentDetailsSelectors } from 'ducks/salesOrderEnquiry/componentDetails';
import { actions as depositDetailsActions, selectors as depositDetailsSelectors } from 'ducks/salesOrderEnquiry/depositDetails';
import { actions as orderLineDetailsAction, selectors as orderLineDetailsSelectors } from 'ducks/salesOrderEnquiry/orderLineDetails';
import { actions as reservationDetailsActions } from 'ducks/salesOrderEnquiry/reservationDetails';
import { actions as cdReservationDetailsActions } from 'ducks/salesOrderEnquiry/cdReservationDetails';
import { actions as paymentEntryActions } from 'ducks/salesOrderEnquiry/paymentEntryDetails';
import { selectors as saleDepositSelectors } from 'ducks/salesOrderEnquiry/saleDeposits';
import SalesOrderEnquiry from './SalesOrderEnquiry';
import { destroy } from 'redux-form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedOrder: orderSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  selectedOrderLine: orderLineSelectors.selectedOrderLine(state),
  selectedBOMLine: BOMSelectors.selectedBOMLine(state),
  componentSummary: componentDetailsSelectors.componentSummary(state),
  orderLineSummary: orderLineDetailsSelectors.lineSummary(state),
  selectedDelivery: deliveryLineSelectors.selectedDeliveryLine(state),
  selectedTransactions: saleDepositSelectors.selected(state),
  selectedDepositDetails: depositDetailsSelectors.selected(state),
  depositDetailsSummary: depositDetailsSelectors.summary(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getLDCommittedProductionOrder: ldCommittedPrOActions.fetch,
    getDepositDetailsSummary: depositDetailsActions.fetchSummary,
    getDeliveryDetails: deliveryLineActions.getDetails,
    getOrderDetails: orderActions.getDetails,
    fetchOrderSummary: orderActions.fetchSummary,
    getOrderLineDetails: orderLineDetailsAction.fetchLineDetails,
    getOrderLineSummary: orderLineDetailsAction.fetchLineSummary,
    getReservationDetails: reservationDetailsActions.fetchReservationDetails,
    getCDReservationDetails: cdReservationDetailsActions.fetchReservationDetails,
    getComponentDetails: componentDetailsActions.fetchComponentDetails,
    getComponentDetailsSummary: componentDetailsActions.fetchComponentSummary,
    getPaymentEntryDetails: paymentEntryActions.fetchPaymentEntryDetails,
    resetSearchLookupDrawer: orderActions.reset,
    destoryForm: () => destroy('SalesOrderEnquiry'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderEnquiry);
