import { connect } from 'react-redux';
import Deliveries from './Deliveries';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

export default connect(null, mapDispatchToProps)(Deliveries);
