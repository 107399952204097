
import React from 'react';
import DateTextField from 'components/FormView/Fields/DateField';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import DataGrid, { Button, Column, Scrolling, LoadPanel, Editing } from 'devextreme-react/data-grid';
import { Checkbox, Divider, withStyles } from '@material-ui/core';
import styles from './ServiceAgreementItemModal.styles';
import { IServiceAgreementItemViewProps, IServicePatternItemDetailsFacadeExtension } from './ServiceAgreementItemModal.properties';
import { isNull } from 'utils/utils';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';

const LOADING_INDICATOR_HEIGHT_WIDTH = 50;
const DATA_GRID_HEIGHT = 300;

const ServiceAgreementItemView = (props: IServiceAgreementItemViewProps) => {
    const {
        classes,
        loading,
        forwardedRef,
        fieldsTouched,
        CustomerId,
        data,
        setData
    } = props;
    const dataGridRef = React.useRef<DataGrid>();

    const [fieldErrors, setFieldErrors] = React.useState(null);

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            hasError(): boolean {
                const hasError = Object.values(fieldErrors || {}).some((v) => v === true);

                return hasError;
            }
        }));

    React.useEffect(
        () => {
            if (dataGridRef.current) {
                if (loading) {
                    dataGridRef.current.instance.beginCustomLoading('');
                } else {
                    dataGridRef.current.instance.endCustomLoading();
                }
            }
        },
        [loading]
    );

    function updateFormData(key: string, value: any): void {
        setData({ ...data, [key]: value });
    }

    function handleFieldError(field: string, hasError: boolean): void {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: hasError }));
    }

    function onDataGridRowRemoved(): void {
        if (data?.Items) {
            const itemsWithNewSeq = data.Items.map((item, idx) => ({ ...item, SequenceNumber: idx + 1 }));
            const first = itemsWithNewSeq[0] || {} as IServicePatternItemDetailsFacadeExtension;
            setData({
                ...data,
                Items: itemsWithNewSeq,
                NextSequenceNumber: first.SequenceNumber,
                TemplateCode: first.TemplateCode,
                TemplateCodeLabel: first.TemplateCodeLabel
            });
        }
    }

    return (
        <div className={classes.serviceAgreementItemContainer}>
            <BasicLookupActionField
                touched={fieldsTouched}
                required
                placeholder={'Service item'}
                label={'Service item'}
                lookupName={'CustomerServiceItem'}
                size={'medium'}
                value={(data?.ServiceItem ?? '').toString()}
                style={styles.lookupField}
                suppressDescription={true}
                params={{ CustomerId: Number(CustomerId) }}
                hasError={(hasError: boolean) => { handleFieldError('ServiceItem', hasError); }}
                onSelectedItemChange={(obj: any = {}) => {
                    if (!isNull(obj)) {
                        setData({
                            ...data,
                            ServiceItem: Number(obj.Code),
                            ServiceItemDisplay: obj.Display,
                            ServiceItemLabel: obj.Label
                        } as any);
                    }
                }}
            />
            <DateTextField
                touched={fieldsTouched}
                required
                label={'Next service date'}
                value={data?.NextServiceDue}
                onChange={(v) => { updateFormData('NextServiceDue', v); }}
                hasError={(hasError: boolean) => { handleFieldError('NextServiceDue', hasError); }}
                size={'medium'}
            />
            <div className={classes.fullWidth}>
                <TextArea
                    label={'Comment'}
                    value={data?.Comment}
                    onChange={(e) => { updateFormData('Comment', e.target.value); }}
                />
            </div>
            <div className={`${classes.fullWidth} ${classes.heading}`}>
                Service pattern detail
                <Divider />
            </div>
            <TextField
                touched={fieldsTouched}
                required
                label={'Frequency'}
                name={'Frequency'}
                type={'number'}
                size={'medium'}
                hasError={(hasError: boolean) => { handleFieldError('FrequencyValue', hasError); }}
                alignment={'left'}
                onChange={(e) => { updateFormData('FrequencyValue', Number(e.target.value)); }}
                value={data?.FrequencyValue}
            />
            <BasicLookupActionField
                touched={fieldsTouched}
                required
                placeholder={'Type'}
                label={'Type'}
                lookupName={'ServiceFrequencyType'}
                size={'medium'}
                hasError={(hasError: boolean) => { handleFieldError('FrequencyType', hasError); }}
                onSelectedItemChange={(v, inputText) => { updateFormData('FrequencyType', v ? v.Code : inputText); }}
                value={data?.FrequencyType}
                style={styles.lookupField}
            />
            <DataGrid
                style={styles.fullWidth}
                keyExpr={'SequenceNumber'}
                ref={dataGridRef}
                height={DATA_GRID_HEIGHT}
                dataSource={(data?.Items) || []}
                repaintChangesOnly={true}
                remoteOperations={true}
                noDataText=''
                columnResizingMode={'nextColumn'}
                allowColumnResizing={true}
                showBorders={false}
                onRowRemoved={onDataGridRowRemoved}
                sorting={{
                    mode: 'none'
                }}
                hoverStateEnabled={true}
            >
                <Scrolling mode='virtual' />
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    confirmDelete={true}
                    allowUpdating={false}
                />
                <Column
                    caption={'#'}
                    dataField={'SequenceNumber'}
                    allowEditing={false}
                    width={'10%'}
                    fixed={true}
                />
                <Column
                    caption={'Job Template'}
                    cellRender={(params) => {
                        return `${params.data.TemplateCode || ''} - ${params.data.TemplateCodeLabel}`;
                    }}
                    allowEditing={false}
                    width={'65%'}
                    fixed={true}
                />
                <Column
                    caption={'Next Service'}
                    dataField={'NextService'}
                    alignment={'center'}
                    cellRender={(d) => {

                        return (<Checkbox checked={d.data.SequenceNumber === data.NextSequenceNumber} style={{ padding: 0 }}
                            onChange={() => {
                                setData({
                                    ...data,
                                    NextSequenceNumber: d.data.SequenceNumber,
                                    TemplateCode: d.data.TemplateCode,
                                    TemplateCodeLabel: d.data.TemplateCodeLabel,
                                });
                            }} />);
                    }}
                    width={'15%'}
                >
                </Column>
                <LoadPanel shading={false} height={LOADING_INDICATOR_HEIGHT_WIDTH} width={LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
                <Column type={'buttons'} width={'10%'} allowResizing={false}>
                    <Button name={'delete'} icon={'trash'} />
                </Column>
            </DataGrid>
        </div>
    );
};

export default withStyles(styles, { index: 1 })(ServiceAgreementItemView);
