import { asyncSelectors, IExtendedState, initializeReducer } from './utils';
import { IMenuResponse, ITopLevelMenu } from 'api/swaggerTypes';

export interface IMainMenuData {
  MenuFolders: ITopLevelMenu[];
}
export interface IMainMenuState extends IExtendedState<IMainMenuData> {
  fetchMainMenu_loading?: boolean;
}

const initialData: IMainMenuData = {
  MenuFolders: []
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'mainMenu',
  initialData: initialData,
  asyncActions: {
    fetchMainMenu: {
      action: () => null,
      successCallback: (data: IMainMenuData, payload: IMenuResponse): IMainMenuData => {
        return {
          ...data,
          MenuFolders: payload ? payload.menuFolders : []
        };
      }
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { mainMenu: IMainMenuState }) => state.mainMenu,
  {
    menuFolders: (data: IMainMenuData) => data.MenuFolders
  }
);

const syncSelector = {
  isLoading: (state: { mainMenu: IMainMenuState }) => state.mainMenu.fetchMainMenu_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
