import React from 'react';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import ActionFieldContainer from './Fields/ActionFieldContainer';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import Paper from './Fields/PaperContainer';
import DateTextField from './Fields/DateField';
import ExtendedDisplayField from './Fields/ExtendedDisplayField';
import TimeField from './Fields/TimeField';
import { Switch, Divider, FormControlLabel, Icon } from '@material-ui/core';

import BasicLookupActionField from './Fields/BasicLookupActionField';
import ContactsBlockContainer from 'components/ContactsBlockContainer';
import AddressSection from './Fields/AddressSection';
import GoogleAddressBlock from './Fields/GoogleAddressBlock';
import { withStyles } from '@material-ui/core/styles';
import SelectContainer from 'components/SelectContainer';
import AutoCompleteContainer from 'components/AutoCompleteContainer';
import ExtendedTextFieldContainer from 'components/ExtendedTextFieldContainer';
import DataGridDexEx from 'components/common/DataGridDevEx';
import ImageGrid from 'components/common/ImageGrid';
import NotePadContainer from 'components/common/Notepad';
import EventsContainer from 'components/common/Events';
import DiaryContainer from 'components/common/Diary';
import DiaryDetailsContainer from 'components/common/DiaryDetails';
import DocOutputField from 'components/common/DocOutputField';
import BreaksTable from 'components/common/BreaksTable';
import RestockingCalculatorField from 'components/common/ExtendedLookUpActionField/RestockingCalculatorField';
import SerialNumber from 'components/common/SerialNumber';
import ActionButton from 'components/common/ActionButton';
import HyperLinkButton from 'components/common/HyperLinkButton';
import MaskedInput from '@markinson/uicomponents-v2/MaskedInputField'
import CustomerLookup from 'components/common/CustomerLookup';
import { isNull } from 'utils/utils';
import { googleApiKey } from '../../config';
import ImageView from 'components/common/ImageView';

const SwitchStyles = {
  root: {
    '&:has(> .MuiButtonBase-focusVisible) + .MuiTypography-root': {
      color: '#347FFE'
    }
  },
  switchBase: {
    '&.MuiButtonBase-focusVisible': {
      '& .MuiSwitch-icon': {
        border: '2px solid #347FFE',
        boxShadow: '0px 0px 5px 1px #347FFE'
      },
      '&$checked': {
        '& + $bar': {
          outline: '2px solid #347FFE',
        },
      },
      '& + $bar': {
        outline: '2px solid #347FFE',
      },
    },
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 0.5
      }
    }
  }
};

const AddressBlockStyles = {
  marginRight: 16,
  marginBottom: 16
};

const WRTextField = ({ input: { value, ...restInput }, name, label, required, readOnly, fullWidth, fieldStyle, alignment, operationMode, ...custom }) => {
  return (<TextField
    label={label}
    style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : fieldStyle || {}}
    required={required}
    disabled={readOnly}
    value={value}
    touched={custom.meta.touched || operationMode === 'SAVE'}
    alignment={alignment}
    autoComplete={'off'}
    {...restInput}
    {...custom}
  />
  );
};

const WRExtendedDisplayField = ({ input: { value, ...restInput }, name, label, required, readOnly, fullWidth, fieldStyle, operationMode, ...custom }) => {
  return (<ExtendedDisplayField
    label={label}
    style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : fieldStyle || {}}
    required={required}
    disabled={readOnly}
    value={value}
    touched={custom.meta.touched || operationMode === 'SAVE'}
    autoComplete="off"
    {...restInput}
    {...custom}
  />
  );
};

const WRMaskedInput = ({ input: { value, ...restInput }, name, label, required, readOnly, operationMode, ...custom }) => (
  <MaskedInput
    label={label}
    required={required}
    disabled={readOnly}
    value={value}
    touched={custom.meta.touched || operationMode === 'SAVE'}
    autoComplete="off"
    {...restInput}
    {...custom}
  />
)

const WRNotepad = ({ input, name, label, fullWidth, required, readOnly, style, ...custom }) => {
  return (<NotePadContainer label={label} fullWidth={fullWidth} required={required} readOnly={readOnly} style={style} {...custom} input={input} />);
};

const WRTextArea = ({ input, name, label, required, readOnly, fullWidth, operationMode, ...custom }) => (
  <TextArea
    label={label}
    style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : {}}
    required={required}
    disabled={readOnly}
    {...input}
    {...custom}
  />
);

const WRImageView = ({ name, ...custom }) => (
  <ImageView {...custom} />
);

const WRActionField = ({ input, name, label, required, disabled, readOnly, fullWidth, operationMode, ...custom }) => (
  <ActionFieldContainer
    label={label}
    style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : {}}
    required={required}
    disabled={disabled}
    readOnly={readOnly}
    {...input}
    {...custom}
  />
);

const WRExtendedTextField = ({ input, value, label, required, readOnly, operationMode, ...custom }) => (
  <ExtendedTextFieldContainer
    label={label}
    readOnly={readOnly}
    autoComplete={'off'}
    {...input}
    {...custom}
  />
);

const WRRestockingCalculatorField = ({ input, value, label, required, readOnly, operationMode, ...custom }) => (
  <RestockingCalculatorField
    label={label}
    readOnly={readOnly}
    autoComplete={'off'}
    {...input}
    {...custom}
  />
);

const WRDateField = ({ input: { value, ...restInput }, label, required, readOnly, dateFormat, operationMode, ...custom }) => (
  <DateTextField
    label={label}
    value={value}
    required={required}
    disabled={readOnly}
    format={dateFormat}
    touched={custom.meta.touched || operationMode === 'SAVE'}
    operationMode={operationMode}
    {...restInput}
    {...custom}
  />
);

const WRTimeField = ({ input: { value, ...restInput }, label, required, readOnly, dateFormat, operationMode, ...custom }) => (
  <TimeField
    label={label}
    value={value}
    required={required}
    disabled={readOnly}
    format={dateFormat}
    operationMode={operationMode}
    {...restInput}
    {...custom}
  />
);

const WRToggleField = withStyles(SwitchStyles, { index: 1 })(({ input: { value, onChange: onInputChange, ...rest }, readOnly, classes, style, operationMode, onChange, ...custom }) => (
  <FormControlLabel
    control={<Switch
      color={'default'}
      checked={!!value}
      disabled={readOnly}
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        bar: classes.bar,
        disabled: classes.disabled,
        root: classes.root
      }}
    />}
    style={{ width: '100%', ...style }}
    classes={{
      label: classes.formControlLabel,
      disabled: classes.disabled
    }}
    onChange={(v) => {
      if (onChange) {
        onChange(v);
      }
      onInputChange(v);
    }}
    {...rest}
    {...custom}
  />
));

const WRSelectContainer = ({ input, label, children, readOnly, options, operationMode, meta, ...custom }) => (
  <SelectContainer
    label={label}
    size={'medium'}
    children={children}
    disabled={readOnly}
    options={options}
    touched={meta.touched}
    {...input}
    {...custom}
  />
);

const WRAutoCompleteContainer = ({ input, label, children, readOnly, options, operationMode, ...custom }) => (
  <AutoCompleteContainer
    label={label}
    size={'medium'}
    children={children}
    disabled={readOnly}
    options={options}
    {...input}
    {...custom}
  />
);

const WRAddressSection = ({ input, label, ...custom }) => (
  <AddressSection
    label={label}
    {...input}
    {...custom}
  />
);
const WRGoogleAddressSection = ({ input, label, ...custom }) => (
  isNull(googleApiKey) ?
    WRAddressSection({ input, label, ...custom }) :
    <GoogleAddressBlock
      label={label}
      {...input}
      {...custom}
    />
);

const WRActionButton = ({ input, ...custom }) => (
  <ActionButton
    {...input}
    {...custom}
  />
);

const WRHyperLinkButton = ({ input, operationMode, ...custom }) => (
  <HyperLinkButton
    {...input}
    {...custom}
  />
);

const WRPaper = ({ props }) => {
  return <Paper
    elevation={props.elevation}
    name={props.name}
  ></Paper>;
};

const WRContactBlock = ({ input, value, label, required, readOnly, variant, meta: { touched, error }, operationMode, ...custom }) => (
  <ContactsBlockContainer
    label={label}
    readOnly={readOnly}
    style={AddressBlockStyles}
    variant={variant}
  />
);

const WRDataGridDexEx = ({ input, value, label, required, readOnly, styles, module, meta: { touched, error }, operationMode, ...custom }) => (
  <DataGridDexEx
    {...custom}
    label={label}
    values={input.value || []}
    readOnly={readOnly}
    style={{ ...styles }}
    module={module}
  />
);

const WRImageGrid = ({ ...custom }) => (
  <ImageGrid {...custom} />
);

const WRDiary = ({ fetchApi, diaryType }) => (
  <DiaryContainer
    fetchApi={fetchApi}
    diaryType={diaryType}
    style={AddressBlockStyles}
  />
);

const WRDiaryDetails = ({ createApi, updateApi, deleteApi, searchByIdApi, input, diaryType }) => (
  <DiaryDetailsContainer
    createApi={createApi}
    updateApi={updateApi}
    deleteApi={deleteApi}
    searchByIdApi={searchByIdApi}
    diaryType={diaryType}
    input={input} />
);

const WREvents = ({ fetchApi }) => (
  <EventsContainer
    fetchApi={fetchApi}
    style={AddressBlockStyles}
  />
);

const WRDivider = () => (
  <Divider
    style={{ marginTop: '16px', marginBottom: '16px' }}
  />
);

const WRExtendedLookUpActionField = (
  {
    input: { value, onBlur, ...restInput },
    label,
    required,
    readOnly,
    meta: { touched, error },
    inputProps,
    fieldRef,
    ...custom
  }) => (
  <BasicLookupActionField
    ref={fieldRef}
    label={label}
    required={required}
    readonly={readOnly}
    value={value}
    touched={touched}
    descriptionField={'Label'}
    {...restInput}
    {...custom}
    {...inputProps}
  />
);

const CustomerLookUpField = (
  {
    input: { value, ...restInput },
    label,
    required,
    readOnly,
    operationMode,
    meta: { touched, error },
    ...custom
  }) => (
  <CustomerLookup
    label={label}
    required={required}
    readOnly={readOnly}
    operationMode={operationMode}
    value={value}
    touched={touched}
    {...restInput}
    {...custom}
  />
);

const WRDocumentOutputField = ({ input, ...restProps }) => {

  return (
    <DocOutputField
      {...input}
      {...restProps} />
  )
}

const WRBreaksTable = (
  {
    input: { value, ...restInput },
    ...custom
  }) => (
  <BreaksTable
    value={value}
    {...restInput}
    {...custom}
  />
);

const WRSerialNumber = (props) => (
  <SerialNumber {...props} />
)

export default {
  TEXT_FIELD: WRTextField,
  TEXT_AREA: WRTextArea,
  IMAGE_VIEW: WRImageView,
  CONTACT_BLOCK: WRContactBlock,
  ADDRESS_BLOCK: WRAddressSection,
  ADDRESS_BLOCK_EX: WRGoogleAddressSection,
  PAPER_CONTAINER: WRPaper,
  SELECT_FIELD: WRSelectContainer,
  TOGGLE_FIELD: WRToggleField,
  DATE_FIELD: WRDateField,
  TIME_FIELD: WRTimeField,
  EXTENDED_TEXT_FIELD: WRExtendedTextField,
  ACTION_FIELD: WRActionField,
  DATA_GRID_DEV_EX: WRDataGridDexEx,
  IMAGE_GRID: WRImageGrid,
  DIVIDER: WRDivider,
  NOTEPAD: WRNotepad,
  EVENTS: WREvents,
  DIARY: WRDiary,
  DIARY_DETAILS: WRDiaryDetails,
  EXTENDED_DISPLAY_FIELD: WRExtendedDisplayField,
  EX_LOOKUP_FIELD: WRExtendedLookUpActionField,
  CUSTOMER_LOOKUP_FIELD: CustomerLookUpField,
  AUTOCOMPLETE_FIELD: WRAutoCompleteContainer,
  DOCUMENT_OUTPUT_FIELD: WRDocumentOutputField,
  BREAKS_TABLE: WRBreaksTable,
  RESTOCKING_CALCULATOR_FIELD: WRRestockingCalculatorField,
  SERIAL_NUMBER_GRID: WRSerialNumber,
  ACTION_BUTTON: WRActionButton,
  HYPERLINK_BUTTON: WRHyperLinkButton,
  MASKED_INPUT: WRMaskedInput,
};
