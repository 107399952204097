import * as React from 'react';
import { Paper, CircularProgress, Button } from '@material-ui/core';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { dragExpandIcon, customerMaintenanceDrawerBackground } from '../../themes/colors';
import DateTextField from '../FormView/Fields/DateField';
import SelectContainer from '../SelectContainer';
import { isShallowEqual, isNull } from '../../utils/utils';
import * as styles from './CreditNotesLookupPanel.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { CircularProgressSize } from '../../utils/constants';
import { ICreditNotesLookupPanelProperties, ICreditNotesLookupPanelState } from './interfaces';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import LookupPanel from 'components/common/LookupPanel';

const inlineStyles = createStyles({
  openedDragController: {
    left: '300px',
    width: '10px',
    backgroundColor: '#F47920',
    cursor: 'pointer'
  },
  dragIcon: {
    color: dragExpandIcon,
    width: '20px',
    height: '20px',
    marginLeft: '-5px',
    cursor: 'pointer'
  },
  closedDragIcon: {
    color: dragExpandIcon,
    width: '30px',
    height: '30px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
  lookupContainerStyle: {
    top: '0',
    backgroundColor: customerMaintenanceDrawerBackground,
    height: '100%',
    boxShadow: 'none',
    width: 300,
    position: 'relative',
  },
  recallLookupContainerStyle: {
    width: 'calc(100% - 20px)',
    padding: '10px 10px 0',
    boxSizing: 'border-box',
    margin: '10px',
  },
  lookupFieldsPaperContainer: {
    width: 'calc(100% - 20px)',
    padding: '10px 10px 0',
    boxSizing: 'border-box',
    margin: '10px',
  },
  topLookupFields: {
    width: '100%',
    minWidth: '200px'
  },
  basicLookupField: {
    '& > div': {
      width: '100%',
      minWidth: '200px'
    }
  },
  expansionListContainer: {
    marginBottom: '10px',
    height: 'calc(100% - 185px)'
  },
  commitActionPaperContainer: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    height: '60px',
    width: '100%',
    backgroundColor: '#ebebeb'
  },
  commitActionButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  panelHeading: {
    color: '#000000',
    fontSize: '15px',
    paddingBottom: '10px',
  }
});

class CreditNotesLookupPanelComponent extends React.Component<ICreditNotesLookupPanelProperties, ICreditNotesLookupPanelState> {

  private invoiceLookup: any;

  constructor(props: Readonly<ICreditNotesLookupPanelProperties>) {
    super(props);
    this.state = {
      loading: true,
      creditNoteContext: {
        CustomerId: { Value: null },
        SalesEntity: { Value: null },
        EnterDate: null,
        Period: null,
        CustomerPurchaseOrder: null,
        Invoice: { Value: null },
        Warehouse: null,
        CustomerCreditReason: null
      }
    };
  }

  componentDidMount(): void {
    this.props.getCreditNoteContext();
    this.focusInvoiceLookup();
  }

  componentDidUpdate(prevProps: Readonly<ICreditNotesLookupPanelProperties>): void {
    const { open } = this.props;
    if (!isShallowEqual(this.props.creditNoteContext, prevProps.creditNoteContext)) {
      const { creditNoteContext } = this.props;
      this.setState((prevState) => {
        return { ...prevState, creditNoteContext: creditNoteContext };
      });
    }
    if (this.props.loading !== prevProps.loading) {
      const { loading } = this.props;
      this.setState((prevState: Readonly<ICreditNotesLookupPanelState>) => {
        return { ...prevState, loading: loading };
      });
    }
    if (open && !prevProps.open) {
      this.focusInvoiceLookup();
    }
  }

  focusInvoiceLookup = (): void => {
    if (this.invoiceLookup) this.invoiceLookup?.inputField?.select();
  }

  onLookupClear = () => {
    this.props.clearCreditNoteContext();
  }

  applyContext = () => {
    this.props.onApply(this.state.creditNoteContext);
    this.props.onToggle(!this.props.open);
  }

  selectCustomer = (CustomerId: any): void => {
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      CustomerId: CustomerId.Code
    });
  }

  selectSalesEntity = (salesEntity: any): void => {
    if (this.state.loading) {
      return;
    }
    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      SalesEntity: salesEntity.Code
    });
  }

  selectPeriod = (period: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      Period: period
    });
  }

  selectReason = (CustomerCreditReason: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      CustomerCreditReason: CustomerCreditReason.Code
    });
  }

  selectWarehouse = (Warehouse: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      Warehouse: Warehouse.Code
    });
  }

  selectInvoice = (Invoice: any): void => {
    const { loading, creditNoteContext } = this.state;
    if (loading || creditNoteContext.Invoice.Value === Invoice.Code) {
      return;
    }

    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      Invoice: Invoice.Code
    });
  }

  selectDate = (date: any): void => {
    if (this.state.loading) {
      return;
    }

    this.props.updateCreditNoteContext({
      ...this.state.creditNoteContext,
      EnterDate: { ...this.state.creditNoteContext.EnterDate, Value: date }
    });
  }

  callUpdateContext = () => {
    this.props.updateCreditNoteContext(this.state.creditNoteContext);
  }

  onLookupToggle = () => {
    this.props.onToggle(!this.props.open);
  }

  isApplyFiltersDisabled = () => {
    const { CustomerId, SalesEntity } = this.state.creditNoteContext;
    const { loading } = this.props;

    return (isNull(CustomerId.Value) || isNull(SalesEntity.Value) || loading);
  }

  render(): React.ReactNode {
    const { classes, open, onToggle } = this.props;
    const { CustomerId, SalesEntity, CustomerPurchaseOrder, Period, EnterDate, Warehouse, Invoice, CustomerCreditReason } = this.state.creditNoteContext;
    const { loading } = this.state;

    return (
      <LookupPanel open={open} setOpen={onToggle}>
        <div className={styles.lookupInner}>
          <Scrollbars>
            <Paper style={inlineStyles.lookupFieldsPaperContainer} square={true}>
              <BasicLookupActionField
                style={inlineStyles.basicLookupField}
                placeholder={'Entity'}
                label={'Entity'}
                size={'large'}
                lookupName={'SalesEntity'}
                value={SalesEntity}
                onSelectedItemChange={this.selectSalesEntity}
                suppressDescription={true}
              />
              <BasicLookupActionField
                style={inlineStyles.basicLookupField}
                placeholder={'Warehouse'}
                label={'Warehouse'}
                size={'large'}
                lookupName={'WarehouseEntity'}
                value={Warehouse}
                onSelectedItemChange={this.selectWarehouse}
                suppressDescription={true}
              />
              <SelectContainer
                label={'Period'}
                width={'100%'}
                value={(Period || { Value: 1 }).Value || 1}
                apiPrefs={({
                  path: '/CustomTypes/Lookup/Period',
                  method: 'GET'
                })}
                onChange={this.selectPeriod}
              />
              <BasicLookupActionField
                style={inlineStyles.basicLookupField}
                placeholder={'Invoice'}
                label={'Invoice'}
                size={'large'}
                lookupName={'SalesInvoice'}
                params={SalesEntity && SalesEntity.Value ? { Entity: SalesEntity.Value } : null}
                value={Invoice}
                onSelectedItemChange={this.selectInvoice}
                suppressDescription={true}
                ref={(ref) => this.invoiceLookup = ref}
              />
              <DateTextField
                label={'Date'}
                value={(EnterDate || {}).Value}
                onChange={this.selectDate}
                size={'large'}
                style={{ width: '130%' }}
              />
            </Paper>
            <Paper style={inlineStyles.recallLookupContainerStyle} square={true}>
              <BasicLookupActionField
                style={inlineStyles.basicLookupField}
                placeholder={'Customer'}
                label={''}
                size={'large'}
                lookupName={'Customer'}
                value={CustomerId}
                onSelectedItemChange={this.selectCustomer}
                suppressDescription={true}
              />
              <TextField
                style={inlineStyles.topLookupFields}
                placeholder={'Customer Purchase Order'}
                label={''}
                size={'large'}
                value={CustomerPurchaseOrder && CustomerPurchaseOrder.Value || ''}
                readOnly={true}
              />
              <BasicLookupActionField
                style={inlineStyles.basicLookupField}
                placeholder={'Reason'}
                label={''}
                size={'large'}
                lookupName={'CustomerCreditReason'}
                value={CustomerCreditReason}
                onSelectedItemChange={this.selectReason}
                suppressDescription={true}
              />
            </Paper>
          </Scrollbars>
          <Paper style={inlineStyles.commitActionPaperContainer} square={true}>
            <Button onClick={this.applyContext}
              disabled={this.isApplyFiltersDisabled()}
              variant={'contained'}
              className={classes.commitActionButton}>
              <DoneAllIcon style={{ color: this.isApplyFiltersDisabled() ? '' : '#006633' }} /> APPLY
            </Button>
          </Paper>
          {loading ?
            <div style={inlineStyles.preLoaderWrapperCustomer}>
              <CircularProgress
                size={CircularProgressSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />
            </div> : ''
          }
        </div>
      </LookupPanel>
    );
  }
}

const mapStateToProps = (state) => ({
  open: uiSelectors.isCreditNotesLookupPanelOpen(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onToggle: uiActions.toggleCreditNotesLookupPanel,
  },
  dispatch);

const CreditNotesLookupPanel = withStyles(inlineStyles, { index: 1 })(CreditNotesLookupPanelComponent);

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotesLookupPanel);
