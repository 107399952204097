import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (productId) => ({ productId }),
  reset: () => null,
  asyncs: {
    search: ({ SearchText, Sort, Type, CheckProductActive, CheckSupplierProduct, AlternateProductCheck }) => ({ SearchText, Sort, BatchPage: 1, Type, CheckProductActive, CheckSupplierProduct, AlternateProductCheck }),
    searchById: ({ ProductId }) => ({ ProductId }),
    fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
    fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
  }
}, 'productLookup')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  selectedDetail: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: ''
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };


    case types.setSelected:
      const selected = action.data.productId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);

    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action)
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const products = result.products.map((combinedObject) => combinedObject.inlineObject);
        const schemas = result.products.map((combinedObject) => combinedObject.schema);
        return {
          ...data,
          list: products,
          schemas: schemas,
          nextPage: result.nextPage && result.currPage + 1,
          prevPage: result.prevPage && result.currPage - 1,
          currPage: result.currPage
        };
      })
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const customers = result.products.map((combinedObject) => combinedObject.inlineObject);
        const schemas = result.products.map((combinedObject) => combinedObject.schema);
        const newRemoval = result.products.length;
        const newList = data.list.concat(customers);
        const newSchemas = data.schemas.concat(schemas);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
          nextPage: result.nextPage && result.currPage + 1,
          prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
        };
      }, { fetch: true })

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const customers = result.products.map((combinedObject) => combinedObject.inlineObject);
        const schemas = result.products.map((combinedObject) => combinedObject.schema);
        const newList = customers.slice(0, data.removeWhenPrev).concat(data.list);
        const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
          schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
          prevPage: result.prevPage && result.currPage - 1,
          nextPage: (newList.length > data.pageSize * data.totalPages) && (result.currPage + data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
        };
      }, { fetch: true })
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
      return asyncOnError(state, action)
    case types.reset:
      return initialState;
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.SearchLookUp.productLookup,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ProductId === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ProductId.Value === data.selected)) || null,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
)

const syncSelector = {
  isLoading: state => state.SearchLookUp.productLookup.search_loading || state.SearchLookUp.productLookup.searchById_loading,
  loadingNextPage: state => state.SearchLookUp.productLookup.fetchNextPage_loading,
  loadingPrevPage: state => state.SearchLookUp.productLookup.fetchPrevPage_loading,
  pageSize: (state) => state.SearchLookUp.productLookup.data.pageSize,
  totalPages: (state) => state.SearchLookUp.productLookup.data.totalPages,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

