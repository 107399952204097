import * as React from 'react';
import { IDeliveryDetailsProps } from './DeliveryDetails.properties';
import FormView from 'components/FormView';
import DeliveryDetailFrom from './DeliveryDetails.form';
import { IWorksaleDeliveryModelFacade } from 'api/swaggerTypes';
import CancelIcon from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Operation } from 'utils/operations';
import { Inline, objectifyAddressForPost } from 'api/utils';
import { IObjectifiedWorksaleDeliveryDetailsResponse, IObjectifiedWorksaleDeliveryResponseModel, useCalculateDeliveryDetails, useCreateWorksaleDelivery, useFetchWorksaleDeliveryDefaults, useUpdateWorksaleDeliveryDetails } from 'api/Worksale/worksale';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';

const DeliveryDetails = (props: IDeliveryDetailsProps): JSX.Element => {
  const { formValues, dirty, deliveryDetail, operationMode, forwardedRef, changeFieldValue, changeSelectedTab, resetForm, formSyncErrors, onDefaultDeliveryChange } = props;
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const customerId = useSPContextSelector(Selectors.CustomerId);
  const { DeliveryType } = formValues || {};
  const { setActionBar } = React.useContext(ActionBarContext);
  const { mutateAsync: calculateDeliveryDetailsMutateAsync } = useCalculateDeliveryDetails();
  const { mutateAsync: updateWorksaleDeliveryDetailsMutateAsync } = useUpdateWorksaleDeliveryDetails();
  const { mutateAsync: createWorksaleDeliveryMutateAsync } = useCreateWorksaleDelivery();
  const { refetch } = useFetchWorksaleDeliveryDefaults(worksaleId);
  const [initialValues, setInitialValues] = React.useState<IWorksaleDeliveryModelFacade>();
  const [valuesSchema, setValuesSchema] = React.useState<Inline<IWorksaleDeliveryModelFacade>>();
  const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
  const isDetailDirty = dirty || internalDirty;

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      isDirty(): boolean {
        return isDetailDirty;
      },
      onOk(): void {
        handleFormSubmission().catch((err) => { console.warn(err); });
      },
      onCancel(callback?: () => void): void {
        if (callback) {
          callback();
        }
        setInternalDirty(false);
      }
    }),
    [isDetailDirty, handleFormSubmission, setInternalDirty]
  );

  React.useEffect(
    () => {

      if (worksaleId < 0) {
        changeSelectedTab('Lines');
      } else {
        setActionBarButtons();
        getInitialValues();
      }

      return () => {
        resetForm();
      };
    },
    []
  );

  async function handleFormSubmission(): Promise<void> {
    if (isValidData()) {
      if (operationMode === Operation.NEW) {
        addNewWorksaleDelivery();
      } else if (operationMode === Operation.EDIT) {
        updateDeliveryDetails();
      }
    }
  }

  async function getInitialValues(): Promise<void> {
    if (operationMode === Operation.NEW) {
      fetchDefaults();
    } else if (operationMode === Operation.EDIT) {
      setInitialValues({
        ...deliveryDetail.inlineObject,
        WorkSaleDeliveryId: Number(deliveryDetail.inlineObject.WorkSaleDeliveryId),
        Shipping: deliveryDetail.inlineObject.Instructions,
        WorksaleID: worksaleId,
        ProceedWithUpdatePrimaryDelivery: null,
        ProceedWithUpdateCustomerDelivery: null
      } as any);
      setValuesSchema({ ...deliveryDetail.schema, WorkSaleDeliveryId: { ...deliveryDetail.schema.WorkSaleDeliveryId, Value: Number(deliveryDetail.schema.WorkSaleDeliveryId.Value) } } as any);
    }
  }

  async function fetchDefaults(): Promise<void> {
    const response = await refetch();
    if (!response?.data?.Forms) {
      const { WorksaleDelivery } = response.data;
      if (WorksaleDelivery) {
        setInitialValues({ ...WorksaleDelivery.inlineObject, WorksaleID: worksaleId, ProceedWithUpdatePrimaryDelivery: null, ProceedWithUpdateCustomerDelivery: null } as any);
        setValuesSchema(WorksaleDelivery.schema as any);
      }
    }
  }

  async function updateWorksaleDeliveryDetailsAndValidate(SiteCode: string, saveDeliveryDetails: boolean, query: IWorksaleDeliveryModelFacade & { address: any }): Promise<IObjectifiedWorksaleDeliveryDetailsResponse> {
    const data = {
      SiteCode,
      ...query,
      ...objectifyAddressForPost(query.address, '', ['', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
      urlQueryParams: {
        SaveDeliveryDetails: saveDeliveryDetails ? 'True' : 'False',
      }
    } as any;

    return updateWorksaleDeliveryDetailsMutateAsync(data);
  }

  async function updateDeliveryDetails(): Promise<void> {
    const response = await updateWorksaleDeliveryDetailsAndValidate(initialValues.SiteCode, formValues.SaveDelivery, { ...formValues } as any);

    if (response.Status && response.WorksaleDelivery) {
      const { WorksaleDelivery } = response;
      if (WorksaleDelivery) {
        setInitialValues(WorksaleDelivery.inlineObject as any);
        setValuesSchema(WorksaleDelivery.schema as any);
        showSnackBar({ variant: 'success', message: 'Delivery Details successfully updated.' });
        if (WorksaleDelivery.inlineObject.Primary) {
          onDefaultDeliveryChange();
        }
        changeSelectedTab('Deliveries');
      }
      setInternalDirty(false);
    } else if (!response.Status && !response.Forms) {
      setInitialValues({ ...formValues, ProceedWithUpdatePrimaryDelivery: null, ProceedWithUpdateCustomerDelivery: null } as any);
      showSnackBar({ variant: 'error', message: 'Details failed to update.' });
    } else {
      setInitialValues({ ...formValues, ProceedWithUpdatePrimaryDelivery: null, ProceedWithUpdateCustomerDelivery: null } as any);
    }
  }

  async function addNewWorksaleDeliveryAndValidate(WorksaleId: number, saveDeliveryDetails: boolean, query: IWorksaleDeliveryModelFacade & { address: any }): Promise<IObjectifiedWorksaleDeliveryDetailsResponse> {
    const data = {
      WorksaleId,
      ...query,
      ...objectifyAddressForPost(query.address, '', ['', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
      urlQueryParams: {
        SaveDeliveryDetails: saveDeliveryDetails ? 'True' : 'False',
      }
    } as any;

    return createWorksaleDeliveryMutateAsync(data);
  }

  async function addNewWorksaleDelivery(): Promise<void> {
    const response = await addNewWorksaleDeliveryAndValidate(worksaleId, formValues.SaveDelivery, formValues as any);

    if (response.Status && response.WorksaleDelivery) {
      const { WorksaleDelivery } = response;
      if (WorksaleDelivery) {
        setInitialValues(WorksaleDelivery.inlineObject as any);
        setValuesSchema(WorksaleDelivery.schema as any);
        showSnackBar({ variant: 'success', message: 'Details successfully updated.' });
        if (WorksaleDelivery.inlineObject.Primary) {
          onDefaultDeliveryChange();
        }
        changeSelectedTab('Deliveries');
      }
      setInternalDirty(false);
    } else if (!response.Status && !response.Forms) {
      setInitialValues({ ...formValues, ProceedWithUpdatePrimaryDelivery: null, ProceedWithUpdateCustomerDelivery: null } as any);
      showSnackBar({ variant: 'error', message: 'Details failed to update.' });
    } else {
      setInitialValues({ ...formValues, ProceedWithUpdatePrimaryDelivery: null, ProceedWithUpdateCustomerDelivery: null } as any);
    }
  }

  function setActionBarButtons(): void {
    setActionBar({
      leftIcons: [],
      centerIcons: [],
      rightIcons: [
        {
          label: 'Cancel',
          Icon: CancelIcon,
          iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
          action: 'DeliveryDetailsCancel',
        },
        {
          label: 'Ok',
          Icon: CheckCircle,
          action: 'DeliveryDetailsOk',
        }
      ]
    });
  }

  async function deliveryTypeOnChange(deliveryType: any): Promise<void> {
    if (deliveryType[0] === '2') {
      const newValues = { ...formValues, address: null, FreightCode: null, FreightType: null, Carrier: null, FreightAccount: null, Shipping: null };
      if (changeFieldValue && formValues) {
        Object.keys(formValues).forEach((key) => {
          changeFieldValue(key, newValues[key]);
        });
      }
    }
    setInternalDirty(true);
  }

  async function freightCodeOnChange(freightCode: any): Promise<void> {
    if (initialValues?.SiteCode && freightCode?.Code && deliveryDetail?.inlineObject?.FreightCode !== freightCode.Code) {
      const response = await calculateDeliveryDetailsAndValidate(worksaleId, 'FreightCode' as keyof IWorksaleDeliveryModelFacade, { ...formValues, FreightCode: freightCode.Code } as any, initialValues?.SiteCode);
      if (response && response.Status) {
        const { WorksaleDelivery } = response;
        if (WorksaleDelivery) {
          setInitialValues({ ...formValues, FreightCode: freightCode.Code, FreightType: WorksaleDelivery.inlineObject.FreightType, Carrier: WorksaleDelivery.inlineObject.Carrier } as any);
        }
      }
    }
  }

  async function calculateDeliveryDetailsAndValidate(WorksaleId: number, changedField: keyof IWorksaleDeliveryModelFacade, deliveryDetails: IWorksaleDeliveryModelFacade & { address: any }, SiteCode?: string): Promise<IObjectifiedWorksaleDeliveryResponseModel> {
    const data = {
      WorksaleId,
      ...deliveryDetails,
      ...objectifyAddressForPost(deliveryDetails.address, '', ['', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
      urlQueryParams: {
        SiteCode: SiteCode ? SiteCode.toString() : '',
        ChangedField: changedField
      }
    } as any;

    const response = await calculateDeliveryDetailsMutateAsync(data);

    if (response.Status) {
      setInternalDirty(true);

      return response;
    }

    return null;
  }

  function isValidData(): boolean {
    if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      return false;
    }

    return true;
  }

  return (
    <React.Fragment>
      <FormView
        customerId={Number(customerId)}
        formName={'DeliveryDetails'}
        schema={DeliveryDetailFrom}
        initialValues={initialValues}
        operationMode={operationMode}
        valuesSchema={valuesSchema}
        fieldExtensions={{
          SiteCode: {
            disabled: operationMode === Operation.EDIT ? true : false,
          },
          Primary: {
            disabled: operationMode === Operation.EDIT && deliveryDetail.inlineObject && deliveryDetail.inlineObject.Primary,
          },
          address: {
            disabled: DeliveryType !== '1'
          },
          FreightCode: {
            disabled: DeliveryType !== '1',
            onSelectedItemChange: (value: any) => (freightCodeOnChange(value))
          },
          FreightType: {
            disabled: DeliveryType !== '1',
          },
          Carrier: {
            disabled: DeliveryType !== '1',
          },
          FreightAccount: {
            disabled: DeliveryType !== '1',
          },
          Shipping: {
            disabled: DeliveryType !== '1',
          },
          DeliveryType: {
            onChange: (value: any) => (deliveryTypeOnChange(value))
          }
        }}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(DeliveryDetails);
