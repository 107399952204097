import { fetchGet, fetchPut } from '../utils'

export const getNote = (data) => {
  const { api } = data
  return fetchGet(api, null)
    .then((result) => (result && {noteData: result}))
}

export const setNote = (data) => {
  const { api, noteData } = data
  return fetchPut(api, `"${noteData}"` )
    .then((result) => (result && {noteData: result}))
}
