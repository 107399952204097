import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset } from 'redux-form';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';
import QuoteConfirmation from './QuoteConfirmation';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  quoteDocumentOutputOptions: documentOutputSelectors.options('CN', 'QuotePrinter')(state),
  dirty: isDirty('QuoteConfirmationForm')(state),
  values: getFormValues('QuoteConfirmationForm')(state),
  isValid: isValid('QuoteConfirmationForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset('QuoteConfirmationForm'),
    setQuoteDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('CN', 'QuotePrinter', options)
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuoteConfirmation);
