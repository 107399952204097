import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { getFormValues, isDirty, isValid, getFormSyncErrors, reset } from 'redux-form';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import {
    selectors as activitySelectors,
    actions as activityActions
} from 'ducks/activityMaintenance/activity';
import { selectors as configSelectors } from 'ducks/appConfig';

import ActivityDetail from './ActivityDetails';

const FORM_NAME = 'ActivityDetails';

const mapStateToProps = (state: IApplicationState) => ({
    values: getFormValues(FORM_NAME)(state),
    isValid: isValid(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    operationMode: operationSelectors.operationMode(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedForm: formSelectors.selected(state),
    isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
    activityData: activitySelectors.activityData(state),
    selectedActivity: activitySelectors.selected(state),
    selectedActivitySchema: activitySelectors.selectedSchema(state),
    dirty: isDirty(FORM_NAME)(state),
    isFormLoading: activitySelectors.isFormLoading(state),
    isV2: configSelectors.isV2(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeOperationMode: operationActions.changeOperationMode,
        createActivity: activityActions.create,
        updateActivity: activityActions.update,
        deleteActivity: activityActions.delete
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetail);
