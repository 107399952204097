import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import { IPaymentEntryProps } from './PaymentEntry.properties';
import styles from './PaymentEntry.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import { default as MUIButton } from '@material-ui/core/Button';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { summaryTableData } from './PaymentEntry.constants';
import { IPaymentLineFacade, IPaymentDetailFacade, IUpdatePaymentLineDetailFacade, IPaymentAmountFacade } from 'api/swaggerTypes';
import classNames from 'classnames';

import SummaryTable from 'components/common/SummaryTable/SummaryTable';
import { isNull } from 'utils/utils';
import { ObjectifiedPaymentAmountResponse, ObjectifiedPaymentDetail, ObjectifiedPaymentDetailsResponse } from 'api/Worksale/payment';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import ChequeDetailsForm from './ChequeDetailsForm';
import CashDetailsForm from './CashDetailsForm';
import GenericDetailsForm from './GenericDetailsForm';
import EftDetailsForm from './EftDetailsForm';
import CreditDetailsForm from './CreditDetailsForm';
import { Inline } from 'api/utils';
import IEftDetailsForm from './IEftDetailsForm';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';
import { ILookupOption } from 'api/customType';
import { Operation } from 'utils/operations';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';

const emptyLookup = { Code: '', Label: '' };

function PaymentEntry(props: IPaymentEntryProps): JSX.Element {
  const {
    classes, open, initialValues,
    onApply, onCancel, onAddNewPaymentLine, onCalculatePaymentLineDetails, onUpdatePaymentLineDetails, onDeletePaymentLine, changeConfirmationDialog, onCalculatePaymentAmount } = props;

  const DisableCashLocation = initialValues?.Header?.schema?.CashLocation?.ReadOnly;

  const processValidationForms = React.useContext(ProcessValidationFormsContext);

  const [values, setValues] = React.useState<ObjectifiedPaymentDetail>(initialValues);
  const [context, setContext] = React.useState<IPaymentDetailFacade['Context']>();
  const [header, setHeader] = React.useState<IPaymentDetailFacade['Header']>();
  const [allowOnAccountPayment, setAllowOnAccountPayment] = React.useState<boolean>();
  const [offsetCreditBalance, setOffsetCreditBalance] = React.useState<boolean>();
  const [newLine, setNewLine] = React.useState<IPaymentDetailFacade['NewLine']>();
  const [lines, setLines] = React.useState<IPaymentLineFacade[]>([]);
  const [amount, setAmount] = React.useState(0);
  const [bankingEntity, setBankingEntity] = React.useState<ILookupOption>({ ...emptyLookup });
  const [cashLocation, setCashLocation] = React.useState<ILookupOption>({ ...emptyLookup });
  const [paymentCode, setPaymentCode] = React.useState<ILookupOption>({ ...emptyLookup });

  const [paymentLineDetail, setPaymentLineDetail] = React.useState<IPaymentLineFacade>();
  const [paymentLineDetailSchema, setPaymentLineDetailSchema] = React.useState<Inline<IPaymentLineFacade>>();
  const [genericDetailsOpen, setGenericDetailsOpen] = React.useState(false);
  const [genericDetailsTitle, setGenericDetailsTitle] = React.useState('');
  const [cashDetailsOpen, setCashDetailsOpen] = React.useState(false);
  const [chequeDetailsOpen, setChequeDetailsOpen] = React.useState(false);
  const [eftDetailsOpen, setEftDetailsOpen] = React.useState(false);
  const [ieftDetailsOpen, setIEftDetailsOpen] = React.useState(false);
  const [creditDetailsOpen, setCreditDetailsOpen] = React.useState(false);

  const [operationMode, setOperationMode] = React.useState<Operation>(Operation.BROWSE);

  const paymentCodeFieldRef = React.useRef<ITextFieldHandle>();
  const amountFieldRef = React.useRef<ITextFieldHandle>();

  const valuesRef = React.useRef(null);
  valuesRef.current = values;

  React.useEffect(
    () => {
      setValues(initialValues);
    },
    [initialValues]
  );

  React.useEffect(
    () => {
      if (header && header.PaymentTotal < header.TransactionTotal && lines && paymentCodeFieldRef && paymentCodeFieldRef.current) {
        paymentCodeFieldRef.current.focus();
      }
    },
    [lines]
  );

  React.useEffect(
    () => {
      if (values && values.Header) {
        const newHeader = values.Header.inlineObject;
        const newHeaderSchema = values.Header.schema;
        setHeader(newHeader);
        if (newHeader) {
          setBankingEntity({
            Label: newHeaderSchema.BankingEntity.Label ? newHeaderSchema.BankingEntity.Label : '',
            Code: newHeaderSchema.BankingEntity.Value ? newHeaderSchema.BankingEntity.Value : ''
          });
          setCashLocation({
            Label: newHeaderSchema.BankingEntity.Label ? newHeaderSchema.BankingEntity.Label : '',
            Code: newHeaderSchema.CashLocation.Value ? newHeaderSchema.CashLocation.Value.toString() : ''
          });
        } else {
          setBankingEntity({ ...emptyLookup });
          setCashLocation({ ...emptyLookup });
        }
      } else {
        setHeader(undefined);
        setBankingEntity({ ...emptyLookup });
        setCashLocation({ ...emptyLookup });
      }
      if (values && values.Context) {
        setContext(values.Context.inlineObject);
      } else {
        setContext(undefined);
      }
      if (values && values.inlineObject) {
        setAllowOnAccountPayment(values.inlineObject.AllowOnAccountPayment);
        setOffsetCreditBalance(values.inlineObject.OffsetCreditBalance);
      } else {
        setAllowOnAccountPayment(false);
        setOffsetCreditBalance(false);
      }
      if (values && values.NewLine) {
        const newNewLine = values.NewLine.inlineObject;
        setNewLine(newNewLine);
        if (newNewLine) {
          setPaymentCode({ Code: newNewLine.PaymentCode });
          setAmount(newNewLine.Amount);
        } else {
          setPaymentCode({ ...emptyLookup });
          setAmount(0);
        }
      } else {
        setNewLine(undefined);
        setPaymentCode({ ...emptyLookup });
        setAmount(0);
      }
      if (values && values.Lines) {
        setLines(values.Lines.map((line) => line.inlineObject));
      } else {
        setLines([]);
      }
    },
    [values]);

  React.useEffect(
    () => {
      if (open) {
        document.addEventListener('keydown', handleSubmitEnter);
      } else {
        document.removeEventListener('keydown', handleSubmitEnter);
      }
    },
    [open]
  );

  function confirmationActions(disableFlag: boolean): IActionItem[] {
    return [
      {
        title: 'Ok',
        disabled: disableFlag,
        listener: async () => {
          if (onApply) {
            handleConfirmationBox();
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            await onCancel();
          }
        }
      }
    ];
  }

  const NumberOfDecimalPlaces = 2;
  const SMALL_COLUMN_MIN_WIDTH = 75;
  const SMALL_COLUMN_DEFAULT_WIDTH = 100;
  const COMMAND_COLUMN_WIDTH = 75;
  const LARGE_COLUMN_MIN_WIDTH = 200;

  const isEntitySelected: boolean = !isNull(bankingEntity && bankingEntity.Code);
  const isPaymentEmpty: boolean = isNull(paymentCode && paymentCode.Code);
  const isAmountEmpty: boolean = (isNull(amount) || amount === 0);

  const handleSubmitEnter = React.useCallback(
    (event: any): void => {
      const isFocusInsideDialog = event.currentTarget.contains(event.target);
      if (isFocusInsideDialog && event.key === 'Enter') {
        event.preventDefault();
        if (valuesRef && valuesRef.current.Header.inlineObject.PaymentTotal >= valuesRef.current.Header.inlineObject.TransactionTotal) {
          if (onApply) {
            handleConfirmationBox();
          }
        }
      }
    },
    [valuesRef]);

  async function onUpdatePaymentLineDetailsAndValidate(query: IUpdatePaymentLineDetailFacade): Promise<ObjectifiedPaymentDetailsResponse> {
    const response = await onUpdatePaymentLineDetails(query);

    return processValidationForms(response, query, onUpdatePaymentLineDetailsAndValidate);
  }

  function detailsDialogBoxOpen(dialogBoxName: string, genericDescription: string): void {
    switch (dialogBoxName) {
      case 'GenericPaymentDetails':
        setGenericDetailsTitle(`${genericDescription} Details`);
        setGenericDetailsOpen(true);
        break;
      case 'CashPaymentDetails':
        setCashDetailsOpen(true);
        break;
      case 'ChequePaymentDetails':
        setChequeDetailsOpen(true);
        break;
      case 'ElectronicPaymentDetails':
        setEftDetailsOpen(true);
        break;
      case 'IntegratedElectronicPaymentDetails':
        setIEftDetailsOpen(true);
        break;
      case 'CreditCardPaymentDetails':
        setCreditDetailsOpen(true);
        break;
      default:
        return;
    }
  }

  async function showDetailsAndUpdate(formId: string, paymentLine: IPaymentLineFacade, schema: Inline<IPaymentLineFacade>): Promise<void> {
    setPaymentLineDetailSchema(schema);
    setPaymentLineDetail(paymentLine);
    setOperationMode(Operation.EDIT);
    detailsDialogBoxOpen(formId, paymentLine.PaymentDescription);
  }

  function handleCancelDetails(): void {
    setGenericDetailsOpen(false);
    setCashDetailsOpen(false);
    setChequeDetailsOpen(false);
    setEftDetailsOpen(false);
    setIEftDetailsOpen(false);
    setCreditDetailsOpen(false);
    setPaymentLineDetailSchema(undefined);
    setPaymentLineDetail(undefined);
    setOperationMode(Operation.BROWSE);
  }

  async function handleApplyDetails(line: IPaymentLineFacade): Promise<void> {
    const updateLineResponse = await onUpdatePaymentLineDetailsAndValidate({
      PaymentDetail: {
        ...values.inlineObject,
        Context: values.Context.inlineObject,
        Header: {
          ...header,
          BankingEntity: bankingEntity.Code,
          CashLocation: parseInt(cashLocation.Code),
        },
        NewLine: values.NewLine.inlineObject,
        Lines: values.Lines.map((l) => l.inlineObject)
      },
      UpdateLine: line,
    });

    if (updateLineResponse.Status && updateLineResponse.PaymentDetail) {
      setValues(updateLineResponse.PaymentDetail);
      setGenericDetailsOpen(false);
      setCashDetailsOpen(false);
      setChequeDetailsOpen(false);
      setEftDetailsOpen(false);
      setIEftDetailsOpen(false);
      setCreditDetailsOpen(false);
      setPaymentLineDetailSchema(undefined);
      setPaymentLineDetail(undefined);
      setOperationMode(Operation.BROWSE);
    }
  }

  async function onAddNewPaymentLineAndValidate(query: IPaymentDetailFacade): Promise<void> {
    let response = await onAddNewPaymentLine(query);
    const screenRequest = response.Forms ? response.Forms.find((form) => form.FormType === 'ScreenRequest') : undefined;
    if (!screenRequest) {
      response = await processValidationForms(response, query, onAddNewPaymentLineAndValidate);

      if (response.Status && response.PaymentDetail) {
        setValues(response.PaymentDetail);
      }

      return;
    }

    await showDetailsAndUpdate(
      screenRequest.FormId,
      response.PaymentDetail.NewLine.inlineObject,
      response.PaymentDetail.NewLine.schema);
  }

  async function onDeletePaymentLineAndValidate(query: IPaymentDetailFacade, PositionNumber: number): Promise<ObjectifiedPaymentDetailsResponse> {
    return processValidationForms(await onDeletePaymentLine(query, PositionNumber), query, async (newQuery) => onDeletePaymentLineAndValidate(newQuery, PositionNumber));
  }

  function blurPaymentEntryFields(): void {
    amountFieldRef.current.blur();
    paymentCodeFieldRef.current.blur();
  }

  async function handleAddLine(): Promise<void> {
    setOperationMode(Operation.NEW);
    if (onAddNewPaymentLine && onUpdatePaymentLineDetails) {
      await onAddNewPaymentLineAndValidate({
        Context: context,
        Header: {
          ...header,
          BankingEntity: bankingEntity.Code,
          CashLocation: parseInt(cashLocation.Code),
        },
        AllowOnAccountPayment: allowOnAccountPayment,
        OffsetCreditBalance: offsetCreditBalance,
        Lines: lines,
        NewLine: {
          ...newLine,
          PaymentCode: paymentCode.Code,
          PaymentDescription: paymentCode.Label,
          Amount: amount
        }
      });
      blurPaymentEntryFields();
    }
  }

  async function handleUpdateLine(e: { row: { data: IPaymentLineFacade } }): Promise<void> {
    const paymentLine = e.row.data;
    const schema = values.Lines.find((line) => line.inlineObject.PositionNumber === paymentLine.PositionNumber).schema;
    setPaymentLineDetailSchema(schema);
    setPaymentLineDetail(paymentLine);

    if (e.row.data.PaymentCode === 'IEFT') {
      setOperationMode(Operation.BROWSE);
      setIEftDetailsOpen(true);
    } else {
      setOperationMode(Operation.EDIT);
      detailsDialogBoxOpen(e.row.data.ScreenCode, paymentLine.PaymentDescription);
    }
  }

  async function handleRemoveLine(e: { row: { data: IPaymentLineFacade } }): Promise<void> {
    if (onDeletePaymentLine) {
      const response = await onDeletePaymentLineAndValidate(
        {
          Context: context,
          Header: {
            ...header,
            BankingEntity: bankingEntity.Code,
            CashLocation: parseInt(cashLocation.Code),
          },
          AllowOnAccountPayment: allowOnAccountPayment,
          OffsetCreditBalance: offsetCreditBalance,
          Lines: lines,
        },
        e.row.data.PositionNumber);

      if (response.Status && response.PaymentDetail) {
        setValues(response.PaymentDetail);
      }
    }
  }

  async function onCalculatePaymentAmountAndValidate(query: IPaymentAmountFacade): Promise<ObjectifiedPaymentAmountResponse> {
    return processValidationForms(await onCalculatePaymentAmount(query), query, async (newQuery) => onCalculatePaymentAmountAndValidate(newQuery));
  }

  async function setPaymentAmount(item: any): Promise<void> {
    if (item) {
      const response = await onCalculatePaymentAmountAndValidate({ PaymentCode: item.Code, Amount: amount });
      if (response.Status && response.PaymentAmount) {
        setPaymentCode(item);
        setAmount(response.PaymentAmount.inlineObject.Amount);
      }
    } else {
      setPaymentCode({...emptyLookup});
    }
  }

  async function handleConfirmationBox(): Promise<void> {
    if (!bankingEntity?.Code || !cashLocation?.Code) {// as these are required fields
      return;
    }

    if (changeConfirmationDialog) {
      changeConfirmationDialog({
        open: true,
        title: 'Question',
        message: 'Confirm Payment.\nAre you sure?',
        okLabel: 'Yes',
        showRefuse: true,
        showCancel: false,
        defaultBtnNo: '3',
        onRefuse: () => undefined,
        onOk: () => {
          onApply({
            Context: valuesRef.current.Context.inlineObject,
            Header: valuesRef.current.Header.inlineObject,
            AllowOnAccountPayment: valuesRef.current.inlineObject.AllowOnAccountPayment,
            OffsetCreditBalance: valuesRef.current.inlineObject.OffsetCreditBalance,
            Lines: valuesRef.current.Lines.map((line) => line.inlineObject)
          });
        }
      });
    }
  }

  const isApplyDisabled = (isAmountEmpty || isPaymentEmpty);

  function getModalContent(): JSX.Element {
    const onBankingEntitySelectionChanged = React.useCallback(
      (item) => {
        if (initialValues?.Header?.inlineObject?.BankingEntity !== item?.Code ||
          initialValues?.Header?.inlineObject?.CashLocation !== Number(cashLocation?.Code)) {
          setBankingEntity(item as any);
          setCashLocation(null);
        }
      },
      [initialValues, cashLocation]
    );

    return <div className={classes.mainDiv}>
      <div className={classes.formDiv}>
        <BasicLookupActionField
          required
          placeholder={''}
          label={'Banking entity'}
          size={'small'}
          lookupName={'Entity'}
          disabled={lines.length > 0}
          value={bankingEntity ? bankingEntity.Code : ''}
          onSelectedItemChange={onBankingEntitySelectionChanged}
        />
        <BasicLookupActionField
          required
          placeholder={''}
          label={'Cash Location'}
          size={'small'}
          lookupName={'CashLocation'}
          isEntityScoped={true}
          value={cashLocation ? cashLocation.Code : ''}
          disabled={!isEntitySelected || DisableCashLocation || lines.length > 0}
          params={isEntitySelected ? { Entity: bankingEntity.Code } : null}
          onSelectedItemChange={(item) => setCashLocation(item as any)}
        />
        <div className={classes.paymentLineDiv}>
          <BasicLookupActionField
            className={classes.paymentLookupField}
            placeholder={''}
            label={'Payment'}
            size={'small'}
            lookupName={'PaymentCode'}
            suppressDescription={true}
            onSelectedItemChange={setPaymentAmount}
            value={paymentCode ? paymentCode.Code : ''}
            ref={paymentCodeFieldRef}
          />
          <NumericField
            label={'Amount'}
            size={'medium'}
            value={amount}
            onBlur={setAmount}
            onKeyDown={handelKeyDown}
            ref={amountFieldRef}
          />
          <MUIButton
            onClick={handleAddLine}
            variant={'contained'}
            disabled={isApplyDisabled}
            className={classNames(classes.button, isApplyDisabled && classes.disabledBtn)}
          >
            <CheckCircleIcon style={{ color: 'green' }} />
            APPLY
          </MUIButton>
        </div>
        <DataGrid
          keyExpr={'LineNumber'}
          className={classes.paymentLineDataGrid}
          dataSource={lines}
          allowColumnReordering={true}
          repaintChangesOnly={true}
          noDataText=''
          remoteOperations={true}
          columnResizingMode={'nextColumn'}
          allowColumnResizing={true}
          editing={{
            allowAdding: false,
            allowDeleting: false,
            allowUpdating: false
          }}
          showBorders={false}
          sorting={{
            mode: 'none'
          }}
          hoverStateEnabled={true}
        >
          <Column
            dataField={'PaymentCode'}
            caption={'Payment'}
            allowEditing={false}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={SMALL_COLUMN_DEFAULT_WIDTH}
          />
          <Column
            dataField={'PaymentDescription'}
            caption={'Description'}
            allowEditing={false}
            minWidth={LARGE_COLUMN_MIN_WIDTH}
          />
          <Column
            dataField={'TotalAmount'}
            caption={'Amount'}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={SMALL_COLUMN_DEFAULT_WIDTH}
            alignment={'right'}
            calculateDisplayValue={
              (data: Record<string, unknown>) => Number(data.TotalAmount).toFixed(NumberOfDecimalPlaces)
            }
          />
          <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false}>
            <Button icon={'edit'} onClick={handleUpdateLine} hint={'Update Payment'} />
            <Button icon={'trash'} hint={'Delete Payment'} onClick={handleRemoveLine} disabled={(e) => e.row.data.PaymentCode === 'IEFT'} />
          </Column>
          <GroupPanel visible={false} />
          <SearchPanel visible={false} />
          <Scrolling mode={'virtual'} />
          <LoadPanel enabled={false} />
        </DataGrid>

      </div>
      <div className={classes.tableDiv}>

        <SummaryTable
          data={summaryTableData}
          Header={header}
        />
      </div>
    </div>;
  }

  function handelKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (!isApplyDisabled && event.key === 'Enter') {
      handleAddLine();
      blurPaymentEntryFields();
    }
  }

  return (
    <React.Fragment>
      <FormViewModal
        open={open}
        loading={false}
        title={'Payment Entry'}
        modalContent={getModalContent()}
        actions={confirmationActions(!(header?.OnAccount === 0))}
        dialogActionsButtons={true}
        dialogActionsShadow={false}
      />
      {genericDetailsOpen ?
        <GenericDetailsForm
          open={genericDetailsOpen}
          title={genericDetailsTitle}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
      {cashDetailsOpen ?
        <CashDetailsForm
          open={cashDetailsOpen}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
      {chequeDetailsOpen ?
        <ChequeDetailsForm
          open={chequeDetailsOpen}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
      {eftDetailsOpen ?
        <EftDetailsForm
          open={eftDetailsOpen}
          paymentDetail={{
            ...values.inlineObject,
            Context: values.Context.inlineObject,
            Header: values.Header.inlineObject,
            NewLine: values.NewLine.inlineObject,
            Lines: values.Lines.map((l) => l.inlineObject)
          }}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onCalculatePaymentLineDetails={onCalculatePaymentLineDetails}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
      {ieftDetailsOpen ?
        <IEftDetailsForm
          open={ieftDetailsOpen}
          operationMode={operationMode}
          paymentDetail={{
            ...values.inlineObject,
            Context: values.Context.inlineObject,
            Header: values.Header.inlineObject,
            NewLine: values.NewLine.inlineObject,
            Lines: values.Lines.map((l) => l.inlineObject)
          }}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onCalculatePaymentLineDetails={onCalculatePaymentLineDetails}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
      {creditDetailsOpen ?
        <CreditDetailsForm
          open={creditDetailsOpen}
          paymentDetail={{
            ...values.inlineObject,
            Context: values.Context.inlineObject,
            Header: values.Header.inlineObject,
            NewLine: values.NewLine.inlineObject,
            Lines: values.Lines.map((l) => l.inlineObject)
          }}
          initialValues={paymentLineDetail}
          schema={paymentLineDetailSchema}
          onCalculatePaymentLineDetails={onCalculatePaymentLineDetails}
          onApply={handleApplyDetails}
          onCancel={handleCancelDetails} /> : undefined}
    </React.Fragment>);
}

export default withStyles(styles, { index: 1 })(PaymentEntry);
