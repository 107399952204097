import * as React from 'react';
import FormView from '../../FormView';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from '../SummaryTableSchema.json';
import { ISalesOrderDeliveriesEnquiryProperties } from '../interfaces';

class SalesOrderDeliveriesEnquiry extends React.PureComponent<ISalesOrderDeliveriesEnquiryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/deliveries':
          onInitialLoad('Deliveries');
          break;
        case '/sales-order-enquiry/deliveries/delivery-details':
          onInitialLoad('DeliveryDetails');
          break;
        default:
          onInitialLoad('Deliveries');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedTab, deliveryDetails } = this.props;
    switch (selectedTab) {
      case 'DeliveryDetails':
        return deliveryDetails && deliveryDetails.values;
      default:
        return null;
    }
  };

  getValuesSchema = () => {
    const { selectedTab, deliveryDetails, loadingDeliverySummary } = this.props;
    switch (selectedTab) {
      case 'DeliveryDetails':
        return deliveryDetails && deliveryDetails.schema && !loadingDeliverySummary;
      default:
        return null;
    }
  };

  deliverySummary = (): React.ReactNode => {
    const { deliveryDetails, loadingDeliverySummary } = this.props;

    return <SummaryTable
      selectedDelivery={deliveryDetails && deliveryDetails.values}
      loadingDeliverySummary={loadingDeliverySummary}
      data={
        SummaryTableSchema.DeliveryDetails
      } />;
  };

  getSummaryTableRenderer = (): React.ReactNode => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'DeliveryDetails':
        return this.deliverySummary;
      default: return null;
    }
  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode } = this.props;
    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValuesSchema();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName='SalesOrderDeliveriesEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          valuesSchema={valuesSchema}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default SalesOrderDeliveriesEnquiry;
