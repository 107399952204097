import React from 'react';
import { FormSection, Field } from 'redux-form';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';

const WRTextField = ({ input, name, label, required, readOnly, placeholder, fullWidth, ...custom }, ) => (
  <TextField
    label={label}
    style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : {}}
    required={required}
    disabled={readOnly}
    title={!label ? placeholder : null}
    placeholder={placeholder}
    {...input}
    {...custom}
  />
);

class AddressSection extends React.Component {
  static defaultProps = {
    name: 'address'
  }

  handelBlur = () => {
    const { input } = this.props;
    if (input && input.onBlur) { input.onBlur() }
  }

  render() {

    const { name, readOnly, disabled } = this.props;

    const isReadOnly = readOnly || disabled;

    return (
      <FormSection name={name}>
        {this.props.type === 'extended' &&
          <Field
            name="addressee"
            component={WRTextField}
            readOnly={isReadOnly}
            size="large"
            placeholder={'Name of Addressee'}
            disableHelperText={true}
            onBlur={this.handelBlur}
            inputProps={{ maxLength: 35 }}
          />
        }
        <Field
          name="addressLine1"
          component={WRTextField}
          readOnly={isReadOnly}
          size="large"
          placeholder={'Address line 1'}
          disableHelperText={true}
          onBlur={this.handelBlur}
          inputProps={{ maxLength: 30 }}
        />
        <Field
          name="addressLine2"
          placeholder={'Address line 2'}
          component={WRTextField}
          readOnly={isReadOnly}
          size="large"
          disableHelperText={true}
          onBlur={this.handelBlur}
          inputProps={{ maxLength: 30 }}
        />
        <Field
          name="city"
          placeholder={'City'}
          component={WRTextField}
          readOnly={isReadOnly}
          size="large"
          disableHelperText={true}
          onBlur={this.handelBlur}
          inputProps={{ maxLength: 25 }}
        />
        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
          <Field
            name="state"
            component={WRTextField}
            readOnly={isReadOnly}
            placeholder={'State'}
            size={'large'}
            style={{ minWidth: 100, width: '60%' }}
            disableHelperText={true}
            onBlur={this.handelBlur}
            inputProps={{ maxLength: 8 }}
          />
          <Field
            name="postalCode"
            component={WRTextField}
            readOnly={isReadOnly}
            placeholder={'Postal code'}
            size={'large'}
            style={{ minWidth: 80, width: '35%' }}
            disableHelperText={true}
            onBlur={this.handelBlur}
            inputProps={{ maxLength: 8 }}
          />
        </div>
        {!this.props.hideCountry &&
          <Field
            name="country"
            placeholder={'Country'}
            label={'Country'}
            component={WRTextField}
            readOnly={isReadOnly}
            size="large"
            disableHelperText={true}
            onBlur={this.handelBlur}
            inputProps={{ maxLength: 25 }}
          />
        }
      </FormSection>
    )
  }
}

export default AddressSection;
