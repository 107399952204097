import { fetchGet, objectify } from './../utils'

export const getLineDetails = (query) => {
  const { SalesOrder, LineNumber } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Line/${LineNumber}`)
    .then((result) => {
      const objectified = objectify(result.SalesOrderLineDetails);
      return { schema: result.SalesOrderLineDetails, values: objectified }
    })
}

export const getLineSummary = (query) => {
  const { SalesOrder, LineNumber } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Line/${LineNumber}/OrderLineSummary`)
    .then((result) => (result && objectify(result.SalesOrderLineSummary)))
}