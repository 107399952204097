import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { ICreditNoteLinesProps } from '../interfaces';
import { OPERATIONS } from '../../../utils/operations';
import { isShallowEqual } from 'utils/utils';

class CreditNoteLines extends React.PureComponent<ICreditNoteLinesProps> {
  componentDidMount(): void {
    const { selectedTab, selectedLine, creditNoteContext } = this.props;
    if (selectedTab === 'CNLineDetails' && selectedLine) {
      this.props.getCreditNoteLineDetails(selectedLine.LineNumber);
    } else if (selectedTab === 'CNLineDetails' && !selectedLine) {
      this.props.createCreditNoteLine({ ...creditNoteContext, LeaveUnallocated: null });
    }
  }

  componentDidUpdate(prevProps: Readonly<ICreditNoteLinesProps>): void {
    const {
      values, operationMode,
      isLoading, initialValues
    } = this.props;
    //if any of the field update, we need to generate an update call.
    if (!isLoading && initialValues && prevProps && prevProps.values) {
      //fields changing by selection
      const {
        ProductId, CustomerCreditReason
      } = prevProps.values;

      if ((ProductId !== values.ProductId || CustomerCreditReason !== values.CustomerCreditReason) && values.ProductId) {
        this.props.updateCreditNoteLineDetails(values);
      }
    }
    if (operationMode !== prevProps.operationMode) {
      this.checkOperation(prevProps);
    }
  }

  checkOperation = (prevProps: Readonly<ICreditNoteLinesProps>) => {
    const { dirty, values, isValid, operationMode, saveCreditNoteLineDetails, selectedTab, applyResponse } = this.props;
    if (operationMode === OPERATIONS.SAVE && isValid && selectedTab === 'CNLineDetails') {
      saveCreditNoteLineDetails(values);
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if (operationMode === OPERATIONS.BROWSE) {
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if (operationMode === OPERATIONS.EDIT || operationMode === OPERATIONS.SAVE) {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }

    if (operationMode === OPERATIONS.SAVE && dirty && !isValid) {
      this.props.changeOperationMode(OPERATIONS.EDIT);
    }
    if (operationMode === OPERATIONS.CANCEL && prevProps.operationMode !== OPERATIONS.CANCEL && selectedTab === 'CNLineDetails') {
      this.cancelFormSubmission(prevProps);
    }
    if (operationMode === OPERATIONS.PROCESS && prevProps.operationMode !== OPERATIONS.PROCESS) {
      this.props.processCreditNote(applyResponse);
      this.props.changeOperationMode(OPERATIONS.BROWSE);
      // this.props.changeSelectedTab('CNPaymentDetails');
    }
  }

  cancelFormSubmission = (prevProps: Readonly<ICreditNoteLinesProps>) => {
    const { dirty, selectedLine } = this.props;
    if (dirty) {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
      this.props.changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          this.props.changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          this.props.cancelCreditNoteLineDetails(selectedLine && selectedLine.LineNumber);
          this.props.changeOperationMode(OPERATIONS.BROWSE);
          this.props.destroyForm();
          this.props.changeSelectedTab('CNLines');
          this.props.toggleCreditNotesLookupPanel(true);
        }
      });
    } else {
      this.props.cancelCreditNoteLineDetails(selectedLine && selectedLine.LineNumber);
      this.props.changeOperationMode(OPERATIONS.EDIT);
      this.props.changeSelectedTab('CNLines');
      this.props.destroyForm();
      this.props.toggleCreditNotesLookupPanel(true);
    }
  }

  getInitialValues = () => {
    const { selectedTab, lineDetails, lineDetailsSchema } = this.props;
    switch (selectedTab) {
      case 'CNLineDetails': return { inlineObject: lineDetails, schema: lineDetailsSchema };
      default:
        return null;
    }
  }

  handleOnBlur = () => {
    const { updateCreditNoteLineDetails, values, initialValues } = this.props;
    if (!isShallowEqual(initialValues, values) && updateCreditNoteLineDetails && values.ProductId) {
      updateCreditNoteLineDetails(values);
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, notifications, summaryTableRenderer } = this.props;
    const { inlineObject, schema } = this.getInitialValues() || { inlineObject: null, schema: null };

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={notifications}
          entity={'Credit Line'}
        />
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={inlineObject}
          valuesSchema={schema}
          operationMode={operationMode}
          onBlur={this.handleOnBlur}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default CreditNoteLines;
