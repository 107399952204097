import React, { useEffect, useRef, useState } from 'react';
import { FormSection, Field } from 'redux-form';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { ICommonFieldProperties } from 'components/FormView';
import { Subheader } from '@markinson/uicomponents-v2';

const PLACE_ADDRESS_COMPONENTS = {
    ZIP_CODE: 'postal_code',
    COUNTRY: 'country',
    STATE: 'administrative_area_level_1',
    CITY: 'locality',
    TOWN: 'sublocality_level_1',
    AREA: 'sublocality_level_2',
    ROUTE: 'route',
    STREET_NUMBER: 'street_number'
};

interface IGooglePlacesData {
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    formattedAddress?: string;
}

interface ITextFieldProps extends ICommonFieldProperties {
    input?: any;
}

interface IAutoCompleteTextFieldProps extends ITextFieldProps {
    placeChanged?(data: IGooglePlacesData): void;
}

const parseGooglePlacesData = (place: google.maps.places.PlaceResult): IGooglePlacesData => {
    const details = {
        city: '',
        country: '',
        postalCode: '',
        state: '',
        formattedAddress: ''
    };

    if (!place.address_components) {
        return details;
    }

    place.address_components.forEach((addressComponent) => {
        const type = addressComponent.types[0];
        switch (type) {
            case PLACE_ADDRESS_COMPONENTS.STREET_NUMBER:
                details.formattedAddress = `${addressComponent.short_name} ${details.formattedAddress}`;
                break;
            case PLACE_ADDRESS_COMPONENTS.ROUTE:
                details.formattedAddress += addressComponent.short_name;
                break;
            case PLACE_ADDRESS_COMPONENTS.CITY:
                details.city = addressComponent.long_name;
                break;
            case PLACE_ADDRESS_COMPONENTS.COUNTRY:
                details.country = addressComponent.long_name;
                break;
            case PLACE_ADDRESS_COMPONENTS.ZIP_CODE:
                details.postalCode = addressComponent.long_name;
                break;
            case PLACE_ADDRESS_COMPONENTS.STATE:
                details.state = addressComponent.short_name;
                break;
            default:
        }
    });

    return details;
};

const WRAutoCompleteTextField = ({ input, name, label, required, readOnly, placeholder, fullWidth, placeChanged, ...custom }: IAutoCompleteTextFieldProps) => {
    const ref = useRef<HTMLInputElement>();
    const [autoCompleteWidget, setAutoCompleteWidget] = useState<
        google.maps.places.Autocomplete | undefined
    >(undefined);

    useEffect(
        () => {
            if (ref.current && !autoCompleteWidget) {
                setAutoCompleteWidget(
                    new google.maps.places.Autocomplete(ref.current, {
                        types: ['address'],
                        componentRestrictions: { country: ['au'] },
                        fields: ['address_components', 'formatted_address'],
                    })
                );
            }
            if (autoCompleteWidget) {
                autoCompleteWidget.addListener('place_changed', handlePlaceChanged);
            }
        },
        [ref, autoCompleteWidget]
    );

    useEffect(
        () => {
            (window as any).gm_authFailure = () => {
                ref.current.disabled = false;
                ref.current.placeholder = 'Address line 1';
                ref.current.style.backgroundImage = '';
                ref.current.focus();
            };
        },
        []
    );

    const handlePlaceChanged = () => {
        const place = autoCompleteWidget.getPlace();

        const addressDetail = parseGooglePlacesData(place);

        if (placeChanged) {
            placeChanged(addressDetail);
        }
    };

    return (
        <TextField
            label={label}
            style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : {}}
            required={required}
            disabled={readOnly}
            title={!label ? placeholder : null}
            placeholder={placeholder}
            inputRef={ref}
            {...input}
            {...custom}
        />
    );
};

const WRTextField = ({ input, name, label, required, readOnly, placeholder, fullWidth, ...custom }: ITextFieldProps) => (
    <TextField
        label={label}
        style={fullWidth ? { minWidth: 100, maxWidth: '100%' } : {}}
        required={required}
        disabled={readOnly}
        title={!label ? placeholder : null}
        placeholder={placeholder}
        {...input}
        {...custom}
    />
);

interface IGoogleAddressBlockProps {
    type: string;
    label?: string;
    name: string;
    readOnly?: boolean;
    hideCountry?: boolean;
    disabled?: boolean;
    value?: any;
    onChange?(value: any): void;
}

export default function GoogleAddressBlock(props: IGoogleAddressBlockProps): JSX.Element {
    const { value, type, label, name, readOnly = false, hideCountry = false, disabled = false, onChange } = props;

    const isReadOnly = readOnly || disabled;

    const handlePlaceChanged = (data: IGooglePlacesData) => {
        onChange({ ...data, addressLine1: data.formattedAddress, addressee: value.addressee });
    };

    return (
        <FormSection name={name} >
            {label && (<Subheader style={{ paddingLeft: 'unset' }}>
                {label}
            </Subheader>)}
            {type === 'extended' &&
                <Field
                    name='addressee'
                    component={WRTextField}
                    readOnly={isReadOnly}
                    size='large'
                    placeholder={'Name of Addressee'}
                    disableHelperText={true}
                    inputProps={{ maxLength: 35 }}
                />
            }
            <Field
                name='addressLine1'
                component={WRAutoCompleteTextField}
                readOnly={isReadOnly}
                size='large'
                placeholder={'Address line 1'}
                disableHelperText={true}
                placeChanged={handlePlaceChanged}
                inputProps={{ maxLength: 30 }}
            />
            <Field
                name='addressLine2'
                placeholder={'Address line 2'}
                component={WRTextField}
                readOnly={isReadOnly}
                size='large'
                disableHelperText={true}
                inputProps={{ maxLength: 30 }}
            />
            <Field
                name='city'
                placeholder={'City'}
                component={WRTextField}
                readOnly={isReadOnly}
                size='large'
                disableHelperText={true}
                inputProps={{ maxLength: 25 }}
            />
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                <Field
                    name='state'
                    component={WRTextField}
                    readOnly={isReadOnly}
                    placeholder={'State'}
                    size={'large'}
                    style={{ minWidth: 100, width: '60%' }}
                    disableHelperText={true}
                    inputProps={{ maxLength: 8 }}
                />
                <Field
                    name='postalCode'
                    component={WRTextField}
                    readOnly={isReadOnly}
                    placeholder={'Postal code'}
                    size={'large'}
                    style={{ minWidth: 80, width: '35%' }}
                    disableHelperText={true}
                    inputProps={{ maxLength: 8 }}
                />
            </div>
            {!hideCountry &&
                <Field
                    name='country'
                    placeholder={'Country'}
                    component={WRTextField}
                    readOnly={isReadOnly}
                    size='large'
                    disableHelperText={true}
                    inputProps={{ maxLength: 25 }}
                />
            }
        </FormSection>
    );
}
