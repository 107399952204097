// import { isDirty, reset, getFormValues } from 'redux-form';
import { reset } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as pickSLipSelectors, actions as pickSLipActions } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';
import { actions as slipLinesActions } from 'ducks/pickSlipEnquiry/slipLines';
import { actions as bomActions } from 'ducks/pickSlipEnquiry/bom';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as staffSelectors } from 'ducks/staff';
import { selectors as slipDetailsSelectos, actions as slipDetailsActons } from 'ducks/pickSlipEnquiry/slipDetails';
import PickSlipEnquiry from './PickSlipEnquiry';

const FORM_NAME = 'SlipDetails';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedPickSlip: pickSLipSelectors.selected(state),
  selectedSupplierSchema: pickSLipSelectors.selectedSchema(state),
  selectedForm: formSelectors.selected(state),
  selectedTab: uiSelectors.selectedTab(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedDespatchId: pickSLipSelectors.selectedDespatchId(state),
  pickSlipDetails: slipDetailsSelectos.slipDetails(state),
  staff: staffSelectors.staff(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    search: pickSLipActions.search,
    searchById: pickSLipActions.searchById,
    getSlipLineDetails: slipLinesActions.fetchSlipLineDetails,
    getSlipLineDetailsSummary: slipLinesActions.fetchSlipLineDetailsSummary,
    getSlipLineBOMDetails: bomActions.fetchDetails,
    getSlipLineBOMDetailsSummary: bomActions.fetchSummary,
    changeSelectedSupplier: pickSLipActions.setSelected,
    closeMenu: uiActions.closeMainMenu,
    fetchSlipDetails: slipDetailsActons.fetch,
    getPickSlipSummary: slipDetailsActons.fetchSummary,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PickSlipEnquiry);
