
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import {
    selectors as activitySchedulerSelectors
} from 'ducks/serviceActivityScheduling/serviceActivities';
import SelectJobPanel from './SelectJobPanel';
import { ISelectJobPanelProps } from 'components/ServiceActivityScheduling/interfaces';

type IStateProps = Pick<ISelectJobPanelProps,
    'data' | 'loading'>;
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    data: activitySchedulerSelectors.reviewJobsData(state),
    loading: activitySchedulerSelectors.jobSummaryLoading(state),
});

export default connect(mapStateToProps, {})(SelectJobPanel);
