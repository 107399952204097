import * as React from 'react';
import { Paper, CircularProgress } from '@material-ui/core';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import { DnDSource, SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import { customerMaintenanceDrawerBackground } from '../../../themes/colors';
import DataList from './DataList';
import LookupControlHeader from './LookupControlHeader';
import FormFiltersLookUpHeader from './FormFiltersLookUpControlHeader';
import * as styles from './LookUpList.scss';
import { createStyles } from '@material-ui/core/styles';
import { CircularProgressSize, ENTER_KEY } from '../../../utils/constants';
import { FormViewField } from 'components/FormView';
import FormHeader from './FormHeader';

const inlineStyles = createStyles({
  lookupContainerStyle: {
    top: '64px',
    backgroundColor: customerMaintenanceDrawerBackground,
    height: '100%',
    width: 300
  },
  browseLookupContainerStyle: {
    top: '64px',
    backgroundColor: '#cccccc',
    height: '100%',
    width: 300
  },
  resultCount: {
    paddingTop: 0,
  },
  hrStyle: {
    backgroundColor: '#777777',
    height: '1px',
    marginBottom: '8px'
  },
  preLoader: {
    margin: '10px 130px'
  }
});

export interface ILookUpListProperties<T extends object, U extends object> {
  searchText: string;
  searchPlaceholder: string;
  totalPages: number;
  isLoading: boolean;
  pageSize: number;
  hideFilterButton?: boolean;
  loadingPrevPage: boolean;
  loadingNextPage: boolean;
  customFilterRow?: {
    formName: string;
    parameters: FormViewField[];
    validate?(values: any): boolean;
  };
  filterHeader?: {
    formName: string;
    applyOnStart?: boolean;
    parameters: FormViewField[];
    validate?(values: any): boolean;
  };
  schedulerData?: SchedulerData;
  dragAndDropSource?: DnDSource;
  suppressSubHeader?: boolean;
  appliedFilters?: any;
  selectedSortFilter: string;
  selectedSortDirection: string;
  prevPage: number;
  nextPage: number;
  keyField: keyof T;
  listItems: T[];
  selectedRecordId: any;
  fieldsToDisplay: [keyof T, keyof T, keyof T, keyof T] | [keyof T, keyof T, keyof T, keyof T, keyof T, keyof T];
  cascadingListKey?: keyof T;
  cascadingListKeyField?: keyof U;
  cascadingListFields?: [keyof U, keyof U, keyof U, keyof U] | [keyof U, keyof U, keyof U, keyof U, keyof U, keyof U];
  sortingFilters: { filter: keyof T; label: string }[];
  browseLookUp?: boolean;
  disabled?: boolean;
  lastLinePosition?: 'left' | 'right';
  newSchedulerEvent?(schedulerData: SchedulerData, slotId: any, slotName: any, start: any, end: any, type: any, item: any): void;
  changeSearchText?(searchText: string): void;
  searchAction?(searchText: string, sort: { Property: string; Direction: string }, filters?: any): void;
  fetchPrevPage?(sort: { Property: string; Direction: string }, filters?: any): void;
  fetchNextPage?(sort: { Property: string; Direction: string }, filters?: any): void;
  handleRecordChange?(recordId: any): void;
  renderCustomItemSection?(item: any): any;
  isItemDraggable?(item: any): boolean;
  isNextItemSelectionAllowed?(nextItem: T): boolean;
}

export interface ILookUpListState {
  searchText: string;
  filterDropDownOpen: boolean;
  selectedSortFilter: string;
  sortDirection: string;
}

class LookUpList<T extends object = any, U extends object = any> extends React.Component<ILookUpListProperties<T, U>, ILookUpListState> {

  constructor(props: Readonly<ILookUpListProperties<T, U>>) {
    super(props);
    const { searchText, selectedSortFilter, selectedSortDirection } = props;
    this.state = {
      searchText: searchText,
      filterDropDownOpen: false,
      selectedSortFilter: selectedSortFilter,
      sortDirection: selectedSortDirection,
    };
  }

  handleChangeSortingFilter = (_event: Event, value: string) => {
    this.setState({ selectedSortFilter: value });
  }

  componentDidUpdate(prevProps: Readonly<ILookUpListProperties<T, U>>): void {
    const { selectedSortFilter, searchText } = this.props;
    if (selectedSortFilter !== prevProps.selectedSortFilter) {
      this.setState({ selectedSortFilter });
    }
    if (searchText !== prevProps.searchText) {
      this.setState({ searchText });
    }
  }

  handleSortDirectionToggle = () => {
    this.setState((prevState) => ({
      sortDirection: prevState.sortDirection === 'Descending' ? 'Ascending' : 'Descending'
    }));
  }

  handleClose = () => {
    this.setState(() => ({
      filterDropDownOpen: false
    }));
  };

  callSearchAction = () => {
    const { searchAction } = this.props;
    const { selectedSortFilter, sortDirection } = this.state;
    if (searchAction) {
      searchAction(
        this.state.searchText,
        {
          Property: selectedSortFilter,
          Direction: sortDirection
        });
    }
    this.handleClose();
  }
  applyFilters = (filters?: any) => {
    const { searchAction, appliedFilters } = this.props;
    if (searchAction && (filters || appliedFilters)) {
      searchAction(
        this.state.searchText,
        null,
        filters || appliedFilters
      );
    }
    this.handleClose();
  }

  fetchPrevPage = () => {
    const { prevPage, fetchPrevPage } = this.props;
    const { selectedSortFilter, sortDirection } = this.state;
    if (prevPage && fetchPrevPage) {
      fetchPrevPage(
        {
          Property: selectedSortFilter,
          Direction: sortDirection
        });
    }
  }

  fetchNextPage = () => {
    const { nextPage, fetchNextPage } = this.props;
    const { selectedSortFilter, sortDirection } = this.state;
    if (nextPage && fetchNextPage) {
      fetchNextPage(
        {
          Property: selectedSortFilter,
          Direction: sortDirection
        });
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === ENTER_KEY) {
      this.callSearchAction();
    }
  }

  handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { changeSearchText } = this.props;
    if (changeSearchText) {
      changeSearchText(event.target.value);
    }
    this.setState({ searchText: event.target.value });
  }
  handleFilterClick = () => {
    this.setState(() => ({
      filterDropDownOpen: !this.state.filterDropDownOpen
    }));
  }

  render(): React.ReactNode {
    const {
      keyField, handleRecordChange, listItems, selectedRecordId, searchPlaceholder, sortingFilters, fieldsToDisplay, filterHeader, suppressSubHeader,
      browseLookUp, pageSize, totalPages, isLoading, disabled, loadingPrevPage, loadingNextPage, hideFilterButton,
      nextPage, prevPage, customFilterRow, appliedFilters, cascadingListKey, cascadingListKeyField, cascadingListFields, schedulerData,
      newSchedulerEvent, dragAndDropSource, renderCustomItemSection, isItemDraggable, isNextItemSelectionAllowed, lastLinePosition } = this.props;

    return (
      <div className={browseLookUp ? styles.browseLookupInner : styles.lookupInner}>
        <Paper style={browseLookUp ? inlineStyles.browseLookupContainerStyle : inlineStyles.lookupContainerStyle} square={true} >
          {(filterHeader &&
            <FormHeader
              formName={filterHeader.formName}
              parameters={filterHeader.parameters}
              applyOnStart={filterHeader.applyOnStart}
              onApplyFilters={this.applyFilters}
              initialValues={appliedFilters}
              validate={(values?: any) => filterHeader.validate ? filterHeader.validate(values) : null}
            />
          )
            || (customFilterRow &&
              <FormFiltersLookUpHeader
                searchPlaceholder={searchPlaceholder}
                disabled={disabled}
                handleChangeSearchText={this.handleChangeSearchText}
                handleKeyDown={this.handleKeyDown}
                callSearchAction={this.applyFilters}
                searchText={this.state.searchText}
                handleClose={this.handleClose}
                handleFilterClick={this.handleFilterClick}
                filterDropDownOpen={this.state.filterDropDownOpen}
                customFilterRow={customFilterRow}
                appliedFilters={appliedFilters}
              />) || <LookupControlHeader searchPlaceholder={searchPlaceholder}
                sortingFilters={sortingFilters}
                disabled={disabled}
                hideFilterButton={hideFilterButton}
                handleChangeSearchText={this.handleChangeSearchText}
                handleKeyDown={this.handleKeyDown}
                callSearchAction={this.callSearchAction}
                searchText={this.state.searchText}
                handleClose={this.handleClose}
                handleSortDirectionToggle={this.handleSortDirectionToggle}
                handleChangeSortingFilter={this.handleChangeSortingFilter}
                handleFilterClick={this.handleFilterClick}
                filterDropDownOpen={this.state.filterDropDownOpen}
                selectedSortFilter={this.state.selectedSortFilter}
                sortDirection={this.state.sortDirection} />
          }
          {
            !suppressSubHeader && (<Subheader>{`${(listItems || []).length} `}results found</Subheader>)
          }
          <div style={inlineStyles.hrStyle} ></div>
          {isLoading ?
            <CircularProgress size={CircularProgressSize} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
            :
            <DataList<T, U>
              disableItemSelection={disabled}
              keyField={keyField}
              disabled={disabled}
              listItems={(listItems || [])}
              selectedRecordId={selectedRecordId}
              fieldsToDisplay={fieldsToDisplay}
              browseLookUp={browseLookUp}
              handleRecordChange={handleRecordChange}
              loadingPrevPage={loadingPrevPage}
              loadingNextPage={loadingNextPage}
              prevPage={prevPage}
              nextPage={nextPage}
              schedulerData={schedulerData}
              dragAndDropSource={dragAndDropSource}
              isItemDraggable={isItemDraggable}
              cascadingListKey={cascadingListKey}
              cascadingListKeyField={cascadingListKeyField}
              cascadingListFields={cascadingListFields}
              fetchPrevPage={this.fetchPrevPage}
              fetchNextPage={this.fetchNextPage}
              newSchedulerEvent={newSchedulerEvent}
              renderCustomItemSection={renderCustomItemSection}
              loadMore={pageSize * totalPages > (listItems || []).length}
              isNextItemSelectionAllowed={isNextItemSelectionAllowed}
              lastLinePosition={lastLinePosition}
            />
          }
        </Paper>
      </div>
    );
  }
}

export default LookUpList;
