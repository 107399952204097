import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as transactionTypes, actions as transactionActions } from 'ducks/customerEnquiry/transactions';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/transactions';


function* search(action){
  const {success, failure} = transactionActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* getDetails(action){
  const {success, failure} = transactionActions.saga.fetchTransactionDetails;

  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  )
}

function* getSummary(action){
  const {success, failure} = transactionActions.saga.fetchTransactionSummary;

  yield callApi(
    call(api.getSummary, action.data),
    success,
    failure
  )
}

export default function* rootTransactionSaga(){
  yield takeLatest(transactionTypes.search, search);
  yield takeLatest(transactionTypes.fetchTransactionDetails, getDetails);
  yield takeLatest(transactionTypes.fetchTransactionSummary, getSummary);
}