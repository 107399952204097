import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  SuccessAction,
  asyncOnError
} from '../utils';

import { FormViewField } from 'components/FormView';
import { pathOr } from 'utils/utils';

export interface IOrdersData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
  openOrderAllocationModal: boolean;
  orderAllocation: any;
  orders: any;
}

export interface IOrdersState extends IExtendedState<IOrdersData> {
  getSpecificOrderAllocation_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    changeModalVisibility: (data) => (data),
    asyncs: {
      fetchAllocatedOrders: (data) => (data),
      getSpecificOrderAllocation: (data) => (data),
      applySpecificOrderAllocation: (data) => (data),
    }
  },
  'CashReceiptingOrders');

const initialState = asyncInitialState<IOrdersData>({
  selected: null,
  selectedFilters: {
    SalesEntity: '',
    SalesOrder: '',
    PriorTo: '',
    CustomerPurchaseOrder: ''
  },
  filterRow: {
    formName: 'CashReceiptingOrdersFilters',
    validate: () => ({}),
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'S/Order',
          placeholder: '0',
          name: 'SalesOrder',
          size: 'small',
          required: false
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          size: 'small',
          name: 'SalesEntity',
          lookupName: 'SalesEntity'
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer P/O',
          size: 'medium',
          valueField: 'Display',
          name: 'CustomerPurchaseOrder',
          lookupName: 'CustomerPurchaseOrder',
          isCustomerScoped: true,
          suppressValidation: true
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        visible: true,
        props: {
          label: 'Prior to',
          name: 'PriorTo',
          size: 'small',
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          required: false
        }
      },
    ] as FormViewField[]
  },
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    { headerName: 'Entered', field: 'EnteredDate', type: 'numericColumn', minWidth: 180 },
    { headerName: 'Entity', field: 'SalesEntity', suppressSorting: true, type: 'numericColumn', minWidth: 180 },
    { headerName: 'S/Order', field: 'SalesOrder', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Customer P/O', field: 'CustomerPurchaseOrder', minWidth: 150, suppressSorting: true, },
    { headerName: 'Allocated', field: 'AllocatedReceiptedAmount', suppressSorting: true, type: 'numericColumn', minWidth: 180 },
    { headerName: 'Description', field: 'Description', minWidth: 150, suppressSorting: true, },
  ],
  openOrderAllocationModal: false,
  orderAllocation: null,
  orders: null
});

export default (state: IOrdersState = initialState, action: IDataAction): IOrdersState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };

    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.getSpecificOrderAllocation:
    case types.applySpecificOrderAllocation:
    case types.fetchAllocatedOrders:
      return asyncOnRequest(state, action);

    case types.saga.getSpecificOrderAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IOrdersData, successAction: SuccessAction) => {
          return {
            ...data,
            orderAllocation: pathOr({}, ['payload'], successAction),
            SalesEntity: pathOr({}, ['payload', 'inlineObject', 'SalesEntity'], successAction),
          };
        });

    case types.saga.fetchAllocatedOrders.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IOrdersData, successAction: SuccessAction) => {
          return {
            ...data,
            orders: pathOr({}, ['payload', 'records'], successAction),
          };
        });

    case types.saga.applySpecificOrderAllocation.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IOrdersData) => {
          return data;
        });

    case types.saga.getSpecificOrderAllocation.failure:
    case types.saga.fetchAllocatedOrders.failure:
    case types.saga.applySpecificOrderAllocation.failure:
      return asyncOnError(state, action);

    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.selected,
  orders: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.orders,
  openOrderAllocationModal: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.openOrderAllocationModal,
  orderAllocation: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.orderAllocation,
  orderAllocationLoading: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.getSpecificOrderAllocation_loading,
  gridOptions: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.gridOptions,
  columns: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.Columns,
  Actions: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.Actions,
  filterRow: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.filterRow,
  selectedFilters: (state: { cashReceipting: { orders: IOrdersState } }) => state.cashReceipting.orders.data.selectedFilters
});
