import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from 'ducks/utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchSummary: Data => Data,
  }
}, 'Prices')

let initialState = asyncInitialState({
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 150 },
    { headerName: 'Separate W/H pricing', field: 'SeparateWHPricingDisplay', suppressSorting: true, minWidth: 150 },
    { headerName: 'Prod price code', field: 'ProductPriceCode', suppressSorting: true, minWidth: 150 },
    { headerName: 'Base price', field: 'BasePriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Retail price', field: 'RetailPriceDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Average cost', field: 'AverageCostDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Last cost', field: 'LastCostDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Last original cost', field: 'LastOriginalCostDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Currency', field: 'Currency', minWidth: 100, suppressSorting: true, },
    { headerName: 'Last Receipt', field: 'LastReceiptDate', type: 'numericColumn', suppressSorting: true, minWidth: 120, }
  ],
  getDetailRowData: (row) => row.data.Breaklevels,
  linesData: [],
  linesSchema: [],
  pricesSummary: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.fetchSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const summary = action.payload;
        return {
          ...data,
          pricesSummary: summary
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.pricing.prices,
  {
    linesData: data => data.linesData,
    pricesSummary: data => data.pricesSummary
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.pricing.prices.loading,
  gridOptions: state => state.inventoryEnquiry.pricing.prices.data.gridOptions,
  lineColumns: state => state.inventoryEnquiry.pricing.prices.data.lineColumns,
  detailRowHeight: (state) => state.inventoryEnquiry.pricing.prices.data.detailRowHeight,
  detailCellRenderer: state => state.inventoryEnquiry.pricing.prices.data.detailCellRenderer,
  pricesSummary: state => state.inventoryEnquiry.pricing.prices.data.pricesSummary,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

