import { combineReducers } from 'redux';

import salesOrder from './salesOrder';
import orderLine from './orderLine';
import deliveryLine from './deliveryLine';
import purchaseOrder from './committedPurchaseOrder';
import productionOrder from './committedProductionOrder';
import inPutAway from './committedInPutAway';
import ldPurchaseOrder from './ldCommittedPurchaseOrder';
import ldProductionOrder from './ldCommittedProductionOrder';
import ldInPutAway from './ldCommittedInPutAway';
import cdPurchaseOrder from './cdCommittedPurchaseOrder';
import cdProductionOrder from './cdCommittedProductionOrder';
import cdInPutAway from './cdCommittedInPutAway';
import saleDeposits from './saleDeposits';
import depositDetails from './depositDetails';
import reservationLots from './reservationLots';
import reservationSerials from './reservationSerials';
import cdReservationLots from './cdReservationLots';
import cdReservationSerials from './cdReservationSerials';
import lineInvoices from './lineInvoices';
import lineDetails from './orderLineDetails';
import BOM from './BOM';
import reservationDetails from './reservationDetails';
import cdReservationDetails from './cdReservationDetails'
import componentDetails from './componentDetails'
import paymentEntryDetails from './paymentEntryDetails'

const salesOrderEnquiryReducer = combineReducers({
  salesOrder,
  orderLine,
  deliveryLine,
  purchaseOrder,
  productionOrder,
  inPutAway,
  ldPurchaseOrder,
  ldProductionOrder,
  ldInPutAway,
  cdPurchaseOrder,
  cdProductionOrder,
  cdInPutAway,
  saleDeposits,
  depositDetails,
  reservationSerials,
  reservationLots,
  cdReservationLots,
  cdReservationSerials,
  lineInvoices,
  lineDetails,
  BOM,
  reservationDetails,
  cdReservationDetails,
  componentDetails,
  paymentEntryDetails,
});

export default salesOrderEnquiryReducer;
