import * as React from 'react';
import { BottomNavigation, BottomNavigationAction, Badge } from '@material-ui/core';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import Icon from '@markinson/uicomponents-v2/Icon';

import * as styles from './ActionBar.scss';
import { Operation } from '../../../utils/operations';
import { isNull } from 'utils/utils';

const MINIMUM_WINDOW_WIDTH_UNMINIZED = 1250;

const inlineStyles = (theme) => createStyles({
  iconStyle: {
    height: '40px',
    width: '40px',
    fill: '#000000'
  },
  disabledIcon: {
    fill: '#AAAAAA'
  },
  navigationItemStyle: {
    width: '50px',
    boxSizing: 'border-box',
    color: 'black',
  },
  actionLabel: {
    fontWeight: 600,
    width: 'max-content'
  },
  bottomNavigationBar: {
    color: 'black'
  },
  mainDiv: {
    display: 'flex',
    width: '100%'
  },
  leftDiv: {
    flex: '3'
  },
  centerDiv: {
    flex: '3'
  },
  badge: {
    top: 5,
    right: 0,
    padding: 0,
    backgroundColor: '#009E11',
    border: `2px solid ${theme.palette.background.paper}`,
  },
  disabledBbadge: {
    backgroundColor: '#AAAAAA',
  },
});

export interface IFormNavigationProperties extends StyledComponentProps {
  operationMode: Operation;
  uiSelectedTab: string;
  selectedDespatchId: any;
  scope: any;
  selectedAddress: any;
  selectedCustomer: any;
  selectedContact: any;
  actionBar: any;
  changeScope(scope: any): void;
  changeOperationMode(operation: Operation): void;
}

export interface IFormNavigationState {
  controlsMinimized: boolean;
}

export interface IActionItem {
  showBadge?: boolean;
  label: string;
  iconName: string;
  badgeCount?: number;
  customStyle: React.CSSProperties;
  action: Operation;
}

class FormNavigation extends React.Component<IFormNavigationProperties, IFormNavigationState> {

  constructor(props: Readonly<IFormNavigationProperties>) {
    super(props);
    const { uiSelectedTab, changeScope, scope } = props;
    this.state = {
      controlsMinimized: false
    };
    if (uiSelectedTab !== 'SalesDiary' && uiSelectedTab !== 'DiaryDetails') {
      changeScope(scope);
    }
  }

  checkForWindowSizes = () => {
    this.setState({
      controlsMinimized: window.innerWidth <= MINIMUM_WINDOW_WIDTH_UNMINIZED
    });
  }

  componentDidMount(): void {
    this.checkForWindowSizes();
    window.addEventListener('resize', this.checkForWindowSizes);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkForWindowSizes);
  }

  disableActionBarButtons = (): boolean => {
    let disable = false;
    const { uiSelectedTab, scope, selectedAddress, selectedCustomer, selectedContact, selectedDespatchId } = this.props;
    if (scope === 'customer-maintenance') {
      switch (uiSelectedTab) {
        case 'Contacts':
          if (!selectedContact) {
            disable = true;
          }
          break;
        case 'DeliveryDetails':
        case 'CustomerProducts':
        case 'Attachments':
          if (!selectedAddress) {
            disable = true;
          }
          break;
        default:
          if (!selectedCustomer) {
            disable = true;
          }
      }
    } else if (scope === 'pick-slips' && isNull(selectedDespatchId)) {
      return true;
    }

    return disable;
  }

  handleActionClick = (_label: string, action: Operation) => {
    const { changeOperationMode } = this.props;
    changeOperationMode(action);
  }

  renderBottomNavigationAction = (key: string, item: IActionItem, disabled: boolean) => {
    const { classes } = this.props;
    const { label, iconName, customStyle, action, showBadge, badgeCount = 0 } = item;

    return (<BottomNavigationAction
      className={styles.navigationItemStyle}
      label={label}
      key={key}
      disabled={disabled}
      classes={{
        label: classes.actionLabel
      }}
      icon={showBadge ?
        <Badge classes={{ badge: `${classes.badge} ${(disabled) ? classes.disabledBbadge : ''}` }} badgeContent={badgeCount} color='primary'>
          <Icon
            iconName={iconName}
            className={`${classes.iconStyle} ${disabled ? classes.disabledIcon : ''}`}
            style={customStyle}
          />
        </Badge> :
        <Icon
          iconName={iconName}
          className={`${classes.iconStyle} ${disabled ? classes.disabledIcon : ''}`}
          style={customStyle}
        />
      }
      onClick={() => {
        if (!disabled) {
          this.handleActionClick(label, action);
        }
      }}
    />);
  }

  render(): React.ReactNode {
    const { actionBar, classes, operationMode } = this.props;

    const BottomNavigationContent = () => (
      <div className={classes.mainDiv}>
        <div className={classes.leftDiv}>
          <div className={styles.leftIcons}>
            {actionBar.leftIcons && actionBar.leftIcons.map((item, key) => {
              return this.renderBottomNavigationAction(key, item, item.disabled(operationMode));
            })}
          </div>
        </div>
        <div className={classes.centerDiv}>
          {actionBar.centerIcons && actionBar.centerIcons.map((item, key) => {
            return this.renderBottomNavigationAction(key, item, item.disabled(operationMode));
          })}
        </div>
        <div>
          {actionBar.rightIcons && actionBar.rightIcons.map((item, key) => {
            return this.renderBottomNavigationAction(key, item, item.disabled(operationMode, this.disableActionBarButtons));
          })}
        </div>
      </div>
    );

    return (
      <BottomNavigation className={styles.bottomNavigationBar} >
        {
          actionBar &&
          <BottomNavigationContent />
        }
      </BottomNavigation>
    );
  }
}

export default withStyles(inlineStyles, { index: 1 })(FormNavigation);
