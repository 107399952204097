import { IFormViewForm } from 'components/FormView';

const SlipLineDetails: IFormViewForm = {
  id: 'SlipLineDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 1.25
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            size: 'large',
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Ordered',
            name: 'Ordered',
            placeholder: '',
            size: 'medium',
            alignment: 'right',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Supplied',
            name: 'Supplied',
            placeholder: '',
            size: 'medium',
            alignment: 'right'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          props: {
            label: 'Kitting',
            name: 'Kitting',
            placeholder: '',
            size: 'medium',
            alignment: 'right',
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          props: {
            label: 'Backordered',
            name: 'BackOrdered',
            placeholder: '',
            size: 'medium',
            alignment: 'right'
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          props: {
            label: 'Price',
            name: 'Price',
            placeholder: '',
            size: 'medium',
            alignment: 'right'
          }
        },
        {
          id: 7,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Discount',
            alignment: 'right',
            name: 'Discount',
            adornment: '%',
            size: 'small'
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          props: {
            label: 'Exchange Deposit',
            name: 'ExchangeRate',
            placeholder: '',
            size: 'medium',
            alignment: 'right',
          }
        },
        {
          id: 8,
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Tax included price',
            name: 'TaxIncludedPrice',
            defaultValue: false
          }
        },
        {
          id: 9,
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Special price',
            name: 'SpecialPrice',
            defaultValue: false,
          }
        },
        {
          id: 10,
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Fixed price',
            name: 'FixedPrice',
            defaultValue: false
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.25
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Quoted currency',
            name: 'QuoteCurrency',
            lookupName: 'CurrencyCode',
            size: 'small',
            placeholder: '',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          props: {
            label: 'Quoted exchange rate',
            name: 'QuotedExchangeRate',
            placeholder: '',
            size: 'medium',
            type: 'number',
            required: false,
            decimalSeparator: true
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Invoice exchange rate',
            name: 'InvoiceExchangeRate',
            placeholder: '',
            size: 'medium',
            type: 'number',
            required: false,
            decimalSeparator: true
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          props: {
            label: 'Required ship',
            name: 'RequiredShip',
            placeholder: '',
            size: 'small',
            required: false,
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          props: {
            label: 'Expected ship',
            name: 'ExpectedShip',
            placeholder: '',
            size: 'small',
            required: false,
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          props: {
            label: 'Expiry',
            name: 'Expiry',
            placeholder: '',
            size: 'small',
            required: false,
          }
        },
        {
          id: 6,
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '300px'
            }
          }
        }
      ]
    }
  ]
};
export default SlipLineDetails;
