import * as React from 'react';
import FormView from '../../FormView/index';
import { ISupplierSettingsProps } from '../interfaces';

class SupplierSettings extends React.PureComponent<ISupplierSettingsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-enquiry/settings/financial':
          onInitialLoad('SupplierFinancialSettings');
          break;
        case '/supplier-enquiry/settings/by-warehouse':
          onInitialLoad('SupplierSettingsByWarehouse');
          break;
        case '/supplier-enquiry/settings/general':
          onInitialLoad('SupplierGeneralSettings');
          break;
        case '/supplier-enquiry/settings/attributes':
          onInitialLoad('SupplierSettingsAttributes');
          break;
        default:
          onInitialLoad('SupplierFinancialSettings');
      }
    }
  }

  getInitialValues = (): { initialValues?: any; valuesSchema?: any } => {
    const { selectedTab, financialSettings, financialSettingsSchema, generalSettings, generalSettingsSchema } = this.props;
    switch (selectedTab) {
      case 'SupplierFinancialSettings':
        return financialSettings && financialSettingsSchema && { initialValues: financialSettings, valuesSchema: financialSettingsSchema };
      case 'SupplierGeneralSettings':
        return generalSettings && generalSettingsSchema && { initialValues: generalSettings, valuesSchema: generalSettingsSchema };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default SupplierSettings;
