import React from 'react';
import IServiceItemTypeMaintenanceProperties from './ServiceItemTypeMaintenance.properties';
import ChecklistSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/ServiceItemType';
import BreadcrumbBar from '@markinson/uicomponents-v2/BreadcrumbBar';
import * as styles from './ServiceItemTypeMaintenance.styles.scss';
import modules from './ServiceItemTypeMaintenance.modules';
import ServiceItemTypeDetails from './ServiceItemTypeDetails';
import { NavigationTabs } from '@markinson/uicomponents-v2';
import { TABS_DATA } from './ServiceItemTypeMaintenance.constants';
import { withRouter, Route } from 'react-router-dom';
import { getKeyByValue } from 'utils/utils';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './ServiceItemTypeMaintenance.options';
import ActionBar from '@markinson/uicomponents-v2/ActionBar';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { IActionBarHandlers } from './ServiceItemTypeDetails/ServiceItemTypeDetails.properties';

const ServiceItemTypeMaintenance = (props: IServiceItemTypeMaintenanceProperties): JSX.Element => {
  const {
    match,
    history,
    isCustomerLookUpOpen
  } = props;

  const { actionBarProps } = React.useContext(ActionBarContext);

  const url = match ? match.url : '';
  const path = match ? match.path : '';

  const tabLocations = {
    ChecklistMaintenance: url,
  };

  const [optionsMenuOpen, setOptionsMenuOpen] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>('ServiceItemTypeDetails');
  const serviceItemTypeDetailsRef = React.useRef<IActionBarHandlers>();

  React.useEffect(
    () => {
      const pathname = location ? location.pathname : '';
      const tabByLocation = getKeyByValue(tabLocations, pathname);
      setSelectedTab(tabByLocation);
    },
    [location]);
  React.useEffect(
    () => {
      return () => {
          props.resetSearchLookupDrawer();
          props.destoryForm();
      };
    },
    []
  );

  function handleModuleChange(newSelectedTab: string): void {
    history.push(tabLocations[newSelectedTab]);
  }

  function handleOptionClick(newSelectedTab: string): void {
    handleModuleChange(newSelectedTab);
  }

  function navigateBack(): void {
    if (!selectedTab) {
      history.push('/dashboard');
    }
    if (selectedTab) {
      const targetModule = modules.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        history.push('/dashboard');
      } else {
        handleModuleChange(targetModule.parent);
      }
    }
  }

  function handleActionClick(action: string): void {
    const detailsRef = serviceItemTypeDetailsRef.current;
    switch (action) {
      case 'Back':
        navigateBack();
        break;
      case 'Edit':
        detailsRef.onEdit();
        break;
      case 'Cancel':
        detailsRef.onCancel();
        break;
      case 'New':
        detailsRef.onNew();
        break;
      case 'Save':
        detailsRef.onOk();
        break;
      case 'Delete':
        detailsRef.onDelete();
        break;
      default:
    }
  }

  return (
    <div className={styles.serviceItemTypeOuter}>
      <div className={styles.serviceItemTypeContainer}>
        <ChecklistSearchLookUpDrawer enableToggle={true} />
        <div className={styles.serviceItemTypeSection} >
          <BreadcrumbBar
            onOptionsMenuClick={() => { setOptionsMenuOpen(!optionsMenuOpen); }}
            mainModule={'Service Item Type Maintenance'}
            activeModuleId={selectedTab}
            moduleTree={modules}
            onBreadcrumbClick={(moduleId) => { handleModuleChange(moduleId); }}
            onMainModuleClick={() => { handleModuleChange('ServiceItemTypeDetails'); }}
          />
          {TABS_DATA.some(({ id }) => id === selectedTab) ? <NavigationTabs
            lookupOpen={isCustomerLookUpOpen}
            tabsData={TABS_DATA}
            activeTabId={selectedTab}
            onTabChange={(tab) => { handleModuleChange(tab); }}
          /> : null}
          <Route
            key={'Lines'}
            path={path}
            exact
            render={(routeProps) => {
              return <ServiceItemTypeDetails
                {...routeProps}
                ref={serviceItemTypeDetailsRef}
              />;
            }} />
        </div>
      </div>
      <OptionsMenu
        open={optionsMenuOpen}
        options={options}
        onOptionClick={handleOptionClick}
        defaultValue={selectedTab}
        onClickAway={() => { setOptionsMenuOpen(false); }}
      />
      <ActionBar {...actionBarProps} onActionClick={handleActionClick} />
    </div>
  );
};

export default withRouter(ServiceItemTypeMaintenance);
