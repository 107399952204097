import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import { ISerialDialogProps } from './SerialDialog.properties';
import styles from './SerialDialog.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import { default as MUIButton } from '@material-ui/core/Button';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import TextField from '@markinson/uicomponents-v2/TextField';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { IBinLotFacade, } from 'api/swaggerTypes';
import classNames from 'classnames';
import { isNull } from 'utils/utils';
import { COLUMN_SIZE } from '../DataGridDevEx/DataGrid.constants';
import { ENTER_KEY } from 'utils/constants';

const LotDialog = (props: ISerialDialogProps): JSX.Element => {
  const {
    classes, open, RequiredQuantity, BinLots, isBinTracked, onApply, onCancel, fetchProductSerials
  } = props;
  const [selectedQuantity, setSelectedQuantity] = React.useState(0);
  const [lines, setLines] = React.useState<IBinLotFacade[]>([]);
  const [serialNumber, setSerialNumber] = React.useState<string>('');
  const serialTextField = React.useRef<HTMLTextAreaElement | HTMLInputElement>();
  const dataGridRef = React.createRef<DataGrid>();

  React.useEffect(
    () => {
      if (open) {

        setLinesFromApi().catch((e) => { console.warn(e); });
        setSelectedQuantity(0);
      }
    },
    [open]);

  const setLinesFromApi = async () => {
    const serialData = await fetchProductSerials();
    if (!isNull(serialData.Status && serialData.ProductSerials)) {
      const updatedLines = serialData.ProductSerials.map((serial) => ({ ...serial, Quantity: 1 }));
      setLines(updatedLines);
    }
  };
  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        disabled: !RequiredQuantity || selectedQuantity !== RequiredQuantity,
        listener: async () => {
          if (onApply) {
            const selected = selectedRows();
            await onApply(selected);
          }
        }
      },
      {
        iconName: 'Cancel',
        title: 'Cancel',
        isDefault: true,
        listener: async () => {
          if (onCancel) {
            await onCancel();
          }
        }
      }];
  };

  const NumberOfDecimalPlaces = 2;
  const SMALL_COLUMN_DEFAULT_WIDTH = 180;

  const selectLine = () => {
    const serial = lines.find((item) => item.LotSerial.toUpperCase() === serialNumber.toUpperCase());
    if (!isNull(serial)) {
      selectRows([serial.LotSerial]);
    }
    clearEntryData();
  };

  const clearEntryData = () => {
    setSerialNumber('');
  };

  const handleSelection = ({ selectedRowKeys }) => {
    setSelectedQuantity(selectedRowKeys.length);
  };

  const defaultSelected = BinLots && BinLots.map((serial) => serial.LotSerial);
  const onContentReady = () => {
    selectRows(defaultSelected);
  };

  const selectRows = (rowsToSelect: string[]) => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.selectRows(rowsToSelect, true);
    }
  };

  const selectedRows = (): IBinLotFacade[] => {
    if (dataGridRef.current) {
      return dataGridRef.current.instance.getSelectedRowsData();
    } else {
      return [];
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      selectLine();
    }
  };

  const isApplyDisabled = isNull(serialNumber);

  const getModalContent = () => {
    return <div className={classNames({
      [classes.mainDiv]: !Boolean(isBinTracked),
      [classes.mainDivBin]: Boolean(isBinTracked)
    })}>

      <div className={classes.lotLine}>
        <TextField
          value={serialNumber}
          innerRef={serialTextField}
          label={'Serial'}
          onKeyDown={handleKeyDown}
          onChange={(value) => { setSerialNumber(value); }}
        />
        <MUIButton
          onClick={selectLine}
          variant={'contained'}
          disabled={isApplyDisabled}
          className={classNames(classes.button, isApplyDisabled && classes.disabledBtn)}
        >
          <CheckCircleIcon style={{ color: 'green' }} />
          APPLY
        </MUIButton>
      </div>
      <DataGrid
        keyExpr={'LotSerial'}
        ref={dataGridRef}
        dataSource={lines}
        className={classes.lotLineGrid}
        repaintChangesOnly={true}
        noDataText=''
        remoteOperations={true}
        allowColumnReordering={true}
        columnResizingMode={'nextColumn'}
        allowColumnResizing={true}
        onSelectionChanged={handleSelection}
        onContentReady={onContentReady}
        editing={{
          allowAdding: false,
          allowDeleting: false,
          allowUpdating: false,
          confirmDelete: false,
        }}
        showBorders={false}
        sorting={{
          mode: 'none'
        }}
        hoverStateEnabled={true}
        selection={{
          mode: 'multiple',
          showCheckBoxesMode: 'always'
        }
        }
      >
        <Column
          caption={'Serial'}
          dataField={'LotSerial'}
          allowEditing={false}
          fixed={true}
          minWidth={COLUMN_SIZE.sm1}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
        />
        <Column
          caption={'Bin'}
          dataField={'Bin'}
          allowEditing={false}
          fixed={true}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm0}
          width={COLUMN_SIZE.md0}
        />
        <Column
          caption={'BinType'}
          dataField={'BinType'}
          allowEditing={false}
          fixed={true}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm0}
          width={COLUMN_SIZE.md0}
        />
        <Column
          caption={'Entered'}
          dataField={'Entered'}
          allowEditing={false}
          fixed={true}
          minWidth={COLUMN_SIZE.sm1}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
        />
        <GroupPanel visible={false} />
        <SearchPanel visible={false} />
        <Scrolling mode={'virtual'} />
        <LoadPanel enabled={false} />
      </DataGrid>
      <Table>
        <TableBody>
          <TableRow className={classes.bottomRow}>
            <TableCell className={classes.headingCell}>REQUIRED</TableCell>
            <TableCell className={classes.emphasizedCell}>{Number(RequiredQuantity || 0).toFixed(NumberOfDecimalPlaces)}</TableCell>
            <TableCell className={classes.headingCell}>SELECTED</TableCell>
            <TableCell className={classes.dataCell}>{Number(selectedQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>;
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Select Serial Numbers'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(LotDialog);
