import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
    fetchByBom: ({ Invoice, SalesEntity, LineNumber }) => ({ Invoice, SalesEntity, LineNumber }),
  }
}, 'Serial');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Serial', field: 'SerialNumber', minWidth: 150 },
    { headerName: 'Quantity', field: 'QuantityDisplay', minWidth: 150 },
  ],
  lines: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchByBom:
      return asyncOnRequest(state, action)
    case types.saga.fetchByBom.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          lines: linesData
        }
      })
    case types.saga.fetchByBom.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesInvoiceEnquiry.Serial,
  {
    lines: data => data.lines,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.salesInvoiceEnquiry.Serial.loading,
  gridOptions: state => state.salesInvoiceEnquiry.Serial.data.gridOptions,
  lineColumns: state => state.salesInvoiceEnquiry.Serial.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)