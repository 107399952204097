import OrderDetails from '../data/forms/purchase_order_enquiry/OrderDetails'
import LineDetails from '../data/forms/purchase_order_enquiry/LineDetails'
import OrderLines from '../data/forms/purchase_order_enquiry/OrderLines'
import CommittedSaleOrder from '../data/forms/purchase_order_enquiry/CommittedSaleOrder'
import CommittedProductionOrder from '../data/forms/purchase_order_enquiry/CommittedProductionOrder'
import CommittedServiceOrders from '../data/forms/purchase_order_enquiry/CommittedServiceOrders'
import LDCommittedSaleOrder from '../data/forms/purchase_order_enquiry/LDCommittedSaleOrder'
import LDCommittedProductionOrder from '../data/forms/purchase_order_enquiry/LDCommittedProductionOrder'
import LDCommittedServiceOrders from '../data/forms/purchase_order_enquiry/LDCommittedServiceOrders'
import BranchOrders from '../data/forms/purchase_order_enquiry/BranchOrders'
import LDBranchOrders from '../data/forms/purchase_order_enquiry/LDBranchOrders'
import ProductReceipts from '../data/forms/purchase_order_enquiry/ProductReceipts'
import SalesDiary from './../data/forms/purchase_order_enquiry/Diary'
import DiaryDetails from './../data/forms/purchase_order_enquiry/DiaryDetails'
import Notepad from './../data/forms/purchase_order_enquiry/Notepad'
import InTransitLocalShipments from './../data/forms/purchase_order_enquiry/InTransitLocalShipments'
import InTransitImportShipments from './../data/forms/purchase_order_enquiry/InTransitImportShipments'
import LDInTransitLocalShipments from './../data/forms/purchase_order_enquiry/LDInTransitLocalShipments'
import LDInTransitImportShipments from './../data/forms/purchase_order_enquiry/LDInTransitImportShipments'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'OrderDetails':
      return OrderDetails
    case 'LineDetails':
      return LineDetails
    case 'OrderLines':
      return OrderLines
    case 'BranchOrders':
      return BranchOrders
    case 'LDBranchOrders':
      return LDBranchOrders
    case 'CommittedSaleOrder':
      return CommittedSaleOrder
    case 'CommittedProductionOrder':
      return CommittedProductionOrder
    case 'CommittedServiceOrders':
      return CommittedServiceOrders
    case 'LDCommittedSalesOrder':
      return LDCommittedSaleOrder
    case 'LDCommittedProductionOrder':
      return LDCommittedProductionOrder
    case 'LDCommittedServiceOrder':
      return LDCommittedServiceOrders
    case 'ProductReceipts':
      return ProductReceipts
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    case 'Notepad':
      return Notepad
    case 'InTransitLocalShipments':
      return InTransitLocalShipments
    case 'InTransitImportShipments':
      return InTransitImportShipments
    case 'LDInTransitLocalShipments':
      return LDInTransitLocalShipments
    case 'LDInTransitImportShipments':
      return LDInTransitImportShipments
    default:
      return OrderDetails
  }
}

export default getFormSchema
