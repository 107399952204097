import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { actions as formActions } from 'ducks/form'
import { selectors as backordersSelector, actions as backordersAction } from 'ducks/customerEnquiry/backorders'
import { selectors as customerSelector } from 'ducks/customer'
import { fetch } from 'api/customerEnquiry/backorders'
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'Backorders',
  columnDefs: backordersSelector.Columns(state),
  rowData: backordersSelector.Data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), // to trigger update size based on drawer state change
  gridOptions: backordersSelector.gridOptions(state),
  filterRow: backordersSelector.filterRow(state),
  selectedFilters: backordersSelector.selectedFilters(state),
  isLoading: backordersSelector.isLoading(state),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  apiMethod: fetch,
  appliedFilters: backordersSelector.filterRow(state) && filterSelectors.getFilters(state, backordersSelector.filterRow(state).formName)
})

export const actions = {
  deleteSelectedRecords: backordersAction.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: backordersAction.setSelected,
  applyFilters: filterActions.applyFilters
}
