import React from 'react';
import { connect } from 'react-redux';
import { IPurchaseOrdersProps } from './PurchaseOrders.properties';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import PurchaseOrders from './PurchaseOrders';
import PurchaseOrdersStateProvider from './PurchaseOrders.context';
import { actions as purchaseordersActions, selectors as purchaseOrdersSelectors } from 'ducks/purchaseOrders/purchaseOrders';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';
import { actions as operationActions } from 'ducks/uiOperations';

const mapStateToProps = (state) => ({
    isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
    isPurchaseOrdersLookUpOpen: uiSelectors.isPurchaseOrdersLookUpOpen(state),
    selectedPurchaseOrder: purchaseOrdersSelectors.selectedPurchaseOrder(state) ?? new URLSearchParams(location.search).get('PurchaseOrder'),
    pickSlipDocumentOutputOptions: documentOutputSelectors.options('SO', 'PickSlipPrintData')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeValidationDialog: uiActions.changeValidationDialog,
        toggleMenuOption: uiActions.toggleMenuOption,
        changeOperationMode: operationActions.changeOperationMode,
        setPickSlipDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'PickSlipPrintData', options),
        resetSearchLookupDrawer: purchaseordersActions.reset
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
    IPurchaseOrdersProps>) => {
    return (
        <PurchaseOrdersStateProvider>
            <PurchaseOrders {...props} />
        </PurchaseOrdersStateProvider>
    );
});
