import * as React from 'react';
import { StyledComponentProps } from '@material-ui/core/styles';
import { fetchFile } from 'api/utils';
import { apiEndpoint } from '../../../../config';

export interface IFileDownloadCellRendererProps extends StyledComponentProps {
    data?: any;
}

function FileDownloadCellRenderer(props: IFileDownloadCellRendererProps): JSX.Element {
    const { data = {} } = props;

    function handleClick(): void {

        fetchFile(`${apiEndpoint}Document/${data.DocumentId}`).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.FileName);
            document.body.appendChild(link);
            link.click();
        });
    }

    return data ? (
        <a href={'#'} onClick={handleClick} >
            {data.FileName} ({data.FileSize})
        </a>
    ) : null;
}

export default FileDownloadCellRenderer;
