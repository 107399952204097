import React from 'react';
import {
    AddCircle as AddCircleIcon,
    RemoveCircle as RemoveCircleIcon,
    Process as ProcessIcon
} from '@markinson/uicomponents-v2/SvgIcons/';
import PrintIcon from 'assets/Print';
import { DataGrid } from 'devextreme-react';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Paper, withStyles } from '@material-ui/core';
import styles from './Lines.styles';
import { useRetrievePickSlipLines, useUpdateGridLine, useRetrievePickSlipSummary, useDeletePickSlipLines, IDeleteGridLinePayload } from 'api/pickSlips/slipLinesUpdated';
import { ILinesHandle, ILinesProperties } from './Lines.properties';
import { createDataSource } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { isNull } from 'utils/utils';
import { DespatchStatusTypes } from '../PickSlips.constants';
import { PickSlipAPIType, useLostSale, useMultipleBom, usePriceOverride } from '../PickSlips.hooks';
import { PriceOverrideScreenType } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { usePSDispatch } from '../PickSlips.context';
import { isScreenRequest } from 'api/utils';
import DynamicDataGrid from 'components/common/DataGridDevEx/DynamicDataGrid';
import { columns } from './constants';

const PrintIconStyled = (props) => <PrintIcon {...props} style={{ width: 35, height: 35, margin: 3 }} />;

const Lines = (props: ILinesProperties, ref: React.Ref<ILinesHandle>) => {
    const { classes, selectedDespatchId, changeSelectedTab, calculateCellValueChange } = props;
    const { setActionBar } = React.useContext(ActionBarContext);
    const contextDisoatch = usePSDispatch();
    const deleteLineMutation = useDeletePickSlipLines();

    const dataGridInnerRef = React.useRef<DataGrid>();

    const { initiateLostSale, isLostSale } = useLostSale();
    const { setPriceOverrideState, isPriceOverrideScreenRequest } = usePriceOverride();
    const { setMultipleBomState } = useMultipleBom();

    const fetchLinesMutation = useRetrievePickSlipLines();
    const fetchSummary = useRetrievePickSlipSummary(Number(selectedDespatchId));
    const updateGridLineMutation = useUpdateGridLine();

    const despatchStatus = fetchSummary.data?.PickSlipSummary?.inlineObject?.Status;
    const isDespatchFinalized = React.useMemo(
        () =>
            fetchSummary.isLoading ||
            DespatchStatusTypes.some((despatchStatusType) => despatchStatus?.toLowerCase()?.includes(despatchStatusType?.toLowerCase())),
        [despatchStatus, fetchSummary.isLoading]
    );

    const centerIcons = [
        {
            label: 'Print',
            Icon: PrintIconStyled,
            action: 'Print',
            disabled: isNull(selectedDespatchId) || fetchSummary.isLoading
        },
        {
            label: 'Charges',
            Icon: AddCircleIcon,
            action: 'Charges',
            disabled: isNull(selectedDespatchId) || isDespatchFinalized
        }
    ];
    const rightIcons = [
        {
            label: 'Remove',
            Icon: RemoveCircleIcon,
            action: 'removePickSlip',
            disabled: isNull(selectedDespatchId) || isDespatchFinalized,
            iconStyle: !isNull(selectedDespatchId) && !isDespatchFinalized ? { fill: 'rgba(178, 0, 0, 1)' } : undefined,
        },
        {
            label: 'Process',
            Icon: ProcessIcon,
            action: 'Process',
            disabled: isNull(selectedDespatchId) || isDespatchFinalized,
            iconStyle: !isNull(selectedDespatchId) && !isDespatchFinalized ? { fill: 'rgba(109, 217, 0, 1)' } : undefined,
        }
    ];

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons,
                rightIcons
            });
        },
        [selectedDespatchId, fetchSummary.isLoading]
    );

    const fetchLines = React.useCallback(
        async (BatchPage, BatchSize, Sort) => {
            if (Number(selectedDespatchId)) {
                try {
                    const lines = await fetchLinesMutation.mutateAsync({
                        DespatchId: selectedDespatchId,
                        urlQueryParams: {
                            BatchPage,
                            BatchSize,
                            Sort
                        }
                    });

                    return lines?.PickSlipLines;
                } catch (error) {
                    console.error('Error fetching lines:', error);

                    return null;
                }
            } else {
                return null;
            }
        },
        [selectedDespatchId]
    );

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [],
                centerIcons,
                rightIcons
            });
        },
        [selectedDespatchId, despatchStatus]
    );

    const updateLine = React.useCallback(
        async (data) => {
            const response = await updateGridLineMutation.mutateAsync(data);
            const responseDespatchGridLineCriteriaInline = response?.DespatchGridLineCriteria?.inlineObject;

            if (response?.Forms && isPriceOverrideScreenRequest(response?.Forms[0])) {
                setPriceOverrideState({
                    open: true,
                    data: {
                        ...responseDespatchGridLineCriteriaInline?.PriceOverride,
                        DespatchId: selectedDespatchId,
                        LineNumber: data?.LineNumber,
                    } as any,
                    type: PriceOverrideScreenType[response?.Forms?.[0]?.FormId],
                    apiType: PickSlipAPIType.updateGridLine
                });
            }

            if (isScreenRequest(response?.Forms, 'SelectBomCode')) {
                setMultipleBomState({
                    open: true,
                    screen: 'Lines',
                    LineNumber: data?.LineNumber,
                    kitStatus: responseDespatchGridLineCriteriaInline?.OpenInvoiceLine?.KitStatus,
                    bomCode: responseDespatchGridLineCriteriaInline?.OpenInvoiceLine?.BomCode,
                    data: responseDespatchGridLineCriteriaInline?.OpenInvoiceLine?.KitDetail
                });
            }

            if (isLostSale(response?.Forms)) {
                await initiateLostSale(PickSlipAPIType.updateGridLine, selectedDespatchId, data?.LineNumber);
            }

            return response;
        },
        [selectedDespatchId]
    );

    const updateGridLine = React.useCallback(
        async (data) => {
            const response = await updateLine({
                DespatchId: selectedDespatchId,
                LineNumber: data?.LineNumber,
                OpenInvoiceLine: data
            });

            return (response?.DespatchGridLineCriteria?.inlineObject?.OpenInvoiceLine) ?
                { ...response?.DespatchGridLineCriteria?.inlineObject?.OpenInvoiceLine, Forms: response.Forms } : null;
        },
        [selectedDespatchId]
    );

    const linesDataSource = React.useMemo(
        () => createDataSource(
            'LineNumber',
            { fetch: fetchLines, update: updateGridLine },
        ),
        [fetchLines, updateGridLine]
    );
    const reloadLines = React.useCallback(
        () => {
            linesDataSource.reload();
            dataGridInnerRef.current?.instance.refresh(true);
        },
        [dataGridInnerRef, linesDataSource]
    );

    const deleteLine = React.useCallback(
        async (data: IDeleteGridLinePayload) => {
            const response = await deleteLineMutation.mutateAsync({
                DespatchId: selectedDespatchId,
                ...data
            });
            if (isLostSale(response?.Forms)) {
                await initiateLostSale(PickSlipAPIType.deleteGridLine, selectedDespatchId, data?.LineNumber);
            }
            reloadLines();

            return response;
        },
        [selectedDespatchId, dataGridInnerRef, reloadLines]
    );

    const refreshDataGrid = React.useCallback(
        async () => {
            await linesDataSource?.reload();
            dataGridInnerRef.current?.instance?.refresh();
            dataGridInnerRef.current?.instance?.cancelEditData();
        },
        [linesDataSource]
    );

    React.useEffect(
        () => {
            if (fetchSummary.isFetched) {
                refreshDataGrid();
            }
        },
        [fetchSummary.isFetched, refreshDataGrid]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            deleteLine,
            updateLine,
            refreshDataGrid
        }),
        [deleteLine, updateLine, refreshDataGrid]
    );

    const detailOnClick = React.useCallback(
        ({ data }) => {
            if (!isDespatchFinalized) {
                changeSelectedTab('LineDetails', {
                    search: {
                        DespatchId: data.DespatchId,
                        LineNumber: data.LineNumber
                    }
                });
                contextDisoatch({
                    setDespatchId: data.DespatchId,
                    setLineNumber: data.LineNumber
                });
            }
        },
        [changeSelectedTab, isDespatchFinalized]
    );

    const handleOnSaving = React.useCallback(
        async (e) => {
            e.cancel = true;
            const rowData = (e.changes && e.changes.length) ? e.changes[0].data : null;
            if (rowData) {
                const editedRowData = e.component.getVisibleRows()?.find((r) => r.isEditing)?.data;
                const updateResp = await linesDataSource.store().update(editedRowData.LineNumber, editedRowData);
                if (!isPriceOverrideScreenRequest(updateResp.Forms?.[0]) && !isLostSale(updateResp?.Forms)) {
                    refreshDataGrid();
                }
            } else {
                dataGridInnerRef.current.instance.cancelEditData();
            }
        },
        [linesDataSource, dataGridInnerRef, refreshDataGrid]
    );

    const deleteClick = React.useCallback(
        async (rowKey): Promise<void> => {
            if (rowKey) {
                deleteLine({ LineNumber: Number(rowKey) });
            }
        },
        [deleteLine]
    );

    const disableReadOnlyColumns = React.useCallback(
        (e) => {
            e.component.getVisibleColumns().forEach((column) => {
                if (['Price', 'Discount'].includes(column?.dataField) && e?.row?.data?.hasOwnProperty(`${column?.dataField}ReadOnly`)) {
                    e.component?.columnOption(column?.dataField, 'allowEditing', !e.row?.data?.[`${column?.dataField}ReadOnly`]);
                }
            });
        },
        []
    );

    const onEditorPreparing = React.useCallback(
        (e) => {
            if (e.dataField === 'Discount') {
                e.editorOptions.format = "#0.00 ' % '";
            }

            disableReadOnlyColumns(e);
        },
        [disableReadOnlyColumns]
    );

    return (
        <Paper className={classes.parentContainer}>
            <DynamicDataGrid
                dataGridClassName={classes.grid}
                calculateFieldValue={calculateCellValueChange}
                onDelete={deleteClick}
                columns={columns}
                allowHeaderFilting={false}
                allowUpdating={true}
                gridProps={{
                    dataSource: linesDataSource,
                    dataGridRef: dataGridInnerRef,
                    disableDrillDown: isDespatchFinalized,
                    disableEditing: isDespatchFinalized,
                    disableDelete: isDespatchFinalized,
                    onEditorPreparing: onEditorPreparing,
                    sorting: { mode: 'single' },
                    onSaving: handleOnSaving
                }}
                detailOnClick={detailOnClick}
            >

            </DynamicDataGrid >
        </Paper >
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Lines)));
