import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as staffSelectors } from 'ducks/staff';
import { selectors as paymentsSelectors, actions as paymentsActions } from 'ducks/common/paymentDetails';
import { actions as programAreaActions } from 'ducks/programArea';
import { actions as mainMenuActions } from 'ducks/mainMenu';

import Layout from './LayoutFrame';

const mapStateToProps = (state) => (
  {
    confirmationDialog: uiSelectors.confirmationDialog(state),
    informationDialog: uiSelectors.informationDialog(state),
    validationDialog: uiSelectors.validationDialog(state),
    isDashboardDialogOpen: uiSelectors.isDashboardDialogOpen(state),
    isDashboardDefault: uiSelectors.isDashboardDefault(state),
    validationFormValues: getFormValues('ValidationDialog')(state),
    staff: staffSelectors.staff(state),
    loggedIn: staffSelectors.loggedIn(state),
    eftposDialog: paymentsSelectors.eftposDialog(state),
    fetStatusLoading: paymentsSelectors.fetStatusLoading(state),
  });

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeInformationDialog: uiActions.changeInformationDialog,
    changeValidationDialog: uiActions.changeValidationDialog,
    toggleDashboardOpen: uiActions.toggleDashboardOpen,
    setIsDashboardDefault: uiActions.setIsDashboardDefault,
    fetchDashboardWidgets: programAreaActions.fetchDashboardWidgets,
    showEftposDialog: paymentsActions.showEftposDialog,
    sendOk: paymentsActions.sendOk,
    sendClose: paymentsActions.sendClose,
    fetchMainMenu: mainMenuActions.fetchMainMenu,
    fetchProgramAreas: programAreaActions.fetchProgramAreas,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
