import * as React from 'react';
import DataGrid, { GroupPanel, SearchPanel, Scrolling, LoadPanel, Column, Paging, Sorting } from 'devextreme-react/data-grid';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, IconButton } from '@material-ui/core/';
import TextBox from 'devextreme-react/text-box';
import { Play } from '@markinson/uicomponents-v2/SvgIcons';
import { withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { ARROW_DOWN, ARROW_UP, ENTER_KEY, ESCAPE, TAB, searchModalTimeout } from 'utils/constants';
import ISearchEntryModalProps from './SearchEntryModal.properties';
import styles from './SearchEntryModal.styles';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

const SearchEntryModal = (props: ISearchEntryModalProps) => {
  const {
    classes,
    dialogStyle,
    open,
    loading = false,
    allowArrowKeysNavigation = true,
    disableGridColumnLines = false,
    columnAutoWidth = false,
    onOk,
    onClose,
    onSearch
  } = props;

  const [searchText, setSearchText] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState<Record<string, string> | undefined>(undefined);
  const [okButtonDisabled, setOkButtonDisabled] = React.useState(true);
  const [searchEntireCatalogue, setSearchEntireCatalogue] = React.useState(true);
  const [searchTouched, setSearchTouched] = React.useState(false);
  const inputTextBoxRef = React.useRef<TextBox>(null);
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null);
  const dataGridInnerRef = React.useRef<DataGrid>();
  const [entriesDataSource, setEntriesDataSource] = React.useState<DataSource>(new DataSource([]));
  const [noOfResults, setNoOfResults] = React.useState<number>(0);
  const currentSelectedRowKey = React.useMemo(
    () => dataGridInnerRef?.current?.instance?.getSelectedRowKeys()?.[0],
    [dataGridInnerRef?.current]
  );
  const PlayIcon = () => <Play className={classes.searchIcon} />;

  const mergedDialogStyle = { ...styles.dialog, ...dialogStyle } as CSSProperties;

  // Focus on Load (Timeout is used to allow the Ref to be set)
  React.useEffect(
    () => {
      if (loading) {
        dataGridInnerRef.current?.instance.beginCustomLoading('');
      } else {
        dataGridInnerRef.current?.instance.endCustomLoading();
      }

    },
    [loading]);

  React.useEffect(
    () => {
      if (searchTouched) { // to check if its not a first load
        checkAndSelectGridOrText();
      }
    },
    [loading, entriesDataSource?.items().length, searchTouched]
  );

  function checkAndSelectGridOrText(): any {
    setTimeout(
      () => {
        if (currentSelectedRowKey) { // if row already selected then no need to select again
          return;
        }

        if (dataGridInnerRef.current) {
          if (entriesDataSource?.items().length > 0) {
            dataGridInnerRef.current.instance.focus();
            dataGridInnerRef.current?.instance.selectRowsByIndexes([0])
              .catch((err) => console.warn(err));
          } else {
            selectModalInputText();
            dataGridInnerRef.current.instance.repaint();
          }
        }
      },
      0
    );
  }

  function selectModalInputText(): any {
    const textBoxInput = document.getElementById('search-screen-text-box') as HTMLInputElement;
    textBoxInput?.select();
  }

  React.useEffect(
    () => {
      setOkButtonDisabled(selectedItem === undefined);
    },
    [selectedItem]);

  React.useEffect(
    () => {

      setTimeout(
        () => {
          if (open && inputTextBoxRef.current) {
            inputTextBoxRef.current.instance.focus();
          }
        },
        0
      );
    },
    [open]);

  const selectFirstRow = async () => {
    if (currentSelectedRowKey === undefined) {
      await dataGridInnerRef.current?.instance.selectRowsByIndexes([0]);
    }
  };

  const handleDialogKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (onClose && e.keyCode === ESCAPE) {
      clearData();
      onClose();
    }
  };

  const handleDataGridKeyDown = async (e) => {
    const keyboardEvent = e.event as KeyboardEvent;
    if (keyboardEvent.keyCode === ENTER_KEY && selectedItem && onOk) {
      setTimeout(
        () => {
          onOk(selectedItem);
          clearData();
          onClose();
        },
        searchModalTimeout
      );
    }
    if (allowArrowKeysNavigation) {
      const selKey = e.component.getSelectedRowKeys();
      const visibleRowsLength = e.component.getVisibleRows().length;

      if (selKey.length) {
        const currentKey = selKey[0];
        let index = e.component.getRowIndexByKey(currentKey);
        if (keyboardEvent.keyCode === ARROW_UP) {
          index--;
          if (index >= 0) {
            await e.component.selectRowsByIndexes([index]);
            keyboardEvent.stopPropagation();
          }
        } else if (keyboardEvent.keyCode === ARROW_DOWN) {
          index++;
          if (index < visibleRowsLength) {
            await e.component.selectRowsByIndexes([index]);
            keyboardEvent.stopPropagation();
          }
        }
      }
      e.component.focus();
    }

    if (onClose && keyboardEvent.keyCode === ESCAPE) {
      clearData();
      onClose();
    }

    if (keyboardEvent.keyCode === TAB) {
      setTimeout(
        () => {
          cancelButtonRef.current?.focus();
        },
        0
      );
    }
  };

  const deselectRow = () => {
    dataGridInnerRef.current?.instance.deselectAll()
      .catch((err) => console.log(err));
  };

  const focusCellChanging = React.useCallback(
    (e) => {
      if (allowArrowKeysNavigation) {
        e.isHighlighted = false;
      }
    },
    []);

  const rowDoubleClick = React.useCallback(
    (e) => {
      if (onOk) {
        onOk(e.data);
        clearData();
      }
    },
    []);

  const selectionChanged = React.useCallback(
    (e) => {
      const selectedRowData = e.selectedRowsData && e.selectedRowsData.length > 0 ? e.selectedRowsData[0] as Record<string, string> : undefined;
      const rowKey = e.selectedRowKeys && e.selectedRowKeys.length > 0 ? e.selectedRowKeys[0] as unknown : undefined;
      setSelectedItem(selectedRowData);
      dataGridInnerRef?.current?.instance?.selectRows([rowKey], false);
    },
    []);

  function onSearchHandle(): any {
    if (onSearch) {
      setEntriesDataSource(
        new DataSource({
          load: async ({ skip, take }: LoadOptions) => {
            if (typeof (skip) === 'number' && take) {
              const BatchPage = Math.floor(skip / take) + 1;
              const BatchSize = take;
              const response = await onSearch({
                searchText,
                searchAll: searchEntireCatalogue,
                BatchPage,
                BatchSize
              });
              setNoOfResults(response.BatchInformation.TotalCount);

              return response.CatalogueSearchEntries;
            }

            return [];
          },
          reshapeOnPush: true
        })
      );
    }
  }

  function clearData(): void {
    setEntriesDataSource(undefined);
    setSelectedItem(undefined);
    dataGridInnerRef?.current?.instance?.selectRows([], false);
    setNoOfResults(0);
    setSearchTouched(false);
    setSearchText(null);
  }

  const handleSearch = (e: any) => {
    const clickEvent = (e?.nativeEvent?.type === 'click');
    const keyEvent = (e?.event?.keyCode === ENTER_KEY);
    if (clickEvent || keyEvent) {
      deselectRow();
      setSearchTouched(true);
      dataGridInnerRef?.current?.instance?.selectRows([], false);
      onSearchHandle();
      setSelectedItem(undefined);
    }
  };

  const handleIconTab = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === TAB) {
      selectFirstRow()
        .catch((err) => console.warn(err));
    }
  };

  const handleOnCancel = () => {
    if (onClose) {
      onClose();
      clearData();
    }
  };

  const handleOnOk = () => {
    if (onOk) {
      onOk(selectedItem);
      clearData();
    }
  };

  const handleRowClick = ({ data }) => {
    dataGridInnerRef?.current?.instance?.selectRows([data], false);
  };

  return (
    <Dialog className='product-catalogue-modal' PaperProps={{ style: mergedDialogStyle }} open={open} onKeyDown={handleDialogKeyDown}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.dialogTitleHeading}>Product Catalogue Search</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.searchContainer}>
          <label className={classes.selectionBox}>
            <Checkbox className={classes.checkBox} checked={searchEntireCatalogue} style={{ padding: 0 }} onChange={() => { searchEntireCatalogue ? setSearchEntireCatalogue(false) : setSearchEntireCatalogue(true); }} />
            Search entire catalogue
          </label>
          <TextBox
            ref={inputTextBoxRef}
            className={classes.searchField}
            value={searchText}
            valueChangeEvent={'keyup'}
            inputAttr={{
              id: 'search-screen-text-box'
            }}
            onValueChanged={({ value }) => setSearchText(value)}
            onKeyDown={handleSearch} />
          <IconButton
            className={classNames(classes.button, classes.searchButton)}
            onKeyDown={handleIconTab}
            onClick={handleSearch}>
            <PlayIcon />
            Search
          </IconButton>
        </div>
        <DataGrid
          ref={dataGridInnerRef}
          className={classes.selectionDataGrid}
          dataSource={entriesDataSource}
          columnAutoWidth={columnAutoWidth || false}
          allowColumnReordering={true}
          remoteOperations={true}
          showBorders={true}
          sorting={{ mode: 'none' }}
          showColumnLines={!disableGridColumnLines}
          allowColumnResizing={true}
          columnResizingMode={'nextColumn'}
          hoverStateEnabled={true}
          noDataText={''}
          onKeyDown={handleDataGridKeyDown}
          onFocusedCellChanging={focusCellChanging}
          onSelectionChanged={selectionChanged}
          onRowDblClick={rowDoubleClick}
          onRowClick={handleRowClick}
        >
          <Sorting mode={'none'} />
          <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
          <GroupPanel visible={false} />
          <SearchPanel visible={false} />
          <Scrolling mode='infinite' />
          <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
          <Column dataField={'FullDescription'} caption={`${noOfResults} results found`} />
        </DataGrid>
      </DialogContent>
      <DialogActions className={classes.dialogActionBar}>
        <Button
          className={classNames(classes.button, classes.cancelBtn)}
          buttonRef={cancelButtonRef}
          onClick={handleOnCancel} >
          Cancel
        </Button>
        <Button
          className={classes.okBtn}
          onClick={handleOnOk}
          disabled={okButtonDisabled}>
          Ok
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default withStyles(styles, { index: 1 })(React.forwardRef(SearchEntryModal));
