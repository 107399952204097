import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/salesOrderEnquiry/depositDetails';
import { selectors as depositSelectors } from 'ducks/salesOrderEnquiry/saleDeposits';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { fetch } from 'api/salesOrderEnquiry/depositDetails';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'DepositDetails',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (orderSelectors.selected(state) || params.get('SalesOrder')) &&
    (depositSelectors.selected(state) || params.get('TransactionNumber')) &&
  {
    SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
    TransactionNumber: (depositSelectors.selected(state) && depositSelectors.selected(state).TransactionNumber) || params.get('TransactionNumber')
  },
  keyFields: [{ valueField: 'DebtorCashObject', routeField: 'DebtorCashObject' }],
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
