export default [
  {
    id: '1',
    name: 'Mechanical',
    abbreviation: 'MEC',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'Electrical',
    abbreviation: 'ELE',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'Civil',
    abbreviation: 'CIV',
    fieldList1: 'field'
  },
];
