import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotePad from './NotePad';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as invoiceSelectors, actions as invoiceActions } from 'ducks/salesInvoiceEnquiry/Invoice';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  invoiceDetails: invoiceSelectors.details(state),
  selectedInvoice: invoiceSelectors.selected(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getInvoiceSummary: invoiceActions.getSummary,
    getInvoiceDetails: invoiceActions.getDetails,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NotePad);
