import { connect } from 'react-redux';
import QuoteDetails from './QuoteDetails';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as quoteSelectors } from 'ducks/customerQuoteEnquiry/quote';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  quoteDetails: quoteSelectors.quoteDetails(state),
  quoteDetailsSchema: quoteSelectors.quoteDetailsSchema(state),
  quoteSummary: quoteSelectors.quoteSummary(state),
  quoteSummarySchema: quoteSelectors.quoteSummarySchema(state),
  selectedQuote: quoteSelectors.selected(state),
});

export default connect(mapStateToProps, {})(QuoteDetails);
