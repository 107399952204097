import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as userFieldsSelector, actions as userFieldsActions } from 'ducks/inventoryEnquiry/userFields';
import { fetch } from 'api/inventoryEnquiry/UserFields';
import { IApplicationState } from 'ducks/reducers';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'InventoryUserFields',
  columnDefs: userFieldsSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: userFieldsSelector.gridOptions(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: userFieldsActions.setSelected
};
