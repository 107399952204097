import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as jobTemplateActions, selectors as jobTemplateSelectors } from 'ducks/jobTemplateMaintenance/jobTemplate';
import { IJobtemplateSearchFacade, IJobtemplateDetailFacade } from 'api/swaggerTypes';
import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

const JobTemplateSearchLookUpDrawer = buildSearchLookUpDrawer<IJobtemplateSearchFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IJobtemplateDetailFacade>,
    'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IJobtemplateDetailFacade>,
    'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'JobTemplates',
        searchPlaceholder: 'Search for Job Templates...',
        operationMode: operationSelectors.operationMode(state),
        sortingFilters: [
            {
                filter: 'TemplateCode',
                label: 'Job template'
            },
            {
                filter: 'Description',
                label: 'Description'
            }
        ],
        records: jobTemplateSelectors.all(state) || [],
        selectedRecord: jobTemplateSelectors.selected(state),
        selectedSortFilter: 'TemplateCode',
        selectedSortDirection: 'Ascending',
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: jobTemplateSelectors.isLoading(state),
        nextPage: jobTemplateSelectors.nextPage(state),
        prevPage: jobTemplateSelectors.prevPage(state),
        pageSize: jobTemplateSelectors.pageSize(state),
        totalPages: jobTemplateSelectors.totalPages(state),
        loadingPrevPage: jobTemplateSelectors.loadingPrevPage(state),
        loadingNextPage: jobTemplateSelectors.loadingNextPage(state),
        searchParams: {},
        keyField: 'TemplateCode',
        fieldsToDisplay: ['TemplateCode', 'Description', null, null],
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
            if (selectedRecord) {
                matchParams.set('TemplateCode', selectedRecord.TemplateCode);
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const templateCode = matchParams.get('TemplateCode');
            if (templateCode) {
                return {
                    id: templateCode,
                };
            }

            return null;
        }
    });

const actionCreators: DispatchProps = {
    changeSelectedRecord: jobTemplateActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    search: jobTemplateActions.search,
    searchById: jobTemplateActions.searchById,
    fetchNextPage: jobTemplateActions.fetchNextPage,
    fetchPrevPage: jobTemplateActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobTemplateSearchLookUpDrawer);
