export const SMALL_COLUMN_MIN_WIDTH = 100;

export const LARGE_COLUMN_MIN_WIDTH = 200;

export const ICON_COLUMN_MIN_WIDTH = 60;

export const WAREHOUSE_COLUMN_WIDTH = 105;

export const InitialLineDetails = {
  FromWarehouse: '',
  LineNumber: [],
  ProductId: 0
};
