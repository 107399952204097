import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PrintDialog from './PrintDialog';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';

const mapStateToProps = (state) => ({
    pickSlipDocumentOutputOptions: documentOutputSelectors.options('SO', 'PickSlipPrintData')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        setPickSlipDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'PickSlipPrintData', options),
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(PrintDialog);
