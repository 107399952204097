import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils';

export const { types, actions } = createActions(
  {
    setSelected: (transactionId) => ({ transactionId }),
    setSelectedTransactionLine: (transactionId) => ({ transactionId }),
    changeSortFilter: (selectedSortFilter) => selectedSortFilter,
    changeSortDirection: (selectedSortDirection) => selectedSortDirection,
    clear: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      search: (query) => (query),
      fetchTransactionDetails: (TransactionId) => ({ TransactionId }),
      fetchTransactionSummary: (TransactionId) => ({ TransactionId })
    }
  },
  'transactions');

const RequiredFields = [
  'EntityView', 'TransactionType', 'TransactionOption'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const NOT_SELECTED = -1;
const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    'EntityView': '',
    'Entity': '',
    'TransactionType': 'AL',
    'TransactionOption': 'Outstanding',
    'FromDate': '',
    'ToDate': '',
    'Invoice': null,
  },
  filterRow: {
    formName: 'CETFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item, index) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Transaction type',
          name: 'TransactionType',
          defaultValue: 'AL',
          required: false,
          apiPrefs: {
            path: '/CustomTypes/Lookup/TransactionType/Search',
            method: 'POST',
            params: {
              BatchSize: 20
            }
          },
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'TransactionOption',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Outstanding',
              label: 'Outstanding'
            },
            {
              value: 'Current',
              label: 'Current'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            }
          ]
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Invoice',
          name: 'Invoice',
          size: 'small',
          required: false
        }
      },
    ]
  },
  selectedTransactionLine: NOT_SELECTED,
  summary: null,
  details: null,
  transactionDetails: null,
  selectedSortFilter: 'InvoiceNumber',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: 'TransactionDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  transactionLineActions: {},
  transactionLineColumns: [
    { headerName: 'Date', field: 'TransactionDate', type: 'numericColumn', minWidth: 120, },
    { headerName: 'Entity', field: 'SalesEntity', suppressSorting: true, minWidth: 120, },
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.InvoiceNumber) {
          return {
            SalesEntity: row.data.InvoiceSalesEntity,
            InvoiceNumber: row.data.InvoiceNumber,
            FieldValue: row.data.InvoiceNumber
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Transaction type', field: 'TransactionType', suppressSorting: true, minWidth: 200, },
    { headerName: 'Original total', field: 'OriginalTotalDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Currency', field: 'Currency', suppressSorting: true, minWidth: 120, },
    { headerName: 'Financial period', field: 'Period', suppressSorting: true, minWidth: 200, },
    { headerName: 'Due', field: 'DueDate', suppressSorting: true, minWidth: 150, type: 'numericColumn', },
    { headerName: 'Reference', field: 'Reference', suppressSorting: true, minWidth: 150, },
    { headerName: 'G/L batch', field: 'GLBatch', suppressSorting: true, minWidth: 100, type: 'numericColumn', },
    { headerName: 'Local original', field: 'LocalOriginalDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Local amount', field: 'LocalAmountDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, }
  ],
  transactionLinesData: null,

});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      };
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.search:
      return asyncOnRequest(state, action);

    case types.setSelected:
      const selected = action.data.transactionId;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.setEntityView:
      const entityView = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.setSelectedTransactionLine:
      const selectedTransactionLine = action.data.transactionId;

      return {
        ...state,
        data: { ...state.data, selectedTransactionLine }
      };

    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {

        return {
          ...data,
          transactionLinesData: action.payload
        };
      });
    case types.saga.search.failure:
      return asyncOnError(state, action);

    case types.fetchTransactionDetails:
    case types.fetchTransactionSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetchTransactionDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          details: {
            ...action.payload.inlineObject,
            schema: action.payload.schema
          }
        };
      });
    case types.saga.fetchTransactionSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          summary: action.payload
        };
      });
    case types.saga.fetchTransactionDetails.failure:
    case types.saga.fetchTransactionSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.transactions,
  {
    linesData: (data) => data.transactionLinesData,
    details: (data) => data.details,
    summary: (data) => data.summary
  }
);

const syncSelector = {
  selected: (state) => state.customerEnquiry.transactions.data.selected,
  selectedSortFilter: (state) => state.customerEnquiry.transactions.data.selectedSortFilter,
  selectedSortDirection: (state) => state.customerEnquiry.transactions.data.selectedSortDirection,
  isLoading: (state) => state.customerEnquiry.transactions.search_loading,
  loadingSummary: (state) => state.customerEnquiry.transactions.fetchTransactionSummary_loading,
  gridOptions: (state) => state.customerEnquiry.transactions.data.gridOptions,
  lineColumns: (state) => state.customerEnquiry.transactions.data.transactionLineColumns,
  lineActions: (state) => state.customerEnquiry.transactions.data.transactionLineActions,
  transactionLineActions: state => state.customerEnquiry.transactions.transactionLineActions,
  transactionDetails: (state) => state.customerEnquiry.transactions.data.transactionDetails,
  filterRow: (state) => state.customerEnquiry.transactions.data.filterRow,
  selectedFilters: (state) => state.customerEnquiry.transactions.data.selectedFilters
};

export const selectors = { ...asyncSelector, ...syncSelector };
