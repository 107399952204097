import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, getFormSyncErrors, reset } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as technicianSelectors } from 'ducks/rosterMaintenance/technician';
import { selectors as rosterEntriesSelectors, actions as rosterEntriesActions } from 'ducks/rosterMaintenance/rosterEntries';
const params = new URLSearchParams(location.search);

import RosterLookup from './RosterLookup';
const FORM_NAME = 'RosterLookup';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  values: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedTechnician: technicianSelectors.selected(state) || parseInt(params.get('TechnicianId')),
  selectedEntry: rosterEntriesSelectors.selected(state) || parseInt(params.get('RosterTemplateId')),
  rosterEntryData: rosterEntriesSelectors.rosterEntryData(state),
  isFormLoading: rosterEntriesSelectors.isFormLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeOperationMode: operationActions.changeOperationMode,
    createRosterEntry: rosterEntriesActions.create,
    removeRosterEntry: rosterEntriesActions.delete,
    updateRosterEntry: rosterEntriesActions.update,
    fetchRosterData: rosterEntriesActions.fetchRosterData,
    resetForm: reset
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RosterLookup);
