import { takeLatest, Effect } from 'redux-saga/effects';
import { types as checklistTypes, actions as checklistActions } from 'ducks/checklistMaintenance/checklist';
import { connectSagaToApi } from '../utils';
import * as api from 'api/checklistMaintenance/checklist';

export default function* rootChecklistMaintenanceSaga(): IterableIterator<Effect> {
  yield takeLatest(checklistTypes.search, connectSagaToApi(checklistActions, 'search', api.search));
  yield takeLatest(checklistTypes.fetchPrevPage, connectSagaToApi(checklistActions, 'fetchPrevPage', api.search));
  yield takeLatest(checklistTypes.fetchNextPage, connectSagaToApi(checklistActions, 'fetchNextPage', api.search));
  yield takeLatest(checklistTypes.searchById, connectSagaToApi(checklistActions, 'searchById', api.searchById));
}
