import { IFormViewForm } from 'components/FormView';

const ChecklistMaintenanceDetails: IFormViewForm = {
    id: 'ChecklistMaintenanceDetail',
    layout: {
        rows: 1,
        columns: 2
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1,
                colspan: 1.5
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Checklist',
                        name: 'ChecklistCode',
                        required: true,
                        placeholder: '',
                        size: 'medium',
                        inputProps: {
                            maxLength: 10
                        }
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Description',
                        name: 'Description',
                        required: true,
                        placeholder: '',
                        size: 'large',
                        inputProps: {
                            maxLength: 40
                        }
                    }
                },
                {
                    id: '3',
                    type: 'SELECT_FIELD',
                    visible: true,
                    props: {
                        label: 'Type',
                        name: 'DataType',
                        placeholder: '',
                        size: 'small',
                        apiPrefs: {
                            path: '/CustomTypes/Lookup/ChecklistDataType',
                            method: 'GET',
                            params: null
                        },
                    }
                }
            ]
        },
        {
            id: '2',
            position: {
                row: 1,
                col: 2,
                colspan: 1.5
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '1',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Instructions',
                        name: 'Instructions',
                        fullWidth: true,
                        inputProps: {
                            maxLength: 100
                        },
                        style: {
                            height: 250
                        }
                    },
                }
            ]
        },
    ]
};
export default ChecklistMaintenanceDetails;
