import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import ProcessIcon from '@markinson/uicomponents-v2/SvgIcons/Process';

import IWorksaleMessagesProps from './WorksaleMessages.properties';
import styles from './WorksaleMessages.styles';
import classNames from 'classnames';

const TINY_COLUMN_WIDTH = 50;
const SMALL_COLUMN_WIDTH = 100;
const LARGE_COLUMN_MIN_WIDTH = 200;

function WorksaleMessages(props: IWorksaleMessagesProps): JSX.Element {
  const { classes, open, dialogTitle, messages = [], onBack, onContinue, description } = props;

  const [continueDisabled, setContinueDisabled] = React.useState(true);

  React.useEffect(
    () => {
      if (messages) {
        const errorCount = messages
          .map((message) => message.MessageType)
          .filter((messageType) => messageType !== 'W').length;
        setContinueDisabled(errorCount > 0);
      }
    },
    [messages]);

  return (<Dialog
    classes={{
      paper: classes.dialog,
    }}
    disableBackdropClick
    disableEscapeKeyDown
    disableEnforceFocus
    maxWidth={'sm'}
    style={{ whiteSpace: 'pre-line' }}
    aria-labelledby={'confirmation-dialog-title'}
    open={open}  >
    <DialogTitle className={classes.dialogTitle} id={'confirmation-dialog-title'}>
      <div className={classes.dialogTitleHeading}>{dialogTitle || 'Processing Problems'}</div>
    </DialogTitle>
    <DialogContent className={classes.modalContent}>
      {
        description ?
          (
            description.split('\n').map((item: string) =>
              <p key={item}>
                <span>{item}</span>
              </p>
            )
          ) :
          (
            <>
              <p>
                <span>The issues listed below have been identified during processing. Any errors found must be corrected before you can continue.</span>
              </p>
              <p>
                <span>Select BACK to return to the sale entry, Warnings and Errors are highlighted in the sale lines grid.</span>
              </p>
            </>
          )
      }

      <div className={classes.warningGridContainer}>
        <DataGrid
          className={classes.warningGrid}
          noDataText=''
          keyExpr={'MessageSequence'}
          dataSource={messages.map((message) => ({
            MessageSequence: message.MessageSequence,
            OrderLine: message.MessageKey || undefined,
            MessageType: message.MessageType === 'W' ? 'WARNING' : 'ERROR',
            MessageDetail: message.MessageDetail
          }))}
          allowColumnResizing={false}
          showBorders={false}
          onCellPrepared={(e) => {
            if (e.rowType !== 'data' || e.columnIndex !== 1) {
              return;
            }
            if (e.data.MessageType === 'ERROR') {
              e.cellElement.style.color = 'Red';
            }
          }}
        >
          <Column dataField={'OrderLine'} caption={'Line'} minWidth={TINY_COLUMN_WIDTH} />
          <Column dataField={'MessageType'} caption={'Type'} minWidth={SMALL_COLUMN_WIDTH} />
          <Column dataField={'MessageDetail'} caption={'Message'} minWidth={LARGE_COLUMN_MIN_WIDTH} />
          <Scrolling mode={'standard'} />
        </DataGrid>
      </div>
    </DialogContent>
    <DialogActions className={classes.dialogActionBar}>
      <Button style={{ float: 'right' }} className={classes.actionBtn} onClick={onContinue} color='default' disabled={continueDisabled}>
        <div className={classes.buttonTitle}><ProcessIcon className={continueDisabled ? classes.disabledIconStyle : classes.iconStyle} />Continue</div>
      </Button>
      <Button style={{ float: 'right' }} className={classNames(classes.actionBtn, !onBack && classes.hide)} onClick={onBack} color='default'>
        <div className={classes.buttonTitle}><ArrowBack className={classes.iconStyle} />Back</div>
      </Button>
    </DialogActions>
  </Dialog >);
}

export default withStyles(styles)(WorksaleMessages);
