import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchComponentDetails: (query) => (query),
    fetchComponentSummary: (query) => (query),
  }
}, 'componentDetails')

let initialState = asyncInitialState({
  componentDetails: '',
  componentSummary: '',
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.fetchComponentDetails:
    case types.fetchComponentSummary:
      return asyncOnRequest(state, action)

    case types.saga.fetchComponentDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          componentDetails: action.payload
        }
      })
    case types.saga.fetchComponentSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          componentSummary: action.payload
        }
      })
    case types.saga.fetchComponentDetails.failure:
    case types.saga.fetchComponentSummary.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.componentDetails,
  {
    componentDetails: data => data.componentDetails,
    componentSummary: data => data.componentSummary,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.componentDetails.loading,
  loadingSummary: state => state.salesOrderEnquiry.componentDetails.fetchComponentSummary_loading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)



