import React from 'react';
import { IAddActivityModal, IAddActivityData, IParamsData } from '../NewJobModal.properties';
import { LookUpActionField as ExtendedLookUpActionField } from 'components/common/ExtendedLookUpActionField/LookUpActionField';
import MaterialButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isNull, pathOr } from 'utils/utils';
import { searchById } from 'api/activityMaintenance/activity';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

export function AddActivityModal(props: IAddActivityModal): JSX.Element {
    const {
        open,
        data,
        editMode = false,
        CustomerId,
        handleClose,
    } = props;

    const [addActivityForm, setAddActivityForm] = React.useState<IAddActivityData>(null);
    const [touched, setTouched] = React.useState<boolean>(false);
    const [params, setParams] = React.useState<IParamsData>({ NoServiceItemType: true });

    React.useEffect(
        () => {
            setAddActivityForm(data);
        },
        [data]
    );

    async function handleOk(): Promise<void> {
        if (isNull(addActivityForm) || isNull(addActivityForm.ActivityCode)) {
            setTouched(true);

            return;
        }

        const result = await isValidActivity();
        switch (result.validation) {
            case 'true':
                break;
            case 'false':
                showSnackBar({ variant: 'warning', message: 'Only activities with valid Labour Product can be added.' });

                return;
            case 'error':
            default:
                showSnackBar({ variant: 'error', message: 'Some error occured.' });

                return;
        }
        if (handleClose) {
            handleClose({ ...result.data, ...addActivityForm });
            setTouched(false);
        }
    }
    const isValidActivity = async () => {
        const res: any = await searchById({ ActivityCode: addActivityForm.ActivityCode });

        if (isNull(res.records)) {
            return { validation: 'error', data: null };
        }

        const labourProd = res.records[0].inlineObject.LabourProductId;

        return { validation: isNull(labourProd) ? 'false' : 'true', data: res.records[0].inlineObject };
    };

    const onSelectedItemChangeHandler = (v: any): void => {
        const servItemType = pathOr(false, ['Details', 'ServiceItemType'], v);
        if (servItemType) {
            setParams({ ServiceItemTypeId: servItemType });
        } else {
            setParams({ NoServiceItemType: true });
        }
        setAddActivityForm((prev) => ({ ...prev, ServiceNo: v && Number(v.Code), ServiceNoDisplay: v && v.Display || null, ServiceNoDisplayLabel: v && v.Label, ActivityCode: '' }));
    };

    return (<div>
        <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            disableEnforceFocus
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{`${editMode ? 'Edit' : 'Add'} Activity`}</DialogTitle>
            <DialogContent>
                <ExtendedLookUpActionField
                    label={'Service Item'}
                    lookupName={'CustomerServiceItem'}
                    size='medium'
                    params={{ CustomerId }}
                    value={addActivityForm && `${addActivityForm.ServiceNo}`}
                    onSelectedItemChange={onSelectedItemChangeHandler}
                    suppressDescription />
                <ExtendedLookUpActionField
                    touched={touched}
                    label={'Activity'}
                    lookupName={'ActiveActivityCode'}
                    size='medium'
                    value={addActivityForm && addActivityForm.ActivityCode}
                    onSelectedItemChange={(value) => {setAddActivityForm((prev) => ({ ...prev, ActivityCode: value?.Display })); }}
                    params={params}
                    suppressDescription
                    required />
            </DialogContent>
            <DialogActions>
                <MaterialButton onClick={() => handleClose && handleClose()} color='primary'>
                    Cancel
                </MaterialButton>
                <MaterialButton onClick={handleOk} color='primary'>
                    {editMode ? 'Update' : 'Add'}
                </MaterialButton>
            </DialogActions>
        </Dialog>
    </div>);
}
