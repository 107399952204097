import { fetchPost, fetchGet, objectify } from '../../utils'
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import moment from 'moment';
export const fetch = (query) => {
  const { SupplierId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const respType = 'Inline';

  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(
    `/SupplierEnquiry/RFC/Supplier/${SupplierId}/Retrieve`,
    filters && { ...filters, FromDate: From, ToDate: To, },
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    respType
  )
    .then(
      (result) => {
        if (result)
          if (result.SupplierRFCs)
            return {
              records: result.SupplierRFCs.map(item => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            }
          else
            return result

      })
}

export const fetchSummary = (query) => {
  const { Supplier } = query
  const respType = 'Inline';

  return fetchGet(`/SupplierEnquiry/RFC/Summary/Supplier/${Supplier}`, null, respType).then((result) => result.SupplierRFCSummary && objectify(result.SupplierRFCSummary));
}
