export default [
  {
    id: '1',
    label: 'Inventory Enquiry in new tab',
    'action': (params) => { window.open('/inventory-enquiry' + ( params ? '/' + params : '' )) }
  },
  {
    id: '2',
    label: 'Inventory Enquiry',
    'action': (params) => { window.location.pathname = '/inventory-enquiry' + ( params ? '/' + params : '' ) }
  }
];
