import * as React from 'react';
import FormView from '../../FormView/index';
import { withRouter } from 'react-router';
import { Operation } from 'utils/operations';
import UserNotifications from '../../common/UserNotifications';
import { IContactProps } from '../interfaces';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class Contacts extends React.PureComponent<IContactProps> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      onInitialLoad('Contacts');
    }
  }

  componentDidUpdate(prevProps: Readonly<IContactProps>): void {
    const { operationMode, selectedContact } = this.props;
    if ((prevProps.operationMode === Operation.EDIT || prevProps.operationMode === Operation.NEW)
      && operationMode === Operation.SAVE
    ) {
      this.submitFormValues(prevProps.operationMode === Operation.NEW);
    }
    if (operationMode === Operation.DELETE && selectedContact) {
      this.deleteAction();
    }
    if (operationMode === Operation.CANCEL || operationMode === Operation.BACK) {
      this.cancelFormSubmission(prevProps);
    }
  }

  cancelFormSubmission = (prevProps: Readonly<IContactProps>) => {
    const {
      dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad, initializeForm, selectedContact, operationMode, resetForm
    } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          resetForm();
          changeOperationMode(Operation.BROWSE);
          initializeForm(selectedContact);
        }
      });
    } else {
      if (operationMode === Operation.CANCEL) {
        resetForm();
        changeOperationMode(Operation.BROWSE);
        initializeForm(selectedContact);
      } else {
        if (prevProps.operationMode === Operation.EDIT) {
          changeOperationMode(Operation.BROWSE);
        } else {
          onInitialLoad('CustomerDetails');
          changeOperationMode(Operation.BROWSE);
        }
      }
    }
  }

  deleteAction = () => {
    const { changeConfirmationDialog, changeOperationMode } = this.props;
    changeConfirmationDialog({
      open: true,
      title: 'Delete selected record',
      message: 'Are you sure you want to delete the selected record?',
      okLabel: 'Delete',
      onCancel: () => {
        changeOperationMode(Operation.BROWSE);
      },
      onOk: () => {
        this.removeRecord();
      }
    });
  }

  removeRecord = () => {
    const { changeOperationMode, location, resetForm, removeContact, selectedContact, selectedCustomer } = this.props;
    const params = new URLSearchParams(location.search);
    const CustomerId = (selectedCustomer && selectedCustomer.CustomerId) || parseInt(params.get('CustomerId'));
    if (selectedContact) removeContact(CustomerId, selectedContact.ContactNumber);
    resetForm();
    changeOperationMode(Operation.BROWSE);
  }

  submitFormValues = (create?: boolean): void => {
    const { selectedContact, values, updateContact, isValid, formSyncErrors, changeOperationMode, createContact, selectedCustomer, initializeForm } = this.props;
    if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0)) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      changeOperationMode(Operation.EDIT);
      if (create) {
        initializeForm(values);
      }

      return;
    }

    const params = new URLSearchParams(location.search);
    const CustomerId = (selectedCustomer && selectedCustomer.CustomerId) || parseInt(params.get('CustomerId'));

    if (create) {
      createContact(CustomerId, values);
    } else if (selectedContact && CustomerId) {
      updateContact(CustomerId, selectedContact.ContactNumber, values);
    }

    changeOperationMode(Operation.BROWSE);
  }

  getInitialValues = (): any => {
    const { selectedContact, selectedContactSchema, operationMode } = this.props;
    if (operationMode === Operation.NEW) {
      return {
        initialValues:
          {},
        valuesSchema:
          {}
      };
    }

    return { initialValues: selectedContact, valuesSchema: selectedContactSchema };
  }

  render(): React.ReactNode {
    const {
      location, selectedForm, operationMode, dirty, isValid, formSyncErrors, contactNotifications, contactError,
      selectedCustomer, isV2 } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };
    const params = new URLSearchParams(location.search);
    const CustomerId = selectedCustomer ? selectedCustomer.CustomerId : parseInt(params.get('CustomerId'));

    return (selectedForm &&
      <React.Fragment>
        {((dirty
          && operationMode === Operation.SAVE) || (operationMode === Operation.BROWSE))
          && <UserNotifications
            notifications={contactNotifications}
            errors={contactError}
            formSyncErrors={!isValid && formSyncErrors}
            entity={'Contact'}
            isValid={isValid}
          />}

        {(selectedForm.id === 'Contacts') &&
          (<FormView
            formName={'CustomerContacts'}
            includeTabsHeight={true}
            schema={selectedForm}
            browseLookUpOpen={true}
            browseLookUpComponent={CustomerId && {
              variant: 'Contacts',
              searchParams: {
                CustomerId: CustomerId
              },
              searchPlaceholder: 'Search for contacts...',
              isBrowseLookUp: true
            }}
            initialValues={initialValues}
            operationMode={operationMode}
            valuesSchema={valuesSchema}
            fieldExtensions={{
              address: {
                hideCountry: isV2
              }
            }}
          />)}
      </React.Fragment>
    );
  }
}

export default withRouter(Contacts);
