import React from 'react';
import IServiceAgreementMaintenanceProperties from './ServiceAgreementMaintenance.properties';
import LookUpDrawer from '../common/SearchLookUpDrawer/Containers/ServiceAgreement';
import BreadcrumbBar from '@markinson/uicomponents-v2/BreadcrumbBar';
import * as styles from './ServiceAgreementMaintenance.styles.scss';
import modules from './ServiceAgreementMaintenance.modules';
import ServiceAgreementDetail from './ServiceAgreementDetail';
import { NavigationTabs } from '@markinson/uicomponents-v2';
import { TABS_DATA } from './ServiceAgreementMaintenance.constants';
import { withRouter, Route } from 'react-router-dom';
import { getKeyByValue, isNull } from 'utils/utils';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './ServiceAgreementMaintenance.options';
import ActionBar from '@markinson/uicomponents-v2/ActionBar';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { IActionBarHandlers, ISelectedServiceAgreement } from './ServiceAgreementDetail/ServiceAgreementDetail.properties';
import { fetchServiceAgreement, fetchServiceAgreementSchedules } from 'api/serviceAgreementMaintenance/serviceAgreement';

const ServiceAgreementMaintenance = (props: IServiceAgreementMaintenanceProperties): JSX.Element => {
  const {
    match,
    history,
    ServiceAgreementId,
    isCustomerLookUpOpen
  } = props;

  const { actionBarProps } = React.useContext(ActionBarContext);

  const url = match ? match.url : '';
  const path = match ? match.path : '';

  const tabLocations = {
    ServiceAgreementDetail: url,
  };

  const [optionsMenuOpen, setOptionsMenuOpen] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>('ServiceAgreementDetail');
  const ServiceAgreementDetailRef = React.useRef<IActionBarHandlers>();
  const [fetchAgreementLoading, setFetchAgreementLoading] = React.useState<boolean>(false);
  const [selectedServiceAgreement, setSelectedServiceAgreement] = React.useState<ISelectedServiceAgreement>(null);

  React.useEffect(
    () => {
      fetchPatternDetailsRecord();
    },
    []);

  React.useEffect(
    () => {
      return () => {
        props.resetSearchLookupDrawer();
        props.destoryForm();
      };
    },
    []
  );

  React.useEffect(
    () => {
      const pathname = location ? location.pathname : '';
      const tabByLocation = getKeyByValue(tabLocations, pathname);
      setSelectedTab(tabByLocation);
    },
    [location]);

  function handleModuleChange(newSelectedTab: string): void {
    history.push(tabLocations[newSelectedTab]);
  }

  function handleOptionClick(newSelectedTab: string): void {
    handleModuleChange(newSelectedTab);
  }

  function navigateBack(): void {
    if (!selectedTab) {
      history.push('/dashboard');
    }
    if (selectedTab) {
      const targetModule = modules.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        history.push('/dashboard');
      } else {
        handleModuleChange(targetModule.parent);
      }
    }
  }

  function handleActionClick(action: string): void {
    const detailsRef = ServiceAgreementDetailRef.current;
    switch (action) {
      case 'Back':
        navigateBack();
        break;
      case 'Edit':
        detailsRef.onEdit();
        break;
      case 'Cancel':
        detailsRef.onCancel();
        break;
      case 'New':
        detailsRef.onNew();
        break;
      case 'Save':
        detailsRef.onOk();
        break;
      case 'Delete':
        detailsRef.onDelete();
        break;
      case 'Add':
        detailsRef.onAdd();
        break;
      default:
    }
  }

  async function fetchPatternDetailsRecord(): Promise<void> {
    if (!isNull(ServiceAgreementId) && fetchServiceAgreement) {
      try {
        setFetchAgreementLoading(true);
        const serviceAgreementResponse = await fetchServiceAgreement(ServiceAgreementId);
        const schedulesResponse = await fetchServiceAgreementSchedules(ServiceAgreementId);

        setSelectedServiceAgreement(serviceAgreementResponse.ServiceAgreementDetails ?
          { ...serviceAgreementResponse.ServiceAgreementDetails.inlineObject, Schedules: schedulesResponse.ServiceAgreementSchedules.map((s) => s.inlineObject) }
          : null);
      } catch (err) {
        console.warn(err);
      } finally {
        setFetchAgreementLoading(false);
      }
    }
  }

  function getRecordChangeCallBacks(): (() => void)[] {
    const callBacks = [];
    switch (selectedTab) {
      case 'ServiceAgreementDetail':
        callBacks.push(fetchPatternDetailsRecord);
        break;
      default:
    }

    return callBacks;
  }

  return (
    <div className={styles.serviceItemTypeOuter}>
      <div className={styles.serviceItemTypeContainer}>
        <LookUpDrawer
          enableToggle={true}
          recordChangeCallBacks={getRecordChangeCallBacks()}
        />
        <div className={styles.serviceItemTypeSection} >
          <BreadcrumbBar
            onOptionsMenuClick={() => { setOptionsMenuOpen(!optionsMenuOpen); }}
            mainModule={'Service Agreement Maintenance'}
            activeModuleId={selectedTab}
            moduleTree={modules}
            onBreadcrumbClick={(moduleId) => { handleModuleChange(moduleId); }}
            onMainModuleClick={() => { handleModuleChange('ServiceAgreementDetail'); }}
          />
          {TABS_DATA.some(({ id }) => id === selectedTab) ? <NavigationTabs
            lookupOpen={isCustomerLookUpOpen}
            tabsData={TABS_DATA}
            activeTabId={selectedTab}
            onTabChange={(tab) => { handleModuleChange(tab); }}
          /> : null}
          <Route
            key={'Lines'}
            path={path}
            exact
            render={(routeProps) => {
              return <ServiceAgreementDetail
                {...routeProps}
                loading={fetchAgreementLoading}
                selectedServAgreement={selectedServiceAgreement}
                ref={ServiceAgreementDetailRef}
              />;
            }} />
        </div>
      </div>
      <OptionsMenu
        open={optionsMenuOpen}
        options={options}
        onOptionClick={handleOptionClick}
        defaultValue={selectedTab}
        onClickAway={() => { setOptionsMenuOpen(false); }}
      />
      <ActionBar {...actionBarProps} onActionClick={handleActionClick} />
    </div>
  );
};

export default withRouter(ServiceAgreementMaintenance);
