import { IState } from './PickSlips.reducer';

export interface IActions {
    [PickSlipsActionTypes.setPriceOverrideDialog]?: IState['PriceOverrideDialog'];
    [PickSlipsActionTypes.setLostSaleDialog]?: IState['lostSaleDialog'];
    [PickSlipsActionTypes.setDespatchId]?: IState['DespatchId'];
    [PickSlipsActionTypes.setLineNumber]?: IState['LineNumber'];
    [PickSlipsActionTypes.setDespatchLineId]?: IState['DespatchLineId'];
    [PickSlipsActionTypes.setComponentLineNumber]?: IState['ComponentLineNumber'];
    [PickSlipsActionTypes.setComponentId]?: IState['ComponentId'];
    [PickSlipsActionTypes.setMultipleBomState]?: IState['multipleBomDialog'];
}

export enum PickSlipsActionTypes {
    setPriceOverrideDialog = 'setPriceOverrideDialog',
    setLostSaleDialog = 'setLostSaleDialog',
    setDespatchId = 'setDespatchId',
    setLineNumber = 'setLineNumber',
    setDespatchLineId = 'setDespatchLineId',
    setComponentLineNumber = 'setComponentLineNumber',
    setComponentId = 'setComponentId',
    setMultipleBomState = 'setMultipleBomState',
}
