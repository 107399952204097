
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions } from 'ducks/ui';
import Lines from './Lines';
import { actions as documentOutputActions } from 'ducks/documentOutput';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        setPurchaseOrderOuputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'PurchaseOrderOutput', options),
    },
    dispatch);

export default connect(null, mapDispatchToProps)(Lines);
