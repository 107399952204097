import { IFormViewForm } from 'components/FormView';

const CatalogueProductDetail: IFormViewForm = {
  id: 'CatalogueProductDetailsForm',
  layout: {
    rows: 3,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_FIELD',
          props: {
            label: 'Sequence',
            name: 'Sequence',
            type: 'number',
            size: 'small',
            alignment: 'right'
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Quantity',
            name: 'Quantity',
            type: 'number',
            size: 'small',
            alignment: 'right',
            decimalSeparator: true,
            decimalPlaces: 2
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Created',
            name: 'CreatedDate',
            placeholder: '',
            size: 'medium',
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Modified',
            name: 'ModifiedDate',
            placeholder: '',
            size: 'medium',
          }
        },
      ]
    },
    {
      id: '2',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'IMAGE_VIEW',
          props: {
            name: 'Image'
          }
        }
      ]
    },
    {
      id: '3',
      position: {
        row: 2,
        col: 1,
        colspan: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            size: 'large',
            style: {
              height: '300px'
            }
          }
        }
      ]
    },
  ]
};
export default CatalogueProductDetail;
