export default [
  {
    id: '1',
    label: 'Customer Quote Enquiry in new tab',
    'action': (params) => { window.open('/customer-quote-enquiry' + ( params ? '/' + params : '' )) }
  },
  {
    id: '2',
    label: 'Customer Quote Enquiry',
    'action': (params) => { window.location.pathname = '/customer-quote-enquiry' + ( params ? '/' + params : '' ) }
  }
];
