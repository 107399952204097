import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/priceCheck/trueCost';

import { callApi } from '../utils';
import * as api from 'api/priceCheck/trueCost';

function* fetchDefaultTrueCostValues(action) {
  const { success, failure } = actions.saga.fetchDefaultTrueCostValues;

  yield callApi(
    call(api.fetchDefaultTrueCostValues, action.data),
    success,
    failure
  )
}

export default function* rootSaga() {
  yield takeLatest(types.fetchDefaultTrueCostValues, fetchDefaultTrueCostValues)
}