import React, { Component } from 'react';


class FavouritesPanel extends Component{
  render(){

    return (
      <div>
        You have no favourites.
      </div>
    )
  }
}

export default FavouritesPanel;