import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button, } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import { ILotDialogProps } from './LotDialog.properties';
import styles from './LotDialog.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import ProductLotLookup from 'components/common/ProductLotLookup';
import { default as MUIButton } from '@material-ui/core/Button';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import UndoIcon from '@markinson/uicomponents-v2/SvgIcons/Undo';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { IBinLotFacade, IProductLotSearchFacade } from 'api/swaggerTypes';
import classNames from 'classnames';

import { isNull } from 'utils/utils';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import { COLUMN_SIZE } from '../DataGridDevEx/DataGrid.constants';
import { ENTER_KEY } from 'utils/constants';

const LotDialog = (props: ILotDialogProps): JSX.Element => {
  const {
    classes, open, onApply, onCancel, RequiredQuantity, BinLots,
    WarehouseEntity, ProductId, Worksale, WorksaleId, LineNumber, ReservationNumber, DocumentId, DocumentType, isBinTracked = false
  } = props;
  const [enteredQuantity, setEnteredQuantity] = React.useState(0);
  const [availableQuantity, setAvailableQuantity] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [lines, setLines] = React.useState<IBinLotFacade[]>([]);
  const [productLot, setProductLot] = React.useState<IProductLotSearchFacade>();
  const [productLotValue, setProductLotValue] = React.useState<unknown>();
  const [productLotDisplay, setProductLotDisplay] = React.useState<string>();
  const [isApplyDisabled, setIsApplyDisabled] = React.useState<boolean>(true);

  const quantityRef = React.useRef<ITextFieldHandle>();

  React.useEffect(
    () => { setLines(BinLots); },
    [BinLots]);

  React.useEffect(
    () => { updateQuantities(); },
    [lines]);

  React.useEffect(
    () => {
      shouldDisableApply(quantity);
    },
    [productLot, quantity]);

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        disabled: !RequiredQuantity || enteredQuantity !== RequiredQuantity,
        listener: async () => {
          if (onApply) {
            await onApply(lines);
          }
        }
      },
      {
        iconName: 'Cancel',
        isDefault: true,
        title: 'Cancel',
        listener: async () => {
          if (onCancel) {
            await onCancel();
          }
        }
      }
    ];
  };

  const NumberOfDecimalPlaces = 2;
  const SMALL_COLUMN_MIN_WIDTH = 75;
  const SMALL_COLUMN_DEFAULT_WIDTH = 100;
  const COMMAND_COLUMN_WIDTH = 75;
  const LARGE_COLUMN_MIN_WIDTH = 145;

  const shouldDisableApply = (value: number) => {
    setIsApplyDisabled(isNull(productLot) || (isNull(value) || value === 0));
  };

  const addLine = (qty?: number) => {
    if (isApplyDisabled) {
      return;
    }
    const quantityToAdd = isNull(qty) ? quantity : qty;
    const newLine = {
      ...productLot,
      Quantity: quantityToAdd
    };
    if (isNull(lines)) {
      setLines([newLine]);
    } else {
      const index = lines.findIndex((item) => item.LotSerial.toUpperCase() === productLot.LotSerial.toUpperCase());
      if (index < 0) {
        setLines([...lines, newLine]);
      } else {
        const updatedLines = [...lines];
        updatedLines[index].Quantity += quantityToAdd;
        setLines(updatedLines);
      }
    }
    updateQuantities();
    setTimeout(clearEntryData, 0);
  };

  const undoChanges = () => {
    clearEntryData();
  };

  const clearEntryData = () => {
    setQuantity(0);
    setProductLot(undefined);
    setProductLotValue(undefined);
    setProductLotDisplay('');
  };

  const getSum = (type) => {
    return isNull(lines) ? 0 : lines.reduce((a, b) => (a + +b[type]), 0);
  };

  const updateQuantities = () => {
    setAvailableQuantity(getSum('AvailableQuantity') || 0);
    setEnteredQuantity(getSum('Quantity') || 0);
  };

  const onQuantityFocus = () => {
    if (quantityRef.current) {
      quantityRef.current.select();
    }
  };

  const quantityBlur = () => {
    if (quantityRef.current) {
      quantityRef.current.blur();
    }
  };

  const onQuantityKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      quantityBlur();
      addLine(Number(event.target.value));
    }
  };
  const onQuantityChange = (value) => {
    shouldDisableApply(value);
  };

  const getModalContent = () => {
    return <div className={classes.mainDiv}>

      <div className={classes.lotLineDiv}>
        <div className={classes.inlineFields}>
          <ProductLotLookup
            WarehouseEntity={WarehouseEntity}
            ProductId={ProductId}
            Worksale={Worksale}
            WorksaleId={WorksaleId}
            LineNumber={LineNumber}
            ReservationNumber={ReservationNumber}
            DocumentId={DocumentId}
            DocumentType={DocumentType}
            value={productLotValue}
            display={productLotDisplay}
            suppressDescription={true}
            onBlur={(value, display, _description, record) => {
              if (value !== productLotValue) {
                if (record) {
                  setProductLotValue(value);
                  setProductLotDisplay(display);
                  setProductLot(record);
                } else {
                  setProductLotValue(undefined);
                  setProductLotDisplay('');
                  setProductLot(undefined);
                }
              }
            }}
            onCloseSearchScreen={(record: IBinLotFacade) => {
              const value = (record && record.LotSerial) ? record.LotSerial : null;

              if (value !== productLotValue) {
                if (record) {
                  setProductLotValue(record.LotSerial);
                  setProductLotDisplay(record.LotSerial);
                  setProductLot(record);
                } else {
                  setProductLotValue(undefined);
                  setProductLotDisplay('');
                  setProductLot(undefined);
                }
              }
            }}
          />
          <NumericField
            label={'Quantity'}
            size={'medium'}
            onFocus={onQuantityFocus}
            value={quantity}
            onBlur={setQuantity}
            onChange={onQuantityChange}
            ref={quantityRef}
            onKeyDown={onQuantityKeyDown}
            class={classNames(classes.inlineFieldsSpacing)}
          />
        </div>
        <div className={classes.inlineFields}>
          <MUIButton
            onClick={() => { addLine(); }}
            variant={'contained'}
            disabled={isApplyDisabled}
            className={classNames(classes.button, isApplyDisabled && classes.disabledBtn)}
          >
            <CheckCircleIcon style={{ color: 'green' }} />
            APPLY
          </MUIButton>
          <MUIButton
            onClick={undoChanges}
            variant={'contained'}
            className={classNames(classes.button)}
          >
            <UndoIcon style={{ color: '#990000' }} />
            Undo
          </MUIButton>
        </div>
      </div>
      <DataGrid
        keyExpr={'LotSerial'}
        className={classNames({
          [classes.lotLineDataGrid]: !Boolean(isBinTracked),
          [classes.binLotLineDataGrid]: Boolean(isBinTracked),
        })}
        dataSource={lines}
        repaintChangesOnly={true}
        noDataText=''
        remoteOperations={true}
        columnResizingMode={'nextColumn'}
        allowColumnReordering={true}
        allowColumnResizing={true}
        onRowUpdated={updateQuantities}
        onRowRemoved={updateQuantities}
        editing={{
          allowAdding: false,
          allowDeleting: true,
          allowUpdating: true,
          confirmDelete: false,
        }}
        onEditorPreparing={(e) => {
          e.editorOptions.onFocusIn = (args) => {
            const input = args.element.querySelector("input[type='text']");
            if (input) {
              input.select();
            }
          };
        }}
        showBorders={false}
        sorting={{
          mode: 'none'
        }}
        hoverStateEnabled={true}
      >
        <Column
          caption={'Lot number'}
          dataField={'LotSerial'}
          allowEditing={false}
          fixed={true}
          minWidth={SMALL_COLUMN_MIN_WIDTH}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
        />
        <Column
          caption={'Bin'}
          dataField={'Bin'}
          allowEditing={false}
          fixed={true}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm0}
          width={COLUMN_SIZE.md0}
        />
        <Column
          caption={'BinType'}
          dataField={'BinType'}
          allowEditing={false}
          visible={Boolean(isBinTracked)}
          minWidth={COLUMN_SIZE.sm0}
          width={COLUMN_SIZE.md0}
        />
        <Column
          caption={'Entered'}
          dataField={'Entered'}
          allowEditing={false}
          minWidth={SMALL_COLUMN_MIN_WIDTH}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
        />
        <Column
          caption={'Expiry'}
          dataField={'Expiry'}
          allowEditing={false}
          minWidth={SMALL_COLUMN_MIN_WIDTH}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
        />
        <Column
          caption={'Available'}
          dataField={'AvailableQuantity'}
          minWidth={SMALL_COLUMN_MIN_WIDTH}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
          allowEditing={false}
          alignment={'right'}
          calculateDisplayValue={
            (data: Record<string, unknown>) => Number(data.AvailableQuantity).toFixed(NumberOfDecimalPlaces)
          }
        />
        <Column
          dataField={'Quantity'}
          caption={'Quantity'}
          minWidth={SMALL_COLUMN_MIN_WIDTH}
          width={SMALL_COLUMN_DEFAULT_WIDTH}
          alignment={'right'}
          calculateDisplayValue={
            (data: Record<string, unknown>) => Number(data.Quantity).toFixed(NumberOfDecimalPlaces)
          }
        />
        <Column
          dataField={'Description'}
          caption={'Description'}
          allowEditing={false}
          minWidth={LARGE_COLUMN_MIN_WIDTH}
        />
        <Column type={'buttons'} width={COMMAND_COLUMN_WIDTH} allowResizing={false} fixed={true}>
          <Button name={'save'} icon={'save'} hint={'Update Changes'} />
          <Button name={'edit'} icon={'edit'} hint={'Edit Quantity'} />
          <Button name={'delete'} icon={'trash'} hint={'Remove Line'} />
          <Button name={'cancel'} icon={'undo'} hint={'Undo Changes'} />
        </Column>
        <GroupPanel visible={false} />
        <SearchPanel visible={false} />
        <Scrolling mode={'virtual'} />
        <LoadPanel enabled={false} />
      </DataGrid>
      <Table>
        <TableBody>
          <TableRow className={classes.bottomRow}>
            <div>
              <TableCell className={classes.headingCell}>REQUIRED</TableCell>
              <TableCell className={Boolean(isBinTracked) ? classNames(classes.emphasizedCell, classes.binLotCellSpacing) : classNames(classes.emphasizedCell, classes.cellSpacing)}>{Number(RequiredQuantity || 0).toFixed(NumberOfDecimalPlaces)}</TableCell>
            </div>
            <div>
              <TableCell className={classes.headingCell}>AVAILABLE</TableCell>
              <TableCell className={Boolean(isBinTracked) ? classNames(classes.emphasizedCell, classes.binLotCellSpacing) : classNames(classes.emphasizedCell, classes.cellSpacing)}>{Number(availableQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
            </div>
            <div>
              <TableCell className={classes.headingCell}>ENTERED</TableCell>
              <TableCell className={classes.dataCell}>{Number(enteredQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
            </div>
          </TableRow>
        </TableBody>
      </Table>
    </div>;
  };

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Select Lot Numbers'}
    modalContent={getModalContent()}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default withStyles(styles, { index: 1 })(LotDialog);
