import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotePad from './NotePad';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as slipDetailsSelectors, actions as slipDetailsActons } from 'ducks/pickSlipEnquiry/slipDetails';
import { selectors as pickSlipSelectors } from 'ducks/pickSlipEnquiry/pickSlipEnquiries';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  pickSlipDetails: slipDetailsSelectors.slipDetails(state),
  pickSlipSummary: slipDetailsSelectors.slipDetailsSummary(state),
  selectedDespatchId: pickSlipSelectors.selectedDespatchId(state)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSlipDetails: slipDetailsActons.fetch,
    getPickSlipSummary: slipDetailsActons.fetchSummary,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(NotePad);
