import { IFormViewForm } from 'components/FormView';

const CNDetails: IFormViewForm = {
  id: 'CNDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'SUBHEADER',
          props: {
            value: 'Postal address',
          }
        },
        {
          id: 1,
          type: 'ADDRESS_BLOCK_EX',
          props: {
            label: 'Postal Address',
            name: 'PostalAddress',
            type: 'extended',
          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Site',
            name: 'SiteCode',
            lookupName: 'DeliverySite',
            size: 'small',
            required: false,
            isCustomerScoped: true
          }
        },
        {
          id: 1,
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Delivery address',
          }
        },
        {
          id: 2,
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            label: 'Delivery address',
            name: 'DeliveryAddress',
            type: 'extended'
          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Terms',
            name: 'Terms',
            size: 'small',
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Territory',
            name: 'Territory',
            lookupName: 'SalesTerritory',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            isLookupTypeFixed: true,
            lookupName: 'TaxClass',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          props: {
            label: 'Tax exempt reference',
            name: 'TaxExemptRef',
            size: 'medium'
          }
        },
        {
          id: 4,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sales rep',
            name: 'SalesRepresentative',
            lookupName: 'Personnel',
            size: 'small'
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          props: {
            label: 'Age',
            name: 'Age',
            size: 'small'
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          props: {
            label: 'Reference',
            name: 'Reference',
            size: 'medium'
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          props: {
            label: 'Instructions',
            name: 'Instruction1',
            size: 'large'
          }
        },
        {
          id: 8,
          type: 'TEXT_FIELD',
          props: {
            label: '',
            name: 'Instruction2',
            size: 'large'
          }
        },
      ]
    },
    {
      id: '1',
      position: {
        row: 2,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            label: 'General Notes',
            name: 'GeneralNotes',
            fullWidth: true,
            style: {
              height: '420px'
            }
          },
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 2,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_AREA',
          props: {
            label: 'Internal Notes',
            name: 'InternalNotes',
            fullWidth: true,
            style: {
              height: '420px'
            }
          },
        }
      ]
    },
  ]
};
export default CNDetails;
