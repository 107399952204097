import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';

import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import CustomerSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Customer';
import CustomerDetails from './CustomerDetails';
import Financial from './Financial';
import Sales from './Sales';
import Pricing from './Pricing';
import { shallowCompare } from '../../utils/utils';

import BreadcrumbBar from './../common/BreadcrumbBar';
import * as styles from './CustomerEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';
import Settings from './Settings';

import { ICustomerEnquiryViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const inlineStyles = createStyles({
  customerEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
});

class CustomerEnquiryView extends React.Component<ICustomerEnquiryViewProperties> {

  handleModuleChange = (selectedTab: string) => {
    if (!this.props.selectedCustomer && !this.props.searchingForCustomers && (selectedTab !== 'CustomerDetails')) {
      showSnackBar({ variant: 'error', message: 'No customer selected.' });
      this.props.changeSelectedTab('CustomerDetails');

      return;
    }
    const nonExistingModules = [
      { id: 'Settings', target: 'CustomerSetting' },
    ];
    const fromNonExisting = nonExistingModules.find((node) => node.id === selectedTab);
    const targetTab = (fromNonExisting && fromNonExisting.target) || selectedTab;

    this.props.changeSelectedTab(targetTab);
    const context = 'customer-enquiry';
    this.props.getFormSchema({
      context,
      formId: targetTab
    });
    const notCustomer = targetTab === 'DeliveryDetails' || targetTab === 'Contacts' || targetTab === 'CustomerProducts' || targetTab === 'Marketing';
    this.props.toggleBrowseLookUp(notCustomer);
    this.props.changeSelectedForm(context, targetTab);
    if (targetTab !== 'DiaryDetails' && targetTab !== 'CreditDiaryDetails') {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    const { pathname, search } = this.getRoute()[targetTab || 'CustomerDetails'];
    this.props.history.push({ pathname, search });
    this.fetchChildRecords(targetTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'customer-enquiry';
    const CustomerId = params.get('CustomerId') || '';

    const routes = ({
      CustomerEnquiry: { pathname: `/${context}`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      CustomerDetails: { pathname: `/${context}/customer-details`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      Contacts: { pathname: `/${context}/customer-details/contacts`, search: `?CustomerId=${CustomerId}&ContactNumber=${params.get('ContactNumber') || ''}`, component: CustomerDetails },
      DeliveryDetails: { pathname: `/${context}/customer-details/delivery-details`, search: `?CustomerId=${CustomerId}&SiteCode=${params.get('SiteCode') || ''}`, component: CustomerDetails },
      CustomerProducts: { pathname: `/${context}/customer-details/products`, search: `?CustomerId=${CustomerId}&CustomerProduct=${params.get('CustomerProduct') || ''}`, component: CustomerDetails },
      Marketing: { pathname: `/${context}/customer-details/marketing`, search: `?CustomerId=${CustomerId}&AttributeCode=${params.get('AttributeCode') || ''}&AttributeSet=${params.get('AttributeSet') || ''}`, component: CustomerDetails },
      UserFields: { pathname: `/${context}/customer-details/user-fields`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      Notepad: { pathname: `/${context}/customer-details/notepad`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      SalesDiary: { pathname: `/${context}/customer-details/sales-diary`, search: `?CustomerId=${CustomerId}`, component: CustomerDetails },
      DiaryDetails: { pathname: `/${context}/customer-details/sales-diary/diary-details`, search: `?CustomerId=${CustomerId}&DiaryId=${params.get('DiaryId') || ''}`, component: CustomerDetails },
      Balances: { pathname: `/${context}/financial/balances`, search: `?CustomerId=${CustomerId}`, component: Financial },
      CustomerChildAccounts: { pathname: `/${context}/financial/child-accounts`, search: `?CustomerId=${CustomerId}`, component: Financial },
      Transactions: { pathname: `/${context}/financial/transactions`, search: `?CustomerId=${CustomerId}`, component: Financial },
      TransactionDetails: { pathname: `/${context}/financial/transactions/transaction-details`, search: `?CustomerId=${CustomerId}&TransactionId=${params.get('TransactionId') || ''}`, component: Financial },
      DetailsTransactions: { pathname: `/${context}/financial/transactions/transaction-details/transactions`, search: `?CustomerId=${CustomerId}&TransactionId=${params.get('TransactionId') || ''}`, component: Financial },
      CreditDiary: { pathname: `/${context}/financial/credit-diary`, search: `?CustomerId=${CustomerId}`, component: Financial },
      CreditDiaryDetails: { pathname: `/${context}/financial/credit-diary/diary-details`, search: `?CustomerId=${CustomerId}&DiaryId=${params.get('DiaryId') || ''}`, component: Financial },
      Invoices: { pathname: `/${context}/sales/invoices`, search: `?CustomerId=${CustomerId}`, component: Sales },
      Movements: { pathname: `/${context}/sales/movements`, search: `?CustomerId=${CustomerId}`, component: Sales },
      SalesOrders: { pathname: `/${context}/sales/sales-orders`, search: `?CustomerId=${CustomerId}`, component: Sales },
      Backorders: { pathname: `/${context}/sales/back-orders`, search: `?CustomerId=${CustomerId}`, component: Sales },
      ServiceOrders: { pathname: `/${context}/sales/service-orders`, search: `?CustomerId=${CustomerId}`, component: Sales },
      Enquiries: { pathname: `/${context}/sales/enquiries`, search: `?CustomerId=${CustomerId}`, component: Sales },
      Quotes: { pathname: `/${context}/sales/quotes`, search: `?CustomerId=${CustomerId}`, component: Sales },
      Pricing: { pathname: `/${context}/pricing`, search: `?CustomerId=${CustomerId}`, component: Pricing },
      CustomerSetting: { pathname: `/${context}/settings/general`, search: `?CustomerId=${CustomerId}`, component: Settings },
      SettingsFinancial: { pathname: `/${context}/settings/financial`, search: `?CustomerId=${CustomerId}`, component: Settings },
      Settings: { pathname: `/${context}/settings`, search: `?CustomerId=${CustomerId}`, component: Settings }
    });

    return routes;
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption, isMenuOptionOpen } = this.props;
    toggleMenuOption(!isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<ICustomerEnquiryViewProperties>): boolean {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: Readonly<ICustomerEnquiryViewProperties>): void {
    const { operationMode, selectedTab, changeSelectedForm, selectedForm, selectedCustomer } = this.props;
    if (prevProps.selectedTab !== selectedTab || (!selectedCustomer && !this.props.searchingForCustomers && this.props.searchingForCustomers !== prevProps.searchingForCustomers)) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction(prevProps);
    }
    if (!selectedForm) {
      changeSelectedForm('customer-enquiry', selectedTab);
    }
    if ((selectedCustomer ? selectedCustomer.CustomerId : -1) !== (prevProps.selectedCustomer ? prevProps.selectedCustomer.CustomerId : -1)) {
      this.onCustomerChange();
    }
  }

  componentWillUnmount(): void {
    this.props.destoryForm();
    this.props.resetSearchLookupDrawer();
  }

  backAction = (prevProps: Readonly<ICustomerEnquiryViewProperties>) => {
    const { dirty, selectedTab, changeConfirmationDialog, changeOperationMode, history, resetForm } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          if (selectedTab === 'CustomerDetails') {
            history.push('/dashboard');
          }
          this.handleModuleChange('CustomerDetails');
          changeOperationMode(Operation.BROWSE);
          resetForm();
        }
      });
    } else {
      if (selectedTab) {
        const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
        if (!targetModule || !targetModule.parent || targetModule.parent === '') {
          if (selectedTab === 'CustomerDetails') {
            history.push('/dashboard');
          } else {
            this.handleModuleChange('CustomerDetails');
          }
        } else {
          this.handleModuleChange(targetModule.parent);
        }
      }
      changeOperationMode(Operation.BROWSE);
    }
  }

  fetchChildRecords = (selectedTab: string) => {
    const {
      staff, setInvoicesEntityView, setTransactionsEntityView, setQuotesEntityView,
      setSalesOrdersEntityView, setBackordersEntityView } = this.props;
    switch (selectedTab) {
      case 'CustomerDetails':
        this.fetchContacts();
        break;
      case 'Invoices':
        if (staff) {
          setInvoicesEntityView(staff.defaultView);
        }
        break;
      case 'Transactions':
        if (staff) {
          setTransactionsEntityView(staff.defaultView);
        }
        break;
      case 'TransactionDetails':
        this.fetchTransactionDetails();
        break;
      case 'Quotes':
        if (staff) {
          setQuotesEntityView(staff.defaultView);
        }
        break;
      case 'SalesOrders':
        if (staff) {
          setSalesOrdersEntityView(staff.defaultView);
        }
        break;
      case 'Backorders':
        if (staff) {
          setBackordersEntityView(staff.defaultView);
        }
        break;
      case 'Pricing':
        this.getCustomerPricingDetails();
        break;
      case 'Balances':
        this.getBalanceDetails();
        break;
      default:
    }
  }

  getBalanceDetails = () => {
    const { fetchBalanceDetails, selectedCustomer } = this.props;
    if (selectedCustomer && selectedCustomer.CustomerId) {
      fetchBalanceDetails({ CustomerId: selectedCustomer.CustomerId });
    }
  }

  getCustomerPricingDetails = () => {
    const { getCustomerPricingSummary, selectedCustomer } = this.props;
    if (selectedCustomer && selectedCustomer.CustomerId) {
      getCustomerPricingSummary({ CustomerId: selectedCustomer.CustomerId });
    }
  }

  fetchTransactionDetails = () => {
    const { fetchTransactionDetails, fetchTransactionSummary, selectedTransaction } = this.props;
    if (selectedTransaction && selectedTransaction.TransactionId) {
      fetchTransactionDetails(selectedTransaction.TransactionId);
      fetchTransactionSummary(selectedTransaction.TransactionId);
    }
  }

  fetchContacts = () => {
    const { getContacts, selectedCustomer } = this.props;
    if (selectedCustomer && selectedCustomer.CustomerId) {
      getContacts({ CustomerId: selectedCustomer.CustomerId });
    }
  }

  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      case 'TransactionDetails':
      case 'DetailsTransactions':
        return options.TransactionDetails;
      default:
        return options.CustomerEnquiry;
    }
  }

  onCustomerChange = (): void => {
    const { selectedCustomer } = this.props;
    if (selectedCustomer) {
      this.fetchChildRecords(this.props.selectedTab);
    }
  }

  handleOptionClick = (selectedTab: string): void => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab(selectedTab);
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>

          <CustomerSearchLookUpDrawer
            enableToggle={true}
          />
          <div
            style={inlineStyles.customerEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Customer Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                  formName={`${selectedTab}`}
                />}
              />
            ))}

          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleOptionClick}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'customer-enquiry'} />
      </div>
    );
  }
}

export default withRouter(CustomerEnquiryView);
