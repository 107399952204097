import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';
import { asyncSelectors, IExtendedState, initializeReducer } from '../utils';
import { IInvoiceLinesLotDetailsSearchResponse } from 'api/supplierInvoiceEnquiry/interfaces';
import { ISupplierInvoiceEnquiryLineLotFacade } from 'api/swaggerTypes';

export interface IInvoiceLinesLotDetailsData {
  selected: ISupplierInvoiceEnquiryLineLotFacade;
  gridOptions: IDataGridOptions;
  Actions: any;
  lineColumns: IColDef[];
  lines: ISupplierInvoiceEnquiryLineLotFacade[];
}

export interface IInvoiceLinesLotDetailsState extends IExtendedState<IInvoiceLinesLotDetailsData> {
}

const initialData: IInvoiceLinesLotDetailsData = {
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true
  },
  Actions: {
  },
  lineColumns: [
    { headerName: 'Lot number', field: 'LotNumber', minWidth: 150 },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn' },
    { headerName: 'Average cost', field: 'AverageCostDisplay', type: 'numericColumn' },
    { headerName: 'Expiry', field: 'Expiry' },
    { headerName: 'Description', field: 'Description' }
  ],
  lines: []
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'supplierInvoiceLinesLotDetails',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (row: ISupplierInvoiceEnquiryLineLotFacade) => (row),
      callback: (data: IInvoiceLinesLotDetailsData, row: ISupplierInvoiceEnquiryLineLotFacade) => ({ ...data, selected: row })
    },
    clearLines: {
      action: () => null,
    }
  },
  asyncActions: {
    fetch: {
      action: (query: { DocumentNumber: number; LineNumber: number; Page?: number; Sort?: string; SortDirection?: string }) => query,
      successCallback: (data: IInvoiceLinesLotDetailsData, payload: IInvoiceLinesLotDetailsSearchResponse) => ({ ...data, lines: payload && payload.records })
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesLotDetails: IInvoiceLinesLotDetailsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesLotDetails,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
  }
);

const syncSelector = {
  isLoading: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesLotDetails: IInvoiceLinesLotDetailsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesLotDetails.loading,
  gridOptions: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesLotDetails: IInvoiceLinesLotDetailsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesLotDetails.data.gridOptions,
  lineColumns: (state: { supplierInvoiceEnquiry: { supplierInvoiceLinesLotDetails: IInvoiceLinesLotDetailsState } }) => state.supplierInvoiceEnquiry.supplierInvoiceLinesLotDetails.data.lineColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
