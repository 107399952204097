import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as rfcDetailsSelectors } from 'ducks/rfcEnquiry/rfcDetails';
import { selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';

const mapStateToProps = (state) => ({
  selectedInvoice: rfcSelectors.selected(state),
  rfcSummary: rfcDetailsSelectors.summary(state),
});

export default connect(mapStateToProps, {})(SummaryPanel);
