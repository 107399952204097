import React from 'react';
import { SummaryPanelSchemas } from '../constants';
import { useDocumentDetailsSummary, useRetrievePurchaseOrderSummary } from 'api/Worksale/backorderCommitment';
import SummaryPanel from 'components/common/SummaryPanel';
import { useBOCContextSelector } from '../BackorderCommitment.context';

export interface IBackorderSummaryPanel {
    selectedTab: string;
}

const BackorderSummaryPanel = (props: IBackorderSummaryPanel) => {
    const { selectedTab } = props;

    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);
    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);
    const documentDetailSummary = useDocumentDetailsSummary(SalesOrderId);
    const retrievePurchaseOrderSummary = useRetrievePurchaseOrderSummary(PurchaseOrderId);

    const summaryPanelData = React.useMemo(
        () => {

            const documentDetailPanelData = {
                data: documentDetailSummary.data?.Document?.inlineObject ?? {},
                loading: documentDetailSummary.isInitialLoading
            };
            const purchaseOrderLinesData = {
                data: retrievePurchaseOrderSummary?.data?.PurchaseSummary ?? {},
                loading: retrievePurchaseOrderSummary.isInitialLoading
            };

            return {
                BackorderLines: documentDetailPanelData,
                LineCommitment: documentDetailPanelData,
                PurchaseOrderLines: purchaseOrderLinesData,
                PurchaseOrderLineDetails: purchaseOrderLinesData,
                OrderDetails: purchaseOrderLinesData,
                Deliveries: purchaseOrderLinesData,

            };
        },
        [documentDetailSummary, retrievePurchaseOrderSummary]
    );

    return (
        <>
            {SummaryPanelSchemas?.[selectedTab] && <SummaryPanel
                loading={summaryPanelData?.[selectedTab]?.loading ?? false}
                data={summaryPanelData?.[selectedTab]?.data}
                schema={SummaryPanelSchemas?.[selectedTab]}
            />}
        </>
    );
};

export default BackorderSummaryPanel;
