import * as React from 'react';
import { DnDSource, SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { dragExpandIcon } from '../../../themes/colors';
import LookUpList from '../LookUpList';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Operation } from 'utils/operations';
import { FormViewField } from 'components/FormView';
import { ISortOrder } from 'api/swaggerTypes';
import LookupPanel from '../LookupPanel';

const inlineStyles = createStyles({
  openedDragController: {
    left: '300px',
    width: '10px',
    backgroundColor: '#F47920',
    cursor: 'pointer'
  },
  dragIcon: {
    color: dragExpandIcon,
    width: '20px',
    height: '20px',
    marginLeft: '-5px',
    cursor: 'pointer'
  },
  closedDragIcon: {
    color: dragExpandIcon,
    width: '30px',
    height: '30px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
});

export interface ISearchLookUpDrawerProperties<T extends object = any, U extends object = any> extends StyledComponentProps, RouteComponentProps<{ SearchText: string }> {
  open: boolean;
  isLoading: boolean;
  disabled?: boolean;
  keyField: keyof T;
  hideFilterButton?: boolean;
  searchPlaceholder: string;
  sortingFilters?: { filter: keyof T; label: string }[];
  selectedSortFilter?: string;
  schedulerData?: SchedulerData;
  dragAndDropSource?: DnDSource;
  customFilterRow?: {
    formName: string;
    parameters: FormViewField[];
    validate?(values: any): boolean;
  };
  filterHeader?: {
    formName: string;
    parameters: FormViewField[];
    validate?(values: any): boolean;
  };
  suppressSubHeader?: boolean;
  appliedFilters?: any;
  variant: string;
  selectedSortDirection?: string;
  operationMode: Operation;
  records: T[];
  selectedRecord: any;
  fieldsToDisplay: [keyof T, keyof T, keyof T, keyof T] | [keyof T, keyof T, keyof T, keyof T, keyof T, keyof T];
  cascadingListKey?: keyof T;
  cascadingListKeyField?: keyof U;
  cascadingListFields?: [keyof U, keyof U, keyof U, keyof U] | [keyof U, keyof U, keyof U, keyof U, keyof U, keyof U];
  prevPage: number;
  nextPage: number;
  totalPages: number;
  isBrowseLookUp?: boolean;
  searchParams?: any;
  enableToggle: boolean;
  recordChangeCallBacks?: (() => void)[];
  pageSize: number;
  loadingPrevPage: boolean;
  loadingNextPage: boolean;
  searchTextProp?: string;
  searchInitially?: boolean;
  lastLinePosition?: 'left' | 'right';
  newSchedulerEvent?(schedulerData: SchedulerData, slotId: any, slotName: any, start: any, end: any, type: any, item: any): void;
  changeSelectedRecord(recordId: any): void;
  onToggle(open: boolean): void;
  getSearchFromMatchParams?(matchParams: URLSearchParams): { id?: any; searchText?: string };
  changeSelectedRecordCallBack?(matchParams: URLSearchParams, selectedRecord: any): string;
  search?(search: { SearchText: string; Sort?: ISortOrder; BatchPage?: number; filters?: any }): void;
  searchById?(id: any): void;
  fetchPrevPage?(search: { Sort?: ISortOrder; BatchPage: number }): void;
  fetchNextPage?(search: { Sort?: ISortOrder; BatchPage: number }): void;
  changeSearchText?(searchText: string): void;
  renderCustomItemSection?(item: any): any;
  isItemDraggable?(item: any): boolean;
  isNextItemSelectionAllowed?(nextItem: T): boolean;
}

export interface ISearchLookUpDrawerState {
  searchText: string;
}

class SearchLookUpDrawer<T extends object = any, U extends object = any> extends React.PureComponent<ISearchLookUpDrawerProperties<T, U>, ISearchLookUpDrawerState> {

  constructor(props: Readonly<ISearchLookUpDrawerProperties<T, U>>) {
    super(props);

    this.state = {
      searchText: ''
    };

    this.isNextItemSelectionAllowed = this.isNextItemSelectionAllowed.bind(this);
  }
  handleLookupToggle = (open: boolean) => {
    const { enableToggle, onToggle } = this.props;
    if (enableToggle) {
      onToggle(open);
    }
  }

  componentDidMount(): void {
    const { getSearchFromMatchParams, searchById, search, location, selectedSortFilter, selectedSortDirection, match, searchInitially, customFilterRow, appliedFilters } = this.props;
    const params = new URLSearchParams(location.search);
    if (getSearchFromMatchParams) {
      const searchFromMatchParams = getSearchFromMatchParams(params);
      if (searchFromMatchParams) {
        if (searchFromMatchParams.id && searchById) {
          searchById(searchFromMatchParams.id);

          return;
        } else if (searchFromMatchParams.searchText && search) {
          if (customFilterRow && (appliedFilters)) {
            this.search(searchFromMatchParams.searchText, { Property: selectedSortFilter, Direction: selectedSortDirection }, (appliedFilters));

            return;
          }
          this.search(searchFromMatchParams.searchText, { Property: selectedSortFilter, Direction: selectedSortDirection });

          return;
        }
      }
    } else if (match && match.params.SearchText) {
      this.search(match.params.SearchText, { Property: selectedSortFilter, Direction: selectedSortDirection });

      return;
    }

    if (searchInitially) this.search('', { Property: selectedSortFilter, Direction: selectedSortDirection });
  }

  changeSelectedRecord = (recordId: any) => {
    const { operationMode, changeSelectedRecord } = this.props;
    if (operationMode === Operation.BROWSE) {
      changeSelectedRecord(recordId);
    }
  }

  onRecordChange = () => {
    const { recordChangeCallBacks } = this.props;
    if (recordChangeCallBacks) {
      recordChangeCallBacks.map((callback) => { callback(); });
    }
  }

  hasRecordChanged = (prevRecord: T & U, currRecord: T & U, keyField: keyof T, cascadingListKeyField: keyof U) => {
    return (!prevRecord && currRecord) ||
      (!cascadingListKeyField && prevRecord && currRecord && prevRecord[keyField] !== currRecord[keyField]) ||
      (cascadingListKeyField && prevRecord && currRecord && (prevRecord[keyField] !== currRecord[keyField] || prevRecord[cascadingListKeyField] !== currRecord[cascadingListKeyField]));
  }

  componentDidUpdate(prevProps: Readonly<ISearchLookUpDrawerProperties<T>>): void {
    const {
      keyField, cascadingListKeyField, selectedRecord, recordChangeCallBacks, searchParams,
      variant, selectedSortFilter, selectedSortDirection, changeSelectedRecordCallBack, isLoading,
      searchTextProp
    } = this.props;
    if (!isLoading && this.hasRecordChanged(prevProps.selectedRecord, selectedRecord, keyField, cascadingListKeyField)) {
      this.setState({ searchText: selectedRecord[searchTextProp] || '' });
      if (changeSelectedRecordCallBack) {
        this.props.history.push({ search: changeSelectedRecordCallBack(new URLSearchParams(this.props.location.search), selectedRecord) });
      }
      if (recordChangeCallBacks) {
        this.onRecordChange();
      }
    }

    this.checkSelectedRecord();

    if (
      (searchParams && !prevProps.searchParams) ||
      (searchParams && prevProps.searchParams && Object.keys(searchParams).findIndex((key) => searchParams[key] !== prevProps.searchParams[key]) > -1) ||
      variant !== prevProps.variant
    ) {
      this.search('', {
        Property: selectedSortFilter,
        Direction: selectedSortDirection
      });
    }
  }

  checkSelectedRecord = (): void => {
    const { isLoading, records, selectedRecord, keyField, cascadingListFields } = this.props;
    if (!isLoading && records && records.length > 0 && !selectedRecord) {
      if (!cascadingListFields) {
        this.changeSelectedRecord(records[0][keyField]);
      } else {
        const childRecords = records && records.filter((record) => (record as any).children && (record as any).children.length > 0);
        if (childRecords && childRecords[0]) {
          this.changeSelectedRecord((childRecords[0] as any).children[0][keyField]);
        }
      }
    }
  }

  search = (SearchText: string, Sort: { Property: string; Direction: string } = null, filters?: any) => {
    const { search, searchParams } = this.props;
    if (search) {
      search({ SearchText, ...searchParams, Sort, filters });
    }
  }

  fetchPrevPage = (Sort: { Property: string; Direction: string } = null, filters?: any) => {
    const { fetchPrevPage, searchParams, prevPage } = this.props;
    if (fetchPrevPage) {
      fetchPrevPage({ ...searchParams, Sort, BatchPage: prevPage, filters });
    }
  }

  fetchNextPage = (Sort: { Property: string; Direction: string } = null, filters: any) => {
    const { fetchNextPage, searchParams, nextPage } = this.props;
    if (fetchNextPage) {
      fetchNextPage({ ...searchParams, Sort, BatchPage: nextPage, filters });
    }
  }

  isNextItemSelectionAllowed(nextItem: any): boolean {
    const { operationMode, isNextItemSelectionAllowed } = this.props;

    return operationMode === Operation.BROWSE && (isNextItemSelectionAllowed ? isNextItemSelectionAllowed(nextItem) : true);
  }

  render(): React.ReactNode {
    const {
      operationMode, disabled = false, keyField, fieldsToDisplay, open, selectedRecord, selectedSortFilter, selectedSortDirection,
      pageSize, totalPages, sortingFilters, searchPlaceholder, isBrowseLookUp, records, isLoading, filterHeader,
      loadingPrevPage, loadingNextPage, nextPage, prevPage, customFilterRow, appliedFilters, cascadingListKey, cascadingListKeyField, cascadingListFields, suppressSubHeader,
      hideFilterButton, schedulerData, dragAndDropSource, renderCustomItemSection, isItemDraggable, newSchedulerEvent, lastLinePosition } = this.props;
    const { searchText } = this.state;

    return (
      <div style={{ height: '100%', marginTop: '-10px' }}>
        <LookupPanel open={open} setOpen={this.handleLookupToggle}>
          <LookUpList<T>
            schedulerData={schedulerData}
            dragAndDropSource={dragAndDropSource}
            newSchedulerEvent={newSchedulerEvent}
            searchPlaceholder={searchPlaceholder || 'Search for records...'}
            sortingFilters={sortingFilters}
            fieldsToDisplay={fieldsToDisplay}
            listItems={records}
            hideFilterButton={hideFilterButton}
            handleRecordChange={this.changeSelectedRecord}
            selectedRecordId={selectedRecord && operationMode !== Operation.NEW && selectedRecord[keyField]}
            selectedSortFilter={selectedSortFilter}
            selectedSortDirection={selectedSortDirection}
            searchAction={this.search}
            browseLookUp={isBrowseLookUp}
            keyField={keyField}
            isLoading={isLoading}
            disabled={disabled || (operationMode !== Operation.BROWSE)}
            changeSearchText={this.props.changeSearchText}
            searchText={searchText || ''}
            loadingPrevPage={loadingPrevPage}
            loadingNextPage={loadingNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            pageSize={pageSize}
            totalPages={totalPages}
            fetchPrevPage={this.fetchPrevPage}
            fetchNextPage={this.fetchNextPage}
            customFilterRow={customFilterRow}
            filterHeader={filterHeader}
            appliedFilters={appliedFilters}
            cascadingListKey={cascadingListKey}
            cascadingListKeyField={cascadingListKeyField}
            cascadingListFields={cascadingListFields}
            suppressSubHeader={suppressSubHeader}
            renderCustomItemSection={renderCustomItemSection}
            isItemDraggable={isItemDraggable}
            isNextItemSelectionAllowed={this.isNextItemSelectionAllowed}
            lastLinePosition={lastLinePosition}
          />
        </LookupPanel>
      </div>
    );
  }
}

// tslint:disable-next-line:typedef
export function buildSearchLookUpDrawer<T extends object = any, U extends object = any>() {
  const decorator = withStyles(inlineStyles, { index: 1 });
  class BoundSearchLookUpDrawer extends SearchLookUpDrawer<T, U> { }
  const decorated = decorator(BoundSearchLookUpDrawer);

  return withRouter<ISearchLookUpDrawerProperties<T, U>>(decorated);
}

// Untyped for those who don't care about type.
export default buildSearchLookUpDrawer();
