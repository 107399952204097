import { fetchPost, objectify, fetchGet } from '../utils';
import moment from 'moment';

export const fetchAllocatedOrders = (filters) => {
  const PriorTo = filters && filters.PriorTo && moment(filters.PriorTo, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSZ');

  return fetchPost(
    '/PointOfSaleCashReceipting/CashReceipt/SearchSaleOrders',
    filters
    && { ...filters, PriorTo },
    'Inline'
  ).then(
    (result) => {
      if (result) {
        if (result.CashReceiptSalesOrderAllocations) {
          return {
            records: result.CashReceiptSalesOrderAllocations.map((item) => objectify(item, null, 'Inline')),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          };
        }
      }

      return result;
    });
};

export const getSpecificOrderAllocation = (query) => {
  const { SalesOrder } = query;

  return fetchGet(`/PointOfSaleCashReceipting/CashReceipt/SalesOrder/${SalesOrder}/CurrentAllocation`, {}, 'Inline')
    .then(({ CashReceiptSalesOrderAllocation = {} }) => ({ inlineObject: objectify(CashReceiptSalesOrderAllocation), schema: CashReceiptSalesOrderAllocation }));
};
export const applySpecificOrderAllocation = (data) => {

  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/ApplySalesOrderAllocation', data, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};
