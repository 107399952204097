import { toObjectified } from 'api/baseApi';
import { Payload, usePost } from 'api/reactQuery/queryService';
import { ICalculateWorksalelineDetailFacade, ICalculateWorksalelineDetailResponse, IErrorResponse, IKitDetail as IKitDetailSwagger, ILoadComponentsFacade, ILoadedComponentDetailResponse, ILoadedComponentsDetailFacade, IUpdateWorksalelineDetailFacade, IUpdateWorksalelineDetailResponse, IWorksalelineDetailFacade, IWorksalelineDetailResponse } from 'api/swaggerTypes';
import { Inline, IObjectified } from 'api/utils';
import { APIRoutes } from './constants';
import { IBatchInfo } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IKitDetail extends IKitDetailSwagger {
  Primary?: boolean;
  BomCode?: string;
  Description?: string;
}
interface IUpdateWorksalelineDetailFacadeExtended extends IUpdateWorksalelineDetailFacade {
  KitDetail: IKitDetail[];
  WorksaleId?: number;
}

export type InlineIUpdateWorksalelineDetailResponse = Omit<IUpdateWorksalelineDetailResponse, 'OverrideDetails'> & {
  OverrideDetails?: Inline<IUpdateWorksalelineDetailFacadeExtended>;
};

export type IObjectifiedIUpdateWorksalelineDetailResponse = Omit<IUpdateWorksalelineDetailResponse, 'OverrideDetails'> & {
  OverrideDetails?: IObjectified<IUpdateWorksalelineDetailFacadeExtended>;
};

const updateLineDetailsOnSuccess = (response: InlineIUpdateWorksalelineDetailResponse): IObjectifiedIUpdateWorksalelineDetailResponse => {
  return {
    ...response,
    OverrideDetails: response.OverrideDetails ? toObjectified(response.OverrideDetails) : null
  };
};

export const useUpdateWorkSaleLineDetails = () => {
  return usePost<Payload<IUpdateWorksalelineDetailFacadeExtended>, IObjectifiedIUpdateWorksalelineDetailResponse>(
    APIRoutes.workSaleLine.details.update,
    null,
    { inline: true, responseParser: updateLineDetailsOnSuccess },
  );
};

export type IObjectifiedCalculateWorksaleLineResponse = Omit<ICalculateWorksalelineDetailResponse, 'CalculateWorksalelineDetail'> & {
  CalculateWorksalelineDetail?: IObjectified<ICalculateWorksalelineDetailFacade>;
};

export type InlineCalculateWorksaleLineResponse = Omit<ICalculateWorksalelineDetailResponse, 'CalculateWorksalelineDetail'> & {
  CalculateWorksalelineDetail?: Inline<ICalculateWorksalelineDetailFacade>;
};

const useCalculateWorkSaleLineDetailsResponseParser = (response: InlineCalculateWorksaleLineResponse): IObjectifiedCalculateWorksaleLineResponse => {
  return {
    ...response,
    CalculateWorksalelineDetail: response.CalculateWorksalelineDetail ? toObjectified(response.CalculateWorksalelineDetail) : null
  };
};

export const useCalculateWorkSaleLineDetails = () => {
  return usePost<Payload<ICalculateWorksalelineDetailFacade & { WorksaleId?: number }, { ChangedField: string }>, IObjectifiedCalculateWorksaleLineResponse>(
    APIRoutes.workSaleLine.details.calculate,
    null,
    { inline: true, responseParser: useCalculateWorkSaleLineDetailsResponseParser },
  );
};

export type IObjectifiedLoadedComponentDetailResponse = Omit<ILoadedComponentDetailResponse, 'Components'> & {
  Components: IObjectified<ILoadedComponentsDetailFacade>;
};
export type InlineLoadedComponentDetailResponse = Omit<ILoadedComponentDetailResponse, 'Components'> & {
  Components: Inline<ILoadedComponentsDetailFacade>;
};

const useLoadComponentsResponseParser = (response: InlineLoadedComponentDetailResponse): IObjectifiedLoadedComponentDetailResponse => {
  return {
    ...response,
    Components: response.Components ? toObjectified(response.Components) : null
  };
};

export const useLoadComponents = () => {
  return usePost<Payload<ILoadComponentsFacade & { WorksalelineId?: number }, IBatchInfo>, IObjectifiedLoadedComponentDetailResponse>(
    APIRoutes.workSaleLine.compnents.load,
    null,
    { inline: true, responseParser: useLoadComponentsResponseParser },
  );
};

export const useCancelLineDetails = () => {
  return usePost<Payload<{ WorksalelineId: number }>, IErrorResponse>(
    APIRoutes.workSaleLine.details.cancel,
    null,
    { inline: true },
  );
};

export type IObjectifiedWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'WorksalelineDetail'> & {
  WorksalelineDetail: IObjectified<IWorksalelineDetailFacade>;
};

export type InlineWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'WorksalelineDetail'> & {
  WorksalelineDetail: Inline<IWorksalelineDetailFacade>;
};

const worksaleLineResponseParser = (response: InlineWorksaleLineResponse): IObjectifiedWorksaleLineResponse => {
  return {
    ...response,
    WorksalelineDetail: response.WorksalelineDetail ? toObjectified(response.WorksalelineDetail) : null
  };
};

export const useFetchWorksaleLine = () => {
  return usePost<Payload<{ WorksalelineId?: number }, { NotifyUnsavedChanges: boolean; KeepUnsavedChanges?: boolean }>, IObjectifiedWorksaleLineResponse>(
    APIRoutes.workSaleLine.details.fetch,
    null,
    { inline: true, responseParser: worksaleLineResponseParser },
  );
};
