import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/salesInvoiceEnquiry/SalesAuditDetails';
import { fetch } from 'api/salesInvoiceEnquiry/SalesAuditDetails';
import { selectors as salesAuditSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceSalesAudit';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'AuditDetails',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (salesAuditSelectors.selected(state) || params.get('AuditDataId'))
    &&
  {
    AuditDataId: (salesAuditSelectors.selected(state) && salesAuditSelectors.selected(state).SalesauditdataId) || params.get('AuditDataId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
