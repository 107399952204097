import React from 'react';
import INewJobServiceAgreementModalProps, {
    IActivitiesPanelHandle, IServiceItemsPanelHandle,
    IAddActivityData, IServiceItemData, IConfirmationDataData, IUpcomingServiceAgreementData
} from './NewJobModal.properties';
import ActivitiesPanel from './Panels/ActivitiesPanel';
import ConfirmationPanel from './Panels/ConfirmationPanel';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import NewJobSchema from './NewJobSchema.json';
import { PanelIdToName, TITLES } from './NewJobModal.constants';
import { isNull } from 'utils/utils';
import { Operation } from 'utils/operations';
import ServiceItemsPanel from './Panels/ServiceItemsPanel';
import UpcomingServiceAgreementPanel from './Panels/UpcomingServiceAgreementPanel';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import moment from 'moment';
import lodash from 'lodash';
import { ICustomerContactDetails } from 'api/swaggerTypes';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
// tslint:disable: no-magic-numbers

const NewJobModal = (props: INewJobServiceAgreementModalProps) => {
    const {
        open,
        loading,
        formValues = {},
        formSyncErrors,
        selectedCustomerDetails = {},
        forNewJob,
        SalesEntity,
        upcomingServiceJobs,
        upcomingServAgreementsLoading,
        upcomingServiceDate,
        selectedFilters,
        touchFormFields,
        handleClose,
        changeFormFieldValue,
        getCustomerDetails,
        clearCustomerDetails,
        getDeliveryAddress,
        changeOperationMode,
        getJobActivities,
        getDefaultJob,
        createNewJob,
        getServiceAgreementJobs,
        performSearch,
        recordServiceAgreementSchedule,
        fetchDeliverySiteOptions,
        getCustomerContacts,
        changeConfirmationDialog,
    } = props;

    const ActivitiesPanelRef = React.useRef<IActivitiesPanelHandle>();
    const ServiceItemsPanelRef = React.useRef<IServiceItemsPanelHandle>();
    const processValidationForms = React.useContext(ProcessValidationFormsContext);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isServiceItemLoading, setIsServiceItemLoading] = React.useState<boolean>(false);
    const [activitiesData, setActivitiesData] = React.useState<IAddActivityData[]>([]);
    const [serviceItemsData, setServiceItemsData] = React.useState<IServiceItemData[]>([]);
    const [confirmationData, setConfirmationData] = React.useState<IConfirmationDataData[]>([]);
    const [upcomingServAgreementsData, setUpcomingServAgreementsData] = React.useState<IUpcomingServiceAgreementData>(null);
    const [customerContacts, setCustomerContacts] = React.useState<ICustomerContactDetails[]>([]);
    const [panel, setPanel] = React.useState<number>(1);
    const selectedCustomerId = !isNull(formValues.CustomerId) ? Number(formValues.CustomerId) : formValues.CustomerId;
    const [selectedCustomerDisplay, setSelectedCustomerDisplay] = React.useState<string>('');
    const [selectedCustomerDescription, setSelectedCustomerDescription] = React.useState<string>('');

    React.useEffect(
        () => {
            setPanel(forNewJob ? 2 : 1);
        },
        [forNewJob]
    );

    React.useEffect(
        () => {
            const inlineJobs = upcomingServiceJobs ? upcomingServiceJobs.map((j) => j.inlineObject) : [];
            setUpcomingServAgreementsData(inlineJobs);
        },
        [upcomingServiceJobs]
    );

    React.useEffect(
        () => {
            if (open) {
                populateDefaultValues();
            }
        },
        [open]
    );

    React.useEffect(
        () => {
            setIsLoading(loading);
        },
        [loading]
    );

    const isAnySelected = upcomingServAgreementsData && upcomingServAgreementsData.some((sa) => sa.Checked);

    function populateDefaultDeliverySite(): void {
        fetchDeliverySiteOptions({ SearchText: '', CustomerId: selectedCustomerId })
            .then((response) => {
                const firstDeliverySite = lodash.first(response.Data);
                changeFormFieldValue('DeliverySite', firstDeliverySite.Code);
                handleDeliverySiteChange({ Code: firstDeliverySite.Code }, selectedCustomerId);

            })
            .catch((err) => console.warn(err));
    }

    function populateDefaultValues(): void {
        getDefaultJob()
            .then((response) => {
                if (response.Status && !isNull(response.JobTemplateDetails)) {
                    changeFormFieldValue('JobTemplate', response.JobTemplateDetails.TemplateCode);
                    changeFormFieldValue('JobDescription', response.JobTemplateDetails.Description);
                }
            })
            .catch((err) => console.warn(err));
        changeFormFieldValue('DeliveryType', '1');
    }

    function modalActions(): IActionItem[] {
        const NEXT_BUTTON: IActionItem = {
            iconName: 'Forward',
            title: 'Next',
            disabled: !forNewJob && !isAnySelected,
            listener: () => {
                handleNextButton();
            }
        };

        const PREV_BUTTON: IActionItem = {
            iconName: 'Forward',
            angleOfRotation: 180,
            title: 'Back',
            alignment: 'left',
            listener: () => {
                setPanel(panel - 1);
            }
        };

        const CANCEL_BUTTON: IActionItem = {
            iconName: 'Cancel',
            title: 'Cancel',
            alignment: 'left',
            listener: () => {
                restModal();
                changeOperationMode(Operation.BROWSE);
                if (clearCustomerDetails) {
                    clearCustomerDetails();
                }
                if (handleClose) {
                    handleClose();
                }
            }
        };

        const DONE_BUTTON: IActionItem = {
            title: 'Create Job',
            iconName: 'CheckCircle',
            listener: () => {

                const { InitialActivities = [] } = formValues;
                const JobActivity = [...InitialActivities, ...activitiesData];

                if (validateServiceJobActivities(JobActivity)) {
                    createNewJobFunction(JobActivity);
                    handleClose();
                    changeOperationMode(Operation.BROWSE);
                }
            }
        };
        const ADD_ACTIVITY_BUTTON: IActionItem = {
            title: 'Add Activity',
            alignment: 'center',
            iconName: 'AddCircle',
            listener: () => {
                if (ActivitiesPanelRef.current) {
                    ActivitiesPanelRef.current.AddActivity();
                }
            }
        };
        const ADD_ITEM_BUTTON: IActionItem = {
            title: 'Add Service Item',
            alignment: 'center',
            iconName: 'AddCircle',
            listener: () => {
                if (ServiceItemsPanelRef.current) {
                    ServiceItemsPanelRef.current.AddItem();
                }
            }
        };
        switch (panel) {
            case 1: return [CANCEL_BUTTON, NEXT_BUTTON];
            case 2:
                if (forNewJob) {
                    return [CANCEL_BUTTON, NEXT_BUTTON];
                } else {
                    return [CANCEL_BUTTON, PREV_BUTTON, NEXT_BUTTON];
                }
            case 3: return [CANCEL_BUTTON, PREV_BUTTON, NEXT_BUTTON];
            case 4: return [CANCEL_BUTTON, PREV_BUTTON, NEXT_BUTTON];
            case 5: return [CANCEL_BUTTON, PREV_BUTTON, ADD_ITEM_BUTTON, NEXT_BUTTON];
            case 6: return [CANCEL_BUTTON, PREV_BUTTON, ADD_ACTIVITY_BUTTON, NEXT_BUTTON, DONE_BUTTON];
            case 7: return [CANCEL_BUTTON, PREV_BUTTON, DONE_BUTTON];

            default: return [];
        }
    }

    function validateServiceJobActivities(JobActivity: IAddActivityData[]): boolean {

        if (isNull(JobActivity)) {
            showSnackBar({ variant: 'error', message: 'Please select some activity.' });

            return false;
        }

        return true;
    }

    function createNewJobFunction(JobActivity: IAddActivityData[]): void {

        createJob({ ...formValues, SalesEntity, JobActivity })
            .then(async (response) => {

                const ServiceJobId = response.ServiceJob && response.ServiceJob.inlineObject.ServiceJobId;

                if (response.Status && !response.Forms) {
                    showSnackBar({ variant: 'success', message: `Service job created: ${ServiceJobId}` });

                    if (!forNewJob) {
                        const payload = upcomingServAgreementsData && upcomingServAgreementsData.filter((j) => j.Checked).map((job) => ({
                            SOrder: ServiceJobId,
                            ServiceAgreementId: job.ServiceAgreementId,
                            ServiceAgreementScheduleId: job.ServiceAgreementScheduleId
                        }));

                        await recordScheduleHistory(payload);
                    }

                    performSearch({ SearchText: '', filters: selectedFilters });
                }
                if (!response.Status) {
                    showSnackBar({ variant: 'error', message: 'Failed to create Service Job.' });
                }
            }).catch((err) => {
                showSnackBar({ variant: 'error', message: 'Failed to create Service Job.' });
                console.warn(err);
            });

        restModal();
    }

    async function createJob(query: any): Promise<any> {
        const response = await createNewJob(query);

        return processValidationForms(response, query, async (validatedResponse) => createNewJob(validatedResponse));
    }

    async function recordScheduleHistory(query: any): Promise<any> {
        const response = await recordServiceAgreementSchedule(query);

        return processValidationForms(response, query, async (validatedResponse) => recordServiceAgreementSchedule(validatedResponse));
    }

    function newJobSchema(): any {
        const schema = NewJobSchema.Form;
        schema.fields = NewJobSchema[PanelIdToName[panel]];

        return schema;
    }

    function handleCustomerChange(item: any): void {
        if (isNull(item)) {
            changeFormFieldValue('CustomerName', '');
            changeFormFieldValue('Contact', '');
            changeFormFieldValue('Phone', '');
            changeFormFieldValue('Email', '');
            setSelectedCustomerDisplay('');
            setSelectedCustomerDescription('');
            if (clearCustomerDetails) {
                clearCustomerDetails();
            }

            return;
        }

        if (!isNull(item)) {
            setSelectedCustomerDisplay(item.CustomerAccount);
            setSelectedCustomerDescription(item.Name);
            getCustomerDetails(item.CustomerId);
            fillContactDetails(Number(item.CustomerId));
        }
    }

    function handleContactTypeChange(item: any): void {
        const selectedContact = isNull(item) ? null : customerContacts.find((contact) => contact.ContactType === item.Code && contact.Primary);
        setContactForm(selectedContact);
    }

    function fillContactDetails(customerId: number): void {
        getCustomerContacts(customerId).then((response) => {
            changeFormFieldValue('ContactType', '3');
            setCustomerContacts(response);
        }).catch((err) => console.warn(err));
    }

    function setContactForm(contact: ICustomerContactDetails): void {
        if (contact) {
            changeFormFieldValue('ContactType', contact.ContactType || '');
            changeFormFieldValue('Contact', contact.Contact || '');
            changeFormFieldValue('Phone', contact.Phone || '');
            changeFormFieldValue('Email', contact.Email || '');
        }
    }

    function handleDeliverySiteChange(item: any, CustomerId: number): void {
        if (isNull(item?.Code)) {
            changeFormFieldValue('address', {});

            return;
        }
        getDeliveryAddress(CustomerId, item?.Code);
    }

    function handleJobTemplateChange(item: any): void {
        if (isNull(item)) {
            setActivitiesData([]);

            return;
        }
        changeFormFieldValue('JobDescription', item.Label);

    }

    function initContactForm(): void {
        const reqPrimaryContact = customerContacts.find((contact) => contact.ContactType === '3' && contact.Primary) ||
            customerContacts.find((contact) => contact.ContactType === '1' && contact.Primary);
        setContactForm(reqPrimaryContact);
    }

    function handleNextButton(): void {
        switch (panel) {
            case 1:
                handlePanelOneNext();
                break;
            case 2:
                handlePanelTwoNext();
                break;
            case 3:
                handlePanelThreeNext();
                break;
            case 5:
                handlePanelFiveOnNext();
                break;
            case 6:
                handlePanelSixNext();
                break;
            default:
                moveToNextPanel();
        }
    }

    function handlePanelTwoNext(): void {
        const ErrorFields = Object.keys(formSyncErrors);
        if (ErrorFields.includes('CustomerId')) {
            touchFormFields('CustomerId');

            return;
        }
        if (ErrorFields.includes('CustomerPO')) {
            touchFormFields('CustomerPO');

            return;
        }
        if (ErrorFields.includes('JobDescription')) {
            touchFormFields('JobDescription');

            return;
        }
        populateDefaultDeliverySite();
        moveToNextPanel();
    }

    function handlePanelThreeNext(): void {
        const ErrorFields = Object.keys(formSyncErrors);
        const { DeliveryType, DeliverySite } = formValues;
        if (DeliveryType === '1' && (ErrorFields.includes('DeliverySite') || isNull(DeliverySite))) {
            touchFormFields('DeliverySite');

            return;
        }
        if (ErrorFields.includes('DeliveryType')) {
            touchFormFields('DeliveryType');

            return;
        }
        initContactForm();
        moveToNextPanel();
    }

    function handlePanelOneNext(): void {
        const selectedJob = (upcomingServAgreementsData && upcomingServAgreementsData.find((j) => j.Checked)) || {};
        const item = { Value: selectedJob.CustomerId, CustomerAccount: selectedJob.CustomerIdDisplay };
        changeFormFieldValue('CustomerId', selectedJob.CustomerId);
        changeFormFieldValue('CustomerName', selectedJob.CustomerName);
        handleCustomerChange(item);

        const serviceItems = upcomingServAgreementsData.filter((j) => j.Checked).map((job, idx) => ({
            Id: idx,
            ...job
        }));

        setServiceItemsData(serviceItems as any);
        moveToNextPanel();
    }

    async function handlePanelFiveOnNext(): Promise<void> {

        if (getJobActivities) {
            const { JobTemplate } = formValues;

            const cloneServItemData = lodash.cloneDeep(serviceItemsData);
            cloneServItemData.unshift({
                Id: 0,
                TemplateCode: JobTemplate,
                TemplateCodeLabel: JobTemplate,
                ServiceItem: null,
                ServiceItemDisplay: null,
                ServiceItemLabel: 'No Service Item'
            });

            let newActivities = [];

            try {
                setIsServiceItemLoading(true);

                for await (const item of cloneServItemData) {
                    const response = await getJobActivities(item.TemplateCode);
                    if (!isNull(response)) {

                        const newId = isNull(newActivities) ? 1 : Math.max(...newActivities.map((a) => a.Id));
                        const activities: IAddActivityData[] = response ? response.map(
                            (activity, index) => (
                                {
                                    Id: newId + index,
                                    ServiceItem: item.ServiceNo,
                                    ServiceNoDisplay: item.ServiceNoDisplay,
                                    ServiceNoDisplayLabel: item.ServiceNoDisplayLabel,
                                    ...activity.inlineObject
                                })) : [];

                        newActivities = newActivities.concat(activities);
                    }
                }
                setActivitiesData(newActivities);
                moveToNextPanel();
            } catch (err) {
                console.warn(err);
            } finally {
                setIsServiceItemLoading(false);
            }
        }
    }

    function handlePanelSixNext(): void {

        const confirmationPanelData = activitiesData.map((activity, idx) => ({
            ...activity,
            Id: idx + 1,
            EstimatedHours: !isNull(activity.Estimate) ? hoursMinutesToDecimal(activity.Estimate) : 0,
        }));

        setConfirmationData(confirmationPanelData);
        moveToNextPanel();
    }

    function hoursMinutesToDecimal(t: string): number {
        const hoursMinutes = t.split(':');
        const hours = parseInt(hoursMinutes[0], 10);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

        return hours + minutes / 60;
    }

    function moveToNextPanel(): void {
        setPanel(panel + 1);
    }

    function restModal(): void {
        const { resetForm } = props;
        setPanel(forNewJob ? 2 : 1);
        setActivitiesData([]);
        setServiceItemsData([]);
        setConfirmationData([]);
        resetForm();
    }

    function isFormPanel(): boolean {
        return panel === 2 || panel === 3 || panel === 4;
    }

    async function SearchUpcomingServiceAgreements(serviceData: string): Promise<void> {
        const payload = {
            SalesEntity,
            StartDate: moment(serviceData, 'DD/MM/YYYY').subtract(2, 'months').format('YYYY/MM/DD'),
            EndDate: moment(serviceData, 'DD/MM/YYYY').format('YYYY/MM/DD')
        };

        getServiceAgreementJobs(payload);
    }

    function getModalContent(): JSX.Element {

        switch (panel) {
            case 1:
                return (<UpcomingServiceAgreementPanel
                    loading={upcomingServAgreementsLoading}
                    data={upcomingServAgreementsData}
                    serviceDate={upcomingServiceDate}
                    setData={setUpcomingServAgreementsData}
                    onSearch={SearchUpcomingServiceAgreements} />);
            case 5:
                return (<ServiceItemsPanel
                    loading={isServiceItemLoading}
                    data={serviceItemsData}
                    setData={setServiceItemsData}
                    CustomerId={selectedCustomerId}
                    changeConfirmationDialog={changeConfirmationDialog}
                    onDeleteRow={(Id) => {
                        const updatedServiceItemsData = serviceItemsData.filter((serviceItem) => serviceItem.Id !== Id);
                        setServiceItemsData(updatedServiceItemsData);
                    }}
                    innerRef={ServiceItemsPanelRef} />);
            case 6:
                return (<ActivitiesPanel
                    loading={isLoading}
                    data={activitiesData}
                    innerRef={ActivitiesPanelRef}
                    CustomerId={selectedCustomerId}
                    changeConfirmationDialog={changeConfirmationDialog}
                    setData={setActivitiesData} />);
            case 7:
                return (<ConfirmationPanel
                    loading={isLoading}
                    data={confirmationData} />);
            default:
                return undefined;

        }

    }

    function handleContactForm({ target }: any): void {
        changeFormFieldValue(target.name, target.value || '');
    }

    function getModalFormProps(): any {
        const { CustomerId, DeliveryType } = formValues;

        const formProps = {
            style: { minWidth: '668px', height: '622px' },
            formSchema: newJobSchema(),
            customerId: Number(CustomerId) || 0,
            fieldExtensions: ({
                CustomerId: {
                    disabled: !forNewJob,
                    display: selectedCustomerDisplay,
                    description: selectedCustomerDescription,
                    onSelectedItemChanged: handleCustomerChange,
                },
                ContactType: {
                    onSelectedItemChange: handleContactTypeChange
                },
                Contact: {
                    onChange: handleContactForm
                },
                Phone: {
                    onChange: handleContactForm
                },
                Email: {
                    onChange: handleContactForm
                },
                DeliveryType: {
                    onChange: (value) => {
                        if (value[0] === '2') {
                            changeFormFieldValue('address', {});
                            changeFormFieldValue('DeliverySite', '');
                        }
                    }
                },
                DeliverySite: {
                    disabled: DeliveryType !== '1',
                    required: DeliveryType === '1',
                    onSelectedItemChange: (item) => { handleDeliverySiteChange(item, Number(CustomerId)); }

                },
                CustomerPO: {
                    required: selectedCustomerDetails && selectedCustomerDetails.EnforceOrder,
                },
                address: {
                    disabled: DeliveryType !== '1',
                },
                JobTemplate: {
                    onSelectedItemChange: handleJobTemplateChange
                },
                SaveDelivery: {
                    disabled: DeliveryType !== '1' || selectedCustomerDetails.BlockSaveDeliveryAddress,
                }
            })
        };

        return isFormPanel() ? formProps : {};
    }

    return (
        <FormViewModal
            open={open}
            loading={isLoading}
            title={TITLES[panel] || 'New Job'}
            actions={modalActions()}
            {...isFormPanel() ? { formProps: getModalFormProps() } : { modalContent: getModalContent() }}
        />
    );
};

export default NewJobModal;
// tslint:enable: no-magic-numbers
