import { processValidationForms as defaultProcessValidationForms } from 'utils/processValidationForms';
import { IWorksaleGridLineDetailFacade, IErrorResponse, IWorksaleGridLineDetailSearchResponse, IFormInfo } from 'api/swaggerTypes';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { IObjectifiedWorksaleGridLineDetailsResponseResponse } from 'api/Worksale/worksale';

interface IWorksaleGridLineDetailFacadeExtended {
  Forms?: IFormInfo[];
}
/**
 * This creates a generic data source
 * Called by both Mock data Sources and functions that wrap this with calls to integrated APIs.
 * @param api api functions
 * @param processValidationForms form validation
 * @returns Data Source of Worksale Lines
 */
export function createWorksaleLinesDataSource(
  api: {
    fetch(batchPage?: number, batchSize?: number, filter?: any): Promise<IWorksaleGridLineDetailSearchResponse>;
    totalCount(): Promise<number>;
    remove(lineNumber: number): Promise<IErrorResponse>;
    insert(worksaleLineSummary: IWorksaleGridLineDetailFacade): Promise<IObjectifiedWorksaleGridLineDetailsResponseResponse>;
    update(worksaleLineSummary: IWorksaleGridLineDetailFacade): Promise<IObjectifiedWorksaleGridLineDetailsResponseResponse>;
  },
  processValidationForms: typeof defaultProcessValidationForms): DataSource {

  const insertCallBack = async (item: IWorksaleGridLineDetailFacade) => {
    let response = await api.insert(item);

    response = await processValidationForms(response, item, async (query) => insertCallBack(query));

    return response.GridLineDetails ? { ...response.GridLineDetails.inlineObject, ...response } : {};
  };

  const updateCallBack = async (key: any, item: IWorksaleGridLineDetailFacade): Promise<IWorksaleGridLineDetailFacadeExtended> => {
    const combinedKeyAndItem = { ...item, WorksalelineId: key };
    let response = await api.update(combinedKeyAndItem);

    response = await processValidationForms(response, item, async (query) => updateCallBack(key, query));

    return response.GridLineDetails ? { ...response.GridLineDetails.inlineObject, Forms: response.Forms } : {};
  };

  return new DataSource({
    key: 'WorksalelineId',
    load: async (loadOptions: LoadOptions): Promise<IWorksaleGridLineDetailFacade[]> => {
      const defaultSkip = 0;
      const defaultTake = 10;
      const skip = loadOptions.skip || defaultSkip;
      const take = loadOptions.take || defaultTake;

      const BatchPage = (skip / take) + 1;
      const BatchSize = take;

      if (loadOptions.filter) {
        const { filter } = loadOptions;
        const filterMap = {};
        const addToMap = (key, value) => {
          if (filterMap.hasOwnProperty(key)) {
            filterMap[key].push(value);
          } else {
            filterMap[key] = [value];
          }
        };
        const extractFilter = (filterData) => {
          if (filterData && filterData.filterValue) {
            addToMap(filterData[0], filterData.filterValue);
          } else if (typeof (filterData) !== 'string' && filterData !== null && Array.isArray(filterData)) {
            filterData.forEach((item) => {
              if (typeof (item) !== 'string') {
                extractFilter(item);
              }
            });
          }
        };

        extractFilter(filter);
        const response = await api.fetch(BatchPage, BatchSize, filterMap);

        return response.GridLines;
      } else {
        const response = await api.fetch(BatchPage, BatchSize);

        return response.GridLines;
      }
    },
    remove: async (key: any): Promise<void> => {
      await api.remove(key);
    },
    insert: insertCallBack,
    update: updateCallBack,
    totalCount: async (_loadOptions: LoadOptions): Promise<number> => {
      return api.totalCount();
    }
  });
}
