import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
  }
}, 'whereUsedCatalogue');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    disableExcelExport: true
  },
  Columns: [
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Entry', field: 'Description', minWidth: 400, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
  
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.whereUsed.whereUsedCatalogue,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.whereUsed.whereUsedCatalogue.fetch_loading,
  columns: state => state.inventoryEnquiry.whereUsed.whereUsedCatalogue.data.Columns,
  gridOptions: state => state.inventoryEnquiry.whereUsed.whereUsedCatalogue.data.gridOptions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)