import { takeLatest, Effect } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLinesLotDetails';

import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierInvoiceEnquiry/invoiceLinesLotDetails';

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(invoiceLineTypes.fetch, connectSagaToApi(invoiceLineActions, 'fetch', api.search));
}
