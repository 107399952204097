import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import OutstandingCustomerQuotes from './OutstandingCustomerQuotes';
import { IOutstandingCustomerQuotesProperties } from './OutstandingCustomerQuotes.properties';
import * as api from 'api/dashboardMaintenance/dashboardWidgets/OutstandingCustomerQuotes';
import { touch, getFormValues, change } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';

const OUTSTANDING_QUOTES_FORM_NAME = 'OutstandingCustomerQuotes';

const mapStateToProps = (state: IApplicationState) => ({
    modalFormValues: getFormValues(OUTSTANDING_QUOTES_FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        touchFormFields: (...args: any) => touch(OUTSTANDING_QUOTES_FORM_NAME, ...args),
        updateFormField: (FieldName: string, FieldValue: any) => change(OUTSTANDING_QUOTES_FORM_NAME, FieldName, FieldValue)
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
    IOutstandingCustomerQuotesProperties, 'getOutstandingCustomerQuotes'>>) => {
    return (
        <OutstandingCustomerQuotes
            getOutstandingCustomerQuotes={api.getOutstandingCustomerQuotes}
            {...props} />
    );
});
