const SummaryTableSchema = [
  {
    objectName: 'ProductSummary',
    isLoading: 'loadingProductSummary',
    children: [
      {
        label: 'Product',
        fieldNames: [
          'ProductIdDisplay',
          'ProductIdLabel'
        ],
        valueRenderer: {
          api: {
            api: '/inventoryEnquiry/lookup/action',
            name: 'enquiry',
            params: {
              queryFields: {
                ProductId: 'ProductId',
                SearchText: 'ProductId'
              }
            }
          }
        }
      },
      {
        label: 'Product Group',
        fieldNames: [
          'ProductGroup',
          'ProductGroupLabel'
        ]
      },
      {
        label: 'Product Type',
        fieldNames: [
          'ProductTypeLabel'
        ]
      },
    ]
  },
  {
    objectName: 'ProductSummary',
    isLoading: 'loadingProductSummary',
    children: [
      {
        label: 'Warehouse',
        fieldNames: [
          'WarehouseEntity',
          'WarehouseEntityDescription'
        ],
      },
      {
        label: 'Available',
        fieldNames: [
          'AvailableInWarehouseDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'Elsewhere',
        fieldNames: [
          'AvailableElsewhereDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
    ]
  },
  {
    objectName: 'ProductSummary',
    isLoading: 'loadingProductSummary',
    children: [
      {
        label: 'Cost',
        fieldNames: [
          'DisplayCostDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'Base',
        fieldNames: [
          'BasePriceDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'GP',
        fieldNames: [
          'GrossPercentageDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'SELL/UNIT',
        fieldNames: [
          'SellPerUnitIncTaxAmountDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
      {
        label: 'SELL/UNIT (EX. TAX)',
        fieldNames: [
          'SellPerUnitExTaxAmountDisplay'
        ],
        valueRenderer: {
          isNumericDisplay: true
        }
      },
    ]
  }
];

export default SummaryTableSchema;
