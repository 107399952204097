import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils';

interface IQueryParams {
  ProductId: number;
  Page: number;
  BatchSize?: number;
  filters?: any;
  Sort: string;
  SortDirection: string;
}

export const fetch = async (query: IQueryParams): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { ProductId, Page, BatchSize = DEFAULT_PAGE_SIZE, filters, Sort, SortDirection, } = query;
  const respType = 'Inline';

  return fetchPost(
    `/InventoryEnquiry/Product/${ProductId}/Pricing/PriceMatrix`,
    filters,
    {
      BatchPage: Page,
      BatchSize,
      Sort: Sort && SortDirection && `${Sort}:${SortDirection}`
    },
    respType)
    .then(
      (result: {
        InventoryPriceMatrixs: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.InventoryPriceMatrixs) {

            return {
              records: result.InventoryPriceMatrixs.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
