import {
    asyncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {
    IActivitySearchFacade,
    ISortOrder,
    IActivityDetailFacade,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';
import { isNull } from 'utils/utils';

export interface IActivityData {
    activityData: { inlineObject: IActivitySearchFacade; schema: Inline<IActivitySearchFacade> };
    selected: string;
    list: IActivitySearchFacade[];
    schemas: Inline<IActivitySearchFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IActivityState extends IExtendedState<IActivityData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
    fetchActivityData_loading?: boolean;
    create_loading?: boolean;
    update_loading?: boolean;
    delete_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: IActivityData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    activityData: { inlineObject: {}, schema: {} },
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'activity',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (activityCode: string) => activityCode,
            callback: (data: IActivityData, activityCode: string): IActivityData => ({ ...data, selected: activityCode })
        },
        reset: {
          action: () => null,
          callback: (data: IActivityData): IActivityData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }) => (search),
            callback: (data: IActivityData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IActivityData => {
                const activities = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: activities,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        searchById: {
            action: (activityCode: string) => ({ ActivityCode: activityCode }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IActivityData => {
                const activities = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: activities,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        fetchActivityData: {
            action: (activityCode: string) => ({ ActivityCode: activityCode }),
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    activityData: { inlineObject: activities[0], schema: schemas[0] },
                };
            }
        },
        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IActivityData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newRemoval = payload.records.length;
                const newList = data.list.concat(activities);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },
        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IActivityData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IActivityData, payload: { records: IObjectified<IActivitySearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IActivityData => {
                const activities = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newList = activities.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },

        create: {
            action: (values: IActivityDetailFacade) => (values),
            successCallback: (data: IActivityData, payload: { ActivityDetails: IObjectified<IActivitySearchFacade> }): IActivityData => {

                return { ...data, activityData: payload.ActivityDetails };
            }
        },
        update: {
            action: (values: IActivityDetailFacade) => (values),
            successCallback: (data: IActivityData, payload: { ActivityDetails: IObjectified<IActivitySearchFacade> }): IActivityData => {

                return { ...data, activityData: payload.ActivityDetails };
            }
        },
        delete: {
            action: (ActivityCode: string) => (ActivityCode),
            successCallback: (data: IActivityData): IActivityData => {
                const list = [...data.list];
                const schemas = [...data.schemas];
                if (!isNull(list)) {
                    const selected = data.list.find((element) => element.ActivityCode === data.selected);
                    list.splice(list.indexOf(selected), 1);
                }
                if (!isNull(schemas)) {

                    const selectedSchema = data.schemas.find((element) => element.ActivityCode.Value === data.selected);
                    schemas.splice(schemas.indexOf(selectedSchema), 1);
                }

                return {
                    ...data, list, schemas,
                    selected: NOT_SELECTED,
                    activityData: { inlineObject: {}, schema: {} }
                };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ActivityCode === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ActivityCode.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
        activityData: (data) => data.activityData,
    }
);

const syncSelector = {
    ActivityCode: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.data.selected,
    isLoading: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.search_loading || state.activityMaintenance.activity.searchById_loading,
    loadingNextPage: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.fetchNextPage_loading,
    loadingPrevPage: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.fetchPrevPage_loading,
    pageSize: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.data.pageSize,
    totalPages: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.data.totalPages,
    notifications: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.notifications,
    error: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.error,
    isFormLoading: (state: { activityMaintenance: { activity: IActivityState } }) => state.activityMaintenance.activity.fetchActivityData_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
