import { IFormViewForm } from 'components/FormView';

const ChangeDeliveryForm: IFormViewForm = {
    id: 'ChangeDeliveryForm',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1,
                colspan: 3
            },
            type: 'PAPER_CONTAINER',
            props: {
                elevation: 0
            },
            children: [
                {
                    type: 'TYPOGRAPHY',
                    props: {
                        text: 'Move all sales lines from current site to new delivery site.',
                        type: 'default'
                    }
                },
                {
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Current Site',
                        name: 'SiteCode',
                        size: 'small',
                        lookupName: 'WorksaleDelivery',
                        isLookupTypeFixed: true,
                        isWorksaleScoped: true,
                        disabled: true
                    }
                },
                {
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'New Site',
                        name: 'NewSiteCode',
                        size: 'small',
                        lookupName: 'WorksaleDelivery',
                        isLookupTypeFixed: true,
                        required: true,
                        isWorksaleScoped: true
                    }
                },
                {
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Default Delivery',
                        name: 'Primary',
                        defaultValue: false,
                    }
                },
            ]
        }
    ]
};
export default ChangeDeliveryForm;
