import {
  createActions, asyncInitialState, asyncSelectors,
  asyncOnRequest,
  asyncOnSuccess, asyncOnError, dateComparator
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
  }
}, 'saleDeposits');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'DepositDetails',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Date', field: 'Date', comparator: dateComparator, suppressSorting: true, type: 'numericColumn', minWidth: 150 },
    { headerName: 'Deposit received', field: 'DepositReceivedDisplay', suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Deposit outstanding', field: 'DepositOutstandingDisplay', suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Currency', field: 'Currency', suppressSorting: true, },
    { headerName: 'G/L Batch', field: 'Batch', suppressSorting: true, type: 'numericColumn' },
  ],
  lines: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
   
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.saleDeposits,
  {
    lines: data => data.lines,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.saleDeposits.loading,
  gridOptions: state => state.salesOrderEnquiry.saleDeposits.data.gridOptions,
  lineColumns: state => state.salesOrderEnquiry.saleDeposits.data.lineColumns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)