import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as purchaseOrderSelector, actions as purchaseOrderActions } from 'ducks/supplierEnquiry/purchasing/purchaseOrders';
import { fetch } from 'api/supplierEnquiry/purchasing/purchaseOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SupplierPurchasingOrders',
  columnDefs: purchaseOrderSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: purchaseOrderSelector.gridOptions(state),
  filterRow: purchaseOrderSelector.filterRow(state),
  selectedFilters: purchaseOrderSelector.selectedFilters(state),
  appliedFilters: purchaseOrderSelector.filterRow(state) && filterSelectors.getFilters(state, purchaseOrderSelector.filterRow(state).formName),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
    &&
    {
      Supplier: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: purchaseOrderActions.setSelected,
  applyFilters: filterActions.applyFilters
};
