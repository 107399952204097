import {
    asyncSelectors,
    syncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {
    IServiceAgreementFacade,
    ISortOrder,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';

export interface IServiceAgreementData {
    selected: number;
    list: IServiceAgreementFacade[];
    schemas: Inline<IServiceAgreementFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IServiceAgreementState extends IExtendedState<IServiceAgreementData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = null;

const initialData: IServiceAgreementData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'serviceAgreement',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (serviceAgreementId: number) => serviceAgreementId,
            callback: (data: IServiceAgreementData, serviceAgreementId: number): IServiceAgreementData => ({ ...data, selected: serviceAgreementId })
        },
        reset: {
          action: () => null,
          callback: (data: IServiceAgreementData): IServiceAgreementData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
            callback: (data: IServiceAgreementData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IServiceAgreementData, payload: { records: IObjectified<IServiceAgreementFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceAgreementData => {
                const serviceAgreements = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: serviceAgreements,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        searchById: {
            action: (serviceAgreementId: number) => ({ ServiceAgreementId: serviceAgreementId }),
            successCallback: (data: IServiceAgreementData, payload: { records: IObjectified<IServiceAgreementFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IServiceAgreementData => {
                const serviceAgreements = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: serviceAgreements,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IServiceAgreementData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServiceAgreementData, payload: { records: IObjectified<IServiceAgreementFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceAgreementData => {
                const serviceAgreements = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newRemoval = payload.records.length;
                const newList = data.list.concat(serviceAgreements);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },
        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IServiceAgreementData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServiceAgreementData, payload: { records: IObjectified<IServiceAgreementFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceAgreementData => {
                const serviceAgreements = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newList = serviceAgreements.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { serviceAgreementMaintenance: { serviceAgreement: IServiceAgreementState } }) => state.serviceAgreementMaintenance.serviceAgreement,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ServiceAgreementId === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ServiceAgreementId.Value === data.selected)) || {},
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
    }
);

const syncSelector = syncSelectors(
    (state: { serviceAgreementMaintenance: { serviceAgreement: IServiceAgreementState } }) => state.serviceAgreementMaintenance.serviceAgreement,
    {
        ServiceAgreementId: (serviceAgreement) => serviceAgreement.data.selected,
        isLoading: (serviceAgreement) => serviceAgreement.search_loading || serviceAgreement.searchById_loading,
        loadingNextPage: (serviceAgreement) => serviceAgreement.fetchNextPage_loading,
        loadingPrevPage: (serviceAgreement) => serviceAgreement.fetchPrevPage_loading,
        pageSize: (serviceAgreement) => serviceAgreement.data.pageSize,
        totalPages: (serviceAgreement) => serviceAgreement.data.totalPages,
        notifications: (serviceAgreement) => serviceAgreement.notifications,
        error: (serviceAgreement) => serviceAgreement.error,
    }
);

export const selectors = { ...asyncSelector, ...syncSelector };
