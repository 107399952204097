import { connect } from 'react-redux';
import { getFormValues, reset, isDirty, isValid, getFormSyncErrors, touch, untouch } from 'redux-form';
import { Dispatch, bindActionCreators } from 'redux';
import TemplateDetails from './TemplateDetails';
import { actions as uiActions } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { IApplicationState } from 'ducks/reducers';
import { selectors as extractMaintenanceSelectors, actions as extractMaintenanceActions } from 'ducks/extractMaintenance/extractMaintenanceTemplates';
import { selectors as formSelectors } from 'ducks/form';

const params = new URLSearchParams(location.search);
const FORM_NAME = 'TemplateDetailsForm';

const mapStateToProps = (state: IApplicationState) => ({
    selectedTemplateId: extractMaintenanceSelectors.selected(state)?.TemplateId ?? params.get('TemplateId'),
    dirty: isDirty(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
    operationMode: operationSelectors.operationMode(state),
    isValid: isValid(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    formAsyncErrors: formSelectors.getFormAsyncErrors(FORM_NAME)(state),
    searchLoading: extractMaintenanceSelectors.isLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeOperationMode: operationActions.changeOperationMode,
        changeSelectedRecord: extractMaintenanceActions.setSelected,
        searchById: extractMaintenanceActions.searchById,
        search: extractMaintenanceActions.search,
        resetForm: () => reset(FORM_NAME),
        touchFormFields: (...args: any) => touch(FORM_NAME, ...args),
        untouchFormFields: (...args: any) => untouch(FORM_NAME, ...args),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDetails);
