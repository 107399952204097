import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelector } from 'ducks/ui';
import { actions as formActions } from 'ducks/form';
import { IApplicationState } from 'ducks/reducers';
import BreadcrumbBar from './BreadcrumbBar';

const mapStateToProps = (state: IApplicationState) => ({
  scope: uiSelector.scope(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeSelectedTab: uiActions.changeSelectedTab,
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    changeSelectedForm: formActions.setSelected,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbBar);
