import * as React from 'react';
import { withRouter } from 'react-router';
import { IActivitiesProps } from '../interfaces';
import FormView from 'components/FormView';
import { Operation } from 'utils/operations';
import { isNull } from 'utils/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
class Activities extends React.PureComponent<IActivitiesProps> {
    operationMap: Map<Operation, (prevProps?: Readonly<IActivitiesProps>) => void>;

    constructor(props: Readonly<IActivitiesProps>) {
        super(props);
        this.operationMap = new Map<Operation, () => void>();
        this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
        this.operationMap[Operation.BACK] = this.cancelFormSubmission;
        this.operationMap[Operation.SAVE] = this.submitFormValues;
        this.operationMap[Operation.DELETE] = this.deleteActivity;
        this.operationMap[Operation.BROWSE] = this.resetFormData;
    }

    componentDidMount(): void {
        const { path, onInitialLoad, setSelectedActivity } = this.props;

        setSelectedActivity('');
        if (path) {
            onInitialLoad('Activities');
        }
    }

    componentDidUpdate(prevProps: Readonly<IActivitiesProps>): void {
        const { operationMode, selectedJobTemplate, selectedActivity, activityData, fetchActivityData } = this.props;

        if (operationMode !== prevProps.operationMode) {
            if (this.operationMap[operationMode]) {
                this.operationMap[operationMode](prevProps);
            }
        }
        if (prevProps.selectedJobTemplate !== selectedJobTemplate) {
            this.resetForm();
        }
        if (selectedActivity && selectedActivity?.ActivityCode !== prevProps?.selectedActivity?.ActivityCode && isNull(activityData.inlineObject)) {
            fetchActivityData(selectedActivity.ActivityCode);
        }
    }

    resetFormData = (): void => {
        const { resetFormData, formData } = this.props;

        if (formData && formData.inlineObject && formData.inlineObject.ActivityCode) {
            resetFormData();
        }
    }

    deleteActivity = (): void => {
        const { deleteActivity, formValues = {}, changeOperationMode, changeConfirmationDialog } = this.props;
        const params = new URLSearchParams(location.search);
        const TemplateCode = params.get('TemplateCode');

        if (formValues.ActivityCode) {
            changeConfirmationDialog({
                open: true,
                title: 'Delete Activity',
                message: 'Are you sure you want to delete?',
                okLabel: 'Delete',
                onCancel: () => {
                    changeOperationMode(Operation.BROWSE);
                },
                onOk: () => {
                    deleteActivity(TemplateCode, formValues.ActivityCode);
                }
            });
        }
        changeOperationMode(Operation.BROWSE);
    }

    submitFormValues = (prevProps: Readonly<IActivitiesProps>): void => {
        const { isValid, formSyncErrors, changeOperationMode, touchFormFields, formValues } = this.props;
        if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0)) {
            const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
            if (requiredError) {
                showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
                touchFormFields(requiredError);
            } else {
                const firstError = Object.keys(formSyncErrors)[0];
                showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
            }
            changeOperationMode(prevProps.operationMode);

            return;
        }
        if (isNull(formValues.LabourProductId)) {
            showSnackBar({ variant: 'warning', message: 'Only activities with valid Labour Product can be added.' });
            changeOperationMode(Operation.CANCEL);

            return;
        }
        this.addActivity();

        changeOperationMode(Operation.BROWSE);
    }

    addActivity = () => {
        const { addActivity, location, selectedJobTemplate, formValues } = this.props;
        const params = new URLSearchParams(location.search);
        const TemplateCode = selectedJobTemplate ? selectedJobTemplate.TemplateCode : parseInt(params.get('TemplateCode'));
        const ActivityCode = formValues.ActivityCode;
        addActivity(TemplateCode, ActivityCode);
    }

    cancelFormSubmission = (prevProps: Readonly<IActivitiesProps>) => {
        const {
            changeOperationMode, onInitialLoad, selectedTab, operationMode, history
        } = this.props;

        if (operationMode === Operation.CANCEL || prevProps.operationMode === Operation.EDIT) {
            this.resetForm();
            changeOperationMode(Operation.BROWSE);
        } else if (selectedTab !== 'Activities') {
            onInitialLoad('Activities');
            changeOperationMode(Operation.BROWSE);
        } else if (selectedTab === 'Activities') {
            history.push('/dashboard');
        }
    }

    resetForm = () => {
        const { resetActivityData } = this.props;
        resetActivityData();
    }

    getDynamicBrowseLookup = (selectedTab: string) => {
        const { location, selectedJobTemplate } = this.props;

        const params = new URLSearchParams(location.search);
        const TemplateCode = selectedJobTemplate ? selectedJobTemplate.TemplateCode : params.get('TemplateCode');

        switch (selectedTab) {
            case 'Activities':
                return {
                    open: true,
                    variant: 'Activities',
                    recordChangeCallBacks: [this.fetchActivitiesRecord],
                    searchParams: {
                        TemplateCode: TemplateCode
                    },
                    searchPlaceholder: 'Search for Activities...',
                    isBrowseLookUp: true,
                    hideFilterButton: true
                };

            default: return null;
        }

        return null;
    }

    getInitialValues = (): any => {
        const { activityData, selectedTab, operationMode, formData } = this.props;
        if (operationMode === Operation.CANCEL) { return; }
        if (operationMode === Operation.NEW) {

            return {
                initialValues: formData && formData.inlineObject || {},
                valuesSchema: formData && formData.schema || {}
            };
        }

        switch (selectedTab) {
            case 'Activities':
                return {
                    initialValues: activityData && activityData.inlineObject,
                    valuesSchema: activityData && activityData.schema
                };
            default:
                return null;
        }
    }

    fetchActivitiesRecord = () => {
        const { location, selectedActivity } = this.props;
        const params = new URLSearchParams(location.search);
        const ActivityCode = selectedActivity && selectedActivity.ActivityCode || params.get('ActivityCode');

        if (ActivityCode) {
            this.props.fetchActivityData(ActivityCode);
        }
    }

    handleActivityCodeChange = (item: any) => {
        if (this.props.operationMode !== Operation.BROWSE) {
            const ActivityCode = item && item.Code;

            if (ActivityCode) {
                this.props.updateActivity(ActivityCode);
            }
        }
    }

    render(): React.ReactNode {
        const { isBrowseLookUpOpen, selectedForm, operationMode, formName, isFormLoading, ServiceItemTypeId } = this.props;
        const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

        return (selectedForm &&
            <React.Fragment>
                <FormView
                    isLoading={isFormLoading}
                    formName={formName}
                    browseLookUpOpen={isBrowseLookUpOpen}
                    browseLookUpComponent={this.getDynamicBrowseLookup(formName)}
                    schema={selectedForm}
                    initialValues={initialValues}
                    operationMode={operationMode}
                    valuesSchema={valuesSchema}
                    fieldExtensions={{
                        ActivityCode: {
                            onSelectedItemChange: this.handleActivityCodeChange,
                            params: isNull(ServiceItemTypeId) ? { NoServiceItemType: true } : { ServiceItemTypeId }
                        }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(Activities);
