import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors } from 'ducks/supplierEnquiry/supplierDetailsTransactions';
import { selectors as transactionSelector } from 'ducks/supplierEnquiry/supplierTransactions';
import { search } from 'api/supplierEnquiry/detailsTransactions';
import { IApplicationState } from 'ducks/reducers';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SupplierEnquiryDetailsTransactions',
  columnDefs: lineSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lineSelectors.gridOptions(state),
  reqParams: (transactionSelector.selected(state) || params().get('TransactionNumber'))
    &&
    {
      TransactionNumber: (transactionSelector.selected(state) && transactionSelector.selected(state).TransactionNumber) || params().get('TransactionNumber')
    },
  keyFields: [{ valueField: 'TransactionNumber', routeField: 'TransactionNumber' }],
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected
};
