import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as customerActions, selectors as customerSelectors } from 'ducks/customer';
import { selectors as staffSelectors, actions as staffActions } from 'ducks/staff';
import { actions as formActions } from 'ducks/form';

import Layout from './Layout';

const mapStateToProps = (state) => (
  {
    loggedIn: staffSelectors.loggedIn(state),
    isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
    scope: uiSelectors.scope(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedCustomer: customerSelectors.selected(state),
  });

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    openMainMenu: uiActions.openMainMenu,
    closeMainMenu: uiActions.closeMainMenu,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    login: staffActions.login,
    changeSelectedTab: uiActions.changeSelectedTab,
    changeSelectedCustomer: customerActions.setSelected,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedForm: formActions.setSelected,
    getFormSchema: formActions.search,
    changeScope: uiActions.changeScope
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
