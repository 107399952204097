import {
    asyncSelectors, IExtendedState, initializeReducer,
    deleteKey
} from 'ducks/utils';
import { Inline, IObjectified } from 'api/utils';
import { ISortOrder, IActivityCapabilityLinkFacade, IActivityCapabilityLinkDetailsResponse } from 'api/swaggerTypes';

export interface ICapabilityData {
    selected: string;
    list: IActivityCapabilityLinkFacade[];
    schemas: Inline<IActivityCapabilityLinkFacade>[];
    capabilityData: IObjectified<IActivityCapabilityLinkDetailsResponse>;
}

export interface ICapabilityState extends IExtendedState<ICapabilityData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: ICapabilityData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    capabilityData: {
        inlineObject: {},
        schema: {}
    },
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'capability',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (capabilityCode: string) => capabilityCode,
            callback: (data: ICapabilityData, capabilityCode: string) => ({ ...data, selected: capabilityCode })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; ActivityCode: string; Sort?: ISortOrder }) => (search),
            successCallback: (data: ICapabilityData, payload: { records: IObjectified<IActivityCapabilityLinkFacade>[] }): ICapabilityData => {
                const capabilities = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return { ...data, list: capabilities, schemas: schemas, selected: NOT_SELECTED };
            }
        },
        searchById: {
            action: (search: { ActivityCode: string; CapabilityCode: string }) => (search),
            successCallback: (data: ICapabilityData, payload: { records: IObjectified<IActivityCapabilityLinkFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): ICapabilityData => {
                const capabilities = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return { ...data, list: capabilities, schemas: schemas };
            }
        },
        create: {
            action: (ActivityCode: string, CapabilityCode: string) => ({ ActivityCode, CapabilityCode }),
            successCallback: (data: ICapabilityData, payload: IObjectified<IActivityCapabilityLinkDetailsResponse>): ICapabilityData => {
                const inline = payload.inlineObject;
                const schema = payload.schema;

                return { ...data, capabilityData: { inlineObject: inline, schema: schema } };
            }
        },
        delete: {
            action: (ActivityCode: string, CapabilityCode: string) => ({ ActivityCode, CapabilityCode }),
            successCallback: (data: ICapabilityData, payload: IObjectified<IActivityCapabilityLinkFacade>): ICapabilityData => {
                const prevCapabilities = deleteKey(data.list, payload, 'CapabilityCode');
                const prevSchemas = deleteKey(data.schemas, payload, 'CapabilityCode');

                return { ...data, list: prevCapabilities, schemas: prevSchemas };
            }
        }
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { activityMaintenance: { capability: ICapabilityState } }) => state.activityMaintenance.capability,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.CapabilityCode === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.CapabilityCode.Value === data.selected)) || null,
    }
);

const syncSelector = {
    CapabilityCode: (state: { activityMaintenance: { capability: ICapabilityState } }) => state.activityMaintenance.capability.data.selected,
    notifications: (state: { activityMaintenance: { capability: ICapabilityState } }) => state.activityMaintenance.capability.notifications,
    error: (state: { activityMaintenance: { capability: ICapabilityState } }) => state.activityMaintenance.capability.error
};

export const selectors = { ...asyncSelector, ...syncSelector };
