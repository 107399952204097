import { combineReducers } from 'redux';
import movements, { IMovementsState } from './Movements';
import transactions, { ITransactionsState } from './Transactions';
import binLocations, { IBinLocationsState } from './BinLocations';
import lotDetails, { ILotDetailsState } from './LotDetails';

export interface IProductMovementsEnquiryState {
  movements: IMovementsState;
  transactions: ITransactionsState;
  binLocations: IBinLocationsState;
  lotDetails: ILotDetailsState;
}
const combined = combineReducers<IProductMovementsEnquiryState>({
  movements,
  transactions,
  binLocations,
  lotDetails
});

export default combined;
