import { FormViewField } from 'components/FormView';

export default {
    formName: 'RecallQuoteFilterRow',
    validate: null,
    rowModelType: 'serverSide',
    parameters: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Entity View',
            name: 'EntityView',
            lookupName: 'EntityView',
            size: 'small',
            required: true
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Entity',
            name: 'Entity',
            lookupName: 'SalesEntity',
            size: 'small',
            required: false
          }
        },
        {
          id: 3,
          type: 'SELECT_FIELD',
          props: {
            label: 'Show',
            name: 'FinalizationOption',
            required: false,
            options: [
              {
                value: 'Unfinalised',
                label: 'Unfinalised only'
              },
              {
                value: 'Finalised',
                label: 'Finalised only'
              },
            ]
          }
        },
        {
          id: 4,
          type: 'DATE_FIELD',
          props: {
            label: 'Entered range from',
            name: 'FromDate',
            required: false,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            nullable: 'true',
            size: 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px'
            }
          }
        },
        {
          id: 5,
          type: 'DATE_FIELD',
          props: {
            label: 'To',
            name: 'ToDate',
            required: false,
            dateFormat: 'DD/MM/YYYY',
            placeholder: 'DD/MM/YYYY',
            nullable: 'true',
            size: 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px'
            }
          }
        },
      ] as FormViewField[]
};
