import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isDirty, reset, getFormSyncErrors } from 'redux-form';
import KitComponentDetails from './KitComponentDetails';
import { IApplicationState } from 'ducks/reducers';
import IKitComponentDetailsProperties from './KitComponentDetails.properties';
import { actions as uiActions } from 'ducks/ui';
import { bindActionCreators, Dispatch } from 'redux';
import { selectors as configSelectors } from 'ducks/appConfig';

const FORM_NAME = 'KitComponentDetailsForm';
const mapStateToProps = (state: IApplicationState) => ({
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    isV2: configSelectors.isV2(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
    IKitComponentDetailsProperties,
    'dirty' | 'values' | 'formSyncErrors' | 'isV2' | 'resetForm' | 'changeConfirmationDialog'>>) => {
    return (<KitComponentDetails {...props} />);
});
