import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';

export interface IRFCDetailsData {
  rfcDetails: any;
  Comment: any;
  Summary: any;
  rfcSummary: any;
}

export interface IRFCDetailsState extends IExtendedState<IRFCDetailsData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetch: (filters) => (filters),
      fetchComment: (query) => (query),
      fetchSummary: (query) => (query),
      fetchRFCSummary: (query) => (query),
    }
  },
  'rfcDetails');

const initialState = asyncInitialState<IRFCDetailsData>({
  rfcDetails: null,
  Comment: {},
  Summary: null,
  rfcSummary: {}
});

export default (state: IRFCDetailsState = initialState, action: DataAction): IRFCDetailsState => {
  switch (action.type) {
    case types.fetchComment:
    case types.fetchSummary:
    case types.fetchRFCSummary:
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: IRFCDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          rfcDetails: successAction.payload
        };
      });
    case types.saga.fetchComment.success:
      return asyncOnSuccess(state, action, (data: IRFCDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          Comment: { Comment: successAction.payload }
        };
      });
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data: IRFCDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          Summary: successAction.payload
        };
      });
    case types.saga.fetchRFCSummary.success:
      return asyncOnSuccess(state, action, (data: IRFCDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          rfcSummary: successAction.payload
        };
      });
    case types.saga.fetch.failure:
    case types.saga.fetchComment.failure:
    case types.saga.fetchSummary.failure:
    case types.saga.fetchRFCSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { rfcEnquiry: { rfcDetails: IRFCDetailsState } }) => state.rfcEnquiry.rfcDetails,
    {
      rfcDetails: (data: IRFCDetailsData) => data.rfcDetails,
      comment: (data: IRFCDetailsData) => data.Comment,
      summary: (data: IRFCDetailsData) => data.Summary,
      rfcSummary: (data: IRFCDetailsData) => data.rfcSummary,
    }),
  isLoading: (state: { rfcEnquiry: { rfcDetails: IRFCDetailsState } }) => state.rfcEnquiry.rfcDetails.loading,
};
