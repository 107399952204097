import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './ExtractMaintenance.actions';
import reducer, { initialState, IState } from './ExtractMaintenance.reducer';

export const ExtractMaintenance = createContext<IState>(initialState);
export const ExtractMaintenanceDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const useEMDispatch = () => useContext(ExtractMaintenanceDispatchContext);
export const useEMState = () => useContext(ExtractMaintenance);
export const useEMContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(ExtractMaintenance, selector);

interface IPickSlipsStateProvider {
  children: React.ReactNode;
}

const ExtractMaintenanceProvider = ({ children }: IPickSlipsStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ExtractMaintenance.Provider value={state}>
      <ExtractMaintenanceDispatchContext.Provider value={dispatch}>
        {children}
      </ExtractMaintenanceDispatchContext.Provider>
    </ExtractMaintenance.Provider>
  );
};

export default ExtractMaintenanceProvider;
