import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

import { FormViewField } from 'components/FormView';

export interface IProductsData {
  selected: any;
  selectedFilters: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
  filterRow(params: { Supplier: number }): {
    formName: string;
    parameters: FormViewField[];
  };
}

export interface IProductsState extends IExtendedState<IProductsData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
    }
  },
  'supplierPurchasingProducts');

const initialState = asyncInitialState<IProductsData>({
  selected: null,
  selectedFilters: {
    ProductFrom: '',
    ProductTo: ''
  },
  filterRow: (param: { Supplier: number }) => ({
    formName: 'SupplierPurchasingProductsFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Supplier product range from',
          name: 'ProductFrom',
          lookupName: 'SupplierProduct',
          size: 'medium',
          required: false,
          valueField: 'Display',
          suppressValidation: true,
          params: { Supplier: param.Supplier }
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'To',
          name: 'ProductTo',
          valueField: 'Display',
          lookupName: 'SupplierProduct',
          size: 'medium',
          required: false,
          params: { Supplier: param.Supplier },
          suppressValidation: true
        }
      }
    ] as FormViewField[]
  }),
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Supplier Product',
      field: 'SupplierProduct',
      valueGetter: (row) => {

        return row && row.data && row.data.ProductCode;
      },
      minWidth: 180,
      sort: 'asc'
    },
    {
      headerName: 'Description',
      field: 'Description',
      minWidth: 180
    },
    {
      headerName: 'Barcode',
      field: 'BarCode',
      minWidth: 180
    }
  ]
});

export default (state: IProductsState = initialState, action: IDataAction): IProductsState => {
  switch (action.type) {
    case types.setSelected:
      const row = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selected: row
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }) => state.supplierEnquiry.purchasing.products.data.selected,
  gridOptions: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }) => state.supplierEnquiry.purchasing.products.data.gridOptions,
  columns: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }) => state.supplierEnquiry.purchasing.products.data.Columns,
  Actions: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }) => state.supplierEnquiry.purchasing.products.data.Actions,
  filterRow: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }, SupplierId: string) => state.supplierEnquiry.purchasing.products.data.filterRow({ Supplier: parseInt(SupplierId) }),
  selectedFilters: (state: { supplierEnquiry: { purchasing: { products: IProductsState } } }) => state.supplierEnquiry.purchasing.products.data.selectedFilters
});
