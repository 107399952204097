import { ApiError } from 'api/baseApi';
import { IValidationDialogAction, IValidationDialogOptions } from 'components/common/ValidationDialog/ValidationDialog.properties';
import { IFormViewForm } from 'components/FormView/index';
import { isNull } from 'utils/utils';
import { getValidationFormDefinition, getMessageBoxFormDefinition } from 'components/common/ValidationForms/formDefinations';
import { IFormInfo } from 'api/swaggerTypes';

export interface IValidationOptions {
  open?: boolean;
  title?: string;
  message?: string;
  icon?: string;
  color?: string;
  form?: IFormViewForm;
  initialValues?: any;
  valuesSchema?: any;
  pollingTimeoutMilliseconds: number;
  actions?: {
    name: string;
    isDefault: boolean;
    label: string;
    data?: any;
    value?: any;
    cancelRemainingValidations?: boolean;
    cancelFormReSubmission?: boolean;
  }[];
  enableCloseOnOutsideClick?: boolean;
}

export class FormsValidator {
  changeValidationDialog: (options: IValidationDialogOptions) => void;
  actionsCallback: (action: IValidationDialogAction) => void;

  constructor(changeValidationDialog: (options: IValidationDialogOptions) => void, actionsCallback?: (action: IValidationDialogAction) => void) {
    this.changeValidationDialog = changeValidationDialog;
    this.actionsCallback = actionsCallback;
  }

  showNotificationForm = (form: IFormInfo, formValues: any): Promise<{ submitFormValues: boolean; formValues: any; cancelRemainingValidations: boolean }> => {
    return new Promise<{ submitFormValues: boolean; formValues: any; cancelRemainingValidations: boolean }>((resolve) => {
      this.changeValidationDialog({
        open: true,
        title: 'Notification',
        form: null,
        message: form.Message,
        actions: [
          {
            name: 'ok',
            label: 'Ok',
            isDefault: true,
            callback: () => {
              resolve({ submitFormValues: false, formValues: formValues, cancelRemainingValidations: false });
            }
          }
        ]
      });
    });
  }

  isSubmitFormValuesDisabled(action: IValidationOptions['actions'][0]): boolean {
    return action.name === 'cancel' || action.data === null || action.cancelFormReSubmission;
  }

  showValidationForm = async (formDefinition: IValidationOptions, formValues: any, isMessageBox?: boolean): Promise<{ submitFormValues: boolean; formValues: any; cancelRemainingValidations: boolean }> => {
    return new Promise<{ submitFormValues: boolean; formValues: any; cancelRemainingValidations: boolean }>((resolve) => {
      this.changeValidationDialog({
        open: !isMessageBox,
        enableCloseOnOutsideClick: isMessageBox && (formDefinition.title === 'Information' || formDefinition.actions.some((action) => action.name === 'cancel')),
        messageBoxOpen: isMessageBox,
        title: formDefinition.title,
        message: formDefinition.message,
        icon: formDefinition.icon,
        color: formDefinition.color,
        form: formDefinition.form,
        initialValues: formDefinition.form ? formDefinition.initialValues || formValues : null,
        valuesSchema: formDefinition.valuesSchema,
        pollingTimeoutMilliseconds: formDefinition.pollingTimeoutMilliseconds,
        actionsCallback: this.actionsCallback,
        refreshAction: {
          callback: (dialogFormValues) => {
            resolve({ submitFormValues: true, formValues: { ...formValues, ...dialogFormValues }, cancelRemainingValidations: false });
          }
        },
        actions: formDefinition.actions.map((action) => {

          return {
            name: action.name,
            label: action.label,
            isDefault: action.isDefault,
            value: action.value,
            callback: (dialogFormValues) => {
              resolve({ submitFormValues: this.isSubmitFormValuesDisabled(action) ? false : true, formValues: { ...formValues, ...action.data ? action.data : {}, formValues: dialogFormValues, }, cancelRemainingValidations: action.cancelRemainingValidations });
            }
          };
        })
      });
    });
  }

  processValidationForm = async (form: IFormInfo, options: any): Promise<{ submitFormValues: boolean; formValues: any; cancelRemainingValidations: boolean }> => {

    const { formValues = {} } = options;
    const formDefinition = getValidationFormDefinition(form, options);
    const messageBoxDefinition = getMessageBoxFormDefinition(form, options);

    if (!isNull(messageBoxDefinition)) {
      return this.showValidationForm(messageBoxDefinition, formValues, true);
    } else if (!isNull(formDefinition)) {
      return this.showValidationForm(formDefinition, formValues);
    } else if (form.FormType === 'Notification' || form.FormType === 'Error') {
      return this.showNotificationForm(form, formValues);
    } else {
      const error = new ApiError(`Unknown validation code ${form.FormType}`);
      error.showUser = true;
      throw error;
    }
  }

  processValidationForms = async (forms: IFormInfo[], options: any): Promise<{ submitFormValues: boolean; formValues: any }> => {
    const { formValues } = options;
    if (isNull(forms)) {
      return { submitFormValues: false, formValues: formValues };
    }

    const nextForm = forms[0];
    const remainingForms = forms.slice(1);

    const result = await this.processValidationForm(nextForm, options);
    if (result.cancelRemainingValidations || remainingForms.length === 0) {
      return { submitFormValues: result.submitFormValues, formValues: result.formValues };
    }

    return this.processValidationForms(remainingForms, { ...options, formValues: result.formValues });
  }
}
