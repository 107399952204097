import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/inventoryEnquiry/stocking/movements';

import { callApi } from './../../utils';
import * as api from 'api/inventoryEnquiry/stocking/movements';


function* fetch(action){
  const {success, failure} = actions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(types.fetch, fetch)
}