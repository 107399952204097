import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Icon from '@markinson/uicomponents-v2/Icon';
import FormView from '../../FormView';
import { classStyles, headingStyle, titleStyle } from './FormViewModal.styles';
import { IFormViewModalProperties, IActionItem, IFormViewModelHandle } from './FormViewModal.properties';
import FocusLock, { MoveFocusInside } from 'react-focus-lock';

const formStyles = (style) => {

    return {
        ...style,
        height: `calc(${style.height || '490px'} + 32px)`,
        minWidth: `calc(${style.minWidth || '22vw'} + 32px)`
    };
};

const sortActionItems = (actionsItems: IActionItem[]) => {
    const leftActions = actionsItems.filter((action) => action.alignment === 'left');
    const centerActions = actionsItems.filter((action) => action.alignment === 'center');
    const rightActions = actionsItems.filter((action) => (action.alignment === undefined || action.alignment === 'right'));

    return leftActions.concat(centerActions, rightActions.reverse());
};

const DialogActionIcon = ({ action, idx, buttonTitle = '' }) => {
    const iconStyle = action.disabled ? classStyles.disabledIconStyle : classStyles.iconStyle;

    return (
        <div className={buttonTitle}>
            {action.iconName &&
                <Icon
                    key={`${action.iconName}${idx}`}
                    iconName={action.iconName}
                    style={{
                        ...iconStyle,
                        transform: `rotate(${action.angleOfRotation || 0}deg)`
                    }}

                />
            }
            {action.title}
        </div>
    );
};

const dialogActionStyle = (dialogActionsShadow: boolean, dialogActionsButtons: boolean) => ({
    ...dialogActionsButtons ? { paddingRight: '12px', paddingTop: '12px' } : {}, boxShadow: (dialogActionsShadow) ? '0 0 7px 0 rgba(0, 0, 0, 0.65)' : ''
});

function FormViewModal(props: IFormViewModalProperties, ref: React.Ref<IFormViewModelHandle>): JSX.Element {

    const handleClose = () => {
        const { onClose } =
            props;
        if (onClose) {
            onClose();
        }
    };

    const {
        classes, title, open, loading, formProps = {}, actions = [], modalContent, dialogActionsButtons = false,
        dialogActionsShadow = true, subTitle = '', onEnter, onEntered, onKeyDown, onActionsTabOut, onActionShiftTabOut
    } = props;

    const { summaryTableRenderer, initialValues, formSchema = {}, valuesSchema = {}, style = {}, operationMode = 'BROWS', ...restProps } = formProps;
    const isLoading = typeof loading === 'boolean' ? loading : false;
    const [discardDefaultBtnFocus, setDiscardDefaultBtnFocus] = React.useState<boolean>(false);

    const firstButtonRef = React.useRef<HTMLButtonElement>(null);
    const lastButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useImperativeHandle(
        ref,
        () => ({
            focusFirstButton(): void {
                if (firstButtonRef.current) {
                    firstButtonRef.current.focus();
                }
            },
            focusLastButton(): void {
                if (lastButtonRef.current) {
                    lastButtonRef.current.focus();
                }
            },
        })
    );

    React.useEffect(
        () => {
            if (!open) {
                setDiscardDefaultBtnFocus(false);
            }
        },
        [open]
    );

    const memoizedSortedActions = React.useMemo(
        () => sortActionItems(actions),
        [actions]);

    const handleActionKeyDown = (e: any, idx: any) => {
        if (!handleEscapeEvent(e)) {
            const enabledButtons = actions.filter((b) => !b.disabled);

            if (idx === enabledButtons.length - 1 && e.key === 'Tab' && !e.shiftKey) {
                if (onActionsTabOut) {
                    setTimeout(
                        () => {
                            onActionsTabOut();
                        },
                        0
                    );

                    return;
                }

                firstButtonRef.current?.focus();
                e.preventDefault();
            } else if (idx === 0 && e.key === 'Tab' && e.shiftKey) {
                if (onActionShiftTabOut) {
                    onActionShiftTabOut();
                }
                e.preventDefault();
            }
        }
    };

    const handleOnFocus = (action: IActionItem) => {
        if (!action.isDefault) {
            setDiscardDefaultBtnFocus(true);
        }
    };

    const handleEscapeEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            memoizedSortedActions.map((action) => {
                if (action.title === 'Cancel') {
                    if (action.listener) action.listener();
                }
            });

            return true;
        }

        return false;
    };

    return (
        <Dialog
            disableBackdropClick={isLoading}
            disableEscapeKeyDown={isLoading}
            disableEnforceFocus={true}
            classes={{
                paper: summaryTableRenderer ? classes.dialogIncludedSummaryTable : classes.dialog,
            }}
            disableAutoFocus
            aria-labelledby='confirmation-dialog-title'
            open={open || false}
            onClose={handleClose}
            onEnter={onEnter}
            onEntered={onEntered}
            onKeyDown={onKeyDown}
        >
            <DialogTitle id='confirmation-dialog-title'
                className={classes.dialogTitle}
                style={titleStyle(Boolean(subTitle), dialogActionsShadow)}
            >
                <div className={classes.dialogTitleHeading} style={headingStyle(!Boolean(subTitle))}>{title}</div>
                <div className={classes.dialogSubTitleHeading}>{subTitle}</div>
            </DialogTitle>
            <FocusLock autoFocus={false}>
                <MoveFocusInside>
                    <DialogContent className={classes.modalContent} onKeyDown={handleEscapeEvent}>
                        {modalContent ?
                            modalContent
                            : <FormView
                                formName={formSchema && formSchema.id}
                                schema={formSchema}
                                operationMode={operationMode}
                                style={formStyles(style)}
                                initialValues={initialValues}
                                valuesSchema={valuesSchema}
                                isLoading={isLoading}
                                summaryTableRenderer={summaryTableRenderer}
                                {...restProps}
                            />}
                    </DialogContent>
                </MoveFocusInside>
                <DialogActions
                    className={classes.dialogActionBar}
                    style={dialogActionStyle(dialogActionsShadow, dialogActionsButtons)}
                >
                    {memoizedSortedActions && memoizedSortedActions.map((action: IActionItem, idx: number) => {

                        return (
                            <Button
                                key={idx}
                                tabIndex={idx + 1}
                                onClick={() => { if (action.listener) action.listener(); }}
                                style={{ float: action.alignment === 'center' ? 'unset' : (action.alignment || 'right') }}
                                className={dialogActionsButtons ? (action.isDefault && !discardDefaultBtnFocus) ?
                                    classes.active : classes.action : classes.actionBtn}
                                color='primary'
                                disabled={action.disabled || isLoading}
                                autoFocus={action.isDefault}
                                onKeyDown={(e) => handleActionKeyDown(e, idx)}
                                onFocus={() => handleOnFocus(action)}
                                buttonRef={(Ref) => {
                                    if (action.ref) {
                                        action.ref.current = Ref;
                                    }
                                    if (idx === 0) {
                                        firstButtonRef.current = Ref;
                                    }
                                    if (idx === memoizedSortedActions.length - 1) {
                                        lastButtonRef.current = Ref;
                                    }
                                }}
                            >
                                {
                                    dialogActionsButtons ?

                                        <>{action.title}</>
                                        :
                                        <DialogActionIcon
                                            action={action}
                                            idx={idx}
                                            buttonTitle={classes.buttonTitle}
                                        />
                                }
                            </Button>
                        );
                    }
                    )}
                </DialogActions>
            </FocusLock>
        </Dialog >
    );
}

export default withStyles(classStyles, { index: 1 })(React.memo(React.forwardRef(FormViewModal)));
