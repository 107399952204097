import { ActionCreator, ActionCreatorsMapObject } from 'redux';
import { IDataGridProps } from './DataGrid.properties';
import { IDataAction } from '../../../ducks/utils';
import { IApplicationState } from '../../../ducks/reducers';

import { actions as CETransactionsActions, mapStateToProps as CETransactionsSelectors } from './Containers/customerEnquiry/CETransactions';
import { actions as CEChildAccountActions, mapStateToProps as CEChildAccountSelectors } from './Containers/customerEnquiry/childAccounts';
import { actions as CEInvoicesActions, mapStateToProps as CEInvoicesSelectors } from './Containers/customerEnquiry/Invoices';
import { actions as CEBackordersActions, mapStateToProps as CEBackordersSelectors } from './Containers/customerEnquiry/Backorders';
import { actions as CESalesOrdersActions, mapStateToProps as CESalesOrdersSelectors } from './Containers/customerEnquiry/SalesOrders';
import { actions as CEQuotesActions, mapStateToProps as CEQuotesSelectors } from './Containers/customerEnquiry/Quotes';
import { actions as CEPricingActions, mapStateToProps as CEPricingSelectors } from './Containers/customerEnquiry/Pricing';
import { actions as CEDetailsTransactionsActions, mapStateToProps as CEDetailsTransactionsSelectors } from './Containers/customerEnquiry/CEDetailsTransactions';
import { actions as CEMovementsActions, mapStateToProps as CEMovementsSelectors } from './Containers/customerEnquiry/Movements';
import { actions as CEServiceOrdersActions, mapStateToProps as CEServiceOrdersSelectors } from './Containers/customerEnquiry/ServiceOrders';
import { actions as CEEnquiriesActions, mapStateToProps as CEEnquiriesSelectors } from './Containers/customerEnquiry/Enquiries';
import { actions as CEUserFieldsActions, mapStateToProps as CEUserFieldsSelectors } from './Containers/customerEnquiry/USerFields';

import { actions as CQEBOMActions, mapStateToProps as CQEBOMSelectors } from './Containers/customerQuoteEnquiry/CQEBOM';
import { actions as BOMReservationLotActions, mapStateToProps as BOMReservationLotSelectors } from './Containers/customerQuoteEnquiry/CQEBOMReservationLot';
import { actions as BOMReservationSerialActions, mapStateToProps as BOMReservationSerialSelectors } from './Containers/customerQuoteEnquiry/CQEBOMReservationSerial';
import { actions as CQEQuoteLinesActions, mapStateToProps as CQEQuoteLinesSelectors } from './Containers/customerQuoteEnquiry/CQEQuoteLines';
import { actions as CQEReservationLotActions, mapStateToProps as CQEReservationLotSelectors } from './Containers/customerQuoteEnquiry/CQEReservationLot';
import { actions as CQEReservationSerialActions, mapStateToProps as CQEReservationSerialSelectors } from './Containers/customerQuoteEnquiry/CQEReservationSerial';

import { actions as SOEBOMActions, mapStateToProps as SOEBOMSelectors } from './Containers/salesOrderEnquiry/BOM';
import { actions as SOECDPutAwayActions, mapStateToProps as SOECDPutAwaySelectors } from './Containers/salesOrderEnquiry/CDCommittedInPutAwayContainer';
import { actions as SOECDProductionOrderActions, mapStateToProps as SOECDProductionOrderSelectors } from './Containers/salesOrderEnquiry/CDCommittedProductionOrderContainer';
import { actions as SEOCDPurchaseActions, mapStateToProps as SEOCDPurchaseSelectors } from './Containers/salesOrderEnquiry/CDCommittedPurchaseOrderContainer';
import { actions as SOECDReservationLotsActions, mapStateToProps as SOECDReservationLotsSelectors } from './Containers/salesOrderEnquiry/CDReservationLotsContainer';
import { actions as SOECDReservationSerialsActions, mapStateToProps as SOECDReservationSerialsSelectors } from './Containers/salesOrderEnquiry/CDReservationSerialsContainer';
import { actions as SOECommittedInPutAwayActions, mapStateToProps as SOECommittedInPutAwaySelectors } from './Containers/salesOrderEnquiry/CommittedInPutAwayContainer';
import { actions as SOECommittedProductionOrderActions, mapStateToProps as SOECommittedProductionOrderSelectors } from './Containers/salesOrderEnquiry/CommittedProductionOrderContainer';
import { actions as SOECommittedPurchaseOrderActions, mapStateToProps as SOECommittedPurchaseOrderSelectors } from './Containers/salesOrderEnquiry/CommittedPurchaseOrderContainer';
import { actions as SOEDeliveriesActions, mapStateToProps as SOEDeliveriesSelectors } from './Containers/salesOrderEnquiry/DeliveriesContainer';
import { actions as SOEDepositActions, mapStateToProps as SOEDepositSelectors } from './Containers/salesOrderEnquiry/DepositDetails';
import { actions as SOELDPutAwayActions, mapStateToProps as SOELDPutAwaySelectors } from './Containers/salesOrderEnquiry/LDCommittedInPutAwayContainer';
import { actions as SOELDProductionOrderActions, mapStateToProps as SOELDProductionOrderSelectors } from './Containers/salesOrderEnquiry/LDCommittedProductionOrderContainer';
import { actions as SEOLDPurchaseActions, mapStateToProps as SEOLDPurchaseSelectors } from './Containers/salesOrderEnquiry/LDCommittedPurchaseOrderContainer';
import { actions as SOELineInvoicesActions, mapStateToProps as SOELineInvoicesSelectors } from './Containers/salesOrderEnquiry/LineInvoices';
import { actions as SOEOrderLineActions, mapStateToProps as SOEOrderLineSelectors } from './Containers/salesOrderEnquiry/OrderLineContainer';
import { actions as SOEReservationLotsActions, mapStateToProps as SOEReservationLotsSelectors } from './Containers/salesOrderEnquiry/ReservationLotsContainer';
import { actions as SOEReservationSerialsActions, mapStateToProps as SOEReservationSerialsSelectors } from './Containers/salesOrderEnquiry/ReservationSerialsContainer';
import { actions as SOESaleDepositsActions, mapStateToProps as SOESaleDepositsSelectors } from './Containers/salesOrderEnquiry/SaleDeposits';

import { actions as POECommittedServiceOrdersActions, mapStateToProps as POECommittedServiceOrdersSelectors } from './Containers/purchaseOrderEnquiry/CommittedServiceOrdersContainer';
import { actions as POECommittedProductionOrderActions, mapStateToProps as POECommittedProductionOrderSelectors } from './Containers/purchaseOrderEnquiry/CommittedProductionOrderContainer';
import { actions as POECommittedSaleOrderActions, mapStateToProps as POECommittedSaleOrderSelectors } from './Containers/purchaseOrderEnquiry/CommittedSaleOrderContainer';
import { actions as POELDCommittedServiceOrdersActions, mapStateToProps as POELDCommittedServiceOrdersSelectors } from './Containers/purchaseOrderEnquiry/LDCommittedServiceOrdersContainer';
import { actions as POELDCommittedProductionOrderActions, mapStateToProps as POELDCommittedProductionOrderSelectors } from './Containers/purchaseOrderEnquiry/LDCommittedProductionOrderContainer';
import { actions as POELDCommittedSaleOrderActions, mapStateToProps as POELDCommittedSaleOrderSelectors } from './Containers/purchaseOrderEnquiry/LDCommittedSaleOrderContainer';
import { actions as POEBranchOrdersActions, mapStateToProps as POEBranchOrdersSelectors } from './Containers/purchaseOrderEnquiry/BranchOrders';
import { actions as POELDBranchOrdersActions, mapStateToProps as POELDBranchOrdersSelectors } from './Containers/purchaseOrderEnquiry/LDBranchOrders';
import { actions as POEOrderLineActions, mapStateToProps as POEOrderLineSelectors } from './Containers/purchaseOrderEnquiry/OrderLineContainer';
import { actions as POEProductReceiptsActions, mapStateToProps as POEProductReceiptsSelectors } from './Containers/purchaseOrderEnquiry/ProductReceipts';
import { actions as POEInTransitImportShipmentsActions, mapStateToProps as POEInTransitImportShipmentsSelectors } from './Containers/purchaseOrderEnquiry/InTransitImportShipments';
import { actions as POEInTransitLocalShipmentsActions, mapStateToProps as POEInTransitLocalShipmentsSelectors } from './Containers/purchaseOrderEnquiry/InTransitLocalShipments';
import { actions as POELDInTransitImportShipmentsActions, mapStateToProps as POELDInTransitImportShipmentsSelectors } from './Containers/purchaseOrderEnquiry/LDInTransitImportShipments';
import { actions as POELDInTransitLocalShipmentsActions, mapStateToProps as POELDInTransitLocalShipmentsSelectors } from './Containers/purchaseOrderEnquiry/LDInTransitLocalShipments';

import { actions as IEASCAlternatesActions, mapStateToProps as IEASCAlternatesSelectors } from './Containers/inventoryEnquiry/associations/alternates';
import { actions as IEUserFieldsActions, mapStateToProps as IEUserFieldsSelectors } from './Containers/inventoryEnquiry/USerFields';
import { actions as IEASCBOMActions, mapStateToProps as IEASCBOMSelectors } from './Containers/inventoryEnquiry/associations/BOM';
import { actions as IESuppressionsActions, mapStateToProps as IESuppressionsSelectors } from './Containers/inventoryEnquiry/associations/suppressions';
import { actions as IEForeignPricesActions, mapStateToProps as IEForeignPricesSelectors } from './Containers/inventoryEnquiry/pricing/ForeignPrices';
import { actions as IEPriceMatrixActions, mapStateToProps as IEPriceMatrixSelectors } from './Containers/inventoryEnquiry/pricing/PriceMatrix';
import { actions as IEPricesActions, mapStateToProps as IEPricesSelectors } from './Containers/inventoryEnquiry/pricing/Prices';
import { actions as IEScheduleActions, mapStateToProps as IEScheduleSelectors } from './Containers/inventoryEnquiry/pricing/Schedule';
import { actions as IESLostSalesActions, mapStateToProps as IESLostSalesSelectors } from './Containers/inventoryEnquiry/sales/LostSales';
import { actions as IESServiceOrdersActions, mapStateToProps as IESServiceOrdersSelectors } from './Containers/inventoryEnquiry/sales/ServiceOrders';
import { actions as IESSalesOrdersActions, mapStateToProps as IESSalesOrdersSelectors } from './Containers/inventoryEnquiry/sales/SalesOrders';
import { actions as IESQuotesActions, mapStateToProps as IESQuotesSelectors } from './Containers/inventoryEnquiry/sales/Quotes';
import { actions as IESReturnAuthoritiesActions, mapStateToProps as IESReturnAuthoritiesSelectors } from './Containers/inventoryEnquiry/sales/ReturnAuthorities';
import { actions as IESMovementActions, mapStateToProps as IESMovementSelectors } from './Containers/inventoryEnquiry/stocking/movements';
import { actions as IEStockingBinLocationsActions, mapStateToProps as IEStockingBinLocationsSelectors } from './Containers/inventoryEnquiry/stocking/binLocations';
import { actions as IEStockingQuantitiesInPutAwayWIPActions, mapStateToProps as IEStockingQuantitiesInPutAwayWIPSelectors } from './Containers/inventoryEnquiry/stocking/inPutAwayWIP';
import { actions as IEStockingQuantitiesInTransitImportActions, mapStateToProps as IEStockingQuantitiesInTransitImportSelectors } from './Containers/inventoryEnquiry/stocking/inTransitImport';
import { actions as IEStockingQuantitiesInTransitLocalActions, mapStateToProps as IEStockingQuantitiesInTransitLocalSelectors } from './Containers/inventoryEnquiry/stocking/inTransitLocal';
import { actions as IEStockingQuantitiesKittingActions, mapStateToProps as IEStockingQuantitiesKittingSelectors } from './Containers/inventoryEnquiry/stocking/kitting';
import { actions as IEStockingLotDetailsActions, mapStateToProps as IEStockingLotDetailsSelectors } from './Containers/inventoryEnquiry/stocking/lotDetails';
import { actions as IEStockingLotInPutAwayActions, mapStateToProps as IEStockingLotInPutAwaySelectors } from './Containers/inventoryEnquiry/stocking/lotInPutAway';
import { actions as IEStockingLotPickingActions, mapStateToProps as IEStockingLotPickingSelectors } from './Containers/inventoryEnquiry/stocking/lotPicking';
import { actions as IEStockingLotReservedActions, mapStateToProps as IEStockingLotReservedSelectors } from './Containers/inventoryEnquiry/stocking/lotReserved';
import { actions as IEStockingQuantitiesPickingActions, mapStateToProps as IEStockingQuantitiesPickingSelectors } from './Containers/inventoryEnquiry/stocking/picking';
import { actions as IEStockingQuantitiesProductionOrderActions, mapStateToProps as IEStockingQuantitiesProductionOrderSelectors } from './Containers/inventoryEnquiry/stocking/productionOrders';
import { actions as IEStockingQuantitiesProductionWIPActions, mapStateToProps as IEStockingQuantitiesProductionWIPSelectors } from './Containers/inventoryEnquiry/stocking/productionWIP';
import { actions as IEStockingQuantitiesPurchaseOrderActions, mapStateToProps as IEStockingQuantitiesPurchaseOrderSelectors } from './Containers/inventoryEnquiry/stocking/purchaseOrders';
import { actions as IEStockingQuantitiesActions, mapStateToProps as IEStockingQuantitiesSelectors } from './Containers/inventoryEnquiry/stocking/quantities';
import { actions as IEStockingReserveHistoryActions, mapStateToProps as IEStockingReserveHistorySelectors } from './Containers/inventoryEnquiry/stocking/reserveHistory';
import { actions as IEStockingQuantitiesReservedActions, mapStateToProps as IEStockingQuantitiesReservedSelectors } from './Containers/inventoryEnquiry/stocking/reserved';
import { actions as IEStockingSerialsActions, mapStateToProps as IEStockingSerialsSelectors } from './Containers/inventoryEnquiry/stocking/serials';
import { actions as IEStockingQuantitiesServiceWIPActions, mapStateToProps as IEStockingQuantitiesServiceWIPSelectors } from './Containers/inventoryEnquiry/stocking/serviceWIP';
import { actions as WhereUsedBOMActions, mapStateToProps as WhereUsedBOMSelectors } from './Containers/inventoryEnquiry/whereUsed/BOM';
import { actions as WhereUsedCatalogueActions, mapStateToProps as WhereUsedCatalogueSelectors } from './Containers/inventoryEnquiry/whereUsed/Catalogue';
import { actions as HistoricalDatesActions, mapStateToProps as HistoricalDatesSelectors } from './Containers/inventoryEnquiry/HistoricalDates';
import { actions as ProductDetailsActions, mapStateToProps as ProductDetailsSelectors } from './Containers/inventoryEnquiry/ProductDetails';
import { actions as InventoryEnquiryDocumentsActions, mapStateToProps as InventoryEnquiryDocumentsSelectors } from './Containers/inventoryEnquiry/attachments/documents';
import { actions as InventoryEnquiryLinksActions, mapStateToProps as InventoryEnquiryLinksSelectors } from './Containers/inventoryEnquiry/attachments/Links';

import { actions as PCCategoriesActions, mapStateToProps as PCCategoriesSelectors } from './Containers/priceCheck/categories';
import { actions as PCTrueCostActions, mapStateToProps as PCTrueCostSelectors } from './Containers/priceCheck/trueCost';
import { actions as PCSalesHistoryActions, mapStateToProps as PCSalesHistorySelectors } from './Containers/priceCheck/salesHistory';
import { actions as PCMatrixActions, mapStateToProps as PCMatrixSelectors } from './Containers/priceCheck/matrix';
import { actions as PCPricesByWHActions, mapStateToProps as PCPricesByWHSelectors } from './Containers/priceCheck/PCPricesByWH';
import { actions as PCPricesActions, mapStateToProps as PCPricesSelectors } from './Containers/priceCheck/PCPrices';

import { actions as SalesAuditDetailsActions, mapStateToProps as SalesAuditDetailsSelectors } from './Containers/salesInvoiceEnquiry/InvoiceAuditDetails';
import { actions as InvoiceLineBOMActions, mapStateToProps as InvoiceLineBOMSelectors } from './Containers/salesInvoiceEnquiry/InvoiceLineBOM';
import { actions as InvoiceLineLotsActions, mapStateToProps as InvoiceLineLotsSelectors } from './Containers/salesInvoiceEnquiry/InvoiceLineLots';
import { actions as InvoiceLinesActions, mapStateToProps as InvoiceLinesSelectors } from './Containers/salesInvoiceEnquiry/InvoiceLines';
import { actions as InvoiceLineSerialsActions, mapStateToProps as InvoiceLineSerialsSelectors } from './Containers/salesInvoiceEnquiry/InvoiceLineSerials';
import { actions as SalesAuditActions, mapStateToProps as SalesAuditSelectors } from './Containers/salesInvoiceEnquiry/InvoiceSalesAudit';
import { actions as PaymentActions, mapStateToProps as PaymentSelectors } from './Containers/salesInvoiceEnquiry/SIEPayment';
import { actions as PickSlipsByDespatchActions, mapStateToProps as PickSlipsByDespatchSelectors } from './Containers/salesInvoiceEnquiry/slipsByDespatch';
import { actions as PickSlipsByProductActions, mapStateToProps as PickSlipsByProductSelectors } from './Containers/salesInvoiceEnquiry/slipsByProduct';

import { actions as SupplierEnquiryUserFieldsActions, mapStateToProps as SupplierEnquiryUserFieldsSelectors } from './Containers/supplierEnquiry/UserFields';
import { actions as SupplierEnquiryTransactionsActions, mapStateToProps as SupplierEnquiryTransactionsSelectors } from './Containers/supplierEnquiry/Transactions';
import { actions as SupplierEnquiryDetailsTransactionsActions, mapStateToProps as SupplierEnquiryDetailsTransactionsSelectors } from './Containers/supplierEnquiry/DetailsTransactions';
import { actions as SupplierEnquiryInvoiceActions, mapStateToProps as SupplierEnquiryInvoiceSelectors } from './Containers/supplierEnquiry/activity/Invoices';
import { actions as CashReceiptingInvoiceActions, mapStateToProps as CashReceiptingInvoicesSelectors } from './Containers/cashReceipting/Invoices';
import { actions as CashReceiptingOrdersActions, mapStateToProps as CashReceiptingOrdersSelectors } from './Containers/cashReceipting/Orders';
import { actions as PaymentMethodActions, mapStateToProps as PaymentMethodSelectors } from './Containers/common/PaymentDetails';

import { actions as SupplierEnquiryGRNActions, mapStateToProps as SupplierEnquiryGRNSelectors } from './Containers/supplierEnquiry/activity/GRN';
import { actions as SupplierEnquiryMovementsActions, mapStateToProps as SupplierEnquiryMovementsSelectors } from './Containers/supplierEnquiry/activity/Movements';
import { actions as SupplierEnquiryFreightActions, mapStateToProps as SupplierEnquiryFreightSelectors } from './Containers/supplierEnquiry/activity/Freight';
import { actions as SupplierEnquiryRFCActions, mapStateToProps as SupplierEnquiryRFCSelectors } from './Containers/supplierEnquiry/activity/rfc';
import { actions as SupplierEnquiryFinancialChildAccountsActions, mapStateToProps as SupplierEnquiryFinancialChildAccountsSelectors } from './Containers/supplierEnquiry/financial/ChildAccounts';
import { actions as SupplierEnquiryPurchasingPurchaseOrdersActions, mapStateToProps as SupplierEnquiryPurchasingPurchaseOrdersSelectors } from './Containers/supplierEnquiry/purchasing/PurchaseOrders';
import { actions as SupplierEnquirySettingsByWarehouseActions, mapStateToProps as SupplierEnquirySettingsByWarehouseSelectors } from './Containers/supplierEnquiry/settings/SettingsByWarehouse';
import { actions as SupplierEnquirySettingsAttributesActions, mapStateToProps as SupplierEnquirySettingsAttributesSelectors } from './Containers/supplierEnquiry/settings/Attributes';
import { actions as SEPLocalShipmentsActions, mapStateToProps as SEPLocalShipmentsSelectors } from './Containers/supplierEnquiry/SEPlocalShipments';
import { actions as SupplierEnquiryPurchasingProductssActions, mapStateToProps as SupplierEnquiryPurchasingProductsSelectors } from './Containers/supplierEnquiry/purchasing/Products';
import { actions as SupplierEnquiryDocumentsActions, mapStateToProps as SupplierEnquiryDocumentsSelectors } from './Containers/supplierEnquiry/attachments/Documents';
import { actions as SupplierEnquiryLinksActions, mapStateToProps as SupplierEnquiryLinksSelectors } from './Containers/supplierEnquiry/attachments/Links';

import { actions as SupplierInvoiceEnquiryInvoiceLinesActions, mapStateToProps as SupplierInvoiceEnquiryInvoiceLinesSelectors } from './Containers/supplierInvoiceEnquiry/InvoiceLines';
import { actions as SupplierInvoiceEnquiryInvoiceLinesLotDetailsActions, mapStateToProps as SupplierInvoiceEnquiryInvoiceLinesLotDetailsSelectors } from './Containers/supplierInvoiceEnquiry/InvoiceLinesLotDetails';
import { actions as SupplierInvoiceEnquiryInvoiceLinesSerialNumbersActions, mapStateToProps as SupplierInvoiceEnquiryInvoiceLinesSerialNumbersSelectors } from './Containers/supplierInvoiceEnquiry/InvoiceLinesSerialNumbers';
import { actions as SupplierInvoiceEnquiryInvoiceLinesMovementsActions, mapStateToProps as SupplierInvoiceEnquiryInvoiceLinesMovementsSelectors } from './Containers/supplierInvoiceEnquiry/InvoiceLinesMovements';

import { actions as RFCEnquiryRFCLinesActions, mapStateToProps as RFCEnquiryRFCLinesSelectors } from './Containers/rfcEnquiry/RFCLines';

import { actions as pmeTransactionsActions, mapStateToProps as pmeTransactionsSelectors } from './Containers/pme/Transactions';
import { actions as pmeLotDetailsActions, mapStateToProps as pmeLotDetailsSelectors } from './Containers/pme/LotDetails';
import { actions as pmeBinLocationsActions, mapStateToProps as pmeBinLocationsSelectors } from './Containers/pme/BinLocations';

import { actions as EventsActions, mapStateToProps as EventsSelectors } from './Containers/common/events';
import { actions as DiaryActions, mapStateToProps as DiarySelectors } from './Containers/common/diary';

import { actions as PSESlipLinesActions, mapStateToProps as PSESlipLinesSelectors } from './Containers/pickSlipEnquiry/SlipLines';
import { actions as PSELotsActions, mapStateToProps as PSELotsSelectors } from './Containers/pickSlipEnquiry/Lots';
import { actions as PSESerialsActions, mapStateToProps as PSESerialsSelectors } from './Containers/pickSlipEnquiry/Serials';
import { actions as PSEBOMActions, mapStateToProps as PSEBOMSelectors } from './Containers/pickSlipEnquiry/BOM';
import { actions as PSEBOMLotsActions, mapStateToProps as PSEBOMLotsSelectors } from './Containers/pickSlipEnquiry/BOMLots';
import { actions as PSEBOMSerialsActions, mapStateToProps as PSEBOMSerialsSelectors } from './Containers/pickSlipEnquiry/BOMSerials';

import { actions as PSSlipLinesActions, mapStateToProps as PSSlipLinesSelectors } from './Containers/pickSlips/SlipLines';
import { actions as PSLotsActions, mapStateToProps as PSLotsSelectors } from './Containers/pickSlips/PSLots';
import { actions as PSSerialsActions, mapStateToProps as PSSerialsSelectors } from './Containers/pickSlips/Serials';
import { actions as PSBOMActions, mapStateToProps as PSBOMSelectors } from './Containers/pickSlips/BOM';
import { actions as PSBOMLotsActions, mapStateToProps as PSBOMLotsSelectors } from './Containers/pickSlips/BOMLots';
import { actions as PSBOMSerialsActions, mapStateToProps as PSBOMSerialsSelectors } from './Containers/pickSlips/BOMSerials';
import { actions as BinLocationsActions, mapStateToProps as BinLocationsSelectors } from './Containers/pickSlips/BinLocations';
import { actions as KitComponentsActions, mapStateToProps as KitComponentsSelectors } from './Containers/pickSlips/KitComponents';

import { actions as SPEProductPriceScheduleActions, mapStateToProps as SPEProductPriceScheduleSelectors } from './Containers/supplierProductEnquiry/SPEProductPriceSchedule';
import { actions as SPEProductLinksActions, mapStateToProps as SPEProductLinksSelectors } from './Containers/supplierProductEnquiry/SPEProductLinks';
import { actions as SupplierProductEnquiryDocuemtnsActions, mapStateToProps as SupplierProductEnquiryDocuemtnsSelectors } from './Containers/supplierProductEnquiry/attachments/documents';
import { actions as SupplierProductEnquiryLinksActions, mapStateToProps as SupplierProductEnquiryLinksSelectors } from './Containers/supplierProductEnquiry/attachments/Links';

import { actions as securityPolicyActions, mapStateToProps as securityPolicySelectors } from './Containers/securityManagement/policyRules';
import { actions as securityPolicyUserGroupsActions, mapStateToProps as securityPolicyUserGroupsSelectors } from './Containers/securityManagement/policyUserGroups';

import { actions as creditNoteLinesActions, mapStateToProps as creditNoteLinesSelectors } from './Containers/creditNotes/CreditNoteLines';

declare type DataGridActionMap = {
  [index in keyof Partial<IDataGridProps>]: ActionCreator<IDataAction>;
};

declare type DataGridSelectorMap = (state: IApplicationState) => Partial<IDataGridProps>;

const containers: { [module: string]: { actions: DataGridActionMap; selectors: DataGridSelectorMap } } = {
  //common
  Diary: {
    actions: { ...DiaryActions },
    selectors: DiarySelectors
  },
  Events: {
    actions: { ...EventsActions },
    selectors: EventsSelectors
  },

  //Sales Invoice Enquiry
  PickSlipsByProduct: {
    actions: { ...PickSlipsByProductActions },
    selectors: PickSlipsByProductSelectors
  },
  PickSlipsByDespatch: {
    actions: { ...PickSlipsByDespatchActions },
    selectors: PickSlipsByDespatchSelectors
  },
  Payment: {
    actions: { ...PaymentActions },
    selectors: PaymentSelectors
  },
  SalesAudit: {
    actions: { ...SalesAuditActions },
    selectors: SalesAuditSelectors
  },
  InvoiceLineSerials: {
    actions: { ...InvoiceLineSerialsActions },
    selectors: InvoiceLineSerialsSelectors
  },
  InvoiceLines: {
    actions: { ...InvoiceLinesActions },
    selectors: InvoiceLinesSelectors
  },
  InvoiceLineLots: {
    actions: { ...InvoiceLineLotsActions },
    selectors: InvoiceLineLotsSelectors
  },
  InvoiceLineBOM: {
    actions: { ...InvoiceLineBOMActions },
    selectors: InvoiceLineBOMSelectors
  },
  SalesAuditDetails: {
    actions: { ...SalesAuditDetailsActions },
    selectors: SalesAuditDetailsSelectors
  },

  //Price Check
  PCPrices: {
    actions: { ...PCPricesActions },
    selectors: PCPricesSelectors
  },
  PCPricesByWH: {
    actions: { ...PCPricesByWHActions },
    selectors: PCPricesByWHSelectors
  },
  PCMatrix: {
    actions: { ...PCMatrixActions },
    selectors: PCMatrixSelectors
  },
  PCSalesHistory: {
    actions: { ...PCSalesHistoryActions },
    selectors: PCSalesHistorySelectors
  },
  PCTrueCost: {
    actions: { ...PCTrueCostActions },
    selectors: PCTrueCostSelectors
  },
  PCCategories: {
    actions: { ...PCCategoriesActions },
    selectors: PCCategoriesSelectors
  },

  //Inventory Enquiry
  IEUserFields: {
    actions: { ...IEUserFieldsActions },
    selectors: IEUserFieldsSelectors
  },
  ProductDetails: {
    actions: { ...ProductDetailsActions },
    selectors: ProductDetailsSelectors
  },
  HistoricalDates: {
    actions: { ...HistoricalDatesActions },
    selectors: HistoricalDatesSelectors
  },
  WhereUsedCatalogue: {
    actions: { ...WhereUsedCatalogueActions },
    selectors: WhereUsedCatalogueSelectors
  },
  WhereUsedBOM: {
    actions: { ...WhereUsedBOMActions },
    selectors: WhereUsedBOMSelectors
  },
  IEStockingQuantitiesServiceWIP: {
    actions: { ...IEStockingQuantitiesServiceWIPActions },
    selectors: IEStockingQuantitiesServiceWIPSelectors
  },
  IEStockingSerials: {
    actions: { ...IEStockingSerialsActions },
    selectors: IEStockingSerialsSelectors
  },
  IEStockingQuantitiesReserved: {
    actions: { ...IEStockingQuantitiesReservedActions },
    selectors: IEStockingQuantitiesReservedSelectors
  },
  IEStockingReserveHistory: {
    actions: { ...IEStockingReserveHistoryActions },
    selectors: IEStockingReserveHistorySelectors
  },
  IEStockingQuantities: {
    actions: { ...IEStockingQuantitiesActions },
    selectors: IEStockingQuantitiesSelectors
  },
  IEStockingQuantitiesPurchaseOrder: {
    actions: { ...IEStockingQuantitiesPurchaseOrderActions },
    selectors: IEStockingQuantitiesPurchaseOrderSelectors
  },
  IEStockingQuantitiesProductionWIP: {
    actions: { ...IEStockingQuantitiesProductionWIPActions },
    selectors: IEStockingQuantitiesProductionWIPSelectors
  },
  IEStockingQuantitiesProductionOrder: {
    actions: { ...IEStockingQuantitiesProductionOrderActions },
    selectors: IEStockingQuantitiesProductionOrderSelectors
  },
  IEStockingQuantitiesPicking: {
    actions: { ...IEStockingQuantitiesPickingActions },
    selectors: IEStockingQuantitiesPickingSelectors
  },
  IEStockingLotReserved: {
    actions: { ...IEStockingLotReservedActions },
    selectors: IEStockingLotReservedSelectors
  },
  IEStockingLotPicking: {
    actions: { ...IEStockingLotPickingActions },
    selectors: IEStockingLotPickingSelectors
  },
  IEStockingLotInPutAway: {
    actions: { ...IEStockingLotInPutAwayActions },
    selectors: IEStockingLotInPutAwaySelectors
  },
  IEStockingLotDetails: {
    actions: { ...IEStockingLotDetailsActions },
    selectors: IEStockingLotDetailsSelectors
  },
  IEStockingQuantitiesKitting: {
    actions: { ...IEStockingQuantitiesKittingActions },
    selectors: IEStockingQuantitiesKittingSelectors
  },
  IEStockingQuantitiesInTransitLocal: {
    actions: { ...IEStockingQuantitiesInTransitLocalActions },
    selectors: IEStockingQuantitiesInTransitLocalSelectors
  },
  IEStockingQuantitiesInTransitImport: {
    actions: { ...IEStockingQuantitiesInTransitImportActions },
    selectors: IEStockingQuantitiesInTransitImportSelectors
  },
  IEStockingQuantitiesInPutAwayWIP: {
    actions: { ...IEStockingQuantitiesInPutAwayWIPActions },
    selectors: IEStockingQuantitiesInPutAwayWIPSelectors
  },
  IEStockingBinLocations: {
    actions: { ...IEStockingBinLocationsActions },
    selectors: IEStockingBinLocationsSelectors
  },
  IEStockingMovements: {
    actions: { ...IESMovementActions },
    selectors: IESMovementSelectors
  },
  IESReturnAuthorities: {
    actions: { ...IESReturnAuthoritiesActions },
    selectors: IESReturnAuthoritiesSelectors
  },
  IESQuotes: {
    actions: { ...IESQuotesActions },
    selectors: IESQuotesSelectors
  },
  IESSalesOrders: {
    actions: { ...IESSalesOrdersActions },
    selectors: IESSalesOrdersSelectors
  },
  IESServiceOrders: {
    actions: { ...IESServiceOrdersActions },
    selectors: IESServiceOrdersSelectors
  },
  IESLostSales: {
    actions: { ...IESLostSalesActions },
    selectors: IESLostSalesSelectors
  },
  Schedule: {
    actions: { ...IEScheduleActions },
    selectors: IEScheduleSelectors
  },
  Prices: {
    actions: { ...IEPricesActions },
    selectors: IEPricesSelectors
  },
  PriceMatrix: {
    actions: { ...IEPriceMatrixActions },
    selectors: IEPriceMatrixSelectors
  },
  IEForeignPrices: {
    actions: { ...IEForeignPricesActions },
    selectors: IEForeignPricesSelectors
  },
  IEASCSuppressions: {
    actions: { ...IESuppressionsActions },
    selectors: IESuppressionsSelectors
  },
  IEASCBOM: {
    actions: { ...IEASCBOMActions },
    selectors: IEASCBOMSelectors
  },
  IEASCAlternates: {
    actions: { ...IEASCAlternatesActions },
    selectors: IEASCAlternatesSelectors
  },
  InventoryEnquiryDocuments: {
    actions: { ...InventoryEnquiryDocumentsActions },
    selectors: InventoryEnquiryDocumentsSelectors
  },
  InventoryEnquiryLinks: {
    actions: { ...InventoryEnquiryLinksActions },
    selectors: InventoryEnquiryLinksSelectors
  },

  //Sales Order Enquiry
  SaleDeposits: {
    actions: { ...SOESaleDepositsActions },
    selectors: SOESaleDepositsSelectors
  },
  LDReservationSerials: {
    actions: { ...SOEReservationSerialsActions },
    selectors: SOEReservationSerialsSelectors
  },
  LDReservationLots: {
    actions: { ...SOEReservationLotsActions },
    selectors: SOEReservationLotsSelectors
  },
  OrderLines: {
    actions: { ...SOEOrderLineActions },
    selectors: SOEOrderLineSelectors
  },
  LineInvoices: {
    actions: { ...SOELineInvoicesActions },
    selectors: SOELineInvoicesSelectors
  },
  LDCommittedPurchaseOrder: {
    actions: { ...SEOLDPurchaseActions },
    selectors: SEOLDPurchaseSelectors
  },
  LDCommittedProductionOrder: {
    actions: { ...SOELDProductionOrderActions },
    selectors: SOELDProductionOrderSelectors
  },
  LDCommittedInPutAway: {
    actions: { ...SOELDPutAwayActions },
    selectors: SOELDPutAwaySelectors
  },
  DepositDetails: {
    actions: { ...SOEDepositActions },
    selectors: SOEDepositSelectors
  },
  Deliveries: {
    actions: { ...SOEDeliveriesActions },
    selectors: SOEDeliveriesSelectors
  },
  CommittedPurchaseOrder: {
    actions: { ...SOECommittedPurchaseOrderActions },
    selectors: SOECommittedPurchaseOrderSelectors
  },
  CommittedProductionOrder: {
    actions: { ...SOECommittedProductionOrderActions },
    selectors: SOECommittedProductionOrderSelectors
  },
  CommittedInPutAway: {
    actions: { ...SOECommittedInPutAwayActions },
    selectors: SOECommittedInPutAwaySelectors
  },
  CDReservationSerials: {
    actions: { ...SOECDReservationSerialsActions },
    selectors: SOECDReservationSerialsSelectors
  },
  CDReservationLots: {
    actions: { ...SOECDReservationLotsActions },
    selectors: SOECDReservationLotsSelectors
  },
  CDCommittedPurchaseOrder: {
    actions: { ...SEOCDPurchaseActions },
    selectors: SEOCDPurchaseSelectors
  },
  CDCommittedProductionOrder: {
    actions: { ...SOECDProductionOrderActions },
    selectors: SOECDProductionOrderSelectors
  },
  CDCommittedInPutAway: {
    actions: { ...SOECDPutAwayActions },
    selectors: SOECDPutAwaySelectors
  },
  SOEBOM: {
    actions: { ...SOEBOMActions },
    selectors: SOEBOMSelectors
  },

  //Purchase Order Enquiry
  POEProductReceipts: {
    actions: { ...POEProductReceiptsActions },
    selectors: POEProductReceiptsSelectors
  },
  POEInTransitLocalShipments: {
    actions: { ...POEInTransitLocalShipmentsActions },
    selectors: POEInTransitLocalShipmentsSelectors
  },
  POEInTransitImportShipments: {
    actions: { ...POEInTransitImportShipmentsActions },
    selectors: POEInTransitImportShipmentsSelectors
  },
  POELDInTransitLocalShipments: {
    actions: { ...POELDInTransitLocalShipmentsActions },
    selectors: POELDInTransitLocalShipmentsSelectors
  },
  POELDInTransitImportShipments: {
    actions: { ...POELDInTransitImportShipmentsActions },
    selectors: POELDInTransitImportShipmentsSelectors
  },
  POEOrderLines: {
    actions: { ...POEOrderLineActions },
    selectors: POEOrderLineSelectors
  },
  POEBranchOrders: {
    actions: { ...POEBranchOrdersActions },
    selectors: POEBranchOrdersSelectors
  },
  POELDBranchOrders: {
    actions: { ...POELDBranchOrdersActions },
    selectors: POELDBranchOrdersSelectors
  },
  POECommittedSaleOrder: {
    actions: { ...POECommittedSaleOrderActions },
    selectors: POECommittedSaleOrderSelectors
  },
  POECommittedProductionOrder: {
    actions: { ...POECommittedProductionOrderActions },
    selectors: POECommittedProductionOrderSelectors
  },
  POECommittedServiceOrders: {
    actions: { ...POECommittedServiceOrdersActions },
    selectors: POECommittedServiceOrdersSelectors
  },
  POELDCommittedSaleOrder: {
    actions: { ...POELDCommittedSaleOrderActions },
    selectors: POELDCommittedSaleOrderSelectors
  },
  POELDCommittedProductionOrder: {
    actions: { ...POELDCommittedProductionOrderActions },
    selectors: POELDCommittedProductionOrderSelectors
  },
  POELDCommittedServiceOrders: {
    actions: { ...POELDCommittedServiceOrdersActions },
    selectors: POELDCommittedServiceOrdersSelectors
  },

  //Customer Enquiry
  CustomerFinancialChildAccounts: {
    actions: { ...CEChildAccountActions },
    selectors: CEChildAccountSelectors
  },
  CEDetailsTransactions: {
    actions: { ...CEDetailsTransactionsActions },
    selectors: CEDetailsTransactionsSelectors
  },
  CustomerEnquiryMovements: {
    actions: { ...CEMovementsActions },
    selectors: CEMovementsSelectors
  },
  CEServiceOrders: {
    actions: { ...CEServiceOrdersActions },
    selectors: CEServiceOrdersSelectors
  },
  CEEnquiries: {
    actions: { ...CEEnquiriesActions },
    selectors: CEEnquiriesSelectors
  },
  CEUserFields: {
    actions: { ...CEUserFieldsActions },
    selectors: CEUserFieldsSelectors
  },
  CETransactions: {
    actions: { ...CETransactionsActions },
    selectors: CETransactionsSelectors
  },
  SEPLocalShipments: {
    actions: { ...SEPLocalShipmentsActions },
    selectors: SEPLocalShipmentsSelectors
  },
  Invoices: {
    actions: { ...CEInvoicesActions },
    selectors: CEInvoicesSelectors
  },
  Backorders: {
    actions: { ...CEBackordersActions },
    selectors: CEBackordersSelectors
  },
  SalesOrders: {
    actions: { ...CESalesOrdersActions },
    selectors: CESalesOrdersSelectors
  },
  Quotes: {
    actions: { ...CEQuotesActions },
    selectors: CEQuotesSelectors
  },
  Pricing: {
    actions: { ...CEPricingActions },
    selectors: CEPricingSelectors
  },

  //Customer Quotes Enquiry
  BillOfMaterials: {
    actions: { ...CQEBOMActions },
    selectors: CQEBOMSelectors
  },
  BOMReservationLots: {
    actions: { ...BOMReservationLotActions },
    selectors: BOMReservationLotSelectors
  },
  BOMReservationSerials: {
    actions: { ...BOMReservationSerialActions },
    selectors: BOMReservationSerialSelectors
  },
  QuoteLines: {
    actions: { ...CQEQuoteLinesActions },
    selectors: CQEQuoteLinesSelectors
  },
  ReservationLots: {
    actions: { ...CQEReservationLotActions },
    selectors: CQEReservationLotSelectors
  },
  ReservationSerials: {
    actions: { ...CQEReservationSerialActions },
    selectors: CQEReservationSerialSelectors
  },
  // Supplier Enquiry
  SupplierEnquiryUserFields: {
    actions: { ...SupplierEnquiryUserFieldsActions },
    selectors: SupplierEnquiryUserFieldsSelectors
  },
  SupplierEnquiryTransactions: {
    actions: { ...SupplierEnquiryTransactionsActions },
    selectors: SupplierEnquiryTransactionsSelectors
  },
  SupplierEnquiryDetailsTransactions: {
    actions: { ...SupplierEnquiryDetailsTransactionsActions },
    selectors: SupplierEnquiryDetailsTransactionsSelectors
  },
  SupplierInvoices: {
    actions: { ...SupplierEnquiryInvoiceActions },
    selectors: SupplierEnquiryInvoiceSelectors
  },
  CashReceiptingInvoices: {
    actions: { ...CashReceiptingInvoiceActions },
    selectors: CashReceiptingInvoicesSelectors
  },
  CashReceiptingOrders: {
    actions: { ...CashReceiptingOrdersActions },
    selectors: CashReceiptingOrdersSelectors
  },
  PaymentMethod: {
    actions: { ...PaymentMethodActions },
    selectors: PaymentMethodSelectors
  },
  SupplierGRNS: {
    actions: { ...SupplierEnquiryGRNActions },
    selectors: SupplierEnquiryGRNSelectors
  },
  SupplierMovements: {
    actions: { ...SupplierEnquiryMovementsActions },
    selectors: SupplierEnquiryMovementsSelectors
  },
  SupplierInvoiceEnquiryInvoiceLines: {
    actions: { ...SupplierInvoiceEnquiryInvoiceLinesActions },
    selectors: SupplierInvoiceEnquiryInvoiceLinesSelectors
  },
  SupplierInvoiceEnquiryInvoiceLinesLotDetails: {
    actions: { ...SupplierInvoiceEnquiryInvoiceLinesLotDetailsActions },
    selectors: SupplierInvoiceEnquiryInvoiceLinesLotDetailsSelectors
  },
  SupplierInvoiceEnquiryInvoiceLinesSerialNumbers: {
    actions: { ...SupplierInvoiceEnquiryInvoiceLinesSerialNumbersActions },
    selectors: SupplierInvoiceEnquiryInvoiceLinesSerialNumbersSelectors
  },
  SupplierInvoiceEnquiryInvoiceLinesMovements: {
    actions: { ...SupplierInvoiceEnquiryInvoiceLinesMovementsActions },
    selectors: SupplierInvoiceEnquiryInvoiceLinesMovementsSelectors
  }
  ,
  SupplierFreight: {
    actions: { ...SupplierEnquiryFreightActions },
    selectors: SupplierEnquiryFreightSelectors
  },
  SupplierRFC: {
    actions: { ...SupplierEnquiryRFCActions },
    selectors: SupplierEnquiryRFCSelectors
  },
  SupplierChildAccounts: {
    actions: { ...SupplierEnquiryFinancialChildAccountsActions },
    selectors: SupplierEnquiryFinancialChildAccountsSelectors
  },
  SupplierPurchaseOrder: {
    actions: { ...SupplierEnquiryPurchasingPurchaseOrdersActions },
    selectors: SupplierEnquiryPurchasingPurchaseOrdersSelectors
  },
  RFCEnquiryRFCLines: {
    actions: { ...RFCEnquiryRFCLinesActions },
    selectors: RFCEnquiryRFCLinesSelectors
  },
  SupplierSettingsByWarehouse: {
    actions: { ...SupplierEnquirySettingsByWarehouseActions },
    selectors: SupplierEnquirySettingsByWarehouseSelectors
  },
  SupplierSettingsAttributes: {
    actions: { ...SupplierEnquirySettingsAttributesActions },
    selectors: SupplierEnquirySettingsAttributesSelectors
  },
  SupplierPurchasingProducts: {
    actions: { ...SupplierEnquiryPurchasingProductssActions },
    selectors: SupplierEnquiryPurchasingProductsSelectors
  },
  SupplierEnquiryDocuments: {
    actions: { ...SupplierEnquiryDocumentsActions },
    selectors: SupplierEnquiryDocumentsSelectors
  },
  SupplierEnquiryLinks: {
    actions: { ...SupplierEnquiryLinksActions },
    selectors: SupplierEnquiryLinksSelectors
  },

  //PME Bindings

  PMETransactions: {
    actions: { ...pmeTransactionsActions },
    selectors: pmeTransactionsSelectors
  },
  PMEBinLocations: {
    actions: { ...pmeBinLocationsActions },
    selectors: pmeBinLocationsSelectors
  },
  PMELotDetails: {
    actions: { ...pmeLotDetailsActions },
    selectors: pmeLotDetailsSelectors
  },
  PSESlipLines: {
    actions: { ...PSESlipLinesActions },
    selectors: PSESlipLinesSelectors
  },
  PSELots: {
    actions: { ...PSELotsActions },
    selectors: PSELotsSelectors
  },
  PSESerials: {
    actions: { ...PSESerialsActions },
    selectors: PSESerialsSelectors
  },
  PSEBOM: {
    actions: { ...PSEBOMActions },
    selectors: PSEBOMSelectors
  },
  PSEBOMLots: {
    actions: { ...PSEBOMLotsActions },
    selectors: PSEBOMLotsSelectors
  },
  PSEBOMSerials: {
    actions: { ...PSEBOMSerialsActions },
    selectors: PSEBOMSerialsSelectors
  },
  PSSlipLines: {
    actions: { ...PSSlipLinesActions },
    selectors: PSSlipLinesSelectors
  },
  PSLots: {
    actions: { ...PSLotsActions },
    selectors: PSLotsSelectors
  },
  PSSerials: {
    actions: { ...PSSerialsActions },
    selectors: PSSerialsSelectors
  },
  PSBOM: {
    actions: { ...PSBOMActions },
    selectors: PSBOMSelectors
  },
  PSBOMLots: {
    actions: { ...PSBOMLotsActions },
    selectors: PSBOMLotsSelectors
  },
  PSBOMSerials: {
    actions: { ...PSBOMSerialsActions },
    selectors: PSBOMSerialsSelectors
  },
  PSBinLocations: {
    actions: { ...BinLocationsActions },
    selectors: BinLocationsSelectors
  },
  PSKitComponents: {
    actions: { ...KitComponentsActions },
    selectors: KitComponentsSelectors
  },

  //supplier product enquiry
  SupplierProductEnquiryDocuments: {
    actions: { ...SupplierProductEnquiryDocuemtnsActions },
    selectors: SupplierProductEnquiryDocuemtnsSelectors
  },
  SupplierProductEnquiryLinks: {
    actions: { ...SupplierProductEnquiryLinksActions },
    selectors: SupplierProductEnquiryLinksSelectors
  },
  SPEProductPriceSchedule: {
    actions: { ...SPEProductPriceScheduleActions },
    selectors: SPEProductPriceScheduleSelectors
  },
  SPEProductLinks: {
    actions: { ...SPEProductLinksActions },
    selectors: SPEProductLinksSelectors
  },

  //Security Management
  SecurityPolicyRules: {
    actions: { ...securityPolicyActions },
    selectors: securityPolicySelectors
  },
  SecurityPolicyUserGroups: {
    actions: { ...securityPolicyUserGroupsActions },
    selectors: securityPolicyUserGroupsSelectors
  },

  //CreditNotes
  CNLines: {
    actions: { ...creditNoteLinesActions },
    selectors: creditNoteLinesSelectors
  },

};

export const Actions = (module: string): ActionCreatorsMapObject => {
  const result = containers[module];
  if (result) {
    return result.actions;
  } else {
    return {};
  }
};

export const Selectors = (module: string, state: IApplicationState): Partial<IDataGridProps> => {
  const result = containers[module];
  if (result) {
    return result.selectors(state);
  } else {
    return {};
  }
};
