import { IDespatchGridLineCriteriaFacade } from 'api/swaggerTypes';

export enum PriceOverrideScreenType {
  UserAuthAndReasonScreen = 'UserAuthAndReasonScreen',
  ReasonScreen = 'ReasonScreen',
  UserAuthScreen = 'UserAuthScreen'
}

export enum PriceOverrideScreenTitle {
  UserAuthAndReasonScreen = 'Price Override Authorisation',
  ReasonScreen = 'Price Override Reason',
  UserAuthScreen = 'Price Override Authorisation'
}

export type IPriceOverrideData = Omit<IDespatchGridLineCriteriaFacade['PriceOverride'], | 'HyperlinkReference'>;
export interface IPriceOverrideAuthorityDialogProperties<IApiType = any> {
  open: boolean;
  loading?: boolean;
  type?: PriceOverrideScreenType;
  apiType?: IApiType;
  data?: IPriceOverrideData;
  values?: IPriceOverrideData;
  resetForm?(): void;
  onOk(data: IPriceOverrideData, apiType?: IApiType): void;
  onCancel(): void;
}
