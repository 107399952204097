import { IFormViewForm } from 'components/FormView';

const Financial: IFormViewForm = {
  id: 'SupplierFinancialSettings',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit limit',
            name: 'CreditLimit',
            size: 'medium',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            size: 'medium',
            type: 'string'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Trading terms (days)',
            name: 'TradingTerms',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 4,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Terms from end of month',
            name: 'EndOfMonthTerms',
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Discount terms (days)',
            name: 'DiscountTerms',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 6,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Discount',
            name: 'Discount',
            size: 'small',
            type: 'number',
            decimalSeparator: true,
            adornment: '%',
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Pay by',
            name: 'PayBy',
            size: 'medium',
            type: 'string',
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Business number',
            name: 'BussinessNumber',
            size: 'medium',
            type: 'string',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Company number',
            name: 'CompanyNumber',
            size: 'medium',
            type: 'string',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Charge to supplier',
            name: 'ChargeToSupplier',
            size: 'small',
            api: '/supplierEnquiry/lookup/action',
            params: {
              queryFields: {
                Supplier: {
                  Field: 'ChargeToSupplier',
                  Property: 'Value'
                }
              }
            }
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Default chart account',
            name: 'DefaultChartAccount',
            size: 'medium',
            type: 'string',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Customer',
            name: 'Customer',
            size: 'small',
            api: '/supplierEnquiryToCustomer/lookup/action',
            params: {
              queryFields: {
                CustomerId: {
                  Field: 'Customer',
                  Property: 'Value'
                },
                CustomerAccountNumber: {
                  Field: 'Customer',
                  Property: 'Display'
                }
              }
            }
          }
        },
        {
          id: 5,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'RCTI Authorised',
            name: 'RCTIAuthorised',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Bank',
            name: 'Bank',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Branch',
            name: 'Branch',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'BSB',
            name: 'BSB',
            size: 'small'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Account number',
            name: 'AccountNumber',
            size: 'small'
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Payee',
            name: 'Payee',
            size: 'large'
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Account name',
            name: 'AccountName',
            size: 'large'
          }
        }
      ]
    }
  ]
};

export default Financial;
