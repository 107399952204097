const NotepadForm = {
    id: 'Notepad',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            props: {
                name: 'notepad',
                fullWidth: true
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3
            },
            children: [
                {
                    id: '1',
                    type: 'NOTEPAD',
                    props: {
                        formName: 'Notepad',
                        fetchApi: '/PickSlipTransaction/Despatch/<DespatchId>/Note',
                        updateApi: '/PickSlipTransaction/Despatch/<DespatchId>/Note',
                        label: 'Notepad',
                        name: 'noteData',
                        fullWidth: true,
                        style: {
                            height: '650px'
                        }
                    }
                }
            ]
        }
    ]
};
export default NotepadForm;
