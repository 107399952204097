import React from 'react';
import TextArea from '@markinson/uicomponents-v2/TextArea';
import { createStyles, withStyles } from '@material-ui/core/styles';
import SelectContainer from 'components/SelectContainer/SelectContainer';
import { IServiceJobActivitySummaryFacade } from 'api/swaggerTypes';
import lodash from 'lodash';
import ImageModal from 'components/common/ImageGrid/ImageModal';
import { fetchFile } from 'api/utils';

const classesStyles = createStyles({
    detailViewContainer: {
        padding: 20
    },
    notesImagesContainer: {
        display: 'grid',
        gridTemplateColumns: '49% 49%',
        gridColumnGap: '2%'
    },
    imageContainer: {
        overflowY: 'auto',
        cursor: 'pointer'
    },
    title: {
        color: '#777777',
        display: 'block',
        padding: '16px 0 8px 0',
        fontSize: 12,
        fontFamily: 'Roboto, sans - serif',
        fontWeight: 400,
    },
    image: {
        margin: '0px 10px',
        width: '50px',
        height: '50px'
    }
});

interface ISummaryPanelDetailViewRendererProps {
    classes: any;
    data: any;
}

const Image = (props) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);

    const handleClick = (): void => {
        if (props.ImageId) {
            setOpen(!open);
            setLoading(true);
            fetchFile(`/Image/${props.ImageId}`).then((result) => {
                setImageData(window.URL.createObjectURL(result));
            }).catch((err) => console.warn(err))
                .finally(() => setLoading(false));
        }
    };

    return (<React.Fragment>
        <img alt='image' style={classesStyles.image} src={props.data} onClick={handleClick} />
        <ImageModal
            open={open}
            image={imageData || (props.data && props.data.Image && props.data.Image.Thumbnail)}
            loading={loading}
            onClose={() => setOpen(!open)}
            data={props.data}
        />
    </React.Fragment>
    );
};

const Images = (props) => {

    const { images = [] } = props;

    return (
        <div style={classesStyles.imageContainer}>
            <div style={classesStyles.title}>Images</div>
            {images && images.map((img, key) => <Image key={key} {...img} />)}
        </div>
    );
};

function SummaryPanelDetailViewRenderer(props: ISummaryPanelDetailViewRendererProps): JSX.Element {
    const { classes, data = {} } = props;
    const [selectedActivity, setSelectedActivity] = React.useState<IServiceJobActivitySummaryFacade>({});
    const activities = data && data.data && data.data.Activities;
    const activityCodeOptions = activities.map((act) => ({
        value: act.ActivityId,
        label: act.ActivityCode
    }));

    React.useEffect(
        () => {
            const firstActivity = lodash.first(activities);
            setSelectedActivity(firstActivity);
        },
        [activities]);

    return (
        <div className={classes.detailViewContainer}>
            <SelectContainer
                label={'Activity'}
                width={'100%'}
                options={activityCodeOptions}
                value={selectedActivity.ActivityId}
                onChange={(value: number) => {
                    const activity = activities.find((act) => act.ActivityId === value);
                    setSelectedActivity(activity);
                }}
            />
            <div className={classes.notesImagesContainer}>
                <TextArea
                    name={'Labour'}
                    label={`Labour - $${selectedActivity.LabourCost}`}
                    size={'medium'}
                    style={{ height: 150 }}
                    readOnly={true}
                    value={selectedActivity.LabourDetail}
                    autoComplete={'off'} />
                <TextArea
                    name={'Materials'}
                    label={`Materials - $${selectedActivity.MaterialCost}`}
                    style={{ height: 150 }}
                    readOnly={true}
                    size={'medium'}
                    value={selectedActivity.MaterialDetail}
                    autoComplete={'off'} />
            </div>
            <div className={classes.notesImagesContainer}>
                <TextArea
                    name={'Notes'}
                    style={{ height: 150 }}
                    label={'Notes'}
                    size={'medium'}
                    readOnly={true}
                    value={selectedActivity.JournalNotes}
                    className={classes.notes}
                    autoComplete={'off'} />
                <Images images={selectedActivity.Images} />
            </div>
        </div>
    );
}

export default withStyles(classesStyles, { index: 1 })(SummaryPanelDetailViewRenderer);
