import { IFormViewForm } from 'components/FormView';

const InvoiceLineDetails: IFormViewForm = {
  id: 'LineDetails',
  layout: {
    rows: 2,
    columns: 2
  },
  fields: [
    {
      id: 0,
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: '',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Buy quantity',
            name: 'BuyQuanity',
            size: 'medium'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Buy unit',
            name: 'BuyUnit',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Stock quantity',
            name: 'StockQuantityDisplay',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Stock unit',
            name: 'StockUnit',
            size: 'medium'
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Conversion factor',
            name: 'ConversionFactor',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Base',
            name: 'BaseDisplay',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Discount',
            name: 'DiscountDisplay',
            size: 'medium'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Price',
            name: 'PriceDisplay',
            size: 'medium'
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax total',
            name: 'TaxTotalDisplay',
            size: 'medium'
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Extended',
            name: 'ExtendedDisplay',
            size: 'medium'
          }
        }
      ]
    },
    {
      id: 2,
      position: {
        row: 2,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Duty code',
            name: 'DutyCode',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tariff',
            name: 'Tariff',
            size: 'large'
          }
        },
      ]
    },
    {
      id: 3,
      position: {
        row: 2,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          props: {
            name: 'Comment',
            label: 'Invoice line comment',
            fullWidth: true,
            style: {
              height: '250px'
            }
          }
        }
      ]
    }
  ]
};
export default InvoiceLineDetails;
