import { combineReducers } from 'redux';
import rfcDetails, { IRFCDetailsState } from './rfcDetails';
import rfc, { IRFCState } from './rfc';
import RFCLines, { IInvoiceLinesState } from './RFCLines';

export interface IRFCEnquiryState {
  rfcDetails: IRFCDetailsState;
  rfc: IRFCState;
  RFCLines: IInvoiceLinesState;
}
const combined = combineReducers({
  rfcDetails,
  rfc,
  RFCLines
});

export default combined;
