import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  clear: () => null,
  asyncs: {
    fetch: Data => Data,
    fetchSummary: Data => Data,
  }
}, 'productDetails');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    disableExcelExport: true
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: '', field: 'Location', suppressMenu: true, suppressSorting: true, suppressMovable: true, minWidth: 150 },
    { headerName: 'Available', field: 'AvailableQuantityDisplay', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true },
    { headerName: 'Allocated', field: 'AllocatedQuantityDisplay', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true },
    { headerName: 'Inbound', field: 'InboundQuantityDisplay', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true },
    { headerName: 'Outbound', field: 'OutboundQuantityDisplay', type: 'numericColumn', suppressMenu: true, suppressSorting: true, suppressMovable: true }
  ],
  Data: [],
  productDetails: {},
  productDetailsSchema: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          productDetails: action.payload.inlineObject,
          productDetailsSchema: action.payload.schema,
          Data: (action.payload && action.payload.inlineObject) ? action.payload.inlineObject.Quantities : null
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action)
    case types.fetchSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          productDetailsSummary: linesData
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    case types.reset:
      return initialState;
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.productDetails,
  {
    // Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,

  }
)

const syncSelector = {
  Data: state => state.inventoryEnquiry.productDetails.data.Data,
  isLoading: state => state.inventoryEnquiry.productDetails.loading,
  gridOptions: state => state.inventoryEnquiry.productDetails.data.gridOptions,
  Columns: state => state.inventoryEnquiry.productDetails.data.Columns,
  Actions: state => state.inventoryEnquiry.productDetails.data.Actions,
  productDetails: state => state.inventoryEnquiry.productDetails.data.productDetails,
  productDetailsSchema: state => state.inventoryEnquiry.productDetails.data.productDetailsSchema,
  productDetailsSummary: state => state.inventoryEnquiry.productDetails.data.productDetailsSummary,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)