import React from 'react';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Editing, Button } from 'devextreme-react/data-grid';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import { IFilterGridProps } from './FilterGrid.properties';
import { withStyles } from '@material-ui/core';
import styles from './FilterGrid.styles';
import { ACTION_COLUMN_WIDTH, DRAG_COLUMN_WIDTH, FIELD_COLUMN_WIDTH } from '../Fields.constants';
import { useDeleteEMFilter, useRetrieveEMFilters, useSetEMFiltersData } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { useEMContextSelector } from 'components/ExtractMaintenance/ExtractMaintenance.context';

const FilterGrid = (props: IFilterGridProps) => {
    const { classes, handleUpdateFilterDetails } = props;
    const params = new URLSearchParams(location.search);
    const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);
    const { data: fetchFiltersData } = useRetrieveEMFilters(templateId);
    const { mutateAsync: deleteFilterMutate } = useDeleteEMFilter();
    const setEMFiltersData = useSetEMFiltersData();
    const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);

    const onDelete = React.useCallback(
        async (e: any): Promise<void> => {
            if (e?.row?.key) {
                const data = e?.row?.data;
                const response = await deleteFilterMutate({
                    TemplateId: templateId,
                    ExtractId: data.ExtractId,
                    FilterId: data.FilterId,
                    urlQueryParams: {
                        FilterField: data.FilterField
                    }
                });
                setEMFiltersData(templateId, response);
            }
        },
        [templateId]
    );

    const onDetailClick = React.useCallback(
        async (e: any): Promise<void> => {
            if (handleUpdateFilterDetails) {
                handleUpdateFilterDetails(e?.data?.data);
            }
        },
        [handleUpdateFilterDetails]
    );

    return (
        <DataGrid
            className={classes.FilterGrid}
            dataSource={fetchFiltersData?.ExtractFilters}
            showBorders={true}
            renderAsync={true}
            remoteOperations={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            hoverStateEnabled={true}
            noDataText=''
            columnAutoWidth={true}
            keyExpr={'FilterId'}
            disabled={!inEditMode}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={true}
            />
            <Column
                allowEditing={false}
                width={DRAG_COLUMN_WIDTH}
                allowResizing={false}
                fixed={true}
                alignment='center'
                allowHeaderFiltering={false}
                cellComponent={(allProps) => {
                    return <MoreIcon className={classes.lineDetailIcon} onClick={() => inEditMode ? onDetailClick(allProps) : null} />;
                }}
            />
            <Column
                dataField={'FilterFieldDisplay'}
                caption={'Filter by'}
                width={FIELD_COLUMN_WIDTH}
                allowEditing={false}
                fixed={false}
            >
            </Column>
            <Column
                dataField={'Condition'}
                caption={'Condition'}
                minWidth={FIELD_COLUMN_WIDTH}
                allowEditing={false}
            >
            </Column>
            <Column type={'buttons'} width={ACTION_COLUMN_WIDTH} allowResizing={false} fixed={true}>
                <Button name={'delete'} icon={'trash'} onClick={onDelete} />
            </Column>
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        </DataGrid>);
};

export default withStyles(styles, { index: 1 })(FilterGrid);
