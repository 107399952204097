import * as React from 'react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OptionsMenuIcon from '@markinson/uicomponents-v2/SvgIcons/OptionsMenu';
import Tooltip from '@material-ui/core/Tooltip';
import * as styles from './BreadcrumbBar.scss';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { isNull } from 'utils/utils';

const classStyles = createStyles({
  title: {
    lineHeight: '40px',
    height: '40px',
    flex: 1
  },
  iconStyleRight: {
    margin: 0
  },
  toolbar: {
    minHeight: 40,
    paddingLeft: 0,
    paddingRight: 0
  }
});

interface IModule {
  id: string;
  label: string;
  parent: string;
}

export interface IBreadcrumbBarProperties extends StyledComponentProps {
  scope: string;
  moduleTree: IModule[];
  mainModule: string;
  activeModule: string;
  selectedTab?: string;
  onClick(event: React.MouseEvent): void;
  changeSelectedForm(scope: string, tab: string): void;
  changeSelectedTab(tab: string): void;
  toggleCustomerLookUp(open: boolean): void;
}

class BreadcrumbBar extends React.PureComponent<IBreadcrumbBarProperties> {

  list: any[] = [];

  searchModule = (activeModule: string, obj: IModule[]) => {
    const index = obj.findIndex((x) => x.id === activeModule);
    if (index > -1) {
      if (obj[index].parent !== '') {
        this.searchModule(obj[index].parent, obj);
      }
      this.list.push(obj[index]);
    }
  }

  onBreadCrumbClick = (toTab: string) => () => {
    this.changeSelectedTab(toTab);
  }

  onMainModuleClick = () => {
    if (!isNull(this.props.moduleTree)) {
      this.changeSelectedTab(this.props.moduleTree[0].id);
    }
    this.props.changeSelectedForm(this.props.scope, this.props.selectedTab);
    this.props.toggleCustomerLookUp(true);
  }

  changeSelectedTab = (tab: string) => {
    this.props.changeSelectedTab(tab);
  }

  render(): React.ReactNode {
    const { onClick, mainModule, activeModule, classes, moduleTree } = this.props;
    this.list = [];
    this.searchModule(activeModule, moduleTree);
    const filteredList = this.list && this.list.filter((item) => item.label);

    const trailBreadcrumbClasses = classNames(
      styles.trailBreadcrumb,
      styles.cursorPointer
    );

    const Title = () => (
      <span className={styles.breadcrumbText}>
        <span
          className={filteredList.length > 0 ? trailBreadcrumbClasses : styles.currentBreadcrumb}
          onClick={this.onMainModuleClick}
        >
          {mainModule}
        </span>
        {filteredList.map((item, loc) => (
          item.label ? <span
            key={loc}
            className={loc < filteredList.length - 1 ? trailBreadcrumbClasses : styles.currentBreadcrumb}
            onClick={this.onBreadCrumbClick(item.id)}
          >
            <i
              className={'material-icons'}
              style={{ verticalAlign: 'middle', color: '#919191' }}
            >
              &#xE409;
            </i>
            {item.label}
          </span> : null
        )
        )}

      </span>
    );

    return (
      <div>
        <AppBar
          className={styles.breadcrumbBar}
          position='static'
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.title}><Title /></div>
            <Tooltip title='Display Options Menu'>
              <div className={styles.iconOptionsMenu} onClick={onClick}>
                <OptionsMenuIcon />
              </div>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(classStyles, { index: 1 })(BreadcrumbBar);
