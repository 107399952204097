import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Diary.styles';
import IDiaryProperties from './Diary.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import DiaryGrid from './DiaryGrid';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';
import { AddCircle as AddIcon } from '@markinson/uicomponents-v2/SvgIcons/';

const Diary = (props: IDiaryProperties) => {

    const {
        classes,
        DespatchId,
        changeSelectedTab,
    } = props;

    const { setActionBar } = React.useContext(ActionBarContext);

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [BACK_BUTTON],
                centerIcons: [],
                rightIcons: [
                    {
                        label: 'New',
                        Icon: AddIcon,
                        disabled: !Number(DespatchId) ,
                        action: 'AddDiary',
                    }]
            });

        },
        [DespatchId]
    );

    return (
        <div className={classes.container}>
            <DiaryGrid
                navigateBack={props.navigateBack}
                showDetailOnClick={(options) => {
                    const locationObj = {
                        search: {
                            DiaryId: options.DiaryId,
                            DespatchId: DespatchId
                       }
                    };
                    changeSelectedTab('SalesDiaryDetails', locationObj);
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(Diary);
