
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { actions as activitySchedulerActions, selectors as activitySchedulerSelectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import { ISchedulerProps } from '../interfaces';
import Scheduler from './Scheduler';
import { bindActionCreators, Dispatch } from 'redux';
import dndSources from '../../common/LookUpList/DragAndDropSources';
import { selectors as lookupSelectors } from 'ducks/lookup';

type IStateProps = Pick<ISchedulerProps,
  'operationMode' | 'selectedTab' | 'isBrowseLookUpOpen' | 'resourcesList' | 'selectedId' | 'dndSources' |
  'getLookupData' | 'newEventData' | 'moveEventData' | 'schedulerData' | 'salesEntity' |
  'openScheduleActivities' | 'openMoveActivities' | 'schedulerDataTriggerString' |
  'fetchSchedulerActivitiesLoading' | 'isSchedulerLoading' | 'fetchResourcesLoading'
>;
const mapStateToProps = (state: IApplicationState): IStateProps => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  resourcesList: activitySchedulerSelectors.resourcesList(state),
  selectedId: activitySchedulerSelectors.selectedId(state),
  schedulerData: activitySchedulerSelectors.schedulerData(state),
  schedulerDataTriggerString: activitySchedulerSelectors.schedulerDataTriggerString(state), // to tell component, schedulerData is modified.
  salesEntity: activitySchedulerSelectors.salesEntity(state),
  dndSources: dndSources,
  getLookupData: lookupSelectors.getData(state),
  newEventData: activitySchedulerSelectors.newEventData(state),
  moveEventData: activitySchedulerSelectors.moveEventData(state),
  openScheduleActivities: activitySchedulerSelectors.openScheduleActivities(state),
  openMoveActivities: activitySchedulerSelectors.openMoveActivities(state),
  fetchSchedulerActivitiesLoading: activitySchedulerSelectors.fetchSchedulerActivitiesLoading(state),
  fetchResourcesLoading: activitySchedulerSelectors.fetchResourcesLoading(state),
  isSchedulerLoading: activitySchedulerSelectors.fetchSchedulerActivitiesLoading(state)
    || activitySchedulerSelectors.fetchResourcesLoading(state)
    || activitySchedulerSelectors.isLoading(state)
    || activitySchedulerSelectors.fetchJobsToReviewLoading(state)
    || activitySchedulerSelectors.deleteActivityScheduleLoading(state)
    || activitySchedulerSelectors.reAddDeletedJobToListLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeOperationMode: operationActions.changeOperationMode,
    newSchedulerEvent: activitySchedulerActions.newSchedulerEvent,
    moveSchedulerEvent: activitySchedulerActions.moveSchedulerEvent,
    getSchedulerResources: activitySchedulerActions.getResources,
    getSchedulerActivities: activitySchedulerActions.getScheduledActivities,
    newSchedulerEventConfirm: activitySchedulerActions.newSchedulerEventConfirm,
    moveSchedulerEventConfirm: activitySchedulerActions.moveSchedulerEventConfirm,
    updateRoster: activitySchedulerActions.updateRoster,
    scheduleActivityModalToggle: activitySchedulerActions.scheduleActivityModalToggle,
    scheduleActivityOptionsModalToggle: activitySchedulerActions.scheduleActivityOptionsModalToggle,
    moveActivityModalToggle: activitySchedulerActions.moveActivityModalToggle,
    setSelectedActivitySchedule: activitySchedulerActions.setSelectedActivitySchedule,
    triggerSchedulerDataChange: activitySchedulerActions.triggerSchedulerDataChange,
    setSchedulerData: activitySchedulerActions.setSchedulerData,
    getSelectedJobDetails: activitySchedulerActions.getSelectedJobDetails
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
