import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        'height': 'Calc(100% - 210px)',
        'width': '100%',
        'display': 'flex',
        'marginTop': 10,
        'gap': '10px',
        '@media screen and (max-width: 1100px)': {
            flexDirection: 'column',
        }
    },
    entriesView: {
        'maxHeight': 'unset',
        'width': '100%',
        '@media screen and (max-width: 1100px)': {
            width: 'unset',
            maxHeight: 'unset'
        }
    },
    entriesImageHidden: {
        maxWidth: '1180px',
    },
    paperContainer: {
        'background': 'white',
        'height': '100%',
        'padding': 0,
        '& .dx-widget': {
            fontFamily: 'monospace',
        },
    },
    paperContainerWrapper: {
        height: '100%'
    },
    productImageDiv: {
        'padding': '10px',
        '& div': {
            display: 'flex',
            alignItems: 'center'
        },
        '& div div': {
            height: '210px'
        },
        '& div div div': {
            '@media screen and (max-width: 1100px)': {
                width: '250px',
            }
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none'
        }
    },
    productDataGrid: {
        height: '100%'
    },
    productHeaderFilter: {
        '& span': {
            color: '#959595 !important',
            cursor: 'pointer',
        }
    },
    grid: {
        marginTop: '10px'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledLineDetailIcon: {
        cursor: 'default',
        fill: 'grey'
    }
});
