import { fetchPost, objectify } from '../../utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import moment from 'moment';

export const search = async (query: { Supplier: number; Page: number; filters: any; Sort: string; SortDirection: string; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { Supplier, Page, filters, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  const respType = 'Inline';

  return fetchPost(
    `/SupplierEnquiry/LocalShipment/Supplier/${Supplier}/Retrieve`,
    filters
    &&
    {
      ...filters,
      FromDate: From,
      ToDate: To,
    },
    {
      BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`
    },
    respType)
    .then(
      (result: {
        SupplierLocalShipments: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.SupplierLocalShipments) {

            return {
              records: result.SupplierLocalShipments.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
