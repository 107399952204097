import {
  createActions, asyncInitialState, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null
}, 'ldInTransitImportShipments');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Shipment', field: 'Shipment', minWidth: 150 },
    { headerName: 'ETA', field: 'ETA', minWidth: 150, suppressSorting: true },
    { headerName: 'In transit', field: 'QtyIntransitDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150 },
    { headerName: 'Freight type', field: 'FreightType', suppressSorting: true, minWidth: 150 },
    { headerName: 'Carrier', field: 'Carrier', suppressSorting: true, minWidth: 150 },
    { headerName: 'Description', field: 'Description', minWidth: 150 },
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.ldInTransitImportShipments,
  {
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.ldInTransitImportShipments.loading,
  gridOptions: state => state.purchaseOrderEnquiry.ldInTransitImportShipments.data.gridOptions,
  Columns: state => state.purchaseOrderEnquiry.ldInTransitImportShipments.data.Columns,
  Actions: state => state.purchaseOrderEnquiry.ldInTransitImportShipments.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)