import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../../utils';

export const { types, actions } = createActions(
  {
    setSelected: (Shipment) => ({ Shipment }),
    setSelectedLocalShipment: (Shipment) => ({ Shipment }),
    changeSortFilter: (selectedSortFilter) => selectedSortFilter,
    changeSortDirection: (selectedSortDirection) => selectedSortDirection,
    clear: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      search: (query) => (query)
    }
  },
  'localShipments');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const NOT_SELECTED = -1;
const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    'EntityView': 'MPRO',
    'Warehouse': '',
    'FromDate': '',
    'ToDate': '',
    'Invoice': '',
    'Shipment': 0,
    'SortDirection': ''
  },
  filterRow: {
    formName: 'SEPLSFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item, index) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Despatch W/H',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'TEXT_FIELD',
        props: {
          label: 'Invoice',
          name: 'Invoice',
          size: 'small',
          required: false
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Shipment',
          name: 'Shipment',
          size: 'small',
          required: false
        }
      },
    ]
  },
  selectedLocalShipment: NOT_SELECTED,
  selectedSortFilter: 'InvoiceNumber',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  localShipmentColumns: [
    { headerName: 'Shipment', field: 'Shipment', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    {
      headerName: 'Invoice', field: 'Invoice',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry/invoice-details'
      },
      type: 'numericColumn',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice) {
          return {
            DocumentNumber: row.data.DocumentNumber,
            FieldValue: row.data.Invoice
          };
        } else {
          return null;
        }
      }
    },
    { headerName: 'ETA', field: 'ETA', suppressSorting: false, minWidth: 180, },
    { headerName: 'Despatch W/H', field: 'DespatchWarehouse', suppressSorting: true, minWidth: 180 },
    { headerName: 'Delivery W/H', field: 'DeliveryWarehouse', type: 'numericColumn', suppressSorting: true, minWidth: 180 },
    { headerName: 'Invoice total', field: 'InvoiceTotalDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 180 },
    { headerName: 'Description', field: 'Description', suppressSorting: true, minWidth: 180 },
    { headerName: 'Charges', field: 'ChargesDisplay', minWidth: 130, type: 'numericColumn', suppressSorting: true },
    { headerName: 'Overcharge', field: 'OverchargeDisplay', suppressSorting: true, minWidth: 150, type: 'numericColumn' },
    { headerName: 'Variance', field: 'VarianceDisplay', suppressSorting: true, minWidth: 130, type: 'numericColumn' },
    { headerName: 'Currency', field: 'Currency', suppressSorting: true, minWidth: 130, type: 'numericColumn' }
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      };
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.search:
      return asyncOnRequest(state, action);

    case types.setSelected:
      const selected = action.data.Shipment;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.setEntityView:
      const entityView = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          localShipments: action.payload
        };
      });
    case types.saga.search.failure:
      return asyncOnError(state, action);

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.supplierEnquiry.localShipments,
  {
    linesData: (data) => data.localShipments
  }
);

const syncSelector = {
  selected: (state) => state.supplierEnquiry.localShipments.data.selected,
  selectedSortFilter: (state) => state.supplierEnquiry.localShipments.data.selectedSortFilter,
  selectedSortDirection: (state) => state.supplierEnquiry.localShipments.data.selectedSortDirection,
  isLoading: (state) => state.supplierEnquiry.localShipments.search_loading,
  gridOptions: (state) => state.supplierEnquiry.localShipments.data.gridOptions,
  lineColumns: (state) => state.supplierEnquiry.localShipments.data.localShipmentColumns,
  filterRow: (state) => state.supplierEnquiry.localShipments.data.filterRow,
  selectedFilters: (state) => state.supplierEnquiry.localShipments.data.selectedFilters
};

export const selectors = { ...asyncSelector, ...syncSelector };
