import { IFormViewForm } from 'components/FormView';

const SlipLineBOMDetails: IFormViewForm = {
  id: 'SlipLineBOMDetails',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Product description',
            name: 'Description',
            placeholder: '',
            size: 'large'
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Required',
            name: 'RequiredQuantity',
            placeholder: '',
            size: 'medium'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supplied',
            name: 'SuppliedQuantityDisplay',
            placeholder: '',
            size: 'medium',
            alignment: 'right',
            type: 'number',
            decimalSeparator: true,
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: 'Component line comment',
            name: 'ComponentLineComment',
            fullWidth: true,
            style: {
              height: '350px'
            }
          }
        }
      ]
    }
  ]
};

export default SlipLineBOMDetails;
