import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../../utils';

export interface ISettingsByWarehouseData {
  selected: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
}

export interface ISettingsByWarehouseState extends IExtendedState<ISettingsByWarehouseData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {

    }
  },
  'supplierSettingsByWarehouse');

const initialState = asyncInitialState<ISettingsByWarehouseData>({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Columns: [
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 180 },
    { headerName: 'Additional lead time', field: 'AdditionalLeadTime', minWidth: 180, suppressSorting: true, },
  ]
});

export default (state: ISettingsByWarehouseState = initialState, action: IDataAction): ISettingsByWarehouseState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { supplierEnquiry: { settings: { settingsByWarehouse: ISettingsByWarehouseState } } }) => state.supplierEnquiry.settings.settingsByWarehouse.data.selected,
  gridOptions: (state: { supplierEnquiry: { settings: { settingsByWarehouse: ISettingsByWarehouseState } } }) => state.supplierEnquiry.settings.settingsByWarehouse.data.gridOptions,
  columns: (state: { supplierEnquiry: { settings: { settingsByWarehouse: ISettingsByWarehouseState } } }) => state.supplierEnquiry.settings.settingsByWarehouse.data.Columns,
});
