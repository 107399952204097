import React from 'react';
import SaleConfirmationSchema from './SaleConfirmationDialog.form';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { ISaleConfirmationDialogProperties } from './SaleConfirmationDialog.properties';
import { DialogTitle, ToogleInvoiceFields } from './SaleConfirmationDialog.constants';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useFetchSaleConfirmation } from 'api/Worksale/worksale';

const SaleConfirmationDialog = (props: ISaleConfirmationDialogProperties) => {
    const {
        open, loading, values,
        invoiceDocumentOutputOptions, setInvoiceDocumentOutputOptions,
        salesDocketDocumentOutputOptions, setSalesDocketDocumentOutputOptions,
        pickSlipDocumentOutputOptions, setPickSlipDocumentOutputOptions,
        orderDocumentOutputOptions, setOrderDocumentOutputOptions,
        barcodeLabelOutput, setBarcodeLabelOutputOptions,
        onOk, onCancel, changeFormFieldValue, operationInProgress, processSaleQuery
    } = props;
    const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
    const { data: response } = useFetchSaleConfirmation(worksaleId);

    const customerId = useSPContextSelector(Selectors.CustomerId);
    const initialValues = React.useMemo(
        () => {
            if (operationInProgress === 'ProcessSale') {
                return processSaleQuery;
            } else {
                return {
                    ...response?.SaleConfirmation.inlineObject,
                    InvoiceOutput: response?.SaleConfirmation.InvoiceOutput.inlineObject,
                    OrderAcknowledgementOutput: response?.SaleConfirmation.OrderAcknowledgementOutput.inlineObject,
                    PickSlipOutput: response?.SaleConfirmation.PickSlipOutput.inlineObject,
                    SalesDocketOutput: response?.SaleConfirmation.SalesDocketOutput.inlineObject
                };
            }
        },
        [operationInProgress, processSaleQuery, response]
    );
    const valuesSchema = React.useMemo(() => (response?.SaleConfirmation.schema), [response]);

    React.useEffect(
        () => {
            if (initialValues) {
                setInvoiceDocumentOutputOptions(initialValues?.InvoiceOutput);
                setSalesDocketDocumentOutputOptions(initialValues?.SalesDocketOutput);
                setPickSlipDocumentOutputOptions(initialValues?.PickSlipOutput);
                setBarcodeLabelOutputOptions(initialValues?.BarcodeLabelOutput);
                setOrderDocumentOutputOptions(initialValues?.OrderAcknowledgementOutput);
            } else {
                setInvoiceDocumentOutputOptions(undefined);
                setSalesDocketDocumentOutputOptions(undefined);
                setPickSlipDocumentOutputOptions(undefined);
                setBarcodeLabelOutputOptions(undefined);
                setOrderDocumentOutputOptions(undefined);
            }
        },
        [initialValues]);

    const toogleInvoiceSale = (toogle: boolean): void => {
        ToogleInvoiceFields.forEach((fieldName) => {
            changeFormFieldValue(fieldName, toogle ? valuesSchema[fieldName].Value : toogle);
        });
    };

    return (
        <div>
            <FormViewModal
                open={open}
                loading={loading}
                title={DialogTitle}
                formProps={{
                    formSchema: SaleConfirmationSchema,
                    initialValues: {
                        ...initialValues,
                        InvoiceOutput: initialValues && initialValues.InvoiceOutput ? initialValues.InvoiceOutput.Summary : '',
                        SalesDocketOutput: initialValues && initialValues.SalesDocketOutput ? initialValues.SalesDocketOutput.Summary : '',
                        PickSlipOutput: initialValues && initialValues.PickSlipOutput ? initialValues.PickSlipOutput.Summary : '',
                        OrderAcknowledgementOutput: initialValues && initialValues.OrderAcknowledgementOutput ? initialValues.OrderAcknowledgementOutput.Summary : '',
                        BarcodeLabelOutput: initialValues && initialValues.BarcodeLabelOutput ? initialValues.BarcodeLabelOutput.Summary : ''
                    },
                    fieldExtensions: {
                        InvoiceSale: {
                            onChange: (e) => {
                                toogleInvoiceSale(e.target.checked);
                            }
                        },
                        OutputInvoice: {
                            disabled: !(values && values.InvoiceSale),
                        },
                        InvoiceOutput: {
                            disabled: !(values && values.InvoiceSale)
                        },
                        SalesDocketOutput: {
                            disabled: !(values && values.InvoiceSale)
                        },
                        OutputBarcodeLabel: {
                            disabled: (valuesSchema?.OutputBarcodeLabel?.ReadOnly || !(values && values.InvoiceSale))
                        },
                    },
                    valuesSchema: valuesSchema ? valuesSchema : {},
                    style: { height: '575px', minWidth: '600px' },
                    customerId: customerId
                }}
                actions={[
                    {
                        title: 'Ok',
                        listener: () => {
                            if (onOk) {
                                onOk({
                                    ...values,
                                    InvoiceOutput: invoiceDocumentOutputOptions,
                                    SalesDocketOutput: salesDocketDocumentOutputOptions,
                                    PickSlipOutput: pickSlipDocumentOutputOptions,
                                    OrderAcknowledgementOutput: orderDocumentOutputOptions,
                                    BarcodeLabelOutput: barcodeLabelOutput,
                                });
                            }
                        }
                    },
                    {
                        title: 'Cancel',
                        isDefault: true,
                        listener: () => {
                            if (onCancel) {
                                onCancel();
                            }
                        },
                    },
                ]}
                dialogActionsButtons={true}
                dialogActionsShadow={false}
            />
        </div>
    );
};

export default SaleConfirmationDialog;
