import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    serviceAgreementItemContainer: {
        maxWidth: 650,
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        padding: 25,
        gridRowGap: 5
    },
    fullWidth: {
        gridColumn: '1 / span 2'
    },
    serviceDueContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    heading: {
        fontSize: 'larger',
        color: '#777777',
        margin: '20px 0px'
    },
    UpcomingGroupCellComponentContainer: {
        display: 'flex',
        gap: '20px',
    }
});
