import { COLUMN_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export const columns = [
    {
        dataField: 'LineNumber',
        caption: 'Line',
        width: COLUMN_SIZE.sm0,
        fixed: true,
        allowSorting: true,
        allowEditing: false,
    },
    {
        dataField: 'ProductIdDisplay',
        caption: 'Product',
    },
    {
        dataField: 'OrderedQuantity',
        caption: 'Ordered',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        allowEditing: false,
    },
    {
        dataField: 'SuppliedQuantity',
        caption: 'Supplied',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        isEditable: true,
    },
    {
        dataField: 'Price',
        caption: 'Price',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        isEditable: true,
    },
    {
        dataField: 'Currency',
        caption: 'Currency',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        allowEditing: false,
    },
    {
        dataField: 'Discount',
        caption: 'Discount %',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: "#0.00 '%'",
        allowSorting: false,
        isEditable: true,
    },
    {
        dataField: 'Description',
        caption: 'Description',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        allowEditing: false,
    },
    {
        dataField: 'Comment',
        caption: 'Comment',
        minWidth: COLUMN_SIZE.md0,
        width: COLUMN_SIZE.md1,
        format: '#0.00',
        allowSorting: false,
        allowEditing: false,
    },
    {
        dataField: 'BackorderedQuantity',
        visible: false,
    },
    {
        dataField: 'KittingQuantity',
        visible: false,
    },
    {
        dataField: 'QuotedExchangeRate',
        visible: false,
    },
    {
        dataField: 'QuoteCurrency',
        visible: false,
    },
];
