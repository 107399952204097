import {
  actions as uiActions,
} from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as diarySelectors, actions as diaryActions } from 'ducks/common/diary'
import { search } from 'api/common/diary';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

export const mapStateToProps = state => ({
  gridName: 'Diary',
  selectedRecord: diarySelectors.selected(state),
  columnDefs: diarySelectors.columns(state),
  detailColumns: diarySelectors.detailColumns(state),
  rowData: diarySelectors.data(state),
  gridOptions: diarySelectors.gridOptions(state),
  isLoading: diarySelectors.isLoading(state),
  filterRow: diarySelectors.filterRow(state),
  selectedFilters: diarySelectors.selectedFilters(state),
  detailCellRenderer: diarySelectors.detailCellRenderer(state),
  appliedFilters: diarySelectors.filterRow(state) && filterSelectors.getFilters(state, diarySelectors.filterRow(state).formName),
  keyFields: [{ valueField: 'DiaryId', routeField: 'DiaryId' }],
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: diaryActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}