import * as React from 'react';
import { withRouter } from 'react-router';
import { IJobTemplateDetailsProps, IJobTemplateDetailsState } from '../interfaces';
import FormView from 'components/FormView';
import { Operation } from 'utils/operations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
class JobTemplateDetail extends React.PureComponent<IJobTemplateDetailsProps, IJobTemplateDetailsState> {

    operationMap: Map<Operation, (prevProps?: Readonly<IJobTemplateDetailsProps>) => void>;

    constructor(props: Readonly<IJobTemplateDetailsProps>) {
        super(props);
        this.state = {
            getLocalValue: false,
            values: {
                TemplateCode: '',
                Description: '',
                Comment: '',
            },
        };
        this.operationMap = new Map<Operation, () => void>();
        this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
        this.operationMap[Operation.DELETE] = this.deleteJobTemplate;
        this.operationMap[Operation.BACK] = this.cancelFormSubmission;
        this.operationMap[Operation.NEW] = this.populateNewForm;
        this.operationMap[Operation.SAVE] = this.submitFormValues;
    }

    submitFormValues = (prevProps: Readonly<IJobTemplateDetailsProps>): void => {
        const { isValid, formSyncErrors, formAsyncErrors = {}, changeOperationMode, touchFormFields } = this.props;

        const create = prevProps.operationMode === Operation.NEW;

        if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0) || Object.keys(formAsyncErrors).length > 0) {
            const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
            if (requiredError) {
                showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
                touchFormFields(requiredError);
            } else {
                showSnackBar({ variant: 'warning', message: 'Please enter valid values' });
            }
            changeOperationMode(prevProps.operationMode);
            this.setState({ getLocalValue: true });

            return;
        }
        if (create) {
            this.setState({ getLocalValue: true });
            this.createJobTemplate();
        } else {
            this.updateJobTemplate();
        }
    }

    createJobTemplate = (): void => {
        const { createJobTemplate, values } = this.props;
        createJobTemplate(values);
    }

    updateJobTemplate = (): void => {
        const { updateJobTemplate, values } = this.props;
        updateJobTemplate(values);
    }

    deleteJobTemplate = (): void => {
        const { deleteJobTemplate, values = {}, changeOperationMode, changeConfirmationDialog } = this.props;
        if (values.TemplateCode) {
            changeConfirmationDialog({
                open: true,
                title: 'Delete Template',
                message: 'Are you sure you want to delete?',
                okLabel: 'Delete',
                onCancel: () => {
                    changeOperationMode(Operation.BROWSE);
                },
                onOk: () => {
                    deleteJobTemplate(values.TemplateCode);
                }

            });
        }

        changeOperationMode(Operation.BROWSE);
    }

    populateNewForm = (prevProps: Readonly<IJobTemplateDetailsProps>): void => {
        const { values } = this.props;
        this.setState({ getLocalValue: true });
        if (prevProps.operationMode === Operation.CANCEL) {
            this.setState({
                values: values
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<IJobTemplateDetailsProps>): void {
        const { operationMode } = this.props;
        if (operationMode !== prevProps.operationMode) {
            this.setState({ getLocalValue: false });
            if (this.operationMap[operationMode]) { this.operationMap[operationMode](prevProps); }

        }
    }

    componentDidMount(): void {
        const { path, onInitialLoad } = this.props;

        if (path) {
            switch (path) {
                case '/job-template-maintenance/job-template-details':
                    onInitialLoad('JobTemplateDetails');
                    break;
                default:
                    onInitialLoad('JobTemplateDetails');
            }
        }
    }

    cancelFormSubmission = (prevProps: Readonly<IJobTemplateDetailsProps>) => {
        const {
            dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad,
            selectedTab, resetForm, operationMode, history
        } = this.props;
        if (dirty) {
            changeConfirmationDialog({
                open: true,
                title: 'Discard changes',
                message: 'Are you sure you want to continue?',
                okLabel: 'Discard',
                onCancel: () => {
                    changeOperationMode(prevProps.operationMode);
                },
                onOk: () => {
                    changeOperationMode(Operation.BROWSE);
                    resetForm();
                }
            });
        } else {
            if (operationMode === Operation.CANCEL) {
                changeOperationMode(Operation.BROWSE);
            } else {
                if (prevProps.operationMode === Operation.NEW) {
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab !== 'JobTemplateDetails') {
                    onInitialLoad('JobTemplateDetails');
                    changeOperationMode(Operation.BROWSE);
                } else if (selectedTab === 'JobTemplateDetails') {
                    history.push('/dashboard');
                }
            }
        }
    }

    getInitialValues = (): any => {
        const { selectedTab, operationMode, jobTemplateData, values } = this.props;
        const { getLocalValue } = this.state;

        if (operationMode === Operation.CANCEL) { return { initialValues: values }; }

        if (getLocalValue) {
            return { initialValues: this.state.values };
        }

        switch (selectedTab) {
            case 'JobTemplateDetails':
                return {
                    initialValues: jobTemplateData && jobTemplateData.inlineObject,
                    valuesSchema: jobTemplateData && jobTemplateData.schema
                };
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const { isBrowseLookUpOpen, selectedForm, operationMode, formName, isFormLoading } = this.props;
        const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

        return (selectedForm &&
            <React.Fragment>
                <FormView
                    formName={formName}
                    browseLookUpOpen={isBrowseLookUpOpen}
                    includeTabsHeight={false}
                    schema={selectedForm}
                    initialValues={initialValues}
                    operationMode={operationMode}
                    valuesSchema={valuesSchema}
                    isLoading={isFormLoading}
                    fieldExtensions={
                        {
                            TemplateCode: {
                                readOnly: operationMode === Operation.EDIT
                            }
                        }
                    }
                />
            </React.Fragment>
        );
    }
}

export default withRouter(JobTemplateDetail);
