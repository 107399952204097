import { toObjectified } from 'api/baseApi';
import { AsObjectified, Inline } from 'api/utils';
import { Payload, useFetch, usePost,  } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { INotepadDetailResponse, INotepadFacade } from 'api/swaggerTypes';

export type IObjectifiedNotepadResponse = AsObjectified<INotepadDetailResponse, 'Notepad'>;
const notepadResponseParser = (response: INotepadDetailResponse) => {
    const { Notepad } = response;

    return {
        ...response,
        Notepad: Notepad ? toObjectified(Notepad as Inline<INotepadFacade>) : null
    };
};

export const useRetrieveDiaryDetail = (DespatchId: number) =>
    useFetch<IObjectifiedNotepadResponse>(
        {
            url: APIRoutes.pickSlips.notepad.retrieve,
            urlParams: { DespatchId },
            options: { inline: true, responseParser: notepadResponseParser }
        }
    );

export const useCreateDiaryDetail = () =>
usePost<Payload<{ DespatchId?: number; Note: string }>, any>
    (APIRoutes.pickSlips.notepad.update, null, { inline: true, });
