import OrderDetails from '../data/forms/sales_order_enquiry/OrderDetails'
import LineDetails from '../data/forms/sales_order_enquiry/LineDetails'
import OrderLines from '../data/forms/sales_order_enquiry/OrderLines'
import CommittedPurchaseOrder from '../data/forms/sales_order_enquiry/CommittedPurchaseOrder'
import CommittedProductionOrder from '../data/forms/sales_order_enquiry/CommittedProductionOrder'
import CommittedInPutAway from '../data/forms/sales_order_enquiry/CommittedInPutAway'
import LDCommittedPurchaseOrder from '../data/forms/sales_order_enquiry/LDCommittedPurchaseOrder'
import LDCommittedProductionOrder from '../data/forms/sales_order_enquiry/LDCommittedProductionOrder'
import LDCommittedInPutAway from '../data/forms/sales_order_enquiry/LDCommittedInPutAway'
import CDCommittedPurchaseOrder from '../data/forms/sales_order_enquiry/CDCommittedPurchaseOrder'
import CDCommittedProductionOrder from '../data/forms/sales_order_enquiry/CDCommittedProductionOrder'
import CDCommittedInPutAway from '../data/forms/sales_order_enquiry/CDCommittedInPutAway'
import Comment from '../data/forms/sales_order_enquiry/Comment'
import Deliveries from '../data/forms/sales_order_enquiry/Deliveries'
import DeliveryDetails from '../data/forms/sales_order_enquiry/DeliveryDetails'
import SaleDeposits from '../data/forms/sales_order_enquiry/SaleDeposits'
import DepositDetails from '../data/forms/sales_order_enquiry/DepositDetails'
import PaymentEntryDetails from '../data/forms/sales_order_enquiry/PaymentEntryDetails'
import ReservationDetails from '../data/forms/sales_order_enquiry/ReservationDetails'
import ReservationSerials from '../data/forms/sales_order_enquiry/ReservationSerials'
import ReservationLots from '../data/forms/sales_order_enquiry/ReservationLots'
import CDReservationDetails from '../data/forms/sales_order_enquiry/CDReservationDetails'
import CDReservationSerials from '../data/forms/sales_order_enquiry/CDReservationSerials'
import CDReservationLots from '../data/forms/sales_order_enquiry/CDReservationLots'
import LineInvoices from './../data/forms/sales_order_enquiry/LineInvoices'
import BOM from './../data/forms/sales_order_enquiry/BOM'
import ComponentDetails from './../data/forms/sales_order_enquiry/ComponentDetails'
import SalesDiary from './../data/forms/sales_order_enquiry/Diary'
import DiaryDetails from './../data/forms/sales_order_enquiry/DiaryDetails'
import Notepad from './../data/forms/sales_order_enquiry/Notepad'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'OrderDetails':
      return OrderDetails
    case 'LineDetails':
      return LineDetails
    case 'OrderLines':
      return OrderLines
    case 'Deliveries':
      return Deliveries
    case 'DeliveryDetails':
      return DeliveryDetails
    case 'CommittedPurchaseOrder':
      return CommittedPurchaseOrder
    case 'CommittedProductionOrder':
      return CommittedProductionOrder
    case 'CommittedInPutAway':
      return CommittedInPutAway
    case 'LDCommittedPurchaseOrder':
      return LDCommittedPurchaseOrder
    case 'LDCommittedProductionOrder':
      return LDCommittedProductionOrder
    case 'LDCommittedInPutAway':
      return LDCommittedInPutAway
    case 'CDCommittedPurchaseOrder':
      return CDCommittedPurchaseOrder
    case 'CDCommittedProductionOrder':
      return CDCommittedProductionOrder
    case 'CDCommittedInPutAway':
      return CDCommittedInPutAway
    case 'Comment':
      return Comment
    case 'SaleDeposits':
      return SaleDeposits
    case 'DepositDetails':
      return DepositDetails
    case 'PaymentEntryDetails':
      return PaymentEntryDetails
    case 'ReservationDetails':
      return ReservationDetails
    case 'LDReservationSerials':
      return ReservationSerials
    case 'LDReservationLots':
      return ReservationLots
    case 'CDReservationDetails':
      return CDReservationDetails
    case 'CDReservationSerials':
      return CDReservationSerials
    case 'CDReservationLots':
      return CDReservationLots
    case 'LineInvoices':
      return LineInvoices
    case 'SOEBOM':
      return BOM
    case 'ComponentDetails':
      return ComponentDetails
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    case 'Notepad':
      return Notepad
    default:
      return OrderDetails
  }
}

export default getFormSchema
