import {
  createActions,
  IDataAction,
  asyncOnRequest,
  IExtendedState,
  asyncInitialState,
  asyncSelectors,
  asyncOnSuccess,
  SuccessAction,
  asyncOnError,
} from '../utils';

export interface ISerialNumberData {
  selectedLines?: any;
  availableLines?: any;
  serialSummary: any;
}

export interface ISerialNumberState extends IExtendedState<ISerialNumberData> {
  fetchAvailableLines_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    asyncs: {
      fetchAvailableLines: (query) => (query),
      fetchSelectedSerials: (query) => (query),
      fetchSerialsSummary: (query) => (query),
      saveSerials: (query) => (query),
    },
    clearSelectedLines: () => null,
    addSelectedLine: (line) => (line),
    addFromAvailableLines: (line) => (line),
    removeSelectedLine: (line) => (line),
  },
  'serialNumber'
);

const initialState = asyncInitialState<ISerialNumberData>({
  selectedLines: [],
  availableLines: [],
  serialSummary: { WarehouseEntity: null, ProductId: null },
});

export default (state: ISerialNumberState = initialState, action: IDataAction): ISerialNumberState => {
  let lineToAdd = { SerialNumber: '' };
  let newArr = [];
  let tempAvailArr = [];
  let foundInAvailable;

  switch (action.type) {
    case types.clearSelectedLines:
      tempAvailArr = [...state.data.availableLines];
      tempAvailArr.forEach((item) => item.hide = false);

      return {
        ...state,
        data:
        {
          ...state.data,
          selectedLines: [],
          availableLines: tempAvailArr
        },
      };

    case types.removeSelectedLine:
      const removeSerial = action.data;
      newArr = [...state.data.selectedLines];
      const index = newArr.findIndex(({ SerialNumber }) => SerialNumber === removeSerial);
      if (index || index === 0) newArr.splice(index, 1);

      tempAvailArr = [...state.data.availableLines];
      foundInAvailable = tempAvailArr.find(({ SerialNumber }) => SerialNumber === removeSerial);
      if (foundInAvailable) {
        foundInAvailable.hide = false;
      }

      return {
        ...state,
        data:
        {
          ...state.data,
          selectedLines: newArr,
          availableLines: tempAvailArr,
        }
      };

    case types.addFromAvailableLines:
      lineToAdd = { SerialNumber: action.data };
      tempAvailArr = [...state.data.availableLines];
      newArr = [...state.data.selectedLines];
      const foundInSelected = newArr.find(({ SerialNumber }) => SerialNumber === lineToAdd.SerialNumber);
      if (!foundInSelected) {
        foundInAvailable = tempAvailArr.find(({ SerialNumber }) => SerialNumber === lineToAdd.SerialNumber);
        if (foundInAvailable) {
          foundInAvailable.hide = true;
          newArr = [...newArr, lineToAdd];
        }
      }

      return {
        ...state,
        data:
        {
          ...state.data,
          selectedLines: newArr,
          availableLines: tempAvailArr,
        }
      };

    case types.addSelectedLine:
      lineToAdd = { SerialNumber: action.data };
      newArr = [...state.data.selectedLines];
      const found = newArr.find(({ SerialNumber }) => SerialNumber === lineToAdd.SerialNumber);
      if (!found) newArr = [...newArr, lineToAdd];

      return {
        ...state,
        data:
        {
          ...state.data,
          selectedLines: newArr
        }
      };
    case types.fetchSelectedSerials:
    case types.fetchSerialsSummary:
    case types.saveSerials:
    case types.fetchAvailableLines:
      return asyncOnRequest(state, action);
    case types.saga.fetchAvailableLines.success:
      return asyncOnSuccess(state, action, (data: ISerialNumberData, successAction: SuccessAction) => {
        const lines = successAction.payload;

        return {
          ...data,
          availableLines: lines
        };
      });
    case types.saga.fetchSelectedSerials.success:
      return asyncOnSuccess(state, action, (data: ISerialNumberData, successAction: SuccessAction) => {
        const lines = successAction.payload;

        return {
          ...data,
          selectedLines: lines
        };
      });
    case types.saga.fetchSerialsSummary.success:
      return asyncOnSuccess(state, action, (data: ISerialNumberData, successAction: SuccessAction) => {
        const summary = successAction.payload;

        return {
          ...data,
          serialSummary: summary
        };
      });
    case types.saga.saveSerials.success:
      return state;
    case types.saga.fetchSelectedSerials.failure:
    case types.saga.fetchAvailableLines.failure:
    case types.saga.saveSerials.failure:
    case types.saga.fetchSerialsSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { common: { serialNumber: ISerialNumberState } }) => state.common.serialNumber,
    {
      getAvailableLines: (data) => data.availableLines.filter((element) => !element.hide),
      getSelectedLines: (data) => (data.selectedLines),
      serialNumberSummary: (data) => (data.serialSummary),
    }),
};
