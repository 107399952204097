export const getTabs = (options: { customerId: number; worksaleId: number }) => ([
  { id: 'Lines', label: 'Lines' },
  { id: 'ProductCatalogue', label: 'Catalogue', disabled: !Boolean(options?.worksaleId) },
  { id: 'OrderDetails', label: 'Order Details', disabled: !Boolean(options?.customerId) },
  { id: 'Deliveries', label: 'Deliveries', disabled: !Boolean(options?.customerId) },
]);

export const CircularProgressSize = 18;
export const DEFAULT_BATCH_SIZE = 10;
export const DEFAULT_BREAD_CRUMBS = 4;

export const ACTION_BAR_BTN_LABELS = {
  Lines: {
    rSale: 'Recall Sale',
    rOrder: 'Recall Order',
    rQuote: 'Recall Quote',
    park: 'Park',
    quote: 'Quote',
  },
  cancel: 'Cancel',
  process: 'Process',
};

export const COMMIT_BACKORDER_POPUP_MESSAGE = 'A backorder quantity has been recorded for one or more sale lines. \n You may choose to commit the backorders to new or outstanding purchase orders or to stock awaiting put away.';
