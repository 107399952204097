import { connect } from 'react-redux';
import { selectors as activitySchedulerSelectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import { IApplicationState } from 'ducks/reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as operationActions } from 'ducks/uiOperations';
import ScheduleActivityModal from './ScheduleActivityModal';

const mapStateToProps = (state: IApplicationState) => ({
  resourcesList: activitySchedulerSelectors.resourcesList(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeOperationMode: operationActions.changeOperationMode,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleActivityModal);
