import PickSlipDetails from './../data/forms/pick-slips/SlipDetails';
import SlipLines from './../data/forms/pick-slips/SlipLines';
import SlipLineDetails from './../data/forms/pick-slips/SlipLineDetails';
import Serials from './../data/forms/pick-slips/Serials';
import NotePad from './../data/forms/pick-slips/NotePad';
import PSLots from '../data/forms/pick-slips/PSLots';
import SlipLineBOM from './../data/forms/pick-slips/SlipLineBOM';
import SlipLineBOMDetails from './../data/forms/pick-slips/SlipLineBOMDetails';
import BOMLots from './../data/forms/pick-slips/BOMLots';
import BOMSerials from './../data/forms/pick-slips/BOMSerials';
import Diary from './../data/forms/pick-slips/Diary';
import DiaryDetails from './../data/forms/pick-slips/DiaryDetails';
import SerialNumber from './../data/forms/pick-slips/SerialNumber';
import BinLocations from './../data/forms/pick-slips/BinLocations';
import KitComponents from './../data/forms/pick-slips/KitComponents';
import KitComponentDetail from './../data/forms/pick-slips/KitComponentDetail';

const getFormSchema = (formId) => {
    switch (formId) {
        case 'SlipDetails':
            return PickSlipDetails;
        case 'SlipLines':
            return SlipLines;
        case 'SlipLineDetails':
            return SlipLineDetails;
        case 'PSLots':
            return PSLots;
        case 'Serials':
            return Serials;
        case 'SlipLineBOM':
            return SlipLineBOM;
        case 'SlipLineBOMDetails':
            return SlipLineBOMDetails;
        case 'SlipLineBOMLots':
            return BOMLots;
        case 'SlipLineBOMSerials':
            return BOMSerials;
        case 'Notepad':
            return NotePad;
        case 'SalesDiary':
            return Diary;
        case 'DiaryDetails':
            return DiaryDetails;
        case 'SerialNumber':
            return SerialNumber;
        case 'BinLocations':
            return BinLocations;
        case 'KitComponents':
            return KitComponents;
        case 'KitComponentDetail':
            return KitComponentDetail;
        default:
            return PickSlipDetails;
    }
};

export default getFormSchema;
