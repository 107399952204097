import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch';
import { withStyles, StyledComponentProps } from '@material-ui/core/styles';
import { ENTER_KEY } from 'utils/constants';

const SwitchStyles = {
    switchBase: {
        '&$checked': {
            '& + $bar': {
                backgroundColor: '#6DD900',
                opacity: 1
            },
        },
    },
    bar: {},
    checked: {},
    formControlLabel: {
        '&$disabled': {
            color: '#555555',
        },
    },
    disabled: {
        '&$checked': {
            '& + $bar': {
                backgroundColor: '#6DD900',
                opacity: 0.5
            }
        }
    }
};

interface ISwitchPropsExtended extends Omit<SwitchProps, 'classes'>, StyledComponentProps {
    label: string;
}

const ToggleButton = (props: ISwitchPropsExtended) => {
    const { classes, onKeyDown, ...restProps } = props;

    const _onKeyDown = React.useCallback(
        (e) => {
            if (e.keyCode === ENTER_KEY) {
                props.onChange({ target: { checked: !props.checked } } as any, !props.checked);
            }
            if (onKeyDown) {
                onKeyDown(e);
            }
        },
        [props.checked]
    );

    return (
        <FormControlLabel
            control={<Switch
                color={'default'}
                onKeyDown={_onKeyDown}
                classes={{
                    switchBase: classes.switchBase,
                    checked: classes.checked,
                    bar: classes.bar,
                    disabled: classes.disabled
                }}
                {...restProps} />}
            style={{ width: props?.style?.width ?? '100%', ...props?.style }}
            classes={{
                label: classes.formControlLabel,
                disabled: classes.disabled
            }}
            label={props.label} />
    );
};

export default withStyles(SwitchStyles, { index: 1 })(ToggleButton);
