export default [
  {
    id: '1',
    name: 'QLD SALES',
    abbreviation: 'QS',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'BMW SALES',
    abbreviation: 'BS',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'AUDI SALES',
    abbreviation: 'AS',
    fieldList1: 'field'
  },
];
