// tslint:disable: no-magic-numbers
import * as React from 'react';
//icons
import Icon from '@markinson/uicomponents-v2/Icon';
import Paths from './Paths.json';
//components
import CustomerMaintenance from './CustomerMaintenance/index';
import CustomerEnquiry from './CustomerEnquiry/index';
import PickSlipEnquiry from './PickSlipEnquiry/index';
import PickSlips from './PickSlips';
import CustomerQuoteEnquiry from './CustomerQuoteEnquiry/index';
import SalesOrderEnquiry from './SalesOrderEnquiry/index';
import SalesInvoiceEnquiry from './SalesInvoiceEnquiry/index';
import InventoryEnquiry from './InventoryEnquiry/index';
import PriceCheck from './PriceCheck/index';
import SupplierEnquiry from './SupplierEnquiry/index';
import SupplierInvoiceEnquiry from './SupplierInvoiceEnquiry/index';
import ReturnForCreditEnquiry from './RFCEnquiry/index';
import SupplierProductEnquiry from 'components/SupplierProductEnquiry';
import CreditNotes from 'components/CreditNotes';
import CashReceipting from 'components/CashReceipting';
import PurchaseOrderEnquiry from 'components/PurchaseOrderEnquiry';
import ServiceActivityScheduling from 'components/ServiceActivityScheduling';
import ActivityMaintenance from 'components/ActivityMaintenance';
import ChecklistMaintenance from 'components/ChecklistMaintenance';
import JobTemplateMaintenance from 'components/JobTemplateMaintenance';
import RosterMaintenance from 'components/RosterMaintenance';
import Worksale from 'components/Worksale';
import ServiceItemTypeMaintenance from 'components/ServiceItemTypeMaintenance';
import ServicePatternMaintenance from 'components/ServicePatternMaintenance';
import ServiceAgreementMaintenance from 'components/ServiceAgreementMaintenance';
import ExtractMaintenance from 'components/ExtractMaintenance';
import RunDynamicExtract from 'components/RunDynamicExtract';
import PurchaseOrders from 'components/PurchaseOrders';

export default {
  1: {
    component: SupplierEnquiry,
    path: Paths[1],
    icon: (<Icon iconName='LocalShipping' className='material-icons md-60' />),
    description: 'Supplier Enquiry'
  },
  2: {
    component: CustomerEnquiry,
    path: Paths[2],
    icon: (<Icon iconName='Contacts' className='material-icons md-60' />),
    description: 'Customer Enquiry'
  },
  3: {
    component: CustomerMaintenance,
    path: Paths[3],
    icon: (<Icon iconName='AccountBox' className='material-icons md-60' />),
    description: 'Customer Maintenance'
  },
  4: {
    component: SalesInvoiceEnquiry,
    path: Paths[4],
    icon: (<Icon iconName='Store' className='material-icons md-60' />),
    description: 'Sales Invoice Enquiry'
  },
  5: {
    component: SupplierInvoiceEnquiry,
    path: Paths[5],
    icon: (<Icon iconName='AccountsPayable' />),
    description: 'Supplier Invoice Enquiry'
  },
  6: {
    component: InventoryEnquiry,
    path: Paths[6],
    icon: (<Icon iconName='Inventory' />),
    description: 'Inventory Enquiry'
  },
  7: {
    component: SalesOrderEnquiry,
    path: Paths[7],
    icon: (<Icon iconName='Assignment' className='material-icons md-60' />),
    description: 'Sales Order Enquiry'
  },
  8: {
    component: CustomerQuoteEnquiry,
    path: Paths[8],
    icon: (<Icon iconName='AccountsReceivable' />),
    description: 'Customer Quote Enquiry'
  },
  9: {
    component: Worksale,
    path: Paths[9],
    icon: (<Icon iconName='SalesProcessing' />),
    description: 'Sales Processing'
  },
  // 10: {
  //   component: ServiceOrders, path: Paths[10],
  //   icon: (<Icon iconName='Services' />),
  //   description: 'Service Orders'
  // },
  11: {
    component: PriceCheck,
    path: Paths[11],
    icon: (<Icon iconName='Pricing' />),
    description: 'Price Check'
  },
  12: {
    component: PickSlipEnquiry,
    path: Paths[12],
    icon: (<Icon iconName='PlaylistAddCheck' className='material-icons md-60' />),
    description: 'Pick Slip Enquiry'
  },
  13: {
    component: CashReceipting,
    path: Paths[13],
    icon: (<Icon iconName='Receipt' />),
    description: 'POS Cash Receipting'
  },
  // 14: { component: StockAdjustments, path: Paths[14] },
  // 15: { component: StockConversions, path: Paths[15] },
  // 16: { component: CostAdjustments, path: Paths[16] },
  // 17: { component: StockReservationReport, path: Paths[17] },
  // 18: { component: DebtorCashRefunds, path: Paths[18] },
  19: {
    component: CreditNotes,
    path: Paths[19],
    icon: (<Icon iconName='LocalShipping' className='material-icons md-60' />),
    description: 'Credit Notes'
  },
  // 20: { component: InvoiceSummaryReport, path: Paths[20] },
  // 21: { component: BankDepositSlip, path: Paths[21] },
  22: {
    component: SupplierProductEnquiry,
    path: Paths[22],
    icon: (<Icon iconName='LocalShipping' className='material-icons md-60' />),
    description: 'Supplier Product Enquiry'
  },
  // 23: { component: PriceScheduleMaintenance, path: Paths[23] },
  // 24: { component: CustomerPriceList, path: Paths[24] },
  // 25: { component: ServiceOrderEnquiry, path: Paths[25] },
  // 26: { component: ServiceItemEnquiry, path: Paths[26] },
  // 27: { component: ServiceItemMaintenance, path: Paths[27] },
  // 28: { component: ServiceOrderMasterList, path: Paths[28] },
  // 29: { component: ServiceItemMasterList, path: Paths[29] },
  // 30: { component: StocktakeEnquiry, path: Paths[30] },
  // 31: { component: Stocktakes, path: Paths[31] },
  // 32: { component: StocktakeCountEntry, path: Paths[32] },
  // 33: { component: StocktakeCountSheet, path: Paths[33] },
  34: {
    component: ReturnForCreditEnquiry,
    path: Paths[34],
    icon: (<Icon iconName='AssignmentReturn' className='material-icons md-60' />),
    description: 'Return For Credit Enquiry'
  },
  35: {
    component: PickSlips,
    path: Paths[35],
    icon: (<Icon iconName='PlaylistAddCheck' className='material-icons md-60' />),
    description: 'Pick Slips'
  },
  36: {
    component: PurchaseOrderEnquiry,
    path: Paths[36],
    icon: (<Icon iconName='Purchasing' className='material-icons md-60' />),
    description: 'Purchase Order Enquiry'
  },
  37: {
    component: ServiceActivityScheduling,
    path: Paths[37],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Service Activity Scheduling'
  },
  38: {
    component: ActivityMaintenance,
    path: Paths[38],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Activity Maintenance'
  },
  39: {
    component: RosterMaintenance,
    path: Paths[39],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Roster Maintenance'
  },
  40: {
    component: JobTemplateMaintenance,
    path: Paths[40],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Job Template Maintenance'
  },
  41: {
    component: ServiceItemTypeMaintenance,
    path: Paths[41],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Service Item Type Maintenance'
  },
  42: {
    component: ChecklistMaintenance,
    path: Paths[42],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Checklist Maintenance'
  },
  43: {
    component: ServicePatternMaintenance,
    path: Paths[43],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Service Pattern Maintenance'
  },
  44: {
    component: ServiceAgreementMaintenance,
    path: Paths[44],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Service Agreement Maintenance'
  },
  45: {
    component: RunDynamicExtract,
    path: Paths[45],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Run Dynamic Extract'
  },
  46: {
    component: ExtractMaintenance,
    path: Paths[46],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Dynamic Data Extract'
  },
  47: {
    component: PurchaseOrders,
    path: Paths[47],
    icon: (<Icon iconName='CalendarToday' className='material-icons md-60' />),
    description: 'Purchase Orders'
  },
};
