import React from 'react';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';

class ActionFieldContainer extends React.PureComponent {

  fieldLabel = () => {
    const { name, selectedTab, saleConfirmation } = this.props;
    if (selectedTab === 'SPSaleConfirmation' && saleConfirmation) {
      if (name === 'SurchageAmount') {
        return saleConfirmation.SurchargeLabel
      }
      else if (name === 'SurchargeTaxAmount') {
        return saleConfirmation.SurchargeTaxLabel
      }
    }
  }

  onIconClick = (name) => (value) => {
    if (value) {
      switch (name) {
        case 'Mail': window.location.href = `mailto:${value}`;
          break;
        case 'Phone': window.location.href = `tel:${value}`;
          break;
        case 'Language':
          if (!String(value).startsWith('http://') && !String(value).startsWith('https://')) {
            window.open(`http://${value}`);
          } else {
            window.open(`${value}`);
          }
          break;
        default:
      }
    }
  }

  render() {
    const { action, ...rest } = this.props;
    let newAction = action;
    if (action) {
      if (action.iconName === 'Mail' && !action.controller) {
        newAction.controller = this.onIconClick('Mail');
      }
      if (action.iconName === 'Phone' && !action.controller) {
        newAction.controller = this.onIconClick('Phone');
      }
      if (action.iconName === 'Language' && !action.controller) {
        newAction.controller = this.onIconClick('Language');
      }
    }
    return (
      <TextField
        action={newAction}
        {...rest}
      />
    )
  }
}

export default ActionFieldContainer;