import BreaksTable from './BreaksTable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as lookupActions, selectors as lookupSelectors } from '../../../ducks/lookup';
import { IApplicationState } from '../../../ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  lookUpOptions: lookupSelectors.options(state) || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchLookupOptions: lookupActions.fetchData,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BreaksTable);
