import { IFormViewForm } from 'components/FormView';

const SlipLineDetails: IFormViewForm = {
  id: 'SlipLineDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            size: 'large',
            disabled: true
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Ordered',
            name: 'OrderedQuantity',
            placeholder: '',
            size: 'medium',
            disabled: true,
            alignment: 'right'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          props: {
            label: 'Supplied',
            name: 'SuppliedQuantityDisplay',
            placeholder: '',
            size: 'medium',
            disabled: true,
            alignment: 'right'
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Kitting',
            name: 'KittingQuantityDisplay',
            placeholder: '',
            size: 'medium',
            disabled: true,
            alignment: 'right'
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'To pick',
            name: 'ToPickQuantityDisplay',
            placeholder: '',
            size: 'medium',
            disabled: true,
            alignment: 'right'
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Backordered',
            name: 'BackorderQuantityDisplay',
            placeholder: '',
            size: 'medium',
            disabled: true,
            alignment: 'right'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          props: {
            name: 'LineComment',
            label: 'Component line comment',
            fullWidth: true,
            disabled: true,
            style: {
              height: '200px'
            }
          }
        }
      ]
    }
  ]
};
export default SlipLineDetails;
