import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as serviceOrdersSelectors, actions as serviceOrdersActions } from 'ducks/purchaseOrderEnquiry/ldCommittedServiceOrder';
import { searchServiceOrders } from 'api/purchaseOrderEnquiry/ldCommitted';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { selectors as orderLineSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'CommittedServiceOrders',
  columnDefs: serviceOrdersSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: serviceOrdersSelectors.Actions(state),
  gridOptions: serviceOrdersSelectors.gridOptions(state),
  isLoading: serviceOrdersSelectors.isLoading(state),
  apiMethod: searchServiceOrders,
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) && (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber'))
   &&
    {
      PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
    },
});

export const actions = {
  deleteSelectedRecords: serviceOrdersActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: serviceOrdersActions.setSelected
}
