// Styles module

export const AppBar	= '#000000';
export const AppBarSelectedItem = '#F47920';
export const AppBarItemHover = '#F47920';
export const AppBarSubMenu	=	'#BBBBBB';
export const MainContainer =	'#FBFBFB';
export const ActionBar	=	'#EEEEEE';
export const MainNavigationMenuSelector	= '#333333';
export const MainNavigationMenuBar = 	'#444444';
export const MainNavigationSubMenu =	'#BBBBBB';
export const ActiveMainNavigationMenuItem = '#BBBBBB';
export const ContentContainer = '#FFFFFF';
export const ContentHeadingRow = '#555555';
export const ContentActionMenu =	'#333333';

export const BackgroundColors = {
  AppBar,
  AppBarSelectedItem,
  AppBarItemHover,
  AppBarSubMenu,
  MainContainer,
  ActionBar,
  MainNavigationMenuSelector,
  MainNavigationMenuBar,
  MainNavigationSubMenu,
  ActiveMainNavigationMenuItem,
  ContentContainer,
  ContentHeadingRow,
  ContentActionMenu
};

export const TextFieldBorder = '#AAAAAA';
export const TextFieldBorderError = '#FF0000';
export const TextFieldBorderFocused = '#347FFE';
export const TextFieldActionButton	= '#CCCCCC';
export const TextFieldActionButtonHover = '#333333';
export const InlineNavigationButton = '#F47920';
export const InlineNavigationButtonHover =	'#333333'
export const ActionBarButton = '#000000';
export const ActionBarButtonHover =	'#F47920';
export const ActionBarButtonDisabled	 = '#AAAAAA';
export const Notification = '#F47920';
export const NotificationError	 = '#FF0000';
export const NotificationActionRequired	= '#25B7D3';

export const ComponentColors = [
  TextFieldBorder,
  TextFieldBorderError,
  TextFieldBorderFocused,
  TextFieldActionButton,
  TextFieldActionButtonHover,
  InlineNavigationButton,
  InlineNavigationButtonHover,
  ActionBarButton,
  ActionBarButtonHover,
  ActionBarButtonDisabled,
  Notification,
  NotificationError,
  NotificationActionRequired
];

export const Heading = '#000000';
export const Label = '#777777';
export const LabelError = '#FF0000';
export const LabelFocused = '#347FFE';
export const Paragraph = '#000000';
export const TextField =	'#000000'
export const PlaceholderText = '#AAAAAA';
export const HeadingContent =	'#FFFFFF';
export const HeadingSection = '#777777';

export const drawerDragControllerBackground = '#F47920';
export const dragExpandIcon = '#FFFFFF';
export const customerMaintenanceDrawerBackground = '#CCCCCC';

export const TextColors = [
  Heading,
  Label,
  LabelError,
  LabelFocused,
  Paragraph,
  TextField,
  PlaceholderText,
  HeadingContent,
  HeadingSection
];

const styles = {
  TextColors,
  ComponentColors,
  BackgroundColors
};

export default styles