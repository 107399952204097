import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { actions as priceScheduleActions, selectors as priceScheduleSelectors } from 'ducks/supplierProductEnquiry/productPriceSchedule';
import { fetch } from 'api/supplierProductEnquiry/productPriceSchedule';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as supplierProductSelectors } from 'ducks/supplierProductEnquiry/supplierProduct';
import { IApplicationState } from 'ducks/reducers';

const getParams = () => new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'SPEProductPriceSchedule',
  columnDefs: priceScheduleSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: priceScheduleSelectors.gridOptions(state),
  filterRow: priceScheduleSelectors.filterRow(state),
  selectedFilters: priceScheduleSelectors.selectedFilters(state),
  appliedFilters: priceScheduleSelectors.filterRow(state) && filterSelectors.getFilters(state, priceScheduleSelectors.filterRow(state).formName),
  reqParams: (supplierProductSelectors.selected(state) || getParams().get('SupplierProductId'))
    &&
  {
    SupplierProductId: (supplierProductSelectors.selected(state) && supplierProductSelectors.selected(state).SupplierProductId) || getParams().get('SupplierProductId')
  },
  keyFields: [{ valueField: 'LoadNumber', routeField: 'LoadNumber' }, { valueField: 'Supplier', routeField: 'Supplier' }, { valueField: 'SupplierProduct', routeField: 'SupplierProduct' }],
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  applyFilters: filterActions.applyFilters,
  setSelectedOrderLine: priceScheduleActions.setSelected
};
