import * as React from 'react';
import { isNull } from 'utils/utils';
import { IConfigurationProperties } from './OutstandingCustomerOrders.properties';
import configurationSchema from './OutstandingCustomerOrders.form.json';
import ChartContainer from 'components/common/Charts/Charts.container';
import DoughnutChart from 'components/common/Charts/DoughnutChart';
import { withStyles } from '@material-ui/core/styles';
import styles from './OutstandingCustomerOrders.styles';
import { IDashboardWidgetOutstandingOrderFacade } from 'api/swaggerTypes';

const Hundred = 100;
const FIXED_VALUE = 2;

const OutstandingCustomerOrders = (props: IConfigurationProperties): JSX.Element => {
    const { classes, widgetInfo, modalFormValues = {}, getOutstandingCustomerOrders, touchFormFields, updateFormField } = props;
    const [filterOpen, setFilterOpen] = React.useState<boolean>(false);
    const [isConfigurationSet, setIsConfigurationSet] = React.useState<boolean>(false);
    const [chartData, setChartData] = React.useState<IDashboardWidgetOutstandingOrderFacade[]>([]);
    const [initValues, setInitValues] = React.useState<any>({});
    const [totalOutstanding, setTotalOutstanding] = React.useState<number>(0);
    const [totalCount, setTotalCount] = React.useState<number>(0);

    React.useEffect(
        () => {
            if (widgetInfo && widgetInfo.Configuration) {
                const configuration = JSON.parse(widgetInfo.Configuration);
                setIsConfigurationSet(true);
                fetchSalesHistory(configuration.Entity, configuration.EntityView);
            }
        },
        [widgetInfo]
    );

    const fetchSalesHistory = (wh, entView) => {
        setInitValues({
            Entity: wh,
            EntityView: entView,
        });
        getOutstandingCustomerOrders({ SalesEntity: wh, EntView: entView })
            .then((res) => {
                const { OutstandingCustomerOrders: OCO } = res;
                const data = OCO && OCO.inlineObject as any;
                setChartData(data.OutstandingOrders);
                setTotalOutstanding(data.TotalValueDisplay);
                setTotalCount(data.TotalCountDisplay);
            }).catch((err) => {
                console.warn(err);
            });

    };

    const validateConfiguration = () => (!isNull(modalFormValues.EntityView) && !isNull(modalFormValues.Entity));

    const handleOkClick = () => {
        if (validateConfiguration()) {
            fetchSalesHistory(modalFormValues.Entity, modalFormValues.EntityView);
            setIsConfigurationSet(true);
            setFilterOpen(false);
        } else {
            touchFormFields('Entity');
            touchFormFields('EntityView');
        }
    };
    const handleCancelClick = () => {
        setFilterOpen(false);
    };

    const customizeTooltip = (arg) => {

        return {
            text: `
            $${arg.point.data.SalesOrderValueDisplay} - ${(arg.percent * Hundred).toFixed(FIXED_VALUE)}%\n
            (Count: ${arg.point.data.SalesOrderCountDisplay})`,
        };
    };

    const customizeLabelText = (arg) => {
        return `$${arg.point.data.SalesOrderValueDisplay}`;
    };
    const onEntityViewChange = () => {
        updateFormField('Entity', '');
    };

    return (<div >
        <ChartContainer
            isConfigurationSet={isConfigurationSet}
            filterOpen={filterOpen}
            title='Outstanding Customer Orders'
            widgetInfo={widgetInfo}
            modalFormProps={{
                formSchema: configurationSchema,
                initialValues: initValues,
                formValues: modalFormValues,
                fieldExtensions: {
                    EntityView: {
                        onSelectedItemChange: onEntityViewChange,
                        onSelectedValueChange: onEntityViewChange
                    },
                    Entity: {
                        params: { ParentView: modalFormValues.EntityView }
                    },
                }
            }}
            setFilterOpen={setFilterOpen}
            modalOkClick={handleOkClick}
            modalCancelClick={handleCancelClick}
            validateConfiguration={validateConfiguration}
        >
            <DoughnutChart chartData={chartData} config={{
                id: 'OutstandingCustomerOrders',
                title: 'Outstanding Customer Orders',
                seriesArgumentField: 'AgeGroup',
                seriesValueField: 'SalesOrderValue',
                customizeTooltip,
                customizeLabelText

            }} />
            <div className={classes.totalContainer}>
                <span>Total outstanding: <b>{`$${totalOutstanding}`}</b></span>
                <span>Total count:<b> {totalCount}</b></span>
            </div>
        </ChartContainer>

    </div >);
};

export default withStyles(styles, { index: 1 })(OutstandingCustomerOrders);
