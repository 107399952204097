import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    getNote: (api) => ({api}),
    setNote: (api, noteData) => ({api, noteData})
  }
}, 'notepad')

let initialState = asyncInitialState({
  note: null
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.getNote:
      return asyncOnRequest(state, action)

    case types.saga.getNote.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          note: action.payload
        }
      })
    case types.saga.getNote.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })

    case types.setNote:
      return asyncOnRequest(state, action)

    case types.saga.setNote.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          note: action.payload
        }
      })

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.common.notepad,
  {
    note: data => data.note,
  }
)

const syncSelector = {
  isLoading: state => state.common.notepad.loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)


