import { connect } from 'react-redux';
import Notepad from './Notepad';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { getFormValues, reset, change } from 'redux-form';
import { Dispatch, bindActionCreators } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';

const params = new URLSearchParams(location.search);
const FORM_NAME = 'PickSlipNotepadForm';

const mapStateToProps = (state: IApplicationState) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    formValues: getFormValues(FORM_NAME)(state),
    operationMode: operationSelectors.operationMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeOperationMode: operationActions.changeOperationMode,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeFieldValue: (field, value) => change(FORM_NAME, field, value),
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notepad);
