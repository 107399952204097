import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  IDataAction,
  IExtendedState
} from '../utils';

export interface IBOMData {
  selected: any;
  gridOptions: IDataGridOptions;
  deleteObj: any;
  Actions: any;
  bomDetails?: any;
  bomSummary?: any;
  lineColumns: IColDef[];
  lines: any[];
}

export interface IBOMState extends IExtendedState<IBOMData> {
  fetchSummary_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clearLines: () => null,
    asyncs: {
      fetch: (Data) => (Data),
      fetchDetails: (Data) => (Data),
      fetchSummary: (Data) => (Data),
    }
  },
  'PSbom');

const initialState = asyncInitialState<IBOMData>({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'SlipLineBOMDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  deleteObj: null,
  Actions: {
  },
  lineColumns: [
    { headerName: 'Line', field: 'BOMLineNumber', minWidth: 100, suppressSorting: true, },
    {
      headerName: 'Product',
      field: 'ProductIdDisplay',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      }
    },
    { headerName: 'Product group', field: 'ProductGroup', minWidth: 120, suppressSorting: true, },
    { headerName: 'Required', field: 'RequiredQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Unit', field: 'Unit', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 150, suppressSorting: true, },
  ],
  lines: [],
  bomDetails: {},
  bomSummary: {}
});

export default (state: IBOMState = initialState, action: IDataAction): IBOMState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetch:
    case types.fetchDetails:
    case types.fetchSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload && successAction.payload.records;

        return {
          ...data,
          lines: linesData
        };
      });
    case types.saga.fetchDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          bomDetails: successAction.payload
        };
      });
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          bomSummary: successAction.payload
        };
      });

    case types.saga.fetch.failure:
    case types.saga.fetchDetails.failure:
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { bom: IBOMState } }) => state.pickSlips.bom,
  {
    lines: (data) => data.lines,
    selected: (data) => data.selected,
    bomDetails: (data) => data.bomDetails,
    bomSummary: (data) => data.bomSummary,
  }
);

const syncSelector = {
  isLoading: (state: { pickSlips: { bom: IBOMState } }) => state.pickSlips.bom.loading,
  gridOptions: (state: { pickSlips: { bom: IBOMState } }) => state.pickSlips.bom.data.gridOptions,
  lineColumns: (state: { pickSlips: { bom: IBOMState } }) => state.pickSlips.bom.data.lineColumns,
  loadingBOMSummary: (state: { pickSlips: { bom: IBOMState } }) => state.pickSlips.bom.fetchSummary_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
