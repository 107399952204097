import { createActions, asyncInitialState, asyncOnRequest, asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, DataAction } from '../utils';
import { pathOr } from 'utils/utils';

export interface ICreditNoteLineData {
  lineDetails: any;
  lineDetailsSchema?: any;
}

export interface ICreditNoteLineState extends IExtendedState<ICreditNoteLineData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      getCreditNoteLine: (data) => (data),
      creditNoteLineLoading: () => null,
      createCreditNoteLine: (data) => data,
      editCreditNoteLine: (data) => (data),
      updateCreditNoteLine: (data) => (data),
      saveCreditNoteLine: (data) => (data),
      cancelCreditNoteLine: (data) => (data),
      deleteCreditNoteLine: (data) => (data),
    },
  },
  'creditNoteLine');

const initialState = asyncInitialState<ICreditNoteLineData>({
  lineDetails: {},
  lineDetailsSchema: {}
});

export default (state: ICreditNoteLineState = initialState, action: DataAction): ICreditNoteLineState => {
  switch (action.type) {
    case types.creditNoteLineLoading:
      return asyncOnRequest(state, action);
    case types.saga.creditNoteLineLoading.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        return {
          ...data,
          lineDetails: { ...pathOr({}, ['payload', 'CreditNoteLine', 'inlineObject'], successAction) },
          lineDetailsSchema: { ...pathOr({}, ['payload', 'CreditNoteLine', 'schema'], successAction) }
        };
      });
    case types.saga.creditNoteLineLoading.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors((state: { creditNotes: { creditNoteLine: ICreditNoteLineState } }) => state.creditNotes.creditNoteLine, {
    lineDetails: (data: ICreditNoteLineData) => data.lineDetails,
    lineDetailsSchema: (data: ICreditNoteLineData) => data.lineDetailsSchema,
  }),
  creditNoteLineError: (state: { creditNotes: { creditNoteLine: ICreditNoteLineState } }) => state.creditNotes.creditNoteLine.error,
  creditNoteLineFetched: (state: { creditNotes: { creditNoteLine: ICreditNoteLineState } }) => state.creditNotes.creditNoteLine.fetched,
  creditNoteLineLoading: (state: { creditNotes: { creditNoteLine: ICreditNoteLineState } }) => state.creditNotes.creditNoteLine.loading
};
