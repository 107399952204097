import * as React from 'react';
import { Route } from 'react-router';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import PurchaseOrderSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/PurchaseOrder';
import BreadcrumbBar from '../common/BreadcrumbBar';
import SummaryPanel from './SummaryPanel';
import * as styles from './PurchaseOrderEnquiry.scss';
import options from './OptionItems.json';
import PurchaseOrderLinesEnquiry from './PurchaseOrderLinesEnquiry';
import BranchOrders from './BranchOrders';
import { shallowCompare } from '../../utils/utils';
import { Operation } from '../../utils/operations';
import PurchaseOrderEnquiry from './MainPurchaseOrderEnquiry';
import Commitments from './Commitments';
import ProductReceipts from './ProductReceipts';
import InTransit from './InTransit';
import Diary from './Diary';
import NotePad from './NotePad';
import { withRouter } from 'react-router-dom';
import { IPurchaseOrderEnquiryViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  saleOrderEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class PurchaseOrderEnquiryView extends React.Component<IPurchaseOrderEnquiryViewProperties> {

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'purchase-order-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    const { pathname, search } = this.getRoute()[selectedTab];
    this.props.history.push({ pathname, search });
    this.fetchChildRecords(selectedTab);
  };

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'purchase-order-enquiry';
    const PurchaseOrder = params.get('PurchaseOrder') || '';
    const LineNumber = params.get('LineNumber') || '';
    const DiaryId = params.get('DiaryId') || '';

    return ({
      OrderDetails: { pathname: `/${context}/order-details`, search: `?PurchaseOrder=${PurchaseOrder}`, component: PurchaseOrderEnquiry },
      BranchOrders: { pathname: `/${context}/branch-orders`, search: `?PurchaseOrder=${PurchaseOrder}`, component: BranchOrders },
      InTransitLocalShipments: { pathname: `/${context}/in-transit/local-shipments`, search: `?PurchaseOrder=${PurchaseOrder}`, component: InTransit },
      InTransitImportShipments: { pathname: `/${context}/in-transit/import-shipments`, search: `?PurchaseOrder=${PurchaseOrder}`, component: InTransit },
      LDInTransitLocalShipments: { pathname: `/${context}/order-lines/in-transit/local-shipments`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      LDInTransitImportShipments: { pathname: `/${context}/order-lines/in-transit/import-shipments`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      OrderLines: { pathname: `/${context}/order-lines`, search: `?PurchaseOrder=${PurchaseOrder}`, component: PurchaseOrderLinesEnquiry },
      LineDetails: { pathname: `/${context}/order-lines/line-details`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      LDCommittedSalesOrder: { pathname: `/${context}/order-lines/line-details/committed-sales-orders`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      LDCommittedProductionOrder: { pathname: `/${context}/order-lines/line-details/committed-production-orders`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      LDCommittedServiceOrder: { pathname: `/${context}/order-lines/line-details/committed-service-orders`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      LDBranchOrders: { pathname: `/${context}/order-lines/line-details/branch-orders`, search: `?PurchaseOrder=${PurchaseOrder}&LineNumber=${LineNumber}`, component: PurchaseOrderLinesEnquiry },
      CommittedSaleOrder: { pathname: `/${context}/commitments/committed-sales-orders`, search: `?PurchaseOrder=${PurchaseOrder}`, component: Commitments },
      CommittedProductionOrder: { pathname: `/${context}/commitments/committed-production-order`, search: `?PurchaseOrder=${PurchaseOrder}`, component: Commitments },
      CommittedServiceOrders: { pathname: `/${context}/commitments/committed-service-orders`, search: `?PurchaseOrder=${PurchaseOrder}`, component: Commitments },
      ProductReceipts: { pathname: `/${context}/product-receipts`, search: `?PurchaseOrder=${PurchaseOrder}`, component: ProductReceipts },
      SalesDiary: { pathname: `/${context}/purchase-diary`, search: `?PurchaseOrder=${PurchaseOrder}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/purchase-diary/diary-details`, search: `?PurchaseOrder=${PurchaseOrder}&DiaryId=${DiaryId}`, component: Diary },
      Notepad: { pathname: `/${context}/notepad`, search: `?PurchaseOrder=${PurchaseOrder}`, component: NotePad },
      null: { pathname: `/${context}`, search: `?PurchaseOrder=${PurchaseOrder}`, component: PurchaseOrderEnquiry }
    });
  }

  handleOrderChange = () => {
    const { selectedTab } = this.props;
    this.fetchChildRecords(selectedTab);
  };

  fetchChildRecords = (selectedTab: string) => {
    if (typeof this[`get${selectedTab}`] === 'function') {
      this[`get${selectedTab}`]();
    }
    switch (selectedTab) {
      case 'LDCommittedSalesOrder':
      case 'LDCommittedProductionOrder':
      case 'LDCommittedServiceOrder':
      case 'LDBranchOrders':
      case 'LDInTransitLocalShipments':
      case 'LDInTransitImportShipments':
        this.getOrderLineSummary();
        break;
      case 'BranchOrders':
        this.getBranchOrdersSummary();
        break;
      default: return;
    }
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  };

  shouldComponentUpdate(nextProps: IPurchaseOrderEnquiryViewProperties, nextState: any): boolean {

    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: IPurchaseOrderEnquiryViewProperties): void {
    const context = 'purchase-order-enquiry';
    const { operationMode } = this.props;
    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) {
      this.handleModuleChange(this.props.selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm(context, this.props.selectedTab);
    }
    if ((this.props.selectedOrder && !prevProps.selectedOrder) || ((this.props.selectedOrder && prevProps.selectedOrder) && (this.props.selectedOrder.PurchaseOrder !== prevProps.selectedOrder.PurchaseOrder))) {
      this.props.fetchOrderSummary(this.props.selectedOrder.PurchaseOrder);
    }
  }

  getOrderDetails = () => {
    const { selectedOrder } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const PurchaseOrder = (selectedOrder && selectedOrder.PurchaseOrder) || params.get('PurchaseOrder');
    if (PurchaseOrder) {
      this.props.getOrderDetails(PurchaseOrder);
    }
  };

  getBranchOrdersSummary = () => {
    const { selectedOrder } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const PurchaseOrder = (selectedOrder && selectedOrder.PurchaseOrder) || params.get('PurchaseOrder');
    if (PurchaseOrder) {
      this.props.fetchBranchOrderSummary(PurchaseOrder);
    }
  };

  getLineDetails = () => {
    const { selectedOrder, selectedOrderLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const PurchaseOrder = (selectedOrder && selectedOrder.PurchaseOrder) || params.get('PurchaseOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (PurchaseOrder && LineNumber) {
      const query = { PurchaseOrder: PurchaseOrder, LineNumber: LineNumber };
      this.props.getOrderLineDetails(query);
      this.getOrderLineSummary();
    }
  };

  getOrderLineSummary = () => {
    const { selectedOrder, selectedOrderLine, orderLineSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const PurchaseOrder = (selectedOrder && selectedOrder.PurchaseOrder) || params.get('PurchaseOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (PurchaseOrder && LineNumber
      && (!orderLineSummary
        || (orderLineSummary.values
          && orderLineSummary.values.LineNumber !== parseInt(LineNumber)))) {
      const query = { PurchaseOrder: PurchaseOrder, LineNumber: LineNumber };
      this.props.getOrderLineSummary(query);
    }
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'OrderDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('OrderDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  };

  render(): React.ReactNode {
    const { isMenuOptionOpen } = this.props;
    const selectedTab = this.props.selectedTab || 'OrderDetails';

    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.saleOrderEnquiryOuter} >
        <div className={styles.saleOrderEnquiryContainer}>
          <PurchaseOrderSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              () => { this.fetchChildRecords(selectedTab); }
            ]}
          />
          <div
            style={inlineStyles.saleOrderEnquirySection}
            className={styles.saleOrderEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Purchase Order Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} />;
                }}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={(selectedTab === 'LineDetails'
            || selectedTab === 'LDCommittedServiceOrder'
            || selectedTab === 'LDCommittedSalesOrder'
            || selectedTab === 'LDCommittedProductionOrder'
            || selectedTab === 'LDInTransitLocalShipments'
            || selectedTab === 'LDInTransitImportShipments'
            || selectedTab === 'LDBranchOrders')
            ? options.LineDetails
            : options.OrderDetails}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'sales-order-enquiry'} />
      </div>
    );
  }
}

export default withRouter(PurchaseOrderEnquiryView);
