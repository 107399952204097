import * as React from 'react';
import DataGrid from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IWorksaleLinesHandle, IWorksaleLinesProps } from './WorksaleLines.properties';
import { IWorksaleGridLineDetailFacade } from 'api/swaggerTypes';
import styles from './WorksaleLines.styles';
import { useSPDispatch } from 'components/Worksale/Worksale.context';
import { SalesProcessingActionsTypes } from 'components/Worksale/Worksale.actions';
import DynamicDataGrid from 'components/common/DataGridDevEx/DynamicDataGrid';
import { getColumns } from './constants';

function WorksaleLines(props: Readonly<IWorksaleLinesProps>, ref: React.Ref<IWorksaleLinesHandle>): JSX.Element {
    const { saleLinesDataSource, classes, className, onSaving, showDetailOnClick, onSaleLineFieldChanged, siteHeaderFilter, onDelete } = props;

    const dataGridRef = React.useRef<DataGrid>();
    const contextDispatch = useSPDispatch();
    const columns = React.useMemo(
        () => getColumns(
            {
                SiteCode: siteHeaderFilter
            },
            classes
        ),
        [siteHeaderFilter]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            cancelRowEditing: () => {
                if (dataGridRef.current) {
                    dataGridRef.current.instance.cancelEditData();
                }
            },
            refreshDataGrid: () => {
                if (dataGridRef.current) {
                    dataGridRef.current.instance.refresh(true);
                }
            }
        })
    );

    function navigateToInsertedKey(values: any): void {
        setTimeout(
            () => {
                dataGridRef.current.instance.navigateToRow(values.WorksalelineId);
            },
            0);
    }

    async function deleteClick(key: number, data: IWorksaleGridLineDetailFacade): Promise<void> {
        if (onDelete) {
            onDelete(key, data);
        }
    }

    React.useEffect(
        () => {
            saleLinesDataSource.store().on('inserted', navigateToInsertedKey);

            return () => { saleLinesDataSource.store().off('inserted', navigateToInsertedKey); };
        },
        [saleLinesDataSource]);

    const detailOnClick = (allProps) => {
        const disableLine = allProps.data && allProps.data.LineType === 'r';

        if (!disableLine && showDetailOnClick) {
            showDetailOnClick(allProps.data ? allProps.data : null);
        }
    };

    const onEditorPreparing = (e) => {
        if (e.dataField === 'Description' && e.row.data.IsEnableDescription) {
            e.editorOptions.disabled = false;
        }
        if (e.dataField === 'SuppliedQuantity') {
            e.editorOptions.disabled = !e.row.data.IsEnableSupplied;
        }
        if (e.dataField === 'KittingQuantity') {
            //temporarily disabled until the lost sale functioanlity is implemented
            e.editorOptions.disabled = true; //e.row.data.LineType !== 'k';
        }
        if (e.dataField === 'Discount') {
            e.editorOptions.format = "#0.00 '%'";
        }
        contextDispatch({ [SalesProcessingActionsTypes.setLineEdit]: true });
    };

    const handleOnSaving = (e) => {
        e.cancel = true;
        const rowData = (e.changes && e.changes.length) ? e.changes[0].data : null;
        if (rowData) {
            onSaving(rowData);
        } else {
            dataGridRef.current.instance.cancelEditData();
        }
        contextDispatch({ [SalesProcessingActionsTypes.setLineEdit]: false });
    };

    const onEditCanceled = () => { contextDispatch({ [SalesProcessingActionsTypes.setLineEdit]: false }); };

    return (
        <Paper className={classNames(classes.parentContainer, className)} >
            <DynamicDataGrid
                dataGridClassName={classes.worksaleLineDataGrid}
                onDelete={deleteClick}
                calculateFieldValue={onSaleLineFieldChanged}
                columns={columns}
                allowHeaderFilting={true}
                allowUpdating={true}
                gridProps={{
                    dataSource: saleLinesDataSource,
                    dataGridRef: dataGridRef,
                    onEditorPreparing: onEditorPreparing,
                    onSaving: handleOnSaving,
                    hoverStateEnabled: true,
                    onEditCanceled: onEditCanceled
                }}
                detailOnClick={detailOnClick}
            >
            </DynamicDataGrid >
        </Paper >
    );
}

export default withStyles(styles, { index: 1 })(React.forwardRef(WorksaleLines));
