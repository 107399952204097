import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (id) => ({ id }),
  asyncs: {
    fetchCustomerPricingSummary: ({ CustomerId }) => ({ CustomerId }),
  }
}, 'pricing')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,

  gridOptions: {
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Product price code', field: 'ProductPriceCode' },
    { headerName: 'Description', field: 'ProductCodeDescription', suppressSorting: true },
    { headerName: 'Type', field: 'Type', suppressSorting: true },
    { headerName: 'Percent', field: 'PercentDisplay', type: 'numericColumn', suppressSorting: true }
  ],
  detailRowHeight: 380,
  detailCellRendererParams: {
    detailGridOptions: {
      columnDefs: [
        { headerName: 'Break level', field: 'BreakLevel', suppressSorting: true },
        { headerName: 'Quantity', field: 'BreakQuantity', type: 'numericColumn', suppressSorting: true },
        { headerName: 'Percent', field: 'BreakPercentDisplay', type: 'numericColumn', suppressSorting: true }
      ],
    },
    getDetailRowData: (row) => row.data.Breaklevels
  },
  list: [],
  summary: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.fetchCustomerPricingSummary:
      return asyncOnRequest(state, action)

    case types.saga.fetchCustomerPricingSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          summary: action.payload
        }
      })
    case types.saga.fetchCustomerPricingSummary.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.pricing,
  {
    list: data => data.list || [],
    selected: data => (data.list && data.selected) || null,
    summary: data => data.summary
  }
)

const syncSelector = {
  loadingCustomerPricingDetails: state => state.customerEnquiry.pricing.fetchCustomerPricingDetails_loading,
  loadingCustomerPricingSummary: state => state.customerEnquiry.pricing.fetchCustomerPricingSummary_loading,
  gridOptions: state => state.customerEnquiry.pricing.data.gridOptions,
  columns: state => state.customerEnquiry.pricing.data.columns,
  detailColumns: state => state.customerEnquiry.pricing.data.detailCellRendererParams,
  detailRowHeight: (state) => state.customerEnquiry.pricing.data.detailRowHeight,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

