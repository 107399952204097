import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../utils';
export const fetch = async (query: { CustomerId: number; Page: number; Sort: string; SortDirection: string; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { CustomerId, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchPost(
    `/CustomerEnquiry/ChildAccounts/Customer/${CustomerId}`, {},
    {
      BatchPage: Page,
      BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`
    },
    respType)
    .then(
      (result: {
        ChildAccounts: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.ChildAccounts) {

            return {
              records: result.ChildAccounts.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
