import { APIMethod, Payload, prepareUrl, useFetch, useFetchMutation, usePost } from 'api/reactQuery/queryService';
import {
    ICalculateFreightChargeResponse, IDeleteRecordResponse, IDespatchConfirmationDetailsResponse, IDespatchConfirmationFacade,
    IDespatchSurchargeFacade, IDespatchSurchargeResponse, ILostsaleFacade, IPickSlipContactDetailFacade,
    IPickSlipContactDetailsResponse, IPickSlipDetailsFacade, IPickSlipLineDetailsFacade, IPickSlipLineDetailsFacadeResponse,
    IPickSlipLostsaleResponse, IPickSlipShippingDetailFacade, IPickSlipShippingDetailsResponse, IPickSlipTransactionDetailResponse,
    IProcessDespatchCriteriaFacade, IProcessPickSlipDetailResponse, IRemovePickSlipCriteriaFacade, IRemovePickSlipResponse,
    IValidatedResponse
} from 'api/swaggerTypes';
import { APIRoutes } from './constants';
import { AsObjectified, IObjectified, Inline, objectify } from 'api/utils';
import { toObjectified } from 'api/baseApi';
import { useQueryClient } from '@tanstack/react-query';

export type IObjectifiedPickSlipDetailResponse = AsObjectified<IPickSlipTransactionDetailResponse, 'PickSlip'>;

const pickSlipDetailResponseParser = (response) => {
    const { PickSlip } = response;

    if (PickSlip) {
        const objectifiedResult = toObjectified(PickSlip as Inline<IPickSlipDetailsFacade>);

        return {
            ...response,
            PickSlip: { ...objectifiedResult, schema: { ...objectifiedResult.schema, address: { ReadOnly: PickSlip.Address1.ReadOnly } } }
        };
    }

    return {
        ...response
    };
};

export const useFetchPickSlipDetail = (DespatchId: number) =>
    useFetch<IObjectifiedPickSlipDetailResponse>(
        {
            url: APIRoutes.pickSlips.detail.retrieve,
            urlParams: { DespatchId },
            queryConfig: { enabled: Boolean(DespatchId) },
            options: { inline: true, responseParser: pickSlipDetailResponseParser }
        }
    );

export const useCalculatePickSlipDetail = () =>
    usePost<Payload<IPickSlipDetailsFacade, { ChangedField: string }>, IObjectifiedPickSlipDetailResponse>
        (APIRoutes.pickSlips.detail.calculate, null, { inline: true, responseParser: pickSlipDetailResponseParser });

export const useUpdatePickSlipDetail = () =>
    usePost<Payload<IPickSlipDetailsFacade>, Omit<IObjectifiedPickSlipDetailResponse, 'PickSlip'>>
        (APIRoutes.pickSlips.detail.update, null, { inline: true, responseParser: pickSlipDetailResponseParser });

export const useSetPickSlipDetailsQueryData = () => {
    const queryClient = useQueryClient();

    return (DespatchId: number, dataToUpdate: IObjectifiedPickSlipDetailResponse) => {
        const finalUrl = prepareUrl(APIRoutes.pickSlips.detail.retrieve, { DespatchId }, null, true);
        queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
    };
};

type IObjectifiedPickSlipShippingDetailResponse = AsObjectified<IPickSlipShippingDetailsResponse, 'PickSlipShippingDetails'>;

const pickSlipShippingDetailResponseParser = (response) => ({
    ...response,
    PickSlipShippingDetails: response?.PickSlipShippingDetails ? toObjectified(response?.PickSlipShippingDetails as Inline<IPickSlipShippingDetailFacade>) : null,
});

export const useRetrievePickSlipShippingDetails = (DespatchId: number) =>
    useFetch<IObjectifiedPickSlipShippingDetailResponse>(
        {
            url: APIRoutes.pickSlips.shipping.retrieve,
            urlParams: { DespatchId }, queryConfig: {},
            options: { inline: true, responseParser: pickSlipShippingDetailResponseParser }
        }
    );

export const useSetShippingQueryData = () => {
    const queryClient = useQueryClient();

    return (DespatchId: number, dataToUpdate: IObjectifiedPickSlipShippingDetailResponse) => {
        const finalUrl = prepareUrl(APIRoutes.pickSlips.shipping.retrieve, { DespatchId }, null, true);
        queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
    };
};

export const useCalculateShipping = () =>
    usePost<Payload<IPickSlipShippingDetailFacade, { ChangedField: string }>, IObjectifiedPickSlipShippingDetailResponse>
        (APIRoutes.pickSlips.shipping.calculate, null, { inline: true, responseParser: pickSlipShippingDetailResponseParser });

export const useUpdateShipping = () =>
    usePost<Payload<IPickSlipShippingDetailFacade>, IObjectifiedPickSlipShippingDetailResponse>
        (APIRoutes.pickSlips.shipping.update, null, { inline: true, responseParser: pickSlipShippingDetailResponseParser });

type IObjectifiedPickSlipLineDetailResponse = Omit<IPickSlipLineDetailsFacadeResponse, 'PickSlipLineDetails'> & {
    PickSlipLineDetails: IObjectified<IPickSlipLineDetailsFacade>;
};

const pickSlipLineDetailResponseParser = (response) => ({
    ...response,
    PickSlipLineDetails: response?.PickSlipLineDetails ? toObjectified(response?.PickSlipLineDetails as Inline<IPickSlipLineDetailsFacade>) : null
});

export const useRetrievePickSlipLineDetails = () =>
    useFetchMutation<Payload<{ DespatchId?: number; LineNumber: number }, { DespatchLineId: number }>, IObjectifiedPickSlipLineDetailResponse>(
        APIRoutes.pickSlips.lines.detail.retrieve,
        null,
        {
            inline: true,
            responseParser: pickSlipLineDetailResponseParser
        }
    );

export const useCancelPickSlipLineDetails = () =>
    usePost<Payload<{ DespatchLineId: number }>, IValidatedResponse>(
        APIRoutes.pickSlips.lines.detail.cancel,
        null,
        { inline: true }
    );

type IObjectifiedRemovePickSlipResponse = Omit<IRemovePickSlipResponse, 'RemovePickSlipCriteria'> & {
    RemovePickSlipCriteria: IObjectified<IRemovePickSlipCriteriaFacade>;
};

const removePickSlipResponseParser = (response) => ({
    ...response,
    RemovePickSlipCriteria: response?.RemovePickSlipCriteria ? toObjectified(response?.RemovePickSlipCriteria as Inline<IRemovePickSlipCriteriaFacade>) : null
});

export const useRemovePickSlip = () =>
    usePost<Payload<IRemovePickSlipCriteriaFacade & { DespatchId: number }>, IObjectifiedRemovePickSlipResponse>(
        APIRoutes.pickSlips.remove,
        null,
        { inline: true, responseParser: removePickSlipResponseParser }
    );

const lostSaleResponseParser = (response): IObjectifiedPickSlipLostsaleResponse => {
    const { Lostsale } = response;

    return {
        ...response,
        Lostsale: Lostsale ? toObjectified(Lostsale) : null
    };
};

type IObjectifiedPickSlipLostsaleResponse = Omit<IPickSlipLostsaleResponse, 'Lostsale'> & {
    Lostsale: IObjectified<ILostsaleFacade>;
};

export const useInitializeLostSale = () =>
    useFetchMutation<Payload<{ DespatchId?: number }>, IObjectifiedPickSlipLostsaleResponse>(
        APIRoutes.pickSlips.initializeLostSale,
        null,
        {
            inline: true,
            responseParser: lostSaleResponseParser
        }
    );

type IObjectifiedPickSlipContactDetailsResponse = AsObjectified<IPickSlipContactDetailsResponse, 'PickSlipContactDetails'>;

const pickSlipContactDetailsResponseParser = (response) => ({
    ...response,
    PickSlipContactDetails: response?.PickSlipContactDetails ? toObjectified(response?.PickSlipContactDetails as Inline<IPickSlipContactDetailFacade>) : null,
});

export const useRetrievePickSlipContactDetails = (DespatchId: number) =>
    useFetch<IObjectifiedPickSlipContactDetailsResponse>(
        {
            url: APIRoutes.pickSlips.contact.retrieve,
            urlParams: { DespatchId }, queryConfig: {},
            options: { inline: true, responseParser: pickSlipContactDetailsResponseParser }
        }
    );

const pickSlipConfirmationResponseParser = (response) => ({
    ...response,
    DespatchConfirmation: response?.DespatchConfirmation ? toObjectified(response?.DespatchConfirmation as Inline<IDespatchConfirmationFacade>) : null,
});
type IObjectifiedPickSlipConfirmationResponse = AsObjectified<IDespatchConfirmationDetailsResponse, 'DespatchConfirmation'>;

export const usePickSlipConfirmation = () =>
    usePost<Payload<{ DespatchId?: number; processWMSDespatch?: boolean }>, IObjectifiedPickSlipConfirmationResponse>(
        APIRoutes.pickSlips.confirmation,
        null,
        { inline: true, responseParser: pickSlipConfirmationResponseParser }
    );

export type ObjectifiedProcessPickSlip = Omit<IProcessDespatchCriteriaFacade, 'FreightCharge' | 'SalesSurcharge' | 'DespatchDetail'> & {
    FreightCharge: IObjectified<IProcessDespatchCriteriaFacade['FreightCharge']>;
    SalesSurcharge: IObjectified<IProcessDespatchCriteriaFacade['SalesSurcharge']>;
    DespatchDetail: IObjectified<IProcessDespatchCriteriaFacade['DespatchDetail']>;
};

export type IObjectifiedProcessPickSlipResponse = Omit<IProcessPickSlipDetailResponse, 'ProcessDespatchCriteria'> & {
    ProcessDespatchCriteria: IObjectified<ObjectifiedProcessPickSlip>;
};

const processPickSlipResponseParser = (response): IObjectifiedProcessPickSlipResponse => ({
    ...response,
    ProcessDespatchCriteria: {
        inlineObject: {
            ...response?.ProcessDespatchCriteria ? objectify(response?.ProcessDespatchCriteria) : null,
            FreightCharge: response?.ProcessDespatchCriteria?.FreightCharge ? toObjectified(response?.ProcessDespatchCriteria?.FreightCharge) : null,
            SalesSurcharge: response?.ProcessDespatchCriteria?.SalesSurcharge?.Surcharge ? toObjectified(response?.ProcessDespatchCriteria?.SalesSurcharge) : null,
            DespatchDetail: response?.ProcessDespatchCriteria?.DespatchDetail?.DespatchId ? toObjectified(response?.ProcessDespatchCriteria?.DespatchDetail) : null
        },
        schema: response?.ProcessDespatchCriteria
    },
});

export const useProcessPickSlip = () =>
    usePost<Payload<IProcessDespatchCriteriaFacade>, IObjectifiedProcessPickSlipResponse>(
        APIRoutes.pickSlips.process,
        null,
        { inline: true, responseParser: processPickSlipResponseParser }
    );

export type IObjectifiedDespatchSurchargeResponse = AsObjectified<IDespatchSurchargeResponse, 'DespatchSurcharge'>;

const calculateSurchargeResponseParser = (response) => ({
    ...response,
    DespatchSurcharge: response?.DespatchSurcharge ? toObjectified(response?.DespatchSurcharge as Inline<IDespatchSurchargeFacade>) : null,
});

export const useCalculateSurcharge = () =>
    usePost<Payload<IDespatchSurchargeFacade & { DespatchId: number }>, IObjectifiedDespatchSurchargeResponse>(
        APIRoutes.pickSlips.calculateSurcharge,
        null,
        { inline: true, responseParser: calculateSurchargeResponseParser }
    );

const calculateFreightResponseParser = (response) => ({
    ...response,
    FreightCharge: response?.FreightCharge ? toObjectified(response?.FreightCharge as Inline<IDespatchSurchargeFacade>) : null,
});

export type IObjectifiedDespatchFreightResponse = AsObjectified<ICalculateFreightChargeResponse, 'FreightCharge'>;

export const useCalculateFreight = () =>
    usePost<Payload<IDespatchSurchargeFacade & { DespatchId: number }>, IObjectifiedDespatchFreightResponse>(
        APIRoutes.pickSlips.calculateFreight,
        null,
        { inline: true, responseParser: calculateFreightResponseParser }
    );
export const useRemoveInvoiceChargeLines = () =>
    usePost<Payload<{ InvoiceNumber: number; SalesEntity: string }, { FreightLineNumber: number }>, IDeleteRecordResponse>(
        APIRoutes.pickSlips.removeChargeLines,
    );
