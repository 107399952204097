import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as invoiceSelector } from 'ducks/salesInvoiceEnquiry/Invoice'
import { selectors as slipSelectors, actions as slipActions } from 'ducks/salesInvoiceEnquiry/slips';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'
import { search } from 'api/salesInvoiceEnquiry/pickSlipEnquiries';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'PickSlipsByProduct',
  columnDefs: slipSelectors.byProductColumns(state),
  rowData: slipSelectors.lines(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: slipSelectors.gridOptions(state),
  isLoading: slipSelectors.isLoading(state),
  filterRow: slipSelectors.filterRow(state),
  selectedFilters: slipSelectors.selectedFilters(state),
  appliedFilters: slipSelectors.filterRow(state) && filterSelectors.getFilters(state, slipSelectors.filterRow(state).form),
  reqParams: (invoiceSelector.selected(state) || params.get('SalesEntity') || params.get('InvoiceNumber'))
    &&
  {
    SalesEntity: (invoiceSelector.selected(state) && invoiceSelector.selected(state).SalesEntity) || params.get('SalesEntity'),
    InvoiceNumber: (invoiceSelector.selected(state) && invoiceSelector.selected(state).Invoice) || params.get('InvoiceNumber')
  },
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: slipActions.setSelected,
  applyFilters: filterActions.applyFilters
}
