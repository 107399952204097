import ProductPriceSchedule from 'utils/data/forms/supplier_product_enquiry/ProductPriceSchedule';
import ProductLinks from 'utils/data/forms/supplier_product_enquiry/ProductLinks';
import Diary from 'utils/data/forms/supplier_product_enquiry/Diary';
import DiaryDetails from 'utils/data/forms/supplier_product_enquiry/DiaryDetails';
import Notepad from 'utils/data/forms/supplier_product_enquiry/Notepad';
import ProductPriceScheduleDetails from 'utils/data/forms/supplier_product_enquiry/ProductPriceScheduleDetails';
import ProductPriceScheduleSellPrices from 'utils/data/forms/supplier_product_enquiry/ProductPriceScheduleSellPrices';
import ProductPriceScheduleSettings from 'utils/data/forms/supplier_product_enquiry/ProductPriceScheduleSettings';
import ProductPriceScheduleComments from 'utils/data/forms/supplier_product_enquiry/ProductPriceScheduleComments';
import Documents from 'utils/data/forms/supplier_product_enquiry/attachments/Documents';
import Links from 'utils/data/forms/supplier_product_enquiry/attachments/Links';
import Images from 'utils/data/forms/supplier_product_enquiry/attachments/Images';

const getFormSchema = (formId: string) => {
    switch (formId) {
        case 'ProductPriceSchedule':
            return ProductPriceSchedule;
        case 'ProductLinks':
            return ProductLinks;
        case 'SalesDiary':
            return Diary;
        case 'DiaryDetails':
            return DiaryDetails;
        case 'Notepad':
            return Notepad;
        case 'ProductPriceScheduleDetails':
            return ProductPriceScheduleDetails;
        case 'ProductPriceScheduleSellPrices':
            return ProductPriceScheduleSellPrices;
        case 'ProductPriceScheduleSettings':
            return ProductPriceScheduleSettings;
        case 'ProductPriceScheduleComments':
            return ProductPriceScheduleComments;
        case 'Documents':
            return Documents;
        case 'Links':
            return Links;
        case 'Images':
            return Images;
        default:
            return null;
    }
};

export default getFormSchema;
