const options = {
    PS: [
        {
            label: 'Pick Slips',
            value: 'PickSlips',
            SelectionDisabled: true,
            children: [
                {
                    label: 'Lines',
                    value: 'Lines'
                },
                {
                    label: 'Pick Slip Details',
                    value: 'Details'
                },
                {
                    label: 'Contact',
                    value: 'Contact'
                },
                {
                    label: 'Diary',
                    value: 'SalesDiary'
                },
                {
                    label: 'Notepad',
                    value: 'SalesNotepad'
                }
            ]
        }
    ],
};

export default options;
