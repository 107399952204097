import { combineReducers } from 'redux';

import documents, { IDocumentsState } from './documents';
import links, { ILinksState } from './links';
import images, { IImagesState } from './images';

export interface ISupplierProductAttachmentsState {
  documents: IDocumentsState;
  links: ILinksState;
  images: IImagesState;
}

const customerEnquiryReducer = combineReducers<ISupplierProductAttachmentsState>({
  documents,
  links,
  images
});

export default customerEnquiryReducer;
