import React from 'react';
import PurchaseOrderLineDetailsForm from './PurchaseOrderLineDetails.form';
import summaryTableSchema from './PurchaseOrderLineDetails.summaryTable.schema';
import ILineDetailsProperties, { IPurchaseOrderLineDetailsHandle } from './PurchaseOrderLineDetails.properties';
import FormView from 'components/FormView/FormView';
import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import SummaryTable from 'components/common/SummaryTable';
import innerStyles from './PurchaseOrderLineDetails.styles';
import { useCalculatePurchaseOrderLineDetail, useRetrievePurchaseOrderLineDetails, useSavePurchaseOrderLineDetails } from 'api/Worksale/backorderCommitment';
import { withStyles } from '@material-ui/core/styles';
import { PurchaseOrderLineDetailsNonCalculationFields } from '../../constants';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { isNull } from 'utils/utils';
import { isSuccess } from 'api/utils';

const PurchaseOrderLineDetails = (props: ILineDetailsProperties, ref: React.Ref<IPurchaseOrderLineDetailsHandle>) => {
    const { formValues, supplier, data, changeSelectedTab } = props;

    const params = new URLSearchParams(location.search);
    const PurchaseOrderId = Number(params.get('PurchaseOrderId') ?? '');
    const PurchaseOrderLineId = Number(params.get('PurchaseOrderLineId') ?? '');

    const fetchPurchaseOrderLineDetailsMutation = useRetrievePurchaseOrderLineDetails();
    const calculatePurchaseOrderLineDetail = useCalculatePurchaseOrderLineDetail();
    const savePurchaseOrderLineDetails = useSavePurchaseOrderLineDetails();

    const [detailData, setDetailData] = React.useState(data);

    const purchaseOrderLineDetailsSummary = React.useMemo(() => detailData?.PurchaseOrderLineSummary?.inlineObject, [detailData]);
    const purchaseOrderLineDetailInlineObject = React.useMemo(() => detailData?.PurchaseOrderLineDetail?.inlineObject, [detailData]);

    const fetchAndSetLineDetails = React.useCallback(
        async () => {
            if (isNull(data)) {
                const response = await fetchPurchaseOrderLineDetailsMutation.mutateAsync({
                    PurchaseOrderLineId
                });
                if (isSuccess(response)) {
                    setDetailData(response);
                } else {
                    changeSelectedTab('PurchaseOrderLines');
                }
            }
        },
        [data, PurchaseOrderLineId]
    );

    React.useEffect(
        () => {
            if (!PurchaseOrderLineId) {
                changeSelectedTab('PurchaseOrderLines');

                return;
            }

            fetchAndSetLineDetails();
        },
        [PurchaseOrderLineId, fetchAndSetLineDetails]
    );

    const { setActionBar } = React.useContext(ActionBarContext);

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        []
    );

    const rightIcons = [
        {
            label: 'Cancel',
            Icon: CancelIcon,
            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
            action: 'PurchaseOrderLineDetailsCancel',
        },
        {
            label: 'OK',
            Icon: CheckCircle,
            action: 'PurchaseOrderLineDetailsOk',
        },
    ];

    React.useImperativeHandle(
        ref,
        () => ({
            async onOK(handleModuleChange: (page: string) => void): Promise<void> {
                if (!PurchaseOrderLineId || !PurchaseOrderId) {
                    return;
                }

                const response = await savePurchaseOrderLineDetails.mutateAsync({ ...formValues, PurchaseOrderLineId, PurchaseOrderId });
                if (isSuccess(response)) {
                    showSnackBar({ variant: 'success', message: 'Line Details successfully updated.' });
                    handleModuleChange('PurchaseOrderLines');
                }
            },
            onCancel(handleModuleChange: (page: string) => void): void {
                handleModuleChange('PurchaseOrderLines');
            },
        }),
        [PurchaseOrderLineId, PurchaseOrderId, formValues]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons
        });
    };
    const hasValueUpdate = (fieldName: string, value?: any) => (value !== (purchaseOrderLineDetailInlineObject?.hasOwnProperty(`${fieldName}Display`) ?
        purchaseOrderLineDetailInlineObject?.[`${fieldName}Display`] : purchaseOrderLineDetailInlineObject?.[fieldName]));

    const calculateLineSummary = React.useCallback(
        async (fieldName: string, value?: any) => {
            if (PurchaseOrderLineId && hasValueUpdate(fieldName, value)) {
                const response = await calculatePurchaseOrderLineDetail.mutateAsync({
                    ...formValues,
                    [fieldName]: value,
                    PurchaseOrderLineId,
                    urlQueryParams: {
                        ChangedFieldId: fieldName
                    }
                });
                if (response?.Status === true) {
                    setDetailData(response);
                }
            }
        },
        [PurchaseOrderLineId, formValues]
    );

    const handleBuyingUnitOnChange = React.useCallback(
        async (item) => {
            if (item?.Code && purchaseOrderLineDetailInlineObject?.UnitBuy !== item?.Code) {
                await calculateLineSummary('UnitBuy', item?.Code);
            }
        },
        [calculateLineSummary]
    );

    const formViewOnBlur = React.useCallback(
        async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
            const fieldName = e?.target?.name;
            const fieldValue = e?.target?.value;
            if (PurchaseOrderLineDetailsNonCalculationFields.includes(fieldName)) {
                return;
            }

            if (fieldName) {
                calculateLineSummary(fieldName, fieldValue);
            }
        },
        [PurchaseOrderLineId, formValues, calculateLineSummary]
    );

    const formViewToogleOnChange = React.useCallback(
        async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
            const fieldName = e?.target?.name;
            const fieldValue = e?.target?.checked;

            if (fieldName) {
                calculateLineSummary(fieldName, fieldValue);
            }
        },
        [PurchaseOrderLineId, formValues, calculateLineSummary]
    );

    return (
        <div style={innerStyles.container}>
            <FormView
                hidden={false}
                isLoading={false}
                supplierId={Number(supplier)}
                formName={'PurchaseOrderLineDetailsForm'}
                schema={PurchaseOrderLineDetailsForm}
                onBlur={formViewOnBlur}
                includeTabsHeight={false}
                initialValues={purchaseOrderLineDetailInlineObject}
                valuesSchema={detailData?.PurchaseOrderLineDetail?.schema}
                operationMode={'EDIT'}
                summaryTableRenderer={() => summaryTableSchema ?
                    (<SummaryTable loadingWorksaleLineDetailsSummary={false}
                        selectedWorksaleLine={purchaseOrderLineDetailsSummary}
                        data={summaryTableSchema}
                    />) : null}
                fieldExtensions={{
                    UnitBuy: {
                        onSelectedItemChange: handleBuyingUnitOnChange,
                    },
                    SpecialPrice: {
                        onChange: formViewToogleOnChange
                    },
                    Estimated: {
                        onChange: formViewToogleOnChange
                    }
                }}
            />
        </div>);
};

export default withStyles(innerStyles, { index: 1 })(React.forwardRef(PurchaseOrderLineDetails));
