import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as balancesTypes, actions as balancesActions } from 'ducks/customerEnquiry/balances';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/balances';

function* fetch(action) {
  const { success, failure } = balancesActions.saga.fetch;
  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}
export default function* rootAttributeSaga() {
  yield takeLatest(balancesTypes.fetch, fetch)
}