import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as supplierTypes, actions as supplierActions } from 'ducks/supplierEnquiry/supplier';

import { callApi } from '../utils';
import * as api from 'api/supplierEnquiry/supplier';


function* search(action){
  const {success, failure} = supplierActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = supplierActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = supplierActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = supplierActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}


function* update(action){
  const {success, failure} = supplierActions.saga.update;

  yield callApi(
    call(api.update, action.data),
    success,
    failure
  )
}

function* create(action){
  const {success, failure} = supplierActions.saga.create;

  yield callApi(
    call(api.create, action.data),
    success,
    failure
  )
}

function* remove(action){
  const {success, failure} = supplierActions.saga.remove;

  yield callApi(
    call(api.remove, action.data),
    success,
    failure
  )
}
function* getSupplierDetails(action) {
  const {success, failure} = supplierActions.saga.getSupplierDetails;

  yield callApi(
    call(api.getSupplierDetails, action.data),
    success,
    failure
  )
}

function* getPrimaryContacts(action) {
  const {success, failure} = supplierActions.saga.getPrimaryContacts;

  yield callApi(
    call(api.getPrimaryContacts, action.data),
    success,
    failure
  )
}

function* getContactDetails(action) {
  const {success, failure} = supplierActions.saga.getContactDetails;

  yield callApi(
    call(api.getContactDetails, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(supplierTypes.search, search)
  yield takeLatest(supplierTypes.searchById, searchById)
  yield takeLatest(supplierTypes.update, update)
  yield takeLatest(supplierTypes.create, create)
  yield takeLatest(supplierTypes.remove, remove)
  yield takeLatest(supplierTypes.getSupplierDetails, getSupplierDetails)
  yield takeLatest(supplierTypes.getPrimaryContacts, getPrimaryContacts)
  yield takeLatest(supplierTypes.getContactDetails, getContactDetails)
  yield takeLatest(supplierTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(supplierTypes.fetchNextPage, fetchNext)
}