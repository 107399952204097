import { fetchGet, objectify } from '../utils'


export const fetch = (query) => {
  const { RFCNumber } = query
  return fetchGet(`/ReturnForCreditEnquiry/RFC/${RFCNumber}/Details`, {}, 'Inline')
    .then((result) => {
      if (result) {
        const obj = objectify(result.RFCEnquiryDetail, null, 'Inline');
        return { inlineObject: { ...obj }, schema: result.RFCEnquiryDetail }
      }
    }
    )
}

export const fetchSummary = (query) => {
  const { RFCNumber } = query;
  return fetchGet(`/ReturnForCreditEnquiry/RFC/${RFCNumber}/Summary`, {}, 'Inline')
    .then((result) => result ? objectify(result.RFCEnquirySummary, null, 'Inline') : null)
}

export const fetchRFCSummary = (query) => {
  const { RFCNumber } = query;
  return fetchGet(`/ReturnForCreditEnquiry/RFC/${RFCNumber}/TotalsSummary`, {}, 'Inline')
    .then((result) => result ? objectify(result.RFCEnquiryTotalsSummary, null, 'Inline') : null)
}

export const fetchComment = (query) => {
  const { RFCNumber } = query;
  return fetchGet(`/ReturnForCreditEnquiry/RFC/${RFCNumber}/Comments`, {}, 'Inline')
    .then((result) => result ? objectify(result.RFCEnquiryTotalsComment, null, 'Inline') : null)
}