import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/customerEnquiry/pricing';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/pricing';

function* getSummary(action) {
  const { success, failure } = actions.saga.fetchCustomerPricingSummary;

  yield callApi(
    call(api.getPricingSummary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(types.fetchCustomerPricingSummary, getSummary)
}