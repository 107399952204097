import React from 'react';
import { connect } from 'react-redux';
import { IBackorderCommitmentProps } from './BackorderCommitment.properties';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import BackorderCommitment from './BackorderCommitment';
import BackorderCommitmentStateProvider from './BackorderCommitment.context';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeValidationDialog: uiActions.changeValidationDialog,
    },
    dispatch);

export default connect(null, mapDispatchToProps)((props: Readonly<Omit<
    IBackorderCommitmentProps,
    'classes'>>) => {
    return (
        <BackorderCommitmentStateProvider>
            <BackorderCommitment {...props} />
        </BackorderCommitmentStateProvider>
    );
});
