import { createStyles } from '@material-ui/core/styles';
import { customerMaintenanceDrawerBackground, dragExpandIcon } from '../../../themes/colors';

export default createStyles({
    lookupContainerStyle: {
        top: '0',
        backgroundColor: customerMaintenanceDrawerBackground,
        height: '100%',
        boxShadow: 'none',
        width: 300,
        position: 'relative',
        paddingTop: '10px'
    },
    lookupDragController: {
        'position': 'fixed',
        'top': 'calc(64px)',
        'left': '0',
        'width': '20px',
        'height': 'calc(100% - 67px - 64px)',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'background-color': '#F47920 !important',
        'cursor': 'pointer',
        '-webkit-box-shadow': '2px 0px 5px 0px rgba(0,0,0,0.45) !important',
        '-moz-box-shadow': '2px 0px 5px 0px rgba(0,0,0,0.45) !important',
        'box-shadow': '2px 0px 5px 0px rgba(0,0,0,0.45) !important',
        'z-index': 1,
    },
    lookupInner: {
        height: 'calc(100% - 77px)'
    },
    openedDragController: {
        left: '300px',
        width: '10px',
        backgroundColor: '#F47920',
        cursor: 'pointer'
    },
    dragIcon: {
        color: dragExpandIcon,
        width: '20px',
        height: '20px',
        marginLeft: '-5px',
        cursor: 'pointer'
    },
    closedDragIcon: {
        color: dragExpandIcon,
        width: '30px',
        height: '30px',
        marginLeft: '-5px',
        cursor: 'pointer',
    },
});
