import RosterLookupForm from '../data/forms/roster_maintenance/RosterLookupForm'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'RosterLookup':
      return RosterLookupForm
    default:
      return RosterLookupForm
  }
}

export default getFormSchema
