import { call } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga/effects';

import { types as lookupTypes, actions as lookupActions } from 'ducks/lookup';

import { callApi } from './utils';
import * as api from 'api/lookup';


function* fetch(action){
  const {success, failure} = lookupActions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}

function* dynamicFetch(action){
  const {success, failure} = lookupActions.saga.fetchData;
  
  yield callApi(
    call(api.dynamicFetch, action.data),
    success,
    failure
  )
}
export default function* rootLookupSaga(){
  yield takeEvery(lookupTypes.fetch, fetch)
  yield takeEvery(lookupTypes.fetchData, dynamicFetch)
}