
import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, IExtendedState, IDataAction,
  SuccessAction, asyncOnError
} from '../utils';

export interface ISupplierDeliveryDetailData {
  selected: any;
  list: any[];
  schemas: any;
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  deliveryDetails?: null;
  selectedRecord: any;
  totalPages: number;
}

export interface ISupplierDeliveryDetailState extends IExtendedState<ISupplierDeliveryDetailData> {
  searchDeliveryDetail_loading?: boolean;
  searchDeliveryDetailById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (SiteCode) => ({ SiteCode }),
    asyncs: {
      searchDeliveryDetail: (query) => (query),
      searchDeliveryDetailById: ({ Supplier, SiteCode }) => ({ Supplier, SiteCode }),
      searchDeliveryDetailForId: ({ Supplier, SiteCode }) => ({ Supplier, SiteCode }),
    }
  },
  'supplierDeliveryDetails');

const NOT_SELECTED = null;
const initialState = asyncInitialState<ISupplierDeliveryDetailData>({
  selected: NOT_SELECTED,
  list: null,
  selectedRecord: null,
  deliveryDetails: null,
  schemas: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 1,
});

export default (state: ISupplierDeliveryDetailState = initialState, action: IDataAction): ISupplierDeliveryDetailState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.SiteCode;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.searchDeliveryDetailForId:
      return asyncOnRequest({ ...state, data: { ...state.data } }, action);
    case types.searchDeliveryDetail:
    case types.searchDeliveryDetailById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.saga.searchDeliveryDetail.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierDeliveryDetailData, successAction: SuccessAction) => {
          const records = successAction.payload.records;
          const batchInformation = successAction.payload.BatchInformation;
          if (records) {
            const list = records.map((combinedObject) => combinedObject.inlineObject);
            const schemas = records.map((combinedObject) => combinedObject.schema);

            return {
              ...data,
              list: list,
              schemas: schemas,
              nextPage: batchInformation && !batchInformation.EndOfData ? batchInformation.BatchPage + 1 : 0,
              prevPage: batchInformation && batchInformation.EndOfData ? batchInformation.BatchPage - 1 : 0,
              currPage: batchInformation && batchInformation.BatchPage,
            };
          }

          return {
            ...data,
          };
        },
        { fetch: true });

    case types.saga.searchDeliveryDetailById.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierDeliveryDetailData, successAction: SuccessAction) => {
          const records = successAction.payload.records;
          const batchInformation = successAction.payload.BatchInformation;
          if (records) {
            const deliveryDetails = { values: records[0].inlineObject, schema: records[0].schema };
            const list = records.map((combinedObject) => combinedObject.inlineObject);
            const schemas = records.map((combinedObject) => combinedObject.schema);

            return {
              ...data,
              list: list,
              schemas: schemas,
              deliveryDetails: deliveryDetails,
              nextPage: batchInformation && !batchInformation.EndOfData ? batchInformation.BatchPage + 1 : 0,
              prevPage: batchInformation && batchInformation.EndOfData ? batchInformation.BatchPage - 1 : 0,
              currPage: batchInformation && batchInformation.BatchPage,
            };
          }

          return {
            ...data,
          };
        },
        { fetch: true });

    case types.saga.searchDeliveryDetailForId.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierDeliveryDetailData, successAction: SuccessAction) => {
          const records = successAction.payload.records;
          if (records) {
            const deliveryDetails = records && records.length === 1 && { values: records[0].inlineObject, schema: records[0].schema };

            return {
              ...data,
              deliveryDetails: deliveryDetails
            };
          }

          return {
            ...data,
          };
        },
        { fetch: true });
    case types.saga.searchDeliveryDetail.failure:
    case types.saga.searchDeliveryDetailById.failure:
    case types.saga.searchDeliveryDetailForId.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => data.selected || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.SiteCode.Value === data.selected)) || null,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
    selectedRecord: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.SiteCode === data.selected)) || null,
    deliveryDetails: (data) => data.deliveryDetails
  }
);

const syncSelector = {
  isLoading: (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails.searchDeliveryDetail_loading || state.supplierEnquiry.supplierDeliveryDetails.searchDeliveryDetailById_loading,
  loadingNextPage: (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails.fetchNextPage_loading,
  loadingPrevPage: (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails.fetchPrevPage_loading,
  pageSize: (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails.data.pageSize,
  totalPages: (state: { supplierEnquiry: { supplierDeliveryDetails: ISupplierDeliveryDetailState } }) => state.supplierEnquiry.supplierDeliveryDetails.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
