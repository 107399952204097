import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function Print(props: Readonly<SvgIconProps & { disabled?: boolean }>): JSX.Element {
    return (
        <SvgIcon viewBox={'0 0 44 44'} {...props}>
            <circle cx='22' cy='22' r='22' className={props?.className} fill={props?.disabled ? '#AAAAAA' : 'black'} />
            <mask id='mask0_2331_57499' maskUnits='userSpaceOnUse' x='6' y='6' width='32' height='32'>
                <rect x='6' y='6' width='32' height='32' fill='white' />
            </mask>
            <g mask='url(#mask0_2331_57499)'>
                <path d='M29.9974 15.3333H13.9974V10H29.9974V15.3333ZM29.9974 22.6667C30.3752 22.6667 30.6918 22.5389 30.9474 22.2833C31.2029 22.0278 31.3307 21.7111 31.3307 21.3333C31.3307 20.9556 31.2029 20.6389 30.9474 20.3833C30.6918 20.1278 30.3752 20 29.9974 20C29.6196 20 29.303 20.1278 29.0474 20.3833C28.7918 20.6389 28.6641 20.9556 28.6641 21.3333C28.6641 21.7111 28.7918 22.0278 29.0474 22.2833C29.303 22.5389 29.6196 22.6667 29.9974 22.6667ZM27.3307 31.3333V26H16.6641V31.3333H27.3307ZM29.9974 34H13.9974V28.6667H8.66406V20.6667C8.66406 19.5333 9.05295 18.5833 9.83073 17.8167C10.6085 17.05 11.553 16.6667 12.6641 16.6667H31.3307C32.4641 16.6667 33.4141 17.05 34.1807 17.8167C34.9474 18.5833 35.3307 19.5333 35.3307 20.6667V28.6667H29.9974V34Z' fill='white' />
            </g>
        </SvgIcon>);
}

export default React.memo(Print);
