import React from 'react';
import CommonKitComponents from 'components/common/KitComponents';
import IKitComponentsProperties, { OmittedApiTypes } from 'components/common/KitComponents/KitComponents.properties';

const KitComponents = (props: Readonly<Omit<
    IKitComponentsProperties,
    'classes' | OmittedApiTypes>>) => {
    return (<CommonKitComponents {...props} />);
};

export default KitComponents;
