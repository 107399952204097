import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { actions as formActions } from 'ducks/form'
import { selectors as linkSelector } from 'ducks/supplierEnquiry/attachments/links'
import { fetch } from 'api/supplierEnquiry/attachments/links'
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'
import {selectors as supplierSelectors} from 'ducks/supplierEnquiry/supplier';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'SupplierEnquiryLinks',
  columnDefs: linkSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: linkSelector.gridOptions(state),
  filterRow: linkSelector.filterRow(state),
  selectedFilters: linkSelector.selectedFilters(state),
  appliedFilters: linkSelector.filterRow(state) && filterSelectors.getFilters(state, linkSelector.filterRow(state).formName),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
    &&
    {
      SupplierId: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
    },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  applyFilters: filterActions.applyFilters
}
