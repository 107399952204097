import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Selection, Paging, Sorting } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { IProductGridHandle, IProductGridProps } from './ProductGrid.properties';
import styles from './ProductGrid.styles';
import ProductCell from 'components/common/ProductCell';
import ImageView from 'components/common/ImageView';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data';
import { ICatalogueProductFacade } from 'api/swaggerTypes';
import Paper from '@material-ui/core/Paper';
import { ARROW_DOWN, ARROW_UP, ENTER_KEY, PaperElevation, TAB } from 'utils/constants';
import { COLUMN_SIZE, DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';

const SMALL_COLUMN_MIN_WIDTH = 100;
const SMALL_COLUMN_DEFAULT_WIDTH = 150;
const DEFAULT_ROW_HEIGHT = 44;
const QUANTITY_WIDTH = 105;

function ProductGrid(props: Readonly<IProductGridProps>, ref: React.Ref<IProductGridHandle>): JSX.Element {
    const {
        catalogueId, defaultSelectedId, worksaleId, handleLoadProducts, loadImage,
        classes, getMainImage, getListOfImages, hideImage = false, allowArrowKeysNavigation = true,
        onProductSelectionChanged, showDetailOnClick, onKeyDown
    } = props;
    const { preventDataGridDefaults } = useGridKeyboardNavigation();
    const dataGridInnerRef = React.useRef<DataGrid>();
    const [selectedProduct, setSelectedProduct] = React.useState<ICatalogueProductFacade>();
    const [productDataSource, setProductDataSource] = React.useState<DataSource>(new DataSource([]));
    const [isFirstTimeLoad, setIsFirstTimeLoad] = React.useState<boolean>(true);

    React.useImperativeHandle(
        ref,
        () => ({
            reloadLines(): Promise<any> {
                return dataGridInnerRef.current?.instance.refresh();
            },
            selectDefaultRow(): void {
                if (defaultSelectedId) {
                    dataGridInnerRef.current?.instance.selectRows([defaultSelectedId], false);
                } else {
                    dataGridInnerRef.current?.instance.selectRowsByIndexes([0]);
                }

                const selectedElemet = document.querySelector('tr[aria-selected="true"]') as any;
                if (selectedElemet) dataGridInnerRef.current?.instance.focus(selectedElemet);

            },
            reload(): Promise<any> {
                return productDataSource?.reload();
            }
        })
    );

    React.useEffect(
        () => {
            if (catalogueId && worksaleId) {
                dataGridInnerRef.current?.instance?.clearSelection();
                setSelectedProduct(undefined);
                setIsFirstTimeLoad(true);
                setProductDataSource(
                    new DataSource({
                        key: 'CatalogueProductId',
                        load: async (loadOptions: LoadOptions): Promise<ICatalogueProductFacade[]> => {
                            const defaultSkip = 0;
                            const defaultTake = 10;

                            const skip = loadOptions.skip || defaultSkip;
                            const take = loadOptions.take || defaultTake;

                            const BatchPage = Math.floor((skip / take) + 1);
                            const BatchSize = take;

                            if (take > defaultTake) { //if take is greater then default batch page. Do multiple API calls to fetch all data requested through 'take' param
                                return async function (): Promise<any> {
                                    const APICallsCount = Math.floor(loadOptions.take / defaultTake);
                                    let allRecords = [];

                                    try {
                                        for (let i = 0; i < APICallsCount; i++) {
                                            const response = await handleLoadProducts(worksaleId, catalogueId, BatchPage + i, defaultTake);

                                            if (response?.EntryProducts?.length > 0) {
                                                allRecords = allRecords.concat(response.EntryProducts);
                                            }

                                            if (response?.EntryProducts?.length < defaultTake) {
                                                break;
                                            }
                                        }

                                    } catch (err) {
                                        console.warn(err);
                                    }

                                    return allRecords;
                                }();
                            } else {
                                const response = await handleLoadProducts(worksaleId, catalogueId, BatchPage, BatchSize);

                                return response?.EntryProducts;
                            }
                        }
                    })
                );
            } else {
                setProductDataSource(null);
            }
        },
        [catalogueId, worksaleId]
    );

    const handleDataGridKeyDown = async (e) => {
        const keyboardEvent = e.event as KeyboardEvent;

        // MPF-1664 - Handle tabs navigation
        preventDataGridDefaults(e);

        if (onKeyDown) {
            onKeyDown(keyboardEvent);
        }

        if (keyboardEvent.keyCode === ENTER_KEY && selectedProduct) {
            showDetailOnClick(selectedProduct);

            return;
        }
        const grid = dataGridInnerRef.current?.instance;
        if (allowArrowKeysNavigation) {
            const selKey = e.component.getSelectedRowKeys();
            const visibleRowsLength = e.component.getVisibleRows().length;
            const clientHeight = document.getElementsByClassName('dx-datagrid-rowsview')[0].clientHeight;

            if (selKey.length) {
                const currentKey = selKey[0];
                let index = e.component.getRowIndexByKey(currentKey);

                if (keyboardEvent.keyCode === ARROW_UP) {
                    index--;
                    if (index >= 0) {
                        await e.component.selectRowsByIndexes([index]);
                        if ((index * DEFAULT_ROW_HEIGHT) <= DEFAULT_ROW_HEIGHT) {
                            grid.getScrollable().scrollBy(-DEFAULT_ROW_HEIGHT);
                        }
                        keyboardEvent.stopPropagation();
                    }
                } else if (keyboardEvent.keyCode === ARROW_DOWN) {
                    index++;
                    if (index < visibleRowsLength) {
                        await e.component.selectRowsByIndexes([index]);
                        if ((index + 1) * DEFAULT_ROW_HEIGHT > clientHeight) {
                            grid.getScrollable().scrollBy(DEFAULT_ROW_HEIGHT);
                        }
                        keyboardEvent.stopPropagation();
                    }
                }
            }
            e.component.focus();
        }

        if (keyboardEvent.keyCode === TAB) {
            setTimeout(
                () => {
                    unFocusGrid();
                },
                0);
        }
    };

    const deselectRow = () => {
        dataGridInnerRef.current?.instance.deselectAll()
            .catch((err) => console.warn(err));
    };

    function unFocusGrid(): any {
        deselectRow();
        dataGridInnerRef.current?.instance.clearSelection();
        setSelectedProduct(undefined);
        if (onProductSelectionChanged) {
            onProductSelectionChanged(undefined);
        }
    }

    const focusCellChanging = React.useCallback(
        (e) => {
            e.isHighlighted = false;
        },
        [allowArrowKeysNavigation]);

    const selectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e?.selectedRowsData?.length > 0 ? e.selectedRowsData[0] : undefined;
            setSelectedProduct(selectedRowData);
            if (onProductSelectionChanged) {
                onProductSelectionChanged(selectedRowData);
            }
        },
        []);

    const onContentReady = React.useCallback(
        async () => {
            if (dataGridInnerRef.current?.instance?.getDataSource()?.items().length > 0 && isFirstTimeLoad) {

                if (defaultSelectedId) {
                    await dataGridInnerRef.current?.instance.selectRows([defaultSelectedId], false);
                    const productData = await dataGridInnerRef.current?.instance.getSelectedRowsData()?.[0];
                    onProductSelectionChanged(productData);
                } else {
                    dataGridInnerRef.current.instance.selectRowsByIndexes([0])
                        .catch((err) => console.warn(err));
                }

                setIsFirstTimeLoad(false);
            }
        },
        [isFirstTimeLoad, defaultSelectedId, dataGridInnerRef.current]
    );

    const detailCellComponent = React.useCallback(
        (allProps) => {
            return <MoreIcon className={classes.lineDetailIcon} onClick={() => {
                if (showDetailOnClick) {
                    showDetailOnClick(allProps.data ? allProps.data.data : null);
                }
            }} />;
        },
        [classes, showDetailOnClick]);

    const productCodeCellComponent = React.useCallback(
        (allProps) => (
            <ProductCell
                urlParamKeys={['ProductId']}
                toLink={'/inventory-enquiry/product-details'}
                {...allProps} />
        ),
        []
    );

    return (
        <div className={classes.parentContainer} >
            <Paper className={classes.productGrid}>
                <DataGrid
                    ref={dataGridInnerRef}
                    className={classes.productDataGrid}
                    dataSource={productDataSource}
                    showBorders={false}
                    renderAsync={true}
                    remoteOperations={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    hoverStateEnabled={true}
                    onKeyDown={handleDataGridKeyDown}
                    onFocusedCellChanging={focusCellChanging}
                    onSelectionChanged={selectionChanged}
                    noDataText=''
                    onContentReady={onContentReady}
                >
                    <Column
                        width={COLUMN_SIZE.sm0}
                        allowResizing={false}
                        fixed={true}
                        alignment='center'
                        allowHeaderFiltering={false}
                        cellComponent={detailCellComponent}
                    />
                    <Column
                        dataField={'ProductCode'}
                        caption={'Product'}
                        allowHeaderFiltering={false}
                        minWidth={SMALL_COLUMN_MIN_WIDTH}
                        width={SMALL_COLUMN_DEFAULT_WIDTH}
                        fixed={true}
                        cellComponent={productCodeCellComponent}
                    />
                    <Column
                        dataField={'Quantity'}
                        caption={'Quantity'}
                        allowHeaderFiltering={false}
                        format='#0.00'
                        width={QUANTITY_WIDTH} />
                    <Column
                        dataField={'Price'}
                        caption={'Price'}
                        format='#0.00'
                        allowHeaderFiltering={false}
                        minWidth={SMALL_COLUMN_MIN_WIDTH}
                        width={SMALL_COLUMN_DEFAULT_WIDTH}>
                    </Column>
                    <Column
                        dataField={'QuantityAvailable'}
                        caption={'Available'}
                        allowHeaderFiltering={false}
                        format='#0.00'
                        width={QUANTITY_WIDTH} />
                    <Column
                        dataField={'Description'}
                        caption={'Description'}
                        allowHeaderFiltering={false}
                        minWidth={COLUMN_SIZE.xl0} />
                    <Column
                        dataField={'DetailedDescription'}
                        caption={'Extended description'}
                        allowHeaderFiltering={false}
                        minWidth={COLUMN_SIZE.xl0} />
                    <Column
                        dataField={'Comment'}
                        caption={'Comment'}
                        allowHeaderFiltering={false}
                        minWidth={COLUMN_SIZE.xl0} />
                    <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                    <Sorting mode='none' />
                    <Selection mode={'single'} />
                    <GroupPanel visible={false} />
                    <SearchPanel visible={false} />
                    <Scrolling mode={'infinite'} />
                    <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
                </DataGrid>
            </Paper>
            {!hideImage &&
                <>
                    <Paper elevation={PaperElevation} className={classes.productImageDiv}>
                        <ImageView
                            dialogTitle='Product Image'
                            getMainImage={getMainImage}
                            mainImageObject={selectedProduct?.MainImage?.[0] ?? null}
                            loadImage={loadImage}
                            imageCount={selectedProduct?.ImageCount ?? 0}
                            getListOfImages={() => getListOfImages(selectedProduct?.CatalogueProductId)}
                        />
                    </Paper>
                </>
            }
        </div>
    );
}

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(ProductGrid)));
