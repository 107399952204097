import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  treeList: {
    '& .dx-treelist-focus-overlay': {
      border: 'unset'
    }
  },
  treeListNormal: {
    '& .dx-treelist-container .dx-treelist-table .dx-row.dx-selection.dx-row-focused > .dx-treelist-cell-expandable': {
      backgroundColor: '#E5E5E5',
      color: 'black',
      border: '1px solid #E5E5E5',
    }
  },
  treeListBold: {
    '& .dx-treelist-container .dx-treelist-table .dx-row.dx-selection.dx-row-focused > .dx-treelist-cell-expandable': {
      color: 'black',
      fontWeight: 700,
      backgroundColor: '#FFF !important',
      border: 'unset'
    }
  },
});
