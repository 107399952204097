import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { CircularProgressSize, SnackBarDisplayTimeMilliseconds } from '../../../utils/constants';
import { showSnackBar } from '../SnackBars/SnackBar.hooks';

const classStyles = {
  preLoader: {
    margin: '0 auto'
  },
  circularContainer: {
    'display': 'flex',
    'align-items': 'center',
    'text-align': 'center',
    'height': 'calc(100% - 24px)'
  }
};

class UserNotifications extends React.PureComponent<any> {
  componentDidUpdate(prevProps: Readonly<any>): void {
    const { notifications, errors, isLoading, operationMode } = this.props;
    if (notifications && notifications !== prevProps.notifications) {
      this.checkNotifications();
    }
    if (errors && errors.Status === 'ValidationError' && prevProps.isLoading && !isLoading) {
      this.checkValidationErrors(errors);
    }
    if (operationMode !== prevProps.operationMode && operationMode === 'SAVE') {
      this.checkFormSyncErrors();
    }
  }

  checkValidationErrors = (error: any) => {
    const { ValidationErrors } = error;
    ValidationErrors.forEach((item) => {
      showSnackBar({ variant: 'warning', message: `Validation Error = ${item.Property} : ${item.Message}`, delay: SnackBarDisplayTimeMilliseconds });
    });
  }

  checkFormSyncErrors = () => {
    const { formSyncErrors } = this.props;
    if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
      if (typeof formSyncErrors === 'string') {
        showSnackBar({ variant: 'error', message: formSyncErrors });

        return;
      }
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }
    }
  }

  checkNotifications = () => {
    const { notifications, entity } = this.props;
    if (notifications.update) {
      showSnackBar({ variant: 'success', message: `${entity} Successfully Updated` });
    }
    if (notifications.create) {
      showSnackBar({ variant: 'success', message: `${entity} Successfully Added` });
    }
    if (notifications.remove) {
      showSnackBar({ variant: 'success', message: `${entity} Successfully Deleted` });
    }
    if (notifications.clear) {
      notifications.clear();
    }
  }

  render(): React.ReactNode {
    const { isLoading, classes } = this.props;

    return (isLoading ?
      <div className={classes.circularContainer} >
        <CircularProgress
          size={CircularProgressSize}
          className={classes.preLoader}
          color={'secondary'}
          variant={'indeterminate'}
        />
      </div >
      : null);
  }
}

export default withStyles(classStyles, { index: 1 })(UserNotifications);
