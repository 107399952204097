import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router-dom';
import { ISlipDetailsProps } from '../interfaces';

class CustomerDetail extends React.Component<ISlipDetailsProps> {

  componentDidMount(): void {
    const { onInitialLoad } = this.props;
    onInitialLoad('SlipDetails');
  }

  getInitialValues = (): any => {
    const {
      selectedTab, slipDetails,
    } = this.props;

    switch (selectedTab) {
      case 'SlipDetails':
        return { initialValues: (slipDetails && slipDetails.inlineObject), valuesSchema: slipDetails && slipDetails.schema };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(CustomerDetail);
