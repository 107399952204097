import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/rfcEnquiry/RFCLines';

import { callApi } from '../utils';
import * as api from 'api/rfc/rfcLines';

function* fetchDetails(action){
  const {success, failure} = invoiceLineActions.saga.fetchDetails;

  yield callApi(
    call(api.fetchDetails, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(invoiceLineTypes.fetchDetails, fetchDetails)
}