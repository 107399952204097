import { IState } from './PurchaseOrders.reducer';

export interface IActions {
    [PurchaseOrdersActionTypes.setPurchaseOrder]?: IState['PurchaseOrder'];
    [PurchaseOrdersActionTypes.setLineNumber]?: IState['LineNumber'];
}

export enum PurchaseOrdersActionTypes {
    setPurchaseOrder = 'setPurchaseOrder',
    setLineNumber = 'setLineNumber',
}
