import { isDirty, reset, destroy } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as activitySelectors, actions as activityActions } from 'ducks/activityMaintenance/activity';
import { selectors as checklistSelectors, actions as checklistActions } from 'ducks/activityMaintenance/checklists';
import { actions as filterActions } from 'ducks/common/filters';
import ActivityMaintenance from './ActivityMaintenance';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'ActivityForm';
const params = new URLSearchParams(location.search);
const mapStateToProps = (state: IApplicationState) => ({
    scope: uiSelectors.scope(state),
    operationMode: operationSelectors.operationMode(state),
    selectedActivity: activitySelectors.selected(state),
    selectedForm: formSelectors.selected(state),
    selectedTab: uiSelectors.selectedTab(state),
    isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
    isActivityLookUpOpen: uiSelectors.isActivityLookUpOpen(state),
    isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
    ActivityCode: activitySelectors.ActivityCode(state) || params.get('ActivityCode'),
    dirty: isDirty(FORM_NAME)(state),
    isChecklistsDirty: checklistSelectors.isDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
        changeOperationMode: operationActions.changeOperationMode,
        changeSelectedTab: uiActions.changeSelectedTab,
        getFormSchema: formActions.search,
        changeSelectedForm: formActions.setSelected,
        toggleMenuOption: uiActions.toggleMenuOption,
        resetForm: () => reset('ActivityForm'),
        changeScope: uiActions.changeScope,
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        closeMenu: uiActions.closeMainMenu,
        applyFilters: filterActions.applyFilters,
        fetchActivityData: activityActions.fetchActivityData,
        setChecklistIsDirty: checklistActions.setIsDirty,
        setSelectedActivity: activityActions.setSelected,
        resetSearchLookupDrawer: activityActions.reset,
        destoryForm: () => destroy('ActivityDetails'),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActivityMaintenance);
