import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as movementsSelectors, actions as movementsActions } from 'ducks/supplierEnquiry/activity/movements';
import { fetch } from 'api/supplierEnquiry/activity/movements';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import {selectors as supplierSelectors} from 'ducks/supplierEnquiry/supplier';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'SupplierActivityMovements',
  columnDefs: movementsSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: movementsSelectors.gridOptions(state),
  filterRow: movementsSelectors.filterRow(state),
  selectedFilters: movementsSelectors.selectedFilters(state),
  appliedFilters: movementsSelectors.filterRow(state) && filterSelectors.getFilters(state, movementsSelectors.filterRow(state).formName),
  reqParams: (supplierSelectors.selected(state) || params.get('Supplier'))
    &&
    {
      SupplierId: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || params.get('Supplier')
    },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: movementsActions.setSelected,
  applyFilters: filterActions.applyFilters
}
