import { combineReducers } from 'redux';
import details from './details';
import prices from './prices';
import pricesByWH from './pricesByWH';
import categories from './categories';
import matrix from './matrix';
import salesHistory from './salesHistory';
import why from './why';
import trueCost from './trueCost'

const combined = combineReducers({
  details,
  prices,
  pricesByWH,
  categories,
  matrix,
  salesHistory,
  why,
  trueCost
});

export default combined;
