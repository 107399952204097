import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: '100%',
        height: 'calc(100% - 55px)',
        padding: 0,
        flexGrow: 1,
        boxSizing: 'border-box',
    },
    checklistQuestionDataGrid: {
        height: '100%'
    },
});
