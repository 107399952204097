
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as purchaseOrdersSelectors } from 'ducks/supplierEnquiry/purchasing/purchaseOrders';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import Purchasing from './Purchasing';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedSupplier: supplierSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  purchaseOrdersSummary: purchaseOrdersSelectors.purchaseOrdersSummary(state),
  loadingPurchaseOrdersSummary: purchaseOrdersSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(Purchasing);
