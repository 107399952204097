import {
  IExtendedState,
  createActions,
  asyncInitialState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncSelectors,
  asyncOnError,
  SuccessAction,
  DataAction
} from './utils';
import { IStaff, parseToken } from 'api/login';
import { IChangePasswordFields } from 'components/ChangePasswordForm/ChangePassword.properties';

export interface IStaffDetails {
  token: string;
  token_type: string;
  staff: IStaff;
}

export interface IStaffData extends IStaffDetails {
  loggedIn: boolean;
}

export interface IStaffState extends IExtendedState<IStaffData> {
  changePassword_loading?: boolean;
}

export interface ILoginFields {
  staffUserName: string;
  staffPassword: string;
}

export const { types, actions } = createActions(
  {
    asyncs: {
      login: (fields: ILoginFields) => fields,
      changePassword: (fields: IChangePasswordFields) => fields,
      logout: () => null,
    }
  },
  'staff');

const initialState = {
  ...asyncInitialState<IStaffData>({
    loggedIn: sessionStorage.getItem('token') !== null,
    token: sessionStorage.getItem('token'),
    token_type: sessionStorage.getItem('token_type'),
    staff: (() => {
      const token = sessionStorage.getItem('token');
      if (token) {
        return parseToken(token);
      } else {
        return null;
      }
    })()
  }),
  fetched: true
};

export default (state: IStaffState = initialState, action: DataAction): IStaffState => {
  switch (action.type) {
    case types.login:
      return asyncOnRequest(state, action);
    case types.changePassword:
      action.data.staffUserName = state.data.staff.name;

      return asyncOnRequest(state, action);
    case types.saga.login.success:
      return asyncOnSuccess(state, action, (_data: IStaffData, successAction: SuccessAction) => {
        const { token, token_type, staff } = successAction.payload;
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('token_type', token_type);

        const loggedIn = true;

        return {
          loggedIn,
          token,
          token_type,
          staff
        };
      });
    case types.saga.changePassword.success:
      return asyncOnSuccess(state, action, (_data: IStaffData) => {
        return _data;
      });
    case types.saga.login.failure:
      return asyncOnError(state, action);
    case types.saga.changePassword.failure:
      return asyncOnError(state, action);
    case types.logout:
      return asyncOnRequest(state, action);
    case types.saga.logout.failure:
      return asyncOnError(state, action);
    case types.saga.logout.success:
      sessionStorage.clear();

      return asyncOnSuccess(state, action, (_data: IStaffData) => {
        return {
          ...state.data,
          loggedIn: false,
          token: null,
          token_type: null,
          staff: null
        };
      });

    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { staff: IStaffState }) => state.staff,
    {
      loggedIn: (data: IStaffData) => data.loggedIn,
      staff: (data: IStaffData) => data.staff,
      name: (data: IStaffData) => data.staff.name,
      id: (data: IStaffData) => Number(data.staff.id),
      defaultSalesEntity: (data: IStaffData) => data.staff.defaultSalesEntity,
      defaultWarehouseEntity: (data: IStaffData) => data.staff.defaultWarehouseEntity,
      IsAdministrator: (data: IStaffData) => data.staff && data.staff.IsAdministrator === 'True',
    }
  ),
  loading: (state: { staff: IStaffState }) => state.staff.loading,
  changePassword_loading: (state: { staff: IStaffState }) => state.staff.changePassword_loading,
  error: (state: { staff: IStaffState }) => state.staff.error
};
