import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';

const warningIconDialogStyle = createStyles({
  alertDialogTitle: {
    padding: '10px 15px'
  },
  dialogContent: {
    padding: '20px',
    background: '#EFEFEF',
    margin: '0px 10px',
    border: 'solid 3px darkgray',
    whiteSpace: 'pre-line'
  },
  warningIcon: {
    'height': '24px',
    'width': '24px',
    'verticalAlign': 'middle',
    'color': 'red',
    'marginLeft': 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
});

interface IWarningIconDialog {
  classes: any;
  title?: any;
  message: any;
}

function WarningIconDialog(props: IWarningIconDialog): JSX.Element {

  const [open, setOpen] = React.useState(false);

  const { title = 'Alert', message, classes } = props;

  return (<React.Fragment>
    <WarningIcon className={classes.warningIcon} onClick={() => setOpen(true)} />
    <span className='alert-dialog-container'>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className={classes.alertDialogTitle}>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            Ok
        </Button>
        </DialogActions>
      </Dialog>
    </span>
  </React.Fragment>
  );

}

export default withStyles(warningIconDialogStyle, {index: 1})(WarningIconDialog);
