import { combineReducers } from 'redux';

import securityPolicies, { ISecurityPolicyState } from './securityPolicies';
import policyRules, { IPolicyRulesState } from './policyRules';
import policyUserGroups, { IPolicyUserGroupsState } from './policyUserGroups';

export interface ISecurityManagementState {
    securityPolicies: ISecurityPolicyState;
    policyRules: IPolicyRulesState;
    policyUserGroups: IPolicyUserGroupsState;
}

const SecurityManagementReducer = combineReducers({
    securityPolicies,
    policyRules,
    policyUserGroups
});

export default SecurityManagementReducer;
