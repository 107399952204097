import React from 'react';
import DataGrid, { Column, Scrolling, LoadPanel, HeaderFilter, Paging, Selection } from 'devextreme-react/data-grid';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import HyperLinkRenderer from 'components/common/DataGridDevEx/CustomRenderers/HyperLinkRenderer';
import { GRID_HEIGHT, SUB_TITLE_CONTENT } from './TransmitInternalPODialog.constants';
import { TransmitInternalPODialogStyles } from './TransmitInternalPODialog.styles';
import { withStyles } from '@material-ui/core';
import { ISubTitleProperties, ITransmitInternalPOGridProperties } from './TransmitInternalPODialog.properties';

const TransmitInternalPOGrid = ({ classes, loading, data, onSelectedRowChanged }: ITransmitInternalPOGridProperties) => {

    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const dataGridRef = React.useRef<DataGrid>();

    React.useEffect(() => setIsFirstTime(true), []);

    React.useEffect(
        () => {
            if (dataGridRef && dataGridRef.current) {
                if (loading) {
                    dataGridRef.current.instance.beginCustomLoading('');
                } else {
                    dataGridRef.current.instance.endCustomLoading();
                }
            }
        },
        [loading]
    );

    const SelectAndFocusFirstRow = () => {
        setTimeout(
            () => {
                dataGridRef.current?.instance?.focus();
                dataGridRef?.current.instance.selectRowsByIndexes([0]);
            },
            0
        );
    };

    const onContentReady = React.useCallback(
        (e) => {
            if (isFirstTime && e.component.getDataSource()?.items().length) {
                SelectAndFocusFirstRow();
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );

    const handleOnSelectionChanged = ({ selectedRowsData }) => {
        onSelectedRowChanged((selectedRowsData && selectedRowsData[0]) ? selectedRowsData[0].PurchaseOrder : {});
    };

    return (
        <div className={classes.Container}>
            <DataGrid
                ref={dataGridRef}
                id='TransmitInternalPO'
                dataSource={data}
                keyExpr={'PurchaseOrder'}
                noDataText=''
                repaintChangesOnly={true}
                remoteOperations={true}
                allowColumnReordering={true}
                columnResizingMode={'nextColumn'}
                allowColumnResizing={true}
                renderAsync={true}
                showBorders={false}
                hoverStateEnabled={true}
                onContentReady={onContentReady}
                onSelectionChanged={handleOnSelectionChanged}
                height={GRID_HEIGHT}
            >
                <HeaderFilter visible />
                <Column
                    dataField={'PurchaseOrder'}
                    caption={'Purchase order'}
                    allowEditing={false}
                    allowHeaderFiltering={false}
                    cellRender={(col) => <HyperLinkRenderer
                        colDef={col}
                        value={col.value}
                        link={`inventory-enquiry/product-details?ProductId=${col.value}`}
                    />
                    }
                />
                <Column
                    caption={'Status'}
                    dataField={'StatusDescription'}
                    allowFiltering={false}
                />
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Scrolling mode={'infinite'} />
                <Selection mode={'single'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
        </div>
    );
};

export default withStyles(TransmitInternalPODialogStyles)(React.memo(TransmitInternalPOGrid));

export const SubTitle = withStyles(TransmitInternalPODialogStyles)(({ classes }: ISubTitleProperties) => (<span className={classes.subTitle}>{SUB_TITLE_CONTENT}</span>));
