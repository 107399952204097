import CustomerForm from './../data/forms/customer_enquiry/CustomerForm'
import DeliveryDetailsForm from './../data/forms/customer_enquiry/DeliveryDetailsForm'
import ContactsForm from './../data/forms/customer_enquiry/ContactsForm'
import CustomerProductsForm from './../data/forms/customer_enquiry/ProductsForm'
import MarketingForm from './../data/forms/customer_enquiry/MarketingForm'
import Pricing from './../data/forms/customer_enquiry/Pricing'
import NotepadForm from './../data/forms/customer_enquiry/NotepadForm'
import Balances from './../data/forms/customer_enquiry/Balances'
import Transactions from './../data/forms/customer_enquiry/Transactions'
import TransactionDetails from './../data/forms/customer_enquiry/TransactionDetails'
import DetailsTransactions from './../data/forms/customer_enquiry/DetailsTransactions'
import Backorders from './../data/forms/customer_enquiry/Backorders'
import Quotes from './../data/forms/customer_enquiry/Quotes'
import SalesOrders from './../data/forms/customer_enquiry/SalesOrders'
import Invoices from './../data/forms/customer_enquiry/invoices'
import SalesDiary from './../data/forms/customer_enquiry/SalesDiary'
import CreditDiary from './../data/forms/customer_enquiry/CreditDiary'
import DiaryDetails from './../data/forms/customer_enquiry/DiaryDetails'
import CreditDiaryDetails from './../data/forms/customer_enquiry/CreditDiaryDetailsForm'
import CustomerChildAccounts from './../data/forms/customer_enquiry/ChildAccounts'
import Movements from './../data/forms/customer_enquiry/Movements'
import SettingsGeneralForm from '../data/forms/customer_enquiry/SettingsGeneralForm'
import FinancialForm from './../data/forms/customer_enquiry/FinancialForm'
import ServiceOrders from './../data/forms/customer_enquiry/ServiceOrders'
import Enquiries from './../data/forms/customer_enquiry/Enquiries'
import UserFields from './../data/forms/customer_enquiry/UserFields'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'CustomerDetails':
      return CustomerForm
    case 'Contacts':
      return ContactsForm
    case 'CustomerProducts':
      return CustomerProductsForm
    case 'Marketing':
      return MarketingForm
    case 'DeliveryDetails':
      return DeliveryDetailsForm
    case 'Pricing':
      return Pricing
    case 'Notepad':
      return NotepadForm
    case 'Balances':
      return Balances
    case 'Transactions':
      return Transactions
    case 'TransactionDetails':
      return TransactionDetails
    case 'DetailsTransactions':
      return DetailsTransactions
    case 'Backorders':
      return Backorders
    case 'Quotes':
      return Quotes
    case 'SalesOrders':
      return SalesOrders
    case 'Invoices':
      return Invoices
    case 'SalesDiary':
      return SalesDiary
    case 'CreditDiary':
      return CreditDiary
    case 'DiaryDetails':
      return DiaryDetails
    case 'CreditDiaryDetails':
      return CreditDiaryDetails
    case 'CustomerChildAccounts':
      return CustomerChildAccounts
    case 'Movements':
      return Movements
    case 'CustomerSetting':
      return SettingsGeneralForm
    case 'SettingsFinancial':
      return FinancialForm
    case 'ServiceOrders':
      return ServiceOrders
    case 'Enquiries':
      return Enquiries
    case 'UserFields':
      return UserFields
    default:
      return CustomerForm
  }
}

export default getFormSchema
