import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from './utils'

export const { types, actions } = createActions({
  asyncs: {
    fetch: (query) => query,
    fetchData: (query) => query
  }
}, 'lookups')


let initialState = asyncInitialState({
  list: {},
  options: {}
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const lookupOptions = action.payload;
        let prevList = data.list !== null ? data.list : {}
        if (lookupOptions.Data)
          lookupOptions.Data = lookupOptions.Data.map((d) => ({ ...d, value: d.Code, label: d.Label, description: d.Description }))
        prevList[lookupOptions.lookupId] = lookupOptions.Data ? { ...lookupOptions, Data: lookupOptions.Data.sort((a, b) => (a.value + b.value)) } : lookupOptions;
        return {
          ...data,
          list: { ...prevList }
        }
      })
    case types.fetchData:
      return asyncOnRequest(state, action)
    case types.saga.fetchData.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const lookupOptions = action.payload;
        let prevList = data.options !== null ? data.options : {}
        prevList[lookupOptions.api] = lookupOptions
        return {
          ...data,
          options: { ...prevList }
        }
      })
    case types.saga.fetch.failure:
    case types.saga.fetchData.failure:
      return asyncOnError(state, action);

    default:
      return state
  }
}

export const selectors = asyncSelectors(
  (state) => state.lookup,
  {
    all: (data) => data && data.list !== undefined && data.list != null && data.list,
    getData: data => (lookupName) => (data && data.list && data.list[lookupName]) || {},
    options: (data) => data && data.options !== undefined && data.options != null && data.options
  }
)
