import * as React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@markinson/uicomponents-v2/SvgIcons/ExpandLess';
import ExpandMore from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import { Drawer, ListItem, ListItemText, List } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// import { List } from '@markinson/ui-components/material/List';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
  drawer: {
    top: 64,
    left: 210,
    width: 270,
    boxShadow: ''
  },
  paperDrawer: theme.mixins.gutters({
    backgroundColor: '#BBBBBB',
    width: 270,
    height: '100%',
    outline: 'none',

  }),
  link: {
    textDecoration: 'none',
  },
  menuLevel1Text: theme.menuLevel1Text,
  menuLevel2Text: theme.menuLevel2Text,
  menuHeading: theme.menuHeading,
  subMenu: {
    marginBottom: '60px'
  },
  ripple: {
    backgroundColor: 'black'
  }
});

interface ISubMenuProps {
  moduleItem: any;
  open: any;
  classes: any;
}
interface ISubMenuState {
  nestedOpen: any;
  open: boolean;
}
class SubMenu extends React.Component<ISubMenuProps, ISubMenuState> {

  constructor(props: ISubMenuProps) {
    super(props);
    this.state = {
      open: false,
      nestedOpen: {}
    };
  }

  componentDidMount(): void {
    const { moduleItem } = this.props;
    const nestedMenus = {};
    if (moduleItem.subMenu) {
      moduleItem.subMenu.map((_item, ind) => {
        return nestedMenus[ind] = true;
      });
    }
    this.setState({ nestedOpen: nestedMenus });
  }

  componentDidUpdate(prevProps: ISubMenuProps): void {
    const { moduleItem } = this.props;
    if (!prevProps.moduleItem || (moduleItem && moduleItem.label !== prevProps.moduleItem.label)) {
      const nestedMenus = {};
      if (moduleItem.subMenu) {
        moduleItem.subMenu.map((_item, ind) => {
          return nestedMenus[ind] = true;
        });
      }
      this.setState({ nestedOpen: nestedMenus });
    }
  }

  handleNestedListClick = (ind: any) => {
    this.setState({ nestedOpen: { ...this.state.nestedOpen, [ind]: !this.state.nestedOpen[ind] } });
  };

  render(): React.ReactNode {
    const { open, moduleItem, classes } = this.props;

    return (

      <Drawer
        anchor={'left'}
        open={open}
        className={classes.drawer}
        ModalProps={{
          hideBackdrop: true,
          disableEnforceFocus: true,
          disableRestoreFocus: true,
          disableAutoFocus: true
        }}
        PaperProps={{
          style: {
            padding: 0
          },
          className: classes.paperDrawer,
          elevation: 0
        }}
      >
        <Scrollbars>
          <List
            disablePadding
            className={classes.subMenu}
          >

            {moduleItem.subMenu && moduleItem.subMenu.map((item, ind) => (
              <div key={ind}>
                {item.children ?
                  <ListItem button key={ind} onClick={item.children ? () => {
                    this.handleNestedListClick(ind);
                  } : null}>
                    <ListItemText primary={item.label} className={classes.menuLevel1Text} />
                    {!this.state.nestedOpen[ind] ? <ExpandMore /> : <ExpandLess />}
                  </ListItem>
                  :
                  <Link to={`/${item.label.replace(/ /g, '-').toLowerCase()}`} className={classes.link} key={`link-sub-item-${ind}`}>
                    <ListItem button TouchRippleProps={{
                      classes: {
                        child: classes.ripple,
                      }
                    }}>
                      <ListItemText primary={item.label} className={classes.menuLevel1Text} />
                    </ListItem>
                  </Link>
                }
                {!item.children ? null :
                  <Collapse in={this.state.nestedOpen[ind]} timeout='auto' unmountOnExit>
                    <List disablePadding>
                      {item.children != null ? item.children.map((childItem, childInd) => (
                        <Link to={`/${childItem.label.replace(/ /g, '-').toLowerCase()}`} className={classes.link} key={`link-sub-item-${ind}-${childInd}`}>
                          <ListItem button TouchRippleProps={{
                            classes: {
                              child: classes.ripple,
                            }
                          }}>
                            <ListItemText primary={childItem.label} className={classes.menuLevel2Text} />
                          </ListItem>
                        </Link>
                      )) : []}
                    </List>
                  </Collapse>
                }
              </div>
            ))}
          </List>
        </Scrollbars>

      </Drawer >
    );

  }
}

export default withStyles(styles, { index: 1 })(SubMenu);
