import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './TemplateDetails.styles';
import FormView from 'components/FormView';
import SummaryTable from 'components/common/SummaryTable';
import TemplateDetailsForm from './TemplateDetails.form';
import IDetailsProperties, { ITemplateDetailsHandle } from './TemplateDetails.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import SummaryTableSchema from './TemplateDetails.summaryTable.schema';
import { AddCircle as AddIcon, RemoveCircle, Edit, Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons/';
import { useDeleteEMTemplate, useFetchEMNewTemplate, useFetchEMTemplate, useSaveEMTemplate, useSetEMTemplateQueryData } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { Operation } from 'utils/operations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { IBasicLookupFieldHandle } from '@markinson/uicomponents-v2/BasicLookupField/BasicLookup.properties';

const TemplateDetails = (props: IDetailsProperties, ref: React.Ref<ITemplateDetailsHandle>) => {
    const { classes, dirty, selectedTemplateId, operationMode, changeOperationMode, formValues, changeConfirmationDialog, resetForm, searchById, search, formSyncErrors, isValid, touchFormFields, formAsyncErrors, searchLoading } = props;

    const { data: newTemplate } = useFetchEMNewTemplate();
    const { data: template, isLoading: fetchTemplateLoading } = useFetchEMTemplate(selectedTemplateId);
    const { mutateAsync: saveTemplateMutateAsync, isLoading: saveTemplateLoading } = useSaveEMTemplate();
    const { mutateAsync: deleteTemplateMutateAsync } = useDeleteEMTemplate();
    const setTemplateQueryData = useSetEMTemplateQueryData();
    const { setActionBar } = React.useContext(ActionBarContext);

    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const templateNameFieldRef = React.useRef<IBasicLookupFieldHandle>();
    const isDirty = internalDirty || dirty;
    const params = React.useMemo(() => (new URLSearchParams(location.search)), [location.search]);

    React.useEffect(
        () => {
            if (params.get('TemplateId') && Number(params.get('TemplateId')) > 1) {
                searchById(params.get('TemplateId'));
            } else if ((!params.get('TemplateId') || Number(params.get('TemplateId')) === 0) && selectedTemplateId) {
                params.set('TemplateId', selectedTemplateId.toString());
                window.history.replaceState(null, null, `?TemplateId=${selectedTemplateId.toString()}`);
            }
        },
        []
    );

    React.useEffect(
        () => {
            if (operationMode === Operation.NEW) {
                setTimeout(
                    () => {
                        templateNameFieldRef?.current?.focus();
                    },
                    0
                );
            }
            setActionBarButtons();
        },
        [operationMode, params]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'Add',
                    Icon: AddIcon,
                    action: 'TemplateDetailsOnAdd',
                    disabled: operationMode === Operation.EDIT || operationMode === Operation.NEW,
                },
                {
                    label: 'Edit',
                    Icon: Edit,
                    disabled: operationMode === Operation.NEW || operationMode === Operation.EDIT || !params.get('TemplateId'),
                    action: 'TemplateDetailsOnEdit',
                },
                {
                    label: 'Delete',
                    Icon: RemoveCircle,
                    disabled: operationMode === Operation.NEW || operationMode === Operation.EDIT || !params.get('TemplateId'),
                    action: 'TemplateDetailsOnDelete',
                },
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    disabled: !(operationMode === Operation.EDIT || operationMode === Operation.NEW),
                    action: 'TemplateDetailsCancel',
                },
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    disabled: !(operationMode === Operation.EDIT || operationMode === Operation.NEW),
                    action: 'TemplateDetailsOk',
                },
            ]
        });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (isDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !isDirty;
            },
            async TemplateDetailsOnAdd(): Promise<void> {
                setInternalDirty(true);
                changeOperationMode(Operation.NEW);
            },
            async TemplateDetailsOnEdit(): Promise<void> {
                setInternalDirty(true);
                changeOperationMode(Operation.EDIT);
            },
            async TemplateDetailsOnDelete(): Promise<void> {
                deleteTemplate(selectedTemplateId);
            },
            async TemplateDetailsCancel(): Promise<void> {
                if (isDirty) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: async () => {
                            resetForm();
                            setInternalDirty(false);
                            changeOperationMode(Operation.BROWSE);
                        }
                    });
                } else {
                    changeOperationMode(Operation.BROWSE);
                }
            },
            async TemplateDetailsOk(): Promise<void> {
                saveTemplateDetails(formValues);
            },
        }),
        [selectedTemplateId, operationMode, formValues, isDirty, formSyncErrors, isValid, formAsyncErrors]
    );

    async function saveTemplate(selectedTemplate: number, query: any): Promise<any> {

        return saveTemplateMutateAsync({
            TemplateId: operationMode === Operation.NEW ? 0 : selectedTemplate,
            ...query,
        });
    }

    async function saveTemplateDetails(values: any): Promise<void> {
        if (!isValid || !isValidForm(values) || (formSyncErrors && Object.keys(formSyncErrors).length > 0) || Object.keys(formAsyncErrors).length > 0) {
            const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
            if (requiredError) {
                showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
                Object.entries(formSyncErrors)
                    .filter(([, value]) => value === 'Required')
                    .forEach(([key]) => touchFormFields(key));
            } else {
                showSnackBar({ variant: 'warning', message: 'Please enter valid values' });
            }
        } else {
            const response = await saveTemplate(selectedTemplateId, values);
            if (response?.Status && response?.TemplateDetails) {
                const { TemplateDetails: details } = response;
                if (details) {
                    setTemplateQueryData(Number(selectedTemplateId), {
                        Status: true,
                        TemplateDetails: operationMode !== Operation.NEW ? details : {}
                    });

                    if (operationMode === Operation.NEW) {
                        searchById(details?.inlineObject?.TemplateId);
                    }
                    changeOperationMode(Operation.BROWSE);
                }
            } else {
                showSnackBar({ variant: 'error', message: 'Details failed to save.' });
                changeOperationMode(Operation.BROWSE);
            }
            setInternalDirty(false);
        }
    }

    async function deleteTemplate(templateId: number): Promise<void> {
        const response = await deleteTemplateMutateAsync({ TemplateId: templateId });
        if (response?.Status && response?.Forms[0]?.MessageType === 'Success') {
            search('');
        }
    }

    const isValidForm = (values) => {
        const { Name, Description, ResponsibleUser } = values;
        if (Object.keys(values).length > 0 && Name && Description && ResponsibleUser) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={searchLoading || (fetchTemplateLoading && params.get('TemplateId')) || saveTemplateLoading}
                formName={'TemplateDetailsForm'}
                schema={TemplateDetailsForm}
                includeTabsHeight={false}
                initialValues={operationMode === Operation.NEW ? (newTemplate?.TemplateDetails.inlineObject || {}) : (template?.TemplateDetails?.inlineObject || {})}
                valuesSchema={operationMode === Operation.NEW ? (newTemplate?.TemplateDetails.schema || {}) : (template?.TemplateDetails?.schema || {})}
                operationMode={operationMode}
                summaryTableRenderer={
                    (() => <SummaryTable
                        selectedTemplate={{ ...template?.TemplateDetails?.inlineObject, templateId: template?.TemplateDetails?.inlineObject?.TemplateId }}
                        loading={false}
                        data={SummaryTableSchema}
                    />)}
                fieldExtensions={{
                    Name: {
                        fieldRef: (Ref) => templateNameFieldRef.current = Ref
                    }
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(TemplateDetails)));
