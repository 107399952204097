import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as supplierActions, selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

interface ISupplierItem {
  Supplier: number;
  SupplierDisplay: string;
  SupplierLabel: string;
  Abbrev: string;
  City: string;
}

const SupplierSearchLookUpDrawer = buildSearchLookUpDrawer<ISupplierItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Suppliers',
    searchPlaceholder: 'Search for suppliers...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'SupplierDisplay',
        label: 'Supplier account number'
      },
      {
        filter: 'SupplierLabel',
        label: 'Supplier name'
      },
      {
        filter: 'Abbrev',
        label: 'Abbreviation'
      },
      {
        filter: 'City',
        label: 'City'
      }
    ],
    records: supplierSelectors.all(state) || [],
    selectedRecord: supplierSelectors.selected(state),
    selectedSortFilter: 'SupplierDisplay',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isSupplierLookUpOpen(state),
    isLoading: supplierSelectors.isLoading(state),
    nextPage: supplierSelectors.nextPage(state),
    prevPage: supplierSelectors.prevPage(state),
    pageSize: supplierSelectors.pageSize(state),
    totalPages: supplierSelectors.totalPages(state),
    loadingPrevPage: supplierSelectors.loadingPrevPage(state),
    loadingNextPage: supplierSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'Supplier',
    fieldsToDisplay: ['SupplierDisplay', 'SupplierLabel', 'Abbrev', 'City'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('Supplier', selectedRecord.Supplier);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const supplierId = parseInt(matchParams.get('Supplier'));
      if (supplierId) {
        return {
          id: supplierId,
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: supplierActions.search,
  searchById: supplierActions.searchById,
  changeSelectedRecord: supplierActions.setSelected,
  onToggle: uiActions.toggleSupplierLookUp,
  fetchNextPage: supplierActions.fetchNextPage,
  fetchPrevPage: supplierActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearchLookUpDrawer);
