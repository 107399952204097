import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as lookupActions, selectors as lookupSelectors } from 'ducks/lookup';
import ExtendedTextFieldContainer from './ExtendedTextFieldContainer';

const mapStateToProps = (state) => ({
  results: lookupSelectors.options(state) || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchLookupOptions: lookupActions.fetchData,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedTextFieldContainer);
