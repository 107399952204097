export const CircularProgressSize = 50;
export const SnackBarDisplayTimeMilliseconds = 10000;
export const PaperElevation = 2;
export const SummaryBackPaperElevation = 0;
export const MoProV3 = 'V3';
export const MoProV2 = 'V2';
export const PROGRAM_NAME_INDEX = 3;
export const ALLOWED_CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789';

//names of keys
export enum HotKeyNames {
    Tab = 'Tab',
    ShiftTab = 'ShiftTab',
    Up = 'Up',
    Down = 'Down',
    Enter = 'Enter',
    Escape = 'Escape',
    CtrlB = 'CtrlB',
    CtrlL = 'CtrlL',
    CtrlK = 'CtrlK',
    CtrlS = 'CtrlS',
    CtrlO = 'CtrlO',
    CtrlQ = 'CtrlQ',
    CtrlU = 'CtrlU',
    CtrlP = 'CtrlP',
    CtrlR = 'CtrlR',
    CtrlBackspace = 'CtrlBackspace',
    CtrlEnter = 'CtrlEnter',
}

// name and key sequence pair
export const KEY_MAP = {
    [HotKeyNames.Tab]: 'tab',
    [HotKeyNames.ShiftTab]: 'shift+tab',
    [HotKeyNames.Up]: 'up',
    [HotKeyNames.Down]: 'down',
    [HotKeyNames.Enter]: 'enter',
    [HotKeyNames.Escape]: 'escape',
    [HotKeyNames.CtrlL]: 'ctrl+l',
    [HotKeyNames.CtrlK]: 'ctrl+k',
    [HotKeyNames.CtrlB]: ['ctrl+b', 'cmd+b'],
    [HotKeyNames.CtrlS]: ['ctrl+s', 'cmd+s'],
    [HotKeyNames.CtrlO]: ['ctrl+o', 'cmd+o'],
    [HotKeyNames.CtrlP]: ['ctrl+p', 'cmd+p'],
    [HotKeyNames.CtrlQ]: ['ctrl+q', 'cmd+q'],
    [HotKeyNames.CtrlU]: ['ctrl+u', 'cmd+u'],
    [HotKeyNames.CtrlR]: ['ctrl+r', 'cmd+r'],
    [HotKeyNames.CtrlBackspace]: ['ctrl+backspace', 'cmd+Backspace'],
    [HotKeyNames.CtrlEnter]: ['ctrl+enter', 'cmd+enter'],
};

export const ENTER_KEY = 13;
export const ARROW_UP = 38;
export const ARROW_DOWN = 40;
export const ARROW_RIGHT = 39;
export const ARROW_LEFT = 37;
export const SPACEBAR = 32;
export const ESCAPE = 27;
export const TAB = 9;

export const autoHideDuration = 3000;
export const KeyPressTimeout = 400;
export const searchModalTimeout = 100;
