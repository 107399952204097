import { fetchPost, fetchGet, objectify } from '../utils';

export const search = async (query): Promise<any> => {
  const { SearchText, Sort, BatchPage, Type, CheckProductActive, CheckSupplierProduct, AlternateProductCheck } = query;

  return fetchPost('/InventoryEnquiry/ProductSearch', { SearchText, Sort, BatchPage, Type, CheckProductActive, CheckSupplierProduct, AlternateProductCheck }, null, 'Inline')
    .then((result) => (result && result.InventoryProductSearch &&
    {
      products: result.InventoryProductSearch.map((val) => ({ inlineObject: objectify(val), schema: val })),
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
    })
      ||
      { products: [] });
};

export const searchById = async ({ ProductId }): Promise<any> => {
  return fetchGet(`/InventoryEnquiry/Product/${ProductId}/Details`, null, 'Inline')
    .then((result) => (result && result.ProductDetails && {
      products: [{ inlineObject: objectify(result.ProductDetails), schema: result.ProductDetails }],
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1,
    }) || { products: [] });
};
