import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { IProductReceiptsProperties } from '../interfaces';

class ProductReceipts extends React.PureComponent<IProductReceiptsProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/purchase-order-enquiry/product-receipts':
          onInitialLoad('ProductReceipts');
          break;
        default:
          onInitialLoad('ProductReceipts');
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={this.props.notifications}
          entity='Product Receipts'
        />
        <FormView
          formName='ProductReceipts'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default ProductReceipts;
