import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, isDirty, reset, isValid } from 'redux-form';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as lookupSelectors, actions as lookupActions } from 'ducks/lookup';
import FilterRow from './FilterRow';

const mapStateToProps = (state, ownProps) => (
  {
    operationMode: operationSelectors.operationMode(state),
    validate: ownProps.validate,
    form: ownProps.formName,
    key: ownProps.formName,
    values: getFormValues(ownProps.formName)(state),
    dirty: isDirty(ownProps.formName)(state),
    isValid: isValid(ownProps.formName)(state),
    lookupList: lookupSelectors.all(state)
  });

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    reset: reset,
    fetchLookups: lookupActions.fetch,
  },
  dispatch);

export default compose<any, any, any>(
  connect(mapStateToProps, {}),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: true
  })
)(connect(mapStateToProps, mapDispatchToProps)(FilterRow));
