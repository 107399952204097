import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import MTDSaleByRep from './MTDSaleByRep';
import { IConfigurationProperties } from './MTDSaleByRep.properties';
import * as api from 'api/dashboardMaintenance/dashboardWidgets/MTDSales';
import { touch, getFormValues, change } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
const MTD_SALES_FORM_NAME = 'MTDSalesConfiguration';

const mapStateToProps = (state: IApplicationState) => ({
    modalFormValues: getFormValues(MTD_SALES_FORM_NAME)(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        touchFormFields: (...args: any) => touch(MTD_SALES_FORM_NAME, ...args),
        updateFormField: (FieldName: string, FieldValue: any) => change(MTD_SALES_FORM_NAME, FieldName, FieldValue)
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
    IConfigurationProperties, 'getSaleHistory'>>) => {
    return (
        <MTDSaleByRep
            getSaleHistory={api.getSaleHistory}
            {...props} />
    );
});
