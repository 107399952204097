import React from 'react';
import { withStyles } from '@material-ui/core';
import {
    Cancel as CancelIcon, CheckCircle, ViewList, LibraryBooks
} from '@markinson/uicomponents-v2/SvgIcons/';
import FormView from 'components/FormView';
import PickSlipLineDetailsForm from './LineDetails.from';
import styles from './LineDetails.styles';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from './LineDetails.summaryTable.schema';
import ILineDetailsProperties, { ILineDetailsHandle } from './LineDetails.properties';
import { useCancelPickSlipLineDetails, useRetrievePickSlipLineDetails } from 'api/pickSlips/pickslip';
import { useCalculateLine, useUpdateLine, IUpdateLinePayload } from 'api/pickSlips/slipLinesUpdated';
import { PickSlipLineCalculationFields } from '../PickSlips.constants';
import { PickSlipAPIType, useLostSale, useMultipleBom, usePriceOverride } from '../PickSlips.hooks';
import { PriceOverrideScreenType } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import SerialDialog from 'components/common/SerialDialog';
import { fetchProductSerials } from 'api/product/productSearch';
import { IBinLotFacade } from 'api/swaggerTypes';
import { useUpdateProductWIPLotSerial } from 'api/product/productSearchUpdated';
import LotDialog from 'components/common/LotDialog';
import PartialSerialDialog from 'components/common/PartialSerialDialog';
import BinLocationsDialog from 'components/common/BinLocationsDialog';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { Route } from 'react-router';
import KitComponents from '../KitComponents';
import KitComponentDetails from '../KitComponents/KitComponentDetails';
import { usePSContextSelector, usePSDispatch } from '../PickSlips.context';
import { IKitComponentDetailsHandle } from 'components/common/KitComponents/KitComponentDetails/KitComponentDetails.properties';
import { useLoadComponents } from 'api/pickSlips/components';
import { isScreenRequest } from 'api/utils';
import { useAsyncClickHandler } from 'utils/hooks';

const DifferentChangedField = {
    QuoteCurrency: 'QuotedCurrency'
};

const LineDetails = (props: ILineDetailsProperties, ref: React.Ref<ILineDetailsHandle>) => {
    const { classes, dirty, match, formValues, changeSelectedTab, resetForm } = props;
    const { setActionBar } = React.useContext(ActionBarContext);
    const [internalDirty, setInternalDirty] = React.useState<boolean>(false);
    const isDirty = internalDirty || dirty;
    const onCancelClicked = React.useRef(false);

    const params = new URLSearchParams(location.search);
    const lineNumber = Number(params.get('LineNumber') ?? '');
    const despatchId = Number(params.get('DespatchId') ?? '');
    const isPathLineDetails = React.useMemo(() => match && match.url && match.url === '/pick-slips/line-details', [match]);

    const { initiateLostSale, isLostSale } = useLostSale();
    const { setPriceOverrideState, isPriceOverrideScreenRequest } = usePriceOverride();
    const { setMultipleBomState, closeDialog: closeBomDialog } = useMultipleBom();

    const loadComponentsMutation = useLoadComponents();
    const despatchLineId = usePSContextSelector<'DespatchLineId'>((state) => state.DespatchLineId);
    const componentId = usePSContextSelector<'ComponentId'>((state) => state.ComponentId);

    const retrievePickSlipLineDetailsMutation = useRetrievePickSlipLineDetails();
    const [pickSlipLineDetailsData, setPickSlipLineDetailsData] = React.useState(null);
    const pickSlipLineDetailsInlineObject = pickSlipLineDetailsData?.inlineObject;
    const calculateLineMutation = useCalculateLine();
    const updateLine = useUpdateLine();
    const contextDispatch = usePSDispatch();

    const { showSnackBar } = useSnackBar();

    const [enableLotSerial, setEnableLotSerial] = React.useState<boolean>(false);
    const [fullSerialDialogOpen, setFullSerialDialogOpen] = React.useState<boolean>(false);
    const [lotDialogOpen, setLotDialogOpen] = React.useState<boolean>(false);
    const [partialSerialDialogOpen, setPartialSerialDialogOpen] = React.useState<boolean>(false);
    const [binLocationsDialogopen, setBinLocationsDialogopen] = React.useState(false);

    const updateLotSerialMutation = useUpdateProductWIPLotSerial();
    const cancelLineMutation = useCancelPickSlipLineDetails();

    const kitComponentLineDetailsRef = React.useRef<IKitComponentDetailsHandle>();

    const isBinTracked = React.useMemo(
        () => pickSlipLineDetailsInlineObject?.BinTracked === 'f',
        [pickSlipLineDetailsInlineObject]
    );
    const isComponentsEnabled = React.useMemo(
        () => pickSlipLineDetailsInlineObject?.LineType === 'k' &&
            (
                pickSlipLineDetailsInlineObject?.KittingQuantity > 0 ||
                pickSlipLineDetailsInlineObject?.BackOrderedQuantity > 0
            ),
        [pickSlipLineDetailsInlineObject]
    );
    const lotSerialTracked = React.useMemo(
        () => pickSlipLineDetailsInlineObject?.LotSerialTracked?.toLowerCase(),
        [pickSlipLineDetailsInlineObject]
    );

    const fetchLineDetails = React.useCallback(
        async () => {
            if (!isPathLineDetails || !despatchId || !lineNumber) {
                return;
            }
            const response = await retrievePickSlipLineDetailsMutation.mutateAsync({
                DespatchId: despatchId,
                LineNumber: lineNumber,
                urlQueryParams: {
                    DespatchLineId: despatchLineId
                }
            });

            setPickSlipLineDetailsData(response?.PickSlipLineDetails);

            if (response?.Status === true) {
                contextDispatch({
                    setDespatchLineId: response?.PickSlipLineDetails?.inlineObject?.DespatchLineId ?? null
                });
            }
        },
        [despatchId, lineNumber, despatchLineId, isPathLineDetails]
    );

    React.useEffect(
        () => {
            if (!despatchId || !lineNumber) {
                changeSelectedTab('Lines');
            }
        },
        [despatchId, lineNumber]
    );

    React.useEffect(
        () => {
            fetchLineDetails();
        },
        [isPathLineDetails, fetchLineDetails]
    );

    const serialDialogToogle = React.useCallback(
        (serialDialogsType: string, isOpen: boolean) => {
            switch (serialDialogsType) {
                case 'lf':
                    setLotDialogOpen(isOpen);
                    break;
                case 'sf':
                    setFullSerialDialogOpen(isOpen);
                    break;
                case 'sp':
                    setPartialSerialDialogOpen(isOpen);
                    break;
                case 'n':
                    setBinLocationsDialogopen(isOpen);
                    break;
                default:
            }
        },
        [setFullSerialDialogOpen, setPartialSerialDialogOpen]
    );

    const onCancel = React.useCallback(
        (): void => {
            onCancelClicked.current = true;
            cancelLine().then((response) => {
                if (response.Status) {
                    resetForm();
                    setInternalDirty(false);
                    changeSelectedTab('Lines');
                    contextDispatch({
                        setDespatchLineId: null,
                        setLineNumber: null,
                        setComponentId: null,
                        setComponentLineNumber: null
                    });
                }
            })
                .catch((err) => { console.warn(err); });

        },
        [changeSelectedTab, onCancelClicked]
    );

    const ok = React.useCallback(
        async (data?: IUpdateLinePayload) => {
            const response = await updateLine.mutateAsync({
                ...pickSlipLineDetailsInlineObject,
                ...formValues,
                ...data
            });

            const responsePickslipLineDetailsInline = response?.PickSlipLineDetails?.inlineObject;

            if (isLostSale(response?.Forms)) {
                await initiateLostSale(PickSlipAPIType.updateLine, despatchId, formValues?.LineNumber, formValues?.DespatchLineId);

                return response;
            }

            if (response?.Forms && isPriceOverrideScreenRequest(response?.Forms[0])) {
                setPriceOverrideState({
                    open: true,
                    data: {
                        ...responsePickslipLineDetailsInline?.PriceOverride,
                    } as any,
                    type: PriceOverrideScreenType[response?.Forms?.[0]?.FormId],
                    apiType: PickSlipAPIType.updateLine
                });
            }

            if (isScreenRequest(response?.Forms, 'SelectBomCode')) {
                setMultipleBomState({
                    open: true,
                    screen: 'LineDetails',
                    kitStatus: responsePickslipLineDetailsInline?.KitStatus,
                    bomCode: responsePickslipLineDetailsInline?.BomCode,
                    data: responsePickslipLineDetailsInline?.KitDetail
                });

                return response;
            }

            if (response?.Status === true) {
                setInternalDirty(false);
                changeSelectedTab('Lines');
                closeBomDialog();
                contextDispatch({
                    setDespatchLineId: null,
                    setLineNumber: null,
                    setComponentId: null,
                    setComponentLineNumber: null
                });
            }

            return response;
        },
        [despatchId, pickSlipLineDetailsInlineObject, formValues, changeSelectedTab]
    );

    const { onClick: onOk } = useAsyncClickHandler(ok, !calculateLineMutation.isLoading);

    const pickingOnClick = React.useCallback(
        () => serialDialogToogle(lotSerialTracked, true),
        [lotSerialTracked]
    );

    const loadComponents = React.useCallback(
        async (data?: IUpdateLinePayload) => {
            if (!pickSlipLineDetailsInlineObject?.DespatchLineId) {
                return;
            }
            const response = await loadComponentsMutation.mutateAsync({
                DocumentId: pickSlipLineDetailsInlineObject?.DespatchLineId,
                ...data
            });
            if (response?.Status === true && isScreenRequest(response?.Forms, 'SelectBomCode')) {
                setMultipleBomState({
                    open: true,
                    screen: 'LineDetails',
                    action: 'Components',
                    kitStatus: response?.Components?.KitStatus,
                    bomCode: response?.Components?.BomCode,
                    data: response?.Components?.KitDetail
                });
            } else if (response?.Status === true) {
                closeBomDialog();
                changeSelectedTab('KitComponents', {
                    search: {
                        DespatchId: despatchId,
                        LineNumber: lineNumber,
                        DespatchLineId: despatchLineId
                    }
                });
            }

            contextDispatch({
                setDespatchLineId: despatchLineId
            });
        },
        [pickSlipLineDetailsInlineObject, despatchId, lineNumber]
    );

    const centerIcons = React.useMemo(
        () => [
            {
                label: 'Picking',
                Icon: LibraryBooks,
                action: 'LineDetailsPicking',
                iconStyle: { fill: undefined },
                disabled: !enableLotSerial,
                callBack: pickingOnClick
            },
            {
                label: 'Components',
                Icon: ViewList,
                action: 'KitComponents',
                iconStyle: { fill: undefined },
                disabled: !isComponentsEnabled,
                callBack: loadComponents
            }
        ],
        [enableLotSerial, isComponentsEnabled, pickingOnClick, loadComponents]
    );

    const rightIcons = React.useMemo(
        () => [
            {
                label: 'Cancel',
                Icon: CancelIcon,
                iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                action: 'PickSlipsLineDetailsCancel',
                invokeMouseDownCallback: true,
                callBack: onCancel
            },
            {
                label: 'OK',
                Icon: CheckCircle,
                action: 'PickSlipsLineDetailsOk',
                callBack: onOk
            },
        ],
        [onCancel, onOk]
    );

    React.useEffect(
        () => {
            if (isPathLineDetails) {
                setEnableLotSerial(pickSlipLineDetailsInlineObject?.EnablePicking);
                setActionBar({
                    leftIcons: [],
                    centerIcons,
                    rightIcons
                });
            }
        },
        [pickSlipLineDetailsInlineObject, isPathLineDetails, location?.pathname, rightIcons, centerIcons]
    );

    const cancelLine = React.useCallback(
        async () => {
            const response = await cancelLineMutation.mutateAsync({
                DespatchLineId: pickSlipLineDetailsInlineObject.DespatchLineId
            });

            return response;
        },
        [pickSlipLineDetailsInlineObject]
    );

    const componentDetailOnCancel = React.useCallback(
        () => kitComponentLineDetailsRef.current.onCancel(),
        [changeSelectedTab]
    );
    const componentDetailOnOk = React.useCallback(
        async () => {
            await kitComponentLineDetailsRef.current.onOk();
        },
        [changeSelectedTab]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (isDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !isDirty;
            },
            onOk,
            components: loadComponents,
            onCancel,
            componentDetailOnCancel
        }),
        [isDirty, onOk, onCancel, cancelLine, componentDetailOnCancel, loadComponents]
    );

    const calculateLineDetail = React.useCallback(
        async (name, value) => {
            if (!despatchLineId || !despatchId || retrievePickSlipLineDetailsMutation.isLoading ||
                pickSlipLineDetailsInlineObject?.[name] === value) {
                return null;
            }
            const response = await calculateLineMutation.mutateAsync({
                ...pickSlipLineDetailsInlineObject,
                ...formValues,
                DespatchLineId: despatchLineId,
                DespatchId: despatchId,
                [name]: value,
                urlQueryParams: {
                    ChangedField: DifferentChangedField?.[name] ?? name
                }
            });

            if (response?.Status) {
                setPickSlipLineDetailsData(response?.PickSlipLineDetails);
                setEnableLotSerial(response.PickSlipLineDetails?.inlineObject?.EnablePicking);
                setInternalDirty(true);
            } else {
                resetForm();
            }

            return response;
        },
        [formValues, calculateLineMutation, despatchLineId, pickSlipLineDetailsInlineObject, despatchId, lineNumber, retrievePickSlipLineDetailsMutation]
    );

    const formViewOnBlur = React.useCallback(
        async (e: any): Promise<void> => {
            const fieldName = e?.target?.name;
            const fieldValue = e?.target?.value;

            if (onCancelClicked.current) {
                onCancelClicked.current = false;

                return;
            }

            if (fieldName) {

                if (!PickSlipLineCalculationFields.includes(fieldName)) {
                    return;
                }

                const isValueChanged = PickSlipLineCalculationFields.includes(fieldName) ? parseFloat(fieldValue) ? parseFloat(fieldValue) : 0 !== parseFloat(pickSlipLineDetailsInlineObject[fieldName])
                    : fieldValue !== pickSlipLineDetailsInlineObject[fieldName];

                const isInvalidQuantity = PickSlipLineCalculationFields.includes(fieldName) && (fieldValue === null || fieldValue === '');

                if (isValueChanged) {
                    setInternalDirty(true);
                    if (isInvalidQuantity) {
                        await calculateLineDetail(fieldName, 0);
                    } else {
                        await calculateLineDetail(fieldName, fieldValue);
                    }
                }
            }
        },
        [calculateLineDetail, pickSlipLineDetailsInlineObject]
    );

    const onLookupSelectedItemChange = React.useCallback(
        (name, item) => {
            if (item) {
                calculateLineDetail(name, item?.Code);
            }
        },
        [calculateLineDetail]
    );

    const updateLotSerial = React.useCallback(
        async (data) => {
            const response = await updateLotSerialMutation.mutateAsync({
                BinLot: data,
                urlQueryParams: {
                    DocumentId: pickSlipLineDetailsInlineObject?.DespatchLineId,
                    RequiredQuantity: (pickSlipLineDetailsInlineObject?.SuppliedQuantity ?? 0) + (pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0),
                    ProductId: pickSlipLineDetailsInlineObject?.ProductId,
                    SuppliedQuantity: pickSlipLineDetailsInlineObject?.SuppliedQuantity,
                    KittingQuantity: pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0,
                    WarehouseEntity: pickSlipLineDetailsInlineObject?.WarehouseEntity
                }
            });

            return response;
        },
        [pickSlipLineDetailsInlineObject]
    );

    const closeLotSerialDialogs = React.useCallback(
        (SerialDialogsType: string) => {
            serialDialogToogle(SerialDialogsType, false);
        },
        [setFullSerialDialogOpen, setLotDialogOpen, setPartialSerialDialogOpen]
    );

    const applyDialog = React.useCallback(
        (binLots: IBinLotFacade[]) => {
            updateLotSerial(binLots)
                .then((response) => {
                    if (response.Status) {
                        fetchLineDetails();
                        closeLotSerialDialogs(lotSerialTracked);
                    }
                })
                .catch((err) => { console.warn(err); });

        },
        [lotSerialTracked, updateLotSerial, closeLotSerialDialogs, fetchLineDetails, retrievePickSlipLineDetailsMutation]
    );

    const fetchProductSerialsCallback = React.useCallback(
        () => {
            const batchSize = 200;

            return fetchProductSerials(
                'Lookup',
                {
                    SearchText: '',
                    WarehouseEntity: pickSlipLineDetailsInlineObject?.Summary.Warehouse,
                    ProductId: pickSlipLineDetailsInlineObject?.ProductId,
                    DocumentType: 'Worktable',
                    DocumentId: pickSlipLineDetailsInlineObject?.DespatchLineId
                },
                1,
                batchSize
            );
        },
        [pickSlipLineDetailsInlineObject]
    );

    const componentOnPicking = React.useCallback(
        () => {
            kitComponentLineDetailsRef.current?.lotSerial();
        },
        [kitComponentLineDetailsRef]
    );

    return (
        <React.Fragment>
            <Route
                key={'KitComponents'}
                path={`${match?.path ?? ''}/kit-components`}
                exact
                render={() => (
                    <KitComponents
                        worksaleLineId={null}
                        DocumentId={pickSlipLineDetailsInlineObject?.DespatchLineId}
                        showDetailOnClick={(data) => {
                            changeSelectedTab('ComponentDetails', {
                                search: {
                                    DespatchId: despatchId,
                                    LineNumber: lineNumber,
                                    DespatchLineId: despatchLineId,
                                    ComponentLineNumber: data?.LineNumber,
                                    ComponentId: data?.ComponentId
                                }
                            });
                            contextDispatch({ setComponentLineNumber: data?.LineNumber, setComponentId: data?.ComponentId });
                        }}
                    />
                )} />
            <Route
                key={'ComponentDetails'}
                path={`${match?.path ?? ''}/component-details`}
                exact
                render={() => (
                    <KitComponentDetails
                        ref={kitComponentLineDetailsRef}
                        DocumentId={pickSlipLineDetailsInlineObject?.DespatchLineId}
                        worksaleId={null}
                        componentId={componentId}
                        LineNumber={lineNumber}
                        worksaleLineId={null}
                        disableSupplied={null}
                        onPicking={componentOnPicking}
                        onOk={componentDetailOnOk}
                        onCancel={componentDetailOnCancel}
                        navigateBack={() => changeSelectedTab('KitComponents')} />
                )} />
            <BinLocationsDialog
                open={binLocationsDialogopen}
                DocumentType={'Worktable'}
                DocumentId={pickSlipLineDetailsInlineObject?.DespatchLineId}
                LineNumber={lineNumber}
                WarehouseEntity={pickSlipLineDetailsInlineObject?.Summary.Warehouse}
                ProductId={pickSlipLineDetailsInlineObject?.ProductId}
                RequiredQuantity={(
                    (pickSlipLineDetailsInlineObject?.SuppliedQuantity ?? 0) +
                    (pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0)
                )}
                BinLots={pickSlipLineDetailsInlineObject?.BinLot}
                onApply={async (binLots) => { applyDialog(binLots); }}
                onCancel={() => { setBinLocationsDialogopen(false); }}
            />
            <LotDialog
                open={lotDialogOpen}
                isBinTracked={isBinTracked}
                DocumentType={'Worktable'}
                DocumentId={pickSlipLineDetailsInlineObject?.DespatchLineId}
                LineNumber={lineNumber}
                WarehouseEntity={pickSlipLineDetailsInlineObject?.Summary.Warehouse}
                ProductId={pickSlipLineDetailsInlineObject?.ProductId}
                RequiredQuantity={(
                    (pickSlipLineDetailsInlineObject?.SuppliedQuantity ?? 0) +
                    (pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0)
                )}
                BinLots={pickSlipLineDetailsInlineObject?.BinLot}
                onApply={async (binLots) => { applyDialog(binLots); }}
                onCancel={async () => { setLotDialogOpen(false); }}
            />
            <SerialDialog
                open={fullSerialDialogOpen}
                isBinTracked={isBinTracked}
                RequiredQuantity={(
                    (pickSlipLineDetailsInlineObject?.SuppliedQuantity ?? 0) +
                    (pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0)
                )}
                BinLots={pickSlipLineDetailsInlineObject?.BinLot}
                onApply={async (binLots) => { applyDialog(binLots); }}
                onCancel={async () => { setFullSerialDialogOpen(false); }}
                fetchProductSerials={fetchProductSerialsCallback}
            />
            <PartialSerialDialog
                DocumentType={'Worktable'}
                open={partialSerialDialogOpen}
                DocumentId={pickSlipLineDetailsInlineObject?.DespatchLineId}
                BinLots={pickSlipLineDetailsInlineObject?.BinLot}
                ProductId={Number(pickSlipLineDetailsInlineObject?.ProductId)}
                RequiredQuantity={(
                    (pickSlipLineDetailsInlineObject?.SuppliedQuantity ?? 0) +
                    (pickSlipLineDetailsInlineObject?.KittingQuantity ?? 0)
                )}
                WarehouseEntity={pickSlipLineDetailsInlineObject?.Summary.Warehouse}
                isBinTracked={isBinTracked}
                LineNumber={lineNumber}
                onApply={async (binLots) => { applyDialog(binLots); }}
                onCancel={async () => { setPartialSerialDialogOpen(false); }}
            />
            {isPathLineDetails && <div className={classes.formContainer}>
                <FormView
                    isLoading={false}
                    formName={'PickSlipLineDetailsForm'}
                    onBlur={formViewOnBlur}
                    schema={PickSlipLineDetailsForm}
                    includeTabsHeight={false}
                    initialValues={pickSlipLineDetailsInlineObject}
                    valuesSchema={pickSlipLineDetailsData?.schema}
                    operationMode={'EDIT'}
                    fieldExtensions={{
                        QuoteCurrency: {
                            onSelectedItemChange: (v) => onLookupSelectedItemChange('QuoteCurrency', v)
                        }
                    }}
                    summaryTableRenderer={
                        (() => <SummaryTable
                            loading={false}
                            selectedPickSlipLine={pickSlipLineDetailsData?.inlineObject?.Summary}
                            data={SummaryTableSchema}
                        />)}
                />
            </div>}
        </React.Fragment>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(LineDetails)));
