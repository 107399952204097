import React, { useState } from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button, HeaderFilter, Selection, Sorting, Paging, Editing } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import styles from './Fulfillment.styles';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from './SummaryTableSchema.json';
import Paper from '@material-ui/core/Paper';
import { IFulfillmentProps, IFulfillmentFiltersResponse, IFulfillmentHandle, ILineDetails, LineNumbers } from './Fulfillment.properties';
import { IFulfillmentLocationFacade, IMessageModelFacade, IValidatedResponse } from 'api/swaggerTypes';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import classNames from 'classnames';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { createDataSource, useFetchDataGridFilters, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { ArrowBack, AssistantDirection, PlaylistAddCheckCircle, SourceNotes, SwapHorizontalCircle } from '@markinson/uicomponents-v2/SvgIcons';
import WarehouseSelectionDialog from 'components/common/WarehouseSelectionDialog';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import { FormControlLabel, Radio } from '@material-ui/core';
import { useFetchFulfillmentDeliverySummary, useInitialiseFulfillmentColumns, useRetrieveFulfillment, useInternalTransfer, useDirectSupply } from 'api/Worksale/fulfillment';
import { isNull } from 'utils/utils';
import { WAREHOUSE_COLUMN_WIDTH, ICON_COLUMN_MIN_WIDTH, InitialLineDetails, LARGE_COLUMN_MIN_WIDTH, SMALL_COLUMN_MIN_WIDTH } from './Fulfillment.contsants';
import ProductCell from 'components/common/ProductCell';
import WorksaleMessages from '../WorksaleMessages';
import { WorksaleMessagesState } from '../WorksaleMessages/WorksaleMessages.properties';
import { useElementSize } from 'utils/hooks';
import { SUMMARY_PANEL_WIDTH } from '../Deliveries/constants';
import { ARROW_DOWN, ARROW_UP, SPACEBAR } from 'utils/constants';

const Fulfillment = (props: IFulfillmentProps, ref: React.Ref<IFulfillmentHandle>) => {
  const { classes, changeSelectedTab } = props;
  const { onFocusedCellChanging, preventDataGridDefaults } = useGridKeyboardNavigation();
  const initialDeliverySummary = useSPContextSelector(Selectors.WorksaleDeliverySummary);
  const dataGridRef = React.useRef<DataGrid>();
  const { setActionBar } = React.useContext(ActionBarContext);
  const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<string>('');
  const [isChangeWarehouseDialogOpen, setIsChangeWarehouseDialogOpen] = useState<boolean>(false);
  const [warehouseColumns, setWarehouseColumns] = useState<IFulfillmentLocationFacade[]>([]);
  const [selectedRowsKeys, setSelectedRowsKeys] = React.useState<number[]>([]);
  const [lineDetails, setLineDetails] = useState<ILineDetails>(InitialLineDetails);
  const [summaryTableData, setSummaryTableData] = useState<any>(null);
  const retrieveFulfillmentMutation = useRetrieveFulfillment();
  const { mutateAsync: initialiseFulfillmentColumns } = useInitialiseFulfillmentColumns();
  const { mutateAsync: internalTransfer } = useInternalTransfer();
  const { mutateAsync: directSupply } = useDirectSupply();
  const fulfillmentFiltersMutation = useFetchDataGridFilters<IFulfillmentFiltersResponse>();
  const deliverySummaryMutation = useFetchFulfillmentDeliverySummary();
  const [worksaleMessagesDialog, setWorksaleMessagesDialog] = useState<WorksaleMessagesState>({
    open: false,
    dialogTitle: undefined,
    description: undefined,
    messages: undefined,
    onBack: () => undefined,
    onContinue: () => undefined,
  });
  const [messages, setMessages] = useState<IMessageModelFacade[]>(undefined);

  const params = new URLSearchParams(location.search);
  const worksaleId = Number(useSPContextSelector<'WorksaleId'>((state) => state.WorksaleId)) || Number(params.get('WorksaleId')) || 0;
  const worksaleDeliveryId = Number(useSPContextSelector<'WorksaleDeliveryId'>((state) => state.WorksaleDeliveryId)) || Number(params.get('DeliveryId')) || 0;
  const fulfillmentContainerRef = React.useRef<HTMLDivElement>(null);
  const { width: fulfillmentContainerWidth } = useElementSize(fulfillmentContainerRef);

  const initialiseWarehouseColumns = async () => {
    if (worksaleId !== -1) {
      const result = await initialiseFulfillmentColumns({ worksaleId });
      const hasForms = result && result.hasOwnProperty('Forms');
      const isDataInValid = !result?.Status && hasForms || isNull(result) || isNull(result.FulfillmentLocations);
      setWarehouseColumns(isDataInValid ? [] : result.FulfillmentLocations);
    }
  };

  const OrderLinesMapper = (lineNumbers: LineNumbers) => lineNumbers.map((lineNumber) => ({ LineNumber: lineNumber }));

  const getDefaults = (FromWarehouse: string, OrderLines: ReturnType<typeof OrderLinesMapper>) => ({ WorksaleId: worksaleId, FromWarehouse, OrderLines });

  const handleInternalTransfer = async (warehouse: string, lineNumbers: LineNumbers, ignoreWarning?: boolean) => internalTransfer({ ...getDefaults(warehouse, OrderLinesMapper(lineNumbers)), urlQueryParams: { IgnoreWarning: ignoreWarning || false } });

  const handleSupply = async (SupplyBackorder: boolean, warehouse: string, lineNumbers: LineNumbers, ignoreWarning?: boolean) => directSupply({ SupplyRemaining: SupplyBackorder, ...getDefaults(warehouse, OrderLinesMapper(lineNumbers)), urlQueryParams: { IgnoreWarning: ignoreWarning || false } });

  const onContinueCallBack = React.useCallback(
    async (actionsCallback?: () => Promise<IValidatedResponse>) => {
      if (actionsCallback) {
        const response = await actionsCallback();
        validateProcessingProblems(response, actionsCallback);
      }
    },
    []
  );

  const populateFocusedSummaryTableData = React.useCallback(
    (focusedRow) => {
      if (worksaleDeliveryId && focusedRow) {
        deliverySummaryMutation.mutateAsync(
          {
            WorksaledelivId: worksaleDeliveryId.toString(),
            LineNumber: focusedRow.LineNumber,
            urlQueryParams: {
              Warehouse: focusedRow.Warehouse
            }
          }).then((r) => {
            if (r && r?.Status) {
              setSummaryTableData({ ...initialDeliverySummary, ...r?.DeliverySummary?.inlineObject });
            }
          });
      } else {
        setSummaryTableData(initialDeliverySummary);
      }
    },
    [worksaleDeliveryId]
  );

  const handleFocusedRowChange = React.useCallback(
    (e) => {
      const { row } = e;
      populateFocusedSummaryTableData(row?.data);
    },
    []
  );

  React.useEffect(
    () => {
      dataGridRef?.current?.instance?.selectRows(selectedRowsKeys, false);
    },
    [selectedRowsKeys]
  );

  const loadFilters = React.useCallback(
    async () => {
      fulfillmentFiltersMutation.mutate({
        FilterScreenId: 'Fulfillment',
        FilterScope: [{ scopeValue: worksaleDeliveryId.toString(), scopeName: 'WorksaleDeliveryId' }]
      });
    },
    [worksaleId, worksaleDeliveryId]
  );

  React.useEffect(
    () => {
      setSummaryTableData(initialDeliverySummary);
    },
    []
  );

  React.useEffect(
    () => {
      initialiseWarehouseColumns();
    },
    [worksaleId]
  );

  React.useEffect(
    () => {
      if (!worksaleId || !worksaleDeliveryId) {
        changeSelectedTab('Deliveries');
      }
    },
    [worksaleId, worksaleDeliveryId]
  );

  React.useEffect(
    () => {
      loadFilters();
    },
    [worksaleId, worksaleDeliveryId]
  );

  React.useEffect(
    () => {
      setActionBarButtons();
    },
    [changeSelectedTab, selectedRowsKeys, selectedWarehouse]
  );

  const onKeyDown = async (e) => {
    const keyboardEvent = e.event;
    preventDataGridDefaults(e);

    if (![ARROW_DOWN, ARROW_UP, SPACEBAR].includes(keyboardEvent.keyCode)) {
      return;
    }
  };

  const SelectAndFocusFirstRow = () => {
    setTimeout(
      () => {
        dataGridRef.current?.instance?.focus();
      },
      0
    );
  };

  const setActionBarButtons = (): void => {
    setActionBar({
      leftIcons: [
        {
          label: 'Back',
          Icon: ArrowBack,
          action: 'Back',
        }
      ],
      centerIcons: [
        {
          label: 'Supply in Full',
          Icon: PlaylistAddCheckCircle,
          action: 'SupplyFull',
          disabled: !(selectedRowsKeys.length > 0 && selectedWarehouse !== ''),
        },
        {
          label: 'Supply Remaining',
          Icon: AssistantDirection,
          action: 'SupplyBackorder',
          iconStyle: {
            transform: 'scaleX(-1)'
          },
          disabled: !(selectedRowsKeys.length > 0 && selectedWarehouse !== ''),
        },
        {
          label: 'Internal Transfer',
          Icon: SwapHorizontalCircle,
          action: 'InternalTransfer',
          disabled: !(selectedRowsKeys.length > 0 && selectedWarehouse !== ''),
        },
      ],
      rightIcons: []
    });
  };

  const loadLines = React.useCallback(
    async (BatchPage, BatchSize, Sort, filter) => {
      const response = await retrieveFulfillmentMutation.mutateAsync({
        WorksaledelivId: worksaleDeliveryId,
        WorksaleId: worksaleId,
        FulfillmentWarehouse: filter?.WarehouseLabel || [],
        FulfillmentStatus: filter?.Status || [],
        OutstandingQuantity: filter?.RemainingDisplay || [],
        urlQueryParams: {
          BatchPage,
          BatchSize,
          Sort
        }
      });

      const lines = response?.Fulfillment ?? undefined;

      if (lines && messages && messages.length > 0) {
        messages?.forEach((message) => {
          if (message.MessageFile === 'worksaleline' && message.MessageKey) {
            lines
              .filter((line) => line.LineNumber === parseInt(message.MessageKey))?.forEach((matchedLine) => { (matchedLine as any).AlertMessage = message.MessageDetail; });
          }
        });

        setSelectedRowsKeys(messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey)));
      }

      return lines ?? [];
    },
    [worksaleId, worksaleDeliveryId, messages]
  );

  const fulfillmentDataSource = React.useMemo(
    () => createDataSource(
      'LineNumber',
      { fetch: loadLines },
      {
        WarehouseLabel: fulfillmentFiltersMutation.data?.FulfillmentWarehouse?.Data,
        Status: fulfillmentFiltersMutation.data?.FulfillmentStatus?.Data,
        RemainingDisplay: fulfillmentFiltersMutation.data?.OutstandingBOQuantity?.Data
      }
    ),
    [loadLines, fulfillmentFiltersMutation?.data]
  );

  const reloadGrid = React.useCallback(
    async (result) => {
      if (result && result?.Status) {
        dataGridRef.current?.instance.deselectRows(selectedRowsKeys).then(() => {
          setSelectedRowsKeys([]);
          fulfillmentDataSource.reload();
        });
      }
    },
    [dataGridRef, fulfillmentDataSource, selectedRowsKeys]
  );

  const closeWorksaleMessagesDialog = React.useCallback(
    async () => {
      setWorksaleMessagesDialog({
        open: false,
        dialogTitle: undefined,
        description: undefined,
        messages: undefined,
        onBack: () => undefined,
        onContinue: () => undefined,
      });
    },
    [setWorksaleMessagesDialog, worksaleMessagesDialog]
  );

  const validateProcessingProblems = React.useCallback(
    async (result, actionsCallback?: () => Promise<IValidatedResponse>) => {
      if (result?.Messages && result?.Messages.length > 0) {
        setWorksaleMessagesDialog(
          {
            open: true,
            messages: result?.Messages,
            description: 'The issues listed below have been identified during processing. Any errors found must be corrected before you can continue.\n Select BACK to return to the Fulfillment screen. Warning and Errors are highlighted in the fulfillment grid.',
            onContinue: () => { onContinueCallBack(actionsCallback); },
            onBack: () => { closeWorksaleMessagesDialog(); }
          });
        setMessages(result?.Messages);
      } else {
        setMessages(undefined);
        reloadGrid(result);
        closeWorksaleMessagesDialog();
      }
      loadFilters();
    },
    [reloadGrid, loadFilters]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      async SupplyFull(): Promise<void> {
        const result = await handleSupply(false, selectedWarehouse, selectedRowsKeys) as any;
        let newKeys = [];
        if (result?.Messages && result?.Messages.length > 0) {
          newKeys = result?.Messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey));
        }
        validateProcessingProblems(result, () => handleSupply(false, selectedWarehouse, newKeys, true));
      },
      async SupplyBackorder(): Promise<void> {
        const result = await handleSupply(true, selectedWarehouse, selectedRowsKeys) as any;
        let newKeys = [];
        if (result?.Messages && result?.Messages.length > 0) {
          newKeys = result?.Messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey));
        }
        validateProcessingProblems(result, () => handleSupply(true, selectedWarehouse, newKeys, true));
      },
      async InternalTransfer(): Promise<void> {
        const result = await handleInternalTransfer(selectedWarehouse, selectedRowsKeys) as any;
        let newKeys = [];
        if (result?.Messages && result?.Messages.length > 0) {
          newKeys = result?.Messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey));
        }
        validateProcessingProblems(result, () => handleInternalTransfer(selectedWarehouse, newKeys, true));
      },
    }),
    [validateProcessingProblems, selectedWarehouse, selectedRowsKeys]
  );

  const onContentReady = React.useCallback(
    (e) => {
      if (isFirstTime && e.component.getDataSource()?.items().length) {
        SelectAndFocusFirstRow();
        setIsFirstTime(false);
      }
    },
    [isFirstTime]
  );

  const onSelectedRowChanged = React.useCallback(
    async ({ component }) => {
      const selectedRowKeys = await component?.getSelectedRowKeys();
      setSelectedRowsKeys(selectedRowKeys);
    },
    [setSelectedRowsKeys]
  );

  const onChangeDeliveryClicked = ({ data }) => {
    setIsChangeWarehouseDialogOpen(true);
    setLineDetails((prevProps) => ({
      ...prevProps,
      LineNumber: [data.LineNumber],
      ProductId: data.ProductId
    }));
  };

  const handleSupplyListener = async (SupplyBackorder: boolean, ignoreWarning?: boolean) => {
    const result = await handleSupply(SupplyBackorder, lineDetails.FromWarehouse, lineDetails.LineNumber, ignoreWarning) as any;
    if (result && result?.Status) {
      setIsChangeWarehouseDialogOpen(false);
      let newKeys = [];
      if (result?.Messages && result?.Messages.length > 0) {
        newKeys = result?.Messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey));
      }
      validateProcessingProblems(result, () => handleSupply(SupplyBackorder, lineDetails.FromWarehouse, newKeys, true));
      setLineDetails(InitialLineDetails);
    }
  };

  const warehouseSelectionModalActions: IActionItem[] = [
    {
      title: 'Supply in full',
      isDefault: false,
      listener: async () => { handleSupplyListener(false); },
      disabled: !(lineDetails.FromWarehouse),
    },
    {
      title: 'Supply remaining',
      isDefault: false,
      listener: async () => { handleSupplyListener(true); },
      disabled: !(lineDetails.FromWarehouse),
    },
    {
      title: 'Internal Transfer',
      isDefault: false,
      listener: async () => {
        const result = await handleInternalTransfer(lineDetails.FromWarehouse, lineDetails.LineNumber) as any;
        if (result && result?.Status) {
          setIsChangeWarehouseDialogOpen(false);
          let newKeys = [];
          if (result?.Messages && result?.Messages.length > 0) {
            newKeys = result?.Messages?.filter((m) => m.MessageType === 'W')?.map((m) => Number(m.MessageKey));
          }
          validateProcessingProblems(result, () => handleInternalTransfer(selectedWarehouse, newKeys, true));
        }
      },
      disabled: !(lineDetails.FromWarehouse),
    },
    {
      title: 'Cancel',
      isDefault: true,
      listener: () => {
        setIsChangeWarehouseDialogOpen(false);
      }
    }
  ];

  const handleRadioChange = (event) => {
    setSelectedWarehouse(event.target.value);
  };

  const getWarehouseColumnClassname = (cellData) => {
    const { OnOrder, Remaining } = cellData?.data;
    if (cellData.value < Remaining) return 'red';
    if (cellData.value < OnOrder && cellData.value >= Remaining) return 'amber';
    if (cellData.value >= OnOrder) return 'green';

    return '';
  };

  const warehouseCellRenderer = (cellData) => {

    return (
      <div className={`${classes.warehouseQuantity} ${classes[getWarehouseColumnClassname(cellData)]}`}>
        {cellData.displayValue}
      </div>
    );
  };

  const getHeaderCellRender = (col: IFulfillmentLocationFacade) => (
    <div className={classes.header}>
      <p className={classes.capabilityLabel}>{col.Label1}</p>
      <p className={classes.capabilityPercent}>{col.Label2}</p>
      <FormControlLabel
        classes={{ root: classes.radio }}
        label=''
        value={col.Warehouse}
        control={
          <Radio
            checked={selectedWarehouse === col.Warehouse}
            onChange={handleRadioChange}
            value={col.Warehouse}
            name='warehouseRadioGroup'
          />
        }
      />
    </div>
  );

  return (
    <>
      <div className={classes.container} ref={fulfillmentContainerRef}>
        <Paper className={classes.grid} style={{ width: `${fulfillmentContainerWidth - SUMMARY_PANEL_WIDTH}px` }}>
          <DataGrid
            ref={dataGridRef}
            className={classes.FulfillmentDataGrid}
            id='FulfillmentGrid'
            onKeyDown={onKeyDown}
            onFocusedCellChanging={onFocusedCellChanging}
            dataSource={fulfillmentDataSource}
            noDataText=''
            height={'100%'}
            repaintChangesOnly={true}
            remoteOperations={true}
            allowColumnReordering={true}
            columnResizingMode={'nextColumn'}
            allowColumnResizing={true}
            renderAsync={true}
            showBorders={false}
            onSelectionChanged={onSelectedRowChanged}
            hoverStateEnabled={true}
            focusedRowEnabled={true}
            onFocusedRowChanged={handleFocusedRowChange}
            onContentReady={onContentReady}
          >
            <HeaderFilter visible />
            <Editing
              refreshMode={'reshape'}
              mode='row'
              allowAdding={false}
              allowDeleting={true}
              allowUpdating={false}
              confirmDelete={true}
            />
            <Column
              dataField={'ProductIdDisplay'}
              caption={'Product'}
              allowEditing={false}
              allowHeaderFiltering={false}
              width={SMALL_COLUMN_MIN_WIDTH}
              cellComponent={(allProps) => (
                <ProductCell
                  urlParamKeys={['ProductId']}
                  toLink={'/inventory-enquiry/product-details'}
                  hideIcon={false}
                  {...allProps} />
              )}
            />
            <Column
              caption={'On Order'}
              dataField={'OnOrderDisplay'}
              allowFiltering={false}
              width={SMALL_COLUMN_MIN_WIDTH}
              alignment='right'
            />
            <Column
              caption={'Remaining'}
              dataField={'RemainingDisplay'}
              allowFiltering={true}
              width={SMALL_COLUMN_MIN_WIDTH}
              alignment='right'
            >
              <HeaderFilter
                dataSource={fulfillmentFiltersMutation.data?.OutstandingBOQuantity?.Data} />
            </Column>
            <Column
              caption={'Warehouse'}
              dataField={'WarehouseLabel'}
              width={WAREHOUSE_COLUMN_WIDTH}
              allowFiltering={true}
            >
              <HeaderFilter
                dataSource={fulfillmentFiltersMutation.data?.FulfillmentWarehouse?.Data} />
            </Column>
            <Column
              caption={'Status'}
              dataField={'Status'}
              allowFiltering={true}
              width={LARGE_COLUMN_MIN_WIDTH}
            >
              <HeaderFilter
                dataSource={fulfillmentFiltersMutation.data?.FulfillmentStatus?.Data} />
            </Column>
            {
              warehouseColumns?.map((col) => <Column
                key={col.DataField}
                cssClass={col.Warehouse === selectedWarehouse ? classes.highlight : undefined}
                dataField={col.DataField}
                allowFiltering={false}
                width={SMALL_COLUMN_MIN_WIDTH}
                cellRender={warehouseCellRenderer}
                headerCellRender={() => getHeaderCellRender(col)}
                visible={!col.Hidden}
              />
              )
            }
            <Column
              caption={'Description'}
              dataField={'Description'}
              allowFiltering={false}
              width={LARGE_COLUMN_MIN_WIDTH}
            />
            <Column alignment={'center'} type={'buttons'} allowResizing={false} fixed={true} width={ICON_COLUMN_MIN_WIDTH}>
              <Button render={
                (rowData) => <a className={classes.anchor}> <SourceNotes
                  className={classNames(classes.actionButtonIcon, !Number(worksaleDeliveryId) && classes.disabledIcon)}
                  onClick={() => onChangeDeliveryClicked(rowData)} /></a>
              } />
            </Column>
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
            <Sorting mode='none' />
            <Selection mode={'multiple'} showCheckBoxesMode='always' />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
          </DataGrid>
        </Paper>
        <SummaryTable
          loadingFulfillmentSummary={false}
          fulfillmentSummary={summaryTableData}
          data={
            SummaryTableSchema.Fulfillment
          }
        />
      </div >
      <WarehouseSelectionDialog
        open={isChangeWarehouseDialogOpen}
        ProductId={lineDetails?.ProductId}
        title='Warehouse Update'
        actions={warehouseSelectionModalActions}
        onWarehouseSelectionChanged={(warehouse) => {
          setLineDetails((prevProps) => ({
            ...prevProps,
            FromWarehouse: warehouse,
          }));
        }}
      />
      <WorksaleMessages
        open={worksaleMessagesDialog.open}
        messages={worksaleMessagesDialog.messages}
        onBack={worksaleMessagesDialog.onBack}
        onContinue={worksaleMessagesDialog.onContinue}
        dialogTitle={worksaleMessagesDialog.dialogTitle}
        description={worksaleMessagesDialog.description}
      />
    </>
  );
};

export default withStyles(styles, { index: 1 })(React.forwardRef(Fulfillment));
