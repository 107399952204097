import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../Fields/PaperContainer';
import { Grid as GridLayout } from '@material-ui/core';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import Typography from '@markinson/uicomponents-v2/Typography';
import classNames from 'classnames';
import renderComponent from '../RenderComponent';
import cssStyles from './DynamicFormView.scss';
import { OPERATIONS } from 'utils/operations';
import { isNull, isPrecedentFieldsFilled } from 'utils/utils';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    marginBottom: 16,
    marginRight: 16,
    height: 'calc(100% - 48px)',
  },
});

const SubheaderStyles = {
  fontSize: '18px',
  fontWeight: 100,
  marginBottom: 0,
  paddingLeft: 0
}

const MIN_COLUMN_WIDTH = 400;

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

const validateEmail = (email = '') => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !re.test(email.toString().toLowerCase()) ? 'Invalid Email' : undefined
}

const validateConstraints = (data) => {
  const { value, constraints, type } = data;
  if (value && constraints) {
    if (constraints.minLength !== undefined) {
      if (value.length < constraints.minLength) {
        return 'Invalid Length';
      }
    }
    if (constraints.maxLength !== undefined) {
      if (value.length > constraints.maxLength) {
        return 'Invalid Length';
      }
    }
    if (type === 'number' && constraints.minValue !== undefined) {
      if (Number(value) < constraints.minValue) {
        return 'Invalid Value';
      }
    }
    if (type === 'number' && constraints.maxValue !== undefined) {
      if (Number(value) > constraints.maxValue) {
        return 'Invalid Value';
      }
    }
  }
  return undefined;
}

class DynamicFormView extends Component {

  constructor(props) {
    super();
    this.state = {
      readOnly: props.operationMode === OPERATIONS.BROWSE,
    }
  }

  componentWillUnmount() {
    const { resetFormAsyncErrors, formName } = this.props;
    resetFormAsyncErrors(formName);
  }

  renderField = (fieldSchema, rowItemInd) => {
    const { props, type, style } = fieldSchema;
    const {
      valuesSchema, operationMode, onBlur, customerId, entity, supplierId, productId, widgetTypeId,
      fieldExtensions, formValues = {}, isV2, isV3, worksaleId, setFieldAsyncError, formName
    } = this.props;

    let isRequired = !!props.required;
    let isReadOnly = this.state.readOnly;

    if (valuesSchema && valuesSchema[props.name]) {
      const objVal = valuesSchema[props.name];
      isRequired = isRequired || (objVal && objVal.Required)
      isReadOnly = isReadOnly || (objVal && objVal.ReadOnly)
    }


    if (fieldExtensions && fieldExtensions[props.name]) {
      const extendedProperties = fieldExtensions[props.name];
      const handleToggleChange = (e) => {
        const extendedChange = extendedProperties['onChange'];
        if (extendedChange) extendedChange(e);
      }
      for (let propertyName in extendedProperties) {
        if (extendedProperties.hasOwnProperty(propertyName)) {
          if (type === 'TOGGLE_FIELD' && propertyName === 'onChange') {
            props['onChange'] = handleToggleChange;
          } else {
            props[propertyName] = extendedProperties[propertyName];
          }
        }
      }
    }

    let validations = isRequired ? [required] : null;
    if (props.constraints) {
      const resp = (value) => {
        const data = { value, constraints: props.constraints, type: props.type }
        return validateConstraints(data)
      }
      validations = validations ? [...validations, resp] : [resp]
    }
    if (props && (type === 'ACTION_FIELD' || type === 'TEXT_FIELD') && props.type === 'email') {
      validations = validations ? [...validations, validateEmail] : [validateEmail]
    }

    if (type === 'SUBHEADER') {
      return <Subheader
        key={rowItemInd}
        style={SubheaderStyles}>
        {props.value}
      </Subheader>;
    }

    if (type === 'TYPOGRAPHY') {
      return <Typography
        key={rowItemInd}
        type={props.type}
        customStyles={props.customStyles}
      >
        {props.text}
      </Typography>;
    }

    if ((fieldSchema.type === 'ADDRESS_BLOCK' || fieldSchema.type === 'ADDRESS_BLOCK_EX') && valuesSchema) {
      isReadOnly = isReadOnly || addressReadOnly(props.name);
    }

    function addressReadOnly(type) {
      let keys = Object.keys(valuesSchema).filter(index => index.startsWith(type));
      let readOnly = false;
      keys.forEach(element => {
        const ReadOnly = valuesSchema[element].ReadOnly
        readOnly = isNull(ReadOnly) ? readOnly : ReadOnly;
      });
      return readOnly;
    }

    if (fieldSchema.props.isCustomerScoped) {
      props.params = props.params || {};
      props.params.CustomerId = customerId;
    }
    if (fieldSchema.props.isWorksaleScoped) {
      props.params = props.params || {};
      props.params.WorksaleID = worksaleId;
    }
    if (fieldSchema.props.isEntityScoped) {
      props.params = props.params || {};
      props.params.Entity = entity;
    }
    if (fieldSchema.props.isSupplierScoped) {
      props.params = props.params || {};
      props.params.Supplier = supplierId;
    }

    if (fieldSchema.props.isProductScoped) {
      props.params = props.params || {};
      props.params.ProductId = productId;
    }

    if (fieldSchema.props.isWidgetTypeScoped) {
      props.params = props.params || {};
      props.params.WidgetTypeId = widgetTypeId;
    }
    if (fieldSchema.type === 'RESTOCKING_CALCULATOR_FIELD') {
      props.calculator = valuesSchema && valuesSchema.RestockingChargeCalculator;
    }

    const { readOnly, name, label, precedentFields, visible = true, hideInV2 = false, hideInV3 = false, hasDynamicLabel = false, ...restProps } = props;
    let ReadOnly = isNull(isReadOnly) ? readOnly : isReadOnly;
    if (!isNull(precedentFields)) {
      const _isPrecedentFieldsFilled = isPrecedentFieldsFilled(precedentFields, formValues);
      ReadOnly = !_isPrecedentFieldsFilled || ReadOnly;
    }

    const fieldLabel = (hasDynamicLabel && valuesSchema.hasOwnProperty(`${name}Label`)) ? valuesSchema[`${name}Label`].Value : label;

    const theField = <Field
      key={rowItemInd}
      name={name}
      component={renderComponent[fieldSchema.type]}
      readOnly={ReadOnly}
      label={fieldLabel}
      validate={validations}
      schema={valuesSchema}
      operationMode={operationMode}
      onBlur={onBlur}
      required={isRequired}
      setFieldAsyncError={(f, e) => setFieldAsyncError(formName, f, e)}
      {...restProps}
    />

    if (!visible) return null;
    if (hideInV2 && isV2) return null;
    if (hideInV3 && isV3) return null;

    if (isNull(style)) return (theField)
    else
      return (
        <div style={style} key={rowItemInd}>
          {theField}
        </div>)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      readOnly: nextProps.operationMode === OPERATIONS.BROWSE
    };
  }

  renderGroupLayout(groupFieldSchemas) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        ...groupFieldSchemas?.style
      }} className={cssStyles.group}>
        {groupFieldSchemas.childern.map((fieldSchema, childRowId) => (this.renderField(fieldSchema, childRowId)))}
      </div>
    )
  }

  renderRow = (rowItems, rowInd) => {
    const { classes } = this.props;
    const calculateXSValue = (colItem) => {
      return (colItem && colItem.position.colspan) * 4 || 4;
    }

    return (
      rowItems.map((colItems, rowItemInd) => {
        const columnWidth = (colItems[0] && colItems[0].props) ? colItems[0].props.minColumnWidth || MIN_COLUMN_WIDTH : MIN_COLUMN_WIDTH;

        return (
          <GridLayout item xs={calculateXSValue(colItems[0])} style={{ minWidth: columnWidth }} className={cssStyles.gridItem} key={rowItemInd}>
            {colItems.map((rowItem, colItemInd) => {
              if (rowItem.type === 'PAPER_CONTAINER') {
                return (
                  <Paper
                    className={classes.paper}
                    key={colItemInd}
                    style={rowItem.props.style}
                    square={rowItem.props.square}
                    name={rowItem.props.name}
                    elevation={rowItem.props.elevation}
                  >
                    <label className={classNames(rowItem.props.label ? cssStyles.header : cssStyles.noHeader)}>{rowItem.props.label}</label>
                    {
                      rowItem.children && rowItem.children.map((childFieldSchema, childRowId) => {
                        if (childFieldSchema.hasOwnProperty('group')) {
                          return this.renderGroupLayout(childFieldSchema.group);
                        } else {
                          return this.renderField(childFieldSchema, childRowId);
                        }
                      })
                    }
                  </Paper>
                )
              }
              if (rowItem.type === 'PLACEHOLDER_CONTAINER') {
                return (null)
              }
              return this.renderField(rowItem, rowItemInd)
            })}
          </GridLayout>
        )
      }))
  }

  onSubmit = (values) => {
    if (values) {
      values.preventDefault();
    }
  }

  render() {
    const { layout } = this.props;
    return (
      <div className={cssStyles.root}>

        <form onSubmit={this.onSubmit} className={cssStyles.formDiv}>
          <GridLayout container spacing={0} className={cssStyles.grid}>
            {layout.map((rows) => (
              rows.map((rowItems, rowInd) => {
                return this?.renderRow(rowItems, rowInd)
              })
            ))}
          </GridLayout>
        </form>
      </div>

    );
  }
}

DynamicFormView.propTypes = {
  classes: PropTypes.object.isRequired,
  layout: PropTypes.array.isRequired
};


export default (withStyles(styles, { index: 1 })(DynamicFormView));