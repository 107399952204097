import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (lotId) => ({ lotId }),
  setSelectedLotLine: (lotId) => ({ lotId }),
  changeSortFilter: (selectedSortFilter) => selectedSortFilter,
  changeSortDirection: (selectedSortDirection) => selectedSortDirection,
  clear: () => null,
  asyncs: {
    fetchLotDetails: (lotDetails) => lotDetails,
  }
}, 'bomReservationSerials')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  selectedLotLine: NOT_SELECTED,
  lines: [],
  schemas: null,
  selectedSortFilter: 'Name',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  lotLineActions: {},
  lotLineColumns: [
    { headerName: 'Serial', field: 'Serial', minWidth: 200, }
  ],
  lotLinesSchema: []
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return initialState
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.setSelected:
      const selected = action.data.lotId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.setSelectedLotLine:
      const selectedLotLine = action.data.lotId;
      return {
        ...state,
        data: { ...state.data, selectedLotLine }
      }

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerQuoteEnquiry.bomReservationSerials,
  {
    lines: (data) => data.lines !== undefined && data.lines != null && Object.values(data.lines),
    // selected: (data) => (data.list !== undefined && data.list != null && data.list[data.selected]) || null,
    lotLineActions: data => data.lotLineActions
  }
)

const syncSelector = {
  selectedSortFilter: state => state.customerQuoteEnquiry.bomReservationSerials.data.selectedSortFilter,
  selectedSortDirection: state => state.customerQuoteEnquiry.bomReservationSerials.data.selectedSortDirection,
  isLoading: state => state.customerQuoteEnquiry.bomReservationSerials.loading,
  gridOptions: state => state.customerQuoteEnquiry.bomReservationSerials.data.gridOptions,
  lineColumns: state => state.customerQuoteEnquiry.bomReservationSerials.data.lotLineColumns,
  lineActions: state => state.customerQuoteEnquiry.bomReservationSerials.data.lotLineActions,
  selected: (state) => (state.customerQuoteEnquiry.bomReservationSerials.data.list !== undefined && state.customerQuoteEnquiry.bomReservationSerials.data.list != null && Object.assign({}, state.customerQuoteEnquiry.bomReservationSerials.data.list.filter(x => x.Lot.Value === state.customerQuoteEnquiry.bomReservationSerials.data.selected))[0]) || null,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

