import { call, put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { actions as operationActions } from 'ducks/uiOperations';
import { initialize } from 'redux-form';

import { types as customerTypes, actions as customerActions } from 'ducks/customer';

import { callApi } from './utils';
import * as api from 'api/customer';
import { Operation } from 'utils/operations';


function* search(action) {
  const { success, failure } = customerActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action) {
  const { success, failure } = customerActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchNext(action) {
  const { success, failure } = customerActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action) {
  const { success, failure } = customerActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}


function* update(action) {
  const { success, failure } = customerActions.saga.update;

  function* postApiFailureCallEffect() {
    yield put(operationActions.changeOperationMode(Operation.EDIT));
  }

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: update, postApiFailureCallEffect }
  )
}

function* create(action) {
  const { success, failure } = customerActions.saga.create;

  function* postApiFailureCallEffect() {
    yield put(operationActions.changeOperationMode(Operation.NEW));
    yield put(initialize('CustomerForm', action.data));
  }

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: create, postApiFailureCallEffect }
  )
}

function* remove(action) {
  const { success, failure } = customerActions.saga.remove;

  yield callApi(
    call(api.remove, action.data),
    success,
    failure,
    { dialogActionCallEffect: remove }
  )
}

function* getContacts(action) {
  const { success, failure } = customerActions.saga.subOp_getContacts;

  yield callApi(
    call(api.getContacts, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(customerTypes.search, search)
  yield takeLatest(customerTypes.searchById, searchById)
  yield takeLatest(customerTypes.update, update)
  yield takeLatest(customerTypes.create, create)
  yield takeLatest(customerTypes.remove, remove)
  yield takeLatest(customerTypes.subOp_getContacts, getContacts)
  yield takeLatest(customerTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(customerTypes.fetchNextPage, fetchNext)
}