import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Diary from './Diary';
import { getFormValues, destroy } from 'redux-form';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';

const mapStateToProps = state => ({
  formValues: getFormValues('Diary')(state),
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isLoading: diarySelectors.savingData(state),
  notifications: diarySelectors.notifications(state),
  errors: diarySelectors.error(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  destroyForm: () => destroy('Diary'),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Diary);