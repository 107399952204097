import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';
import SummaryPanel from './SummaryPanel';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu';
import ServiceActivityLookUpDrawer from '../common/SearchLookUpDrawer/Containers/ServiceActivity';
import { shallowCompare } from '../../utils/utils';

import BreadcrumbBar from '../common/BreadcrumbBar';
import * as styles from './ServiceActivityScheduling.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';

import { IServiceActivitySchedulingProperties } from './interfaces';
import { MODULE_TREE } from './constants';

import Scheduler from './Scheduler';

const inlineStyles = createStyles({
  ServiceActivitySchedulingSection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
});

class ServiceActivityScheduling extends React.Component<IServiceActivitySchedulingProperties> {

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'service-activity-scheduling';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    if (!!selectedTab) {
      this.props.changeSelectedForm(context, selectedTab);
    }
    this.props.history.push(this.getRoute()[selectedTab || null]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'service-activity-scheduling';
    const params = (new URLSearchParams(this.props.location.search));
    const activityId = params.get('ActivityId') || '';
    const serviceJobId = params.get('ServiceJobId') || '';
    const search = (activityId && serviceJobId) ? `?ActivityId=${activityId}&ServiceJobId=${serviceJobId}` : '';

    const routes = ({
      Scheduler: { pathname: `/${context}`, search: search, component: Scheduler }
    });

    return routes;
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption, isMenuOptionOpen } = this.props;
    toggleMenuOption(!isMenuOptionOpen);
  }

  componentDidMount(): void {
    const { staff } = this.props;
    this.props.setDefaultSalesEntity(staff.defaultSalesEntity);
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<IServiceActivitySchedulingProperties>): boolean {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: Readonly<IServiceActivitySchedulingProperties>): void {
    const { operationMode, selectedTab, changeSelectedForm, selectedForm } = this.props;
    if (prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (!selectedForm) {
      changeSelectedForm('service-activity-scheduling', selectedTab);
    }
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
  }

  backAction = () => {
    const { selectedTab, history } = this.props;
    if (!selectedTab) {
      history.push('/dashboard');
    }
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (!selectedTab) {
          history.push('/dashboard');
        } else {
          this.handleModuleChange(null);
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  fetchChildRecords = (selectedTab: string) => {
    const { getSchedulerResources, getSchedulerActivities, schedulerData, salesEntity } = this.props;
    switch (selectedTab) {
      default:
        if (getSchedulerResources && salesEntity) {
          getSchedulerResources(salesEntity, schedulerData.startDate, schedulerData.endDate);
        }
        if (getSchedulerActivities && salesEntity) {
          getSchedulerActivities(salesEntity, schedulerData.startDate, schedulerData.endDate);
        }
    }
  }

  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      default:
        return options.ServiceActivityScheduling;
    }
  }

  onSelectedActivityChanged = (): void => {
    const { getSelectedJobDetails } = this.props;
    if (getSelectedJobDetails) {
      getSelectedJobDetails({});
    }
  }

  handleOptionClick = (selectedTab: string): void => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab(selectedTab);
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.ServiceActivitySchedulingOuter}>
        <div className={styles.ServiceActivitySchedulingContainer}>

          <ServiceActivityLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.onSelectedActivityChanged
            ]}
          />
          <div
            style={inlineStyles.ServiceActivitySchedulingSection}
            className={styles.ServiceActivitySchedulingSection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Service Activity Scheduling'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                  formName={`${selectedTab}`}
                />}
              />
            ))}

          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleOptionClick}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'customer-enquiry'} />
      </div>
    );
  }
}

export default withRouter(ServiceActivityScheduling);
