import * as React from 'react';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import InlineActionButton from '@markinson/uicomponents-v2/InlineActionButton';
import { createStyles, StyledComponentProps } from '@material-ui/core/styles';
import FiltersForm from './FiltersForms';

import * as styles from './LookUpList.scss';
import { FormViewField } from 'components/FormView';

const inlineStyles = createStyles({
  searchTextField: {
    marginTop: '14px',
    width: '240px',
    minWidth: '240px'
  },
  filterRadioButton: {
    marginLeft: '0px',
    color: '#444444'
  },
  sortingApplyButton: {
    marginTop: '10px',
    width: '250px',
    backgroundColor: '#F47920',
    color: '#FFFFFF'
  },
  inlineFilterButton: {
    marginTop: '14px',
    backgroundColor: '#CCCCCC'
  },
  radioFormLabel: {
    color: '#777777'
  },
  sortingIconAscending: {
    position: 'absolute',
    top: -15,
    left: 0,
    fontSize: 45,
    color: '#777777'
  },
  sortingIconDescending: {
    position: 'absolute',
    left: 30,
    color: '#777777',
    fontSize: 45,
    top: -16,
  },
  iconColorOrange: {
    color: '#F47920'
  },
});

export interface ILookUpListProperties extends StyledComponentProps {
  searchPlaceholder: string;
  searchText: string;
  filterDropDownOpen: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  customFilterRow?: {
    formName: string;
    parameters: FormViewField[];
    validate?(values: any): boolean;
  };
  appliedFilters?: any;
  callSearchAction(): void;
  handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void;
  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void;
  handleClose(): void;
  handleFilterClick(): void;
}

class LookUpList extends React.Component<ILookUpListProperties> {
  searchField: any;

  constructor(props: Readonly<ILookUpListProperties>) {
    super(props);
    this.searchField = React.createRef<typeof TextField>();
  }

  componentDidMount(): void {
    if (this.searchField) {
      this.searchField.focus();
    }
  }

  render(): React.ReactNode {
    const {
      searchPlaceholder, style, disabled, handleChangeSearchText, handleKeyDown,
      callSearchAction, searchText, handleFilterClick, filterDropDownOpen, customFilterRow,
      appliedFilters } = this.props;

    return (
      <div style={style} className={styles.lookupSearchSection}>
        <TextField
          fieldRef={(ref) => { this.searchField = ref; }}
          placeholder={searchPlaceholder}
          value={searchText}
          action={{
            iconName: 'Search',
            controller: callSearchAction
          }}
          iconStyles={{ width: 23, height: 23 }}
          style={inlineStyles.searchTextField}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          onChange={handleChangeSearchText}
        />
        <InlineActionButton
          disabled={disabled}
          iconName={'Filter'}
          onClick={handleFilterClick}
          style={inlineStyles.inlineFilterButton}
        />
        {filterDropDownOpen ?
          <FiltersForm parameters={customFilterRow.parameters}
            formName={customFilterRow.formName}
            onApplyFilters={callSearchAction}
            initialValues={appliedFilters}
            validate={customFilterRow.validate}
            reqParams={{}} />
          : null}
      </div>
    );
  }
}

export default LookUpList;
