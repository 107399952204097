export default [
  {
    id: '1',
    name: 'AD',
    abbreviation: 'AD',
    fieldList1: 'field'
  },
  {
    id: '2',
    name: 'BC',
    abbreviation: 'BC',
    fieldList1: 'field'
  },
  {
    id: '3',
    name: 'QG',
    abbreviation: 'QG',
    fieldList1: 'field'
  },
];
