import { connect } from 'react-redux';
import ChargesDialog from './ChargesDialog';
import { getFormValues, isDirty, reset, change } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'PickSlipChargesForm';
const mapStateToProps = (state: IApplicationState) => ({
    formValues: getFormValues(FORM_NAME)(state),
    dirty: isDirty(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        resetForm: () => reset(FORM_NAME),
        changeFieldValue: (field, value) => change(FORM_NAME, field, value)
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChargesDialog);
