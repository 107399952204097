import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset } from 'redux-form';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';
import OrderConfirmationDialog from './OrderConfirmationDialog';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  orderDocumentOutputOptions: documentOutputSelectors.options('SO', 'OrderAcknowledgement')(state),
  dirty: isDirty('OrderConfirmationForm')(state),
  values: getFormValues('OrderConfirmationForm')(state),
  isValid: isValid('OrderConfirmationForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset('OrderConfirmationForm'),
    setOrderDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'OrderAcknowledgement', options)
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationDialog);
