import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import ServiceAgreementMaintenance from './ServiceAgreementMaintenance';
import ServiceAgreementMaintenanceProps from './ServiceAgreementMaintenance.properties';
import { actions as formActions } from 'ducks/form';
import { actions as serviceAgreementActions, selectors as agreementSelectors } from 'ducks/serviceAgreementMaintenance/serviceAgreement';
import { IApplicationState } from 'ducks/reducers';
import { selectors as uiSelectors } from 'ducks/ui';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeSelectedForm: formActions.setSelected,
    getFormSchema: formActions.search,
    resetSearchLookupDrawer: serviceAgreementActions.reset,
    destoryForm: () => destroy('ServiceAgreementDetail'),

  },
  dispatch);

const mapStateToProps = (state: IApplicationState) => ({
  ServiceAgreementId: agreementSelectors.ServiceAgreementId(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state)
});

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
  ServiceAgreementMaintenanceProps>) => {
  return (<ServiceAgreementMaintenance
    {...props} />);
});
