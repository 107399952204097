import { DnDSource, SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import DraggableListItem from './DraggableListItem';
import { ISchedulerActivitySearchFacade, ISchedulerJobSearchFacade } from 'api/swaggerTypes';
import { isNull } from 'utils/utils';
import moment from 'moment';

interface IItem extends ISchedulerActivitySearchFacade, ISchedulerJobSearchFacade { }

interface IDragAndDropActivity {
  item: IItem;
  schedulerData: SchedulerData;
  beginDrag(props: IDragAndDropActivity): void;
  endDrag(props: IDragAndDropActivity, monitor: any): void;
}

// We need one of these for each 'type' of thing we can drop.
export const dragAndDropActivity = new DnDSource(
  (props: IDragAndDropActivity) => {
    if (props.beginDrag) props.beginDrag(props);
    const { item } = props;
    const isJob = isNull(item.ActivityId);
    let estimate = moment.duration({
      h: 0,
      m: 0
    });
    if (isJob) {
      item.Activities.forEach((activity) => {
        if (activity.ScheduleStatus === 'Unscheduled') {
          const est = activity.Estimate;
          const duration = moment.duration({
            h: moment(est, 'hhm').hours(),
            m: moment(est, 'hhm').minute()
          });
          estimate = moment.duration(estimate).add(duration);
        }
      });
    }
    const estimatedSum = `${estimate.get('hours')}:${estimate.get('minute')}`;

    return {
      id: item.ActivityId,
      name: item.ActivityCode,
      description: item.Description,
      serviceJobId: item.ServiceJobId,
      estimate: isJob ? estimatedSum : item.Estimate,
      isActivity: !isJob,
    };
  },
  DraggableListItem,
  'activity',
  (props: IDragAndDropActivity, monitor: any) => {
    if (props.endDrag) props.endDrag(props, monitor);
  });

const dragAndDropSources = [dragAndDropActivity];

export default dragAndDropSources;
