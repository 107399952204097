import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IFilter, IOrderRecallDialogProperties } from './OrderRecallDialog.properties';
import { OrderRecallStyles } from './OrderRecallDialog.styles';
import { withStyles } from '@material-ui/core/styles';
import DataGrid, { LoadPanel, Column, Paging, Selection, GroupPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import FilterRow from 'components/common/DataGridDevEx/FilterRow';
import filterRow from './OrderRecallDialogContent.filterRow';
import { DATAGRIDCONSTANTS, ParseOrderStatus, SUBTITLE } from './OrderRecallDialog.constants';
import HyperLinkRenderer from 'components/common/DataGridDevEx/CustomRenderers/HyperLinkRenderer';
import { IOutstandingOrdersFilter } from 'api/swaggerTypes';
import { useCreateLotSale, useRecallOrder, useSearchOutstandingCustomerOrders } from 'api/Worksale/worksaleLatest';
import { createDataSource, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { isEqual } from 'lodash';
import { isScreenRequest } from 'api/utils';
import { useLostSale } from '../Worksale.hooks';
import { IFormViewModelHandle } from 'components/common/Modals/FormViewModal.properties';
import { HotKeys } from 'react-hotkeys';
import { useMemoizedKeyHandlers } from 'utils/hooks';
import { useProcessValidationForms } from 'utils/processValidationForms';
import { useSPContextSelector } from '../Worksale.context';
import ToggleButton from 'components/common/ToggleButton';
import { isNull } from 'utils/utils';
import { ENTER_KEY, ESCAPE, TAB } from 'utils/constants';
import Selectors from '../Worksale.selectors';

const getActions = (onClose, recallOrderCallback, newCallback, RecallOrNewOrder: boolean, gridLength: number, isRowSelected: boolean, recallButtonRef) => {
    if (RecallOrNewOrder) {
        return [
            {
                title: 'New',
                iconName: 'CheckCircle',
                isDefault: (gridLength > 0),
                disabled: (gridLength === 0),
                listener: () => {
                    if (newCallback) {
                        newCallback();
                    }
                }
            },
            {
                title: 'Recall',
                iconName: 'CheckCircle',
                disabled: (gridLength === 0) || !isRowSelected,
                ref: recallButtonRef,
                listener: () => {
                    if (recallOrderCallback) {
                        recallOrderCallback();
                    }
                }
            },
            {
                title: 'Cancel',
                iconName: 'Cancel',
                listener: () => {
                    if (onClose) {
                        onClose();
                    }
                },
            },
        ];
    }

    return [
        {
            title: 'Recall',
            iconName: 'CheckCircle',
            isDefault: (gridLength > 0),
            disabled: (gridLength === 0),
            ref: recallButtonRef,
            listener: () => {
                if (recallOrderCallback) {
                    recallOrderCallback();
                }
            }
        },
        {
            title: 'Cancel',
            iconName: 'Cancel',
            listener: () => {
                if (onClose) {
                    onClose();
                }
            },
        },
    ];
};

const OrderRecallDialog = (props: IOrderRecallDialogProperties) => {
    const {
        classes, open, loading, customerId, CustomerPurchaseOrder: customerPO,
        salesEntity, date, period, recallOrNewOrder, onClose, onRecallSuccess, createWorkSale
    } = props;

    const gridRef = React.useRef<DataGrid>();
    const formViewModalRef = React.useRef<IFormViewModelHandle>();
    const filterButtonRef = React.useRef<HTMLButtonElement>();

    const autoSupplyQuantity = useSPContextSelector<'autoSupplyQuantity'>((state) => state.autoSupplyQuantity);
    const disableAutoSupplyQuantity = useSPContextSelector<'disableAutoSupplyQuantity'>((state) => state.disableAutoSupplyQuantity);
    const worksaleId = useSPContextSelector(Selectors.WorksaleId);
    const unAppliedCustomerId = useSPContextSelector<'UnAppliedCustomerId'>((state) => state.UnAppliedCustomerId);

    const searchOrdersMutation = useSearchOutstandingCustomerOrders();
    const recallOrderMutation = useRecallOrder();
    const createLotSaleMutation = useCreateLotSale();
    const { onKeyDown: gridOnKeyDown, onFocusedCellChanging } = useGridKeyboardNavigation();
    const processValidationForms = useProcessValidationForms();

    const { setDialogState: setLostSaleDialogStates, closeModal: closeLostSaleDialog } = useLostSale();

    const [defaultSuppliedQuantityToggle, setDefaultSuppliedQuantityToggle] = React.useState<boolean>(autoSupplyQuantity);
    const [filters, setFilters] = React.useState<IFilter>({
        SalesEntity: null,
        OrderStatus: 'a',
        CustomerPurchaseOrder: '',
        DefaultEntity: true,
        Category: null,
        SalesOrder: null
    });
    const [filtersValuesSchema, setFiltersValuesSchema] = React.useState<any>({});
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [gridLength, setGridLength] = React.useState(0);
    const [defaultFirstRowSelection, setDefaultFirstRowSelection] = React.useState<boolean>(false);
    const recallButtonRef = React.useRef<HTMLButtonElement>();

    React.useEffect(
        () => {
            setDefaultFirstRowSelection(true);
        },
        []
    );

    React.useEffect(
        () => {
            if (recallOrNewOrder) {
                setFilters((previousFilters) => ({ ...previousFilters, CustomerPurchaseOrder: recallOrNewOrder ? customerPO : '', CustomerId: customerId, }));
            }
        },
        [recallOrNewOrder]
    );

    React.useEffect(
        () => {
            if (worksaleId) {
                setFilters((previousFilters) => ({ ...previousFilters, CustomerId: customerId, }));
            }
        },
        [worksaleId]
    );
    React.useEffect(
        () => {
            if (unAppliedCustomerId) {
                setFilters((previousFilters) => ({ ...previousFilters, CustomerId: unAppliedCustomerId, }));
            }
        },
        [unAppliedCustomerId]
    );

    React.useEffect(
        () => {
            setGridLength(gridRef.current?.instance?.getDataSource()?.items().length);
        },
        [gridRef.current?.instance?.getDataSource()?.items().length]);

    const setFiltersCallback = React.useCallback((_filters: IFilter) => setFilters({ ..._filters, DefaultSearch: false }), []);

    const searchOrdersApiCallback = React.useCallback(
        async (BatchPage, BatchSize, Sort) => {
            const { DefaultEntity, ...restFilters } = filters;
            const response = await searchOrdersMutation.mutateAsync({
                ...restFilters,
                urlQueryParams: {
                    DefaultEntity: DefaultEntity,
                    WorksaleId: worksaleId,
                    Sort,
                    BatchPage,
                    BatchSize
                }
            });

            const filterFromResponse = response?.OutstandingOrders?.inlineObject?.OutstandingOrdersFilter ?? {};
            const { SalesEntity, CustomerId, Category, SalesOrder, CustomerPurchaseOrder, OrderStatus } = filterFromResponse;

            const newFilter = {
                SalesEntity: SalesEntity || '',
                CustomerId: CustomerId || null,
                Category: Category || '',
                OrderStatus: OrderStatus || '',
                SalesOrder: SalesOrder || '',
                CustomerPurchaseOrder: CustomerPurchaseOrder || '',
                DefaultEntity: filters.DefaultEntity,
                DefaultSearch: false
            };

            if (!isEqual(newFilter, filters)) {
                setFiltersCallback(newFilter);
                const filtersSchema = response?.OutstandingOrders?.schema?.OutstandingOrdersFilter as any ?? {};
                setFiltersValuesSchema({
                    ...filtersSchema,
                    CustomerId: {
                        ...filtersSchema?.CustomerId,
                        ReadOnly: Boolean(worksaleId)
                    }
                });
            }

            return response?.OutstandingOrders?.inlineObject?.OutstandingCustomerOrder ?? [];
        },
        [worksaleId, filters]
    );

    const dataSource = React.useMemo(
        () => createDataSource(
            'SalesOrder',
            { fetch: searchOrdersApiCallback },
        ),
        [searchOrdersApiCallback]
    );

    const submitLostSale = React.useCallback(
        async (data) => {
            const { FromLineNumber, ...restData } = data;
            const response = await createLotSaleMutation.mutateAsync({ ...restData, urlQueryParams: { FromLineNumber } });

            if (response.Status && onClose && onRecallSuccess && closeLostSaleDialog) {
                closeLostSaleDialog();
                onClose();
                onRecallSuccess();
            }
        },
        [onclose, onRecallSuccess, closeLostSaleDialog]
    );

    const recallOrderApiCallback = React.useCallback(
        async (additionalData = {}) => {
            if (!selectedRow?.SalesOrder) {
                return;
            }

            const response = await recallOrderMutation.mutateAsync({
                SalesOrder: selectedRow.SalesOrder,
                AutoSupplyQuantity: defaultSuppliedQuantityToggle,
                ...additionalData,
            });

            if (response?.Forms) {
                await processValidationForms(response, {}, (ref) => recallOrderApiCallback({ ...ref, FromLineNumber: response?.RecallOrder?.FromLineNumber })).catch((err) => { console.warn(err); });
            }

            if (response.Status && isScreenRequest(response.Forms) && response.Forms?.[0]?.FormId === 'LostSale') {
                setLostSaleDialogStates({
                    open: true,
                    data: {
                        ...response.RecallOrder?.Lostsale,
                        FromLineNumber: response.RecallOrder.FromLineNumber,
                        WorksaleId: response.RecallOrder.WorksaleId
                    },
                    onOk: submitLostSale
                });
            }

            if (response.Status && onClose && onRecallSuccess) {
                onClose();
                onRecallSuccess();
            }
        },
        [defaultSuppliedQuantityToggle, selectedRow, recallOrderMutation?.data, onClose, onRecallSuccess, submitLostSale, setLostSaleDialogStates]
    );

    const newOrderApiCallback = React.useCallback(
        async () => {
            if (createWorkSale) {
                await createWorkSale({
                    CustomerId: customerId,
                    CustomerPurchaseOrder: customerPO,
                    SalesEntity: salesEntity,
                    CheckCustomerPO: false,
                    Date: date,
                    Period: period,
                });

                if (onClose) {
                    onClose();
                }
            }
        },
        [salesEntity, date, period, salesEntity, customerId, customerPO, createWorkSale, onClose]
    );

    const actions = React.useMemo(
        () => getActions(onClose, recallOrderApiCallback, newOrderApiCallback, recallOrNewOrder, gridLength, !isNull(selectedRow), recallButtonRef),
        [recallOrNewOrder, recallOrderApiCallback, onClose, gridLength, newOrderApiCallback]
    );

    const handleToggleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setDefaultSuppliedQuantityToggle(event.target.checked);
        },
        []
    );

    const focusAndSelectFirstRowGrid = React.useCallback(
        () => {
            if (!gridRef.current) {
                return;
            }

            gridRef.current?.instance?.getScrollable()?.scrollTo(0);
            gridRef.current?.instance.focus();

            if (gridRef.current?.instance?.getDataSource()?.items()?.length > 0) {
                gridRef.current?.instance?.selectRowsByIndexes([0]);
            }
            setDefaultFirstRowSelection(false);
        },
        [gridRef.current, setDefaultFirstRowSelection]
    );

    const filterOnApply = ({ filters: appliedFilters }: { formName?: string; filters: IOutstandingOrdersFilter }) => {
        setFiltersCallback({ ...appliedFilters, DefaultEntity: false });
        setDefaultFirstRowSelection(true);
    };

    const handleOnSelectionChanged = React.useCallback(
        (e) => {
            const selectedRowData = e.selectedRowsData?.[0] ?? {};
            setSelectedRow(selectedRowData);
        },
        [setSelectedRow]
    );

    const onEscape = React.useCallback(
        () => {
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    const onEnter = React.useCallback(
        () => {
            if (recallButtonRef.current &&
                gridRef.current.instance?.$element()?.[0]?.contains(document.activeElement)) {
                recallOrderApiCallback();
            }
        },
        [recallButtonRef.current, recallOrderApiCallback]
    );

    const handleGridKeyDown = React.useCallback(
        (e) => {
            const keyboardEvent = e.event as KeyboardEvent;
            if (keyboardEvent.keyCode === TAB && !keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        formViewModalRef.current?.focusFirstButton();
                    },
                    0
                );

                return;
            }
            if (keyboardEvent.keyCode === TAB && keyboardEvent.shiftKey) {
                setTimeout(
                    () => {
                        focusFilterButton();
                    },
                    0
                );

                return;
            }

            if (keyboardEvent.keyCode === ESCAPE) {
                onEscape();

                return;
            }

            if (keyboardEvent.keyCode === ENTER_KEY) {
                onEnter();

                return;
            }

            gridOnKeyDown(e);
        },
        [gridOnKeyDown, onEscape, onEnter, formViewModalRef.current]
    );

    const handleOnContentReady = React.useCallback(
        () => {
            if (defaultFirstRowSelection) {
                focusAndSelectFirstRowGrid();
            }
        },
        [defaultFirstRowSelection, focusAndSelectFirstRowGrid]
    );

    const handlers = useMemoizedKeyHandlers({
        Escape: onEscape,
        Enter: onEnter
    });

    const focusFilterButton = React.useCallback(
        () => {
            if (filterButtonRef.current) {
                filterButtonRef.current.focus();
            }
        },
        [filterButtonRef.current]
    );

    const focusGrid = React.useCallback(
        () => {
            setTimeout(
                () => {
                    gridRef.current?.instance.focus();
                },
                0
            );
        },
        [gridRef.current]
    );

    const handleFilterButtonShiftTab = React.useCallback(
        () => {
            formViewModalRef.current?.focusLastButton();
        },
        [formViewModalRef.current]
    );

    return (
        <div>
            <HotKeys handlers={handlers}>
                <FormViewModal
                    open={open}
                    title='Recall Customer Order'
                    subTitle={recallOrNewOrder ? SUBTITLE : ''}
                    loading={loading}
                    innerRef={formViewModalRef}
                    onActionsTabOut={focusFilterButton}
                    onActionShiftTabOut={focusGrid}
                    modalContent={
                        <div className={classes.OrderRecallContainer}>
                            <FilterRow
                                filterButtonRef={filterButtonRef}
                                disableInitialApplyCall={true}
                                parameters={filterRow.parameters}
                                formName={filterRow.formName}
                                onApplyFilters={filterOnApply}
                                initialValues={{ ...filters }}
                                valuesSchema={filtersValuesSchema}
                                validate={filterRow.validate}
                                rowModelType={filterRow.rowModelType}
                                onFilterButtonShiftTab={handleFilterButtonShiftTab}
                            />
                            <DataGrid
                                ref={gridRef}
                                dataSource={dataSource}
                                style={{ height: 442 }}
                                allowColumnReordering={true}
                                renderAsync={true}
                                remoteOperations={true}
                                showBorders={false}
                                showColumnLines={true}
                                columnResizingMode={'nextColumn'}
                                hoverStateEnabled={true}
                                noDataText=''
                                onSelectionChanged={handleOnSelectionChanged}
                                onFocusedCellChanging={onFocusedCellChanging}
                                onKeyDown={handleGridKeyDown}
                                onContentReady={handleOnContentReady}
                            >
                                <Column dataField='SalesOrder' caption='Sales order' allowResizing={false} width={DATAGRIDCONSTANTS.header.SalesOrder} fixed={true}
                                    cellComponent={({ data }) => {
                                        return (<HyperLinkRenderer value={{ SalesOrder: data.value, FieldValue: data.value }} colDef={{ headerName: 'Sales Order' }} link='sales-order-enquiry/order-details' />);
                                    }}
                                />
                                <Column dataField='CustomerIdDisplay' caption='Customer' allowResizing={false} width={DATAGRIDCONSTANTS.header.Customer} fixed={true} />
                                <Column dataField='CustomerPurchaseOrder' caption='Customer P/O' allowResizing={true} width={DATAGRIDCONSTANTS.header.CustomerPO} />
                                <Column dataField='EnterDate' caption='Entered' allowResizing={false} width={DATAGRIDCONSTANTS.header.Entered} allowSorting={true} />
                                <Column dataField='SalesEntity' caption='Entity' allowResizing={false} width={DATAGRIDCONSTANTS.header.Entity} />
                                <Column dataField='Warehouse' caption='W/H' allowResizing={true} width={DATAGRIDCONSTANTS.header.WH} />
                                <Column dataField='OrderStatus' caption='Status' allowResizing={true} width={DATAGRIDCONSTANTS.header.Status} calculateCellValue={(d) => ParseOrderStatus(d.OrderStatus)} />
                                <Column dataField='Category' caption='Category' allowResizing={true} width={DATAGRIDCONSTANTS.header.Category} />
                                <Column dataField='ShipDate' caption='Earliest ship' allowResizing={true} width={DATAGRIDCONSTANTS.header.EarliestShip} />
                                <Column dataField='ExpiryDate' caption='Expiry' allowResizing={true} width={DATAGRIDCONSTANTS.header.Expiry} />
                                <Column dataField='Description' caption='Description' allowResizing={true} width={DATAGRIDCONSTANTS.header.Description} />
                                <Column dataField='CustomerName' caption='Customer name' allowResizing={true} width={DATAGRIDCONSTANTS.header.CustomerName} />
                                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                                <Selection mode={'single'} />
                                <GroupPanel visible={false} />
                                <SearchPanel visible={false} />
                                <Scrolling mode={'infinite'} />
                                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
                            </DataGrid>
                            <div className={classes.OrderRecallToogleContaner}>
                                <ToggleButton
                                    checked={defaultSuppliedQuantityToggle}
                                    disabled={disableAutoSupplyQuantity}
                                    onChange={handleToggleChange}
                                    label={'Default supplied quantity for available products'}
                                    value={'RecalculatePricing'}
                                />
                            </div>
                        </div>
                    }
                    actions={actions}
                    dialogActionsButtons={true}
                    dialogActionsShadow={false}
                />
            </HotKeys>
        </div>
    );
};

export default withStyles(OrderRecallStyles)(React.memo(OrderRecallDialog));
