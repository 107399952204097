import * as React from 'react';
import FormView from '../../FormView/index';
import { IProductDetailsProps } from '../interfaces';

class ProductDetails extends React.PureComponent<IProductDetailsProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = 'product-movement-enquiry';
    if (path) {
      switch (path) {
        case `/${context}/transactions`:
          onInitialLoad('PMETransactions');
          break;
        case `/${context}/bin-locations`:
          onInitialLoad('PMEBinLocations');
          break;
        case `/${context}/lot-details`:
          onInitialLoad('PMELotDetails');
          break;
        case `/${context}/serial-numbers`:
          onInitialLoad('PMESerialNumbers');
          break;
        case `/${context}/movement-details`:
        default:
          onInitialLoad('PMEMovementDetails');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedTab, movementDetails } = this.props;

    switch (selectedTab) {
      case 'PMEMovementDetails':
        return { initialValues: movementDetails && movementDetails.Values, valuesSchema: movementDetails && movementDetails.Schema };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, selectedTab } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={valuesSchema}
        />
      </React.Fragment>
    );
  }
}

export default ProductDetails;
