import { connect } from 'react-redux';
import Lines from './Lines';
import { selectors as purchaseOrdersSelectors } from 'ducks/purchaseOrders/purchaseOrders';
import React from 'react';
import { ILinesHandle, ILinesProperties, IPickSlipLineDetailsFacadeExtended } from './Lines.properties';
import { useCalculateGridLine } from 'api/pickSlips/slipLinesUpdated';

const params = new URLSearchParams(location.search);

const mapStateToProps = (state) => ({
    selectedPurchaseOrder: purchaseOrdersSelectors.selected(state)?.PurchaseOrder ?? params.get('PurchaseOrder'),
});

const ContainerComponent = (props: Omit<ILinesProperties, 'classes' | 'calculateCellValueChange'> & { forwardRef?: React.Ref<ILinesHandle> }) => {
    const { mutateAsync: calculateGridLineMutation } = useCalculateGridLine();
    // declaring calculate function in container is because
    // useMutation isLoading variable causes component re render which causes issues in datagrid

    const calculateCellValueChange = React.useCallback(
        async <K extends keyof IPickSlipLineDetailsFacadeExtended>(rowData: IPickSlipLineDetailsFacadeExtended, field: K) => {
            const response = await calculateGridLineMutation({
                ...rowData,
                urlQueryParams: {
                    ChangedField: field
                }
            });

            return response;
        },
        []
    );

    return <Lines
        {...props}
        calculateCellValueChange={calculateCellValueChange}
        innerRef={props?.forwardRef}
    />;
};

export default connect(mapStateToProps, null)(React.forwardRef(ContainerComponent));
