import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils';

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
      fetch: ({ fetchApi, filters }) => ({ fetchApi, filters }),
      create: ({ createApi, diaryEntry }) => ({ createApi, diaryEntry }),
      update: ({ updateApi, diaryEntry }) => ({ updateApi, diaryEntry }),
      remove: ({ deleteApi }) => ({ deleteApi }),
      searchById: ({ searchByIdApi }) => ({ searchByIdApi })
    }
  },
  'diary');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'DiaryDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
    enableMasterDetailModel: true,
    detailCellRenderer: 'CommentAreaRenderer',
    detailCellRendererParams: {
      getDetailRowData: function (params) {
        return { commentData: params.data.CommentFull }
      }
    },
  },
  Columns: [
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 120, },
    { headerName: 'Time', field: 'Time', type: 'numericColumn', suppressSorting: true, minWidth: 120 },
    { headerName: 'Subject', field: 'Subject', minWidth: 250, },
    { headerName: 'User ID', field: 'UserId', suppressSorting: true, minWidth: 120, },
    { headerName: 'Surname', field: 'Surname', suppressSorting: true, minWidth: 200, },
    { headerName: 'Firstname', field: 'FirstName', suppressSorting: true, minWidth: 200, },
    { headerName: 'Comment', field: 'CommentShort', minWidth: 350, },
    { headerName: 'System generated', field: 'SystemGenerated', suppressSorting: true, minWidth: 120, },
  ],
  selectedFilters: {
    Subject: '',
    UserId: '',
    SystemGenerated: false,
    From: '',
    To: '',
    DefaultSearch: true
  },
  filterRow: {
    formName: 'DiaryFilter',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'TEXT_FIELD',
        props: {
          label: 'Subject',
          name: 'Subject',
          size: 'large',
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'User ID',
          name: 'UserId',
          lookupName: 'Personnel',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'TOGGLE_FIELD',
        visible: true,
        props: {
          label: 'System Generated',
          name: 'SystemGenerated',
          required: false,
          disabled: false,
          defaultValue: false
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px'
          }
        }
      },
    ]
  },
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetch:
    case types.create:
    case types.update:
    case types.remove:
    case types.searchById:
      return asyncOnRequest(state, action);
    case types.saga.create.success:
    case types.saga.update.success:
    case types.saga.remove.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          selected: linesData
        };
      });

    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const diaryEntry = successAction.payload;

        return {
          ...data,
          selected: diaryEntry
        };
      });
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload;

        return {
          ...data,
          Data: linesData,
          selected: null
        };
      });
    case types.saga.fetch.failure:
    case types.saga.create.failure:
    case types.saga.update.failure:
    case types.saga.remove.failure:
    case types.saga.searchById.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.common.diary,
  {
    data: (data) => data.Data,

  }
);

const syncSelector = {
  isLoading: (state) => state.common.diary.fetch_loading,
  gridOptions: (state) => state.common.diary.data.gridOptions,
  columns: (state) => state.common.diary.data.Columns,
  detailColumns: (state) => state.common.diary.data.detailCellRendererParams,
  filterRow: (state) => state.common.diary.data.filterRow,
  selectedFilters: (state) => state.common.diary.data.selectedFilters,
  detailCellRenderer: (state) => state.common.diary.data.detailCellRenderer,
  savingData: (state) => state.common.diary.create_loading || state.common.diary.update_loading || state.common.diary.searchById_loading,
  notifications: (state) => state.common.diary.notifications,
  errors: (state) => state.common.diary.error,
  selected: (state) => state.common.diary.data.selected
};

export const selectors = { ...asyncSelector, ...syncSelector };
