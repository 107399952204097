import { MoProV2, MoProV3 } from 'utils/constants';
import { IExtendedState, initializeReducer, syncSelectors } from './utils';

export interface IAppConfigData {
    version: string;
}
export interface IAppConfigState extends IExtendedState<IAppConfigData> {
}

const initialData: IAppConfigData = {
    version: ''
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'appConfig',
    initialData: initialData,
    syncActions: {
        setVersion: {
            action: (version: string) => version,
            callback: (data: IAppConfigData, version: string) => ({ ...data, version })
        }
    }
});

export { types, actions };
export default reducer;

const syncSelector = syncSelectors(
    (state: { appConfig: IAppConfigState }) => state.appConfig.data,
    {
        version: (config) => config.version,
        isV2: (config) => config.version === MoProV2,
        isV3: (config) => config.version === MoProV3,
    });

export const selectors = { ...syncSelector };
