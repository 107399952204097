import { IFormViewForm } from 'components/FormView';

const SalesSurchargeDialog: IFormViewForm = {
    id: 'SalesSurchargeForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: 1,
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0,
                minColumnWidth: 300
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Invoice',
                        name: 'Invoice',
                        placeholder: '',
                        type: 'number',
                        size: 'small',
                        readOnly: true
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Invoice subtotal',
                        name: 'InvoiceSubTotal',
                        type: 'number',
                        placeholder: '',
                        size: 'medium',
                        readOnly: true,
                        decimalSeparator: true,
                        decimalPlaces: 2,
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Surcharge',
                        name: 'Surcharge',
                        type: 'number',
                        placeholder: '',
                        hasDynamicLabel: true,
                        size: 'medium',
                        decimalSeparator: true,
                        decimalPlaces: 2,
                    }
                },
                {
                    id: 4,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Surcharge tax',
                        name: 'SurchargeTax',
                        placeholder: '',
                        hasDynamicLabel: true,
                        size: 'medium',
                        readOnly: true
                    }
                },
                {
                    id: 4,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Invoice total',
                        name: 'InvoiceTotal',
                        type: 'number',
                        placeholder: '',
                        size: 'medium',
                        readOnly: true,
                        decimalSeparator: true,
                        decimalPlaces: 2,
                    }
                },
            ]

        }
    ]
};

export default SalesSurchargeDialog;
