import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils';
export const fetch = async (query: { ProductId: string; filters: any; Page: number; Sort: string; SortDirection: string; BatchSize?: number }) => {
    const { ProductId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
    const respType = 'Inline';

    return fetchPost(
        `/InventoryEnquiry/Product/${ProductId}/SearchDocuments`,
        filters && { ...filters },
        { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
        respType
    )
        .then(
            (result) => {
                if (result) {
                    if (result.Documents) {
                        return {
                            records: result.Documents.map((item) => objectify(item, null, 'Inline')),
                            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
                            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
                        };
                    } else {
                        return result;
                    }
                }

            });
};
