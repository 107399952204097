import { toObjectified } from 'api/baseApi';
import { Payload, ResponseType, useFetchMutation, usePost, useFetch } from 'api/reactQuery/queryService';
import {
  ICatalogueEntryDetailFacade, ICatalogueEntryDetailResponse, ICalculateFastLineCriteriaFacade, ICreateFastLineFacade, IInitialiseProductCatalogueFacade, ICatalogueProductDetailFacade, ICatalogueProductDetailResponse, IRetrieveCatalogueEntriesSearchResponse, IRetrieveCatalogueProductsSearchResponse, IRetrieveImagesSearchResponse, ISearchCatalogueResponse, IWorksaleGridLineDetailFacade, IWorksaleFastLineDetailFacade, IWorksaleGridLineDetailsResponse,
  IWorksaleFastLineDetailsResponse, IWorksaleFacade
} from 'api/swaggerTypes';
import { Inline, IObjectified, objectify } from 'api/utils';
import { APIRoutes } from './constants';

export type IObjectifiedWorksaleGridLineDetailsResponse = Omit<IWorksaleGridLineDetailsResponse, 'GridLineDetails'> & {
  GridLineDetails: IObjectified<IWorksaleGridLineDetailFacade>;
};

export type IObjectifiedCatalogueProductDetailResponse = Omit<ICatalogueProductDetailResponse, 'CatalogueProductDetail'> & {
  CatalogueProductDetail: IObjectified<ICatalogueProductDetailFacade>;
};

export type IObjectifiedCatalogueEntryDetailResponse = Omit<ICatalogueEntryDetailResponse, 'CatatlogueEntryDetails'> & {
  CatatlogueEntryDetails: IObjectified<ICatalogueEntryDetailFacade>;
};

const retrieveEntriesResponseParser = (response: IRetrieveCatalogueEntriesSearchResponse): IRetrieveCatalogueEntriesSearchResponse => ({
  ...response,
  catalogueEntries: response?.catalogueEntries.map((e) => ({ ...e, ParentId: e.ParentId ? e.ParentId : null, hasItems: e.HasSubEntry }))
});

export const useRetrieveEntries = () =>
  useFetchMutation<Payload<{ CatalogueId?: number }>, IRetrieveCatalogueEntriesSearchResponse>(APIRoutes.productCatalogue.entries.retrieve, null, {
    responseParser: retrieveEntriesResponseParser
  });

export const useRetrieveEntryImages = () =>
  useFetchMutation<Payload<{ CatalogueId?: number }>, IRetrieveImagesSearchResponse>(APIRoutes.productCatalogue.entries.images.retrieve, null);

export const useRetrieveCatalogueProductImages = () =>
  useFetchMutation<Payload<{ CatalogueProductId?: number }>, IRetrieveImagesSearchResponse>(APIRoutes.productCatalogue.entries.products.images.retrieve, null);

const imageResponseParser = (response: any) => window.URL.createObjectURL(response);

export const useRetrieveImage = () =>
  useFetchMutation<Payload<{ ImageId?: number }>, any>(
    APIRoutes.image.retrieve,
    { maximumWidth: 300, maximumHeight: 300 },
    { responseType: ResponseType.file, responseParser: imageResponseParser }
  );

export const useRetrieveCatalogueProducts = () =>
  useFetchMutation<Payload<{ WorksaleId: number; CatalogueId: number }>, IRetrieveCatalogueProductsSearchResponse>(APIRoutes.productCatalogue.entries.products.retrieve, null);

export const useFetchInitialCatalogueConfig = (worksaleId: string) =>
  useFetch<IInitialiseProductCatalogueFacade>(
    {
      url: APIRoutes.productCatalogue.entries.initialize.retrieve,
      options: {
        responseParser: (r) => r.productCatalogue
      },
      queryConfig: { enabled: Boolean(worksaleId) }
    }
  );

export const useSearchCatalogueEntries = () =>
  usePost<Payload<{ CatalogueId: number; SearchAll: boolean; SearchText: string; BatchPage?: number; BatchSize?: number }>, ISearchCatalogueResponse>(APIRoutes.productCatalogue.entries.search.retrieve, null);

const calculateFastLineDetailResponseParser = (response) => {
  const { FastLineDetails } = response;

  return {
    ...response,
    FastLineDetails: FastLineDetails ? objectify(FastLineDetails as Inline<IWorksaleFastLineDetailFacade>) : null
  };
};

export const useCalculateFastLineDetail = () =>
  usePost<Payload<ICalculateFastLineCriteriaFacade & { WorksaleId?: number }>, IWorksaleFastLineDetailsResponse>(
    APIRoutes.productCatalogue.addSale.retrieve,
    null,
    { inline: true, responseParser: calculateFastLineDetailResponseParser }
  );

const createFastLineResponseParser = (response) => {
  const { GridLineDetails } = response;

  return {
    ...response,
    GridLineDetails: GridLineDetails ? toObjectified(GridLineDetails as Inline<IWorksaleGridLineDetailFacade>) : null
  };
};

export const useCreateFastLine = () =>
  usePost<Payload<{ WorksaleId?: number } & ICreateFastLineFacade>, IObjectifiedWorksaleGridLineDetailsResponse>(
    APIRoutes.productCatalogue.addSale.create,
    null,
    { inline: true, responseParser: createFastLineResponseParser }
  );
const catalogProductDetailResponseParser = (response) => {
  const { CatalogueProductDetail } = response;

  return {
    ...response,
    CatalogueProductDetail: CatalogueProductDetail ? toObjectified(CatalogueProductDetail as Inline<ICatalogueProductDetailFacade>) : null
  };
};

const catalogEntryDetailResponseParser = (response) => {
  const { CatatlogueEntryDetails } = response;

  return {
    ...response,
    CatatlogueEntryDetails: CatatlogueEntryDetails ? toObjectified(CatatlogueEntryDetails as Inline<ICatalogueEntryDetailFacade>) : null
  };
};

export const useRetrieveCatalogueProductDetails = (WorksaleId: number, CatalogueProductId: number) =>
  useFetch<IObjectifiedCatalogueProductDetailResponse>(
    {
      url: APIRoutes.productCatalogue.entries.products.product.details.retrieve,
      urlParams: { WorksaleId, CatalogueProductId },
      queryConfig: { enabled: Boolean(WorksaleId) && Boolean(CatalogueProductId) },
      options: { inline: true, responseParser: catalogProductDetailResponseParser }
    }
  );

export const useRetrieveCatalogueEntryDetails = (CatalogueId: number) =>
  useFetch<IObjectifiedCatalogueEntryDetailResponse>(
    {
      url: APIRoutes.productCatalogue.entries.details.retrieve,
      urlParams: { CatalogueId },
      queryConfig: { enabled: Boolean(CatalogueId) },
      options: { inline: true, responseParser: catalogEntryDetailResponseParser }
    }
  );

export const useCurrentWorksale = () =>
  useFetch<IWorksaleFacade>(
    {
      url: APIRoutes.productCatalogue.entries.initialize.currentWorksale,
      options: { inline: false, responseParser: (r) => r.WorkSale }
    }
  );
