import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  setEntityView: (defaultView, defaultWarehouse) => ({ defaultView, defaultWarehouse }),
  asyncs: {
  }
}, 'IESReturnAuthorities');

const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    EntityView: 'MPRO',
    Warehouse: '',
    CustomerId: '',
    Type: 'All'
  },
  filterRow: {
    formName: 'IESReturnAuthoritiesFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity view',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 2,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'small',
          required: false
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Type',
          name: 'Type',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Credit',
              label: 'Credit'
            },
            {
              value: 'Replacement',
              label: 'Replacement'
            }
          ]
        }
      },
    ]
  },
  gridOptions: {
    toolPanel: false,
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Return authority', field: 'ReturnAuthorityId', minWidth: 180 },
    { headerName: 'Sales entity', field: 'SalesEntity', minWidth: 120, suppressSorting: true },
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Customer) {
          return {
            CustomerId: row.data.Customer,
            CustomerAccountNumber: row.data.CustomerDisplay,
            FieldValue: row.data.CustomerDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Type', field: 'Type', minWidth: 120, suppressSorting: true },
    { headerName: 'Reference', field: 'Reference', minWidth: 200, suppressSorting: true },
    { headerName: 'Entered', field: 'Entered', minWidth: 120, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 200, suppressSorting: true },
    { headerName: 'Customer name', field: 'CustomerLabel', minWidth: 200, suppressSorting: true },
    { headerName: 'Description', field: 'Description', minWidth: 250 }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return initialState;
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setEntityView:
      const entityView = action.data.defaultView;
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView,
            Warehouse: warehouse
          }
        }
      };

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.sales.returnAuthorities,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.sales.returnAuthorities.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.sales.returnAuthorities.data.gridOptions,
  columns: state => state.inventoryEnquiry.sales.returnAuthorities.data.Columns,
  filterRow: state => state.inventoryEnquiry.sales.returnAuthorities.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.sales.returnAuthorities.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)