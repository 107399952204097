const SerialNumber = {
  id: 'SerialNumber',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      type: 'SERIAL_NUMBER_GRID',
      props: {
        name: 'Serials',
        getSummaryAPI: '/PickSlipTransaction/Despatch/<DespatchId>/Line/<LineNumber>/SerialSummary',
        getSelectedAPI: '/PickSlipTransaction/Despatch/<DespatchId>/Line/<LineNumber>/Serials'
      }
    }
  ]
};
export default SerialNumber;
