import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import InlineActionButton from '@markinson/uicomponents-v2/InlineActionButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import renderField from '../../DataGridDevEx/CustomRenderers/RenderField';
import { isShallowEqual, pathOr, isPrecedentFieldsFilled } from 'utils/utils';
import { HotKeys } from 'react-hotkeys';
import { TAB, HotKeyNames } from 'utils/constants';
import FocusLock from 'react-focus-lock';

const styles = createStyles({
  table: {
    marginBottom: '8px'
  },
  filterCell: {
    width: '170px',
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    padding: '10px',

  },
  filterLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '34px'
  },
  valueCell: {
    backgroundColor: '#dddddd',
    border: '1px solid #fff',
    padding: '10px',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  filterMenuPaper: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '400px',
    top: '80px',
    height: 'auto',
    zIndex: 100,
  },
  footer: {
    backgroundColor: '#e0e0e0',
    height: '50px'
  },
  applyButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    float: 'right',
    lineHeight: '34px',
    padding: '8px 16px'
  },
  fieldsContainer: {
    padding: '15px'
  }
});

const inlineStyles = createStyles({
  FilterButton: {
    backgroundColor: '#eeeeee',
    float: 'right'
  }
});

export interface IFilterRowProperties extends StyledComponentProps {
  formName: string;
  form: string;
  isValid: boolean;
  values: any;
  hidePreFilters: boolean;
  disableInitialApplyCall?: boolean;
  initialValues: any;
  parameters: [IParameters];
  reqParams: any;
  valuesSchema: any;
  lookupList: any;
  operationMode: string;
  rowModelType?: string;
  filterButtonRef?: React.MutableRefObject<HTMLButtonElement>;
  onBlur(event: any): boolean;
  reset(formName: string): void;
  fetchLookups(something: any): any;
  onFilterButtonShiftTab?(): any;
  onApplyFilters?(filters: any): void;
}

interface IFilterRowState {
  filterDropDownOpen: boolean;
  readOnly: boolean;
  apply: boolean;
  appliedFilters: string;
  lookups: {
    data: { [x: string]: any };
    loading: { [x: string]: boolean };
    hasError: { [x: string]: boolean };
    isFocused: { [x: string]: boolean };
  };
}

interface IParameters {
  id: number;
  type: string;
  props: object;
  [x: string]: any;
}

class FilterRow extends React.Component<IFilterRowProperties, IFilterRowState> {

  handler: any = null;
  constructor(props: Readonly<IFilterRowProperties>) {
    super(props);

    this.state = {
      filterDropDownOpen: false,
      apply: false,
      appliedFilters: '',
      readOnly: false,
      lookups: { data: {}, loading: {}, hasError: {}, isFocused: {} }
    };

    this.onEnter = this.onEnter.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.filterBtnKeyDown = this.filterBtnKeyDown.bind(this);
    this.handler = {
      [HotKeyNames.Enter]: this.onEnter,
      [HotKeyNames.Escape]: this.onEscape,
    };
  }

  onEnter(): void {
    if (this.state.filterDropDownOpen && !this.isAnyLookupLoading() && !this.anyLookupFocused()) {
      this.applyFilter();
    }
  }
  onEscape(): void {
    const { formName, reset } = this.props;
    if (this.state.filterDropDownOpen && !this.isAnyLookupLoading()) {
      if (reset) {
        reset(formName);
      }
      this.setState({ filterDropDownOpen: false });
    }
  }

  onLookupEnter = () => {
    this.applyFilter();
  }

  componentDidMount(): void {
    const { onApplyFilters, disableInitialApplyCall = false, rowModelType, form, values, reqParams } = this.props;
    this.initialFilters();
    if (!disableInitialApplyCall && onApplyFilters) {
      if (rowModelType === 'serverSide') {
        onApplyFilters({ formName: form, filters: values });
      } else {
        onApplyFilters({ ...reqParams, filters: values });
      }
    }
  }

  handleFilterClick = () => {
    this.setState({ filterDropDownOpen: !this.state.filterDropDownOpen });
  }

  applyFilter = () => {
    this.setState({ apply: true });
  }

  stringGenerator = (field: any, displayValue: any) => {
    const { valuesSchema } = this.props;
    const TrimmedValue = (typeof displayValue === 'string') ? displayValue.trim() : displayValue;
    if (field && TrimmedValue) {
      return `<span style="font-size:12px; text-transform:uppercase;">
        ${field.props.label}:
        </span> <span style="font-weight:bold;">
        ${((field.type === 'SELECT_FIELD' || field.type === 'AUTOCOMPLETE_FIELD') ?
          (field.props.options) ?
            field.props.options.find((option) => option.value === displayValue) && field.props.options.find((option) => option.value === displayValue).label :
            this.getLookupValue(field, displayValue)
          : field.type === 'EX_LOOKUP_FIELD' ?
            this.state.lookups.data[field.props.name]?.Display ?? valuesSchema?.[field.props.name]?.Display ?? displayValue
            : displayValue)}</span>`;
    } else {
      return '';
    }
  }

  getLookupValue = (field: any, displayValue: string) => {
    const { lookupList } = this.props;
    if (lookupList && lookupList[field.props.apiPrefs.path]) {
      return this.getLabel(field, displayValue);
    } else {
      this.props.fetchLookups(field.props.apiPrefs);
    }

    return '-';
  }

  getLabel = (field: any, displayValue: string) => {
    const { lookupList } = this.props;
    const lookup = lookupList[field.props.apiPrefs.path];
    const label = lookup.Data.find((option) => option.value === displayValue) ? lookup.Data.find((option) => option.value === displayValue).label : '';

    return label;
  }
  paramsUpdated = (prevProps: Readonly<IFilterRowProperties>): boolean => {
    return (this.props.reqParams &&
      ((Object.keys(this.props.reqParams).map((key) =>
        !prevProps.reqParams
        || this.props.reqParams[key] !== prevProps.reqParams[key])
        .includes(true))
      )
    );
  }

  isAnyLookupLoading = () => Object.values(this.state.lookups.loading).some((item) => item);
  anyLookupHasError = () => Object.values(this.state.lookups.hasError).some((item) => item);
  anyLookupFocused = () => Object.values(this.state.lookups.isFocused).some((item) => item);

  handleApply = (paramsUpdated: boolean) => {
    const { isValid, values, reqParams, onApplyFilters, rowModelType, form } = this.props;
    if (isValid && !this.anyLookupHasError()) {
      this.updateFilterString();
      if (onApplyFilters) {
        if (rowModelType === 'serverSide') {
          onApplyFilters({ formName: form, filters: { ...values, DefaultSearch: !this.state.apply && paramsUpdated } });

        } else {
          onApplyFilters({ ...reqParams, filters: { ...values, DefaultSearch: !this.state.apply && paramsUpdated } });
        }
      }
    }
    this.setState({ apply: false });
  }

  componentDidUpdate(prevProps: Readonly<IFilterRowProperties>): void {
    const { lookupList, reqParams, initialValues, valuesSchema } = this.props;
    if (!isShallowEqual(prevProps.lookupList, lookupList) || !isShallowEqual(prevProps.valuesSchema, valuesSchema)) {
      this.updateFilterString();
    }

    const paramsUpdated = this.paramsUpdated(prevProps);
    if ((this.state.apply && !this.isAnyLookupLoading()) || paramsUpdated) {
      //Pushing Apply handling to another thread so that state update cycle can be reset.
      setTimeout(
        () => {
          this.handleApply(paramsUpdated);
        },
        0);
    }
    //This is the case where the data in LookupDrawer changes and FilterRow needs update.
    if (JSON.stringify(reqParams) !== JSON.stringify(prevProps.reqParams)) {
      const { reset, formName,
      } = this.props;
      this.setState({
        filterDropDownOpen: false,
        apply: false
      });
      if (reset) {
        reset(formName);
      }
      this.initialFilters();
    }

    if (JSON.stringify(initialValues) !== JSON.stringify(prevProps.initialValues)) {
      this.initialFilters();
    }
  }

  initialFilters = () => {
    const {
      hidePreFilters
    } = this.props;
    if (!hidePreFilters) {
      this.updateFilterString();
    } else {
      this.setState({ appliedFilters: '' });
    }

  }

  updateFilterString = () => {
    const { values, parameters } = this.props;
    const appliedFilters = [];

    parameters.forEach((param: any) => {
      const currentFilterValue = values[param.props.name] || '';
      if (currentFilterValue !== '' && currentFilterValue !== null) {
        let displayValue = currentFilterValue;
        if (currentFilterValue === false) { displayValue = 'No'; }
        if (currentFilterValue === true) { displayValue = 'Yes'; }
        appliedFilters.push(this.stringGenerator(param, displayValue));
      }
    });

    this.setState({
      filterDropDownOpen: false,
      appliedFilters: appliedFilters.filter(Boolean).join(' | ')
    });
  }

  handleLookupItemChange = (fieldName: string, item: any) => {
    this.setState({
      lookups: {
        ...this.state.lookups,
        data: {
          ...this.state.lookups.data,
          [fieldName]: item
        }
      }
    });
  }

  handleLookupLoading = (fieldName: string, loading: boolean) => {
    this.setState({
      lookups: {
        ...this.state.lookups,
        loading: {
          ...this.state.lookups.loading,
          [fieldName]: loading
        }
      }
    });
  }

  handleLookupError = (fieldName: string, hasError: boolean) => {
    this.setState({
      lookups: {
        ...this.state.lookups,
        hasError: {
          ...this.state.lookups.hasError,
          [fieldName]: hasError
        }
      }
    });
  }

  handleLookupFocus = (fieldName: string, isFocused: boolean) => {
    this.setState({
      lookups: {
        ...this.state.lookups,
        isFocused: {
          ...this.state.lookups.isFocused,
          [fieldName]: isFocused
        }
      }
    });
  }

  filterBtnKeyDown(e: React.KeyboardEvent<HTMLSpanElement>): void {
    const { onFilterButtonShiftTab } = this.props;

    if (e?.keyCode === TAB && e?.shiftKey) {
      if (onFilterButtonShiftTab) {
        onFilterButtonShiftTab();
      }
      e.preventDefault();
    }
  }

  render(): React.ReactNode {
    const { classes, parameters, reqParams, filterButtonRef } = this.props;

    return (
      <HotKeys handlers={this.handler}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.filterCell}>
                <span className={classes.filterLabel}>Filters</span>
                <span onKeyDown={this.filterBtnKeyDown}>
                  <InlineActionButton
                    buttonRef={filterButtonRef}
                    iconName={'Filter'}
                    onClick={this.handleFilterClick}
                    style={inlineStyles.FilterButton}
                  />
                </span>
                {this.state.filterDropDownOpen ?
                  <Paper className={classes.filterMenuPaper}>
                    <FocusLock>
                      <div className={classes.fieldsContainer}>
                        {parameters.map((childFieldSchema: any, childRowId) => {
                          const { valuesSchema, operationMode, onBlur, values } = this.props;
                          const { name } = childFieldSchema.props;
                          const { readOnly } = this.state;
                          const props = pathOr({}, ['props'], childFieldSchema ?? {});
                          const type = pathOr({}, ['type'], childFieldSchema ?? {});

                          const params = {
                            ...props.isCustomerScoped ? { CustomerId: Number(reqParams.customerId) } : {},
                            ...props.isWorksaleScoped ? { WorksaleID: reqParams.worksaleId } : {},
                            ...props.isProductScoped ? { ProductId: Number(reqParams.productId) } : {},
                            ...props.isEntityScoped ? { Entity: reqParams.entity } : {},
                            ...props.isSupplierScoped ? { Supplier: reqParams.supplierId } : {},
                          };

                          const { precedentFields = [] } = props;
                          const _isPrecedentFieldsFilled = isPrecedentFieldsFilled(precedentFields, values);

                          let _childFieldSchema = {
                            ...childFieldSchema,
                            props: {
                              ...props, params, disabled: !_isPrecedentFieldsFilled,
                              readOnly: !_isPrecedentFieldsFilled || valuesSchema && valuesSchema[name] && valuesSchema[name]?.ReadOnly
                            }
                          };

                          let stateAndProps: any = { valuesSchema, operationMode, onBlur, values, readOnly };

                          if (type === 'EX_LOOKUP_FIELD') {
                            _childFieldSchema = {
                              ..._childFieldSchema,
                              props: {
                                ..._childFieldSchema.props,
                                onSelectedItemChange: (item) => { this.handleLookupItemChange(props.name, item); },
                                hasError: (loading) => {
                                  this.handleLookupError(props.name, loading);
                                },
                                isFocused: (isFocused) => {
                                  this.handleLookupFocus(props.name, isFocused);
                                },
                                onEnterKeyDown: this.onLookupEnter
                              }
                            };
                            stateAndProps = {
                              ...stateAndProps,
                              isLoading: (loading) => {
                                this.handleLookupLoading(props.name, loading);
                              },
                            };
                          }

                          if (!_isPrecedentFieldsFilled) values[props.name] = null;
                          const fieldData = { childFieldSchema: _childFieldSchema, childRowId };

                          return renderField(fieldData, stateAndProps);
                        })}
                      </div>
                      <div className={classes.footer}>
                        <Button
                          variant={'contained'}
                          className={classes.applyButton}
                          onClick={this.applyFilter}>
                          <DoneAllIcon style={{ color: 'green' }} /> APPLY
                        </Button>
                      </div>
                    </FocusLock>
                  </Paper>
                  : null}
              </TableCell>
              <TableCell className={classes.valueCell} dangerouslySetInnerHTML={{ __html: this.state.appliedFilters }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </HotKeys>
    );
  }
}

export default withStyles(styles, { index: 1 })(FilterRow);
