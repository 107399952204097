import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as pickSlipActions, selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

interface IPSEItem {
  PickSlip: string;
  SalesEntity: string;
  Warehouse: string;
  CustomerIdDisplay: string;
  DespatchId: number;
  DespatchNumber: number;
  Invoice: number;
}

const PSSearchLookUpDrawer = buildSearchLookUpDrawer<IPSEItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IPSEItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IPSEItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'PickSlips',
    searchPlaceholder: 'Search for pick slips...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'Invoice',
        label: 'Invoice'
      },
      {
        filter: 'SalesEntity',
        label: 'Sales Entity'
      },
      {
        filter: 'DespatchNumber',
        label: 'Despatch Number'
      },
    ],
    records: pickSlipSelectors.all(state) || [],
    selectedRecord: pickSlipSelectors.selected(state),
    selectedSortFilter: 'DespatchId',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isSupplierLookUpOpen(state),
    isLoading: pickSlipSelectors.isLoading(state),
    nextPage: pickSlipSelectors.nextPage(state),
    prevPage: pickSlipSelectors.prevPage(state),
    pageSize: pickSlipSelectors.pageSize(state),
    totalPages: pickSlipSelectors.totalPages(state),
    loadingPrevPage: pickSlipSelectors.loadingPrevPage(state),
    loadingNextPage: pickSlipSelectors.loadingNextPage(state),
    keyField: 'DespatchId',
    fieldsToDisplay: ['PickSlip', 'SalesEntity', 'Warehouse', 'CustomerIdDisplay'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('DespatchId', selectedRecord.DespatchId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const DespatchId = parseInt(matchParams.get('DespatchId'));
      if (DespatchId) {
        return {
          id: DespatchId,
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: pickSlipActions.search,
  searchById: pickSlipActions.searchById,
  changeSelectedRecord: pickSlipActions.setSelected,
  onToggle: uiActions.toggleSupplierLookUp,
  fetchNextPage: pickSlipActions.fetchNextPage,
  fetchPrevPage: pickSlipActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PSSearchLookUpDrawer);
