
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, getFormSyncErrors, reset, initialize } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as contactSelectors, actions as contactActions } from 'ducks/contact';
import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as configSelectors } from 'ducks/appConfig';

import Contacts from './Contacts';

const FORM_NAME = 'CustomerContacts';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  values: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedContact: contactSelectors.selected(state),
  selectedContactSchema: contactSelectors.selectedSchema(state),
  selectedCustomer: customerSelectors.selected(state),
  contactNotifications: contactSelectors.notifications(state),
  contactError: contactSelectors.error(state),
  isV2: configSelectors.isV2(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    updateContact: contactActions.update,
    initializeForm: (values) => initialize(FORM_NAME, values),
    createContact: contactActions.create,
    removeContact: contactActions.remove,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeOperationMode: operationActions.changeOperationMode,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
