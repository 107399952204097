const InvoiceDetails = {
  'id': 'InvoiceDetails',
  'layout': {
    'rows': 2,
    'columns': 3
  },
  'fields': [
    {
      'type': 'PAPER_CONTAINER',
      'props': {
        'label': 'Postal address',
        'name': 'address',
      },
      'children': [
        {
          'type': 'ADDRESS_BLOCK_EX',
          'props': {
            'name': 'address',
            'type': 'extended',
          }
        }
      ],
      'position': {
        'row': 1,
        'col': 1
      },
    },
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'position': {
        'row': 1,
        'col': 2
      },
      'children': [
        {
          'id': '1',
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'User ID',
            'name': 'UserID',
            'size': 'small'
          }
        },
        {
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Due date',
            'name': 'DueDate',
            'size': 'small'
          }
        },
        {
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Payment terms (days)',
            'name': 'PaymentTerms',
            'size': 'small',
            'type': 'number'
          }
        },
        {
          'id': '3',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Invoice instructions',
            'name': 'InvoiceInstructions1',
            'size': 'large'
          }
        },
        {
          'id': '4',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'name': 'InvoiceInstructions2',
            'size': 'large'
          }
        }
      ]
    },
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'position': {
        'row': 1,
        'col': 3
      },
      'children': [
        {
          'id': '1',
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'S/order category',
            'name': 'SalesOrderCategory',
            'size': 'small',
          }
        },
        {
          'id': '1',
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Service category',
            'name': 'ServiceCategory',
            'size': 'small',
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price category',
            'name': 'PriceCategory',
            'size': 'small'
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax class',
            'name': 'TaxClass',
            'size': 'small'
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax exempt reference',
            'name': 'TaxExemptReference',
            'size': 'medium'
          }
        }
      ]
    },
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'label': 'Credit note details',
        'fullWidth': true
      },
      'position': {
        'row': 2,
        'col': 1
      },
      'children': [
        {
          'type': 'EXTENDED_TEXT_FIELD',
          'props': {
            'label': 'Original invoice',
            'name': 'OrignalInvoice',
            'size': 'small',
            'api': '/salesInvoiceEnquiry/lookup/action',
            'params': {
              'queryFields': {
                'InvoiceNumber': 'OrignalInvoice',
                'SalesEntity': 'SalesEntity',
                'SearchText': { 'Field': 'OrignalInvoice', 'Property': 'Value' }
              }
            }
          }
        },
        {
          'type': 'EXTENDED_TEXT_FIELD',
          'props': {
            'label': 'Return authority',
            'name': 'ReturnAuthority',
            'size': 'small',
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reference',
            'name': 'Reference',
            'size': 'medium'
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Restocking charge',
            'name': 'RestockChargeDisplay',
            'size': 'medium',
            'type': 'text',
            'alignment': 'right',
          }
        }
      ]
    },
    {
      'id': '1',
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'User defined categories'
      },
      'position': {
        'row': 2,
        'col': 2
      },
      'children': [
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 1',
            'name': 'Category1',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px',
              clear: 'left',
            }
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 4',
            'name': 'Category4',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px'
            }
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 2',
            'name': 'Category2',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px',
              clear: 'left',
            }
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 5',
            'name': 'Category5',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px',
              clear: 'left',
            }
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 3',
            'name': 'Category3',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px',
              clear: 'left',
            }
          }
        },
        {
          'id': '1',
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Category 6',
            'name': 'Category6',
            'size': 'small',
            style: {
              display: 'inline-block',
              width: '155px',
              marginRight: '16px',
              clear: 'left',
            }
          }
        },
        {
          'id': 1,
          'type': 'SUBHEADER',
          'props': {
            'value': 'Internal sale details',
          }
        },
        {
          'type': 'EXTENDED_TEXT_FIELD',
          'props': {
            'label': 'Local shipment',
            'name': 'LocalShipment',
            'size': 'small',
            // 'api': '/salesInvoiceEnquiry/lookup/action',
          }
        }
      ]
    }
  ]
};
export default InvoiceDetails;