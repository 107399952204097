import { IFormViewForm } from 'components/FormView';

const SupplierGeneralSettings: IFormViewForm = {
  id: 'SupplierGeneralSettings',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Owner',
            name: 'Owner',
            size: 'large',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Date opened',
            name: 'DateOpened',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'SUBHEADER',
          props: {
            value: 'General settings',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supplier type',
            name: 'SupplierType',
            size: 'medium',
            type: 'string'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Pareto class',
            name: 'ParetoClass',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Advise method',
            name: 'AdviceMethod',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 5,
          type: 'SUBHEADER',
          props: {
            value: 'Internal distribution details',
          }
        },
        {
          id: 6,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Warehouse',
            name: 'Warehouse',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 7,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Entity',
            name: 'SalesEntity',
            size: 'small',
            type: 'string',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        label: 'Purchasing settings'
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_DISPLAY_FIELD',
          visible: true,
          props: {
            label: 'Freight factor',
            name: 'FreightFactor',
            size: 'small',
            type: 'number',
            decimalSeparator: true,
            adornment: '%',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supply lead time (days)',
            name: 'LeadTime',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Minimum order value',
            name: 'MinimumOrderValue',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Min order message',
            name: 'MinOrderMessage',
            size: 'large',
            type: 'string',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            size: 'small',
            type: 'string',
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Order category',
            name: 'OrderCategory',
            size: 'small',
            type: 'string',
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        label: 'Account options'
      },
      children: [
        {
          id: 0,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Buy prices include tax',
            name: 'BuyPriceIncludeTax',
          }
        },
        {
          id: 1,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Recommended sell prices include tax',
            name: 'SellPriceIncludeTax',
          }
        },
        {
          id: 2,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Allow backorders',
            name: 'AllowBackorders',
          }
        },
        {
          id: 3,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Ask for shipment numbers on invoice entry',
            name: 'AskForShipmentNumbersOnInvoiceEntry',
          }
        },
        {
          id: 0,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Remittance advice required',
            name: 'RemittanceAdviceRequired',
          }
        }
      ]
    }
  ]
};

export default SupplierGeneralSettings;
