import React from 'react';
import classNames from 'classnames';
import { ILineLostSaleExtended, IPickSlipsProps } from './PickSlips.properties';
import PSSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/PickSlipTransaction';
import { ILocationState, useModule } from 'utils/hooks';
import { MainScreenTabs, TABS_DATA } from './PickSlips.constants';
import modules from './PickSlips.modules';
import { ActionBar, BreadcrumbBar, NavigationTabs } from '@markinson/uicomponents-v2';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './PickSlips.options';
import { Route, withRouter } from 'react-router';
import Lines from './Lines';
import Details from './Details';
import { withStyles } from '@material-ui/core';
import styles from './PickSlips.styles';
import PickSlipsSummaryPanel from './SummaryPanel';
import Contact from './Contact';
import { IDetailsHandle } from './Details/Details.properties';
import { IContactHandle } from './Contact/Contact.properties';
import ChargesDialog from './ChargesDialog';
import LineDetails from './LineDetails';
import { ILineDetailsHandle } from './LineDetails/LineDetails.properties';
import LostSalesDialog from 'components/common/LostSalesDialog';
import { PickSlipAPIType, useLostSale, useMultipleBom, usePriceOverride, useTabLocations } from './PickSlips.hooks';
import { ILinesHandle } from './Lines/Lines.properties';
import { LostSalesType } from 'components/common/LostSalesDialog/LostSalesDialog.properties';
import PriceOverrideAuthorityDialog from 'components/common/PriceOverrideAuthorityDialog';
import PrintDialog from './PrintDialog';
import { useRemovePickSlip } from 'api/pickSlips/pickslip';
import { ISummaryHandle } from './SummaryPanel/SummaryPanel.properties';
import { IProcessPickSlipDialogsHandle } from './ProcessPickSlipDialogs/ProcessPickSlipDialogs.properties';
import ProcessPickSlipDialogs from './ProcessPickSlipDialogs';
import Diary from './Diary';
import BOMDialog from 'components/common/BOMDialog';
import DiaryDetails from './Diary/DiaryDetails';
import { IDiaryDetailsHandle } from './Diary/DiaryDetails/DiaryDetails.properties';
import { Operation } from 'utils/operations';
import Notepad from './Notepad/index';
import { INotepadHandle } from './Notepad/Notepad.properties';

const PickSlips = (props: IPickSlipsProps) => {
    const { classes, match, history, location, isMenuOptionOpen, isSupplierLookUpOpen, selectedPickSlip, selectedDespatchId, toggleMenuOption, changeOperationMode } = props;

    const { actionBarProps } = React.useContext(ActionBarContext);

    const url = match ? match.url : '';
    const path = match ? match.path : '';

    const detailsPageRef = React.useRef<IDetailsHandle>();
    const ContactDetailsRef = React.useRef<IContactHandle>();
    const linesRef = React.useRef<ILinesHandle>();
    const processPickSlipRef = React.useRef<IProcessPickSlipDialogsHandle>();
    const notepadPageRef = React.useRef<INotepadHandle>();

    const lineDetailsPageRef = React.useRef<ILineDetailsHandle>();
    const diaryDetailsPageRef = React.useRef<IDiaryDetailsHandle>();
    const summaryRef = React.useRef<ISummaryHandle>();
    const { tabLocations } = useTabLocations(url, location);
    const [openDocumentOutputDialog, setOpenDocumentOutputDialog] = React.useState<boolean>(false);
    const [openChargesDialog, setOpenChargesDialog] = React.useState<boolean>(false);

    const { dialogStates: priceOverrideStates, closeDialog: closePriceOverrideDialog, onSubmit: priseOverrideOnSubmit } = usePriceOverride();
    const { dialogStates, closeModal: closeLotSaleDialog, onSubmit: onLostSaleSubmit, initiateLostSale, isLostSale } = useLostSale();
    const { multipleBomDialog, closeDialog: closeBomDialog, onBomSelectedRowChanged, OnActionClick: bomOnActionClick } = useMultipleBom();
    const { selectedTab, handleModuleChange } = useModule(
        history,
        location,
        'Lines',
        tabLocations,
        modules,
    );

    const removePickSlipMutation = useRemovePickSlip();

    React.useEffect(
        () => {
            if (!selectedDespatchId) {
                handleModuleChange('Lines');
            }
        },
        [selectedDespatchId]
    );
    React.useEffect(
        () => {
            return () => {
                props.resetSearchLookupDrawer();
            };
        },
        []
    );

    const _handleModuleChange = React.useCallback(
        (tabToNavigate, inlineObject?: ILocationState) => {
            if (!selectedDespatchId) return;

            if (selectedTab === 'Details' && !detailsPageRef.current?.isChangesSavedOrDiscarded()) {
                return;
            }
            if (selectedTab === 'LineDetails') {
                if (lineDetailsPageRef.current?.isChangesSavedOrDiscarded()) {
                    lineDetailsPageRef.current?.onCancel();
                }

                return;
            }
            if (selectedTab === 'ComponentDetails') {
                lineDetailsPageRef.current.componentDetailOnCancel();

                return;
            }
            if (selectedTab === 'SalesDiaryDetails' && inlineObject === undefined && diaryDetailsPageRef.current?.isChangesSavedOrDiscarded()) {

                return;
            }
            if (selectedTab === 'SalesNotepad' && inlineObject === undefined && notepadPageRef.current?.isChangesSavedOrDiscarded()) {

                return;
            }

            handleModuleChange(tabToNavigate, inlineObject ?? { search: (selectedTab === 'Lines') ? { DespatchId: selectedDespatchId } : null });
        },
        [selectedTab, detailsPageRef, selectedDespatchId, handleModuleChange]
    );
    const handleOK = React.useCallback(
        () => {
            setOpenChargesDialog(false);
            if (linesRef.current) {
                linesRef.current.refreshDataGrid();
            }
        },
        [linesRef.current]
    );

    const handleToggleMenuOptionOpen = React.useCallback(
        () => {
            toggleMenuOption(!isMenuOptionOpen);
        },
        [isMenuOptionOpen, toggleMenuOption]
    );

    const removePickSlip = React.useCallback(
        async () => {
            const response = await removePickSlipMutation.mutateAsync
                ({
                    DespatchId: selectedDespatchId
                });
            if (isLostSale(response?.Forms)) {
                await initiateLostSale(PickSlipAPIType.removePickSlip, selectedDespatchId, null, null, response?.RemovePickSlipCriteria?.inlineObject?.LostSaleDetails);
            } else if (response?.Status === true) {
                linesRef.current?.refreshDataGrid();
                summaryRef.current.reloadSummary();
            }
        },
        [selectedDespatchId, linesRef, summaryRef]
    );

    const searchParams = React.useCallback(
        () => ({
        search: {
            DespatchId: selectedDespatchId
        }
        }),
        [selectedDespatchId]
    );

    const handleActionBarButtonClick = React.useCallback(
        (action: string) => {
            switch (action) {
                case 'Back':
                    switch (selectedTab) {
                        case 'LineDetails':
                            if (lineDetailsPageRef.current?.isChangesSavedOrDiscarded()) {
                                lineDetailsPageRef.current?.onCancel();
                            }
                            break;

                        case 'KitComponents':
                        case 'SalesDiaryDetails':
                            const backPageFor = {
                                KitComponents: 'LineDetails',
                                SalesDiaryDetails: 'SalesDiary',
                            };
                            _handleModuleChange(backPageFor[selectedTab]);
                            break;
                        case 'SalesNotepad':
                            _handleModuleChange('Lines');
                            break;
                        default:
                    }
                    break;
                case 'removePickSlip':
                    removePickSlip();
                    break;
                case 'PickSlipDetailsOk':
                    detailsPageRef.current?.ok(() => handleModuleChange('Lines'));
                    break;
                case 'PickSlipDetailsCancel':
                    detailsPageRef.current?.onCancel(() => handleModuleChange('Lines'));
                    break;
                case 'PickSlipContactDetailsOk':
                    ContactDetailsRef.current.onOK(() => handleModuleChange('Lines'));
                    break;
                case 'Print':
                    setOpenDocumentOutputDialog(true);
                    break;
                case 'Charges':
                    setOpenChargesDialog(true);
                    break;
                case 'Process':
                    processPickSlipRef.current?.process();
                    break;
                case 'AddDiary':
                case 'PickSlipDiaryDetailsNew':
                    changeOperationMode(Operation.NEW);
                    _handleModuleChange('SalesDiaryDetails', searchParams());
                    break;
                case 'PickSlipDiaryDetailsOk':
                    diaryDetailsPageRef.current?.[action]((SearchObj) => handleModuleChange('SalesDiaryDetails', SearchObj));
                    break;
                case 'PickSlipDiaryDetailsCancel':
                case 'PickSlipDiaryDetailsDelete':
                case 'PickSlipDiaryDetailsEdit':
                    diaryDetailsPageRef.current?.[action](() => _handleModuleChange('SalesDiary', searchParams()));
                    break;
                    case 'NotepadOk':
                    case 'NotepadCancel':
                    case 'NotepadEdit':
                        notepadPageRef.current?.[action]();
                        break;
                case 'ComponentDetails':

                    break;
                default:
            }
        },
        [selectedTab, detailsPageRef, ContactDetailsRef, diaryDetailsPageRef, processPickSlipRef, selectedDespatchId, notepadPageRef, _handleModuleChange, handleModuleChange, removePickSlip]
    );

    const _onLostSaleSubmit = React.useCallback(
        async (data: ILineLostSaleExtended, _type: LostSalesType, apiType) => {
            await onLostSaleSubmit(apiType, data, linesRef, lineDetailsPageRef, summaryRef);
        },
        [linesRef, lineDetailsPageRef, onLostSaleSubmit, handleModuleChange, summaryRef]
    );

    const _priseOverrideOnSubmit = React.useCallback(
        (data, apiType) => {
            priseOverrideOnSubmit(apiType, data, linesRef, lineDetailsPageRef);
        },
        [linesRef, lineDetailsPageRef, priseOverrideOnSubmit]
    );

    const handleActionClick = React.useCallback(
        (status) => {
            bomOnActionClick(status, lineDetailsPageRef, linesRef);
        },
        [lineDetailsPageRef, bomOnActionClick]
    );

    return (
        <div className={classes.mainDiv}>
            <div className={classes.innerContainer}>
                <PSSearchLookUpDrawer enableToggle disabled={!MainScreenTabs.includes(selectedTab)} />
                <div
                    className={classNames(classes.PSSection, {
                        [classes.PSSectionMarginOpen]: isSupplierLookUpOpen,
                        [classes.PSSectionMarginClose]: !isSupplierLookUpOpen
                    })}
                >
                    <BreadcrumbBar
                        mainModule={'Pick Slips'}
                        activeModuleId={selectedTab}
                        moduleTree={modules}
                        onBreadcrumbClick={_handleModuleChange}
                        onMainModuleClick={() => _handleModuleChange('Lines')}
                        onOptionsMenuClick={handleToggleMenuOptionOpen}
                    />
                    <PickSlipsSummaryPanel ref={summaryRef} selectedDespatchId={selectedDespatchId} />
                    {TABS_DATA.some(({ id }) => id === selectedTab) && <NavigationTabs
                        lookupOpen={isSupplierLookUpOpen}
                        tabsData={TABS_DATA}
                        activeTabId={selectedTab}
                        onTabChange={_handleModuleChange}
                    />}
                    <Route
                        key={'Lines'}
                        path={path}
                        exact
                        render={() => <Lines forwardRef={linesRef} changeSelectedTab={handleModuleChange} />}
                    />
                    <Route
                        key={'Details'}
                        path={`${path.endsWith('/') ? path : `${path}/`}details`}
                        exact
                        render={() => (
                            <Details innerRef={detailsPageRef} />
                        )}
                    />
                    <Route
                        key={'Contact'}
                        path={`${path.endsWith('/') ? path : `${path}/`}contact`}
                        exact
                        render={(routeProps) => (
                            <Contact
                                {...routeProps}
                                innerRef={ContactDetailsRef} />
                        )}
                    />
                    <Route
                        key={'LineDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}line-details*`}
                        exact
                        render={(routeProps) => (
                            <LineDetails {...routeProps} innerRef={lineDetailsPageRef} changeSelectedTab={handleModuleChange} />
                        )}
                    />
                    <Route
                        key={'SalesDiary'}
                        path={`${path.endsWith('/') ? path : `${path}/`}sales-diary`}
                        exact
                        render={() => (
                            <Diary changeSelectedTab={handleModuleChange} DespatchId={selectedDespatchId} />
                        )}
                    />
                    <Route
                        key={'SalesDiaryDetails'}
                        path={`${path.endsWith('/') ? path : `${path}/`}sales-diary-details`}
                        exact
                        render={(routeProps) => (
                            <DiaryDetails {...routeProps} innerRef={diaryDetailsPageRef} />
                        )}
                    />
                    <Route
                        key={'SalesNotepad'}
                        path={`${path.endsWith('/') ? path : `${path}/`}notepad`}
                        exact
                        render={() => (
                            <Notepad innerRef={notepadPageRef} />
                        )}
                    />
                </div>
            </div>
            <OptionsMenu
                open={isMenuOptionOpen}
                options={options.PS}
                onOptionClick={_handleModuleChange}
                defaultValue={selectedTab}
            />
            {
                (selectedDespatchId && openDocumentOutputDialog) &&
                <PrintDialog
                    despatchId={selectedDespatchId}
                    openDocumentOutputDialog={openDocumentOutputDialog}
                    setOpenDocumentOutputDialog={setOpenDocumentOutputDialog}
                    customerId={selectedPickSlip?.CustomerId}
                />
            }
            <ChargesDialog
                despatchId={selectedDespatchId}
                open={openChargesDialog}
                onOk={handleOK}
                onCancel={setOpenChargesDialog}
            />
            <ActionBar {...actionBarProps} onActionClick={handleActionBarButtonClick} />
            <LostSalesDialog
                open={dialogStates.open}
                loading={false}
                data={dialogStates.data}
                type={dialogStates.type}
                onOk={_onLostSaleSubmit}
                apiType={dialogStates.apiType}
                onCancel={closeLotSaleDialog}
            />
            <PriceOverrideAuthorityDialog
                open={priceOverrideStates.open}
                loading={priceOverrideStates.loading}
                type={priceOverrideStates.type}
                data={priceOverrideStates.data}
                apiType={priceOverrideStates.apiType}
                onOk={_priseOverrideOnSubmit}
                onCancel={closePriceOverrideDialog}
            />
            <ProcessPickSlipDialogs
                ref={processPickSlipRef}
                linesRef={linesRef}
                summaryRef={summaryRef} />
            <BOMDialog
                open={multipleBomDialog?.open}
                onClose={closeBomDialog}
                data={multipleBomDialog?.data}
                kitStatus={multipleBomDialog?.kitStatus ? multipleBomDialog?.kitStatus?.toLowerCase() : ''}
                onActionClick={handleActionClick}
                onSelectedRowChanged={onBomSelectedRowChanged}
            />
        </div >
    );
};

export default withRouter(withStyles(styles, { index: 1 })(PickSlips));
