import { connect } from 'react-redux';
import BranchOrders from './BranchOrders';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as branchOrderSelectors } from 'ducks/purchaseOrderEnquiry/branchOrders';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  notifications: state.salesOrderEnquiry.deliveryLine.notifications,
  branchOrderSummary: branchOrderSelectors.summary(state),
  branchOrderSummaryLoading: branchOrderSelectors.isLoading(state),
});

export default connect(mapStateToProps, {})(BranchOrders);
