import ProductCell from 'components/common/ProductCell';
import { COLUMN_SIZE, DEFAULT_PAGE_SIZE, NumberOfDecimalPlaces, Patterns } from 'components/common/DataGridDevEx/DataGrid.constants';
import { DataGrid } from 'devextreme-react';
import { Column, GroupPanel, Paging, Scrolling, SearchPanel, Sorting, LoadPanel, Editing, Button } from 'devextreme-react/data-grid';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import styles from './Lines.styles';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Cancel as CancelIcon, Process as ProcessIcon } from '@markinson/uicomponents-v2/SvgIcons';
import {
    useCancelPurchaseOrder, useDeletePurchaseOrderGridLine, useRetreivePurchaseOrderLines,
    useRetrievePurchaseOrderSummary, useUpdatePurchaseOrderLine, useCompletePurchaseOrder, useProcessPurchaseOrder
} from 'api/Worksale/backorderCommitment';
import { ILinesProperties, IPurchaseOrderLinesHandle } from './Lines.properties';
import { createDataSource, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import { ICommitPurchaseOrderGridLineDetailFacade, IPurchaseOrderCommitCriteriaFacade } from 'api/swaggerTypes';
import { withStyles } from '@material-ui/core/styles';
import { useBOCContextSelector, useBOCDispatch } from '../../BackorderCommitment.context';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import FinalConfirmationDialog from '../FinalConfirmationDialog';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { isScreenRequest } from 'api/utils';

const ChangeFields = {
    BackorderedQuantity: 'OnOrderBUQuantity',
} as any;

const Lines = (props: ILinesProperties, ref: React.Ref<IPurchaseOrderLinesHandle>) => {
    const {
        classes, supplierId, onDetailClick, changeSelectedTab, changeConfirmationDialog,
        setPurchaseOrderOuputOptions, calculateCellValue
    } = props;

    const { preventDataGridDefaults } = useGridKeyboardNavigation();
    const { setActionBar } = React.useContext(ActionBarContext);
    const dataGridInnerRef = React.useRef<DataGrid>();

    const [finalConfirmationModalOpen, setFinalConfirmationModalOpen] = React.useState(false);

    const contextDispatch = useBOCDispatch();
    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);

    const retreivePurchaseMutation = useRetreivePurchaseOrderLines();
    const updatePurchaseOrderLineMutation = useUpdatePurchaseOrderLine();
    const deletePurchaseOrderGridLineMutation = useDeletePurchaseOrderGridLine();
    const retreivePurchaseOrderSummaryMutation = useRetrievePurchaseOrderSummary(PurchaseOrderId);
    const completePurchaseOrderMutation = useCompletePurchaseOrder();
    const cancelPurchaseOrder = useCancelPurchaseOrder();

    const processPurchaseOrderMutation = useProcessPurchaseOrder();

    React.useImperativeHandle(
        ref,
        () => ({
            async onCancel(handleModuleChange: (page: string) => void): Promise<void> {
                if (!PurchaseOrderId) {
                    handleModuleChange('BackorderLines');

                    return;
                }
                const response = await cancelPurchaseOrder.mutateAsync({
                    PurchaseOrderId
                });

                if (response.Status && !response.Forms) {
                    showSnackBar({ variant: 'success', message: 'Purchase order cancelled successfully.' });
                    handleModuleChange('BackorderLines');
                }

            },
            async onProcess(): Promise<void> {
                const response = await processPurchaseOrderMutation.mutateAsync({
                    PurchaseOrderId,
                });
                setFinalConfirmationModalOpen(response?.Status);
                if (response?.Status === true && response?.PurchaseOrderConfirmation) {
                    setPurchaseOrderOuputOptions(response?.PurchaseOrderConfirmation?.inlineObject?.PurchaseOrderOutput);
                }
            }
        }),
        [PurchaseOrderId]
    );

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        []
    );

    const rightIcons = [
        {
            label: 'Cancel',
            Icon: CancelIcon,
            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
            action: 'PurchaseOrderLinesCancel'
        },
        {
            label: 'Process',
            Icon: ProcessIcon,
            action: 'PurchaseOrderLinesProcess',
            disabled: true
        },
    ];

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons
        });
    };

    React.useEffect(
        () => {
            if (dataGridInnerRef.current?.instance?.getDataSource()?.items().length) {
                setActionBar({
                    leftIcons: [],
                    centerIcons: [],
                    rightIcons: [
                        {
                            label: 'Cancel',
                            Icon: CancelIcon,
                            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
                            action: 'PurchaseOrderLinesCancel',
                        },
                        {
                            label: 'Process',
                            Icon: ProcessIcon,
                            action: 'PurchaseOrderLinesProcess',
                            iconStyle: { fill: 'rgba(109, 217, 0, 1)' },
                        },
                    ]
                });
            }
        },
        [dataGridInnerRef.current?.instance?.getDataSource()?.items().length]);

    const loadLines = React.useCallback(
        async (BatchPage, BatchSize, Sort) => {
            if (!PurchaseOrderId) {
                return [];
            }

            const response = await retreivePurchaseMutation.mutateAsync({
                PurchaseOrderId: PurchaseOrderId,
                urlQueryParams: {
                    BatchPage,
                    BatchSize,
                    Sort
                }
            });

            return response?.PurchaseOrderLines ?? [];
        },
        [PurchaseOrderId]
    );

    const updateLine = React.useCallback(
        async (data) => {
            if (!PurchaseOrderId || !data?.PurchaseOrderLineId) {
                return null;
            }

            const item = await dataGridInnerRef.current?.instance?.getDataSource().items().find((f) => f.PurchaseOrderLineId === data.PurchaseOrderLineId);

            const response = await updatePurchaseOrderLineMutation.mutateAsync({
                ...item,
                ...data,
                OnOrderBUQuantity: data?.BackorderedQuantity ?? item?.BackorderedQuantity,
                PurchaseOrderId: PurchaseOrderId
            });
            retreivePurchaseOrderSummaryMutation.refetch();

            return response?.PurchaseOrderGridLineDetail ?? null;

        },
        [PurchaseOrderId, dataGridInnerRef]
    );

    const deleteLine = React.useCallback(
        async (PurchaseOrderLineId) => {
            if (!PurchaseOrderLineId) {
                return null;
            }

            const response = await deletePurchaseOrderGridLineMutation.mutateAsync({
                PurchaseOrderLineId,
            });
            retreivePurchaseOrderSummaryMutation.refetch();

            return response?.Status ?? null;

        },
        []
    );

    const linesDataSource = React.useMemo(
        () => createDataSource(
            'PurchaseOrderLineId',
            { fetch: loadLines, update: updateLine, remove: deleteLine },
        ),
        [loadLines, updateLine, deleteLine]
    );

    const detailOnClick = React.useCallback(
        (data) => {
            onDetailClick(data?.data);
        },
        [onDetailClick]
    );

    const handleCalculateCellValue = React.useCallback(
        async <K extends keyof ICommitPurchaseOrderGridLineDetailFacade>(rowData: ICommitPurchaseOrderGridLineDetailFacade, field: K, value: ICommitPurchaseOrderGridLineDetailFacade[K]) =>
            calculateCellValue({
                ...rowData,
                [field]: value,
                urlQueryParams: {
                    ChangedFieldId: field
                }
            })
        ,
        []
    );

    const onValueChanged = (e: EditorPreparingEvent<any, any>) => async (d) => {
        const changeField = ChangeFields?.[e?.dataField] ? ChangeFields[e?.dataField] : e?.dataField;
        e.component.cellValue(e?.row?.rowIndex, e.dataField, d.value);
        const lineDetail = await handleCalculateCellValue(e?.row?.data, changeField, d.value);
        e.component.cellValue(e?.row?.rowIndex, e.dataField, lineDetail?.[changeField]);
    };

    const onDelete = React.useCallback(
        (e) => {
            changeConfirmationDialog({
                open: true,
                title: 'Delete Order Line',
                message: 'The selected order line will be deleted. \nThis action cannot be reversed.',
                okLabel: 'Delete',
                showRefuse: false,
                onCancel: () => undefined,
                onOk: async () => {
                    await linesDataSource.store().remove(e?.row?.key);
                    dataGridInnerRef.current?.instance?.refresh(true);
                }
            });
        },
        [linesDataSource, dataGridInnerRef]
    );

    const handleOnSaving = React.useCallback(
        async (e) => {
            e.cancel = true;
            const rowData = (e.changes && e.changes.length) ? e.changes[0].data : null;
            if (rowData) {
                const editedRowData = e.component.getVisibleRows()?.find((r) => r.isEditing)?.data;
                await linesDataSource.store().update(editedRowData.PurchaseOrderLineId, editedRowData);
                await linesDataSource.reload();
                dataGridInnerRef.current.instance.refresh();
                dataGridInnerRef.current.instance.cancelEditData();
            } else {
                dataGridInnerRef.current.instance.cancelEditData();
            }
        },
        [linesDataSource, dataGridInnerRef]
    );

    const completeProcessPurchaseOrder = React.useCallback(
        async (data: IPurchaseOrderCommitCriteriaFacade) => {

            const response = await completePurchaseOrderMutation.mutateAsync({
                ...data,
                PurchaseOrderId: PurchaseOrderId,
            });

            if (response?.Status && response?.Forms) {
                if (isScreenRequest(response?.Forms, 'NotificationDialog')) {
                    contextDispatch({
                        setInternalOrderDialogData: response?.PurchaseOrderCommitDetail?.inlineObject?.Notification,
                        setOpenInternalOrderDialog: true
                    });
                }
                changeSelectedTab('BackorderLines');
                setFinalConfirmationModalOpen(false);
            }
        },
        [PurchaseOrderId, setFinalConfirmationModalOpen, changeSelectedTab, contextDispatch]
    );

    return (
        <Paper className={classes.parentContainer} >
            <DataGrid
                ref={dataGridInnerRef}
                className={classes.grid}
                onKeyDown={preventDataGridDefaults}
                dataSource={linesDataSource}
                showBorders={false}
                renderAsync={true}
                repaintChangesOnly={true}
                allowColumnReordering={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                noDataText='No Data'
                onEditorPreparing={(e) => {
                    e.editorOptions.onFocusIn = (args) => {
                        const input = args.element.querySelector("input[type='text']");
                        if (input) {
                            input.select();
                        }
                    };
                    if (e.dataField === 'BackorderedQuantity') {
                        e.editorOptions.disabled = Boolean(e.row.data.BackorderedQuantityReadOnly);
                    }
                    if (e.dataField === 'UnitConversion') {
                        e.editorOptions.disabled = Boolean(e.row.data.UnitConversionReadOnly);
                    }
                    if (e.dataField === 'PriceCurr') {
                        e.editorOptions.disabled = Boolean(e.row.data.PriceCurrReadOnly);
                    }
                    e.editorOptions.onValueChanged = onValueChanged(e);
                }}
                onSaving={handleOnSaving}
            >
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={true}
                    confirmDelete={false}
                />
                <Column
                    allowEditing={false}
                    minWidth={COLUMN_SIZE.sm0}
                    width={COLUMN_SIZE.sm0}
                    allowResizing={false}
                    fixed={true}
                    alignment='center'
                    allowHeaderFiltering={false}
                    cellComponent={({ data }) => {

                        return <MoreIcon className={classes.lineDetailIcon} onClick={() => detailOnClick(data)} />;
                    }}
                />
                <Column
                    dataField={'ProductIdDisplay'}
                    caption={'Product'}
                    minWidth={COLUMN_SIZE.md0}
                    allowEditing={false}
                    width={COLUMN_SIZE.md1}
                    fixed={true}
                    cellComponent={(allProps) => (
                        <ProductCell
                            urlParamKeys={['ProductId']}
                            toLink={'/inventory-enquiry/product-details'}
                            {...allProps} />
                    )}
                />
                <Column
                    dataField={'BackorderedQuantity'}
                    caption={'Quantity'}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }}
                    validationRules={[Patterns.positiveTwoDecimalValue]}
                    calculateDisplayValue={
                        (data: Record<string, unknown>) =>
                            (data.BackorderedQuantity as number)?.toFixed(data.BackorderedQuantityMaximumFractionDigits as number ?? NumberOfDecimalPlaces)
                    }
                />
                <Column
                    dataField={'UnitBuy'}
                    caption={'Unit'}
                    allowEditing={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                />
                <Column
                    dataField={'UnitConversion'}
                    caption={'Conversion factor'}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }}
                    validationRules={[Patterns.positiveTwoDecimalValue]}
                    calculateDisplayValue={
                        (data: Record<string, unknown>) =>
                            (data.UnitConversion as number)?.toFixed(data.UnitConversionMaximumFractionDigits as number ?? NumberOfDecimalPlaces)
                    }
                />
                <Column
                    dataField={'PriceCurr'}
                    caption={'Price'}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    editorOptions={{
                        step: 0
                    }}
                    validationRules={[Patterns.positiveTwoDecimalValue]}
                    calculateDisplayValue={
                        (data: Record<string, unknown>) =>
                            (data.PriceCurr as number)?.toFixed(data.PriceCurrMaximumFractionDigits as number ?? NumberOfDecimalPlaces)
                    }
                />
                <Column
                    dataField={'ExtendedTotal'}
                    caption={'Extended'}
                    allowEditing={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                    calculateDisplayValue={
                        (data: Record<string, unknown>) =>
                            (data.ExtendedTotal as number)?.toFixed(data.ExtendedTotalMaximumFractionDigits as number ?? NumberOfDecimalPlaces)
                    }
                />
                <Column
                    dataField={'Description'}
                    caption={'Description'}
                    allowEditing={false}
                    minWidth={COLUMN_SIZE.md0}
                    width={COLUMN_SIZE.md1}
                />
                <Column type={'buttons'} width={COLUMN_SIZE.sm1} allowResizing={false} fixed={true}>
                    <Button name={'save'} icon={'save'} />
                    <Button name={'edit'} icon={'edit'} />
                    <Button name={'delete'} icon={'trash'} onClick={onDelete} />
                    <Button name={'cancel'} icon={'undo'} />
                    <Button name={'undelete'} icon={'undo'} />
                </Column>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Sorting mode='none' />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel enabled={false} />
            </DataGrid>

            <FinalConfirmationDialog
                open={finalConfirmationModalOpen}
                loading={completePurchaseOrderMutation.isLoading}
                data={processPurchaseOrderMutation.data}
                supplierId={supplierId}
                onCancel={() => { setFinalConfirmationModalOpen(false); }}
                onOk={completeProcessPurchaseOrder}
            />
        </Paper>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Lines)));
