import { connect } from 'react-redux';
import { selectors as orderLineDetailsSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLineDetails';
import PurchaseOrdersLinesEnquiry from './PurchaseOrderLinesEnquiry';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  orderLineDetails: orderLineDetailsSelectors.lineDetails(state),
  orderLineDetailsSchema: orderLineDetailsSelectors.lineDetailsSchema(state),
  orderLineSummary: orderLineDetailsSelectors.lineSummary(state),
  loadingOrderLineSummary: orderLineDetailsSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(PurchaseOrdersLinesEnquiry);
