import { combineReducers } from 'redux';
import serials from './serials';
import movements from './movements'
import quantities from './quantities'
import purchaseOrders from './purchaseOrders'
import productionOrders from './productionOrders'
import productionWIP from './productionWIP'
import inTransitImport from './inTransitImport'
import inTransitLocal from './inTransitLocal'
import picking from './picking'
import reserved from './reserved'
import kitting from './kitting'
import lotDetails from './lotDetails'
import binLocations from './binLocations'
import reserveHistory from './reserveHistory'
import lotPicking from './lotPicking'
import serviceWIP from './serviceWIP'
import inPutAwayWIP from './inPutAwayWIP'
import lotInPutAway from './lotInPutAway'
import lotReserved from './lotReserved'

const associationsReducer = combineReducers({
  serials,
  quantities,
  movements,
  inTransitImport,
  inTransitLocal,
  purchaseOrders,
  productionOrders,
  productionWIP,
  inPutAwayWIP,
  picking,
  kitting,
  reserved,
  lotDetails,
  binLocations,
  reserveHistory,
  lotPicking,
  serviceWIP,
  lotInPutAway,
  lotReserved
});

export default associationsReducer;
