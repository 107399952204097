import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as quotesSelectors, actions as quotesActions } from 'ducks/inventoryEnquiry/sales/quotes';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { fetch } from 'api/inventoryEnquiry/sales/quotes';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'IESQuotes',
  columnDefs: quotesSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: quotesSelectors.gridOptions(state),
  filterRow: quotesSelectors.filterRow(state),
  selectedFilters: quotesSelectors.selectedFilters(state),
  appliedFilters: quotesSelectors.filterRow(state) && filterSelectors.getFilters(state, quotesSelectors.filterRow(state).formName),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) && {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: quotesActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
};
