import { IFormViewForm } from 'components/FormView';

const FreightChargesDialog: IFormViewForm = {
    id: 'FreightChargesForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: 1,
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0,
                minColumnWidth: 300
            },
            position: {
                row: 1,
                col: 1,
            },
            children: [
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Outstanding despatch total',
                        name: 'OutstandingTotal',
                        type: 'number',
                        placeholder: '',
                        size: 'medium',
                        decimalSeparator: true,
                        decimalPlaces: 2,
                        readOnly: true
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Freight Cost',
                        name: 'FreightCost',
                        placeholder: '',
                        type: 'number',
                        size: 'medium',
                        decimalSeparator: true,
                        decimalPlaces: 4,
                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Freight Charges',
                        name: 'FreightCharges',
                        placeholder: '',
                        type: 'number',
                        size: 'medium',
                        decimalSeparator: true,
                        decimalPlaces: 2,
                    }
                },
                {
                    id: 4,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Freight Tax',
                        name: 'FreightTax',
                        placeholder: '',
                        size: 'medium',
                        readOnly: true
                    }
                },
            ]

        }
    ]
};

export default FreightChargesDialog;
