import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as orderActions, selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface IOrderItem {
  SalesOrder: number;
  Description: string;
  CustomerIdDescription: string;
  CustomerIdLabel: string;
}

const OrderSearchLookUpDrawer = buildSearchLookUpDrawer<IOrderItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IOrderItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IOrderItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Order',
    searchPlaceholder: 'Search for order...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'SalesOrder',
        label: 'Order'
      }
    ],
    records: orderSelectors.all(state) || [],
    selectedRecord: orderSelectors.selected(state),
    selectedSortFilter: 'SalesOrder',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: orderSelectors.isLoading(state),
    nextPage: orderSelectors.nextPage(state),
    prevPage: orderSelectors.prevPage(state),
    pageSize: orderSelectors.pageSize(state),
    totalPages: orderSelectors.totalPages(state),
    loadingPrevPage: orderSelectors.loadingPrevPage(state),
    loadingNextPage: orderSelectors.loadingNextPage(state),
    keyField: 'SalesOrder',
    fieldsToDisplay: ['SalesOrder', 'Description', 'CustomerIdDescription', 'CustomerIdLabel'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) matchParams.set('SalesOrder', selectedRecord.SalesOrder);

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const salesOrder = matchParams.get('SalesOrder');
      if (salesOrder) {
        return {
          id: salesOrder
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: orderActions.search,
  searchById: orderActions.searchById,
  changeSelectedRecord: orderActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: orderActions.fetchNextPage,
  fetchPrevPage: orderActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearchLookUpDrawer);
