import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'Schedule');

const initialState = asyncInitialState({
  gridOptions: {
    doubleClickActionTab: '',
    rowModelType: 'serverSide',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Schedule', field: 'Schedule', minWidth: 200, },
    { headerName: 'Custom price code', field: 'CustomerPriceCode', minWidth: 200, suppressSorting: true, },
    { headerName: 'Start', field: 'StartDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'End', field: 'EndDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Purpose', field: 'Purpose', minWidth: 200, suppressSorting: true, },
    { headerName: 'Type', field: 'Type', minWidth: 200, suppressSorting: true, },
    { headerName: 'Schedule description', field: 'Description', minWidth: 250, suppressSorting: true, },
    { headerName: 'Currency', field: 'CurrencyCode', minWidth: 100, suppressSorting: true, },
    { headerName: 'Fixed price contract', field: 'FixedPriceDisplay', minWidth: 200, suppressSorting: true, },
    { headerName: 'Tax included price', field: 'TaxIncludedPricesDisplay', minWidth: 200, suppressSorting: true, },
    { headerName: 'Supplier based pricing', field: 'SupplierBasedPricingDisplay', minWidth: 200, suppressSorting: true, },
    { headerName: 'Retail pricing', field: 'RetailPricingDisplay', minWidth: 150, suppressSorting: true, },
  ],
  Data: [],
  selectedFilters: {
    Schedule: '',
  },
  filterRow: {
    formName: 'ScheduleFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Schedule',
          name: 'Schedule',
          lookupName: 'PriceSchedule',
          size: 'small',
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.pricing.schedule,
  {
    linesData: data => data.Data,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.pricing.schedule.loading,
  gridOptions: state => state.inventoryEnquiry.pricing.schedule.data.gridOptions,
  columns: state => state.inventoryEnquiry.pricing.schedule.data.Columns,
  filterRow: state => state.inventoryEnquiry.pricing.schedule.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.pricing.schedule.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)