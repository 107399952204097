import { Payload, usePost, useDelete, useUpdate, useFetchMutation  } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { objectify, Inline } from 'api/utils';
import { IBatchInfo } from 'components/common/DataGridDevEx/DataGrid.properties';
import { IDiaryFacade, IDiarySearchResponse } from 'api/swaggerTypes';
import { toObjectified } from 'api/baseApi';

const diaryLinesResponseParser = (response): IDiarySearchResponse => {
    const { DiaryEntry } = response;

    return {
        ...response,
        DiaryEntry: DiaryEntry.map((responseObject) => objectify(responseObject as Inline<IDiaryFacade>))
    };
};
export type IRetrieveDiaryLinesPayload = Payload<{ DespatchId: number }, IBatchInfo>;

const updateDairyResponseParser = (response) => ({
    ...response,
    DiaryEntry: response.DiaryEntry && toObjectified(response.DiaryEntry as Inline<IDiaryFacade>)
});

export const useUpdateDiary = () =>
    useUpdate<Payload<{ DiaryId: number; Subject: string; CommentFull: string}>, any>
        (APIRoutes.pickSlips.diary.lines.update, {TableId: 'debinvdesp', DiaryType: ''}, { inline: true, responseParser: updateDairyResponseParser });

export const useRetrieveDiaryLines = () =>
    usePost<IRetrieveDiaryLinesPayload, any>
        (APIRoutes.pickSlips.diary.lines.retrieve, {DiaryType: ''}, { inline: true, responseParser: diaryLinesResponseParser });

export const useDeleteDiaryLines = (KeyId: number) =>
    useDelete<Payload<{ DiaryId: string }>>(APIRoutes.pickSlips.diary.lines.delete, {TableId: 'debinvdesp', KeyId, DiaryType: ''}, null);

const diaryDetailResponseParser = (response) => {
    const { DiaryEntry } = response;

    return {
        ...response,
        DiaryEntry: DiaryEntry ? toObjectified(DiaryEntry) : null
    };
};
export const useRetrieveDiaryDetail = () =>
    useFetchMutation<Payload<{ DiaryId?: number }>, any>(
        APIRoutes.pickSlips.diary.detail.retrieve,
        null,
        {
            inline: true,
            responseParser: diaryDetailResponseParser
        }
    );

export const useCreateDiaryDetail = () =>
usePost<Payload<{ DespatchId?: number; LineNumber?: number }>, IDiaryFacade>
    (APIRoutes.pickSlips.diary.detail.create, null, { inline: true, });
