export default [
    { id: 'Lines', label: 'Lines', parent: '' },
    { id: 'Details', label: 'Pick Slip Details', parent: '' },
    { id: 'Contact', label: 'Contact', parent: '' },
    { id: 'LineDetails', label: 'Line Details', parent: 'Lines' },
    { id: 'KitComponents', label: 'Kit Components', parent: 'LineDetails' },
    { id: 'ComponentDetails', label: 'Component Details', parent: 'KitComponents' },
    { id: 'SalesDiary', label: 'Diary', parent: '' },
    { id: 'SalesDiaryDetails', label: 'Diary Details', parent: 'SalesDiary' },
    { id: 'SalesNotepad', label: 'Notepad', parent: '' },
];
