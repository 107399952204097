import { createActions } from './utils';
import { OPERATIONS } from '../utils/operations';


export const { types, actions } = createActions({
  changeOperationMode: (operationMode) => operationMode,
}, 'uiOperations');

const initialState = {
  operationMode: OPERATIONS.BROWSE,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeOperationMode:
      return {
        ...state, operationMode: action.data
      };
    default:
      return state;

  }
};

export const selectors = {
  operationMode: state => state.uiOperations && state.uiOperations.operationMode,
}