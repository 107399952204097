import { connect } from 'react-redux';

import Events from './Events';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  selectedInvoice: invoiceSelectors.selected(state),
});

export default connect(mapStateToProps, {})(Events);
