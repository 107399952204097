import { createStyles } from '@material-ui/core';

export const creditStatusDialogStyles = createStyles({
    creditStatus: {
        color: 'red',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '25px',
    },
    dialogStyles: {
        minWidth: '300px',
    },
    button: {
        'backgroundColor': '#43A047',
        'color': '#FFFFFF',
        'marginRight': '20px',
        'marginBottom': '16px',
        '&:hover': {
            backgroundColor: '#66BB6A',
        }
    },
});

export const customerStatusStyles = createStyles({
    mainPanel: {
        width: '17vw',
        minWidth: '320px',
        maxWidth: '320px',
        backgroundColor: '#666666',
        float: 'right',
        height: 'calc(100vh - 64px)',
    },
    subPanel: {
        margin: '10px',
        padding: '10px'
    },
    subPanelLastChild: {
        margin: '10px 10px 120px 10px',
        padding: '10px'
    },
    subPanelTitle: {
        color: '#000000',
        fontSize: '15px',
        marginBottom: '10px'
    },
    summaryTable: {
        width: '100%',
        margin: 'auto'
    },
    summaryRow: {
        height: 'inherit',
    },
    headingCell: {
        backgroundColor: '#eeeeee',
        border: '1px solid #fff',
        color: '#000000',
        fontSize: '10px',
        fontWeight: 'bold',
        width: '75px',
        padding: '10px !important',
    },
    dataCell: {
        fontSize: '15px',
        padding: '10px !important',
        backgroundColor: '#dddddd',
        color: '#000000',
        border: '1px solid #fff !important'
    },
    cell: {
        backgroundColor: '#dddddd',
        color: '#000000',
        fontSize: '15px',
        border: '1px solid #fff',
        padding: '10px !important'
    },
    numericCell: {
        textAlign: 'right',
    },
    stringCell: {
        textAlign: 'center',
    },
    totalCell: {
        backgroundColor: '#cccccc',
        fontSize: '16px',
        color: '#000000',
        textAlign: 'right',
        padding: '10px !important'
    },
    splitCellContainer: {
        display: 'flex'
    },
    splitCellContent: {
        flex: '5',
    },
    splitCellAction: {
        flex: '1',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginRight: '-5px'
    },
    centerPanel: {
        margin: '10px',
        padding: '10px'
    },
    headers: {
        fontSize: '10px',
        fontWeight: 'bold'
    },
    headerCell: {
        backgroundColor: '#eeeeee',
        border: '1px solid #fff',
        fontSize: '10px',
        color: '#000000',
    },
    table2DataCell: {
        fontSize: '12px',
        paddingLeft: '10px'
    },
    numericDataCell: {
        paddingRight: '10px'
    },
    table1DataRow: {
        height: '25px',
    },
    table1HeaderRow: {
        height: '15px',
    },
    table1TD: {
        '& td': {
            width: '60px',
            padding: '3px ',
            textAlign: 'center'
        }
    },
    creditStatusCell: {
        color: 'RED',
        padding: '5px 10px',
        height: '30px',
        boxSizing: 'border-box',
    },
    icon: {
        'height': '18px',
        'width': '18px',
        'verticalAlign': 'middle',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    iconWarning: {
        'height': '24px',
        'width': '24px',
        'verticalAlign': 'middle',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    preLoaderWrapperCustomer: {
        height: '96px'
    },
    preLoaderWrapperTotal: {
        height: '106px'
    },
    preLoader: {
        position: 'relative',
        left: 'calc(9.5vw - 50px)',
    },
    creditStatusCellHover: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
});
