import { combineReducers } from 'redux';
import purchaseOrders, { IPurchaseOrdersState } from './purchaseOrders';
import products, { IProductsState } from './products';

export interface ISupplierPurchasingState {
  purchaseOrders: IPurchaseOrdersState;
  products: IProductsState;
}

const supplierFinancialReducer = combineReducers<ISupplierPurchasingState>({
  purchaseOrders,
  products
});

export default supplierFinancialReducer;
