import React from 'react';
import IServicePatternMaintenanceProperties from './ServicePatternMaintenance.properties';
import LookUpDrawer from '../common/SearchLookUpDrawer/Containers/ServicePattern';
import BreadcrumbBar from '@markinson/uicomponents-v2/BreadcrumbBar';
import * as styles from './ServicePatternMaintenance.styles.scss';
import modules from './ServicePatternMaintenance.modules';
import ServicePatternDetail from './ServicePatternDetail';
import { NavigationTabs } from '@markinson/uicomponents-v2';
import { TABS_DATA } from './ServicePatternMaintenance.constants';
import { withRouter, Route } from 'react-router-dom';
import { getKeyByValue, isNull } from 'utils/utils';
import OptionsMenu from 'components/common/OptionsMenu';
import options from './ServicePatternMaintenance.options';
import ActionBar from '@markinson/uicomponents-v2/ActionBar';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { IActionBarHandlers } from './ServicePatternDetail/ServicePatternDetail.properties';
import { IServicePatternItemDetailsFacade } from 'api/swaggerTypes';

const ServicePatternMaintenance = (props: IServicePatternMaintenanceProperties): JSX.Element => {
  const {
    match,
    history,
    ServicePatternId,
    isCustomerLookUpOpen,
    fetchServicePattern
  } = props;

  const { actionBarProps } = React.useContext(ActionBarContext);

  const url = match ? match.url : '';
  const path = match ? match.path : '';

  const tabLocations = {
    ServicePatternDetail: url,
  };

  const [optionsMenuOpen, setOptionsMenuOpen] = React.useState<boolean>(false);
  const [fetchPatternLoading, setFetchPatternLoading] = React.useState<boolean>(false);
  const [selectedServicePattern, setSelectedServicePattern] = React.useState<IServicePatternItemDetailsFacade>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>('ServicePatternDetail');
  const ServicePatternDetailRef = React.useRef<IActionBarHandlers>();
  React.useEffect(
    () => {
      if (!isNull(ServicePatternId)) {
        fetchServicePattern(ServicePatternId)
          .then((response) => {
            setSelectedServicePattern(response.ServicePatternDetails ? response.ServicePatternDetails.inlineObject : null);
          })
          .catch((err) => { console.warn(err); })
          .finally(() => setFetchPatternLoading(false));
      }
    },
    []);

  React.useEffect(
    () => {
        return () => {
            props.resetSearchLookupDrawer();
            props.destoryForm();
        };
    },
    []
  );

  React.useEffect(
    () => {
      const pathname = location ? location.pathname : '';
      const tabByLocation = getKeyByValue(tabLocations, pathname);
      setSelectedTab(tabByLocation);
    },
    [location]);

  function handleModuleChange(newSelectedTab: string): void {
    history.push(tabLocations[newSelectedTab]);
  }

  function handleOptionClick(newSelectedTab: string): void {
    handleModuleChange(newSelectedTab);
  }

  function navigateBack(): void {
    if (!selectedTab) {
      history.push('/dashboard');
    }
    if (selectedTab) {
      const targetModule = modules.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        history.push('/dashboard');
      } else {
        handleModuleChange(targetModule.parent);
      }
    }
  }

  function handleActionClick(action: string): void {
    const detailsRef = ServicePatternDetailRef.current;
    switch (action) {
      case 'Back':
        navigateBack();
        break;
      case 'Edit':
        detailsRef.onEdit();
        break;
      case 'Cancel':
        detailsRef.onCancel();
        break;
      case 'New':
        detailsRef.onNew();
        break;
      case 'Save':
        detailsRef.onOk();
        break;
      case 'Delete':
        detailsRef.onDelete();
        break;
      case 'AddJob':
        detailsRef.onAdd();
        break;
      default:
    }
  }

  function fetchPatternDetailsRecord(): void {
    if (ServicePatternId && fetchServicePattern) {
      setFetchPatternLoading(true);
      fetchServicePattern(ServicePatternId)
        .then((response) => {
          setSelectedServicePattern(response.ServicePatternDetails ? response.ServicePatternDetails.inlineObject : null);
        })
        .catch((err) => console.warn(err))
        .finally(() => setFetchPatternLoading(false));
    }
  }

  function getRecordChangeCallBacks(): (() => void)[] {
    const callBacks = [];
    switch (selectedTab) {
      case 'ServicePatternDetail':
        callBacks.push(fetchPatternDetailsRecord);
        break;
      default:
    }

    return callBacks;
  }

  return (
    <div className={styles.serviceItemTypeOuter}>
      <div className={styles.serviceItemTypeContainer}>
        <LookUpDrawer enableToggle={true}
          recordChangeCallBacks={getRecordChangeCallBacks()} />
        <div className={styles.serviceItemTypeSection} >
          <BreadcrumbBar
            onOptionsMenuClick={() => { setOptionsMenuOpen(!optionsMenuOpen); }}
            mainModule={'Service Pattern Maintenance'}
            activeModuleId={selectedTab}
            moduleTree={modules}
            onBreadcrumbClick={(moduleId) => { handleModuleChange(moduleId); }}
            onMainModuleClick={() => { handleModuleChange('ServicePatternDetail'); }}
          />
          {TABS_DATA.some(({ id }) => id === selectedTab) ? <NavigationTabs
            tabsData={TABS_DATA}
            activeTabId={selectedTab}
            onTabChange={(tab) => { handleModuleChange(tab); }}
            lookupOpen={isCustomerLookUpOpen}
          /> : null}
          <Route
            key={'Lines'}
            path={path}
            exact
            render={(routeProps) => {
              return <ServicePatternDetail
                {...routeProps}
                isLoading={fetchPatternLoading}
                selectedServPattern={selectedServicePattern}
                ref={ServicePatternDetailRef}
              />;
            }} />
        </div>
      </div>
      <OptionsMenu
        open={optionsMenuOpen}
        options={options}
        onOptionClick={handleOptionClick}
        defaultValue={selectedTab}
        onClickAway={() => { setOptionsMenuOpen(false); }}
      />
      <ActionBar {...actionBarProps} onActionClick={handleActionClick} />
    </div>
  );
};

export default withRouter(ServicePatternMaintenance);
