import React from 'react';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import { IActionItem } from '@markinson/uicomponents-v2/ActionBar/ActionBar.properties';
import { HotKeyNames } from './constants';

export interface IActionItemExtended extends IActionItem {
    hotkey?: HotKeyNames;
}
export interface IActionBarProps {
    disabled?: boolean;
    actionBar: {
        leftIcons: IActionItemExtended[];
        centerIcons: IActionItemExtended[];
        rightIcons: IActionItemExtended[];
    };
}

export const ActionBarContext = React.createContext<{
    actionBarProps: IActionBarProps;
    setDisabled(value?: boolean): void;
    setActionBar(value: {
        leftIcons: IActionItemExtended[];
        centerIcons: IActionItemExtended[];
        rightIcons: IActionItemExtended[];
    }): void;
}>(undefined);

const ActionBarContextProvider = (props: Readonly<React.PropsWithChildren<{}>>) => {
    const [disabled, setDisabled] = React.useState<boolean>();
    const [actionBar, setActionBar] = React.useState<{
        leftIcons: IActionItemExtended[];
        centerIcons: IActionItemExtended[];
        rightIcons: IActionItemExtended[];
    }>({
        leftIcons: [{
            label: 'Back',
            Icon: ArrowBack,
            action: 'Back',
        }],
        centerIcons: [],
        rightIcons: []
    });

    return (
        <ActionBarContext.Provider value={{
            actionBarProps: { disabled, actionBar },
            setDisabled: (value) => { setDisabled(() => value); },
            setActionBar: (value) => { setActionBar(() => value); },
        }}>
            {props.children}
        </ActionBarContext.Provider >
    );
};

export default ActionBarContextProvider;
