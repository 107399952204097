import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import POSCashReceipt from './POSCashReceipt';
import { getFormValues } from 'redux-form';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as cashReceiptSelectors, actions as cashReceiptActions } from 'ducks/cashReceipting/cashReceipt';
import { selectors as allocateSelectors, actions as allocateActions } from 'ducks/cashReceipting/allocate';

const FORM_NAME = 'CashReceipt';
const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  cashReceipt: cashReceiptSelectors.cashReceipt(state),
  entity: cashReceiptSelectors.entity(state),
  CustomerId: cashReceiptSelectors.CustomerId(state),
  cashReceiptPayload: cashReceiptSelectors.cashReceiptPayload(state),
  openProcessConfirmation: cashReceiptSelectors.openProcessConfirmation(state),
  openConfirmReceipt: cashReceiptSelectors.openConfirmReceipt(state),
  docOutputOptions: cashReceiptSelectors.docOutputOptions(state),
  cashReceiptSummary: cashReceiptSelectors.cashReceiptSummary(state),
  confirmReceiptLoading: cashReceiptSelectors.confirmReceiptLoading(state),
  isLoading: cashReceiptSelectors.isLoading(state) || allocateSelectors.isLoading(state),
  formValues: getFormValues(FORM_NAME)(state),
  confirmCashReceiptformValues: getFormValues('ConfirmCashReceipt')(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCashReceipt: cashReceiptActions.getCashReceipt,
    updateCashReceipt: cashReceiptActions.updateCashReceipt,
    deleteCashReceipt: cashReceiptActions.deleteCashReceipt,
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    allocateCashReceipt: allocateActions.allocateCashReceipt,
    processCashReceipt: cashReceiptActions.processCashReceipt,
    changeModalVisibility: cashReceiptActions.changeModalVisibility,
    confirmCashReceiptProcess: cashReceiptActions.confirmCashReceiptProcess,
    cashReceiptProcessComplete: cashReceiptActions.cashReceiptProcessComplete,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(POSCashReceipt);
