import React from 'react';
import IKitComponentsProperties from './KitComponents.properties';
import { withStyles } from '@material-ui/core/styles';
import inlineStyles from './KitComponents.styles';
import WorksaleFastEntry from '../FastEntry';
import SummaryTable from 'components/common/SummaryTable';
import KitComponentsDetailSummarySchema from './KitComponents.summaryTable.schema';
import { ICalculateComponentDetailsFacade, ICreateFastLineFacade, IWIPComponentFacade, IWorksaleFastLineDetailsResponse, IWorksaleGridLineDetailFacade, IWorksalelineKitSummaryFacade } from 'api/swaggerTypes';
import { MessageBoxColor } from 'components/common/ValidationForms/formDefinations';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { IFastEntryHandle } from '../FastEntry/FastEntry.properties';
import KitComponentsLines from './KitComponentsLines';
import { createDataSource } from '../DataGridDevEx/DataGrid.hooks';
import { BACK_BUTTON } from '../ActionBar/constants';

const KitComponents = (props: IKitComponentsProperties) => {

    const {
        classes,
        customerId,
        worksaleLineId,
        navigateBack,
        getLineKitSummary,
        showDetailOnClick,
        onFetchComponentLines,
        onCreateComponentLine,
        onUpdateComponentGridLine,
        onDeleteComponent,
        onCalculateComponentFastLineDetails,
        onCalculateComponentGridLineDetails,
        onFetchTotalComponentLinesCount,
        changeConfirmationDialog,
        changeValidationDialog,
        confirmOnDelete = false
    } = props;

    const { setActionBar } = React.useContext(ActionBarContext);
    const workSaleFastEntryRef = React.useRef<IFastEntryHandle>(null);

    const [kitSummary, setKitSummary] = React.useState<IWorksalelineKitSummaryFacade>({});
    const [worksaleLineSummaryLoading, setWorksaleLineSummaryLoading] = React.useState<boolean>(false);
    const [isLineEditing, setIsLineEditing] = React.useState<boolean>(false);

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [BACK_BUTTON],
                centerIcons: [],
                rightIcons: []
            });
        },
        []
    );

    React.useEffect(
        () => {
            if (!worksaleLineId && navigateBack) {
                navigateBack();
            }

            setWorksaleLineSummaryLoading(true);
            fetchWorksaleLineKitSummary();
        },
        []
    );

    const fetchLines = React.useCallback(
        (BatchPage: number, BatchSize: number) => onFetchComponentLines(worksaleLineId, BatchPage, BatchSize)
            .then((response) => response?.Components)
            .catch((warn) => console.warn(warn)) as Promise<any>,
        [worksaleLineId]
    );

    const totalCount = React.useCallback(
        () => onFetchTotalComponentLinesCount(worksaleLineId),
        [worksaleLineId]
    );

    const updateComponentGLine = async (row: IWIPComponentFacade, item?: IWIPComponentFacade): Promise<IWIPComponentFacade> => {
        const response = await onUpdateComponentGridLine(worksaleLineId, row.ComponentId, { ...row, ...item });

        fetchWorksaleLineKitSummary();

        return response.ComponentLine ? response.ComponentLine : {};
    };

    const insert = React.useCallback(
        (line) => line,
        []
    );

    const linesDataSource = React.useMemo(
        () => createDataSource(
            'ComponentId',
            { insert, fetch: fetchLines, update: updateComponentGLine, totalCount },
        ),
        [fetchLines, totalCount]
    );

    const fetchWorksaleLineKitSummary = () => {
        getLineKitSummary(worksaleLineId)
            .then((response) => {
                if (response.kitSummary) {
                    setKitSummary(response.kitSummary.inlineObject);
                }
            })
            .catch((err) => console.warn(err))
            .finally(() => {
                setWorksaleLineSummaryLoading(false);
            });
    };

    const createComponentLine = async (productId: number, requiredQuantity: number): Promise<IWIPComponentFacade> => {
        const response = await onCreateComponentLine(worksaleLineId, productId, requiredQuantity);

        return response.ComponentLine ? response.ComponentLine : {};
    };

    const handleFastEntryOnApply = async (query: ICreateFastLineFacade) => {
        try {
            const response = await createComponentLine(query.ProductId, query.OrderedQuantity);
            await linesDataSource.store().insert(response);
            await linesDataSource.reload();
            workSaleFastEntryRef.current.resetToDefault();
            fetchWorksaleLineKitSummary();
        } catch (err) {
            console.warn(err);
        }
    };

    const handleFastEntryFieldChanged = async (line: IWorksaleGridLineDetailFacade): Promise<IWorksaleFastLineDetailsResponse> => {
        const response = await onCalculateComponentFastLineDetails(worksaleLineId, line.ProductId, line.OrderedQuantity);

        return {
            FastLineDetails: response.ComponentDetails ? {
                ProductId: response.ComponentDetails.inlineObject.ProductId,
                OrderedQuantity: response.ComponentDetails.inlineObject.RequiredQuantity
            } : null
        };
    };

    const handleComponentLineFieldChanged = async (WorksalelineId: number, ComponentId: number, line: ICalculateComponentDetailsFacade, changedField: keyof ICalculateComponentDetailsFacade): Promise<ICalculateComponentDetailsFacade> => {
        const response = await onCalculateComponentGridLineDetails(WorksalelineId, ComponentId, line, changedField);

        return response.ComponentDetails ? response.ComponentDetails.inlineObject : null;
    };

    const deleteAndValidateComponentLine = async (componentId: number) => {
        if (linesDataSource.items().length === 1) {
            if (changeValidationDialog) {
                changeValidationDialog({
                    messageBoxOpen: true,
                    icon: 'ReportProblem',
                    color: MessageBoxColor.Alert,
                    title: 'Cannot delete component',
                    form: null,
                    message: 'The selected component line cannot be deleted. \n A kit item must have at least one component item.',
                    actions: [
                        {
                            name: 'go-back',
                            label: 'Go back',
                            isDefault: false,
                            callback: () => undefined
                        }
                    ]
                });
            }

        } else {
            if (confirmOnDelete) {
                if (changeConfirmationDialog) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Delete Component Line',
                        message: 'Are you sure you want to delete?',
                        okLabel: 'Delete',
                        onCancel: () => undefined,
                        onOk: async () => {
                            await onDeleteComponent(componentId);
                            await linesDataSource.reload();
                            fetchWorksaleLineKitSummary();
                        }
                    });
                }
            } else {
                await onDeleteComponent(componentId);
                await linesDataSource.reload();
                fetchWorksaleLineKitSummary();
            }
        }
    };

    const onEditChange = React.useCallback(
        (flag) => {
            setIsLineEditing(flag);
        },
        []);

    return (
        <div className={classes.kitComponentsContainer}>
            <div className={classes.KitComponents}>
                <WorksaleFastEntry
                    innerRef={workSaleFastEntryRef}
                    hidePrice
                    CustomerId={customerId}
                    onFastEntryFieldChanged={handleFastEntryFieldChanged}
                    onApply={handleFastEntryOnApply}
                    disableProduct={isLineEditing}
                />
                <KitComponentsLines
                    className={classes.grid}
                    worksaleLineId={worksaleLineId}
                    onEditChange={onEditChange}
                    componentLinesDataSource={linesDataSource}
                    disableSuppliedQuantityEditing={kitSummary.KittingQuantity === 0}
                    onComponentLineFieldChanged={handleComponentLineFieldChanged}
                    onDelete={deleteAndValidateComponentLine}
                    showDetailOnClick={showDetailOnClick}
                />
            </div>
            <SummaryTable className={classes.kitSummaryTable} kitProductDetailsSummaryLoading={worksaleLineSummaryLoading}
                selectedKitProduct={kitSummary}
                data={
                    KitComponentsDetailSummarySchema
                }
            />
        </div>
    );
};

export default withStyles(inlineStyles, { index: 1 })(KitComponents);
