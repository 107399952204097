import { IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
  createActions, asyncInitialState, asyncSelectors,
  IDataAction, asyncOnRequest, asyncOnError, asyncOnSuccess,
  IExtendedState
} from '../utils';

export interface IBinLocationsData {
  selected: any;
  summary: any;
  deleteObj: any;
  Actions: any;
  lineColumns: IColDef[];
  binLocations: any[];
  WarehouseProduct: string;
  openBinDetailModal: boolean;
  newLineRenderer: string;
}

export interface IBinLocationsState extends IExtendedState<IBinLocationsData> {
  fetchBinLocationsSummary_loading?: boolean;
  saveBinlocation_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    changeModalVisibility: (data) => (data),
    openBinModal: (data) => (data),
    clearLines: () => null,
    asyncs: {
      fetchBinLocations: (data) => (data),
      fetchBinLocationsSummary: (data) => (data),
      saveBinlocation: (data) => (data),
    }
  },
  'binLoc');

const initialState = asyncInitialState<IBinLocationsData>({
  selected: null,
  deleteObj: null,
  Actions: {
  },
  lineColumns: [
    { headerName: 'Bin', field: 'Bin', minWidth: 150, suppressSorting: true, },
    { headerName: 'Bin type', field: 'BinType', minWidth: 150, suppressSorting: true },
    { headerName: 'Available', field: 'QtyAvailable', minWidth: 150, suppressSorting: true, type: 'numericColumn' },
    { headerName: 'Quantity', field: 'QtyAllocated', minWidth: 150, suppressSorting: true, type: 'numericColumn' },
  ],
  binLocations: [],
  newLineRenderer: 'BinLocations',
  WarehouseProduct: null,
  openBinDetailModal: false,
  summary: {}
});

export default (state: IBinLocationsState = initialState, action: IDataAction): IBinLocationsState => {
  switch (action.type) {
    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };
    case types.openBinModal:

      return {
        ...state,
        data: {
          ...state.data,
          openBinDetailModal: true,
          selected: action.data
        }
      };

    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.fetchBinLocations:
    case types.saveBinlocation:
    case types.fetchBinLocationsSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetchBinLocations.success:
      return asyncOnSuccess(state, action, (data, successAction) => {
        const linesData = successAction.payload && successAction.payload.records;

        return {
          ...data,
          binLocations: linesData
        };
      });
    case types.saga.saveBinlocation.success:
      return asyncOnSuccess(state, action, (data) => {

        return {
          ...data,
          openBinDetailModal: false
        };
      });
    case types.saga.fetchBinLocationsSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => {

        return {
          ...data,
          summary: successAction.payload.values
        };
      });
    case types.saga.fetchBinLocations.failure:
    case types.saga.saveBinlocation.failure:
    case types.saga.fetchBinLocationsSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations,
  {
    binLocations: (data) => data.binLocations,
    selected: (data) => data.selected,
    summary: (data) => data.summary,
  }
);

const syncSelector = {
  openBinDetailModal: (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations.data.openBinDetailModal,
  lineColumns: (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations.data.lineColumns,
  newLineRenderer: (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations.data.newLineRenderer,
  fetchBinLocationsSummary_loading: (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations.fetchBinLocationsSummary_loading,
  saveBinlocation_loading: (state: { pickSlips: { binLocations: IBinLocationsState } }) => state.pickSlips.binLocations.saveBinlocation_loading,
};

export const selectors = { ...asyncSelector, ...syncSelector };
