import React from 'react';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from 'components/common/DataGridDevEx/DataGrid.constants';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, HeaderFilter, Editing, Button, RowDragging, Lookup } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core';
import styles from './SortGrid.styles';
import { ISortGridProps } from './SortGrid.properties';
import { useDeleteEMTemplateSortField, useReorderEMTemplateSortFields, useRetrieveEMTemplateSortFields, useSetEMTemplateSortFieldsData, useUpdateEMTemplateSortField } from 'api/extractMaintenance/extractMaintenanceTemplates';
import { useEMContextSelector } from 'components/ExtractMaintenance/ExtractMaintenance.context';
import { ACTION_COLUMN_WIDTH, DRAG_COLUMN_WIDTH, FIELD_COLUMN_WIDTH } from '../Fields.constants';
import { ILookupOption } from 'api/customType';

const SortGrid = (props: ISortGridProps) => {
    const { classes } = props;
    const params = new URLSearchParams(location.search);
    const templateId = Number(params.get('TemplateId')) || useEMContextSelector<'TemplateId'>((state) => state.TemplateId);
    const { data: fetchSortFieldsData } = useRetrieveEMTemplateSortFields(templateId);
    const { mutateAsync: updateSortFieldMutate } = useUpdateEMTemplateSortField();
    const { mutateAsync: deleteSortFieldMutate } = useDeleteEMTemplateSortField();
    const { mutateAsync: reorderSortFieldsMutate } = useReorderEMTemplateSortFields();
    const setEMTemplateSortFieldsData = useSetEMTemplateSortFieldsData();
    const inEditMode = useEMContextSelector<'isFieldsInEdit'>((state) => state.isFieldsInEdit);
    const dataGridInnerRef = React.useRef<DataGrid>(null) as React.MutableRefObject<DataGrid | null>;

    const sortingOptions: ILookupOption[] = [
        {
            Code: 'DESC',
            Display: 'Descending',
            Value: 'DESC',
        },
        {
            Code: 'ASCE',
            Display: 'Ascending',
            Value: 'ASCE'
        }
    ];
    const onDelete = React.useCallback(
        async (e: any): Promise<void> => {
            if (e?.row?.key) {
                const data = e?.row?.data;
                const response = await deleteSortFieldMutate({
                    TemplateId: templateId,
                    ExtractId: data.ExtractId,
                    urlQueryParams: {
                        SortProperty: data.SortProperty
                    }
                });
                setEMTemplateSortFieldsData(templateId, response);
            }
        },
        [templateId]
    );

    const onUpdate = React.useCallback(
        async (e: any): Promise<void> => {
            if (e?.row?.key) {
                const data = e?.row?.data;
                if (data.SortDirection !== fetchSortFieldsData?.SortFields?.find((f) => f.SortProperty === data.SortProperty).SortDirection) {
                    const response = await updateSortFieldMutate({
                        TemplateId: templateId,
                        ExtractId: data.ExtractId,
                        urlQueryParams: {
                            SortDirection: data.SortDirection,
                            SortProperty: data.SortProperty,
                        }
                    });
                    setEMTemplateSortFieldsData(templateId, response);
                } else {
                    await dataGridInnerRef.current.instance.saveEditData();
                }
            }
        },
        [templateId, fetchSortFieldsData]
    );

    const onReorder = React.useCallback(
        async (e: any): Promise<void> => {
            const visibleRows = e.component.getVisibleRows();
            const sortFields = [...fetchSortFieldsData?.SortFields];
            const toIndex = sortFields.indexOf(visibleRows[e.toIndex].data);
            const fromIndex = sortFields.indexOf(e.itemData);

            sortFields.splice(fromIndex, 1);
            sortFields.splice(toIndex, 0, e.itemData);

            const response = await reorderSortFieldsMutate({
                TemplateId: templateId,
                ExtractId: e.itemData.ExtractId,
                SortFields: sortFields
            });

            setEMTemplateSortFieldsData(Number(templateId), response);
        },
        [templateId, fetchSortFieldsData]
    );

    return (
        <DataGrid
            className={classes.SortGrid}
            dataSource={fetchSortFieldsData?.SortFields}
            ref={dataGridInnerRef}
            showBorders={true}
            renderAsync={true}
            remoteOperations={true}
            allowColumnResizing={true}
            columnResizingMode={'nextColumn'}
            hoverStateEnabled={true}
            noDataText=''
            columnAutoWidth={true}
            disabled={!inEditMode}
        >
            <Editing
                refreshMode={'reshape'}
                mode='row'
                allowAdding={false}
                allowDeleting={true}
                allowUpdating={true}
            />
            <HeaderFilter visible />
            <Column
                type='drag'
                width={DRAG_COLUMN_WIDTH}
                fixed={true}
            />
            <Column
                dataField={'SortPropertyDisplay'}
                caption={'Sort by'}
                allowHeaderFiltering={false}
                width={FIELD_COLUMN_WIDTH}
                allowEditing={false}
                allowSorting={false}
            >
            </Column>
            <Column
                dataField={'SortDirection'}
                caption={'Sort order'}
                allowHeaderFiltering={false}
                minWidth={FIELD_COLUMN_WIDTH}
                allowSorting={false}
                allowEditing={true}
            >
                <Lookup dataSource={sortingOptions} displayExpr='Display' valueExpr='Value' />
            </Column>
            <Column type={'buttons'} width={ACTION_COLUMN_WIDTH} allowResizing={false} fixed={true}>
                <Button name={'save'} icon={'save'} onClick={onUpdate} />
                <Button name={'edit'} icon={'edit'} />
                <Button name={'delete'} icon={'trash'} onClick={onDelete} />
                <Button name={'cancel'} icon={'undo'} />
            </Column>
            <RowDragging
                allowReordering={true}
                onReorder={onReorder}
                showDragIcons={true}
            />
            <GroupPanel visible={false} />
            <SearchPanel visible={false} />
            <Scrolling mode={'infinite'} />
            <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        </DataGrid>
    );
};

export default withStyles(styles, { index: 1 })(SortGrid);
