import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset, change, getFormSyncErrors } from 'redux-form';
import DeliveryDetails from './DeliveryDetails';
import { IApplicationState } from 'ducks/reducers';
import { selectors as formSelectors } from 'ducks/form';
import { IDeliveryDetailsHandle, IDeliveryDetailsProps } from './DeliveryDetails.properties';
import { actions as uiActions } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';

const FORM_NAME = 'DeliveryDetails';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
  selectedForm: formSelectors.selected(state),
  operationMode: operationSelectors.operationMode(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeFieldValue: (field, value) => change(FORM_NAME, field, value)
  },
  dispatch);

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
  IDeliveryDetailsProps, ''>>) => {
  return (<DeliveryDetails {...props} />);
});

export default React.forwardRef(
  (props: Readonly<Omit<IDeliveryDetailsProps, 'operationMode'>>, ref: React.Ref<IDeliveryDetailsHandle>) => {

    return <ConnectedComponent {...props} forwardedRef={ref} />;
  });
