import * as React from 'react';
import FormView from '../../FormView';
import { withRouter } from 'react-router';
import { IEventsProperties } from '../interfaces';

class Events extends React.PureComponent<IEventsProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-quote-enquiry/notepad':
          onInitialLoad('Notepad');
          break;
        default:
          onInitialLoad('Notepad');
      }
    }
  }
  getInitialValues = () => {
    const { selectedTab, selectedQuote, location } = this.props;
    const params = (new URLSearchParams(location.search));
    const Quote = params.get('Quote') || '';
    switch (selectedTab) {
      case 'Notepad':
        return { initialValues: { params: { DocumentNumber: (selectedQuote && selectedQuote.Quote) || Quote } }, valuesSchema: {} };
      default: return null;
    }
  }
  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;
    const initials = this.getInitialValues();
    const initialValues = initials ? initials.initialValues : null;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Events);
