import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/index';
import SupplierProductSearchLookUpDrawer from 'components/common/SearchLookUpDrawer/Containers/SupplierProduct';
import SummaryPanel from './SummaryPanel';
import { shallowCompare } from 'utils/utils';
import { withRouter } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import styles from './SupplierProductEnquiry.scss';
import options from './OptionItems.json';
import { OPERATIONS } from 'utils/operations';
import { Route } from 'react-router';
import ProductPriceSchedule from './ProductPriceSchedule';
import ProductLinks from './ProductLinks';
import Attachments from './Attachments';
import Diary from './Diary';
import Notepad from './NotePad';
import { ISupplierProductEnquiryProps } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  mainModuleSection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class SupplierProductEnquiryView extends React.Component<ISupplierProductEnquiryProps> {

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: any): void {
    const { operationMode, selectedTab,
      // selectedInvoice, invoiceSummary
    } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('supplier-product-enquiry', selectedTab);
    }
  }

  handleModuleChange = (selectedTab: any) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'supplier-product-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any; fetchChildRecords?(): void } } => {
    const context = 'supplier-product-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProductId = params.get('SupplierProductId') || '';
    const DiaryId = params.get('DiaryId') || '';
    const LoadNumber = params.get('LoadNumber') || '';
    const Supplier = params.get('Supplier') || '';
    const SupplierProduct = params.get('SupplierProduct') || '';

    return ({
      ProductPriceSchedule: { pathname: `/${context}/product-price-schedule`, search: `?SupplierProductId=${SupplierProductId}`, component: ProductPriceSchedule },
      ProductLinks: { pathname: `/${context}/product-links`, search: `?SupplierProductId=${SupplierProductId}`, component: ProductLinks },
      SalesDiary: { pathname: `/${context}/diary`, search: `?SupplierProductId=${SupplierProductId}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/diary/diary-details`, search: `?SupplierProductId=${SupplierProductId}&DiaryId=${DiaryId}`, component: Diary },
      Notepad: { pathname: `/${context}/notepad`, search: `?SupplierProductId=${SupplierProductId}`, component: Notepad },
      ProductPriceScheduleDetails: { pathname: `/${context}/product-price-schedule/details`, search: `?SupplierProductId=${SupplierProductId}&LoadNumber=${LoadNumber}&Supplier=${Supplier}&SupplierProduct=${SupplierProduct}`, component: ProductPriceSchedule, fetchChildRecords: this.fetchProductPriceScheduleDetails },
      ProductPriceScheduleSellPrices: { pathname: `/${context}/product-price-schedule/sell-prices`, search: `?SupplierProductId=${SupplierProductId}&LoadNumber=${LoadNumber}&Supplier=${Supplier}&SupplierProduct=${SupplierProduct}`, component: ProductPriceSchedule, fetchChildRecords: this.fetchProductPriceScheduleSellPrices },
      ProductPriceScheduleSettings: { pathname: `/${context}/product-price-schedule/settings`, search: `?SupplierProductId=${SupplierProductId}&LoadNumber=${LoadNumber}&Supplier=${Supplier}&SupplierProduct=${SupplierProduct}`, component: ProductPriceSchedule, fetchChildRecords: this.fetchProductPriceScheduleSettings },
      ProductPriceScheduleComments: { pathname: `/${context}/product-price-schedule/comments`, search: `?SupplierProductId=${SupplierProductId}&LoadNumber=${LoadNumber}&Supplier=${Supplier}&SupplierProduct=${SupplierProduct}`, component: ProductPriceSchedule, fetchChildRecords: this.fetchProductPriceScheduleComments },
      Documents: { pathname: `/${context}/attachments/documents`, search: `?SupplierProductId=${SupplierProductId}`, component: Attachments },
      Links: { pathname: `/${context}/attachments/links`, search: `?SupplierProductId=${SupplierProductId}`, component: Attachments },
      Images: { pathname: `/${context}/attachments/images`, search: `?SupplierProductId=${SupplierProductId}`, component: Attachments },
      null: { pathname: `/${context}/`, search: null, component: ProductPriceSchedule, },
    });
  }

  handleProductChange = () => {
    const { selectedTab } = this.props;
    const { getSummary, selectedProduct } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProductId = (selectedProduct && selectedProduct.SupplierProductId) || params.get('SupplierProductId') || '';
    this.fetchChildRecords(selectedTab);
    if (getSummary && SupplierProductId) getSummary(SupplierProductId);
  }

  fetchChildRecords = (selectedTab: any) => {
    const routeData = this.getRoute()[selectedTab];
    if (routeData && routeData.fetchChildRecords) {
      routeData.fetchChildRecords();
    }
  }

  fetchProductPriceScheduleDetails = () => {
    const { selectedProduct, selectedPriceSchedule } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProduct = (selectedProduct && selectedProduct.SupplierProduct) || params.get('SupplierProduct');
    const LoadNumber = (selectedPriceSchedule && selectedPriceSchedule.LoadNumber) || params.get('LoadNumber');
    const Supplier = (selectedPriceSchedule && selectedProduct.Supplier) || params.get('Supplier');
    if (SupplierProduct && LoadNumber && Supplier) {
      this.props.fetchProductPriceScheduleDetails({ SupplierProduct, LoadNumber, Supplier });
    }
  }

  fetchProductPriceScheduleSettings = () => {
    const { selectedProduct, selectedPriceSchedule } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProduct = (selectedProduct && selectedProduct.SupplierProduct) || params.get('SupplierProduct');
    const LoadNumber = (selectedPriceSchedule && selectedPriceSchedule.LoadNumber) || params.get('LoadNumber');
    const Supplier = (selectedPriceSchedule && selectedProduct.Supplier) || params.get('Supplier');
    if (SupplierProduct && LoadNumber && Supplier) {
      this.props.fetchProductPriceScheduleSettings({ SupplierProduct, LoadNumber, Supplier });
    }
  }

  fetchProductPriceScheduleSellPrices = () => {
    const { selectedProduct, selectedPriceSchedule } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProduct = (selectedProduct && selectedProduct.SupplierProduct) || params.get('SupplierProduct');
    const LoadNumber = (selectedPriceSchedule && selectedPriceSchedule.LoadNumber) || params.get('LoadNumber');
    const Supplier = (selectedPriceSchedule && selectedProduct.Supplier) || params.get('Supplier');
    if (SupplierProduct && LoadNumber && Supplier) {
      this.props.fetchProductPriceScheduleSellPrices({ SupplierProduct, LoadNumber, Supplier });
    }
  }

  fetchProductPriceScheduleComments = () => {
    const { selectedProduct, selectedPriceSchedule } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SupplierProduct = (selectedProduct && selectedProduct.SupplierProduct) || params.get('SupplierProduct');
    const LoadNumber = (selectedPriceSchedule && selectedPriceSchedule.LoadNumber) || params.get('LoadNumber');
    const Supplier = (selectedPriceSchedule && selectedProduct.Supplier) || params.get('Supplier');
    if (SupplierProduct && LoadNumber && Supplier) {
      this.props.fetchProductPriceScheduleComments({ SupplierProduct, LoadNumber, Supplier });
    }
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'ProductPriceSchedule') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('ProductPriceSchedule');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getValuesSchema = () => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      default:
        return null;
    }
  }

  getOptionMenuItems = () => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'ProductPriceScheduleDetails':
      case 'ProductPriceScheduleSellPrices':
      case 'ProductPriceScheduleSettings':
      case 'ProductPriceScheduleComments': return options.ProductPriceScheduleDetails;
      default: return options.SupplierProductEnquiry;
    }
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const schema = this.getValuesSchema();
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.moduleOuter}>
        <div className={styles.moduleContainer}>
          <SupplierProductSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.handleProductChange
            ]}
          />
          <div
            style={inlineStyles.mainModuleSection}
            className={styles.moduleSection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Supplier Product Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} valuesSchema={schema} />;
                }}
              />
            ))}

          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuItems()}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'supplier-product-enquiry'} />
      </div>
    );
  }
}

export default withRouter(SupplierProductEnquiryView);
