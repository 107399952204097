import { fetchGet, objectify, IObjectified } from '../utils';
import {
  ISupplierInvoiceEnquiryDetailFacade,
  ISupplierInvoiceEnquirySummaryFacade,
  ISupplierInvoiceEnquiryCommentFacade,
} from 'api/swaggerTypes';

export async function fetch(query: { DocumentNumber: number }): Promise<IObjectified<ISupplierInvoiceEnquiryDetailFacade>> {
  const { DocumentNumber } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/Details/Invoices/${DocumentNumber}`, {}, 'Inline')
    .then((result) => {
      if (!result) {
        return null;
      }

      const obj = objectify(result.SupplierInvoiceEnquiryDetail, null, 'Inline');

      return { inlineObject: { ...obj }, schema: result.SupplierInvoiceEnquiryDetail };
    });
}

export async function fetchSummary(query: { DocumentNumber: number }): Promise<ISupplierInvoiceEnquirySummaryFacade> {
  const { DocumentNumber } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/Summary/Invoices/${DocumentNumber}`, {}, 'Inline')
    .then((result) => result ? objectify(result.SupplierInvoiceEnquirySummary, null, 'Inline') : null);
}

export async function fetchComment(query: { DocumentNumber: number }): Promise<ISupplierInvoiceEnquiryCommentFacade> {
  const { DocumentNumber } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/Comments/Invoices/${DocumentNumber}`, {}, 'Inline')
    .then((result) => result ? objectify(result.SupplierInvoiceEnquiryComment, null, 'Inline') : null);
}
