import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { withRouter } from 'react-router-dom';
import { IQuoteDetailsProperties, IQuoteDetailsState } from '../interfaces';

class QuoteDetails extends React.Component<IQuoteDetailsProperties, IQuoteDetailsState> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-quote-enquiry/quote-details':
          onInitialLoad('QuoteDetails');
          break;
        case '/customer-quote-enquiry/quote-details-summary':
          onInitialLoad('QuoteSummary');
          break;
        case '/customer-quote-enquiry/quote-details-comments':
          onInitialLoad('Comments');
          break;
        default:
          onInitialLoad('QuoteDetails');
      }
    }
  }

  getInitialValues = () => {
    const { selectedTab, quoteDetailsSchema, quoteSummarySchema, quoteSummary, quoteDetails } = this.props;
    switch (selectedTab) {
      case 'QuoteDetails':
        return { initialValues: quoteDetails, valuesSchema: quoteDetailsSchema };
      case 'Comments':
        return { initialValues: quoteDetails, valuesSchema: {} };
      case 'QuoteSummary':
        return { initialValues: quoteSummary, valuesSchema: quoteSummarySchema };
      default: return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, notifications } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={notifications}
          entity='CustomerQuoteEnquiry'
        />
        <FormView
          isLoading={isLoading}
          formName='CustomerQuoteEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          valuesSchema={valuesSchema}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(QuoteDetails);
