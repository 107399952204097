import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    ButtonLabelStyle: {
        cursor: 'pointer',
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 500,
        alignItems: 'flex-start',
        lineHeight: '26.5px',
        pointerEvents: 'none'
    },
    circularLoading: {
        color: '#006633',
        margin: '0px 5px'
    }
});
