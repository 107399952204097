import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function UserField(props: Readonly<SvgIconProps>): JSX.Element {
  return (
    <SvgIcon height='24px' viewBox='0 -960 960 960' width='24px' fill='#000' {...props}>
      <path d='M80-200v-80h400v80H80Zm0-200v-80h200v80H80Zm0-200v-80h200v80H80Zm744 400L670-354q-24 17-52.5 25.5T560-320q-83 0-141.5-58.5T360-520q0-83 58.5-141.5T560-720q83 0 141.5 58.5T760-520q0 29-8.5 57.5T726-410l154 154-56 56ZM560-400q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z' />
    </SvgIcon>);
}

export default React.memo(UserField);
