import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  fieldsContainer: {
    'display': 'flex',
    'gap': '15px',
    'padding': '15px',
    'height': 'calc(100% - 140px)',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      flexDirection: 'column',
      overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '3px',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      borderRadius: '3px',
    },
  },
  leftContainer: {
    'width': '30%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      width: '100%'
    }
  },
  rightContainer: {
    'display': 'grid',
    'grid-template-columns': '99%',
    'grid-template-rows': '33% 33% 33%',
    'height': '100%',
    'width': '69%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      width: '100%',
      height: 'unset',
      display: 'block'
    }
  },
  paper: {
    padding: '15px',
    marginBottom: 16,
  },
  paperLeft: {
    'padding': '15px',
    'height': '38%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      height: 'unset',
    }
  },
  fieldsLeftContainer: {
    'padding': '15px',
    'padding-top': '5px',
    'height': '45%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      height: 'unset',
    }
  },
  paperLeftContainer: {
    'margin-bottom': '15px',
    'padding-bottom': '15px',
    'height': '90.5%',
    'display': 'flex',
    'flex-direction': 'column',
    'gap': '3%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      height: 'unset',
    }
  },
  actionBtn: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  containerLabel: {
    fontSize: 14,
    fontWeight: 200,
    color: '#777777',
    height: 25
  },
  buttonsContainer: {
    'display': 'flex',
    'columnGap': 15,
    'height': '10%',
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      paddingBottom: 15
    }
  },
  outputFieldsContainer: {
    padding: '15px',
  },
  action: {
    'background': '#F5F5F5',
    'max-height': '40px',
    'color': 'black',
    'borderRadius': '4px',
    'textTransform': 'none',
    'padding': '8px 16px',
    'width': '100%',
    '&:focus': {
      background: '#F47920',
      border: '1px solid #F47920',
      color: 'white'
    },
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      width: '33%',
      padding: 0,
    }
  },
  active: {
    'max-height': '40px',
    'background': '#F47920',
    'color': 'white',
    'borderRadius': '4px',
    'textTransform': 'none',
    'padding': '8px 16px',
    'width': '100%',
    '&:hover': {
      background: '#F47920',
    },
    '@media screen and (max-width: 1100px), (max-height: 1000px)': {
      width: '33%',
      padding: 0,
    }
  },
});
