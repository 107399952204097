import {
  createActions, asyncInitialState, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'productionOrders');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Production order', field: 'ProductionOrderId', minWidth: 150 },
    { headerName: 'Line', field: 'Line', type: 'numericColumn', suppressSorting: true, minWidth: 100, },
    { headerName: 'Started', field: 'Started', suppressSorting: true, minWidth: 120, type: 'numericColumn', },
    { headerName: 'Created', field: 'Created', suppressSorting: true, minWidth: 120, type: 'numericColumn', },
    { headerName: 'Earliest due', field: 'EarliestDue', suppressSorting: true, minWidth: 120, type: 'numericColumn', },
    { headerName: 'Build quantity', field: 'BuildQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Primary item', field: 'PrimaryDisplay', minWidth: 120, suppressSorting: true, },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true, },
    { headerName: 'Responsible person', field: 'ResposiblePerson', minWidth: 120, suppressSorting: true, },
    { headerName: 'Name', field: 'ResposiblePersonDescription', minWidth: 350, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 350, }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.productionOrders,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.productionOrders.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.productionOrders.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.productionOrders.data.Columns
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)