import { objectify, Inline, fetchGet, fetchPost, AsObjectified, objectifyAddress } from '../utils';
import { Payload, useFetch, useFetchMutation } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { IBatchInfo } from 'components/common/DataGridDevEx/DataGrid.properties';
import { toObjectified } from 'api/baseApi';
import { IPurchaseOrderDetailsFacade, IPurchaseOrderDetailsResponse, IPurchaseOrderLineSummaryResponse } from 'api/swaggerTypes';

const STALETIME = 120000;
export type IObjectifiedPickSlipSummaryResponse = AsObjectified<IPurchaseOrderLineSummaryResponse, 'PurchaseOrderLineSummary'>;

export interface IPurchaseOrdersDetailsResponse {
    values: {
        SupplierAddress: {
            addressee: string;
            addressLine1: string;
            addressLine2: string;
            city: string;
            state: string;
            postalCode: string;
            country: string;
        };
        PurchaseOrderDetails?: IPurchaseOrderDetailsFacade;
    };
    schema: IPurchaseOrderDetailsFacade;
}

export const search = (query) => {
    const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;
    const respType = 'Inline';

    return fetchPost('/PurchaseOrders/Search', { SearchText, Sort, BatchPage, BatchSize }, null, respType)
        .then((response) => ({
            records: response.PurchaseOrders.map((val) => ({ inlineObject: objectify(val), schema: val })),
            currPage: response.BatchInformation && response.BatchInformation.BatchPage,
            nextPage: response.BatchInformation && !response.BatchInformation.EndOfData,
            prevPage: response.BatchInformation && response.BatchInformation.BatchPage > 1
        }));
};

export const searchById = (PurchaseOrder) => {
    if (PurchaseOrder) {
        const apiCall = fetchGet(`/PurchaseOrders/${PurchaseOrder}/Details`, null, 'Inline')
            .then((response) => ({
                records: [{ inlineObject: objectify(response.PurchaseOrders), schema: response.PurchaseOrders }],
                currPage: 1,
                nextPage: false,
                prevPage: false
            }));

        return apiCall;
    }

    return [];
};

const purchaseOrdersLinesResponseParser = (response): any => {
    const { PurchaseOrdersLines } = response;

    return {
        ...response,
        PurchaseOrdersLines: PurchaseOrdersLines.map((o) => objectify(o as Inline<any>))
    };
};

export type IRetrievePurchaseOrdersLinesPayload = Payload<{ PurchaseOrder: number }, IBatchInfo>;
export const useRetrievePurchaseOrdersLines = () =>
    useFetchMutation<IRetrievePurchaseOrdersLinesPayload, any>
        (APIRoutes.purchaseOrders.lines.retrieve, null, { inline: true, responseParser: purchaseOrdersLinesResponseParser });

const purchaseOrdersSummaryResponseParser = (response): IObjectifiedPickSlipSummaryResponse => {
    const { PurchaseOrderSummary } = response;

    return {
        ...response,
        PurchaseOrderSummary: toObjectified(PurchaseOrderSummary as Inline<IPurchaseOrderLineSummaryResponse>)
    };
};
export const useRetrievePurchaseOrdersSummary = (PurchaseOrder) =>
    useFetch<any>(
        {
            url: APIRoutes.purchaseOrders.summary.retrieve,
            urlParams: { PurchaseOrder },
            queryConfig: { enabled: Boolean(PurchaseOrder), staleTime: STALETIME },
            options: { inline: true, responseParser: purchaseOrdersSummaryResponseParser }
        }
    );

const purchaseOrderDetailResponseParser = (response: IPurchaseOrderDetailsResponse): IPurchaseOrdersDetailsResponse => {
    const purchaseOrderDetails = objectify(response.PurchaseOrderDetails as Inline<IPurchaseOrderDetailsResponse>);

    return {
        values: {
            ...purchaseOrderDetails,
            SupplierAddress: objectifyAddress(purchaseOrderDetails, 'Supplier')

        },
        schema: response.PurchaseOrderDetails
    };
};

export const useRetrievePurchaseOrderDetails = (PurchaseOrder: number) =>

    useFetch<IPurchaseOrdersDetailsResponse>(
        {
            url: APIRoutes.purchaseOrders.details.retrieve,
            urlParams: { PurchaseOrder },
            queryConfig: { enabled: Boolean(PurchaseOrder) },
            options: { inline: true, responseParser: purchaseOrderDetailResponseParser }
        }
    );
