
import * as React from 'react';
import * as api from 'api/serviceScheduling/serviceChecklists';
import { connect } from 'react-redux';
import ChecklistSummary from './ChecklistSummary';
import { IChecklistSummaryProps } from 'components/ServiceActivityScheduling/interfaces';
import {
    selectors as activitySchedulerSelectors,
} from 'ducks/serviceActivityScheduling/serviceActivities';
import { IApplicationState } from 'ducks/reducers';

type IStateProps = Pick<IChecklistSummaryProps,
    'serviceJobId'>;
const mapStateToProps = (state: IApplicationState): IStateProps => ({
    serviceJobId: activitySchedulerSelectors.serviceJobId(state),
});

export default connect(mapStateToProps, {})((props: Readonly<Omit<
    IChecklistSummaryProps, 'getChecklists'>>) => {
    return (<ChecklistSummary
        {...props}
        getChecklists={api.fetchJobsChecklists}
        onChecklistSave={api.updateChecklist}
    />);
});
