
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as transactionSelectors } from 'ducks/supplierEnquiry/supplierTransactions';
import { selectors as balancesSelectors } from 'ducks/supplierEnquiry/financial/balances';
import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';

import Financial from './Financial';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedSupplier: supplierSelectors.selected(state),
  balanceDetails: balancesSelectors.details(state),
  balanceDetailsSchema: balancesSelectors.schema(state),
  transactionDetails: transactionSelectors.details(state),
  transactionSummary: transactionSelectors.summary(state),
  loadingTransactionSummary: transactionSelectors.loadingSummary(state),
});

export default connect(mapStateToProps, {})(Financial);
