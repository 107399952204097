import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Allocate from './Allocate';
import { getFormValues } from 'redux-form';

import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as allocateSelectors, actions as allocateActions } from 'ducks/cashReceipting/allocate';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isLoading: allocateSelectors.isLoading(state),
  allocate: allocateSelectors.allocate(state),
  formValues: getFormValues('Allocate')(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    getAllocatedCashReceipt: allocateActions.getAllocatedCashReceipt,
    updateAllocatedCashReceipt: allocateActions.updateAllocatedCashReceipt
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Allocate);
