
export const MODULE_TREE = [
    { id: 'OrderDetails', label: 'Order Details', parent: '' },
    { id: 'Comment', label: 'Comment', parent: '' },
    { id: 'OrderLines', label: 'Order Lines', parent: '' },
    { id: 'LineDetails', label: 'Line Details', parent: 'OrderLines' },
    { id: 'LDCommittedPurchaseOrder', label: 'Committed Purchase Orders', parent: 'LineDetails' },
    { id: 'LDCommittedProductionOrder', label: 'Committed Production Orders', parent: 'LineDetails' },
    { id: 'LDCommittedInPutAway', label: 'Committed In Put Away', parent: 'LineDetails' },
    { id: 'SOEBOM', label: 'Bill of Materials', parent: 'LineDetails' },
    { id: 'ReservationDetails', label: 'Reservation Details', parent: 'LineDetails' },
    { id: 'LDReservationSerials', label: 'Reservation Serials', parent: 'LineDetails' },
    { id: 'LDReservationLots', label: 'Reservation Lots', parent: 'LineDetails' },
    { id: 'LineInvoices', label: 'Invoices', parent: 'LineDetails' },
    { id: 'Deliveries', label: 'Deliveries', parent: '' },
    { id: 'DeliveryDetails', label: 'Delivery Details', parent: 'Deliveries' },
    { id: 'CommittedPurchaseOrder', label: 'Committed Purchase Orders', parent: '' },
    { id: 'CommittedProductionOrder', label: 'Committed Production Orders', parent: '' },
    { id: 'CommittedInPutAway', label: 'Committed In Put Away', parent: '' },
    { id: 'SaleDeposits', label: 'Sale Deposits', parent: '' },
    { id: 'DepositDetails', label: 'Deposit Details', parent: 'SaleDeposits' },
    { id: 'PaymentEntryDetails', label: 'Payment Entry Details', parent: 'DepositDetails' },
    { id: 'ComponentDetails', label: 'Component Details', parent: 'SOEBOM' },
    { id: 'CDCommittedPurchaseOrder', label: 'Committed Purchase Orders', parent: 'ComponentDetails' },
    { id: 'CDCommittedProductionOrder', label: 'Committed Production Orders', parent: 'ComponentDetails' },
    { id: 'CDCommittedInPutAway', label: 'Committed In Put Away', parent: 'ComponentDetails' },
    { id: 'CDReservationDetails', label: 'Reservation Details', parent: 'ComponentDetails' },
    { id: 'CDReservationSerials', label: 'Reservation Serials', parent: 'ComponentDetails' },
    { id: 'CDReservationLots', label: 'Reservation Lots', parent: 'ComponentDetails' },
    { id: 'Notepad', label: 'Notepad', parent: '' },
    { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' }
];
