import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearOrderLines: () => null,
  asyncs: {
  }
}, 'orderLine');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'LineDetails',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  orderLineActions: {
  },
  orderLineColumns: [
    { headerName: 'Line', field: 'LineNumber', type: 'numericColumn', minWidth: 100 },
    {
      headerName: 'Product',
      field: 'ProductDisplay',
      suppressSorting: true,
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Product) {
          return {
            ProductId: row.data.Product,
            FieldValue: row.data.ProductDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      },
    },
    { headerName: 'Product Group', field: 'ProductGroup', suppressSorting: true, minWidth: 120 },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'On backorder', field: 'BackorderQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Price', field: 'PriceDisplay', type: 'numericColumn', suppressSorting: true },
    { headerName: 'Discount %', field: 'DiscountDisplay', type: 'numericColumn', suppressSorting: true, },
    { headerName: 'Warehouse', field: 'Warehouse' },
    { headerName: 'Site', field: 'Site', suppressSorting: true },
    { headerName: 'Description', field: 'Description', suppressSorting: true },
  ],
  orderLinesData: [
  ]

});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearOrderLines:
      return {
        ...state,
        data: {
          ...state.data,
          orderLinesData: []
        }
      }
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.orderLine,
  {
    selectedOrderLine: data => data.selected,
    orderLineActions: data => data.orderLineActions,
    orderLinesData: data => data.orderLinesData
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.orderLine.loading,
  gridOptions: state => state.salesOrderEnquiry.orderLine.data.gridOptions,
  orderLineColumns: state => state.salesOrderEnquiry.orderLine.data.orderLineColumns,
  orderLineActions: state => state.salesOrderEnquiry.orderLine.data.orderLineActions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)