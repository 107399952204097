import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError, IExtendedState, SuccessAction, DataAction
} from '../utils';

export interface IPriceCheckDetailsData {
  details: any;
  customerPriceSummary: any;
}

export interface IPriceCheckDetailsState extends IExtendedState<IPriceCheckDetailsData> {

}

export const { types, actions } = createActions(
  {
    asyncs: {
      clearDetails: () => null,
      fetch: (filters) => (filters),
      fetchCustomerSummary: (CustomerId) => (CustomerId)
    }
  },
  'details');

const initialState = asyncInitialState<IPriceCheckDetailsData>({
  details: null,
  customerPriceSummary: null
});

export default (state: IPriceCheckDetailsState = initialState, action: DataAction): IPriceCheckDetailsState => {
  switch (action.type) {
    case types.fetch:
      return asyncOnRequest(state, action);
    case types.clearDetails:
      return {
        ...state,
        data: {...state.data, details: null}
      };
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data: IPriceCheckDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          details: successAction.payload
        };
      });
    case types.saga.fetch.failure:
      return asyncOnError(state, action);
    case types.fetchCustomerSummary:
      return asyncOnRequest(state, action);
    case types.saga.fetchCustomerSummary.success:
      return asyncOnSuccess(state, action, (data: IPriceCheckDetailsData, successAction: SuccessAction) => {
        return {
          ...data,
          customerPriceSummary: successAction.payload
        };
      });
    case types.saga.fetchCustomerSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = {
  ...asyncSelectors(
    (state: { priceCheck: { details: IPriceCheckDetailsState } }) => state.priceCheck.details,
    {
      details: (data: IPriceCheckDetailsData) => data.details,
      customerPriceCode: (data: IPriceCheckDetailsData) => data.customerPriceSummary && data.customerPriceSummary.CustomerPriceCode,
    }),
  isLoading: (state: { priceCheck: { details: IPriceCheckDetailsState } }) => state.priceCheck.details.loading,
};
