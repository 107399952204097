import { call, Effect, takeLatest } from 'redux-saga/effects';

import { IDataAction } from 'ducks/utils';
import { types as binTypes, actions as binActions } from 'ducks/pickSlips/kitComponents';

import { callApi } from '../utils';
import * as api from 'api/pickSlips/kitComponents';

function* fetchKitComponents(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.fetchKitComponents;

    yield callApi(
        call(api.fetchKitComponents, action.data),
        success,
        failure,
        { dialogActionCallEffect: fetchKitComponents }
    );
}

function* fetchKitComponentSummary(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.fetchKitComponentSummary;

    yield callApi(
        call(api.fetchKitComponentSummary, action.data),
        success,
        failure
    );
}

function* fetchKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.fetchKitComponentDetails;

    yield callApi(
        call(api.fetchKitComponentDetails, action.data),
        success,
        failure
    );
}
function* createKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.createKitComponentDetails;

    yield callApi(
        call(api.createKitComponentDetails, action.data),
        success,
        failure
    );
}
function* updateKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.updateKitComponentDetails;

    yield callApi(
        call(api.updateKitComponentDetails, action.data),
        success,
        failure
    );
}
function* saveKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.saveKitComponentDetails;

    yield callApi(
        call(api.saveKitComponentDetails, action.data),
        success,
        failure
    );
}
function* cancelKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.cancelKitComponentDetails;

    yield callApi(
        call(api.cancelKitComponentDetails, action.data),
        success,
        failure
    );
}
function* removeKitComponentDetails(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = binActions.saga.removeKitComponentDetails;

    yield callApi(
        call(api.removeKitComponentDetails, action.data),
        success,
        failure
    );
}

export default function* rootBinLocationsSaga(): IterableIterator<Effect> {
    yield takeLatest(binTypes.fetchKitComponents, fetchKitComponents);
    yield takeLatest(binTypes.fetchKitComponentSummary, fetchKitComponentSummary);
    yield takeLatest(binTypes.fetchKitComponentDetails, fetchKitComponentDetails);
    yield takeLatest(binTypes.createKitComponentDetails, createKitComponentDetails);
    yield takeLatest(binTypes.updateKitComponentDetails, updateKitComponentDetails);
    yield takeLatest(binTypes.saveKitComponentDetails, saveKitComponentDetails);
    yield takeLatest(binTypes.cancelKitComponentDetails, cancelKitComponentDetails);
    yield takeLatest(binTypes.removeKitComponentDetails, removeKitComponentDetails);
}
