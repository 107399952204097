import * as React from 'react';
import LookupField from '@markinson/uicomponents-v2/LookupField';
import { ProductLotLookupProps } from './ProductLotLookup.properties';
import { Column, Paging } from 'devextreme-react/data-grid';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import { lotLookup, lotSearch } from 'api/product/productSearch';
import { DEFAULT_PAGE_SIZE } from '../DataGridDevEx/DataGrid.constants';
const WIDTH_100 = 100;
const WIDTH_200 = 200;

function ProductLotLookup(props: Readonly<ProductLotLookupProps>, ref: React.Ref<ITextFieldHandle>): JSX.Element {
    const { WarehouseEntity, ProductId, Worksale, WorksaleId, LineNumber, ReservationNumber, DocumentId, DocumentType, ...rest } = props;
    const query = {
        WarehouseEntity,
        ProductId,
        Worksale,
        WorksaleId,
        LineNumber,
        ReservationNumber,
        DocumentId,
        DocumentType
    };

    const searchScreenDialogStyle = {
        maxWidth: 600,
        width: 600,
        height: 495,
        maxHeight: 495
    };

    const AvailableQuantityHeaderCellRender = React.useCallback(
        () => WarehouseEntity ? <span>Available<br />{WarehouseEntity}</span> : <span>Available</span>,
        [WarehouseEntity]
    );

    const onLookup = React.useCallback(
        (searchText, exactMatch) => lotLookup({ SearchText: searchText || '', ...query, CheckExactMatch: exactMatch }),
        [query]
    );
    const onSearch = React.useCallback(
        (searchText) => lotSearch({ SearchText: searchText || '', ...query }),
        [query]
    );

    return (
        <LookupField
            innerRef={ref}
            searchScreenDialogStyle={searchScreenDialogStyle}
            valueField={'LotSerial'}
            displayField={'LotSerial'}
            label={'Lot'}
            descriptionField={'Description'}
            placeholder={'Lot'}
            onLookup={onLookup}
            onSearch={onSearch}
            {...rest}
        >
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
            <Column dataField='LotSerial' caption='Lot' allowResizing={true} minWidth={WIDTH_100} fixed={true} />
            <Column dataField='Entered' caption='Entered' allowResizing={true} minWidth={WIDTH_100} />
            <Column dataField='Expiry' caption='Expiry' allowResizing={true} minWidth={WIDTH_100} />
            <Column dataField='AvailableQuantity' headerCellRender={AvailableQuantityHeaderCellRender} minWidth={WIDTH_100} width={WIDTH_100} />
            <Column dataField='Description' caption='Description' allowResizing={true} minWidth={WIDTH_200} width={WIDTH_200} />
        </LookupField >);
}

export default React.forwardRef(ProductLotLookup);
