import { call, Effect, takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/supplierEnquiry/financial/balances';

import { callApi } from '../../utils';
import * as api from 'api/supplierEnquiry/financial/balances';
import { IDataAction } from 'ducks/utils';

function* getSupplierBalancesDetailsDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = actions.saga.getBalancesDetails;
  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(types.getBalancesDetails, getSupplierBalancesDetailsDetails);
}
