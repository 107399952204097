import * as React from 'react';

const draggableListItem = (props: { connectDragSource: any; connectDragPreview: any; children?: any }): React.ReactNode => {
    const { connectDragSource, connectDragPreview, children } = props;

    return (connectDragPreview(
        connectDragSource(
            <div>
                {children}
            </div>))
    );
};

export default draggableListItem;
