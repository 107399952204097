
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as imagesSelectors } from 'ducks/supplierProductEnquiry/attachments/images';

import Attachments from './Attachments';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  images: imagesSelectors.images(state),
  schema: imagesSelectors.schema(state),
  imagesLoading: imagesSelectors.isLoading(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
});

export default connect(mapStateToProps, {})(Attachments);
