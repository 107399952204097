import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncSelectors,
    IExtendedState, IDataAction, SuccessAction, asyncOnError, formatFloatingNumber
} from '../utils';
import { StyledComponentProps } from '@material-ui/core';
import { IEFTPOSModalData } from 'components/common/EFTPOSModal/EFTPOSModal';
import { FormViewField } from 'components/FormView';
interface IEFTPOSModal extends StyledComponentProps {
    open: boolean;
    data: IEFTPOSModalData;
    disableOk?: boolean;
    disableClose?: boolean;
}
interface IPaymentList {
    Payment: string;
    Amount: number;
    Description: string;
}

export interface IPaymentsData {
    statusData: any;
    paymentDetails: any;
    paymentContext: any;
    defaultLine: any;
    paymentLines: any[];
    eftposDialog: IEFTPOSModal;
    selected: any;
    selectedFilters: any;
    filterRow: {
        formName: string;
        parameters: FormViewField[];
        validate(values: any): any;
    };
    gridOptions: IDataGridOptions;
    Columns: IColDef[];
    Actions: any;
    list: IPaymentList[];
    newLineRenderer: string;
}

export interface IPaymentsState extends IExtendedState<IPaymentsData> {
    fetStatus_loading?: boolean;
    applyPayment_loading?: boolean;
    updatePaymentContext_loading?: boolean;
    fetchPaymentDetails_loading?: boolean;
    processPayment_loading?: boolean;
    purchase_loading?: boolean;
    deletePayment_loading?: boolean;
    cancelPayment_loading?: boolean;
    paymentSuccessful?: boolean;
}

export const { types, actions } = createActions(
    {
        setSelected: (row) => ({ row }),
        asyncs: {
            fetStatus: () => null,
            sendOk: () => null,
            sendClose: () => null,
            fetchPaymentDetails: () => null,
            submitSaleConfirmation: (data) => (data),
            applyPayment: (data) => (data),
            purchase: (data) => (data),
            updatePaymentContext: (data) => (data),
            confirmCashReceiptProcess: (data) => (data),
            processPayment: (data) => (data),
            deletePayment: (data) => (data),
            cancelPayment: (data) => (data),
            processCreditNote: (data) => (data),
        },
        showEftposDialog: (options: IEFTPOSModal) => options,

    },
    'paymentsDetail');

const NOT_SELECTED = -1;

const defaultState = {
    paymentSuccessful: false
};
const initialState = {
    ...defaultState,
    ...asyncInitialState<IPaymentsData>({
        selected: NOT_SELECTED,
        newLineRenderer: 'PaymentDetails',
        list: [],
        selectedFilters: {
            CashLocation: '',
            BankingEntity: ''
        },
        filterRow: {
            formName: 'CashReceiptingPaymentDetailsFilters',
            validate: () => ({}),
            parameters: [
                {
                    id: 0,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Banking Entity',
                        name: 'BankingEntity',
                        lookupName: 'Entity',
                        size: 'small',
                        required: false
                    }
                },
                {
                    id: 1,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Cash location',
                        name: 'CashLocation',
                        lookupName: 'CashLocation',
                        size: 'small',
                        required: false,
                        isEntityScoped: true
                    }
                }
            ] as FormViewField[]
        },
        gridOptions: {
            suppressEditInContextMenu: true,
        },
        Actions: {
        },
        Columns: [
            { headerName: 'Payment', field: 'PaymentCode', width: 350 },
            { headerName: 'Description', field: 'PaymentDescription' },
            { headerName: 'Amount', field: 'Amount', valueGetter: formatFloatingNumber, type: 'numericColumn' },
        ],
        statusData: {},
        paymentDetails: {},
        paymentLines: [],
        eftposDialog: {
            open: false,
            data: {
                status: '',
                description: ''
            },
        },
        paymentContext: {},
        defaultLine: {},
    })
};
export default (state: IPaymentsState = initialState, action: IDataAction): IPaymentsState => {
    switch (action.type) {
        case types.setSelected:
            const selected = action.data.row;

            return {
                ...state,
                data: {
                    ...state.data,
                    selected: selected
                }
            };

        case types.showEftposDialog:

            return {
                ...state,
                data: {
                    ...state.data,
                    eftposDialog: {
                        ...state.data.eftposDialog,
                        ...action.data
                    }
                }
            };

        case types.fetStatus:
        case types.sendOk:
        case types.sendClose:
        case types.fetchPaymentDetails:
        case types.purchase:
        case types.applyPayment:
        case types.updatePaymentContext:
        case types.confirmCashReceiptProcess:
        case types.processPayment:
        case types.deletePayment:
        case types.cancelPayment:
        case types.processCreditNote:
        case types.submitSaleConfirmation:
            return asyncOnRequest(state, action);

        case types.saga.fetStatus.success:
        case types.saga.sendOk.success:
        case types.saga.sendClose.success:
        case types.saga.purchase.success:
        case types.saga.updatePaymentContext.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPaymentsData, successAction: SuccessAction) => {
                    return {
                        ...data,
                        statusData: successAction.payload,
                    };
                });

        case types.saga.fetchPaymentDetails.success:
        case types.saga.applyPayment.success:
        case types.saga.confirmCashReceiptProcess.success:
        case types.saga.deletePayment.success:
        case types.saga.processCreditNote.success:
        case types.saga.submitSaleConfirmation.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPaymentsData, successAction: SuccessAction) => {
                    return {
                        ...data,
                        paymentDetails: successAction.payload.PaymentDetails,
                        paymentLines: successAction.payload.PaymentLines,
                        defaultLine: successAction.payload.DefaultLine,
                        paymentContext: successAction.payload.PaymentContext
                    };
                });

        case types.saga.processPayment.success:
        case types.saga.cancelPayment.success:
            return asyncOnSuccess(
                state,
                action,
                (data: IPaymentsData) => {
                    return {
                        ...data,
                    };
                });

        case types.saga.fetStatus.failure:
        case types.saga.sendOk.failure:
        case types.saga.sendClose.failure:
        case types.saga.applyPayment.failure:
        case types.saga.fetchPaymentDetails.failure:
        case types.saga.purchase.failure:
        case types.saga.processPayment.failure:
        case types.saga.updatePaymentContext.failure:
        case types.saga.deletePayment.failure:
        case types.saga.cancelPayment.failure:
        case types.saga.processCreditNote.failure:
        case types.saga.submitSaleConfirmation.failure:
            return asyncOnError(state, action);

        default:
            return state;
    }
};

const asyncSelector = asyncSelectors(
    (state: { paymentDetails: IPaymentsState }) => state.paymentDetails,
    {
        statusData: (data) => data.statusData,
        paymentDetails: (data) => data.paymentDetails,
        paymentLines: (data) => data.paymentLines,
        defaultLine: (data) => data.defaultLine,
        paymentContext: (data) => data.paymentContext,
    }
);

const syncSelector = {
    isLoading: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.applyPayment_loading
        || state.paymentDetails.fetchPaymentDetails_loading
        || state.paymentDetails.processPayment_loading
        || state.paymentDetails.cancelPayment_loading
        || state.paymentDetails.deletePayment_loading
        || state.paymentDetails.purchase_loading,
    eftposDialog: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.eftposDialog,
    fetStatusLoading: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.fetStatus_loading,
    selected: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.selected,
    gridOptions: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.gridOptions,
    columns: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.Columns,
    Actions: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.Actions,
    filterRow: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.filterRow,
    newLineRenderer: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.newLineRenderer,
    selectedFilters: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.selectedFilters,
    paymentDetails: (state: { paymentDetails: IPaymentsState }) => state.paymentDetails.data.paymentDetails,
};

export const selectors = { ...asyncSelector, ...syncSelector };
