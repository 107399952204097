import {
  createActions, asyncInitialState, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null,
  asyncs: {
  }
}, 'historicalDates');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 120, },
    { headerName: 'Last receipt', field: 'LastReceiptDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Last external receipt', field: 'LastExternaltReceiptDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Last sale', field: 'LastSaleDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Last external sale', field: 'LastExternalSaleDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Last stock take', field: 'LastSotckTakeDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Last usage', field: 'LastUsageDate', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', minWidth: 350, suppressSorting: true }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.historicalDates,
  {
    // Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  Data: state => state.inventoryEnquiry.historicalDates.data.Data,
  isLoading: state => state.inventoryEnquiry.historicalDates.loading,
  gridOptions: state => state.inventoryEnquiry.historicalDates.data.gridOptions,
  Columns: state => state.inventoryEnquiry.historicalDates.data.Columns,
  Actions: state => state.inventoryEnquiry.historicalDates.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)