import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { actions as lookupActions, selectors as lookupSelectors } from 'ducks/lookup';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { IApplicationState } from 'ducks/reducers';

import SelectContainer from './SelectContainer';

const mapStateToProps = (state: IApplicationState) => ({
  results: lookupSelectors.all(state) || [],
  operationMode: operationSelectors.operationMode(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    fetchLookupOptions: lookupActions.fetch,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectContainer);
