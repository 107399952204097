const RequiredFields = [
  'CustomerAccountNumber',
  'Name',
  'OpenDate',
  'CustomerType',
  'ParetoClass',
  'ParetoClass',
  'DeliveryType',
  'Priority',
  'SalesTerritory',
  'SalesCategory',
  'CreditCode',
  'CurrencyCode',
  'TaxClass'
];
const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

export default function (values) {
  let errors = {};
  RequiredFields.forEach((item, index) => {
    if (isNull(values[item])) {
      errors[item] = 'Required';
    }
  })
  return errors;
}