import React, { Component } from 'react';
import { Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import HomeIcon from '@markinson/uicomponents-v2/SvgIcons/Home';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styles from './NotFound.scss';
import { Entry } from '../../../../config';

const classStyles = {
  buttonStyle: {
    backgroundColor: '#FE6F01',
    color: '#FFFFFF',
    position: 'relative',
    bottom: '42px'
  },
  heading: {
    color: '#FE6F01'
  }
};

class NotFound extends Component {
  backToHome = event => {
    if (Entry && Entry === 'security-management') {
      this.props.history.push('/security-policies');
    }
    else {
      this.props.history.push('/dashboard');
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.outer}>
          <Paper className={styles.inner}>
            <div className={styles.top}>
              <Typography variant="display4" className={classes.heading} >404</Typography>
            </div>
            <div className={styles.bottom}>
              <div className={styles.right}>
                <Button
                  onClick={this.backToHome}
                  variant="fab"
                  className={classes.buttonStyle}>
                  <HomeIcon />
                </Button>
              </div>
            </div>
            <div className={styles.bottom}>
              <Typography variant="title" gutterBottom>Oops! Something went wrong</Typography>
              <Typography>The page you are looking for could not be found.</Typography>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(classStyles, {index: 1})(NotFound));
