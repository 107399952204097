import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as productTypes, actions as productActions } from 'ducks/SearchLookUp/productLookup';

import { callApi } from '../utils';
import * as api from 'api/SearchLookUp/productLookup';


function* search(action) {
  const { success, failure } = productActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = productActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = productActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = productActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootProductLookUpSaga() {
  yield takeLatest(productTypes.search, search)
  yield takeLatest(productTypes.searchById, searchById)
  yield takeLatest(productTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(productTypes.fetchNextPage, fetchNext)
}