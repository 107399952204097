import * as React from 'react';
import { withStyles, createStyles, StyledComponentProps } from '@material-ui/core/styles';
import SummaryTable from 'components/common/SummaryTable';
import * as SummaryTableSchema from './SummaryTableSchema.json';
import Paper from 'components/FormView/Fields/PaperContainer';
import { Grid as GridLayout, FormControlLabel, Switch, Button } from '@material-ui/core';
import Typography from '@markinson/uicomponents-v2/Typography';
import { LookUpActionField as ExtendedLookUpActionField } from 'components/common/ExtendedLookUpActionField/LookUpActionField';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import DoneAllIcon from '@markinson/uicomponents-v2/SvgIcons/DoneAll';
import { selectors as filterSelectors, actions as filterActions } from 'ducks/common/filters';
import { actions as trueCostActions, selectors as trueCostSelectors } from 'ducks/priceCheck/trueCost';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PaperElevation } from 'utils/constants';
const styles = (theme) => createStyles({
  gridContainer: {
    width: 'auto',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    paddingLeft: '15px'
  },
  gridItem: {
    width: '50%',
    minWidth: '400px',
    padding: '15px 0px 0px 0px'
  },
  paper: {
    padding: theme.spacing.unit * PaperElevation,
    color: theme.palette.text.secondary,
    marginBottom: 16,
    marginRight: 16,
    height: 'calc(100% - 48px)',
  },
  summaryDiv: {
    width: '33%',
    minWidth: '400px',
  },
  header: {
    fontSize: '18px',
    fontWeight: 100,
    display: 'inline-block',
    paddingBottom: '16px'
  },
  switchBase: {
    '&$checked': {
      '& + $bar': {
        backgroundColor: '#6DD900',
        opacity: 1
      },
    },
  },
  bar: {},
  checked: {},
  formControlLabel: {
    '&$disabled': {
      color: '#555555',
    },
  },
  disabled: {

  },
  footer: {
    backgroundColor: '#e0e0e0',
    height: '50px'
  },
  applyButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0,
    float: 'right',
    lineHeight: '34px'
  }
});
const gridLayoutSX = 4;
interface ITrueCostCalculatorProps extends StyledComponentProps {
  filters: any;
  classes: any;
  isLoading: boolean;
  trueCostSummary: any;
  initialData: any;
  onApplyFilters(filters: any, supplier: any, quantity: any, calculateForProjectOrder: any): void;
  fetchDefaultTrueCostValues(filters: any): any;
}
interface ITrueCostCalculatorState {
  Supplier: any;
  CalculateForProjectOrder: any;
  Quantity: any;
  supplier: any;

}
class TrueCostCalculator extends React.Component<ITrueCostCalculatorProps, ITrueCostCalculatorState> {

  constructor(props: Readonly<ITrueCostCalculatorProps>) {
    super(props);
    this.state = { Supplier: '', CalculateForProjectOrder: false, Quantity: 0, supplier: '' };
    this.props.fetchDefaultTrueCostValues(this.props.filters);
  }

  componentDidUpdate(prevProps: Readonly<ITrueCostCalculatorProps>): void {
    if (prevProps.initialData !== this.props.initialData && this.props.initialData) {
      this.setState({ Supplier: this.props.initialData.Supplier, CalculateForProjectOrder: this.props.initialData.ProjectOrder, Quantity: this.props.initialData.Quantity, supplier: this.props.initialData.Supplier });
    }
  }

  changeSupplier = (event: any) => {
    this.setState({ Supplier: (event) || '' });
  }
  changeQuantity = (event: any) => {
    this.setState({ Quantity: event.target.value });
  }
  changeToggle = () => {
    this.setState({ CalculateForProjectOrder: !this.state.CalculateForProjectOrder });
  }

  applyFilters = () => {
    if (this.props.filters && this.state.Supplier && this.state.Supplier !== '') {
      this.props.onApplyFilters(this.props.filters, this.state.Supplier, this.state.Quantity, this.state.CalculateForProjectOrder);
    }
  }

  render(): React.ReactNode {
    const { classes, trueCostSummary } = this.props;

    return (<GridLayout container spacing={0} className={classes.gridContainer}>
      <GridLayout item xs={gridLayoutSX} className={classes.gridItem}>
        <Paper className={classes.paper}>
          <label className={classes.header}>True Cost Calculator</label>
          <ExtendedLookUpActionField
            label={'Supplier'}
            value={this.state.supplier}
            lookupName={'Supplier'}
            onChange={this.changeSupplier}
            size={'small'}
          />
          <TextField
            name='TrueCostQuantity'
            autoComplete='off'
            type={'number'}
            decimalSeparator
            label={'Quantity'}
            value={this.state.Quantity}
            onChange={this.changeQuantity}
            className={classes.searchField}
          />
          <FormControlLabel
            control={<Switch
              color={'default'}
              checked={this.state.CalculateForProjectOrder}
              classes={{
                switchBase: classes.switchBase,
                checked: classes.checked,
                bar: classes.bar,
              }}
            />}
            style={{ width: '100%' }}
            classes={{
              label: classes.formControlLabel,
              disabled: classes.disabled
            }}
            label={'Calculate for project order'}
            onChange={this.changeToggle}
          />
          <div className={classes.footer}>
            <Button
              variant={'contained'}
              className={classes.applyButton}
              onClick={this.applyFilters}
            >
              <DoneAllIcon style={{ color: 'green' }} />&nbsp;Calculate
            </Button>
          </div>
        </Paper>
      </GridLayout>
      <GridLayout item xs={gridLayoutSX} className={classes.gridItem}>
        <Paper className={classes.paper}>
          <label className={classes.header}>About the True Cost Calculator</label>
          <Typography type={'default'}>The product True Cost is a notational value calculated from the supplier's buy price with consideration for multiple factors including the supplier's settlement discount, freight factor, rebates and import duty.</Typography>
        </Paper>
      </GridLayout>
      <GridLayout item xs={gridLayoutSX} className={classes.summaryDiv}>
        <SummaryTable
          trueCostSummary={trueCostSummary && { Supplier: trueCostSummary.SupplierDisplay, SupplierName: trueCostSummary.SupplierLabel, Quantity: trueCostSummary.QuantityDisplay, Currency: trueCostSummary.Currency, AverageCost: trueCostSummary.CostDisplay, TrueCost: trueCostSummary.TrueCostTotalDisplay }}
          data={SummaryTableSchema.TrueCost}
          loadingTrueCostSummary={this.props.isLoading}
        />
      </GridLayout>
    </GridLayout>);
  }
}

const mapStateToProps = (state) => ({
  filters: filterSelectors.getFilters(state, 'PriceCheckFilters'),
  isLoading: trueCostSelectors.isLoading(state),
  trueCostSummary: trueCostSelectors.summary(state),
  initialData: trueCostSelectors.initialData(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    onApplyFilters: filterActions.applyFilters,
    fetchDefaultTrueCostValues: trueCostActions.fetchDefaultTrueCostValues
  },
  dispatch);

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { index: 1 })(TrueCostCalculator)));
