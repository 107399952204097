import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as customerProductTypes, actions as customerProductActions } from 'ducks/customerProduct';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/product';


function* search(action) {
  const { success, failure } = customerProductActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = customerProductActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchDetail(action){
  const { success, failure } = customerProductActions.saga.fetchDetail;

  yield callApi(
    call(api.fetchDetail, action.data),
    success,
    failure
  )
}
export default function* rootProductSaga() {
  yield takeLatest(customerProductTypes.search, search)
  yield takeLatest(customerProductTypes.searchById, searchById)
  yield takeLatest(customerProductTypes.fetchDetail, fetchDetail)
}