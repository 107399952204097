import { fetchPost, fetchGet, objectify } from '../utils';

export const fetchAvailableSerials = async (query: { WarehouseEntity: string; ProductId: any; searchText?: string }) => {
    const { WarehouseEntity, ProductId, searchText = '' } = query;

    return fetchPost(
        '/CustomTypes/Lookup/WarehouseProductLot/Search', { WarehouseEntity, ProductId, searchText })
        .then(formatAvailableLines);
};

export const fetchSelectedSerial = async (query: { apiString: string }) => {
    const { apiString, } = query;

    return fetchGetData({ apiString, outputObject: 'PickSlipSerials' }).
        then((PickSlipSerials) => {

            return PickSlipSerials.map((serial) => {
                const objectifiedSerial: any = objectify(serial);

                return { SerialNumber: objectifiedSerial.SerialNumber };
            });
        });
};

export const fetchSerialSummary = async (query: { apiString: string }) => {
    const { apiString, } = query;

    return fetchGetData({ apiString, outputObject: 'PickSlipSerialSummary' }).
        then((PickSlipSerialSummary) => (objectify(PickSlipSerialSummary)));
};

const fetchGetData = async (query: { apiString: string; outputObject: string }) => {
    const { apiString, outputObject } = query;

    return fetchGet(apiString).then(
        (results) => {
            return results[outputObject];
        }
    );
};

export const saveSerials = async (data) => {
    const { DespatchId, LineNumber, serials } = data;

    return fetchPost(`PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/CreatePickSlipSerialRange`, { SerialNumber: serials });
};

const formatAvailableLines = ({ Options }) => {
    const keys = Object.keys(Options);

    return keys.map((key) => {
        return { SerialNumber: key, Entered: Options[key].Description };
    });
};
