import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import inlineStyles from './ParkedSaleList.styles';
import { CircularProgress } from '@material-ui/core';
import { CircularProgressSize } from 'components/Worksale/constants';
import { IParkedSaleListProps } from './ParkedSaleList.properties';
import DataList from 'components/common/LookUpList/DataList';
//<T>
//<T extends object = any>
const ParkedSaleList = (props: IParkedSaleListProps) => {
    const {
        isLoading,
        keyField,
        listItems,
        disabled,
        fieldsToDisplay,
        handleRecordChange
    } = props;

    const [selectedRecord, setSelectedRecord] = React.useState(null);
    const onRecordChange = (record: any) => {
        setSelectedRecord(record);
        if (handleRecordChange) {
            handleRecordChange(record);
        }
    };

    return (
        <>
            <div style={inlineStyles.main}>
                {isLoading ?
                    <CircularProgress size={CircularProgressSize} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
                    :
                    <DataList
                        disableDefaultSelection
                        disableItemSelection={disabled}
                        keyField={keyField}
                        listItems={(listItems || [])}
                        selectedRecordId={selectedRecord}
                        fieldsToDisplay={fieldsToDisplay}
                        handleRecordChange={onRecordChange}
                    />
                }
            </div>
        </>
    );
};

export default withStyles(inlineStyles, { index: 1 })(ParkedSaleList);
