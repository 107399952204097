import * as React from 'react';
import FormView from 'components/FormView';
import { IAllocateCashReceiptProps, IAllocateCashReceiptState } from '../interfaces';
import { OPERATIONS, Operation } from 'utils/operations';
import { isShallowEqual, isNull, pathOr } from 'utils/utils';
class CashReceipt extends React.PureComponent<IAllocateCashReceiptProps, IAllocateCashReceiptState> {

  constructor(props: IAllocateCashReceiptProps) {
    super(props);
    this.state = {
      lastUpdatedValues: {}
    };
  }

  componentDidMount(): void {
    const { path, onInitialLoad, allocate } = this.props;
    if (isNull(allocate)) {
      onInitialLoad('CashReceipt');
    } else if (path === '/cash-receipting/allocate') {
      onInitialLoad('Allocate');
    } else {
      onInitialLoad('CashReceipt');
    }
  }

  componentDidUpdate(provProps: IAllocateCashReceiptProps): void {
    const { operationMode, changeSelectedTab, changeOperationMode } = this.props;
    if (provProps.operationMode !== operationMode) {
      switch (operationMode) {
        case OPERATIONS.INVOICES:
          changeSelectedTab('CashReceiptingInvoices');
          changeOperationMode(Operation.EDIT);
          break;
        case OPERATIONS.ORDERS:
          changeSelectedTab('CashReceiptingOrders');
          changeOperationMode(Operation.EDIT);
          break;
        default:
          changeOperationMode(Operation.EDIT);
      }
    }
  }

  fillEmptyDecimalFields = (formValues: any = {}, formJsonSchema: any) => {
    const fields = pathOr([], ['fields'], formJsonSchema);
    let childrens = [];

    fields.forEach((field: any) => {
      const children = pathOr([], ['children'], field);
      childrens = childrens.concat(children);
    });

    childrens.forEach((child: any) => {
      const { props = {} } = child;
      if (props.type === 'number' && isNull(formValues[props.name])) {
        formValues[props.name] = 0;
      }
    });

    return formValues;
  }

  handleOnBlur = () => {
    const { lastUpdatedValues } = this.state;
    const { updateAllocatedCashReceipt, formValues, selectedForm } = this.props;
    if (!isShallowEqual(lastUpdatedValues, formValues) && updateAllocatedCashReceipt) {
      const reqFormValues = this.fillEmptyDecimalFields(formValues, selectedForm);
      updateAllocatedCashReceipt(reqFormValues);
      this.setState({ lastUpdatedValues: reqFormValues });
    }

  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, summaryTableRenderer, allocate } = this.props;
    const initialValues = allocate && allocate.inlineObject;
    const valuesSchema = allocate && allocate.schema;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          onBlur={this.handleOnBlur}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={valuesSchema}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default CashReceipt;
