import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reset, getFormSyncErrors } from 'redux-form';
import UserIdScreenDialog from './UserIdScreenDialog';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'UserIdScreenDialogForm';

const mapStateToProps = (state: IApplicationState) => ({
  values: getFormValues(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserIdScreenDialog);
