import React from 'react';
import FormView from 'components/FormView';
import ServiceItemTypeDetailsForm from './ServiceItemTypeDetails.form';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import Cancel from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import Edit from '@markinson/uicomponents-v2/SvgIcons/Edit';
import RemoveCircle from '@markinson/uicomponents-v2/SvgIcons/RemoveCircle';
import AddCircle from '@markinson/uicomponents-v2/SvgIcons/AddCircle';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import IServiceItemTypeDetailsProperties from './ServiceItemTypeDetails.properties';
import { IServiceItemTypeFacade, IServiceItemTypeResponse } from 'api/swaggerTypes';
import { Operation } from 'utils/operations';
import { isNull } from 'utils/utils';
import { IObjectifiedServiceItemTypeResponse } from 'api/serviceItemTypeMaintenance/serviceItemType';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const ServiceItemTypeDetails = (props: IServiceItemTypeDetailsProperties): JSX.Element => {
  const {
    selectedServItemType, forwardedRef, changeFormFieldValue, resetForm,
    deleteServiceItemType, createServiceItemType, values,
    updateServiceItemType, performSearch, changeConfirmationDialog, dirty
  } = props;
  const [initialValues, setInitialValues] = React.useState<IServiceItemTypeFacade>();
  const [operationMode, setOperationMode] = React.useState<Operation>(Operation.BROWSE);
  const { setActionBar } = React.useContext(ActionBarContext);
  const processValidationForms = React.useContext(ProcessValidationFormsContext);

  const inEditNew = operationMode === Operation.NEW || operationMode === Operation.EDIT;
  const isItemSelected = !isNull(selectedServItemType);
  const leftIcons = [
    {
      label: 'Back',
      Icon: ArrowBack,
      action: 'Back',
    }
  ];
  const centerIcons = [];
  const rightIcons = [
    {
      label: 'New',
      Icon: AddCircle,
      action: 'New',
      disabled: inEditNew
    },
    {
      label: 'Edit',
      Icon: Edit,
      action: 'Edit',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Delete',
      Icon: RemoveCircle,
      action: 'Delete',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Cancel',
      Icon: Cancel,
      action: 'Cancel',
      disabled: !inEditNew
    },
    {
      label: 'OK',
      Icon: CheckCircle,
      action: 'Save',
      disabled: !inEditNew
    },
  ];

  React.useEffect(
    () => {
      setOperationMode(Operation.BROWSE);
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    []
  );
  React.useEffect(
    () => {
      setInitialValues(selectedServItemType);
    },
    [selectedServItemType]
  );

  React.useEffect(
    () => {
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    [operationMode, selectedServItemType]
  );

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      onEdit(): void {
        setOperationMode(Operation.EDIT);
      },
      onCancel(): void {
        if (dirty) {
          changeConfirmationDialog({
            open: true,
            title: 'Discard changes',
            message: 'Are you sure you want to continue?',
            okLabel: 'Discard',
            onCancel: () => null,
            onOk: () => {
              resetForm();
              setOperationMode(Operation.BROWSE);
            }
          });
        } else {
          resetForm();
          setOperationMode(Operation.BROWSE);
        }
      },
      onNew(): void {
        changeFormFieldValue('ServiceItemTypeId', '');
        changeFormFieldValue('Description', '');
        setOperationMode(Operation.NEW);
      },
      onOk(): void {
        if (operationMode === Operation.NEW) {
          createServiceItemTypeAndValidate(values)
            .then((response) => {
              if (response.Status && !response.Forms) {
                const { ServiceItemTypeId }: any = response.ServiceItemType;
                showSnackBar({ variant: 'success', message: 'Service Item Type added successfully.' });
                performSearch({ SearchText: `${ServiceItemTypeId.Value}` });
                setOperationMode(Operation.BROWSE);
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to add Service Item Type.' });
              }
            })
            .catch((err) => { console.warn(err); });
        } else {
          updateServiceItemTypeAndValidate(values)
            .then((response) => {
              if (response.Status && !response.Forms) {
                showSnackBar({ variant: 'success', message: 'Service Item Type updated successfully.' });
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to update Service Item Type.' });
              }
            })
            .catch((err) => { console.warn(err); });

          setOperationMode(Operation.BROWSE);
        }
      },
      onDelete(): void {
        changeConfirmationDialog({
          open: true,
          title: 'Delete Service Item Type',
          message: 'Are you sure you want to continue?',
          okLabel: 'Delete',
          onCancel: () => null,
          onOk: () => {
            deleteServiceItemType(selectedServItemType.ServiceItemTypeId)
              .then((response) => {
                if (response.Status) {
                  showSnackBar({ variant: 'success', message: 'Service Item Type deleted successfully.' });
                  performSearch({ SearchText: '' });
                } else {
                  showSnackBar({ variant: 'error', message: response.Message });
                }
              })
              .catch((err) => { console.warn(err); });
          }
        });
      }
    })
  );

  async function createServiceItemTypeAndValidate(query: any): Promise<IServiceItemTypeResponse> {
    const response = await createServiceItemType(query);

    return processValidationForms(response, query, async (validatedResponse) => createServiceItemTypeAndValidate(validatedResponse));
  }

  async function updateServiceItemTypeAndValidate(query: any): Promise<IObjectifiedServiceItemTypeResponse> {
    const response = await updateServiceItemType(selectedServItemType.ServiceItemTypeId, query);

    return processValidationForms(response, query, async (validatedResponse) => updateServiceItemTypeAndValidate(validatedResponse));
  }

  return (
    <React.Fragment>
      <FormView
        formName={'ServiceItemTypeDetail'}
        schema={ServiceItemTypeDetailsForm}
        initialValues={initialValues}
        operationMode={operationMode}
        fieldExtensions={
          {
            ServiceItemTypeId: {
              disabled: !(operationMode === Operation.NEW)
            },
          }
        }
      />
    </React.Fragment>
  );
};

export default ServiceItemTypeDetails;
