import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from '../SummaryTableSchema.json';
import { ISaleDepositsProperties } from '../interfaces';

class SaleDeposits extends React.PureComponent<ISaleDepositsProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/sale-deposits':
          onInitialLoad('SaleDeposits');
          break;
        case '/sales-order-enquiry/sale-deposits/deposit-details':
          onInitialLoad('DepositDetails');
          break;
        case '/sales-order-enquiry/sale-deposits/deposit-details/payment-entry-details':
          onInitialLoad('PaymentEntryDetails');
          break;
        default:
          onInitialLoad('SaleDeposits');
      }
    }
  }

  getValuesSchema = (): any => {
    const { selectedTab, paymentEntryDetails } = this.props;
    switch (selectedTab) {
      case 'PaymentEntryDetails':
        return paymentEntryDetails && paymentEntryDetails.schema;
      default:
        return null;
    }
  };

  getInitialValues = (): any => {
    const {
      selectedTab,
      depositDetailsSummary, paymentEntryDetails
    } = this.props;
    switch (selectedTab) {
      case 'DepositDetails':
        return depositDetailsSummary && { ...depositDetailsSummary };
      case 'PaymentEntryDetails':
        return paymentEntryDetails && { ...paymentEntryDetails.values, ...paymentEntryDetails };
      default:
        return null;
    }
  };

  getSummaryTableRenderer = (): React.ReactNode => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'PaymentEntryDetails':
      case 'DepositDetails':
        return this.paymentEntrySummary;
      default: return null;
    }
  };

  paymentEntrySummary = (): React.ReactNode => {
    const { depositDetailsSummary, loadingPaymentEntrySummary } = this.props;

    return <SummaryTable
      selectedEntry={depositDetailsSummary}
      loadingPaymentEntrySummary={loadingPaymentEntrySummary}
      data={
        SummaryTableSchema.PaymentEntryDetails
      } />;
  };

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={this.props.notifications}
          entity='Sale Deposits'
        />
        <FormView
          isLoading={isLoading}
          formName='SaleDeposits'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={this.getInitialValues()}
          valuesSchema={this.getValuesSchema()}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default SaleDeposits;
