import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify, fetchGet } from '../utils'

export const search = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchPost('SupplierProductEnquiry/SearchSupplierProducts', { SearchText, Sort, BatchPage, BatchSize })
    .then((result) => ({
      records: result.SupplierProducts.map((val) => Object.assign({}, { inlineObject: objectify(val), schema: val })),
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
    }))
}

export const searchById = (SupplierProductId) => {
  return fetchGet(`SupplierProductEnquiry/SupplierProduct/${SupplierProductId}`, {}, 'Inline')
    .then((result) => ({
      records: [{ inlineObject: objectify(result.SupplierProductDetail), schema: result.SupplierProductDetail }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }))
}

export const fetchSummary = (SupplierProductId) => {
  return fetchPost(`/SupplierProductEnquiry/SupplierProduct/${SupplierProductId}/Summary`, {}, 'Inline')
    .then((result) => result ? objectify(result.PordProceSchedSummary, null, 'Inline') : null)
}
