import { call, Effect, takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/supplierEnquiry/purchasing/purchaseOrders';

import { callApi } from '../../utils';
import * as api from 'api/supplierEnquiry/purchasing/purchaseOrders';
import { IDataAction } from 'ducks/utils';

function* getSupplierPurchaseOrdersSummary(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = actions.saga.getPurchaseOrdersSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(types.getPurchaseOrdersSummary, getSupplierPurchaseOrdersSummary);
}
