const SummaryTableSchema = [
    {
        objectName: 'selectedTemplate',
        isLoading: 'loadingSelectedTemplateSummary',
        children: [
            {
                label: 'Last Modified',
                fieldNames: ['LastModifiedDate'],
            },
            {
                label: 'Created',
                fieldNames: ['CreatedDate']
            },
            {
                label: 'Status',
                fieldNames: ['Status']
            },
        ]
    },
];

export default SummaryTableSchema;
