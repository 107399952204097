import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset, change, getFormSyncErrors } from 'redux-form';
import OrderDetails from './OrderDetails';
import { IApplicationState } from 'ducks/reducers';
import { selectors as formSelectors } from 'ducks/form';
import { IOrderDetailsHandle, IOrderDetailsProps } from './OrderDetails.properties';
import { actions as uiActions } from 'ducks/ui';

const FORM_NAME = 'OrderDetails';
const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty(FORM_NAME)(state),
  formValues: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
  selectedForm: formSelectors.selected(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  formAsyncErrors: formSelectors.getFormAsyncErrors(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeValidationDialog: uiActions.changeValidationDialog,
    changeFieldValue: (field, value) => change(FORM_NAME, field, value)
  },
  dispatch);

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: Readonly<IOrderDetailsProps>) => {
  return (<OrderDetails {...props} />);
});

export default React.forwardRef(
  (props: Readonly<Omit<IOrderDetailsProps, 'changeValidationDialog'>>, ref: React.Ref<IOrderDetailsHandle>) => {

    return <ConnectedComponent {...props} forwardedRef={ref} />;
  });
