export const MODULE_TREE = [
  { id: 'CustomerDetails', label: 'Customer Details', parent: '' },
  { id: 'CustomerSetting', label: 'Settings', parent: '' },
  { id: 'CustomerFinancial', label: 'Financial', parent: '' },
  { id: 'CustomerPricing', label: 'Pricing', parent: '' },
  { id: 'Contacts', label: 'Contacts', parent: '' },
  { id: 'DeliveryDetails', label: 'Delivery Details', parent: '' },
  { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
  { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' }
];

export const TABS_DATA = [
  { id: 'CustomerDetails', label: 'Customer Details' },
  { id: 'CustomerSetting', label: 'Settings' },
  { id: 'CustomerFinancial', label: 'Financial' },
  { id: 'CustomerPricing', label: 'Pricing' }
];
