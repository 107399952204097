import { IFormViewForm } from 'components/FormView';

const RFCSummaryForm: IFormViewForm = {
  id: 'Summary',
  layout: {
    rows: 1,
    columns: 2
  },
  fields: [
    {
      id: 0,
      position: {
        row: 1,
        col: 1,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'RFC totals',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'RFC total',
            name: 'RFCTotalDisplay',
            size: 'medium',
            style: {
              width: '45%'
            },
            alignment: 'right'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'RFCTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Charges',
            name: 'ChargesDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'ChargesTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Variance',
            name: 'VarianceDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'VarianceTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit total',
            name: 'CreditTotalDisplay',
            size: 'medium',
            style: {
              width: '45%'
            },
            alignment: 'right'
          }
        },
        {
          id: 8,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'CreditTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2,
        colspan: 1.5
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Local RFC totals',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'LocalCurrency',
            size: 'small',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Exchange rate',
            name: 'ExchangeRateDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'RFC total',
            name: 'LocalRFCTotalDisplay',
            size: 'medium',
            style: {
              width: '45%'
            },
            alignment: 'right'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'LocalRFCTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Charges',
            name: 'LocalChargesDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'LocalChargesTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Variance',
            name: 'LocalVarianceDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'LocalVarianceTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
        {
          id: 8,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit total',
            name: 'LocalCreditTotalDisplay',
            size: 'medium',
            style: {
              width: '45%'
            },
            alignment: 'right'
          }
        },
        {
          id: 9,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax',
            name: 'LocalCreditTaxDisplay',
            type: 'number',
            size: 'medium',
            style: {
              width: '45%'
            }
          }
        },
      ]
    }
  ]
};
export default RFCSummaryForm;
