import QuoteDetailsForm from './../data/forms/customer_quote_enquiry/QuoteDetails'
import QuoteSummaryForm from './../data/forms/customer_quote_enquiry/QuoteSummary'
import QuoteLinesForm from './../data/forms/customer_quote_enquiry/QuoteLines'
import LineDetailsForm from './../data/forms/customer_quote_enquiry/LineDetails'
import Comments from './../data/forms/customer_quote_enquiry/Comments'
import BillOfMaterials from './../data/forms/customer_quote_enquiry/BillOfMaterials'
import ComponentDetails from './../data/forms/customer_quote_enquiry/ComponentDetails'
import ReservationDetails from './../data/forms/customer_quote_enquiry/ReservationDetails'
import ReservationLots from './../data/forms/customer_quote_enquiry/ReservationLots'
import ReservationSerials from './../data/forms/customer_quote_enquiry/ReservationSerials'
import BOMReservationLots from './../data/forms/customer_quote_enquiry/BOMReservationLots'
import BOMReservationDetails from './../data/forms/customer_quote_enquiry/BOMReservationDetails'
import BOMReservationSerials from './../data/forms/customer_quote_enquiry/BOMReservationSerials'
import NotePadForm from './../data/forms/customer_quote_enquiry/NotepadForm'
import SalesDiary from './../data/forms/customer_quote_enquiry/SalesDiary'
import DiaryDetails from './../data/forms/customer_quote_enquiry/DiaryDetails'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'QuoteDetails':
      return QuoteDetailsForm
    case 'QuoteSummary':
      return QuoteSummaryForm
    case 'QuoteLines':
      return QuoteLinesForm
    case 'LineDetails':
      return LineDetailsForm
    case 'Comments':
      return Comments
    case 'BillOfMaterials':
      return BillOfMaterials
    case 'ComponentDetails':
      return ComponentDetails
    case 'ReservationDetails':
      return ReservationDetails
    case 'ReservationLots':
      return ReservationLots
    case 'ReservationSerials':
      return ReservationSerials
    case 'BOMReservationLots':
      return BOMReservationLots
    case 'BOMReservationDetails':
      return BOMReservationDetails
    case 'BOMReservationSerials':
      return BOMReservationSerials
    case 'Notepad':
      return NotePadForm
    case 'SalesDiary':
      return SalesDiary
    case 'DiaryDetails':
      return DiaryDetails
    default:
      return QuoteDetailsForm
  }
}

export default getFormSchema
