import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as quoteSelectors } from '../../../ducks/customerQuoteEnquiry/quote';

const mapStateToProps = (state) => ({
  selectedQuote: quoteSelectors.selected(state),
  quoteSummaryPanel: quoteSelectors.quoteSummaryPanel(state),
  selectedQuoteLoading: quoteSelectors.isLoading(state),
});

export default connect(mapStateToProps, {})(SummaryPanel);
