import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as slipLinesSelectors, actions as slipLinesActions } from 'ducks/pickSlips/slipLines';
import { selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { selectors as pickSlipDetailsSelectors } from 'ducks/pickSlips/slipDetails';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'PSSlipLines',
  columnDefs: slipLinesSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: slipLinesSelectors.gridOptions(state),
  filterRow: slipLinesSelectors.filterRow(state),
  selectedFilters: slipLinesSelectors.selectedFilters(state),
  appliedFilters: slipLinesSelectors.filterRow(state) && filterSelectors.getFilters(state, slipLinesSelectors.filterRow(state).formName),
  reqParams: (pickSlipSelectors.selected(state) || params.get('DespatchId'))
    &&
    {
      DespatchId: (pickSlipSelectors.selected(state) && pickSlipSelectors.selected(state).DespatchId) || params.get('DespatchId'),
      entity: pickSlipDetailsSelectors.entity(state)
    },
  keyFields: [{ valueField: 'LineNumber', routeField: 'LineNumber' }],
  isLoading: slipLinesSelectors.isLoading(state),
  rowData: slipLinesSelectors.lines(state),
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  deleteSelectedRecords: slipLinesActions.deleteSlipLine,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: slipLinesActions.setSelected,
  applyFilters: slipLinesActions.fetchSlipLines
};
