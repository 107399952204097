import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions } from 'ducks/ui';
import ChecklistMaintenanceDetail from './ChecklistMaintenanceDetail';
import ChecklistMaintenanceDetailProps, { IChecklistMaintenanceDetailsHandle } from './ChecklistMaintenanceDetail.properties';
import { selectors as checklistSelectors, actions as checklistDetailActions } from 'ducks/checklistMaintenance/checklist';
import { getFormValues, isDirty, reset, change, touch } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import * as api from 'api/checklistMaintenance/checklist';

const FORM_NAME = 'ChecklistMaintenanceDetail';
const mapStateToProps = (state: IApplicationState) => ({
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
    selectedChecklist: checklistSelectors.selected(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
        touchFormFields: (...args: any) => touch(FORM_NAME, ...args),
        search: checklistDetailActions.search,
        searchById: checklistDetailActions.searchById
    },
    dispatch);

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)((props: Readonly<Omit<
    ChecklistMaintenanceDetailProps, 'createChecklist' | 'updateChecklist' | 'deleteChecklist'>>) => {
    return (<ChecklistMaintenanceDetail
        {...props}
        createChecklist={api.create}
        updateChecklist={api.update}
        deleteChecklist={api.deleteChecklist}
    />);
});

export default React.forwardRef(
    (props: Readonly<Omit<ChecklistMaintenanceDetailProps, 'touchFormFields' | 'selectedChecklist' | 'createChecklist' | 'updateChecklist' | 'deleteChecklist'>>, ref: React.Ref<IChecklistMaintenanceDetailsHandle>) => {

        return <ConnectedComponent {...props} forwardedRef={ref} />;
    });
