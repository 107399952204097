import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    mainDiv: {
        height: ' calc(100% - 64px)',
        top: '64px',
        position: 'relative'
    },
    innerContainer: {
        'width': '100%',
        'height': '100%',
        'display': 'flex',
        'flexDirection': 'row',
        'flexWrap': 'nowrap',
        'justifyContent': 'space-between',
        '& > div': {
            width: '100%'
        }
    },
    EMSection: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
    },
    EMSectionMarginOpen: {
        marginLeft: '10px',
        width: 'calc(100% - 310px)'
    },
    EMSectionMarginClose: {
        marginLeft: '20px',
        width: 'calc(100% - 20px)'
    },
});
