import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './PurchaseOrders.actions';
import reducer, { initialState, IState } from './PurchaseOrders.reducer';

export const PurchaseOrdersStateContext = createContext<IState>(initialState);
export const PurchaseOrdersDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const usePODispatch = () => useContext(PurchaseOrdersDispatchContext);
export const usePOState = () => useContext(PurchaseOrdersStateContext);
export const usePOContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(PurchaseOrdersStateContext, selector);

interface IPurchaseOrdersStateProvider {
  children: React.ReactNode;
}

const PurchaseOrdersStateProvider = ({ children }: IPurchaseOrdersStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <PurchaseOrdersStateContext.Provider value={state}>
      <PurchaseOrdersDispatchContext.Provider value={dispatch}>
        {children}
      </PurchaseOrdersDispatchContext.Provider>
    </PurchaseOrdersStateContext.Provider>
  );
};

export default PurchaseOrdersStateProvider;
