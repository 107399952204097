import { combineReducers } from 'redux';
import technician, { ITechnicianState } from './technician';
import rosterEntries, { IRosterEntriesState } from './rosterEntries';

export interface IRosterMaintenanceState {
  technician: ITechnicianState;
  rosterEntries: IRosterEntriesState;
}
const combined = combineReducers({
  technician,
  rosterEntries
});

export default combined;
