import * as React from 'react';
import FormView from '../../FormView/index';
import { ICustomerSalesProps } from '../interfaces';

class CustomerDetail extends React.PureComponent<ICustomerSalesProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-enquiry/sales/invoices':
          onInitialLoad('Invoices');
          break;
        case '/customer-enquiry/sales/sales-orders':
          onInitialLoad('SalesOrders');
          break;
        case '/customer-enquiry/sales/back-orders':
          onInitialLoad('Backorders');
          break;
        case '/customer-enquiry/sales/service-orders':
          onInitialLoad('ServiceOrders');
          break;
        case '/customer-enquiry/sales/enquiries':
          onInitialLoad('Enquiries');
          break;
        case '/customer-enquiry/sales/quotes':
          onInitialLoad('Quotes');
          break;
        case '/customer-enquiry/sales/movements':
          onInitialLoad('Movements');
          break;
        default:
          onInitialLoad('Invoices');
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedForm, operationMode, formName } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={formName}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          valuesSchema={null}
        />
      </React.Fragment>
    );
  }
}

export default CustomerDetail;
