import { connect } from 'react-redux';

import Commitments from './Commitments';

import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isLoading: orderLineSelectors.isLoading(state),
});

export default connect(mapStateToProps, null)(Commitments);
