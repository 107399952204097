import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../utils';
import moment from 'moment';

export const search = (query) => {
  const { filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const respType = 'Inline'
  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return fetchPost('/PriceCheck/GetSalesHistory',
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    }, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    respType)
    .then((result) => (result && result.SalesHistory &&
    {
      records: result.SalesHistory.map((val) => (objectify(val))),
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
    })
      ||
    {
      records: [],
      nextPage: false,
      currPage: 1,
    });
}