import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as purchaseOrderLineSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';
import { selectors as purchaseOrderSelectors, actions as purchaseOrderActions } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { actions as branchOrderActions } from 'ducks/purchaseOrderEnquiry/branchOrders';
import { actions as purchaseOrderLineDetailsAction, selectors as purchaseOrderLineDetailsSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLineDetails';
import PurchaseOrderEnquiry from './PurchaseOrderEnquiry';
import { destroy } from 'redux-form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedOrder: purchaseOrderSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  selectedOrderLine: purchaseOrderLineSelectors.selectedOrderLine(state),
  purchaseOrderLineSummary: purchaseOrderLineDetailsSelectors.lineSummary(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getOrderDetails: purchaseOrderActions.getDetails,
    fetchOrderSummary: purchaseOrderActions.fetchSummary,
    fetchBranchOrderSummary: branchOrderActions.fetchSummary,
    getOrderLineDetails: purchaseOrderLineDetailsAction.fetchLineDetails,
    getOrderLineSummary: purchaseOrderLineDetailsAction.fetchLineSummary,
    resetSearchLookupDrawer: purchaseOrderActions.reset,
    destoryForm: () => destroy('PurchaseOrderEnquiry'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderEnquiry);
