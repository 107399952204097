import * as React from 'react';
import AppBar from '../common/ApplicationBar';
import ConfirmationDialog, { IConfirmationDialogProperties } from '../common/ConfirmationDialog/ConfirmationDialog';
import ValidationDialog from '../common/ValidationDialog/ValidationDialog';
import { IValidationDialogProperties, IValidationDialogOptions } from '../common/ValidationDialog/ValidationDialog.properties';
import MessageBoxValidationDialog from '../common/ValidationDialog/MessageBoxValidationDialog';
// import SnackBar from '../common/SnackBars';
import EFTPOSModal from 'components/common/EFTPOSModal';
// import { fetStatus } from 'api/common/paymentDetails';
import DashboardMaintenance from 'components/DashboardMaintenance';
import { TestInstanceName } from '../../../src/config';
import InformationDialog from 'components/common/InformationDialog';
import { IInformationDialogProperties } from 'components/common/InformationDialog/InformationDialog';

export interface ILayoutFrameProperties {
  confirmationDialog: IConfirmationDialogProperties;
  informationDialog: IInformationDialogProperties;
  validationDialog: IValidationDialogProperties;
  validationFormValues: any;
  eftposDialog: any;
  fetStatusLoading: boolean;
  isDashboardDialogOpen: boolean;
  isDashboardDefault: boolean;
  loggedIn: boolean;
  changeConfirmationDialog(options: IConfirmationDialogProperties): void;
  changeInformationDialog(options: IInformationDialogProperties): void;
  changeValidationDialog(options: IValidationDialogOptions): void;
  showEftposDialog(options: any): void;
  toggleDashboardOpen(open: boolean): void;
  fetchDashboardWidgets(): void;
  fetchMainMenu(): void;
  fetchProgramAreas(): void;
  fetStatus(): void;
  sendClose(): void;
  sendOk(): void;
  setIsDashboardDefault(yes: boolean): void;
}

class Layout extends React.PureComponent<ILayoutFrameProperties> {

  handleEftposOnClose = () => {
    const { sendClose } = this.props;
    if (sendClose) sendClose();
  }

  handleDashboardModalClose = () => {
    const { isDashboardDefault, toggleDashboardOpen, setIsDashboardDefault } = this.props;
    if (toggleDashboardOpen) toggleDashboardOpen(false);
    if (isDashboardDefault) {
      setIsDashboardDefault(false);
    }
  }

  handleDashboardModalCloseSuccess = () => {
    const { fetchDashboardWidgets, fetchMainMenu, fetchProgramAreas } = this.props;
    if (fetchDashboardWidgets) fetchDashboardWidgets();
    fetchMainMenu();
    fetchProgramAreas();
  }

  handleEftposOnOk = () => {
    const { sendOk } = this.props;
    if (sendOk) sendOk();
  }

  handleConfirmationOnClose = () => {
    const { changeConfirmationDialog } = this.props;
    changeConfirmationDialog({ open: false });
  }

  handleInformationOnClose = () => {
    const { changeInformationDialog } = this.props;
    changeInformationDialog({ open: false });
  }

  handleValidationOnClose = () => {
    const { changeValidationDialog } = this.props;
    changeValidationDialog({ open: false, messageBoxOpen: false });
  }

  render(): React.ReactNode {
    const {
      confirmationDialog, validationDialog, validationFormValues, eftposDialog, fetStatusLoading, informationDialog,
      isDashboardDialogOpen, isDashboardDefault
    } = this.props;

    return (
      <React.Fragment>
        <AppBar {...this.props} testInstanceName={TestInstanceName} />
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          onCancel={confirmationDialog?.onCancel?.bind(confirmationDialog)}
          onOk={confirmationDialog?.onOk?.bind(confirmationDialog)}
          onClose={this.handleConfirmationOnClose}
          okLabel={confirmationDialog.okLabel}
          cancelLabel={confirmationDialog.cancelLabel}
          showRefuse={confirmationDialog.showRefuse}
          defaultBtnNo={confirmationDialog.defaultBtnNo}
          onRefuse={confirmationDialog?.onRefuse}
          refuseLabel={confirmationDialog.refuseLabel}
          showCancel={confirmationDialog.showCancel}
        />
        <InformationDialog
          open={informationDialog.open}
          title={informationDialog.title}
          message={informationDialog.message}
          onClose={this.handleInformationOnClose}
          okLabel={informationDialog.okLabel}
        />
        <ValidationDialog
          open={validationDialog.open}
          title={validationDialog.title}
          message={validationDialog.message}
          form={validationDialog.form}
          modalContent={validationDialog.modalContent}
          initialValues={validationDialog.initialValues}
          valuesSchema={validationDialog.valuesSchema}
          formValues={validationFormValues}
          actions={validationDialog.actions}
          onClose={this.handleValidationOnClose}
          refreshAction={validationDialog.refreshAction}
          pollingTimeoutMilliseconds={validationDialog.pollingTimeoutMilliseconds}
        />
        <MessageBoxValidationDialog
          open={validationDialog.messageBoxOpen}
          title={validationDialog.title}
          message={validationDialog.message}
          icon={validationDialog.icon}
          color={validationDialog.color}
          form={validationDialog.form}
          modalContent={validationDialog.modalContent}
          initialValues={validationDialog.initialValues}
          valuesSchema={validationDialog.valuesSchema}
          formValues={validationFormValues}
          actions={validationDialog.actions}
          actionsCallback={validationDialog.actionsCallback}
          onClose={this.handleValidationOnClose}
          refreshAction={validationDialog.refreshAction}
          pollingTimeoutMilliseconds={validationDialog.pollingTimeoutMilliseconds}
          enableCloseOnOutsideClick={validationDialog.enableCloseOnOutsideClick}
        />
        <DashboardMaintenance
          open={isDashboardDialogOpen}
          isDefaultDashboard={isDashboardDefault}
          onClose={this.handleDashboardModalClose}
          onCloseSuccess={this.handleDashboardModalCloseSuccess}
        />
        {/* <SnackBar /> */}
        {eftposDialog.open && <EFTPOSModal
          open={eftposDialog.open}
          loading={fetStatusLoading}
          data={eftposDialog.data}
          disableOk={eftposDialog.disableOk}
          disableClose={eftposDialog.disableClose}
          onOk={this.handleEftposOnOk}
          onClose={this.handleEftposOnClose}
        />}
      </React.Fragment>
    );
  }
}

export default Layout;
