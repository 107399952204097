import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as salesOrdersSelector, actions as salesOrdersAction } from 'ducks/customerEnquiry/salesOrders';
import { selectors as customerSelector } from 'ducks/customer';
import { fetch } from 'api/customerEnquiry/salesOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search)

export const mapStateToProps = (state) => ({
  gridName: 'SalesOrders',
  columnDefs: salesOrdersSelector.Columns(state),
  rowData: salesOrdersSelector.Data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: salesOrdersSelector.gridOptions(state),
  filterRow: salesOrdersSelector.filterRow(state),
  selectedFilters: salesOrdersSelector.selectedFilters(state),
  isLoading: salesOrdersSelector.isLoading(state),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  appliedFilters: salesOrdersSelector.filterRow(state) && filterSelectors.getFilters(state, salesOrdersSelector.filterRow(state).formName),
  apiMethod: fetch
});

export const actions = {
  deleteSelectedRecords: salesOrdersAction.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: salesOrdersAction.setSelected,
  applyFilters: filterActions.applyFilters
}
