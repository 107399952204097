import * as React from 'react';
import FormView from '../../FormView/index';
import SummaryPanel from '../SummaryPanel/index';
import FutureBreaks from '../FutureBreaks';
import PriceBreaks from '../PriceBreaks';
import { IPricingProps } from '../interfaces';
import BreakLevelsRenderer from '../BreaksTable';
class Pricing extends React.PureComponent<IPricingProps> {
  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    const context = 'inventory-enquiry';
    if (path) {
      switch (path) {
        case `/${context}/pricing/prices`:
          onInitialLoad('Prices');
          break;
        case `/${context}/pricing/foreign-prices`:
          onInitialLoad('ForeignPrices');
          break;
        case `/${context}/pricing/price-matrix`:
          onInitialLoad('PriceMatrix');
          break;
        case `/${context}/pricing/schedule`:
          onInitialLoad('Schedule');
          break;
        case `/${context}/pricing/future-breaks`:
          onInitialLoad('FutureBreaks');
          break;
        case `/${context}/pricing/price-breaks`:
          onInitialLoad('PriceBreaks');
          break;
        default:
          onInitialLoad('Prices');
      }
    }
  }

  render(): React.ReactNode {
    const
      { isBrowseLookUpOpen, selectedForm,
        operationMode, selectedTab, pricesSummary
      } = this.props;

    return (<React.Fragment>
      <SummaryPanel
        variant={'Pricing'}
        pricesSummary={pricesSummary}
      />
      {(selectedTab === 'FutureBreaks' &&
        <FutureBreaks
        />) ||
        (selectedTab === 'PriceBreaks' &&
          <PriceBreaks
          />) ||
        (selectedForm && <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={null}
          operationMode={operationMode}
          enableReinitialize={true}
          valuesSchema={null}
          fieldExtensions={
            {
              inventoryEnquiryPrices: {
                masterDetailRenderer: (detailProps) => {
                  return <BreakLevelsRenderer data={detailProps.data.row.data} />;
                }
              }
            }
          }
        />)}
    </React.Fragment>
    );
  }
}

export default Pricing;
