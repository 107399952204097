import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        'height': '210px',
        'width': '100%',
        'display': 'flex',
        'marginBottom': '180px',
        'gap': '10px',
        'marginTop': 10,
        '@media screen and (max-width: 1100px)': {
            flexDirection: 'column',
        }
    },
    paperContainer: {
        width: 'fit-content',
        maxWidth: 'calc(100% - 10px)',
    },
    productGrid: {
        'width': '100%',
        'overflow': 'auto',
        'flexGrow': 1,
        'boxSizing': 'border-box',
        '& .dx-widget': {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
        },
    },
    productImageDiv: {
        'padding': '10px',
        '& div div div': {
            '@media screen and (max-width: 1100px)': {
                width: '250px',
            }
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none'
        }
    },
    productDataGrid: {
        'height': '100%',
        '& .dx-datagrid-headers .dx-row > td ': {
            outline: 'unset !important'
        },

    },
    productHeaderFilter: {
        '& span': {
            color: '#959595 !important',
            cursor: 'pointer',
        }
    },
    grid: {
        marginTop: '10px'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledLineDetailIcon: {
        cursor: 'default',
        fill: 'grey'
    },
});
