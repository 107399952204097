
export const MODULE_TREE = [
    { id: 'OrderDetails', label: 'Order Details', parent: '' },
    { id: 'InTransitLocalShipments', label: 'In Transit - Local Shipments', parent: '' },
    { id: 'InTransitImportShipments', label: 'In Transit - Import Shipments', parent: '' },
    { id: 'LDInTransitLocalShipments', label: 'In Transit - Local Shipments', parent: 'LineDetails' },
    { id: 'LDInTransitImportShipments', label: 'In Transit - Import Shipments', parent: 'LineDetails' },
    { id: 'OrderLines', label: 'Order Lines', parent: '' },
    { id: 'LineDetails', label: 'Line Details', parent: 'OrderLines' },
    { id: 'LDCommittedSalesOrder', label: 'Sales Orders', parent: 'LineDetails' },
    { id: 'LDCommittedProductionOrder', label: 'Production Orders', parent: 'LineDetails' },
    { id: 'LDCommittedServiceOrder', label: 'Service Orders', parent: 'LineDetails' },
    { id: 'SOEBOM', label: 'Bill of Materials', parent: 'LineDetails' },
    { id: 'ReservationDetails', label: 'Reservation Details', parent: 'LineDetails' },
    { id: 'BranchOrders', label: 'Branch Orders', parent: '' },
    { id: 'LDBranchOrders', label: 'Branch Orders', parent: 'LineDetails' },
    { id: 'ProductReceipts', label: 'Product Receipts', parent: '' },
    { id: 'CommittedSaleOrder', label: 'Committed Sale Orders', parent: '' },
    { id: 'CommittedProductionOrder', label: 'Committed Production Orders', parent: '' },
    { id: 'CommittedServiceOrders', label: 'Committed Service Orders', parent: '' },
    { id: 'Notepad', label: 'Notepad', parent: '' },
    { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' }
];
