import { Button as MaterialButton, withStyles, Checkbox } from '@material-ui/core';
import React from 'react';
import styles from '../NewJob.styles';
import { IUpcomingServiceAgreementPanelProps } from '../NewJobModal.properties';
import DateTextField from 'components/FormView/Fields/DateField';
import DataGrid, { Column, Scrolling, LoadPanel, Export } from 'devextreme-react/data-grid';
import { NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH } from '../NewJobModal.constants';
import lodash from 'lodash';
import { isNull } from 'utils/utils';
import ExcelJS from 'exceljs/dist/exceljs';
import saveAs from 'file-saver';
import { ExcelDataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import moment from 'moment';

// tslint:disable: no-magic-numbers

function UpcomingServiceAgreementPanel(props: IUpcomingServiceAgreementPanelProps): JSX.Element {
    const {
        classes,
        loading,
        data,
        serviceDate: serviceDateProp,
        onSearch,
        setData
        // forwardedRef,
    } = props;

    const dataGridRef = React.useRef<DataGrid>();
    const [serviceDate, setServiceDate] = React.useState<string>('');

    const selectedRowKeys = data && data.filter((sa) => sa.Checked).map((sa) => sa.ServiceAgreementScheduleId);

    React.useEffect(
        () => {
            const formattedDate = isNull(serviceDateProp) ? '' : moment(serviceDateProp, 'YYYY/MM/DD').format('DD/MM/YYYY');
            setServiceDate(formattedDate);
        },
        [serviceDateProp]
    );

    React.useEffect(
        () => {
            if (dataGridRef.current) {
                if (loading) {
                    dataGridRef.current.instance.beginCustomLoading('');
                } else {
                    dataGridRef.current.instance.endCustomLoading();
                }
            }
        },
        [loading]
    );

    function itemOnChecked(rowData: any): void {
        const updatedJobs = lodash.cloneDeep(data);

        const allowed = !updatedJobs.some((job) => job.CustomerId !== rowData.data.CustomerId && job.Checked);

        if (allowed) {
            const currentAgreement = updatedJobs.find((s) => s.ServiceAgreementScheduleId === rowData.data.ServiceAgreementScheduleId);
            currentAgreement.Checked = !currentAgreement.Checked;
        }

        setData(updatedJobs);
    }

    function groupItemOnChecked(e: any, rowData: any): void {
        const updatedJobs = lodash.cloneDeep(data);
        const items = (rowData.data.items || rowData.data.collapsedItems);

        const allowed = !updatedJobs.some((job) => job.CustomerId !== rowData.value && job.Checked);

        if (allowed) {
            if (items) {
                items.forEach((item) => {
                    const job = updatedJobs.find((j) => j.ServiceAgreementScheduleId === item.ServiceAgreementScheduleId);
                    job.Checked = e.target.checked;
                });
            }
        }

        setData(updatedJobs);
    }

    function GroupCellComponent({ data: rowData }: any): JSX.Element {

        const items = (rowData.data.items || rowData.data.collapsedItems);
        const firstJob = lodash.first(items) as any;
        const allChildrenChecked = data && data.filter((s) => s.CustomerId === rowData.value).every((item) => item.Checked);

        return (<div className={classes.UpcomingGroupCellComponentContainer}>
            <Checkbox checked={allChildrenChecked} style={{ padding: 0 }} onChange={(e) => rowData && groupItemOnChecked(e, rowData)} />
            <div>
                <div >{firstJob.CustomerName}</div>
            </div>
        </div>);
    }

    function customizeExcelCell({ gridCell, excelCell }: { gridCell?: ExcelDataGridCell; excelCell?: any }): void {

        if (!gridCell) {
            return;
        }

        if (gridCell.rowType === 'group' && gridCell.column.dataField === 'CustomerId') {
            const job = data.find((j) => j.CustomerId === gridCell.value) || {} as any;
            excelCell.value = job.CustomerName;
        }
    }

    function onExporting(e: any): void {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: customizeExcelCell
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Upcoming Service Agreements.xlsx');
            });
        });
        e.cancel = true;
    }

    return (
        <div className={classes.serviceAgreementItemContainer}>
            <div className={`${classes.fullWidth} ${classes.heading}`}>Select a Customer and Service items you want to create a job for.</div>
            <div className={`${classes.serviceDueContainer} ${classes.fullWidth}`}>
                <DateTextField
                    required
                    label={'Service due before'}
                    value={serviceDate}
                    onChange={setServiceDate}
                    size={'medium'}
                />
                <MaterialButton variant={'contained'} onClick={() => data && onSearch && onSearch(serviceDate)}>Search</MaterialButton>
            </div>
            <DataGrid
                style={styles.fullWidth}
                keyExpr={'ServiceAgreementScheduleId'}
                ref={dataGridRef}
                height={470}
                dataSource={data || []}
                repaintChangesOnly={true}
                columnResizingMode={'nextColumn'}
                allowColumnResizing={true}
                allowColumnReordering={true}
                noDataText=''
                showBorders={false}
                onExporting={onExporting}
                sorting={{
                    mode: 'none'
                }}
                selectedRowKeys={selectedRowKeys}
            >
                <Scrolling mode='virtual' />
                <Column
                    caption={''}
                    allowExporting={false}
                    dataField={'Checked'}
                    alignment={'center'}
                    cellRender={(d) => {

                        return (<Checkbox checked={Boolean(d.value)} style={{ padding: 0 }} onChange={() => data && itemOnChecked(d)} />);
                    }}
                    minWidth={'7%'}
                />
                <Column
                    dataField={'CustomerId'}
                    groupIndex={0}
                    groupCellComponent={GroupCellComponent}
                />
                <Column
                    caption={'Customer / Service Item'}
                    calculateCellValue={(d) => `${d.CustomerIdDisplay} - ${d.ServiceDescription}`}
                    minWidth={'38%'}
                />
                <Column
                    caption={'Next Service Job'}
                    dataField={'TemplateDescription'}
                    minWidth={'35%'}
                />
                <Column
                    caption={'Next Service'}
                    dataType={'date'}
                    dataField={'ServiceDate'}
                    minWidth={'20%'}
                />
                <Export enabled={!isNull(data)} />
                <LoadPanel shading={false} height={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} width={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
        </div>
    );
}

export default withStyles(styles, { index: 1 })(UpcomingServiceAgreementPanel);
// tslint:enable: no-magic-numbers
