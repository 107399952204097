import LookupService from 'utils/api_services/LookupService';
import { fetchGet, fetchPost } from './utils';

export const fetch = (apiPrefs) => {
  const { SearchText } = apiPrefs.params ? apiPrefs.params : '';
  switch (apiPrefs.method) {
    case 'GET':
      let url = apiPrefs.path
      return fetchGet(url)
        .then((something) => {
          return ({
            ...something,
            Data: something.Data.filter((d) =>
              !SearchText ? true
                : d.Label.toLowerCase()
                  .includes(SearchText.toLowerCase())
                || d.Code.toLowerCase()
                  .includes(SearchText.toLowerCase()))
            ,
            lookupId: apiPrefs.path
          })
        })
    case 'POST':
      return fetchPost(apiPrefs.path, { ...apiPrefs.params, SearchText: SearchText, BatchPage: 1 }, '', 'None', 'metadataResponse')
        .then((something) => {
          return ({
            ...something,
            Data: something.Data,
            lookupId: apiPrefs.path
          })
        })
    default:
      break;
  }
}

export const dynamicFetch = ({ api, name }) => {
  switch (api) {
    case '/customermaintenance/salesterritory':
      return new LookupService().getSalesTerritories();
    case '/customermaintenance/salescategory':
      return new LookupService().getSalesCategories();
    case '/customermaintenance/ordercategory':
      return new LookupService().getOrderCategories();
    case '/customermaintenance/freightcode':
      return new LookupService().getFreightCodes();
    case '/customermaintenance/salesrep':
      return new LookupService().getSalesReps();
    case '/customermaintenance/salesentity':
      return new LookupService().getSalesEntities();
    case '/customermaintenance/internalwarehouse':
      return new LookupService().getInternalWarehouses();
    case '/customermaintenance/taxclass':
      return new LookupService().getTaxClasses();
    case '/customermaintenance/currency':
      return new LookupService().getCurrencies();
    case '/customermaintenance/creditType':
      return new LookupService().getCreditTypes();
    case '/customermaintenance/customerpricecode':
      return new LookupService().getCustomerPriceCodes();
    case '/customermaintenance/defaultwarehouse':
      return new LookupService().getDefaultWarehouses();
    case '/customermaintenance/lookup/action': {
      const Options = new LookupService().getActionList(name);
      return {
        api: '/customermaintenance/lookup/action/' + name,
        Options
      }
    }
    case '/salesOrderEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('saleOrder');
      return {
        api: '/salesOrderEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/salesInvoiceEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('salesInvoiceEnquiry');
      return {
        api: '/salesInvoiceEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/customerenquiry/lookup/action': {
      const Options = new LookupService().getActionList('enquiry');
      return {
        api: '/customerenquiry/lookup/action/' + name,
        Options
      }
    }
    case '/inventoryEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('enquiry');
      return {
        api: '/inventoryEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/priceCheckToInv/lookup/action': {
      const Options = new LookupService().getActionList('priceToInventory');
      return {
        api: '/priceCheckToInv/lookup/action/' + name,
        Options
      }
    }
    case '/customerEnquiry/lookup/action': {
      const Options = new LookupService().getActionList(name);
      return {
        api: '/customerEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/priceCheck/lookup/action': {
      const Options = new LookupService().getActionList(name);
      return {
        api: '/priceCheck/lookup/action/' + name,
        Options
      }
    }
    case '/salesprocessing/lookup/action': {
      const Options = new LookupService().getActionList('salesprocessing');
      return {
        api: '/salesprocessing/lookup/action/' + name,
        Options
      }
    }
    case '/salesprocessingprice/lookup/action': {
      const Options = new LookupService().getActionList('salesprocessingprice');
      return {
        api: '/salesprocessingprice/lookup/action/' + name,
        Options
      }
    }
    case '/supplierEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('supplier');
      return {
        api: '/supplierEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/supplierEnquiryToCustomer/lookup/action': {
      const Options = new LookupService().getActionList('BillToId');
      return {
        api: '/supplierEnquiryToCustomer/lookup/action/' + name,
        Options
      }
    }
    case '/supplierInvoiceEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('supplierInvoice');
      return {
        api: '/supplierInvoiceEnquiry/lookup/action/' + name,
        Options
      }
    }
    case '/rfcEnquiry/lookup/action': {
      const Options = new LookupService().getActionList('rfc');
      return {
        api: '/rfcEnquiry/lookup/action/' + name,
        Options
      }
    }
    default:
      return null;
  }
}