import { IFormViewForm } from 'components/FormView';

const SlipDetails: IFormViewForm = {
  id: 'SlipDetails',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1,
        colspan: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          group: {
          childern: [
          {
            id: 0,
            type: 'EX_LOOKUP_FIELD',
            visible: true,
            props: {
              label: 'User Id',
              name: 'UserId',
              size: 'small',
              required: true,
              lookupName: 'Personnel',
            }
          },
          {
            id: 1,
            type: 'DATE_FIELD',
            visible: true,
            props: {
              label: 'Date',
              name: 'Date',
              placeholder: '',
              required: true,
              size: 'small',
            }
          },
          {
            id: 2,
            type: 'TIME_FIELD',
            props: {
              label: 'Time',
              size: 'small',
              name: 'Time',
              required: true,
            }
          },
          {
            id: 3,
            type: 'TOGGLE_FIELD',
            visible: true,
            props: {
              label: 'System Generated',
              disabled: true,
              name: 'SystemGenerated',
              style: {
                width: 'auto',
              }
            }
          },
        ]}},
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Subject',
            name: 'Subject',
            type: 'extended',
            size: 'large',
            required: true,
          }
        },
        {
          id: 3,
          type: 'TEXT_AREA',
          visible: true,
          props: {
            label: '',
            name: 'CommentFull',
            type: 'extended',
            style: {
              height: '400px',
              width: '550px'
            }
          }
        },
      ]
    },
  ]
};

export default SlipDetails;
