import React, { Component, useState } from 'react';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import Subheader from '@markinson/uicomponents-v2/Subheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import Icon from '@markinson/uicomponents-v2/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import Paths from 'components/Paths.json';

const styles = {
  listItem: {
    paddingLeft: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  headerStyle: {
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '25px',
    paddingLeft: 12,
    width: '100%',
    paddingTop: 12
  }
}

const SearchField = {
  paddingLeft: 12,
  width: 280
}

const SearchPanel = ({ programAreas, classes, history }) => {
  const styles = {
    listItem: {
      paddingLeft: 12,
      paddingTop: 6,
      paddingBottom: 6,
    },
    headerStyle: {
      boxSizing: 'border-box',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '25px',
      paddingLeft: 12,
      width: '100%',
      paddingTop: 12
    }
  }
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const filterResults = (filterString) => {
    let resluts = programAreas.filter((item) => {
      return item['Description'].toLowerCase().includes(filterString.toLowerCase())
    });
    resluts = resluts.map(item => ({ ...item, path: Paths[item['ProgramAreaId']] }))
    setSearchResults(resluts)
  }

  const handleChangeField = event => setSearchInput(event.target.value);

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      filterResults(event.target.value);
    }
  }

  const handleItemClick = (item) => item && item.path && history.push(item.path);
  return (
    (<div>
      <TextField
        placeholder="Search..."
        style={SearchField}
        name="Search"
        action={
          { iconName: 'GoIcon', inlineIcon: true, controller: (event) => filterResults(searchInput) }
        }
        value={searchInput}
        onKeyDown={handleKeyDown}
        onChange={handleChangeField}
        autoFocus={true}
      />
      <Subheader>Results</Subheader>
      <List component="nav">
        {searchResults.map((item, idx) => (
          <ListItem button key={idx} className={classes.listItem} onClick={() => handleItemClick(item)}>
            <ListItemText primary={item.Description} />
          </ListItem>
        ))}
      </List>
      <hr />
    </div>)
  )
}

export default withStyles(styles, { index: 1 })(withRouter(SearchPanel));