import * as React from 'react';
import FormView from 'components/FormView';
import { ICashReceiptProps, ICashReceiptState } from '../interfaces';
import { OPERATIONS, Operation } from 'utils/operations';
import { isShallowEqual, pathOr, isNull } from 'utils/utils';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import FormViewModal from 'components/common/Modals/FormViewModal';
import CashReceiptSchema from 'utils/data/forms/cash-receipting/confirm-receipt.json';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class CashReceipt extends React.PureComponent<ICashReceiptProps, ICashReceiptState> {

  constructor(props: ICashReceiptProps) {
    super(props);
    this.state = {
      lastUpdatedValues: {}
    };
  }

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path === '/cash-receipting') {
      onInitialLoad('CashReceipt');
    }
  }

  componentDidUpdate(provProps: ICashReceiptProps): void {
    const { operationMode, changeOperationMode, formValues, deleteCashReceipt } = this.props;

    if (provProps.operationMode !== operationMode) {
      switch (operationMode) {
        case OPERATIONS.ALLOCATE:
          this.onAllocate();
          changeOperationMode(Operation.EDIT);
          break;
        case OPERATIONS.PROCESS:
          this.onProcess();
          changeOperationMode(Operation.EDIT);
          break;
        case OPERATIONS.CANCEL:
          if (formValues && deleteCashReceipt) deleteCashReceipt();
          break;
        default:
          changeOperationMode(operationMode);
      }
    }
  }

  handleOnBlur = (e: any) => {
    if (e.target && e.target.name === 'OverrideDiscountTax') return;
    this.handleChange();

  }

  getChildren = () => {
    const { selectedForm } = this.props;
    const fields = pathOr([], ['fields'], selectedForm);
    let tempChildren = [];

    fields.forEach((field: any) => {
      const children = pathOr([], ['children'], field);
      tempChildren = tempChildren.concat(children);
    });

    return tempChildren;
  }

  fillEmptyDecimalFields = (formValues: any = {}) => {
    const tempChildren = this.getChildren();

    tempChildren.forEach((child: any) => {
      const { props = {} } = child;
      if (props.type === 'number' && isNull(formValues[props.name])) {
        formValues[props.name] = 0;
      }
    });

    return formValues;
  }

  compareFormValues = (lastUpdatedValues: any, formValues: any = {}) => {
    const tempChildren = this.getChildren();
    let hasChanged = false;

    for (const child of tempChildren) {
      if (hasChanged) break;
      const { props = {} } = child;
      const formValue = formValues[props.name];
      const savedValue = lastUpdatedValues[props.name];
      hasChanged = (props.type === 'number') ?
        Number(formValue) !== Number(savedValue) :
        !isShallowEqual(formValue, savedValue);
    }

    return hasChanged;
  }

  checkNegative = (): boolean => {
    const { formValues = {} } = this.props;

    const tempChildren = this.getChildren();
    let hasNegative = false;
    for (const child of tempChildren) {
      if (hasNegative) break;
      const { props = {} } = child;
      if (props.type === 'number' && props.name !== 'ActualReceipt') {
        const formValue = formValues[props.name];
        if (formValue < 0) {
          hasNegative = true;
          showSnackBar({ variant: 'error', message: `${props.label} cannot be negative.` });
        }
      }
    }

    return hasNegative;
  }

  onAllocate = () => {
    const { formValues = {}, allocateCashReceipt } = this.props;
    if (this.checkNegative()) return;
    if (allocateCashReceipt && formValues) allocateCashReceipt(formValues);
  }

  onProcess = () => {
    const { formValues = {}, processCashReceipt, cashReceiptSummary = {}, changeModalVisibility } = this.props;
    if (this.checkNegative()) return;

    if (cashReceiptSummary.Unallocated <= 0) {
      if (processCashReceipt && formValues) processCashReceipt(formValues);
    } else {
      changeModalVisibility({ openProcessConfirmation: true });
    }
  }

  handleChange = (field?: string, checked?: boolean) => {
    const { lastUpdatedValues } = this.state;
    const { updateCashReceipt, formValues } = this.props;
    const updatedFormValues = !isNull(checked) ? { ...formValues, [field]: checked } : formValues;
    if (this.compareFormValues(lastUpdatedValues, updatedFormValues) && updateCashReceipt) {
      const reqFormValues = this.fillEmptyDecimalFields(updatedFormValues);

      updateCashReceipt(reqFormValues);
      this.setState({ lastUpdatedValues: reqFormValues });
    }
  }

  toggleChange = (e: any = {}) => {
    if (e.target) this.handleChange(e.target.name, e.target.checked);
  }

  proceedProcessOnOk = () => {
    const { changeModalVisibility, processCashReceipt, formValues } = this.props;

    if (processCashReceipt && formValues) processCashReceipt(formValues);
    changeModalVisibility({ openProcessConfirmation: false });
  }

  proceedProcessOnCancel = () => {
    const { changeModalVisibility } = this.props;
    changeModalVisibility({ openProcessConfirmation: false });
  }

  cashReceiptOnOk = () => {
    const { confirmCashReceiptProcess, confirmCashReceiptformValues } = this.props;
    if (confirmCashReceiptformValues && confirmCashReceiptProcess) confirmCashReceiptProcess(confirmCashReceiptformValues);
  }

  render(): React.ReactNode {
    const {
      isBrowseLookUpOpen, selectedTab, cashReceipt, selectedForm, operationMode,
      summaryTableRenderer, entity, isLoading, CustomerId, openProcessConfirmation,
      openConfirmReceipt, changeModalVisibility, docOutputOptions, confirmReceiptLoading
    } = this.props;
    const valuesSchema = cashReceipt && cashReceipt.schema;
    const initialValues = cashReceipt && cashReceipt.inlineObject;

    return (selectedForm &&
      <React.Fragment>
        <ConfirmationDialog
          open={openProcessConfirmation}
          title={'Unallocated Amount'}
          message={'There is an unallocated amount. Continue?'}
          okLabel='Yes'
          cancelLabel='No'
          onCancel={this.proceedProcessOnCancel}
          onOk={this.proceedProcessOnOk}
        />
        <FormViewModal
          open={openConfirmReceipt}
          loading={confirmReceiptLoading}
          title='Confirm Receipt'
          formProps={{
            style: { height: '150px' },
            formSchema: CashReceiptSchema,
            initialValues: pathOr({}, ['inlineObject'], docOutputOptions),
            valuesSchema: pathOr({}, ['schema'], docOutputOptions),
            enableReinitialize: true,
            customerId: CustomerId
          }}
          actions={[
            {
              title: 'CANCEL',
              listener: () => { changeModalVisibility({ openConfirmReceipt: false }); }
            },
            {
              title: 'OK',
              listener: this.cashReceiptOnOk
            },
          ]}
        />
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          includeTabsHeight={false}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          initialValues={initialValues}
          valuesSchema={valuesSchema}
          customerId={Number(CustomerId)}
          entity={entity}
          onBlur={this.handleOnBlur}
          operationMode={operationMode}
          summaryTableRenderer={summaryTableRenderer}
          fieldExtensions={({
            OverrideDiscountTax: {
              onChange: this.toggleChange,
            },
            InvoiceNumber: {
              onSelectedValueChange: this.handleChange
            },
            CustomerSalesOrder: {
              onSelectedValueChange: this.handleChange
            }
          })}
        />
      </React.Fragment>
    );
  }
}

export default CashReceipt;
