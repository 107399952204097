import React from 'react';
import DataGridDevEx from 'components/common/DataGridDevEx';

const MasterDetailRowRenderer = (props) => {
    const { detailColumnsParams, data = {} } = props;
    const { getDetailRowData, detailGridOptions } = detailColumnsParams;
    const gridData = getDetailRowData ? getDetailRowData(data.row) || {} : {};

    return (
        <DataGridDevEx style={{ height: 'unset' }} rowData={gridData} columnDefs={detailGridOptions.columnDefs} dataGridProps={{ showBorders: true }} />
    );
};

export default MasterDetailRowRenderer;
