import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BreaksTable from '../BreaksTable/index';
import { PaperElevation } from '../../../utils/constants';
import { IPriceBreaksProperties } from '../interfaces';

const styles = createStyles({
  paper: {
    padding: '15px',
    margin: '15px',
    height: '100%',
  },
  mainPanel: {
    padding: '15px',
    margin: '15px',
    backgroundColor: '#ffffff',
    height: 'calc(80vh - 64px)',
    width: '76%',
  },

});

class PriceBreaks extends React.PureComponent<IPriceBreaksProperties> {
  componentDidMount(): void {
    this.fetchBreaks();
  }

  componentDidUpdate(prevProps: IPriceBreaksProperties): void {
    if (prevProps.selectedProduct !== this.props.selectedProduct) {
      this.fetchBreaks();
    }
  }

  fetchBreaks = (): void => {
    const { selectedProduct, getBreaks } = this.props;
    if (selectedProduct && selectedProduct.ProductId && getBreaks) {
      getBreaks(selectedProduct.ProductId);
    }
  }

  render(): React.ReactNode {
    const { classes, breaksData } = this.props;

    return (
      <Paper elevation={PaperElevation} className={classes.mainPanel} >
        <BreaksTable
          data={breaksData}
        />
      </Paper>
    );
  }
}

export default withStyles(styles, {index: 1})(PriceBreaks);
