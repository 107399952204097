import InvoiceDetails from 'utils/data/forms/sales_invoice_enquiry/InvoiceDetails'
import InvoiceLines from 'utils/data/forms/sales_invoice_enquiry/InvoiceLines'
import InvoiceLineDetails from 'utils/data/forms/sales_invoice_enquiry/InvoiceLineDetails'
import PickSlipsByDespatch from 'utils/data/forms/sales_invoice_enquiry/pickSlipByDespatch'
import PickSlipsByProduct from 'utils/data/forms/sales_invoice_enquiry/pickSlipByProduct'
import Payment from 'utils/data/forms/sales_invoice_enquiry/Payment'
import PaymentDetails from 'utils/data/forms/sales_invoice_enquiry/PaymentDetails'
import InvoiceLineBOM from 'utils/data/forms/sales_invoice_enquiry/InvoiceLineBOM'
import InvoiceLineBOMComponent from 'utils/data/forms/sales_invoice_enquiry/InvoiceLineBOMComponent'
import InvoiceLineSerials from 'utils/data/forms/sales_invoice_enquiry/InvoiceLineSerials'
import InvoiceLineBOMSerials from 'utils//data/forms/sales_invoice_enquiry/InvoiceLineBOMSerials'
import InvoiceLineLots from 'utils//data/forms/sales_invoice_enquiry/InvoiceLineLots'
import InvoiceLineBOMComponentLots from 'utils/data/forms/sales_invoice_enquiry/InvoiceLineBOMLots'
import InvoiceComments from './../data/forms/sales_invoice_enquiry/Comments'
import InvoiceInternalComments from './../data/forms/sales_invoice_enquiry/InternalComments'
import SalesAudit from 'utils/data/forms/sales_invoice_enquiry/SalesAudit'
import AuditDetails from './../data/forms/sales_invoice_enquiry/InvoiceAuditDetails'
import Events from './../data/forms/sales_invoice_enquiry/Events'
import Diary from './../data/forms/sales_invoice_enquiry/Diary'
import DiaryDetails from './../data/forms/sales_invoice_enquiry/DiaryDetails'
import NotepadForm from '../data/forms/sales_invoice_enquiry/Notepad'

const getFormSchema = (formId) => {
  switch (formId) {
    case 'InvoiceDetails':
      return InvoiceDetails
    case 'InvoiceLines':
      return InvoiceLines
    case 'InvoiceLineDetails':
      return InvoiceLineDetails
    case 'PickSlipsByDespatch':
      return PickSlipsByDespatch
    case 'PickSlipsByProduct':
      return PickSlipsByProduct
    case 'Payment':
      return Payment
    case 'PaymentDetails':
      return PaymentDetails
    case 'InvoiceLineBOM':
      return InvoiceLineBOM
    case 'InvoiceLineBOMComponent':
      return InvoiceLineBOMComponent
    case 'InvoiceLineSerials':
      return InvoiceLineSerials
    case 'InvoiceLineBOMComponentSerials':
      return InvoiceLineBOMSerials
    case 'InvoiceLineLots':
      return InvoiceLineLots
    case 'InvoiceLineBOMComponentLots':
      return InvoiceLineBOMComponentLots
    case 'Comments':
      return InvoiceComments
    case 'InternalComments':
      return InvoiceInternalComments
    case 'SalesAudit':
      return SalesAudit
    case 'SalesAuditDetails':
      return AuditDetails
    case 'Events':
      return Events
    case 'SalesDiary':
      return Diary
    case 'DiaryDetails':
      return DiaryDetails
    case 'Notepad':
      return NotepadForm
    default:
      return InvoiceDetails
  }
}

export default getFormSchema
