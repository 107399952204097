import {
  createActions, asyncInitialState, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
  }
}, 'matrix');


const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  columns: [
    { headerName: 'Customer price code', field: 'CustomerPriceCode', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'CustomerPriceCodeLabel', minWidth: 200, suppressSorting: true, },
    { headerName: 'Percentage', field: 'PercentageDisplay', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Type', field: 'PriceType', minWidth: 100, suppressSorting: true, },
    { headerName: 'Nett Price', field: 'NettPriceDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Resident', field: 'ResidentDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'GP', field: 'GrossProfitPercentDisplay', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Special', field: 'IsSpecialDisplay', minWidth: 100, suppressSorting: true, },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.priceCheck.matrix,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.priceCheck.matrix.loading,
  gridOptions: state => state.priceCheck.matrix.data.gridOptions,
  columns: state => state.priceCheck.matrix.data.columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)