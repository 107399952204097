import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from '../NewJob.styles';
import { IActivitiesPanelHandle, IActivitiesPanelProps, IAddActivityData } from '../NewJobModal.properties';
import { NEW_JOB_DATA_GRID_HEIGHT, NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH } from '../NewJobModal.constants';
import DataGrid, { Button, Column, Scrolling, LoadPanel, Editing } from 'devextreme-react/data-grid';
import { AddActivityModal } from './AddActivityModal';
import { isNull } from 'utils/utils';
import { cloneDeep } from 'lodash';

function ActivitiesPanel(props: IActivitiesPanelProps, ref: React.Ref<IActivitiesPanelHandle>): JSX.Element {
    const {
        classes,
        data,
        CustomerId,
        setData,
        changeConfirmationDialog,
    } = props;

    const dataGridRef = React.useRef<DataGrid>();
    const [addActivityModalOpen, setAddActivityModalOpen] = React.useState<boolean>(false);
    const [activityModalEditMode, setActivityModalEditMode] = React.useState<boolean>(false);
    const [addActivityData, setAddActivityData] = React.useState<IAddActivityData>(null);

    React.useImperativeHandle(
        ref,
        () => ({
            AddActivity(): void {
                setAddActivityModalOpen(true);
                setAddActivityData(null);
            }
        })
    );

    function onEditClicked(rowData: any): void {
        setAddActivityModalOpen(true);
        setActivityModalEditMode(true);
        setAddActivityData(rowData.row.data);
    }

    function handleAddUpdateActivity(activityData: IAddActivityData): void {
        if (!isNull(activityData)) {

            if (activityModalEditMode) {
                const dataIndex = data.findIndex((a) => a.Id === activityData.Id);
                const clonedData = cloneDeep(data);
                clonedData.splice(dataIndex, 1, activityData);
                setData(clonedData);
            } else {

                const newId = isNull(data) ? 1 : Math.max(...data.map((d) => d.Id)) + 1;
                setData([...data, { ...activityData, Id: newId }]);
            }
        }

        setAddActivityModalOpen(false);
        setActivityModalEditMode(false);
    }

    const deleteClick = ({row}) => {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the selected record?',
            okLabel: 'Delete',
            onCancel: () => null,
            onOk: () => {
                const updatedActivityItemsData = data.filter((activityItem) => activityItem.Id !== row.data.Id);
                setData(updatedActivityItemsData);
            }
        });
    };

    return (
        <div className={classes.serviceAgreementItemContainer}>
            <div className={`${classes.fullWidth} ${classes.heading}`}>Add any additional activities that are required.</div>
            <DataGrid
                style={styles.fullWidth}
                keyExpr={'Id'}
                ref={dataGridRef}
                height={NEW_JOB_DATA_GRID_HEIGHT}
                dataSource={data}
                repaintChangesOnly={true}
                allowColumnReordering={true}
                remoteOperations={true}
                columnResizingMode={'nextColumn'}
                allowColumnResizing={true}
                noDataText=''
                showBorders={false}
                sorting={{
                    mode: 'none'
                }}
                hoverStateEnabled={true}
            >
                <Editing
                    refreshMode={'reshape'}
                    mode='row'
                    allowAdding={false}
                    allowDeleting={true}
                    confirmDelete={true}
                    allowUpdating={false}
                />
                <Scrolling mode='virtual' />
                <Column
                    caption={'Service Item'}
                    calculateCellValue={(d) => isNull(d.ServiceNoDisplay) ? 'No ServiceItem' : `${d.ServiceNoDisplay} - ${d.ServiceNoDisplayLabel}`}
                    alignment={'left'}
                    minWidth={'45%'}
                    fixed={true}
                >
                </Column>
                <Column
                    caption={'Activities'}
                    calculateCellValue={(d) => `${d.ActivityCode} - ${d.Description}`}
                    minWidth={'45%'}
                >
                </Column>
                <LoadPanel shading={false} height={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} width={NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
                <Column type={'buttons'} width={'10%'} allowResizing={false}>
                    <Button icon={'edit'} onClick={onEditClicked} />
                    <Button name={'delete'} icon={'trash'} onClick={deleteClick} />
                    <Button name={'undelete'} icon={'undo'} />
                </Column>
            </DataGrid>
            {addActivityModalOpen &&
                <AddActivityModal
                    open={addActivityModalOpen}
                    CustomerId={CustomerId}
                    data={addActivityData}
                    editMode={activityModalEditMode}
                    handleClose={handleAddUpdateActivity}
                />}
        </div>
    );
}

export default withStyles(styles, { index: 1 })(React.forwardRef(ActivitiesPanel));
