export const MODULE_TREE = [
  { id: 'ActivityDetails', label: 'Activity Details', parent: '' },
  { id: 'Checklists', label: 'Checklists', parent: '' },
  { id: 'Capabilities', label: 'Capabilities', parent: '' },
];

export const TABS_DATA = [
  { id: 'ActivityDetails', label: 'Activity Details', parent: '' },
  { id: 'Checklists', label: 'Checklists', parent: '' },
  { id: 'Capabilities', label: 'Capabilities', parent: '' },
];
