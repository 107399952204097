import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from '../../utils'

export const search = (query) => {
  const { ProductId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchPost(`/InventoryEnquiry/Sales/ServiceOrders/Product/${ProductId}`,
    { ...filters }, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    'Inline')
    .then((result) => result && result.ServiceOrders &&
      ({
        records: result.ServiceOrders.map(item => objectify(item, null, 'Inline')),
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      }))
}
