import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';

export const { types, actions } = createActions({
  saveSearch: (query) => ({query}),
  deleteSearch: (index) => ({index}),
  asyncs: {
    search: (query) => ({ query }),
  }
}, 'searchPanel');

let initialState = asyncInitialState({
  list: null,
  saved: ['Purchase', 'Sales', 'Report']
});

export default (state = initialState, action) => {
  let prevSaved =  state.data.saved;
  switch (action.type) {
    case types.deleteSearch:
     
      prevSaved.splice(action.data.index, 1);
      return {
        ...state,
        data: {
          ...state.data, saved: prevSaved
        }
      };  
    case types.saveSearch:
      if (!state.data.saved.find((val) => val.toLowerCase() === action.data.query.toLowerCase()))
      {
        prevSaved.push(action.data.query);
      }
      return {
        ...state,
        data: {
          ...state.data, saved: prevSaved
        }
      };  
    case types.search:
      return asyncOnRequest(state, action);
    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const results = action.payload;
        return {
          ...data,
          list: results,
          saved: state.data.saved
        };
      })

    case types.saga.search.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.searchPanel,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list).filter((value, index, self) =>
    index === self.findIndex((t) => (
  t.label === value.label && t.route === value.route
  ))
  )
  }
)

const syncSelector = {
  saved: (state) => state.searchPanel.data.saved
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)