import {
    asyncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {
    ISortOrder,
    IChecklistDetailsFacade,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';

export interface IChecklistData {
    checklistData: { inlineObject: IChecklistDetailsFacade; schema: Inline<IChecklistDetailsFacade> };
    selected: string;
    list: IChecklistDetailsFacade[];
    schemas: Inline<IChecklistDetailsFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IChecklistState extends IExtendedState<IChecklistData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
    create_loading?: boolean;
    update_loading?: boolean;
    delete_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: IChecklistData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    checklistData: { inlineObject: {}, schema: {} },
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'checklist',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (checklistCode: string) => checklistCode,
            callback: (data: IChecklistData, checklistCode: string): IChecklistData => ({ ...data, selected: checklistCode })
        },
        reset: {
          action: () => null,
          callback: (data: IChecklistData): IChecklistData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
            callback: (data: IChecklistData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IChecklistData, payload: { records: IObjectified<IChecklistDetailsFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IChecklistData => {
                const checklists = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: checklists,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        searchById: {
            action: (checklistCode: string) => ({ ChecklistCode: checklistCode }),
            successCallback: (data: IChecklistData, payload: { records: IObjectified<IChecklistDetailsFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IChecklistData => {
                const checklists = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: checklists,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IChecklistData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IChecklistData, payload: { records: IObjectified<IChecklistDetailsFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IChecklistData => {
                const checklists = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newRemoval = payload.records.length;
                const newList = data.list.concat(checklists);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },
        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IChecklistData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IChecklistData, payload: { records: IObjectified<IChecklistDetailsFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IChecklistData => {
                const checklists = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newList = checklists.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },

    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ChecklistCode === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ChecklistCode.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
        checklistData: (data) => data.checklistData,
    }
);

const syncSelector = {
    ChecklistCode: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.data.selected,
    isLoading: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.search_loading || state.checklistMaintenance.checklist.searchById_loading,
    loadingNextPage: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.fetchNextPage_loading,
    loadingPrevPage: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.fetchPrevPage_loading,
    pageSize: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.data.pageSize,
    totalPages: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.data.totalPages,
    notifications: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.notifications,
    error: (state: { checklistMaintenance: { checklist: IChecklistState } }) => state.checklistMaintenance.checklist.error,
};

export const selectors = { ...asyncSelector, ...syncSelector };
