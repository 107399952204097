import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from './../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
  }
}, 'alternates');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    rowModelType: 'serverSide',
    disableExcelExport: false
  },
  Columns: [
    { headerName: 'Alternate product', field: 'AlternateProductCode', cellRenderer: 'agGroupCellRenderer', minWidth: 150 },
    { headerName: 'Group', field: 'AlternateProductGroup' },
    { headerName: 'Group description', field: 'GroupDescription' },
    { headerName: 'On file', field: 'OnFileDisplay' },
    { headerName: 'Available', field: 'AvailableQuantityDisplay', type: 'numericColumn' },
    { headerName: 'Description', field: 'Description' },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
 
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.associations.alternates,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.associations.alternates.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.associations.alternates.data.gridOptions,
  columns: state => state.inventoryEnquiry.associations.alternates.data.Columns,
  detailCellRenderer: state => state.inventoryEnquiry.associations.alternates.data.detailCellRenderer,
  detailColumns: state => state.inventoryEnquiry.associations.alternates.data.detailCellRendererParams,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)