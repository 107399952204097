import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from 'devextreme-react/tooltip';
import classNames from 'classnames';

import { IProductCellProps } from './ProductCell.properties';
import styles from './ProductCell.styles';

function ProductCell(props: Readonly<IProductCellProps>): JSX.Element {
  const { data, classes, urlParamKeys, toLink, hideIcon = false } = props;
  const { displayValue } = data;
  const { data: rowData } = data;

  const [alertHover, setAlertHover] = React.useState(false);
  const [alertClick, setAlertClick] = React.useState(false);

  const warningIconRef = React.useRef<HTMLElement>();

  const RenderInfoButton = (): JSX.Element => {

    function appendParams(path: string): string {
      const search = new URLSearchParams();
      urlParamKeys.forEach((paramKey) => {
        if (rowData.hasOwnProperty(paramKey)) {
          search.append(paramKey, rowData[paramKey]);
        }
      });
      path += `?${search.toString()}`;

      return path;
    }

    const handleOnClick = () => {
      window.open(appendParams(toLink));
    };

    return (
      <i
        className={classNames('dx-icon-info', classes.infoIcon)}
        onClick={handleOnClick}
        hidden={hideIcon}
      />);
  };

  return (
    <React.Fragment>
      <div className={classes.mainDiv}>
        {displayValue}
        <div className={classes.iconDiv}>
          {(rowData.AlertMessage || rowData.StatusMessage) && (
            <div className={classes.alertMessageDiv}>
              <i
                ref={warningIconRef}
                className={classNames('dx-icon-warning', classes.alertIcon, rowData.LineStatus === 'Warning' ? classes.alertIconWarning : classes.alertIconError)}
                onMouseEnter={() => { setAlertHover(true); }}
                onMouseLeave={() => { setAlertHover(false); }}
                onClick={() => { setAlertClick(true); }}
              />
              <Tooltip
                target={warningIconRef.current}
                visible={alertHover || alertClick}
                hideOnOutsideClick={true}
                onHiding={() => { setAlertClick(false); }}>
                {rowData.AlertMessage || rowData.StatusMessage}
              </Tooltip>
            </div>)
          }
          <RenderInfoButton />
        </div>
      </div>
    </React.Fragment>);
}

export default withStyles(styles, { index: 1 })(ProductCell);
