import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../utils';

export const getSupplierUserFields = (query) => {
  const { Supplier, Page, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchGet(`SupplierEnquiry/UserFields/Supplier/${Supplier}`, { BatchPage: Page, BatchSize }, 'Inline')
    .then((data) => {
      if (data && data.SupplierUserFields) {
        return {
          records: data.SupplierUserFields.map((val) => Object.assign({}, objectify(val))),
          nextPage: data.BatchInformation && !data.BatchInformation.EndOfData && data.BatchInformation.BatchPage + 1,
          currPage: data.BatchInformation && data.BatchInformation.BatchPage,
        }
      }
      else return []
    })
}