import { connect } from 'react-redux';
import DiaryGrid from './DiaryGrid';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';

const params = new URLSearchParams(location.search);

const mapStateToProps = (state) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiaryGrid);
