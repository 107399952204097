import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as serviceOrdersSelectors, actions as serviceOrdersActions } from 'ducks/customerEnquiry/serviceOrders';
import { fetch } from 'api/customerEnquiry/serviceOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { selectors as customerSelector } from 'ducks/customer';


const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'CEServiceOrders',
  columnDefs: serviceOrdersSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: serviceOrdersSelectors.gridOptions(state),
  filterRow: serviceOrdersSelectors.filterRow(state),
  selectedFilters: serviceOrdersSelectors.selectedFilters(state),
  appliedFilters: serviceOrdersSelectors.filterRow(state) && filterSelectors.getFilters(state, serviceOrdersSelectors.filterRow(state).formName),
  reqParams: (customerSelector.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelector.selected(state) && customerSelector.selected(state).CustomerId) || params.get('CustomerId')
    },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: serviceOrdersActions.setSelected,
  applyFilters: filterActions.applyFilters
}
