import React from 'react';
import { withStyles } from '@material-ui/core';
import { Heading, Content } from '../CommonComponents';
import styles from '../DynamicExtractModal.styles';
import { IFileSelectionHandle, IFileSelectionProps } from '../DynamicExtractModal.properties';
import { TextField } from '@markinson/uicomponents-v2';
import SelectContainer from 'components/SelectContainer';
import { useDEContextSelector, useDEDispatch } from '../../RunDynamicExtract.context';
import { PROGRAM_ID } from '../DynamicExtractModal.constants';

const FileSelection = (props: IFileSelectionProps, ref: React.Ref<IFileSelectionHandle>) => {
    const { classes, details } = props;

    const contextDispatch = useDEDispatch();
    const WizardDetails = useDEContextSelector<'WizardDetails'>((state) => state.WizardDetails);
    const CurrentPanel = useDEContextSelector<'CurrentPanel'>((state) => state.CurrentPanel);
    const fileLocationRef = React.useRef(null);
    const fileNameRef = React.useRef<HTMLInputElement>();
    const fileTypeRef = React.useRef(null);

    React.useEffect(
        () => {
            if (WizardDetails?.FileName && WizardDetails?.FileName !== '') {
                contextDispatch({
                    setErrorState: { InError: false, Panel: '' }
                });
            } else {
                contextDispatch({
                    setErrorState: { InError: true, Panel: CurrentPanel }
                });
            }
        },
        [WizardDetails?.FileName]
    );

    React.useImperativeHandle(ref, () => ({
        focusFileLocationField: () => {
            fileLocationRef.current.handleValidation();
        },
        focusFileNameField: () => {
            fileNameRef.current.focus();
            fileNameRef.current.blur();
            fileNameRef.current.select();
        },
        focusFileTypeField: () => {
            fileTypeRef.current.handleValidation();
        },
    }));

    return (
        <div className={classes.contentContainer}>
            <Heading text={details?.Header} />
            <Content text={details?.Description} />
            <SelectContainer
                width={'100%'}
                value={WizardDetails?.FileLocation}
                innerRef={fileLocationRef}
                label='File location'
                name='FileArea'
                apiPrefs={({
                    LookupType: 'Dynamic',
                    path: '/CustomTypes/Lookup/WizardFileArea/Search',
                    method: 'POST'
                })}
                params={{
                    SearchText: '',
                    ProgramId: PROGRAM_ID,
                    BatchPage: 1,
                    BatchSize: 100
                }}
                onChange={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, FileLocation: v }
                    });
                }}
                required={false}
                size='medium'
                defaultFirstValue={true}
            />
            <TextField
                label={'File name'}
                innerRef={fileNameRef}
                name={'FileLocation'}
                size='large'
                value={WizardDetails?.FileName}
                required={true}
                onChange={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, FileName: v }
                    });
                }}
            />
            <SelectContainer
                width={'100%'}
                value={WizardDetails?.FileType}
                innerRef={fileTypeRef}
                label='File type'
                name='FileType'
                apiPrefs={({
                    LookupType: 'Dynamic',
                    path: '/CustomTypes/Lookup/WizardFileType/Search',
                    method: 'POST'
                })}
                params={{
                    SearchText: '',
                    ProgramId: PROGRAM_ID,
                    BatchPage: 1,
                    BatchSize: 100
                }}
                onChange={(v) => {
                    contextDispatch({
                        setWizardDetails: { ...WizardDetails, FileType: v }
                    });
                }}
                required={false}
                defaultFirstValue={true}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.forwardRef(FileSelection));
