import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    KitComponents: {
        width: 'calc(100% - 350px)',
        padding: '15px',
        float: 'left',
        display: 'flex',
        flexDirection: 'column'
    },
    grid: {
        marginTop: '10px'
    },
    recallIcon: {
        width: 35,
        height: 38
    },
    kitComponentsContainer: {
        display: 'flex',
        height: 'calc(100% - 105px)'
    },
    kitSummaryTable: {
        height: 'fit-content'
    },
    warehouseIcon: {
        width: 40,
        height: 40
    }
});
