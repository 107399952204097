import { IFormViewForm } from 'components/FormView';

const RosterLookupForm: IFormViewForm = {
  id: 'RosterLookup',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'rosterlookup',
        fullWidth: true,
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Technician ID',
            name: 'UserId',
            size: 'small',
            disabled: true
          },
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Entity',
            name: 'EntCode',
            size: 'small',
            disabled: true
          },
        },
        {
          id: '3',
          type: 'SELECT_FIELD',
          visible: true,
          props: {
            label: 'Weekday',
            name: 'Weekday',
            apiPrefs: {
              path: '/CustomTypes/Lookup/Weekday',
              method: 'GET',
              params: null
            },
            size: 'medium',
          }
        },
        {
          id: '4',
          type: 'TIME_FIELD',
          visible: true,
          props: {
            label: 'Workday starts at',
            name: 'StartTime',
            size: 'small',
            required: true

          }
        },
        {
          id: '5',
          type: 'TIME_FIELD',
          visible: true,
          props: {
            label: 'Workday ends at',
            name: 'EndTime',
            size: 'small',
            required: true
          }
        }
      ]
    }
  ]
};
export default RosterLookupForm;
