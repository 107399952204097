import { connect } from 'react-redux';

import CreditNoteLines from './CreditNoteLines';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as creditNoteSelectors, actions as creditNoteActions } from 'ducks/creditNotes/creditNote';
import { selectors as creditNoteContextSelectors } from 'ducks/creditNotes/creditNoteContext';
import { actions as creditNoteLineActions, selectors as creditNoteLineSelectors } from 'ducks/creditNotes/creditNoteLine';
import { getFormValues, isDirty, isValid, getFormSyncErrors, destroy, reset, getFormMeta, getFormInitialValues } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';

import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'CNLineDetails';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),

  selectedForm: formSelectors.selected(state),

  isLoading: creditNoteLineSelectors.isLoading(state),
  notifications: creditNoteSelectors.creditNoteNotifications(state),
  applyResponse: creditNoteSelectors.applyResponse(state),
  selectedLine: creditNoteSelectors.selected(state),
  lineDetails: creditNoteLineSelectors.lineDetails(state),
  lineDetailsSchema: creditNoteLineSelectors.lineDetailsSchema(state),
  creditNoteContext: creditNoteContextSelectors.creditNoteContext(state),

  values: getFormValues(FORM_NAME)(state),
  initialValues: getFormInitialValues(FORM_NAME)(state),
  meta: getFormMeta(FORM_NAME)(state),
  dirty: isDirty(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    destroyForm: () => destroy(FORM_NAME),
    resetForm: () => reset(FORM_NAME),

    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    toggleCreditNotesLookupPanel: uiActions.toggleCreditNotesLookupPanel,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,

    getCreditNoteLineDetails: creditNoteLineActions.getCreditNoteLine,
    createCreditNoteLine: creditNoteLineActions.createCreditNoteLine,
    saveCreditNoteLineDetails: creditNoteLineActions.saveCreditNoteLine,
    updateCreditNoteLineDetails: creditNoteLineActions.updateCreditNoteLine,
    processCreditNote: creditNoteActions.processCreditNote,
    cancelCreditNoteLineDetails: creditNoteLineActions.cancelCreditNoteLine,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteLines);
