import { call, takeLatest } from 'redux-saga/effects';

import { types as componentDetailsType, actions as componentDetailsActions } from 'ducks/salesOrderEnquiry/componentDetails';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/componentDetails';

function* getComponentDetails(action) {
  const { success, failure } = componentDetailsActions.saga.fetchComponentDetails;
  yield callApi(
    call(api.getComponentDetails, action.data),
    success,
    failure
  )
}

function* getComponentSummary(action) {
  const { success, failure } = componentDetailsActions.saga.fetchComponentSummary;
  yield callApi(
    call(api.getComponentSummary, action.data),
    success,
    failure
  )
}

export default function* rootLineDetailsSaga() {
  yield takeLatest(componentDetailsType.fetchComponentDetails, getComponentDetails)
  yield takeLatest(componentDetailsType.fetchComponentSummary, getComponentSummary)
}