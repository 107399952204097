import { IFormViewForm } from 'components/FormView';

const PricingForm: IFormViewForm = {
  id: 'CustomerPricing',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'pricing',
        required: true,
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Customer price code',
            name: 'CustomerPriceCode',
            lookupName: 'CustomerPriceCode',
            required: true,
            size: 'small',
            inputProps: {
              maxLength: 3
            }
          },
        },
        {
          id: '2',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Default price schedule',
            name: 'DefaultPriceSchedule',
            lookupName: 'PriceSchedule',
            size: 'small',
            inputProps: {
              maxLength: 8
            }
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Override schedule',
            name: 'OverridePriceSchedule',
            lookupName: 'PriceSchedule',
            size: 'small',
            inputProps: {
              maxLength: 8
            }
          }
        },
        {
          id: '4',
          type: 'AUTOCOMPLETE_FIELD',
          visible: true,
          props: {
            label: 'Override price break level',
            name: 'BreakLevel',
            defaultValue: 'None',
            sort: 'desc',
            apiPrefs: {
              path: '/CustomTypes/Lookup/BreakPriceLevels',
              method: 'GET',
              params: null
            },
          }
        },
        {
          type: 'SELECT_FIELD',
          props: {
            label: 'Price override',
            required: false,
            name: 'PriceOverride',
            apiPrefs: {
              path: '/CustomTypes/Lookup/CustomerPriceOverride',
              method: 'GET',
              params: null
            },
          }
        },
        {
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Sell price rounding',
            name: 'RndPointId',
            required: false,
            size: 'small',
            lookupName: 'PricePoint',
            inputProps: {
              maxLength: 20
            }
          }
        }
      ]
    },
  ]
};
export default PricingForm;
