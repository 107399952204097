import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncSelectors,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError
} from '../../utils';

export interface IBalancesData {
  details: any;
  schema: any;
}

export interface IBalancesState extends IExtendedState<IBalancesData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      getBalancesDetails: (params: { Supplier: number }) => (params)
    }
  },
  'supplierBalances');

const initialState = asyncInitialState<IBalancesData>({
  details: null,
  schema: null,
});

export default (state: IBalancesState = initialState, action: IDataAction): IBalancesState => {
  switch (action.type) {
    case types.getBalancesDetails:
      return asyncOnRequest({ ...state, data: { ...state.data, details: null, schema: null } }, action);
    case types.saga.getBalancesDetails.success:
      return asyncOnSuccess(state, action, (data, successAction) => ({
        ...data,
        details: successAction.payload && successAction.payload.values,
        schema: successAction.payload && successAction.payload.schema
      }));
    case types.saga.getBalancesDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = ({
  ...asyncSelectors((state: { supplierEnquiry: { financial: { balances: IBalancesState } } }) => state.supplierEnquiry.financial.balances, {
    details: (data: IBalancesData) => data.details,
    schema: (data: IBalancesData) => data.schema
  })
});
