import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (orderId) => ({ orderId }),
  asyncs: {
    search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
    searchById: (SalesOrder) => SalesOrder,
    fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
    fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
    getDetails: (SalesOrder) => ({ SalesOrder }),
    fetchSummary: (SalesOrder) => ({ SalesOrder }),
    reset: () => {}
  }
}, 'salesOrder')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
  details: null,
  summary: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: '',
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };
    case types.setSelected:
      const selected = action.data.orderId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.search:
      return asyncOnRequest({
        ...state,
        data: { ...state.data, details: null, selected: NOT_SELECTED, currSearchText: action.data.SearchText }
      }, action);
    case types.searchById:
      return asyncOnRequest({
        ...state,
        data: { ...state.data, details: null, selected: NOT_SELECTED }
      }, action);
    case types.getDetails:
      return asyncOnRequest({
        ...state,
        data: { ...state.data, details: null }
      }, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action)


    case types.saga.search.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        return {
          ...data,
          list: result.SalesOrders,
          nextPage: result.nextPage && result.currPage + 1,
          prevPage: result.prevPage && result.currPage - 1,
          currPage: result.currPage
        };
      }, { fetch: true })
    case types.saga.searchById.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const result = successAction.payload;

          return {
            ...data,
            list: result.SalesOrders,
            nextPage: result.nextPage && result.currPage + 1,
            prevPage: result.prevPage && result.currPage - 1,
            currPage: result.currPage
          };
        },
        { fetch: true });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const newRemoval = result.SalesOrders.length;
        const newList = data.list.concat(result.SalesOrders);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
          nextPage: result.nextPage && result.currPage + 1,
          prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
        };
      }, { fetch: true })

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const result = action.payload;
        const newList = result.SalesOrders.slice(0, data.removeWhenPrev).concat(data.list);
        return {
          ...data,
          list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
          prevPage: result.prevPage && result.currPage - 1,
          nextPage: (newList.length > data.pageSize * data.totalPages) && (result.currPage + data.totalPages),
          currPage: result.currPage,
          removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
        };
      }, { fetch: true })
    case types.saga.getDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          details: action.payload,
        }
      })
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.getDetails.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
      return asyncOnError(state, action)
    case types.fetchSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          summary: linesData
        }
      })
    case types.saga.fetchSummary.failure:
      return asyncOnError(state, action);
    case types.reset: 
      return initialState;
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.salesOrder,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.SalesOrder === data.selected)) || null,
    details: data => data.details,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
    summary: data => data.summary,
  },
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.salesOrder.search_loading || state.salesOrderEnquiry.salesOrder.searchById_loading,
  loadingNextPage: state => state.salesOrderEnquiry.salesOrder.fetchNextPage_loading,
  loadingPrevPage: state => state.salesOrderEnquiry.salesOrder.fetchPrevPage_loading,
  pageSize: (state) => state.salesOrderEnquiry.salesOrder.data.pageSize,
  totalPages: (state) => state.salesOrderEnquiry.salesOrder.data.totalPages,
  loadingSummary: (state) => state.salesOrderEnquiry.salesOrder.fetchSummary_loading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)