import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (attributeId) => ({ attributeId }),
  asyncs: {
    search: (query) => (query),
    searchById: ({ SupplierId, AttributeSet, AttributeCode }) => ({ SupplierId, AttributeSet, AttributeCode }),
    
  }
}, 'marketing')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  list: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.search:
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
      

    case types.setSelected:
      const selected = action.data.attributeId;
      return {
        ...state,
        data: { ...state.data, selected }
      }

    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(state, action, (data, action) => {
        
        const marketing = action.payload.map((combinedObject) => combinedObject.inlineObject);
        const schemas = action.payload.map((combinedObject) => combinedObject.schema);
        return {
          ...data,
          list: marketing,
          schemas: schemas
        };
      })
    case types.saga.search.failure:
    case types.saga.searchById.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.supplierEnquiry.marketing,
  {
    all: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.AttributeCode === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.AttributeCode.Value === data.selected)) || null,
  }
)

const syncSelector = {
  isLoading: state => state.supplierEnquiry.marketing.search_loading || state.supplierEnquiry.marketing.searchById_loading
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

