import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  asyncs: {
    fetchPaymentEntryDetails: (query) => (query),
  }
}, 'paymentEntryDetails')

let initialState = asyncInitialState({
  paymentEntryDetails: '',
})

export default (state = initialState, action) => {
  switch (action.type) {

    case types.fetchPaymentEntryDetails:
      return asyncOnRequest(state, action)

    case types.saga.fetchPaymentEntryDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          paymentEntryDetails: action.payload
        }
      })
    case types.saga.fetchPaymentEntryDetails.failure:
      return asyncOnError(state, action, (data, action) => {
        return {
          ...data,
          error: action.payload
        }
      })

    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.paymentEntryDetails,
  {
    paymentEntryDetails: data => data.paymentEntryDetails,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.paymentEntryDetails.loading,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)



