export const DefaultBreadCrumbItem = { id: '0', label: 'Backorder Commitment' };
import SummaryTableSchema from './LineCommitment/SummaryPanelSchema';
import purchaseOrderSummaryTableSchema from './PurchaseOrder/Lines/SummaryPanelSchema';

export const getTabs = ({ PurchaseOrderId }) => ([
  { id: 'PurchaseOrderLines', label: 'Lines', disabled: !PurchaseOrderId },
  { id: 'OrderDetails', label: 'Order Details', disabled: !PurchaseOrderId },
  { id: 'Deliveries', label: 'DELIVERY', disabled: !PurchaseOrderId },
]);

export const SummaryPanelSchemas = {
  BackorderLines: SummaryTableSchema,
  LineCommitment: SummaryTableSchema,
  PurchaseOrderLines: purchaseOrderSummaryTableSchema,
  PurchaseOrderLineDetails: purchaseOrderSummaryTableSchema,
  OrderDetails: purchaseOrderSummaryTableSchema,
  Deliveries: purchaseOrderSummaryTableSchema,
};

export const OrderDetailsCalculationFields = ['DueDate', 'PurchaseOrderCategory', 'PurchaseOrderTranType', 'FreightCode'];

export const PurchaseOrderLineDetailsNonCalculationFields = ['SpecialPrice', 'Estimated'];
