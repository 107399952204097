import { call } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects'

import { types as searchTypes, actions as searchActions } from 'ducks/searchPanel'

import { callApi } from './utils'
import * as api from 'api/search'

function * search (action) {
  const {success, failure} = searchActions.saga.search
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}
export default function * rootCustomerSaga () {
  yield takeLatest(searchTypes.search, search)
}
