import React from 'react';
import { Button, CircularProgress, WithStyles, withStyles } from '@material-ui/core';
import styles from './LoadingButton.styles';
import { ButtonProps } from '@material-ui/core/Button';

interface ILoadingButton extends Omit<ButtonProps, 'classes'>, WithStyles<typeof styles> {
    loading: boolean;
    startIcon?: React.ReactElement;
    circularProgressSize?: number;
}

const DefaultCircularProgressSize = 18;

const LoadingButton = (props: ILoadingButton) => {
    const { classes, loading, children, startIcon, circularProgressSize = DefaultCircularProgressSize, ...restProps } = props;

    return (
        <Button {...restProps}>
            <React.Fragment>
                <span className={classes.ButtonLabelStyle}>
                    {loading ? <CircularProgress size={circularProgressSize} className={classes.circularLoading} />
                        : startIcon || ''}
                </span>
                {children}
            </React.Fragment>
        </Button>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(LoadingButton));
