import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {  Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import supplierActionList from 'utils/data/db/actions/supplierActionList';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize, PaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps, ISummaryPanelState } from '../interfaces';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '9px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '9px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '9px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '9px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  }
});
const pSize = CircularProgressSize;
const paperElevation = PaperElevation;

class SummaryPanel extends React.PureComponent<ISummaryPanelProps, ISummaryPanelState> {

  constructor(props: ISummaryPanelProps) {
    super(props);
    const state: ISummaryPanelState = {
      open: false,
      dialogOpen: false,
    };
    this.state = state;
  }
  componentDidUpdate(prevProps: any): void {
    const { search, saleTotal, filters, getSaleDetails } = this.props;

    if (filters && (!prevProps || !prevProps.filters || prevProps.filters !== filters)) {
      const { customer, order } = filters;

      if (customer && customer.CustomerAccountNumber) {
        search(customer.CustomerAccountNumber);
      }
      if (order && order.SalesOrder) {
        saleTotal(order.SalesOrder);
        getSaleDetails(order.SalesOrder);
      }
    }
  }

  appendParam = (actionList: any, invoiceSummary: any) => {
    if (invoiceSummary && invoiceSummary.Supplier) {
      return actionList.map((item) => {
        item.actionParam = { Supplier: Number(invoiceSummary.Supplier) };

        return item;
      });
    }

    return actionList;
  }

  render(): React.ReactNode {
    const { classes, invoiceSummary, isLoading } = this.props;
    const actionList = supplierActionList;
    const saleOrderDetails = {
      'SUPPLIER': <div style={inlineStyles.splitCellContainer}>
        <div style={inlineStyles.splitCellContent} >
          <span >{invoiceSummary && invoiceSummary.SupplierDisplay}</span>
          <br />
          <span ></span>
        </div>
        <div style={inlineStyles.splitCellAction} >
          {
            <InfoButton actionList={this.appendParam(actionList, invoiceSummary)}
              disabled={!(invoiceSummary && invoiceSummary.Supplier)}
            />
          }
        </div>
      </div>,
      'GRN': <div>
        <span >{invoiceSummary && invoiceSummary.GRN}</span>
      </div>
      ,
      'INVOICE': <div>
        <span >{invoiceSummary && invoiceSummary.InvoiceNumber}</span>
      </div>,
      'INVOICE DATE': <div>
        <span >{invoiceSummary && invoiceSummary.InvoiceDate}</span>
      </div>,
      'WAREHOUSE': <div>
        <div style={inlineStyles.splitCellContent} >
          <span >{invoiceSummary && invoiceSummary.Warehouse}</span>
          <br />
          <span >{invoiceSummary && invoiceSummary.WarehouseDescription}</span>
        </div>
      </div>,

    };

    const totals = {
      'CURRENCY': invoiceSummary && invoiceSummary.Currency,
      'CHARGES': invoiceSummary && invoiceSummary.ChargesDisplay,
      'OVERCHARGE': invoiceSummary && invoiceSummary.OverchargeDisplay,
      'VARIANCE': invoiceSummary && invoiceSummary.VarianceDisplay,
      'TAX TOTAL': invoiceSummary && invoiceSummary.TaxTotalDisplay,
      'INVOICE TOTAL': invoiceSummary && invoiceSummary.InvoiceTotalDisplay,
    };
    const numeric = (property) => ['CHARGES', 'TAX TOTAL', 'INVOICE TOTAL', 'OVERCHARGE', 'VARIANCE'].includes(property);

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true}>
          <Scrollbars>
            <Paper elevation={paperElevation} className={classes.subPanel} square={true}>
              <p className={classes.subPanelTitle}>Document details</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                <Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(saleOrderDetails).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            className={classes.dataCell}>
                            {saleOrderDetails[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
            <Paper elevation={paperElevation} className={classes.subPanelLastChild} square={true}>
              <p className={classes.subPanelTitle}>Totals</p>
              {(isLoading && <CircularProgress
                size={pSize}
                style={inlineStyles.preLoader}
                color={'secondary'}
                variant={'indeterminate'} />)
                ||
                < Table className={classes.summaryTable} >
                  <TableBody>
                    {Object.keys(totals).map((property, i) => {
                      return (
                        <TableRow key={i} className={classes.summaryRow}>
                          <TableCell className={classes.headingCell}>{property}</TableCell>
                          <TableCell
                            numeric={numeric(property)}
                            className={property === 'INVOICE TOTAL'
                              ? classes.totalCell :
                              numeric(property) ?
                                classes.numericCell
                                : classes.dataCell}>
                            {totals[property]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow className={classes.summaryRow}>
                      <TableCell>
                        <br />
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.summaryRow}>
                      <TableCell className={classes.headingCell}>{'LOCAL CURRENCY'}</TableCell>
                      <TableCell
                        className={classes.dataCell}>
                        {invoiceSummary && invoiceSummary.LocalCurrency}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.summaryRow}>
                      <TableCell className={classes.headingCell}>{'LOCAL TOTAL'}</TableCell>
                      <TableCell
                        numeric={true}
                        className={classes.dataCell}>
                        {invoiceSummary && invoiceSummary.LocalTotalDisplay}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              }
            </Paper>
          </Scrollbars>
        </Paper>
      </div>
    );
  }
}

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
