import { ISummaryTableDataNode } from 'components/common/SummaryTable/SummaryTable.properties';

const schema: ISummaryTableDataNode[] = [
    {
        objectName: 'pickslipsDetails',
        isLoading: 'pickslipsLoading',
        title: 'Customer Status',
        componentName: 'CustomerStatus'
    },
    {
        objectName: 'pickslipsDetails',
        isLoading: 'pickslipsLoading',
        title: 'Pick Slip Details',
        children: [
            {
                label: 'Invoice',
                fieldNames: [
                    'Invoice',
                ],
                valueRenderer: {
                    api: {
                        api: '/salesInvoiceEnquiry/lookup/action',
                        name: 'salesInvoiceEnquiry',
                        params: {
                            queryFields: {
                                InvoiceNumber: 'Invoice',
                                SalesEntity: 'SalesEntity',
                                SearchText: ''
                            }
                        }
                    }
                }
            },
            {
                label: 'Despatch',
                fieldNames: [
                    'DespatchNumber'
                ],
            },
            {
                label: 'Pick Number',
                fieldNames: [
                    'PickNumber',
                ],
            },
            {
                label: 'CUSTOMER',
                fieldNames: [
                    'CustomerIdDisplay',
                    'CustomerIdLabel'
                ],
                valueRenderer: {
                    api: {
                        api: '/customermaintenance/lookup/action',
                        name: 'CustomerId',
                        params: {
                            queryFields: {
                                CustomerId: 'CustomerId',
                                SearchText: ''
                            }
                        }
                    }
                }
            },
            {
                label: 'CUSTOMER P/O',
                fieldNames: [
                    'CustomerPurchaseOrder',
                ],
            },
            {
                label: 'SALES ORDER',
                fieldNames: [
                    'SalesOrder',
                ],
                valueRenderer: {
                    api: {
                        api: '/salesOrderEnquiry/lookup/action',
                        name: 'saleOrder',
                        params: {
                            queryFields: {
                                SalesOrder: 'SalesOrder',
                                SearchText: ''
                            }
                        }
                    }
                }
            },
            {
                label: 'STATUS',
                fieldNames: [
                    'Status',
                ],
            },

        ]
    },
    {
        objectName: 'pickslipsDetails',
        isLoading: 'pickslipsLoading',
        children: [
            {
                label: 'WAREHOUSE',
                fieldNames: [
                    'Warehouse',
                    'WarehouseLabel',
                ],
            },
            {
                label: 'TERRITORY',
                fieldNames: [
                    'Territory',
                    'TerritoryLabel',
                ],
            },
            {
                label: 'SALES REP',
                fieldNames: [
                    'SalesRepresentative',
                    'SalesRepresentativeLabel',
                ],
            },
        ]
    },
    {
        objectName: 'pickslipsDetails',
        isLoading: 'pickslipsLoading',
        children: [
            {
                label: 'PICKER',
                fieldNames: [
                    'PickerId',
                    'PickerName',
                ],
            },
            {
                label: 'CREATED',
                fieldNames: [
                    'CreatedDate',
                ],
            },
            {
                label: 'DESPATCHED',
                fieldNames: [
                    'DespatchedDate',
                ],
            },
        ]
    },
];

export default schema;
