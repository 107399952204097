import { withStyles } from '@material-ui/core/styles';
import styles from './LineCommitment.styles';
import React from 'react';
import ILineCommitmentProperties from './LineCommitment.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import LineCommitmentGrid from './LineCommitmentGrid/LineCommitmentGrid';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from './LineCommitment.summaryTable.schema';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';
import { useRetrieveCommitmentLinesSummary } from 'api/Worksale/backorderCommitment';
import { useBOCContextSelector } from '../BackorderCommitment.context';

const LineCommitment = (props: ILineCommitmentProperties) => {

    const {
        classes,
    } = props;

    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);
    const CommitmentLineNumber = useBOCContextSelector<'CommitmentLineNumber'>((state) => state.CommitmentLineNumber);

    const retrieveCommitmentLinesSummary = useRetrieveCommitmentLinesSummary(SalesOrderId, CommitmentLineNumber);

    const { setActionBar } = React.useContext(ActionBarContext);

    React.useEffect(
        () => {
            setActionBar({
                leftIcons: [BACK_BUTTON],
                centerIcons: [],
                rightIcons: []
            });

        },
        []
    );

    return (
        <div className={classes.container}>
            <LineCommitmentGrid
                salesOrder={SalesOrderId}
                lineNumber={CommitmentLineNumber}
                onLineUpdate={retrieveCommitmentLinesSummary.refetch}
                navigateBack={props.navigateBack}
            />
            <SummaryTable
                className={classes.summaryTable}
                loadingLineCommitmentSummary={false}
                lineCommitmentSummary={retrieveCommitmentLinesSummary.data?.Summary?.inlineObject}
                data={
                    SummaryTableSchema
                }
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(LineCommitment);
