import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as BOMSelectors, actions as BOMActions } from 'ducks/salesOrderEnquiry/BOM';
import { selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { selectors as orderLineSelectors } from 'ducks/salesOrderEnquiry/orderLine';
import { fetch } from 'api/salesOrderEnquiry/BOM';

export const mapStateToProps = state => {
  const params = new URLSearchParams(location.search);

  return ({
    gridName: 'SOEBOM',
    columnDefs: BOMSelectors.BOMLineColumns(state),
    isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
    actions: BOMSelectors.BOMLineActions(state),
    gridOptions: BOMSelectors.gridOptions(state),
    isLoading: BOMSelectors.isLoading(state),
    reqParams: (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber')) &&
      (orderSelectors.selected(state) || params.get('SalesOrder')) &&
    {
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
      SalesOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).SalesOrder) || params.get('SalesOrder'),
    },
    keyFields: [{ valueField: 'LineNumber', routeField: 'BOMLineNumber' }],
    apiMethod: fetch
  });
}

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: BOMActions.setSelected
}
