import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@markinson/uicomponents-v2/SvgIcons';
import inlineStyles from './FastEntry.styles';
import IFastEntryProps, { ILastChangedField, IFastEntryHandle } from './FastEntry.properties';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import { isNull } from 'utils/utils';
import ProductLookup from 'components/common/ProductLookup';
import classNames from 'classnames';
import { IWorksaleGridLineDetailFacade, ICreateFastLineFacade, ICalculateFastLineCriteriaFacade, IWorksaleFastLineDetailFacade } from 'api/swaggerTypes';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import ExtendedTextField from '@markinson/uicomponents-v2/ExtendedTextField';
import { useValidateProduct } from 'api/Worksale/worksaleLatest';
import LoadingButton from 'components/common/LoadingButton';
import { ENTER_KEY } from 'utils/constants';
import { useFetchCurrentWorksale } from 'api/Worksale/worksale';
import { useElementSize } from 'utils/hooks';

const RESET_WAIT = 300;
const MINIMUMSIZESCREEN = 913;

const FastEntry = (props: IFastEntryProps, ref: React.Ref<IFastEntryHandle>): JSX.Element => {
    const { data: currentWorksaleData } = useFetchCurrentWorksale();
    const { classes, disabled, SalesEntity, enableProductValidation, CustomerId, hidePrice, KitStatus, BomCode, disableProduct, onUndo, onApply, onFastEntryFieldChanged, onProductChanged } = props;

    const validateProductMutation = useValidateProduct();

    const [isLoading, setIsLoading] = React.useState(false);
    const [quantity, setQuantity] = React.useState(0);
    const [price, setPrice] = React.useState(0);
    const [product, setProduct] = React.useState<number>(undefined);
    const [packageId, setPackageId] = React.useState<number>(undefined);
    const [matchType, setMatchType] = React.useState<string>('');
    const [productDisplay, setProductDisplay] = React.useState<string>('');
    const [productDescription, setProductDescription] = React.useState<string>('');
    const [nonProductFieldsDisabled, setNonProductFieldsDisabled] = React.useState(false);
    const [fastLineDetails, setFastLineDetails] = React.useState<IWorksaleFastLineDetailFacade>();
    const [lastChangedField, setLastChangedField] = React.useState<ILastChangedField>();
    const [priceDisabled, setPriceDisabled] = React.useState<boolean>(false);
    const [isInvalidProduct, setIsInvalidProduct] = React.useState<boolean>(false);
    const [apply, setApply] = React.useState<boolean>(false);
    const { width } = useElementSize();

    const quantityRef = React.useRef<ITextFieldHandle>();
    let priceRef = React.useRef<HTMLInputElement>();
    const productRef = React.useRef<ITextFieldHandle>();
    const WarehouseEntity = React.useMemo(() => (currentWorksaleData?.WorkSale?.Summary?.SaleHeader?.inlineObject?.WarehouseEntity), [currentWorksaleData]);

    // Retrieve new price/quantity if product/quantity changed.
    async function handleFastEntryFieldChanged(details: ICalculateFastLineCriteriaFacade, field?: ILastChangedField): Promise<IWorksaleFastLineDetailFacade | void> {
        try {
            if (enableProductValidation && field === 'product' && details.ProductId) {
                const validationResponse = await validateProductMutation.mutateAsync({
                    ProductId: details.ProductId,
                    CustomerId: CustomerId,
                    WarehouseEntity: WarehouseEntity,
                    SalesEntity: SalesEntity
                });

                setIsInvalidProduct(!validationResponse.Status);

                if (!validationResponse.Status) {
                    setTimeout(
                        () => {
                            resetToDefault();
                        },
                        RESET_WAIT); // wait for the completion of other blocking UI operations

                    return;
                }
            }

            if (onFastEntryFieldChanged && details.ProductId) {
                setIsLoading(true);
                const response = await onFastEntryFieldChanged(details);
                if (response && response?.Status) {
                    setFastLineDetails(response?.FastLineDetails);

                    return response?.FastLineDetails;
                }

                return null;
            }
        } catch (err) {
            console.warn(err);
        } finally {
            setIsLoading(false);
        }
    }

    React.useImperativeHandle(
        ref,
        () => ({
            calculateFastLineEntry(details: IWorksaleGridLineDetailFacade): Promise<IWorksaleFastLineDetailFacade | void> {
                return handleFastEntryFieldChanged({
                    ProductId: product,
                    PackageId: packageId,
                    MatchType: matchType,
                    KitStatus: (details && details.KitStatus) ? details.KitStatus : KitStatus,
                    BomCode,
                    OrderedQuantity: quantity
                });
            },
            disablePriceField(): void {
                setPriceDisabled(true);
            },
            focusPriceField,
            resetToDefault
        })
    );

    React.useEffect(
        () => {
            if (onProductChanged) {
                onProductChanged(product);
            }
        },
        [product]
    );

    React.useEffect(
        () => {
            if (!disabled) {
                resetToDefault();
            }
        },
        [disabled]
    );

    // When we are loading, we disable fields, so we can only re-focus if they are enabled.
    React.useEffect(
        () => {
            if (!nonProductFieldsDisabled) {
                if (lastChangedField === 'product' && quantityRef.current) {
                    quantityRef.current.focus();
                    quantityRef.current.select();
                } else if (lastChangedField === 'quantity') {
                    focusPriceField();
                }
            }
        },
        [nonProductFieldsDisabled, lastChangedField]
    );

    // Update quantity/price on fast line details changed
    React.useEffect(
        () => {
            if (fastLineDetails) {
                setQuantity(fastLineDetails.OrderedQuantity ? fastLineDetails.OrderedQuantity : quantity);
                setPrice((fastLineDetails && fastLineDetails.DiscountedPrice) ? fastLineDetails.DiscountedPrice : price);
            } else {
                setQuantity(0);
                setPrice(0);
            }
        },
        [fastLineDetails]
    );

    //Non product fields disabled depends on whether whole component is disabled or it is loading or whether there is no product id.
    React.useEffect(
        () => {
            setNonProductFieldsDisabled(isLoading || isNull(product) || disabled);
        },
        [isLoading, product, disabled]
    );

    const onApplyCallback = React.useCallback(
        async () => {
            setApply(false);
            await handleApply({ ...fastLineDetails, OrderedQuantity: quantity, DiscountedPrice: price, ProductId: product });
        },
        [fastLineDetails, quantity, price, product]
    );

    React.useEffect(
        () => {
            if (!isLoading && apply) {
                onApplyCallback();
            }
        },
        [isLoading, apply, onApplyCallback]
    );

    function focusPriceField(): void {
        if (priceRef.current) {
            priceRef.current.focus();
            priceRef.current.select();
        } else {
            (document?.getElementsByName('FastEntryPrice')[0] as HTMLInputElement)?.select();
        }
    }

    async function handleQuantityKeyDown(event: React.KeyboardEvent<HTMLInputElement>): Promise<void> {
        if (event.keyCode === ENTER_KEY) {
            await quantityRef?.current?.blur();
            setApply(true);
        }
    }

    async function handleApply(updatedFastLineDetails: ICreateFastLineFacade): Promise<void> {
        if (isNull(product)) {
            return;
        }

        try {
            setIsLoading(true);
            if (onApply) {
                const OriginalDiscountedPrice = fastLineDetails ? fastLineDetails.DiscountedPrice : 0;
                await onApply({
                    ...updatedFastLineDetails,
                    DiscountedPrice: updatedFastLineDetails.DiscountedPrice,
                    PriceOverride: updatedFastLineDetails.DiscountedPrice !== OriginalDiscountedPrice,
                });
            }
            setPriceDisabled(false);
        } catch (err) {
            console.warn(err);
        } finally {
            setIsLoading(false);
        }
    }

    function resetToDefault(donotFocusProduct?: boolean): void {
        setQuantity(0);
        setPrice(0);
        setProduct(undefined);
        setPackageId(undefined);
        setMatchType('');
        setPriceDisabled(false);
        setProductDisplay('');
        setProductDescription('');
        if (!donotFocusProduct) {
            setTimeout(
                () => {
                    if (productRef.current) {
                        productRef.current.focus();
                    }
                },
                0);
        }
    }

    const handleApplyEvent = () => {
        setTimeout(
            () => {
                setApply(true);
            },
            0
        );
    };

    const handleUndoEvent = async () => {
        try {
            setIsLoading(true);
            if (onUndo) {
                await onUndo();
            }
            resetToDefault();

        } catch (err) {
            console.warn(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Paper className={classes.fastEntryContainer}>
            <div className={classes.fastEntryFields}>
                <div className={classes.productLookup} style={productDisplay ? {} : { paddingBottom: 16 }}>
                    <ProductLookup
                        ref={productRef}
                        WarehouseEntity={WarehouseEntity}
                        CustomerId={CustomerId}
                        size={'medium'}
                        value={product}
                        display={productDisplay}
                        description={productDescription}
                        disabled={isLoading || disabled || disableProduct}
                        onBlur={(value, display, description, item: Record<string, unknown>) => {
                            const productId = parseFloat(value as string);
                            const itemPackageId = item?.PackageId as number ?? undefined;
                            const itemMatchType = item?.MatchType as string ?? undefined;

                            if (productId !== product || itemPackageId !== packageId) {
                                if (productId) {
                                    setProduct(productId);
                                    setPackageId(itemPackageId);
                                    setMatchType(itemMatchType);
                                    setProductDisplay(display);
                                    setProductDescription(description);
                                    handleFastEntryFieldChanged({ ProductId: productId, PackageId: itemPackageId, MatchType: itemMatchType }, 'product').catch((err) => { console.warn(err); });
                                    setPriceDisabled(false);
                                    setLastChangedField('product');
                                } else {
                                    resetToDefault(true);
                                }
                            }
                        }}
                        onCloseSearchScreen={(selectedProduct: any) => {
                            const value = selectedProduct && selectedProduct.ProductId ? parseFloat(selectedProduct.ProductId as string) : undefined;
                            const itemPackageId = selectedProduct?.PackageId as number ?? undefined;
                            const itemMatchType = selectedProduct?.MatchType as string ?? undefined;

                            if (value && value !== product) {
                                setProduct(value);
                                setPackageId(itemPackageId);
                                setMatchType(itemMatchType);
                                setProductDisplay(selectedProduct.ProductCode);
                                setProductDescription(selectedProduct.Description);
                                handleFastEntryFieldChanged({ ProductId: value, PackageId: itemPackageId, MatchType: itemMatchType }, 'product').catch((err) => { console.warn(err); });
                                setPriceDisabled(false);
                                setLastChangedField('product');
                            }
                        }}
                    />
                </div>
                <NumericField
                    label={'Quantity'}
                    size={width < MINIMUMSIZESCREEN ? 'medium' : 'small'}
                    disabled={nonProductFieldsDisabled || isInvalidProduct}
                    className={classes.quantity}
                    value={quantity}
                    onBlur={async (value) => {
                        if (value !== quantity) {
                            setLastChangedField('quantity');
                            const updatedFastLineDetails = value === quantity ? fastLineDetails : await handleFastEntryFieldChanged({ ProductId: product, PackageId: packageId, MatchType: matchType, OrderedQuantity: value, KitStatus, BomCode }).catch((err) => { console.warn(err); });
                            //price is not updated yet just after calling handleFastEntryFieldChanged. So i'm extracting it by myself again.
                            const discountedPrice = (updatedFastLineDetails && updatedFastLineDetails.DiscountedPrice) ? updatedFastLineDetails.DiscountedPrice : price;
                            const tempQuantity = (updatedFastLineDetails && updatedFastLineDetails.OrderedQuantity) ? updatedFastLineDetails.OrderedQuantity : quantity;
                            setPrice(discountedPrice);
                            if (quantity === tempQuantity) {
                                setQuantity(value); // As value is not changed on blur yet, so change it first, so that updated value can be re render component
                            }
                            setQuantity(tempQuantity);
                        }
                    }}
                    ref={quantityRef}
                    onKeyDown={async (e: any) => handleQuantityKeyDown(e)}
                />
                {!hidePrice && <ExtendedTextField
                    label={'Price'}
                    size={'medium'}
                    disabled={nonProductFieldsDisabled || priceDisabled || isInvalidProduct}
                    value={price}
                    fieldRef={(reference) => priceRef = reference}
                    placeholder={'0.00'}
                    decimalSeparator
                    type={'number'}
                    name='FastEntryPrice'
                    helpText={product && fastLineDetails && fastLineDetails.PriceDescription}
                    onChange={(e) => {
                        const value = Number(e.target.value);
                        setLastChangedField('price');
                        setPrice(value);
                    }}
                    onBlur={(e) => {
                        const value = Number(e.target.value);
                        if (value !== price) {
                            setLastChangedField('price');
                        }
                        setPrice(value);
                    }}
                    onKeyDown={async (e: any) => {
                        if (e.keyCode === ENTER_KEY) {
                            const value = Number(e.target.value);
                            setPrice(value);
                            setApply(true);
                        }
                    }}
                />}
            </div>
            <div className={classes.buttonsContainer}>
                <LoadingButton
                    onMouseDown={handleApplyEvent}
                    onKeyPress={(event) => { if (event.key === 'Enter') handleApplyEvent(); }}
                    disabled={nonProductFieldsDisabled || isInvalidProduct}
                    variant={'contained'}
                    loading={isLoading}
                    startIcon={<CheckCircleIcon style={{ color: 'green' }} />}
                    className={classNames(classes.button, nonProductFieldsDisabled ? classes.disabledBtn : undefined)}>
                    APPLY
                </LoadingButton>
                <Button
                    onClick={handleUndoEvent}
                    onKeyPress={(event) => { if (event.key === 'Enter') handleUndoEvent(); }}
                    disabled={nonProductFieldsDisabled}
                    variant={'contained'}
                    className={classNames(classes.button, nonProductFieldsDisabled ? classes.disabledBtn : undefined)}>
                    <CancelIcon style={{ color: 'red' }} /> UNDO
                </Button>
            </div>
        </Paper >
    );
};

export default withStyles(inlineStyles, { index: 1 })(React.forwardRef(FastEntry));
