import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentDetails from './PaymentDetails';
import { destroy } from 'redux-form';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as paymentActions, selectors as paymentSelectors } from 'ducks/common/paymentDetails';
import { selectors as formSelectors } from 'ducks/form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  paymentDetails: paymentSelectors.paymentDetails(state),
  defaultLine: paymentSelectors.defaultLine(state),
  paymentContext: paymentSelectors.paymentContext(state),
  paymentLines: paymentSelectors.paymentLines(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    changeOperationMode: operationActions.changeOperationMode,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    destroyForm: destroy,
    fetchPaymentDetails: paymentActions.fetchPaymentDetails,
    processPayment: paymentActions.processPayment,
    cancelPayment: paymentActions.cancelPayment,
  },
  dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
