import React, { Component } from 'react';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchemas from './SummaryTableSchemas.json';
import { Paper, Button } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import DoneIcon from '@markinson/uicomponents-v2/SvgIcons/Done';
import { IAssignmentsPanelProps, IAssignmentsPanelState } from 'components/ServiceActivityScheduling/interfaces';
import '../ReviewJobWizard.css';
import { isNull } from 'utils/utils';
import BasicLookupActionField from 'components/FormView/Fields/BasicLookupActionField';

const classesStyles = createStyles({
    main: {
        height: 600
    },
    assignmentPanel: {
        display: 'grid',
        gridTemplateColumns: 'auto auto'
    },
    title: {
        color: '#000000',
        fontSize: '15px',
        marginBottom: '10px',
    },
    assignmentCard: {
        width: 280,
        float: 'right',
        margin: 15,
        padding: 15
    },
    actualCharges: {

    },
    charges: {

    },
    finalCharges: {

    },
    commitActionButton: {
        flexBasis: '50%',
        boxShadow: 'none',
        borderRadius: 0
    },
    adjustBtn: {
        float: 'right'
    },
});

class AssignmentsPanel extends Component<IAssignmentsPanelProps, IAssignmentsPanelState> {
    private productLookupRef: any;

    constructor(props: IAssignmentsPanelProps) {
        super(props);
        const { data = {} } = props;
        this.state = {
            Product: {
                Code: data.inlineObject && data.inlineObject.AdjustmentProductId,
            },
            Description: data.inlineObject && data.inlineObject.AdjustmentDescription,
            Value: data.inlineObject && data.inlineObject.AdjustmentAmount
        };
    }

    componentDidMount(): void {
        this.productLookupRef?.select();
        this.props.updateAdjustmentProps({});
    }

    applyAdjustment = () => {
        const { Product = {}, Description, Value } = this.state;
        const { onAdjust, data = {} } = this.props;

        onAdjust({
            ServiceJobId: data.inlineObject && data.inlineObject.ServiceJobId,
            AdjustmentProductId: Product.Code,
            AdjustmentAmount: Value,
            AdjustmentDescription: Description
        });
    }

    updateState = (newStateValue: IAssignmentsPanelState) => {
        const { adjustmentProps, updateAdjustmentProps, data } = this.props;

        this.setState({ ...newStateValue });
        updateAdjustmentProps({
            ...adjustmentProps,
            ServiceJobId: data.inlineObject && data.inlineObject.ServiceJobId,
            ...(newStateValue.Product) ? { AdjustmentProductId: newStateValue.Product.Code } : {},
            ...(newStateValue.Value) ? { AdjustmentAmount: Number(newStateValue.Value) } : {},
            ...(newStateValue.Description) ? { AdjustmentDescription: newStateValue.Description } : {}

        });
    }

    IsDataInValid = () => {
        const { Product = {}, Value } = this.state;

        return isNull(Product) || isNull(Product.Code) || isNull(Value);
    }

    render(): React.ReactNode {
        const { classes, loading } = this.props;
        const { Product = {}, Description = '', Value = '' } = this.state;

        return (
            <div className={classes.main} >
                <div className={`${classes.assignmentPanel} job-review-assignment-panel`}>
                    <div className={classes.actualCharges}>
                        <SummaryTable
                            loadingActualCostsSummary={loading}
                            ActualCostsSummary={this.props.data.inlineObject}
                            data={
                                SummaryTableSchemas.ActualCosts
                            }
                        />
                    </div>
                    <div className={classes.charges}>
                        <SummaryTable
                            className='job-review-assignment-panel-charges-section'
                            loadingChargesSummary={loading}
                            ChargesSummary={this.props.data.inlineObject}
                            data={
                                SummaryTableSchemas.Charges
                            }
                        />
                    </div>
                    <Paper title={'Adjustment'} square={true} style={classesStyles.assignmentCard}>
                        <div className={classes.title}>Adjustment</div>
                        <BasicLookupActionField
                            placeholder={''}
                            label={'Product'}
                            size={'large'}
                            lookupName={'SundryProduct'}
                            suppressDescription={true}
                            display={(Product && Product.Display) || ''}
                            value={(Product && Product?.Code) || ''}
                            onSelectedItemChange={(v) => this.updateState({ Product: v })}
                            ref={(ref) => this.productLookupRef = ref}
                        />
                        <TextField
                            name={'Description'}
                            label={'Description'}
                            size={'medium'}
                            value={Description}
                            onChange={(e) => this.updateState({ Description: e.target.value })}
                            style={{ maxWidth: 'unset' }}
                            autoComplete={'off'}
                        />
                        <TextField
                            name={'Value'}
                            label={'Value'}
                            size={'medium'}
                            value={Value}
                            onChange={(e) => this.updateState({ Value: e.target.value })}
                            style={{ maxWidth: 'unset' }}
                            autoComplete={'off'}
                        />
                        <Button onClick={this.applyAdjustment}
                            style={classesStyles.adjustBtn}
                            variant={'contained'}
                            disabled={this.IsDataInValid()}
                            className={classes.commitActionButton}>
                            <DoneIcon style={{ color: '#009e11' }} /> ADJUST
                        </Button>
                    </Paper>
                    <div className={classes.finalCharges}>
                        <SummaryTable
                            loadingFinalChargesSummary={loading}
                            FinalChargesSummary={this.props.data.inlineObject}
                            data={
                                SummaryTableSchemas.FinalCharges
                            }
                        />
                    </div>
                </div >
            </ div>
        );
    }
}

export default withStyles(classesStyles, { index: 1 })(AssignmentsPanel);
