import * as React from 'react';

import MappedDataGrid from './mapBinding';

const DataGrid = (props: any) => {
  const { module, reqParams, style, doubleClickActionTab, ...restProps } = props;

  return <MappedDataGrid
    {...restProps}
    module={module}
    style={style && style}
    reqParams={reqParams && reqParams}
    doubleClickActionTab={doubleClickActionTab}
  />;
};

export default DataGrid;
