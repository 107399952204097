import {
    asyncSelectors,
    syncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {
    ISortOrder,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';

export interface IServiceItemTypeSearchFacade {
    'HyperlinkReference'?: string;
    'ServiceItemTypeId'?: string;
    'Description'?: string;
}

export interface IServiceItemTypeData {
    selected: string;
    list: IServiceItemTypeSearchFacade[];
    schemas: Inline<IServiceItemTypeSearchFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IServiceItemTypeState extends IExtendedState<IServiceItemTypeData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = '';

const initialData: IServiceItemTypeData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'serviceItemType',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (serviceItemTypeId: string) => serviceItemTypeId,
            callback: (data: IServiceItemTypeData, serviceItemTypeId: string): IServiceItemTypeData => ({ ...data, selected: serviceItemTypeId })
        },
        reset: {
          action: () => null,
          callback: (data: IServiceItemTypeData): IServiceItemTypeData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
            callback: (data: IServiceItemTypeData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IServiceItemTypeData, payload: { records: IObjectified<IServiceItemTypeSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceItemTypeData => {
                const serviceTypeTypes = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: serviceTypeTypes,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        searchById: {
            action: (serviceItemTypeId: string) => ({ ServiceItemTypeId: serviceItemTypeId }),
            successCallback: (data: IServiceItemTypeData, payload: { records: IObjectified<IServiceItemTypeSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IServiceItemTypeData => {
                const serviceItemTypes = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: serviceItemTypes,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IServiceItemTypeData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServiceItemTypeData, payload: { records: IObjectified<IServiceItemTypeSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceItemTypeData => {
                const serviceItemTypes = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newRemoval = payload.records.length;
                const newList = data.list.concat(serviceItemTypes);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },
        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IServiceItemTypeData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServiceItemTypeData, payload: { records: IObjectified<IServiceItemTypeSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServiceItemTypeData => {
                const serviceItemTypes = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newList = serviceItemTypes.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { serviceItemTypeMaintenance: { serviceItemType: IServiceItemTypeState } }) => state.serviceItemTypeMaintenance.serviceItemType,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ServiceItemTypeId === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ServiceItemTypeId.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
    }
);

const syncSelector = syncSelectors(
    (state: { serviceItemTypeMaintenance: { serviceItemType: IServiceItemTypeState } }) => state.serviceItemTypeMaintenance.serviceItemType,
    {
        ServiceItemTypeId: (serviceItemType) => serviceItemType.data.selected,
        isLoading: (serviceItemType) => serviceItemType.search_loading || serviceItemType.searchById_loading,
        loadingNextPage: (serviceItemType) => serviceItemType.fetchNextPage_loading,
        loadingPrevPage: (serviceItemType) => serviceItemType.fetchPrevPage_loading,
        pageSize: (serviceItemType) => serviceItemType.data.pageSize,
        totalPages: (serviceItemType) => serviceItemType.data.totalPages,
        notifications: (serviceItemType) => serviceItemType.notifications,
        error: (serviceItemType) => serviceItemType.error,
    }
);

export const selectors = { ...asyncSelector, ...syncSelector };
