import { IFormViewForm } from 'components/FormView';

const Events: IFormViewForm = {
    id: 'Events',
    layout: {
        rows: 1,
        columns: 1
    },
    fields: [
        {
            id: 1,
            position: {
                row: 1,
                col: 1,
                colspan: 3
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [{
                type: 'EVENTS',
                props: {
                    name: 'InvoiceEnquiryEvents',
                    fetchApi: '/PickSlipEnquiry/Despatch/<DespatchId>/Events'
                }
            }]
        }
    ]
};
export default Events;
