import { fetchPut, fetchPost, fetchGet, objectify, objectifyAddress, objectifyAddressForPost, fetchDelete } from './utils';
import moment from 'moment';

export const getCreditNoteContext = async () => {
  return fetchGet('CreditNotes/CreditNotesContext', {}, 'Inline');
};

export const updateCreditNoteContext = async (data) => {
  return fetchPut('CreditNotes/CreditNotesContext', objectify(data), 'Inline');
};

export const clearCreditNoteContext = async (data) => {
  return fetchPost('CreditNotes/CancelCreditNotes', data, {}, 'Inline');
};

export const applyCreditNoteContext = async (data) => {
  return fetchPost('CreditNotes/CreditNotesContext', objectify(data), {}, 'Inline')
    .then(formatCreditNoteContextResponse);
};

export const creditNoteContextCustomerSearch = async (data) => {
  return fetchPost('CustomTypes/Lookup/Customer/Search', data, {}, 'None');
};

export const getCreditNote = async () => {
  return fetchGet('CreditNotes/CreditNotesDetails', {}, 'Inline')
    .then(formatCreditNoteResponse);
};

export const updateDetails = async (data) => {
  return fetchPut('CreditNotes/CreditNotesDetails', data, 'Inline')
    .then(formatCreditNoteResponse);
};

export const saveCreditNote = async (data) => {
  return fetchPost(
    'CreditNotes/CreditNotesDetails',
    {
      ...data,
      ...objectifyAddressForPost(data && data.PostalAddress, 'Postal', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
      ...objectifyAddressForPost(data && data.DeliveryAddress, 'Delivery', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
    },
    null,
    'Inline'
  )
    .then(formatCreditNoteResponse);
};

export const cancelCreditNote = async () => {
  return fetchPost('CreditNotes/CancelCreditNote', {}, null, 'Inline');
};

export const cancelCreditNoteDetails = async () => {
  return fetchPost('CreditNotes/CancelCreditNotesDetails', {}, null, 'Inline')
    .then(formatCreditNoteResponse);
};

export const getCreditNoteLine = async (LineNumber) => {
  return fetchGet(`CreditNotes/Line/${LineNumber}/LineDetails`, {})
    .then(formatCreditNoteLineResponse);
};

export const calculateRestockingCharge = async (Calculator: any) => {
  const Date = Calculator && Calculator.TranDate && moment(Calculator.TranDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  Calculator.TranDate = Date;

  return fetchPost('/CreditNotes/CalculateRestockingCharge', Calculator, {}, 'None').then((result) => result.RestockingChargeCalculator);
};

export const updateCreditNoteLine = async (data) => {
  return fetchPut('CreditNotes/UpdateLineDetails', { ...data, RestockingChargeCalculator: {} }, 'Inline')
    .then(formatCreditNoteLineResponse);
};

export const createCreditNoteLine = async (data) => {
  return fetchPost('CreditNotes/CreateNewLine', objectify(data), null, 'Inline')
    .then(formatCreditNoteLineResponse);
};

export const cancelCreditNoteLine = async (LineNumber) => {
  return fetchPost(`CreditNotes/Line/${LineNumber}/CancelLineDetails`, null, null, 'Inline')
    .then(formatCreditNoteContextResponse);
};

export const deleteCreditNoteLine = async (LineNumber) => {
  return fetchDelete(`CreditNotes/Line/${LineNumber}/DeleteLine`, null, null, 'Inline')
    .then(formatCreditNoteContextResponse);
};

export const saveCreditNoteLine = async (data) => {
  return fetchPost('CreditNotes/SaveLineDetails', { ...data, RestockingChargeCalculator: {} }, null, 'Inline')
    .then(formatCreditNoteOrCreditNoteLineResponse);
};

export const saveNewCreditNoteLine = async (data) => {
  return fetchPost('CreditNotes/SaveNewCreditNoteLine', data, null, 'Inline')
    .then(formatCreditNoteResponse);
};

export const processCreditNote = async (data) => {
  return fetchPost(
    'CreditNotes/Process',
    {
      ...data,
      CreditNoteConfirmation: data.CreditNoteConfirmation ? data.CreditNoteConfirmation : {},
      PaymentDetails: { Value: null, Type: 'SalePaymentDetails' },
      RestockingCharge: data.RestockingCharge ? data.RestockingCharge : {}
    },
    null, 'Inline')
    .then(formatCreditNoteContextResponse);
};

export const completeProcessCreditNote = async (data) => {
  const { PaymentStatus } = data;
  delete data.PaymentStatus;
  const requestData = {
    ...data,
    CreditNoteConfirmation: data.CreditNoteConfirmation ? data.CreditNoteConfirmation : {},
    RestockingCharge: data.RestockingCharge ? data.RestockingCharge : {}
  };

  return fetchPost(
    'CreditNotes/ProcessComplete',
    cleanRequestData(requestData)
    ,
    { PaymentStatus: PaymentStatus || false }, 'Inline')
    .then(formatCreditNoteContextResponse);
};

const cleanRequestData = (send) => {

  delete send.Lines;
  const toSend = {
    ...objectify(send),
    CreditNoteConfirmation: objectify(send.CreditNoteConfirmation),
    CreditNoteHeader: objectify(send.CreditNoteHeader),
    CreditNoteLines: send.CreditNoteLines.map((line) => (objectify(line))),
    RestockingCharge: objectify(send.RestockingCharge),
    RestockingChargeCalculator: objectify(send.RestockingChargeCalculator),
    PaymentDetails: send.PaymentRequired.Value === false ? null : objectify(send.PaymentDetails),
  };
  deleteRedundant(toSend);

  return toSend;
};

const deleteRedundant = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      if (typeof object[key] === 'object') {
        deleteRedundant(object[key]);
      } else if ((key !== 'Description' && key !== 'Label')
        && (key.includes('-')
          || key.includes('Description')
          || key.includes('Label')
          || key.includes('Display')
          || key.includes('DisplayLabel')
          || key.includes('Thumbnail')
        )) {
        //TODO:Fix this later
        //tslint:disable-next-line: no-dynamic-delete
        delete object[key];
      }
    }
  });
};

function formatCreditNoteResponse(data: any): any {
  if (!data.Details) {
    return data;
  }

  const detailsSchema = { ...data.Details };
  const details = objectify(data.Details);
  const expandedDetails = {
    ...details,
    PostalAddress: objectifyAddress(details, 'Postal', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
    DeliveryAddress: objectifyAddress(details, 'Delivery', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country'])
  };
  const response = {
    Details: {
      inlineObject: expandedDetails,
      schema: detailsSchema
    }
  };

  return response;
}

function formatCreditNoteContextResponse(data: any): any {
  if (!data.CreditNotes) {
    return data;
  }
  const response = {
    ...data,
    CreditNotes: {
      ...data.CreditNotes,
      Lines: (data.CreditNotes.CreditNoteLines || []).map((line) => ({ inlineObject: objectify(line), schema: line })),
    },
    Response: data.CreditNotes
  };

  return response;
}

function formatCreditNoteLineResponse(data: any): any {
  return !data.CreditNoteLine ?
    data :
    {
      ...data,
      CreditNoteLine: {
        inlineObject: objectify(data.CreditNoteLine),
        schema: { ...data.CreditNoteLine, RestockingChargeCalculator: objectify(data.CreditNoteLine.RestockingChargeCalculator) }
      }
    };
}

function formatCreditNoteOrCreditNoteLineResponse(data: any): any {
  if (data.CreditNote) {
    return formatCreditNoteResponse(data);
  } else if (data.CreditNoteLine) {
    return formatCreditNoteLineResponse(data);
  }
}
