import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as creditNoteSelectors, actions as creditNoteActions } from 'ducks/creditNotes/creditNote';
import { actions as creditNoteLineActions } from 'ducks/creditNotes/creditNoteLine';

export const mapStateToProps = state => ({
  gridName: 'CNLines',
  scope: uiSelectors.scope(state),
  isSalesProcessingLookupPanelOpen: uiSelectors.isCreditNotesLookupPanelOpen(state), //to trigger update size based on drawer state change

  creditNoteFetched: creditNoteSelectors.creditNoteFetched(state),
  rowData: creditNoteSelectors.Lines(state),
  columnDefs: creditNoteSelectors.creditNoteLineColumns(state),
  actions: creditNoteSelectors.creditNoteLineActions(state),
  gridOptions: creditNoteSelectors.creditNoteLineGridOptions(state),
  isLoading: creditNoteSelectors.creditNoteLoading(state),
  newLineRenderer: creditNoteSelectors.newLineRenderer(state),
  bypassDeleteConfirmation: true
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,

  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,

  editCreditNoteLine: creditNoteLineActions.editCreditNoteLine,
  createCreditNoteLine: creditNoteLineActions.createCreditNoteLine,
  deleteSelectedRecords: creditNoteLineActions.deleteCreditNoteLine,
  setSelectedOrderLine: creditNoteActions.setSelected,
}
