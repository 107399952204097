import { IChecklistSearchResponse, IChecklistDetailsFacade, IChecklistDetailResponse, ISortOrder, ISearchRequest, IDeleteRecordResponse, IFormInfo } from './../swaggerTypes';
import { IObjectified } from '../utils';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { ILookupResponse } from 'api/customType';

export async function search(query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number; BatchSize?: number }): Promise<{
    records: IObjectified<IChecklistDetailsFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

    return Api.postInlineCollectionResponse<ISearchRequest, IChecklistSearchResponse, 'Checklists'>(
        'ChecklistMaintenance/SearchChecklists', { SearchText, Sort, BatchPage, BatchSize })
        .then((response) => {
            if (response && response.Checklists[0] && response.Checklists[0].Questions) {
                (response.Checklists[0].Questions as any).sort((a, b) => (a.Sequence.Value - b.Sequence.Value));
            }
            const records = asObjectifiedArray(response, 'Checklists');
            const batchInformation = (response as unknown as IChecklistSearchResponse).BatchInformation;

            return {
                records,
                currPage: batchInformation ? batchInformation.BatchPage : 1,
                nextPage: batchInformation && !batchInformation.EndOfData,
                prevPage: batchInformation && batchInformation.BatchPage > 1
            };
        });
}

export async function searchById(query: { ChecklistCode: string }): Promise<{
    records: IObjectified<IChecklistDetailsFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { ChecklistCode } = query;

    return fetch(ChecklistCode).then((response) => {
        const records = isNull(response.Forms) ? [response.Checklist] : [];

        return {
            Forms: response.Forms,
            records: records,
            currPage: 1,
            nextPage: false,
            prevPage: false
        };
    });

}
export interface IObjectifiedChecklistDetailResponse {
    Checklist: IObjectified<IChecklistDetailsFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
}

export async function fetch(ChecklistCode: string): Promise<IObjectifiedChecklistDetailResponse> {

    return Api.getInlineResponse<IChecklistDetailResponse, 'Checklist'>(
        `ChecklistMaintenance/Checklist/${ChecklistCode}`)
        .then((response) => {
            if (response && response.Checklist && response.Checklist.Questions) {
                (response.Checklist.Questions as any).sort((a, b) => (a.Sequence.Value - b.Sequence.Value));
            }

            return {
                ...response,
                Checklist: asObjectified(response, 'Checklist')
            };
        });
}

export async function create(query: IChecklistDetailsFacade):
    Promise<IObjectifiedChecklistDetailResponse> {

    return Api.postInlineResponse<IChecklistDetailsFacade, IChecklistDetailResponse, 'Checklist'>(
        'ChecklistMaintenance/Checklist/CreateChecklist', query)
        .then((response) => {

            return {
                ...response,
                Checklist: asObjectified(response, 'Checklist')
            };
        });
}

export async function update(query: IChecklistDetailsFacade): Promise<IObjectifiedChecklistDetailResponse> {

    const { ChecklistCode } = query;

    return Api.putInlineResponse<IChecklistDetailsFacade, IChecklistDetailResponse, 'Checklist'>(
        `ChecklistMaintenance/Checklist/${ChecklistCode}`, query)
        .then((response) => {

            return {
                ...response,
                Checklist: asObjectified(response, 'Checklist')
            };
        });
}

export async function deleteChecklist(ChecklistCode: string): Promise<IDeleteRecordResponse> {

    return Api.delete<IDeleteRecordResponse>(
        `ChecklistMaintenance/Checklist/${ChecklistCode}`)
        .then((response) => response);
}

export const fetchChecklistLookupOptions = async (): Promise<ILookupResponse> => {
    return Api.get<ILookupResponse>('CustomTypes/Lookup/ChecklistDataType');
};
