import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  deleteKey, IExtendedState, IDataAction, SuccessAction
} from '../utils';

export interface ISupplierData {
  selected: number;
  details: any;
  list: any[];
  primaryContacts: any[];
  schemas: any;
  contacts: any[];
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
}

export interface ISupplierState extends IExtendedState<ISupplierData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  getContactDetails_loading?: boolean;
  getPrimaryContacts_loading?: boolean;
  getSupplierDetails_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (supplierId) => ({ supplierId }),
    asyncs: {
      search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
      searchById: (id: number) => id,
      fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      update: (data) => (data),
      create: (data) => (data),
      remove: (supplierId) => supplierId,
      getContactDetails: ({ SearchText, Sort, Supplier, ContactNumber }) => ({ SearchText, Sort, BatchPage: 1, Supplier, ContactNumber }),
      getSupplierDetails: (supplierId) => ({ supplierId }),
      getPrimaryContacts: (supplierId) => ({ supplierId })
    }
  },
  'supplier');

const NOT_SELECTED = -1;
const initialState = asyncInitialState<ISupplierData>({
  selected: NOT_SELECTED,
  details: null,
  list: null,
  schemas: null,
  contacts: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  primaryContacts: [],
  currSearchText: ''
});

export default (state: ISupplierState = initialState, action: IDataAction): ISupplierState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.supplierId;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.update:
    case types.create:
    case types.remove:
    case types.getContactDetails:
    case types.getPrimaryContacts:
    case types.getSupplierDetails:
      return asyncOnRequest(state, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action);
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const suppliers = successAction.payload.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = successAction.payload.records.map((combinedObject) => combinedObject.schema);

          return {
            ...data,
            list: suppliers,
            schemas: schemas,
            nextPage: successAction.payload.nextPage && parseInt(successAction.payload.currPage) + 1,
            prevPage: successAction.payload.prevPage && parseInt(successAction.payload.currPage) - 1,
            currPage: successAction.payload.currPage
          };
        },
        { fetch: true });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const suppliers = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newRemoval = result.records.length;
          const newList = data.list.concat(suppliers);
          const newSchemas = data.schemas.concat(schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
            nextPage: result.nextPage && parseInt(result.currPage) + 1,
            prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
          };
        },
        { fetch: true });

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const suppliers = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newList = suppliers.slice(0, data.removeWhenPrev).concat(data.list);
          const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
            prevPage: result.prevPage && result.currPage - 1,
            nextPage: (newList.length > data.pageSize * data.totalPages) && (parseInt(result.currPage) + data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
          };
        },
        { fetch: true });

    case types.saga.update.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const newSupplier = successAction.payload.inlineObject || successAction.payload;
          const newSchema = successAction.payload.schema;
          const prevSuppliers = deleteKey(data.list, newSupplier.Supplier, 'Supplier', newSupplier);
          const prevSchemas = deleteKey(data.schemas, newSchema.Supplier.Value, 'Supplier', newSchema);

          return {
            ...data,
            list: prevSuppliers,
            schemas: prevSchemas,
          };
        },
        { update: true });
    case types.saga.create.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const newSupplier = successAction.payload.inlineObject || successAction.payload;
          const newSchema = successAction.payload.schema;
          const prevSuppliers = data.list;
          const prevSchemas = data.schemas;
          prevSchemas.push(newSchema);
          prevSuppliers.push(newSupplier);

          return {
            ...data,
            list: prevSuppliers,
            schemas: prevSchemas,
            selected: newSupplier.Supplier
          };
        },
        { create: true });
    case types.saga.remove.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ISupplierData, successAction: SuccessAction) => {
          const prevSuppliers = deleteKey(data.list, successAction.payload, 'Supplier');
          const prevSchemas = deleteKey(data.schemas, successAction.payload, 'Supplier');

          return {
            ...data,
            list: prevSuppliers,
            schemas: prevSchemas
          };
        },
        { delete: true });
    case types.saga.getContactDetails.success:
      return asyncOnSuccess(state, action, (data: ISupplierData, successAction: SuccessAction) => {
        const contacts = successAction.payload;

        return {
          ...data,
          contacts: contacts
        };
      });
    case types.saga.getPrimaryContacts.success:
      return asyncOnSuccess(state, action, (data: ISupplierData, successAction: SuccessAction) => {
        const contacts = successAction.payload;

        return {
          ...data,
          primaryContacts: contacts.values
        };
      });
    case types.saga.getSupplierDetails.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const details = successAction.payload;

          return {
            ...data,
            details: details
          };
        },
        { fetch: true });
    case types.saga.update.failure:
    case types.saga.create.failure:
    case types.saga.search.failure:
    case types.saga.searchById.failure:
    case types.saga.remove.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
    case types.saga.getContactDetails.failure:
    case types.saga.getPrimaryContacts.failure:
      return asyncOnError(state, action);
    case types.saga.getSupplierDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.Supplier === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.Supplier && element.Supplier.Value === data.selected)) || null,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
    supplierDetails: (data) => data.details,
    contacts: (data) => data.contacts,
    primaryContacts: (data) => data.primaryContacts
  }
);

const syncSelector = {
  isLoading: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.search_loading || state.supplierEnquiry.supplier.searchById_loading,
  detailLoading: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.getContactDetails_loading,
  primaryContactsLoading: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.getPrimaryContacts_loading,
  loadingNextPage: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.fetchNextPage_loading,
  loadingPrevPage: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.fetchPrevPage_loading,
  pageSize: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.data.pageSize,
  totalPages: (state: { supplierEnquiry: { supplier: ISupplierState } }) => state.supplierEnquiry.supplier.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
