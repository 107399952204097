import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as pickSlipSelectors } from 'ducks/pickSlipEnquiry/slipDetails';

const mapStateToProps = (state) => ({
  slipDetailsSummary: pickSlipSelectors.slipDetailsSummary(state),
  isLoading: pickSlipSelectors.loadingSummary(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
