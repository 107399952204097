import { takeLatest, Effect, call, select, put } from 'redux-saga/effects';
import { change, getFormValues } from 'redux-form';
import { SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import { types as serviceActivityTypes, actions as serviceActivityActions, selectors as serviceActivitySelectors, IServiceActivityFilters } from 'ducks/serviceActivityScheduling/serviceActivities';
import { connectSagaToApi, callApi, getErrorMessage } from './../utils';
import * as api from 'api/serviceScheduling/serviceActivities';
import { IDataAction } from 'ducks/utils';
import { isNull } from 'utils/utils';
import moment from 'moment';
import { ISchedulerJobSearchFacade } from 'api/swaggerTypes';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* getCustomerDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getCustomerDetails;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    const { CustomerName, Contact, Email, Phone } = response.inlineObject;

    yield put(change('NewJobForm', 'CustomerName', CustomerName));
    yield put(change('NewJobForm', 'Contact', Contact));
    yield put(change('NewJobForm', 'Phone', Phone));
    yield put(change('NewJobForm', 'Email', Email));
  }

  yield callApi(
    call(api.getCustomerDetails, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* getDeliveryAddress(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getDeliveryAddress;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    const { address } = response.inlineObject;

    yield put(change('NewJobForm', 'address', address));
  }

  yield callApi(
    call(api.getDeliveryAddress, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* getJobActivities(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getDeliveryAddress;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    const activities = response && response.map((combinedObject) => combinedObject.inlineObject);

    yield put(change('NewJobForm', 'InitialActivities', activities));
  }

  yield callApi(
    call(api.getJobActivities, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  );
}

function* newSchedulerEventConfirm(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.newSchedulerEventConfirm;

  let endPoint;
  switch (action.data.Schedule.itemType) {
    case 'ServiceJob':
      endPoint = api.scheduleJob;
      break;
    case 'ServiceActivity':
      endPoint = api.scheduleActivity;
      break;
    default:
      return;
  }

  yield callApi(
    call(endPoint, action.data),
    success,
    failure,
    { dialogActionCallEffect: newSchedulerEventConfirm }
  );
}

function* scheduleGeneralActivity(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.scheduleGeneralActivity;

  yield callApi(
    call(api.scheduleGeneralActivity, action.data),
    success,
    failure,
    { dialogActionCallEffect: scheduleGeneralActivity }
  );
}
function* moveSchedulerEventConfirm(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.moveSchedulerEventConfirm;
  let endPoint;
  switch (action.data.Schedule.itemType) {
    case 'ServiceJob':
      endPoint = api.moveJob;
      break;
    case 'ServiceActivity':
      endPoint = api.moveActivity;
      break;
    case 'General':
      endPoint = api.updateGeneralActivity;
      break;
    default:
      return;
  }

  yield callApi(
    call(endPoint, action.data),
    success,
    failure,
    { dialogActionCallEffect: moveSchedulerEventConfirm }
  );
}

function* deleteActivitySchedule(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.deleteActivitySchedule;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {

    // On deleting some scheduled activity or job. We are inserting it back to the search lookup drawer list.
    // If its not General and its status matches with current selected job filter.

    if (action.data.ActivityType !== 'General') {
      yield put(serviceActivityActions.reAddDeletedJobToList(action.data.ServiceJobId));
    }
  }

  let endPoint;
  switch (action.data.ActivityType) {
    case 'ServiceJob':
      endPoint = api.deleteJob;
      break;
    case 'ServiceActivity':
      endPoint = api.deleteActivitySchedule;
      break;
    case 'General':
      endPoint = api.deleteGeneralActivity;
      break;
    default:
      return;
  }
  yield callApi(
    call(endPoint, action.data),
    success,
    failure,
    { dialogActionCallEffect: deleteActivitySchedule, postApiSuccessCallEffect }
  );
}

function* reAddDeletedJobToList(action: IDataAction): IterableIterator<Effect> {
  const list: ISchedulerJobSearchFacade[] = yield select(serviceActivitySelectors.all);
  const { success, failure } = serviceActivityActions.saga.reAddDeletedJobToList;

  try {

    // it will find closest job index so that we can insert deleted jon on that index
    const getClosestJobIndex = (deletedJob) => {
      const deletedJobNumberDigits = Number(deletedJob.ServiceJobId.match(/\d+/g).join(''));

      const closestJob = list.reduce((prev, curr) => {
        const currJobNumDigits = Number(curr.ServiceJobId.match(/\d+/g).join(''));
        const prevJobNumDigits = Number(prev.ServiceJobId.match(/\d+/g).join(''));

        return (Math.abs(currJobNumDigits - deletedJobNumberDigits) < Math.abs(prevJobNumDigits - deletedJobNumberDigits) ? curr : prev);
      });

      return list.findIndex((j) => j.ServiceJobId === closestJob.ServiceJobId);
    };

    if (action.data.DeletedServiceJobId && !list.find((job) => job.ServiceJobId === action.data.DeletedServiceJobId)) {
      const jobDetailsResponse: api.IJobDetailsResponse = yield call(api.getJobDetails, { ServiceJobId: action.data.DeletedServiceJobId });
      const jobFilter: IServiceActivityFilters = yield select(serviceActivitySelectors.selectedFilters);
      const jobDetails: any = jobDetailsResponse.JobDetails.inlineObject;

      if (jobDetails.ScheduleStatus === jobFilter.JobStatusFilter
        || (jobFilter.JobStatusFilter === 'Unscheduled' && jobDetails.ScheduleStatus === 'Partial')) {

        const closestJobIndex = getClosestJobIndex(jobDetails);
        const closestJobNumDigits = Number(list[closestJobIndex].ServiceJobId.match(/\d+/g).join(''));
        const deletedJobNumberDigits = Number(jobDetails.ServiceJobId.match(/\d+/g).join(''));

        const indexToPlace = (closestJobNumDigits > deletedJobNumberDigits) ? closestJobIndex : closestJobIndex + 1;
        list.splice(indexToPlace, 0, jobDetails);
      }
    }

    yield put(success(list));

  } catch (err) {
    yield put(failure(getErrorMessage(err)));

  }
}

function* createNewJob(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getDeliveryAddress;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    if (response.Forms) { return; }

    const ServiceJobId = response.ServiceJob && response.ServiceJob.inlineObject.ServiceJobId;
    const formValues: any = yield select(getFormValues('serviceActivityFilters'));
    if (!isNull(formValues)) {
      yield put(serviceActivityActions.search({ SearchText: '', filters: { SalesEntity: formValues.SalesEntity, JobStatusFilter: formValues.JobStatusFilter } }));
    }
    showSnackBar({ variant: 'success', message: `Service job created: ${ServiceJobId}`, time: -1 });

  }

  yield callApi(
    call(api.createNewJob, action.data),
    success,
    failure,
    { dialogActionCallEffect: createNewJob, postApiSuccessCallEffect }
  );
}

function* updateRoster(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.updateRoster;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    showSnackBar({ variant: 'success', message: 'Roster Posted successfully.' });
    const salesEntity = yield select(serviceActivitySelectors.salesEntity);
    const schedulerData: SchedulerData = yield select(serviceActivitySelectors.schedulerData);

    if (salesEntity) {
      yield put(serviceActivityActions.getResources(salesEntity, schedulerData.startDate, schedulerData.endDate));
      yield put(serviceActivityActions.getScheduledActivities(salesEntity, schedulerData.startDate, schedulerData.endDate));
    }
  }

  yield callApi(
    call(api.updateRoster, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateRoster, postApiSuccessCallEffect }
  );
}

function* search(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.search;

  function* postApiSuccessCallEffect(): IterableIterator<Effect> {
    const defaultServiceDueBefore = moment().add(1, 'months').format('DD/MM/YYYY');
    const SalesEntity = yield select(serviceActivitySelectors.salesEntity);

    const payload = {
      SalesEntity,
      StartDate: moment(defaultServiceDueBefore, 'DD/MM/YYYY').subtract(1, 'months').format('YYYY/MM/DD'),
      EndDate: moment(defaultServiceDueBefore, 'DD/MM/YYYY').format('YYYY/MM/DD')
    };

    yield put(serviceActivityActions.getServiceAgreementJobs(payload));
  }

  yield callApi(
    call(api.search, action.data),
    success,
    failure,
    { dialogActionCallEffect: search, postApiSuccessCallEffect }
  );

  yield put(serviceActivityActions.fetchJobsToReview({ SearchText: '', filters: action.data }));
  const salesEntity = yield select(serviceActivitySelectors.salesEntity);
  const schedulerData: SchedulerData = yield select(serviceActivitySelectors.schedulerData);

  if (salesEntity) {
    yield put(serviceActivityActions.getResources(salesEntity, schedulerData.startDate, schedulerData.endDate));
    yield put(serviceActivityActions.getScheduledActivities(salesEntity, schedulerData.startDate, schedulerData.endDate));
  }
}

function* fetchJobsForReview(action: any): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.fetchJobsToReview;
  yield callApi(
    call(api.fetchJobsForReview, { ...action.data, JobStatusFilter: 'ReadyForReview', responseMetadataFormat: 'inline', BatchSize: 99 }),
    success,
    failure,
    { dialogActionCallEffect: fetchJobsForReview }
  );
}

function* fetchReviewJobSummary(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.fetchReviewJobSummary;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    if (isNull(response.Forms)) {
      yield put(serviceActivityActions.fetchActivityImages(response));
    }
  }

  yield callApi(
    call(api.fetchJobSummary, action.data),
    success,
    failure,
    { dialogActionCallEffect: fetchReviewJobSummary, postApiSuccessCallEffect }
  );
}

function* fetchActivityImages(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.fetchActivityImages;

  const { ServiceJob = {} } = action.data;
  const activities = ServiceJob.inlineObject.Activities;
  try {
    for (const activity of activities) {
      for (const image of activity.Images) {
        const response = yield call(api.getActivityImage, { ActivityId: image.ActivityId, ImageId: image.ImageId });
        image.data = response;
      }
    }

    yield put(success({ ServiceJob }));
  } catch (err) {
    yield put(failure(err));
  }
}

function* completeJobReview(): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.fetchReviewJobSummary;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    if (response.Status) {
      showSnackBar({ variant: 'success', message: 'Job review completed successfully.', time: -1 });
    }

    const SalesEntity = yield select(serviceActivitySelectors.salesEntity);

    yield call(fetchJobsForReview, { data: { SalesEntity } });
  }
  const ServiceJobId = yield select(serviceActivitySelectors.serviceJobId);

  yield callApi(
    call(api.completeReview, { ServiceJobId }),
    success,
    failure,
    { dialogActionCallEffect: completeJobReview, postApiSuccessCallEffect }
  );
}

function* applyReviewJobAdjustments(action: any): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.applyReviewJobAdjustments;
  yield callApi(
    call(api.applyAdjustment, action.data),
    success,
    failure,
    { dialogActionCallEffect: applyReviewJobAdjustments }
  );
}

function* getResources(action: any): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getResources;

  yield callApi(
    call(api.getResources, action.data),
    success,
    failure,
    { dialogActionCallEffect: getResources }
  );
}

function* getScheduledActivities(action: any): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getScheduledActivities;

  yield callApi(
    call(api.getScheduledActivities, action.data),
    success,
    failure,
    { dialogActionCallEffect: getScheduledActivities }
  );
}

function* getServiceAgreementJobs(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = serviceActivityActions.saga.getServiceAgreementJobs;

  yield callApi(
    call(api.getServiceAgreementJobs, action.data),
    success,
    failure,
    { dialogActionCallEffect: getServiceAgreementJobs }
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(serviceActivityTypes.search, search);
  yield takeLatest(serviceActivityTypes.reAddDeletedJobToList, reAddDeletedJobToList);
  yield takeLatest(serviceActivityTypes.fetchJobsToReview, fetchJobsForReview);
  yield takeLatest(serviceActivityTypes.fetchReviewJobSummary, fetchReviewJobSummary);
  yield takeLatest(serviceActivityTypes.completeJobReview, completeJobReview);
  yield takeLatest(serviceActivityTypes.fetchActivityImages, fetchActivityImages);
  yield takeLatest(serviceActivityTypes.applyReviewJobAdjustments, applyReviewJobAdjustments);
  yield takeLatest(serviceActivityTypes.fetchNextPage, connectSagaToApi(serviceActivityActions, 'fetchNextPage', api.search));
  yield takeLatest(serviceActivityTypes.fetchPrevPage, connectSagaToApi(serviceActivityActions, 'fetchPrevPage', api.search));
  yield takeLatest(serviceActivityTypes.searchById, connectSagaToApi(serviceActivityActions, 'searchById', api.searchById));
  yield takeLatest(serviceActivityTypes.getResources, getResources);
  yield takeLatest(serviceActivityTypes.getScheduledActivities, getScheduledActivities);
  yield takeLatest(serviceActivityTypes.getSelectedJobDetails, connectSagaToApi(serviceActivityActions, 'getSelectedJobDetails', api.getJobDetails));
  yield takeLatest(serviceActivityTypes.updateRoster, updateRoster);
  yield takeLatest(serviceActivityTypes.createNewJob, createNewJob);
  yield takeLatest(serviceActivityTypes.getCustomerDetails, getCustomerDetails);
  yield takeLatest(serviceActivityTypes.getDeliveryAddress, getDeliveryAddress);
  yield takeLatest(serviceActivityTypes.getJobActivities, getJobActivities);
  yield takeLatest(serviceActivityTypes.newSchedulerEventConfirm, newSchedulerEventConfirm);
  yield takeLatest(serviceActivityTypes.scheduleGeneralActivity, scheduleGeneralActivity);
  yield takeLatest(serviceActivityTypes.moveSchedulerEventConfirm, moveSchedulerEventConfirm);
  yield takeLatest(serviceActivityTypes.deleteActivitySchedule, deleteActivitySchedule);
  yield takeLatest(serviceActivityTypes.getServiceAgreementJobs, getServiceAgreementJobs);
}
