import { Payload, usePost } from 'api/reactQuery/queryService';
import { Api } from '../baseApi';
import {
    IProductSearchFacadeResponse,
    IProductSearchSalesRequest,
    IProductSearchFacade,
    IProductSerialSearchRequest,
    IProductLotSearchRequest,
    IProductLotSearchFacadeResponse,
    IProductLotSearchFacade,
    IProductSerialSearchFacade,
    IProductSerialSearchFacadeResponse
} from 'api/swaggerTypes';
import DataSource from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { createDataSource } from 'components/common/DataGridDevEx/DataGrid.hooks';

export interface IProductLotLookupSearchInput {
    SearchText: string;
    WarehouseEntity?: string;
    ProductId?: number;
    DocumentType?: string;
    DocumentId?: number;
    Worksale?: string;
    WorksaleId?: number;
    LineNumber?: number;
    ReservationNumber?: number;
    CheckExactMatch?: boolean;
}

export async function fetch(Type: 'Search' | 'Lookup', SearchText: string, CustomerId?: number, WarehouseEntity?: string, BatchPage?: number, BatchSize?: number, exactMatch?: boolean): Promise<IProductSearchFacadeResponse> {
    return Api.post<IProductSearchSalesRequest, IProductSearchFacadeResponse>(
        'Product/SearchProductsSales',
        { SearchText, CustomerId, WarehouseEntity, BatchPage, BatchSize, Type, CheckExactMatch: exactMatch });
}

export async function fetchProductSerials(Type: 'Search' | 'Lookup', query: IProductLotLookupSearchInput, BatchPage?: number, BatchSize?: number): Promise<IProductSerialSearchFacadeResponse> {
    return Api.post<IProductSerialSearchRequest, IProductSerialSearchFacadeResponse>(
        'Product/SearchProductSerial',
        { ...query, BatchPage, BatchSize, Type });
}
export async function fetchProductBin(Type: 'Search' | 'Lookup', query: IProductLotLookupSearchInput, BatchPage?: number, BatchSize?: number): Promise<IProductLotSearchFacadeResponse> {
    return Api.post<IProductLotSearchRequest, IProductLotSearchFacadeResponse>(
        'Product/SearchProductBin',
        { ...query, BatchPage, BatchSize, Type });

}
type ProductBinPayload = Payload<IProductLotSearchRequest & IProductLotLookupSearchInput & { Type: 'Search' | 'Lookup'; BatchPage?: number; BatchSize?: number }>;

export async function fetchProductLots(Type: 'Search' | 'Lookup', query: IProductLotLookupSearchInput, BatchPage?: number, BatchSize?: number): Promise<IProductLotSearchFacadeResponse> {
    return Api.post<IProductLotSearchRequest, IProductLotSearchFacadeResponse>(
        'Product/SearchProductLot',
        { ...query, BatchPage, BatchSize, Type });
}

const DEFAULT_SKIP = 0;
const DEFAULT_TAKE = 10;

export async function search(SearchText: string, CustomerId?: number, WarehouseEntity?: string): Promise<DataSource> {
    return new DataSource({
        key: 'KeyId',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;

            const data = await fetch('Search', SearchText, CustomerId, WarehouseEntity, BatchPage, BatchSize)
                .then((response) => response.Products);

            return data;
        },
    });
}

export async function lookup(SearchText: string, CustomerId?: number, WarehouseEntity?: string, exactMatch?: boolean): Promise<DataSource> {
    return new DataSource({
        key: 'KeyId',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;

            const data = await fetch('Lookup', SearchText, CustomerId, WarehouseEntity, BatchPage, BatchSize, exactMatch)
                .then((response) => response.Products);

            return data;
        },
    });
}

export async function serialLookup(query: IProductLotLookupSearchInput): Promise<DataSource> {
    return new DataSource({
        key: 'LotSerial',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductSerialSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;
            const data = await fetchProductSerials(
                'Lookup', query, BatchPage, BatchSize)
                .then((response) => response.ProductSerials);

            return data;
        },
    });
}

export async function lotLookup(query: IProductLotLookupSearchInput): Promise<DataSource> {
    return new DataSource({
        key: 'LotSerial',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductLotSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;
            const data = await fetchProductLots(
                'Lookup', query, BatchPage, BatchSize)
                .then((response) => response.ProductLots);

            return data;
        },
    });
}

export async function serialSearch(query: IProductLotLookupSearchInput): Promise<DataSource> {
    return new DataSource({
        key: 'LotSerial',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductSerialSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;

            const data = await fetchProductSerials('Search', query, BatchPage, BatchSize)
                .then((response) => response.ProductSerials);

            return data;
        },
    });
}

export async function lotSearch(query: IProductLotLookupSearchInput): Promise<DataSource> {
    return new DataSource({
        key: 'LotSerial',
        loadMode: 'processed',
        load: async function (loadOptions: LoadOptions): Promise<IProductLotSearchFacade[]> {
            const skip = loadOptions.skip || DEFAULT_SKIP;
            const take = loadOptions.take || DEFAULT_TAKE;

            const BatchPage = Math.ceil((skip / take) + 1);
            const BatchSize = take;

            const data = await fetchProductLots('Search', query, BatchPage, BatchSize)
                .then((response) => response.ProductLots);

            return data;
        },
    });
}

export const useProductBinLookupApis = () => {
    const fetchBins = usePost<ProductBinPayload, IProductLotSearchFacadeResponse>('Product/SearchProductBin');

    const fetchProductBins = async (Type: 'Search' | 'Lookup', query: IProductLotLookupSearchInput): Promise<DataSource> =>
        createDataSource('Bin', {
            fetch: (BatchPage?: number, BatchSize?: number, sort?: string, filter?: any) => fetchBins.mutateAsync({
                ...query,
                Type,
                BatchPage,
                BatchSize,
                sort,
                filter
            }).then((response) => (response as any)?.ProductBins)
        });

    return { fetchProductBins };
};
