import { IState } from './RunDynamicExtract.reducer';

export interface IActions {
    [DynamicExtractActionTypes.setWizardDetails]?: IState['WizardDetails'];
    [DynamicExtractActionTypes.setErrorState]?: IState['ErrorState'];
    [DynamicExtractActionTypes.setCurrentPanel]?: IState['CurrentPanel'];
}

export enum DynamicExtractActionTypes {
    setWizardDetails = 'setWizardDetails',
    setErrorState = 'setErrorState',
    setCurrentPanel = 'setCurrentPanel'
}
