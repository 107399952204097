import { IFormViewForm } from 'components/FormView';

const PurchaseOrderDeliveriesForm: IFormViewForm = {
    id: 'PurchaseOrderDeliveryForm',
    layout: {
        rows: 3,
        columns: 3
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true,
                label: 'Deliver to'
            },
            children: [
                {
                    id: '0',
                    type: 'EXTENDED_TEXT_FIELD',
                    props: {
                        label: 'Warehouse',
                        name: 'Warehouse',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '1',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Site',
                        name: 'SiteCode',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        lookupName: 'EntityDeliverySite',
                        isEntityScoped: true,
                    }
                },
                {
                    id: '2',
                    type: 'ADDRESS_BLOCK_EX',
                    props: {
                        label: '',
                        name: 'DeliveryAddress',
                        required: false,
                        type: 'extended',
                        hideCountry: true,
                    }
                },
                {
                    id: '3',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Contact',
                        name: 'DeliveryContact',
                        placeholder: '',
                        size: 'large',
                        required: false,
                    }
                },
            ]
        },
    ]
};
export default PurchaseOrderDeliveriesForm;
