import { call, takeLatest } from 'redux-saga/effects';

import { types as lineDetailsType, actions as lineDetailsActions } from 'ducks/salesOrderEnquiry/orderLineDetails';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesOrderEnquiry/orderLineDetails';

function* getLineDetails(action) {
  const { success, failure } = lineDetailsActions.saga.fetchLineDetails;
  yield callApi(
    call(api.getLineDetails, action.data),
    success,
    failure
  )
}

function* getLineSummary(action) {
  const { success, failure } = lineDetailsActions.saga.fetchLineSummary;
  yield callApi(
    call(api.getLineSummary, action.data),
    success,
    failure
  )
}

export default function* rootLineDetailsSaga() {
  yield takeLatest(lineDetailsType.fetchLineDetails, getLineDetails)
  yield takeLatest(lineDetailsType.fetchLineSummary, getLineSummary)
}