
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import {
  selectors as activitySchedulerSelectors,
  actions as activitySchedulerActions,
} from 'ducks/serviceActivityScheduling/serviceActivities';
import { IReviewJobWizardProps } from '../../interfaces';
import ReviewJobWizard from './ReviewJobWizard';
import { bindActionCreators, Dispatch } from 'redux';

type IStateProps = Pick<IReviewJobWizardProps,
  'operationMode' | 'data' | 'jobSummaryLoading'>;

const mapStateToProps = (state: IApplicationState): IStateProps => ({
  operationMode: operationSelectors.operationMode(state),
  data: activitySchedulerSelectors.reviewJobSummary(state),
  jobSummaryLoading: activitySchedulerSelectors.jobSummaryLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    fetchReviewJobSummary: activitySchedulerActions.fetchReviewJobSummary,
    completeJobReview: activitySchedulerActions.completeJobReview,
    changeOperationMode: operationActions.changeOperationMode,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReviewJobWizard);
