import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as checklistActions, selectors as checklistSelectors } from 'ducks/checklistMaintenance/checklist';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';
import { IChecklistDetailsFacade } from 'api/swaggerTypes';

const ChecklistSearchLookUpDrawer = buildSearchLookUpDrawer<IChecklistDetailsFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IChecklistDetailsFacade>,
    'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
    'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
    'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IChecklistDetailsFacade>,
    'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
    {
        variant: 'Checklist',
        searchPlaceholder: 'Search for checklist...',
        operationMode: operationSelectors.operationMode(state),
        sortingFilters: [
            {
                filter: 'ChecklistCode',
                label: 'Checklist Code'
            },
            {
                filter: 'Description',
                label: 'Description'
            }
        ],
        records: checklistSelectors.all(state) || [],
        selectedRecord: checklistSelectors.selected(state),
        selectedSortFilter: 'ChecklistCode',
        selectedSortDirection: 'Ascending',
        open: uiSelectors.isCustomerLookUpOpen(state),
        isLoading: checklistSelectors.isLoading(state),
        nextPage: checklistSelectors.nextPage(state),
        prevPage: checklistSelectors.prevPage(state),
        pageSize: checklistSelectors.pageSize(state),
        totalPages: checklistSelectors.totalPages(state),
        loadingPrevPage: checklistSelectors.loadingPrevPage(state),
        loadingNextPage: checklistSelectors.loadingNextPage(state),
        searchParams: {},
        keyField: 'ChecklistCode',
        fieldsToDisplay: ['ChecklistCode', null, 'Description', null],
        changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
            if (selectedRecord) {
                matchParams.set('ChecklistCode', selectedRecord.ChecklistCode);
            }

            return matchParams.toString();
        },
        getSearchFromMatchParams: (matchParams: URLSearchParams) => {
            const checklistId = parseInt(matchParams.get('ChecklistCode'));
            if (checklistId) {
                return {
                    id: checklistId,
                };
            }

            return null;
        },
    });

const actionCreators: DispatchProps = {
    search: checklistActions.search,
    searchById: checklistActions.searchById,
    changeSelectedRecord: checklistActions.setSelected,
    onToggle: uiActions.toggleCustomerLookUp,
    fetchNextPage: checklistActions.fetchNextPage,
    fetchPrevPage: checklistActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistSearchLookUpDrawer);
