import { connect } from 'react-redux';
import SaleDeposits from './SaleDeposits';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as paymentEntrySelectors } from 'ducks/salesOrderEnquiry/paymentEntryDetails';
import { selectors as depositDetailsSelectors } from 'ducks/salesOrderEnquiry/depositDetails';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  depositDetailsSummary: depositDetailsSelectors.summary(state),
  paymentEntryDetails: paymentEntrySelectors.paymentEntryDetails(state),
  loadingPaymentEntrySummary: depositDetailsSelectors.loadingSummary(state),
});

export default connect(mapStateToProps, {})(SaleDeposits);
