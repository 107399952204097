import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/customerQuoteEnquiry/bomReservationSerials';
import { selectors as quoteLineSelectors } from 'ducks/customerQuoteEnquiry/quote';
import { fetch } from 'api/customerQuoteEnquiry/bomReservationSerials';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'ReservationSerials',
  columnDefs: lineSelectors.lineColumns(state),
  rowData: lineSelectors.lines(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  reqParams: (quoteLineSelectors.selected(state) || params.get('Quote'))
    &&
  {
    QuoteId: (quoteLineSelectors.selected(state) && quoteLineSelectors.selected(state).Quote) || params.get('Quote'),
    LineNumber: (quoteLineSelectors.selectedQuoteLine(state) && quoteLineSelectors.selectedQuoteLine(state).LineNumber) || params.get('LineNumber')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
