import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IEnquiriesData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IEnquiriesState extends IExtendedState<IEnquiriesData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {

    }
  },
  'enquiries');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IEnquiriesData>({
  selected: null,
  selectedFilters: {
    EntityView: 'MPRO',
    Entity: '',
    FromDate: '',
    ToDate: '',
  },
  filterRow: {
    formName: 'CEEnquiriesFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    { headerName: 'Entity', field: 'SalesEntity', minWidth: 100, suppressSorting: true },
    { headerName: 'Enquiry', field: 'EnquiryNumber', minWidth: 150 },
    { headerName: 'Entered', field: 'Entered', minWidth: 180 },
    { headerName: 'User ID', field: 'UserId', minWidth: 180, suppressSorting: true },
    { headerName: 'Name', field: 'UserIdLabel', minWidth: 180, suppressSorting: true }
  ]
});

export default (state: IEnquiriesState = initialState, action: IDataAction): IEnquiriesState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  selected: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.selected,
  gridOptions: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.gridOptions,
  columns: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.Columns,
  Actions: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.Actions,
  filterRow: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.filterRow,
  selectedFilters: (state: { customerEnquiry: { enquiries: IEnquiriesState } }) => state.customerEnquiry.enquiries.data.selectedFilters
});
