import { connect } from 'react-redux';
import Details from './Details';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { getFormValues, reset, isDirty } from 'redux-form';
import { Dispatch, bindActionCreators } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);
const FORM_NAME = 'PickSlipDetailsForm';

const mapStateToProps = (state: IApplicationState) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    dirty: isDirty(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
    selectedCustomerId: pickSlipSelectors.selected(state)?.CustomerId,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Details);
