import { combineReducers } from 'redux';
import invoiceDetails, { ISupplierInvoiceDetailsState } from './invoiceDetails';
import supplierInvoice, { ISupplierInvoiceState } from './supplierInvoice';
import supplierInvoiceLines, { IInvoiceLinesState } from './supplierInvoiceLines';
import supplierInvoiceLinesLotDetails, { IInvoiceLinesLotDetailsState } from './supplierInvoiceLinesLotDetails';
import supplierInvoiceLinesSerialNumbers, { IInvoiceLinesSerialNumbersState } from './supplierInvoiceLinesSerialNumbers';
import supplierInvoiceLinesMovements, { IInvoiceLinesMovementsState } from './supplierInvoiceLinesMovements';

export interface ISupplierInvoiceEnquiryState {
  invoiceDetails: ISupplierInvoiceDetailsState;
  supplierInvoice: ISupplierInvoiceState;
  supplierInvoiceLines: IInvoiceLinesState;
  supplierInvoiceLinesLotDetails: IInvoiceLinesLotDetailsState;
  supplierInvoiceLinesSerialNumbers: IInvoiceLinesSerialNumbersState;
  supplierInvoiceLinesMovements: IInvoiceLinesMovementsState;
}
const combined = combineReducers({
  invoiceDetails,
  supplierInvoice,
  supplierInvoiceLines,
  supplierInvoiceLinesLotDetails,
  supplierInvoiceLinesSerialNumbers,
  supplierInvoiceLinesMovements
});

export default combined;
