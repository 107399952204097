import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    mainContainer: {
        width: '638px',
        background: 'white',
    },
    configContainer: {
        width: '648px',
        minHeight: '332px',
        background: 'white',
        display: 'flex',
        flexFlow: 'column',
    },
    chartContainer: {
        padding: '8px',
    },
    iconContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        padding: '8px 16px'
    },
    iconStyles: {
        flex: '1',
        cursor: 'pointer'
    },
    upperContainer: {
        flex: '9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    setupDivContainer: {
        width: '50%',
        border: 'solid 2px #80808066',
        padding: '16px'
    },
    totalContainer: {
        display: 'flex',
        padding: '10px 75px',
        justifyContent: 'space-between',
    }
});
