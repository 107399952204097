import { fetchPost, objectify } from '../../utils';

export const fetch = async (query) => {
  const { ProductId, filters } = query;
  const respType = 'Inline';
  if (ProductId) {
    return fetchPost(
      `/InventoryEnquiry/Product/${ProductId}/SearchImages`,
      filters ?
        {
          ...filters
        } : {},
      null,
      respType
    ).then(
      (result) => {
        if (result.Images) {
          return {
            inlineObject: result.Images.map((item) => objectify(item, null, 'Inline')),
            schema: result.Images
          };
        }

        return result;
      });
  }

};
