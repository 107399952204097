import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { withRouter } from 'react-router-dom';
import { IMainPurchaseOrderEnquiryProperties } from '../interfaces';

class MainPurchaseOrderEnquiry extends React.Component<IMainPurchaseOrderEnquiryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/purchase-order-enquiry/order-details':
          onInitialLoad('OrderDetails');
          break;
        default:
          onInitialLoad('OrderDetails');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedOrder, purchaseOrderDetails } = this.props;

    return selectedOrder && purchaseOrderDetails && purchaseOrderDetails.values;
  }

  getValueSchema = (): any => {
    const { selectedOrder, purchaseOrderDetails } = this.props;

    return selectedOrder && purchaseOrderDetails && purchaseOrderDetails.schema;
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, notifications } = this.props;

    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValueSchema();

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={notifications}
          entity='PurchaseOrderEnquiry'
        />
        <FormView
          isLoading={isLoading}
          formName='PurchaseOrderEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          valuesSchema={valuesSchema}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MainPurchaseOrderEnquiry);
