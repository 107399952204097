import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  setSelectedBOMLine: (row) => ({ row }),
  clearLines: () => null,
  asyncs: {
    fetchLineDetails: lineDetails => lineDetails,
    fetchLineDetailSummary: lineDetailSummary => lineDetailSummary,
    fetchReservationDetails: reservationDetails => reservationDetails,
  }
}, 'BOMLines');


const initialState = asyncInitialState({
  selected: null,
  selectedBOMLine: null,
  gridOptions: {
    doubleClickActionTab: 'ComponentDetails',
    suppressEditDeleteInContextMenu: true,
  },
  lineColumns: [
    { headerName: 'Line', field: 'Line', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    {
      headerName: 'Product',
      field: 'ProductCode',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductCode) {
          return {
            ProductId: row.data.ProductId,
            FieldValue: row.data.ProductCode
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      }
    },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 300, suppressSorting: true, },
    { headerName: 'Comment', field: 'Comment', minWidth: 300, suppressSorting: true, }
  ],
  lines: [],
  lineDetails: {},
  lineDetailSummary: {},
  reservationDetails: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clearLines:
      return initialState
    case types.setSelected:
      const selected = action.data.row;
      const selectedBOMLine = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected,
          selectedBOMLine
        }
      }
    case types.fetchLineDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchLineDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          lineDetails: action.payload
        }
      })
    case types.saga.fetchLineDetails.failure:
      return asyncOnError(state, action);

    case types.fetchLineDetailSummary:
      return asyncOnRequest(state, action)
    case types.saga.fetchLineDetailSummary.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          lineDetailSummary: action.payload
        }
      })
    case types.saga.fetchLineDetailSummary.failure:
      return asyncOnError(state, action);

    case types.fetchReservationDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchReservationDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          reservationDetails: action.payload
        }
      })
    case types.saga.fetchReservationDetails.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.customerQuoteEnquiry.BOM,
  {
    lines: data => data.lines,
    lineDetails: data => data.lineDetails,
    reservationDetails: data => data.reservationDetails,
    lineDetailSummary: data => data.lineDetailSummary,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.customerQuoteEnquiry.BOM.loading,
  gridOptions: state => state.customerQuoteEnquiry.BOM.data.gridOptions,
  lineColumns: state => state.customerQuoteEnquiry.BOM.data.lineColumns,
  // lines: state => state.customerQuoteEnquiry.BOM.data.lines,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)