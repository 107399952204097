import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from '../utils';

export const getSummary = (query) => {
  const { AuditRegId } = query;
  const respType = 'Inline';

  return fetchGet(`SalesInvoiceEnquiry/SalesAudit/${AuditRegId}/Summary`, null, respType)
    .then((result) => result && objectify(result.SalesAuditSummery));
};

export const fetch = async (query: { AuditDataId: number; Page: number; BatchSize?: number }): Promise<{ records: any[]; nextPage: boolean; currPage: number }> => {
  const { AuditDataId, Page, BatchSize = DEFAULT_PAGE_SIZE } = query;
  const respType = 'Inline';

  return fetchGet(
    `SalesInvoiceEnquiry/SalesAudit/${AuditDataId}/Details`,
    {
      BatchPage: Page,
      BatchSize
    },
    respType)
    .then(
      (result: {
        SalesAuditDetails: any[];
        BatchInformation: {
          BatchPage: number;
          EndOfData: boolean;
        };
      }) => {
        if (result) {
          if (result.SalesAuditDetails) {

            return {
              records: result.SalesAuditDetails.map((item: any) => objectify(item, null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            };
          }
        }

        return {
          records: [],
          nextPage: false,
          currPage: 1,
        };
      });
};
