import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as salesInvoiceEnquiryTypes, actions as salesInvoiceEnquiryActions } from 'ducks/salesInvoiceEnquiry/payment';

import { callApi } from 'sagas/utils';
import * as api from 'api/salesInvoiceEnquiry/payment';

function* getPaymentSummary(action) {
  const { success, failure } = salesInvoiceEnquiryActions.saga.getPaymentSummary;
  yield callApi(
    call(api.getPaymentSummary, action.data),
    success,
    failure
  )
}

function* getPaymentDetails(action) {
  const { success, failure } = salesInvoiceEnquiryActions.saga.getPaymentDetails;
  yield callApi(
    call(api.getPaymentDetails, action.data),
    success,
    failure
  )
}

export default function* rootSalesOrderSaga() {
  yield takeLatest(salesInvoiceEnquiryTypes.getPaymentSummary, getPaymentSummary)
  yield takeLatest(salesInvoiceEnquiryTypes.getPaymentDetails, getPaymentDetails)
}