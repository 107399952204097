import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as pickingSelectors, actions as pickingActions } from 'ducks/inventoryEnquiry/stocking/picking'
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup'
import { selectors as quantitySelectors } from 'ducks/inventoryEnquiry/stocking/quantities'
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';
import { actions as formActions } from 'ducks/form';
import { search } from 'api/inventoryEnquiry/stocking/picking';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesPicking',
  columnDefs: pickingSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  selectedFilters: pickingSelectors.selectedFilters(state),
  appliedFilters: pickingSelectors.filterRow(state) && filterSelectors.getFilters(state, pickingSelectors.filterRow(state).formName),
  gridOptions: pickingSelectors.gridOptions(state),
  isLoading: pickingSelectors.isLoading(state),
  filterRow: pickingSelectors.filterRow(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    (quantitySelectors.selected(state) || params.get('Warehouse')) &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (quantitySelectors.selected(state) && quantitySelectors.selected(state).Warehouse) || params.get('Warehouse')
  },
  apiMethod: search

})

export const actions = {
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: pickingActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
