import { IFormViewForm } from 'components/FormView';

const FinancialForm: IFormViewForm = {
  id: 'SettingsFinancial',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'credit'
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Credit type',
            name: 'CreditCode',
            size: 'small'
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Credit limit',
            name: 'CreditLimitDisplay',
            alignment: 'right',
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Accounting method',
            name: 'AccountingMethodLabel',
            size: 'medium',
          }
        },
        {
          id: '4',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Currency',
            name: 'CurrencyCode',
            size: 'small'
          }
        },
        {
          id: '5',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            size: 'small'
          }
        },
        {
          id: '6',
          type: 'TEXT_FIELD',
          props: {
            label: 'Tax exempt reference',
            name: 'TaxExemptRef',
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Business details',
        name: 'BusinessDetails'
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Business number',
            name: 'BusinessNumber',
            required: false,
            size: 'medium',
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Company number',
            name: 'CompanyNo',
            required: false,
            size: 'medium'
          }
        },
        {
          type: 'SUBHEADER',
          props: {
            value: 'Account details',
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Bill to account',
            name: 'BillToId',
            required: false,
            size: 'small',
            disabled: true,
            api: '/customermaintenance/lookup/action',
            params: {
              queryFields: {
                CustomerId: 'BillToId',
                SearchText: { Field: 'BillToId', Property: 'Display' }
              }
            }
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Default chart account',
            name: 'DefaultChartAccount',
            required: false,
            disabled: true,
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Supplier',
            name: 'SupplierId',
            api: '/supplierEnquiry/lookup/action',
            params: {
              queryFields: {
                Supplier: 'SupplierId',
                SearchText: { Field: 'SupplierId', Property: 'Display' }
              }
            }
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Bank details',
        name: 'bankDetails',
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Bank',
            name: 'BankCode',
            size: 'small',
            required: false,
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Branch',
            name: 'Branch',
            required: false,
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Payee',
            name: 'Payee',
            size: 'large',
            fullWidth: true,
            required: false,
          }
        }
      ]
    },
  ]
};
export default FinancialForm;
