import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as purchaseOrderActions, selectors as purchaseOrderSelectors } from 'ducks/purchaseOrders/purchaseOrders';
import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';

interface IPurchaseOrderItem {
  PurchaseOrder: number;
  SupplierDisplay: string;
  SupplierLabel: string;
  SupplierCityState: string;
}

const PurchaseOrderSearchLookUpDrawer = buildSearchLookUpDrawer<IPurchaseOrderItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<IPurchaseOrderItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<IPurchaseOrderItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'PurchaseOrder',
    searchPlaceholder: 'Search for purchase orders...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'PurchaseOrder',
        label: 'Purchase Order'
      }
    ],
    records: purchaseOrderSelectors.all(state) || [],
    selectedRecord: purchaseOrderSelectors.selected(state),
    selectedSortFilter: 'PurchaseOrder',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isPurchaseOrdersLookUpOpen(state),
    isLoading: purchaseOrderSelectors.isLoading(state),
    nextPage: purchaseOrderSelectors.nextPage(state),
    prevPage: purchaseOrderSelectors.prevPage(state),
    pageSize: purchaseOrderSelectors.pageSize(state),
    totalPages: purchaseOrderSelectors.totalPages(state),
    loadingPrevPage: purchaseOrderSelectors.loadingPrevPage(state),
    loadingNextPage: purchaseOrderSelectors.loadingNextPage(state),
    keyField: 'PurchaseOrder',
    fieldsToDisplay: ['PurchaseOrder', 'SupplierDisplay', 'SupplierLabel', 'SupplierCityState'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) matchParams.set('PurchaseOrder', selectedRecord.PurchaseOrder);

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const purchaseOrder = matchParams.get('PurchaseOrder');
      if (purchaseOrder) {
        return {
          id: purchaseOrder
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: purchaseOrderActions.search,
  searchById: purchaseOrderActions.searchById,
  changeSelectedRecord: purchaseOrderActions.setSelected,
  onToggle: uiActions.togglePurchaseOrdersLookUp,
  fetchNextPage: purchaseOrderActions.fetchNextPage,
  fetchPrevPage: purchaseOrderActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderSearchLookUpDrawer);
