import React, { PureComponent } from 'react';
import { isNull } from '../../../utils/utils';
import DataGrid from '../DataGridDevEx';

class Events extends PureComponent {
  getApi = () => {
    const { fetchApi, formValues } = this.props;
    const reducer = (acc, curr) => {
      const myRegExp = new RegExp(`<${curr}>`, 'gi');

      return acc.replace(myRegExp, formValues.params[curr]);
    };
    const isFormParamsValid = () => {
      if (
        !isNull(formValues.params) &&
        Object.keys(formValues.params).some((key) => !isNull(formValues.params[key]))
      ) {
        return true;
      }
      return false;
    };
    return (
      isFormParamsValid() &&
      ((formValues &&
        formValues.params &&
        Object.keys(formValues.params).reduce(reducer, fetchApi))
      )
    );
  };

  render() {
    const { style } = this.props;
    const api = this.getApi();

    return (
      <DataGrid
        style={style}
        module={'Events'}
        reqParams={
          (!api && {
            fetchApi: api
          }) || (!api.includes('null') && !api.includes('<') && !api.includes('>') &&
          {
            fetchApi: api
          })
          || null
        }
      />
    );
  }
}

export default Events;
