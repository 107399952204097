export const MODULE_TREE = [
    { id: 'InvoiceDetails', label: 'Invoice Details', parent: '' },
    { id: 'InvoiceLines', label: 'Invoice Lines', parent: '' },
    { id: 'Payment', label: 'Payment', parent: '' },
    { id: 'PaymentDetails', label: 'Payment Details', parent: 'Payment' },
    { id: 'InvoiceLineDetails', label: 'Line Details', parent: 'InvoiceLines' },
    { id: 'InvoiceLineSerials', label: 'Serials', parent: 'InvoiceLineDetails' },
    { id: 'InvoiceLineLots', label: 'Lots', parent: 'InvoiceLineDetails' },
    { id: 'InvoiceLineBOM', label: 'Bill of Materials', parent: 'InvoiceLineDetails' },
    { id: 'InvoiceLineBOMComponent', label: 'Component Details', parent: 'InvoiceLineBOM' },
    { id: 'InvoiceLineBOMComponentSerials', label: 'Serials', parent: 'InvoiceLineBOMComponent' },
    { id: 'InvoiceLineBOMComponentLots', label: 'Lots', parent: 'InvoiceLineBOMComponent' },
    { id: 'PickSlipsByProduct', label: 'Pick Slips by Product', parent: '' },
    { id: 'PickSlipsByDespatch', label: 'Pick Slips by Despatch', parent: '' },
    { id: 'Comments', label: 'Comments', parent: '' },
    { id: 'InternalComments', label: 'Internal Comments', parent: '' },
    { id: 'SalesAudit', label: 'Sales Audit', parent: '' },
    { id: 'SalesAuditDetails', label: 'Sales Audit Details', parent: 'SalesAudit' },
    { id: 'Events', label: 'Events', parent: '' },
    { id: 'SalesDiary', label: 'Sales Diary', parent: '' },
    { id: 'DiaryDetails', label: 'Diary Details', parent: 'SalesDiary' },
    { id: 'Notepad', label: 'Notepad', parent: '' }
];
