import {
    asyncSelectors,
    syncSelectors,
    IExtendedState,
    initializeReducer
} from '../utils';
import {
    IServicePatternSearchFacade,
    ISortOrder,
} from 'api/swaggerTypes';
import { IObjectified, Inline } from 'api/utils';

export interface IServicePatternData {
    selected: number;
    list: IServicePatternSearchFacade[];
    schemas: Inline<IServicePatternSearchFacade>[];
    nextPage?: number;
    prevPage?: number;
    currPage: number;
    pageSize: number;
    totalPages: number;
    currSearchText: string;
    removeWhenPrev: number;
}

export interface IServicePatternState extends IExtendedState<IServicePatternData> {
    search_loading?: boolean;
    searchById_loading?: boolean;
    fetchNextPage_loading?: boolean;
    fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = null;

const initialData: IServicePatternData = {
    selected: NOT_SELECTED,
    list: [],
    schemas: [],
    nextPage: null,
    prevPage: null,
    currPage: 1,
    pageSize: 10,
    totalPages: 5,
    currSearchText: '',
    removeWhenPrev: 0,
};

const { types, actions, reducer } = initializeReducer({
    namespace: 'servicePattern',
    initialData: initialData,
    syncActions: {
        setSelected: {
            action: (servicePatternId: number) => servicePatternId,
            callback: (data: IServicePatternData, servicePatternId: number): IServicePatternData => ({ ...data, selected: servicePatternId })
        },
        reset: {
          action: () => null,
          callback: (data: IServicePatternData): IServicePatternData => ({ ...data, ...initialData })
        }
    },
    asyncActions: {
        search: {
            action: (search: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => (search),
            callback: (data: IServicePatternData, query: { SearchText?: string; Sort?: ISortOrder; BatchPage?: number }) => ({ ...data, selected: NOT_SELECTED, currSearchText: query.SearchText }),
            successCallback: (data: IServicePatternData, payload: { records: IObjectified<IServicePatternSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServicePatternData => {
                const servicePatterns = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: servicePatterns,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        searchById: {
            action: (servicePatternId: number) => ({ ServicePatternId: servicePatternId }),
            successCallback: (data: IServicePatternData, payload: { records: IObjectified<IServicePatternSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IServicePatternData => {
                const serviceItemTypes = payload.records && payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records && payload.records.map((combinedObject) => combinedObject.schema);

                return {
                    ...data,
                    list: serviceItemTypes,
                    schemas: schemas,
                    nextPage: payload.nextPage ? Number(payload.currPage) + 1 : null,
                    prevPage: payload.prevPage ? Number(payload.currPage) - 1 : null,
                    currPage: payload.currPage
                };
            }
        },
        fetchNextPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => ({ SearchText: '', Sort: search.Sort, BatchPage: search.BatchPage }),
            callback: (data: IServicePatternData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServicePatternData, payload: { records: IObjectified<IServicePatternSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServicePatternData => {
                const serviceItemTypes = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newRemoval = payload.records.length;
                const newList = data.list.concat(serviceItemTypes);
                const newSchemas = data.schemas.concat(schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
                    nextPage: payload.nextPage && payload.currPage + 1,
                    prevPage: (payload.prevPage && payload.currPage > data.totalPages && payload.currPage - data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
                };
            }
        },
        fetchPrevPage: {
            action: (search: { Sort?: ISortOrder; BatchPage: number }) => (search),
            callback: (data: IServicePatternData, search: { SearchText?: string; Sort?: ISortOrder; BatchPage: number }) => {
                search.SearchText = data.currSearchText;

                return data;
            },
            successCallback: (data: IServicePatternData, payload: { records: IObjectified<IServicePatternSearchFacade>[]; nextPage: boolean; prevPage: boolean; currPage: number }): IServicePatternData => {
                const serviceItemTypes = payload.records.map((combinedObject) => combinedObject.inlineObject);
                const schemas = payload.records.map((combinedObject) => combinedObject.schema);

                const newList = serviceItemTypes.slice(0, data.removeWhenPrev).concat(data.list);
                const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

                return {
                    ...data,
                    list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
                    schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
                    prevPage: payload.prevPage && payload.currPage - 1,
                    nextPage: (newList.length > data.pageSize * data.totalPages) && (payload.currPage + data.totalPages),
                    currPage: payload.currPage,
                    removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
                };
            }
        },
    }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
    (state: { servicePatternMaintenance: { servicePattern: IServicePatternState } }) => state.servicePatternMaintenance.servicePattern,
    {
        all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
        allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
        selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ServicePatternId === data.selected)) || null,
        selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.ServicePatternId.Value === data.selected)) || null,
        nextPage: (data) => data.nextPage,
        prevPage: (data) => data.prevPage,
    }
);

const syncSelector = syncSelectors(
    (state: { servicePatternMaintenance: { servicePattern: IServicePatternState } }) => state.servicePatternMaintenance.servicePattern,
    {
        ServicePatternId: (servicePattern) => servicePattern.data.selected,
        isLoading: (servicePattern) => servicePattern.search_loading || servicePattern.searchById_loading,
        loadingNextPage: (servicePattern) => servicePattern.fetchNextPage_loading,
        loadingPrevPage: (servicePattern) => servicePattern.fetchPrevPage_loading,
        pageSize: (servicePattern) => servicePattern.data.pageSize,
        totalPages: (servicePattern) => servicePattern.data.totalPages,
        notifications: (servicePattern) => servicePattern.notifications,
        error: (servicePattern) => servicePattern.error,
    }
);

export const selectors = { ...asyncSelector, ...syncSelector };
