import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: 'calc(100% - 380px)',
        height: 'calc(100vh - 230px)',
        padding: '15px',
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: 15,
        overflow: 'auto'
    },
    paperContainer: {
        width: 'fit-content',
        maxWidth: 'calc(100% - 10px)',
    },
    commitmentImageDiv: {
        'padding': '10px',
        '& div div div': {
            '@media screen and (max-width: 1100px)': {
                width: '250px',
            }
        },
        '@media screen and (max-width: 1100px)': {
            display: 'none'
        }
    },
    commitmentDataGrid: {
        'height': '100%',
        '& .dx-datagrid-headers .dx-row > td ': {
            outline: 'unset !important'
        },
        '& .dx-checkbox-container .dx-checkbox-icon ': {
            width: '20px',
            height: '18px',
            border: '2px solid #333333',
            marginBottom: '3px',
        },
        '& .dx-checkbox-checked .dx-checkbox-icon': {
            color: '#333333',
        },
        '& .dx-checkbox-indeterminate .dx-checkbox-icon::before': {
            backgroundColor: '#333333',
        },
        '& .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td': {
            backgroundColor: '#cccccc',
            color: 'inherit',
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc',
        },

    },
    commitmentHeaderFilter: {
        '& span': {
            color: '#959595 !important',
            cursor: 'pointer',
        }
    },
    grid: {
        marginTop: '10px'
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledLineDetailIcon: {
        cursor: 'default',
        fill: 'grey'
    },
});
