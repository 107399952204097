import * as React from 'react';
import { IQuoteConfirmationProps } from './QuoteConfirmation.properties';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import QuoteConfirmationForm from './QuoteConfirmation.form';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';
import { useFetchQuoteConfirmation } from 'api/Worksale/worksale';

const QuoteConfirmation = (props: IQuoteConfirmationProps): JSX.Element => {
  const { open, values, quoteDocumentOutputOptions, setQuoteDocumentOutputOptions, onOk: onApply, onCancel } = props;
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const { data: response } = useFetchQuoteConfirmation(worksaleId);

  const initialValues = React.useMemo(
    () => ({
      ...response?.QuoteConfirmation?.inlineObject,
      QuoteOutput: response?.QuoteConfirmation?.QuoteOutput?.inlineObject
    }),
    [response]
  );

  const valuesSchema = React.useMemo(() => (response?.QuoteConfirmation?.schema), [response]);

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        listener: () => {
          onApply({ ...values, QuoteOutput: quoteDocumentOutputOptions });
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        listener: () => {
          onCancel();
        }
      }
    ];
  };

  React.useEffect(
    () => {
      if (initialValues) {
        setQuoteDocumentOutputOptions(initialValues.QuoteOutput);
      } else {
        setQuoteDocumentOutputOptions(undefined);
      }
    },
    [initialValues]);

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Quote Confirmation'}
    formProps={{
      style: {
        height: '585px',
        minWidth: '535px'
      },
      formSchema: QuoteConfirmationForm,
      initialValues: {
        ...initialValues,
        QuoteOutput: initialValues && initialValues.QuoteOutput ? initialValues.QuoteOutput.Summary : ''
      },
      valuesSchema: valuesSchema ? valuesSchema : {},
      customerId: 1,
    }}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
  />);
};

export default QuoteConfirmation;
