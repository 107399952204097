import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as marketingTypes, actions as marketingActions } from 'ducks/customerEnquiry/marketing';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/marketing';


function* search(action) {
  const { success, failure } = marketingActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = marketingActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

export default function* rootAttributeSaga() {
  yield takeLatest(marketingTypes.search, search)
  yield takeLatest(marketingTypes.searchById, searchById)
  
}