import { FormViewField } from 'components/FormView';

export default {
  formName: 'DiaryGridFilterRow',
  validate: null,
  rowModelType: 'serverSide',
  parameters: [
    {
      id: 0,
      type: 'EX_LOOKUP_FIELD',
      props: {
        label: 'User ID',
        name: 'UserID',
        lookupName: 'Username',
        size: 'small',
      }
    }
  ] as FormViewField[]
};
