import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  jobDetails: selectors.jobDetails(state),
  activityDetails: selectors.activityDetails(state),
  isLoading: selectors.isLoading(state)
});

export default connect(mapStateToProps, {})(SummaryPanel);
