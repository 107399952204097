function appendParams(path: string, params: (number | { ProductId?: number; SearchText?: string})): string {
  if (params && typeof(params) === 'string') {
    const search = new URLSearchParams();
    search.append('ProductId', params);
    path += `?${search.toString()}`;
  } else if (params && typeof(params) === 'object' && params.ProductId) {
    const search = new URLSearchParams();
    if (params.ProductId) {
      search.append('ProductId', params.ProductId.toString());
      search.append('SearchText', params.SearchText || '');
    }
    if (params.SearchText) {
      search.append('SearchText', params.SearchText);
    }
    path += `?${search.toString()}`;
  }

  return path;
}

export default [
  {
    id: '1',
    label: 'Inventory Enquiry in new tab',
    action: (params?: (number | { ProductId?: number; SearchText?: string})) => { window.open(appendParams('/inventory-enquiry', params)); }
  },
  {
    id: '2',
    label: 'Inventory Enquiry',
    action: (params?: (number | { ProductId?: number; SearchText?: string})) => { window.location.assign(appendParams('/inventory-enquiry', params)); }
  }
];
