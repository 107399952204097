import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  container: {
    width: 'calc(100% - 350px)',
    height: 'calc(100vh - 200px)',
    padding: '15px',
    float: 'left',
    display: 'flex',
  },
  summaryTable: {
    margin: 'unset',
    marginLeft: 15,
    height: 'fit-content'
  }
});
