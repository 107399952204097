import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, change } from 'redux-form';

import ChangePassword from './ChangePassword';
import { selectors as staffSelectors, actions as staffActions } from 'ducks/staff';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'ChangePasswordForm';
const mapStateToProps = (state: IApplicationState) => ({
  isLoading: staffSelectors.changePassword_loading(state),
  formValues: getFormValues(FORM_NAME)(state),
  dirty: isDirty(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    onSubmit: staffActions.changePassword,
    changeFieldValue: (field, value) => change(FORM_NAME, field, value)
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
