import React, { Dispatch } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { IActions } from './Worksale.actions';
import reducer, { initialState, IState } from './Worksale.reducer';

export const WorksaleStateContext = createContext<IState>(initialState);
export const WorksaleDispatchContext = createContext<Dispatch<IActions>>(() => null);

export const useSPDispatch = () => useContext(WorksaleDispatchContext);
export const useSPState = () => useContext(WorksaleStateContext);
export const useSPContextSelector = <K extends keyof IState>(selector: (state: IState) => IState[K]) => useContextSelector<IState, IState[K]>(WorksaleStateContext, selector);

interface IWorksaleStateProvider {
  children: React.ReactNode;
}

const WorksaleStateProvider = ({ children }: IWorksaleStateProvider) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <WorksaleStateContext.Provider value={state}>
      <WorksaleDispatchContext.Provider value={dispatch}>
        {children}
      </WorksaleDispatchContext.Provider>
    </WorksaleStateContext.Provider>
  );
};

export default WorksaleStateProvider;
