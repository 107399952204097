import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as childAccountSelector, actions as childAccountActions } from 'ducks/customerEnquiry/childAccounts';
import { fetch } from 'api/customerEnquiry/childAccounts';
import { selectors as customerSelectors } from 'ducks/customer';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

export const mapStateToProps = (state: IApplicationState) => ({
  gridName: 'CustomerFinancialChildAccounts',
  columnDefs: childAccountSelector.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: childAccountSelector.gridOptions(state),
  reqParams: (customerSelectors.selected(state) || params.get('CustomerId'))
    &&
    {
      CustomerId: (customerSelectors.selected(state) && customerSelectors.selected(state).CustomerId) || params.get('CustomerId')
    },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: childAccountActions.setSelected
};
