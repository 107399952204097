import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Button, } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import styles from './BinLocationsDialog.styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import NumericField from '@markinson/uicomponents-v2/NumericField';
import { default as MUIButton } from '@material-ui/core/Button';
import CheckCircleIcon from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import classNames from 'classnames';
import { IBinLocationsDialogProps } from './BinLocationsDialog.properties';
import { isNull } from 'utils/utils';
import { IBinLotFacade, IProductLotSearchFacade } from 'api/swaggerTypes';
import ProductBinLookup from '../ProductBinLookup';
import { ENTER_KEY } from 'utils/constants';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';

const NumberOfDecimalPlaces = 2;
const SMALL_COLUMN_MIN_WIDTH = 75;
const MEDIUM_COLUMN_MIN_WIDTH = 90;
const SMALL_COLUMN_DEFAULT_WIDTH = 100;

const BinLocationsDialog = ({open, onCancel, classes, onApply, BinLots, RequiredQuantity, ProductId, DocumentId, DocumentType, WarehouseEntity, LineNumber, ReservationNumber}: IBinLocationsDialogProps) => {

  const [lines, setLines] = React.useState<IBinLotFacade[]>([]);
  const [enteredQuantity, setEnteredQuantity] = React.useState(0);
  const [availableQuantity, setAvailableQuantity] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [isApplyDisabled, setIsApplyDisabled] = React.useState<boolean>(true);
  const [productLotValue, setProductLotValue] = React.useState<unknown>();
  const [productLotDisplay, setProductLotDisplay] = React.useState<string>();
  const [productLot, setProductLot] = React.useState<IProductLotSearchFacade>();
  const productRef = React.useRef<ITextFieldHandle>();
  const quantityTextField = React.useRef<HTMLTextAreaElement | HTMLInputElement>();

  const getSum = (type) => {
    return isNull(lines) ? 0 : lines.reduce((a, b) => (a + +b[type]), 0);
  };

  React.useEffect(
    () => { updateQuantities(); },
    [lines]);

  const updateQuantities = () => {
    setAvailableQuantity(getSum('AvailableQuantity') || 0);
    setEnteredQuantity(getSum('Quantity') || 0);
  };

  const addLine = () => {
    if (isApplyDisabled) {
      return;
    }

    const existingBinIndex = lines.findIndex((element) => element.Bin === productLot.Bin);

    if (existingBinIndex !== -1) {
      lines[existingBinIndex] = {...lines[existingBinIndex] , ...productLot , Quantity: quantity + lines[existingBinIndex].Quantity};
      setLines([...lines]);
    } else {
      setLines([...lines, {
        Quantity: quantity,
        ...productLot
      }]);
    }

    clearEntryData();
  };

  const clearEntryData = () => {
    setProductLotValue(undefined);
    setProductLotDisplay('');
    setProductLot(undefined);
    setQuantity(0);

    setTimeout(
      () => {
          if (productRef.current) {
              productRef.current.focus();
          }
      },
      0);
  };

  React.useEffect(
    () => {
      if (open) {
        setLines(BinLots);
      }
      },
    [BinLots, open]);
  React.useEffect(
    () => {
      shouldDisableApply((productLotValue && quantity));
    },
    [productLotValue, quantity]);

  const shouldDisableApply = (value: number) => {
    setIsApplyDisabled((isNull(value) || value === 0));
  };

  const binLookupOnCloseSearchScreen = React.useCallback(
    (record: IBinLotFacade) => {
      const value = (record && record.Bin) ? record.Bin : null;

      if (value !== productLotValue) {
        if (record) {
          setProductLotValue(record.Bin);
          setProductLotDisplay(record.Bin);
          setProductLot(record);
          if (quantityTextField.current) {
            quantityTextField.current.select();
            quantityTextField.current.focus();
          }
        } else {
          setProductLotValue(undefined);
          setProductLotDisplay('');
          setProductLot(undefined);
        }
      }
    },
    [productLotValue, quantityTextField]
  );

  const binLookupOnBlur = React.useCallback(
    (value, display, _description, record) => {
      if (value !== productLotValue) {
        if (record) {
          setProductLotValue(value);
          setProductLotDisplay(display);
          setProductLot(record);
        } else {
          setProductLotValue(undefined);
          setProductLotDisplay('');
          setProductLot(undefined);
        }
      }
    },
    [productLotValue]
  );

  const handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      addLine();
    }
  };

  const getModalContent = () => {
      return <div className={classes.mainDiv}>

        <div className={classes.lotLineDiv}>
          <div className={classes.inlineFields}>
          <ProductBinLookup
          ref={productRef}
          WarehouseEntity={WarehouseEntity}
          ProductId={ProductId}
          LineNumber={LineNumber}
          ReservationNumber={ReservationNumber}
          DocumentId={DocumentId}
          DocumentType={DocumentType}
          value={productLotValue}
          display={productLotDisplay}
          PartialSerial={false}
          suppressDescription={true}
          onBlur={binLookupOnBlur}
          onCloseSearchScreen={binLookupOnCloseSearchScreen}
        />
            <NumericField
              label={'Quantity'}
              size={'medium'}
              value={quantity}
              onBlur={setQuantity}
              innerRef={quantityTextField}
              onKeyDown={handleKeyDown}
              onChange={(value) => { setQuantity(value); }}
            />
          </div>
          <MUIButton
          onClick={() => { addLine(); }}
            variant={'contained'}
            disabled={isApplyDisabled}
            className={classNames(classes.button, isApplyDisabled && classes.disabledBtn)}
          >
            <CheckCircleIcon style={{ color: 'green' }} />
            APPLY
          </MUIButton>
        </div>
        <DataGrid
          keyExpr={'Bin'}
          className={classes.lotLineDataGrid}
          dataSource={lines}
          repaintChangesOnly={true}
          noDataText=''
          remoteOperations={true}
          columnResizingMode={'nextColumn'}
          allowColumnReordering={true}
          allowColumnResizing={true}
          editing={{
            allowAdding: false,
            allowDeleting: true,
            allowUpdating: true,
            confirmDelete: false,
          }}
          onEditorPreparing={(e) => {
            e.editorOptions.onFocusIn = (args) => {
              const input = args.element.querySelector("input[type='text']");
              if (input) {
                input.select();
              }
            };
          }}
          showBorders={false}
          sorting={{
            mode: 'none'
          }}
          hoverStateEnabled={true}
          onRowUpdated={updateQuantities}
        onRowRemoved={updateQuantities}
        >
          <Column
            caption={'Bin'}
            dataField={'Bin'}
            allowEditing={false}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={MEDIUM_COLUMN_MIN_WIDTH}
          />
          <Column
            caption={'Bin type'}
            dataField={'BinType'}
            allowEditing={false}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={MEDIUM_COLUMN_MIN_WIDTH}
          />
          <Column
            caption={'Available'}
            dataField={'AvailableQuantity'}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={SMALL_COLUMN_DEFAULT_WIDTH}
            allowEditing={false}
            alignment={'right'}
          />
          <Column
            caption={'Quantity'}
            dataField={'Quantity'}
            minWidth={SMALL_COLUMN_MIN_WIDTH}
            width={SMALL_COLUMN_DEFAULT_WIDTH}
            allowEditing={true}
            alignment={'right'}
          />
          <Column type={'buttons'} allowResizing={false} width='100%' alignment={'left'}>
            <Button name={'save'} icon={'save'} hint={'Update Changes'} />
            <Button name={'edit'} icon={'edit'} hint={'Edit Quantity'} />
            <Button name={'delete'} icon={'trash'} hint={'Remove Line'} />
            <Button name={'cancel'} icon={'undo'} hint={'Undo Changes'} />
          </Column>
          <GroupPanel visible={false} />
          <SearchPanel visible={false} />
          <Scrolling mode={'virtual'} />
          <LoadPanel enabled={false} />
        </DataGrid>
        <Table>
          <TableBody>
            <TableRow className={classes.bottomRow}>
              <TableCell className={classes.headingCell}>REQUIRED</TableCell>
              <TableCell className={classNames(classes.emphasizedCell, classes.cellSpacing)}>{Number(RequiredQuantity || 0).toFixed(NumberOfDecimalPlaces)}</TableCell>
              <TableCell className={classes.headingCell}>AVAILABLE</TableCell>
              <TableCell className={classNames(classes.dataCell, classes.cellSpacing)}>{Number(availableQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
              <TableCell className={classes.headingCell}>ENTERED</TableCell>
              <TableCell className={classes.dataCell}>{Number(enteredQuantity).toFixed(NumberOfDecimalPlaces)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>;
    };

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        disabled: enteredQuantity !== RequiredQuantity,
        listener: async () => {
          if (onApply) {
            await onApply(lines);
          }
        }
      },
      {
        iconName: 'Cancel',
        isDefault: true,
        title: 'Cancel',
        listener: async () => {
          clearEntryData();
          setLines([]);
          onCancel();
        }
      }
    ];
  };

  return (
      <div>
      <FormViewModal
          open={open}
          loading={false}
          title='Select Bin Locations'
          modalContent={getModalContent()}
          actions={confirmationActions()}
          dialogActionsButtons={true}
          dialogActionsShadow={false}
      />
  </div>
  );
};

export default withStyles(styles, { index: 1 })(BinLocationsDialog);
