import { Payload, useFetchMutation, usePost } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { IDeliverySummaryFacade, IDeliverySummaryResponse, IFulfillmentFacade, IFulfillmentGridFilter, IGetFulfillmentResponse, IInitialiseFulfillmentResponse, IValidatedResponse } from 'api/swaggerTypes';
import { AsObjectified, IObjectified, Inline, objectify } from 'api/utils';
import { toObjectified } from 'api/baseApi';

export type IObjectifiedGetFulfillmentResponse = Omit<IGetFulfillmentResponse, 'Fulfillment'> & {
  Fulfillment: IObjectified<IFulfillmentFacade>;
};

const fulfillmentResponseParser = (response) => {
  const { Fulfillment } = response;

  return {
    ...response,
    Fulfillment: Fulfillment.map((o) => objectify(o as Inline<IFulfillmentFacade>))
  };
};

export const useRetrieveFulfillment = () =>
  usePost<Payload<IFulfillmentGridFilter & { WorksaleId: number; WorksaledelivId: number }>, IGetFulfillmentResponse>(
    APIRoutes.worksale.fulfillment.retrieve,
    null,
    { inline: true, responseParser: fulfillmentResponseParser }
  );

export const useInitialiseFulfillmentColumns = () =>
  usePost<Payload<{ worksaleId: number }>, IInitialiseFulfillmentResponse>(
    APIRoutes.worksale.fulfillment.initialise.columns,
    null,
  );

export const useInternalTransfer = () =>
  usePost<Payload<{
    WorksaleId: number; FromWarehouse: string; OrderLines: { LineNumber: number }[]; urlQueryParams: { IgnoreWarning?: boolean };
  }>, IValidatedResponse>(
    APIRoutes.worksale.fulfillment.internalTransfer,
    null,
  );

export const useDirectSupply = () =>
  usePost<Payload<{
    WorksaleId: number; FromWarehouse: string; SupplyRemaining: boolean; OrderLines: { LineNumber: number }[]; urlQueryParams: { IgnoreWarning?: boolean };
  }>, IValidatedResponse>(
    APIRoutes.worksale.fulfillment.directSupply,
    null,
  );

type IObjectifiedDeliverySummaryResponse = AsObjectified<IDeliverySummaryResponse, 'DeliverySummary'>;

const fulfillmentDeliverySummaryResponseParser = (response) => ({
  ...response,
  DeliverySummary: response?.DeliverySummary ? toObjectified(response?.DeliverySummary as Inline<IDeliverySummaryFacade>) : null,
});

export const useFetchFulfillmentDeliverySummary = () =>
  useFetchMutation<Payload<{ WorksaledelivId: string; LineNumber: number; urlQueryParams: { Warehouse: string } }>, IObjectifiedDeliverySummaryResponse>(
    APIRoutes.worksale.fulfillment.deliverySummary,
    null,
    {
      inline: true,
      responseParser: fulfillmentDeliverySummaryResponseParser
    }
  );
