import React from 'react';
import { TreeList } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import { ITreeListHandle, TreeListProperties } from './TreeList.properties';
import { KeyboardNavigation, LoadPanel, RemoteOperations, Scrolling, StateStoring } from 'devextreme-react/tree-list';
import { withStyles } from '@material-ui/core';
import styles from './TreeList.styles';
import { ALLOWED_CHARACTERS, KeyPressTimeout, TAB } from 'utils/constants';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH } from '../DataGridDevEx/DataGrid.constants';
import { LoadOptions } from 'devextreme/data';
import classNames from 'classnames';
import { isNull } from 'utils/utils';
import { DEFAULT_SAVE_TIMEOUT } from './constants';
import { useGridKeyboardNavigation } from '../DataGridDevEx/DataGrid.hooks';

const TreeListWrapper = (props: TreeListProperties, treeRef: React.Ref<ITreeListHandle>) => {

  const {
    classes,
    itemId,
    disableKeyboardNavigation,
    children,
    defaultFirstRowSelection,
    productEntriesBold,
    storageKey,
    loadData,
    onSelectionChanged,
    onFocusedRowChanged,
    onTabOut,
    ...rest
  } = props;

  const { preventDataGridDefaults } = useGridKeyboardNavigation();
  const [selectedItemKey, setSelectedItemKey] = React.useState<string>(null);
  const [isRowTextBold, setIsRowTextBold] = React.useState<boolean>(false);
  const [isFirstTimeLoad, setIsFirstTimeLoad] = React.useState<boolean>(true);
  const [intervalId, setIntervalId] = React.useState(null);

  const treeListRef = React.useRef<TreeList>();
  const treeListContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(
    () => {
      // Cleanup timer on component unmount
      return () => {
        if (intervalId) {
          clearTimeout(intervalId);
        }
      };
    },
    [intervalId]
  );

  const dataSource = React.useMemo(
    () => new DataSource({
      key: String(itemId),
      load(loadOptions: LoadOptions<any>): Promise<any> {
        let response = [];

        return async function (): Promise<any> {
          for await (const parent of loadOptions?.parentIds) {
            const resp = await loadData(parent);
            response = response.concat(resp);
          }

          return response;
        }();
      },
    }),
    []
  );
  const selectedRowKey = React.useMemo(
    () => {
      return treeListRef.current?.instance.getSelectedRowKeys()?.[0];
    },
    [treeListRef.current?.instance.getSelectedRowKeys()]);

  React.useImperativeHandle(
    treeRef,
    () => ({
      searchItem,
      selectItem(key: any): void {
        treeListRef.current.instance?.selectRows([key], false);
        focusTreeList();
      },
      scrollToItem(key: any): void {
        treeListRef.current.instance?.navigateToRow(key);
      },
      unselectAll(): void {
        treeListRef.current.instance?.clearSelection();
      },
      selectDefault(): void {
        collapseAll();
        selectFirstRow();
      },
      collapseAll,
      collapseSelectedNodeChildren
    })
  );

  const collapseSelectedNodeChildren = () => {
    const selectedNode = treeListRef.current.instance?.getNodeByKey(
      treeListRef.current.instance.getSelectedRowKeys()?.[0]
    ) as any;

    selectedNode?.children?.forEach((item) => {
      if (treeListRef.current.instance.isRowExpanded(item.key)) {
        treeListRef.current.instance?.collapseRow(item.key);
      }
    });
    treeListRef.current.instance?.collapseRow(selectedNode?.key);
  };

  const handleFocusedRowChanging = React.useCallback(
    (e) => {
      const rowData = e.rows?.[e.newRowIndex]?.data;

      treeListRef.current?.instance.selectRows([rowData?.[itemId]], false);

      if (onFocusedRowChanged) {
        onFocusedRowChanged(e);
      }
    },
    [itemId]
  );

  const collapseAll = () => {
    treeListRef.current.instance?.option('expandedRowKeys', []);
  };

  const selectFirstRow = () => {
    treeListRef.current?.instance.selectRowsByIndexes([0]);
  };

  const areAllCharactersSame = (str) => [...str].every((char) => char === str[0]);

  const selectAndFocus = async (treeList, rowKey) => {
    await treeList.selectRows([String(rowKey)], false);
    const selectedElement = treeList.getRowElement(treeList.getRowIndexByKey(treeList.getSelectedRowKeys()[0]))[0];
    setTimeout(
      () => {
        treeList.focus(selectedElement);
      },
      0
    );
  };

  const jumpToRowFn = async (treeList, keys) => {
    const jumpToRow = treeList?.getVisibleRows().findIndex(
      (item) => item?.data?.Description.toLowerCase().startsWith(keys.toLowerCase())
    );

    if (jumpToRow !== -1) {
      const rowKey = treeList?.getVisibleRows()[jumpToRow]?.key;
      selectAndFocus(treeList, rowKey);
    } else {
      if (keys.length > 1 && areAllCharactersSame(keys)) {
        jumpToNextRowFn(treeList, keys[0]);
      }
    }
  };

  const jumpToNextRowFn = async (treeList, keys) => {
    const selectedRow = treeList.getSelectedRowsData()[0];
    const currentIndex = selectedRow ? treeList?.getVisibleRows().findIndex((item) => item.key === selectedRow.CatalogueId) : -1;
    const jumpToRow = treeList?.getVisibleRows().findIndex(
      (item, index) => index > currentIndex && item?.data?.Description.toLowerCase().startsWith(keys.toLowerCase())
    );
    if (jumpToRow !== -1) {
      const rowKey = treeList?.getVisibleRows()[jumpToRow]?.key;
      selectAndFocus(treeList, rowKey);
    } else {
      jumpToRowFn(treeList, keys);
    }
  };

  const handleKeys = async (treeList, keys) => {
    const selectedRow = treeList.getSelectedRowsData()[0];
    if (selectedRow?.Description.toLowerCase().startsWith(keys.toLowerCase())) {
      const currentIndex = selectedRow ? treeList?.getVisibleRows().findIndex((item) => item.key === selectedRow.CatalogueId) : -1;
      const nextItemIndex = treeList?.getVisibleRows().findIndex(
        (item, index) => index > currentIndex && item?.data?.Description.toLowerCase().startsWith(keys.toLowerCase())
      );

      if (nextItemIndex !== -1) {
        const rowKey = treeList?.getVisibleRows()[nextItemIndex]?.key;
        if (currentIndex !== -1) {
          selectAndFocus(treeList, rowKey);
        }
      } else {
        jumpToRowFn(treeList, keys);
      }

    } else {
      jumpToNextRowFn(treeList, keys);
    }
  };

  const handleRowCollapseExpand = async (e, treeList) => {
    if (!e.event.ctrlKey && e.event.key === 'ArrowRight') {
      e.handled = true;
      await treeList?.expandRow(selectedRowKey);
    }
    if (!e.event.ctrlKey && e.event.key === 'ArrowLeft') {
      //if row expanded, collapse it, other wise select and collapse parent.
      e.handled = true;
      if (treeList?.isRowExpanded(selectedRowKey)) {
        await treeList?.collapseRow(selectedRowKey);
      } else {
        const parentkey = treeList?.getNodeByKey(selectedRowKey).parent?.key;
        if (!isNull(parentkey)) {
          await treeList?.selectRows([parentkey], false);
          await treeList?.collapseRow(parentkey);
        }
      }
    }
  };

  const handleKeyboardNavigation = (e, treeList) => {
    if (treeList.getSelectedRowsData() && treeList.getSelectedRowsData().length > 0) {
      localStorage.setItem('pressedKeys', localStorage.getItem('pressedKeys') ? localStorage.getItem('pressedKeys') + e.event.key : e.event.key);

      // Clearing Timeout whenever a new key is pressed
      if (intervalId) {
        clearTimeout(intervalId);
      }

      // Start a new timeout
      const newIntervalId = setTimeout(
        () => {
          // Timeout will only remove the entry
          localStorage.removeItem('pressedKeys');
        },
        KeyPressTimeout
      );

      setIntervalId(newIntervalId);

      const keys = localStorage.getItem('pressedKeys');
      const selectedRow = treeList.getSelectedRowsData()[0];

      if (keys.length === 1) {
        // Handle single key
        handleKeys(treeList, e.event.key);
      } else if (keys.length > 1 && !selectedRow?.Description.toLowerCase().startsWith(keys.toLowerCase())) {
        // Handle multiple keys
        handleKeys(treeList, keys);
      }
    }
  };

  const handleKeyDown = React.useCallback(
    async (e) => {

      // Handle Tabs navigation through keyboard
      preventDataGridDefaults(e);

      const treeList = treeListRef?.current?.instance;

      if (!e.event.ctrlKey && e.event.keyCode !== TAB && (ALLOWED_CHARACTERS).includes(e.event.key.toLowerCase())) {
        // Handle Keyboard Navigation MPF-1525
        handleKeyboardNavigation(e, treeList);
      }

      // Handle row expansion and collapse
      handleRowCollapseExpand(e, treeList);

      if (e.event.keyCode === TAB) {
        if (onTabOut) {
          onTabOut();
        }
        e.handled = true;
      }
    },
    [treeListRef.current, selectedItemKey]
  );

  const treeListSelectionChanged = React.useCallback(
    (e) => {

      if (onSelectionChanged) {
        onSelectionChanged(e.component.getNodeByKey(e.component.getSelectedRowKeys()?.[0]) ?? null);
      }
    },
    []
  );

  function focusTreeList(): void {
    setTimeout(
      () => {
        treeListRef.current?.instance.focus();
      },
      0
    );
  }

  async function searchItem(parents: any, key: any): Promise<void> {
    if (treeListRef.current) {
      for await (const i of parents) {
        if (String(i) === String(key)) {
          await treeListRef.current?.instance.selectRows([String(i)], false);
          const selectedElement = treeListContainerRef.current?.querySelector('tr[aria-selected="true"]');
          await treeListRef.current?.instance.focus(selectedElement);
        } else {
          await treeListRef.current?.instance.expandRow(String(i));
        }
      }
    }
  }

  const handleOnSelectionChanged = React.useCallback(
    (e) => {
      const selectedNode = e.component.getSelectedRowsData()?.[0];
      if (!Boolean(selectedNode)) {
        sessionStorage.removeItem(storageKey);
      }

      setSelectedItemKey(selectedNode?.[itemId]);

      if (selectedNode) { // prevent deselection on click
        treeListSelectionChanged(e);
      }
    },
    []
  );

  const treeListContainerOnBlur = React.useCallback(
    () => {
      if (selectedItemKey) {
        setIsRowTextBold(true);
      }
    },
    [selectedItemKey]
  );

  const treeListContainerOnFocus = React.useCallback(
    () => {
      setIsRowTextBold(false);
    },
    []
  );

  const handleRowDbClick = React.useCallback(
    (e) => {
      treeListRef.current?.instance.expandRow(e.key);
    },
    []
  );

  const collapseSiblings = (node, siblings) => {
    siblings?.forEach((sibling) => {
      if (sibling.key !== node.key && treeListRef.current?.instance.isRowExpanded(sibling.key)) {
        collapseSiblings(sibling, sibling.children);
        treeListRef.current?.instance.collapseRow(sibling.key);
      }
    });
  };

  const onRowExpanding = React.useCallback(
    (e) => {
      const node = treeListRef.current?.instance.getNodeByKey(e.key);
      collapseSiblings(node, node.parent?.children);
    }
    ,
    [collapseSiblings]
  );

  const onContentReady = React.useCallback(
    () => {

      if (isFirstTimeLoad) {
        setIsFirstTimeLoad(false);

        if (defaultFirstRowSelection) {
          const storedTreeListData = sessionStorage.getItem('entry-treelist');
          const storedEntryData = JSON.parse(storedTreeListData);

          if (!storedEntryData?.selectedRowKeys?.[0]) {
            selectFirstRow();
            treeListRef.current?.instance.selectRowsByIndexes([0]);
          }
          focusTreeList();
        }
      }
    },
    [isFirstTimeLoad, defaultFirstRowSelection]
  );

  return (
    <div
      ref={treeListContainerRef}
      onBlur={treeListContainerOnBlur}
      onFocus={treeListContainerOnFocus}>
      <TreeList
        {...rest}
        ref={treeListRef}
        keyExpr={itemId}
        dataSource={dataSource}
        rootValue=''
        focusedRowEnabled={true}
        focusedRowKey={selectedItemKey}
        onFocusedRowChanging={handleFocusedRowChanging}
        onKeyDown={handleKeyDown}
        className={classNames({
          [classes.treeList]: true,
          [classes.treeListNormal]: !isRowTextBold,
          [classes.treeListBold]: isRowTextBold
        })}
        onRowExpanding={onRowExpanding}
        onRowDblClick={handleRowDbClick}
        onSelectionChanged={handleOnSelectionChanged}
        onContentReady={onContentReady}
      >
        <StateStoring enabled={true} type='sessionStorage' savingTimeout={DEFAULT_SAVE_TIMEOUT} storageKey={storageKey} />
        <RemoteOperations filtering={true} sorting={true} />
        <KeyboardNavigation enabled={!disableKeyboardNavigation} />
        {children}
        <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
        <Scrolling mode='standard' />
      </TreeList>
    </div>
  );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(TreeListWrapper)));
