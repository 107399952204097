import { getFormValues, isDirty, isValid, getFormSyncErrors, destroy, reset, getFormMeta, getFormInitialValues } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import CreditNoteDetails from './CreditNoteDetails';

import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as formSelectors } from 'ducks/form';
import { actions as creditNoteActions, selectors as creditNoteSelectors } from 'ducks/creditNotes/creditNote';

import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'CNOrderDetails';

const mapStateToProps = (state: IApplicationState) => (
  {
    operationMode: operationSelectors.operationMode(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedForm: formSelectors.selected(state),
    isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),

    creditNoteFetched: creditNoteSelectors.creditNoteFetched(state),
    creditNoteLoading: creditNoteSelectors.creditNoteLoading(state),
    creditNoteForms: creditNoteSelectors.creditNoteForms(state),
    creditNoteNotifications: creditNoteSelectors.notifications(state),
    creditNoteErrors: creditNoteSelectors.error(state),

    details: creditNoteSelectors.details(state),
    detailsSchema: creditNoteSelectors.detailsSchema(state),

    values: getFormValues(FORM_NAME)(state),
    initialValues: getFormInitialValues(FORM_NAME)(state),
    meta: getFormMeta(FORM_NAME)(state),
    dirty: isDirty(FORM_NAME)(state),
    isValid: isValid(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),

    isLoading: creditNoteSelectors.isLoading(state)
  });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    destroyForm: () => destroy(FORM_NAME),
    resetForm: () => reset(FORM_NAME),

    changeValidationDialog: uiActions.changeValidationDialog,
    changeSelectedTab: uiActions.changeSelectedTab,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    toggleCreditNotesLookupPanel: uiActions.toggleCreditNotesLookupPanel,

    updateDetails: creditNoteActions.updateDetails,
    saveCreditNoteDetails: creditNoteActions.saveCreditNoteDetails,
    cancelCreditNoteDetails: creditNoteActions.cancelCreditNoteDetails,
    getCreditNote: creditNoteActions.getCreditNote,
    changeOperationMode: operationActions.changeOperationMode,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteDetails);
