import { fetchPost, objectify, objectifyAddress, fetchGet } from '../utils'

export const search = (query) => {
  const { SearchText, Sort, BatchPage } = query
  const respType = 'Inline'
  return fetchPost('/SalesOrderEnquiry/SearchOrders', { SearchText, Sort, BatchPage}, null, respType)
    .then((result) => (result && result.SalesOrders &&
      {
        SalesOrders: result.SalesOrders.map((val) => objectify(val)),
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
      }
    ) || {SalesOrders: []})
}

export const searchById = (SalesOrder) => {
  return fetchGet(`/SalesOrderEnquiry/SalesOrder/${SalesOrder}/Details`, null, 'Inline')
    .then((result) => ({
      SalesOrders: (result
        && result.SalesOrderDetails
        && [ objectify(result.SalesOrderDetails) ])
        || [],
      currPage: 1,
      nextPage: false,
      prevPage: false}))
}

export const fetchSummary = (query) => {
  const { SalesOrder } = query
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Summary`)
    .then((result) => result ? objectify(result.SalesOrderSummary) : null)
}

export const getDetails = (query) => {
  const { SalesOrder } = query
  return fetchGet(`/SalesOrderEnquiry/SalesOrder/${SalesOrder}/Details`, null, 'Inline')
    .then((result) => {
      if (result) {
        const salesOrderDetails = objectify(result.SalesOrderDetails)
        return {
          values: Object.assign(salesOrderDetails, {
            PostalAddress: objectifyAddress(salesOrderDetails, 'Postal'),
            DeliveryAddress: objectifyAddress(salesOrderDetails, 'Delivery')
          }), schema: result.SalesOrderDetails
        }
      } else {
        return null
      }
    })
}
