import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as supplierInvoiceActions, selectors as supplierInvoiceSelectors } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from 'ducks/reducers';

// SupplierDisplay and InvoiceTotalDisplay are constructed fields from objectify
interface ISupplierItem {
  DocumentNumber: number;
  InvoiceNumber: string;
  SupplierDisplay: string;
  Warehouse: string;
  InvoiceTotalDisplay: string;
}

const SupplierSearchLookUpDrawer = buildSearchLookUpDrawer<ISupplierItem>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack' | 'searchTextProp'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ISupplierItem>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'SupplierInvoice',
    searchPlaceholder: 'Search for invoices...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'DocumentNumber',
        label: 'Document number'
      },
      {
        filter: 'SupplierDisplay',
        label: 'Supplier name'
      },
      {
        filter: 'Warehouse',
        label: 'Warehouse'
      },
      {
        filter: 'InvoiceTotalDisplay',
        label: 'Invoice total'
      }
    ],
    records: (supplierInvoiceSelectors.all(state) || []) as ISupplierItem[],
    selectedRecord: supplierInvoiceSelectors.selected(state),
    selectedSortFilter: 'DocumentNumber',
    selectedSortDirection: 'Ascending',
    open: uiSelectors.isSupplierLookUpOpen(state),
    isLoading: supplierInvoiceSelectors.isLoading(state),
    nextPage: supplierInvoiceSelectors.nextPage(state),
    prevPage: supplierInvoiceSelectors.prevPage(state),
    pageSize: supplierInvoiceSelectors.pageSize(state),
    totalPages: supplierInvoiceSelectors.totalPages(state),
    loadingPrevPage: supplierInvoiceSelectors.loadingPrevPage(state),
    loadingNextPage: supplierInvoiceSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'DocumentNumber',
    fieldsToDisplay: ['InvoiceNumber', 'SupplierDisplay', 'Warehouse', 'InvoiceTotalDisplay'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('DocumentNumber', selectedRecord.DocumentNumber);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const DocumentNumber = parseInt(matchParams.get('DocumentNumber'));
      if (DocumentNumber) {
        return {
          id: DocumentNumber
        };
      }

      return null;
    },
  });

const actionCreators: DispatchProps = {
  search: supplierInvoiceActions.search,
  searchById: supplierInvoiceActions.searchById,
  changeSelectedRecord: supplierInvoiceActions.setSelected,
  onToggle: uiActions.toggleSupplierLookUp,
  fetchNextPage: supplierInvoiceActions.fetchNextPage,
  fetchPrevPage: supplierInvoiceActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSearchLookUpDrawer);
