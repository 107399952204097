import { IFormViewForm } from 'components/FormView';

const RFCDetailsForm: IFormViewForm = {
  id: 'RFCDetails',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'RFC Details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference',
            name: 'Reference',
            size: 'large'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Return authority',
            name: 'ReturnAuthority',
            size: 'large'
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit note',
            name: 'CreditNote',
            size: 'small'
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reason',
            name: 'Reason',
            size: 'small'
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            size: 'small'
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Responsible person',
            name: 'ResponsiblePerson',
            size: 'small'
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Entered',
            name: 'Entered',
            size: 'small'
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Processed',
            name: 'Processed',
            size: 'small'
          }
        },
        {
          id: 8,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Financial period',
            name: 'FinancialPeriod',
            size: 'medium'
          }
        },
        {
          id: 9,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Finalised',
            name: 'DateFinalised',
            size: 'small'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Supplier details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Site code',
            name: 'SiteCode',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            label: 'Address',
            name: 'address'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Site contact',
            name: 'Contact',
            size: 'large'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Shipping details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Send via',
            name: 'SendVia',
            size: 'large'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Carrier',
            name: 'Carrier',
            size: 'large'
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Consignment Note',
            name: 'AccountNumber',
            size: 'large'
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Shipping instructions',
            name: 'ShippingInstructions',
            size: 'large'
          }
        },
      ]
    }
  ]
};
export default RFCDetailsForm;
