import {
  IWorksaleSummaryFacade,
  IWorksaleSummaryDetailsResponse,
  IWorksaleDetailsResponse,
  IWorksaleFacade,
  IWorksaleGridLineDetailFacade,
  IWorksaleGridLineDetailSearchResponse,
  IWorksaleFastLineDetailsResponse,
  IErrorResponse,
  IWorksaleGridLineDetailsResponse,
  IQuoteConfirmationDetailsResponse,
  IQuoteConfirmationFacade,
  IDocumentOutputOptionsFacade,
  ISaveAsQuoteDetailsResponse,
  IMessageModelSearchResponse,
  ISaleConfirmationDetailsResponse,
  ISaleConfirmationFacade,
  IWorksaleConfirmationDetailsResponse,
  ISalesOrderConfirmationDetailsResponse,
  IProcessSalesOrderDetailsResponse,
  ISalesOrderConfirmationFacade,
  IProcessSalesInvoiceDetailsResponse,
  IPaymentInformationDetailsResponse,
  ICalculateWorksalelineDetailFacade,
  IUpdateWorksalelineDetailFacade,
  IUpdateWorksalelineDetailResponse,
  IWorksalelineDetailResponse,
  IWorksaleDeliveryResponse,
  IWorksaleDeliveryFacade,
  IWorksaleDetailsFacade,
  ICustomerDeliveryDetailsResponse,
  ICustomerDeliveryDetails,
  IWorksalelineDetailFacade,
  IMessageModelFacade,
  IParkedSalesSearchResponse,
  IParkedSaleFilter,
  IRecallParkedSaleFacade,
  IInquiryConfirmationFacade,
  IInquiryConfirmationDetailsResponse,
  IGetWarehouseSelectionResponse,
  IWarehouseSelectionLinesFacade,
  IInitialiseParkedSaleResponse,
  IInitialiseParkedSaleFacade,
  IWorksaleDetailsDetailsResponse,
  IRetrieveWorksaleDeliveriesResponse,
  IWorksaleDeliveryDataFacade,
  IWorksaleDeliveryResponseModel,
  IWorksaleDeliveryModelFacade,
  IWorksaleLostsaleResponse,
  ILostsaleFacade,
  IWorksalelineLostsaleResponse,
  ILineLostsaleFacade,
  ICalculateFastLineCriteriaFacade,
  IWorksalelineComponentSearchResponse,
  IKitDetail as IKitDetailSwagger,
  IComponentFastLineDetailsResponse,
  ICalculateComponentDetailsFacade,
  ILoadedComponentsDetailFacade,
  ILoadedComponentDetailResponse,
  IComponentLineDetailResponse,
  IComponentLineDetailFacade,
  IComponentDetailFacade,
  IUpdateComponentLineDetailFacade,
  IUpdateComponentLineDetailResponse,
  IWorksalelineKitSummaryResponse,
  IWorksalelineKitSummaryFacade,
  IWIPComponentDetailResponse,
  IWIPComponentFacade,
  IDeleteRecordResponse,
} from './../swaggerTypes';
import { Inline, IObjectified, InlineValue, objectify } from '../utils';
import { Api, toObjectified, toObjectifiedArray } from '../baseApi';
import { ILookupResponse } from 'api/customType';
import { APIMethod, Payload, prepareUrl, useDelete, useFetch, useFetchMutation, usePost, useUpdate } from 'api/reactQuery/queryService';
import { APIRoutes } from './constants';
import { useQueryClient } from '@tanstack/react-query';

export type InlineWorkSaleSummary = Omit<IWorksaleSummaryFacade, 'SaleHeader' | 'SaleTotals' | 'CustomerStatus'> & {
  SaleHeader: Inline<IWorksaleSummaryFacade['SaleHeader']>;
  CustomerStatus: Inline<IWorksaleSummaryFacade['CustomerStatus']>;
  SaleTotals: Inline<IWorksaleSummaryFacade['SaleTotals']>;
  WorksaleId: InlineValue<IWorksaleSummaryFacade['WorksaleId']>;
};

export type ObjectifiedWorkSaleSummary = Omit<IWorksaleSummaryFacade, 'SaleHeader' | 'SaleTotals' | 'CustomerStatus'> & {
  SaleHeader: IObjectified<IWorksaleSummaryFacade['SaleHeader']>;
  CustomerStatus: IObjectified<IWorksaleSummaryFacade['CustomerStatus']>;
  SaleTotals: IObjectified<IWorksaleSummaryFacade['SaleTotals']>;
  WorksaleId: IWorksaleSummaryFacade['WorksaleId'];
};

export type InlineWorkSaleSummaryDetailsResponse = Omit<IWorksaleSummaryDetailsResponse, 'WorksaleSummary'> & {
  WorksaleSummary: InlineWorkSaleSummary;
};

export type ObjectifiedWorkSaleSummaryDetailsResponse = Omit<IWorksaleSummaryDetailsResponse, 'WorksaleSummary'> & {
  WorksaleSummary: ObjectifiedWorkSaleSummary;
};

const applyWorksaleCriteriaResponseParser = (response): Promise<InlineWorkSaleSummaryDetailsResponse> => {

  return {
    ...response,
    WorksaleSummary: response.WorksaleSummary ? {
      ...response.WorksaleSummary,
      SaleHeader: toObjectified(response.WorksaleSummary.SaleHeader),
      CustomerStatus: toObjectified(response.WorksaleSummary.CustomerStatus),
      SaleTotals: toObjectified(response.WorksaleSummary.SaleTotals),
      WorksaleId: response.WorksaleSummary.WorksaleId.Value
    } : null
  };
};

export const useApplyWorksaleCriteria = () => {

  return usePost<Payload<IWorksaleSummaryFacade>, ObjectifiedWorkSaleSummaryDetailsResponse>
    (APIRoutes.worksale.apply.worksaleCriteria, null, { inline: true, responseParser: applyWorksaleCriteriaResponseParser });
};

const changeDeliveryResponseParser = (response): Promise<ObjectifiedWorkSaleSummaryDetailsResponse> => {

  return {
    ...response,
    WorksaleSummary: response.WorksaleSummary ? {
      ...response.WorksaleSummary,
      SaleHeader: toObjectified(response.WorksaleSummary.SaleHeader),
      CustomerStatus: toObjectified(response.WorksaleSummary.CustomerStatus),
      SaleTotals: toObjectified(response.WorksaleSummary.SaleTotals),
      WorksaleId: response.WorksaleSummary.WorksaleId.Value
    } : null
  };
};

export const useChangeDelivery = () => {

  return usePost<Payload<{ WorksaleId: number }>, ObjectifiedWorkSaleSummaryDetailsResponse>
    (APIRoutes.deliveries.update.changeDelivery, null, { inline: true, responseParser: changeDeliveryResponseParser });
};

export type InlineWorksaleDetailsResponse = Omit<IWorksaleDetailsResponse, 'WorkSale'> & {
  WorkSale: Omit<IWorksaleFacade, 'Criteria' | 'Summary'> & {
    Criteria: Inline<IWorksaleFacade['Criteria']>;
    Summary: InlineWorkSaleSummary;
  };
};

export type ObjectifiedWorksaleDetailsResponse = Omit<IWorksaleDetailsResponse, 'WorkSale'> & {
  WorkSale: Omit<IWorksaleFacade, 'Criteria' | 'Summary'> & {
    Criteria: IObjectified<IWorksaleFacade['Criteria']>;
    Summary: ObjectifiedWorkSaleSummary;
  };
};

const fetchCurrentWorksaleResponseParser = (response): Promise<InlineWorksaleDetailsResponse> => {
  return {
    ...response,
    WorkSale: response.WorkSale ? {
      ...response.WorkSale,
      Criteria: toObjectified(response.WorkSale.Criteria),
      Summary: {
        ...response.WorkSale.Summary,
        SaleHeader: toObjectified(response.WorkSale.Summary.SaleHeader),
        CustomerStatus: toObjectified(response.WorkSale.Summary.CustomerStatus),
        SaleTotals: toObjectified(response.WorkSale.Summary.SaleTotals),
        WorksaleId: response.WorkSale.Summary.WorksaleId.Value
      }
    } : null
  };
};
export const useFetchCurrentWorksale = () => {
  return useFetch<ObjectifiedWorksaleDetailsResponse>(
    {
      url: APIRoutes.worksale.retrieve.currentWorksale,
      queryConfig: { enabled: false },
      options: { inline: true, responseParser: fetchCurrentWorksaleResponseParser }
    }
  );
};

export const useSetCurrentWorksaleQueryData = () => {
  const queryClient = useQueryClient();

  return (dataToUpdate: ObjectifiedWorksaleDetailsResponse) => {
    const finalUrl = prepareUrl(APIRoutes.worksale.retrieve.currentWorksale, null, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

const fetchCurrentWorksaleSummaryResponseParser = (response): Promise<InlineWorkSaleSummaryDetailsResponse> => {

  return {
    ...response,
    WorksaleSummary: response.WorksaleSummary ? {
      SaleHeader: toObjectified(response.WorksaleSummary.SaleHeader),
      CustomerStatus: toObjectified(response.WorksaleSummary.CustomerStatus),
      SaleTotals: toObjectified(response.WorksaleSummary.SaleTotals),
      WorksaleId: response.WorksaleSummary.WorksaleId.Value
    } : null
  };
};
export const useFetchCurrentWorksaleSummary = (worksaleId: number) => {
  return useFetch<ObjectifiedWorkSaleSummaryDetailsResponse>(
    {
      url: APIRoutes.worksale.retrieve.currentWorksaleSummary,
      queryConfig: { enabled: Boolean(worksaleId > 0) },
      options: { inline: true, responseParser: fetchCurrentWorksaleSummaryResponseParser }
    }
  );
};

export const useSetSummaryQueryData = () => {
  const queryClient = useQueryClient();

  return (dataToUpdate: ObjectifiedWorkSaleSummaryDetailsResponse) => {
    const finalUrl = prepareUrl(APIRoutes.worksale.retrieve.currentWorksaleSummary, null, null, true);
    queryClient.setQueryData([APIMethod.GET, finalUrl], dataToUpdate);
  };
};

export const useFetchWorksaleSites = () => {

  return usePost<Payload<{ SearchText: string; WorksaleID: number }>, ILookupResponse>
    (APIRoutes.worksale.lines.worksale.sites, null);
};

export const useDeleteCurrentWorksale = () => {

  return usePost<Payload<{ urlQueryParams: { createLostsale: boolean } } & ILostsaleFacade>, IDeleteRecordResponse>
    (APIRoutes.worksale.delete.currentWorksale, null);
};

export const useCalculateFastEntryDetails = () => {

  return usePost<Payload<ICalculateFastLineCriteriaFacade & { WorksaleId: number }>, IWorksaleFastLineDetailsResponse>
    (APIRoutes.worksale.lines.calculateFastLine, null, { disableDefaultValidation: true });
};

export const useFetchLines = () => {

  return usePost<Payload<{ WorksaleId: number; SiteCode: any }>, IWorksaleGridLineDetailSearchResponse>
    (APIRoutes.worksale.lines.retrieve, null);
};

export type IObjectifiedWorksalelineComponentSearchResponse = Omit<IWorksalelineComponentSearchResponse, 'Components'> & {
  Components: IObjectified<IWIPComponentFacade>[];
};

const fetchComponentLinesResponseParser = (response): Promise<IObjectifiedWorksalelineComponentSearchResponse> => {

  return {
    ...response,
    Components: response?.Components.map((c) => objectify(c, null, 'Inline'))
  };
};

export const useFetchComponentLines = () => {

  return useFetchMutation<Payload<{ WorksalelineId: number }>, IObjectifiedWorksalelineComponentSearchResponse>(
    APIRoutes.kitComponents.line.retrieve,
    null,
    {
      inline: true,
      responseParser: fetchComponentLinesResponseParser
    }
  );
};

export const useCreateComponentLine = () => {

  return useUpdate<Payload<{ WorksalelineId: number; urlQueryParams?: { ProductId: string | number; RequiredQuantity: string | number } }>, IWIPComponentDetailResponse>
    (APIRoutes.kitComponents.line.create, null, null);
};

export type IObjectifiedComponentFastLineDetailsResponse = Omit<IComponentFastLineDetailsResponse, 'ComponentDetails'> & {
  ComponentDetails: IObjectified<ICalculateComponentDetailsFacade>;
};

export type InlineComponentFastLineDetailsResponse = Omit<IWorksaleGridLineDetailsResponse, 'ComponentDetails'> & {
  ComponentDetails: Inline<ICalculateComponentDetailsFacade>;
};

const calculateComponentFastLineDetailsResponseParser = (response): Promise<InlineComponentFastLineDetailsResponse> => {
  const { ComponentDetails } = response;

  return {
    ...response,
    ComponentDetails: ComponentDetails ? toObjectified(ComponentDetails as Inline<ICalculateComponentDetailsFacade>) : null
  };
};

export const useCalculateComponentFastLineDetails = () => {

  return useFetchMutation<Payload<{ WorksalelineId: number }>, IObjectifiedComponentFastLineDetailsResponse>(
    APIRoutes.kitComponents.calculate.fastLineDetails,
    null,
    {
      inline: true,
      responseParser: calculateComponentFastLineDetailsResponseParser
    }
  );
};

export type IObjectifiedWorksalelineKitSummaryResponse = Omit<IWorksalelineKitSummaryResponse, 'WorksalelineKitSummary'> & {
  kitSummary: IObjectified<IWorksalelineKitSummaryFacade>;
};
export type InlineWorksalelineKitSummaryResponse = Omit<IWorksalelineKitSummaryResponse, 'WorksalelineKitSummary'> & {
  WorksalelineKitSummary: Inline<IWorksalelineKitSummaryFacade>;
};

const getLineKitSummaryResponseParser = (response): Promise<InlineWorksalelineKitSummaryResponse> => {
  const { WorksalelineKitSummary } = response;

  return {
    ...response,
    kitSummary: WorksalelineKitSummary ? toObjectified(WorksalelineKitSummary as Inline<IWorksalelineKitSummaryFacade>) : null
  };
};

export const useGetLineKitSummary = () => {

  return useFetchMutation<Payload<{ WorksalelineId: number }>, IObjectifiedWorksalelineKitSummaryResponse>(
    APIRoutes.kitComponents.line.kit.summary,
    null,
    {
      inline: true,
      responseParser: getLineKitSummaryResponseParser
    }
  );
};

const calculateComponentGridLineDetailsResponseParser = (response): Promise<IObjectifiedComponentFastLineDetailsResponse> => {
  const { ComponentDetails } = response;

  return {
    ...response,
    ComponentDetails: ComponentDetails ? toObjectified(ComponentDetails as Inline<ICalculateComponentDetailsFacade>) : null
  };
};

export const useCalculateComponentGridLineDetails = () => {

  return usePost<Payload<ICalculateComponentDetailsFacade & { WorksalelineId: number; ComponentId: number }>, IObjectifiedComponentFastLineDetailsResponse>
    (APIRoutes.kitComponents.calculate.gridLineDetails, null, { responseParser: calculateComponentGridLineDetailsResponseParser });
};

export type IObjectifiedWorksalelineComponentDetailResponse = Omit<IWIPComponentDetailResponse, 'ComponentDetails'> & {
  ComponentLine: IObjectified<IWIPComponentFacade>;
};

export type InlineWorksalelineComponentDetailResponse = Omit<IWIPComponentDetailResponse, 'ComponentDetails'> & {
  ComponentLine: Inline<IWIPComponentFacade>;
};

const updateComponentGridLineResponseParser = (response): Promise<InlineWorksalelineComponentDetailResponse> => {
  const { ComponentLine } = response;

  return {
    ...response,
    ComponentLine: ComponentLine ? toObjectified(ComponentLine as Inline<IWIPComponentFacade>) : null
  };
};

export const useUpdateComponentGridLine = () => {

  return usePost<Payload<IWIPComponentFacade & { WorksalelineId: number; ComponentId: number }>, IObjectifiedWorksalelineComponentDetailResponse>
    (APIRoutes.kitComponents.line.update, null, { inline: true, responseParser: updateComponentGridLineResponseParser });
};

export const useDeleteComponent = () => {

  return useDelete<Payload<{ ComponentId: number }>>(APIRoutes.kitComponents.line.delete, null, null);
};

const totalComponentLinesCountResponseParser = (response): Promise<number> => {

  return response?.BatchInformation?.TotalCount;
};

export const useTotalComponentLinesCount = () => {

  return useFetchMutation<Payload<{ WorksalelineId: number }>, number>(
    APIRoutes.kitComponents.line.retrieve,
    null,
    {
      responseParser: totalComponentLinesCountResponseParser
    }
  );
};

const totalLinesCountResponseParser = (response): Promise<IWorksaleGridLineDetailSearchResponse> => {
  return response?.BatchInformation?.TotalCount;
};

export const useTotalLinesCount = () => {

  return usePost<Payload<{ WorksaleId: number }>, number>
    (APIRoutes.worksale.lines.totalCount, null, { responseParser: totalLinesCountResponseParser });
};

export const useRemoveLine = () => {

  return usePost<Payload<{ WorkSaleLineId: number; urlQueryParams: { createLostsale: boolean } } & ILineLostsaleFacade>, IDeleteRecordResponse>
    (APIRoutes.worksale.delete.line, null, null);
};

export type IObjectifiedWorksaleGridLineDetailsResponseResponse = Omit<IWorksaleGridLineDetailsResponse, 'GridLineDetails'> & {
  GridLineDetails: IObjectified<IWorksaleGridLineDetailFacade>;
};
export type InlineWorksaleGridLineDetailsResponseResponse = Omit<IWorksaleGridLineDetailsResponse, 'GridLineDetails'> & {
  GridLineDetails: Inline<IWorksaleGridLineDetailFacade>;
};

const lineResponseParser = (response): Promise<InlineWorksaleGridLineDetailsResponseResponse> => {
  const { GridLineDetails } = response;

  return {
    ...response,
    GridLineDetails: GridLineDetails ? toObjectified(GridLineDetails as Inline<IWorksaleGridLineDetailFacade>) : null
  };
};

export const useInsertLine = () => {

  return usePost<Payload<IWorksaleGridLineDetailFacade & { WorksaleId: number }>, IObjectifiedWorksaleGridLineDetailsResponseResponse>
    (APIRoutes.worksale.lines.insert, null, { responseParser: lineResponseParser });
};

export const useCalculateGridLineDetails = () => {

  return usePost<Payload<IWorksaleGridLineDetailFacade & { WorksaleId: number; WorksalelineId: number }>, IWorksaleGridLineDetailsResponse>
    (APIRoutes.worksale.lines.calculateGridLine, null, null);
};

export const useUpdateLine = () => {

  return usePost<Payload<IWorksaleGridLineDetailFacade & { WorksaleId: number; WorksalelineId: number }>, IObjectifiedWorksaleGridLineDetailsResponseResponse>
    (APIRoutes.worksale.lines.update, null, { responseParser: lineResponseParser });
};

export type IObjectifiedCalculateWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'CalculateWorksalelineDetail'> & {
  CalculateWorksalelineDetail?: IObjectified<IWorksalelineDetailFacade>;
};
export type InlineCalculateWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'CalculateWorksalelineDetail'> & {
  CalculateWorksalelineDetail?: Inline<IWorksalelineDetailFacade>;
};

export async function calculateWorksaleLineDetails(WorksaleId: number, line: ICalculateWorksalelineDetailFacade, changedField: keyof ICalculateWorksalelineDetailFacade): Promise<IObjectifiedCalculateWorksaleLineResponse> {
  const params = new URLSearchParams('');
  params.set('responseMetadataFormat', 'Inline');
  params.set('ChangedField', changedField);

  return Api.post<ICalculateWorksalelineDetailFacade, InlineCalculateWorksaleLineResponse>(
    `SalesProcessing/Worksale/${WorksaleId}/Worksaleline/${line.WorksalelineId}/CalculateWorksalelineDetail?${params.toString()}`,
    line)
    .then((response) => {

      return {
        ...response,
        CalculateWorksalelineDetail: response.CalculateWorksalelineDetail ? toObjectified(response.CalculateWorksalelineDetail) : null
      };
    });
}
export interface IKitDetail extends IKitDetailSwagger {
  Primary?: boolean;
  BomCode?: string;
  Description?: string;
}
interface IUpdateWorksalelineDetailFacadeExtended extends IUpdateWorksalelineDetailFacade {
  KitDetail: IKitDetail[];
}

export type IObjectifiedIUpdateWorksalelineDetailResponse = Omit<IUpdateWorksalelineDetailResponse, 'OverrideDetails'> & {
  OverrideDetails?: IObjectified<IUpdateWorksalelineDetailFacadeExtended>;
};
export type InlineIUpdateWorksalelineDetailResponse = Omit<IUpdateWorksalelineDetailResponse, 'OverrideDetails'> & {
  OverrideDetails?: Inline<IUpdateWorksalelineDetailFacadeExtended>;
};

export type IObjectifiedWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'WorksalelineDetail'> & {
  WorksalelineDetail: IObjectified<IWorksalelineDetailFacade>;
};

export type InlineWorksaleLineResponse = Omit<IWorksalelineDetailResponse, 'WorksalelineDetail'> & {
  WorksalelineDetail: Inline<IWorksalelineDetailFacade>;
};

export type IObjectifiedLoadedComponentDetailResponse = Omit<ILoadedComponentDetailResponse, 'Components'> & {
  Components: IObjectified<ILoadedComponentsDetailFacade>;
};
export type InlineLoadedComponentDetailResponse = Omit<ILoadedComponentDetailResponse, 'Components'> & {
  Components: Inline<ILoadedComponentsDetailFacade>;
};

export type InlineQuoteConfirmationDetailsResponse = Omit<IQuoteConfirmationDetailsResponse, 'QuoteConfirmation'> & {
  QuoteConfirmation: Inline<Omit<CorrectedQuoteConfirmationFacade, 'QuoteOutput'>> & {
    QuoteOutput: Inline<IDocumentOutputOptionsFacade>;
  };
};

export type CorrectedQuoteConfirmationFacade = Omit<IQuoteConfirmationFacade, 'QuoteOutput'> & {
  QuoteOutput?: IDocumentOutputOptionsFacade;
};

export type ObjectifiedQuoteConfirmationDetailsResponse = Omit<IQuoteConfirmationDetailsResponse, 'QuoteConfirmation'> & {
  QuoteConfirmation: IObjectified<Omit<CorrectedQuoteConfirmationFacade, 'QuoteOutput'>> & {
    QuoteOutput: IObjectified<IDocumentOutputOptionsFacade>;
  };
};

export const useRecallQuote = () => {

  return usePost<Payload<{ WorksaleId: number }>, IMessageModelSearchResponse>
    (APIRoutes.worksale.recallQuote.recall, null, { inline: true });
};

const fetchQuoteConfirmationResponseParser = (response): Promise<InlineQuoteConfirmationDetailsResponse> => {
  if (!response.QuoteConfirmation) {
    return {
      ...response,
      QuoteConfirmation: undefined
    };
  }

  const { QuoteOutput, ...QuoteConfirmation } = response.QuoteConfirmation;

  return {
    ...response,
    QuoteConfirmation: {
      ...toObjectified(QuoteConfirmation),
      QuoteOutput: toObjectified(QuoteOutput)
    }
  };
};

export const useFetchQuoteConfirmation = (WorksaleId) => {
  return useFetch<ObjectifiedQuoteConfirmationDetailsResponse>(
    {
      url: APIRoutes.worksale.quote.confirmation,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { inline: true, responseParser: fetchQuoteConfirmationResponseParser }
    }
  );
};

export type InlineInquiryConfirmationDetailsResponse = Omit<IInquiryConfirmationDetailsResponse, 'InquiryConfirmation'> & {
  InquiryConfirmation: Inline<IInquiryConfirmationFacade>;
};

export type ObjectifiedInquiryConfirmationDetailsResponse = Omit<IInquiryConfirmationDetailsResponse, 'InquiryConfirmation'> & {
  InquiryConfirmation: IObjectified<IInquiryConfirmationFacade>;
};

const fetchParksaleConfirmationResponseParser = (response): Promise<InlineInquiryConfirmationDetailsResponse> => {
  if (!response.InquiryConfirmation) {
    return {
      ...response,
      InquiryConfirmation: undefined
    };
  }

  const { ...InquiryConfirmation } = response.InquiryConfirmation;

  return {
    ...response,
    InquiryConfirmation: {
      ...toObjectified(InquiryConfirmation)
    }
  };
};

export const useFetchParksaleConfirmation = (WorksaleId) => {
  return useFetch<ObjectifiedInquiryConfirmationDetailsResponse>(
    {
      url: APIRoutes.worksale.parksale.confirmation,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { inline: true, responseParser: fetchParksaleConfirmationResponseParser }
    }
  );
};

export const useSaveAsQuote = () => {

  return usePost<Payload<CorrectedQuoteConfirmationFacade>, ISaveAsQuoteDetailsResponse>
    (APIRoutes.worksale.quote.save, null, { inline: true });
};

export const useSaveAsSalesEnquiry = () => {

  return usePost<Payload<IInquiryConfirmationFacade>, ISaveAsQuoteDetailsResponse>
    (APIRoutes.worksale.salesEnquiry.save, null, { inline: true });
};

const fetchWorksaleConfirmationResponseParser = (response): Promise<IWorksaleConfirmationDetailsResponse> => {

  return response;
};

export const useFetchWorksaleConfirmation = (WorksaleId) => {
  return useFetch<IWorksaleConfirmationDetailsResponse>(
    {
      url: APIRoutes.worksale.worksale.confirmation,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { responseParser: fetchWorksaleConfirmationResponseParser, disableDefaultValidation: true }
    }
  );
};

export type InlineSaleConfirmationDetailsResponse = Omit<ISaleConfirmationDetailsResponse, 'SaleConfirmation'> & {
  SaleConfirmation: Inline<Omit<ISaleConfirmationFacade, 'InvoiceOutput' | 'OrderAcknowledgementOutput' | 'PickSlipOutput' | 'SalesDocketOutput'>> & {
    InvoiceOutput: Inline<IDocumentOutputOptionsFacade>;
    OrderAcknowledgementOutput: Inline<IDocumentOutputOptionsFacade>;
    PickSlipOutput: Inline<IDocumentOutputOptionsFacade>;
    SalesDocketOutput: Inline<IDocumentOutputOptionsFacade>;
  };
};

export type ObjectifiedSaleConfirmationDetailsResponse = Omit<ISaleConfirmationDetailsResponse, 'SaleConfirmation'> & {
  SaleConfirmation: IObjectified<Omit<ISaleConfirmationFacade, 'InvoiceOutput' | 'OrderAcknowledgementOutput' | 'PickSlipOutput' | 'SalesDocketOutput'>> & {
    InvoiceOutput: IObjectified<IDocumentOutputOptionsFacade>;
    OrderAcknowledgementOutput: IObjectified<IDocumentOutputOptionsFacade>;
    PickSlipOutput: IObjectified<IDocumentOutputOptionsFacade>;
    SalesDocketOutput: IObjectified<IDocumentOutputOptionsFacade>;
  };
};

const fetchSaleConfirmationResponseParser = (response): Promise<InlineSaleConfirmationDetailsResponse> => {
  if (!response.SaleConfirmation) {
    return {
      ...response,
      SaleConfirmation: undefined
    };
  }

  const { InvoiceOutput, OrderAcknowledgementOutput, PickSlipOutput, SalesDocketOutput, ...SaleConfirmation } = response.SaleConfirmation;

  return {
    ...response,
    SaleConfirmation: {
      ...toObjectified(SaleConfirmation),
      InvoiceOutput: toObjectified(InvoiceOutput),
      OrderAcknowledgementOutput: toObjectified(OrderAcknowledgementOutput),
      PickSlipOutput: toObjectified(PickSlipOutput),
      SalesDocketOutput: toObjectified(SalesDocketOutput)
    }
  };
};

export const useFetchSaleConfirmation = (WorksaleId) => {
  return useFetch<ObjectifiedSaleConfirmationDetailsResponse>(
    {
      url: APIRoutes.worksale.sale.confirmation,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { inline: true, responseParser: fetchSaleConfirmationResponseParser }
    }
  );
};

export type InlineSalesOrderConfirmationDetailsResponse = Omit<ISalesOrderConfirmationDetailsResponse, 'SalesOrderConfirmation'> & {
  SalesOrderConfirmation: Inline<Omit<ISaleConfirmationFacade, 'OrderAcknowledgementOutput'>> & {
    OrderAcknowledgementOutput: Inline<IDocumentOutputOptionsFacade>;
  };
};

export type ObjectifiedSalesOrderConfirmationDetailsResponse = Omit<ISalesOrderConfirmationDetailsResponse, 'SalesOrderConfirmation'> & {
  SalesOrderConfirmation: IObjectified<Omit<ISaleConfirmationFacade, 'OrderAcknowledgementOutput'>> & {
    OrderAcknowledgementOutput: IObjectified<IDocumentOutputOptionsFacade>;
  };
};

const fetchSalesOrderConfirmationResponseParser = (response): Promise<InlineSalesOrderConfirmationDetailsResponse> => {
  if (!response.SalesOrderConfirmation) {
    return {
      ...response,
      SalesOrderConfirmation: undefined
    };
  }

  const { OrderAcknowledgementOutput, ...SalesOrderConfirmation } = response.SalesOrderConfirmation;

  return {
    ...response,
    SalesOrderConfirmation: {
      ...toObjectified(SalesOrderConfirmation),
      OrderAcknowledgementOutput: toObjectified(OrderAcknowledgementOutput),
    }
  };
};

export const useFetchSalesOrderConfirmation = (WorksaleId) => {
  return useFetch<ObjectifiedSalesOrderConfirmationDetailsResponse>(
    {
      url: APIRoutes.worksale.salesOrder.confirmation,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { responseParser: fetchSalesOrderConfirmationResponseParser }
    }
  );
};

export const useProcessSalesOrderConfirmation = () => {

  return usePost<Payload<ISalesOrderConfirmationFacade>, IProcessSalesOrderDetailsResponse>
    (APIRoutes.worksale.process.orderConfirmation, null, { disableDefaultValidation: true });
};

export const useProcessSaleConfirmation = () => {

  return usePost<Payload<ISaleConfirmationFacade>, IProcessSalesInvoiceDetailsResponse>
    (APIRoutes.worksale.process.saleConfirmation, null, { disableDefaultValidation: true });
};

export const useTransmitInternalSale = () => {

  return usePost<Payload<{ SalesOrder: string; PurchaseOrder: string }, { SalesOrder: string; PurchaseOrder: string }>, IProcessSalesInvoiceDetailsResponse>
    (APIRoutes.worksale.internaleTransfer.transmit, null);
};

const initializeWorksaleLostSaleResponseParser = (response): Promise<InlineWorksaleLostsaleResponse> => {
  const { Lostsale } = response;

  return {
    ...response,
    Lostsale: Lostsale ? toObjectified(Lostsale as Inline<ILostsaleFacade>) : null
  };
};

export const useInitializeWorksaleLostSale = () => {

  return usePost<Payload<{ WorksaleId?: number }>, IObjectifiedWorksaleLostsaleResponse>
    (APIRoutes.worksale.initialize.worksaleLostSale, null, { inline: true, responseParser: initializeWorksaleLostSaleResponseParser });
};

const initializeWorksaleLineLostSaleResponseParser = (response): Promise<InlineWorksalelineLostsaleResponse> => {
  const { LineLostsale } = response;

  return {
    ...response,
    LineLostsale: LineLostsale ? toObjectified(LineLostsale as Inline<ILineLostsaleFacade>) : null
  };
};

export const useInitializeWorksaleLineLostSale = () => {

  return usePost<Payload<{ WorksaleId?: number; WorksaleLineId: number }>, IObjectifiedWorksalelineLostsaleResponse>
    (APIRoutes.worksale.initialize.worksaleLineLostSale, null, { inline: true, responseParser: initializeWorksaleLineLostSaleResponseParser });
};

const fetchPaymentInformationResponseParser = (response): Promise<IPaymentInformationDetailsResponse> => {

  return response;
};

export const useFetchPaymentInformation = (WorksaleId) => {
  return useFetch<IPaymentInformationDetailsResponse>(
    {
      url: APIRoutes.worksale.payment.information,
      urlParams: { WorksaleId },
      queryConfig: { enabled: false },
      options: { responseParser: fetchPaymentInformationResponseParser }
    }
  );
};

export type IObjectifiedComponentLineDetailResponse = Omit<IComponentLineDetailResponse, 'ComponentLineDetail'> & {
  ComponentLineDetail: IObjectified<IComponentLineDetailFacade>;
};
export type InlineComponentLineDetailResponse = Omit<IComponentLineDetailResponse, 'ComponentLineDetail'> & {
  ComponentLineDetail: Inline<IComponentLineDetailFacade>;
};

export type IObjectifiedUpdateComponentLineDetailResponse = Omit<IUpdateComponentLineDetailResponse, 'ComponentLineDetail'> & {
  ComponentLineDetail: IObjectified<IUpdateComponentLineDetailFacade>;
};
export type InlineUpdateComponentLineDetailResponse = Omit<IUpdateComponentLineDetailResponse, 'ComponentLineDetail'> & {
  ComponentLineDetail: Inline<IUpdateComponentLineDetailFacade>;
};

const fetchComponentLineDetailResponseParser = (response): Promise<IObjectifiedComponentLineDetailResponse> => {
  const { ComponentLineDetail = {} } = response;

  return {
    ...response,
    ComponentLineDetail: ComponentLineDetail ? toObjectified(ComponentLineDetail as Inline<IComponentLineDetailFacade>) : null
  };
};

export const useFetchComponentLineDetail = () => {

  return useFetchMutation<Payload<{ ComponentId: number }>, IObjectifiedComponentLineDetailResponse>(
    APIRoutes.kitComponents.details.retrieve,
    null,
    {
      inline: true,
      responseParser: fetchComponentLineDetailResponseParser
    }
  );
};

const calculateComponentLineDetailResponseParser = (response): Promise<IObjectifiedComponentLineDetailResponse> => {
  const { ComponentLineDetail } = response;

  return {
    ...response,
    ComponentLineDetail: ComponentLineDetail ? toObjectified(ComponentLineDetail as Inline<IComponentLineDetailFacade>) : null
  };
};

export const useCalculateComponentLineDetail = () => {

  return usePost<Payload<IComponentDetailFacade & { WorksalelineId: number; ComponentId: number }>, IObjectifiedComponentLineDetailResponse>
    (APIRoutes.kitComponents.details.calculate.componentLineDetail, null, { inline: true, responseParser: calculateComponentLineDetailResponseParser });
};

const updateComponentDetailResponseParser = (response): Promise<IObjectifiedUpdateComponentLineDetailResponse> => {
  const { ComponentLineDetail } = response;

  return {
    ...response,
    ComponentLineDetail: ComponentLineDetail ? toObjectified(ComponentLineDetail as Inline<IUpdateComponentLineDetailFacade>) : null
  };
};

export const useUpdateComponentDetail = () => {

  return usePost<Payload<IUpdateComponentLineDetailFacade & { WorksalelineId: number; ComponentId: number }>, IObjectifiedUpdateComponentLineDetailResponse>
    (APIRoutes.kitComponents.details.update, null, { inline: true, responseParser: updateComponentDetailResponseParser });
};

export type IObjectifiedWorksaleDeliveryDetailsResponse = Omit<IWorksaleDeliveryResponse, 'WorksaleDelivery'> & {
  WorksaleDelivery: IObjectified<IWorksaleDeliveryFacade>;
};
export type InlineWorksaleDeliveryDetailsResponse = Omit<IWorksaleDeliveryResponse, 'WorksaleDelivery'> & {
  WorksaleDelivery: Inline<IWorksaleDeliveryFacade>;
};

export type IObjectifiedWorksalelineLostsaleResponse = Omit<IWorksalelineLostsaleResponse, 'LineLostsale'> & {
  LineLostsale: IObjectified<ILineLostsaleFacade>;
};
export type InlineWorksalelineLostsaleResponse = Omit<IWorksalelineLostsaleResponse, 'LineLostsale'> & {
  LineLostsale: Inline<ILineLostsaleFacade>;
};

export type IObjectifiedWorksaleLostsaleResponse = Omit<IWorksaleLostsaleResponse, 'Lostsale'> & {
  Lostsale: IObjectified<ILostsaleFacade>;
};
export type InlineWorksaleLostsaleResponse = Omit<IWorksaleLostsaleResponse, 'Lostsale'> & {
  Lostsale: Inline<ILostsaleFacade>;
};

export type ObjectifiedRetrieveWorksaleDeliveriesResponse = Omit<IRetrieveWorksaleDeliveriesResponse, 'Deliveries'> & {
  Deliveries: IObjectified<IWorksaleDeliveryDataFacade>[];
};

export type IObjectifiedWorksaleDeliveryResponseModel = Omit<IWorksaleDeliveryResponseModel, 'WorksaleDelivery'> & {
  WorksaleDelivery: IObjectified<IWorksaleDeliveryModelFacade>;
};
export type InlineWorksaleDeliveryResponseModel = Omit<IWorksaleDeliveryResponseModel, 'WorksaleDelivery'> & {
  WorksaleDelivery: Inline<IWorksaleDeliveryModelFacade>;
};

export type IObjectifiedWorksaleOrderDetailsResponse = Omit<IWorksaleDetailsDetailsResponse, 'Messages' | 'WorksaleDetails'> & {
  WorksaleDetails: IObjectified<IWorksaleDetailsFacade>;
  'Messages'?: IMessageModelFacade[];
};
export type InlineWorksaleOrderDetailsResponse = Omit<IWorksaleDetailsDetailsResponse, 'WorksaleDetails'> & {
  WorksaleDetails: Inline<IWorksaleDetailsFacade>;
};

const fetchWorksaleDeliveryDefaultsResponseParser = (response): Promise<IObjectifiedWorksaleDeliveryDetailsResponse> => {
  const { WorksaleDelivery = {} } = response;
  const WorksaleDeliveryDetails = {
    ...WorksaleDelivery
  };

  return {
    ...response,
    WorksaleDelivery: WorksaleDelivery ? toObjectified(WorksaleDeliveryDetails as Inline<IWorksaleDeliveryFacade>) : null
  };
};

export const useFetchWorksaleDeliveryDefaults = (WorkSaleId) => {
  return useFetch<IObjectifiedWorksaleDeliveryDetailsResponse>(
    {
      url: APIRoutes.deliveries.default.retrieve,
      urlParams: { WorkSaleId },
      queryConfig: { enabled: false },
      options: { inline: true, responseParser: fetchWorksaleDeliveryDefaultsResponseParser }
    }
  );
};

export type IObjectifiedCustomerDeliveryDetailsResponse = Omit<ICustomerDeliveryDetailsResponse, 'CustomerDeliveryDetails'> & {
  CustomerDeliveryDetails: IObjectified<ICustomerDeliveryDetails>;
};
export type InlineCustomerDeliveryDetailsResponse = Omit<ICustomerDeliveryDetailsResponse, 'CustomerDeliveryDetails'> & {
  CustomerDeliveryDetails: Inline<ICustomerDeliveryDetails>;
};

const updateWorksaleDeliveryDetailsResponseParser = (response): Promise<IObjectifiedWorksaleDeliveryDetailsResponse> => {
  const { WorksaleDelivery = {} } = response;
  const WorksaleDeliveryDetails = {
    ...WorksaleDelivery
  };

  return {
    ...response,
    WorksaleDelivery: WorksaleDelivery ? toObjectified(WorksaleDeliveryDetails as Inline<IWorksaleDeliveryFacade>) : null
  };
};

export const useUpdateWorksaleDeliveryDetails = () => {

  return useUpdate<Payload<{ SiteCode: string; saveDeliveryDetails: boolean; query: IWorksaleDeliveryFacade & { address: any }; urlQueryParams?: any }>, any>
    (APIRoutes.deliveries.update.worksaleDelivery, null, { inline: true, responseParser: updateWorksaleDeliveryDetailsResponseParser });
};

const createWorksaleDeliveryResponseParser = (response): Promise<IObjectifiedWorksaleDeliveryDetailsResponse> => {
  const { WorksaleDelivery } = response;

  return {
    ...response,
    WorksaleDelivery: WorksaleDelivery ? toObjectified(WorksaleDelivery as Inline<IWorksaleDeliveryFacade>) : null
  };
};

export const useCreateWorksaleDelivery = () => {

  return usePost<Payload<{ WorksaleId: number; saveDeliveryDetails: boolean; query: IWorksaleDeliveryFacade & { address: any }; urlQueryParams?: any }>, any>
    (APIRoutes.deliveries.create.worksaleDelivery, null, { inline: true, responseParser: createWorksaleDeliveryResponseParser });
};

const calculateDeliveryDetailsResponseParser = (response): Promise<IObjectifiedWorksaleDeliveryResponseModel> => {
  const { WorksaleDelivery } = response;

  return {
    ...response,
    WorksaleDelivery: WorksaleDelivery ? toObjectified(WorksaleDelivery as Inline<IWorksaleDeliveryModelFacade>) : null
  };
};

export const useCalculateDeliveryDetails = () => {

  return useUpdate<Payload<{ WorksaleId: number; changedField: string; deliveryDetails: IWorksaleDeliveryFacade & { address: any }; SiteCode?: string; urlQueryParams?: any }>, any>
    (APIRoutes.deliveries.calculate.deliveryDetails, null, { inline: true, responseParser: calculateDeliveryDetailsResponseParser });
};

export async function recallParkedSale(query: IRecallParkedSaleFacade): Promise<IMessageModelSearchResponse> {
  return Api.postInline<IMessageModelFacade, IMessageModelSearchResponse>('SalesProcessing/Worksale/RecallParkedSale', query);
}

export type ObjectifiedIParkedSalesSearchResponse = Omit<IParkedSalesSearchResponse, 'ParkedSales'> & {
  ParkedSales: IObjectified<IWorksaleDeliveryFacade>[];
};
export async function retrieveParkedSales(query: IParkedSaleFilter): Promise<ObjectifiedIParkedSalesSearchResponse> {
  return Api.postInlineCollectionResponse<IParkedSaleFilter, ObjectifiedIParkedSalesSearchResponse, 'ParkedSales'>('SalesProcessing/RetrieveParkedSales', query)
    .then((response) => {

      return {
        ...response,
        ParkedSales: response.ParkedSales ? response.ParkedSales.map((d) => objectify(d, null, 'Inline')) : []
      };
    });
}

export async function deleteParkSale(ParkSaleId: string): Promise<IErrorResponse> {
  return Api.delete<IErrorResponse>(`SalesProcessing/DeleteParkedSales/${ParkSaleId}`);
}

interface IObjectifiedGetWarehouseSelectionResponse {
  records: IObjectified<IWarehouseSelectionLinesFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}

export async function getWorksaleSelection({ ProductId, Page, SortDirection, Sort }: { ProductId: number; filters?: any; Page: number; Sort: string; SortDirection: string }): Promise<IObjectifiedGetWarehouseSelectionResponse> {
  const params = new URLSearchParams('');
  params.set('responseMetadataFormat', 'None');
  params.set('BatchPage', Page.toString());
  params.set('BatchSize', '10');
  params.set('Sort', Sort && SortDirection && `${Sort}:${SortDirection}`);

  return Api.postInlineCollectionResponse<void, IGetWarehouseSelectionResponse, 'WarehouseSelectionLines'>(`Product/${ProductId}/GetWarehouseSelection?${params.toString()}`)
    .then((response) => {
      const records = response.WarehouseSelectionLines ? response.WarehouseSelectionLines.map((d) => objectify(d, null, 'Inline')) as IObjectified<IWarehouseSelectionLinesFacade>[] : [];
      const batchInformation = (response as IGetWarehouseSelectionResponse).BatchInformation;

      return {
        records,
        currPage: batchInformation ? batchInformation.BatchPage : 1,
        nextPage: batchInformation && !batchInformation.EndOfData,
        prevPage: batchInformation && batchInformation.BatchPage > 1
      };
    });
}

export type IObjectifiedInitializeRecallParkedSaleResponse = Omit<IInitialiseParkedSaleResponse, 'InitialisedParkedSales'> & {
  InitialisedParkedSales: IObjectified<IInitialiseParkedSaleFacade>;
};
export type InlineInitialisedParkedSales = Omit<IInitialiseParkedSaleResponse, 'InitialisedParkedSales'> & {
  InitialisedParkedSales: Inline<IInitialiseParkedSaleFacade>;
};

export async function fetchInitialRetrieveParkedSales(): Promise<IObjectifiedInitializeRecallParkedSaleResponse> {
  return Api.postInline<void, InlineInitialisedParkedSales>('SalesProcessing/RetrieveParkedSale/InitialiseRetrieveParkedSales')
    .then((response) => {
      return {
        ...response,
        InitialisedParkedSales: response.InitialisedParkedSales ? toObjectified(response.InitialisedParkedSales) as IObjectified<IInitialiseParkedSaleFacade> : null
      };
    });
}

export type IObjectifiedRetrieveWorksaleDeliveriesResponse = Omit<IRetrieveWorksaleDeliveriesResponse, 'Deliveries'> & {
  Deliveries: IObjectified<IWorksaleDeliveryDataFacade>[];
};

export interface IRetrieveWorksaleDeliveriesPayload {
  WorkSaleId: number;
  CustomerId: number;
  Sort: string;
  SortDirection: string;
  BatchPage: number;
  BatchSize?: number;
}

const retrieveEntriesResponseParser = (response): any => ({
  ...response,
  Deliveries: response.Deliveries ? toObjectifiedArray(response.Deliveries) as IObjectified<IWorksaleDeliveryDataFacade>[] : []
});

export const useRetrieveWorkSaleDeliveries = () => {

  return usePost<Payload<{ WorkSaleId?: number; urlQueryParams?: any }>, any>(APIRoutes.deliveries.entries.retrieve, null, {
    inline: true, responseParser: retrieveEntriesResponseParser
  });
};

const updateDefaultWorksaleDeliveryResponseParser = (response) => ({
  ...response,
  WorksaleSummary: (response.WorksaleSummary && response.WorksaleSummary) ? {
    ...response.WorksaleSummary,
    SaleHeader: toObjectified(response.WorksaleSummary.SaleHeader),
    CustomerStatus: toObjectified(response.WorksaleSummary.CustomerStatus),
    SaleTotals: toObjectified(response.WorksaleSummary.SaleTotals),
    WorksaleId: response.WorksaleSummary.WorksaleId.Value
  } : null
});

export const useUpdateDefaultWorksaleDelivery = () => {

  return useUpdate<Payload<{ WorkSaleId?: number; urlQueryParams?: any }>, any>
    (APIRoutes.deliveries.default.worksale, null, { inline: true, responseParser: updateDefaultWorksaleDeliveryResponseParser });
};

export const useDeleteWorksaleDelivery = () => {

  return useDelete<Payload<{ WorksaleDeliveryId: number }>>(APIRoutes.deliveries.delete.worksaleDelivery, null, null);
};
