import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/salesInvoiceEnquiry/InvoiceLineBOM';
import { fetch } from 'api/salesInvoiceEnquiry/invoiceLineBOM';
import { selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';
import { selectors as invoiceLineSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLines';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'BOM',
  columnDefs: lineSelectors.lineColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  keyFields: [{ valueField: 'LineNumber', routeField: 'BomLineNumber' }],
  reqParams: (invoiceSelectors.selected(state) || params.get('InvoiceNumber') || params.get('SalesEntity')) &&
    (invoiceLineSelectors.selected(state) || params.get('LineNumber'))
    &&
  {
    Invoice: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).Invoice) || params.get('InvoiceNumber'),
    SalesEntity: (invoiceSelectors.selected(state) && invoiceSelectors.selected(state).SalesEntity) || params.get('SalesEntity'),
    LineNumber: (invoiceLineSelectors.selected(state) && invoiceLineSelectors.selected(state).LineNumber) || params.get('LineNumber')
  },
  apiMethod: fetch
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected
}
