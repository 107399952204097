import { combineReducers } from 'redux';
import serviceActivity, { IServiceActivityState } from './serviceActivities';

export interface IServiceActivitySchedulingState {
    serviceActivity: IServiceActivityState;
}
const combined = combineReducers<IServiceActivitySchedulingState>({
    serviceActivity
});

export default combined;
