import React from 'react';
import SummaryPanel from 'components/common/SummaryPanel';
import { useRetrievePickSlipSummary } from 'api/pickSlips/slipLinesUpdated';
import CustomerStatus from './CreditStatus';
import { IPickSlipsSummaryPanel, ISummaryHandle } from './SummaryPanel.properties';
import SummaryTableSchema from '../SummaryPanel/SummaryTableSchema';

const PickSlipsSummaryPanel = (props: IPickSlipsSummaryPanel, ref: React.Ref<ISummaryHandle>) => {
    const { selectedDespatchId } = props;

    const fetchSummary = useRetrievePickSlipSummary(Number(selectedDespatchId));

    React.useImperativeHandle(
        ref,
        () => ({
            reloadSummary(): void {
                fetchSummary.refetch();
            }
        }),
        [fetchSummary]
    );

    return (
        <>
            <SummaryPanel
                loading={fetchSummary.isInitialLoading}
                data={fetchSummary.data?.PickSlipSummary?.inlineObject}
                schema={SummaryTableSchema}
                customChildren={{ CustomerStatus }}
            />
        </>
    );
};

export default React.forwardRef(PickSlipsSummaryPanel);
