import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
    createActions, asyncInitialState, asyncSelectors,
    IDataAction,
    IExtendedState
} from '../utils';

import { FormViewField } from 'components/FormView';

export interface IPolicyRulesData {
    selected: any;
    gridOptions: IDataGridOptions;
    Actions: any;
    rulesColumns: IColDef[];
    rules: any[];
    selectedFilters: any;
    contextMenu: any[];
    filterRow: {
        formName: string;
        parameters: FormViewField[];
    };
}

export interface IPolicyRulesState extends IExtendedState<IPolicyRulesData> {
    fetch_loading?: boolean;
}

export const { types, actions } = createActions(
    {
        setSelected: (row) => ({ row }),
        asyncs: {
        }
    },
    'policyRules');

const initialState = asyncInitialState<IPolicyRulesData>({
    selected: null,
    gridOptions: {
        doubleClickActionTab: '',
        suppressEditDeleteInContextMenu: true,
        rowModelType: 'serverSide',
        cacheBlockSize: 10,
        maxBlocksInCache: 5,
        enableMultiSelection: true,
        allowSelectAll: false
    },
    contextMenu: [
        {
            name: 'Reset to Default',
            disabled: false
        },
        'separator',
        {
            beginGroup: true,
            name: 'Set Full Access',
            disabled: false
        },
        {
            name: 'Set No Access',
            disabled: false
        },
        'separator',
        'export'
    ],
    Actions: {
    },
    rulesColumns: [
        { headerName: 'Item', field: 'Description', minWidth: 800 },
        {
            headerName: 'Access', field: 'AccessLevel', suppressSorting: true, minWidth: 200, valueGetter: (row) => {
                if (row.data) {
                    if (row.data.HasRule) {
                        return row.data.AccessLevel;
                    } else {
                        return `Default(${row.data.AccessLevel})`;
                    }
                }

                return null;
            }
        },
        { headerName: 'Type', field: 'ProgramType', suppressSorting: true, minWidth: 200 }
    ],
    rules: [],
    selectedFilters: { Type: 'AllPrograms', Access: 'All' },
    filterRow: {
        formName: 'SecurityManagementPolicyRulesFilters',
        parameters: [
            {
                id: 0,
                type: 'AUTOCOMPLETE_FIELD',
                props: {
                    label: 'Type',
                    name: 'Type',
                    required: true,
                    options: [
                        {
                            value: 'AllPrograms',
                            label: 'All'
                        },
                        {
                            value: 'Configuration',
                            label: 'Configuration'
                        },
                        {
                            value: 'Diagnostic',
                            label: 'Diagnostic'
                        },
                        {
                            value: 'Export',
                            label: 'Export'
                        },
                        {
                            value: 'Import',
                            label: 'Import'
                        },
                        {
                            value: 'Enquiry',
                            label: 'Enquiry'
                        },
                        {
                            value: 'Maintenance',
                            label: 'Maintenance'
                        },
                        {
                            value: 'ModulesFolder',
                            label: 'Modules Folder'
                        },
                        {
                            value: 'Report',
                            label: 'Report'
                        },
                        {
                            value: 'Tool',
                            label: 'Tool'
                        },
                        {
                            value: 'Transaction',
                            label: 'Transaction'
                        }
                    ]
                }
            },
            {
                id: 1,
                type: 'AUTOCOMPLETE_FIELD',
                props: {
                    label: 'Access',
                    name: 'Access',
                    required: true,
                    options: [
                        {
                            value: 'All',
                            label: 'All'
                        },
                        {
                            value: 'Default',
                            label: 'Default'
                        }
                        ,
                        {
                            value: 'FullAccess',
                            label: 'Full Access'
                        }
                        ,
                        {
                            value: 'NoAccess',
                            label: 'No Access'
                        }
                    ]
                }
            },
        ] as FormViewField[]
    }
});

export default (state: IPolicyRulesState = initialState, action: IDataAction): IPolicyRulesState => {
    switch (action.type) {
        case types.setSelected:
            const selected = action.data.row;

            return {
                ...state,
                data: {
                    ...state.data,
                    selected: selected
                }
            };
        default:
            return state;
    }
};

const asyncSelector = asyncSelectors(
    (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules,
    {

    }
);

const syncSelector = {
    selected: (data) => data.selected,
    isLoading: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.fetch_loading,
    gridOptions: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.data.gridOptions,
    columns: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.data.rulesColumns,
    filterRow: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.data.filterRow,
    selectedFilters: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.data.selectedFilters,
    contextMenu: (state: { securityManagement: { policyRules: IPolicyRulesState } }) => state.securityManagement.policyRules.data.contextMenu
};

export const selectors = { ...asyncSelector, ...syncSelector };
