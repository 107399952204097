import { IFormViewForm } from 'components/FormView';

const ServicePatternDetail: IFormViewForm = {
  id: 'ServicePatternDetail',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1,
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Name',
            name: 'Name',
            placeholder: '',
            size: 'medium',
            required: true,
            inputProps: {
              maxLength: 40
            }
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'Description',
            placeholder: '',
            required: true,
            size: 'large',
            inputProps: {
              maxLength: 40
            }
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Service item type',
            name: 'ServiceItemType',
            lookupName: 'ServiceItemType',
            placeholder: '',
            size: 'medium'
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Frequency value',
            name: 'FrequencyValue',
            placeholder: '',
            size: 'medium',
            required: true,
            type: 'number',
            constraints: {
              minValue: 1,
              maxValue: 9999
            }
          }
        },
        {
          id: '5',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Frequency type',
            lookupName: 'ServiceFrequencyType',
            name: 'FrequencyType',
            placeholder: '',
            required: true,
            isLookupTypeFixed: true,
            size: 'medium'
          }
        },
      ]
    }
  ]
};
export default ServicePatternDetail;
