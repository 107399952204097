import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectify } from './../utils'

export const search = (query) => {
  const { TransactionNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost(`/SupplierEnquiry/Transactions/Transaction/${TransactionNumber}/Retrieve`, {},
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize })
    .then((result) => {
      if (result) {
        if (result.SupplierTransactions) {
          const params = new URLSearchParams(location.search);
          const Supplier = params.get('Supplier');
          return {
            records: result.SupplierTransactions.map((val) => {
              const obj = objectify(val, null, 'Inline');
              return { ...obj, Supplier }
            }
            ),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        }
        else {
          return result
        }
      }
    })
}