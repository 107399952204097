import { IFormViewForm } from 'components/FormView';

const DiaryDetails: IFormViewForm = {
  id: 'DiaryDetails',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        name: 'diary',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 2
      },
      children: [
        {
          id: 1,
          type: 'DIARY_DETAILS',
          props: {
            label: 'PickSlipDiaryDetails',
            name: 'PickSlipDiaryDetails',
            createApi: '/PickSlipTransaction/Despatch/<DespatchId>/CreateDiary',
            updateApi: '/PickSlipTransaction/Despatch/<DespatchId>/Diary/<DiaryId>',
            searchByIdApi: '/PickSlipTransaction/Despatch/<DespatchId>/Diary/<DiaryId>',
            deleteApi: '/PickSlipTransaction/Despatch/<DespatchId>/Diary/<DiaryId>'
          }
        }
      ]
    }
  ]
};
export default DiaryDetails;
