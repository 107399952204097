const SummaryTableSchema = [
    {
      objectName: 'chargesSummary',
      isLoading: 'loadingChargesSummarytop',
      children: [
        {
          label: 'Product',
          fieldNames: [
            'ProductIdDisplay',
            'ProductIdDisplayLabel'
          ],
        },
        {
          label: 'Group',
          fieldNames: [
            'ProductGroup',
          ],
        }
      ]
    },
    {
      objectName: 'chargesSummary',
      isLoading: 'loadingChargesSummary',
      children: [
        {
          label: 'Base Price',
          fieldNames: [
            'BasePriceDisplay'
          ],
          valueRenderer: {
            isNumeric: true
          }
        },
        {
          label: 'Price Include Tax',
          fieldNames: [
            'TaxIncludedPriceDisplay'
          ],
          valueRenderer: {
            isNumeric: true
          }
        },
        {
          label: 'Unit',
          fieldNames: [
            'Unit'
          ],
        },
        {
          label: 'Sell/UNIT',
          fieldNames: [
            'SellPerUnitDisplay'
          ],
          valueRenderer: {
            isNumeric: true
          }
        },
        {
          label: 'Tax Total',
          fieldNames: [
            'TaxTotalDisplay'
          ],
          valueRenderer: {
            isNumeric: true
          }
        },
        {
          label: 'Extended Total',
          fieldNames: [
            'ExtendedTotalDisplay'
          ],
          valueRenderer: {
            isNumeric: true,
            emphasize: true,
          }
        },
      ]
    },
  ];

export default SummaryTableSchema;
