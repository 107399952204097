import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncSelectors, asyncOnError
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (transactionId) => ({ transactionId }),
  setSelectedTransactionLine: (transactionId) => ({ transactionId }),
  changeSortFilter: (selectedSortFilter) => selectedSortFilter,
  changeSortDirection: (selectedSortDirection) => selectedSortDirection,
  clear: () => null,
  asyncs: {
    fetchTransactionDetails: (transactionDetails) => transactionDetails,
  }
}, 'detailsTransactions')

const NOT_SELECTED = -1
let initialState = asyncInitialState({
  selected: NOT_SELECTED,
  selectedTransactionLine: NOT_SELECTED,
  list: null,
  schemas: null,
  transactionDetails: null,
  selectedSortFilter: 'Name',
  selectedSortDirection: 'desc',
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  transactionLineActions: {},
  transactionLineColumns: [
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 120, },
    { headerName: 'Entity', field: 'SalesEntity', minWidth: 120, suppressSorting: true },
    {
      headerName: 'Invoice',
      field: 'InvoiceNumber',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data.InvoiceNumber) {
          return {
            SalesEntity: row.data.SalesEntity,
            InvoiceNumber: row.data.InvoiceNumber,
            FieldValue: row.data.InvoiceNumber
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Transaction type', field: 'TransactionType', minWidth: 150, suppressSorting: true },
    { headerName: 'Transaction', field: 'TransactionIdDisplay', minWidth: 120, },
    { headerName: 'Original total', field: 'OriginalTotalDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Amount', field: 'AmountDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true },
    { headerName: 'Financial period', field: 'FinancialPeriod', minWidth: 250, suppressSorting: true },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true },
    { headerName: 'Local original', field: 'LocalOriginalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Local amount', field: 'LocalAmountDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true }
  ],
  transactionLinesData: null,
  transactionLinesSchema: [],
})

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return initialState
    case types.changeSortFilter:
      return {
        ...state,
        data: {
          ...state.data, selectedSortFilter: action.data
        }
      };
    case types.changeSortDirection:
      return {
        ...state,
        data: {
          ...state.data, selectedSortDirection: action.data
        }
      };

    case types.setSelected:
      const selected = action.data.transactionId;
      return {
        ...state,
        data: { ...state.data, selected }
      }
    case types.setSelectedTransactionLine:
      const selectedTransactionLine = action.data.transactionId;
      return {
        ...state,
        data: { ...state.data, selectedTransactionLine }
      }

    case types.fetchTransactionDetails:
      return asyncOnRequest(state, action)
    case types.saga.fetchTransactionDetails.success:
      return asyncOnSuccess(state, action, (data, action) => {
        return {
          ...data,
          transactionDetails: action.payload
        }
      })
    case types.saga.fetchTransactionDetails.failure:
      return asyncOnError(state, action)
    default:
      return state
  }
}

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.detailsTransactions,
  {
    list: (data) => data.list !== undefined && data.list != null && Object.values(data.list),
    // selected: (data) => (data.list !== undefined && data.list != null && data.list[data.selected]) || null,
    transactionLineActions: data => data.transactionLineActions
  }
)

const syncSelector = {
  selectedSortFilter: state => state.customerEnquiry.detailsTransactions.data.selectedSortFilter,
  selectedSortDirection: state => state.customerEnquiry.detailsTransactions.data.selectedSortDirection,
  isLoading: state => state.customerEnquiry.detailsTransactions.loading,
  gridOptions: state => state.customerEnquiry.detailsTransactions.data.gridOptions,
  lineColumns: state => state.customerEnquiry.detailsTransactions.data.transactionLineColumns,
  lineActions: state => state.customerEnquiry.detailsTransactions.data.transactionLineActions,
  linesData: state => state.customerEnquiry.detailsTransactions.data.transactionLinesData,
  // list: (state) => state.customerEnquiry.transactions.data.list,
  transactionDetails: (state) => state.customerEnquiry.detailsTransactions.data.transactionDetails,
  selected: (state) => (state.customerEnquiry.detailsTransactions.data.list !== undefined && state.customerEnquiry.detailsTransactions.data.list != null && Object.assign({}, state.customerEnquiry.detailsTransactions.data.list.filter(x => x.Transaction.Value === state.customerEnquiry.detailsTransactions.data.selected))[0]) || null,
  selectedTransactionLine: (state) => state.customerEnquiry.detailsTransactions.data.selectedTransactionLine,
  selectedTransactionSchema: (state) => (state.customerEnquiry.detailsTransactions.data.transactionLinesSchema && state.customerEnquiry.detailsTransactions.data.selectedTransactionLine) ? state.customerEnquiry.detailsTransactions.data.transactionLinesSchema.filter(x => x.Transaction === state.customerEnquiry.detailsTransactions.data.selectedTransactionLine.Transaction)[0] : null,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)

