import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as imagesTypes, actions as imagesActions } from 'ducks/supplierEnquiry/attachments/images';

import { callApi } from '../../utils';
import * as api from 'api/supplierEnquiry/attachments/images';


function* fetch(action) {
  const { success, failure } = imagesActions.saga.fetch;
  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga() {
  yield takeLatest(imagesTypes.fetch, fetch)
}