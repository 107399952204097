import { IFormViewForm } from 'components/FormView';

const SaleConfirmation: IFormViewForm = {
    id: 'DespatchConfirmationForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    props: {
                        name: 'InvoiceSale',
                        defaultValue: false,
                        label: 'Invoice sale',
                    }
                },
                {
                    id: 2,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                        verticalAlign: 'sub'
                    },
                    props: {
                        name: 'OutputInvoice',
                        defaultValue: false,
                        label: 'Invoice',
                        style: {
                            width: 'fit-content',
                            marginLeft: '10px',
                        },
                        precedentFields: ['InvoiceSale'],
                    }
                },
                {
                    id: 3,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'InvoiceOutput',
                        fieldId: 'InvoiceOutput',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputInvoice'],
                    }
                },
                {
                    id: 2,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                        verticalAlign: 'sub'
                    },
                    props: {
                        name: 'Transmit',
                        defaultValue: false,
                        label: 'Transmit',
                        style: {
                            width: 'fit-content',
                            marginLeft: '10px',
                        },
                        precedentFields: ['InvoiceSale'],
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    style: {
                        display: 'inline-block',
                        width: '62%'
                    },
                    props: {
                        name: 'TransmitVia',
                        placeholder: '',
                        size: 'large',
                    }
                },
                {
                    id: 4,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                        verticalAlign: 'sub'
                    },
                    props: {
                        name: 'OutputSalesDocket',
                        defaultValue: false,
                        label: 'Sales docket',
                        precedentFields: ['InvoiceSale', 'EnableOutputSalesDocket'],
                        style: {
                            marginLeft: '10px',
                            width: 'fit-content'
                        }
                    }
                },
                {
                    id: 5,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'SalesDocketOutput',
                        fieldId: 'SalesDocketOutput',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputSalesDocket'],
                    }
                },
                {
                    id: 6,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '26%',
                        marginLeft: '4%',
                        verticalAlign: 'sub'
                    },
                    props: {
                        name: 'OutputBarcodeLabel',
                        defaultValue: false,
                        label: 'Barcode label',
                        precedentFields: ['EnableOutputBarcodeLabel'],
                    }
                },
                {
                    id: 7,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'BarcodeLabelOutput',
                        fieldId: 'BarcodeLabelOutput',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputBarcodeLabel'],
                    }
                },
                {
                    id: 6,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                    },
                    props: {
                        name: 'OutputDespatchNote',
                        defaultValue: false,
                        label: 'Despatch note',
                        precedentFields: ['EnableOutputDespatchNote'],
                    }
                },
                {
                    id: 7,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'DespatchNoteOutput',
                        fieldId: 'DespatchNoteOutput',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputDespatchNote'],
                    }
                },
                {
                    id: 8,
                    type: 'TOGGLE_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '30%',
                        verticalAlign: 'sub'
                    },
                    props: {
                        name: 'OutputDeliveryDocket',
                        defaultValue: false,
                        label: 'Delivery Docket',
                        precedentFields: ['EnableOutputDeliveryDocket'],
                    }
                },
                {
                    id: 9,
                    type: 'DOCUMENT_OUTPUT_FIELD',
                    visible: true,
                    style: {
                        display: 'inline-block',
                        width: '70%',
                    },
                    props: {
                        size: 'large',
                        label: 'Print to printer',
                        name: 'DeliveryDocketOutput',
                        fieldId: 'DeliveryDocketOutput',
                        documentType: 'SO',
                        isCustomerScoped: true,
                        precedentFields: ['OutputDeliveryDocket'],
                    }
                },
                {
                    id: 10,
                    type: 'DIVIDER',
                    props: {
                    }
                },
                {
                    id: 11,
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Pick ID',
                        name: 'PickId',
                        lookupName: 'Personnel',
                        isLookupTypeFixed: true,
                        size: 'small',
                    }
                },
                {
                    id: 12,
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        label: 'User ID',
                        name: 'UserId',
                        lookupName: 'Personnel',
                        placeholder: '',
                        size: 'small',

                    }
                },
                {
                    id: 13,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'PIN',
                        name: 'Pin',
                        placeholder: '****',
                        size: 'small',
                        type: 'password',
                        inputProps: {
                            maxLength: 4
                        },
                        required: false
                    }
                },
                {
                    id: 14,
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Invoice due',
                        name: 'InvoiceDueDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'small',
                    }
                },
            ]
        }
    ]
};

export default SaleConfirmation;
