import { ViewTypes } from '@markinson/mk.mpv4.schedulercomponent';

export const MODULE_TREE = [

];

export const SCHEDULER_CONFIG = {
    schedulerWidth: '67.6%',
    schedulerMaxHeight: '100%',
    eventItemHeight: 70,
    eventItemLineHeight: 70,
    tableHeaderHeight: 50,
    nonWorkingTimeHeadColor: '#000000',
    nonWorkingTimeHeadBgColor: '#fccc92',
    nonWorkingTimeBodyBgColor: '#fccc92',
    groupOnlySlotColor: '#dddddd',
    resourceName: 'Equipment/Technician',
    views: [
        { viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
        { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
        { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false }
    ]

};
