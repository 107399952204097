import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/common/priceCalculator';

import { callApi } from '../utils';
import * as api from 'api/common/priceCalculator';


function* getPriceDetails(action) {
  const { success, failure } = actions.saga.fetchPriceDetails;

  yield callApi(
    call(api.fetchPriceDetails, action.data),
    success,
    failure
  )
}

function* fetchDefaultPriceDetails(action) {
  const { success, failure } = actions.saga.fetchDefaultPriceDetails;

  yield callApi(
    call(api.fetchDefaultPriceDetails, action.data),
    success,
    failure
  )
}

export default function* rootSaga() {
  yield takeLatest(types.fetchPriceDetails, getPriceDetails)
  yield takeLatest(types.fetchDefaultPriceDetails, fetchDefaultPriceDetails)
}