import { combineReducers } from 'redux';

import notepad from './notepad';
import events from './events';
import diary from './diary';
import filters from './filters';
import priceCalculator from './priceCalculator';
import serialNumber, { ISerialNumberState } from './serialNumber';
import paymentDetails, { IPaymentsState } from './paymentDetails';

export interface ICommonState {
  serialNumber: ISerialNumberState;
  paymentDetails: IPaymentsState;
}

const commonReducer = combineReducers({
  notepad,
  events,
  diary,
  filters,
  priceCalculator,
  serialNumber,
  paymentDetails
});

export default commonReducer;
