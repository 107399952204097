import SummaryTable from 'components/common/SummaryTable';
import FormView from 'components/FormView';
import React from 'react';
import SummaryTableSchema from './CatalogueProductDetails.summaryTable.schema';
import ComponentDetailFormSchema from './CatalogueProductDetails.form';
import ICatalogueProductDetailsProperties from './CatalogueProductDetails.properties';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Operation } from 'utils/operations';
import { ArrowBack, AddCircle as AddSalesLine } from '@markinson/uicomponents-v2/SvgIcons/';
import { useRetrieveCatalogueProductDetails } from 'api/Worksale/catalogue';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const CatalogueProductDetails = (props: ICatalogueProductDetailsProperties) => {
  const { setActionBar } = React.useContext(ActionBarContext);

  const { catalogueProductId, forwardedRef, worksaleId, changeSelectedTab, loadImage, getMainImage, getListOfImages, resetForm } = props;

  const productDetails = useRetrieveCatalogueProductDetails(worksaleId, catalogueProductId);
  const productDetailsInlineValues = productDetails.data?.CatalogueProductDetail?.inlineObject;
  const summaryTableData = productDetailsInlineValues?.CatalogueProductSummary;

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      onAddSalesLine: handleAddSalesLine,
      onCancel(): void {
        navigateBack();

        return;
      }
    })
  );

  React.useEffect(
    () => {
      setActionBar({
        leftIcons: [{
          label: 'Back',
          Icon: ArrowBack,
          action: 'Back',
        }],
        centerIcons: [],
        rightIcons: [
          {
            label: 'Add Line',
            Icon: AddSalesLine,
            action: 'AddToSalesLines'
          }
        ]
      });
    },
    []
  );

  React.useEffect(
    () => {
      if (!catalogueProductId) {
        navigateBack();
      }
    },
    [catalogueProductId]
  );

  async function handleAddSalesLine(): Promise<void> {
    try {
      showSnackBar({ variant: 'success', message: 'Sales Line Added' });
      navigateBack();
    } catch (err) {
      console.warn(err);
    }
  }

  function navigateBack(): void {
    if (changeSelectedTab) {
      resetForm();
      changeSelectedTab('ProductCatalogue');
    }
  }

  return (
    <>{
      <FormView
        isLoading={productDetails.isLoading}
        formName={'CatalogueProductDetailsForm'}
        schema={ComponentDetailFormSchema}
        includeTabsHeight={false}
        initialValues={productDetailsInlineValues}
        valuesSchema={productDetails.data?.CatalogueProductDetail?.schema}
        operationMode={Operation.BROWSE}
        summaryTableRenderer={() => <SummaryTable loadingProductSummary={false}
          ProductSummary={summaryTableData && summaryTableData}
          data={
            SummaryTableSchema
          }
        />}
        fieldExtensions={{
          Image: {
            dialogTitle: 'Product Image',
            getMainImage,
            loadImage,
            mainImageObject: productDetailsInlineValues?.MainImage ? productDetailsInlineValues.MainImage[0] : null,
            imageCount: productDetailsInlineValues?.ImageCount ?? 0,
            getListOfImages: () => getListOfImages(catalogueProductId),
          }
        }}

      />
    }
    </>
  );
};

export default React.memo(React.forwardRef(CatalogueProductDetails));
