import {
  createActions, asyncInitialState,
  asyncSelectors,
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'purchaseOrders');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Purchase order', field: 'PurchaseOrder', minWidth: 150, },
    { headerName: 'Line', field: 'LineNo', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Supplier', field: 'SupplierIdDisplay', minWidth: 120, suppressSorting: true, },
    { headerName: 'Due', field: 'DueDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'On order', field: 'OnOrderBUQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Committed', field: 'CommittedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplied', field: 'SuppliedBUQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Buying unit', field: 'BuyingUnitDescription', minWidth: 150, suppressSorting: true, },
    { headerName: 'Supplier name', field: 'SupplierName', minWidth: 250, suppressSorting: true, },
    { headerName: 'Supplier product', field: 'SupplierProduct', minWidth: 150, suppressSorting: true, },
    { headerName: 'Status', field: 'PurchaseOrderStatusDescription', minWidth: 250, suppressSorting: true, },
    { headerName: 'Created', field: 'CreatedDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Ship', field: 'ShipDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 100, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', minWidth: 250, suppressSorting: true, }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.purchaseOrders,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.purchaseOrders.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.purchaseOrders.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.purchaseOrders.data.Columns
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)