import { takeLatest, Effect, call, put } from 'redux-saga/effects';
import { types as rosterEntryTypes, actions as rosterEntryActions } from 'ducks/rosterMaintenance/rosterEntries';
import { connectSagaToApi, callApi } from '../utils';
import * as api from 'api/rosterMaintenance/rosterEntries';
import { IDataAction } from 'ducks/utils';
import { OPERATIONS } from 'utils/operations';
import { actions as uiOperationActions } from 'ducks/uiOperations';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* postSuccessCallback(response: any, message: string): IterableIterator<Effect> {
  if (!response.Forms) {
    const { RosterTemplateId, UserId } = response.RosterTemplateDetails && response.RosterTemplateDetails.inlineObject;

    yield put(rosterEntryActions.search({ TechnicianId: UserId }));
    yield put(rosterEntryActions.setSelected(RosterTemplateId));
    showSnackBar({ variant: 'success', message });
  } else {
    yield put(uiOperationActions.changeOperationMode(OPERATIONS.EDIT));
  }
}

function* createEntry(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = rosterEntryActions.saga.create;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    yield call(postSuccessCallback, response, 'Roster entry created successfully.');
  }

  yield callApi(
    call(api.create, action.data),
    success,
    failure,
    { dialogActionCallEffect: createEntry, postApiSuccessCallEffect }
  );
}

function* updateEntry(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = rosterEntryActions.saga.create;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    yield call(postSuccessCallback, response, 'Roster entry updated successfully.');
  }

  yield callApi(
    call(api.update, action.data),
    success,
    failure,
    { dialogActionCallEffect: updateEntry, postApiSuccessCallEffect }
  );
}

function* deleteEntry(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = rosterEntryActions.saga.delete;

  function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
    if (!response.Forms) {
      showSnackBar({ variant: 'success', message: 'Roster entry deleted successfully.' });
    }
  }

  yield callApi(
    call(api.removeRosterEntry, action.data),
    success,
    failure,
    { dialogActionCallEffect: deleteEntry, postApiSuccessCallEffect }
  );
}

export default function* rootRosterMaintenanceCapabilitySaga(): IterableIterator<Effect> {
  yield takeLatest(rosterEntryTypes.search, connectSagaToApi(rosterEntryActions, 'search', api.search));
  yield takeLatest(rosterEntryTypes.searchById, connectSagaToApi(rosterEntryActions, 'searchById', api.searchById));
  yield takeLatest(rosterEntryTypes.fetchRosterData, connectSagaToApi(rosterEntryActions, 'fetchRosterData', api.searchById));
  yield takeLatest(rosterEntryTypes.create, createEntry);
  yield takeLatest(rosterEntryTypes.update, updateEntry);
  yield takeLatest(rosterEntryTypes.delete, deleteEntry);
}
