import { fetchGet, fetchPost, objectify } from '../utils';

export const fetchBinLocations = (query) => {
    const { DespatchId, LineNumber } = query;

    return fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/GetPickSlipBins`)
        .then((result) => {
            if (result) {
                if (result.PickSlipBins) {
                    return {
                        records: result.PickSlipBins.map((val) =>
                            ({ ...objectify(val) })),
                        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
                        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
                    };
                } else {
                    return result;
                }
            }
        });
};

export const fetchBinLocationsSummary = (query) => {
    const { DespatchId, LineNumber } = query;

    return fetchGet(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/GetPickSlipBinsSummary`)
        .then((result) => {
            return {
                values: objectify(result.PickSlipBinLocationSummary),
                schema: result.PickSlipBinLocationSummary
            };
        });
};

export const saveBinlocation = (query) => {
    const { DespatchId, LineNumber, data } = query;

    return fetchPost(`/PickSlipTransaction/Despatch/${DespatchId}/Line/${LineNumber}/UpdatePickSlipBin`, data)
        .then((result) => {
            if (result) {
                if (result.PickSlipBin) {
                    return result.PickSlipBin;
                } else {
                    return result;
                }
            }
        });
};
