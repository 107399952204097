import { IState } from './Worksale.reducer';

export interface IActions {
  [SalesProcessingActionsTypes.setCustomerId]?: IState['CustomerId'];
  [SalesProcessingActionsTypes.setUnAppliedCustomerId]?: IState['UnAppliedCustomerId'];
  [SalesProcessingActionsTypes.setWorksaleId]?: IState['WorksaleId'];
  [SalesProcessingActionsTypes.setWorksaleDeliveryId]?: IState['WorksaleDeliveryId'];
  [SalesProcessingActionsTypes.setSalesEntity]?: IState['salesEntity'];
  [SalesProcessingActionsTypes.setWorksaleLineId]?: IState['WorksaleLineId'];
  [SalesProcessingActionsTypes.setPriceOverrideScreenType]?: IState['priceOverrideScreenType'];
  [SalesProcessingActionsTypes.setPriceOverrideDialogOpen]?: IState['priceOverrideDialogOpen'];
  [SalesProcessingActionsTypes.setPriceOverrideAuthRequestedScreen]?: IState['priceOverrideAuthRequestedScreen'];
  [SalesProcessingActionsTypes.setPriceOverrideDetailSummary]?: IState['priceOverrideDetailSummary'];
  [SalesProcessingActionsTypes.setMultipleBomState]?: IState['multipleBomOpen'];
  [SalesProcessingActionsTypes.setKitStatus]?: IState['kitStatus'];
  [SalesProcessingActionsTypes.setSelectedBomCode]?: IState['selectedBomCode'];
  [SalesProcessingActionsTypes.setBomList]?: IState['bomList'];
  [SalesProcessingActionsTypes.setOrderRecalled]?: IState['orderRecalled'];
  [SalesProcessingActionsTypes.setLostSaleDialog]?: IState['lostSaleDialog'];
  [SalesProcessingActionsTypes.setAutoSupplyQuantity]?: IState['autoSupplyQuantity'];
  [SalesProcessingActionsTypes.setDisableAutoSupplyQuantity]?: IState['disableAutoSupplyQuantity'];
  [SalesProcessingActionsTypes.setLineEdit]?: IState['isLineEdit'];
  [SalesProcessingActionsTypes.setWorksaleMessagesOpen]?: IState['worksaleMessagesOpen'];
  [SalesProcessingActionsTypes.setWorksaleMessages]?: IState['worksaleMessages'];
  [SalesProcessingActionsTypes.setWorksaleDeliverySummary]?: IState['worksaleDeliverySummary'];
}

export enum SalesProcessingActionsTypes {
  setWorksaleId = 'setWorksaleId',
  setWorksaleDeliveryId = 'setWorksaleDeliveryId',
  setWorksaleLineId = 'setWorksaleLineId',
  setCustomerId = 'setCustomerId',
  setSalesEntity= 'setSalesEntity',
  setUnAppliedCustomerId = 'setUnAppliedCustomerId',
  setPriceOverrideScreenType = 'setPriceOverrideScreenType',
  setPriceOverrideDialogOpen = 'setPriceOverrideDialogOpen',
  setPriceOverrideAuthRequestedScreen = 'setPriceOverrideAuthRequestedScreen',
  setPriceOverrideDetailSummary = 'setPriceOverrideDetailSummary',
  setMultipleBomState = 'setMultipleBomState',
  setBomList = 'setBomList',
  setKitStatus = 'setKitStatus',
  setSelectedBomCode = 'setSelectedBomCode',
  setOrderRecalled = 'setOrderRecalled',
  setLostSaleDialog = 'setLostSaleDialog',
  setAutoSupplyQuantity = 'setAutoSupplyQuantity',
  setDisableAutoSupplyQuantity = 'setDisableAutoSupplyQuantity',
  setLineEdit = 'setLineEdit',
  setWorksaleMessagesOpen = 'setWorksaleMessagesOpen',
  setWorksaleMessages = 'setWorksaleMessages',
  setWorksaleDeliverySummary = 'setWorksaleDeliverySummary',
}
