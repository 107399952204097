import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors, touch } from 'redux-form';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as activitySchedulerActions, selectors as activitySchedulerSelectors } from 'ducks/serviceActivityScheduling/serviceActivities';
import GeneralAcivityModal from './GeneralAcivityModal';
import { bindActionCreators, Dispatch } from 'redux';

const GENERAL_ACTIVITY_FORM = 'GeneralActivity';

const mapStateToProps = (state: IApplicationState) => ({
    operationMode: operationSelectors.operationMode(state),
    formValues: getFormValues(GENERAL_ACTIVITY_FORM)(state),
    SalesEntity: activitySchedulerSelectors.salesEntity(state),
    formSyncErrors: getFormSyncErrors(GENERAL_ACTIVITY_FORM)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        schedule: activitySchedulerActions.scheduleGeneralActivity,
        touchFormFields: (...args: any) => touch(GENERAL_ACTIVITY_FORM, ...args)
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAcivityModal);
