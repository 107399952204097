import * as React from 'react';
import LookupField from '@markinson/uicomponents-v2/LookupField';
import { ProductLotLookupProps } from './ProductBinLookup.properties';
import { Column, Paging } from 'devextreme-react/data-grid';
import ITextFieldHandle from '@markinson/uicomponents-v2/TextField/TextField.handle';
import { useProductBinLookupApis } from 'api/product/productSearch';
import { COLUMN_SIZE, DEFAULT_PAGE_SIZE } from '../DataGridDevEx/DataGrid.constants';

const searchScreenDialogStyle = {
    maxWidth: 600,
    width: 600,
    height: 495,
    maxHeight: 495
};

function ProductBinLookup(props: Readonly<ProductLotLookupProps>, ref: React.Ref<ITextFieldHandle>): JSX.Element {
    const { WarehouseEntity, ProductId, LineNumber, ReservationNumber, DocumentId, DocumentType, PartialSerial, ...rest } = props;

    const { fetchProductBins } = useProductBinLookupApis();

    const query = {
        WarehouseEntity,
        ProductId,
        LineNumber,
        ReservationNumber,
        DocumentId,
        DocumentType,
        PartialSerial
    };

    const AvailableQuantityHeaderCellRender = React.useCallback(
        () => WarehouseEntity ? <span>Available<br />{WarehouseEntity}</span> : <span>Available</span>,
        [WarehouseEntity]
    );

    const onLookup = React.useCallback(
        (searchText, exactMatch) => fetchProductBins('Lookup', { SearchText: searchText || '', ...query, CheckExactMatch: exactMatch }),
        [query]
    );
    const onSearch = React.useCallback(
        (searchText) => fetchProductBins('Search', { SearchText: searchText || '', ...query }),
        [query]
    );

    return (
        <LookupField
            innerRef={ref}
            searchScreenDialogStyle={searchScreenDialogStyle}
            valueField={'Bin'}
            displayField={'Bin'}
            label={'Bin'}
            descriptionField={'Description'}
            placeholder={'Bin'}
            onLookup={onLookup}
            onSearch={onSearch}
            {...rest}
        >
            <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
            <Column dataField='Bin' caption='Bin' minWidth={COLUMN_SIZE.md0} width={COLUMN_SIZE.md0} fixed={true} />
            <Column dataField='BinType' caption='BinType' minWidth={COLUMN_SIZE.md0} width={COLUMN_SIZE.md0} />
            <Column dataField='AvailableQuantity' headerCellRender={AvailableQuantityHeaderCellRender} minWidth={COLUMN_SIZE.md0} width={COLUMN_SIZE.md0} />
            <Column dataField='Entered' caption='Entered' minWidth={COLUMN_SIZE.md0} width={COLUMN_SIZE.md0} />
            <Column dataField='Price' caption='Price' minWidth={COLUMN_SIZE.md0} width={COLUMN_SIZE.md0} />
            <Column dataField='Expiry' caption='Expiry' minWidth={COLUMN_SIZE.lg1} width={COLUMN_SIZE.lg1} />
            <Column dataField='Description' caption='Description' minWidth={COLUMN_SIZE.lg1} width={COLUMN_SIZE.lg1} />
        </LookupField >);
}

export default React.forwardRef(ProductBinLookup);
