import * as React from 'react';
import FormView from '../../FormView/index';
import { withRouter } from 'react-router';
import { Operation } from 'utils/operations';
import UserNotifications from '../../common/UserNotifications';
import { IRosterLookupProps } from '../interfaces';
import moment from 'moment';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

class RosterLookup extends React.PureComponent<IRosterLookupProps> {

  operationMap: Map<Operation, (prevProps?: Readonly<IRosterLookupProps>) => void>;

  constructor(props: Readonly<IRosterLookupProps>) {
    super(props);
    this.operationMap = new Map<Operation, () => void>();
    this.operationMap[Operation.CANCEL] = this.cancelFormSubmission;
    this.operationMap[Operation.BACK] = this.cancelFormSubmission;
    this.operationMap[Operation.SAVE] = this.submitFormValues;
    this.operationMap[Operation.DELETE] = this.removeRosterEntry;
  }

  initialData = () => ({
    initialValues:
    {
      StartTime: '08:00 AM',
      EndTime: '04:00 PM',
      UserId: this.props?.selectedTechnician?.UserId,
      EntCode: this.props?.selectedTechnician?.EntCode,
    },
    valuesSchema:
      {}
  });

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      onInitialLoad('RosterLookup');
    }
  }

  componentDidUpdate(prevProps: Readonly<IRosterLookupProps>): void {
    const { operationMode } = this.props;

    if (operationMode !== prevProps.operationMode) {
      if (this.operationMap[operationMode]) {
        this.operationMap[operationMode](prevProps);
      }
    }
  }

  cancelFormSubmission = (prevProps: Readonly<IRosterLookupProps>) => {
    const {
      dirty, changeConfirmationDialog, changeOperationMode, onInitialLoad, operationMode, resetForm
    } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          resetForm('RosterLookup');
          changeOperationMode(Operation.BROWSE);
        }
      });
    } else {
      if (operationMode === Operation.CANCEL) {
        changeOperationMode(Operation.BROWSE);
      } else {
        if (prevProps.operationMode === Operation.EDIT) {
          changeOperationMode(Operation.BROWSE);
        } else {
          onInitialLoad('RosterLookup');
          changeOperationMode(Operation.BROWSE);
        }
      }
    }
  }

  submitFormValues = (prevProps: Readonly<IRosterLookupProps>): void => {
    const {
      isValid, formSyncErrors, changeOperationMode, values
    } = this.props;

    if (!isValid || (formSyncErrors && Object.keys(formSyncErrors).length > 0)) {
      const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
      if (requiredError) {
        showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
      } else {
        const firstError = Object.keys(formSyncErrors)[0];
        showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
      }

      changeOperationMode(prevProps.operationMode);

      return;
    }

    if (values.StartTime && values.EndTime) {
      const TIME_FORMAT = 'hh:mm A';
      const momentStart = moment(values.StartTime, TIME_FORMAT);
      const momentEnd = moment(values.EndTime, TIME_FORMAT);

      const difference = momentEnd.diff(momentStart, 'minutes');
      if (difference < 0) {
        showSnackBar({ variant: 'error', message: 'End time must be later than the start time!' });
        changeOperationMode(prevProps.operationMode);

        return;
      }
    }
    if (prevProps.operationMode === Operation.NEW) {
      this.createRosterEntry();
    } else {
      this.updateRosterEntry();
    }

    changeOperationMode(Operation.BROWSE);
  }

  createRosterEntry = () => {
    const { createRosterEntry, selectedTechnician, values } = this.props;

    const UserId = selectedTechnician.UserId;
    createRosterEntry({ UserId, values });
  }

  updateRosterEntry = (): void => {
    const { updateRosterEntry, values = {}, changeOperationMode } = this.props;
    updateRosterEntry(values);
    changeOperationMode(Operation.BROWSE);
  }

  removeRosterEntry = (): void => {
    const { removeRosterEntry, values = {}, changeOperationMode, changeConfirmationDialog } = this.props;

    if (values.RosterTemplateId) {
      changeConfirmationDialog({
        open: true,
        title: 'Delete Roster Entry',
        message: 'Are you sure you want to delete?',
        okLabel: 'Delete',
        onCancel: () => {
          changeOperationMode(Operation.BROWSE);
        },
        onOk: () => {
          removeRosterEntry({ UserId: values.UserId, RosterTemplateId: values.RosterTemplateId });
          changeOperationMode(Operation.BROWSE);
        }
      });
    }
  }
  getInitialValues = (): any => {
    const { rosterEntryData, operationMode } = this.props;
    if (operationMode === Operation.NEW) {
      return this.initialData();
    }
    const initialValues = rosterEntryData && rosterEntryData.inlineObject || {};
    const valuesSchema = rosterEntryData && rosterEntryData.schema || {};

    return { initialValues, valuesSchema };
  }

  fetchRosterData = () => {
    const { selectedTechnician, selectedEntry, fetchRosterData } = this.props;
    const UserId = selectedTechnician.UserId;
    const RosterTemplateId = selectedEntry && selectedEntry.RosterTemplateId;

    fetchRosterData({ TechnicianId: UserId, RosterTemplateId });

  }

  render(): React.ReactNode {
    const {
      isFormLoading, selectedForm, operationMode, dirty, isValid, formSyncErrors,
      rosterNotifications, rosterError, selectedTechnician } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || this.initialData();
    const UserId = selectedTechnician && selectedTechnician.UserId || '';

    return (selectedForm &&
      <React.Fragment>
        {((dirty
          && operationMode === Operation.SAVE) || (operationMode === Operation.BROWSE))
          && <UserNotifications
            notifications={rosterNotifications}
            errors={rosterError}
            formSyncErrors={!isValid && formSyncErrors}
            entity={'RosterLookup'}
            isValid={isValid}
          />}

        {(selectedForm.id === 'RosterLookup') &&
          (<FormView
            formName={'RosterLookup'}
            schema={selectedForm}
            browseLookUpOpen={true}
            browseLookUpComponent={{
              variant: 'RosterEntries',
              searchParams: {
                TechnicianId: UserId
              },
              searchPlaceholder: 'Weekday',
              isBrowseLookUp: true,
              recordChangeCallBacks: [this.fetchRosterData]
            }}
            initialValues={initialValues}
            operationMode={operationMode}
            valuesSchema={valuesSchema}
            isLoading={isFormLoading}
          />)}
      </React.Fragment>
    );
  }
}

export default withRouter(RosterLookup);
