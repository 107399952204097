import OrderDetails from 'utils/data/forms/service_orders/OrderDetails';

const getFormSchema = (formId: string) => {
  switch (formId) {
    case 'OrderDetails':
      return OrderDetails;
    default:
      return OrderDetails;
  }
};

export default getFormSchema;
