import { IFormViewForm } from 'components/FormView';

const OrderDetails: IFormViewForm = {
  id: 'OrderDetails',
  layout: {
    rows: 3,
    columns: 3
  },
  fields: [
    {
      id: '1',
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Order category',
            size: 'small',
            name: 'OrderCategory',
            isLookupTypeFixed: true,
            lookupName: 'WarehouseEntity',
            required: true
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Category',
            size: 'small',
            name: 'Category',
            lookupName: 'WarehouseEntity',
            required: true
          }
        },
        {
          id: '2',
          type: 'AUTOCOMPLETE_FIELD',
          visible: true,
          props: {
            label: 'Service status',
            name: 'ServiceStatus',
            size: 'medium',
            required: true,
            apiPrefs: {
              path: '/CustomTypes/Lookup/DeliveryType',
              method: 'GET',
              params: null
            },
          }
        },
        {
          id: '3',
          type: 'SUBHEADER',
          props: {
            value: 'Postal address',
          }
        },
        {
          id: '4',
          type: 'ADDRESS_BLOCK_EX',
          props: {
            label: 'Postal Address',
            name: 'PostalAddress',
            type: 'extended',
          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'SELECT_FIELD',
          visible: true,
          props: {
            label: 'Delivery type',
            name: 'DeliveryType',
            placeholder: '',
            size: 'small',
            apiPrefs: {
              path: '/CustomTypes/Lookup/DeliveryType',
              method: 'GET',
              params: null
            },
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Site',
            name: 'DeliverySiteCode',
            lookupName: 'DeliverySite',
            placeholder: '',
            size: 'small',

            isCustomerScoped: true
          }
        },
        {
          id: '2',
          type: 'SUBHEADER',
          visible: true,
          props: {
            value: 'Delivery address',
          }
        },
        {
          id: '3',
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            label: 'Delivery address',
            name: 'DeliveryAddress',
            type: 'extended'
          }
        },
        {
          id: '4',
          type: 'TOGGLE_FIELD',
          props: {
            label: 'Save address',
            name: 'SaveAddress',
          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Service item',
            name: 'ServiceItem',
            lookupName: 'ServiceItem',
            placeholder: '',
            size: 'medium',
            required: false
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Kilometers/hours',
            name: 'KmsHours',
            size: 'small'
          }
        },
        {
          id: '2',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Quote',
            name: 'Quote',
            lookupName: 'Quote',
            placeholder: '',
            size: 'medium',
            required: false
          }
        },
        {
          id: '3',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Customer purchase order',
            name: 'CustomerPO',
            lookupName: 'CustomerPO',
            placeholder: '',
            size: 'medium',
            required: false
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Description',
            name: 'CustomerPODescription',
            placeholder: '',
            size: 'large',

          }
        },
        {
          id: '5',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Project',
            name: 'Project',
            lookupName: 'Project',
            placeholder: '',
            size: 'medium',
            required: false
          }
        },
        {
          id: '6',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Milestone',
            name: 'Milestone',
            lookupName: 'Milestone',
            placeholder: '',
            size: 'medium',
            required: false
          }
        },
        {
          id: '6',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Service call',
            name: 'ServiceCall',
            lookupName: 'ServiceCall',
            placeholder: '',
            size: 'medium',
            required: false
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Sales rep',
            name: 'SalesRep',
            lookupName: 'Personnel',
            placeholder: '',
            size: 'small',

          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Responsible person',
            lookupName: 'Personnel',
            name: 'Responsible',
            placeholder: '',
            size: 'small',

          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Technician',
            name: 'Technician',
            lookupName: 'Personnel',
            placeholder: '',
            size: 'small',

          }
        },
        {
          id: 3,
          type: 'DATE_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Schedule',
            name: 'Schedule',
            format: '',
            size: 'small',
          }
        },
        {
          id: 4,
          type: 'TIME_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Schedule time',
            name: 'ScheduleTime',
            size: 'small',
          }
        },
        {
          id: 5,
          type: 'DATE_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Started',
            name: 'Started',
            size: 'small'
          }
        },
        {
          id: 6,
          type: 'TIME_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Started time',
            name: 'StartedTime',
            format: '',
            size: 'small',
          }
        },
        {
          id: 7,
          type: 'DATE_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Required',
            name: 'Required',
            format: '',
            size: 'small',
          }
        },
        {
          id: 8,
          type: 'TIME_FIELD',
          visible: true,
          style: {
            width: '50%',
            display: 'inline-block'
          },
          props: {
            label: 'Required time',
            name: 'RequiredTime',
            format: '',
            size: 'small',

          }
        }
      ]
    },
    {
      id: '1',
      position: {
        row: 2,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Freight code',
            name: 'FreightCode',
            lookupName: 'FreightCode',
            placeholder: '',
            size: 'small',
            required: false
          }
        },
        {
          id: '1',
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Send via',
            name: 'SendVia',
            lookupName: 'FreightType',
            suppressValidation: true,
            suppressDescription: true,
            size: 'small'
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Carrier',
            name: 'Carrier',
            placeholder: '',
            size: 'large',
            required: false
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Freight account',
            name: 'FreightAccount',
            placeholder: '',
            size: 'medium',

          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Shipping',
            name: 'Shipping',
            placeholder: '',
            size: 'large',

          }
        }
      ]
    },
    {
      id: 1,
      position:
      {
        row: 2,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Terms',
            name: 'Terms',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Territory',
            name: 'Territory',
            size: 'small',
            lookupName: 'SalesTerritory',
          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          visible: true,
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            size: 'small',
            isLookupTypeFixed: true,
            lookupName: 'TaxClass',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax exempt reference',
            name: 'TaxExemptReference',
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Invoice instructions',
            name: 'InvoiceInstructions',
            size: 'large',
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            name: 'InvoiceInstructions1',
            size: 'large',
          }
        }
      ]
    }
  ]
};
export default OrderDetails;
