import { actions as addressActions, selectors as addressSelectors } from 'ducks/address';
import { actions as customerActions, selectors as customerSelectors } from 'ducks/customer';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as contactActions, selectors as contactSelectors } from 'ducks/contact';

import { selectors as activityCapabilitySelectors } from 'ducks/activityMaintenance/activity';
import { actions as capabilityActions, selectors as capabilitySelectors } from 'ducks/activityMaintenance/capability';

import { selectors as jobTemplateSelectors } from 'ducks/jobTemplateMaintenance/jobTemplate';
import { actions as activityActions, selectors as activitySelectors } from 'ducks/jobTemplateMaintenance/activity';

import { selectors as supplierSelectors } from 'ducks/supplierEnquiry/supplier';
import { actions as supplierContactActions, selectors as supplierContactSelectors } from 'ducks/supplierEnquiry/supplierContacts';
import { actions as supplierdeliveryDetailsActions, selectors as supplierdeliveryDetailsSelectors } from 'ducks/supplierEnquiry/deliveryDetails';
import { actions as customerProductActions, selectors as customerProductSelectors } from 'ducks/customerProduct';
import { actions as attributeActions, selectors as attributeSelectors } from 'ducks/customerEnquiry/marketing';
import { actions as invoiceActions, selectors as invoiceSelectors } from 'ducks/salesInvoiceEnquiry/Invoice';
import { actions as orderActions, selectors as orderSelectors } from 'ducks/salesOrderEnquiry/salesOrder';
import { IApplicationState } from 'ducks/reducers';
import { actions as rosterEntriesActions, selectors as rosterEntriesSelectors } from 'ducks/rosterMaintenance/rosterEntries';
import { ISearchLookUpDrawerProperties } from './SearchLookUpDrawer';
import { ActionCreator, ActionCreatorsMapObject } from 'redux';
import { IDataAction } from 'ducks/utils';

declare type SearchLookUpDrawerActionMap = {
  [index in keyof Partial<ISearchLookUpDrawerProperties>]: ActionCreator<IDataAction>;
};

declare type SearchLookUpDrawerSelectorsMap = (state: IApplicationState) => Partial<ISearchLookUpDrawerProperties>;

const variants: { [variant: string]: { actions: SearchLookUpDrawerActionMap; selectors: SearchLookUpDrawerSelectorsMap } } = {
  DeliveryDetails: {
    actions:
    {
      search: addressActions.search,
      searchById: addressActions.searchById,
      changeSelectedRecord: addressActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      open: uiSelectors.isBrowseLookUpOpen(state),
      CustomerId: (customerSelectors.selected(state) && customerSelectors.selected(state).CustomerId) || null,
      records: addressSelectors.all(state) || [],
      selectedRecord: addressSelectors.selected(state),
      selectedSortFilter: 'Name',
      selectedSortDirection: 'Ascending',
      isLoading: addressSelectors.isLoading(state),
      keyField: 'SiteCode',
      fieldsToDisplay: ['Name', '', 'SiteCode', 'Phone'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (customerSelectors.selected(state) && selectedRecord) {
          matchParams.set('CustomerId', customerSelectors.selected(state).CustomerId);
          matchParams.set('SiteCode', selectedRecord.SiteCode);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const CustomerId = matchParams.get('CustomerId');
        const SiteCode = matchParams.get('SiteCode');
        if (SiteCode && CustomerId) {
          return {
            id: { CustomerId, SiteCode },
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  Customers: {
    actions:
    {
      search: customerActions.search,
      changeSelectedRecord: customerActions.setSelected,
      onToggle: uiActions.toggleCustomerLookUp,
      fetchNextPage: customerActions.fetchNextPage,
      fetchPrevPage: customerActions.fetchPrevPage
    },
    selectors: (state: IApplicationState) => ({
      records: customerSelectors.all(state) || [],
      selectedRecord: customerSelectors.selected(state),
      selectedSortFilter: 'CustomerAccountNumber',
      selectedSortDirection: 'Ascending',
      open: uiSelectors.isCustomerLookUpOpen(state),
      isLoading: customerSelectors.isLoading(state),
      nextPage: customerSelectors.nextPage(state),
      prevPage: customerSelectors.prevPage(state),
      pageSize: customerSelectors.pageSize(state),
      totalPages: customerSelectors.totalPages(state),
      loadingPrevPage: customerSelectors.loadingPrevPage(state),
      loadingNextPage: customerSelectors.loadingNextPage(state),
      keyField: 'CustomerId',
      fieldsToDisplay: ['CustomerAccountNumber', 'Name', 'Abbreviation', 'City'],
      searchInitially: true,
    })
  },
  Contacts: {
    actions:
    {
      search: contactActions.search,
      searchById: contactActions.searchById,
      changeSelectedRecord: contactActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      CustomerId: (customerSelectors.selected(state) && customerSelectors.selected(state).CustomerId) || null,
      records: contactSelectors.all(state) || [],
      selectedRecord: contactSelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'Contact',
      selectedSortDirection: 'Ascending',
      isLoading: contactSelectors.isLoading(state),
      keyField: 'ContactNumber',
      fieldsToDisplay: ['Contact', '', 'ContactTypeLabel', 'Phone'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (customerSelectors.selected(state) && selectedRecord) {
          matchParams.set('CustomerId', customerSelectors.selected(state).CustomerId);
          matchParams.set('ContactNumber', selectedRecord.ContactNumber);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const CustomerId = matchParams.get('CustomerId');
        const ContactNumber = matchParams.get('ContactNumber');
        const SearchText = matchParams.get('SearchText');
        if (ContactNumber && CustomerId) {
          return {
            id: { CustomerId, ContactNumber },
            searchText: ContactNumber.toString() || ''
          };
        }
        if (SearchText) {
          return {
            searchText: SearchText
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  Capabilities: {
    actions:
    {
      search: capabilityActions.search,
      searchById: capabilityActions.searchById,
      changeSelectedRecord: capabilityActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      ActivityCode: (activityCapabilitySelectors.selected(state) && activityCapabilitySelectors.selected(state).ActivityCode) || null,
      records: capabilitySelectors.all(state) || [],
      selectedRecord: capabilitySelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'CapabilityCode',
      selectedSortDirection: 'Ascending',
      isLoading: capabilitySelectors.isLoading(state),
      keyField: 'CapabilityCode',
      fieldsToDisplay: ['CapabilityCode', 'Description', null, null],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (capabilitySelectors.selected(state) && selectedRecord) {
          matchParams.set('CapabilityCode', selectedRecord.CapabilityCode);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const CapabilityCode = matchParams.get('CapabilityCode');
        const ActivityCode = matchParams.get('ActivityCode');
        if (CapabilityCode) {
          return {
            id: { CapabilityCode, ActivityCode },
            searchText: CapabilityCode.toString() || ''
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  RosterEntries: {
    actions:
    {
      search: rosterEntriesActions.search,
      searchById: rosterEntriesActions.search,
      changeSelectedRecord: rosterEntriesActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      records: rosterEntriesSelectors.all(state) || [],
      selectedRecord: rosterEntriesSelectors.selected(state),
      open: true,
      selectedSortFilter: 'RosterEntries',
      selectedSortDirection: 'Ascending',
      isLoading: rosterEntriesSelectors.isLookupLoading(state),
      keyField: 'RosterTemplateId',
      fieldsToDisplay: ['WeekdayLabel', null, null, null, 'StartTime', 'EndTime'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (rosterEntriesSelectors.selected(state) && selectedRecord) {
          matchParams.set('RosterTemplateId', selectedRecord.RosterTemplateId);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const RosterTemplateId = matchParams.get('RosterTemplateId');
        const TechnicianId = matchParams.get('TechnicianId');
        if (RosterTemplateId) {
          return {
            id: { TechnicianId, RosterTemplateId },
            searchText: RosterTemplateId.toString() || ''
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  Activities: {
    actions:
    {
      search: activityActions.search,
      searchById: activityActions.searchById,
      changeSelectedRecord: activityActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      TemplateCode: (jobTemplateSelectors.selected(state) && jobTemplateSelectors.selected(state).TemplateCode) || null,
      records: activitySelectors.all(state) || [],
      selectedRecord: activitySelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'ActivityCode',
      selectedSortDirection: 'Ascending',
      isLoading: activitySelectors.isLookupLoading(state),
      keyField: 'ActivityCode',
      fieldsToDisplay: ['ActivityCode', 'Description', null, null],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (activitySelectors.selected(state) && selectedRecord) {
          matchParams.set('ActivityCode', selectedRecord.ActivityCode);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const ActivityCode = matchParams.get('ActivityCode');
        const TemplateCode = matchParams.get('TemplateCode');
        if (ActivityCode) {
          return {
            id: { ActivityCode, TemplateCode },
            searchText: ActivityCode.toString() || ''
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  SupplierContact: {
    actions:
    {
      search: supplierContactActions.searchContact,
      searchById: supplierContactActions.searchContactById,
      changeSelectedRecord: supplierContactActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      Supplier: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || null,
      records: supplierContactSelectors.all(state) || [],
      selectedRecord: supplierContactSelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'ContactName',
      selectedSortDirection: 'Ascending',
      isLoading: supplierContactSelectors.isLoading(state),
      keyField: 'ContactNumber',
      fieldsToDisplay: ['ContactName', 'ContactNumber', 'PrimaryDisplay', 'Phone'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (supplierSelectors.selected(state) && selectedRecord) {
          matchParams.set('Supplier', supplierSelectors.selected(state).Supplier);
          matchParams.set('ContactNumber', (selectedRecord && selectedRecord.ContactNumber) || '');
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const Supplier = matchParams.get('Supplier');
        const ContactNumber = matchParams.get('ContactNumber');
        const SearchText = matchParams.get('SearchText');
        if (ContactNumber && Supplier) {
          return {
            id: { Supplier, ContactNumber },
            searchText: ContactNumber.toString() || ''
          };
        }
        if (SearchText) {
          return {
            searchText: SearchText
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  SupplierDeliveryDetails: {
    actions:
    {
      search: supplierdeliveryDetailsActions.searchDeliveryDetail,
      searchById: supplierdeliveryDetailsActions.searchDeliveryDetailById,
      changeSelectedRecord: supplierdeliveryDetailsActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      Supplier: (supplierSelectors.selected(state) && supplierSelectors.selected(state).Supplier) || null,
      records: supplierdeliveryDetailsSelectors.all(state) || [],
      selectedRecord: supplierdeliveryDetailsSelectors.selectedRecord(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'Name',
      selectedSortDirection: 'Ascending',
      isLoading: supplierdeliveryDetailsSelectors.isLoading(state),
      keyField: 'SiteCode',
      fieldsToDisplay: ['SiteCode', '', 'Name', 'Phone'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (supplierSelectors.selected(state) && selectedRecord) {
          matchParams.set('Supplier', supplierSelectors.selected(state).Supplier);
          matchParams.set('SiteCode', (selectedRecord && selectedRecord.SiteCode) || '');
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const Supplier = matchParams.get('Supplier');
        const SiteCode = matchParams.get('SiteCode');
        if (SiteCode && Supplier) {
          return {
            id: { Supplier, SiteCode },
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  CustomerProducts: {
    actions:
    {
      search: customerProductActions.search,
      searchById: customerProductActions.searchById,
      changeSelectedRecord: customerProductActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      CustomerId: (customerSelectors.selected(state) && customerSelectors.selected(state).CustomerId) || null,
      records: customerProductSelectors.all(state) || [],
      selectedRecord: customerProductSelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'CustomerProduct',
      selectedSortDirection: 'Ascending',
      isLoading: customerProductSelectors.isLoading(state),
      keyField: 'CustomerProduct',
      fieldsToDisplay: ['CustomerProduct', 'CustomerDescription', 'OurProductDisplay', 'ProductGroup'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (customerSelectors.selected(state) && selectedRecord) {
          matchParams.set('CustomerId', customerSelectors.selected(state).CustomerId);
          matchParams.set('CustomerProduct', selectedRecord.CustomerProduct);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const CustomerId = matchParams.get('CustomerId');
        const CustomerProduct = matchParams.get('CustomerProduct');
        const SearchText = matchParams.get('SearchText');
        if (CustomerProduct && CustomerId) {
          return {
            id: { CustomerId, CustomerProduct },
            searchText: CustomerProduct.toString() || ''
          };
        }
        if (SearchText) {
          return {
            searchText: SearchText
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  Marketing: {
    actions:
    {
      search: attributeActions.search,
      searchById: attributeActions.searchById,
      changeSelectedRecord: attributeActions.setSelected
    },
    selectors: (state: IApplicationState) => ({
      CustomerId: (customerSelectors.selected(state) && customerSelectors.selected(state).CustomerId) || null,
      records: attributeSelectors.all(state) || [],
      selectedRecord: attributeSelectors.selected(state),
      open: uiSelectors.isBrowseLookUpOpen(state),
      selectedSortFilter: 'AttributeSet',
      selectedSortDirection: 'Ascending',
      isLoading: attributeSelectors.isLoading(state),
      keyField: 'AttributeCode',
      fieldsToDisplay: ['AttributeSet', '', 'AttributeCode', 'Description'],
      changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
        if (customerSelectors.selected(state) && selectedRecord) {
          matchParams.set('CustomerId', customerSelectors.selected(state).CustomerId);
          matchParams.set('AttributeSet', selectedRecord.AttributeSet);
          matchParams.set('AttributeCode', selectedRecord.AttributeCode);
        }

        return matchParams.toString();
      },
      getSearchFromMatchParams: (matchParams: URLSearchParams) => {
        const CustomerId = matchParams.get('CustomerId');
        const AttributeCode = matchParams.get('AttributeCode');
        const AttributeSet = matchParams.get('AttributeSet');
        const SearchText = matchParams.get('SearchText');
        if (AttributeCode && CustomerId && AttributeSet) {
          return {
            id: { CustomerId, AttributeSet, AttributeCode },
            searchText: AttributeCode.toString() || ''
          };
        }
        if (SearchText) {
          return {
            searchText: SearchText
          };
        }

        return null;
      },
      searchInitially: true,
    })
  },
  Invoice: {
    actions:
    {
      search: invoiceActions.search,
      onToggle: uiActions.toggleCustomerLookUp,
      changeSelectedRecord: invoiceActions.setSelected,
      fetchNextPage: invoiceActions.fetchNextPage,
      fetchPrevPage: invoiceActions.fetchPrevPage
    },
    selectors: (state: IApplicationState) => ({
      records: invoiceSelectors.all(state) || [],
      selectedRecord: invoiceSelectors.selected(state),
      selectedSortFilter: 'Invoice',
      selectedSortDirection: 'Ascending',
      open: uiSelectors.isCustomerLookUpOpen(state),
      nextPage: invoiceSelectors.nextPage(state),
      prevPage: invoiceSelectors.prevPage(state),
      pageSize: invoiceSelectors.pageSize(state),
      totalPages: invoiceSelectors.totalPages(state),
      isLoading: invoiceSelectors.isLoading(state),
      loadingPrevPage: invoiceSelectors.loadingPrevPage(state),
      loadingNextPage: invoiceSelectors.loadingNextPage(state),
      keyField: 'key',
      fieldsToDisplay: ['SalesEntity', 'Invoice', 'CustomerDescription', 'CustomerLabel'],
      searchInitially: true,
    })
  },
  Order: {
    actions:
    {
      search: orderActions.search,
      onToggle: uiActions.toggleCustomerLookUp,
      changeSelectedRecord: orderActions.setSelected,
      fetchNextPage: orderActions.fetchNextPage,
      fetchPrevPage: orderActions.fetchPrevPage
    },
    selectors: (state: IApplicationState) => ({
      records: orderSelectors.all(state) || [],
      selectedRecord: orderSelectors.selected(state),
      selectedSortFilter: 'SalesOrder',
      selectedSortDirection: 'Ascending',
      open: uiSelectors.isCustomerLookUpOpen(state),
      isLoading: orderSelectors.isLoading(state),
      nextPage: orderSelectors.nextPage(state),
      prevPage: orderSelectors.prevPage(state),
      pageSize: orderSelectors.pageSize(state),
      totalPages: orderSelectors.totalPages(state),
      loadingPrevPage: orderSelectors.loadingPrevPage(state),
      loadingNextPage: orderSelectors.loadingNextPage(state),
      keyField: 'SalesOrder',
      fieldsToDisplay: ['SalesOrder', 'Description', 'CustomerIdDescription', 'CustomerIdLabel'],
      searchInitially: true,
    })
  },
};

export const Actions = (variant: string): ActionCreatorsMapObject => {
  const result = variants[variant];
  if (result) {
    return result.actions;
  } else {
    return {};
  }
};

export const Selectors = (variant: string, state: IApplicationState): Partial<ISearchLookUpDrawerProperties> => {
  const result = variants[variant];
  if (result) {
    return result.selectors(state);
  } else {
    return {};
  }
};
