import {
  createActions, asyncInitialState,
  asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'serviceWIP');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    toolPanel: false,
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Service order', field: 'ServiceOrder', minWidth: 150, },
    { headerName: 'Line', field: 'Line', type: 'numericColumn', minWidth: 100, suppressSorting: true, },
    { headerName: 'Sales entity', field: 'SalesEntity', minWidth: 120, suppressSorting: true, },
    { headerName: 'Started', field: 'Started', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Required date', field: 'RequiredDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Required quantity', field: 'RequiredQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Issued quantity', field: 'IssuedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Description', field: 'Description', minWidth: 250, },
    {
      headerName: 'Customer',
      field: 'CustomerDisplay',
      minWidth: 150,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data.Customer && row.data.CustomerDisplay) {
          return {
            CustomerId: row.data.Customer,
            CustomerAccountNumber: row.data.CustomerDisplay,
            FieldValue: row.data.CustomerDisplay
          };
        } else {
          return null;
        }
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer name', field: 'CustomerLabel', minWidth: 250, suppressSorting: true, },
    { headerName: 'Customer P/O', field: 'CustomerPO', minWidth: 200, suppressSorting: true, },
    { headerName: 'Service item', field: 'ServiceItemCode', minWidth: 250, suppressSorting: true, },
    { headerName: 'Product group', field: 'ServiceItemGroup', minWidth: 200, suppressSorting: true, },
    { headerName: 'Service item description', field: 'ServiceItemLabel', minWidth: 350, suppressSorting: true, }
  ],
  Data: [],
  selectedFilters: {
  },
  filterRow: {
    formName: 'IEStockingQuantitiesServiceWIPFilters',
    hidePreFilters: true,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Sales entity',
          name: 'SalesEntity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.serviceWIP,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.serviceWIP.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.serviceWIP.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.serviceWIP.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.serviceWIP.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.serviceWIP.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)