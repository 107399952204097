import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, IDataAction, SuccessAction
} from '../utils';
import { pathOr } from 'utils/utils';

export interface ICashReceiptData {
  cashReceipt: any;
  cashReceiptContext: any;
  cashReceiptSummary: any;
  UserId: any;
  docOutputOptions: any;
  isCashReceiptingLookupPanelOpen?: boolean;
  openProcessConfirmation?: boolean;
  openConfirmReceipt?: boolean;
  paymentRequired?: boolean;
}

export interface ICashReceiptState extends IExtendedState<ICashReceiptData> {
  getCashReceipt_loading?: boolean;
  updateCashReceipt_loading?: boolean;
  deleteCashReceipt_loading?: boolean;
  applyCashReceiptContext_loading?: boolean;
  fetchCashReceiptContext_loading?: boolean;
  updateCashReceiptContext_loading?: boolean;
  confirmCashReceiptProcess_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    changeModalVisibility: (data) => (data),
    asyncs: {
      getCashReceipt: () => null,
      cashReceiptProcessComplete: () => null,
      updateCashReceipt: (data) => (data),
      deleteCashReceipt: () => null,
      applyCashReceiptContext: (data) => (data),
      updateCashReceiptContext: (data) => (data),
      updateAllocatedCashReceipt: (data) => (data),
      allocateCashReceipt: (data) => (data),
      fetchCashReceiptContext: () => null,
      processCashReceipt: (data) => (data),
      applyBulkSelectionCriteria: (data) => (data),
      applySpecificInvoiceAllocation: (data) => (data),
      applySpecificOrderAllocation: (data) => (data),
      deleteSpecificInvoiceAllocation: (data) => (data),
      confirmCashReceiptProcess: (data) => (data),
    },
    toggleCashReceiptingLookupPanel: (open: boolean) => open,

  },
  'cashReceipt');

const initialState = asyncInitialState<ICashReceiptData>({
  cashReceipt: null,
  cashReceiptContext: null,
  cashReceiptSummary: null,
  UserId: null,
  isCashReceiptingLookupPanelOpen: true,
  docOutputOptions: null,
  openProcessConfirmation: false,
  paymentRequired: false
});

export default (state: ICashReceiptState = initialState, action: IDataAction): ICashReceiptState => {
  switch (action.type) {

    case types.changeModalVisibility:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      };

    case types.toggleCashReceiptingLookupPanel:
      return {
        ...state,
        data: {
          ...state.data,
          isCashReceiptingLookupPanelOpen: action.data
        }
      };

    case types.getCashReceipt:
    case types.updateCashReceipt:
    case types.deleteCashReceipt:
    case types.applyCashReceiptContext:
    case types.updateCashReceiptContext:
    case types.fetchCashReceiptContext:
    case types.processCashReceipt:
    case types.confirmCashReceiptProcess:
    case types.cashReceiptProcessComplete:
      return asyncOnRequest(state, action);

    case types.saga.getCashReceipt.success:
    case types.saga.updateCashReceipt.success:
    case types.saga.deleteCashReceipt.success:
    case types.saga.applyCashReceiptContext.success:
    case types.saga.updateAllocatedCashReceipt.success:
    case types.saga.allocateCashReceipt.success:
    case types.saga.applyBulkSelectionCriteria.success:
    case types.saga.applySpecificInvoiceAllocation.success:
    case types.saga.applySpecificOrderAllocation.success:
    case types.saga.deleteSpecificInvoiceAllocation.success:
    case types.saga.confirmCashReceiptProcess.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICashReceiptData, successAction: SuccessAction) => {
          return {
            ...data,
            cashReceipt: pathOr({}, ['payload', 'CashReceipt'], successAction),
            cashReceiptSummary: successAction.payload.CashReceiptSummary || successAction.payload.Summary,
            UserId: pathOr(null, ['payload', 'UserId'], successAction),
            paymentRequired: pathOr(false, ['payload', 'PaymentRequired'], successAction),
          };
        });

    case types.saga.processCashReceipt.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICashReceiptData, successAction: SuccessAction) => {
          return {
            ...data,
            docOutputOptions: pathOr({}, ['payload'], successAction),
          };
        });

    case types.saga.fetchCashReceiptContext.success:
    case types.saga.updateCashReceiptContext.success:
      return asyncOnSuccess(
        state,
        action,
        (data: ICashReceiptData, successAction: SuccessAction) => {
          return {
            ...data,
            cashReceiptContext: successAction.payload,
          };
        });

    case types.saga.cashReceiptProcessComplete.failure:
    case types.saga.processCashReceipt.failure:
    case types.saga.getCashReceipt.failure:
    case types.saga.updateCashReceipt.failure:
    case types.saga.deleteCashReceipt.failure:
    case types.saga.fetchCashReceiptContext.failure:
    case types.saga.updateCashReceiptContext.failure:
    case types.saga.applyCashReceiptContext.failure:
    case types.saga.confirmCashReceiptProcess.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt,
  {
    cashReceipt: (data) => data.cashReceipt,
    docOutputOptions: (data) => data.docOutputOptions,
    cashReceiptContext: (data) => pathOr({}, ['cashReceiptContext', 'inlineObject'], data),
    cashReceiptSummary: (data) => pathOr({}, ['cashReceiptSummary', 'inlineObject'], data),
    UserId: (data) => pathOr(null, ['UserId', 'Value'], data),
    entity: (data) => pathOr('', ['cashReceiptContext', 'inlineObject', 'SalesEntity'], data),
    CustomerId: (data) => pathOr('', ['cashReceiptContext', 'inlineObject', 'CustomerId'], data),
    cashReceiptPayload: (data) => ({
      CashReceipt: pathOr({}, ['cashReceiptContext', 'inlineObject'], data),
      CashReceiptSummary: pathOr({}, ['cashReceiptSummary', 'inlineObject'], data),
      UserId: pathOr(null, ['UserId', 'Value'], data)
    }),
    paymentRequired: (data) => data.paymentRequired
  }
);

const syncSelector = {
  isLoading: (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt.fetchCashReceiptContext_loading
    || state.cashReceipting.cashReceipt.applyCashReceiptContext_loading
    || state.cashReceipting.cashReceipt.deleteCashReceipt_loading
    || state.cashReceipting.cashReceipt.getCashReceipt_loading,
  isCashReceiptingLookupPanelOpen: (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt.data.isCashReceiptingLookupPanelOpen,
  openProcessConfirmation: (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt.data.openProcessConfirmation,
  openConfirmReceipt: (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt.data.openConfirmReceipt,
  confirmReceiptLoading: (state: { cashReceipting: { cashReceipt: ICashReceiptState } }) => state.cashReceipting.cashReceipt.confirmCashReceiptProcess_loading,
};

export const selectors = { ...asyncSelector, ...syncSelector };
