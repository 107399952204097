import { connect } from 'react-redux';
import { selectors as staffSelectors } from 'ducks/staff';

import RecallQuoteDialog from './RecallQuoteDialog';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
    staff: staffSelectors.staff(state),
});

export default connect(mapStateToProps, null)(RecallQuoteDialog);
