const ProductDetails = {
  'id': 'ProductDetails',
  'layout': {
    'rows': 2,
    'columns': 3
  },
  'fields': [
    {
      'id': 0,
      'position': {
        'row': 1,
        'col': 1,
        'colspan': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true
      },
      'children': [{
        'type': 'DATA_GRID_DEV_EX',
        'props': {
          'name': 'ProductDetails',
          'module': 'ProductDetails',
          'styles': {
            'height': '300px'
          }
        }
      }]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Pricing',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Retail price',
            'name': 'RetailPriceDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Base price (ex tax)',
            'name': 'BasePriceExTaxDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Base price (incl tax)',
            'name': 'BasePriceIncTaxDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Price per',
            'name': 'PricePerDisplay',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Block Separate W/H Pricing',
            'name': 'BlockSeparateWhPricing',
            'required': false,
            'defaultValue': false
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Buying details',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Buying unit',
            'name': 'BuyingUnit',
            'placeholder': '',
            'size': 'small',
            'required': false,
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Unit conversion factor',
            'name': 'UnitConversionFactorDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Buy multiples of',
            'name': 'BuyMultiplesOf',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Stock multiples of',
            'name': 'StockMultiplesOf',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Import duty code',
            'name': 'ImportDutyCode',
            'placeholder': '',
            'size': 'small',
            'required': false,
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Import tariff code',
            'name': 'ImportTariffCode',
            'placeholder': '',
            'size': 'medium',
            'required': false,
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Reorder warning message',
            'name': 'ReorderWarningMessage',
            'placeholder': '',
            'size': 'large',
            'required': false,
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Selling details',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sell multiples of',
            'name': 'SellMultiplesOf',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_DISPLAY_FIELD',
          'visible': true,
          'props': {
            'label': 'Allowed over supply',
            'name': 'AllowedOverSupply',
            'placeholder': '',
            'size': 'small',
            'required': false,
            'type': 'number',
            'decimalSeparator': true,
            'adornment': '%',
          }
        },
        {
          'id': 2,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Charge customer for over supply',
            'name': 'ChargeForOverSupply',
            'required': false,
            'defaultValue': false
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product terms',
            'name': 'ProductTerms',
            'placeholder': '',
            'helpText': '',
            'size': 'small',
            'required': false,
            'api': '',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sale warning message',
            'name': 'SaleWarningMessage',
            'placeholder': '',
            'size': 'large',
            'required': false,
          }
        },
        {
          'id': 5,
          'type': 'TOGGLE_FIELD',
          'visible': true,
          'props': {
            'label': 'Deposit required',
            'name': 'DepositRequired',
            'required': false,
            'defaultValue': false
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Default exchange deposit',
            'name': 'DefaultExhangeDepositDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 2,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Storage details',
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Storage code',
            'name': 'StorageCode',
            'placeholder': '',
            'size': 'medium',
            'required': false,
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Dangerous code',
            'name': 'DangerousCode',
            'size': 'small',
            'required': false,
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Shelf life (days)',
            'name': 'ShelfLife',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'SUBHEADER',
          'props': {
            'value': ' ',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Weight',
            'name': 'WeightDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Cubic size',
            'name': 'CubicSizeDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Volume',
            'name': 'VolumeDisplay',
            'placeholder': '',
            'size': 'medium',
            'required': false,
            'alignment': 'right',
          }
        }
      ]
    }
  ]
}
export default ProductDetails;