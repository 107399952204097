import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types, actions } from 'ducks/supplierEnquiry/activity/rfc';

import { callApi } from '../../utils';
import * as api from 'api/supplierEnquiry/activity/rfc';


function* getSummary(action){
  const {success, failure} = actions.saga.getRFCSummary;

  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

export default function* rootSaga(){
  yield takeLatest(types.getRFCSummary, getSummary)
}