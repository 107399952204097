import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import LostSalesSchema from './LostSalesDialog.form';

import { ILostSalesDialogProperties, LostSalesType } from './LostSalesDialog.properties';
import LostSalesSummaryTableSchema from './LostSales.summaryTable.schema';
import SummaryTable from 'components/common/SummaryTable';

const LostSalesDialog = (props: ILostSalesDialogProperties): JSX.Element => {

  const { open, loading = false, type, apiType, data, onCancel, onOk, values } = props;

  const formSchema = LostSalesSchema.LostSale;

  const getActions = () => {
    return [
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: async () => {
          if (onOk) {
            onOk(values, type, apiType);
          }
        }
      },
      {
        title: 'Cancel',
        isDefault: true,
        iconName: 'Cancel',
        listener: () => {
          if (onCancel) {
            onCancel();
          }
        },
      },
    ];
  };

  return (
    <div>
      <FormViewModal
        open={open}
        loading={loading}
        title='Lost Sale'
        formProps={{
          formSchema: formSchema,
          initialValues: data,
          style: { height: '440px' },
          summaryTableRenderer: () => type === LostSalesType.CancelSale && LostSalesSummaryTableSchema.CancelSaleSchema ?
            (<SummaryTable loadingCancelSaleSummary={false}
              cancelSaleSummary={data}
              data={
                LostSalesSummaryTableSchema.CancelSaleSchema
              }
            />) : type === LostSalesType.DeleteSaleLine && LostSalesSummaryTableSchema.DeleteSaleLineSchema ?
              (<SummaryTable loadingDeleteSaleLineSummary={false}
                deleteSaleLineSummary={data}
                data={
                  LostSalesSummaryTableSchema.DeleteSaleLineSchema
                }
              />) : type === LostSalesType.ShortSuppliedSaleLine && LostSalesSummaryTableSchema.ShortSuppliedSaleLineSchema ?
                (<SummaryTable loadingShortSuppliedSaleLineSummary={false}
                  shortSuppliedSaleLineSummary={data}
                  data={
                    LostSalesSummaryTableSchema.ShortSuppliedSaleLineSchema
                  }
                />) : null
        }}
        actions={getActions()}
        dialogActionsButtons={true}
        dialogActionsShadow={false}
      />
    </div>
  );
};

export default LostSalesDialog;
