import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as staffSelectors } from 'ducks/staff';
import { IApplicationState } from 'ducks/reducers';
import { actions as mainMenuActions } from 'ducks/mainMenu';
import { actions as programAreaActions, selectors as programAreaSelectors } from 'ducks/programArea';
import { actions as appConfigActions } from 'ducks/appConfig';

import AppContent from './AppContent';

const mapStateToProps = (state: IApplicationState) => (
  {
    programAreas: programAreaSelectors.programAreas(state),
    dashboardWidgets: programAreaSelectors.dashboardWidgets(state),
    programAreasLoaded: programAreaSelectors.isFetched(state),
    programAreasLoading: programAreaSelectors.isLoading(state),
    loggedIn: staffSelectors.loggedIn(state),
  });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    fetchMainMenu: mainMenuActions.fetchMainMenu,
    fetchProgramAreas: programAreaActions.fetchProgramAreas,
    fetchDashboardWidgets: programAreaActions.fetchDashboardWidgets,
    setAppVersion: appConfigActions.setVersion
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
