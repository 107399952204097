import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchGet, objectify } from './../utils';
import { IInvoiceLinesLotDetailsSearchResponse } from './interfaces';

export async function search(query: { DocumentNumber: number; LineNumber: number; Page?: number; Sort?: string; SortDirection?: string; BatchSize?: number }): Promise<IInvoiceLinesLotDetailsSearchResponse> {
  const { DocumentNumber, LineNumber, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchGet(`SupplierInvoiceEnquiry/SupplierInvoice/LineLots/Invoices/${DocumentNumber}/Line/${LineNumber}`, { BatchPage: Page, BatchSize, Sort: Sort && SortDirection && `${Sort}:${SortDirection}` })
    .then((result) => result && result.SupplierInvoiceEnquiryLineLots &&
      ({
        records: result.SupplierInvoiceEnquiryLineLots.map((item) => objectify(item, null, 'Inline')),
        nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
        currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      })
    );
}
