import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { createStyles, withStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@markinson/uicomponents-v2/SvgIcons/Close';

export interface ISnackBarState {
    open?: boolean;
    variant: 'success' | 'warning' | 'error' | 'info' | null;
    persist?: boolean;
    message: string | React.ReactNode;
    time?: number;
    delay?: number;
    detail?: any;
}
const MAX_STACK = 4;

const styles = createStyles(() => ({
    snackBar: {
        top: -60,
        whiteSpace: 'pre-wrap'
    }
}));
const buttonStyles = createStyles(() => ({
    icon: {
        fontSize: '20px',
        color: 'white'
    }
}));

const SnackbarCloseButton = ({ snackbarKey, classes }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseIcon className={classes.icon} />
        </IconButton>
    );
};

const SnackbarCloseButtonStyled = withStyles(buttonStyles, { index: 1 })(SnackbarCloseButton);

const SnackBarProvider = (props: Readonly<React.PropsWithChildren<{ classes?: any }>>) => {
    const { classes } = props;

    return (
        <SnackbarProvider
            maxSnack={MAX_STACK}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            classes={{
                containerAnchorOriginBottomRight: classes.snackBar
            }}
            action={(snackbarKey) => <SnackbarCloseButtonStyled snackbarKey={snackbarKey} />}>
            {props.children}
        </SnackbarProvider>
    );
};

export default withStyles(styles, { index: 1 })(SnackBarProvider);
