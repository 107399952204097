import { call, Effect, takeLatest } from 'redux-saga/effects';
import { types as purchaseOrdersTypes, actions as purchaseOrdersActions } from 'ducks/purchaseOrders/purchaseOrders';
import { callApi } from '../utils';
import { IDataAction } from 'ducks/utils';
import * as api from 'api/purchaseOrders/purchaseOrders';

function* search(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = purchaseOrdersActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = purchaseOrdersActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  );
}

function* fetchNext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = purchaseOrdersActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* fetchPrev(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = purchaseOrdersActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(purchaseOrdersTypes.search, search);
  yield takeLatest(purchaseOrdersTypes.searchById, searchById);
  yield takeLatest(purchaseOrdersTypes.fetchPrevPage, fetchPrev);
  yield takeLatest(purchaseOrdersTypes.fetchNextPage, fetchNext);
}
