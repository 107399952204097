import {
    takeLatest, Effect,
    call,
    put
} from 'redux-saga/effects';
import { types as jobTemplateTypes, actions as jobTemplateActions } from 'ducks/jobTemplateMaintenance/jobTemplate';
import { actions as activityActions } from 'ducks/jobTemplateMaintenance/activity';
import {
    connectSagaToApi,
    callApi
} from '../utils';
import * as api from 'api/jobTemplateMaintenance/jobTemplate';
import { IDataAction } from 'ducks/utils';
import { Operation } from 'utils/operations';
import { actions as operationActions } from 'ducks/uiOperations';
import { initialize, reset } from 'redux-form';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

function* postSuccessCallback(response: any, message: string): IterableIterator<Effect> {
    if (!response.Forms) {
        const TemplateCode = response.JobTemplateDetails && response.JobTemplateDetails.inlineObject.TemplateCode;
        yield put(jobTemplateActions.searchById(TemplateCode));
        showSnackBar({ variant: 'success', message });
    }
}

function* createJobTemplate(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = jobTemplateActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield put(operationActions.changeOperationMode(Operation.BROWSE));
        yield call(postSuccessCallback, response, 'Job Template created successfully.');
        yield put(activityActions.resetFormData());
        yield put(activityActions.resetActivityData());
    }

    function* postApiFailureCallEffect(): IterableIterator<Effect> {
        yield put(reset('JobTemplateDetails'));
        yield put(operationActions.changeOperationMode(Operation.NEW));
        yield put(initialize('JobTemplateDetails', action.data));
    }

    yield callApi(
        call(api.create, action.data),
        success,
        failure,
        { dialogActionCallEffect: createJobTemplate, postApiFailureCallEffect, postApiSuccessCallEffect }
    );
}

function* updateJobTemplate(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = jobTemplateActions.saga.create;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        yield put(operationActions.changeOperationMode(Operation.BROWSE));
        if (!response.Forms) {
            showSnackBar({ variant: 'success', message: 'Job Template updated successfully.' });
        }
    }

    yield callApi(
        call(api.update, action.data),
        success,
        failure,
        { dialogActionCallEffect: updateJobTemplate, postApiSuccessCallEffect }
    );
}

function* deleteJobTemplate(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = jobTemplateActions.saga.delete;

    function* postApiSuccessCallEffect(response: any): IterableIterator<Effect> {
        if (!response.Forms) {
            yield put(jobTemplateActions.search({ SearchText: '' }));
            showSnackBar({ variant: 'success', message: 'Job Template deleted successfully.' });
        }
    }

    yield callApi(
        call(api.deleteJobTemplate, action.data),
        success,
        failure,
        { dialogActionCallEffect: deleteJobTemplate, postApiSuccessCallEffect }
    );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
    const { success, failure } = jobTemplateActions.saga.searchById;

    yield callApi(
        call(api.searchById, action.data),
        success,
        failure,
        { dialogActionCallEffect: searchById }
    );
}

export default function* rootJobTemplateMaintenanceSaga(): IterableIterator<Effect> {
    yield takeLatest(jobTemplateTypes.create, createJobTemplate);
    yield takeLatest(jobTemplateTypes.update, updateJobTemplate);
    yield takeLatest(jobTemplateTypes.delete, deleteJobTemplate);
    yield takeLatest(jobTemplateTypes.search, connectSagaToApi(jobTemplateActions, 'search', api.search));
    yield takeLatest(jobTemplateTypes.fetchPrevPage, connectSagaToApi(jobTemplateActions, 'fetchPrevPage', api.search));
    yield takeLatest(jobTemplateTypes.fetchNextPage, connectSagaToApi(jobTemplateActions, 'fetchNextPage', api.search));
    yield takeLatest(jobTemplateTypes.searchById, searchById);
    yield takeLatest(jobTemplateTypes.fetchJobTemplateData, connectSagaToApi(jobTemplateActions, 'fetchJobTemplateData', api.searchById));
}
