import * as React from 'react';
import DataGrid, { Column, Scrolling, GroupPanel, SearchPanel, LoadPanel, Selection, Paging, Sorting, HeaderFilter } from 'devextreme-react/data-grid';
import { withStyles } from '@material-ui/core/styles';
import MoreIcon from '@markinson/uicomponents-v2/SvgIcons/More';
import { Purchase as PurchaseIcon, Commit as CommitIcon } from '@markinson/uicomponents-v2/SvgIcons/';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { ICommitmentFiltersResponse, ICommitmentGridHandle, ICommitmentGridProps } from './CommitmentGrid.properties';
import styles from './CommitmentGrid.styles';
import ProductCell from 'components/common/ProductCell';
import Paper from '@material-ui/core/Paper';
import { DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH, DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { useBackOrderCommit, useRetrieveBackorderCommitmentLines } from 'api/Worksale/backorderCommitment';
import { createDataSource, useFetchDataGridFilters, useGridKeyboardNavigation } from 'components/common/DataGridDevEx/DataGrid.hooks';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { useBOCContextSelector } from '../BackorderCommitment.context';
import UserIdScreenDialog from 'components/Worksale/UserIdScreenDialog';
import { ICommitSalesLinesCriteriaFacade } from 'api/swaggerTypes';
import { isScreenRequest } from 'api/utils';
import { CANCEL_BUTTON } from 'components/common/ActionBar/constants';
import { ARROW_DOWN, ARROW_UP, SPACEBAR } from 'utils/constants';

const SMALL_COLUMN_MIN_WIDTH = 100;
const SMALL_COLUMN_DEFAULT_WIDTH = 150;
const COMMAND_COLUMN_WIDTH = 50;
const LARGE_COLUMN_MIN_WIDTH = 200;
const ICON_COLUMN_DEFAULT_WIDTH = 50;

function CommitmentGrid(props: Readonly<ICommitmentGridProps>, ref: React.Ref<ICommitmentGridHandle>): JSX.Element {
    const { classes, disableCommit, disablePurchase, showDetailOnClick
    } = props;

    const { setActionBar } = React.useContext(ActionBarContext);
    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);

    const dataGridInnerRef = React.useRef<DataGrid>();
    const backOrderCommitmentLinesFetchMutation = useRetrieveBackorderCommitmentLines();
    const linesFiltersMutation = useFetchDataGridFilters<ICommitmentFiltersResponse>();
    const { onFocusedCellChanging, preventDataGridDefaults } = useGridKeyboardNavigation();
    const [selectedRowsKeys, setSelectedRowsKeys] = React.useState<number[]>();
    const [userIdDialogOpen, setUserIdDialogOpen] = React.useState<boolean>(false);
    const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true);

    const backOrderCommitMutation = useBackOrderCommit();
    const commitData = backOrderCommitMutation.data;
    const commitForm = backOrderCommitMutation.data?.Forms?.[0];

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        [disablePurchase, disableCommit]
    );

    const leftIcons = [];

    const centerIcons = [
        {
            label: 'Purchase',
            Icon: PurchaseIcon,
            action: 'Purchase',
            disabled: disablePurchase
        },
        {
            label: 'Commit',
            Icon: CommitIcon,
            action: 'Commit',
            disabled: disableCommit
        }
    ];

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons,
            centerIcons,
            rightIcons: [
                {
                    ...CANCEL_BUTTON,
                    label: 'Close',
                }
            ]
        });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            getSelectedRowKeys(): number[] {
                return selectedRowsKeys;
            },
            commitBackOrder(): void {
                commitBackOrder();
            }
        }),
        [selectedRowsKeys]
    );

    React.useEffect(
        () => {
            if (SalesOrderId) {
                linesFiltersMutation.mutate({
                    FilterScreenId: 'OrderCommitmentLines',
                    FilterScope: [
                        {
                            scopeName: 'SalesOrder',
                            scopeValue: SalesOrderId
                        }
                    ]
                });
            }
        },
        [SalesOrderId]
    );

    const loadLines = React.useCallback(
        async (BatchPage, BatchSize, Sort, filter) => {
            const response = await backOrderCommitmentLinesFetchMutation.mutateAsync({
                OrderNumber: SalesOrderId,
                OutstandingBOQuantity: filter?.BackorderOutstandingDisplay,
                Supplier: filter?.MainSupplierIdDisplay,
                Warehouse: filter?.Warehouse,
                urlQueryParams: {
                    BatchPage,
                    BatchSize,
                    Sort
                }
            });

            return response?.OutstandingOrderLines ?? [];
        },
        [SalesOrderId]
    );

    const commitmentDataSource = React.useMemo(
        () => createDataSource(
            'LineNumber',
            { fetch: loadLines },
            {
                Warehouse: linesFiltersMutation.data?.Warehouse?.Data,
                MainSupplierIdDisplay: linesFiltersMutation.data?.Supplier?.Data,
                BackorderOutstandingDisplay: linesFiltersMutation.data?.OutstandingBOQuantity?.Data
            }
        ),
        [loadLines, linesFiltersMutation.data]
    );

    const detailCellComponent = React.useCallback(
        (allProps) => {
            return <MoreIcon className={classes.lineDetailIcon} onClick={() => {
                if (showDetailOnClick) {
                    showDetailOnClick(allProps.data ? allProps.data.data : null);
                }
            }} />;
        },
        [classes, showDetailOnClick]);

    const productCodeCellComponent = React.useCallback(
        (allProps) => (
            <ProductCell
                urlParamKeys={['ProductId']}
                toLink={'/inventory-enquiry/product-details'}
                {...allProps} />
        ),
        []
    );

    const onKeyDown = async (e) => {
        const keyboardEvent = e.event;
        preventDataGridDefaults(e);

        if (![ARROW_DOWN, ARROW_UP, SPACEBAR].includes(keyboardEvent.keyCode)) {
            return;
        }
    };

    const SelectAndFocusFirstRow = () => {
        setTimeout(
            () => {
                dataGridInnerRef.current?.instance?.focus();
            },
            0
        );
    };

    const handleSelectionChanged = React.useCallback(
        async (e) => {
            const selectedRowKeys = await e?.component?.getSelectedRowKeys();
            setSelectedRowsKeys(selectedRowKeys);
        },
        [setSelectedRowsKeys]
    );

    const commitBackOrder = React.useCallback(
        async (data?: ICommitSalesLinesCriteriaFacade) => {
            const response = await backOrderCommitMutation.mutateAsync({
                OrderNumber: SalesOrderId,
                CommitLines: selectedRowsKeys.map((l) => ({
                    LineNumber: l
                })),
                ...data
            });

            if (isScreenRequest(response?.Forms, 'UserID')) {
                setUserIdDialogOpen(true);
            }

            return response;
        },
        [SalesOrderId, selectedRowsKeys, setUserIdDialogOpen]
    );

    const submitUserIdData = React.useCallback(
        async (data) => {
            const response = await commitBackOrder(data);
            if (response?.Status && !isScreenRequest(response?.Forms, 'UserID')) {
                setUserIdDialogOpen(false);
                reloadLines();
            }
        },
        [commitBackOrder]
    );

    const reloadLines = () => {
        return commitmentDataSource.reload()
            .then(() => {
                dataGridInnerRef.current.instance.refresh();
            });
    };

    const handleRowClick = React.useCallback(
        (e) => {
            const keys = e.component.getSelectedRowKeys();
            const index = keys.indexOf(e.key);

            if (index > -1) {
                keys.splice(index, 1);
            } else {
                keys.push(e.key);
            }

            e.component.selectRows(keys, false);
        },
        []
    );

    const onContentReady = React.useCallback(
        (e) => {
            if (isFirstTime && e.component.getDataSource()?.items().length) {
                SelectAndFocusFirstRow();
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );

    return (
        <Paper className={classes.parentContainer}>
            <DataGrid
                ref={dataGridInnerRef}
                className={classes.commitmentDataGrid}
                dataSource={commitmentDataSource}
                showBorders={false}
                renderAsync={true}
                remoteOperations={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                onKeyDown={onKeyDown}
                onFocusedCellChanging={onFocusedCellChanging}
                noDataText='No Data'
                onSelectionChanged={handleSelectionChanged}
                onRowClick={handleRowClick}
                focusedRowEnabled={true}
                onContentReady={onContentReady}
            >
                <HeaderFilter visible />
                <Column
                    minWidth={ICON_COLUMN_DEFAULT_WIDTH}
                    width={COMMAND_COLUMN_WIDTH}
                    allowResizing={false}
                    fixed={true}
                    alignment='center'
                    allowHeaderFiltering={false}
                    cellComponent={detailCellComponent}
                />
                <Column
                    dataField={'Warehouse'}
                    caption={'W/H'}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                >
                    <HeaderFilter
                        dataSource={linesFiltersMutation.data?.Warehouse?.Data} />
                </Column>
                <Column
                    dataField={'MainSupplierIdDisplay'}
                    caption={'Supplier'}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                >
                    <HeaderFilter
                        dataSource={linesFiltersMutation.data?.Supplier?.Data} />
                </Column>
                <Column
                    dataField={'ProductIdDisplay'}
                    caption={'Product'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    cellComponent={productCodeCellComponent}
                />
                <Column
                    dataField={'BackorderedQuantityDisplay'}
                    caption={'On backorder'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                />
                <Column
                    dataField={'ReservedQuantityDisplay'}
                    caption={'Reserved'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                />
                <Column
                    dataField={'BackorderOutstandingDisplay'}
                    caption={'B/O outstanding'}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                    defaultFilterValues={['O']}
                >
                    <HeaderFilter dataSource={linesFiltersMutation.data?.OutstandingBOQuantity?.Data} />
                </Column>
                <Column
                    dataField={'UncommittedQuantityDisplay'}
                    caption={'Uncommitted'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                />
                <Column
                    dataField={'ReorderStatus'}
                    caption={'Reorder status'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                />
                <Column
                    dataField={'Description'}
                    caption={'Description'}
                    allowHeaderFiltering={false}
                    minWidth={LARGE_COLUMN_MIN_WIDTH} />
                <Column
                    dataField={'MainSupplierIdLabel'}
                    caption={'Supplier name'}
                    allowHeaderFiltering={false}
                    minWidth={SMALL_COLUMN_MIN_WIDTH}
                    width={SMALL_COLUMN_DEFAULT_WIDTH}
                >
                </Column>
                <Paging defaultPageSize={DEFAULT_PAGE_SIZE} defaultPageIndex={0} />
                <Sorting mode='none' />
                <Selection mode={'multiple'}
                    showCheckBoxesMode='always' />
                <GroupPanel visible={false} />
                <SearchPanel visible={false} />
                <Scrolling mode={'infinite'} />
                <LoadPanel shading={false} height={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} width={DATA_GRID_LOADING_INDICATOR_HEIGHT_WIDTH} text={''} showPane={false} />
            </DataGrid>
            <UserIdScreenDialog
                open={userIdDialogOpen}
                title={commitForm?.Title}
                message={commitForm?.Message}
                loading={false}
                data={{
                    UserId: commitData?.CommitSalesLinesCriteria?.OverrideUserId,
                    Pin: commitData?.CommitSalesLinesCriteria?.OverrideUserPIN
                }}
                onOk={(data) => submitUserIdData({
                    ConfirmCommit: true,
                    OverrideUserId: data.UserId,
                    OverrideUserPIN: data.Pin,
                })}
                onCancel={() => setUserIdDialogOpen(false)}
            />
        </Paper>
    );
}

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(CommitmentGrid)));
