import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as productionOrderSelectors, actions as productionOrderActions } from 'ducks/inventoryEnquiry/stocking/productionOrders';
import { search } from 'api/inventoryEnquiry/stocking/productionOrders';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesProductionOrder',
  columnDefs: productionOrderSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: productionOrderSelectors.gridOptions(state),
  isLoading: productionOrderSelectors.isLoading(state),
  reqParams: (productionOrderSelectors.selected(state) || params().get('ProductId') || params().get('Warehouse'))
    &&
  {
    ProductId: (productionOrderSelectors.selected(state) && productionOrderSelectors.selected(state).ProductId) || params().get('ProductId'),
    Warehouse: (productionOrderSelectors.selected(state) && productionOrderSelectors.selected(state).Warehouse) || params().get('Warehouse')
  },
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: productionOrderActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
