import { createReducer } from 'utils/helpers';
import { BackorderCommitmentActionTypes, IActions } from './BackorderCommitment.actions';

export interface IState {
    SalesOrderId?: string;
    PurchaseOrderId?: number;
    PurchaseOrderLineId?: number;
    CommitmentLineNumber?: number;
    OpenInternalOrderDialog?: boolean;
    InternalOrderDialogData?: any;
}

export const initialState: IState = {
    SalesOrderId: '',
    PurchaseOrderId: 0,
    PurchaseOrderLineId: 0,
    CommitmentLineNumber: 0,
    OpenInternalOrderDialog: false,
    InternalOrderDialogData: {}
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<BackorderCommitmentActionTypes, IAction>;

const actionMap: IActionMap = {
    [BackorderCommitmentActionTypes.setSalesOrderId]: (state: IState, payload: IState['SalesOrderId']): IState => ({ ...state, SalesOrderId: payload }),
    [BackorderCommitmentActionTypes.setPurchaseOrderId]: (state: IState, payload: IState['PurchaseOrderId']): IState => ({ ...state, PurchaseOrderId: payload }),
    [BackorderCommitmentActionTypes.setPurchaseOrderLineId]: (state: IState, payload: IState['PurchaseOrderLineId']): IState => ({ ...state, PurchaseOrderLineId: payload }),
    [BackorderCommitmentActionTypes.setCommitmentLineNumber]: (state: IState, payload: IState['CommitmentLineNumber']): IState => ({ ...state, CommitmentLineNumber: payload }),
    [BackorderCommitmentActionTypes.setOpenInternalOrderDialog]: (state: IState, payload: IState['OpenInternalOrderDialog']): IState => ({ ...state, OpenInternalOrderDialog: payload }),
    [BackorderCommitmentActionTypes.setInternalOrderDialogData]: (state: IState, payload: IState['InternalOrderDialogData']): IState => ({ ...state, InternalOrderDialogData: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
