import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as quotesTypes, actions as quotesActions } from 'ducks/customerEnquiry/salesOrders';

import { callApi } from '../utils';
import * as api from 'api/customerEnquiry/salesOrders';


function* fetch(action) {
  const { success, failure } = quotesActions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}
export default function* salesOrdersSaga() {
  yield takeLatest(quotesTypes.fetch, fetch)
}