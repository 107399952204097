import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as invoiceSelectors } from 'ducks/supplierInvoiceEnquiry/supplierInvoice';
import { selectors as invoiceDetailsSelectors, actions as invoiceDetailsActions } from 'ducks/supplierInvoiceEnquiry/invoiceDetails';
import { actions as invoiceLineActions, selectors as invoiceLineSelectors } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLines';
import { actions as invoiceLineLotDetailsActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLinesLotDetails';
import { actions as invoiceLineSerialNumbersActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLinesSerialNumbers';
import { actions as invoiceLineMovementsActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLinesMovements';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import SupplierInvoiceEnquiry from './SupplierInvoiceEnquiry';
import { selectors as diarySelectors } from 'ducks/common/diary';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedInvoice: invoiceSelectors.selected(state),
  invoiceSummary: invoiceDetailsSelectors.summary(state),
  invoiceDetails: invoiceDetailsSelectors.invoiceDetails(state),
  invoiceComment: invoiceDetailsSelectors.comment(state),
  invoiceLineSummary: invoiceLineSelectors.invoiceLineSummary(state),
  invoiceLineDetails: invoiceLineSelectors.details(state),
  loadingInvoiceLineSummary: invoiceLineSelectors.loadingSummary(state),
  selectedInvoiceLine: invoiceLineSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  selectedDiary: diarySelectors.selected(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    fetchInvoiceLinesLotDetails: invoiceLineLotDetailsActions.fetch,
    fetchInvoiceLinesSerialNumbers: invoiceLineSerialNumbersActions.fetch,
    fetchInvoiceLinesMovements: invoiceLineMovementsActions.fetch,
    getInvoiceSummary: invoiceDetailsActions.fetchSummary,
    getInvoiceComment: invoiceDetailsActions.fetchComment,
    getInvoiceDetails: invoiceDetailsActions.fetch,
    getInvoiceLineSummary: invoiceLineActions.getInvoiceLineSummary,
    getInvoiceLineDetails: invoiceLineActions.getInvoiceLineDetails,
    lockDrawer: uiActions.lockDrawer,
    unlockDrawer: uiActions.unlockDrawer,
    closeMenu: uiActions.closeMainMenu
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierInvoiceEnquiry);
