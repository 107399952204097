export enum PanelIdToName {
    UpcomingJob = 1,
    Customer,
    Delivery,
    Contact,
    Activities,
    Confirmation
}

export const TITLES = {
    1: 'Upcoming Service Agreements',
    2: 'New Job',
    3: 'Service location details',
    4: 'Contact information',
    5: 'Service Items',
    6: 'Activities',
    7: 'Confirmation',
};

export const NEW_JOB_LOADING_INDICATOR_HEIGHT_WIDTH = 50;
export const NEW_JOB_DATA_GRID_HEIGHT = 541;
