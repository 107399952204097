import React, { Component } from 'react';

class HelpPanel extends Component{
  render(){

    return (
      <div>
      You have come to right place for help. 
      </div>
    )
  }
}

export default HelpPanel;