import React from 'react';
import Lines from './Lines';
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styles from './PurchaseOrder.styles';
import IPurchaseOrderProps from './PurchaseOrder.properties';
import PurchaseOrderLookupPanel from '../BackorderCommitmentLookupPanel';
import {
    useApplyPurchaseOrderContext, useCalculatePurchaseOrderContext, useCalculatePurchaseOrderLine, usePurchaseOrderContext, useRetrievePurchaseOrderLineDetails, useRetrievePurchaseOrderSummary,
} from 'api/Worksale/backorderCommitment';
import { ICommitLine, ICommitPurchaseOrderGridLineDetailFacade } from 'api/swaggerTypes';
import PurchaseOrderLineDetails from './PurchaseOrderLineDetails';
import { useBOCContextSelector, useBOCDispatch } from '../BackorderCommitment.context';
import OrderDetails from './OrderDetails';
import Deliveries from './Deliveries';
import { isNull } from 'utils/utils';
import { isSuccess } from 'api/utils';

const PurchaseOrder = (props: IPurchaseOrderProps) => {
    const {
        classes, match, location, history, purchaseOrderLineDetailsRef, purchaseOrderDetailsRef, purchaseDeliveriesRef, purchaseOrderLinesRef,
        setLookupPanelOpen: setLookupPanelOpenProp, changeSelectedTab
    } = props;

    const [lookupPanelOpen, setLookupPanelOpen] = React.useState<boolean>(false);
    const [commitLines, setCommitLines] = React.useState<ICommitLine[]>();

    const contextDispatch = useBOCDispatch();
    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);
    const SalesOrderId = useBOCContextSelector<'SalesOrderId'>((state) => state.SalesOrderId);

    const calculateContextMutation = useCalculatePurchaseOrderContext();
    const fetchPurchaseOrderContextMutation = usePurchaseOrderContext();
    const applyPurchaseOrderContextMutation = useApplyPurchaseOrderContext();
    const retrievePurchaseOrderSummary = useRetrievePurchaseOrderSummary(PurchaseOrderId);
    const calculatePurchaseOrderLineMutation = useCalculatePurchaseOrderLine();
    const fetchPurchaseOrderLineDetailsMutation = useRetrievePurchaseOrderLineDetails();

    const purchaseOrderResponse = fetchPurchaseOrderContextMutation.data;
    const panelLoading = fetchPurchaseOrderContextMutation.isLoading || calculateContextMutation.isLoading;
    const path = match?.path ?? '';

    const [formData, setFormData] = React.useState<{ PurchaseOrder: string; Supplier: number; SupplierDisplay: string }>();

    const autoGeneratePO = React.useMemo(() => fetchPurchaseOrderContextMutation.data?.PurchaseOrderContext?.AutoGeneratePO, [fetchPurchaseOrderContextMutation.data]);

    React.useEffect(
        () => {
            if (PurchaseOrderId) {
                setFormData({
                    PurchaseOrder: retrievePurchaseOrderSummary.data?.PurchaseSummary?.PurchaseOrder,
                    Supplier: retrievePurchaseOrderSummary.data?.PurchaseSummary?.SupplierId,
                    SupplierDisplay: (retrievePurchaseOrderSummary.data?.PurchaseSummary as any)?.SupplierIdDisplay,
                });
            }
        },
        [retrievePurchaseOrderSummary.data, PurchaseOrderId]
    );

    const _setLookupPanelOpen = (open: boolean) => {
        if (setLookupPanelOpenProp) {
            setLookupPanelOpenProp(open);
        }
        setLookupPanelOpen(open);
    };

    React.useEffect(
        () => {

            return () => {
                _setLookupPanelOpen(false);
                contextDispatch({
                    setPurchaseOrderId: null,
                    setPurchaseOrderLineId: null,
                    setCommitmentLineNumber: null
                });
            };
        },
        [contextDispatch]
    );

    React.useEffect(
        () => {
            _setLookupPanelOpen(!Boolean(PurchaseOrderId));

        },
        [PurchaseOrderId]
    );

    const fetchPurchaseOrderContext = React.useCallback(
        async (salesOrder, selectedCommitLines: ICommitLine[]) => {
            const response = await fetchPurchaseOrderContextMutation.mutateAsync({
                OrderNumber: salesOrder,
                CommitLines: selectedCommitLines
            });

            if (response?.Status === true && response?.PurchaseOrderContext) {
                setFormData({
                    PurchaseOrder: response?.PurchaseOrderContext?.PurchaseOrder,
                    Supplier: response?.PurchaseOrderContext?.SupplierId,
                    SupplierDisplay: (response?.PurchaseOrderContext as any)?.SupplierIdDisplay,
                });
            }
        },
        [setFormData]
    );

    React.useEffect(
        () => {
            const selectedCommitLines = location.state?.selectedLines?.map((num) => ({
                lineNumber: num
            }));
            setCommitLines(selectedCommitLines);
            if (!PurchaseOrderId && SalesOrderId && !isNull(selectedCommitLines)) {
                fetchPurchaseOrderContext(SalesOrderId, selectedCommitLines);
            }
        },
        [SalesOrderId, PurchaseOrderId, fetchPurchaseOrderContext]
    );

    const handlePanelFieldOnChange = React.useCallback(
        async (name, value, form) => {
            if (value && !Boolean(PurchaseOrderId)) {
                const response = await calculateContextMutation.mutateAsync({
                    AddProductsToExistingPurchaseOrder: null,
                    AddPurchaseOrder: null,
                    Warehouse: purchaseOrderResponse?.PurchaseOrderContext?.Warehouse,
                    CommitLines: commitLines,
                    ...form,
                    ...{ [name]: value },
                    urlQueryParams: {
                        ChangedFieldId: (name === 'Supplier') ? 'SupplierId' : name,
                    }
                });

                if (isSuccess(response)) {
                    setFormData({
                        PurchaseOrder: response?.PurchaseOrderContext?.PurchaseOrder ?? '',
                        Supplier: response?.PurchaseOrderContext?.SupplierId,
                        SupplierDisplay: (response?.PurchaseOrderContext as any)?.SupplierIdDisplay,
                    });
                }

                return response;
            }

            return null;
        },
        [commitLines, purchaseOrderResponse, PurchaseOrderId, setFormData, autoGeneratePO]
    );

    const handleApplyPurchaseOrderContext = React.useCallback(
        async (form) => {
            const response = await applyPurchaseOrderContextMutation.mutateAsync({
                OrderNumber: SalesOrderId,
                AddProductsToExistingPurchaseOrder: null,
                AddPurchaseOrder: null,
                Warehouse: purchaseOrderResponse?.PurchaseOrderContext?.Warehouse,
                CommitLines: commitLines,
                ...form,
            });

            history.push({
                search: `?SalesOrderId=${SalesOrderId}&PurchaseOrderId=${response.PurchaseOrderId}`,
                state: location.state
            });
            contextDispatch({
                setPurchaseOrderId: response.PurchaseOrderId
            });
        },
        [commitLines, purchaseOrderResponse, SalesOrderId, location]
    );

    const calculateCellValue = React.useCallback(
        async (payload): Promise<ICommitPurchaseOrderGridLineDetailFacade> => {
            const response = await calculatePurchaseOrderLineMutation.mutateAsync(payload);

            return response?.PurchaseOrderGridLineDetail;
        },
        []
    );

    const handleLinesDetailClick = React.useCallback(
        async (data) => {
            if (!data?.PurchaseOrderLineId) {
                return;
            }

            const response = await fetchPurchaseOrderLineDetailsMutation.mutateAsync({
                PurchaseOrderLineId: data?.PurchaseOrderLineId
            });

            if (isSuccess(response)) {
                changeSelectedTab('PurchaseOrderLineDetails', {
                    search: {
                        SalesOrderId,
                        PurchaseOrderId,
                        PurchaseOrderLineId: data?.PurchaseOrderLineId,
                    }
                });
                contextDispatch({
                    setPurchaseOrderLineId: data?.PurchaseOrderLineId
                });
            }
        },
        [SalesOrderId, PurchaseOrderId]
    );

    return (
        <>
            <PurchaseOrderLookupPanel
                open={lookupPanelOpen}
                loading={panelLoading}
                formData={formData}
                disabled={Boolean(PurchaseOrderId)}
                setOpen={_setLookupPanelOpen}
                onfieldChange={handlePanelFieldOnChange}
                onApply={handleApplyPurchaseOrderContext}
                autoGeneratePO={autoGeneratePO}
            />
            <div className={classes.container}>
                <Route
                    key={'PurchaseOrderLines'}
                    path={path}
                    exact
                    render={() => <Lines
                        innerRef={purchaseOrderLinesRef}
                        supplierId={formData?.Supplier}
                        changeSelectedTab={changeSelectedTab}
                        calculateCellValue={calculateCellValue}
                        onDetailClick={handleLinesDetailClick}
                    />}
                />
                <Route
                    key={'PurchaseOrderLineDetails'}
                    path={`${path}/line-details`}
                    exact
                    render={() => (
                        <PurchaseOrderLineDetails
                            data={fetchPurchaseOrderLineDetailsMutation.data}
                            supplier={formData?.Supplier}
                            innerRef={purchaseOrderLineDetailsRef}
                            changeSelectedTab={changeSelectedTab}
                        />
                    )}
                />
                <Route
                    key={'OrderDetails'}
                    path={`${path}/order-details`}
                    exact
                    render={() => (
                        <OrderDetails
                            innerRef={purchaseOrderDetailsRef} />
                    )}
                />
                <Route
                    key={'Deliveries'}
                    path={`${(match && match.path) || ''}/delivery`}
                    exact
                    render={() => (
                        <Deliveries
                            innerRef={purchaseDeliveriesRef}
                            warehouse={retrievePurchaseOrderSummary.data?.PurchaseSummary?.Warehouse} />
                    )}
                />
            </div>
        </>
    );
};

export default withRouter(withStyles(styles, { index: 1 })(React.memo(PurchaseOrder)));
