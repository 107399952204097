import { Payload, usePost } from 'api/reactQuery/queryService';
import {
  IValidateProductFacade, IValidateProductResponse, IQuoteRecallSearchRequestSales, IQuoteRecallSearchResponse,
  IOutstandingOrdersFilter, ISearchOutstandingCustomerOrdersResponse, IRecallOrderResponse, ILostsaleFacade,
  IRecallOrderFacade, IOutstandingOrdersFacade, IParkedSaleFilter, IParkedSalesSearchResponse, IMessageModelSearchResponse, IRecallParkedSaleFacade, IParkedSalesFacade, IWorksaleCriteriaFacade, ICalculateWorksaleContextResponse,
  ILookupResponse
} from 'api/swaggerTypes';
import { APIRoutes } from './constants';
import { IObjectified, objectify } from 'api/utils';
import { toObjectified } from 'api/baseApi';

export const useValidateProduct = () =>
  usePost<Payload<IValidateProductFacade>, IValidateProductResponse>(APIRoutes.worksale.product.validate, null);

const retrieveQuotesResponseParser = (response) => {
  const { Quote } = response;

  return {
    ...response,
    Quote: Quote ? response.Quote.map((d) => objectify(d, null, 'Inline')) : []
  };
};

export const useRetrieveQuotes = () =>
  usePost<Payload<IQuoteRecallSearchRequestSales>, IQuoteRecallSearchResponse>(APIRoutes.worksale.recallQuote.retrieve, null, {
    inline: true,
    responseParser: retrieveQuotesResponseParser
  });

interface IOutstandingOurderRequest extends IOutstandingOrdersFilter {
  DefaultEntity?: boolean;
  WorksaleId?: number;
  BatchPage?: number;
  BatchSize?: number;
  Sort?: string;
}

interface ISearchOutstandingCustomerOrdersResponseExtended extends Omit<ISearchOutstandingCustomerOrdersResponse, 'OutstandingOrders'> {
  'OutstandingOrders'?: IObjectified<IOutstandingOrdersFacade>;
}

const searchOutstandingCustomerOrders = (response) => {
  const { OutstandingOrders } = response;

  return {
    ...response,
    OutstandingOrders: OutstandingOrders ? {
      ...toObjectified(OutstandingOrders as any[])
    } : null
  };
};

export const useSearchOutstandingCustomerOrders = () =>
  usePost<Payload<IOutstandingOurderRequest>, ISearchOutstandingCustomerOrdersResponseExtended>(
    APIRoutes.worksale.recallOutstandingOrders.retrieve,
    null,
    { responseParser: searchOutstandingCustomerOrders, inline: true }
  );

const recallOrderParser = (response) => {
  const { RecallOrder } = response;

  return {
    ...response,
    RecallOrder: RecallOrder ? objectify(RecallOrder as any, null, 'Inline') : null
  };
};

export const useRecallOrder = () =>
  usePost<Payload<IRecallOrderFacade>, IRecallOrderResponse>(
    APIRoutes.worksale.recallOutstandingOrders.create,
    null,
    { responseParser: recallOrderParser, inline: true, disableDefaultValidation: true },
  );

interface ILostsaleFacadeExtended extends ILostsaleFacade {
  WorksaleId: number;
}

export const useCreateLotSale = () =>
  usePost<Payload<ILostsaleFacadeExtended, { FromLineNumber: number }>, IRecallOrderResponse>(
    APIRoutes.worksale.lostSale.create,
    null
  );

export type ObjectifiedIParkedSalesSearchResponse = Omit<IParkedSalesSearchResponse, 'ParkedSales'> & {
  ParkedSales: IObjectified<IParkedSalesFacade>[];
};

const recallParkedSaleParser = (response) => {

  return {
    ...response,
    ParkedSales: response.ParkedSales ? response.ParkedSales.map((d) => objectify({ ...d, DateTime: `${d?.Entered.Value} ${d?.Time?.Display}` }, null, 'Inline')) : []
  };
};

export const useRetriveParkedSale = () =>
  usePost<Payload<IParkedSaleFilter, { ParkedSaleOnly?: boolean }>, ObjectifiedIParkedSalesSearchResponse>(
    APIRoutes.worksale.recallParkedSales.retrieve,
    null,
    { inline: true, responseParser: recallParkedSaleParser }
  );

export const useRecallParkedSale = () =>
  usePost<Payload<IRecallParkedSaleFacade>, IMessageModelSearchResponse>(
    APIRoutes.worksale.recallParkedSales.recallSale,
    null,
    { inline: true }
  );

const calculateWorksaleContextResponseParser = (response) => ({ ...response, WorksaleCriteria: toObjectified(response?.WorksaleCriteria)?.inlineObject });

export const useCalculateWorksaleContext = () =>
  usePost<Payload<IWorksaleCriteriaFacade, { ChangedField: string }>, ICalculateWorksaleContextResponse>(
    APIRoutes.worksale.search.calculate,
    null,
    { inline: true, responseParser: calculateWorksaleContextResponseParser, disableDefaultValidation: true }
  );

export const useRetrieveWorksalePeriod = () => {

  return usePost<Payload<{ SearchText: string; Module: string }>, ILookupResponse>
    (
      APIRoutes.worksale.search.retrievePeriod,
      null,
      { inline: true }
    );
};
