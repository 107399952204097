import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as rfcTypes, actions as rfcActions } from 'ducks/rfcEnquiry/rfc';

import { callApi } from '../utils';
import * as api from 'api/rfc/rfc';


function* search(action){
  const {success, failure} = rfcActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* searchById(action){
  const {success, failure} = rfcActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  )
}

function* fetchNext(action){
  const {success, failure} = rfcActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

function* fetchPrev(action){
  const {success, failure} = rfcActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  )
}

export default function* rootSupplierInvoiceSaga(){
  yield takeLatest(rfcTypes.search, search)
  yield takeLatest(rfcTypes.searchById, searchById)
  yield takeLatest(rfcTypes.fetchPrevPage, fetchPrev)
  yield takeLatest(rfcTypes.fetchNextPage, fetchNext)
}