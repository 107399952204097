import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

import {
    createActions, asyncInitialState, asyncSelectors,
    IDataAction,
    IExtendedState
} from '../utils';

export interface IPolicyUserGroupsData {
    selected: any;
    gridOptions: IDataGridOptions;
    contextMenu: any[];
    Actions: any;
    groupsColumns: IColDef[];
}

export interface IPolicyUserGroupsState extends IExtendedState<IPolicyUserGroupsData> {
    fetch_loading?: boolean;
}

export const { types, actions } = createActions(
    {
        setSelected: (row) => ({ row }),
        asyncs: {
        }
    },
    'policyUserGroups');

const initialState = asyncInitialState<IPolicyUserGroupsData>({
    selected: null,
    gridOptions: {
        doubleClickActionTab: '',
        suppressEditDeleteInContextMenu: true,
        rowModelType: 'serverSide',
        cacheBlockSize: 10,
        maxBlocksInCache: 5,
        enableMultiSelection: true,
        allowSelectAll: false
    },
    contextMenu: [
        {
            name: 'Remove Selected User Group',
        },
    ],
    Actions: {
    },
    groupsColumns: [
        { headerName: 'User Group', field: 'UserGroup', minWidth: 150 },
    ],
});

export default (state: IPolicyUserGroupsState = initialState, action: IDataAction): IPolicyUserGroupsState => {
    switch (action.type) {
        case types.setSelected:
            const selected = action.data.row;

            return {
                ...state,
                data: {
                    ...state.data,
                    selected: selected
                }
            };
        default:
            return state;
    }
};

const asyncSelector = asyncSelectors(
    (state: { securityManagement: { policyUserGroups: IPolicyUserGroupsState } }) => state.securityManagement.policyUserGroups,
    {

    }
);

const syncSelector = {
    selected: (data) => data.selected,
    isLoading: (state: { securityManagement: { policyUserGroups: IPolicyUserGroupsState } }) => state.securityManagement.policyUserGroups.fetch_loading,
    gridOptions: (state: { securityManagement: { policyUserGroups: IPolicyUserGroupsState } }) => state.securityManagement.policyUserGroups.data.gridOptions,
    contextMenu: (state: { securityManagement: { policyUserGroups: IPolicyUserGroupsState } }) => state.securityManagement.policyUserGroups.data.contextMenu,
    columns: (state: { securityManagement: { policyUserGroups: IPolicyUserGroupsState } }) => state.securityManagement.policyUserGroups.data.groupsColumns
};

export const selectors = { ...asyncSelector, ...syncSelector };
