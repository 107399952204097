import { IFormInfo } from 'api/swaggerTypes';
import { IKitDetail } from 'api/Worksale/worksale';
import React from 'react';
import { ILostSalesData, PriceOverrideAuthRequestedScreen } from './Worksale.properties';
import { useSPContextSelector, useSPDispatch } from './Worksale.context';
import { SalesProcessingActionsTypes } from './Worksale.actions';
import { LostSaleDialogState } from '../common/LostSalesDialog/LostSalesDialog.properties';
import { initialState } from './Worksale.reducer';
import { PriceOverrideScreenType } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { isScreenRequest } from 'api/utils';
import { RouteComponentProps } from 'react-router-dom';
export interface IValidateResponse {
  Forms?: IFormInfo[];
  OverrideDetails?: any;
  KitDetail?: any;
  KitStatus?: string;
  BomCode?: string;
  multipleBomDialogApi?: BomApis;
}

type BomApis = 'addLine' | 'calculateFastEntry' | 'updateGridLine' | 'calculateGridLine' | 'update' | 'load';

export const usePriceOverrideAndKitValidations = () => {
  const [multipleBomOpen, setMultipleBomState] = React.useState<boolean>(false);
  const [priceOverrideDialogLoading, setPriceOverrideDialogLoading] = React.useState<boolean>(false);
  const [priceOverrideScreenType, setPriceOverrideScreenType] = React.useState<PriceOverrideScreenType>(PriceOverrideScreenType.UserAuthAndReasonScreen);
  const [priceOverrideDialogOpen, setPriceOverrideDialogOpen] = React.useState<boolean>(false);
  const [priceOverrideDetailSummary, setPriceOverrideDetailSummary] = React.useState<any>(null);
  const [bomList, setBomList] = React.useState<IKitDetail[]>([]);
  const [kitStatus, setKitStatus] = React.useState<string>('');
  const [selectedBomCode, setSelectedBomCode] = React.useState<string>('');
  const [priceOverrideAuthRequestedScreen, setPriceOverrideAuthRequestedScreen] = React.useState<PriceOverrideAuthRequestedScreen>(PriceOverrideAuthRequestedScreen.FastLineEntry);
  const [multipleBomDialogApi, setMultipleBomDialogApi] = React.useState<BomApis>(null);

  const resetStates = () => {
    setMultipleBomState(false);
    setPriceOverrideDialogLoading(false);
    setPriceOverrideDialogOpen(false);
    setPriceOverrideDetailSummary(null);
    setBomList([]);
    setKitStatus('');
    setSelectedBomCode('');
    setPriceOverrideAuthRequestedScreen(PriceOverrideAuthRequestedScreen.FastLineEntry);
    setMultipleBomDialogApi(null);
    setPriceOverrideScreenType(PriceOverrideScreenType.UserAuthAndReasonScreen);
  };

  const isPriceOverrideScreenRequest = (form: any) => form?.FormId === PriceOverrideScreenType.UserAuthAndReasonScreen.toString() ||
    form?.FormId === PriceOverrideScreenType.ReasonScreen.toString() ||
    form?.FormId === PriceOverrideScreenType.UserAuthScreen.toString();

  const validate = (screen: PriceOverrideAuthRequestedScreen, response: IValidateResponse) => {
    if (!response.Forms) {
      return;
    }
    const form = response.Forms?.[0];

    if (isPriceOverrideScreenRequest(form)) {
      setPriceOverrideScreenType(PriceOverrideScreenType[form.FormId]);
      setPriceOverrideDetailSummary(response?.OverrideDetails);
      setPriceOverrideAuthRequestedScreen(screen);
      setPriceOverrideDialogOpen(true);
    } else if (form?.FormId === 'SelectBomCode') {
      setBomList((response?.KitDetail) ?? [] as any);
      setKitStatus((response?.KitStatus) ?? '');
      setSelectedBomCode((response?.BomCode) ?? '');
      setMultipleBomDialogApi(response?.multipleBomDialogApi ?? null);
      setMultipleBomState(true);
    }
  };

  return {
    priceOverrideDialogLoading,
    priceOverrideAuthRequestedScreen,
    priceOverrideDialogOpen,
    priceOverrideDetailSummary,
    priceOverrideScreenType,
    bomList,
    kitStatus,
    selectedBomCode,
    multipleBomOpen,
    multipleBomDialogApi,
    resetStates,
    isPriceOverrideScreenRequest,
    setMultipleBomDialogApi,
    validate,
    setBomList,
    setKitStatus,
    setSelectedBomCode,
    setMultipleBomState,
    setPriceOverrideScreenType,
    setPriceOverrideDialogOpen,
    setPriceOverrideDetailSummary,
    setPriceOverrideDialogLoading
  };
};

export const useLostSale = () => {
  const contextDispatch = useSPDispatch();
  const dialogStates = useSPContextSelector<'lostSaleDialog'>((state) => state.lostSaleDialog);

  const setDialogState = React.useCallback(
    (dialoge: LostSaleDialogState<ILostSalesData>) => contextDispatch({
      [SalesProcessingActionsTypes.setLostSaleDialog]: dialoge
    }),
    [contextDispatch]
  );

  const closeModal = React.useCallback(
    () => {
      setDialogState(initialState.lostSaleDialog);
    },
    []
  );

  const isLostSale = React.useCallback(
    (forms) => isScreenRequest(forms, 'WorksalelineLostsaleScreen'),
    []
  );

  return {
    dialogStates,
    setDialogState,
    closeModal,
    isLostSale
  };
};

export const useTabLocations = (url: string, location: RouteComponentProps['location']) => {
  const contextDispatch = useSPDispatch();
  const WorksaleLineId = useSPContextSelector<'WorksaleLineId'>((state) => state.WorksaleLineId);
  const WorksaleDeliveryId = useSPContextSelector<'WorksaleDeliveryId'>((state) => state.WorksaleDeliveryId);
  const WorksaleId = useSPContextSelector<'WorksaleId'>((state) => state.WorksaleId);
  const tabLocations = React.useMemo(
    () => {
      return ({
        Lines: url,
        OrderDetails: `${url.endsWith('/') ? url : `${url}/`}order-details`,
        Deliveries: `${url.endsWith('/') ? url : `${url}/`}deliveries`,
        LineDetails: {
          pathname: `${url.endsWith('/') ? url : `${url}/`}line-details`,
          search: `?WorksaleLineId=${WorksaleLineId}`
        },
        DeliveryDetails: `${url.endsWith('/') ? url : `${url}/`}delivery-details`,
        Fulfillment: {
          pathname: `${url.endsWith('/') ? url : `${url}/`}fulfillment`,
          search: `?WorksaleId=${WorksaleId}&DeliveryId=${WorksaleDeliveryId}`
        },
        KitComponents: `${url.endsWith('/') ? url : `${url}/`}line-details/kit-components`,
        ComponentDetails: `${url.endsWith('/') ? url : `${url}/`}line-details/kit-components/component-details`,
        ProductCatalogue: `${url.endsWith('/') ? url : `${url}/`}product-catalogue`,
        CatalogueProductDetails: `${url.endsWith('/') ? url : `${url}/`}product-catalogue/catalogue-product-details`,
        CatalogueEntryDetails: `${url.endsWith('/') ? url : `${url}/`}product-catalogue/catalogue-entry-details`,
        BackorderLines: '/backorder-commitment'
      });
    },
    [url, WorksaleLineId, WorksaleId, WorksaleDeliveryId]
  );
  React.useEffect(
    () => {
      const params = (new URLSearchParams(location.search));
      const WorksaleLineIdParam = params.get('WorksaleLineId') || '';
      const WorksaleIdParam = params.get('WorksaleId') || '';
      const WorksaleDeliveryIdParam = params.get('DeliveryId') || '';
      contextDispatch({
        setWorksaleLineId: Number(WorksaleLineIdParam),
        setWorksaleId: Number(WorksaleIdParam),
        setWorksaleDeliveryId: Number(WorksaleDeliveryIdParam),
      });
    },
    [contextDispatch]
  );

  return { tabLocations };
};
