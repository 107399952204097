import { createStyles } from '@material-ui/core/styles';
import { customerMaintenanceDrawerBackground, dragExpandIcon } from '../../../themes/colors';

export default createStyles({
  mainDiv: {
    height: ' calc(100% - 64px)',
    top: '64px',
    position: 'relative'
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  paperContainer: {
    padding: '5px',
    width: 'fit-content',
  },
  collapseableBreadCrumb: {
    'padding-bottom': 0,
    'font-family': '"Roboto", "Helvetica", "Arial", sans-serif;',
  },
  grid: {
    width: 'calc(100% - 300px)',
    margin: 15,
    marginRight: 0
  },
  Icon: {
    cursor: 'pointer',
  },
  actionButtonIcon: {
    cursor: 'pointer',
    fontSize: '18px',
    margin: '0 3px',
    color: '#337ab7'
  },
  anchor: {
    display: 'inline-flex'
  },
  disabledIcon: {
    color: 'gray',
    pointerEvents: 'none'
  },
  rowsContainer: {
    height: 'calc(100% - 45px)'
  },
  lookupFieldsPaperContainer: {
    width: 'calc(100% - 20px)',
    padding: '10px 10px 0',
    boxSizing: 'border-box',
    margin: '0px 10px 10px 10px',
  },
  customerLookupField: {
    'padding-bottom': 15,
    '& > div': {
      width: '100%',
      maxWidth: 'unset',
      padding: 0
    },
    'flex-wrap': 'wrap'
  },
  POfield: {
    'width': '100%',
    'minWidth': '200px',
    'maxWidth': 'unset',
    'margin-top': '8px'
  },
  lookupContainerStyle: {
    top: '0',
    backgroundColor: customerMaintenanceDrawerBackground,
    height: '100%',
    boxShadow: 'none',
    width: 300,
    position: 'relative',
    paddingTop: '10px'
  },
  dragIcon: {
    color: dragExpandIcon,
    width: '20px',
    height: '20px',
    marginLeft: '-5px',
    cursor: 'pointer'
  },
  closedDragIcon: {
    color: dragExpandIcon,
    width: '30px',
    height: '30px',
    marginLeft: '-5px',
    cursor: 'pointer',
  },
  commitActionPaperContainer: {
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '60px',
    backgroundColor: '#ebebeb',
    margin: '0px 10px'
  },
  commitActionButton: {
    flexBasis: '50%',
    boxShadow: 'none',
    borderRadius: 0
  },
  circularLoading: {
    color: '#006633',
    margin: '0px 5px'
  },
  centerContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: 10
  },
  backorderCommitmentLookupPanel: {
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 64
  },
  onLookupOpened: {
    float: 'right',
    width: 'calc(100% - 310px)',
    marginLeft: 10
  },
  onLookupClosed: {
    width: 'calc(100% - 20px)',
    marginLeft: 20
  },
  hideMargin: {
    marginLeft: 'unset',
    width: '100%'
  },
});
