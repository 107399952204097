import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ExtractMaintenance from './ExtractMaintenance';
import ExtractMaintenanceProvider from './ExtractMaintenance.context';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { IExtractMaintenanceProps } from './ExtractMaintenance.properties';
import { selectors as extractMaintenanceSelectors, actions as extractMaintenanceActions } from 'ducks/extractMaintenance/extractMaintenanceTemplates';

const mapStateToProps = (state) => {
    const params = new URLSearchParams(location.search);

    return ({
        selectedTemplateId: extractMaintenanceSelectors.selected(state)?.TemplateId ?? params.get('TemplateId'),
        isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
        isExtractMaintenanceLookUpOpen: uiSelectors.isExtractMaintenanceLookUpOpen(state),
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        toggleMenuOption: uiActions.toggleMenuOption,
        searchById: extractMaintenanceActions.searchById,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((props: Readonly<
    IExtractMaintenanceProps>) => {
    return (
        <ExtractMaintenanceProvider>
            <ExtractMaintenance {...props} />
        </ExtractMaintenanceProvider>
    );
});
