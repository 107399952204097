import React from 'react';
import { Paper } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import { withStyles } from '@material-ui/core/styles';
import ISummaryPanelProperties from './SummaryPanel.properties';
import inlineStyles from './SummaryPanel.styles';
import SummaryTable from '../SummaryTable';

const SummaryPanel = <T extends object>(props: ISummaryPanelProperties<T>) => {
    const { classes, loading, schema, data, customChildren } = props;

    const loadingDataProps = React.useMemo(
        () => schema?.[0] ? ({
            [schema?.[0]?.objectName]: data,
            [schema?.[0]?.isLoading]: loading
        }) : ({}),
        [schema, data, loading]
    );

    return (
        <Paper elevation={0} className={classes.mainPanel} square={true}>
            <Scrollbars>
                <SummaryTable
                    inSummaryPanel
                    data={schema}
                    customChildren={customChildren}
                    {...loadingDataProps} />
            </Scrollbars>
        </Paper>
    );
};

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
