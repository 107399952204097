
import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncSelectors,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IPurchaseOrdersData {
  selected: any;
  summary: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IPurchaseOrdersState extends IExtendedState<IPurchaseOrdersData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      getPurchaseOrdersSummary: (params: { Supplier: number; filters: any }) => (params)
    }
  },
  'supplierPurchaseOrder');

const RequiredFields = [
  'EntityView', 'TransactionOption'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IPurchaseOrdersData>({
  selected: null,
  summary: null,
  selectedFilters: {
    EntityView: '',
    Warehouse: '',
    FinalisedOption: 'Unfinalised',
    FromDate: '',
    ToDate: ''
  },
  filterRow: {
    formName: 'SupplierPurchaseOrdersFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'FinalisedOption',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            }
          ]
        }
      },
      {
        id: 2,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'Purchase order',
      field: 'PurchaseOrder',
      minWidth: 80,
      sort: 'asc',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.PurchaseOrder) {
          return {
            PurchaseOrder: row.data.PurchaseOrder,
            FieldValue: row.data.PurchaseOrder
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/purchase-order-enquiry/order-details'
      }
    },
    { headerName: 'W/H', field: 'Warehouse', minWidth: 60, suppressSorting: true, },
    { headerName: 'Created', field: 'Created', type: 'numericColumn', minWidth: 100 },
    { headerName: 'Earliest due', field: 'Due', type: 'numericColumn', minWidth: 100, suppressSorting: true },
    { headerName: 'Type', field: 'Type', minWidth: 120, suppressSorting: true, },
    { headerName: 'Outstanding', field: 'OutstandingDisplay', type: 'numericColumn', minWidth: 90, suppressSorting: true, },
    { headerName: 'Curr', field: 'Currency', minWidth: 80, suppressSorting: true, },
    { headerName: 'Freight', field: 'Freight', minWidth: 100, suppressSorting: true, },
    { headerName: 'Local outstanding', field: 'LocalOutstandingDisplay', type: 'numericColumn', minWidth: 60, suppressSorting: true, },
    { headerName: 'Local currency', field: 'LocalCurrency', minWidth: 100, suppressSorting: true, },
    { headerName: 'Warehouse Name', field: 'WarehouseLabel', minWidth: 100, suppressSorting: true, },
    { headerName: 'Contact', field: 'ContactName', minWidth: 120, suppressSorting: true, },
    { headerName: 'Phone', field: 'Phone', minWidth: 100, suppressSorting: true, },
  ]
});

export default (state: IPurchaseOrdersState = initialState, action: IDataAction): IPurchaseOrdersState => {
  switch (action.type) {
    case types.getPurchaseOrdersSummary:
      return asyncOnRequest(state, action);
    case types.saga.getPurchaseOrdersSummary.success:
      return asyncOnSuccess(state, action, (data, successAction) => ({
        ...data,
        summary: successAction.payload
      }));
    case types.saga.getPurchaseOrdersSummary.failure:
      return asyncOnError(state, action);
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    default:
      return state;
  }
};

export const selectors = ({
  ...asyncSelectors((state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders, {
    purchaseOrdersSummary: (data: IPurchaseOrdersData) => data.summary
  }),
  loadingSummary: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.loading,
  selected: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.selected,
  gridOptions: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.gridOptions,
  columns: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.Columns,
  Actions: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.Actions,
  filterRow: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.filterRow,
  selectedFilters: (state: { supplierEnquiry: { purchasing: { purchaseOrders: IPurchaseOrdersState } } }) => state.supplierEnquiry.purchasing.purchaseOrders.data.selectedFilters
});
