import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  SuccessAction,
  asyncSelectors
} from '../../utils';

export interface IGeneralSettingsData {
  details?: any;
  schema?: any;
}

export interface IGeneralSettingsState extends IExtendedState<IGeneralSettingsData> {
}

export const { types, actions } = createActions(
  {
    asyncs: {
      getSupplierGeneralSettings: (params: { Supplier: number }) => (params)
    }
  },
  'supplierFinancialSettings');

const initialState = asyncInitialState<IGeneralSettingsData>({
  details: null,
  schema: null
});

export default (state: IGeneralSettingsState = initialState, action: IDataAction): IGeneralSettingsState => {
  switch (action.type) {
    case types.getSupplierGeneralSettings:
      return asyncOnRequest(state, action);
    case types.saga.getSupplierGeneralSettings.success:
      return asyncOnSuccess(state, action, (data: IGeneralSettingsData, successAction: SuccessAction) => {
        const result = successAction.payload;

        return {
          ...data,
          details: result && result.values,
          schema: result && result.schema,
        };
      });
    case types.saga.getSupplierGeneralSettings.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = ({
  ...asyncSelectors(
    (state: { supplierEnquiry: { settings: { general: IGeneralSettingsState } } }) => state.supplierEnquiry.settings.general,
    {
      generalSettingsDetails: (data: IGeneralSettingsData) => data.details,
      generalSettingsSchema: (data: IGeneralSettingsData) => data.schema,
    }),
  loadingDetails: (state: { supplierEnquiry: { settings: { general: IGeneralSettingsState } } }) => state.supplierEnquiry.settings.general.loading,
});
