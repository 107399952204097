import * as React from 'react';
import TextArea from '@markinson/uicomponents-v2/TextArea';

export interface ICommentAreaRendererProps {
  data: any;
}

function CommentAreaRenderer(props: ICommentAreaRendererProps): JSX.Element {
  const { data } = props;

  return (
    <TextArea
      label={data.Label || 'Comment'}
      readOnly={true}
      style={{ height: '260px' }}
      value={data.CommentFull || data.Commment || data.Comments || data.Comment}
    />
  );
}

export const MasterDetailCommentAreaRenderer = (detailProps) => <CommentAreaRenderer data={detailProps.data.row.data} />;

export default CommentAreaRenderer;
