import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as actionBarSelectors } from 'ducks/actionBar';
import { selectors as addressSelectors } from 'ducks/address';
import { selectors as contactSelectors } from 'ducks/contact';
import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as pickSlipsSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { IApplicationState } from 'ducks/reducers';

import FormNavigation from './ActionBar';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  actionBar: actionBarSelectors.actionBar(state),
  uiSelectedTab: uiSelectors.selectedTab(state),
  selectedAddress: addressSelectors.selected(state),
  selectedContact: contactSelectors.selected(state),
  selectedCustomer: customerSelectors.selected(state),
  selectedDespatchId: pickSlipsSelectors.selectedDespatchId(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeScope: uiActions.changeScope,
    changeOperationMode: operationActions.changeOperationMode
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormNavigation);
