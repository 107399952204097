import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import * as SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from 'components/common/SummaryTable';
import { IInvoiceLinesFields } from '../interfaces';

class InvoiceLines extends React.PureComponent<IInvoiceLinesFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-invoice-enquiry/invoice-lines':
          onInitialLoad('InvoiceLines');
          break;
        case '/supplier-invoice-enquiry/invoice-lines/line-details':
          onInitialLoad('LineDetails');
          break;
        case '/supplier-invoice-enquiry/invoice-lines/serial-numbers':
          onInitialLoad('SerialNumbers');
          break;
        case '/supplier-invoice-enquiry/invoice-lines/movements':
          onInitialLoad('Movements');
          break;
        case '/supplier-invoice-enquiry/invoice-lines/lot-details':
          onInitialLoad('LotDetails');
          break;
        default:
          onInitialLoad('InvoiceLines');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, selectedInvoiceLine,
      invoiceLineDetails
    } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const LineNumber = params.get('LineNumber');
    switch (selectedTab) {
      case 'LineDetails':
        return {
          initialValues:
            (selectedInvoiceLine || LineNumber) &&
            invoiceLineDetails &&
            invoiceLineDetails.values,
          valuesSchema:
            invoiceLineDetails &&
            invoiceLineDetails.schema &&
            {
              ...invoiceLineDetails.schema,
              DutyCode: {
                ...invoiceLineDetails.schema.DutyCode,
                Label: invoiceLineDetails.schema.DutyCodeRate.Display
              }
            }
        };
      default:
        return null;
    }
  }

  invoiceLineSummary = () => {
    const { invoiceLineSummary, loadingInvoiceLineSummary } = this.props;

    return <SummaryTable loadingInvoiceLineSummary={loadingInvoiceLineSummary}
      selectedInvoiceLine={(invoiceLineSummary && invoiceLineSummary.values && {
        Product: invoiceLineSummary.values.ProductId && invoiceLineSummary.values.ProductIdDisplay,
        ProductId: invoiceLineSummary.values.ProductId,
        ProductIdDisplay: invoiceLineSummary.values.ProductIdDisplay,
        ProductGroup: invoiceLineSummary.values.ProductGroup,
        Description: invoiceLineSummary.values.Description,
        SupplierProduct: invoiceLineSummary.values.SupplierProduct,
        PurchaseOrder: invoiceLineSummary.values.PurchaseOrder
      }) || {}}
      data={
        SummaryTableSchema.InvoiceLineDetails
      }
    />;
  }

  getSummaryTableRenderer = (selectedTab: any) => {
    switch (selectedTab) {
      case 'LineDetails':
        return this.invoiceLineSummary;
      default: return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          valuesSchema={valuesSchema}
          includeTabsHeight={false}
          initialValues={initialValues || {}}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer(selectedTab)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(InvoiceLines);
