import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as cashReceiptSelectors, actions as cashReceiptingActions } from 'ducks/cashReceipting/cashReceipt';
import { selectors as invoiceSelectors, actions as invoicesActions } from 'ducks/cashReceipting/invoices';

import CashReceipting from './CashReceipting';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state),
  invoiceFilters: invoiceSelectors.selectedFilters(state),
  cashReceiptContextLoading: cashReceiptSelectors.isLoading(state),
  cashReceiptContext: cashReceiptSelectors.cashReceiptContext(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    fetchCashReceiptContext: cashReceiptingActions.fetchCashReceiptContext,
    updateCashReceiptContext: cashReceiptingActions.updateCashReceiptContext,
    applyCashReceiptContext: cashReceiptingActions.applyCashReceiptContext,
    completePaymentProcess: cashReceiptingActions.cashReceiptProcessComplete,
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    lockDrawer: uiActions.lockDrawer,
    unlockDrawer: uiActions.unlockDrawer,
    closeMenu: uiActions.closeMainMenu,
    changeScope: uiActions.changeScope,
    fetchAllocatedInvoices: invoicesActions.fetchAllocatedInvoices,
    resetForm: reset
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CashReceipting);
