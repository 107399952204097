import { call, takeLatest, Effect } from 'redux-saga/effects';

import { types as productTypes, actions as productActions } from 'ducks/pme/Movements';

import { callApi } from '../utils';
import * as api from 'api/pme/movements';
import { IDataAction } from 'ducks/utils';

function* search(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.search;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  );
}

function* fetchNext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* fetchPrev(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* getDetails(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.getDetails;

  yield callApi(
    call(api.getDetails, action.data),
    success,
    failure
  );
}

function* getSummary(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = productActions.saga.getSummary;

  yield callApi(
    call(api.getSummary, action.data),
    success,
    failure
  );
}

export default function* rootProductLookUpSaga(): IterableIterator<Effect> {
  yield takeLatest(productTypes.search, search);
  yield takeLatest(productTypes.searchById, searchById);
  yield takeLatest(productTypes.fetchPrevPage, fetchPrev);
  yield takeLatest(productTypes.fetchNextPage, fetchNext);
  yield takeLatest(productTypes.getDetails, getDetails);
  yield takeLatest(productTypes.getSummary, getSummary);
}
