const SummaryTableSchema = [
    {
        objectName: 'selectedPickSlipLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Product',
                fieldNames: [
                    'ProductIdDisplay'
                ],
                valueRenderer: {
                    api: {
                        api: '/inventoryEnquiry/lookup/action',
                        name: 'enquiry',
                        params: {
                            queryFields: {
                                ProductId: 'ProductId'
                            }
                        }
                    }
                }
            },
            {
                label: 'Product Description',
                fieldNames: [
                    'ProductDescription'
                ]
            },
            {
                label: 'Product Group',
                fieldNames: [
                    'ProductGroup',
                    'ProductGroupLabel'
                ]
            },
            {
                label: 'Product Type',
                fieldNames: [
                    'ProductTypeLabel'
                ]
            },
            {
                label: 'Customer Product',
                fieldNames: [
                    'CustomerProduct'
                ]
            },
        ]
    },
    {
        objectName: 'selectedPickSlipLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Status',
                fieldNames: [
                    'Status',
                ],
            }
        ]
    },
    {
        objectName: 'selectedPickSlipLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Warehouse',
                fieldNames: [
                    'Warehouse',
                    'WarehouseDescription'
                ],
            },
            {
                label: 'Available',
                fieldNames: [
                    'AvailableQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Elsewhere',
                fieldNames: [
                    'ElsewhereQuantityDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedPickSlipLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Cost',
                fieldNames: [
                    'CostDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Base Price',
                fieldNames: [
                    'BasePriceDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'GP%',
                fieldNames: [
                    'GrossProfitDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Sell/Unit',
                fieldNames: [
                    'SellPerUnitDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
        ]
    },
    {
        objectName: 'selectedPickSlipLine',
        isLoading: 'loadingWorksaleLineDetailsSummary',
        children: [
            {
                label: 'Exchange Variation',
                fieldNames: [
                    'ExchangeVariationDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Tax Total',
                fieldNames: [
                    'TaxTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true
                }
            },
            {
                label: 'Extended Total',
                fieldNames: [
                    'ExtendedTotalDisplay'
                ],
                valueRenderer: {
                    isNumericDisplay: true,
                    emphasize: true
                }
            },
        ]
    }
];

export default SummaryTableSchema;
