import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as lotPickingSelectors, actions as lotPickingActions } from 'ducks/inventoryEnquiry/stocking/lotPicking'
import { selectors as lotSelectors } from 'ducks/inventoryEnquiry/stocking/lotDetails'
import { fetch } from 'api/inventoryEnquiry/stocking/lotPicking';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search)

export const mapStateToProps = state => ({
  gridName: 'IEStockingLotDetails',
  columnDefs: lotPickingSelectors.columns(state),
  rowData: lotPickingSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: lotPickingSelectors.gridOptions(state),
  isLoading: lotPickingSelectors.isLoading(state),
  filterRow: lotPickingSelectors.filterRow(state),
  selectedFilters: lotPickingSelectors.selectedFilters(state),
  appliedFilters: lotPickingSelectors.filterRow(state) && filterSelectors.getFilters(state, lotPickingSelectors.filterRow(state).formName),
  reqParams: (lotSelectors.selected(state) || params.get('ProductId'))
    && (lotSelectors.selected(state) || params.get('Warehouse'))
    && (lotSelectors.selected(state) || params.get('LotSerial'))
    && {
    ProductId: (lotSelectors.selected(state) && lotSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (lotSelectors.selected(state) && lotSelectors.selected(state).Warehouse) || params.get('Warehouse'),
    LotSerial: (lotSelectors.selected(state) && lotSelectors.selected(state).LotSerial) || params.get('LotSerial')
  },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: lotPickingActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
