import * as React from 'react';
import FormView from 'components/FormView';
import { FORM_NAME } from './DiaryDetails.container';
import IDiaryDetailsProperties, { IDiaryDetailsHandle, ISearchObj } from './DiaryDetails.properties';
import DiaryDetailsForm from './DiaryDetails.form';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { useCreateDiaryDetail, useRetrieveDiaryDetail, useUpdateDiary, useDeleteDiaryLines } from 'api/pickSlips/diary';
import { AddCircle as AddIcon, RemoveCircle, Edit, Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons/';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';
import { Operation } from 'utils/operations';
const fieldNames = ['UserId', 'Date', 'Time', 'Subject', 'CommentFull'];

const  DiaryDetails = (props: IDiaryDetailsProperties, ref: React.Ref<IDiaryDetailsHandle>) => {
    const { currentUserId, formValues, formSyncErrors, selectedDespatchId, dirty, operationMode, changeConfirmationDialog, changeOperationMode, resetForm } = props;
    const { showSnackBar } = useSnackBar();
    const fetchLineDetailMutation = useRetrieveDiaryDetail();
    const updateLinesMutation = useUpdateDiary();
    const deleteLinesMutation = useDeleteDiaryLines(selectedDespatchId);
    const createDiaryDetailMutation = useCreateDiaryDetail();
    const [diaryDetailsData, setDiaryDetailsData] = React.useState(null);

    const { setActionBar } = React.useContext(ActionBarContext);
    const DiaryId = new URLSearchParams(location.search).get('DiaryId');
    const [isdirty, setDirty] = React.useState(false);

    const isFormDirty = (FormValues) => {
        const result = fieldNames.reduce(
            (flag, key) => {
            if (`${diaryDetailsData.inlineObject[key]}` !== FormValues[key]) {

            return true; // Form value has changed
            }

            return flag;
            },
            false
        );
        setDirty(result);

        return result;
    };

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        [operationMode]
    );
    React.useEffect(
        () => {
            changeOperationMode(DiaryId === null ? Operation.NEW : Operation.BROWSE);
            getInitialValues();
            if (!DiaryId) {
                setDirty(false);
            }
            setActionBarButtons();
        },
        [DiaryId, diaryDetailsData?.inlineObject?.SystemGenerated]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [BACK_BUTTON],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'New',
                    Icon: AddIcon,
                    disabled: [Operation.NEW, Operation.EDIT].includes(operationMode),
                    action: 'PickSlipDiaryDetailsNew',
                },
                {
                    label: 'Edit',
                    Icon: Edit,
                    disabled: ([Operation.NEW, Operation.EDIT].includes(operationMode) || diaryDetailsData?.inlineObject?.SystemGenerated),
                    action: 'PickSlipDiaryDetailsEdit',
                },
                {
                    label: 'Delete',
                    Icon: RemoveCircle,
                    disabled: ([Operation.NEW, Operation.EDIT].includes(operationMode) || diaryDetailsData?.inlineObject?.SystemGenerated),
                    action: 'PickSlipDiaryDetailsDelete',
                },
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    disabled: ([Operation.BROWSE].includes(operationMode) || diaryDetailsData?.inlineObject?.SystemGenerated),
                    action: 'PickSlipDiaryDetailsCancel',
                },
                {
                    label: 'OK',
                    disabled: ([Operation.BROWSE].includes(operationMode) || diaryDetailsData?.inlineObject?.SystemGenerated),
                    Icon: CheckCircle,
                    action: 'PickSlipDiaryDetailsOk',
                },
            ]
        });
    };

    const PickSlipDiaryDetailsCancel = (navigateBack: () => void) => {
        if (isdirty) {
            setDirty(false);
            changeConfirmationDialog({
              open: true,
              title: 'Discard changes',
              message: 'Are you sure you want to continue?',
              okLabel: 'Discard',
              onCancel: () => {
                changeOperationMode(DiaryId ? Operation.EDIT : Operation.NEW);
                if (!DiaryId) {
                    resetForm();
                }
              },
              onOk: () => {
                changeOperationMode(Operation.BROWSE);
                if (!DiaryId) {
                    navigateBack();
                }
              }
            });
          } else {
            changeOperationMode(Operation.BROWSE);
            if (!DiaryId) navigateBack();
          }
    };

    React.useImperativeHandle(
        ref,
        () => ({
            async PickSlipDiaryDetailsOk(handleModuleChange: (SearchObj: ISearchObj) => void): Promise<void> {
                if (isValidData()) {
                    const result = (DiaryId) ?
                        await updateLinesMutation.mutateAsync({DiaryId: Number(DiaryId), Subject: formValues.Subject, CommentFull: formValues.CommentFull}) :

                        await createDiaryDetailMutation.mutateAsync({DespatchId: selectedDespatchId, ...formValues});

                    if (result.Status && result.Forms[0].DisplayType === 'StatusMessage' && result.Forms[0].MessageType === 'Success') {
                        changeOperationMode(Operation.BROWSE);
                        if (!DiaryId) {
                            handleModuleChange({search: {
                                DiaryId: result.DiaryEntry.DiaryId.Value,
                                DespatchId: selectedDespatchId
                           }});
                        }
                    }
                }
            },
            PickSlipDiaryDetailsCancel,
            PickSlipDiaryDetailsDelete,
            isChangesSavedOrDiscarded(): boolean {
                if (operationMode === Operation.BROWSE) return false;
                const isformDirty = isFormDirty(formValues);
                if (isformDirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return isformDirty;
            },
            PickSlipDiaryDetailsEdit(_navigateBack: () => void): void {
                changeOperationMode(Operation.EDIT);
            }
        }),
        [formSyncErrors, formValues, DiaryId, dirty, changeOperationMode, PickSlipDiaryDetailsCancel]
    );

    async function PickSlipDiaryDetailsDelete(navigateBack: () => void): Promise<void> {
        changeConfirmationDialog({
            open: true,
            title: 'Delete selected record',
            message: 'Are you sure you want to delete the selected diary?',
            okLabel: 'Delete',
            onCancel: () => undefined,
            onOk: async () => {
                const {Status } = await deleteLinesMutation.mutateAsync({
                    DiaryId: DiaryId
                });
                if (Status) {
                    navigateBack();
                }

                return Status;
            }
        });
    }

    function isValidData(): boolean {
        if (formSyncErrors && Object.keys(formSyncErrors).length > 0) {
          const requiredError = Object.entries(formSyncErrors).find((item) => item.includes('Required'));
          if (requiredError) {
            showSnackBar({ variant: 'warning', message: 'Please fill in required fields.' });
          } else {
            const firstError = Object.keys(formSyncErrors)[0];
            showSnackBar({ variant: 'warning', message: `Please enter valid ${firstError}` });
          }

          return false;
        }

        return true;
    }

    const getInitialValues = async () => {
        if (DiaryId) {
            const data = await fetchLineDetailMutation.mutateAsync({DiaryId: Number(DiaryId)});
            setDiaryDetailsData(data.DiaryEntry);
        } else {
            setDiaryDetailsData({inlineObject: {
            UserId: currentUserId,
            Subject: '',
            Date: moment().format('DD/MM/YYYY'),
            Time: moment().format('hh:mm A'),
            CommentFull: '',
            DiaryId: null,
            SystemGenerated: false
        }});
        }
    };

    function formFieldOnBlur(): void {
        isFormDirty(formValues);
    }

    return (
      <React.Fragment>
        <FormView
          isLoading={false}
          formName={FORM_NAME}
          schema={DiaryDetailsForm}
          includeTabsHeight={false}
          initialValues={diaryDetailsData?.inlineObject}
          operationMode={DiaryId ? operationMode : Operation.NEW}
          enableReinitialize={true}
          onBlur={formFieldOnBlur}
          valuesSchema={diaryDetailsData?.schema}
        />
      </React.Fragment>
    );
};
export default withStyles({}, { index: 1 })(React.memo(React.forwardRef(DiaryDetails)));
