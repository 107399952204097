const QuoteSummary = {
  'id': 'QuoteSummary',
  'layout': {
    'rows': 1,
    'columns': 3
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Original currency details'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted materials charge',
            'name': 'OriginalMaterialsCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted labour charge',
            'name': 'OriginalLaborCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted sundry charge',
            'name': 'OriginalSundryCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted charge sub total',
            'name': 'OriginalSubtotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Currency',
            'name': 'Currency',
            'placeholder': '',
            'size': 'small',
            'type': 'string',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax total',
            'name': 'OriginalTaxTotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
            'emphasized': true
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quote total',
            'name': 'OriginalQuoteTotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
            'emphasized': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Local currency details'
      },
      'children': [
        {
          'id': 0,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted materials charge',
            'name': 'LocalMaterialsCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted labour charge',
            'name': 'LocalLaborCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted sundry charge',
            'name': 'LocalSundryCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quoted charge sub total',
            'name': 'LocalSubtotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Exchange rate',
            'name': 'ExchangeRate',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Tax total',
            'name': 'LocalTaxTotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
            'emphasized': true
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Quote total',
            'name': 'LocalQuoteTotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
            'emphasized': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'fullWidth': true,
        'label': 'Estimated costs'
      },
      'children': [
        {
          'id': 0,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Material cost',
            'name': 'EstimatedMaterialsCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Labour cost',
            'name': 'EstimatedLaborCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Sundry cost',
            'name': 'EstimatedSundryCharge',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Cost total',
            'name': 'EstimatedTotal',
            'placeholder': '',
            'size': 'medium',
            'type': 'string',
            'alignment': 'right',
            'emphasized': true
          }
        }
      ]
    }
  ]
}
  ;
export default QuoteSummary;