import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  asyncs: {
  }
}, 'suppressions');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    {
      headerName: 'Superseded product',
      field: 'SupersededProductCode',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.ProductId) {
          return {
            ProductId: row.data.SupersededProductId,
            FieldValue: row.data.SupersededProductCode
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry/product-details'
      }
      , minWidth: 150
    },
    { headerName: 'Group', field: 'SupersededProductGroup' },
    { headerName: 'Group description', field: 'GroupDescription' },
    { headerName: 'Incl reorder', field: 'IncludeReorderDisplay' },
    { headerName: 'Active', field: 'ActiveDisplay' },
    { headerName: 'Description', field: 'Description' },
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.associations.suppressions,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.associations.suppressions.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.associations.suppressions.data.gridOptions,
  columns: state => state.inventoryEnquiry.associations.suppressions.data.Columns,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)