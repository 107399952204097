
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as customerSelectors } from 'ducks/customer';
import { selectors as contactSelectors } from 'ducks/contact';
import { selectors as addressSelectors } from 'ducks/address';
import { selectors as customerProductSelectors } from 'ducks/customerProduct';
import { selectors as marketingSelectors } from 'ducks/customerEnquiry/marketing';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { selectors as configSelectors } from 'ducks/appConfig';

import CustomerDetail from './CustomerDetails';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  selectedCustomer: customerSelectors.selected(state),
  selectedCustomerSchema: customerSelectors.selectedSchema(state),
  selectedContact: contactSelectors.selected(state),
  selectedContactSchema: contactSelectors.selectedSchema(state),
  selectedAddress: addressSelectors.selected(state),
  selectedAddressSchema: addressSelectors.selectedSchema(state),
  selectedProduct: customerProductSelectors.selected(state),
  selectedProductSchema: customerProductSelectors.selectedSchema(state),
  selectedMarketing: marketingSelectors.selected(state),
  selectedMarketingSchema: marketingSelectors.selectedSchema(state),
  selectedDiary: diarySelectors.selected(state),
  isV2: configSelectors.isV2(state),
});

export default connect(mapStateToProps, {})(CustomerDetail);
