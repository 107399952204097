import { createReducer } from 'utils/helpers';
import { PickSlipsActionTypes, IActions } from './PickSlips.actions';
import { IPriceOverrideAuthorityDialogProperties } from 'components/common/PriceOverrideAuthorityDialog/PriceOverrideAuthorityDialog.properties';
import { LostSaleDialogState } from 'components/common/LostSalesDialog/LostSalesDialog.properties';
import { ILineLostSaleExtended } from './PickSlips.properties';
import { PickSlipAPIType } from './PickSlips.hooks';
import { IBOMDialogStete } from 'components/common/BOMDialog/BOMDialog.properties';

export type IPriceOverrideDialogState<IApiType = any> = Omit<IPriceOverrideAuthorityDialogProperties<IApiType>, 'values' | 'resetForm' | 'onOk' | 'onCancel'>;
export interface IState {
    SalesOrderId?: string;
    PurchaseOrderId?: number;
    DespatchId?: number;
    LineNumber?: number;
    DespatchLineId?: number;
    ComponentLineNumber?: number;
    ComponentId?: number;
    PurchaseOrderLineId?: number;
    CommitmentLineNumber?: number;
    DespatchStatus: string;
    PriceOverrideDialog: IPriceOverrideDialogState<PickSlipAPIType>;
    lostSaleDialog: LostSaleDialogState<ILineLostSaleExtended, PickSlipAPIType>;
    multipleBomDialog?: IBOMDialogStete;
}

export const initialState: IState = {
    SalesOrderId: '',
    PurchaseOrderId: 0,
    PurchaseOrderLineId: 0,
    CommitmentLineNumber: 0,
    DespatchId: 0,
    LineNumber: 0,
    DespatchLineId: 0,
    ComponentLineNumber: 0,
    ComponentId: null,
    DespatchStatus: '',
    PriceOverrideDialog: {
        open: false,
        loading: false,
        type: null,
        data: null,
        apiType: null
    },
    lostSaleDialog: {
        open: false,
        data: null,
        loading: false,
        type: null,
        apiType: null
    },
    multipleBomDialog: {
        open: false,
        data: null,
        kitStatus: '',
        bomCode: '',
        LineNumber: 0
    }
};

type IAction = (state: IState, payload: any) => IState;

type IActionMap = Record<PickSlipsActionTypes, IAction>;

const actionMap: IActionMap = {
    [PickSlipsActionTypes.setPriceOverrideDialog]: (state: IState, payload: IState['PriceOverrideDialog']): IState => ({ ...state, PriceOverrideDialog: payload }),
    [PickSlipsActionTypes.setLostSaleDialog]: (state: IState, payload: IState['lostSaleDialog']): IState => ({ ...state, lostSaleDialog: { ...state.lostSaleDialog, ...payload } }),
    [PickSlipsActionTypes.setDespatchId]: (state: IState, payload: IState['DespatchId']): IState => ({ ...state, DespatchId: payload }),
    [PickSlipsActionTypes.setLineNumber]: (state: IState, payload: IState['LineNumber']): IState => ({ ...state, LineNumber: payload }),
    [PickSlipsActionTypes.setDespatchLineId]: (state: IState, payload: IState['DespatchLineId']): IState => ({ ...state, DespatchLineId: payload }),
    [PickSlipsActionTypes.setComponentLineNumber]: (state: IState, payload: IState['ComponentLineNumber']): IState => ({ ...state, ComponentLineNumber: payload }),
    [PickSlipsActionTypes.setComponentId]: (state: IState, payload: IState['ComponentId']): IState => ({ ...state, ComponentId: payload }),
    [PickSlipsActionTypes.setMultipleBomState]: (state: IState, payload: IState['multipleBomDialog']): IState => ({ ...state, multipleBomDialog: payload }),
};

export default createReducer<IActionMap, IState, IActions>(actionMap);
