import { IDashboardWidgetOutstandingCustomerOrderFacade, IDashboardWidgetOutstandingCustomerOrderResponse } from 'api/swaggerTypes';
import { IObjectified } from 'api/utils';
import { Api, asObjectified } from '../../baseApi';

export interface IObjectifiedOutstandingCustomerOrdersResponse {
    OutstandingCustomerOrders: IObjectified<IDashboardWidgetOutstandingCustomerOrderFacade>;
    Status?: boolean;
}

export const getOutstandingCustomerOrders = async ({ EntView, SalesEntity }): Promise<IObjectifiedOutstandingCustomerOrdersResponse> => {
    return Api.getInlineResponse<IDashboardWidgetOutstandingCustomerOrderResponse, 'OutstandingCustomerOrders'>(
        `DashboardMaintenance/DashboardWidget/OutstandingCustomerOrders/EntView/${EntView}/SalesEntity/${SalesEntity}`)
        .then((response) => {
            return {
                ...response,
                OutstandingCustomerOrders: response.OutstandingCustomerOrders ? asObjectified(response, 'OutstandingCustomerOrders') : null
            };
        });
};
