import React from 'react';
import { connect } from 'react-redux';
import KitComponents from './KitComponents';
import { actions as uiActions } from 'ducks/ui';
import { Dispatch, bindActionCreators } from 'redux';
import IKitComponentsProperties from './KitComponents.properties';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    changeValidationDialog: uiActions.changeValidationDialog,
  },
  dispatch);

export default connect(null, mapDispatchToProps)(
  (props: Omit<IKitComponentsProperties, 'classes' | 'changeConfirmationDialog' | 'changeValidationDialog'>) =>
    <KitComponents {...props} />
);
