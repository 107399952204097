import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as lineSelectors, actions as lineActions } from 'ducks/priceCheck/matrix';
import { search } from 'api/priceCheck/matrix';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

export const mapStateToProps = state => ({
  gridName: 'PCPrices',
  columnDefs: lineSelectors.columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  appliedFilters: filterSelectors.getFilters(state, 'PriceCheckFilters'),
  apiMethod: search
});

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected,
  applyFilters: filterActions.applyFilters
}
