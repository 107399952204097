import {
  createActions, asyncInitialState, asyncSelectors,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row })
}, 'ldBranchOrders');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  ldBranchOrdersActions: {
  },
  ldBranchOrdersColumns: [
    { headerName: 'Warehouse', field: 'Warehouse', suppressSorting: true, minWidth: 150 },
    {
      headerName: 'Purchase order', field: 'PurchaseOrder',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/purchase-order-enquiry'
      },
      minWidth: 150,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.PurchaseOrder) {
          return {
            ProductId: row.data.PurchaseOrder,
            FieldValue: row.data.PurchaseOrder
          };
        } else return null;
      },
    },
    { headerName: 'Resp person', field: 'ResponsiblePerson', minWidth: 150, suppressSorting: true },
    { headerName: 'Created', field: 'Created', minWidth: 150, suppressSorting: true },
    { headerName: 'Earliest due', field: 'DueDate', minWidth: 150, suppressSorting: true },
    { headerName: 'Buying total', field: 'BuyingTotalDisplay', minWidth: 150, suppressSorting: true },
    { headerName: 'Ordered', field: 'OrderedDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'On order', field: 'OnOrderDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Supplied', field: 'Supplied', type: 'numericColumn', minWidth: 150, suppressSorting: true },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true },
  ],
  selectedFilters: {
    Warehouse: '',
    Finalised: 'All'
  },
  filterRow: {
    formName: 'BranchOrderaFilters',
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'Warehouse',
          size: 'small'
        }
      },
      {
        id: 1,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Show',
          name: 'Finalised',
          defaultValue: 'All',
          required: false,
          options: [
            {
              value: 'All',
              label: 'All'
            },
            {
              value: 'Finalised',
              label: 'Finalised'
            },
            {
              value: 'Unfinalised',
              label: 'Unfinalised'
            }
          ]
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.purchaseOrderEnquiry.ldBranchOrders,
  {
    selectedOrderLine: data => data.selected,
    ldBranchOrdersActions: data => data.ldBranchOrdersActions,
  }
)

const syncSelector = {
  isLoading: state => state.purchaseOrderEnquiry.ldBranchOrders.loading,
  gridOptions: state => state.purchaseOrderEnquiry.ldBranchOrders.data.gridOptions,
  ldBranchOrdersColumns: state => state.purchaseOrderEnquiry.ldBranchOrders.data.ldBranchOrdersColumns,
  ldBranchOrdersActions: state => state.purchaseOrderEnquiry.ldBranchOrders.data.ldBranchOrdersActions,
  filterRow: state => state.purchaseOrderEnquiry.ldBranchOrders.data.filterRow,
  selectedFilters: state => state.purchaseOrderEnquiry.ldBranchOrders.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)