import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { IProductPriceSchedule } from '../interfaces';

class ProductPriceSchedule extends React.PureComponent<IProductPriceSchedule> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/supplier-product-enquiry/product-price-schedule':
          onInitialLoad('ProductPriceSchedule');
          break;
        case '/supplier-product-enquiry/product-price-schedule/details':
          onInitialLoad('ProductPriceScheduleDetails');
          break;
        case '/supplier-product-enquiry/product-price-schedule/sell-prices':
          onInitialLoad('ProductPriceScheduleSellPrices');
          break;
        case '/supplier-product-enquiry/product-price-schedule/settings':
          onInitialLoad('ProductPriceScheduleSettings');
          break;
        case '/supplier-product-enquiry/product-price-schedule/Comments':
          onInitialLoad('ProductPriceScheduleDetails');
          break;
        default:
          onInitialLoad('ProductPriceSchedule');
      }
    }
  }

  getInitialValues = () => {
    const {
      selectedTab, details, settings, comments, sellPrices
    } = this.props;
    switch (selectedTab) {
      case 'ProductPriceScheduleDetails':
        return {
          initialValues: details && { ...details.values, Breaks: details.values },
          valuesSchema: details && details.schema
        };
      case 'ProductPriceScheduleSettings':
        return {
          initialValues: settings && settings.values,
          valuesSchema: settings && settings.schema
        };
      case 'ProductPriceScheduleSellPrices':
        return {
          initialValues: sellPrices && { ...sellPrices.values, Breaks: sellPrices.values },
          valuesSchema: sellPrices && sellPrices.schema
        };
      case 'ProductPriceScheduleComments':
        return {
          initialValues: comments,
          valuesSchema: null
        };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;
    const { initialValues, valuesSchema } = this.getInitialValues() || { initialValues: null, valuesSchema: null };

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={valuesSchema}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(ProductPriceSchedule);
