import * as React from 'react';
import FormView from 'components/FormView';
import { withRouter } from 'react-router';
import { INotepadFields } from '../interfaces';

class Diary extends React.PureComponent<INotepadFields> {

  componentDidMount(): void {
    const { onInitialLoad, path } = this.props;
    const context = 'pick-slip-enquiry';
    switch (path) {
      case `/${context}/diary`:
        onInitialLoad('SalesDiary');
        break;
      case `/${context}/diary/diary-details`:
        onInitialLoad('DiaryDetails');
        break;
      default:
        onInitialLoad('SalesDiary');
    }
  }
  getInitialValues = () => {
    const {
      selectedTab, selectedDespatchId, location
    } = this.props;
    const params = (new URLSearchParams(location.search));
    const DespatchId = selectedDespatchId || params.get('DespatchId');

    switch (selectedTab) {
      case 'SalesDiary':
      case 'DiaryDetails':
        return {
          params: DespatchId && {
            DespatchId: DespatchId,
          }
        };
      default:
        return null;
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab === 'SalesDiary' ? 'Diary' : 'DiaryDetails'}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Diary);
