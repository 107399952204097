import CNLines from 'utils/data/forms/credit_notes/CNLines';
import CNDetails from 'utils/data/forms/credit_notes/CNDetails';
import CNLineDetails from 'utils/data/forms/credit_notes/CNLineDetails';
import CNPaymentDetails from 'utils/data/forms/common_components/payment-details.json';

const getFormSchema = (formId: string) => {
  switch (formId) {
    case 'CNLines':
      return CNLines;
    case 'CNDetails':
      return CNDetails;
    case 'CNLineDetails':
      return CNLineDetails;
    case 'PaymentDetails':
      return CNPaymentDetails;
    default:
      return CNLines;
  }
};

export default getFormSchema;
