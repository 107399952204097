const SummaryTableSchema = [
  {
    objectName: 'priceOverrideSummary',
    isLoading: 'loadingPriceOverrideSummary',
    children: [
      {
        label: 'Product',
        fieldNames: [
          'ProductIdDisplay',
          'ProductIdLabel'
        ],
      },
      {
        label: 'Original Price (Ex Tax)',
        fieldNames: [
          'OldPriceDisplay',
        ],
        valueRenderer: {
          isNumeric: true,
        }
      },
      {
        label: 'Original GP',
        fieldNames: [
          'OldGPDisplay'
        ],
        valueRenderer: {
          isNumeric: true
        }
      },
      {
        label: 'New Price (Ex Tax)',
        fieldNames: [
          'NewPriceDisplay'
        ],
        valueRenderer: {
          isNumeric: true
        }
      },
      {
        label: 'New GP',
        fieldNames: [
          'NewGPDisplay'
        ],
        valueRenderer: {
          isNumeric: true
        }
      },
    ]
  },
];

export default SummaryTableSchema;
