import { IFormViewForm } from 'components/FormView';

const ChangePassword: IFormViewForm = {
    id: 'ChangePasswordForm',
    layout: {
        rows: 1,
        columns: 1,
    },
    fields: [
        {
            id: '1',
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                name: 'activity',
                fullWidth: true,
                elevation: 0,
                minColumnWidth: 10
            },
            position: {
                row: 1,
                col: 1,
                colspan: 3

            },
            children: [
                {
                    id: 1,
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Current Password',
                        name: 'staffPassword',
                        size: 'medium',
                        placeholder: '',
                        required: true,
                        type: 'password',
                    }
                },
                {
                    id: 2,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        name: 'newPassword',
                        label: 'New Password',
                        placeholder: '',
                        size: 'medium',
                        required: true,
                        type: 'password',

                    }
                },
                {
                    id: 3,
                    type: 'TEXT_FIELD',
                    style: {
                        width: '50%'
                    },
                    props: {
                        label: 'Confirm Password',
                        name: 'confirmPassword',
                        placeholder: '',
                        size: 'medium',
                        type: 'password',
                        required: true,
                    }
                },
            ]
        }
    ]
};

export default ChangePassword;
