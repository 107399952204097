const options = {
    PS: [
        {
            label: 'Purchase Orders',
            value: 'PurchaseOrders',
            SelectionDisabled: true,
            children: [
                {
                    label: 'Lines',
                    value: 'Lines'
                },
                {
                    label: 'Purchase order details',
                    value: 'PurchaseOrderDetails'
                }
            ]
        }
    ],
};

export default options;
