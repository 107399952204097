import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ExtendedDisplayField from './ExtendedDisplayField';

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => bindActionCreators({

})

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedDisplayField);