import * as React from 'react';
import { ListItem, CircularProgress, List, Collapse } from '@material-ui/core';
// import { List } from '@markinson/ui-components/material';
import StarIcon from '@markinson/uicomponents-v2/SvgIcons/Star';
import ExpandLess from '@markinson/uicomponents-v2/SvgIcons/ExpandLess';
import ExpandMore from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import DragIndicator from '@markinson/uicomponents-v2/SvgIcons/DragIndicator';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import { DnDSource, SchedulerData } from '@markinson/mk.mpv4.schedulercomponent';
import * as styles from './LookUpList.scss';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as activitySchedulerActions } from 'ducks/serviceActivityScheduling/serviceActivities';
import { isNull } from 'utils/utils';

const CIRCULAR_PROGRESS_SIZE = 40;
const SCROLL_MARGIN = 2.5;

const inlineStyles = createStyles({
  lookupListingItem: {
    display: 'block',
    overflowX: 'hidden',
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    paddingLeft: '0px',
  },
  lookupListingItemNested: {
    paddingLeft: '10px'
  },
  listItemBG: {
    backgroundColor: '#BCBCBC',
    marginTop: '1px',
  },
  browseListItemBG: {
    position: 'relative',
    backgroundColor: '#CCCCCC',
    borderBottom: '1px solid #777',
    height: 'auto',
    paddingTop: '16px',
    paddingBottom: '20px',
  },
  listItemSelected: {
    backgroundColor: '#777777',
  },
  browseListItemSelected: {
    backgroundColor: '#989898'
  },
  favoriteBrowseItemIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: '35px solid #777777',
    borderLeft: '35px solid transparent',
    width: 0,
    height: 0,
    boxSizing: 'border-box'
  },
  favoriteStarIcon: {
    height: '15px',
    width: '15px',
    color: '#FFFFFF',
    position: 'absolute',
    top: -30,
    right: 2,
  },
  selectableListComponentStyle: {
    backgroundColor: '#999999',
    padding: 0,
    margin: 0
  },
  selectedItemStyle: {
    backgroundColor: '#777777',
  },
  browseSelectedItemStyle: {
    backgroundColor: '#989898',
  },
  preLoader: {

  },
  loadingItem: {
    padding: '7.5px 130px',
    height: '65px'
  },
  nestedListItem: {
    backgroundColor: '#BCBCBC',
    marginTop: '1px',
  },
  nestedBrowseListItemBG: {
    position: 'relative',
    backgroundColor: '#CCCCCC',
    borderBottom: '1px solid #777',
    height: 'auto',
    paddingTop: '16px',
    paddingBottom: '20px',
    paddingLeft: '45px'
  },
  nestedListExpansionIcon: {
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 13px)',
    cursor: 'pointer'
  },
  listItemInner: {
    display: 'grid',
    gridTemplateColumns: '25px auto',
    alignItems: 'center',
  },
});

export interface ILookUpListProperties<T extends object, U extends object> {
  classes: any;
  keyField: keyof T;
  listItems: T[];
  fieldsToDisplay: [keyof T, keyof T, keyof T, keyof T] | [keyof T, keyof T, keyof T, keyof T, keyof T, keyof T];
  cascadingListKey?: keyof T;
  cascadingListKeyField?: keyof U;
  cascadingListFields?: [keyof U, keyof U, keyof U, keyof U] | [keyof U, keyof U, keyof U, keyof U, keyof U, keyof U];
  selectedRecordId: any;
  browseLookUp: boolean;
  prevPage: number;
  nextPage: number;
  loadMore: boolean;
  loadingPrevPage: boolean;
  loadingNextPage: boolean;
  schedulerData?: SchedulerData;
  dragAndDropSource?: DnDSource;
  disableDefaultSelection?: boolean;
  disableItemSelection?: boolean;
  lastLinePosition?: 'left' | 'right';
  newSchedulerEvent?(schedulerData: SchedulerData, slotId: any, slotName: any, start: any, end: any, type: any, item: any): void;
  fetchPrevPage(): void;
  fetchNextPage(): void;
  triggerSchedulerDataChange(): void;
  handleRecordChange?(recordId: any): void;
  renderCustomItemSection?(item: any): any;
  isItemDraggable?(item: any): boolean;
  isNextItemSelectionAllowed?(nextItem: T): boolean;
}

interface ILookUpListState {
  selectedIndex: number;
  cascadingOpen?: {
    [name: number]: boolean;
  };
}

const DISPLAY_FIELD_MAIN_PRIMARY = 0;
const DISPLAY_FIELD_MAIN_SECONDARY = 1;
const DISPLAY_FIELD_DESCRIPTION_PRIMARY = 2;
const DISPLAY_FIELD_DESCRIPTION_SECONDARY = 3;
const DISPLAY_FIELD_ADDITIONAL_PRIMARY = 4;
const DISPLAY_FIELD_ADDITIONAL_SECONDARY = 5;

const ListItemInner = (props) => {
  const { item, dataKeys, renderCustomItemSection, browseLookUp, expandable, cascadingListFields, cascadingListKey, cascadingOpen, index, isDraggable, toggleCollapse, lastLinePosition } = props;

  const secondaryDescription = item[dataKeys[DISPLAY_FIELD_DESCRIPTION_SECONDARY]];
  const primaryDescriptionKey = dataKeys[DISPLAY_FIELD_DESCRIPTION_PRIMARY];
  const additionalPrimaryKey = dataKeys[DISPLAY_FIELD_ADDITIONAL_PRIMARY];
  const additionalSecondary = item[dataKeys[DISPLAY_FIELD_ADDITIONAL_SECONDARY]];
  const mainPrimary = item[dataKeys[DISPLAY_FIELD_MAIN_PRIMARY]];
  const mainSecondary = item[dataKeys[DISPLAY_FIELD_MAIN_SECONDARY]];

  const _toggleCollapse = (e) => {
    toggleCollapse(index + 1);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div style={inlineStyles.listItemInner}>
      <div>
        {isDraggable && <DragIndicator />}
      </div>
      <div>
        {(browseLookUp && (item as any).Primary) &&
          <div style={inlineStyles.favoriteBrowseItemIndicator}>
            {<StarIcon style={inlineStyles.favoriteStarIcon} />}
          </div>
        }
        <label
          className={styles.lookupListingItemTitleSection}
          title={`${mainPrimary} - ${mainSecondary}`}>
          {mainPrimary} {mainSecondary && ` - ${mainSecondary}`}
        </label>
        {item[primaryDescriptionKey] &&
          <label title={`${item[primaryDescriptionKey]}`} className={classNames({
            [styles.lookupListingItemMetaSection]: secondaryDescription,
            [styles.lookupListingItemMetaSectionSingle]: !secondaryDescription,
            [styles.lookupListingItemRow1]: secondaryDescription,
            [styles.lookupListingItemRowFull]: secondaryDescription,
            [styles.lookupListingItemUser]: primaryDescriptionKey === 'timeStamp' || primaryDescriptionKey === 'EnterDate'
          })}>
            {item[primaryDescriptionKey]}
          </label>}
        {
          secondaryDescription &&
          <label title={`${secondaryDescription}`} className={classNames(styles.lookupListingItemRow2, lastLinePosition === 'right' && styles.alignRight, {
            [styles.lookupListingItemMetaSection]: secondaryDescription,
            [styles.lookupListingItemMetaSectionSingle]: !secondaryDescription
          })}>
            {secondaryDescription}
          </label>
        }
        {
          (item[additionalPrimaryKey] || additionalSecondary) &&
          <label title={`${item[additionalPrimaryKey]}`} className={classNames({
            [styles.lookupListingItemMetaSection]: additionalSecondary,
            [styles.lookupListingItemMetaSectionSingle]: !additionalSecondary,
            [styles.lookupListingItemRow1]: additionalSecondary,
            [styles.lookupListingItemRowFull]: !additionalSecondary,
            [styles.lookupListingItemUser]: additionalPrimaryKey === 'timeStamp' || additionalPrimaryKey === 'EnterDate'
          })}>
            {item[additionalPrimaryKey]}{additionalSecondary && ` - ${additionalSecondary}`}
          </label>
        }
        {
          renderCustomItemSection && <div>
            {renderCustomItemSection(item)}
          </div>
        }
        {
          (expandable && (cascadingListFields && (item as any)[cascadingListKey] && ((cascadingOpen[index + 1])) ?
            <ExpandLess onClick={_toggleCollapse} style={inlineStyles.nestedListExpansionIcon} /> : <ExpandMore onClick={_toggleCollapse} style={inlineStyles.nestedListExpansionIcon} />))
        }
      </div>
    </div >);
};

const DraggableListItem = (props) => {

  const {
    index, className, style, itemSelectedStyle, uniqueKey, item, dragAndDropSource, schedulerData, selectedIndex,
    idProp, beginDrag, endDrag, newEvent, listItemOnClick, isDraggable, disableItemSelection
  } = props;

  const DragAndDropSource = dragAndDropSource ? dragAndDropSource.getDragSource() : null;
  const isSelectedAndNotDraggable = (selectedIndex === index) && !dragAndDropSource;

  return (
    <ListItem
      button
      disabled={disableItemSelection}
      key={item?.TemplateId || uniqueKey}
      style={isSelectedAndNotDraggable ? { ...style, ...itemSelectedStyle } : { ...style, cursor: isDraggable ? 'move' : 'pointer' }}
      onClick={(e) => listItemOnClick(e, index, item[idProp])}
      selected={isSelectedAndNotDraggable}
      classes={{ root: className }}>
      {DragAndDropSource && isDraggable ?
        <DragAndDropSource
          key={item?.TemplateId || uniqueKey}
          beginDrag={beginDrag}
          endDrag={endDrag}
          item={item}
          schedulerData={schedulerData}
          newEvent={newEvent} >
          <ListItemInner {...props} />
        </DragAndDropSource> : <ListItemInner {...props} />}
    </ListItem >
  );
};

class LookUpList<T extends object, U extends object> extends React.Component<ILookUpListProperties<T, U>, ILookUpListState> {

  scrollBars: React.RefObject<Scrollbars>;

  constructor(props: Readonly<ILookUpListProperties<T, U>>) {
    super(props);
    this.scrollBars = React.createRef<Scrollbars>();

    this.state = {
      cascadingOpen: props.cascadingListFields && { [1]: true },
      selectedIndex: props.disableDefaultSelection ? null : 0
    };
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.resize);
    this.forceUpdate();
    this.setLastSelectedRecord();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps: Readonly<ILookUpListProperties<T, U>>): void {
    const { nextPage, loadMore, loadingPrevPage, loadingNextPage, fetchNextPage, selectedRecordId } = this.props;
    const values = this.scrollBars.current.getValues();
    if (nextPage && ((values.clientHeight) === values.scrollHeight && loadMore) && (!loadingPrevPage && !loadingNextPage)) {
      fetchNextPage();
    }
    if (values.scrollTop === 0) {
      this.scrollBars.current.scrollTop(1);
    }
    if (values.clientHeight + values.scrollTop === values.scrollHeight) {
      this.scrollBars.current.scrollTop(values.scrollTop - 1);
    }
    if (selectedRecordId && prevProps.selectedRecordId !== selectedRecordId) {
      this.handleSelectedRecordIdChange();
    }
  }

  handleSelectedRecordIdChange = () => {
    const { selectedRecordId, keyField, listItems } = this.props;
    const { selectedIndex } = this.state;

    if (!isNull(selectedRecordId)) {
      const selectedRecordItemIndex = listItems.findIndex((item) => item[keyField] === selectedRecordId);

      if (selectedRecordItemIndex !== selectedIndex) {
        this.handleListItemSelectionChange(null, selectedRecordItemIndex, selectedRecordId);
      }
    }

  }

  setLastSelectedRecord = () => {
    const { selectedRecordId = '', listItems = [], keyField, disableDefaultSelection } = this.props;
    const lastSelectedRecordIndex = !isNull(selectedRecordId) ?
      listItems.findIndex((item) => item[keyField] === selectedRecordId) : disableDefaultSelection ? null : 0;

    this.setState({ selectedIndex: lastSelectedRecordIndex });
  }
  resize = () => {
    this.forceUpdate();
  }

  handleListItemSelectionChange = (_event: React.ChangeEvent, idx: number, id: any) => {
    const { handleRecordChange, isNextItemSelectionAllowed, listItems } = this.props;

    if (isNextItemSelectionAllowed && !isNextItemSelectionAllowed(listItems[idx])) {
      return;
    }

    this.setState({ selectedIndex: idx });
    if (handleRecordChange) {
      handleRecordChange(id);
    }
  };

  isOverflown = () => {
    const element = this.scrollBars;
    if (element.current) {
      return element.current.getScrollHeight() > element.current.getClientHeight();
    }

    return false;
  }

  handleScroll = () => {
    const { loadingPrevPage, loadingNextPage, fetchPrevPage, fetchNextPage } = this.props;
    if (!loadingPrevPage && !loadingNextPage) {
      const { clientHeight, scrollTop, scrollHeight } = this.scrollBars.current.getValues();
      if ((clientHeight + scrollTop + SCROLL_MARGIN) >= scrollHeight && fetchNextPage) {
        fetchNextPage();
      } else if (scrollTop === 0 && fetchPrevPage) {
        fetchPrevPage();
      }
    }
  }

  toggleCollapse = (index: number): void => {
    if (this.state.cascadingOpen) {
      this.setState({ cascadingOpen: { ...this.state.cascadingOpen, [index]: !this.state.cascadingOpen[index] } });
    }
  }

  beginDrag = (props: any) => {
    const { schedulerData, triggerSchedulerDataChange } = this.props;

    if (props.item && props.item.ActivityStatus === 'C') return; //item is already completed

    const draggedActivityCapabilities = (props.item && props.item.Capabilities) ? props.item.Capabilities : [];
    const matchedRows = schedulerData.resources.filter((r) => draggedActivityCapabilities.every((c) => r.Capabilities.includes(c)));
    const unmatchedRows = schedulerData.resources.filter((value) => !matchedRows.includes(value));

    const frozenRowsData = [];

    schedulerData.resources.forEach((r) => {
      if (unmatchedRows.some((row) => row.id === r.id)) {
        frozenRowsData.push({
          slotId: r.id,
          freeze: true,
          freezeMessage: 'Capability requirement not met.'
        });
      }
    });

    schedulerData.setFrozenRows(frozenRowsData);
    triggerSchedulerDataChange();
  }

  endDrag = (_: any, monitor: any) => {
    const { schedulerData } = this.props;

    if (monitor.getDropResult) {
      const dropResult = monitor.getDropResult();
      if (dropResult && schedulerData.resources.some((d) => d.id === dropResult.slotId)) return;
    }
    this.unfreezeResources();
  }

  unfreezeResources = () => {
    const { schedulerData, triggerSchedulerDataChange } = this.props;
    schedulerData.setFrozenRows([]);
    triggerSchedulerDataChange();
  }

  newEvent = (schedulerData: SchedulerData, slotId: any, slotName: any, start: any, end: any, type: any, item: any) => {
    const { newSchedulerEvent } = this.props;
    const droppedSlot = schedulerData.renderData.find((d) => d.slotId === slotId);
    const firstFrozenHeaderItem = droppedSlot.headerItems.find((col) => col.start === start);
    const frozen = droppedSlot.freeze ? true : firstFrozenHeaderItem && firstFrozenHeaderItem.freeze;
    if (!frozen) {
      newSchedulerEvent(schedulerData, slotId, slotName, start, end, type, item);
    }
    this.unfreezeResources();
  }

  renderListChildList = (props: { children: U[]; index: number }): React.ReactElement<any> => {
    const { children, index } = props;
    const { browseLookUp, cascadingListKeyField, cascadingListFields, classes, isItemDraggable } = this.props;
    const open = this.state.cascadingOpen[index + 1];

    return (
      <Collapse in={open}
        timeout='auto'
        key={`nestedList-collapse-${index}.${index + 1}`}>
        <List
          component='nav'
          style={{
            padding: 0
          }}
          className={styles.lookupListingList}
          onChange={() => this.handleListItemSelectionChange}
          key={`nestedList-${index}`}
        >
          {children && children.map((item, i) => {
            return (
              <DraggableListItem
                {...this.props}
                {...this.state}
                key={i}
                index={i}
                isDraggable={isItemDraggable && isItemDraggable(item)}
                uniqueKey={`nested-${index}-${i + 1}`}
                item={item}
                className={`${classes.lookupListingItemNested} ${classes.lookupListingItem}`}
                style={browseLookUp ? inlineStyles.nestedBrowseListItemBG : inlineStyles.nestedListItem}
                itemSelectedStyle={browseLookUp ? inlineStyles.browseListItemSelected : inlineStyles.listItemSelected}
                idProp={cascadingListKeyField}
                dataKeys={cascadingListFields}
                beginDrag={this.beginDrag}
                endDrag={this.endDrag}
                newEvent={this.newEvent}
                listItemOnClick={(e, idx, id) => { this.handleListItemSelectionChange(e, idx, id); }}
              />
            );
          })}
        </List>
      </Collapse>);
  }

  render(): React.ReactNode {
    const {
      listItems, browseLookUp, loadingPrevPage, loadingNextPage, classes,
      cascadingListFields, cascadingListKey, fieldsToDisplay, keyField, isItemDraggable
    } = this.props;

    const ChildList = this.renderListChildList;

    return (
      <Scrollbars
        className={styles.scrollbarStyle}
        ref={this.scrollBars}
        onScroll={this.handleScroll}
      >
        <List
          component='nav'
          style={{
            padding: 0,
            paddingRight: this.isOverflown() ? '10px' : 0
          }}
          className={styles.lookupListingList}>
          {loadingPrevPage && <ListItem
            button={!cascadingListFields}
            className={styles.lookupListingItem}
            style={inlineStyles.loadingItem}
            disabled>
            <div className={styles.loadingItem}>
              <CircularProgress size={CIRCULAR_PROGRESS_SIZE} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
            </div>
          </ListItem>
          }

          {listItems && listItems.map((item, i) => {
            const isExpandable = cascadingListFields && !isNull(item[cascadingListKey]);

            return (
              <React.Fragment key={`wrapper-${i}`}>
                <DraggableListItem
                  {...this.props}
                  {...this.state}
                  index={i}
                  uniqueKey={i + 1}
                  item={item}
                  isDraggable={isItemDraggable && isItemDraggable(item)}
                  expandable={isExpandable}
                  className={classes.lookupListingItem}
                  style={browseLookUp ? inlineStyles.browseListItemBG : inlineStyles.listItemBG}
                  itemSelectedStyle={browseLookUp ? inlineStyles.browseListItemSelected : inlineStyles.listItemSelected}
                  idProp={keyField}
                  dataKeys={fieldsToDisplay}
                  toggleCollapse={this.toggleCollapse}
                  beginDrag={this.beginDrag}
                  endDrag={this.endDrag}
                  newEvent={this.newEvent}
                  listItemOnClick={(e, idx, id) => {
                    this.handleListItemSelectionChange(e, idx, id);
                  }}
                />
                {cascadingListFields && item[cascadingListKey] &&
                  <ChildList
                    key={`child${i + 1}`}
                    index={i}
                    children={item[cascadingListKey] as any as U[]} />}
              </React.Fragment>

            );
          })}
          {loadingNextPage && <ListItem
            button
            className={styles.lookupListingItem}
            style={inlineStyles.loadingItem}
            disabled>
            <div className={styles.loadingItem}>
              <CircularProgress size={CIRCULAR_PROGRESS_SIZE} style={inlineStyles.preLoader} color={'secondary'} variant={'indeterminate'} />
            </div>
          </ListItem>
          }
        </List>
      </Scrollbars>
    );
  }

}
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    triggerSchedulerDataChange: activitySchedulerActions.triggerSchedulerDataChange,
  },
  dispatch);

// Does not handle generics - so just go to the any type for now.
export default connect(null, mapDispatchToProps)(withStyles(inlineStyles, { index: 1 })(LookUpList)) as any;
