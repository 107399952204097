import * as React from 'react';
import ActionBar from 'components/common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/index';
import SupplierInvoiceSearchLookUpDrawer from 'components/common/SearchLookUpDrawer/Containers/SupplierInvoice';
import SummaryPanel from './SummaryPanel';
import { shallowCompare } from 'utils/utils';
import { withRouter } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import styles from './SupplierInvoiceEnquiry.scss';
import options from './OptionItems.json';
import { OPERATIONS } from 'utils/operations';
import { Route } from 'react-router';
import InvoiceComponent from './InvoiceComponent/index';
import Events from './Events/index';
import Diary from './Diary/index';
import InvoiceLines from './InvoiceLines/index';
import { ISupplierInvoiceEnquiryProps } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  salesInvoiceEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class SupplierInvoiceEnquiryView extends React.Component<ISupplierInvoiceEnquiryProps> {

  handleModuleChange = (selectedTab: any) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'supplier-invoice-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(OPERATIONS.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'supplier-invoice-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const lineNumber = params.get('LineNumber');
    const DocumentNumber = params.get('DocumentNumber');
    const DiaryId = params.get('DiaryId');

    return ({
      InvoiceDetails: { pathname: `/${context}/invoice-details`, search: `?DocumentNumber=${DocumentNumber}`, component: InvoiceComponent },
      Events: { pathname: `/${context}/events`, search: `?DocumentNumber=${DocumentNumber}`, component: Events },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?DocumentNumber=${DocumentNumber}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?DocumentNumber=${DocumentNumber}&DiaryId=${DiaryId}`, component: Diary },
      Comments: { pathname: `/${context}/comments`, search: `?DocumentNumber=${DocumentNumber}`, component: InvoiceComponent },
      InvoiceLines: { pathname: `/${context}/invoice-lines`, search: `?DocumentNumber=${DocumentNumber}`, component: InvoiceLines },
      LineDetails: { pathname: `/${context}/invoice-lines/line-details`, search: `?DocumentNumber=${DocumentNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      LotDetails: { pathname: `/${context}/invoice-lines/lot-details`, search: `?DocumentNumber=${DocumentNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      SerialNumbers: { pathname: `/${context}/invoice-lines/serial-numbers`, search: `?DocumentNumber=${DocumentNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      Movements: { pathname: `/${context}/invoice-lines/movements`, search: `?DocumentNumber=${DocumentNumber}&LineNumber=${lineNumber}`, component: InvoiceLines },
      null: { pathname: `/${context}/`, search: null, component: InvoiceComponent, },
    });
  }

  handleInvoiceChange = () => {
    const { selectedTab } = this.props;
    this.fetchChildRecords(selectedTab);
    this.getInvoiceSummary();
  }

  fetchChildRecords = (selectedTab: any) => {
    switch (selectedTab) {
      case 'InvoiceDetails':
        this.getInvoiceDetails();
        break;
      case 'Comments':
        this.getInvoiceComment();
        break;
      case 'LineDetails':
        this.getInvoiceLineDetails();
        break;
      case 'LotDetails':
        this.getInvoiceLineLotDetails();
        break;
      case 'SerialNumbers':
        this.getInvoiceLineSerialNumbers();
        break;
      case 'Movements':
        this.getInvoiceLineMovements();
        break;
      default:
    }
  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(OPERATIONS.BROWSE);
  }

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: any): void {
    const { operationMode, selectedTab, selectedInvoice, invoiceSummary } = this.props;
    if (operationMode === OPERATIONS.BACK) {
      this.backAction();
    }
    if (selectedTab && prevProps.selectedTab !== selectedTab) {
      this.handleModuleChange(selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('supplier-invoice-enquiry', selectedTab);
    }
    if (selectedInvoice && !invoiceSummary) {
      this.getInvoiceSummary();
    }
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'InvoiceDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('InvoiceDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  }

  getInvoiceLineLotDetails = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const DocumentNumber = (selectedInvoice && selectedInvoice.DocumentNumber) || Number(params.get('DocumentNumber'));
    const LineNumber = (selectedInvoiceLine && selectedInvoiceLine.LineNumber) || Number(params.get('LineNumber'));
    if (DocumentNumber && LineNumber) {
      this.props.fetchInvoiceLinesLotDetails({ DocumentNumber, LineNumber });
    }
  }

  getInvoiceLineSerialNumbers = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const DocumentNumber = (selectedInvoice && selectedInvoice.DocumentNumber) || Number(params.get('DocumentNumber'));
    const LineNumber = (selectedInvoiceLine && selectedInvoiceLine.LineNumber) || Number(params.get('LineNumber'));
    if (DocumentNumber && LineNumber) {
      this.props.fetchInvoiceLinesSerialNumbers({ DocumentNumber, LineNumber });
    }
  }

  getInvoiceLineMovements = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const DocumentNumber = (selectedInvoice && selectedInvoice.DocumentNumber) || Number(params.get('DocumentNumber'));
    const LineNumber = (selectedInvoiceLine && selectedInvoiceLine.LineNumber) || Number(params.get('LineNumber'));
    if (DocumentNumber && LineNumber) {
      this.props.fetchInvoiceLinesMovements({ DocumentNumber, LineNumber });
    }
  }

  getInvoiceLineDetails = () => {
    const { selectedInvoice, selectedInvoiceLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const DocumentNumber = (selectedInvoice && selectedInvoice.DocumentNumber) || Number(params.get('DocumentNumber'));
    const LineNumber = (selectedInvoice && selectedInvoiceLine && selectedInvoiceLine.LineNumber) || Number(params.get('LineNumber'));
    if (DocumentNumber) {
      this.props.getInvoiceLineDetails({ DocumentNumber, LineNumber });
      this.props.getInvoiceLineSummary({ DocumentNumber, LineNumber });
    }
  }

  getInvoiceDetails = () => {
    if (this.props.selectedInvoice) {
      const { DocumentNumber } = this.props.selectedInvoice;
      this.props.getInvoiceDetails({ DocumentNumber });
      this.props.getInvoiceSummary({ DocumentNumber });
    }
  }

  getInvoiceSummary = () => {
    if (this.props.selectedInvoice) {
      const { DocumentNumber } = this.props.selectedInvoice;
      this.props.getInvoiceSummary({ DocumentNumber });
    }
  }

  getInvoiceComment = () => {
    if (this.props.selectedInvoice) {
      const { DocumentNumber } = this.props.selectedInvoice;
      this.props.getInvoiceSummary({ DocumentNumber });
      this.props.getInvoiceComment({ DocumentNumber });
    }
  }

  getValuesSchema = () => {
    const { selectedTab, invoiceDetails } = this.props;
    switch (selectedTab) {
      case 'InvoiceDetails':
        return invoiceDetails && invoiceDetails.schema;
      default:
        return null;
    }
  }

  getOptionMenuItems = () => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
      case 'LotDetails':
      case 'SerialNumbers':
      case 'Movements': return options.InvoiceLines;
      default: return options.InvoiceDetails;
    }
  }
  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const schema = this.getValuesSchema();
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.customerEnquiryOuter}>
        <div className={styles.customerEnquiryContainer}>
          <SupplierInvoiceSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.handleInvoiceChange
            ]}
          />
          <div
            style={inlineStyles.salesInvoiceEnquirySection}
            className={styles.customerEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Supplier Invoice Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} valuesSchema={schema} />;
                }}
              />
            ))}

          </div>

        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuItems()}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'supplier-invoice-enquiry'} />
      </div>
    );
  }
}

export default withRouter(SupplierInvoiceEnquiryView);
