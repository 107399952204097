/* APIRoutes
* Use create/retrieve/update/delete keywords for api prop name and follow the module screen hierarchy.
* if same api is used for multiple operations, define it once with the prop name in short form
*  (e.g cu, cd,cud, crud)
*/

export const APIRoutes = {
  worksale: {
    apply: {
      worksaleCriteria: 'SalesProcessing/ApplyWorksaleContext'
    },
    retrieve: {
      currentWorksale: 'SalesProcessing/CurrentWorksale',
      currentWorksaleSummary: 'SalesProcessing/CurrentWorksale/Summary'
    },
    delete: {
      currentWorksale: 'SalesProcessing/CurrentWorksale/Delete',
      line: 'SalesProcessing/WorksaleLine/:WorkSaleLineId/Delete'
    },
    search: {
      calculate: 'SalesProcessing/CalculateWorksaleContext',
      retrievePeriod: 'CustomTypes/Lookup/ActiveModulePeriod/Search'
    },
    product: {
      validate: 'SalesProcessing/ValidateSalesProduct'
    },
    recallQuote: {
      retrieve: 'Quotes/SearchCustomerQuotes',
      recall: 'SalesProcessing/Worksale/:WorksaleId/RecallQuote',
    },
    quote: {
      confirmation: 'SalesProcessing/Worksale/:WorksaleId/QuoteConfirmation',
      save: 'SalesProcessing/Worksale/:WorksaleId/SaveAsQuote'
    },
    salesEnquiry: {
      save: 'SalesProcessing/Worksale/:WorksaleId/SaveAsSalesEnquiry'
    },
    salesOrder: {
      confirmation: 'SalesProcessing/Worksale/:WorksaleId/SalesOrderConfirmation'
    },
    sale: {
      confirmation: 'SalesProcessing/Worksale/:WorksaleId/SaleConfirmation'
    },
    worksale: {
      confirmation: 'SalesProcessing/Worksale/:WorksaleId/WorksaleConfirmation',
    },
    parksale: {
      confirmation: 'SalesProcessing/Worksale/:WorksaleId/ParksaleConfirmation'
    },
    payment: {
      information: 'SalesProcessing/Worksale/:WorksaleId/PaymentInformation'
    },
    initialize: {
      worksaleLostSale: 'SalesProcessing/Worksale/:WorksaleId/InitialiseWorksaleLostsale',
      worksaleLineLostSale: 'SalesProcessing/Worksale/:WorksaleId/worksaleline/:WorksaleLineId/InitialiseWorksalelineLostsale'
    },
    process: {
      saleConfirmation: 'SalesProcessing/Worksale/:WorksaleId/ProcessSalesInvoice',
      orderConfirmation: 'SalesProcessing/Worksale/:WorksaleId/ProcessSalesOrder'
    },
    internaleTransfer: {
      transmit: 'SalesProcessing/SalesOrder/:SalesOrder/PurchaseOrder/:PurchaseOrder/Transmit'
    },
    recallParkedSales: {
      retrieve: 'SalesProcessing/RetrieveParkedSales',
      recallSale: 'SalesProcessing/Worksale/RecallParkedSale'
    },
    recallOutstandingOrders: {
      retrieve: 'SalesProcessing/RecallOrder/SearchOutstandingCustomerOrders',
      create: 'SalesProcessing/RecallOrder/:SalesOrder'
    },
    lines: {
      calculateGridLine: 'SalesProcessing/Worksale/:WorksaleId/GridLine/:WorksalelineId/CalculateGridLineDetails',
      calculateFastLine: 'SalesProcessing/Worksale/:WorksaleId/CalculateFastLineDetails',
      insert: 'SalesProcessing/Worksale/:WorksaleId/CreateFastLine',
      retrieve: 'SalesProcessing/Worksale/:WorksaleId/Worksalelines',
      totalCount: 'SalesProcessing/Worksale/:WorksaleId/Worksalelines',
      update: 'SalesProcessing/Worksale/:WorksaleId/GridLine/:WorksalelineId/UpdateGridLineDetails',
      worksale: {
        sites: 'CustomTypes/Lookup/WorksaleSiteCode/Search'
      }
    },
    lostSale: {
      create: 'SalesProcessing/Worksale/:WorksaleId/CreateLostSale'
    },
    fulfillment: {
      initialise: {
        columns: 'SalesProcessing/Worksale/:worksaleId/InitialiseFulfillment'
      },
      retrieve: 'SalesProcessing/Worksale/:WorksaleId/Delivery/:WorksaledelivId/GetFulfillment',
      internalTransfer: 'SalesProcessing/Worksale/:WorksaleId/Fulfillment/InternalTransfer/:FromWarehouse',
      directSupply: 'SalesProcessing/Worksale/:WorksaleId/Fulfillment/DirectSupply/:FromWarehouse',
      deliverySummary: 'SalesProcessing/WorksaleDeliv/:WorksaledelivId/FulfillmentLine/:LineNumber/DeliverySummary'
    }
  },
  image: {
    retrieve: '/Image/:ImageId'
  },
  workSaleLine: {
    details: {
      fetch: 'SalesProcessing/Worksaleline/:WorksalelineId',
      update: 'SalesProcessing/Worksale/:WorksaleId/Worksaleline/:WorksalelineId/UpdateWorksalelineDetail',
      calculate: 'SalesProcessing/Worksale/:WorksaleId/Worksaleline/:WorksalelineId/CalculateWorksalelineDetail',
      cancel: 'SalesProcessing/Worksaleline/:WorksalelineId/CancelLineDetails'
    },
    compnents: {
      load: 'SalesProcessing/Worksaleline/:WorksalelineId/LoadComponents'
    }
  },
  deliveries: {
    entries: {
      retrieve: 'SalesProcessing/Worksale/:WorkSaleId/RetrieveWorksaleDeliveries'
    },
    default: {
      worksale: 'SalesProcessing/Worksale/:WorkSaleId/UpdateDefaultWorksaleDelivery',
      retrieve: 'SalesProcessing/Worksale/:WorkSaleId/RetrieveDeliveryDefaults'
    },
    delete: {
      worksaleDelivery: 'SalesProcessing/WorksaleDeliv/:WorksaleDeliveryId'
    },
    update: {
      worksaleDelivery: 'SalesProcessing/WorksaleDelivery/:SiteCode/UpdateDelivery',
      changeDelivery: 'SalesProcessing/Worksale/:WorksaleId/ChangeDelivery'
    },
    create: {
      worksaleDelivery: 'SalesProcessing/Worksale/:WorksaleId/CreateDelivery'
    },
    calculate: {
      deliveryDetails: 'SalesProcessing/Worksale/:WorksaleId/CalculateDeliveryDetails'
    }
  },
  orderDetails: {
    entries: {
      retrieve: 'SalesProcessing/WorksaleDetails'
    },
    update: {
      worksaleOrderDetails: 'SalesProcessing/Worksale/:WorksaleId/UpdateWorksaleDetails'
    },
  },
  productCatalogue: {
    entries: {
      retrieve: 'SalesProcessing/Catalogue/:CatalogueId/RetrieveCatalogueEntries',
      details: {
        retrieve: 'SalesProcessing/Catalogue/:CatalogueId',
      },
      images: {
        retrieve: 'SalesProcessing/Catalogue/:CatalogueId/RetrieveImages',
      },
      search: {
        retrieve: 'SalesProcessing/Catalogue/SearchEntries',
      },
      products: {
        retrieve: 'SalesProcessing/Worksale/:WorksaleId/Catalogue/:CatalogueId/RetrieveCatalogueProducts',
        images: {
          retrieve: 'SalesProcessing/CatalogueProduct/:CatalogueProductId/RetrieveImages',
        },
        product: {
          details: {
            retrieve: 'SalesProcessing/Worksale/:WorksaleId/CatalogueProduct/:CatalogueProductId',
          }
        }
      },
      initialize: {
        retrieve: 'SalesProcessing/Catalogue/InitialiseProductCatalogue',
        currentWorksale: 'SalesProcessing/CurrentWorksale'
      }
    },
    addSale: {
      retrieve: 'SalesProcessing/Worksale/:WorksaleId/CalculateFastLineDetails',
      create: 'SalesProcessing/Worksale/:WorksaleId/CreateFastLine'
    }
  },
  backorderCommitment: {
    outstandingLines: {
      retrieve: 'SalesProcessing/CommitmentOrder/:OrderNumber/OutstandingLines'
    },
    linesfilters: {
      retrieve: 'Filters/GetFilterOptionsList'
    },
    documentDetails: {
      retrieve: 'SalesOrderEnquiry/SalesOrder/:SalesOrderId/DocumentDetails',
    },
    outstandingDocuments: {
      retrieve: 'SalesProcessing/CommitmentOrder/:OrderNumber/LineNumber/:LineNumber/OutstandingDocuments',
      update: 'SalesProcessing/CommitmentOrder/:OrderNumber/LineNumber/:LineNumber/UpdateCommitment',
      retrieveSummary: 'SalesProcessing/CommitmentOrder/:OrderNumber/LineNumber/:LineNumber/Summary'
    },
    purchaseOrderContext: {
      retrieve: 'SalesProcessing/CommitmentOrder/:OrderNumber/PurchaseOrderContext',
      calculate: 'SalesProcessing/CommitmentOrder/CalculatePurchaseOrderContext',
      create: 'SalesProcessing/CommitmentOrder/:OrderNumber/ApplyPurchaseOrderContext',
      cancel: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/Cancel'
    },
    PurchaseOrderSummary: {
      retrieveSummary: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/PurchaseOrderSummary'
    },
    purchaseOrderLines: {
      retrieve: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/PurchaseOrderLines'
    },
    PurchaseOrderLineDetails: {
      retrieve: 'SalesProcessing/CommitmentOrder/PurchaseOrderLine/:PurchaseOrderLineId',
      calculate: 'SalesProcessing/CommitmentOrder/PurchaseOrderLine/:PurchaseOrderLineId/CalculateLineDetail',
      update: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/PurchaseOrderLine/:PurchaseOrderLineId/UpdateLine'
    },
    purchaseOrderGrid: {
      calculate: 'SalesProcessing/CommitmentOrder/PurchaseOrderLine/:PurchaseOrderLineId/CalculateGridLineDetail',
      delete: 'SalesProcessing/CommitmentOrder/PurchaseOrderLine/:PurchaseOrderLineId',
      update: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/PurchaseOrderLine/:PurchaseOrderLineId/UpdateGridLine'
    },
    purchaseOrderDetail: {
      retrieve: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/OrderDetail',
      update: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/UpdateOrderDetail',
      calculate: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/CalculateOrderDetail',
      process: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/ProcessPurchaseOrder'
    },
    PurchaseOrderDeliveryDetails: {
      retrieveDelivery: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/DeliveryDetail',
      calculateDelivery: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/CalculateDeliveryDetail',
      updateDelivery: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/UpdateDeliveryDetail',
    },
    processPurchaseOrder: {
      process: 'SalesProcessing/CommitmentOrder/:PurchaseOrderId/PurchaseOrderConfirmation'
    },
    commit: {
      create: 'SalesProcessing/CommitmentOrder/:OrderNumber/Commit'
    }
  },
  kitComponents: {
    line: {
      retrieve: 'SalesProcessing/Worksaleline/:WorksalelineId/Components',
      create: 'SalesProcessing/Worksaleline/:WorksalelineId/CreateComponentLine',
      update: 'SalesProcessing/Worksaleline/:WorksalelineId/Component/:ComponentId/UpdateComponentGridLine',
      delete: 'SalesProcessing/Component/:ComponentId',
      kit: {
        summary: 'SalesProcessing/WorksaleLine/:WorksalelineId/KitSummary'
      }
    },
    calculate: {
      gridLineDetails: 'SalesProcessing/Worksaleline/:WorksalelineId/Component/:ComponentId/CalculateComponentGridLineDetails',
      fastLineDetails: 'SalesProcessing/Worksaleline/:WorksalelineId/Component/CalculateComponentFastLineDetails'
    },
    details: {
      retrieve: 'SalesProcessing/Component/:ComponentId',
      update: 'SalesProcessing/Worksaleline/:WorksalelineId/Component/:ComponentId/UpdateComponentDetail',
      calculate: {
        componentLineDetail: 'SalesProcessing/Worksaleline/:WorksalelineId/Component/:ComponentId/CalculateComponentLineDetail',
      }
    }
  }
};
