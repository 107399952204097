import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import { withRouter } from 'react-router-dom';
import { IMainSalesOrderEnquiryProperties } from '../interfaces';

class MainSalesOrderEnquiry extends React.Component<IMainSalesOrderEnquiryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/order-details':
          onInitialLoad('OrderDetails');
          break;
        case '/sales-order-enquiry/comment':
          onInitialLoad('Comment');
          break;
        default:
          onInitialLoad('OrderDetails');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedOrder, orderDetails } = this.props;

    return selectedOrder && orderDetails && orderDetails.values;
  }

  getValueSchema = (): any => {
    const { selectedOrder, orderDetails } = this.props;

    return selectedOrder && orderDetails && orderDetails.schema;
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, notifications } = this.props;

    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValueSchema();

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={notifications}
          entity='SalesOrderEnquiry'
        />
        <FormView
          isLoading={isLoading}
          formName='SalesOrderEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          valuesSchema={valuesSchema}
          initialValues={initialValues}
          operationMode={operationMode}
          enableReinitialize={true}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MainSalesOrderEnquiry);
