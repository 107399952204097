import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, reset, initialize } from 'redux-form';
import FreightCharges from './FreightChargesDialog';
import { IApplicationState } from 'ducks/reducers';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';

const params = new URLSearchParams(location.search);
const FORM_NAME = 'FreightChargesForm';
const mapStateToProps = (state: IApplicationState) => ({
    selectedDespatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    dirty: isDirty(FORM_NAME)(state),
    values: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        initializeForm: (values) => initialize(FORM_NAME, values),
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FreightCharges);
