import {
  IPaymentInformationFacade,
  IPaymentDetailsResponse,
  IPaymentDetailFacade,
  IUpdatePaymentLineDetailFacade,
  IProcessPaymentDetailsResponse,
  IPaymentLineFacade,
  IPaymentLineDetailsResponse,
  IPaymentAmountResponse,
  ICalculatePaymentFacade
} from 'api/swaggerTypes';
import { Api, toObjectified, toObjectifiedArray } from 'api/baseApi';
import { Inline, IObjectified, AsInline, AsObjectified } from 'api/utils';

export type InlinePaymentDetail = Inline<Omit<IPaymentDetailFacade, 'Context' | 'Header' | 'NewLine' | 'Lines'>> & {
  Context: Inline<IPaymentDetailFacade['Context']>;
  Header: Inline<IPaymentDetailFacade['Header']>;
  NewLine: Inline<IPaymentDetailFacade['NewLine']>;
  Lines: Inline<IPaymentLineFacade>[];
};

export type InlinePaymentDetailsResponse = Omit<IPaymentDetailsResponse, 'PaymentDetail'> & {
  PaymentDetail: InlinePaymentDetail;
};

export type ObjectifiedPaymentDetail = IObjectified<Omit<IPaymentDetailFacade, 'Context' | 'Header' | 'NewLine' | 'Lines'>> & {
  Context: IObjectified<IPaymentDetailFacade['Context']>;
  Header: IObjectified<IPaymentDetailFacade['Header']>;
  NewLine: IObjectified<IPaymentDetailFacade['NewLine']>;
  Lines: IObjectified<IPaymentLineFacade>[];
};

export type ObjectifiedPaymentDetailsResponse = Omit<IPaymentDetailsResponse, 'PaymentDetail'> & {
  PaymentDetail: ObjectifiedPaymentDetail;
};

export type InlinePaymentAmountResponse = AsInline<IPaymentAmountResponse, 'PaymentAmount'>;

export type ObjectifiedPaymentAmountResponse = AsObjectified<IPaymentAmountResponse, 'PaymentAmount'>;

function parseInlinePaymentDetailsResponse(response: InlinePaymentDetailsResponse): ObjectifiedPaymentDetailsResponse {
  if (!response.PaymentDetail) {
    return {
      ...response,
      PaymentDetail: undefined
    };
  }

  const { Context, Header, NewLine, Lines, ...PaymentDetail } = response.PaymentDetail;

  return {
    ...response,
    PaymentDetail: {
      ...toObjectified(PaymentDetail),
      Context: toObjectified(Context),
      Header: toObjectified(Header),
      NewLine: toObjectified(NewLine),
      Lines: toObjectifiedArray(Lines),
    }
  };
}

export async function initializePayment(query: IPaymentInformationFacade): Promise<ObjectifiedPaymentDetailsResponse> {
  return Api.postInline<IPaymentInformationFacade, InlinePaymentDetailsResponse>('Payment/InitializePayment', query)
    .then(parseInlinePaymentDetailsResponse);
}

export async function addNewPaymentLine(query: IPaymentDetailFacade): Promise<ObjectifiedPaymentDetailsResponse> {

  return Api.postInline<IPaymentDetailFacade, InlinePaymentDetailsResponse>('Payment/AddNewPaymentLine', query)
    .then(parseInlinePaymentDetailsResponse);
}

export type InlinePaymentLineDetailsResponse = AsInline<IPaymentLineDetailsResponse, 'PaymentLineDetail'>;

export type ObjectifiedPaymentLineDetailsResponse = AsObjectified<IPaymentLineDetailsResponse, 'PaymentLineDetail'>;

export async function calculatePaymentLineDetails(query: IUpdatePaymentLineDetailFacade, ChangedField: string): Promise<ObjectifiedPaymentLineDetailsResponse> {
  const params = new URLSearchParams('');
  params.set('ChangedField', ChangedField);

  return Api.postInline<IUpdatePaymentLineDetailFacade, InlinePaymentLineDetailsResponse>(`Payment/CalculateLineDetails?${params.toString()}`, query)
    .then((response) => {
      if (!response.PaymentLineDetail) {
        return {
          ...response,
          PaymentLineDetail: undefined
        };
      }

      const { PaymentLineDetail, ...rest } = response;

      return {
        ...rest,
        PaymentLineDetail: toObjectified(PaymentLineDetail)
      };
    });
}

export async function calculatePaymentAmount(query: ICalculatePaymentFacade): Promise<ObjectifiedPaymentAmountResponse> {

  return Api.postInline<ICalculatePaymentFacade, InlinePaymentAmountResponse>('Payment/CalculatePaymentAmount', query)
    .then((response) => {
      if (!response.PaymentAmount) {
        return {
          ...response,
          PaymentAmount: undefined
        };
      }

      const { PaymentAmount, ...rest } = response;

      return {
        ...rest,
        PaymentAmount: toObjectified(PaymentAmount)
      };
    });
}

export async function updatePaymentLineDetails(query: IUpdatePaymentLineDetailFacade): Promise<ObjectifiedPaymentDetailsResponse> {
  const { UpdateLine } = query;
  const isIntegrated = UpdateLine.PaymentCode === 'IEFT';

  return Api.post<IUpdatePaymentLineDetailFacade, InlinePaymentDetailsResponse>(`Payment/UpdateLineDetails?ProcessIntegratedEftpos=${isIntegrated}`, query)
    .then(parseInlinePaymentDetailsResponse);
}

export async function deletePaymentLine(query: IPaymentDetailFacade, PositionNumber: number): Promise<ObjectifiedPaymentDetailsResponse> {
  return Api.post<IPaymentDetailFacade, InlinePaymentDetailsResponse>(`Payment/PositionNumber/${PositionNumber}/DeletePaymentLine`, query)
    .then(parseInlinePaymentDetailsResponse);
}

export async function processPayment(query: IPaymentDetailFacade): Promise<IProcessPaymentDetailsResponse> {
  const params = new URLSearchParams('');
  params.set('responseMetadataFormat', 'None');

  return Api.post<IPaymentDetailFacade, IPaymentDetailsResponse>(`Payment/ProcessPayment?${params.toString()}`, query);
}
