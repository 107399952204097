import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { actions as formActions } from 'ducks/form'
import { selectors as lineSelectors, actions as lineActions } from 'ducks/supplierEnquiry/supplierTransactions'
import { selectors as supplierSelector } from 'ducks/supplierEnquiry/supplier'
import { search } from 'api/supplierEnquiry/supplierTransactions'
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'

const params = new URLSearchParams(location.search)

export const mapStateToProps = (state) => ({
  gridName: 'SupplierEnquiryTransactions',
  columnDefs: lineSelectors.lineColumns(state),
  rowData: lineSelectors.linesData(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), // to trigger update size based on drawer state change
  gridOptions: lineSelectors.gridOptions(state),
  isLoading: lineSelectors.isLoading(state),
  filterRow: lineSelectors.filterRow(state),
  selectedFilters: lineSelectors.selectedFilters(state),
  appliedFilters: lineSelectors.filterRow(state) && filterSelectors.getFilters(state, lineSelectors.filterRow(state).formName),
  reqParams: (supplierSelector.selected(state) || params.get('Supplier'))
    &&
    {
      Supplier: (supplierSelector.selected(state) && supplierSelector.selected(state).Supplier) || params.get('Supplier')
    },
  keyFields: [{valueField: 'TransactionNumber', routeField: 'TransactionNumber'}],
  apiMethod: search
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: lineActions.setSelected,
  applyFilters: filterActions.applyFilters
}
