import React, { useState } from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import WarehouseSelectionDialogContent from './WarehouseSelectionDialogContent';
import { IWarehouseSelectionDialogProps } from './WarehouseSelectionDialog.properties';
import { IActionItem } from '../Modals/FormViewModal.properties';

const WarehouseSelectionDialog = (props: IWarehouseSelectionDialogProps) => {
    const { open, loading, ProductId, onCancel, onOk, title = 'Warehouse Selection', actions = null, onWarehouseSelectionChanged } = props;

    const [selectedWarehouse, setSelectedWarehouse] = useState<string>('');

    const onWarehouseSelection = async (rowsData) => {
        const rowData = rowsData.selectedRowsData ? rowsData.selectedRowsData[0] : {};
        setSelectedWarehouse(rowData?.Warehouse);
        if (onWarehouseSelectionChanged && rowData) {
            onWarehouseSelectionChanged(rowData.Warehouse);
        }
    };

    const onRowDblClick = () => {
        if (onOk) {
            onOk(selectedWarehouse);
        }
    };

    const defaultActions: IActionItem[] = [
        {
            title: 'Cancel',
            iconName: 'Cancel',
            listener: () => {
                if (onCancel) {
                    onCancel();
                }
            },
        },
        {
            title: 'Ok',
            iconName: 'CheckCircle',
            listener: () => {
                if (onOk) {
                    onOk(selectedWarehouse);
                }
            },
        }
    ];

    return (
        <FormViewModal
            open={open}
            title={title}
            loading={loading}
            modalContent={
                < WarehouseSelectionDialogContent
                    ProductId={ProductId}
                    onRowDblClick={onRowDblClick}
                    onWarehouseSelectionChanged={onWarehouseSelection}
                />
            }
            actions={actions || defaultActions}
            dialogActionsButtons={actions !== null}
        />
    );
};

export default WarehouseSelectionDialog;
