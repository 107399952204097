import * as React from 'react';
import { IParksaleConfirmationProps } from './ParksaleConfirmation.properties';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import ParksaleConfirmationForm from './ParksaleConfirmation.form';
import { useFetchParksaleConfirmation } from 'api/Worksale/worksale';
import { useSPContextSelector } from '../Worksale.context';
import Selectors from '../Worksale.selectors';

const ParksaleConfirmation = (props: IParksaleConfirmationProps): JSX.Element => {
  const { open, values, onOk: onApply, onCancel } = props;
  const worksaleId = useSPContextSelector(Selectors.WorksaleId) || -1;
  const { data: response } = useFetchParksaleConfirmation(worksaleId);
  const noteFieldRef = React.useRef<HTMLTextAreaElement>();

  const initialValues = React.useMemo(
    () => ({
      ...response?.InquiryConfirmation?.inlineObject
    }),
    [response]
  );

  const valuesSchema = React.useMemo(() => (response?.InquiryConfirmation?.schema), [response]);

  const confirmationActions = (): IActionItem[] => {
    return [
      {
        iconName: 'Cancel',
        title: 'Cancel',
        listener: () => {
          onCancel();
        }
      }
      ,
      {
        title: 'Save Enquiry',
        iconName: 'SaveIcon',
        listener: () => {
          onApply({ ...values });
        }
      },
      {
        title: 'Park Sale',
        iconName: 'ParkSaleIcon',
        listener: () => {
          onApply({ ...values, IsParkedSale: true });
        },
        isDefault: true
      }];
  };

  const focusNoteField = () => {
    noteFieldRef?.current?.focus();
  };

  React.useEffect(
    () => {
      if (open && noteFieldRef.current) {
        focusNoteField();
      }
    },
    [open, noteFieldRef.current]
  );

  return (<FormViewModal
    open={open}
    loading={false}
    title={'Park Sale Confirmation'}
    formProps={{
      style: {
        height: '450px',
        minWidth: '500px'
      },
      formSchema: ParksaleConfirmationForm,
      initialValues: {
        ...initialValues
      },
      valuesSchema: valuesSchema ? valuesSchema : {},
      customerId: 1,
      fieldExtensions: {
        Note: {
          fieldRef: (Ref) => noteFieldRef.current = Ref
        }
      }
    }}
    actions={confirmationActions()}
    dialogActionsButtons={true}
    dialogActionsShadow={false}
    onActionsTabOut={focusNoteField}
    onActionShiftTabOut={focusNoteField}
  />);
};

export default ParksaleConfirmation;
