import { fetchGet, objectify } from './../utils'

export const getComponentDetails = (query) => {
  const { SalesOrder, LineNumber } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Line/${LineNumber}/ComponentDetails`)
    .then((result) => {
      if (result) {
        const objectified = objectify(result.SalesOrderComponentDetails);
        return { schema: result.SalesOrderComponentDetails, values: { ...objectified } }
      }
      else
        return null;
    })
}

export const getComponentSummary = (query) => {
  const { SalesOrder, LineNumber } = query;
  return fetchGet(`SalesOrderEnquiry/SalesOrder/${SalesOrder}/Line/${LineNumber}/ComponentSummary`)
    .then((result) => {
      if (result) {
        const objectified = objectify(result.SalesOrderComponentSummary);
        return { schema: result.SalesOrderComponentSummary, values: { ...objectified } }
      }
      else
        return null;
    })
}
