import * as React from 'react';
import FormView from '../../FormView/index';
import { withRouter } from 'react-router';
import { ISalesContactProps } from '../interfaces';

class Contacts extends React.PureComponent<ISalesContactProps> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/customer-maintenance/sales-diary':
          onInitialLoad('SalesDiary');
          break;
        case '/customer-maintenance/sales-diary/diary-details':
          onInitialLoad('DiaryDetails');
          break;
        default:
          onInitialLoad('SalesDiary');
      }
    }
  }

  getInitialValues = (): any => {
    const { selectedCustomer, location } = this.props;
    const params = new URLSearchParams(location.search);
    const CustomerId = selectedCustomer && selectedCustomer.CustomerId || parseInt(params.get('CustomerId'));

    return {
      params: CustomerId
        ? {
          CustomerId
        }
        : {}
    };
  }

  render(): React.ReactNode {
    const { selectedForm, operationMode, selectedTab } = this.props;
    const initialValues = this.getInitialValues();

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={(selectedTab === 'SalesDiary' && 'Diary') || selectedTab}
          includeTabsHeight={true}
          schema={selectedForm}
          browseLookUpOpen={true}
          initialValues={initialValues}
          operationMode={operationMode}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Contacts);
