import { RouteComponentProps } from 'react-router-dom';
import { IWorksaleGridLineDetailSearchResponse, IWorksaleGridLineDetailFacade, IWorksaleGridLineDetailsResponse, IErrorResponse, IWorksaleFastLineDetailsResponse, IWorksaleCriteriaFacade, IFormInfo, ISaveAsQuoteDetailsResponse, IRecallQuoteFacade, IMessageModelSearchResponse, IWorksaleConfirmationDetailsResponse, ISaleConfirmationFacade, ISalesOrderConfirmationFacade, IProcessSalesOrderDetailsResponse, IProcessSalesInvoiceDetailsResponse, IPaymentInformationDetailsResponse, IInquiryConfirmationFacade, ILostsaleFacade, ILineLostsaleFacade, ICalculateFastLineCriteriaFacade } from 'api/swaggerTypes';
import { ObjectifiedWorksaleDetailsResponse, ObjectifiedWorkSaleSummaryDetailsResponse, ObjectifiedQuoteConfirmationDetailsResponse, CorrectedQuoteConfirmationFacade, ObjectifiedSaleConfirmationDetailsResponse, ObjectifiedSalesOrderConfirmationDetailsResponse, ObjectifiedInquiryConfirmationDetailsResponse, IObjectifiedWorksaleGridLineDetailsResponseResponse, IObjectifiedWorksalelineLostsaleResponse, IObjectifiedWorksaleLostsaleResponse } from 'api/Worksale/worksale';
import { Operation } from 'utils/operations';
import { ILookupResponse } from 'api/customType';
import { IConfirmationDialogProperties } from 'components/common/ConfirmationDialog/ConfirmationDialog';
import { IInformationDialogProperties } from 'components/common/InformationDialog/InformationDialog';
export default interface IWorksaleProperties extends RouteComponentProps {
    changeOperationMode(mode: Operation): void;
    getFormSchema(form: { context: string; formId: string }): void;
    changeSelectedForm(context: string, formId: string): void;

    onFetch(): Promise<ObjectifiedWorksaleDetailsResponse>;
    onFetchSummary(): Promise<ObjectifiedWorkSaleSummaryDetailsResponse>;
    onApply(query: IWorksaleCriteriaFacade): Promise<ObjectifiedWorkSaleSummaryDetailsResponse>;
    onDelete(createLostsale: boolean, data?: ILostsaleFacade): Promise<{ Status?: boolean; Forms?: IFormInfo[] }>;
    onFetchLines(worksaleId: number, batchPage?: number, batchSize?: number, filter?: any): Promise<IWorksaleGridLineDetailSearchResponse>;
    onFetchTotalLinesCount(WorksaleId: number): Promise<number>;
    onInsertLine(worksaleId: number, line: IWorksaleGridLineDetailFacade): Promise<IObjectifiedWorksaleGridLineDetailsResponseResponse>;
    onUpdateLine(worksaleId: number, line: IWorksaleGridLineDetailFacade): Promise<IObjectifiedWorksaleGridLineDetailsResponseResponse>;
    onRemoveLine(worksaleId: number, workSaleLineId: number, createLostsale?: boolean, data?: ILineLostsaleFacade): Promise<IErrorResponse>;
    onCalculateFastEntryDetails(WorksaleId: number, query: ICalculateFastLineCriteriaFacade): Promise<IWorksaleFastLineDetailsResponse>;
    onFetchWorksaleSites(WorksaleId: number): Promise<ILookupResponse>;
    onCalculateGridLineDetails(WorksaleId: number, line: IWorksaleGridLineDetailFacade, changedField: keyof IWorksaleGridLineDetailFacade): Promise<IWorksaleGridLineDetailsResponse>;
    onFetchQuoteConfirmation(WorksaleId: number): Promise<ObjectifiedQuoteConfirmationDetailsResponse>;
    onFetchParksaleConfirmation(WorksaleId: number): Promise<ObjectifiedInquiryConfirmationDetailsResponse>;
    onRecallQuote(WorksaleId: number, query: IRecallQuoteFacade): Promise<IMessageModelSearchResponse>;
    onSaveAsQuote(WorksaleId: number, query: CorrectedQuoteConfirmationFacade): Promise<ISaveAsQuoteDetailsResponse>;
    onSaveAsSalesEnquiry(WorksaleId: number, query: IInquiryConfirmationFacade): Promise<ISaveAsQuoteDetailsResponse>;
    onFetchWorksaleConfirmation(WorksaleId: number): Promise<IWorksaleConfirmationDetailsResponse>;
    onFetchSalesOrderConfirmation(WorksaleId: number): Promise<ObjectifiedSalesOrderConfirmationDetailsResponse>;
    onProcessSalesOrderConfirmation(WorksaleId: number, reserveSuppliedQuantity: boolean, ignoreWarning: boolean, query: ISalesOrderConfirmationFacade): Promise<IProcessSalesOrderDetailsResponse>;
    onFetchSaleConfirmation(WorksaleId: number): Promise<ObjectifiedSaleConfirmationDetailsResponse>;
    onProcessSaleConfirmation(WorksaleId: number, ignoreWarning: boolean, query: ISaleConfirmationFacade): Promise<IProcessSalesInvoiceDetailsResponse>;
    onFetchPaymentInformation(WorksaleId: number): Promise<IPaymentInformationDetailsResponse>;
    onInitializeWorksaleLostSale(WorksaleId: number): Promise<IObjectifiedWorksaleLostsaleResponse>;
    onInitializeWorksaleLineLostSale(WorksaleId: number, WorksaleLineId: number): Promise<IObjectifiedWorksalelineLostsaleResponse>;
    changeConfirmationDialog?(options: IConfirmationDialogProperties): void;
    changeInformationDialog?(options: IInformationDialogProperties): void;
}

export enum PriceOverrideAuthRequestedScreen {
    FastLineEntry = 'FastLineEntry',
    WorksaleLineGrid = 'WorksaleLineGrid',
    WorksaleLineDetails = 'WorksaleLineDetails'
}
export interface ILostSalesData {
    HyperlinkReference?: string;
    Comment?: string;
    CustomerId?: number;
    CustomerPO?: string;
    FailedPinAttempts?: number;
    LostsaleUserId?: number;
    LostType?: string;
    Pin?: string;
    Reference?: string;
    Cost?: number;
    Price?: number;
    ProductId?: number;
    QuantityLost?: number;
    WorksaleId?: number;
    WorksaleLineId?: number;
    FromLineNumber?: number;
    LineLostsale?: any;
}
