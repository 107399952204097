import { combineReducers } from 'redux';

import purchaseOrder from './purchaseOrder';
import purchaseOrderLine from './purchaseOrderLine';
import branchOrders from './branchOrders';
import ldBranchOrders from './ldBranchOrders';
import committedSaleOrder from './committedSaleOrder';
import productionOrder from './committedProductionOrder';
import serviceOrders from './committedServiceOrders';
import ldCommittedSalesOrder from './ldCommittedSalesOrder';
import ldCommittedProductionOrder from './ldCommittedProductionOrder';
import ldCommittedServiceOrder from './ldCommittedServiceOrder';
import productReceipts from './productReceipts';
import purchaseOrderLineDetails from './purchaseOrderLineDetails';
import inTransitLocalShipments from './inTransitLocalShipments'
import inTransitImportShipments from './inTransitImportShipments'
import ldInTransitLocalShipments from './ldInTransitLocalShipments'
import ldInTransitImportShipments from './ldInTransitImportShipments'

const purchaseOrderEnquiryReducer = combineReducers({
  purchaseOrder,
  purchaseOrderLine,
  branchOrders,
  productionOrder,
  serviceOrders,
  ldCommittedSalesOrder,
  ldCommittedProductionOrder,
  ldCommittedServiceOrder,
  productReceipts,
  purchaseOrderLineDetails,
  committedSaleOrder,
  inTransitImportShipments,
  inTransitLocalShipments,
  ldInTransitLocalShipments,
  ldInTransitImportShipments,
  ldBranchOrders,
});

export default purchaseOrderEnquiryReducer;
