import * as React from 'react';
import { Field } from 'redux-form';
import renderComponent from '../../../FormView/RenderComponent';

const required = (value) => {
  return (value || typeof value === 'number' ? undefined : 'Required');
};

const renderField = (fieldData, stateAndProps) => {
  const { childFieldSchema, childRowId } = fieldData;
  const { valuesSchema, operationMode, onBlur, values, readOnly, isLoading } = stateAndProps;
  const { props } = childFieldSchema;

  if (values && values.EntityView && (props.lookupName === 'SalesEntity' || props.lookupName === 'WarehouseEntity')) {
    props.params = { ParentView: values.EntityView };
  }

  let isRequired = !!props.required;
  let isReadOnly = readOnly;

  if (valuesSchema && valuesSchema[props.name]) {
    const objVal = valuesSchema[props.name];
    isRequired = isRequired || (objVal && objVal.Required);
    isReadOnly = isReadOnly || (objVal && objVal.ReadOnly);
  }
  const validations = isRequired ? [required] : null;

  return (
    <div key={`wrapper-${childRowId}`} style={props.style}>
      <Field
        key={childRowId}
        name={props.name}
        component={renderComponent[childFieldSchema.type]}
        label={props.label}
        props={childFieldSchema.type === 'EX_LOOKUP_FIELD' ? { ...props, isLoading } : props}
        readOnly={isReadOnly || props.readOnly}
        validate={validations}
        schema={valuesSchema}
        operationMode={operationMode}
        onBlur={onBlur}
        {...props}
      />
    </div>
  );
};

export default (renderField)
