import {
  createActions, asyncInitialState,
  IDataAction,
  IExtendedState,
  asyncOnRequest,
  asyncOnSuccess,
  asyncOnError,
  SuccessAction,
  asyncSelectors
} from '../../utils';

import { FormViewField } from 'components/FormView';
import { IDataGridOptions, IColDef } from 'components/common/DataGridDevEx/DataGrid.properties';

export interface IRFCData {
  selected: any;
  selectedFilters: any;
  filterRow: {
    formName: string;
    parameters: FormViewField[];
    validate(values: any): any;
  };
  summary?: any;
  gridOptions: IDataGridOptions;
  Columns: IColDef[];
  Actions: any;
}

export interface IRFCState extends IExtendedState<IRFCData> {
}

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      getRFCSummary: (params: { Supplier: number }) => (params)
    }
  },
  'rfc');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
};

const initialState = asyncInitialState<IRFCData>({
  selected: null,
  summary: null,
  selectedFilters: {
    EntityView: '',
    Warehouse: '',
    StatusOption: 0,
    TypeOption: 0,
    FromDate: '',
    ToDate: '',
  },
  filterRow: {
    formName: 'SupplierRFCFilters',
    validate: (values) => {
      const errors = {};
      RequiredFields.forEach((item) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      });

      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Type',
          name: 'TypeOption',
          defaultValue: 0,
          required: false,
          options: [
            {
              value: 0,
              label: 'All'
            },
            {
              value: 1,
              label: 'Credit'
            },
            {
              value: 2,
              label: 'Replacement'
            },
            {
              value: 3,
              label: 'Overcharge'
            }
          ]
        }
      },
      {
        id: 3,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Status',
          name: 'StatusOption',
          defaultValue: 0,
          required: false,
          options: [
            {
              value: 0,
              label: 'All'
            },
            {
              value: 1,
              label: 'Preparing'
            },
            {
              value: 2,
              label: 'Processed'
            },
            {
              value: 3,
              label: 'Finalised'
            }
          ]
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'Date range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      }
    ] as FormViewField[]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
  },
  Actions: {
  },
  Columns: [
    {
      headerName: 'RFC',
      field: 'RFC',
      minWidth: 120,
      sort: 'asc',
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.RFC) {
          return {
            RFCNumber: row.data.RFC,
            FieldValue: row.data.RFC
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/rfc-enquiry/details'
      }
    },
    { headerName: 'W/H', field: 'Warehosue', minWidth: 120, suppressSorting: true, },
    { headerName: 'Entered', field: 'Entered', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    {
      headerName: 'Invoice',
      field: 'Invoice',
      minWidth: 120,
      suppressSorting: true,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.Invoice) {
          return {
            DocumentNumber: row.data.InvoiceDocumentNumber,
            FieldValue: row.data.Invoice
          };
        } else {
          return null;
        }
      },
      type: 'numericColumn',
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/supplier-invoice-enquiry/invoice-details'
      }
    },
    { headerName: 'Reference', field: 'Reference', minWidth: 150, suppressSorting: true, },
    { headerName: 'RFC total', field: 'RFCTotalDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Currency', field: 'Currency', minWidth: 120, suppressSorting: true, },
    { headerName: 'Status', field: 'Status', minWidth: 150, suppressSorting: true, },
    {
      headerName: 'Name', field: 'Name', minWidth: 120
    },
    { headerName: 'Credit note', field: 'CreditNote', minWidth: 150, suppressSorting: true, },
  ]
});

export default (state: IRFCState = initialState, action: IDataAction): IRFCState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    case types.setEntityView:
      const entityView = action.data;

      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.getRFCSummary:
      return asyncOnRequest(state, action);
    case types.saga.getRFCSummary.success:
      return asyncOnSuccess(state, action, (data: IRFCData, successAction: SuccessAction) => {
        return {
          ...data,
          summary: successAction.payload
        };
      });
    case types.saga.getRFCSummary.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

export const selectors = ({
  ...asyncSelectors(
    (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc,
    {
      summary: (data: IRFCData) => data.summary,
    }),
  loadingSummary: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.loading,
  selected: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.selected,
  gridOptions: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.gridOptions,
  columns: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.Columns,
  Actions: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.Actions,
  filterRow: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.filterRow,
  selectedFilters: (state: { supplierEnquiry: { activity: { rfc: IRFCState } } }) => state.supplierEnquiry.activity.rfc.data.selectedFilters
});
