import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as supplierTypes, actions as supplierActions } from 'ducks/supplierEnquiry/userFields';

import { callApi } from '../utils';
import * as api from 'api/supplierEnquiry/userFields';


function* search(action){
  const {success, failure} = supplierActions.saga.fetch;
  yield callApi(
    call(api.getSupplierUserFields, action.data),
    success,
    failure
  )
}


export default function* rootSupplierUserFieldsSaga(){
  yield takeLatest(supplierTypes.fetch, search)
}