import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../utils'

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    clear: () => null,
    setEntityView: (defaultView) => defaultView,
    asyncs: {
      fetch: (Data) => Data,
    }
  }, 'backorders');

const RequiredFields = [
  'EntityView'
];

const isNull = (value) => {
  return value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

const initialState = asyncInitialState({
  selected: null,
  selectedFilters: {
    EntityView: '',
    Entity: '',
    OrderType: '0',
    FromDate: '',
    ToDate: '',
    SupplierPO: ''
  },
  filterRow: {
    formName: 'SupplierBackordersFilters',
    validate: (values) => {
      let errors = {};
      RequiredFields.forEach((item, index) => {
        if (isNull(values[item])) {
          errors[item] = 'Required';
        }
      })
      return errors;
    },
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity View',
          name: 'EntityView',
          lookupName: 'EntityView',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Entity',
          name: 'Entity',
          lookupName: 'SalesEntity',
          size: 'small',
          required: false
        }
      },
      {
        id: 2,
        type: 'SELECT_FIELD',
        props: {
          label: 'Order Type',
          name: 'OrderType',
          required: false,
          options: [
            {
              value: '0',
              label: 'All'
            },
            {
              value: '1',
              label: 'Sales orders only'
            },
            {
              value: '2',
              label: 'Service orders only'
            }
          ]
        }
      },
      {
        id: 3,
        type: 'DATE_FIELD',
        props: {
          label: 'Ship range from',
          name: 'FromDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 4,
        type: 'DATE_FIELD',
        props: {
          label: 'To',
          name: 'ToDate',
          required: false,
          dateFormat: 'DD/MM/YYYY',
          placeholder: 'DD/MM/YYYY',
          nullable: 'true',
          size: 'small',
          style: {
            display: 'inline-block',
            width: '155px',
            marginRight: '16px'
          }
        }
      },
      {
        id: 5,
        type: 'TEXT_FIELD',
        props: {
          label: 'Supplier P/O',
          name: 'SupplierPO',
          size: 'medium',
          required: false
        }
      },
    ]
  },
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    {
      headerName: 'Product',
      valueGetter: (row) => {
        if (row.data && row.data.ProductCode) {
          return {
            ProductId: row.data.ProductId,
            SearchText: row.data.ProductCode
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/inventory-enquiry'
      },
      suppressSorting: true,
      minWidth: 150,
    },
    {
      headerName: 'Sales order',
      field: 'SalesOrder',
      valueGetter: (row) => {
        if (row.data && row.data.SalesOrder) {
          return {
            SalesOrder: row.data.SalesOrder,
            SearchText: row.data.SalesOrder
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/sales-order-enquiry/order-details'
      },
      minWidth: 150,
    },
    { headerName: 'Type', field: 'OrderType', suppressSorting: true, minWidth: 120, },
    { headerName: 'Entity', field: 'SalesEntity', suppressSorting: true, minWidth: 100, },
    { headerName: 'Ordered', field: 'OrderedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'On backorder', field: 'BackorderQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Supplier P/O', field: 'SupplierPO', suppressSorting: true, minWidth: 150, },
    { headerName: 'Ship date', field: 'ShipDate', suppressSorting: true, minWidth: 120, type: 'numericColumn', },
    { headerName: 'Expiry date', field: 'ExpiryDate', suppressSorting: true, minWidth: 150, type: 'numericColumn', },
    { headerName: 'Description', field: 'Description', minWidth: 350, }
  ],
  rowBuffer: 999,
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setEntityView:
      const entityView = action.data;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            EntityView: entityView
          }
        }
      };
    case types.fetch:
      return asyncOnRequest(state, action)
    case types.saga.fetch.success:
      return asyncOnSuccess(state, action, (data, action) => {
        const linesData = action.payload;
        return {
          ...data,
          Data: linesData
        }
      })
    case types.saga.fetch.failure:
      return asyncOnError(state, action)

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.customerEnquiry.backorders,
  {
    // Data: data => data.Data,
    selected: (data) => data.selected,
    Actions: (data) => data.Actions,
  }
)

const syncSelector = {
  Data: state => state.supplierEnquiry.backorders.data.Data,
  isLoading: state => state.supplierEnquiry.backorders.loading,
  gridOptions: state => state.supplierEnquiry.backorders.data.gridOptions,
  Columns: state => state.supplierEnquiry.backorders.data.Columns,
  Actions: state => state.supplierEnquiry.backorders.data.Actions,
  filterRow: state => state.supplierEnquiry.backorders.data.filterRow,
  selectedFilters: state => state.supplierEnquiry.backorders.data.selectedFilters
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)