import { call } from 'redux-saga/effects';
import {takeLatest } from 'redux-saga/effects';

import { types as ProductDetailTypes, actions as ProductDetailActions } from 'ducks/inventoryEnquiry/productDetails';

import { callApi } from '../utils';
import * as api from 'api/inventoryEnquiry/productDetails';


function* fetch(action){
  const {success, failure} = ProductDetailActions.saga.fetch;

  yield callApi(
    call(api.fetch, action.data),
    success,
    failure
  )
}

function* fetchSummary(action) {
  const { success, failure } = ProductDetailActions.saga.fetchSummary;
  yield callApi(
    call(api.fetchSummary, action.data),
    success,
    failure
  )
}

export default function* rootCustomerSaga(){
  yield takeLatest(ProductDetailTypes.fetch, fetch)
  yield takeLatest(ProductDetailTypes.fetchSummary, fetchSummary)
}