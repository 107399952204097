const Details = {
  'id': 'Details',
  'layout': {
    'rows': 1,
    'columns': 3
  },
  'fields': [
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 1
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'label': ''
      },
      'children': [
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product',
            'name': 'ProductCode',
            'placeholder': '',
            'size': 'medium'
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Description',
            'name': 'ProductDescription',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 3,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Product price code',
            'name': 'ProductPriceCode',
            'placeholder': '',
            'size': 'small',
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Available',
            'name': 'AvailableQuantityDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Customer',
            'name': 'CustomerIdDisplay',
            'placeholder': '',
            'size': 'small'
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'Name',
            'name': 'Name',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 7,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'City',
            'name': 'City',
            'size': 'large',
            'required': false,
            'disabled': true
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'props': {
            'label': 'State',
            'name': 'State',
            'size': 'small',
            'required': false,
            'disabled': true
          }
        }
      ]
    },
    {
      'id': 1,
      'position': {
        'row': 1,
        'col': 2
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'label': ''
      },
      'children': [
        {
          'id': 1,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Base price',
            'name': 'BasePriceDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Retail price',
            'name': 'RetailPriceDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Previous base',
            'name': 'PreviousBasePriceDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 4,
          'type': 'EXTENDED_TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Previous retail',
            'name': 'PreviousRetailPriceDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 5,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Last price change',
            'name': 'LastPriceChangeDate',
            'size': 'small',
          }
        },
        {
          'id': 6,
          'type': 'TOGGLE_FIELD',
          'props': {
            'label': 'Price schedules on file?',
            'name': 'IsAvailablePriceSchedule',
            'required': false
          }
        }
      ]
    },
    {
      'id': 2,
      'position': {
        'row': 1,
        'col': 3
      },
      'type': 'PAPER_CONTAINER',
      'visible': true,
      'props': {
        'label': ''
      },
      'children': [
        {
          'id': 1,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Average cost',
            'name': 'AverageCostDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 2,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Last cost',
            'name': 'LastCostDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right',
          }
        },
        {
          'id': 3,
          'type': 'TOGGLE_FIELD',
          'props': {
            'label': 'Product breaks?',
            'name': 'IsAvailableProductBreaks',
            'required': false
          }
        },
        {
          'id': 4,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Next break at',
            'name': 'NextBreakDisplay',
            'placeholder': '',
            'size': 'medium',
            'alignment': 'right'
          }
        },
        {
          'id': 6,
          'type': 'TEXT_FIELD',
          'visible': true,
          'props': {
            'label': 'Surcharge code',
            'name': 'SurchargeCode',
            'placeholder': '',
            'size': 'medium',
          }
        }
      ]
    }
  ]
}
export default Details
