import { fetchPost, objectify, objectifyAddress, fetchGet } from '../utils'
export const search = (query) => {
  const { SearchText, Sort, BatchPage } = query
  const respType = 'Inline'
  return fetchPost('/SalesInvoiceEnquiry/SearchInvoices', { SearchText, Sort, BatchPage }, null, respType)
    .then((result) => (result && result.SalesInvoices && {
      invoices: result.SalesInvoices.map((val) => ({ ...objectify(val), key: `${val.SalesEntity.Value}-${val.Invoice.Value}` })),
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1,
    }) || { invoices: [] })
}

export const searchById = ({SalesEntity, InvoiceNumber}) => {
  return fetchGet(`/SalesInvoiceEnquiry/SalesInvoice/${InvoiceNumber}/Entity/${SalesEntity}`, null, 'Inline')
    .then((result) => (result && result.SalesInvoice && {
      invoices: [ { ...objectify(result.SalesInvoice), key: `${result.SalesInvoice.SalesEntity.Value}-${result.SalesInvoice.Invoice.Value}` } ],
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1,
    }) || { invoices: [] })
}

export const getSummary = (query) => {
  const { InvoiceNumber, SalesEntity } = query
  const respType = 'Inline'
  return fetchGet(`/SalesInvoiceEnquiry/SalesInvoice/${InvoiceNumber}/Entity/${SalesEntity}/DocumentDetails`, null, respType)
    .then((result) => (result && { ...result.DocumentDetails }))
}

export const getDetails = (query) => {
  const { InvoiceNumber, SalesEntity } = query
  const respType = 'Inline'
  return fetchGet(`/SalesInvoiceEnquiry/SalesInvoice/${InvoiceNumber}/Entity/${SalesEntity}`, null, respType)
    .then((result) => {
      const objectified = objectify(result.SalesInvoice);
      return ({
        schema: result.SalesInvoice, values: { ...objectified, address: objectifyAddress(objectified, 'Postal') }
      })
    })
}
