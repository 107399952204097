
export enum LostSalesType {
  CancelSale = 'CancelSale',
  DeleteSaleLine = 'DeleteSaleLine',
  ShortSuppliedSaleLine = 'ShortSuppliedSaleLine'
}

export interface ILostSalesDialogProperties<D = any, IApiType = any> {
  open?: boolean;
  loading?: boolean;
  type?: LostSalesType;
  apiType?: IApiType;
  data?: D;
  values?: D;
  resetForm?(): void;
  onOk?(data: D, type: LostSalesType, apiType: IApiType): void;
  onCancel(): void;
}

export type LostSaleDialogState<D, IApiType = any> = Omit<ILostSalesDialogProperties<D, IApiType>, 'resetForm' | 'values' | 'onCancel'>;
