import {
  asyncSelectors, IExtendedState, initializeReducer,
} from 'ducks/utils';
import { Inline, IObjectified } from 'api/utils';
import { ISortOrder, IRostertemplateSearchFacade, IRostertemplateDetailFacade } from 'api/swaggerTypes';
import { isNull } from 'utils/utils';

export interface IRosterEntriesData {
  rosterEntryData: { inlineObject: IRostertemplateDetailFacade; schema: Inline<IRostertemplateDetailFacade> };
  selected: number;
  list: IRostertemplateSearchFacade[];
  schemas: Inline<IRostertemplateSearchFacade>[];
}

export interface IRosterEntriesState extends IExtendedState<IRosterEntriesData> {
  search_loading?: boolean;
  searchById_loading?: boolean;
  fetchRosterData_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

const NOT_SELECTED = -1;

const initialData: IRosterEntriesData = {
  selected: NOT_SELECTED,
  list: [],
  schemas: [],
  rosterEntryData: {
    inlineObject: {},
    schema: {}
  },
};

const { types, actions, reducer } = initializeReducer({
  namespace: 'rosterEntries',
  initialData: initialData,
  syncActions: {
    setSelected: {
      action: (RosterTemplateId: number) => RosterTemplateId,
      callback: (data: IRosterEntriesData, RosterTemplateId: number) => ({ ...data, selected: RosterTemplateId })
    }
  },
  asyncActions: {
    search: {
      action: (search: { SearchText?: string; TechnicianId?: number; Sort?: ISortOrder }) => (search),
      successCallback: (data: IRosterEntriesData, payload: { records: IObjectified<IRostertemplateSearchFacade>[] }): IRosterEntriesData => {
        const rosterEntries = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        const rosterEntryDataObj = isNull(rosterEntries) ? {
          rosterEntryData: {
            inlineObject: {},
            schema: {}
          },
          selected: NOT_SELECTED
        } : {};

        return {
          ...data, list: rosterEntries, schemas: schemas, ...rosterEntryDataObj
        };
      }
    },
    searchById: {
      action: (search: { TechnicianId: number; RosterTemplateId: number }) => (search),
      successCallback: (data: IRosterEntriesData, payload: { records: IObjectified<IRostertemplateSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IRosterEntriesData => {
        const rosterEntries = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return { ...data, list: rosterEntries, schemas: schemas };
      }
    },
    fetchRosterData: {
      action: (search: { TechnicianId: number; RosterTemplateId: number }) => (search),
      successCallback: (data: IRosterEntriesData, payload: { records: IObjectified<IRostertemplateSearchFacade>[]; nextPage: boolean; prevPage?: boolean; currPage: number }): IRosterEntriesData => {
        const rosterEntries = payload.records.map((combinedObject) => combinedObject.inlineObject);
        const schemas = payload.records.map((combinedObject) => combinedObject.schema);

        return {
          ...data,
          rosterEntryData: { inlineObject: rosterEntries[0], schema: schemas[0] },
        };
      }
    },

    create: {
      action: (query: { UserId: number; values: IRostertemplateDetailFacade }) => (query),
      successCallback: (data: IRosterEntriesData, payload: { RosterTemplateDetails: IObjectified<IRostertemplateDetailFacade> }): IRosterEntriesData => {

        return { ...data, rosterEntryData: payload.RosterTemplateDetails };
      }
    },
    update: {
      action: (values: any) => (values),
      successCallback: (data: IRosterEntriesData, payload: { RosterTemplateDetails: IObjectified<IRostertemplateDetailFacade> }): IRosterEntriesData => {

        return { ...data, rosterEntryData: payload.RosterTemplateDetails };
      }
    },
    delete: {
      action: (query: { UserId: number; RosterTemplateId: number }) => (query),
      successCallback: (data: IRosterEntriesData): IRosterEntriesData => {

        const list = [...data.list];
        const schemas = [...data.schemas];
        if (!isNull(list)) {
          const selected = data.list.find((element) => element.RosterTemplateId === data.selected);
          list.splice(list.indexOf(selected), 1);
        }
        if (!isNull(schemas)) {

          const selectedSchema = data.schemas.find((element) => element.RosterTemplateId.Value === data.selected);
          schemas.splice(schemas.indexOf(selectedSchema), 1);
        }

        return {
          ...data, list, schemas,
          selected: NOT_SELECTED,
          rosterEntryData: { inlineObject: {}, schema: {} }
        };
      }
    }
  }
});

export { types, actions };
export default reducer;

const asyncSelector = asyncSelectors(
  (state: { rosterMaintenance: { rosterEntries: IRosterEntriesState } }) => state.rosterMaintenance.rosterEntries,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.RosterTemplateId === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.RosterTemplateId.Value === data.selected)) || null,
    rosterEntryData: (data) => (data.rosterEntryData),
  }
);

const syncSelector = {
  notifications: (state: { rosterMaintenance: { rosterEntries: IRosterEntriesState } }) => state.rosterMaintenance.rosterEntries.notifications,
  error: (state: { rosterMaintenance: { rosterEntries: IRosterEntriesState } }) => state.rosterMaintenance.rosterEntries.error,
  isLookupLoading: (state: { rosterMaintenance: { rosterEntries: IRosterEntriesState } }) => state.rosterMaintenance.rosterEntries.search_loading || state.rosterMaintenance.rosterEntries.searchById_loading,
  isFormLoading: (state: { rosterMaintenance: { rosterEntries: IRosterEntriesState } }) => state.rosterMaintenance.rosterEntries.fetchRosterData_loading
};

export const selectors = { ...asyncSelector, ...syncSelector };
