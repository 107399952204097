import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { toObjectified } from './baseApi';
import { INewCustomerDetails } from './swaggerTypes';
import { fetchPost, fetchPut, fetchDelete, fetchGet, createRequestObject, objectify, Inline, IObjectified } from './utils';

export const getSelectedDetails = async (customerId) => {
  return fetchGet(`CustomerMaintenance/Customer/${customerId}`)
    .then((result) => result && result.Customer);
};

export const search = async (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  return fetchPost('CustomerMaintenance/SearchCustomers', { SearchText, Sort, BatchPage, BatchSize })
    .then((something) => ({
      records: something.Customers.map((val) => ({ inlineObject: objectify(val), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }));
};

export const searchById = async (customerId) => {
  return fetchGet(`CustomerMaintenance/Customer/${customerId}`, {}, 'Inline')
    .then((something) => ({
      records: [{ inlineObject: objectify(something.Customer), schema: something.Customer }],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }));
};

export const update = async (data) => {
  const respType = 'Inline';
  const { customer, newValues } = data;
  const updatedCustomer = { ...customer, ...createRequestObject(newValues) };

  return fetchPut(`CustomerMaintenance/Customer/${customer.CustomerId}`, updatedCustomer, respType)
    .then((result) => result.Customer ? ({ inlineObject: objectify(result.Customer, null, respType), schema: result.Customer }) : result);
};

export const create = async (data) => {
  const respType = 'Inline';

  return fetchPost('CustomerMaintenance/CreateCustomer', createRequestObject(data), null, respType)
    .then((result) => result.Customer ? ({ inlineObject: objectify(result.Customer, null, respType), schema: result.Customer }) : result);
};

export const getNewCustomerTemplate = async (): Promise<IObjectified<INewCustomerDetails>> => {
  return fetchGet<Inline<INewCustomerDetails>>('CustomerMaintenance/CreateCustomerTemplate', {}, 'Inline')
    .then(toObjectified);
};

export const remove = async (customerId) => {
  return fetchDelete(`CustomerMaintenance/Customer/${customerId}`)
    .then(() => customerId);
};

export const getContacts = async (customerId) => {
  const respType = 'None';

  return fetchGet(`CustomerEnquiry/Customer/${customerId}/PrimaryContacts`, null, respType)
    .then((result) => {
      return result.CustomerContacts.map((val) => {
        return ({
          ContactNumber: val.ContactNumber,
          Primary: val.Primary,
          type: val.ContactType === '1' ? 'sls' : val.ContactType === '2' ? 'acc' : val.ContactType === '3' ? 'svc' : val.ContactType === '4' ? 'adm' : 'oth',
          title: val.Contact,
          phone: val.Phone,
          email: val.Email
        });
      });
    });
};
