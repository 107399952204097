import React, { Component } from 'react';
import ContactBlock from '@markinson/uicomponents-v2/ContactBlock';
import { Paper, CircularProgress } from '@material-ui/core';
import { CircularProgressSize } from '../../utils/constants';

const loaderStyle = {
  margin: '40px',
  position: 'relative',
  left: 'calc(50% - 65px)'
}

class ContactsBlockContainer extends Component {
  render() {
    const { label, readOnly, loading, style, primaryContacts } = this.props;
    if (loading) {
      return <Paper style={style}>
        <CircularProgress size={CircularProgressSize} style={loaderStyle} color={'primary'} variant={'indeterminate'} />
      </Paper>
    }
    return <ContactBlock
      label={label}
      values={primaryContacts || []}
      readOnly={readOnly}
      style={style} />
  }
}

export default ContactsBlockContainer;