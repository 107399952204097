export default [
  {
    id: '1',
    label: 'Price Schedule Enquiry in new tab',
    'action': (params) => { window.open('/price-enquiry' + ( params ? '/' + params : '' )) }
  },
  {
    id: '2',
    label: 'Price Schedule Enquiry',
    'action': (params) => { window.location.pathname = '/price-enquiry' + ( params ? '/' + params : '' ) }
  }
];
