import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors, IExtendedState, IDataAction, SuccessAction
} from '../utils';

export interface IInventoryProductData {
  selected: any;
  list: any[];
  schemas: any;
  summary: any;
  nextPage?: number;
  prevPage?: number;
  currPage: number;
  pageSize: number;
  totalPages: number;
  removeWhenPrev: number;
  currSearchText: string;
}

export interface IInventoryProductState extends IExtendedState<IInventoryProductData> {
  search_loading?: boolean;
  getSummary_loading?: boolean;
  searchById_loading?: boolean;
  fetchNextPage_loading?: boolean;
  fetchPrevPage_loading?: boolean;
}

export const { types, actions } = createActions(
  {
    setSelected: (ProductId) => ({ ProductId }),
    asyncs: {
      search: ({ SearchText, Sort }) => ({ SearchText, Sort, BatchPage: 1 }),
      searchById: (id: number) => id,
      fetchNextPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      fetchPrevPage: ({ Sort, BatchPage }) => ({ Sort, BatchPage }),
      getSummary: (ProductId) => (ProductId),
    }
  },
  'inventoryProduct');

const NOT_SELECTED = -1;
const initialState = asyncInitialState<IInventoryProductData>({
  selected: NOT_SELECTED,
  list: null,
  schemas: null,
  summary: null,
  nextPage: null,
  prevPage: null,
  currPage: 1,
  pageSize: 10,
  totalPages: 5,
  removeWhenPrev: 0,
  currSearchText: ''
});

export default (state: IInventoryProductState = initialState, action: IDataAction): IInventoryProductState => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.ProductId;

      return {
        ...state,
        data: { ...state.data, selected }
      };
    case types.search:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED, currSearchText: action.data.SearchText } }, action);
    case types.searchById:
      return asyncOnRequest({ ...state, data: { ...state.data, selected: NOT_SELECTED } }, action);
    case types.getSummary:
      return asyncOnRequest(state, action);
    case types.fetchNextPage:
    case types.fetchPrevPage:
      action.data.SearchText = state.data.currSearchText;

      return asyncOnRequest(state, action);
    case types.saga.search.success:
    case types.saga.searchById.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInventoryProductData, successAction: SuccessAction) => {
          const suppliers = successAction.payload.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = successAction.payload.records.map((combinedObject) => combinedObject.schema);

          return {
            ...data,
            list: suppliers,
            schemas: schemas,
            nextPage: successAction.payload.nextPage && successAction.payload.currPage + 1,
            prevPage: successAction.payload.prevPage && successAction.payload.currPage - 1,
            currPage: successAction.payload.currPage
          };
        },
        { fetch: true });
    case types.saga.getSummary.success:
      return asyncOnSuccess(
        state,
        action,
        (data, successAction) => {
          const summary = successAction.payload;

          return {
            ...data,
            summary: summary
          };
        },
        { fetch: true });
    case types.saga.fetchNextPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInventoryProductData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const suppliers = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newRemoval = result.records.length;
          const newList = data.list.concat(suppliers);
          const newSchemas = data.schemas.concat(schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(newRemoval, newList.length),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(newRemoval, newSchemas.length),
            nextPage: result.nextPage && result.currPage + 1,
            prevPage: (result.prevPage && result.currPage > data.totalPages && result.currPage - data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: (newList.length > data.pageSize * data.totalPages && newRemoval) || 0
          };
        },
        { fetch: true });

    case types.saga.fetchPrevPage.success:
      return asyncOnSuccess(
        state,
        action,
        (data: IInventoryProductData, successAction: SuccessAction) => {
          const result = successAction.payload;
          const products = result.records.map((combinedObject) => combinedObject.inlineObject);
          const schemas = result.records.map((combinedObject) => combinedObject.schema);
          const newList = products.slice(0, data.removeWhenPrev).concat(data.list);
          const newSchemas = schemas.slice(0, data.removeWhenPrev).concat(data.schemas);

          return {
            ...data,
            list: (newList.length <= data.pageSize * data.totalPages && newList) || newList.slice(0, newList.length - data.removeWhenPrev),
            schemas: (newList.length <= data.pageSize * data.totalPages && newSchemas) || newSchemas.slice(0, newSchemas.length - data.removeWhenPrev),
            prevPage: result.prevPage && result.currPage - 1,
            nextPage: (newList.length > data.pageSize * data.totalPages) && (result.currPage + data.totalPages),
            currPage: result.currPage,
            removeWhenPrev: ((newList.length >= data.pageSize * data.totalPages) && data.pageSize) || 0
          };
        },
        { fetch: true });
    case types.saga.search.failure:
    case types.saga.getSummary.failure:
    case types.saga.searchById.failure:
    case types.saga.fetchNextPage.failure:
    case types.saga.fetchPrevPage.failure:
      return asyncOnError(state, action);
    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct,
  {
    all: (data) => (data.list !== undefined && data.list != null && data.list) || [],
    allSchemas: (data) => data.schemas !== undefined && data.schemas != null && Object.values(data.schemas),
    selected: (data) => (data.list !== undefined && data.list != null && data.list.find((element) => element.ProductId === data.selected)) || null,
    selectedSchema: (data) => (data.schemas !== undefined && data.schemas != null && data.schemas.find((element) => element.inventoryProduct.Value === data.selected)) || null,
    summary: (data) => data.list && data.selected && data.summary,
    nextPage: (data) => data.nextPage,
    prevPage: (data) => data.prevPage,
  }
);

const syncSelector = {
  isLoading: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.search_loading || state.inventoryEnquiry.inventoryProduct.searchById_loading,
  loadingNextPage: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.fetchNextPage_loading,
  loadingPrevPage: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.fetchPrevPage_loading,
  loadingSummary: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.getSummary_loading,
  pageSize: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.data.pageSize,
  totalPages: (state: { inventoryEnquiry: { inventoryProduct: IInventoryProductState } }) => state.inventoryEnquiry.inventoryProduct.data.totalPages,
};

export const selectors = { ...asyncSelector, ...syncSelector };
