import { IFormViewForm } from 'components/FormView';

const Balances: IFormViewForm = {
  id: 'Balances',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Financial period',
            name: 'Period',
            size: 'medium',
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Currency',
            name: 'Currency',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Opening balance',
            name: 'OpeningBalanceDisplay',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Outstanding orders',
            name: 'OutstandingOrdersDisplay',
            size: 'medium',
            type: 'string',
            alignment: 'right',
            hideInV2: true
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Current outstanding balance',
            name: 'OutstandingBalanceDisplay',
            size: 'medium',
            type: 'string',
            emphasized: true,
            alignment: 'right',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true,
        label: 'Aged balances',
      },
      children: [
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Current',
            name: 'CurrentBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '30 days',
            name: 'Day30Balance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '60 days',
            name: 'Day60Balance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '90 days',
            name: 'Day90Balance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 5,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: '120 days and over',
            name: 'Day120Balance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 6,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Unallocated balance',
            name: 'UnallocatedBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 7,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Future balance',
            name: 'FutureBalance',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Trading terms',
            name: 'Terms',
            size: 'small',
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit type',
            name: 'CreditType',
            size: 'small',
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Credit limit',
            name: 'CreditLimit',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 3,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last purchase',
            name: 'LastPurchaseAmount',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        },
        {
          id: 4,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Last payment',
            name: 'LastPaymentAmount',
            size: 'medium',
            type: 'string',
            alignment: 'right',
          }
        }
      ]
    }
  ]
};

export default Balances;
