import {
    ISchedulerJobSearchResponse, ISchedulerJobDetailsResponse, ISchedulerJobDetailFacade, IResourcesResponse,
    ISearchRequest, IJobtemplateActivityFacade, IJobtemplateActivitySearchResponse,
    IDailyResourceActivityScheduleResponse, IActivityScheduleFacade, ICustomerDeliveryDetailsResponse,
    IServiceJobDetailResponse, IServiceJobFacade, IRostertemplateDetailsResponse, IRostertemplateDetailFacade,
    IActivityScheduleResponse, IResourceActivityScheduleFacade, IServiceActivityScheduleFacade, INewGeneralActivityScheduleFacade,
    IGeneralActivityScheduleFacade, IServiceJobScheduleFacade, IServiceJobSummaryResponse, IServiceJobCustomerDetailsResponse, IJobtemplateDetailsResponse, IUpcomingServiceJobsResponse, ISearchServiceJobFacade, IUpcomingServiceJobFacade, IServiceAgreementScheduleHistoryFacade, IServiceAgreementHistoryResponse, IFormInfo, IServiceAgreementSchedulesHistoryFacade, ICustomerContactDetails, ICustomerContactSearchResponse,
} from '../swaggerTypes';
import { Api, asObjectified, asObjectifiedArray, toObjectifiedArray, toObjectified } from '../baseApi';
import { objectify, AsObjectified, AsInline, fetchGet, IObjectified, objectifyAddressForPost, Inline, fetchFile } from 'api/utils';
import { AsObjectifiedArray } from './../utils.d';
import { isNull } from 'utils/utils';
import { ILookupResponse } from 'api/customType';

export type ISearchResponse = ISchedulerJobSearchResponse & { SalesEntity: string };
export async function search(query: { SalesEntity?: string; JobStatusFilter?: string; BatchPage?: number }):
    Promise<ISearchResponse> {
    const { SalesEntity, JobStatusFilter, BatchPage } = query;

    return Api.getInlineCollectionResponse<ISchedulerJobSearchResponse, 'ServiceJobs'>(`ActivityScheduler/SalesEntity/${SalesEntity}/ServiceJobs?responseMetadataFormat=None&jobStatusFilter=${JobStatusFilter}&BatchPage=${BatchPage || 1}`)
        .then((response) => {
            const ServiceJobs = asObjectifiedArray(response, 'ServiceJobs');

            return { ...response, ServiceJobs: ServiceJobs.map((j) => j.inlineObject), SalesEntity };
        });
}

export async function fetchJobsForReview(query: { SalesEntity?: string; JobStatusFilter?: string; BatchPage?: number; BatchSize?: number }):
    Promise<AsObjectifiedArray<ISchedulerJobSearchResponse, 'ServiceJobs'> & { SalesEntity: string }> {
    const DEFAULT_BATCH_SIZE = 10;
    const { SalesEntity, JobStatusFilter, BatchPage = 1, BatchSize = DEFAULT_BATCH_SIZE } = query;

    return Api.getInlineCollectionResponse<ISchedulerJobSearchResponse, 'ServiceJobs'>(`ActivityScheduler/SalesEntity/${SalesEntity}/ServiceJobs?jobStatusFilter=${JobStatusFilter}&BatchPage=${BatchPage}&BatchSize=${BatchSize}`)
        .then((response) => ({ ...response, SalesEntity, ServiceJobs: asObjectifiedArray(response, 'ServiceJobs') }));
}

export async function searchById(query: { ServiceJobId: string; ActivityId?: number }):
    Promise<ISchedulerJobDetailsResponse> {
    const { ServiceJobId, ActivityId } = query;

    return Api.get<ISchedulerJobDetailsResponse>(`ActivityScheduler/ServiceJob/${ServiceJobId}/JobDetails?responseMetadataFormat=None${ActivityId ? `&ActivityId=${ActivityId}` : ''}`);
}

export async function fetchJobSummary(query: { ServiceJobId?: string }):
    Promise<AsObjectified<IServiceJobSummaryResponse, 'ServiceJob'>> {
    const { ServiceJobId } = query;

    return Api.getInlineResponse<IServiceJobSummaryResponse, 'ServiceJob'>(`ServiceJobMaintenance/ServiceJob/${ServiceJobId}/JobSummary`)
        .then((response) => ({
            ...response,
            ServiceJob: asObjectified(response, 'ServiceJob'),
        }));
}

export async function completeReview(query: { AdjustmentAmount?: number; AdjustmentDescription?: string; AdjustmentProductId?: string; ServiceJobId?: string }):
    Promise<AsObjectified<IServiceJobDetailResponse, 'ServiceJob'>> {
    const { ServiceJobId } = query;

    return Api.post(`ServiceJobMaintenance/ServiceJob/${ServiceJobId}/CompleteReview`, query)
        .then((response) => response);
}

export async function applyAdjustment(query: { AdjustmentAmount?: number; AdjustmentDescription?: string; AdjustmentProductId?: string; ServiceJobId?: string }):
    Promise<AsObjectified<IServiceJobDetailResponse, 'ServiceJob'>> {
    const { ServiceJobId } = query;

    return Api.postInlineResponse<IServiceJobFacade, IServiceJobDetailResponse, 'ServiceJob'>(`ServiceJobMaintenance/ServiceJob/${ServiceJobId}/ApplyAdjustment`, query)
        .then((response) => ({
            ...response,
            ServiceJob: asObjectified(response, 'ServiceJob'),
        }));
}
export type IJobDetailsResponse = AsObjectified<ISchedulerJobDetailsResponse, 'JobDetails'> & AsObjectified<ISchedulerJobDetailFacade, 'ActivityDetail'>;
export async function getJobDetails(query: { ServiceJobId: string; ActivityId?: number }):
    Promise<IJobDetailsResponse> {
    const { ServiceJobId, ActivityId } = query;

    return Api.getInlineResponse<ISchedulerJobDetailsResponse, 'JobDetails'>(
        `ActivityScheduler/ServiceJob/${ServiceJobId}/JobDetails${ActivityId ? `?ActivityId=${ActivityId}` : ''}`)
        .then((response) => ({
            JobDetails: asObjectified(response, 'JobDetails'),
            ActivityDetail: asObjectified(response.JobDetails as any as AsInline<ISchedulerJobDetailFacade, 'ActivityDetail'>, 'ActivityDetail')
        }));
}

export async function getResources(query: { SalesEntity: string; FromDate: string; ToDate: string }):
    Promise<AsObjectifiedArray<IResourcesResponse, 'Resources'>> {
    const { SalesEntity, FromDate, ToDate } = query;

    return Api.getInlineCollectionResponse<IResourcesResponse, 'Resources'>(
        `ActivityScheduler/SalesEntity/${SalesEntity}/Resources?FromDate=${FromDate}&ToDate=${ToDate}`
    ).then((response) => ({ ...response, Resources: asObjectifiedArray(response, 'Resources') }));
}

export async function getScheduledActivities(query: { SalesEntity: string; FromDate: string; ToDate: string }):
    Promise<AsObjectifiedArray<IActivityScheduleResponse, 'ActivitySchedules'>> {
    const { SalesEntity, FromDate, ToDate } = query;

    return Api.getInlineCollectionResponse<IActivityScheduleResponse, 'ActivitySchedules'>(
        `ActivityScheduler/SalesEntity/${SalesEntity}/ActivitySchedules?FromDate=${FromDate}&ToDate=${ToDate}`
    ).then((response) => {
        return { ...response, ActivitySchedules: asObjectifiedArray(response, 'ActivitySchedules') };
    });
}

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export type InlineResourceActivitySchedule = Omit<Inline<IResourceActivityScheduleFacade>, 'ActivitySchedules'> & { ActivitySchedules: Inline<IActivityScheduleFacade>[] };
export interface IObjectifiedResourceActivitySchedule {
    inlineObject: Omit<IResourceActivityScheduleFacade, 'ActivitySchedules'> & {
        ActivitySchedules: IObjectified<IActivityScheduleFacade>[];
    };
    schema: Inline<IResourceActivityScheduleFacade>;
}

function parseResourceActivitySchedule(input: InlineResourceActivitySchedule): IObjectifiedResourceActivitySchedule {
    const objectified = toObjectified<Omit<IResourceActivityScheduleFacade, 'ActivitySchedules'>>(input);

    return {
        inlineObject: {
            ...objectified.inlineObject,
            ActivitySchedules: input.ActivitySchedules ? toObjectifiedArray(input.ActivitySchedules) : undefined
        },
        schema: objectified.schema
    };
}

export type InlineDailyResourceActivityScheduleResponse = Omit<IDailyResourceActivityScheduleResponse, 'ResourceActivitySchedules'> & { ResourceActivitySchedules: InlineResourceActivitySchedule[] };
export type ObjectifiedDailyResourceActivityScheduleResponse = Omit<IDailyResourceActivityScheduleResponse, 'ResourceActivitySchedules'> & { ResourceActivitySchedules: IObjectifiedResourceActivitySchedule[] };

function parseDailyResourceActivityScheduleResponse(input: InlineDailyResourceActivityScheduleResponse): ObjectifiedDailyResourceActivityScheduleResponse {
    return {
        ...input,
        ResourceActivitySchedules: input.ResourceActivitySchedules ? input.ResourceActivitySchedules.map(parseResourceActivitySchedule) : undefined
    };
}

export async function getActivityImage(query: { ActivityId: number; ImageId: number }):
    Promise<any> {
    const { ActivityId, ImageId } = query;

    return fetchFile(
        `/ServiceJobMaintenance/ServiceActivity/${ActivityId}/Image/${ImageId}`, { preview: false }
    ).then((response) => (window.URL.createObjectURL(response)));
}

export async function scheduleActivity(query: { Schedule: IServiceActivityScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { Schedule } = query;

    return Api.postInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        'ActivityScheduler/ScheduleServiceActivity',
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function scheduleJob(query: { Schedule: IServiceJobScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { Schedule } = query;

    return Api.postInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        'ActivityScheduler/ScheduleServiceJob',
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function scheduleGeneralActivity(query: { Schedule: INewGeneralActivityScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { Schedule } = query;

    return Api.postInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        'ActivityScheduler/ScheduleGeneralActivity',
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function moveActivity(query: { ActivityScheduleId: any; Schedule: IServiceActivityScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId, Schedule } = query;

    return Api.putInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/UpdateServiceActivitySchedule/${ActivityScheduleId}`,
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function moveJob(query: { ActivityScheduleId: any; Schedule: IServiceJobScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId, Schedule } = query;

    return Api.putInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/UpdateServiceJobSchedule/${ActivityScheduleId}`,
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function updateGeneralActivity(query: { ActivityScheduleId: any; Schedule: IGeneralActivityScheduleFacade }):
    Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId, Schedule } = query;

    return Api.putInline<IActivityScheduleFacade, InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/UpdateGeneralActivitySchedule/${ActivityScheduleId}`,
        Schedule
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function getRosterPeriod(query: { SalesEntity: string }): Promise<IRostertemplateDetailFacade> {
    const { SalesEntity } = query;

    return Api.get<IRostertemplateDetailFacade>(
        `RosterMaintenance/SalesEntity/${SalesEntity}/Periods`);
}

export async function updateRoster(query: { SalesEntity: string; StartDate: string; Duration: string }): Promise<AsObjectifiedArray<IRostertemplateDetailsResponse, 'RosterTemplateDetails'>> {
    const { SalesEntity, StartDate, Duration } = query;

    return Api.postInlineCollectionResponse<IActivityScheduleFacade, IRostertemplateDetailsResponse, 'RosterTemplateDetails'>(
        `RosterMaintenance/SalesEntity/${SalesEntity}/StartDate/${StartDate}/PostRoster?Duration=${Duration}`
    ).then((response) => ({ ...response, Roster: asObjectifiedArray(response, 'RosterTemplateDetails') }));
}

export async function deleteActivitySchedule(query: { ActivityScheduleId: number }): Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId } = query;

    return Api.deleteInline<InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/ServiceActivitySchedule/${ActivityScheduleId}`
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function deleteJob(query: { ActivityScheduleId: number }): Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId } = query;

    return Api.deleteInline<InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/ServiceJobSchedule/${ActivityScheduleId}`
    ).then(parseDailyResourceActivityScheduleResponse);
}

export async function deleteGeneralActivity(query: { ActivityScheduleId: number }): Promise<ObjectifiedDailyResourceActivityScheduleResponse> {
    const { ActivityScheduleId } = query;

    return Api.deleteInline<InlineDailyResourceActivityScheduleResponse>(
        `ActivityScheduler/GeneralActivitySchedule/${ActivityScheduleId}`
    ).then(parseDailyResourceActivityScheduleResponse);
}
export async function getCustomerDetails(CustomerId?: number, ServiceItemId?: number):
    Promise<IObjectified<IServiceJobCustomerDetailsResponse>> {
    return fetchGet('ServiceJobMaintenance/Service/CustomerDetails', { CustomerId, ServiceItemId })
        .then(({ CustomerDetails = {} }) => ({ inlineObject: objectify(CustomerDetails), schema: CustomerDetails }));
}
export async function getCustomerContacts(CustomerId?: number): Promise<ICustomerContactDetails[]> {

    return Api.postInlineCollectionResponse<ISearchRequest, ICustomerContactSearchResponse, 'CustomerContacts'>(
        `CustomerMaintenance/Customer/${CustomerId}/SearchContacts`, { SearchText: '' }
    ).then((response) => {
        const objectifiedResponse = asObjectifiedArray(response, 'CustomerContacts');

        return objectifiedResponse.map((r) => r.inlineObject);
    });
}

export async function getDeliveryAddress(query: { CustomerId: number; SiteCode: string }):
    Promise<IObjectified<ICustomerDeliveryDetailsResponse>> {
    const { CustomerId, SiteCode } = query;

    return Api.getInlineResponse<ICustomerDeliveryDetailsResponse, 'CustomerDeliveryDetails'>(
        `ServiceJobMaintenance/Customer/${CustomerId}/DeliveryDetails/${SiteCode}`)
        .then((response) => (asObjectified(response, 'CustomerDeliveryDetails')));
}

export interface IObjectifiedCreateNewJobResponse {
    ServiceJob: IObjectified<IServiceJobFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
}

export async function createNewJob(data: any):
    Promise<IObjectifiedCreateNewJobResponse> {
    const address = !isNull(data.address) ? objectifyAddressForPost(data.address) : {};
    delete data.address;
    delete data.InitialActivities;
    delete data.AddedActivities;
    const postData = { ...data, ...address };

    return Api.postInlineResponse<IServiceJobFacade, IServiceJobDetailResponse, 'ServiceJob'>('ServiceJobMaintenance/CreateJob', postData)
        .then((response) => ({
            ...response,
            ServiceJob: asObjectified(response, 'ServiceJob')
        }));
}

export async function getJobActivities(TemplateCode: string): Promise<IObjectified<IJobtemplateActivityFacade>[]> {
    return Api.postInlineCollectionResponse<ISearchRequest, IJobtemplateActivitySearchResponse, 'Activities'>(
        `JobTemplateMaintenance/JobTemplate/${TemplateCode}/SearchActivities`, { SearchText: '' })
        .then((response) => (asObjectifiedArray(response, 'Activities')));
}

export async function getDefaultJob(ServiceItemType: string):
    Promise<IJobtemplateDetailsResponse> {

    return Api.get<IJobtemplateDetailsResponse>(`JobTemplateMaintenance/DefaultJobTemplate?responseMetadataFormat=None${ServiceItemType ? `&ServiceItemType=${ServiceItemType}` : ''}`);
}

export type IObjectifiedIUpcomingServiceJobs = IObjectified<IUpcomingServiceJobFacade>[];
export async function getServiceAgreementJobs(query: ISearchServiceJobFacade):
    Promise<IObjectifiedIUpcomingServiceJobs> {

    return Api.postInlineCollectionResponse<ISearchServiceJobFacade, IUpcomingServiceJobsResponse, 'ServiceJobs'>(
        'ActivityScheduler/SearchServiceAgreementJobs', query)
        .then((response) => (asObjectifiedArray(response, 'ServiceJobs')));
}

export interface IObjectifiedRecordServiceAgreementSchedule {
    ServiceAgreementsHistory: IObjectified<IServiceAgreementScheduleHistoryFacade>;
    Status?: boolean;
    Forms?: IFormInfo[];
}

export async function recordServiceAgreementSchedule(data: IServiceAgreementScheduleHistoryFacade[]):
    Promise<IObjectifiedRecordServiceAgreementSchedule> {
    const payload = { ServiceAgreementsHistory: data };

    return Api.postInlineResponse<IServiceAgreementSchedulesHistoryFacade, IServiceAgreementHistoryResponse, 'ServiceAgreementsHistory'>('ServiceJobMaintenance/RecordServiceAgreementSchedule', payload)
        .then((response) => ({
            ...response,
            ServiceAgreementsHistory: asObjectified(response, 'ServiceAgreementsHistory')
        }));
}

export const fetchDeliverySiteOptions = async (query: { SearchText: string; CustomerId: number }): Promise<ILookupResponse> => {
    return Api.post<{ SearchText: string; CustomerId: number }, ILookupResponse>('CustomTypes/Lookup/DeliverySite/Search', query);
};
