import { IFormViewForm } from 'components/FormView';

const ContactDetails: IFormViewForm = {
    id: 'ContactDetails',
    layout: {
        rows: 2,
        columns: 3
    },
    fields: [
        {
            id: 1,
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: 0,
                    type: 'TEXT_FIELD',
                    visible: true,
                    props: {
                        label: 'Contact',
                        name: 'ContactName',
                        disabled: true,
                        size: 'large',
                    }
                },
                {
                    id: 1,
                    type: 'ACTION_FIELD',
                    visible: true,
                    props: {
                        label: 'Phone',
                        name: 'Phone',
                        action: {
                            iconName: 'Phone'
                        },
                    }
                },
                {
                    id: 2,
                    type: 'ACTION_FIELD',
                    visible: true,
                    props: {
                        label: 'Fax',
                        name: 'Fax',
                        action: {
                            iconName: 'Phone'
                        },
                    }
                },
                {
                    id: 3,
                    type: 'ACTION_FIELD',
                    visible: true,
                    props: {
                        label: 'Mobile',
                        name: 'Mobile',
                        action: {
                            iconName: 'Phone'
                        },
                    }
                },
                {
                    id: 4,
                    type: 'ACTION_FIELD',
                    visible: true,
                    props: {
                        label: 'Email',
                        name: 'Email',
                        size: 'large',
                        action: {
                            iconName: 'Mail'
                        },
                    }
                }
            ]
        }
    ]
};
export default ContactDetails;
