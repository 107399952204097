import * as React from 'react';
import FormView from 'components/FormView';
import { ICashReceiptOrdersProps, ICashReceiptOrdersStates } from '../interfaces';
import { OPERATIONS, Operation } from 'utils/operations';
import { isNull, pathOr } from 'utils/utils';
import FormViewModal from 'components/common/Modals/FormViewModal';
import orderAllocationModalSchema from 'utils/data/forms/cash-receipting/order-allocation.json';
import SummaryTableSchema from '../SummaryTableSchema.json';
import SummaryTable from 'components/common/SummaryTable';

class Orders extends React.PureComponent<ICashReceiptOrdersProps, ICashReceiptOrdersStates> {

  constructor(props: ICashReceiptOrdersProps) {
    super(props);
    this.state = {
      openOrderAlloModal: false
    };
  }
  componentDidMount(): void {
    const { path, onInitialLoad, allocate } = this.props;
    if (isNull(allocate)) {
      onInitialLoad('CashReceipt');
    } else if (path === '/cash-receipting/allocate/orders') {
      onInitialLoad('CashReceiptingOrders');
    }
  }

  componentDidUpdate(provProps: ICashReceiptOrdersProps): void {
    const { operationMode, changeOperationMode } = this.props;

    if (provProps.operationMode !== operationMode) {
      switch (operationMode) {
        case OPERATIONS.SAVE:
          changeOperationMode(Operation.EDIT);
          break;
        case OPERATIONS.CANCEL:
          changeOperationMode(Operation.EDIT);
          break;
        default:
          changeOperationMode(Operation.EDIT);
      }
    }
  }

  getSummaryTableRenderer = () => {
    const { orderAllocation, orderAllocationLoading } = this.props;

    return () => <SummaryTable
      orderAllocationLoading={orderAllocationLoading}
      orderAllocation={orderAllocation && orderAllocation.inlineObject}
      data={
        SummaryTableSchema.OrderAllocationSummaryTable
      }
    />;
  }

  handleEnquiry = () => {
    const { orderAllocation } = this.props;
    const SalesOrder = (orderAllocation && orderAllocation.SalesOrder) || '';

    const search = new URLSearchParams();
    search.append('SalesOrder', SalesOrder.toString());
    window.open(`/sales-order-enquiry/order-detail?${search.toString()}`);
  }

  handleModalVisibility = (name: string, open: boolean) => {
    const { changeModalVisibility } = this.props;
    changeModalVisibility({ [name]: open });
  }

  handleOrderAllocationSave = () => {
    const { applySpecificOrderAllocation, orderAllocationFormValues } = this.props;
    if (applySpecificOrderAllocation && orderAllocationFormValues) {
      applySpecificOrderAllocation(orderAllocationFormValues);
    }
  }

  render(): React.ReactNode {
    const {
      isBrowseLookUpOpen, selectedTab, cashReceipt, selectedForm, operationMode,
      summaryTableRenderer, orderAllocation = {}, orderAllocationLoading = false, openOrderAllocationModal
    } = this.props;
    const initialValues = cashReceipt && cashReceipt.inlineObject;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          includeTabsHeight={false}
          schema={selectedForm}
          initialValues={initialValues}
          operationMode={operationMode}
          valuesSchema={null}
          summaryTableRenderer={summaryTableRenderer}
        />
        <FormViewModal
          open={openOrderAllocationModal}
          title='Order Allocation'
          loading={orderAllocationLoading}
          formProps={{
            formSchema: orderAllocationModalSchema,
            style: { height: '530px' },
            initialValues: pathOr({}, ['inlineObject'], orderAllocation),
            valuesSchema: pathOr({}, ['schema'], orderAllocation),
            summaryTableRenderer: this.getSummaryTableRenderer(),
            enableReinitialize: true
          }}
          onClose={() => this.handleModalVisibility('openOrderAllocationModal', false)}
          actions={[
            {
              title: 'ENQUIRY',
              listener: this.handleEnquiry
            },
            {
              title: 'CANCEL',
              listener: () => this.handleModalVisibility('openOrderAllocationModal', false)
            },
            {
              title: 'SAVE',
              listener: this.handleOrderAllocationSave
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

export default Orders;
