import { IFormViewForm } from 'components/FormView';

const PurchaseOrderLineDetailsForm: IFormViewForm = {
    id: 'PurchaseOrderLineDetailsForm',
    layout: {
        rows: 3,
        columns: 3
    },
    fields: [
        {
            id: '1',
            position: {
                row: 1,
                col: 1
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Product',
                        name: 'ProductIdDisplay',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        readOnly: true,
                        disabled: true
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Description',
                        name: 'Description',
                        placeholder: '',
                        size: 'large',
                        required: false,
                        disabled: true,
                        inputProps: {
                            maxLength: 40
                        }
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'On order',
                        name: 'OnOrderBUQuantity',
                        placeholder: '',
                        size: 'small',
                        alignment: 'right',
                        type: 'number',
                        required: false,
                        decimalSeparator: true,
                        decimalPlaces: 2
                    }
                },
                {
                    id: '3',
                    type: 'EX_LOOKUP_FIELD',
                    props: {
                        label: 'Buying unit',
                        name: 'UnitBuy',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        lookupName: 'UnitCode',
                    }
                },
                {
                    id: '3',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Unit conversion factor',
                        name: 'UnitConversion',
                        placeholder: '',
                        size: 'small',
                        required: false,
                        decimalSeparator: true,
                        type: 'number',
                        alignment: 'right',
                        decimalPlaces: 4
                    }
                },
                {
                    id: '4',
                    type: 'EX_LOOKUP_FIELD',
                    visible: true,
                    props: {
                        label: 'Supplier product',
                        name: 'SupplierProductId',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        lookupName: 'SupplierProduct',
                        isSupplierScoped: true
                    }
                },
            ]
        },
        {
            id: '2',
            position: {
                row: 1,
                col: 2
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Base price',
                        name: 'BasePrice',
                        placeholder: '',
                        size: 'medium',
                        type: 'number',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2
                    }
                },
                {
                    id: '1',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Discount (%)',
                        name: 'Discount',
                        placeholder: '',
                        type: 'number',
                        size: 'small',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2
                    }
                },
                {
                    id: '2',
                    type: 'TEXT_FIELD',
                    props: {
                        label: 'Price',
                        name: 'PriceCurr',
                        type: 'number',
                        placeholder: '',
                        size: 'medium',
                        required: false,
                        decimalSeparator: true,
                        alignment: 'right',
                        decimalPlaces: 2
                    }
                },
                {
                    id: '3',
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Special price',
                        name: 'SpecialPrice'
                    }
                },
                {
                    id: '4',
                    type: 'TOGGLE_FIELD',
                    props: {
                        label: 'Estimated price',
                        name: 'Estimated'
                    }
                },
                {
                    id: '5',
                    type: 'DATE_FIELD',
                    props: {
                        label: 'Due into stock',
                        name: 'DueDate',
                        dateFormat: 'DD/MM/YYYY',
                        placeholder: 'DD/MM/YYYY',
                        size: 'small',
                    }
                }
            ]
        },
        {
            id: '3',
            position: {
                row: 2,
                col: 1,
                colspan: 1,
            },
            type: 'PAPER_CONTAINER',
            visible: true,
            props: {
                fullWidth: true
            },
            children: [
                {
                    id: '0',
                    type: 'TEXT_AREA',
                    props: {
                        label: 'Order line comment',
                        name: 'Comment',
                        size: 'large',
                        style: {
                            height: '300px'
                        }
                    }
                }
            ]
        },
    ]
};
export default PurchaseOrderLineDetailsForm;
