import { IFormViewForm } from 'components/FormView';

const InvoiceDetailsForm: IFormViewForm = {
  id: 'InvoiceDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Other invoice details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference',
            name: 'Reference',
            size: 'large'
          }
        },
        {
          id: 1,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Shipment',
            name: 'Shipment',
            size: 'small'
          }
        },
        {
          id: 2,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'User ID',
            name: 'UserId',
            size: 'small'
          }
        },
        {
          id: 3,
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Recipient created tax invoice',
            name: 'ReceiptTaxInvoice'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Freight supplier details',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'SUBHEADER',
          props: {
            value: 'Please note: This section shows details for any freight charges assigned to a separate supplier.',
          }
        },
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Supplier',
            name: 'Supplier',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Reference',
            name: 'SupplierReference',
            size: 'large'
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Freight (Ex Tax)',
            name: 'FreightCostDisplay',
            placeholder: '',
            size: 'small'
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Freight comments',
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'TEXT_AREA',
          props: {
            name: 'FreightComments',
            fullWidth: true,
            style: {
              height: '250px'
            }
          }
        }
      ]
    },
    {
      id: 1,
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EXTENDED_TEXT_FIELD',
          visible: true,
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            size: 'small'
          }
        },
        {
          id: 1,
          type: 'SUBHEADER',
          props: {
            value: 'User defined categories',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 1',
            name: 'Category1',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 2',
            name: 'Category2',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
        {
          id: 4,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 3',
            name: 'Category3',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
        {
          id: 5,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 4',
            name: 'Category4',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
        {
          id: 6,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 5',
            name: 'Category5',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
        {
          id: 7,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Category 6',
            name: 'Category6',
            placeholder: '',
            size: 'small',
            required: false,
            disabled: true
          }
        },
      ]
    }
  ]
};
export default InvoiceDetailsForm;
