import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as customerSelectors, actions as customerActions } from 'ducks/customer';
import { selectors as contactSelectors } from 'ducks/contact';
import { selectors as addressSelectors } from 'ducks/address';
import { actions as productActions } from 'ducks/product';
import { actions as productLookupActions, selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { selectors as marketingSelectors } from 'ducks/customerEnquiry/marketing';
import { actions as productDetailsActions, selectors as productDetailsSelectors } from 'ducks/inventoryEnquiry/productDetails';
import { actions as stockingSerialActions, selectors as stockingSerialSelectors } from 'ducks/inventoryEnquiry/stocking/serials';
import { actions as stockingMovementActions, } from 'ducks/inventoryEnquiry/stocking/movements';
import { actions as salesOrderActions, selectors as salesOrderSelectors } from 'ducks/inventoryEnquiry/sales/salesOrders';
import { actions as serviceOrderActions, selectors as serviceOrderSelectors } from 'ducks/inventoryEnquiry/sales/serviceOrders';
import { actions as returnAuthoritiesActions, selectors as returnAuthoritiesSelectors } from 'ducks/inventoryEnquiry/sales/returnAuthorities';
import { actions as lostSalesActions, selectors as lostSalesSelectors } from 'ducks/inventoryEnquiry/sales/lostSales';
import { actions as salesQuotesActions, selectors as salesQuotesSelectors } from 'ducks/inventoryEnquiry/sales/quotes';
import { actions as lotDetailsActions, selectors as lotDetailsSelectors } from 'ducks/inventoryEnquiry/stocking/lotDetails';
import { selectors as lotPickingSelectors } from 'ducks/inventoryEnquiry/stocking/lotPicking';
import { selectors as lotReservedSelectors } from 'ducks/inventoryEnquiry/stocking/lotReserved';
import { selectors as lotInPutAwaySelectors } from 'ducks/inventoryEnquiry/stocking/lotInPutAway';
import { actions as stockingQuantitiesActions, selectors as stockingQuantitiesSelectors } from 'ducks/inventoryEnquiry/stocking/quantities';
import { actions as binLocationsActions, selectors as binLocationsSelectors } from 'ducks/inventoryEnquiry/stocking/binLocations';
import { actions as stockingReserveHistoryActions, selectors as stockingReserveHistorySelectors } from 'ducks/inventoryEnquiry/stocking/reserveHistory';
import { selectors as stockingQuantitiesInPutAwayWIPSelectors } from 'ducks/inventoryEnquiry/stocking/inPutAwayWIP';
import { selectors as stockingQuantitiesServiceWIPSelectors } from 'ducks/inventoryEnquiry/stocking/serviceWIP';
import { actions as priceMatrixActions, selectors as priceMatrixSelectors } from 'ducks/inventoryEnquiry/pricing/priceMatrix';
import { actions as pricesActions } from 'ducks/inventoryEnquiry/pricing/prices';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as staffSelectors } from 'ducks/staff';
import { actions as attachmentsImagesActions } from 'ducks/inventoryEnquiry/attachments/images';

import InventoryEnquiry from './InventoryEnquiry';
import { IApplicationState } from 'ducks/reducers';
import { isDirty, reset, getFormValues, destroy } from 'redux-form';

const FORM_NAME = 'InventoryEnquiry';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),

  selectedCustomer: customerSelectors.selected(state),
  selectedAddress: addressSelectors.selected(state),
  selectedContact: contactSelectors.selected(state),
  selectedProduct: productSelectors.selected(state),
  selectedMarketing: marketingSelectors.selected(state),
  selectedQuantity: stockingQuantitiesSelectors.selected(state),
  selectedStockingBinLocations: binLocationsSelectors.selected(state),
  selectedStockingReserveHistory: stockingReserveHistorySelectors.selected(state),
  selectedStockingInPutAwayWIP: stockingQuantitiesInPutAwayWIPSelectors.selected(state),
  selectedStockingServiceWIP: stockingQuantitiesServiceWIPSelectors.selected(state),
  selectedLot: lotDetailsSelectors.selected(state),

  selectedCustomerSchema: customerSelectors.selectedSchema(state),
  selectedContactSchema: contactSelectors.selectedSchema(state),
  selectedAddressSchema: addressSelectors.selectedSchema(state),
  selectedProductSchema: productSelectors.selectedSchema(state),
  selectedMarketingSchema: marketingSelectors.selectedSchema(state),

  productDetails: productDetailsSelectors.productDetails(state),
  productDetailsSchema: productDetailsSelectors.productDetailsSchema(state),
  productDetailsSummary: productDetailsSelectors.productDetailsSummary(state),

  selectedLotDetailsFilters: lotDetailsSelectors.selectedFilters(state),
  selectedLotPickingFilters: lotPickingSelectors.selectedFilters(state),
  selectedLotReservedFilters: lotReservedSelectors.selectedFilters(state),
  selectedLotInPutAwayFilters: lotInPutAwaySelectors.selectedFilters(state),
  selectedSerialFilters: stockingSerialSelectors.selectedFilters(state),
  selectedLostSalesFilters: lostSalesSelectors.selectedFilters(state),
  selectedStockingBinLocationsFilters: binLocationsSelectors.selectedFilters(state),
  selectedStockingReserveHistoryFilters: stockingReserveHistorySelectors.selectedFilters(state),
  selectedStockingInPutAwayWIPFilters: stockingQuantitiesInPutAwayWIPSelectors.selectedFilters(state),
  selectedStockingServiceWIPFilters: stockingQuantitiesServiceWIPSelectors.selectedFilters(state),
  selectedSalesOrderFilters: salesOrderSelectors.selectedFilters(state),
  selectedServiceOrderFilters: serviceOrderSelectors.selectedFilters(state),
  selectedReturnAuthoritiesFilters: returnAuthoritiesSelectors.selectedFilters(state),
  selectedSalesQuotesFilters: salesQuotesSelectors.selectedFilters(state),
  selectedPriceMatrixFilters: priceMatrixSelectors.selectedFilters(state),

  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  dirty: isDirty(state.ui.selectedTab === 'Notepad' ? 'CustomerNotes' : FORM_NAME)(state),
  formValues: getFormValues(state.ui.selectedTab === 'Notepad' ? 'CustomerNotes' : FORM_NAME)(state),
  staff: staffSelectors.staff(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleSideLookUp: uiActions.toggleProductLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    resetForm: () => reset(FORM_NAME),
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getContacts: customerActions.subOp_getContacts,
    fetchProductDetail: productActions.fetchDetail,
    fetchProductDetails: productDetailsActions.fetch,
    fetchProductSummary: productDetailsActions.fetchSummary,
    resetStockingSerials: stockingSerialActions.reset,
    resetStockingMovements: stockingMovementActions.reset,
    fetchStockingMovements: stockingMovementActions.fetch,
    getAttachmentsImages: attachmentsImagesActions.fetch,
    fetchStockingQuantities: stockingQuantitiesActions.fetch,
    setSalesOrdersEntityView: salesOrderActions.setEntityView,
    setWarehouse: priceMatrixActions.setWarehouse,
    setStockingBinLocationsWarehouse: binLocationsActions.setWarehouse,
    setStockingReserveHistoryWarehouse: stockingReserveHistoryActions.setWarehouse,
    setSalesOrderEntityView: salesOrderActions.setEntityView,
    setServiceOrderEntityView: serviceOrderActions.setEntityView,
    setReturnAuthoritiesEntityView: returnAuthoritiesActions.setEntityView,
    setLostSalesWarehouse: lostSalesActions.setWarehouse,
    setSalesQuotesEntityView: salesQuotesActions.setEntityView,
    setStockingSerialsWarehouse: stockingSerialActions.setWarehouse,
    setStockingMovementsWarehouse: stockingMovementActions.setWarehouse,
    setStockingLotDetailsWarehouse: lotDetailsActions.setWarehouse,
    fetchPricesSummary: pricesActions.fetchSummary,
    changeSelectedCustomer: customerActions.setSelected,
    closeMenu: uiActions.closeMainMenu,
    resetSearchLookupDrawer: productLookupActions.reset,
    resetproductDetailsModule: productDetailsActions.reset,
    destoryForm: () => destroy('ProductDetails'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryEnquiry);
