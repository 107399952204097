import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function CommitIcon(props: Readonly<SvgIconProps>): JSX.Element {
  return (
    <SvgIcon viewBox={'0 -960 960 960'} {...props}>
      <path d='M280-280h400v-80H280v80Zm200-120 160-160-56-56-64 62v-166h-80v166l-64-62-56 56 160 160Zm0 320q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z'/>
    </SvgIcon>);
}

export default React.memo(CommitIcon);
