import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as serialSelectors, actions as serialActions } from 'ducks/common/serialNumber';
import SerialNumber from './SerialNumber';

const mapStateToProps = (state: IApplicationState) => ({
  selectedLines: serialSelectors.getSelectedLines(state),
  availableLines: serialSelectors.getAvailableLines(state),
  serialNumberSummary: serialSelectors.serialNumberSummary(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    clearSelectedLines: serialActions.clearSelectedLines,
    addSelectedLine: serialActions.addSelectedLine,
    fetchSelectedSerials: serialActions.fetchSelectedSerials,
    fetchSerialsSummary: serialActions.fetchSerialsSummary,
    fetchAvailableLines: serialActions.fetchAvailableLines,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumber);
