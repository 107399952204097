import * as React from 'react';
import { Route } from 'react-router';
import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import OrderSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Order';
import BreadcrumbBar from '../common/BreadcrumbBar';
import SummaryPanel from './SummaryPanel';
import * as styles from './SalesOrderEnquiry.scss';
import options from './OptionItems.json';
import SalesOrderLinesEnquiry from './SalesOrderLinesEnquiry';
import SalesOrderDeliveriesEnquiry from './SalesOrderDeliveriesEnquiry';
import { shallowCompare, isTabChild, findParentTab } from '../../utils/utils';
import { Operation } from '../../utils/operations';
import SalesOrderEnquiry from './MainSalesOrderEnquiry';
import Commitments from './Commitments';
import SaleDeposits from './SaleDeposits';
import Diary from './Diary';
import NotePad from './NotePad';
import { withRouter } from 'react-router-dom';
import { ISalesOrderEnquiryViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  saleOrderEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class SalesOrderEnquiryView extends React.Component<ISalesOrderEnquiryViewProperties> {

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  componentWillUnmount(): void {
    this.props.resetSearchLookupDrawer();
    this.props.destoryForm();
  }

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'sales-order-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    if (selectedTab !== 'DiaryDetails') {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    const { pathname, search } = this.getRoute()[selectedTab];
    this.props.history.push({ pathname, search });
    this.fetchChildRecords(selectedTab);
  };

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'sales-order-enquiry';
    const SalesOrder = params.get('SalesOrder') || '';
    const DeliveryId = params.get('DeliveryId') || '';
    const LineNumber = params.get('LineNumber') || '';
    const BOMLineNumber = params.get('BOMLineNumber') || '';
    const DebtorCashObject = params.get('DebtorCashObject') || '';
    const TransactionNumber = params.get('TransactionNumber') || '';
    const DiaryId = params.get('DiaryId') || '';

    return ({
      OrderDetails: { pathname: `/${context}/order-details`, search: `?SalesOrder=${SalesOrder}`, component: SalesOrderEnquiry },
      Comment: { pathname: `/${context}/comment`, search: `?SalesOrder=${SalesOrder}`, component: SalesOrderEnquiry },
      Deliveries: { pathname: `/${context}/deliveries`, search: `?SalesOrder=${SalesOrder}`, component: SalesOrderDeliveriesEnquiry },
      DeliveryDetails: { pathname: `/${context}/deliveries/delivery-details`, search: `?SalesOrder=${SalesOrder}&DeliveryId=${DeliveryId}`, component: SalesOrderDeliveriesEnquiry },
      OrderLines: { pathname: `/${context}/order-lines`, search: `?SalesOrder=${SalesOrder}`, component: SalesOrderLinesEnquiry },
      LineDetails: { pathname: `/${context}/order-lines/line-details`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      LDCommittedPurchaseOrder: { pathname: `/${context}/order-lines/line-details/committed-purchase-order`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      LDCommittedProductionOrder: { pathname: `/${context}/order-lines/line-details/committed-production-order`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      LDCommittedInPutAway: { pathname: `/${context}/order-lines/line-details/committed-in-put-away`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      SOEBOM: { pathname: `/${context}/order-lines/line-details/bom`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      ComponentDetails: { pathname: `/${context}/order-lines/line-details/bom/component-details`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDCommittedPurchaseOrder: { pathname: `/${context}/order-lines/line-details/bom/component-details/committed-purchase-order`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDCommittedProductionOrder: { pathname: `/${context}/order-lines/line-details/bom/component-details/committed-production-order`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDCommittedInPutAway: { pathname: `/${context}/order-lines/line-details/bom/component-details/committed-in-put-away`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDReservationDetails: { pathname: `/${context}/order-lines/line-details/bom/component-details/reservation-details`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDReservationSerials: { pathname: `/${context}/order-lines/line-details/bom/component-details/reservation-serials`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      CDReservationLots: { pathname: `/${context}/order-lines/line-details/bom/component-details/reservation-lots`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}&BOMLineNumber=${BOMLineNumber}`, component: SalesOrderLinesEnquiry },
      LineInvoices: { pathname: `/${context}/order-lines/line-details/invoices`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      ReservationDetails: { pathname: `/${context}/order-lines/line-details/reservation-details`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      LDReservationSerials: { pathname: `/${context}/order-lines/line-details/reservation-serials`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      LDReservationLots: { pathname: `/${context}/order-lines/line-details/reservation-lots`, search: `?SalesOrder=${SalesOrder}&LineNumber=${LineNumber}`, component: SalesOrderLinesEnquiry },
      CommittedPurchaseOrder: { pathname: `/${context}/commitments/committed-purchase-order`, search: `?SalesOrder=${SalesOrder}`, component: Commitments },
      CommittedProductionOrder: { pathname: `/${context}/commitments/committed-production-order`, search: `?SalesOrder=${SalesOrder}`, component: Commitments },
      CommittedInPutAway: { pathname: `/${context}/commitments/committed-in-put-away`, search: `?SalesOrder=${SalesOrder}`, component: Commitments },
      SaleDeposits: { pathname: `/${context}/sale-deposits`, search: `?SalesOrder=${SalesOrder}`, component: SaleDeposits },
      DepositDetails: { pathname: `/${context}/sale-deposits/deposit-details`, search: `?SalesOrder=${SalesOrder}&TransactionNumber=${TransactionNumber}`, component: SaleDeposits },
      PaymentEntryDetails: { pathname: `/${context}/sale-deposits/deposit-details/payment-entry-details`, search: `?SalesOrder=${SalesOrder}&TransactionNumber=${TransactionNumber}&DebtorCashObject=${DebtorCashObject}`, component: SaleDeposits },
      SalesDiary: { pathname: `/${context}/sales-diary`, search: `?SalesOrder=${SalesOrder}`, component: Diary },
      DiaryDetails: { pathname: `/${context}/sales-diary/diary-details`, search: `?SalesOrder=${SalesOrder}&DiaryId=${DiaryId}`, component: Diary },
      Notepad: { pathname: `/${context}/notepad`, search: `?SalesOrder=${SalesOrder}`, component: NotePad },
      null: { pathname: `/${context}/`, search: `?SalesOrder=${SalesOrder}`, component: SalesOrderEnquiry }
    });
  }

  handleOrderChange = () => {
    const { selectedTab } = this.props;
    this.fetchChildRecords(selectedTab);
  };

  fetchChildRecords = (selectedTab: string, _movedToParent: boolean = false) => {
    if (typeof this[`get${selectedTab}`] === 'function' && !_movedToParent) {
      this[`get${selectedTab}`]();
    } else if (selectedTab === 'Comment') {
      this.getOrderDetails();
    }

  }

  moveBackToParentTab = () => {
    const { selectedTab }: any = this.props;

    if (isTabChild(MODULE_TREE, selectedTab)) {
      const parentTab = findParentTab(MODULE_TREE, selectedTab);
      this.handleModuleChange(parentTab);
    }

  }

  handleToggleMenuOptionOpen = () => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  };

  shouldComponentUpdate(nextProps: ISalesOrderEnquiryViewProperties, nextState: any): boolean {

    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: ISalesOrderEnquiryViewProperties): void {
    const context = 'sales-order-enquiry';
    const { operationMode } = this.props;
    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (this.props.selectedTab && prevProps.selectedTab !== this.props.selectedTab) {
      this.handleModuleChange(this.props.selectedTab);
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm(context, this.props.selectedTab);
    }
    if ((this.props.selectedOrder && !prevProps.selectedOrder) || ((this.props.selectedOrder && prevProps.selectedOrder) && (this.props.selectedOrder.SalesOrder !== prevProps.selectedOrder.SalesOrder))) {
      this.props.fetchOrderSummary(this.props.selectedOrder.SalesOrder);
    }
  }

  getOrderDetails = () => {
    const { selectedOrder } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    if (SalesOrder) {
      this.props.getOrderDetails(SalesOrder);
    }
  };

  getLineDetails = () => {
    const { selectedOrder, selectedOrderLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber) {
      const query = { SalesOrder: SalesOrder, LineNumber: LineNumber };
      this.props.getOrderLineDetails(query);
      this.getOrderLineSummary();
    }
  };

  getReservationDetails = () => {
    const { selectedOrder, selectedOrderLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber) {
      const query = { SalesOrder: SalesOrder, LineNumber: LineNumber };
      this.props.getReservationDetails(query);
      this.getOrderLineSummary();
    }
  };

  getCDReservationDetails = () => {
    const { selectedOrder, selectedOrderLine, selectedBOMLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedBOMLine && selectedBOMLine.LineNumber) || params.get('BOMLineNumber');
    const LineRef = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber && LineRef) {
      const query = { SalesOrder: SalesOrder, LineNumber: LineNumber };
      this.props.getCDReservationDetails(query);
      this.getComponentDetailsSummary();
    }
  };

  getComponentDetailsSummary = () => {
    const { selectedOrder, selectedOrderLine, selectedBOMLine, componentSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedBOMLine && selectedBOMLine.LineNumber) || params.get('BOMLineNumber');
    const LineRef = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber && LineRef
      && (!componentSummary
        || (componentSummary.values
          && componentSummary.values.LineNumber !== parseInt(LineNumber)))) {
      const query = { SalesOrder: SalesOrder, LineNumber: LineNumber };
      this.props.getComponentDetailsSummary(query);
    }
  }

  getOrderLineSummary = () => {
    const { selectedOrder, selectedOrderLine, orderLineSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber
      && (!orderLineSummary
        || (orderLineSummary.LineNumber !== parseInt(LineNumber)))) {
      const query = { SalesOrder: SalesOrder, LineNumber: LineNumber };
      this.props.getOrderLineSummary(query);
    }
  }

  getDeliveryDetails = () => {
    const { selectedOrder, selectedDelivery } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const DeliveryId = (selectedDelivery && selectedDelivery.DeliveryId) || params.get('DeliveryId');
    if (SalesOrder && DeliveryId) {
      this.props.getDeliveryDetails(DeliveryId);
    }
  };

  getLDCommittedProductionOrder = () => {
    const { selectedOrder, selectedOrderLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (SalesOrder && LineNumber) {
      this.props.getLDCommittedProductionOrder({ SalesOrder: SalesOrder, LineNumber: LineNumber });
      this.getOrderLineSummary();
    }
  };

  getDepositDetailsSummary = () => {
    const { selectedOrder, selectedTransactions, depositDetailsSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const TransactionNumber = (selectedTransactions && selectedTransactions.TransactionNumber) || params.get('TransactionNumber');
    if (SalesOrder && TransactionNumber &&
      (!depositDetailsSummary
        || (depositDetailsSummary.TransactionNumber !== parseInt(TransactionNumber)))) {
      this.props.getDepositDetailsSummary({ TransactionNumber });
    }
  }

  getComponentDetails = () => {
    const { selectedOrder, selectedOrderLine, selectedBOMLine } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const LineNumber = (selectedBOMLine && selectedBOMLine.LineNumber) || params.get('BOMLineNumber');
    const LineRef = (selectedOrderLine && selectedOrderLine.LineNumber) || params.get('LineNumber');
    if (LineNumber && SalesOrder && LineRef) {
      const query = {
        LineNumber: LineNumber,
        SalesOrder: SalesOrder
      };
      this.props.getComponentDetails(query);
      this.getComponentDetailsSummary();
    }
  };

  getPaymentEntryDetails = () => {
    const { selectedOrder, selectedTransactions, selectedDepositDetails } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const SalesOrder = (selectedOrder && selectedOrder.SalesOrder) || params.get('SalesOrder');
    const TransactionNumber = (selectedTransactions && selectedTransactions.TransactionNumber) || params.get('TransactionNumber');
    const DebtorCashObject = (selectedDepositDetails && selectedDepositDetails.DebtorCashObject) || params.get('DebtorCashObject');
    if (SalesOrder && TransactionNumber && DebtorCashObject) {
      this.props.getPaymentEntryDetails({ DebtorCashObject });
      this.getDepositDetailsSummary();
    }
  };

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'OrderDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('OrderDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
  };

  render(): React.ReactNode {
    const { isMenuOptionOpen } = this.props;
    const selectedTab = this.props.selectedTab || 'OrderDetails';

    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.saleOrderEnquiryOuter} >
        <div className={styles.saleOrderEnquiryContainer}>
          <OrderSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              () => { this.moveBackToParentTab(); },
              () => { this.fetchChildRecords(selectedTab, isTabChild(MODULE_TREE, selectedTab)); },
            ]}
          />
          <div
            style={inlineStyles.saleOrderEnquirySection}
            className={styles.saleOrderEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Sales Order Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel />
            {routes.map((route) => (
              < Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => {
                  return <route.component onInitialLoad={this.handleModuleChange} path={route.pathname} />;
                }}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={(selectedTab === 'LineDetails'
            || selectedTab === 'LDCommittedInPutAway'
            || selectedTab === 'LDCommittedPurchaseOrder'
            || selectedTab === 'LDCommittedProductionOrder'
            || selectedTab === 'ReservationDetails'
            || selectedTab === 'LDReservationSerials'
            || selectedTab === 'LDReservationLots'
            || selectedTab === 'SOEBOM'
            || selectedTab === 'LineInvoices')
            ? options.LineDetails
            : selectedTab === 'ComponentDetails'
              || selectedTab === 'CDCommittedInPutAway'
              || selectedTab === 'CDCommittedPurchaseOrder'
              || selectedTab === 'CDCommittedProductionOrder'
              || selectedTab === 'CDReservationDetails'
              || selectedTab === 'CDReservationSerials'
              || selectedTab === 'CDReservationLots'
              ? options.ComponentDetails
              : options.OrderDetails}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'sales-order-enquiry'} />
      </div>
    );
  }
}

export default withRouter(SalesOrderEnquiryView);
