import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset, change } from 'redux-form';
import ChangeDeliveryDialog from './ChangeDeliveryDialog';
import { IApplicationState } from 'ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  dirty: isDirty('ChangeDeliveryForm')(state),
  values: getFormValues('ChangeDeliveryForm')(state),
  isValid: isValid('ChangeDeliveryForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset('ChangeDeliveryForm'),
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change('ChangeDeliveryForm', fieldName, fieldValue),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDeliveryDialog);
