
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as uiSelectors } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import { selectors as slipDetailsSelectors } from 'ducks/pickSlipEnquiry/slipDetails';

import SlipDetails from './SlipDetails';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  slipDetails: slipDetailsSelectors.slipDetails(state)
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SlipDetails);
