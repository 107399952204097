import React from 'react';
import IDeliveriesProperties, { IDeliveriesHandle } from './Deliveries.properties';
import styles from './Deliveries.styles';
import FormView from 'components/FormView/FormView';
import PurchaseOrderDeliveriesForm from './Deliveries.form';
import { Cancel as CancelIcon, CheckCircle } from '@markinson/uicomponents-v2/SvgIcons';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { withStyles } from '@material-ui/core';
import { useBOCContextSelector } from '../../BackorderCommitment.context';
import { useCalculatePurchaseOrderDeliveryDetails, useRetrievePurchaseOrderDeliveryDetail, useSavePurchaseOrderDeliveryDetails, useSetDeliveryDetailQueryData } from 'api/Worksale/backorderCommitment';
import { objectifyAddressForPost } from 'api/utils';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const Deliveries = (props: IDeliveriesProperties, ref: React.Ref<IDeliveriesHandle>) => {
    const { dirty, classes, formValues, changeConfirmationDialog, resetForm, warehouse } = props;

    const { setActionBar } = React.useContext(ActionBarContext);

    const PurchaseOrderId = useBOCContextSelector<'PurchaseOrderId'>((state) => state.PurchaseOrderId);

    const retrieveDeliveryDetail = useRetrievePurchaseOrderDeliveryDetail(PurchaseOrderId);
    const calculateDeliveryDetail = useCalculatePurchaseOrderDeliveryDetails();
    const saveDeliveryDetails = useSavePurchaseOrderDeliveryDetails();

    const updateRetreiveDeliveryDetailsData = useSetDeliveryDetailQueryData();

    React.useEffect(
        () => {
            resetForm();
            setActionBarButtons();
        },
        []
    );

    const rightIcons = [
        {
            label: 'Cancel',
            Icon: CancelIcon,
            iconStyle: { fill: 'rgba(178, 0, 0, 1)' },
            action: 'PurchaseOrderDeliveriesCancel',
        },
        {
            label: 'OK',
            Icon: CheckCircle,
            action: 'PurchaseOrderDeliveriesOk',
        },
    ];

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (dirty) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return !dirty;
            },
            async onOK(handleModuleChange: (page: string) => void): Promise<void> {
                const response = await saveDeliveryDetails.mutateAsync({
                    ...formValues,
                    ...objectifyAddressForPost(formValues.DeliveryAddress, 'Delivery', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
                    PurchaseOrderId
                });
                if (response.Status === true) {
                    showSnackBar({ variant: 'success', message: 'Delivery Details successfully updated.' });
                    handleModuleChange('PurchaseOrderLines');
                } else if ((response.Status as unknown) === 'ValidationError') {
                    showSnackBar({ variant: 'error', message: (response as any).ValidationErrors[0].Message });
                } else {
                    showSnackBar({ variant: 'error', message: 'Delivery Details failed to update.' });
                }
            },
            onCancel(handleModuleChange: (page: string) => void): void {
                if (dirty) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: () => {
                            resetForm();
                            handleModuleChange('PurchaseOrderLines');
                        }
                    });
                } else {
                    resetForm();
                    handleModuleChange('PurchaseOrderLines');
                }

            },
        }),
        [PurchaseOrderId, formValues]
    );

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [],
            centerIcons: [],
            rightIcons
        });
    };

    const calculateDeliveryDetails = async (fieldName: string, ChangedField = {}) => {
        const response = await calculateDeliveryDetail.mutateAsync({
            ...formValues,
            ...objectifyAddressForPost(formValues.DeliveryAddress, 'Delivery', ['Addressee', 'Address1', 'Address2', 'City', 'State', 'PostCode', 'Country']),
            ...ChangedField,
            PurchaseOrderId,
            urlQueryParams: {
                ChangedFieldId: fieldName
            }
        });
        if (response?.Status === true) {
            updateRetreiveDeliveryDetailsData(PurchaseOrderId, response);
        }
    };

    const handleSitecodeChange = async (item) => {
        if (item?.Code && retrieveDeliveryDetail.data?.DeliveryDetail?.inlineObject?.SiteCode !== item?.Code) {
            await calculateDeliveryDetails('SiteCode', { SiteCode: item?.Code });
        }
    };

    return (
        <div className={classes.formContainer}>
            <FormView
                hidden={false}
                isLoading={false}
                formName={'PurchaseOrderDeliveriesForm'}
                schema={PurchaseOrderDeliveriesForm}
                includeTabsHeight={false}
                initialValues={retrieveDeliveryDetail.data?.DeliveryDetail?.inlineObject}
                valuesSchema={retrieveDeliveryDetail.data?.DeliveryDetail?.schema}
                operationMode={'EDIT'}
                entity={warehouse}
                fieldExtensions={{
                    SiteCode: {
                        onSelectedItemChange: handleSitecodeChange,
                    }
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Deliveries)));
