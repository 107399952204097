import { IFormViewForm } from 'components/FormView';

const CustomerProductsForm: IFormViewForm = {
  id: 'CustomerProducts',
  layout: {
    rows: 1,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        label: 'Customer product details',
        name: 'pricing',
        required: true,
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 2,
      },
      children: [
        {
          id: '1',
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Our product',
            name: 'OurProduct',
            size: 'medium',
            api: '/customerenquiry/lookup/action',
            params: {
              queryFields: {
                ProductId: 'OurProduct',
                SearchText: { Field: 'OurProduct', Property: 'Display' }
              }
            }
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Product group',
            name: 'ProductGroup',
            size: 'small'
          }
        },
        {
          type: 'EXTENDED_TEXT_FIELD',
          props: {
            label: 'Customer product',
            name: 'CustomerProduct',
            size: 'medium'
          }
        },
        {
          id: '4',
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comment',
            fullWidth: true,
            style: {
              height: '200px'
            }
          }
        },
      ]
    },
  ]
};
export default CustomerProductsForm;
