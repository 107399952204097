import { fetchPost, objectify, swapObjectValues } from '../utils';
import moment from 'moment';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export const search = (query) => {
  const { filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const respType = 'Inline';

  const EffectiveDate = filters && filters.EffectiveDate && moment(filters.EffectiveDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(
    `/PriceCheck/GetPricesByWarehouse`,
    filters && {
      ...filters,
      EffectiveDate: EffectiveDate,
      CustomerId: filters.CustomerId ? filters.CustomerId : 0,
    },
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    respType
  )
    .then(
      (result) => {
        if (result)
          if (result.PricesByWarehouse)
            return {
              records: result.PricesByWarehouse.map(item => objectify(swapObjectValues(item), null, 'Inline')),
              nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
              currPage: result.BatchInformation && result.BatchInformation.BatchPage,
            }
          else
            return result

      })
}
