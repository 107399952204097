import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';

interface IIconsCellRendrerProps {
    classes: any;
    icons: string[];
    style?: React.CSSProperties;
}

const classesStyles = createStyles({
    iconsCellContainer: {
        padding: 5
    },
    icon: {
        padding: '0 5px'
    }
});

class IconsCellRendrer extends Component<IIconsCellRendrerProps> {
    render(): React.ReactNode {
        const { icons, classes, style } = this.props;

        return (
            <div style={style} className={classes.iconsCellContainer}>
                {icons && icons.map((icon, key) => {
                    return <div key={key} className={classes.icon}>{icon}</div>;
                })}
            </div>
        );
    }
}

export default withStyles(classesStyles, { index: 1 })(IconsCellRendrer);
