import { combineReducers } from 'redux';
import details from './details';
import userFields from './userFields';
import supplier, { ISupplierState } from './supplier';
import activity, { ISupplierActivityState } from './activity';
import balances from './balances';
import pricing from './pricing';
import marketing from './marketing';
import supplierDetailsTransactions, { ISupplierDetailsTransactionsState } from './supplierDetailsTransactions';
import supplierTransactions from './supplierTransactions';
import localShipments from './purchasing/localShipments';
import backorders from './backorders';
import quotes from './quotes';
import supplierContacts, { ISupplierContactState } from './supplierContacts';
import salesOrders from './salesOrders';
import supplierDeliveryDetails, { ISupplierDeliveryDetailState } from './deliveryDetails';
import financial, { ISupplierFinancialState } from './financial';
import purchasing, { ISupplierPurchasingState } from './purchasing';
import settings, { ISupplierSettingsState } from './settings';
import attachments, { ISupplierAttachmentsState } from './attachments';

export interface ISupplierEnquiryState {
  supplier: ISupplierState;
  supplierContacts: ISupplierContactState;
  supplierDeliveryDetails: ISupplierDeliveryDetailState;
  activity: ISupplierActivityState;
  financial: ISupplierFinancialState;
  purchasing: ISupplierPurchasingState;
  supplierDetailsTransactions: ISupplierDetailsTransactionsState;
  settings: ISupplierSettingsState;
  attachments: ISupplierAttachmentsState;
  [name: string]: any;
}
const combined = combineReducers<ISupplierEnquiryState>({
  details,
  supplierContacts,
  supplierDeliveryDetails,
  userFields,
  supplier,
  activity,
  pricing,
  marketing,
  balances,
  supplierTransactions,
  supplierDetailsTransactions,
  localShipments,
  backorders,
  quotes,
  salesOrders,
  financial,
  purchasing,
  settings,
  attachments
});

export default combined;
