import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from 'ducks/reducers';
import { selectors as operationSelectors, actions as operationActions } from 'ducks/uiOperations';
import { isDirty, reset, getFormValues, getFormSyncErrors, isValid } from 'redux-form';
import { selectors as uiSelectors, actions as uiActions } from 'ducks/ui';
import { selectors as formSelectors } from 'ducks/form';
import {
    selectors as activitySelectors,
    //actions as activityActions
} from 'ducks/activityMaintenance/activity';
import {
    selectors as capabilitySelectors,
    actions as capabilityActions
} from 'ducks/activityMaintenance/capability';

import Capabilities from './Capabilities';

const FORM_NAME = 'Capabilities';

const mapStateToProps = (state: IApplicationState) => ({
    isValid: isValid(FORM_NAME)(state),
    formValues: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    operationMode: operationSelectors.operationMode(state),
    selectedTab: uiSelectors.selectedTab(state),
    selectedForm: formSelectors.selected(state),
    isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
    activityData: activitySelectors.activityData(state),
    selectedActivity: activitySelectors.selected(state),
    selectedCapability: capabilitySelectors.selected(state),
    selectedCapabilitySchema: capabilitySelectors.selectedSchema(state),
    dirty: isDirty(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        resetForm: () => reset(FORM_NAME),
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        changeOperationMode: operationActions.changeOperationMode,
        addCapability: capabilityActions.create,
        deleteCapability: capabilityActions.delete
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Capabilities);
