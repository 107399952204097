import React from 'react';
import { useEMContextSelector, useEMDispatch } from './ExtractMaintenance.context';
import { RouteComponentProps } from 'react-router';

export const useTabLocations = (url: string, location: RouteComponentProps['location']) => {

    const contextDispatch = useEMDispatch();
    const TemplateId = useEMContextSelector<'TemplateId'>((state) => state.TemplateId);

    const tabLocations = React.useMemo(
        () => {

            return ({
                TemplateDetails: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}template-details`,
                    search: `?TemplateId=${TemplateId}`
                },
                Fields: {
                    pathname: `${url.endsWith('/') ? url : `${url}/`}fields`,
                    search: `?TemplateId=${TemplateId}`
                },
            });
        },
        [url, TemplateId]
    );

    React.useEffect(
        () => {
            const params = (new URLSearchParams(location.search));
            const TemplateIdParam = params.get('TemplateId') || '';

            contextDispatch({
                setTemplateId: Number(TemplateIdParam),
            });
        },
        [contextDispatch, location]
    );

    return {
        tabLocations
    };
};
