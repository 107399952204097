import { IFormViewForm } from 'components/FormView';

const PurchaseOrderDetails: IFormViewForm = {
  id: 'PurchaseOrderDetails',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: 1,
      position: {
        row: 1,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Supplier',
            name: 'Supplier',
            size: 'small',
            lookupName: 'Supplier',
            required: true,
          }
        },
        {
          id: 1,
          type: 'ADDRESS_BLOCK_EX',
          visible: true,
          props: {
            name: 'SupplierAddress',
            type: 'extended',
          }
        },
        {
          id: 2,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Contact',
            name: 'Contact',
            placeholder: '',
            size: 'large',
            required: false,
          }
        },
        {
          id: 3,
          type: 'ACTION_FIELD',
          props: {
            label: 'Phone',
            name: 'ContactPhone',
            size: 'medium',
            disabled: true,
            action: {
              iconName: 'Phone',
            }
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 2
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 0,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Order type',
            name: 'OrderType',
            size: 'small',
            required: true,
            lookupName: 'POrderTransactionType',
          }
        },
        {
          id: 1,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Order category',
            name: 'PurchaseOrderCategory',
            size: 'small',
            lookupName: 'POrderCategory',
            required: true,
          }
        },
        {
          id: 2,
          type: 'EX_LOOKUP_FIELD',
          props: {
            label: 'Tax class',
            name: 'TaxClass',
            required: true,
            size: 'small',
            lookupName: 'TaxClass',
          }
        },
        {
          id: 3,
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Quote reference',
            name: 'QuoteReference',
            size: 'medium',
          }
        },
        {
          id: 4,
          type: 'DATE_FIELD',
          visible: true,
          props: {
            label: 'Ship date',
            name: 'ShipDate',
            size: 'small',
          }
        },
        {
          id: 5,
          type: 'DATE_FIELD',
          visible: true,
          props: {
            label: 'Earliest due',
            name: 'EarliestDueDate',
            size: 'small',
          }
        },
      ]
    },
    {
      id: 1,
      position: {
        row: 1,
        col: 3
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: 6,
          type: 'TEXT_AREA',
          props: {
            label: 'Comment',
            name: 'Comments',
            size: 'large',
            style: {
              'height': '510px',
              '& label': {
                paddingTop: 0,
              }
            },
          }
        }
      ]
    },
    {
      id: '4',
      position: {
        row: 2,
        col: 1
      },
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        fullWidth: true
      },
      children: [
        {
          id: '0',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 1',
            name: 'UserDefinedCat1',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '1',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 2',
            name: 'UserDefinedCat2',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '2',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 3',
            name: 'UserDefinedCat3',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '3',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 4',
            name: 'UserDefinedCat4',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '4',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 5',
            name: 'UserDefinedCat5',
            placeholder: '',
            size: 'small',
          }
        },
        {
          id: '5',
          type: 'TEXT_FIELD',
          props: {
            label: 'Category 6',
            name: 'UserDefinedCat6',
            placeholder: '',
            size: 'small',
          }
        },
      ]
    },
  ]
};

export default PurchaseOrderDetails;
