import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, objectifyAddress, fetchGet, objectifyForLabel, objectify } from '../utils'

export const searchQuotes = (query) => {
  const { SearchText, Sort, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query
  return fetchPost(
    'CustomerQuoteEnquiry/SearchQuotes',
    { SearchText, Sort, BatchPage, BatchSize }
  )
    .then((something) => ({
      records: something.Quote.map((val) => Object.assign({}, { inlineObject: objectify(val, null, 'Inline'), schema: val })),
      currPage: something.BatchInformation && something.BatchInformation.BatchPage,
      nextPage: something.BatchInformation && !something.BatchInformation.EndOfData,
      prevPage: something.BatchInformation && something.BatchInformation.BatchPage > 1
    }))
}


export const searchById = ({ Quote }) => {
  return fetchGet(`/CustomerQuoteEnquiry/Quote/${Quote}`, null, 'Inline')
    .then((something) => {
      const newQuote = something.Quote && { ...something.Quote, CustomerId: something.Quote.Customer, Quote: something.Quote.DocumentNumber };
      return ({
        records: (newQuote && [{ inlineObject: objectify(newQuote, null, 'Inline'), schema: newQuote }]) || [],
        currPage: 1,
        nextPage: false,
        prevPage: false
      })
    })
}
export const fetchCustomerQuoteDetails = (query) => {
  const { QuoteId } = query
  return fetchGet('CustomerQuoteEnquiry/Quote/' + QuoteId, {})
    .then((something) => {
      if (something) {
        const Quote = objectify(something.Quote);
        return {
          inlineObject: Object.assign(Quote, {
            PostalAddress: objectifyAddress(Quote, 'Postal'),
            DeliveryAddress: objectifyAddress(Quote, 'Delivery')
          }), schema: something.Quote
        }
      }
    })
}
export const fetchQuoteSummary = (query) => {
  const { QuoteId } = query
  return fetchGet(`CustomerQuoteEnquiry/Quote/${QuoteId}/TotalsSummary`, null, 'Inline')
    .then((something) => {
      if (something) {
        const dataObject = something.QuoteTotalsSummary;
        const labeledDataObject = {
          ...dataObject,
          EstimatedLaborCharge: {
            ...dataObject.EstimatedLaborCharge,
            Data: dataObject.EstimatedLaborCharge.Value,
            Value: dataObject.EstimatedLaborCharge.Display,
          },
          EstimatedMaterialsCharge: {
            ...dataObject.EstimatedMaterialsCharge,
            Data: dataObject.EstimatedMaterialsCharge.Value,
            Value: dataObject.EstimatedMaterialsCharge.Display,
          },
          EstimatedSundryCharge: {
            ...dataObject.EstimatedSundryCharge,
            Data: dataObject.EstimatedSundryCharge.Value,
            Value: dataObject.EstimatedSundryCharge.Display,
          },
          EstimatedTotal: {
            ...dataObject.EstimatedTotal,
            Data: dataObject.EstimatedTotal.Value,
            Value: dataObject.EstimatedTotal.Display,
          },
          ExchangeRate: {
            ...dataObject.ExchangeRate,
            Data: dataObject.ExchangeRate.Value,
            Value: dataObject.ExchangeRate.Display,
          },
          LocalLaborCharge: {
            ...dataObject.LocalLaborCharge,
            Data: dataObject.LocalLaborCharge.Value,
            Value: dataObject.LocalLaborCharge.Display,
          },
          LocalMaterialsCharge: {
            ...dataObject.LocalMaterialsCharge,
            Data: dataObject.LocalMaterialsCharge.Value,
            Value: dataObject.LocalMaterialsCharge.Display,
          },
          LocalQuoteTotal: {
            ...dataObject.LocalQuoteTotal,
            Data: dataObject.LocalQuoteTotal.Value,
            Value: dataObject.LocalQuoteTotal.Display,
          },
          LocalSubtotal: {
            ...dataObject.LocalSubtotal,
            Data: dataObject.LocalSubtotal.Value,
            Value: dataObject.LocalSubtotal.Display,
          },
          LocalSundryCharge: {
            ...dataObject.LocalSundryCharge,
            Data: dataObject.LocalSundryCharge.Value,
            Value: dataObject.LocalSundryCharge.Display,
          },
          LocalTaxTotal: {
            ...dataObject.LocalTaxTotal,
            Data: dataObject.LocalTaxTotal.Value,
            Value: dataObject.LocalTaxTotal.Display,
          },
          OriginalLaborCharge: {
            ...dataObject.OriginalLaborCharge,
            Data: dataObject.OriginalLaborCharge.Value,
            Value: dataObject.OriginalLaborCharge.Display,
          },
          OriginalMaterialsCharge: {
            ...dataObject.OriginalMaterialsCharge,
            Data: dataObject.OriginalMaterialsCharge.Value,
            Value: dataObject.OriginalMaterialsCharge.Display,
          },
          OriginalQuoteTotal: {
            ...dataObject.OriginalQuoteTotal,
            Data: dataObject.OriginalQuoteTotal.Value,
            Value: dataObject.OriginalQuoteTotal.Display,
          },
          OriginalSubtotal: {
            ...dataObject.OriginalSubtotal,
            Data: dataObject.OriginalSubtotal.Value,
            Value: dataObject.OriginalSubtotal.Display,
          },
          OriginalSundryCharge: {
            ...dataObject.OriginalSundryCharge,
            Data: dataObject.OriginalSundryCharge.Value,
            Value: dataObject.OriginalSundryCharge.Display,
          },
          OriginalTaxTotal: {
            ...dataObject.OriginalTaxTotal,
            Data: dataObject.OriginalTaxTotal.Value,
            Value: dataObject.OriginalTaxTotal.Display,
          }
        }
        return { inlineObject: objectifyForLabel(labeledDataObject), schema: labeledDataObject }
      }
    })

}
export const fetchQuoteLines = (query) => {
  const { QuoteId, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query;
  return fetchGet(
    'CustomerQuoteEnquiry/Quote/' + QuoteId + '/QuoteLines',
    { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize },
    'Inline'
  )
    .then(
      (result) =>
        ({
          records: result.QuoteLine.map((val) =>
            Object.assign({}, objectify(val))),
          nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
          currPage: result.BatchInformation && result.BatchInformation.BatchPage,
        }))
}
export const fetchQuoteLineDetail = (query) => {
  const { QuoteId, LineNumber } = query
  return fetchGet(`CustomerQuoteEnquiry/Quote/${QuoteId}/Line/${LineNumber}`, null, 'Inline')
    .then((something) => (something && { inlineObject: objectify(something.QuoteLine), schema: something.QuoteLine }))
}
export const fetchQuoteLineSummary = (query) => {
  const { QuoteId, LineNumber } = query
  return fetchGet('CustomerQuoteEnquiry/Quote/' + QuoteId + '/Line/' + LineNumber + '/QuoteLineSummary', {}, 'Inline')
    .then((something) => something && objectify(something.QuoteLine))
}
export const fetchReservationDetails = (query) => {
  const { QuoteId, LineNumber } = query
  return fetchGet('CustomerQuoteEnquiry/Quote/' + QuoteId + '/Line/' + LineNumber + '/LineReservations/Details', {}, 'Inline')
    .then((something) => (something && { inlineObject: objectify(something.ReservationDetail), schema: something.ReservationDetail }))
}
export const fetchQuoteSummaryPanel = (query) => {
  const { QuoteId } = query
  return fetchGet('CustomerQuoteEnquiry/Quote/' + QuoteId + '/Summary', {})
    .then((something) => (something && something.QuoteSummary))
}
