import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    parentContainer: {
        width: '100%',
        padding: '0',
        boxSizing: 'border-box',
        height: 'calc(100% - 125px)',
        overflow: 'auto',
        flexGrow: 1
    },
    dataGrid: {
        height: '100%',
    },
    headerFilter: {
        '& span': {
            color: '#959595 !important',
            cursor: 'pointer',
        }
    },
    lineDetailIcon: {
        cursor: 'pointer'
    },
    disabledLineDetailIcon: {
        cursor: 'default',
        fill: 'grey'
    },
    actionIcon: {
        'color': '#337ab7 !important',
        '&.dx-state-disabled': {
            color: '#AAA !important'
        }
    },
});
