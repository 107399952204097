import { connect } from 'react-redux';
import SummaryPanel from './SummaryPanel';
import { selectors as cashReceiptSelectors } from 'ducks/cashReceipting/cashReceipt';
import { selectors as allocateSelectors } from 'ducks/cashReceipting/allocate';

const mapStateToProps = (state) => ({
  CashReceiptingSummary: cashReceiptSelectors.cashReceiptSummary(state),
  isLoading: cashReceiptSelectors.isLoading(state) || allocateSelectors.isLoading(state),
});

export default connect(mapStateToProps, {})(SummaryPanel);
