import { Payload, usePost, useFetch, useFetchMutation, APIMethod, prepareUrl } from 'api/reactQuery/queryService';
import {
    IPickSlipTransactionLinesFacade, IPickSlipTransactionLinesSearchResponse,
    IPickSlipSummaryDetailsResponse, IPickSlipSummaryFacade, IPickSlipLineDetailsFacadeResponse,
    IPickSlipLineDetailsFacade, IDespatchGridLineCriteriaFacade, IUpdateDespatchGridLineDetailResponse,
    IDeletePickSlipLineCriteriaFacade, IDeletePickSlipLinesResponse, IPickSliplineLostsaleResponse, IPickSlipOutputResponse, ICalculateChargeLineResponse, IChargeLineDetailFacade, IChargeLineSummaryFacade
} from 'api/swaggerTypes';
import { IBatchInfo } from 'components/common/DataGridDevEx/DataGrid.properties';
import { APIRoutes } from './constants';
import { AsObjectified, IObjectified, Inline, objectify, } from 'api/utils';
import { toObjectified } from 'api/baseApi';
import { useQueryClient } from '@tanstack/react-query';

const STALETIME = 120000;

export type IObjectifiedPickSlipSummaryResponse = AsObjectified<IPickSlipSummaryDetailsResponse, 'PickSlipSummary'>;

export type InlinePaymentDetail = Inline<IPickSlipSummaryFacade>;
export type InlinePaymentDetailsResponse = Omit<IPickSlipSummaryDetailsResponse, 'PickSlipSummary'> & {
    PickSlipSummary: InlinePaymentDetail;
};

const pickSlipLinesResponseParser = (response): IPickSlipTransactionLinesSearchResponse => {
    const { PickSlipLines } = response;

    return {
        ...response,
        PickSlipLines: PickSlipLines.map((o) => objectify(o as Inline<IPickSlipTransactionLinesFacade>))
    };
};
export type IRetrievePickSlipLinesPayload = Payload<{ DespatchId: number }, IBatchInfo>;
export const useRetrievePickSlipLines = () =>
    usePost<IRetrievePickSlipLinesPayload, IPickSlipTransactionLinesSearchResponse>
        (APIRoutes.pickSlips.lines.retrieve, null, { inline: true, responseParser: pickSlipLinesResponseParser });

const pickSlipSummaryResponseParser = (response: InlinePaymentDetailsResponse): IObjectifiedPickSlipSummaryResponse => {
    const { PickSlipSummary } = response;

    return {
        ...response,
        PickSlipSummary: toObjectified(PickSlipSummary as Inline<IPickSlipSummaryDetailsResponse>)
    };
};
export const useRetrievePickSlipSummary = (DespatchId) =>
    useFetch<IObjectifiedPickSlipSummaryResponse>(
        {
            url: APIRoutes.pickSlips.summary.retrieve,
            urlParams: { DespatchId },
            queryConfig: { enabled: Boolean(DespatchId), staleTime: STALETIME },
            options: { inline: true, responseParser: pickSlipSummaryResponseParser }
        }
    );

const lineDetailResponseParser = (response) => {
    const { PickSlipLineDetails } = response;

    return {
        ...response,
        PickSlipLineDetails: PickSlipLineDetails ? toObjectified(PickSlipLineDetails) : null
    };
};

export type ICalculateGridLinePayload = Payload<IPickSlipLineDetailsFacade & { DespatchId?: number; LineNumber?: number }, { ChangedField?: string }>;
export type ICalculateGridLineResponse = AsObjectified<IPickSlipLineDetailsFacadeResponse, 'PickSlipLineDetails'>;

export const useCalculateGridLine = () =>
    usePost<ICalculateGridLinePayload, ICalculateGridLineResponse>(
        APIRoutes.pickSlips.lines.calculate,
        null,
        { inline: true, responseParser: lineDetailResponseParser }
    );

type IObjectifiedPickSlipLineDetailResponse = Omit<IPickSlipLineDetailsFacadeResponse, 'PickSlipLineDetails'> & {
    PickSlipLineDetails: IObjectified<IPickSlipLineDetailsFacade>;
};

const pickSlipLineDetailResponseParser = (response) => ({
    ...response,
    PickSlipLineDetails: response?.PickSlipLineDetails ? toObjectified(response?.PickSlipLineDetails as Inline<IPickSlipLineDetailsFacade>) : null
});

export type ICalculateLinePayload = Payload<IPickSlipLineDetailsFacade & { DespatchId?: number; DespatchLineId?: number }, { ChangedField?: string }>;
export const useCalculateLine = () =>
    usePost<ICalculateLinePayload, IObjectifiedPickSlipLineDetailResponse>(
        APIRoutes.pickSlips.lines.detail.calculate,
        null,
        { inline: true, responseParser: pickSlipLineDetailResponseParser }
    );

const updateLineResponseParser = (response) => {
    const { DespatchGridLineCriteria } = response;

    return {
        ...response,
        DespatchGridLineCriteria: DespatchGridLineCriteria ? toObjectified(DespatchGridLineCriteria) : null
    };
};

export type IObjectifiedUpdateDespatchGridLineDetailResponse = AsObjectified<IUpdateDespatchGridLineDetailResponse, 'DespatchGridLineCriteria'>;

export type IDespatchGridLineCriteriaFacadeExtended = { DespatchId?: number; LineNumber?: number } & IDespatchGridLineCriteriaFacade;
export const useUpdateGridLine = () =>
    usePost<Payload<IDespatchGridLineCriteriaFacadeExtended>, IObjectifiedUpdateDespatchGridLineDetailResponse>(
        APIRoutes.pickSlips.lines.update,
        null,
        { inline: true, responseParser: updateLineResponseParser }
    );

export type IObjectifiedUpdateLineDetailResponse = AsObjectified<IPickSlipLineDetailsFacadeResponse, 'PickSlipLineDetails'>;
export type IUpdateLinePayload = Payload<{ DespatchId?: number; DespatchLineId?: number } & IPickSlipLineDetailsFacade>;

export const useUpdateLine = () =>
    usePost<IUpdateLinePayload, IObjectifiedUpdateLineDetailResponse>(
        APIRoutes.pickSlips.lines.detail.update,
        null,
        { inline: true, responseParser: lineDetailResponseParser }
    );

export const useGetUpdateLineCachedData = () => {
    const queryClient = useQueryClient();

    return (DespatchId: number, DespatchLineId: number): IObjectifiedUpdateLineDetailResponse =>
        queryClient.getQueriesData<IObjectifiedUpdateLineDetailResponse>([
            APIMethod.POST,
            prepareUrl(APIRoutes.pickSlips.lines.detail.update, { DespatchId, DespatchLineId }, null, true)
        ])?.[0]?.[1] ?? null;
};

export const useGetUpdateGridLineCachedData = () => {
    const queryClient = useQueryClient();

    return (DespatchId: number, LineNumber: number): IObjectifiedUpdateDespatchGridLineDetailResponse =>
        queryClient.getQueriesData<IObjectifiedUpdateDespatchGridLineDetailResponse>([
            APIMethod.POST,
            prepareUrl(APIRoutes.pickSlips.lines.update, { DespatchId, LineNumber }, null, true)
        ])?.[0]?.[1] ?? null;
};

const deleteLineResponseParser = (response) => {
    const { DeletePickSlipLineCriteria } = response;

    return {
        ...response,
        DeletePickSlipLineCriteria: DeletePickSlipLineCriteria ? toObjectified(DeletePickSlipLineCriteria) : null
    };
};

export type IDeleteGridLinePayload = { DespatchId?: number; LineNumber: number } & IDeletePickSlipLineCriteriaFacade;

export const useDeletePickSlipLines = () =>
    usePost<Payload<IDeleteGridLinePayload>, IDeletePickSlipLinesResponse>
        (APIRoutes.pickSlips.lines.delete, null, { inline: true, responseParser: deleteLineResponseParser });

const lostSaleResponseParser = (response): IObjectifiedPickSliplineLostsaleResponse => {
    const { PickSliplineLostsale } = response;

    return {
        ...response,
        PickSliplineLostsale: PickSliplineLostsale ? toObjectified(PickSliplineLostsale) : null
    };
};

export type IObjectifiedPickSliplineLostsaleResponse = AsObjectified<IPickSliplineLostsaleResponse, 'PickSliplineLostsale'>;

export const useInitializeLineLostSale = () =>
    useFetchMutation<Payload<{ DespatchId?: number; LineNumber?: number }>, IObjectifiedPickSliplineLostsaleResponse>(
        APIRoutes.pickSlips.lines.initializeLostSale,
        null,
        {
            inline: true,
            responseParser: lostSaleResponseParser
        }
    );

export type IObjectifiedPrintResponse = AsObjectified<IPickSlipOutputResponse, 'PickSlip'>;

const printDataResponseParser = (response): IObjectifiedPrintResponse => {
    const { PickSlip } = response;

    return {
        ...response,
        PickSlip: PickSlip ? toObjectified(PickSlip) : null
    };
};

/**
  * Get Print Data
  * @param DespatchId Dispatch Id.
  * @param DocumentReprint False for documents being generated(e.g. Sales Processing) True for reprint (e.g. Pick Slips).
  * @returns IObjectifiedPrintResponse
  */
export const useRetrievePrintData = (DespatchId, DocumentReprint: boolean) =>
    useFetch<IObjectifiedPrintResponse>(
        {
            url: APIRoutes.pickSlips.print.retrieve,
            urlParams: { DespatchId },
            urlQueryParams: { DocumentReprint },
            options: { inline: true, responseParser: printDataResponseParser }
        }
    );
export type IObjectifiedCalculateChargeLineResponseResponse = AsObjectified<ICalculateChargeLineResponse, 'ChargeLineDetail'> & AsObjectified<ICalculateChargeLineResponse, 'ChargeLineSummary'>;

const calculateChargesResponseParser = (response): IObjectifiedCalculateChargeLineResponseResponse => ({
    ...response,
    ChargeLineDetail: response?.ChargeLineDetail ? toObjectified(response?.ChargeLineDetail as Inline<IChargeLineDetailFacade>) : null,
    ChargeLineSummary: response?.ChargeLineSummary ? toObjectified(response?.ChargeLineSummary as Inline<IChargeLineSummaryFacade>) : null,
});

export const useCalculateCharges = () =>
    usePost<Payload<IChargeLineDetailFacade, { ChangedField: string }>, IObjectifiedCalculateChargeLineResponseResponse>
        (APIRoutes.pickSlips.charges.calculate, null, { inline: true, responseParser: calculateChargesResponseParser });

export const useUpdateCharges = () =>
    usePost<Payload<IChargeLineDetailFacade>, IObjectifiedCalculateChargeLineResponseResponse>
        (APIRoutes.pickSlips.charges.update, null, { inline: true });
