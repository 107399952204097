import React from 'react';
import FormView from 'components/FormView';
import ServiceAgreementDetailForm from './ServiceAgreementDetail.form';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import ArrowBack from '@markinson/uicomponents-v2/SvgIcons/ArrowBack';
import CheckCircle from '@markinson/uicomponents-v2/SvgIcons/CheckCircle';
import Cancel from '@markinson/uicomponents-v2/SvgIcons/Cancel';
import Edit from '@markinson/uicomponents-v2/SvgIcons/Edit';
import RemoveCircle from '@markinson/uicomponents-v2/SvgIcons/RemoveCircle';
import AddCircle from '@markinson/uicomponents-v2/SvgIcons/AddCircle';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';
import IServiceAgreementDetailProperties from './ServiceAgreementDetail.properties';
import { Operation } from 'utils/operations';
import { isNull } from 'utils/utils';
import moment from 'moment';
import ServiceAgreementItemModal from './ServiceAgreementItemModal';
import DataGrid from 'devextreme-react/data-grid';
import styles from './ServiceAgreementDetail.styles';
import ServiceAgreementScheduleGrid from './ServiceAgreementScheduleGrid';
import { IServiceAgreementFacade, IServiceAgreementScheduleDetailsFacade, IServiceAgreementScheduleFacade } from 'api/swaggerTypes';
import { isEqual, cloneDeep, first } from 'lodash';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const ServiceAgreementDetail = (props: IServiceAgreementDetailProperties): JSX.Element => {
  const {
    loading, formValues, operationMode, selectedServAgreement, forwardedRef, dirty, formSyncErrors, changeFormFieldValue, resetForm,
    performSearch, createServiceAgreement, updateServiceAgreement, deleteServiceAgreement, changeConfirmationDialog,
    fetchScheduleDetail, createSchedule, updateSchedule, deleteSchedule, changeValidationDialog, touchFormFields, changeOperationMode
  } = props;
  const dataGridRef = React.useRef<DataGrid>();

  const [initialValues, setInitialValues] = React.useState<any>();
  const [openItemModal, setOpenItemModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { setActionBar } = React.useContext(ActionBarContext);
  const processValidationForms = React.useContext(ProcessValidationFormsContext);
  const [agreementSchedulesData, setAgreementSchedulesData] = React.useState<IServiceAgreementScheduleFacade[]>([]);
  const [agreementScheduleModified, setAgreementScheduleModified] = React.useState<boolean>(false);
  const [itemModalData, setItemModalData] = React.useState<IServiceAgreementScheduleDetailsFacade>(null);
  const [itemEditRowIndex, setItemEditRowIndex] = React.useState<number>(-1);
  const [itemModalLoading, setItemModalLoading] = React.useState<boolean>(false);

  const inEditNew = operationMode === Operation.NEW || operationMode === Operation.EDIT;
  const isItemSelected = !isNull(selectedServAgreement);
  const leftIcons = [
    {
      label: 'Back',
      Icon: ArrowBack,
      action: 'Back',
    }
  ];
  const centerIcons = [
    {
      label: 'Add Item',
      Icon: AddCircle,
      action: 'Add',
      disabled: !inEditNew
    }];
  const rightIcons = [
    {
      label: 'New',
      Icon: AddCircle,
      action: 'New',
      disabled: inEditNew
    },
    {
      label: 'Edit',
      Icon: Edit,
      action: 'Edit',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Delete',
      Icon: RemoveCircle,
      action: 'Delete',
      disabled: inEditNew || !isItemSelected
    },
    {
      label: 'Cancel',
      Icon: Cancel,
      action: 'Cancel',
      disabled: !inEditNew
    },
    {
      label: 'OK',
      Icon: CheckCircle,
      action: 'Save',
      disabled: !inEditNew
    },
  ];

  React.useEffect(
    () => {
      changeOperationMode(Operation.BROWSE);
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    [changeOperationMode]
  );

  React.useEffect(
    () => {
      setActionBar({
        leftIcons,
        centerIcons,
        rightIcons
      }
      );
    },
    [operationMode, isItemSelected]
  );
  React.useEffect(
    () => {
      setIsLoading(loading);
    },
    [loading]
  );

  React.useEffect(
    () => {
      setInitialValues(selectedServAgreement);
      setAgreementSchedulesData(selectedServAgreement ? cloneDeep(selectedServAgreement.Schedules) : []);
    },
    [selectedServAgreement]
  );

  React.useImperativeHandle(
    forwardedRef,
    () => ({
      onEdit(): void {
        changeOperationMode(Operation.EDIT);
      },
      onCancel(): void {
        if (dirty || agreementScheduleModified) {
          changeConfirmationDialog({
            open: true,
            title: 'Discard changes',
            message: 'Are you sure you want to continue?',
            okLabel: 'Discard',
            onCancel: () => null,
            onOk: () => {
              resetForm();
              changeOperationMode(Operation.BROWSE);
              setAgreementSchedulesData(selectedServAgreement ? cloneDeep(selectedServAgreement.Schedules) : []);
              setAgreementScheduleModified(false);
            }
          });
        } else {
          resetForm();
          changeOperationMode(Operation.BROWSE);
        }
      },
      onNew(): void {
        initServiceAgreementDetail();
        changeOperationMode(Operation.NEW);
      },
      onOk(): void {

        if (!isNull(formSyncErrors)) {
          showSnackBar({ variant: 'error', message: 'Please Fill in required fields.' });
          touchFormFields('Description', 'Customer', 'StartDate', 'ExpiryDate');

          return;
        } else if (!moment(formValues.ExpiryDate, 'DD/MM/YYYY').isAfter(moment(formValues.StartDate, 'DD/MM/YYYY'))) {
          showSnackBar({ variant: 'error', message: 'Finish Date must be later than Start Date.' });

          return;
        }

        setAgreementScheduleModified(false);

        if (operationMode === Operation.NEW) {
          create(formValues)
            .then(async (response) => {
              setIsLoading(true);
              const serviceAgreementId = response.ServiceAgreementDetails && response.ServiceAgreementDetails.inlineObject.ServiceAgreementId;

              if (serviceAgreementId) {
                await createSchedules(serviceAgreementId);
              }

              if (response.Status && !response.Forms) {
                showSnackBar({ variant: 'success', message: 'Service Agreement created successfully.' });
                performSearch({ SearchText: '' });
                changeOperationMode(Operation.BROWSE);
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to create Service Agreement.' });
              }
            })
            .catch((err) => {
              showSnackBar({ variant: 'error', message: 'Failed to create Service Agreement.' });
              console.warn(err);
            })
            .finally(() => setIsLoading(false));
        } else {
          update(formValues)
            .then(async (response) => {
              setIsLoading(true);
              const serviceAgreementId = response.ServiceAgreementDetails && response.ServiceAgreementDetails.inlineObject.ServiceAgreementId;

              if (serviceAgreementId) {
                await updateSchedules(serviceAgreementId);
              }

              if (response.Status && !response.Forms) {
                showSnackBar({ variant: 'success', message: 'Service Agreement updated successfully.' });
                performSearch({ SearchText: '' });
                changeOperationMode(Operation.BROWSE);
              }
              if (!response.Status) {
                showSnackBar({ variant: 'error', message: 'Failed to update Service Agreement.' });
              }

            })
            .catch((err) => {
              showSnackBar({ variant: 'error', message: 'Failed to update Service Agreement.' });
              console.warn(err);
            })
            .finally(() => setIsLoading(false));
        }
      },
      onDelete(): void {
        changeConfirmationDialog({
          open: true,
          title: 'Delete Service Agreement',
          message: 'Are you sure you want to continue?',
          okLabel: 'Delete',
          onCancel: () => null,
          onOk: () => {
            if (deleteAgreement) {
              setIsLoading(true);

              deleteAgreement(selectedServAgreement.ServiceAgreementId)
                .then(async (response) => {
                  await deleteSchedules();

                  if (response.Status && !response.Forms) {
                    showSnackBar({ variant: 'success', message: 'Service Agreement deleted successfully.' });
                    performSearch({ SearchText: '' });
                  }
                  if (!response.Status && !response.Forms) {
                    showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete Service Agreement.' });
                  }
                })
                .catch((err) => {
                  showSnackBar({ variant: 'error', message: 'Something went wrong, failed to delete Service Agreement.' });
                  console.warn(err);
                })
                .finally(() => setIsLoading(false));
            }
          }
        });
      },
      onAdd(): void {
        setItemModalData(null);
        setOpenItemModal(true);
      },
    })
  );

  async function createSchedules(serviceAgreementId: number): Promise<void> {
    const errorsMessages = [];
    for await (const schedule of agreementSchedulesData as any) {
      schedule.ServiceAgreementId = serviceAgreementId;
      const response = await createSchedule(schedule);
      if (response && response.Forms) {
        const firstObj = first(response.Forms);
        const message = firstObj ? `(${schedule.ServiceItemDisplay}-${schedule.ServiceItemLabel}) ${firstObj.Message}` : `(${schedule.ServiceItemDisplay}-${schedule.ServiceItemLabel}) unable to update schedule.`;
        errorsMessages.push(message);
      }
    }

    if (!isNull(errorsMessages)) {
      showErrorDialogue(errorsMessages.join('\n'));
    }
  }

  function showErrorDialogue(message: string): void {
    changeValidationDialog({
      open: true,
      title: 'Error',
      form: null,
      message: message,
      actions: [
        {
          name: 'ok',
          label: 'Ok',
          isDefault: true,
          callback: () => undefined
        }
      ]
    });
  }

  async function updateSchedules(serviceAgreementId: number): Promise<void> {

    const errorsMessages = [];

    for await (const schedule of agreementSchedulesData as any) {
      schedule.ServiceAgreementId = serviceAgreementId;

      let response;
      const originalSchedule = selectedServAgreement.Schedules ?
        selectedServAgreement.Schedules.find((sch) => sch.ServiceAgreementScheduleId === schedule.ServiceAgreementScheduleId) : null;

      if (isNull(originalSchedule)) {
        response = await createSchedule(schedule);
      } else {
        if (!isEqual(schedule, originalSchedule)) {
          response = await updateSchedule(serviceAgreementId, schedule.ServiceAgreementScheduleId, schedule);
        }
      }

      if (response && response.Forms) {
        const firstObj = first(response.Forms) as any;
        const message = firstObj ? `(${schedule.ServiceItemDisplay}-${schedule.ServiceItemLabel}) ${firstObj.Message}` : `(${schedule.ServiceItemDisplay}-${schedule.ServiceItemLabel}) unable to update schedule.`;
        errorsMessages.push(message);
      }
    }

    if (!isNull(errorsMessages)) {
      showErrorDialogue(errorsMessages.join('\n'));
    }

    for await (const schedule of selectedServAgreement.Schedules) {
      if (!agreementSchedulesData.some((sch) => sch.ServiceAgreementScheduleId === schedule.ServiceAgreementScheduleId)) {
        await deleteSchedule(schedule.ServiceAgreementId, schedule.ServiceAgreementScheduleId);
      }
    }

  }

  async function create(query: IServiceAgreementFacade): Promise<any> {
    const response = await createServiceAgreement(query);

    return processValidationForms(response, query, async (validatedResponse) => create(validatedResponse));
  }

  async function update(query: IServiceAgreementFacade): Promise<any> {
    const response = await updateServiceAgreement(query.ServiceAgreementId, query);

    return processValidationForms(response, query, async (validatedResponse) => update(validatedResponse));
  }

  async function deleteSchedules(): Promise<void> {
    for await (const schedule of selectedServAgreement.Schedules) {
      await deleteSchedule(schedule.ServiceAgreementId, schedule.ServiceAgreementScheduleId);
    }
  }

  async function deleteAgreement(servicePatternId: number): Promise<any> {
    const response = await deleteServiceAgreement(servicePatternId);

    return processValidationForms(response, servicePatternId, async (validatedResponse) => deleteAgreement(validatedResponse));
  }

  function initServiceAgreementDetail(pattern: IServiceAgreementFacade = null): void {
    changeFormFieldValue('ServiceAgreementId', pattern ? pattern.ServiceAgreementId : null);
    changeFormFieldValue('Description', pattern ? pattern.Description : '');
    changeFormFieldValue('StartDate', pattern ? pattern.StartDate : '');
    changeFormFieldValue('ExpiryDate', pattern ? pattern.ExpiryDate : '');
    changeFormFieldValue('Comment', pattern ? pattern.Comment : null);
    changeFormFieldValue('Customer', pattern ? pattern.Customer : '');
    setAgreementSchedulesData([]);
  }

  function handleGridOnEdit(e: any): void {
    const rowData = e.row.data;
    setOpenItemModal(true);
    setItemEditRowIndex(e.row.rowIndex);

    if (!isNull(rowData)) {
      if (rowData.hasOwnProperty('Items')) {  //data is already present
        setItemModalData(rowData);
      } else {
        const { ServiceAgreementId, ServiceAgreementScheduleId } = rowData;
        setItemModalLoading(true);
        fetchScheduleDetail(ServiceAgreementId, ServiceAgreementScheduleId)
          .then((response) => {
            setItemModalData(response.ServiceAgreementScheduleDetails.inlineObject);
          })
          .catch((err) => console.warn(err))
          .finally(() => setItemModalLoading(false));
      }
    }
  }

  function handleItemModalOnSubmit(d: IServiceAgreementScheduleDetailsFacade): void {
    setOpenItemModal(false);
    if (itemEditRowIndex > -1) {
      updateRowData(itemEditRowIndex, d);
    } else {
      addRowData(d);
    }
    setItemEditRowIndex(-1);
    setItemModalData(null);
    setAgreementScheduleModified(true);
  }

  function updateRowData(rowIndex: number, data: IServiceAgreementScheduleDetailsFacade): void {
    const itemsCopy = cloneDeep(agreementSchedulesData);
    itemsCopy[rowIndex] = data;
    setAgreementSchedulesData(itemsCopy);
  }

  function addRowData(data: IServiceAgreementScheduleDetailsFacade): void {
    const itemsCopy = cloneDeep(agreementSchedulesData);
    const maxId = isNull(itemsCopy) ? 0 : Math.max(...itemsCopy.map((i) => i.ServiceAgreementScheduleId));
    itemsCopy.push({ ...data, ServiceAgreementScheduleId: maxId + 1 });
    setAgreementSchedulesData(itemsCopy);
  }

  function onItemModalClose(): void {
    setItemEditRowIndex(-1);
    setOpenItemModal(false);
    setItemModalData(null);
  }

  return (
    <React.Fragment>
      <FormView
        isLoading={isLoading}
        formName={'ServiceAgreementDetail'}
        style={{
          height: 343
        }}
        schema={ServiceAgreementDetailForm}
        initialValues={initialValues}
        operationMode={operationMode}
      />
      <div style={styles.templatesGridContainer}>
        <ServiceAgreementScheduleGrid
          loading={isLoading}
          disabled={operationMode === Operation.BROWSE}
          itemsData={agreementSchedulesData}
          dataGridRef={dataGridRef}
          onEditClicked={handleGridOnEdit}
          changeConfirmationDialog={changeConfirmationDialog}
          onUpdate={() => { setAgreementScheduleModified(true); }}
          onDelete={() => { setAgreementScheduleModified(true); }}
          onInsert={() => { setAgreementScheduleModified(true); }}
          onRowRecordDelete={(ServiceAgreementScheduleId) => {
            const updatedAgreementSchedulesData = agreementSchedulesData.filter((agreementSchedule) => agreementSchedule.ServiceAgreementScheduleId !== ServiceAgreementScheduleId);
            setAgreementSchedulesData(updatedAgreementSchedulesData);
            setAgreementScheduleModified(true);
          }}
        />
      </div>
      {openItemModal && <ServiceAgreementItemModal loading={itemModalLoading} open={openItemModal} data={itemModalData} onClose={onItemModalClose} onSubmit={handleItemModalOnSubmit} />}
    </React.Fragment>
  );
};

export default ServiceAgreementDetail;
