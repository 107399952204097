import React from 'react';
import DespatchConfirmationDialog from './DespatchConfirmationDialog';
import FreightChargesDialog from './FreightChargesDialog';
import SalesSurchargeDialog from './SalesSurchargeDialog';
import DespatchDetailsDialog from './DespatchDetailsDialog';
import { usePickSlipConfirmation, useProcessPickSlip, useRemoveInvoiceChargeLines } from 'api/pickSlips/pickslip';
import { isScreenRequest } from 'api/utils';
import { IProcessPickSlipDialogsProperties } from './ProcessPickSlipDialogs.properties';
import PaymentEntryWrapper from 'components/common/PaymentEntryWrapper';
import { IProcessPaymentDetailsResponse } from 'api/swaggerTypes';

const ProcessPickSlipDialogs = (props: IProcessPickSlipDialogsProperties) => {
    const { selectedDespatchId, linesRef, summaryRef, forwardedRef } = props;

    const [freightChargesDialogOpen, setFreightChargesDialogOpen] = React.useState<boolean>(false);
    const [salesSurchargeDialogOpen, setSalesSurchargeDialogOpen] = React.useState<boolean>(false);
    const [despatchDetailsDialogOpen, setDespatchDetailsDialogOpen] = React.useState<boolean>(false);
    const [despatchConfirmationOpen, setDespatchConfirmationOpen] = React.useState(false);
    const [paymentEntryOpen, setPaymentEntryOpen] = React.useState<boolean>(false);

    const confirmPickSlipMutation = usePickSlipConfirmation();
    const processPickSlipMutation = useProcessPickSlip();
    const removeInvoiceChargeLinesMutation = useRemoveInvoiceChargeLines();

    const processDespatchCriteria = processPickSlipMutation.data?.ProcessDespatchCriteria?.inlineObject;

    const processPickSlipOnClick = React.useCallback(
        async () => {
            const response = await confirmPickSlipMutation.mutateAsync({
                DespatchId: selectedDespatchId
            });

            if (response?.Status === true) {
                setDespatchConfirmationOpen(true);
            }
        },
        [confirmPickSlipMutation, selectedDespatchId]
    );

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            process: processPickSlipOnClick,
        }),
        [processPickSlipOnClick]
    );

    const processPickSlip = React.useCallback(
        async (data) => {
            const response = await processPickSlipMutation.mutateAsync({
                ...data,
                FreightCharge: data?.FreightCharge?.inlineObject ?? data?.FreightCharge,
                SalesSurcharge: data?.SalesSurcharge?.inlineObject ?? data?.SalesSurcharge,
                DespatchDetail: data?.DespatchDetail?.inlineObject ?? data?.DespatchDetail
            });

            if (isScreenRequest(response?.Forms, 'FreightCharges')) {
                setDespatchConfirmationOpen(false);
                setFreightChargesDialogOpen(true);

                return;
            }
            if (isScreenRequest(response?.Forms, 'SalesSurcharge')) {
                setDespatchConfirmationOpen(false);
                setSalesSurchargeDialogOpen(true);

                return;
            }
            if (isScreenRequest(response?.Forms, 'DespatchDetails')) {
                setDespatchConfirmationOpen(false);
                setDespatchDetailsDialogOpen(true);

                return;
            }
            if (isScreenRequest(response?.Forms, 'PaymentEntry')) {
                setDespatchConfirmationOpen(false);
                setPaymentEntryOpen(true);

                return;
            }

            if (response?.Status === true && response?.Forms?.[0]?.MessageType === 'Success') {
                setDespatchConfirmationOpen(false);
                linesRef.current?.refreshDataGrid();
                summaryRef.current?.reloadSummary();
            }
        },
        [linesRef, summaryRef]
    );

    const freightChargeOnOk = React.useCallback(
        (values) => {
            processPickSlip({
                ...processDespatchCriteria,
                FreightCharge: values
            });
            setFreightChargesDialogOpen(false);
        },
        [processPickSlipMutation.data, processPickSlip]
    );
    const salesChargeOnOk = React.useCallback(
        (values) => {
            processPickSlip({
                ...processDespatchCriteria,
                SalesSurcharge: values
            });
            setSalesSurchargeDialogOpen(false);
        },
        [processPickSlipMutation.data, processPickSlip]
    );

    const despatchDetailsOnOk = React.useCallback(
        (values) => {
            processPickSlip({
                ...processDespatchCriteria,
                DespatchDetail: values
            });
            setDespatchDetailsDialogOpen(false);
        },
        [processPickSlipMutation.data, processPickSlip]
    );

    const handleApplyPaymentEntry = React.useCallback(
        async (paymentResponse: IProcessPaymentDetailsResponse) => {
            if (paymentResponse.Status) {
                setPaymentEntryOpen(false);
                processPickSlip({
                    ...processPickSlipMutation?.data?.ProcessDespatchCriteria?.inlineObject,
                    PaymentDetail: paymentResponse?.ProcessPaymentDetail
                });
            }
        },
        [setPaymentEntryOpen, processPickSlip, processPickSlipMutation?.data]
    );

    const removeInvoiceChargeLines = React.useCallback(
        () => {
            removeInvoiceChargeLinesMutation.mutate({
                InvoiceNumber: processDespatchCriteria?.Invoice,
                SalesEntity: processDespatchCriteria?.SalesEntity,
                urlQueryParams: {
                    FreightLineNumber: processDespatchCriteria?.FreightLineNumber
                }
            });
        },
        [processPickSlipMutation.data]
    );

    const freightChargesOnCancel = React.useCallback(
        () => {
            removeInvoiceChargeLines();
            setFreightChargesDialogOpen(false);
        },
        [removeInvoiceChargeLines]
    );
    const salesSurchargeOnCancel = React.useCallback(
        () => {
            removeInvoiceChargeLines();
            setSalesSurchargeDialogOpen(false);
        },
        [removeInvoiceChargeLines]
    );
    const despatchDetailsOnCancel = React.useCallback(
        () => {
            removeInvoiceChargeLines();
            setDespatchDetailsDialogOpen(false);
        },
        [removeInvoiceChargeLines]
    );
    const paymentEntryOnCancel = React.useCallback(
        async () => {
            removeInvoiceChargeLines();
            setPaymentEntryOpen(false);
        },
        [removeInvoiceChargeLines]
    );

    return (
        <>
            <DespatchConfirmationDialog
                open={despatchConfirmationOpen}
                initialValues={confirmPickSlipMutation.data?.DespatchConfirmation?.inlineObject}
                valuesSchema={confirmPickSlipMutation.data?.DespatchConfirmation?.schema}
                onCancel={() => { setDespatchConfirmationOpen(false); }}
                onOk={processPickSlip}
            />
            <FreightChargesDialog
                open={freightChargesDialogOpen}
                data={processDespatchCriteria?.FreightCharge}
                onOk={freightChargeOnOk}
                onCancel={freightChargesOnCancel} />
            <SalesSurchargeDialog
                open={salesSurchargeDialogOpen}
                data={processDespatchCriteria?.SalesSurcharge}
                onOk={salesChargeOnOk}
                onCancel={salesSurchargeOnCancel} />
            <DespatchDetailsDialog
                open={despatchDetailsDialogOpen}
                data={processDespatchCriteria?.DespatchDetail}
                onOk={despatchDetailsOnOk}
                onCancel={despatchDetailsOnCancel} />
            <PaymentEntryWrapper
                open={paymentEntryOpen}
                initialValues={processPickSlipMutation?.data?.ProcessDespatchCriteria?.inlineObject?.PaymentInformation}
                onCancel={paymentEntryOnCancel}
                onApply={handleApplyPaymentEntry}
            />
        </>
    );
};

export default ProcessPickSlipDialogs;
