import { fetchPost, objectify } from '../../utils'
export const fetch = (query) => {
  const { Supplier, filters } = query
  const respType = 'Inline';


  return fetchPost(`/SupplierEnquiry/Supplier/${Supplier}/SearchImages`, filters ?
    {
      ...filters
    } : {}, null, respType)
    .then(
      (result) => {
        if (result.Images)
          return {
            inlineObject: result.Images.map(item => objectify(item, null, 'Inline')),
            schema: result.Images
          }
      })
}
