import SummaryTableSchema from './SummaryPanel/SummaryTableSchema';

export const TABS_DATA = [
    { id: 'Lines', label: 'Lines' },
    { id: 'Details', label: 'Pick Slip Details' },
    { id: 'Contact', label: 'Contact' },
];

export const MainScreenTabs = ['Lines', 'Details', 'Contact', 'SalesNotepad', 'SalesDiary'];

export const SummaryPanelSchemas = {
    Lines: SummaryTableSchema,
    Details: SummaryTableSchema,
    Contact: SummaryTableSchema,
    SalesDiary: SummaryTableSchema,
};

export const LookupAndSummary = ['Lines', 'Details', 'Contact', 'SalesDiary'];

export const PickSlipLineCalculationFields = ['Discount', 'InvoiceExchangeRate', 'Price', 'BackOrderedQuantity', 'SuppliedQuantity', 'KittingQuantity', 'OrderedQuantity', 'QuoteCurrency', 'QuotedExchangeRate', 'ExchangeDeposit'];

export const DespatchStatusTypes = ['Finalised', 'Cancelled', 'In Error'];

export const ChargesDialogNonCalculationFields = ['Description', 'Comment'];
export const ChargesDialogCalculationFields = { DefaultDiscount: 'Discount' };
