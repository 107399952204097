import { Inline, objectify, IObjectified } from 'api/utils';
import { IDashboardWidget } from 'components/DashboardMaintenance/DashboardMaintenance.properties';
import { Api, asObjectified } from '../baseApi';
import { IDashboardWidgetDetails, IDashboardWidgetResponse as DashboardResponse, IFormInfo, IDashboardWidgetsResponse } from 'api/swaggerTypes';
import { Payload, usePost } from 'api/reactQuery/queryService';
import { APIRoutes } from 'api/constants';

export interface IObjectifiedWidgetInfoResponse {
    WidgetInfo: IObjectified<IDashboardWidgetDetails>;
    Status?: boolean;
    Forms?: IFormInfo[];
}

export const getUserDashboards = async (isDefault: boolean) => {
    const params = new URLSearchParams('');
    params.set('defaultDashboard', isDefault ? 'True' : 'False');

    return Api.getInline(`DashboardMaintenance/UserDashboardWidgets?${params.toString()}`)
        .then((response: { DashboardWidgets?: Inline<IDashboardWidget>[] }) => {
            return {
                ...response,
                DashboardWidgets: response.DashboardWidgets.map((w) => ({ ...objectify(w), Id: w.WidgetId }))
            };
        });
};

export const useSaveDashboards = () => {

    return usePost<Payload<{ arrayTypePayload: IDashboardWidget[] }>, IDashboardWidgetsResponse>
        (APIRoutes.Dashboard.Save, null, { inline: true });
};

export const setWidgetConfiguration = async ({ WidgetId, Configuration }): Promise<IObjectifiedWidgetInfoResponse> => {
    const config = JSON.stringify(Configuration);

    return Api.postInlineCollectionResponse<any, DashboardResponse, 'DashboardWidget'>(
        `DashboardMaintenance/DashboardWidget/UpdateConfiguration/${WidgetId}`, { Configuration: config })
        .then((response) => {
            return {
                ...response,
                WidgetInfo: response.DashboardWidget ? asObjectified(response, 'DashboardWidget') : null
            };
        });
};
