import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    main: {
        maxHeight: '420px',
        marginTop: '10px',
        height: '100%',
    },
    preLoader: {
        margin: '10px 130px'
    }
});
