import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { types as BOMLineTypes, actions as BOMLineActions } from 'ducks/customerQuoteEnquiry/BOM';

import { callApi } from '../utils';
import * as api from 'api/customerQuoteEnquiry/BOM';

function* fetchLineDetails(action) {
  const { success, failure } = BOMLineActions.saga.fetchLineDetails;

  yield callApi(
    call(api.fetchLineDetails, action.data),
    success,
    failure
  )
}
function* fetchLineDetailSummary(action) {
  const { success, failure } = BOMLineActions.saga.fetchLineDetailSummary;

  yield callApi(
    call(api.fetchLineDetailSummary, action.data),
    success,
    failure
  )
}

function* fetchReservationDetails(action) {
  const { success, failure } = BOMLineActions.saga.fetchReservationDetails;

  yield callApi(
    call(api.fetchReservationDetails, action.data),
    success,
    failure
  )
}

export default function* rootBOMSaga() {
  yield takeLatest(BOMLineTypes.fetchLineDetails, fetchLineDetails)
  yield takeLatest(BOMLineTypes.fetchLineDetailSummary, fetchLineDetailSummary)
  yield takeLatest(BOMLineTypes.fetchReservationDetails, fetchReservationDetails)
}