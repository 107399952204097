import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { fetchPost, fetchGet, objectify } from '../utils';
import moment from 'moment';

export const search = async (query: { SearchText: string; filters: any; BatchPage?: number; BatchSize?: number }): Promise<{ records: any[]; nextPage?: boolean; currPage?: number; prevPage?: boolean } | any> => {
  const { SearchText, filters, BatchPage, BatchSize = DEFAULT_PAGE_SIZE } = query;

  const From = filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

  return fetchPost(
    '/ProductMovementEnquiry/Search', {
    SearchText, ...filters, FromDate: From,
    ToDate: To, BatchPage: BatchPage || 1,
    BatchSize
  },
    null, 'Inline')
    .then((result: {
      PMEProductSearchs: any[];
      BatchInformation: {
        BatchPage: number;
        EndOfData: boolean;
      };
    }) => (result && result.PMEProductSearchs &&
    {
      records: result.PMEProductSearchs.map((val: any) => objectify(val, null, 'Inline')),
      currPage: result.BatchInformation && result.BatchInformation.BatchPage,
      nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
      prevPage: result.BatchInformation && result.BatchInformation.BatchPage > 1
    })
      ||
      result);
};

export const searchById = async ({ ProductMovementId }): Promise<{ records: any[]; nextPage?: boolean; currPage?: number; prevPage?: boolean }> => {
  return fetchGet(`/ProductMovementEnquiry/ProductMovemenDetails/Product/${ProductMovementId}`, null, 'Inline')
    .then((result: { PMEMovementDetail: any }) => (result && result.PMEMovementDetail && {
      records: [objectify(result.PMEMovementDetail, null, 'Inline')],
      currPage: 1,
      nextPage: false,
      prevPage: false
    }) || { records: [] });
};

export const getDetails = async ({ ProductMovementId }): Promise<{ details?: { Values: any; Schema: any } }> => {
  return fetchGet(`/ProductMovementEnquiry/ProductMovemenDetails/Product/${ProductMovementId}`, null, 'Inline')
    .then((result: { PMEMovementDetail: any }) => (result && result.PMEMovementDetail && {
      details: { Values: objectify(result.PMEMovementDetail, null, 'Inline'), Schema: result.PMEMovementDetail }
    }) || { details: null });
};

export const getSummary = async ({ ProductMovementId }): Promise<{ summary?: any }> => {
  return fetchGet(`/ProductMovementEnquiry/ProductMovemenDetails/Product/${ProductMovementId}`, null, 'Inline')
    .then((result: { PMEMovementDetail: any }) => (result && result.PMEMovementDetail && {
      summary: objectify(result.PMEMovementDetail, null, 'Inline')
    }) || { summary: null });
};
