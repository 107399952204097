import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as rfcSelectors } from 'ducks/rfcEnquiry/rfc';
import { selectors as rfcDetailsSelectors, actions as rfcDetailsActions } from 'ducks/rfcEnquiry/rfcDetails';
import { selectors as rfcLineSelectors } from 'ducks/rfcEnquiry/RFCLines';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import RFCEnquiry from './RFCEnquiry';
import { selectors as diarySelectors } from 'ducks/common/diary';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedRFC: rfcSelectors.selected(state),
  rfcSummary: rfcDetailsSelectors.summary(state),
  rfcDetails: rfcDetailsSelectors.rfcDetails(state),
  rfcComment: rfcDetailsSelectors.comment(state),
  selectedRFCLine: rfcLineSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  selectedDiary: diarySelectors.selected(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getRFCSummary: rfcDetailsActions.fetchSummary,
    getRFCDetailsSummary: rfcDetailsActions.fetchRFCSummary,
    getRFCComment: rfcDetailsActions.fetchComment,
    getRFCDetails: rfcDetailsActions.fetch,
    lockDrawer: uiActions.lockDrawer,
    unlockDrawer: uiActions.unlockDrawer,
    closeMenu: uiActions.closeMainMenu
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RFCEnquiry);
