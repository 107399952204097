import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as binLocationsSelectors, actions as binLocationsActions } from 'ducks/inventoryEnquiry/stocking/binLocations';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { actions as formActions } from 'ducks/form';
import { search } from 'api/inventoryEnquiry/stocking/binLocations';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingBinLocations',
  columnDefs: binLocationsSelectors.columns(state),
  appliedFilters: binLocationsSelectors.filterRow(state) && filterSelectors.getFilters(state, binLocationsSelectors.filterRow(state).formName),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: binLocationsSelectors.gridOptions(state),
  isLoading: binLocationsSelectors.isLoading(state),
  filterRow: binLocationsSelectors.filterRow(state),
  selectedFilters: binLocationsSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    { ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId') },
  detailColumns: binLocationsSelectors.detailColumns(state),
  detailCellRenderer: binLocationsSelectors.detailCellRenderer(state),
  apiMethod: search
})

export const actions = {
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: binLocationsActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
