
export const MODULE_TREE = [
    { id: 'SupplierDetails', label: 'Supplier Details', parent: '' },
    { id: 'Contacts', label: 'Contacts', parent: '' },
    { id: 'DeliveryDetails', label: 'Delivery Details', parent: '' },
    { id: 'UserFields', label: 'User Fields', parent: '' },
    { id: 'Notepad', label: 'Notepad', parent: '' },
    { id: 'Financial', label: 'Financial', parent: '' },
    { id: 'SupplierFinancialBalances', label: 'Balances', parent: 'Financial' },
    { id: 'Transactions', label: 'Transactions', parent: 'Financial' },
    { id: 'TransactionDetails', label: 'Transaction Details', parent: 'Transactions' },
    { id: 'DetailsTransactions', label: 'Transactions', parent: 'TransactionDetails' },
    { id: 'CreditDiary', label: 'Credit Diary', parent: 'Financial' },
    { id: 'CreditDiaryDetails', label: 'Diary Details', parent: 'CreditDiary' },
    { id: 'SupplierFinancialChildAccounts', label: 'Child Accounts', parent: 'Financial' },
    { id: 'Activity', label: 'Activity', parent: '' },
    { id: 'SupplierActivityInvoices', label: 'Invoices', parent: 'Activity' },
    { id: 'SupplierActivityMovements', label: 'Movements', parent: 'Activity' },
    { id: 'SupplierActivityGRNs', label: 'GRNs', parent: 'Activity' },
    { id: 'SupplierActivityFreight', label: 'Freight', parent: 'Activity' },
    { id: 'SupplierActivityRFCs', label: 'RFCs', parent: 'Activity' },
    { id: 'Purchasing', label: 'Purchasing', parent: '' },
    { id: 'PurchaseOrder', label: 'Purchase Order', parent: 'Purchasing' },
    { id: 'Products', label: 'Products', parent: 'Purchasing' },
    { id: 'PurchaseDiary', label: 'Purchase Diary', parent: 'Purchasing' },
    { id: 'PurchaseDiaryDetails', label: 'Purchase Diary Details', parent: 'PurchaseDiary' },
    { id: 'LocalShipments', label: 'Local Shipment', parent: 'Purchasing' },
    { id: 'SupplierPurchasingProducts', label: 'Products', parent: 'Purchasing' },
    { id: 'SupplierPurchaseOrder', label: 'Purchase Orders', parent: 'Purchasing' },
    { id: 'Settings', label: 'Settings', parent: '' },
    { id: 'SupplierFinancialSettings', label: 'Financial', parent: 'Settings' },
    { id: 'SupplierGeneralSettings', label: 'General', parent: 'Settings' },
    { id: 'SupplierSettingsByWarehouse', label: 'Settings by W/H', parent: 'Settings' },
    { id: 'SupplierSettingsAttributes', label: 'Attributes', parent: 'Settings' },
    { id: 'Attachments', label: 'Attachments', parent: '' },
    { id: 'Images', label: 'Images', parent: 'Attachments' },
    { id: 'Documents', label: 'Documents', parent: 'Attachments' },
    { id: 'Links', label: 'Links', parent: 'Attachments' }
];
