import { IFormViewForm } from 'components/FormView';

const DiaryDetails: IFormViewForm = {
  id: 'DiaryDetails',
  layout: {
    rows: 1,
    columns: 1
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      props: {
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1,
        colspan: 3
      },
      visible: true,
      children: [
        {
          id: '1',
          type: 'DIARY_DETAILS',
          props: {
            label: 'SupplierInvoiceDiary',
            name: 'DiaryDetails',
            createApi: '/ReturnForCreditEnquiry/RFC/<RFCNumber>/CreateDiary',
            updateApi: '/ReturnForCreditEnquiry/RFC/<RFCNumber>/Diary/<DiaryId>',
            searchByIdApi: '/ReturnForCreditEnquiry/RFC/<RFCNumber>/Diary/<DiaryId>',
            deleteApi: '/ReturnForCreditEnquiry/RFC/<RFCNumber>/Diary/<DiaryId>',
          }
        }
      ]
    }
  ]
};
export default DiaryDetails;
