import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions, selectors as operationSelectors } from 'ducks/uiOperations';
import { selectors as invoiceSelectors, actions as invoiceActions } from 'ducks/salesInvoiceEnquiry/Invoice';
import { actions as invoiceLineActions, selectors as invoiceLineSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLines';
import { actions as invoiceLineBOMActions, selectors as invoiceLineBOMSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceLineBOM';
import { actions as invoiceLineSerialActions } from 'ducks/salesInvoiceEnquiry/InvoiceLineSerials';
import { actions as invoiceLineLotActions } from 'ducks/salesInvoiceEnquiry/InvoiceLineLots';
import { actions as formActions, selectors as formSelectors } from 'ducks/form';
import { selectors as paymentSelectors, actions as paymentActions } from 'ducks/salesInvoiceEnquiry/payment';
import SalesInvoiceEnquiry from './SalesInvoiceEnquiry';
import { selectors as salesAuditSelectors } from 'ducks/salesInvoiceEnquiry/InvoiceSalesAudit';
import { actions as auditDetailsActions, selectors as auditDetailsSelectors } from 'ducks/salesInvoiceEnquiry/SalesAuditDetails';
import { selectors as diarySelectors } from 'ducks/common/diary';
import { destroy } from 'redux-form';

const mapStateToProps = (state) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedPaymentLine: paymentSelectors.selected(state),
  paymentSummary: paymentSelectors.paymentSummary(state),
  paymentDetails: paymentSelectors.paymentDetails(state),
  selectedInvoice: invoiceSelectors.selected(state),
  invoiceSummary: invoiceSelectors.summary(state),
  invoiceDetails: invoiceSelectors.details(state),
  invoiceLineSummary: invoiceLineSelectors.invoiceLineSummary(state),
  invoiceLineDetails: invoiceLineSelectors.details(state),
  loadingInvoiceLineSummary: invoiceLineSelectors.loadingSummary(state),
  selectedInvoiceLine: invoiceLineSelectors.selected(state),
  selectedBOM: invoiceLineBOMSelectors.selected(state),
  bomDetails: invoiceLineBOMSelectors.details(state),
  loadingBomComponentDetails: invoiceLineBOMSelectors.loadingDetails(state),
  selectedAudit: salesAuditSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
  isMenuOptionOpen: uiSelectors.isMenuOptionOpen(state),
  selectedForm: formSelectors.selected(state),
  selectedDiary: diarySelectors.selected(state),
  loadingPaymentSummary: paymentSelectors.loadingSummary(state),
  auditDetailsSummary: auditDetailsSelectors.summary(state),
  loadingAuditSummary: auditDetailsSelectors.loadingSummary(state),
  isMainMenuOpen: uiSelectors.isMainMenuOpen(state),
  scope: uiSelectors.scope(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    toggleCustomerLookUp: uiActions.toggleCustomerLookUp,
    toggleBrowseLookUp: uiActions.toggleBrowseLookUp,
    changeOperationMode: operationActions.changeOperationMode,
    changeSelectedTab: uiActions.changeSelectedTab,
    getFormSchema: formActions.search,
    changeSelectedForm: formActions.setSelected,
    toggleMenuOption: uiActions.toggleMenuOption,
    changeConfirmationDialog: uiActions.changeConfirmationDialog,
    getInvoiceSummary: invoiceActions.getSummary,
    getInvoiceDetails: invoiceActions.getDetails,
    getInvoiceLineSummary: invoiceLineActions.getInvoiceLineSummary,
    getInvoiceLineDetails: invoiceLineActions.getInvoiceLineDetails,
    getInvoiceLineBOMDetails: invoiceLineBOMActions.fetchComponentDetails,
    getInvoiceLineSerialsByBom: invoiceLineSerialActions.fetchByBom,
    getInvoiceLineLotsByBom: invoiceLineLotActions.fetchByBom,
    getAuditDetailsSummary: auditDetailsActions.fetchSummary,
    getPaymentSummary: paymentActions.getPaymentSummary,
    getPaymentDetails: paymentActions.getPaymentDetails,
    lockDrawer: uiActions.lockDrawer,
    unlockDrawer: uiActions.unlockDrawer,
    closeMenu: uiActions.closeMainMenu,
    resetSearchLookupDrawer: invoiceActions.reset,
    destoryForm: () => destroy('InvoiceDetails'),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceEnquiry);
