import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';
import { objectify, fetchPost } from '../../utils'
import moment from 'moment'

export const fetch = (query) => {
  const { ProductId, filters, Page, Sort, SortDirection, BatchSize = DEFAULT_PAGE_SIZE } = query
  const From = (filters && filters.FromDate && moment(filters.FromDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
  const To = filters && filters.ToDate && moment(filters.ToDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  return fetchPost(`/InventoryEnquiry/Product/${ProductId}/Movements`, filters && {
    ...filters,
    FromDate: From,
    ToDate: To,
  }, { BatchPage: Page, Sort: Sort && SortDirection && `${Sort}:${SortDirection}`, BatchSize })
    .then((result) => {
      if (result)
        if (result.StockingMovementss)
          return {
            records: result.StockingMovementss.map(item => objectify(item, null, 'Inline')),
            nextPage: result.BatchInformation && !result.BatchInformation.EndOfData,
            currPage: result.BatchInformation && result.BatchInformation.BatchPage,
          }
        else
          return result

    })
}
