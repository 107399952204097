import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid, reset, change } from 'redux-form';
import { actions as documentOutputActions, selectors as documentOutputSelectors } from 'ducks/documentOutput';
import SaleConfirmationDialog from './SaleConfirmationDialog';
import { IApplicationState } from 'ducks/reducers';

const FORM_NAME = 'SaleConfirmationForm';
const mapStateToProps = (state: IApplicationState) => ({
  invoiceDocumentOutputOptions: documentOutputSelectors.options('SO', 'InvoiceOutput')(state),
  salesDocketDocumentOutputOptions: documentOutputSelectors.options('SO', 'SalesDocketOutput')(state),
  pickSlipDocumentOutputOptions: documentOutputSelectors.options('SO', 'PickSlipOutput')(state),
  orderDocumentOutputOptions: documentOutputSelectors.options('SO', 'OrderAcknowledgementOutput')(state),
  barcodeLabelOutput: documentOutputSelectors.options('SO', 'BarcodeLabelOutput')(state),
  dirty: isDirty(FORM_NAME)(state),
  values: getFormValues(FORM_NAME)(state),
  isValid: isValid(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetForm: () => reset(FORM_NAME),
    setInvoiceDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'InvoiceOutput', options),
    setSalesDocketDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'SalesDocketOutput', options),
    setPickSlipDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'PickSlipOutput', options),
    setOrderDocumentOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'OrderAcknowledgementOutput', options),
    setBarcodeLabelOutputOptions: (options) => documentOutputActions.updateInitialOptions('SO', 'BarcodeLabelOutput', options),
    changeFormFieldValue: (fieldName: string, fieldValue: any) => change(FORM_NAME, fieldName, fieldValue),
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SaleConfirmationDialog);
