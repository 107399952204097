import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { selectors as reservedSelectors, actions as reservedActions } from 'ducks/inventoryEnquiry/stocking/reserved';
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { selectors as quantitySelectors } from 'ducks/inventoryEnquiry/stocking/quantities';
import { fetch } from 'api/inventoryEnquiry/stocking/reserved';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'IEStockingQuantitiesReserved',
  columnDefs: reservedSelectors.columns(state),
  rowData: reservedSelectors.data(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: reservedSelectors.gridOptions(state),
  isLoading: reservedSelectors.isLoading(state),
  filterRow: reservedSelectors.filterRow(state),
  appliedFilters: reservedSelectors.filterRow(state) && filterSelectors.getFilters(state, reservedSelectors.filterRow(state).formName),
  selectedFilters: reservedSelectors.selectedFilters(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId')) &&
    (quantitySelectors.selected(state) || params.get('Warehouse')) &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId'),
    Warehouse: (quantitySelectors.selected(state) && quantitySelectors.selected(state).Warehouse) || params.get('Warehouse')
  },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: reservedActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
  applyFilters: filterActions.applyFilters
}
