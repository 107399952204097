import { connect } from 'react-redux';
import Contact from './Contact';
import { selectors as pickSlipSelectors } from 'ducks/pickSlips/pickSlipTransaction';
import { getFormValues, isDirty, reset } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';
import { IApplicationState } from 'ducks/reducers';

const params = new URLSearchParams(location.search);

const FORM_NAME = 'PickSlipContactForm';
const mapStateToProps = (state: IApplicationState) => ({
    despatchId: pickSlipSelectors.selected(state)?.DespatchId ?? params.get('DespatchId'),
    formValues: getFormValues(FORM_NAME)(state),
    dirty: isDirty(FORM_NAME)(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
        resetForm: () => reset(FORM_NAME),
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
