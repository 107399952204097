
import { connect } from 'react-redux';
import { IApplicationState } from '../../../ducks/reducers';
import { selectors as operationSelectors } from '../../../ducks/uiOperations';
import { selectors as uiSelectors } from '../../../ducks/ui';
import { selectors as formSelectors } from '../../../ducks/form';

import ProductDetails from './Associations';

const mapStateToProps = (state: IApplicationState) => ({
  operationMode: operationSelectors.operationMode(state),
  selectedTab: uiSelectors.selectedTab(state),
  selectedForm: formSelectors.selected(state),
  isBrowseLookUpOpen: uiSelectors.isBrowseLookUpOpen(state),
});

export default connect(mapStateToProps, {})(ProductDetails);
