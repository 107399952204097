import {
  createActions, asyncInitialState
} from '../utils';

export const { types, actions } = createActions(
  {
    setSelected: (row) => ({ row }),
    asyncs: {
    }
  },
  'events');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
    cacheBlockSize: 10,
    maxBlocksInCache: 5,
    enableMasterDetailModel: true,
    detailCellRenderer: 'CommentAreaRenderer',
    detailCellRendererParams: {
      getDetailRowData: function (params) {
        return { commentData: params.data.Comment }
      }
    }
  },
  Columns: [

    { headerName: 'Event #', field: 'EventNo', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Date', field: 'Date', type: 'numericColumn', minWidth: 120, suppressSorting: true },
    { headerName: 'Time', field: 'Time', type: 'numericColumn', suppressSorting: true, minWidth: 120 },
    { headerName: 'Description', field: 'Description', suppressSorting: true, minWidth: 250 },
    { headerName: 'Program', field: 'Program', suppressSorting: true, minWidth: 250 },
  ]
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.setSelected:
      const selected = action.data.row;

      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      };
    default:
      return state;
  }
};

const syncSelector = {
  isLoading: (state) => state.common.events.loading,
  gridOptions: (state) => state.common.events.data.gridOptions,
  columns: (state) => state.common.events.data.Columns,
  selected: (data) => data.selected,
};

export const selectors = { ...syncSelector };
