import { call, Effect, takeLatest } from 'redux-saga/effects';
import { types as extractMaintenanceTypes, actions as extractMaintenanceActions } from 'ducks/extractMaintenance/extractMaintenanceTemplates';
import { callApi } from '../utils';
import { IDataAction } from 'ducks/utils';
import * as api from 'api/extractMaintenance/extractMaintenanceTemplates';

function* search(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = extractMaintenanceActions.saga.search;
  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* searchById(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = extractMaintenanceActions.saga.searchById;

  yield callApi(
    call(api.searchById, action.data),
    success,
    failure
  );
}

function* fetchNext(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = extractMaintenanceActions.saga.fetchNextPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

function* fetchPrev(action: IDataAction): IterableIterator<Effect> {
  const { success, failure } = extractMaintenanceActions.saga.fetchPrevPage;

  yield callApi(
    call(api.search, action.data),
    success,
    failure
  );
}

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(extractMaintenanceTypes.search, search);
  yield takeLatest(extractMaintenanceTypes.searchById, searchById);
  yield takeLatest(extractMaintenanceTypes.fetchPrevPage, fetchPrev);
  yield takeLatest(extractMaintenanceTypes.fetchNextPage, fetchNext);
}
