import { IObjectified } from '../utils';
import { IActivityCapabilityLinkSearchResponse, ISortOrder, ISearchRequest, IActivityCapabilityLinkFacade, IActivityCapabilityLinkDetailsResponse } from '../swaggerTypes';
import { Api, asObjectifiedArray, asObjectified } from '../baseApi';
import { isNull } from 'utils/utils';
import { DEFAULT_PAGE_SIZE } from 'components/common/DataGridDevEx/DataGrid.constants';

export async function search(request: { SearchText: string; ActivityCode: string; Sort: ISortOrder; BatchSize?: number }): Promise<{
    records: IObjectified<IActivityCapabilityLinkFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { SearchText, ActivityCode, Sort, BatchSize = DEFAULT_PAGE_SIZE } = request;
    if (isNull(ActivityCode)) {
        return {
            records: [],
            currPage: 1,
            nextPage: false,
            prevPage: false
        };
    }

    return Api.postInlineCollectionResponse<ISearchRequest, IActivityCapabilityLinkSearchResponse, 'Capabilities'>(
        `ActivityMaintenance/Activity/${ActivityCode}/SearchCapabilities`, { SearchText, Sort, BatchSize }
    ).then((response) => {
        const records = isNull(response.Forms) ? asObjectifiedArray(response, 'Capabilities') : [];
        const batchInformation = (response as IActivityCapabilityLinkSearchResponse).BatchInformation;

        return {
            Forms: response.Forms,
            records,
            currPage: batchInformation ? batchInformation.BatchPage : 1,
            nextPage: batchInformation && !batchInformation.EndOfData,
            prevPage: batchInformation && batchInformation.BatchPage > 1
        };
    });
}

export async function searchById(query: { ActivityCode: string; CapabilityCode: string }): Promise<{
    records: IObjectified<IActivityCapabilityLinkFacade>[]; currPage: number; nextPage: boolean; prevPage: boolean;
}> {
    const { CapabilityCode, ActivityCode } = query;

    return Api.getInlineResponse<IActivityCapabilityLinkDetailsResponse, 'CapabilityDetails'>(
        `ActivityMaintenance/Activity/${ActivityCode}/Capability/${CapabilityCode}`)
        .then((response) => {
            const records = isNull(response.Forms) ? [asObjectified(response, 'CapabilityDetails')] : [];

            return {
                Forms: response.Forms,
                records: records,
                currPage: 1,
                nextPage: false,
                prevPage: false
            };
        });
}

export async function create(query: { ActivityCode: string; CapabilityCode: string }):
    Promise<{ CapabilityDetails: IObjectified<IActivityCapabilityLinkDetailsResponse> }> {
    const { ActivityCode, CapabilityCode } = query;

    return Api.postInlineResponse<IActivityCapabilityLinkFacade, IActivityCapabilityLinkDetailsResponse, 'CapabilityDetails'>(
        `ActivityMaintenance/Activity/${ActivityCode}/Capability/${CapabilityCode}`
    ).then((response) => ({ ...response, CapabilityDetails: asObjectified(response, 'CapabilityDetails') }));
}

export async function deleteCapability(query: { ActivityCode: string; CapabilityCode: string }): Promise<IActivityCapabilityLinkDetailsResponse> {
    const { ActivityCode, CapabilityCode } = query;

    return Api.delete<IActivityCapabilityLinkDetailsResponse>(

        `ActivityMaintenance/Activity/${ActivityCode}/Capability/${CapabilityCode}`)
        .then((response) => response);
}
