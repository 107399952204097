import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui'
import { actions as operationActions } from 'ducks/uiOperations'
import { selectors as BOMSelectors, actions as BOMActions } from 'ducks/inventoryEnquiry/associations/BOM'
import { selectors as productSelectors } from 'ducks/SearchLookUp/productLookup';
import { fetch } from 'api/inventoryEnquiry/associations/bom';
const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'ProductAssociationsBOM',
  columnDefs: BOMSelectors.columns(state),
  detailColumns: BOMSelectors.detailColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state),
  gridOptions: BOMSelectors.gridOptions(state),
  isLoading: BOMSelectors.isLoading(state),
  detailCellRenderer: BOMSelectors.detailCellRenderer(state),
  reqParams: (productSelectors.selected(state) || params.get('ProductId'))
    &&
  {
    ProductId: (productSelectors.selected(state) && productSelectors.selected(state).ProductId) || params.get('ProductId')
  },
  apiMethod: fetch
})

export const actions = {
  changeSelectedTab: uiActions.changeSelectedTab,
  setSelectedOrderLine: BOMActions.setSelected,
  changeOperationMode: operationActions.changeOperationMode,
}
