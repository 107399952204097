import * as React from 'react';
import { ListItem as MaterialListItem, List, ListItemText, Drawer } from '@material-ui/core';
import ExpandLess from '@markinson/uicomponents-v2/SvgIcons/ExpandLess';
import ExpandMore from '@markinson/uicomponents-v2/SvgIcons/ExpandMore';
import Scrollbars from 'react-custom-scrollbars';
import { withStyles, createStyles, StyledComponentProps, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import makeSelectable from '../../../utils/makeSelectable';

const ListItem = (props: any) => <MaterialListItem {...props} />;
ListItem.muiName = 'ListItem';

const SelectableList = makeSelectable(List);

const classStyles = (theme: Theme) => createStyles({
  drawer: {
    top: 104,
    width: 270,
    right: 0,
    left: 'initial',
    height: 'calc(100% - 64px - 40px - 67px)'
  },
  paperDrawer: theme.mixins.gutters({
    backgroundColor: '#333333',
    width: 270,
    outline: 'none',
    boxShadow: 'none',
    height: '100%'
  }),
  listItem: {
    paddingLeft: 15,
    paddingTop: 12
  },
  nestedListItem: {
    paddingLeft: 30,
    paddingTop: 12
  },
  primary: {
    color: 'rgba(255,255,255,0.87)',
    fontSize: 15,
    fontWeight: 500
  },
  secondary: {
    color: 'rgba(255,255,255,0.54)',
    fontSize: 15,
    fontWeight: 400
  }
});

const styles = createStyles({
  selectedItemStyle: {
    backgroundColor: '#555555'
  }
});

export interface IOption {
  label: string;
  value: string;
  hideInV2?: boolean;
  hideInV3?: boolean;
  SelectionDisabled?: boolean;
  children?: IOption[];
}

export interface IOptionsMenuProperties extends StyledComponentProps {
  open: boolean;
  isV2?: boolean;
  isV3?: boolean;
  options: IOption[];
  defaultValue: string;
  onOptionClick(option: any): void;
  toggleMenuOption(open: boolean): void;
  onClickAway?(): void;
}

declare interface IOptionsMenuState {
  nestedOpen: any;
  selectedOption: any;
}

class OptionsMenu extends React.Component<IOptionsMenuProperties, IOptionsMenuState> {

  static getDerivedStateFromProps(nextProps: Readonly<IOptionsMenuProperties>): Partial<IOptionsMenuState> {
    return {
      selectedOption: nextProps.defaultValue || -1,
    };
  }

  constructor(props: Readonly<IOptionsMenuProperties>) {
    super(props);
    this.state = {
      nestedOpen: {},
      selectedOption: -1
    };
  }

  componentDidMount(): void {
    const { options } = this.props;
    const nestedMenus = {};
    if (options) {
      options.map((_item, ind) => {
        return nestedMenus[ind] = true;
      });
    }
    this.setState({ nestedOpen: nestedMenus });
  }

  isSelectionDisabled = (value: any) => {
    const { options } = this.props;
    const selectionDisabled = options.filter((item) => {
      return item.SelectionDisabled && item.value === value;
    });

    return selectionDisabled.length > 0;
  }

  handleRequestChange = (_event: React.ChangeEvent, index: number) => {
    const { onOptionClick } = this.props;
    if (this.isSelectionDisabled(index)) {
      return;
    }
    this.setState({
      selectedOption: index,
    });

    onOptionClick(index);
  };

  handleClickAway = () => {
    const { open, toggleMenuOption, onClickAway } = this.props;
    if (open) {
      toggleMenuOption(false);
      if (onClickAway) {
        onClickAway();
      }
    }
  }

  handleNestedListClick = (index: number) => {
    this.setState({ nestedOpen: { ...this.state.nestedOpen, [index]: !this.state.nestedOpen[index] } });
  };

  render(): React.ReactNode {
    const { open, options, classes, isV2, isV3 } = this.props;
    const SelectableListItem = (props) => {
      const { itemchildren, parentclasses } = props;

      return (
        <div>
          <ListItem {...props} />
          {itemchildren &&
            <Collapse in={props.open} timeout='auto' unmountOnExit>
              <SelectableList component='div'
                value={this.state.selectedOption}
                onChange={this.handleRequestChange}
                selectedItemStyle={styles.selectedItemStyle}
              >
                {itemchildren.map((childItem, childInd) => {
                  if (isV2 && childItem.hideInV2) { return null; }
                  if (isV3 && childItem.hideInV3) { return null; }

                  return <ListItem button
                    value={childItem.value}
                    key={childInd}
                    className={parentclasses.nestedListItem}>
                    <ListItemText
                      classes={{ primary: classes.secondary }}
                      primary={childItem.label} />
                  </ListItem>;
                })}
              </SelectableList>
            </Collapse>
          }
        </div>

      );
    };
    (SelectableListItem as any).muiName = 'ListItem';

    return (
      <div>
        <Drawer
          anchor={'right'}
          open={open}
          className={classes.drawer}
          ModalProps={{
            hideBackdrop: true,
            disableEnforceFocus: true,
            disableRestoreFocus: true,
            disableAutoFocus: true
          }}
          PaperProps={{
            style: {
              padding: 0
            },
            className: classes.paperDrawer,
            elevation: 0
          }}
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Scrollbars>
              <SelectableList
                value={this.state.selectedOption}
                onChange={this.handleRequestChange}
                selectedItemStyle={styles.selectedItemStyle}
                component={'div'}
              >
                {options.map((item, ind) => {
                  if (isV2 && item.hideInV2) { return null; }
                  if (isV3 && item.hideInV3) { return null; }

                  return <SelectableListItem button key={ind}
                    className={classes.listItem}
                    itemchildren={item.children}
                    parentclasses={classes}
                    value={item.value}
                    open={this.state.nestedOpen[ind]}
                  >
                    <ListItemText classes={{ primary: classes.primary }}
                      primary={item.label} />
                    {item.children ? !this.state.nestedOpen[ind] ?
                      <ExpandMore onClick={item.children && (() => { this.handleNestedListClick(ind); })} style={{ color: 'white' }} /> :
                      <ExpandLess onClick={item.children && (() => { this.handleNestedListClick(ind); })} style={{ color: 'white' }} /> : null}
                  </SelectableListItem>;
                })}
              </SelectableList>
            </Scrollbars>
          </ClickAwayListener>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(classStyles, { index: 1 })(OptionsMenu);
