import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  setWarehouse: (defaultWarehouse) => ({ defaultWarehouse }),
  reset: () => null,
  asyncs: {
  }
}, 'lotDetails');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: 'StockingLotAllocationDetails',
    suppressEditDeleteInContextMenu: true,
    rowModelType: 'serverSide',
  },
  Columns: [
    { headerName: 'Lot/Serial', field: 'LotSerial', minWidth: 200, },
    { headerName: 'Quantity', field: 'QuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'In Picking', field: 'PickingQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Reserved', field: 'ReservedQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'In Put Away', field: 'PutawayQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Available', field: 'AvailableQuantityDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, },
    { headerName: 'Average cost', field: 'AverageCostDisplay', type: 'numericColumn', suppressSorting: true, minWidth: 150, }
  ],
  Data: [],
  selectedFilters: {
    Warehouse: 'QW',
    LotSerial: ''
  },
  filterRow: {
    formName: 'IELotDetailsFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Warehouse',
          name: 'Warehouse',
          lookupName: 'WarehouseEntity',
          size: 'small',
          required: true
        }
      },
      {
        id: 1,
        type: 'TEXT_FIELD',
        props: {
          label: 'Lot/Serial',
          name: 'LotSerial',
          size: 'medium',
          required: false,
        }
      },
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }
    case types.setWarehouse:
      const warehouse = action.data.defaultWarehouse;
      return {
        ...state,
        data: {
          ...state.data,
          selectedFilters: {
            ...state.data.selectedFilters,
            Warehouse: warehouse
          }
        }
      };

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.lotDetails,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.lotDetails.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.lotDetails.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.lotDetails.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.lotDetails.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.lotDetails.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)