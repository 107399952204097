import { createStyles } from '@material-ui/core/styles';

export default createStyles({
    mainDiv: {
        height: ' calc(100% - 64px)',
        top: '64px',
        position: 'relative'
    },
    innerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    PSSection: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
    },
    PSSectionMarginOpen: {
        marginLeft: '10px',
    },
    PSSectionMarginClose: {
        marginLeft: '20px',
    }
});
