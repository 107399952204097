import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PriceBreaks from './PriceBreaks';
import { actions as breaksActions, selectors as breaksSelectors } from '../../../ducks/inventoryEnquiry/pricing/priceBreaks';
import { selectors as productSelectors } from '../../../ducks/SearchLookUp/productLookup';
import { IApplicationState } from '../../../ducks/reducers';

const mapStateToProps = (state: IApplicationState) => ({
  breaksData: breaksSelectors.breaksData(state),
  selectedProduct: productSelectors.selected(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBreaks: breaksActions.getBreaks,
  },
  dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceBreaks);
