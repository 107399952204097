import { IFormViewForm } from 'components/FormView';

const ContactsForm: IFormViewForm = {
  id: 'Contacts',
  layout: {
    rows: 2,
    columns: 3
  },
  fields: [
    {
      id: '1',
      type: 'PAPER_CONTAINER',
      visible: true,
      props: {
        name: 'contact',
        fullWidth: true
      },
      position: {
        row: 1,
        col: 1
      },
      children: [
        {
          id: '1',
          type: 'TEXT_FIELD',
          visible: true,
          props: {
            label: 'Contact',
            name: 'Contact',
            fullWidth: true
          },
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Contact type',
            name: 'ContactTypeLabel',
            size: 'medium',
            fullWidth: true
          }
        },
        {
          id: '3',
          type: 'TOGGLE_FIELD',
          visible: true,
          props: {
            label: 'Primary contact',
            name: 'Primary',
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Personal details',
        name: 'personalDetails',
      },
      position: {
        row: 1,
        col: 2
      },
      children: [
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Title',
            name: 'Title',
            required: false,
            size: 'large',
            fullWidth: true
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Salutation',
            name: 'Salutation',
            size: 'small',
            required: false
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'First name',
            name: 'FirstName',
            required: false,
            fullWidth: true
          }
        },
        {
          type: 'TEXT_FIELD',
          props: {
            label: 'Last name',
            name: 'LastName',
            required: false,
            fullWidth: true
          }
        }
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Contact details',
        name: 'contactDetail',
        required: true,
      },
      position: {
        row: 1,
        col: 3
      },
      children: [
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Phone',
            name: 'Phone',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Fax',
            name: 'Fax',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Mobile',
            name: 'PhoneMobile',
            required: false,
            size: 'medium',
            action: {
              iconName: 'Phone',
            }
          }
        },
        {
          type: 'ACTION_FIELD',
          props: {
            label: 'Email',
            type: 'email',
            name: 'Email',
            size: 'large',
            required: false,
            action: {
              iconName: 'Mail',
            }
          }
        },
      ]
    },
    {
      type: 'PAPER_CONTAINER',
      props: {
        label: 'Address',
        name: 'address',
        required: true,
      },
      children: [
        {
          type: 'ADDRESS_BLOCK_EX',
          props: {
            name: 'address'
          }
        }
      ],
      position: {
        row: 2,
        col: 1
      },
    },
  ]
};
export default ContactsForm;
