import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './Notepad.styles';
import FormView from 'components/FormView';
import PickSlipNotepadForm from './Notepad.form';
import INotepadProperties, { INotepadHandle } from './Notepad.properties';
import { BACK_BUTTON } from 'components/common/ActionBar/constants';
import { ActionBarContext } from 'utils/ActionBarContextProvider';
import { Cancel as CancelIcon, CheckCircle, Edit } from '@markinson/uicomponents-v2/SvgIcons';
import { useSnackBar } from 'components/common/SnackBars/SnackBar.hooks';
import { Operation } from 'utils/operations';
import { useCreateDiaryDetail, useRetrieveDiaryDetail } from 'api/pickSlips/notepad';

const Notepad = (props: INotepadProperties, ref: React.Ref<INotepadHandle>) => {
    const {classes, operationMode, selectedDespatchId, formValues, changeOperationMode, changeConfirmationDialog, resetForm, changeFieldValue} = props;
    const { setActionBar } = React.useContext(ActionBarContext);
    const { showSnackBar } = useSnackBar();
    const NotepadData = useRetrieveDiaryDetail(selectedDespatchId);
    const createDiaryDetailMutation = useCreateDiaryDetail();

    React.useEffect(
        () => {
            setActionBarButtons();
        },
        [operationMode]
    );
    React.useEffect(
        () => {
            changeOperationMode(Operation.BROWSE);
        },
        []
    );

    const isFormDirty = () => NotepadData?.data?.Notepad?.inlineObject?.Note !== formValues.Note;

    const setActionBarButtons = (): void => {
        setActionBar({
            leftIcons: [BACK_BUTTON],
            centerIcons: [],
            rightIcons: [
                {
                    label: 'Edit',
                    Icon: Edit,
                    disabled: operationMode === Operation.EDIT || !Number(selectedDespatchId),
                    action: 'NotepadEdit',
                },
                {
                    label: 'Cancel',
                    Icon: CancelIcon,
                    disabled: operationMode === Operation.BROWSE,
                    action: 'NotepadCancel',
                },
                {
                    label: 'OK',
                    Icon: CheckCircle,
                    disabled: operationMode === Operation.BROWSE,
                    action: 'NotepadOk',
                },
            ]
        });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            isChangesSavedOrDiscarded(): boolean {
                if (operationMode === Operation.BROWSE) return false;
                const result = isFormDirty();
                if (result) {
                    showSnackBar({ variant: 'warning', message: 'Please save or discard changes.' });
                }

                return result;
            },
            NotepadEdit(): void {
                changeOperationMode(Operation.EDIT);
            },
            NotepadCancel(): void {
                if (isFormDirty()) {
                    changeConfirmationDialog({
                        open: true,
                        title: 'Discard changes',
                        message: 'Are you sure you want to continue?',
                        okLabel: 'Discard',
                        onCancel: () => undefined,
                        onOk: async () => {
                            resetForm();
                            const {data} = await NotepadData.refetch();
                            changeFieldValue('Note', data?.Notepad?.inlineObject?.Note);
                            changeOperationMode(Operation.BROWSE);
                        }
                    });
                } else {
                    changeOperationMode(Operation.BROWSE);
                }
            },
            async NotepadOk(): Promise<void> {
                const result = await createDiaryDetailMutation.mutateAsync({DespatchId: selectedDespatchId, Note: formValues.Note});
                if (result.Status) {
                    changeOperationMode(Operation.BROWSE);
                }
            },
        }),
        [formValues, operationMode, NotepadData?.data?.Notepad?.inlineObject, selectedDespatchId]
    );

    return (
        <div className={classes.formContainer}>
            <FormView
                isLoading={false}
                formName={'PickSlipNotepadForm'}
                schema={PickSlipNotepadForm}
                includeTabsHeight={false}
                initialValues={NotepadData?.data?.Notepad?.inlineObject}
                valuesSchema={NotepadData?.data?.Notepad?.schema}
                operationMode={operationMode}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(React.memo(React.forwardRef(Notepad)));
