import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import { IExportModalContentProps, IExportSpreadSheetModalProps, IFileMeta, IUseExcelExportResponse } from './DataGrid.properties';
import TextField from '@markinson/uicomponents-v2/TextFieldV1';
import { DEFAULT_EXPORT_BATCH_SIZE } from './DataGrid.constants';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs/dist/exceljs';
import saveAs from 'file-saver';

export const exportModalStyles = createStyles({
  modalContent: {
    padding: '2rem'
  }
});

const ExportModalContent = (props: IExportModalContentProps) => {
  const { className, fileMeta, setFileMeta } = props;

  return (
    <div className={className}>
      <TextField
        name='BatchSize'
        label='Maximum Rows'
        value={fileMeta.BatchSize}
        onChange={(v) => setFileMeta((prev) => ({ ...prev, BatchSize: Number(v.target.value) }))}
      />
      <TextField
        name='fileName'
        label='File Name'
        value={fileMeta.fileName}
        onChange={(v) => setFileMeta((prev) => ({ ...prev, fileName: v.target.value }))}
      />
    </div>
  );
};

const ExportSpreadSheetModal = (props: IExportSpreadSheetModalProps) => {
  const { classes, open = false, fileMeta, setFileMeta, onOk, onCancel } = props;

  const actions = (): IActionItem[] => {
    return [
      {
        iconName: 'Cancel',
        title: 'Cancel',
        listener: async () => {
          if (onCancel) {
            onCancel();
          }
        }
      }
      ,
      {
        title: 'Ok',
        iconName: 'CheckCircle',
        listener: async () => {
          if (onOk) {
            onOk(fileMeta);
          }
        }
      }];
  };

  return (
    <FormViewModal
      open={open}
      loading={false}
      title={'Export to Spreadsheet'}
      modalContent={<ExportModalContent className={classes.modalContent} fileMeta={fileMeta} setFileMeta={setFileMeta} />}
      actions={actions()}
    />
  );
};

export default withStyles(exportModalStyles, { index: 1 })(ExportSpreadSheetModal);

export const useExcelExport = (gridName: string): IUseExcelExportResponse => {
  const [exportEvent, setExportEvent] = React.useState(null);
  const [fileMeta, setFileMeta] = React.useState<IFileMeta>(null);
  const [openExportModal, setOpenExportModal] = React.useState(false);

  const fileMetaRef = React.useRef<IFileMeta>();

  fileMetaRef.current = fileMeta;

  const handleExportCancel = () => {
    setExportEvent(null);
    setOpenExportModal(false);
  };

  const deleteBlankColumns = (workbook: any) => {
    const blankColumnIndexws = exportEvent.component.getVisibleColumns().filter((c) => !c.name && !c.caption && c.command !== 'expand')
      .map((c) => c.visibleIndex);
    if (workbook && workbook.worksheets) {
      blankColumnIndexws.forEach((index) => {
        const columnIndex = Math.abs(index - 1);
        workbook.worksheets[0].spliceColumns(columnIndex || 1, 1);
      });
    }
  };

  const exportToSpreadsheet = (meta: IFileMeta) => {
    setOpenExportModal(false);

    exportEvent.cancel = false;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: exportEvent.component,
      worksheet: worksheet,
      autoFilterEnabled: false,
    }).then(() => {

      deleteBlankColumns(workbook);

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${meta.fileName}`);
      });
    });
    exportEvent.cancel = true;
  };

  function onExporting(e: any): void {
    setExportEvent(e);
    e.cancel = true;
    setFileMeta({
      BatchSize: DEFAULT_EXPORT_BATCH_SIZE,
      fileName: `${gridName}.xlsx`
    });
    setOpenExportModal(true);
  }

  return { openExportModal, fileMeta, fileMetaRef, exportToSpreadsheet, setFileMeta, onExporting, handleExportCancel };
};
