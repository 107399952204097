import { takeLatest, Effect } from 'redux-saga/effects';

import { types as invoiceLineTypes, actions as invoiceLineActions } from 'ducks/supplierInvoiceEnquiry/supplierInvoiceLines';

import { connectSagaToApi } from '../utils';
import * as api from 'api/supplierInvoiceEnquiry/invoiceLines';

export default function* rootCustomerSaga(): IterableIterator<Effect> {
  yield takeLatest(invoiceLineTypes.getInvoiceLineSummary, connectSagaToApi(invoiceLineActions, 'getInvoiceLineSummary', api.getInvoiceLineSummary));
  yield takeLatest(invoiceLineTypes.getInvoiceLineDetails, connectSagaToApi(invoiceLineActions, 'getInvoiceLineDetails', api.getInvoiceLineDetails));
}
