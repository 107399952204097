import * as React from 'react';
import FormView from 'components/FormView';
import {  withRouter } from 'react-router';
import { IInvoiceLinesFields } from '../interfaces';

class InvoiceLines extends React.PureComponent<IInvoiceLinesFields> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/rfc-enquiry/rfc-lines':
          onInitialLoad('RFCLines');
          break;
        default:
          onInitialLoad('RFCDetails');
      }
    }
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading, summaryTableRenderer } = this.props;

    return (selectedForm &&
      <React.Fragment>
        <FormView
          isLoading={isLoading}
          formName={selectedTab}
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={summaryTableRenderer}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(InvoiceLines);
