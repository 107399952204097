import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';

import ActionBar from '../common/ActionBar';
import OptionsMenu from '../common/OptionsMenu/';
import SupplierSearchLookUpDrawer from '../common/SearchLookUpDrawer/Containers/Supplier';
import SupplierDetails from './SupplierDetails';
import Activity from './Activity';
import Financial from './Financial';
import Purchasing from './Purchasing';
import Settings from './Settings';
import Attachments from './Attachments';

import { isNull, shallowCompare } from 'utils/utils';

import BreadcrumbBar from './../common/BreadcrumbBar';
import * as styles from './SupplierEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from 'utils/operations';
import { ISupplierEnquiryViewProperties, ISupplierEnquiryState } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = createStyles({
  supplierEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
});

class SupplierEnquiryView extends React.Component<ISupplierEnquiryViewProperties, ISupplierEnquiryState> {

  handleModuleChange = (selectedTab: string) => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'supplier-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    const notSupplier = selectedTab === 'DeliveryDetails' || selectedTab === 'Contacts' || selectedTab === 'UserFields' || selectedTab === 'Marketing';
    this.props.toggleBrowseLookUp(notSupplier);
    this.props.toggleSupplierLookUp(!notSupplier);
    this.props.changeSelectedForm(context, selectedTab);
    if (!(selectedTab === 'CreditDiaryDetails' || selectedTab === 'PurchaseDiaryDetails')) {
      this.props.changeOperationMode(Operation.BROWSE);
    }
    this.props.history.push(this.getRoute()[selectedTab]);
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any; fetchChildRecords?(params: any): void } } => {
    const params = (new URLSearchParams(this.props.location.search));
    const context = 'supplier-enquiry';
    const Supplier = params.get('Supplier') || '';
    const ContactNumber = params.get('ContactNumber') || '';
    const SiteCode = params.get('SiteCode') || '';
    const TransactionNumber = params.get('TransactionNumber') || '';
    const DiaryId = params.get('DiaryId');

    return ({
      SupplierDetails: { pathname: `/${context}/supplier-details`, search: `?Supplier=${Supplier}`, component: SupplierDetails, fetchChildRecords: this.fetchSupplierDetail },
      Contacts: { pathname: `/${context}/supplier-details/contacts`, search: `?Supplier=${Supplier}&ContactNumber=${ContactNumber}`, component: SupplierDetails, fetchChildRecords: this.fetchContacts },
      DeliveryDetails: { pathname: `/${context}/supplier-details/delivery-details`, search: `?Supplier=${Supplier}&SiteCode=${SiteCode}`, component: SupplierDetails },
      UserFields: { pathname: `/${context}/supplier-details/user-fields`, search: `?Supplier=${Supplier}`, component: SupplierDetails, fetchChildRecords: this.fetchSupplierUserDetail },
      Notepad: { pathname: `/${context}/supplier-details/notepad`, search: `?Supplier=${Supplier}`, component: SupplierDetails },
      Transactions: { pathname: `/${context}/financial/transactions`, search: `?Supplier=${Supplier}`, component: Financial, fetchChildRecords: this.setTransactionsFilters },
      TransactionDetails: { pathname: `/${context}/financial/transactions/transaction-details`, search: `?Supplier=${Supplier}&TransactionNumber=${TransactionNumber}`, component: Financial, fetchChildRecords: this.fetchTransactionDetails },
      DetailsTransactions: { pathname: `/${context}/financial/transactions/transaction-details/transactions`, search: `?Supplier=${Supplier}&TransactionNumber=${TransactionNumber}`, component: Financial, fetchChildRecords: this.getDetailsTransactions },
      SupplierActivity: { pathname: `/${context}/activity`, search: `?Supplier=${Supplier}`, component: Activity },
      SupplierActivityInvoices: { pathname: `/${context}/activity/invoices`, search: `?Supplier=${Supplier}`, component: Activity, fetchChildRecords: this.setSupplierActivityInvoicesFilters },
      SupplierActivityMovements: { pathname: `/${context}/activity/movements`, search: `?Supplier=${Supplier}`, component: Activity, fetchChildRecords: this.setSupplierActivityMovementsFilters },
      SupplierActivityGRNs: { pathname: `/${context}/activity/grns`, search: `?Supplier=${Supplier}`, component: Activity, fetchChildRecords: this.setSupplierActivityGRNsFilters },
      SupplierActivityFreight: { pathname: `/${context}/activity/freight`, search: `?Supplier=${Supplier}`, component: Activity, fetchChildRecords: this.setSupplierActivityFreightFilters },
      SupplierActivityRFCs: { pathname: `/${context}/activity/rfcs`, search: `?Supplier=${Supplier}`, component: Activity, fetchChildRecords: this.setSupplierActivityRFCsValues },
      SupplierFinancialChildAccounts: { pathname: `/${context}/financial/child-accounts`, search: `?Supplier=${Supplier}`, component: Financial },
      SupplierFinancialBalances: { pathname: `/${context}/financial/balances`, search: `?Supplier=${Supplier}`, component: Financial, fetchChildRecords: this.getBalanceDetails },
      Financial: { pathname: `/${context}/financial`, search: `?Supplier=${Supplier}`, component: Financial },
      CreditDiary: { pathname: `/${context}/financial/credit-diary`, search: `?Supplier=${Supplier}`, component: Financial },
      CreditDiaryDetails: { pathname: `/${context}/financial/credit-diary/diary-details`, search: `?Supplier=${Supplier}&DiaryId=${DiaryId}`, component: Financial },
      Purchasing: { pathname: `/${context}/purchasing/`, search: `?Supplier=${Supplier}`, component: Purchasing },
      SupplierPurchaseOrder: { pathname: `/${context}/purchasing/purchase-orders`, search: `?Supplier=${Supplier}`, component: Purchasing, fetchChildRecords: this.setSupplierPurchaseOrderFilters },
      PurchaseDiary: { pathname: `/${context}/purchasing/purchase-diary`, search: `?Supplier=${Supplier}`, component: Purchasing },
      PurchaseDiaryDetails: { pathname: `/${context}/purchasing/purchase-diary/diary-details`, search: `?Supplier=${Supplier}&DiaryId=${DiaryId}`, component: Purchasing },
      Settings: { pathname: `/${context}/settings`, search: `?Supplier=${Supplier}`, component: Settings },
      SupplierSettingsAttributes: { pathname: `/${context}/settings/attributes`, search: `?Supplier=${Supplier}`, component: Settings },
      SupplierFinancialSettings: { pathname: `/${context}/settings/financial`, search: `?Supplier=${Supplier}`, component: Settings, fetchChildRecords: this.getSupplierFinancialSettings },
      SupplierGeneralSettings: { pathname: `/${context}/settings/general`, search: `?Supplier=${Supplier}`, component: Settings, fetchChildRecords: this.getSupplierGeneralSettings },
      SupplierSettingsByWarehouse: { pathname: `/${context}/settings/by-warehouse`, search: `?Supplier=${Supplier}`, component: Settings },
      LocalShipments: { pathname: `/${context}/purchasing/local-shipments`, search: `?Supplier=${Supplier}`, component: Purchasing },
      SupplierPurchasingProducts: { pathname: `/${context}/purchasing/products`, search: `?Supplier=${Supplier}`, component: Purchasing },
      Documents: { pathname: `/${context}/attachments/documents`, search: `?Supplier=${Supplier}`, component: Attachments },
      Links: { pathname: `/${context}/attachments/links`, search: `?Supplier=${Supplier}`, component: Attachments },
      Images: { pathname: `/${context}/attachments/images`, search: `?Supplier=${Supplier}`, component: Attachments, fetchChildRecords: this.getAttachmentsImages },
      null: { pathname: `/${context}`, search: `?Supplier=${Supplier}`, component: SupplierDetails },
    });
  }

  handleToggleMenuOptionOpen = () => {
    const { toggleMenuOption, isMenuOptionOpen } = this.props;
    toggleMenuOption(!isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<ISupplierEnquiryViewProperties>, nextState: Readonly<ISupplierEnquiryState>): boolean {
    return shallowCompare(this, nextProps, nextState);
  }

  componentDidUpdate(prevProps: Readonly<ISupplierEnquiryViewProperties>): void {
    const { operationMode, selectedTab, changeSelectedForm, selectedForm, selectedSupplier } = this.props;
    if (prevProps.selectedTab !== selectedTab || (!selectedSupplier && !this.props.searchingForCustomers && this.props.searchingForCustomers !== prevProps.searchingForCustomers)) {
      this.handleModuleChange(selectedTab);
    }
    if (operationMode === Operation.BACK) {
      this.backAction(prevProps);
    }
    if (!selectedForm) {
      changeSelectedForm('supplier-enquiry', selectedTab);
    }
  }

  backAction = (prevProps: Readonly<ISupplierEnquiryViewProperties>) => {
    const { dirty, selectedTab, changeConfirmationDialog, changeOperationMode, history, resetForm } = this.props;
    if (dirty) {
      changeConfirmationDialog({
        open: true,
        title: 'Discard changes',
        message: 'Are you sure you want to continue?',
        okLabel: 'Discard',
        onCancel: () => {
          changeOperationMode(prevProps.operationMode);
        },
        onOk: () => {
          if (selectedTab === 'SupplierDetails') {
            history.push('/dashboard');
          }
          this.handleModuleChange('SupplierDetails');
          changeOperationMode(Operation.BROWSE);
          resetForm();
        }
      });
    } else {
      if (selectedTab) {
        const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
        if (!targetModule || !targetModule.parent || targetModule.parent === '') {
          if (selectedTab === 'SupplierDetails') {
            history.push('/dashboard');
          } else {
            this.handleModuleChange('SupplierDetails');
          }
        } else {
          this.handleModuleChange(targetModule.parent);
        }
      }
      changeOperationMode(Operation.BROWSE);
    }
  }

  setSupplierActivityInvoicesFilters = (): void => {
    const { staff, setInvoicesEntityView } = this.props;
    if (staff) {
      setInvoicesEntityView(staff.defaultView);
    }
  }

  setSupplierActivityGRNsFilters = (): void => {
    const { staff, setGRNEntityView } = this.props;
    if (staff) {
      setGRNEntityView(staff.defaultView);
    }
  }

  setSupplierActivityMovementsFilters = (): void => {
    const { staff, setMovementsEntityVew } = this.props;
    if (staff) {
      setMovementsEntityVew(staff.defaultView);
    }
  }

  setSupplierActivityFreightFilters = (): void => {
    const { staff, setFreightEntityView } = this.props;
    if (staff) {
      setFreightEntityView(staff.defaultView);
    }
  }

  setSupplierActivityRFCsValues = (): void => {
    const { staff, setRFCEntityView, getRFCSummary } = this.props;
    if (staff) {
      setRFCEntityView(staff.defaultView);
    }
    const params = (new URLSearchParams(this.props.location.search));
    const Supplier = params.get('Supplier') || '';
    if (Supplier) {
      getRFCSummary({ Supplier: parseInt(Supplier) });
    }
  }

  setTransactionsFilters = (): void => {
    const { staff, setTransactionsEntityView } = this.props;
    if (staff) {
      setTransactionsEntityView(staff.defaultView);
    }
  }

  setQuotesFilters = (): void => {
    const { staff, setQuotesEntityView } = this.props;
    if (staff) {
      setQuotesEntityView(staff.defaultView);
    }
  }

  setSalesOrdersFilters = (): void => {
    const { staff, setSalesOrdersEntityView } = this.props;
    if (staff) {
      setSalesOrdersEntityView(staff.defaultView);
    }
  }

  setBackordersFilters = (): void => {
    const { staff, setBackordersEntityView } = this.props;
    if (staff) {
      setBackordersEntityView(staff.defaultView);
    }
  }

  setSupplierPurchaseOrderFilters = (): void => {
    const { staff, setPurchaseOrdersEntityView } = this.props;
    if (staff) {
      setPurchaseOrdersEntityView(staff.defaultView);
    }
    this.getPurchaseOrdersSummary();
  }

  fetchChildRecords = (selectedTab: string) => {
    const routeData = this.getRoute()[selectedTab];
    if (routeData && routeData.fetchChildRecords) {
      routeData.fetchChildRecords(null);
    }
  }

  getSupplierFinancialSettings = (): void => {
    const { getSupplierFinancialSettings, selectedSupplier, location } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = selectedSupplier ? selectedSupplier.Supplier : parseInt(params.get('Supplier'));
    if (Supplier) {
      getSupplierFinancialSettings({ Supplier });
    }
  }
  getAttachmentsImages = (): void => {
    const { getAttachmentsImages, selectedSupplier, location } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = selectedSupplier ? selectedSupplier.Supplier : parseInt(params.get('Supplier'));
    if (Supplier) {
      getAttachmentsImages({ Supplier });
    }
  }
  getSupplierGeneralSettings = (): void => {
    const { getSupplierGeneralSettings, selectedSupplier, location } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = selectedSupplier ? selectedSupplier.Supplier : parseInt(params.get('Supplier'));
    if (Supplier) {
      getSupplierGeneralSettings({ Supplier });
    }
  }

  getBalanceDetails = (): void => {
    const { getSupplierBalanceDetails, selectedSupplier, location } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = selectedSupplier ? selectedSupplier.Supplier : parseInt(params.get('Supplier'));
    if (Supplier) {
      getSupplierBalanceDetails({ Supplier });
    }

  }

  getPurchaseOrdersSummary = (): void => {
    const { getSupplierPurchaseOrdersSummary, selectedSupplier, location, selectedPurchaseOrderFilters } = this.props;
    const params = new URLSearchParams(location.search);
    const Supplier = selectedSupplier ? selectedSupplier.Supplier : parseInt(params.get('Supplier'));
    const { EntityView } = selectedPurchaseOrderFilters;
    if (!isNull(Supplier) && !isNull(EntityView)) {
      getSupplierPurchaseOrdersSummary({ Supplier, filters: selectedPurchaseOrderFilters });
    }

  }

  getCustomerPricingDetails = () => {
    const { getCustomerPricingDetails, getCustomerPricingSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const Supplier = params.get('Supplier') || '';
    if (Supplier) {
      getCustomerPricingDetails({ Supplier: parseInt(Supplier) });
      getCustomerPricingSummary({ Supplier: parseInt(Supplier) });
    }
  }
  getDetailsTransactions = () => {
    const { getDetailsTransactions, fetchTransactionSummary } = this.props;
    const params = (new URLSearchParams(this.props.location.search));
    const TransactionNumber = params.get('TransactionNumber') || '';
    if (TransactionNumber) {
      getDetailsTransactions({ TransactionNumber: parseInt(TransactionNumber) });
      fetchTransactionSummary(parseInt(TransactionNumber));
    }
  }

  fetchTransactionDetails = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const TransactionNumber = params.get('TransactionNumber') || '';
    const { fetchTransactionDetails, fetchTransactionSummary } = this.props;
    if (TransactionNumber) {
      fetchTransactionDetails(parseInt(TransactionNumber));
      fetchTransactionSummary(parseInt(TransactionNumber));
    }
  }

  fetchContacts = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const Supplier = params.get('Supplier') || '';
    const ContactNumber = params.get('ContactNumber') || '';
    const { getContactDetails } = this.props;
    if (Supplier && ContactNumber) {
      getContactDetails({ Supplier: parseInt(Supplier), ContactNumber: parseInt(ContactNumber) });
    }
  }
  fetchSupplierDetail = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSupplierDetails, selectedSupplier, getPrimaryContacts } = this.props;
    const Supplier = (selectedSupplier && selectedSupplier.Supplier && selectedSupplier.Supplier.toString()) || params.get('Supplier') || '';
    if (Supplier && getSupplierDetails) {
      getSupplierDetails({ Supplier: parseInt(Supplier) });
      getPrimaryContacts({ Supplier: parseInt(Supplier) });
    }
  }
  fetchSupplierUserDetail = () => {
    const params = (new URLSearchParams(this.props.location.search));
    const { getSupplierUserFields, selectedSupplier } = this.props;
    const Supplier = (selectedSupplier && selectedSupplier.Supplier && selectedSupplier.Supplier.toString()) || params.get('Supplier') || '';
    if (Supplier && getSupplierUserFields) {
      getSupplierUserFields({ Supplier: parseInt(Supplier) });
    }
  }
  getOptionMenuData = (selectedTab: string) => {
    switch (selectedTab) {
      case 'TransactionDetails':
      case 'DetailsTransactions':
        return options.Transactions;
      default:
        return options.SupplierEnquiry;
    }
  }

  onSupplierChange = (): void => {
    this.fetchChildRecords(this.props.selectedTab);
  }

  handleOptionClick = (selectedTab: string): void => {
    const { changeSelectedTab } = this.props;
    changeSelectedTab(selectedTab);
  }

  render(): React.ReactNode {
    const { selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.supplierEnquiryOuter}>
        <div className={styles.supplierEnquiryContainer}>

          <SupplierSearchLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.onSupplierChange
            ]}
          />
          <div
            style={inlineStyles.supplierEnquirySection}
            className={styles.supplierEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Supplier Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                  formName={`${selectedTab}`}
                />}
              />
            ))}

          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={this.getOptionMenuData(selectedTab)}
          onOptionClick={this.handleOptionClick}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'supplier-enquiry'} />
      </div>
    );
  }
}

export default withRouter(SupplierEnquiryView);
