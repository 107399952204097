import React from 'react';
import { getWorksaleSelection } from 'api/Worksale/worksale';
import DataGridDevEx from 'components/common/DataGridDevEx';
import DataGrid from 'devextreme-react/data-grid';
import { IWarehouseSelectionDialogContentProps } from './WarehouseSelectionDialog.properties';
// import { calculateWorksaleLineDetails } from 'api/Worksale/worksale';

export const TABLE_COLUMN_DEFS = [
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Warehouse',
        field: 'WarehouseLabel',
    },
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Available',
        field: 'AvailableQuantityDisplay',
        type: 'NUMERICCOLUMN'
    },
    {
        suppressMenu: true,
        suppressSorting: true,
        headerName: 'Allocated',
        field: 'AllocatedQuantityDisplay',
        type: 'NUMERICCOLUMN'
    },
];

const WarehouseSelectionDialogContent = (props: IWarehouseSelectionDialogContentProps) => {
    const { ProductId, onWarehouseSelectionChanged, onRowDblClick } = props;
    const dataGridRef = React.useRef<DataGrid>();

    return (
        <DataGridDevEx
            loading={false}
            columnDefs={TABLE_COLUMN_DEFS}
            dataGridRef={dataGridRef}
            style={{ height: 470, width: 700, padding: '0 10px' }}
            reqParams={{ ProductId }}
            apiMethod={getWorksaleSelection}
            dataGridProps={{
                selection: true,
                onRowDblClick: onRowDblClick,
                onSelectionChanged: onWarehouseSelectionChanged
            }}
        />
    );
};

export default WarehouseSelectionDialogContent;
