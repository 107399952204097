import React from 'react';
import { IAddServiceItemModal, IParamsData, IServiceItemData } from '../NewJobModal.properties';
import { LookUpActionField as ExtendedLookUpActionField } from 'components/common/ExtendedLookUpActionField/LookUpActionField';
import MaterialButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isNull, pathOr } from 'utils/utils';

export function AddServiceItemModal(props: IAddServiceItemModal): JSX.Element {
    const {
        open,
        data,
        editMode = false,
        CustomerId,
        handleClose
    } = props;

    const [addItemForm, setAddItemForm] = React.useState<IServiceItemData>(null);
    const [touched, setTouched] = React.useState<boolean>(false);
    const [params, setParams] = React.useState<IParamsData>({ NoServiceItemType: true });

    React.useEffect(
        () => {
            setAddItemForm(data);
        },
        [data]
    );

    function handleOk(): void {
        if (isNull(addItemForm) || isNull(addItemForm.TemplateCode)) {
            setTouched(true);

            return;
        }

        if (handleClose) {
            handleClose(addItemForm);
            setTouched(false);
        }
    }

    const onSelectedServiceItemChangeHandler = (v: any): void => {
        const servItemType = pathOr(false, ['Details', 'ServiceItemType'], v);
        if (servItemType) {
            setParams({ ServiceItemTypeId: servItemType });
        } else {
            setParams({ NoServiceItemType: true });
        }
        setAddItemForm((prev) => ({
            ...prev,
            ServiceNo:  v ? v.Code : null,
            ServiceNoDisplay: v && v.Display || null,
            ServiceNoDisplayLabel: v && v.Label,
            TemplateCode: '',
            TemplateCodeLabel: ''
        }));
    };

    const onSelectedTemplateChangeHandler = (v: any): void => {
        setAddItemForm((prev) => ({
            ...prev,
            TemplateCode: v ? v.Code : null,
            TemplateCodeLabel: v ? v.Label : null
        }));
    };

    return (<div>
        <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            disableEnforceFocus
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{`${editMode ? 'Edit' : 'Add'} Item`}</DialogTitle>
            <DialogContent>
                <ExtendedLookUpActionField
                    label={'Service Item'}
                    lookupName={'CustomerServiceItem'}
                    size='medium'
                    params={{ CustomerId }}
                    value={addItemForm && `${addItemForm.ServiceNo}`}
                    onSelectedItemChange={onSelectedServiceItemChangeHandler}
                    suppressDescription />
                <ExtendedLookUpActionField
                    touched={touched}
                    label={'Job Template'}
                    lookupName={'TemplateCode'}
                    size='medium'
                    params={params}
                    value={addItemForm && addItemForm.TemplateCode}
                    onSelectedItemChange={onSelectedTemplateChangeHandler}
                    suppressDescription
                    required />
            </DialogContent>
            <DialogActions>
                <MaterialButton onClick={() => { if (handleClose) handleClose(); }} color='primary'>
                    Cancel
          </MaterialButton>
                <MaterialButton onClick={handleOk} color='primary'>
                    {editMode ? 'Update' : 'Add'}
                </MaterialButton>
            </DialogActions>
        </Dialog>
    </div>);
}
