import * as React from 'react';
import FormView from '../../FormView';
import UserNotifications from '../../common/UserNotifications';
import SummaryTable from 'components/common/SummaryTable';
import SummaryTableSchema from '../SummaryTableSchema.json';
import { ISalesOrderLinesEnquiryProperties } from '../interfaces';

class SalesOrderLinesEnquiry extends React.PureComponent<ISalesOrderLinesEnquiryProperties> {

  componentDidMount(): void {
    const { path, onInitialLoad } = this.props;
    if (path) {
      switch (path) {
        case '/sales-order-enquiry/order-lines':
          onInitialLoad('OrderLines');
          break;
        case '/sales-order-enquiry/order-lines/line-details':
          onInitialLoad('LineDetails');
          break;
        case '/sales-order-enquiry/order-lines/line-details/committed-purchase-order':
          onInitialLoad('LDCommittedPurchaseOrder');
          break;
        case '/sales-order-enquiry/order-lines/line-details/committed-production-order':
          onInitialLoad('LDCommittedProductionOrder');
          break;
        case '/sales-order-enquiry/order-lines/line-details/committed-in-put-away':
          onInitialLoad('LDCommittedInPutAway');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom':
          onInitialLoad('SOEBOM');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details':
          onInitialLoad('ComponentDetails');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/committed-purchase-order':
          onInitialLoad('CDCommittedPurchaseOrder');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/committed-production-order':
          onInitialLoad('CDCommittedProductionOrder');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/committed-in-put-away':
          onInitialLoad('CDCommittedInPutAway');
          break;
        case '/sales-order-enquiry/order-lines/line-details/reservation-details':
          onInitialLoad('ReservationDetails');
          break;
        case '/sales-order-enquiry/order-lines/line-details/reservation-serials':
          onInitialLoad('LDReservationSerials');
          break;
        case '/sales-order-enquiry/order-lines/line-details/reservation-lots':
          onInitialLoad('LDReservationLots');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/reservation-details':
          onInitialLoad('CDReservationDetails');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/reservation-serials':
          onInitialLoad('CDReservationSerials');
          break;
        case '/sales-order-enquiry/order-lines/line-details/bom/component-details/reservation-lots':
          onInitialLoad('CDReservationLots');
          break;
        case '/sales-order-enquiry/order-lines/line-details/invoices':
          onInitialLoad('LineInvoices');
          break;
        default:
          onInitialLoad('OrderLines');
      }
    }
  }

  getInitialValues = (): any => {
    const {
      selectedTab, orderLineDetails, orderLineSummary, reservationDetails,
      cdReservationDetails, componentDetails, componentSummary,
      loadingComponentDetailsSummary, loadingOrderLineSummary } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
        return (orderLineDetails && orderLineSummary && !loadingOrderLineSummary && { ...orderLineDetails.values, ...orderLineSummary });
      case 'LDReservationSerials':
      case 'LDReservationLots':
      case 'LDCommittedPurchaseOrder':
      case 'LDCommittedProductionOrder':
      case 'LDCommittedInPutAway':
      case 'LineInvoices':
      case 'SOEBOM':
        return loadingOrderLineSummary || null;
      case 'ReservationDetails':
        return (reservationDetails && !loadingOrderLineSummary && reservationDetails.values);
      case 'CDReservationDetails':
        return (cdReservationDetails && cdReservationDetails.values) || {};
      case 'ComponentDetails':
        return componentDetails && componentSummary && !loadingComponentDetailsSummary && { ...componentDetails.values, ...componentSummary };
      case 'CDReservationSerials':
      case 'CDReservationLots':
      case 'CDCommittedPurchaseOrder':
      case 'CDCommittedProductionOrder':
      case 'CDCommittedInPutAway':
        return loadingComponentDetailsSummary;
      default:
        return {};
    }
  }

  getValueSchema = (): any => {
    const { selectedTab, reservationDetails, componentDetails, cdReservationDetails, orderLineDetails } = this.props;
    switch (selectedTab) {
      case 'ReservationDetails':
        return reservationDetails && reservationDetails.schema;
      case 'CDReservationDetails':
        return cdReservationDetails && cdReservationDetails.schema;
      case 'ComponentDetails':
        return componentDetails && componentDetails.schema;
      case 'LineDetails':
        return (orderLineDetails && orderLineDetails.schema) || {};
      default:
        return null;
    }
  }

  getSummaryTableRenderer = (): React.ReactNode => {
    const { selectedTab } = this.props;
    switch (selectedTab) {
      case 'LineDetails':
      case 'ReservationDetails':
      case 'LDReservationSerials':
      case 'LDReservationLots':
      case 'LDCommittedPurchaseOrder':
      case 'LDCommittedProductionOrder':
      case 'LDCommittedInPutAway':
      case 'LineInvoices':
      case 'SOEBOM':
        return this.lineSummary;
      case 'ComponentDetails':
      case 'CDReservationDetails':
      case 'CDReservationSerials':
      case 'CDReservationLots':
      case 'CDCommittedPurchaseOrder':
      case 'CDCommittedProductionOrder':
      case 'CDCommittedInPutAway':
        return this.componentDetailsTable;
      default: return null;
    }
  };

  lineSummary = (): React.ReactNode => {
    const { orderLineSummary, loadingOrderLineSummary } = this.props;

    return <SummaryTable
      selectedLine={orderLineSummary}
      loadingOrderLineSummary={loadingOrderLineSummary}
      data={
        SummaryTableSchema.LineDetails
      } />;
  };

  componentDetailsTable = (): React.ReactNode => {
    const { componentSummary, loadingComponentDetailsSummary } = this.props;

    return <SummaryTable
      selectedProduct={componentSummary && componentSummary.values}
      loadingComponentDetailsSummary={loadingComponentDetailsSummary}
      data={
        SummaryTableSchema.ComponentDetails
      } />;
  }

  render(): React.ReactNode {
    const { isBrowseLookUpOpen, selectedTab, selectedForm, operationMode, isLoading } = this.props;
    const initialValues = this.getInitialValues();
    const valuesSchema = this.getValueSchema();

    return (selectedForm &&
      <React.Fragment>
        <UserNotifications
          notifications={this.props.notifications}
          entity='Order Line'
        />
        <FormView
          isLoading={isLoading}
          formName='SalesOrderLinesEnquiry'
          browseLookUpOpen={isBrowseLookUpOpen}
          browseLookUpComponent={selectedTab}
          schema={selectedForm}
          includeTabsHeight={false}
          initialValues={initialValues}
          valuesSchema={valuesSchema}
          operationMode={operationMode}
          enableReinitialize={true}
          summaryTableRenderer={this.getSummaryTableRenderer()}
        />
      </React.Fragment>
    );
  }
}

export default SalesOrderLinesEnquiry;
