import React from 'react';
import { connect } from 'react-redux';
import ProductCatalogue from './ProductCatalogue';
import { IProductCatalogueProps, IProductCatalogueHandle } from './ProductCatalogue.properties';
import { bindActionCreators, Dispatch } from 'redux';
import { actions as uiActions } from 'ducks/ui';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
    {
        changeConfirmationDialog: uiActions.changeConfirmationDialog,
    },
    dispatch);

const ConnectedComponent = connect(null, mapDispatchToProps)((props: Readonly<Omit<
    IProductCatalogueProps,
    'classes'>>) => {
    return (<ProductCatalogue
        {...props} />);
});

export default React.forwardRef(
    (props: Readonly<Omit<IProductCatalogueProps, 'classes'>>, ref: React.Ref<IProductCatalogueHandle>) => {
    return <ConnectedComponent {...props} forwardedRef={ref} />;
});
