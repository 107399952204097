import CashReceiptingAPI from './../data/forms/cash-receipting/cash-receipting.json';
import AllocateAPI from './../data/forms/cash-receipting/allocate.json';
import InvoicesAPI from './../data/forms/cash-receipting/invoices.json';
import OrdersAPI from './../data/forms/cash-receipting/orders.json';
import PaymentDetails from './../data/forms/common_components/payment-details.json';

const getFormSchema = (formId) => {
    switch (formId) {
        case 'CashReceipt':
            return CashReceiptingAPI;
        case 'Allocate':
            return AllocateAPI;
        case 'CashReceiptingInvoices':
            return InvoicesAPI;
        case 'CashReceiptingOrders':
            return OrdersAPI;
        case 'PaymentDetails':
            return PaymentDetails;

        default:
            return CashReceiptingAPI;
    }
};

export default getFormSchema;
