import React from 'react';
import FormViewModal from 'components/common/Modals/FormViewModal';
import { IActionItem } from 'components/common/Modals/FormViewModal.properties';
import Icon from '@markinson/uicomponents-v2/Icon';
import { withStyles } from '@material-ui/core/styles';
import styles from './Charts.styles';
import { IChartContainerProperties } from './Charts.properties';
import { ProcessValidationFormsContext } from 'utils/processValidationForms';

const ChartContainer = (props: IChartContainerProperties) => {
    const {
        classes, isConfigurationSet, children, modalOkClick, validateConfiguration, resetConfigurationForm,
        modalCancelClick, title, modalFormProps, filterOpen, setFilterOpen, widgetInfo, setWidgetConfiguration
    } = props;

    const filterActions = (): IActionItem[] => {
        return [
            {
                iconName: 'Cancel',
                title: 'Cancel',
                listener: async () => {
                    handleCancelClick();
                }
            },
            {
                title: 'Ok',
                iconName: 'CheckCircle',
                listener: async () => {
                    handleOkClick();
                }
            },
        ];
    };

    const processValidationForms = React.useContext(ProcessValidationFormsContext);

    async function setWidgetConfig(query: any): Promise<any> {
        const response = await setWidgetConfiguration(query);

        return processValidationForms(response, query, async (validatedResponse) => setWidgetConfiguration(validatedResponse));
    }

    const setConfiguration = () => {
        if (validateConfiguration()) {
            setWidgetConfig({ WidgetId: widgetInfo.DashboardWidgetId, Configuration: modalFormProps.formValues })
                .catch((err) => {
                    console.warn(err);
                });
        }
    };

    const handleOkClick = () => {
        if (modalOkClick) modalOkClick();
        setConfiguration();
    };
    const handleCancelClick = () => {
        if (modalCancelClick) modalCancelClick();
    };

    const handleSettingsOnClick = () => {
        setFilterOpen(true);
        resetConfigurationForm();
    };

    const renderContent = () => {
        return <div className={classes.configContainer}>
            <div className={classes.iconContainer}>
                <Icon
                    iconName='Settings'
                    className={classes.iconStyles}
                    onClick={handleSettingsOnClick}
                />
            </div>
            {isConfigurationSet && children}
            {!isConfigurationSet &&
                <div className={classes.upperContainer}>
                    <div className={classes.setupDivContainer}>
                        <span>{title}</span><br />
                        <span>Please select the setup button to configure the Widget.</span>
                    </div>
                </div>}
        </div>;
    };

    return (
        <div className={classes.mainContainer}>
            {renderContent()}
            <FormViewModal
                open={filterOpen}
                loading={false}
                title={'Configure'}
                actions={filterActions()}
                formProps={{
                    style: {
                        height: '175px',
                        minWidth: '390px'
                    },
                    ...modalFormProps,
                }}
            />
        </div>
    );
};

export default withStyles(styles, { index: 1 })(ChartContainer);
