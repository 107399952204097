import * as React from 'react';
import ActionBar from '../common/ActionBar/index';
import OptionsMenu from '../common/OptionsMenu/index';
import ProductsLookUpDrawer from '../common/SearchLookUpDrawer/Containers/ProductsMovements';
import { shallowCompare } from '../../utils/utils';

import { withRouter, Route } from 'react-router-dom';
import BreadcrumbBar from '../common/BreadcrumbBar/index';
import * as styles from './ProductMovementEnquiry.scss';
import * as options from './OptionItems.json';
import { Operation } from '../../utils/operations';
import ProductDetails from './MovementDetails';
import SummaryPanel from './SummaryPanel';
import { IInventoryEnquiryViewProperties } from './interfaces';
import { MODULE_TREE } from './constants';

const inlineStyles = {
  inventoryEnquirySection: {
    width: 'calc(100% - 20px)',
    marginLeft: '10px',
  }
};

class InventoryEnquiryView extends React.Component<IInventoryEnquiryViewProperties> {

  handleModuleChange = (selectedTab: string): void => {
    this.props.changeSelectedTab(selectedTab);
    const context = 'product-movement-enquiry';
    this.props.getFormSchema({
      context,
      formId: selectedTab
    });
    this.props.changeSelectedForm(context, selectedTab);
    this.props.changeOperationMode(Operation.BROWSE);
    const { pathname, search } = this.getRoute()[selectedTab];
    this.props.history.push({ pathname, search });
    this.fetchChildRecords(selectedTab);
  }

  getRoute = (): { [name: string]: { pathname: string; search: string; component: any } } => {
    const context = 'product-movement-enquiry';
    const params = (new URLSearchParams(this.props.location.search));
    const ProductMovementId = params.get('ProductMovementId') || '';

    const routes = {
      PMEMovementDetails: { pathname: `/${context}/movement-details`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
      PMETransactions: { pathname: `/${context}/transactions`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
      PMEBinLocations: { pathname: `/${context}/bin-locations`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
      PMELotDetails: { pathname: `/${context}/lot-details`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
      PMESerialNumbers: { pathname: `/${context}/serial-numbers`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
      null: { pathname: `/${context}`, search: `?ProductMovementId=${ProductMovementId}`, component: ProductDetails },
    };

    return routes;
  }

  handleToggleMenuOptionOpen = (): void => {
    this.props.toggleMenuOption(!this.props.isMenuOptionOpen);
  }

  componentDidMount(): void {
    this.props.changeOperationMode(Operation.BROWSE);
  }

  shouldComponentUpdate(nextProps: Readonly<IInventoryEnquiryViewProperties>): boolean {
    return shallowCompare(this, nextProps);
  }

  componentDidUpdate(prevProps: IInventoryEnquiryViewProperties): void {
    const { operationMode, selectedTab } = this.props;

    if (operationMode === Operation.BACK) {
      this.backAction();
    }
    if (!this.props.selectedForm) {
      this.props.changeSelectedForm('product-movement-enquiry', this.props.selectedTab);
    }
    if (selectedTab !== prevProps.selectedTab) {
      this.handleModuleChange(selectedTab);
    }
  }

  backAction = () => {
    const { selectedTab } = this.props;
    if (selectedTab) {
      const targetModule = MODULE_TREE.find((item) => item.id === selectedTab);
      if (!targetModule || !targetModule.parent || targetModule.parent === '') {
        if (selectedTab === 'ProductDetails') {
          this.props.history.push('/dashboard');
        } else {
          this.handleModuleChange('ProductDetails');
        }
      } else {
        this.handleModuleChange(targetModule.parent);
      }
    }
    this.props.changeOperationMode(Operation.BROWSE);
  }

  getMovementDetails = (): void => {
    const { getMovementDetails, selectedMovement, location } = this.props;
    const params = new URLSearchParams(location.search);
    const ProductMovementId = (selectedMovement && selectedMovement.ProductMovementId) || parseInt(params.get('ProductMovementId'));
    if (ProductMovementId) getMovementDetails({ ProductMovementId });
  }

  fetchChildRecords = (selectedTab: string): void => {
    switch (selectedTab) {
      case 'PMEMovementDetails':
        this.getMovementDetails();
        break;
      default:
    }
  }

  onProductChange = (): void => {
    const { selectedTab, getMovementSummary, selectedMovement, location } = this.props;
    const params = new URLSearchParams(location.search);
    const ProductMovementId = (selectedMovement && selectedMovement.ProductMovementId) || parseInt(params.get('ProductMovementId'));
    if (ProductMovementId) getMovementSummary({ ProductMovementId });
    this.fetchChildRecords(selectedTab);
  }

  render(): React.ReactNode {
    const {
      selectedTab, isMenuOptionOpen } = this.props;
    const routes = Object.values(this.getRoute());

    return (
      <div className={styles.inventoryEnquiryOuter}>
        <div className={styles.inventoryEnquiryContainer}>
          <ProductsLookUpDrawer
            enableToggle={true}
            recordChangeCallBacks={[
              this.onProductChange
            ]}
          />
          <div
            style={inlineStyles.inventoryEnquirySection}
            className={styles.inventoryEnquirySection}
          >
            <BreadcrumbBar
              onClick={this.handleToggleMenuOptionOpen}
              mainModule={'Product Movement Enquiry'}
              activeModule={selectedTab}
              moduleTree={MODULE_TREE}
            />
            <SummaryPanel
            />
            {routes.map((route) => (
              <Route
                exact
                key={route.pathname}
                path={route.pathname}
                render={() => <route.component
                  onInitialLoad={this.handleModuleChange}
                  path={route.pathname}
                />}
              />
            ))}
          </div>
        </div>
        <OptionsMenu
          open={isMenuOptionOpen}
          options={options.ProductMovementEnquiry}
          onOptionClick={this.handleModuleChange}
          defaultValue={selectedTab}
        />
        <ActionBar scope={'product-movement-enquiry'} />
      </div>
    );
  }
}

export default withRouter(InventoryEnquiryView);
