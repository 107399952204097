import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors,
  // formatFloatingNumber,
} from '../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  clear: () => null,
  asyncs: {
  }
}, 'cdInPutAway');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  deleteObj: null,
  Actions: {
  },
  Columns: [
    { headerName: 'Document', field: 'Document', suppressSorting: true, minWidth: 150 },
    { headerName: 'Committed', field: 'CommittedQuantity', suppressSorting: true },
    { headerName: 'User ID', field: 'UserId', suppressSorting: true },
    { headerName: 'Status', field: 'Status', suppressSorting: true },
    { headerName: 'Account', field: 'Account', suppressSorting: true }
  ],
  Data: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.clear:
      return {
        ...state,
        data: {
          ...state.data,
          Data: []
        }
      }

    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state;
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.salesOrderEnquiry.cdInPutAway,
  {
    Data: data => data.Data,
    selected: data => data.selected,
    Actions: data => data.Actions,
  }
)

const syncSelector = {
  isLoading: state => state.salesOrderEnquiry.cdInPutAway.loading,
  gridOptions: state => state.salesOrderEnquiry.cdInPutAway.data.gridOptions,
  Columns: state => state.salesOrderEnquiry.cdInPutAway.data.Columns,
  Actions: state => state.salesOrderEnquiry.cdInPutAway.data.Actions,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)