import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as searchhistoricalDatesSelectors, actions as searchhistoricalDatesActions } from 'ducks/inventoryEnquiry/historicalDates';
import { search } from 'api/inventoryEnquiry/historicalDates';

const params = () => (new URLSearchParams(location.search));

export const mapStateToProps = state => ({
  gridName: 'HistoricalDates',
  columnDefs: searchhistoricalDatesSelectors.Columns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: searchhistoricalDatesSelectors.Actions(state),
  gridOptions: searchhistoricalDatesSelectors.gridOptions(state),
  reqParams: (searchhistoricalDatesSelectors.selected(state) || params().get('ProductId'))
    &&
  {
    ProductId: (searchhistoricalDatesSelectors.selected(state) && searchhistoricalDatesSelectors.selected(state).ProductId) || params().get('ProductId'),
  },
  apiMethod: search
});

export const actions = {
  deleteSelectedRecords: searchhistoricalDatesActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: searchhistoricalDatesActions.setSelected
}
