import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import InfoButton from '@markinson/uicomponents-v2/InfoButton';
import BillToIdActionList from '../../../utils/data/db/actions/BillToIdActionList';
import Scrollbars from 'react-custom-scrollbars';
import { CircularProgressSize } from '../../../utils/constants';
import { ISummaryPanelProperties, ISummaryPanelState } from '../interfaces';

const inlineStyles = createStyles({
  mainPanel: {
    width: '17vw',
    minWidth: '300px',
    backgroundColor: '#666666',
    float: 'right',
    height: 'calc(100vh - 64px)',
  },
  subPanel: {
    margin: '10px',
    padding: '10px'
  },
  subPanelLastChild: {
    margin: '10px 10px 120px 10px',
    padding: '10px'
  },
  subPanelTitle: {
    color: '#000000',
    fontSize: '15px',
    marginBottom: '10px'
  },
  summaryTable: {
    width: '100%',
    margin: 'auto'
  },
  summaryRow: {
    height: 'inherit',
  },
  headingCell: {
    backgroundColor: '#eeeeee',
    border: '1px solid #fff',
    color: '#000000',
    fontSize: '10px',
    fontWeight: 'bold',
    width: '75px',
    padding: '10px !important',
  },
  dataCell: {
    fontSize: '15px',
    padding: '10px !important',
    backgroundColor: '#dddddd',
    color: '#000000',
    border: '1px solid #fff !important'
  },
  numericCell: {
    backgroundColor: '#dddddd',
    color: '#000000',
    fontSize: '15px',
    border: '1px solid #fff',
    textAlign: 'right',
    padding: '10px !important'
  },
  totalCell: {
    backgroundColor: '#cccccc',
    fontSize: '16px',
    color: '#000000',
    textAlign: 'right',
    padding: '10px !important'
  },
  icon: {
    'height': '18px',
    'width': '18px',
    'verticalAlign': 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  preLoaderWrapperCustomer: {
    height: '96px'
  },
  preLoaderWrapperTotal: {
    height: '106px'
  },
  preLoader: {
    position: 'relative',
    left: 'calc(9.5vw - 50px)',
  },
  splitCellContainer: {
    display: 'flex'
  },
  splitCellContent: {
    flex: '5',
  },
  splitCellAction: {
    flex: '1',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '-5px'
  }
});

class SummaryPanel extends React.PureComponent<ISummaryPanelProperties, ISummaryPanelState> {
  constructor(props: Readonly<ISummaryPanelProperties>) {
    super(props);
    this.state = {
      open: false,
      dialogOpen: false
    };
  }

  appendParam = (actionList: any, selectedQuote: any) => {
    if (selectedQuote && selectedQuote.CustomerId && selectedQuote.CustomerId) {
      return actionList.map((item) => {
        item.actionParam = { CustomerId: selectedQuote.CustomerId, CustomerAccountNumber: selectedQuote.CustomerIdDisplay };

        return item;
      });
    }

    return actionList;
  }

  renderQuoteDetails = (): React.ReactNode => {

    const { classes, selectedQuote, quoteSummaryPanel } = this.props;
    const actionList = BillToIdActionList;
    const elevation = 2;

    return (<Paper elevation={elevation} square={true} className={classes.subPanel}>
      <p className={classes.subPanelTitle}>Quote details</p>
      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>QUOTE</TableCell>
            <TableCell className={classes.dataCell}>
              <div>
                <span>{selectedQuote && selectedQuote.QuoteDescription}</span>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>CUSTOMER</TableCell>
            <TableCell className={classes.dataCell}>
              <div className={classes.splitCellContainer}>
                <div className={classes.splitCellContent}>
                  <span >{selectedQuote && selectedQuote.CustomerId && selectedQuote.CustomerIdDisplay}</span>
                  <br />
                  <span >{selectedQuote && selectedQuote.CustomerId && selectedQuote.CustomerIdLabel}</span>
                </div>
                <div className={classes.splitCellAction} >
                  {actionList &&
                    <InfoButton
                      actionList={this.appendParam(actionList, selectedQuote)}
                      disabled={!(selectedQuote && selectedQuote.CustomerId && selectedQuote.CustomerIdDisplay)}
                    />
                  }
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>SALES ENTITY</TableCell>
            <TableCell className={classes.dataCell}>
              <div>
                <span >{quoteSummaryPanel && quoteSummaryPanel.SalesEntity && quoteSummaryPanel.SalesEntity.Value}</span>
                <br />
                <span >{quoteSummaryPanel && quoteSummaryPanel.SalesEntity && quoteSummaryPanel.SalesEntity.Description}</span>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>WAREHOUSE</TableCell>
            <TableCell className={classes.dataCell}>
              <div>
                <span >{quoteSummaryPanel && quoteSummaryPanel.Warehouse && quoteSummaryPanel.Warehouse.Value}</span>
                <br />
                <span >{quoteSummaryPanel && quoteSummaryPanel.Warehouse && quoteSummaryPanel.Warehouse.Description}</span>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>QUOTE STATUS</TableCell>
            <TableCell className={classes.dataCell}>{quoteSummaryPanel ? quoteSummaryPanel.QuoteStatus && quoteSummaryPanel.QuoteStatus.Value : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
  }

  renderPreLoader = (title?: string): React.ReactNode => {
    const { classes } = this.props;
    const elevation = 2;

    return (<Paper
      elevation={elevation}
      className={classes.subPanel}
      square={true}>
      {title && <p className={classes.subPanelTitle}>{title}</p>}
      <CircularProgress
        size={CircularProgressSize}
        style={inlineStyles.preLoader}
        color={'secondary'}
        variant={'indeterminate'} />
    </Paper>);
  }

  renderDates = (): React.ReactNode => {
    const { classes, quoteSummaryPanel } = this.props;
    const elevation = 2;

    return (<Paper elevation={elevation} className={classes.subPanel} square={true}>
      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>ENTERED</TableCell>
            <TableCell className={classes.dataCell}>
              {quoteSummaryPanel ? quoteSummaryPanel.EnteredDate && quoteSummaryPanel.EnteredDate.Value : ''}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>EXPIRY</TableCell>
            <TableCell className={classes.dataCell}>
              {quoteSummaryPanel ? quoteSummaryPanel.ExpiryDate && quoteSummaryPanel.ExpiryDate.Value : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
  }

  renderFinancial = (): React.ReactNode => {
    const { classes, quoteSummaryPanel } = this.props;
    const elevation = 2;

    return (<Paper elevation={elevation} className={classes.subPanel} square={true}>
      <p className={classes.subPanelTitle}>Totals</p>
      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>CURRENCY</TableCell>
            <TableCell className={classes.numericCell}>
              {quoteSummaryPanel && quoteSummaryPanel.Currency && quoteSummaryPanel.Currency.Value}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>TAX TOTAL</TableCell>
            <TableCell className={classes.numericCell}>
              {quoteSummaryPanel ? quoteSummaryPanel.TaxTotal && quoteSummaryPanel.TaxTotal.Display : ''}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>QUOTE TOTAL</TableCell>
            <TableCell className={classes.totalCell}>
              {quoteSummaryPanel ? quoteSummaryPanel.QuoteTotal && quoteSummaryPanel.QuoteTotal.Display : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
  }

  render(): React.ReactNode {
    const { classes, selectedQuoteLoading } = this.props;

    return (
      <div>
        <Paper elevation={0} className={classes.mainPanel} square={true} >
          <Scrollbars>
            {(selectedQuoteLoading
              && this.renderPreLoader('Quote details'))
              || this.renderQuoteDetails()
            }

            {(selectedQuoteLoading
              && this.renderPreLoader())
              || this.renderDates()
            }
            {(selectedQuoteLoading
              && this.renderPreLoader('Totals'))
              || this.renderFinancial()
            }
          </Scrollbars>
        </Paper>
      </div >
    );
  }
}

export default withStyles(inlineStyles, {index: 1})(SummaryPanel);
