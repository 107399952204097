import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, Paper, CircularProgress, Switch, Table, TableBody, TableCell, TableRow, Dialog, DialogTitle, DialogActions, List, ListItemText, Button, CardMedia } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';
import WarningIcon from '@markinson/uicomponents-v2/SvgIcons/Warning';
import { PaperElevation, CircularProgressSize, SummaryBackPaperElevation } from '../../../utils/constants';
import { ISummaryPanelProps } from '../interfaces';
import { ISummaryPanelState } from 'components/CustomerQuoteEnquiry/interfaces';
import { fetchFile } from 'api/utils';
import ImageModal from 'components/common/ImageGrid/ImageModal';
import { pathOr } from 'utils/utils';
import { ISimpleDialogProperties } from './SummaryPanel.properties';
import { styles, inlineStyles } from './SummaryPanel.styles';
import { showSnackBar } from 'components/common/SnackBars/SnackBar.hooks';

const SimpleDialog = (props: ISimpleDialogProperties): JSX.Element => {
  const { classes, onClose, message, ...other } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} {...other}
      classes={{
        paper: classes.dialogStyles
      }}
    >
      <DialogTitle>Alerts</DialogTitle>
      <List style={{ overflow: 'auto' }}>
        {
          message && message.length > 0 && message.map((item, idx) =>
            <ListItem key={idx}>
              <ListItemText primary={item.AlertMsg} secondary={item.AlertMsgDesc}
                classes={{ primary: classes.alertMessage }} />
            </ListItem>
          )
        }
      </List>
      <DialogActions>
        <Button onClick={handleClose} variant='contained' autoFocus
          classes={{ raised: classes.button }}>
          OK
        </Button>
      </DialogActions>
    </Dialog >
  );
};

const SimpleDialogWrapped = withStyles(styles, { index: 1 })(SimpleDialog);
class SummaryPanel extends React.PureComponent<ISummaryPanelProps, ISummaryPanelState> {
  constructor(props: ISummaryPanelProps) {
    super(props);
    this.state = {
      open: false,
      openImageModal: false,
      dialogOpen: false,
      imageModalLoading: false,
      imageData: ''
    };
  }

  toggleMenu = (): void => {
    event.stopPropagation();
    this.setState(() => ({
      open: !this.state.open
    }));
  };

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  handleDialogOpen = (): void => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleDialogClose = (): void => {
    this.setState({ dialogOpen: false });
  };

  onStatusCellClick = () => {
    this.handleDialogOpen();
  }

  appendParam = (actionList: any, productDetailsSummary: any): any => {
    if (productDetailsSummary && productDetailsSummary.ProductId) {
      return actionList.map((item) => {
        item.actionParam = productDetailsSummary.ProductId;

        return item;
      });
    }

    return actionList;
  }

  renderPreLoader = (): React.ReactNode => {
    const { classes, } = this.props;

    return (<Paper
      elevation={PaperElevation}
      className={classes.subPanel}
      square={true}>
      <p className={classes.subPanelTitle}>Product details</p>
      <CircularProgress
        size={CircularProgressSize}
        className={classes.preLoader}
        color={'secondary'}
        variant={'indeterminate'} />
    </Paper>);
  }

  renderGeneric = (): React.ReactNode => {
    const { classes, productDetailsSummary, variant } = this.props;

    return (
      <Paper elevation={PaperElevation} square={true} className={classes.subPanel}>
        <p className={classes.subPanelTitle}>Product details</p>
        <Table className={classes.summaryTable}>
          <TableBody>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    <span>{productDetailsSummary && productDetailsSummary.ProductIdDisplay}</span>
                    <br />
                    <span>{productDetailsSummary && productDetailsSummary.ProductIdLabel}</span>
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT GROUP</TableCell>
              <TableCell className={classes.dataCell}>
                <div className={classes.splitCellContainer}>
                  <div className={classes.splitCellContent}>
                    <span >{productDetailsSummary && productDetailsSummary.ProductGroup}</span>
                    <br />
                    <span >{productDetailsSummary && productDetailsSummary.ProductGroupDescription}</span>
                  </div>
                  <div className={classes.splitCellAction} >
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>PRODUCT TYPE</TableCell>
              <TableCell className={classes.dataCell}>{productDetailsSummary && productDetailsSummary.ProductType}</TableCell>
            </TableRow>
            {variant === 'ProductDetails'
              && <TableRow className={classes.summaryRow}>
                <TableCell className={classes.headingCell}>STATUS</TableCell>
                <TableCell className={classes.dataCell}>{productDetailsSummary && productDetailsSummary.Status}
                  {productDetailsSummary && productDetailsSummary.ProductAlerts && productDetailsSummary.ProductAlerts.length > 0
                    && <WarningIcon
                      className={classes.iconWarning}
                      style={{ right: '25', position: 'absolute', color: '#F47920' }}
                      onClick={this.onStatusCellClick}
                    />
                  }
                </TableCell>
              </TableRow>
            }
            {variant === 'ProductDetails'
              && <TableRow className={classes.summaryRow}>
                <TableCell className={classes.headingCell}>PARETO CLASS</TableCell>
                <TableCell className={classes.dataCell}>{productDetailsSummary && productDetailsSummary.ParetoClass}</TableCell>
              </TableRow>
            }
            {variant === 'ProductDetails'
              &&
              <TableRow className={classes.summaryRow}>
                <TableCell className={classes.headingCell}>PRIMARY BIN</TableCell>
                <TableCell className={classes.dataCell}>{productDetailsSummary && productDetailsSummary.PrimaryBin}</TableCell>
              </TableRow>
            }

            <TableRow className={classes.summaryRow}>
              <TableCell className={classes.headingCell}>STOCKING UNIT</TableCell>
              <TableCell className={classes.dataCell}>{productDetailsSummary && productDetailsSummary.StockingUnit}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </Paper>
    );
  }

  renderPricingSummary = (): React.ReactNode => {
    const { classes, pricesSummary } = this.props;

    return (<Paper elevation={PaperElevation} className={classes.subPanel} square={true}>
      <p className={classes.subPanelTitle}>Price details</p>
      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>REFERENCE COST</TableCell>
            <TableCell className={classes.totalCell}>{pricesSummary && pricesSummary.ReferenceCostDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>BASE PRICE</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.BasePriceDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>RETAIL PRICE</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.RetailPriceDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>PRICE PER</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.PricePerDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>PRODUCT PRICE CODE</TableCell>
            <TableCell className={classes.dataCell}>
              <div className={classes.splitCellContainer}>
                <div className={classes.splitCellContent}>
                  <span >{pricesSummary && pricesSummary.ProductPriceCode}</span>

                </div>
                <div className={classes.splitCellAction} >
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>SURCHARGE CODE</TableCell>
            <TableCell className={classes.dataCell}>
              <div className={classes.splitCellContainer}>
                <div className={classes.splitCellContent}>
                  <span >{pricesSummary && pricesSummary.SurchargeCode}</span>
                  <br />
                  <span >{pricesSummary && pricesSummary.SurchargeName}</span>
                </div>
                <div className={classes.splitCellAction} >
                </div>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>COMPONENT SURCHARGE</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.ComponentSurchargeDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>FUTURE BASE PRICE</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.FutureBasePriceDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>FUTURE RETAIL PRICE</TableCell>
            <TableCell className={classes.numericCell}>{pricesSummary && pricesSummary.FutureRetailPriceDisplay}</TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>FUTURE PRICE EFFECTIVE</TableCell>
            <TableCell className={classes.dataCell}>
              {pricesSummary &&
                pricesSummary.FuturePriceEffective}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>PRICE OVERRIDE</TableCell>
            <TableCell className={classes.dataCell}>
              {pricesSummary &&
                pricesSummary.AllowPriceOverrideLabel}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.headingCell}>ALLOWED LOT BASED PRICE</TableCell>
            <TableCell className={classes.dataCell}>
              <Switch
                // color={'default'}
                checked={pricesSummary && pricesSummary.AllowLotBasedPrice ? true : false}
                disabled={true}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.checked,
                  bar: classes.bar,
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
  }

  renderProductDetailsSummary = (): React.ReactNode => {

    const { classes, productDetailsSummary } = this.props;

    return (<Paper elevation={PaperElevation} className={classes.subPanel} square={true}>
      <p className={classes.subPanelTitle}>Detailed description</p>
      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.dataCell}>
              {productDetailsSummary && productDetailsSummary.DetailLine1}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.dataCell}>
              {productDetailsSummary && productDetailsSummary.DetailLine2}
            </TableCell>
          </TableRow>
          <TableRow className={classes.summaryRow}>
            <TableCell className={classes.dataCell}>
              {productDetailsSummary && productDetailsSummary.DetailLine3}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>);
  }

  handleImageOnClick = (): void => {
    const { productDetailsSummary = {} } = this.props;
    const splitResult = (pathOr('', ['Image'], productDetailsSummary) || '').split('Image/');
    const imageId = splitResult[1] ? splitResult[1] : undefined;

    if (imageId) {
      this.setState({ imageData: '', openImageModal: !this.state.openImageModal, imageModalLoading: true });
      fetchFile(`/Image/${imageId}`, { preview: false }).then((result) => {
        this.setState({ imageData: window.URL.createObjectURL(result), imageModalLoading: false });
      }).catch((err) => {
        showSnackBar({ variant: 'error', message: err.message, detail: err.res });
        this.setState({ imageData: '', imageModalLoading: false });
      });
    }
  }

  renderProductBottom = (): React.ReactNode => {
    const { classes, productDetailsSummary = {} } = this.props;

    return (<Paper elevation={PaperElevation} className={classes.subPanelLastChild} square={true}>
      <CardMedia
        className={classes.media}
        image={productDetailsSummary.ImageThumbnail}
        onClick={this.handleImageOnClick}
      />
    </Paper>);
  }

  handleImageModalClose = (): void => {
    this.setState({
      openImageModal: false
    });
  }

  render(): React.ReactNode {
    const { classes, selectedTransactionLoading, productDetailsSummary = {}, variant } = this.props;
    const { imageData, openImageModal, imageModalLoading } = this.state;

    return (
      <div>
        <Paper elevation={SummaryBackPaperElevation} className={classes.mainPanel} square={true} >
          <Scrollbars>
            {(selectedTransactionLoading
              && this.renderPreLoader())
              ||
              this.renderGeneric()
            }
            {(variant === 'Pricing'
              &&
              this.renderPricingSummary())
              ||
              this.renderProductDetailsSummary()
            }
            {variant === 'ProductDetails'
              &&
              this.renderProductBottom()
            }
          </Scrollbars>
        </Paper>
        <ImageModal
          open={openImageModal}
          image={imageData || (productDetailsSummary.ImageThumbnail)}
          loading={imageModalLoading}
          onClose={this.handleImageModalClose}
          data={{}}
        />
        <SimpleDialogWrapped
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          message={(productDetailsSummary && productDetailsSummary.ProductAlerts) || ''}
        />
      </div >
    );
  }
}

export default withStyles(inlineStyles, { index: 1 })(SummaryPanel);
