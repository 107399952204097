import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  dialog: {
    width: 'calc(100% - 650px)',
    maxWidth: 'calc(100% - 650px)',
    height: 495,
    maxHeight: 495,
    position: 'absolute',
    left: '272px'
  },
  dialogTitleHeading: {
    fontSize: 22,
    paddingBottom: 8
  },
  dialogTitle: {
    padding: '20px 22px 10px 22px',
    backgroundColor: '#dddddd'
  },
  dialogActionBar: {
    backgroundColor: '#dddddd',
    borderRadius: 0.7,
    padding: '10px 22px 10px 22px',
    margin: 0,
    height: 52
  },
  dialogContent: {
    height: '80%',
    overflowY: 'hidden',
    padding: '0 1rem 0 1rem'
  },
  searchContainer: {
    display: 'flex',
    padding: '1rem 0 1rem 0'
  },
  searchField: {
    display: 'inline-block',
    flexGrow: 1,
    marginRight: 7,
    fontWeight: 400,
    alignSelf: 'center'
  },
  searchButton: {
    minWidth: '100px',
    padding: '8px !important',
    justifyContent: 'space-around'
  },
  formControlRoot: {
    height: 74
  },
  okBtn: {
    'background': '#fff',
    'color': '#000',
    'borderColor': '#ddd',
    'borderRadius': 4,
    'borderWidth': 1,
    'borderStyle': 'solid',
    'fontWeight': 400,
    'fontSize': 14,
    'lineHeight': 1.35715,
    'padding': '7px 18px 8px',
    'textTransform': 'none',

    '&:hover': {
      background: '#f5f5f5'
    },

    '&:disabled': {
      background: '#fff',
      opacity: '50%',
      color: '#000'
    }
  },
  cancelBtn: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  primaryLabel: {
    fontSize: 16
  },
  secondaryLabel: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  resultLabel: {
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: 16
  },
  preLoader: {
    margin: '0 auto'
  },
  circularContainer: {
    'display': 'flex',
    'align-items': 'center',
    'text-align': 'center',
    'height': 'calc(100% - 24px)'
  },
  selectionDataGrid: {
    height: '78%',
    width: '100%'
  },
  searchTypeStyle: {
    paddingTop: '2px',
    paddingBottom: '2px'
  },
  simpleIcon: {
    height: '18px',
    width: '18px',
    transition: 'none',
  },
  button: {
    'backgroundColor': '#fff',
    'borderColor': '#ddd',
    'color': 'rgba(0, 0, 0, 0.87)',
    'borderRadius': 4,
    'borderWidth': 1,
    'borderStyle': 'solid',
    'fontWeight': 400,
    'fontSize': 14,
    'lineHeight': 1.35715,
    'padding': '7px 18px 8px',
    'textTransform': 'none',

    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
  searchIcon: {
    width: '0.75em',
    height: '0.73em',
  },
  selectionBox: {
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  checkBox: {
    padding: '5px',
  }
});
