import { createStyles } from '@material-ui/core/styles';

export const TransmitInternalPODialogStyles = createStyles({
    Container: {
        width: 500,
    },
    subTitle: {
        width: 484,
        display: 'inline-block',
        fontSize: 14,
        lineHeight: '20px'
    }
});
