import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectors as operationSelectors } from 'ducks/uiOperations';
import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as technicianActions, selectors as technicianSelectors } from 'ducks/rosterMaintenance/technician';

import { buildSearchLookUpDrawer, ISearchLookUpDrawerProperties } from '../SearchLookUpDrawer';
import { IApplicationState } from '../../../../ducks/reducers';
import { ITechnicianSearchFacade } from 'api/swaggerTypes';

const TechnicianSearchLookUpDrawer = buildSearchLookUpDrawer<ITechnicianSearchFacade>();

// We are declaring the props so that we get type-safety in the maps below.
type StateProps = Pick<ISearchLookUpDrawerProperties<ITechnicianSearchFacade>,
  'searchPlaceholder' | 'operationMode' | 'sortingFilters' | 'records' | 'selectedRecord' | 'selectedSortFilter' | 'selectedSortDirection' |
  'open' | 'isLoading' | 'nextPage' | 'prevPage' | 'pageSize' | 'totalPages' | 'loadingPrevPage' | 'loadingNextPage' |
  'keyField' | 'fieldsToDisplay' | 'searchParams' | 'variant' | 'getSearchFromMatchParams' | 'changeSelectedRecordCallBack'>;

type DispatchProps = Pick<ISearchLookUpDrawerProperties<ITechnicianSearchFacade>,
  'search' | 'searchById' | 'changeSelectedRecord' | 'onToggle' | 'fetchNextPage' | 'fetchPrevPage'>;

const mapStateToProps = (state: IApplicationState): StateProps => (
  {
    variant: 'Technicians',
    searchPlaceholder: 'Search for Technicians...',
    operationMode: operationSelectors.operationMode(state),
    sortingFilters: [
      {
        filter: 'UserId',
        label: 'Technician ID'
      },
      {
        filter: 'Surname',
        label: 'Last Name'
      },
      {
        filter: 'FirstName',
        label: 'First Name'
      }
    ],
    selectedSortDirection: 'Ascending',
    selectedSortFilter: 'UserId',
    records: technicianSelectors.all(state) || [],
    selectedRecord: technicianSelectors.selected(state),
    open: uiSelectors.isCustomerLookUpOpen(state),
    isLoading: technicianSelectors.isLoading(state),
    nextPage: technicianSelectors.nextPage(state),
    prevPage: technicianSelectors.prevPage(state),
    pageSize: technicianSelectors.pageSize(state),
    totalPages: technicianSelectors.totalPages(state),
    loadingPrevPage: technicianSelectors.loadingPrevPage(state),
    loadingNextPage: technicianSelectors.loadingNextPage(state),
    searchParams: {},
    keyField: 'UserId',
    fieldsToDisplay: ['UserId', null, null, null, 'Surname', 'FirstName'],
    changeSelectedRecordCallBack: (matchParams: URLSearchParams, selectedRecord: any) => {
      if (selectedRecord) {
        matchParams.set('TechnicianId', selectedRecord.UserId);
      }

      return matchParams.toString();
    },
    getSearchFromMatchParams: (matchParams: URLSearchParams) => {
      const userId = matchParams.get('TechnicianId');
      if (userId) {
        return {
          id: userId,
        };
      }

      return null;
    }
  });

const actionCreators: DispatchProps = {
  search: technicianActions.search,
  searchById: technicianActions.searchById,
  changeSelectedRecord: technicianActions.setSelected,
  onToggle: uiActions.toggleCustomerLookUp,
  fetchNextPage: technicianActions.fetchNextPage,
  fetchPrevPage: technicianActions.fetchPrevPage
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianSearchLookUpDrawer);
