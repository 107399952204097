import { combineReducers } from 'redux';
import extractMaintenanceTemplates, { IEMState } from './extractMaintenanceTemplates';

export interface IExtractMaintenanceState {
  templates: IEMState;
  [name: string]: any;
}
const combined = combineReducers<IExtractMaintenanceState>({
  templates: extractMaintenanceTemplates,
});

export default combined;
