import { fetchGet, objectify, fetchPut, fetchDelete, fetchPost, swapObjectValues } from '../utils';
import { pathOr } from 'utils/utils';

export const processCashReceipt = (data: any) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/Process', data, null, 'Inline')
    .then(({ DocketOptions = {} }) => ({ inlineObject: objectify(DocketOptions || {}), schema: DocketOptions || {} }));
};

export const getCashReceipt = () => {
  return fetchGet('/PointOfSaleCashReceipting/CashReceipt', {}, 'Inline')
    .then(({ POSCashReceipt = {} }) => ({
      ...POSCashReceipt,
      CashReceipt: { inlineObject: objectify(POSCashReceipt.CashReceipt || {}), schema: POSCashReceipt.CashReceipt || {} },
      CashReceiptSummary: { inlineObject: objectify(POSCashReceipt.CashReceiptSummary || {}), schema: POSCashReceipt.CashReceiptSummary || {} }
    }));
};

export const updateCashReceipt = (data: any) => {
  return fetchPut('/PointOfSaleCashReceipting/CashReceipt/Details', data, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const deleteCashReceipt = () => {
  return fetchDelete('/PointOfSaleCashReceipting/CashReceipt')
    .then(({ POSCashReceipt = {} }) => ({
      ...POSCashReceipt,
      CashReceipt: { inlineObject: objectify(POSCashReceipt.CashReceipt || {}), schema: POSCashReceipt.CashReceipt || {} },
      CashReceiptSummary: { inlineObject: objectify(POSCashReceipt.CashReceiptSummary || {}), schema: POSCashReceipt.CashReceiptSummary || {} }
    }));
};

export const fetchCashReceiptContext = () => {
  return fetchGet('/PointOfSaleCashReceipting/CashReceiptContext', {}, 'Inline')
    .then((something) => ({ inlineObject: objectify(something.CashReceiptContext), schema: something.CashReceiptContext }));
};

export const updateCashReceiptContext = (data: any) => {
  return fetchPut('/PointOfSaleCashReceipting/CashReceiptContext', data, 'Inline')
    .then((something) => ({ inlineObject: objectify(something.CashReceiptContext), schema: something.CashReceiptContext }));
};

export const applyCashReceiptContext = (data: any) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceiptContext/Apply', data, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const allocateCashReceipt = (data: any) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/Allocate', data, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const getAllocatedCashReceipt = () => {
  return fetchGet('/PointOfSaleCashReceipting/CashReceipt/Allocation', {}, 'Inline')
    .then(({ POSAllocation = {} }) => ({ inlineObject: objectify(POSAllocation), schema: POSAllocation }));
};

export const updateAllocatedCashReceipt = (data: any) => {
  return fetchPut('/PointOfSaleCashReceipting/CashReceipt/Allocation', data, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} }
    }));
};

export const confirmCashReceiptProcess = (data: any) => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/Confirmation/Process', data, null, 'Inline')
    .then(({ CashReceipt = {} }) => ({
      ...CashReceipt,
      Allocation: { inlineObject: objectify(CashReceipt.Allocation || {}), schema: CashReceipt.Allocation || {} },
      CashReceipt: { inlineObject: objectify(CashReceipt.Details || {}), schema: CashReceipt.Details || {} },
      CashReceiptSummary: { inlineObject: objectify(CashReceipt.Summary || {}), schema: CashReceipt.Summary || {} },
      PaymentDetails: { inlineObject: objectify(CashReceipt.PaymentDetails || {}), schema: CashReceipt.PaymentDetails || {} },
      DefaultLine: { inlineObject: objectify(pathOr(null, ['PaymentDetails', 'DefaultLine'], CashReceipt) || {}), schema: pathOr(null, ['PaymentDetails', 'DefaultLine'], CashReceipt) || {} },
      PaymentLines: CashReceipt.PaymentDetails.PaymentLines.map((item) => objectify(swapObjectValues(item), null, 'Inline')),
      PaymentContext: { inlineObject: objectify(pathOr(null, ['PaymentDetails', 'PaymentContext'], CashReceipt) || {}), schema: pathOr(null, ['PaymentDetails', 'PaymentContext'], CashReceipt) || {} },
      PaymentRequired: pathOr(false, ['PaymentRequired', 'Value'], CashReceipt),
    }));
};
export const cashReceiptProcessComplete = async () => {
  return fetchPost('/PointOfSaleCashReceipting/CashReceipt/ProcessComplete');
};
