import {
  createActions, asyncInitialState, asyncOnRequest,
  asyncOnSuccess, asyncOnError, asyncSelectors
} from '../../utils'

export const { types, actions } = createActions({
  setSelected: (row) => ({ row }),
  reset: () => null,
  asyncs: {
  }
}, 'reserved');

const initialState = asyncInitialState({
  selected: null,
  gridOptions: {
    rowModelType: 'serverSide',
    doubleClickActionTab: '',
    suppressEditDeleteInContextMenu: true,
  },
  Columns: [
    { headerName: 'Reserve type', field: 'ReservedType', minWidth: 200, suppressSorting: true, },
    { headerName: 'Document', field: 'Document', minWidth: 100, suppressSorting: true, },
    { headerName: 'Reserved quantity', field: 'ReservedQuantityDisplay', type: 'numericColumn', minWidth: 150, suppressSorting: true, },
    { headerName: 'Entered', field: 'EnteredDate', type: 'numericColumn', minWidth: 120, suppressSorting: true, },
    { headerName: 'Expiry', field: 'ExpiryDate', type: 'numericColumn', minWidth: 120, },
    { headerName: 'User', field: 'ResponsiblePerson', minWidth: 100, suppressSorting: true, },
    { headerName: 'Name', field: 'ResponsiblePersonLabel', minWidth: 200, suppressSorting: true, },
    {
      headerName: 'Customer',
      field: 'CustomerIdDisplay',
      minWidth: 120,
      hyperlinkParamGetter: (row) => {
        if (row.data && row.data.CustomerId) {
          return {
            CustomerId: row.data.CustomerId,
            CustomerAccountNumber: row.data.CustomerIdDisplay,
            FieldValue: row.data.CustomerIdDisplay
          };
        } else return null;
      },
      cellRenderer: 'hyperLinkRenderer',
      cellRendererParams: {
        link: '/customer-enquiry'
      }
    },
    { headerName: 'Customer name', field: 'CustomerIdLabel', minWidth: 250, },
    { headerName: 'Warehouse', field: 'Warehouse', minWidth: 100, suppressSorting: true, },
    { headerName: 'Warehouse name', field: 'WarehouseDescription', minWidth: 350, suppressSorting: true, },
  ],
  Data: [],
  selectedFilters: {
    ReserveType: '1',
    CustomerId: 0,
  },
  filterRow: {
    formName: 'IEStockingQuantitiesPickingFilters',
    hidePreFilters: false,
    parameters: [
      {
        id: 0,
        type: 'AUTOCOMPLETE_FIELD',
        props: {
          label: 'Reserve type',
          name: 'ReserveType',
          required: true,
          options: [
            {
              value: '1',
              label: 'All'
            },
            {
              value: '2',
              label: 'General'
            },
            {
              value: '3',
              label: 'Quotes'
            },
            {
              value: '4',
              label: 'Inquiry'
            },
            {
              value: '5',
              label: 'Sales Order'
            }
          ]
        }
      },
      {
        id: 1,
        type: 'EX_LOOKUP_FIELD',
        props: {
          label: 'Customer',
          name: 'CustomerId',
          lookupName: 'Customer',
          size: 'medium',
          required: false
        }
      }
    ]
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case types.reset:
      return {
        ...state,
        data: initialState.data
      };
    case types.setSelected:
      const selected = action.data.row;
      return {
        ...state,
        data: {
          ...state.data,
          selected: selected
        }
      }

    default:
      return state
  }
};

const asyncSelector = asyncSelectors(
  (state) => state.inventoryEnquiry.stocking.reserved,
  {
    data: data => data.Data,
    selected: data => data.selected,
  }
)

const syncSelector = {
  isLoading: state => state.inventoryEnquiry.stocking.reserved.fetch_loading,
  gridOptions: state => state.inventoryEnquiry.stocking.reserved.data.gridOptions,
  columns: state => state.inventoryEnquiry.stocking.reserved.data.Columns,
  filterRow: state => state.inventoryEnquiry.stocking.reserved.data.filterRow,
  selectedFilters: state => state.inventoryEnquiry.stocking.reserved.data.selectedFilters,
}

export const selectors = Object.assign({}, asyncSelector, syncSelector)