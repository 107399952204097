import { actions as uiActions, selectors as uiSelectors } from 'ducks/ui';
import { actions as operationActions } from 'ducks/uiOperations';
import { actions as formActions } from 'ducks/form';
import { selectors as branchOrdersSelectors, actions as branchOrdersActions } from 'ducks/purchaseOrderEnquiry/ldBranchOrders';
import { selectors as orderSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrder';
import { search } from 'api/purchaseOrderEnquiry/ldBranchOrders';
import { actions as filterActions, selectors as filterSelectors } from 'ducks/common/filters'
import { selectors as orderLineSelectors } from 'ducks/purchaseOrderEnquiry/purchaseOrderLine';

const params = new URLSearchParams(location.search);

export const mapStateToProps = state => ({
  gridName: 'LDBranchOrders',
  columnDefs: branchOrdersSelectors.ldBranchOrdersColumns(state),
  isCustomerLookUpOpen: uiSelectors.isCustomerLookUpOpen(state), //to trigger update size based on drawer state change
  actions: branchOrdersSelectors.ldBranchOrdersActions(state),
  gridOptions: branchOrdersSelectors.gridOptions(state),
  isLoading: branchOrdersSelectors.isLoading(state),
  filterRow: branchOrdersSelectors.filterRow(state),
  selectedFilters: branchOrdersSelectors.selectedFilters(state),
  appliedFilters: branchOrdersSelectors.filterRow(state) && filterSelectors.getFilters(state, branchOrdersSelectors.filterRow(state).formName),
  reqParams: (orderSelectors.selected(state) || params.get('PurchaseOrder')) && (orderLineSelectors.selectedOrderLine(state) || params.get('LineNumber'))
   &&
    {
      PurchaseOrder: (orderSelectors.selected(state) && orderSelectors.selected(state).PurchaseOrder) || params.get('PurchaseOrder'),
      LineNumber: (orderLineSelectors.selectedOrderLine(state) && orderLineSelectors.selectedOrderLine(state).LineNumber) || params.get('LineNumber'),
    },
  keyFields: [{ valueField: 'LineNumber', routeField: 'LineNumber' }],
  apiMethod: search
});

export const actions = {
  deleteSelectedRecords: branchOrdersActions.remove,
  changeSelectedTab: uiActions.changeSelectedTab,
  changeOperationMode: operationActions.changeOperationMode,
  getFormSchema: formActions.search,
  changeSelectedForm: formActions.setSelected,
  setSelectedOrderLine: branchOrdersActions.setSelected,
  applyFilters: filterActions.applyFilters
}
