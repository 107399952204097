// tslint:disable: cyclomatic-complexity
import { createActions, DataAction } from './utils';
import { Operation } from '../utils/operations';
import { IConfirmationDialogProperties } from 'components/common/ConfirmationDialog/ConfirmationDialog';
import { IValidationDialogOptions, IValidationDialogProperties } from 'components/common/ValidationDialog/ValidationDialog.properties';
import { IInformationDialogProperties } from 'components/common/InformationDialog/InformationDialog';

export const { types, actions } = createActions(
  {
    asyncs: {},
    openMainMenu: () => null,
    closeMainMenu: () => null,
    toggleCustomerLookUp: (open: boolean) => open,
    togglePurchaseOrdersLookUp: (open: boolean) => open,
    toggleCapabilityLookUp: (open: boolean) => open,
    toggleActivityLookUp: (open: boolean) => open,
    toggleSupplierLookUp: (open: boolean) => open,
    toggleExtractMaintenanceLookUp: (open: boolean) => open,
    toggleSupplierContactsLookUp: (open: boolean) => open,
    toggleBrowseLookUp: (open: boolean) => open,
    toggleProductLookUp: (open: boolean) => open,
    toggleSalesProcessingLookupPanel: (open: boolean) => open,
    toggleCreditNotesLookupPanel: (open: boolean) => open,
    toggleMenuOption: (open: boolean) => open,
    toggleDashboardOpen: (open: boolean) => open,
    toggleTechnicianLookUp: (open: boolean) => open,
    toggleRosterEntriesLookUp: (open: boolean) => open,
    changeSelectedTab: (selectedTab: string) => selectedTab,
    changeOperationMode: (operationMode: Operation) => operationMode,
    changeScope: (scope) => scope,
    changeSelectedWarehouse: (selectedWarehouse) => selectedWarehouse,
    changeWarehouseLookupOpen: (open: boolean) => open,
    setIsDashboardDefault: (yes: boolean) => yes,
    changeConfirmationDialog: (options: IConfirmationDialogProperties) => options,
    changeInformationDialog: (options: IConfirmationDialogProperties) => options,
    changeValidationDialog: (options: IValidationDialogOptions) => options,
    lockDrawer: () => null,
    unlockDrawer: () => null
  },
  'ui');

const SALES_PROCESSING = 'sales-processing';
const CASH_RECEIPTING = 'cash-receipting';
const CUSTOMER_MAINTENANCE = 'customer-maintenance';
const ACTIVITY_MAINTENANCE = 'activity-maintenance';
const CUSTOMER_ENQUIRY = 'customer-enquiry';
const SALES_ORDER_ENQUIRY = 'sales-order-enquiry';
const CUSTOMER_QUOTE_ENQUIRY = 'customer-quote-enquiry';
const SALES_INVOICE_ENQUIRY = 'sales-invoice-enquiry';
const INVENTORY_ENQUIRY = 'inventory-enquiry';
const PRODUCT_MOVEMENT_ENQUIRY = 'product-movement-enquiry';
const PRICE_CHECK = 'price-check';
const SUPPLIER_ENQUIRY = 'supplier-enquiry';
const SUPPLIER_INVOICE_ENQUIRY = 'supplier-invoice-enquiry';
const SERVICE_ORDERS = 'service-orders';
const PICK_SLIP_ENQUIRY = 'pick-slip-enquiry';
const SUPPLIER_PRODUCT_ENQUIRY = 'supplier-product-enquiry';
const PURCHASE_ORDER_ENQUIRY = 'purchase-order-enquiry';
const PURCHASE_ORDERS = 'purchase-orders';
const CREDIT_NOTES = 'credit-notes';
const ROSTER_MAINTENANCE = 'roster-maintenance';
const SERVICE_ITEM_TYPE_MAINTENANCE = 'service-item-type-maintenance';
const JOB_TEMPLATE_MAINTENANCE = 'job-template-maintenance';
const SERVICE_ITEM_MAINTENANCE = 'service-pattern-maintenance';
const SERVICE_AGREEMENT_MAINTENANCE = 'service-agreement-maintenance';
const CHECKLIST_MAINTENANCE = 'checklist-maintenance';
const PICK_SLIPS = 'pick-slips';
const EXTRACT_MAINTENANCE = 'extract-maintenance';
const RUN_DYNAMIC_EXTRACT = 'run-dynamic-extract';

export interface IUIData {
  isMainMenuOpen: boolean;
  isActivityLookUpOpen: boolean;
  isCapabilityLookUpOpen: boolean;
  isCustomerLookUpOpen: boolean;
  isPurchaseOrdersLookUpOpen: boolean;
  isTechnicianLookUpOpen: boolean;
  isRosterEntriesLookUpOpen: boolean;
  isSupplierLookUpOpen: boolean;
  isExtractMaintenanceLookUpOpen: boolean;
  isSupplierSearchLookUpOpen: boolean;
  isBrowseLookUpOpen: boolean;
  isProductLookUpOpen: boolean;
  isSalesProcessingLookupPanelOpen: boolean;
  isCreditNotesLookupPanelOpen: boolean;
  isMenuOptionOpen: boolean;
  isDashboardDialogOpen: boolean;
  selectedTab: string;
  operationMode: Operation;
  scope: string;
  selectedWarehouse: any;
  selectedProduct: any;
  warehouseLookupOpen: boolean;
  mainLookupDrawerLocked: boolean;
  confirmationDialogOpen: boolean;
  confirmationDialog: IConfirmationDialogProperties;
  informationDialog: IInformationDialogProperties;
  validationDialog: IValidationDialogProperties;
  isDashboardDefault: boolean;
}

const initialState: IUIData = {
  isMainMenuOpen: false,
  isCustomerLookUpOpen: true,
  isPurchaseOrdersLookUpOpen: true,
  isCapabilityLookUpOpen: true,
  isActivityLookUpOpen: true,
  isSupplierLookUpOpen: true,
  isExtractMaintenanceLookUpOpen: true,
  isSupplierSearchLookUpOpen: true,
  isBrowseLookUpOpen: false,
  isProductLookUpOpen: true,
  isTechnicianLookUpOpen: true,
  isRosterEntriesLookUpOpen: true,
  isSalesProcessingLookupPanelOpen: true,
  isCreditNotesLookupPanelOpen: true,
  mainLookupDrawerLocked: false,
  isMenuOptionOpen: false,
  isDashboardDefault: false,
  isDashboardDialogOpen: false,
  selectedTab: '',
  operationMode: Operation.BROWSE,
  scope: '',
  selectedWarehouse: null,
  selectedProduct: null,
  warehouseLookupOpen: false,
  confirmationDialogOpen: false,
  confirmationDialog: {
    open: false,
    onCancel: () => null,
    onOk: () => null,
    title: 'Confirmation',
    message: 'Are you sure?',
    okLabel: 'OK',
    cancelLabel: 'Cancel'
  },
  informationDialog: {
    open: false,
    onOk: () => null,
    title: '',
    message: '',
    okLabel: '',
  },
  validationDialog: {
    open: false,
    title: 'Validation',
    message: '',
    actions: [],
  }
};

const resetInitStateByScope = (scope) => {
  switch (scope) {
    case CUSTOMER_MAINTENANCE:
      return {
        scope: CUSTOMER_MAINTENANCE,
        isMainMenuOpen: false
      };
    case ACTIVITY_MAINTENANCE:
      return {
        scope: ACTIVITY_MAINTENANCE,
        isMainMenuOpen: false
      };
    case CUSTOMER_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: CUSTOMER_ENQUIRY,
        isMainMenuOpen: false
      };
    case SERVICE_ITEM_TYPE_MAINTENANCE:
      return {
        operationMode: Operation.BROWSE,
        scope: SERVICE_ITEM_TYPE_MAINTENANCE,
        isMainMenuOpen: false
      };
    case JOB_TEMPLATE_MAINTENANCE:
      return {
        operationMode: Operation.BROWSE,
        scope: JOB_TEMPLATE_MAINTENANCE,
        isMainMenuOpen: false
      };
    case SERVICE_ITEM_MAINTENANCE:
      return {
        operationMode: Operation.BROWSE,
        scope: SERVICE_ITEM_MAINTENANCE,
        isMainMenuOpen: false
      };
    case SERVICE_AGREEMENT_MAINTENANCE:
      return {
        operationMode: Operation.BROWSE,
        scope: SERVICE_AGREEMENT_MAINTENANCE,
        isMainMenuOpen: false
      };
    case CHECKLIST_MAINTENANCE:
      return {
        operationMode: Operation.BROWSE,
        scope: CHECKLIST_MAINTENANCE,
        isMainMenuOpen: false
      };
    case SALES_ORDER_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: SALES_ORDER_ENQUIRY,
        isMainMenuOpen: false
      };
    case CUSTOMER_QUOTE_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: CUSTOMER_QUOTE_ENQUIRY,
        isMainMenuOpen: false
      };
    case SALES_PROCESSING:
      return {
        selectedTab: 'SPLines',
        scope: SALES_PROCESSING,
        isMainMenuOpen: false,
      };
    case CASH_RECEIPTING:
      return {
        scope: CASH_RECEIPTING,
        isMainMenuOpen: false,
      };
    case SALES_INVOICE_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: SALES_INVOICE_ENQUIRY,
        isMainMenuOpen: false,
      };
    case INVENTORY_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: INVENTORY_ENQUIRY,
        isMainMenuOpen: false
      };
    case PRODUCT_MOVEMENT_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: PRODUCT_MOVEMENT_ENQUIRY,
        isMainMenuOpen: false
      };
    case SUPPLIER_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: SUPPLIER_ENQUIRY,
        isMainMenuOpen: false
      };
    case SUPPLIER_INVOICE_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: SUPPLIER_INVOICE_ENQUIRY,
        isMainMenuOpen: false
      };
    case PRICE_CHECK:
      return {
        selectedTab: 'Details',
        operationMode: Operation.BROWSE,
        scope: PRICE_CHECK,
        isMainMenuOpen: false
      };
    case SERVICE_ORDERS:
      return {
        selectedTab: 'OrderDetails',
        operationMode: Operation.EDIT,
        scope: SERVICE_ORDERS,
        isMainMenuOpen: false
      };
    case PICK_SLIP_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: PICK_SLIP_ENQUIRY,
        isMainMenuOpen: false
      };
    case SUPPLIER_PRODUCT_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: SUPPLIER_PRODUCT_ENQUIRY,
        isMainMenuOpen: false
      };
    case PURCHASE_ORDER_ENQUIRY:
      return {
        operationMode: Operation.BROWSE,
        scope: PURCHASE_ORDER_ENQUIRY
      };
    case CREDIT_NOTES:
      return {
        operationMode: Operation.BROWSE,
        scope: CREDIT_NOTES
      };
    case ROSTER_MAINTENANCE:
      return {
        scope: ROSTER_MAINTENANCE,
        isMainMenuOpen: false
      };
    case PICK_SLIPS:
      return {
        selectedTab: 'Lines',
        scope: PICK_SLIPS,
        isMainMenuOpen: false,
      };
    case EXTRACT_MAINTENANCE:
      return {
        scope: EXTRACT_MAINTENANCE,
        isMainMenuOpen: false,
      };
    case RUN_DYNAMIC_EXTRACT:
      return {
        scope: RUN_DYNAMIC_EXTRACT,
        isMainMenuOpen: false,
      };
    case PURCHASE_ORDERS:
      return {
        scope: PURCHASE_ORDERS,
        isMainMenuOpen: false,
      };
    default:
      return {
        scope: scope
      };
  }
};
export default (state: IUIData = initialState, action: DataAction): IUIData => {
  switch (action.type) {
    case types.openMainMenu:
      return {
        ...state,
        isMainMenuOpen: true
      };
    case types.closeMainMenu:
      return {
        ...state,
        isMainMenuOpen: false
      };
    case types.toggleCustomerLookUp:
      return {
        ...state, isCustomerLookUpOpen: action.data
      };
    case types.togglePurchaseOrdersLookUp:
      return {
        ...state, isPurchaseOrdersLookUpOpen: action.data
      };
    case types.toggleActivityLookUp:
      return {
        ...state, isActivityLookUpOpen: action.data
      };
    case types.toggleCapabilityLookUp:
      return {
        ...state, isCapabilityLookUpOpen: action.data
      };
    case types.toggleSupplierLookUp:
      return {
        ...state, isSupplierLookUpOpen: action.data
      };
    case types.toggleExtractMaintenanceLookUp:
      return {
        ...state, isExtractMaintenanceLookUpOpen: action.data
      };
    case types.toggleSupplierContactsLookUp:
      return {
        ...state, isSupplierSearchLookUpOpen: action.data
      };
    case types.toggleBrowseLookUp:
      return {
        ...state, isBrowseLookUpOpen: action.data
      };
    case types.toggleProductLookUp:
      return {
        ...state, isProductLookUpOpen: action.data
      };
    case types.toggleSalesProcessingLookupPanel:
      return {
        ...state, isSalesProcessingLookupPanelOpen: action.data
      };
    case types.toggleCreditNotesLookupPanel:
      return {
        ...state, isCreditNotesLookupPanelOpen: action.data
      };
    case types.toggleMenuOption:
      return {
        ...state, isMenuOptionOpen: action.data
      };
    case types.toggleDashboardOpen:
      return {
        ...state, isDashboardDialogOpen: action.data
      };
    case types.setIsDashboardDefault:
      return {
        ...state, isDashboardDefault: action.data
      };
    case types.changeOperationMode:
      return {
        ...state, operationMode: action.data
      };
    case types.changeSelectedTab:
      return {
        ...state, selectedTab: action.data
      };
    case types.changeScope:
      return {
        ...state, ...resetInitStateByScope(action.data)
      };
    case types.changeSelectedWarehouse:
      return {
        ...state, selectedWarehouse: action.data
      };
    case types.changeWarehouseLookupOpen:
      return {
        ...state, warehouseLookupOpen: action.data
      };
    case types.changeConfirmationDialog:
      const confirmationDialogOptions = action.data as IConfirmationDialogProperties;

      return {
        ...state,
        confirmationDialog: {
          ...confirmationDialogOptions
        }
      };
    case types.changeInformationDialog:
      const informnationDialogOptions = action.data as IConfirmationDialogProperties;

      return {
        ...state,
        informationDialog: {
          ...state.informationDialog,
          ...informnationDialogOptions
        }
      };
    case types.changeValidationDialog:
      const validationDialogOptions = action.data as IValidationDialogOptions;

      return {
        ...state,
        validationDialog: {
          ...state.validationDialog,
          ...validationDialogOptions
        }
      };
    default:
      return state;

  }
};

export const selectors = {
  isMainMenuOpen: (state: { ui: IUIData }) => state.ui.isMainMenuOpen,
  isActivityLookUpOpen: (state: { ui: IUIData }) => state.ui.isActivityLookUpOpen,
  isCapabilityLookUpOpen: (state: { ui: IUIData }) => state.ui.isCapabilityLookUpOpen,
  isCustomerLookUpOpen: (state: { ui: IUIData }) => state.ui.isCustomerLookUpOpen,
  isPurchaseOrdersLookUpOpen: (state: { ui: IUIData }) => state.ui.isPurchaseOrdersLookUpOpen,
  isTechnicianLookUpOpen: (state: { ui: IUIData }) => state.ui.isTechnicianLookUpOpen,
  isRosterEntriesLookUpOpen: (state: { ui: IUIData }) => state.ui.isRosterEntriesLookUpOpen,
  isSupplierLookUpOpen: (state: { ui: IUIData }) => state.ui.isSupplierLookUpOpen,
  isSupplierSearchLookUpOpen: (state: { ui: IUIData }) => state.ui.isSupplierSearchLookUpOpen,
  isExtractMaintenanceLookUpOpen: (state: { ui: IUIData }) => state.ui.isExtractMaintenanceLookUpOpen,
  isBrowseLookUpOpen: (state: { ui: IUIData }) => state.ui.isBrowseLookUpOpen,
  isProductLookUpOpen: (state: { ui: IUIData }) => state.ui.isProductLookUpOpen,
  isSalesProcessingLookupPanelOpen: (state: { ui: IUIData }) => state.ui.isSalesProcessingLookupPanelOpen,
  isCreditNotesLookupPanelOpen: (state: { ui: IUIData }) => state.ui.isCreditNotesLookupPanelOpen,
  isMenuOptionOpen: (state: { ui: IUIData }) => state.ui.isMenuOptionOpen,
  isDashboardDialogOpen: (state: { ui: IUIData }) => state.ui.isDashboardDialogOpen,
  selectedTab: (state: { ui: IUIData }) => state.ui.selectedTab,
  operationMode: (state: { ui: IUIData }) => state.ui.operationMode,
  scope: (state: { ui: IUIData }) => state.ui.scope,
  selectedProduct: (state: { ui: IUIData }) => state.ui.selectedProduct,
  selectedWarehouse: (state: { ui: IUIData }) => state.ui.selectedWarehouse,
  warehouseLookupOpen: (state: { ui: IUIData }) => state.ui.warehouseLookupOpen,
  confirmationDialog: (state: { ui: IUIData }) => state.ui.confirmationDialog,
  informationDialog: (state: { ui: IUIData }) => state.ui.informationDialog,
  validationDialog: (state: { ui: IUIData }) => state.ui.validationDialog,
  isDashboardDefault: (state: { ui: IUIData }) => state.ui.isDashboardDefault
};
